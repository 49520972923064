// eslint-disable-next-line no-restricted-imports
import { YearMonth, YMInterval } from '../utils/YearMonth';
import type { FilterValue, GQBiDataResult } from '../customScalarGqlTypes.ts';
import type { Expression } from '@watershed/shared-universal/bi/query/expression';
import { DateTime } from 'luxon';
import { JSONSchema7 } from 'json-schema';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Untyped according to the GraphQL spec, but typed according to codegen. see it's type def in workspaces/shared-universal/customScalarGqlTypes.ts */
  BiDataResult: { input: GQBiDataResult; output: GQBiDataResult };
  /**
   * The 'Date' scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: { input: Date; output: Date };
  /**
   * The 'DateTime' scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: Date; output: Date };
  Expression: { input: Expression; output: Expression };
  /** Untyped according to the GraphQL spec, but typed according to codegen as string | boolean. see it's type def in workspaces/shared-universal/customScalarGqlTypes.ts */
  FilterValue: { input: FilterValue; output: FilterValue };
  Int53: { input: number; output: number };
  /**
   * The 'JSONSchema' scalar type represents a JSONSchema
   * value as specified by
   * [JSONSchema Draft 7](https://datatracker.ietf.org/doc/html/draft-handrews-json-schema-01).
   */
  JSONSchema: { input: JSONSchema7; output: JSONSchema7 };
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: any; output: any };
  /**
   * Similar in concept to JSONString, but not (double-)encoded as a string. It's
   * just whatever data the server decided to send you. I hope you can make sense
   * of it!
   */
  UntypedData: { input: any; output: any };
  YMInterval: { input: YMInterval; output: YMInterval };
  YearMonth: { input: YearMonth; output: YearMonth };
};

export type GQAbbreviatedPlanSubtargetInput = {
  comparisonType?: InputMaybe<GQPlanTargetTargetComparisonType>;
  emissionsTargetCustom?: InputMaybe<GQSimpleTimeseriesInput>;
  id: Scalars['ID']['input'];
  intensityType?: InputMaybe<GQPlanTargetIntensityType>;
  shouldDelete?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQAbbreviatedPlanTargetInput = {
  comparisonType?: InputMaybe<GQPlanTargetTargetComparisonType>;
  emissionsTargetCustom?: InputMaybe<GQSimpleTimeseriesInput>;
  id: Scalars['ID']['input'];
  intensityType?: InputMaybe<GQPlanTargetIntensityType>;
  shouldDelete?: InputMaybe<Scalars['Boolean']['input']>;
  subtargets?: InputMaybe<Array<GQAbbreviatedPlanSubtargetInput>>;
};

export type GQAcceptFinanceImportDiffInput = {
  financeImportId: Scalars['ID']['input'];
};

export type GQAcceptFinanceImportDiffPayload = {
  __typename?: 'AcceptFinanceImportDiffPayload';
  financeImport: GQFinanceImport;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQAcceptFootprintTestSuiteExecutionResultsInput = {
  footprintAssemblyStep: GQFootprintAssemblyStep;
  footprintTestSuiteConfigId: Scalars['ID']['input'];
  footprintTestSuiteExecutionId: Scalars['ID']['input'];
};

export type GQAcceptFootprintTestSuiteExecutionResultsPayload = {
  __typename?: 'AcceptFootprintTestSuiteExecutionResultsPayload';
  footprintTestExecutionStepResult: GQFootprintTestExecutionStepResult;
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQAcceptMethodologyTestSuiteExecutionResultsInput = {
  methodologyTestSuiteExecutionId: Scalars['ID']['input'];
};

export type GQAcceptMethodologyTestSuiteExecutionResultsPayload = {
  __typename?: 'AcceptMethodologyTestSuiteExecutionResultsPayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQAcceptReportAnswerVerifierFailuresInput = {
  reportAnswerVerifierFailureIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type GQAcceptReportAnswerVerifierFailuresPayload = {
  __typename?: 'AcceptReportAnswerVerifierFailuresPayload';
  reportAnswerVerifierFailureIds: Array<Maybe<Scalars['ID']['output']>>;
  updatedVerifierFailures: Array<GQReportAnswerVerifierFailure>;
};

export type GQAcknowledgeReportAnswerWarningInput = {
  id: Scalars['ID']['input'];
};

export type GQActiveOrganization = {
  __typename?: 'ActiveOrganization';
  canAccessCorporate: Scalars['Boolean']['output'];
  canAccessFinance: Scalars['Boolean']['output'];
  companyId: Scalars['ID']['output'];
  country: Maybe<Scalars['String']['output']>;
  currency: Maybe<Scalars['String']['output']>;
  datasourceIdsToCompletePermissions: Array<Scalars['ID']['output']>;
  demoOrg: Scalars['Boolean']['output'];
  displayCustomReports: Scalars['Boolean']['output'];
  domains: Array<Scalars['String']['output']>;
  employeeReportId: Maybe<Scalars['String']['output']>;
  financeSavedViews: Array<GQFinanceSavedView>;
  fiscalYearStartMonth: Maybe<Scalars['Int']['output']>;
  footprintKindsMeta: Array<GQFootprintKindMeta>;
  funds: Maybe<Array<GQFund>>;
  helpChannel: Maybe<Scalars['String']['output']>;
  iconUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latestDraftFootprintVersion: Maybe<Scalars['String']['output']>;
  latestPublishedFootprintVersion: Maybe<Scalars['String']['output']>;
  loginAsUser: Maybe<GQUser>;
  logoUrl: Maybe<Scalars['String']['output']>;
  measurementProjects: Array<GQMeasurementProject>;
  name: Scalars['String']['output'];
  region: GQRegion;
  sessionId: Scalars['String']['output'];
  stagingOrg: Maybe<Scalars['Boolean']['output']>;
  testOrg: Scalars['Boolean']['output'];
  user: GQUser;
  userOnboardingsCompleted: Array<GQOnboardingKind>;
  userPermissions: Array<GQPermissionItem>;
  userRoles: Array<GQUserRoleAssignment>;
  watershedEmployeePointOfContactKinds: Array<GQOrgPointOfContactKind>;
  watershedPlan: GQWatershedPlan;
  watershedPlanLegacy: GQWatershedPlanLegacy;
};

export type GQActivityBasedInitiativeImpact = {
  __typename?: 'ActivityBasedInitiativeImpact';
  filterableReductionDifference: Array<GQFilterableReducibleFootprintAggregate>;
  reducedKgco2eFraction: Scalars['Float']['output'];
};

export type GQActivityContextMetadata = {
  __typename?: 'ActivityContextMetadata';
  batUploadSets: Array<GQBusinessActivityUploadSet>;
  /** @deprecated Use batUploadSets */
  uploads: Array<GQUserUpload>;
};

export type GQActivityDataExtractor = GQIdInterface & {
  __typename?: 'ActivityDataExtractor';
  activityDataTable: GQActivityDataTable;
  bartActivityType: Maybe<Scalars['String']['output']>;
  bartId: Maybe<Scalars['ID']['output']>;
  cleanAdtOutput: Maybe<GQParquetData>;
  createdAt: Scalars['DateTime']['output'];
  duckdbVersion: Scalars['String']['output'];
  error: Maybe<Scalars['String']['output']>;
  facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
  generated: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  kind: GQActivityDataExtractorKind;
  name: Scalars['String']['output'];
  published: Maybe<Scalars['Boolean']['output']>;
  pushedBuildings: Maybe<Scalars['Boolean']['output']>;
  runSqlOutput: Maybe<GQParquetData>;
  sql: Scalars['String']['output'];
  transformedOutput: Maybe<GQParquetData>;
  user: Maybe<GQUser>;
  userUploadedTables: Array<GQUserUploadedTable>;
  userVisible: Scalars['Boolean']['output'];
  warnings: Maybe<Scalars['JSONString']['output']>;
};

export type GQActivityDataExtractorConnection = {
  __typename?: 'ActivityDataExtractorConnection';
  edges: Array<Maybe<GQActivityDataExtractorEdge>>;
  pageInfo: GQPageInfo;
};

export type GQActivityDataExtractorEdge = {
  __typename?: 'ActivityDataExtractorEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQActivityDataExtractor>;
};

export const GQActivityDataExtractorKind = {
  Bart: 'BART',
  Cadt: 'CADT',
  Reproduction: 'REPRODUCTION',
  Scratchpad: 'SCRATCHPAD',
} as const;

export type GQActivityDataExtractorKind =
  (typeof GQActivityDataExtractorKind)[keyof typeof GQActivityDataExtractorKind];
export type GQActivityDataTable = GQContextRelationInterface &
  GQIdInterface & {
    __typename?: 'ActivityDataTable';
    activityDataExtractors: GQActivityDataExtractorConnection;
    adminUrl: Scalars['String']['output'];
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQFootprintQuestion
      | GQMeasurementProject
      | GQMeasurementVerificationItemQuestion
    >;
    autoBartActivityType: Maybe<Scalars['String']['output']>;
    autoBartKind: Maybe<GQAutoBartKind>;
    autoBartTaskApprovalState: GQAutoBartTaskApprovalState;
    connectedToAutoBart: Scalars['Boolean']['output'];
    datasets: Array<GQDataset>;
    datasources: Array<GQDatasource>;
    draftAutoBartHasPreviousCustomExtractor: Scalars['Boolean']['output'];
    draftBartExtractor: Maybe<GQActivityDataExtractor>;
    draftCadtExtractor: Maybe<GQActivityDataExtractor>;
    draftScratchpadExtractor: Maybe<GQActivityDataExtractor>;
    filterInterval: Scalars['YMInterval']['output'];
    id: Scalars['ID']['output'];
    lastRefreshedAt: Maybe<Scalars['DateTime']['output']>;
    latestBartActivityType: Maybe<Scalars['String']['output']>;
    /** @deprecated Not used. Delete after 2024-07-18. */
    latestPublishedExtractorSql: Maybe<Scalars['String']['output']>;
    measurementProject: Maybe<GQMeasurementProject>;
    name: Scalars['String']['output'];
    primaryDatasource: Maybe<GQDatasource>;
    publishedExtractor: Maybe<GQActivityDataExtractor>;
    recommendedActivityTypes: Array<Scalars['String']['output']>;
    /** @deprecated Not used. Delete after 2024-07-18. */
    reproductionExtractor: Maybe<GQActivityDataExtractor>;
    reviews: Array<GQActivityDataTableReview>;
    stage: GQActivityDataTableStageKind;
  };

export type GQActivityDataTableActivityDataExtractorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQActivityDataTableConnection = {
  __typename?: 'ActivityDataTableConnection';
  edges: Array<Maybe<GQActivityDataTableEdge>>;
  pageInfo: GQPageInfo;
};

export type GQActivityDataTableEdge = {
  __typename?: 'ActivityDataTableEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQActivityDataTable>;
};

export type GQActivityDataTableReview = GQIdInterface & {
  __typename?: 'ActivityDataTableReview';
  activityDataTableId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  reviewer: GQUser;
  watershedReviewerId: Scalars['ID']['output'];
};

export const GQActivityDataTableStageKind = {
  BuildingsIdiUpload: 'BuildingsIdiUpload',
  ForFootprint: 'ForFootprint',
  PreBiodiversityTransform: 'PreBiodiversityTransform',
  PreMapping: 'PreMapping',
} as const;

export type GQActivityDataTableStageKind =
  (typeof GQActivityDataTableStageKind)[keyof typeof GQActivityDataTableStageKind];
export const GQActivityTimelineItemType = {
  AssignedTaskApprover: 'AssignedTaskApprover',
  AssignedUploader: 'AssignedUploader',
  BuildingsAdded: 'BuildingsAdded',
  BuildingsApproved: 'BuildingsApproved',
  BuildingsDeleted: 'BuildingsDeleted',
  BuildingsEdited: 'BuildingsEdited',
  CommentReply: 'CommentReply',
  CommentRoot: 'CommentRoot',
  CtsFormClosed: 'CtsFormClosed',
  CtsFormCreated: 'CtsFormCreated',
  CtsFormEntryCreated: 'CtsFormEntryCreated',
  DeletedFile: 'DeletedFile',
  RemovedTaskApprover: 'RemovedTaskApprover',
  RemovedUploader: 'RemovedUploader',
  SupportIssueReply: 'SupportIssueReply',
  SupportIssueRoot: 'SupportIssueRoot',
  TagAdded: 'TagAdded',
  TagRemoved: 'TagRemoved',
  TaskApproved: 'TaskApproved',
  TaskRejected: 'TaskRejected',
  TaskSubmittedForApproval: 'TaskSubmittedForApproval',
  TaskUnlockRequested: 'TaskUnlockRequested',
  TaskUnlocked: 'TaskUnlocked',
  UploadedFile: 'UploadedFile',
} as const;

export type GQActivityTimelineItemType =
  (typeof GQActivityTimelineItemType)[keyof typeof GQActivityTimelineItemType];
export type GQActivityTypeSchema = {
  __typename?: 'ActivityTypeSchema';
  activityType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  schema: GQCanonicalSchema;
};

export type GQAddCalculationTagToReferenceDataInput = {
  sourceId: Scalars['ID']['input'];
  tagName: Scalars['String']['input'];
};

export type GQAddCalculationTagToReferenceDataPayload = {
  __typename?: 'AddCalculationTagToReferenceDataPayload';
  success: Scalars['Boolean']['output'];
};

export type GQAddCalculationTagToStableModelInput = {
  stableId: Scalars['ID']['input'];
  tagName: Scalars['String']['input'];
};

export type GQAddCalculationTagToStableModelPayload = {
  __typename?: 'AddCalculationTagToStableModelPayload';
  success: Scalars['Boolean']['output'];
};

export type GQAddCompaniesToPeerCompanyGroupsInput = {
  companyIds: Array<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  peerCompanyGroupIds: Array<Scalars['ID']['input']>;
};

export type GQAddCompaniesToPeerCompanyGroupsPayload = {
  __typename?: 'AddCompaniesToPeerCompanyGroupsPayload';
  peerCompanyGroups: Array<GQPeerCompanyGroup>;
};

export type GQAddDataApprovalCommentInput = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  comment: Scalars['String']['input'];
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQAddDataApprovalCommentPayload = {
  __typename?: 'AddDataApprovalCommentPayload';
  dataIssue: GQDataIssue;
  newChangelogEvents: Array<GQDataApprovalChangelogEvent>;
};

export type GQAddEmissionsModelToOrgInput = {
  orgId: Scalars['ID']['input'];
  stableId: Scalars['ID']['input'];
};

export type GQAddEmissionsModelToOrgPayload = {
  __typename?: 'AddEmissionsModelToOrgPayload';
  emissionsModelStable: GQEmissionsModelStable;
};

export type GQAddEngagementTaskCommentInput = {
  comment: Scalars['String']['input'];
  engagementTaskId: Scalars['ID']['input'];
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQAddEngagementTaskCommentPayload = {
  __typename?: 'AddEngagementTaskCommentPayload';
  comment: GQEngagementTaskComment;
};

export type GQAddHeaderToCustomReportInput = {
  insertionIndex?: InputMaybe<Scalars['Int']['input']>;
  kind: GQCustomReportHeaderKind;
  reportConfigId: Scalars['ID']['input'];
};

export type GQAddHeaderToCustomReportPayload = {
  __typename?: 'AddHeaderToCustomReportPayload';
  report: GQReport;
};

export type GQAddMarketplaceSupplierPointOfContactConnectionInput = {
  pointOfContactId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
};

export type GQAddMarketplaceSupplierPointOfContactConnectionPayload = {
  __typename?: 'AddMarketplaceSupplierPointOfContactConnectionPayload';
  marketplacePointOfContact: GQMarketplacePointOfContact;
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQAddMarketplaceSupplierPointOfContactInput = {
  pointOfContactEmail: Scalars['String']['input'];
  pointOfContactName: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

export type GQAddMarketplaceSupplierPointOfContactPayload = {
  __typename?: 'AddMarketplaceSupplierPointOfContactPayload';
  marketplacePointOfContact: GQMarketplacePointOfContact;
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQAddMarketplaceSupplierWatershedEmployeeInput = {
  supplierId: Scalars['ID']['input'];
  watershedEmployeeId: Scalars['ID']['input'];
};

export type GQAddMarketplaceSupplierWatershedEmployeePayload = {
  __typename?: 'AddMarketplaceSupplierWatershedEmployeePayload';
  marketplaceSupplier: GQMarketplaceSupplier;
  watershedEmployee: GQWatershedEmployee;
};

export type GQAddMetricsToCustomReportInput = {
  biSavedViewStableIds: Array<Scalars['String']['input']>;
  insertionIndex?: InputMaybe<Scalars['Int']['input']>;
  newBiSavedViews?: InputMaybe<Array<GQCreateBiSavedViewInput>>;
  reportConfigId: Scalars['ID']['input'];
};

export type GQAddMetricsToCustomReportPayload = {
  __typename?: 'AddMetricsToCustomReportPayload';
  report: GQReport;
};

export type GQAddOrgAccessToReleaseInput = {
  orgIds: Array<Scalars['ID']['input']>;
  releaseId: Scalars['ID']['input'];
};

export type GQAddOrgPointOfContactInput = {
  kind: GQOrgPointOfContactKind;
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  watershedEmployeeId: Scalars['ID']['input'];
};

export type GQAddOrgPointOfContactPayload = {
  __typename?: 'AddOrgPointOfContactPayload';
  org: GQOrganization;
};

export type GQAddReportQuestionNoteInput = {
  memberUserIds: Array<Scalars['String']['input']>;
  note: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
  reportQuestionId: Scalars['String']['input'];
};

export type GQAddReportQuestionNotePayload = {
  __typename?: 'AddReportQuestionNotePayload';
  discussion: GQDiscussion;
  reportQuestion: GQReportQuestion;
};

export type GQAddTestRowsToTestSuiteBartInput = {
  activityRows: Array<Scalars['JSONString']['input']>;
  measurementTestSuiteBartId: Scalars['ID']['input'];
};

export type GQAddTestRowsToTestSuiteBartPayload = {
  __typename?: 'AddTestRowsToTestSuiteBartPayload';
  measurementTestSuiteBart: GQMeasurementTestSuiteBart;
};

export type GQAddUserUploadCandidatesToFileFeedEmbeddedSessionInput = {
  embeddedSessionId: Scalars['String']['input'];
  userUploadCandidateIds: Array<Scalars['ID']['input']>;
};

export type GQAddWatershedEmployeeInput = {
  email: Scalars['String']['input'];
};

export type GQAddWatershedEmployeePayload = {
  __typename?: 'AddWatershedEmployeePayload';
  watershedEmployee: GQWatershedEmployee;
};

export type GQAdditionalQuestionInput = {
  answer: Scalars['JSONString']['input'];
  questionId: GQIngestionQuestionKey;
};

export type GQAdditionalQuestionResponse = {
  __typename?: 'AdditionalQuestionResponse';
  answer: Scalars['JSONString']['output'];
  questionId: GQIngestionQuestionKey;
};

export const GQAdeFacilitiesSurveyStatus = {
  NotFacilitiesAdt: 'NotFacilitiesAdt',
  Stale: 'Stale',
  Unknown: 'Unknown',
  UpToDate: 'UpToDate',
} as const;

export type GQAdeFacilitiesSurveyStatus =
  (typeof GQAdeFacilitiesSurveyStatus)[keyof typeof GQAdeFacilitiesSurveyStatus];
export type GQAdminDatasourcePayload = {
  __typename?: 'AdminDatasourcePayload';
  datasource: GQDatasource;
  measurementProject: Maybe<GQMeasurementProject>;
};

export type GQAdminSuppliers = {
  __typename?: 'AdminSuppliers';
  suppliersMerged: GQSuppliersData;
};

export const GQAggregateKind = {
  CustomIntensity: 'CustomIntensity',
  Electricity: 'Electricity',
  HeadcountIntensity: 'HeadcountIntensity',
  LocationBased: 'LocationBased',
  OriginalTotal: 'OriginalTotal',
  PercentageOfCurrentView: 'PercentageOfCurrentView',
  PercentageOfTotalFootprint: 'PercentageOfTotalFootprint',
  RevenueIntensity: 'RevenueIntensity',
  Total: 'Total',
} as const;

export type GQAggregateKind =
  (typeof GQAggregateKind)[keyof typeof GQAggregateKind];
export type GQAnswerMeasurementVerificationItemQuestionInput = {
  answerText?: InputMaybe<Scalars['String']['input']>;
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  questionId: Scalars['ID']['input'];
};

export type GQAnswerMeasurementVerificationItemQuestionPayload = {
  __typename?: 'AnswerMeasurementVerificationItemQuestionPayload';
  measurementProject: Maybe<GQMeasurementProject>;
  measurementTask: GQMeasurementParentTask | GQMeasurementTaskFlat;
  question: GQMeasurementVerificationItemQuestion;
};

export type GQApiKey = {
  __typename?: 'ApiKey';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: Array<GQApiKeyRoleAssignment>;
  secret: Scalars['String']['output'];
};

export type GQApiKeyConnection = {
  __typename?: 'ApiKeyConnection';
  edges: Array<Maybe<GQApiKeyEdge>>;
  pageInfo: GQPageInfo;
};

export type GQApiKeyEdge = {
  __typename?: 'ApiKeyEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQApiKey>;
};

export type GQApiKeyRoleAssignment = {
  __typename?: 'ApiKeyRoleAssignment';
  apiKey: GQApiKey;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  org: GQOrganization;
  revokedAt: Maybe<Scalars['DateTime']['output']>;
  role: GQRole;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQApiUpload = {
  __typename?: 'ApiUpload';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQApplyFootprintCategoryRulesInput = {
  rules: Array<GQFootprintCategoryRuleInput>;
  userChangelogMd?: InputMaybe<Scalars['String']['input']>;
};

export type GQApplyFootprintCategoryRulesPayload = {
  __typename?: 'ApplyFootprintCategoryRulesPayload';
  footprintAssemblyRunId: Scalars['ID']['output'];
  jobId: Scalars['ID']['output'];
  organization: GQOrganization;
};

export type GQApplyGlobalTagToTestDataInput = {
  rulesListPayload: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
};

export type GQApplyGlobalTagToTestDataPayload = {
  __typename?: 'ApplyGlobalTagToTestDataPayload';
  taggedResultParquetSignedUrl: Scalars['String']['output'];
};

export type GQApplyIntegrationConnectionInput = {
  datasourceIds: Array<Scalars['ID']['input']>;
  integrationConnectionId: Scalars['ID']['input'];
};

export type GQApplyIntegrationConnectionPayload = {
  __typename?: 'ApplyIntegrationConnectionPayload';
  datasources: Array<GQDatasource>;
  integrationConnection: GQIntegrationConnection;
};

export type GQApplyTagsToPipelineResultInput = {
  rulesListPayload: Scalars['String']['input'];
  showUniqueInputsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  tagName: Scalars['String']['input'];
  targetPipelineResultId: Scalars['String']['input'];
};

export type GQApplyTagsToPipelineResultPayload = {
  __typename?: 'ApplyTagsToPipelineResultPayload';
  distinctCount: Scalars['Int']['output'];
  taggedResultParquetSignedUrl: Scalars['String']['output'];
};

export const GQApprovalStatus = {
  Approved: 'Approved',
  NotReadyForApproval: 'NotReadyForApproval',
  SubmittedForApproval: 'SubmittedForApproval',
} as const;

export type GQApprovalStatus =
  (typeof GQApprovalStatus)[keyof typeof GQApprovalStatus];
export type GQApprovalTarget = {
  approvalStatus: GQApprovalStatus;
  approvers: Array<GQUserWithApprovalStatus>;
  id: Scalars['ID']['output'];
  lockState: GQApprovalTargetLockState;
};

export const GQApprovalTargetLockState = {
  Locked: 'Locked',
  UnlockRequested: 'UnlockRequested',
  Unlocked: 'Unlocked',
} as const;

export type GQApprovalTargetLockState =
  (typeof GQApprovalTargetLockState)[keyof typeof GQApprovalTargetLockState];
export type GQApproveAllValueMappingRecordsInput = {
  configId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  ruleId?: InputMaybe<Scalars['String']['input']>;
};

export type GQApproveAllValueMappingRecordsPayload = {
  __typename?: 'ApproveAllValueMappingRecordsPayload';
  success: Scalars['Boolean']['output'];
  workflowId: Scalars['String']['output'];
};

export type GQApproveCtsTransformTestCaseInput = {
  ctsTransformTestRunId: Scalars['ID']['input'];
};

export type GQApproveCtsTransformTestCasePayload = {
  __typename?: 'ApproveCtsTransformTestCasePayload';
  ctsTransformTestCase: GQCustomerTargetSchemaTransformTestCase;
};

export type GQApproveDataInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  targetId: Scalars['ID']['input'];
  targetKind: Scalars['String']['input'];
};

export type GQApproveDataPayload = {
  __typename?: 'ApproveDataPayload';
  newChangelogEvents: Array<GQDataApprovalChangelogEvent>;
  target: GQUserUploadTask;
};

export type GQApproveReportAnswerVerifiersInput = {
  reportAnswerVerifierFailureIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type GQApproveReportAnswerVerifiersPayload = {
  __typename?: 'ApproveReportAnswerVerifiersPayload';
  reportAnswerVerifierFailureIds: Array<Maybe<Scalars['ID']['output']>>;
  updatedAnswers: Array<GQReportAnswer>;
};

export type GQArchiveFootprintCustomReportInput = {
  id: Scalars['ID']['input'];
};

export type GQArchiveFootprintCustomReportPayload = {
  __typename?: 'ArchiveFootprintCustomReportPayload';
  footprintCustomReport: GQFootprintCustomReport;
};

export type GQArchiveFootprintSnapshotInput = {
  id: Scalars['ID']['input'];
};

export type GQArchiveFootprintSnapshotPayload = {
  __typename?: 'ArchiveFootprintSnapshotPayload';
  snapshot: GQFootprintSnapshot;
};

export type GQArchivePipelineResultInput = {
  id: Scalars['ID']['input'];
};

export type GQArchivePipelineResultPayload = {
  __typename?: 'ArchivePipelineResultPayload';
  pipelineResult: GQPipelineResult;
};

export type GQArchiveReferenceDataCitationPayload = {
  __typename?: 'ArchiveReferenceDataCitationPayload';
  referenceDataCitation: GQReferenceDataCitation;
};

export type GQArchiveReferenceDataRevisionInput = {
  id: Scalars['ID']['input'];
};

export type GQArchiveReferenceDataRevisionPayload = {
  __typename?: 'ArchiveReferenceDataRevisionPayload';
  referenceDataRevision: GQReferenceDataRevision;
};

export type GQArchiveReferenceDataSourceInput = {
  id: Scalars['ID']['input'];
};

export type GQArchiveReferenceDataSourcePayload = {
  __typename?: 'ArchiveReferenceDataSourcePayload';
  referenceDataSource: GQReferenceDataSource;
};

export type GQArchiveReferenceDataVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQArchiveReferenceDataVersionPayload = {
  __typename?: 'ArchiveReferenceDataVersionPayload';
  referenceDataVersion: GQReferenceDataVersion;
};

export type GQArchiveReportInput = {
  reportId: Scalars['ID']['input'];
};

export type GQArchiveReportPayload = {
  __typename?: 'ArchiveReportPayload';
  report: GQReport;
};

export type GQAssetComment = {
  __typename?: 'AssetComment';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetCommercialRealEstateId: Maybe<Scalars['ID']['output']>;
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  attachments: Maybe<Array<GQAssetCommentAttachment>>;
  commentHistory: Maybe<Scalars['UntypedData']['output']>;
  commentText: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lastEdited: Maybe<Scalars['DateTime']['output']>;
  orgId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: Maybe<GQUser>;
  userId: Scalars['ID']['output'];
};

export type GQAssetCommentAttachment = {
  __typename?: 'AssetCommentAttachment';
  assetCommentId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  fileMetadata: Maybe<GQFileMetadata>;
  fileMetadataId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQAssetCorporate = GQIFinanceAsset & {
  __typename?: 'AssetCorporate';
  anonymousCompanyUuid: Maybe<Scalars['String']['output']>;
  assetComments: Array<GQAssetComment>;
  assetGroup: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  benchmarks: Array<GQBenchmark>;
  buildingSizeUnit: Maybe<GQBuildingSizeUnit>;
  company: Maybe<GQCompany>;
  companyBea: Maybe<Scalars['String']['output']>;
  companyId: Maybe<Scalars['ID']['output']>;
  companyIsin: Maybe<Scalars['String']['output']>;
  companyLei: Maybe<Scalars['String']['output']>;
  companyTicker: Maybe<Scalars['String']['output']>;
  companyUrl: Maybe<Scalars['String']['output']>;
  contacts: Array<GQSupplierContact>;
  countryAlpha2: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  disclosures: Array<GQDisclosure>;
  emissionsByGhgCategory: Maybe<GQEmissionsByGhgCategoryForFinance>;
  engagementTasks: Array<GQEngagementTask>;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  naicsCode: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  selfBenchmark: Maybe<GQBenchmark>;
  /** @deprecated use tasks */
  surveys: Array<GQCompanySurvey>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetCorporateAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateEmissionsByGhgCategoryArgs = {
  emissionsYearId: InputMaybe<Scalars['ID']['input']>;
  year: Scalars['Int']['input'];
};

export type GQAssetCorporateFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateSelfBenchmarkArgs = {
  year: Scalars['Int']['input'];
};

export type GQAssetGroup = GQIFinanceAsset & {
  __typename?: 'AssetGroup';
  assetComments: Array<GQAssetComment>;
  assetGroupAssets: Array<GQAssetGroupAsset>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  buildingSizeUnit: Maybe<GQBuildingSizeUnit>;
  company: Maybe<GQCompany>;
  companyId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetGroupAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetGroupAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetGroupFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetGroupRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetGroupAsset =
  | GQAssetCorporate
  | GQAssetGroup
  | GQAssetRealEstate;

export type GQAssetPersonalMotorVehicleInsurance = GQIFinanceAsset & {
  __typename?: 'AssetPersonalMotorVehicleInsurance';
  assetComments: Array<GQAssetComment>;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  buildingSizeUnit: Maybe<GQBuildingSizeUnit>;
  companyId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetPersonalMotorVehicleInsuranceAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetPersonalMotorVehicleInsuranceAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetPersonalMotorVehicleInsuranceFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetPersonalMotorVehicleInsuranceRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetRealEstate = GQIFinanceAsset & {
  __typename?: 'AssetRealEstate';
  assetComments: Array<GQAssetComment>;
  assetGroup: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  buildingKind: Maybe<Scalars['String']['output']>;
  /** @deprecated Use name instead */
  buildingName: Scalars['String']['output'];
  buildingSizeUnit: Maybe<GQBuildingSizeUnit>;
  city: Maybe<Scalars['String']['output']>;
  companyId: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use countryAlpha2 instead */
  country: Maybe<Scalars['String']['output']>;
  countryAlpha2: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  postalCode: Maybe<Scalars['String']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  /** @deprecated Use buildingSizeUnit instead */
  sizeUnit: Maybe<GQBuildingSizeUnit>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  streetAddress2: Maybe<Scalars['String']['output']>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetRealEstateAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetRealEstateAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetRealEstateFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetRealEstateRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetSovereignBond = GQIFinanceAsset & {
  __typename?: 'AssetSovereignBond';
  assetComments: Array<GQAssetComment>;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  buildingSizeUnit: Maybe<GQBuildingSizeUnit>;
  companyId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  sovereignEntity: Scalars['String']['output'];
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetSovereignBondAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetSovereignBondAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetSovereignBondFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetSovereignBondRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export const GQAssetYearStatus = {
  Contracting: 'Contracting',
  Estimation: 'Estimation',
  Measurement: 'Measurement',
  Survey: 'Survey',
} as const;

export type GQAssetYearStatus =
  (typeof GQAssetYearStatus)[keyof typeof GQAssetYearStatus];
export type GQAssetsCorporateConnection = {
  __typename?: 'AssetsCorporateConnection';
  edges: Array<GQAssetsCorporateEdge>;
  pageInfo: GQPageInfo;
};

export type GQAssetsCorporateEdge = {
  __typename?: 'AssetsCorporateEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQAssetCorporate>;
};

export type GQAssignFacilitiesToTasksInput = {
  allowReassignment?: InputMaybe<Scalars['Boolean']['input']>;
  includeListBuildingIds: Array<Scalars['String']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  userUploadTaskIds: Array<Scalars['ID']['input']>;
};

export type GQAssignFacilitiesToTasksPayload = {
  __typename?: 'AssignFacilitiesToTasksPayload';
  facilitiesAssigned: Array<GQFacility>;
  facilitiesRequiringConfirmation: Array<GQFacility>;
  lockedDestinationTasks: Array<GQUserUploadTask>;
  lockedSourceTasks: Array<GQUserUploadTask>;
  measurementProject: GQMeasurementProject;
};

export type GQAssignMeasurementTaskInput = {
  assigneeIds: Array<Scalars['ID']['input']>;
  kind: Scalars['String']['input'];
  measurementTaskId: Scalars['ID']['input'];
  objectId: Scalars['String']['input'];
  permissions?: InputMaybe<Array<GQCreatePermissionItemInput>>;
};

export type GQAssignMeasurementTaskPayload = {
  __typename?: 'AssignMeasurementTaskPayload';
  measurementTask: GQMeasurementParentTask | GQMeasurementTaskFlat;
  organization: GQOrganization;
};

export type GQAssignReportQuestionContainerPreviewEmailInput = {
  assigneeIds: Array<Scalars['String']['input']>;
  reportId: Scalars['String']['input'];
  /** @deprecated Not used anymore, delete 2024-09-26 */
  reportObjectType?: InputMaybe<GQReportObjectType>;
  reportShortName: Scalars['String']['input'];
};

export type GQAssignToContainerInput = {
  assigneeId: Scalars['ID']['input'];
  containerId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
  reportShortName: Scalars['String']['input'];
  shouldSendEmail: Scalars['Boolean']['input'];
};

export type GQAssignToContainerPayload = {
  __typename?: 'AssignToContainerPayload';
  assignedCount: Scalars['Float']['output'];
  container: GQReportQuestionContainer;
};

export type GQAssignUserUploadTaskInput = {
  permissions?: InputMaybe<Array<GQCreatePermissionItemInput>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQAssignUserUploadTaskPayload = {
  __typename?: 'AssignUserUploadTaskPayload';
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQAssumptionChangeset = {
  __typename?: 'AssumptionChangeset';
  authors: Array<GQWatershedEmployee>;
  changesetType: Scalars['String']['output'];
  committedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  recordCount: Scalars['Int']['output'];
  records: Array<GQAssumptionRecordForAdmin>;
};

export type GQAssumptionDescription = {
  __typename?: 'AssumptionDescription';
  assumptionId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userVisibleConversionDescription: Scalars['String']['output'];
};

export type GQAssumptionRecordForAdmin = {
  __typename?: 'AssumptionRecordForAdmin';
  assumptionActivityType: Maybe<Scalars['String']['output']>;
  assumptionDescription: Maybe<GQAssumptionDescription>;
  assumptionId: Scalars['ID']['output'];
  assumptionSource: GQAssumptionSource;
  assumptionSourceId: Maybe<Scalars['String']['output']>;
  assumptionSourceMemo: Maybe<Scalars['String']['output']>;
  authorId: Scalars['ID']['output'];
  categoryId: Maybe<Scalars['String']['output']>;
  conversionDescription: Maybe<Scalars['String']['output']>;
  conversionMultiplier: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  endMonth: Maybe<Scalars['YearMonth']['output']>;
  excludeFromResults: Maybe<Scalars['Boolean']['output']>;
  ghgCategoryId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inputEmissionsSpecifier: Maybe<Scalars['String']['output']>;
  inputEmissionsSubspecifier: Maybe<Scalars['String']['output']>;
  inputUnit: Maybe<Scalars['String']['output']>;
  internalDedupCategory: Maybe<Scalars['String']['output']>;
  internalDedupDetail: Maybe<Scalars['String']['output']>;
  internalDedupSubcategory: Maybe<Scalars['String']['output']>;
  isEntry: Maybe<Scalars['Boolean']['output']>;
  isOutput: Maybe<Scalars['Boolean']['output']>;
  locationCity: Maybe<Scalars['String']['output']>;
  locationCountry: Maybe<Scalars['String']['output']>;
  locationGrid: Maybe<Scalars['String']['output']>;
  locationState: Maybe<Scalars['String']['output']>;
  methodologySourceId: Maybe<Scalars['String']['output']>;
  orgId: Maybe<Scalars['String']['output']>;
  outputEmissionsSpecifier: Maybe<Scalars['String']['output']>;
  outputEmissionsSubspecifier: Maybe<Scalars['String']['output']>;
  outputUnit: Maybe<Scalars['String']['output']>;
  passthrough: Maybe<Scalars['Boolean']['output']>;
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Maybe<Scalars['DateTime']['output']>;
  specificityRank: Maybe<Scalars['Int']['output']>;
  startMonth: Maybe<Scalars['YearMonth']['output']>;
  subcategoryId: Maybe<Scalars['String']['output']>;
  vendorEntity: Maybe<Scalars['String']['output']>;
  vendorSubentity: Maybe<Scalars['String']['output']>;
};

export type GQAssumptionRecordForAdminConnection = {
  __typename?: 'AssumptionRecordForAdminConnection';
  edges: Array<Maybe<GQAssumptionRecordForAdminEdge>>;
  pageInfo: GQPageInfo;
};

export type GQAssumptionRecordForAdminEdge = {
  __typename?: 'AssumptionRecordForAdminEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQAssumptionRecordForAdmin>;
};

export type GQAssumptionRecordForDashboard = {
  __typename?: 'AssumptionRecordForDashboard';
  assumptionDescription: Maybe<GQAssumptionDescription>;
  assumptionSource: GQAssumptionSource;
  assumptionSourceMemo: Maybe<Scalars['String']['output']>;
  conversionDescription: Maybe<Scalars['String']['output']>;
  conversionMultiplier: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  endMonth: Maybe<Scalars['YearMonth']['output']>;
  id: Scalars['ID']['output'];
  inputEmissionsSpecifier: Maybe<Scalars['String']['output']>;
  inputEmissionsSubspecifier: Maybe<Scalars['String']['output']>;
  inputUnit: Maybe<Scalars['String']['output']>;
  methodologySourceId: Maybe<Scalars['String']['output']>;
  outputEmissionsSpecifier: Maybe<Scalars['String']['output']>;
  outputEmissionsSubspecifier: Maybe<Scalars['String']['output']>;
  outputUnit: Maybe<Scalars['String']['output']>;
  startMonth: Maybe<Scalars['YearMonth']['output']>;
};

export type GQAssumptionRecordForDashboardConnection = {
  __typename?: 'AssumptionRecordForDashboardConnection';
  edges: Array<Maybe<GQAssumptionRecordForDashboardEdge>>;
  pageInfo: GQPageInfo;
};

export type GQAssumptionRecordForDashboardEdge = {
  __typename?: 'AssumptionRecordForDashboardEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQAssumptionRecordForDashboard>;
};

export type GQAssumptionSource = {
  __typename?: 'AssumptionSource';
  authorId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  externalSourceNotes: Maybe<Scalars['String']['output']>;
  externalSourceUrl: Maybe<Scalars['String']['output']>;
  hasMultiplierLicenseRestrictions: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sourceNotes: Maybe<Scalars['String']['output']>;
  sourceUrl: Scalars['String']['output'];
  userVisibleSourceUrl: Maybe<Scalars['String']['output']>;
};

export type GQAttachmentMetadata = {
  __typename?: 'AttachmentMetadata';
  fileMetadataId: Scalars['ID']['output'];
  signedUrl: Scalars['String']['output'];
};

export type GQAuditLogConnection = {
  __typename?: 'AuditLogConnection';
  edges: Array<Maybe<GQAuditLogEdge>>;
  facets: GQAuditLogFacets;
  pageInfo: GQPageInfo;
};

export type GQAuditLogEdge = {
  __typename?: 'AuditLogEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQAuditLogEntry>;
};

export type GQAuditLogEntry = {
  __typename?: 'AuditLogEntry';
  appType: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  eventKind: Scalars['String']['output'];
  eventMetadata: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Possibly sensitive data, use resolved fields instead. Remove EOW Aug 14 */
  loggingContext: Maybe<Scalars['JSONString']['output']>;
  userDisplayName: Maybe<Scalars['String']['output']>;
};

export type GQAuditLogFacets = {
  __typename?: 'AuditLogFacets';
  eventKinds: Array<Scalars['String']['output']>;
};

export type GQAuditReportColumn = {
  __typename?: 'AuditReportColumn';
  column: Scalars['String']['output'];
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQAuditReportMetadataInput = {
  footprintEmissionsKind: GQFootprintEmissionsKind;
};

export type GQAuditReportMetadataPayload = {
  __typename?: 'AuditReportMetadataPayload';
  columns: Array<GQAuditReportColumn>;
};

export const GQAutoBartKind = {
  BuildingList: 'BuildingList',
  FileUpload: 'FileUpload',
} as const;

export type GQAutoBartKind =
  (typeof GQAutoBartKind)[keyof typeof GQAutoBartKind];
export const GQAutoBartTaskApprovalState = {
  Approved: 'Approved',
  BuildingsSdi: 'BuildingsSdi',
  Error: 'Error',
  FacilitiesList: 'FacilitiesList',
  NeedsApproval: 'NeedsApproval',
  PreBiodiversityTransform: 'PreBiodiversityTransform',
  PreMapping: 'PreMapping',
  Unknown: 'Unknown',
} as const;

export type GQAutoBartTaskApprovalState =
  (typeof GQAutoBartTaskApprovalState)[keyof typeof GQAutoBartTaskApprovalState];
export type GQAutosaveEmissionsModelInput = {
  emissionsModelJson: Scalars['JSONString']['input'];
  matchers: Array<GQEmissionsModelMatcherInput>;
  parameterResolverJson: Scalars['JSONString']['input'];
  parentEmissionsModelVersionId: Scalars['ID']['input'];
};

export type GQAutosaveEmissionsModelPayload =
  | GQAutosaveEmissionsModelPayloadBlocked
  | GQAutosaveEmissionsModelPayloadError
  | GQAutosaveEmissionsModelPayloadOk;

export type GQAutosaveEmissionsModelPayloadBlocked = {
  __typename?: 'AutosaveEmissionsModelPayloadBlocked';
  authorId: Scalars['ID']['output'];
  authorName: Scalars['String']['output'];
  autosavedAt: Scalars['DateTime']['output'];
};

export type GQAutosaveEmissionsModelPayloadError = {
  __typename?: 'AutosaveEmissionsModelPayloadError';
  error: GQSerializableError;
};

export type GQAutosaveEmissionsModelPayloadOk = {
  __typename?: 'AutosaveEmissionsModelPayloadOk';
  emissionsModelActiveDraftRecord: GQEmissionsModelActiveDraft;
};

export type GQBackgroundJob = GQIdInterface & {
  __typename?: 'BackgroundJob';
  args: Maybe<Scalars['JSONString']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator: Maybe<GQUser | GQWatershedEmployee>;
  durationSeconds: Maybe<Scalars['Float']['output']>;
  error: Maybe<GQSerializableError>;
  finishedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  queueName: GQBackgroundJobQueueName;
  remainingPreemptions: Maybe<Scalars['Int']['output']>;
  result: Maybe<Scalars['JSONString']['output']>;
  state: GQBackgroundJobState;
  /** @deprecated Use 'creator' instead */
  user: Maybe<GQUser>;
};

export type GQBackgroundJobEntry = GQIdInterface & {
  __typename?: 'BackgroundJobEntry';
  args: Maybe<Scalars['JSONString']['output']>;
  createdAt: Scalars['DateTime']['output'];
  error: Maybe<GQSerializableError>;
  finishedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  orgId: Maybe<Scalars['String']['output']>;
  queueName: Scalars['String']['output'];
  result: Maybe<Scalars['JSONString']['output']>;
  state: GQBackgroundJobState;
  user: Maybe<GQUser>;
};

export const GQBackgroundJobQueueName = {
  FinanceEstimations: 'financeEstimations',
  UserWaiting: 'userWaiting',
} as const;

export type GQBackgroundJobQueueName =
  (typeof GQBackgroundJobQueueName)[keyof typeof GQBackgroundJobQueueName];
export const GQBackgroundJobState = {
  Done: 'DONE',
  Error: 'ERROR',
  Failed: 'FAILED',
  Processing: 'PROCESSING',
  Queued: 'QUEUED',
} as const;

export type GQBackgroundJobState =
  (typeof GQBackgroundJobState)[keyof typeof GQBackgroundJobState];
export type GQBackgroundJobStatus = GQIdInterface & {
  __typename?: 'BackgroundJobStatus';
  error: Maybe<GQSerializableError>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  state: GQBackgroundJobState;
};

export type GQBartColumnDetails = {
  __typename?: 'BartColumnDetails';
  columnName: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  emissionsModelEvaluationId: Scalars['String']['output'];
};

export type GQBatchSetCustomFieldForBuildingsInput = {
  buildingIds: Array<Scalars['ID']['input']>;
  ingestionCustomFieldId: Scalars['ID']['input'];
  ingestionCustomFieldValue: Scalars['String']['input'];
};

export type GQBatchSetCustomFieldForBuildingsPayload = {
  __typename?: 'BatchSetCustomFieldForBuildingsPayload';
  buildingsUntyped: Array<GQBuildingUntyped>;
};

export type GQBatchSetCustomFieldForDatasourcesInput = {
  datasourceIds: Array<Scalars['ID']['input']>;
  ingestionCustomFieldId: Scalars['ID']['input'];
  ingestionCustomFieldValue: Scalars['String']['input'];
};

export type GQBatchSetCustomFieldForDatasourcesPayload = {
  __typename?: 'BatchSetCustomFieldForDatasourcesPayload';
  datasources: Array<GQDatasource>;
};

export type GQBatchUnsetCustomFieldForBuildingsInput = {
  buildingIds: Array<Scalars['ID']['input']>;
  ingestionCustomFieldId: Scalars['ID']['input'];
};

export type GQBatchUnsetCustomFieldForBuildingsPayload = {
  __typename?: 'BatchUnsetCustomFieldForBuildingsPayload';
  buildingsUntyped: Array<GQBuildingUntyped>;
};

export type GQBatchUnsetCustomFieldForDatasourcesInput = {
  datasourceIds: Array<Scalars['ID']['input']>;
  ingestionCustomFieldId: Scalars['ID']['input'];
};

export type GQBatchUnsetCustomFieldForDatasourcesPayload = {
  __typename?: 'BatchUnsetCustomFieldForDatasourcesPayload';
  datasources: Array<GQDatasource>;
};

export type GQBatsByCalculationTagNamesPayload = {
  __typename?: 'BatsByCalculationTagNamesPayload';
  businessActivityTypes: Array<Scalars['String']['output']>;
  tagId: Scalars['ID']['output'];
  tagName: Scalars['String']['output'];
};

export type GQBenchmark = {
  __typename?: 'Benchmark';
  amountKgco2e: Scalars['Int53']['output'];
  companyId: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  industries: Array<Scalars['String']['output']>;
  kgco2eByScope: Array<GQBenchmarkScopeBreakdownDataPoint>;
  kind: GQBenchmarkKind;
  name: Scalars['String']['output'];
  normalizationKind: GQAggregateKind;
  publishingYear: Scalars['Int']['output'];
  qualityScore: Maybe<GQDisclosureQualityScore>;
  reportId: Maybe<Scalars['String']['output']>;
  reportingYear: Scalars['Int']['output'];
  scope2Source: Maybe<GQScope2Source>;
  source: Maybe<GQExternalReportType>;
};

export type GQBenchmarkBreakdownDataPoint = {
  __typename?: 'BenchmarkBreakdownDataPoint';
  businessCategory: Scalars['String']['output'];
  kgco2e: Scalars['Int']['output'];
};

export const GQBenchmarkKind = {
  /** Another business. */
  Business: 'Business',
  IndustryAverage: 'IndustryAverage',
  /** The active organization itself. */
  Self: 'Self',
} as const;

export type GQBenchmarkKind =
  (typeof GQBenchmarkKind)[keyof typeof GQBenchmarkKind];
export type GQBenchmarkScopeBreakdownDataPoint = {
  __typename?: 'BenchmarkScopeBreakdownDataPoint';
  kgco2e: Maybe<Scalars['Float']['output']>;
  scope: GQGhgScope;
};

export type GQBiActivityDataQueryContextInput = {
  businessActivityType?: InputMaybe<Scalars['String']['input']>;
  extBartInstanceId?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
};

export type GQBiActivityQueryContextInput = {
  businessActivityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  datasourceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  filterSchemaToBartsMatchingFilter?: InputMaybe<Scalars['Boolean']['input']>;
  measurementProjectIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId: Scalars['String']['input'];
};

export const GQBiAggregateMethod = {
  Avg: 'avg',
  CountDistinct: 'countDistinct',
  MonthlyAvg: 'monthlyAvg',
  Sum: 'sum',
} as const;

export type GQBiAggregateMethod =
  (typeof GQBiAggregateMethod)[keyof typeof GQBiAggregateMethod];
export type GQBiAiQueryInput = {
  queryContext: GQBiQueryContextInput;
  queryMetadata: GQBiAiQueryMetadata;
  userQuery: Scalars['String']['input'];
};

export type GQBiAiQueryMetadata = {
  availableDimensionsMetadata: Array<GQBiAiQueryAvailableDimensionsMetadata>;
  availableMetricsMetadata: Array<GQBiAiQueryAvailableMetricMetadata>;
  maxDateRange: Scalars['YMInterval']['input'];
};

export type GQBiAiQueryPayload = {
  __typename?: 'BiAiQueryPayload';
  dimensions: Array<Scalars['String']['output']>;
  filters: Array<GQBiQueryFilter>;
  normalizedMeasureId: Scalars['String']['output'];
  timeIntervals: Array<Scalars['YMInterval']['output']>;
};

export type GQBiAiQueryResponse = {
  __typename?: 'BiAiQueryResponse';
  payload: Maybe<GQBiAiQueryPayload>;
};

export type GQBiBaseFieldMeta = {
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  emptyStringType: Maybe<GQBiEmptyStringType>;
  fieldFamily: Maybe<Scalars['String']['output']>;
  fieldId: Scalars['String']['output'];
  hiddenInFilters: Maybe<Scalars['Boolean']['output']>;
  hiddenInGroupBy: Maybe<Scalars['Boolean']['output']>;
  isCustomField: Maybe<Scalars['Boolean']['output']>;
  isMultiValue: Maybe<Scalars['Boolean']['output']>;
};

export const GQBiChartKind = {
  BarHorizontal: 'BarHorizontal',
  BarVertical: 'BarVertical',
  Meter: 'Meter',
  Pie: 'Pie',
  TimeSeries: 'TimeSeries',
  Treemap: 'Treemap',
} as const;

export type GQBiChartKind = (typeof GQBiChartKind)[keyof typeof GQBiChartKind];
export type GQBiCustomMetric = {
  __typename?: 'BiCustomMetric';
  creator: GQUser;
  expression: Scalars['Expression']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
  stableId: Scalars['ID']['output'];
};

export type GQBiCustomMetricRef = {
  __typename?: 'BiCustomMetricRef';
  id: Maybe<Scalars['String']['output']>;
  stableId: Scalars['String']['output'];
};

export type GQBiCustomMetricRefInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  stableId: Scalars['String']['input'];
};

export const GQBiDataFormat = {
  Columnar: 'Columnar',
} as const;

export type GQBiDataFormat =
  (typeof GQBiDataFormat)[keyof typeof GQBiDataFormat];
export type GQBiDimensionFieldMeta =
  | GQBiFieldBooleanMeta
  | GQBiFieldDateMeta
  | GQBiFieldNumberMeta
  | GQBiFieldStringMeta
  | GQBiFieldYearMonthMeta;

export type GQBiDimensionLimitInput = {
  dimension: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
};

export type GQBiDimensionListOptionItem = {
  __typename?: 'BiDimensionListOptionItem';
  label: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['UntypedData']['output']>;
};

export type GQBiDimensionListOptionsInput = {
  dimension: Scalars['String']['input'];
  filters?: InputMaybe<Array<GQBiQueryFilterInput>>;
  queryContext: GQBiQueryContextInput;
  search?: InputMaybe<Scalars['String']['input']>;
  timeInterval?: InputMaybe<Scalars['YMInterval']['input']>;
};

export type GQBiDimensionListOptionsResult = {
  __typename?: 'BiDimensionListOptionsResult';
  id: Scalars['String']['output'];
  listOptionItems: Array<GQBiDimensionListOptionItem>;
};

export type GQBiDimensionMeta = {
  __typename?: 'BiDimensionMeta';
  fieldMeta: GQBiDimensionFieldMeta;
};

export type GQBiDisplayValueMapping = {
  __typename?: 'BiDisplayValueMapping';
  dimension: Scalars['String']['output'];
  listOptionItems: Array<GQBiDimensionListOptionItem>;
};

export type GQBiDynamicNormalizedMeasureInput = {
  baseMeasure: Scalars['String']['input'];
  normalizingQuery?: InputMaybe<GQBiDynamicNormalizingQueryInput>;
};

export type GQBiDynamicNormalizingMeasure = {
  __typename?: 'BiDynamicNormalizingMeasure';
  baseFilters: Maybe<Array<GQBiQueryFilter>>;
  baseMeasure: Scalars['String']['output'];
  normalizingQuery: Maybe<GQBiDynamicNormalizingQuery>;
};

export type GQBiDynamicNormalizingQuery = {
  __typename?: 'BiDynamicNormalizingQuery';
  computedDimensionType: Maybe<GQDimensionComputedType>;
  computedFiltersType: Maybe<GQDimensionComputedType>;
  dimensions: Maybe<Array<Scalars['String']['output']>>;
  filters: Maybe<Array<GQBiQueryFilter>>;
  measure: Scalars['String']['output'];
};

export type GQBiDynamicNormalizingQueryInput = {
  computedDimensionType?: InputMaybe<GQDimensionComputedType>;
  computedFiltersType?: InputMaybe<GQDimensionComputedType>;
  dimensions?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<Array<GQBiQueryFilterInput>>;
  measure: Scalars['String']['input'];
};

export const GQBiEmptyStringType = {
  Emdash: 'Emdash',
  Empty: 'Empty',
  UnspecifiedWithDisplayName: 'UnspecifiedWithDisplayName',
} as const;

export type GQBiEmptyStringType =
  (typeof GQBiEmptyStringType)[keyof typeof GQBiEmptyStringType];
export type GQBiFieldBooleanMeta = GQBiBaseFieldMeta & {
  __typename?: 'BiFieldBooleanMeta';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  emptyStringType: Maybe<GQBiEmptyStringType>;
  fieldFamily: Maybe<Scalars['String']['output']>;
  fieldId: Scalars['String']['output'];
  hiddenInFilters: Maybe<Scalars['Boolean']['output']>;
  hiddenInGroupBy: Maybe<Scalars['Boolean']['output']>;
  isCustomField: Maybe<Scalars['Boolean']['output']>;
  isMultiValue: Maybe<Scalars['Boolean']['output']>;
};

export type GQBiFieldDateMeta = GQBiBaseFieldMeta & {
  __typename?: 'BiFieldDateMeta';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  emptyStringType: Maybe<GQBiEmptyStringType>;
  fieldFamily: Maybe<Scalars['String']['output']>;
  fieldId: Scalars['String']['output'];
  format: Maybe<GQDateTimeFormatOptions>;
  hiddenInFilters: Maybe<Scalars['Boolean']['output']>;
  hiddenInGroupBy: Maybe<Scalars['Boolean']['output']>;
  isCustomField: Maybe<Scalars['Boolean']['output']>;
  isMultiValue: Maybe<Scalars['Boolean']['output']>;
};

export type GQBiFieldMeta =
  | GQBiFieldBooleanMeta
  | GQBiFieldDateMeta
  | GQBiFieldNumberMeta
  | GQBiFieldStringMeta
  | GQBiFieldYearMonthMeta;

export type GQBiFieldNumberMeta = GQBiBaseFieldMeta & {
  __typename?: 'BiFieldNumberMeta';
  aggregateType: Maybe<GQBiAggregateMethod>;
  aggregatedUnitDimension: Maybe<Scalars['String']['output']>;
  /** @deprecated Use aggregatedUnitDimension instead as Feb 1 2024 */
  dependentDimensions: Maybe<Array<Scalars['String']['output']>>;
  description: Maybe<Scalars['String']['output']>;
  disableCommas: Maybe<Scalars['Boolean']['output']>;
  displayName: Scalars['String']['output'];
  divideBy: Maybe<Scalars['Int']['output']>;
  emptyStringType: Maybe<GQBiEmptyStringType>;
  fieldFamily: Maybe<Scalars['String']['output']>;
  fieldId: Scalars['String']['output'];
  hiddenInFilters: Maybe<Scalars['Boolean']['output']>;
  hiddenInGroupBy: Maybe<Scalars['Boolean']['output']>;
  includeUnit: Maybe<Scalars['Boolean']['output']>;
  isCurrency: Maybe<Scalars['Boolean']['output']>;
  isCustomField: Maybe<Scalars['Boolean']['output']>;
  isMultiValue: Maybe<Scalars['Boolean']['output']>;
  precision: Maybe<Scalars['Int']['output']>;
  shouldFormatToPercent: Maybe<Scalars['Boolean']['output']>;
  unit: Maybe<Scalars['String']['output']>;
  unitDimension: Maybe<Scalars['String']['output']>;
  unitUserFacing: Maybe<Scalars['String']['output']>;
};

export type GQBiFieldStringMeta = GQBiBaseFieldMeta & {
  __typename?: 'BiFieldStringMeta';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  emptyStringType: Maybe<GQBiEmptyStringType>;
  fieldFamily: Maybe<Scalars['String']['output']>;
  fieldId: Scalars['String']['output'];
  hiddenInFilters: Maybe<Scalars['Boolean']['output']>;
  hiddenInGroupBy: Maybe<Scalars['Boolean']['output']>;
  isCustomField: Maybe<Scalars['Boolean']['output']>;
  isMultiValue: Maybe<Scalars['Boolean']['output']>;
  knownDisplayValueMappingType: Maybe<GQBiKnownDisplayValueMappingType>;
  shouldNullifyPseudoNulls: Maybe<Scalars['Boolean']['output']>;
  shouldSentenceCaseValues: Scalars['Boolean']['output'];
  staticListOptions: Maybe<Array<GQBiDimensionListOptionItem>>;
};

export type GQBiFieldYearMonthMeta = GQBiBaseFieldMeta & {
  __typename?: 'BiFieldYearMonthMeta';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  emptyStringType: Maybe<GQBiEmptyStringType>;
  fieldFamily: Maybe<Scalars['String']['output']>;
  fieldId: Scalars['String']['output'];
  format: Maybe<GQDateTimeFormatOptions>;
  hiddenInFilters: Maybe<Scalars['Boolean']['output']>;
  hiddenInGroupBy: Maybe<Scalars['Boolean']['output']>;
  isCustomField: Maybe<Scalars['Boolean']['output']>;
  isMultiValue: Maybe<Scalars['Boolean']['output']>;
};

export type GQBiFilterDisplayValueMappingsInput = {
  filters: Array<GQBiQueryFilterInput>;
  queryContext: GQBiQueryContextInput;
};

export const GQBiFilterOperator = {
  Empty: 'Empty',
  Equal: 'Equal',
  GreaterThan: 'GreaterThan',
  GreaterThanOrEqual: 'GreaterThanOrEqual',
  In: 'In',
  LessThan: 'LessThan',
  LessThanOrEqual: 'LessThanOrEqual',
  NotEmpty: 'NotEmpty',
  NotEqual: 'NotEqual',
  NotIn: 'NotIn',
} as const;

export type GQBiFilterOperator =
  (typeof GQBiFilterOperator)[keyof typeof GQBiFilterOperator];
export type GQBiFinanceQueryContextInput = {
  assetId?: InputMaybe<Scalars['String']['input']>;
  financeSnapshotId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  year?: InputMaybe<Scalars['Int']['input']>;
};

export const GQBiKnownDisplayValueMappingType = {
  CountryCode: 'CountryCode',
  LabelMappingsNew: 'LabelMappingsNew',
} as const;

export type GQBiKnownDisplayValueMappingType =
  (typeof GQBiKnownDisplayValueMappingType)[keyof typeof GQBiKnownDisplayValueMappingType];
export type GQBiMeasureFieldMeta = GQBiFieldNumberMeta;

export type GQBiMeasureMeta = {
  __typename?: 'BiMeasureMeta';
  fieldMeta: GQBiMeasureFieldMeta;
  supportedDimensions: Array<Scalars['String']['output']>;
};

export type GQBiNormalizedMeasure = {
  __typename?: 'BiNormalizedMeasure';
  baseMeasure: Scalars['String']['output'];
  fieldId: Scalars['String']['output'];
  normalizingQuery: Maybe<GQBiNormalizingQuery>;
};

export type GQBiNormalizedMeasureInput = {
  baseMeasure: Scalars['String']['input'];
  fieldId: Scalars['String']['input'];
  normalizingQuery?: InputMaybe<GQBiNormalizingQueryInput>;
};

export type GQBiNormalizingQuery = {
  __typename?: 'BiNormalizingQuery';
  dimensions: Array<Scalars['String']['output']>;
  filters: Array<GQBiQueryFilter>;
  measure: Scalars['String']['output'];
};

export type GQBiNormalizingQueryInput = {
  dimensions: Array<Scalars['String']['input']>;
  exampleFields?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<Array<GQBiQueryFilterInput>>;
  measure: Scalars['String']['input'];
};

export type GQBiPostgresToActivityDataPayload = {
  __typename?: 'BiPostgresToActivityDataPayload';
  isFacilitiesDataStale: Scalars['Boolean']['output'];
};

export type GQBiQueryComponentsInput = {
  dimensionLimits?: InputMaybe<Array<GQBiDimensionLimitInput>>;
  dimensions: Array<Scalars['String']['input']>;
  exampleFields?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<Array<GQBiQueryFilterInput>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  normalizedMeasures: Array<GQBiNormalizedMeasureInput>;
  order?: InputMaybe<Array<GQBiQueryOrderInput>>;
  select?: InputMaybe<Array<Scalars['Expression']['input']>>;
  timeInterval: Scalars['YMInterval']['input'];
};

export type GQBiQueryContextInput = {
  activity?: InputMaybe<GQBiActivityQueryContextInput>;
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  finance?: InputMaybe<GQBiFinanceQueryContextInput>;
  mode: GQBiQueryMode;
  snapshot?: InputMaybe<GQBiSnapshotQueryContextInput>;
};

export type GQBiQueryDataInput = {
  queryComponents: GQBiQueryComponentsInput;
  queryContext: GQBiQueryContextInput;
};

export type GQBiQueryDataMultipleInput = {
  inputs: Array<GQBiQueryDataInput>;
};

export type GQBiQueryDataResult = {
  __typename?: 'BiQueryDataResult';
  data: Scalars['BiDataResult']['output'];
  displayValueMappings: Maybe<Array<GQBiDisplayValueMapping>>;
  id: Scalars['String']['output'];
  warnings: Maybe<Array<GQBiWarning>>;
};

export type GQBiQueryFilter = {
  __typename?: 'BiQueryFilter';
  dimension: Scalars['String']['output'];
  operator: GQBiFilterOperator;
  value: Array<Maybe<Scalars['FilterValue']['output']>>;
};

export type GQBiQueryFilterInput = {
  dimension: Scalars['String']['input'];
  operator: GQBiFilterOperator;
  value: Array<InputMaybe<Scalars['FilterValue']['input']>>;
};

export type GQBiQueryMetadataInput = {
  queryContext: GQBiQueryContextInput;
};

export type GQBiQueryMetadataResult = {
  __typename?: 'BiQueryMetadataResult';
  dimensions: Array<GQBiDimensionMeta>;
  id: Scalars['String']['output'];
  measures: Array<GQBiMeasureMeta>;
  schemaNames: Maybe<Array<Scalars['String']['output']>>;
  timeInterval: Maybe<Scalars['YMInterval']['output']>;
};

export const GQBiQueryMode = {
  Activity: 'Activity',
  Finance: 'Finance',
  FinanceChangelog: 'FinanceChangelog',
  Snapshot: 'Snapshot',
} as const;

export type GQBiQueryMode = (typeof GQBiQueryMode)[keyof typeof GQBiQueryMode];
export type GQBiQueryOrder = {
  __typename?: 'BiQueryOrder';
  dimensionOrMeasure: Scalars['String']['output'];
  direction: GQBiQueryOrderDirection;
};

export const GQBiQueryOrderDirection = {
  Asc: 'Asc',
  Desc: 'Desc',
} as const;

export type GQBiQueryOrderDirection =
  (typeof GQBiQueryOrderDirection)[keyof typeof GQBiQueryOrderDirection];
export type GQBiQueryOrderInput = {
  dimensionOrMeasure: Scalars['String']['input'];
  direction?: InputMaybe<GQBiQueryOrderDirection>;
};

export type GQBiQuerySingleMeasureSelectorInput = {
  filters?: InputMaybe<Array<GQBiQueryFilterInput>>;
  footprintKind: Scalars['String']['input'];
  measure: Scalars['String']['input'];
  timeInterval: Scalars['YMInterval']['input'];
};

export type GQBiSavedView = {
  __typename?: 'BiSavedView';
  chartKind: GQBiChartKind;
  createdAt: Scalars['DateTime']['output'];
  customMetrics: Maybe<Array<GQBiCustomMetricRef>>;
  dimensions: Array<Scalars['String']['output']>;
  editVisibility: GQBiSavedViewVisibility;
  editor: GQUser;
  filters: Array<GQBiQueryFilter>;
  id: Scalars['ID']['output'];
  jsonSchemaVersion: Scalars['String']['output'];
  name: Scalars['String']['output'];
  normalizedMeasures: Array<GQBiDynamicNormalizingMeasure>;
  order: Maybe<GQBiQueryOrder>;
  org: GQOrganization;
  owner: GQUser;
  queryContext: Maybe<GQBiSavedViewQueryContext>;
  searchTerm: Maybe<Scalars['String']['output']>;
  stableId: Scalars['ID']['output'];
  timeIntervals: Array<Scalars['YMInterval']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  viewVisibility: GQBiSavedViewVisibility;
};

export type GQBiSavedViewQueryContext = GQBiSavedViewSnapshotQueryContext;

export type GQBiSavedViewQueryContextInput = {
  mode: GQBiQueryMode;
  snapshot?: InputMaybe<GQBiSavedViewSnapshotQueryContextInput>;
};

export type GQBiSavedViewSnapshotQueryContext = {
  __typename?: 'BiSavedViewSnapshotQueryContext';
  footprintKind: Scalars['String']['output'];
};

export type GQBiSavedViewSnapshotQueryContextInput = {
  footprintKind: Scalars['String']['input'];
};

export const GQBiSavedViewVisibility = {
  Org: 'Org',
  Private: 'Private',
} as const;

export type GQBiSavedViewVisibility =
  (typeof GQBiSavedViewVisibility)[keyof typeof GQBiSavedViewVisibility];
export type GQBiSavedViewsInput = {
  mode: GQBiQueryMode;
};

export type GQBiSnapshotQueryContextInput = {
  footprintKind?: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: Scalars['String']['input'];
  includeActivityData?: InputMaybe<Scalars['Boolean']['input']>;
  includeDedupedRows?: InputMaybe<Scalars['Boolean']['input']>;
  includeIntensityData?: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['String']['input'];
};

export type GQBiWarning = {
  __typename?: 'BiWarning';
  learnMoreLink: Maybe<Scalars['String']['output']>;
  warningMessage: Scalars['String']['output'];
  warningType: GQBiWarningType;
};

export const GQBiWarningType = {
  LegacyCadtData: 'LegacyCadtData',
} as const;

export type GQBiWarningType =
  (typeof GQBiWarningType)[keyof typeof GQBiWarningType];
export type GQBuildingCompletionIntervals = GQIdInterface & {
  __typename?: 'BuildingCompletionIntervals';
  data: Array<GQBuildingCompletionIntervalsData>;
  id: Scalars['ID']['output'];
};

export type GQBuildingCompletionIntervalsData = {
  __typename?: 'BuildingCompletionIntervalsData';
  fuelKind: Maybe<GQStationaryCombustionFuelKind>;
  hasOnsiteCleanElectricity: Maybe<Scalars['Boolean']['output']>;
  periodsWithActuals: Array<GQDateTimeInterval>;
  periodsWithSupportingDocs: Array<GQDateTimeInterval>;
  utilityType: GQBuildingUtilityType;
};

export type GQBuildingCompletionIntervalsUntyped = GQIdInterface & {
  __typename?: 'BuildingCompletionIntervalsUntyped';
  data: Scalars['UntypedData']['output'];
  id: Scalars['ID']['output'];
};

export type GQBuildingDiffError = {
  __typename?: 'BuildingDiffError';
  adminMessage: Scalars['String']['output'];
  code: GQBuildingDiffErrorCode;
  message: Scalars['String']['output'];
};

export const GQBuildingDiffErrorCode = {
  ForbiddenError: 'ForbiddenError',
  TimeIntersectionInData: 'TimeIntersectionInData',
} as const;

export type GQBuildingDiffErrorCode =
  (typeof GQBuildingDiffErrorCode)[keyof typeof GQBuildingDiffErrorCode];
export type GQBuildingDiffWarning = {
  __typename?: 'BuildingDiffWarning';
  adminMessage: Scalars['String']['output'];
  code: GQBuildingDiffWarningCode;
  message: Scalars['String']['output'];
};

export const GQBuildingDiffWarningCode = {
  KeyInfoChangeDetected: 'KeyInfoChangeDetected',
  TimeGapInData: 'TimeGapInData',
} as const;

export type GQBuildingDiffWarningCode =
  (typeof GQBuildingDiffWarningCode)[keyof typeof GQBuildingDiffWarningCode];
export const GQBuildingEditState = {
  Full: 'Full',
  NeedsConfirmation: 'NeedsConfirmation',
  Partial: 'Partial',
} as const;

export type GQBuildingEditState =
  (typeof GQBuildingEditState)[keyof typeof GQBuildingEditState];
export type GQBuildingEstimate = GQIdInterface & {
  __typename?: 'BuildingEstimate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  modelInput: Maybe<Scalars['JSONString']['output']>;
  modelVersion: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  rawOutputFileMetadataId: Scalars['ID']['output'];
  scope1Kgco2e: Scalars['Float']['output'];
  scope2Kgco2e: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQBuildingFacilitiesTaskFilter = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQBuildingForDiff = GQBuildingInterface & {
  __typename?: 'BuildingForDiff';
  buildingKind: Maybe<Scalars['String']['output']>;
  buildingName: Scalars['String']['output'];
  buildingSubkind: Maybe<Scalars['String']['output']>;
  buildingUniqueId: Scalars['String']['output'];
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  /** @deprecated Moved to BuildingPeriod */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  isWithoutNaturalGas: Maybe<Scalars['Boolean']['output']>;
  isWithoutRefrigerants: Maybe<Scalars['Boolean']['output']>;
  leaseType: Maybe<GQBuildingLeaseType>;
  periods: Array<GQBuildingPeriodForDiff>;
  postalCode: Maybe<Scalars['String']['output']>;
  revision: Maybe<Scalars['Int']['output']>;
  sizeUnit: Maybe<GQBuildingSizeUnit>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  streetAddress2: Maybe<Scalars['String']['output']>;
};

export type GQBuildingInterface = {
  buildingKind: Maybe<Scalars['String']['output']>;
  buildingName: Scalars['String']['output'];
  buildingSubkind: Maybe<Scalars['String']['output']>;
  buildingUniqueId: Scalars['String']['output'];
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  isWithoutNaturalGas: Maybe<Scalars['Boolean']['output']>;
  isWithoutRefrigerants: Maybe<Scalars['Boolean']['output']>;
  leaseType: Maybe<GQBuildingLeaseType>;
  periods: Array<GQBuildingPeriod | GQBuildingPeriodForDiff>;
  postalCode: Maybe<Scalars['String']['output']>;
  revision: Maybe<Scalars['Int']['output']>;
  sizeUnit: Maybe<GQBuildingSizeUnit>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  streetAddress2: Maybe<Scalars['String']['output']>;
};

export const GQBuildingLeaseType = {
  CoworkingOrServicedOffice: 'CoworkingOrServicedOffice',
  DownstreamLeaseNotPayingUtilities: 'DownstreamLeaseNotPayingUtilities',
  DownstreamLeasePayingUtilities: 'DownstreamLeasePayingUtilities',
  DownstreamSubleaseNotPayingUtilities: 'DownstreamSubleaseNotPayingUtilities',
  DownstreamSubleasePayingUtilities: 'DownstreamSubleasePayingUtilities',
  Franchise: 'Franchise',
  Leased: 'Leased',
  Owned: 'Owned',
  SoldBuilding: 'SoldBuilding',
  Unknown: 'Unknown',
  UpstreamSublease: 'UpstreamSublease',
} as const;

export type GQBuildingLeaseType =
  (typeof GQBuildingLeaseType)[keyof typeof GQBuildingLeaseType];
export type GQBuildingPeriod = GQBuildingPeriodInterface & {
  __typename?: 'BuildingPeriod';
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  customFieldId: Maybe<Scalars['String']['output']>;
  customFieldValue: Maybe<Scalars['String']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  id: Scalars['ID']['output'];
  isBuildingEmpty: Maybe<Scalars['Boolean']['output']>;
  kind: GQBuildingPeriodKind;
  orgUnitStableId: Maybe<Scalars['String']['output']>;
  orgUnitTypeStableId: Maybe<Scalars['String']['output']>;
  partialCleanPowerPercent: Maybe<Scalars['Float']['output']>;
  powerUsageEffectiveness: Maybe<Scalars['Float']['output']>;
  sizeQuantity: Maybe<Scalars['Float']['output']>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
};

export type GQBuildingPeriodForDiff = GQBuildingPeriodInterface & {
  __typename?: 'BuildingPeriodForDiff';
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  customFieldId: Maybe<Scalars['String']['output']>;
  customFieldValue: Maybe<Scalars['String']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  isBuildingEmpty: Maybe<Scalars['Boolean']['output']>;
  kind: GQBuildingPeriodKind;
  orgUnitStableId: Maybe<Scalars['String']['output']>;
  orgUnitTypeStableId: Maybe<Scalars['String']['output']>;
  partialCleanPowerPercent: Maybe<Scalars['Float']['output']>;
  powerUsageEffectiveness: Maybe<Scalars['Float']['output']>;
  sizeQuantity: Maybe<Scalars['Float']['output']>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
};

export type GQBuildingPeriodInterface = {
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  customFieldId: Maybe<Scalars['String']['output']>;
  customFieldValue: Maybe<Scalars['String']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  isBuildingEmpty: Maybe<Scalars['Boolean']['output']>;
  kind: GQBuildingPeriodKind;
  orgUnitStableId: Maybe<Scalars['String']['output']>;
  orgUnitTypeStableId: Maybe<Scalars['String']['output']>;
  partialCleanPowerPercent: Maybe<Scalars['Float']['output']>;
  powerUsageEffectiveness: Maybe<Scalars['Float']['output']>;
  sizeQuantity: Maybe<Scalars['Float']['output']>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
};

export const GQBuildingPeriodKind = {
  BuildingSize: 'BuildingSize',
  CleanPower: 'CleanPower',
  CustomField: 'CustomField',
  IsBuildingEmpty: 'IsBuildingEmpty',
  OrgUnit: 'OrgUnit',
  PowerUsageEffectiveness: 'PowerUsageEffectiveness',
} as const;

export type GQBuildingPeriodKind =
  (typeof GQBuildingPeriodKind)[keyof typeof GQBuildingPeriodKind];
export const GQBuildingSizeUnit = {
  SquareFeet: 'SquareFeet',
  SquareMeters: 'SquareMeters',
} as const;

export type GQBuildingSizeUnit =
  (typeof GQBuildingSizeUnit)[keyof typeof GQBuildingSizeUnit];
export type GQBuildingSupportingDocsExport = {
  __typename?: 'BuildingSupportingDocsExport';
  jobId: Scalars['ID']['output'];
};

export type GQBuildingSurveyStatus = {
  __typename?: 'BuildingSurveyStatus';
  bartActivityType: Maybe<Scalars['String']['output']>;
  latestSnapshotIsEmptyFile: Scalars['Boolean']['output'];
  latestSnapshotUpToDate: Scalars['Boolean']['output'];
  latestSnapshotUut: Maybe<GQUserUploadedTable>;
  measurementProjectIntervalMatches: Scalars['Boolean']['output'];
  numAddedBuildingsSinceUutGenerated: Scalars['Int']['output'];
  numChangedBuildingsSinceUutGenerated: Scalars['Int']['output'];
  numDeletedBuildingsSinceUutGenerated: Scalars['Int']['output'];
  numOmittedPartialBuildings: Scalars['Int']['output'];
  numUnchangedBuildingsSinceUutGenerated: Scalars['Int']['output'];
};

export type GQBuildingUntyped = GQIdInterface & {
  __typename?: 'BuildingUntyped';
  data: Scalars['UntypedData']['output'];
  id: Scalars['ID']['output'];
};

export type GQBuildingUtilitiesExport = {
  __typename?: 'BuildingUtilitiesExport';
  jobId: Scalars['ID']['output'];
};

export type GQBuildingUtility = GQIdInterface & {
  __typename?: 'BuildingUtility';
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  cleanPowerPercent: Maybe<Scalars['Float']['output']>;
  customFieldValues: Array<GQBuildingUtilityCustomField>;
  fuelCombustionReason: Maybe<Scalars['String']['output']>;
  fuelKind: Maybe<GQStationaryCombustionFuelKind>;
  id: Scalars['ID']['output'];
  isWasteHazardous: Maybe<Scalars['Boolean']['output']>;
  isWasteRadioactive: Maybe<Scalars['Boolean']['output']>;
  notes: Maybe<Scalars['String']['output']>;
  onsiteCleanFuelKind: Maybe<Scalars['String']['output']>;
  periodEnd: Scalars['Date']['output'];
  periodStart: Scalars['Date']['output'];
  provider: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  refrigerantLeakageSource: Maybe<GQRefrigerantLeakageSource>;
  refrigerantName: Maybe<Scalars['String']['output']>;
  supportingDocuments: Array<GQSupportingDocument>;
  unit: GQBuildingUtilityUnit;
  utilityType: GQBuildingUtilityType;
  wasteMaterial: Maybe<GQBuildingWasteMaterial>;
  wasteTreatment: Maybe<GQBuildingWasteTreatment>;
  waterUseKind: Maybe<GQBuildingWaterUseKind>;
};

export type GQBuildingUtilityConnection = {
  __typename?: 'BuildingUtilityConnection';
  edges: Array<Maybe<GQBuildingUtilityEdge>>;
  pageInfo: GQPageInfo;
};

export type GQBuildingUtilityCustomField = GQIdInterface & {
  __typename?: 'BuildingUtilityCustomField';
  customFieldValue: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ingestionCustomFieldId: Scalars['ID']['output'];
};

export type GQBuildingUtilityCustomFieldInput = {
  customFieldValue?: InputMaybe<Scalars['String']['input']>;
  ingestionCustomFieldId: Scalars['ID']['input'];
};

export type GQBuildingUtilityEdge = {
  __typename?: 'BuildingUtilityEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQBuildingUtility>;
};

export const GQBuildingUtilityType = {
  ElectricityConsumption: 'ElectricityConsumption',
  Refrigerant: 'Refrigerant',
  StationaryCombustion: 'StationaryCombustion',
  Waste: 'Waste',
  Water: 'Water',
} as const;

export type GQBuildingUtilityType =
  (typeof GQBuildingUtilityType)[keyof typeof GQBuildingUtilityType];
export const GQBuildingUtilityUnit = {
  Ccf: 'Ccf',
  Centigallon: 'Centigallon',
  Cf: 'Cf',
  CubicMeter: 'CubicMeter',
  G: 'G',
  Gallons: 'Gallons',
  Gj: 'Gj',
  Joule: 'Joule',
  Kbtu: 'Kbtu',
  Kg: 'Kg',
  Kilogallon: 'Kilogallon',
  Kw: 'Kw',
  Kwh: 'Kwh',
  Lb: 'Lb',
  Liter: 'Liter',
  M3NaturalGas: 'M3NaturalGas',
  Mcf: 'Mcf',
  Mj: 'Mj',
  Mmbtu: 'Mmbtu',
  Oz: 'Oz',
  Therm: 'Therm',
  Tonnes: 'Tonnes',
  Tons: 'Tons',
} as const;

export type GQBuildingUtilityUnit =
  (typeof GQBuildingUtilityUnit)[keyof typeof GQBuildingUtilityUnit];
export const GQBuildingWasteMaterial = {
  AluminumCans: 'AluminumCans',
  Beef: 'Beef',
  Bread: 'Bread',
  Clothing: 'Clothing',
  CommercialAndIndustrial: 'CommercialAndIndustrial',
  Concrete: 'Concrete',
  ConstructionWaste: 'ConstructionWaste',
  CorrugatedContainers: 'CorrugatedContainers',
  CrtDisplays: 'CrtDisplays',
  DairyProducts: 'DairyProducts',
  DesktopCpus: 'DesktopCpus',
  ElectronicPeripherals: 'ElectronicPeripherals',
  FlatPanelDisplays: 'FlatPanelDisplays',
  FoodWaste: 'FoodWaste',
  FoodWasteMeatOnly: 'FoodWasteMeatOnly',
  FoodWasteNonMeat: 'FoodWasteNonMeat',
  FruitsAndVegetables: 'FruitsAndVegetables',
  Glass: 'Glass',
  Grains: 'Grains',
  HardCopyDevices: 'HardCopyDevices',
  Hdpe: 'Hdpe',
  Ldpe: 'Ldpe',
  Lldpe: 'Lldpe',
  MixedElectronics: 'MixedElectronics',
  MixedMetals: 'MixedMetals',
  MixedMsw: 'MixedMsw',
  MixedOrganics: 'MixedOrganics',
  MixedPaperGeneral: 'MixedPaperGeneral',
  MixedPlastics: 'MixedPlastics',
  MixedRecyclables: 'MixedRecyclables',
  Newspaper: 'Newspaper',
  Pet: 'Pet',
  Pla: 'Pla',
  PortableElectronicDevices: 'PortableElectronicDevices',
  Poultry: 'Poultry',
  Pp: 'Pp',
  Ps: 'Ps',
  Pvc: 'Pvc',
  SteelCans: 'SteelCans',
  Unspecified: 'Unspecified',
  Wood: 'Wood',
} as const;

export type GQBuildingWasteMaterial =
  (typeof GQBuildingWasteMaterial)[keyof typeof GQBuildingWasteMaterial];
export const GQBuildingWasteTreatment = {
  AnaerobicallyDigested: 'AnaerobicallyDigested',
  Combusted: 'Combusted',
  Composted: 'Composted',
  Landfilled: 'Landfilled',
  PreparedForReuse: 'PreparedForReuse',
  Recycled: 'Recycled',
  Unspecified: 'Unspecified',
} as const;

export type GQBuildingWasteTreatment =
  (typeof GQBuildingWasteTreatment)[keyof typeof GQBuildingWasteTreatment];
export const GQBuildingWasteUnit = {
  G: 'G',
  Kg: 'Kg',
  Lb: 'Lb',
  Tonnes: 'Tonnes',
  Tons: 'Tons',
} as const;

export type GQBuildingWasteUnit =
  (typeof GQBuildingWasteUnit)[keyof typeof GQBuildingWasteUnit];
export const GQBuildingWaterUnit = {
  Ccf: 'Ccf',
  Centigallon: 'Centigallon',
  Cf: 'Cf',
  CubicMeter: 'CubicMeter',
  Gallons: 'Gallons',
  Kilogallon: 'Kilogallon',
  Liter: 'Liter',
  Mcf: 'Mcf',
} as const;

export type GQBuildingWaterUnit =
  (typeof GQBuildingWaterUnit)[keyof typeof GQBuildingWaterUnit];
export const GQBuildingWaterUseKind = {
  Consumed: 'Consumed',
  Discharged: 'Discharged',
  Recycled: 'Recycled',
  Stored: 'Stored',
  Used: 'Used',
} as const;

export type GQBuildingWaterUseKind =
  (typeof GQBuildingWaterUseKind)[keyof typeof GQBuildingWaterUseKind];
export type GQBuildingWithUtilities = GQIdInterface & {
  __typename?: 'BuildingWithUtilities';
  completionIntervals: GQBuildingCompletionIntervals;
  id: Scalars['ID']['output'];
  utilities: Array<GQBuildingUtility>;
  utilitiesPaginated: GQBuildingUtilityConnection;
};

export type GQBuildingWithUtilitiesCompletionIntervalsArgs = {
  separateOnsiteCleanElectricity: InputMaybe<Scalars['Boolean']['input']>;
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
};

export type GQBuildingWithUtilitiesUtilitiesPaginatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sortModel: InputMaybe<Array<GQDataGridSortItemInput>>;
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
  utilityType: InputMaybe<GQBuildingUtilityType>;
};

export const GQBuildingsMergeStrategy = {
  MergePeriods: 'mergePeriods',
  OverwriteDuplicates: 'overwriteDuplicates',
} as const;

export type GQBuildingsMergeStrategy =
  (typeof GQBuildingsMergeStrategy)[keyof typeof GQBuildingsMergeStrategy];
export type GQBulkCreateUploadTasksInput = {
  measurementProjectId: Scalars['String']['input'];
  rows: Array<GQCreateUploadTaskInput>;
};

export type GQBulkCreateUploadTasksPayload = {
  __typename?: 'BulkCreateUploadTasksPayload';
  success: Scalars['Boolean']['output'];
};

export type GQBulkCreateUserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GQBulkCreateUsersForFacilitiesInput = {
  users: Array<GQBulkCreateUserInput>;
};

export type GQBulkCreateUsersForFacilitiesPayload = {
  __typename?: 'BulkCreateUsersForFacilitiesPayload';
  organization: GQOrganization;
  users: Array<GQUser>;
};

export type GQBulkImportMarketplaceAllocationInstructionsInput = {
  instructions: Array<GQCreateMarketplaceAllocationInstructionInput>;
};

export type GQBulkImportMarketplaceAllocationInstructionsPayload = {
  __typename?: 'BulkImportMarketplaceAllocationInstructionsPayload';
  allocationInstructions: Array<GQMarketplaceAllocationInstruction>;
};

export type GQBulkStartCompanySurveyEngagementInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  isFinance?: InputMaybe<Scalars['Boolean']['input']>;
  suppliersSpecificInfo: Array<GQSupplierSpecificInfoForBulkDeploy>;
  tasksSpecificInfo: Array<GQCreateCompanySurveyInput>;
};

export type GQBulkStartCompanySurveyEngagementPayload = {
  __typename?: 'BulkStartCompanySurveyEngagementPayload';
  engagementTaskBatchIds: Array<Scalars['ID']['output']>;
};

export type GQBulkUpdateMarketplacePurchaseLineItemInput = {
  amount: Scalars['Float']['input'];
  amountUnit?: InputMaybe<GQMarketplaceProjectArchetypeUnit>;
  cleanPowerContractualInstrument?: InputMaybe<GQCleanPowerContractualInstrument>;
  closeDate?: InputMaybe<Scalars['Date']['input']>;
  coverageInterval: Scalars['YMInterval']['input'];
  deliveryDate?: InputMaybe<Scalars['Date']['input']>;
  eacMetadata?: InputMaybe<GQUpdateMarketplacePurchaseLineItemEacMetadataInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  mechanism?: InputMaybe<GQMarketplaceProjectArchetypeMechanism>;
  noteMd: Scalars['String']['input'];
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  status: GQMarketplacePurchaseLineItemStatus;
  unitFeeUsdCents: Scalars['Float']['input'];
  unitPriceUsdCents: Scalars['Float']['input'];
};

export type GQBulkUpdateMarketplacePurchaseLineItemsInput = {
  lineItems: Array<GQBulkUpdateMarketplacePurchaseLineItemInput>;
  orgId: Scalars['ID']['input'];
  purchaseId: Scalars['ID']['input'];
};

export type GQBulkUpdateMarketplacePurchaseLineItemsPayload = {
  __typename?: 'BulkUpdateMarketplacePurchaseLineItemsPayload';
  createdLineItems: Maybe<Array<GQMarketplacePurchaseLineItem>>;
  deletedLineItems: Maybe<Array<GQMarketplacePurchaseLineItem>>;
  marketplacePurchase: GQMarketplacePurchase;
  updatedLineItems: Maybe<Array<GQMarketplacePurchaseLineItem>>;
};

export type GQBulkUpdateReportQuestionsInput = {
  allowNotes?: InputMaybe<Scalars['Boolean']['input']>;
  allowedAttachmentKinds?: InputMaybe<Array<GQReportAttachmentItemKind>>;
  componentIds: Array<Scalars['String']['input']>;
  reportConfigId: Scalars['ID']['input'];
};

export type GQBulkUpdateReportQuestionsPayload = {
  __typename?: 'BulkUpdateReportQuestionsPayload';
  reportQuestions: Array<GQReportQuestion>;
};

export type GQBusinessActivityType = {
  __typename?: 'BusinessActivityType';
  description: Scalars['String']['output'];
  fields: Array<GQBusinessActivityTypeField>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  version: GQBusinessActivityTypeVersion;
  versionId: Scalars['ID']['output'];
};

export type GQBusinessActivityTypeField = {
  __typename?: 'BusinessActivityTypeField';
  description: Scalars['String']['output'];
  fieldName: Scalars['String']['output'];
  fieldType: GQBusinessActivityTypeFieldType;
  fieldValueOneOf: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  isProratable: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  typeId: Scalars['ID']['output'];
  unitFieldName: Maybe<Scalars['String']['output']>;
  versionId: Scalars['ID']['output'];
};

export const GQBusinessActivityTypeFieldType = {
  Boolean: 'BOOLEAN',
  Bytes: 'BYTES',
  Date: 'DATE',
  Float: 'FLOAT',
  Int: 'INT',
  Json: 'JSON',
  OneOf: 'ONE_OF',
  String: 'STRING',
  Timestamp: 'TIMESTAMP',
  Uuid: 'UUID',
} as const;

export type GQBusinessActivityTypeFieldType =
  (typeof GQBusinessActivityTypeFieldType)[keyof typeof GQBusinessActivityTypeFieldType];
export type GQBusinessActivityTypeVersion = {
  __typename?: 'BusinessActivityTypeVersion';
  businessActivityTypes: Array<GQBusinessActivityType>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  primaryAndExtensionBusinessActivityTypes: Array<GQBusinessActivityType>;
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
};

export type GQBusinessActivityTypeWithReleases = {
  __typename?: 'BusinessActivityTypeWithReleases';
  businessActivityType: Scalars['String']['output'];
  datasetIds: Array<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  releases: Array<GQEmissionsModelRelease>;
};

export type GQBusinessActivityUploadSet = {
  __typename?: 'BusinessActivityUploadSet';
  businessActivityType: Scalars['String']['output'];
  lastUpdater: Maybe<GQUser>;
  relatedDatasets: Array<GQDataset>;
  updatedAt: Scalars['DateTime']['output'];
  uploads: Array<GQUserUpload>;
};

export type GQBusinessCategoryCollection = {
  __typename?: 'BusinessCategoryCollection';
  id: Scalars['ID']['output'];
  records: Array<Scalars['String']['output']>;
};

export type GQCadtBuildingDiff = {
  __typename?: 'CadtBuildingDiff';
  buildingPresentInBart: Maybe<Scalars['Boolean']['output']>;
  errors: Array<GQBuildingDiffError>;
  new: Maybe<GQBuildingForDiff>;
  old: Maybe<GQBuildingForDiff>;
  warnings: Array<GQBuildingDiffWarning>;
};

export type GQCadtBuildingDiffSet = {
  __typename?: 'CadtBuildingDiffSet';
  added: Scalars['Int']['output'];
  changed: Scalars['Int']['output'];
  diffs: Array<GQCadtBuildingDiff>;
  removed: Scalars['Int']['output'];
  unchanged: Scalars['Int']['output'];
};

export type GQCadtBuildingsPushPreview = {
  __typename?: 'CadtBuildingsPushPreview';
  customFields: Array<GQIngestionCustomField>;
  mergePeriodsDiffUntyped: Maybe<Scalars['UntypedData']['output']>;
  orgUnitTypes: Array<GQOrgUnitType>;
  orgUnits: Array<GQOrgUnit>;
  overwriteDuplicatesDiffUntyped: Maybe<Scalars['UntypedData']['output']>;
};

export type GQCadtRowsToMapForOrgOutput = {
  __typename?: 'CadtRowsToMapForOrgOutput';
  cadtRowsToMap: GQRawCadtRowToMapConnection;
};

export type GQCalculationChain = {
  __typename?: 'CalculationChain';
  conversionFactor: Maybe<GQConversionFactorForDashboard>;
  convertedQuantity: Scalars['Float']['output'];
  emissionsFactor: Maybe<GQEmissionsFactorRecordForDashboard>;
  emissionsModelVersionId: Maybe<Scalars['String']['output']>;
  inputQuantity: Maybe<Scalars['Float']['output']>;
  inputUnit: Maybe<Scalars['String']['output']>;
  locationEmissionsFactor: Maybe<GQEmissionsFactorRecordForDashboard>;
  sumOfMeasure: Scalars['Float']['output'];
  /** @deprecated Switch to sumOfMeasure */
  tco2eLocationQuantity: Scalars['Float']['output'];
  /** @deprecated Switch to sumOfMeasure */
  tco2eQuantity: Scalars['Float']['output'];
};

export type GQCalculationChainCustomFilterInput = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type GQCalculationChainCustomFilterListInput = {
  filters: Array<GQCalculationChainCustomFilterInput>;
};

export type GQCalculationDataLineageInput = {
  bartInstanceId: Scalars['String']['input'];
  bartRowIds: Array<Scalars['Int']['input']>;
};

export type GQCalculationDataLineagePayload = {
  __typename?: 'CalculationDataLineagePayload';
  bartRows: Maybe<Array<GQFileDataLineage>>;
  extBartRows: Maybe<Array<GQFileDataLineage>>;
  fileMetadataIds: Maybe<Array<Scalars['ID']['output']>>;
  isTruncated: Scalars['Boolean']['output'];
  uutRows: Maybe<Array<GQUutRowsDataLineage>>;
};

export type GQCalculationTag = {
  __typename?: 'CalculationTag';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQCalculationTagPayload = {
  __typename?: 'CalculationTagPayload';
  tag: GQCalculationTag;
};

export type GQCalculationTagWithUsage = {
  __typename?: 'CalculationTagWithUsage';
  emissionsModels: Array<GQEmissionsModelStable>;
  measurementTestSuites: Array<GQMeasurementTestSuite>;
  referenceDataSources: Array<GQReferenceDataSource>;
  tag: GQCalculationTag;
};

export type GQCancelWorkflowInput = {
  workflowId: Scalars['String']['input'];
};

export type GQCancelWorkflowPayload = {
  __typename?: 'CancelWorkflowPayload';
  success: Scalars['Boolean']['output'];
};

export type GQCanonicalClimateProgramProject = GQIdInterface & {
  __typename?: 'CanonicalClimateProgramProject';
  commitmentType: Maybe<Scalars['String']['output']>;
  costEstimate: Maybe<Scalars['String']['output']>;
  datasetRequirements: Array<GQCanonicalClimateProgramProjectRequirementDataset>;
  description: Scalars['String']['output'];
  ghgpRequirements: Array<GQCanonicalClimateProgramProjectRequirementGhgp>;
  hasBenchmarkMapping: Scalars['Boolean']['output'];
  hidden: Maybe<Scalars['Boolean']['output']>;
  hiddenFromMeasurement: Maybe<Scalars['Boolean']['output']>;
  iconType: GQClimateProgramProjectIconType;
  iconTypeOverride: Maybe<GQClimateProgramProjectIconType>;
  id: Scalars['ID']['output'];
  kind: GQClimateProgramProjectKind;
  matchObjectType: Maybe<Scalars['String']['output']>;
  measurementObjectCopy: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  peerBenchmarkCompanies: Array<GQCompany>;
  reportKind: Maybe<Scalars['String']['output']>;
  routeType: Maybe<Scalars['String']['output']>;
  timeEstimate: Maybe<Scalars['String']['output']>;
};

export type GQCanonicalClimateProgramProjectPayload = {
  __typename?: 'CanonicalClimateProgramProjectPayload';
  canonicalClimateProgramProject: GQCanonicalClimateProgramProject;
};

export type GQCanonicalClimateProgramProjectRequirementDataset =
  GQIdInterface & {
    __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
    additionalNotesMd: Maybe<Scalars['String']['output']>;
    canonicalDataset: GQCanonicalDataset;
    id: Scalars['ID']['output'];
  };

export type GQCanonicalClimateProgramProjectRequirementDatasetPayload = {
  __typename?: 'CanonicalClimateProgramProjectRequirementDatasetPayload';
  canonicalClimateProgramProject: GQCanonicalClimateProgramProject;
  canonicalClimateProgramProjectRequirementDataset: GQCanonicalClimateProgramProjectRequirementDataset;
};

export type GQCanonicalClimateProgramProjectRequirementGhgp = GQIdInterface & {
  __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
  additionalNotesMd: Maybe<Scalars['String']['output']>;
  ghgScope: Scalars['Int']['output'];
  ghgScope3Category: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  minimumFidelity: GQClimateProgramProjectRequirementFidelity;
};

export type GQCanonicalClimateProgramProjectRequirementGhgpPayload = {
  __typename?: 'CanonicalClimateProgramProjectRequirementGhgpPayload';
  canonicalClimateProgramProject: GQCanonicalClimateProgramProject;
  canonicalClimateProgramProjectRequirementGhgp: GQCanonicalClimateProgramProjectRequirementGhgp;
};

export type GQCanonicalDataset = GQIdInterface & {
  __typename?: 'CanonicalDataset';
  canonicalDatasources: Array<GQCanonicalDatasource>;
  customerTargetSchemas: Array<GQCustomerTargetSchema>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructions: Maybe<GQInstructionsBundle>;
  kind: GQCanonicalDatasetKind;
  name: Scalars['String']['output'];
  potentialOverlaps: Maybe<Scalars['String']['output']>;
  selfServeDisabledReason: Maybe<Scalars['String']['output']>;
  usedFor: Maybe<Scalars['String']['output']>;
};

export type GQCanonicalDatasetCustomerTargetSchemasArgs = {
  hasPublishedVersion: InputMaybe<Scalars['Boolean']['input']>;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
};

export const GQCanonicalDatasetKind = {
  BillOfMaterials: 'BillOfMaterials',
  BriberyAndCorruption: 'BriberyAndCorruption',
  Buildings: 'Buildings',
  CapitalExpenses: 'CapitalExpenses',
  CarbonCreditRetirements: 'CarbonCreditRetirements',
  CareInstructions: 'CareInstructions',
  ChartOfAccounts: 'ChartOfAccounts',
  CloudCosts: 'CloudCosts',
  CloudUsage: 'CloudUsage',
  CorporateInvestments: 'CorporateInvestments',
  CostOfRevenue: 'CostOfRevenue',
  CryptoHoldings: 'CryptoHoldings',
  CryptoTransactions: 'CryptoTransactions',
  CustomEmissionsFactor: 'CustomEmissionsFactor',
  DataCenters: 'DataCenters',
  DirectEmissions: 'DirectEmissions',
  DownstreamLogistics: 'DownstreamLogistics',
  EmployeeTravelExpenses: 'EmployeeTravelExpenses',
  Employees: 'Employees',
  EndOfLifeTreatmentOfSoldProducts: 'EndOfLifeTreatmentOfSoldProducts',
  EnergyAttributeCertificatesPurchased: 'EnergyAttributeCertificatesPurchased',
  Factories: 'Factories',
  FinancedEmissions: 'FinancedEmissions',
  Financials: 'Financials',
  Flights: 'Flights',
  Food: 'Food',
  HistoricalResults: 'HistoricalResults',
  Hotels: 'Hotels',
  HumanRightsViolations: 'HumanRightsViolations',
  Lobbying: 'Lobbying',
  MaterialAggregate: 'MaterialAggregate',
  MaterialContents: 'MaterialContents',
  Microplastics: 'Microplastics',
  OperatingExpenses: 'OperatingExpenses',
  OperationalPayments: 'OperationalPayments',
  Packaging: 'Packaging',
  PrivateFlights: 'PrivateFlights',
  ProductsPurchased: 'ProductsPurchased',
  ProductsSold: 'ProductsSold',
  Rails: 'Rails',
  Refrigerants: 'Refrigerants',
  Revenue: 'Revenue',
  SafetyIncidents: 'SafetyIncidents',
  Sites: 'Sites',
  SoldProductCircularity: 'SoldProductCircularity',
  Substances: 'Substances',
  SupplierFactoriesTier1: 'SupplierFactoriesTier1',
  SupplierUpstreamLogistics: 'SupplierUpstreamLogistics',
  TextileProductSales: 'TextileProductSales',
  UpstreamLogistics: 'UpstreamLogistics',
  UseOfGoods: 'UseOfGoods',
  UseOfSoldProductsCommonRetail: 'UseOfSoldProductsCommonRetail',
  UseOfSoldProductsFuels: 'UseOfSoldProductsFuels',
  UseOfSoldProductsRefrigerants: 'UseOfSoldProductsRefrigerants',
  UseOfSoldProductsVehicles: 'UseOfSoldProductsVehicles',
  UsePhaseOfGoods: 'UsePhaseOfGoods',
  Utilities: 'Utilities',
  Vehicles: 'Vehicles',
  Waste: 'Waste',
  WaterUsage: 'WaterUsage',
  WeightOfGoodsPurchased: 'WeightOfGoodsPurchased',
  WorkforceSocial: 'WorkforceSocial',
  WorkplaceReports: 'WorkplaceReports',
} as const;

export type GQCanonicalDatasetKind =
  (typeof GQCanonicalDatasetKind)[keyof typeof GQCanonicalDatasetKind];
export type GQCanonicalDatasetPayload = {
  __typename?: 'CanonicalDatasetPayload';
  canonicalDataset: GQCanonicalDataset;
};

export type GQCanonicalDatasource = GQIdInterface & {
  __typename?: 'CanonicalDatasource';
  activeIntegrationConnection: Maybe<GQIntegrationConnection>;
  canonicalDataset: GQCanonicalDataset;
  externalId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  instructions: Maybe<GQInstructionsBundle>;
  integrationPartner: Maybe<GQIntegrationPartner>;
  name: Scalars['String']['output'];
};

export type GQCanonicalDatasourcePayload = {
  __typename?: 'CanonicalDatasourcePayload';
  canonicalDatasource: GQCanonicalDatasource;
};

export const GQCanonicalReportObjective = {
  All: 'All',
  Awareness: 'Awareness',
  Regulatory: 'Regulatory',
  Stakeholder: 'Stakeholder',
} as const;

export type GQCanonicalReportObjective =
  (typeof GQCanonicalReportObjective)[keyof typeof GQCanonicalReportObjective];
export type GQCanonicalSchema = {
  __typename?: 'CanonicalSchema';
  exampleData: GQCanonicalSchemaExampleData;
  id: Scalars['ID']['output'];
  jsonSchema: Scalars['JSONString']['output'];
};

export type GQCanonicalSchemaExampleData = {
  __typename?: 'CanonicalSchemaExampleData';
  columns: Array<Scalars['String']['output']>;
  rows: Array<GQCanonicalSchemaExampleRow>;
};

export type GQCanonicalSchemaExampleRow = {
  __typename?: 'CanonicalSchemaExampleRow';
  fields: Array<GQCanonicalSchemaExampleRowFields>;
  id: Scalars['String']['output'];
};

export type GQCanonicalSchemaExampleRowFields = {
  __typename?: 'CanonicalSchemaExampleRowFields';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQCarbonNeutralCommitment = GQCompanyClimateCommitment &
  GQIdInterface & {
    __typename?: 'CarbonNeutralCommitment';
    commitmentMadeDate: Maybe<Scalars['Date']['output']>;
    commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
    commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
    description: Maybe<Scalars['String']['output']>;
    externalUrl: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    kind: GQCompanyClimateCommitmentKind;
    targetYear: Maybe<Scalars['Int']['output']>;
  };

export type GQCarbonNeutralCommitmentInput = {
  targetYear?: InputMaybe<Scalars['Int']['input']>;
};

export type GQCategorizedEmissionData = {
  __typename?: 'CategorizedEmissionData';
  amountKgco2e: Scalars['Float']['output'];
  businessCategory: Scalars['String']['output'];
  businessSubcategory: Maybe<Scalars['String']['output']>;
  proportion: Scalars['Float']['output'];
};

export type GQCategoryInfo = {
  __typename?: 'CategoryInfo';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GQCdpApiManualAddRowInput = {
  cdpQuestionId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  rowId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCdpApiManualAddRowPayload = {
  __typename?: 'CdpApiManualAddRowPayload';
  complexQuestionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  instanceId: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type GQCdpApiManualDeleteRowInput = {
  orgId: Scalars['ID']['input'];
  rowId: Scalars['ID']['input'];
};

export type GQCdpApiManualDeleteRowPayload = {
  __typename?: 'CdpApiManualDeleteRowPayload';
  complexQuestionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  instanceId: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type GQCdpApiManualGetResponseInput = {
  cdpQuestionId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQCdpApiManualGetResponsePayload = {
  __typename?: 'CdpApiManualGetResponsePayload';
  response: Maybe<Scalars['JSONString']['output']>;
};

export type GQCdpApiManualPutResponseInput = {
  id: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  rowId?: InputMaybe<Scalars['ID']['input']>;
  updateResponseInput: GQCdpApiUpdateResponseInput;
};

export type GQCdpApiManualPutResponsePayload = {
  __typename?: 'CdpApiManualPutResponsePayload';
  responseVersion: Scalars['Int']['output'];
};

export type GQCdpApiRegistrationPayload = {
  __typename?: 'CdpApiRegistrationPayload';
  cdpOrganizationId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isReportRegistered: Scalars['Boolean']['output'];
  registeredReportId: Maybe<Scalars['ID']['output']>;
};

export const GQCdpApiResponseStatusEnum = {
  Answered: 'ANSWERED',
  Conditional: 'CONDITIONAL',
  Progress: 'PROGRESS',
  Reviewed: 'REVIEWED',
  Skipped: 'SKIPPED',
  Unanswered: 'UNANSWERED',
} as const;

export type GQCdpApiResponseStatusEnum =
  (typeof GQCdpApiResponseStatusEnum)[keyof typeof GQCdpApiResponseStatusEnum];
export type GQCdpApiUpdateResponseInput = {
  content: Scalars['JSONString']['input'];
  status: GQCdpApiResponseStatusEnum;
};

export type GQCdpCandidateColumnIdMapping = {
  __typename?: 'CdpCandidateColumnIdMapping';
  cdpColumnQuestionJson: Scalars['JSONString']['output'];
  cdpParentQuestionJson: Scalars['JSONString']['output'];
  cdpParentRefId: Scalars['String']['output'];
  cdpRefId: Scalars['String']['output'];
  columnId: Maybe<Scalars['String']['output']>;
  columnLabel: Maybe<Scalars['String']['output']>;
  otherColumnIdMapping: Maybe<Scalars['JSONString']['output']>;
  parentReportQuestion: Maybe<GQReportQuestion>;
};

export type GQCdpCandidateQuestionIdMapping = {
  __typename?: 'CdpCandidateQuestionIdMapping';
  cdpQuestionJson: Scalars['JSONString']['output'];
  cdpRefId: Scalars['String']['output'];
  reportQuestion: Maybe<GQReportQuestion>;
};

export type GQCdpColumnIdMapping = {
  __typename?: 'CdpColumnIdMapping';
  cdpRefId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  otherColumnIdMapping: Maybe<Scalars['JSONString']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  watershedColumnId: Scalars['String']['output'];
};

export type GQCdpColumnMappingInput = {
  cdpRefId?: InputMaybe<Scalars['String']['input']>;
  otherColumnIdMapping?: InputMaybe<Scalars['JSONString']['input']>;
  watershedColumnId: Scalars['String']['input'];
};

export type GQCdpIdMappingsPayload = {
  __typename?: 'CdpIdMappingsPayload';
  columnIdMappings: Array<GQCdpColumnIdMapping>;
  id: Scalars['ID']['output'];
  questionIdMappings: Array<GQCdpQuestionIdMapping>;
};

export type GQCdpLatestSyncWorkflowStatusPayload = {
  __typename?: 'CdpLatestSyncWorkflowStatusPayload';
  workflowStatus: Maybe<GQCdpSyncWorkflowStatusPayload>;
};

export type GQCdpMissingIdMappingsPayload = {
  __typename?: 'CdpMissingIdMappingsPayload';
  candidateColumnMappings: Array<GQCdpCandidateColumnIdMapping>;
  candidateQuestionMappings: Array<GQCdpCandidateQuestionIdMapping>;
  unmappedWatershedQuestions: Array<GQReportQuestion>;
};

export type GQCdpOrganization = {
  __typename?: 'CdpOrganization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQCdpOrganizationPayload = {
  __typename?: 'CdpOrganizationPayload';
  error: Maybe<Scalars['String']['output']>;
  organizations: Maybe<Array<GQCdpOrganization>>;
  success: Scalars['Boolean']['output'];
};

export type GQCdpQuestionIdMapping = {
  __typename?: 'CdpQuestionIdMapping';
  cdpRefId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reportQuestionIdentifier: GQReportQuestionIdentifier;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQCdpQuestionMappingInput = {
  cdpRefId?: InputMaybe<Scalars['String']['input']>;
  reportQuestionIdentifierId: Scalars['ID']['input'];
};

export type GQCdpSyncQuestionHistoryConnection = {
  __typename?: 'CdpSyncQuestionHistoryConnection';
  edges: Array<Maybe<GQCdpSyncQuestionHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQCdpSyncQuestionHistoryEdge = {
  __typename?: 'CdpSyncQuestionHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQCdpSyncQuestionStatus>;
};

export type GQCdpSyncQuestionStatus = {
  __typename?: 'CdpSyncQuestionStatus';
  actor: GQUser;
  actorName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  failureDetails: Maybe<Scalars['JSONString']['output']>;
  failureKind: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSuccess: Maybe<Scalars['Boolean']['output']>;
  oldCdpResponses: Maybe<Scalars['JSONString']['output']>;
  reportId: Scalars['ID']['output'];
  reportQuestion: GQReportQuestion;
  reportQuestionId: Scalars['ID']['output'];
  syncTime: Scalars['DateTime']['output'];
};

export type GQCdpSyncWorkflowStatusPayload = {
  __typename?: 'CdpSyncWorkflowStatusPayload';
  id: Scalars['ID']['output'];
  questions: Array<GQCdpSyncQuestionStatus>;
  workflowId: Scalars['String']['output'];
};

export type GQCdpVendorData = {
  __typename?: 'CdpVendorData';
  disclosureQualityScore: GQDisclosureQualityScore;
  id: Scalars['ID']['output'];
  pctEvaluationStatusesMatchResponse: Scalars['Float']['output'];
  publishingYear: Scalars['Int']['output'];
  reportingYear: Scalars['Int']['output'];
  scope1Nonzero: Scalars['Boolean']['output'];
  scope1Verified: Scalars['Boolean']['output'];
  scope2Nonzero: Scalars['Boolean']['output'];
  scope2Verified: Scalars['Boolean']['output'];
  scope3Nonzero: Scalars['Boolean']['output'];
  scope3Verified: Scalars['Boolean']['output'];
  scope301EvaluationStatus: GQScope3EvaluationStatus;
  scope301Or302Nonzero: Scalars['Boolean']['output'];
  scope302EvaluationStatus: GQScope3EvaluationStatus;
  scope303EvaluationStatus: GQScope3EvaluationStatus;
  scope304EvaluationStatus: GQScope3EvaluationStatus;
  scope305EvaluationStatus: GQScope3EvaluationStatus;
  scope306EvaluationStatus: GQScope3EvaluationStatus;
  scope307EvaluationStatus: GQScope3EvaluationStatus;
  scope308EvaluationStatus: GQScope3EvaluationStatus;
  scope309EvaluationStatus: GQScope3EvaluationStatus;
  scope310EvaluationStatus: GQScope3EvaluationStatus;
  scope311EvaluationStatus: GQScope3EvaluationStatus;
  scope312EvaluationStatus: GQScope3EvaluationStatus;
  scope313EvaluationStatus: GQScope3EvaluationStatus;
  scope314EvaluationStatus: GQScope3EvaluationStatus;
  scope315EvaluationStatus: GQScope3EvaluationStatus;
  scope316EvaluationStatus: GQScope3EvaluationStatus;
  scope317EvaluationStatus: GQScope3EvaluationStatus;
  totalEmissionsNonzero: Scalars['Boolean']['output'];
};

export type GQChangeset = GQAssumptionChangeset;

export type GQChartOrderInput = {
  chartId: Scalars['ID']['input'];
  visible: Scalars['Boolean']['input'];
};

export type GQCheckAndGenerateFacilitiesBartsInput = {
  orgId: Scalars['ID']['input'];
};

export type GQCheckAndHealForecast = {
  __typename?: 'CheckAndHealForecast';
  currentVersion: Scalars['String']['output'];
  error: Maybe<Scalars['String']['output']>;
  healed: Maybe<Scalars['Boolean']['output']>;
  latestVersion: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  valid: Scalars['Boolean']['output'];
};

export type GQCheckAndHealForecastInput = {
  orgIds: Array<Scalars['ID']['input']>;
  shouldHeal?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCheckAndHealForecastPayload = {
  __typename?: 'CheckAndHealForecastPayload';
  results: Array<GQCheckAndHealForecast>;
};

export type GQCleanEnergyCommitment = GQCompanyClimateCommitment &
  GQIdInterface & {
    __typename?: 'CleanEnergyCommitment';
    commitmentMadeDate: Maybe<Scalars['Date']['output']>;
    commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
    commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
    description: Maybe<Scalars['String']['output']>;
    externalUrl: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    kind: GQCompanyClimateCommitmentKind;
    targetPercentageCleanEnergy: Maybe<Scalars['Int']['output']>;
    targetYear: Maybe<Scalars['Int']['output']>;
  };

export type GQCleanEnergyCommitmentInput = {
  targetPercentageCleanEnergy?: InputMaybe<Scalars['Int']['input']>;
  targetYear?: InputMaybe<Scalars['Int']['input']>;
};

export const GQCleanPowerContractualInstrument = {
  Eac: 'EAC',
  GreenPowerProgram: 'GreenPowerProgram',
  Ppa: 'PPA',
  Vppa: 'VPPA',
} as const;

export type GQCleanPowerContractualInstrument =
  (typeof GQCleanPowerContractualInstrument)[keyof typeof GQCleanPowerContractualInstrument];
export type GQCleanPowerImpact = {
  __typename?: 'CleanPowerImpact';
  appliedInstructions: Array<GQMarketplaceAllocationInstructionImpact>;
};

export type GQClearAllReportAnswerVerifiersFailurePayload = {
  __typename?: 'ClearAllReportAnswerVerifiersFailurePayload';
  num: Scalars['Int']['output'];
};

export type GQClearReportAnswerVerifierFailureInput = {
  reportAnswerId: Scalars['ID']['input'];
};

export type GQClearReportAnswerVerifierFailurePayload = {
  __typename?: 'ClearReportAnswerVerifierFailurePayload';
  reportAnswerId: Scalars['ID']['output'];
};

export type GQClearReportAnswerVerifierFailuresInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQClearReportAnswerVerifierFailuresPayload = {
  __typename?: 'ClearReportAnswerVerifierFailuresPayload';
  num: Scalars['Int']['output'];
};

export type GQClimateProgramCalendarEvent = {
  __typename?: 'ClimateProgramCalendarEvent';
  endDate: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
};

export type GQClimateProgramCalendarEventPayload = {
  __typename?: 'ClimateProgramCalendarEventPayload';
  climateProgramCalendarEvent: GQClimateProgramCalendarEvent;
};

export type GQClimateProgramInfo = {
  __typename?: 'ClimateProgramInfo';
  hasCustomizedSteps: Scalars['Boolean']['output'];
  steps: Array<GQClimateProgramInfoStep>;
  targets: Array<GQClimateProgramInfoTarget>;
};

export type GQClimateProgramInfoInput = {
  steps?: InputMaybe<Array<GQClimateProgramInfoStepInput>>;
};

export type GQClimateProgramInfoStep = {
  __typename?: 'ClimateProgramInfoStep';
  descriptionMd: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQClimateProgramInfoStepInput = {
  descriptionMd: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type GQClimateProgramInfoTarget = {
  __typename?: 'ClimateProgramInfoTarget';
  amount: Scalars['String']['output'];
  descriptor: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export const GQClimateProgramKind = {
  MeasureAvoidReduce: 'MeasureAvoidReduce',
  MeasureReduceNetZero: 'MeasureReduceNetZero',
  MeasureReduceOffset: 'MeasureReduceOffset',
  MeasureReduceOffsetZero: 'MeasureReduceOffsetZero',
  MeasureReduceReport: 'MeasureReduceReport',
} as const;

export type GQClimateProgramKind =
  (typeof GQClimateProgramKind)[keyof typeof GQClimateProgramKind];
export type GQClimateProgramLifecycle = {
  __typename?: 'ClimateProgramLifecycle';
  completedStage: GQCompletedLifecycleStage;
  kickoffBlurb: Maybe<Scalars['String']['output']>;
  nextStage: Maybe<GQNextLifecycleStage>;
};

export type GQClimateProgramProject = GQClimateProgramTimelineObject &
  GQIClimateProgramProject &
  GQIdInterface & {
    __typename?: 'ClimateProgramProject';
    canonicalClimateProgramProject: Maybe<GQCanonicalClimateProgramProject>;
    completedAt: Maybe<Scalars['DateTime']['output']>;
    datasetRequirements: Array<GQClimateProgramProjectRequirementDataset>;
    description: Scalars['String']['output'];
    endDate: Maybe<Scalars['Date']['output']>;
    iconType: GQClimateProgramProjectIconType;
    id: Scalars['ID']['output'];
    kind: GQClimateProgramProjectKind;
    measurementProject: Maybe<GQMeasurementProject>;
    name: Scalars['String']['output'];
    plannedYear: Scalars['Int']['output'];
    startDate: Maybe<Scalars['Date']['output']>;
    status: GQClimateProgramProjectStatus;
  };

export const GQClimateProgramProjectIconType = {
  CarbonOffset: 'CarbonOffset',
  CarbonRemoval: 'CarbonRemoval',
  CleanPower: 'CleanPower',
  Commitments: 'Commitments',
  Custom: 'Custom',
  GenericReduction: 'GenericReduction',
  Kickoff: 'Kickoff',
  ReductionPlan: 'ReductionPlan',
  ReportDisclosure: 'ReportDisclosure',
  ReportGoverningBody: 'ReportGoverningBody',
  ReportPublic: 'ReportPublic',
  Simulate: 'Simulate',
  SupplierEngagement: 'SupplierEngagement',
  Targets: 'Targets',
  UnderstandEmissions: 'UnderstandEmissions',
} as const;

export type GQClimateProgramProjectIconType =
  (typeof GQClimateProgramProjectIconType)[keyof typeof GQClimateProgramProjectIconType];
export const GQClimateProgramProjectKind = {
  Custom: 'Custom',
  Insight: 'Insight',
  Reduction: 'Reduction',
  Report: 'Report',
  Target: 'Target',
} as const;

export type GQClimateProgramProjectKind =
  (typeof GQClimateProgramProjectKind)[keyof typeof GQClimateProgramProjectKind];
export type GQClimateProgramProjectPayload = {
  __typename?: 'ClimateProgramProjectPayload';
  climateProgramProject: GQClimateProgramProject;
};

export type GQClimateProgramProjectRequirementDataset = GQIdInterface & {
  __typename?: 'ClimateProgramProjectRequirementDataset';
  additionalNotesMd: Maybe<Scalars['String']['output']>;
  canonicalGhgpRequirement: Maybe<GQCanonicalClimateProgramProjectRequirementGhgp>;
  climateProgramProject: GQClimateProgramProject;
  dataset: GQDataset;
  id: Scalars['ID']['output'];
};

export type GQClimateProgramProjectRequirementDatasetPayload = {
  __typename?: 'ClimateProgramProjectRequirementDatasetPayload';
  climateProgramProjectRequirementDataset: GQClimateProgramProjectRequirementDataset;
};

export const GQClimateProgramProjectRequirementFidelity = {
  ActivityBased: 'ActivityBased',
  Cee: 'Cee',
  SpendBased: 'SpendBased',
  SquareFootageBased: 'SquareFootageBased',
  UtilitiesBased: 'UtilitiesBased',
} as const;

export type GQClimateProgramProjectRequirementFidelity =
  (typeof GQClimateProgramProjectRequirementFidelity)[keyof typeof GQClimateProgramProjectRequirementFidelity];
export const GQClimateProgramProjectStatus = {
  Active: 'Active',
  Completed: 'Completed',
  Overdue: 'Overdue',
  Planned: 'Planned',
} as const;

export type GQClimateProgramProjectStatus =
  (typeof GQClimateProgramProjectStatus)[keyof typeof GQClimateProgramProjectStatus];
export const GQClimateProgramProjectSubkind = {
  MarketBased: 'MarketBased',
  OperationalSolutions: 'OperationalSolutions',
} as const;

export type GQClimateProgramProjectSubkind =
  (typeof GQClimateProgramProjectSubkind)[keyof typeof GQClimateProgramProjectSubkind];
export type GQClimateProgramTimelineObject = {
  id: Scalars['ID']['output'];
};

export type GQClimateProgramTimelineProject = GQIClimateProgramProject &
  GQIdInterface & {
    __typename?: 'ClimateProgramTimelineProject';
    canonicalClimateProgramProject: Maybe<GQCanonicalClimateProgramProject>;
    completedAt: Maybe<Scalars['DateTime']['output']>;
    description: Scalars['String']['output'];
    endDate: Maybe<Scalars['Date']['output']>;
    iconType: GQClimateProgramProjectIconType;
    id: Scalars['ID']['output'];
    kind: GQClimateProgramProjectKind;
    name: Scalars['String']['output'];
    object: GQClimateProgramProject | GQMeasurementProject | GQReport;
    objectType: GQClimateProgramTimelineProjectObjectType;
    plannedYear: Scalars['Int']['output'];
    startDate: Maybe<Scalars['Date']['output']>;
  };

export const GQClimateProgramTimelineProjectObjectType = {
  ClimateProgramProject: 'ClimateProgramProject',
  MeasurementProject: 'MeasurementProject',
  Report: 'Report',
} as const;

export type GQClimateProgramTimelineProjectObjectType =
  (typeof GQClimateProgramTimelineProjectObjectType)[keyof typeof GQClimateProgramTimelineProjectObjectType];
export type GQClimateProgramTimelineProjectPayload = {
  __typename?: 'ClimateProgramTimelineProjectPayload';
  climateProgramTimelineProject: GQClimateProgramTimelineProject;
};

export type GQCliqReferenceDataConnection = {
  __typename?: 'CliqReferenceDataConnection';
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  referenceDataSource: GQReferenceDataSource;
};

export const GQColumnDimension = {
  Monthly: 'Monthly',
  Summary: 'Summary',
  Yearly: 'Yearly',
} as const;

export type GQColumnDimension =
  (typeof GQColumnDimension)[keyof typeof GQColumnDimension];
export type GQColumnMapping = {
  __typename?: 'ColumnMapping';
  sourceFileColumnName: Maybe<Scalars['String']['output']>;
  watershedColumnName: Scalars['String']['output'];
};

export type GQColumnMappingInput = {
  sourceFileColumnName?: InputMaybe<Scalars['String']['input']>;
  watershedColumnName: Scalars['String']['input'];
};

export type GQColumnVisibility = {
  columnId: Scalars['ID']['input'];
  visible: Scalars['Boolean']['input'];
};

export type GQComment = {
  __typename?: 'Comment';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  person: Maybe<GQUser | GQWatershedEmployee>;
};

export type GQCommentConnection = {
  __typename?: 'CommentConnection';
  edges: Array<Maybe<GQCommentEdge>>;
  pageInfo: GQPageInfo;
};

export type GQCommentEdge = {
  __typename?: 'CommentEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQComment>;
};

export type GQCompany = GQIdInterface &
  GQPermissionObject & {
    __typename?: 'Company';
    alternateNames: Maybe<Array<Scalars['String']['output']>>;
    beas: Array<GQCompanyBea>;
    bestMatchesForCompany: Array<GQCompanyMatch>;
    betterCompanyId: Maybe<Scalars['ID']['output']>;
    climateProgress: GQCompanyClimateProgress;
    companyIngestionRecordUpdateId: Maybe<Scalars['ID']['output']>;
    companyRelationships: Array<GQCompanyRelationship>;
    companyWebsite: Maybe<GQCompanyUrl>;
    companyWebsiteUrlId: Maybe<Scalars['ID']['output']>;
    contacts: Array<GQSupplierContact>;
    countries: Array<GQCompanyCountry>;
    country: Maybe<Scalars['String']['output']>;
    countryAlpha2: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    description: Maybe<Scalars['String']['output']>;
    disclosures: Array<GQDisclosure>;
    disclosuresV2: Array<GQDisclosure>;
    editor: Maybe<GQUser>;
    fullLogoUrl: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    isDemo: Scalars['Boolean']['output'];
    isWatershedCorporateCustomer: Scalars['Boolean']['output'];
    isins: Array<GQCompanyIsin>;
    leis: Array<GQCompanyLei>;
    logo: Maybe<GQCompanyUrl>;
    logoUrl: Maybe<Scalars['String']['output']>;
    logoUrlId: Maybe<Scalars['ID']['output']>;
    naicsCode: Maybe<Scalars['String']['output']>;
    naicsCodeSource: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    names: Array<GQCompanyName>;
    parentRelationships: Array<GQParentRelationship>;
    replacedBy: Maybe<GQCompany>;
    sAndP: Maybe<GQCompanySAndP>;
    sbtiStage: GQCompanySbtCommitmentStage;
    sfdcOpportunityId: Maybe<Scalars['String']['output']>;
    sfdcStage: Maybe<Scalars['String']['output']>;
    source: Scalars['String']['output'];
    sourceId: Scalars['ID']['output'];
    sourceKey: Maybe<GQCompanySourceKey>;
    sustainabilityWebsite: Maybe<GQCompanyUrl>;
    sustainabilityWebsiteUrl: Maybe<Scalars['String']['output']>;
    sustainabilityWebsiteUrlId: Maybe<Scalars['ID']['output']>;
    tickerSymbols: Array<GQCompanyTickerSymbol>;
    updatedAt: Scalars['DateTime']['output'];
    url: Maybe<Scalars['String']['output']>;
    userEditorId: Maybe<Scalars['ID']['output']>;
  };

export type GQCompanyDisclosuresArgs = {
  opts: InputMaybe<GQDisclosureInput>;
};

export type GQCompanyDisclosuresV2Args = {
  opts: InputMaybe<GQDisclosureInput>;
};

export type GQCompanyParentRelationshipsArgs = {
  onlyGetActiveRelationships: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCompanyAutoMatch = {
  __typename?: 'CompanyAutoMatch';
  company: GQCompany;
  confidenceScore: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
};

export type GQCompanyBea = GQIdInterface & {
  __typename?: 'CompanyBea';
  bea: Scalars['String']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyBeaInput = {
  bea: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  sourceId: Scalars['ID']['input'];
};

export type GQCompanyChangeRequest = {
  __typename?: 'CompanyChangeRequest';
  action: GQCompanyChangeRequestAction;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  org: GQOrganization;
  payload: Scalars['JSONString']['output'];
  userEditor: Maybe<GQUser>;
};

export const GQCompanyChangeRequestAction = {
  Create: 'Create',
} as const;

export type GQCompanyChangeRequestAction =
  (typeof GQCompanyChangeRequestAction)[keyof typeof GQCompanyChangeRequestAction];
export type GQCompanyClimateCommitment = {
  commitmentMadeDate: Maybe<Scalars['Date']['output']>;
  commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
  commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
  description: Maybe<Scalars['String']['output']>;
  externalUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind: GQCompanyClimateCommitmentKind;
};

export const GQCompanyClimateCommitmentKind = {
  CarbonNeutral: 'CarbonNeutral',
  CleanEnergy: 'CleanEnergy',
  NetZero: 'NetZero',
  ScienceBasedTarget: 'ScienceBasedTarget',
} as const;

export type GQCompanyClimateCommitmentKind =
  (typeof GQCompanyClimateCommitmentKind)[keyof typeof GQCompanyClimateCommitmentKind];
export type GQCompanyClimateCommitmentPayload = {
  __typename?: 'CompanyClimateCommitmentPayload';
  commitment:
    | GQCarbonNeutralCommitment
    | GQCleanEnergyCommitment
    | GQNetZeroCommitment
    | GQScienceBasedTargetCommitment;
};

export type GQCompanyClimateCommitmentRaw = {
  __typename?: 'CompanyClimateCommitmentRaw';
  commitmentMadeDate: Maybe<Scalars['Date']['output']>;
  commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
  commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  externalUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind: GQCompanyClimateCommitmentKind;
  orgId: Maybe<Scalars['ID']['output']>;
  privateDisclosureId: Maybe<Scalars['ID']['output']>;
  publicDisclosureId: Maybe<Scalars['ID']['output']>;
  sbtBaselineYear: Maybe<Scalars['Int']['output']>;
  sbtCommitmentDeadline: Maybe<Scalars['Date']['output']>;
  sbtCommitmentStage: Maybe<GQSbtCommitmentStage>;
  sbtCommitmentType: Maybe<GQSbtCommitmentType>;
  sbtLongTermTargetYear: Maybe<Scalars['Int']['output']>;
  sbtNearTermTargetYear: Maybe<Scalars['Int']['output']>;
  sbtNetZeroCommitted: Maybe<Scalars['Boolean']['output']>;
  sbtNetZeroTargetYear: Maybe<Scalars['Int']['output']>;
  sbtTargetClassification: Maybe<GQSbtTargetClassification>;
  targetPercentageCleanEnergy: Maybe<Scalars['Float']['output']>;
  targetYear: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userEditorId: Maybe<Scalars['ID']['output']>;
  watershedEditorId: Maybe<Scalars['ID']['output']>;
};

export const GQCompanyClimateProgress = {
  DisclosedEmissions: 'DisclosedEmissions',
  DisclosedEmissionsAndSetCommitments: 'DisclosedEmissionsAndSetCommitments',
  DisclosedEmissionsInitiatives: 'DisclosedEmissionsInitiatives',
  DisclosedEmissionsTargetsInitiatives: 'DisclosedEmissionsTargetsInitiatives',
  DisclosedInitiatives: 'DisclosedInitiatives',
  DisclosedTargets: 'DisclosedTargets',
  DisclosedTargetsInitiatives: 'DisclosedTargetsInitiatives',
  None: 'None',
  Unknown: 'Unknown',
} as const;

export type GQCompanyClimateProgress =
  (typeof GQCompanyClimateProgress)[keyof typeof GQCompanyClimateProgress];
export type GQCompanyColorsInput = {
  primary?: InputMaybe<Scalars['String']['input']>;
  secondary?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type GQCompanyConnection = {
  __typename?: 'CompanyConnection';
  edges: Array<Maybe<GQCompanyEdge>>;
  pageInfo: GQPageInfo;
};

export type GQCompanyCountry = GQIdInterface & {
  __typename?: 'CompanyCountry';
  countryAlpha2: Scalars['String']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyCountryInput = {
  countryAlpha2: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isPrimary: Scalars['Boolean']['input'];
  sourceId: Scalars['String']['input'];
};

export type GQCompanyDataFiltersForMetric = {
  cdpScoreSet?: InputMaybe<Array<Scalars['String']['input']>>;
  industry?: InputMaybe<Scalars['String']['input']>;
  peerCompanyGroupId?: InputMaybe<Scalars['ID']['input']>;
  reportingYear?: InputMaybe<Scalars['Int']['input']>;
};

export type GQCompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQCompany>;
};

export type GQCompanyEmissionsInterface = {
  scope1Kgco2e: Maybe<Scalars['Float']['output']>;
  scope2LocationKgco2e: Maybe<Scalars['Float']['output']>;
  scope2MarketKgco2e: Maybe<Scalars['Float']['output']>;
  scope2PurchasedCoolingWaterKgco2e: Maybe<Scalars['Float']['output']>;
  scope2PurchasedHeatSteamKgco2e: Maybe<Scalars['Float']['output']>;
  scope2UnknownKgco2e: Maybe<Scalars['Float']['output']>;
  scope3Kgco2e: Maybe<Scalars['Float']['output']>;
  scope301Kgco2e: Maybe<Scalars['Float']['output']>;
  scope302Kgco2e: Maybe<Scalars['Float']['output']>;
  scope303Kgco2e: Maybe<Scalars['Float']['output']>;
  scope304Kgco2e: Maybe<Scalars['Float']['output']>;
  scope305Kgco2e: Maybe<Scalars['Float']['output']>;
  scope306Kgco2e: Maybe<Scalars['Float']['output']>;
  scope307Kgco2e: Maybe<Scalars['Float']['output']>;
  scope308Kgco2e: Maybe<Scalars['Float']['output']>;
  scope309Kgco2e: Maybe<Scalars['Float']['output']>;
  scope310Kgco2e: Maybe<Scalars['Float']['output']>;
  scope311Kgco2e: Maybe<Scalars['Float']['output']>;
  scope312Kgco2e: Maybe<Scalars['Float']['output']>;
  scope313Kgco2e: Maybe<Scalars['Float']['output']>;
  scope314Kgco2e: Maybe<Scalars['Float']['output']>;
  scope315Kgco2e: Maybe<Scalars['Float']['output']>;
  scope316Kgco2e: Maybe<Scalars['Float']['output']>;
  scope317Kgco2e: Maybe<Scalars['Float']['output']>;
};

export type GQCompanyEmissionsInterfaceV2 = {
  revenueCurrency: Maybe<Scalars['String']['output']>;
  scope1: Maybe<Scalars['Float']['output']>;
  scope2: Maybe<Scalars['Float']['output']>;
  scope2Location: Maybe<Scalars['Float']['output']>;
  scope2Market: Maybe<Scalars['Float']['output']>;
  scope3: Maybe<Scalars['Float']['output']>;
  scope301: Maybe<Scalars['Float']['output']>;
  scope302: Maybe<Scalars['Float']['output']>;
  scope303: Maybe<Scalars['Float']['output']>;
  scope304: Maybe<Scalars['Float']['output']>;
  scope305: Maybe<Scalars['Float']['output']>;
  scope306: Maybe<Scalars['Float']['output']>;
  scope307: Maybe<Scalars['Float']['output']>;
  scope308: Maybe<Scalars['Float']['output']>;
  scope309: Maybe<Scalars['Float']['output']>;
  scope310: Maybe<Scalars['Float']['output']>;
  scope311: Maybe<Scalars['Float']['output']>;
  scope312: Maybe<Scalars['Float']['output']>;
  scope313: Maybe<Scalars['Float']['output']>;
  scope314: Maybe<Scalars['Float']['output']>;
  scope315: Maybe<Scalars['Float']['output']>;
  scope316: Maybe<Scalars['Float']['output']>;
  scope317: Maybe<Scalars['Float']['output']>;
  units: GQCompanyEmissionsUnits;
};

export const GQCompanyEmissionsUnits = {
  AllocatedKgco2e: 'allocatedKgco2e',
  Kgco2e: 'kgco2e',
  Kgco2ePerDollar: 'kgco2ePerDollar',
} as const;

export type GQCompanyEmissionsUnits =
  (typeof GQCompanyEmissionsUnits)[keyof typeof GQCompanyEmissionsUnits];
export type GQCompanyFilterValues = {
  __typename?: 'CompanyFilterValues';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQCompanyIngestionDiff = GQIdInterface & {
  __typename?: 'CompanyIngestionDiff';
  company: Maybe<GQCompany>;
  companyMatchResults: Maybe<Scalars['JSONString']['output']>;
  companyMatchScore: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isCompanyDemo: Scalars['Boolean']['output'];
  primaryCompanyName: Scalars['String']['output'];
  processedAt: Maybe<Scalars['DateTime']['output']>;
  processedBy: Maybe<GQUser>;
  recordUpdates: Array<GQCompanyIngestionRecordUpdate>;
  sourceRecord: GQCompanyIngestionSourceRecord;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQCompanyIngestionDiffError = {
  __typename?: 'CompanyIngestionDiffError';
  companyIngestionDiffId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  errorCode: Scalars['String']['output'];
  errorData: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export const GQCompanyIngestionOperation = {
  Insert: 'Insert',
  Update: 'Update',
} as const;

export type GQCompanyIngestionOperation =
  (typeof GQCompanyIngestionOperation)[keyof typeof GQCompanyIngestionOperation];
export type GQCompanyIngestionRecordUpdate = GQIdInterface & {
  __typename?: 'CompanyIngestionRecordUpdate';
  createdAt: Scalars['DateTime']['output'];
  diff: GQCompanyIngestionDiff;
  id: Scalars['ID']['output'];
  newValue: Maybe<Scalars['JSONString']['output']>;
  oldValue: Maybe<Scalars['JSONString']['output']>;
  record: Maybe<GQCompanyRecord>;
  status: GQCompanyIngestionRecordUpdateStatus;
  tableName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQCompanyIngestionRecordUpdateFilters = {
  isCompanyDemo?: InputMaybe<Scalars['Boolean']['input']>;
  matches?: InputMaybe<Array<GQCompanyMatchScore>>;
  operation?: InputMaybe<GQCompanyIngestionOperation>;
  statuses?: InputMaybe<Array<GQCompanyIngestionRecordUpdateStatus>>;
};

export type GQCompanyIngestionRecordUpdateRequestConnection = {
  __typename?: 'CompanyIngestionRecordUpdateRequestConnection';
  edges: Array<Maybe<GQCompanyIngestionRecordUpdateRequestEdge>>;
  pageInfo: GQPageInfo;
};

export type GQCompanyIngestionRecordUpdateRequestEdge = {
  __typename?: 'CompanyIngestionRecordUpdateRequestEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQCompanyIngestionRecordUpdate>;
};

export const GQCompanyIngestionRecordUpdateStatus = {
  Approved: 'Approved',
  Declined: 'Declined',
  Unprocessed: 'Unprocessed',
} as const;

export type GQCompanyIngestionRecordUpdateStatus =
  (typeof GQCompanyIngestionRecordUpdateStatus)[keyof typeof GQCompanyIngestionRecordUpdateStatus];
export type GQCompanyIngestionSourceRecord =
  | GQCdpVendorData
  | GQCompany
  | GQCompanyChangeRequest
  | GQIngestedSbtiCompanyUpdate
  | GQSbtTarget;

export type GQCompanyInput = {
  beas?: InputMaybe<Array<GQCompanyBeaInput>>;
  companyWebsiteUrl?: InputMaybe<GQCompanyUrlInput>;
  countries?: InputMaybe<Array<GQCompanyCountryInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isDemo: Scalars['Boolean']['input'];
  isins?: InputMaybe<Array<GQCompanyIsinInput>>;
  leis?: InputMaybe<Array<GQCompanyLeiInput>>;
  logoUrl?: InputMaybe<GQCompanyUrlInput>;
  names?: InputMaybe<Array<GQCompanyNameInput>>;
  replacedById?: InputMaybe<Scalars['String']['input']>;
  sAndPs?: InputMaybe<Array<GQCompanySAndPInput>>;
  salesforce?: InputMaybe<GQCompanySalesforceInput>;
  sourceId?: InputMaybe<Scalars['ID']['input']>;
  sustainabilityWebsiteUrl?: InputMaybe<GQCompanyUrlInput>;
  tickerSymbols?: InputMaybe<Array<GQCompanyTickerSymbolInput>>;
};

export type GQCompanyIsin = GQIdInterface & {
  __typename?: 'CompanyIsin';
  editor: GQUser;
  id: Scalars['ID']['output'];
  isin: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyIsinInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isin: Scalars['String']['input'];
  sourceId: Scalars['ID']['input'];
};

export type GQCompanyLei = GQIdInterface & {
  __typename?: 'CompanyLei';
  editor: GQUser;
  id: Scalars['ID']['output'];
  lei: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyLeiInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  lei: Scalars['String']['input'];
  sourceId: Scalars['ID']['input'];
};

export type GQCompanyMatch = GQIdInterface & {
  __typename?: 'CompanyMatch';
  beaMatch: Maybe<Scalars['String']['output']>;
  company: GQCompany;
  countryMatch: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isinMatch: Maybe<Scalars['String']['output']>;
  leiMatch: Maybe<Scalars['String']['output']>;
  nameMatch: Maybe<Scalars['String']['output']>;
  sAndPIdMatch: Maybe<Scalars['String']['output']>;
  score: GQCompanyMatchScore;
  tickerMatch: Maybe<Scalars['String']['output']>;
};

export const GQCompanyMatchScore = {
  Conflict: 'CONFLICT',
  Exact: 'EXACT',
  High: 'HIGH',
  Low: 'LOW',
  Mid: 'MID',
  None: 'NONE',
  VeryHigh: 'VERY_HIGH',
} as const;

export type GQCompanyMatchScore =
  (typeof GQCompanyMatchScore)[keyof typeof GQCompanyMatchScore];
export type GQCompanyName = GQIdInterface & {
  __typename?: 'CompanyName';
  cleanedName: Maybe<Scalars['String']['output']>;
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyNameInput = {
  cleanedName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPrimary: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  sourceId: Scalars['ID']['input'];
};

export type GQCompanyOverrideMatchInput = {
  autoApprove: Scalars['Boolean']['input'];
  insertNewCompany: Scalars['Boolean']['input'];
  updateCompanyId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCompanyPortalSettings = {
  __typename?: 'CompanyPortalSettings';
  id: Maybe<Scalars['ID']['output']>;
  portalCallout: Maybe<Scalars['String']['output']>;
  portalTitle: Scalars['String']['output'];
  sidebarContent: Maybe<Scalars['String']['output']>;
  toggleHeaderImage: Maybe<Scalars['Boolean']['output']>;
};

export type GQCompanyPublicDisclosure = GQIdInterface & {
  __typename?: 'CompanyPublicDisclosure';
  editor: GQUser;
  id: Scalars['ID']['output'];
  publicDisclosureId: Maybe<Scalars['ID']['output']>;
  source: GQCompanySourceKey;
  sourceId: Scalars['ID']['output'];
};

export type GQCompanyRecord =
  | GQAssetCorporate
  | GQCompany
  | GQCompanyBea
  | GQCompanyCountry
  | GQCompanyIsin
  | GQCompanyLei
  | GQCompanyName
  | GQCompanyPublicDisclosure
  | GQCompanySAndP
  | GQCompanyTickerSymbol
  | GQCompanyUrl
  | GQOrgCompany
  | GQPrivateDisclosure;

export type GQCompanyRelationship = {
  __typename?: 'CompanyRelationship';
  companyA: GQCompany;
  companyAId: Scalars['ID']['output'];
  companyB: GQCompany;
  companyBId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  relationshipEndDate: Maybe<Scalars['YearMonth']['output']>;
  relationshipStartDate: Maybe<Scalars['YearMonth']['output']>;
  relationshipType: GQCompanyRelationshipType;
  watershedEditorId: Maybe<Scalars['ID']['output']>;
};

export const GQCompanyRelationshipType = {
  ParentChild: 'ParentChild',
} as const;

export type GQCompanyRelationshipType =
  (typeof GQCompanyRelationshipType)[keyof typeof GQCompanyRelationshipType];
export type GQCompanyReportingFacts = GQIdInterface & {
  __typename?: 'CompanyReportingFacts';
  asOfMonth: Scalars['YearMonth']['output'];
  assetsEuUsd: Maybe<Scalars['Float']['output']>;
  assetsGlobalUsd: Maybe<Scalars['Float']['output']>;
  assetsUkUsd: Maybe<Scalars['Float']['output']>;
  companyId: Scalars['ID']['output'];
  federalContractsUkUsd: Maybe<Scalars['Float']['output']>;
  federalContractsUsUsd: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  isBank: Maybe<Scalars['Boolean']['output']>;
  isDirectorsFilerUk: Maybe<Scalars['Boolean']['output']>;
  isDoingBusinessCa: Maybe<Scalars['Boolean']['output']>;
  isInsurance: Maybe<Scalars['Boolean']['output']>;
  isLlpUk: Maybe<Scalars['Boolean']['output']>;
  isNfisFilerUk: Maybe<Scalars['Boolean']['output']>;
  isPublicEu: Maybe<Scalars['Boolean']['output']>;
  isSmallCreditInsurance: Maybe<Scalars['Boolean']['output']>;
  isTradedBankingLlpUk: Maybe<Scalars['Boolean']['output']>;
  largestContractUkUsd: Maybe<Scalars['Float']['output']>;
  legalEntityCountries: Maybe<Array<Scalars['String']['output']>>;
  listedExchanges: Maybe<Array<Scalars['String']['output']>>;
  lseListingStatus: Maybe<Scalars['String']['output']>;
  nfrdFilerStatus: Maybe<Scalars['String']['output']>;
  numEmployeesEu: Maybe<Scalars['Int']['output']>;
  numEmployeesGlobal: Maybe<Scalars['Int']['output']>;
  numEmployeesUk: Maybe<Scalars['Int']['output']>;
  overriddenAt: Maybe<Scalars['DateTime']['output']>;
  primaryJurisdiction: Maybe<Scalars['String']['output']>;
  revenueEuUsd: Maybe<Scalars['Float']['output']>;
  revenueGlobalUsd: Maybe<Scalars['Float']['output']>;
  revenueUkUsd: Maybe<Scalars['Float']['output']>;
  secFilerStatus: Maybe<Scalars['String']['output']>;
};

export type GQCompanyReportingFactsInput = {
  assetsEuUsd?: InputMaybe<Scalars['Float']['input']>;
  assetsGlobalUsd?: InputMaybe<Scalars['Float']['input']>;
  assetsUkUsd?: InputMaybe<Scalars['Float']['input']>;
  federalContractsUkUsd?: InputMaybe<Scalars['Float']['input']>;
  federalContractsUsUsd?: InputMaybe<Scalars['Float']['input']>;
  isBank?: InputMaybe<Scalars['Boolean']['input']>;
  isDirectorsFilerUk?: InputMaybe<Scalars['Boolean']['input']>;
  isDoingBusinessCa?: InputMaybe<Scalars['Boolean']['input']>;
  isInsurance?: InputMaybe<Scalars['Boolean']['input']>;
  isLlpUk?: InputMaybe<Scalars['Boolean']['input']>;
  isNfisFilerUk?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEu?: InputMaybe<Scalars['Boolean']['input']>;
  isSmallCreditInsurance?: InputMaybe<Scalars['Boolean']['input']>;
  isTradedBankingLlpUk?: InputMaybe<Scalars['Boolean']['input']>;
  largestContractUkUsd?: InputMaybe<Scalars['Float']['input']>;
  legalEntityCountries?: InputMaybe<Array<Scalars['String']['input']>>;
  listedExchanges?: InputMaybe<Array<Scalars['String']['input']>>;
  lseListingStatus?: InputMaybe<Scalars['String']['input']>;
  nfrdFilerStatus?: InputMaybe<Scalars['String']['input']>;
  numEmployeesEu?: InputMaybe<Scalars['Int']['input']>;
  numEmployeesGlobal?: InputMaybe<Scalars['Int']['input']>;
  numEmployeesUk?: InputMaybe<Scalars['Int']['input']>;
  primaryJurisdiction?: InputMaybe<Scalars['String']['input']>;
  revenueEuUsd?: InputMaybe<Scalars['Float']['input']>;
  revenueGlobalUsd?: InputMaybe<Scalars['Float']['input']>;
  revenueUkUsd?: InputMaybe<Scalars['Float']['input']>;
  secFilerStatus?: InputMaybe<Scalars['String']['input']>;
};

export type GQCompanySAndP = GQIdInterface & {
  __typename?: 'CompanySAndP';
  editor: GQUser;
  id: Scalars['ID']['output'];
  sAndPId: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanySAndPInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  sAndPId: Scalars['String']['input'];
  sourceId: Scalars['ID']['input'];
};

export const GQCompanySbtCommitmentStage = {
  Committed: 'Committed',
  None: 'None',
  TargetsDeveloped: 'TargetsDeveloped',
  TargetsSet: 'TargetsSet',
  TargetsSubmitted: 'TargetsSubmitted',
} as const;

export type GQCompanySbtCommitmentStage =
  (typeof GQCompanySbtCommitmentStage)[keyof typeof GQCompanySbtCommitmentStage];
export type GQCompanySalesforce = GQIdInterface & {
  __typename?: 'CompanySalesforce';
  editor: GQUser;
  id: Scalars['ID']['output'];
  opportunityId: Maybe<Scalars['String']['output']>;
  opportunityStage: Maybe<Scalars['String']['output']>;
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanySalesforceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  opportunityStage?: InputMaybe<Scalars['String']['input']>;
  sourceId: Scalars['ID']['input'];
};

export type GQCompanySourceKey = GQIdInterface & {
  __typename?: 'CompanySourceKey';
  createdAt: Scalars['DateTime']['output'];
  editor: Maybe<GQUser>;
  id: Scalars['ID']['output'];
  priority: Scalars['Int']['output'];
  sourceKey: Scalars['String']['output'];
  sourceNotes: Maybe<Scalars['String']['output']>;
  sourceUrl: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userVisibleSourceNotes: Maybe<Scalars['String']['output']>;
  userVisibleSourceUrl: Maybe<Scalars['String']['output']>;
};

export type GQCompanySourceKeyPayload = {
  __typename?: 'CompanySourceKeyPayload';
  companySourceKey: GQCompanySourceKey;
};

export type GQCompanySurvey = GQPermissionObject & {
  __typename?: 'CompanySurvey';
  approvedAt: Maybe<Scalars['DateTime']['output']>;
  comments: Array<GQCompanySurveyComment>;
  company: GQCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  crossOrgModelId: Scalars['String']['output'];
  currentAssignee: GQCompanySurveyAssignee;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  dueDate: Maybe<Scalars['Date']['output']>;
  edits: Array<GQCompanySurveyEdit>;
  engagementTaskBatchId: Maybe<Scalars['ID']['output']>;
  engagementTaskConfig: GQEngagementTaskConfig;
  engagementTaskConfigId: Scalars['ID']['output'];
  expenseCategories: Maybe<Array<GQDiscloseEmissionsExpenseCategories>>;
  expiresAt: Maybe<Scalars['DateTime']['output']>;
  facilityName: Maybe<Scalars['String']['output']>;
  footprintEstimate: Maybe<GQFootprintEstimateOutput>;
  id: Scalars['ID']['output'];
  initialSupplierAssigneeEmail: Maybe<Scalars['String']['output']>;
  latestCeeWorkflowId: Maybe<Scalars['ID']['output']>;
  latestPleeWorkflowId: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  proposeTargetEmissionsTarget: Maybe<GQSimpleTimeseries>;
  proposeTargetFilters: Maybe<GQFilterExpressionGroup>;
  proposeTargetIntensityType: Maybe<GQDisclosureTargetIntensityType>;
  reportingYear: Maybe<Scalars['Int']['output']>;
  rootAssignee: Maybe<Scalars['ID']['output']>;
  rootCompany: GQCompany;
  rootCompanyId: Scalars['ID']['output'];
  rootOrgId: Scalars['ID']['output'];
  status: GQCompanySurveyStatus;
  statusChangedAt: Maybe<Scalars['DateTime']['output']>;
  submittedAnswers: Array<GQCompanySurveyAnswer>;
  submittedAt: Maybe<Scalars['DateTime']['output']>;
  supplierAssignee: Maybe<Scalars['ID']['output']>;
  supplierFacingDescription: Maybe<Scalars['String']['output']>;
  supplierOrgId: Maybe<Scalars['ID']['output']>;
  /** @deprecated replaced with custom portal callout configuration */
  supplierPortalCustomEducation: Maybe<Scalars['Boolean']['output']>;
  surveyKind: GQCompanySurveyKind;
  watershedGeneric: Maybe<Scalars['Boolean']['output']>;
};

export type GQCompanySurveyAnswer = {
  __typename?: 'CompanySurveyAnswer';
  answer: Maybe<Scalars['JSONString']['output']>;
  attachments: Array<GQCompanySurveyAnswerAttachment>;
  id: Scalars['ID']['output'];
  questionIndex: Maybe<Scalars['Int']['output']>;
  questionKey: Scalars['String']['output'];
  skipped: Scalars['Boolean']['output'];
  surveyId: Scalars['ID']['output'];
};

export type GQCompanySurveyAnswerAttachment = {
  __typename?: 'CompanySurveyAnswerAttachment';
  fileMetadata: GQFileMetadata;
  originalFilename: Scalars['String']['output'];
  questionKey: Scalars['String']['output'];
  signedDownloadUrl: Maybe<Scalars['String']['output']>;
};

export const GQCompanySurveyAssignee = {
  RootCustomer: 'RootCustomer',
  Supplier: 'Supplier',
} as const;

export type GQCompanySurveyAssignee =
  (typeof GQCompanySurveyAssignee)[keyof typeof GQCompanySurveyAssignee];
export type GQCompanySurveyComment = {
  __typename?: 'CompanySurveyComment';
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorOrgId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  userEditorId: Maybe<Scalars['ID']['output']>;
  userEditorName: Maybe<Scalars['String']['output']>;
};

export type GQCompanySurveyConnection = {
  __typename?: 'CompanySurveyConnection';
  edges: Array<GQCompanySurveyEdge>;
  pageInfo: GQPageInfo;
};

export type GQCompanySurveyEdge = {
  __typename?: 'CompanySurveyEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQCompanySurvey>;
};

export type GQCompanySurveyEdit = {
  __typename?: 'CompanySurveyEdit';
  columnName: GQEditColumn;
  createdAt: Scalars['DateTime']['output'];
  creatorOrgId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  newValue: Maybe<Scalars['String']['output']>;
  oldValue: Maybe<Scalars['String']['output']>;
  userEditorId: Maybe<Scalars['ID']['output']>;
  userEditorName: Maybe<Scalars['String']['output']>;
  valueType: Maybe<GQEditValueType>;
};

export const GQCompanySurveyKind = {
  Nzsep: 'NZSEP',
  PortfolioCompany: 'PortfolioCompany',
  Supplier: 'Supplier',
} as const;

export type GQCompanySurveyKind =
  (typeof GQCompanySurveyKind)[keyof typeof GQCompanySurveyKind];
export const GQCompanySurveyQuestionKey = {
  AcceptedProposeTarget: 'AcceptedProposeTarget',
  ActivitiesNaics: 'ActivitiesNaics',
  AllocateEmissionsMethod: 'AllocateEmissionsMethod',
  AllocateEmissionsMethodV2: 'AllocateEmissionsMethodV2',
  AllocationMethodology: 'AllocationMethodology',
  AnnualPercentTurnover: 'AnnualPercentTurnover',
  BoardDiversity: 'BoardDiversity',
  CSuiteDiversity: 'CSuiteDiversity',
  CarbonCommitments: 'CarbonCommitments',
  CarbonMeasurementCompletionDate: 'CarbonMeasurementCompletionDate',
  CarbonMeasurementPlan: 'CarbonMeasurementPlan',
  CarbonMeasurementProvider: 'CarbonMeasurementProvider',
  CarbonRemoval: 'CarbonRemoval',
  CarbonRemovalAmount: 'CarbonRemovalAmount',
  CeeAbsoluteEmissionsByScope: 'CeeAbsoluteEmissionsByScope',
  CeeAggregatedBuildingSize: 'CeeAggregatedBuildingSize',
  CeeBiomassPercentage: 'CeeBiomassPercentage',
  CeeBuildings: 'CeeBuildings',
  CeeBusinessAirMiles: 'CeeBusinessAirMiles',
  CeeBusinessAirSpend: 'CeeBusinessAirSpend',
  CeeBusinessAirSpendCurrency: 'CeeBusinessAirSpendCurrency',
  CeeBusinessGroundMiles: 'CeeBusinessGroundMiles',
  CeeCloud: 'CeeCloud',
  CeeCloudProviders: 'CeeCloudProviders',
  CeeCloudTotalInstanceHours: 'CeeCloudTotalInstanceHours',
  CeeComputationNonCloud: 'CeeComputationNonCloud',
  CeeComputationNonCloudCleanPower: 'CeeComputationNonCloudCleanPower',
  CeeConsulting: 'CeeConsulting',
  CeeDairyAvgMilkGallonsPerYear: 'CeeDairyAvgMilkGallonsPerYear',
  CeeDairyManureManagement: 'CeeDairyManureManagement',
  CeeDairyManureManagementLiquidCap: 'CeeDairyManureManagementLiquidCap',
  CeeDairyNumberOfCows: 'CeeDairyNumberOfCows',
  CeeDairyProductionSystem: 'CeeDairyProductionSystem',
  CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeed:
    'CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeed',
  CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeedKgPerCow:
    'CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeedKgPerCow',
  CeeLogisticsAirKmTonnes: 'CeeLogisticsAirKmTonnes',
  CeeLogisticsAirLoading: 'CeeLogisticsAirLoading',
  CeeLogisticsFreezerSeaKmTonnes: 'CeeLogisticsFreezerSeaKmTonnes',
  CeeLogisticsFreezerTruckKmTonnes: 'CeeLogisticsFreezerTruckKmTonnes',
  CeeLogisticsRefrigeratedSeaKmTonnes: 'CeeLogisticsRefrigeratedSeaKmTonnes',
  CeeLogisticsRefrigeratedTruckKmTonnes:
    'CeeLogisticsRefrigeratedTruckKmTonnes',
  CeeLogisticsSeaKmTonnes: 'CeeLogisticsSeaKmTonnes',
  CeeLogisticsSeaLoading: 'CeeLogisticsSeaLoading',
  CeeLogisticsTrainKmTonnes: 'CeeLogisticsTrainKmTonnes',
  CeeLogisticsTrainLoading: 'CeeLogisticsTrainLoading',
  CeeLogisticsTruckKmTonnes: 'CeeLogisticsTruckKmTonnes',
  CeeLogisticsTruckLoading: 'CeeLogisticsTruckLoading',
  CeeOfficeDaysPerWeek: 'CeeOfficeDaysPerWeek',
  CeePaperCleanPowerPercentage: 'CeePaperCleanPowerPercentage',
  CeePaperless: 'CeePaperless',
  CeeRemotePercent: 'CeeRemotePercent',
  CeeRevenueIntensityByScope: 'CeeRevenueIntensityByScope',
  CeeTmsFileUpload: 'CeeTmsFileUpload',
  CeeTmsManualInput: 'CeeTmsManualInput',
  CeeWoodByCountry: 'CeeWoodByCountry',
  ChallengesProcuringRenewableForCustomer:
    'ChallengesProcuringRenewableForCustomer',
  CleanPowerDescription: 'CleanPowerDescription',
  CleanPowerImportReport: 'CleanPowerImportReport',
  CleanPowerInitiativeRenewableEnergyUsage:
    'CleanPowerInitiativeRenewableEnergyUsage',
  CleanPowerInitiativeRenewableEnergyUsageImport:
    'CleanPowerInitiativeRenewableEnergyUsageImport',
  CleanPowerPercentage: 'CleanPowerPercentage',
  CleanPowerPurchased: 'CleanPowerPurchased',
  Comment: 'Comment',
  CompanyIndustry: 'CompanyIndustry',
  CompanyName: 'CompanyName',
  ContactEmail: 'ContactEmail',
  ContactName: 'ContactName',
  ContactTitle: 'ContactTitle',
  CountriesOfOperation: 'CountriesOfOperation',
  CountryOfHeadquarters: 'CountryOfHeadquarters',
  CustomQuestions: 'CustomQuestions',
  CustomerSpendInReportingYear: 'CustomerSpendInReportingYear',
  DaysLostDueToInjury: 'DaysLostDueToInjury',
  DerivedSustainabilityInitiatives: 'DerivedSustainabilityInitiatives',
  EdciAdditionalGhgTargetDetails: 'EdciAdditionalGhgTargetDetails',
  EdciAdditionalMethodologyDetails: 'EdciAdditionalMethodologyDetails',
  EdciAnnualPercentTurnoverForFtes: 'EdciAnnualPercentTurnoverForFtes',
  EdciAnnualRevenueInMostRecentCalendarYear:
    'EdciAnnualRevenueInMostRecentCalendarYear',
  EdciCompanyGrowthStage: 'EdciCompanyGrowthStage',
  EdciCompanyStructure: 'EdciCompanyStructure',
  EdciCountryOfDomicile: 'EdciCountryOfDomicile',
  EdciCountryOfOperations: 'EdciCountryOfOperations',
  EdciDaysLostToInjury: 'EdciDaysLostToInjury',
  EdciDecarbonizationPlan: 'EdciDecarbonizationPlan',
  EdciEmissionsDataReviewDetails: 'EdciEmissionsDataReviewDetails',
  EdciEmployeeSurvey: 'EdciEmployeeSurvey',
  EdciFinancialDataCurrency: 'EdciFinancialDataCurrency',
  EdciFteCountEndOfPriorYear: 'EdciFteCountEndOfPriorYear',
  EdciFteCountEndOfYear: 'EdciFteCountEndOfYear',
  EdciLgbtqBoardMembers: 'EdciLgbtqBoardMembers',
  EdciLongTermNetZeroGoal: 'EdciLongTermNetZeroGoal',
  EdciNetChangeFteDueToMnA: 'EdciNetChangeFteDueToMnA',
  EdciOrganicNetNewHires: 'EdciOrganicNetNewHires',
  EdciPercentResponseEmployeeSurvey: 'EdciPercentResponseEmployeeSurvey',
  EdciPrimaryIndustryOfOperations: 'EdciPrimaryIndustryOfOperations',
  EdciPrimarySectorOfOperations: 'EdciPrimarySectorOfOperations',
  EdciScope1Emissions: 'EdciScope1Emissions',
  EdciScope1Methodology: 'EdciScope1Methodology',
  EdciScope2Emissions: 'EdciScope2Emissions',
  EdciScope2Methodology: 'EdciScope2Methodology',
  EdciScope3Emissions: 'EdciScope3Emissions',
  EdciScope3Methdology: 'EdciScope3Methdology',
  EdciShortTermReductionTarget: 'EdciShortTermReductionTarget',
  EdciTotalBoardMembers: 'EdciTotalBoardMembers',
  EdciTotalCSuitePositions: 'EdciTotalCSuitePositions',
  EdciTotalEnergyConsumption: 'EdciTotalEnergyConsumption',
  EdciTotalNetNewHires: 'EdciTotalNetNewHires',
  EdciTotalRenewableEnergyConsumption: 'EdciTotalRenewableEnergyConsumption',
  EdciTotalWomenCSuitePositions: 'EdciTotalWomenCSuitePositions',
  EdciUnderrepresentedGroupBoardMembers:
    'EdciUnderrepresentedGroupBoardMembers',
  EdciWomenBoardMembers: 'EdciWomenBoardMembers',
  EdciWorkRelatedFatalities: 'EdciWorkRelatedFatalities',
  EdciWorkRelatedInjuries: 'EdciWorkRelatedInjuries',
  EmployeeDiversity: 'EmployeeDiversity',
  EmployeeSurvey: 'EmployeeSurvey',
  EmployeeSurveyKinds: 'EmployeeSurveyKinds',
  EmployeeSurveyResponseRate: 'EmployeeSurveyResponseRate',
  EnergyUseEstimateMethod: 'EnergyUseEstimateMethod',
  EnvironmentalRiskAssessment: 'EnvironmentalRiskAssessment',
  EsgBoardDiscussion: 'EsgBoardDiscussion',
  EsgBoardMember: 'EsgBoardMember',
  EsgRiskAssessment: 'EsgRiskAssessment',
  EvaluateProposedTarget: 'EvaluateProposedTarget',
  ExpenseCategories: 'ExpenseCategories',
  ExpenseCategoryEmissions: 'ExpenseCategoryEmissions',
  ExpenseCategoryShareMethod: 'ExpenseCategoryShareMethod',
  ExpensesNaics: 'ExpensesNaics',
  ExternalReporting: 'ExternalReporting',
  FacilityBiomassNonWoodUsageAmount: 'FacilityBiomassNonWoodUsageAmount',
  FacilityBiomassNonWoodUsageFileUpload:
    'FacilityBiomassNonWoodUsageFileUpload',
  FacilityBiomassNonWoodUsageUnit: 'FacilityBiomassNonWoodUsageUnit',
  FacilityBrackishSurfaceWaterOrSeawaterAmount:
    'FacilityBrackishSurfaceWaterOrSeawaterAmount',
  FacilityBrackishSurfaceWaterOrSeawaterFileUpload:
    'FacilityBrackishSurfaceWaterOrSeawaterFileUpload',
  FacilityBrackishSurfaceWaterOrSeawaterUnit:
    'FacilityBrackishSurfaceWaterOrSeawaterUnit',
  FacilityCleanPowerDescribeCleanPowerSources:
    'FacilityCleanPowerDescribeCleanPowerSources',
  FacilityCleanPowerDoesUseCleanPower: 'FacilityCleanPowerDoesUseCleanPower',
  FacilityCleanPowerRelevantReports: 'FacilityCleanPowerRelevantReports',
  FacilityCleanPowerTotalAmountInMWhCoveredByCleanPower:
    'FacilityCleanPowerTotalAmountInMWhCoveredByCleanPower',
  FacilityCoalUsageAmount: 'FacilityCoalUsageAmount',
  FacilityCoalUsageFileUpload: 'FacilityCoalUsageFileUpload',
  FacilityCoalUsageUnit: 'FacilityCoalUsageUnit',
  FacilityDetailedAllocationDoesSubMeterOrTrackProperties:
    'FacilityDetailedAllocationDoesSubMeterOrTrackProperties',
  FacilityDetailedAllocationDoesTrackMetricForCompanyProducts:
    'FacilityDetailedAllocationDoesTrackMetricForCompanyProducts',
  FacilityDetailedAllocationMetricUnit: 'FacilityDetailedAllocationMetricUnit',
  FacilityDetailedAllocationRelevantReport:
    'FacilityDetailedAllocationRelevantReport',
  FacilityDetailedAllocationTotalAmountOfMetricForCompany:
    'FacilityDetailedAllocationTotalAmountOfMetricForCompany',
  FacilityDetailedAllocationTotalAmountOfMetricForEntireFacility:
    'FacilityDetailedAllocationTotalAmountOfMetricForEntireFacility',
  FacilityElectrictyFromGridUsageAmount:
    'FacilityElectrictyFromGridUsageAmount',
  FacilityElectrictyFromGridUsageFileUpload:
    'FacilityElectrictyFromGridUsageFileUpload',
  FacilityElectrictyFromGridUsageUnit: 'FacilityElectrictyFromGridUsageUnit',
  FacilityEnergySources: 'FacilityEnergySources',
  FacilityGroundwaterAmount: 'FacilityGroundwaterAmount',
  FacilityGroundwaterFileUpload: 'FacilityGroundwaterFileUpload',
  FacilityGroundwaterUnit: 'FacilityGroundwaterUnit',
  FacilityInfoAddress: 'FacilityInfoAddress',
  FacilityInfoCountryOfOperation: 'FacilityInfoCountryOfOperation',
  FacilityInfoHeadcount: 'FacilityInfoHeadcount',
  FacilityInfoIndustry: 'FacilityInfoIndustry',
  FacilityInfoListAllProcesses: 'FacilityInfoListAllProcesses',
  FacilityInfoName: 'FacilityInfoName',
  FacilityMunicipalWaterAmount: 'FacilityMunicipalWaterAmount',
  FacilityMunicipalWaterFileUpload: 'FacilityMunicipalWaterFileUpload',
  FacilityMunicipalWaterUnit: 'FacilityMunicipalWaterUnit',
  FacilityNaturalGasUsageAmount: 'FacilityNaturalGasUsageAmount',
  FacilityNaturalGasUsageFileUpload: 'FacilityNaturalGasUsageFileUpload',
  FacilityNaturalGasUsageUnit: 'FacilityNaturalGasUsageUnit',
  FacilityOtherAmount: 'FacilityOtherAmount',
  FacilityOtherFileUpload: 'FacilityOtherFileUpload',
  FacilityOtherUnit: 'FacilityOtherUnit',
  FacilityPetrolUsageAmount: 'FacilityPetrolUsageAmount',
  FacilityPetrolUsageFileUpload: 'FacilityPetrolUsageFileUpload',
  FacilityPetrolUsageUnit: 'FacilityPetrolUsageUnit',
  FacilityProductionListOfTypesOfProducts:
    'FacilityProductionListOfTypesOfProducts',
  FacilityProductionTotalNumberOfUnits: 'FacilityProductionTotalNumberOfUnits',
  FacilityPropaneUsageAmount: 'FacilityPropaneUsageAmount',
  FacilityPropaneUsageFileUpload: 'FacilityPropaneUsageFileUpload',
  FacilityPropaneUsageUnit: 'FacilityPropaneUsageUnit',
  FacilityRainwaterAmount: 'FacilityRainwaterAmount',
  FacilityRainwaterFileUpload: 'FacilityRainwaterFileUpload',
  FacilityRainwaterUnit: 'FacilityRainwaterUnit',
  FacilitySolarUsageAmount: 'FacilitySolarUsageAmount',
  FacilitySolarUsageFileUpload: 'FacilitySolarUsageFileUpload',
  FacilitySolarUsageUnit: 'FacilitySolarUsageUnit',
  FacilityWastewaterAmount: 'FacilityWastewaterAmount',
  FacilityWastewaterFileUpload: 'FacilityWastewaterFileUpload',
  FacilityWastewaterUnit: 'FacilityWastewaterUnit',
  FacilityWaterSources: 'FacilityWaterSources',
  FacilityWoodUsageAmount: 'FacilityWoodUsageAmount',
  FacilityWoodUsageFileUpload: 'FacilityWoodUsageFileUpload',
  FacilityWoodUsageUnit: 'FacilityWoodUsageUnit',
  FootprintMethodologyImportReport: 'FootprintMethodologyImportReport',
  FootprintProviderName: 'FootprintProviderName',
  GhgInventoryInLastYear: 'GhgInventoryInLastYear',
  GhgInventoryInLastYearMethodology: 'GhgInventoryInLastYearMethodology',
  GhgScope1CustomerShareOfEmissionsTonnesCo2e:
    'GhgScope1CustomerShareOfEmissionsTonnesCo2e',
  GhgScope1FugitiveEmissionsRevenueIntensity:
    'GhgScope1FugitiveEmissionsRevenueIntensity',
  GhgScope1FugitiveEmissionsTonnesCo2e: 'GhgScope1FugitiveEmissionsTonnesCo2e',
  GhgScope1Methodology: 'GhgScope1Methodology',
  GhgScope1MobileCombustionRevenueIntensity:
    'GhgScope1MobileCombustionRevenueIntensity',
  GhgScope1MobileCombustionTonnesCo2e: 'GhgScope1MobileCombustionTonnesCo2e',
  GhgScope1RevenueIntensity: 'GhgScope1RevenueIntensity',
  GhgScope1StationaryCombustionRevenueIntensity:
    'GhgScope1StationaryCombustionRevenueIntensity',
  GhgScope1StationaryCombustionTonnesCo2e:
    'GhgScope1StationaryCombustionTonnesCo2e',
  GhgScope1TonnesCo2e: 'GhgScope1TonnesCo2e',
  GhgScope2CustomerShareOfEmissionsTonnesCo2e:
    'GhgScope2CustomerShareOfEmissionsTonnesCo2e',
  GhgScope2DisclosureIncludesLeasedBuildings:
    'GhgScope2DisclosureIncludesLeasedBuildings',
  GhgScope2LocationBasedRevenueIntensity:
    'GhgScope2LocationBasedRevenueIntensity',
  GhgScope2LocationBasedTonnesCo2e: 'GhgScope2LocationBasedTonnesCo2e',
  GhgScope2MarketBasedRevenueIntensity: 'GhgScope2MarketBasedRevenueIntensity',
  GhgScope2MarketBasedTonnesCo2e: 'GhgScope2MarketBasedTonnesCo2e',
  GhgScope2PurchasedCoolingWaterRevenueIntensity:
    'GhgScope2PurchasedCoolingWaterRevenueIntensity',
  GhgScope2PurchasedCoolingWaterTonnesCo2e:
    'GhgScope2PurchasedCoolingWaterTonnesCo2e',
  GhgScope2PurchasedHeatSteamRevenueIntensity:
    'GhgScope2PurchasedHeatSteamRevenueIntensity',
  GhgScope2PurchasedHeatSteamTonnesCo2e:
    'GhgScope2PurchasedHeatSteamTonnesCo2e',
  GhgScope3ByCategoryRevenueIntensity: 'GhgScope3ByCategoryRevenueIntensity',
  GhgScope3ByCategoryTonnesCo2e: 'GhgScope3ByCategoryTonnesCo2e',
  GhgScope3CustomerShareOfEmissionsTonnesCo2e:
    'GhgScope3CustomerShareOfEmissionsTonnesCo2e',
  GhgScope3Methodology: 'GhgScope3Methodology',
  GhgScope3TotalRevenueIntensity: 'GhgScope3TotalRevenueIntensity',
  GhgScope3TotalTonnesCo2e: 'GhgScope3TotalTonnesCo2e',
  HasEnvironmentalTeam: 'HasEnvironmentalTeam',
  HasMeasuredScope3: 'HasMeasuredScope3',
  HasSbtTargets: 'HasSbtTargets',
  HasSubmittedSbtiTargets: 'HasSubmittedSbtiTargets',
  HasTargets: 'HasTargets',
  HaveCommittedSbtiTargets: 'HaveCommittedSbtiTargets',
  HaveMeasuredEmissions: 'HaveMeasuredEmissions',
  HaveMeasuredEmissionsV2: 'HaveMeasuredEmissionsV2',
  Headcount: 'Headcount',
  HealthBenefits: 'HealthBenefits',
  HelpNeeded: 'HelpNeeded',
  HelpNeededMeasuringEmissions: 'HelpNeededMeasuringEmissions',
  HelpNeededReductionTargets: 'HelpNeededReductionTargets',
  HelpNeededSbtTargets: 'HelpNeededSbtTargets',
  HelpNeededSettingTargets: 'HelpNeededSettingTargets',
  HelpNeededSustainabilityProgram: 'HelpNeededSustainabilityProgram',
  HelpNeededToSetSbtTargets: 'HelpNeededToSetSbtTargets',
  HelpNeededToSetTargets: 'HelpNeededToSetTargets',
  ImportReport: 'ImportReport',
  LearningBusinessCaseCorporateClimateActionBenefits:
    'LearningBusinessCaseCorporateClimateActionBenefits',
  LearningBusinessCaseEmployees: 'LearningBusinessCaseEmployees',
  LearningBusinessCaseMarketTrends: 'LearningBusinessCaseMarketTrends',
  LearningBusinessCaseOperationalCosts: 'LearningBusinessCaseOperationalCosts',
  LearningBusinessCaseRisk: 'LearningBusinessCaseRisk',
  LearningCorporateClimateActionReduceYourEmissions:
    'LearningCorporateClimateActionReduceYourEmissions',
  LearningCorporateClimateActionStep2: 'LearningCorporateClimateActionStep2',
  LearningCorporateClimateActionStep3: 'LearningCorporateClimateActionStep3',
  LearningEngageYourTeamEngagingEmployeesTactics:
    'LearningEngageYourTeamEngagingEmployeesTactics',
  LearningEngageYourTeamEngagingLeadershipTactics:
    'LearningEngageYourTeamEngagingLeadershipTactics',
  LearningEngageYourTeamIdentifyingStakeholders:
    'LearningEngageYourTeamIdentifyingStakeholders',
  LearningFinanceImpAuditSlider: 'LearningFinanceImpAuditSlider',
  LearningFinanceImpAuditingVideoFinished:
    'LearningFinanceImpAuditingVideoFinished',
  LearningFinanceImpDataManagementSlider:
    'LearningFinanceImpDataManagementSlider',
  LearningFinanceImpDataManagementVideoFinished:
    'LearningFinanceImpDataManagementVideoFinished',
  LearningFinanceImpDeepDiveVideoFinished:
    'LearningFinanceImpDeepDiveVideoFinished',
  LearningFinanceImpEmissionsQuantificationVideoFinished:
    'LearningFinanceImpEmissionsQuantificationVideoFinished',
  LearningFinanceImpManagementToolsVideoFinished:
    'LearningFinanceImpManagementToolsVideoFinished',
  LearningFinanceImpRecap: 'LearningFinanceImpRecap',
  LearningFinanceImpValue: 'LearningFinanceImpValue',
  LearningLaunchingAClimateProgramAmbitionLevelSpectrum:
    'LearningLaunchingAClimateProgramAmbitionLevelSpectrum',
  LearningLaunchingAClimateProgramLongTerm:
    'LearningLaunchingAClimateProgramLongTerm',
  LearningLaunchingAClimateProgramSupport:
    'LearningLaunchingAClimateProgramSupport',
  LearningLaunchingAClimateProgramVoluntaryReporting:
    'LearningLaunchingAClimateProgramVoluntaryReporting',
  LearningMeasuringEmissionsBusinessActivities:
    'LearningMeasuringEmissionsBusinessActivities',
  LearningMeasuringEmissionsCarbonAccounting:
    'LearningMeasuringEmissionsCarbonAccounting',
  LearningMeasuringEmissionsEmissionsFactors:
    'LearningMeasuringEmissionsEmissionsFactors',
  LearningMeasuringEmissionsScopeOne: 'LearningMeasuringEmissionsScopeOne',
  LearningMeasuringEmissionsScopeThree: 'LearningMeasuringEmissionsScopeThree',
  LearningMeasuringEmissionsScopeTwo: 'LearningMeasuringEmissionsScopeTwo',
  LearningMeasuringEmissionsSpectrum: 'LearningMeasuringEmissionsSpectrum',
  LearningScienceBasedTargetsClimateScienceBasics:
    'LearningScienceBasedTargetsClimateScienceBasics',
  LearningScienceBasedTargetsDefinition3:
    'LearningScienceBasedTargetsDefinition3',
  LearningScienceBasedTargetsDevelopTargetKeyQuestions:
    'LearningScienceBasedTargetsDevelopTargetKeyQuestions',
  LearningScienceBasedTargetsNumberOfCompaniesCommitted:
    'LearningScienceBasedTargetsNumberOfCompaniesCommitted',
  LearningVideosFinished: 'LearningVideosFinished',
  LivingWage: 'LivingWage',
  MeasurementProjectsToShare: 'MeasurementProjectsToShare',
  ModifiedProposeTarget: 'ModifiedProposeTarget',
  NetChangeEmployeesNonOrganic: 'NetChangeEmployeesNonOrganic',
  NoGhgInventoryHelpNeeded: 'NoGhgInventoryHelpNeeded',
  NoSustainabilityProgramHelpNeeded: 'NoSustainabilityProgramHelpNeeded',
  Operations: 'Operations',
  OtherTargets: 'OtherTargets',
  Paperless: 'Paperless',
  ParentalLeave: 'ParentalLeave',
  PlanOfRecordCommitToMeasureGhg: 'PlanOfRecordCommitToMeasureGhg',
  PlanOfRecordCommitToMeasureGhgNoExplain:
    'PlanOfRecordCommitToMeasureGhgNoExplain',
  PlanOfRecordCommitToWorkingTowardsSbt:
    'PlanOfRecordCommitToWorkingTowardsSbt',
  PlanOfRecordCommitToWorkingTowardsSbtNoExplain:
    'PlanOfRecordCommitToWorkingTowardsSbtNoExplain',
  PlanOfRecordSubmittedSbtLetter: 'PlanOfRecordSubmittedSbtLetter',
  PlanOfRecordSubmittedSbtLetterExpectedDate:
    'PlanOfRecordSubmittedSbtLetterExpectedDate',
  PlanOfRecordSubmittedSbtLetterFileUpload:
    'PlanOfRecordSubmittedSbtLetterFileUpload',
  PlanOfRecordSubmittedSbtLetterNoExplain:
    'PlanOfRecordSubmittedSbtLetterNoExplain',
  PleeEstimationOutputs: 'PleeEstimationOutputs',
  ProcessCheckEmissionsAlreadyAllocated:
    'ProcessCheckEmissionsAlreadyAllocated',
  ProviderVerifiedFootprint: 'ProviderVerifiedFootprint',
  ReducedTravelPercentage: 'ReducedTravelPercentage',
  ReductionInitiatives: 'ReductionInitiatives',
  ReductionInitiativesSet: 'ReductionInitiativesSet',
  ReductionTargets: 'ReductionTargets',
  ReductionTargetsPlanOfAction: 'ReductionTargetsPlanOfAction',
  ReductionTargetsSet: 'ReductionTargetsSet',
  RemainingSitesElectricityMWh: 'RemainingSitesElectricityMWh',
  RenewableEnergyTargets: 'RenewableEnergyTargets',
  Revenue: 'Revenue',
  RevenueCurrency: 'RevenueCurrency',
  SasbIndustry: 'SasbIndustry',
  SasbSector: 'SasbSector',
  SbtDevelopTargetHasDevelopedTarget: 'SbtDevelopTargetHasDevelopedTarget',
  SbtEngagementStatus: 'SbtEngagementStatus',
  SbtGhgMeasurementHasCompletedMeasurement:
    'SbtGhgMeasurementHasCompletedMeasurement',
  SbtSubmitTargetHasSubmittedTarget: 'SbtSubmitTargetHasSubmittedTarget',
  SbtSubmitTargetSubmissionLetterImport:
    'SbtSubmitTargetSubmissionLetterImport',
  SbtiCommitmentLetterImport: 'SbtiCommitmentLetterImport',
  Scope1And2EmissionTargets: 'Scope1And2EmissionTargets',
  Scope3EmissionTargets: 'Scope3EmissionTargets',
  SfdrBiodiversitySensitiveAreas: 'SfdrBiodiversitySensitiveAreas',
  SfdrBoardGenderDiversity: 'SfdrBoardGenderDiversity',
  SfdrEmissionsToWater: 'SfdrEmissionsToWater',
  SfdrEnergyConsumptionHighImpact: 'SfdrEnergyConsumptionHighImpact',
  SfdrExposureToControversialWeapons: 'SfdrExposureToControversialWeapons',
  SfdrFossilFuelSector: 'SfdrFossilFuelSector',
  SfdrGenderPayGap: 'SfdrGenderPayGap',
  SfdrHazardousWaste: 'SfdrHazardousWaste',
  SfdrUnGlobalCompactCompliance: 'SfdrUnGlobalCompactCompliance',
  SfdrUnGlobalCompactViolations: 'SfdrUnGlobalCompactViolations',
  ShareEmissionsPreference: 'ShareEmissionsPreference',
  ShareFootprint: 'ShareFootprint',
  ShareFootprintDisclosureData: 'ShareFootprintDisclosureData',
  ShareMethod: 'ShareMethod',
  ShareMethodV2: 'ShareMethodV2',
  SitesElectricityData: 'SitesElectricityData',
  SmeHeadcount: 'SmeHeadcount',
  SubmittedSbtiCommitment: 'SubmittedSbtiCommitment',
  SustainabilityCommitments: 'SustainabilityCommitments',
  SustainabilityInitiatives: 'SustainabilityInitiatives',
  SustainabilityInitiativesWithDetails: 'SustainabilityInitiativesWithDetails',
  SustainabilityProgram: 'SustainabilityProgram',
  TopMaterialIssues: 'TopMaterialIssues',
  TotalEmployeesPriorYearEnd: 'TotalEmployeesPriorYearEnd',
  TotalEmployeesYearEnd: 'TotalEmployeesYearEnd',
  TotalEnergyConsumption: 'TotalEnergyConsumption',
  VisibilityOfCountryForAnimalFeed: 'VisibilityOfCountryForAnimalFeed',
  WasteManagement: 'WasteManagement',
  WorkplaceFatality: 'WorkplaceFatality',
  WorkplaceInjury: 'WorkplaceInjury',
  YearFullyRenewableForCustomer: 'YearFullyRenewableForCustomer',
} as const;

export type GQCompanySurveyQuestionKey =
  (typeof GQCompanySurveyQuestionKey)[keyof typeof GQCompanySurveyQuestionKey];
export const GQCompanySurveyStatus = {
  Approved: 'Approved',
  AutoApproved: 'AutoApproved',
  ChangesRequested: 'ChangesRequested',
  Closed: 'Closed',
  InProgress: 'InProgress',
  LoggedIn: 'LoggedIn',
  Submitted: 'Submitted',
  SurveyCreated: 'SurveyCreated',
} as const;

export type GQCompanySurveyStatus =
  (typeof GQCompanySurveyStatus)[keyof typeof GQCompanySurveyStatus];
export const GQCompanySurveyStep = {
  Activities: 'Activities',
  AllocateEmissionsMethod: 'AllocateEmissionsMethod',
  CarbonMeasurementPlan: 'CarbonMeasurementPlan',
  CleanPowerInitiativeConfirm: 'CleanPowerInitiativeConfirm',
  CleanPowerInitiativeElectricitySource:
    'CleanPowerInitiativeElectricitySource',
  CleanPowerInitiativeIntro: 'CleanPowerInitiativeIntro',
  Company: 'Company',
  Confirm: 'Confirm',
  CustomQuestions: 'CustomQuestions',
  ElectricitySites: 'ElectricitySites',
  ElectricitySource: 'ElectricitySource',
  EmissionAllocation: 'EmissionAllocation',
  Environmental: 'Environmental',
  EsgdcSocial: 'EsgdcSocial',
  EstimationCompanyInfo: 'EstimationCompanyInfo',
  ExpenseCategoryEmissions: 'ExpenseCategoryEmissions',
  ExpenseCategoryIntro: 'ExpenseCategoryIntro',
  ExpenseCategoryShareMethod: 'ExpenseCategoryShareMethod',
  Expenses: 'Expenses',
  FacilityBiomassNonWoodReporting: 'FacilityBiomassNonWoodReporting',
  FacilityBrackishSurfaceWaterOrSeawaterReporting:
    'FacilityBrackishSurfaceWaterOrSeawaterReporting',
  FacilityCleanPower: 'FacilityCleanPower',
  FacilityCoalReporting: 'FacilityCoalReporting',
  FacilityDetailedAllocation: 'FacilityDetailedAllocation',
  FacilityElectrictyFromGridReporting: 'FacilityElectrictyFromGridReporting',
  FacilityEnergySources: 'FacilityEnergySources',
  FacilityGroundwaterReporting: 'FacilityGroundwaterReporting',
  FacilityInfo: 'FacilityInfo',
  FacilityMunicipalWaterReporting: 'FacilityMunicipalWaterReporting',
  FacilityNaturalGasReporting: 'FacilityNaturalGasReporting',
  FacilityOtherReporting: 'FacilityOtherReporting',
  FacilityPetrolReporting: 'FacilityPetrolReporting',
  FacilityProductionInfo: 'FacilityProductionInfo',
  FacilityPropaneReporting: 'FacilityPropaneReporting',
  FacilityRainwaterReporting: 'FacilityRainwaterReporting',
  FacilitySolarReporting: 'FacilitySolarReporting',
  FacilityWastewaterReporting: 'FacilityWastewaterReporting',
  FacilityWaterSources: 'FacilityWaterSources',
  FacilityWoodReporting: 'FacilityWoodReporting',
  FootprintQuality: 'FootprintQuality',
  GhgMeasurementProgress: 'GhgMeasurementProgress',
  Governance: 'Governance',
  HaveMeasuredMethod: 'HaveMeasuredMethod',
  ImportReport: 'ImportReport',
  Intro: 'Intro',
  LearningContent: 'LearningContent',
  LearningVideo: 'LearningVideo',
  ProposeTarget: 'ProposeTarget',
  ProposeTargetConfirm: 'ProposeTargetConfirm',
  ProposeTargetIntro: 'ProposeTargetIntro',
  ProposeTargetModify: 'ProposeTargetModify',
  ReductionInitiatives: 'ReductionInitiatives',
  ReductionTargets: 'ReductionTargets',
  Revenue: 'Revenue',
  SbtCommitmentCommitted: 'SbtCommitmentCommitted',
  SbtCommitmentConfirm: 'SbtCommitmentConfirm',
  SbtCommitmentIntro: 'SbtCommitmentIntro',
  SbtCommitmentUploadLetter: 'SbtCommitmentUploadLetter',
  SbtDevelopTargetConfirm: 'SbtDevelopTargetConfirm',
  SbtDevelopTargetIntro: 'SbtDevelopTargetIntro',
  SbtDevelopTargetProgress: 'SbtDevelopTargetProgress',
  SbtGhgMeasurementConfirm: 'SbtGhgMeasurementConfirm',
  SbtGhgMeasurementIntro: 'SbtGhgMeasurementIntro',
  SbtGhgMeasurementProgress: 'SbtGhgMeasurementProgress',
  SbtGhgMeasurementShareMethod: 'SbtGhgMeasurementShareMethod',
  SbtProgress: 'SbtProgress',
  SbtProgressConfirm: 'SbtProgressConfirm',
  SbtProgressIntro: 'SbtProgressIntro',
  SbtProgressSbtEngagementStatus: 'SbtProgressSbtEngagementStatus',
  SbtSubmitTargetConfirm: 'SbtSubmitTargetConfirm',
  SbtSubmitTargetIntro: 'SbtSubmitTargetIntro',
  SbtSubmitTargetProgress: 'SbtSubmitTargetProgress',
  Scope1And2Emission: 'Scope1And2Emission',
  Scope3Emission: 'Scope3Emission',
  SfdrClimateAndEnvironmentIndicators: 'SfdrClimateAndEnvironmentIndicators',
  SfdrSocialIndicators: 'SfdrSocialIndicators',
  ShareMethod: 'ShareMethod',
  SmeStatus: 'SmeStatus',
  Social: 'Social',
  SustainabilityMaturity: 'SustainabilityMaturity',
  SustainabilityMaturityTargetFollowup: 'SustainabilityMaturityTargetFollowup',
} as const;

export type GQCompanySurveyStep =
  (typeof GQCompanySurveyStep)[keyof typeof GQCompanySurveyStep];
export type GQCompanyTickerSymbol = GQIdInterface & {
  __typename?: 'CompanyTickerSymbol';
  editor: GQUser;
  exchangeSymbol: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSourcePrimary: Scalars['Boolean']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
  tickerSymbol: Scalars['String']['output'];
};

export type GQCompanyTickerSymbolInput = {
  exchangeSymbol?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isSourcePrimary: Scalars['Boolean']['input'];
  sourceId: Scalars['ID']['input'];
  tickerSymbol: Scalars['String']['input'];
};

export type GQCompanyUrl = GQIdInterface & {
  __typename?: 'CompanyUrl';
  createdAt: Scalars['DateTime']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type GQCompanyUrlInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  sourceId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type GQCompleteMeasurementProjectInput = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQCompleteMeasurementProjectPayload = {
  __typename?: 'CompleteMeasurementProjectPayload';
  measurementProject: GQMeasurementProject;
};

export type GQCompleteOnboardingInput = {
  kind: GQOnboardingKind;
  userId: Scalars['ID']['input'];
};

export type GQCompleteOnboardingPayload = {
  __typename?: 'CompleteOnboardingPayload';
  user: Maybe<GQUser>;
  userOnboardingsCompleted: Array<GQOnboardingKind>;
};

export type GQCompleteUserUploadTaskInput = {
  /** @deprecated Not used anymore, delete 8/1/2024 */
  markedCompleteManually?: InputMaybe<Scalars['Boolean']['input']>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQCompleteUserUploadTaskPayload = {
  __typename?: 'CompleteUserUploadTaskPayload';
  measurementProject: GQMeasurementProject;
  userUploadTask: GQUserUploadTask;
};

export const GQCompletedLifecycleStage = {
  Kickoff: 'Kickoff',
  Measurement: 'Measurement',
  Reductions: 'Reductions',
} as const;

export type GQCompletedLifecycleStage =
  (typeof GQCompletedLifecycleStage)[keyof typeof GQCompletedLifecycleStage];
export type GQCompositeDataOrgDetail = {
  __typename?: 'CompositeDataOrgDetail';
  latestPublishedRevision: Maybe<GQReferenceDataRevision>;
  organization: Maybe<GQOrganization>;
};

export type GQComputeAllReportAnswersInput = {
  reportId: Scalars['ID']['input'];
};

export type GQComputeReportAnswerWarningsForOrgInput = {
  componentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId: Scalars['ID']['input'];
};

export type GQComputeReportAnswerWarningsForOrgPayload = {
  __typename?: 'ComputeReportAnswerWarningsForOrgPayload';
  numVerified: Scalars['Int']['output'];
};

export type GQComputeReportQuestionMetricInput = {
  dataFilters?: InputMaybe<GQCompanyDataFiltersForMetric>;
  metricId: Scalars['ID']['input'];
  metricInputs: Scalars['JSONString']['input'];
  questionIdentifier: Scalars['String']['input'];
};

export type GQComputedFilterExpressionPrimitive = {
  __typename?: 'ComputedFilterExpressionPrimitive';
  field: GQComputedFilterField;
  operator: GQFilterOperator;
  value: Array<Scalars['String']['output']>;
};

/** Computed fields are derived from attributes of the footprint. */
export const GQComputedFilterField = {
  ElectricityType: 'electricityType',
} as const;

export type GQComputedFilterField =
  (typeof GQComputedFilterField)[keyof typeof GQComputedFilterField];
export type GQConfigLinterError = {
  __typename?: 'ConfigLinterError';
  message: Scalars['String']['output'];
  reportObjectId: Scalars['ID']['output'];
  type: GQConfigLinterErrorType;
};

export const GQConfigLinterErrorType = {
  Error: 'Error',
  Warning: 'Warning',
} as const;

export type GQConfigLinterErrorType =
  (typeof GQConfigLinterErrorType)[keyof typeof GQConfigLinterErrorType];
export const GQConfigLinterStatus = {
  Invalid: 'Invalid',
  Valid: 'Valid',
  ValidWithWarnings: 'ValidWithWarnings',
} as const;

export type GQConfigLinterStatus =
  (typeof GQConfigLinterStatus)[keyof typeof GQConfigLinterStatus];
export type GQConfigureAutoBartInput = {
  activityDataTableId?: InputMaybe<Scalars['String']['input']>;
  datasourceId: Scalars['String']['input'];
  measurementProjectId: Scalars['String']['input'];
};

export type GQConfigureAutoBartPayload = {
  __typename?: 'ConfigureAutoBartPayload';
  assignedAdt: Maybe<GQActivityDataTable>;
  unassignedAdt: Maybe<GQActivityDataTable>;
};

export type GQConfirmMarketplacePurchaseInput = {
  eacMetadata?: InputMaybe<GQUpdateMarketplacePurchaseEacMetadataInput>;
  id: Scalars['ID']['input'];
};

export type GQConfirmMarketplacePurchasePayload = {
  __typename?: 'ConfirmMarketplacePurchasePayload';
  purchase: GQMarketplacePurchase;
};

export type GQConfirmUtilitySchemaInput = {
  userUploadCandidateId: Scalars['ID']['input'];
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQConfirmUtilitySchemaPayload = {
  __typename?: 'ConfirmUtilitySchemaPayload';
  error: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GQContextOrgInfo = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQContextRelationInterface = {
  adminUrl: Scalars['String']['output'];
  ancestorRelations: Array<
    | GQActivityDataTable
    | GQDataIssue
    | GQDataset
    | GQDatasource
    | GQFileMetadata
    | GQFinancialsReviewItem
    | GQFootprintQuestion
    | GQMeasurementProject
    | GQMeasurementVerificationItemQuestion
  >;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export const GQContractingStatus = {
  InDiscussion: 'InDiscussion',
  MovingToMeasurement: 'MovingToMeasurement',
  NotMovingForward: 'NotMovingForward',
  Referred: 'Referred',
} as const;

export type GQContractingStatus =
  (typeof GQContractingStatus)[keyof typeof GQContractingStatus];
export type GQConversionChainItemForDashboard =
  | GQAssumptionRecordForDashboard
  | GQUnitConversionRecord;

export type GQConversionFactorForDashboard = GQIdInterface & {
  __typename?: 'ConversionFactorForDashboard';
  assumptions: Array<GQAssumptionRecordForDashboard>;
  conversionChain: Maybe<Array<GQConversionChainItemForDashboard>>;
  conversionFactorId: Scalars['String']['output'];
  conversionMultiplier: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  inputUnit: Scalars['String']['output'];
  outputUnit: Scalars['String']['output'];
};

export type GQConvertCtsBackInput = {
  ctsId: Scalars['ID']['input'];
};

export type GQConvertCtsBackPayload = {
  __typename?: 'ConvertCtsBackPayload';
  cts: Maybe<GQCustomerTargetSchema>;
};

export type GQConvertCtsToTSchemaInput = {
  ctsId: Scalars['ID']['input'];
};

export type GQConvertCtsToTSchemaPayload = {
  __typename?: 'ConvertCtsToTSchemaPayload';
  cts: Maybe<GQCustomerTargetSchema>;
};

export type GQCopyEngagementTaskConfigToOrgForAdminInput = {
  orgId: Scalars['ID']['input'];
  taskConfigId: Scalars['ID']['input'];
};

export type GQCopyReportQuestionAnswersInput = {
  destinationQuestionId: Scalars['ID']['input'];
  isDryRun: Scalars['Boolean']['input'];
  reportAnswerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sourceQuestionId: Scalars['ID']['input'];
};

export type GQCopyReportQuestionAnswersPayload = {
  __typename?: 'CopyReportQuestionAnswersPayload';
  numCopied: Scalars['Int']['output'];
};

export const GQCountryAlpha2 = {
  Ad: 'AD',
  Ae: 'AE',
  Af: 'AF',
  Ag: 'AG',
  Ai: 'AI',
  Al: 'AL',
  Am: 'AM',
  Ao: 'AO',
  Aq: 'AQ',
  Ar: 'AR',
  As: 'AS',
  At: 'AT',
  Au: 'AU',
  Aw: 'AW',
  Ax: 'AX',
  Az: 'AZ',
  Ba: 'BA',
  Bb: 'BB',
  Bd: 'BD',
  Be: 'BE',
  Bf: 'BF',
  Bg: 'BG',
  Bh: 'BH',
  Bi: 'BI',
  Bj: 'BJ',
  Bl: 'BL',
  Bm: 'BM',
  Bn: 'BN',
  Bo: 'BO',
  Bq: 'BQ',
  Br: 'BR',
  Bs: 'BS',
  Bt: 'BT',
  Bv: 'BV',
  Bw: 'BW',
  By: 'BY',
  Bz: 'BZ',
  Ca: 'CA',
  Cc: 'CC',
  Cd: 'CD',
  Cf: 'CF',
  Cg: 'CG',
  Ch: 'CH',
  Ci: 'CI',
  Ck: 'CK',
  Cl: 'CL',
  Cm: 'CM',
  Cn: 'CN',
  Co: 'CO',
  Cr: 'CR',
  Cu: 'CU',
  Cv: 'CV',
  Cw: 'CW',
  Cx: 'CX',
  Cy: 'CY',
  Cz: 'CZ',
  De: 'DE',
  Dj: 'DJ',
  Dk: 'DK',
  Dm: 'DM',
  Do: 'DO',
  Dz: 'DZ',
  Ec: 'EC',
  Ee: 'EE',
  Eg: 'EG',
  Eh: 'EH',
  Er: 'ER',
  Es: 'ES',
  Et: 'ET',
  Fi: 'FI',
  Fj: 'FJ',
  Fk: 'FK',
  Fm: 'FM',
  Fo: 'FO',
  Fr: 'FR',
  Ga: 'GA',
  Gb: 'GB',
  Gd: 'GD',
  Ge: 'GE',
  Gf: 'GF',
  Gg: 'GG',
  Gh: 'GH',
  Gi: 'GI',
  Gl: 'GL',
  Gm: 'GM',
  Gn: 'GN',
  Gp: 'GP',
  Gq: 'GQ',
  Gr: 'GR',
  Gs: 'GS',
  Gt: 'GT',
  Gu: 'GU',
  Gw: 'GW',
  Gy: 'GY',
  Hk: 'HK',
  Hm: 'HM',
  Hn: 'HN',
  Hr: 'HR',
  Ht: 'HT',
  Hu: 'HU',
  Id: 'ID',
  Ie: 'IE',
  Il: 'IL',
  Im: 'IM',
  In: 'IN',
  Io: 'IO',
  Iq: 'IQ',
  Ir: 'IR',
  Is: 'IS',
  It: 'IT',
  Je: 'JE',
  Jm: 'JM',
  Jo: 'JO',
  Jp: 'JP',
  Ke: 'KE',
  Kg: 'KG',
  Kh: 'KH',
  Ki: 'KI',
  Km: 'KM',
  Kn: 'KN',
  Kp: 'KP',
  Kr: 'KR',
  Kw: 'KW',
  Ky: 'KY',
  Kz: 'KZ',
  La: 'LA',
  Lb: 'LB',
  Lc: 'LC',
  Li: 'LI',
  Lk: 'LK',
  Lr: 'LR',
  Ls: 'LS',
  Lt: 'LT',
  Lu: 'LU',
  Lv: 'LV',
  Ly: 'LY',
  Ma: 'MA',
  Mc: 'MC',
  Md: 'MD',
  Me: 'ME',
  Mf: 'MF',
  Mg: 'MG',
  Mh: 'MH',
  Mk: 'MK',
  Ml: 'ML',
  Mm: 'MM',
  Mn: 'MN',
  Mo: 'MO',
  Mp: 'MP',
  Mq: 'MQ',
  Mr: 'MR',
  Ms: 'MS',
  Mt: 'MT',
  Mu: 'MU',
  Mv: 'MV',
  Mw: 'MW',
  Mx: 'MX',
  My: 'MY',
  Mz: 'MZ',
  Na: 'NA',
  Nc: 'NC',
  Ne: 'NE',
  Nf: 'NF',
  Ng: 'NG',
  Ni: 'NI',
  Nl: 'NL',
  No: 'NO',
  Np: 'NP',
  Nr: 'NR',
  Nu: 'NU',
  Nz: 'NZ',
  Om: 'OM',
  Pa: 'PA',
  Pe: 'PE',
  Pf: 'PF',
  Pg: 'PG',
  Ph: 'PH',
  Pk: 'PK',
  Pl: 'PL',
  Pm: 'PM',
  Pn: 'PN',
  Pr: 'PR',
  Ps: 'PS',
  Pt: 'PT',
  Pw: 'PW',
  Py: 'PY',
  Qa: 'QA',
  Re: 'RE',
  Ro: 'RO',
  Rs: 'RS',
  Ru: 'RU',
  Rw: 'RW',
  Sa: 'SA',
  Sb: 'SB',
  Sc: 'SC',
  Sd: 'SD',
  Se: 'SE',
  Sg: 'SG',
  Sh: 'SH',
  Si: 'SI',
  Sj: 'SJ',
  Sk: 'SK',
  Sl: 'SL',
  Sm: 'SM',
  Sn: 'SN',
  So: 'SO',
  Sr: 'SR',
  Ss: 'SS',
  St: 'ST',
  Sv: 'SV',
  Sx: 'SX',
  Sy: 'SY',
  Sz: 'SZ',
  Tc: 'TC',
  Td: 'TD',
  Tf: 'TF',
  Tg: 'TG',
  Th: 'TH',
  Tj: 'TJ',
  Tk: 'TK',
  Tl: 'TL',
  Tm: 'TM',
  Tn: 'TN',
  To: 'TO',
  Tr: 'TR',
  Tt: 'TT',
  Tv: 'TV',
  Tw: 'TW',
  Tz: 'TZ',
  Ua: 'UA',
  Ug: 'UG',
  Um: 'UM',
  Us: 'US',
  Uy: 'UY',
  Uz: 'UZ',
  Va: 'VA',
  Vc: 'VC',
  Ve: 'VE',
  Vg: 'VG',
  Vi: 'VI',
  Vn: 'VN',
  Vu: 'VU',
  Wf: 'WF',
  Ws: 'WS',
  Ye: 'YE',
  Yt: 'YT',
  Za: 'ZA',
  Zm: 'ZM',
  Zw: 'ZW',
} as const;

export type GQCountryAlpha2 =
  (typeof GQCountryAlpha2)[keyof typeof GQCountryAlpha2];
export type GQCountryProjectedEnergyConsumption = {
  __typename?: 'CountryProjectedEnergyConsumption';
  country: Scalars['String']['output'];
  scope2: Scalars['Float']['output'];
  scope33: Scalars['Float']['output'];
  scope37: Scalars['Float']['output'];
};

export type GQCreateActivityDataTableInput = {
  /** @deprecated Field no longer supported */
  datasourceId?: InputMaybe<Scalars['ID']['input']>;
  datasourceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterEndYearMonth: Scalars['YearMonth']['input'];
  filterStartYearMonth: Scalars['YearMonth']['input'];
  measurementProjectId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  primaryDatasourceId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreateActivityDataTablePayload = {
  __typename?: 'CreateActivityDataTablePayload';
  activityDataTables: Maybe<GQActivityDataTableConnection>;
  table: GQActivityDataTable;
};

export type GQCreateActivityDataTablePayloadActivityDataTablesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQCreateAdminUploadInput = {
  category: GQFileCategory;
  directory: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  revisionRoot?: InputMaybe<Scalars['ID']['input']>;
  size: Scalars['Int53']['input'];
};

export type GQCreateAdminUploadPayload = {
  __typename?: 'CreateAdminUploadPayload';
  fileId: Scalars['ID']['output'];
  remotePath: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GQCreateApiKeyInput = {
  orgId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  testMode: Scalars['Boolean']['input'];
};

export type GQCreateApiKeyPayload = {
  __typename?: 'CreateApiKeyPayload';
  key: GQApiKey;
};

export type GQCreateArcadiaIntegrationInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type GQCreateAssetCommentAttachmentInput = {
  contentSize: Scalars['Int']['input'];
  filename: Scalars['String']['input'];
};

export type GQCreateAssetCommentInput = {
  assetCommercialRealEstateId?: InputMaybe<Scalars['ID']['input']>;
  assetCorporateId?: InputMaybe<Scalars['ID']['input']>;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  assetPersonalMotorVehicleInsuranceId?: InputMaybe<Scalars['ID']['input']>;
  assetSovereignBondId?: InputMaybe<Scalars['ID']['input']>;
  attachments: Array<GQCreateAssetCommentAttachmentInput>;
  commentText: Scalars['String']['input'];
};

export type GQCreateAssetCommentPayload = {
  __typename?: 'CreateAssetCommentPayload';
  assetComment: GQAssetComment;
  attachmentMetadata: Array<GQAttachmentMetadata>;
};

export type GQCreateAssetCorporateInput = {
  action: GQFinanceAuditAction;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  buildingSizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  companyBea?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  companyIsin?: InputMaybe<Scalars['String']['input']>;
  companyLei?: InputMaybe<Scalars['String']['input']>;
  companyTicker?: InputMaybe<Scalars['String']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryAlpha2?: InputMaybe<GQCountryAlpha2>;
  currencyCode: GQCurrencyCode;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQCreateAssetCorporatePayload = {
  __typename?: 'CreateAssetCorporatePayload';
  asset: GQAssetCorporate;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetGroupInput = {
  action: GQFinanceAuditAction;
  assetClass?: InputMaybe<GQFundAssetClass>;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  buildingSizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  currencyCode: GQCurrencyCode;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQCreateAssetGroupPayload = {
  __typename?: 'CreateAssetGroupPayload';
  asset: GQAssetGroup;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetGroupsInput = {
  action?: InputMaybe<GQFinanceAuditAction>;
  assets: Array<GQCreateAssetGroupInput>;
  fileInfo?: InputMaybe<GQFinanceAuditFileInfoInput>;
};

export type GQCreateAssetHoldingNewInput = {
  action: GQFinanceAuditAction;
  assetClass: GQFundAssetClass;
  assetYearId: Scalars['ID']['input'];
  currencyCode: GQCurrencyCode;
  fundId: Scalars['ID']['input'];
  outstandingAmountNative?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ1?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ2?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ3?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ4?: InputMaybe<Scalars['Float']['input']>;
  premiumNative?: InputMaybe<Scalars['Float']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
  yearOfInitialInvestment?: InputMaybe<Scalars['Int']['input']>;
};

export type GQCreateAssetHoldingPayload = {
  __typename?: 'CreateAssetHoldingPayload';
  assetHolding: GQFinanceAssetHolding;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetPersonalMotorVehicleInsuranceInput = {
  action: GQFinanceAuditAction;
  currencyCode: GQCurrencyCode;
  name: Scalars['String']['input'];
};

export type GQCreateAssetPersonalMotorVehicleInsurancePayload = {
  __typename?: 'CreateAssetPersonalMotorVehicleInsurancePayload';
  asset: GQAssetPersonalMotorVehicleInsurance;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetRealEstateInput = {
  action: GQFinanceAuditAction;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  buildingKind: Scalars['String']['input'];
  buildingSizeUnit: GQBuildingSizeUnit;
  buildingValueNativeAtOrigination?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryAlpha2: GQCountryAlpha2;
  currencyCode: GQCurrencyCode;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQCreateAssetRealEstatePayload = {
  __typename?: 'CreateAssetRealEstatePayload';
  asset: GQAssetRealEstate;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetSovereignBondInput = {
  action: GQFinanceAuditAction;
  currencyCode: GQCurrencyCode;
  name: Scalars['String']['input'];
  sovereignEntity: Scalars['String']['input'];
};

export type GQCreateAssetSovereignBondPayload = {
  __typename?: 'CreateAssetSovereignBondPayload';
  asset: GQAssetSovereignBond;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetYearNewInput = {
  action: GQFinanceAuditAction;
  assetCommercialRealEstateId?: InputMaybe<Scalars['ID']['input']>;
  assetCorporateId?: InputMaybe<Scalars['ID']['input']>;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  assetPersonalMotorVehicleInsuranceId?: InputMaybe<Scalars['ID']['input']>;
  assetSovereignBondId?: InputMaybe<Scalars['ID']['input']>;
  buildingSizeNative?: InputMaybe<Scalars['Int']['input']>;
  cloudSpendNative?: InputMaybe<Scalars['Float']['input']>;
  committedToNearTermSbtAlignedNetZero?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  currencyCode: GQCurrencyCode;
  demonstratingYoyEmissionsInLineWithNetZero?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  emissionsYearId?: InputMaybe<Scalars['String']['input']>;
  estimationMethodology?: InputMaybe<GQEstimationMethodology>;
  flightMiles?: InputMaybe<Scalars['Int']['input']>;
  headcount?: InputMaybe<Scalars['Int']['input']>;
  iciAlignmentOverride?: InputMaybe<GQIciAlignmentStatus>;
  initiatedDecarbonizationPlan?: InputMaybe<Scalars['Boolean']['input']>;
  measuringMaterialScope3Emissions?: InputMaybe<Scalars['Boolean']['input']>;
  measuringScope1And2Emissions?: InputMaybe<Scalars['Boolean']['input']>;
  percentEmployeesWfh?: InputMaybe<Scalars['Float']['input']>;
  percentRenewable?: InputMaybe<Scalars['Float']['input']>;
  revenueNative?: InputMaybe<Scalars['Float']['input']>;
  revenueSourceEmissionsYearId?: InputMaybe<Scalars['String']['input']>;
  scenario?: InputMaybe<Scalars['String']['input']>;
  scope1OverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope1OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2LocationOverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope2LocationOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2MarketOverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope2MarketOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope3OverrideKind?: InputMaybe<GQScope3OverrideKind>;
  scope3OverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope3OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope301OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope302OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope303OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope304OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope305OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope306OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope307OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope308OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope309OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope310OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope311OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope312OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope313OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope314OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope315OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
  totalMwh?: InputMaybe<Scalars['Float']['input']>;
  valueNative?: InputMaybe<Scalars['Float']['input']>;
  year: Scalars['Int']['input'];
};

export type GQCreateAssetYearNewPayload = {
  __typename?: 'CreateAssetYearNewPayload';
  assetYear: GQFinanceAssetYearNew;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetsCorporateInput = {
  action?: InputMaybe<GQFinanceAuditAction>;
  assets: Array<GQCreateAssetCorporateInput>;
  fileInfo?: InputMaybe<GQFinanceAuditFileInfoInput>;
};

export type GQCreateAssetsRealEstateInput = {
  action?: InputMaybe<GQFinanceAuditAction>;
  assets: Array<GQCreateAssetRealEstateInput>;
  fileInfo?: InputMaybe<GQFinanceAuditFileInfoInput>;
};

export type GQCreateBiCustomMetricInput = {
  expression: Scalars['Expression']['input'];
  name: Scalars['String']['input'];
};

export type GQCreateBiSavedViewAndPropagateToCustomReportsInput = {
  oldBiSavedViewStableId?: InputMaybe<Scalars['ID']['input']>;
  reportConfigIdsToUpdate: Array<Scalars['ID']['input']>;
  savedViewDefinition: GQCreateBiSavedViewInput;
};

export type GQCreateBiSavedViewInput = {
  chartKind: GQBiChartKind;
  customMetrics?: InputMaybe<Array<GQBiCustomMetricRefInput>>;
  dimensions: Array<Scalars['String']['input']>;
  editVisibility: GQBiSavedViewVisibility;
  filters: Array<GQBiQueryFilterInput>;
  name: Scalars['String']['input'];
  normalizedMeasures: Array<GQBiDynamicNormalizedMeasureInput>;
  order?: InputMaybe<GQBiQueryOrderInput>;
  queryContext?: InputMaybe<GQBiSavedViewQueryContextInput>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  timeIntervals: Array<Scalars['YMInterval']['input']>;
  viewVisibility: GQBiSavedViewVisibility;
};

export type GQCreateBuildingInput = {
  buildingKind?: InputMaybe<Scalars['String']['input']>;
  buildingName: Scalars['String']['input'];
  buildingSubkind?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  endYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  isWithoutNaturalGas?: InputMaybe<Scalars['Boolean']['input']>;
  isWithoutRefrigerants?: InputMaybe<Scalars['Boolean']['input']>;
  leaseType?: InputMaybe<GQBuildingLeaseType>;
  measurementProjectId?: InputMaybe<Scalars['ID']['input']>;
  needsConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  periods: Array<GQCreateBuildingPeriodInput>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  sizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  startYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  supportingDocumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreateBuildingPayload = {
  __typename?: 'CreateBuildingPayload';
  measurementProject: Maybe<GQMeasurementProject>;
  organization: GQOrganization;
  updatedUserUploadTasks: Array<GQUserUploadTask>;
};

export type GQCreateBuildingPeriodInput = {
  cleanPowerContractualInstrument?: InputMaybe<GQCleanPowerContractualInstrument>;
  customFieldId?: InputMaybe<Scalars['String']['input']>;
  customFieldValue?: InputMaybe<Scalars['String']['input']>;
  endYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  isBuildingEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  kind: GQBuildingPeriodKind;
  orgUnitStableId?: InputMaybe<Scalars['String']['input']>;
  orgUnitTypeStableId?: InputMaybe<Scalars['String']['input']>;
  partialCleanPowerPercent?: InputMaybe<Scalars['Float']['input']>;
  powerUsageEffectiveness?: InputMaybe<Scalars['Float']['input']>;
  sizeQuantity?: InputMaybe<Scalars['Float']['input']>;
  startYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
};

export type GQCreateBuildingUtilityInput = {
  buildingId: Scalars['ID']['input'];
  cleanPowerPercent?: InputMaybe<Scalars['Float']['input']>;
  fuelCombustionReason?: InputMaybe<Scalars['String']['input']>;
  fuelKind?: InputMaybe<GQStationaryCombustionFuelKind>;
  isWasteHazardous?: InputMaybe<Scalars['Boolean']['input']>;
  isWasteRadioactive?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  onsiteCleanFuelKind?: InputMaybe<Scalars['String']['input']>;
  periodEnd: Scalars['Date']['input'];
  periodStart: Scalars['Date']['input'];
  provider: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
  refrigerantLeakageSource?: InputMaybe<GQRefrigerantLeakageSource>;
  refrigerantName?: InputMaybe<Scalars['String']['input']>;
  unit: GQBuildingUtilityUnit;
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
  utilityType: GQBuildingUtilityType;
  wasteMaterial?: InputMaybe<GQBuildingWasteMaterial>;
  wasteTreatment?: InputMaybe<GQBuildingWasteTreatment>;
  waterUseKind?: InputMaybe<GQBuildingWaterUseKind>;
};

export type GQCreateBuildingUtilityPayload = {
  __typename?: 'CreateBuildingUtilityPayload';
  /** @deprecated Use buildingWithUtilities.completionIntervals instead */
  buildingCompletionIntervalsUntyped: GQBuildingCompletionIntervalsUntyped;
  buildingUntyped: GQBuildingUntyped;
  buildingUtility: GQBuildingUtility;
  buildingWithUtilities: GQBuildingWithUtilities;
  updatedUserUploadTask: Maybe<GQUserUploadTask>;
};

export type GQCreateCalculationTagInput = {
  name: Scalars['String']['input'];
};

export type GQCreateCandidateRowInput = {
  userUploadCandidateId: Scalars['ID']['input'];
};

export type GQCreateCanonicalClimateProgramProjectInput = {
  commitmentType?: InputMaybe<Scalars['String']['input']>;
  costEstimate?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  iconTypeOverride?: InputMaybe<GQClimateProgramProjectIconType>;
  kind: GQClimateProgramProjectKind;
  matchObjectType?: InputMaybe<Scalars['String']['input']>;
  measurementObjectCopy?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reportKind?: InputMaybe<Scalars['String']['input']>;
  routeType?: InputMaybe<Scalars['String']['input']>;
  timeEstimate?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCanonicalClimateProgramProjectRequirementDatasetInput = {
  additionalNotesMd?: InputMaybe<Scalars['String']['input']>;
  canonicalClimateProgramProjectId: Scalars['ID']['input'];
  canonicalDatasetId: Scalars['ID']['input'];
};

export type GQCreateCanonicalClimateProgramProjectRequirementGhgpInput = {
  additionalNotesMd?: InputMaybe<Scalars['String']['input']>;
  canonicalClimateProgramProjectId: Scalars['ID']['input'];
  ghgScope: Scalars['Int']['input'];
  ghgScope3Category?: InputMaybe<Scalars['Int']['input']>;
  minimumFidelity: GQClimateProgramProjectRequirementFidelity;
};

export type GQCreateCanonicalDatasetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  instructions: GQIngestionInstructionsInput;
  kind: GQCanonicalDatasetKind;
  name: Scalars['String']['input'];
};

export type GQCreateCanonicalDatasourceInput = {
  canonicalDatasetId: Scalars['String']['input'];
  instructions: GQIngestionInstructionsInput;
  integrationPartnerId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type GQCreateCdpApiRegistrationInput = {
  cdpOrganizationId: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQCreateCdpApiRegistrationPayload = {
  __typename?: 'CreateCdpApiRegistrationPayload';
  cdpOrganizationId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
};

export type GQCreateCdpColumnMappingsInput = {
  mappings: Array<GQCdpColumnMappingInput>;
};

export type GQCreateCdpColumnMappingsPayload = {
  __typename?: 'CreateCdpColumnMappingsPayload';
  mappings: GQCdpIdMappingsPayload;
};

export type GQCreateCdpQuestionMappingsInput = {
  mappings: Array<GQCdpQuestionMappingInput>;
};

export type GQCreateCdpQuestionMappingsPayload = {
  __typename?: 'CreateCdpQuestionMappingsPayload';
  mappings: GQCdpIdMappingsPayload;
};

export type GQCreateClimateProgramCalendarEventInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
};

export type GQCreateClimateProgramProjectDatasetRequirementInput = {
  additionalNotesMd?: InputMaybe<Scalars['String']['input']>;
  climateProgramProjectId: Scalars['ID']['input'];
  datasetId: Scalars['ID']['input'];
};

export type GQCreateClimateProgramProjectInput = {
  canonicalClimateProgramProjectId?: InputMaybe<Scalars['ID']['input']>;
  completedAt?: InputMaybe<Scalars['Date']['input']>;
  createdForMeasureWhatMatters?: InputMaybe<Scalars['Boolean']['input']>;
  customKind?: InputMaybe<GQClimateProgramProjectKind>;
  descriptionOverride?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  nameOverride?: InputMaybe<Scalars['String']['input']>;
  plannedYear?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type GQCreateCompanyBaseInput = {
  assetCorporateId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCompanyChangeRequestCreateInput = {
  assetCorporateId?: InputMaybe<Scalars['String']['input']>;
  bea?: InputMaybe<Scalars['String']['input']>;
  isin?: InputMaybe<Scalars['String']['input']>;
  lei?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgCompanyName?: InputMaybe<Scalars['String']['input']>;
  sAndPId?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<GQOrgCompanySource>;
  sourceKey?: InputMaybe<Scalars['String']['input']>;
  tickerSymbol?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCompanyChangeRequestCreatePayload = {
  __typename?: 'CreateCompanyChangeRequestCreatePayload';
  companyChangeRequest: GQCompanyChangeRequest;
};

export type GQCreateCompanyClimateCommitmentInput = {
  carbonNeutralCommitment?: InputMaybe<GQCarbonNeutralCommitmentInput>;
  cleanEnergyCommitment?: InputMaybe<GQCleanEnergyCommitmentInput>;
  commitmentMadeDate?: InputMaybe<Scalars['Date']['input']>;
  commitmentPeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  commitmentPeriodStart?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<GQCompanyClimateCommitmentKind>;
  netZeroCommitment?: InputMaybe<GQNetZeroCommitmentInput>;
  privateDisclosureId?: InputMaybe<Scalars['String']['input']>;
  publicDisclosureId?: InputMaybe<Scalars['String']['input']>;
  publicUrl?: InputMaybe<Scalars['String']['input']>;
  sbtCommitment?: InputMaybe<GQSbtCommitmentInput>;
};

export type GQCreateCompanyPayload = {
  __typename?: 'CreateCompanyPayload';
  company: GQCompany;
};

export type GQCreateCompanyRelationshipInput = {
  companyAId: Scalars['ID']['input'];
  companyBId: Scalars['ID']['input'];
  relationshipEndDate?: InputMaybe<Scalars['YearMonth']['input']>;
  relationshipStartDate?: InputMaybe<Scalars['YearMonth']['input']>;
  relationshipType: GQCompanyRelationshipType;
};

export type GQCreateCompanyRelationshipPayload = {
  __typename?: 'CreateCompanyRelationshipPayload';
  companyRelationship: GQCompanyRelationship;
};

export type GQCreateCompanySourceKeyInput = {
  priority: Scalars['Int']['input'];
  sourceKey: Scalars['String']['input'];
  sourceNotes?: InputMaybe<Scalars['String']['input']>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  userVisibleSourceNotes?: InputMaybe<Scalars['String']['input']>;
  userVisibleSourceUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCompanySurveyInput = {
  /** @deprecated Everything is backed by engagementTaskConfig now that we've migrated. Delete 10/12 */
  backedByEngagementTaskConfig?: InputMaybe<Scalars['Boolean']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  facilityName?: InputMaybe<Scalars['String']['input']>;
  proposeTargetFilters?: InputMaybe<GQFilterExpressionGroupInput>;
  proposeTargetIntensityType?: InputMaybe<GQDisclosureTargetIntensityType>;
  proposeTargetSimpleTimeseries?: InputMaybe<GQSimpleTimeseriesInput>;
  reportingYear?: InputMaybe<Scalars['Int']['input']>;
  rootAssignee?: InputMaybe<Scalars['ID']['input']>;
  supplierFacingDescription?: InputMaybe<Scalars['String']['input']>;
  surveyKind?: InputMaybe<GQCompanySurveyKind>;
  templateId: Scalars['ID']['input'];
  type?: InputMaybe<GQEngagementTaskConfigType>;
  useExpenseCategories?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCreateCompositeDataSourceInput = {
  externalSourceNotes?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  versionVintage: Scalars['DateTime']['input'];
};

export type GQCreateCompositeDataSourcePayload = {
  __typename?: 'CreateCompositeDataSourcePayload';
  referenceDataSource: GQReferenceDataSource;
};

export type GQCreateCorporateSandboxEstimationInput = {
  buildingSizeNative?: InputMaybe<Scalars['Int']['input']>;
  buildingSizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  cloudSpendNative?: InputMaybe<Scalars['Float']['input']>;
  countryAlpha2: GQCountryAlpha2;
  currencyCode: GQCurrencyCode;
  estimationMethodology: GQEstimationMethodology;
  flightMiles?: InputMaybe<Scalars['Int']['input']>;
  headcount?: InputMaybe<Scalars['Int']['input']>;
  naicsCode: Scalars['String']['input'];
  percentEmployeesWfh?: InputMaybe<Scalars['Float']['input']>;
  percentRenewable?: InputMaybe<Scalars['Float']['input']>;
  revenueNative: Scalars['Float']['input'];
  scope1OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2LocationOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2MarketOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope3OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  shouldIncludeDownstreamScope3?: InputMaybe<Scalars['Boolean']['input']>;
  totalMwh?: InputMaybe<Scalars['Float']['input']>;
};

export type GQCreateCorporateSandboxEstimationPayload = {
  __typename?: 'CreateCorporateSandboxEstimationPayload';
  backgroundJobId: Scalars['String']['output'];
};

export type GQCreateCsrdDmaInput = {
  fileMetadataId?: InputMaybe<Scalars['String']['input']>;
  ingestionMethod: GQDmaIngestionMethod;
  orgId: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
  topicMaterialities: Array<GQCsrdTopicMateriality>;
  validityEnd: Scalars['YearMonth']['input'];
  validityStart: Scalars['YearMonth']['input'];
};

export type GQCreateCsrdDmaPayload = {
  __typename?: 'CreateCsrdDmaPayload';
  csrdDma: GQCsrdDoubleMaterialityAssessment;
  dmaTopics: Array<GQCsrdDoubleMaterialityAssessmentTopic>;
  updatedReport: GQReport;
};

export type GQCreateCsrdMaterialMetricInput = {
  metricNumber: Scalars['String']['input'];
  standardId: Scalars['ID']['input'];
  watershedMetricGroup: GQCsrdWatershedMetricGroup;
};

export type GQCreateCsrdMaterialMetricPayload = {
  __typename?: 'CreateCsrdMaterialMetricPayload';
  result: GQCsrdMaterialStandard;
};

export type GQCreateCsrdMaterialStandardInput = {
  csrdVersion?: InputMaybe<GQCsrdVersion>;
  orgId: Scalars['String']['input'];
  standards: Array<GQCsrdStandard>;
};

export type GQCreateCsrdMaterialStandardPayload = {
  __typename?: 'CreateCsrdMaterialStandardPayload';
  result: Array<GQCsrdMaterialStandard>;
};

export type GQCreateCtsFormEntryInput = {
  ctsFormId: Scalars['ID']['input'];
  data: Scalars['JSONString']['input'];
};

export type GQCreateCtsFormInput = {
  ctsId: Scalars['ID']['input'];
  ctsVersionId: Scalars['ID']['input'];
  datasetId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQCreateCtsInput = {
  canonicalDatasetIds: Array<Scalars['ID']['input']>;
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  rank: Scalars['Int']['input'];
};

export type GQCreateCtsPayload = {
  __typename?: 'CreateCtsPayload';
  schema: GQCustomerTargetSchema;
};

export type GQCreateCtsTransformTestCaseInput = {
  ctsTransformId: Scalars['ID']['input'];
  fileMetadataId?: InputMaybe<Scalars['ID']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCtsTransformTestCasePayload = {
  __typename?: 'CreateCtsTransformTestCasePayload';
  ctsTransformTestCase: GQCustomerTargetSchemaTransformTestCase;
};

export type GQCreateCtsVersionInput = {
  businessActivityTypeName?: InputMaybe<Scalars['String']['input']>;
  businessActivityTypeVersionId?: InputMaybe<Scalars['String']['input']>;
  customerTargetSchemaId: Scalars['ID']['input'];
  exampleData?: InputMaybe<Scalars['JSONString']['input']>;
  formattingNotesMd?: InputMaybe<Scalars['String']['input']>;
  oneSchemaJson?: InputMaybe<Scalars['JSONString']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  schemaJson?: InputMaybe<Scalars['JSONString']['input']>;
  semverVersion: Scalars['String']['input'];
  sql: Scalars['String']['input'];
  transformTarget: GQCustomerTargetSchemaTransformTarget;
  versionNotesMd: Scalars['String']['input'];
};

export type GQCreateCtsVersionPayload = {
  __typename?: 'CreateCtsVersionPayload';
  customerTargetSchema: Maybe<GQCustomerTargetSchema>;
};

export type GQCreateCtsvOneSchemaFileFeedInput = {
  ctsVersionId: Scalars['ID']['input'];
  userUploadCandidateId: Scalars['ID']['input'];
};

export type GQCreateCustomEmissionsFactorsReferenceDataRevisionInput = {
  measurementProjectId: Scalars['String']['input'];
  oneSchemaEmbedId: Scalars['Int']['input'];
  oneSchemaSheetId: Scalars['Int']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQCreateCustomEmissionsFactorsReferenceDataRevisionPayload = {
  __typename?: 'CreateCustomEmissionsFactorsReferenceDataRevisionPayload';
  jobId: Scalars['ID']['output'];
};

export type GQCreateCustomIntensityConfigInput = {
  description: Scalars['String']['input'];
  humanReadable: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isSbtValidated?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  quantityType: GQCustomIntensityConfigQuantityType;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCustomerInitiatedDataIssueInput = {
  fileMetadataIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  linkedObjectId: Scalars['ID']['input'];
  linkedObjectType: GQMeasurementTaskForDataIssueKind;
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  message: Scalars['String']['input'];
  shouldNotifyWatershed?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type GQCreateCustomerInitiatedDataIssuePayload = {
  __typename?: 'CreateCustomerInitiatedDataIssuePayload';
  issue: GQDataIssue;
  measurementProject: GQMeasurementProject;
  userUploadTask: Maybe<GQUserUploadTask>;
  valueMappingTask: Maybe<GQValueMappingTask>;
};

export type GQCreateDataIssueCommentInput = {
  issueId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};

export type GQCreateDataIssueCommentPayload = {
  __typename?: 'CreateDataIssueCommentPayload';
  comment: GQComment;
  issue: GQDataIssue;
};

export type GQCreateDataIssueInput = {
  description: Scalars['String']['input'];
  fileIds: Array<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  shouldCcClimateLeads?: InputMaybe<Scalars['Boolean']['input']>;
  shouldPublish?: InputMaybe<Scalars['Boolean']['input']>;
  state: GQDataIssueState;
  tags?: InputMaybe<Array<GQDataIssueTag>>;
  title: Scalars['String']['input'];
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQCreateDataIssuePayload = {
  __typename?: 'CreateDataIssuePayload';
  issue: GQDataIssue;
  measurementProject: GQMeasurementProject;
  userUploadTask: GQUserUploadTask;
};

export type GQCreateDatasetInput = {
  canonicalDatasetId?: InputMaybe<Scalars['ID']['input']>;
  customExplainer?: InputMaybe<Scalars['String']['input']>;
  customName?: InputMaybe<Scalars['String']['input']>;
  customPotentialOverlaps?: InputMaybe<Scalars['String']['input']>;
  customUsedFor?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<GQIngestionInstructionsInput>;
  measurementProjectIdToAddTaskIn?: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['String']['input'];
};

export type GQCreateDatasourceAdminInput = {
  canonicalDatasourceId?: InputMaybe<Scalars['ID']['input']>;
  customerTargetSchemaIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  datasetId: Scalars['ID']['input'];
  importInstructionsMd?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<GQIngestionInstructionsInput>;
  isUserConfirmed: Scalars['Boolean']['input'];
  measurementProjectIdToAddTaskIn?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  notesFromWatershedMd?: InputMaybe<Scalars['String']['input']>;
  refreshCadence?: InputMaybe<GQDatasetCadence>;
  userIdsWithPermission?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQCreateDatasourceInput = {
  canonicalDatasourceId?: InputMaybe<Scalars['ID']['input']>;
  customPotentialOverlaps?: InputMaybe<Scalars['String']['input']>;
  customUsedFor?: InputMaybe<Scalars['String']['input']>;
  datasetId: Scalars['ID']['input'];
  importInstructionsMd?: InputMaybe<Scalars['String']['input']>;
  isUserConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  orgAccessIdsWithPermission?: InputMaybe<Array<Scalars['ID']['input']>>;
  refreshCadence?: InputMaybe<GQDatasetCadence>;
  userIdsWithPermission?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQCreateDemoOrgInput = {
  companySourceKey: Scalars['String']['input'];
  dataRegion: GQDataRegion;
  demoConfigurationType: GQResetFootprintCustomActionSubset;
  grantAccessUserIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  stagingOrg: Scalars['Boolean']['input'];
  testOrg: Scalars['Boolean']['input'];
  userName: Scalars['String']['input'];
};

export type GQCreateDemoOrgPayload = {
  __typename?: 'CreateDemoOrgPayload';
  backgroundJobId: Scalars['ID']['output'];
};

export type GQCreateDiscussionInput = {
  additionalProperties?: InputMaybe<GQDiscussionAdditionalProperties>;
  anchor: GQDiscussionAnchor;
  fileMetadataIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  message: Scalars['String']['input'];
  shouldNotifyWatershed?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type GQCreateDiscussionPayload = {
  __typename?: 'CreateDiscussionPayload';
  discussion: GQDiscussion;
};

export type GQCreateDrilldownSavedViewInput = {
  fields: GQDrilldownSavedViewInput;
};

export type GQCreateEacMarketplaceProjectsInput = {
  countryCodes: Array<Scalars['String']['input']>;
  marketplaceProjectArchetypeId: Scalars['ID']['input'];
  marketplaceSupplierIds: Array<Scalars['ID']['input']>;
  vintageYears: Array<Scalars['Int']['input']>;
};

export type GQCreateEacMarketplaceProjectsPayload = {
  __typename?: 'CreateEacMarketplaceProjectsPayload';
  affectedMarketplaceProjectIds: Array<Scalars['ID']['output']>;
};

export type GQCreateEacPriceEstimateInput = {
  alternativeCountries: Array<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  defaultProjectId?: InputMaybe<Scalars['ID']['input']>;
  minCreditPurchase: Scalars['Int']['input'];
  priceEstimateUsdCents: Scalars['Int']['input'];
  standard: Scalars['String']['input'];
};

export type GQCreateEacPriceEstimatePayload = {
  __typename?: 'CreateEacPriceEstimatePayload';
  eacPriceEstimate: GQEacPriceEstimate;
  id: Scalars['ID']['output'];
};

export type GQCreateEmissionsModelInput = {
  description: Scalars['String']['input'];
  emissionsModelJson: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type GQCreateEmissionsModelPayload =
  | GQCreateEmissionsModelPayloadInvalid
  | GQCreateEmissionsModelPayloadValid;

export type GQCreateEmissionsModelPayloadInvalid = {
  __typename?: 'CreateEmissionsModelPayloadInvalid';
  validationErrors: Array<GQEmissionsModelError>;
};

export type GQCreateEmissionsModelPayloadValid = {
  __typename?: 'CreateEmissionsModelPayloadValid';
  emissionsModelStableRecord: GQEmissionsModelStable;
  emissionsModelVersionRecord: GQEmissionsModelVersion;
  parameterResolverRecord: GQParameterResolver;
};

export type GQCreateEmissionsModelReleaseInput = {
  businessActivityTypeName: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  externalNotes: Scalars['String']['input'];
  internalNotes: Scalars['String']['input'];
  isOrgGated: Scalars['Boolean']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreateEmissionsYearInput = {
  numEmployees?: InputMaybe<Scalars['Float']['input']>;
  reportingYear: Scalars['Int']['input'];
  revenue?: InputMaybe<Scalars['Float']['input']>;
  revenueCurrency?: InputMaybe<Scalars['String']['input']>;
  scope1?: InputMaybe<Scalars['Float']['input']>;
  scope2Location?: InputMaybe<Scalars['Float']['input']>;
  scope2Market?: InputMaybe<Scalars['Float']['input']>;
  scope3?: InputMaybe<Scalars['Float']['input']>;
  scope301?: InputMaybe<Scalars['Float']['input']>;
  scope302?: InputMaybe<Scalars['Float']['input']>;
  scope303?: InputMaybe<Scalars['Float']['input']>;
  scope304?: InputMaybe<Scalars['Float']['input']>;
  scope305?: InputMaybe<Scalars['Float']['input']>;
  scope306?: InputMaybe<Scalars['Float']['input']>;
  scope307?: InputMaybe<Scalars['Float']['input']>;
  scope308?: InputMaybe<Scalars['Float']['input']>;
  scope309?: InputMaybe<Scalars['Float']['input']>;
  scope310?: InputMaybe<Scalars['Float']['input']>;
  scope311?: InputMaybe<Scalars['Float']['input']>;
  scope312?: InputMaybe<Scalars['Float']['input']>;
  scope313?: InputMaybe<Scalars['Float']['input']>;
  scope314?: InputMaybe<Scalars['Float']['input']>;
  scope315?: InputMaybe<Scalars['Float']['input']>;
  scope316?: InputMaybe<Scalars['Float']['input']>;
  scope317?: InputMaybe<Scalars['Float']['input']>;
  units: GQCompanyEmissionsUnits;
};

export type GQCreateEmployeeReportInput = {
  orgId: Scalars['ID']['input'];
};

export type GQCreateEmployeeReportPayload = {
  __typename?: 'CreateEmployeeReportPayload';
  employeeReport: GQEmployeeReport;
};

export type GQCreateEngagementCohortInput = {
  name: Scalars['String']['input'];
};

export type GQCreateEngagementCohortsForCompanyInput = {
  companyId: Scalars['String']['input'];
  names: Array<Scalars['String']['input']>;
};

export type GQCreateEngagementTaskAttachmentsInput = {
  attachmentMetadataInput: Array<GQEngagementTaskAttachmentMetadataInput>;
  engagementTaskId: Scalars['ID']['input'];
};

export type GQCreateEngagementTaskAttachmentsPayload = {
  __typename?: 'CreateEngagementTaskAttachmentsPayload';
  attachmentMetadata: Array<GQEngagementTaskAttachment>;
};

export type GQCreateExternalReportQuestionInput = {
  externalReportTypeId: Scalars['ID']['input'];
  identifier: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type GQCreateExternalReportQuestionPayload = {
  __typename?: 'CreateExternalReportQuestionPayload';
  question: GQExternalReportQuestion;
};

export type GQCreateExternalReportSectionUrlInput = {
  sectionKind: GQExternalReportSectionKind;
  urlFragment: Scalars['String']['input'];
};

export type GQCreateExternalReportTypeWithRevisionInput = {
  revision: Scalars['String']['input'];
  specUrl?: InputMaybe<Scalars['String']['input']>;
  type: GQExternalReportType;
};

export type GQCreateExternalReportTypeWithRevisionPayload = {
  __typename?: 'CreateExternalReportTypeWithRevisionPayload';
  externalReportTypeWithRevision: GQExternalReportTypeWithRevision;
};

export type GQCreateFacilitiesSetupTaskInput = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQCreateFacilitiesSetupTaskPayload = {
  __typename?: 'CreateFacilitiesSetupTaskPayload';
  measurementProject: GQMeasurementProject;
};

export type GQCreateFinanceImportInput = {
  dataFormat: GQOneSchemaDataFormat;
  oneSchemaEmbedId: Scalars['Int']['input'];
  oneSchemaErrorMessages: Scalars['UntypedData']['input'];
  oneSchemaSheetId: Scalars['Int']['input'];
  originalFileName: Scalars['String']['input'];
};

export type GQCreateFinanceImportPayload = {
  __typename?: 'CreateFinanceImportPayload';
  backgroundJobId: Scalars['ID']['output'];
  financeImport: GQFinanceImport;
};

export type GQCreateFinanceReportingTagsInput = {
  reportType: GQExternalReportType;
};

export type GQCreateFinanceReportingTagsPayload = {
  __typename?: 'CreateFinanceReportingTagsPayload';
  financeTags: Array<GQFinanceTag>;
};

export type GQCreateFinanceSavedViewInput = {
  charts: Array<Scalars['JSONString']['input']>;
  filters?: InputMaybe<Scalars['JSONString']['input']>;
  hiddenColumns?: InputMaybe<Array<Scalars['String']['input']>>;
  layout: Scalars['String']['input'];
  metrics: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type GQCreateFinanceSavedViewPayload = {
  __typename?: 'CreateFinanceSavedViewPayload';
  financeSavedView: GQFinanceSavedView;
};

export type GQCreateFinanceTagInput = {
  columnConfiguration?: InputMaybe<Scalars['UntypedData']['input']>;
  columnFormat: GQGridColumnFormat;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  tagType: GQFinanceTagType;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateFinanceTagPayload = {
  __typename?: 'CreateFinanceTagPayload';
  financeTag: GQFinanceTag;
};

export type GQCreateFinanceUploadTemplateInput = {
  dataFormat: GQOneSchemaDataFormat;
};

export type GQCreateFinanceUploadTemplatePayload = {
  __typename?: 'CreateFinanceUploadTemplatePayload';
  oneSchemaTemplateKey: Scalars['String']['output'];
};

export type GQCreateFinancialsReviewItemDraftInput = {
  accountDescription?: InputMaybe<Scalars['String']['input']>;
  accountId: Scalars['String']['input'];
  displayAccountId: Scalars['String']['input'];
  exclusionReason?: InputMaybe<Scalars['String']['input']>;
  initialIndustryClassificationId?: InputMaybe<Scalars['ID']['input']>;
  initiallyIncluded: Scalars['Boolean']['input'];
  kind: GQFinancialsReviewItemKind;
  prompt?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateFinancialsReviewItemDraftsInput = {
  inputs: Array<GQCreateFinancialsReviewItemDraftInput>;
  measurementProjectId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQCreateFinancialsReviewItemDraftsPayload = {
  __typename?: 'CreateFinancialsReviewItemDraftsPayload';
  financialsReviewItems: GQFinancialsReviewItemConnection;
};

export type GQCreateFootprintCustomReportInput = {
  description: Scalars['String']['input'];
  fileId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQCreateFootprintCustomReportPayload = {
  __typename?: 'CreateFootprintCustomReportPayload';
  footprintCustomReport: GQFootprintCustomReport;
};

export type GQCreateFootprintDisclosureInput = {
  footprintSnapshotId: Scalars['ID']['input'];
  granularity: GQFootprintDisclosureGranularity;
  year: Scalars['Int']['input'];
};

export type GQCreateFootprintDisclosurePayload = {
  __typename?: 'CreateFootprintDisclosurePayload';
  footprintDisclosure: GQFootprintDisclosure;
};

export type GQCreateFootprintInput = {
  name: Scalars['String']['input'];
};

export type GQCreateFootprintPayload = {
  __typename?: 'CreateFootprintPayload';
  footprint: GQFootprintTemp;
};

export type GQCreateFootprintSliceInitiativeInput = {
  costTimeseries: GQSimpleTimeseriesInput;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  filters: GQFilterExpressionGroupWithNewFiltersInput;
  name: Scalars['String']['input'];
  planId: Scalars['ID']['input'];
  reductionTimeseries: GQSimpleTimeseriesInput;
  scheduleType: GQScheduleType;
};

export type GQCreateFootprintSliceInitiativePayload = {
  __typename?: 'CreateFootprintSliceInitiativePayload';
  plan: GQPlan;
};

export type GQCreateFootprintSnapshotInput = {
  adminFootprintCreationOtherText?: InputMaybe<Scalars['String']['input']>;
  adminFootprintCreationReason: Scalars['String']['input'];
  configs: Array<GQPipelineConfigInput>;
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  extraAdeIdsForNonEmissiveActivity?: InputMaybe<
    Array<Scalars['String']['input']>
  >;
  extraAdtIdsForIntDenoms?: InputMaybe<Array<Scalars['String']['input']>>;
  footprintId?: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  selectedAllocationInstructionIds: Array<Scalars['ID']['input']>;
  shouldSkipApplyingCategoryRules?: InputMaybe<Scalars['Boolean']['input']>;
  shouldSkipApplyingExclusionRules?: InputMaybe<Scalars['Boolean']['input']>;
  shouldUseDedicatedQueue?: InputMaybe<Scalars['Boolean']['input']>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQCreateFootprintSnapshotPayload = {
  __typename?: 'CreateFootprintSnapshotPayload';
  job: GQBackgroundJobEntry;
};

export type GQCreateFootprintVersionPinInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  footprintVersionId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQCreateFootprintVersionPinPayload = {
  __typename?: 'CreateFootprintVersionPinPayload';
  footprintVersion: GQFootprintVersion;
};

export type GQCreateForecastScenarioInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  growthForecasts: Array<GQCreateGrowthForecastInput>;
  id: Scalars['ID']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedByName?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateFundInput = {
  excludeAsSandbox?: InputMaybe<Scalars['Boolean']['input']>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  fundCategory?: InputMaybe<Scalars['String']['input']>;
  fundGroup?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nameSortable?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQCreateFundInputAdmin = {
  excludeAsSandbox?: InputMaybe<Scalars['Boolean']['input']>;
  fundGroup?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nameSortable?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreateFundPayload = {
  __typename?: 'CreateFundPayload';
  fund: GQFund;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateFundsInput = {
  action?: InputMaybe<GQFinanceAuditAction>;
  fileInfo?: InputMaybe<GQFinanceAuditFileInfoInput>;
  funds: Array<GQCreateFundInput>;
};

export type GQCreateGrowthForecastInput = {
  customIntensityConfigId?: InputMaybe<Scalars['ID']['input']>;
  forecast: GQCreateSimpleTimeseriesInput;
  growthFactorType: GQGrowthFactorType;
  historicalGrowthValues?: InputMaybe<GQCreateSimpleTimeseriesInput>;
  isDefaultScalingFactor?: InputMaybe<Scalars['Boolean']['input']>;
  scalingFactorFilters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQCreateIngestionCustomFieldInput = {
  /** @deprecated unused, remove may13 */
  canVaryOverTime?: InputMaybe<Scalars['Boolean']['input']>;
  datasetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  exampleValues?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  possibleValues?: InputMaybe<Array<Scalars['String']['input']>>;
  type: GQIngestionCustomFieldType;
  utilityTypes?: InputMaybe<Array<GQBuildingUtilityType>>;
};

export type GQCreateIngestionCustomFieldPayload = {
  __typename?: 'CreateIngestionCustomFieldPayload';
  ingestionCustomField: GQIngestionCustomField;
  organization: GQOrganization;
};

export type GQCreateMarketplaceAllocationInstructionInput = {
  amountKwh: Scalars['Int']['input'];
  buildingName?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  footprintKind?: InputMaybe<Scalars['String']['input']>;
  ghgCategoryId: Scalars['String']['input'];
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
  isZeroedOut?: InputMaybe<Scalars['Boolean']['input']>;
  locationCity?: InputMaybe<Scalars['String']['input']>;
  locationCountry?: InputMaybe<Scalars['String']['input']>;
  locationGrid?: InputMaybe<Scalars['String']['input']>;
  locationState?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Scalars['String']['input']>;
  purchaseLineItemId: Scalars['ID']['input'];
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  vendorEntity?: InputMaybe<Scalars['String']['input']>;
  vendorSubentity?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateMarketplaceAllocationInstructionPayload = {
  __typename?: 'CreateMarketplaceAllocationInstructionPayload';
  allocationInstruction: GQMarketplaceAllocationInstruction;
};

export type GQCreateMarketplaceDeveloperInput = {
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateMarketplaceDeveloperPayload = {
  __typename?: 'CreateMarketplaceDeveloperPayload';
  marketplaceDeveloper: GQMarketplaceDeveloper;
};

export type GQCreateMarketplaceEacPurchaseCountryInput = {
  countryWithEacs: Scalars['String']['input'];
  countryWithEmissions: Scalars['String']['input'];
  coverageInterval: Scalars['YMInterval']['input'];
  eacFeeUsdCents: Scalars['Int']['input'];
  eacPriceUsdCents: Scalars['Int']['input'];
  marketTco2e: Scalars['Float']['input'];
  mwh: Scalars['Float']['input'];
  numEacsRequested: Scalars['Int']['input'];
};

export type GQCreateMarketplaceEacPurchaseInput = {
  countryEacRequests: Array<GQCreateMarketplaceEacPurchaseCountryInput>;
  ghgCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  interval: Scalars['YMInterval']['input'];
  marketTco2e: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  requestNotes?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateMarketplaceEacPurchasePayload = {
  __typename?: 'CreateMarketplaceEacPurchasePayload';
  marketplacePurchase: Maybe<GQMarketplacePurchase>;
  marketplacePurchaseId: Maybe<Scalars['ID']['output']>;
};

export type GQCreateMarketplacePortfolioInput = {
  budgetUsdCents?: InputMaybe<Scalars['Int53']['input']>;
  cleanPowerTargetAmount?: InputMaybe<Scalars['Float']['input']>;
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
  investInRd?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  offsetTargetAmount?: InputMaybe<Scalars['Float']['input']>;
  offsetTargetFraction?: InputMaybe<Scalars['Float']['input']>;
  targetAmount?: InputMaybe<Scalars['Float']['input']>;
  targetFraction?: InputMaybe<Scalars['Float']['input']>;
};

export type GQCreateMarketplacePortfolioItemInput = {
  amount?: InputMaybe<Scalars['Int53']['input']>;
  archetypeId: Scalars['ID']['input'];
  fraction?: InputMaybe<Scalars['Float']['input']>;
  portfolioId: Scalars['ID']['input'];
  priceUsdCents?: InputMaybe<Scalars['Int53']['input']>;
};

export type GQCreateMarketplacePortfolioItemPayload = {
  __typename?: 'CreateMarketplacePortfolioItemPayload';
  portfolioItem: GQMarketplacePortfolioItem;
};

export type GQCreateMarketplacePortfolioPayload = {
  __typename?: 'CreateMarketplacePortfolioPayload';
  portfolio: GQMarketplacePortfolio;
};

export type GQCreateMarketplaceProjectArchetypeInput = {
  additionalityRating?: InputMaybe<Scalars['Int']['input']>;
  bannerImageUrl?: InputMaybe<Scalars['String']['input']>;
  certificateBlurb?: InputMaybe<Scalars['String']['input']>;
  certificateColor?: InputMaybe<Scalars['String']['input']>;
  descriptionMd: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isUserVisible: Scalars['Boolean']['input'];
  kind: GQMarketplaceProjectArchetypeKind;
  name: Scalars['String']['input'];
  permanenceRating?: InputMaybe<Scalars['String']['input']>;
  summaryMd: Scalars['String']['input'];
  unit: GQMarketplaceProjectArchetypeUnit;
};

export type GQCreateMarketplaceProjectArchetypePayload = {
  __typename?: 'CreateMarketplaceProjectArchetypePayload';
  marketplaceProjectArchetype: GQMarketplaceProjectArchetype;
};

export type GQCreateMarketplaceProjectInput = {
  archetypeId: Scalars['ID']['input'];
  developerId?: InputMaybe<Scalars['ID']['input']>;
  internalNotesMd?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  registryId?: InputMaybe<Scalars['String']['input']>;
  status: GQMarketplaceProjectStatus;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreateMarketplaceProjectOfferingInput = {
  availability: Scalars['Float']['input'];
  isAddingVintage?: InputMaybe<Scalars['Boolean']['input']>;
  noteMd: Scalars['String']['input'];
  priceUsdCents: Scalars['Float']['input'];
  projectId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
  vintage?: InputMaybe<Scalars['Int']['input']>;
};

export type GQCreateMarketplaceProjectOfferingPayload = {
  __typename?: 'CreateMarketplaceProjectOfferingPayload';
  addedVintageOfferings: Maybe<Array<GQMarketplaceProjectOffering>>;
  marketplaceProject: GQMarketplaceProject;
  marketplaceProjectOffering: GQMarketplaceProjectOffering;
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQCreateMarketplaceProjectPayload = {
  __typename?: 'CreateMarketplaceProjectPayload';
  marketplaceProject: GQMarketplaceProject;
};

export type GQCreateMarketplacePurchaseFromPortfolioInput = {
  portfolioId: Scalars['ID']['input'];
};

export type GQCreateMarketplacePurchaseFromPortfolioPayload = {
  __typename?: 'CreateMarketplacePurchaseFromPortfolioPayload';
  portfolio: GQMarketplacePortfolio;
  purchase: GQMarketplacePurchase;
};

export type GQCreateMarketplacePurchaseInput = {
  name: Scalars['String']['input'];
  noteMd?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  status: GQMarketplacePurchaseStatus;
};

export type GQCreateMarketplacePurchaseLineItemEacMetadataInput = {
  countryWithEacs: Scalars['String']['input'];
  countryWithEmissions: Scalars['String']['input'];
  marketTco2e: Scalars['Float']['input'];
  mwh: Scalars['Float']['input'];
  numEacsRequested: Scalars['Int']['input'];
};

export type GQCreateMarketplacePurchaseLineItemInput = {
  amount: Scalars['Float']['input'];
  amountUnit?: InputMaybe<GQMarketplaceProjectArchetypeUnit>;
  cleanPowerContractualInstrument?: InputMaybe<GQCleanPowerContractualInstrument>;
  closeDate?: InputMaybe<Scalars['Date']['input']>;
  coverageInterval: Scalars['YMInterval']['input'];
  deliveryDate?: InputMaybe<Scalars['Date']['input']>;
  eacMetadata?: InputMaybe<GQCreateMarketplacePurchaseLineItemEacMetadataInput>;
  mechanism?: InputMaybe<GQMarketplaceProjectArchetypeMechanism>;
  noteMd: Scalars['String']['input'];
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  purchaseId: Scalars['ID']['input'];
  status: GQMarketplacePurchaseLineItemStatus;
  unitFeeUsdCents: Scalars['Float']['input'];
  unitPriceUsdCents: Scalars['Float']['input'];
};

export type GQCreateMarketplacePurchaseLineItemPayload = {
  __typename?: 'CreateMarketplacePurchaseLineItemPayload';
  marketplacePurchase: GQMarketplacePurchase;
  marketplacePurchaseLineItem: GQMarketplacePurchaseLineItem;
};

export type GQCreateMarketplacePurchasePayload = {
  __typename?: 'CreateMarketplacePurchasePayload';
  marketplacePurchase: GQMarketplacePurchase;
};

/**
 * #####################################################
 * Marketplace
 */
export type GQCreateMarketplaceSupplierInput = {
  name: Scalars['String']['input'];
};

export type GQCreateMarketplaceSupplierPayload = {
  __typename?: 'CreateMarketplaceSupplierPayload';
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQCreateMeasurementContextItemInput = {
  descriptionMd: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  relations: Array<GQCreateMeasurementContextItemRelationInput>;
  tags: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  urls: Array<Scalars['String']['input']>;
};

export type GQCreateMeasurementContextItemPayload = {
  __typename?: 'CreateMeasurementContextItemPayload';
  measurementContextItem: GQMeasurementContextItem;
};

export type GQCreateMeasurementContextItemRelationInput = {
  id: Scalars['ID']['input'];
  type: GQMeasurementContextItemRelationType;
};

export type GQCreateMeasurementProjectFromCanonicalClimateProgramProjectInput =
  {
    canonicalClimateProgramProjectId: Scalars['ID']['input'];
    coverageInterval: Scalars['YMInterval']['input'];
    deadline: Scalars['Date']['input'];
    kickoff: Scalars['Date']['input'];
    name: Scalars['String']['input'];
  };

export type GQCreateMeasurementProjectFromCanonicalClimateProgramProjectPayload =
  {
    __typename?: 'CreateMeasurementProjectFromCanonicalClimateProgramProjectPayload';
    climateProgramProjects: Array<GQClimateProgramProject>;
    measurementProject: GQMeasurementProject;
  };

export type GQCreateMeasurementProjectFromWizardInput = {
  climateProgramProjectIds: Array<Scalars['ID']['input']>;
  coverageInterval: Scalars['YMInterval']['input'];
  deadline: Scalars['Date']['input'];
  kickoff: Scalars['Date']['input'];
  name: Scalars['String']['input'];
};

export type GQCreateMeasurementProjectFromWizardPayload = {
  __typename?: 'CreateMeasurementProjectFromWizardPayload';
  climateProgramProjects: Array<GQClimateProgramProject>;
  measurementProject: GQMeasurementProject;
};

export type GQCreateMeasurementProjectPayload = {
  __typename?: 'CreateMeasurementProjectPayload';
  measurementProject: Maybe<GQMeasurementProject>;
  org: Maybe<GQOrganization>;
};

export type GQCreateMeasurementTestSuiteBartInput = {
  activityTypeName: Scalars['String']['input'];
  addFixtureData: Scalars['Boolean']['input'];
  businessActivityTypeVersionId: Scalars['String']['input'];
  fixtureCustomIdentifier: Scalars['String']['input'];
  measurementTestSuiteId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type GQCreateMeasurementTestSuiteBartPayload = {
  __typename?: 'CreateMeasurementTestSuiteBartPayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQCreateMeasurementTestSuiteInput = {
  description: Scalars['String']['input'];
  kind?: InputMaybe<GQMeasurementTestSuiteKind>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  releaseIds: Array<Scalars['ID']['input']>;
  tagIds: Array<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type GQCreateMeasurementTestSuitePayload = {
  __typename?: 'CreateMeasurementTestSuitePayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQCreateMeasurementVerificationItemInput = {
  datasetId?: InputMaybe<Scalars['ID']['input']>;
  datasetName: Scalars['String']['input'];
  description: Scalars['String']['input'];
  measurementProjectId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type GQCreateMeasurementVerificationItemPayload = {
  __typename?: 'CreateMeasurementVerificationItemPayload';
  measurementVerificationItem: GQMeasurementVerificationItem;
};

export type GQCreateMeasurementVerificationItemQuestionInput = {
  contentKind: GQMeasurementVerificationQuestionContentKind;
  contentMetadataJson: Scalars['JSONString']['input'];
  contentSubtitle?: InputMaybe<Scalars['String']['input']>;
  contentTitle?: InputMaybe<Scalars['String']['input']>;
  questionKind: GQMeasurementVerificationQuestionKind;
  questionPrompt: Scalars['String']['input'];
  status: GQMeasurementVerificationQuestionStatus;
  verificationItemId: Scalars['ID']['input'];
};

export type GQCreateMeasurementVerificationItemQuestionPayload = {
  __typename?: 'CreateMeasurementVerificationItemQuestionPayload';
  measurementVerificationItemQuestion: GQMeasurementVerificationItemQuestion;
};

export type GQCreateMergeIntegrationInput = {
  publicToken: Scalars['String']['input'];
};

export type GQCreateNetSuiteIntegrationInput = {
  consumerKey: Scalars['String']['input'];
  consumerSecret: Scalars['String']['input'];
  realm: Scalars['String']['input'];
  token: Scalars['String']['input'];
  tokenSecret: Scalars['String']['input'];
};

export type GQCreateOneSchemaSessionForApiUploadInput = {
  apiUploadId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreateOneSchemaSessionForApiUploadPayload = {
  __typename?: 'CreateOneSchemaSessionForApiUploadPayload';
  fileMetadataId: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  measurementProjectId: Scalars['String']['output'];
  oneSchemaEmbedId: Scalars['String']['output'];
  oneSchemaEmbedStatus: Scalars['String']['output'];
  oneSchemaEmbedUserJwt: Scalars['String']['output'];
  oneSchemaSessionToken: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  userUploadTaskId: Scalars['String']['output'];
};

export type GQCreateOrgCompaniesAndCompaniesInput = {
  creationInfo: Array<GQOrgCompanyAndCompanyNameForCompany>;
  orgId: Scalars['String']['input'];
  source: GQOrgCompanySource;
};

export type GQCreateOrgCompaniesForCompanyInput = {
  companyId: Scalars['String']['input'];
  names: Array<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  source: GQOrgCompanySource;
};

export type GQCreateOrgCompaniesForCompanyPayload = {
  __typename?: 'CreateOrgCompaniesForCompanyPayload';
  organization: GQOrganization;
};

export type GQCreateOrgCompanyPayload = {
  __typename?: 'CreateOrgCompanyPayload';
  organization: GQOrganization;
};

export type GQCreateOrgRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  permissions?: InputMaybe<Array<GQCreatePermissionItemNestedInput>>;
};

export type GQCreateOrgStructureImportTemplateInput = {
  orgStructureVersionId: Scalars['ID']['input'];
};

export type GQCreateOrgStructureImportTemplatePayload = {
  __typename?: 'CreateOrgStructureImportTemplatePayload';
  oneSchemaTemplateKey: Scalars['String']['output'];
};

export type GQCreateOrgUnitInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orgUnitTypeId: Scalars['String']['input'];
  relatedOrgUnitIds?: InputMaybe<Array<Scalars['String']['input']>>;
  versionId: Scalars['ID']['input'];
};

export type GQCreateOrgUnitPayload = {
  __typename?: 'CreateOrgUnitPayload';
  orgUnit: GQOrgUnit;
};

export type GQCreateOrgUnitTypeInput = {
  externalId: Scalars['String']['input'];
  isLegalEntity: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  versionId: Scalars['ID']['input'];
};

export type GQCreateOrgUnitTypePayload = {
  __typename?: 'CreateOrgUnitTypePayload';
  orgUnitType: GQOrgUnitType;
};

export type GQCreateOrgUnitTypeRelationshipInput = {
  cardinality: GQOrgUnitRelationshipCardinality;
  defaultOrgUnitId?: InputMaybe<Scalars['ID']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  orgUnitTypeIdFrom: Scalars['ID']['input'];
  orgUnitTypeIdTo: Scalars['ID']['input'];
  versionId: Scalars['ID']['input'];
};

export type GQCreateOrgUnitTypeRelationshipPayload = {
  __typename?: 'CreateOrgUnitTypeRelationshipPayload';
  orgUnitTypeRelationship: GQOrgUnitTypeRelationship;
  orgUnits: Array<GQOrgUnit>;
};

export type GQCreateOrganizationInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  country: Scalars['String']['input'];
  countryAlpha2?: InputMaybe<Scalars['String']['input']>;
  demoOrg: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  domains: Array<Scalars['String']['input']>;
  fiscalYearStartMonth?: InputMaybe<Scalars['Int']['input']>;
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  passwordAuthDisabled: Scalars['Boolean']['input'];
  stagingOrg?: InputMaybe<Scalars['Boolean']['input']>;
  testOrg?: InputMaybe<Scalars['Boolean']['input']>;
  watershedPlanLegacy: GQWatershedPlanLegacy;
};

export type GQCreatePeerCompanyGroupInput = {
  footprintKinds?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  shouldHideFromFootprintOverview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCreatePermissionItemInput = {
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectType?: InputMaybe<GQPermissionObjectType>;
  orgAccessId?: InputMaybe<Scalars['ID']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  permission: GQPermissionType;
  roleId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreatePermissionItemNestedInput = {
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectType?: InputMaybe<GQPermissionObjectType>;
  permission: GQPermissionType;
};

export type GQCreatePermissionItemPayload = {
  __typename?: 'CreatePermissionItemPayload';
  permissionItem: GQPermissionItem;
};

export type GQCreatePlanInput = {
  autogenerateSbtTargets?: InputMaybe<Scalars['Boolean']['input']>;
  baselineYearStart: Scalars['YearMonth']['input'];
  forecastIntervalEndYearMonth: Scalars['YearMonth']['input'];
  forecastReferencePeriodEnd: Scalars['YearMonth']['input'];
  forecastReferencePeriodStart: Scalars['YearMonth']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isInNativeCurrency?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  overrideFootprintKind?: InputMaybe<Scalars['String']['input']>;
  termLength?: InputMaybe<GQSbtTermLength>;
};

export type GQCreatePlanPayload = {
  __typename?: 'CreatePlanPayload';
  plan: GQPlan;
};

export type GQCreatePlanTargetInput = {
  assignedTo?: InputMaybe<Scalars['String']['input']>;
  baseYear: Scalars['YearMonth']['input'];
  comparisonType?: InputMaybe<GQPlanTargetTargetComparisonType>;
  customNotes?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filters: GQFilterExpressionGroupInput;
  intensityType: GQPlanTargetIntensityType;
  parentTargetId?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['ID']['input'];
  reductionRate?: InputMaybe<GQTargetReductionRate>;
  reductionTargets: Array<GQReductionTargetByYearInput>;
  reportDescription?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreatePlanTargetPayload = {
  __typename?: 'CreatePlanTargetPayload';
  plan: GQPlan;
  planTarget: GQPlanTarget;
};

export type GQCreatePlanTargetReduxInput = {
  baseYear: Scalars['YearMonth']['input'];
  comparisonType?: InputMaybe<GQPlanTargetTargetComparisonType>;
  customIntensityConfigId?: InputMaybe<Scalars['ID']['input']>;
  customNotes?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emissionsTargetCustom: GQSimpleTimeseriesInput;
  filters: GQFilterExpressionGroupInput;
  intensityType: GQPlanTargetIntensityType;
  planId: Scalars['ID']['input'];
};

export type GQCreatePlanWithDefaultsInput = {
  autogenerateSbtTargets?: InputMaybe<Scalars['Boolean']['input']>;
  baselineYearStart?: InputMaybe<Scalars['YearMonth']['input']>;
  forecastIntervalEndYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  forecastReferencePeriodEnd?: InputMaybe<Scalars['YearMonth']['input']>;
  forecastReferencePeriodStart?: InputMaybe<Scalars['YearMonth']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  overrideFootprintKind?: InputMaybe<Scalars['String']['input']>;
  termLength?: InputMaybe<GQSbtTermLength>;
};

export type GQCreatePlanWithDefaultsPayload = {
  __typename?: 'CreatePlanWithDefaultsPayload';
  plan: GQPlan;
};

export type GQCreatePrecomputedActivityBasedInitiativeInput = {
  costTimeseries: GQSimpleTimeseriesInput;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  filters: GQFilterExpressionGroupWithNewFiltersInput;
  implementationTimeseries: GQSimpleTimeseriesInput;
  lever: GQPrecomputedLever;
  name: Scalars['String']['input'];
  planId: Scalars['ID']['input'];
  scheduleType: GQScheduleType;
};

export type GQCreatePrecomputedActivityBasedInitiativePayload = {
  __typename?: 'CreatePrecomputedActivityBasedInitiativePayload';
  plan: GQPlan;
};

export type GQCreatePrivateDisclosureInput = {
  companyId: Scalars['ID']['input'];
  disclosureTargets?: InputMaybe<Array<GQDisclosureTargetInput>>;
};

export type GQCreatePrivateDisclosurePayload = {
  __typename?: 'CreatePrivateDisclosurePayload';
  disclosure: GQDisclosure;
};

export type GQCreatePublicDisclosureInput = {
  companyId: Scalars['ID']['input'];
  emissionsYearData?: InputMaybe<GQCreateEmissionsYearInput>;
  gsUrl?: InputMaybe<Scalars['String']['input']>;
  publicUrl: Scalars['String']['input'];
  publishingYear: Scalars['Int']['input'];
  reportType: GQExternalReportType;
  sectionUrls?: InputMaybe<Array<GQCreateExternalReportSectionUrlInput>>;
};

export type GQCreatePublicDisclosurePayload = {
  __typename?: 'CreatePublicDisclosurePayload';
  publicDisclosure: GQPublicDisclosure;
};

export type GQCreatePublicDisclosureTargetInput = {
  disclosureTarget: GQDisclosureTargetInput;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  publicDisclosureId: Scalars['ID']['input'];
};

export type GQCreatePublicDisclosureTargetPayload = {
  __typename?: 'CreatePublicDisclosureTargetPayload';
  disclosureTarget: GQDisclosureTargetDetails;
};

export type GQCreateRealEstateSandboxEstimationInput = {
  buildingKind: Scalars['String']['input'];
  buildingSizeNative: Scalars['Int']['input'];
  buildingSizeUnit: GQBuildingSizeUnit;
  countryAlpha2: GQCountryAlpha2;
  percentRenewable?: InputMaybe<Scalars['Float']['input']>;
  scope1OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2LocationOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2MarketOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
};

export type GQCreateRealEstateSandboxEstimationPayload = {
  __typename?: 'CreateRealEstateSandboxEstimationPayload';
  backgroundJobId: Scalars['String']['output'];
};

export type GQCreateReferenceDataCitationInput = {
  displayName: Scalars['String']['input'];
  externalSourceNotes?: InputMaybe<Scalars['String']['input']>;
  externalSourceUrl?: InputMaybe<Scalars['String']['input']>;
  hasLicenseRestrictions: Scalars['Boolean']['input'];
  internalSourceNotes: Scalars['String']['input'];
  internalSourceUrl: Scalars['String']['input'];
  isCustomerProvided?: InputMaybe<Scalars['Boolean']['input']>;
  isSupplierSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  privateDisclosureId?: InputMaybe<Scalars['String']['input']>;
  publicDisclosureId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

export type GQCreateReferenceDataCitationPayload = {
  __typename?: 'CreateReferenceDataCitationPayload';
  referenceDataCitation: GQReferenceDataCitation;
};

export type GQCreateReferenceDataOverlayTemplateInput = {
  orgId: Scalars['String']['input'];
  referenceDataVersionId: Scalars['ID']['input'];
  skippedColumns?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQCreateReferenceDataOverlayTemplatePayload = {
  __typename?: 'CreateReferenceDataOverlayTemplatePayload';
  oneSchemaTemplateKey: Scalars['String']['output'];
};

export type GQCreateReferenceDataSourceInput = {
  externalSourceNotes?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  versionState?: InputMaybe<GQReferenceDataVersionState>;
};

export type GQCreateReferenceDataSourcePayload = {
  __typename?: 'CreateReferenceDataSourcePayload';
  referenceDataSource: GQReferenceDataSource;
};

export type GQCreateReferenceDataVersionInput = {
  kind?: InputMaybe<GQReferenceDataKind>;
  sourceId: Scalars['String']['input'];
  state?: InputMaybe<GQReferenceDataVersionState>;
  versionName: Scalars['String']['input'];
  versionVintage: Scalars['DateTime']['input'];
};

export type GQCreateReferenceDataVersionPayload = {
  __typename?: 'CreateReferenceDataVersionPayload';
  referenceDataVersion: GQReferenceDataVersion;
};

export type GQCreateRenewableElectricityTargetInput = {
  baseYear: Scalars['YearMonth']['input'];
  planId: Scalars['ID']['input'];
  targetYearExclusive?: InputMaybe<Scalars['YearMonth']['input']>;
};

export type GQCreateReportAttachmentFileInput = {
  filename: Scalars['String']['input'];
  size: Scalars['Int53']['input'];
};

export type GQCreateReportAttachmentsInput = {
  attachmentItemIds: Array<Scalars['ID']['input']>;
  reportId: Scalars['ID']['input'];
  reportQuestionId: Scalars['ID']['input'];
};

export type GQCreateReportAttachmentsPayload = {
  __typename?: 'CreateReportAttachmentsPayload';
  attachmentItems: Array<GQReportAttachmentItem>;
};

export type GQCreateReportBCorpInput = {
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  planId?: InputMaybe<Scalars['ID']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportCdpInput = {
  baseInterval: Scalars['YMInterval']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  previouslyFiled?: InputMaybe<Scalars['Boolean']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportConfigFromSrcInput = {
  configIdToExtend?: InputMaybe<Scalars['ID']['input']>;
  importBehaviorFlags?: InputMaybe<GQReportConfigImportBehaviorFlagsInput>;
  src: Scalars['JSONString']['input'];
};

export type GQCreateReportConfigInput = {
  brandColor: Scalars['String']['input'];
  computedQuestionIds: Array<Scalars['ID']['input']>;
  description: Scalars['String']['input'];
  inputQuestionIds: Array<Scalars['ID']['input']>;
  isCorporateReport: Scalars['Boolean']['input'];
  isCreationBlocked: Scalars['Boolean']['input'];
  isFinanceReport: Scalars['Boolean']['input'];
  isHiddenFromOverview: Scalars['Boolean']['input'];
  isRegulatoryExposureBlocked: Scalars['Boolean']['input'];
  itemIds: Array<Scalars['ID']['input']>;
  longName: Scalars['String']['input'];
  reportObjectives: Array<GQCanonicalReportObjective>;
  reportType: Scalars['String']['input'];
  requiredPermissions: Array<GQPermissionType>;
  shortName: Scalars['String']['input'];
  status?: InputMaybe<GQReportConfigStatus>;
  useReportingIntention?: InputMaybe<Scalars['Boolean']['input']>;
  useTableDisplay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCreateReportConfigPayload = {
  __typename?: 'CreateReportConfigPayload';
  reportConfig: GQReportConfig;
};

export type GQCreateReportEuropeTcfdInput = {
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  planId?: InputMaybe<Scalars['ID']['input']>;
  reportingInterval?: InputMaybe<Scalars['YMInterval']['input']>;
};

export type GQCreateReportFileAttachmentItemPayload = {
  __typename?: 'CreateReportFileAttachmentItemPayload';
  attachment: GQReportAttachmentItem;
  url: Scalars['String']['output'];
};

export type GQCreateReportFileAttachmentsInput = {
  files: Array<GQCreateReportAttachmentFileInput>;
};

export type GQCreateReportFileAttachmentsPayload = {
  __typename?: 'CreateReportFileAttachmentsPayload';
  items: Array<GQCreateReportFileAttachmentItemPayload>;
};

export type GQCreateReportInput = {
  bcorp?: InputMaybe<GQCreateReportBCorpInput>;
  cdp?: InputMaybe<GQCreateReportCdpInput>;
  europeTcfd?: InputMaybe<GQCreateReportEuropeTcfdInput>;
  sasb?: InputMaybe<GQCreateReportSasbInput>;
  sec?: InputMaybe<GQCreateReportEuropeTcfdInput>;
  secr?: InputMaybe<GQCreateReportSecrInput>;
  tcfd?: InputMaybe<GQCreateReportTcfdInput>;
  ukTender?: InputMaybe<GQCreateReportUkTenderInput>;
  ws?: InputMaybe<GQCreateReportWsInput>;
};

export type GQCreateReportPayload = {
  __typename?: 'CreateReportPayload';
  report: GQReport;
  reportGenerationJobId: Maybe<Scalars['String']['output']>;
};

export type GQCreateReportQuestionContainerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fullWidth?: InputMaybe<Scalars['Boolean']['input']>;
  guidance: Scalars['JSONString']['input'];
  insertionIndex?: InputMaybe<Scalars['Int']['input']>;
  items?: InputMaybe<Array<Scalars['ID']['input']>>;
  label?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  reportConfigId: Scalars['ID']['input'];
  status: Scalars['JSONString']['input'];
};

export type GQCreateReportQuestionContainerPayload = {
  __typename?: 'CreateReportQuestionContainerPayload';
  container: GQReportQuestionContainer;
};

export type GQCreateReportQuestionInput = {
  allowNotes: Scalars['Boolean']['input'];
  allowedAttachmentKinds?: InputMaybe<Array<GQReportAttachmentItemKind>>;
  componentId: Scalars['String']['input'];
  dynamicInputs?: InputMaybe<Scalars['JSONString']['input']>;
  guidance: Scalars['JSONString']['input'];
  identifier: Scalars['String']['input'];
  isComputed: Scalars['Boolean']['input'];
  isDynamic: Scalars['Boolean']['input'];
  isInput?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  reportConfigId: Scalars['ID']['input'];
  staticInputs?: InputMaybe<Scalars['JSONString']['input']>;
  status: Scalars['JSONString']['input'];
};

export type GQCreateReportQuestionPayload = {
  __typename?: 'CreateReportQuestionPayload';
  question: GQReportQuestion;
};

export type GQCreateReportSasbInput = {
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  industry: GQSasbIndustry;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportSecrInput = {
  balanceSheet?: InputMaybe<Scalars['Boolean']['input']>;
  baseInterval: Scalars['YMInterval']['input'];
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  employees?: InputMaybe<Scalars['Boolean']['input']>;
  listed?: InputMaybe<Scalars['Boolean']['input']>;
  previouslyFiled?: InputMaybe<Scalars['Boolean']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
  turnover?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCreateReportTcfdInput = {
  baseInterval?: InputMaybe<Scalars['YMInterval']['input']>;
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportUrlAttachmentItemInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type GQCreateReportUrlAttachmentItemPayload = {
  __typename?: 'CreateReportURLAttachmentItemPayload';
  attachment: GQReportAttachmentItem;
  url: Scalars['String']['output'];
};

export type GQCreateReportUkTenderInput = {
  baseInterval: Scalars['YMInterval']['input'];
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  planId: Scalars['ID']['input'];
  reductionPctIn5Years: Scalars['Float']['input'];
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportWithConfigAdminInput = {
  configId: Scalars['ID']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};

export type GQCreateReportWithConfigInput = {
  configId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type GQCreateReportWithCustomConfigAdminInput = {
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
};

export type GQCreateReportWithCustomConfigAdminPayload = {
  __typename?: 'CreateReportWithCustomConfigAdminPayload';
  report: GQReport;
};

export type GQCreateReportWithCustomConfigInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateReportWithCustomConfigPayload = {
  __typename?: 'CreateReportWithCustomConfigPayload';
  report: GQReport;
};

export type GQCreateReportWsInput = {
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  planId?: InputMaybe<Scalars['ID']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  permissions?: InputMaybe<Array<GQCreatePermissionItemNestedInput>>;
};

export type GQCreateRolePayload = {
  __typename?: 'CreateRolePayload';
  role: GQRole;
};

export type GQCreateSbtiTargetExclusionInput = {
  filters: GQFilterExpressionGroupWithNewFiltersInput;
};

export type GQCreateSbtiTargetExclusionPayload = {
  __typename?: 'CreateSbtiTargetExclusionPayload';
  org: GQOrganization;
};

export type GQCreateSimpleTimeseriesInput = {
  base: Scalars['Date']['input'];
  frequency: GQTimeseriesFrequency;
  values: Array<Scalars['Float']['input']>;
};

export type GQCreateSingleUploaderFacilitiesTaskInput = {
  measurementProjectId: Scalars['ID']['input'];
  type: GQFacilitiesType;
};

export type GQCreateSingleUploaderFacilitiesTaskPayload = {
  __typename?: 'CreateSingleUploaderFacilitiesTaskPayload';
  measurementProject: GQMeasurementProject;
  task: GQUserUploadTask;
};

export type GQCreateSnapshotInput = {
  config?: InputMaybe<GQFinanceSnapshotConfigV1Input>;
  note?: InputMaybe<Scalars['String']['input']>;
  snapshotName?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateSnapshotPayload = {
  __typename?: 'CreateSnapshotPayload';
  snapshot: GQFinanceSnapshot;
  workflowId: Scalars['String']['output'];
};

export type GQCreateSupplierAttachmentItemInput = {
  filename: Scalars['String']['input'];
  size: Scalars['Int53']['input'];
};

export type GQCreateSupplierAttachmentItemPayload = {
  __typename?: 'CreateSupplierAttachmentItemPayload';
  attachment: GQSupplierAttachment;
  url: Scalars['String']['output'];
};

export type GQCreateSupplierAttachmentsInput = {
  companyId: Scalars['ID']['input'];
  files: Array<GQCreateSupplierAttachmentItemInput>;
};

export type GQCreateSupplierAttachmentsPayload = {
  __typename?: 'CreateSupplierAttachmentsPayload';
  fileUrls: Array<GQCreateSupplierAttachmentItemPayload>;
};

export type GQCreateSupplierChartInput = {
  supplierChartInput: GQSupplierChartInput;
};

export type GQCreateSupplierChartInputAdmin = {
  orgId: Scalars['ID']['input'];
  supplierChartInput: GQSupplierChartInput;
};

export type GQCreateSupplierChartPayload = {
  __typename?: 'CreateSupplierChartPayload';
  views: Array<GQSupplierView>;
};

export type GQCreateSupplierChartPayloadAdmin = {
  __typename?: 'CreateSupplierChartPayloadAdmin';
  chart:
    | GQSupplierEmissionsForecastChartConfig
    | GQSupplierEngagementFunnelChartConfig
    | GQSupplierMetricsChartConfig
    | GQSupplierSbtCommitmentOverTimeChartConfig
    | GQSupplierStandardChartConfig
    | GQSupplierTaskStatusChangeOverTimeChartConfig;
  views: Array<GQSupplierView>;
};

export type GQCreateSupplierContactInput = {
  companyId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateSupplierContactPayload = {
  __typename?: 'CreateSupplierContactPayload';
  contact: GQSupplierContact;
};

export type GQCreateSupplierContactsInput = {
  contacts: Array<GQCreateSupplierContactInput>;
};

export type GQCreateSupplierContactsPayload = {
  __typename?: 'CreateSupplierContactsPayload';
  contacts: Array<GQSupplierContact>;
};

export type GQCreateSupplierSavedViewInput = {
  filters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
  name: Scalars['String']['input'];
};

export type GQCreateSupplierSavedViewPayload = {
  __typename?: 'CreateSupplierSavedViewPayload';
  view: GQSupplierView;
};

export type GQCreateSupplierScoreCriteriaInput = {
  filters: Array<GQBiQueryFilterInput>;
  label: Scalars['String']['input'];
  supplierScoreId: Scalars['ID']['input'];
};

export type GQCreateSupplierScoreCriteriaPayload = {
  __typename?: 'CreateSupplierScoreCriteriaPayload';
  supplierScoreCriteria: GQSupplierScoreCriteria;
};

export type GQCreateSupplierTableColumnInput = {
  baseEngagementTaskConfigId?: InputMaybe<Scalars['ID']['input']>;
  columnFormat?: InputMaybe<GQSupplierTableColumnFormat>;
  description?: InputMaybe<Scalars['String']['input']>;
  headerName?: InputMaybe<Scalars['String']['input']>;
  questionKey?: InputMaybe<Scalars['String']['input']>;
  selectOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCreateSupplierTableColumnPayload = {
  __typename?: 'CreateSupplierTableColumnPayload';
  views: Array<GQSupplierView>;
};

export type GQCreateSupportingDocumentsInput = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  buildingUtilityId?: InputMaybe<Scalars['ID']['input']>;
  files: Array<GQCreateUserUploadItemInput>;
  newBuildingToken?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Tasks are no longer required for supporting documents */
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
  utilityType?: InputMaybe<GQBuildingUtilityType>;
};

export type GQCreateSupportingDocumentsPayload = {
  __typename?: 'CreateSupportingDocumentsPayload';
  buildingUntyped: Maybe<GQBuildingUntyped>;
  buildingUtility: Maybe<GQBuildingUtility>;
  files: Array<GQCreateUserUploadItemPayload>;
  supportingDocuments: Array<GQSupportingDocument>;
};

export type GQCreateTargetInput = {
  orgId: Scalars['ID']['input'];
  target: GQTargetInput;
};

export type GQCreateTargetPayload = {
  __typename?: 'CreateTargetPayload';
  org: GQOrganization;
  target: GQTarget;
};

export type GQCreateTcfdArchetypeOpportunityInput = {
  archetypeRiskId?: InputMaybe<Scalars['ID']['input']>;
  distributionModel: GQTcfdStrategyModuleDistributionModel;
  geographies: Array<Scalars['String']['input']>;
  sectors: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userDescription: Scalars['String']['input'];
};

export type GQCreateTcfdArchetypeRiskInput = {
  distributionModel: GQTcfdStrategyModuleDistributionModel;
  geographies: Array<Scalars['String']['input']>;
  highTrajectoryImpact: GQTcfdRiskImpact;
  kind: GQTcfdRiskKind;
  lowTrajectoryImpact: GQTcfdRiskImpact;
  mediumTrajectoryImpact: GQTcfdRiskImpact;
  physicalRiskSeverity?: InputMaybe<GQTcfdPhysicalRiskSeverity>;
  possibleImpactDescription: Scalars['String']['input'];
  sectors: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userDescription: Scalars['String']['input'];
  whyWeChoseThisMd: Scalars['String']['input'];
};

export type GQCreateTripActionsIntegrationInput = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
};

export type GQCreateUpdateAndDeleteForecastScenariosInput = {
  created: Array<GQCreateForecastScenarioInput>;
  deletedScenarioIds: Array<Scalars['String']['input']>;
  forecastId: Scalars['String']['input'];
  updated: Array<GQUpdateForecastScenarioInput>;
};

export type GQCreateUpdateAndDeleteForecastScenariosPayload = {
  __typename?: 'CreateUpdateAndDeleteForecastScenariosPayload';
  forecast: GQForecast;
  forecastScenarios: Array<GQForecastScenario>;
  plans: Array<GQPlan>;
};

export type GQCreateUploadTaskInput = {
  commaSeparatedAssigneeEmails?: InputMaybe<Scalars['String']['input']>;
  commaSeparatedAssigneeNames?: InputMaybe<Scalars['String']['input']>;
  datasetName: Scalars['String']['input'];
  datasourceName: Scalars['String']['input'];
};

export const GQCreateUserAdminSendUserEmails = {
  FromWatershed: 'FromWatershed',
  None: 'None',
} as const;

export type GQCreateUserAdminSendUserEmails =
  (typeof GQCreateUserAdminSendUserEmails)[keyof typeof GQCreateUserAdminSendUserEmails];
export type GQCreateUserAdminVersionInput = {
  activateUser?: InputMaybe<Scalars['Boolean']['input']>;
  allowOutsideDomain?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<
    Array<InputMaybe<GQCreatePermissionItemNestedInput>>
  >;
  roles?: InputMaybe<Array<Scalars['ID']['input']>>;
  sendUserEmails: GQCreateUserAdminSendUserEmails;
};

export type GQCreateUserAdminVersionPayload = {
  __typename?: 'CreateUserAdminVersionPayload';
  org: GQOrganization;
  user: GQUser;
};

export type GQCreateUserInput = {
  activateUser?: InputMaybe<Scalars['Boolean']['input']>;
  allowOutsideDomain?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<
    Array<InputMaybe<GQCreatePermissionItemNestedInput>>
  >;
  roles?: InputMaybe<Array<Scalars['ID']['input']>>;
  source?: InputMaybe<GQCreateUserSource>;
};

export type GQCreateUserPayload = {
  __typename?: 'CreateUserPayload';
  org: GQOrganization;
  user: GQUser;
};

export type GQCreateUserPermissionRequestInput = {
  permission: GQPermissionType;
};

export type GQCreateUserPermissionRequestPayload = {
  __typename?: 'CreateUserPermissionRequestPayload';
  userPermissionRequest: Maybe<GQUserPermissionRequest>;
};

export const GQCreateUserSource = {
  Admin: 'Admin',
  Dashboard: 'Dashboard',
  FinancePortal: 'FinancePortal',
  SupplierPortal: 'SupplierPortal',
} as const;

export type GQCreateUserSource =
  (typeof GQCreateUserSource)[keyof typeof GQCreateUserSource];
export type GQCreateUserUploadItemInput = {
  filename: Scalars['String']['input'];
  size: Scalars['Int53']['input'];
};

export type GQCreateUserUploadItemPayload = {
  __typename?: 'CreateUserUploadItemPayload';
  fileId: Scalars['ID']['output'];
  filename: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GQCreateUserUploadTaskInput = {
  datasourceId: Scalars['String']['input'];
  measurementProjectId: Scalars['ID']['input'];
};

export type GQCreateUserUploadTaskPayload = {
  __typename?: 'CreateUserUploadTaskPayload';
  measurementProject: GQMeasurementProject;
  userUploadTask: GQUserUploadTask;
};

export type GQCreateUserUploadsInput = {
  ctsVersionId?: InputMaybe<Scalars['ID']['input']>;
  files: Array<GQCreateUserUploadItemInput>;
  fullStorySessionUrl?: InputMaybe<Scalars['String']['input']>;
  processingMode?: InputMaybe<GQUserUploadProcessingMode>;
  updateFileMetadataId?: InputMaybe<Scalars['ID']['input']>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQCreateUserUploadsPayload = {
  __typename?: 'CreateUserUploadsPayload';
  failedToUpload: Array<GQUserUploadFailure>;
  files: Array<GQCreateUserUploadItemPayload>;
  userUploadTask: Maybe<GQUserUploadTask>;
  userUploads: Array<GQUserUpload>;
};

export type GQCreateUtilityDocsInput = {
  ctsId: Scalars['ID']['input'];
  ctsVersionId: Scalars['ID']['input'];
  datasetId: Scalars['ID']['input'];
  files: Array<GQCreateUserUploadItemInput>;
  utilityType?: InputMaybe<GQBuildingUtilityType>;
};

export type GQCreateUtilityDocsPayload = {
  __typename?: 'CreateUtilityDocsPayload';
  candidateIds: Array<Scalars['String']['output']>;
  failedToUpload: Maybe<Array<GQUserUploadFailure>>;
  files: Array<GQCreateUserUploadItemPayload>;
  supportingDocuments: Array<GQSupportingDocument>;
};

export type GQCreateValueMappingCommentInput = {
  createdBy: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  subjectId: Scalars['ID']['input'];
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQCreateWatershedFootprintReviewRequestInput = {
  footprintVersionId: Scalars['ID']['input'];
};

export type GQCreateWatershedFootprintReviewRequestPayload = {
  __typename?: 'CreateWatershedFootprintReviewRequestPayload';
  discussionThreads: GQDiscussionsPayload;
  footprintVersion: GQFootprintVersion;
};

export type GQCsrdDoubleMaterialityAssessment = {
  __typename?: 'CsrdDoubleMaterialityAssessment';
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  fileMetadataId: Maybe<Scalars['String']['output']>;
  ingestionMethod: GQDmaIngestionMethod;
  orgId: Scalars['String']['output'];
  validityEnd: Scalars['YearMonth']['output'];
  validityStart: Scalars['YearMonth']['output'];
};

export type GQCsrdDoubleMaterialityAssessmentTopic = {
  __typename?: 'CsrdDoubleMaterialityAssessmentTopic';
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  dmaId: Scalars['String']['output'];
  financialAssessment: Maybe<Scalars['String']['output']>;
  impactAssessment: Maybe<Scalars['String']['output']>;
  isMaterial: Scalars['Boolean']['output'];
  orgId: Scalars['String']['output'];
  topicIdentifier: GQCsrdTopic;
};

export type GQCsrdMaterialMetric = GQIdInterface & {
  __typename?: 'CsrdMaterialMetric';
  id: Scalars['ID']['output'];
  metricNumber: Scalars['String']['output'];
  watershedMetricGroup: GQCsrdWatershedMetricGroup;
};

export type GQCsrdMaterialStandard = GQIdInterface & {
  __typename?: 'CsrdMaterialStandard';
  id: Scalars['ID']['output'];
  materialMetrics: Array<GQCsrdMaterialMetric>;
  standard: GQCsrdStandard;
};

export const GQCsrdStandard = {
  E1: 'E1',
  E2: 'E2',
  E3: 'E3',
  E4: 'E4',
  E5: 'E5',
} as const;

export type GQCsrdStandard =
  (typeof GQCsrdStandard)[keyof typeof GQCsrdStandard];
export const GQCsrdTopic = {
  E1: 'E1',
  E2: 'E2',
  E3: 'E3',
  E4: 'E4',
  E5: 'E5',
  G1: 'G1',
  S1: 'S1',
  S2: 'S2',
  S3: 'S3',
  S4: 'S4',
} as const;

export type GQCsrdTopic = (typeof GQCsrdTopic)[keyof typeof GQCsrdTopic];
export type GQCsrdTopicMateriality = {
  financialAssessment?: InputMaybe<Scalars['String']['input']>;
  impactAssessment?: InputMaybe<Scalars['String']['input']>;
  isMaterial: Scalars['Boolean']['input'];
  topicIdentifier: GQCsrdTopic;
};

export const GQCsrdVersion = {
  C20235303Final: 'C20235303Final',
} as const;

export type GQCsrdVersion = (typeof GQCsrdVersion)[keyof typeof GQCsrdVersion];
export const GQCsrdWatershedMetricGroup = {
  AirPollution: 'AirPollution',
  CarbonCredits: 'CarbonCredits',
  EnergyConsumptionAndMix: 'EnergyConsumptionAndMix',
  GhgEmissions: 'GhgEmissions',
  Microplastics: 'Microplastics',
  ProductOutflows: 'ProductOutflows',
  ResourceInflows: 'ResourceInflows',
  Waste: 'Waste',
} as const;

export type GQCsrdWatershedMetricGroup =
  (typeof GQCsrdWatershedMetricGroup)[keyof typeof GQCsrdWatershedMetricGroup];
export type GQCtsForm = {
  __typename?: 'CtsForm';
  closedAt: Maybe<Scalars['Date']['output']>;
  createdBy: GQUser;
  ctsVersionId: Scalars['ID']['output'];
  customerTargetSchemaId: Scalars['ID']['output'];
  dataset: GQDataset;
  datasetId: Scalars['ID']['output'];
  description: Maybe<Scalars['String']['output']>;
  entries: Array<GQCtsFormEntry>;
  id: Scalars['ID']['output'];
  title: Maybe<Scalars['String']['output']>;
};

export type GQCtsFormEntry = {
  __typename?: 'CtsFormEntry';
  createdBy: GQUser;
  data: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
};

export const GQCtsTestRunStatus = {
  Approved: 'Approved',
  Error: 'Error',
  Fail: 'Fail',
  Pass: 'Pass',
  Stale: 'Stale',
} as const;

export type GQCtsTestRunStatus =
  (typeof GQCtsTestRunStatus)[keyof typeof GQCtsTestRunStatus];
export const GQCtsTransformTestKind = {
  File: 'File',
  Json: 'Json',
} as const;

export type GQCtsTransformTestKind =
  (typeof GQCtsTransformTestKind)[keyof typeof GQCtsTransformTestKind];
export type GQCtsUserUpload = {
  __typename?: 'CtsUserUpload';
  batvName: Scalars['String']['output'];
  businessActivityType: Scalars['String']['output'];
  datasourceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  measurementProjectId: Scalars['ID']['output'];
  userUploadFileName: Scalars['String']['output'];
  userUploadId: Scalars['ID']['output'];
};

export type GQCtsvOneSchemaFileFeed = {
  __typename?: 'CtsvOneSchemaFileFeed';
  ctsVersionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  lastRun: Maybe<GQCtsvOneSchemaFileFeedImport>;
  numErrorImports: Scalars['Int']['output'];
  numSuccessfulImports: Scalars['Int']['output'];
  oneSchemaFileFeedId: Scalars['String']['output'];
};

export type GQCtsvOneSchemaFileFeedImport = {
  __typename?: 'CtsvOneSchemaFileFeedImport';
  createdAt: Scalars['DateTime']['output'];
  ctsvOneSchemaFileFeed: GQCtsvOneSchemaFileFeed;
  destinationDataPreview: Maybe<Scalars['UntypedData']['output']>;
  id: Scalars['ID']['output'];
  oneSchemaFileFeedImportId: Scalars['String']['output'];
  sourceUserUploadCandidateId: Scalars['ID']['output'];
  status: GQCtsvOneSchemaFileFeedImportStatus;
};

export const GQCtsvOneSchemaFileFeedImportStatus = {
  Error: 'Error',
  Pending: 'Pending',
  Success: 'Success',
} as const;

export type GQCtsvOneSchemaFileFeedImportStatus =
  (typeof GQCtsvOneSchemaFileFeedImportStatus)[keyof typeof GQCtsvOneSchemaFileFeedImportStatus];
export const GQCurrencyCode = {
  Aed: 'AED',
  Afn: 'AFN',
  All: 'ALL',
  Amd: 'AMD',
  Ang: 'ANG',
  Aoa: 'AOA',
  Ars: 'ARS',
  Aud: 'AUD',
  Awg: 'AWG',
  Azn: 'AZN',
  Bam: 'BAM',
  Bbd: 'BBD',
  Bdt: 'BDT',
  Bgn: 'BGN',
  Bhd: 'BHD',
  Bif: 'BIF',
  Bmd: 'BMD',
  Bnd: 'BND',
  Bob: 'BOB',
  Brl: 'BRL',
  Bsd: 'BSD',
  Btn: 'BTN',
  Bwp: 'BWP',
  Byn: 'BYN',
  Bzd: 'BZD',
  Cad: 'CAD',
  Cdf: 'CDF',
  Chf: 'CHF',
  Clp: 'CLP',
  Cny: 'CNY',
  Cop: 'COP',
  Crc: 'CRC',
  Cup: 'CUP',
  Cve: 'CVE',
  Czk: 'CZK',
  Djf: 'DJF',
  Dkk: 'DKK',
  Dop: 'DOP',
  Dzd: 'DZD',
  Egp: 'EGP',
  Ern: 'ERN',
  Etb: 'ETB',
  Eur: 'EUR',
  Fjd: 'FJD',
  Fkp: 'FKP',
  Gbp: 'GBP',
  Gel: 'GEL',
  Ggp: 'GGP',
  Ghs: 'GHS',
  Gip: 'GIP',
  Gmd: 'GMD',
  Gnf: 'GNF',
  Gtq: 'GTQ',
  Gyd: 'GYD',
  Hkd: 'HKD',
  Hnl: 'HNL',
  Hrk: 'HRK',
  Htg: 'HTG',
  Huf: 'HUF',
  Idr: 'IDR',
  Ils: 'ILS',
  Imp: 'IMP',
  Inr: 'INR',
  Iqd: 'IQD',
  Irr: 'IRR',
  Isk: 'ISK',
  Jep: 'JEP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Jpy: 'JPY',
  Kes: 'KES',
  Kgs: 'KGS',
  Khr: 'KHR',
  Kmf: 'KMF',
  Kpw: 'KPW',
  Krw: 'KRW',
  Kwd: 'KWD',
  Kyd: 'KYD',
  Kzt: 'KZT',
  Lak: 'LAK',
  Lbp: 'LBP',
  Lkr: 'LKR',
  Lrd: 'LRD',
  Lsl: 'LSL',
  Lyd: 'LYD',
  Mad: 'MAD',
  Mdl: 'MDL',
  Mga: 'MGA',
  Mkd: 'MKD',
  Mmk: 'MMK',
  Mnt: 'MNT',
  Mop: 'MOP',
  Mru: 'MRU',
  Mur: 'MUR',
  Mvr: 'MVR',
  Mwk: 'MWK',
  Mxn: 'MXN',
  Myr: 'MYR',
  Mzn: 'MZN',
  Nad: 'NAD',
  Ngn: 'NGN',
  Nio: 'NIO',
  Nok: 'NOK',
  Npr: 'NPR',
  Nzd: 'NZD',
  Omr: 'OMR',
  Pab: 'PAB',
  Pen: 'PEN',
  Pgk: 'PGK',
  Php: 'PHP',
  Pkr: 'PKR',
  Pln: 'PLN',
  Pyg: 'PYG',
  Qar: 'QAR',
  Ron: 'RON',
  Rsd: 'RSD',
  Rub: 'RUB',
  Rur: 'RUR',
  Rwf: 'RWF',
  Sar: 'SAR',
  Sbd: 'SBD',
  Scr: 'SCR',
  Sdg: 'SDG',
  Sek: 'SEK',
  Sgd: 'SGD',
  Shp: 'SHP',
  Sll: 'SLL',
  Sos: 'SOS',
  Srd: 'SRD',
  Ssp: 'SSP',
  Std: 'STD',
  Svc: 'SVC',
  Syp: 'SYP',
  Szl: 'SZL',
  Thb: 'THB',
  Tjs: 'TJS',
  Tmt: 'TMT',
  Tnd: 'TND',
  Top: 'TOP',
  Try: 'TRY',
  Ttd: 'TTD',
  Tvd: 'TVD',
  Twd: 'TWD',
  Tzs: 'TZS',
  Uah: 'UAH',
  Ugx: 'UGX',
  Usd: 'USD',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vef: 'VEF',
  Vnd: 'VND',
  Vuv: 'VUV',
  Wst: 'WST',
  Xaf: 'XAF',
  Xcd: 'XCD',
  Xof: 'XOF',
  Xpf: 'XPF',
  Yer: 'YER',
  Zar: 'ZAR',
  Zmw: 'ZMW',
} as const;

export type GQCurrencyCode =
  (typeof GQCurrencyCode)[keyof typeof GQCurrencyCode];
export type GQCurrencyExchangeRate = {
  __typename?: 'CurrencyExchangeRate';
  baseCurrency: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type GQCurrencyOverride = {
  __typename?: 'CurrencyOverride';
  selectedCurrency: Scalars['String']['output'];
};

export type GQCurrencyOverrideInput = {
  selectedCurrency?: InputMaybe<Scalars['String']['input']>;
};

export type GQCustomEmissionsFactorsForDashboard = {
  __typename?: 'CustomEmissionsFactorsForDashboard';
  citationNotes: Scalars['String']['output'];
  citationTitle: Scalars['String']['output'];
  citationUrl: Scalars['String']['output'];
  description: Scalars['String']['output'];
  emissionsSpecifier: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kgco2e: Scalars['Float']['output'];
  startDate: Scalars['String']['output'];
  unit: Scalars['String']['output'];
};

export type GQCustomEmissionsFactorsSetup = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'CustomEmissionsFactorsSetup';
    id: Scalars['ID']['output'];
  };

export type GQCustomIntensityConfig = {
  __typename?: 'CustomIntensityConfig';
  description: Scalars['String']['output'];
  humanReadable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isSbtValidated: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  quantityType: GQCustomIntensityConfigQuantityType;
  unit: Maybe<Scalars['String']['output']>;
};

export type GQCustomIntensityConfigInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  humanReadable?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isSbtValidated?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  quantityType?: InputMaybe<GQCustomIntensityConfigQuantityType>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export const GQCustomIntensityConfigQuantityType = {
  Currency: 'Currency',
  Number: 'Number',
} as const;

export type GQCustomIntensityConfigQuantityType =
  (typeof GQCustomIntensityConfigQuantityType)[keyof typeof GQCustomIntensityConfigQuantityType];
export type GQCustomIntensityDataPoint = {
  customIntensityId: Scalars['String']['output'];
  value: Maybe<Scalars['Float']['output']>;
};

export type GQCustomIntensityMetadata = {
  __typename?: 'CustomIntensityMetadata';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  intensityUnit: Scalars['String']['output'];
  label: Scalars['String']['output'];
  scaleFactor: Scalars['Float']['output'];
  tooltipUnit: Scalars['String']['output'];
  unit: Scalars['String']['output'];
  unitUserFacing: Scalars['String']['output'];
};

export type GQCustomReportControlsInput = {
  reportId: Scalars['ID']['input'];
};

export type GQCustomReportControlsPayload = {
  __typename?: 'CustomReportControlsPayload';
  customReportControlsQuestion: GQReportQuestion;
};

export const GQCustomReportHeaderKind = {
  H1: 'H1',
  Text: 'Text',
} as const;

export type GQCustomReportHeaderKind =
  (typeof GQCustomReportHeaderKind)[keyof typeof GQCustomReportHeaderKind];
export type GQCustomReportsWithBiSavedViewInput = {
  biSavedViewStableId: Scalars['ID']['input'];
};

export const GQCustomerStatus = {
  Canceled: 'Canceled',
  Completed: 'Completed',
  ConfirmedByCustomer: 'ConfirmedByCustomer',
  ConfirmedByWatershed: 'ConfirmedByWatershed',
  ContractReceived: 'ContractReceived',
  ContractSigned: 'ContractSigned',
  Expired: 'Expired',
  Initiated: 'Initiated',
  InvoicePaid: 'InvoicePaid',
  InvoiceReceived: 'InvoiceReceived',
} as const;

export type GQCustomerStatus =
  (typeof GQCustomerStatus)[keyof typeof GQCustomerStatus];
export type GQCustomerTargetSchema = {
  __typename?: 'CustomerTargetSchema';
  canonicalDatasets: Array<GQCanonicalDataset>;
  datasets: Array<GQDataset>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMd: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latestPublishedVersion: Maybe<GQCustomerTargetSchemaVersion>;
  name: Scalars['String']['output'];
  org: Maybe<GQOrganization>;
  publishedVersions: Array<GQCustomerTargetSchemaVersion>;
  rank: Scalars['Int']['output'];
  schemaId: Maybe<Scalars['ID']['output']>;
  utilityType: Maybe<GQBuildingUtilityType>;
  versions: Array<GQCustomerTargetSchemaVersion>;
};

export type GQCustomerTargetSchemaTransform = {
  __typename?: 'CustomerTargetSchemaTransform';
  businessActivityTypeName: Maybe<Scalars['String']['output']>;
  businessActivityTypeVersion: Maybe<GQBusinessActivityTypeVersion>;
  businessActivityTypeVersionId: Maybe<Scalars['String']['output']>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  runs: Array<GQCustomerTargetSchemaTransformRun>;
  sql: Scalars['String']['output'];
  testCases: Array<GQCustomerTargetSchemaTransformTestCase>;
  transformTarget: GQCustomerTargetSchemaTransformTarget;
};

export const GQCustomerTargetSchemaTransformParentType = {
  CtsVersion: 'CtsVersion',
  UserUpload: 'UserUpload',
} as const;

export type GQCustomerTargetSchemaTransformParentType =
  (typeof GQCustomerTargetSchemaTransformParentType)[keyof typeof GQCustomerTargetSchemaTransformParentType];
export type GQCustomerTargetSchemaTransformRun = {
  __typename?: 'CustomerTargetSchemaTransformRun';
  createdAt: Scalars['DateTime']['output'];
  error: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  input: GQFileMetadata;
  output: Maybe<GQParquetData>;
  runner: GQUser;
  sql: Scalars['String']['output'];
  standardizedOutput: Maybe<GQParquetData>;
  transform: GQCustomerTargetSchemaTransform;
  warnings: Array<GQValidationWarning>;
};

export const GQCustomerTargetSchemaTransformTarget = {
  Bart: 'Bart',
  ReferenceData: 'ReferenceData',
  Utilities: 'Utilities',
} as const;

export type GQCustomerTargetSchemaTransformTarget =
  (typeof GQCustomerTargetSchemaTransformTarget)[keyof typeof GQCustomerTargetSchemaTransformTarget];
export type GQCustomerTargetSchemaTransformTestCase = {
  __typename?: 'CustomerTargetSchemaTransformTestCase';
  expectedOutput: Maybe<GQParquetData>;
  id: Scalars['ID']['output'];
  inputJson: Maybe<Scalars['JSONString']['output']>;
  kind: GQCtsTransformTestKind;
  latestRun: Maybe<GQCustomerTargetSchemaTransformTestRun>;
  name: Scalars['String']['output'];
  transform: GQCustomerTargetSchemaTransform;
};

export type GQCustomerTargetSchemaTransformTestRun = {
  __typename?: 'CustomerTargetSchemaTransformTestRun';
  id: Scalars['ID']['output'];
  status: GQCtsTestRunStatus;
  testDiffResults: Maybe<Scalars['JSONString']['output']>;
  transformRun: GQCustomerTargetSchemaTransformRun;
};

export type GQCustomerTargetSchemaVersion = {
  __typename?: 'CustomerTargetSchemaVersion';
  createdAt: Scalars['DateTime']['output'];
  deprecatedAt: Maybe<Scalars['DateTime']['output']>;
  exampleData: Scalars['JSONString']['output'];
  formattingNotesMd: Maybe<Scalars['String']['output']>;
  globalIngestionExampleData: GQIngestionExampleData;
  id: Scalars['ID']['output'];
  lastEditedByUser: GQUser;
  oneSchemaFileFeed: Maybe<GQCtsvOneSchemaFileFeed>;
  orgDatasetExampleData: Array<GQIngestionExampleData>;
  orgSchemaJson: Scalars['JSONString']['output'];
  plannedRemovedAt: Maybe<Scalars['DateTime']['output']>;
  publishedAt: Maybe<Scalars['DateTime']['output']>;
  publishedForTestingAt: Maybe<Scalars['DateTime']['output']>;
  removedAt: Maybe<Scalars['DateTime']['output']>;
  schema: GQCustomerTargetSchema;
  schemaJson: Scalars['JSONString']['output'];
  schemaVersionId: Maybe<Scalars['ID']['output']>;
  semverVersion: Scalars['String']['output'];
  transforms: Array<GQCustomerTargetSchemaTransform>;
  transformsIncludingDeleted: Array<GQCustomerTargetSchemaTransform>;
  unpublishedAt: Maybe<Scalars['DateTime']['output']>;
  versionNotesMd: Scalars['String']['output'];
};

export type GQCustomerTargetSchemaVersionOrgSchemaJsonArgs = {
  datasetId: Scalars['ID']['input'];
  datasourceId: InputMaybe<Scalars['ID']['input']>;
};

export type GQDataApprovalChangelogEvent = {
  __typename?: 'DataApprovalChangelogEvent';
  activityType: GQActivityTimelineItemType;
  actorUser: Maybe<GQUser>;
  eventTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  moreDetails: Scalars['JSONString']['output'];
};

export type GQDataApprovalObjectChangelogInput = {
  objectId: Scalars['ID']['input'];
  objectType: GQObjectType;
};

export type GQDataApprovalObjectChangelogPayload = {
  __typename?: 'DataApprovalObjectChangelogPayload';
  changelogEvents: Array<GQDataApprovalChangelogEvent>;
};

export type GQDataApprovalUserUploadTaskChangelogInput = {
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQDataApprovalUserUploadTaskChangelogPayload = {
  __typename?: 'DataApprovalUserUploadTaskChangelogPayload';
  changelogEvents: Array<GQDataApprovalChangelogEvent>;
};

export type GQDataCollectionEmissionsData = {
  __typename?: 'DataCollectionEmissionsData';
  amountKgco2e: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  surveyStatus: Maybe<GQCompanySurveyStatus>;
};

export const GQDataGridFilterOperator = {
  Contains: 'contains',
  EndsWith: 'endsWith',
  Equals: 'equals',
  GreaterThan: 'greaterThan',
  GreaterThanOrEqual: 'greaterThanOrEqual',
  IsAnyOf: 'isAnyOf',
  IsEmpty: 'isEmpty',
  IsNotAnyOf: 'isNotAnyOf',
  IsNotEmpty: 'isNotEmpty',
  LessThan: 'lessThan',
  LessThanOrEqual: 'lessThanOrEqual',
  NotEquals: 'notEquals',
  StartsWith: 'startsWith',
} as const;

export type GQDataGridFilterOperator =
  (typeof GQDataGridFilterOperator)[keyof typeof GQDataGridFilterOperator];
export const GQDataGridSortDirection = {
  Asc: 'asc',
  Desc: 'desc',
} as const;

export type GQDataGridSortDirection =
  (typeof GQDataGridSortDirection)[keyof typeof GQDataGridSortDirection];
export type GQDataGridSortItem = {
  __typename?: 'DataGridSortItem';
  field: Scalars['String']['output'];
  sort: GQDataGridSortDirection;
};

export type GQDataGridSortItemInput = {
  field: Scalars['String']['input'];
  sort: GQDataGridSortDirection;
};

export const GQDataIngestionMeasurementEventKind = {
  DataIngestionAdditionalQuestionsCompleted:
    'DataIngestionAdditionalQuestionsCompleted',
  DataIngestionDataFormatSelectionCompleted:
    'DataIngestionDataFormatSelectionCompleted',
  DataIngestionUserColumnMappingCompleted:
    'DataIngestionUserColumnMappingCompleted',
  DataIngestionUserFooterSelectionCompleted:
    'DataIngestionUserFooterSelectionCompleted',
  DataIngestionUserHeaderSelectionCompleted:
    'DataIngestionUserHeaderSelectionCompleted',
} as const;

export type GQDataIngestionMeasurementEventKind =
  (typeof GQDataIngestionMeasurementEventKind)[keyof typeof GQDataIngestionMeasurementEventKind];
export type GQDataIssue = GQContextRelationInterface &
  GQIdInterface &
  GQMeasurementTaskObject &
  GQQuestion & {
    __typename?: 'DataIssue';
    adminUrl: Scalars['String']['output'];
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQFootprintQuestion
      | GQMeasurementProject
      | GQMeasurementVerificationItemQuestion
    >;
    anchor: GQDiscussionAnchorPayload;
    assignee: Maybe<GQUser>;
    comments: GQCommentConnection;
    createdAt: Scalars['DateTime']['output'];
    description: Maybe<Scalars['String']['output']>;
    files: GQFileMetadataConnection;
    id: Scalars['ID']['output'];
    isCustomerInitiated: Scalars['Boolean']['output'];
    isPublished: Scalars['Boolean']['output'];
    linkedObject: Maybe<GQDataIssueLinkedObject>;
    measurementProject: GQMeasurementProject;
    measurementProjectId: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    state: GQDataIssueState;
    tags: Maybe<Array<GQDataIssueTag>>;
    title: Scalars['String']['output'];
  };

export type GQDataIssueCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQDataIssueConnection = {
  __typename?: 'DataIssueConnection';
  edges: Array<Maybe<GQDataIssueEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDataIssueEdge = {
  __typename?: 'DataIssueEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDataIssue>;
};

export type GQDataIssueLinkedObject =
  | GQFinancialsReviewItem
  | GQMeasurementVerificationItemQuestion
  | GQUserUploadTask
  | GQValueMappingTask;

export const GQDataIssueState = {
  Done: 'Done',
  NeedsUserResponse: 'NeedsUserResponse',
} as const;

export type GQDataIssueState =
  (typeof GQDataIssueState)[keyof typeof GQDataIssueState];
export const GQDataIssueTag = {
  ConfirmColumnToUse: 'ConfirmColumnToUse',
  ConfirmDataAnomaly: 'ConfirmDataAnomaly',
  ConfirmDataInterpretation: 'ConfirmDataInterpretation',
  ConfirmDataSourceToUse: 'ConfirmDataSourceToUse',
  MissingColumn: 'MissingColumn',
  MissingDataForCategory: 'MissingDataForCategory',
  MissingDataForDateRange: 'MissingDataForDateRange',
  Other: 'Other',
} as const;

export type GQDataIssueTag =
  (typeof GQDataIssueTag)[keyof typeof GQDataIssueTag];
export const GQDataRegion = {
  Eu: 'EU',
  Us: 'US',
} as const;

export type GQDataRegion = (typeof GQDataRegion)[keyof typeof GQDataRegion];
export type GQDataRegistryBatchMigratorRun = {
  __typename?: 'DataRegistryBatchMigratorRun';
  author: Maybe<GQUser>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  error: Maybe<Scalars['String']['output']>;
  failureCount: Maybe<Scalars['Int']['output']>;
  filters: Maybe<Scalars['JSONString']['output']>;
  fromSchemaVersion: GQSchemaRegistrySchemaVersion;
  fromSchemaVersionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  successCount: Maybe<Scalars['Int']['output']>;
  toSchemaVersion: GQSchemaRegistrySchemaVersion;
  toSchemaVersionId: Scalars['ID']['output'];
  workflowId: Maybe<Scalars['String']['output']>;
};

export type GQDataRegistryBatchMigratorRunConnection = {
  __typename?: 'DataRegistryBatchMigratorRunConnection';
  edges: Array<Maybe<GQDataRegistryBatchMigratorRunEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDataRegistryBatchMigratorRunEdge = {
  __typename?: 'DataRegistryBatchMigratorRunEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDataRegistryBatchMigratorRun>;
};

export type GQDataRegistryMigrator = {
  __typename?: 'DataRegistryMigrator';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inputSchemaVersionId: Scalars['ID']['output'];
  inputVersion: Maybe<GQSchemaRegistrySchemaVersion>;
  migrationCode: Maybe<Scalars['String']['output']>;
  migratorType: Scalars['String']['output'];
  outputSchemaVersionId: Scalars['ID']['output'];
  outputVersion: Maybe<GQSchemaRegistrySchemaVersion>;
};

export type GQDataRegistryMigratorAndSchema = {
  __typename?: 'DataRegistryMigratorAndSchema';
  migrator: GQDataRegistryMigrator;
  outputSchema: GQSchemaRegistrySchemaVersion;
};

export type GQDataRegistryMigratorConnection = {
  __typename?: 'DataRegistryMigratorConnection';
  edges: Array<Maybe<GQDataRegistryMigratorEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDataRegistryMigratorEdge = {
  __typename?: 'DataRegistryMigratorEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDataRegistryMigrator>;
};

export type GQDataRegistryMigratorRun = {
  __typename?: 'DataRegistryMigratorRun';
  batchMigratorRunId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  errorMessage: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inputTable: GQDataRegistryTable;
  inputTableId: Scalars['ID']['output'];
  migrator: GQDataRegistryMigrator;
  migratorId: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  organization: Maybe<GQOrganization>;
  outputTable: Maybe<GQDataRegistryTable>;
  outputTableId: Maybe<Scalars['ID']['output']>;
  status: Scalars['String']['output'];
  workflowId: Maybe<Scalars['String']['output']>;
};

export type GQDataRegistryMigratorRunConnection = {
  __typename?: 'DataRegistryMigratorRunConnection';
  edges: Array<Maybe<GQDataRegistryMigratorRunEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDataRegistryMigratorRunEdge = {
  __typename?: 'DataRegistryMigratorRunEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDataRegistryMigratorRun>;
};

export type GQDataRegistryMigratorRunFilters = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type GQDataRegistryTable = {
  __typename?: 'DataRegistryTable';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isDraft: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  schemaId: Scalars['ID']['output'];
  schemaVersionId: Scalars['ID']['output'];
};

export type GQDataset = GQContextRelationInterface &
  GQIdInterface &
  GQPermissionObject & {
    __typename?: 'Dataset';
    adminUrl: Scalars['String']['output'];
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQFootprintQuestion
      | GQMeasurementProject
      | GQMeasurementVerificationItemQuestion
    >;
    canonicalDataset: Maybe<GQCanonicalDataset>;
    customExampleData: Maybe<GQTabularData>;
    customPotentialOverlaps: Maybe<Scalars['String']['output']>;
    customUsedFor: Maybe<Scalars['String']['output']>;
    datasources: Array<GQDatasource>;
    explainer: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    instructions: Maybe<GQInstructionsBundle>;
    materialCsrdStandards: Array<GQCsrdStandard>;
    name: Scalars['String']['output'];
    potentialOverlaps: Maybe<Scalars['String']['output']>;
    usedFor: Maybe<Scalars['String']['output']>;
  };

export const GQDatasetCadence = {
  Never: 'Never',
  Once: 'Once',
  Quarterly: 'Quarterly',
  Yearly: 'Yearly',
} as const;

export type GQDatasetCadence =
  (typeof GQDatasetCadence)[keyof typeof GQDatasetCadence];
export type GQDatasetConnection = {
  __typename?: 'DatasetConnection';
  edges: Array<Maybe<GQDatasetEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDatasetEdge = {
  __typename?: 'DatasetEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDataset>;
};

export type GQDatasetForOrgUnitType = {
  __typename?: 'DatasetForOrgUnitType';
  datasetId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type GQDatasetForOrgUnitTypeInput = {
  datasetId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isRequired: Scalars['Boolean']['input'];
};

export type GQDatasetIdsPerMeasurementProjectId = {
  datasetIds: Array<Scalars['ID']['input']>;
  measurementProjectId: Scalars['ID']['input'];
};

export type GQDatasetPayload = {
  __typename?: 'DatasetPayload';
  dataset: GQDataset;
  org: Maybe<GQOrganization>;
};

export const GQDatasetStage = {
  NeedsMapping: 'NeedsMapping',
  NeedsResponse: 'NeedsResponse',
  NeedsUpload: 'NeedsUpload',
  Processing: 'Processing',
  ReadyForFootprint: 'ReadyForFootprint',
} as const;

export type GQDatasetStage =
  (typeof GQDatasetStage)[keyof typeof GQDatasetStage];
export type GQDatasetSummary = {
  __typename?: 'DatasetSummary';
  signedUrl: Maybe<Scalars['String']['output']>;
};

export type GQDatasetSummaryFilter = {
  __typename?: 'DatasetSummaryFilter';
  field: Scalars['String']['output'];
  operator: GQFilterOperator;
  value: Array<Scalars['String']['output']>;
};

export type GQDatasetWithStage = GQIdInterface & {
  __typename?: 'DatasetWithStage';
  areAllTasksComplete: Scalars['Boolean']['output'];
  areUploadsComplete: Scalars['Boolean']['output'];
  dataset: Maybe<GQDataset>;
  hasUnsupportedBarts: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isNonEmissive: Scalars['Boolean']['output'];
  isUsableInFootprint: Scalars['Boolean']['output'];
  largeUserUploadedFiles: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Please check whether uploads or tasks are complete instead */
  stage: GQDatasetStage;
};

export type GQDatasetsByBat = {
  __typename?: 'DatasetsByBat';
  businessActivityType: Scalars['String']['output'];
  businessActivityTypeDisplayName: Scalars['String']['output'];
  datasets: Array<GQLiteDataset>;
  id: Scalars['ID']['output'];
};

export type GQDatasetsByBatWithMetadata = {
  __typename?: 'DatasetsByBatWithMetadata';
  ctsUserUploads: Array<GQCtsUserUpload>;
  datasetsByBat: Array<GQDatasetsByBat>;
  id: Scalars['ID']['output'];
};

export type GQDatasource = GQContextRelationInterface &
  GQIdInterface &
  GQPermissionObject & {
    __typename?: 'Datasource';
    activeIntegrationConnection: Maybe<GQIntegrationConnection>;
    adminUrl: Scalars['String']['output'];
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQFootprintQuestion
      | GQMeasurementProject
      | GQMeasurementVerificationItemQuestion
    >;
    canDelete: Scalars['Boolean']['output'];
    canonicalDatasource: Maybe<GQCanonicalDatasource>;
    customPotentialOverlaps: Maybe<Scalars['String']['output']>;
    customUsedFor: Maybe<Scalars['String']['output']>;
    customerTargetSchemaIds: Maybe<Array<Scalars['ID']['output']>>;
    dataset: GQDataset;
    firstUnansweredMeasurementVerificationQuestion: Maybe<GQMeasurementVerificationItemQuestion>;
    hasApiAccess: Scalars['Boolean']['output'];
    hasIntegration: Scalars['Boolean']['output'];
    hasIntegrationOption: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    importInstructionsMd: Maybe<Scalars['String']['output']>;
    instructions: Maybe<GQInstructionsBundle>;
    isApi: Maybe<Scalars['Boolean']['output']>;
    isUserConfirmed: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    notesFromWatershedMd: Maybe<Scalars['String']['output']>;
    permissions: Maybe<Array<GQPermissionItem>>;
    potentialOverlaps: Maybe<Scalars['String']['output']>;
    presetCustomTags: Array<GQPresetCustomTag>;
    /** @deprecated Use presetCustomTags instead */
    presetIngestionCustomFields: Array<GQIngestionCustomFieldDatasource>;
    presetOrgUnits: Array<GQPresetOrgUnit>;
    refreshCadence: GQDatasetCadence;
    sharedFiles: GQSharedFileConnection;
    /** @deprecated Use summaryWithMetadata instead, remove mar1 */
    summary: Array<GQDatasourceSummary>;
    summaryWithMetadata: GQDatasourceSummaryWithMetadata;
    usedFor: Maybe<Scalars['String']['output']>;
    /** @deprecated Use userCanManageDatasource instead */
    userCanAccessDetails: Maybe<Scalars['Boolean']['output']>;
    userCanApproveDatasource: Maybe<Scalars['Boolean']['output']>;
    userCanManageDatasource: Maybe<Scalars['Boolean']['output']>;
    userUploadTaskForMeasurementProject: GQUserUploadTask;
    userUploadTasks: GQUserUploadTaskConnection;
  };

export type GQDatasourceFirstUnansweredMeasurementVerificationQuestionArgs = {
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
};

export type GQDatasourceSharedFilesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  forUserId: InputMaybe<Scalars['ID']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQDatasourceUserUploadTaskForMeasurementProjectArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQDatasourceUserUploadTasksArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQDatasourceConnection = {
  __typename?: 'DatasourceConnection';
  edges: Array<GQDatasourceEdge>;
  pageInfo: GQPageInfo;
};

export type GQDatasourceEdge = {
  __typename?: 'DatasourceEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDatasource>;
};

export const GQDatasourceImportState = {
  Complete: 'Complete',
  ConfirmDetails: 'ConfirmDetails',
  DataCleaning: 'DataCleaning',
  HasDataIssues: 'HasDataIssues',
  InProgress: 'InProgress',
  InReview: 'InReview',
  NoTask: 'NoTask',
  NotStarted: 'NotStarted',
} as const;

export type GQDatasourceImportState =
  (typeof GQDatasourceImportState)[keyof typeof GQDatasourceImportState];
export type GQDatasourcePayload = {
  __typename?: 'DatasourcePayload';
  datasource: GQDatasource;
};

export type GQDatasourceSummary = {
  __typename?: 'DatasourceSummary';
  bartActivityType: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQDatasourceSummaryWithMetadata = {
  __typename?: 'DatasourceSummaryWithMetadata';
  id: Scalars['ID']['output'];
  legacyUploads: Array<GQUserUpload>;
  summaries: Array<GQDatasourceSummary>;
  unsupportedUploads: Array<GQUserUpload>;
};

export type GQDatasourceValueMappingRevisionData = {
  __typename?: 'DatasourceValueMappingRevisionData';
  id: Scalars['ID']['output'];
  revisionState: Maybe<GQReferenceDataRevisionState>;
};

export type GQDatasourceValueMappingRule = GQIdInterface & {
  __typename?: 'DatasourceValueMappingRule';
  applicableDatasetKinds: Array<GQCanonicalDatasetKind>;
  /** @deprecated As we start using reverse feature flags, this makes less sense. Instead, encode flagging behavior in getSelfIfPassingFlag */
  featureFlag: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  revisionData: GQDatasourceValueMappingRevisionData;
  /** @deprecated With SSP we won't fetch whole ValueMapping at once */
  valueMapping: Maybe<GQValueMapping>;
  valueMappingConfigId: Scalars['ID']['output'];
  valueMappingTask: GQValueMappingTask;
};

export type GQDateTimeFormatOptions = {
  __typename?: 'DateTimeFormatOptions';
  day: Maybe<Scalars['String']['output']>;
  month: Maybe<Scalars['String']['output']>;
  year: Maybe<Scalars['String']['output']>;
};

export type GQDateTimeInterval = {
  __typename?: 'DateTimeInterval';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
};

export type GQDeleteAllAnswersForReportPayload = {
  __typename?: 'DeleteAllAnswersForReportPayload';
  deleteCount: Scalars['Float']['output'];
};

export type GQDeleteAllMarketplaceAllocationInstructionsInput = {
  purchaseLineItemId: Scalars['ID']['input'];
};

export type GQDeleteAllMarketplaceAllocationInstructionsPayload = {
  __typename?: 'DeleteAllMarketplaceAllocationInstructionsPayload';
  allocationInstructions: Array<GQMarketplaceAllocationInstruction>;
};

export type GQDeleteAllReductionsDataInput = {
  orgIds: Array<Scalars['ID']['input']>;
};

export type GQDeleteAllReductionsDataPayload = {
  __typename?: 'DeleteAllReductionsDataPayload';
  message: Scalars['String']['output'];
};

export type GQDeleteAssetCommentInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteAssetCommentPayload = {
  __typename?: 'DeleteAssetCommentPayload';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  id: Scalars['ID']['output'];
};

export type GQDeleteAssetHoldingInput = {
  action: GQFinanceAuditAction;
  id: Scalars['ID']['input'];
};

export type GQDeleteAssetHoldingPayload = {
  __typename?: 'DeleteAssetHoldingPayload';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  id: Scalars['ID']['output'];
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQDeleteAssetHoldingsInput = {
  action: GQFinanceAuditAction;
  ids: Array<Scalars['ID']['input']>;
};

export type GQDeleteAssetHoldingsPayload = {
  __typename?: 'DeleteAssetHoldingsPayload';
  ids: Array<Scalars['ID']['output']>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQDeleteAssetYearsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQDeleteAssetYearsPayload = {
  __typename?: 'DeleteAssetYearsPayload';
  assetHoldingIds: Array<Scalars['ID']['output']>;
  assetYearIds: Array<Scalars['ID']['output']>;
  emissionsYearIds: Array<Scalars['ID']['output']>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
  privateDisclosureIds: Array<Scalars['ID']['output']>;
};

export type GQDeleteAssetsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQDeleteAssetsPayload = {
  __typename?: 'DeleteAssetsPayload';
  assetCorporateIds: Array<Scalars['ID']['output']>;
  assetGroupIds: Array<Scalars['ID']['output']>;
  assetHoldingIds: Array<Scalars['ID']['output']>;
  assetPersonalMotorVehicleInsuranceIds: Array<Scalars['ID']['output']>;
  assetRealEstateIds: Array<Scalars['ID']['output']>;
  assetSovereignBondIds: Array<Scalars['ID']['output']>;
  assetYearIds: Array<Scalars['ID']['output']>;
  emissionsYearIds: Array<Scalars['ID']['output']>;
  footprintEstimateOutputIds: Array<Scalars['ID']['output']>;
  fundHoldingBenchmarkIds: Array<Scalars['ID']['output']>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
  privateDisclosureIds: Array<Scalars['ID']['output']>;
};

export type GQDeleteBiCustomMetricInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteBiSavedViewInput = {
  /** @deprecated Use stableId instead. */
  id?: InputMaybe<Scalars['ID']['input']>;
  stableId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQDeleteBuildingInput = {
  buildingId: Scalars['ID']['input'];
  measurementProjectId: Scalars['ID']['input'];
};

export type GQDeleteBuildingUtilityInput = {
  id: Scalars['ID']['input'];
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQDeleteBuildingUtilityPayload = {
  __typename?: 'DeleteBuildingUtilityPayload';
  /** @deprecated Use buildingWithUtilities.completionIntervals instead */
  buildingCompletionIntervalsUntyped: GQBuildingCompletionIntervalsUntyped;
  buildingUntyped: GQBuildingUntyped;
  buildingWithUtilities: GQBuildingWithUtilities;
  updatedUserUploadTask: Maybe<GQUserUploadTask>;
};

export type GQDeleteBuildingsInput = {
  buildingIds: Array<Scalars['ID']['input']>;
  measurementProjectId: Scalars['ID']['input'];
};

export type GQDeleteBuildingsPayload = {
  __typename?: 'DeleteBuildingsPayload';
  measurementProject: GQMeasurementProject;
  organization: GQOrganization;
};

export type GQDeleteCalculationTagInput = {
  name: Scalars['String']['input'];
};

export type GQDeleteCalculationTagPayload = {
  __typename?: 'DeleteCalculationTagPayload';
  success: Scalars['Boolean']['output'];
};

export type GQDeleteCandidateRowInput = {
  userUploadCandidateId: Scalars['ID']['input'];
  userUploadCandidateRowId: Scalars['ID']['input'];
};

export type GQDeleteCanonicalClimateProgramProjectPayload = {
  __typename?: 'DeleteCanonicalClimateProgramProjectPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteCanonicalClimateProgramProjectRequirementDatasetInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteCanonicalClimateProgramProjectRequirementGhgpInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteCdpApiRegistrationInput = {
  orgId: Scalars['ID']['input'];
};

export type GQDeleteCdpApiRegistrationPayload = {
  __typename?: 'DeleteCdpApiRegistrationPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteCdpColumnMappingsInput = {
  idsToDelete: Array<Scalars['ID']['input']>;
};

export type GQDeleteCdpColumnMappingsPayload = {
  __typename?: 'DeleteCdpColumnMappingsPayload';
  mappings: GQCdpIdMappingsPayload;
};

export type GQDeleteCdpQuestionMappingsInput = {
  idsToDelete: Array<Scalars['ID']['input']>;
};

export type GQDeleteCdpQuestionMappingsPayload = {
  __typename?: 'DeleteCdpQuestionMappingsPayload';
  mappings: GQCdpIdMappingsPayload;
};

export type GQDeleteClimateProgramCalendarEventInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteClimateProgramCalendarEventPayload = {
  __typename?: 'DeleteClimateProgramCalendarEventPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteClimateProgramProjectInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteClimateProgramProjectPayload = {
  __typename?: 'DeleteClimateProgramProjectPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteClimateProgramTimelineProjectInput = {
  id: Scalars['ID']['input'];
  objectType: GQClimateProgramTimelineProjectObjectType;
};

export type GQDeleteClimateProgramTimelineProjectPayload = {
  __typename?: 'DeleteClimateProgramTimelineProjectPayload';
  id: Scalars['ID']['output'];
  objectType: GQClimateProgramTimelineProjectObjectType;
};

export type GQDeleteCommentInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  question: GQDataIssue | GQDiscussion | GQFootprintQuestion;
};

export type GQDeleteCompanyClimateCommitmentInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteCompanyClimateCommitmentPayload = {
  __typename?: 'DeleteCompanyClimateCommitmentPayload';
  disclosure: GQDisclosure;
};

export type GQDeleteCompanyEngagementCohortInput = {
  cohortId: Scalars['ID']['input'];
};

export type GQDeleteCompanyEngagementCohortPayload = {
  __typename?: 'DeleteCompanyEngagementCohortPayload';
  affectedCompanyIds: Array<Scalars['ID']['output']>;
};

export type GQDeleteCompanyRelationshipInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteCompanyRelationshipPayload = {
  __typename?: 'DeleteCompanyRelationshipPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteCompanyReportingFactsInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteCompanyReportingFactsPayload = {
  __typename?: 'DeleteCompanyReportingFactsPayload';
  companyReportingFacts: GQCompanyReportingFacts;
};

export type GQDeleteCompanySourceKeyPayload = {
  __typename?: 'DeleteCompanySourceKeyPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteCtsInput = {
  ctsId: Scalars['ID']['input'];
};

export type GQDeleteCtsPayload = {
  __typename?: 'DeleteCtsPayload';
  schema: GQCustomerTargetSchema;
};

export type GQDeleteCtsTransformTestCaseInput = {
  ctsTransformTestCaseId: Scalars['ID']['input'];
};

export type GQDeleteCtsTransformTestCasePayload = {
  __typename?: 'DeleteCtsTransformTestCasePayload';
  ctsTransform: GQCustomerTargetSchemaTransform;
};

export type GQDeleteDataIssueInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteDataIssuePayload = {
  __typename?: 'DeleteDataIssuePayload';
  measurementProject: GQMeasurementProject;
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQDeleteDataRegistryTablesInput = {
  batchMigratorRunId: Scalars['ID']['input'];
};

export type GQDeleteDataRegistryTablesPayload = {
  __typename?: 'DeleteDataRegistryTablesPayload';
  deletedCount: Scalars['Int']['output'];
};

export type GQDeleteDatasetInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteDatasetPayload = {
  __typename?: 'DeleteDatasetPayload';
  id: Scalars['ID']['output'];
  org: Maybe<GQOrganization>;
};

export type GQDeleteDatasourceAdminPayload = {
  __typename?: 'DeleteDatasourceAdminPayload';
  dataset: GQDataset;
  id: Scalars['ID']['output'];
};

export type GQDeleteDatasourceInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteDrilldownSavedViewInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteEacPriceEstimateInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteEacPriceEstimatePayload = {
  __typename?: 'DeleteEacPriceEstimatePayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteEmissionsModelReleasePayload = {
  __typename?: 'DeleteEmissionsModelReleasePayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteEmissionsYearInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteEmissionsYearPayload = {
  __typename?: 'DeleteEmissionsYearPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteEngagementTaskConfigForAdminPayload = {
  __typename?: 'DeleteEngagementTaskConfigForAdminPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteEngagementTaskConfigInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteEngagementTaskConfigPayload = {
  __typename?: 'DeleteEngagementTaskConfigPayload';
  message: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GQDeleteEngagementTasksAndAnswersByCrossOrgModelIdInput = {
  engagementTaskCrossOrgModelId: Scalars['ID']['input'];
};

export type GQDeleteEngagementTasksAndAnswersByCrossOrgModelIdPayload = {
  __typename?: 'DeleteEngagementTasksAndAnswersByCrossOrgModelIdPayload';
  engagementTasks: Array<GQEngagementTask>;
};

export type GQDeleteExternalReportQuestionInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteExternalReportQuestionPayload = {
  __typename?: 'DeleteExternalReportQuestionPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteExternalReportTypeWithRevisionInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteExternalReportTypeWithRevisionPayload = {
  __typename?: 'DeleteExternalReportTypeWithRevisionPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteFacilitiesSetupTaskInput = {
  taskId: Scalars['ID']['input'];
};

export type GQDeleteFacilitiesSetupTaskPayload = {
  __typename?: 'DeleteFacilitiesSetupTaskPayload';
  measurementProject: GQMeasurementProject;
};

export type GQDeleteFileInput = {
  fileId: Scalars['ID']['input'];
};

export type GQDeleteFilePayload = {
  __typename?: 'DeleteFilePayload';
  datasource: Maybe<GQDatasource>;
  file: Maybe<GQFileMetadata>;
  measurementProject: Maybe<GQMeasurementProject>;
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQDeleteFinanceSavedViewInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteFinanceSavedViewPayload = {
  __typename?: 'DeleteFinanceSavedViewPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteFinanceTagInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteFinanceTagPayload = {
  __typename?: 'DeleteFinanceTagPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteFinanceTagsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQDeleteFinanceTagsPayload = {
  __typename?: 'DeleteFinanceTagsPayload';
  ids: Array<Scalars['ID']['output']>;
};

export type GQDeleteFinancialsReviewItemInput = {
  id: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQDeleteFinancialsReviewItemPayload = {
  __typename?: 'DeleteFinancialsReviewItemPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteFootprintTagInput = {
  tagId: Scalars['ID']['input'];
};

export type GQDeleteFootprintTagPayload = {
  __typename?: 'DeleteFootprintTagPayload';
  footprintTag: GQFootprintTag;
};

export type GQDeleteFootprintTestSuiteConfigInput = {
  measurementTestSuiteId: Scalars['ID']['input'];
};

export type GQDeleteFootprintTestSuiteConfigPayload = {
  __typename?: 'DeleteFootprintTestSuiteConfigPayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQDeleteFootprintVersionPinInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteFootprintVersionPinPayload = {
  __typename?: 'DeleteFootprintVersionPinPayload';
  footprintVersion: GQFootprintVersion;
};

export type GQDeleteFundsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type GQDeleteFundsPayload = {
  __typename?: 'DeleteFundsPayload';
  ids: Array<Scalars['String']['output']>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQDeleteIngestionCustomFieldInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteIngestionCustomFieldPayload = {
  __typename?: 'DeleteIngestionCustomFieldPayload';
  ingestionCustomField: GQIngestionCustomField;
  organization: GQOrganization;
};

export type GQDeleteInitiativeInput = {
  initiativeId: Scalars['ID']['input'];
};

export type GQDeleteInitiativePayload = {
  __typename?: 'DeleteInitiativePayload';
  initiative: GQFootprintSliceInitiative | GQPrecomputedActivityBasedInitiative;
  plan: GQPlan;
};

export type GQDeleteMarketplaceAllocationInstructionInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplaceAllocationInstructionPayload = {
  __typename?: 'DeleteMarketplaceAllocationInstructionPayload';
  allocationInstruction: GQMarketplaceAllocationInstruction;
};

export type GQDeleteMarketplaceDeveloperInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplaceDeveloperPayload = {
  __typename?: 'DeleteMarketplaceDeveloperPayload';
  marketplaceDeveloper: GQMarketplaceDeveloper;
  marketplaceProjects: Array<GQMarketplaceProject>;
};

export type GQDeleteMarketplaceDocumentInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplaceDocumentPayload = {
  __typename?: 'DeleteMarketplaceDocumentPayload';
  document: GQMarketplaceDocument;
};

export type GQDeleteMarketplacePortfolioInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplacePortfolioItemInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplacePortfolioItemPayload = {
  __typename?: 'DeleteMarketplacePortfolioItemPayload';
  portfolio: GQMarketplacePortfolio;
};

export type GQDeleteMarketplacePortfolioPayload = {
  __typename?: 'DeleteMarketplacePortfolioPayload';
  success: Scalars['Boolean']['output'];
};

export type GQDeleteMarketplaceProjectInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplaceProjectPayload = {
  __typename?: 'DeleteMarketplaceProjectPayload';
  marketplaceProject: GQMarketplaceProject;
};

export type GQDeleteMarketplacePurchaseInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplacePurchaseLineItemInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplacePurchaseLineItemPayload = {
  __typename?: 'DeleteMarketplacePurchaseLineItemPayload';
  marketplacePurchase: GQMarketplacePurchase;
};

export type GQDeleteMarketplacePurchasePayload = {
  __typename?: 'DeleteMarketplacePurchasePayload';
  marketplacePurchase: GQMarketplacePurchase;
};

export type GQDeleteMeasurementContextItemInput = {
  measurementContextItemId: Scalars['ID']['input'];
};

export type GQDeleteMeasurementContextItemPayload = {
  __typename?: 'DeleteMeasurementContextItemPayload';
  measurementContextItem: GQMeasurementContextItem;
};

export type GQDeleteMeasurementProjectInput = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQDeleteMeasurementTestRowsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQDeleteMeasurementTestRowsPayload = {
  __typename?: 'DeleteMeasurementTestRowsPayload';
  ids: Array<Scalars['ID']['output']>;
};

export type GQDeleteMeasurementTestSuiteBartPayload = {
  __typename?: 'DeleteMeasurementTestSuiteBartPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteMeasurementTestSuitePayload = {
  __typename?: 'DeleteMeasurementTestSuitePayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteMeasurementVerificationItemInput = {
  verificationItemId: Scalars['ID']['input'];
};

export type GQDeleteMeasurementVerificationItemPayload = {
  __typename?: 'DeleteMeasurementVerificationItemPayload';
  measurementProject: GQMeasurementProject;
  measurementVerificationItem: GQMeasurementVerificationItem;
};

export type GQDeleteMeasurementVerificationItemQuestionInput = {
  questionId: Scalars['ID']['input'];
};

export type GQDeleteMeasurementVerificationItemQuestionPayload = {
  __typename?: 'DeleteMeasurementVerificationItemQuestionPayload';
  measurementVerificationItem: GQMeasurementVerificationItem;
  measurementVerificationItemQuestion: GQMeasurementVerificationItemQuestion;
};

export type GQDeleteMetricFromCustomReportInput = {
  questionId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type GQDeleteMetricFromCustomReportPayload = {
  __typename?: 'DeleteMetricFromCustomReportPayload';
  report: GQReport;
};

export type GQDeleteNonFootprintSupplierInput = {
  companyId: Scalars['ID']['input'];
};

export type GQDeleteNonFootprintSupplierPayload = {
  __typename?: 'DeleteNonFootprintSupplierPayload';
  success: Maybe<Scalars['Boolean']['output']>;
};

export type GQDeleteOneSchemaTemplateInput = {
  oneSchemaTemplateKey: Scalars['String']['input'];
};

export type GQDeleteOneSchemaTemplatePayload = {
  __typename?: 'DeleteOneSchemaTemplatePayload';
  oneSchemaTemplateKey: Scalars['String']['output'];
};

export type GQDeleteOrgCompanyInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteOrgCompanyPayload = {
  __typename?: 'DeleteOrgCompanyPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteOrgPointOfContactInput = {
  orgId: Scalars['ID']['input'];
  orgPointOfContactId: Scalars['ID']['input'];
};

export type GQDeleteOrgPointOfContactPayload = {
  __typename?: 'DeleteOrgPointOfContactPayload';
  org: GQOrganization;
};

export type GQDeleteOrgStructureVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteOrgStructureVersionPayload = {
  __typename?: 'DeleteOrgStructureVersionPayload';
  orgStructureVersion: GQOrgStructureVersion;
};

export type GQDeleteOrgUnitTypeInput = {
  id: Scalars['ID']['input'];
  versionId: Scalars['ID']['input'];
};

export type GQDeleteOrgUnitTypePayload = {
  __typename?: 'DeleteOrgUnitTypePayload';
  orgUnitType: GQOrgUnitType;
};

export type GQDeleteOrgUnitTypeRelationshipInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteOrgUnitTypeRelationshipPayload = {
  __typename?: 'DeleteOrgUnitTypeRelationshipPayload';
  orgUnitTypeRelationship: GQOrgUnitTypeRelationship;
};

export type GQDeleteOrgUnitsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQDeleteOrgUnitsPayload = {
  __typename?: 'DeleteOrgUnitsPayload';
  orgUnits: Array<GQOrgUnit>;
};

export type GQDeleteOrganizationInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteOrganizationPayload = {
  __typename?: 'DeleteOrganizationPayload';
  id: Scalars['ID']['output'];
};

export type GQDeletePeerCompanyGroupInput = {
  id: Scalars['ID']['input'];
};

export type GQDeletePeerCompanyGroupPayload = {
  __typename?: 'DeletePeerCompanyGroupPayload';
  id: Scalars['ID']['output'];
};

export type GQDeletePlanAdminPayload = {
  __typename?: 'DeletePlanAdminPayload';
  plans: Array<GQPlan>;
};

export type GQDeletePlanInput = {
  planId: Scalars['ID']['input'];
};

export type GQDeletePlanTargetInput = {
  id: Scalars['ID']['input'];
};

export type GQDeletePlanTargetPayload = {
  __typename?: 'DeletePlanTargetPayload';
  plan: GQPlan;
};

export type GQDeletePublicDisclosureInput = {
  id: Scalars['ID']['input'];
};

export type GQDeletePublicDisclosurePayload = {
  __typename?: 'DeletePublicDisclosurePayload';
  id: Scalars['ID']['output'];
};

export type GQDeletePublicDisclosureTargetInput = {
  id: Scalars['ID']['input'];
};

export type GQDeletePublicDisclosureTargetPayload = {
  __typename?: 'DeletePublicDisclosureTargetPayload';
  disclosureTarget: GQDisclosureTargetDetails;
};

export type GQDeleteReportAnswerInput = {
  questionId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type GQDeleteReportAnswerPayload = {
  __typename?: 'DeleteReportAnswerPayload';
  numDeleted: Scalars['Int']['output'];
  question: GQReportQuestion;
};

export type GQDeleteReportAnswersPayload = {
  __typename?: 'DeleteReportAnswersPayload';
  deleteCount: Scalars['Float']['output'];
};

export type GQDeleteReportAttachmentInput = {
  itemId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
  reportQuestionId: Scalars['ID']['input'];
};

export type GQDeleteReportAttachmentItemInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteReportAttachmentItemPayload = {
  __typename?: 'DeleteReportAttachmentItemPayload';
  success: Scalars['Boolean']['output'];
};

export type GQDeleteReportAttachmentPayload = {
  __typename?: 'DeleteReportAttachmentPayload';
  success: Scalars['Boolean']['output'];
};

export type GQDeleteReportConfigInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteReportConfigPayload = {
  __typename?: 'DeleteReportConfigPayload';
  reportConfig: GQReportConfig;
};

export type GQDeleteReportQuestionContainerInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteReportQuestionContainerPayload = {
  __typename?: 'DeleteReportQuestionContainerPayload';
  container: GQReportQuestionContainer;
  parentId: Scalars['ID']['output'];
};

export type GQDeleteReportQuestionInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteReportQuestionNoteItemInput = {
  reportQuestionNoteItemId: Scalars['String']['input'];
};

export type GQDeleteReportQuestionNoteItemPayload = {
  __typename?: 'DeleteReportQuestionNoteItemPayload';
  discussion: GQDiscussion;
  reportQuestion: GQReportQuestion;
};

export type GQDeleteReportQuestionPayload = {
  __typename?: 'DeleteReportQuestionPayload';
  parentId: Scalars['ID']['output'];
  question: GQReportQuestion;
};

export type GQDeleteRoleInput = {
  roleId: Scalars['ID']['input'];
};

export type GQDeleteRolePayload = {
  __typename?: 'DeleteRolePayload';
  orgId: Scalars['ID']['output'];
  roleId: Scalars['ID']['output'];
};

export type GQDeleteSbtiTargetExclusionInput = {
  sbtiTargetExclusionId: Scalars['ID']['input'];
};

export type GQDeleteSbtiTargetExclusionPayload = {
  __typename?: 'DeleteSbtiTargetExclusionPayload';
  org: GQOrganization;
};

export type GQDeleteSnapshotInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteSnapshotPayload = {
  __typename?: 'DeleteSnapshotPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteSupplierAttachmentInput = {
  companyId: Scalars['ID']['input'];
  fileMetadataId: Scalars['ID']['input'];
};

export type GQDeleteSupplierAttachmentPayload = {
  __typename?: 'DeleteSupplierAttachmentPayload';
  numberDeleted: Scalars['Int']['output'];
};

export type GQDeleteSupplierChartInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteSupplierChartInputAdmin = {
  chartId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQDeleteSupplierChartPayload = {
  __typename?: 'DeleteSupplierChartPayload';
  views: Array<GQSupplierView>;
};

export type GQDeleteSupplierChartPayloadAdmin = {
  __typename?: 'DeleteSupplierChartPayloadAdmin';
  views: Array<GQSupplierView>;
};

export type GQDeleteSupplierContactInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type GQDeleteSupplierContactPayload = {
  __typename?: 'DeleteSupplierContactPayload';
  company: GQCompany;
  id: Scalars['ID']['output'];
  numberDeleted: Scalars['Int']['output'];
};

export type GQDeleteSupplierSavedViewInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteSupplierSavedViewPayload = {
  __typename?: 'DeleteSupplierSavedViewPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteSupplierScoreCriteriaInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteSupplierScoreCriteriaPayload = {
  __typename?: 'DeleteSupplierScoreCriteriaPayload';
  supplierScorecard: GQSupplierScorecard;
};

export type GQDeleteSupplierTableColumnInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteSupplierTableColumnPayload = {
  __typename?: 'DeleteSupplierTableColumnPayload';
  views: Array<GQSupplierView>;
};

export type GQDeleteSupportingDocumentInput = {
  supportingDocumentId: Scalars['ID']['input'];
};

export type GQDeleteSupportingDocumentPayload = {
  __typename?: 'DeleteSupportingDocumentPayload';
  buildingUntyped: Maybe<GQBuildingUntyped>;
  supportingDocument: GQSupportingDocument;
};

export type GQDeleteTargetInput = {
  targetId: Scalars['ID']['input'];
};

export type GQDeleteTargetPayload = {
  __typename?: 'DeleteTargetPayload';
  org: GQOrganization;
  target: GQTarget;
};

export type GQDeleteTcfdArchetypeRiskInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteUserUploadCandidateAndRowsInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteUserUploadCandidateAndRowsPayload = {
  __typename?: 'DeleteUserUploadCandidateAndRowsPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteUserUploadTaskInput = {
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQDeleteUserUploadTaskPayload = {
  __typename?: 'DeleteUserUploadTaskPayload';
  measurementProject: GQMeasurementProject;
};

export type GQDeleteValueMappingCommentInput = {
  deletedBy: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type GQDeleteWatershedFootprintReviewRequestInput = {
  message: Scalars['String']['input'];
  watershedFootprintReviewRequestId: Scalars['ID']['input'];
};

export type GQDeleteWatershedFootprintReviewRequestPayload = {
  __typename?: 'DeleteWatershedFootprintReviewRequestPayload';
  discussion: GQDiscussion;
  footprintVersion: GQFootprintVersion;
};

export type GQDemoPlatformRun = {
  __typename?: 'DemoPlatformRun';
  config: Scalars['JSONString']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  statusJson: Maybe<Scalars['JSONString']['output']>;
  user: GQUser;
};

export type GQDemoPlatformRunConnection = {
  __typename?: 'DemoPlatformRunConnection';
  edges: Array<Maybe<GQDemoPlatformRunEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDemoPlatformRunEdge = {
  __typename?: 'DemoPlatformRunEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDemoPlatformRun>;
};

export type GQDependentQuestionFilter = {
  excludedComputed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQDependentReportItems = {
  __typename?: 'DependentReportItems';
  containerProgresses: Array<GQReportQuestionContainer>;
  containerStatuses: Array<GQReportQuestionContainer>;
  inputs: Array<GQReportQuestion>;
  questionStatuses: Array<GQReportQuestion>;
};

export type GQDetatchOrgCompaniesFromCompanyInput = {
  orgCompanyIds: Array<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
};

export type GQDetatchOrgCompaniesFromCompanyPayload = {
  __typename?: 'DetatchOrgCompaniesFromCompanyPayload';
  organization: GQOrganization;
};

export const GQDimensionComputedType = {
  NumeratorIntersection: 'NumeratorIntersection',
} as const;

export type GQDimensionComputedType =
  (typeof GQDimensionComputedType)[keyof typeof GQDimensionComputedType];
export type GQDiscloseEmissionsExpenseCategories = {
  __typename?: 'DiscloseEmissionsExpenseCategories';
  beaCode: Scalars['String']['output'];
  inputQuantity: Scalars['Float']['output'];
  inputUnit: Scalars['String']['output'];
  kgco2e: Scalars['Float']['output'];
};

export type GQDiscloseEmissionsExpenseCategoriesInput = {
  beaCode: Scalars['String']['input'];
  inputQuantity: Scalars['Float']['input'];
  inputUnit: Scalars['String']['input'];
  kgco2e: Scalars['Float']['input'];
};

export type GQDisclosure = {
  __typename?: 'Disclosure';
  climateCommitments: Maybe<
    Array<
      | GQCarbonNeutralCommitment
      | GQCleanEnergyCommitment
      | GQNetZeroCommitment
      | GQScienceBasedTargetCommitment
    >
  >;
  company: Maybe<GQCompany>;
  historicalEmissionsYears: Maybe<Array<GQEmissionsYear>>;
  id: Scalars['ID']['output'];
  initiatives: Maybe<Array<GQDisclosureInitiative>>;
  privateDisclosure: Maybe<GQPrivateDisclosure>;
  publicDisclosure: Maybe<GQPublicDisclosure>;
  qualityScore: Maybe<GQDisclosureQualityScore>;
  targets: Maybe<Array<GQDisclosureTargetDetails>>;
};

export const GQDisclosureCenterMadLibAnnualTurnover = {
  Between36And500Million: 'Between36And500Million',
  LessThan36Million: 'LessThan36Million',
  MoreThan500Million: 'MoreThan500Million',
} as const;

export type GQDisclosureCenterMadLibAnnualTurnover =
  (typeof GQDisclosureCenterMadLibAnnualTurnover)[keyof typeof GQDisclosureCenterMadLibAnnualTurnover];
export const GQDisclosureCenterMadLibCompanyBasedIn = {
  Eu: 'EU',
  Uk: 'UK',
  Us: 'US',
} as const;

export type GQDisclosureCenterMadLibCompanyBasedIn =
  (typeof GQDisclosureCenterMadLibCompanyBasedIn)[keyof typeof GQDisclosureCenterMadLibCompanyBasedIn];
export const GQDisclosureCenterMadLibEmployeeCount = {
  LessThan250: 'LessThan250',
  MoreThan250LessThan499: 'MoreThan250LessThan499',
  MoreThan500: 'MoreThan500',
} as const;

export type GQDisclosureCenterMadLibEmployeeCount =
  (typeof GQDisclosureCenterMadLibEmployeeCount)[keyof typeof GQDisclosureCenterMadLibEmployeeCount];
export type GQDisclosureCenterMadLibInput = {
  annualTurnover: GQDisclosureCenterMadLibAnnualTurnover;
  companyBasedIn: GQDisclosureCenterMadLibCompanyBasedIn;
  employeeCount: GQDisclosureCenterMadLibEmployeeCount;
  isBankingInsurance: Scalars['Boolean']['input'];
  isMoreThan18MillionBalanceSheet: Scalars['Boolean']['input'];
  isPubliclyListed: Scalars['Boolean']['input'];
};

export type GQDisclosureClimateCommitmentFilters = {
  commitmentKind?: InputMaybe<GQCompanyClimateCommitmentKind>;
};

export type GQDisclosureEmissionsFactor = {
  __typename?: 'DisclosureEmissionsFactor';
  emissionsFactorRow: GQReferenceDataRevisionRow;
  privateDisclosureId: Maybe<Scalars['ID']['output']>;
  publicDisclosureId: Maybe<Scalars['ID']['output']>;
};

export type GQDisclosureHistoricalYearFilters = {
  allScopes?: InputMaybe<Scalars['Boolean']['input']>;
  includeExpenseCategoryEmissions?: InputMaybe<Scalars['Boolean']['input']>;
  onlyMarketBased?: InputMaybe<Scalars['Boolean']['input']>;
  revenueMustExist?: InputMaybe<Scalars['Boolean']['input']>;
  units?: InputMaybe<GQCompanyEmissionsUnits>;
  withinOrderOfMagnitudeOfNaicsEf?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQDisclosureIdWithReportingYear = {
  disclosureId: Scalars['String']['input'];
  reportingYear: Scalars['Int']['input'];
};

export type GQDisclosureInitiative = GQIdInterface & {
  __typename?: 'DisclosureInitiative';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  emissionsTimeseries: Maybe<GQSimpleTimeseries>;
  emissionsTimeseriesId: Maybe<Scalars['ID']['output']>;
  endDate: Maybe<Scalars['Int']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  filters: Maybe<GQFilterExpressionGroup>;
  id: Scalars['ID']['output'];
  initiativeType: GQDisclosureInitiativeType;
  intensityType: Maybe<GQDisclosureTargetIntensityType>;
  name: Maybe<Scalars['String']['output']>;
  orgId: Maybe<Scalars['ID']['output']>;
  privateDisclosureId: Maybe<Scalars['ID']['output']>;
  publicDisclosureId: Maybe<Scalars['ID']['output']>;
  startDate: Maybe<Scalars['Int']['output']>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQDisclosureInitiativeFilters = {
  placeholder?: InputMaybe<Scalars['Boolean']['input']>;
};

export const GQDisclosureInitiativeType = {
  AlternativeFuels: 'AlternativeFuels',
  BusinessTravelEfficiency: 'BusinessTravelEfficiency',
  CarbonRemoval: 'CarbonRemoval',
  Circularity: 'Circularity',
  ConductInventory: 'ConductInventory',
  DeforestationPrevention: 'DeforestationPrevention',
  EnergyEfficiency: 'EnergyEfficiency',
  EngageSuppliers: 'EngageSuppliers',
  HeavyTransportEfficiency: 'HeavyTransportEfficiency',
  ManureManagement: 'ManureManagement',
  MeasureFootprint: 'MeasureFootprint',
  Other: 'Other',
  RegenerativeAgriculture: 'RegenerativeAgriculture',
  RenewableEnergy: 'RenewableEnergy',
} as const;

export type GQDisclosureInitiativeType =
  (typeof GQDisclosureInitiativeType)[keyof typeof GQDisclosureInitiativeType];
export type GQDisclosureInput = {
  climateCommitmentFilters?: InputMaybe<GQDisclosureClimateCommitmentFilters>;
  historicalYearFilters?: InputMaybe<GQDisclosureHistoricalYearFilters>;
  initiativeFilters?: InputMaybe<GQDisclosureInitiativeFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  privateDisclosureFilters?: InputMaybe<GQPrivateDisclosureInput>;
  publicDisclosureFilters?: InputMaybe<GQPublicDisclosureInput>;
  targetFilters?: InputMaybe<GQDisclosureTargetFilters>;
};

export const GQDisclosureQualityScore = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
  Unusable: 'Unusable',
  VeryHigh: 'VeryHigh',
} as const;

export type GQDisclosureQualityScore =
  (typeof GQDisclosureQualityScore)[keyof typeof GQDisclosureQualityScore];
export type GQDisclosureTargetDetails = GQIdInterface & {
  __typename?: 'DisclosureTargetDetails';
  baseYear: Scalars['YearMonth']['output'];
  companyClimateCommitmentId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  emissionsTarget: GQSimpleTimeseries;
  emissionsTargetId: Scalars['ID']['output'];
  emissionsType: GQDisclosureTargetEmissionsType;
  filters: GQFilterExpressionGroup;
  id: Scalars['ID']['output'];
  intensityType: GQDisclosureTargetIntensityType;
  name: Scalars['String']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  privateDisclosureId: Maybe<Scalars['ID']['output']>;
  publicDisclosureId: Maybe<Scalars['ID']['output']>;
  reductionRate: GQDisclosureTargetReductionRate;
  unit: Maybe<Scalars['String']['output']>;
  unitDescription: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export const GQDisclosureTargetEmissionsType = {
  GrossEmissions: 'GrossEmissions',
  NetEmissions: 'NetEmissions',
} as const;

export type GQDisclosureTargetEmissionsType =
  (typeof GQDisclosureTargetEmissionsType)[keyof typeof GQDisclosureTargetEmissionsType];
export type GQDisclosureTargetFilters = {
  placeholder?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQDisclosureTargetInput = {
  baseYear: Scalars['YearMonth']['input'];
  description: Scalars['String']['input'];
  emissionsType: GQDisclosureTargetEmissionsType;
  filters: GQFilterExpressionGroupInput;
  intensityType: GQDisclosureTargetIntensityType;
  name: Scalars['String']['input'];
  reductionRate: GQDisclosureTargetReductionRate;
  reductionTarget: Scalars['Float']['input'];
  targetYear: Scalars['YearMonth']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
  unitDescription?: InputMaybe<Scalars['String']['input']>;
};

export const GQDisclosureTargetIntensityType = {
  Absolute: 'Absolute',
  GrossProfit: 'GrossProfit',
  Headcount: 'Headcount',
  Revenue: 'Revenue',
  SupplierEngagement: 'SupplierEngagement',
  Unit: 'Unit',
} as const;

export type GQDisclosureTargetIntensityType =
  (typeof GQDisclosureTargetIntensityType)[keyof typeof GQDisclosureTargetIntensityType];
export const GQDisclosureTargetReductionRate = {
  LinearAnnualReduction: 'LinearAnnualReduction',
  YearOverYear: 'YearOverYear',
} as const;

export type GQDisclosureTargetReductionRate =
  (typeof GQDisclosureTargetReductionRate)[keyof typeof GQDisclosureTargetReductionRate];
export const GQDisclosureType = {
  Cdp: 'CDP',
  Estimate: 'Estimate',
  PublicDisclosure: 'PublicDisclosure',
  Survey: 'Survey',
  SurveyEstimate: 'SurveyEstimate',
  WatershedMeasurement: 'WatershedMeasurement',
} as const;

export type GQDisclosureType =
  (typeof GQDisclosureType)[keyof typeof GQDisclosureType];
export type GQDisconnectIntegrationConnectionInput = {
  integrationConnectionId: Scalars['ID']['input'];
};

export type GQDisconnectIntegrationConnectionPayload = {
  __typename?: 'DisconnectIntegrationConnectionPayload';
  integrationConnection: GQIntegrationConnection;
  integrationPartner: GQIntegrationPartner;
  revoked: Scalars['Boolean']['output'];
};

export type GQDiscussion = GQIdInterface &
  GQQuestion & {
    __typename?: 'Discussion';
    anchor: GQDiscussionAnchorPayload;
    comments: GQCommentConnection;
    createdAt: Scalars['DateTime']['output'];
    description: Maybe<Scalars['String']['output']>;
    files: GQFileMetadataConnection;
    id: Scalars['ID']['output'];
    isCustomerInitiated: Scalars['Boolean']['output'];
    state: GQDataIssueState;
    title: Scalars['String']['output'];
  };

export type GQDiscussionCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQDiscussionAdditionalProperties = {
  footprintVersionId?: InputMaybe<Scalars['String']['input']>;
};

export type GQDiscussionAnchor = {
  buildingId?: InputMaybe<Scalars['String']['input']>;
  dataApprovalEventId?: InputMaybe<Scalars['String']['input']>;
  financialsReviewItemId?: InputMaybe<Scalars['String']['input']>;
  measurementVerificationItemQuestionId?: InputMaybe<
    Scalars['String']['input']
  >;
  productSection?: InputMaybe<GQDiscussionProductSection>;
  reportQuestionInstance?: InputMaybe<GQReportQuestionInstance>;
  userUploadTaskId?: InputMaybe<Scalars['String']['input']>;
  valueMappingTaskId?: InputMaybe<Scalars['String']['input']>;
};

export type GQDiscussionAnchorPayload = {
  __typename?: 'DiscussionAnchorPayload';
  financialsReviewItemId: Maybe<Scalars['String']['output']>;
  /** @deprecated Remove Feb 21st, 2024 */
  measurementProjectId: Maybe<Scalars['String']['output']>;
  measurementVerificationItemQuestionId: Maybe<Scalars['String']['output']>;
  productSection: Maybe<GQDiscussionProductSection>;
  reportQuestionInstance: Maybe<GQReportQuestionInstancePayload>;
  userUploadTaskId: Maybe<Scalars['String']['output']>;
  valueMappingTaskId: Maybe<Scalars['String']['output']>;
};

export const GQDiscussionProductSection = {
  EngagementTaskPortal: 'EngagementTaskPortal',
  Exclusions: 'Exclusions',
  Facilities: 'Facilities',
  Finance: 'Finance',
  FinanceFootprintAssembly: 'FinanceFootprintAssembly',
  FootprintQuestion: 'FootprintQuestion',
  Global: 'Global',
  Recategorization: 'Recategorization',
  SupplyChain: 'SupplyChain',
} as const;

export type GQDiscussionProductSection =
  (typeof GQDiscussionProductSection)[keyof typeof GQDiscussionProductSection];
export const GQDiscussionType = {
  Comment: 'Comment',
  Support: 'Support',
} as const;

export type GQDiscussionType =
  (typeof GQDiscussionType)[keyof typeof GQDiscussionType];
export type GQDiscussionsPayload = {
  __typename?: 'DiscussionsPayload';
  discussions: Array<GQDiscussion>;
  id: Scalars['ID']['output'];
};

export type GQDismissFootprintExclusionSuggestionInput = {
  id: Scalars['ID']['input'];
};

export type GQDismissFootprintExclusionSuggestionPayload = {
  __typename?: 'DismissFootprintExclusionSuggestionPayload';
  id: Scalars['ID']['output'];
};

export const GQDmaIngestionMethod = {
  DeloitteApi: 'DeloitteApi',
  KpmgApi: 'KpmgApi',
  ManualUpload: 'ManualUpload',
} as const;

export type GQDmaIngestionMethod =
  (typeof GQDmaIngestionMethod)[keyof typeof GQDmaIngestionMethod];
export type GQDocumentCandidate = {
  __typename?: 'DocumentCandidate';
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  pdfUrl: Scalars['String']['output'];
};

export type GQDocumentCandidatesPayload = {
  __typename?: 'DocumentCandidatesPayload';
  documentEntries: Array<GQDocumentCandidate>;
};

export type GQDocumentParseAttempt = GQIdInterface & {
  __typename?: 'DocumentParseAttempt';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  modelType: GQDocumentParseAttemptModelType;
  updatedAt: Scalars['DateTime']['output'];
  userUploadId: Scalars['ID']['output'];
  utilityUsageData: Maybe<GQUtilityUsageData>;
};

export const GQDocumentParseAttemptModelType = {
  HumanReview: 'HumanReview',
  InvoiceParser: 'InvoiceParser',
  UtilityParser: 'UtilityParser',
} as const;

export type GQDocumentParseAttemptModelType =
  (typeof GQDocumentParseAttemptModelType)[keyof typeof GQDocumentParseAttemptModelType];
export const GQDrilldownAggregateKind = {
  Electricity: 'Electricity',
  Emissions: 'Emissions',
  Intensity: 'Intensity',
  LocationBasedEmissions: 'LocationBasedEmissions',
  PercentageEmissions: 'PercentageEmissions',
  PercentageOfCurrentView: 'PercentageOfCurrentView',
} as const;

export type GQDrilldownAggregateKind =
  (typeof GQDrilldownAggregateKind)[keyof typeof GQDrilldownAggregateKind];
export type GQDrilldownForReportQuestionInput = {
  questionId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type GQDrilldownForReportQuestionPayload = {
  __typename?: 'DrilldownForReportQuestionPayload';
  cardControlsJson: Maybe<Scalars['JSONString']['output']>;
  mergedControlsJson: Scalars['JSONString']['output'];
  question: GQReportQuestion;
  report: GQReport;
  reportControlsJson: Scalars['JSONString']['output'];
  savedViewControlsJson: Scalars['JSONString']['output'];
};

export const GQDrilldownInclusionKind = {
  Gross: 'Gross',
  Net: 'Net',
  Removal: 'Removal',
} as const;

export type GQDrilldownInclusionKind =
  (typeof GQDrilldownInclusionKind)[keyof typeof GQDrilldownInclusionKind];
export type GQDrilldownParameters = {
  aggregateKind: GQDrilldownAggregateKind;
  defaultTimeInterval: Maybe<Scalars['YMInterval']['output']>;
  filterExpressions: Maybe<GQFilterExpressionGroupWithNewFilters>;
  footprintKind: Scalars['String']['output'];
  groupBy: Maybe<Array<Scalars['String']['output']>>;
  inclusionKind: GQDrilldownInclusionKind;
  intensityDenominatorName: Maybe<Scalars['String']['output']>;
  timeSeriesKind: GQDrilldownTimeSeriesKind;
};

export type GQDrilldownSavedView = GQDrilldownParameters &
  GQIdInterface & {
    __typename?: 'DrilldownSavedView';
    aggregateKind: GQDrilldownAggregateKind;
    biSavedViewId: Maybe<Scalars['ID']['output']>;
    defaultTimeInterval: Maybe<Scalars['YMInterval']['output']>;
    description: Maybe<Scalars['String']['output']>;
    filterExpressions: Maybe<GQFilterExpressionGroupWithNewFilters>;
    footprintKind: Scalars['String']['output'];
    groupBy: Maybe<Array<Scalars['String']['output']>>;
    hasDataTable: Scalars['Boolean']['output'];
    hasPieChart: Scalars['Boolean']['output'];
    hasTimeSeriesChart: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    inclusionKind: GQDrilldownInclusionKind;
    intensityDenominatorName: Maybe<Scalars['String']['output']>;
    isPrivate: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    searchTerm: Maybe<Scalars['String']['output']>;
    timeSeriesKind: GQDrilldownTimeSeriesKind;
  };

export type GQDrilldownSavedViewInput = {
  aggregateKind: GQDrilldownAggregateKind;
  defaultTimeInterval?: InputMaybe<Scalars['YMInterval']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filterExpressions?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
  footprintKind: Scalars['String']['input'];
  groupBy?: InputMaybe<Array<Scalars['String']['input']>>;
  hasDataTable: Scalars['Boolean']['input'];
  hasPieChart: Scalars['Boolean']['input'];
  hasTimeSeriesChart: Scalars['Boolean']['input'];
  inclusionKind: GQDrilldownInclusionKind;
  intensityDenominatorName?: InputMaybe<Scalars['String']['input']>;
  isPrivate: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  timeSeriesKind: GQDrilldownTimeSeriesKind;
};

export const GQDrilldownTimeSeriesKind = {
  Monthly: 'Monthly',
  Summary: 'Summary',
  Yearly: 'Yearly',
} as const;

export type GQDrilldownTimeSeriesKind =
  (typeof GQDrilldownTimeSeriesKind)[keyof typeof GQDrilldownTimeSeriesKind];
export const GQDuckAccessory = {
  CowboyHat: 'CowboyHat',
  LuckyHat: 'LuckyHat',
  None: 'None',
  PumpkinHat: 'PumpkinHat',
  WitchHat: 'WitchHat',
} as const;

export type GQDuckAccessory =
  (typeof GQDuckAccessory)[keyof typeof GQDuckAccessory];
export const GQDuckDbInstanceKind = {
  Compute: 'Compute',
  External: 'External',
  ExtraLarge: 'ExtraLarge',
  Internal: 'Internal',
  Local: 'Local',
  Postgres: 'Postgres',
  Spatial: 'Spatial',
  Test: 'Test',
} as const;

export type GQDuckDbInstanceKind =
  (typeof GQDuckDbInstanceKind)[keyof typeof GQDuckDbInstanceKind];
export type GQDuckHuntLeaderboardEntry = {
  __typename?: 'DuckHuntLeaderboardEntry';
  hunter: GQWatershedEmployee;
  lastFoundAt: Scalars['DateTime']['output'];
  numDucks: Scalars['Int']['output'];
};

export const GQDuckType = {
  MallardF: 'MallardF',
  MallardM: 'MallardM',
  White: 'White',
  Yellow: 'Yellow',
} as const;

export type GQDuckType = (typeof GQDuckType)[keyof typeof GQDuckType];
export type GQDuplicateCtsInput = {
  customerTargetSchemaId: Scalars['ID']['input'];
};

export type GQDuplicateCtsPayload = {
  __typename?: 'DuplicateCtsPayload';
  schema: GQCustomerTargetSchema;
};

export type GQDuplicateInitiativeInput = {
  duplicatedInitiativeId: Scalars['ID']['input'];
  kind: GQInitiativeKind;
  planId: Scalars['ID']['input'];
};

export type GQDuplicateInitiativePayload = {
  __typename?: 'DuplicateInitiativePayload';
  initiative: GQFootprintSliceInitiative | GQPrecomputedActivityBasedInitiative;
  plan: GQPlan;
};

export type GQDuplicateMarketplacePortfolioInput = {
  id: Scalars['ID']['input'];
};

export type GQDuplicateMarketplacePortfolioPayload = {
  __typename?: 'DuplicateMarketplacePortfolioPayload';
  portfolio: GQMarketplacePortfolio;
};

export type GQDuplicateMeasurementTestSuiteInput = {
  id: Scalars['ID']['input'];
  includeExpectations: Scalars['Boolean']['input'];
};

export type GQDuplicateMeasurementTestSuitePayload = {
  __typename?: 'DuplicateMeasurementTestSuitePayload';
  id: Scalars['ID']['output'];
};

export type GQDuplicateOrgStructureVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQDuplicateOrgStructureVersionPayload = {
  __typename?: 'DuplicateOrgStructureVersionPayload';
  orgStructureVersion: GQOrgStructureVersion;
};

export type GQDuplicatePlanInput = {
  name: Scalars['String']['input'];
  planId: Scalars['ID']['input'];
};

export type GQDuplicatePlanPayload = {
  __typename?: 'DuplicatePlanPayload';
  plan: GQPlan;
};

export const GQEmReleaseLifecyclePhase = {
  Alpha: 'Alpha',
  Beta: 'Beta',
  Current: 'Current',
  EndOfLife: 'EndOfLife',
  Lts: 'LTS',
  Maintenance: 'Maintenance',
} as const;

export type GQEmReleaseLifecyclePhase =
  (typeof GQEmReleaseLifecyclePhase)[keyof typeof GQEmReleaseLifecyclePhase];
export type GQEacPriceEstimate = GQIdInterface & {
  __typename?: 'EacPriceEstimate';
  alternativeCountries: Array<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  defaultProject: Maybe<GQMarketplaceProject>;
  id: Scalars['ID']['output'];
  minCreditPurchase: Scalars['Int']['output'];
  priceEstimateUsdCents: Scalars['Int']['output'];
  standard: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const GQEdciCompanyGrowthStageOption = {
  Buyout: 'Buyout',
  Growth: 'Growth',
  Venture: 'Venture',
} as const;

export type GQEdciCompanyGrowthStageOption =
  (typeof GQEdciCompanyGrowthStageOption)[keyof typeof GQEdciCompanyGrowthStageOption];
export const GQEdciCompanyStructureOption = {
  Private: 'Private',
  Public: 'Public',
} as const;

export type GQEdciCompanyStructureOption =
  (typeof GQEdciCompanyStructureOption)[keyof typeof GQEdciCompanyStructureOption];
export const GQEdciScope1MethodologyOption = {
  ActivityBased: 'ActivityBased',
  DirectMonitoring: 'DirectMonitoring',
  Other: 'Other',
  SpendBased: 'SpendBased',
} as const;

export type GQEdciScope1MethodologyOption =
  (typeof GQEdciScope1MethodologyOption)[keyof typeof GQEdciScope1MethodologyOption];
export const GQEdciScope2MethodologyOption = {
  LocationBased: 'LocationBased',
  MarketBased: 'MarketBased',
  Other: 'Other',
} as const;

export type GQEdciScope2MethodologyOption =
  (typeof GQEdciScope2MethodologyOption)[keyof typeof GQEdciScope2MethodologyOption];
export const GQEditColumn = {
  DueDate: 'DueDate',
  Status: 'Status',
} as const;

export type GQEditColumn = (typeof GQEditColumn)[keyof typeof GQEditColumn];
export type GQEditEmissionsYearInput = {
  id: Scalars['ID']['input'];
  numEmployees?: InputMaybe<Scalars['Float']['input']>;
  reportingYear: Scalars['Int']['input'];
  revenue?: InputMaybe<Scalars['Float']['input']>;
  revenueCurrency?: InputMaybe<Scalars['String']['input']>;
  scope1?: InputMaybe<Scalars['Float']['input']>;
  scope2Location?: InputMaybe<Scalars['Float']['input']>;
  scope2Market?: InputMaybe<Scalars['Float']['input']>;
  scope3?: InputMaybe<Scalars['Float']['input']>;
  scope301?: InputMaybe<Scalars['Float']['input']>;
  scope302?: InputMaybe<Scalars['Float']['input']>;
  scope303?: InputMaybe<Scalars['Float']['input']>;
  scope304?: InputMaybe<Scalars['Float']['input']>;
  scope305?: InputMaybe<Scalars['Float']['input']>;
  scope306?: InputMaybe<Scalars['Float']['input']>;
  scope307?: InputMaybe<Scalars['Float']['input']>;
  scope308?: InputMaybe<Scalars['Float']['input']>;
  scope309?: InputMaybe<Scalars['Float']['input']>;
  scope310?: InputMaybe<Scalars['Float']['input']>;
  scope311?: InputMaybe<Scalars['Float']['input']>;
  scope312?: InputMaybe<Scalars['Float']['input']>;
  scope313?: InputMaybe<Scalars['Float']['input']>;
  scope314?: InputMaybe<Scalars['Float']['input']>;
  scope315?: InputMaybe<Scalars['Float']['input']>;
  scope316?: InputMaybe<Scalars['Float']['input']>;
  scope317?: InputMaybe<Scalars['Float']['input']>;
  units: GQCompanyEmissionsUnits;
};

export type GQEditEmissionsYearPayload = {
  __typename?: 'EditEmissionsYearPayload';
  emissionsYear: GQEmissionsYear;
};

export type GQEditExternalReportQuestionInput = {
  id: Scalars['ID']['input'];
  identifier: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type GQEditExternalReportQuestionPayload = {
  __typename?: 'EditExternalReportQuestionPayload';
  question: GQExternalReportQuestion;
};

export type GQEditExternalReportTypeWithRevisionInput = {
  id: Scalars['ID']['input'];
  revision: Scalars['String']['input'];
  specUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GQEditExternalReportTypeWithRevisionPayload = {
  __typename?: 'EditExternalReportTypeWithRevisionPayload';
  externalReportTypeWithRevision: GQExternalReportTypeWithRevision;
};

export type GQEditIngestionCustomFieldInput = {
  /** @deprecated unused, remove may13 */
  canVaryOverTime?: InputMaybe<Scalars['Boolean']['input']>;
  datasetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  exampleValues?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  possibleValues?: InputMaybe<Array<Scalars['String']['input']>>;
  type: GQIngestionCustomFieldType;
  utilityTypes?: InputMaybe<Array<GQBuildingUtilityType>>;
};

export type GQEditIngestionCustomFieldPayload = {
  __typename?: 'EditIngestionCustomFieldPayload';
  ingestionCustomField: GQIngestionCustomField;
};

export type GQEditMappedExternalReportQuestionsInput = {
  externalReportQuestionId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  identicalQuestionIds: Array<Scalars['ID']['input']>;
  relatedQuestionIds: Array<Scalars['ID']['input']>;
};

export type GQEditMappedExternalReportQuestionsPayload = {
  __typename?: 'EditMappedExternalReportQuestionsPayload';
  mappedExternalReportQuestions1: GQMappedExternalReportQuestions;
  mappedExternalReportQuestions2: GQMappedExternalReportQuestions;
};

export type GQEditPublicDisclosureInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  emissionsYearData?: InputMaybe<GQEditEmissionsYearInput>;
  gsUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  publicUrl: Scalars['String']['input'];
  publishingYear: Scalars['Int']['input'];
  reportType: GQExternalReportType;
  sectionUrls?: InputMaybe<Array<GQCreateExternalReportSectionUrlInput>>;
};

export type GQEditPublicDisclosurePayload = {
  __typename?: 'EditPublicDisclosurePayload';
  publicDisclosure: GQPublicDisclosure;
};

export type GQEditValueMappingCommentInput = {
  editedBy: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};

export const GQEditValueType = {
  Date: 'Date',
  String: 'String',
} as const;

export type GQEditValueType =
  (typeof GQEditValueType)[keyof typeof GQEditValueType];
export const GQElectricityType = {
  Clean: 'Clean',
  Grid: 'Grid',
} as const;

export type GQElectricityType =
  (typeof GQElectricityType)[keyof typeof GQElectricityType];
export type GQEmReleaseGlobalFootprintTagInput = {
  globalFootprintTagId: Scalars['ID']['input'];
  tagName: Scalars['String']['input'];
};

export type GQEmailContents = {
  __typename?: 'EmailContents';
  bccs: Maybe<Array<Maybe<GQEmailUser>>>;
  bodyHtml: Scalars['String']['output'];
  ccs: Maybe<Array<GQEmailUser>>;
  recipients: Array<GQEmailUser>;
  sender: GQEmailUser;
  subject: Scalars['String']['output'];
};

export type GQEmailContentsInput = {
  bccs?: InputMaybe<Array<InputMaybe<GQEmailUserInput>>>;
  bodyHtml: Scalars['String']['input'];
  ccs?: InputMaybe<Array<GQEmailUserInput>>;
  recipients: Array<GQEmailUserInput>;
  sender: GQEmailUserInput;
  subject: Scalars['String']['input'];
};

export type GQEmailContentsWithUserRecipient = {
  __typename?: 'EmailContentsWithUserRecipient';
  emailContents: GQEmailContents;
  userId: Scalars['ID']['output'];
};

export const GQEmailKind = {
  CompanyEngagement: 'CompanyEngagement',
  GeneralMillsCompanyEngagement: 'GeneralMillsCompanyEngagement',
  MeasurementNudge: 'MeasurementNudge',
  Referral: 'Referral',
  SurveyComment: 'SurveyComment',
  SurveyKickoff: 'SurveyKickoff',
  SurveyNudge: 'SurveyNudge',
} as const;

export type GQEmailKind = (typeof GQEmailKind)[keyof typeof GQEmailKind];
export const GQEmailRelationshipKind = {
  Bcc: 'Bcc',
  Cc: 'Cc',
  To: 'To',
} as const;

export type GQEmailRelationshipKind =
  (typeof GQEmailRelationshipKind)[keyof typeof GQEmailRelationshipKind];
export type GQEmailSent = {
  __typename?: 'EmailSent';
  clickCount: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fromEmail: Scalars['String']['output'];
  htmlContent: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  openCount: Maybe<Scalars['Int']['output']>;
  org: GQOrganization;
  subject: Scalars['String']['output'];
  users: Array<GQEmailSentUser>;
};

export type GQEmailSentUser = {
  __typename?: 'EmailSentUser';
  id: Scalars['ID']['output'];
  latestEmailStatus: GQEmailStatus;
  recipientEmail: Scalars['String']['output'];
  recipientUser: Maybe<GQUser>;
  /** @deprecated redundant, use recipientUser.id instead */
  recipientUserId: Maybe<Scalars['ID']['output']>;
  relationshipKind: GQEmailRelationshipKind;
};

export const GQEmailStatus = {
  Bounce: 'Bounce',
  Deferred: 'Deferred',
  Delivered: 'Delivered',
  Dropped: 'Dropped',
  Processed: 'Processed',
  Unknown: 'Unknown',
} as const;

export type GQEmailStatus = (typeof GQEmailStatus)[keyof typeof GQEmailStatus];
export type GQEmailUser = {
  __typename?: 'EmailUser';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GQEmailUserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GQEmeCalculationSampler = {
  __typename?: 'EmeCalculationSampler';
  samples: Array<GQEmeCalculationSamplerItem>;
};

export type GQEmeCalculationSamplerItem = {
  __typename?: 'EmeCalculationSamplerItem';
  equation: GQEmissionsModelEvalEquation;
  fingerprint: Scalars['String']['output'];
  rowId: Scalars['Int']['output'];
};

export type GQEmeMissingBartValueMetadata = {
  __typename?: 'EmeMissingBartValueMetadata';
  businessActivityTypeName: Scalars['String']['output'];
  column: Scalars['String']['output'];
};

export type GQEmeMissingReferenceValueMetadata = {
  __typename?: 'EmeMissingReferenceValueMetadata';
  column: Scalars['String']['output'];
  revisionId: Scalars['String']['output'];
  rowId: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
};

export type GQEmeMissingTraceMetadata = {
  __typename?: 'EmeMissingTraceMetadata';
  missingBartValueMetadata: Array<GQEmeMissingBartValueMetadata>;
  missingReferenceValueCitations: Array<GQEmeMissingReferenceValueMetadata>;
  missingReferenceValueDescriptions: Array<GQEmeMissingReferenceValueMetadata>;
};

export type GQEmissionByGroup = {
  __typename?: 'EmissionByGroup';
  attributes: Array<GQRegionAttribute>;
  kgco2e: Scalars['Float']['output'];
};

export type GQEmissionsBreakdown = {
  __typename?: 'EmissionsBreakdown';
  dataPoints: Array<GQEmissionsDataPoint>;
  fields: Array<Scalars['String']['output']>;
  intensityDenominator: Maybe<GQIntensityDenominatorDataPoint>;
};

export type GQEmissionsByGhgCategoryForFinance = {
  __typename?: 'EmissionsByGhgCategoryForFinance';
  fullIntervalGross: Maybe<Scalars['Float']['output']>;
  topGhgCategories: Array<GQGhgCategorizedEmissionData>;
  topGhgCategoriesWithSubcategories: Array<GQGhgCategorizedEmissionData>;
};

export type GQEmissionsByGhgScope = {
  __typename?: 'EmissionsByGhgScope';
  id: Scalars['ID']['output'];
  isEstimated: Scalars['Boolean']['output'];
  kgco2e: Scalars['Int53']['output'];
  scope: GQGhgScope;
  sourceUrl: Maybe<Scalars['String']['output']>;
  sourceYear: Maybe<Scalars['Int']['output']>;
};

export type GQEmissionsDataPoint = {
  __typename?: 'EmissionsDataPoint';
  fieldValues: Array<GQFootprintFieldValue>;
  locationTco2e: Scalars['Float']['output'];
  marketTco2e: Scalars['Float']['output'];
};

export type GQEmissionsFactorDescription = {
  __typename?: 'EmissionsFactorDescription';
  emissionsFactorId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userVisibleEfName: Scalars['String']['output'];
};

export type GQEmissionsFactorRecordForDashboard = {
  __typename?: 'EmissionsFactorRecordForDashboard';
  assumptionSource: Maybe<GQAssumptionSource>;
  assumptionSourceMemo: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  efActivityType: Maybe<Scalars['String']['output']>;
  efName: Maybe<Scalars['String']['output']>;
  emissionsFactorDescription: Maybe<GQEmissionsFactorDescription>;
  emissionsFactorId: Scalars['ID']['output'];
  endMonth: Scalars['YearMonth']['output'];
  id: Scalars['ID']['output'];
  isCleanPower: Maybe<Scalars['Boolean']['output']>;
  isElectricityMarketEf: Maybe<Scalars['Boolean']['output']>;
  isVendorSpecific: Maybe<Scalars['Boolean']['output']>;
  kgCo2e: Maybe<Scalars['Float']['output']>;
  startMonth: Scalars['YearMonth']['output'];
  supplierDisclosure: Maybe<GQDisclosure>;
  unit: Maybe<Scalars['String']['output']>;
};

export type GQEmissionsFactorRecordForDashboardSupplierDisclosureArgs = {
  opts: GQDisclosureInput;
};

export type GQEmissionsModelActiveDraft = {
  __typename?: 'EmissionsModelActiveDraft';
  activeAuthor: GQWatershedEmployee;
  activeAuthorId: Scalars['ID']['output'];
  autosavedAt: Scalars['DateTime']['output'];
  emissionsModelJson: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  importedDescendants: Array<GQEmissionsModelVersion>;
  isCheckedOut: Scalars['Boolean']['output'];
  matchers: Scalars['JSONString']['output'];
  parameterResolverJson: Maybe<Scalars['JSONString']['output']>;
  releasedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQEmissionsModelAncestor = {
  __typename?: 'EmissionsModelAncestor';
  ancestorForkOriginStableModelId: Maybe<Scalars['ID']['output']>;
  ancestorStableModel: GQEmissionsModelStable;
  relationship: GQEmissionsModelImportRelationship;
  rootIsLatestPublishedVersion: Scalars['Boolean']['output'];
  rootVersionId: Scalars['String']['output'];
};

export type GQEmissionsModelAncestry = {
  __typename?: 'EmissionsModelAncestry';
  ancestors: Array<GQEmissionsModelAncestor>;
};

export type GQEmissionsModelEditorEvalInput = {
  activityRecordJson: Scalars['JSONString']['input'];
  businessActivityTypeName?: InputMaybe<Scalars['String']['input']>;
  emissionsModelJson: Scalars['JSONString']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  parameterResolverJson: Scalars['JSONString']['input'];
  unboundParameterBindings?: InputMaybe<Array<GQUnboundParameterBinding>>;
};

export type GQEmissionsModelEditorEvalPayload = {
  __typename?: 'EmissionsModelEditorEvalPayload';
  emissionsEquations: Array<GQEmissionsModelEvalEquation>;
  resultJson: Scalars['JSONString']['output'];
  warnings: Array<Scalars['String']['output']>;
};

export type GQEmissionsModelEditorResolvedParameters = {
  __typename?: 'EmissionsModelEditorResolvedParameters';
  modelParameters: Scalars['JSONString']['output'];
};

export type GQEmissionsModelEmissionsFactor = {
  __typename?: 'EmissionsModelEmissionsFactor';
  externalDescription: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSupplierSpecific: Maybe<Scalars['Boolean']['output']>;
  supplierDisclosure: Maybe<GQDisclosure>;
  unit: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['Float']['output']>;
};

export type GQEmissionsModelEmissionsFactorSupplierDisclosureArgs = {
  opts: GQDisclosureInput;
};

export type GQEmissionsModelError = {
  __typename?: 'EmissionsModelError';
  msg: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  severity: GQEmissionsModelErrorSeverity;
};

export const GQEmissionsModelErrorSeverity = {
  Error: 'error',
  Warning: 'warning',
} as const;

export type GQEmissionsModelErrorSeverity =
  (typeof GQEmissionsModelErrorSeverity)[keyof typeof GQEmissionsModelErrorSeverity];
export type GQEmissionsModelEvalEquation = {
  __typename?: 'EmissionsModelEvalEquation';
  emissionsVariableName: Scalars['String']['output'];
  trace: Scalars['String']['output'];
  traceDetails: GQTraceDetails;
};

export type GQEmissionsModelEvaluation = GQIdInterface & {
  __typename?: 'EmissionsModelEvaluation';
  activityDataExtractor: Maybe<GQActivityDataExtractor>;
  bartTotalRowCount: Maybe<Scalars['Int']['output']>;
  calculationSampler: Maybe<GQEmeCalculationSampler>;
  createdAt: Scalars['DateTime']['output'];
  emissionsModelVersion: GQEmissionsModelVersion;
  emissionsModelVersionId: Scalars['ID']['output'];
  evaluatedBartId: Maybe<Scalars['ID']['output']>;
  evaluatedEmissionsModelMatcher: Maybe<Scalars['JSONString']['output']>;
  evaluatedEmtaFmdId: Maybe<Scalars['ID']['output']>;
  evaluatedExtendedBart: Maybe<GQExtendedBart>;
  evaluatedExtendedBartId: Maybe<Scalars['ID']['output']>;
  fingerprintCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  missingTraceMetadata: GQEmeMissingTraceMetadata;
  resultFileMetadata: GQFileMetadata;
  resultFileMetadataId: Scalars['String']['output'];
  uniqueBartRowCount: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQEmissionsModelForRdv = {
  __typename?: 'EmissionsModelForRdv';
  isArchived: Scalars['Boolean']['output'];
  latestPublishedEmvChangelog: Scalars['String']['output'];
  latestPublishedEmvCreatedAt: Scalars['DateTime']['output'];
  latestPublishedEmvId: Scalars['String']['output'];
  latestPublishedEmvUsesRdv: Scalars['Boolean']['output'];
  stableId: Scalars['ID']['output'];
  stableName: Scalars['String']['output'];
};

export type GQEmissionsModelFork = {
  __typename?: 'EmissionsModelFork';
  createdAt: Scalars['DateTime']['output'];
  forkedParameterResolverId: Scalars['ID']['output'];
  forkedStable: GQEmissionsModelStable;
  forkedStableId: Scalars['ID']['output'];
  forkedVersionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  newStable: GQEmissionsModelStable;
  newStableId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQEmissionsModelForkMatcher = {
  __typename?: 'EmissionsModelForkMatcher';
  createdAt: Scalars['DateTime']['output'];
  emissionsModelForkId: Scalars['ID']['output'];
  forkedMatcherId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQEmissionsModelForkRelationshipsPayload = {
  __typename?: 'EmissionsModelForkRelationshipsPayload';
  downstreamEntries: Maybe<Array<GQEmissionsModelFork>>;
  upstreamEntry: Maybe<GQEmissionsModelFork>;
};

export type GQEmissionsModelImportRelationship = {
  __typename?: 'EmissionsModelImportRelationship';
  childId: Scalars['String']['output'];
  parentId: Scalars['String']['output'];
};

export type GQEmissionsModelMatcher = {
  __typename?: 'EmissionsModelMatcher';
  bindings: Scalars['JSONString']['output'];
  businessActivityType: Scalars['String']['output'];
  businessActivityTypeVersionId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  emissionsModelStableId: Scalars['ID']['output'];
  filterExpression: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keepBatVersionAtLatest: Scalars['Boolean']['output'];
  kind: GQEmissionsModelMatcherKind;
  orgId: Maybe<Scalars['String']['output']>;
  quantitySpecifier: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQEmissionsModelMatcherInput = {
  bindings: Scalars['JSONString']['input'];
  businessActivityType: Scalars['String']['input'];
  businessActivityTypeVersionId: Scalars['String']['input'];
  emissionsModelStableId: Scalars['ID']['input'];
  filterExpression?: InputMaybe<Scalars['String']['input']>;
  keepBatVersionAtLatest: Scalars['Boolean']['input'];
  quantitySpecifier?: InputMaybe<Scalars['String']['input']>;
};

export const GQEmissionsModelMatcherKind = {
  Draft: 'Draft',
  Published: 'Published',
} as const;

export type GQEmissionsModelMatcherKind =
  (typeof GQEmissionsModelMatcherKind)[keyof typeof GQEmissionsModelMatcherKind];
export type GQEmissionsModelRelease = {
  __typename?: 'EmissionsModelRelease';
  businessActivityTypeName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  emissionsModels: Array<GQEmissionsModelStable>;
  externalNotes: Scalars['String']['output'];
  globalFootprintTags: Array<GQGlobalFootprintTag>;
  id: Scalars['ID']['output'];
  internalNotes: Scalars['String']['output'];
  isCustomToUserOrg: Maybe<Scalars['Boolean']['output']>;
  isOrgGated: Scalars['Boolean']['output'];
  latestCompatibleBatVersion: Maybe<GQBusinessActivityTypeVersion>;
  lifecyclePhase: GQEmReleaseLifecyclePhase;
  lifecyclePhaseHistory: Array<GQEmissionsModelReleaseLifecyclePhase>;
  measurementTestSuites: Array<GQMeasurementTestSuite>;
  org: Maybe<GQOrganization>;
  orgAccess: Array<GQEmissionsModelReleaseOrgAccess>;
  orgId: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: Maybe<GQUser>;
  userAuthorId: Maybe<Scalars['ID']['output']>;
};

export type GQEmissionsModelReleaseByBatName = {
  __typename?: 'EmissionsModelReleaseByBatName';
  batName: Scalars['String']['output'];
  lifecyclePhase: Maybe<GQEmReleaseLifecyclePhase>;
  release: GQEmissionsModelRelease;
};

export type GQEmissionsModelReleaseEmissionsModelsStable = {
  __typename?: 'EmissionsModelReleaseEmissionsModelsStable';
  models: Array<GQEmissionsModelStable>;
  release: GQEmissionsModelRelease;
};

export type GQEmissionsModelReleaseIdInput = {
  releaseId: Scalars['ID']['input'];
};

export type GQEmissionsModelReleaseLifecyclePhase = {
  __typename?: 'EmissionsModelReleaseLifecyclePhase';
  id: Scalars['ID']['output'];
  lifecyclePhase: GQEmReleaseLifecyclePhase;
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
};

export type GQEmissionsModelReleaseOrgAccess = {
  __typename?: 'EmissionsModelReleaseOrgAccess';
  createdAt: Scalars['DateTime']['output'];
  emissionsModelRelease: GQEmissionsModelRelease;
  emissionsModelReleaseId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  org: GQOrganization;
  orgId: Scalars['ID']['output'];
};

export type GQEmissionsModelReleasePayload = {
  __typename?: 'EmissionsModelReleasePayload';
  emissionsModelRelease: GQEmissionsModelRelease;
};

export type GQEmissionsModelResolvedParameters = GQIdInterface & {
  __typename?: 'EmissionsModelResolvedParameters';
  emissionsModelVersionId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modelParameters: Scalars['JSONString']['output'];
};

export type GQEmissionsModelStable = GQIdInterface & {
  __typename?: 'EmissionsModelStable';
  ancestry: GQEmissionsModelAncestry;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isMutable: Scalars['Boolean']['output'];
  kind: GQEmissionsModelStableKind;
  latestPublishedVersion: Maybe<GQEmissionsModelVersion>;
  latestVersion: GQEmissionsModelVersion;
  matchers: Array<GQEmissionsModelMatcher>;
  org: Maybe<GQOrganization>;
  releases: Array<GQEmissionsModelRelease>;
  scope: GQEmissionsModelStableScope;
  tags: Array<GQCalculationTag>;
  title: Scalars['String']['output'];
  versions: Maybe<Array<GQEmissionsModelVersion>>;
};

export const GQEmissionsModelStableKind = {
  Core: 'core',
  Customer: 'customer',
} as const;

export type GQEmissionsModelStableKind =
  (typeof GQEmissionsModelStableKind)[keyof typeof GQEmissionsModelStableKind];
export const GQEmissionsModelStableScope = {
  Full: 'full',
  Partial: 'partial',
} as const;

export type GQEmissionsModelStableScope =
  (typeof GQEmissionsModelStableScope)[keyof typeof GQEmissionsModelStableScope];
export type GQEmissionsModelVariableDetails = {
  __typename?: 'EmissionsModelVariableDetails';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  emissionsModelEvaluationId: Scalars['String']['output'];
  variableName: Scalars['String']['output'];
};

export type GQEmissionsModelVersion = GQIdInterface & {
  __typename?: 'EmissionsModelVersion';
  activeDraftForVersion: Maybe<GQEmissionsModelActiveDraft>;
  author: Maybe<GQUser>;
  changelog: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  emissionsModelJson: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
  importedDescendants: Array<GQEmissionsModelVersion>;
  kind: GQEmissionsModelVersionKind;
  parameterResolver: GQParameterResolver;
  parameterResolverJson: Scalars['JSONString']['output'];
  referenceDataVersions: Array<GQReferenceDataVersion>;
  schemaVersion: Scalars['String']['output'];
  stableId: Scalars['String']['output'];
  stableModel: GQEmissionsModelStable;
  title: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export type GQEmissionsModelVersionHistory = {
  __typename?: 'EmissionsModelVersionHistory';
  id: Scalars['ID']['output'];
  records: Array<GQEmissionsModelVersion>;
};

export type GQEmissionsModelVersionHistoryConnection = {
  __typename?: 'EmissionsModelVersionHistoryConnection';
  edges: Array<GQEmissionsModelVersionHistoryEdge>;
  pageInfo: GQPageInfo;
};

export type GQEmissionsModelVersionHistoryEdge = {
  __typename?: 'EmissionsModelVersionHistoryEdge';
  cursor: Scalars['String']['output'];
  node: GQEmissionsModelVersion;
};

export const GQEmissionsModelVersionKind = {
  Draft: 'Draft',
  Published: 'Published',
} as const;

export type GQEmissionsModelVersionKind =
  (typeof GQEmissionsModelVersionKind)[keyof typeof GQEmissionsModelVersionKind];
export type GQEmissionsModelVersionWithReleaseIds = {
  __typename?: 'EmissionsModelVersionWithReleaseIds';
  emv: GQEmissionsModelVersion;
  id: Scalars['ID']['output'];
  releaseIds: Array<Scalars['ID']['output']>;
};

export const GQEmissionsSource = {
  Cee: 'CEE',
  CompanyNaicsCodeEstimate: 'CompanyNaicsCodeEstimate',
  ExternalReport: 'ExternalReport',
  ManualEntry: 'ManualEntry',
  Survey: 'Survey',
  SurveyEstimate: 'SurveyEstimate',
  WatershedMeasurement: 'WatershedMeasurement',
} as const;

export type GQEmissionsSource =
  (typeof GQEmissionsSource)[keyof typeof GQEmissionsSource];
export type GQEmissionsYear = GQCompanyEmissionsInterfaceV2 &
  GQIdInterface & {
    __typename?: 'EmissionsYear';
    assetCorporateId: Maybe<Scalars['ID']['output']>;
    buildingEstimateOutputId: Maybe<Scalars['ID']['output']>;
    /** @deprecated unused, remove jul23 */
    cleanEnergyNotes: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['DateTime']['output'];
    currencyConversionRate: Maybe<Scalars['Float']['output']>;
    emissionsByGhgCategory: Maybe<GQEmissionsByGhgCategoryForFinance>;
    expenseCategory: Maybe<Scalars['String']['output']>;
    footprintEstimateOutputId: Maybe<Scalars['ID']['output']>;
    id: Scalars['ID']['output'];
    numEmployees: Maybe<Scalars['Float']['output']>;
    orgId: Maybe<Scalars['ID']['output']>;
    pcafScoreScope1: Maybe<Scalars['Float']['output']>;
    percentageCleanEnergy: Maybe<Scalars['Float']['output']>;
    priorEstimateInputs: Maybe<Scalars['JSONString']['output']>;
    privateDisclosureId: Maybe<Scalars['ID']['output']>;
    publicDisclosureId: Maybe<Scalars['ID']['output']>;
    reportingYear: Scalars['Int']['output'];
    revenue: Maybe<Scalars['Float']['output']>;
    revenueCurrency: Maybe<Scalars['String']['output']>;
    revenueUsd: Maybe<Scalars['Float']['output']>;
    scenario: Maybe<Scalars['String']['output']>;
    scope1: Maybe<Scalars['Float']['output']>;
    scope2: Maybe<Scalars['Float']['output']>;
    scope2Location: Maybe<Scalars['Float']['output']>;
    scope2Market: Maybe<Scalars['Float']['output']>;
    scope3: Maybe<Scalars['Float']['output']>;
    scope123RevenueIntensityKgco2e: Maybe<GQExternalReportRevenueIntensityKgco2eByScope>;
    scope301: Maybe<Scalars['Float']['output']>;
    scope302: Maybe<Scalars['Float']['output']>;
    scope303: Maybe<Scalars['Float']['output']>;
    scope304: Maybe<Scalars['Float']['output']>;
    scope305: Maybe<Scalars['Float']['output']>;
    scope306: Maybe<Scalars['Float']['output']>;
    scope307: Maybe<Scalars['Float']['output']>;
    scope308: Maybe<Scalars['Float']['output']>;
    scope309: Maybe<Scalars['Float']['output']>;
    scope310: Maybe<Scalars['Float']['output']>;
    scope311: Maybe<Scalars['Float']['output']>;
    scope312: Maybe<Scalars['Float']['output']>;
    scope313: Maybe<Scalars['Float']['output']>;
    scope314: Maybe<Scalars['Float']['output']>;
    scope315: Maybe<Scalars['Float']['output']>;
    scope316: Maybe<Scalars['Float']['output']>;
    scope317: Maybe<Scalars['Float']['output']>;
    totalMwh: Maybe<Scalars['Int']['output']>;
    units: GQCompanyEmissionsUnits;
    updatedAt: Scalars['DateTime']['output'];
  };

export type GQEmissionsYearConnection = {
  __typename?: 'EmissionsYearConnection';
  edges: Array<GQEmissionsYearEdge>;
  pageInfo: GQPageInfo;
};

export type GQEmissionsYearEdge = {
  __typename?: 'EmissionsYearEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQEmissionsYear>;
};

export const GQEmissionsYearScenario = {
  Actual: 'Actual',
  BauForecast: 'BAUForecast',
  Sandbox: 'Sandbox',
} as const;

export type GQEmissionsYearScenario =
  (typeof GQEmissionsYearScenario)[keyof typeof GQEmissionsYearScenario];
export const GQEmissionsYearSource = {
  BuildingEstimationEngine: 'BuildingEstimationEngine',
  CompanyEstimatationEngine: 'CompanyEstimatationEngine',
  ManualEntry: 'ManualEntry',
  PrivateCdp: 'PrivateCdp',
  PublicDisclosure: 'PublicDisclosure',
  Survey: 'Survey',
  SurveyEstimate: 'SurveyEstimate',
  WatershedMeasurement: 'WatershedMeasurement',
} as const;

export type GQEmissionsYearSource =
  (typeof GQEmissionsYearSource)[keyof typeof GQEmissionsYearSource];
export type GQEmployeeReport = {
  __typename?: 'EmployeeReport';
  archetypePurchases: Array<GQEmployeeReportArchetypePurchase>;
  /** Emissions by category. */
  categorizedEmissions: Maybe<Array<GQCategorizedEmissionData>>;
  climateProgramInfo: GQClimateProgramInfo;
  footprintInterval: Scalars['YMInterval']['output'];
  footprintKindBreakdown: GQFootprintKindBreakdown;
  /** Overall emissions number. In kgCO2e */
  grossEmissions: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  intensityTimeseries: Maybe<GQFootprintAnalysisTimeSeries>;
  markdownCardInfo: Maybe<GQMarkdownCardInfo>;
  marketplaceInterval: Scalars['YMInterval']['output'];
  marketplacePurchaseLineItems: Array<GQMarketplacePurchaseLineItem>;
  netZeroPlan: Maybe<GQNetZeroPlan>;
  netZeroYear: Maybe<Scalars['Int']['output']>;
  /** The plan that's visible on the summary report. */
  plan: Maybe<GQPlan>;
  programKind: GQClimateProgramKind;
  reductionTargetsInfo: Maybe<GQReductionTargetsInfo>;
  timeseries: Maybe<GQFootprintAnalysisTimeSeries>;
  visibility: GQEmployeeReportVisibility;
};

export type GQEmployeeReportClimateProgramInfoArgs = {
  includeCustomSteps: InputMaybe<Scalars['Boolean']['input']>;
  shouldInterpolate: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQEmployeeReportGrossEmissionsArgs = {
  kind: InputMaybe<GQAggregateKind>;
};

export type GQEmployeeReportIntensityTimeseriesArgs = {
  footprintKind: Scalars['String']['input'];
};

export type GQEmployeeReportMarkdownCardInfoArgs = {
  shouldInterpolate: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQEmployeeReportTimeseriesArgs = {
  footprintKind: Scalars['String']['input'];
};

export type GQEmployeeReportArchetypePurchase = {
  __typename?: 'EmployeeReportArchetypePurchase';
  archetype: GQMarketplaceProjectArchetype;
  proportionOfAmount: Scalars['Float']['output'];
  proportionOfSpend: Scalars['Float']['output'];
};

export const GQEmployeeReportVisibility = {
  AdminOnly: 'AdminOnly',
  Draft: 'Draft',
  Published: 'Published',
} as const;

export type GQEmployeeReportVisibility =
  (typeof GQEmployeeReportVisibility)[keyof typeof GQEmployeeReportVisibility];
export type GQEnergyConsumptionBreakdown = {
  __typename?: 'EnergyConsumptionBreakdown';
  dataPoints: Array<GQEnergyConsumptionDataPoint>;
  fields: Array<Scalars['String']['output']>;
  totalCleanMwh: Scalars['Float']['output'];
  totalElectricityTco2e: Scalars['Float']['output'];
  totalFootprintResults: Array<GQFootprintKindTotals>;
  /** @deprecated This returns the total footprint accross all footprint kinds. Use totalFootprintResults instead. */
  totalFootprintTco2e: Scalars['Float']['output'];
  totalMwh: Scalars['Float']['output'];
};

export type GQEnergyConsumptionDataPoint = {
  __typename?: 'EnergyConsumptionDataPoint';
  avoidedMarketTco2e: Scalars['Float']['output'];
  fieldValues: Array<GQFootprintFieldValue>;
  locationTco2e: Scalars['Float']['output'];
  marketTco2e: Scalars['Float']['output'];
  mwhConsumed: Scalars['Float']['output'];
};

export type GQEnergyEstimationFactors = {
  employeeHeadcount: Scalars['Float']['input'];
  employeeRemoteRatio: Scalars['Float']['input'];
  officeOccupancy: Scalars['Float']['input'];
  officeSizeIncrease?: InputMaybe<Scalars['Float']['input']>;
  onsiteEmployeeWfhRatio: Scalars['Float']['input'];
  yoyMultiplier: Scalars['Float']['input'];
};

export type GQEngagementCohort = {
  __typename?: 'EngagementCohort';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQEngagementCohortWithCompanyIds = {
  __typename?: 'EngagementCohortWithCompanyIds';
  companyIds: Maybe<Array<Scalars['ID']['output']>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQEngagementFunnel = {
  __typename?: 'EngagementFunnel';
  displayOnSupplierDetailsPage: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  steps: Array<GQEngagementFunnelStep>;
};

export type GQEngagementFunnelInput = {
  displayOnSupplierDetailsPage: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  steps: Array<GQEngagementFunnelStepInput>;
};

export type GQEngagementFunnelStep = {
  __typename?: 'EngagementFunnelStep';
  engagementTaskConfigId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sbtStage: Maybe<GQCompanySbtCommitmentStage>;
  stepName: Scalars['String']['output'];
  stepType: GQEngagementFunnelStepType;
  supplierTableColumn: Maybe<GQSupplierTableColumn>;
  supplierTableColumnValues: Maybe<Array<Scalars['String']['output']>>;
};

export type GQEngagementFunnelStepInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  sbtStage?: InputMaybe<GQCompanySbtCommitmentStage>;
  stepName: Scalars['String']['input'];
  stepType: GQEngagementFunnelStepType;
  supplierTableColumnId: Scalars['ID']['input'];
  supplierTableColumnValues?: InputMaybe<Array<Scalars['String']['input']>>;
};

export const GQEngagementFunnelStepType = {
  CustomSupplierTableColumn: 'CustomSupplierTableColumn',
  EmissionsDisclosed: 'EmissionsDisclosed',
  InitiativesDisclosed: 'InitiativesDisclosed',
  NotStarted: 'NotStarted',
  SbtStage: 'SbtStage',
  SupplierLoggedIn: 'SupplierLoggedIn',
  SurveyApproved: 'SurveyApproved',
  SurveyCreated: 'SurveyCreated',
  SurveyInProgress: 'SurveyInProgress',
  SurveySubmitted: 'SurveySubmitted',
  TargetsDisclosed: 'TargetsDisclosed',
} as const;

export type GQEngagementFunnelStepType =
  (typeof GQEngagementFunnelStepType)[keyof typeof GQEngagementFunnelStepType];
export type GQEngagementNotificationSetting = GQIdInterface & {
  __typename?: 'EngagementNotificationSetting';
  companyId: Maybe<Scalars['ID']['output']>;
  engagementTaskId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  shouldNotify: Scalars['Boolean']['output'];
  userId: Scalars['ID']['output'];
};

export type GQEngagementNotificationSettingInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  engagementTaskId?: InputMaybe<Scalars['ID']['input']>;
  shouldNotify: Scalars['Boolean']['input'];
};

export const GQEngagementNotificationSide = {
  MappedCompany: 'MappedCompany',
  RootCustomer: 'RootCustomer',
} as const;

export type GQEngagementNotificationSide =
  (typeof GQEngagementNotificationSide)[keyof typeof GQEngagementNotificationSide];
export type GQEngagementTask = GQPermissionObject & {
  __typename?: 'EngagementTask';
  approvedAt: Maybe<Scalars['DateTime']['output']>;
  comments: Array<GQEngagementTaskComment>;
  company: GQCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  crossOrgModelId: Scalars['String']['output'];
  currentAssignee: GQCompanySurveyAssignee;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  dueDate: Maybe<Scalars['Date']['output']>;
  edits: Array<GQCompanySurveyEdit>;
  engagementTaskBatchId: Maybe<Scalars['ID']['output']>;
  engagementTaskConfig: GQEngagementTaskConfig;
  engagementTaskConfigId: Scalars['ID']['output'];
  expenseCategories: Maybe<Array<GQDiscloseEmissionsExpenseCategories>>;
  expiresAt: Maybe<Scalars['DateTime']['output']>;
  facilityName: Maybe<Scalars['String']['output']>;
  footprintEstimate: Maybe<GQFootprintEstimateOutput>;
  id: Scalars['ID']['output'];
  initialSupplierAssigneeEmail: Maybe<Scalars['String']['output']>;
  latestCeeWorkflowId: Maybe<Scalars['ID']['output']>;
  latestFootprintDisclosureWorkflowId: Maybe<Scalars['ID']['output']>;
  latestPleeWorkflowId: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  proposeTargetEmissionsTarget: Maybe<GQSimpleTimeseries>;
  proposeTargetFilters: Maybe<GQFilterExpressionGroup>;
  proposeTargetIntensityType: Maybe<GQDisclosureTargetIntensityType>;
  reportingYear: Maybe<Scalars['Int']['output']>;
  rootAssignee: Maybe<Scalars['ID']['output']>;
  rootAssigneeDisplayName: Maybe<Scalars['String']['output']>;
  rootCompany: GQCompany;
  rootCompanyId: Scalars['ID']['output'];
  rootOrgId: Scalars['ID']['output'];
  status: GQCompanySurveyStatus;
  statusChangedAt: Maybe<Scalars['DateTime']['output']>;
  submittedAnswers: Array<GQEngagementTaskAnswer>;
  submittedAt: Maybe<Scalars['DateTime']['output']>;
  supplierAssignee: Maybe<Scalars['ID']['output']>;
  supplierFacingDescription: Maybe<Scalars['String']['output']>;
  supplierOrgId: Scalars['ID']['output'];
  surveyKind: GQCompanySurveyKind;
  watershedGeneric: Maybe<Scalars['Boolean']['output']>;
};

export type GQEngagementTaskAnswer = {
  __typename?: 'EngagementTaskAnswer';
  answer: Maybe<Scalars['JSONString']['output']>;
  attachments: Array<GQEngagementTaskAnswerAttachment>;
  id: Scalars['ID']['output'];
  questionIndex: Maybe<Scalars['Int']['output']>;
  questionKey: Scalars['String']['output'];
  skipped: Scalars['Boolean']['output'];
  surveyId: Scalars['ID']['output'];
};

export type GQEngagementTaskAnswerAttachment = {
  __typename?: 'EngagementTaskAnswerAttachment';
  fileMetadata: GQFileMetadata;
  originalFilename: Scalars['String']['output'];
  questionKey: Scalars['String']['output'];
  signedDownloadUrl: Maybe<Scalars['String']['output']>;
};

export type GQEngagementTaskAnswerInput = {
  answer?: InputMaybe<Scalars['JSONString']['input']>;
  explanation?: InputMaybe<Scalars['JSONString']['input']>;
  questionIndex?: InputMaybe<Scalars['Int']['input']>;
  questionKey: Scalars['String']['input'];
  skipped?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQEngagementTaskAttachment = {
  __typename?: 'EngagementTaskAttachment';
  fileMetadataId: Scalars['ID']['output'];
  signedUploadUrl: Scalars['String']['output'];
  supplierAttachmentCrossOrgId: Scalars['ID']['output'];
};

export type GQEngagementTaskAttachmentMetadataInput = {
  name: Scalars['String']['input'];
  sizeBytes: Scalars['Int']['input'];
};

export type GQEngagementTaskBatch = GQIdInterface & {
  __typename?: 'EngagementTaskBatch';
  createdAt: Scalars['DateTime']['output'];
  deployStatuses: Array<GQEngagementTaskDeployStatus>;
  engagedSuppliers: Scalars['Int']['output'];
  engagementTaskConfig: Maybe<GQEngagementTaskConfig>;
  engagementTaskConfigId: Maybe<Scalars['ID']['output']>;
  fromBulkDeployment: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isFinance: Scalars['Boolean']['output'];
  isManuallyResolved: Scalars['Boolean']['output'];
  orgId: Scalars['ID']['output'];
  percentageApproved: Scalars['Float']['output'];
  percentageSubmitted: Scalars['Float']['output'];
  workflowId: Maybe<Scalars['String']['output']>;
};

export type GQEngagementTaskBatchDeployStatus = GQIdInterface & {
  __typename?: 'EngagementTaskBatchDeployStatus';
  failedDeployCompanies: Array<GQCompany>;
  id: Scalars['ID']['output'];
  successfulDeployCompanies: Array<GQCompany>;
};

export type GQEngagementTaskComment = {
  __typename?: 'EngagementTaskComment';
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorOrgId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  userEditorId: Maybe<Scalars['ID']['output']>;
  userEditorName: Maybe<Scalars['String']['output']>;
};

export type GQEngagementTaskConfig = GQIdInterface & {
  __typename?: 'EngagementTaskConfig';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  baseConfig: Maybe<GQEngagementTaskConfig>;
  canDelete: Scalars['Boolean']['output'];
  canDeleteInAdmin: Scalars['Boolean']['output'];
  category: GQSurveyDefinitionCategory;
  configGroup: Maybe<GQEngagementTaskConfigGroup>;
  configHasTaskAssignedToCompany: Scalars['Boolean']['output'];
  containers: Array<GQEngagementTaskContainer>;
  crossOrgModelId: Maybe<Scalars['String']['output']>;
  defaultSupplierFacingDescription: Maybe<Scalars['String']['output']>;
  engagementTaskInputs: Array<GQEngagementTaskInput>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
  order: Scalars['Int']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  publishedAt: Maybe<Scalars['DateTime']['output']>;
  rootFacingDescription: Maybe<Scalars['String']['output']>;
  sidebarTitle: Scalars['String']['output'];
  timeNeeded: Maybe<Scalars['Int']['output']>;
  type: Maybe<GQEngagementTaskConfigType>;
};

export type GQEngagementTaskConfigCreateInputForAdmin = {
  category: GQSurveyDefinitionCategory;
  configGroup?: InputMaybe<GQEngagementTaskConfigGroup>;
  defaultSupplierFacingDescription?: InputMaybe<Scalars['String']['input']>;
  engagementTaskInputs: Array<GQEngagementTaskInput>;
  name: Scalars['String']['input'];
  optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
  order: Scalars['Int']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  rootFacingDescription?: InputMaybe<Scalars['String']['input']>;
  rootOrgId?: InputMaybe<Scalars['String']['input']>;
  sidebarTitle: Scalars['String']['input'];
  timeNeeded?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<GQEngagementTaskConfigType>;
};

export const GQEngagementTaskConfigGroup = {
  Finance: 'Finance',
  SupplyChain: 'SupplyChain',
} as const;

export type GQEngagementTaskConfigGroup =
  (typeof GQEngagementTaskConfigGroup)[keyof typeof GQEngagementTaskConfigGroup];
export type GQEngagementTaskConfigInputForAdmin = {
  category: GQSurveyDefinitionCategory;
  configGroup?: InputMaybe<GQEngagementTaskConfigGroup>;
  containers: Array<GQEngagementTaskContainerInputForAdmin>;
  defaultSupplierFacingDescription?: InputMaybe<Scalars['String']['input']>;
  engagementTaskInputs: Array<GQEngagementTaskInput>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
  order: Scalars['Int']['input'];
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rootFacingDescription?: InputMaybe<Scalars['String']['input']>;
  sidebarTitle: Scalars['String']['input'];
  timeNeeded?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<GQEngagementTaskConfigType>;
};

export type GQEngagementTaskConfigPayload = {
  __typename?: 'EngagementTaskConfigPayload';
  engagementTaskConfig: GQEngagementTaskConfig;
};

export const GQEngagementTaskConfigType = {
  DiscloseEmissions: 'DiscloseEmissions',
  DiscloseReductionProjects: 'DiscloseReductionProjects',
  DiscloseTargets: 'DiscloseTargets',
  LearningBusinessCaseForClimateAction: 'LearningBusinessCaseForClimateAction',
  LearningEngageYourTeam: 'LearningEngageYourTeam',
  LearningGettingStartedWithCorporateScienceBasedTargets:
    'LearningGettingStartedWithCorporateScienceBasedTargets',
  LearningLaunchingAClimateProgram: 'LearningLaunchingAClimateProgram',
  LearningMeasuringEmissions: 'LearningMeasuringEmissions',
  LearningScienceBasedTargets: 'LearningScienceBasedTargets',
  SbtCommitment: 'SbtCommitment',
  SbtGhgMeasurement: 'SbtGhgMeasurement',
  SbtProgress: 'SbtProgress',
  SetASpecificTarget: 'SetASpecificTarget',
  ShareFootprint: 'ShareFootprint',
  ShareMeasurementProjectStatus: 'ShareMeasurementProjectStatus',
  SustainabilityScreening: 'SustainabilityScreening',
} as const;

export type GQEngagementTaskConfigType =
  (typeof GQEngagementTaskConfigType)[keyof typeof GQEngagementTaskConfigType];
export type GQEngagementTaskConnection = {
  __typename?: 'EngagementTaskConnection';
  edges: Array<GQEngagementTaskEdge>;
  pageInfo: GQPageInfo;
};

export type GQEngagementTaskContainer = GQIdInterface & {
  __typename?: 'EngagementTaskContainer';
  componentId: Maybe<GQEngagementTaskContainerComponentId>;
  containers: Array<GQEngagementTaskContainer>;
  description: Maybe<Scalars['String']['output']>;
  editable: Scalars['Boolean']['output'];
  eyebrowText: Maybe<Scalars['String']['output']>;
  headerImage: Maybe<GQEngagementTaskImageId>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  questions: Array<GQEngagementTaskQuestion>;
  sidebarStepLabelOverride: Maybe<Scalars['String']['output']>;
  statusJson: Scalars['JSONString']['output'];
};

export const GQEngagementTaskContainerComponentId = {
  EngagementTaskConfirmSection: 'EngagementTaskConfirmSection',
  EngagementTaskDiscloseReductionProjectsIntroSteps:
    'EngagementTaskDiscloseReductionProjectsIntroSteps',
  EngagementTaskDiscloseTargetsIntroSteps:
    'EngagementTaskDiscloseTargetsIntroSteps',
  EngagementTaskEmissionsIntroSteps: 'EngagementTaskEmissionsIntroSteps',
  EngagementTaskExpenseCategoryIntro: 'EngagementTaskExpenseCategoryIntro',
  EngagementTaskFinanceImpBaseYearImage:
    'EngagementTaskFinanceImpBaseYearImage',
  EngagementTaskFinanceImpCaseIntro: 'EngagementTaskFinanceImpCaseIntro',
  EngagementTaskFinanceImpDataQualityScores:
    'EngagementTaskFinanceImpDataQualityScores',
  EngagementTaskFinanceImpDataTables: 'EngagementTaskFinanceImpDataTables',
  EngagementTaskFinanceImpGoals: 'EngagementTaskFinanceImpGoals',
  EngagementTaskFinanceImpScheduleCall: 'EngagementTaskFinanceImpScheduleCall',
  EngagementTaskFinanceImpSection1_4Image:
    'EngagementTaskFinanceImpSection1_4Image',
  EngagementTaskFinanceImpSection3_2Image:
    'EngagementTaskFinanceImpSection3_2Image',
  EngagementTaskFinanceImpSection3_3Image:
    'EngagementTaskFinanceImpSection3_3Image',
  EngagementTaskFinanceImpSection3_6Image:
    'EngagementTaskFinanceImpSection3_6Image',
  EngagementTaskFinanceImpSubmit: 'EngagementTaskFinanceImpSubmit',
  EngagementTaskFinanceUsingImp: 'EngagementTaskFinanceUsingImp',
  EngagementTaskLearningActivatingEmployeesDeepDive:
    'EngagementTaskLearningActivatingEmployeesDeepDive',
  EngagementTaskLearningActivatingEmployeesRecap:
    'EngagementTaskLearningActivatingEmployeesRecap',
  EngagementTaskLearningBuildingSupportDeepDive:
    'EngagementTaskLearningBuildingSupportDeepDive',
  EngagementTaskLearningBuildingSupportRecap:
    'EngagementTaskLearningBuildingSupportRecap',
  EngagementTaskLearningBusinessCaseCompletion:
    'EngagementTaskLearningBusinessCaseCompletion',
  EngagementTaskLearningBusinessCaseIntro:
    'EngagementTaskLearningBusinessCaseIntro',
  EngagementTaskLearningBusinessPartners:
    'EngagementTaskLearningBusinessPartners',
  EngagementTaskLearningCorporateScienceBasedTargetsAfterSettingSbt:
    'EngagementTaskLearningCorporateScienceBasedTargetsAfterSettingSbt',
  EngagementTaskLearningCorporateScienceBasedTargetsCompletion:
    'EngagementTaskLearningCorporateScienceBasedTargetsCompletion',
  EngagementTaskLearningCorporateScienceBasedTargetsEmployeeEngagementStrategy:
    'EngagementTaskLearningCorporateScienceBasedTargetsEmployeeEngagementStrategy',
  EngagementTaskLearningCorporateScienceBasedTargetsFoundationForChange:
    'EngagementTaskLearningCorporateScienceBasedTargetsFoundationForChange',
  EngagementTaskLearningCorporateScienceBasedTargetsIntro:
    'EngagementTaskLearningCorporateScienceBasedTargetsIntro',
  EngagementTaskLearningCorporateScienceBasedTargetsJourneyImage:
    'EngagementTaskLearningCorporateScienceBasedTargetsJourneyImage',
  EngagementTaskLearningCorporateScienceBasedTargetsLearnMoreAboutSbts:
    'EngagementTaskLearningCorporateScienceBasedTargetsLearnMoreAboutSbts',
  EngagementTaskLearningCorporateScienceBasedTargetsMeasureImage:
    'EngagementTaskLearningCorporateScienceBasedTargetsMeasureImage',
  EngagementTaskLearningCorporateScienceBasedTargetsRegulatoryDemands:
    'EngagementTaskLearningCorporateScienceBasedTargetsRegulatoryDemands',
  EngagementTaskLearningCorporateScienceBasedTargetsStartingWithSbt:
    'EngagementTaskLearningCorporateScienceBasedTargetsStartingWithSbt',
  EngagementTaskLearningCorporateScienceBasedTargetsStep1:
    'EngagementTaskLearningCorporateScienceBasedTargetsStep1',
  EngagementTaskLearningCorporateScienceBasedTargetsStep4:
    'EngagementTaskLearningCorporateScienceBasedTargetsStep4',
  EngagementTaskLearningCorporateScienceBasedTargetsWhatAreSbts:
    'EngagementTaskLearningCorporateScienceBasedTargetsWhatAreSbts',
  EngagementTaskLearningCorporateScienceBasedTargetsWhatAreSbtsVmWareOnly:
    'EngagementTaskLearningCorporateScienceBasedTargetsWhatAreSbtsVMWareOnly',
  EngagementTaskLearningDeepDives: 'EngagementTaskLearningDeepDives',
  EngagementTaskLearningEmissionsScopes:
    'EngagementTaskLearningEmissionsScopes',
  EngagementTaskLearningEngageYourTeamCompletion:
    'EngagementTaskLearningEngageYourTeamCompletion',
  EngagementTaskLearningEngageYourTeamIntro:
    'EngagementTaskLearningEngageYourTeamIntro',
  EngagementTaskLearningEngagingLeadershipDeepDive:
    'EngagementTaskLearningEngagingLeadershipDeepDive',
  EngagementTaskLearningEngagingLeadershipRecap:
    'EngagementTaskLearningEngagingLeadershipRecap',
  EngagementTaskLearningEngagingPartners:
    'EngagementTaskLearningEngagingPartners',
  EngagementTaskLearningGettingStartedWithSbt:
    'EngagementTaskLearningGettingStartedWithSbt',
  EngagementTaskLearningIdentifyingPartnersRecap:
    'EngagementTaskLearningIdentifyingPartnersRecap',
  EngagementTaskLearningInvestors: 'EngagementTaskLearningInvestors',
  EngagementTaskLearningLaunchingAClimateProgramAmbitionLevel:
    'EngagementTaskLearningLaunchingAClimateProgramAmbitionLevel',
  EngagementTaskLearningLaunchingAClimateProgramCompletion:
    'EngagementTaskLearningLaunchingAClimateProgramCompletion',
  EngagementTaskLearningLaunchingAClimateProgramInsightsIntoAction:
    'EngagementTaskLearningLaunchingAClimateProgramInsightsIntoAction',
  EngagementTaskLearningLaunchingAClimateProgramIntro:
    'EngagementTaskLearningLaunchingAClimateProgramIntro',
  EngagementTaskLearningLaunchingAClimateProgramMandatoryReporting:
    'EngagementTaskLearningLaunchingAClimateProgramMandatoryReporting',
  EngagementTaskLearningLaunchingAClimateProgramMeasurementWork:
    'EngagementTaskLearningLaunchingAClimateProgramMeasurementWork',
  EngagementTaskLearningLaunchingAClimateProgramMeasuringEmissions:
    'EngagementTaskLearningLaunchingAClimateProgramMeasuringEmissions',
  EngagementTaskLearningLaunchingAClimateProgramPartingThoughts:
    'EngagementTaskLearningLaunchingAClimateProgramPartingThoughts',
  EngagementTaskLearningLaunchingAClimateProgramRationale:
    'EngagementTaskLearningLaunchingAClimateProgramRationale',
  EngagementTaskLearningLaunchingAClimateProgramReporting:
    'EngagementTaskLearningLaunchingAClimateProgramReporting',
  EngagementTaskLearningLaunchingAClimateProgramShortTerm:
    'EngagementTaskLearningLaunchingAClimateProgramShortTerm',
  EngagementTaskLearningLaunchingAClimateProgramStrategy:
    'EngagementTaskLearningLaunchingAClimateProgramStrategy',
  EngagementTaskLearningMeasuredAndManaged:
    'EngagementTaskLearningMeasuredAndManaged',
  EngagementTaskLearningMeasurement: 'EngagementTaskLearningMeasurement',
  EngagementTaskLearningMeasuringEmissionsCompletion:
    'EngagementTaskLearningMeasuringEmissionsCompletion',
  EngagementTaskLearningMeasuringEmissionsIntro:
    'EngagementTaskLearningMeasuringEmissionsIntro',
  EngagementTaskLearningRegulations: 'EngagementTaskLearningRegulations',
  EngagementTaskLearningScienceBasedTargetsApproved:
    'EngagementTaskLearningScienceBasedTargetsApproved',
  EngagementTaskLearningScienceBasedTargetsCompletion:
    'EngagementTaskLearningScienceBasedTargetsCompletion',
  EngagementTaskLearningScienceBasedTargetsDefinition1:
    'EngagementTaskLearningScienceBasedTargetsDefinition1',
  EngagementTaskLearningScienceBasedTargetsDefinition2:
    'EngagementTaskLearningScienceBasedTargetsDefinition2',
  EngagementTaskLearningScienceBasedTargetsDisclose:
    'EngagementTaskLearningScienceBasedTargetsDisclose',
  EngagementTaskLearningScienceBasedTargetsExpertsAdvice:
    'EngagementTaskLearningScienceBasedTargetsExpertsAdvice',
  EngagementTaskLearningScienceBasedTargetsFaq:
    'EngagementTaskLearningScienceBasedTargetsFAQ',
  EngagementTaskLearningScienceBasedTargetsIntro:
    'EngagementTaskLearningScienceBasedTargetsIntro',
  EngagementTaskLearningScienceBasedTargetsNextSteps:
    'EngagementTaskLearningScienceBasedTargetsNextSteps',
  EngagementTaskLearningScienceBasedTargetsSubmit:
    'EngagementTaskLearningScienceBasedTargetsSubmit',
  EngagementTaskSbtCommitmentIntroSteps:
    'EngagementTaskSbtCommitmentIntroSteps',
  EngagementTaskSbtGhgMeasurementIntroSteps:
    'EngagementTaskSbtGhgMeasurementIntroSteps',
  EngagementTaskSbtProgressIntroSteps: 'EngagementTaskSbtProgressIntroSteps',
  EngagementTaskSetTargetConfirmSection:
    'EngagementTaskSetTargetConfirmSection',
  EngagementTaskSetTargetIntroSteps: 'EngagementTaskSetTargetIntroSteps',
  EngagementTaskSustainabilityIntroSteps:
    'EngagementTaskSustainabilityIntroSteps',
  EngagementTaskVmWarePlanofRecordIntroSteps:
    'EngagementTaskVMWarePlanofRecordIntroSteps',
} as const;

export type GQEngagementTaskContainerComponentId =
  (typeof GQEngagementTaskContainerComponentId)[keyof typeof GQEngagementTaskContainerComponentId];
export type GQEngagementTaskContainerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
};

export type GQEngagementTaskContainerInputForAdmin = {
  componentId?: InputMaybe<GQEngagementTaskContainerComponentId>;
  containers: Array<GQEngagementTaskSubContainerInputForAdmin>;
  description?: InputMaybe<Scalars['String']['input']>;
  editable: Scalars['Boolean']['input'];
  eyebrowText?: InputMaybe<Scalars['String']['input']>;
  headerImage?: InputMaybe<GQEngagementTaskImageId>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label: Scalars['String']['input'];
  questions: Array<GQEngagementTaskQuestionInputForAdmin>;
  sidebarStepLabelOverride?: InputMaybe<Scalars['String']['input']>;
  statusJson?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQEngagementTaskDeployStatus = GQIdInterface & {
  __typename?: 'EngagementTaskDeployStatus';
  company: GQCompany;
  engagementTaskBatch: GQEngagementTaskBatch;
  id: Scalars['ID']['output'];
  latestContactEmail: Maybe<Scalars['String']['output']>;
  latestContactName: Maybe<Scalars['String']['output']>;
  latestEmailSent: Maybe<GQEmailSent>;
  success: Scalars['Boolean']['output'];
};

export type GQEngagementTaskEdge = {
  __typename?: 'EngagementTaskEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQEngagementTask>;
};

export const GQEngagementTaskImageId = {
  BriefcaseBrimmingWithGrass: 'BriefcaseBrimmingWithGrass',
  ClimateBuildingBlocks: 'ClimateBuildingBlocks',
  ClimateCurve: 'ClimateCurve',
  MeasuringGif: 'MeasuringGif',
  Target: 'Target',
  ToolsForClimateChange: 'ToolsForClimateChange',
} as const;

export type GQEngagementTaskImageId =
  (typeof GQEngagementTaskImageId)[keyof typeof GQEngagementTaskImageId];
export const GQEngagementTaskInput = {
  ExpenseCategories: 'expenseCategories',
  FacilityName: 'facilityName',
  ProposeTargetFilters: 'proposeTargetFilters',
  ProposeTargetIntensityType: 'proposeTargetIntensityType',
  ProposeTargetSimpleTimeseries: 'proposeTargetSimpleTimeseries',
  ReportingYear: 'reportingYear',
} as const;

export type GQEngagementTaskInput =
  (typeof GQEngagementTaskInput)[keyof typeof GQEngagementTaskInput];
export type GQEngagementTaskLimitedProfile = {
  __typename?: 'EngagementTaskLimitedProfile';
  id: Scalars['ID']['output'];
  rootCompany: GQCompany;
  rootCompanyId: Scalars['ID']['output'];
};

export type GQEngagementTaskQuestion = GQIdInterface & {
  __typename?: 'EngagementTaskQuestion';
  answerIsPrivate: Scalars['Boolean']['output'];
  componentId: GQEngagementTaskQuestionComponentId;
  deletable: Scalars['Boolean']['output'];
  description: Maybe<Scalars['String']['output']>;
  editable: Scalars['Boolean']['output'];
  engagementTaskConfigId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Maybe<Scalars['String']['output']>;
  questionKey: Scalars['String']['output'];
  statusJson: Scalars['JSONString']['output'];
  videoLink: Maybe<Scalars['String']['output']>;
  whyCollectThis: Maybe<Scalars['String']['output']>;
};

export const GQEngagementTaskQuestionComponentId = {
  EngagementTaskAcceptedProposeTargetInput:
    'EngagementTaskAcceptedProposeTargetInput',
  EngagementTaskAllocateEmissionsMethodInput:
    'EngagementTaskAllocateEmissionsMethodInput',
  EngagementTaskAllocateEmissionsMethodV2Input:
    'EngagementTaskAllocateEmissionsMethodV2Input',
  EngagementTaskCarbonMeasurementPlanInput:
    'EngagementTaskCarbonMeasurementPlanInput',
  EngagementTaskCeeBuildingsInput: 'EngagementTaskCeeBuildingsInput',
  EngagementTaskCeeBusinessAirMilesInput:
    'EngagementTaskCeeBusinessAirMilesInput',
  EngagementTaskCeeBusinessAirSpendCurrencyInput:
    'EngagementTaskCeeBusinessAirSpendCurrencyInput',
  EngagementTaskCeeBusinessAirSpendInput:
    'EngagementTaskCeeBusinessAirSpendInput',
  EngagementTaskCeeBusinessGroundMilesInput:
    'EngagementTaskCeeBusinessGroundMilesInput',
  EngagementTaskCeeCloudInput: 'EngagementTaskCeeCloudInput',
  EngagementTaskCeeCloudProvidersInput: 'EngagementTaskCeeCloudProvidersInput',
  EngagementTaskCeeComputationNonCloudInput:
    'EngagementTaskCeeComputationNonCloudInput',
  EngagementTaskCeeEstimationOutputsInput:
    'EngagementTaskCeeEstimationOutputsInput',
  EngagementTaskCeeOfficeDaysPerMonthInput:
    'EngagementTaskCeeOfficeDaysPerMonthInput',
  EngagementTaskCleanPowerDescriptionInput:
    'EngagementTaskCleanPowerDescriptionInput',
  EngagementTaskCompanyGrowthStageSelectField:
    'EngagementTaskCompanyGrowthStageSelectField',
  EngagementTaskCompanyIndustryInput: 'EngagementTaskCompanyIndustryInput',
  EngagementTaskCompanyStructureSelectField:
    'EngagementTaskCompanyStructureSelectField',
  EngagementTaskCountryAutocompleteField:
    'EngagementTaskCountryAutocompleteField',
  EngagementTaskDecarbonizationPlanSelectField:
    'EngagementTaskDecarbonizationPlanSelectField',
  EngagementTaskDiversityInput: 'EngagementTaskDiversityInput',
  EngagementTaskEmissionAndContextAnswer:
    'EngagementTaskEmissionAndContextAnswer',
  EngagementTaskEmployeeSurveyKindsMultiSelect:
    'EngagementTaskEmployeeSurveyKindsMultiSelect',
  EngagementTaskEvaluateProposedTargetInput:
    'EngagementTaskEvaluateProposedTargetInput',
  EngagementTaskExpenseCategoryEmissionsInput:
    'EngagementTaskExpenseCategoryEmissionsInput',
  EngagementTaskExpenseCategoryShareMethodInput:
    'EngagementTaskExpenseCategoryShareMethodInput',
  EngagementTaskExpensesInput: 'EngagementTaskExpensesInput',
  EngagementTaskFinanceImpAuditSlider: 'EngagementTaskFinanceImpAuditSlider',
  EngagementTaskFinanceImpRecap: 'EngagementTaskFinanceImpRecap',
  EngagementTaskFinanceImpSlider: 'EngagementTaskFinanceImpSlider',
  EngagementTaskFinanceImpValue: 'EngagementTaskFinanceImpValue',
  EngagementTaskFootprintDisclosureOutputs:
    'EngagementTaskFootprintDisclosureOutputs',
  EngagementTaskFootprintShareSingleSelect:
    'EngagementTaskFootprintShareSingleSelect',
  EngagementTaskGhgMethodologySelectField:
    'EngagementTaskGhgMethodologySelectField',
  EngagementTaskHasEnvironmentalTeamInput:
    'EngagementTaskHasEnvironmentalTeamInput',
  EngagementTaskHaveCommittedSbtiTargetsInput:
    'EngagementTaskHaveCommittedSbtiTargetsInput',
  EngagementTaskHaveMeasuredEmissionsInput:
    'EngagementTaskHaveMeasuredEmissionsInput',
  EngagementTaskHaveMeasuredEmissionsSbtGhgV2Input:
    'EngagementTaskHaveMeasuredEmissionsSbtGhgV2Input',
  EngagementTaskHaveMeasuredEmissionsV2Input:
    'EngagementTaskHaveMeasuredEmissionsV2Input',
  EngagementTaskHaveMeasuredEmissionsV2MustMeasureInput:
    'EngagementTaskHaveMeasuredEmissionsV2MustMeasureInput',
  EngagementTaskHelpNeededMeasuringEmissions:
    'EngagementTaskHelpNeededMeasuringEmissions',
  EngagementTaskHelpNeededSettingTargets:
    'EngagementTaskHelpNeededSettingTargets',
  EngagementTaskHelpNeededSustainabilityProgram:
    'EngagementTaskHelpNeededSustainabilityProgram',
  EngagementTaskImportReportInput: 'EngagementTaskImportReportInput',
  EngagementTaskLearningBusinessActivities:
    'EngagementTaskLearningBusinessActivities',
  EngagementTaskLearningCarbonAccounting:
    'EngagementTaskLearningCarbonAccounting',
  EngagementTaskLearningCorporateClimateActionBenefits:
    'EngagementTaskLearningCorporateClimateActionBenefits',
  EngagementTaskLearningCorporateScienceBasedTargetsReduceYourEmissions:
    'EngagementTaskLearningCorporateScienceBasedTargetsReduceYourEmissions',
  EngagementTaskLearningCorporateScienceBasedTargetsStep2:
    'EngagementTaskLearningCorporateScienceBasedTargetsStep2',
  EngagementTaskLearningCorporateScienceBasedTargetsStep3:
    'EngagementTaskLearningCorporateScienceBasedTargetsStep3',
  EngagementTaskLearningEmissionsFactors:
    'EngagementTaskLearningEmissionsFactors',
  EngagementTaskLearningEmployees: 'EngagementTaskLearningEmployees',
  EngagementTaskLearningEngagingEmployeesTactics:
    'EngagementTaskLearningEngagingEmployeesTactics',
  EngagementTaskLearningEngagingLeadershipTactics:
    'EngagementTaskLearningEngagingLeadershipTactics',
  EngagementTaskLearningIdentifyingStakeholders:
    'EngagementTaskLearningIdentifyingStakeholders',
  EngagementTaskLearningLaunchingAClimateProgramAmbitionLevelSpectrum:
    'EngagementTaskLearningLaunchingAClimateProgramAmbitionLevelSpectrum',
  EngagementTaskLearningLaunchingAClimateProgramLongTerm:
    'EngagementTaskLearningLaunchingAClimateProgramLongTerm',
  EngagementTaskLearningLaunchingAClimateProgramSupport:
    'EngagementTaskLearningLaunchingAClimateProgramSupport',
  EngagementTaskLearningLaunchingAClimateProgramVoluntaryReporting:
    'EngagementTaskLearningLaunchingAClimateProgramVoluntaryReporting',
  EngagementTaskLearningMarketTrends: 'EngagementTaskLearningMarketTrends',
  EngagementTaskLearningOperationalCosts:
    'EngagementTaskLearningOperationalCosts',
  EngagementTaskLearningRisk: 'EngagementTaskLearningRisk',
  EngagementTaskLearningScienceBasedTargetsClimateScienceBasics:
    'EngagementTaskLearningScienceBasedTargetsClimateScienceBasics',
  EngagementTaskLearningScienceBasedTargetsDefinition3:
    'EngagementTaskLearningScienceBasedTargetsDefinition3',
  EngagementTaskLearningScienceBasedTargetsDevelopTargetKeyQuestions:
    'EngagementTaskLearningScienceBasedTargetsDevelopTargetKeyQuestions',
  EngagementTaskLearningScienceBasedTargetsNumberOfCompaniesCommitted:
    'EngagementTaskLearningScienceBasedTargetsNumberOfCompaniesCommitted',
  EngagementTaskLearningScopeOne: 'EngagementTaskLearningScopeOne',
  EngagementTaskLearningScopeThree: 'EngagementTaskLearningScopeThree',
  EngagementTaskLearningScopeTwo: 'EngagementTaskLearningScopeTwo',
  EngagementTaskLongTermNetZeroSelectField:
    'EngagementTaskLongTermNetZeroSelectField',
  EngagementTaskMeasurementProjectMultiSelect:
    'EngagementTaskMeasurementProjectMultiSelect',
  EngagementTaskMeasuringEmissionsSpectrum:
    'EngagementTaskMeasuringEmissionsSpectrum',
  EngagementTaskModifyProposedTargetInput:
    'EngagementTaskModifyProposedTargetInput',
  EngagementTaskMonthYearInput: 'EngagementTaskMonthYearInput',
  EngagementTaskNullComponent: 'EngagementTaskNullComponent',
  EngagementTaskNumberField: 'EngagementTaskNumberField',
  EngagementTaskNumberFieldOptional: 'EngagementTaskNumberFieldOptional',
  EngagementTaskNumberFieldWithBooleanSelect:
    'EngagementTaskNumberFieldWithBooleanSelect',
  EngagementTaskParentalLeaveInput: 'EngagementTaskParentalLeaveInput',
  EngagementTaskPercentageNumberField: 'EngagementTaskPercentageNumberField',
  EngagementTaskPleeEstimationOutputsInput:
    'EngagementTaskPleeEstimationOutputsInput',
  EngagementTaskPrimaryIndustryOfOperationsSelectField:
    'EngagementTaskPrimaryIndustryOfOperationsSelectField',
  EngagementTaskPrimarySectorOperationsSelectField:
    'EngagementTaskPrimarySectorOperationsSelectField',
  EngagementTaskProviderVerifiedFootprintInput:
    'EngagementTaskProviderVerifiedFootprintInput',
  EngagementTaskReductionInitiativesInput:
    'EngagementTaskReductionInitiativesInput',
  EngagementTaskReductionTargetsInput: 'EngagementTaskReductionTargetsInput',
  EngagementTaskSasbIndustrySelectField:
    'EngagementTaskSASBIndustrySelectField',
  EngagementTaskSasbSelectField: 'EngagementTaskSASBSelectField',
  EngagementTaskSbtEngagementStatusInput:
    'EngagementTaskSbtEngagementStatusInput',
  EngagementTaskScope1MethodologySelectField:
    'EngagementTaskScope1MethodologySelectField',
  EngagementTaskScope2MethodologySelectField:
    'EngagementTaskScope2MethodologySelectField',
  EngagementTaskScope3ByCategoryInput: 'EngagementTaskScope3ByCategoryInput',
  EngagementTaskScopeBoxInput: 'EngagementTaskScopeBoxInput',
  EngagementTaskSelectCurrencyField: 'EngagementTaskSelectCurrencyField',
  EngagementTaskSfdrUnGlobalCompactComplianceMultiSelect:
    'EngagementTaskSfdrUnGlobalCompactComplianceMultiSelect',
  EngagementTaskSfdrUnGlobalCompactViolationsYesNoMaybeInput:
    'EngagementTaskSfdrUnGlobalCompactViolationsYesNoMaybeInput',
  EngagementTaskShareMethodInput: 'EngagementTaskShareMethodInput',
  EngagementTaskShareMethodV2Input: 'EngagementTaskShareMethodV2Input',
  EngagementTaskShortTermReductionTargetSelectField:
    'EngagementTaskShortTermReductionTargetSelectField',
  EngagementTaskSingleCountryAutocompleteField:
    'EngagementTaskSingleCountryAutocompleteField',
  EngagementTaskSustainabilityCommitmentsInput:
    'EngagementTaskSustainabilityCommitmentsInput',
  EngagementTaskSustainabilityInitiativesWithDetailsInput:
    'EngagementTaskSustainabilityInitiativesWithDetailsInput',
  EngagementTaskTextField: 'EngagementTaskTextField',
  EngagementTaskTextFieldMultiline: 'EngagementTaskTextFieldMultiline',
  EngagementTaskVideo: 'EngagementTaskVideo',
  EngagementTaskYesNoInput: 'EngagementTaskYesNoInput',
  EngagementTaskYesNoMaybeInput: 'EngagementTaskYesNoMaybeInput',
} as const;

export type GQEngagementTaskQuestionComponentId =
  (typeof GQEngagementTaskQuestionComponentId)[keyof typeof GQEngagementTaskQuestionComponentId];
export type GQEngagementTaskQuestionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type GQEngagementTaskQuestionInputForAdmin = {
  answerIsPrivate: Scalars['Boolean']['input'];
  componentId: GQEngagementTaskQuestionComponentId;
  deletable: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  editable: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  questionKey: Scalars['String']['input'];
  statusJson?: InputMaybe<Scalars['JSONString']['input']>;
  videoLink?: InputMaybe<Scalars['String']['input']>;
  whyCollectThis?: InputMaybe<Scalars['String']['input']>;
};

export type GQEngagementTaskQuestionSelectOption = {
  __typename?: 'EngagementTaskQuestionSelectOption';
  componentId: GQEngagementTaskQuestionComponentId;
  description: Maybe<Scalars['String']['output']>;
  engagementTaskConfigId: Scalars['ID']['output'];
  engagementTaskConfigName: Scalars['String']['output'];
  questionKey: Scalars['String']['output'];
  taskConfigsWithQuestion: Array<Scalars['String']['output']>;
};

export type GQEngagementTaskSubContainerInputForAdmin = {
  componentId?: InputMaybe<GQEngagementTaskContainerComponentId>;
  description?: InputMaybe<Scalars['String']['input']>;
  editable: Scalars['Boolean']['input'];
  eyebrowText?: InputMaybe<Scalars['String']['input']>;
  headerImage?: InputMaybe<GQEngagementTaskImageId>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label: Scalars['String']['input'];
  questions: Array<GQEngagementTaskQuestionInputForAdmin>;
  sidebarStepLabelOverride?: InputMaybe<Scalars['String']['input']>;
  statusJson?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQEnqueueBackgroundJobInput = {
  args: Scalars['JSONString']['input'];
  kind: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  queueName?: InputMaybe<GQBackgroundJobQueueName>;
};

export type GQEnqueueBackgroundJobPayload = {
  __typename?: 'EnqueueBackgroundJobPayload';
  job: GQBackgroundJob;
};

export type GQEraseReportComponentInput = {
  componentId: Scalars['String']['input'];
};

export type GQEraseReportComponentPayload = {
  __typename?: 'EraseReportComponentPayload';
  affectedOrgs: Array<GQOrganization>;
  affectedReportConfigs: Array<GQReportConfig>;
  affectedReports: Array<GQReport>;
  componentId: Scalars['String']['output'];
  deletedAnswersCount: Scalars['Int']['output'];
  deletedQuestionsCount: Scalars['Int']['output'];
};

export const GQEsgdcGhgMethodologyType = {
  ActivityBased: 'ActivityBased',
  DirectEmissionsOrSupplierBased: 'DirectEmissionsOrSupplierBased',
  Other: 'Other',
  SpendBased: 'SpendBased',
} as const;

export type GQEsgdcGhgMethodologyType =
  (typeof GQEsgdcGhgMethodologyType)[keyof typeof GQEsgdcGhgMethodologyType];
export const GQEstimationMethodology = {
  Ceda: 'CEDA',
  Useeio: 'USEEIO',
} as const;

export type GQEstimationMethodology =
  (typeof GQEstimationMethodology)[keyof typeof GQEstimationMethodology];
export const GQEstimationStatus = {
  Estimated: 'Estimated',
  JustEmissions: 'JustEmissions',
  JustHoldings: 'JustHoldings',
  None: 'None',
} as const;

export type GQEstimationStatus =
  (typeof GQEstimationStatus)[keyof typeof GQEstimationStatus];
export type GQEvaluatedEmissionsModelsResults = {
  __typename?: 'EvaluatedEmissionsModelsResults';
  emissionsModelVersions: Array<GQEmissionsModelVersion>;
};

export type GQExampleTraceByFingerprint = {
  __typename?: 'ExampleTraceByFingerprint';
  emissionsModelEvaluationId: Scalars['String']['output'];
  exampleTrace: Scalars['String']['output'];
  fingerprint: Scalars['String']['output'];
};

export type GQExampleTracesForFingerprints = {
  __typename?: 'ExampleTracesForFingerprints';
  bartColumnDetails: Array<GQBartColumnDetails>;
  emissionsModelVariableDetails: Array<GQEmissionsModelVariableDetails>;
  referenceValueDetails: Array<GQReferenceValueDetails>;
  tracesByFingerprint: Array<Scalars['UntypedData']['output']>;
};

export type GQExclusionRuleTargetedQuantity = {
  __typename?: 'ExclusionRuleTargetedQuantity';
  measure: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type GQExecuteExtractSchemasInput = {
  userUploadCandidateIds: Array<Scalars['ID']['input']>;
};

export type GQExecuteExtractSchemasPayload = {
  __typename?: 'ExecuteExtractSchemasPayload';
  success: Scalars['Boolean']['output'];
};

export type GQExpenseCategoryData = {
  __typename?: 'ExpenseCategoryData';
  beaCode: Scalars['String']['output'];
  /** @deprecated This is no longer used */
  inputQuantity: Scalars['Float']['output'];
  /** @deprecated This is no longer used */
  inputUnit: Scalars['String']['output'];
  kgco2e: Scalars['Float']['output'];
};

export type GQExportMeasurementTestSuiteBundlePayload = {
  __typename?: 'ExportMeasurementTestSuiteBundlePayload';
  downloadUrl: Scalars['String']['output'];
};

export type GQExportMeasurementTestSuitePayload = {
  __typename?: 'ExportMeasurementTestSuitePayload';
  downloadUrl: Scalars['String']['output'];
};

export type GQExtendedBart = {
  __typename?: 'ExtendedBart';
  bartInstanceId: Scalars['ID']['output'];
  dashboardFileMetadataId: Maybe<Scalars['ID']['output']>;
  extensionKind: Maybe<Scalars['String']['output']>;
  fileMetadataId: Scalars['ID']['output'];
};

export type GQExternalReportAnswer = {
  __typename?: 'ExternalReportAnswer';
  answer: Scalars['JSONString']['output'];
  answerType: Scalars['String']['output'];
  externalReportQuestionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  publicDisclosure: GQPublicDisclosure;
  question: GQExternalReportQuestion;
  questionPhrasing: Maybe<Scalars['String']['output']>;
  watershedQualityScore: Maybe<GQDisclosureQualityScore>;
};

export type GQExternalReportAnswerEdge = {
  __typename?: 'ExternalReportAnswerEdge';
  cursor: Scalars['String']['output'];
  node: GQExternalReportAnswer;
};

export type GQExternalReportAnswersConnection = {
  __typename?: 'ExternalReportAnswersConnection';
  edges: Array<Maybe<GQExternalReportAnswerEdge>>;
  pageInfo: GQPageInfo;
};

export type GQExternalReportQuestion = {
  __typename?: 'ExternalReportQuestion';
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
  reportQuestionIdentifier: GQReportQuestionIdentifier;
  type: GQExternalReportTypeWithRevision;
};

export type GQExternalReportRevenueIntensityKgco2eByScope = {
  __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
  id: Scalars['ID']['output'];
  scope1: Maybe<Scalars['Float']['output']>;
  scope2Location: Maybe<Scalars['Float']['output']>;
  scope2Market: Maybe<Scalars['Float']['output']>;
  scope3: Maybe<Scalars['Float']['output']>;
};

export const GQExternalReportSectionKind = {
  E1: 'E1',
  E2: 'E2',
  E3: 'E3',
  E4: 'E4',
  E5: 'E5',
  G1: 'G1',
  Governance: 'Governance',
  Metrics: 'Metrics',
  Risk: 'Risk',
  S1: 'S1',
  S2: 'S2',
  S3: 'S3',
  S4: 'S4',
  Strategy: 'Strategy',
  Targets: 'Targets',
  Taxonomy: 'Taxonomy',
} as const;

export type GQExternalReportSectionKind =
  (typeof GQExternalReportSectionKind)[keyof typeof GQExternalReportSectionKind];
export type GQExternalReportSectionUrl = {
  __typename?: 'ExternalReportSectionUrl';
  id: Scalars['ID']['output'];
  sectionKind: GQExternalReportSectionKind;
  urlFragment: Scalars['String']['output'];
};

export const GQExternalReportType = {
  BCorp: 'BCorp',
  Carb: 'CARB',
  Cdp: 'CDP',
  Csrd: 'CSRD',
  Djsi: 'DJSI',
  Edci: 'EDCI',
  Edgar: 'EDGAR',
  Esg: 'ESG',
  Impact: 'Impact',
  Msci: 'MSCI',
  Marketing: 'Marketing',
  Sasb: 'SASB',
  Sb253: 'SB253',
  Sbt: 'SBT',
  Sec: 'SEC',
  Secr: 'SECR',
  Sfdr: 'SFDR',
  Sustainalytics: 'Sustainalytics',
  Tcfd: 'TCFD',
  Unfccc: 'UNFCCC',
  UkTender: 'UkTender',
} as const;

export type GQExternalReportType =
  (typeof GQExternalReportType)[keyof typeof GQExternalReportType];
export type GQExternalReportTypeWithRevision = {
  __typename?: 'ExternalReportTypeWithRevision';
  id: Scalars['ID']['output'];
  questions: Array<GQExternalReportQuestion>;
  revision: Scalars['String']['output'];
  specUrl: Maybe<Scalars['String']['output']>;
  type: GQExternalReportType;
};

export type GQExtractorRun = GQIdInterface &
  GQQueryRunInterface & {
    __typename?: 'ExtractorRun';
    activityDataExtractor: Maybe<GQActivityDataExtractor>;
    createdAt: Scalars['DateTime']['output'];
    error: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    jobError: Maybe<GQSerializableError>;
    state: GQBackgroundJobState;
  };

export type GQFacilitiesAdditionInput = {
  buildingName: Scalars['String']['input'];
  buildingOwnerIds: Array<Scalars['ID']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  utilityOwnerIds: Array<Scalars['ID']['input']>;
};

export type GQFacilitiesFilterInput = {
  field: GQFacilityFieldInput;
  operator: GQDataGridFilterOperator;
  value: Array<Scalars['String']['input']>;
};

export type GQFacilitiesMetadata = {
  __typename?: 'FacilitiesMetadata';
  buildingsDataset: Maybe<GQDataset>;
  utilitiesDataset: Maybe<GQDataset>;
};

export type GQFacilitiesPreview = {
  __typename?: 'FacilitiesPreview';
  preview: Array<GQFacility>;
  totalCount: Scalars['Int']['output'];
};

export type GQFacilitiesSdiPayload = {
  __typename?: 'FacilitiesSdiPayload';
  workflowId: Scalars['String']['output'];
};

export type GQFacilitiesSetup = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'FacilitiesSetup';
    id: Scalars['ID']['output'];
  };

export type GQFacilitiesSortInput = {
  direction: GQDataGridSortDirection;
  field: GQFacilityFieldInput;
};

export const GQFacilitiesType = {
  Buildings: 'Buildings',
  Utilities: 'Utilities',
} as const;

export type GQFacilitiesType =
  (typeof GQFacilitiesType)[keyof typeof GQFacilitiesType];
export type GQFacility = GQBuildingInterface &
  GQIdInterface & {
    __typename?: 'Facility';
    buildingKind: Maybe<Scalars['String']['output']>;
    buildingName: Scalars['String']['output'];
    /** @deprecated Use periods instead, removing this soon */
    buildingPeriods: Array<GQBuildingPeriod>;
    buildingSubkind: Maybe<Scalars['String']['output']>;
    buildingUniqueId: Scalars['String']['output'];
    buildingWithUtilities: GQBuildingWithUtilities;
    city: Maybe<Scalars['String']['output']>;
    country: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['DateTime']['output'];
    editState: GQBuildingEditState;
    endYearMonth: Maybe<Scalars['YearMonth']['output']>;
    id: Scalars['ID']['output'];
    isWithoutNaturalGas: Maybe<Scalars['Boolean']['output']>;
    isWithoutRefrigerants: Maybe<Scalars['Boolean']['output']>;
    latestApprovedVersion: Maybe<GQBuildingForDiff>;
    leaseType: Maybe<GQBuildingLeaseType>;
    periods: Array<GQBuildingPeriod>;
    postalCode: Maybe<Scalars['String']['output']>;
    revision: Maybe<Scalars['Int']['output']>;
    sizeUnit: Maybe<GQBuildingSizeUnit>;
    startYearMonth: Maybe<Scalars['YearMonth']['output']>;
    state: Maybe<Scalars['String']['output']>;
    streetAddress: Maybe<Scalars['String']['output']>;
    streetAddress2: Maybe<Scalars['String']['output']>;
    supportingDocuments: Array<GQSupportingDocument>;
    tasks: Array<GQUserUploadTask>;
    updatedAt: Scalars['DateTime']['output'];
  };

export type GQFacilityTasksArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQFacilityConnection = {
  __typename?: 'FacilityConnection';
  edges: Array<Maybe<GQFacilityEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFacilityEdge = {
  __typename?: 'FacilityEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFacility>;
};

export type GQFacilityFieldInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  type: GQFacilityFieldType;
};

export const GQFacilityFieldType = {
  BuildingIdentifier: 'BuildingIdentifier',
  BuildingKind: 'BuildingKind',
  BuildingTaskName: 'BuildingTaskName',
  City: 'City',
  Country: 'Country',
  CustomField: 'CustomField',
  LeaseType: 'LeaseType',
  OrgUnit: 'OrgUnit',
  Sqft: 'Sqft',
  State: 'State',
  UserUploadTask: 'UserUploadTask',
  UtilitiesTaskName: 'UtilitiesTaskName',
} as const;

export type GQFacilityFieldType =
  (typeof GQFacilityFieldType)[keyof typeof GQFacilityFieldType];
export type GQFeatureFlag = {
  __typename?: 'FeatureFlag';
  activated: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  enabledForAllOrgs: Scalars['Boolean']['output'];
  enabledForOrgs: GQOrgConnection;
  id: Scalars['ID']['output'];
  name: GQFlags;
};

export type GQFeatureFlagConnection = {
  __typename?: 'FeatureFlagConnection';
  edges: Array<Maybe<GQFeatureFlagEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFeatureFlagEdge = {
  __typename?: 'FeatureFlagEdge';
  cursor: Scalars['String']['output'];
  node: GQFeatureFlag;
};

export type GQFeatureFlagsDiff = {
  __typename?: 'FeatureFlagsDiff';
  add: Array<GQFlags>;
  remove: Array<Scalars['String']['output']>;
};

export const GQFeedbackSurfaceArea = {
  Benchmarks: 'Benchmarks',
  Calculation: 'Calculation',
  ClimateProgramTimeline: 'ClimateProgramTimeline',
  DataGovernance: 'DataGovernance',
  DataLineage: 'DataLineage',
  Drilldown: 'Drilldown',
  Facilities: 'Facilities',
  Finance: 'Finance',
  FootprintReview: 'FootprintReview',
  Homepage: 'Homepage',
  LearningHub: 'LearningHub',
  MethodologyCustomization: 'MethodologyCustomization',
  Redux: 'Redux',
  Reporting: 'Reporting',
  SupplierEmissionsFactors: 'SupplierEmissionsFactors',
  SupplyChain: 'SupplyChain',
  Test: 'Test',
} as const;

export type GQFeedbackSurfaceArea =
  (typeof GQFeedbackSurfaceArea)[keyof typeof GQFeedbackSurfaceArea];
export type GQFetchIntegrationDataInput = {
  activityDataTableId: Scalars['ID']['input'];
};

export type GQFetchIntegrationDataPayload = {
  __typename?: 'FetchIntegrationDataPayload';
  job: GQFetchIntegrationDataWorkflow;
};

export type GQFetchIntegrationDataWorkflow = {
  __typename?: 'FetchIntegrationDataWorkflow';
  id: Scalars['String']['output'];
};

export const GQFileCategory = {
  Admin: 'Admin',
  Automapper: 'Automapper',
  BuildingSurvey: 'BuildingSurvey',
  BusinessActivityRecordTable: 'BusinessActivityRecordTable',
  DataRegistryTable: 'DataRegistryTable',
  DrilldownExport: 'DrilldownExport',
  EmissionsModel: 'EmissionsModel',
  FileExport: 'FileExport',
  Fixture: 'Fixture',
  GlobalReferenceData: 'GlobalReferenceData',
  Integration: 'Integration',
  MarketplaceCustomer: 'MarketplaceCustomer',
  MeasurementArtifact: 'MeasurementArtifact',
  NetworkedDataTemporaryDataTransfer: 'NetworkedDataTemporaryDataTransfer',
  Public: 'Public',
  RawVendors: 'RawVendors',
  ReferenceData: 'ReferenceData',
  Report: 'Report',
  Supplier: 'Supplier',
  SupplierActivity: 'SupplierActivity',
  SupplierAttachment: 'SupplierAttachment',
  SuppliersCache: 'SuppliersCache',
  System: 'System',
  Table: 'Table',
  TcfdReportUpload: 'TcfdReportUpload',
  User: 'User',
  UserBackup: 'UserBackup',
} as const;

export type GQFileCategory =
  (typeof GQFileCategory)[keyof typeof GQFileCategory];
export type GQFileDataLineage = {
  __typename?: 'FileDataLineage';
  batType: Maybe<Scalars['String']['output']>;
  fileMetadataId: Scalars['String']['output'];
  isTruncated: Scalars['Boolean']['output'];
  rows: Array<Scalars['JSONString']['output']>;
};

export type GQFileFeedEmbeddedSession = {
  __typename?: 'FileFeedEmbeddedSession';
  embeddedSessionId: Scalars['String']['output'];
  oneSchemaEmbedUserJwt: Scalars['String']['output'];
};

export type GQFileMetadata = GQContextRelationInterface &
  GQIdInterface & {
    __typename?: 'FileMetadata';
    adminUrl: Scalars['String']['output'];
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQFootprintQuestion
      | GQMeasurementProject
      | GQMeasurementVerificationItemQuestion
    >;
    category: GQFileCategory;
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    description: Maybe<Scalars['String']['output']>;
    directory: Scalars['String']['output'];
    gcloudStorageUrl: Scalars['String']['output'];
    hidden: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    remotePath: Scalars['String']['output'];
    remoteWritten: Scalars['Boolean']['output'];
    revisionRoot: Maybe<Scalars['ID']['output']>;
    revisionRootFileMetadata: Maybe<GQFileMetadata>;
    signedParquetUrl: Scalars['String']['output'];
    size: Scalars['Int53']['output'];
    uploader: Maybe<GQUser | GQWatershedEmployee>;
    userUploadTask: Maybe<GQUserUploadTask>;
  };

export type GQFileMetadataConnection = {
  __typename?: 'FileMetadataConnection';
  edges: Array<Maybe<GQFileMetadataEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'FileMetadata' and its cursor. */
export type GQFileMetadataEdge = {
  __typename?: 'FileMetadataEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFileMetadata>;
};

export type GQFileMetadataFilterInput = {
  categories?: InputMaybe<Array<GQFileCategory>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export const GQFilterConjunction = {
  AndConjunction: 'andConjunction',
} as const;

export type GQFilterConjunction =
  (typeof GQFilterConjunction)[keyof typeof GQFilterConjunction];
export type GQFilterExpressionGroup = {
  __typename?: 'FilterExpressionGroup';
  conjunction: GQFilterConjunction;
  expressions: Array<GQFilterExpressionPrimitive>;
};

export type GQFilterExpressionGroupInput = {
  conjunction: GQFilterConjunction;
  expressions: Array<GQFilterExpressionPrimitiveInput>;
};

export type GQFilterExpressionGroupWithNewFilters = {
  __typename?: 'FilterExpressionGroupWithNewFilters';
  conjunction: GQFilterConjunction;
  expressions: Array<GQFilterExpressionPrimitiveWithNewFilters>;
};

export type GQFilterExpressionGroupWithNewFiltersInput = {
  conjunction: GQFilterConjunction;
  expressions: Array<GQFilterExpressionPrimitiveWithNewFiltersInput>;
};

export type GQFilterExpressionPrimitive = {
  __typename?: 'FilterExpressionPrimitive';
  field: GQFilterFieldLegacy;
  operator: GQFilterOperator;
  value: Array<Scalars['String']['output']>;
};

export type GQFilterExpressionPrimitiveInput = {
  field: GQFilterFieldLegacy;
  operator: GQFilterOperator;
  value: Array<Scalars['String']['input']>;
};

export type GQFilterExpressionPrimitiveWithNewFilters = {
  __typename?: 'FilterExpressionPrimitiveWithNewFilters';
  field: Scalars['String']['output'];
  operator: GQFilterOperator;
  value: Array<Scalars['String']['output']>;
};

export type GQFilterExpressionPrimitiveWithNewFiltersInput = {
  field: Scalars['String']['input'];
  operator: GQFilterOperator;
  value: Array<Scalars['UntypedData']['input']>;
};

/** Primitive fields map directly to attributes of the footprint. */
export const GQFilterFieldLegacy = {
  BuildingName: 'buildingName',
  BusinessCategory: 'businessCategory',
  BusinessSubcategory: 'businessSubcategory',
  Description: 'description',
  ElectricityType: 'electricityType',
  FootprintKind: 'footprintKind',
  GhgCategoryId: 'ghgCategoryId',
  GhgScope: 'ghgScope',
  Location: 'location',
  LocationCountry: 'locationCountry',
  Product: 'product',
  Vendor: 'vendor',
} as const;

export type GQFilterFieldLegacy =
  (typeof GQFilterFieldLegacy)[keyof typeof GQFilterFieldLegacy];
/**
 * These fields map directly to known attributes of the footprint in the new
 * Measurement Artifacts Interface, in contrast with fields custom to customers.
 * Listed in the same order as the fields in FilterField (but with the new
 * names), with the exception of productMarket - that has been removed.
 */
export const GQFilterFieldWatershed = {
  BuildingName: 'buildingName',
  CategoryId: 'categoryId',
  CompanyId: 'companyId',
  Description: 'description',
  ElectricityType: 'electricityType',
  FootprintKind: 'footprintKind',
  GhgCategoryId: 'ghgCategoryId',
  GhgScope: 'ghgScope',
  InputUnit: 'inputUnit',
  IsSupplierSpecific: 'isSupplierSpecific',
  Location: 'location',
  LocationCity: 'locationCity',
  LocationCountry: 'locationCountry',
  LocationState: 'locationState',
  Product: 'product',
  SubcategoryId: 'subcategoryId',
  Vendor: 'vendor',
} as const;

export type GQFilterFieldWatershed =
  (typeof GQFilterFieldWatershed)[keyof typeof GQFilterFieldWatershed];
export const GQFilterOperator = {
  In: 'in',
  NotIn: 'notIn',
} as const;

export type GQFilterOperator =
  (typeof GQFilterOperator)[keyof typeof GQFilterOperator];
export type GQFilterableReducibleFootprintAggregate = {
  __typename?: 'FilterableReducibleFootprintAggregate';
  footprintRowCategoryIds: Array<Scalars['String']['output']>;
  footprintRowSubcategoryIds: Array<Scalars['String']['output']>;
  ghgCategory: Scalars['String']['output'];
  locationCity: Scalars['String']['output'];
  locationCountry: Scalars['String']['output'];
  locationGrid: Scalars['String']['output'];
  locationState: Scalars['String']['output'];
  quantityKgco2e: Scalars['Float']['output'];
  reductionKgco2e: Scalars['Float']['output'];
};

export type GQFinanceAggregateMetric = {
  __typename?: 'FinanceAggregateMetric';
  additionalInfo: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  isMissingInputs: Scalars['Boolean']['output'];
  metric: GQFinanceMetric;
  name: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  unit: Maybe<Scalars['String']['output']>;
};

export type GQFinanceAggregateMetricRecord = {
  __typename?: 'FinanceAggregateMetricRecord';
  assetId: Scalars['String']['output'];
  assetName: Scalars['String']['output'];
  colorIndex: Scalars['Int']['output'];
  isEstimate: Maybe<Scalars['Boolean']['output']>;
  value: Maybe<GQFinanceAggregateMetric>;
};

export type GQFinanceAnyMetric =
  | GQFinanceAggregateMetric
  | GQFinanceStringMetric;

export type GQFinanceAssetHolding = {
  __typename?: 'FinanceAssetHolding';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetClass: GQFundAssetClass;
  assetYearId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  currencyConversionRate: Scalars['Float']['output'];
  emissionsYearId: Maybe<Scalars['ID']['output']>;
  fund: Maybe<GQFund>;
  fundId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  outstandingAmountNative: Maybe<Scalars['Float']['output']>;
  outstandingAmountNativeQ1: Maybe<Scalars['Float']['output']>;
  outstandingAmountNativeQ2: Maybe<Scalars['Float']['output']>;
  outstandingAmountNativeQ3: Maybe<Scalars['Float']['output']>;
  outstandingAmountNativeQ4: Maybe<Scalars['Float']['output']>;
  premiumNative: Maybe<Scalars['Float']['output']>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
  year: Maybe<Scalars['Int']['output']>;
  yearOfInitialInvestment: Maybe<Scalars['Int']['output']>;
};

export const GQFinanceAssetIigccStatus = {
  AchievingNetZero: 'AchievingNetZero',
  Aligned: 'Aligned',
  Aligning: 'Aligning',
  CommittedToAligning: 'CommittedToAligning',
  NotAligned: 'NotAligned',
} as const;

export type GQFinanceAssetIigccStatus =
  (typeof GQFinanceAssetIigccStatus)[keyof typeof GQFinanceAssetIigccStatus];
export type GQFinanceAssetYear = {
  __typename?: 'FinanceAssetYear';
  assetCommercialRealEstateId: Maybe<Scalars['String']['output']>;
  assetCorporateId: Maybe<Scalars['String']['output']>;
  assetGroupId: Maybe<Scalars['String']['output']>;
  buildingEstimationErr: Maybe<Scalars['String']['output']>;
  buildingSizeNative: Maybe<Scalars['Int']['output']>;
  cloudSpendNative: Maybe<Scalars['Float']['output']>;
  companyEstimationErr: Maybe<Scalars['String']['output']>;
  currencyCode: GQCurrencyCode;
  currencyConversionRate: Scalars['Float']['output'];
  emissionsYearId: Maybe<Scalars['ID']['output']>;
  estimationStatus: Maybe<Scalars['String']['output']>;
  flightMiles: Maybe<Scalars['Int']['output']>;
  headcount: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  percentEmployeesWfh: Maybe<Scalars['Float']['output']>;
  percentRenewable: Maybe<Scalars['Float']['output']>;
  revenueNative: Maybe<Scalars['Float']['output']>;
  revenueSourceEmissionsYearId: Maybe<Scalars['ID']['output']>;
  scenario: Maybe<Scalars['String']['output']>;
  scope1OverridePcaf: Maybe<Scalars['Int']['output']>;
  scope1OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope2LocationOverridePcaf: Maybe<Scalars['Int']['output']>;
  scope2LocationOverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope2MarketOverridePcaf: Maybe<Scalars['Int']['output']>;
  scope2MarketOverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope3OverrideKind: Maybe<GQScope3OverrideKind>;
  scope3OverridePcaf: Maybe<Scalars['Int']['output']>;
  scope3OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope301OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope302OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope303OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope304OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope305OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope306OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope307OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope308OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope309OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope310OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope311OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope312OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope313OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope314OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope315OverrideTco2e: Maybe<Scalars['Float']['output']>;
  surveyPortalUrl: Maybe<Scalars['String']['output']>;
  surveyStatus: Maybe<GQCompanySurveyStatus>;
  totalMwh: Maybe<Scalars['Float']['output']>;
  valueNative: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

export type GQFinanceAssetYearNew = {
  __typename?: 'FinanceAssetYearNew';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetCommercialRealEstateId: Maybe<Scalars['ID']['output']>;
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  assetPersonalMotorVehicleInsuranceId: Maybe<Scalars['ID']['output']>;
  assetSovereignBondId: Maybe<Scalars['ID']['output']>;
  buildingEstimationErr: Maybe<Scalars['String']['output']>;
  buildingSizeNative: Maybe<Scalars['Int']['output']>;
  cloudSpendNative: Maybe<Scalars['Float']['output']>;
  committedToNearTermSbtAlignedNetZero: Maybe<Scalars['Boolean']['output']>;
  companyEstimationErr: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  currencyConversionRate: Scalars['Float']['output'];
  demonstratingYoyEmissionsInLineWithNetZero: Maybe<
    Scalars['Boolean']['output']
  >;
  emissionsYearId: Maybe<Scalars['ID']['output']>;
  estimationMethodology: Maybe<GQEstimationMethodology>;
  flightMiles: Maybe<Scalars['Int']['output']>;
  headcount: Maybe<Scalars['Int']['output']>;
  iciAlignmentOverride: Maybe<GQIciAlignmentStatus>;
  id: Scalars['ID']['output'];
  initiatedDecarbonizationPlan: Maybe<Scalars['Boolean']['output']>;
  measuringMaterialScope3Emissions: Maybe<Scalars['Boolean']['output']>;
  measuringScope1And2Emissions: Maybe<Scalars['Boolean']['output']>;
  orgId: Scalars['ID']['output'];
  percentEmployeesWfh: Maybe<Scalars['Float']['output']>;
  percentRenewable: Maybe<Scalars['Float']['output']>;
  revenueNative: Maybe<Scalars['Float']['output']>;
  revenueSourceEmissionsYearId: Maybe<Scalars['ID']['output']>;
  scenario: Maybe<Scalars['String']['output']>;
  scope1OverridePcaf: Maybe<Scalars['Int']['output']>;
  scope1OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope2LocationOverridePcaf: Maybe<Scalars['Int']['output']>;
  scope2LocationOverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope2MarketOverridePcaf: Maybe<Scalars['Int']['output']>;
  scope2MarketOverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope3OverrideKind: Maybe<GQScope3OverrideKind>;
  scope3OverridePcaf: Maybe<Scalars['Int']['output']>;
  scope3OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope301OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope302OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope303OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope304OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope305OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope306OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope307OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope308OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope309OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope310OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope311OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope312OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope313OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope314OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope315OverrideTco2e: Maybe<Scalars['Float']['output']>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  totalMwh: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Date']['output'];
  valueNative: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

export type GQFinanceAssetYearOverYearEdge = {
  __typename?: 'FinanceAssetYearOverYearEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceAssetsYearOverYear = {
  __typename?: 'FinanceAssetsYearOverYear';
  edges: Array<Maybe<GQFinanceAssetYearOverYearEdge>>;
  pageInfo: GQPageInfo;
};

export const GQFinanceAuditAction = {
  ApiRequest: 'APIRequest',
  AssetPageUpdate: 'AssetPageUpdate',
  BulkImport: 'BulkImport',
  BulkUpdateAssetFromFlags: 'BulkUpdateAssetFromFlags',
  CompanyMatchUpdate: 'CompanyMatchUpdate',
  CreateAssetDialog: 'CreateAssetDialog',
  EngagementTask: 'EngagementTask',
  EstimateEmissions: 'EstimateEmissions',
  SidebarUpdate: 'SidebarUpdate',
  WatershedUpdate: 'WatershedUpdate',
} as const;

export type GQFinanceAuditAction =
  (typeof GQFinanceAuditAction)[keyof typeof GQFinanceAuditAction];
export type GQFinanceAuditFileInfoInput = {
  fileMetadataId?: InputMaybe<Scalars['ID']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  financeImportId: Scalars['ID']['input'];
};

export type GQFinanceAuditReportForAssetYearInput = {
  assetId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQFinanceAuditReportForAssetYearPayload = {
  __typename?: 'FinanceAuditReportForAssetYearPayload';
  jobId: Scalars['ID']['output'];
};

export type GQFinanceAutomaticReminder = {
  __typename?: 'FinanceAutomaticReminder';
  deadline: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  reminderConfig: Scalars['JSONString']['output'];
  reminderType: GQFinanceReminderType;
};

export type GQFinanceChangelogConnection = {
  __typename?: 'FinanceChangelogConnection';
  edges: Array<Maybe<GQFinanceChangelogEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFinanceChangelogEdge = {
  __typename?: 'FinanceChangelogEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFinanceChangelogEntry>;
};

export type GQFinanceChangelogEntry = {
  __typename?: 'FinanceChangelogEntry';
  assetId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  eventKind: Scalars['String']['output'];
  fundId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lineItems: Array<GQFinanceChangelogLineItem>;
  objectLabel: Scalars['String']['output'];
  userDisplayName: Scalars['String']['output'];
};

export type GQFinanceChangelogLineItem = {
  __typename?: 'FinanceChangelogLineItem';
  field: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  newValue: Maybe<Scalars['String']['output']>;
  oldValue: Maybe<Scalars['String']['output']>;
};

export type GQFinanceChart = {
  __typename?: 'FinanceChart';
  activeObjectType: Maybe<GQFinanceChartActiveObjectType>;
  chartKind: GQFinanceChartKind;
  data: Scalars['UntypedData']['output'];
  highchartsData: Scalars['UntypedData']['output'];
  highchartsKind: GQFinanceHighchartsChartKind;
  highchartsUnit: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isWideFormat: Maybe<Scalars['Boolean']['output']>;
  metric: Maybe<GQFinanceChartMetric>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  tooltipText: Maybe<Scalars['String']['output']>;
};

export const GQFinanceChartActiveObjectType = {
  Asset: 'Asset',
  Fund: 'Fund',
  FundCategory: 'FundCategory',
  FundGroup: 'FundGroup',
} as const;

export type GQFinanceChartActiveObjectType =
  (typeof GQFinanceChartActiveObjectType)[keyof typeof GQFinanceChartActiveObjectType];
export const GQFinanceChartKind = {
  FinanceColumnChart: 'FinanceColumnChart',
  FinanceStackedBarChart: 'FinanceStackedBarChart',
  FinancedEmissionsByScope: 'FinancedEmissionsByScope',
  FinancedEmissionsBySector: 'FinancedEmissionsBySector',
  TotalEmissionsByScope: 'TotalEmissionsByScope',
} as const;

export type GQFinanceChartKind =
  (typeof GQFinanceChartKind)[keyof typeof GQFinanceChartKind];
export const GQFinanceChartMetric = {
  AssetCount: 'assetCount',
  EconomicIntensity: 'economicIntensity',
  EconomicIntensityDisplay: 'economicIntensityDisplay',
  FinancedEmissions: 'financedEmissions',
  HoldingCount: 'holdingCount',
  OutstandingAmountDisplay: 'outstandingAmountDisplay',
  OutstandingAmountTarget: 'outstandingAmountTarget',
  Pcaf: 'pcaf',
  RevenueIntensity: 'revenueIntensity',
  RevenueIntensityDisplay: 'revenueIntensityDisplay',
  TotalEmissions: 'totalEmissions',
  Waci: 'waci',
  WaciDisplay: 'waciDisplay',
} as const;

export type GQFinanceChartMetric =
  (typeof GQFinanceChartMetric)[keyof typeof GQFinanceChartMetric];
export type GQFinanceColumn = {
  __typename?: 'FinanceColumn';
  columnConfiguration: Maybe<
    GQGridColumnConfigurationMultiSelect | GQGridColumnConfigurationSingleSelect
  >;
  columnFormat: GQGridColumnFormat;
  description: Maybe<Scalars['String']['output']>;
  field: Scalars['String']['output'];
  headerName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  width: Maybe<Scalars['Int']['output']>;
};

export type GQFinanceDataFlag = {
  __typename?: 'FinanceDataFlag';
  assetYearId: Maybe<Scalars['ID']['output']>;
  category: GQFinanceDataFlagCategory;
  checkKind: GQFinanceDataFlagCheck;
  columns: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  finSnapAssetId: Maybe<Scalars['ID']['output']>;
  finSnapFundId: Maybe<Scalars['ID']['output']>;
  finSnapHoldingId: Maybe<Scalars['ID']['output']>;
  finSnapYearId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  objectKind: GQFinanceDataFlagObjectKind;
  rowData: Scalars['UntypedData']['output'];
  stepsToResolve: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export const GQFinanceDataFlagCategory = {
  NoFinancedEmissions: 'NoFinancedEmissions',
  NoTotalEmissions: 'NoTotalEmissions',
  PossibleDataImprovement: 'PossibleDataImprovement',
  PossibleDataIssue: 'PossibleDataIssue',
} as const;

export type GQFinanceDataFlagCategory =
  (typeof GQFinanceDataFlagCategory)[keyof typeof GQFinanceDataFlagCategory];
export const GQFinanceDataFlagCheck = {
  AnomalousAssetValue: 'AnomalousAssetValue',
  AnomalousAttributionFactor: 'AnomalousAttributionFactor',
  AnomalousMwhValue: 'AnomalousMwhValue',
  AnomalousOutstandingAmount: 'AnomalousOutstandingAmount',
  AnomalousRevenueMultiple: 'AnomalousRevenueMultiple',
  AnomalousRevenueValue: 'AnomalousRevenueValue',
  AnomalousTotalBuildingArea: 'AnomalousTotalBuildingArea',
  AnomalousYearOverYearAssetValue: 'AnomalousYearOverYearAssetValue',
  AnomalousYearOverYearFinancedEmissions:
    'AnomalousYearOverYearFinancedEmissions',
  AnomalousYearOverYearOutstandingAmount:
    'AnomalousYearOverYearOutstandingAmount',
  AnomalousYearOverYearRevenueValue: 'AnomalousYearOverYearRevenueValue',
  AnomalousYearOverYearTotalEmissions: 'AnomalousYearOverYearTotalEmissions',
  AssetInEmissionsIntensiveIndustryWithEstimatesNoOverrides:
    'AssetInEmissionsIntensiveIndustryWithEstimatesNoOverrides',
  AssetUsingAdjustmentCode: 'AssetUsingAdjustmentCode',
  BottomUpFootprintAvailable: 'BottomUpFootprintAvailable',
  CedaForAluminumSector: 'CedaForAluminumSector',
  CedaForPowerSector: 'CedaForPowerSector',
  CedaForWasteSector: 'CedaForWasteSector',
  ExternalReportAvailable: 'ExternalReportAvailable',
  FundHighEconomicIntensity: 'FundHighEconomicIntensity',
  FundHighWaciScope1And2: 'FundHighWaciScope1And2',
  FundHighWaciScope123: 'FundHighWaciScope123',
  HighQualityCdpReportAvailable: 'HighQualityCdpReportAvailable',
  InvestmentVolumeSignificantPortionOfAssetValue:
    'InvestmentVolumeSignificantPortionOfAssetValue',
  MissingAssetValue: 'MissingAssetValue',
  MissingBuildingKind: 'MissingBuildingKind',
  MissingCountry: 'MissingCountry',
  MissingEmissionsData: 'MissingEmissionsData',
  MissingEstimation: 'MissingEstimation',
  MissingIndustry: 'MissingIndustry',
  MissingInvestmentVolume: 'MissingInvestmentVolume',
  MissingNextYearAnnualData: 'MissingNextYearAnnualData',
  MissingNextYearHoldingData: 'MissingNextYearHoldingData',
  MissingOutstandingAmount: 'MissingOutstandingAmount',
  MissingRevenue: 'MissingRevenue',
  MissingTotalBuildingArea: 'MissingTotalBuildingArea',
  OutstandingAmountSignificantPortionOfAssetValue:
    'OutstandingAmountSignificantPortionOfAssetValue',
  Scope2MarketOverrideGreaterThanLocation:
    'Scope2MarketOverrideGreaterThanLocation',
  Scope3TotalOverrideZero: 'Scope3TotalOverrideZero',
  WatershedMeasurementAvailable: 'WatershedMeasurementAvailable',
} as const;

export type GQFinanceDataFlagCheck =
  (typeof GQFinanceDataFlagCheck)[keyof typeof GQFinanceDataFlagCheck];
export const GQFinanceDataFlagObjectKind = {
  Asset: 'Asset',
  AssetCorporate: 'AssetCorporate',
  AssetHolding: 'AssetHolding',
  AssetRealEstate: 'AssetRealEstate',
  AssetYear: 'AssetYear',
  Fund: 'Fund',
} as const;

export type GQFinanceDataFlagObjectKind =
  (typeof GQFinanceDataFlagObjectKind)[keyof typeof GQFinanceDataFlagObjectKind];
export type GQFinanceDisclosure = {
  __typename?: 'FinanceDisclosure';
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  currencyCode: Maybe<GQCurrencyCode>;
  disclosureDataId: Maybe<Scalars['ID']['output']>;
  disclosureId: Scalars['ID']['output'];
  disclosureSource: GQDisclosureType;
  isPrivate: Maybe<Scalars['Boolean']['output']>;
  reportingYear: Maybe<Scalars['Int']['output']>;
  revenueNative: Maybe<Scalars['Float']['output']>;
};

export type GQFinanceFeedbackInput = {
  message: Scalars['String']['input'];
};

export type GQFinanceFeedbackPayload = {
  __typename?: 'FinanceFeedbackPayload';
  status: Scalars['String']['output'];
};

export type GQFinanceFundYearOverYearEdge = {
  __typename?: 'FinanceFundYearOverYearEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceFundsYearOverYear = {
  __typename?: 'FinanceFundsYearOverYear';
  edges: Array<Maybe<GQFinanceFundYearOverYearEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFinanceHeader = {
  __typename?: 'FinanceHeader';
  fundExcluded: Scalars['Boolean']['output'];
  isAssetGroupItem: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export const GQFinanceHighchartsChartKind = {
  BarHorizontal: 'BarHorizontal',
  BarVertical: 'BarVertical',
  Meter: 'Meter',
  Pareto: 'Pareto',
  Pie: 'Pie',
  Treemap: 'Treemap',
} as const;

export type GQFinanceHighchartsChartKind =
  (typeof GQFinanceHighchartsChartKind)[keyof typeof GQFinanceHighchartsChartKind];
export type GQFinanceImport = {
  __typename?: 'FinanceImport';
  accepted: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  dataFormat: Maybe<Scalars['String']['output']>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  diff: Maybe<Scalars['UntypedData']['output']>;
  diffFileMetadataId: Maybe<Scalars['ID']['output']>;
  errorMessages: Scalars['UntypedData']['output'];
  fileMetadata: Maybe<GQFileMetadata>;
  fileMetadataId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  rawOutput: Scalars['UntypedData']['output'];
  recordsCreated: Maybe<Scalars['Int']['output']>;
  recordsUnchanged: Maybe<Scalars['Int']['output']>;
  recordsUpdated: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQFinanceInsightsBlock = GQIFinanceViewSection & {
  __typename?: 'FinanceInsightsBlock';
  id: Scalars['ID']['output'];
  insights: Array<GQFinanceInsightsBlock>;
  isWideFormat: Maybe<Scalars['Boolean']['output']>;
  renderInCard: Maybe<Scalars['Boolean']['output']>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type GQFinanceMeasurementProgressRow = {
  __typename?: 'FinanceMeasurementProgressRow';
  assetId: Scalars['String']['output'];
  assetName: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  contacts: Maybe<Array<GQSupplierContact>>;
  coverageEndDate: Maybe<Scalars['Date']['output']>;
  coverageStartDate: Maybe<Scalars['Date']['output']>;
  fundIds: Array<Scalars['String']['output']>;
  hasApprovedProgressRequest: Maybe<Scalars['Boolean']['output']>;
  hasRequestedFootprint: Maybe<Scalars['Boolean']['output']>;
  hasRequestedProgress: Maybe<Scalars['Boolean']['output']>;
  measuredFootprintDetails: Maybe<GQMeasuredFootprintDetails>;
  portcoFootprintReviewRequestMetadata: Maybe<
    Array<GQPortcoFootprintReviewRequestMetadata>
  >;
  projectName: Maybe<Scalars['String']['output']>;
  taskStatusDumps: Maybe<Array<GQTaskStatusDump>>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQFinanceMetadata = {
  __typename?: 'FinanceMetadata';
  anyAssetYearHasEstimationMethodology: Scalars['Boolean']['output'];
  hasSomeAssetCorporate: Scalars['Boolean']['output'];
};

export const GQFinanceMetric = {
  AssetCount: 'AssetCount',
  EconomicIntensity: 'EconomicIntensity',
  EconomicIntensityScope1And2: 'EconomicIntensityScope1And2',
  FinancedEmissions: 'FinancedEmissions',
  FinancedEmissionsScope1: 'FinancedEmissionsScope1',
  FinancedEmissionsScope1And2: 'FinancedEmissionsScope1And2',
  FinancedEmissionsScope2: 'FinancedEmissionsScope2',
  FinancedEmissionsScope3: 'FinancedEmissionsScope3',
  HoldingCount: 'HoldingCount',
  IciAlignment: 'IciAlignment',
  Pcaf: 'Pcaf',
  PcafScope1: 'PcafScope1',
  PcafScope2: 'PcafScope2',
  PcafScope3: 'PcafScope3',
  RevenueIntensity: 'RevenueIntensity',
  RevenueIntensityScope1: 'RevenueIntensityScope1',
  RevenueIntensityScope2: 'RevenueIntensityScope2',
  RevenueIntensityScope3: 'RevenueIntensityScope3',
  TotalEmissions: 'TotalEmissions',
  TotalEmissionsScope1: 'TotalEmissionsScope1',
  TotalEmissionsScope2: 'TotalEmissionsScope2',
  TotalEmissionsScope3: 'TotalEmissionsScope3',
  TotalOutstandingAmount: 'TotalOutstandingAmount',
  Waci: 'Waci',
  WaciScope1: 'WaciScope1',
  WaciScope2: 'WaciScope2',
  WaciScope3: 'WaciScope3',
} as const;

export type GQFinanceMetric =
  (typeof GQFinanceMetric)[keyof typeof GQFinanceMetric];
export type GQFinanceMetricBar = {
  __typename?: 'FinanceMetricBar';
  emptyMetrics: Array<GQFinanceAnyMetric>;
  id: Scalars['ID']['output'];
  isWideFormat: Maybe<Scalars['Boolean']['output']>;
  metrics: Array<GQFinanceAnyMetric>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type GQFinanceModelCardMetadataPayload = {
  __typename?: 'FinanceModelCardMetadataPayload';
  id: Scalars['ID']['output'];
  numAnnualData: Scalars['Int']['output'];
  numAssets: Scalars['Int']['output'];
  numContacts: Scalars['Int']['output'];
  numFunds: Scalars['Int']['output'];
  numHoldings: Scalars['Int']['output'];
};

export const GQFinanceReminderType = {
  FootprintFinalization: 'FootprintFinalization',
  MeasurementProject: 'MeasurementProject',
} as const;

export type GQFinanceReminderType =
  (typeof GQFinanceReminderType)[keyof typeof GQFinanceReminderType];
export type GQFinanceSavedView = {
  __typename?: 'FinanceSavedView';
  charts: Maybe<Array<Scalars['String']['output']>>;
  filters: Maybe<Scalars['UntypedData']['output']>;
  fundGroup: Maybe<Scalars['String']['output']>;
  fundId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  layout: Maybe<Scalars['String']['output']>;
  metrics: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  /** @deprecated Use charts length instead */
  showInsights: Scalars['Boolean']['output'];
  /** @deprecated Use metrics length instead */
  showMetrics: Scalars['Boolean']['output'];
  standardView: Maybe<GQFinanceStandardView>;
};

export type GQFinanceSectorEmissions = {
  __typename?: 'FinanceSectorEmissions';
  absoluteFinancedEmissionsTco2e: Maybe<Scalars['Int']['output']>;
  holdingNames: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type GQFinanceSettings = {
  __typename?: 'FinanceSettings';
  disabledFinanceDataFlags: Array<Scalars['String']['output']>;
  estimateDownstreamScope3: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type GQFinanceShowYourWorkData = {
  __typename?: 'FinanceShowYourWorkData';
  aggregateRows: Maybe<Scalars['UntypedData']['output']>;
  rowsWithOnlyFinancedEmissions: Maybe<Scalars['UntypedData']['output']>;
  subtitle: Maybe<Scalars['String']['output']>;
};

export type GQFinanceSnapshot = {
  __typename?: 'FinanceSnapshot';
  completedAt: Maybe<Scalars['DateTime']['output']>;
  config: Maybe<Scalars['UntypedData']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<GQUser>;
  executionStatus: GQFinanceSnapshotExecutionStatus;
  executionStatusMessage: Scalars['String']['output'];
  financedEmissionsTonnesCurrentYear: Maybe<Scalars['Float']['output']>;
  flagsPopulated: Maybe<Scalars['Boolean']['output']>;
  footprintSnapshotId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isStale: Maybe<Scalars['Boolean']['output']>;
  note: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  snapshotName: Maybe<Scalars['String']['output']>;
};

export type GQFinanceSnapshotAsset = {
  __typename?: 'FinanceSnapshotAsset';
  asset: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotAssetEdge = {
  __typename?: 'FinanceSnapshotAssetEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotAssetGroupedByCompany = {
  __typename?: 'FinanceSnapshotAssetGroupedByCompany';
  asset: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotAssetGroupedByCompanyEdge = {
  __typename?: 'FinanceSnapshotAssetGroupedByCompanyEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotAssets = {
  __typename?: 'FinanceSnapshotAssets';
  edges: Array<Maybe<GQFinanceSnapshotAssetEdge>>;
  id: Scalars['ID']['output'];
  pageInfo: GQPageInfo;
};

export type GQFinanceSnapshotAssetsGroupedByCompany = {
  __typename?: 'FinanceSnapshotAssetsGroupedByCompany';
  edges: Array<Maybe<GQFinanceSnapshotAssetGroupedByCompanyEdge>>;
  id: Scalars['ID']['output'];
  pageInfo: GQPageInfo;
};

export type GQFinanceSnapshotConfigV1Input = {
  estimateDownstreamScope3: Scalars['Boolean']['input'];
  estimationMethodologyOverride: GQFinanceSnapshotMethodology;
  version: Scalars['Int']['input'];
};

export const GQFinanceSnapshotExecutionStatus = {
  Error: 'ERROR',
  Processing: 'PROCESSING',
  Success: 'SUCCESS',
} as const;

export type GQFinanceSnapshotExecutionStatus =
  (typeof GQFinanceSnapshotExecutionStatus)[keyof typeof GQFinanceSnapshotExecutionStatus];
export type GQFinanceSnapshotFund = {
  __typename?: 'FinanceSnapshotFund';
  fund: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotFundEdge = {
  __typename?: 'FinanceSnapshotFundEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotFunds = {
  __typename?: 'FinanceSnapshotFunds';
  edges: Array<Maybe<GQFinanceSnapshotFundEdge>>;
  id: Scalars['ID']['output'];
  pageInfo: GQPageInfo;
};

export type GQFinanceSnapshotHoldingEdge = {
  __typename?: 'FinanceSnapshotHoldingEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotHoldings = {
  __typename?: 'FinanceSnapshotHoldings';
  edges: Array<Maybe<GQFinanceSnapshotHoldingEdge>>;
  id: Scalars['ID']['output'];
  pageInfo: GQPageInfo;
};

export const GQFinanceSnapshotMethodology = {
  Ceda: 'CEDA',
  Useeio: 'USEEIO',
  Custom: 'custom',
} as const;

export type GQFinanceSnapshotMethodology =
  (typeof GQFinanceSnapshotMethodology)[keyof typeof GQFinanceSnapshotMethodology];
export type GQFinanceSnapshotTag = {
  __typename?: 'FinanceSnapshotTag';
  columnConfiguration: Maybe<Scalars['UntypedData']['output']>;
  columnFormat: GQGridColumnFormat;
  description: Maybe<Scalars['String']['output']>;
  financeTag: GQFinanceTag;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tagType: GQFinanceTagType;
};

export const GQFinanceStandardView = {
  Asset: 'Asset',
  Fund: 'Fund',
  FundCategory: 'FundCategory',
  FundGroup: 'FundGroup',
  Overview: 'Overview',
  YearOverYear: 'YearOverYear',
} as const;

export type GQFinanceStandardView =
  (typeof GQFinanceStandardView)[keyof typeof GQFinanceStandardView];
export type GQFinanceStringMetric = {
  __typename?: 'FinanceStringMetric';
  additionalInfo: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  isMissingInputs: Scalars['Boolean']['output'];
  metric: GQFinanceMetric;
  name: Scalars['String']['output'];
  unit: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type GQFinanceTag = {
  __typename?: 'FinanceTag';
  columnConfiguration: Maybe<Scalars['UntypedData']['output']>;
  columnFormat: GQGridColumnFormat;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  tagCategory: Maybe<Scalars['String']['output']>;
  tagType: GQFinanceTagType;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Maybe<Scalars['String']['output']>;
};

export const GQFinanceTagType = {
  AssetTag: 'AssetTag',
  AssetYearTag: 'AssetYearTag',
  FundTag: 'FundTag',
  HoldingTag: 'HoldingTag',
} as const;

export type GQFinanceTagType =
  (typeof GQFinanceTagType)[keyof typeof GQFinanceTagType];
export type GQFinanceView = {
  __typename?: 'FinanceView';
  charts: Maybe<Array<GQFinanceChart>>;
  gridWorksheet: Maybe<GQFinanceWorksheet>;
  hasHoldings: Scalars['Boolean']['output'];
  header: GQFinanceHeader;
  metricBar: Maybe<GQFinanceMetricBar>;
  savedView: GQFinanceSavedView;
  standardView: GQFinanceStandardView;
};

export const GQFinanceViewSidebar = {
  AssetSidebar: 'AssetSidebar',
} as const;

export type GQFinanceViewSidebar =
  (typeof GQFinanceViewSidebar)[keyof typeof GQFinanceViewSidebar];
export const GQFinanceWaterfallChartType = {
  ExistingInvestments: 'ExistingInvestments',
  FinancedEmissions: 'FinancedEmissions',
  TotalEmissions: 'TotalEmissions',
} as const;

export type GQFinanceWaterfallChartType =
  (typeof GQFinanceWaterfallChartType)[keyof typeof GQFinanceWaterfallChartType];
export type GQFinanceWorksheet = GQIdInterface & {
  __typename?: 'FinanceWorksheet';
  columns: Array<GQFinanceWorksheetColumn>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  hiddenColumns: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  orgColumns: Array<GQFinanceColumn>;
  sortModel: Array<GQDataGridSortItem>;
  standardView: Maybe<GQFinanceStandardView>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQFinanceWorksheetColumn = {
  __typename?: 'FinanceWorksheetColumn';
  columnConfiguration: Maybe<
    GQGridColumnConfigurationMultiSelect | GQGridColumnConfigurationSingleSelect
  >;
  columnFormat: GQGridColumnFormat;
  description: Scalars['String']['output'];
  field: Scalars['String']['output'];
  financeWorksheetId: Maybe<Scalars['ID']['output']>;
  headerName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  width: Maybe<Scalars['Int']['output']>;
};

export type GQFinancialMappingGroupHistory = {
  __typename?: 'FinancialMappingGroupHistory';
  id: Scalars['ID']['output'];
  json: Scalars['JSONString']['output'];
};

export type GQFinancialsAccount = {
  __typename?: 'FinancialsAccount';
  accountId: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  displayAccountId: Scalars['String']['output'];
};

export type GQFinancialsReviewGroup = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'FinancialsReviewGroup';
    datasources: Array<GQDatasource>;
    id: Scalars['ID']['output'];
    measurementProject: GQMeasurementProject;
  };

export type GQFinancialsReviewItem = GQContextRelationInterface &
  GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'FinancialsReviewItem';
    account: GQFinancialsAccount;
    adminUrl: Scalars['String']['output'];
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQFootprintQuestion
      | GQMeasurementProject
      | GQMeasurementVerificationItemQuestion
    >;
    askingEmployee: Maybe<GQWatershedEmployee>;
    createdAt: Scalars['DateTime']['output'];
    discussion: Maybe<GQDataIssue>;
    exclusionReason: Maybe<Scalars['String']['output']>;
    group: GQFinancialsReviewGroup;
    id: Scalars['ID']['output'];
    initialIndustryClassification: Maybe<GQIndustryClassification>;
    initiallyIncluded: Maybe<Scalars['Boolean']['output']>;
    kind: GQFinancialsReviewItemKind;
    name: Scalars['String']['output'];
    prompt: Maybe<Scalars['String']['output']>;
    respondingUser: Maybe<GQUser>;
    status: GQFinancialsReviewItemStatus;
    userResponse: Maybe<Scalars['String']['output']>;
  };

export type GQFinancialsReviewItemConnection = {
  __typename?: 'FinancialsReviewItemConnection';
  edges: Array<Maybe<GQFinancialsReviewItemEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFinancialsReviewItemEdge = {
  __typename?: 'FinancialsReviewItemEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFinancialsReviewItem>;
};

export const GQFinancialsReviewItemKind = {
  FreeText: 'FreeText',
  YesNo: 'YesNo',
} as const;

export type GQFinancialsReviewItemKind =
  (typeof GQFinancialsReviewItemKind)[keyof typeof GQFinancialsReviewItemKind];
export const GQFinancialsReviewItemStatus = {
  UserApproved: 'UserApproved',
  UserRejected: 'UserRejected',
  UserReplied: 'UserReplied',
  WatershedActioned: 'WatershedActioned',
  WatershedDraft: 'WatershedDraft',
  WatershedSubmitted: 'WatershedSubmitted',
} as const;

export type GQFinancialsReviewItemStatus =
  (typeof GQFinancialsReviewItemStatus)[keyof typeof GQFinancialsReviewItemStatus];
export type GQFindDuckInput = {
  duckName: Scalars['String']['input'];
};

export type GQFindDuckPayload = {
  __typename?: 'FindDuckPayload';
  description: Scalars['String']['output'];
};

export type GQFixReduxValidationErrorsInput = {
  orgIds: Array<Scalars['ID']['input']>;
};

export type GQFixReduxValidationErrorsPayload = {
  __typename?: 'FixReduxValidationErrorsPayload';
  fixedPlans: Array<GQPlan>;
};

export const GQFlags = {
  ActivityDataAllActivityButton: 'ActivityDataAllActivityButton',
  ActivityDataListShowAllSchemas: 'ActivityDataListShowAllSchemas',
  ActivityDataMultipleMeasures: 'ActivityDataMultipleMeasures',
  ActivityDataPage: 'ActivityDataPage',
  AlwaysAllowTcfdQualitative: 'AlwaysAllowTcfdQualitative',
  AssetManagerCommercialLinesOfInsurance:
    'AssetManagerCommercialLinesOfInsurance',
  AssetManagerConfigureDataQualityFlags:
    'AssetManagerConfigureDataQualityFlags',
  AssetManagerCreateFootprintSnapshot: 'AssetManagerCreateFootprintSnapshot',
  AssetManagerCurrencyDisplay: 'AssetManagerCurrencyDisplay',
  AssetManagerDueDiligence: 'AssetManagerDueDiligence',
  AssetManagerFootprintAssemblyFlow: 'AssetManagerFootprintAssemblyFlow',
  AssetManagerFootprintTags: 'AssetManagerFootprintTags',
  AssetManagerGroupByCompanyName: 'AssetManagerGroupByCompanyName',
  AssetManagerPcafScore5ValueBasedEstimation:
    'AssetManagerPcafScore5ValueBasedEstimation',
  AssetManagerPersonalMotorVehicleInsurance:
    'AssetManagerPersonalMotorVehicleInsurance',
  AssetManagerRenameFunds: 'AssetManagerRenameFunds',
  AssetManagerScope2Subscopes: 'AssetManagerScope2Subscopes',
  AssetManagerSidebarCanCollapse: 'AssetManagerSidebarCanCollapse',
  AssetManagerSkipFlagCreation: 'AssetManagerSkipFlagCreation',
  AssetManagerSnapshotDeletion: 'AssetManagerSnapshotDeletion',
  AssetManagerSnapshotVerification: 'AssetManagerSnapshotVerification',
  AssetManagerSovereignBonds: 'AssetManagerSovereignBonds',
  AssetManagerSynchronousFootprintCreation:
    'AssetManagerSynchronousFootprintCreation',
  AssetManagerViewEdciFieldsPreset: 'AssetManagerViewEdciFieldsPreset',
  AssetManagerYearOverYearComparison: 'AssetManagerYearOverYearComparison',
  AutoRunFootprintOnUserUpload: 'AutoRunFootprintOnUserUpload',
  BenchmarkRevenueAtRisk: 'BenchmarkRevenueAtRisk',
  BiDrilldownAiQuerying: 'BiDrilldownAiQuerying',
  BiDrilldownCarbonRemovalAndCreditsMeasures:
    'BiDrilldownCarbonRemovalAndCreditsMeasures',
  BiDrilldownFacilitiesMeasures: 'BiDrilldownFacilitiesMeasures',
  BiDrilldownMultipleMeasures: 'BiDrilldownMultipleMeasures',
  BiDrilldownSupplierMeasures: 'BiDrilldownSupplierMeasures',
  BiShowCadtWarning: 'BiShowCadtWarning',
  BigRocksUiFinance: 'BigRocksUiFinance',
  BulkUploadTaskManagement: 'BulkUploadTaskManagement',
  ByPassChonkerChecker: 'ByPassChonkerChecker',
  CalculationEmEvalRefactorCut: 'CalculationEmEvalRefactorCut',
  CalculationEmEvalRefactorParity: 'CalculationEmEvalRefactorParity',
  CalculationEnableAlphaReleases: 'CalculationEnableAlphaReleases',
  CalculationEnablePipelinesPrototype: 'CalculationEnablePipelinesPrototype',
  CalculationKeepZerosInPipelineResults:
    'CalculationKeepZerosInPipelineResults',
  CalculationMethodologyCustomizationPage:
    'CalculationMethodologyCustomizationPage',
  CompleteFiscalYearInOverview: 'CompleteFiscalYearInOverview',
  ConsumerGoodsApparelNewMethodologies: 'ConsumerGoodsApparelNewMethodologies',
  ContractOverrideUpsellBenchmarks: 'ContractOverrideUpsellBenchmarks',
  CsrdAvoidedCleanPower: 'CsrdAvoidedCleanPower',
  CsrdDeloitteDma: 'CsrdDeloitteDma',
  CsrdDeloitteDmaInProgress: 'CsrdDeloitteDmaInProgress',
  CsrdEnableDemoAllSections: 'CsrdEnableDemoAllSections',
  CsrdEnableEmployeesUpload: 'CsrdEnableEmployeesUpload',
  CsrdEnableNetRevenueUpload: 'CsrdEnableNetRevenueUpload',
  CsrdEnableReportAnswerConflictNotification:
    'CsrdEnableReportAnswerConflictNotification',
  CsrdEnableReportingIntention: 'CsrdEnableReportingIntention',
  CsrdHafniaTopicMaterialitySelector: 'CsrdHafniaTopicMaterialitySelector',
  CsrdManualAnswerOverrideEnabled: 'CsrdManualAnswerOverrideEnabled',
  DangerousDoNotUseIntentionallyBreakThings:
    'DangerousDoNotUseIntentionallyBreakThings',
  DashboardCleanPowerAdvancedEstimation:
    'DashboardCleanPowerAdvancedEstimation',
  DataInfraBumpMaxExpressionDepthFootAssembly:
    'DataInfraBumpMaxExpressionDepthFootAssembly',
  DataInfraCreateTempTableInCleanPower: 'DataInfraCreateTempTableInCleanPower',
  DataInfraMoveQueriesOffXlServer: 'DataInfraMoveQueriesOffXlServer',
  DataInfraUseDuckDbComputeServer: 'DataInfraUseDuckDbComputeServer',
  DataIngestionApiDataRegistry: 'DataIngestionApiDataRegistry',
  DataIngestionColocatedDataCentersInFacilities:
    'DataIngestionColocatedDataCentersInFacilities',
  DataIngestionCsrdApprovalFlow: 'DataIngestionCsrdApprovalFlow',
  DataIngestionCtsSurvey: 'DataIngestionCtsSurvey',
  DataIngestionDataAuditTransformation: 'DataIngestionDataAuditTransformation',
  DataIngestionDisableExportFacilitiesDocs:
    'DataIngestionDisableExportFacilitiesDocs',
  DataIngestionDisableValueMappingTaskAssignment:
    'DataIngestionDisableValueMappingTaskAssignment',
  DataIngestionEditablePowerUsageEffectiveness:
    'DataIngestionEditablePowerUsageEffectiveness',
  DataIngestionFacilitiesApprovals: 'DataIngestionFacilitiesApprovals',
  DataIngestionFacilitiesExtraLargeMode:
    'DataIngestionFacilitiesExtraLargeMode',
  DataIngestionFacilitiesTracker: 'DataIngestionFacilitiesTracker',
  DataIngestionSeparateFacilitiesPermissions:
    'DataIngestionSeparateFacilitiesPermissions',
  DataIngestionSnowfall: 'DataIngestionSnowfall',
  DataIngestionTestCts: 'DataIngestionTestCts',
  DataIngestionValueMappingDisableVendorPreviews:
    'DataIngestionValueMappingDisableVendorPreviews',
  DataIngestionValueMappingForCustomersBetaDoNotUseWithoutPermission:
    'DataIngestionValueMappingForCustomersBetaDoNotUseWithoutPermission',
  DataIngestionValueMappingsVendorRowsExpanded:
    'DataIngestionValueMappingsVendorRowsExpanded',
  DefaultDrilldownChartsClosed: 'DefaultDrilldownChartsClosed',
  DisableContractorAccess: 'DisableContractorAccess',
  DisableDataGovernancePage: 'DisableDataGovernancePage',
  DisableFacilitiesGeocoding: 'DisableFacilitiesGeocoding',
  DisableFinancialMappingAiSuggestions: 'DisableFinancialMappingAiSuggestions',
  DisableFootprintGeneration: 'DisableFootprintGeneration',
  DisableSalesforceSyncSupportPermissions:
    'DisableSalesforceSyncSupportPermissions',
  EmissionsModelMinimizeVariableNameInSql:
    'EmissionsModelMinimizeVariableNameInSql',
  EnableBiodiversityModule: 'EnableBiodiversityModule',
  EnableCarbonCreditRetirementsModule: 'EnableCarbonCreditRetirementsModule',
  EnableCompanyMergingAdmin: 'EnableCompanyMergingAdmin',
  EnableCsrdMeasurementProject: 'EnableCsrdMeasurementProject',
  EnableDownloadAllButtonOnGovernancePage:
    'EnableDownloadAllButtonOnGovernancePage',
  EnableEditProductRulesAlways: 'EnableEditProductRulesAlways',
  EnableFacilitiesWasteForGhg: 'EnableFacilitiesWasteForGhg',
  EnableFacilitiesWaterForGhg: 'EnableFacilitiesWaterForGhg',
  EnableGeneralAnomalyDetection: 'EnableGeneralAnomalyDetection',
  EnableMultipleFootprints: 'EnableMultipleFootprints',
  EnablePreSetCustomTagsForBuildings: 'EnablePreSetCustomTagsForBuildings',
  EnablePublishFileUploadFacilities: 'EnablePublishFileUploadFacilities',
  EnableResourceInflowsOutflowsModule: 'EnableResourceInflowsOutflowsModule',
  EnableUserUploadCountsOnMeasurementPage:
    'EnableUserUploadCountsOnMeasurementPage',
  EnableUtilityFileScanning: 'EnableUtilityFileScanning',
  FootBridgeInProduct: 'FootBridgeInProduct',
  FootprintBenchmarksForNonCorporate: 'FootprintBenchmarksForNonCorporate',
  FootprintProductLevelEmissions: 'FootprintProductLevelEmissions',
  FootprintRowLimit: 'FootprintRowLimit',
  FootprintShowProductModels: 'FootprintShowProductModels',
  FootprintSuppliersPage: 'FootprintSuppliersPage',
  HideOverviewNav: 'HideOverviewNav',
  LimitLargeGroupDataLineage: 'LimitLargeGroupDataLineage',
  LocalizationDynamicStrings: 'LocalizationDynamicStrings',
  LogAuditEventsToWorkOs: 'LogAuditEventsToWorkOs',
  MarketplacePortfolioBuilder: 'MarketplacePortfolioBuilder',
  MeasurementIntegrationsArcadia: 'MeasurementIntegrationsArcadia',
  MeasurementIntegrationsMergeFinancials:
    'MeasurementIntegrationsMergeFinancials',
  MeasurementIntegrationsNetSuite: 'MeasurementIntegrationsNetSuite',
  MeasurementIntegrationsQuickBooks: 'MeasurementIntegrationsQuickBooks',
  MeasurementIntegrationsUrjanet: 'MeasurementIntegrationsUrjanet',
  MergePipelineResultsDuckDbServerUnion:
    'MergePipelineResultsDuckDbServerUnion',
  MultipleDatasourcesPerAdt: 'MultipleDatasourcesPerAdt',
  OrgStructure: 'OrgStructure',
  OrgStructureBulkUpload: 'OrgStructureBulkUpload',
  OrgStructureChangelog: 'OrgStructureChangelog',
  OverrideBlockedPipelineV2Dialog: 'OverrideBlockedPipelineV2Dialog',
  PricingFy25BlockAllScope3Temp: 'PricingFy25BlockAllScope3Temp',
  PricingFy25BlockScope1And2Temp: 'PricingFy25BlockScope1And2Temp',
  PricingFy25BlockScope3_1Temp: 'PricingFy25BlockScope3_1Temp',
  PricingFy25BlockScope3_2Temp: 'PricingFy25BlockScope3_2Temp',
  PricingFy25BlockScope3_3Temp: 'PricingFy25BlockScope3_3Temp',
  PricingFy25BlockScope3_4Temp: 'PricingFy25BlockScope3_4Temp',
  PricingFy25BlockScope3_5Temp: 'PricingFy25BlockScope3_5Temp',
  PricingFy25BlockScope3_6Temp: 'PricingFy25BlockScope3_6Temp',
  PricingFy25BlockScope3_7Temp: 'PricingFy25BlockScope3_7Temp',
  PricingFy25BlockScope3_8Temp: 'PricingFy25BlockScope3_8Temp',
  PricingFy25BlockScope3_9Temp: 'PricingFy25BlockScope3_9Temp',
  PricingFy25BlockScope3_10Temp: 'PricingFy25BlockScope3_10Temp',
  PricingFy25BlockScope3_11Temp: 'PricingFy25BlockScope3_11Temp',
  PricingFy25BlockScope3_12Temp: 'PricingFy25BlockScope3_12Temp',
  PricingFy25BlockScope3_13Temp: 'PricingFy25BlockScope3_13Temp',
  PricingFy25BlockScope3_14Temp: 'PricingFy25BlockScope3_14Temp',
  PricingFy25CsrdE2ModuleTemp: 'PricingFy25CsrdE2ModuleTemp',
  PricingFy25CsrdE3ModuleTemp: 'PricingFy25CsrdE3ModuleTemp',
  PricingFy25CsrdE4ModuleTemp: 'PricingFy25CsrdE4ModuleTemp',
  PricingFy25CsrdE5ModuleTemp: 'PricingFy25CsrdE5ModuleTemp',
  PricingFy25CsrdReportBuilderModuleTemp:
    'PricingFy25CsrdReportBuilderModuleTemp',
  PricingFy25CsrdSocialAndGovernanceModuleTemp:
    'PricingFy25CsrdSocialAndGovernanceModuleTemp',
  PricingFy25ReductionsModuleTemp: 'PricingFy25ReductionsModuleTemp',
  PricingFy25ReportingModuleTemp: 'PricingFy25ReportingModuleTemp',
  ReduxContributionsIntervalOptimization:
    'ReduxContributionsIntervalOptimization',
  ReduxDisableCustomTagsInContributions:
    'ReduxDisableCustomTagsInContributions',
  ReduxExperimentalCalculations: 'ReduxExperimentalCalculations',
  ReduxSupplierEngagementBySpend: 'ReduxSupplierEngagementBySpend',
  ReduxUseLocationBasedEmissions: 'ReduxUseLocationBasedEmissions',
  ReportingAttachmentsEnabled: 'ReportingAttachmentsEnabled',
  ReportingBulkAssignQuestions: 'ReportingBulkAssignQuestions',
  ReportingCustomReports: 'ReportingCustomReports',
  ReportingEnableCdpApi: 'ReportingEnableCdpApi',
  ReportingEnableNewClimateIntelligencePanel:
    'ReportingEnableNewClimateIntelligencePanel',
  ReportingEnableReportQuestionAssignment:
    'ReportingEnableReportQuestionAssignment',
  ReportingEnableTableView: 'ReportingEnableTableView',
  ReportingIgnoreCdpPlanRestrictions: 'ReportingIgnoreCdpPlanRestrictions',
  ReportingShowCdpApiIsDownWarnings: 'ReportingShowCdpApiIsDownWarnings',
  ReportingShowUniversalProgress: 'ReportingShowUniversalProgress',
  ReportingTextExportRichText: 'ReportingTextExportRichText',
  ReportingTextInputRichText: 'ReportingTextInputRichText',
  SendEmailsOnDemoOrTestOrg: 'SendEmailsOnDemoOrTestOrg',
  ShouldAllowPipelineResultFootprintKind:
    'ShouldAllowPipelineResultFootprintKind',
  ShouldUseExtraLargeDuckDbServer: 'ShouldUseExtraLargeDuckDbServer',
  ShouldUseTestDuckDbServer: 'ShouldUseTestDuckDbServer',
  ShowActivityDataInDataReview: 'ShowActivityDataInDataReview',
  ShowEmissionsSparklinesOnFootprintConfigPage:
    'ShowEmissionsSparklinesOnFootprintConfigPage',
  ShowTopSuppliersTableInFootprintOverview:
    'ShowTopSuppliersTableInFootprintOverview',
  ShowYourWorkTotalRow: 'ShowYourWorkTotalRow',
  SimulationEnableSavingScenarios: 'SimulationEnableSavingScenarios',
  SimulationEnableSupplierCleanPower: 'SimulationEnableSupplierCleanPower',
  SimulationShowSimulationUserInterface:
    'SimulationShowSimulationUserInterface',
  SimulationShowTemplatesTab: 'SimulationShowTemplatesTab',
  SupplierEngagementSurveys: 'SupplierEngagementSurveys',
  SupplierExpenseCategories: 'SupplierExpenseCategories',
  SupplierFootprintUncollapse: 'SupplierFootprintUncollapse',
  SupplyChainAdminCreateCompany: 'SupplyChainAdminCreateCompany',
  SupplyChainBulkSurveyResponseExport: 'SupplyChainBulkSurveyResponseExport',
  SupplyChainChartsConfigAdvancedControls:
    'SupplyChainChartsConfigAdvancedControls',
  SupplyChainCustomSupplierConfig: 'SupplyChainCustomSupplierConfig',
  SupplyChainDisableAutoMappingButton: 'SupplyChainDisableAutoMappingButton',
  SupplyChainDisableLearningTasks: 'SupplyChainDisableLearningTasks',
  SupplyChainEmissionsExport: 'SupplyChainEmissionsExport',
  SupplyChainEnableAutoMapping: 'SupplyChainEnableAutoMapping',
  SupplyChainGeneralMillsCompanyEngagementEmail:
    'SupplyChainGeneralMillsCompanyEngagementEmail',
  SupplyChainReopenApprovedTask: 'SupplyChainReopenApprovedTask',
  SupplyChainTargetsInitiativesExport: 'SupplyChainTargetsInitiativesExport',
  SupplyChainVendorMappingV2: 'SupplyChainVendorMappingV2',
  TempIndividualWritesForMappings: 'TempIndividualWritesForMappings',
  TurnOffCdpUpdateFeatureOnboardingDialog:
    'TurnOffCdpUpdateFeatureOnboardingDialog',
  Unknown: 'Unknown',
  UseBartForCustomIntensity: 'UseBartForCustomIntensity',
  UseBartForHeadcountIntensity: 'UseBartForHeadcountIntensity',
  UseBartForMonetaryIntensity: 'UseBartForMonetaryIntensity',
  UseDedicatedTaskQueueForTraceAddendum:
    'UseDedicatedTaskQueueForTraceAddendum',
  WatershedHiddenFootprintAssemblyTest: 'WatershedHiddenFootprintAssemblyTest',
} as const;

export type GQFlags = (typeof GQFlags)[keyof typeof GQFlags];
export type GQFootprintAdvancedFilters = {
  fieldFilters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
};

export type GQFootprintAnalysis = {
  __typename?: 'FootprintAnalysis';
  customFields: Array<Scalars['String']['output']>;
  customReports: Array<GQFootprintCustomReport>;
  energyConsumptionBreakdown: GQEnergyConsumptionBreakdown;
  exclusionRuleTargetedEmissions: Maybe<
    Array<GQFootprintExclusionRuleTargetedEmissions>
  >;
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  footprintKinds: Array<Scalars['String']['output']>;
  footprintVersion: Maybe<GQFootprintVersion>;
  id: Maybe<Scalars['String']['output']>;
  peerCompanyGroups: Array<GQPeerCompanyGroup>;
  reductionsContributionsData: GQFootprintContributionsData;
  snapshotDescription: Maybe<Scalars['String']['output']>;
  snapshotFootprintKindsMeta: Maybe<Array<GQSnapshotFootprintKindMeta>>;
  /** @deprecated Use FootprintSummary instead */
  timeseries: Maybe<GQFootprintAnalysisTimeSeries>;
  topVendors: GQFootprintAnalysisTopVendors;
  totalSpendUsd: Scalars['Float']['output'];
  workivaContributions: GQWorkivaContributionsPayload;
};

export type GQFootprintAnalysisEnergyConsumptionBreakdownArgs = {
  groupBy: Array<Scalars['String']['input']>;
  interval: Scalars['YMInterval']['input'];
  isGridOnly: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQFootprintAnalysisReductionsContributionsDataArgs = {
  columns: InputMaybe<Array<Scalars['String']['input']>>;
  enableReduxContributionsIntervalOptimization: InputMaybe<
    Scalars['Boolean']['input']
  >;
  footprintKind: Scalars['String']['input'];
  timeseriesKind: GQColumnDimension;
  useNewColumnarFormat: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQFootprintAnalysisSnapshotDescriptionArgs = {
  id: Scalars['String']['input'];
};

export type GQFootprintAnalysisTimeseriesArgs = {
  filters: InputMaybe<GQFootprintAnalysisFilters>;
  grouping: InputMaybe<Scalars['String']['input']>;
  kind: InputMaybe<GQAggregateKind>;
};

export type GQFootprintAnalysisTopVendorsArgs = {
  filters: InputMaybe<GQFootprintAnalysisFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};

export type GQFootprintAnalysisTotalSpendUsdArgs = {
  filters: InputMaybe<GQFootprintAnalysisFilters>;
  footprintKind: Scalars['String']['input'];
};

export type GQFootprintAnalysisWorkivaContributionsArgs = {
  footprintKind: Scalars['String']['input'];
  footprintSnapshotId: Scalars['String']['input'];
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQFootprintAnalysisAdmin = {
  __typename?: 'FootprintAnalysisAdmin';
  footprintCategories: Maybe<Array<Scalars['String']['output']>>;
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  footprintKinds: Array<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
};

export type GQFootprintAnalysisCustomIntensityDataPoint =
  GQCustomIntensityDataPoint & {
    __typename?: 'FootprintAnalysisCustomIntensityDataPoint';
    customIntensityId: Scalars['String']['output'];
    value: Maybe<Scalars['Float']['output']>;
  };

export type GQFootprintAnalysisFile = {
  __typename?: 'FootprintAnalysisFile';
  category: GQFileCategory;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  uploader: Maybe<GQUser>;
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQFootprintAnalysisFilters = {
  endYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  filters?: InputMaybe<GQFilterExpressionGroupInput>;
  inclusionKind?: InputMaybe<GQFootprintAnalysisInclusionKind>;
  startYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
};

export const GQFootprintAnalysisInclusionKind = {
  Gross: 'Gross',
  Net: 'Net',
  Removal: 'Removal',
} as const;

export type GQFootprintAnalysisInclusionKind =
  (typeof GQFootprintAnalysisInclusionKind)[keyof typeof GQFootprintAnalysisInclusionKind];
export type GQFootprintAnalysisTimeSeries = {
  __typename?: 'FootprintAnalysisTimeSeries';
  id: Scalars['String']['output'];
  kind: GQAggregateKind;
  series: Array<GQFootprintAnalysisTimeseriesSeries>;
};

export type GQFootprintAnalysisTimeSeriesDataPoint = {
  __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
  customIntensities: Maybe<Array<GQFootprintAnalysisCustomIntensityDataPoint>>;
  date: Scalars['Date']['output'];
  headcount: Maybe<Scalars['Int']['output']>;
  revenue: Maybe<Scalars['Int53']['output']>;
  value: Scalars['Int53']['output'];
};

export type GQFootprintAnalysisTimeseriesSeries = {
  __typename?: 'FootprintAnalysisTimeseriesSeries';
  data: Array<GQFootprintAnalysisTimeSeriesDataPoint>;
  label: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

export type GQFootprintAnalysisTopVendor = {
  __typename?: 'FootprintAnalysisTopVendor';
  proportion: Scalars['Float']['output'];
  series: GQFootprintAnalysisTimeseriesSeries;
  vendorInfo: GQVendor;
};

export type GQFootprintAnalysisTopVendors = {
  __typename?: 'FootprintAnalysisTopVendors';
  summarySentences: GQFootprintAnalysisTopVendorsSummarySentences;
  vendors: Array<GQFootprintAnalysisTopVendor>;
};

export type GQFootprintAnalysisTopVendorsSummarySentences = {
  __typename?: 'FootprintAnalysisTopVendorsSummarySentences';
  all: Scalars['String']['output'];
  netZero: Scalars['String']['output'];
  other: Scalars['String']['output'];
  sbt: Scalars['String']['output'];
};

export type GQFootprintAssemblyRun = GQIdInterface & {
  __typename?: 'FootprintAssemblyRun';
  createdAt: Scalars['DateTime']['output'];
  footprintSnapshotId: Maybe<Scalars['ID']['output']>;
  footprintVersion: Maybe<GQFootprintVersion>;
  generatedBy: Maybe<GQUser>;
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  source: GQFootprintGenerationSource;
  status: GQFootprintAssemblyRunStatus;
  workflowId: Maybe<Scalars['String']['output']>;
};

export const GQFootprintAssemblyRunStatus = {
  Canceled: 'Canceled',
  Failed: 'Failed',
  Running: 'Running',
  Success: 'Success',
} as const;

export type GQFootprintAssemblyRunStatus =
  (typeof GQFootprintAssemblyRunStatus)[keyof typeof GQFootprintAssemblyRunStatus];
export const GQFootprintAssemblyStep = {
  AssembledFootprintSnapshot: 'assembledFootprintSnapshot',
  CategoryRules: 'categoryRules',
  CleanPowerAppliedFootprintSnapshot: 'cleanPowerAppliedFootprintSnapshot',
  CleanPowerPurchases: 'cleanPowerPurchases',
  CustomTagging: 'customTagging',
  DataCleaning: 'dataCleaning',
  Deduplication: 'deduplication',
  DefaultTagging: 'defaultTagging',
  EmEvalPipelineResult: 'emEvalPipelineResult',
  ExclusionRules: 'exclusionRules',
  FinalFootprint: 'finalFootprint',
  MergePipelineResults: 'mergePipelineResults',
  MergedPipelineResultsWithTags: 'mergedPipelineResultsWithTags',
  RowRemoval: 'rowRemoval',
} as const;

export type GQFootprintAssemblyStep =
  (typeof GQFootprintAssemblyStep)[keyof typeof GQFootprintAssemblyStep];
export type GQFootprintAuditBundle = {
  __typename?: 'FootprintAuditBundle';
  createdAt: Scalars['DateTime']['output'];
  downloadSize: Maybe<Scalars['Int53']['output']>;
  downloadUrl: Maybe<Scalars['String']['output']>;
  footprintEmissionsKind: GQFootprintEmissionsKind;
  id: Scalars['String']['output'];
  inputJson: Maybe<Scalars['JSONString']['output']>;
};

export type GQFootprintBridgeData = {
  __typename?: 'FootprintBridgeData';
  footprintBridgeDates: Maybe<GQFootprintBridgeDates>;
  footprintBridgeDetails: Array<GQFootprintBridgeDetail>;
  footprintBridgeRecords: Array<GQFootprintBridgeRecord>;
};

export type GQFootprintBridgeDates = {
  __typename?: 'FootprintBridgeDates';
  newEndDate: Scalars['YearMonth']['output'];
  newStartDate: Scalars['YearMonth']['output'];
  oldEndDate: Scalars['YearMonth']['output'];
  oldStartDate: Scalars['YearMonth']['output'];
};

export type GQFootprintBridgeDetail = {
  __typename?: 'FootprintBridgeDetail';
  activityDelta: Maybe<Scalars['Float']['output']>;
  emissionsCounterfactual: Maybe<Scalars['Float']['output']>;
  emissionsNew: Maybe<Scalars['Float']['output']>;
  emissionsOld: Maybe<Scalars['Float']['output']>;
  groupByValue: Scalars['String']['output'];
  inputQuantityNew: Maybe<Scalars['Float']['output']>;
  inputQuantityOld: Maybe<Scalars['Float']['output']>;
  inputUnit: Maybe<Scalars['String']['output']>;
  kgco2eQuantityNew: Maybe<Scalars['Float']['output']>;
  kgco2eQuantityOld: Maybe<Scalars['Float']['output']>;
  methodologyDelta: Maybe<Scalars['Float']['output']>;
};

export const GQFootprintBridgeGroupByColumn = {
  CategoryId: 'categoryId',
  GhgCategoryId: 'ghgCategoryId',
} as const;

export type GQFootprintBridgeGroupByColumn =
  (typeof GQFootprintBridgeGroupByColumn)[keyof typeof GQFootprintBridgeGroupByColumn];
export type GQFootprintBridgeRecord = {
  __typename?: 'FootprintBridgeRecord';
  activityDeltaNegative: Maybe<Scalars['Float']['output']>;
  activityDeltaPositive: Maybe<Scalars['Float']['output']>;
  aggregateKind: GQAggregateKind;
  emissionsNew: Maybe<Scalars['Float']['output']>;
  emissionsOld: Maybe<Scalars['Float']['output']>;
  groupBy: GQFootprintBridgeGroupByColumn;
  groupByValue: Scalars['String']['output'];
  methodologyDeltaNegative: Maybe<Scalars['Float']['output']>;
  methodologyDeltaPositive: Maybe<Scalars['Float']['output']>;
};

export type GQFootprintCategoryRule = GQIdInterface & {
  __typename?: 'FootprintCategoryRule';
  author: Maybe<GQUser>;
  filters: Array<GQBiQueryFilter>;
  id: Scalars['ID']['output'];
  latestActiveRule: Maybe<GQFootprintCategoryRule>;
  outputColumn: Scalars['String']['output'];
  outputValue: Scalars['String']['output'];
  ruleOrder: Scalars['Int']['output'];
  stableId: Scalars['String']['output'];
  timeIntervals: Maybe<Array<Scalars['YMInterval']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQFootprintCategoryRuleInput = {
  filters: Array<GQBiQueryFilterInput>;
  outputColumn: Scalars['String']['input'];
  outputValue: Scalars['String']['input'];
  ruleOrder: Scalars['Int']['input'];
  stableId?: InputMaybe<Scalars['String']['input']>;
  timeIntervals?: InputMaybe<Array<Scalars['YMInterval']['input']>>;
};

export type GQFootprintChangelog = {
  __typename?: 'FootprintChangelog';
  id: Scalars['ID']['output'];
  versions: Maybe<GQFootprintVersionConnection>;
};

export type GQFootprintChangelogVersionsArgs = {
  filters: InputMaybe<GQFootprintChangelogFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};

export type GQFootprintChangelogFilters = {
  excludeIsUserChangelogHidden?: InputMaybe<Scalars['Boolean']['input']>;
  footprintId?: InputMaybe<Scalars['ID']['input']>;
  footprintVersionKindFilter?: InputMaybe<GQFootprintVersionKind>;
  onlyShowDraftIfLatest?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQFootprintConfigCarbonRemoval = {
  __typename?: 'FootprintConfigCarbonRemoval';
  id: Scalars['ID']['output'];
  totalTco2e: Scalars['Float']['output'];
};

export type GQFootprintConfiguration = {
  __typename?: 'FootprintConfiguration';
  carbonRemoval: Maybe<GQFootprintConfigCarbonRemoval>;
  categoryRules: Array<GQFootprintCategoryRule>;
  createdAt: Scalars['DateTime']['output'];
  exclusionRules: Array<GQFootprintExclusionRule>;
  footprintInterval: Scalars['YMInterval']['output'];
  id: Scalars['ID']['output'];
  marketplaceAllocationInstructions: GQMarketplaceAllocationInstructionConnection;
  measurementProjectDatasets: Array<GQFootprintMeasurementProjectDataset>;
  methodologyConfig: Maybe<GQMethodologyConfig>;
  releasesByBat: Array<GQEmissionsModelReleaseByBatName>;
};

export type GQFootprintConnection = {
  __typename?: 'FootprintConnection';
  edges: Array<Maybe<GQFootprintEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFootprintContributionLegacy = {
  __typename?: 'FootprintContributionLegacy';
  buildingName: Maybe<Scalars['String']['output']>;
  businessCategory: Scalars['String']['output'];
  businessSubcategory: Maybe<Scalars['String']['output']>;
  conversionFactorId: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  electricityType: Maybe<GQElectricityType>;
  fileMetadataIds: Array<Scalars['ID']['output']>;
  footprintKind: Scalars['String']['output'];
  ghgCategoryId: Scalars['String']['output'];
  ghgScope: Scalars['String']['output'];
  inputQuantity: Maybe<Scalars['Float']['output']>;
  inputUnit: Maybe<Scalars['String']['output']>;
  kgco2e: Scalars['Float']['output'];
  location: Maybe<Scalars['String']['output']>;
  locationBasedKgco2e: Maybe<Scalars['Float']['output']>;
  locationCountry: Maybe<Scalars['String']['output']>;
  product: Maybe<Scalars['String']['output']>;
  productMarket: Maybe<Scalars['String']['output']>;
  quantityUnit: Maybe<Scalars['String']['output']>;
  quantityValue: Maybe<Scalars['Int']['output']>;
  rowId: Scalars['String']['output'];
  subEntity: Maybe<Scalars['String']['output']>;
  vendor: Maybe<Scalars['String']['output']>;
  vendorId: Maybe<Scalars['ID']['output']>;
  vendorProgram: Maybe<Scalars['String']['output']>;
  yearMonth: Scalars['YearMonth']['output'];
};

export type GQFootprintContributionsData = {
  __typename?: 'FootprintContributionsData';
  data: Scalars['UntypedData']['output'];
  id: Scalars['String']['output'];
};

export type GQFootprintCustomReport = GQIdInterface & {
  __typename?: 'FootprintCustomReport';
  archivedAt: Maybe<Scalars['Date']['output']>;
  description: Scalars['String']['output'];
  downloadUrl: Scalars['String']['output'];
  footprintSnapshotId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  lastRefreshedAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  published: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQFootprintDatasetOrDatasetGroup = {
  __typename?: 'FootprintDatasetOrDatasetGroup';
  dataset: Maybe<GQDataset>;
  datasetGroupName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type GQFootprintDebugPayload = GQIdInterface & {
  __typename?: 'FootprintDebugPayload';
  appliedExclusionRules: Array<GQFootprintSnapshotExclusionRule>;
  cleanPowerImpact: Maybe<GQCleanPowerImpact>;
  id: Scalars['ID']['output'];
  intermediateFileIds: Maybe<Scalars['JSONString']['output']>;
};

export type GQFootprintDisclosure = GQIdInterface & {
  __typename?: 'FootprintDisclosure';
  createdAt: Scalars['DateTime']['output'];
  fileMetadata: GQFileMetadata;
  footprintSnapshot: GQFootprintSnapshot;
  granularity: GQFootprintDisclosureGranularity;
  id: Scalars['ID']['output'];
  reportingYear: Maybe<Scalars['Int']['output']>;
  shareLogs: Array<GQFootprintDisclosureShareLog>;
};

export type GQFootprintDisclosureConnection = {
  __typename?: 'FootprintDisclosureConnection';
  edges: Array<Maybe<GQFootprintDisclosureEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFootprintDisclosureEdge = {
  __typename?: 'FootprintDisclosureEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFootprintDisclosure>;
};

export const GQFootprintDisclosureGranularity = {
  AggregatesOnly: 'AggregatesOnly',
  Full: 'Full',
} as const;

export type GQFootprintDisclosureGranularity =
  (typeof GQFootprintDisclosureGranularity)[keyof typeof GQFootprintDisclosureGranularity];
export type GQFootprintDisclosureShareLog = GQIdInterface & {
  __typename?: 'FootprintDisclosureShareLog';
  footprintDisclosureId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  sharedByUserId: Scalars['ID']['output'];
  sharedToOrgId: Scalars['ID']['output'];
};

export type GQFootprintDisclosureShareLogConnection = {
  __typename?: 'FootprintDisclosureShareLogConnection';
  edges: Array<Maybe<GQFootprintDisclosureShareLogEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFootprintDisclosureShareLogEdge = {
  __typename?: 'FootprintDisclosureShareLogEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFootprintDisclosureShareLog>;
};

export type GQFootprintEdge = {
  __typename?: 'FootprintEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFootprintTemp>;
};

export const GQFootprintEmissionsKind = {
  Ghg: 'Ghg',
  MicroplasticsInflow: 'MicroplasticsInflow',
  MicroplasticsOutflow: 'MicroplasticsOutflow',
  SubstanceInflow: 'SubstanceInflow',
  SubstanceOutflow: 'SubstanceOutflow',
  Waste: 'Waste',
  WaterConsumption: 'WaterConsumption',
} as const;

export type GQFootprintEmissionsKind =
  (typeof GQFootprintEmissionsKind)[keyof typeof GQFootprintEmissionsKind];
export type GQFootprintEstimateOutput = GQIdInterface & {
  __typename?: 'FootprintEstimateOutput';
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  companyId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  output: Maybe<GQFootprintEstimateOutputByScope>;
};

export type GQFootprintEstimateOutputByScope = GQCompanyEmissionsInterfaceV2 & {
  __typename?: 'FootprintEstimateOutputByScope';
  revenueCurrency: Maybe<Scalars['String']['output']>;
  scope1: Maybe<Scalars['Float']['output']>;
  scope2: Maybe<Scalars['Float']['output']>;
  scope2Location: Maybe<Scalars['Float']['output']>;
  scope2Market: Maybe<Scalars['Float']['output']>;
  scope3: Maybe<Scalars['Float']['output']>;
  scope301: Maybe<Scalars['Float']['output']>;
  scope302: Maybe<Scalars['Float']['output']>;
  scope303: Maybe<Scalars['Float']['output']>;
  scope304: Maybe<Scalars['Float']['output']>;
  scope305: Maybe<Scalars['Float']['output']>;
  scope306: Maybe<Scalars['Float']['output']>;
  scope307: Maybe<Scalars['Float']['output']>;
  scope308: Maybe<Scalars['Float']['output']>;
  scope309: Maybe<Scalars['Float']['output']>;
  scope310: Maybe<Scalars['Float']['output']>;
  scope311: Maybe<Scalars['Float']['output']>;
  scope312: Maybe<Scalars['Float']['output']>;
  scope313: Maybe<Scalars['Float']['output']>;
  scope314: Maybe<Scalars['Float']['output']>;
  scope315: Maybe<Scalars['Float']['output']>;
  scope316: Maybe<Scalars['Float']['output']>;
  scope317: Maybe<Scalars['Float']['output']>;
  units: GQCompanyEmissionsUnits;
};

export type GQFootprintExclusionRule = GQIdInterface & {
  __typename?: 'FootprintExclusionRule';
  author: GQUser;
  deduplicationNote: Maybe<Scalars['String']['output']>;
  filtersSourceOne: Array<GQBiQueryFilter>;
  filtersSourceTwo: Maybe<Array<GQBiQueryFilter>>;
  id: Scalars['ID']['output'];
  latestActiveRule: Maybe<GQFootprintExclusionRule>;
  outputValue: Scalars['Float']['output'];
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
  ruleOrder: Scalars['Int']['output'];
  selectedSource: GQFootprintExclusionRuleSelectedSource;
  stableId: Scalars['String']['output'];
  timeIntervals: Maybe<Array<Scalars['YMInterval']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQFootprintExclusionRuleEmissions = {
  __typename?: 'FootprintExclusionRuleEmissions';
  excludedMwhConsumed: Maybe<Scalars['Float']['output']>;
  excludedTco2eQuantity: Scalars['Float']['output'];
};

export type GQFootprintExclusionRuleInput = {
  deduplicationNote?: InputMaybe<Scalars['String']['input']>;
  filtersSourceOne: Array<GQBiQueryFilterInput>;
  filtersSourceTwo?: InputMaybe<Array<GQBiQueryFilterInput>>;
  outputValue: Scalars['Float']['input'];
  ruleOrder: Scalars['Int']['input'];
  selectedSource: GQFootprintExclusionRuleSelectedSource;
  stableId?: InputMaybe<Scalars['String']['input']>;
  timeIntervals?: InputMaybe<Array<Scalars['YMInterval']['input']>>;
};

export const GQFootprintExclusionRuleSelectedSource = {
  SourceOne: 'SourceOne',
  SourceTwo: 'SourceTwo',
} as const;

export type GQFootprintExclusionRuleSelectedSource =
  (typeof GQFootprintExclusionRuleSelectedSource)[keyof typeof GQFootprintExclusionRuleSelectedSource];
export type GQFootprintExclusionRuleTargetedEmissions = {
  __typename?: 'FootprintExclusionRuleTargetedEmissions';
  footprintExclusionRuleId: Scalars['ID']['output'];
  targetedQuantities: Maybe<Array<GQExclusionRuleTargetedQuantity>>;
};

export type GQFootprintExclusionSuggestion = GQIdInterface & {
  __typename?: 'FootprintExclusionSuggestion';
  id: Scalars['ID']['output'];
  suggestion:
    | GQFootprintExclusionSuggestionSuggestionConflict
    | GQFootprintExclusionSuggestionSuggestionUnmappedActivity;
};

export const GQFootprintExclusionSuggestionConflictingSourceKind = {
  Activity: 'Activity',
  Spend: 'Spend',
} as const;

export type GQFootprintExclusionSuggestionConflictingSourceKind =
  (typeof GQFootprintExclusionSuggestionConflictingSourceKind)[keyof typeof GQFootprintExclusionSuggestionConflictingSourceKind];
export type GQFootprintExclusionSuggestionSuggestion = {
  kind: GQFootprintExclusionSuggestionSuggestionKind;
};

export type GQFootprintExclusionSuggestionSuggestionConflict =
  GQFootprintExclusionSuggestionSuggestion & {
    __typename?: 'FootprintExclusionSuggestionSuggestionConflict';
    beaCode: Scalars['String']['output'];
    beaCodeDescription: Scalars['String']['output'];
    conflictingSources: Array<
      | GQFootprintExclusionSuggestionSuggestionConflictActivity
      | GQFootprintExclusionSuggestionSuggestionConflictSpend
    >;
    kind: GQFootprintExclusionSuggestionSuggestionKind;
  };

export type GQFootprintExclusionSuggestionSuggestionConflictActivity =
  GQFootprintExclusionSuggestionSuggestionConflictingSource & {
    __typename?: 'FootprintExclusionSuggestionSuggestionConflictActivity';
    dataset: GQDataset;
    source: GQFootprintExclusionSuggestionConflictingSourceKind;
    tCO2e: Scalars['Float']['output'];
  };

export type GQFootprintExclusionSuggestionSuggestionConflictSpend =
  GQFootprintExclusionSuggestionSuggestionConflictingSource & {
    __typename?: 'FootprintExclusionSuggestionSuggestionConflictSpend';
    financialAccountId: Scalars['String']['output'];
    financialAccountName: Scalars['String']['output'];
    source: GQFootprintExclusionSuggestionConflictingSourceKind;
    tCO2e: Scalars['Float']['output'];
    vendorEntity: Maybe<Scalars['String']['output']>;
  };

export type GQFootprintExclusionSuggestionSuggestionConflictingSource = {
  source: GQFootprintExclusionSuggestionConflictingSourceKind;
  tCO2e: Scalars['Float']['output'];
};

export const GQFootprintExclusionSuggestionSuggestionKind = {
  Conflict: 'Conflict',
  UnmappedActivity: 'UnmappedActivity',
} as const;

export type GQFootprintExclusionSuggestionSuggestionKind =
  (typeof GQFootprintExclusionSuggestionSuggestionKind)[keyof typeof GQFootprintExclusionSuggestionSuggestionKind];
export type GQFootprintExclusionSuggestionSuggestionUnmappedActivity =
  GQFootprintExclusionSuggestionSuggestion & {
    __typename?: 'FootprintExclusionSuggestionSuggestionUnmappedActivity';
    dataset: GQDataset;
    kind: GQFootprintExclusionSuggestionSuggestionKind;
    tCO2e: Scalars['Float']['output'];
  };

export type GQFootprintFieldValue = {
  __typename?: 'FootprintFieldValue';
  field: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQFootprintGenerationInfo = {
  __typename?: 'FootprintGenerationInfo';
  totalPublishedRecordsForOrg: Scalars['Int']['output'];
};

export type GQFootprintGenerationSource = GQApiKey | GQUser;

export type GQFootprintKindBreakdown = {
  __typename?: 'FootprintKindBreakdown';
  footprintKinds: Array<GQFootprintKindBreakdownItem>;
  id: Scalars['ID']['output'];
};

export type GQFootprintKindBreakdownItem = {
  __typename?: 'FootprintKindBreakdownItem';
  footprintKind: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kgco2e: Scalars['Float']['output'];
  percentOfTotal: Scalars['Float']['output'];
};

export type GQFootprintKindMeta = {
  __typename?: 'FootprintKindMeta';
  description: Scalars['String']['output'];
  footprintDraftInterval: Maybe<Scalars['YMInterval']['output']>;
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQFootprintKindTotals = {
  __typename?: 'FootprintKindTotals';
  footprintKind: Scalars['String']['output'];
  totalTco2e: Scalars['Float']['output'];
};

export type GQFootprintKindWithProductsOrMaterials = {
  __typename?: 'FootprintKindWithProductsOrMaterials';
  footprintKind: Scalars['String']['output'];
};

export type GQFootprintMeasurementProjectDataset = {
  __typename?: 'FootprintMeasurementProjectDataset';
  datasetsAndDatasetGroups: Array<GQFootprintDatasetOrDatasetGroup>;
  id: Scalars['ID']['output'];
  measurementProject: GQMeasurementProject;
};

export type GQFootprintNamedQuery = {
  filter: GQFilterExpressionGroupInput;
  name: Scalars['String']['input'];
};

export type GQFootprintPrioritizedQueries = {
  default: Scalars['String']['input'];
  queries: Array<GQFootprintNamedQuery>;
};

export type GQFootprintQuantityKindMetadata = {
  __typename?: 'FootprintQuantityKindMetadata';
  includedFootprintQuantityKinds: Array<Scalars['String']['output']>;
};

export type GQFootprintQuestion = GQContextRelationInterface &
  GQIdInterface &
  GQMeasurementTaskObject &
  GQQuestion & {
    __typename?: 'FootprintQuestion';
    adminUrl: Scalars['String']['output'];
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQFootprintQuestion
      | GQMeasurementProject
      | GQMeasurementVerificationItemQuestion
    >;
    associatedFootprintVersionIds: Array<Scalars['ID']['output']>;
    comments: GQCommentConnection;
    createdAt: Scalars['DateTime']['output'];
    createdAtUrl: Scalars['String']['output'];
    createdFootprintVersionId: Scalars['ID']['output'];
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    resolvedFootprintVersionId: Maybe<Scalars['ID']['output']>;
    state: GQFootprintQuestionState;
  };

export type GQFootprintQuestionCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export const GQFootprintQuestionState = {
  NeedsUserResponse: 'NeedsUserResponse',
  NeedsWatershedResponse: 'NeedsWatershedResponse',
  Resolved: 'Resolved',
  ResolvedInNextMeasurement: 'ResolvedInNextMeasurement',
} as const;

export type GQFootprintQuestionState =
  (typeof GQFootprintQuestionState)[keyof typeof GQFootprintQuestionState];
export const GQFootprintReportKind = {
  CloudComputeReport: 'CloudComputeReport',
  Custom: 'Custom',
  ElectricityConsumption: 'ElectricityConsumption',
  ElectricityConsumptionDetail: 'ElectricityConsumptionDetail',
  FootprintAuditMonthlyReport: 'FootprintAuditMonthlyReport',
  FootprintAuditReport: 'FootprintAuditReport',
  GoldmanEsg: 'GoldmanEsg',
  GoldmanEsgEmissions: 'GoldmanEsgEmissions',
  LocationBasedFootprintAuditReport: 'LocationBasedFootprintAuditReport',
  MarketVsLocationReport: 'MarketVsLocationReport',
  Servicenow: 'Servicenow',
} as const;

export type GQFootprintReportKind =
  (typeof GQFootprintReportKind)[keyof typeof GQFootprintReportKind];
export type GQFootprintSliceInitiative = GQIdInterface &
  GQInitiative & {
    __typename?: 'FootprintSliceInitiative';
    costTimeseries: GQSimpleTimeseries;
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    description: Maybe<Scalars['String']['output']>;
    enabled: Scalars['Boolean']['output'];
    filters: GQFilterExpressionGroupWithNewFilters;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    plan: GQPlan;
    reductionTimeseries: GQSimpleTimeseries;
    scheduleType: GQScheduleType;
  };

export type GQFootprintSnapshot = GQIdInterface & {
  __typename?: 'FootprintSnapshot';
  assemblyDebugJson: Maybe<Scalars['JSONString']['output']>;
  categoryRules: Array<GQFootprintCategoryRule>;
  configs: Array<GQPipelineConfig>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<GQUser>;
  dataRegistryTableName: Maybe<Scalars['String']['output']>;
  descriptionMd: Maybe<Scalars['String']['output']>;
  excludedScopesWithPriceGating: Array<Scalars['String']['output']>;
  exclusionRules: Array<GQFootprintExclusionRule>;
  extraAdeIdsForIntDenoms: Array<Scalars['String']['output']>;
  extraAdeIdsForNonEmissiveActivity: Array<Scalars['String']['output']>;
  footprint: GQFootprintTemp;
  footprintAssemblyRun: Maybe<GQFootprintAssemblyRun>;
  footprintConfiguration: GQFootprintConfiguration;
  footprintInterval: Scalars['YMInterval']['output'];
  globalTags: Array<GQGlobalFootprintTag>;
  id: Scalars['ID']['output'];
  intermediateFileIds: Maybe<Scalars['JSONString']['output']>;
  isLatest: Scalars['Boolean']['output'];
  isManuallySaved: Scalars['Boolean']['output'];
  isStableRefreshAvailable: Scalars['Boolean']['output'];
  marketplaceAllocationInstructionIds: Array<Scalars['String']['output']>;
  marketplaceAllocationInstructions: Array<GQMarketplaceAllocationInstruction>;
  orgId: Scalars['ID']['output'];
  publishedAt: Maybe<Scalars['DateTime']['output']>;
  publishedDraftAt: Maybe<Scalars['DateTime']['output']>;
  publishedHiddenAt: Maybe<Scalars['DateTime']['output']>;
  reportsUsingFootprint: Array<GQReport>;
  source: GQFootprintGenerationSource;
  stitchedFootprintSnapshotId: Maybe<Scalars['ID']['output']>;
  summary: GQFootprintSnapshotSummary;
  tags: Array<GQFootprintTag>;
  userUploadedTableIds: Array<Scalars['String']['output']>;
  versions: Array<GQFootprintVersion>;
};

export type GQFootprintSnapshotConnection = {
  __typename?: 'FootprintSnapshotConnection';
  edges: Array<Maybe<GQFootprintSnapshotEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFootprintSnapshotEdge = {
  __typename?: 'FootprintSnapshotEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFootprintSnapshot>;
};

export type GQFootprintSnapshotExclusionRule = GQIdInterface & {
  __typename?: 'FootprintSnapshotExclusionRule';
  footprintExclusionRule: GQFootprintExclusionRule;
  footprintSnapshot: GQFootprintSnapshot;
  id: Scalars['ID']['output'];
  impactedEmissions: GQFootprintExclusionRuleEmissions;
};

export type GQFootprintSnapshotSummary = {
  __typename?: 'FootprintSnapshotSummary';
  emissions: Array<GQFootprintSnapshotSummaryEmission>;
  format: Scalars['Int']['output'];
};

export type GQFootprintSnapshotSummaryEmission = {
  __typename?: 'FootprintSnapshotSummaryEmission';
  businessCategory: Maybe<Scalars['String']['output']>;
  footprintKind: Scalars['String']['output'];
  kgCo2e: Scalars['Float']['output'];
  month: Scalars['Date']['output'];
  pipeline: Scalars['String']['output'];
};

export type GQFootprintSourceOption = {
  __typename?: 'FootprintSourceOption';
  externalScore: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  source: GQEmissionsYearSource;
  year: Scalars['Int']['output'];
};

export const GQFootprintStatus = {
  Approved: 'Approved',
  Draft: 'Draft',
  PendingWatershedReview: 'PendingWatershedReview',
  ReviewedByWatershed: 'ReviewedByWatershed',
} as const;

export type GQFootprintStatus =
  (typeof GQFootprintStatus)[keyof typeof GQFootprintStatus];
export type GQFootprintSummary = {
  __typename?: 'FootprintSummary';
  customIntensityMetadata: Array<GQCustomIntensityMetadata>;
  heavyHitters: Array<GQCategorizedEmissionData>;
  id: Scalars['String']['output'];
  timeseries: Maybe<GQFootprintAnalysisTimeSeries>;
  topCategories: Array<GQCategorizedEmissionData>;
  topCategoriesWithSubcategories: Array<GQCategorizedEmissionData>;
  total: Maybe<Scalars['Float']['output']>;
};

export type GQFootprintSummaryHeavyHittersArgs = {
  filters: InputMaybe<GQFootprintSummaryFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};

export type GQFootprintSummaryTimeseriesArgs = {
  filters: InputMaybe<GQFootprintSummaryFilters>;
  grouping: InputMaybe<Scalars['String']['input']>;
  kind: InputMaybe<GQAggregateKind>;
};

export type GQFootprintSummaryTopCategoriesArgs = {
  filters: InputMaybe<GQFootprintSummaryFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};

export type GQFootprintSummaryTopCategoriesWithSubcategoriesArgs = {
  filters: InputMaybe<GQFootprintSummaryFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};

export type GQFootprintSummaryTotalArgs = {
  filters: InputMaybe<GQFootprintSummaryFilters>;
  kind: InputMaybe<GQAggregateKind>;
};

export type GQFootprintSummaryFilters = {
  endYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  ghgScope?: InputMaybe<Scalars['String']['input']>;
  inclusionKind?: InputMaybe<GQFootprintAnalysisInclusionKind>;
  startYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
};

export type GQFootprintSummaryGroups = {
  __typename?: 'FootprintSummaryGroups';
  groupName: Maybe<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
};

export type GQFootprintTag = GQIdInterface & {
  __typename?: 'FootprintTag';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isFinance: Scalars['Boolean']['output'];
  orgId: Scalars['ID']['output'];
  rulesList: Scalars['JSONString']['output'];
  rulesSchemaVersion: Scalars['String']['output'];
  tagName: Scalars['String']['output'];
  watershedEditor: Maybe<GQWatershedEmployee>;
};

export type GQFootprintTemp = GQIdInterface & {
  __typename?: 'FootprintTemp';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<GQUser>;
  defaultFootprintSnapshot: Maybe<GQFootprintSnapshot>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  latestDraftFootprintSnapshot: Maybe<GQFootprintSnapshot>;
  latestPublishedFootprintSnapshot: Maybe<GQFootprintSnapshot>;
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  status: GQFootprintStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQFootprintTestExecutionRecordDiffData = {
  __typename?: 'FootprintTestExecutionRecordDiffData';
  actual: Maybe<Scalars['UntypedData']['output']>;
  equal: Scalars['Boolean']['output'];
  expected: Maybe<Scalars['UntypedData']['output']>;
  fieldName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type GQFootprintTestExecutionStepResult = {
  __typename?: 'FootprintTestExecutionStepResult';
  id: Scalars['ID']['output'];
  resultRecords: Array<GQFootprintTestExecutionStepResultRecord>;
};

export type GQFootprintTestExecutionStepResultRecord = {
  __typename?: 'FootprintTestExecutionStepResultRecord';
  allocationLocationQuantityKgCo2E: Maybe<Scalars['Float']['output']>;
  bartName: Scalars['String']['output'];
  bartRowId: Scalars['Int']['output'];
  categoryId: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  emStableId: Scalars['String']['output'];
  emStableTitle: Scalars['String']['output'];
  emVariableName: Scalars['String']['output'];
  fullDiff: Scalars['UntypedData']['output'];
  ghgCategoryId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inActual: Maybe<Scalars['Boolean']['output']>;
  inExpected: Maybe<Scalars['Boolean']['output']>;
  month: Scalars['String']['output'];
  quantityKgCo2e: Maybe<Scalars['Float']['output']>;
  resultAccepted: Maybe<Scalars['Boolean']['output']>;
  rowSplitKey: Maybe<Scalars['String']['output']>;
  status: GQFootprintTestExecutionStepStatus;
  subcategoryId: Maybe<Scalars['String']['output']>;
};

export const GQFootprintTestExecutionStepStatus = {
  Failed: 'Failed',
  Passed: 'Passed',
} as const;

export type GQFootprintTestExecutionStepStatus =
  (typeof GQFootprintTestExecutionStepStatus)[keyof typeof GQFootprintTestExecutionStepStatus];
export type GQFootprintTestSuiteConfig = {
  __typename?: 'FootprintTestSuiteConfig';
  categoryRuleIds: Maybe<Array<Scalars['ID']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  customTagNames: Maybe<Array<Scalars['String']['output']>>;
  exclusionRuleIds: Maybe<Array<Scalars['ID']['output']>>;
  id: Scalars['ID']['output'];
  marketplaceAllocationInstructionIds: Maybe<Array<Scalars['ID']['output']>>;
  pipelineEndDate: Maybe<Scalars['YearMonth']['output']>;
  pipelineStartDate: Maybe<Scalars['YearMonth']['output']>;
  shouldExclude: Scalars['Boolean']['output'];
};

export type GQFootprintTestSuiteConfigFields = {
  categoryRuleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  customTagNames?: InputMaybe<Array<Scalars['String']['input']>>;
  exclusionRuleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  marketplaceAllocationInstructionIds?: InputMaybe<
    Array<Scalars['ID']['input']>
  >;
  pipelineEndDate?: InputMaybe<Scalars['YearMonth']['input']>;
  pipelineStartDate?: InputMaybe<Scalars['YearMonth']['input']>;
  shouldExclude: Scalars['Boolean']['input'];
};

export type GQFootprintTestSuiteConfigPayload = {
  __typename?: 'FootprintTestSuiteConfigPayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQFootprintTestSuiteExecution = {
  __typename?: 'FootprintTestSuiteExecution';
  createdAt: Scalars['DateTime']['output'];
  error: Maybe<Scalars['String']['output']>;
  failedStep: Maybe<GQFootprintAssemblyStep>;
  id: Scalars['ID']['output'];
  runOnTheT: Maybe<Scalars['Boolean']['output']>;
  status: GQMeasurementTestSuiteStageExecutionStatus;
  updatedAt: Scalars['DateTime']['output'];
  warnings: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GQFootprintVersion = GQIdInterface & {
  __typename?: 'FootprintVersion';
  canUserPublish: Scalars['Boolean']['output'];
  configuration: Maybe<GQFootprintConfiguration>;
  createdAt: Scalars['DateTime']['output'];
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  generatedBy: Maybe<GQUser>;
  id: Scalars['ID']['output'];
  internalNotesMd: Scalars['String']['output'];
  isAutoGenerated: Scalars['Boolean']['output'];
  isGeneratedFromProduct: Scalars['Boolean']['output'];
  isUserChangelogHidden: Scalars['Boolean']['output'];
  isUserVisible: Scalars['Boolean']['output'];
  kind: GQFootprintVersionKind;
  measurementProjects: Array<GQMeasurementProject>;
  newDataCoverageInterval: Maybe<Scalars['YMInterval']['output']>;
  orgId: Scalars['ID']['output'];
  originalFootprintSnapshot: Maybe<GQFootprintSnapshot>;
  originalFootprintSnapshotId: Scalars['ID']['output'];
  pinInfo: Maybe<GQFootprintVersionPin>;
  publishableUserChangelogMd: Maybe<Scalars['String']['output']>;
  source: GQFootprintGenerationSource;
  userChangelogMd: Maybe<Scalars['String']['output']>;
  userVisibleFootprintSnapshot: GQFootprintSnapshot;
  userVisibleFootprintSnapshotId: Scalars['ID']['output'];
  versionIdentifier: Maybe<Scalars['String']['output']>;
  versionName: Maybe<Scalars['String']['output']>;
  watershedFootprintReviewRequest: Maybe<GQWatershedFootprintReviewRequest>;
};

export type GQFootprintVersionConnection = {
  __typename?: 'FootprintVersionConnection';
  edges: Array<Maybe<GQFootprintVersionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFootprintVersionEdge = {
  __typename?: 'FootprintVersionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFootprintVersion>;
};

export const GQFootprintVersionKind = {
  Draft: 'Draft',
  Publish: 'Publish',
} as const;

export type GQFootprintVersionKind =
  (typeof GQFootprintVersionKind)[keyof typeof GQFootprintVersionKind];
export type GQFootprintVersionPin = GQIdInterface & {
  __typename?: 'FootprintVersionPin';
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  footprintVersionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQForecast = GQIdInterface & {
  __typename?: 'Forecast';
  acknowledgedFootprintSnapshotId: Maybe<Scalars['ID']['output']>;
  businessCategories: Array<GQCategorizedEmissionData>;
  customIntensityConfigs: Array<GQCustomIntensityConfig>;
  electricityConsumptionBreakdown: Array<GQRenewableEnergyPercentagePoint>;
  /** @deprecated This shouldn't be user-visible, there is no dashboard resolver for this */
  footprintSnapshot: GQFootprintSnapshot;
  footprintSnapshotId: Scalars['ID']['output'];
  historicalPeriod: GQForecastPeriod;
  id: Scalars['ID']['output'];
  interval: Scalars['YMInterval']['output'];
  name: Scalars['String']['output'];
  overrideFootprintKind: Maybe<Scalars['String']['output']>;
  planTargets: Array<GQPlanTarget>;
  referencePeriod: GQForecastPeriod;
  referencePeriodInterval: Scalars['YMInterval']['output'];
  scenarios: Array<GQForecastScenario>;
  validReferenceInterval: Scalars['YMInterval']['output'];
  validYearsForIntervalEnd: Scalars['YMInterval']['output'];
};

export type GQForecastBusinessCategoriesArgs = {
  useLegacyFootprintVersionSelection: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQForecastCustomIntensityDataPoint = GQCustomIntensityDataPoint & {
  __typename?: 'ForecastCustomIntensityDataPoint';
  customIntensityId: Scalars['String']['output'];
  value: Maybe<Scalars['Float']['output']>;
};

export type GQForecastCustomIntensityDataPointInput = {
  customIntensityId: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type GQForecastPeriod = {
  __typename?: 'ForecastPeriod';
  data: Array<GQForecastPeriodPoint>;
  interval: Scalars['YMInterval']['output'];
};

export type GQForecastPeriodPoint = {
  __typename?: 'ForecastPeriodPoint';
  customGrowthFactors: Array<GQForecastCustomIntensityDataPoint>;
  date: Scalars['Date']['output'];
  headcount: Maybe<Scalars['Int']['output']>;
  revenue: Maybe<Scalars['Int53']['output']>;
};

export type GQForecastScenario = GQIdInterface & {
  __typename?: 'ForecastScenario';
  description: Maybe<Scalars['String']['output']>;
  growthForecasts: Array<GQGrowthForecast>;
  historicalUserInputtedGrowthFactorDataPoints: Array<GQHistoricalUserInputtedGrowthFactorDataPoint>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedByName: Maybe<Scalars['String']['output']>;
};

export type GQForkEmissionsModelInput = {
  description: Scalars['String']['input'];
  stableId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type GQForkEmissionsModelPayload = {
  __typename?: 'ForkEmissionsModelPayload';
  emissionsModelStableRecord: GQEmissionsModelStable;
  emissionsModelVersionRecord: GQEmissionsModelVersion;
};

export type GQForkThenLink = {
  description: Scalars['String']['input'];
  stableId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type GQFrozenDatasourceSummary = {
  __typename?: 'FrozenDatasourceSummary';
  datasource: GQDatasource;
  isStale: Scalars['Boolean']['output'];
  signedUrl: Scalars['String']['output'];
};

export type GQFulfillShareFileRequestInput = {
  sharedFileRequestIds: Array<Scalars['ID']['input']>;
};

export type GQFulfillShareFileRequestPayload = {
  __typename?: 'FulfillShareFileRequestPayload';
  newSharedFiles: Array<GQSharedFile>;
  sharedFileRequests: Array<GQSharedFileRequest>;
};

export type GQFund = GQPermissionObject & {
  __typename?: 'Fund';
  anonymousFundUuid: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  excludeAsSandbox: Maybe<Scalars['Boolean']['output']>;
  externalId: Maybe<Scalars['ID']['output']>;
  fundCategory: Maybe<Scalars['String']['output']>;
  fundGroup: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nameSortable: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export const GQFundAssetClass = {
  AssetGroup: 'AssetGroup',
  BusinessLoans: 'BusinessLoans',
  CommercialLineOfInsurance: 'CommercialLineOfInsurance',
  CommercialRealEstate: 'CommercialRealEstate',
  CorporateBonds: 'CorporateBonds',
  ListedEquity: 'ListedEquity',
  PersonalMotorVehicleInsurance: 'PersonalMotorVehicleInsurance',
  SovereignDebt: 'SovereignDebt',
  UnlistedEquity: 'UnlistedEquity',
} as const;

export type GQFundAssetClass =
  (typeof GQFundAssetClass)[keyof typeof GQFundAssetClass];
export type GQFundConnection = {
  __typename?: 'FundConnection';
  edges: Array<Maybe<GQFundEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFundEdge = {
  __typename?: 'FundEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFund>;
};

export type GQFundId = {
  __typename?: 'FundId';
  fundId: Scalars['ID']['output'];
};

export type GQGenerateAuditReportInput = {
  filters?: InputMaybe<Array<GQFilterExpressionPrimitiveWithNewFiltersInput>>;
  footprintEmissionsKind?: InputMaybe<GQFootprintEmissionsKind>;
  footprintSnapshotId: Scalars['ID']['input'];
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
};

export type GQGenerateAuditReportPayload = {
  __typename?: 'GenerateAuditReportPayload';
  jobId: Scalars['ID']['output'];
};

export type GQGenerateAutoFootprintAlertOptionsInput = {
  fullStorySessionUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GQGenerateAutoFootprintInput = {
  alertOpts?: InputMaybe<GQGenerateAutoFootprintAlertOptionsInput>;
  categoryRuleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  datasetIdsPerMeasurementProjectId: Array<GQDatasetIdsPerMeasurementProjectId>;
  exclusionRuleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  footprintId?: InputMaybe<Scalars['String']['input']>;
  methodologyConfig?: InputMaybe<GQMethodologyConfigInput>;
  orgId: Scalars['ID']['input'];
  shouldSendEmailOnCompletion: Scalars['Boolean']['input'];
  shouldSkipFootprintPublish: Scalars['Boolean']['input'];
  userChangelogMd?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  versionName?: InputMaybe<Scalars['String']['input']>;
};

export type GQGenerateAutoFootprintPayload = {
  __typename?: 'GenerateAutoFootprintPayload';
  footprintAssemblyRun: GQFootprintAssemblyRun;
  jobId: Scalars['ID']['output'];
};

export type GQGenerateBartFromFileUploadInput = {
  ctsVersionId: Scalars['ID']['input'];
  embedId?: InputMaybe<Scalars['String']['input']>;
  fileMetadataId: Scalars['ID']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQGenerateBartFromFileUploadPayload = {
  __typename?: 'GenerateBartFromFileUploadPayload';
  jobId: Scalars['ID']['output'];
  userUploadTask: GQUserUploadTask;
};

export type GQGenerateCustomIntensityConfigIdInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type GQGenerateCustomIntensityConfigIdPayload = {
  __typename?: 'GenerateCustomIntensityConfigIdPayload';
  id: Scalars['String']['output'];
};

export type GQGenerateCustomerUploadedReferenceDataRevisionInput = {
  customizationTypeId: Scalars['String']['input'];
  oneSchemaEmbedId: Scalars['Int']['input'];
  oneSchemaSheetId: Scalars['Int']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQGenerateCustomerUploadedReferenceDataRevisionPayload = {
  __typename?: 'GenerateCustomerUploadedReferenceDataRevisionPayload';
  jobId: Scalars['ID']['output'];
};

export type GQGenerateForecastScenarioIdInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type GQGenerateForecastScenarioIdPayload = {
  __typename?: 'GenerateForecastScenarioIdPayload';
  id: Scalars['String']['output'];
};

export type GQGenerateGmailXmlInput = {
  existingFilters?: InputMaybe<Scalars['String']['input']>;
  handle: Scalars['String']['input'];
  teams?: InputMaybe<Array<Scalars['String']['input']>>;
  watching?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQGenerateGmailXmlPayload = {
  __typename?: 'GenerateGmailXmlPayload';
  xml: Scalars['String']['output'];
};

export type GQGenerateMeasurementVerificationItemsInput = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQGenerateMeasurementVerificationItemsPayload = {
  __typename?: 'GenerateMeasurementVerificationItemsPayload';
  measurementProject: GQMeasurementProject;
};

export type GQGenerateOverrideReferenceDataRevisionInput = {
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQGenerateOverrideReferenceDataRevisionPayload = {
  __typename?: 'GenerateOverrideReferenceDataRevisionPayload';
  jobId: Scalars['ID']['output'];
};

export type GQGenerateTemplateDownloadUrlForDatasetInput = {
  datasetId: Scalars['ID']['input'];
};

export type GQGenerateTemplateDownloadUrlForDatasetPayload = {
  __typename?: 'GenerateTemplateDownloadUrlForDatasetPayload';
  templateDownloadUrl: Scalars['String']['output'];
};

export type GQGenerateTemplateDownloadUrlForOrgDatasetsInput = {
  datasetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQGenerateTemplateDownloadUrlForOrgDatasetsPayload = {
  __typename?: 'GenerateTemplateDownloadUrlForOrgDatasetsPayload';
  templateDownloadUrl: Scalars['String']['output'];
};

export type GQGenericObjectPayload = GQIdInterface & {
  __typename?: 'GenericObjectPayload';
  data: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
  model: Scalars['String']['output'];
};

export const GQGeocodingClient = {
  GoogleMaps: 'GoogleMaps',
  Mapbox: 'Mapbox',
} as const;

export type GQGeocodingClient =
  (typeof GQGeocodingClient)[keyof typeof GQGeocodingClient];
export type GQGetEmissionsModelsForOrgInput = {
  orgId: Scalars['ID']['input'];
};

export type GQGetEmissionsModelsForOrgPayload = {
  __typename?: 'GetEmissionsModelsForOrgPayload';
  emissionsModels: Array<GQEmissionsModelStable>;
};

export type GQGetNewDecryptedTokenInput = {
  integrationConnectionId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
  ticketLink: Scalars['String']['input'];
};

export type GQGetNewDecryptedTokenPayload = {
  __typename?: 'GetNewDecryptedTokenPayload';
  decryptedToken: Maybe<Scalars['JSONString']['output']>;
};

export type GQGetOrCreateDatasetFromCanonicalDatasetKindInput = {
  kind: GQCanonicalDatasetKind;
};

export type GQGetOrCreateDatasetFromCanonicalDatasetKindPayload = {
  __typename?: 'GetOrCreateDatasetFromCanonicalDatasetKindPayload';
  dataset: GQDataset;
};

export type GQGetOrSendEmailsInput = {
  blurb?: InputMaybe<Scalars['String']['input']>;
  contactIds: Array<Scalars['ID']['input']>;
  emailIsForPortco?: InputMaybe<Scalars['Boolean']['input']>;
  emailKind: GQEmailKind;
  shouldSendEmails: Scalars['Boolean']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type GQGetOrSendEmailsPayload = {
  __typename?: 'GetOrSendEmailsPayload';
  defaultBlurb: Scalars['String']['output'];
  defaultSubject: Scalars['String']['output'];
  sampleEmail: GQEmailContents;
};

export type GQGetOrSendMeasurementEmailsInput = {
  blurb?: InputMaybe<Scalars['String']['input']>;
  ccUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  measurementProjectId: Scalars['ID']['input'];
  recipientUserIds: Array<Scalars['ID']['input']>;
  shouldSendEmails: Scalars['Boolean']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  triggererUserId: Scalars['ID']['input'];
  type: GQMeasurementEmailType;
};

export type GQGetOrSendMeasurementEmailsPayload = {
  __typename?: 'GetOrSendMeasurementEmailsPayload';
  defaultBlurb: Scalars['String']['output'];
  measurementProject: GQMeasurementProject;
  sampleEmail: GQEmailContents;
};

export type GQGetReportObjectConfigPathInput = {
  id: Scalars['ID']['input'];
};

export type GQGetReportObjectConfigPathPayload = {
  __typename?: 'GetReportObjectConfigPathPayload';
  path: Maybe<Array<GQReportObjectConfigPathItem>>;
};

export type GQGhgCategorizedEmissionData = {
  __typename?: 'GhgCategorizedEmissionData';
  amountKgco2e: Scalars['Float']['output'];
  businessSubcategory: Maybe<Scalars['String']['output']>;
  ghgCategoryId: Scalars['String']['output'];
  proportion: Scalars['Float']['output'];
};

export const GQGhgScope = {
  AdditionalCleanPower: 'AdditionalCleanPower',
  CarbonRemoval: 'CarbonRemoval',
  Scope1: 'Scope1',
  Scope2: 'Scope2',
  Scope3: 'Scope3',
} as const;

export type GQGhgScope = (typeof GQGhgScope)[keyof typeof GQGhgScope];
export type GQGiveFeedbackInput = {
  message: Scalars['String']['input'];
  surfaceArea: GQFeedbackSurfaceArea;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GQGlobalFootprintTag = GQIdInterface & {
  __typename?: 'GlobalFootprintTag';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
  rulesList: Scalars['JSONString']['output'];
  rulesSchemaVersion: Scalars['String']['output'];
  tagName: Scalars['String']['output'];
  watershedEditor: Maybe<GQWatershedEmployee>;
};

export type GQGrantFinanceUsersPortcoAccessesInput = {
  assetManagerUserIds: Array<Scalars['ID']['input']>;
  portcoOrgIds: Array<Scalars['ID']['input']>;
};

export type GQGrantFinanceUsersPortcoAccessesPayload = {
  __typename?: 'GrantFinanceUsersPortcoAccessesPayload';
  backgroundJobId: Scalars['ID']['output'];
};

export type GQGrantUserRoleInput = {
  orgAccessId: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  roleId: Scalars['ID']['input'];
};

export type GQGrantUserRolePayload = {
  __typename?: 'GrantUserRolePayload';
  userRoleAssignment: Maybe<GQUserRoleAssignment>;
};

export type GQGridColumnConfiguration = {
  columnFormat: GQGridColumnFormat;
};

export type GQGridColumnConfigurationMultiSelect = GQGridColumnConfiguration & {
  __typename?: 'GridColumnConfigurationMultiSelect';
  columnFormat: GQGridColumnFormat;
  options: Array<Scalars['String']['output']>;
};

export type GQGridColumnConfigurationSingleSelect =
  GQGridColumnConfiguration & {
    __typename?: 'GridColumnConfigurationSingleSelect';
    columnFormat: GQGridColumnFormat;
    options: Array<Scalars['String']['output']>;
  };

export const GQGridColumnFormat = {
  Boolean: 'Boolean',
  Checkbox: 'Checkbox',
  Country: 'Country',
  Currency: 'Currency',
  Date: 'Date',
  DateTime: 'DateTime',
  Float: 'Float',
  Integer: 'Integer',
  Metric: 'Metric',
  MultiSelect: 'MultiSelect',
  NullableCheckbox: 'NullableCheckbox',
  Percentage: 'Percentage',
  PercentageChange: 'PercentageChange',
  SingleSelect: 'SingleSelect',
  String: 'String',
} as const;

export type GQGridColumnFormat =
  (typeof GQGridColumnFormat)[keyof typeof GQGridColumnFormat];
export type GQGroupedAuditLogConnection = {
  __typename?: 'GroupedAuditLogConnection';
  edges: Array<Maybe<GQGroupedAuditLogEdge>>;
  pageInfo: GQPageInfo;
};

export type GQGroupedAuditLogEdge = {
  __typename?: 'GroupedAuditLogEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQGroupedAuditLogEntry>;
};

export type GQGroupedAuditLogEntry = {
  __typename?: 'GroupedAuditLogEntry';
  createdAt: Scalars['DateTime']['output'];
  eventKind: Scalars['String']['output'];
  firstEventMetadata: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  numEvents: Scalars['Int']['output'];
  objectKind: Maybe<Scalars['String']['output']>;
  user: Maybe<GQUser>;
  userId: Maybe<Scalars['String']['output']>;
};

export const GQGrowthFactor = {
  Headcount: 'Headcount',
  Revenue: 'Revenue',
} as const;

export type GQGrowthFactor =
  (typeof GQGrowthFactor)[keyof typeof GQGrowthFactor];
export type GQGrowthFactorDataPoint = {
  __typename?: 'GrowthFactorDataPoint';
  customIntensityConfigId: Maybe<Scalars['ID']['output']>;
  growthFactorType: GQGrowthFactorType;
  value: Maybe<Scalars['Float']['output']>;
};

export const GQGrowthFactorType = {
  Custom: 'Custom',
  GrossProfit: 'GrossProfit',
  Headcount: 'Headcount',
  NightsStayed: 'NightsStayed',
  Orders: 'Orders',
  Patients: 'Patients',
  Revenue: 'Revenue',
  SupplierSpend: 'SupplierSpend',
} as const;

export type GQGrowthFactorType =
  (typeof GQGrowthFactorType)[keyof typeof GQGrowthFactorType];
export type GQGrowthForecast = {
  __typename?: 'GrowthForecast';
  customIntensityConfigId: Maybe<Scalars['ID']['output']>;
  forecast: GQSimpleTimeseries;
  growthFactorType: GQGrowthFactorType;
  hasUserInputtedHistoricalPeriod: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isDefaultScalingFactor: Scalars['Boolean']['output'];
  scalingFactorFilters: Maybe<GQFilterExpressionGroupWithNewFilters>;
};

export type GQGrowthForecastInput = {
  customIntensityConfigId?: InputMaybe<Scalars['ID']['input']>;
  forecast?: InputMaybe<GQSimpleTimeseriesInput>;
  growthFactorType: GQGrowthFactorType;
  historicalGrowthValues?: InputMaybe<GQCreateSimpleTimeseriesInput>;
  isDefaultScalingFactor?: InputMaybe<Scalars['Boolean']['input']>;
  scalingFactorFilters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQHasPermissionInput = {
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectType?: InputMaybe<GQPermissionObjectType>;
  permissionType: GQPermissionType;
};

export type GQHistoricalUserInputtedGrowthFactorDataPoint = {
  __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
  date: Scalars['Date']['output'];
  growthFactors: Array<GQGrowthFactorDataPoint>;
};

export type GQI18nSandbox = {
  __typename?: 'I18nSandbox';
  name: Scalars['String']['output'];
  name2: Scalars['String']['output'];
  name3: Scalars['String']['output'];
};

export type GQIClimateProgramProject = {
  canonicalClimateProgramProject: Maybe<GQCanonicalClimateProgramProject>;
  completedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  endDate: Maybe<Scalars['Date']['output']>;
  iconType: GQClimateProgramProjectIconType;
  id: Scalars['ID']['output'];
  kind: GQClimateProgramProjectKind;
  name: Scalars['String']['output'];
  startDate: Maybe<Scalars['Date']['output']>;
};

export type GQIContact = {
  companyId: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  role: Maybe<Scalars['String']['output']>;
};

export type GQIdInterface = {
  id: Scalars['ID']['output'];
};

export type GQIFinanceAsset = {
  assetComments: Array<GQAssetComment>;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  buildingSizeUnit: Maybe<GQBuildingSizeUnit>;
  companyId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQIFinanceAssetAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQIFinanceAssetAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQIFinanceAssetFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQIFinanceAssetRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQIFinanceViewSection = {
  id: Scalars['ID']['output'];
  isWideFormat: Maybe<Scalars['Boolean']['output']>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type GQIReportItem = {
  id: Scalars['ID']['output'];
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  sublabel: Maybe<Scalars['String']['output']>;
};

export type GQITcfdOpportunity = {
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  userDescription: Scalars['String']['output'];
};

export type GQITcfdRisk = {
  highTrajectoryImpact: GQTcfdRiskImpact;
  id: Scalars['ID']['output'];
  kind: GQTcfdRiskKind;
  lowTrajectoryImpact: GQTcfdRiskImpact;
  mediumTrajectoryImpact: GQTcfdRiskImpact;
  physicalRiskSeverity: Maybe<GQTcfdPhysicalRiskSeverity>;
  possibleImpactDescription: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  userDescription: Scalars['String']['output'];
};

export const GQIciAlignmentStatus = {
  Aligned: 'Aligned',
  Aligning: 'Aligning',
  CapturingData: 'CapturingData',
  NotStarted: 'NotStarted',
  PreparingToDecarbonize: 'PreparingToDecarbonize',
} as const;

export type GQIciAlignmentStatus =
  (typeof GQIciAlignmentStatus)[keyof typeof GQIciAlignmentStatus];
export type GQIdInput = {
  id: Scalars['ID']['input'];
};

export const GQImportFlowDataCleaningStep = {
  AdditionalQuestions: 'AdditionalQuestions',
  ColumnMapping: 'ColumnMapping',
  DataFormat: 'DataFormat',
  DataMelt: 'DataMelt',
  FooterSelection: 'FooterSelection',
  HeaderSelection: 'HeaderSelection',
} as const;

export type GQImportFlowDataCleaningStep =
  (typeof GQImportFlowDataCleaningStep)[keyof typeof GQImportFlowDataCleaningStep];
export type GQImportMeasurementTestSuiteInput = {
  rawJson: Scalars['JSONString']['input'];
};

export type GQImportMeasurementTestSuitePayload = {
  __typename?: 'ImportMeasurementTestSuitePayload';
  id: Scalars['ID']['output'];
};

export type GQIndustryClassification = {
  __typename?: 'IndustryClassification';
  id: Scalars['ID']['output'];
  longDescription: Scalars['String']['output'];
  shortDescription: Scalars['String']['output'];
};

export type GQIndustrySector = {
  __typename?: 'IndustrySector';
  name: Maybe<Scalars['String']['output']>;
  sectorCode: Maybe<Scalars['String']['output']>;
};

export type GQIngestedSbtiCompanyUpdate = GQIdInterface & {
  __typename?: 'IngestedSbtiCompanyUpdate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sbtiCompanyId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  workflowId: Scalars['String']['output'];
};

export type GQIngestionCustomField = GQIdInterface & {
  __typename?: 'IngestionCustomField';
  appliesToAllDatasets: Scalars['Boolean']['output'];
  appliesToAllUtilityTypes: Scalars['Boolean']['output'];
  author: Maybe<GQUser>;
  bartTagName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  exampleValues: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  ingestionCustomFieldDatasources: Maybe<
    Array<GQIngestionCustomFieldDatasource>
  >;
  name: Scalars['String']['output'];
  possibleValues: Maybe<Array<Scalars['String']['output']>>;
  specificDatasets: Array<GQDataset>;
  specificUtilityTypes: Array<GQBuildingUtilityType>;
  type: GQIngestionCustomFieldType;
  usedValues: Array<Scalars['String']['output']>;
};

export type GQIngestionCustomFieldDatasource = GQIdInterface & {
  __typename?: 'IngestionCustomFieldDatasource';
  createdAt: Scalars['DateTime']['output'];
  datasourceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  ingestionCustomFieldId: Scalars['ID']['output'];
  ingestionCustomFieldValue: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const GQIngestionCustomFieldType = {
  Boolean: 'Boolean',
  Date: 'Date',
  Number: 'Number',
  String: 'String',
  StringEnum: 'StringEnum',
} as const;

export type GQIngestionCustomFieldType =
  (typeof GQIngestionCustomFieldType)[keyof typeof GQIngestionCustomFieldType];
export type GQIngestionExampleData = {
  __typename?: 'IngestionExampleData';
  columns: Array<GQIngestionExampleDataColumn>;
  datasetId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  rows: Array<GQIngestionExampleDataRow>;
  title: Scalars['String']['output'];
};

export type GQIngestionExampleDataColumn = {
  __typename?: 'IngestionExampleDataColumn';
  defaultValue: Maybe<Scalars['JSONString']['output']>;
  description: Scalars['String']['output'];
  examples: Array<Scalars['String']['output']>;
  fieldName: Scalars['String']['output'];
  optionalToMap: Maybe<Scalars['Boolean']['output']>;
  required: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type GQIngestionExampleDataRow = {
  __typename?: 'IngestionExampleDataRow';
  fields: Array<GQIngestionExampleDataRowField>;
};

export type GQIngestionExampleDataRowField = {
  __typename?: 'IngestionExampleDataRowField';
  fieldName: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQIngestionInstructionStepInput = {
  descriptionMd: Scalars['String']['input'];
  fileMetadataId?: InputMaybe<Scalars['String']['input']>;
  remotePath?: InputMaybe<Scalars['String']['input']>;
};

export type GQIngestionInstructionsInput = {
  customExampleData?: InputMaybe<GQTabularDataInput>;
  ghgScopes?: InputMaybe<Array<GQGhgScope>>;
  instructionsDataFormattingMd?: InputMaybe<Scalars['String']['input']>;
  instructionsIntroMd?: InputMaybe<Scalars['String']['input']>;
  instructionsSteps?: InputMaybe<Array<GQIngestionInstructionStepInput>>;
  potentialOverlaps?: InputMaybe<Scalars['String']['input']>;
  sdiInstructionsDataFormattingMd?: InputMaybe<Scalars['String']['input']>;
  sdiInstructionsFaq?: InputMaybe<Array<GQSdiInstructionsFaq>>;
  sdiInstructionsIntroMd?: InputMaybe<Scalars['String']['input']>;
  sdiInstructionsSteps?: InputMaybe<Array<GQSdiIngestionInstructionStepInput>>;
  usedFor?: InputMaybe<Scalars['String']['input']>;
};

export type GQIngestionQuestion = GQIdInterface & {
  __typename?: 'IngestionQuestion';
  id: Scalars['ID']['output'];
  options: Array<GQIngestionQuestionOption>;
  responseType: GQIngestionQuestionResponseType;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type GQIngestionQuestionAnswer = {
  __typename?: 'IngestionQuestionAnswer';
  booleanValue: Maybe<Scalars['Boolean']['output']>;
  numberValue: Maybe<Scalars['Int']['output']>;
  stringValue: Maybe<Scalars['String']['output']>;
  yearMonthValue: Maybe<Scalars['YearMonth']['output']>;
};

export const GQIngestionQuestionKey = {
  DataFormatIsUnmelted: 'DataFormatIsUnmelted',
  EmployeeTravelExpensesIncludedTravelExpenseTypes:
    'EmployeeTravelExpensesIncludedTravelExpenseTypes',
  EmployeeTravelExpensesPercentCoverage:
    'EmployeeTravelExpensesPercentCoverage',
  EmployeeTravelExpensesPercentCoverageIsUnknown:
    'EmployeeTravelExpensesPercentCoverageIsUnknown',
  EmployeeTravelExpensesTimeRangeEndDate:
    'EmployeeTravelExpensesTimeRangeEndDate',
  EmployeeTravelExpensesTimeRangeStartDate:
    'EmployeeTravelExpensesTimeRangeStartDate',
  EmployeesAccountedForCovid: 'EmployeesAccountedForCovid',
  EmployeesIncludedContractors: 'EmployeesIncludedContractors',
  EmployeesTimeDifferedBetweenOffices: 'EmployeesTimeDifferedBetweenOffices',
  EmployeesTimeDifferedBetweenOfficesAdditionalInfo:
    'EmployeesTimeDifferedBetweenOfficesAdditionalInfo',
  EmployeesWorkedRemotely: 'EmployeesWorkedRemotely',
  EmployeesWorkedRemotelyEndDate: 'EmployeesWorkedRemotelyEndDate',
  EmployeesWorkedRemotelyStartDate: 'EmployeesWorkedRemotelyStartDate',
  FlightsCancellationsIncluded: 'FlightsCancellationsIncluded',
  FlightsCancellationsIncludedColumn: 'FlightsCancellationsIncludedColumn',
  FlightsCancellationsIncludedOther: 'FlightsCancellationsIncludedOther',
  FlightsDataCoverageEndDate: 'FlightsDataCoverageEndDate',
  FlightsDataCoverageStartDate: 'FlightsDataCoverageStartDate',
  FlightsRoundTripsIncluded: 'FlightsRoundTripsIncluded',
  FlightsRoundTripsIncludedColumn: 'FlightsRoundTripsIncludedColumn',
  RailCancellationsIncluded: 'RailCancellationsIncluded',
  RailCancellationsIncludedColumn: 'RailCancellationsIncludedColumn',
  RailCancellationsIncludedOther: 'RailCancellationsIncludedOther',
  RailDataCoverageEndDate: 'RailDataCoverageEndDate',
  RailDataCoverageStartDate: 'RailDataCoverageStartDate',
  RailRoundTripsIncluded: 'RailRoundTripsIncluded',
  RailRoundTripsIncludedColumn: 'RailRoundTripsIncludedColumn',
} as const;

export type GQIngestionQuestionKey =
  (typeof GQIngestionQuestionKey)[keyof typeof GQIngestionQuestionKey];
export type GQIngestionQuestionOption = {
  __typename?: 'IngestionQuestionOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQIngestionQuestionResponse = {
  __typename?: 'IngestionQuestionResponse';
  answer: Maybe<GQIngestionQuestionAnswer>;
  questionId: Scalars['String']['output'];
  responseType: GQIngestionQuestionResponseType;
};

export const GQIngestionQuestionResponseType = {
  Boolean: 'Boolean',
  Number: 'Number',
  String: 'String',
  YearMonth: 'YearMonth',
} as const;

export type GQIngestionQuestionResponseType =
  (typeof GQIngestionQuestionResponseType)[keyof typeof GQIngestionQuestionResponseType];
export type GQInitializeDefaultSupplierViewAdminInput = {
  orgId: Scalars['ID']['input'];
};

export type GQInitializeDefaultSupplierViewAdminPayload = {
  __typename?: 'InitializeDefaultSupplierViewAdminPayload';
  view: GQSupplierView;
};

export type GQInitializeDefaultSupplierViewPayload = {
  __typename?: 'InitializeDefaultSupplierViewPayload';
  view: GQSupplierView;
};

export type GQInitializeWorkosInput = {
  orgId: Scalars['ID']['input'];
};

export type GQInitializeWorkosPayload = {
  __typename?: 'InitializeWorkosPayload';
  org: GQOrganization;
};

export type GQInitiative = {
  costTimeseries: GQSimpleTimeseries;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  filters: GQFilterExpressionGroupWithNewFilters;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  plan: GQPlan;
  scheduleType: GQScheduleType;
};

export const GQInitiativeKind = {
  FootprintSlice: 'FootprintSlice',
  PrecomputedActivityBased: 'PrecomputedActivityBased',
} as const;

export type GQInitiativeKind =
  (typeof GQInitiativeKind)[keyof typeof GQInitiativeKind];
export const GQInitiativeType = {
  Buildings: 'Buildings',
  Cloud: 'Cloud',
  Commute: 'Commute',
  Custom: 'Custom',
  Travel: 'Travel',
} as const;

export type GQInitiativeType =
  (typeof GQInitiativeType)[keyof typeof GQInitiativeType];
export type GQInputDataQueryResult = {
  __typename?: 'InputDataQueryResult';
  rows: Array<Scalars['JSONString']['output']>;
  totalRows: Scalars['Int53']['output'];
};

export type GQInputQuantityByGroup = {
  __typename?: 'InputQuantityByGroup';
  attributes: Array<GQRegionAttribute>;
  inputQuantity: Scalars['Float']['output'];
};

export type GQInsertReportQuestionIdentifierInput = {
  identifier: Scalars['String']['input'];
};

export type GQInstructionsBundle = {
  __typename?: 'InstructionsBundle';
  ghgScopes: Maybe<Array<GQGhgScope>>;
  instructionsDataFormattingMd: Maybe<Scalars['String']['output']>;
  instructionsIntroMd: Maybe<Scalars['String']['output']>;
  instructionsSteps: Maybe<Array<GQInstructionsStep>>;
  potentialOverlaps: Maybe<Scalars['String']['output']>;
  sdiInstructionsDataFormattingMd: Maybe<Scalars['String']['output']>;
  sdiInstructionsFaq: Maybe<Array<GQSdiInstructionsFaqType>>;
  sdiInstructionsIntroMd: Maybe<Scalars['String']['output']>;
  sdiInstructionsSteps: Maybe<Array<GQSdiInstructionsStep>>;
  usedFor: Maybe<Scalars['String']['output']>;
};

export type GQInstructionsStep = {
  __typename?: 'InstructionsStep';
  description: Scalars['String']['output'];
  descriptionMd: Scalars['String']['output'];
  fileMetadataId: Maybe<Scalars['String']['output']>;
  publicUrl: Maybe<Scalars['String']['output']>;
  remotePath: Maybe<Scalars['String']['output']>;
};

export type GQIntegrationConnection = GQIdInterface & {
  __typename?: 'IntegrationConnection';
  additionalIdentifier: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  datasources: GQDatasourceConnection;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  integrationDataPulls: GQIntegrationDataPullConnection;
  integrationPartner: GQIntegrationPartner;
  integrationPartnerId: Scalars['ID']['output'];
  integrationRequests: GQIntegrationRequestConnection;
  isActive: Scalars['Boolean']['output'];
  netsuiteIntegrationSettings: Maybe<GQNetsuiteIntegrationSettings>;
  org: GQOrganization;
  orgId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQIntegrationConnectionDatasourcesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQIntegrationConnectionIntegrationDataPullsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQIntegrationConnectionIntegrationRequestsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQIntegrationConnectionConnection = {
  __typename?: 'IntegrationConnectionConnection';
  edges: Array<Maybe<GQIntegrationConnectionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQIntegrationConnectionEdge = {
  __typename?: 'IntegrationConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQIntegrationConnection>;
};

export type GQIntegrationDataPull = GQIdInterface & {
  __typename?: 'IntegrationDataPull';
  adtName: Scalars['String']['output'];
  adtUrl: Scalars['String']['output'];
  backgroundJob: Maybe<GQBackgroundJob>;
  chunksFetched: Scalars['Int']['output'];
  completed: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: GQWatershedEmployee;
  endYearMonth: Scalars['YearMonth']['output'];
  filesProcessed: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  integrationConnection: GQIntegrationConnection;
  startYearMonth: Scalars['YearMonth']['output'];
  totalChunksToFetch: Maybe<Scalars['Int']['output']>;
  totalFilesToProcess: Maybe<Scalars['Int']['output']>;
  userUploadedTable: Maybe<GQUserUploadedTable>;
};

export type GQIntegrationDataPullConnection = {
  __typename?: 'IntegrationDataPullConnection';
  edges: Array<Maybe<GQIntegrationDataPullEdge>>;
  pageInfo: GQPageInfo;
};

export type GQIntegrationDataPullEdge = {
  __typename?: 'IntegrationDataPullEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQIntegrationDataPull>;
};

export type GQIntegrationPartner = GQIdInterface & {
  __typename?: 'IntegrationPartner';
  activeIntegrationConnections: Array<GQIntegrationConnection>;
  applicableDatasources: Array<GQDatasource>;
  dataType: Scalars['String']['output'];
  externalId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isRequestOnly: Scalars['Boolean']['output'];
  logoUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GQIntegrationPartnerConnection = {
  __typename?: 'IntegrationPartnerConnection';
  edges: Array<Maybe<GQIntegrationPartnerEdge>>;
  pageInfo: GQPageInfo;
};

export type GQIntegrationPartnerEdge = {
  __typename?: 'IntegrationPartnerEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQIntegrationPartner>;
};

export type GQIntegrationRequest = {
  __typename?: 'IntegrationRequest';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  integrationConnectionId: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  requestBody: Maybe<Scalars['String']['output']>;
  requestMethod: Scalars['String']['output'];
  requestUrl: Maybe<Scalars['String']['output']>;
  responseBodyFileMetadataId: Maybe<Scalars['ID']['output']>;
  responseFileMetadata: Maybe<GQFileMetadata>;
  responseStatus: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQIntegrationRequestConnection = {
  __typename?: 'IntegrationRequestConnection';
  edges: Array<Maybe<GQIntegrationRequestEdge>>;
  pageInfo: GQPageInfo;
};

export type GQIntegrationRequestEdge = {
  __typename?: 'IntegrationRequestEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQIntegrationRequest>;
};

export type GQIntensityDenominatorDataPoint = {
  __typename?: 'IntensityDenominatorDataPoint';
  value: Scalars['Float']['output'];
  yearMonth: Scalars['YearMonth']['output'];
};

export type GQIntensityDenominatorDataPointInput = {
  value: Scalars['Float']['input'];
  yearMonth: Scalars['YearMonth']['input'];
};

export type GQIntensityDenominatorFilters = {
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
};

export const GQIntensityDenominatorKind = {
  Gmv: 'GMV',
  GrossProfit: 'GrossProfit',
  Headcount: 'Headcount',
  Megawatts: 'Megawatts',
  Merchants: 'Merchants',
  MonthlyActiveUsers: 'MonthlyActiveUsers',
  NetRevenue: 'NetRevenue',
  NightsStayed: 'NightsStayed',
  OperationalRevenue: 'OperationalRevenue',
  Orders: 'Orders',
  OrdersKg: 'OrdersKg',
  Patients: 'Patients',
  PayingSitesUnderManagement: 'PayingSitesUnderManagement',
  Revenue: 'Revenue',
  Shipments: 'Shipments',
  SquareFeet: 'SquareFeet',
  SupplierSpend: 'SupplierSpend',
  Units: 'Units',
} as const;

export type GQIntensityDenominatorKind =
  (typeof GQIntensityDenominatorKind)[keyof typeof GQIntensityDenominatorKind];
export type GQIntensityDenominatorRecord = {
  __typename?: 'IntensityDenominatorRecord';
  aggregateKind: GQAggregateKind;
  data: Array<GQIntensityDenominatorDataPoint>;
  intensityKind: GQIntensityDenominatorKind;
};

export type GQIntensityQuantitiesPerMonth = {
  __typename?: 'IntensityQuantitiesPerMonth';
  intensityKind: Scalars['String']['output'];
  month: Scalars['DateTime']['output'];
  quantity: Scalars['Float']['output'];
  unit: Scalars['String']['output'];
};

export type GQIntensityQuantitiesPerMonthPayload = {
  __typename?: 'IntensityQuantitiesPerMonthPayload';
  quantitiesPerMonth: Array<GQIntensityQuantitiesPerMonth>;
};

export type GQInterimTargetInput = {
  reductionAmount?: InputMaybe<Scalars['Float']['input']>;
  targetYearInclusive?: InputMaybe<Scalars['YearMonth']['input']>;
};

export const GQInternalFieldWatershed = {
  InternalCategoryId: 'internalCategoryId',
  InternalDescription: 'internalDescription',
  InternalGhgCategoryId: 'internalGhgCategoryId',
  InternalSubcategoryId: 'internalSubcategoryId',
} as const;

export type GQInternalFieldWatershed =
  (typeof GQInternalFieldWatershed)[keyof typeof GQInternalFieldWatershed];
export type GQInvalidateBackgroundJobCachePayload = {
  __typename?: 'InvalidateBackgroundJobCachePayload';
  success: Scalars['Boolean']['output'];
};

export type GQInvalidateMeasurementTestSuitePagePayload = {
  __typename?: 'InvalidateMeasurementTestSuitePagePayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQJoinProductWaitlistPayload = {
  __typename?: 'JoinProductWaitlistPayload';
  id: Scalars['ID']['output'];
  type: GQProductWaitlistType;
};

export type GQJoinReportWaitlistPayload = {
  __typename?: 'JoinReportWaitlistPayload';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
};

export type GQLabeledTimeseries = {
  __typename?: 'LabeledTimeseries';
  label: Scalars['String']['output'];
  timeseries: GQTimeseries;
};

export type GQLeaveProductWaitlistPayload = {
  __typename?: 'LeaveProductWaitlistPayload';
  type: GQProductWaitlistType;
};

export type GQLeaveReportWaitlistPayload = {
  __typename?: 'LeaveReportWaitlistPayload';
  kind: Scalars['String']['output'];
};

export type GQLegacyAssumptionAsMeasurementMapping =
  GQMeasurementMappingFields & {
    __typename?: 'LegacyAssumptionAsMeasurementMapping';
    author: GQWatershedEmployee;
    authorExplanation: Maybe<Scalars['String']['output']>;
    categoryId: Maybe<Scalars['String']['output']>;
    description: Maybe<Scalars['String']['output']>;
    endMonth: Scalars['YearMonth']['output'];
    ghgCategoryId: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    inputEmissionsSpecifier: Scalars['String']['output'];
    inputEmissionsSubspecifier: Scalars['String']['output'];
    outputEmissionsSpecifier: Scalars['String']['output'];
    outputEmissionsSubspecifier: Scalars['String']['output'];
    shouldInclude: Scalars['Boolean']['output'];
    startMonth: Scalars['YearMonth']['output'];
    subcategoryId: Maybe<Scalars['String']['output']>;
    updatedAt: Maybe<Scalars['DateTime']['output']>;
    vendorEntity: Scalars['String']['output'];
  };

export type GQLegacyAssumptionAsMeasurementMappingConnection = {
  __typename?: 'LegacyAssumptionAsMeasurementMappingConnection';
  edges: Array<Maybe<GQLegacyAssumptionAsMeasurementMappingEdge>>;
  pageInfo: GQPageInfo;
};

export type GQLegacyAssumptionAsMeasurementMappingEdge = {
  __typename?: 'LegacyAssumptionAsMeasurementMappingEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQLegacyAssumptionAsMeasurementMapping>;
};

export type GQLegacyFootprint = {
  __typename?: 'LegacyFootprint';
  bridgeWithDates: GQFootprintBridgeData;
  footprintBridgeDates: Maybe<GQFootprintBridgeDates>;
  footprintKinds: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  intensityDenominators: Array<GQIntensityDenominatorRecord>;
  summary: GQFootprintSummary;
  version: Scalars['String']['output'];
};

export type GQLegacyFootprintBridgeWithDatesArgs = {
  aggregateKind: GQAggregateKind;
  groupBy: GQFootprintBridgeGroupByColumn;
  intensityKind: InputMaybe<GQIntensityDenominatorKind>;
  shouldShowFootprintBridge: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQLegacyFootprintIntensityDenominatorsArgs = {
  filters: InputMaybe<GQIntensityDenominatorFilters>;
};

export type GQLegacyFootprintSummaryArgs = {
  footprintKind: Scalars['String']['input'];
};

export type GQLinkEmissionsModelsToReleaseInput = {
  emissionsModelStableIds: Array<Scalars['ID']['input']>;
  releaseId: Scalars['ID']['input'];
};

export type GQLinkGlobalFootprintTagsToReleaseInput = {
  globalFootprintTagInputs: Array<GQEmReleaseGlobalFootprintTagInput>;
  releaseId: Scalars['ID']['input'];
};

export type GQLintReportConfigInput = {
  id: Scalars['ID']['input'];
};

export type GQLintReportConfigPayload = {
  __typename?: 'LintReportConfigPayload';
  lintErrors: Array<GQConfigLinterError>;
  status: GQConfigLinterStatus;
};

export type GQLiteDataset = {
  __typename?: 'LiteDataset';
  datasetName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type GQLogCustomerHubViewedInput = {
  customerCompanyId: Scalars['ID']['input'];
};

export type GQLogCustomerHubViewedPayload = {
  __typename?: 'LogCustomerHubViewedPayload';
  customerCompanyId: Scalars['ID']['output'];
};

export type GQLogSupplyChainSurveyEventInput = {
  eventContext: GQSupplyChainSurveyEventContext;
  eventType: Scalars['String']['input'];
};

export type GQLogSupplyChainSurveyEventPayload = {
  __typename?: 'LogSupplyChainSurveyEventPayload';
  success: Scalars['Boolean']['output'];
};

export type GQLogUpdateVendorMatchingTaskInput = {
  vendorMatchingTaskId: Scalars['ID']['input'];
};

export type GQLogUpdateVendorMatchingTaskPayload = {
  __typename?: 'LogUpdateVendorMatchingTaskPayload';
  vendorMatchingTaskId: Scalars['ID']['output'];
};

export type GQLoginAsUserInput = {
  targetOrgId: Scalars['String']['input'];
  targetUserId?: InputMaybe<Scalars['String']['input']>;
};

export type GQLoginAsUserPayload = {
  __typename?: 'LoginAsUserPayload';
  isSuccessful: Scalars['Boolean']['output'];
  token: Maybe<Scalars['String']['output']>;
};

export type GQMappedExternalReportQuestions = {
  __typename?: 'MappedExternalReportQuestions';
  id: Scalars['ID']['output'];
  identicalQuestions: Array<GQExternalReportQuestion>;
  relatedQuestions: Array<GQExternalReportQuestion>;
};

export type GQMappingCategoryIds = {
  __typename?: 'MappingCategoryIds';
  categoryId: Array<GQCategoryInfo>;
  ghgCategoryId: Array<GQCategoryInfo>;
  subcategoryId: Array<GQCategoryInfo>;
};

export type GQMarkAdminUploadCompletedInput = {
  fileId: Scalars['ID']['input'];
  parse: Scalars['Boolean']['input'];
};

export type GQMarkAdminUploadCompletedPayload = {
  __typename?: 'MarkAdminUploadCompletedPayload';
  file: GQFileMetadata;
  parseJobId: Maybe<Scalars['ID']['output']>;
};

export type GQMarkMeasurementProjectDatasetCompletedInput = {
  isCompleted: Scalars['Boolean']['input'];
  measurementProjectDatasetId: Scalars['ID']['input'];
};

export type GQMarkMeasurementProjectDatasetCompletedPayload = {
  __typename?: 'MarkMeasurementProjectDatasetCompletedPayload';
  measurementProject: GQMeasurementProject;
  measurementProjectDataset: GQMeasurementProjectDataset;
};

export type GQMarkUserUploadAcknowledgedInput = {
  id: Scalars['ID']['input'];
};

export type GQMarkUserUploadAcknowledgedPayload = {
  __typename?: 'MarkUserUploadAcknowledgedPayload';
  userUpload: GQUserUpload;
};

export type GQMarkUserUploadAsProcessingInput = {
  fileId: Scalars['ID']['input'];
  shouldSubmitUserUploadTask?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQMarkUserUploadAsProcessingPayload = {
  __typename?: 'MarkUserUploadAsProcessingPayload';
  userUpload: Maybe<GQUserUpload>;
};

export type GQMarkUserUploadCompletedInput = {
  fileId: Scalars['ID']['input'];
};

export type GQMarkUserUploadCompletedPayload = {
  __typename?: 'MarkUserUploadCompletedPayload';
  file: GQFileMetadata;
  parseJobId: Maybe<Scalars['String']['output']>;
  userUpload: Maybe<GQUserUpload>;
};

export type GQMarkUserUploadedTablesIgnoredInput = {
  userUploadedTableIds: Array<Scalars['ID']['input']>;
};

export type GQMarkUserUploadedTablesIgnoredPayload = {
  __typename?: 'MarkUserUploadedTablesIgnoredPayload';
  userUploadedTables: Array<GQUserUploadedTable>;
};

export type GQMarkdownCardInfo = {
  __typename?: 'MarkdownCardInfo';
  contentMd: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQMarketplaceAllocationInstruction = {
  __typename?: 'MarketplaceAllocationInstruction';
  amountKwh: Scalars['Int']['output'];
  buildingName: Maybe<Scalars['String']['output']>;
  categoryId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  footprintKind: Maybe<Scalars['String']['output']>;
  ghgCategoryId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interval: Scalars['YMInterval']['output'];
  isZeroedOut: Scalars['Boolean']['output'];
  locationCity: Maybe<Scalars['String']['output']>;
  locationCountry: Maybe<Scalars['String']['output']>;
  locationGrid: Maybe<Scalars['String']['output']>;
  locationState: Maybe<Scalars['String']['output']>;
  product: Maybe<Scalars['String']['output']>;
  purchaseLineItem: GQMarketplacePurchaseLineItem;
  results: Maybe<GQMarketplaceAllocationInstructionResultConnection>;
  subcategoryId: Maybe<Scalars['String']['output']>;
  vendorEntity: Maybe<Scalars['String']['output']>;
  vendorSubentity: Maybe<Scalars['String']['output']>;
};

export type GQMarketplaceAllocationInstructionResultsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceAllocationInstructionConnection = {
  __typename?: 'MarketplaceAllocationInstructionConnection';
  edges: Array<Maybe<GQMarketplaceAllocationInstructionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceAllocationInstructionEdge = {
  __typename?: 'MarketplaceAllocationInstructionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceAllocationInstruction>;
};

export type GQMarketplaceAllocationInstructionImpact = GQIdInterface & {
  __typename?: 'MarketplaceAllocationInstructionImpact';
  allocatedKwh: Scalars['Float']['output'];
  amountKwh: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQMarketplaceAllocationInstructionResult = {
  __typename?: 'MarketplaceAllocationInstructionResult';
  amountAllocatedKwh: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isZeroedOut: Scalars['Boolean']['output'];
};

export type GQMarketplaceAllocationInstructionResultConnection = {
  __typename?: 'MarketplaceAllocationInstructionResultConnection';
  edges: Array<Maybe<GQMarketplaceAllocationInstructionResultEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceAllocationInstructionResultEdge = {
  __typename?: 'MarketplaceAllocationInstructionResultEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceAllocationInstructionResult>;
};

export type GQMarketplaceDeveloper = GQIdInterface & {
  __typename?: 'MarketplaceDeveloper';
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMd: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  projects: GQMarketplaceProjectConnection;
  url: Maybe<Scalars['String']['output']>;
};

export type GQMarketplaceDeveloperConnection = {
  __typename?: 'MarketplaceDeveloperConnection';
  edges: Array<Maybe<GQMarketplaceDeveloperEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceDeveloperEdge = {
  __typename?: 'MarketplaceDeveloperEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceDeveloper>;
};

export type GQMarketplaceDocument = GQIdInterface & {
  __typename?: 'MarketplaceDocument';
  createdAt: Scalars['Date']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  downloadUrl: Scalars['String']['output'];
  fileMetadata: GQFileMetadata;
  id: Scalars['ID']['output'];
  kind: GQMarketplaceDocumentKind;
  name: Scalars['String']['output'];
  noteMd: Maybe<Scalars['String']['output']>;
  org: GQOrganization;
  purchase: Maybe<GQMarketplacePurchase>;
  purchaseLineItem: Maybe<GQMarketplacePurchaseLineItem>;
  updatedAt: Scalars['Date']['output'];
  uploader: Maybe<GQWatershedEmployee>;
};

export type GQMarketplaceDocumentConnection = {
  __typename?: 'MarketplaceDocumentConnection';
  edges: Array<Maybe<GQMarketplaceDocumentEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceDocumentEdge = {
  __typename?: 'MarketplaceDocumentEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceDocument>;
};

export const GQMarketplaceDocumentKind = {
  ContractAppendix: 'ContractAppendix',
  ContractMasterAgreement: 'ContractMasterAgreement',
  ContractSupplierErpa: 'ContractSupplierERPA',
  ContractSupplierOffering: 'ContractSupplierOffering',
  DeliveryCertificateSupplier: 'DeliveryCertificateSupplier',
  DeliveryCertificateWatershed: 'DeliveryCertificateWatershed',
  InvoiceCustomer: 'InvoiceCustomer',
  InvoiceSupplier: 'InvoiceSupplier',
  RetirementNotice: 'RetirementNotice',
} as const;

export type GQMarketplaceDocumentKind =
  (typeof GQMarketplaceDocumentKind)[keyof typeof GQMarketplaceDocumentKind];
export type GQMarketplacePointOfContact = GQIdInterface & {
  __typename?: 'MarketplacePointOfContact';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQMarketplacePointOfContactConnection = {
  __typename?: 'MarketplacePointOfContactConnection';
  edges: Array<Maybe<GQMarketplacePointOfContactEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePointOfContactEdge = {
  __typename?: 'MarketplacePointOfContactEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePointOfContact>;
};

export type GQMarketplacePortfolio = {
  __typename?: 'MarketplacePortfolio';
  budgetUsdCents: Scalars['Int53']['output'];
  cleanPowerTargetAmount: Maybe<Scalars['Float']['output']>;
  creator: GQUser;
  footprintTco2e: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  interval: Maybe<Scalars['YMInterval']['output']>;
  investInRd: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  offsetTargetAmount: Maybe<Scalars['Float']['output']>;
  offsetTargetFraction: Maybe<Scalars['Float']['output']>;
  portfolioItems: GQMarketplacePortfolioItemConnection;
  purchase: Maybe<GQMarketplacePurchase>;
  targetAmount: Maybe<Scalars['Float']['output']>;
  targetFraction: Maybe<Scalars['Float']['output']>;
};

export type GQMarketplacePortfolioPortfolioItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplacePortfolioAllocation = GQIdInterface & {
  __typename?: 'MarketplacePortfolioAllocation';
  amount: Scalars['Int53']['output'];
  businessCategory: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  portfolioId: Scalars['String']['output'];
  unit: GQMarketplaceProjectArchetypeUnit;
};

export type GQMarketplacePortfolioAllocationConnection = {
  __typename?: 'MarketplacePortfolioAllocationConnection';
  edges: Array<Maybe<GQMarketplacePortfolioAllocationEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePortfolioAllocationEdge = {
  __typename?: 'MarketplacePortfolioAllocationEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePortfolioAllocation>;
};

export type GQMarketplacePortfolioConnection = {
  __typename?: 'MarketplacePortfolioConnection';
  edges: Array<GQMarketplacePortfolioEdge>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePortfolioEdge = {
  __typename?: 'MarketplacePortfolioEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePortfolio>;
};

export type GQMarketplacePortfolioItem = {
  __typename?: 'MarketplacePortfolioItem';
  amount: Scalars['Int53']['output'];
  archetype: GQMarketplaceProjectArchetype;
  fraction: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  portfolio: GQMarketplacePortfolio;
  priceUsdCents: Scalars['Int53']['output'];
};

export type GQMarketplacePortfolioItemConnection = {
  __typename?: 'MarketplacePortfolioItemConnection';
  edges: Array<GQMarketplacePortfolioItemEdge>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePortfolioItemEdge = {
  __typename?: 'MarketplacePortfolioItemEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePortfolioItem>;
};

export type GQMarketplaceProject = GQIdInterface & {
  __typename?: 'MarketplaceProject';
  archetype: GQMarketplaceProjectArchetype;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  developer: Maybe<GQMarketplaceDeveloper>;
  id: Scalars['ID']['output'];
  internalNotesMd: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offerings: GQMarketplaceProjectOfferingConnection;
  registryId: Maybe<Scalars['String']['output']>;
  status: GQMarketplaceProjectStatus;
  supplier: Maybe<GQMarketplaceSupplier>;
};

export type GQMarketplaceProjectOfferingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceProjectArchetype = GQIdInterface & {
  __typename?: 'MarketplaceProjectArchetype';
  additionalityRating: Maybe<Scalars['Int']['output']>;
  availability: Scalars['Float']['output'];
  bannerImageUrl: Maybe<Scalars['String']['output']>;
  certificateBlurb: Maybe<Scalars['String']['output']>;
  certificateColor: Maybe<Scalars['String']['output']>;
  descriptionMd: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  isUserVisible: Scalars['Boolean']['output'];
  kind: GQMarketplaceProjectArchetypeKind;
  mechanism: GQMarketplaceProjectArchetypeMechanism;
  medianPriceUsdCents: Maybe<Scalars['Int']['output']>;
  medianPriceWithFeeUsdCents: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  offerings: GQMarketplaceProjectOfferingConnection;
  permanenceRating: Maybe<Scalars['String']['output']>;
  priceUsdCentsMax: Maybe<Scalars['Int']['output']>;
  priceUsdCentsMin: Maybe<Scalars['Int']['output']>;
  projects: GQMarketplaceProjectConnection;
  summaryMd: Scalars['String']['output'];
  unit: GQMarketplaceProjectArchetypeUnit;
  visibleDevelopers: GQMarketplaceDeveloperConnection;
};

export type GQMarketplaceProjectArchetypeOfferingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceProjectArchetypeProjectsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceProjectArchetypeVisibleDevelopersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceProjectArchetypeConnection = {
  __typename?: 'MarketplaceProjectArchetypeConnection';
  edges: Array<Maybe<GQMarketplaceProjectArchetypeEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceProjectArchetypeEdge = {
  __typename?: 'MarketplaceProjectArchetypeEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceProjectArchetype>;
};

export const GQMarketplaceProjectArchetypeKind = {
  CarbonOffset: 'CarbonOffset',
  CleanEnergyCertificate: 'CleanEnergyCertificate',
  HighDemandCarbonOffset: 'HighDemandCarbonOffset',
  MatureCarbonRemoval: 'MatureCarbonRemoval',
  RAndDCarbonRemoval: 'RAndDCarbonRemoval',
} as const;

export type GQMarketplaceProjectArchetypeKind =
  (typeof GQMarketplaceProjectArchetypeKind)[keyof typeof GQMarketplaceProjectArchetypeKind];
export const GQMarketplaceProjectArchetypeMechanism = {
  CleanPower: 'CleanPower',
  Offset: 'Offset',
  Removal: 'Removal',
} as const;

export type GQMarketplaceProjectArchetypeMechanism =
  (typeof GQMarketplaceProjectArchetypeMechanism)[keyof typeof GQMarketplaceProjectArchetypeMechanism];
export const GQMarketplaceProjectArchetypeUnit = {
  MWh: 'MWh',
  Tonnes: 'Tonnes',
} as const;

export type GQMarketplaceProjectArchetypeUnit =
  (typeof GQMarketplaceProjectArchetypeUnit)[keyof typeof GQMarketplaceProjectArchetypeUnit];
export type GQMarketplaceProjectConnection = {
  __typename?: 'MarketplaceProjectConnection';
  edges: Array<Maybe<GQMarketplaceProjectEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceProjectEdge = {
  __typename?: 'MarketplaceProjectEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceProject>;
};

export type GQMarketplaceProjectOffering = GQIdInterface & {
  __typename?: 'MarketplaceProjectOffering';
  availability: Scalars['Float']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  noteMd: Maybe<Scalars['String']['output']>;
  priceUsdCents: Scalars['Float']['output'];
  priceWithFeeUsdCents: Scalars['Float']['output'];
  project: GQMarketplaceProject;
  supplier: GQMarketplaceSupplier;
  vintage: Maybe<Scalars['Float']['output']>;
};

export type GQMarketplaceProjectOfferingConnection = {
  __typename?: 'MarketplaceProjectOfferingConnection';
  edges: Array<Maybe<GQMarketplaceProjectOfferingEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceProjectOfferingEdge = {
  __typename?: 'MarketplaceProjectOfferingEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceProjectOffering>;
};

export const GQMarketplaceProjectStatus = {
  Active: 'Active',
  LowQuality: 'LowQuality',
  Unknown: 'Unknown',
} as const;

export type GQMarketplaceProjectStatus =
  (typeof GQMarketplaceProjectStatus)[keyof typeof GQMarketplaceProjectStatus];
export type GQMarketplacePurchase = GQIdInterface &
  GQMarketplacePurchaseTrackedProperties & {
    __typename?: 'MarketplacePurchase';
    createdAt: Scalars['DateTime']['output'];
    customerStatus: GQCustomerStatus;
    customerUser: Maybe<GQUser>;
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    documents: GQMarketplaceDocumentConnection;
    eacMetadata: Maybe<GQMarketplacePurchaseEacMetadata>;
    editor: Maybe<GQTrackedEditor>;
    history: GQMarketplacePurchaseHistoryConnection;
    id: Scalars['ID']['output'];
    lineItems: GQMarketplacePurchaseLineItemConnection;
    name: Scalars['String']['output'];
    noteMd: Scalars['String']['output'];
    offerExpiration: Maybe<Scalars['DateTime']['output']>;
    org: GQOrganization;
    portfolio: Maybe<GQMarketplacePortfolio>;
    status: GQMarketplacePurchaseStatus;
    totalCostUsdCents: Scalars['Int53']['output'];
    totalFeeUsdCents: Scalars['Int53']['output'];
    updatedAt: Scalars['DateTime']['output'];
    waitingOnParty: Maybe<GQMarketplaceWaitingOnParty>;
  };

export type GQMarketplacePurchaseDocumentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplacePurchaseLineItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplacePurchaseConnection = {
  __typename?: 'MarketplacePurchaseConnection';
  edges: Array<Maybe<GQMarketplacePurchaseEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePurchaseEacMetadata = {
  __typename?: 'MarketplacePurchaseEacMetadata';
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  ghgCategoryIds: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  interval: Scalars['YMInterval']['output'];
  marketTco2e: Scalars['Float']['output'];
  org: GQOrganization;
  purchase: GQMarketplacePurchase;
  requestNotes: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQMarketplacePurchaseEdge = {
  __typename?: 'MarketplacePurchaseEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePurchase>;
};

export type GQMarketplacePurchaseHistory = GQIdInterface &
  GQMarketplacePurchaseTrackedProperties & {
    __typename?: 'MarketplacePurchaseHistory';
    createdAt: Scalars['DateTime']['output'];
    customerUser: Maybe<GQUser>;
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    editor: Maybe<GQTrackedEditor>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    noteMd: Scalars['String']['output'];
    offerExpiration: Maybe<Scalars['DateTime']['output']>;
    org: GQOrganization;
    portfolio: Maybe<GQMarketplacePortfolio>;
    purchaseId: Scalars['ID']['output'];
    recordEndAt: Maybe<Scalars['DateTime']['output']>;
    recordStartAt: Scalars['DateTime']['output'];
    status: GQMarketplacePurchaseStatus;
    updatedAt: Scalars['DateTime']['output'];
  };

export type GQMarketplacePurchaseHistoryConnection = {
  __typename?: 'MarketplacePurchaseHistoryConnection';
  edges: Array<Maybe<GQMarketplacePurchaseHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePurchaseHistoryEdge = {
  __typename?: 'MarketplacePurchaseHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePurchaseHistory>;
};

export type GQMarketplacePurchaseLineItem = GQIdInterface &
  GQMarketplacePurchaseLineItemTrackedProperties & {
    __typename?: 'MarketplacePurchaseLineItem';
    allocations: GQMarketplaceAllocationInstructionConnection;
    amount: Scalars['Float']['output'];
    amountUnit: GQMarketplaceProjectArchetypeUnit;
    appliedToFootprint: Maybe<Scalars['Boolean']['output']>;
    archetype: Maybe<GQMarketplaceProjectArchetype>;
    cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
    closeDate: Maybe<Scalars['Date']['output']>;
    coverageInterval: Scalars['YMInterval']['output'];
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    deliveryDate: Maybe<Scalars['Date']['output']>;
    documents: GQMarketplaceDocumentConnection;
    eacMetadata: Maybe<GQMarketplacePurchaseLineItemEacMetadata>;
    editor: Maybe<GQTrackedEditor>;
    history: GQMarketplacePurchaseLineItemHistoryConnection;
    id: Scalars['ID']['output'];
    isClosed: Scalars['Boolean']['output'];
    isConfirmed: Scalars['Boolean']['output'];
    isDelivered: Scalars['Boolean']['output'];
    mechanism: GQMarketplaceProjectArchetypeMechanism;
    name: Scalars['String']['output'];
    noteMd: Scalars['String']['output'];
    offering: Maybe<GQMarketplaceProjectOffering>;
    org: GQOrganization;
    project: Maybe<GQMarketplaceProject>;
    purchase: GQMarketplacePurchase;
    status: GQMarketplacePurchaseLineItemStatus;
    unitCostUsdCents: Scalars['Float']['output'];
    unitFeeUsdCents: Scalars['Float']['output'];
    unitPriceUsdCents: Scalars['Float']['output'];
    updatedAt: Scalars['DateTime']['output'];
    vintage: Maybe<Scalars['Int']['output']>;
    waitingOnParty: Maybe<GQMarketplaceWaitingOnParty>;
  };

export type GQMarketplacePurchaseLineItemAllocationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplacePurchaseLineItemDocumentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplacePurchaseLineItemConnection = {
  __typename?: 'MarketplacePurchaseLineItemConnection';
  edges: Array<Maybe<GQMarketplacePurchaseLineItemEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePurchaseLineItemEacMetadata = {
  __typename?: 'MarketplacePurchaseLineItemEacMetadata';
  countryWithEacs: Scalars['String']['output'];
  countryWithEmissions: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  marketTco2e: Scalars['Float']['output'];
  mwh: Scalars['Float']['output'];
  numEacsRequested: Scalars['Int']['output'];
  org: GQOrganization;
  purchaseLineItem: GQMarketplacePurchaseLineItem;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQMarketplacePurchaseLineItemEdge = {
  __typename?: 'MarketplacePurchaseLineItemEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePurchaseLineItem>;
};

export type GQMarketplacePurchaseLineItemHistory = GQIdInterface &
  GQMarketplacePurchaseLineItemTrackedProperties & {
    __typename?: 'MarketplacePurchaseLineItemHistory';
    amount: Scalars['Float']['output'];
    amountUnit: GQMarketplaceProjectArchetypeUnit;
    archetype: Maybe<GQMarketplaceProjectArchetype>;
    cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
    closeDate: Maybe<Scalars['Date']['output']>;
    coverageInterval: Scalars['YMInterval']['output'];
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    deliveryDate: Maybe<Scalars['Date']['output']>;
    editor: Maybe<GQTrackedEditor>;
    id: Scalars['ID']['output'];
    isClosed: Scalars['Boolean']['output'];
    isDelivered: Scalars['Boolean']['output'];
    lineItemId: Scalars['ID']['output'];
    mechanism: GQMarketplaceProjectArchetypeMechanism;
    name: Scalars['String']['output'];
    noteMd: Scalars['String']['output'];
    offering: Maybe<GQMarketplaceProjectOffering>;
    org: GQOrganization;
    project: Maybe<GQMarketplaceProject>;
    purchase: GQMarketplacePurchase;
    recordEndAt: Maybe<Scalars['DateTime']['output']>;
    recordStartAt: Scalars['DateTime']['output'];
    status: GQMarketplacePurchaseLineItemStatus;
    unitCostUsdCents: Scalars['Float']['output'];
    unitFeeUsdCents: Scalars['Float']['output'];
    unitPriceUsdCents: Scalars['Float']['output'];
    updatedAt: Scalars['DateTime']['output'];
    vintage: Maybe<Scalars['Int']['output']>;
  };

export type GQMarketplacePurchaseLineItemHistoryConnection = {
  __typename?: 'MarketplacePurchaseLineItemHistoryConnection';
  edges: Array<Maybe<GQMarketplacePurchaseLineItemHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePurchaseLineItemHistoryEdge = {
  __typename?: 'MarketplacePurchaseLineItemHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePurchaseLineItemHistory>;
};

export const GQMarketplacePurchaseLineItemStatus = {
  Draft: 'Draft',
  SupplierDeliveredToWatershed: 'SupplierDeliveredToWatershed',
  SupplierSentInvoiceToWatershed: 'SupplierSentInvoiceToWatershed',
  WatershedDeliveredToCustomer: 'WatershedDeliveredToCustomer',
  WatershedPaidInvoiceToSupplier: 'WatershedPaidInvoiceToSupplier',
  WatershedSentRequestToSupplier: 'WatershedSentRequestToSupplier',
} as const;

export type GQMarketplacePurchaseLineItemStatus =
  (typeof GQMarketplacePurchaseLineItemStatus)[keyof typeof GQMarketplacePurchaseLineItemStatus];
export type GQMarketplacePurchaseLineItemTrackedProperties = {
  amount: Scalars['Float']['output'];
  amountUnit: GQMarketplaceProjectArchetypeUnit;
  archetype: Maybe<GQMarketplaceProjectArchetype>;
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  closeDate: Maybe<Scalars['Date']['output']>;
  coverageInterval: Scalars['YMInterval']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  deliveryDate: Maybe<Scalars['Date']['output']>;
  editor: Maybe<GQTrackedEditor>;
  isClosed: Scalars['Boolean']['output'];
  isDelivered: Scalars['Boolean']['output'];
  mechanism: GQMarketplaceProjectArchetypeMechanism;
  name: Scalars['String']['output'];
  noteMd: Scalars['String']['output'];
  offering: Maybe<GQMarketplaceProjectOffering>;
  project: Maybe<GQMarketplaceProject>;
  purchase: GQMarketplacePurchase;
  status: GQMarketplacePurchaseLineItemStatus;
  unitCostUsdCents: Scalars['Float']['output'];
  unitFeeUsdCents: Scalars['Float']['output'];
  unitPriceUsdCents: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vintage: Maybe<Scalars['Int']['output']>;
};

export const GQMarketplacePurchaseStatus = {
  CustomerCanceled: 'CustomerCanceled',
  CustomerConfirmedPurchase: 'CustomerConfirmedPurchase',
  CustomerInitiatedPurchase: 'CustomerInitiatedPurchase',
  CustomerPaidInvoiceAwaitingDelivery: 'CustomerPaidInvoiceAwaitingDelivery',
  CustomerRequestToWatershed: 'CustomerRequestToWatershed',
  CustomerSignedContract: 'CustomerSignedContract',
  WatershedConfirmedForCustomer: 'WatershedConfirmedForCustomer',
  WatershedFullyDelivered: 'WatershedFullyDelivered',
  WatershedPartiallyDelivered: 'WatershedPartiallyDelivered',
  WatershedSentContract: 'WatershedSentContract',
  WatershedSentInvoice: 'WatershedSentInvoice',
} as const;

export type GQMarketplacePurchaseStatus =
  (typeof GQMarketplacePurchaseStatus)[keyof typeof GQMarketplacePurchaseStatus];
export type GQMarketplacePurchaseTrackedProperties = {
  createdAt: Scalars['DateTime']['output'];
  customerUser: Maybe<GQUser>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  editor: Maybe<GQTrackedEditor>;
  name: Scalars['String']['output'];
  noteMd: Scalars['String']['output'];
  offerExpiration: Maybe<Scalars['DateTime']['output']>;
  status: GQMarketplacePurchaseStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQMarketplaceSupplier = GQIdInterface & {
  __typename?: 'MarketplaceSupplier';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  offerings: GQMarketplaceProjectOfferingConnection;
  pointsOfContact: Array<GQMarketplacePointOfContact>;
  projects: GQMarketplaceProjectConnection;
  watershedEmployees: Array<GQWatershedEmployee>;
};

export type GQMarketplaceSupplierOfferingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceSupplierProjectsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceSupplierConnection = {
  __typename?: 'MarketplaceSupplierConnection';
  edges: Array<Maybe<GQMarketplaceSupplierEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceSupplierEdge = {
  __typename?: 'MarketplaceSupplierEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceSupplier>;
};

export type GQMarketplaceVoluntaryCarbonMarketDisclosure = {
  __typename?: 'MarketplaceVoluntaryCarbonMarketDisclosure';
  url: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export const GQMarketplaceWaitingOnParty = {
  Customer: 'Customer',
  Supplier: 'Supplier',
  Watershed: 'Watershed',
} as const;

export type GQMarketplaceWaitingOnParty =
  (typeof GQMarketplaceWaitingOnParty)[keyof typeof GQMarketplaceWaitingOnParty];
export type GQMaterialFootprintRowReference = {
  __typename?: 'MaterialFootprintRowReference';
  id: Scalars['String']['output'];
  material: Scalars['String']['output'];
  percentageOfProduct: Scalars['Float']['output'];
};

export type GQMaterialFootprintRowUpdatedTaxonomy = {
  __typename?: 'MaterialFootprintRowUpdatedTaxonomy';
  emissionsPerKg: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  inputQuantity: Scalars['Float']['output'];
  inputQuantityPercentage: Maybe<Scalars['Float']['output']>;
  orgMaterial: Scalars['String']['output'];
  orgRawMaterial: Scalars['String']['output'];
  product: Array<GQProductFootprintRowReference>;
  totalEmissions: Scalars['Float']['output'];
  totalEmissionsPercent: Maybe<Scalars['Float']['output']>;
  vectors: Maybe<Array<GQVectorNameAndValues>>;
};

export type GQMaybeCreateIntegrationConnectionInput = {
  arcadia?: InputMaybe<GQCreateArcadiaIntegrationInput>;
  datasourceId?: InputMaybe<Scalars['String']['input']>;
  integrationPartnerId: Scalars['String']['input'];
  merge?: InputMaybe<GQCreateMergeIntegrationInput>;
  netSuite?: InputMaybe<GQCreateNetSuiteIntegrationInput>;
  tripActions?: InputMaybe<GQCreateTripActionsIntegrationInput>;
};

export type GQMaybeCreateIntegrationConnectionPayload = {
  __typename?: 'MaybeCreateIntegrationConnectionPayload';
  datasources: Array<GQDatasource>;
  integrationConnection: Maybe<GQIntegrationConnection>;
};

export type GQMeasuredFootprintDetails = {
  __typename?: 'MeasuredFootprintDetails';
  emissionsTco2e: Scalars['Float']['output'];
  footprintSharedAt: Scalars['Date']['output'];
};

export type GQMeasurementContextItem = {
  __typename?: 'MeasurementContextItem';
  author: Maybe<GQWatershedEmployee>;
  createdAt: Scalars['DateTime']['output'];
  descriptionMd: Scalars['String']['output'];
  history: Array<GQMeasurementContextItemHistory>;
  id: Scalars['ID']['output'];
  org: GQOrganization;
  relations: Array<
    | GQActivityDataTable
    | GQDataIssue
    | GQDataset
    | GQDatasource
    | GQFileMetadata
    | GQFinancialsReviewItem
    | GQFootprintQuestion
    | GQMeasurementProject
    | GQMeasurementVerificationItemQuestion
  >;
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  urls: Array<Scalars['String']['output']>;
};

export type GQMeasurementContextItemConnection = {
  __typename?: 'MeasurementContextItemConnection';
  edges: Array<Maybe<GQMeasurementContextItemEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMeasurementContextItemEdge = {
  __typename?: 'MeasurementContextItemEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMeasurementContextItem>;
};

export type GQMeasurementContextItemHistory = {
  __typename?: 'MeasurementContextItemHistory';
  author: Maybe<GQWatershedEmployee>;
  createdAt: Scalars['DateTime']['output'];
  descriptionMd: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  measurementContextItem: GQMeasurementContextItem;
  org: GQOrganization;
  relations: Array<
    | GQActivityDataTable
    | GQDataIssue
    | GQDataset
    | GQDatasource
    | GQFileMetadata
    | GQFinancialsReviewItem
    | GQFootprintQuestion
    | GQMeasurementProject
    | GQMeasurementVerificationItemQuestion
  >;
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  urls: Array<Scalars['String']['output']>;
};

export const GQMeasurementContextItemRelationType = {
  ActivityDataTable: 'ActivityDataTable',
  DataIssue: 'DataIssue',
  Dataset: 'Dataset',
  Datasource: 'Datasource',
  FileMetadata: 'FileMetadata',
  FinancialsReviewItem: 'FinancialsReviewItem',
  FootprintQuestion: 'FootprintQuestion',
  MeasurementProject: 'MeasurementProject',
  MeasurementVerificationItemQuestion: 'MeasurementVerificationItemQuestion',
} as const;

export type GQMeasurementContextItemRelationType =
  (typeof GQMeasurementContextItemRelationType)[keyof typeof GQMeasurementContextItemRelationType];
export type GQMeasurementDataMappingTask = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'MeasurementDataMappingTask';
    completedAt: Maybe<Scalars['DateTime']['output']>;
    dataset: GQDataset;
    id: Scalars['ID']['output'];
    measurementProject: GQMeasurementProject;
  };

export const GQMeasurementEmailType = {
  Kickoff: 'Kickoff',
  Reminder: 'Reminder',
} as const;

export type GQMeasurementEmailType =
  (typeof GQMeasurementEmailType)[keyof typeof GQMeasurementEmailType];
export type GQMeasurementMapping = GQIdInterface &
  GQMeasurementMappingFields & {
    __typename?: 'MeasurementMapping';
    author: GQWatershedEmployee;
    authorExplanation: Maybe<Scalars['String']['output']>;
    categoryId: Maybe<Scalars['String']['output']>;
    description: Maybe<Scalars['String']['output']>;
    endMonth: Scalars['YearMonth']['output'];
    ghgCategoryId: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    inputEmissionsSpecifier: Scalars['String']['output'];
    inputEmissionsSubspecifier: Scalars['String']['output'];
    outputEmissionsSpecifier: Scalars['String']['output'];
    outputEmissionsSubspecifier: Scalars['String']['output'];
    shouldInclude: Scalars['Boolean']['output'];
    startMonth: Scalars['YearMonth']['output'];
    subcategoryId: Maybe<Scalars['String']['output']>;
    updatedAt: Maybe<Scalars['DateTime']['output']>;
    vendorEntity: Scalars['String']['output'];
  };

export type GQMeasurementMappingConnection = {
  __typename?: 'MeasurementMappingConnection';
  edges: Array<Maybe<GQMeasurementMappingEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMeasurementMappingEdge = {
  __typename?: 'MeasurementMappingEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMeasurementMapping>;
};

export type GQMeasurementMappingFields = {
  author: GQWatershedEmployee;
  authorExplanation: Maybe<Scalars['String']['output']>;
  categoryId: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  endMonth: Scalars['YearMonth']['output'];
  ghgCategoryId: Maybe<Scalars['String']['output']>;
  inputEmissionsSpecifier: Scalars['String']['output'];
  inputEmissionsSubspecifier: Scalars['String']['output'];
  outputEmissionsSpecifier: Scalars['String']['output'];
  outputEmissionsSubspecifier: Scalars['String']['output'];
  shouldInclude: Scalars['Boolean']['output'];
  startMonth: Scalars['YearMonth']['output'];
  subcategoryId: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  vendorEntity: Scalars['String']['output'];
};

export type GQMeasurementMappingOutputOption = {
  __typename?: 'MeasurementMappingOutputOption';
  description: Scalars['String']['output'];
  efKgCo2eAverage: Maybe<Scalars['Float']['output']>;
  efKgCo2eDescription: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inputEmissionsSpecifier: Scalars['String']['output'];
  outputEmissionsSpecifier: Scalars['String']['output'];
  outputEmissionsSubspecifier: Scalars['String']['output'];
  units: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQMeasurementMappingOutputOptionConnection = {
  __typename?: 'MeasurementMappingOutputOptionConnection';
  edges: Array<Maybe<GQMeasurementMappingOutputOptionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMeasurementMappingOutputOptionEdge = {
  __typename?: 'MeasurementMappingOutputOptionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMeasurementMappingOutputOption>;
};

export type GQMeasurementParentTask = GQIdInterface &
  GQMeasurementTask & {
    __typename?: 'MeasurementParentTask';
    approvers: Array<GQUser>;
    assignees: Array<GQUser>;
    dataset: Maybe<GQDataset>;
    id: Scalars['ID']['output'];
    isAssignedToWatershed: Scalars['Boolean']['output'];
    link: Maybe<Scalars['String']['output']>;
    lockState: Maybe<GQApprovalTargetLockState>;
    object:
      | GQCustomEmissionsFactorsSetup
      | GQDataIssue
      | GQFacilitiesSetup
      | GQFinancialsReviewGroup
      | GQFinancialsReviewItem
      | GQFootprintQuestion
      | GQMeasurementDataMappingTask
      | GQMeasurementProject
      | GQMeasurementProjectDataset
      | GQMeasurementVerificationItem
      | GQUserUploadTask
      | GQValueMappingTask
      | GQVendorMatchingTask;
    status: GQTaskWatershedProcessState;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
    subTasks: Array<GQMeasurementParentTask | GQMeasurementTaskFlat>;
    taskGroup: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export type GQMeasurementProject = GQClimateProgramTimelineObject &
  GQContextRelationInterface &
  GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'MeasurementProject';
    active: Scalars['Boolean']['output'];
    adminUrl: Scalars['String']['output'];
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQFootprintQuestion
      | GQMeasurementProject
      | GQMeasurementVerificationItemQuestion
    >;
    buildingSurveyStatus: Array<GQBuildingSurveyStatus>;
    canEditCoverageInterval: Scalars['Boolean']['output'];
    climateProgramProjects: Array<GQClimateProgramProject>;
    completedAt: Maybe<Scalars['Date']['output']>;
    coverageEndDate: Scalars['Date']['output'];
    coverageInterval: Scalars['YMInterval']['output'];
    coverageStartDate: Scalars['Date']['output'];
    createdAt: Scalars['DateTime']['output'];
    dataIssues: Array<GQDataIssue>;
    datasetsWithStages: Array<GQDatasetWithStage>;
    deadline: Maybe<Scalars['Date']['output']>;
    deletedAt: Maybe<Scalars['Date']['output']>;
    facilitiesBartsStatus: GQMeasurementProjectFacilitiesBartsStatus;
    footprintGenerationInfo: GQFootprintGenerationInfo;
    footprintStatus: GQMeasurementProjectFootprintStatus;
    id: Scalars['ID']['output'];
    measurementProjectDatasets: Array<GQMeasurementProjectDataset>;
    measurementTasks: Array<GQMeasurementParentTask>;
    measurementTimeline: Maybe<GQMeasurementTimeline>;
    measurementVerificationItems: GQMeasurementVerificationItemConnection;
    measurementVerificationItemsForDataset: GQMeasurementVerificationItemConnection;
    name: Scalars['String']['output'];
    orgId: Scalars['ID']['output'];
    setupAt: Maybe<Scalars['Date']['output']>;
    setupBy: Maybe<GQUser>;
    sharedFiles: GQSharedFileConnection;
    status: GQMeasurementProjectStatus;
    supportedValueMappingRules: Array<Scalars['String']['output']>;
    taskStatusDumps: Array<GQTaskStatusDump>;
    updatedAt: Scalars['DateTime']['output'];
    userUploadTasks: GQUserUploadTaskConnection;
    userUploadVerificationLogs: Array<Maybe<GQUserUploadVerificationLog>>;
    valueMappingTask: Maybe<GQValueMappingTask>;
  };

export type GQMeasurementProjectMeasurementVerificationItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMeasurementProjectMeasurementVerificationItemsForDatasetArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  datasetId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMeasurementProjectSharedFilesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  forUserId: InputMaybe<Scalars['ID']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMeasurementProjectUserUploadTasksArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMeasurementProjectValueMappingTaskArgs = {
  valueMappingRuleId: Scalars['String']['input'];
};

export type GQMeasurementProjectConnection = {
  __typename?: 'MeasurementProjectConnection';
  edges: Array<Maybe<GQMeasurementProjectEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMeasurementProjectDataset = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'MeasurementProjectDataset';
    dataset: GQDataset;
    id: Scalars['ID']['output'];
    isCompleted: Scalars['Boolean']['output'];
    modelId: Maybe<Scalars['ID']['output']>;
  };

export type GQMeasurementProjectDatasetProgress = {
  __typename?: 'MeasurementProjectDatasetProgress';
  completed: Array<GQDataset>;
  needsUpload: Array<GQDataset>;
  processing: Array<GQDataset>;
};

export type GQMeasurementProjectEdge = {
  __typename?: 'MeasurementProjectEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMeasurementProject>;
};

export type GQMeasurementProjectFacilitiesBartsStatus = {
  __typename?: 'MeasurementProjectFacilitiesBartsStatus';
  debugInfo: Maybe<Scalars['String']['output']>;
  upToDate: Scalars['Boolean']['output'];
};

export const GQMeasurementProjectFootprintStatus = {
  Draft: 'Draft',
  NonPublishableDraft: 'NonPublishableDraft',
  None: 'None',
  Published: 'Published',
} as const;

export type GQMeasurementProjectFootprintStatus =
  (typeof GQMeasurementProjectFootprintStatus)[keyof typeof GQMeasurementProjectFootprintStatus];
export type GQMeasurementProjectPayload = {
  __typename?: 'MeasurementProjectPayload';
  measurementProject: GQMeasurementProject;
};

export const GQMeasurementProjectStatus = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  Planned: 'Planned',
} as const;

export type GQMeasurementProjectStatus =
  (typeof GQMeasurementProjectStatus)[keyof typeof GQMeasurementProjectStatus];
export type GQMeasurementProjectStatusShare = {
  __typename?: 'MeasurementProjectStatusShare';
  id: Scalars['ID']['output'];
  measurementProjectId: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
};

export type GQMeasurementResourceEvent = {
  __typename?: 'MeasurementResourceEvent';
  author: Maybe<GQUser>;
  createdAt: Scalars['DateTime']['output'];
  eventData: Scalars['JSONString']['output'];
  eventKind: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  userId: Maybe<Scalars['ID']['output']>;
};

export const GQMeasurementResourcePublicationErrorCode = {
  AcceptedExecutionNotLatest: 'AcceptedExecutionNotLatest',
} as const;

export type GQMeasurementResourcePublicationErrorCode =
  (typeof GQMeasurementResourcePublicationErrorCode)[keyof typeof GQMeasurementResourcePublicationErrorCode];
export const GQMeasurementResourcePublishWorkflowOutcome = {
  ErrorOnEmissionsModelValidation: 'ErrorOnEmissionsModelValidation',
  ErrorOnTestResultAcceptance: 'ErrorOnTestResultAcceptance',
  TestResultsAcceptedAndResourcePublished:
    'TestResultsAcceptedAndResourcePublished',
  TestResultsRejected: 'TestResultsRejected',
  TimeoutOnTestResultAcceptance: 'TimeoutOnTestResultAcceptance',
} as const;

export type GQMeasurementResourcePublishWorkflowOutcome =
  (typeof GQMeasurementResourcePublishWorkflowOutcome)[keyof typeof GQMeasurementResourcePublishWorkflowOutcome];
export const GQMeasurementStatus = {
  AtRisk: 'AtRisk',
  Complete: 'Complete',
  Measuring: 'Measuring',
  OffTrack: 'OffTrack',
  OnTrack: 'OnTrack',
  Planning: 'Planning',
} as const;

export type GQMeasurementStatus =
  (typeof GQMeasurementStatus)[keyof typeof GQMeasurementStatus];
export type GQMeasurementTask = {
  approvers: Array<GQUser>;
  assignees: Array<GQUser>;
  dataset: Maybe<GQDataset>;
  id: Scalars['ID']['output'];
  isAssignedToWatershed: Scalars['Boolean']['output'];
  link: Maybe<Scalars['String']['output']>;
  lockState: Maybe<GQApprovalTargetLockState>;
  object:
    | GQCustomEmissionsFactorsSetup
    | GQDataIssue
    | GQFacilitiesSetup
    | GQFinancialsReviewGroup
    | GQFinancialsReviewItem
    | GQFootprintQuestion
    | GQMeasurementDataMappingTask
    | GQMeasurementProject
    | GQMeasurementProjectDataset
    | GQMeasurementVerificationItem
    | GQUserUploadTask
    | GQValueMappingTask
    | GQVendorMatchingTask;
  status: GQTaskWatershedProcessState;
  statusSimplified: GQTaskWatershedProcessStateSimplified;
  taskGroup: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQMeasurementTaskFlat = GQIdInterface &
  GQMeasurementTask & {
    __typename?: 'MeasurementTaskFlat';
    approvers: Array<GQUser>;
    assignees: Array<GQUser>;
    dataset: Maybe<GQDataset>;
    id: Scalars['ID']['output'];
    isAssignedToWatershed: Scalars['Boolean']['output'];
    link: Maybe<Scalars['String']['output']>;
    lockState: Maybe<GQApprovalTargetLockState>;
    object:
      | GQCustomEmissionsFactorsSetup
      | GQDataIssue
      | GQFacilitiesSetup
      | GQFinancialsReviewGroup
      | GQFinancialsReviewItem
      | GQFootprintQuestion
      | GQMeasurementDataMappingTask
      | GQMeasurementProject
      | GQMeasurementProjectDataset
      | GQMeasurementVerificationItem
      | GQUserUploadTask
      | GQValueMappingTask
      | GQVendorMatchingTask;
    status: GQTaskWatershedProcessState;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
    taskGroup: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export const GQMeasurementTaskForDataIssueKind = {
  UserUploadTask: 'UserUploadTask',
  ValueMappingTask: 'ValueMappingTask',
} as const;

export type GQMeasurementTaskForDataIssueKind =
  (typeof GQMeasurementTaskForDataIssueKind)[keyof typeof GQMeasurementTaskForDataIssueKind];
export type GQMeasurementTaskObject = {
  id: Scalars['ID']['output'];
};

export type GQMeasurementTestRow = {
  __typename?: 'MeasurementTestRow';
  activityRow: Scalars['JSONString']['output'];
  bartRowId: Scalars['Int']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export const GQMeasurementTestRowResultStatus = {
  Added: 'Added',
  Changed: 'Changed',
  Matched: 'Matched',
  Removed: 'Removed',
} as const;

export type GQMeasurementTestRowResultStatus =
  (typeof GQMeasurementTestRowResultStatus)[keyof typeof GQMeasurementTestRowResultStatus];
export type GQMeasurementTestSuite = {
  __typename?: 'MeasurementTestSuite';
  calculationTags: Array<GQCalculationTag>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  emissionsModelReleases: Array<GQEmissionsModelRelease>;
  footprintTestSuiteConfig: Maybe<GQFootprintTestSuiteConfig>;
  id: Scalars['ID']['output'];
  kind: Maybe<GQMeasurementTestSuiteKind>;
  latestChangelogEntry: GQMeasurementTestSuiteChangelog;
  latestFootprintTestSuiteExecution: Maybe<GQFootprintTestSuiteExecution>;
  latestFootprintTestSuiteExecutionOnTheT: Maybe<GQFootprintTestSuiteExecution>;
  latestMethodologyTestSuiteExecution: Maybe<GQMethodologyTestSuiteExecution>;
  measurementTestSuiteBarts: Array<GQMeasurementTestSuiteBart>;
  organization: Maybe<GQOrganization>;
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQMeasurementTestSuiteBart = {
  __typename?: 'MeasurementTestSuiteBart';
  activityTypeFields: Array<GQBusinessActivityTypeField>;
  activityTypeName: Scalars['String']['output'];
  businessActivityTypeVersion: GQBusinessActivityTypeVersion;
  businessActivityTypeVersionId: Scalars['String']['output'];
  fieldDefaults: Scalars['JSONString']['output'];
  hiddenFields: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  measurementTestRowCount: Scalars['Int']['output'];
  measurementTestRows: Array<GQMeasurementTestRow>;
  title: Scalars['String']['output'];
};

export type GQMeasurementTestSuiteChangelog = {
  __typename?: 'MeasurementTestSuiteChangelog';
  author: Maybe<GQUser>;
  authorId: Maybe<Scalars['ID']['output']>;
  changelog: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  measurementResourceEvent: Maybe<GQMeasurementResourceEvent>;
  measurementResourceEventId: Maybe<Scalars['ID']['output']>;
};

export type GQMeasurementTestSuiteChangelogConnection = {
  __typename?: 'MeasurementTestSuiteChangelogConnection';
  edges: Array<GQMeasurementTestSuiteChangelogEdge>;
  pageInfo: GQPageInfo;
};

export type GQMeasurementTestSuiteChangelogEdge = {
  __typename?: 'MeasurementTestSuiteChangelogEdge';
  cursor: Scalars['String']['output'];
  node: GQMeasurementTestSuiteChangelog;
};

export const GQMeasurementTestSuiteKind = {
  Critical: 'Critical',
} as const;

export type GQMeasurementTestSuiteKind =
  (typeof GQMeasurementTestSuiteKind)[keyof typeof GQMeasurementTestSuiteKind];
export const GQMeasurementTestSuiteStage = {
  Footprint: 'footprint',
  Methodology: 'methodology',
} as const;

export type GQMeasurementTestSuiteStage =
  (typeof GQMeasurementTestSuiteStage)[keyof typeof GQMeasurementTestSuiteStage];
export const GQMeasurementTestSuiteStageExecutionStatus = {
  Errored: 'Errored',
  Failed: 'Failed',
  Invalid: 'Invalid',
  Passed: 'Passed',
  Queued: 'Queued',
  Running: 'Running',
} as const;

export type GQMeasurementTestSuiteStageExecutionStatus =
  (typeof GQMeasurementTestSuiteStageExecutionStatus)[keyof typeof GQMeasurementTestSuiteStageExecutionStatus];
export type GQMeasurementTimeline = GQIdInterface & {
  __typename?: 'MeasurementTimeline';
  deadline: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  kickoff: Scalars['Date']['output'];
};

export type GQMeasurementVerificationItem = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'MeasurementVerificationItem';
    assignee: Maybe<GQUser>;
    dataset: Maybe<GQDataset>;
    datasetName: Scalars['String']['output'];
    description: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    measurementProject: GQMeasurementProject;
    name: Scalars['String']['output'];
    pointOfContact: Maybe<GQWatershedEmployee>;
    questions: GQMeasurementVerificationItemQuestionConnection;
    status: GQMeasurementVerificationItemStatus;
  };

export type GQMeasurementVerificationItemQuestionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMeasurementVerificationItemConnection = {
  __typename?: 'MeasurementVerificationItemConnection';
  edges: Array<GQMeasurementVerificationItemEdge>;
  pageInfo: GQPageInfo;
};

export type GQMeasurementVerificationItemContentMetadata =
  | GQMeasurementVerificationItemContentMetadataFileOverlaps
  | GQMeasurementVerificationItemContentMetadataQuantityTrend
  | GQMeasurementVerificationItemContentMetadataTopAccounts;

export type GQMeasurementVerificationItemContentMetadataFileOverlaps =
  GQIdInterface & {
    __typename?: 'MeasurementVerificationItemContentMetadataFileOverlaps';
    files: Array<GQFileMetadata>;
    id: Scalars['ID']['output'];
  };

export type GQMeasurementVerificationItemContentMetadataQuantityTrend =
  GQIdInterface & {
    __typename?: 'MeasurementVerificationItemContentMetadataQuantityTrend';
    aggregateField: Maybe<Scalars['String']['output']>;
    anomaly: Maybe<GQQuantityTrendAnomaly>;
    filters: Maybe<Array<GQDatasetSummaryFilter>>;
    frozenDatasourceSummaries: Maybe<Array<GQFrozenDatasourceSummary>>;
    id: Scalars['ID']['output'];
  };

export type GQMeasurementVerificationItemContentMetadataTopAccounts =
  GQIdInterface & {
    __typename?: 'MeasurementVerificationItemContentMetadataTopAccounts';
    frozenDatasourceSummaries: Maybe<Array<GQFrozenDatasourceSummary>>;
    id: Scalars['ID']['output'];
  };

export type GQMeasurementVerificationItemEdge = {
  __typename?: 'MeasurementVerificationItemEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMeasurementVerificationItem>;
};

export type GQMeasurementVerificationItemQuestion = GQContextRelationInterface &
  GQIdInterface & {
    __typename?: 'MeasurementVerificationItemQuestion';
    adminUrl: Scalars['String']['output'];
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQFootprintQuestion
      | GQMeasurementProject
      | GQMeasurementVerificationItemQuestion
    >;
    answerText: Maybe<Scalars['String']['output']>;
    contentKind: GQMeasurementVerificationQuestionContentKind;
    contentMetadata: GQMeasurementVerificationItemContentMetadata;
    contentMetadataJson: Scalars['JSONString']['output'];
    contentSubtitle: Maybe<Scalars['String']['output']>;
    contentTitle: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['DateTime']['output'];
    discussion: Maybe<GQDataIssue>;
    id: Scalars['ID']['output'];
    item: GQMeasurementVerificationItem;
    name: Scalars['String']['output'];
    publisher: Maybe<GQWatershedEmployee>;
    questionKind: GQMeasurementVerificationQuestionKind;
    questionPrompt: Scalars['String']['output'];
    rank: Maybe<Scalars['Int53']['output']>;
    section: GQMeasurementVerificationSection;
    status: GQMeasurementVerificationQuestionStatus;
    updatedAt: Scalars['DateTime']['output'];
    verificationItemId: Scalars['ID']['output'];
  };

export type GQMeasurementVerificationItemQuestionConnection = {
  __typename?: 'MeasurementVerificationItemQuestionConnection';
  edges: Array<GQMeasurementVerificationItemQuestionEdge>;
  pageInfo: GQPageInfo;
};

export type GQMeasurementVerificationItemQuestionEdge = {
  __typename?: 'MeasurementVerificationItemQuestionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMeasurementVerificationItemQuestion>;
};

export type GQMeasurementVerificationItemQuestionOrder = {
  questionId: Scalars['ID']['input'];
  rank: Scalars['Int53']['input'];
};

export const GQMeasurementVerificationItemStatus = {
  Complete: 'Complete',
  InProgress: 'InProgress',
  NotStarted: 'NotStarted',
} as const;

export type GQMeasurementVerificationItemStatus =
  (typeof GQMeasurementVerificationItemStatus)[keyof typeof GQMeasurementVerificationItemStatus];
export const GQMeasurementVerificationQuestionContentKind = {
  FileOverlaps: 'FileOverlaps',
  QuantityTrend: 'QuantityTrend',
  TopAccountsList: 'TopAccountsList',
} as const;

export type GQMeasurementVerificationQuestionContentKind =
  (typeof GQMeasurementVerificationQuestionContentKind)[keyof typeof GQMeasurementVerificationQuestionContentKind];
export const GQMeasurementVerificationQuestionKind = {
  FreeText: 'FreeText',
  YesNo: 'YesNo',
} as const;

export type GQMeasurementVerificationQuestionKind =
  (typeof GQMeasurementVerificationQuestionKind)[keyof typeof GQMeasurementVerificationQuestionKind];
export const GQMeasurementVerificationQuestionStatus = {
  UserApproved: 'UserApproved',
  UserRejected: 'UserRejected',
  UserReplied: 'UserReplied',
  WatershedDraft: 'WatershedDraft',
  WatershedSubmitted: 'WatershedSubmitted',
} as const;

export type GQMeasurementVerificationQuestionStatus =
  (typeof GQMeasurementVerificationQuestionStatus)[keyof typeof GQMeasurementVerificationQuestionStatus];
export const GQMeasurementVerificationSection = {
  FileOverlaps: 'FileOverlaps',
  FilteredTrendAndAnomalies: 'FilteredTrendAndAnomalies',
  OverallTrendAndAnomalies: 'OverallTrendAndAnomalies',
  TopAccounts: 'TopAccounts',
} as const;

export type GQMeasurementVerificationSection =
  (typeof GQMeasurementVerificationSection)[keyof typeof GQMeasurementVerificationSection];
export type GQMergeAssetsInput = {
  assetIdToDelete: Scalars['ID']['input'];
  assetIdToKeep: Scalars['ID']['input'];
};

export type GQMergeAssetsPayload = {
  __typename?: 'MergeAssetsPayload';
  id: Scalars['ID']['output'];
};

export type GQMethodologyConfig = {
  __typename?: 'MethodologyConfig';
  id: Scalars['ID']['output'];
  spendMethodology: Maybe<GQSpendMethodology>;
  wasteEmissionsExclusions: Maybe<Array<GQWasteEmissionsExclusion>>;
};

export type GQMethodologyConfigInput = {
  selectedReleasesByBusinessActivityType?: InputMaybe<
    Scalars['JSONString']['input']
  >;
  spendMethodology?: InputMaybe<GQSpendMethodology>;
  wasteEmissionsExclusions?: InputMaybe<Array<GQWasteEmissionsExclusionInput>>;
};

export type GQMethodologyCustomizationBundle = {
  __typename?: 'MethodologyCustomizationBundle';
  id: Scalars['String']['output'];
  orgName: Scalars['String']['output'];
  referenceDataRevisionRows: Array<GQReferenceDataRevisionRow>;
  revisionCreatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQMethodologyCustomizationColumnDisplayFormat = {
  __typename?: 'MethodologyCustomizationColumnDisplayFormat';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  parquetColumnName: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export const GQMethodologyCustomizationDisplayConfigIcon = {
  Flash: 'Flash',
  Logistics: 'Logistics',
  SupplierEngagement: 'SupplierEngagement',
} as const;

export type GQMethodologyCustomizationDisplayConfigIcon =
  (typeof GQMethodologyCustomizationDisplayConfigIcon)[keyof typeof GQMethodologyCustomizationDisplayConfigIcon];
export type GQMethodologyCustomizationDisplayRow = {
  __typename?: 'MethodologyCustomizationDisplayRow';
  customizationTypeId: Scalars['String']['output'];
  mappingConfigIds: Array<Scalars['String']['output']>;
  typeConfig: GQMethodologyCustomizationTypeConfig;
};

export type GQMethodologyCustomizationDisplaySection = {
  __typename?: 'MethodologyCustomizationDisplaySection';
  sectionName: Scalars['String']['output'];
  sectionRows: Array<GQMethodologyCustomizationDisplayRow>;
};

export type GQMethodologyCustomizationFootprintPreviewPayload = {
  __typename?: 'MethodologyCustomizationFootprintPreviewPayload';
  footprintSnapshot: Maybe<GQFootprintSnapshot>;
  id: Scalars['String']['output'];
  mappingArtifactCreatedAt: Maybe<Scalars['Date']['output']>;
};

export type GQMethodologyCustomizationMappingConfig = {
  __typename?: 'MethodologyCustomizationMappingConfig';
  id: Scalars['String']['output'];
  keyColumns: Scalars['JSONString']['output'];
  missingSourceDataConfig: GQMethodologyCustomizationMissingSourceDataConfig;
};

export const GQMethodologyCustomizationMissingDataSourceConfigImage = {
  ClimateBuildingBlocks: 'ClimateBuildingBlocks',
} as const;

export type GQMethodologyCustomizationMissingDataSourceConfigImage =
  (typeof GQMethodologyCustomizationMissingDataSourceConfigImage)[keyof typeof GQMethodologyCustomizationMissingDataSourceConfigImage];
export type GQMethodologyCustomizationMissingSourceDataConfig = {
  __typename?: 'MethodologyCustomizationMissingSourceDataConfig';
  buttonLabel: Scalars['String']['output'];
  imageName: GQMethodologyCustomizationMissingDataSourceConfigImage;
  message: Scalars['String']['output'];
};

export type GQMethodologyCustomizationTypeConfig = {
  __typename?: 'MethodologyCustomizationTypeConfig';
  description: Scalars['String']['output'];
  displayColumns: Array<GQMethodologyCustomizationColumnDisplayFormat>;
  displayName: Scalars['String']['output'];
  globalColumnsToCustomerUploadedColumns: Scalars['JSONString']['output'];
  globalReferenceDataSources: Array<Scalars['String']['output']>;
  iconName: GQMethodologyCustomizationDisplayConfigIcon;
  id: Scalars['String']['output'];
  quantityColumnName: Scalars['String']['output'];
};

export type GQMethodologyExplainer = {
  __typename?: 'MethodologyExplainer';
  biCalculationChains: Array<GQCalculationChain>;
  biCalculationTraces: Array<Scalars['UntypedData']['output']>;
  biExampleTracesForFingerprints: GQExampleTracesForFingerprints;
  biGhgCategoryIds: Array<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
};

export type GQMethodologyExplainerBiCalculationChainsArgs = {
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQMethodologyExplainerBiCalculationTracesArgs = {
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQMethodologyExplainerBiExampleTracesForFingerprintsArgs = {
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQMethodologyExplainerBiGhgCategoryIdsArgs = {
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQMethodologyExplainerCalculationTrace = {
  __typename?: 'MethodologyExplainerCalculationTrace';
  convertedUnit: Maybe<Scalars['String']['output']>;
  emissionsModelEvaluationId: Scalars['String']['output'];
  emissionsModelVersionId: Scalars['String']['output'];
  fingerprint: Scalars['String']['output'];
  footprintExclusionRule: Maybe<GQFootprintExclusionRule>;
  /** @deprecated No longer usedc */
  footprintSnapshotExclusionRule: Maybe<GQFootprintSnapshotExclusionRule>;
  id: Scalars['String']['output'];
  inputUnit: Maybe<Scalars['String']['output']>;
  quantitySpecifier: Maybe<Scalars['String']['output']>;
  sumOfConvertedActivity: Scalars['Float']['output'];
  /** @deprecated Migrating to sumOfMeasure */
  sumOfEmissions: Scalars['Float']['output'];
  sumOfFunctionalUnit: Scalars['Float']['output'];
  sumOfMeasure: Scalars['Float']['output'];
};

export type GQMethodologyTestRowResult = {
  __typename?: 'MethodologyTestRowResult';
  createdAt: Scalars['DateTime']['output'];
  diff: Scalars['JSONString']['output'];
  emissionsModelStable: GQEmissionsModelStable;
  emissionsModelStableId: Scalars['ID']['output'];
  frozenExpectation: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
  measurementTestRow: GQMeasurementTestRow;
  measurementTestRowId: Scalars['ID']['output'];
  result: Scalars['JSONString']['output'];
  resultAccepted: Scalars['Boolean']['output'];
  status: GQMeasurementTestRowResultStatus;
};

export type GQMethodologyTestSuiteExecution = {
  __typename?: 'MethodologyTestSuiteExecution';
  author: Maybe<GQWatershedEmployee>;
  authorId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  methodologyTestRowResults: Array<GQMethodologyTestRowResult>;
  status: GQMeasurementTestSuiteStageExecutionStatus;
};

export type GQMethodologyTestSuiteValidationResults = {
  __typename?: 'MethodologyTestSuiteValidationResults';
  id: Scalars['ID']['output'];
  isValid: Scalars['Boolean']['output'];
  warningMessage: Maybe<Scalars['String']['output']>;
};

export type GQMigrateCardControls024524Payload = {
  __typename?: 'MigrateCardControls024524Payload';
  badReportConfigIds: Array<Scalars['ID']['output']>;
  count: Scalars['Int']['output'];
};

export type GQMigrateOrgPlansToEnterpriseRedux = {
  orgIds: Array<Scalars['ID']['input']>;
};

export type GQMigrateOrgPlansToEnterpriseReduxPayload = {
  __typename?: 'MigrateOrgPlansToEnterpriseReduxPayload';
  migratedPlans: Array<GQPlan>;
};

export type GQMigrateOrgPlansToOrgFiscalMonthInput = {
  orgIds: Array<Scalars['ID']['input']>;
};

export type GQMigrateOrgPlansToOrgFiscalMonthPayload = {
  __typename?: 'MigrateOrgPlansToOrgFiscalMonthPayload';
  forecasts: Array<GQForecast>;
  plans: Array<GQPlan>;
};

export type GQMigratePermissionViewFootprintDetailToManageSuppliersInput = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQMigratePermissionViewFootprintDetailToManageSuppliersPayload = {
  __typename?: 'MigratePermissionViewFootprintDetailToManageSuppliersPayload';
  userAndRoleIds: Array<Scalars['ID']['output']>;
};

export type GQMutation = {
  __typename?: 'Mutation';
  acceptFinanceImportDiff: GQAcceptFinanceImportDiffPayload;
  acceptFootprintTestSuiteExecutionResults: GQAcceptFootprintTestSuiteExecutionResultsPayload;
  acceptMethodologyTestSuiteExecutionResults: GQAcceptMethodologyTestSuiteExecutionResultsPayload;
  acceptReportAnswerVerifierFailures: Maybe<GQAcceptReportAnswerVerifierFailuresPayload>;
  acknowledgeReportAnswerWarning: GQReportAnswerWarning;
  addCalculationTagToReferenceData: GQAddCalculationTagToReferenceDataPayload;
  addCalculationTagToStableModel: GQAddCalculationTagToStableModelPayload;
  addCompaniesToPeerCompanyGroups: Maybe<GQAddCompaniesToPeerCompanyGroupsPayload>;
  addDataApprovalComment: GQAddDataApprovalCommentPayload;
  addEmissionsModelToOrg: GQAddEmissionsModelToOrgPayload;
  addEngagementTaskComment: Maybe<GQAddEngagementTaskCommentPayload>;
  addHeaderToCustomReport: Maybe<GQAddHeaderToCustomReportPayload>;
  addMarketplaceSupplierPointOfContact: Maybe<GQAddMarketplaceSupplierPointOfContactPayload>;
  addMarketplaceSupplierPointOfContactConnection: Maybe<GQAddMarketplaceSupplierPointOfContactConnectionPayload>;
  addMarketplaceSupplierWatershedEmployee: Maybe<GQAddMarketplaceSupplierWatershedEmployeePayload>;
  addMetricsToCustomReport: Maybe<GQAddMetricsToCustomReportPayload>;
  addOrgAccessToRelease: GQEmissionsModelReleasePayload;
  addOrgPointOfContact: Maybe<GQAddOrgPointOfContactPayload>;
  addReportQuestionNote: GQAddReportQuestionNotePayload;
  addTestRowsToTestSuiteBart: GQAddTestRowsToTestSuiteBartPayload;
  addUserUploadCandidatesToFileFeedEmbeddedSession: Scalars['Boolean']['output'];
  addWatershedEmployee: Maybe<GQAddWatershedEmployeePayload>;
  adminUpdatePlan: Maybe<GQUpdatePlanPayload>;
  answerMeasurementVerificationItemQuestion: GQAnswerMeasurementVerificationItemQuestionPayload;
  applyGlobalTagToTestData: Maybe<GQApplyGlobalTagToTestDataPayload>;
  applyIntegrationConnection: GQApplyIntegrationConnectionPayload;
  applyTagsToPipelineResult: Maybe<GQApplyTagsToPipelineResultPayload>;
  approveAllValueMappingRecords: Maybe<GQApproveAllValueMappingRecordsPayload>;
  approveCompanyIngestionDiff: GQProcessCompanyIngestionDiffPayload;
  approveCompanyIngestionUpdate: GQProcessCompanyIngestionUpdatePayload;
  approveCtsTransformTestCase: GQApproveCtsTransformTestCasePayload;
  approveData: GQApproveDataPayload;
  approveReportAnswerVerifiers: Maybe<GQApproveReportAnswerVerifiersPayload>;
  archiveFootprintCustomReport: Maybe<GQArchiveFootprintCustomReportPayload>;
  archiveFootprintSnapshot: Maybe<GQArchiveFootprintSnapshotPayload>;
  archivePipelineResult: Maybe<GQArchivePipelineResultPayload>;
  archiveReferenceDataCitation: GQArchiveReferenceDataCitationPayload;
  archiveReferenceDataRevision: Maybe<GQArchiveReferenceDataRevisionPayload>;
  archiveReferenceDataSource: Maybe<GQArchiveReferenceDataSourcePayload>;
  archiveReferenceDataVersion: Maybe<GQArchiveReferenceDataVersionPayload>;
  archiveReport: Maybe<GQArchiveReportPayload>;
  assignFacilitiesToTasks: GQAssignFacilitiesToTasksPayload;
  assignMeasurementTask: GQAssignMeasurementTaskPayload;
  assignToContainer: Maybe<GQAssignToContainerPayload>;
  assignUserUploadTask: GQAssignUserUploadTaskPayload;
  autosaveEmissionsModel: GQAutosaveEmissionsModelPayload;
  batchSetCustomFieldForBuildings: GQBatchSetCustomFieldForBuildingsPayload;
  batchSetCustomFieldForDatasources: GQBatchSetCustomFieldForDatasourcesPayload;
  batchUnsetCustomFieldForBuildings: GQBatchUnsetCustomFieldForBuildingsPayload;
  batchUnsetCustomFieldForDatasources: GQBatchUnsetCustomFieldForDatasourcesPayload;
  bulkCreateUploadTasks: GQBulkCreateUploadTasksPayload;
  bulkCreateUsersForFacilities: GQBulkCreateUsersForFacilitiesPayload;
  bulkImportMarketplaceAllocationInstructions: GQBulkImportMarketplaceAllocationInstructionsPayload;
  bulkStartCompanySurveyEngagement: Maybe<GQBulkStartCompanySurveyEngagementPayload>;
  bulkUpdateMarketplacePurchaseLineItems: Maybe<GQBulkUpdateMarketplacePurchaseLineItemsPayload>;
  bulkUpdateReportQuestions: GQBulkUpdateReportQuestionsPayload;
  cancelWorkflow: Maybe<GQCancelWorkflowPayload>;
  cdpApiManualAddRow: GQCdpApiManualAddRowPayload;
  cdpApiManualDeleteRow: GQCdpApiManualDeleteRowPayload;
  cdpApiManualPutResponse: GQCdpApiManualPutResponsePayload;
  checkAndGenerateFacilitiesBarts: GQFacilitiesSdiPayload;
  checkAndHealForecast: GQCheckAndHealForecastPayload;
  clearAllReportAnswerVerifierFailures: Maybe<GQClearAllReportAnswerVerifiersFailurePayload>;
  clearReportAnswerVerifierFailure: Maybe<GQClearReportAnswerVerifierFailurePayload>;
  clearReportAnswerVerifierFailures: Maybe<GQClearReportAnswerVerifierFailuresPayload>;
  completeMeasurementProject: Maybe<GQCompleteMeasurementProjectPayload>;
  completeOnboarding: Maybe<GQCompleteOnboardingPayload>;
  completeUserUploadTask: GQCompleteUserUploadTaskPayload;
  computeAllReportAnswers: Array<GQReportAnswer>;
  computeReportAnswerWarningsForOrg: Maybe<GQComputeReportAnswerWarningsForOrgPayload>;
  configureAutoBart: Maybe<GQConfigureAutoBartPayload>;
  confirmMarketplacePurchase: Maybe<GQConfirmMarketplacePurchasePayload>;
  confirmUtilitySchema: GQConfirmUtilitySchemaPayload;
  convertCtsBack: GQConvertCtsBackPayload;
  convertCtsToTSchema: GQConvertCtsToTSchemaPayload;
  convertGlobalReleaseToOrgGated: GQEmissionsModelReleasePayload;
  convertOrgGatedReleaseToGlobal: GQEmissionsModelReleasePayload;
  copyEngagementTaskConfigToOrgForAdmin: GQEngagementTaskConfig;
  copyReportQuestionAnswers: Maybe<GQCopyReportQuestionAnswersPayload>;
  copySimulationScenario: GQSimulationScenario;
  createActivityDataTable: Maybe<GQCreateActivityDataTablePayload>;
  createAdminUpload: Maybe<GQCreateAdminUploadPayload>;
  createApiKey: Maybe<GQCreateApiKeyPayload>;
  createAssetComment: GQCreateAssetCommentPayload;
  createAssetCorporate: Maybe<GQCreateAssetCorporatePayload>;
  createAssetGroup: Maybe<GQCreateAssetGroupPayload>;
  createAssetHolding: Maybe<GQCreateAssetHoldingPayload>;
  createAssetPersonalMotorVehicleInsurance: Maybe<GQCreateAssetPersonalMotorVehicleInsurancePayload>;
  createAssetRealEstate: Maybe<GQCreateAssetRealEstatePayload>;
  createAssetSovereignBond: Maybe<GQCreateAssetSovereignBondPayload>;
  createAssetYear: Maybe<GQCreateAssetYearNewPayload>;
  createBiCustomMetric: GQBiCustomMetric;
  createBiSavedView: GQBiSavedView;
  createBiSavedViewAndPropagateToCustomReports: GQBiSavedView;
  createBuilding: Maybe<GQCreateBuildingPayload>;
  createBuildingUtility: GQCreateBuildingUtilityPayload;
  createCalculationTag: GQCalculationTagPayload;
  createCandidateRowEntry: GQUserUploadCandidateEntry;
  createCanonicalClimateProgramProject: GQCanonicalClimateProgramProjectPayload;
  createCanonicalClimateProgramProjectRequirementDataset: GQCanonicalClimateProgramProjectRequirementDatasetPayload;
  createCanonicalClimateProgramProjectRequirementGhgp: GQCanonicalClimateProgramProjectRequirementGhgpPayload;
  createCanonicalDataset: Maybe<GQCanonicalDatasetPayload>;
  createCanonicalDatasource: Maybe<GQCanonicalDatasourcePayload>;
  createCdpApiRegistration: GQCreateCdpApiRegistrationPayload;
  createCdpColumnMappings: Maybe<GQCreateCdpColumnMappingsPayload>;
  createCdpQuestionMappings: Maybe<GQCreateCdpQuestionMappingsPayload>;
  createClimateProgramCalendarEvent: GQClimateProgramCalendarEventPayload;
  createClimateProgramProject: GQClimateProgramProjectPayload;
  createClimateProgramProjectDatasetRequirement: GQClimateProgramProjectPayload;
  createCompanyChangeRequestCreate: GQCreateCompanyChangeRequestCreatePayload;
  createCompanyClimateCommitment: Maybe<GQCompanyClimateCommitmentPayload>;
  createCompanyRelationship: GQCreateCompanyRelationshipPayload;
  createCompanySourceKey: GQCompanySourceKeyPayload;
  createCompositeDataSource: GQCreateCompositeDataSourcePayload;
  createCorporateSandboxEstimation: Maybe<GQCreateCorporateSandboxEstimationPayload>;
  createCsrdDma: GQCreateCsrdDmaPayload;
  createCsrdMaterialMetric: Maybe<GQCreateCsrdMaterialMetricPayload>;
  createCsrdMaterialStandard: Maybe<GQCreateCsrdMaterialStandardPayload>;
  createCts: GQCreateCtsPayload;
  createCtsForm: GQUserUploadTask;
  createCtsFormEntry: GQCtsFormEntry;
  createCtsTransformTestCase: Maybe<GQCreateCtsTransformTestCasePayload>;
  createCtsVersion: GQCreateCtsVersionPayload;
  createCtsvOneSchemaFileFeed: Maybe<GQCustomerTargetSchemaVersion>;
  createCustomEmissionsFactorsReferenceDataRevision: GQCreateCustomEmissionsFactorsReferenceDataRevisionPayload;
  createCustomerInitiatedDataIssue: Maybe<GQCreateCustomerInitiatedDataIssuePayload>;
  createDataIssue: Maybe<GQCreateDataIssuePayload>;
  createDataIssueComment: Maybe<GQCreateDataIssueCommentPayload>;
  createDataset: Maybe<GQDatasetPayload>;
  createDatasource: GQDatasourcePayload;
  createDatasourceAdmin: GQAdminDatasourcePayload;
  createDemoOrg: Maybe<GQCreateDemoOrgPayload>;
  createDiscussion: GQCreateDiscussionPayload;
  createDrilldownSavedView: Maybe<GQDrilldownSavedView>;
  createEacMarketplaceProjects: GQCreateEacMarketplaceProjectsPayload;
  createEacPriceEstimate: GQCreateEacPriceEstimatePayload;
  createEmissionsModel: GQCreateEmissionsModelPayload;
  createEmissionsModelRelease: GQEmissionsModelReleasePayload;
  createEmployeeReport: Maybe<GQCreateEmployeeReportPayload>;
  createEngagementCohort: GQEngagementCohort;
  createEngagementCohortsForCompany: Array<GQEngagementCohort>;
  createEngagementTaskAttachments: Maybe<GQCreateEngagementTaskAttachmentsPayload>;
  createEngagementTaskConfigForAdmin: GQEngagementTaskConfigPayload;
  createExternalReportQuestion: GQCreateExternalReportQuestionPayload;
  createExternalReportTypeWithRevision: GQCreateExternalReportTypeWithRevisionPayload;
  createFacilitiesSetupTask: GQCreateFacilitiesSetupTaskPayload;
  createFinanceImport: GQCreateFinanceImportPayload;
  createFinanceReportingTags: GQCreateFinanceReportingTagsPayload;
  createFinanceSavedView: Maybe<GQCreateFinanceSavedViewPayload>;
  createFinanceTag: GQCreateFinanceTagPayload;
  createFinanceUploadTemplate: GQCreateFinanceUploadTemplatePayload;
  createFinancialsReviewItemDrafts: Maybe<GQCreateFinancialsReviewItemDraftsPayload>;
  createFootprint: GQCreateFootprintPayload;
  createFootprintCustomReport: Maybe<GQCreateFootprintCustomReportPayload>;
  createFootprintDisclosure: Maybe<GQCreateFootprintDisclosurePayload>;
  createFootprintSliceInitiative: GQCreateFootprintSliceInitiativePayload;
  createFootprintSnapshot: GQCreateFootprintSnapshotPayload;
  createFootprintVersionPin: Maybe<GQCreateFootprintVersionPinPayload>;
  createFund: Maybe<GQCreateFundPayload>;
  createFundAdmin: Maybe<GQCreateFundPayload>;
  createIngestionCustomField: GQCreateIngestionCustomFieldPayload;
  createMarketplaceAllocationInstruction: Maybe<GQCreateMarketplaceAllocationInstructionPayload>;
  createMarketplaceDeveloper: Maybe<GQCreateMarketplaceDeveloperPayload>;
  createMarketplaceEacPurchase: Maybe<GQCreateMarketplaceEacPurchasePayload>;
  createMarketplacePortfolio: Maybe<GQCreateMarketplacePortfolioPayload>;
  createMarketplacePortfolioItem: Maybe<GQCreateMarketplacePortfolioItemPayload>;
  createMarketplaceProject: Maybe<GQCreateMarketplaceProjectPayload>;
  createMarketplaceProjectArchetype: Maybe<GQCreateMarketplaceProjectArchetypePayload>;
  createMarketplaceProjectOffering: Maybe<GQCreateMarketplaceProjectOfferingPayload>;
  createMarketplacePurchase: Maybe<GQCreateMarketplacePurchasePayload>;
  createMarketplacePurchaseFromPortfolio: Maybe<GQCreateMarketplacePurchaseFromPortfolioPayload>;
  createMarketplacePurchaseLineItem: Maybe<GQCreateMarketplacePurchaseLineItemPayload>;
  createMarketplaceSupplier: Maybe<GQCreateMarketplaceSupplierPayload>;
  createMeasurementContextItem: GQCreateMeasurementContextItemPayload;
  createMeasurementProjectFromCanonicalClimateProgramProject: GQCreateMeasurementProjectFromCanonicalClimateProgramProjectPayload;
  createMeasurementProjectFromWizard: GQCreateMeasurementProjectFromWizardPayload;
  createMeasurementTestSuite: GQCreateMeasurementTestSuitePayload;
  createMeasurementTestSuiteBart: GQCreateMeasurementTestSuiteBartPayload;
  createMeasurementVerificationItem: GQCreateMeasurementVerificationItemPayload;
  createMeasurementVerificationItemQuestion: GQCreateMeasurementVerificationItemQuestionPayload;
  createOneSchemaSessionForApiUpload: Maybe<GQCreateOneSchemaSessionForApiUploadPayload>;
  createOrgRole: Maybe<GQCreateRolePayload>;
  createOrgStructureImportTemplate: GQCreateOrgStructureImportTemplatePayload;
  createOrgUnit: GQCreateOrgUnitPayload;
  createOrgUnitType: GQCreateOrgUnitTypePayload;
  createOrgUnitTypeRelationship: GQCreateOrgUnitTypeRelationshipPayload;
  createOrganization: Maybe<GQOrganizationPayload>;
  createPeerCompanyGroup: Maybe<GQPeerCompanyGroupPayload>;
  createPermissionItem: Maybe<GQCreatePermissionItemPayload>;
  createPlan: Maybe<GQCreatePlanPayload>;
  createPlanTarget: Maybe<GQCreatePlanTargetPayload>;
  createPlanTargetRedux: Maybe<GQCreatePlanTargetPayload>;
  createPlanWithDefaults: GQCreatePlanWithDefaultsPayload;
  createPrecomputedActivityBasedInitiative: GQCreatePrecomputedActivityBasedInitiativePayload;
  createPrivateDisclosure: Maybe<GQCreatePrivateDisclosurePayload>;
  createPublicDisclosure: GQCreatePublicDisclosurePayload;
  createPublicDisclosureTarget: GQCreatePublicDisclosureTargetPayload;
  createRealEstateSandboxEstimation: Maybe<GQCreateRealEstateSandboxEstimationPayload>;
  createReferenceDataCitation: GQCreateReferenceDataCitationPayload;
  createReferenceDataOverlayTemplate: Maybe<GQCreateReferenceDataOverlayTemplatePayload>;
  createReferenceDataSource: GQCreateReferenceDataSourcePayload;
  createReferenceDataVersion: GQCreateReferenceDataVersionPayload;
  createRenewableElectricityTarget: Maybe<GQCreatePlanTargetPayload>;
  createReportAttachments: Maybe<GQCreateReportAttachmentsPayload>;
  createReportConfig: Maybe<GQCreateReportConfigPayload>;
  createReportConfigFromSrc: Maybe<GQCreateReportConfigPayload>;
  createReportFileAttachments: Maybe<GQCreateReportFileAttachmentsPayload>;
  createReportQuestion: Maybe<GQCreateReportQuestionPayload>;
  createReportQuestionContainer: Maybe<GQCreateReportQuestionContainerPayload>;
  createReportQuestionIdentifierMapping: GQUpdateReportQuestionIdentifierMappingPayload;
  createReportURLAttachmentItem: Maybe<GQCreateReportUrlAttachmentItemPayload>;
  createReportWithConfig: GQReportWithConfig;
  createReportWithConfigAdmin: GQReportWithConfig;
  createReportWithCustomConfig: Maybe<GQCreateReportWithCustomConfigPayload>;
  createReportWithCustomConfigAdmin: Maybe<GQCreateReportWithCustomConfigAdminPayload>;
  createRole: Maybe<GQCreateRolePayload>;
  createSbtiTargetExclusion: GQCreateSbtiTargetExclusionPayload;
  createSimulationPortfolio: GQSimulationPortfolio;
  createSimulationScenario: GQSimulationScenario;
  createSingleUploaderFacilitiesTask: GQCreateSingleUploaderFacilitiesTaskPayload;
  createSnapshot: GQCreateSnapshotPayload;
  createSupplierAttachments: Maybe<GQCreateSupplierAttachmentsPayload>;
  createSupplierChart: Maybe<GQCreateSupplierChartPayload>;
  createSupplierChartAdmin: Maybe<GQCreateSupplierChartPayloadAdmin>;
  createSupplierContact: Maybe<GQCreateSupplierContactPayload>;
  createSupplierSavedView: GQCreateSupplierSavedViewPayload;
  createSupplierScoreCriteria: GQCreateSupplierScoreCriteriaPayload;
  createSupplierTableColumn: Maybe<GQCreateSupplierTableColumnPayload>;
  createSupportingDocuments: Maybe<GQCreateSupportingDocumentsPayload>;
  createTarget: GQCreateTargetPayload;
  createTcfdArchetypeOpportunity: GQTcfdArchetypeOpportunity;
  createTcfdArchetypeRisk: GQTcfdArchetypeRisk;
  createUpdateAndDeleteForecastScenarios: GQCreateUpdateAndDeleteForecastScenariosPayload;
  createUser: Maybe<GQCreateUserPayload>;
  createUserAdminVersion: Maybe<GQCreateUserAdminVersionPayload>;
  createUserPermissionRequest: Maybe<GQCreateUserPermissionRequestPayload>;
  createUserUploadTask: Maybe<GQCreateUserUploadTaskPayload>;
  createUserUploads: GQCreateUserUploadsPayload;
  createUtilityDocs: GQCreateUtilityDocsPayload;
  createValueMappingComment: GQValueMappingComment;
  createWatershedFootprintReviewRequest: GQCreateWatershedFootprintReviewRequestPayload;
  debugPlaygroundSql: Maybe<Scalars['JSONString']['output']>;
  declineCompanyIngestionDiff: GQProcessCompanyIngestionDiffPayload;
  declineCompanyIngestionUpdate: GQProcessCompanyIngestionUpdatePayload;
  deleteAllAnswersForReport: Maybe<GQDeleteAllAnswersForReportPayload>;
  deleteAllMarketplaceAllocationInstructions: Maybe<GQDeleteAllMarketplaceAllocationInstructionsPayload>;
  deleteAllReductionsData: GQDeleteAllReductionsDataPayload;
  deleteAssetComment: GQDeleteAssetCommentPayload;
  deleteAssetHolding: Maybe<GQDeleteAssetHoldingPayload>;
  deleteAssetHoldings: Maybe<GQDeleteAssetHoldingsPayload>;
  deleteAssetYears: GQDeleteAssetYearsPayload;
  deleteAssets: GQDeleteAssetsPayload;
  deleteBiCustomMetric: GQBiCustomMetric;
  deleteBiSavedView: GQBiSavedView;
  deleteBuildingUtility: GQDeleteBuildingUtilityPayload;
  deleteBuildings: GQDeleteBuildingsPayload;
  deleteCalculationTag: GQDeleteCalculationTagPayload;
  deleteCandidateRowEntry: GQUserUploadCandidateEntry;
  deleteCanonicalClimateProgramProject: GQDeleteCanonicalClimateProgramProjectPayload;
  deleteCanonicalClimateProgramProjectRequirementDataset: GQCanonicalClimateProgramProjectPayload;
  deleteCanonicalClimateProgramProjectRequirementGhgp: GQCanonicalClimateProgramProjectPayload;
  deleteCdpApiRegistration: GQDeleteCdpApiRegistrationPayload;
  deleteCdpColumnMappings: Maybe<GQDeleteCdpColumnMappingsPayload>;
  deleteCdpQuestionMappings: Maybe<GQDeleteCdpQuestionMappingsPayload>;
  deleteClimateProgramCalendarEvent: GQDeleteClimateProgramCalendarEventPayload;
  deleteClimateProgramProject: GQDeleteClimateProgramProjectPayload;
  deleteClimateProgramProjectDatasetRequirement: GQClimateProgramProjectPayload;
  deleteClimateProgramTimelineProject: GQDeleteClimateProgramTimelineProjectPayload;
  deleteComment: Maybe<GQDeleteCommentPayload>;
  deleteCompanyClimateCommitment: Maybe<GQDeleteCompanyClimateCommitmentPayload>;
  deleteCompanyEngagementCohort: GQDeleteCompanyEngagementCohortPayload;
  deleteCompanyRelationship: GQDeleteCompanyRelationshipPayload;
  deleteCompanyReportingFacts: GQDeleteCompanyReportingFactsPayload;
  deleteCompanySourceKey: GQDeleteCompanySourceKeyPayload;
  deleteCts: GQDeleteCtsPayload;
  deleteCtsTransformTestCase: Maybe<GQDeleteCtsTransformTestCasePayload>;
  deleteCtsvOneSchemaFileFeed: Maybe<GQCustomerTargetSchemaVersion>;
  deleteDataIssue: Maybe<GQDeleteDataIssuePayload>;
  deleteDataRegistryTables: GQDeleteDataRegistryTablesPayload;
  deleteDataset: Maybe<GQDeleteDatasetPayload>;
  deleteDatasourceAdmin: Maybe<GQDeleteDatasourceAdminPayload>;
  deleteDrilldownSavedView: Maybe<GQDrilldownSavedView>;
  deleteEacPriceEstimate: GQDeleteEacPriceEstimatePayload;
  deleteEmissionsModelRelease: GQDeleteEmissionsModelReleasePayload;
  deleteEmissionsYear: GQDeleteEmissionsYearPayload;
  deleteEngagementTaskConfig: Maybe<GQDeleteEngagementTaskConfigPayload>;
  deleteEngagementTaskConfigForAdmin: Maybe<GQDeleteEngagementTaskConfigForAdminPayload>;
  deleteEngagementTasksAndAnswersByCrossOrgModelId: Maybe<GQDeleteEngagementTasksAndAnswersByCrossOrgModelIdPayload>;
  deleteExternalReportQuestion: GQDeleteExternalReportQuestionPayload;
  deleteExternalReportTypeWithRevision: GQDeleteExternalReportTypeWithRevisionPayload;
  deleteFacilitiesSetupTask: GQDeleteFacilitiesSetupTaskPayload;
  deleteFile: Maybe<GQDeleteFilePayload>;
  deleteFinanceSavedView: Maybe<GQDeleteFinanceSavedViewPayload>;
  deleteFinanceTag: GQDeleteFinanceTagPayload;
  deleteFinanceTags: GQDeleteFinanceTagsPayload;
  deleteFinancialsReviewItem: Maybe<GQDeleteFinancialsReviewItemPayload>;
  deleteFootprintTag: Maybe<GQDeleteFootprintTagPayload>;
  deleteFootprintTestSuiteConfig: GQDeleteFootprintTestSuiteConfigPayload;
  deleteFootprintVersionPin: Maybe<GQDeleteFootprintVersionPinPayload>;
  deleteFunds: Maybe<GQDeleteFundsPayload>;
  deleteIngestionCustomField: GQDeleteIngestionCustomFieldPayload;
  deleteInitiative: Maybe<GQDeleteInitiativePayload>;
  deleteMarketplaceAllocationInstruction: Maybe<GQDeleteMarketplaceAllocationInstructionPayload>;
  deleteMarketplaceDeveloper: Maybe<GQDeleteMarketplaceDeveloperPayload>;
  deleteMarketplaceDocument: Maybe<GQDeleteMarketplaceDocumentPayload>;
  deleteMarketplacePortfolio: Maybe<GQDeleteMarketplacePortfolioPayload>;
  deleteMarketplacePortfolioItem: Maybe<GQDeleteMarketplacePortfolioItemPayload>;
  deleteMarketplaceProject: Maybe<GQDeleteMarketplaceProjectPayload>;
  deleteMarketplacePurchase: Maybe<GQDeleteMarketplacePurchasePayload>;
  deleteMarketplacePurchaseLineItem: Maybe<GQDeleteMarketplacePurchaseLineItemPayload>;
  deleteMeasurementContextItem: GQDeleteMeasurementContextItemPayload;
  deleteMeasurementProject: Maybe<GQOrganizationPayload>;
  deleteMeasurementTestRows: GQDeleteMeasurementTestRowsPayload;
  deleteMeasurementTestSuite: GQDeleteMeasurementTestSuitePayload;
  deleteMeasurementTestSuiteBart: GQDeleteMeasurementTestSuiteBartPayload;
  deleteMeasurementVerificationItem: GQDeleteMeasurementVerificationItemPayload;
  deleteMeasurementVerificationItemQuestion: GQDeleteMeasurementVerificationItemQuestionPayload;
  deleteMetricFromCustomReport: Maybe<GQDeleteMetricFromCustomReportPayload>;
  deleteNonFootprintSupplier: Maybe<GQDeleteNonFootprintSupplierPayload>;
  deleteOneSchemaTemplate: GQDeleteOneSchemaTemplatePayload;
  deleteOrgCompany: GQDeleteOrgCompanyPayload;
  deleteOrgPointOfContact: Maybe<GQDeleteOrgPointOfContactPayload>;
  deleteOrgStructureVersion: GQDeleteOrgStructureVersionPayload;
  deleteOrgUnitType: GQDeleteOrgUnitTypePayload;
  deleteOrgUnitTypeRelationship: GQDeleteOrgUnitTypeRelationshipPayload;
  deleteOrgUnits: GQDeleteOrgUnitsPayload;
  deleteOrganization: Maybe<GQDeleteOrganizationPayload>;
  deletePeerCompanyGroup: Maybe<GQDeletePeerCompanyGroupPayload>;
  deletePlan: Scalars['Boolean']['output'];
  deletePlanAdmin: GQDeletePlanAdminPayload;
  deletePlanTarget: GQDeletePlanTargetPayload;
  deletePublicDisclosure: GQDeletePublicDisclosurePayload;
  deletePublicDisclosureTarget: GQDeletePublicDisclosureTargetPayload;
  deleteReportAnswer: GQDeleteReportAnswerPayload;
  deleteReportAnswers: Maybe<GQDeleteReportAnswersPayload>;
  deleteReportAttachment: Maybe<GQDeleteReportAttachmentPayload>;
  deleteReportAttachmentItem: Maybe<GQDeleteReportAttachmentItemPayload>;
  deleteReportConfig: Maybe<GQDeleteReportConfigPayload>;
  deleteReportQuestion: Maybe<GQDeleteReportQuestionPayload>;
  deleteReportQuestionContainer: Maybe<GQDeleteReportQuestionContainerPayload>;
  deleteReportQuestionIdentifierMapping: GQUpdateReportQuestionIdentifierMappingPayload;
  deleteReportQuestionNoteItem: GQDeleteReportQuestionNoteItemPayload;
  deleteRole: Maybe<GQDeleteRolePayload>;
  deleteSbtiTargetExclusion: GQDeleteSbtiTargetExclusionPayload;
  deleteSimulationPortfolio: GQSimulationPortfolio;
  deleteSimulationScenario: GQSimulationScenario;
  deleteSnapshot: GQDeleteSnapshotPayload;
  deleteSupplierAttachment: Maybe<GQDeleteSupplierAttachmentPayload>;
  deleteSupplierChart: Maybe<GQDeleteSupplierChartPayload>;
  deleteSupplierChartAdmin: Maybe<GQDeleteSupplierChartPayloadAdmin>;
  deleteSupplierContact: Maybe<GQDeleteSupplierContactPayload>;
  deleteSupplierSavedView: GQDeleteSupplierSavedViewPayload;
  deleteSupplierScoreCriteria: GQDeleteSupplierScoreCriteriaPayload;
  deleteSupplierTableColumn: Maybe<GQDeleteSupplierTableColumnPayload>;
  deleteSupportingDocument: Maybe<GQDeleteSupportingDocumentPayload>;
  deleteTarget: GQDeleteTargetPayload;
  deleteTcfdArchetypeRisk: GQTcfdArchetypeRisk;
  deleteUserUploadCandidateAndRows: GQDeleteUserUploadCandidateAndRowsPayload;
  deleteUserUploadTask: GQDeleteUserUploadTaskPayload;
  deleteValueMappingComment: GQValueMappingComment;
  deleteWatershedFootprintReviewRequest: GQDeleteWatershedFootprintReviewRequestPayload;
  disconnectIntegrationConnection: GQDisconnectIntegrationConnectionPayload;
  dismissFootprintExclusionSuggestion: Maybe<GQDismissFootprintExclusionSuggestionPayload>;
  duplicateCts: GQDuplicateCtsPayload;
  duplicateInitiative: GQDuplicateInitiativePayload;
  duplicateMarketplacePortfolio: Maybe<GQDuplicateMarketplacePortfolioPayload>;
  duplicateMeasurementTestSuite: GQDuplicateMeasurementTestSuitePayload;
  duplicateOrgStructureVersion: GQDuplicateOrgStructureVersionPayload;
  duplicatePlan: Maybe<GQDuplicatePlanPayload>;
  duplicatePlanAdmin: GQPlan;
  duplicateScenarioWithPortfolio: GQSimulationScenario;
  editEmissionsYear: GQEditEmissionsYearPayload;
  editExternalReportQuestion: GQEditExternalReportQuestionPayload;
  editExternalReportTypeWithRevision: GQEditExternalReportTypeWithRevisionPayload;
  editIngestionCustomField: GQEditIngestionCustomFieldPayload;
  editPublicDisclosure: GQEditPublicDisclosurePayload;
  editSimulationCandidateCost: GQSimulationCostPerUnitOverride;
  editSimulationPortfolio: GQSimulationPortfolio;
  editSimulationScenario: GQSimulationScenario;
  editValueMappingComment: GQValueMappingComment;
  /** @deprecated Use `startWorkflow` instead. */
  enqueueBackgroundJob: Maybe<GQEnqueueBackgroundJobPayload>;
  eraseReportComponent: Maybe<GQEraseReportComponentPayload>;
  executeExtractSchemas: GQExecuteExtractSchemasPayload;
  exportMeasurementTestSuite: GQExportMeasurementTestSuitePayload;
  exportMeasurementTestSuiteBundle: GQExportMeasurementTestSuiteBundlePayload;
  fetchIntegrationData: GQFetchIntegrationDataPayload;
  financeFeedback: GQFinanceFeedbackPayload;
  findDuck: GQFindDuckPayload;
  fixReduxValidationErrors: GQFixReduxValidationErrorsPayload;
  forkEmissionsModel: GQForkEmissionsModelPayload;
  fulfillShareFileRequest: Maybe<GQFulfillShareFileRequestPayload>;
  generateAuditReport: GQGenerateAuditReportPayload;
  generateAuditReportForAssetYear: Maybe<GQFinanceAuditReportForAssetYearPayload>;
  generateAutoFootprint: GQGenerateAutoFootprintPayload;
  generateBartFromFileUpload: GQGenerateBartFromFileUploadPayload;
  generateCustomIntensityConfigId: GQGenerateCustomIntensityConfigIdPayload;
  generateCustomerUploadedReferenceDataRevision: GQGenerateCustomerUploadedReferenceDataRevisionPayload;
  generateForecastScenarioId: GQGenerateForecastScenarioIdPayload;
  generateGmailXml: Maybe<GQGenerateGmailXmlPayload>;
  generateMeasurementVerificationItems: Maybe<GQGenerateMeasurementVerificationItemsPayload>;
  generateOverrideReferenceDataRevision: GQGenerateOverrideReferenceDataRevisionPayload;
  generateTemplateDownloadUrlForDataset: GQGenerateTemplateDownloadUrlForDatasetPayload;
  generateTemplateDownloadUrlForOrgDatasets: GQGenerateTemplateDownloadUrlForOrgDatasetsPayload;
  generateValueMappingDownloadUrl: Scalars['String']['output'];
  getNewDecryptedToken: Maybe<GQGetNewDecryptedTokenPayload>;
  getOrCreateDatasetFromCanonicalDatasetKind: GQGetOrCreateDatasetFromCanonicalDatasetKindPayload;
  getOrSendEmails: GQGetOrSendEmailsPayload;
  getOrSendMeasurementEmails: Maybe<GQGetOrSendMeasurementEmailsPayload>;
  giveFeedback: GQRequestHelpPayload;
  grantFinanceUsersPortcoAccesses: Maybe<GQGrantFinanceUsersPortcoAccessesPayload>;
  grantUserRole: Maybe<GQGrantUserRolePayload>;
  importMeasurementTestSuite: GQImportMeasurementTestSuitePayload;
  initializeDefaultSupplierView: GQInitializeDefaultSupplierViewPayload;
  initializeDefaultSupplierViewAdmin: GQInitializeDefaultSupplierViewAdminPayload;
  initializeWorkos: Maybe<GQInitializeWorkosPayload>;
  insertReportQuestionIdentifier: GQReportQuestionIdentifier;
  invalidateBackgroundJobCache: Maybe<GQInvalidateBackgroundJobCachePayload>;
  invalidateMeasurementTestSuitePage: GQInvalidateMeasurementTestSuitePagePayload;
  joinReportWaitlist: GQJoinReportWaitlistPayload;
  joinWaitlist: GQJoinProductWaitlistPayload;
  leaveReportWaitlist: GQLeaveReportWaitlistPayload;
  leaveWaitlist: GQLeaveProductWaitlistPayload;
  linkEmissionsModelsToRelease: GQEmissionsModelReleasePayload;
  linkGlobalFootprintTagsToRelease: GQEmissionsModelReleasePayload;
  lintReportConfig: Maybe<GQLintReportConfigPayload>;
  logCustomerHubViewed: Maybe<GQLogCustomerHubViewedPayload>;
  logLocalMeasurementState: Maybe<Scalars['Boolean']['output']>;
  logSupplyChainSurveyEvent: Maybe<GQLogSupplyChainSurveyEventPayload>;
  logUpdateVendorMatchingTask: GQLogUpdateVendorMatchingTaskPayload;
  loginAsUser: GQLoginAsUserPayload;
  markAdminUploadCompleted: Maybe<GQMarkAdminUploadCompletedPayload>;
  markMeasurementProjectDatasetCompleted: GQMarkMeasurementProjectDatasetCompletedPayload;
  markUserUploadAcknowledged: Maybe<GQMarkUserUploadAcknowledgedPayload>;
  markUserUploadAsProcessing: Maybe<GQMarkUserUploadAsProcessingPayload>;
  markUserUploadCompleted: Maybe<GQMarkUserUploadCompletedPayload>;
  markUserUploadedTablesIgnored: Maybe<GQMarkUserUploadedTablesIgnoredPayload>;
  maybeCreateIntegrationConnection: Maybe<GQMaybeCreateIntegrationConnectionPayload>;
  mergeAssets: GQMergeAssetsPayload;
  migrateCardControls042524: Maybe<GQMigrateCardControls024524Payload>;
  migrateOrgPlansToOrgFiscalMonth: GQMigrateOrgPlansToOrgFiscalMonthPayload;
  migratePermissionViewFootprintDetailToManageSuppliers: Maybe<GQMigratePermissionViewFootprintDetailToManageSuppliersPayload>;
  notifyEngagementTaskCreation: Maybe<GQNotifyEngagementTaskCreationPayload>;
  overrideContainerProgress: GQReportQuestionContainer;
  previewDataIssueEmails: Maybe<GQPreviewDataIssueEmailsPayload>;
  previewFinancialsReviewEmails: Maybe<GQPreviewFinancialsReviewEmailsPayload>;
  previewMeasurementVerificationItemEmails: GQPreviewMeasurementVerificationItemEmailsPayload;
  previewUtilitiesDiff: GQFacilitiesSdiPayload;
  pruneOrphanedQuestionsForConfig: Maybe<GQPruneOrphanedQuestionsForConfigPayload>;
  publishCtsVersion: GQPublishCtsVersionPayload;
  publishCtsVersionForTesting: GQPublishCtsVersionPayload;
  publishCustomTransformForUserUpload: GQPublishCustomTransformForUserUploadPayload;
  publishDataIssues: Maybe<GQPublishDataIssuesPayload>;
  publishDataRegistryTables: GQPublishDataRegistryTablesPayload;
  publishDraftFootprint: Maybe<GQPublishDraftFootprintPayload>;
  publishEngagementTaskConfigForAdmin: Maybe<GQPublishEngagementTaskConfigForAdminPayload>;
  publishExtractor: Maybe<GQPublishExtractorPayload>;
  publishFinancialsReviewItems: Maybe<GQPublishFinancialsReviewItemsPayload>;
  publishFootprintCustomReport: Maybe<GQPublishFootprintCustomReportPayload>;
  publishMeasurementVerificationItems: GQPublishMeasurementVerificationItemsPayload;
  publishOrgStructureVersion: GQPublishOrgStructureVersionPayload;
  publishReferenceDataVersion: GQPublishReferenceDataVersionPayload;
  publishValueMappingArtifact: Maybe<GQPublishValueMappingArtifactPayload>;
  purgeDraftsSinceLastPublishedActivityDataTable: Maybe<GQPurgeDraftsSinceLastPublishedActivityDataTablePayload>;
  pushBartBuilding: Maybe<GQFacilitiesSdiPayload>;
  pushCtsVersionToOneSchema: GQPushCtsVersionToOneSchemaPayload;
  pushCtsVersionToOneSchemaInProduct: GQPushCtsVersionToOneSchemaInProductPayload;
  pushStagingReportConfigToProd: GQPushStagingReportConfigToProdPayload;
  pushUtilitiesDiff: GQFacilitiesSdiPayload;
  reassignFacilitiesTask: GQReassignFacilitiesTaskPayload;
  refreshAssetYearEstimations: Maybe<GQRefreshAssetYearEstimationsPayload>;
  refreshCarbonRemovalActivityDataTable: Maybe<GQRefreshCarbonRemovalActivityDataTablePayload>;
  refreshMarketplacePurchase: Maybe<GQConfirmMarketplacePurchasePayload>;
  regenerateAuditReport: GQGenerateAuditReportPayload;
  regenerateUserSignupToken: Maybe<GQRegenerateUserSignupTokenPayload>;
  regenerateUserSignupTokenAdminVersion: Maybe<GQRegenerateUserSignupTokenPayload>;
  reingestCompanyIngestionDiffs: GQReingestCompanyIngestionDiffPayload;
  rejectData: GQRejectDataPayload;
  releaseEmissionsModelActiveDraft: GQReleaseEmissionsModelActiveDraftPayload;
  removeCalculationTagFromReferenceData: GQRemoveCalculationTagFromReferenceDataPayload;
  removeCalculationTagFromStableModel: GQRemoveCalculationTagFromStableModelPayload;
  removeCompanyFromPeerCompanyGroup: Maybe<GQRemoveCompanyFromPeerCompanyGroupPayload>;
  removeMarketplaceSupplierPointOfContact: Maybe<GQRemoveMarketplaceSupplierPointOfContactPayload>;
  removeMarketplaceSupplierWatershedEmployee: Maybe<GQRemoveMarketplaceSupplierWatershedEmployeePayload>;
  removeUser: Maybe<GQRemoveUserPayload>;
  removeUserAdminVersion: Maybe<GQRemoveUserAdminVersionPayload>;
  renameBiSavedView: GQBiSavedView;
  renameCustomEmissionsFactorSetupTask: GQRenameCustomEmissionsFactorSetupTaskPayload;
  renameFootprint: GQRenameFootprintPayload;
  renameFootprintVersion: GQRenameFootprintVersionPayload;
  renameReport: Maybe<GQRenameReportPayload>;
  replaceCompany: GQReplaceCompanyPayload;
  replaceReportingCdpMappings: GQReplaceReportingCdpMappingsPayload;
  requestDataApproval: GQRequestDataApprovalPayload;
  requestHelp: GQRequestHelpPayload;
  requestSupplyChainHelp: GQRequestHelpPayload;
  requestUnlockData: GQRequestUnlockDataPayload;
  /** @deprecated Dead code */
  rerunBartForPresetTagWorkflow: GQRerunBartForPresetTagWorkflowOutput;
  resetFootprintExclusionSuggestions: Maybe<GQResetFootprintExclusionSuggestionsPayload>;
  resetLockedUser: Maybe<GQResetLockedUserPayload>;
  resetLockedUserAdminVersion: Maybe<GQResetLockedUserAdminVersionPayload>;
  resolveDataIssue: Maybe<GQResolveDataIssuePayload>;
  resolveDiscussion: Maybe<GQResolveDiscussionPayload>;
  respondToDataIssue: Maybe<GQRespondToDataIssuePayload>;
  respondToDiscussion: Maybe<GQRespondToDiscussionPayload>;
  restoreDeletedSavedView: Maybe<GQBiSavedView>;
  restoreReport: Maybe<GQRestoreReportAdminPayload>;
  reviewFinancialsReviewItem: Maybe<GQReviewFinancialsReviewItemPayload>;
  reviewPrepublicationTestResultsForEmissionsModel: GQReviewPrepublicationTestResultsForEmissionsModelPayload;
  reviewPrepublicationTestResultsForReferenceDataRevision: GQReviewPrepublicationTestResultsForReferenceDataRevisionPayload;
  reviewPrepublicationTestResultsForRelease: GQReviewPrepublicationTestResultsForReleasePayload;
  revokePermissionItem: Maybe<GQRevokePermissionItemPayload>;
  revokeUserAppSessions: GQRevokeUserAppSessionsPayload;
  revokeUserRole: Maybe<GQRevokeUserRolePayload>;
  runAdvancedEnergyEstimation: Maybe<GQRunAdvancedEnergyEstimationPayload>;
  runCeeForEngagementTask: Maybe<GQRunCeeForEngagementTaskPayload>;
  runCtsTransformEditor: GQRunCtsTransformEditorPayload;
  runCtsTransformTestCase: GQRunCtsTransformEditorPayload;
  runDataRegistryMigration: Scalars['ID']['output'];
  runDemoPlatform: Maybe<GQRunDemoPlatformPayload>;
  runExtractor: Maybe<GQRunExtractorPayload>;
  runPlaygroundSql: Maybe<GQRunPlaygroundSqlPayload>;
  runPleeForEngagementTask: Maybe<GQRunPleeForEngagementTaskPayload>;
  runReportAnswerVerifier: Maybe<GQRunReportAnswerVerifierPayload>;
  runSchemaValidationForReferenceDataVersion: GQRunSchemaValidationForReferenceDataVersionPayload;
  runSimulation: GQSimulationScenarioRun;
  saveCategoryRuleGhgCategoryNote: Maybe<GQSaveCategoryRuleGhgCategoryNotePayload>;
  saveDeltaProgressValueMapping: GQSaveDeltaProgressValueMappingPayload;
  saveEmissionsModelDraft: GQSaveEmissionsModelDraftPayload;
  saveEmployeeFeedback: Maybe<GQSaveEmployeeFeedbackPayload>;
  saveFootprintTag: Maybe<GQSaveFootprintTagPayload>;
  saveGlobalFootprintTag: Maybe<GQSaveGlobalFootprintTagPayload>;
  saveIncompleteOneSchemaImport: GQSaveIncompleteOneSchemaImportPayload;
  scoreAnswer: GQExternalReportAnswer;
  sendIntegrationInvite: GQSendIntegrationInvitePayload;
  setApprovers: GQSetApproversPayload;
  setArchiveEngagementTaskConfig: Maybe<GQSetArchiveEngagementTaskConfigPayload>;
  setAssetBenchmarks: GQSetAssetBenchmarksPayload;
  setCalculationTagReferenceDatas: Scalars['Boolean']['output'];
  setCalculationTagStableModels: Scalars['Boolean']['output'];
  setEmissionsModelIsArchived: GQSetEmissionsModelIsArchivedPayload;
  setEngagementCohortAndMembers: GQSetEngagementCohortAndMembersPayload;
  setEngagementCohortsForCompany: GQSetEngagementCohortsForCompanyPayload;
  setEngagementNotificationSettingsForUser: GQUser;
  setFootprintAsWatershedReviewed: Maybe<GQSetFootprintAsWatershedReviewedPayload>;
  setFootprintSnapshotIsManuallySaved: Maybe<GQSetFootprintSnapshotIsManuallySavedPayload>;
  setFootprintTestSuiteConfigDefaultOrgId: Scalars['ID']['output'];
  setFootprintVersionKind: Maybe<GQSetFootprintVersionKindPayload>;
  setFootprintVersionUserVisible: Maybe<GQSetFootprintVersionUserVisiblePayload>;
  setOrgsForFeatureFlag: GQSetOrgsForFeatureFlagPayload;
  setPeerCompanyGroupMemberFeatureStatus: Maybe<GQSetPeerCompanyGroupMemberFeatureStatusPayload>;
  setPreferredLocale: GQSetPreferredLocalePayload;
  setPublishEngagementTaskConfig: Maybe<GQSetPublishEngagementTaskConfigPayload>;
  setUserUploadedTableNotIgnored: Maybe<GQSetUserUploadedTableNotIgnoredPayload>;
  setUserUploadedTableSheetsNotIngested: Maybe<GQSetUserUploadedTableSheetsNotIngestedPayload>;
  setUserUploadedTableSqlTableName: Maybe<GQSetUserUploadedTableSqlTableNamePayload>;
  shareFiles: Maybe<GQShareFilesPayload>;
  shareMeasurementProject: Maybe<GQShareMeasurementProjectPayload>;
  shareMeasurementProjectStatus: Maybe<GQShareMeasurementProjectStatusPayload>;
  signalExecuteMeasurementTestSuite: GQSignalExecuteMeasurementTestSuitePayload;
  startCdpSyncAnswers: GQStartCdpSyncAnswersPayload;
  startFileFeedEmbeddedSession: GQFileFeedEmbeddedSession;
  startPrepublicationTestsForEmissionsModel: GQStartPrepublicationTestsPayload;
  startPrepublicationTestsForReferenceDataRevision: GQStartPrepublicationTestsPayload;
  startPrepublicationTestsForRelease: GQStartPrepublicationTestsPayload;
  startPublishingEmissionsModel: GQStartPublishingEmissionsModelPayload;
  startWorkflow: Maybe<GQStartWorkflowPayload>;
  submitAndCloseCtsForm: GQUserUploadTask;
  submitBuildings: Maybe<GQSubmitFacilitiesPayload>;
  submitEarlyUploadHelpRequest: GQSubmitEarlyUploadHelpRequestPayload;
  submitEngagementTask: Maybe<GQSubmitEngagementTaskPayload>;
  submitFacilitiesBulkAddition: GQSubmitFacilitiesBulkAdditionPayload;
  submitUserUploadTask: GQSubmitUserUploadTaskPayload;
  submitUtilities: GQSubmitFacilitiesPayload;
  submitVendorMatchingTask: GQSubmitVendorMatchingTaskPayload;
  suggestMappings: GQSuggestMappingsPayload;
  supplierDataExport: GQSupplierDataExportPayload;
  supplierSurveyResponseExport: GQSupplierSurveyResponseExportPayload;
  swapEmissionsModelExpectationsForFootprintTestRows: GQSwapEmissionsModelExpectationsForFootprintTestRowsPayload;
  swapEmissionsModelExpectationsForTestRows: GQSwapEmissionsModelExpectationsForMeasurementTestRowsPayload;
  syncFeatureFlags: GQSyncFeatureFlagsPayload;
  syncMarketplacePortfolioItems: Maybe<GQSyncMarketplacePortfolioItemsPayload>;
  syncMeasurementMappings: Maybe<GQSyncMeasurementMappingsPayload>;
  throwError: Maybe<GQThrowErrorPayload>;
  toggleCliqSyncForReferenceDataSource: GQToggleCliqSyncForReferenceDataSourcePayload;
  toggleMeasurementTestSuiteCritical: GQUpdateMeasurementTestSuitePayload;
  unassignFacilitiesFromTask: GQUnassignFacilitiesFromTaskPayload;
  unassignFromContainer: Maybe<GQUnassignFromContainerPayload>;
  unassociateIntegrationConnectionFromDatasource: GQUnassociateIntegrationConnectionFromDatasourcePayload;
  uncompleteMeasurementProject: Maybe<GQUncompleteMeasurementProjectPayload>;
  uncompleteUserUploadTask: GQCompleteUserUploadTaskPayload;
  undeleteMeasurementTestSuite: GQUndeleteMeasurementTestSuitePayload;
  unlinkEmissionsModelsFromRelease: GQEmissionsModelReleasePayload;
  unlinkGlobalFootprintTagsFromRelease: GQEmissionsModelReleasePayload;
  unlockData: GQUnlockDataPayload;
  unpublishActivityDataTable: Maybe<GQUnpublishActivityDataTablePayload>;
  unpublishCtsVersion: GQUnpublishCtsVersionPayload;
  unpublishDataRegistryTables: GQUnpublishDataRegistryTablesPayload;
  unpublishOrgStructureVersion: GQUnpublishOrgStructureVersionPayload;
  unshareMeasurementProjectStatus: Maybe<GQUnshareMeasurementProjectStatusPayload>;
  updateActivityDataTable: Maybe<GQUpdateActivityDataTablePayload>;
  updateActivityDataTableReviewer: Maybe<GQUpdateActivityDataTableReviewerPayload>;
  updateAdminMeasurementVerificationItem: GQUpdateMeasurementVerificationItemPayload;
  updateAssetComment: GQUpdateAssetCommentPayload;
  updateAssetCorporate: Maybe<GQUpdateAssetCorporatePayload>;
  updateAssetGroup: Maybe<GQUpdateAssetGroupPayload>;
  updateAssetHolding: Maybe<GQUpdateAssetHoldingPayload>;
  updateAssetPersonalMotorVehicleInsurance: Maybe<GQUpdateAssetPersonalMotorVehicleInsurancePayload>;
  updateAssetRealEstate: Maybe<GQUpdateAssetRealEstatePayload>;
  updateAssetSovereignBond: Maybe<GQUpdateAssetSovereignBondPayload>;
  updateAssetYear: Maybe<GQUpdateAssetYearNewPayload>;
  updateAssetYears: Maybe<GQUpdateAssetYearsNewPayload>;
  updateBiCustomMetric: GQBiCustomMetric;
  updateBiSavedView: GQBiSavedView;
  updateBuilding: Maybe<GQUpdateBuildingPayload>;
  updateBuildingUtility: GQUpdateBuildingUtilityPayload;
  updateBuildings: GQUpdateBuildingsPayload;
  updateCalculationTag: GQUpdateCalculationTagPayload;
  updateCandidateRowEntry: GQUserUploadCandidateEntry;
  updateCanonicalClimateProgramProject: GQCanonicalClimateProgramProjectPayload;
  updateCanonicalClimateProgramProjectRequirementDataset: GQCanonicalClimateProgramProjectRequirementDatasetPayload;
  updateCanonicalClimateProgramProjectRequirementGhgp: GQCanonicalClimateProgramProjectRequirementGhgpPayload;
  updateCanonicalDataset: Maybe<GQCanonicalDatasetPayload>;
  updateCanonicalDatasource: Maybe<GQCanonicalDatasourcePayload>;
  updateClimateProgramCalendarEvent: GQClimateProgramCalendarEventPayload;
  updateClimateProgramProject: GQClimateProgramProjectPayload;
  updateClimateProgramProjectDatasetRequirement: GQClimateProgramProjectRequirementDatasetPayload;
  updateClimateProgramTimelineProject: GQClimateProgramTimelineProjectPayload;
  updateComment: Maybe<GQUpdateCommentPayload>;
  updateCompanyClimateCommitment: Maybe<GQCompanyClimateCommitmentPayload>;
  updateCompanySourceKey: GQCompanySourceKeyPayload;
  updateCts: GQUpdateCtsPayload;
  updateCtsForm: GQUserUploadTask;
  updateCtsTransformTestCase: Maybe<GQUpdateCtsTransformTestCasePayload>;
  updateDataIssue: Maybe<GQUpdateDataIssuePayload>;
  updateDataIssues: Maybe<GQUpdateDataIssuesPayload>;
  updateDataRegistryBatchMigratorRun: GQDataRegistryBatchMigratorRun;
  updateDataset: Maybe<GQDatasetPayload>;
  updateDatasource: GQDatasourcePayload;
  updateDatasourceAdmin: Maybe<GQDatasourcePayload>;
  updateDrilldownSavedView: Maybe<GQDrilldownSavedView>;
  updateEacPriceEstimate: GQUpdateEacPriceEstimatePayload;
  updateEacPriceEstimates: GQUpdateEacPriceEstimatesPayload;
  updateEmissionsModelReleaseLifecyclePhase: GQEmissionsModelReleasePayload;
  updateEmissionsModelReleaseMetadata: GQEmissionsModelReleasePayload;
  updateEmissionsModelStable: Maybe<GQUpdateEmissionsModelStablePayload>;
  updateEmployeeReport: Maybe<GQUpdateEmployeeReportPayload>;
  updateEngagementTask: Maybe<GQUpdateEngagementTaskPayload>;
  updateEngagementTaskConfig: Maybe<GQUpdateEngagementTaskConfigPayload>;
  updateEngagementTaskConfigForAdmin: GQEngagementTaskConfigPayload;
  updateFinanceAutomaticReminders: GQUpdateFinanceAutomaticRemindersPayload;
  updateFinanceSavedView: Maybe<GQUpdateFinanceSavedViewPayload>;
  updateFinanceSettings: Maybe<GQUpdateFinanceSettingsPayload>;
  updateFinanceTag: GQUpdateFinanceTagPayload;
  updateFinanceWorksheet: GQUpdateFinanceWorksheetPayload;
  updateFinancialsReviewItem: Maybe<GQUpdateFinancialsReviewItemPayload>;
  updateFootprintAssemblyRunStatus: GQUpdateFootprintAssemblyRunStatusPayload;
  updateFootprintSliceInitiative: Maybe<GQUpdateFootprintSliceInitiativePayload>;
  updateFootprintSnapshot: Maybe<GQUpdateFootprintSnapshotPayload>;
  updateFootprintVersion: Maybe<GQUpdateFootprintVersionPayload>;
  updateFootprintVersionPin: Maybe<GQUpdateFootprintVersionPinPayload>;
  updateForecast: GQUpdateForecastPayload;
  updateForecastFootprintSnapshotIdToLatest: GQUpdateForecastFootprintSnapshotIdToLatestPayload;
  updateFund: Maybe<GQUpdateFundPayload>;
  updateIngestionInstructions: Maybe<GQUpdateIngestionInstructionsPayload>;
  updateIntegrationConnection: GQUpdateIntegrationConnectionPayload;
  updateMarketplaceAllocationInstruction: Maybe<GQUpdateMarketplaceAllocationInstructionPayload>;
  updateMarketplaceDeveloper: Maybe<GQUpdateMarketplaceDeveloperPayload>;
  updateMarketplaceDocument: Maybe<GQUpdateMarketplaceDocumentPayload>;
  updateMarketplacePortfolio: Maybe<GQUpdateMarketplacePortfolioPayload>;
  updateMarketplacePortfolioItems: Maybe<GQUpdateMarketplacePortfolioItemsPayload>;
  updateMarketplaceProject: Maybe<GQUpdateMarketplaceProjectPayload>;
  updateMarketplaceProjectArchetype: Maybe<GQUpdateMarketplaceProjectArchetypePayload>;
  updateMarketplacePurchase: Maybe<GQUpdateMarketplacePurchasePayload>;
  updateMarketplacePurchaseLineItem: Maybe<GQUpdateMarketplacePurchaseLineItemPayload>;
  updateMarketplaceSupplier: Maybe<GQUpdateMarketplaceSupplierPayload>;
  updateMeasurementContextItem: GQUpdateMeasurementContextItemPayload;
  updateMeasurementProject: Maybe<GQUpdateMeasurementProjectPayload>;
  updateMeasurementProjectDashboard: GQUpdateMeasurementProjectPayload;
  updateMeasurementTestSuite: GQUpdateMeasurementTestSuitePayload;
  updateMeasurementTestSuiteBart: GQUpdateMeasurementTestSuiteBartPayload;
  updateMeasurementVerificationItemQuestion: GQUpdateMeasurementVerificationItemQuestionPayload;
  updateMeasurementVerificationItemQuestionOrder: GQOrderMeasurementVerificationItemQuestionsPayload;
  updateOrgForFeatureFlag: GQUpdateOrgForFeatureFlagPayload;
  updateOrgPointOfContact: Maybe<GQUpdateOrgPointOfContactPayload>;
  updateOrgProfile: GQUpdateOrgProfilePayload;
  updateOrgUnitType: GQUpdateOrgUnitTypePayload;
  updateOrgUnitTypeRelationship: GQUpdateOrgUnitTypeRelationshipPayload;
  updateOrgUnits: GQUpdateOrgUnitsPayload;
  updateOrganization: Maybe<GQOrganizationPayload>;
  updatePeerCompanyGroup: Maybe<GQPeerCompanyGroupPayload>;
  updatePipelineResult: Maybe<GQUpdatePipelineResultPayload>;
  updatePlan: Maybe<GQUpdatePlanPayload>;
  updatePlanSettings: Maybe<GQUpdatePlanSettingsPayload>;
  updatePlanState: Maybe<GQUpdatePlanStatePayload>;
  updatePlanTarget: Maybe<GQUpdatePlanTargetPayload>;
  updatePrecomputedActivityBasedInitiative: Maybe<GQUpdatePrecomputedActivityBasedInitiativePayload>;
  updatePrivateDisclosure: Maybe<GQUpdatePrivateDisclosurePayload>;
  updatePublicDisclosureTarget: GQUpdatePublicDisclosureTargetPayload;
  updateReferenceDataCitation: GQUpdateReferenceDataCitationPayload;
  updateReferenceDataRevision: GQUpdateReferenceDataRevisionPayload;
  updateReferenceDataSource: GQUpdateReferenceDataSourcePayload;
  updateReferenceDataVersion: GQUpdateReferenceDataVersionPayload;
  updateReferenceDataVersionSchema: GQUpdateReferenceDataVersionSchemaPayload;
  updateReport: Maybe<GQUpdateReportPayload>;
  updateReportConfig: Maybe<GQUpdateReportConfigPayload>;
  updateReportConfigDashboard: Maybe<GQUpdateReportConfigDashboardPayload>;
  updateReportQuestion: Maybe<GQUpdateReportQuestionPayload>;
  updateReportQuestionAssignees: Maybe<GQUpdateReportQuestionAssigneesPayload>;
  updateReportQuestionContainer: Maybe<GQUpdateReportQuestionContainerPayload>;
  updateReportQuestionContainerDashboard: Maybe<GQUpdateReportQuestionContainerDashboardPayload>;
  updateReportQuestionNote: GQUpdateReportQuestionNotePayload;
  updateReportQuestionNoteItem: GQUpdateReportQuestionNoteItemPayload;
  updateReportingIntentions: GQUpdateReportingIntentionsPayload;
  updateRole: Maybe<GQUpdateRolePayload>;
  updateSbtiTargetExclusion: GQUpdateSbtiTargetExclusionPayload;
  updateSnapshotName: GQUpdateSnapshotNamePayload;
  updateSupplierAttachment: Maybe<GQUpdateSupplierAttachmentPayload>;
  updateSupplierChart: Maybe<GQUpdateSupplierChartPayload>;
  updateSupplierChartAdmin: Maybe<GQUpdateSupplierChartPayloadAdmin>;
  updateSupplierColumnOrder: Maybe<GQUpdateSupplierColumnOrderPayload>;
  updateSupplierColumnVisibilityBatch: Maybe<GQUpdateSupplierColumnVisibilityBatchPayload>;
  updateSupplierColumnWidth: Maybe<GQUpdateSupplierColumnWidthPayload>;
  updateSupplierContact: Maybe<GQUpdateSupplierContactPayload>;
  updateSupplierCustomData: Maybe<GQUpdateSupplierCustomDataPayload>;
  updateSupplierSavedView: GQUpdateSupplierSavedViewPayload;
  updateSupplierScoreCriteria: GQUpdateSupplierScoreCriteriaPayload;
  updateSupplierTableColumn: Maybe<GQUpdateSupplierTableColumnPayload>;
  updateSuppliersSettings: Maybe<GQUpdateSuppliersSettingsPayload>;
  updateSuppliersSettingsAdmin: Maybe<GQUpdateSuppliersSettingsPayload>;
  updateTarget: GQUpdateTargetPayload;
  updateTcfdArchetypeOpportunity: GQTcfdArchetypeOpportunity;
  updateTcfdArchetypeRisk: GQTcfdArchetypeRisk;
  updateTestRowsInTestSuiteBart: GQUpdateTestRowsInTestSuiteBartPayload;
  updateUserEmailAdminVersion: Maybe<GQUpdateUserEmailAdminVersionPayload>;
  updateUserIngestionReview: Maybe<GQUpdateUserIngestionReviewPayload>;
  updateUserName: Maybe<GQUpdateUserNamePayload>;
  updateUserNameAdminVersion: Maybe<GQUpdateUserNameAdminVersionPayload>;
  updateUserPermissionRequest: Maybe<GQUpdateUserPermissionRequestPayload>;
  updateUserUpload: Maybe<GQUpdateUserUploadPayload>;
  updateUserUploadTask: Maybe<GQUpdateUserUploadTaskPayload>;
  updateWatershedFootprintReviewRequestStatus: GQUpdateWatershedFootprintReviewRequestStatusPayload;
  uploadMarketplaceDocuments: Maybe<GQUploadMarketplaceDocumentsPayload>;
  upsertCdpColumnMappings: Maybe<GQUpsertCdpColumnMappingsPayload>;
  upsertCdpQuestionMappings: Maybe<GQUpsertCdpQuestionMappingsPayload>;
  upsertCompany: GQUpsertCompanyPayload;
  upsertCompanyPortalSettings: Maybe<GQUpsertCompanyPortalSettingsPayload>;
  upsertCompanyReportingFacts: GQUpsertCompanyReportingFactsPayload;
  upsertDocumentParseAttempt: GQDocumentParseAttempt;
  upsertEngagementTaskAnswers: Maybe<GQUpsertEngagementTaskAnswersPayload>;
  upsertFootprintCategoryRules: Maybe<GQUpsertFootprintCategoryRulesPayload>;
  upsertFootprintExclusionRules: Maybe<GQUpsertFootprintExclusionRulesPayload>;
  upsertFootprintTestSuiteConfig: GQFootprintTestSuiteConfigPayload;
  upsertNonFootprintSupplier: Maybe<GQUpsertNonFootprintSupplierPayload>;
  upsertOrgCompanyForName: GQUpsertOrgCompanyForNamePayload;
  upsertOrgStructureVersion: GQUpsertOrgStructureVersionPayload;
  /** @deprecated Use upsertReportAnswerWithMetadata instead. */
  upsertReportAnswer: GQReportAnswer;
  upsertReportAnswerWithMetadata: GQUpsertReportAnswerPayload;
  validateEmissionsModelMatcher: GQValidateEmissionsModelMatcherPayload;
  validateFormAgainstCts: GQValidateFormAgainstCtsPayload;
  verifyReportAnswers: Maybe<GQVerifyReportAnswersPayload>;
  withdrawCompanyChangeRequest: GQWithdrawCompanyChangeRequestPayload;
};

export type GQMutationAcceptFinanceImportDiffArgs = {
  input: GQAcceptFinanceImportDiffInput;
};

export type GQMutationAcceptFootprintTestSuiteExecutionResultsArgs = {
  input: GQAcceptFootprintTestSuiteExecutionResultsInput;
};

export type GQMutationAcceptMethodologyTestSuiteExecutionResultsArgs = {
  input: GQAcceptMethodologyTestSuiteExecutionResultsInput;
};

export type GQMutationAcceptReportAnswerVerifierFailuresArgs = {
  input: GQAcceptReportAnswerVerifierFailuresInput;
};

export type GQMutationAcknowledgeReportAnswerWarningArgs = {
  input: GQAcknowledgeReportAnswerWarningInput;
};

export type GQMutationAddCalculationTagToReferenceDataArgs = {
  input: GQAddCalculationTagToReferenceDataInput;
};

export type GQMutationAddCalculationTagToStableModelArgs = {
  input: GQAddCalculationTagToStableModelInput;
};

export type GQMutationAddCompaniesToPeerCompanyGroupsArgs = {
  input: GQAddCompaniesToPeerCompanyGroupsInput;
};

export type GQMutationAddDataApprovalCommentArgs = {
  input: GQAddDataApprovalCommentInput;
};

export type GQMutationAddEmissionsModelToOrgArgs = {
  input: GQAddEmissionsModelToOrgInput;
};

export type GQMutationAddEngagementTaskCommentArgs = {
  engagementTaskId: Scalars['ID']['input'];
  input: GQAddEngagementTaskCommentInput;
};

export type GQMutationAddHeaderToCustomReportArgs = {
  input: GQAddHeaderToCustomReportInput;
};

export type GQMutationAddMarketplaceSupplierPointOfContactArgs = {
  input: GQAddMarketplaceSupplierPointOfContactInput;
};

export type GQMutationAddMarketplaceSupplierPointOfContactConnectionArgs = {
  input: GQAddMarketplaceSupplierPointOfContactConnectionInput;
};

export type GQMutationAddMarketplaceSupplierWatershedEmployeeArgs = {
  input: GQAddMarketplaceSupplierWatershedEmployeeInput;
};

export type GQMutationAddMetricsToCustomReportArgs = {
  input: GQAddMetricsToCustomReportInput;
};

export type GQMutationAddOrgAccessToReleaseArgs = {
  input: GQAddOrgAccessToReleaseInput;
};

export type GQMutationAddOrgPointOfContactArgs = {
  input: GQAddOrgPointOfContactInput;
};

export type GQMutationAddReportQuestionNoteArgs = {
  input: GQAddReportQuestionNoteInput;
};

export type GQMutationAddTestRowsToTestSuiteBartArgs = {
  input: GQAddTestRowsToTestSuiteBartInput;
};

export type GQMutationAddUserUploadCandidatesToFileFeedEmbeddedSessionArgs = {
  input: GQAddUserUploadCandidatesToFileFeedEmbeddedSessionInput;
};

export type GQMutationAddWatershedEmployeeArgs = {
  input: GQAddWatershedEmployeeInput;
};

export type GQMutationAdminUpdatePlanArgs = {
  input: GQUpdatePlanInput;
};

export type GQMutationAnswerMeasurementVerificationItemQuestionArgs = {
  input: GQAnswerMeasurementVerificationItemQuestionInput;
};

export type GQMutationApplyGlobalTagToTestDataArgs = {
  input: GQApplyGlobalTagToTestDataInput;
};

export type GQMutationApplyIntegrationConnectionArgs = {
  input: GQApplyIntegrationConnectionInput;
};

export type GQMutationApplyTagsToPipelineResultArgs = {
  input: GQApplyTagsToPipelineResultInput;
};

export type GQMutationApproveAllValueMappingRecordsArgs = {
  input: GQApproveAllValueMappingRecordsInput;
};

export type GQMutationApproveCompanyIngestionDiffArgs = {
  input: GQIdInput;
};

export type GQMutationApproveCompanyIngestionUpdateArgs = {
  input: GQIdInput;
};

export type GQMutationApproveCtsTransformTestCaseArgs = {
  input: GQApproveCtsTransformTestCaseInput;
};

export type GQMutationApproveDataArgs = {
  input: GQApproveDataInput;
};

export type GQMutationApproveReportAnswerVerifiersArgs = {
  input: GQApproveReportAnswerVerifiersInput;
};

export type GQMutationArchiveFootprintCustomReportArgs = {
  input: GQArchiveFootprintCustomReportInput;
};

export type GQMutationArchiveFootprintSnapshotArgs = {
  input: GQArchiveFootprintSnapshotInput;
};

export type GQMutationArchivePipelineResultArgs = {
  input: GQArchivePipelineResultInput;
};

export type GQMutationArchiveReferenceDataCitationArgs = {
  input: GQIdInput;
};

export type GQMutationArchiveReferenceDataRevisionArgs = {
  input: GQArchiveReferenceDataRevisionInput;
};

export type GQMutationArchiveReferenceDataSourceArgs = {
  input: GQArchiveReferenceDataSourceInput;
};

export type GQMutationArchiveReferenceDataVersionArgs = {
  input: GQArchiveReferenceDataVersionInput;
};

export type GQMutationArchiveReportArgs = {
  input: GQArchiveReportInput;
};

export type GQMutationAssignFacilitiesToTasksArgs = {
  input: GQAssignFacilitiesToTasksInput;
};

export type GQMutationAssignMeasurementTaskArgs = {
  input: GQAssignMeasurementTaskInput;
};

export type GQMutationAssignToContainerArgs = {
  input: GQAssignToContainerInput;
};

export type GQMutationAssignUserUploadTaskArgs = {
  input: GQAssignUserUploadTaskInput;
};

export type GQMutationAutosaveEmissionsModelArgs = {
  input: GQAutosaveEmissionsModelInput;
};

export type GQMutationBatchSetCustomFieldForBuildingsArgs = {
  input: GQBatchSetCustomFieldForBuildingsInput;
};

export type GQMutationBatchSetCustomFieldForDatasourcesArgs = {
  input: GQBatchSetCustomFieldForDatasourcesInput;
};

export type GQMutationBatchUnsetCustomFieldForBuildingsArgs = {
  input: GQBatchUnsetCustomFieldForBuildingsInput;
};

export type GQMutationBatchUnsetCustomFieldForDatasourcesArgs = {
  input: GQBatchUnsetCustomFieldForDatasourcesInput;
};

export type GQMutationBulkCreateUploadTasksArgs = {
  input: GQBulkCreateUploadTasksInput;
};

export type GQMutationBulkCreateUsersForFacilitiesArgs = {
  input: GQBulkCreateUsersForFacilitiesInput;
};

export type GQMutationBulkImportMarketplaceAllocationInstructionsArgs = {
  input: GQBulkImportMarketplaceAllocationInstructionsInput;
};

export type GQMutationBulkStartCompanySurveyEngagementArgs = {
  input: GQBulkStartCompanySurveyEngagementInput;
};

export type GQMutationBulkUpdateMarketplacePurchaseLineItemsArgs = {
  input: GQBulkUpdateMarketplacePurchaseLineItemsInput;
};

export type GQMutationBulkUpdateReportQuestionsArgs = {
  input: GQBulkUpdateReportQuestionsInput;
};

export type GQMutationCancelWorkflowArgs = {
  input: GQCancelWorkflowInput;
};

export type GQMutationCdpApiManualAddRowArgs = {
  input: GQCdpApiManualAddRowInput;
};

export type GQMutationCdpApiManualDeleteRowArgs = {
  input: GQCdpApiManualDeleteRowInput;
};

export type GQMutationCdpApiManualPutResponseArgs = {
  input: GQCdpApiManualPutResponseInput;
};

export type GQMutationCheckAndGenerateFacilitiesBartsArgs = {
  input: GQCheckAndGenerateFacilitiesBartsInput;
};

export type GQMutationCheckAndHealForecastArgs = {
  input: GQCheckAndHealForecastInput;
};

export type GQMutationClearReportAnswerVerifierFailureArgs = {
  input: GQClearReportAnswerVerifierFailureInput;
};

export type GQMutationClearReportAnswerVerifierFailuresArgs = {
  input: GQClearReportAnswerVerifierFailuresInput;
};

export type GQMutationCompleteMeasurementProjectArgs = {
  input: GQCompleteMeasurementProjectInput;
};

export type GQMutationCompleteOnboardingArgs = {
  input: GQCompleteOnboardingInput;
};

export type GQMutationCompleteUserUploadTaskArgs = {
  input: GQCompleteUserUploadTaskInput;
};

export type GQMutationComputeAllReportAnswersArgs = {
  input: GQComputeAllReportAnswersInput;
};

export type GQMutationComputeReportAnswerWarningsForOrgArgs = {
  input: GQComputeReportAnswerWarningsForOrgInput;
};

export type GQMutationConfigureAutoBartArgs = {
  input: GQConfigureAutoBartInput;
};

export type GQMutationConfirmMarketplacePurchaseArgs = {
  input: GQConfirmMarketplacePurchaseInput;
};

export type GQMutationConfirmUtilitySchemaArgs = {
  input: GQConfirmUtilitySchemaInput;
};

export type GQMutationConvertCtsBackArgs = {
  input: GQConvertCtsBackInput;
};

export type GQMutationConvertCtsToTSchemaArgs = {
  input: GQConvertCtsToTSchemaInput;
};

export type GQMutationConvertGlobalReleaseToOrgGatedArgs = {
  input: GQEmissionsModelReleaseIdInput;
};

export type GQMutationConvertOrgGatedReleaseToGlobalArgs = {
  input: GQEmissionsModelReleaseIdInput;
};

export type GQMutationCopyEngagementTaskConfigToOrgForAdminArgs = {
  input: GQCopyEngagementTaskConfigToOrgForAdminInput;
};

export type GQMutationCopyReportQuestionAnswersArgs = {
  input: GQCopyReportQuestionAnswersInput;
};

export type GQMutationCopySimulationScenarioArgs = {
  simulationScenarioId: Scalars['ID']['input'];
};

export type GQMutationCreateActivityDataTableArgs = {
  input: GQCreateActivityDataTableInput;
};

export type GQMutationCreateAdminUploadArgs = {
  input: GQCreateAdminUploadInput;
};

export type GQMutationCreateApiKeyArgs = {
  input: GQCreateApiKeyInput;
};

export type GQMutationCreateAssetCommentArgs = {
  input: GQCreateAssetCommentInput;
};

export type GQMutationCreateAssetCorporateArgs = {
  input: GQCreateAssetCorporateInput;
};

export type GQMutationCreateAssetGroupArgs = {
  input: GQCreateAssetGroupInput;
};

export type GQMutationCreateAssetHoldingArgs = {
  input: GQCreateAssetHoldingNewInput;
};

export type GQMutationCreateAssetPersonalMotorVehicleInsuranceArgs = {
  input: GQCreateAssetPersonalMotorVehicleInsuranceInput;
};

export type GQMutationCreateAssetRealEstateArgs = {
  input: GQCreateAssetRealEstateInput;
};

export type GQMutationCreateAssetSovereignBondArgs = {
  input: GQCreateAssetSovereignBondInput;
};

export type GQMutationCreateAssetYearArgs = {
  input: GQCreateAssetYearNewInput;
};

export type GQMutationCreateBiCustomMetricArgs = {
  input: GQCreateBiCustomMetricInput;
};

export type GQMutationCreateBiSavedViewArgs = {
  input: GQCreateBiSavedViewInput;
};

export type GQMutationCreateBiSavedViewAndPropagateToCustomReportsArgs = {
  input: GQCreateBiSavedViewAndPropagateToCustomReportsInput;
};

export type GQMutationCreateBuildingArgs = {
  input: GQCreateBuildingInput;
};

export type GQMutationCreateBuildingUtilityArgs = {
  input: GQCreateBuildingUtilityInput;
};

export type GQMutationCreateCalculationTagArgs = {
  input: GQCreateCalculationTagInput;
};

export type GQMutationCreateCandidateRowEntryArgs = {
  input: GQCreateCandidateRowInput;
};

export type GQMutationCreateCanonicalClimateProgramProjectArgs = {
  input: GQCreateCanonicalClimateProgramProjectInput;
};

export type GQMutationCreateCanonicalClimateProgramProjectRequirementDatasetArgs =
  {
    input: GQCreateCanonicalClimateProgramProjectRequirementDatasetInput;
  };

export type GQMutationCreateCanonicalClimateProgramProjectRequirementGhgpArgs =
  {
    input: GQCreateCanonicalClimateProgramProjectRequirementGhgpInput;
  };

export type GQMutationCreateCanonicalDatasetArgs = {
  input: GQCreateCanonicalDatasetInput;
};

export type GQMutationCreateCanonicalDatasourceArgs = {
  input: GQCreateCanonicalDatasourceInput;
};

export type GQMutationCreateCdpApiRegistrationArgs = {
  input: GQCreateCdpApiRegistrationInput;
};

export type GQMutationCreateCdpColumnMappingsArgs = {
  input: GQCreateCdpColumnMappingsInput;
};

export type GQMutationCreateCdpQuestionMappingsArgs = {
  input: GQCreateCdpQuestionMappingsInput;
};

export type GQMutationCreateClimateProgramCalendarEventArgs = {
  input: GQCreateClimateProgramCalendarEventInput;
};

export type GQMutationCreateClimateProgramProjectArgs = {
  input: GQCreateClimateProgramProjectInput;
};

export type GQMutationCreateClimateProgramProjectDatasetRequirementArgs = {
  input: GQCreateClimateProgramProjectDatasetRequirementInput;
};

export type GQMutationCreateCompanyChangeRequestCreateArgs = {
  input: GQCreateCompanyChangeRequestCreateInput;
};

export type GQMutationCreateCompanyClimateCommitmentArgs = {
  input: GQCreateCompanyClimateCommitmentInput;
};

export type GQMutationCreateCompanyRelationshipArgs = {
  input: GQCreateCompanyRelationshipInput;
};

export type GQMutationCreateCompanySourceKeyArgs = {
  input: GQCreateCompanySourceKeyInput;
};

export type GQMutationCreateCompositeDataSourceArgs = {
  input: GQCreateCompositeDataSourceInput;
};

export type GQMutationCreateCorporateSandboxEstimationArgs = {
  input: GQCreateCorporateSandboxEstimationInput;
};

export type GQMutationCreateCsrdDmaArgs = {
  input: GQCreateCsrdDmaInput;
};

export type GQMutationCreateCsrdMaterialMetricArgs = {
  input: GQCreateCsrdMaterialMetricInput;
};

export type GQMutationCreateCsrdMaterialStandardArgs = {
  input: GQCreateCsrdMaterialStandardInput;
};

export type GQMutationCreateCtsArgs = {
  input: GQCreateCtsInput;
};

export type GQMutationCreateCtsFormArgs = {
  input: GQCreateCtsFormInput;
};

export type GQMutationCreateCtsFormEntryArgs = {
  input: GQCreateCtsFormEntryInput;
};

export type GQMutationCreateCtsTransformTestCaseArgs = {
  input: GQCreateCtsTransformTestCaseInput;
};

export type GQMutationCreateCtsVersionArgs = {
  input: GQCreateCtsVersionInput;
};

export type GQMutationCreateCtsvOneSchemaFileFeedArgs = {
  input: GQCreateCtsvOneSchemaFileFeedInput;
};

export type GQMutationCreateCustomEmissionsFactorsReferenceDataRevisionArgs = {
  input: GQCreateCustomEmissionsFactorsReferenceDataRevisionInput;
};

export type GQMutationCreateCustomerInitiatedDataIssueArgs = {
  input: GQCreateCustomerInitiatedDataIssueInput;
};

export type GQMutationCreateDataIssueArgs = {
  input: GQCreateDataIssueInput;
};

export type GQMutationCreateDataIssueCommentArgs = {
  input: GQCreateDataIssueCommentInput;
};

export type GQMutationCreateDatasetArgs = {
  input: GQCreateDatasetInput;
};

export type GQMutationCreateDatasourceArgs = {
  input: GQCreateDatasourceInput;
};

export type GQMutationCreateDatasourceAdminArgs = {
  input: GQCreateDatasourceAdminInput;
};

export type GQMutationCreateDemoOrgArgs = {
  input: GQCreateDemoOrgInput;
};

export type GQMutationCreateDiscussionArgs = {
  input: GQCreateDiscussionInput;
};

export type GQMutationCreateDrilldownSavedViewArgs = {
  input: GQCreateDrilldownSavedViewInput;
};

export type GQMutationCreateEacMarketplaceProjectsArgs = {
  input: GQCreateEacMarketplaceProjectsInput;
};

export type GQMutationCreateEacPriceEstimateArgs = {
  input: GQCreateEacPriceEstimateInput;
};

export type GQMutationCreateEmissionsModelArgs = {
  input: GQCreateEmissionsModelInput;
};

export type GQMutationCreateEmissionsModelReleaseArgs = {
  input: GQCreateEmissionsModelReleaseInput;
};

export type GQMutationCreateEmployeeReportArgs = {
  input: GQCreateEmployeeReportInput;
};

export type GQMutationCreateEngagementCohortArgs = {
  input: GQCreateEngagementCohortInput;
};

export type GQMutationCreateEngagementCohortsForCompanyArgs = {
  input: GQCreateEngagementCohortsForCompanyInput;
};

export type GQMutationCreateEngagementTaskAttachmentsArgs = {
  input: GQCreateEngagementTaskAttachmentsInput;
};

export type GQMutationCreateEngagementTaskConfigForAdminArgs = {
  input: GQEngagementTaskConfigCreateInputForAdmin;
};

export type GQMutationCreateExternalReportQuestionArgs = {
  input: GQCreateExternalReportQuestionInput;
};

export type GQMutationCreateExternalReportTypeWithRevisionArgs = {
  input: GQCreateExternalReportTypeWithRevisionInput;
};

export type GQMutationCreateFacilitiesSetupTaskArgs = {
  input: GQCreateFacilitiesSetupTaskInput;
};

export type GQMutationCreateFinanceImportArgs = {
  input: GQCreateFinanceImportInput;
};

export type GQMutationCreateFinanceReportingTagsArgs = {
  input: GQCreateFinanceReportingTagsInput;
};

export type GQMutationCreateFinanceSavedViewArgs = {
  input: GQCreateFinanceSavedViewInput;
};

export type GQMutationCreateFinanceTagArgs = {
  input: GQCreateFinanceTagInput;
};

export type GQMutationCreateFinanceUploadTemplateArgs = {
  input: GQCreateFinanceUploadTemplateInput;
};

export type GQMutationCreateFinancialsReviewItemDraftsArgs = {
  input: GQCreateFinancialsReviewItemDraftsInput;
};

export type GQMutationCreateFootprintArgs = {
  input: GQCreateFootprintInput;
};

export type GQMutationCreateFootprintCustomReportArgs = {
  input: GQCreateFootprintCustomReportInput;
};

export type GQMutationCreateFootprintDisclosureArgs = {
  input: GQCreateFootprintDisclosureInput;
};

export type GQMutationCreateFootprintSliceInitiativeArgs = {
  input: GQCreateFootprintSliceInitiativeInput;
};

export type GQMutationCreateFootprintSnapshotArgs = {
  input: GQCreateFootprintSnapshotInput;
};

export type GQMutationCreateFootprintVersionPinArgs = {
  input: GQCreateFootprintVersionPinInput;
};

export type GQMutationCreateFundArgs = {
  input: GQCreateFundInput;
};

export type GQMutationCreateFundAdminArgs = {
  input: GQCreateFundInputAdmin;
};

export type GQMutationCreateIngestionCustomFieldArgs = {
  input: GQCreateIngestionCustomFieldInput;
};

export type GQMutationCreateMarketplaceAllocationInstructionArgs = {
  input: GQCreateMarketplaceAllocationInstructionInput;
};

export type GQMutationCreateMarketplaceDeveloperArgs = {
  input: GQCreateMarketplaceDeveloperInput;
};

export type GQMutationCreateMarketplaceEacPurchaseArgs = {
  input: GQCreateMarketplaceEacPurchaseInput;
};

export type GQMutationCreateMarketplacePortfolioArgs = {
  input: GQCreateMarketplacePortfolioInput;
};

export type GQMutationCreateMarketplacePortfolioItemArgs = {
  input: GQCreateMarketplacePortfolioItemInput;
};

export type GQMutationCreateMarketplaceProjectArgs = {
  input: GQCreateMarketplaceProjectInput;
};

export type GQMutationCreateMarketplaceProjectArchetypeArgs = {
  input: GQCreateMarketplaceProjectArchetypeInput;
};

export type GQMutationCreateMarketplaceProjectOfferingArgs = {
  input: GQCreateMarketplaceProjectOfferingInput;
};

export type GQMutationCreateMarketplacePurchaseArgs = {
  input: GQCreateMarketplacePurchaseInput;
};

export type GQMutationCreateMarketplacePurchaseFromPortfolioArgs = {
  input: GQCreateMarketplacePurchaseFromPortfolioInput;
};

export type GQMutationCreateMarketplacePurchaseLineItemArgs = {
  input: GQCreateMarketplacePurchaseLineItemInput;
};

export type GQMutationCreateMarketplaceSupplierArgs = {
  input: GQCreateMarketplaceSupplierInput;
};

export type GQMutationCreateMeasurementContextItemArgs = {
  input: GQCreateMeasurementContextItemInput;
};

export type GQMutationCreateMeasurementProjectFromCanonicalClimateProgramProjectArgs =
  {
    input: GQCreateMeasurementProjectFromCanonicalClimateProgramProjectInput;
  };

export type GQMutationCreateMeasurementProjectFromWizardArgs = {
  input: GQCreateMeasurementProjectFromWizardInput;
};

export type GQMutationCreateMeasurementTestSuiteArgs = {
  input: GQCreateMeasurementTestSuiteInput;
};

export type GQMutationCreateMeasurementTestSuiteBartArgs = {
  input: GQCreateMeasurementTestSuiteBartInput;
};

export type GQMutationCreateMeasurementVerificationItemArgs = {
  input: GQCreateMeasurementVerificationItemInput;
};

export type GQMutationCreateMeasurementVerificationItemQuestionArgs = {
  input: GQCreateMeasurementVerificationItemQuestionInput;
};

export type GQMutationCreateOneSchemaSessionForApiUploadArgs = {
  input: GQCreateOneSchemaSessionForApiUploadInput;
};

export type GQMutationCreateOrgRoleArgs = {
  input: GQCreateOrgRoleInput;
};

export type GQMutationCreateOrgStructureImportTemplateArgs = {
  input: GQCreateOrgStructureImportTemplateInput;
};

export type GQMutationCreateOrgUnitArgs = {
  input: GQCreateOrgUnitInput;
};

export type GQMutationCreateOrgUnitTypeArgs = {
  input: GQCreateOrgUnitTypeInput;
};

export type GQMutationCreateOrgUnitTypeRelationshipArgs = {
  input: GQCreateOrgUnitTypeRelationshipInput;
};

export type GQMutationCreateOrganizationArgs = {
  input: GQCreateOrganizationInput;
};

export type GQMutationCreatePeerCompanyGroupArgs = {
  input: GQCreatePeerCompanyGroupInput;
};

export type GQMutationCreatePermissionItemArgs = {
  input: GQCreatePermissionItemInput;
};

export type GQMutationCreatePlanArgs = {
  input: GQCreatePlanInput;
};

export type GQMutationCreatePlanTargetArgs = {
  input: GQCreatePlanTargetInput;
};

export type GQMutationCreatePlanTargetReduxArgs = {
  input: GQCreatePlanTargetReduxInput;
};

export type GQMutationCreatePlanWithDefaultsArgs = {
  input: InputMaybe<GQCreatePlanWithDefaultsInput>;
};

export type GQMutationCreatePrecomputedActivityBasedInitiativeArgs = {
  input: GQCreatePrecomputedActivityBasedInitiativeInput;
};

export type GQMutationCreatePrivateDisclosureArgs = {
  input: GQCreatePrivateDisclosureInput;
};

export type GQMutationCreatePublicDisclosureArgs = {
  input: GQCreatePublicDisclosureInput;
};

export type GQMutationCreatePublicDisclosureTargetArgs = {
  input: GQCreatePublicDisclosureTargetInput;
};

export type GQMutationCreateRealEstateSandboxEstimationArgs = {
  input: GQCreateRealEstateSandboxEstimationInput;
};

export type GQMutationCreateReferenceDataCitationArgs = {
  input: GQCreateReferenceDataCitationInput;
};

export type GQMutationCreateReferenceDataOverlayTemplateArgs = {
  input: GQCreateReferenceDataOverlayTemplateInput;
};

export type GQMutationCreateReferenceDataSourceArgs = {
  input: GQCreateReferenceDataSourceInput;
};

export type GQMutationCreateReferenceDataVersionArgs = {
  input: GQCreateReferenceDataVersionInput;
};

export type GQMutationCreateRenewableElectricityTargetArgs = {
  input: GQCreateRenewableElectricityTargetInput;
};

export type GQMutationCreateReportAttachmentsArgs = {
  input: GQCreateReportAttachmentsInput;
};

export type GQMutationCreateReportConfigArgs = {
  input: GQCreateReportConfigInput;
};

export type GQMutationCreateReportConfigFromSrcArgs = {
  input: GQCreateReportConfigFromSrcInput;
};

export type GQMutationCreateReportFileAttachmentsArgs = {
  input: GQCreateReportFileAttachmentsInput;
};

export type GQMutationCreateReportQuestionArgs = {
  input: GQCreateReportQuestionInput;
};

export type GQMutationCreateReportQuestionContainerArgs = {
  input: GQCreateReportQuestionContainerInput;
};

export type GQMutationCreateReportQuestionIdentifierMappingArgs = {
  identifierId1: Scalars['ID']['input'];
  identifierId2: Scalars['ID']['input'];
};

export type GQMutationCreateReportUrlAttachmentItemArgs = {
  input: GQCreateReportUrlAttachmentItemInput;
};

export type GQMutationCreateReportWithConfigArgs = {
  input: GQCreateReportWithConfigInput;
};

export type GQMutationCreateReportWithConfigAdminArgs = {
  input: GQCreateReportWithConfigAdminInput;
};

export type GQMutationCreateReportWithCustomConfigArgs = {
  input: InputMaybe<GQCreateReportWithCustomConfigInput>;
};

export type GQMutationCreateReportWithCustomConfigAdminArgs = {
  input: GQCreateReportWithCustomConfigAdminInput;
};

export type GQMutationCreateRoleArgs = {
  input: GQCreateRoleInput;
};

export type GQMutationCreateSbtiTargetExclusionArgs = {
  input: GQCreateSbtiTargetExclusionInput;
};

export type GQMutationCreateSimulationPortfolioArgs = {
  name: Scalars['String']['input'];
  type: GQSimulationScenarioConfigType;
};

export type GQMutationCreateSimulationScenarioArgs = {
  name: Scalars['String']['input'];
  simulationPortfolioId: Scalars['ID']['input'];
  type: GQSimulationScenarioConfigType;
};

export type GQMutationCreateSingleUploaderFacilitiesTaskArgs = {
  input: GQCreateSingleUploaderFacilitiesTaskInput;
};

export type GQMutationCreateSnapshotArgs = {
  input: GQCreateSnapshotInput;
};

export type GQMutationCreateSupplierAttachmentsArgs = {
  input: GQCreateSupplierAttachmentsInput;
};

export type GQMutationCreateSupplierChartArgs = {
  input: GQCreateSupplierChartInput;
};

export type GQMutationCreateSupplierChartAdminArgs = {
  input: GQCreateSupplierChartInputAdmin;
};

export type GQMutationCreateSupplierContactArgs = {
  input: GQCreateSupplierContactInput;
  supplierId: Scalars['String']['input'];
};

export type GQMutationCreateSupplierSavedViewArgs = {
  input: GQCreateSupplierSavedViewInput;
};

export type GQMutationCreateSupplierScoreCriteriaArgs = {
  input: GQCreateSupplierScoreCriteriaInput;
};

export type GQMutationCreateSupplierTableColumnArgs = {
  input: GQCreateSupplierTableColumnInput;
};

export type GQMutationCreateSupportingDocumentsArgs = {
  input: GQCreateSupportingDocumentsInput;
};

export type GQMutationCreateTargetArgs = {
  input: GQCreateTargetInput;
};

export type GQMutationCreateTcfdArchetypeOpportunityArgs = {
  input: GQCreateTcfdArchetypeOpportunityInput;
};

export type GQMutationCreateTcfdArchetypeRiskArgs = {
  input: GQCreateTcfdArchetypeRiskInput;
};

export type GQMutationCreateUpdateAndDeleteForecastScenariosArgs = {
  input: GQCreateUpdateAndDeleteForecastScenariosInput;
};

export type GQMutationCreateUserArgs = {
  input: GQCreateUserInput;
};

export type GQMutationCreateUserAdminVersionArgs = {
  input: GQCreateUserAdminVersionInput;
};

export type GQMutationCreateUserPermissionRequestArgs = {
  input: GQCreateUserPermissionRequestInput;
};

export type GQMutationCreateUserUploadTaskArgs = {
  input: GQCreateUserUploadTaskInput;
};

export type GQMutationCreateUserUploadsArgs = {
  input: GQCreateUserUploadsInput;
};

export type GQMutationCreateUtilityDocsArgs = {
  input: GQCreateUtilityDocsInput;
};

export type GQMutationCreateValueMappingCommentArgs = {
  input: GQCreateValueMappingCommentInput;
};

export type GQMutationCreateWatershedFootprintReviewRequestArgs = {
  input: GQCreateWatershedFootprintReviewRequestInput;
};

export type GQMutationDebugPlaygroundSqlArgs = {
  input: GQRunPlaygroundSqlInput;
};

export type GQMutationDeclineCompanyIngestionDiffArgs = {
  input: GQIdInput;
};

export type GQMutationDeclineCompanyIngestionUpdateArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteAllAnswersForReportArgs = {
  id: Scalars['ID']['input'];
};

export type GQMutationDeleteAllMarketplaceAllocationInstructionsArgs = {
  input: GQDeleteAllMarketplaceAllocationInstructionsInput;
};

export type GQMutationDeleteAllReductionsDataArgs = {
  input: GQDeleteAllReductionsDataInput;
};

export type GQMutationDeleteAssetCommentArgs = {
  input: GQDeleteAssetCommentInput;
};

export type GQMutationDeleteAssetHoldingArgs = {
  input: GQDeleteAssetHoldingInput;
};

export type GQMutationDeleteAssetHoldingsArgs = {
  input: GQDeleteAssetHoldingsInput;
};

export type GQMutationDeleteAssetYearsArgs = {
  input: GQDeleteAssetYearsInput;
};

export type GQMutationDeleteAssetsArgs = {
  input: GQDeleteAssetsInput;
};

export type GQMutationDeleteBiCustomMetricArgs = {
  input: GQDeleteBiCustomMetricInput;
};

export type GQMutationDeleteBiSavedViewArgs = {
  input: GQDeleteBiSavedViewInput;
};

export type GQMutationDeleteBuildingUtilityArgs = {
  input: GQDeleteBuildingUtilityInput;
};

export type GQMutationDeleteBuildingsArgs = {
  input: GQDeleteBuildingsInput;
};

export type GQMutationDeleteCalculationTagArgs = {
  input: GQDeleteCalculationTagInput;
};

export type GQMutationDeleteCandidateRowEntryArgs = {
  input: GQDeleteCandidateRowInput;
};

export type GQMutationDeleteCanonicalClimateProgramProjectArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteCanonicalClimateProgramProjectRequirementDatasetArgs =
  {
    input: GQDeleteCanonicalClimateProgramProjectRequirementDatasetInput;
  };

export type GQMutationDeleteCanonicalClimateProgramProjectRequirementGhgpArgs =
  {
    input: GQDeleteCanonicalClimateProgramProjectRequirementGhgpInput;
  };

export type GQMutationDeleteCdpApiRegistrationArgs = {
  input: GQDeleteCdpApiRegistrationInput;
};

export type GQMutationDeleteCdpColumnMappingsArgs = {
  input: GQDeleteCdpColumnMappingsInput;
};

export type GQMutationDeleteCdpQuestionMappingsArgs = {
  input: GQDeleteCdpQuestionMappingsInput;
};

export type GQMutationDeleteClimateProgramCalendarEventArgs = {
  input: GQDeleteClimateProgramCalendarEventInput;
};

export type GQMutationDeleteClimateProgramProjectArgs = {
  input: GQDeleteClimateProgramProjectInput;
};

export type GQMutationDeleteClimateProgramProjectDatasetRequirementArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteClimateProgramTimelineProjectArgs = {
  input: GQDeleteClimateProgramTimelineProjectInput;
};

export type GQMutationDeleteCommentArgs = {
  input: GQDeleteCommentInput;
};

export type GQMutationDeleteCompanyClimateCommitmentArgs = {
  input: GQDeleteCompanyClimateCommitmentInput;
};

export type GQMutationDeleteCompanyEngagementCohortArgs = {
  input: GQDeleteCompanyEngagementCohortInput;
};

export type GQMutationDeleteCompanyRelationshipArgs = {
  input: GQDeleteCompanyRelationshipInput;
};

export type GQMutationDeleteCompanyReportingFactsArgs = {
  input: GQDeleteCompanyReportingFactsInput;
};

export type GQMutationDeleteCompanySourceKeyArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteCtsArgs = {
  input: GQDeleteCtsInput;
};

export type GQMutationDeleteCtsTransformTestCaseArgs = {
  input: GQDeleteCtsTransformTestCaseInput;
};

export type GQMutationDeleteCtsvOneSchemaFileFeedArgs = {
  ctsvOneSchemaFileFeedId: Scalars['ID']['input'];
};

export type GQMutationDeleteDataIssueArgs = {
  input: GQDeleteDataIssueInput;
};

export type GQMutationDeleteDataRegistryTablesArgs = {
  input: GQDeleteDataRegistryTablesInput;
};

export type GQMutationDeleteDatasetArgs = {
  input: GQDeleteDatasetInput;
};

export type GQMutationDeleteDatasourceAdminArgs = {
  input: GQDeleteDatasourceInput;
};

export type GQMutationDeleteDrilldownSavedViewArgs = {
  input: GQDeleteDrilldownSavedViewInput;
};

export type GQMutationDeleteEacPriceEstimateArgs = {
  input: GQDeleteEacPriceEstimateInput;
};

export type GQMutationDeleteEmissionsModelReleaseArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteEmissionsYearArgs = {
  input: GQDeleteEmissionsYearInput;
};

export type GQMutationDeleteEngagementTaskConfigArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteEngagementTaskConfigForAdminArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteEngagementTasksAndAnswersByCrossOrgModelIdArgs = {
  input: GQDeleteEngagementTasksAndAnswersByCrossOrgModelIdInput;
};

export type GQMutationDeleteExternalReportQuestionArgs = {
  input: GQDeleteExternalReportQuestionInput;
};

export type GQMutationDeleteExternalReportTypeWithRevisionArgs = {
  input: GQDeleteExternalReportTypeWithRevisionInput;
};

export type GQMutationDeleteFacilitiesSetupTaskArgs = {
  input: GQDeleteFacilitiesSetupTaskInput;
};

export type GQMutationDeleteFileArgs = {
  input: GQDeleteFileInput;
};

export type GQMutationDeleteFinanceSavedViewArgs = {
  input: GQDeleteFinanceSavedViewInput;
};

export type GQMutationDeleteFinanceTagArgs = {
  input: GQDeleteFinanceTagInput;
};

export type GQMutationDeleteFinanceTagsArgs = {
  input: GQDeleteFinanceTagsInput;
};

export type GQMutationDeleteFinancialsReviewItemArgs = {
  input: GQDeleteFinancialsReviewItemInput;
};

export type GQMutationDeleteFootprintTagArgs = {
  input: GQDeleteFootprintTagInput;
};

export type GQMutationDeleteFootprintTestSuiteConfigArgs = {
  input: GQDeleteFootprintTestSuiteConfigInput;
};

export type GQMutationDeleteFootprintVersionPinArgs = {
  input: GQDeleteFootprintVersionPinInput;
};

export type GQMutationDeleteFundsArgs = {
  input: GQDeleteFundsInput;
};

export type GQMutationDeleteIngestionCustomFieldArgs = {
  input: GQDeleteIngestionCustomFieldInput;
};

export type GQMutationDeleteInitiativeArgs = {
  input: GQDeleteInitiativeInput;
};

export type GQMutationDeleteMarketplaceAllocationInstructionArgs = {
  input: GQDeleteMarketplaceAllocationInstructionInput;
};

export type GQMutationDeleteMarketplaceDeveloperArgs = {
  input: GQDeleteMarketplaceDeveloperInput;
};

export type GQMutationDeleteMarketplaceDocumentArgs = {
  input: GQDeleteMarketplaceDocumentInput;
};

export type GQMutationDeleteMarketplacePortfolioArgs = {
  input: GQDeleteMarketplacePortfolioInput;
};

export type GQMutationDeleteMarketplacePortfolioItemArgs = {
  input: GQDeleteMarketplacePortfolioItemInput;
};

export type GQMutationDeleteMarketplaceProjectArgs = {
  input: GQDeleteMarketplaceProjectInput;
};

export type GQMutationDeleteMarketplacePurchaseArgs = {
  input: GQDeleteMarketplacePurchaseInput;
};

export type GQMutationDeleteMarketplacePurchaseLineItemArgs = {
  input: GQDeleteMarketplacePurchaseLineItemInput;
};

export type GQMutationDeleteMeasurementContextItemArgs = {
  input: GQDeleteMeasurementContextItemInput;
};

export type GQMutationDeleteMeasurementProjectArgs = {
  input: GQDeleteMeasurementProjectInput;
};

export type GQMutationDeleteMeasurementTestRowsArgs = {
  input: GQDeleteMeasurementTestRowsInput;
};

export type GQMutationDeleteMeasurementTestSuiteArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteMeasurementTestSuiteBartArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteMeasurementVerificationItemArgs = {
  input: GQDeleteMeasurementVerificationItemInput;
};

export type GQMutationDeleteMeasurementVerificationItemQuestionArgs = {
  input: GQDeleteMeasurementVerificationItemQuestionInput;
};

export type GQMutationDeleteMetricFromCustomReportArgs = {
  input: GQDeleteMetricFromCustomReportInput;
};

export type GQMutationDeleteNonFootprintSupplierArgs = {
  input: GQDeleteNonFootprintSupplierInput;
  supplierId: Scalars['String']['input'];
};

export type GQMutationDeleteOneSchemaTemplateArgs = {
  input: GQDeleteOneSchemaTemplateInput;
};

export type GQMutationDeleteOrgCompanyArgs = {
  input: GQDeleteOrgCompanyInput;
};

export type GQMutationDeleteOrgPointOfContactArgs = {
  input: GQDeleteOrgPointOfContactInput;
};

export type GQMutationDeleteOrgStructureVersionArgs = {
  input: GQDeleteOrgStructureVersionInput;
};

export type GQMutationDeleteOrgUnitTypeArgs = {
  input: GQDeleteOrgUnitTypeInput;
};

export type GQMutationDeleteOrgUnitTypeRelationshipArgs = {
  input: GQDeleteOrgUnitTypeRelationshipInput;
};

export type GQMutationDeleteOrgUnitsArgs = {
  input: GQDeleteOrgUnitsInput;
};

export type GQMutationDeleteOrganizationArgs = {
  input: GQDeleteOrganizationInput;
};

export type GQMutationDeletePeerCompanyGroupArgs = {
  input: GQDeletePeerCompanyGroupInput;
};

export type GQMutationDeletePlanArgs = {
  input: GQDeletePlanInput;
};

export type GQMutationDeletePlanAdminArgs = {
  id: Scalars['ID']['input'];
};

export type GQMutationDeletePlanTargetArgs = {
  input: GQDeletePlanTargetInput;
};

export type GQMutationDeletePublicDisclosureArgs = {
  input: GQDeletePublicDisclosureInput;
};

export type GQMutationDeletePublicDisclosureTargetArgs = {
  input: GQDeletePublicDisclosureTargetInput;
};

export type GQMutationDeleteReportAnswerArgs = {
  input: GQDeleteReportAnswerInput;
};

export type GQMutationDeleteReportAnswersArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQMutationDeleteReportAttachmentArgs = {
  input: GQDeleteReportAttachmentInput;
};

export type GQMutationDeleteReportAttachmentItemArgs = {
  input: GQDeleteReportAttachmentItemInput;
};

export type GQMutationDeleteReportConfigArgs = {
  input: GQDeleteReportConfigInput;
};

export type GQMutationDeleteReportQuestionArgs = {
  input: GQDeleteReportQuestionInput;
};

export type GQMutationDeleteReportQuestionContainerArgs = {
  input: GQDeleteReportQuestionContainerInput;
};

export type GQMutationDeleteReportQuestionIdentifierMappingArgs = {
  identifierId1: Scalars['ID']['input'];
  identifierId2: Scalars['ID']['input'];
};

export type GQMutationDeleteReportQuestionNoteItemArgs = {
  input: GQDeleteReportQuestionNoteItemInput;
};

export type GQMutationDeleteRoleArgs = {
  input: GQDeleteRoleInput;
};

export type GQMutationDeleteSbtiTargetExclusionArgs = {
  input: GQDeleteSbtiTargetExclusionInput;
};

export type GQMutationDeleteSimulationPortfolioArgs = {
  simulationPortfolioId: Scalars['ID']['input'];
};

export type GQMutationDeleteSimulationScenarioArgs = {
  simulationScenarioId: Scalars['ID']['input'];
};

export type GQMutationDeleteSnapshotArgs = {
  input: GQDeleteSnapshotInput;
};

export type GQMutationDeleteSupplierAttachmentArgs = {
  input: GQDeleteSupplierAttachmentInput;
};

export type GQMutationDeleteSupplierChartArgs = {
  input: GQDeleteSupplierChartInput;
};

export type GQMutationDeleteSupplierChartAdminArgs = {
  input: GQDeleteSupplierChartInputAdmin;
};

export type GQMutationDeleteSupplierContactArgs = {
  input: GQDeleteSupplierContactInput;
  supplierId: Scalars['String']['input'];
};

export type GQMutationDeleteSupplierSavedViewArgs = {
  input: GQDeleteSupplierSavedViewInput;
};

export type GQMutationDeleteSupplierScoreCriteriaArgs = {
  input: GQDeleteSupplierScoreCriteriaInput;
};

export type GQMutationDeleteSupplierTableColumnArgs = {
  input: GQDeleteSupplierTableColumnInput;
};

export type GQMutationDeleteSupportingDocumentArgs = {
  input: GQDeleteSupportingDocumentInput;
};

export type GQMutationDeleteTargetArgs = {
  input: GQDeleteTargetInput;
};

export type GQMutationDeleteTcfdArchetypeRiskArgs = {
  input: GQDeleteTcfdArchetypeRiskInput;
};

export type GQMutationDeleteUserUploadCandidateAndRowsArgs = {
  input: GQDeleteUserUploadCandidateAndRowsInput;
};

export type GQMutationDeleteUserUploadTaskArgs = {
  input: GQDeleteUserUploadTaskInput;
};

export type GQMutationDeleteValueMappingCommentArgs = {
  input: GQDeleteValueMappingCommentInput;
};

export type GQMutationDeleteWatershedFootprintReviewRequestArgs = {
  input: GQDeleteWatershedFootprintReviewRequestInput;
};

export type GQMutationDisconnectIntegrationConnectionArgs = {
  input: GQDisconnectIntegrationConnectionInput;
};

export type GQMutationDismissFootprintExclusionSuggestionArgs = {
  input: GQDismissFootprintExclusionSuggestionInput;
};

export type GQMutationDuplicateCtsArgs = {
  input: GQDuplicateCtsInput;
};

export type GQMutationDuplicateInitiativeArgs = {
  input: GQDuplicateInitiativeInput;
};

export type GQMutationDuplicateMarketplacePortfolioArgs = {
  input: GQDuplicateMarketplacePortfolioInput;
};

export type GQMutationDuplicateMeasurementTestSuiteArgs = {
  input: GQDuplicateMeasurementTestSuiteInput;
};

export type GQMutationDuplicateOrgStructureVersionArgs = {
  input: GQDuplicateOrgStructureVersionInput;
};

export type GQMutationDuplicatePlanArgs = {
  input: GQDuplicatePlanInput;
};

export type GQMutationDuplicatePlanAdminArgs = {
  id: Scalars['ID']['input'];
};

export type GQMutationDuplicateScenarioWithPortfolioArgs = {
  simulationScenarioId: Scalars['ID']['input'];
};

export type GQMutationEditEmissionsYearArgs = {
  input: GQEditEmissionsYearInput;
};

export type GQMutationEditExternalReportQuestionArgs = {
  input: GQEditExternalReportQuestionInput;
};

export type GQMutationEditExternalReportTypeWithRevisionArgs = {
  input: GQEditExternalReportTypeWithRevisionInput;
};

export type GQMutationEditIngestionCustomFieldArgs = {
  input: GQEditIngestionCustomFieldInput;
};

export type GQMutationEditPublicDisclosureArgs = {
  input: GQEditPublicDisclosureInput;
};

export type GQMutationEditSimulationCandidateCostArgs = {
  candidate: Scalars['String']['input'];
  override: GQSimulationCostPerUnitOverrideInput;
  scenarioId: Scalars['ID']['input'];
};

export type GQMutationEditSimulationPortfolioArgs = {
  props: GQSimulationPortfolioProps;
  simulationPortfolioId: Scalars['ID']['input'];
};

export type GQMutationEditSimulationScenarioArgs = {
  props: GQSimulationScenarioProps;
  simulationScenarioId: Scalars['ID']['input'];
};

export type GQMutationEditValueMappingCommentArgs = {
  input: GQEditValueMappingCommentInput;
};

export type GQMutationEnqueueBackgroundJobArgs = {
  input: GQEnqueueBackgroundJobInput;
};

export type GQMutationEraseReportComponentArgs = {
  input: GQEraseReportComponentInput;
};

export type GQMutationExecuteExtractSchemasArgs = {
  input: GQExecuteExtractSchemasInput;
};

export type GQMutationExportMeasurementTestSuiteArgs = {
  input: GQIdInput;
};

export type GQMutationExportMeasurementTestSuiteBundleArgs = {
  input: GQIdInput;
};

export type GQMutationFetchIntegrationDataArgs = {
  input: GQFetchIntegrationDataInput;
};

export type GQMutationFinanceFeedbackArgs = {
  input: GQFinanceFeedbackInput;
};

export type GQMutationFindDuckArgs = {
  input: GQFindDuckInput;
};

export type GQMutationFixReduxValidationErrorsArgs = {
  input: GQFixReduxValidationErrorsInput;
};

export type GQMutationForkEmissionsModelArgs = {
  input: GQForkEmissionsModelInput;
};

export type GQMutationFulfillShareFileRequestArgs = {
  input: GQFulfillShareFileRequestInput;
};

export type GQMutationGenerateAuditReportArgs = {
  input: GQGenerateAuditReportInput;
};

export type GQMutationGenerateAuditReportForAssetYearArgs = {
  input: GQFinanceAuditReportForAssetYearInput;
};

export type GQMutationGenerateAutoFootprintArgs = {
  input: GQGenerateAutoFootprintInput;
};

export type GQMutationGenerateBartFromFileUploadArgs = {
  input: GQGenerateBartFromFileUploadInput;
};

export type GQMutationGenerateCustomIntensityConfigIdArgs = {
  input: GQGenerateCustomIntensityConfigIdInput;
};

export type GQMutationGenerateCustomerUploadedReferenceDataRevisionArgs = {
  input: GQGenerateCustomerUploadedReferenceDataRevisionInput;
};

export type GQMutationGenerateForecastScenarioIdArgs = {
  input: GQGenerateForecastScenarioIdInput;
};

export type GQMutationGenerateGmailXmlArgs = {
  input: GQGenerateGmailXmlInput;
};

export type GQMutationGenerateMeasurementVerificationItemsArgs = {
  input: GQGenerateMeasurementVerificationItemsInput;
};

export type GQMutationGenerateOverrideReferenceDataRevisionArgs = {
  input: GQGenerateOverrideReferenceDataRevisionInput;
};

export type GQMutationGenerateTemplateDownloadUrlForDatasetArgs = {
  input: GQGenerateTemplateDownloadUrlForDatasetInput;
};

export type GQMutationGenerateTemplateDownloadUrlForOrgDatasetsArgs = {
  input: GQGenerateTemplateDownloadUrlForOrgDatasetsInput;
};

export type GQMutationGenerateValueMappingDownloadUrlArgs = {
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
  valueMappingConfigId: Scalars['ID']['input'];
  withComments: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQMutationGetNewDecryptedTokenArgs = {
  input: GQGetNewDecryptedTokenInput;
};

export type GQMutationGetOrCreateDatasetFromCanonicalDatasetKindArgs = {
  input: GQGetOrCreateDatasetFromCanonicalDatasetKindInput;
};

export type GQMutationGetOrSendEmailsArgs = {
  input: GQGetOrSendEmailsInput;
};

export type GQMutationGetOrSendMeasurementEmailsArgs = {
  input: GQGetOrSendMeasurementEmailsInput;
};

export type GQMutationGiveFeedbackArgs = {
  input: GQGiveFeedbackInput;
};

export type GQMutationGrantFinanceUsersPortcoAccessesArgs = {
  input: GQGrantFinanceUsersPortcoAccessesInput;
};

export type GQMutationGrantUserRoleArgs = {
  input: GQGrantUserRoleInput;
};

export type GQMutationImportMeasurementTestSuiteArgs = {
  input: GQImportMeasurementTestSuiteInput;
};

export type GQMutationInitializeDefaultSupplierViewAdminArgs = {
  input: GQInitializeDefaultSupplierViewAdminInput;
};

export type GQMutationInitializeWorkosArgs = {
  input: GQInitializeWorkosInput;
};

export type GQMutationInsertReportQuestionIdentifierArgs = {
  input: GQInsertReportQuestionIdentifierInput;
};

export type GQMutationInvalidateMeasurementTestSuitePageArgs = {
  input: GQIdInput;
};

export type GQMutationJoinReportWaitlistArgs = {
  kind: Scalars['String']['input'];
};

export type GQMutationJoinWaitlistArgs = {
  type: GQProductWaitlistType;
};

export type GQMutationLeaveReportWaitlistArgs = {
  kind: Scalars['String']['input'];
};

export type GQMutationLeaveWaitlistArgs = {
  type: GQProductWaitlistType;
};

export type GQMutationLinkEmissionsModelsToReleaseArgs = {
  input: GQLinkEmissionsModelsToReleaseInput;
};

export type GQMutationLinkGlobalFootprintTagsToReleaseArgs = {
  input: GQLinkGlobalFootprintTagsToReleaseInput;
};

export type GQMutationLintReportConfigArgs = {
  input: GQLintReportConfigInput;
};

export type GQMutationLogCustomerHubViewedArgs = {
  input: GQLogCustomerHubViewedInput;
};

export type GQMutationLogLocalMeasurementStateArgs = {
  data: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  tag: Scalars['String']['input'];
};

export type GQMutationLogSupplyChainSurveyEventArgs = {
  input: GQLogSupplyChainSurveyEventInput;
};

export type GQMutationLogUpdateVendorMatchingTaskArgs = {
  input: GQLogUpdateVendorMatchingTaskInput;
};

export type GQMutationLoginAsUserArgs = {
  input: GQLoginAsUserInput;
};

export type GQMutationMarkAdminUploadCompletedArgs = {
  input: GQMarkAdminUploadCompletedInput;
};

export type GQMutationMarkMeasurementProjectDatasetCompletedArgs = {
  input: GQMarkMeasurementProjectDatasetCompletedInput;
};

export type GQMutationMarkUserUploadAcknowledgedArgs = {
  input: GQMarkUserUploadAcknowledgedInput;
};

export type GQMutationMarkUserUploadAsProcessingArgs = {
  input: GQMarkUserUploadAsProcessingInput;
};

export type GQMutationMarkUserUploadCompletedArgs = {
  input: GQMarkUserUploadCompletedInput;
};

export type GQMutationMarkUserUploadedTablesIgnoredArgs = {
  input: GQMarkUserUploadedTablesIgnoredInput;
};

export type GQMutationMaybeCreateIntegrationConnectionArgs = {
  input: GQMaybeCreateIntegrationConnectionInput;
};

export type GQMutationMergeAssetsArgs = {
  input: GQMergeAssetsInput;
};

export type GQMutationMigrateOrgPlansToOrgFiscalMonthArgs = {
  input: GQMigrateOrgPlansToOrgFiscalMonthInput;
};

export type GQMutationMigratePermissionViewFootprintDetailToManageSuppliersArgs =
  {
    input: GQMigratePermissionViewFootprintDetailToManageSuppliersInput;
  };

export type GQMutationNotifyEngagementTaskCreationArgs = {
  input: GQNotifyEngagementTaskCreationInput;
};

export type GQMutationOverrideContainerProgressArgs = {
  input: GQOverrideContainerProgressInput;
};

export type GQMutationPreviewDataIssueEmailsArgs = {
  input: GQPreviewDataIssueEmailsInput;
};

export type GQMutationPreviewFinancialsReviewEmailsArgs = {
  input: GQPreviewFinancialsReviewEmailsInput;
};

export type GQMutationPreviewMeasurementVerificationItemEmailsArgs = {
  input: GQPreviewMeasurementVerificationItemEmailsInput;
};

export type GQMutationPreviewUtilitiesDiffArgs = {
  input: GQPreviewUtilitiesDiffInput;
};

export type GQMutationPruneOrphanedQuestionsForConfigArgs = {
  id: Scalars['ID']['input'];
};

export type GQMutationPublishCtsVersionArgs = {
  input: GQPublishCtsVersionInput;
};

export type GQMutationPublishCtsVersionForTestingArgs = {
  input: GQPublishCtsVersionInput;
};

export type GQMutationPublishCustomTransformForUserUploadArgs = {
  input: GQPublishCustomTransformForUserUploadInput;
};

export type GQMutationPublishDataIssuesArgs = {
  input: GQPublishDataIssuesInput;
};

export type GQMutationPublishDataRegistryTablesArgs = {
  input: GQPublishDataRegistryTablesInput;
};

export type GQMutationPublishDraftFootprintArgs = {
  input: GQPublishDraftFootprintInput;
};

export type GQMutationPublishEngagementTaskConfigForAdminArgs = {
  input: GQPublishEngagementTaskConfigForAdminInput;
};

export type GQMutationPublishExtractorArgs = {
  input: GQPublishExtractorInput;
};

export type GQMutationPublishFinancialsReviewItemsArgs = {
  input: GQPublishFinancialsReviewItemsInput;
};

export type GQMutationPublishFootprintCustomReportArgs = {
  input: GQPublishFootprintCustomReportInput;
};

export type GQMutationPublishMeasurementVerificationItemsArgs = {
  input: GQPublishMeasurementVerificationItemsInput;
};

export type GQMutationPublishOrgStructureVersionArgs = {
  input: GQPublishOrgStructureVersionInput;
};

export type GQMutationPublishReferenceDataVersionArgs = {
  input: GQPublishReferenceDataVersionInput;
};

export type GQMutationPublishValueMappingArtifactArgs = {
  input: GQPublishValueMappingArtifactInput;
};

export type GQMutationPurgeDraftsSinceLastPublishedActivityDataTableArgs = {
  input: GQPurgeDraftsSinceLastPublishedActivityDataTableInput;
};

export type GQMutationPushBartBuildingArgs = {
  input: GQPushBartBuildingInput;
};

export type GQMutationPushCtsVersionToOneSchemaArgs = {
  input: GQPushCtsVersionToOneSchemaInput;
};

export type GQMutationPushCtsVersionToOneSchemaInProductArgs = {
  input: GQPushCtsVersionToOneSchemaInProductInput;
};

export type GQMutationPushStagingReportConfigToProdArgs = {
  input: GQPushStagingReportConfigToProdInput;
};

export type GQMutationPushUtilitiesDiffArgs = {
  input: GQPushUtilitiesDiffInput;
};

export type GQMutationReassignFacilitiesTaskArgs = {
  input: GQReassignFacilitiesTaskInput;
};

export type GQMutationRefreshAssetYearEstimationsArgs = {
  input: GQRefreshAssetYearEstimationsInput;
};

export type GQMutationRefreshCarbonRemovalActivityDataTableArgs = {
  input: GQRefreshCarbonRemovalActivityDataTableInput;
};

export type GQMutationRefreshMarketplacePurchaseArgs = {
  input: GQRefreshMarketplacePurchaseInput;
};

export type GQMutationRegenerateAuditReportArgs = {
  input: GQRegenerateAuditReportInput;
};

export type GQMutationRegenerateUserSignupTokenArgs = {
  input: GQRegenerateUserSignupTokenInput;
};

export type GQMutationRegenerateUserSignupTokenAdminVersionArgs = {
  input: GQRegenerateUserSignupTokenInput;
};

export type GQMutationReingestCompanyIngestionDiffsArgs = {
  input: GQReingestCompanyIngestionDiffInput;
};

export type GQMutationRejectDataArgs = {
  input: GQRejectDataInput;
};

export type GQMutationReleaseEmissionsModelActiveDraftArgs = {
  input: GQReleaseEmissionsModelActiveDraftInput;
};

export type GQMutationRemoveCalculationTagFromReferenceDataArgs = {
  input: GQRemoveCalculationTagFromReferenceDataInput;
};

export type GQMutationRemoveCalculationTagFromStableModelArgs = {
  input: GQRemoveCalculationTagFromStableModelInput;
};

export type GQMutationRemoveCompanyFromPeerCompanyGroupArgs = {
  input: GQRemoveCompanyFromPeerCompanyGroupInput;
};

export type GQMutationRemoveMarketplaceSupplierPointOfContactArgs = {
  input: GQRemoveMarketplaceSupplierPointOfContactInput;
};

export type GQMutationRemoveMarketplaceSupplierWatershedEmployeeArgs = {
  input: GQRemoveMarketplaceSupplierWatershedEmployeeInput;
};

export type GQMutationRemoveUserArgs = {
  input: GQRemoveUserInput;
};

export type GQMutationRemoveUserAdminVersionArgs = {
  input: GQRemoveUserAdminVersionInput;
};

export type GQMutationRenameBiSavedViewArgs = {
  input: GQRenameBiSavedViewInput;
};

export type GQMutationRenameCustomEmissionsFactorSetupTaskArgs = {
  input: GQRenameCustomEmissionsFactorSetupTaskInput;
};

export type GQMutationRenameFootprintArgs = {
  input: GQRenameFootprintInput;
};

export type GQMutationRenameFootprintVersionArgs = {
  input: GQRenameFootprintVersionInput;
};

export type GQMutationRenameReportArgs = {
  input: GQRenameReportInput;
};

export type GQMutationReplaceCompanyArgs = {
  input: GQReplaceCompanyInput;
};

export type GQMutationReplaceReportingCdpMappingsArgs = {
  input: GQReplaceReportingCdpMappingsInput;
};

export type GQMutationRequestDataApprovalArgs = {
  input: GQRequestDataApprovalInput;
};

export type GQMutationRequestHelpArgs = {
  input: GQRequestHelpInput;
};

export type GQMutationRequestSupplyChainHelpArgs = {
  input: GQRequestHelpInput;
};

export type GQMutationRequestUnlockDataArgs = {
  input: GQRequestUnlockDataInput;
};

export type GQMutationRerunBartForPresetTagWorkflowArgs = {
  input: GQRerunBartForPresetTagWorkflowInput;
};

export type GQMutationResetFootprintExclusionSuggestionsArgs = {
  input: GQResetFootprintExclusionSuggestionsInput;
};

export type GQMutationResetLockedUserArgs = {
  input: GQResetLockedUserInput;
};

export type GQMutationResetLockedUserAdminVersionArgs = {
  input: GQResetLockedUserAdminVersionInput;
};

export type GQMutationResolveDataIssueArgs = {
  input: GQResolveDataIssueInput;
};

export type GQMutationResolveDiscussionArgs = {
  input: GQResolveDiscussionInput;
};

export type GQMutationRespondToDataIssueArgs = {
  input: GQRespondToDataIssueInput;
};

export type GQMutationRespondToDiscussionArgs = {
  input: GQRespondToDiscussionInput;
};

export type GQMutationRestoreDeletedSavedViewArgs = {
  input: GQRestoreDeletedSavedViewInput;
};

export type GQMutationRestoreReportArgs = {
  input: GQRestoreReportInput;
};

export type GQMutationReviewFinancialsReviewItemArgs = {
  input: GQReviewFinancialsReviewItemInput;
};

export type GQMutationReviewPrepublicationTestResultsForEmissionsModelArgs = {
  input: GQReviewPrepublicationTestResultsInput;
};

export type GQMutationReviewPrepublicationTestResultsForReferenceDataRevisionArgs =
  {
    input: GQReviewPrepublicationTestResultsInput;
  };

export type GQMutationReviewPrepublicationTestResultsForReleaseArgs = {
  input: GQReviewPrepublicationTestResultsInput;
};

export type GQMutationRevokePermissionItemArgs = {
  input: GQRevokePermissionItemInput;
};

export type GQMutationRevokeUserAppSessionsArgs = {
  input: GQRevokeUserAppSessionsInput;
};

export type GQMutationRevokeUserRoleArgs = {
  input: GQRevokeUserRoleInput;
};

export type GQMutationRunAdvancedEnergyEstimationArgs = {
  input: GQRunAdvancedEnergyEstimationInput;
};

export type GQMutationRunCeeForEngagementTaskArgs = {
  input: GQRunCeeForEngagementTaskInput;
};

export type GQMutationRunCtsTransformEditorArgs = {
  input: GQRunCtsTransformEditorDashboardInput;
};

export type GQMutationRunCtsTransformTestCaseArgs = {
  input: GQRunCtsTransformTestCaseInput;
};

export type GQMutationRunDataRegistryMigrationArgs = {
  input: GQRunDataRegistryMigrationInput;
};

export type GQMutationRunDemoPlatformArgs = {
  input: GQRunDemoPlatformInput;
};

export type GQMutationRunExtractorArgs = {
  input: GQRunExtractorInput;
};

export type GQMutationRunPlaygroundSqlArgs = {
  input: GQRunPlaygroundSqlInput;
};

export type GQMutationRunPleeForEngagementTaskArgs = {
  input: GQRunPleeForEngagementTaskInput;
};

export type GQMutationRunReportAnswerVerifierArgs = {
  input: GQRunReportAnswerVerifierInput;
};

export type GQMutationRunSchemaValidationForReferenceDataVersionArgs = {
  input: GQRunSchemaValidationForReferenceDataVersionInput;
};

export type GQMutationRunSimulationArgs = {
  config: GQSimulationScenarioRunConfig;
};

export type GQMutationSaveCategoryRuleGhgCategoryNoteArgs = {
  input: GQSaveCategoryRuleGhgCategoryNoteInput;
};

export type GQMutationSaveDeltaProgressValueMappingArgs = {
  input: GQSaveDeltaProgressValueMappingInput;
};

export type GQMutationSaveEmissionsModelDraftArgs = {
  input: GQSaveEmissionsModelDraftInput;
};

export type GQMutationSaveEmployeeFeedbackArgs = {
  input: GQSaveEmployeeFeedbackInput;
};

export type GQMutationSaveFootprintTagArgs = {
  input: GQSaveFootprintTagInput;
};

export type GQMutationSaveGlobalFootprintTagArgs = {
  input: GQSaveGlobalFootprintTagInput;
};

export type GQMutationSaveIncompleteOneSchemaImportArgs = {
  input: GQSaveIncompleteOneSchemaImportInput;
};

export type GQMutationScoreAnswerArgs = {
  answerId: Scalars['ID']['input'];
  score: InputMaybe<GQDisclosureQualityScore>;
};

export type GQMutationSendIntegrationInviteArgs = {
  input: GQSendIntegrationInviteInput;
};

export type GQMutationSetApproversArgs = {
  input: GQSetApproversInput;
};

export type GQMutationSetArchiveEngagementTaskConfigArgs = {
  input: GQSetArchiveEngagementTaskConfigInput;
};

export type GQMutationSetAssetBenchmarksArgs = {
  input: GQSetAssetBenchmarksInput;
};

export type GQMutationSetCalculationTagReferenceDatasArgs = {
  sourceIds: Array<Scalars['ID']['input']>;
  tag: Scalars['String']['input'];
};

export type GQMutationSetCalculationTagStableModelsArgs = {
  stableModelIds: Array<Scalars['ID']['input']>;
  tag: Scalars['String']['input'];
};

export type GQMutationSetEmissionsModelIsArchivedArgs = {
  input: GQSetEmissionsModelIsArchivedInput;
};

export type GQMutationSetEngagementCohortAndMembersArgs = {
  input: GQSetEngagementCohortAndMembersInput;
};

export type GQMutationSetEngagementCohortsForCompanyArgs = {
  input: GQSetEngagementCohortsForCompanyInput;
};

export type GQMutationSetEngagementNotificationSettingsForUserArgs = {
  input: GQSetEngagementNotificationSettingsForUserInput;
};

export type GQMutationSetFootprintAsWatershedReviewedArgs = {
  input: GQSetFootprintAsWatershedReviewedInput;
};

export type GQMutationSetFootprintSnapshotIsManuallySavedArgs = {
  input: GQSetFootprintSnapshotIsManuallySavedInput;
};

export type GQMutationSetFootprintTestSuiteConfigDefaultOrgIdArgs = {
  input: GQIdInput;
};

export type GQMutationSetFootprintVersionKindArgs = {
  input: GQSetFootprintVersionKindInput;
};

export type GQMutationSetFootprintVersionUserVisibleArgs = {
  input: GQSetFootprintVersionUserVisibleInput;
};

export type GQMutationSetOrgsForFeatureFlagArgs = {
  input: GQSetOrgsForFeatureFlagInput;
};

export type GQMutationSetPeerCompanyGroupMemberFeatureStatusArgs = {
  input: GQSetPeerCompanyGroupMemberFeatureStatusInput;
};

export type GQMutationSetPreferredLocaleArgs = {
  input: GQSetPreferredLocaleInput;
};

export type GQMutationSetPublishEngagementTaskConfigArgs = {
  input: GQSetPublishEngagementTaskConfigInput;
};

export type GQMutationSetUserUploadedTableNotIgnoredArgs = {
  input: GQSetUserUploadedTableNotIgnoredInput;
};

export type GQMutationSetUserUploadedTableSheetsNotIngestedArgs = {
  input: GQSetUserUploadedTableSheetsNotIngestedInput;
};

export type GQMutationSetUserUploadedTableSqlTableNameArgs = {
  input: GQSetUserUploadedTableSqlTableNameInput;
};

export type GQMutationShareFilesArgs = {
  input: GQShareFilesInput;
};

export type GQMutationShareMeasurementProjectArgs = {
  input: GQShareMeasurementProjectInput;
};

export type GQMutationShareMeasurementProjectStatusArgs = {
  input: GQShareMeasurementProjectStatusInput;
};

export type GQMutationSignalExecuteMeasurementTestSuiteArgs = {
  input: GQSignalExecuteMeasurementTestSuiteInput;
};

export type GQMutationStartCdpSyncAnswersArgs = {
  input: GQStartCdpSyncAnswersInput;
};

export type GQMutationStartFileFeedEmbeddedSessionArgs = {
  input: GQStartFileFeedEmbeddedSessionInput;
};

export type GQMutationStartPrepublicationTestsForEmissionsModelArgs = {
  input: GQStartPrepublicationTestsForEmissionsModelInput;
};

export type GQMutationStartPrepublicationTestsForReferenceDataRevisionArgs = {
  input: GQStartPrepublicationTestsForReferenceDataRevisionInput;
};

export type GQMutationStartPrepublicationTestsForReleaseArgs = {
  input: GQStartPrepublicationTestsForReleaseInput;
};

export type GQMutationStartPublishingEmissionsModelArgs = {
  input: GQStartPublishingEmissionsModelInput;
};

export type GQMutationStartWorkflowArgs = {
  input: GQStartWorkflowInput;
};

export type GQMutationSubmitAndCloseCtsFormArgs = {
  ctsFormId: Scalars['ID']['input'];
};

export type GQMutationSubmitBuildingsArgs = {
  input: GQSubmitFacilitiesInput;
};

export type GQMutationSubmitEarlyUploadHelpRequestArgs = {
  input: GQSubmitEarlyUploadHelpRequestInput;
};

export type GQMutationSubmitEngagementTaskArgs = {
  input: GQSubmitEngagementTaskInput;
};

export type GQMutationSubmitFacilitiesBulkAdditionArgs = {
  input: GQSubmitFacilitiesBulkAdditionInput;
};

export type GQMutationSubmitUserUploadTaskArgs = {
  input: GQSubmitUserUploadTaskInput;
};

export type GQMutationSubmitUtilitiesArgs = {
  input: GQSubmitFacilitiesInput;
};

export type GQMutationSubmitVendorMatchingTaskArgs = {
  input: GQSubmitVendorMatchingTaskInput;
};

export type GQMutationSuggestMappingsArgs = {
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQMutationSupplierDataExportArgs = {
  input: GQSupplierDataExportInput;
};

export type GQMutationSupplierSurveyResponseExportArgs = {
  input: GQSupplierSurveyResponseExportInput;
};

export type GQMutationSwapEmissionsModelExpectationsForFootprintTestRowsArgs = {
  input: GQSwapEmissionsModelExpectationsForFootprintTestRowsInput;
};

export type GQMutationSwapEmissionsModelExpectationsForTestRowsArgs = {
  input: GQSwapEmissionsModelExpectationsForMeasurementTestRowsInput;
};

export type GQMutationSyncFeatureFlagsArgs = {
  input: GQSyncFeatureFlagsInput;
};

export type GQMutationSyncMarketplacePortfolioItemsArgs = {
  input: GQSyncMarketplacePortfolioItemsInput;
};

export type GQMutationSyncMeasurementMappingsArgs = {
  input: GQSyncMeasurementMappingsInput;
};

export type GQMutationThrowErrorArgs = {
  input: GQThrowErrorInput;
};

export type GQMutationToggleCliqSyncForReferenceDataSourceArgs = {
  input: GQToggleCliqSyncForReferenceDataSourceInput;
};

export type GQMutationToggleMeasurementTestSuiteCriticalArgs = {
  measurementTestSuiteId: Scalars['ID']['input'];
};

export type GQMutationUnassignFacilitiesFromTaskArgs = {
  input: GQUnassignFacilitiesFromTaskInput;
};

export type GQMutationUnassignFromContainerArgs = {
  input: GQUnassignFromContainerInput;
};

export type GQMutationUnassociateIntegrationConnectionFromDatasourceArgs = {
  input: GQUnassociateIntegrationConnectionFromDatasourceInput;
};

export type GQMutationUncompleteMeasurementProjectArgs = {
  input: GQUncompleteMeasurementProjectInput;
};

export type GQMutationUncompleteUserUploadTaskArgs = {
  input: GQUncompleteUserUploadTaskInput;
};

export type GQMutationUndeleteMeasurementTestSuiteArgs = {
  input: GQIdInput;
};

export type GQMutationUnlinkEmissionsModelsFromReleaseArgs = {
  input: GQUnlinkEmissionsModelsFromReleaseInput;
};

export type GQMutationUnlinkGlobalFootprintTagsFromReleaseArgs = {
  input: GQUnlinkGlobalFootprintTagsFromReleaseInput;
};

export type GQMutationUnlockDataArgs = {
  input: GQUnlockDataInput;
};

export type GQMutationUnpublishActivityDataTableArgs = {
  input: GQUnpublishActivityDataTableInput;
};

export type GQMutationUnpublishCtsVersionArgs = {
  input: GQUnpublishCtsVersionInput;
};

export type GQMutationUnpublishDataRegistryTablesArgs = {
  input: GQUnpublishDataRegistryTablesInput;
};

export type GQMutationUnpublishOrgStructureVersionArgs = {
  input: GQUnpublishOrgStructureVersionInput;
};

export type GQMutationUnshareMeasurementProjectStatusArgs = {
  input: GQUnshareMeasurementProjectStatusInput;
};

export type GQMutationUpdateActivityDataTableArgs = {
  input: GQUpdateActivityDataTableInput;
};

export type GQMutationUpdateActivityDataTableReviewerArgs = {
  input: GQUpdateActivityDataTableReviewerInput;
};

export type GQMutationUpdateAdminMeasurementVerificationItemArgs = {
  input: GQUpdateAdminMeasurementVerificationItemInput;
};

export type GQMutationUpdateAssetCommentArgs = {
  input: GQUpdateAssetCommentInput;
};

export type GQMutationUpdateAssetCorporateArgs = {
  input: GQUpdateAssetCorporateInput;
};

export type GQMutationUpdateAssetGroupArgs = {
  input: GQUpdateAssetGroupInput;
};

export type GQMutationUpdateAssetHoldingArgs = {
  input: GQUpdateAssetHoldingNewInput;
};

export type GQMutationUpdateAssetPersonalMotorVehicleInsuranceArgs = {
  input: GQUpdateAssetPersonalMotorVehicleInsuranceInput;
};

export type GQMutationUpdateAssetRealEstateArgs = {
  input: GQUpdateAssetRealEstateInput;
};

export type GQMutationUpdateAssetSovereignBondArgs = {
  input: GQUpdateAssetSovereignBondInput;
};

export type GQMutationUpdateAssetYearArgs = {
  input: GQUpdateAssetYearNewInput;
};

export type GQMutationUpdateAssetYearsArgs = {
  input: GQUpdateAssetYearsNewInput;
};

export type GQMutationUpdateBiCustomMetricArgs = {
  input: GQUpdateBiCustomMetricInput;
};

export type GQMutationUpdateBiSavedViewArgs = {
  input: GQUpdateBiSavedViewInput;
};

export type GQMutationUpdateBuildingArgs = {
  input: GQUpdateBuildingInput;
};

export type GQMutationUpdateBuildingUtilityArgs = {
  input: GQUpdateBuildingUtilityInput;
};

export type GQMutationUpdateBuildingsArgs = {
  input: GQUpdateBuildingsInput;
};

export type GQMutationUpdateCalculationTagArgs = {
  input: GQUpdateCalculationTagInput;
};

export type GQMutationUpdateCandidateRowEntryArgs = {
  input: GQUpdateCandidateRowInput;
};

export type GQMutationUpdateCanonicalClimateProgramProjectArgs = {
  input: GQUpdateCanonicalClimateProgramProjectInput;
};

export type GQMutationUpdateCanonicalClimateProgramProjectRequirementDatasetArgs =
  {
    input: GQUpdateCanonicalClimateProgramProjectRequirementDatasetInput;
  };

export type GQMutationUpdateCanonicalClimateProgramProjectRequirementGhgpArgs =
  {
    input: GQUpdateCanonicalClimateProgramProjectRequirementGhgpInput;
  };

export type GQMutationUpdateCanonicalDatasetArgs = {
  input: GQUpdateCanonicalDatasetInput;
};

export type GQMutationUpdateCanonicalDatasourceArgs = {
  input: GQUpdateCanonicalDatasourceInput;
};

export type GQMutationUpdateClimateProgramCalendarEventArgs = {
  input: GQUpdateClimateProgramCalendarEventInput;
};

export type GQMutationUpdateClimateProgramProjectArgs = {
  input: GQUpdateClimateProgramProjectInput;
};

export type GQMutationUpdateClimateProgramProjectDatasetRequirementArgs = {
  input: GQUpdateClimateProgramProjectDatasetRequirementInput;
};

export type GQMutationUpdateClimateProgramTimelineProjectArgs = {
  input: GQUpdateClimateProgramTimelineProjectInput;
};

export type GQMutationUpdateCommentArgs = {
  input: GQUpdateCommentInput;
};

export type GQMutationUpdateCompanyClimateCommitmentArgs = {
  input: GQUpdateCompanyClimateCommitmentInput;
};

export type GQMutationUpdateCompanySourceKeyArgs = {
  input: GQUpdateCompanySourceKeyInput;
};

export type GQMutationUpdateCtsArgs = {
  input: GQUpdateCtsInput;
};

export type GQMutationUpdateCtsFormArgs = {
  input: GQUpdateCtsFormInput;
};

export type GQMutationUpdateCtsTransformTestCaseArgs = {
  input: GQUpdateCtsTransformTestCaseInput;
};

export type GQMutationUpdateDataIssueArgs = {
  input: GQUpdateDataIssueInput;
};

export type GQMutationUpdateDataIssuesArgs = {
  input: Array<GQUpdateDataIssueInput>;
};

export type GQMutationUpdateDataRegistryBatchMigratorRunArgs = {
  input: GQUpdateDataRegistryBatchMigratorRunInput;
};

export type GQMutationUpdateDatasetArgs = {
  input: GQUpdateDatasetInput;
};

export type GQMutationUpdateDatasourceArgs = {
  input: GQUpdateDatasourceInput;
};

export type GQMutationUpdateDatasourceAdminArgs = {
  input: GQUpdateDatasourceAdminInput;
};

export type GQMutationUpdateDrilldownSavedViewArgs = {
  input: GQUpdateDrilldownSavedViewInput;
};

export type GQMutationUpdateEacPriceEstimateArgs = {
  input: GQUpdateEacPriceEstimateInput;
};

export type GQMutationUpdateEacPriceEstimatesArgs = {
  input: GQUpdateEacPriceEstimatesInput;
};

export type GQMutationUpdateEmissionsModelReleaseLifecyclePhaseArgs = {
  input: GQUpdateEmissionsModelReleaseLifecyclePhaseInput;
};

export type GQMutationUpdateEmissionsModelReleaseMetadataArgs = {
  input: GQUpdateEmissionsModelReleaseMetadataInput;
};

export type GQMutationUpdateEmissionsModelStableArgs = {
  input: GQUpdateEmissionsModelStableInput;
};

export type GQMutationUpdateEmployeeReportArgs = {
  input: GQUpdateEmployeeReportInput;
};

export type GQMutationUpdateEngagementTaskArgs = {
  input: GQUpdateEngagementTaskInput;
  supplierId: Scalars['ID']['input'];
};

export type GQMutationUpdateEngagementTaskConfigArgs = {
  input: GQUpdateEngagementTaskConfigInput;
};

export type GQMutationUpdateEngagementTaskConfigForAdminArgs = {
  input: GQEngagementTaskConfigInputForAdmin;
};

export type GQMutationUpdateFinanceAutomaticRemindersArgs = {
  input: GQUpdateFinanceAutomaticRemindersInput;
};

export type GQMutationUpdateFinanceSavedViewArgs = {
  input: GQUpdateFinanceSavedViewInput;
};

export type GQMutationUpdateFinanceSettingsArgs = {
  input: GQUpdateFinanceSettingsInput;
};

export type GQMutationUpdateFinanceTagArgs = {
  input: GQUpdateFinanceTagInput;
};

export type GQMutationUpdateFinanceWorksheetArgs = {
  input: GQUpdateFinanceWorksheetInput;
};

export type GQMutationUpdateFinancialsReviewItemArgs = {
  input: GQUpdateFinancialsReviewItemInput;
};

export type GQMutationUpdateFootprintAssemblyRunStatusArgs = {
  input: GQUpdateFootprintAssemblyRunStatusInput;
};

export type GQMutationUpdateFootprintSliceInitiativeArgs = {
  input: GQUpdateFootprintSliceInitiativeInput;
};

export type GQMutationUpdateFootprintSnapshotArgs = {
  input: GQUpdateFootprintSnapshotInput;
};

export type GQMutationUpdateFootprintVersionArgs = {
  input: GQUpdateFootprintVersionInput;
};

export type GQMutationUpdateFootprintVersionPinArgs = {
  input: GQUpdateFootprintVersionPinInput;
};

export type GQMutationUpdateForecastArgs = {
  input: GQUpdateForecastInput;
};

export type GQMutationUpdateForecastFootprintSnapshotIdToLatestArgs = {
  input: GQUpdateForecastFootprintSnapshotIdToLatestInput;
};

export type GQMutationUpdateFundArgs = {
  input: GQUpdateFundInput;
};

export type GQMutationUpdateIngestionInstructionsArgs = {
  input: GQUpdateIngestionInstructionsInput;
};

export type GQMutationUpdateIntegrationConnectionArgs = {
  input: GQUpdateIntegrationConnectionInput;
};

export type GQMutationUpdateMarketplaceAllocationInstructionArgs = {
  input: GQUpdateMarketplaceAllocationInstructionInput;
};

export type GQMutationUpdateMarketplaceDeveloperArgs = {
  input: GQUpdateMarketplaceDeveloperInput;
};

export type GQMutationUpdateMarketplaceDocumentArgs = {
  input: GQUpdateMarketplaceDocumentInput;
};

export type GQMutationUpdateMarketplacePortfolioArgs = {
  input: GQUpdateMarketplacePortfolioInput;
};

export type GQMutationUpdateMarketplacePortfolioItemsArgs = {
  input: GQUpdateMarketplacePortfolioItemsInput;
};

export type GQMutationUpdateMarketplaceProjectArgs = {
  input: GQUpdateMarketplaceProjectInput;
};

export type GQMutationUpdateMarketplaceProjectArchetypeArgs = {
  input: GQUpdateMarketplaceProjectArchetypeInput;
};

export type GQMutationUpdateMarketplacePurchaseArgs = {
  input: GQUpdateMarketplacePurchaseInput;
};

export type GQMutationUpdateMarketplacePurchaseLineItemArgs = {
  input: GQUpdateMarketplacePurchaseLineItemInput;
};

export type GQMutationUpdateMarketplaceSupplierArgs = {
  input: GQUpdateMarketplaceSupplierInput;
};

export type GQMutationUpdateMeasurementContextItemArgs = {
  input: GQUpdateMeasurementContextItemInput;
};

export type GQMutationUpdateMeasurementProjectArgs = {
  input: GQUpdateMeasurementProjectInput;
};

export type GQMutationUpdateMeasurementProjectDashboardArgs = {
  input: GQUpdateMeasurementProjectInput;
};

export type GQMutationUpdateMeasurementTestSuiteArgs = {
  input: GQUpdateMeasurementTestSuiteInput;
};

export type GQMutationUpdateMeasurementTestSuiteBartArgs = {
  input: GQUpdateMeasurementTestSuiteBartInput;
};

export type GQMutationUpdateMeasurementVerificationItemQuestionArgs = {
  input: GQUpdateMeasurementVerificationItemQuestionInput;
};

export type GQMutationUpdateMeasurementVerificationItemQuestionOrderArgs = {
  input: GQOrderMeasurementVerificationItemQuestionsInput;
};

export type GQMutationUpdateOrgForFeatureFlagArgs = {
  input: GQUpdateOrgForFeatureFlagInput;
};

export type GQMutationUpdateOrgPointOfContactArgs = {
  input: GQUpdateOrgPointOfContactInput;
};

export type GQMutationUpdateOrgProfileArgs = {
  input: GQUpdateOrgProfileInput;
};

export type GQMutationUpdateOrgUnitTypeArgs = {
  input: GQUpdateOrgUnitTypeInput;
};

export type GQMutationUpdateOrgUnitTypeRelationshipArgs = {
  input: GQUpdateOrgUnitTypeRelationshipInput;
};

export type GQMutationUpdateOrgUnitsArgs = {
  input: GQUpdateOrgUnitsInput;
};

export type GQMutationUpdateOrganizationArgs = {
  input: GQUpdateOrganizationInput;
};

export type GQMutationUpdatePeerCompanyGroupArgs = {
  input: GQUpdatePeerCompanyGroupInput;
};

export type GQMutationUpdatePipelineResultArgs = {
  input: GQUpdatePipelineResultInput;
};

export type GQMutationUpdatePlanArgs = {
  input: GQUpdatePlanAndArtifactsInput;
};

export type GQMutationUpdatePlanSettingsArgs = {
  input: GQUpdatePlanSettingsInput;
};

export type GQMutationUpdatePlanStateArgs = {
  input: GQUpdatePlanStateInput;
};

export type GQMutationUpdatePlanTargetArgs = {
  input: GQUpdatePlanTargetInput;
};

export type GQMutationUpdatePrecomputedActivityBasedInitiativeArgs = {
  input: GQUpdatePrecomputedActivityBasedInitiativeInput;
};

export type GQMutationUpdatePrivateDisclosureArgs = {
  input: GQUpdatePrivateDisclosureInput;
};

export type GQMutationUpdatePublicDisclosureTargetArgs = {
  input: GQUpdatePublicDisclosureTargetInput;
};

export type GQMutationUpdateReferenceDataCitationArgs = {
  input: GQUpdateReferenceDataCitationInput;
};

export type GQMutationUpdateReferenceDataRevisionArgs = {
  input: GQUpdateReferenceDataRevisionInput;
};

export type GQMutationUpdateReferenceDataSourceArgs = {
  input: GQUpdateReferenceDataSourceInput;
};

export type GQMutationUpdateReferenceDataVersionArgs = {
  input: GQUpdateReferenceDataVersionInput;
};

export type GQMutationUpdateReferenceDataVersionSchemaArgs = {
  input: GQUpdateReferenceDataVersionSchemaInput;
};

export type GQMutationUpdateReportArgs = {
  input: GQUpdateReportInput;
};

export type GQMutationUpdateReportConfigArgs = {
  input: GQUpdateReportConfigInput;
};

export type GQMutationUpdateReportConfigDashboardArgs = {
  input: GQUpdateReportConfigDashboardInput;
};

export type GQMutationUpdateReportQuestionArgs = {
  input: GQUpdateReportQuestionInput;
};

export type GQMutationUpdateReportQuestionAssigneesArgs = {
  input: GQUpdateReportQuestionAssigneesInput;
};

export type GQMutationUpdateReportQuestionContainerArgs = {
  input: GQUpdateReportQuestionContainerInput;
};

export type GQMutationUpdateReportQuestionContainerDashboardArgs = {
  input: GQUpdateReportQuestionContainerDashboardInput;
};

export type GQMutationUpdateReportQuestionNoteArgs = {
  input: GQUpdateReportQuestionNoteInput;
};

export type GQMutationUpdateReportQuestionNoteItemArgs = {
  input: GQUpdateReportQuestionNoteItemInput;
};

export type GQMutationUpdateReportingIntentionsArgs = {
  input: GQUpdateReportingIntentionsInput;
};

export type GQMutationUpdateRoleArgs = {
  input: GQUpdateRoleInput;
};

export type GQMutationUpdateSbtiTargetExclusionArgs = {
  input: GQUpdateSbtiTargetExclusionInput;
};

export type GQMutationUpdateSnapshotNameArgs = {
  input: GQUpdateSnapshotNameInput;
};

export type GQMutationUpdateSupplierAttachmentArgs = {
  input: GQUpdateSupplierAttachmentInput;
};

export type GQMutationUpdateSupplierChartArgs = {
  input: GQUpdateSupplierChartInput;
};

export type GQMutationUpdateSupplierChartAdminArgs = {
  input: GQUpdateSupplierChartInputAdmin;
};

export type GQMutationUpdateSupplierColumnOrderArgs = {
  input: GQUpdateSupplierColumnOrderInput;
};

export type GQMutationUpdateSupplierColumnVisibilityBatchArgs = {
  input: GQUpdateSupplierColumnVisibilityBatchInput;
};

export type GQMutationUpdateSupplierColumnWidthArgs = {
  input: GQUpdateSupplierColumnWidthInput;
};

export type GQMutationUpdateSupplierContactArgs = {
  input: GQUpdateSupplierContactInput;
  supplierId: Scalars['String']['input'];
};

export type GQMutationUpdateSupplierCustomDataArgs = {
  input: GQUpdateSupplierCustomDataInput;
};

export type GQMutationUpdateSupplierSavedViewArgs = {
  input: GQUpdateSupplierSavedViewInput;
};

export type GQMutationUpdateSupplierScoreCriteriaArgs = {
  input: GQUpdateSupplierScoreCriteriaInput;
};

export type GQMutationUpdateSupplierTableColumnArgs = {
  input: GQUpdateSupplierTableColumnInput;
};

export type GQMutationUpdateSuppliersSettingsArgs = {
  input: GQUpdateSuppliersSettingsInput;
};

export type GQMutationUpdateSuppliersSettingsAdminArgs = {
  input: GQUpdateSuppliersSettingsAdminInput;
};

export type GQMutationUpdateTargetArgs = {
  input: GQUpdateTargetInput;
};

export type GQMutationUpdateTcfdArchetypeOpportunityArgs = {
  input: GQUpdateTcfdArchetypeOpportunityInput;
};

export type GQMutationUpdateTcfdArchetypeRiskArgs = {
  input: GQUpdateTcfdArchetypeRiskInput;
};

export type GQMutationUpdateTestRowsInTestSuiteBartArgs = {
  input: GQUpdateTestRowsInTestSuiteBartInput;
};

export type GQMutationUpdateUserEmailAdminVersionArgs = {
  input: GQUpdateUserEmailAdminVersionInput;
};

export type GQMutationUpdateUserIngestionReviewArgs = {
  input: GQUpdateUserIngestionReviewInput;
};

export type GQMutationUpdateUserNameArgs = {
  input: GQUpdateUserNameInput;
};

export type GQMutationUpdateUserNameAdminVersionArgs = {
  input: GQUpdateUserNameAdminVersionInput;
};

export type GQMutationUpdateUserPermissionRequestArgs = {
  input: GQUpdateUserPermissionRequestInput;
};

export type GQMutationUpdateUserUploadArgs = {
  input: GQUpdateUserUploadInput;
};

export type GQMutationUpdateUserUploadTaskArgs = {
  input: GQUpdateUserUploadTaskInput;
};

export type GQMutationUpdateWatershedFootprintReviewRequestStatusArgs = {
  input: GQUpdateWatershedFootprintReviewRequestStatusInput;
};

export type GQMutationUploadMarketplaceDocumentsArgs = {
  input: GQUploadMarketplaceDocumentsInput;
};

export type GQMutationUpsertCdpColumnMappingsArgs = {
  input: GQUpsertCdpColumnMappingsInput;
};

export type GQMutationUpsertCdpQuestionMappingsArgs = {
  input: GQUpsertCdpQuestionMappingsInput;
};

export type GQMutationUpsertCompanyArgs = {
  input: GQCompanyInput;
};

export type GQMutationUpsertCompanyPortalSettingsArgs = {
  input: GQUpsertCompanyPortalSettingsInput;
};

export type GQMutationUpsertCompanyReportingFactsArgs = {
  input: GQUpsertCompanyReportingFactsInput;
};

export type GQMutationUpsertDocumentParseAttemptArgs = {
  input: GQUpsertDocumentParseAttemptInput;
};

export type GQMutationUpsertEngagementTaskAnswersArgs = {
  input: GQUpsertEngagementTaskAnswersInput;
};

export type GQMutationUpsertFootprintCategoryRulesArgs = {
  input: GQUpsertFootprintCategoryRulesInput;
};

export type GQMutationUpsertFootprintExclusionRulesArgs = {
  input: GQUpsertFootprintExclusionRulesInput;
};

export type GQMutationUpsertFootprintTestSuiteConfigArgs = {
  input: GQUpsertFootprintTestSuiteConfigInput;
};

export type GQMutationUpsertNonFootprintSupplierArgs = {
  footprintInterval: Scalars['YMInterval']['input'];
  input: GQUpsertNonFootprintSupplierInput;
  version: InputMaybe<Scalars['String']['input']>;
};

export type GQMutationUpsertOrgCompanyForNameArgs = {
  input: GQUpsertOrgCompanyForNameInput;
};

export type GQMutationUpsertOrgStructureVersionArgs = {
  input: GQUpsertOrgStructureVersionInput;
};

export type GQMutationUpsertReportAnswerArgs = {
  input: GQUpsertReportAnswerInput;
};

export type GQMutationUpsertReportAnswerWithMetadataArgs = {
  input: GQUpsertReportAnswerInput;
};

export type GQMutationValidateEmissionsModelMatcherArgs = {
  input: GQValidateEmissionsModelMatcherInput;
};

export type GQMutationValidateFormAgainstCtsArgs = {
  input: GQValidateFormAgainstCtsInput;
};

export type GQMutationVerifyReportAnswersArgs = {
  reportAnswerIds: Array<Scalars['ID']['input']>;
};

export type GQMutationWithdrawCompanyChangeRequestArgs = {
  input: GQWithdrawCompanyChangeRequestInput;
};

export type GQNaicsEf = {
  __typename?: 'NaicsEF';
  emissionsSpecifier: Scalars['String']['output'];
  emissionsSubspecifier: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kgco2e: Scalars['Float']['output'];
  unit: Scalars['String']['output'];
};

export type GQNetZeroCommitment = GQCompanyClimateCommitment &
  GQIdInterface & {
    __typename?: 'NetZeroCommitment';
    commitmentMadeDate: Maybe<Scalars['Date']['output']>;
    commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
    commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
    description: Maybe<Scalars['String']['output']>;
    externalUrl: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    kind: GQCompanyClimateCommitmentKind;
    targetYear: Maybe<Scalars['Int']['output']>;
  };

export type GQNetZeroCommitmentInput = {
  targetYear?: InputMaybe<Scalars['Int']['input']>;
};

export const GQNetZeroNormalizationKind = {
  Revenue: 'Revenue',
  Total: 'Total',
} as const;

export type GQNetZeroNormalizationKind =
  (typeof GQNetZeroNormalizationKind)[keyof typeof GQNetZeroNormalizationKind];
export type GQNetZeroPlan = GQIdInterface & {
  __typename?: 'NetZeroPlan';
  descriptionMd: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  summary: Maybe<GQNetZeroSummary>;
};

export type GQNetZeroPlanSummaryArgs = {
  kind: InputMaybe<GQNetZeroNormalizationKind>;
};

export type GQNetZeroSummary = {
  __typename?: 'NetZeroSummary';
  kind: GQNetZeroNormalizationKind;
  years: Array<GQNetZeroSummaryYear>;
};

export type GQNetZeroSummaryYear = {
  __typename?: 'NetZeroSummaryYear';
  bauEmissionsAvoided: Scalars['Float']['output'];
  carbonRemoval: Scalars['Float']['output'];
  emissionsWithReductions: Scalars['Float']['output'];
  kind: GQNetZeroNormalizationKind;
  year: Scalars['Int']['output'];
};

export type GQNetsuiteIntegrationSettings = {
  __typename?: 'NetsuiteIntegrationSettings';
  accountNamesToExclude: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  transactionTypeFilters: Array<Scalars['String']['output']>;
  usePostingPeriodForDateFilters: Scalars['Boolean']['output'];
};

export type GQNetsuiteIntegrationSettingsInput = {
  accountNamesToExclude: Array<Scalars['String']['input']>;
  transactionTypeFilters: Array<Scalars['String']['input']>;
  usePostingPeriodForDateFilters: Scalars['Boolean']['input'];
};

export type GQNewMeasurementMappingInput = {
  authorExplanation?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endMonth: Scalars['YearMonth']['input'];
  ghgCategoryId?: InputMaybe<Scalars['String']['input']>;
  inputEmissionsSpecifier: Scalars['String']['input'];
  inputEmissionsSubspecifier: Scalars['String']['input'];
  outputEmissionsSpecifier: Scalars['String']['input'];
  outputEmissionsSubspecifier: Scalars['String']['input'];
  shouldInclude: Scalars['Boolean']['input'];
  startMonth: Scalars['YearMonth']['input'];
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  vendorEntity: Scalars['String']['input'];
};

export const GQNextLifecycleStage = {
  Measurement: 'Measurement',
  Reductions: 'Reductions',
  Reporting: 'Reporting',
} as const;

export type GQNextLifecycleStage =
  (typeof GQNextLifecycleStage)[keyof typeof GQNextLifecycleStage];
export type GQNotifyEngagementTaskCreationInput = {
  contactEmails: Array<Scalars['String']['input']>;
  emailBlurb?: InputMaybe<Scalars['String']['input']>;
  engagementTaskBatchId: Scalars['ID']['input'];
  forPortcos?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQNotifyEngagementTaskCreationPayload = {
  __typename?: 'NotifyEngagementTaskCreationPayload';
  engagementTaskBatch: GQEngagementTaskBatch;
};

export const GQObjectType = {
  Building: 'Building',
} as const;

export type GQObjectType = (typeof GQObjectType)[keyof typeof GQObjectType];
export const GQOnboardingKind = {
  BenchmarksV1Onboarding: 'BenchmarksV1Onboarding',
  BiDrilldownBeta: 'BiDrilldownBeta',
  BiDrilldownSavedViews: 'BiDrilldownSavedViews',
  CdpUpdateFy2024Q4Onboarding: 'CDPUpdateFY2024Q4Onboarding',
  ClimateProgramTimelineOnboarding: 'ClimateProgramTimelineOnboarding',
  DataLineageCalculationTableOnboarding:
    'DataLineageCalculationTableOnboarding',
  DataLineageDetailViewOnboarding: 'DataLineageDetailViewOnboarding',
  DataReviewOnboarding: 'DataReviewOnboarding',
  DrilldownDataGridBeta: 'DrilldownDataGridBeta',
  FinanceFlagsOnboarding: 'FinanceFlagsOnboarding',
  FootprintFeedbackSurvey: 'FootprintFeedbackSurvey',
  IdiOnboarding: 'IdiOnboarding',
  MarketplaceOnboarding: 'MarketplaceOnboarding',
  MeasurementEmptyStateExpandHowToMeasure:
    'MeasurementEmptyStateExpandHowToMeasure',
  MeasurementTaskTabOnboarding: 'MeasurementTaskTabOnboarding',
  MeasurementTasksOnboarding: 'MeasurementTasksOnboarding',
  MeasurementTimelineOnboarding: 'MeasurementTimelineOnboarding',
  NetworkTasksCalloutOnboarding: 'NetworkTasksCalloutOnboarding',
  NetworkTasksOnboarding: 'NetworkTasksOnboarding',
  NewBuildingsPageOnboarding: 'NewBuildingsPageOnboarding',
  NewToMeasurementOnboarding: 'NewToMeasurementOnboarding',
  ReductionsOnboarding: 'ReductionsOnboarding',
  ReduxEnterpriseOnboarding: 'ReduxEnterpriseOnboarding',
  ReportingCustomReportsWelcome: 'ReportingCustomReportsWelcome',
  SavedViewsLandingPage: 'SavedViewsLandingPage',
  TcfdOnboarding: 'TcfdOnboarding',
  ValueMappingOnboarding: 'ValueMappingOnboarding',
  WelcomeOnboarding: 'WelcomeOnboarding',
} as const;

export type GQOnboardingKind =
  (typeof GQOnboardingKind)[keyof typeof GQOnboardingKind];
export const GQOneSchemaDataFormat = {
  AssetCorporate: 'AssetCorporate',
  AssetGroup: 'AssetGroup',
  AssetHoldingAll: 'AssetHoldingAll',
  AssetPersonalMotorVehicleInsurance: 'AssetPersonalMotorVehicleInsurance',
  AssetRealEstate: 'AssetRealEstate',
  AssetSovereignBond: 'AssetSovereignBond',
  AssetYearDefault: 'AssetYearDefault',
  Fund: 'Fund',
  PersonalMotorVehicleInsuranceAssetHolding:
    'PersonalMotorVehicleInsuranceAssetHolding',
  PersonalMotorVehicleInsuranceAssetYear:
    'PersonalMotorVehicleInsuranceAssetYear',
  PointOfContact: 'PointOfContact',
  SovereignBondAssetYear: 'SovereignBondAssetYear',
} as const;

export type GQOneSchemaDataFormat =
  (typeof GQOneSchemaDataFormat)[keyof typeof GQOneSchemaDataFormat];
export type GQOneSchemaJwtPayload = {
  __typename?: 'OneSchemaJwtPayload';
  token: Scalars['String']['output'];
};

export type GQOpenAiMessage = {
  content: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type GQOrderMeasurementVerificationItemQuestionsInput = {
  questions: Array<GQMeasurementVerificationItemQuestionOrder>;
};

export type GQOrderMeasurementVerificationItemQuestionsPayload = {
  __typename?: 'OrderMeasurementVerificationItemQuestionsPayload';
  updatedQuestions: Maybe<Array<GQMeasurementVerificationItemQuestion>>;
};

export type GQOrgAdminSuppliers = {
  __typename?: 'OrgAdminSuppliers';
  organization: GQOrganization;
  suppliers: GQAdminSuppliers;
};

export type GQOrgCompany = {
  __typename?: 'OrgCompany';
  company: Maybe<GQCompany>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  source: Maybe<GQOrgCompanySource>;
};

export type GQOrgCompanyAndCompanyNameForCompany = {
  companyInfo: GQCreateCompanyBaseInput;
  orgCompanyName: Scalars['String']['input'];
};

export const GQOrgCompanySource = {
  CorporateSupplier: 'CorporateSupplier',
  FundHolding: 'FundHolding',
  NonFootprintSupplier: 'NonFootprintSupplier',
  SandboxCompany: 'SandboxCompany',
} as const;

export type GQOrgCompanySource =
  (typeof GQOrgCompanySource)[keyof typeof GQOrgCompanySource];
export type GQOrgConnection = {
  __typename?: 'OrgConnection';
  edges: Array<Maybe<GQOrgEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'Org' and its cursor. */
export type GQOrgEdge = {
  __typename?: 'OrgEdge';
  cursor: Scalars['String']['output'];
  node: GQOrganization;
};

/** An object representing limited essential information about an organization */
export type GQOrgLimitedProfile = {
  __typename?: 'OrgLimitedProfile';
  company: GQCompany;
  companyId: Scalars['ID']['output'];
  engagementTasks: Array<GQEngagementTaskLimitedProfile>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  watershedPlanLegacy: GQWatershedPlanLegacy;
};

export type GQOrgPointOfContact = GQIdInterface & {
  __typename?: 'OrgPointOfContact';
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  kind: GQOrgPointOfContactKind;
  orgId: Scalars['String']['output'];
  watershedEmployee: GQWatershedEmployee;
};

export const GQOrgPointOfContactKind = {
  AccountManager: 'AccountManager',
  AccountOwner: 'AccountOwner',
  CarbonDataAnalyst: 'CarbonDataAnalyst',
  ClimateAdvisor: 'ClimateAdvisor',
  CustomerSuccessManager: 'CustomerSuccessManager',
} as const;

export type GQOrgPointOfContactKind =
  (typeof GQOrgPointOfContactKind)[keyof typeof GQOrgPointOfContactKind];
export type GQOrgPost = GQIdInterface & {
  __typename?: 'OrgPost';
  content: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  summary: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type GQOrgPostConnection = {
  __typename?: 'OrgPostConnection';
  edges: Array<Maybe<GQOrgPostEdge>>;
  pageInfo: GQPageInfo;
};

export type GQOrgPostEdge = {
  __typename?: 'OrgPostEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQOrgPost>;
};

export type GQOrgProfile = {
  __typename?: 'OrgProfile';
  climateCommitments: Maybe<Array<GQCompanyClimateCommitmentKind>>;
  climateGoals: Maybe<Array<Scalars['String']['output']>>;
  climateMotivation: Maybe<Scalars['String']['output']>;
  completedAt: Maybe<Scalars['DateTime']['output']>;
  completedBy: Maybe<GQUser>;
  country: Maybe<Scalars['String']['output']>;
  fiscalYearStartMonth: Maybe<Scalars['Int']['output']>;
  hasClimateCommitments: Maybe<GQYesNoUnknown>;
  hasMeasuredFootprintOutsideOfWatershed: Maybe<GQYesNoUnknown>;
  id: Scalars['ID']['output'];
  industry: Maybe<Scalars['String']['output']>;
  isCompanyDataConfirmed: Maybe<Scalars['Boolean']['output']>;
  lastNonWatershedMeasurementYearString: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  otherClimateCommitment: Maybe<Scalars['String']['output']>;
  /** @deprecated Use country. Remove in July 2023 */
  primaryOperatingCountry: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  updatedBy: Maybe<GQUser>;
};

export type GQOrgStructureTypeInput = {
  name: Scalars['String']['input'];
  orgUnits: Array<Scalars['String']['input']>;
};

export type GQOrgStructureUnitRelationshipInput = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type GQOrgStructureVersion = {
  __typename?: 'OrgStructureVersion';
  effectiveFromDate: Maybe<Scalars['DateTime']['output']>;
  effectiveToDate: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  orgUnitTypes: Array<GQOrgUnitType>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: GQUser;
};

export type GQOrgUnit = {
  __typename?: 'OrgUnit';
  description: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgUnitDatasources: Array<GQOrgUnitDatasource>;
  orgUnitTypeId: Scalars['String']['output'];
  relatedOrgUnits: Array<GQOrgUnit>;
  stableId: Scalars['String']['output'];
};

export type GQOrgUnitDatasource = {
  __typename?: 'OrgUnitDatasource';
  createdAt: Scalars['DateTime']['output'];
  datasourceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgUnitStableId: Scalars['String']['output'];
  orgUnitTypeStableId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const GQOrgUnitRelationshipCardinality = {
  ManyToMany: 'ManyToMany',
  OneToMany: 'OneToMany',
  OneToOne: 'OneToOne',
} as const;

export type GQOrgUnitRelationshipCardinality =
  (typeof GQOrgUnitRelationshipCardinality)[keyof typeof GQOrgUnitRelationshipCardinality];
export type GQOrgUnitType = {
  __typename?: 'OrgUnitType';
  cyclesWhenFromNode: Array<Maybe<Array<Scalars['String']['output']>>>;
  cyclesWhenToNode: Array<Maybe<Array<Scalars['String']['output']>>>;
  enabledDatasets: Array<GQDatasetForOrgUnitType>;
  enabledForAllDatasets: Scalars['Boolean']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isLegalEntity: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  orgUnits: Array<GQOrgUnit>;
  relationships: Array<GQOrgUnitTypeRelationship>;
  requiredForAllDatasets: Scalars['Boolean']['output'];
  stableId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: GQUser;
};

export type GQOrgUnitTypeCyclesWhenFromNodeArgs = {
  baseOrgUnitTypeId: Scalars['ID']['input'];
};

export type GQOrgUnitTypeCyclesWhenToNodeArgs = {
  baseOrgUnitTypeId: Scalars['ID']['input'];
};

export type GQOrgUnitTypeRelationship = {
  __typename?: 'OrgUnitTypeRelationship';
  cardinality: GQOrgUnitRelationshipCardinality;
  defaultOrgUnitId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  orgUnitTypeIdFrom: Scalars['ID']['output'];
  orgUnitTypeIdTo: Scalars['ID']['output'];
};

export type GQOrganization = GQIdInterface & {
  __typename?: 'Organization';
  apiKeys: Array<GQApiKey>;
  bigqueryDatasetName: Maybe<Scalars['String']['output']>;
  bigqueryTableSchema: Scalars['String']['output'];
  buildingCompletionIntervalsUntyped: Array<GQBuildingCompletionIntervalsUntyped>;
  buildingNames: Array<Scalars['String']['output']>;
  buildingsUntyped: Array<GQBuildingUntyped>;
  canAccessCorporate: Maybe<Scalars['Boolean']['output']>;
  canAccessFinance: Maybe<Scalars['Boolean']['output']>;
  company: GQCompany;
  country: Maybe<Scalars['String']['output']>;
  currency: Maybe<Scalars['String']['output']>;
  dataIssues: Array<GQDataIssue>;
  dataRegion: GQDataRegion;
  datasets: Array<GQDataset>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  demoOrg: Scalars['Boolean']['output'];
  domains: Array<Scalars['String']['output']>;
  engagementFunnels: Array<GQEngagementFunnel>;
  facilitiesMetadata: GQFacilitiesMetadata;
  fiscalYearStartMonth: Maybe<Scalars['Int']['output']>;
  footprintCategoryRules: Array<GQFootprintCategoryRule>;
  footprintExclusionRules: Array<GQFootprintExclusionRule>;
  footprintExclusionSuggestions: Array<GQFootprintExclusionSuggestion>;
  hasActiveWorkosConnection: Scalars['Boolean']['output'];
  hasAnyPv2PipelineResults: Scalars['Boolean']['output'];
  hasUsers: Scalars['Boolean']['output'];
  helpChannel: Maybe<Scalars['String']['output']>;
  iconRemotePath: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ingestionCustomFields: Array<GQIngestionCustomField>;
  isWatershed: Scalars['Boolean']['output'];
  legalName: Maybe<Scalars['String']['output']>;
  lifecycle: Maybe<GQClimateProgramLifecycle>;
  logoRemotePath: Maybe<Scalars['String']['output']>;
  magicLinkDisabled: Scalars['Boolean']['output'];
  measurementProjects: Array<GQMeasurementProject>;
  name: Scalars['String']['output'];
  nextFootprintVersionIdentifier: Scalars['String']['output'];
  notionUrl: Maybe<Scalars['String']['output']>;
  orgCompanies: Array<GQOrgCompany>;
  passwordAuthDisabled: Scalars['Boolean']['output'];
  pointsOfContact: Array<GQOrgPointOfContact>;
  pointsOfContactIncludingDeleted: Array<GQOrgPointOfContact>;
  productWaitlistTypes: Array<GQProductWaitlistType>;
  profile: GQOrgProfile;
  region: GQRegion;
  roles: Maybe<GQRoleConnection>;
  sbtiTargetExclusions: Array<GQSbtiTargetExclusion>;
  sharedFileRequests: GQSharedFileRequestConnection;
  sharedFiles: GQSharedFileConnection;
  stagingOrg: Maybe<Scalars['Boolean']['output']>;
  state: GQOrganizationState;
  targets: GQTargetConnection;
  testOrg: Scalars['Boolean']['output'];
  users: Maybe<GQUserConnection>;
  watershedPlan: GQWatershedPlan;
  watershedPlanLegacy: GQWatershedPlanLegacy;
  watershedSlackChannelId: Maybe<Scalars['String']['output']>;
  workosConnections: Array<GQWorkosConnection>;
  workosOrgId: Maybe<Scalars['String']['output']>;
};

export type GQOrganizationFootprintCategoryRulesArgs = {
  footprintSnapshotId: InputMaybe<Scalars['ID']['input']>;
};

export type GQOrganizationFootprintExclusionRulesArgs = {
  footprintSnapshotId: InputMaybe<Scalars['ID']['input']>;
};

export type GQOrganizationFootprintExclusionSuggestionsArgs = {
  footprintSnapshotId: Scalars['ID']['input'];
};

export type GQOrganizationMeasurementProjectsArgs = {
  active: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQOrganizationPointsOfContactArgs = {
  kinds: InputMaybe<Array<GQOrgPointOfContactKind>>;
};

export type GQOrganizationRolesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationSharedFileRequestsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationSharedFilesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationTargetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationUsersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<GQUserFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationInboxItem =
  | GQDataIssue
  | GQFootprintQuestion
  | GQUserUploadTask;

export type GQOrganizationInboxItemConnection = {
  __typename?: 'OrganizationInboxItemConnection';
  edges: Array<Maybe<GQOrganizationInboxItemEdge>>;
  pageInfo: GQPageInfo;
};

export type GQOrganizationInboxItemEdge = {
  __typename?: 'OrganizationInboxItemEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQOrganizationInboxItem>;
};

export type GQOrganizationPayload = {
  __typename?: 'OrganizationPayload';
  org: GQOrganization;
};

export const GQOrganizationState = {
  Active: 'ACTIVE',
  Onboarding: 'ONBOARDING',
} as const;

export type GQOrganizationState =
  (typeof GQOrganizationState)[keyof typeof GQOrganizationState];
export type GQOverrideContainerProgressInput = {
  containerId: Scalars['ID']['input'];
  progress?: InputMaybe<GQResolvedContainerProgress>;
  reportId: Scalars['ID']['input'];
};

/** The Relay compliant 'PageInfo' type, containing data necessary to paginate this connection. */
export type GQPageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
  /**
   * Only some connections will provide a total row count, because it can be
   * expensive to calculate.
   */
  totalRowCount: Maybe<Scalars['Int']['output']>;
};

export type GQParameterResolver = GQIdInterface & {
  __typename?: 'ParameterResolver';
  authorId: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type GQParentRelationship = {
  __typename?: 'ParentRelationship';
  id: Scalars['ID']['output'];
  parent: GQCompany;
  parentId: Scalars['ID']['output'];
  relationshipEndDate: Maybe<Scalars['YearMonth']['output']>;
  relationshipStartDate: Maybe<Scalars['YearMonth']['output']>;
  watershedEditorId: Maybe<Scalars['ID']['output']>;
};

export type GQParquetColumn = {
  __typename?: 'ParquetColumn';
  approxDistinctValues: Scalars['Int']['output'];
  maxValue: Scalars['JSONString']['output'];
  minValue: Scalars['JSONString']['output'];
  name: Scalars['String']['output'];
  numNulls: Scalars['Int']['output'];
  sampleValues: Array<Scalars['JSONString']['output']>;
  sqlType: Scalars['String']['output'];
};

export type GQParquetData = GQIdInterface & {
  __typename?: 'ParquetData';
  columns: Array<GQParquetColumn>;
  id: Scalars['ID']['output'];
  ndjsonByteBoundaries: Maybe<Array<Scalars['Int53']['output']>>;
  ndjsonSignedUrl: Maybe<Scalars['String']['output']>;
  ndjsonSize: Maybe<Scalars['Int53']['output']>;
  numRecords: Scalars['Int']['output'];
};

export type GQParseConfigInput = {
  meltIdVars?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noHeader: Scalars['Boolean']['input'];
  skipBottomNRows: Scalars['Int']['input'];
  skipTopNRows: Scalars['Int']['input'];
};

export const GQPcafScore = {
  Score1: 'Score1',
  Score2: 'Score2',
  Score3: 'Score3',
  Score4: 'Score4',
  Score5: 'Score5',
} as const;

export type GQPcafScore = (typeof GQPcafScore)[keyof typeof GQPcafScore];
export type GQPeerCompanyGroup = {
  __typename?: 'PeerCompanyGroup';
  createdAt: Scalars['DateTime']['output'];
  footprintKinds: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  members: Array<GQPeerCompanyGroupMember>;
  name: Scalars['String']['output'];
  revenueForRevenueAtRisk: Maybe<Scalars['Int53']['output']>;
  selfBenchmarks: Array<GQBenchmark>;
  shouldHideFromFootprintOverview: Scalars['Boolean']['output'];
};

export type GQPeerCompanyGroupSelfBenchmarksArgs = {
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  version: InputMaybe<Scalars['String']['input']>;
};

export type GQPeerCompanyGroupMember = {
  __typename?: 'PeerCompanyGroupMember';
  company: GQCompany;
  id: Scalars['ID']['output'];
  mostRecentHighQualityBenchmark: Maybe<GQBenchmark>;
  revenueAtRisk: Maybe<Scalars['Int53']['output']>;
  shouldHideFromFootprintOverview: Scalars['Boolean']['output'];
};

export type GQPeerCompanyGroupPayload = {
  __typename?: 'PeerCompanyGroupPayload';
  id: Scalars['ID']['output'];
  peerCompanyGroup: GQPeerCompanyGroup;
};

export type GQPeerExternalReportAnswers = {
  __typename?: 'PeerExternalReportAnswers';
  answers: Array<GQExternalReportAnswer>;
  peerCompanies: Array<GQCompany>;
  questions: Array<GQExternalReportQuestion>;
};

export type GQPeerExternalReportAnswersForIdentifiersPayload = {
  __typename?: 'PeerExternalReportAnswersForIdentifiersPayload';
  answers: Array<GQExternalReportAnswer>;
  peerCompanies: Array<GQCompany>;
  questions: Array<GQExternalReportQuestion>;
};

export const GQPermission = {
  Measurement: 'Measurement',
  MeasurementAdmin: 'MeasurementAdmin',
  Viewer: 'Viewer',
  WatershedAdmin: 'WatershedAdmin',
} as const;

export type GQPermission = (typeof GQPermission)[keyof typeof GQPermission];
export type GQPermissionConnection = {
  __typename?: 'PermissionConnection';
  edges: Array<Maybe<GQPermissionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQPermissionEdge = {
  __typename?: 'PermissionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPermissionItem>;
};

export type GQPermissionItem = {
  __typename?: 'PermissionItem';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  object: Maybe<
    | GQCompany
    | GQCompanySurvey
    | GQDataset
    | GQDatasource
    | GQEngagementTask
    | GQFund
  >;
  objectId: Maybe<Scalars['ID']['output']>;
  org: GQOrganization;
  permission: GQPermissionType;
  revokedAt: Maybe<Scalars['DateTime']['output']>;
  role: Maybe<GQRole>;
  updatedAt: Scalars['DateTime']['output'];
  user: Maybe<GQUser>;
};

export type GQPermissionItemInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectType?: InputMaybe<GQPermissionObjectType>;
  permission: GQPermissionType;
  revokedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GQPermissionObject = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export const GQPermissionObjectType = {
  Company: 'Company',
  CompanySurvey: 'CompanySurvey',
  Dataset: 'Dataset',
  Datasource: 'Datasource',
  EngagementTask: 'EngagementTask',
  Fund: 'Fund',
} as const;

export type GQPermissionObjectType =
  (typeof GQPermissionObjectType)[keyof typeof GQPermissionObjectType];
export type GQPermissionOrigin = {
  __typename?: 'PermissionOrigin';
  id: Scalars['ID']['output'];
  permission: Maybe<GQPermissionItem>;
  role: Maybe<GQUserRoleAssignment>;
};

export const GQPermissionType = {
  Admin: 'Admin',
  AnyUser: 'AnyUser',
  ApproveDatasource: 'ApproveDatasource',
  CorporateAdmin: 'CorporateAdmin',
  FinanceAdmin: 'FinanceAdmin',
  FinanceReadOnly: 'FinanceReadOnly',
  ManageDataset: 'ManageDataset',
  ManageDatasource: 'ManageDatasource',
  ManageDisclosures: 'ManageDisclosures',
  ManageFund: 'ManageFund',
  ManageMarketplacePurchases: 'ManageMarketplacePurchases',
  ManageMeasurement: 'ManageMeasurement',
  ManageReductionPlans: 'ManageReductionPlans',
  ManageSingleSignOn: 'ManageSingleSignOn',
  ManageSuppliers: 'ManageSuppliers',
  ViewAuditDetail: 'ViewAuditDetail',
  ViewEmployeeReport: 'ViewEmployeeReport',
  ViewFootprintDetail: 'ViewFootprintDetail',
  ViewLearningHub: 'ViewLearningHub',
  ViewReductions: 'ViewReductions',
  WatershedAdmin: 'WatershedAdmin',
} as const;

export type GQPermissionType =
  (typeof GQPermissionType)[keyof typeof GQPermissionType];
export type GQPerson = {
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Please use isWatershedEmployee or isWatershedContractor */
  isWatershed: Scalars['Boolean']['output'];
  isWatershedContractor: Scalars['Boolean']['output'];
  isWatershedEmployee: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type GQPipelineConfig = {
  __typename?: 'PipelineConfig';
  coverageInterval: Scalars['YMInterval']['output'];
  pipelineResultId: Scalars['ID']['output'];
};

export type GQPipelineConfigInput = {
  coverageInterval: Scalars['YMInterval']['input'];
  pipelineResultId: Scalars['ID']['input'];
};

export const GQPipelineKind = {
  AmazonCurPipeline: 'amazon_cur_pipeline',
  BuildingsPipeline: 'buildings_pipeline',
  CloudComputePipeline: 'cloud_compute_pipeline',
  CloudSpendPipeline: 'cloud_spend_pipeline',
  CommutePipeline: 'commute_pipeline',
  DangerousExperiment: 'dangerous_experiment',
  EmissionsModel: 'emissions_model',
  FinancialsPipeline: 'financials_pipeline',
  ManualUpload: 'manual_upload',
  PipelineV2All: 'pipeline_v2_all',
  PipelineV2Cloud: 'pipeline_v2_cloud',
  PipelineV2Commute: 'pipeline_v2_commute',
  PipelineV2Financials: 'pipeline_v2_financials',
  PipelineV2Flights: 'pipeline_v2_flights',
  PipelineV2Offices: 'pipeline_v2_offices',
  PipelineV2Travel: 'pipeline_v2_travel',
  PipelineV2Wfh: 'pipeline_v2_wfh',
  TravelPipeline: 'travel_pipeline',
  WastePipeline: 'waste_pipeline',
  WfhPipeline: 'wfh_pipeline',
} as const;

export type GQPipelineKind =
  (typeof GQPipelineKind)[keyof typeof GQPipelineKind];
export type GQPipelineOutputSummary = {
  __typename?: 'PipelineOutputSummary';
  emissions: Array<GQPipelineOutputSummaryEmission>;
  footprintQuantityMeta: Maybe<GQFootprintQuantityKindMetadata>;
  format: Scalars['Int']['output'];
  warnings: Maybe<Array<GQValidationWarning>>;
};

export type GQPipelineOutputSummaryEmission = {
  __typename?: 'PipelineOutputSummaryEmission';
  adtName: Maybe<Scalars['String']['output']>;
  businessCategory: Maybe<Scalars['String']['output']>;
  footprintKind: Scalars['String']['output'];
  kgCo2e: Scalars['Float']['output'];
  month: Scalars['Date']['output'];
};

export type GQPipelineResult = GQIdInterface & {
  __typename?: 'PipelineResult';
  coverageInterval: Scalars['YMInterval']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  emissionsModelEvaluations: Array<GQEmissionsModelEvaluation>;
  id: Scalars['ID']['output'];
  isManual: Scalars['Boolean']['output'];
  isUsedInLatestDraft: Scalars['Boolean']['output'];
  isUsedInLatestPublish: Scalars['Boolean']['output'];
  kgco2eByColumnSummary: Array<GQPipelineResultQuerySummaryRow>;
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  parquetDataId: Maybe<Scalars['ID']['output']>;
  pipelineKind: GQPipelineKind;
  pipelineRunConfig: Scalars['String']['output'];
  prkFileMetadataId: Maybe<Scalars['String']['output']>;
  runUserId: Maybe<Scalars['ID']['output']>;
  sourceData: Scalars['String']['output'];
  summary: GQPipelineOutputSummary;
  summaryJson: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQPipelineResultKgco2eByColumnSummaryArgs = {
  columns: Array<Scalars['String']['input']>;
};

export type GQPipelineResultConnection = {
  __typename?: 'PipelineResultConnection';
  edges: Array<Maybe<GQPipelineResultEdge>>;
  pageInfo: GQPageInfo;
};

export type GQPipelineResultEdge = {
  __typename?: 'PipelineResultEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPipelineResult>;
};

export type GQPipelineResultEmissionsModelEvaluation = GQIdInterface & {
  __typename?: 'PipelineResultEmissionsModelEvaluation';
  createdAt: Scalars['DateTime']['output'];
  emissionsModelEvaluation: GQEmissionsModelEvaluation;
  emissionsModelEvaluationId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  pipelineResult: GQPipelineResult;
  pipelineResultId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQPipelineResultQuerySummaryColumnValue = {
  __typename?: 'PipelineResultQuerySummaryColumnValue';
  columnName: Scalars['String']['output'];
  columnValue: Maybe<Scalars['String']['output']>;
};

export type GQPipelineResultQuerySummaryRow = {
  __typename?: 'PipelineResultQuerySummaryRow';
  columns: Array<GQPipelineResultQuerySummaryColumnValue>;
  kgco2e: Scalars['Float']['output'];
};

export type GQPipelineRun = GQIdInterface & {
  __typename?: 'PipelineRun';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  pipelineResults: Array<GQPipelineResult>;
};

export type GQPipelineRunConnection = {
  __typename?: 'PipelineRunConnection';
  edges: Array<Maybe<GQPipelineRunEdge>>;
  pageInfo: GQPageInfo;
};

export type GQPipelineRunEdge = {
  __typename?: 'PipelineRunEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPipelineRun>;
};

export type GQPlan = GQIdInterface & {
  __typename?: 'Plan';
  baselineYearStart: Scalars['YearMonth']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  fiscalMonth: Scalars['Int']['output'];
  forecast: GQForecast;
  hideCarbonAvoidance: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  isSbtValidated: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  reduxCarbonOffsets: GQSimpleTimeseries;
  reduxCarbonOffsetsCosts: GQSimpleTimeseries;
  reduxCarbonRemoval: GQSimpleTimeseries;
  reduxCarbonRemovalCosts: GQSimpleTimeseries;
  reduxInitiatives: Array<
    GQFootprintSliceInitiative | GQPrecomputedActivityBasedInitiative
  >;
  relevantFootprintFields: Array<Scalars['String']['output']>;
  state: GQPlanState;
  targets: Array<GQPlanTarget>;
  updatedAt: Scalars['DateTime']['output'];
  updatedByName: Maybe<Scalars['String']['output']>;
  variables: GQPlanVariables;
};

export type GQPlanTargetsArgs = {
  ids: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQPlanCarbonFund = {
  __typename?: 'PlanCarbonFund';
  highQualityOffset: Scalars['Float']['output'];
  natureRemoval: Scalars['Float']['output'];
  offset: Scalars['Float']['output'];
  rndRemoval: Scalars['Float']['output'];
};

export type GQPlanCarbonFundInput = {
  highQualityOffset: Scalars['Float']['input'];
  natureRemoval: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
  rndRemoval: Scalars['Float']['input'];
};

export type GQPlanConnection = {
  __typename?: 'PlanConnection';
  edges: Array<Maybe<GQPlanEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'Plan' and its cursor. */
export type GQPlanEdge = {
  __typename?: 'PlanEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPlan>;
};

export const GQPlanState = {
  Archived: 'Archived',
  Committed: 'Committed',
  Draft: 'Draft',
} as const;

export type GQPlanState = (typeof GQPlanState)[keyof typeof GQPlanState];
export type GQPlanTarget = GQIdInterface & {
  __typename?: 'PlanTarget';
  assignedTo: Maybe<Scalars['String']['output']>;
  baseYear: Scalars['YearMonth']['output'];
  comparisonType: GQPlanTargetTargetComparisonType;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customIntensityConfigId: Maybe<Scalars['ID']['output']>;
  customNotes: Scalars['String']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  emissionsTargetCustom: GQSimpleTimeseries;
  filters: GQFilterExpressionGroup;
  id: Scalars['ID']['output'];
  intensityType: GQPlanTargetIntensityType;
  interimTargetDate: Maybe<Scalars['YearMonth']['output']>;
  interimTargetValue: Maybe<Scalars['Float']['output']>;
  parentTarget: Maybe<GQPlanTarget>;
  parentTargetId: Maybe<Scalars['String']['output']>;
  reductionRate: GQTargetReductionRate;
  reportDescription: Scalars['String']['output'];
  subtargets: Array<GQPlanTarget>;
};

export const GQPlanTargetIntensityType = {
  Absolute: 'Absolute',
  Custom: 'Custom',
  GrossProfit: 'GrossProfit',
  Headcount: 'Headcount',
  NightsStayed: 'NightsStayed',
  Orders: 'Orders',
  Patients: 'Patients',
  RenewableElectricity: 'RenewableElectricity',
  Revenue: 'Revenue',
  SupplierEngagement: 'SupplierEngagement',
  SupplierEngagementBySpend: 'SupplierEngagementBySpend',
} as const;

export type GQPlanTargetIntensityType =
  (typeof GQPlanTargetIntensityType)[keyof typeof GQPlanTargetIntensityType];
export const GQPlanTargetTargetComparisonType = {
  BaseYear: 'BaseYear',
  BusinessAsUsual: 'BusinessAsUsual',
} as const;

export type GQPlanTargetTargetComparisonType =
  (typeof GQPlanTargetTargetComparisonType)[keyof typeof GQPlanTargetTargetComparisonType];
export type GQPlanVariables = {
  __typename?: 'PlanVariables';
  cleanPowerStart: Scalars['YearMonth']['output'];
  commitmentsHasNetZero: Scalars['Boolean']['output'];
  commitmentsHasSBT: Scalars['Boolean']['output'];
  commitmentsNetZeroYear: Scalars['Int']['output'];
  commitmentsSBTScope3: GQSbtScope3Target;
  commitmentsSBTScope12: GQSbtScope1And2Target;
  commitmentsSBTSubmissionDate: Scalars['YearMonth']['output'];
  commitmentsSBTTargetYear: Scalars['Int']['output'];
  commitmentsSBTTermLength: GQSbtTermLength;
  flightsPerEmployeeForecastEnd: Scalars['Float']['output'];
  netZeroSuppliersReductionPercent: Scalars['Float']['output'];
  percentWorkFromHomeForecastEnd: Scalars['Float']['output'];
};

export type GQPlanVariablesInput = {
  cleanPowerStart: Scalars['YearMonth']['input'];
  commitmentsHasNetZero: Scalars['Boolean']['input'];
  commitmentsHasSBT: Scalars['Boolean']['input'];
  commitmentsNetZeroYear: Scalars['Int']['input'];
  commitmentsSBTScope3: GQSbtScope3Target;
  commitmentsSBTScope12: GQSbtScope1And2Target;
  commitmentsSBTSubmissionDate: Scalars['YearMonth']['input'];
  commitmentsSBTTargetYear: Scalars['Int']['input'];
  commitmentsSBTTermLength: GQSbtTermLength;
  flightsPerEmployeeForecastEnd: Scalars['Float']['input'];
  netZeroSuppliersReductionPercent?: InputMaybe<Scalars['Float']['input']>;
  percentWorkFromHomeForecastEnd: Scalars['Float']['input'];
};

export type GQPlaygroundSql = GQIdInterface & {
  __typename?: 'PlaygroundSql';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  sql: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQPlaygroundSqlRun = GQIdInterface &
  GQQueryRunInterface & {
    __typename?: 'PlaygroundSqlRun';
    createdAt: Scalars['DateTime']['output'];
    error: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    jobError: Maybe<GQSerializableError>;
    output: Maybe<GQParquetData>;
    sql: Scalars['String']['output'];
    state: GQBackgroundJobState;
    user: Maybe<GQUser>;
  };

export type GQPlaygroundSqlRunConnection = {
  __typename?: 'PlaygroundSqlRunConnection';
  edges: Array<Maybe<GQPlaygroundSqlRunEdge>>;
  pageInfo: GQPageInfo;
};

export type GQPlaygroundSqlRunEdge = {
  __typename?: 'PlaygroundSqlRunEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPlaygroundSqlRun>;
};

export type GQPortcoFootprintReviewRequestMetadata = {
  __typename?: 'PortcoFootprintReviewRequestMetadata';
  footprintSnapshotId: Maybe<Scalars['ID']['output']>;
  footprintVersionId: Scalars['ID']['output'];
  footprintVersionKind: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: Maybe<GQWatershedFootprintReviewRequestStatus>;
};

export type GQPowerSimulationData = {
  __typename?: 'PowerSimulationData';
  rows: Array<GQPowerSimulationRow>;
};

export type GQPowerSimulationRow = {
  __typename?: 'PowerSimulationRow';
  percentageOfEmissions: Maybe<Scalars['Float']['output']>;
  region: Maybe<Scalars['String']['output']>;
  supplier: Maybe<Scalars['String']['output']>;
  totalEmissions: Maybe<Scalars['Float']['output']>;
  unitsProduced: Maybe<Scalars['Float']['output']>;
};

export type GQPrecomputedActivityBasedInitiative = GQIdInterface &
  GQInitiative & {
    __typename?: 'PrecomputedActivityBasedInitiative';
    costTimeseries: GQSimpleTimeseries;
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    description: Maybe<Scalars['String']['output']>;
    enabled: Scalars['Boolean']['output'];
    filters: GQFilterExpressionGroupWithNewFilters;
    id: Scalars['ID']['output'];
    implementationTimeseries: GQSimpleTimeseries;
    lever: GQPrecomputedLever;
    name: Scalars['String']['output'];
    plan: GQPlan;
    scheduleType: GQScheduleType;
  };

export const GQPrecomputedLever = {
  ChangeCareLabelToAirDry: 'ChangeCareLabelToAirDry',
  ChangeCareLabelToWashCold: 'ChangeCareLabelToWashCold',
  ElectrifyGasStovesInOffices: 'ElectrifyGasStovesInOffices',
  EliminateEmployeeWfhElectricityEmissions:
    'EliminateEmployeeWfhElectricityEmissions',
  EnergyEfficiencyImprovementsHeatPumps:
    'EnergyEfficiencyImprovementsHeatPumps',
  GreenRideShareOptions: 'GreenRideShareOptions',
  GroundTransportInsteadOfAir: 'GroundTransportInsteadOfAir',
  ImplementGreenCodingPractices: 'ImplementGreenCodingPractices',
  ImproveElectricityEfficiencyOfficeBuildings:
    'ImproveElectricityEfficiencyOfficeBuildings',
  LocalizeSupply: 'LocalizeSupply',
  OptimizeCloudComputeType: 'OptimizeCloudComputeType',
  OptimizeCloudHostingLocation: 'OptimizeCloudHostingLocation',
  ProductUsersAdoptMoreEfficientWasherDryers:
    'ProductUsersAdoptMoreEfficientWasherDryers',
  PurchaseReCsOrEaCs: 'PurchaseRECsOrEACs',
  ReduceEmissionsFromElectricityProduction:
    'ReduceEmissionsFromElectricityProduction',
  ReduceRefrigerantLeakageRate: 'ReduceRefrigerantLeakageRate',
  ReduceTotalTravelMiles: 'ReduceTotalTravelMiles',
  ReplaceConventionalJetFuelWithSustainableAviationFuel:
    'ReplaceConventionalJetFuelWithSustainableAviationFuel',
  RequirePassengerTrainInsteadOfShortHaulFlight:
    'RequirePassengerTrainInsteadOfShortHaulFlight',
  SwapAirFreightToRailFreight: 'SwapAirFreightToRailFreight',
  SwapAirFreightToSeaFreight: 'SwapAirFreightToSeaFreight',
  SwapEmployeeCommuteFromIceVehicleToElectricVehicle:
    'SwapEmployeeCommuteFromIceVehicleToElectricVehicle',
  SwapEmployeeCommuteFromIceVehicleToPublicTransport:
    'SwapEmployeeCommuteFromIceVehicleToPublicTransport',
  SwapFlightClassFromBusinessToEconomy: 'SwapFlightClassFromBusinessToEconomy',
  SwapFlightClassFromFirstToEconomy: 'SwapFlightClassFromFirstToEconomy',
  SwapGroundTransportToRailFreight: 'SwapGroundTransportToRailFreight',
  SwapProductsSentToLandfillToRecycled: 'SwapProductsSentToLandfillToRecycled',
  SwapToLowerGwpRefrigerants: 'SwapToLowerGWPRefrigerants',
  SwapWasteFromLandfillToCompost: 'SwapWasteFromLandfillToCompost',
  SwapWasteFromLandfillToRecycle: 'SwapWasteFromLandfillToRecycle',
  SwitchNaturalGasToHeatPumps: 'SwitchNaturalGasToHeatPumps',
  SwitchToLowEmissionsCloudProvider: 'SwitchToLowEmissionsCloudProvider',
} as const;

export type GQPrecomputedLever =
  (typeof GQPrecomputedLever)[keyof typeof GQPrecomputedLever];
export type GQPreflightEraseReportComponentPayload = {
  __typename?: 'PreflightEraseReportComponentPayload';
  affectedOrgs: Array<GQOrganization>;
  componentId: Scalars['String']['output'];
  reportAnswers: Array<GQReportAnswer>;
  reportConfigs: Array<GQReportConfig>;
  reportQuestions: Array<GQReportQuestion>;
  reports: Array<GQReport>;
};

export type GQPrepublicationTestResults = {
  __typename?: 'PrepublicationTestResults';
  emissionsModelValidationErrors: Maybe<Array<GQEmissionsModelError>>;
  measurementTestSuiteExecutions: Array<GQPrepublicationTestSuiteExecution>;
};

export type GQPrepublicationTestSuiteExecution = {
  __typename?: 'PrepublicationTestSuiteExecution';
  errorMessage: Maybe<Scalars['String']['output']>;
  footprintTestSuiteExecution: Maybe<GQFootprintTestSuiteExecution>;
  footprintTestSuiteExecutionOnTheT: Maybe<GQFootprintTestSuiteExecution>;
  measurementTestSuite: GQMeasurementTestSuite;
  methodologyTestSuiteExecution: GQMethodologyTestSuiteExecution;
};

export type GQPresetCustomTag = {
  __typename?: 'PresetCustomTag';
  ingestionCustomField: GQIngestionCustomField;
  ingestionCustomFieldDatasource: GQIngestionCustomFieldDatasource;
};

export type GQPresetOrgUnit = {
  __typename?: 'PresetOrgUnit';
  orgUnit: GQOrgUnit;
  orgUnitDatasource: GQOrgUnitDatasource;
};

export type GQPreviewDataIssueEmailsInput = {
  commentIds: Array<Scalars['ID']['input']>;
  issueIds: Array<Scalars['ID']['input']>;
};

export type GQPreviewDataIssueEmailsPayload = {
  __typename?: 'PreviewDataIssueEmailsPayload';
  numberOfEmails: Scalars['Int']['output'];
  sampleEmails: Array<GQEmailContentsWithUserRecipient>;
};

export type GQPreviewFinancialsReviewEmailsInput = {
  measurementProjectId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQPreviewFinancialsReviewEmailsPayload = {
  __typename?: 'PreviewFinancialsReviewEmailsPayload';
  emails: Array<GQEmailContentsWithUserRecipient>;
};

export type GQPreviewMeasurementVerificationItemEmailsInput = {
  orgId: Scalars['String']['input'];
  verificationItemIds: Array<Scalars['ID']['input']>;
};

export type GQPreviewMeasurementVerificationItemEmailsPayload = {
  __typename?: 'PreviewMeasurementVerificationItemEmailsPayload';
  previewEmails: Array<GQEmailContentsWithUserRecipient>;
};

export type GQPreviewUtilitiesDiffInput = {
  ctsVersionId: Scalars['String']['input'];
  embedId: Scalars['String']['input'];
  fileMetadataId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  userUploadTaskId: Scalars['String']['input'];
};

export type GQPrivateDisclosure = {
  __typename?: 'PrivateDisclosure';
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  assetPersonalMotorVehicleInsuranceId: Maybe<Scalars['ID']['output']>;
  cdpVendorData: Maybe<GQCdpVendorData>;
  companyId: Maybe<Scalars['ID']['output']>;
  companyIngestionRecordUpdateId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  disclosureQualityExplanation: Maybe<Scalars['String']['output']>;
  disclosureQualityScore: Maybe<GQDisclosureQualityScore>;
  engagementTaskId: Maybe<Scalars['ID']['output']>;
  footprintDisclosureId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  orgName: Scalars['String']['output'];
  privateDisclosureType: GQPrivateDisclosureType;
  sourceId: Scalars['ID']['output'];
  surveyId: Maybe<Scalars['ID']['output']>;
  thirdPartyVerified: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userEditorId: Scalars['ID']['output'];
  watershedEditorId: Maybe<Scalars['ID']['output']>;
};

export type GQPrivateDisclosureInput = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
};

export const GQPrivateDisclosureType = {
  Cdp: 'CDP',
  Estimate: 'Estimate',
  ManualEntry: 'ManualEntry',
  Survey: 'Survey',
  SurveyEstimate: 'SurveyEstimate',
  WatershedMeasurement: 'WatershedMeasurement',
} as const;

export type GQPrivateDisclosureType =
  (typeof GQPrivateDisclosureType)[keyof typeof GQPrivateDisclosureType];
export type GQProcessCompanyIngestionDiffPayload = {
  __typename?: 'ProcessCompanyIngestionDiffPayload';
  companyIngestionDiff: GQCompanyIngestionDiff;
  companyIngestionRecordUpdates: Array<GQCompanyIngestionRecordUpdate>;
};

export type GQProcessCompanyIngestionUpdatePayload = {
  __typename?: 'ProcessCompanyIngestionUpdatePayload';
  company: Maybe<GQCompany>;
  companyIngestionRecordUpdate: GQCompanyIngestionRecordUpdate;
};

export type GQProductFootprintRowReference = {
  __typename?: 'ProductFootprintRowReference';
  id: Scalars['String']['output'];
  product: Scalars['String']['output'];
  totalEmissions: Scalars['Float']['output'];
};

export type GQProductFootprintRowUpdatedTaxonomy = {
  __typename?: 'ProductFootprintRowUpdatedTaxonomy';
  categoryEmissionsPercent: Maybe<Scalars['Float']['output']>;
  emissionsPerUnit: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  product: Scalars['String']['output'];
  relatedMaterials: Array<GQMaterialFootprintRowReference>;
  totalEmissions: Scalars['Float']['output'];
  totalEmissionsPercent: Maybe<Scalars['Float']['output']>;
  unitsProduced: Scalars['Float']['output'];
  unitsProducedPercentage: Maybe<Scalars['Float']['output']>;
  vectors: Maybe<Array<GQVectorNameAndValues>>;
};

export const GQProductWaitlistType = {
  Ppa: 'Ppa',
  SupplyChain: 'SupplyChain',
  Vppa: 'Vppa',
} as const;

export type GQProductWaitlistType =
  (typeof GQProductWaitlistType)[keyof typeof GQProductWaitlistType];
export type GQProductsAndMaterialSimulationData = {
  __typename?: 'ProductsAndMaterialSimulationData';
  materials: Array<GQMaterialFootprintRowUpdatedTaxonomy>;
  products: Array<GQProductFootprintRowUpdatedTaxonomy>;
};

export type GQProgramSubstatusData = {
  __typename?: 'ProgramSubstatusData';
  count: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  statusLabel: Scalars['String']['output'];
};

export type GQPruneOrphanedQuestionsForConfigPayload = {
  __typename?: 'PruneOrphanedQuestionsForConfigPayload';
  deleteCount: Scalars['Float']['output'];
};

export type GQPublicDisclosure = {
  __typename?: 'PublicDisclosure';
  cdpVendorData: Maybe<GQCdpVendorData>;
  company: Maybe<GQCompany>;
  companyId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  externalScore: Maybe<Scalars['String']['output']>;
  gsUrl: Maybe<Scalars['String']['output']>;
  historicalEmissionsYearsDetails: Array<GQEmissionsYear>;
  id: Scalars['ID']['output'];
  publicUrl: Scalars['String']['output'];
  publishingYear: Scalars['Int']['output'];
  qualityScore: Maybe<GQDisclosureQualityScore>;
  reportType: GQExternalReportType;
  reportingYears: Array<Scalars['Int']['output']>;
  sectionUrls: Maybe<Array<GQExternalReportSectionUrl>>;
  source: GQPublicDisclosureSource;
  sourceRecordId: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQPublicDisclosureConnection = {
  __typename?: 'PublicDisclosureConnection';
  edges: Array<GQPublicDisclosureEdge>;
  pageInfo: GQPageInfo;
};

export type GQPublicDisclosureEdge = {
  __typename?: 'PublicDisclosureEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPublicDisclosure>;
};

export type GQPublicDisclosureInput = {
  excludeCdp?: InputMaybe<Scalars['Boolean']['input']>;
  mustHaveUrl?: InputMaybe<Scalars['Boolean']['input']>;
};

export const GQPublicDisclosureSource = {
  Licensed: 'Licensed',
  Scraped: 'Scraped',
} as const;

export type GQPublicDisclosureSource =
  (typeof GQPublicDisclosureSource)[keyof typeof GQPublicDisclosureSource];
export type GQPublishCtsTransformInput = {
  ctsTransformId: Scalars['String']['input'];
  userUploadId: Scalars['String']['input'];
  userUploadTaskId: Scalars['String']['input'];
};

export type GQPublishCtsTransformPayload = {
  __typename?: 'PublishCtsTransformPayload';
  jobId: Scalars['ID']['output'];
};

export type GQPublishCtsVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQPublishCtsVersionPayload = {
  __typename?: 'PublishCtsVersionPayload';
  ctsVersion: GQCustomerTargetSchemaVersion;
  customerTargetSchema: GQCustomerTargetSchema;
};

export type GQPublishCustomTransformForUserUploadInput = {
  ctsTransformId: Scalars['String']['input'];
  userUploadId: Scalars['String']['input'];
  userUploadTaskId: Scalars['String']['input'];
};

export type GQPublishCustomTransformForUserUploadPayload = {
  __typename?: 'PublishCustomTransformForUserUploadPayload';
  jobId: Scalars['ID']['output'];
};

export type GQPublishDataIssuesInput = {
  commentIds: Array<Scalars['ID']['input']>;
  emails: Array<GQEmailContentsInput>;
  issueIds: Array<Scalars['ID']['input']>;
};

export type GQPublishDataIssuesPayload = {
  __typename?: 'PublishDataIssuesPayload';
  comments: Array<GQComment>;
  issues: Array<GQDataIssue>;
};

export type GQPublishDataRegistryTablesInput = {
  batchMigratorRunId: Scalars['ID']['input'];
};

export type GQPublishDataRegistryTablesPayload = {
  __typename?: 'PublishDataRegistryTablesPayload';
  publishedCount: Scalars['Int']['output'];
};

export type GQPublishDraftFootprintInput = {
  id: Scalars['ID']['input'];
  userChangelogMd?: InputMaybe<Scalars['String']['input']>;
};

export type GQPublishDraftFootprintPayload = {
  __typename?: 'PublishDraftFootprintPayload';
  footprintAnalysis: GQFootprintAnalysis;
};

export type GQPublishEngagementTaskConfigForAdminInput = {
  id: Scalars['ID']['input'];
  isPublished: Scalars['Boolean']['input'];
};

export type GQPublishEngagementTaskConfigForAdminPayload = {
  __typename?: 'PublishEngagementTaskConfigForAdminPayload';
  engagementTaskConfig: GQEngagementTaskConfig;
};

export type GQPublishExtractorInput = {
  activityDataExtractorId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  /** @deprecated unused, remove may22 */
  publishExplanationJson?: InputMaybe<Scalars['String']['input']>;
  userVisible: Scalars['Boolean']['input'];
};

export type GQPublishExtractorPayload = {
  __typename?: 'PublishExtractorPayload';
  jobId: Scalars['ID']['output'];
};

export type GQPublishFinancialsReviewItemsInput = {
  emailContents: Array<GQEmailContentsInput>;
  orgId: Scalars['ID']['input'];
};

export type GQPublishFinancialsReviewItemsPayload = {
  __typename?: 'PublishFinancialsReviewItemsPayload';
  financialsReviewItems: GQFinancialsReviewItemConnection;
};

export type GQPublishFootprintCustomReportInput = {
  id: Scalars['ID']['input'];
};

export type GQPublishFootprintCustomReportPayload = {
  __typename?: 'PublishFootprintCustomReportPayload';
  footprintCustomReport: GQFootprintCustomReport;
};

export type GQPublishMeasurementVerificationItemsInput = {
  emailContents: Array<GQEmailContentsInput>;
  orgId: Scalars['String']['input'];
  verificationItemIds: Array<Scalars['ID']['input']>;
};

export type GQPublishMeasurementVerificationItemsPayload = {
  __typename?: 'PublishMeasurementVerificationItemsPayload';
  updatedQuestions: Maybe<Array<GQMeasurementVerificationItemQuestion>>;
};

export type GQPublishOrgStructureVersionInput = {
  effectiveFromDate?: InputMaybe<Scalars['DateTime']['input']>;
  effectiveToDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
};

export type GQPublishOrgStructureVersionPayload = {
  __typename?: 'PublishOrgStructureVersionPayload';
  orgStructureVersion: GQOrgStructureVersion;
};

export type GQPublishReferenceDataVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQPublishReferenceDataVersionPayload = {
  __typename?: 'PublishReferenceDataVersionPayload';
  referenceDataVersion: GQReferenceDataVersion;
};

export type GQPublishValueMappingArtifactInput = {
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQPublishValueMappingArtifactPayload = {
  __typename?: 'PublishValueMappingArtifactPayload';
  publishedArtifactRevisionId: Scalars['ID']['output'];
};

export type GQPurgeDraftsSinceLastPublishedActivityDataTableInput = {
  activityDataTableId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQPurgeDraftsSinceLastPublishedActivityDataTablePayload = {
  __typename?: 'PurgeDraftsSinceLastPublishedActivityDataTablePayload';
  updateCount: Scalars['Int']['output'];
};

export type GQPushBartBuildingInput = {
  activityDataExtractorId: Scalars['ID']['input'];
  mergeStrategy?: InputMaybe<GQBuildingsMergeStrategy>;
  userUploadId: Scalars['ID']['input'];
};

export type GQPushCtsVersionToOneSchemaInProductInput = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  ctsVersionId: Scalars['ID']['input'];
  datasetId: Scalars['ID']['input'];
  datasourceId?: InputMaybe<Scalars['ID']['input']>;
  measurementProjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQPushCtsVersionToOneSchemaInProductPayload = {
  __typename?: 'PushCtsVersionToOneSchemaInProductPayload';
  oneSchemaTemplateKey: Scalars['String']['output'];
};

export type GQPushCtsVersionToOneSchemaInput = {
  ctsVersionId: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQPushCtsVersionToOneSchemaPayload = {
  __typename?: 'PushCtsVersionToOneSchemaPayload';
  fileMetadata: GQFileMetadata;
  oneSchemaTemplateKey: Scalars['String']['output'];
  oneSchemaTemplateUrl: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type GQPushStagingReportConfigToProdInput = {
  isTesting: Scalars['Boolean']['input'];
  prodConfigId: Scalars['ID']['input'];
  stagingConfigId: Scalars['ID']['input'];
};

export type GQPushStagingReportConfigToProdPayload = {
  __typename?: 'PushStagingReportConfigToProdPayload';
  prodConfig: GQReportConfig;
};

export type GQPushUtilitiesDiffInput = {
  ctsVersionId: Scalars['String']['input'];
  embedId?: InputMaybe<Scalars['String']['input']>;
  fileMetadataId: Scalars['String']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  userUploadTaskId: Scalars['String']['input'];
};

export type GQQuantityTrendAnomaly = {
  __typename?: 'QuantityTrendAnomaly';
  highlightMonth: Scalars['YearMonth']['output'];
};

export type GQQuery = {
  __typename?: 'Query';
  activeMeasurementProjects: Array<GQMeasurementProject>;
  activeOrganization: GQActiveOrganization;
  activeWatershedEmployee: Maybe<GQWatershedEmployee>;
  activityContextMetadata: GQActivityContextMetadata;
  activityDataDataLineage: GQCalculationDataLineagePayload;
  activityDataExtractor: Maybe<GQActivityDataExtractor>;
  activityDataExtractors: GQActivityDataExtractorConnection;
  activityDataExtractorsById: Array<GQActivityDataExtractor>;
  activityDataTable: Maybe<GQActivityDataTable>;
  activityDataTables: GQActivityDataTableConnection;
  activityTypeSchemas: Array<GQActivityTypeSchema>;
  adminUsers: Array<GQUser>;
  allIntegrationPartners: Array<GQIntegrationPartner>;
  allReportConfigs: Array<GQReportConfig>;
  applicableDatasources: Array<GQDatasource>;
  asset:
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond;
  assetCorporate: GQAssetCorporate;
  assetCorporateAdmin: GQAssetCorporate;
  assetGroups: Array<GQAssetGroup>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetPersonalMotorVehicleInsurances: Array<GQAssetPersonalMotorVehicleInsurance>;
  assetSovereignBonds: Array<GQAssetSovereignBond>;
  assetYears: Array<GQFinanceAssetYearNew>;
  assetsCorporate: Array<GQAssetCorporate>;
  assetsCorporatePaginated: GQAssetsCorporateConnection;
  assetsRealEstate: Array<GQAssetRealEstate>;
  assetsWithTasks: Array<GQAssetCorporate>;
  assignReportQuestionContainerPreviewEmail: GQEmailContents;
  auditLogs: GQAuditLogConnection;
  auditReportMetadata: GQAuditReportMetadataPayload;
  availableCanonicalDatasetsForPlan: Array<GQCanonicalDataset>;
  availableProjectsForCompany: Array<GQMeasurementProject>;
  availableReleasesForSelectedDatasets: Array<GQBusinessActivityTypeWithReleases>;
  /** @deprecated Use averageCurrencyExchangeRates instead */
  averageCurrencyExchangeRate: GQCurrencyExchangeRate;
  averageCurrencyExchangeRates: Array<GQCurrencyExchangeRate>;
  backgroundJob: Maybe<GQBackgroundJob>;
  backgroundJobStatus: Maybe<GQBackgroundJobStatus>;
  backgroundJobStatuses: Array<GQBackgroundJobStatus>;
  batsByCalculationTagNames: Array<GQBatsByCalculationTagNamesPayload>;
  benchmarkCompanies: GQCompanyConnection;
  benchmarkNaicsCodes: Array<Scalars['String']['output']>;
  biAiQuery: GQBiAiQueryResponse;
  biCalculationDataLineage: GQCalculationDataLineagePayload;
  biCustomMetrics: Array<GQBiCustomMetric>;
  biDimensionListOptions: GQBiDimensionListOptionsResult;
  biFilterDisplayValueMappings: Array<GQBiDisplayValueMapping>;
  biPostgresToActivityData: GQBiPostgresToActivityDataPayload;
  biQueryData: GQBiQueryDataResult;
  biQueryDataMultiple: Array<GQBiQueryDataResult>;
  biQueryMetadata: GQBiQueryMetadataResult;
  biSavedViews: Maybe<Array<GQBiSavedView>>;
  buildingSupportingDocsExport: GQBuildingSupportingDocsExport;
  buildingTemplateDataUntyped: Maybe<Scalars['UntypedData']['output']>;
  buildingUntyped: GQBuildingUntyped;
  buildingUtilitiesExport: GQBuildingUtilitiesExport;
  buildingWithUtilities: GQBuildingWithUtilities;
  businessActivityTypeVersionById: GQBusinessActivityTypeVersion;
  businessActivityTypeVersionByName: GQBusinessActivityTypeVersion;
  businessActivityTypeVersionLive: GQBusinessActivityTypeVersion;
  businessActivityTypeVersionPrevious: GQBusinessActivityTypeVersion;
  businessActivityTypeVersions: Array<GQBusinessActivityTypeVersion>;
  businessActivityTypeVersionsByIds: Array<GQBusinessActivityTypeVersion>;
  businessActivityTypeWithFields: Maybe<GQBusinessActivityType>;
  businessActivityTypesForVersion: Array<GQBusinessActivityType>;
  businessActivityTypesPrimaryForVersionWithFields: Array<GQBusinessActivityType>;
  businessCategories: GQBusinessCategoryCollection;
  cadtRowsToMapForOrg: GQCadtRowsToMapForOrgOutput;
  calculationMethodVersionsForFootprint: Array<GQEmissionsModelRelease>;
  calculationTag: GQCalculationTag;
  calculationTags: Array<GQCalculationTag>;
  calculationTagsWithUsage: Array<GQCalculationTagWithUsage>;
  canonicalClimateProgramProject: GQCanonicalClimateProgramProject;
  canonicalClimateProgramProjects: Array<GQCanonicalClimateProgramProject>;
  canonicalDataset: GQCanonicalDataset;
  canonicalDatasets: Array<GQCanonicalDataset>;
  cdpApiManualGetResponse: GQCdpApiManualGetResponsePayload;
  cdpApiRegistrationForReport: GQCdpApiRegistrationPayload;
  cdpIdMappings: GQCdpIdMappingsPayload;
  cdpLatestSyncWorkflowStatus: GQCdpLatestSyncWorkflowStatusPayload;
  cdpMissingIdMappings: GQCdpMissingIdMappingsPayload;
  cdpOrganizations: Maybe<GQCdpOrganizationPayload>;
  cdpSyncQuestionStatus: Array<GQCdpSyncQuestionStatus>;
  cdpSyncQuestionStatusForReport: Array<GQCdpSyncQuestionStatus>;
  cdpSyncWorkflowStatus: GQCdpSyncWorkflowStatusPayload;
  cleanPowerQueryPlaygroundLink: Maybe<Scalars['String']['output']>;
  climateProgramCalendarEvents: Array<GQClimateProgramCalendarEvent>;
  climateProgramProject: GQClimateProgramProject;
  climateProgramProjects: Array<GQClimateProgramProject>;
  climateProgramTimelineProject: GQClimateProgramTimelineProject;
  climateProgramTimelineProjects: Array<GQClimateProgramTimelineProject>;
  commentDiscussionsForAnchor: GQDiscussionsPayload;
  committedChangesets: Array<GQChangeset>;
  companies: GQCompanyConnection;
  companiesForDashboard: Array<GQCompany>;
  company: GQCompany;
  companyClimateCommitmentsRaw: Array<GQCompanyClimateCommitmentRaw>;
  companyDisclosures: Array<GQDisclosure>;
  companyForDashboard: Maybe<GQCompany>;
  companyFuzzyMatch: Array<GQCompany>;
  companyIngestionDiffErrors: Array<GQCompanyIngestionDiffError>;
  companyIngestionRecordUpdates: GQCompanyIngestionRecordUpdateRequestConnection;
  companyPortalSettings: GQCompanyPortalSettings;
  companyReportingFacts: Array<GQCompanyReportingFacts>;
  companySourceKeys: Array<GQCompanySourceKey>;
  companyUrls: Array<GQCompanyUrl>;
  compositeDataVersion: GQReferenceDataVersion;
  computeReportQuestionSeriesMetric: GQReportQuestionSeriesMetric;
  computeReportQuestionSingleValueMetric: GQReportQuestionSingleValueMetric;
  contacts: Array<GQSupplierContact>;
  contactsForCompany: Array<GQSupplierContact>;
  csrdDma: Maybe<GQCsrdDoubleMaterialityAssessment>;
  csrdMateriality: Array<GQCsrdMaterialStandard>;
  ctsForm: GQCtsForm;
  ctsvOneSchemaFileFeedImportForUserUploadCandidate: Maybe<GQCtsvOneSchemaFileFeedImport>;
  currencyExchangeRateFromUsdAsOfMonth: GQCurrencyExchangeRate;
  currentFinanceSnapshot: Maybe<GQFinanceSnapshot>;
  customEmissionsFactorActivityUserUploadTask: Maybe<GQUserUploadTask>;
  customEmissionsFactorsForOrg: Maybe<
    Array<GQCustomEmissionsFactorsForDashboard>
  >;
  customEmissionsFactorsTask: Maybe<GQMeasurementParentTask>;
  customEmissionsSpecifiersForOrg: Maybe<Array<Scalars['String']['output']>>;
  customReportControls: Maybe<GQCustomReportControlsPayload>;
  customReportsWithBiSavedView: Array<GQReport>;
  customerTargetSchema: GQCustomerTargetSchema;
  customerTargetSchemaTransform: GQCustomerTargetSchemaTransform;
  customerTargetSchemaVersion: GQCustomerTargetSchemaVersion;
  customerTargetSchemas: Array<GQCustomerTargetSchema>;
  dataApprovalObjectChangelog: GQDataApprovalObjectChangelogPayload;
  dataApprovalUserUploadTaskChangelog: GQDataApprovalUserUploadTaskChangelogPayload;
  dataGovernanceDownloadAllUrl: Scalars['String']['output'];
  dataRegistryBatchMigratorRun: Maybe<GQDataRegistryBatchMigratorRun>;
  dataRegistryBatchMigratorRuns: Maybe<GQDataRegistryBatchMigratorRunConnection>;
  dataRegistryMigrator: Maybe<GQDataRegistryMigrator>;
  dataRegistryMigratorBestPath: Array<GQDataRegistryMigratorAndSchema>;
  dataRegistryMigratorRuns: Maybe<GQDataRegistryMigratorRunConnection>;
  dataRegistryMigrators: Maybe<GQDataRegistryMigratorConnection>;
  dataset: Maybe<GQDataset>;
  datasets: Array<GQDataset>;
  datasource: Maybe<GQDatasource>;
  datasourceValueMappingRule: GQDatasourceValueMappingRule;
  defaultCalculationTags: Array<GQCalculationTag>;
  defaultMappingsUrl: Scalars['String']['output'];
  demoPlatformRun: Maybe<GQDemoPlatformRun>;
  demoPlatformRuns: GQDemoPlatformRunConnection;
  dependentEmissionsModelReleasesForRdv: Maybe<Array<GQEmissionsModelRelease>>;
  dependentEmissionsModelsForRdv: Maybe<Array<GQEmissionsModelForRdv>>;
  dependentReferenceDataVersionsForRdv: Maybe<
    Array<GQReferenceDataVersionForRdv>
  >;
  disclosure: Maybe<GQDisclosure>;
  disclosureEmissionsFactors: Array<GQDisclosureEmissionsFactor>;
  disclosureInitiatives: Array<GQDisclosureInitiative>;
  disclosureTargetDetails: Array<GQDisclosureTargetDetails>;
  documentCandidates: GQDocumentCandidatesPayload;
  drilldownForReportQuestion: GQDrilldownForReportQuestionPayload;
  drilldownSavedViews: Array<GQDrilldownSavedView>;
  duckHuntLeaderboard: Array<GQDuckHuntLeaderboardEntry>;
  ducksForHunter: Array<Scalars['String']['output']>;
  dumpReportConfigToSrc: GQReportConfigSrcPayload;
  eacPriceEstimates: Array<GQEacPriceEstimate>;
  emailsSent: Array<GQEmailSent>;
  emissionsModelEditorEval: GQEmissionsModelEditorEvalPayload;
  emissionsModelEvaluation: GQEmissionsModelEvaluation;
  emissionsModelForkRelationships: GQEmissionsModelForkRelationshipsPayload;
  emissionsModelLibraryForStableModel: Array<GQEmissionsModelStable>;
  emissionsModelMatchersForStableId: Array<GQEmissionsModelMatcher>;
  emissionsModelRelease: GQEmissionsModelRelease;
  emissionsModelReleases: Array<GQEmissionsModelRelease>;
  emissionsModelReleasesForEmissionsModelStable: Array<GQEmissionsModelRelease>;
  emissionsModelReleasesForEmissionsModelsStable: Array<GQEmissionsModelReleaseEmissionsModelsStable>;
  emissionsModelStable: GQEmissionsModelStable;
  emissionsModelStableVersionHistory: GQEmissionsModelVersionHistoryConnection;
  emissionsModelVersion: GQEmissionsModelVersion;
  emissionsModelVersionImportedDescendantsFromDirectImportIds: Array<GQEmissionsModelVersion>;
  emissionsModelVersionsForFootprint: Array<GQEmissionsModelVersionWithReleaseIds>;
  emissionsModels: Array<GQEmissionsModelVersion>;
  emissionsModelsForOrg: Array<GQEmissionsModelStable>;
  emissionsModelsStable: Array<GQEmissionsModelStable>;
  emissionsModelsStableByIds: Array<GQEmissionsModelStable>;
  emissionsModelsStableForBusinessActivityType: Array<GQEmissionsModelStable>;
  emissionsModelsStablesForReleases: Array<GQEmissionsModelStable>;
  emissionsModelsSupportedBats: Array<Scalars['String']['output']>;
  emissionsYear: GQEmissionsYear;
  employeeReport: Maybe<GQEmployeeReport>;
  engagementCohorts: Array<GQEngagementCohort>;
  engagementTask: Maybe<GQEngagementTask>;
  engagementTaskBatch: GQEngagementTaskBatch;
  /** @deprecated Use engagementTaskBatch for the deploy statuses */
  engagementTaskBatchDeployStatus: GQEngagementTaskBatchDeployStatus;
  engagementTaskBatches: Array<GQEngagementTaskBatch>;
  engagementTaskByCrossOrgModelId: Maybe<GQEngagementTask>;
  engagementTaskConfig: GQEngagementTaskConfig;
  engagementTaskConfigs: Array<GQEngagementTaskConfig>;
  engagementTaskQuestions: Array<GQEngagementTaskQuestion>;
  engagementTaskQuestionsForBaseConfigId: Array<GQEngagementTaskQuestionSelectOption>;
  engagementTasks: GQEngagementTaskConnection;
  engagementTasksAdmin: GQEngagementTaskConnection;
  engagementTasksAssignedToOrg: Array<GQEngagementTask>;
  engagementTasksForCompanyAndOrgV1: Array<GQEngagementTask>;
  evaluatedEmissionsModels: GQEvaluatedEmissionsModelsResults;
  expenseCategoriesForSuppliers: Array<GQSupplierExpenseCategory>;
  externalReportAnswersForQuestion: GQExternalReportAnswersConnection;
  externalReportAnswersForReport: Array<GQExternalReportAnswer>;
  externalReportQuestions: Array<GQExternalReportQuestion>;
  externalReportTypesWithRevision: Array<GQExternalReportTypeWithRevision>;
  extractorRun: Maybe<GQExtractorRun>;
  facilities: GQFacilityConnection;
  facility: GQFacility;
  featureFlagDisabledForOrg: Scalars['Boolean']['output'];
  featureFlagEnabledForOrg: Scalars['Boolean']['output'];
  featureFlagEnabledGlobally: Scalars['Boolean']['output'];
  featureFlags: GQFeatureFlagConnection;
  files: GQFileMetadataConnection;
  financeAssetsYearOverYear: GQFinanceAssetsYearOverYear;
  financeAutomaticReminders: Array<GQFinanceAutomaticReminder>;
  financeChangelog: GQFinanceChangelogConnection;
  financeDataFlags: Array<GQFinanceDataFlag>;
  financeFundsYearOverYear: GQFinanceFundsYearOverYear;
  financeGridWorksheet: GQFinanceWorksheet;
  financeHoldingView: GQFinanceView;
  financeImport: GQFinanceImport;
  financeImports: Array<GQFinanceImport>;
  financeMeasurementProgressRows: Array<GQFinanceMeasurementProgressRow>;
  financeMeasurementProgressRowsAdmin: Array<GQFinanceMeasurementProgressRow>;
  financeMetadata: GQFinanceMetadata;
  financeModelCardMetadata: GQFinanceModelCardMetadataPayload;
  financeSettings: GQFinanceSettings;
  financeShowYourWork: Maybe<GQFinanceShowYourWorkData>;
  financeSnapshot: GQFinanceSnapshot;
  financeSnapshotAsset: GQFinanceSnapshotAsset;
  financeSnapshotAssets: GQFinanceSnapshotAssets;
  financeSnapshotAssetsGroupedByCompany: GQFinanceSnapshotAssetsGroupedByCompany;
  financeSnapshotFilterOptions: Scalars['UntypedData']['output'];
  financeSnapshotFunds: GQFinanceSnapshotFunds;
  financeSnapshotHoldings: GQFinanceSnapshotHoldings;
  financeSnapshotTags: Array<GQFinanceSnapshotTag>;
  financeSnapshotWaterfallChart: GQWaterfallChartPayload;
  financeSnapshots: Array<GQFinanceSnapshot>;
  financeTags: Array<GQFinanceTag>;
  financeView: GQFinanceView;
  financeWorksheetColumns: Array<GQFinanceWorksheetColumn>;
  financialsReviewItem: Maybe<GQFinancialsReviewItem>;
  financialsReviewItems: GQFinancialsReviewItemConnection;
  financialsReviewItemsAdmin: GQFinancialsReviewItemConnection;
  findReferenceDataSource: GQReferenceDataSource;
  flags: Array<GQFeatureFlag>;
  footprintAnalysis: Maybe<GQFootprintAnalysis>;
  footprintAnalysisAdmin: Maybe<GQFootprintAnalysisAdmin>;
  footprintAssemblyRun: GQFootprintAssemblyRun;
  footprintAuditBundle: GQFootprintAuditBundle;
  footprintAuditVerificationTemplateUrl: Scalars['String']['output'];
  footprintChangelog: GQFootprintChangelog;
  footprintCustomReports: Array<GQFootprintCustomReport>;
  footprintDebug: GQFootprintDebugPayload;
  /** @deprecated unused, remove 2024-03-18 */
  footprintGenerationInfo: GQFootprintGenerationInfo;
  footprintKinds: Array<Scalars['String']['output']>;
  footprintKindsWithProductsOrMaterials: Maybe<
    Array<GQFootprintKindWithProductsOrMaterials>
  >;
  footprintSnapshot: GQFootprintSnapshot;
  footprintSnapshotExclusionRule: GQFootprintSnapshotExclusionRule;
  footprintSnapshotInDashboard: GQFootprintSnapshot;
  footprintSnapshots: GQFootprintSnapshotConnection;
  footprintSnapshotsForYear: Array<GQFootprintSnapshot>;
  footprintTag: Maybe<GQFootprintTag>;
  footprintTags: Array<GQFootprintTag>;
  footprintTagsForOrg: Array<GQFootprintTag>;
  footprintTemp: GQFootprintTemp;
  footprintTestSuiteCategoryRules: Maybe<Array<GQFootprintCategoryRule>>;
  footprintTestSuiteConfigDefaultOrgId: Maybe<Scalars['ID']['output']>;
  footprintTestSuiteExclusionRules: Maybe<Array<GQFootprintExclusionRule>>;
  footprintTestSuiteExecutionStepResult: GQFootprintTestExecutionStepResult;
  footprintTestSuiteFootprintTags: Maybe<Array<GQFootprintTag>>;
  footprintTestSuiteMarketplaceAllocInstrs: Maybe<
    Array<GQMarketplaceAllocationInstruction>
  >;
  footprintVersion: Maybe<GQFootprintVersion>;
  footprintVersions: GQFootprintVersionConnection;
  footprints: GQFootprintConnection;
  footprintsForOrg: GQFootprintConnection;
  forceRedirect: Maybe<Scalars['String']['output']>;
  forecast: GQForecast;
  forecastAdmin: Maybe<GQForecast>;
  formalReport: Maybe<GQReport>;
  formalReports: GQReportConnection;
  formalReportsAdmin: GQReportConnection;
  fund: Maybe<GQFund>;
  funds: Array<GQFund>;
  getOneSchemaEmbedStatus: Maybe<Scalars['String']['output']>;
  getOverlayForConfigType: GQSimulationSwapOverlay;
  getReferenceDataFromRawSlugs: Array<GQReferenceDataSource>;
  getReportObjectConfigPath: Maybe<GQGetReportObjectConfigPathPayload>;
  getRowsForConfigType: GQSimulationRowData;
  getValueMappingOutputOptions: Array<GQValueMappingOutputOption>;
  globalEngagementTaskConfigs: Array<GQEngagementTaskConfig>;
  globalFootprintTags: Array<GQGlobalFootprintTag>;
  globalMappingsUrl: Scalars['String']['output'];
  groupedAuditLogs: GQGroupedAuditLogConnection;
  i18nSandbox: GQI18nSandbox;
  inboxItemsForOrg: GQOrganizationInboxItemConnection;
  ingestionCustomField: Maybe<GQIngestionCustomField>;
  inputDataByExtBartIds: GQInputDataQueryResult;
  integrationConnection: GQIntegrationConnection;
  integrationConnections: GQIntegrationConnectionConnection;
  integrationDataPulls: Array<Maybe<GQIntegrationDataPull>>;
  integrationPartners: GQIntegrationPartnerConnection;
  intensityQuantitiesByMonth: GQIntensityQuantitiesPerMonthPayload;
  latestCurrencyExchangeRate: Maybe<GQCurrencyExchangeRate>;
  latestDocumentParseAttempt: GQDocumentParseAttempt;
  latestEmissionsModelVersionForStableId: Maybe<GQEmissionsModelVersion>;
  latestFootprintAssemblyRun: Maybe<GQFootprintAssemblyRun>;
  latestOrgStructureVersion: Maybe<GQOrgStructureVersion>;
  latestOrgUnitTypesForCanonicalDatasetKind: Array<GQOrgUnitType>;
  latestPublishedRevisionForRevisionIdOrNull: Maybe<GQReferenceDataRevision>;
  latestReferenceDataRevisionForVersion: Maybe<GQReferenceDataRevision>;
  latestReferenceDataRevisionsForOrg: Array<Maybe<GQReferenceDataRevision>>;
  latestReportConfigs: Array<GQReportConfig>;
  latestUserVisibleDraftFootprintVersion: Maybe<GQFootprintVersion>;
  latestUserVisiblePublishedFootprintVersion: Maybe<GQFootprintVersion>;
  legacyAssumptionAsMeasurementMappings: GQLegacyAssumptionAsMeasurementMappingConnection;
  legacyFootprint: Maybe<GQLegacyFootprint>;
  legacyMappingsCodesUrl: Scalars['String']['output'];
  logEvents: Array<GQAuditLogEntry>;
  mappedExternalReportQuestions: GQMappedExternalReportQuestions;
  mappingCategoryIds: GQMappingCategoryIds;
  marketplaceAllocationInstructions: GQMarketplaceAllocationInstructionConnection;
  marketplaceDeveloper: GQMarketplaceDeveloper;
  marketplaceDevelopers: GQMarketplaceDeveloperConnection;
  marketplaceDocument: GQMarketplaceDocument;
  marketplaceDocuments: GQMarketplaceDocumentConnection;
  marketplaceInternationalEacProjects: Array<GQMarketplaceProject>;
  marketplacePointsOfContact: GQMarketplacePointOfContactConnection;
  marketplacePortfolio: Maybe<GQMarketplacePortfolio>;
  marketplacePortfolioAllocations: GQMarketplacePortfolioAllocationConnection;
  marketplacePortfolios: GQMarketplacePortfolioConnection;
  marketplaceProject: GQMarketplaceProject;
  marketplaceProjectArchetype: GQMarketplaceProjectArchetype;
  marketplaceProjectArchetypes: GQMarketplaceProjectArchetypeConnection;
  marketplaceProjectOffering: GQMarketplaceProjectOffering;
  marketplaceProjectOfferingUpdates: GQMarketplaceProjectOfferingConnection;
  marketplaceProjectOfferings: GQMarketplaceProjectOfferingConnection;
  marketplaceProjects: GQMarketplaceProjectConnection;
  marketplacePurchase: GQMarketplacePurchase;
  marketplacePurchaseLineItem: GQMarketplacePurchaseLineItem;
  marketplacePurchaseLineItems: GQMarketplacePurchaseLineItemConnection;
  marketplacePurchases: GQMarketplacePurchaseConnection;
  marketplaceSupplier: GQMarketplaceSupplier;
  marketplaceSuppliers: GQMarketplaceSupplierConnection;
  marketplaceVoluntaryCarbonMarketDisclosures: Array<GQMarketplaceVoluntaryCarbonMarketDisclosure>;
  materialsUpdatedTaxonomy: Maybe<Array<GQMaterialFootprintRowUpdatedTaxonomy>>;
  maybeAsset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  measurementContextItems: GQMeasurementContextItemConnection;
  measurementMappingOutputOptions: GQMeasurementMappingOutputOptionConnection;
  measurementMappings: GQMeasurementMappingConnection;
  measurementProject: GQMeasurementProject;
  measurementProjectStatusShares: Array<GQMeasurementProjectStatusShare>;
  measurementProjectsWithFacilities: Array<GQMeasurementProject>;
  measurementTestSuite: GQMeasurementTestSuite;
  measurementTestSuiteChangelog: GQMeasurementTestSuiteChangelogConnection;
  measurementTestSuites: Array<GQMeasurementTestSuite>;
  measurementVerificationItem: GQMeasurementVerificationItem;
  measurementVerificationItems: GQMeasurementVerificationItemConnection;
  measurementVerificationItemsForDataset: GQMeasurementVerificationItemConnection;
  methodologyCustomizationDisplaySections: Array<GQMethodologyCustomizationDisplaySection>;
  methodologyCustomizationMappingConfig: GQMethodologyCustomizationMappingConfig;
  methodologyCustomizationOneSchemaTemplateForType: Scalars['UntypedData']['output'];
  methodologyCustomizationPreviewFootprint: Maybe<GQMethodologyCustomizationFootprintPreviewPayload>;
  methodologyCustomizationRowsForOrg: GQMethodologyCustomizationBundle;
  methodologyCustomizationTypeConfig: GQMethodologyCustomizationTypeConfig;
  methodologyExplainer: Maybe<GQMethodologyExplainer>;
  methodologyTestSuiteExecution: GQMethodologyTestSuiteExecution;
  methodologyTestSuiteValidationResults: Maybe<GQMethodologyTestSuiteValidationResults>;
  naicsEFs: Array<GQNaicsEf>;
  nextFootprintVersionIdentifier: Scalars['String']['output'];
  object: GQGenericObjectPayload;
  orgFootprintDisclosureShareLogs: GQFootprintDisclosureShareLogConnection;
  orgFootprintDisclosures: GQFootprintDisclosureConnection;
  orgFunds: GQFundConnection;
  orgStructureVersions: Array<GQOrgStructureVersion>;
  orgUnitTypeRelationships: Array<GQOrgUnitTypeRelationship>;
  orgUnitTypes: Array<GQOrgUnitType>;
  orgUnits: Array<GQOrgUnit>;
  organization: GQOrganization;
  organizations: GQOrgConnection;
  peerCompanyGroups: Array<GQPeerCompanyGroup>;
  peerExternalReportAnswersForIdentifiers: GQPeerExternalReportAnswersForIdentifiersPayload;
  pendingCompanyChangeRequests: Array<GQCompanyChangeRequest>;
  pendingUserPermissionRequests: Array<GQUserPermissionRequest>;
  pendingUserPermissionRequestsForUser: Array<GQUserPermissionRequest>;
  permissionItem: Maybe<GQPermissionItem>;
  permissions: GQPermissionConnection;
  personalMotorVehicleInsuranceAssetHoldings: Array<GQFinanceAssetHolding>;
  personalMotorVehicleInsuranceAssetYears: Array<GQFinanceAssetYearNew>;
  pipelineResult: Maybe<GQPipelineResult>;
  pipelineResults: GQPipelineResultConnection;
  pipelineRuns: GQPipelineRunConnection;
  plan: GQPlan;
  plans: GQPlanConnection;
  plansForOrg: Array<GQPlan>;
  plansWithDefaults: GQPlanConnection;
  playgroundSql: Maybe<GQPlaygroundSql>;
  playgroundSqlRun: Maybe<GQPlaygroundSqlRun>;
  playgroundSqlRuns: GQPlaygroundSqlRunConnection;
  portcoCompanies: Array<GQCompany>;
  portcoEngagementTasks: Array<GQEngagementTask>;
  portcoOrgs: Array<GQOrganization>;
  portcoPublicDisclosures: Array<GQPublicDisclosure>;
  portcos: Array<GQAssetCorporate>;
  portfolioBuilderArchetypes: GQMarketplaceProjectArchetypeConnection;
  postMeasurementVendorMatchingTableRows: GQVendorMatchingTableRowsPayload;
  preMeasurementSupplierMappingTableRows: GQSupplierMappingTableRowsPayload;
  preMeasurementSupplierMappingV1Analytics: GQSupplierMappingV1Analytics;
  preMeasurementVendorMatchingTableRows: GQVendorMatchingTableRowsPayload;
  preferredLocale: Maybe<Scalars['String']['output']>;
  preflightEraseReportComponent: Maybe<GQPreflightEraseReportComponentPayload>;
  prepublicationMeasurementTestResults: GQPrepublicationTestResults;
  previewCadtBuildingsPush: Maybe<GQCadtBuildingsPushPreview>;
  privateDisclosureIDs: Maybe<Array<Scalars['ID']['output']>>;
  privateDisclosures: Maybe<Array<GQPrivateDisclosure>>;
  productWaitlistTypes: Array<GQProductWaitlistType>;
  productsUpdatedTaxonomy: Maybe<Array<GQProductFootprintRowUpdatedTaxonomy>>;
  publicDisclosure: GQPublicDisclosure;
  publicDisclosureIDs: Maybe<Array<Scalars['ID']['output']>>;
  publicDisclosureSearch: GQPublicDisclosureConnection;
  publicDisclosures: Maybe<Array<GQPublicDisclosure>>;
  publicDisclosuresAdmin: GQPublicDisclosureConnection;
  publicDisclosuresPaginated: GQPublicDisclosureConnection;
  rawCustomerTargetSchemaVersion: GQCustomerTargetSchemaVersion;
  recommendedPublicDisclosures: GQPublicDisclosureConnection;
  recommendedReportKinds: Maybe<GQRecommendedReportKinds>;
  referenceDataAncestryForVersionIds: Array<GQReferenceDataAncestorRelationship>;
  referenceDataCitation: GQReferenceDataCitation;
  referenceDataCitationSlugs: Array<Scalars['String']['output']>;
  referenceDataCitations: GQReferenceDataCitationConnection;
  referenceDataDiffer: GQReferenceDataDifferResult;
  referenceDataOverlay: GQReferenceDataOverlay;
  referenceDataOverlayByRevisionId: Maybe<GQReferenceDataOverlay>;
  referenceDataRevision: GQReferenceDataRevision;
  referenceDataRevisionsByVersionId: GQReferenceDataRevisionConnection;
  referenceDataSource: GQReferenceDataSource;
  referenceDataSourceByName: GQReferenceDataSource;
  referenceDataSources: Maybe<GQReferenceDataSourceConnection>;
  referenceDataSqlForRevisionId: Maybe<GQReferenceDataSql>;
  referenceDataVersion: GQReferenceDataVersion;
  referenceDataVersionBySlug: GQReferenceDataVersion;
  referenceDataVersionsBySlugs: Array<Maybe<GQReferenceDataVersionSlugOutput>>;
  referenceDataVersionsBySlugsIfExists: Array<
    Maybe<GQReferenceDataVersionSlugOutput>
  >;
  refreshableAssets: Array<GQAssetCorporate>;
  report: Maybe<GQEmployeeReport>;
  reportAnswerVerifierFailures: GQReportAnswerVerifierFailureConnection;
  reportAnswerVerifierOptions: GQReportAnswerVerifierOptions;
  reportAnswers: GQReportAnswerConnection;
  reportAttachmentItems: Array<GQReportAttachmentItem>;
  reportAttachmentItemsForQuestion: Array<GQReportAttachmentItem>;
  reportConfig: GQReportConfig;
  reportConfigQuestions: Array<GQReportQuestion>;
  reportConfigs: GQReportConfigConnection;
  reportProgress: Maybe<GQReportProgressPayload>;
  reportQuestion: GQReportQuestion;
  reportQuestionContainer: GQReportQuestionContainer;
  reportQuestionIdentifierMapping: GQReportQuestionIdentifierMapping;
  reportQuestionIdentifiers: GQReportQuestionIdentifierConnection;
  reportQuestions: Array<GQReportQuestion>;
  reportWaitlistTypes: Array<Scalars['String']['output']>;
  reportWithConfig: GQReportWithConfig;
  reportingFootprintHealthChecks: Maybe<GQReportingFootprintHealthChecksPayload>;
  reports: GQReportConnection;
  role: Maybe<GQRole>;
  roles: GQRoleConnection;
  sampledFinancialAccountIds: Array<Scalars['String']['output']>;
  sandboxEmissionsYears: GQEmissionsYearConnection;
  schemaRegistrySchema: Maybe<GQSchemaRegistrySchema>;
  schemaRegistrySchemaVersion: Maybe<GQSchemaRegistrySchemaVersion>;
  schemaRegistrySchemaVersions: Maybe<GQSchemaRegistrySchemaVersionConnection>;
  schemaRegistrySchemas: Maybe<GQSchemaRegistrySchemaConnection>;
  searchReferenceDataSourceName: Array<GQReferenceDataSource>;
  selectedDatasetsByBatWithMetadata: GQDatasetsByBatWithMetadata;
  sharedMeasurementProjects: Array<GQSharedMeasurementProject>;
  shouldShowDuck: Scalars['Boolean']['output'];
  signOneSchemaJwt: GQOneSchemaJwtPayload;
  signedParquetUrl: Scalars['String']['output'];
  simulationEligibleFootprintIntervals: GQSimulationEligibleFootprintIntervals;
  simulationPortfolio: Maybe<GQSimulationPortfolio>;
  simulationPortfolios: Array<GQSimulationPortfolio>;
  simulationScenario: Maybe<GQSimulationScenario>;
  simulationScenarioRun: Maybe<GQSimulationScenarioRun>;
  simulationSwapOptions: Array<GQSimulationSwapOption>;
  singleRowMethodologyExplainer: GQSingleRowMethodologyExplainer;
  singleRowMethodologyExplainerForEmissionsYear: GQSingleRowMethodologyExplainer;
  singleRowMethodologyExplainerWithLatestFps: GQSingleRowMethodologyExplainer;
  sovereignBondAssetYears: Array<GQFinanceAssetYearNew>;
  supplier: Maybe<GQSupplier>;
  supplierActivityTags: GQSupplierActivityTags;
  supplierAttachmentDownloadUrl: Scalars['String']['output'];
  supplierAttachments: Array<GQSupplierAttachment>;
  supplierAttachmentsByCrossOrgIds: Array<GQSupplierAttachment>;
  supplierCustomData: Array<GQSupplierCustomData>;
  supplierDetailedEmissions: Maybe<GQSupplierDetailedEmissions>;
  supplierEmissionsFactorsTableMetadata: GQSupplierEmissionsFactorsTableMetadata;
  supplierEmissionsFactorsTableRows: GQSupplierEmissionsFactorsTableRows;
  supplierFilterOptions: Array<GQSupplierFilterOption>;
  supplierScorecards: Array<GQSupplierScorecard>;
  supplierTableColumns: Array<GQSupplierTableColumn>;
  supplierTableColumnsDashboard: Array<GQSupplierTableColumn>;
  supplierViews: Array<GQSupplierView>;
  supplierViewsAdmin: Array<GQSupplierView>;
  suppliersAdmin: Maybe<GQOrgAdminSuppliers>;
  suppliersSettings: GQSuppliersSettings;
  suppliersSettingsAdmin: GQSuppliersSettings;
  suppliersV2: GQSuppliersData;
  suppliersWithTargetDisclosures: GQSuppliersWithInterval;
  targetingOptions: Array<GQSimulationTargetingOptions>;
  tcfdArchetypeOpportunities: Array<GQTcfdArchetypeOpportunity>;
  tcfdArchetypeRisks: Array<GQTcfdArchetypeRisk>;
  throwErrorQuery: Maybe<GQThrowErrorPayload>;
  uniqueExternalReportFilterValues: GQUniqueExternalReportFilterValues;
  user: Maybe<GQUser>;
  userHasManageFinancialsPermission: Scalars['Boolean']['output'];
  userHasManageValueMappingPermission: Scalars['Boolean']['output'];
  userHasPermission: Scalars['Boolean']['output'];
  userHasViewDatasourcePermission: Scalars['Boolean']['output'];
  userUpload: GQUserUpload;
  userUploadCandidates: GQUserUploadCandidatePayload;
  userUploadTask: Maybe<GQUserUploadTask>;
  userUploadTaskForDatasource: GQUserUploadTask;
  userUploadedTable: Maybe<GQUserUploadedTable>;
  userUploadedTableById: GQUserUploadedTable;
  userUploadedTables: GQUserUploadedTableConnection;
  userUploadedTablesById: Array<GQUserUploadedTable>;
  userUploads: GQUserUploadConnection;
  userUploadsByBuildingId: Array<GQUserUpload>;
  userUploadsById: Array<GQUserUpload>;
  users: Array<GQUser>;
  usersWithSupplierPermission: Array<GQUser>;
  utilitiesMetadata: GQUtilitiesMetadata;
  validateUpdateReferenceDataVersionSchema: GQReferenceDataSchemaUpdateValidation;
  valueMapping: GQValueMapping;
  valueMappingAggregatedQuantities: Maybe<GQValueMappingAggregatedQuantities>;
  valueMappingCommentsForSubject: Array<GQValueMappingComment>;
  valueMappingConfig: Scalars['UntypedData']['output'];
  valueMappingFileRowData: Array<GQValueMappingFileData>;
  valueMappingHistoryForGroup: GQValueMappingHistoryForGroupPayload;
  valueMappingRows: Maybe<GQValueMappingRowConnection>;
  valueMappingTableSourceData: GQValueMappingTableSourceData;
  valueMappingTask: GQValueMappingTask;
  valueMappingWithLatestStatus: GQValueMapping;
  variousSuppliers: GQVariousSuppliersPayload;
  vendorMatchingTask: Maybe<GQVendorMatchingTask>;
  versionedBusinessActivityTypes: Array<GQBusinessActivityType>;
  visibleMarketplaceProjectArchetypes: GQMarketplaceProjectArchetypeConnection;
  watershedEmployees: GQWatershedEmployeeConnection;
  yearMeasurementProjects: Array<GQMeasurementProject>;
};

export type GQQueryActiveMeasurementProjectsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryActivityContextMetadataArgs = {
  input: GQBiActivityQueryContextInput;
};

export type GQQueryActivityDataDataLineageArgs = {
  queryContext: GQBiQueryContextInput;
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQQueryActivityDataExtractorArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryActivityDataExtractorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  ids: Array<Scalars['ID']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryActivityDataExtractorsByIdArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryActivityDataTableArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryActivityDataTablesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryAllReportConfigsArgs = {
  filterHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryApplicableDatasourcesArgs = {
  integrationPartnerId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQQueryAssetArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryAssetCorporateArgs = {
  assetCorporateId: Scalars['ID']['input'];
};

export type GQQueryAssetCorporateAdminArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryAssetsCorporatePaginatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryAssetsWithTasksArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryAssignReportQuestionContainerPreviewEmailArgs = {
  input: GQAssignReportQuestionContainerPreviewEmailInput;
};

export type GQQueryAuditLogsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  eventKind: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  interval: Scalars['YMInterval']['input'];
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryAuditReportMetadataArgs = {
  input: GQAuditReportMetadataInput;
};

export type GQQueryAvailableProjectsForCompanyArgs = {
  companyId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQQueryAvailableReleasesForSelectedDatasetsArgs = {
  datasetIdsPerMeasurementProjectId: Array<GQDatasetIdsPerMeasurementProjectId>;
};

export type GQQueryAverageCurrencyExchangeRateArgs = {
  baseCurrency: Scalars['String']['input'];
  interval: Scalars['YMInterval']['input'];
  targetCurrency: Scalars['String']['input'];
};

export type GQQueryAverageCurrencyExchangeRatesArgs = {
  baseCurrency: Scalars['String']['input'];
  intervals: Array<Scalars['YMInterval']['input']>;
  targetCurrency: Scalars['String']['input'];
};

export type GQQueryBackgroundJobArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryBackgroundJobStatusArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryBackgroundJobStatusesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryBatsByCalculationTagNamesArgs = {
  calculationTagNames: Array<Scalars['String']['input']>;
};

export type GQQueryBenchmarkCompaniesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  naicsCode: InputMaybe<Scalars['String']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryBenchmarkNaicsCodesArgs = {
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryBiAiQueryArgs = {
  input: GQBiAiQueryInput;
};

export type GQQueryBiCalculationDataLineageArgs = {
  conversionFactorId: InputMaybe<Scalars['String']['input']>;
  emissionsModelEvaluationId: InputMaybe<Scalars['String']['input']>;
  fingerprint: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: Scalars['String']['input'];
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQQueryBiDimensionListOptionsArgs = {
  input: GQBiDimensionListOptionsInput;
};

export type GQQueryBiFilterDisplayValueMappingsArgs = {
  input: GQBiFilterDisplayValueMappingsInput;
};

export type GQQueryBiPostgresToActivityDataArgs = {
  input: GQBiActivityQueryContextInput;
};

export type GQQueryBiQueryDataArgs = {
  input: GQBiQueryDataInput;
};

export type GQQueryBiQueryDataMultipleArgs = {
  input: GQBiQueryDataMultipleInput;
};

export type GQQueryBiQueryMetadataArgs = {
  input: GQBiQueryMetadataInput;
};

export type GQQueryBiSavedViewsArgs = {
  input: InputMaybe<GQBiSavedViewsInput>;
};

export type GQQueryBuildingTemplateDataUntypedArgs = {
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryBuildingUntypedArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryBuildingWithUtilitiesArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryBusinessActivityTypeVersionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryBusinessActivityTypeVersionByNameArgs = {
  versionName: Scalars['String']['input'];
};

export type GQQueryBusinessActivityTypeVersionsByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryBusinessActivityTypeWithFieldsArgs = {
  typeName: Scalars['String']['input'];
  versionName: Scalars['String']['input'];
};

export type GQQueryBusinessActivityTypesForVersionArgs = {
  versionName: Scalars['String']['input'];
};

export type GQQueryBusinessActivityTypesPrimaryForVersionWithFieldsArgs = {
  versionName: Scalars['String']['input'];
};

export type GQQueryCadtRowsToMapForOrgArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  endYearMonth: InputMaybe<Scalars['YearMonth']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
  startYearMonth: InputMaybe<Scalars['YearMonth']['input']>;
};

export type GQQueryCalculationMethodVersionsForFootprintArgs = {
  footprintSnapshotId: Scalars['String']['input'];
};

export type GQQueryCalculationTagArgs = {
  tagId: Scalars['ID']['input'];
};

export type GQQueryCanonicalClimateProgramProjectArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCanonicalClimateProgramProjectsArgs = {
  isForMeasurement: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryCanonicalDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCdpApiManualGetResponseArgs = {
  input: GQCdpApiManualGetResponseInput;
};

export type GQQueryCdpApiRegistrationForReportArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQQueryCdpMissingIdMappingsArgs = {
  orgId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type GQQueryCdpSyncQuestionStatusArgs = {
  reportId: Scalars['ID']['input'];
  reportQuestionIds: Array<Scalars['ID']['input']>;
};

export type GQQueryCdpSyncQuestionStatusForReportArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQQueryCdpSyncWorkflowStatusArgs = {
  workflowId: Scalars['String']['input'];
};

export type GQQueryCleanPowerQueryPlaygroundLinkArgs = {
  orgId: Scalars['ID']['input'];
  pipelineRunConfig: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryClimateProgramProjectArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryClimateProgramProjectsArgs = {
  includeMeasureWhatMatters: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryClimateProgramTimelineProjectArgs = {
  id: Scalars['ID']['input'];
  objectType: GQClimateProgramTimelineProjectObjectType;
};

export type GQQueryCommentDiscussionsForAnchorArgs = {
  input: GQDiscussionAnchor;
};

export type GQQueryCommittedChangesetsArgs = {
  committedAfter: Scalars['DateTime']['input'];
  firstChangesetRows: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryCompaniesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeDemo: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryCompaniesForDashboardArgs = {
  disableBetterCompanyUpgrade: InputMaybe<Scalars['Boolean']['input']>;
  ids: Array<Scalars['ID']['input']>;
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryCompanyArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCompanyDisclosuresArgs = {
  companyId: Scalars['ID']['input'];
  opts: InputMaybe<GQDisclosureInput>;
};

export type GQQueryCompanyForDashboardArgs = {
  disableBetterCompanyUpgrade: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryCompanyFuzzyMatchArgs = {
  allowMatchById: InputMaybe<Scalars['Boolean']['input']>;
  q: Scalars['String']['input'];
  searchDemo: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryCompanyIngestionRecordUpdatesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<GQCompanyIngestionRecordUpdateFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sortDirection: InputMaybe<GQDataGridSortDirection>;
};

export type GQQueryCompanyPortalSettingsArgs = {
  rootCompanyId: Scalars['ID']['input'];
};

export type GQQueryCompanyReportingFactsArgs = {
  companyId: Scalars['ID']['input'];
};

export type GQQueryCompanyUrlsArgs = {
  companyId: Scalars['ID']['input'];
};

export type GQQueryCompositeDataVersionArgs = {
  compositeDataVersionId: Scalars['ID']['input'];
};

export type GQQueryComputeReportQuestionSeriesMetricArgs = {
  input: GQComputeReportQuestionMetricInput;
};

export type GQQueryComputeReportQuestionSingleValueMetricArgs = {
  input: GQComputeReportQuestionMetricInput;
};

export type GQQueryContactsForCompanyArgs = {
  companyId: Scalars['ID']['input'];
};

export type GQQueryCsrdDmaArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCsrdMaterialityArgs = {
  version: InputMaybe<GQCsrdVersion>;
};

export type GQQueryCtsFormArgs = {
  ctsFormId: Scalars['ID']['input'];
};

export type GQQueryCtsvOneSchemaFileFeedImportForUserUploadCandidateArgs = {
  userUploadCandidateId: Scalars['ID']['input'];
};

export type GQQueryCurrencyExchangeRateFromUsdAsOfMonthArgs = {
  month: Scalars['YearMonth']['input'];
  targetCurrency: Scalars['String']['input'];
};

export type GQQueryCustomEmissionsFactorActivityUserUploadTaskArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryCustomEmissionsFactorsForOrgArgs = {
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryCustomEmissionsFactorsTaskArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryCustomEmissionsSpecifiersForOrgArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryCustomReportControlsArgs = {
  input: GQCustomReportControlsInput;
};

export type GQQueryCustomReportsWithBiSavedViewArgs = {
  input: GQCustomReportsWithBiSavedViewInput;
};

export type GQQueryCustomerTargetSchemaArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCustomerTargetSchemaTransformArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCustomerTargetSchemaVersionArgs = {
  ctsVersionId: Scalars['ID']['input'];
  customerTargetSchemaId: Scalars['ID']['input'];
};

export type GQQueryDataApprovalObjectChangelogArgs = {
  input: GQDataApprovalObjectChangelogInput;
};

export type GQQueryDataApprovalUserUploadTaskChangelogArgs = {
  input: GQDataApprovalUserUploadTaskChangelogInput;
};

export type GQQueryDataGovernanceDownloadAllUrlArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryDataRegistryBatchMigratorRunArgs = {
  batchMigratorRunId: Scalars['ID']['input'];
};

export type GQQueryDataRegistryBatchMigratorRunsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  searchDescription: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryDataRegistryMigratorArgs = {
  migratorId: Scalars['ID']['input'];
};

export type GQQueryDataRegistryMigratorBestPathArgs = {
  fromSchemaVersionId: Scalars['ID']['input'];
  toSchemaVersionId: Scalars['ID']['input'];
};

export type GQQueryDataRegistryMigratorRunsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  batchMigratorRunId: InputMaybe<Scalars['ID']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<GQDataRegistryMigratorRunFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  searchText: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryDataRegistryMigratorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  schemaId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryDatasetsArgs = {
  sortBy: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryDatasourceArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryDatasourceValueMappingRuleArgs = {
  datasourceId: InputMaybe<Scalars['ID']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  valueMappingRuleId: Scalars['String']['input'];
};

export type GQQueryDemoPlatformRunArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryDemoPlatformRunsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryDependentEmissionsModelReleasesForRdvArgs = {
  referenceDataVersionId: Scalars['ID']['input'];
};

export type GQQueryDependentEmissionsModelsForRdvArgs = {
  referenceDataVersionId: Scalars['ID']['input'];
};

export type GQQueryDependentReferenceDataVersionsForRdvArgs = {
  referenceDataVersionId: Scalars['ID']['input'];
};

export type GQQueryDisclosureArgs = {
  opts: InputMaybe<GQDisclosureInput>;
  privateDisclosureId: InputMaybe<Scalars['ID']['input']>;
  publicDisclosureId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryDisclosureEmissionsFactorsArgs = {
  privateDisclosureIds: Array<Scalars['ID']['input']>;
  publicDisclosureIds: Array<Scalars['ID']['input']>;
};

export type GQQueryDocumentCandidatesArgs = {
  userUploadCandidateIds: Array<Scalars['ID']['input']>;
};

export type GQQueryDrilldownForReportQuestionArgs = {
  input: GQDrilldownForReportQuestionInput;
};

export type GQQueryDumpReportConfigToSrcArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmailsSentArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryEmissionsModelEditorEvalArgs = {
  input: GQEmissionsModelEditorEvalInput;
};

export type GQQueryEmissionsModelEvaluationArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelForkRelationshipsArgs = {
  stableId: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelLibraryForStableModelArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelMatchersForStableIdArgs = {
  id: Scalars['ID']['input'];
  includeDrafts: Scalars['Boolean']['input'];
};

export type GQQueryEmissionsModelReleaseArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelReleasesArgs = {
  businessActivityTypeNames: InputMaybe<Array<Scalars['String']['input']>>;
  includeAllOrgs: InputMaybe<Scalars['Boolean']['input']>;
  includeAlphas: InputMaybe<Scalars['Boolean']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryEmissionsModelReleasesForEmissionsModelStableArgs = {
  stableId: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelReleasesForEmissionsModelsStableArgs = {
  stableIds: Array<Scalars['ID']['input']>;
};

export type GQQueryEmissionsModelStableArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelStableVersionHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryEmissionsModelVersionArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelVersionImportedDescendantsFromDirectImportIdsArgs =
  {
    ids: Array<Scalars['ID']['input']>;
  };

export type GQQueryEmissionsModelVersionsForFootprintArgs = {
  footprintSnapshotId: Scalars['String']['input'];
};

export type GQQueryEmissionsModelsArgs = {
  emissionsModelStableId: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelsForOrgArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelsStableArgs = {
  emissionsModelListTab: InputMaybe<Scalars['String']['input']>;
  showArchived: Scalars['Boolean']['input'];
};

export type GQQueryEmissionsModelsStableByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryEmissionsModelsStableForBusinessActivityTypeArgs = {
  businessActvityTypeName: Scalars['String']['input'];
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryEmissionsModelsStablesForReleasesArgs = {
  excludeOrgSpecificModels: InputMaybe<Scalars['Boolean']['input']>;
  releaseIds: Array<Scalars['ID']['input']>;
};

export type GQQueryEmissionsModelsSupportedBatsArgs = {
  calculationTags: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQQueryEmissionsYearArgs = {
  emissionsYearId: Scalars['ID']['input'];
};

export type GQQueryEmployeeReportArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryEngagementTaskArgs = {
  engagementTaskId: Scalars['ID']['input'];
};

export type GQQueryEngagementTaskBatchArgs = {
  engagementTaskBatchId: Scalars['ID']['input'];
};

export type GQQueryEngagementTaskBatchDeployStatusArgs = {
  engagementTaskBatchId: Scalars['ID']['input'];
};

export type GQQueryEngagementTaskByCrossOrgModelIdArgs = {
  crossOrgModelId: Scalars['String']['input'];
};

export type GQQueryEngagementTaskConfigArgs = {
  engagementTaskConfigId: Scalars['ID']['input'];
};

export type GQQueryEngagementTaskConfigsArgs = {
  configGroup: InputMaybe<GQEngagementTaskConfigGroup>;
  includeGlobalConfigs: InputMaybe<Scalars['Boolean']['input']>;
  includeUnpublishedGlobal: InputMaybe<Scalars['Boolean']['input']>;
  includeUnpublishedOrgSpecific: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryEngagementTaskQuestionsForBaseConfigIdArgs = {
  baseConfigId: Scalars['ID']['input'];
  excludeComplex: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryEngagementTasksArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  ignoreLearningTasks: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  rootOnly: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryEngagementTasksAdminArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryEngagementTasksForCompanyAndOrgV1Args = {
  companyId: Scalars['ID']['input'];
  rootOnly: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryEvaluatedEmissionsModelsArgs = {
  footprintKind: InputMaybe<Scalars['String']['input']>;
  footprintVersion: InputMaybe<Scalars['String']['input']>;
  materialId: InputMaybe<Scalars['String']['input']>;
  orgRawMaterial: InputMaybe<Scalars['String']['input']>;
  productId: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryExpenseCategoriesForSuppliersArgs = {
  companyIds: Array<Scalars['ID']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  useDuckdbPostgresConnection: InputMaybe<Scalars['Boolean']['input']>;
  version: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryExternalReportAnswersForQuestionArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyIds: InputMaybe<Array<Scalars['ID']['input']>>;
  externalReportQuestionId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryExternalReportAnswersForReportArgs = {
  publicDisclosureId: Scalars['ID']['input'];
};

export type GQQueryExternalReportQuestionsArgs = {
  externalReportTypeId: Scalars['ID']['input'];
};

export type GQQueryExtractorRunArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFacilitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<Array<GQFacilitiesFilterInput>>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sort: InputMaybe<Array<GQFacilitiesSortInput>>;
};

export type GQQueryFacilityArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFeatureFlagDisabledForOrgArgs = {
  flag: GQFlags;
  orgId: Scalars['ID']['input'];
};

export type GQQueryFeatureFlagEnabledForOrgArgs = {
  flag: GQFlags;
  orgId: Scalars['ID']['input'];
};

export type GQQueryFeatureFlagEnabledGloballyArgs = {
  flag: GQFlags;
};

export type GQQueryFeatureFlagsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryFilesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<GQFileMetadataFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryFinanceAssetsYearOverYearArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  baselineSnapshotId: Scalars['ID']['input'];
  baselineYear: Scalars['Int']['input'];
  before: InputMaybe<Scalars['String']['input']>;
  comparisonYear: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryFinanceChangelogArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<Array<GQBiQueryFilterInput>>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryFinanceDataFlagsArgs = {
  assetId: InputMaybe<Scalars['ID']['input']>;
  financeSnapshotId: InputMaybe<Scalars['ID']['input']>;
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryFinanceFundsYearOverYearArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  baselineSnapshotId: Scalars['ID']['input'];
  baselineYear: Scalars['Int']['input'];
  before: InputMaybe<Scalars['String']['input']>;
  comparisonYear: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryFinanceGridWorksheetArgs = {
  standardView: GQFinanceStandardView;
};

export type GQQueryFinanceHoldingViewArgs = {
  filters: InputMaybe<Scalars['UntypedData']['input']>;
  financeSnapshotId: InputMaybe<Scalars['ID']['input']>;
  isTotalEmissions: InputMaybe<Scalars['Boolean']['input']>;
  standardView: InputMaybe<GQFinanceStandardView>;
  viewId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryFinanceImportArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFinanceMeasurementProgressRowsArgs = {
  filters: Scalars['UntypedData']['input'];
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceMeasurementProgressRowsAdminArgs = {
  orgId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceModelCardMetadataArgs = {
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceShowYourWorkArgs = {
  filters: Scalars['UntypedData']['input'];
  financeMetric: GQFinanceMetric;
  financeSnapshotId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceSnapshotArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFinanceSnapshotAssetArgs = {
  assetId: Scalars['ID']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceSnapshotAssetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceSnapshotAssetsGroupedByCompanyArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceSnapshotFilterOptionsArgs = {
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
};

export type GQQueryFinanceSnapshotFundsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceSnapshotHoldingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceSnapshotTagsArgs = {
  financeSnapshotId: Scalars['ID']['input'];
};

export type GQQueryFinanceSnapshotWaterfallChartArgs = {
  baselineSnapshotId: Scalars['ID']['input'];
  baselineYear: Scalars['Int']['input'];
  comparisonYear: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  financeWaterfallChartType: GQFinanceWaterfallChartType;
};

export type GQQueryFinanceTagsArgs = {
  tagType: InputMaybe<GQFinanceTagType>;
};

export type GQQueryFinanceViewArgs = {
  filters: InputMaybe<Scalars['UntypedData']['input']>;
  financeSnapshotId: InputMaybe<Scalars['ID']['input']>;
  isTotalEmissions: InputMaybe<Scalars['Boolean']['input']>;
  standardView: InputMaybe<GQFinanceStandardView>;
  viewId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryFinancialsReviewItemArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFinancialsReviewItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryFinancialsReviewItemsAdminArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryFindReferenceDataSourceArgs = {
  query: Scalars['String']['input'];
};

export type GQQueryFootprintAnalysisArgs = {
  footprintKind: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  version: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryFootprintAnalysisAdminArgs = {
  orgId: Scalars['ID']['input'];
  snapshotId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryFootprintAssemblyRunArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFootprintAuditBundleArgs = {
  bundleId: Scalars['String']['input'];
};

export type GQQueryFootprintCustomReportsArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryFootprintDebugArgs = {
  fpsId: Scalars['ID']['input'];
};

export type GQQueryFootprintKindsArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryFootprintKindsWithProductsOrMaterialsArgs = {
  footprintSnapshotId: Scalars['String']['input'];
};

export type GQQueryFootprintSnapshotArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFootprintSnapshotExclusionRuleArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFootprintSnapshotInDashboardArgs = {
  footprintSnapshotId: Scalars['String']['input'];
};

export type GQQueryFootprintSnapshotsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  footprintSnapshotIds: InputMaybe<Array<Scalars['ID']['input']>>;
  isAutoGenerated: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryFootprintSnapshotsForYearArgs = {
  year: Scalars['Int']['input'];
};

export type GQQueryFootprintTagArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFootprintTagsForOrgArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryFootprintTempArgs = {
  footprintId: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryFootprintTestSuiteCategoryRulesArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryFootprintTestSuiteExclusionRulesArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryFootprintTestSuiteExecutionStepResultArgs = {
  footprintAssemblyStep: GQFootprintAssemblyStep;
  footprintTestSuiteExecutionId: Scalars['ID']['input'];
};

export type GQQueryFootprintTestSuiteFootprintTagsArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryFootprintTestSuiteMarketplaceAllocInstrsArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryFootprintVersionArgs = {
  version: Scalars['String']['input'];
};

export type GQQueryFootprintVersionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isUserVisible: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Scalars['String']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryFootprintsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryFootprintsForOrgArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryForceRedirectArgs = {
  pathname: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryForecastArgs = {
  canViewABIs: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryForecastAdminArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryFormalReportArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFormalReportsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportStatus: InputMaybe<GQReportStatus>;
};

export type GQQueryFormalReportsAdminArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryFundArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryGetOneSchemaEmbedStatusArgs = {
  embedId: Scalars['String']['input'];
};

export type GQQueryGetOverlayForConfigTypeArgs = {
  config: Scalars['JSONString']['input'];
  scenarioId: Scalars['ID']['input'];
};

export type GQQueryGetReferenceDataFromRawSlugsArgs = {
  slugs: Array<Scalars['String']['input']>;
};

export type GQQueryGetReportObjectConfigPathArgs = {
  input: GQGetReportObjectConfigPathInput;
};

export type GQQueryGetRowsForConfigTypeArgs = {
  config: Scalars['JSONString']['input'];
  footprintInterval: Scalars['YMInterval']['input'];
  footprintKind: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: Scalars['String']['input'];
  simulationType: GQSimulationScenarioConfigType;
};

export type GQQueryGetValueMappingOutputOptionsArgs = {
  columnName: Scalars['String']['input'];
  configId: Scalars['String']['input'];
  searchTerm: InputMaybe<Scalars['String']['input']>;
  selectedValue: InputMaybe<Scalars['String']['input']>;
  suggestedValues: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQQueryGlobalFootprintTagsArgs = {
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
  latestOfEachTag: InputMaybe<Scalars['Boolean']['input']>;
  tagName: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryGroupedAuditLogsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  eventKinds: Array<Scalars['String']['input']>;
  filters: InputMaybe<Scalars['UntypedData']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryInboxItemsForOrgArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryIngestionCustomFieldArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryInputDataByExtBartIdsArgs = {
  extBartIds: Array<Scalars['ID']['input']>;
};

export type GQQueryIntegrationConnectionArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryIntegrationConnectionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryIntegrationDataPullsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryIntegrationPartnersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryIntensityQuantitiesByMonthArgs = {
  footprintSnapshotId: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryLatestCurrencyExchangeRateArgs = {
  targetCurrency: Scalars['String']['input'];
};

export type GQQueryLatestDocumentParseAttemptArgs = {
  fileId: Scalars['ID']['input'];
  modelType: GQDocumentParseAttemptModelType;
};

export type GQQueryLatestEmissionsModelVersionForStableIdArgs = {
  id: Scalars['ID']['input'];
  includeDrafts: Scalars['Boolean']['input'];
};

export type GQQueryLatestOrgUnitTypesForCanonicalDatasetKindArgs = {
  canonicalDatasetKind: GQCanonicalDatasetKind;
};

export type GQQueryLatestPublishedRevisionForRevisionIdOrNullArgs = {
  revisionId: Scalars['ID']['input'];
};

export type GQQueryLatestReferenceDataRevisionForVersionArgs = {
  anyOrg: InputMaybe<Scalars['Boolean']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
  referenceDataVersionId: Scalars['ID']['input'];
};

export type GQQueryLatestReferenceDataRevisionsForOrgArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryLatestUserVisibleDraftFootprintVersionArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryLatestUserVisiblePublishedFootprintVersionArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryLegacyAssumptionAsMeasurementMappingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryLegacyFootprintArgs = {
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  shouldConvertCurrency: InputMaybe<Scalars['Boolean']['input']>;
  version: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryLogEventsArgs = {
  assetId: InputMaybe<Scalars['ID']['input']>;
  financeSnapshotId: InputMaybe<Scalars['ID']['input']>;
  fundId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryMappedExternalReportQuestionsArgs = {
  questionId: Scalars['ID']['input'];
};

export type GQQueryMarketplaceAllocationInstructionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryMarketplaceDeveloperArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplaceDevelopersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplaceDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplaceDocumentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplacePointsOfContactArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplacePortfolioArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplacePortfolioAllocationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  portfolioId: Scalars['String']['input'];
};

export type GQQueryMarketplacePortfoliosArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplaceProjectArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplaceProjectArchetypeArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplaceProjectArchetypesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplaceProjectOfferingArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplaceProjectOfferingUpdatesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  baseOfferingId: Scalars['ID']['input'];
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplaceProjectOfferingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  archetypeId: InputMaybe<Scalars['ID']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplaceProjectsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  supplierId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryMarketplacePurchaseArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplacePurchaseLineItemArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplacePurchaseLineItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplacePurchasesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplaceSupplierArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplaceSuppliersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMaterialsUpdatedTaxonomyArgs = {
  dateInterval: InputMaybe<Scalars['YMInterval']['input']>;
  footprintKind: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  groupByMonth: Scalars['Boolean']['input'];
  orgMaterial: InputMaybe<Scalars['String']['input']>;
  orgRawMaterial: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryMaybeAssetArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryMeasurementContextItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryMeasurementMappingOutputOptionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMeasurementMappingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryMeasurementProjectArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMeasurementTestSuiteArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMeasurementTestSuiteChangelogArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMeasurementTestSuitesArgs = {
  criticalOnly: Scalars['Boolean']['input'];
  includeDeleted: Scalars['Boolean']['input'];
};

export type GQQueryMeasurementVerificationItemArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMeasurementVerificationItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  measurementProjectId: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryMeasurementVerificationItemsForDatasetArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  datasetId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  measurementProjectId: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryMethodologyCustomizationMappingConfigArgs = {
  configId: Scalars['String']['input'];
};

export type GQQueryMethodologyCustomizationOneSchemaTemplateForTypeArgs = {
  customizationTypeId: Scalars['String']['input'];
};

export type GQQueryMethodologyCustomizationPreviewFootprintArgs = {
  mappingConfigId: Scalars['String']['input'];
};

export type GQQueryMethodologyCustomizationRowsForOrgArgs = {
  customizationTypeId: Scalars['String']['input'];
};

export type GQQueryMethodologyCustomizationTypeConfigArgs = {
  customizationTypeId: Scalars['String']['input'];
};

export type GQQueryMethodologyExplainerArgs = {
  footprintSnapshotId: Scalars['String']['input'];
  version: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryMethodologyTestSuiteExecutionArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMethodologyTestSuiteValidationResultsArgs = {
  measurementTestSuiteId: Scalars['ID']['input'];
};

export type GQQueryObjectArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryOrgFootprintDisclosureShareLogsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryOrgFootprintDisclosuresArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryOrgFundsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryOrgStructureVersionsArgs = {
  versionId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryOrgUnitTypeRelationshipsArgs = {
  orgUnitTypeId: InputMaybe<Scalars['ID']['input']>;
  versionId: Scalars['ID']['input'];
};

export type GQQueryOrgUnitTypesArgs = {
  orgUnitTypeId: InputMaybe<Scalars['ID']['input']>;
  versionId: Scalars['ID']['input'];
};

export type GQQueryOrgUnitsArgs = {
  orgUnitTypeId: InputMaybe<Scalars['ID']['input']>;
  versionId: Scalars['ID']['input'];
};

export type GQQueryOrganizationArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryOrganizationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryPeerCompanyGroupsArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryPeerExternalReportAnswersForIdentifiersArgs = {
  reportQuestionIdentifiers: Array<Scalars['String']['input']>;
};

export type GQQueryPendingUserPermissionRequestsForUserArgs = {
  permission: InputMaybe<GQPermissionType>;
};

export type GQQueryPermissionItemArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPermissionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeWatershedEmployees: InputMaybe<Scalars['Boolean']['input']>;
  includedPermissions: InputMaybe<Array<GQPermissionType>>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryPipelineResultArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPipelineResultsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
  pipelineResultIds: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQQueryPipelineRunsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryPlanArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPlansArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeHidden: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  state: InputMaybe<GQPlanState>;
};

export type GQQueryPlansForOrgArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPlansWithDefaultsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryPlaygroundSqlArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPlaygroundSqlRunArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPlaygroundSqlRunsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
  userId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryPortcoOrgsArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryPortcosArgs = {
  includeCompany: InputMaybe<Scalars['Boolean']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryPortfolioBuilderArchetypesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryPostMeasurementVendorMatchingTableRowsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortBy: InputMaybe<GQSupplierMatchingSortOption>;
  sortByUnmatched: InputMaybe<Scalars['Boolean']['input']>;
  version: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryPreMeasurementSupplierMappingTableRowsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  search: InputMaybe<Scalars['String']['input']>;
  sortBy: InputMaybe<GQSupplierMatchingSortOption>;
  sortByUnmatched: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryPreMeasurementSupplierMappingV1AnalyticsArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryPreMeasurementVendorMatchingTableRowsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  search: InputMaybe<Scalars['String']['input']>;
  sortBy: InputMaybe<GQSupplierMatchingSortOption>;
  sortByUnmatched: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryPreflightEraseReportComponentArgs = {
  input: GQEraseReportComponentInput;
};

export type GQQueryPrepublicationMeasurementTestResultsArgs = {
  workflowId: Scalars['ID']['input'];
};

export type GQQueryPreviewCadtBuildingsPushArgs = {
  activityDataExtractorId: Scalars['ID']['input'];
};

export type GQQueryPrivateDisclosureIDsArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryProductsUpdatedTaxonomyArgs = {
  dateInterval: InputMaybe<Scalars['YMInterval']['input']>;
  footprintKind: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  groupByMonth: Scalars['Boolean']['input'];
  product: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryPublicDisclosureArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPublicDisclosureSearchArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  allScopes: InputMaybe<Scalars['Boolean']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  mustHaveURL: InputMaybe<Scalars['Boolean']['input']>;
  onlyMarketBased: InputMaybe<Scalars['Boolean']['input']>;
  publishingYears: InputMaybe<Array<Scalars['Int']['input']>>;
  reportTypes: InputMaybe<Array<GQExternalReportType>>;
  revenueMustExist: InputMaybe<Scalars['Boolean']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryPublicDisclosuresArgs = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  mustHaveURL: InputMaybe<Scalars['Boolean']['input']>;
  reportType: InputMaybe<GQExternalReportType>;
};

export type GQQueryPublicDisclosuresAdminArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryPublicDisclosuresPaginatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  companyIds: InputMaybe<Array<Scalars['ID']['input']>>;
  filterLicensed: InputMaybe<Scalars['Boolean']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  mustHaveURL: InputMaybe<Scalars['Boolean']['input']>;
  publishingYears: InputMaybe<Array<Scalars['Int']['input']>>;
  reportTypes: InputMaybe<Array<GQExternalReportType>>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryRawCustomerTargetSchemaVersionArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryRecommendedPublicDisclosuresArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryRecommendedReportKindsArgs = {
  companyId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataAncestryForVersionIdsArgs = {
  versionIds: Array<Scalars['ID']['input']>;
};

export type GQQueryReferenceDataCitationArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReferenceDataCitationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeSlugWithName: InputMaybe<Scalars['String']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
  searchString: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryReferenceDataDifferArgs = {
  sourceRevisionId: Scalars['ID']['input'];
  targetRevisionId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataOverlayArgs = {
  overlayId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataOverlayByRevisionIdArgs = {
  referenceDataRevision: Scalars['ID']['input'];
};

export type GQQueryReferenceDataRevisionArgs = {
  revisionId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataRevisionsByVersionIdArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
  referenceDataVersionId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataSourceArgs = {
  referenceDataSourceId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataSourceByNameArgs = {
  name: Scalars['String']['input'];
};

export type GQQueryReferenceDataSourcesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  filter: GQReferenceDataSourceKindFilter;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  tagIdsFilter: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQQueryReferenceDataSqlForRevisionIdArgs = {
  revisionId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataVersionArgs = {
  referenceDataVersionId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataVersionBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type GQQueryReferenceDataVersionsBySlugsArgs = {
  slugs: Array<Scalars['String']['input']>;
};

export type GQQueryReferenceDataVersionsBySlugsIfExistsArgs = {
  slugs: Array<Scalars['String']['input']>;
};

export type GQQueryRefreshableAssetsArgs = {
  orgId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQQueryReportArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReportAnswerVerifierFailuresArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportAnswerFilters: GQReportAnswerFilterInput;
};

export type GQQueryReportAnswersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<GQReportAnswerFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryReportAttachmentItemsArgs = {
  kinds: InputMaybe<Array<GQReportAttachmentItemKind>>;
};

export type GQQueryReportAttachmentItemsForQuestionArgs = {
  reportId: Scalars['ID']['input'];
  reportQuestionId: Scalars['ID']['input'];
};

export type GQQueryReportConfigArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReportConfigQuestionsArgs = {
  reportConfigId: Scalars['ID']['input'];
};

export type GQQueryReportConfigsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportType: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryReportProgressArgs = {
  input: GQReportProgressInput;
};

export type GQQueryReportQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReportQuestionContainerArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReportQuestionIdentifierMappingArgs = {
  reportQuestionIdentifierId: Scalars['ID']['input'];
};

export type GQQueryReportQuestionIdentifiersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryReportQuestionsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryReportWithConfigArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReportingFootprintHealthChecksArgs = {
  input: GQReportingFootprintHealthChecksInput;
};

export type GQQueryReportsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  configId: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  kind: InputMaybe<Scalars['String']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
  status: InputMaybe<GQReportStatus>;
};

export type GQQueryRoleArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryRolesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQuerySandboxEmissionsYearsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQuerySchemaRegistrySchemaArgs = {
  schemaId: Scalars['ID']['input'];
};

export type GQQuerySchemaRegistrySchemaVersionArgs = {
  schemaVersionId: Scalars['ID']['input'];
};

export type GQQuerySchemaRegistrySchemaVersionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  schemaId: InputMaybe<Scalars['ID']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQuerySchemaRegistrySchemasArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQuerySearchReferenceDataSourceNameArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
  q: Scalars['String']['input'];
};

export type GQQuerySelectedDatasetsByBatWithMetadataArgs = {
  datasetIdsPerMeasurementProjectId: Array<GQDatasetIdsPerMeasurementProjectId>;
};

export type GQQueryShouldShowDuckArgs = {
  duckName: Scalars['String']['input'];
};

export type GQQuerySignOneSchemaJwtArgs = {
  payload: Scalars['JSONString']['input'];
};

export type GQQuerySignedParquetUrlArgs = {
  id: Scalars['ID']['input'];
};

export type GQQuerySimulationEligibleFootprintIntervalsArgs = {
  footprintKind: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: Scalars['ID']['input'];
  simulationType: GQSimulationScenarioConfigType;
};

export type GQQuerySimulationPortfolioArgs = {
  simulationPortfolioId: Scalars['ID']['input'];
};

export type GQQuerySimulationPortfoliosArgs = {
  isTemplate: Scalars['Boolean']['input'];
};

export type GQQuerySimulationScenarioArgs = {
  simulationScenarioId: Scalars['ID']['input'];
};

export type GQQuerySimulationScenarioRunArgs = {
  simulationScenarioRunId: Scalars['ID']['input'];
};

export type GQQuerySimulationSwapOptionsArgs = {
  footprintInterval: Scalars['YMInterval']['input'];
  footprintKind: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: Scalars['String']['input'];
  simulationType: GQSimulationScenarioConfigType;
};

export type GQQuerySingleRowMethodologyExplainerArgs = {
  bundleId: Scalars['String']['input'];
  rowId: Scalars['Int']['input'];
};

export type GQQuerySingleRowMethodologyExplainerForEmissionsYearArgs = {
  emissionsYearId: Scalars['String']['input'];
  rowId: Scalars['Int']['input'];
};

export type GQQuerySingleRowMethodologyExplainerWithLatestFpsArgs = {
  rowId: Scalars['Int']['input'];
};

export type GQQuerySupplierArgs = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  vendorName: InputMaybe<Scalars['String']['input']>;
  version: InputMaybe<Scalars['String']['input']>;
  viewFilters: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQQuerySupplierActivityTagsArgs = {
  footprintInterval: Scalars['YMInterval']['input'];
  footprintVersion: InputMaybe<Scalars['String']['input']>;
};

export type GQQuerySupplierAttachmentDownloadUrlArgs = {
  fileMetadataId: Scalars['ID']['input'];
};

export type GQQuerySupplierAttachmentsArgs = {
  companyId: Scalars['ID']['input'];
};

export type GQQuerySupplierAttachmentsByCrossOrgIdsArgs = {
  crossOrgModelIds: Array<Scalars['String']['input']>;
};

export type GQQuerySupplierDetailedEmissionsArgs = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  vendorName: InputMaybe<Scalars['String']['input']>;
  version: InputMaybe<Scalars['String']['input']>;
  viewFilters: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQQuerySupplierEmissionsFactorsTableRowsArgs = {
  companyId: Scalars['String']['input'];
  supplierName: Scalars['String']['input'];
};

export type GQQuerySupplierFilterOptionsArgs = {
  dimension: Scalars['String']['input'];
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  nestedDimensions: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQQuerySupplierTableColumnsArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQuerySupplierViewsAdminArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQuerySuppliersAdminArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQuerySuppliersSettingsAdminArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQuerySuppliersV2Args = {
  enableExperimentalCache: InputMaybe<Scalars['Boolean']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  includedGhgCategories: InputMaybe<Array<Scalars['String']['input']>>;
  options: InputMaybe<GQSuppliersV2Options>;
  tableFilters: InputMaybe<Array<GQBiQueryFilterInput>>;
  version: InputMaybe<Scalars['String']['input']>;
  viewFilters: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQQuerySuppliersWithTargetDisclosuresArgs = {
  footprintInterval: Scalars['YMInterval']['input'];
  footprintVersion: InputMaybe<Scalars['String']['input']>;
  viewFilters: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQQueryTargetingOptionsArgs = {
  config: Scalars['JSONString']['input'];
  footprintInterval: Scalars['YMInterval']['input'];
  footprintKind: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: Scalars['String']['input'];
};

export type GQQueryThrowErrorQueryArgs = {
  input: GQThrowErrorInput;
};

export type GQQueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryUserHasManageValueMappingPermissionArgs = {
  orgId: Scalars['ID']['input'];
  valueMappingRuleId: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryUserHasPermissionArgs = {
  allowAnyObject: InputMaybe<Scalars['Boolean']['input']>;
  objectId: InputMaybe<Scalars['ID']['input']>;
  permission: GQPermissionType;
};

export type GQQueryUserHasViewDatasourcePermissionArgs = {
  allowAnyObject: InputMaybe<Scalars['Boolean']['input']>;
  datasourceId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryUserUploadArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryUserUploadCandidatesArgs = {
  userUploadCandidateIds: Array<Scalars['ID']['input']>;
};

export type GQQueryUserUploadTaskArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryUserUploadTaskForDatasourceArgs = {
  datasourceId: Scalars['ID']['input'];
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryUserUploadedTableArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryUserUploadedTableByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryUserUploadedTablesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryUserUploadedTablesByIdArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryUserUploadsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  datasourceId: InputMaybe<Scalars['ID']['input']>;
  datasourceIds: InputMaybe<Array<Scalars['ID']['input']>>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeRevisions: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryUserUploadsByBuildingIdArgs = {
  id: Scalars['ID']['input'];
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryUserUploadsByIdArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryUsersArgs = {
  includeWatershedEmployees: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryUsersWithSupplierPermissionArgs = {
  companyId: Scalars['ID']['input'];
};

export type GQQueryUtilitiesMetadataArgs = {
  filterInterval: InputMaybe<Scalars['YMInterval']['input']>;
};

export type GQQueryValidateUpdateReferenceDataVersionSchemaArgs = {
  orgId: InputMaybe<Scalars['String']['input']>;
  referenceDataVersionId: Scalars['ID']['input'];
  schemaJson: Scalars['JSONSchema']['input'];
};

export type GQQueryValueMappingArgs = {
  valueMappingConfigId: Scalars['String']['input'];
};

export type GQQueryValueMappingAggregatedQuantitiesArgs = {
  configId: Scalars['ID']['input'];
  contextColumn: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQQueryValueMappingCommentsForSubjectArgs = {
  orgId: Scalars['ID']['input'];
  subjectId: Scalars['ID']['input'];
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQQueryValueMappingConfigArgs = {
  valueMappingConfigId: Scalars['String']['input'];
};

export type GQQueryValueMappingFileRowDataArgs = {
  filter: Array<GQValueMappingFileDataFilter>;
  sortColumn: Scalars['String']['input'];
  userUploadIds: Array<Scalars['ID']['input']>;
};

export type GQQueryValueMappingHistoryForGroupArgs = {
  groupId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQQueryValueMappingRowsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<GQValueMappingFilterType>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
  searchText: InputMaybe<Scalars['String']['input']>;
  sortModel: InputMaybe<Array<GQDataGridSortItemInput>>;
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQQueryValueMappingTableSourceDataArgs = {
  valueMappingConfigId: Scalars['String']['input'];
};

export type GQQueryValueMappingTaskArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryValueMappingWithLatestStatusArgs = {
  projectId: InputMaybe<Scalars['String']['input']>;
  valueMappingConfigId: Scalars['String']['input'];
};

export type GQQueryVariousSuppliersArgs = {
  footprintInterval: Scalars['YMInterval']['input'];
  footprintVersion: InputMaybe<Scalars['String']['input']>;
  includedGhgCategories: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQQueryVendorMatchingTaskArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryVersionedBusinessActivityTypesArgs = {
  input: Array<GQVersionedBusinessActivityTypeInput>;
};

export type GQQueryVisibleMarketplaceProjectArchetypesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryWatershedEmployeesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgPointOfContactKind: InputMaybe<GQOrgPointOfContactKind>;
};

export type GQQueryYearMeasurementProjectsArgs = {
  reportingYear: Scalars['Int']['input'];
};

export type GQQueryRunInterface = {
  error: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobError: Maybe<GQSerializableError>;
  state: GQBackgroundJobState;
};

export type GQQuestion = {
  comments: GQCommentConnection;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type GQQuestionCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQRawCadtRowToMap = {
  __typename?: 'RawCadtRowToMap';
  adt_ids: Array<Scalars['String']['output']>;
  description_from_cadt: Maybe<Scalars['String']['output']>;
  emissions_specifier: Scalars['String']['output'];
  emissions_subspecifier: Scalars['String']['output'];
  month_earliest: Scalars['YearMonth']['output'];
  month_latest: Scalars['YearMonth']['output'];
  top_vendors: Array<GQRawCadtRowToMapVendor>;
  total_amount: Maybe<Scalars['Float']['output']>;
  unit: Maybe<Scalars['String']['output']>;
  unnormalized_emissions_subspecifier: Scalars['String']['output'];
};

export type GQRawCadtRowToMapConnection = {
  __typename?: 'RawCadtRowToMapConnection';
  edges: Array<Maybe<GQRawCadtRowToMapEdge>>;
  pageInfo: GQPageInfo;
};

export type GQRawCadtRowToMapEdge = {
  __typename?: 'RawCadtRowToMapEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQRawCadtRowToMap>;
};

export type GQRawCadtRowToMapVendor = {
  __typename?: 'RawCadtRowToMapVendor';
  amount: Scalars['Float']['output'];
  vendor_entity: Scalars['String']['output'];
};

export type GQReassignFacilitiesTaskInput = {
  assignments: Array<GQReassignFacilitiesTaskRowInput>;
  measurementProjectId: Scalars['ID']['input'];
};

export type GQReassignFacilitiesTaskPayload = {
  __typename?: 'ReassignFacilitiesTaskPayload';
  measurementProject: GQMeasurementProject;
};

export type GQReassignFacilitiesTaskRowInput = {
  buildingId: Scalars['ID']['input'];
  facilitiesType: GQFacilitiesType;
  userIds: Array<Scalars['ID']['input']>;
};

export type GQRecommendedReportKinds = {
  __typename?: 'RecommendedReportKinds';
  id: Scalars['ID']['output'];
  recommended: Array<GQReportKindWithReason>;
  required: Array<GQReportKindWithReason>;
  state: GQRecommendedReportKindsState;
};

export const GQRecommendedReportKindsState = {
  Complete: 'Complete',
  NotStarted: 'NotStarted',
  PartiallyComplete: 'PartiallyComplete',
} as const;

export type GQRecommendedReportKindsState =
  (typeof GQRecommendedReportKindsState)[keyof typeof GQRecommendedReportKindsState];
export type GQReductionFilters = {
  buildingName?: InputMaybe<Array<Scalars['String']['input']>>;
  categoryId: Array<Scalars['String']['input']>;
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  electricityType?: InputMaybe<Array<Scalars['String']['input']>>;
  entity?: InputMaybe<Array<Scalars['String']['input']>>;
  ghgCategoryId?: InputMaybe<Array<Scalars['String']['input']>>;
  ghgScope?: InputMaybe<Array<Scalars['String']['input']>>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  locationCountry?: InputMaybe<Array<Scalars['String']['input']>>;
  product?: InputMaybe<Array<Scalars['String']['input']>>;
  subcategoryId?: InputMaybe<Array<Scalars['String']['input']>>;
  vendor?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQReductionFiltersMulti = {
  buildingName?: InputMaybe<Array<Scalars['String']['input']>>;
  categoryId: Array<Scalars['String']['input']>;
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  electricityType?: InputMaybe<Array<Scalars['String']['input']>>;
  entity?: InputMaybe<Array<Scalars['String']['input']>>;
  ghgCategoryId?: InputMaybe<Array<Scalars['String']['input']>>;
  ghgScope?: InputMaybe<Array<Scalars['String']['input']>>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  locationCountry?: InputMaybe<Array<Scalars['String']['input']>>;
  product?: InputMaybe<Array<Scalars['String']['input']>>;
  subcategoryId?: InputMaybe<Array<Scalars['String']['input']>>;
  vendor?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQReductionTargetByYear = {
  __typename?: 'ReductionTargetByYear';
  reductionTarget: Scalars['Float']['output'];
  targetYear: Scalars['YearMonth']['output'];
};

export type GQReductionTargetByYearInput = {
  reductionTarget: Scalars['Float']['input'];
  targetYearExclusive: Scalars['YearMonth']['input'];
};

export type GQReductionTargetsInfo = {
  __typename?: 'ReductionTargetsInfo';
  heading: Scalars['String']['output'];
  reductionTargets: Array<GQReductionTargetsInfoItem>;
};

export type GQReductionTargetsInfoItem = {
  __typename?: 'ReductionTargetsInfoItem';
  description: Scalars['String']['output'];
  heading: Scalars['String']['output'];
  subheading: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type GQReferenceDataAncestorRelationship = {
  __typename?: 'ReferenceDataAncestorRelationship';
  upstreamVersionId: Scalars['ID']['output'];
  upstreamVersionSlug: Scalars['String']['output'];
  versionId: Scalars['ID']['output'];
  versionSlug: Scalars['String']['output'];
};

export type GQReferenceDataCitation = {
  __typename?: 'ReferenceDataCitation';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  author: Maybe<GQUser>;
  authorUserId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  externalSourceNotes: Maybe<Scalars['String']['output']>;
  externalSourceUrl: Maybe<Scalars['String']['output']>;
  hasLicenseRestrictions: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  internalSourceNotes: Scalars['String']['output'];
  internalSourceUrl: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  isCustomerProvided: Scalars['Boolean']['output'];
  isSupplierSpecific: Scalars['Boolean']['output'];
  org: Maybe<GQOrganization>;
  orgId: Maybe<Scalars['String']['output']>;
  privateDisclosureId: Maybe<Scalars['String']['output']>;
  publicDisclosureId: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQReferenceDataCitationConnection = {
  __typename?: 'ReferenceDataCitationConnection';
  edges: Array<GQReferenceDataCitationEdge>;
  pageInfo: GQPageInfo;
};

export type GQReferenceDataCitationEdge = {
  __typename?: 'ReferenceDataCitationEdge';
  cursor: Scalars['String']['output'];
  node: GQReferenceDataCitation;
};

export type GQReferenceDataDifferResult = {
  __typename?: 'ReferenceDataDifferResult';
  fileMetadata: GQFileMetadata;
  sourceReferenceDataRevision: GQReferenceDataRevision;
  targetReferenceDataRevision: GQReferenceDataRevision;
};

export const GQReferenceDataKind = {
  Composite: 'Composite',
  Translation: 'Translation',
} as const;

export type GQReferenceDataKind =
  (typeof GQReferenceDataKind)[keyof typeof GQReferenceDataKind];
export type GQReferenceDataOverlay = {
  __typename?: 'ReferenceDataOverlay';
  globalCdrId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['String']['output'];
  signedMergedDataUrl: Scalars['String']['output'];
  source: Maybe<GQReferenceDataSource>;
};

export type GQReferenceDataRevision = {
  __typename?: 'ReferenceDataRevision';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  assumptionSource: Maybe<GQAssumptionSource>;
  author: Maybe<GQUser>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  internalSourceNotes: Maybe<Scalars['String']['output']>;
  isLatestForOrgAndState: Scalars['Boolean']['output'];
  isOverlay: Maybe<Scalars['Boolean']['output']>;
  isValueMappingLegacyMigration: Maybe<Scalars['Boolean']['output']>;
  kind: Maybe<GQReferenceDataRevisionKind>;
  lastCompatibleSchema: Maybe<Scalars['ID']['output']>;
  orgId: Maybe<Scalars['String']['output']>;
  organization: Maybe<GQOrganization>;
  outputFileId: Scalars['ID']['output'];
  revisionName: Scalars['String']['output'];
  schemaJson: Maybe<Scalars['JSONSchema']['output']>;
  schemaLastCheckedAgainst: Maybe<Scalars['ID']['output']>;
  signedParquetOutputUrl: Scalars['String']['output'];
  signedSourceUrl: Maybe<Scalars['String']['output']>;
  source: GQReferenceDataSource;
  sourceFileId: Maybe<Scalars['ID']['output']>;
  sourceId: Scalars['ID']['output'];
  sqlRun: Maybe<GQReferenceDataSql>;
  state: GQReferenceDataRevisionState;
  tableAlias: Maybe<Scalars['String']['output']>;
  updates: Scalars['JSONSchema']['output'];
  upstreamReferenceDataVersions: Array<GQReferenceDataVersion>;
  userAuthorId: Maybe<Scalars['ID']['output']>;
  version: GQReferenceDataVersion;
  versionId: Scalars['ID']['output'];
};

export type GQReferenceDataRevisionConnection = {
  __typename?: 'ReferenceDataRevisionConnection';
  edges: Array<Maybe<GQReferenceDataRevisionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReferenceDataRevisionEdge = {
  __typename?: 'ReferenceDataRevisionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReferenceDataRevision>;
};

export const GQReferenceDataRevisionKind = {
  CsvUpload: 'CsvUpload',
  SqlQuery: 'SqlQuery',
  SystemGenerated: 'SystemGenerated',
} as const;

export type GQReferenceDataRevisionKind =
  (typeof GQReferenceDataRevisionKind)[keyof typeof GQReferenceDataRevisionKind];
export type GQReferenceDataRevisionRow = {
  __typename?: 'ReferenceDataRevisionRow';
  citationName: Scalars['String']['output'];
  citationNotes: Maybe<Scalars['String']['output']>;
  citationSlug: Scalars['String']['output'];
  citationUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isCustomerProvided: Maybe<Scalars['Boolean']['output']>;
  rowFields: Scalars['JSONString']['output'];
};

export const GQReferenceDataRevisionState = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type GQReferenceDataRevisionState =
  (typeof GQReferenceDataRevisionState)[keyof typeof GQReferenceDataRevisionState];
export type GQReferenceDataRevisionsByVersion = {
  __typename?: 'ReferenceDataRevisionsByVersion';
  revisions: Array<GQReferenceDataRevision>;
  version: GQReferenceDataVersion;
};

export type GQReferenceDataSchema = {
  __typename?: 'ReferenceDataSchema';
  id: Scalars['ID']['output'];
  schemaJson: Scalars['JSONSchema']['output'];
};

export type GQReferenceDataSchemaUpdateValidation = {
  __typename?: 'ReferenceDataSchemaUpdateValidation';
  errors: Array<Scalars['String']['output']>;
  warnings: Array<Scalars['String']['output']>;
};

export type GQReferenceDataSource = {
  __typename?: 'ReferenceDataSource';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  assumptionSources: Maybe<Array<GQAssumptionSource>>;
  author: Maybe<GQUser>;
  createdAt: Scalars['DateTime']['output'];
  externalSourceNotes: Maybe<Scalars['String']['output']>;
  externalSourceUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCompositeData: Scalars['Boolean']['output'];
  isSyncedWithCliq: Scalars['Boolean']['output'];
  kind: Maybe<GQReferenceDataKind>;
  latestPublishedVersion: Maybe<GQReferenceDataVersion>;
  latestSignedParquetOutputUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  organization: Maybe<GQOrganization>;
  orgsWithRevisions: Maybe<Array<GQOrganization>>;
  referenceDataVersions: Array<GQReferenceDataVersion>;
  tags: Maybe<Array<GQCalculationTag>>;
  updatedAt: Scalars['DateTime']['output'];
  userAuthorId: Maybe<Scalars['ID']['output']>;
};

export type GQReferenceDataSourceLatestSignedParquetOutputUrlArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataSourceOrgsWithRevisionsArgs = {
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQReferenceDataSourceReferenceDataVersionsArgs = {
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQReferenceDataSourceConnection = {
  __typename?: 'ReferenceDataSourceConnection';
  edges: Array<Maybe<GQReferenceDataSourceEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReferenceDataSourceEdge = {
  __typename?: 'ReferenceDataSourceEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReferenceDataSource>;
};

export const GQReferenceDataSourceKindFilter = {
  All: 'All',
  Composite: 'Composite',
  Reference: 'Reference',
} as const;

export type GQReferenceDataSourceKindFilter =
  (typeof GQReferenceDataSourceKindFilter)[keyof typeof GQReferenceDataSourceKindFilter];
export type GQReferenceDataSql = {
  __typename?: 'ReferenceDataSql';
  createdAt: Scalars['DateTime']['output'];
  duckdbVersion: Scalars['String']['output'];
  error: Maybe<Scalars['String']['output']>;
  gitHash: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  queryResultFileId: Maybe<Scalars['ID']['output']>;
  revisionId: Maybe<Scalars['ID']['output']>;
  revisionLastUpdatedDate: Maybe<Scalars['DateTime']['output']>;
  revisionState: Maybe<GQReferenceDataRevisionState>;
  signedParquetOutputUrl: Maybe<Scalars['String']['output']>;
  signedParquetRawResultUrl: Maybe<Scalars['String']['output']>;
  sql: Scalars['String']['output'];
  status: Maybe<GQReferenceDataSqlStatus>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warnings: Maybe<Scalars['JSONString']['output']>;
};

export const GQReferenceDataSqlStatus = {
  Error: 'Error',
  Success: 'Success',
} as const;

export type GQReferenceDataSqlStatus =
  (typeof GQReferenceDataSqlStatus)[keyof typeof GQReferenceDataSqlStatus];
export type GQReferenceDataVersion = {
  __typename?: 'ReferenceDataVersion';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  author: Maybe<GQUser>;
  canPublish: Scalars['Boolean']['output'];
  citations: Array<GQReferenceDataCitation>;
  compositeDataOrgDetails: Maybe<Array<GQCompositeDataOrgDetail>>;
  createdAt: Scalars['DateTime']['output'];
  hasPublishedRevision: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  latestPublishedRevisionByVersionId: Maybe<GQReferenceDataRevision>;
  latestReferenceDataSqlRun: Maybe<GQReferenceDataSql>;
  latestSchema: Maybe<GQReferenceDataSchema>;
  latestSignedParquetOutputUrl: Maybe<Scalars['String']['output']>;
  referenceDataRevisions: Maybe<Array<GQReferenceDataRevision>>;
  referenceDataSqlRuns: Array<GQReferenceDataSql>;
  slug: Scalars['String']['output'];
  source: GQReferenceDataSource;
  sourceId: Scalars['ID']['output'];
  state: GQReferenceDataVersionState;
  updatedAt: Scalars['DateTime']['output'];
  upstreamReferenceDataVersions: Array<GQReferenceDataVersion>;
  userAuthorId: Maybe<Scalars['ID']['output']>;
  versionName: Scalars['String']['output'];
  versionVintage: Scalars['DateTime']['output'];
};

export type GQReferenceDataVersionLatestPublishedRevisionByVersionIdArgs = {
  anyOrg: InputMaybe<Scalars['Boolean']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionLatestReferenceDataSqlRunArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionLatestSignedParquetOutputUrlArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionReferenceDataRevisionsArgs = {
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionReferenceDataSqlRunsArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionForRdv = {
  __typename?: 'ReferenceDataVersionForRdv';
  rdsId: Scalars['String']['output'];
  rdsName: Scalars['String']['output'];
  rdvCreatedAt: Scalars['DateTime']['output'];
  rdvId: Scalars['String']['output'];
  rdvIsLatestPublishedVersion: Scalars['Boolean']['output'];
  rdvName: Scalars['String']['output'];
  rdvUpdatedAt: Scalars['DateTime']['output'];
  usingOutdatedRevision: Scalars['Boolean']['output'];
};

export type GQReferenceDataVersionSlugOutput = {
  __typename?: 'ReferenceDataVersionSlugOutput';
  referenceDataVersion: GQReferenceDataVersion;
  slug: Scalars['String']['output'];
};

export const GQReferenceDataVersionState = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type GQReferenceDataVersionState =
  (typeof GQReferenceDataVersionState)[keyof typeof GQReferenceDataVersionState];
export type GQReferenceValueDetails = {
  __typename?: 'ReferenceValueDetails';
  citation: GQTraceReferenceValueCitationDetails;
  description: Maybe<Scalars['String']['output']>;
  refDataCellPath: Scalars['String']['output'];
};

export type GQRefreshAssetYearEstimationsInput = {
  action?: InputMaybe<GQFinanceAuditAction>;
  assetYearIds: Array<Scalars['ID']['input']>;
};

export type GQRefreshAssetYearEstimationsPayload = {
  __typename?: 'RefreshAssetYearEstimationsPayload';
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQRefreshCarbonRemovalActivityDataTableInput = {
  orgId: Scalars['String']['input'];
};

export type GQRefreshCarbonRemovalActivityDataTablePayload = {
  __typename?: 'RefreshCarbonRemovalActivityDataTablePayload';
  jobId: Maybe<Scalars['ID']['output']>;
};

export type GQRefreshCleanPowerActivityDataTableInput = {
  orgId: Scalars['ID']['input'];
  pipelineResultId: Scalars['ID']['input'];
};

export type GQRefreshCleanPowerActivityDataTablePayload = {
  __typename?: 'RefreshCleanPowerActivityDataTablePayload';
  jobId: Scalars['ID']['output'];
  warnings: Maybe<Array<Scalars['String']['output']>>;
};

export type GQRefreshMarketplacePurchaseInput = {
  eacMetadata?: InputMaybe<GQUpdateMarketplacePurchaseEacMetadataInput>;
  id: Scalars['ID']['input'];
  offerExpiration?: InputMaybe<Scalars['DateTime']['input']>;
  status: GQMarketplacePurchaseStatus;
};

export type GQRefreshMarketplacePurchasePayload = {
  __typename?: 'RefreshMarketplacePurchasePayload';
  purchase: GQMarketplacePurchase;
};

export type GQRefreshReportPayload = {
  __typename?: 'RefreshReportPayload';
  report: GQReport;
  reportGenerationJobId: Maybe<Scalars['String']['output']>;
};

export const GQRefrigerantLeakageSource = {
  InstalledEquipment: 'InstalledEquipment',
  Manufacturing: 'Manufacturing',
} as const;

export type GQRefrigerantLeakageSource =
  (typeof GQRefrigerantLeakageSource)[keyof typeof GQRefrigerantLeakageSource];
export type GQRegenerateAuditReportInput = {
  footprintAuditBundleId: Scalars['ID']['input'];
};

export type GQRegenerateUserSignupTokenInput = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQRegenerateUserSignupTokenPayload = {
  __typename?: 'RegenerateUserSignupTokenPayload';
  user: GQUser;
};

/** The region that the organization is in. This dictates where their data is stores and processed. */
export const GQRegion = {
  Eu: 'eu',
  Us: 'us',
} as const;

export type GQRegion = (typeof GQRegion)[keyof typeof GQRegion];
export type GQRegionAttribute = {
  __typename?: 'RegionAttribute';
  attribute: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQRegionFilter = {
  attribute: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type GQReingestCompanyIngestionDiffInput = {
  companyIngestionDiffIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  filters?: InputMaybe<GQCompanyIngestionRecordUpdateFilters>;
  override?: InputMaybe<GQCompanyOverrideMatchInput>;
};

export type GQReingestCompanyIngestionDiffPayload = {
  __typename?: 'ReingestCompanyIngestionDiffPayload';
  companyIngestionDiffs: Array<GQCompanyIngestionDiff>;
  companyIngestionRecordUpdates: Array<GQCompanyIngestionRecordUpdate>;
};

export type GQRejectDataInput = {
  comment: Scalars['String']['input'];
  targetId: Scalars['ID']['input'];
  targetKind: Scalars['String']['input'];
};

export type GQRejectDataPayload = {
  __typename?: 'RejectDataPayload';
  newChangelogEvents: Array<GQDataApprovalChangelogEvent>;
  target: GQUserUploadTask;
};

export type GQRelatedVectorsByGroup = {
  __typename?: 'RelatedVectorsByGroup';
  attributes: Array<GQRegionAttribute>;
  vectors: Array<GQVectorNameAndValues>;
};

export type GQReleaseEmissionsModelActiveDraftInput = {
  parentEmissionsModelVersionId: Scalars['ID']['input'];
};

export type GQReleaseEmissionsModelActiveDraftPayload =
  | GQReleaseEmissionsModelActiveDraftPayloadNonOp
  | GQReleaseEmissionsModelActiveDraftPayloadSuccess;

export type GQReleaseEmissionsModelActiveDraftPayloadNonOp = {
  __typename?: 'ReleaseEmissionsModelActiveDraftPayloadNonOp';
  message: Maybe<Scalars['String']['output']>;
};

export type GQReleaseEmissionsModelActiveDraftPayloadSuccess = {
  __typename?: 'ReleaseEmissionsModelActiveDraftPayloadSuccess';
  emissionsModelActiveDraftRecord: Maybe<GQEmissionsModelActiveDraft>;
};

export type GQRemoveCalculationTagFromReferenceDataInput = {
  sourceId: Scalars['ID']['input'];
  tagName: Scalars['String']['input'];
};

export type GQRemoveCalculationTagFromReferenceDataPayload = {
  __typename?: 'RemoveCalculationTagFromReferenceDataPayload';
  success: Scalars['Boolean']['output'];
};

export type GQRemoveCalculationTagFromStableModelInput = {
  stableId: Scalars['ID']['input'];
  tagName: Scalars['String']['input'];
};

export type GQRemoveCalculationTagFromStableModelPayload = {
  __typename?: 'RemoveCalculationTagFromStableModelPayload';
  success: Scalars['Boolean']['output'];
};

export type GQRemoveCompanyFromPeerCompanyGroupInput = {
  companyId: Scalars['ID']['input'];
  peerCompanyGroupId: Scalars['ID']['input'];
};

export type GQRemoveCompanyFromPeerCompanyGroupPayload = {
  __typename?: 'RemoveCompanyFromPeerCompanyGroupPayload';
  peerCompanyGroup: Maybe<GQPeerCompanyGroup>;
};

export type GQRemoveMarketplaceSupplierPointOfContactInput = {
  pointOfContactId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
};

export type GQRemoveMarketplaceSupplierPointOfContactPayload = {
  __typename?: 'RemoveMarketplaceSupplierPointOfContactPayload';
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQRemoveMarketplaceSupplierWatershedEmployeeInput = {
  supplierId: Scalars['ID']['input'];
  watershedEmployeeId: Scalars['ID']['input'];
};

export type GQRemoveMarketplaceSupplierWatershedEmployeePayload = {
  __typename?: 'RemoveMarketplaceSupplierWatershedEmployeePayload';
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQRemoveUserAdminVersionInput = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQRemoveUserAdminVersionPayload = {
  __typename?: 'RemoveUserAdminVersionPayload';
  user: GQUser;
};

export type GQRemoveUserInput = {
  userId: Scalars['ID']['input'];
};

export type GQRemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  user: GQUser;
};

export type GQRenameBiSavedViewInput = {
  name: Scalars['String']['input'];
  stableId: Scalars['ID']['input'];
};

export type GQRenameCustomEmissionsFactorSetupTaskInput = {
  newName?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['ID']['input'];
};

export type GQRenameCustomEmissionsFactorSetupTaskPayload = {
  __typename?: 'RenameCustomEmissionsFactorSetupTaskPayload';
  taskId: Scalars['ID']['output'];
};

export type GQRenameFootprintInput = {
  footprintId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type GQRenameFootprintPayload = {
  __typename?: 'RenameFootprintPayload';
  footprint: GQFootprintTemp;
};

export type GQRenameFootprintVersionInput = {
  footprintVersionId: Scalars['ID']['input'];
  newUserChangelogMd?: InputMaybe<Scalars['String']['input']>;
  newVersionName?: InputMaybe<Scalars['String']['input']>;
};

export type GQRenameFootprintVersionPayload = {
  __typename?: 'RenameFootprintVersionPayload';
  footprintVersion: GQFootprintVersion;
};

export type GQRenameReportInput = {
  newName: Scalars['String']['input'];
  reportId: Scalars['ID']['input'];
};

export type GQRenameReportPayload = {
  __typename?: 'RenameReportPayload';
  report: GQReport;
};

export type GQRenewableEnergyPercentagePoint = {
  __typename?: 'RenewableEnergyPercentagePoint';
  renewableEnergyFraction: Scalars['Float']['output'];
  yearStart: Scalars['YearMonth']['output'];
};

export type GQReplaceCompanyInput = {
  fromCompanyId: Scalars['ID']['input'];
  toCompanyId: Scalars['ID']['input'];
};

export type GQReplaceCompanyPayload = {
  __typename?: 'ReplaceCompanyPayload';
  fromCompany: GQCompany;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  toCompany: GQCompany;
};

export type GQReplaceReportingCdpMappingsColumnMappingInput = {
  cdpRefId: Scalars['String']['input'];
  otherColumnIdMapping?: InputMaybe<Scalars['String']['input']>;
  watershedColumnId: Scalars['String']['input'];
};

export type GQReplaceReportingCdpMappingsInput = {
  columnIdMappings: Array<GQReplaceReportingCdpMappingsColumnMappingInput>;
  questionMappings: Array<GQReplaceReportingCdpMappingsQuestionMappingInput>;
};

export type GQReplaceReportingCdpMappingsPayload = {
  __typename?: 'ReplaceReportingCdpMappingsPayload';
  mappings: GQCdpIdMappingsPayload;
  success: Scalars['Boolean']['output'];
};

export type GQReplaceReportingCdpMappingsQuestionMappingInput = {
  cdpRefId: Scalars['String']['input'];
  reportQuestionIdentifierId: Scalars['ID']['input'];
};

export type GQReport = GQClimateProgramTimelineObject &
  GQIdInterface & {
    __typename?: 'Report';
    config: GQReportConfig;
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    dmaId: Maybe<Scalars['ID']['output']>;
    downloadableDocxUrl: Scalars['String']['output'];
    downloadableXlsxUrl: Scalars['String']['output'];
    footprintSnapshot: Maybe<GQFootprintSnapshot>;
    id: Scalars['ID']['output'];
    isHidden: Maybe<Scalars['Boolean']['output']>;
    isMigratedFromStaticConfig: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    organization: GQOrganization;
    publishedAt: Maybe<Scalars['DateTime']['output']>;
    reportKind: Scalars['String']['output'];
    reportStatus: GQReportStatus;
    updatedAt: Scalars['DateTime']['output'];
    warnings: Array<GQReportAnswerWarning>;
  };

export type GQReportAllQuestionsInput = {
  includeComputedItems?: InputMaybe<Scalars['Boolean']['input']>;
  includeInputs?: InputMaybe<Scalars['Boolean']['input']>;
  includeItems?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQReportAnswer = {
  __typename?: 'ReportAnswer';
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  editorName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inputJson: Scalars['JSONString']['output'];
  outputJson: Maybe<Scalars['JSONString']['output']>;
  question: GQReportQuestion;
  report: GQReport;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQReportAnswerConnection = {
  __typename?: 'ReportAnswerConnection';
  edges: Array<Maybe<GQReportAnswerEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportAnswerEdge = {
  __typename?: 'ReportAnswerEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportAnswer>;
};

export type GQReportAnswerFilterInput = {
  componentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orgIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reportTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  skipApprovedFailures: Scalars['Boolean']['input'];
};

export type GQReportAnswerFilters = {
  components?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeDemoOrgs?: InputMaybe<Scalars['Boolean']['input']>;
  excludeTestOrgs?: InputMaybe<Scalars['Boolean']['input']>;
  identifierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  orgIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reportConfigIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reportQuestionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQReportAnswerHistoryConnection = {
  __typename?: 'ReportAnswerHistoryConnection';
  edges: Array<Maybe<GQReportAnswerHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportAnswerHistoryEdge = {
  __typename?: 'ReportAnswerHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportAnswer>;
};

export type GQReportAnswerVerifierFailure = GQIdInterface & {
  __typename?: 'ReportAnswerVerifierFailure';
  errorJson: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  mismatchApprovalTime: Maybe<Scalars['DateTime']['output']>;
  mismatchTime: Scalars['DateTime']['output'];
  orgId: Scalars['ID']['output'];
  organization: GQOrganization;
  outputJson: Maybe<Scalars['JSONString']['output']>;
  reportAnswer: GQReportAnswer;
  reportAnswerUpdatedAt: Scalars['DateTime']['output'];
};

export type GQReportAnswerVerifierFailureConnection = {
  __typename?: 'ReportAnswerVerifierFailureConnection';
  edges: Array<Maybe<GQReportAnswerVerifierFailureEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportAnswerVerifierFailureEdge = {
  __typename?: 'ReportAnswerVerifierFailureEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportAnswerVerifierFailure>;
};

export type GQReportAnswerVerifierOptions = {
  __typename?: 'ReportAnswerVerifierOptions';
  componentIds: Maybe<Array<Scalars['String']['output']>>;
  organizations: Array<GQOrganization>;
  reportTypes: Maybe<Array<Scalars['String']['output']>>;
};

export type GQReportAnswerWarning = {
  __typename?: 'ReportAnswerWarning';
  acknowledgedBy: Maybe<GQUser>;
  answer: GQReportAnswer;
  id: Scalars['ID']['output'];
  outputJson: Scalars['JSONString']['output'];
  reportId: Scalars['String']['output'];
  validatorId: Scalars['String']['output'];
};

export type GQReportAttachment = {
  __typename?: 'ReportAttachment';
  authorId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  reportId: Scalars['ID']['output'];
  reportQuestionId: Scalars['ID']['output'];
};

export type GQReportAttachmentItem = {
  __typename?: 'ReportAttachmentItem';
  authorId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  fmdId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  kind: GQReportAttachmentItemKind;
  name: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export const GQReportAttachmentItemKind = {
  File: 'File',
  Url: 'Url',
} as const;

export type GQReportAttachmentItemKind =
  (typeof GQReportAttachmentItemKind)[keyof typeof GQReportAttachmentItemKind];
export const GQReportCalloutIcon = {
  Error: 'Error',
  Info: 'Info',
  Question: 'Question',
  Warning: 'Warning',
} as const;

export type GQReportCalloutIcon =
  (typeof GQReportCalloutIcon)[keyof typeof GQReportCalloutIcon];
export type GQReportConfig = {
  __typename?: 'ReportConfig';
  allContainers: Array<GQReportQuestionContainer>;
  allQuestions: Array<GQReportQuestion>;
  brandColor: Scalars['String']['output'];
  computedItemIds: Array<Scalars['ID']['output']>;
  computedItems: Array<GQReportQuestion>;
  containers: Array<GQReportQuestionContainer>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  /** @deprecated Unused field */
  hasReductionPlan: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  inputIds: Array<Scalars['ID']['output']>;
  inputs: Array<GQReportQuestion>;
  isCorporateReport: Scalars['Boolean']['output'];
  isCreationBlocked: Scalars['Boolean']['output'];
  /** @deprecated Unused field */
  isDirectEditLocked: Maybe<Scalars['Boolean']['output']>;
  isFinanceReport: Scalars['Boolean']['output'];
  isHiddenFromOverview: Scalars['Boolean']['output'];
  isRegulatoryExposureBlocked: Scalars['Boolean']['output'];
  itemIds: Array<Scalars['ID']['output']>;
  /** @deprecated Use containers or questions instead. */
  items: Array<GQReportQuestionOrContainer>;
  lastLintedAt: Maybe<Scalars['DateTime']['output']>;
  latestComponentUpdatedAt: Maybe<Scalars['DateTime']['output']>;
  latestLinterStatus: Maybe<GQConfigLinterStatus>;
  longName: Scalars['String']['output'];
  questions: Array<GQReportQuestion>;
  reportObjectives: Array<GQCanonicalReportObjective>;
  reportType: Scalars['String']['output'];
  requiredPermissions: Array<GQPermissionType>;
  shortName: Scalars['String']['output'];
  status: Maybe<GQReportConfigStatus>;
  updatedAt: Scalars['DateTime']['output'];
  useReportingIntention: Maybe<Scalars['Boolean']['output']>;
  useTableDisplay: Maybe<Scalars['Boolean']['output']>;
};

export type GQReportConfigAllQuestionsArgs = {
  input: InputMaybe<GQReportAllQuestionsInput>;
};

export type GQReportConfigComputedItemsArgs = {
  filter: InputMaybe<GQReportQuestionFilter>;
};

export type GQReportConfigLatestComponentUpdatedAtArgs = {
  id: Scalars['ID']['input'];
};

export type GQReportConfigConnection = {
  __typename?: 'ReportConfigConnection';
  edges: Array<Maybe<GQReportConfigEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportConfigEdge = {
  __typename?: 'ReportConfigEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportConfig>;
};

export type GQReportConfigImportBehaviorFlagsInput = {
  deleteUnusedItems: Scalars['Boolean']['input'];
  modifyExistingItems: Scalars['Boolean']['input'];
};

export type GQReportConfigSrcPayload = {
  __typename?: 'ReportConfigSrcPayload';
  lintStatus: GQConfigLinterStatus;
  src: Scalars['JSONString']['output'];
};

export const GQReportConfigStatus = {
  Beta: 'Beta',
  ComingSoon: 'ComingSoon',
  Inactive: 'Inactive',
  New: 'New',
} as const;

export type GQReportConfigStatus =
  (typeof GQReportConfigStatus)[keyof typeof GQReportConfigStatus];
export type GQReportConnection = {
  __typename?: 'ReportConnection';
  edges: Array<Maybe<GQReportEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportEdge = {
  __typename?: 'ReportEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReport>;
};

export type GQReportExplanation = {
  __typename?: 'ReportExplanation';
  markdownText: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type GQReportInput = {
  __typename?: 'ReportInput';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQReportItemCallout = GQIReportItem & {
  __typename?: 'ReportItemCallout';
  callout: Maybe<Scalars['String']['output']>;
  icon: Maybe<GQReportCalloutIcon>;
  id: Scalars['ID']['output'];
  isMarkdown: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  sublabel: Maybe<Scalars['String']['output']>;
};

export const GQReportItemKind = {
  Callout: 'CALLOUT',
  SecrStandardTable: 'SECR_STANDARD_TABLE',
  SecrTable: 'SECR_TABLE',
  SingleValue: 'SINGLE_VALUE',
  StandardTable: 'STANDARD_TABLE',
} as const;

export type GQReportItemKind =
  (typeof GQReportItemKind)[keyof typeof GQReportItemKind];
export type GQReportItemSecrStandardTable = GQIReportItem & {
  __typename?: 'ReportItemSecrStandardTable';
  id: Scalars['ID']['output'];
  isFullWidth: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  rows: Array<GQReportTableRow>;
  sublabel: Maybe<Scalars['String']['output']>;
};

export type GQReportItemSingleValue = GQIReportItem & {
  __typename?: 'ReportItemSingleValue';
  explanation: Maybe<GQReportExplanation>;
  id: Scalars['ID']['output'];
  isFullWidth: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  sublabel: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type GQReportItemStandardTable = GQIReportItem & {
  __typename?: 'ReportItemStandardTable';
  columns: Array<GQReportTableColumn>;
  id: Scalars['ID']['output'];
  isFullWidth: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  rows: Array<GQReportTableRow>;
  sublabel: Maybe<Scalars['String']['output']>;
};

export type GQReportKindWithReason = {
  __typename?: 'ReportKindWithReason';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  reason: Maybe<Scalars['String']['output']>;
  reportingYear: Maybe<Scalars['Int']['output']>;
};

export type GQReportObjectConfigPathItem = {
  __typename?: 'ReportObjectConfigPathItem';
  id: Maybe<Scalars['ID']['output']>;
  isComputed: Scalars['Boolean']['output'];
  isInput: Scalars['Boolean']['output'];
  label: Maybe<Scalars['String']['output']>;
};

export const GQReportObjectType = {
  Module: 'module',
  Question: 'question',
} as const;

export type GQReportObjectType =
  (typeof GQReportObjectType)[keyof typeof GQReportObjectType];
export type GQReportProgressInput = {
  reportKind: Scalars['String']['input'];
};

export type GQReportProgressPayload = {
  __typename?: 'ReportProgressPayload';
  id: Scalars['ID']['output'];
  percentComplete: Scalars['Float']['output'];
  reasons: Array<GQReportProgressReason>;
};

export const GQReportProgressReason = {
  CompletedReportBefore: 'CompletedReportBefore',
  HasDraftFootprint: 'HasDraftFootprint',
  HasPublishedFootprint: 'HasPublishedFootprint',
  ReportInProgress: 'ReportInProgress',
  WorkedOnSimilarReport: 'WorkedOnSimilarReport',
} as const;

export type GQReportProgressReason =
  (typeof GQReportProgressReason)[keyof typeof GQReportProgressReason];
export type GQReportQuestion = {
  __typename?: 'ReportQuestion';
  allowNotes: Scalars['Boolean']['output'];
  allowedAttachmentKinds: Maybe<Array<GQReportAttachmentItemKind>>;
  answer: Maybe<GQReportAnswer>;
  assignees: Array<GQUser>;
  cdpSyncHistory: GQCdpSyncQuestionHistoryConnection;
  componentId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  dependentItems: GQDependentReportItems;
  /** @deprecated use parentContainer label instead */
  displayName: Maybe<Scalars['String']['output']>;
  dynamicInputJson: Maybe<Scalars['JSONString']['output']>;
  guidanceJson: Maybe<Scalars['JSONString']['output']>;
  history: GQReportAnswerHistoryConnection;
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  identifierId: Scalars['String']['output'];
  isComputed: Scalars['Boolean']['output'];
  isDynamic: Maybe<Scalars['Boolean']['output']>;
  notes: Maybe<GQDiscussion>;
  parentContainer: Maybe<GQReportQuestionContainer>;
  path: Array<GQReportObjectConfigPathItem>;
  peerExternalReportAnswers: Maybe<GQPeerExternalReportAnswers>;
  questionsWithMatchingOutputSchema: Array<GQReportQuestion>;
  reportConfig: GQReportConfig;
  reportConfigId: Scalars['ID']['output'];
  resolvedInputs: GQResolvedReportQuestionInputs;
  /** @deprecated Use answer instead. */
  resolvedOutputJson: Maybe<Scalars['JSONString']['output']>;
  resolvedReportingIntention: GQResolvedReportingIntention;
  resolvedStatus: GQResolvedReportQuestionStatus;
  resolvedStatusIncludingContainer: GQResolvedReportQuestionStatus;
  resolvedWarnings: Array<GQReportAnswerWarning>;
  staticInputJson: Maybe<Scalars['JSONString']['output']>;
  statusJson: Scalars['JSONString']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQReportQuestionAnswerArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionAssigneesArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionCdpSyncHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionDependentItemsArgs = {
  filter: InputMaybe<GQDependentQuestionFilter>;
};

export type GQReportQuestionHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionNotesArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedInputsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedOutputJsonArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedReportingIntentionArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedStatusArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedStatusIncludingContainerArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedWarningsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionAssignee = {
  __typename?: 'ReportQuestionAssignee';
  assignee: GQUser;
  id: Scalars['ID']['output'];
  questionId: Scalars['ID']['output'];
  reportId: Scalars['ID']['output'];
};

export type GQReportQuestionContainer = {
  __typename?: 'ReportQuestionContainer';
  assignees: Array<GQUser>;
  automaticProgress: GQResolvedContainerProgress;
  containers: Array<GQReportQuestionContainer>;
  description: Maybe<Scalars['String']['output']>;
  fullWidth: Scalars['Boolean']['output'];
  guidanceJson: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  itemIds: Array<Scalars['ID']['output']>;
  items: Array<GQReportQuestionOrContainer>;
  label: Maybe<Scalars['String']['output']>;
  overriddenProgress: Maybe<GQResolvedContainerProgress>;
  parentContainer: Maybe<GQReportQuestionContainer>;
  questionsWithResolvedWarnings: Array<GQReportQuestion>;
  reportConfig: GQReportConfig;
  reportConfigId: Scalars['ID']['output'];
  resolvedStatus: GQResolvedReportQuestionStatus;
  statusJson: Scalars['JSONString']['output'];
};

export type GQReportQuestionContainerAssigneesArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerAutomaticProgressArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerOverriddenProgressArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerQuestionsWithResolvedWarningsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerResolvedStatusArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionFilter = {
  componentId?: InputMaybe<Scalars['String']['input']>;
};

export type GQReportQuestionIdentifier = {
  __typename?: 'ReportQuestionIdentifier';
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
};

export type GQReportQuestionIdentifierConnection = {
  __typename?: 'ReportQuestionIdentifierConnection';
  edges: Array<Maybe<GQReportQuestionIdentifierEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportQuestionIdentifierEdge = {
  __typename?: 'ReportQuestionIdentifierEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportQuestionIdentifier>;
};

export type GQReportQuestionIdentifierMapping = {
  __typename?: 'ReportQuestionIdentifierMapping';
  id: Scalars['ID']['output'];
  questionIdentifier: GQReportQuestionIdentifier;
  relatedQuestionIdentifiers: Array<GQReportQuestionIdentifier>;
};

export type GQReportQuestionInstance = {
  reportId?: InputMaybe<Scalars['String']['input']>;
  reportQuestionId?: InputMaybe<Scalars['String']['input']>;
};

export type GQReportQuestionInstancePayload = {
  __typename?: 'ReportQuestionInstancePayload';
  reportId: Maybe<Scalars['String']['output']>;
  reportQuestionId: Maybe<Scalars['String']['output']>;
};

export const GQReportQuestionNoteItemStatus = {
  Done: 'Done',
  NeedsUserResponse: 'NeedsUserResponse',
} as const;

export type GQReportQuestionNoteItemStatus =
  (typeof GQReportQuestionNoteItemStatus)[keyof typeof GQReportQuestionNoteItemStatus];
export type GQReportQuestionOrContainer =
  | GQReportQuestion
  | GQReportQuestionContainer;

export type GQReportQuestionSeriesMetric = {
  __typename?: 'ReportQuestionSeriesMetric';
  series: Maybe<Array<GQReportQuestionSeriesMetricItem>>;
};

export type GQReportQuestionSeriesMetricItem = {
  __typename?: 'ReportQuestionSeriesMetricItem';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQReportQuestionSingleValueMetric = {
  __typename?: 'ReportQuestionSingleValueMetric';
  value: Maybe<Scalars['String']['output']>;
};

export const GQReportQuestionStatus = {
  Blocked: 'Blocked',
  Excluded: 'Excluded',
  Optional: 'Optional',
  Required: 'Required',
} as const;

export type GQReportQuestionStatus =
  (typeof GQReportQuestionStatus)[keyof typeof GQReportQuestionStatus];
export type GQReportSection = {
  __typename?: 'ReportSection';
  id: Scalars['ID']['output'];
  isCollapsible: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  items: Array<
    | GQReportItemCallout
    | GQReportItemSecrStandardTable
    | GQReportItemSingleValue
    | GQReportItemStandardTable
  >;
  label: Maybe<Scalars['String']['output']>;
  sectionId: Scalars['String']['output'];
  sublabel: Maybe<Scalars['String']['output']>;
};

export const GQReportStatus = {
  Editing: 'Editing',
  Failed: 'Failed',
  Locked: 'Locked',
  Processing: 'Processing',
  Published: 'Published',
  Submitted: 'Submitted',
} as const;

export type GQReportStatus =
  (typeof GQReportStatus)[keyof typeof GQReportStatus];
export type GQReportTableColumn = {
  __typename?: 'ReportTableColumn';
  alignment: Maybe<GQTableCellAlignment>;
  isMarkdown: Maybe<Scalars['Boolean']['output']>;
  label: Maybe<Scalars['String']['output']>;
  minWidthPx: Maybe<Scalars['Int']['output']>;
};

export type GQReportTableRow = {
  __typename?: 'ReportTableRow';
  colspans: Maybe<Array<Scalars['Int']['output']>>;
  explanations: Maybe<Array<Maybe<GQReportExplanation>>>;
  strong: Maybe<Scalars['Boolean']['output']>;
  values: Array<Scalars['String']['output']>;
};

export type GQReportWithConfig = {
  __typename?: 'ReportWithConfig';
  config: GQReportConfig;
  id: Scalars['ID']['output'];
  report: GQReport;
};

export type GQReportingFootprintHealthCheckFailure = {
  __typename?: 'ReportingFootprintHealthCheckFailure';
  outputJson: Scalars['JSONString']['output'];
  validatorId: Scalars['String']['output'];
};

export type GQReportingFootprintHealthChecksInput = {
  footprintSnapshotId?: InputMaybe<Scalars['ID']['input']>;
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQReportingFootprintHealthChecksPayload = {
  __typename?: 'ReportingFootprintHealthChecksPayload';
  failures: Array<GQReportingFootprintHealthCheckFailure>;
  footprintSnapshotId: Scalars['ID']['output'];
  interval: Scalars['YMInterval']['output'];
};

export const GQReportingIntention = {
  IsNotReporting: 'IsNotReporting',
  IsReporting: 'IsReporting',
} as const;

export type GQReportingIntention =
  (typeof GQReportingIntention)[keyof typeof GQReportingIntention];
export type GQReportingIntentionLogEvent = {
  __typename?: 'ReportingIntentionLogEvent';
  createdAt: Scalars['DateTime']['output'];
  createdBy: GQUser;
  reason: Maybe<Scalars['String']['output']>;
};

export type GQRepresentativeNaicsCode = GQIdInterface & {
  __typename?: 'RepresentativeNaicsCode';
  companyId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  naicsCode: Scalars['String']['output'];
};

export type GQRepresentativeNaicsCodeConnection = {
  __typename?: 'RepresentativeNaicsCodeConnection';
  edges: Array<Maybe<GQRepresentativeNaicsCodeEdge>>;
  pageInfo: GQPageInfo;
};

export type GQRepresentativeNaicsCodeEdge = {
  __typename?: 'RepresentativeNaicsCodeEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQRepresentativeNaicsCode>;
};

export type GQRequestDataApprovalInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  targetId: Scalars['ID']['input'];
  targetKind: Scalars['String']['input'];
};

export type GQRequestDataApprovalPayload = {
  __typename?: 'RequestDataApprovalPayload';
  newChangelogEvents: Array<GQDataApprovalChangelogEvent>;
  target: GQUserUploadTask;
};

export type GQRequestHelpInput = {
  message: Scalars['String']['input'];
};

export type GQRequestHelpPayload = {
  __typename?: 'RequestHelpPayload';
  status: Scalars['String']['output'];
};

export type GQRequestUnlockDataInput = {
  comment: Scalars['String']['input'];
  targetId: Scalars['ID']['input'];
  targetKind: Scalars['String']['input'];
};

export type GQRequestUnlockDataPayload = {
  __typename?: 'RequestUnlockDataPayload';
  newChangelogEvents: Array<GQDataApprovalChangelogEvent>;
  target: GQUserUploadTask;
};

export type GQRerunBartForPresetTagWorkflowInput = {
  ingestionCustomFieldDatasourceId: Scalars['ID']['input'];
};

export type GQRerunBartForPresetTagWorkflowOutput = {
  __typename?: 'RerunBartForPresetTagWorkflowOutput';
  userUploadTasks: Array<GQUserUploadTask>;
  workflowId: Scalars['String']['output'];
};

export const GQResetFootprintCustomAction = {
  AddDataIssue: 'AddDataIssue',
  AddEmployeeReport: 'AddEmployeeReport',
  AddFootprintDisclosure: 'AddFootprintDisclosure',
  AddMeasurementVerificationItems: 'AddMeasurementVerificationItems',
  AddTask: 'AddTask',
  BlankSlate: 'BlankSlate',
  BlankSlateCsrd: 'BlankSlateCsrd',
  FullReset: 'FullReset',
  ResetButKeepFootprint: 'ResetButKeepFootprint',
  ResetCompanyFacts: 'ResetCompanyFacts',
  ResetDemoFund: 'ResetDemoFund',
  ResetDemoFundBlank: 'ResetDemoFundBlank',
  ResetDemoPlatform: 'ResetDemoPlatform',
  ResetPartialDraft: 'ResetPartialDraft',
  ResetReductionPlans: 'ResetReductionPlans',
  ResetWithCsrdDemoData: 'ResetWithCsrdDemoData',
  ResetWithSuppliersDemoData50: 'ResetWithSuppliersDemoData50',
  ResetWithSuppliersDemoData5000: 'ResetWithSuppliersDemoData5000',
  ResetWithSuppliersDemoData10000: 'ResetWithSuppliersDemoData10000',
  ResetWithSuppliersDemoData15000: 'ResetWithSuppliersDemoData15000',
} as const;

export type GQResetFootprintCustomAction =
  (typeof GQResetFootprintCustomAction)[keyof typeof GQResetFootprintCustomAction];
export const GQResetFootprintCustomActionSubset = {
  FullReset: 'FullReset',
  ResetDemoFund: 'ResetDemoFund',
  ResetWithCsrdDemoData: 'ResetWithCsrdDemoData',
} as const;

export type GQResetFootprintCustomActionSubset =
  (typeof GQResetFootprintCustomActionSubset)[keyof typeof GQResetFootprintCustomActionSubset];
export type GQResetFootprintExclusionSuggestionsInput = {
  footprintSnapshotId: Scalars['ID']['input'];
};

export type GQResetFootprintExclusionSuggestionsPayload = {
  __typename?: 'ResetFootprintExclusionSuggestionsPayload';
  footprintSnapshotId: Scalars['ID']['output'];
};

export type GQResetLockedUserAdminVersionInput = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQResetLockedUserAdminVersionPayload = {
  __typename?: 'ResetLockedUserAdminVersionPayload';
  user: GQUser;
};

export type GQResetLockedUserInput = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQResetLockedUserPayload = {
  __typename?: 'ResetLockedUserPayload';
  user: GQUser;
};

export type GQResolveDataIssueInput = {
  id: Scalars['ID']['input'];
};

export type GQResolveDataIssuePayload = {
  __typename?: 'ResolveDataIssuePayload';
  issue: GQDataIssue;
};

export type GQResolveDiscussionInput = {
  discussionId: Scalars['ID']['input'];
};

export type GQResolveDiscussionPayload = {
  __typename?: 'ResolveDiscussionPayload';
  discussion: GQDiscussion;
};

export const GQResolvedContainerProgress = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  NotApplicable: 'NotApplicable',
  NotStarted: 'NotStarted',
  OptedOut: 'OptedOut',
} as const;

export type GQResolvedContainerProgress =
  (typeof GQResolvedContainerProgress)[keyof typeof GQResolvedContainerProgress];
export type GQResolvedReportQuestionInputs =
  | GQResolvedReportQuestionInputsFailure
  | GQResolvedReportQuestionInputsSuccess;

export type GQResolvedReportQuestionInputsFailure = {
  __typename?: 'ResolvedReportQuestionInputsFailure';
  errorMessage: Maybe<Scalars['String']['output']>;
  questionIds: Array<Scalars['ID']['output']>;
};

export type GQResolvedReportQuestionInputsSuccess = {
  __typename?: 'ResolvedReportQuestionInputsSuccess';
  inputs: Scalars['JSONString']['output'];
  unparsedInputs: Scalars['JSONString']['output'];
};

export type GQResolvedReportQuestionStatus = {
  __typename?: 'ResolvedReportQuestionStatus';
  blockingMessage: Maybe<Scalars['String']['output']>;
  excludingQuestionId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  status: GQReportQuestionStatus;
};

export type GQResolvedReportingIntention = {
  __typename?: 'ResolvedReportingIntention';
  event: Maybe<GQReportingIntentionLogEvent>;
  id: Scalars['ID']['output'];
  intention: GQReportingIntention;
};

export type GQRespondToDataIssueInput = {
  id: Scalars['ID']['input'];
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  message: Scalars['String']['input'];
  shouldNotifyWatershed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQRespondToDataIssuePayload = {
  __typename?: 'RespondToDataIssuePayload';
  issue: GQDataIssue;
  measurementTask: GQMeasurementParentTask | GQMeasurementTaskFlat;
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQRespondToDiscussionInput = {
  discussionId: Scalars['ID']['input'];
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  message: Scalars['String']['input'];
  shouldNotifyWatershed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQRespondToDiscussionPayload = {
  __typename?: 'RespondToDiscussionPayload';
  discussion: GQDiscussion;
};

export type GQRestoreDeletedSavedViewInput = {
  stableId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQRestoreReportAdminPayload = {
  __typename?: 'RestoreReportAdminPayload';
  report: GQReport;
};

export type GQRestoreReportInput = {
  reportId: Scalars['ID']['input'];
};

export type GQRevenueSourceOption = {
  __typename?: 'RevenueSourceOption';
  currencyCode: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  revenue: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

export type GQReviewFinancialsReviewItemInput = {
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  reviewItemId: Scalars['ID']['input'];
};

export type GQReviewFinancialsReviewItemPayload = {
  __typename?: 'ReviewFinancialsReviewItemPayload';
  financialsUserUploadTasks: Array<GQUserUploadTask>;
  measurementTask: GQMeasurementParentTask | GQMeasurementTaskFlat;
  reviewItem: GQFinancialsReviewItem;
};

export type GQReviewPrepublicationTestResultsForEmissionsModelPayload = {
  __typename?: 'ReviewPrepublicationTestResultsForEmissionsModelPayload';
  errorCode: Maybe<GQMeasurementResourcePublicationErrorCode>;
  outcome: GQMeasurementResourcePublishWorkflowOutcome;
  publishedEmissionsModelMatchers: Maybe<Array<GQEmissionsModelMatcher>>;
  publishedEmissionsModelVersion: Maybe<GQEmissionsModelVersion>;
};

export type GQReviewPrepublicationTestResultsForReferenceDataRevisionPayload = {
  __typename?: 'ReviewPrepublicationTestResultsForReferenceDataRevisionPayload';
  errorCode: Maybe<GQMeasurementResourcePublicationErrorCode>;
  outcome: GQMeasurementResourcePublishWorkflowOutcome;
  publishedReferenceDataRevision: Maybe<GQReferenceDataRevision>;
};

export type GQReviewPrepublicationTestResultsForReleasePayload = {
  __typename?: 'ReviewPrepublicationTestResultsForReleasePayload';
  errorCode: Maybe<GQMeasurementResourcePublicationErrorCode>;
  outcome: GQMeasurementResourcePublishWorkflowOutcome;
  publishedRelease: Maybe<GQEmissionsModelRelease>;
};

export type GQReviewPrepublicationTestResultsInput = {
  acceptResultsAndPublish: Scalars['Boolean']['input'];
  approvedTestSuiteIds: Array<Scalars['String']['input']>;
  workflowId: Scalars['String']['input'];
};

export type GQRevokePermissionItemInput = {
  permissionItemId: Scalars['ID']['input'];
};

export type GQRevokePermissionItemPayload = {
  __typename?: 'RevokePermissionItemPayload';
  permissionItem: GQPermissionItem;
};

export type GQRevokeUserAppSessionsInput = {
  userId: Scalars['ID']['input'];
};

export type GQRevokeUserAppSessionsPayload = {
  __typename?: 'RevokeUserAppSessionsPayload';
  user: GQUser;
};

export type GQRevokeUserRoleInput = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQRevokeUserRolePayload = {
  __typename?: 'RevokeUserRolePayload';
  userRoleAssignment: Maybe<GQUserRoleAssignment>;
};

export type GQRole = {
  __typename?: 'Role';
  apiKeys: Array<GQApiKeyRoleAssignment>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: GQUser;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  org: GQOrganization;
  permissions: Array<GQPermissionItem>;
  updatedAt: Scalars['DateTime']['output'];
  users: Array<GQUserRoleAssignment>;
  watershedManaged: Scalars['Boolean']['output'];
};

export type GQRoleConnection = {
  __typename?: 'RoleConnection';
  edges: Array<Maybe<GQRoleEdge>>;
  pageInfo: GQPageInfo;
};

export type GQRoleEdge = {
  __typename?: 'RoleEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQRole>;
};

export type GQRowPreviewData = {
  __typename?: 'RowPreviewData';
  data: Scalars['UntypedData']['output'];
  id: Scalars['String']['output'];
};

export type GQRunAdvancedEnergyEstimationInput = {
  estimationFactors: GQEnergyEstimationFactors;
  forecastYear: Scalars['Int']['input'];
  orgId: Scalars['ID']['input'];
  startMonth: Scalars['Int']['input'];
};

export type GQRunAdvancedEnergyEstimationPayload = {
  __typename?: 'RunAdvancedEnergyEstimationPayload';
  countryProjectedEnergyConsumptions: Array<GQCountryProjectedEnergyConsumption>;
  jobId: Scalars['String']['output'];
  jobStatus: Scalars['String']['output'];
};

export type GQRunCeeForEngagementTaskInput = {
  engagementTaskId: Scalars['ID']['input'];
};

export type GQRunCeeForEngagementTaskPayload = {
  __typename?: 'RunCeeForEngagementTaskPayload';
  ceeJobId: Maybe<Scalars['ID']['output']>;
  engagementTask: Maybe<GQEngagementTask>;
};

export type GQRunCtsTransformEditorDashboardInput = {
  businessActivityTypeName?: InputMaybe<Scalars['String']['input']>;
  businessActivityTypeVersionId?: InputMaybe<Scalars['String']['input']>;
  inputFileMetadataId?: InputMaybe<Scalars['ID']['input']>;
  parentId: Scalars['ID']['input'];
  parentType: GQCustomerTargetSchemaTransformParentType;
  sql: Scalars['String']['input'];
  transformTarget: GQCustomerTargetSchemaTransformTarget;
};

export type GQRunCtsTransformEditorPayload = {
  __typename?: 'RunCtsTransformEditorPayload';
  workflowId: Scalars['String']['output'];
};

export type GQRunCtsTransformTestCaseInput = {
  ctsTransformTestCaseId: Scalars['ID']['input'];
};

export type GQRunCtsVersionTestInput = {
  ctsVersionId: Scalars['ID']['input'];
  fileMetadataId: Scalars['ID']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
};

export type GQRunDataRegistryMigrationInput = {
  description: Scalars['String']['input'];
  draftMode?: InputMaybe<Scalars['Boolean']['input']>;
  fromSchemaVersionId: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  toSchemaVersionId: Scalars['ID']['input'];
};

export type GQRunDataRegistryMigrationPayload = {
  __typename?: 'RunDataRegistryMigrationPayload';
  dataRegistryBatchMigratorRun: GQDataRegistryBatchMigratorRun;
};

export type GQRunDemoPlatformInput = {
  demoPlatformConfig?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQRunDemoPlatformPayload = {
  __typename?: 'RunDemoPlatformPayload';
  demoPlatformRunId: Scalars['ID']['output'];
  jobId: Scalars['ID']['output'];
};

export type GQRunExtractorInput = {
  activityType?: InputMaybe<Scalars['String']['input']>;
  adtId: Scalars['ID']['input'];
  kind: GQActivityDataExtractorKind;
  orgId: Scalars['ID']['input'];
  sql: Scalars['String']['input'];
  userUploadedTableIds: Array<Scalars['String']['input']>;
};

export type GQRunExtractorPayload = {
  __typename?: 'RunExtractorPayload';
  run: GQExtractorRun;
};

export type GQRunPlaygroundSqlInput = {
  duckDbInstanceKind?: InputMaybe<GQDuckDbInstanceKind>;
  orgId: Scalars['ID']['input'];
  sql: Scalars['String']['input'];
};

export type GQRunPlaygroundSqlPayload = {
  __typename?: 'RunPlaygroundSqlPayload';
  run: GQPlaygroundSqlRun;
};

export type GQRunPleeForEngagementTaskInput = {
  engagementTaskId: Scalars['ID']['input'];
};

export type GQRunPleeForEngagementTaskPayload = {
  __typename?: 'RunPleeForEngagementTaskPayload';
  engagementTask: Maybe<GQEngagementTask>;
};

export type GQRunReportAnswerVerifierInput = {
  reportAnswerId: Scalars['ID']['input'];
};

export type GQRunReportAnswerVerifierPayload = {
  __typename?: 'RunReportAnswerVerifierPayload';
  reportAnswerId: Scalars['ID']['output'];
  reportAnswerVerifierFailure: Maybe<GQReportAnswerVerifierFailure>;
};

export type GQRunSchemaValidationForReferenceDataVersionInput = {
  versionId: Scalars['String']['input'];
};

export type GQRunSchemaValidationForReferenceDataVersionPayload = {
  __typename?: 'RunSchemaValidationForReferenceDataVersionPayload';
  workflowId: Scalars['String']['output'];
};

export type GQSbtCommitment = {
  __typename?: 'SBTCommitment';
  baselineYear: Maybe<Scalars['Int']['output']>;
  commitmentDeadline: Maybe<Scalars['Date']['output']>;
  commitmentType: Maybe<GQSbtCommitmentType>;
  id: Scalars['ID']['output'];
  longTermTargetYear: Maybe<Scalars['Int']['output']>;
  nearTermTargetYear: Maybe<Scalars['Int']['output']>;
  netZeroCommitted: Maybe<Scalars['Boolean']['output']>;
  netZeroTargetYear: Maybe<Scalars['Int']['output']>;
  stage: Maybe<GQSbtCommitmentStage>;
  targetClassification: Maybe<GQSbtTargetClassification>;
};

export type GQSbtCommitmentInput = {
  baselineYear?: InputMaybe<Scalars['Int']['input']>;
  commitmentDeadline?: InputMaybe<Scalars['Date']['input']>;
  commitmentType?: InputMaybe<GQSbtCommitmentType>;
  longTermTargetYear?: InputMaybe<Scalars['Int']['input']>;
  nearTermTargetYear?: InputMaybe<Scalars['Int']['input']>;
  netZeroTargetYear?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<GQSbtCommitmentStage>;
  targetClassification?: InputMaybe<GQSbtTargetClassification>;
};

export const GQSbtScope1And2Target = {
  OnePointFiveC: 'OnePointFiveC',
  WellBelowTwoC: 'WellBelowTwoC',
} as const;

export type GQSbtScope1And2Target =
  (typeof GQSbtScope1And2Target)[keyof typeof GQSbtScope1And2Target];
export const GQSbtScope3Target = {
  Geva7: 'GEVA7',
  OnePointFiveC: 'OnePointFiveC',
  TwoC: 'TwoC',
  WellBelowTwoC: 'WellBelowTwoC',
} as const;

export type GQSbtScope3Target =
  (typeof GQSbtScope3Target)[keyof typeof GQSbtScope3Target];
export const GQSbtTermLength = {
  LongTerm: 'LongTerm',
  NearTerm: 'NearTerm',
} as const;

export type GQSbtTermLength =
  (typeof GQSbtTermLength)[keyof typeof GQSbtTermLength];
export type GQSdiIngestionInstructionStepInput = {
  descriptionMd: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export const GQSasbIndustry = {
  ECommerce: 'ECommerce',
  FoodRetailersAndDistributors: 'FoodRetailersAndDistributors',
  HotelsAndLodging: 'HotelsAndLodging',
  Other: 'Other',
  SoftwareAndItServices: 'SoftwareAndITServices',
} as const;

export type GQSasbIndustry =
  (typeof GQSasbIndustry)[keyof typeof GQSasbIndustry];
export const GQSasbIndustryType = {
  AdvertisingAndMarketing: 'AdvertisingAndMarketing',
  AerospaceAndDefense: 'AerospaceAndDefense',
  AgriculturalProducts: 'AgriculturalProducts',
  AirFreightAndLogistics: 'AirFreightAndLogistics',
  Airlines: 'Airlines',
  AlcoholicBeverages: 'AlcoholicBeverages',
  ApparelAccessoriesAndFootwear: 'ApparelAccessoriesAndFootwear',
  ApplianceManufacturing: 'ApplianceManufacturing',
  AssetManagementAndCustodyActivities: 'AssetManagementAndCustodyActivities',
  AutoParts: 'AutoParts',
  Automobiles: 'Automobiles',
  Biofuels: 'Biofuels',
  BiotechnologyAndPharmaceuticals: 'BiotechnologyAndPharmaceuticals',
  BuildingProductsAndFurnishings: 'BuildingProductsAndFurnishings',
  CarRentalAndLeasing: 'CarRentalAndLeasing',
  CasinosAndGaming: 'CasinosAndGaming',
  Chemicals: 'Chemicals',
  CoalOperations: 'CoalOperations',
  CommercialBanks: 'CommercialBanks',
  ConstructionMaterials: 'ConstructionMaterials',
  ConsumerFinance: 'ConsumerFinance',
  ContainersAndPackaging: 'ContainersAndPackaging',
  CruiseLines: 'CruiseLines',
  DrugRetailers: 'DrugRetailers',
  ECommerce: 'ECommerce',
  Education: 'Education',
  ElectricalAndElectronicEquipment: 'ElectricalAndElectronicEquipment',
  ElectricalUtilitiesAndPowerGenerators:
    'ElectricalUtilitiesAndPowerGenerators',
  ElectronicManufacturingServicesAndOriginalDesignManufacturing:
    'ElectronicManufacturingServicesAndOriginalDesignManufacturing',
  EngineeringAndConstructionServices: 'EngineeringAndConstructionServices',
  FoodRetailersAndDistributors: 'FoodRetailersAndDistributors',
  ForestryManagement: 'ForestryManagement',
  FuelCellsAndIndustrialBatteries: 'FuelCellsAndIndustrialBatteries',
  GasUtilitiesAndDistributors: 'GasUtilitiesAndDistributors',
  Hardware: 'Hardware',
  HealthCareDelivery: 'HealthCareDelivery',
  HealthCareDistributors: 'HealthCareDistributors',
  HomeBuilders: 'HomeBuilders',
  HotelsAndLodging: 'HotelsAndLodging',
  HouseholdAndPersonalProducts: 'HouseholdAndPersonalProducts',
  IndustrialMachineryAndGoods: 'IndustrialMachineryAndGoods',
  Insurance: 'Insurance',
  InternetMediaAndServices: 'InternetMediaAndServices',
  InvestmentBankingAndBrokerage: 'InvestmentBankingAndBrokerage',
  IronAndSteelProducers: 'IronAndSteelProducers',
  LeisureFacilities: 'LeisureFacilities',
  ManagedCare: 'ManagedCare',
  MarineTransportation: 'MarineTransportation',
  MeatPoultryAndDairy: 'MeatPoultryAndDairy',
  MediaAndEntertainment: 'MediaAndEntertainment',
  MedicalEquipmentAndSupplies: 'MedicalEquipmentAndSupplies',
  MetalsAndMining: 'MetalsAndMining',
  MortgageFinance: 'MortgageFinance',
  MultilineAndSpecialtyRetailersAndDistributors:
    'MultilineAndSpecialtyRetailersAndDistributors',
  NonAlcoholicBeverages: 'NonAlcoholicBeverages',
  OilAndGasExplorationAndProduction: 'OilAndGasExplorationAndProduction',
  OilAndGasMidstream: 'OilAndGasMidstream',
  OilAndGasRefiningAndMarketing: 'OilAndGasRefiningAndMarketing',
  OilAndGasServices: 'OilAndGasServices',
  ProcessedFoods: 'ProcessedFoods',
  ProfessionalAndCommercialServices: 'ProfessionalAndCommercialServices',
  PulpAndPaperProducts: 'PulpAndPaperProducts',
  RailTransportation: 'RailTransportation',
  RealEstate: 'RealEstate',
  RealEstateServices: 'RealEstateServices',
  Restaurants: 'Restaurants',
  RoadTransportation: 'RoadTransportation',
  SecurityAndCommodityExchanges: 'SecurityAndCommodityExchanges',
  Semiconductors: 'Semiconductors',
  SoftwareAndItServices: 'SoftwareAndITServices',
  SolarTechnologyAndProjectDevelopers: 'SolarTechnologyAndProjectDevelopers',
  TelecommunicationServices: 'TelecommunicationServices',
  Tobacco: 'Tobacco',
  ToysAndSportingGoods: 'ToysAndSportingGoods',
  WasteManagement: 'WasteManagement',
  WaterUtilitiesAndServices: 'WaterUtilitiesAndServices',
  WindTechnologyAndProjectDevelopers: 'WindTechnologyAndProjectDevelopers',
} as const;

export type GQSasbIndustryType =
  (typeof GQSasbIndustryType)[keyof typeof GQSasbIndustryType];
export const GQSasbSectorType = {
  ConsumerGoods: 'ConsumerGoods',
  ExtractivesAndMineralsProcessing: 'ExtractivesAndMineralsProcessing',
  Financials: 'Financials',
  FoodAndBeverage: 'FoodAndBeverage',
  HealthCare: 'HealthCare',
  Infrastructure: 'Infrastructure',
  RenewableResourcesAndAlternativeEnergy:
    'RenewableResourcesAndAlternativeEnergy',
  ResourceTransformation: 'ResourceTransformation',
  Services: 'Services',
  TechnologyAndCommunications: 'TechnologyAndCommunications',
  Transportation: 'Transportation',
} as const;

export type GQSasbSectorType =
  (typeof GQSasbSectorType)[keyof typeof GQSasbSectorType];
export type GQSaveCategoryRuleGhgCategoryNoteInput = {
  note: Scalars['String']['input'];
  rule: GQFootprintCategoryRuleInput;
};

export type GQSaveCategoryRuleGhgCategoryNotePayload = {
  __typename?: 'SaveCategoryRuleGhgCategoryNotePayload';
  note: Scalars['String']['output'];
};

export type GQSaveDeltaProgressValueMappingInput = {
  configId: Scalars['ID']['input'];
  deltaJson: Scalars['JSONString']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQSaveDeltaProgressValueMappingPayload = {
  __typename?: 'SaveDeltaProgressValueMappingPayload';
  metadata: Maybe<GQValueMappingRowsMetadata>;
  revisionData: GQDatasourceValueMappingRevisionData;
  updatedRows: Array<GQValueMappingRow>;
};

export type GQSaveEmissionsModelDraftInput = {
  changelog: Scalars['String']['input'];
  emissionsModelJson: Scalars['JSONString']['input'];
  matchers: Array<GQEmissionsModelMatcherInput>;
  parameterResolverJson: Scalars['JSONString']['input'];
  parentEmissionsModelVersionId: Scalars['ID']['input'];
  stableId: Scalars['ID']['input'];
};

export type GQSaveEmissionsModelDraftPayload =
  | GQSaveEmissionsModelDraftPayloadInvalid
  | GQSaveEmissionsModelDraftPayloadValid;

export type GQSaveEmissionsModelDraftPayloadInvalid = {
  __typename?: 'SaveEmissionsModelDraftPayloadInvalid';
  validationErrors: Array<GQEmissionsModelError>;
};

export type GQSaveEmissionsModelDraftPayloadValid = {
  __typename?: 'SaveEmissionsModelDraftPayloadValid';
  emissionsModelVersionRecord: GQEmissionsModelVersion;
  matchers: Array<GQEmissionsModelMatcher>;
  parameterResolverRecord: GQParameterResolver;
};

export type GQSaveEmployeeFeedbackInput = {
  feedback?: InputMaybe<Scalars['String']['input']>;
};

export type GQSaveEmployeeFeedbackPayload = {
  __typename?: 'SaveEmployeeFeedbackPayload';
  id: Maybe<Scalars['String']['output']>;
};

export type GQSaveFootprintTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  existingTagId?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
  taggingRules: Scalars['String']['input'];
};

export type GQSaveFootprintTagPayload = {
  __typename?: 'SaveFootprintTagPayload';
  footprintTag: GQFootprintTag;
};

export type GQSaveGlobalFootprintTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  tagName: Scalars['String']['input'];
  taggingRules: Scalars['String']['input'];
};

export type GQSaveGlobalFootprintTagPayload = {
  __typename?: 'SaveGlobalFootprintTagPayload';
  footprintTag: GQGlobalFootprintTag;
};

export type GQSaveIncompleteOneSchemaImportInput = {
  embedId: Scalars['String']['input'];
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQSaveIncompleteOneSchemaImportPayload = {
  __typename?: 'SaveIncompleteOneSchemaImportPayload';
  jobId: Scalars['ID']['output'];
  userUploadTask: GQUserUploadTask;
};

export const GQSbtCommitmentStage = {
  Committed: 'Committed',
  Removed: 'Removed',
  TargetsSet: 'TargetsSet',
} as const;

export type GQSbtCommitmentStage =
  (typeof GQSbtCommitmentStage)[keyof typeof GQSbtCommitmentStage];
export const GQSbtCommitmentType = {
  BaOnePointFiveOptionOne: 'BAOnePointFiveOptionOne',
  BaOnePointFiveOptionOneAndTwo: 'BAOnePointFiveOptionOneAndTwo',
  BaOnePointFiveOptionTwo: 'BAOnePointFiveOptionTwo',
  NetZero: 'NetZero',
  Standard: 'Standard',
} as const;

export type GQSbtCommitmentType =
  (typeof GQSbtCommitmentType)[keyof typeof GQSbtCommitmentType];
export type GQSbtTarget = GQIdInterface & {
  __typename?: 'SbtTarget';
  action: Maybe<Scalars['String']['output']>;
  baseYear: Maybe<Scalars['String']['output']>;
  commitmentDeadline: Maybe<Scalars['String']['output']>;
  commitmentType: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  companyTemperatureAlignment: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  datePublished: Maybe<Scalars['String']['output']>;
  dt: Scalars['Date']['output'];
  fullTargetLanguage: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isin: Maybe<Scalars['String']['output']>;
  lei: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
  organizationType: Maybe<Scalars['String']['output']>;
  reasonForCommitmentExtensionOrRemoval: Maybe<Scalars['String']['output']>;
  region: Maybe<Scalars['String']['output']>;
  scope: Maybe<Scalars['String']['output']>;
  sector: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  target: Maybe<Scalars['String']['output']>;
  targetClassification: Maybe<Scalars['String']['output']>;
  targetSubtype: Maybe<Scalars['String']['output']>;
  targetType: Maybe<Scalars['String']['output']>;
  targetValue: Maybe<Scalars['String']['output']>;
  targetWording: Maybe<Scalars['String']['output']>;
  targetYear: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  yearType: Maybe<Scalars['String']['output']>;
};

export const GQSbtTargetClassification = {
  OnePointFiveDegrees: 'OnePointFiveDegrees',
  TwoDegrees: 'TwoDegrees',
  WellBelowTwoDegrees: 'WellBelowTwoDegrees',
} as const;

export type GQSbtTargetClassification =
  (typeof GQSbtTargetClassification)[keyof typeof GQSbtTargetClassification];
export type GQSbtiTargetExclusion = {
  __typename?: 'SbtiTargetExclusion';
  filters: GQFilterExpressionGroupWithNewFilters;
  id: Scalars['ID']['output'];
};

export const GQScheduleType = {
  Customized: 'Customized',
  SingleParameterized: 'SingleParameterized',
} as const;

export type GQScheduleType =
  (typeof GQScheduleType)[keyof typeof GQScheduleType];
export type GQSchemaRegistrySchema = {
  __typename?: 'SchemaRegistrySchema';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  latestSchemaVersion: Maybe<GQSchemaRegistrySchemaVersion>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQSchemaRegistrySchemaConnection = {
  __typename?: 'SchemaRegistrySchemaConnection';
  edges: Array<Maybe<GQSchemaRegistrySchemaEdge>>;
  pageInfo: GQPageInfo;
};

export type GQSchemaRegistrySchemaEdge = {
  __typename?: 'SchemaRegistrySchemaEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQSchemaRegistrySchema>;
};

export type GQSchemaRegistrySchemaVersion = {
  __typename?: 'SchemaRegistrySchemaVersion';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  schema: GQSchemaRegistrySchema;
  schemaCount: Scalars['Int']['output'];
  schemaJson: Scalars['JSONSchema']['output'];
  version: Scalars['String']['output'];
  versionNumber: Scalars['Int']['output'];
};

export type GQSchemaRegistrySchemaVersionConnection = {
  __typename?: 'SchemaRegistrySchemaVersionConnection';
  edges: Array<Maybe<GQSchemaRegistrySchemaVersionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQSchemaRegistrySchemaVersionEdge = {
  __typename?: 'SchemaRegistrySchemaVersionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQSchemaRegistrySchemaVersion>;
};

export type GQScienceBasedTargetCommitment = GQCompanyClimateCommitment &
  GQIdInterface & {
    __typename?: 'ScienceBasedTargetCommitment';
    commitment: GQSbtCommitment;
    commitmentMadeDate: Maybe<Scalars['Date']['output']>;
    commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
    commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
    description: Maybe<Scalars['String']['output']>;
    externalUrl: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    kind: GQCompanyClimateCommitmentKind;
    submittedToSBTi: Scalars['Boolean']['output'];
  };

export const GQScope2Source = {
  Location: 'Location',
  Market: 'Market',
  Other: 'Other',
} as const;

export type GQScope2Source =
  (typeof GQScope2Source)[keyof typeof GQScope2Source];
export const GQScope3EvaluationStatus = {
  NotEvaluated: 'NOT_EVALUATED',
  NotRelevantCalculated: 'NOT_RELEVANT_CALCULATED',
  NotRelevantExplanationProvided: 'NOT_RELEVANT_EXPLANATION_PROVIDED',
  QuestionNotApplicable: 'QUESTION_NOT_APPLICABLE',
  RelevantCalculated: 'RELEVANT_CALCULATED',
  RelevantNotYetCalculated: 'RELEVANT_NOT_YET_CALCULATED',
  Unknown: 'UNKNOWN',
} as const;

export type GQScope3EvaluationStatus =
  (typeof GQScope3EvaluationStatus)[keyof typeof GQScope3EvaluationStatus];
export const GQScope3OverrideKind = {
  Overall: 'Overall',
  Subscope: 'Subscope',
} as const;

export type GQScope3OverrideKind =
  (typeof GQScope3OverrideKind)[keyof typeof GQScope3OverrideKind];
export type GQSdiInstructionsFaq = {
  answerMd: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type GQSdiInstructionsFaqType = {
  __typename?: 'SdiInstructionsFaqType';
  answerMd: Scalars['String']['output'];
  question: Scalars['String']['output'];
};

export type GQSdiInstructionsStep = {
  __typename?: 'SdiInstructionsStep';
  descriptionMd: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQSendIntegrationInviteInput = {
  datasourceId: Scalars['ID']['input'];
  integrationPartnerId: Scalars['ID']['input'];
  measurementProjectId?: InputMaybe<Scalars['ID']['input']>;
  noteFromAdmin?: InputMaybe<Scalars['String']['input']>;
  recipientUserId: Scalars['ID']['input'];
  shouldSendEmail: Scalars['Boolean']['input'];
  triggererOrgId: Scalars['ID']['input'];
  triggererUserId: Scalars['ID']['input'];
};

export type GQSendIntegrationInvitePayload = {
  __typename?: 'SendIntegrationInvitePayload';
  numberOfEmails: Scalars['Int']['output'];
  sampleEmail: GQEmailContents;
};

export type GQSerializableError = {
  __typename?: 'SerializableError';
  code: Maybe<Scalars['String']['output']>;
  details: Maybe<Scalars['JSONString']['output']>;
  errorType: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  stackTrace: Maybe<Scalars['String']['output']>;
};

export type GQSerializedWithHash = {
  hash: Maybe<Scalars['String']['output']>;
  serialized: Scalars['UntypedData']['output'];
};

export type GQSetApproversInput = {
  approverIds: Array<Scalars['ID']['input']>;
  permissions?: InputMaybe<Array<GQCreatePermissionItemInput>>;
  targetId: Scalars['ID']['input'];
  targetKind: Scalars['String']['input'];
};

export type GQSetApproversPayload = {
  __typename?: 'SetApproversPayload';
  target: GQUserUploadTask;
};

export type GQSetArchiveEngagementTaskConfigInput = {
  id: Scalars['ID']['input'];
  isArchived: Scalars['Boolean']['input'];
};

export type GQSetArchiveEngagementTaskConfigPayload = {
  __typename?: 'SetArchiveEngagementTaskConfigPayload';
  engagementTaskConfig: GQEngagementTaskConfig;
};

export type GQSetAssetBenchmarksInput = {
  action?: InputMaybe<GQFinanceAuditAction>;
  assetCorporateId: Scalars['ID']['input'];
  publicDisclosures: Array<GQDisclosureIdWithReportingYear>;
};

export type GQSetAssetBenchmarksPayload = {
  __typename?: 'SetAssetBenchmarksPayload';
  status: Maybe<Scalars['String']['output']>;
};

export type GQSetEmissionsModelIsArchivedInput = {
  emissionsModelStableId: Scalars['ID']['input'];
  isArchived: Scalars['Boolean']['input'];
};

export type GQSetEmissionsModelIsArchivedPayload = {
  __typename?: 'SetEmissionsModelIsArchivedPayload';
  emissionsModelStableRecord: GQEmissionsModelStable;
};

export type GQSetEngagementCohortAndMembersInput = {
  cohortId: Scalars['ID']['input'];
  companyIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type GQSetEngagementCohortAndMembersPayload = {
  __typename?: 'SetEngagementCohortAndMembersPayload';
  engagementCohortWithCompanyIds: GQEngagementCohortWithCompanyIds;
  oldCompanyIds: Array<Scalars['ID']['output']>;
};

export type GQSetEngagementCohortsForCompanyInput = {
  cohortIds: Array<Scalars['ID']['input']>;
  companyId: Scalars['ID']['input'];
};

export type GQSetEngagementCohortsForCompanyPayload = {
  __typename?: 'SetEngagementCohortsForCompanyPayload';
  cohortIds: Array<Scalars['ID']['output']>;
  companyId: Scalars['ID']['output'];
};

export type GQSetEngagementNotificationSettingsForUserInput = {
  settings: Array<GQEngagementNotificationSettingInput>;
  side: GQEngagementNotificationSide;
  userId: Scalars['ID']['input'];
};

export type GQSetFootprintAsWatershedReviewedInput = {
  id: Scalars['ID']['input'];
  isWatershedReviewed: Scalars['Boolean']['input'];
};

export type GQSetFootprintAsWatershedReviewedPayload = {
  __typename?: 'SetFootprintAsWatershedReviewedPayload';
  footprintVersion: GQFootprintVersion;
};

export type GQSetFootprintSnapshotIsManuallySavedInput = {
  id: Scalars['ID']['input'];
  isManuallySaved: Scalars['Boolean']['input'];
};

export type GQSetFootprintSnapshotIsManuallySavedPayload = {
  __typename?: 'SetFootprintSnapshotIsManuallySavedPayload';
  snapshot: GQFootprintSnapshot;
};

export type GQSetFootprintVersionKindInput = {
  id: Scalars['ID']['input'];
  kind: GQFootprintVersionKind;
};

export type GQSetFootprintVersionKindPayload = {
  __typename?: 'SetFootprintVersionKindPayload';
  footprintVersion: GQFootprintVersion;
};

export type GQSetFootprintVersionUserVisibleInput = {
  id: Scalars['ID']['input'];
  isUserVisible: Scalars['Boolean']['input'];
};

export type GQSetFootprintVersionUserVisiblePayload = {
  __typename?: 'SetFootprintVersionUserVisiblePayload';
  footprintVersion: GQFootprintVersion;
};

export type GQSetOrgsForFeatureFlagInput = {
  enabledForAllOrgs: Scalars['Boolean']['input'];
  featureFlagId: Scalars['ID']['input'];
  orgIds: Array<Scalars['ID']['input']>;
};

export type GQSetOrgsForFeatureFlagPayload = {
  __typename?: 'SetOrgsForFeatureFlagPayload';
  featureFlag: GQFeatureFlag;
};

export type GQSetPeerCompanyGroupMemberFeatureStatusInput = {
  id: Scalars['ID']['input'];
  revenueAtRisk?: InputMaybe<Scalars['Int53']['input']>;
  shouldHideFromFootprintOverview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQSetPeerCompanyGroupMemberFeatureStatusPayload = {
  __typename?: 'SetPeerCompanyGroupMemberFeatureStatusPayload';
  peerCompanyGroupMember: GQPeerCompanyGroupMember;
};

export type GQSetPreferredLocaleInput = {
  locale: Scalars['String']['input'];
};

export type GQSetPreferredLocalePayload = {
  __typename?: 'SetPreferredLocalePayload';
  preferredLocale: Scalars['String']['output'];
};

export type GQSetPublishEngagementTaskConfigInput = {
  id: Scalars['ID']['input'];
  isPublished: Scalars['Boolean']['input'];
};

export type GQSetPublishEngagementTaskConfigPayload = {
  __typename?: 'SetPublishEngagementTaskConfigPayload';
  engagementTaskConfig: GQEngagementTaskConfig;
};

export type GQSetUserUploadedTableNotIgnoredInput = {
  userUploadedTableId: Scalars['ID']['input'];
};

export type GQSetUserUploadedTableNotIgnoredPayload = {
  __typename?: 'SetUserUploadedTableNotIgnoredPayload';
  userUploadedTable: GQUserUploadedTable;
};

export type GQSetUserUploadedTableSheetsNotIngestedInput = {
  userUploadAttemptId: Scalars['ID']['input'];
  userUploadedTableIdsNotIngested: Array<Scalars['ID']['input']>;
};

export type GQSetUserUploadedTableSheetsNotIngestedPayload = {
  __typename?: 'SetUserUploadedTableSheetsNotIngestedPayload';
  userUploadAttempt: GQUserUploadAttempt;
};

export type GQSetUserUploadedTableSqlTableNameInput = {
  newSqlTableName: Scalars['String']['input'];
  userUploadedTableId: Scalars['ID']['input'];
};

export type GQSetUserUploadedTableSqlTableNamePayload = {
  __typename?: 'SetUserUploadedTableSqlTableNamePayload';
  userUploadedTable: GQUserUploadedTable;
};

export type GQSetupMeasurementProjectInput = {
  deadline?: InputMaybe<Scalars['Date']['input']>;
  kickoff?: InputMaybe<Scalars['Date']['input']>;
  measurementProjectId: Scalars['ID']['input'];
};

export const GQSfdrFundClassification = {
  ArticleEight: 'ArticleEight',
  ArticleNine: 'ArticleNine',
  ArticleSix: 'ArticleSix',
} as const;

export type GQSfdrFundClassification =
  (typeof GQSfdrFundClassification)[keyof typeof GQSfdrFundClassification];
export type GQShareFilesInput = {
  climateLeadsToCc?: InputMaybe<Array<Scalars['ID']['input']>>;
  datasourceIds: Array<Scalars['ID']['input']>;
  fileMetadataIds: Array<Scalars['ID']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  orgPointsOfContactToCc?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIds: Array<Scalars['ID']['input']>;
  watershedEmployeeId: Scalars['ID']['input'];
};

export type GQShareFilesPayload = {
  __typename?: 'ShareFilesPayload';
  sharedFiles: Array<GQSharedFile>;
};

export type GQShareMeasurementProjectInput = {
  companyId: Scalars['ID']['input'];
  measurementProjectId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQShareMeasurementProjectPayload = {
  __typename?: 'ShareMeasurementProjectPayload';
  measurementProjectStatusShare: GQMeasurementProjectStatusShare;
};

export type GQShareMeasurementProjectStatusInput = {
  measurementProjectId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQShareMeasurementProjectStatusPayload = {
  __typename?: 'ShareMeasurementProjectStatusPayload';
  measurementProjectStatusShare: GQMeasurementProjectStatusShare;
};

export type GQSharedFile = {
  __typename?: 'SharedFile';
  createdAt: Scalars['DateTime']['output'];
  datasource: GQDatasource;
  expiresAt: Scalars['DateTime']['output'];
  fileMetadata: GQFileMetadata;
  id: Scalars['ID']['output'];
  measurementProject: Maybe<GQMeasurementProject>;
  orgId: Scalars['ID']['output'];
  signedUrl: Scalars['String']['output'];
  user: GQUser;
  watershedEmployee: Maybe<GQWatershedEmployee>;
};

export type GQSharedFileConnection = {
  __typename?: 'SharedFileConnection';
  edges: Array<Maybe<GQSharedFileEdge>>;
  pageInfo: GQPageInfo;
};

export type GQSharedFileEdge = {
  __typename?: 'SharedFileEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQSharedFile>;
};

export type GQSharedFileRequest = {
  __typename?: 'SharedFileRequest';
  createdAt: Scalars['DateTime']['output'];
  datasource: GQDatasource;
  fileMetadata: GQFileMetadata;
  fulfilledAt: Maybe<Scalars['DateTime']['output']>;
  fulfilledBy: Maybe<GQWatershedEmployee>;
  id: Scalars['ID']['output'];
  organization: GQOrganization;
  sharedFile: Maybe<GQSharedFile>;
  user: GQUser;
};

export type GQSharedFileRequestConnection = {
  __typename?: 'SharedFileRequestConnection';
  edges: Array<Maybe<GQSharedFileRequestEdge>>;
  pageInfo: GQPageInfo;
};

export type GQSharedFileRequestEdge = {
  __typename?: 'SharedFileRequestEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQSharedFileRequest>;
};

export type GQSharedMeasurementProject = {
  __typename?: 'SharedMeasurementProject';
  id: Scalars['ID']['output'];
  measurementProjectId: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
};

export type GQSignalExecuteMeasurementTestSuiteInput = {
  id: Scalars['ID']['input'];
  stage: GQMeasurementTestSuiteStage;
};

export type GQSignalExecuteMeasurementTestSuitePayload = {
  __typename?: 'SignalExecuteMeasurementTestSuitePayload';
  id: Scalars['ID']['output'];
};

export type GQSimpleTimeseries = {
  __typename?: 'SimpleTimeseries';
  base: Scalars['Date']['output'];
  frequency: GQTimeseriesFrequency;
  id: Maybe<Scalars['ID']['output']>;
  values: Array<Scalars['Float']['output']>;
};

export type GQSimpleTimeseriesInput = {
  base?: InputMaybe<Scalars['Date']['input']>;
  values: Array<Scalars['Float']['input']>;
};

export type GQSimulationCategoryValuePair = {
  __typename?: 'SimulationCategoryValuePair';
  category: Scalars['String']['output'];
  values: Array<GQSimulationMaterial>;
};

export type GQSimulationCostPerUnitOverride = {
  __typename?: 'SimulationCostPerUnitOverride';
  deltaPerUnit: Scalars['Float']['output'];
  hideCost: Scalars['Boolean']['output'];
  isPercentage: Scalars['Boolean']['output'];
  unit: Scalars['String']['output'];
};

export type GQSimulationCostPerUnitOverrideInput = {
  deltaPerUnit: Scalars['Float']['input'];
  hideCost: Scalars['Boolean']['input'];
  isPercentage: Scalars['Boolean']['input'];
  unit: Scalars['String']['input'];
};

export type GQSimulationEligibleFootprintIntervals = {
  __typename?: 'SimulationEligibleFootprintIntervals';
  intervals: Array<Scalars['YMInterval']['output']>;
};

export type GQSimulationMaterial = {
  __typename?: 'SimulationMaterial';
  higgMaterial: Maybe<Scalars['String']['output']>;
  orgMaterial: Scalars['String']['output'];
  watershedMaterial: Maybe<Scalars['String']['output']>;
};

export type GQSimulationOptionDetails = {
  __typename?: 'SimulationOptionDetails';
  category: Scalars['String']['output'];
  kgco2ePerKg: Scalars['Float']['output'];
};

export type GQSimulationPortfolio = GQIdInterface & {
  __typename?: 'SimulationPortfolio';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isTemplate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  scenarios: Array<GQSimulationScenario>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQSimulationPortfolioProps = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQSimulationRowData =
  | GQPowerSimulationData
  | GQProductsAndMaterialSimulationData;

export type GQSimulationScenario = GQIdInterface & {
  __typename?: 'SimulationScenario';
  createdAt: Scalars['DateTime']['output'];
  creator: GQUser;
  id: Scalars['ID']['output'];
  isTemplate: Scalars['Boolean']['output'];
  latestRun: Maybe<GQSimulationScenarioRun>;
  name: Scalars['String']['output'];
  portfolio: GQSimulationPortfolio;
  portfolioId: Scalars['ID']['output'];
  swapOverlayData: Maybe<GQSimulationSwapOverlay>;
  type: GQSimulationScenarioConfigType;
  updatedAt: Scalars['DateTime']['output'];
};

export const GQSimulationScenarioConfigType = {
  ManufacturingCleanPowerPercent: 'ManufacturingCleanPowerPercent',
  MaterialSwap: 'MaterialSwap',
  Tier1ManufacturingElectricityCleanPower:
    'Tier1ManufacturingElectricityCleanPower',
  Tier1ManufacturingElectricityLocation:
    'Tier1ManufacturingElectricityLocation',
} as const;

export type GQSimulationScenarioConfigType =
  (typeof GQSimulationScenarioConfigType)[keyof typeof GQSimulationScenarioConfigType];
export type GQSimulationScenarioProps = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQSimulationScenarioRun = {
  __typename?: 'SimulationScenarioRun';
  footprintSnapshotId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  intervalEndExclusive: Scalars['YearMonth']['output'];
  intervalStartInclusive: Scalars['YearMonth']['output'];
  resultData: Maybe<Scalars['JSONString']['output']>;
  scenario: GQSimulationScenario;
  scenarioConfiguration: Scalars['JSONString']['output'];
  targetingFilters: Scalars['JSONString']['output'];
};

export type GQSimulationScenarioRunConfig = {
  footprintInterval: Scalars['YMInterval']['input'];
  footprintKind?: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: Scalars['String']['input'];
  scenarioConfiguration: Scalars['JSONString']['input'];
  scenarioId: Scalars['ID']['input'];
  targetingFilters: Scalars['JSONString']['input'];
};

export type GQSimulationSwapOption = {
  __typename?: 'SimulationSwapOption';
  percentOfTotalEmissions: Scalars['Float']['output'];
  sourceCategory: Scalars['String']['output'];
  targetCategories: Array<Scalars['String']['output']>;
  targetCategoriesWithDetails: Array<GQSimulationOptionDetails>;
};

export type GQSimulationSwapOverlay = {
  __typename?: 'SimulationSwapOverlay';
  original: Maybe<GQSimulationSwapOverlayData>;
  swap: Maybe<GQSimulationSwapOverlayData>;
};

export type GQSimulationSwapOverlayData = {
  __typename?: 'SimulationSwapOverlayData';
  assumptionMarkdown: Maybe<Scalars['String']['output']>;
  choice: Scalars['String']['output'];
  cost: Maybe<GQSimulationCostPerUnitOverride>;
  emissionsPerKg: Scalars['Float']['output'];
};

export type GQSimulationTargetingOptions = {
  __typename?: 'SimulationTargetingOptions';
  categoryLabel: Scalars['String']['output'];
  field: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
};

export type GQSingleRowMethodologyExplainer = {
  __typename?: 'SingleRowMethodologyExplainer';
  biogenicCo2LocationTrace: Maybe<GQSingleRowTrace>;
  biogenicCo2Trace: Maybe<GQSingleRowTrace>;
  cleanPowerAvoidedEmissionsTrace: Maybe<GQSingleRowTrace>;
  files: Array<GQFootprintAnalysisFile>;
  footprintExclusionRule: Maybe<GQFootprintExclusionRule>;
  id: Scalars['String']['output'];
  locationTrace: Maybe<GQSingleRowTrace>;
  marketTrace: Maybe<GQSingleRowTrace>;
  microplasticsInflowEmissionsTrace: Maybe<GQSingleRowTrace>;
  microplasticsOutflowEmissionsTrace: Maybe<GQSingleRowTrace>;
  pipelineV2Chain: Maybe<GQCalculationChain>;
  substanceInflowEmissionsTrace: Maybe<GQSingleRowTrace>;
  substanceOutflowEmissionsTrace: Maybe<GQSingleRowTrace>;
  wasteEmissionsTrace: Maybe<GQSingleRowTrace>;
  waterConsumptionEmissionsTrace: Maybe<GQSingleRowTrace>;
};

export type GQSingleRowTrace = {
  __typename?: 'SingleRowTrace';
  convertedActivity: Scalars['Float']['output'];
  convertedActivityUnit: Scalars['String']['output'];
  emissions: Scalars['Float']['output'];
  fingerprint: Scalars['String']['output'];
  functionalUnit: Scalars['Float']['output'];
  functionalUnitUnit: Scalars['String']['output'];
  ghgCategoryId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  trace: Scalars['UntypedData']['output'];
  traceDetails: GQTraceDetails;
};

export type GQSnapshotFootprintKindMeta = {
  __typename?: 'SnapshotFootprintKindMeta';
  description: Scalars['String']['output'];
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  footprintKind: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export const GQSpendMethodology = {
  Ceda: 'CEDA',
  Useeio: 'USEEIO',
} as const;

export type GQSpendMethodology =
  (typeof GQSpendMethodology)[keyof typeof GQSpendMethodology];
export const GQSpendMethoodology = {
  Ceda: 'CEDA',
  Useeio: 'USEEIO',
} as const;

export type GQSpendMethoodology =
  (typeof GQSpendMethoodology)[keyof typeof GQSpendMethoodology];
export type GQStartCdpSyncAnswersInput = {
  overwriteConflicts?: InputMaybe<Scalars['Boolean']['input']>;
  questionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reportId: Scalars['ID']['input'];
};

export type GQStartCdpSyncAnswersPayload = {
  __typename?: 'StartCdpSyncAnswersPayload';
  id: Scalars['ID']['output'];
  workflowId: Scalars['String']['output'];
};

export type GQStartFileFeedEmbeddedSessionInput = {
  ctsvOneSchemaFileFeedId: Scalars['ID']['input'];
};

export type GQStartPrepublicationTestsForEmissionsModelInput = {
  changelog: Scalars['String']['input'];
  emissionsModelJson: Scalars['JSONString']['input'];
  emissionsModelStableId: Scalars['ID']['input'];
  matchers: Array<GQEmissionsModelMatcherInput>;
  parameterResolverJson: Scalars['JSONString']['input'];
  parentEmissionsModelVersionId: Scalars['ID']['input'];
};

export type GQStartPrepublicationTestsForReferenceDataRevisionInput = {
  revisionId: Scalars['ID']['input'];
};

export type GQStartPrepublicationTestsForReleaseInput = {
  emissionsModelStableIds: Array<Scalars['ID']['input']>;
  globalFootprintTagInputs: Array<GQEmReleaseGlobalFootprintTagInput>;
  releaseId: Scalars['ID']['input'];
};

export type GQStartPrepublicationTestsPayload = {
  __typename?: 'StartPrepublicationTestsPayload';
  workflowId: Scalars['ID']['output'];
};

export type GQStartPublishingEmissionsModelInput = {
  changelog: Scalars['String']['input'];
  emissionsModelJson: Scalars['JSONString']['input'];
  matchers: Array<GQEmissionsModelMatcherInput>;
  parameterResolverJson: Scalars['JSONString']['input'];
  parentEmissionsModelVersionId: Scalars['ID']['input'];
  stableId: Scalars['ID']['input'];
};

export type GQStartPublishingEmissionsModelPayload =
  | GQStartPublishingEmissionsModelPayloadError
  | GQStartPublishingEmissionsModelPayloadInvalid
  | GQStartPublishingEmissionsModelPayloadValid;

export type GQStartPublishingEmissionsModelPayloadError = {
  __typename?: 'StartPublishingEmissionsModelPayloadError';
  error: GQSerializableError;
};

export type GQStartPublishingEmissionsModelPayloadInvalid = {
  __typename?: 'StartPublishingEmissionsModelPayloadInvalid';
  validationErrors: Array<GQEmissionsModelError>;
};

export type GQStartPublishingEmissionsModelPayloadValid = {
  __typename?: 'StartPublishingEmissionsModelPayloadValid';
  emissionsModelVersionRecord: GQEmissionsModelVersion;
  matchers: Array<GQEmissionsModelMatcher>;
  parameterResolverRecord: GQParameterResolver;
};

export type GQStartWorkflowInput = {
  args: Scalars['JSONString']['input'];
  type: Scalars['String']['input'];
};

export type GQStartWorkflowPayload = {
  __typename?: 'StartWorkflowPayload';
  workflowExecution: GQWorkflowExecution;
};

export const GQStationaryCombustionFuelKind = {
  AgriculturalByproducts: 'AgriculturalByproducts',
  AnthraciteCoal: 'AnthraciteCoal',
  AsphaltAndRoadOil: 'AsphaltAndRoadOil',
  AviationGasoline: 'AviationGasoline',
  Bagasse: 'Bagasse',
  Bamboo: 'Bamboo',
  Biodiesel: 'Biodiesel',
  BiofuelsAndWaste: 'BiofuelsAndWaste',
  BituminousCoal: 'BituminousCoal',
  BlastFurnaceGas: 'BlastFurnaceGas',
  Butane: 'Butane',
  Butylene: 'Butylene',
  CoalCoke: 'CoalCoke',
  CokeOvenGas: 'CokeOvenGas',
  CompressedNaturalGas: 'CompressedNaturalGas',
  CrudeOil: 'CrudeOil',
  Diesel: 'Diesel',
  DistrictChilledWater: 'DistrictChilledWater',
  DistrictHeat: 'DistrictHeat',
  Ethane: 'Ethane',
  Ethanol: 'Ethanol',
  Ethylene: 'Ethylene',
  FuelGas: 'FuelGas',
  FuelOil1: 'FuelOil1',
  FuelOil2: 'FuelOil2',
  FuelOil4: 'FuelOil4',
  HeavyGasOils: 'HeavyGasOils',
  Isobutane: 'Isobutane',
  Isobutylene: 'Isobutylene',
  Kerosene: 'Kerosene',
  KeroseneTypeJetFuel: 'KeroseneTypeJetFuel',
  LandfillGas: 'LandfillGas',
  LigniteCoal: 'LigniteCoal',
  LiquefiedNaturalGas: 'LiquefiedNaturalGas',
  LiquefiedPetroleumGases: 'LiquefiedPetroleumGases',
  Lubricants: 'Lubricants',
  MixedCommercialSectorCoal: 'MixedCommercialSectorCoal',
  MixedElectricPowerSectorCoal: 'MixedElectricPowerSectorCoal',
  MixedIndustrialCoking: 'MixedIndustrialCoking',
  MixedIndustrialSector: 'MixedIndustrialSector',
  MotorGasoline: 'MotorGasoline',
  MunicipalSolidWaste: 'MunicipalSolidWaste',
  Naphtha: 'Naphtha',
  NaturalGas: 'NaturalGas',
  NaturalGasoline: 'NaturalGasoline',
  NorthAmericanHardwood: 'NorthAmericanHardwood',
  NorthAmericanSoftwood: 'NorthAmericanSoftwood',
  OtherBiomassGases: 'OtherBiomassGases',
  OtherOil: 'OtherOil',
  Peat: 'Peat',
  PentanesPlus: 'PentanesPlus',
  PetrochemicalFeedstocks: 'PetrochemicalFeedstocks',
  PetroleumCoke: 'PetroleumCoke',
  Plastics: 'Plastics',
  Propane: 'Propane',
  PropaneGas: 'PropaneGas',
  Propylene: 'Propylene',
  RenderedAnimalFat: 'RenderedAnimalFat',
  ResidualFuelOil5: 'ResidualFuelOil5',
  ResidualFuelOil6: 'ResidualFuelOil6',
  SolidByproducts: 'SolidByproducts',
  SpecialNaphtha: 'SpecialNaphtha',
  SteamHeat: 'SteamHeat',
  Straw: 'Straw',
  SubBituminousCoal: 'SubBituminousCoal',
  Tires: 'Tires',
  UnfinishedOils: 'UnfinishedOils',
  UsedOil: 'UsedOil',
  VegetableOil: 'VegetableOil',
  WoodAndWoodResiduals: 'WoodAndWoodResiduals',
} as const;

export type GQStationaryCombustionFuelKind =
  (typeof GQStationaryCombustionFuelKind)[keyof typeof GQStationaryCombustionFuelKind];
export type GQStringKeyValue = {
  __typename?: 'StringKeyValue';
  key: Scalars['String']['output'];
  value: Maybe<Scalars['String']['output']>;
};

export type GQStringKeyValueInput = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type GQSubmitEarlyUploadHelpRequestInput = {
  cleaningStepType?: InputMaybe<GQImportFlowDataCleaningStep>;
  fileMetadataIdForDataCleaningStep?: InputMaybe<Scalars['ID']['input']>;
  fileMetadataIdsAllThatWereUploaded: Array<Scalars['ID']['input']>;
  message: Scalars['String']['input'];
  stepId?: InputMaybe<Scalars['String']['input']>;
  userUploadTaskId: Scalars['ID']['input'];
  userUploadedTableIdForDataCleaningStep?: InputMaybe<Scalars['ID']['input']>;
};

export type GQSubmitEarlyUploadHelpRequestPayload = {
  __typename?: 'SubmitEarlyUploadHelpRequestPayload';
  userUploads: Array<GQUserUpload>;
};

export type GQSubmitEngagementTaskInput = {
  answers?: InputMaybe<Array<GQEngagementTaskAnswerInput>>;
  engagementTaskId: Scalars['ID']['input'];
};

export type GQSubmitEngagementTaskPayload = {
  __typename?: 'SubmitEngagementTaskPayload';
  engagementTask: Maybe<GQEngagementTask>;
};

export type GQSubmitFacilitiesBulkAdditionInput = {
  facilitiesToAdd: Array<GQFacilitiesAdditionInput>;
  measurementProjectId: Scalars['ID']['input'];
};

export type GQSubmitFacilitiesBulkAdditionPayload = {
  __typename?: 'SubmitFacilitiesBulkAdditionPayload';
  measurementProject: GQMeasurementProject;
  numFacilitiesCreated: Scalars['Int']['output'];
  org: GQOrganization;
};

export type GQSubmitFacilitiesInput = {
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQSubmitFacilitiesPayload = {
  __typename?: 'SubmitFacilitiesPayload';
  measurementTask: GQMeasurementParentTask | GQMeasurementTaskFlat;
  submittedUserUploadTask: GQUserUploadTask;
};

export type GQSubmitUserUploadTaskInput = {
  id: Scalars['ID']['input'];
};

export type GQSubmitUserUploadTaskPayload = {
  __typename?: 'SubmitUserUploadTaskPayload';
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQSubmitVendorMatchingTaskInput = {
  vendorMatchingTaskId: Scalars['ID']['input'];
};

export type GQSubmitVendorMatchingTaskPayload = {
  __typename?: 'SubmitVendorMatchingTaskPayload';
  batchRerunBartSqlJobId: Scalars['ID']['output'];
  measurementTask: GQMeasurementParentTask | GQMeasurementTaskFlat;
};

export type GQSupplier = {
  __typename?: 'Supplier';
  cleanedFootprintVendorNames: Array<Scalars['String']['output']>;
  climateProgress: GQCompanyClimateProgress;
  company: Maybe<GQCompany>;
  customDataV2: Maybe<Scalars['JSONString']['output']>;
  disclosures: Array<GQDisclosure>;
  emissionsByCategory: Array<GQCategorizedEmissionData>;
  emissionsByMonth: Array<GQLabeledTimeseries>;
  emissionsFactors: Array<GQEmissionsFactorRecordForDashboard>;
  engagementCohortIds: Array<Scalars['ID']['output']>;
  footprintTags: Array<GQStringKeyValue>;
  footprintVendorNames: Array<Scalars['String']['output']>;
  ghgCategoryIds: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  historicalEmissions: Maybe<GQSupplierHistoricalEmissions>;
  id: Scalars['ID']['output'];
  industryAverageAllocatedEmissions: Maybe<GQSupplierHistoricalEmissions>;
  isTotalSpendingPartial: Maybe<Scalars['Boolean']['output']>;
  latestCdpDisclosurePublishingYear: Maybe<Scalars['Int']['output']>;
  latestDisclosureDateTime: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  notes: Maybe<Scalars['String']['output']>;
  percentEmissions: Scalars['Float']['output'];
  rows: Array<GQSupplierRow>;
  sbtiStage: GQCompanySbtCommitmentStage;
  supplierType: GQSupplierType;
  totalKgco2e: Scalars['Float']['output'];
  totalKgco2eFromFootprint: Maybe<Scalars['Float']['output']>;
  totalSpendUsd: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use totalSpendUsd instead */
  totalSpending: Maybe<Scalars['Float']['output']>;
};

export type GQSupplierDisclosuresArgs = {
  opts: InputMaybe<GQDisclosureInput>;
};

export type GQSupplierEmissionsByMonthArgs = {
  groupBy: GQSupplierEmissionsGroupByField;
};

export type GQSupplierActivityTags = {
  __typename?: 'SupplierActivityTags';
  tags: Array<GQStringKeyValue>;
};

export type GQSupplierAttachment = {
  __typename?: 'SupplierAttachment';
  companyId: Scalars['String']['output'];
  crossOrgModelId: Maybe<Scalars['String']['output']>;
  fileMetadataId: Scalars['ID']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isImported: Scalars['Boolean']['output'];
  surveyId: Maybe<Scalars['ID']['output']>;
};

export type GQSupplierChart = {
  __typename?: 'SupplierChart';
  aggregateType: Maybe<GQSupplierChartAggregateType>;
  chartType: GQSupplierChartType;
  colorScale: Maybe<Array<Scalars['String']['output']>>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
  xField: Maybe<Scalars['ID']['output']>;
  yField: Maybe<Scalars['ID']['output']>;
};

export const GQSupplierChartAggregateType = {
  Count: 'Count',
  SpendSum: 'SpendSum',
  Sum: 'Sum',
} as const;

export type GQSupplierChartAggregateType =
  (typeof GQSupplierChartAggregateType)[keyof typeof GQSupplierChartAggregateType];
export type GQSupplierChartConfig = {
  chartType: GQSupplierChartType;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
};

export type GQSupplierChartInput = {
  chartType: GQSupplierChartType;
  description?: InputMaybe<Scalars['String']['input']>;
  emissionsForecastChartConfig?: InputMaybe<GQSupplierEmissionsForecastChartConfigInput>;
  engagementFunnelChartConfig?: InputMaybe<GQSupplierEngagementFunnelChartInput>;
  metricsChartConfig?: InputMaybe<GQSupplierMetricsChartConfigInput>;
  sbtCommitmentOverTimeChartConfig?: InputMaybe<GQSupplierSbtCommitmentOverTimeChartConfigInput>;
  standardChartConfig?: InputMaybe<GQSupplierStandardChartInput>;
  tasksStatusChangeOverTimeChartConfig?: InputMaybe<GQSupplierTaskStatusChangeOverTimeChartConfigInput>;
  title: Scalars['String']['input'];
};

export const GQSupplierChartType = {
  Donut: 'Donut',
  EmissionsForecast: 'EmissionsForecast',
  EngagementFunnel: 'EngagementFunnel',
  HorizontalBar: 'HorizontalBar',
  Metrics: 'Metrics',
  SbtCommitmentOverTime: 'SbtCommitmentOverTime',
  TasksStatusChangeOverTime: 'TasksStatusChangeOverTime',
} as const;

export type GQSupplierChartType =
  (typeof GQSupplierChartType)[keyof typeof GQSupplierChartType];
export type GQSupplierContact = GQIContact & {
  __typename?: 'SupplierContact';
  company: GQCompany;
  companyId: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  role: Maybe<Scalars['String']['output']>;
  userId: Maybe<Scalars['ID']['output']>;
};

export type GQSupplierCustomData = {
  __typename?: 'SupplierCustomData';
  companyId: Scalars['ID']['output'];
  customData: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  notes: Maybe<Scalars['String']['output']>;
  priority: Maybe<GQSupplierPriority>;
};

export type GQSupplierDataExportInput = {
  expandScope: Scalars['Boolean']['input'];
  footprintSnapshotId: Scalars['String']['input'];
};

export type GQSupplierDataExportPayload = {
  __typename?: 'SupplierDataExportPayload';
  workflowId: Scalars['String']['output'];
};

export type GQSupplierDetailedEmissions = {
  __typename?: 'SupplierDetailedEmissions';
  emissionsByCategory: Array<GQCategorizedEmissionData>;
  emissionsByMonth: Array<GQLabeledTimeseries>;
  emissionsFactors: Array<GQEmissionsFactorRecordForDashboard>;
  emissionsModelEmissionsFactors: Array<GQEmissionsModelEmissionsFactor>;
  id: Scalars['ID']['output'];
  rows: Array<GQSupplierRow>;
};

export type GQSupplierDetailedEmissionsEmissionsByMonthArgs = {
  groupBy: GQSupplierEmissionsGroupByField;
};

export type GQSupplierEmissionsFactorsTableMetadata = {
  __typename?: 'SupplierEmissionsFactorsTableMetadata';
  displayColumns: Array<GQMethodologyCustomizationColumnDisplayFormat>;
  quantityColumnName: Scalars['String']['output'];
};

export type GQSupplierEmissionsFactorsTableRows = {
  __typename?: 'SupplierEmissionsFactorsTableRows';
  referenceDataRevisionRows: Array<GQReferenceDataRevisionRow>;
};

export type GQSupplierEmissionsForecastChartConfig = GQSupplierChartConfig & {
  __typename?: 'SupplierEmissionsForecastChartConfig';
  chartType: GQSupplierChartType;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  plan: Maybe<GQPlan>;
  title: Scalars['String']['output'];
};

export type GQSupplierEmissionsForecastChartConfigInput = {
  planId: Scalars['ID']['input'];
};

export const GQSupplierEmissionsGroupByField = {
  BusinessCategory: 'businessCategory',
  BusinessSubcategory: 'businessSubcategory',
} as const;

export type GQSupplierEmissionsGroupByField =
  (typeof GQSupplierEmissionsGroupByField)[keyof typeof GQSupplierEmissionsGroupByField];
export type GQSupplierEngagementFunnelChartConfig = GQSupplierChartConfig & {
  __typename?: 'SupplierEngagementFunnelChartConfig';
  chartType: GQSupplierChartType;
  description: Maybe<Scalars['String']['output']>;
  funnels: Array<GQEngagementFunnel>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
};

export type GQSupplierEngagementFunnelChartInput = {
  engagementFunnels?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQSupplierExpenseCategory = {
  __typename?: 'SupplierExpenseCategory';
  companyId: Scalars['ID']['output'];
  expenseCategories: Maybe<Array<GQExpenseCategoryData>>;
};

export type GQSupplierExpenseCategoryData = {
  __typename?: 'SupplierExpenseCategoryData';
  beaCode: Scalars['String']['output'];
  kgco2e: Scalars['Float']['output'];
  spend: Scalars['Float']['output'];
};

export type GQSupplierFilterOption = {
  __typename?: 'SupplierFilterOption';
  formattedValue: Scalars['String']['output'];
  rawValue: Scalars['FilterValue']['output'];
};

export type GQSupplierHistoricalEmissions = {
  __typename?: 'SupplierHistoricalEmissions';
  expenseCategory: Maybe<Scalars['String']['output']>;
  publicUrl: Maybe<Scalars['String']['output']>;
  publishingYear: Maybe<Scalars['Int']['output']>;
  reportingYear: Scalars['Int']['output'];
  revenue: Maybe<Scalars['Float']['output']>;
  revenueCurrency: Maybe<Scalars['String']['output']>;
  revenueUsd: Maybe<Scalars['Float']['output']>;
  scope1: Maybe<Scalars['Float']['output']>;
  scope1Ratio: Maybe<Scalars['Float']['output']>;
  scope2: Maybe<Scalars['Float']['output']>;
  scope2Ratio: Maybe<Scalars['Float']['output']>;
  scope3: Maybe<Scalars['Float']['output']>;
  scope3Ratio: Maybe<Scalars['Float']['output']>;
  scope301: Maybe<Scalars['Float']['output']>;
  scope302: Maybe<Scalars['Float']['output']>;
  scope303: Maybe<Scalars['Float']['output']>;
  scope304: Maybe<Scalars['Float']['output']>;
  scope305: Maybe<Scalars['Float']['output']>;
  scope306: Maybe<Scalars['Float']['output']>;
  scope307: Maybe<Scalars['Float']['output']>;
  scope308: Maybe<Scalars['Float']['output']>;
  scope309: Maybe<Scalars['Float']['output']>;
  scope310: Maybe<Scalars['Float']['output']>;
  scope311: Maybe<Scalars['Float']['output']>;
  scope312: Maybe<Scalars['Float']['output']>;
  scope313: Maybe<Scalars['Float']['output']>;
  scope314: Maybe<Scalars['Float']['output']>;
  scope315: Maybe<Scalars['Float']['output']>;
  scope316: Maybe<Scalars['Float']['output']>;
  scope317: Maybe<Scalars['Float']['output']>;
  source: GQEmissionsSource;
  surveyId: Maybe<Scalars['ID']['output']>;
  units: GQCompanyEmissionsUnits;
};

export type GQSupplierMappingRowConnection = {
  __typename?: 'SupplierMappingRowConnection';
  edges: Array<Maybe<GQSupplierMappingRowEdge>>;
  pageInfo: GQPageInfo;
};

export type GQSupplierMappingRowEdge = {
  __typename?: 'SupplierMappingRowEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQSupplierMappingTableRow>;
};

export type GQSupplierMappingTableRow = GQIdInterface & {
  __typename?: 'SupplierMappingTableRow';
  autoMatchedCompany: Maybe<GQCompany>;
  autoMatchedCompanyId: Maybe<Scalars['String']['output']>;
  autoMatches: Array<GQCompanyAutoMatch>;
  id: Scalars['ID']['output'];
  orgCompany: Maybe<GQOrgCompany>;
  pendingCompanyCreateRequest: Maybe<GQCompanyChangeRequest>;
  rawVendorEntity: Scalars['String']['output'];
  subRows: Array<GQSupplierMappingTableSubRow>;
  totalSpendUsd: Scalars['Float']['output'];
};

export type GQSupplierMappingTableRowsPayload = {
  __typename?: 'SupplierMappingTableRowsPayload';
  id: Scalars['ID']['output'];
  totalNumVendorRows: Scalars['Int']['output'];
  vendorRows: GQSupplierMappingRowConnection;
};

export type GQSupplierMappingTableSubRow = GQIdInterface & {
  __typename?: 'SupplierMappingTableSubRow';
  category: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  currencyQuantity: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  spendUsd: Scalars['Float']['output'];
  subCategory: Scalars['String']['output'];
};

export type GQSupplierMappingV1Analytics = {
  __typename?: 'SupplierMappingV1Analytics';
  mappedSupplierSpendUsd: Scalars['Float']['output'];
  totalSupplierSpendUsd: Scalars['Float']['output'];
};

export const GQSupplierMatchingSortOption = {
  Matched: 'matched',
  Spend: 'spend',
  Unmatched: 'unmatched',
} as const;

export type GQSupplierMatchingSortOption =
  (typeof GQSupplierMatchingSortOption)[keyof typeof GQSupplierMatchingSortOption];
export type GQSupplierMetricsChartConfig = GQSupplierChartConfig & {
  __typename?: 'SupplierMetricsChartConfig';
  chartType: GQSupplierChartType;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  metricsTypes: Array<GQSupplyChainMetricType>;
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
};

export type GQSupplierMetricsChartConfigInput = {
  metricsTypes: Array<GQSupplyChainMetricType>;
};

export const GQSupplierPriority = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
} as const;

export type GQSupplierPriority =
  (typeof GQSupplierPriority)[keyof typeof GQSupplierPriority];
export type GQSupplierProductLineData = {
  __typename?: 'SupplierProductLineData';
  beaCode: Scalars['String']['output'];
  kgco2e: Scalars['Float']['output'];
  spend: Scalars['Float']['output'];
};

export type GQSupplierRow = {
  __typename?: 'SupplierRow';
  beaCode: Maybe<Scalars['String']['output']>;
  categoryId: Scalars['String']['output'];
  company: Maybe<GQCompany>;
  convertedQuantity: Scalars['Float']['output'];
  convertedUnit: Scalars['String']['output'];
  emissionsFactor: Maybe<GQEmissionsFactorRecordForDashboard>;
  emissionsFactorId: Maybe<Scalars['String']['output']>;
  footprintTags: Array<GQStringKeyValue>;
  footprintVendorName: Maybe<Scalars['String']['output']>;
  ghgCategoryId: Maybe<Scalars['String']['output']>;
  groupingKey: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inputUnit: Maybe<Scalars['String']['output']>;
  kgco2eQuantity: Scalars['Float']['output'];
  month: Maybe<Scalars['YearMonth']['output']>;
  name: Scalars['String']['output'];
  rawVendorName: Maybe<Scalars['String']['output']>;
  subcategoryId: Scalars['String']['output'];
  supplierType: GQSupplierType;
};

export type GQSupplierSbtCommitmentOverTimeChartConfig =
  GQSupplierChartConfig & {
    __typename?: 'SupplierSbtCommitmentOverTimeChartConfig';
    chartType: GQSupplierChartType;
    colorScale: Array<Scalars['String']['output']>;
    description: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    orgId: Maybe<Scalars['ID']['output']>;
    title: Scalars['String']['output'];
  };

export type GQSupplierSbtCommitmentOverTimeChartConfigInput = {
  colorScale: Array<Scalars['String']['input']>;
};

export type GQSupplierScoreCriteria = {
  __typename?: 'SupplierScoreCriteria';
  field: Scalars['String']['output'];
  filters: Array<GQBiQueryFilter>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  supplierScoreId: Scalars['String']['output'];
  weight: Scalars['Int']['output'];
};

export type GQSupplierScorecard = {
  __typename?: 'SupplierScorecard';
  criteria: Array<GQSupplierScoreCriteria>;
  field: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQSupplierSpecificInfoForBulkDeploy = {
  expenseCategories?: InputMaybe<
    Array<GQDiscloseEmissionsExpenseCategoriesInput>
  >;
  supplierCompanyId: Scalars['ID']['input'];
  supplierContactIds: Array<Scalars['ID']['input']>;
};

export type GQSupplierStandardChartConfig = GQSupplierChartConfig & {
  __typename?: 'SupplierStandardChartConfig';
  aggregateType: GQSupplierChartAggregateType;
  chartType: GQSupplierChartType;
  colorScale: Array<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
  xField: Scalars['ID']['output'];
  yField: Maybe<Scalars['ID']['output']>;
};

export type GQSupplierStandardChartInput = {
  aggregateType: GQSupplierChartAggregateType;
  colorScale: Array<Scalars['String']['input']>;
  xField: Scalars['ID']['input'];
  yField?: InputMaybe<Scalars['ID']['input']>;
};

export type GQSupplierSurveyResponseExportInput = {
  surveyIds: Array<Scalars['ID']['input']>;
};

export type GQSupplierSurveyResponseExportPayload = {
  __typename?: 'SupplierSurveyResponseExportPayload';
  workflowId: Scalars['String']['output'];
};

export type GQSupplierTable = {
  __typename?: 'SupplierTable';
  columns: Array<GQSupplierTableColumn>;
};

export type GQSupplierTableColumn = {
  __typename?: 'SupplierTableColumn';
  baseEngagementTaskConfigId: Maybe<Scalars['ID']['output']>;
  columnFormat: Maybe<GQSupplierTableColumnFormat>;
  csatTagName: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  field: Scalars['String']['output'];
  footprintTagName: Maybe<Scalars['String']['output']>;
  headerName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSupplierScore: Scalars['Boolean']['output'];
  isSupplierScoreCriteria: Scalars['Boolean']['output'];
  questionKey: Maybe<Scalars['String']['output']>;
  selectOptions: Maybe<Array<Scalars['String']['output']>>;
  visible: Maybe<Scalars['Boolean']['output']>;
};

export const GQSupplierTableColumnFormat = {
  CurrencyUsdCents: 'CurrencyUsdCents',
  Date: 'Date',
  FootprintTag: 'FootprintTag',
  Integer: 'Integer',
  Number: 'Number',
  Percentage: 'Percentage',
  SingleSelect: 'SingleSelect',
  String: 'String',
} as const;

export type GQSupplierTableColumnFormat =
  (typeof GQSupplierTableColumnFormat)[keyof typeof GQSupplierTableColumnFormat];
export type GQSupplierTaskStatusChangeOverTimeChartConfig =
  GQSupplierChartConfig & {
    __typename?: 'SupplierTaskStatusChangeOverTimeChartConfig';
    chartType: GQSupplierChartType;
    colorScale: Array<Scalars['String']['output']>;
    description: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    isCumulative: Maybe<Scalars['Boolean']['output']>;
    orgId: Maybe<Scalars['ID']['output']>;
    tasksStatusChangeConfig: Maybe<Array<GQSurveyDefinitionAndStatus>>;
    title: Scalars['String']['output'];
  };

export type GQSupplierTaskStatusChangeOverTimeChartConfigInput = {
  colorScale: Array<Scalars['String']['input']>;
  isCumulative?: InputMaybe<Scalars['Boolean']['input']>;
  tasksStatusChangeConfig?: InputMaybe<Scalars['JSONString']['input']>;
};

export const GQSupplierType = {
  KnownSupplier: 'KnownSupplier',
  NonFootprintSupplier: 'NonFootprintSupplier',
  SandboxCompany: 'SandboxCompany',
  SmallSuppliersAggregated: 'SmallSuppliersAggregated',
  UnknownSuppliersAggregated: 'UnknownSuppliersAggregated',
} as const;

export type GQSupplierType =
  (typeof GQSupplierType)[keyof typeof GQSupplierType];
export type GQSupplierView = GQIdInterface & {
  __typename?: 'SupplierView';
  chartOrder: Maybe<Array<GQSupplierViewChartOrder>>;
  chartsV2: Array<
    | GQSupplierEmissionsForecastChartConfig
    | GQSupplierEngagementFunnelChartConfig
    | GQSupplierMetricsChartConfig
    | GQSupplierSbtCommitmentOverTimeChartConfig
    | GQSupplierStandardChartConfig
    | GQSupplierTaskStatusChangeOverTimeChartConfig
  >;
  columnOrder: Maybe<Array<GQSupplierViewColumnOrder>>;
  filters: Maybe<GQFilterExpressionGroupWithNewFilters>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  reductionPlan: Maybe<GQPlan>;
  table: GQSupplierTable;
};

export type GQSupplierViewChartOrder = {
  __typename?: 'SupplierViewChartOrder';
  chartId: Scalars['ID']['output'];
  visible: Scalars['Boolean']['output'];
};

export type GQSupplierViewColumnOrder = {
  __typename?: 'SupplierViewColumnOrder';
  columnId: Scalars['ID']['output'];
  visible: Scalars['Boolean']['output'];
  width: Scalars['Int']['output'];
};

export type GQSuppliersData = {
  __typename?: 'SuppliersData';
  data: Scalars['UntypedData']['output'];
  id: Scalars['String']['output'];
};

export type GQSuppliersSettings = {
  __typename?: 'SuppliersSettings';
  id: Scalars['ID']['output'];
  includedGhgCategories: Array<Scalars['String']['output']>;
  suppressNotifications: Scalars['Boolean']['output'];
  viewLatestSupplierMapping: Scalars['Boolean']['output'];
};

export type GQSuppliersV2Options = {
  filterToCollapsibleVendors?: InputMaybe<Scalars['Boolean']['input']>;
  shouldUseUncollapsedFootprintFields?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQSuppliersWithInterval = {
  __typename?: 'SuppliersWithInterval';
  /** @deprecated no longer used */
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  suppliers: Array<GQSupplier>;
};

export const GQSupplyChainMetricType = {
  ForecastedSuppliersReductionInTargetYearKgco2e:
    'ForecastedSuppliersReductionInTargetYearKgco2e',
  ForecastedSuppliersReductionInTargetYearKgco2eFromInitiatives:
    'ForecastedSuppliersReductionInTargetYearKgco2eFromInitiatives',
  ForecastedSupplyChainSavingsInTargetYear:
    'ForecastedSupplyChainSavingsInTargetYear',
  ForecastedSupplyChainSavingsInTargetYearFromInitiatives:
    'ForecastedSupplyChainSavingsInTargetYearFromInitiatives',
  SuppliersCommitedToSbti: 'SuppliersCommitedToSbti',
  SurveysApproved: 'SurveysApproved',
  SurveysSent: 'SurveysSent',
  SurveysSubmitted: 'SurveysSubmitted',
  TotalSupplyChainKgco2e: 'TotalSupplyChainKgco2e',
} as const;

export type GQSupplyChainMetricType =
  (typeof GQSupplyChainMetricType)[keyof typeof GQSupplyChainMetricType];
export type GQSupplyChainSurveyEventContext = {
  customerOrg?: InputMaybe<GQContextOrgInfo>;
  isDemo?: InputMaybe<Scalars['Boolean']['input']>;
  supplierOrg?: InputMaybe<GQContextOrgInfo>;
  surveyStatus?: InputMaybe<GQCompanySurveyStatus>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
};

export type GQSupportingDocument = GQIdInterface & {
  __typename?: 'SupportingDocument';
  buildingUtilityId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  file: GQFileMetadata;
  id: Scalars['ID']['output'];
  /** @deprecated Use file.name instead */
  name: Scalars['String']['output'];
  utilityType: Maybe<GQBuildingUtilityType>;
};

export type GQSurveyDefinitionAndStatus = {
  __typename?: 'SurveyDefinitionAndStatus';
  engagementTaskConfigId: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export const GQSurveyDefinitionCategory = {
  DataCollection: 'DataCollection',
  Learning: 'Learning',
  MakeCommitments: 'MakeCommitments',
  Other: 'Other',
  ReductionProjects: 'ReductionProjects',
  ScienceBasedTargets: 'ScienceBasedTargets',
} as const;

export type GQSurveyDefinitionCategory =
  (typeof GQSurveyDefinitionCategory)[keyof typeof GQSurveyDefinitionCategory];
export type GQSwapEmissionsModelExpectationsForFootprintTestRowsInput = {
  footprintTestSuiteConfigId: Scalars['ID']['input'];
  footprintTestSuiteExecutionId: Scalars['ID']['input'];
  oldToNewEmissionsModelIds: Scalars['JSONString']['input'];
};

export type GQSwapEmissionsModelExpectationsForFootprintTestRowsPayload = {
  __typename?: 'SwapEmissionsModelExpectationsForFootprintTestRowsPayload';
  ids: Array<Scalars['ID']['output']>;
};

export type GQSwapEmissionsModelExpectationsForMeasurementTestRowsInput = {
  measurementTestRowIds: Array<Scalars['ID']['input']>;
  measurementTestSuiteId: Scalars['ID']['input'];
  oldToNewEmissionsModelIds: Scalars['JSONString']['input'];
};

export type GQSwapEmissionsModelExpectationsForMeasurementTestRowsPayload = {
  __typename?: 'SwapEmissionsModelExpectationsForMeasurementTestRowsPayload';
  ids: Array<Scalars['ID']['output']>;
};

export type GQSyncFeatureFlagsInput = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQSyncFeatureFlagsPayload = {
  __typename?: 'SyncFeatureFlagsPayload';
  diff: GQFeatureFlagsDiff;
  featureFlags: Array<GQFeatureFlag>;
};

export type GQSyncMarketplacePortfolioItemsInput = {
  id: Scalars['ID']['input'];
  portfolioItems: Array<GQUpdateMarketplacePortfolioItemInput>;
};

export type GQSyncMarketplacePortfolioItemsPayload = {
  __typename?: 'SyncMarketplacePortfolioItemsPayload';
  portfolio: GQMarketplacePortfolio;
};

export type GQSyncMeasurementMappingsInput = {
  create?: InputMaybe<Array<GQNewMeasurementMappingInput>>;
  delete?: InputMaybe<Array<Scalars['ID']['input']>>;
  orgId: Scalars['ID']['input'];
  update?: InputMaybe<Array<GQUpdateMeasurementMappingInput>>;
};

export type GQSyncMeasurementMappingsPayload = {
  __typename?: 'SyncMeasurementMappingsPayload';
  jobId: Maybe<Scalars['String']['output']>;
  mappings: Array<GQMeasurementMapping>;
};

export const GQTableCellAlignment = {
  Center: 'center',
  Left: 'left',
  Right: 'right',
} as const;

export type GQTableCellAlignment =
  (typeof GQTableCellAlignment)[keyof typeof GQTableCellAlignment];
export type GQTabularData = {
  __typename?: 'TabularData';
  cells: Array<Array<Scalars['JSONString']['output']>>;
};

export type GQTabularDataInput = {
  cells: Array<Array<Scalars['JSONString']['input']>>;
};

export type GQTarget = GQIdInterface & {
  __typename?: 'Target';
  actualAmount: Maybe<Scalars['Int']['output']>;
  descriptor: Maybe<Scalars['String']['output']>;
  endDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  kind: GQTargetKind;
  startDate: Scalars['Date']['output'];
  targetAmount: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  unit: GQTargetUnit;
};

export type GQTargetConnection = {
  __typename?: 'TargetConnection';
  edges: Array<Maybe<GQTargetEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'Target' and its cursor. */
export type GQTargetEdge = {
  __typename?: 'TargetEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQTarget>;
};

export type GQTargetInput = {
  descriptor?: InputMaybe<Scalars['String']['input']>;
  kind: GQTargetKind;
  targetAmount: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  unit: GQTargetUnit;
};

export const GQTargetKind = {
  AvoidedEmissions: 'AvoidedEmissions',
  EmissionsReduction: 'EmissionsReduction',
  NetZero: 'NetZero',
  Offsets: 'Offsets',
} as const;

export type GQTargetKind = (typeof GQTargetKind)[keyof typeof GQTargetKind];
export const GQTargetReductionRate = {
  Custom: 'Custom',
  LinearAnnualReduction: 'LinearAnnualReduction',
  YearOverYear: 'YearOverYear',
} as const;

export type GQTargetReductionRate =
  (typeof GQTargetReductionRate)[keyof typeof GQTargetReductionRate];
export const GQTargetUnit = {
  Percent: 'Percent',
  PercentAbsolute: 'PercentAbsolute',
  Tco2E: 'TCO2E',
  Year: 'Year',
} as const;

export type GQTargetUnit = (typeof GQTargetUnit)[keyof typeof GQTargetUnit];
export type GQTaskStatusDump = {
  __typename?: 'TaskStatusDump';
  assigneeNames: Maybe<Array<Scalars['String']['output']>>;
  datasetName: Maybe<Scalars['String']['output']>;
  status: GQTaskWatershedProcessStateSimplified;
  taskId: Scalars['ID']['output'];
  taskName: Scalars['String']['output'];
};

export const GQTaskUploadState = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  NotStarted: 'NotStarted',
} as const;

export type GQTaskUploadState =
  (typeof GQTaskUploadState)[keyof typeof GQTaskUploadState];
export const GQTaskWatershedProcessState = {
  Blocked: 'Blocked',
  Completed: 'Completed',
  InProgress: 'InProgress',
  NotStarted: 'NotStarted',
  ReviewRequired: 'ReviewRequired',
} as const;

export type GQTaskWatershedProcessState =
  (typeof GQTaskWatershedProcessState)[keyof typeof GQTaskWatershedProcessState];
export const GQTaskWatershedProcessStateSimplified = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  NeedsApproval: 'NeedsApproval',
  None: 'None',
  NotStarted: 'NotStarted',
} as const;

export type GQTaskWatershedProcessStateSimplified =
  (typeof GQTaskWatershedProcessStateSimplified)[keyof typeof GQTaskWatershedProcessStateSimplified];
export type GQTcfdArchetypeOpportunity = GQIdInterface &
  GQITcfdOpportunity & {
    __typename?: 'TcfdArchetypeOpportunity';
    archetypeRiskId: Maybe<Scalars['ID']['output']>;
    distributionModel: GQTcfdStrategyModuleDistributionModel;
    geographies: Array<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    sectors: Array<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    userDescription: Scalars['String']['output'];
  };

export type GQTcfdArchetypeRisk = GQIdInterface &
  GQITcfdRisk & {
    __typename?: 'TcfdArchetypeRisk';
    distributionModel: GQTcfdStrategyModuleDistributionModel;
    geographies: Array<Scalars['String']['output']>;
    highTrajectoryImpact: GQTcfdRiskImpact;
    id: Scalars['ID']['output'];
    kind: GQTcfdRiskKind;
    lowTrajectoryImpact: GQTcfdRiskImpact;
    mediumTrajectoryImpact: GQTcfdRiskImpact;
    physicalRiskSeverity: Maybe<GQTcfdPhysicalRiskSeverity>;
    possibleImpactDescription: Maybe<Scalars['String']['output']>;
    sectors: Array<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    userDescription: Scalars['String']['output'];
    whyWeChoseThisMd: Scalars['String']['output'];
  };

export const GQTcfdPhysicalRiskSeverity = {
  Acute: 'Acute',
  Chronic: 'Chronic',
} as const;

export type GQTcfdPhysicalRiskSeverity =
  (typeof GQTcfdPhysicalRiskSeverity)[keyof typeof GQTcfdPhysicalRiskSeverity];
export const GQTcfdRiskImpact = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
} as const;

export type GQTcfdRiskImpact =
  (typeof GQTcfdRiskImpact)[keyof typeof GQTcfdRiskImpact];
export const GQTcfdRiskKind = {
  Market: 'Market',
  Physical: 'Physical',
  PolicyAndLegal: 'PolicyAndLegal',
  Reputation: 'Reputation',
  Technology: 'Technology',
} as const;

export type GQTcfdRiskKind =
  (typeof GQTcfdRiskKind)[keyof typeof GQTcfdRiskKind];
export const GQTcfdStrategyModuleDistributionModel = {
  B2B: 'B2B',
  B2BAndB2C: 'B2BAndB2C',
  B2C: 'B2C',
} as const;

export type GQTcfdStrategyModuleDistributionModel =
  (typeof GQTcfdStrategyModuleDistributionModel)[keyof typeof GQTcfdStrategyModuleDistributionModel];
export const GQTeam = {
  Calcprint: 'Calcprint',
  CopyDemoData: 'CopyDemoData',
  DataInfrastructure: 'DataInfrastructure',
  DataIngestion: 'DataIngestion',
  DataIngestionPlaywright: 'DataIngestionPlaywright',
  DataScience: 'DataScience',
  DatasetsAndObjects: 'DatasetsAndObjects',
  Engineering: 'Engineering',
  EnterpriseFoundations: 'EnterpriseFoundations',
  Infrastructure: 'Infrastructure',
  Localization: 'Localization',
  Methodology: 'Methodology',
  Reductions: 'Reductions',
  Reporting: 'Reporting',
  SupplyChain: 'SupplyChain',
  TestTeam: 'TestTeam',
  Unowned: 'Unowned',
  WinCsrd: 'WinCsrd',
  WinFinance: 'WinFinance',
} as const;

export type GQTeam = (typeof GQTeam)[keyof typeof GQTeam];
export type GQThrowErrorInput = {
  errorCode?: InputMaybe<Scalars['String']['input']>;
  fooString?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type GQThrowErrorPayload = {
  __typename?: 'ThrowErrorPayload';
  message: Maybe<Scalars['String']['output']>;
};

export type GQTimeseries = {
  __typename?: 'Timeseries';
  base: Scalars['YearMonth']['output'];
  frequency: GQTimeseriesFrequency;
  values: Array<Scalars['Float']['output']>;
};

export type GQTimeseriesForGroup = {
  __typename?: 'TimeseriesForGroup';
  timeseries: GQSimpleTimeseries;
  value: Maybe<Scalars['String']['output']>;
};

export const GQTimeseriesFrequency = {
  Monthly: 'Monthly',
  Yearly: 'Yearly',
} as const;

export type GQTimeseriesFrequency =
  (typeof GQTimeseriesFrequency)[keyof typeof GQTimeseriesFrequency];
export type GQTimeseriesViewMode = {
  aggregateKind: GQAggregateKind;
  customIntensityId?: InputMaybe<GQIntensityDenominatorKind>;
  timeseriesKind?: InputMaybe<GQTimeseriesFrequency>;
};

export type GQToggleCliqSyncForReferenceDataSourceInput = {
  referenceDataSourceId: Scalars['ID']['input'];
};

export type GQToggleCliqSyncForReferenceDataSourcePayload = {
  __typename?: 'ToggleCliqSyncForReferenceDataSourcePayload';
  cliqReferenceDataConnection: GQCliqReferenceDataConnection;
};

export type GQTraceBartValueDetails = {
  __typename?: 'TraceBartValueDetails';
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  pointer: Scalars['String']['output'];
};

export type GQTraceDetails = {
  __typename?: 'TraceDetails';
  bartValueDetails: Array<Scalars['UntypedData']['output']>;
  emissionsModelEvaluationId: Maybe<Scalars['String']['output']>;
  emissionsModelVariableDetails: Array<Scalars['UntypedData']['output']>;
  emissionsModelVersionId: Maybe<Scalars['String']['output']>;
  referenceValueDetails: Array<Scalars['UntypedData']['output']>;
};

export type GQTraceEmissionsModelVariableDetails = {
  __typename?: 'TraceEmissionsModelVariableDetails';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  pointer: Scalars['String']['output'];
};

export type GQTraceReferenceValueCitationDetails = {
  __typename?: 'TraceReferenceValueCitationDetails';
  displayName: Maybe<Scalars['String']['output']>;
  isCustomerProvided: Maybe<Scalars['Boolean']['output']>;
  notes: Maybe<Scalars['String']['output']>;
  org: GQTraceReferenceValueCitationDetailsOrg;
  publicDisclosureId: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type GQTraceReferenceValueCitationDetailsOrg = {
  __typename?: 'TraceReferenceValueCitationDetailsOrg';
  orgId: Maybe<Scalars['String']['output']>;
  orgName: Maybe<Scalars['String']['output']>;
};

export type GQTraceReferenceValueDetails = {
  __typename?: 'TraceReferenceValueDetails';
  citation: GQTraceReferenceValueCitationDetails;
  description: Maybe<Scalars['String']['output']>;
  pointer: Scalars['String']['output'];
};

/**
 * #####################################################
 * Marketplace
 * #####################################################
 */
export type GQTrackedEditor = GQUser | GQWatershedEmployee;

export type GQUnassignFacilitiesFromTaskInput = {
  buildingIds: Array<Scalars['String']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQUnassignFacilitiesFromTaskPayload = {
  __typename?: 'UnassignFacilitiesFromTaskPayload';
  facilities: Array<GQFacility>;
  measurementProject: GQMeasurementProject;
};

export type GQUnassignFromContainerInput = {
  assigneeId: Scalars['ID']['input'];
  containerId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type GQUnassignFromContainerPayload = {
  __typename?: 'UnassignFromContainerPayload';
  container: GQReportQuestionContainer;
  unassignedCount: Scalars['Float']['output'];
};

export type GQUnassociateIntegrationConnectionFromDatasourceInput = {
  datasourceId: Scalars['ID']['input'];
  integrationConnectionId: Scalars['ID']['input'];
};

export type GQUnassociateIntegrationConnectionFromDatasourcePayload = {
  __typename?: 'UnassociateIntegrationConnectionFromDatasourcePayload';
  integrationConnection: GQIntegrationConnection;
};

export type GQUnboundParameterBinding = {
  expression: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GQUncompleteMeasurementProjectInput = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQUncompleteMeasurementProjectPayload = {
  __typename?: 'UncompleteMeasurementProjectPayload';
  measurementProject: GQMeasurementProject;
};

export type GQUncompleteUserUploadTaskInput = {
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQUndeleteMeasurementTestSuitePayload = {
  __typename?: 'UndeleteMeasurementTestSuitePayload';
  id: Scalars['ID']['output'];
};

export type GQUniqueExternalReportFilterValues = {
  __typename?: 'UniqueExternalReportFilterValues';
  companies: Array<GQCompanyFilterValues>;
  publishingYears: Array<Scalars['Int']['output']>;
  reportTypes: Array<GQExternalReportType>;
};

export type GQUnitConversionRecord = {
  __typename?: 'UnitConversionRecord';
  assumptionId: Maybe<Scalars['String']['output']>;
  assumptionUuid: Maybe<Scalars['String']['output']>;
  conversionDescription: Maybe<Scalars['String']['output']>;
  conversionMultiplier: Maybe<Scalars['Float']['output']>;
  inputUnit: Maybe<Scalars['String']['output']>;
  outputUnit: Maybe<Scalars['String']['output']>;
};

export type GQUnlinkEmissionsModelsFromReleaseInput = {
  emissionsModelStableIds: Array<Scalars['ID']['input']>;
  releaseId: Scalars['ID']['input'];
};

export type GQUnlinkGlobalFootprintTagsFromReleaseInput = {
  globalFootprintTagIds: Array<Scalars['ID']['input']>;
  releaseId: Scalars['ID']['input'];
};

export type GQUnlockDataInput = {
  comment: Scalars['String']['input'];
  targetId: Scalars['ID']['input'];
  targetKind: Scalars['String']['input'];
};

export type GQUnlockDataPayload = {
  __typename?: 'UnlockDataPayload';
  newChangelogEvents: Array<GQDataApprovalChangelogEvent>;
  target: GQUserUploadTask;
};

export type GQUnpublishActivityDataTableInput = {
  adtId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQUnpublishActivityDataTablePayload = {
  __typename?: 'UnpublishActivityDataTablePayload';
  workflowId: Scalars['String']['output'];
};

export type GQUnpublishCtsVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQUnpublishCtsVersionPayload = {
  __typename?: 'UnpublishCtsVersionPayload';
  ctsVersion: GQCustomerTargetSchemaVersion;
  customerTargetSchema: GQCustomerTargetSchema;
};

export type GQUnpublishDataRegistryTablesInput = {
  batchMigratorRunId: Scalars['ID']['input'];
};

export type GQUnpublishDataRegistryTablesPayload = {
  __typename?: 'UnpublishDataRegistryTablesPayload';
  unpublishedCount: Scalars['Int']['output'];
};

export type GQUnpublishOrgStructureVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQUnpublishOrgStructureVersionPayload = {
  __typename?: 'UnpublishOrgStructureVersionPayload';
  orgStructureVersion: GQOrgStructureVersion;
};

export type GQUnshareMeasurementProjectStatusInput = {
  id: Scalars['ID']['input'];
};

export type GQUnshareMeasurementProjectStatusPayload = {
  __typename?: 'UnshareMeasurementProjectStatusPayload';
  status: Maybe<Scalars['String']['output']>;
};

export type GQUpdateActivityBasedInitiativePayload = {
  __typename?: 'UpdateActivityBasedInitiativePayload';
  initiative: GQFootprintSliceInitiative | GQPrecomputedActivityBasedInitiative;
  plan: GQPlan;
};

export type GQUpdateActivityDataTableInput = {
  activityDataTableId: Scalars['ID']['input'];
  /** @deprecated Field no longer supported */
  datasourceId?: InputMaybe<Scalars['ID']['input']>;
  datasourceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  filterEndYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  filterStartYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primaryDatasourceId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUpdateActivityDataTablePayload = {
  __typename?: 'UpdateActivityDataTablePayload';
  activityDataTable: GQActivityDataTable;
};

export type GQUpdateActivityDataTableReviewerInput = {
  activityDataTableId: Scalars['ID']['input'];
  reviewerId: Scalars['ID']['input'];
  watershedReviewerId: Scalars['ID']['input'];
};

export type GQUpdateActivityDataTableReviewerPayload = {
  __typename?: 'UpdateActivityDataTableReviewerPayload';
  activityDataTable: GQActivityDataTable;
};

export type GQUpdateAdminMeasurementVerificationItemInput = {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type GQUpdateAssetCommentInput = {
  commentText: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type GQUpdateAssetCommentPayload = {
  __typename?: 'UpdateAssetCommentPayload';
  assetComment: GQAssetComment;
};

export type GQUpdateAssetCorporateInput = {
  action: GQFinanceAuditAction;
  assetGroupId?: InputMaybe<Scalars['String']['input']>;
  buildingSizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  companyBea?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  companyIsin?: InputMaybe<Scalars['String']['input']>;
  companyLei?: InputMaybe<Scalars['String']['input']>;
  companyTicker?: InputMaybe<Scalars['String']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryAlpha2?: InputMaybe<GQCountryAlpha2>;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetCorporatePayload = {
  __typename?: 'UpdateAssetCorporatePayload';
  asset: Maybe<GQAssetCorporate>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetGroupInput = {
  action: GQFinanceAuditAction;
  assetGroupId?: InputMaybe<Scalars['String']['input']>;
  buildingSizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetGroupPayload = {
  __typename?: 'UpdateAssetGroupPayload';
  asset: Maybe<GQAssetGroup>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetHoldingNewInput = {
  action: GQFinanceAuditAction;
  assetClass?: InputMaybe<GQFundAssetClass>;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  fundId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  outstandingAmountNative?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ1?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ2?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ3?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ4?: InputMaybe<Scalars['Float']['input']>;
  premiumNative?: InputMaybe<Scalars['Float']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
  yearOfInitialInvestment?: InputMaybe<Scalars['Int']['input']>;
};

export type GQUpdateAssetHoldingPayload = {
  __typename?: 'UpdateAssetHoldingPayload';
  assetHolding: GQFinanceAssetHolding;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetPersonalMotorVehicleInsuranceInput = {
  action: GQFinanceAuditAction;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetPersonalMotorVehicleInsurancePayload = {
  __typename?: 'UpdateAssetPersonalMotorVehicleInsurancePayload';
  asset: Maybe<GQAssetPersonalMotorVehicleInsurance>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetRealEstateInput = {
  action: GQFinanceAuditAction;
  assetGroupId?: InputMaybe<Scalars['String']['input']>;
  buildingKind?: InputMaybe<Scalars['String']['input']>;
  buildingSizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryAlpha2?: InputMaybe<GQCountryAlpha2>;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetRealEstatePayload = {
  __typename?: 'UpdateAssetRealEstatePayload';
  asset: Maybe<GQAssetRealEstate>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetSovereignBondInput = {
  action: GQFinanceAuditAction;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  sovereignEntity?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetSovereignBondPayload = {
  __typename?: 'UpdateAssetSovereignBondPayload';
  asset: Maybe<GQAssetSovereignBond>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetYear = {
  companyValueUsdCents?: InputMaybe<Scalars['Int53']['input']>;
  id: Scalars['ID']['input'];
};

export type GQUpdateAssetYearNewInput = {
  action: GQFinanceAuditAction;
  assetCommercialRealEstateId?: InputMaybe<Scalars['ID']['input']>;
  assetCorporateId?: InputMaybe<Scalars['ID']['input']>;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  assetPersonalMotorVehicleInsuranceId?: InputMaybe<Scalars['ID']['input']>;
  assetSovereignBondId?: InputMaybe<Scalars['ID']['input']>;
  buildingSizeNative?: InputMaybe<Scalars['Int']['input']>;
  cloudSpendNative?: InputMaybe<Scalars['Float']['input']>;
  committedToNearTermSbtAlignedNetZero?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  demonstratingYoyEmissionsInLineWithNetZero?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  emissionsYearId?: InputMaybe<Scalars['ID']['input']>;
  estimationMethodology?: InputMaybe<GQEstimationMethodology>;
  flightMiles?: InputMaybe<Scalars['Int']['input']>;
  headcount?: InputMaybe<Scalars['Int']['input']>;
  iciAlignmentOverride?: InputMaybe<GQIciAlignmentStatus>;
  id: Scalars['ID']['input'];
  initiatedDecarbonizationPlan?: InputMaybe<Scalars['Boolean']['input']>;
  measuringMaterialScope3Emissions?: InputMaybe<Scalars['Boolean']['input']>;
  measuringScope1And2Emissions?: InputMaybe<Scalars['Boolean']['input']>;
  percentEmployeesWfh?: InputMaybe<Scalars['Float']['input']>;
  percentRenewable?: InputMaybe<Scalars['Float']['input']>;
  revenueNative?: InputMaybe<Scalars['Float']['input']>;
  revenueSourceEmissionsYearId?: InputMaybe<Scalars['ID']['input']>;
  scenario?: InputMaybe<Scalars['String']['input']>;
  scope1OverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope1OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2LocationOverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope2LocationOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2MarketOverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope2MarketOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope3OverrideKind?: InputMaybe<GQScope3OverrideKind>;
  scope3OverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope3OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope301OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope302OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope303OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope304OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope305OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope306OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope307OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope308OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope309OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope310OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope311OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope312OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope313OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope314OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope315OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
  totalMwh?: InputMaybe<Scalars['Float']['input']>;
  valueNative?: InputMaybe<Scalars['Float']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type GQUpdateAssetYearNewPayload = {
  __typename?: 'UpdateAssetYearNewPayload';
  assetYear: GQFinanceAssetYearNew;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetYearRevenueSourceInput = {
  action?: InputMaybe<GQFinanceAuditAction>;
  assetYearId: Scalars['ID']['input'];
  revenueSourceEmissionsYearId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUpdateAssetYearRevenueSourcePayload = {
  __typename?: 'UpdateAssetYearRevenueSourcePayload';
  assetYearId: Scalars['ID']['output'];
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetYearsNewInput = {
  action: GQFinanceAuditAction;
  assetYears: Array<GQUpdateAssetYearNewInput>;
  fileInfo?: InputMaybe<GQFinanceAuditFileInfoInput>;
  /** @deprecated No longer needed */
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type GQUpdateAssetYearsNewPayload = {
  __typename?: 'UpdateAssetYearsNewPayload';
  assetYears: Array<GQFinanceAssetYearNew>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateBiCustomMetricInput = {
  expression: Scalars['Expression']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type GQUpdateBiSavedViewInput = {
  chartKind: GQBiChartKind;
  customMetrics?: InputMaybe<Array<GQBiCustomMetricRefInput>>;
  dimensions: Array<Scalars['String']['input']>;
  editVisibility: GQBiSavedViewVisibility;
  filters: Array<GQBiQueryFilterInput>;
  /** @deprecated Use stableId instead. */
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  normalizedMeasures: Array<GQBiDynamicNormalizedMeasureInput>;
  order?: InputMaybe<GQBiQueryOrderInput>;
  ownerId: Scalars['ID']['input'];
  queryContext?: InputMaybe<GQBiSavedViewQueryContextInput>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  stableId?: InputMaybe<Scalars['ID']['input']>;
  timeIntervals: Array<Scalars['YMInterval']['input']>;
  viewVisibility: GQBiSavedViewVisibility;
};

export type GQUpdateBuildingInput = {
  buildingKind?: InputMaybe<Scalars['String']['input']>;
  buildingName: Scalars['String']['input'];
  buildingSubkind?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  endYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  id: Scalars['ID']['input'];
  isWithoutNaturalGas?: InputMaybe<Scalars['Boolean']['input']>;
  isWithoutRefrigerants?: InputMaybe<Scalars['Boolean']['input']>;
  leaseType?: InputMaybe<GQBuildingLeaseType>;
  measurementProjectId: Scalars['ID']['input'];
  needsConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  periods?: InputMaybe<Array<GQCreateBuildingPeriodInput>>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  sizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  startYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  supportingDocumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUpdateBuildingPayload = {
  __typename?: 'UpdateBuildingPayload';
  buildingUntyped: GQBuildingUntyped;
  facility: GQFacility;
  organization: GQOrganization;
  updatedUserUploadTasks: Array<GQUserUploadTask>;
};

export type GQUpdateBuildingUtilityInput = {
  cleanPowerContractualInstrument?: InputMaybe<GQCleanPowerContractualInstrument>;
  cleanPowerPercent?: InputMaybe<Scalars['Float']['input']>;
  customFieldValues?: InputMaybe<Array<GQBuildingUtilityCustomFieldInput>>;
  fuelCombustionReason?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isWasteHazardous?: InputMaybe<Scalars['Boolean']['input']>;
  isWasteRadioactive?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  onsiteCleanFuelKind?: InputMaybe<Scalars['String']['input']>;
  periodEnd?: InputMaybe<Scalars['Date']['input']>;
  periodStart?: InputMaybe<Scalars['Date']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  refrigerantLeakageSource?: InputMaybe<GQRefrigerantLeakageSource>;
  refrigerantName?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<GQBuildingUtilityUnit>;
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
  wasteMaterial?: InputMaybe<GQBuildingWasteMaterial>;
  wasteTreatment?: InputMaybe<GQBuildingWasteTreatment>;
  waterUseKind?: InputMaybe<GQBuildingWaterUseKind>;
};

export type GQUpdateBuildingUtilityPayload = {
  __typename?: 'UpdateBuildingUtilityPayload';
  /** @deprecated Use buildingWithUtilities.completionIntervals instead */
  buildingCompletionIntervalsUntyped: GQBuildingCompletionIntervalsUntyped;
  buildingUtility: GQBuildingUtility;
  buildingWithUtilities: GQBuildingWithUtilities;
  updatedUserUploadTask: Maybe<GQUserUploadTask>;
};

export type GQUpdateBuildingsInput = {
  buildings: Array<GQUpdateBuildingInput>;
  /** @deprecated Use UpdateBuildingInput.needsConfirmation instead */
  setNeedsConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateBuildingsPayload = {
  __typename?: 'UpdateBuildingsPayload';
  buildingsUntyped: Array<GQBuildingUntyped>;
  organization: GQOrganization;
  updatedUserUploadTasks: Array<GQUserUploadTask>;
};

export type GQUpdateCalculationTagInput = {
  newName: Scalars['String']['input'];
  oldName: Scalars['String']['input'];
};

export type GQUpdateCalculationTagPayload = {
  __typename?: 'UpdateCalculationTagPayload';
  success: Scalars['Boolean']['output'];
};

export type GQUpdateCandidateRowInput = {
  data: Scalars['JSONString']['input'];
  userUploadCandidateId: Scalars['ID']['input'];
  userUploadCandidateRowId: Scalars['ID']['input'];
};

export type GQUpdateCanonicalClimateProgramProjectInput = {
  commitmentType?: InputMaybe<Scalars['String']['input']>;
  costEstimate?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  hiddenFromMeasurement?: InputMaybe<Scalars['Boolean']['input']>;
  iconTypeOverride?: InputMaybe<GQClimateProgramProjectIconType>;
  id: Scalars['ID']['input'];
  kind?: InputMaybe<GQClimateProgramProjectKind>;
  matchObjectType?: InputMaybe<Scalars['String']['input']>;
  measurementObjectCopy?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reportKind?: InputMaybe<Scalars['String']['input']>;
  routeType?: InputMaybe<Scalars['String']['input']>;
  timeEstimate?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateCanonicalClimateProgramProjectRequirementDatasetInput = {
  additionalNotesMd?: InputMaybe<Scalars['String']['input']>;
  canonicalDatasetId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type GQUpdateCanonicalClimateProgramProjectRequirementGhgpInput = {
  additionalNotesMd?: InputMaybe<Scalars['String']['input']>;
  ghgScope: Scalars['Int']['input'];
  ghgScope3Category?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  minimumFidelity: GQClimateProgramProjectRequirementFidelity;
};

export type GQUpdateCanonicalDatasetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  instructions?: InputMaybe<GQIngestionInstructionsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  selfServeDisabledReason?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateCanonicalDatasourceInput = {
  id: Scalars['ID']['input'];
  instructions?: InputMaybe<GQIngestionInstructionsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateClimateProgramCalendarEventInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
};

export type GQUpdateClimateProgramProjectDatasetRequirementInput = {
  additionalNotesMd?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type GQUpdateClimateProgramProjectInput = {
  completedAt?: InputMaybe<Scalars['Date']['input']>;
  customKind?: InputMaybe<GQClimateProgramProjectKind>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  plannedYear?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type GQUpdateClimateProgramTimelineProjectInput = {
  completedAt?: InputMaybe<Scalars['Date']['input']>;
  customKind?: InputMaybe<GQClimateProgramProjectKind>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  objectType: GQClimateProgramTimelineProjectObjectType;
  plannedYear?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type GQUpdateCommentInput = {
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};

export type GQUpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  comment: GQComment;
};

export type GQUpdateCompanyClimateCommitmentInput = {
  carbonNeutralCommitment?: InputMaybe<GQCarbonNeutralCommitmentInput>;
  cleanEnergyCommitment?: InputMaybe<GQCleanEnergyCommitmentInput>;
  commitmentMadeDate?: InputMaybe<Scalars['Date']['input']>;
  commitmentPeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  commitmentPeriodStart?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  kind: GQCompanyClimateCommitmentKind;
  netZeroCommitment?: InputMaybe<GQNetZeroCommitmentInput>;
  publicUrl?: InputMaybe<Scalars['String']['input']>;
  sbtCommitment?: InputMaybe<GQSbtCommitmentInput>;
};

export type GQUpdateCompanySourceKeyInput = {
  id: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  sourceKey: Scalars['String']['input'];
  sourceNotes?: InputMaybe<Scalars['String']['input']>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  userVisibleSourceNotes?: InputMaybe<Scalars['String']['input']>;
  userVisibleSourceUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateCtsFormInput = {
  ctsFormId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type GQUpdateCtsInput = {
  canonicalDatasetIds: Array<Scalars['ID']['input']>;
  ctsId: Scalars['ID']['input'];
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rank: Scalars['Int']['input'];
};

export type GQUpdateCtsPayload = {
  __typename?: 'UpdateCtsPayload';
  schema: GQCustomerTargetSchema;
};

export type GQUpdateCtsTransformTestCaseInput = {
  ctsTransformTestCaseId: Scalars['ID']['input'];
  inputJson: Scalars['JSONString']['input'];
  name: Scalars['String']['input'];
};

export type GQUpdateCtsTransformTestCasePayload = {
  __typename?: 'UpdateCtsTransformTestCasePayload';
  ctsTransformTestCase: GQCustomerTargetSchemaTransformTestCase;
};

export type GQUpdateDataIssueInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  shouldCcClimateLeads?: InputMaybe<Scalars['Boolean']['input']>;
  shouldPublish?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<GQDataIssueState>;
  tags?: InputMaybe<Array<GQDataIssueTag>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateDataIssuePayload = {
  __typename?: 'UpdateDataIssuePayload';
  issue: GQDataIssue;
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQUpdateDataIssuesPayload = {
  __typename?: 'UpdateDataIssuesPayload';
  payload: Maybe<Array<Maybe<GQUpdateDataIssuePayload>>>;
};

export type GQUpdateDataRegistryBatchMigratorRunInput = {
  batchMigratorRunId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
};

export type GQUpdateDatasetCadenceInput = {
  cadence: GQDatasetCadence;
  id: Scalars['ID']['input'];
};

export type GQUpdateDatasetExportInstructionsInput = {
  exportInstructions?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type GQUpdateDatasetInput = {
  canonicalDatasetId?: InputMaybe<Scalars['ID']['input']>;
  customExplainer?: InputMaybe<Scalars['String']['input']>;
  customName?: InputMaybe<Scalars['String']['input']>;
  customPotentialOverlaps?: InputMaybe<Scalars['String']['input']>;
  customUsedFor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  instructions?: InputMaybe<GQIngestionInstructionsInput>;
};

export type GQUpdateDatasetPayload = {
  __typename?: 'UpdateDatasetPayload';
  dataset: GQDataset;
};

export type GQUpdateDatasourceAdminInput = {
  canonicalDatasourceId?: InputMaybe<Scalars['String']['input']>;
  customerTargetSchemaIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  datasetId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  instructions?: InputMaybe<GQIngestionInstructionsInput>;
  isUserConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notesFromWatershedMd?: InputMaybe<Scalars['String']['input']>;
  refreshCadence?: InputMaybe<GQDatasetCadence>;
};

export type GQUpdateDatasourceInput = {
  canonicalDatasourceId?: InputMaybe<Scalars['ID']['input']>;
  customerTargetSchemaIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  importInstructionsMd?: InputMaybe<Scalars['String']['input']>;
  instructionsConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  isUserConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  refreshCadence?: InputMaybe<GQDatasetCadence>;
};

export type GQUpdateDrilldownSavedViewInput = {
  fields: GQDrilldownSavedViewInput;
  id: Scalars['ID']['input'];
};

export type GQUpdateEacPriceEstimateInput = {
  alternativeCountries: Array<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  defaultProjectId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  minCreditPurchase: Scalars['Int']['input'];
  priceEstimateUsdCents: Scalars['Int']['input'];
  standard: Scalars['String']['input'];
};

export type GQUpdateEacPriceEstimatePayload = {
  __typename?: 'UpdateEacPriceEstimatePayload';
  eacPriceEstimate: GQEacPriceEstimate;
  id: Scalars['ID']['output'];
};

export type GQUpdateEacPriceEstimatesInput = {
  eacPriceEstimates: Array<GQUpdateEacPriceEstimateInput>;
};

export type GQUpdateEacPriceEstimatesPayload = {
  __typename?: 'UpdateEacPriceEstimatesPayload';
  eacPriceEstimates: Array<GQEacPriceEstimate>;
  ids: Array<Scalars['ID']['output']>;
};

export type GQUpdateEmissionsModelReleaseLifecyclePhaseInput = {
  lifecyclePhase: GQEmReleaseLifecyclePhase;
  releaseId: Scalars['ID']['input'];
};

export type GQUpdateEmissionsModelReleaseMetadataInput = {
  displayName: Scalars['String']['input'];
  externalNotes: Scalars['String']['input'];
  internalNotes: Scalars['String']['input'];
  releaseId: Scalars['ID']['input'];
};

export type GQUpdateEmissionsModelStableInput = {
  description: Scalars['String']['input'];
  emissionsModelStableId: Scalars['ID']['input'];
  scope?: InputMaybe<Scalars['String']['input']>;
  tagNames?: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
};

export type GQUpdateEmissionsModelStablePayload = {
  __typename?: 'UpdateEmissionsModelStablePayload';
  emissionsModelStable: GQEmissionsModelStable;
};

export type GQUpdateEmployeeReportInput = {
  climateProgramInfo?: InputMaybe<GQClimateProgramInfoInput>;
  employeeReportId: Scalars['ID']['input'];
  footprintInterval?: InputMaybe<Scalars['YMInterval']['input']>;
  markdownCardInfoContentMd?: InputMaybe<Scalars['String']['input']>;
  markdownCardInfoTitle?: InputMaybe<Scalars['String']['input']>;
  marketplaceInterval?: InputMaybe<Scalars['YMInterval']['input']>;
  programKind?: InputMaybe<GQClimateProgramKind>;
  visibility?: InputMaybe<GQEmployeeReportVisibility>;
};

export type GQUpdateEmployeeReportPayload = {
  __typename?: 'UpdateEmployeeReportPayload';
  employeeReport: GQEmployeeReport;
};

export type GQUpdateEngagementTaskConfigInput = {
  containers?: InputMaybe<Array<GQEngagementTaskContainerInput>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  questions: Array<GQEngagementTaskQuestionInput>;
  sidebarTitle?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateEngagementTaskConfigPayload = {
  __typename?: 'UpdateEngagementTaskConfigPayload';
  engagementTaskConfig: GQEngagementTaskConfig;
};

export type GQUpdateEngagementTaskInput = {
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  engagementTaskId: Scalars['ID']['input'];
  rootAssignee?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<GQCompanySurveyStatus>;
  supplierAssignee?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUpdateEngagementTaskPayload = {
  __typename?: 'UpdateEngagementTaskPayload';
  engagementTask: Maybe<GQEngagementTask>;
  runCeeBackgroundJobId: Maybe<Scalars['ID']['output']>;
};

export type GQUpdateFinanceAutomaticRemindersInput = {
  dataUploadConfig: Scalars['JSONString']['input'];
  dataUploadDate: Scalars['DateTime']['input'];
  footprintFinalizationConfig: Scalars['JSONString']['input'];
  footprintFinalizationDate: Scalars['DateTime']['input'];
};

export type GQUpdateFinanceAutomaticRemindersPayload = {
  __typename?: 'UpdateFinanceAutomaticRemindersPayload';
  financeAutomaticReminders: Array<GQFinanceAutomaticReminder>;
};

export type GQUpdateFinanceColumnWidthInput = {
  id: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
};

export type GQUpdateFinanceSavedViewInput = {
  charts?: InputMaybe<Array<Scalars['JSONString']['input']>>;
  filters?: InputMaybe<Scalars['JSONString']['input']>;
  id: Scalars['ID']['input'];
  layout?: InputMaybe<Scalars['String']['input']>;
  metrics?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateFinanceSavedViewPayload = {
  __typename?: 'UpdateFinanceSavedViewPayload';
  financeSavedView: GQFinanceSavedView;
};

export type GQUpdateFinanceSettingsInput = {
  disabledFinanceDataFlags?: InputMaybe<Array<Scalars['String']['input']>>;
  estimateDownstreamScope3?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateFinanceSettingsPayload = {
  __typename?: 'UpdateFinanceSettingsPayload';
  financeSettings: GQFinanceSettings;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateFinanceTagInput = {
  columnConfiguration?: InputMaybe<Scalars['UntypedData']['input']>;
  columnFormat?: InputMaybe<GQGridColumnFormat>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tagType?: InputMaybe<GQFinanceTagType>;
};

export type GQUpdateFinanceTagPayload = {
  __typename?: 'UpdateFinanceTagPayload';
  financeTag: GQFinanceTag;
};

export type GQUpdateFinanceWorksheetColumnInput = {
  columnFormat?: InputMaybe<GQGridColumnFormat>;
  description?: InputMaybe<Scalars['String']['input']>;
  headerName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type GQUpdateFinanceWorksheetInput = {
  setColumnOrder?: InputMaybe<Array<Scalars['ID']['input']>>;
  setColumnWidth?: InputMaybe<GQUpdateFinanceColumnWidthInput>;
  setHiddenColumns?: InputMaybe<Array<Scalars['ID']['input']>>;
  upsertColumn?: InputMaybe<GQUpdateFinanceWorksheetColumnInput>;
  worksheetId: Scalars['ID']['input'];
};

export type GQUpdateFinanceWorksheetPayload = {
  __typename?: 'UpdateFinanceWorksheetPayload';
  worksheet: GQFinanceWorksheet;
};

export type GQUpdateFinancialsReviewItemInput = {
  id: Scalars['ID']['input'];
  prompt: Scalars['String']['input'];
};

export type GQUpdateFinancialsReviewItemPayload = {
  __typename?: 'UpdateFinancialsReviewItemPayload';
  financialsReviewItem: GQFinancialsReviewItem;
};

export type GQUpdateFootprintAssemblyRunStatusInput = {
  id: Scalars['ID']['input'];
  status: GQFootprintAssemblyRunStatus;
};

export type GQUpdateFootprintAssemblyRunStatusPayload = {
  __typename?: 'UpdateFootprintAssemblyRunStatusPayload';
  footprintAssemblyRun: GQFootprintAssemblyRun;
};

export type GQUpdateFootprintSliceInitiativeInput = {
  costTimeseries?: InputMaybe<GQSimpleTimeseriesInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
  initiativeId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  reductionTimeseries?: InputMaybe<GQSimpleTimeseriesInput>;
  scheduleType?: InputMaybe<GQScheduleType>;
};

export type GQUpdateFootprintSliceInitiativePayload = {
  __typename?: 'UpdateFootprintSliceInitiativePayload';
  initiative: GQFootprintSliceInitiative | GQPrecomputedActivityBasedInitiative;
  plan: GQPlan;
};

export type GQUpdateFootprintSnapshotInput = {
  descriptionMd: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type GQUpdateFootprintSnapshotPayload = {
  __typename?: 'UpdateFootprintSnapshotPayload';
  snapshot: GQFootprintSnapshot;
};

export type GQUpdateFootprintVersionInput = {
  id: Scalars['ID']['input'];
  userChangelogMd: Scalars['String']['input'];
  versionName?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateFootprintVersionPayload = {
  __typename?: 'UpdateFootprintVersionPayload';
  footprintVersion: GQFootprintVersion;
};

export type GQUpdateFootprintVersionPinInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateFootprintVersionPinPayload = {
  __typename?: 'UpdateFootprintVersionPinPayload';
  footprintVersion: GQFootprintVersion;
};

export type GQUpdateForecastFootprintSnapshotIdToLatestInput = {
  orgIds: Array<Scalars['ID']['input']>;
};

export type GQUpdateForecastFootprintSnapshotIdToLatestPayload = {
  __typename?: 'UpdateForecastFootprintSnapshotIdToLatestPayload';
  forecasts: Array<GQForecast>;
};

export type GQUpdateForecastInput = {
  acknowledgedFootprintSnapshotId?: InputMaybe<Scalars['ID']['input']>;
  createdCustomIntensityConfigs?: InputMaybe<
    Array<GQCreateCustomIntensityConfigInput>
  >;
  id: Scalars['ID']['input'];
  intervalEnd?: InputMaybe<Scalars['YearMonth']['input']>;
  referencePeriodInterval?: InputMaybe<Scalars['YMInterval']['input']>;
  updatedCustomIntensityConfigs?: InputMaybe<
    Array<GQCustomIntensityConfigInput>
  >;
};

export type GQUpdateForecastPayload = {
  __typename?: 'UpdateForecastPayload';
  forecast: GQForecast;
  plans: Array<GQPlan>;
};

export type GQUpdateForecastScenarioInput = {
  deletedGrowthForecastIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  growthForecasts?: InputMaybe<Array<GQGrowthForecastInput>>;
  id: Scalars['ID']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateFundInput = {
  excludeAsSandbox?: InputMaybe<Scalars['Boolean']['input']>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  fundCategory?: InputMaybe<Scalars['String']['input']>;
  fundGroup?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nameSortable?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateFundPayload = {
  __typename?: 'UpdateFundPayload';
  fund: GQFund;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateIngestionInstructionsInput = {
  canonicalDatasetId?: InputMaybe<Scalars['ID']['input']>;
  canonicalDatasourceId?: InputMaybe<Scalars['ID']['input']>;
  instructions: GQIngestionInstructionsInput;
};

export type GQUpdateIngestionInstructionsPayload = {
  __typename?: 'UpdateIngestionInstructionsPayload';
  canonicalDataset: Maybe<GQCanonicalDataset>;
  canonicalDatasource: Maybe<GQCanonicalDatasource>;
  id: Scalars['ID']['output'];
};

export type GQUpdateIntegrationConnectionInput = {
  id: Scalars['ID']['input'];
  netsuiteIntegrationSettings?: InputMaybe<GQNetsuiteIntegrationSettingsInput>;
};

export type GQUpdateIntegrationConnectionPayload = {
  __typename?: 'UpdateIntegrationConnectionPayload';
  integrationConnection: GQIntegrationConnection;
};

export type GQUpdateMarketplaceAllocationInstructionInput = {
  amountKwh?: InputMaybe<Scalars['Int']['input']>;
  buildingName?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  footprintKind?: InputMaybe<Scalars['String']['input']>;
  ghgCategoryId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
  isZeroedOut?: InputMaybe<Scalars['Boolean']['input']>;
  locationCity?: InputMaybe<Scalars['String']['input']>;
  locationCountry?: InputMaybe<Scalars['String']['input']>;
  locationGrid?: InputMaybe<Scalars['String']['input']>;
  locationState?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Scalars['String']['input']>;
  purchaseLineItemId?: InputMaybe<Scalars['ID']['input']>;
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  vendorEntity?: InputMaybe<Scalars['String']['input']>;
  vendorSubentity?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMarketplaceAllocationInstructionPayload = {
  __typename?: 'UpdateMarketplaceAllocationInstructionPayload';
  allocationInstruction: GQMarketplaceAllocationInstruction;
};

export type GQUpdateMarketplaceDeveloperInput = {
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMarketplaceDeveloperPayload = {
  __typename?: 'UpdateMarketplaceDeveloperPayload';
  marketplaceDeveloper: GQMarketplaceDeveloper;
};

export type GQUpdateMarketplaceDocumentInput = {
  id: Scalars['ID']['input'];
  kind?: InputMaybe<GQMarketplaceDocumentKind>;
  noteMd?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMarketplaceDocumentPayload = {
  __typename?: 'UpdateMarketplaceDocumentPayload';
  document: GQMarketplaceDocument;
};

export type GQUpdateMarketplacePortfolioInput = {
  budgetUsdCents?: InputMaybe<Scalars['Int53']['input']>;
  cleanPowerTargetAmount?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
  investInRd?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  offsetTargetAmount?: InputMaybe<Scalars['Float']['input']>;
  offsetTargetFraction?: InputMaybe<Scalars['Float']['input']>;
  targetAmount?: InputMaybe<Scalars['Float']['input']>;
  targetFraction?: InputMaybe<Scalars['Float']['input']>;
};

export type GQUpdateMarketplacePortfolioItemInput = {
  amount?: InputMaybe<Scalars['Int53']['input']>;
  archetypeId?: InputMaybe<Scalars['ID']['input']>;
  fraction?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  priceUsdCents?: InputMaybe<Scalars['Int53']['input']>;
};

export type GQUpdateMarketplacePortfolioItemsInput = {
  id: Scalars['ID']['input'];
  portfolioItems: Array<GQUpdateMarketplacePortfolioItemInput>;
};

export type GQUpdateMarketplacePortfolioItemsPayload = {
  __typename?: 'UpdateMarketplacePortfolioItemsPayload';
  portfolio: GQMarketplacePortfolio;
};

export type GQUpdateMarketplacePortfolioPayload = {
  __typename?: 'UpdateMarketplacePortfolioPayload';
  portfolio: GQMarketplacePortfolio;
};

export type GQUpdateMarketplaceProjectArchetypeInput = {
  additionalityRating?: InputMaybe<Scalars['Int']['input']>;
  bannerImageUrl?: InputMaybe<Scalars['String']['input']>;
  certificateBlurb?: InputMaybe<Scalars['String']['input']>;
  certificateColor?: InputMaybe<Scalars['String']['input']>;
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isUserVisible?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<GQMarketplaceProjectArchetypeKind>;
  name?: InputMaybe<Scalars['String']['input']>;
  permanenceRating?: InputMaybe<Scalars['String']['input']>;
  summaryMd?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<GQMarketplaceProjectArchetypeUnit>;
};

export type GQUpdateMarketplaceProjectArchetypePayload = {
  __typename?: 'UpdateMarketplaceProjectArchetypePayload';
  marketplaceProjectArchetype: GQMarketplaceProjectArchetype;
};

export type GQUpdateMarketplaceProjectInput = {
  archetypeId?: InputMaybe<Scalars['ID']['input']>;
  developerId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  internalNotesMd?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  registryId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GQMarketplaceProjectStatus>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUpdateMarketplaceProjectPayload = {
  __typename?: 'UpdateMarketplaceProjectPayload';
  marketplaceProject: GQMarketplaceProject;
};

export type GQUpdateMarketplacePurchaseEacMetadataInput = {
  ghgCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  interval: Scalars['YMInterval']['input'];
  marketTco2e: Scalars['Float']['input'];
  requestNotes?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMarketplacePurchaseInput = {
  eacMetadata?: InputMaybe<GQUpdateMarketplacePurchaseEacMetadataInput>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  noteMd?: InputMaybe<Scalars['String']['input']>;
  offerExpiration?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<GQMarketplacePurchaseStatus>;
};

export type GQUpdateMarketplacePurchaseLineItemEacMetadataInput = {
  countryWithEacs: Scalars['String']['input'];
  countryWithEmissions: Scalars['String']['input'];
  marketTco2e: Scalars['Float']['input'];
  mwh: Scalars['Float']['input'];
  numEacsRequested: Scalars['Int']['input'];
};

export type GQUpdateMarketplacePurchaseLineItemInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountUnit?: InputMaybe<GQMarketplaceProjectArchetypeUnit>;
  appliedToFootprint?: InputMaybe<Scalars['Boolean']['input']>;
  cleanPowerContractualInstrument?: InputMaybe<GQCleanPowerContractualInstrument>;
  closeDate?: InputMaybe<Scalars['Date']['input']>;
  coverageInterval?: InputMaybe<Scalars['YMInterval']['input']>;
  deliveryDate?: InputMaybe<Scalars['Date']['input']>;
  eacMetadata?: InputMaybe<GQUpdateMarketplacePurchaseLineItemEacMetadataInput>;
  id: Scalars['ID']['input'];
  mechanism?: InputMaybe<GQMarketplaceProjectArchetypeMechanism>;
  noteMd?: InputMaybe<Scalars['String']['input']>;
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<GQMarketplacePurchaseLineItemStatus>;
  unitFeeUsdCents?: InputMaybe<Scalars['Float']['input']>;
  unitPriceUsdCents?: InputMaybe<Scalars['Float']['input']>;
};

export type GQUpdateMarketplacePurchaseLineItemPayload = {
  __typename?: 'UpdateMarketplacePurchaseLineItemPayload';
  marketplacePurchase: GQMarketplacePurchase;
  marketplacePurchaseLineItem: GQMarketplacePurchaseLineItem;
};

export type GQUpdateMarketplacePurchasePayload = {
  __typename?: 'UpdateMarketplacePurchasePayload';
  marketplacePurchase: GQMarketplacePurchase;
};

export type GQUpdateMarketplaceSupplierInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMarketplaceSupplierPayload = {
  __typename?: 'UpdateMarketplaceSupplierPayload';
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQUpdateMeasurementContextItemInput = {
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  measurementContextItemId: Scalars['ID']['input'];
  relations?: InputMaybe<Array<GQCreateMeasurementContextItemRelationInput>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  urls?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQUpdateMeasurementContextItemPayload = {
  __typename?: 'UpdateMeasurementContextItemPayload';
  measurementContextItem: GQMeasurementContextItem;
};

export type GQUpdateMeasurementMappingInput = {
  authorExplanation?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endMonth: Scalars['YearMonth']['input'];
  ghgCategoryId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  inputEmissionsSpecifier: Scalars['String']['input'];
  inputEmissionsSubspecifier: Scalars['String']['input'];
  outputEmissionsSpecifier: Scalars['String']['input'];
  outputEmissionsSubspecifier: Scalars['String']['input'];
  shouldInclude: Scalars['Boolean']['input'];
  startMonth: Scalars['YearMonth']['input'];
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  vendorEntity: Scalars['String']['input'];
};

export type GQUpdateMeasurementProjectInput = {
  coverageEndDate?: InputMaybe<Scalars['Date']['input']>;
  coverageStartDate?: InputMaybe<Scalars['Date']['input']>;
  deadline?: InputMaybe<Scalars['Date']['input']>;
  kickoff?: InputMaybe<Scalars['Date']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQUpdateMeasurementProjectPayload = {
  __typename?: 'UpdateMeasurementProjectPayload';
  measurementProject: GQMeasurementProject;
};

export type GQUpdateMeasurementTestSuiteBartInput = {
  businessActivityTypeVersionId?: InputMaybe<Scalars['String']['input']>;
  fieldDefaults?: InputMaybe<Scalars['JSONString']['input']>;
  hiddenFields?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMeasurementTestSuiteBartPayload = {
  __typename?: 'UpdateMeasurementTestSuiteBartPayload';
  measurementTestSuiteBart: GQMeasurementTestSuiteBart;
};

export type GQUpdateMeasurementTestSuiteInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  releaseIds: Array<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMeasurementTestSuitePayload = {
  __typename?: 'UpdateMeasurementTestSuitePayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQUpdateMeasurementVerificationItemPayload = {
  __typename?: 'UpdateMeasurementVerificationItemPayload';
  measurementVerificationItem: GQMeasurementVerificationItem;
};

export type GQUpdateMeasurementVerificationItemQuestionInput = {
  contentKind?: InputMaybe<GQMeasurementVerificationQuestionContentKind>;
  contentMetadataJson?: InputMaybe<Scalars['JSONString']['input']>;
  contentSubtitle?: InputMaybe<Scalars['String']['input']>;
  contentTitle?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  questionKind?: InputMaybe<GQMeasurementVerificationQuestionKind>;
  questionPrompt?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GQMeasurementVerificationQuestionStatus>;
};

export type GQUpdateMeasurementVerificationItemQuestionPayload = {
  __typename?: 'UpdateMeasurementVerificationItemQuestionPayload';
  measurementVerificationItemQuestion: GQMeasurementVerificationItemQuestion;
};

export type GQUpdateOrgForFeatureFlagInput = {
  enabled: Scalars['Boolean']['input'];
  featureFlagId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQUpdateOrgForFeatureFlagPayload = {
  __typename?: 'UpdateOrgForFeatureFlagPayload';
  featureFlag: GQFeatureFlag;
};

export type GQUpdateOrgPointOfContactInput = {
  kind: GQOrgPointOfContactKind;
  orgId: Scalars['ID']['input'];
  orgPointOfContactId: Scalars['ID']['input'];
};

export type GQUpdateOrgPointOfContactPayload = {
  __typename?: 'UpdateOrgPointOfContactPayload';
  org: GQOrganization;
};

export type GQUpdateOrgProfileInput = {
  climateCommitments?: InputMaybe<Array<GQCompanyClimateCommitmentKind>>;
  climateGoals?: InputMaybe<Array<Scalars['String']['input']>>;
  climateMotivation?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  fiscalYearStartMonth?: InputMaybe<Scalars['Int']['input']>;
  hasClimateCommitments?: InputMaybe<GQYesNoUnknown>;
  hasMeasuredFootprintOutsideOfWatershed?: InputMaybe<GQYesNoUnknown>;
  industry?: InputMaybe<Scalars['String']['input']>;
  isCompanyDataConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  isComplete?: InputMaybe<Scalars['Boolean']['input']>;
  lastNonWatershedMeasurementYearString?: InputMaybe<
    Scalars['String']['input']
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  otherClimateCommitment?: InputMaybe<Scalars['String']['input']>;
  otherOperatingCountries?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQUpdateOrgProfilePayload = {
  __typename?: 'UpdateOrgProfilePayload';
  orgProfile: GQOrgProfile;
  organization: GQOrganization;
};

export type GQUpdateOrgUnitInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  relatedOrgUnitIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQUpdateOrgUnitTypeInput = {
  enabledDatasets?: InputMaybe<Array<GQDatasetForOrgUnitTypeInput>>;
  enabledForAllDatasets?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isLegalEntity?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  requiredForAllDatasets?: InputMaybe<Scalars['Boolean']['input']>;
  versionId: Scalars['ID']['input'];
};

export type GQUpdateOrgUnitTypePayload = {
  __typename?: 'UpdateOrgUnitTypePayload';
  orgUnitType: GQOrgUnitType;
};

export type GQUpdateOrgUnitTypeRelationshipInput = {
  cardinality?: InputMaybe<GQOrgUnitRelationshipCardinality>;
  defaultOrgUnitId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateOrgUnitTypeRelationshipPayload = {
  __typename?: 'UpdateOrgUnitTypeRelationshipPayload';
  orgUnitTypeRelationship: GQOrgUnitTypeRelationship;
  orgUnits: Array<GQOrgUnit>;
};

export type GQUpdateOrgUnitsInput = {
  orgUnits: Array<GQUpdateOrgUnitInput>;
};

export type GQUpdateOrgUnitsPayload = {
  __typename?: 'UpdateOrgUnitsPayload';
  orgUnits: Array<GQOrgUnit>;
};

export type GQUpdateOrganizationInput = {
  bigqueryDatasetName?: InputMaybe<Scalars['String']['input']>;
  canAccessCorporate?: InputMaybe<Scalars['Boolean']['input']>;
  canAccessFinance?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  fiscalYearStartMonth?: InputMaybe<Scalars['Int']['input']>;
  helpChannel?: InputMaybe<Scalars['String']['input']>;
  iconRemotePath?: InputMaybe<Scalars['String']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  logoRemotePath?: InputMaybe<Scalars['String']['input']>;
  magicLinkDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notionUrl?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  passwordAuthDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  stagingOrg?: InputMaybe<Scalars['Boolean']['input']>;
  testOrg?: InputMaybe<Scalars['Boolean']['input']>;
  watershedPlan?: InputMaybe<GQWatershedPlan>;
  watershedPlanLegacy?: InputMaybe<GQWatershedPlanLegacy>;
  watershedSlackChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdatePeerCompanyGroupInput = {
  footprintKinds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  revenueForRevenueAtRisk?: InputMaybe<Scalars['Int53']['input']>;
  shouldHideFromFootprintOverview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdatePipelineResultInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type GQUpdatePipelineResultPayload = {
  __typename?: 'UpdatePipelineResultPayload';
  pipelineResult: GQPipelineResult;
};

export type GQUpdatePlanAndArtifactsInput = {
  carbonOffsets?: InputMaybe<GQSimpleTimeseriesInput>;
  carbonOffsetsCosts?: InputMaybe<GQSimpleTimeseriesInput>;
  carbonRemoval?: InputMaybe<GQSimpleTimeseriesInput>;
  carbonRemovalCosts?: InputMaybe<GQSimpleTimeseriesInput>;
  hideCarbonAvoidance?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['ID']['input'];
  reduxCarbonOffsets?: InputMaybe<GQSimpleTimeseriesInput>;
  reduxCarbonOffsetsCosts?: InputMaybe<GQSimpleTimeseriesInput>;
  reduxCarbonRemoval?: InputMaybe<GQSimpleTimeseriesInput>;
  reduxCarbonRemovalCosts?: InputMaybe<GQSimpleTimeseriesInput>;
  targets?: InputMaybe<Array<GQAbbreviatedPlanTargetInput>>;
  variables?: InputMaybe<GQPlanVariablesInput>;
};

export type GQUpdatePlanInput = {
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
  variables?: InputMaybe<GQPlanVariablesInput>;
};

export type GQUpdatePlanPayload = {
  __typename?: 'UpdatePlanPayload';
  plan: GQPlan;
};

export type GQUpdatePlanSettingsInput = {
  baselineYearStart?: InputMaybe<Scalars['YearMonth']['input']>;
  isSbtValidated?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['ID']['input'];
  planSubmissionDate?: InputMaybe<Scalars['YearMonth']['input']>;
  termLength?: InputMaybe<GQSbtTermLength>;
};

export type GQUpdatePlanSettingsPayload = {
  __typename?: 'UpdatePlanSettingsPayload';
  plan: GQPlan;
};

export type GQUpdatePlanStateInput = {
  planId: Scalars['ID']['input'];
  state: GQPlanState;
};

export type GQUpdatePlanStatePayload = {
  __typename?: 'UpdatePlanStatePayload';
  plan: GQPlan;
};

export type GQUpdatePlanTargetCategorySetPayload = {
  __typename?: 'UpdatePlanTargetCategorySetPayload';
  planTarget: GQPlanTarget;
};

export type GQUpdatePlanTargetInput = {
  assignedTo?: InputMaybe<Scalars['String']['input']>;
  baseYear?: InputMaybe<Scalars['YearMonth']['input']>;
  comparisonType?: InputMaybe<GQPlanTargetTargetComparisonType>;
  customIntensityConfigId?: InputMaybe<Scalars['ID']['input']>;
  customNotes?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Switching to targetYearInclusive and reductionAmount */
  emissionsTargetCustom?: InputMaybe<GQSimpleTimeseriesInput>;
  filters?: InputMaybe<GQFilterExpressionGroupInput>;
  id: Scalars['ID']['input'];
  intensityType?: InputMaybe<GQPlanTargetIntensityType>;
  interimTarget?: InputMaybe<GQInterimTargetInput>;
  reductionAmount?: InputMaybe<Scalars['Float']['input']>;
  reductionRate?: InputMaybe<GQTargetReductionRate>;
  reportDescription?: InputMaybe<Scalars['String']['input']>;
  targetYearInclusive?: InputMaybe<Scalars['YearMonth']['input']>;
};

export type GQUpdatePlanTargetPayload = {
  __typename?: 'UpdatePlanTargetPayload';
  forecast: GQForecast;
  planTarget: GQPlanTarget;
};

export type GQUpdatePrecomputedActivityBasedInitiativeInput = {
  costTimeseries?: InputMaybe<GQSimpleTimeseriesInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
  implementationTimeseries?: InputMaybe<GQSimpleTimeseriesInput>;
  initiativeId: Scalars['ID']['input'];
  lever?: InputMaybe<GQPrecomputedLever>;
  scheduleType?: InputMaybe<GQScheduleType>;
};

export type GQUpdatePrecomputedActivityBasedInitiativePayload = {
  __typename?: 'UpdatePrecomputedActivityBasedInitiativePayload';
  initiative: GQFootprintSliceInitiative | GQPrecomputedActivityBasedInitiative;
  plan: GQPlan;
};

export type GQUpdatePrivateDisclosureInput = {
  disclosureTargets?: InputMaybe<Array<GQDisclosureTargetInput>>;
  privateDisclosureId: Scalars['ID']['input'];
};

export type GQUpdatePrivateDisclosurePayload = {
  __typename?: 'UpdatePrivateDisclosurePayload';
  disclosure: GQDisclosure;
};

export type GQUpdatePublicDisclosureTargetInput = {
  disclosureTarget: GQDisclosureTargetInput;
  id: Scalars['ID']['input'];
};

export type GQUpdatePublicDisclosureTargetPayload = {
  __typename?: 'UpdatePublicDisclosureTargetPayload';
  disclosureTarget: GQDisclosureTargetDetails;
};

export type GQUpdateReferenceDataCitationInput = {
  displayName: Scalars['String']['input'];
  externalSourceNotes?: InputMaybe<Scalars['String']['input']>;
  externalSourceUrl?: InputMaybe<Scalars['String']['input']>;
  hasLicenseRestrictions: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  internalSourceNotes: Scalars['String']['input'];
  internalSourceUrl: Scalars['String']['input'];
  privateDisclosureId?: InputMaybe<Scalars['String']['input']>;
  publicDisclosureId?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateReferenceDataCitationPayload = {
  __typename?: 'UpdateReferenceDataCitationPayload';
  referenceDataCitation: GQReferenceDataCitation;
};

export type GQUpdateReferenceDataRevisionInput = {
  id: Scalars['String']['input'];
  internalSourceNotes?: InputMaybe<Scalars['String']['input']>;
  outputFileId?: InputMaybe<Scalars['String']['input']>;
  revisionName?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateReferenceDataRevisionPayload = {
  __typename?: 'UpdateReferenceDataRevisionPayload';
  referenceDataRevision: GQReferenceDataRevision;
};

export type GQUpdateReferenceDataSourceInput = {
  externalSourceNotes?: InputMaybe<Scalars['String']['input']>;
  externalSourceUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateReferenceDataSourcePayload = {
  __typename?: 'UpdateReferenceDataSourcePayload';
  referenceDataSource: GQReferenceDataSource;
};

export type GQUpdateReferenceDataVersionInput = {
  id: Scalars['String']['input'];
  versionName?: InputMaybe<Scalars['String']['input']>;
  versionVintage?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GQUpdateReferenceDataVersionPayload = {
  __typename?: 'UpdateReferenceDataVersionPayload';
  referenceDataVersion: GQReferenceDataVersion;
};

export type GQUpdateReferenceDataVersionSchemaInput = {
  id: Scalars['String']['input'];
  schemaJson: Scalars['JSONSchema']['input'];
};

export type GQUpdateReferenceDataVersionSchemaPayload = {
  __typename?: 'UpdateReferenceDataVersionSchemaPayload';
  referenceDataVersion: GQReferenceDataVersion;
};

export type GQUpdateReportConfigDashboardInput = {
  id: Scalars['ID']['input'];
  itemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQUpdateReportConfigDashboardPayload = {
  __typename?: 'UpdateReportConfigDashboardPayload';
  reportConfig: GQReportConfig;
};

export type GQUpdateReportConfigInput = {
  brandColor?: InputMaybe<Scalars['String']['input']>;
  computedQuestionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  inputQuestionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isCorporateReport?: InputMaybe<Scalars['Boolean']['input']>;
  isCreationBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  isFinanceReport?: InputMaybe<Scalars['Boolean']['input']>;
  isHiddenFromOverview?: InputMaybe<Scalars['Boolean']['input']>;
  isRegulatoryExposureBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  itemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  longName?: InputMaybe<Scalars['String']['input']>;
  reportObjectives?: InputMaybe<Array<GQCanonicalReportObjective>>;
  reportType?: InputMaybe<Scalars['String']['input']>;
  requiredPermissions?: InputMaybe<Array<GQPermissionType>>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GQReportConfigStatus>;
  useReportingIntention?: InputMaybe<Scalars['Boolean']['input']>;
  useTableDisplay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateReportConfigPayload = {
  __typename?: 'UpdateReportConfigPayload';
  reportConfig: GQReportConfig;
};

export type GQUpdateReportInput = {
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['ID']['input'];
  reportStatus?: InputMaybe<GQReportStatus>;
};

export type GQUpdateReportPayload = {
  __typename?: 'UpdateReportPayload';
  report: GQReport;
};

export type GQUpdateReportQuestionAssigneesInput = {
  assigneeIds: Array<Scalars['ID']['input']>;
  ignoredAssigneeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionIds: Array<Scalars['ID']['input']>;
  reportId: Scalars['ID']['input'];
  reportShortName?: InputMaybe<Scalars['String']['input']>;
  shouldSendEmail: Scalars['Boolean']['input'];
};

export type GQUpdateReportQuestionAssigneesPayload = {
  __typename?: 'UpdateReportQuestionAssigneesPayload';
  questions: Array<GQReportQuestion>;
};

export type GQUpdateReportQuestionContainerDashboardInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateReportQuestionContainerDashboardPayload = {
  __typename?: 'UpdateReportQuestionContainerDashboardPayload';
  container: GQReportQuestionContainer;
};

export type GQUpdateReportQuestionContainerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fullWidth?: InputMaybe<Scalars['Boolean']['input']>;
  guidance?: InputMaybe<Scalars['JSONString']['input']>;
  id: Scalars['ID']['input'];
  items?: InputMaybe<Array<Scalars['ID']['input']>>;
  label?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQUpdateReportQuestionContainerPayload = {
  __typename?: 'UpdateReportQuestionContainerPayload';
  container: GQReportQuestionContainer;
};

export type GQUpdateReportQuestionIdentifierMappingPayload = {
  __typename?: 'UpdateReportQuestionIdentifierMappingPayload';
  reportQuestionIdentifierMapping1: GQReportQuestionIdentifierMapping;
  reportQuestionIdentifierMapping2: GQReportQuestionIdentifierMapping;
};

export type GQUpdateReportQuestionInput = {
  allowNotes?: InputMaybe<Scalars['Boolean']['input']>;
  allowedAttachmentKinds?: InputMaybe<Array<GQReportAttachmentItemKind>>;
  componentId?: InputMaybe<Scalars['String']['input']>;
  dynamicInputs?: InputMaybe<Scalars['JSONString']['input']>;
  guidance?: InputMaybe<Scalars['JSONString']['input']>;
  id: Scalars['ID']['input'];
  identifier?: InputMaybe<Scalars['String']['input']>;
  isComputed?: InputMaybe<Scalars['Boolean']['input']>;
  isDynamic?: InputMaybe<Scalars['Boolean']['input']>;
  staticInputs?: InputMaybe<Scalars['JSONString']['input']>;
  status?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQUpdateReportQuestionNoteInput = {
  reportId: Scalars['String']['input'];
  reportQuestionId: Scalars['String']['input'];
  resolved?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateReportQuestionNoteItemInput = {
  markdown?: InputMaybe<Scalars['String']['input']>;
  memberUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  reportId: Scalars['String']['input'];
  reportQuestionId: Scalars['String']['input'];
  reportQuestionNoteId: Scalars['String']['input'];
  reportQuestionNoteItemId: Scalars['String']['input'];
  status?: InputMaybe<GQReportQuestionNoteItemStatus>;
};

export type GQUpdateReportQuestionNoteItemPayload = {
  __typename?: 'UpdateReportQuestionNoteItemPayload';
  discussion: GQDiscussion;
  reportQuestion: GQReportQuestion;
};

export type GQUpdateReportQuestionNotePayload = {
  __typename?: 'UpdateReportQuestionNotePayload';
  discussion: GQDiscussion;
  reportQuestion: GQReportQuestion;
};

export type GQUpdateReportQuestionPayload = {
  __typename?: 'UpdateReportQuestionPayload';
  question: GQReportQuestion;
};

export type GQUpdateReportingIntentionsInput = {
  intention: GQReportingIntention;
  reason?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['ID']['input'];
  reportQuestionIds: Array<Scalars['ID']['input']>;
};

export type GQUpdateReportingIntentionsPayload = {
  __typename?: 'UpdateReportingIntentionsPayload';
  reportQuestions: Array<GQReportQuestion>;
};

export type GQUpdateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions: Array<GQPermissionItemInput>;
  roleId: Scalars['ID']['input'];
  watershedManaged?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateRolePayload = {
  __typename?: 'UpdateRolePayload';
  role: GQRole;
};

export type GQUpdateSbtiTargetExclusionInput = {
  filters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
  sbtiTargetExclusionId: Scalars['ID']['input'];
};

export type GQUpdateSbtiTargetExclusionPayload = {
  __typename?: 'UpdateSbtiTargetExclusionPayload';
  org: GQOrganization;
};

export type GQUpdateSimpleTimeseriesInput = {
  base?: InputMaybe<Scalars['Date']['input']>;
  frequency?: InputMaybe<GQTimeseriesFrequency>;
  id: Scalars['ID']['input'];
  values?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type GQUpdateSimpleTimeseriesPayload = {
  __typename?: 'UpdateSimpleTimeseriesPayload';
  timeseries: GQSimpleTimeseries;
};

export type GQUpdateSnapshotNameInput = {
  id: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  snapshotName: Scalars['String']['input'];
};

export type GQUpdateSnapshotNamePayload = {
  __typename?: 'UpdateSnapshotNamePayload';
  snapshot: GQFinanceSnapshot;
};

export type GQUpdateSupplierAssigneesForUserInput = {
  allSuppliers: Array<Scalars['ID']['input']>;
  supplierSubscriptions: Array<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type GQUpdateSupplierAssigneesForUserPayload = {
  __typename?: 'UpdateSupplierAssigneesForUserPayload';
  optOutsAdded: Array<Scalars['String']['output']>;
  optOutsRemoved: Array<Scalars['String']['output']>;
  user: GQUser;
};

export type GQUpdateSupplierAttachmentInput = {
  companyId: Scalars['ID']['input'];
  fileMetadataId: Scalars['String']['input'];
  isImported: Scalars['Boolean']['input'];
};

export type GQUpdateSupplierAttachmentPayload = {
  __typename?: 'UpdateSupplierAttachmentPayload';
  attachment: GQSupplierAttachment;
};

export type GQUpdateSupplierChartInput = {
  id: Scalars['ID']['input'];
  supplierChartInput: GQSupplierChartInput;
};

export type GQUpdateSupplierChartInputAdmin = {
  chartId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  supplierChartInput: GQSupplierChartInput;
};

export type GQUpdateSupplierChartPayload = {
  __typename?: 'UpdateSupplierChartPayload';
  views: Array<GQSupplierView>;
};

export type GQUpdateSupplierChartPayloadAdmin = {
  __typename?: 'UpdateSupplierChartPayloadAdmin';
  views: Array<GQSupplierView>;
};

export type GQUpdateSupplierColumnOrderInput = {
  columnIds: Array<Scalars['ID']['input']>;
  viewId: Scalars['ID']['input'];
};

export type GQUpdateSupplierColumnOrderPayload = {
  __typename?: 'UpdateSupplierColumnOrderPayload';
  view: GQSupplierView;
};

export type GQUpdateSupplierColumnVisibilityBatchInput = {
  columnVisibilities: Array<GQColumnVisibility>;
  viewId: Scalars['ID']['input'];
};

export type GQUpdateSupplierColumnVisibilityBatchPayload = {
  __typename?: 'UpdateSupplierColumnVisibilityBatchPayload';
  view: GQSupplierView;
};

export type GQUpdateSupplierColumnWidthInput = {
  columnId: Scalars['ID']['input'];
  viewId: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
};

export type GQUpdateSupplierColumnWidthPayload = {
  __typename?: 'UpdateSupplierColumnWidthPayload';
  view: GQSupplierView;
};

export type GQUpdateSupplierContactInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateSupplierContactPayload = {
  __typename?: 'UpdateSupplierContactPayload';
  contact: GQSupplierContact;
};

export type GQUpdateSupplierContactsInput = {
  contacts: Array<GQUpdateSupplierContactInput>;
};

export type GQUpdateSupplierContactsPayload = {
  __typename?: 'UpdateSupplierContactsPayload';
  contacts: Array<GQSupplierContact>;
};

export type GQUpdateSupplierCustomDataInput = {
  companyId: Scalars['ID']['input'];
  customData?: InputMaybe<Scalars['JSONString']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<GQSupplierPriority>;
  supplierId: Scalars['ID']['input'];
};

export type GQUpdateSupplierCustomDataPayload = {
  __typename?: 'UpdateSupplierCustomDataPayload';
  supplierCustomData: GQSupplierCustomData;
  supplierId: Scalars['ID']['output'];
};

export type GQUpdateSupplierSavedViewInput = {
  chartOrder?: InputMaybe<Array<GQChartOrderInput>>;
  filters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateSupplierSavedViewPayload = {
  __typename?: 'UpdateSupplierSavedViewPayload';
  view: GQSupplierView;
};

export type GQUpdateSupplierScoreCriteriaInput = {
  filters?: InputMaybe<Array<GQBiQueryFilterInput>>;
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateSupplierScoreCriteriaPayload = {
  __typename?: 'UpdateSupplierScoreCriteriaPayload';
  supplierScoreCriteria: GQSupplierScoreCriteria;
};

export type GQUpdateSupplierTableColumnInput = {
  columnFormat?: InputMaybe<GQSupplierTableColumnFormat>;
  description?: InputMaybe<Scalars['String']['input']>;
  headerName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  selectOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateSupplierTableColumnPayload = {
  __typename?: 'UpdateSupplierTableColumnPayload';
  views: Array<GQSupplierView>;
};

export type GQUpdateSuppliersSettingsAdminInput = {
  orgId: Scalars['ID']['input'];
  updateSuppliersSettingsInput: GQUpdateSuppliersSettingsInput;
};

export type GQUpdateSuppliersSettingsInput = {
  hideGlobalCharts?: InputMaybe<Scalars['Boolean']['input']>;
  includedGhgCategories?: InputMaybe<Array<Scalars['String']['input']>>;
  suppressNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  viewLatestSupplierMapping?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateSuppliersSettingsPayload = {
  __typename?: 'UpdateSuppliersSettingsPayload';
  suppliersSettings: GQSuppliersSettings;
};

export type GQUpdateTargetInput = {
  target: GQTargetInput;
  targetId: Scalars['ID']['input'];
};

export type GQUpdateTargetPayload = {
  __typename?: 'UpdateTargetPayload';
  org: GQOrganization;
  target: GQTarget;
};

export type GQUpdateTcfdArchetypeOpportunityInput = {
  archetypeRiskId?: InputMaybe<Scalars['ID']['input']>;
  distributionModel?: InputMaybe<GQTcfdStrategyModuleDistributionModel>;
  geographies?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  sectors?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  userDescription?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateTcfdArchetypeRiskInput = {
  distributionModel?: InputMaybe<GQTcfdStrategyModuleDistributionModel>;
  geographies?: InputMaybe<Array<Scalars['String']['input']>>;
  highTrajectoryImpact?: InputMaybe<GQTcfdRiskImpact>;
  id: Scalars['ID']['input'];
  kind?: InputMaybe<GQTcfdRiskKind>;
  lowTrajectoryImpact?: InputMaybe<GQTcfdRiskImpact>;
  mediumTrajectoryImpact?: InputMaybe<GQTcfdRiskImpact>;
  physicalRiskSeverity?: InputMaybe<GQTcfdPhysicalRiskSeverity>;
  possibleImpactDescription?: InputMaybe<Scalars['String']['input']>;
  sectors?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  userDescription?: InputMaybe<Scalars['String']['input']>;
  whyWeChoseThisMd?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateTestRowsInTestSuiteBartInput = {
  activityRows: Array<GQUpdateTestRowsInTestSuiteBartRowInput>;
  id: Scalars['ID']['input'];
};

export type GQUpdateTestRowsInTestSuiteBartPayload = {
  __typename?: 'UpdateTestRowsInTestSuiteBartPayload';
  measurementTestSuiteBart: GQMeasurementTestSuiteBart;
};

export type GQUpdateTestRowsInTestSuiteBartRowInput = {
  activityRow: Scalars['JSONString']['input'];
  id: Scalars['ID']['input'];
};

export type GQUpdateUserEmailAdminVersionInput = {
  newEmail: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQUpdateUserEmailAdminVersionPayload = {
  __typename?: 'UpdateUserEmailAdminVersionPayload';
  user: GQUser;
  watershedEmployeeUpdated: Scalars['Boolean']['output'];
};

export type GQUpdateUserIngestionReviewInput = {
  additionalQuestions?: InputMaybe<Array<GQAdditionalQuestionInput>>;
  /** @deprecated unused */
  canonicalDatasetKind?: InputMaybe<GQCanonicalDatasetKind>;
  /** @deprecated unused */
  datasourceId?: InputMaybe<Scalars['String']['input']>;
  ingestionSchemaId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated unused */
  lastUploadInSessionCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  measurementEventKind: GQDataIngestionMeasurementEventKind;
  /** @deprecated unused */
  measurementProjectId?: InputMaybe<Scalars['String']['input']>;
  shouldTriggerParseAttempt?: InputMaybe<Scalars['Boolean']['input']>;
  userReviewMetadata?: InputMaybe<GQUserReviewMetadataInput>;
  userUploadedTableId: Scalars['ID']['input'];
};

export type GQUpdateUserIngestionReviewPayload = {
  __typename?: 'UpdateUserIngestionReviewPayload';
  userIngestionReview: GQUserIngestionReview;
  userUploadedTable: GQUserUploadedTable;
};

export type GQUpdateUserNameAdminVersionInput = {
  newName: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type GQUpdateUserNameAdminVersionPayload = {
  __typename?: 'UpdateUserNameAdminVersionPayload';
  user: GQUser;
  watershedEmployeeUpdated: Scalars['Boolean']['output'];
};

export type GQUpdateUserNameInput = {
  newName: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type GQUpdateUserNamePayload = {
  __typename?: 'UpdateUserNamePayload';
  user: GQUser;
};

export type GQUpdateUserPermissionRequestInput = {
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<GQUserPermissionRequestState>;
  userPermissionRequestId: Scalars['ID']['input'];
};

export type GQUpdateUserPermissionRequestPayload = {
  __typename?: 'UpdateUserPermissionRequestPayload';
  userPermissionRequest: Maybe<GQUserPermissionRequest>;
};

export type GQUpdateUserUploadInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['ID']['input'];
};

export type GQUpdateUserUploadPayload = {
  __typename?: 'UpdateUserUploadPayload';
  userUpload: Maybe<GQUserUpload>;
};

export type GQUpdateUserUploadTaskInput = {
  datasourceId?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<GQUserUploadTaskState>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQUpdateUserUploadTaskPayload = {
  __typename?: 'UpdateUserUploadTaskPayload';
  userUploadTask: GQUserUploadTask;
};

export type GQUpdateWatershedFootprintReviewRequestStatusInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  shouldSendReplyToDiscussionThread?: InputMaybe<Scalars['Boolean']['input']>;
  status: GQWatershedFootprintReviewRequestStatus;
  watershedFootprintReviewRequestId: Scalars['ID']['input'];
};

export type GQUpdateWatershedFootprintReviewRequestStatusPayload = {
  __typename?: 'UpdateWatershedFootprintReviewRequestStatusPayload';
  watershedFootprintReviewRequest: GQWatershedFootprintReviewRequest;
};

export type GQUploadMarketplaceDocumentInput = {
  fileMetadataId: Scalars['ID']['input'];
  kind: GQMarketplaceDocumentKind;
  name: Scalars['String']['input'];
  noteMd?: InputMaybe<Scalars['String']['input']>;
  purchaseId?: InputMaybe<Scalars['ID']['input']>;
  purchaseLineItemId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUploadMarketplaceDocumentsInput = {
  documents: Array<GQUploadMarketplaceDocumentInput>;
};

export type GQUploadMarketplaceDocumentsPayload = {
  __typename?: 'UploadMarketplaceDocumentsPayload';
  documents: Array<GQMarketplaceDocument>;
};

export type GQUpsertCdpColumnMappingsInput = {
  mappings: Array<GQCdpColumnMappingInput>;
};

export type GQUpsertCdpColumnMappingsPayload = {
  __typename?: 'UpsertCdpColumnMappingsPayload';
  updatedMappings: Array<GQCdpColumnIdMapping>;
};

export type GQUpsertCdpQuestionMappingsInput = {
  mappings: Array<GQCdpQuestionMappingInput>;
};

export type GQUpsertCdpQuestionMappingsPayload = {
  __typename?: 'UpsertCdpQuestionMappingsPayload';
  updatedMappings: Array<GQCdpQuestionIdMapping>;
};

export type GQUpsertCompanyCustomDataInput = {
  companyId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  priority?: InputMaybe<GQSupplierPriority>;
};

export type GQUpsertCompanyPayload = {
  __typename?: 'UpsertCompanyPayload';
  company: GQCompany;
};

export type GQUpsertCompanyPortalSettingsInput = {
  portalCallout?: InputMaybe<Scalars['String']['input']>;
  portalTitle: Scalars['String']['input'];
  sidebarContent?: InputMaybe<Scalars['String']['input']>;
  toggleHeaderImage?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpsertCompanyPortalSettingsPayload = {
  __typename?: 'UpsertCompanyPortalSettingsPayload';
  companyPortalSettings: GQCompanyPortalSettings;
};

export type GQUpsertCompanyReportingFactsInput = {
  asOfMonth: Scalars['YearMonth']['input'];
  companyId: Scalars['ID']['input'];
  updateFields: GQCompanyReportingFactsInput;
};

export type GQUpsertCompanyReportingFactsPayload = {
  __typename?: 'UpsertCompanyReportingFactsPayload';
  companyReportingFacts: GQCompanyReportingFacts;
};

export type GQUpsertDocumentParseAttemptInput = {
  modelType: GQDocumentParseAttemptModelType;
  userUploadId: Scalars['ID']['input'];
  utilityUsageData?: InputMaybe<GQUtilityUsageDataInput>;
};

export type GQUpsertEngagementTaskAnswersInput = {
  answers: Array<GQEngagementTaskAnswerInput>;
  engagementTaskId: Scalars['ID']['input'];
};

export type GQUpsertEngagementTaskAnswersPayload = {
  __typename?: 'UpsertEngagementTaskAnswersPayload';
  engagementTask: Maybe<GQEngagementTask>;
};

export type GQUpsertFootprintCategoryRulesInput = {
  rules: Array<GQFootprintCategoryRuleInput>;
};

export type GQUpsertFootprintCategoryRulesPayload = {
  __typename?: 'UpsertFootprintCategoryRulesPayload';
  organization: GQOrganization;
};

export type GQUpsertFootprintExclusionRulesInput = {
  rules: Array<GQFootprintExclusionRuleInput>;
  userChangelogMd?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpsertFootprintExclusionRulesPayload = {
  __typename?: 'UpsertFootprintExclusionRulesPayload';
  organization: GQOrganization;
};

export type GQUpsertFootprintTestSuiteConfigInput = {
  footprintTestSuiteConfigFields: GQFootprintTestSuiteConfigFields;
  measurementTestSuiteId: Scalars['ID']['input'];
};

export type GQUpsertNonFootprintSupplierInput = {
  companyId: Scalars['ID']['input'];
  tags: Array<GQStringKeyValueInput>;
};

export type GQUpsertNonFootprintSupplierPayload = {
  __typename?: 'UpsertNonFootprintSupplierPayload';
  supplier: GQSupplier;
};

export type GQUpsertOrgCompanyForNameInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  source: GQOrgCompanySource;
};

export type GQUpsertOrgCompanyForNamePayload = {
  __typename?: 'UpsertOrgCompanyForNamePayload';
  orgCompany: GQOrgCompany;
};

export type GQUpsertOrgStructureVersionInput = {
  effectiveFromDate?: InputMaybe<Scalars['DateTime']['input']>;
  effectiveToDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type GQUpsertOrgStructureVersionPayload = {
  __typename?: 'UpsertOrgStructureVersionPayload';
  orgStructureVersion: GQOrgStructureVersion;
};

export type GQUpsertReportAnswerInput = {
  oldOutputJson?: InputMaybe<Scalars['JSONString']['input']>;
  outputJson?: InputMaybe<Scalars['JSONString']['input']>;
  questionId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
  unparsedInputJson: Scalars['JSONString']['input'];
};

export type GQUpsertReportAnswerPayload = {
  __typename?: 'UpsertReportAnswerPayload';
  doesConflict: Scalars['Boolean']['output'];
  updatedAnswer: GQReportAnswer;
};

export type GQUser = GQIdInterface &
  GQPerson & {
    __typename?: 'User';
    accessibleOrgs: Array<GQOrgLimitedProfile>;
    consecutiveLoginFailures: Scalars['Int']['output'];
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    didLatestEmailBounce: Scalars['Boolean']['output'];
    displayName: Scalars['String']['output'];
    email: Scalars['String']['output'];
    engagementNotificationSettings: Array<GQEngagementNotificationSetting>;
    hasAnyPermissions: Scalars['Boolean']['output'];
    hasPermission: Scalars['Boolean']['output'];
    hasPermissions: Scalars['Boolean']['output'];
    hasValidAppSessions: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    isE2ETester: Scalars['Boolean']['output'];
    /** @deprecated Please use isWatershedEmployee or isWatershedContractor */
    isWatershed: Scalars['Boolean']['output'];
    isWatershedContractor: Scalars['Boolean']['output'];
    isWatershedEmployee: Scalars['Boolean']['output'];
    loginActivated: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    onboardingsCompleted: Array<GQOnboardingKind>;
    orgAccessCreatedAt: Scalars['DateTime']['output'];
    orgAccessId: Scalars['ID']['output'];
    permissionOrigin: Maybe<GQPermissionOrigin>;
    permissions: Array<GQPermissionItem>;
    preferredLocale: Maybe<Scalars['String']['output']>;
    primaryLoginOrgId: Scalars['ID']['output'];
    roles: Array<GQUserRoleAssignment>;
    signupLink: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['DateTime']['output'];
    userUploadTasks: Array<GQUserUploadTask>;
  };

export type GQUserEngagementNotificationSettingsArgs = {
  side: GQEngagementNotificationSide;
};

export type GQUserHasAnyPermissionsArgs = {
  orgId: Scalars['ID']['input'];
  permissions: Array<GQHasPermissionInput>;
};

export type GQUserHasPermissionArgs = {
  objectId: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  permission: GQPermissionType;
};

export type GQUserHasPermissionsArgs = {
  orgId: Scalars['ID']['input'];
  permissions: Array<GQHasPermissionInput>;
};

export type GQUserOrgAccessCreatedAtArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQUserOrgAccessIdArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQUserPermissionOriginArgs = {
  objectId: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  permission: InputMaybe<GQPermissionType>;
  permissions: InputMaybe<Array<GQHasPermissionInput>>;
};

export type GQUserPermissionsArgs = {
  includeDeletedOrgAccess: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQUserRolesArgs = {
  includeDeletedOrgAccess: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQUserSignupLinkArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQUserConnection = {
  __typename?: 'UserConnection';
  edges: Array<Maybe<GQUserEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'User' and its cursor. */
export type GQUserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUser>;
};

export type GQUserFilterInput = {
  includeWatershedEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  withSomePermissions?: InputMaybe<Array<GQHasPermissionInput>>;
};

export type GQUserForOrgId = {
  __typename?: 'UserForOrgId';
  orgId: Scalars['ID']['output'];
  user: Maybe<GQUser>;
};

export type GQUserIngestionReview = {
  __typename?: 'UserIngestionReview';
  id: Scalars['ID']['output'];
  ingestionSchemaId: Maybe<Scalars['String']['output']>;
  userUploadedTableId: Scalars['ID']['output'];
};

export type GQUserInputtedForecastPeriodPoint = {
  __typename?: 'UserInputtedForecastPeriodPoint';
  customGrowthFactors: Array<GQForecastCustomIntensityDataPoint>;
  date: Scalars['Date']['output'];
};

export type GQUserInputtedForecastPeriodPointInput = {
  customGrowthFactors: Array<GQForecastCustomIntensityDataPointInput>;
  date: Scalars['Date']['input'];
};

export type GQUserPermissionRequest = {
  __typename?: 'UserPermissionRequest';
  creatorOrgId: Maybe<Scalars['ID']['output']>;
  creatorUser: Maybe<Scalars['ID']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  permission: Maybe<GQPermissionType>;
  respondedAt: Maybe<Scalars['DateTime']['output']>;
  responderUser: Maybe<Scalars['ID']['output']>;
  state: GQUserPermissionRequestState;
  userId: Maybe<Scalars['ID']['output']>;
};

export const GQUserPermissionRequestState = {
  Approved: 'Approved',
  Pending: 'Pending',
  Rejected: 'Rejected',
} as const;

export type GQUserPermissionRequestState =
  (typeof GQUserPermissionRequestState)[keyof typeof GQUserPermissionRequestState];
export type GQUserReviewMetadataInput = {
  buildingAreaUnitOverride?: InputMaybe<GQBuildingSizeUnit>;
  columnMappings?: InputMaybe<Array<GQColumnMappingInput>>;
  currencyOverride?: InputMaybe<GQCurrencyOverrideInput>;
  headerRowIndex?: InputMaybe<Scalars['Int']['input']>;
  skipBottomNRows?: InputMaybe<Scalars['Int']['input']>;
  stepsCompleted?: InputMaybe<Array<GQImportFlowDataCleaningStep>>;
};

export type GQUserRoleAssignment = {
  __typename?: 'UserRoleAssignment';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  org: GQOrganization;
  revokedAt: Maybe<Scalars['DateTime']['output']>;
  role: GQRole;
  updatedAt: Scalars['DateTime']['output'];
  user: GQUser;
};

export const GQUserTcfdRiskOrOpportunitySelectionState = {
  Selected: 'Selected',
  Unselected: 'Unselected',
} as const;

export type GQUserTcfdRiskOrOpportunitySelectionState =
  (typeof GQUserTcfdRiskOrOpportunitySelectionState)[keyof typeof GQUserTcfdRiskOrOpportunitySelectionState];
export type GQUserUpload = GQIdInterface & {
  __typename?: 'UserUpload';
  actualUserUploadId: Maybe<Scalars['ID']['output']>;
  allAttempts: Array<GQUserUploadAttempt>;
  /** @deprecated Use UserUploadCandidate */
  apiUpload: Maybe<GQApiUpload>;
  associatedUtilityCount: Scalars['Int']['output'];
  category: GQFileCategory;
  createdAt: Scalars['DateTime']['output'];
  ctsVersion: Maybe<GQCustomerTargetSchemaVersion>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  errorReason: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isBeingValueMapped: Scalars['Boolean']['output'];
  latestAdeIdForSDIFacilitiesFlow: Maybe<Scalars['String']['output']>;
  latestAttempt: GQUserUploadAttempt;
  measurementProject: Maybe<GQMeasurementProject>;
  name: Scalars['String']['output'];
  org: GQOrganization;
  processingMode: Maybe<GQUserUploadProcessingMode>;
  processingWorkflowId: Maybe<Scalars['String']['output']>;
  revisionRoot: Maybe<Scalars['ID']['output']>;
  status: GQUserUploadStatus;
  transformsIncludingDeleted: Array<GQCustomerTargetSchemaTransform>;
  uploader: Maybe<GQUser>;
  /** @deprecated Use userUploadKind */
  userUploadCandidate: Maybe<GQUserUploadCandidate>;
  userUploadKind: Maybe<GQUserUploadCandidateKind>;
  userUploadTask: Maybe<GQUserUploadTask>;
  userVisibleAttempt: Maybe<GQUserUploadAttempt>;
  userVisibleErrorMessage: Maybe<Scalars['String']['output']>;
};

export type GQUserUploadMeasurementProjectArgs = {
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUserUploadAttempt = GQIdInterface & {
  __typename?: 'UserUploadAttempt';
  category: GQFileCategory;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  gcloudStorageUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  latestUutsIfRevisionExists: Maybe<Array<GQUserUploadedTable>>;
  name: Scalars['String']['output'];
  previewUrl: Scalars['String']['output'];
  remoteWritten: Scalars['Boolean']['output'];
  size: Scalars['Int53']['output'];
  uploader: Maybe<GQUser>;
  userUpload: GQUserUpload;
  userUploadSource: Maybe<GQUserUploadSource>;
  userUploadedTables: Array<GQUserUploadedTable>;
};

export type GQUserUploadAttemptConnection = {
  __typename?: 'UserUploadAttemptConnection';
  edges: Array<Maybe<GQUserUploadAttemptEdge>>;
  pageInfo: GQPageInfo;
};

export type GQUserUploadAttemptEdge = {
  __typename?: 'UserUploadAttemptEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUserUploadAttempt>;
};

export type GQUserUploadCandidate = {
  __typename?: 'UserUploadCandidate';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  userUploadKind: GQUserUploadCandidateKind;
};

export type GQUserUploadCandidateEntry = {
  __typename?: 'UserUploadCandidateEntry';
  candidateRows: Array<GQUserUploadCandidateRowFields>;
  ctsVersion: GQCustomerTargetSchemaVersion;
  id: Scalars['ID']['output'];
};

export const GQUserUploadCandidateKind = {
  Api: 'Api',
  Document: 'Document',
  File: 'File',
  Form: 'Form',
  Integration: 'Integration',
  Manual: 'Manual',
} as const;

export type GQUserUploadCandidateKind =
  (typeof GQUserUploadCandidateKind)[keyof typeof GQUserUploadCandidateKind];
export type GQUserUploadCandidatePayload = {
  __typename?: 'UserUploadCandidatePayload';
  candidateEntries: Array<GQUserUploadCandidateEntry>;
};

export type GQUserUploadCandidateRowFields = {
  __typename?: 'UserUploadCandidateRowFields';
  data: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
};

export type GQUserUploadConnection = {
  __typename?: 'UserUploadConnection';
  edges: Array<Maybe<GQUserUploadEdge>>;
  pageInfo: GQPageInfo;
};

export type GQUserUploadEdge = {
  __typename?: 'UserUploadEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUserUpload>;
};

export type GQUserUploadFailure = {
  __typename?: 'UserUploadFailure';
  filename: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export const GQUserUploadProcessingMode = {
  Api: 'Api',
  IdiRawFile: 'IdiRawFile',
  Legacy: 'Legacy',
  NoProcessing: 'NoProcessing',
  Synchronous: 'Synchronous',
} as const;

export type GQUserUploadProcessingMode =
  (typeof GQUserUploadProcessingMode)[keyof typeof GQUserUploadProcessingMode];
export const GQUserUploadSource = {
  InProduct: 'InProduct',
  PublicApi: 'PublicAPI',
} as const;

export type GQUserUploadSource =
  (typeof GQUserUploadSource)[keyof typeof GQUserUploadSource];
export const GQUserUploadStatus = {
  Errored: 'Errored',
  Finalized: 'Finalized',
  Processed: 'Processed',
  Processing: 'Processing',
  Uploaded: 'Uploaded',
  Uploading: 'Uploading',
  Validated: 'Validated',
} as const;

export type GQUserUploadStatus =
  (typeof GQUserUploadStatus)[keyof typeof GQUserUploadStatus];
export type GQUserUploadTask = GQApprovalTarget &
  GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'UserUploadTask';
    approvalStatus: GQApprovalStatus;
    approvers: Array<GQUserWithApprovalStatus>;
    /** @deprecated Use assignees instead */
    assignee: Maybe<GQUser>;
    assignees: Array<GQUser>;
    buildingIds: Array<Scalars['ID']['output']>;
    buildingTemplateDataUntyped: Maybe<Scalars['UntypedData']['output']>;
    createdAt: Scalars['DateTime']['output'];
    ctsForm: Maybe<GQCtsForm>;
    datasetRequirements: Array<GQClimateProgramProjectRequirementDataset>;
    datasource: GQDatasource;
    dueAt: Maybe<Scalars['DateTime']['output']>;
    facilitiesPreview: GQFacilitiesPreview;
    /** @deprecated Use valueMappingTask instead. Delete may9 */
    hasValueMappingTask: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    importState: GQDatasourceImportState;
    isDatasetComplete: Maybe<Scalars['Boolean']['output']>;
    /** @deprecated Use lockState instead */
    isLocked: Scalars['Boolean']['output'];
    issues: Maybe<GQDataIssueConnection>;
    lockState: GQApprovalTargetLockState;
    measurementProject: GQMeasurementProject;
    measurementTask: GQMeasurementTaskFlat;
    state: GQUserUploadTaskState;
    /** @deprecated Use lockState instead */
    unlockRequested: Scalars['Boolean']['output'];
    updatedAt: Scalars['DateTime']['output'];
    userUploads: Maybe<GQUserUploadConnection>;
    valueMappingTask: Maybe<GQValueMappingTask>;
    valueMappingTasks: Array<GQValueMappingTask>;
  };

export type GQUserUploadTaskIssuesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQUserUploadTaskUserUploadsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  categories: InputMaybe<Array<GQFileCategory>>;
  excludeProcessingModes: InputMaybe<Array<GQUserUploadProcessingMode>>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQUserUploadTaskConnection = {
  __typename?: 'UserUploadTaskConnection';
  edges: Array<Maybe<GQUserUploadTaskEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'UserUploadTask' and its cursor. */
export type GQUserUploadTaskEdge = {
  __typename?: 'UserUploadTaskEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUserUploadTask>;
};

export const GQUserUploadTaskState = {
  Completed: 'Completed',
  Created: 'Created',
  HasReviewItems: 'HasReviewItems',
  InfoRequired: 'InfoRequired',
  Uploaded: 'Uploaded',
  Verifying: 'Verifying',
} as const;

export type GQUserUploadTaskState =
  (typeof GQUserUploadTaskState)[keyof typeof GQUserUploadTaskState];
export type GQUserUploadVerificationLog = GQIdInterface & {
  __typename?: 'UserUploadVerificationLog';
  dataReviewCompleted: Scalars['Boolean']['output'];
  dataset: Scalars['String']['output'];
  datasource: Scalars['String']['output'];
  fileId: Scalars['ID']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sheetName: Maybe<Scalars['String']['output']>;
  submittedAt: Scalars['DateTime']['output'];
  submittedBy: Scalars['String']['output'];
  transformation: Maybe<Scalars['String']['output']>;
};

export type GQUserUploadedTable = GQIdInterface & {
  __typename?: 'UserUploadedTable';
  /** @deprecated unused, remove jan18 */
  additionalQuestions: Maybe<Array<GQAdditionalQuestionResponse>>;
  businessActivityType: Maybe<Scalars['String']['output']>;
  dataPreview: Maybe<GQUserUploadedTableDataPreview>;
  error: Maybe<Scalars['String']['output']>;
  errorMessage: Maybe<Scalars['String']['output']>;
  errorType: Maybe<Scalars['String']['output']>;
  extBartId: Maybe<Scalars['ID']['output']>;
  gcloudStorageUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integrationDataPull: Maybe<GQIntegrationDataPull>;
  isUsingLatestUIR: Scalars['Boolean']['output'];
  latestParseAttemptGCSSignedUrl: Maybe<Scalars['String']['output']>;
  numRows: Maybe<Scalars['Int']['output']>;
  parentFileMetadata: GQFileMetadata;
  parseConfig: Maybe<GQUserUploadedTableParseConfig>;
  rawDataPreview: Maybe<GQUserUploadedTableDataPreview>;
  rawDataUnshiftedPreview: Maybe<GQUserUploadedTableDataPreview>;
  roboCleanerResult: Maybe<GQUserUploadedTableRoboCleanerResult>;
  schema: Maybe<Array<GQUserUploadedTableSchemaColumn>>;
  sheetIndex: Maybe<Scalars['Int']['output']>;
  sheetName: Maybe<Scalars['String']['output']>;
  shouldIgnore: Scalars['Boolean']['output'];
  sourceFileDeleted: Scalars['Boolean']['output'];
  sqlTableName: Maybe<Scalars['String']['output']>;
  status: GQUserUploadedTableStatus;
  uirSchema: Maybe<Array<GQUserUploadedTableSchemaColumn>>;
  userIngestionReview: Maybe<GQUserIngestionReview>;
  userReviewMetadata: Maybe<GQUserUploadedTableUserReviewMetadata>;
  userVisibleAttempt: Maybe<GQUserUploadAttempt>;
};

export type GQUserUploadedTableUserVisibleAttemptArgs = {
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUserUploadedTableCell = {
  __typename?: 'UserUploadedTableCell';
  columnName: Scalars['String']['output'];
  value: Maybe<Scalars['JSONString']['output']>;
};

export type GQUserUploadedTableConnection = {
  __typename?: 'UserUploadedTableConnection';
  edges: Array<Maybe<GQUserUploadedTableEdge>>;
  pageInfo: GQPageInfo;
};

export type GQUserUploadedTableDataPreview = {
  __typename?: 'UserUploadedTableDataPreview';
  /** @deprecated Use dataUntyped instead, delete on apr29 */
  data: Array<Array<GQUserUploadedTableCell>>;
  dataUntyped: Scalars['UntypedData']['output'];
  schema: GQUserUploadedTableDataPreviewSchema;
};

export type GQUserUploadedTableDataPreviewSchema = {
  __typename?: 'UserUploadedTableDataPreviewSchema';
  fields: Array<GQUserUploadedTableDataPreviewSchemaField>;
};

export type GQUserUploadedTableDataPreviewSchemaField = {
  __typename?: 'UserUploadedTableDataPreviewSchemaField';
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type GQUserUploadedTableEdge = {
  __typename?: 'UserUploadedTableEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUserUploadedTable>;
};

export type GQUserUploadedTableParseConfig = {
  __typename?: 'UserUploadedTableParseConfig';
  meltIdVars: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  noHeader: Scalars['Boolean']['output'];
  skipBottomNRows: Scalars['Int']['output'];
  skipTopNRows: Scalars['Int']['output'];
};

export type GQUserUploadedTableRoboCleanerResult = {
  __typename?: 'UserUploadedTableRoboCleanerResult';
  skipTopNRows: Scalars['Int']['output'];
};

export type GQUserUploadedTableSchemaColumn = {
  __typename?: 'UserUploadedTableSchemaColumn';
  alias: Scalars['String']['output'];
  forwardFill: Scalars['Boolean']['output'];
  index: Scalars['Int']['output'];
  kind: GQUserUploadedTableSchemaColumnKind;
  name: Scalars['String']['output'];
};

export const GQUserUploadedTableSchemaColumnKind = {
  Boolean: 'Boolean',
  Date: 'Date',
  Epoch: 'Epoch',
  EuroDate: 'EuroDate',
  EuroFloat: 'EuroFloat',
  ExcelOrdinalDate: 'ExcelOrdinalDate',
  Float: 'Float',
  Month: 'Month',
  String: 'String',
} as const;

export type GQUserUploadedTableSchemaColumnKind =
  (typeof GQUserUploadedTableSchemaColumnKind)[keyof typeof GQUserUploadedTableSchemaColumnKind];
export const GQUserUploadedTableStatus = {
  Error: 'Error',
  Pending: 'Pending',
  Success: 'Success',
  Warning: 'Warning',
} as const;

export type GQUserUploadedTableStatus =
  (typeof GQUserUploadedTableStatus)[keyof typeof GQUserUploadedTableStatus];
export type GQUserUploadedTableUserReviewMetadata = {
  __typename?: 'UserUploadedTableUserReviewMetadata';
  buildingAreaUnitOverride: Maybe<GQBuildingSizeUnit>;
  columnMappings: Array<GQColumnMapping>;
  currencyOverride: Maybe<GQCurrencyOverride>;
  headerRowIndex: Scalars['Int']['output'];
  ingestionQuestionResponses: Maybe<Array<GQIngestionQuestionResponse>>;
  skipBottomNRows: Scalars['Int']['output'];
  stepsCompleted: Maybe<Array<GQImportFlowDataCleaningStep>>;
};

export type GQUserWithApprovalStatus = {
  __typename?: 'UserWithApprovalStatus';
  approvalStatus: Maybe<GQApprovalStatus>;
  user: GQUser;
};

export type GQUtilitiesMetadata = {
  __typename?: 'UtilitiesMetadata';
  id: Scalars['ID']['output'];
  utilityTypes: Array<GQUtilitiesMetadataByType>;
};

export type GQUtilitiesMetadataByType = {
  __typename?: 'UtilitiesMetadataByType';
  containingInterval: Scalars['YMInterval']['output'];
  utilityType: Maybe<GQBuildingUtilityType>;
};

export type GQUtilityUsageData = {
  __typename?: 'UtilityUsageData';
  energyConsumptionAmount: Maybe<Scalars['String']['output']>;
  energyConsumptionUnit: Maybe<Scalars['String']['output']>;
  receiverAddress: Maybe<Scalars['String']['output']>;
  serviceAddress: Maybe<Scalars['String']['output']>;
  serviceEndDate: Maybe<Scalars['String']['output']>;
  serviceStartDate: Maybe<Scalars['String']['output']>;
};

export type GQUtilityUsageDataInput = {
  energyConsumptionAmount?: InputMaybe<Scalars['String']['input']>;
  energyConsumptionUnit?: InputMaybe<Scalars['String']['input']>;
  receiverAddress?: InputMaybe<Scalars['String']['input']>;
  serviceAddress?: InputMaybe<Scalars['String']['input']>;
  serviceEndDate?: InputMaybe<Scalars['String']['input']>;
  serviceStartDate?: InputMaybe<Scalars['String']['input']>;
};

export type GQUutRowsDataLineage = {
  __typename?: 'UutRowsDataLineage';
  fileMetadataId: Scalars['String']['output'];
  isTruncated: Scalars['Boolean']['output'];
  rows: Array<Scalars['JSONString']['output']>;
  userUploadedTable: GQUserUploadedTable;
};

export type GQValidateEmissionsModelMatcherInput = {
  matcher: GQEmissionsModelMatcherInput;
  targetUnboundParameters: Scalars['JSONString']['input'];
  validateSingleMatcher: Scalars['Boolean']['input'];
};

export type GQValidateEmissionsModelMatcherPayload = {
  __typename?: 'ValidateEmissionsModelMatcherPayload';
  errors: Array<GQEmissionsModelError>;
  isValid: Scalars['Boolean']['output'];
};

export type GQValidateFormAgainstCtsErrorPayload = {
  __typename?: 'ValidateFormAgainstCtsErrorPayload';
  errorMessage: Scalars['String']['output'];
};

export type GQValidateFormAgainstCtsInput = {
  ctsVersionId: Scalars['ID']['input'];
  data: Scalars['JSONString']['input'];
  datasetId: Scalars['ID']['input'];
};

export type GQValidateFormAgainstCtsPayload = {
  __typename?: 'ValidateFormAgainstCtsPayload';
  errors: Array<GQValidateFormAgainstCtsErrorPayload>;
};

export type GQValidationWarning = {
  __typename?: 'ValidationWarning';
  blocking: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
};

export type GQValueMapping = {
  __typename?: 'ValueMapping';
  id: Scalars['ID']['output'];
  metadata: Maybe<GQValueMappingRowsMetadata>;
  /** @deprecated use DatasourceValueMappingRule.revisionData instead */
  revisionState: Maybe<GQReferenceDataRevisionState>;
  valueMappingRows: Array<GQValueMappingRow>;
};

export type GQValueMappingAggregatedQuantities = {
  __typename?: 'ValueMappingAggregatedQuantities';
  mappedQuantity: Scalars['Float']['output'];
  totalQuantity: Scalars['Float']['output'];
};

export type GQValueMappingChangelogForGroupsPayload = {
  __typename?: 'ValueMappingChangelogForGroupsPayload';
  id: Scalars['ID']['output'];
  json: Scalars['JSONString']['output'];
};

export type GQValueMappingComment = {
  __typename?: 'ValueMappingComment';
  createdAt: Scalars['DateTime']['output'];
  creator: GQUser;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  deleter: Maybe<GQUser>;
  id: Scalars['ID']['output'];
  lastEditedAt: Maybe<Scalars['DateTime']['output']>;
  lastEditor: Maybe<GQUser>;
  message: Scalars['String']['output'];
  org: GQOrganization;
  subjectId: Scalars['ID']['output'];
  valueMappingConfigId: Scalars['ID']['output'];
};

export type GQValueMappingFileData = {
  __typename?: 'ValueMappingFileData';
  fileData: GQUserUpload;
  id: Scalars['ID']['output'];
  matchedRows: Scalars['JSONSchema']['output'];
};

export type GQValueMappingFileDataFilter = {
  columnNames: Array<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export const GQValueMappingFilterType = {
  All: 'all',
  Flagged: 'flagged',
  Incomplete: 'incomplete',
  Required: 'required',
  Suggested: 'suggested',
} as const;

export type GQValueMappingFilterType =
  (typeof GQValueMappingFilterType)[keyof typeof GQValueMappingFilterType];
export type GQValueMappingHistoryEntry = {
  __typename?: 'ValueMappingHistoryEntry';
  changedAt: Scalars['DateTime']['output'];
  changedBy: GQUser;
  columnName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  newValue: Maybe<Scalars['UntypedData']['output']>;
  oldValue: Maybe<Scalars['UntypedData']['output']>;
  operation: Scalars['String']['output'];
  revision: GQReferenceDataRevision;
  rowId: Scalars['ID']['output'];
};

export type GQValueMappingHistoryForGroupPayload = {
  __typename?: 'ValueMappingHistoryForGroupPayload';
  entries: Array<GQValueMappingHistoryEntry>;
  id: Scalars['ID']['output'];
};

export type GQValueMappingOutputOption = {
  __typename?: 'ValueMappingOutputOption';
  category: Maybe<Scalars['String']['output']>;
  descriptionLong: Maybe<Scalars['String']['output']>;
  extraData: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['String']['output'];
  isSuggested: Scalars['Boolean']['output'];
  subtitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type GQValueMappingOutputPicker = {
  __typename?: 'ValueMappingOutputPicker';
  columnName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  options: Array<GQValueMappingOutputOption>;
  placeholder: Maybe<Scalars['String']['output']>;
};

export const GQValueMappingPublishStatus = {
  Failed: 'Failed',
  LatestPublishSucceeded: 'LatestPublishSucceeded',
  NeverPublished: 'NeverPublished',
  Processing: 'Processing',
} as const;

export type GQValueMappingPublishStatus =
  (typeof GQValueMappingPublishStatus)[keyof typeof GQValueMappingPublishStatus];
export type GQValueMappingRow = {
  __typename?: 'ValueMappingRow';
  baseRowJson: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type GQValueMappingRowConnection = {
  __typename?: 'ValueMappingRowConnection';
  edges: Array<Maybe<GQValueMappingRowEdge>>;
  metadata: GQValueMappingRowsMetadata;
  pageInfo: GQPageInfo;
};

export type GQValueMappingRowEdge = {
  __typename?: 'ValueMappingRowEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQValueMappingRow>;
};

export type GQValueMappingRowsMetadata = {
  __typename?: 'ValueMappingRowsMetadata';
  allRowsAreFilled: Scalars['Boolean']['output'];
  flaggedRowCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  suggestionsRowCount: Scalars['Int']['output'];
  totalRowCount: Scalars['Int']['output'];
  unmappedRowCount: Scalars['Int']['output'];
};

export type GQValueMappingTableSourceData = {
  __typename?: 'ValueMappingTableSourceData';
  id: Scalars['ID']['output'];
  outputOptionPickers: Array<GQValueMappingOutputPicker>;
};

export type GQValueMappingTask = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'ValueMappingTask';
    id: Scalars['ID']['output'];
    issues: Maybe<GQDataIssueConnection>;
    mappingRuleId: Scalars['String']['output'];
    measurementProject: GQMeasurementProject;
    measurementProjectId: Scalars['ID']['output'];
    orgId: Scalars['ID']['output'];
    publishStatus: GQValueMappingPublishStatus;
    status: GQTaskWatershedProcessState;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
  };

export type GQValueMappingTaskIssuesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQVariousSupplierInfo = {
  __typename?: 'VariousSupplierInfo';
  companyId: Maybe<Scalars['String']['output']>;
  totalKgco2e: Scalars['Float']['output'];
  totalSpendUsd: Scalars['Float']['output'];
  vendor: Scalars['String']['output'];
};

export type GQVariousSuppliersPayload = {
  __typename?: 'VariousSuppliersPayload';
  variousSuppliers: Array<GQVariousSupplierInfo>;
};

export type GQVectorNameAndValues = {
  __typename?: 'VectorNameAndValues';
  vectorName: Scalars['String']['output'];
  vectorValues: Maybe<Array<Scalars['String']['output']>>;
};

export type GQVendor = {
  __typename?: 'Vendor';
  program: Maybe<Scalars['String']['output']>;
  programUrl: Maybe<Scalars['String']['output']>;
  sbtTarget: Maybe<Scalars['String']['output']>;
  targetYear: Maybe<Scalars['Int']['output']>;
  vendor: Scalars['String']['output'];
};

export type GQVendorConnection = {
  __typename?: 'VendorConnection';
  edges: Array<Maybe<GQVendorEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'Vendor' and its cursor. */
export type GQVendorEdge = {
  __typename?: 'VendorEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQVendor>;
};

export type GQVendorMatchingTableRow = GQIdInterface & {
  __typename?: 'VendorMatchingTableRow';
  autoMatchedCompany: Maybe<GQCompany>;
  autoMatchedCompanyId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgCompany: Maybe<GQOrgCompany>;
  pendingCompanyCreateRequest: Maybe<GQCompanyChangeRequest>;
  rawVendorEntity: Scalars['String']['output'];
  subRows: Array<GQVendorMatchingTableSubRow>;
  totalSpendUsd: Scalars['Float']['output'];
};

export type GQVendorMatchingTableRowConnection = {
  __typename?: 'VendorMatchingTableRowConnection';
  edges: Array<Maybe<GQVendorMatchingTableRowEdge>>;
  pageInfo: GQPageInfo;
};

export type GQVendorMatchingTableRowEdge = {
  __typename?: 'VendorMatchingTableRowEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQVendorMatchingTableRow>;
};

export type GQVendorMatchingTableRowsPayload = {
  __typename?: 'VendorMatchingTableRowsPayload';
  id: Scalars['ID']['output'];
  totalNumVendorRows: Scalars['Int']['output'];
  vendorRows: GQVendorMatchingTableRowConnection;
};

export type GQVendorMatchingTableSubRow = GQIdInterface & {
  __typename?: 'VendorMatchingTableSubRow';
  category: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  currencyQuantity: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  spendUsd: Scalars['Float']['output'];
  subCategory: Scalars['String']['output'];
};

export type GQVendorMatchingTask = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'VendorMatchingTask';
    assignee: Maybe<GQUser>;
    completedAt: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['ID']['output'];
    measurementProject: GQMeasurementProject;
  };

export type GQVerifyReportAnswersPayload = {
  __typename?: 'VerifyReportAnswersPayload';
  failureCount: Scalars['Float']['output'];
  successCount: Scalars['Float']['output'];
};

export type GQVersionedBusinessActivityTypeInput = {
  businessActivityTypeName: Scalars['String']['input'];
  businessActivityTypeVersionId: Scalars['ID']['input'];
};

export type GQWasteEmissionsExclusion = {
  __typename?: 'WasteEmissionsExclusion';
  shouldExclude: Scalars['Boolean']['output'];
  wasteEmissionsMethodology: GQWasteEmissionsMethodology;
};

export type GQWasteEmissionsExclusionInput = {
  shouldExclude: Scalars['Boolean']['input'];
  wasteEmissionsMethodology: GQWasteEmissionsMethodology;
};

export const GQWasteEmissionsMethodology = {
  BuildingWaste: 'BuildingWaste',
  Employees: 'Employees',
  Waste: 'Waste',
} as const;

export type GQWasteEmissionsMethodology =
  (typeof GQWasteEmissionsMethodology)[keyof typeof GQWasteEmissionsMethodology];
export type GQWaterfallBlock = {
  __typename?: 'WaterfallBlock';
  id: Scalars['ID']['output'];
  isIntermediateSum: Maybe<Scalars['Boolean']['output']>;
  isSum: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type GQWaterfallChartPayload = {
  __typename?: 'WaterfallChartPayload';
  waterfallBlocks: Array<GQWaterfallBlock>;
};

export type GQWatershedEmployee = GQIdInterface &
  GQPerson & {
    __typename?: 'WatershedEmployee';
    displayName: Scalars['String']['output'];
    email: Scalars['String']['output'];
    handle: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    /** @deprecated Please use isWatershedEmployee or isWatershedContractor */
    isWatershed: Scalars['Boolean']['output'];
    isWatershedContractor: Scalars['Boolean']['output'];
    isWatershedEmployee: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    orgPointsOfContact: Array<GQOrgPointOfContact>;
    user: GQUser;
  };

export type GQWatershedEmployeeConnection = {
  __typename?: 'WatershedEmployeeConnection';
  edges: Array<Maybe<GQWatershedEmployeeEdge>>;
  pageInfo: GQPageInfo;
};

export type GQWatershedEmployeeEdge = {
  __typename?: 'WatershedEmployeeEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQWatershedEmployee>;
};

export type GQWatershedFootprintReviewRequest = {
  __typename?: 'WatershedFootprintReviewRequest';
  createdAt: Scalars['Date']['output'];
  discussion: Maybe<GQDiscussion>;
  id: Scalars['ID']['output'];
  status: GQWatershedFootprintReviewRequestStatus;
  user: GQUser;
};

export const GQWatershedFootprintReviewRequestStatus = {
  Done: 'Done',
  NeedsWatershedResponse: 'NeedsWatershedResponse',
  WatershedReviewInitiated: 'WatershedReviewInitiated',
} as const;

export type GQWatershedFootprintReviewRequestStatus =
  (typeof GQWatershedFootprintReviewRequestStatus)[keyof typeof GQWatershedFootprintReviewRequestStatus];
export const GQWatershedPlan = {
  Custom: 'Custom',
  Enterprise: 'Enterprise',
  Essential: 'Essential',
  Finance: 'Finance',
  None: 'None',
  Other: 'Other',
  PortcoPackage: 'PortcoPackage',
  Premium: 'Premium',
  Standard: 'Standard',
} as const;

export type GQWatershedPlan =
  (typeof GQWatershedPlan)[keyof typeof GQWatershedPlan];
export const GQWatershedPlanLegacy = {
  Lite: 'Lite',
  NetZero: 'NetZero',
  NoPlan: 'NoPlan',
  Pro: 'Pro',
  Standard: 'Standard',
} as const;

export type GQWatershedPlanLegacy =
  (typeof GQWatershedPlanLegacy)[keyof typeof GQWatershedPlanLegacy];
export type GQWithdrawCompanyChangeRequestInput = {
  id: Scalars['ID']['input'];
};

export type GQWithdrawCompanyChangeRequestPayload = {
  __typename?: 'WithdrawCompanyChangeRequestPayload';
  id: Scalars['ID']['output'];
};

export type GQWorkflowExecution = {
  __typename?: 'WorkflowExecution';
  runId: Maybe<Scalars['String']['output']>;
  workflowId: Scalars['String']['output'];
};

export type GQWorkivaContributionsPayload = {
  __typename?: 'WorkivaContributionsPayload';
  data: Scalars['UntypedData']['output'];
  id: Scalars['String']['output'];
  interval: Scalars['YMInterval']['output'];
};

export type GQWorkosConnection = {
  __typename?: 'WorkosConnection';
  connectionType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type GQYearMonthInterval = {
  __typename?: 'YearMonthInterval';
  end: Scalars['YearMonth']['output'];
  start: Scalars['YearMonth']['output'];
};

export const GQYesNoUnknown = {
  No: 'No',
  Unknown: 'Unknown',
  Yes: 'Yes',
} as const;

export type GQYesNoUnknown =
  (typeof GQYesNoUnknown)[keyof typeof GQYesNoUnknown];
export type GQBiAiQueryAvailableDimensionsMetadata = {
  family?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type GQBiAiQueryAvailableMetricMetadata = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  fieldFamily?: InputMaybe<Scalars['String']['input']>;
  fieldId: Scalars['String']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type GQSuggestMappingsPayload = {
  __typename?: 'suggestMappingsPayload';
  jobId: Scalars['ID']['output'];
};

export type GQDeleteMarketplaceDeveloperCacheUpdateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQDeleteMarketplaceDeveloperCacheUpdateQuery = {
  __typename?: 'Query';
  marketplaceDevelopers: {
    __typename?: 'MarketplaceDeveloperConnection';
    edges: Array<{
      __typename?: 'MarketplaceDeveloperEdge';
      node: { __typename?: 'MarketplaceDeveloper'; id: string } | null;
    } | null>;
  };
};

export type GQUpdateActivityDataTableMutationVariables = Exact<{
  input: GQUpdateActivityDataTableInput;
}>;

export type GQUpdateActivityDataTableMutation = {
  __typename?: 'Mutation';
  updateActivityDataTable: {
    __typename?: 'UpdateActivityDataTablePayload';
    activityDataTable: {
      __typename?: 'ActivityDataTable';
      id: string;
      name: string;
      lastRefreshedAt: Date | null;
      filterInterval: YMInterval;
      connectedToAutoBart: boolean;
      autoBartKind: GQAutoBartKind | null;
      autoBartTaskApprovalState: GQAutoBartTaskApprovalState;
      stage: GQActivityDataTableStageKind;
      primaryDatasource: {
        __typename?: 'Datasource';
        id: string;
        name: string;
        hasIntegration: boolean;
        hasIntegrationOption: boolean;
        activeIntegrationConnection: {
          __typename?: 'IntegrationConnection';
          id: string;
        } | null;
      } | null;
      datasets: Array<{
        __typename?: 'Dataset';
        name: string;
        id: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      }>;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
        hasIntegration: boolean;
        hasIntegrationOption: boolean;
        activeIntegrationConnection: {
          __typename?: 'IntegrationConnection';
          id: string;
        } | null;
      }>;
      draftCadtExtractor: {
        __typename?: 'ActivityDataExtractor';
        id: string;
        createdAt: Date;
      } | null;
      draftBartExtractor: {
        __typename?: 'ActivityDataExtractor';
        id: string;
        createdAt: Date;
        pushedBuildings: boolean | null;
      } | null;
      publishedExtractor: {
        __typename?: 'ActivityDataExtractor';
        id: string;
        createdAt: Date;
        userVisible: boolean;
        facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
        bartActivityType: string | null;
      } | null;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
      } | null;
      reviews: Array<{
        __typename?: 'ActivityDataTableReview';
        id: string;
        reviewer: { __typename?: 'User'; id: string; name: string };
      }>;
    };
  } | null;
};

export type GQUnpublishActivityDataTableMutationVariables = Exact<{
  input: GQUnpublishActivityDataTableInput;
}>;

export type GQUnpublishActivityDataTableMutation = {
  __typename?: 'Mutation';
  unpublishActivityDataTable: {
    __typename?: 'UnpublishActivityDataTablePayload';
    workflowId: string;
  } | null;
};

export type GQFetchIntegrationDataMutationVariables = Exact<{
  input: GQFetchIntegrationDataInput;
}>;

export type GQFetchIntegrationDataMutation = {
  __typename?: 'Mutation';
  fetchIntegrationData: {
    __typename?: 'FetchIntegrationDataPayload';
    job: { __typename?: 'FetchIntegrationDataWorkflow'; id: string };
  };
};

export type GQGetUserUploadedTableSchemaQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetUserUploadedTableSchemaQuery = {
  __typename?: 'Query';
  userUploadedTable: {
    __typename?: 'UserUploadedTable';
    id: string;
    parseConfig: {
      __typename?: 'UserUploadedTableParseConfig';
      noHeader: boolean;
      skipTopNRows: number;
      skipBottomNRows: number;
      meltIdVars: Array<string | null> | null;
    } | null;
    userReviewMetadata: {
      __typename?: 'UserUploadedTableUserReviewMetadata';
      columnMappings: Array<{
        __typename?: 'ColumnMapping';
        watershedColumnName: string;
        sourceFileColumnName: string | null;
      }>;
    } | null;
    dataPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
      schema: {
        __typename?: 'UserUploadedTableDataPreviewSchema';
        fields: Array<{
          __typename?: 'UserUploadedTableDataPreviewSchemaField';
          name: string;
          type: string;
        }>;
      };
    } | null;
  } | null;
};

export type GQActivityDataExtractorFieldsFragment = {
  __typename?: 'ActivityDataExtractor';
  id: string;
  createdAt: Date;
  kind: GQActivityDataExtractorKind;
  sql: string;
  warnings: any | null;
  error: string | null;
  published: boolean | null;
  userVisible: boolean;
  generated: boolean | null;
  duckdbVersion: string;
  bartActivityType: string | null;
  facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
  user: { __typename?: 'User'; id: string; name: string } | null;
  userUploadedTables: Array<{
    __typename?: 'UserUploadedTable';
    id: string;
    parentFileMetadata: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
      category: GQFileCategory;
    };
  }>;
  runSqlOutput: {
    __typename?: 'ParquetData';
    id: string;
    numRecords: number;
    ndjsonSignedUrl: string | null;
    ndjsonSize: number | null;
    ndjsonByteBoundaries: Array<number> | null;
    columns: Array<{
      __typename?: 'ParquetColumn';
      name: string;
      sqlType: string;
      minValue: any;
      maxValue: any;
      numNulls: number;
      approxDistinctValues: number;
      sampleValues: Array<any>;
    }>;
  } | null;
  transformedOutput: {
    __typename?: 'ParquetData';
    id: string;
    numRecords: number;
    ndjsonSignedUrl: string | null;
    ndjsonSize: number | null;
    ndjsonByteBoundaries: Array<number> | null;
    columns: Array<{
      __typename?: 'ParquetColumn';
      name: string;
      sqlType: string;
      minValue: any;
      maxValue: any;
      numNulls: number;
      approxDistinctValues: number;
      sampleValues: Array<any>;
    }>;
  } | null;
  cleanAdtOutput: {
    __typename?: 'ParquetData';
    id: string;
    numRecords: number;
    ndjsonSignedUrl: string | null;
    ndjsonSize: number | null;
    ndjsonByteBoundaries: Array<number> | null;
    columns: Array<{
      __typename?: 'ParquetColumn';
      name: string;
      sqlType: string;
      minValue: any;
      maxValue: any;
      numNulls: number;
      approxDistinctValues: number;
      sampleValues: Array<any>;
    }>;
  } | null;
};

export type GQActivityDataTableFieldsForEditorFragment = {
  __typename: 'ActivityDataTable';
  id: string;
  name: string;
  filterInterval: YMInterval;
  latestBartActivityType: string | null;
  recommendedActivityTypes: Array<string>;
  connectedToAutoBart: boolean;
  autoBartKind: GQAutoBartKind | null;
  autoBartActivityType: string | null;
  draftAutoBartHasPreviousCustomExtractor: boolean;
  adminUrl: string;
  publishedExtractor: {
    __typename?: 'ActivityDataExtractor';
    id: string;
    createdAt: Date;
    kind: GQActivityDataExtractorKind;
    sql: string;
    warnings: any | null;
    error: string | null;
    published: boolean | null;
    userVisible: boolean;
    generated: boolean | null;
    duckdbVersion: string;
    bartActivityType: string | null;
    facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
    user: { __typename?: 'User'; id: string; name: string } | null;
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        category: GQFileCategory;
      };
    }>;
    runSqlOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    transformedOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    cleanAdtOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
  } | null;
  draftBartExtractor: {
    __typename?: 'ActivityDataExtractor';
    id: string;
    createdAt: Date;
    kind: GQActivityDataExtractorKind;
    sql: string;
    warnings: any | null;
    error: string | null;
    published: boolean | null;
    userVisible: boolean;
    generated: boolean | null;
    duckdbVersion: string;
    bartActivityType: string | null;
    facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
    user: { __typename?: 'User'; id: string; name: string } | null;
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        category: GQFileCategory;
      };
    }>;
    runSqlOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    transformedOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    cleanAdtOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
  } | null;
  draftCadtExtractor: {
    __typename?: 'ActivityDataExtractor';
    id: string;
    createdAt: Date;
    kind: GQActivityDataExtractorKind;
    sql: string;
    warnings: any | null;
    error: string | null;
    published: boolean | null;
    userVisible: boolean;
    generated: boolean | null;
    duckdbVersion: string;
    bartActivityType: string | null;
    facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
    user: { __typename?: 'User'; id: string; name: string } | null;
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        category: GQFileCategory;
      };
    }>;
    runSqlOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    transformedOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    cleanAdtOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
  } | null;
  draftScratchpadExtractor: {
    __typename?: 'ActivityDataExtractor';
    id: string;
    createdAt: Date;
    kind: GQActivityDataExtractorKind;
    sql: string;
    warnings: any | null;
    error: string | null;
    published: boolean | null;
    userVisible: boolean;
    generated: boolean | null;
    duckdbVersion: string;
    bartActivityType: string | null;
    facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
    user: { __typename?: 'User'; id: string; name: string } | null;
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        category: GQFileCategory;
      };
    }>;
    runSqlOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    transformedOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    cleanAdtOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
  } | null;
  datasources: Array<{
    __typename?: 'Datasource';
    id: string;
    name: string;
    dataset: { __typename?: 'Dataset'; id: string; name: string };
    userUploadTasks: {
      __typename?: 'UserUploadTaskConnection';
      edges: Array<{
        __typename?: 'UserUploadTaskEdge';
        node: {
          __typename?: 'UserUploadTask';
          id: string;
          issues: {
            __typename?: 'DataIssueConnection';
            edges: Array<{
              __typename?: 'DataIssueEdge';
              node: {
                __typename: 'DataIssue';
                id: string;
                title: string;
                description: string | null;
                state: GQDataIssueState;
                isPublished: boolean;
                isCustomerInitiated: boolean;
                createdAt: Date;
                tags: Array<GQDataIssueTag> | null;
                name: string;
                adminUrl: string;
                assignee: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  permissions: Array<{
                    __typename?: 'PermissionItem';
                    id: string;
                    objectId: string | null;
                    permission: GQPermissionType;
                    revokedAt: Date | null;
                    object:
                      | { __typename: 'Company'; id: string; name: string }
                      | {
                          __typename: 'CompanySurvey';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Dataset'; id: string; name: string }
                      | { __typename: 'Datasource'; id: string; name: string }
                      | {
                          __typename: 'EngagementTask';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Fund'; id: string; name: string }
                      | null;
                  }>;
                  roles: Array<{
                    __typename?: 'UserRoleAssignment';
                    id: string;
                    revokedAt: Date | null;
                    role: {
                      __typename?: 'Role';
                      id: string;
                      name: string;
                      description: string | null;
                      permissions: Array<{
                        __typename?: 'PermissionItem';
                        id: string;
                        objectId: string | null;
                        permission: GQPermissionType;
                        revokedAt: Date | null;
                        object:
                          | { __typename: 'Company'; id: string; name: string }
                          | {
                              __typename: 'CompanySurvey';
                              id: string;
                              name: string;
                            }
                          | { __typename: 'Dataset'; id: string; name: string }
                          | {
                              __typename: 'Datasource';
                              id: string;
                              name: string;
                            }
                          | {
                              __typename: 'EngagementTask';
                              id: string;
                              name: string;
                            }
                          | { __typename: 'Fund'; id: string; name: string }
                          | null;
                      }>;
                    };
                  }>;
                } | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      message: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            name: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            name: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
                files: {
                  __typename?: 'FileMetadataConnection';
                  edges: Array<{
                    __typename?: 'FileMetadataEdge';
                    node: { __typename?: 'FileMetadata'; id: string } | null;
                  } | null>;
                };
                linkedObject:
                  | { __typename: 'FinancialsReviewItem'; id: string }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename?: 'MeasurementVerificationItem';
                        id: string;
                        datasetName: string;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      assignee: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        permissions: Array<{
                          __typename?: 'PermissionItem';
                          id: string;
                          objectId: string | null;
                          permission: GQPermissionType;
                          revokedAt: Date | null;
                          object:
                            | {
                                __typename: 'Company';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'CompanySurvey';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'Dataset';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'Datasource';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'EngagementTask';
                                id: string;
                                name: string;
                              }
                            | { __typename: 'Fund'; id: string; name: string }
                            | null;
                        }>;
                        roles: Array<{
                          __typename?: 'UserRoleAssignment';
                          id: string;
                          revokedAt: Date | null;
                          role: {
                            __typename?: 'Role';
                            id: string;
                            name: string;
                            description: string | null;
                            permissions: Array<{
                              __typename?: 'PermissionItem';
                              id: string;
                              objectId: string | null;
                              permission: GQPermissionType;
                              revokedAt: Date | null;
                              object:
                                | {
                                    __typename: 'Company';
                                    id: string;
                                    name: string;
                                  }
                                | {
                                    __typename: 'CompanySurvey';
                                    id: string;
                                    name: string;
                                  }
                                | {
                                    __typename: 'Dataset';
                                    id: string;
                                    name: string;
                                  }
                                | {
                                    __typename: 'Datasource';
                                    id: string;
                                    name: string;
                                  }
                                | {
                                    __typename: 'EngagementTask';
                                    id: string;
                                    name: string;
                                  }
                                | {
                                    __typename: 'Fund';
                                    id: string;
                                    name: string;
                                  }
                                | null;
                            }>;
                          };
                        }>;
                      } | null;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | null;
                ancestorRelations: Array<
                  | {
                      __typename: 'ActivityDataTable';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'Dataset';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'Datasource';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'FileMetadata';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'FootprintQuestion';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'MeasurementProject';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                >;
              } | null;
            } | null>;
          } | null;
          userUploads: {
            __typename?: 'UserUploadConnection';
            edges: Array<{
              __typename?: 'UserUploadEdge';
              node: {
                __typename?: 'UserUpload';
                id: string;
                allAttempts: Array<{
                  __typename?: 'UserUploadAttempt';
                  id: string;
                  description: string | null;
                  userUploadedTables: Array<{
                    __typename?: 'UserUploadedTable';
                    id: string;
                  }>;
                }>;
              } | null;
            } | null>;
          } | null;
          assignee: {
            __typename?: 'User';
            id: string;
            name: string;
            permissions: Array<{
              __typename?: 'PermissionItem';
              id: string;
              objectId: string | null;
              permission: GQPermissionType;
              revokedAt: Date | null;
              object:
                | { __typename: 'Company'; id: string; name: string }
                | { __typename: 'CompanySurvey'; id: string; name: string }
                | { __typename: 'Dataset'; id: string; name: string }
                | { __typename: 'Datasource'; id: string; name: string }
                | { __typename: 'EngagementTask'; id: string; name: string }
                | { __typename: 'Fund'; id: string; name: string }
                | null;
            }>;
            roles: Array<{
              __typename?: 'UserRoleAssignment';
              id: string;
              revokedAt: Date | null;
              role: {
                __typename?: 'Role';
                id: string;
                name: string;
                description: string | null;
                permissions: Array<{
                  __typename?: 'PermissionItem';
                  id: string;
                  objectId: string | null;
                  permission: GQPermissionType;
                  revokedAt: Date | null;
                  object:
                    | { __typename: 'Company'; id: string; name: string }
                    | { __typename: 'CompanySurvey'; id: string; name: string }
                    | { __typename: 'Dataset'; id: string; name: string }
                    | { __typename: 'Datasource'; id: string; name: string }
                    | { __typename: 'EngagementTask'; id: string; name: string }
                    | { __typename: 'Fund'; id: string; name: string }
                    | null;
                }>;
              };
            }>;
          } | null;
          datasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: { __typename?: 'Dataset'; id: string; name: string };
          };
        } | null;
      } | null>;
    };
  }>;
  datasets: Array<{ __typename?: 'Dataset'; id: string; name: string }>;
  primaryDatasource: {
    __typename?: 'Datasource';
    id: string;
    name: string;
    dataset: { __typename?: 'Dataset'; id: string; name: string };
    userUploadTasks: {
      __typename?: 'UserUploadTaskConnection';
      edges: Array<{
        __typename?: 'UserUploadTaskEdge';
        node: {
          __typename?: 'UserUploadTask';
          id: string;
          issues: {
            __typename?: 'DataIssueConnection';
            edges: Array<{
              __typename?: 'DataIssueEdge';
              node: {
                __typename: 'DataIssue';
                id: string;
                title: string;
                description: string | null;
                state: GQDataIssueState;
                isPublished: boolean;
                isCustomerInitiated: boolean;
                createdAt: Date;
                tags: Array<GQDataIssueTag> | null;
                name: string;
                adminUrl: string;
                assignee: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  permissions: Array<{
                    __typename?: 'PermissionItem';
                    id: string;
                    objectId: string | null;
                    permission: GQPermissionType;
                    revokedAt: Date | null;
                    object:
                      | { __typename: 'Company'; id: string; name: string }
                      | {
                          __typename: 'CompanySurvey';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Dataset'; id: string; name: string }
                      | { __typename: 'Datasource'; id: string; name: string }
                      | {
                          __typename: 'EngagementTask';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Fund'; id: string; name: string }
                      | null;
                  }>;
                  roles: Array<{
                    __typename?: 'UserRoleAssignment';
                    id: string;
                    revokedAt: Date | null;
                    role: {
                      __typename?: 'Role';
                      id: string;
                      name: string;
                      description: string | null;
                      permissions: Array<{
                        __typename?: 'PermissionItem';
                        id: string;
                        objectId: string | null;
                        permission: GQPermissionType;
                        revokedAt: Date | null;
                        object:
                          | { __typename: 'Company'; id: string; name: string }
                          | {
                              __typename: 'CompanySurvey';
                              id: string;
                              name: string;
                            }
                          | { __typename: 'Dataset'; id: string; name: string }
                          | {
                              __typename: 'Datasource';
                              id: string;
                              name: string;
                            }
                          | {
                              __typename: 'EngagementTask';
                              id: string;
                              name: string;
                            }
                          | { __typename: 'Fund'; id: string; name: string }
                          | null;
                      }>;
                    };
                  }>;
                } | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      message: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            name: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            name: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
                files: {
                  __typename?: 'FileMetadataConnection';
                  edges: Array<{
                    __typename?: 'FileMetadataEdge';
                    node: { __typename?: 'FileMetadata'; id: string } | null;
                  } | null>;
                };
                linkedObject:
                  | { __typename: 'FinancialsReviewItem'; id: string }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename?: 'MeasurementVerificationItem';
                        id: string;
                        datasetName: string;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      assignee: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        permissions: Array<{
                          __typename?: 'PermissionItem';
                          id: string;
                          objectId: string | null;
                          permission: GQPermissionType;
                          revokedAt: Date | null;
                          object:
                            | {
                                __typename: 'Company';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'CompanySurvey';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'Dataset';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'Datasource';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'EngagementTask';
                                id: string;
                                name: string;
                              }
                            | { __typename: 'Fund'; id: string; name: string }
                            | null;
                        }>;
                        roles: Array<{
                          __typename?: 'UserRoleAssignment';
                          id: string;
                          revokedAt: Date | null;
                          role: {
                            __typename?: 'Role';
                            id: string;
                            name: string;
                            description: string | null;
                            permissions: Array<{
                              __typename?: 'PermissionItem';
                              id: string;
                              objectId: string | null;
                              permission: GQPermissionType;
                              revokedAt: Date | null;
                              object:
                                | {
                                    __typename: 'Company';
                                    id: string;
                                    name: string;
                                  }
                                | {
                                    __typename: 'CompanySurvey';
                                    id: string;
                                    name: string;
                                  }
                                | {
                                    __typename: 'Dataset';
                                    id: string;
                                    name: string;
                                  }
                                | {
                                    __typename: 'Datasource';
                                    id: string;
                                    name: string;
                                  }
                                | {
                                    __typename: 'EngagementTask';
                                    id: string;
                                    name: string;
                                  }
                                | {
                                    __typename: 'Fund';
                                    id: string;
                                    name: string;
                                  }
                                | null;
                            }>;
                          };
                        }>;
                      } | null;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | null;
                ancestorRelations: Array<
                  | {
                      __typename: 'ActivityDataTable';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'Dataset';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'Datasource';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'FileMetadata';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'FootprintQuestion';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'MeasurementProject';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      name: string;
                      adminUrl: string;
                    }
                >;
              } | null;
            } | null>;
          } | null;
          userUploads: {
            __typename?: 'UserUploadConnection';
            edges: Array<{
              __typename?: 'UserUploadEdge';
              node: {
                __typename?: 'UserUpload';
                id: string;
                allAttempts: Array<{
                  __typename?: 'UserUploadAttempt';
                  id: string;
                  description: string | null;
                  userUploadedTables: Array<{
                    __typename?: 'UserUploadedTable';
                    id: string;
                  }>;
                }>;
              } | null;
            } | null>;
          } | null;
          assignee: {
            __typename?: 'User';
            id: string;
            name: string;
            permissions: Array<{
              __typename?: 'PermissionItem';
              id: string;
              objectId: string | null;
              permission: GQPermissionType;
              revokedAt: Date | null;
              object:
                | { __typename: 'Company'; id: string; name: string }
                | { __typename: 'CompanySurvey'; id: string; name: string }
                | { __typename: 'Dataset'; id: string; name: string }
                | { __typename: 'Datasource'; id: string; name: string }
                | { __typename: 'EngagementTask'; id: string; name: string }
                | { __typename: 'Fund'; id: string; name: string }
                | null;
            }>;
            roles: Array<{
              __typename?: 'UserRoleAssignment';
              id: string;
              revokedAt: Date | null;
              role: {
                __typename?: 'Role';
                id: string;
                name: string;
                description: string | null;
                permissions: Array<{
                  __typename?: 'PermissionItem';
                  id: string;
                  objectId: string | null;
                  permission: GQPermissionType;
                  revokedAt: Date | null;
                  object:
                    | { __typename: 'Company'; id: string; name: string }
                    | { __typename: 'CompanySurvey'; id: string; name: string }
                    | { __typename: 'Dataset'; id: string; name: string }
                    | { __typename: 'Datasource'; id: string; name: string }
                    | { __typename: 'EngagementTask'; id: string; name: string }
                    | { __typename: 'Fund'; id: string; name: string }
                    | null;
                }>;
              };
            }>;
          } | null;
          datasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: { __typename?: 'Dataset'; id: string; name: string };
          };
        } | null;
      } | null>;
    };
  } | null;
  reviews: Array<{
    __typename?: 'ActivityDataTableReview';
    id: string;
    reviewer: { __typename?: 'User'; id: string; name: string };
  }>;
  measurementProject: { __typename?: 'MeasurementProject'; id: string } | null;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

export type GQOrganizationForAdtEditorFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  demoOrg: boolean;
  testOrg: boolean;
};

export type GQActivityDataTableEditorDataQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  adtId: Scalars['ID']['input'];
}>;

export type GQActivityDataTableEditorDataQuery = {
  __typename?: 'Query';
  enablePublishFileUploadFacilities: boolean;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    demoOrg: boolean;
    testOrg: boolean;
  };
  activityDataTable: {
    __typename: 'ActivityDataTable';
    id: string;
    name: string;
    filterInterval: YMInterval;
    latestBartActivityType: string | null;
    recommendedActivityTypes: Array<string>;
    connectedToAutoBart: boolean;
    autoBartKind: GQAutoBartKind | null;
    autoBartActivityType: string | null;
    draftAutoBartHasPreviousCustomExtractor: boolean;
    adminUrl: string;
    publishedExtractor: {
      __typename?: 'ActivityDataExtractor';
      id: string;
      createdAt: Date;
      kind: GQActivityDataExtractorKind;
      sql: string;
      warnings: any | null;
      error: string | null;
      published: boolean | null;
      userVisible: boolean;
      generated: boolean | null;
      duckdbVersion: string;
      bartActivityType: string | null;
      facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
      user: { __typename?: 'User'; id: string; name: string } | null;
      userUploadedTables: Array<{
        __typename?: 'UserUploadedTable';
        id: string;
        parentFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          category: GQFileCategory;
        };
      }>;
      runSqlOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      transformedOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      cleanAdtOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
    } | null;
    draftBartExtractor: {
      __typename?: 'ActivityDataExtractor';
      id: string;
      createdAt: Date;
      kind: GQActivityDataExtractorKind;
      sql: string;
      warnings: any | null;
      error: string | null;
      published: boolean | null;
      userVisible: boolean;
      generated: boolean | null;
      duckdbVersion: string;
      bartActivityType: string | null;
      facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
      user: { __typename?: 'User'; id: string; name: string } | null;
      userUploadedTables: Array<{
        __typename?: 'UserUploadedTable';
        id: string;
        parentFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          category: GQFileCategory;
        };
      }>;
      runSqlOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      transformedOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      cleanAdtOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
    } | null;
    draftCadtExtractor: {
      __typename?: 'ActivityDataExtractor';
      id: string;
      createdAt: Date;
      kind: GQActivityDataExtractorKind;
      sql: string;
      warnings: any | null;
      error: string | null;
      published: boolean | null;
      userVisible: boolean;
      generated: boolean | null;
      duckdbVersion: string;
      bartActivityType: string | null;
      facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
      user: { __typename?: 'User'; id: string; name: string } | null;
      userUploadedTables: Array<{
        __typename?: 'UserUploadedTable';
        id: string;
        parentFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          category: GQFileCategory;
        };
      }>;
      runSqlOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      transformedOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      cleanAdtOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
    } | null;
    draftScratchpadExtractor: {
      __typename?: 'ActivityDataExtractor';
      id: string;
      createdAt: Date;
      kind: GQActivityDataExtractorKind;
      sql: string;
      warnings: any | null;
      error: string | null;
      published: boolean | null;
      userVisible: boolean;
      generated: boolean | null;
      duckdbVersion: string;
      bartActivityType: string | null;
      facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
      user: { __typename?: 'User'; id: string; name: string } | null;
      userUploadedTables: Array<{
        __typename?: 'UserUploadedTable';
        id: string;
        parentFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          category: GQFileCategory;
        };
      }>;
      runSqlOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      transformedOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      cleanAdtOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
    } | null;
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      name: string;
      dataset: { __typename?: 'Dataset'; id: string; name: string };
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename: 'DataIssue';
                  id: string;
                  title: string;
                  description: string | null;
                  state: GQDataIssueState;
                  isPublished: boolean;
                  isCustomerInitiated: boolean;
                  createdAt: Date;
                  tags: Array<GQDataIssueTag> | null;
                  name: string;
                  adminUrl: string;
                  assignee: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    permissions: Array<{
                      __typename?: 'PermissionItem';
                      id: string;
                      objectId: string | null;
                      permission: GQPermissionType;
                      revokedAt: Date | null;
                      object:
                        | { __typename: 'Company'; id: string; name: string }
                        | {
                            __typename: 'CompanySurvey';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Dataset'; id: string; name: string }
                        | { __typename: 'Datasource'; id: string; name: string }
                        | {
                            __typename: 'EngagementTask';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Fund'; id: string; name: string }
                        | null;
                    }>;
                    roles: Array<{
                      __typename?: 'UserRoleAssignment';
                      id: string;
                      revokedAt: Date | null;
                      role: {
                        __typename?: 'Role';
                        id: string;
                        name: string;
                        description: string | null;
                        permissions: Array<{
                          __typename?: 'PermissionItem';
                          id: string;
                          objectId: string | null;
                          permission: GQPermissionType;
                          revokedAt: Date | null;
                          object:
                            | {
                                __typename: 'Company';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'CompanySurvey';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'Dataset';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'Datasource';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'EngagementTask';
                                id: string;
                                name: string;
                              }
                            | { __typename: 'Fund'; id: string; name: string }
                            | null;
                        }>;
                      };
                    }>;
                  } | null;
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        message: string;
                        createdAt: Date;
                        isPublished: boolean;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              name: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              name: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                  files: {
                    __typename?: 'FileMetadataConnection';
                    edges: Array<{
                      __typename?: 'FileMetadataEdge';
                      node: { __typename?: 'FileMetadata'; id: string } | null;
                    } | null>;
                  };
                  linkedObject:
                    | { __typename: 'FinancialsReviewItem'; id: string }
                    | {
                        __typename: 'MeasurementVerificationItemQuestion';
                        id: string;
                        item: {
                          __typename?: 'MeasurementVerificationItem';
                          id: string;
                          datasetName: string;
                        };
                      }
                    | {
                        __typename: 'UserUploadTask';
                        id: string;
                        assignee: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          permissions: Array<{
                            __typename?: 'PermissionItem';
                            id: string;
                            objectId: string | null;
                            permission: GQPermissionType;
                            revokedAt: Date | null;
                            object:
                              | {
                                  __typename: 'Company';
                                  id: string;
                                  name: string;
                                }
                              | {
                                  __typename: 'CompanySurvey';
                                  id: string;
                                  name: string;
                                }
                              | {
                                  __typename: 'Dataset';
                                  id: string;
                                  name: string;
                                }
                              | {
                                  __typename: 'Datasource';
                                  id: string;
                                  name: string;
                                }
                              | {
                                  __typename: 'EngagementTask';
                                  id: string;
                                  name: string;
                                }
                              | { __typename: 'Fund'; id: string; name: string }
                              | null;
                          }>;
                          roles: Array<{
                            __typename?: 'UserRoleAssignment';
                            id: string;
                            revokedAt: Date | null;
                            role: {
                              __typename?: 'Role';
                              id: string;
                              name: string;
                              description: string | null;
                              permissions: Array<{
                                __typename?: 'PermissionItem';
                                id: string;
                                objectId: string | null;
                                permission: GQPermissionType;
                                revokedAt: Date | null;
                                object:
                                  | {
                                      __typename: 'Company';
                                      id: string;
                                      name: string;
                                    }
                                  | {
                                      __typename: 'CompanySurvey';
                                      id: string;
                                      name: string;
                                    }
                                  | {
                                      __typename: 'Dataset';
                                      id: string;
                                      name: string;
                                    }
                                  | {
                                      __typename: 'Datasource';
                                      id: string;
                                      name: string;
                                    }
                                  | {
                                      __typename: 'EngagementTask';
                                      id: string;
                                      name: string;
                                    }
                                  | {
                                      __typename: 'Fund';
                                      id: string;
                                      name: string;
                                    }
                                  | null;
                              }>;
                            };
                          }>;
                        } | null;
                        datasource: {
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                          };
                        };
                      }
                    | { __typename: 'ValueMappingTask'; id: string }
                    | null;
                  ancestorRelations: Array<
                    | {
                        __typename: 'ActivityDataTable';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'DataIssue';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'Dataset';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'Datasource';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'FileMetadata';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'FinancialsReviewItem';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'FootprintQuestion';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'MeasurementProject';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'MeasurementVerificationItemQuestion';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                  >;
                } | null;
              } | null>;
            } | null;
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: {
                  __typename?: 'UserUpload';
                  id: string;
                  allAttempts: Array<{
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    description: string | null;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                    }>;
                  }>;
                } | null;
              } | null>;
            } | null;
            assignee: {
              __typename?: 'User';
              id: string;
              name: string;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
              roles: Array<{
                __typename?: 'UserRoleAssignment';
                id: string;
                revokedAt: Date | null;
                role: {
                  __typename?: 'Role';
                  id: string;
                  name: string;
                  description: string | null;
                  permissions: Array<{
                    __typename?: 'PermissionItem';
                    id: string;
                    objectId: string | null;
                    permission: GQPermissionType;
                    revokedAt: Date | null;
                    object:
                      | { __typename: 'Company'; id: string; name: string }
                      | {
                          __typename: 'CompanySurvey';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Dataset'; id: string; name: string }
                      | { __typename: 'Datasource'; id: string; name: string }
                      | {
                          __typename: 'EngagementTask';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Fund'; id: string; name: string }
                      | null;
                  }>;
                };
              }>;
            } | null;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: { __typename?: 'Dataset'; id: string; name: string };
            };
          } | null;
        } | null>;
      };
    }>;
    datasets: Array<{ __typename?: 'Dataset'; id: string; name: string }>;
    primaryDatasource: {
      __typename?: 'Datasource';
      id: string;
      name: string;
      dataset: { __typename?: 'Dataset'; id: string; name: string };
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename: 'DataIssue';
                  id: string;
                  title: string;
                  description: string | null;
                  state: GQDataIssueState;
                  isPublished: boolean;
                  isCustomerInitiated: boolean;
                  createdAt: Date;
                  tags: Array<GQDataIssueTag> | null;
                  name: string;
                  adminUrl: string;
                  assignee: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    permissions: Array<{
                      __typename?: 'PermissionItem';
                      id: string;
                      objectId: string | null;
                      permission: GQPermissionType;
                      revokedAt: Date | null;
                      object:
                        | { __typename: 'Company'; id: string; name: string }
                        | {
                            __typename: 'CompanySurvey';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Dataset'; id: string; name: string }
                        | { __typename: 'Datasource'; id: string; name: string }
                        | {
                            __typename: 'EngagementTask';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Fund'; id: string; name: string }
                        | null;
                    }>;
                    roles: Array<{
                      __typename?: 'UserRoleAssignment';
                      id: string;
                      revokedAt: Date | null;
                      role: {
                        __typename?: 'Role';
                        id: string;
                        name: string;
                        description: string | null;
                        permissions: Array<{
                          __typename?: 'PermissionItem';
                          id: string;
                          objectId: string | null;
                          permission: GQPermissionType;
                          revokedAt: Date | null;
                          object:
                            | {
                                __typename: 'Company';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'CompanySurvey';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'Dataset';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'Datasource';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'EngagementTask';
                                id: string;
                                name: string;
                              }
                            | { __typename: 'Fund'; id: string; name: string }
                            | null;
                        }>;
                      };
                    }>;
                  } | null;
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        message: string;
                        createdAt: Date;
                        isPublished: boolean;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              name: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              name: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                  files: {
                    __typename?: 'FileMetadataConnection';
                    edges: Array<{
                      __typename?: 'FileMetadataEdge';
                      node: { __typename?: 'FileMetadata'; id: string } | null;
                    } | null>;
                  };
                  linkedObject:
                    | { __typename: 'FinancialsReviewItem'; id: string }
                    | {
                        __typename: 'MeasurementVerificationItemQuestion';
                        id: string;
                        item: {
                          __typename?: 'MeasurementVerificationItem';
                          id: string;
                          datasetName: string;
                        };
                      }
                    | {
                        __typename: 'UserUploadTask';
                        id: string;
                        assignee: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          permissions: Array<{
                            __typename?: 'PermissionItem';
                            id: string;
                            objectId: string | null;
                            permission: GQPermissionType;
                            revokedAt: Date | null;
                            object:
                              | {
                                  __typename: 'Company';
                                  id: string;
                                  name: string;
                                }
                              | {
                                  __typename: 'CompanySurvey';
                                  id: string;
                                  name: string;
                                }
                              | {
                                  __typename: 'Dataset';
                                  id: string;
                                  name: string;
                                }
                              | {
                                  __typename: 'Datasource';
                                  id: string;
                                  name: string;
                                }
                              | {
                                  __typename: 'EngagementTask';
                                  id: string;
                                  name: string;
                                }
                              | { __typename: 'Fund'; id: string; name: string }
                              | null;
                          }>;
                          roles: Array<{
                            __typename?: 'UserRoleAssignment';
                            id: string;
                            revokedAt: Date | null;
                            role: {
                              __typename?: 'Role';
                              id: string;
                              name: string;
                              description: string | null;
                              permissions: Array<{
                                __typename?: 'PermissionItem';
                                id: string;
                                objectId: string | null;
                                permission: GQPermissionType;
                                revokedAt: Date | null;
                                object:
                                  | {
                                      __typename: 'Company';
                                      id: string;
                                      name: string;
                                    }
                                  | {
                                      __typename: 'CompanySurvey';
                                      id: string;
                                      name: string;
                                    }
                                  | {
                                      __typename: 'Dataset';
                                      id: string;
                                      name: string;
                                    }
                                  | {
                                      __typename: 'Datasource';
                                      id: string;
                                      name: string;
                                    }
                                  | {
                                      __typename: 'EngagementTask';
                                      id: string;
                                      name: string;
                                    }
                                  | {
                                      __typename: 'Fund';
                                      id: string;
                                      name: string;
                                    }
                                  | null;
                              }>;
                            };
                          }>;
                        } | null;
                        datasource: {
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                          };
                        };
                      }
                    | { __typename: 'ValueMappingTask'; id: string }
                    | null;
                  ancestorRelations: Array<
                    | {
                        __typename: 'ActivityDataTable';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'DataIssue';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'Dataset';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'Datasource';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'FileMetadata';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'FinancialsReviewItem';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'FootprintQuestion';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'MeasurementProject';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                    | {
                        __typename: 'MeasurementVerificationItemQuestion';
                        id: string;
                        name: string;
                        adminUrl: string;
                      }
                  >;
                } | null;
              } | null>;
            } | null;
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: {
                  __typename?: 'UserUpload';
                  id: string;
                  allAttempts: Array<{
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    description: string | null;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                    }>;
                  }>;
                } | null;
              } | null>;
            } | null;
            assignee: {
              __typename?: 'User';
              id: string;
              name: string;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
              roles: Array<{
                __typename?: 'UserRoleAssignment';
                id: string;
                revokedAt: Date | null;
                role: {
                  __typename?: 'Role';
                  id: string;
                  name: string;
                  description: string | null;
                  permissions: Array<{
                    __typename?: 'PermissionItem';
                    id: string;
                    objectId: string | null;
                    permission: GQPermissionType;
                    revokedAt: Date | null;
                    object:
                      | { __typename: 'Company'; id: string; name: string }
                      | {
                          __typename: 'CompanySurvey';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Dataset'; id: string; name: string }
                      | { __typename: 'Datasource'; id: string; name: string }
                      | {
                          __typename: 'EngagementTask';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Fund'; id: string; name: string }
                      | null;
                  }>;
                };
              }>;
            } | null;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: { __typename?: 'Dataset'; id: string; name: string };
            };
          } | null;
        } | null>;
      };
    } | null;
    reviews: Array<{
      __typename?: 'ActivityDataTableReview';
      id: string;
      reviewer: { __typename?: 'User'; id: string; name: string };
    }>;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
    } | null;
    ancestorRelations: Array<
      | {
          __typename: 'ActivityDataTable';
          id: string;
          name: string;
          adminUrl: string;
        }
      | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
      | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
      | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
      | {
          __typename: 'FileMetadata';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'FinancialsReviewItem';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'FootprintQuestion';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'MeasurementProject';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'MeasurementVerificationItemQuestion';
          id: string;
          name: string;
          adminUrl: string;
        }
    >;
  } | null;
  activityTypeSchemas: Array<{
    __typename?: 'ActivityTypeSchema';
    id: string;
    activityType: string;
    schema: { __typename?: 'CanonicalSchema'; id: string; jsonSchema: any };
  }>;
};

export type GQAdtEditorDatasourceFragment = {
  __typename?: 'Datasource';
  id: string;
  name: string;
  dataset: { __typename?: 'Dataset'; id: string; name: string };
  userUploadTasks: {
    __typename?: 'UserUploadTaskConnection';
    edges: Array<{
      __typename?: 'UserUploadTaskEdge';
      node: {
        __typename?: 'UserUploadTask';
        id: string;
        issues: {
          __typename?: 'DataIssueConnection';
          edges: Array<{
            __typename?: 'DataIssueEdge';
            node: {
              __typename: 'DataIssue';
              id: string;
              title: string;
              description: string | null;
              state: GQDataIssueState;
              isPublished: boolean;
              isCustomerInitiated: boolean;
              createdAt: Date;
              tags: Array<GQDataIssueTag> | null;
              name: string;
              adminUrl: string;
              assignee: {
                __typename?: 'User';
                id: string;
                name: string;
                permissions: Array<{
                  __typename?: 'PermissionItem';
                  id: string;
                  objectId: string | null;
                  permission: GQPermissionType;
                  revokedAt: Date | null;
                  object:
                    | { __typename: 'Company'; id: string; name: string }
                    | { __typename: 'CompanySurvey'; id: string; name: string }
                    | { __typename: 'Dataset'; id: string; name: string }
                    | { __typename: 'Datasource'; id: string; name: string }
                    | { __typename: 'EngagementTask'; id: string; name: string }
                    | { __typename: 'Fund'; id: string; name: string }
                    | null;
                }>;
                roles: Array<{
                  __typename?: 'UserRoleAssignment';
                  id: string;
                  revokedAt: Date | null;
                  role: {
                    __typename?: 'Role';
                    id: string;
                    name: string;
                    description: string | null;
                    permissions: Array<{
                      __typename?: 'PermissionItem';
                      id: string;
                      objectId: string | null;
                      permission: GQPermissionType;
                      revokedAt: Date | null;
                      object:
                        | { __typename: 'Company'; id: string; name: string }
                        | {
                            __typename: 'CompanySurvey';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Dataset'; id: string; name: string }
                        | { __typename: 'Datasource'; id: string; name: string }
                        | {
                            __typename: 'EngagementTask';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Fund'; id: string; name: string }
                        | null;
                    }>;
                  };
                }>;
              } | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    message: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          name: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
              files: {
                __typename?: 'FileMetadataConnection';
                edges: Array<{
                  __typename?: 'FileMetadataEdge';
                  node: { __typename?: 'FileMetadata'; id: string } | null;
                } | null>;
              };
              linkedObject:
                | { __typename: 'FinancialsReviewItem'; id: string }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename?: 'MeasurementVerificationItem';
                      id: string;
                      datasetName: string;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    assignee: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      permissions: Array<{
                        __typename?: 'PermissionItem';
                        id: string;
                        objectId: string | null;
                        permission: GQPermissionType;
                        revokedAt: Date | null;
                        object:
                          | { __typename: 'Company'; id: string; name: string }
                          | {
                              __typename: 'CompanySurvey';
                              id: string;
                              name: string;
                            }
                          | { __typename: 'Dataset'; id: string; name: string }
                          | {
                              __typename: 'Datasource';
                              id: string;
                              name: string;
                            }
                          | {
                              __typename: 'EngagementTask';
                              id: string;
                              name: string;
                            }
                          | { __typename: 'Fund'; id: string; name: string }
                          | null;
                      }>;
                      roles: Array<{
                        __typename?: 'UserRoleAssignment';
                        id: string;
                        revokedAt: Date | null;
                        role: {
                          __typename?: 'Role';
                          id: string;
                          name: string;
                          description: string | null;
                          permissions: Array<{
                            __typename?: 'PermissionItem';
                            id: string;
                            objectId: string | null;
                            permission: GQPermissionType;
                            revokedAt: Date | null;
                            object:
                              | {
                                  __typename: 'Company';
                                  id: string;
                                  name: string;
                                }
                              | {
                                  __typename: 'CompanySurvey';
                                  id: string;
                                  name: string;
                                }
                              | {
                                  __typename: 'Dataset';
                                  id: string;
                                  name: string;
                                }
                              | {
                                  __typename: 'Datasource';
                                  id: string;
                                  name: string;
                                }
                              | {
                                  __typename: 'EngagementTask';
                                  id: string;
                                  name: string;
                                }
                              | { __typename: 'Fund'; id: string; name: string }
                              | null;
                          }>;
                        };
                      }>;
                    } | null;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | null;
              ancestorRelations: Array<
                | {
                    __typename: 'ActivityDataTable';
                    id: string;
                    name: string;
                    adminUrl: string;
                  }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    name: string;
                    adminUrl: string;
                  }
                | {
                    __typename: 'Dataset';
                    id: string;
                    name: string;
                    adminUrl: string;
                  }
                | {
                    __typename: 'Datasource';
                    id: string;
                    name: string;
                    adminUrl: string;
                  }
                | {
                    __typename: 'FileMetadata';
                    id: string;
                    name: string;
                    adminUrl: string;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    name: string;
                    adminUrl: string;
                  }
                | {
                    __typename: 'FootprintQuestion';
                    id: string;
                    name: string;
                    adminUrl: string;
                  }
                | {
                    __typename: 'MeasurementProject';
                    id: string;
                    name: string;
                    adminUrl: string;
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    name: string;
                    adminUrl: string;
                  }
              >;
            } | null;
          } | null>;
        } | null;
        userUploads: {
          __typename?: 'UserUploadConnection';
          edges: Array<{
            __typename?: 'UserUploadEdge';
            node: {
              __typename?: 'UserUpload';
              id: string;
              allAttempts: Array<{
                __typename?: 'UserUploadAttempt';
                id: string;
                description: string | null;
                userUploadedTables: Array<{
                  __typename?: 'UserUploadedTable';
                  id: string;
                }>;
              }>;
            } | null;
          } | null>;
        } | null;
        assignee: {
          __typename?: 'User';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
          roles: Array<{
            __typename?: 'UserRoleAssignment';
            id: string;
            revokedAt: Date | null;
            role: {
              __typename?: 'Role';
              id: string;
              name: string;
              description: string | null;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
            };
          }>;
        } | null;
        datasource: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: { __typename?: 'Dataset'; id: string; name: string };
        };
      } | null;
    } | null>;
  };
};

export type GQUserUploadedTablesByIdFieldsFragment = {
  __typename?: 'UserUploadedTable';
  id: string;
  sheetName: string | null;
  sqlTableName: string | null;
  shouldIgnore: boolean;
  numRows: number | null;
  gcloudStorageUrl: string;
  sourceFileDeleted: boolean;
  userReviewMetadata: {
    __typename?: 'UserUploadedTableUserReviewMetadata';
    buildingAreaUnitOverride: GQBuildingSizeUnit | null;
    columnMappings: Array<{
      __typename?: 'ColumnMapping';
      watershedColumnName: string;
      sourceFileColumnName: string | null;
    }>;
    currencyOverride: {
      __typename?: 'CurrencyOverride';
      selectedCurrency: string;
    } | null;
  } | null;
  userIngestionReview: {
    __typename?: 'UserIngestionReview';
    id: string;
    ingestionSchemaId: string | null;
  } | null;
  parentFileMetadata: {
    __typename?: 'FileMetadata';
    id: string;
    name: string;
    category: GQFileCategory;
    notesForWatershed: string | null;
  };
};

export type GQGetUserUploadedTablesByIdQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQGetUserUploadedTablesByIdQuery = {
  __typename?: 'Query';
  userUploadedTablesById: Array<{
    __typename?: 'UserUploadedTable';
    id: string;
    sheetName: string | null;
    sqlTableName: string | null;
    shouldIgnore: boolean;
    numRows: number | null;
    gcloudStorageUrl: string;
    sourceFileDeleted: boolean;
    userReviewMetadata: {
      __typename?: 'UserUploadedTableUserReviewMetadata';
      buildingAreaUnitOverride: GQBuildingSizeUnit | null;
      columnMappings: Array<{
        __typename?: 'ColumnMapping';
        watershedColumnName: string;
        sourceFileColumnName: string | null;
      }>;
      currencyOverride: {
        __typename?: 'CurrencyOverride';
        selectedCurrency: string;
      } | null;
    } | null;
    userIngestionReview: {
      __typename?: 'UserIngestionReview';
      id: string;
      ingestionSchemaId: string | null;
    } | null;
    parentFileMetadata: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
      category: GQFileCategory;
      notesForWatershed: string | null;
    };
  }>;
};

export type GQGetAdesByIdQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQGetAdesByIdQuery = {
  __typename?: 'Query';
  activityDataExtractorsById: Array<{
    __typename?: 'ActivityDataExtractor';
    id: string;
    createdAt: Date;
    kind: GQActivityDataExtractorKind;
    sql: string;
    warnings: any | null;
    error: string | null;
    published: boolean | null;
    userVisible: boolean;
    generated: boolean | null;
    duckdbVersion: string;
    bartActivityType: string | null;
    facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
    user: { __typename?: 'User'; id: string; name: string } | null;
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        category: GQFileCategory;
      };
    }>;
    runSqlOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    transformedOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    cleanAdtOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
  }>;
};

export type GQGetActivityDataExtractorQueryVariables = Exact<{
  adeId: Scalars['ID']['input'];
}>;

export type GQGetActivityDataExtractorQuery = {
  __typename?: 'Query';
  activityDataExtractor: {
    __typename?: 'ActivityDataExtractor';
    id: string;
    createdAt: Date;
    kind: GQActivityDataExtractorKind;
    sql: string;
    warnings: any | null;
    error: string | null;
    published: boolean | null;
    userVisible: boolean;
    generated: boolean | null;
    duckdbVersion: string;
    bartActivityType: string | null;
    facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
    user: { __typename?: 'User'; id: string; name: string } | null;
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        category: GQFileCategory;
      };
    }>;
    runSqlOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    transformedOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    cleanAdtOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
  } | null;
};

export type GQGetAdtRunsQueryVariables = Exact<{
  adtId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
}>;

export type GQGetAdtRunsQuery = {
  __typename?: 'Query';
  activityDataTable: {
    __typename?: 'ActivityDataTable';
    id: string;
    activityDataExtractors: {
      __typename?: 'ActivityDataExtractorConnection';
      edges: Array<{
        __typename?: 'ActivityDataExtractorEdge';
        node: {
          __typename?: 'ActivityDataExtractor';
          id: string;
          createdAt: Date;
          kind: GQActivityDataExtractorKind;
          sql: string;
          warnings: any | null;
          error: string | null;
          published: boolean | null;
          userVisible: boolean;
          generated: boolean | null;
          duckdbVersion: string;
          bartActivityType: string | null;
          facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
          user: { __typename?: 'User'; id: string; name: string } | null;
          userUploadedTables: Array<{
            __typename?: 'UserUploadedTable';
            id: string;
            parentFileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              category: GQFileCategory;
            };
          }>;
          runSqlOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          transformedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          cleanAdtOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GQRunExtractorMutationVariables = Exact<{
  input: GQRunExtractorInput;
}>;

export type GQRunExtractorMutation = {
  __typename?: 'Mutation';
  runExtractor: {
    __typename?: 'RunExtractorPayload';
    run: { __typename?: 'ExtractorRun'; id: string };
  } | null;
};

export type GQPublishExtractorMutationVariables = Exact<{
  input: GQPublishExtractorInput;
}>;

export type GQPublishExtractorMutation = {
  __typename?: 'Mutation';
  publishExtractor: {
    __typename?: 'PublishExtractorPayload';
    jobId: string;
  } | null;
};

export type GQLazyBuildingsForAdtEditorQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQLazyBuildingsForAdtEditorQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    buildingsUntyped: Array<{
      __typename?: 'BuildingUntyped';
      id: string;
      data: any;
    }>;
  };
};

export type GQBuildingSurveyStatusForAdtEditorFragment = {
  __typename?: 'BuildingSurveyStatus';
  latestSnapshotUpToDate: boolean;
  latestSnapshotIsEmptyFile: boolean;
  numChangedBuildingsSinceUutGenerated: number;
  measurementProjectIntervalMatches: boolean;
  numAddedBuildingsSinceUutGenerated: number;
  numDeletedBuildingsSinceUutGenerated: number;
  bartActivityType: string | null;
  numOmittedPartialBuildings: number;
  latestSnapshotUut: { __typename?: 'UserUploadedTable'; id: string } | null;
};

export type GQBuildingSurveyStatusForAdtEditorQueryVariables = Exact<{
  adtId: Scalars['ID']['input'];
}>;

export type GQBuildingSurveyStatusForAdtEditorQuery = {
  __typename?: 'Query';
  activityDataTable: {
    __typename?: 'ActivityDataTable';
    id: string;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      buildingSurveyStatus: Array<{
        __typename?: 'BuildingSurveyStatus';
        latestSnapshotUpToDate: boolean;
        latestSnapshotIsEmptyFile: boolean;
        numChangedBuildingsSinceUutGenerated: number;
        measurementProjectIntervalMatches: boolean;
        numAddedBuildingsSinceUutGenerated: number;
        numDeletedBuildingsSinceUutGenerated: number;
        bartActivityType: string | null;
        numOmittedPartialBuildings: number;
        latestSnapshotUut: {
          __typename?: 'UserUploadedTable';
          id: string;
        } | null;
      }>;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
            assignee: { __typename?: 'User'; id: string; name: string } | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type GQAddActivityDataTableDialogQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQAddActivityDataTableDialogQuery = {
  __typename?: 'Query';
  featureFlagEnabledForOrg: boolean;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      coverageInterval: YMInterval;
      active: boolean;
    }>;
  };
};

export type GQCreateActivityDataTableMutationVariables = Exact<{
  input: GQCreateActivityDataTableInput;
}>;

export type GQCreateActivityDataTableMutation = {
  __typename?: 'Mutation';
  createActivityDataTable: {
    __typename?: 'CreateActivityDataTablePayload';
    table: {
      __typename?: 'ActivityDataTable';
      id: string;
      primaryDatasource: {
        __typename?: 'Datasource';
        id: string;
        hasIntegration: boolean;
      } | null;
    };
    activityDataTables: {
      __typename?: 'ActivityDataTableConnection';
      edges: Array<{
        __typename?: 'ActivityDataTableEdge';
        node: {
          __typename?: 'ActivityDataTable';
          id: string;
          name: string;
          lastRefreshedAt: Date | null;
          filterInterval: YMInterval;
          connectedToAutoBart: boolean;
          autoBartKind: GQAutoBartKind | null;
          autoBartTaskApprovalState: GQAutoBartTaskApprovalState;
          stage: GQActivityDataTableStageKind;
          primaryDatasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            hasIntegration: boolean;
            hasIntegrationOption: boolean;
            activeIntegrationConnection: {
              __typename?: 'IntegrationConnection';
              id: string;
            } | null;
          } | null;
          datasets: Array<{
            __typename?: 'Dataset';
            name: string;
            id: string;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          }>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            hasIntegration: boolean;
            hasIntegrationOption: boolean;
            activeIntegrationConnection: {
              __typename?: 'IntegrationConnection';
              id: string;
            } | null;
          }>;
          draftCadtExtractor: {
            __typename?: 'ActivityDataExtractor';
            id: string;
            createdAt: Date;
          } | null;
          draftBartExtractor: {
            __typename?: 'ActivityDataExtractor';
            id: string;
            createdAt: Date;
            pushedBuildings: boolean | null;
          } | null;
          publishedExtractor: {
            __typename?: 'ActivityDataExtractor';
            id: string;
            createdAt: Date;
            userVisible: boolean;
            facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
            bartActivityType: string | null;
          } | null;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
          } | null;
          reviews: Array<{
            __typename?: 'ActivityDataTableReview';
            id: string;
            reviewer: { __typename?: 'User'; id: string; name: string };
          }>;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GQCreateCompanyClimateCommitmentMutationVariables = Exact<{
  input: GQCreateCompanyClimateCommitmentInput;
}>;

export type GQCreateCompanyClimateCommitmentMutation = {
  __typename?: 'Mutation';
  createCompanyClimateCommitment: {
    __typename?: 'CompanyClimateCommitmentPayload';
    commitment:
      | { __typename?: 'CarbonNeutralCommitment'; id: string }
      | { __typename?: 'CleanEnergyCommitment'; id: string }
      | { __typename?: 'NetZeroCommitment'; id: string }
      | { __typename?: 'ScienceBasedTargetCommitment'; id: string };
  } | null;
};

export type GQCreateDatasetMutationVariables = Exact<{
  input: GQCreateDatasetInput;
}>;

export type GQCreateDatasetMutation = {
  __typename?: 'Mutation';
  createDataset: {
    __typename?: 'DatasetPayload';
    dataset: { __typename?: 'Dataset'; id: string };
  } | null;
};

export type GQUpdateDatasetMutationVariables = Exact<{
  input: GQUpdateDatasetInput;
}>;

export type GQUpdateDatasetMutation = {
  __typename?: 'Mutation';
  updateDataset: {
    __typename?: 'DatasetPayload';
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      explainer: string | null;
      customUsedFor: string | null;
      customPotentialOverlaps: string | null;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      } | null;
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsDataFormattingMd: string | null;
        instructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        usedFor: string | null;
        potentialOverlaps: string | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          fileMetadataId: string | null;
          remotePath: string | null;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
      customExampleData: {
        __typename?: 'TabularData';
        cells: Array<Array<any>>;
      } | null;
    };
  } | null;
};

export type GQDeleteDatasetMutationVariables = Exact<{
  input: GQDeleteDatasetInput;
}>;

export type GQDeleteDatasetMutation = {
  __typename?: 'Mutation';
  deleteDataset: {
    __typename?: 'DeleteDatasetPayload';
    id: string;
    org: {
      __typename?: 'Organization';
      id: string;
      datasets: Array<{ __typename?: 'Dataset'; id: string }>;
    } | null;
  } | null;
};

export type GQGetDatasourceDialogInputsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetDatasourceDialogInputsQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    }>;
  };
};

export type GQCreateDatasourceAdminMutationVariables = Exact<{
  input: GQCreateDatasourceAdminInput;
}>;

export type GQCreateDatasourceAdminMutation = {
  __typename?: 'Mutation';
  createDatasourceAdmin: {
    __typename?: 'AdminDatasourcePayload';
    datasource: {
      __typename?: 'Datasource';
      id: string;
      name: string;
      importInstructionsMd: string | null;
      refreshCadence: GQDatasetCadence;
      isUserConfirmed: boolean | null;
      customUsedFor: string | null;
      customPotentialOverlaps: string | null;
      notesFromWatershedMd: string | null;
      customerTargetSchemaIds: Array<string> | null;
      dataset: { __typename?: 'Dataset'; id: string };
      canonicalDatasource: {
        __typename?: 'CanonicalDatasource';
        id: string;
        name: string;
      } | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
              coverageInterval: YMInterval;
            };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: {
                  __typename?: 'UserUpload';
                  id: string;
                  name: string;
                  deletedAt: Date | null;
                  allAttempts: Array<{
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    createdAt: Date;
                    size: number;
                    gcloudStorageUrl: string;
                    deletedAt: Date | null;
                    uploader: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                    } | null;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                      sqlTableName: string | null;
                      shouldIgnore: boolean;
                    }>;
                  }>;
                  latestAttempt: {
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    name: string;
                    gcloudStorageUrl: string;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                      shouldIgnore: boolean;
                    }>;
                  };
                } | null;
              } | null>;
            } | null;
          } | null;
        } | null>;
      };
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsDataFormattingMd: string | null;
        instructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        usedFor: string | null;
        potentialOverlaps: string | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          fileMetadataId: string | null;
          remotePath: string | null;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
    };
    measurementProject: {
      __typename: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      status: GQMeasurementProjectStatus;
      completedAt: Date | null;
      coverageStartDate: Date;
      coverageEndDate: Date;
      coverageInterval: YMInterval;
      deadline: Date | null;
      supportedValueMappingRules: Array<string>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: { __typename?: 'Dataset'; id: string; name: string } | null;
        assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
            assignee: { __typename?: 'User'; id: string } | null;
          } | null;
        }>;
      };
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            datasource: { __typename?: 'Datasource'; id: string; name: string };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: { __typename?: 'UserUpload'; id: string } | null;
              } | null>;
            } | null;
            assignee: { __typename?: 'User'; id: string; name: string } | null;
          } | null;
        } | null>;
      };
      measurementProjectDatasets: Array<{
        __typename?: 'MeasurementProjectDataset';
        id: string;
        isCompleted: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          explainer: string | null;
          customUsedFor: string | null;
          customPotentialOverlaps: string | null;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            name: string;
            kind: GQCanonicalDatasetKind;
          } | null;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            customUsedFor: string | null;
            customPotentialOverlaps: string | null;
            customerTargetSchemaIds: Array<string> | null;
          }>;
        };
      }>;
    } | null;
  };
};

export type GQUpdateDatasourceAdminMutationVariables = Exact<{
  input: GQUpdateDatasourceAdminInput;
}>;

export type GQUpdateDatasourceAdminMutation = {
  __typename?: 'Mutation';
  updateDatasourceAdmin: {
    __typename?: 'DatasourcePayload';
    datasource: {
      __typename?: 'Datasource';
      id: string;
      name: string;
      importInstructionsMd: string | null;
      refreshCadence: GQDatasetCadence;
      isUserConfirmed: boolean | null;
      customUsedFor: string | null;
      customPotentialOverlaps: string | null;
      notesFromWatershedMd: string | null;
      customerTargetSchemaIds: Array<string> | null;
      dataset: { __typename?: 'Dataset'; id: string };
      canonicalDatasource: {
        __typename?: 'CanonicalDatasource';
        id: string;
        name: string;
      } | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
              coverageInterval: YMInterval;
            };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: {
                  __typename?: 'UserUpload';
                  id: string;
                  name: string;
                  deletedAt: Date | null;
                  allAttempts: Array<{
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    createdAt: Date;
                    size: number;
                    gcloudStorageUrl: string;
                    deletedAt: Date | null;
                    uploader: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                    } | null;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                      sqlTableName: string | null;
                      shouldIgnore: boolean;
                    }>;
                  }>;
                  latestAttempt: {
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    name: string;
                    gcloudStorageUrl: string;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                      shouldIgnore: boolean;
                    }>;
                  };
                } | null;
              } | null>;
            } | null;
          } | null;
        } | null>;
      };
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsDataFormattingMd: string | null;
        instructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        usedFor: string | null;
        potentialOverlaps: string | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          fileMetadataId: string | null;
          remotePath: string | null;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
    };
  } | null;
};

export type GQFootprintPinForDialogFragment = {
  __typename?: 'FootprintVersion';
  id: string;
  pinInfo: {
    __typename?: 'FootprintVersionPin';
    id: string;
    name: string;
    description: string | null;
  } | null;
};

export type GQCreateFootprintPinMutationVariables = Exact<{
  input: GQCreateFootprintVersionPinInput;
}>;

export type GQCreateFootprintPinMutation = {
  __typename?: 'Mutation';
  createFootprintVersionPin: {
    __typename?: 'CreateFootprintVersionPinPayload';
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      pinInfo: {
        __typename?: 'FootprintVersionPin';
        id: string;
        name: string;
        description: string | null;
      } | null;
    };
  } | null;
};

export type GQUpdateFootprintPinMutationVariables = Exact<{
  input: GQUpdateFootprintVersionPinInput;
}>;

export type GQUpdateFootprintPinMutation = {
  __typename?: 'Mutation';
  updateFootprintVersionPin: {
    __typename?: 'UpdateFootprintVersionPinPayload';
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      pinInfo: {
        __typename?: 'FootprintVersionPin';
        id: string;
        name: string;
        description: string | null;
      } | null;
    };
  } | null;
};

export type GQDeleteFootprintPinMutationVariables = Exact<{
  input: GQDeleteFootprintVersionPinInput;
}>;

export type GQDeleteFootprintPinMutation = {
  __typename?: 'Mutation';
  deleteFootprintVersionPin: {
    __typename?: 'DeleteFootprintVersionPinPayload';
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      pinInfo: {
        __typename?: 'FootprintVersionPin';
        id: string;
        name: string;
        description: string | null;
      } | null;
    };
  } | null;
};

export type GQAddOrgPointOfContactMutationVariables = Exact<{
  input: GQAddOrgPointOfContactInput;
}>;

export type GQAddOrgPointOfContactMutation = {
  __typename?: 'Mutation';
  addOrgPointOfContact: {
    __typename?: 'AddOrgPointOfContactPayload';
    org: {
      __typename?: 'Organization';
      id: string;
      pointsOfContact: Array<{ __typename?: 'OrgPointOfContact'; id: string }>;
    };
  } | null;
};

export type GQAddOrgPointOfContactDialog_ExistingOrgPointsOfContactFragment = {
  __typename?: 'OrgPointOfContact';
  id: string;
  watershedEmployee: { __typename?: 'WatershedEmployee'; id: string };
};

export type GQCreateOrganizationMutationVariables = Exact<{
  input: GQCreateOrganizationInput;
}>;

export type GQCreateOrganizationMutation = {
  __typename?: 'Mutation';
  createOrganization: {
    __typename?: 'OrganizationPayload';
    org: { __typename?: 'Organization'; id: string };
  } | null;
};

export type GQCreateUserAdminVersionMutationVariables = Exact<{
  input: GQCreateUserAdminVersionInput;
}>;

export type GQCreateUserAdminVersionMutation = {
  __typename?: 'Mutation';
  createUserAdminVersion: {
    __typename?: 'CreateUserAdminVersionPayload';
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    };
  } | null;
};

export type GQCreateUserUploadTaskMutationVariables = Exact<{
  input: GQCreateUserUploadTaskInput;
}>;

export type GQCreateUserUploadTaskMutation = {
  __typename?: 'Mutation';
  createUserUploadTask: {
    __typename?: 'CreateUserUploadTaskPayload';
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: { __typename?: 'UserUploadTask'; id: string } | null;
        } | null>;
      };
    };
  } | null;
};

export type GQOrgFieldsForAdminNavFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  hasUsers: boolean;
  demoOrg: boolean;
  testOrg: boolean;
  watershedPlanLegacy: GQWatershedPlanLegacy;
};

export type GQAdminNavQueryVariables = Exact<{ [key: string]: never }>;

export type GQAdminNavQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'OrgConnection';
    edges: Array<{
      __typename?: 'OrgEdge';
      node: {
        __typename?: 'Organization';
        id: string;
        name: string;
        hasUsers: boolean;
        demoOrg: boolean;
        testOrg: boolean;
        watershedPlanLegacy: GQWatershedPlanLegacy;
      };
    } | null>;
  };
};

export type GQRegenUserSignupTokenAdminVersionMutationVariables = Exact<{
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type GQRegenUserSignupTokenAdminVersionMutation = {
  __typename?: 'Mutation';
  regenerateUserSignupTokenAdminVersion: {
    __typename?: 'RegenerateUserSignupTokenPayload';
    user: { __typename?: 'User'; id: string; signupLink: string | null };
  } | null;
};

export type GQApplicableDatasourcesQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  integrationPartnerId: Scalars['ID']['input'];
}>;

export type GQApplicableDatasourcesQuery = {
  __typename?: 'Query';
  applicableDatasources: Array<{
    __typename?: 'Datasource';
    id: string;
    name: string;
    hasIntegration: boolean;
    dataset: { __typename?: 'Dataset'; id: string; name: string };
  }>;
};

export type GQApplyIntegrationConnectionMutationVariables = Exact<{
  input: GQApplyIntegrationConnectionInput;
}>;

export type GQApplyIntegrationConnectionMutation = {
  __typename?: 'Mutation';
  applyIntegrationConnection: {
    __typename?: 'ApplyIntegrationConnectionPayload';
    integrationConnection: {
      __typename?: 'IntegrationConnection';
      id: string;
      datasources: {
        __typename?: 'DatasourceConnection';
        edges: Array<{
          __typename?: 'DatasourceEdge';
          node: { __typename?: 'Datasource'; id: string } | null;
        }>;
      };
    };
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      hasIntegration: boolean;
      activeIntegrationConnection: {
        __typename?: 'IntegrationConnection';
        id: string;
      } | null;
    }>;
  };
};

export type GQUpdateActivityDataTableReviewerMutationVariables = Exact<{
  input: GQUpdateActivityDataTableReviewerInput;
}>;

export type GQUpdateActivityDataTableReviewerMutation = {
  __typename?: 'Mutation';
  updateActivityDataTableReviewer: {
    __typename?: 'UpdateActivityDataTableReviewerPayload';
    activityDataTable: {
      __typename?: 'ActivityDataTable';
      id: string;
      reviews: Array<{
        __typename?: 'ActivityDataTableReview';
        id: string;
        reviewer: { __typename?: 'User'; id: string; name: string };
      }>;
    };
  } | null;
};

export type GQGetCanonicalDatasetsWithOrgQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetCanonicalDatasetsWithOrgQuery = {
  __typename?: 'Query';
  canonicalDatasets: Array<{
    __typename?: 'CanonicalDataset';
    id: string;
    name: string;
    customerTargetSchemas: Array<{
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      descriptionMd: string | null;
    }>;
  }>;
  organization: {
    __typename?: 'Organization';
    id: string;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      canonicalDataset: { __typename?: 'CanonicalDataset'; id: string } | null;
    }>;
  };
};

export type GQGetCanonicalDatasourcesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetCanonicalDatasourcesQuery = {
  __typename?: 'Query';
  canonicalDatasets: Array<{
    __typename?: 'CanonicalDataset';
    id: string;
    name: string;
    customerTargetSchemas: Array<{
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
    }>;
    canonicalDatasources: Array<{
      __typename?: 'CanonicalDatasource';
      id: string;
      name: string;
    }>;
  }>;
};

export type GQSetFootprintAsWatershedReviewedMutationVariables = Exact<{
  input: GQSetFootprintAsWatershedReviewedInput;
}>;

export type GQSetFootprintAsWatershedReviewedMutation = {
  __typename?: 'Mutation';
  setFootprintAsWatershedReviewed: {
    __typename?: 'SetFootprintAsWatershedReviewedPayload';
    footprintVersion: { __typename?: 'FootprintVersion'; id: string };
  } | null;
};

export type GQConfigureAutoBartMutationVariables = Exact<{
  input: GQConfigureAutoBartInput;
}>;

export type GQConfigureAutoBartMutation = {
  __typename?: 'Mutation';
  configureAutoBart: {
    __typename?: 'ConfigureAutoBartPayload';
    assignedAdt: {
      __typename?: 'ActivityDataTable';
      id: string;
      connectedToAutoBart: boolean;
    } | null;
    unassignedAdt: {
      __typename?: 'ActivityDataTable';
      id: string;
      connectedToAutoBart: boolean;
    } | null;
  } | null;
};

export type GQCreateFootprintCustomReportMutationVariables = Exact<{
  input: GQCreateFootprintCustomReportInput;
}>;

export type GQCreateFootprintCustomReportMutation = {
  __typename?: 'Mutation';
  createFootprintCustomReport: {
    __typename?: 'CreateFootprintCustomReportPayload';
    footprintCustomReport: { __typename?: 'FootprintCustomReport'; id: string };
  } | null;
};

export type GQDatasetsForDatasourceSelectorQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQDatasetsForDatasourceSelectorQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
        hasIntegration: boolean;
        userUploadTasks: {
          __typename?: 'UserUploadTaskConnection';
          edges: Array<{
            __typename?: 'UserUploadTaskEdge';
            node: { __typename?: 'UserUploadTask'; id: string } | null;
          } | null>;
        };
      }>;
    }>;
  };
};

export type GQGetIntensityQuantitiesByMonthQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  footprintSnapshotId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQGetIntensityQuantitiesByMonthQuery = {
  __typename?: 'Query';
  intensityQuantitiesByMonth: {
    __typename?: 'IntensityQuantitiesPerMonthPayload';
    quantitiesPerMonth: Array<{
      __typename?: 'IntensityQuantitiesPerMonth';
      intensityKind: string;
      month: Date;
      unit: string;
      quantity: number;
    }>;
  };
};

export type GQShouldShowDuckQueryVariables = Exact<{
  duckName: Scalars['String']['input'];
}>;

export type GQShouldShowDuckQuery = {
  __typename?: 'Query';
  shouldShowDuck: boolean;
};

export type GQFindDuckMutationVariables = Exact<{
  input: GQFindDuckInput;
}>;

export type GQFindDuckMutation = {
  __typename?: 'Mutation';
  findDuck: { __typename?: 'FindDuckPayload'; description: string };
};

export type GQEditActivityDataTableDialogQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQEditActivityDataTableDialogQuery = {
  __typename?: 'Query';
  featureFlagEnabledForOrg: boolean;
};

export type GQUpdateCompanyClimateCommitmentMutationVariables = Exact<{
  input: GQUpdateCompanyClimateCommitmentInput;
}>;

export type GQUpdateCompanyClimateCommitmentMutation = {
  __typename?: 'Mutation';
  updateCompanyClimateCommitment: {
    __typename?: 'CompanyClimateCommitmentPayload';
    commitment:
      | { __typename?: 'CarbonNeutralCommitment'; id: string }
      | { __typename?: 'CleanEnergyCommitment'; id: string }
      | { __typename?: 'NetZeroCommitment'; id: string }
      | { __typename?: 'ScienceBasedTargetCommitment'; id: string };
  } | null;
};

export type GQUpdateFootprintSnapshotMutationVariables = Exact<{
  input: GQUpdateFootprintSnapshotInput;
}>;

export type GQUpdateFootprintSnapshotMutation = {
  __typename?: 'Mutation';
  updateFootprintSnapshot: {
    __typename?: 'UpdateFootprintSnapshotPayload';
    snapshot: {
      __typename?: 'FootprintSnapshot';
      id: string;
      descriptionMd: string | null;
    };
  } | null;
};

export type GQUpdateMeasurementProjectMutationVariables = Exact<{
  input: GQUpdateMeasurementProjectInput;
}>;

export type GQUpdateMeasurementProjectMutation = {
  __typename?: 'Mutation';
  updateMeasurementProject: {
    __typename?: 'UpdateMeasurementProjectPayload';
    measurementProject: {
      __typename: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      status: GQMeasurementProjectStatus;
      completedAt: Date | null;
      coverageStartDate: Date;
      coverageEndDate: Date;
      coverageInterval: YMInterval;
      deadline: Date | null;
      supportedValueMappingRules: Array<string>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: { __typename?: 'Dataset'; id: string; name: string } | null;
        assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
            assignee: { __typename?: 'User'; id: string } | null;
          } | null;
        }>;
      };
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            datasource: { __typename?: 'Datasource'; id: string; name: string };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: { __typename?: 'UserUpload'; id: string } | null;
              } | null>;
            } | null;
            assignee: { __typename?: 'User'; id: string; name: string } | null;
          } | null;
        } | null>;
      };
      measurementProjectDatasets: Array<{
        __typename?: 'MeasurementProjectDataset';
        id: string;
        isCompleted: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          explainer: string | null;
          customUsedFor: string | null;
          customPotentialOverlaps: string | null;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            name: string;
            kind: GQCanonicalDatasetKind;
          } | null;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            customUsedFor: string | null;
            customPotentialOverlaps: string | null;
            customerTargetSchemaIds: Array<string> | null;
          }>;
        };
      }>;
    };
  } | null;
};

export type GQGetUserUploadedTableQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetUserUploadedTableQuery = {
  __typename?: 'Query';
  userUploadedTable: {
    __typename?: 'UserUploadedTable';
    id: string;
    sqlTableName: string | null;
    status: GQUserUploadedTableStatus;
    errorType: string | null;
    error: string | null;
    isUsingLatestUIR: boolean;
    schema: Array<{
      __typename?: 'UserUploadedTableSchemaColumn';
      index: number;
      alias: string;
      name: string;
      kind: GQUserUploadedTableSchemaColumnKind;
      forwardFill: boolean;
    }> | null;
    parseConfig: {
      __typename?: 'UserUploadedTableParseConfig';
      noHeader: boolean;
      skipTopNRows: number;
      skipBottomNRows: number;
      meltIdVars: Array<string | null> | null;
    } | null;
    userReviewMetadata: {
      __typename?: 'UserUploadedTableUserReviewMetadata';
      headerRowIndex: number;
      skipBottomNRows: number;
      buildingAreaUnitOverride: GQBuildingSizeUnit | null;
      stepsCompleted: Array<GQImportFlowDataCleaningStep> | null;
      columnMappings: Array<{
        __typename?: 'ColumnMapping';
        watershedColumnName: string;
        sourceFileColumnName: string | null;
      }>;
      currencyOverride: {
        __typename?: 'CurrencyOverride';
        selectedCurrency: string;
      } | null;
    } | null;
    userIngestionReview: {
      __typename?: 'UserIngestionReview';
      id: string;
      ingestionSchemaId: string | null;
    } | null;
    dataPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
      schema: {
        __typename?: 'UserUploadedTableDataPreviewSchema';
        fields: Array<{
          __typename?: 'UserUploadedTableDataPreviewSchemaField';
          name: string;
          type: string;
        }>;
      };
    } | null;
  } | null;
};

export type GQSetUserUploadedTableSqlTableNameMutationVariables = Exact<{
  input: GQSetUserUploadedTableSqlTableNameInput;
}>;

export type GQSetUserUploadedTableSqlTableNameMutation = {
  __typename?: 'Mutation';
  setUserUploadedTableSqlTableName: {
    __typename?: 'SetUserUploadedTableSqlTableNamePayload';
    userUploadedTable: {
      __typename?: 'UserUploadedTable';
      id: string;
      sqlTableName: string | null;
    };
  } | null;
};

export type GQMarkUserUploadAcknowledgedMutationVariables = Exact<{
  input: GQMarkUserUploadAcknowledgedInput;
}>;

export type GQMarkUserUploadAcknowledgedMutation = {
  __typename?: 'Mutation';
  markUserUploadAcknowledged: {
    __typename?: 'MarkUserUploadAcknowledgedPayload';
    userUpload: {
      __typename?: 'UserUpload';
      id: string;
      latestAttempt: {
        __typename?: 'UserUploadAttempt';
        id: string;
        userUploadedTables: Array<{
          __typename?: 'UserUploadedTable';
          id: string;
          shouldIgnore: boolean;
        }>;
      };
    };
  } | null;
};

export type GQMarkUserUploadedTablesIgnoredMutationVariables = Exact<{
  input: GQMarkUserUploadedTablesIgnoredInput;
}>;

export type GQMarkUserUploadedTablesIgnoredMutation = {
  __typename?: 'Mutation';
  markUserUploadedTablesIgnored: {
    __typename?: 'MarkUserUploadedTablesIgnoredPayload';
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      shouldIgnore: boolean;
    }>;
  } | null;
};

export type GQSetUserUploadedTableNotIgnoredMutationVariables = Exact<{
  userUploadedTableId: Scalars['ID']['input'];
}>;

export type GQSetUserUploadedTableNotIgnoredMutation = {
  __typename?: 'Mutation';
  setUserUploadedTableNotIgnored: {
    __typename?: 'SetUserUploadedTableNotIgnoredPayload';
    userUploadedTable: {
      __typename?: 'UserUploadedTable';
      id: string;
      shouldIgnore: boolean;
    };
  } | null;
};

export type GQUserUploadFieldsForActionsFragment = {
  __typename?: 'UserUpload';
  id: string;
  name: string;
  latestAttempt: {
    __typename?: 'UserUploadAttempt';
    id: string;
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      shouldIgnore: boolean;
    }>;
  };
};

export type GQGetOrganizationNameQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetOrganizationNameQuery = {
  __typename?: 'Query';
  organization: { __typename?: 'Organization'; id: string };
};

export type GQGetCleanPowerQueryPlaygroundLinkQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  pipelineRunConfig: InputMaybe<Scalars['String']['input']>;
}>;

export type GQGetCleanPowerQueryPlaygroundLinkQuery = {
  __typename?: 'Query';
  cleanPowerQueryPlaygroundLink: string | null;
};

export type GQSetFootprintVersionUserVisibleMutationVariables = Exact<{
  input: GQSetFootprintVersionUserVisibleInput;
}>;

export type GQSetFootprintVersionUserVisibleMutation = {
  __typename?: 'Mutation';
  setFootprintVersionUserVisible: {
    __typename?: 'SetFootprintVersionUserVisiblePayload';
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      isUserVisible: boolean;
    };
  } | null;
};

export type GQFootprintVersionsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  isUserVisible: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQFootprintVersionsQuery = {
  __typename?: 'Query';
  footprintVersions: {
    __typename?: 'FootprintVersionConnection';
    edges: Array<{
      __typename?: 'FootprintVersionEdge';
      node: {
        __typename?: 'FootprintVersion';
        id: string;
        orgId: string;
        createdAt: Date;
        kind: GQFootprintVersionKind;
        versionName: string | null;
        versionIdentifier: string | null;
        internalNotesMd: string;
        userChangelogMd: string | null;
        publishableUserChangelogMd: string | null;
        isUserChangelogHidden: boolean;
        isUserVisible: boolean;
        canUserPublish: boolean;
        originalFootprintSnapshot: {
          __typename?: 'FootprintSnapshot';
          id: string;
        } | null;
        pinInfo: {
          __typename?: 'FootprintVersionPin';
          id: string;
          name: string;
          description: string | null;
        } | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type GQLoginAsUserMutationVariables = Exact<{
  input: GQLoginAsUserInput;
}>;

export type GQLoginAsUserMutation = {
  __typename?: 'Mutation';
  loginAsUser: {
    __typename?: 'LoginAsUserPayload';
    token: string | null;
    isSuccessful: boolean;
  };
};

export type GQAddSupplierPointOfContactDialog_MarketplacePointOfContactFragment =
  {
    __typename?: 'MarketplacePointOfContact';
    id: string;
    name: string;
    email: string;
  };

export type GQAddSupplierPointOfContactDialog_DataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQAddSupplierPointOfContactDialog_DataQuery = {
  __typename?: 'Query';
  marketplacePointsOfContact: {
    __typename?: 'MarketplacePointOfContactConnection';
    edges: Array<{
      __typename?: 'MarketplacePointOfContactEdge';
      node: {
        __typename?: 'MarketplacePointOfContact';
        id: string;
        name: string;
        email: string;
      } | null;
    } | null>;
  };
};

export type GQAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnectionMutationVariables =
  Exact<{
    input: GQAddMarketplaceSupplierPointOfContactConnectionInput;
  }>;

export type GQAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactConnectionMutation =
  {
    __typename?: 'Mutation';
    addMarketplaceSupplierPointOfContactConnection: {
      __typename?: 'AddMarketplaceSupplierPointOfContactConnectionPayload';
      marketplaceSupplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
        projects: {
          __typename?: 'MarketplaceProjectConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectEdge';
            node: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
              };
            } | null;
          } | null>;
        };
        offerings: {
          __typename?: 'MarketplaceProjectOfferingConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectOfferingEdge';
            node: {
              __typename: 'MarketplaceProjectOffering';
              id: string;
              vintage: number | null;
              createdAt: Date;
              availability: number;
              priceUsdCents: number;
              priceWithFeeUsdCents: number;
              noteMd: string | null;
              supplier: {
                __typename?: 'MarketplaceSupplier';
                id: string;
                name: string;
              };
              project: {
                __typename?: 'MarketplaceProject';
                id: string;
                name: string;
                deletedAt: Date | null;
                status: GQMarketplaceProjectStatus;
                archetype: {
                  __typename?: 'MarketplaceProjectArchetype';
                  id: string;
                  name: string;
                  unit: GQMarketplaceProjectArchetypeUnit;
                  mechanism: GQMarketplaceProjectArchetypeMechanism;
                };
              };
            } | null;
          } | null>;
        };
        pointsOfContact: Array<{
          __typename: 'MarketplacePointOfContact';
          id: string;
          name: string;
          email: string;
        }>;
        watershedEmployees: Array<{
          __typename: 'WatershedEmployee';
          id: string;
          name: string;
          email: string;
        }>;
      };
    } | null;
  };

export type GQAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactMutationVariables =
  Exact<{
    input: GQAddMarketplaceSupplierPointOfContactInput;
  }>;

export type GQAddSupplierPointOfContactDialog_AddMarketplaceSupplierPointOfContactMutation =
  {
    __typename?: 'Mutation';
    addMarketplaceSupplierPointOfContact: {
      __typename?: 'AddMarketplaceSupplierPointOfContactPayload';
      marketplaceSupplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
        projects: {
          __typename?: 'MarketplaceProjectConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectEdge';
            node: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
              };
            } | null;
          } | null>;
        };
        offerings: {
          __typename?: 'MarketplaceProjectOfferingConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectOfferingEdge';
            node: {
              __typename: 'MarketplaceProjectOffering';
              id: string;
              vintage: number | null;
              createdAt: Date;
              availability: number;
              priceUsdCents: number;
              priceWithFeeUsdCents: number;
              noteMd: string | null;
              supplier: {
                __typename?: 'MarketplaceSupplier';
                id: string;
                name: string;
              };
              project: {
                __typename?: 'MarketplaceProject';
                id: string;
                name: string;
                deletedAt: Date | null;
                status: GQMarketplaceProjectStatus;
                archetype: {
                  __typename?: 'MarketplaceProjectArchetype';
                  id: string;
                  name: string;
                  unit: GQMarketplaceProjectArchetypeUnit;
                  mechanism: GQMarketplaceProjectArchetypeMechanism;
                };
              };
            } | null;
          } | null>;
        };
        pointsOfContact: Array<{
          __typename: 'MarketplacePointOfContact';
          id: string;
          name: string;
          email: string;
        }>;
        watershedEmployees: Array<{
          __typename: 'WatershedEmployee';
          id: string;
          name: string;
          email: string;
        }>;
      };
    } | null;
  };

export type GQAddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployeeMutationVariables =
  Exact<{
    input: GQAddMarketplaceSupplierWatershedEmployeeInput;
  }>;

export type GQAddSupplierWatershedEmployeeDialog_AddMarketplaceSupplierWatershedEmployeeMutation =
  {
    __typename?: 'Mutation';
    addMarketplaceSupplierWatershedEmployee: {
      __typename?: 'AddMarketplaceSupplierWatershedEmployeePayload';
      marketplaceSupplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
        projects: {
          __typename?: 'MarketplaceProjectConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectEdge';
            node: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
              };
            } | null;
          } | null>;
        };
        offerings: {
          __typename?: 'MarketplaceProjectOfferingConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectOfferingEdge';
            node: {
              __typename: 'MarketplaceProjectOffering';
              id: string;
              vintage: number | null;
              createdAt: Date;
              availability: number;
              priceUsdCents: number;
              priceWithFeeUsdCents: number;
              noteMd: string | null;
              supplier: {
                __typename?: 'MarketplaceSupplier';
                id: string;
                name: string;
              };
              project: {
                __typename?: 'MarketplaceProject';
                id: string;
                name: string;
                deletedAt: Date | null;
                status: GQMarketplaceProjectStatus;
                archetype: {
                  __typename?: 'MarketplaceProjectArchetype';
                  id: string;
                  name: string;
                  unit: GQMarketplaceProjectArchetypeUnit;
                  mechanism: GQMarketplaceProjectArchetypeMechanism;
                };
              };
            } | null;
          } | null>;
        };
        pointsOfContact: Array<{
          __typename: 'MarketplacePointOfContact';
          id: string;
          name: string;
          email: string;
        }>;
        watershedEmployees: Array<{
          __typename: 'WatershedEmployee';
          id: string;
          name: string;
          email: string;
        }>;
      };
    } | null;
  };

export type GQCreateEacMarketplaceProjectsMutationVariables = Exact<{
  input: GQCreateEacMarketplaceProjectsInput;
}>;

export type GQCreateEacMarketplaceProjectsMutation = {
  __typename?: 'Mutation';
  createEacMarketplaceProjects: {
    __typename?: 'CreateEacMarketplaceProjectsPayload';
    affectedMarketplaceProjectIds: Array<string>;
  };
};

export type GQMarketplaceArchetypesAndSuppliersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQMarketplaceArchetypesAndSuppliersQuery = {
  __typename?: 'Query';
  marketplaceProjectArchetypes: {
    __typename?: 'MarketplaceProjectArchetypeConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectArchetypeEdge';
      node: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
      } | null;
    } | null>;
  };
  marketplaceSuppliers: {
    __typename?: 'MarketplaceSupplierConnection';
    edges: Array<{
      __typename?: 'MarketplaceSupplierEdge';
      node: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
      } | null;
    } | null>;
  };
};

export type GQCreateMarketplaceProjectMutationVariables = Exact<{
  input: GQCreateMarketplaceProjectInput;
}>;

export type GQCreateMarketplaceProjectMutation = {
  __typename?: 'Mutation';
  createMarketplaceProject: {
    __typename?: 'CreateMarketplaceProjectPayload';
    marketplaceProject: {
      __typename?: 'MarketplaceProject';
      id: string;
      name: string;
      deletedAt: Date | null;
      status: GQMarketplaceProjectStatus;
      internalNotesMd: string | null;
      registryId: string | null;
      supplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
        projects: {
          __typename?: 'MarketplaceProjectConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectEdge';
            node: { __typename?: 'MarketplaceProject'; id: string } | null;
          } | null>;
        };
      } | null;
      archetype: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
      };
      developer: {
        __typename?: 'MarketplaceDeveloper';
        id: string;
        name: string;
        imageUrl: string | null;
        url: string | null;
        descriptionMd: string | null;
        deletedAt: Date | null;
      } | null;
      offerings: {
        __typename?: 'MarketplaceProjectOfferingConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectOfferingEdge';
          node: {
            __typename: 'MarketplaceProjectOffering';
            id: string;
            vintage: number | null;
            createdAt: Date;
            availability: number;
            priceUsdCents: number;
            priceWithFeeUsdCents: number;
            noteMd: string | null;
            supplier: {
              __typename?: 'MarketplaceSupplier';
              id: string;
              name: string;
            };
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              deletedAt: Date | null;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
                mechanism: GQMarketplaceProjectArchetypeMechanism;
              };
            };
          } | null;
        } | null>;
      };
    };
  } | null;
};

export type GQUpdateMarketplaceProjectMutationVariables = Exact<{
  input: GQUpdateMarketplaceProjectInput;
}>;

export type GQUpdateMarketplaceProjectMutation = {
  __typename?: 'Mutation';
  updateMarketplaceProject: {
    __typename?: 'UpdateMarketplaceProjectPayload';
    marketplaceProject: {
      __typename?: 'MarketplaceProject';
      id: string;
      name: string;
      deletedAt: Date | null;
      status: GQMarketplaceProjectStatus;
      internalNotesMd: string | null;
      registryId: string | null;
      archetype: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
      };
      developer: {
        __typename?: 'MarketplaceDeveloper';
        id: string;
        name: string;
        imageUrl: string | null;
        url: string | null;
        descriptionMd: string | null;
        deletedAt: Date | null;
      } | null;
      supplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
      } | null;
      offerings: {
        __typename?: 'MarketplaceProjectOfferingConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectOfferingEdge';
          node: {
            __typename: 'MarketplaceProjectOffering';
            id: string;
            vintage: number | null;
            createdAt: Date;
            availability: number;
            priceUsdCents: number;
            priceWithFeeUsdCents: number;
            noteMd: string | null;
            supplier: {
              __typename?: 'MarketplaceSupplier';
              id: string;
              name: string;
            };
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              deletedAt: Date | null;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
                mechanism: GQMarketplaceProjectArchetypeMechanism;
              };
            };
          } | null;
        } | null>;
      };
    };
  } | null;
};

export type GQMarketplaceArchetypesAndDevelopersAndSuppliersQueryVariables =
  Exact<{ [key: string]: never }>;

export type GQMarketplaceArchetypesAndDevelopersAndSuppliersQuery = {
  __typename?: 'Query';
  marketplaceProjectArchetypes: {
    __typename?: 'MarketplaceProjectArchetypeConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectArchetypeEdge';
      node: {
        __typename: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
        isUserVisible: boolean;
        mechanism: GQMarketplaceProjectArchetypeMechanism;
        kind: GQMarketplaceProjectArchetypeKind;
        unit: GQMarketplaceProjectArchetypeUnit;
        summaryMd: string;
        priceUsdCentsMin: number | null;
        priceUsdCentsMax: number | null;
        projects: {
          __typename?: 'MarketplaceProjectConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectEdge';
            node: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
  marketplaceDevelopers: {
    __typename?: 'MarketplaceDeveloperConnection';
    edges: Array<{
      __typename?: 'MarketplaceDeveloperEdge';
      node: {
        __typename?: 'MarketplaceDeveloper';
        id: string;
        name: string;
      } | null;
    } | null>;
  };
  marketplaceSuppliers: {
    __typename?: 'MarketplaceSupplierConnection';
    edges: Array<{
      __typename?: 'MarketplaceSupplierEdge';
      node: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
      } | null;
    } | null>;
  };
};

export type GQDeleteMarketplaceAllocationInstructionMutationVariables = Exact<{
  input: GQDeleteMarketplaceAllocationInstructionInput;
}>;

export type GQDeleteMarketplaceAllocationInstructionMutation = {
  __typename?: 'Mutation';
  deleteMarketplaceAllocationInstruction: {
    __typename?: 'DeleteMarketplaceAllocationInstructionPayload';
    allocationInstruction: {
      __typename?: 'MarketplaceAllocationInstruction';
      id: string;
      createdAt: Date;
      deletedAt: Date | null;
      amountKwh: number;
      isZeroedOut: boolean;
      interval: YMInterval;
      locationCity: string | null;
      locationGrid: string | null;
      locationState: string | null;
      locationCountry: string | null;
      buildingName: string | null;
      vendorEntity: string | null;
      vendorSubentity: string | null;
      product: string | null;
      categoryId: string | null;
      ghgCategoryId: string;
      footprintKind: string | null;
      subcategoryId: string | null;
      results: {
        __typename?: 'MarketplaceAllocationInstructionResultConnection';
        edges: Array<{
          __typename?: 'MarketplaceAllocationInstructionResultEdge';
          node: {
            __typename?: 'MarketplaceAllocationInstructionResult';
            id: string;
            isZeroedOut: boolean;
            amountAllocatedKwh: number;
          } | null;
        } | null>;
      } | null;
    };
  } | null;
};

export type GQDeleteAllMarketplaceAllocationInstructionsMutationVariables =
  Exact<{
    input: GQDeleteAllMarketplaceAllocationInstructionsInput;
  }>;

export type GQDeleteAllMarketplaceAllocationInstructionsMutation = {
  __typename?: 'Mutation';
  deleteAllMarketplaceAllocationInstructions: {
    __typename?: 'DeleteAllMarketplaceAllocationInstructionsPayload';
    allocationInstructions: Array<{
      __typename?: 'MarketplaceAllocationInstruction';
      id: string;
      createdAt: Date;
      deletedAt: Date | null;
      amountKwh: number;
      isZeroedOut: boolean;
      interval: YMInterval;
      locationCity: string | null;
      locationGrid: string | null;
      locationState: string | null;
      locationCountry: string | null;
      buildingName: string | null;
      vendorEntity: string | null;
      vendorSubentity: string | null;
      product: string | null;
      categoryId: string | null;
      ghgCategoryId: string;
      footprintKind: string | null;
      subcategoryId: string | null;
      results: {
        __typename?: 'MarketplaceAllocationInstructionResultConnection';
        edges: Array<{
          __typename?: 'MarketplaceAllocationInstructionResultEdge';
          node: {
            __typename?: 'MarketplaceAllocationInstructionResult';
            id: string;
            isZeroedOut: boolean;
            amountAllocatedKwh: number;
          } | null;
        } | null>;
      } | null;
    }>;
  } | null;
};

export type GQBulkImportMarketplaceAllocationInstructionsMutationVariables =
  Exact<{
    input: GQBulkImportMarketplaceAllocationInstructionsInput;
  }>;

export type GQBulkImportMarketplaceAllocationInstructionsMutation = {
  __typename?: 'Mutation';
  bulkImportMarketplaceAllocationInstructions: {
    __typename?: 'BulkImportMarketplaceAllocationInstructionsPayload';
    allocationInstructions: Array<{
      __typename?: 'MarketplaceAllocationInstruction';
      id: string;
      createdAt: Date;
      deletedAt: Date | null;
      amountKwh: number;
      isZeroedOut: boolean;
      interval: YMInterval;
      locationCity: string | null;
      locationGrid: string | null;
      locationState: string | null;
      locationCountry: string | null;
      buildingName: string | null;
      vendorEntity: string | null;
      vendorSubentity: string | null;
      product: string | null;
      categoryId: string | null;
      ghgCategoryId: string;
      footprintKind: string | null;
      subcategoryId: string | null;
      results: {
        __typename?: 'MarketplaceAllocationInstructionResultConnection';
        edges: Array<{
          __typename?: 'MarketplaceAllocationInstructionResultEdge';
          node: {
            __typename?: 'MarketplaceAllocationInstructionResult';
            id: string;
            isZeroedOut: boolean;
            amountAllocatedKwh: number;
          } | null;
        } | null>;
      } | null;
    }>;
  };
};

export type GQCreateMarketplaceProjectArchetypeMutationVariables = Exact<{
  input: GQCreateMarketplaceProjectArchetypeInput;
}>;

export type GQCreateMarketplaceProjectArchetypeMutation = {
  __typename?: 'Mutation';
  createMarketplaceProjectArchetype: {
    __typename?: 'CreateMarketplaceProjectArchetypePayload';
    marketplaceProjectArchetype: {
      __typename?: 'MarketplaceProjectArchetype';
      id: string;
      kind: GQMarketplaceProjectArchetypeKind;
      unit: GQMarketplaceProjectArchetypeUnit;
      isUserVisible: boolean;
      name: string;
      descriptionMd: string;
      summaryMd: string;
      imageUrl: string | null;
      bannerImageUrl: string | null;
      certificateBlurb: string | null;
      certificateColor: string | null;
      additionalityRating: number | null;
      permanenceRating: string | null;
      projects: {
        __typename?: 'MarketplaceProjectConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectEdge';
          node: {
            __typename?: 'MarketplaceProject';
            id: string;
            name: string;
          } | null;
        } | null>;
      };
      offerings: {
        __typename?: 'MarketplaceProjectOfferingConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectOfferingEdge';
          node: {
            __typename: 'MarketplaceProjectOffering';
            id: string;
            vintage: number | null;
            createdAt: Date;
            availability: number;
            priceUsdCents: number;
            priceWithFeeUsdCents: number;
            noteMd: string | null;
            supplier: {
              __typename?: 'MarketplaceSupplier';
              id: string;
              name: string;
            };
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              deletedAt: Date | null;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
                mechanism: GQMarketplaceProjectArchetypeMechanism;
              };
            };
          } | null;
        } | null>;
      };
    };
  } | null;
};

export type GQUpdateMarketplaceProjectArchetypeMutationVariables = Exact<{
  input: GQUpdateMarketplaceProjectArchetypeInput;
}>;

export type GQUpdateMarketplaceProjectArchetypeMutation = {
  __typename?: 'Mutation';
  updateMarketplaceProjectArchetype: {
    __typename?: 'UpdateMarketplaceProjectArchetypePayload';
    marketplaceProjectArchetype: {
      __typename?: 'MarketplaceProjectArchetype';
      id: string;
      kind: GQMarketplaceProjectArchetypeKind;
      unit: GQMarketplaceProjectArchetypeUnit;
      isUserVisible: boolean;
      name: string;
      descriptionMd: string;
      summaryMd: string;
      imageUrl: string | null;
      bannerImageUrl: string | null;
      certificateBlurb: string | null;
      certificateColor: string | null;
      additionalityRating: number | null;
      permanenceRating: string | null;
      projects: {
        __typename?: 'MarketplaceProjectConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectEdge';
          node: {
            __typename?: 'MarketplaceProject';
            id: string;
            name: string;
          } | null;
        } | null>;
      };
      offerings: {
        __typename?: 'MarketplaceProjectOfferingConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectOfferingEdge';
          node: {
            __typename: 'MarketplaceProjectOffering';
            id: string;
            vintage: number | null;
            createdAt: Date;
            availability: number;
            priceUsdCents: number;
            priceWithFeeUsdCents: number;
            noteMd: string | null;
            supplier: {
              __typename?: 'MarketplaceSupplier';
              id: string;
              name: string;
            };
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              deletedAt: Date | null;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
                mechanism: GQMarketplaceProjectArchetypeMechanism;
              };
            };
          } | null;
        } | null>;
      };
    };
  } | null;
};

export type GQCreateMarketplaceAllocationInstructionMutationVariables = Exact<{
  input: GQCreateMarketplaceAllocationInstructionInput;
}>;

export type GQCreateMarketplaceAllocationInstructionMutation = {
  __typename?: 'Mutation';
  createMarketplaceAllocationInstruction: {
    __typename?: 'CreateMarketplaceAllocationInstructionPayload';
    allocationInstruction: {
      __typename?: 'MarketplaceAllocationInstruction';
      id: string;
      createdAt: Date;
      deletedAt: Date | null;
      amountKwh: number;
      isZeroedOut: boolean;
      interval: YMInterval;
      locationCity: string | null;
      locationGrid: string | null;
      locationState: string | null;
      locationCountry: string | null;
      buildingName: string | null;
      vendorEntity: string | null;
      vendorSubentity: string | null;
      product: string | null;
      categoryId: string | null;
      ghgCategoryId: string;
      footprintKind: string | null;
      subcategoryId: string | null;
      results: {
        __typename?: 'MarketplaceAllocationInstructionResultConnection';
        edges: Array<{
          __typename?: 'MarketplaceAllocationInstructionResultEdge';
          node: {
            __typename?: 'MarketplaceAllocationInstructionResult';
            id: string;
            isZeroedOut: boolean;
            amountAllocatedKwh: number;
          } | null;
        } | null>;
      } | null;
    };
  } | null;
};

export type GQEditMarketplaceAllocationInstructionMutationVariables = Exact<{
  input: GQUpdateMarketplaceAllocationInstructionInput;
}>;

export type GQEditMarketplaceAllocationInstructionMutation = {
  __typename?: 'Mutation';
  updateMarketplaceAllocationInstruction: {
    __typename?: 'UpdateMarketplaceAllocationInstructionPayload';
    allocationInstruction: {
      __typename?: 'MarketplaceAllocationInstruction';
      id: string;
      createdAt: Date;
      deletedAt: Date | null;
      amountKwh: number;
      isZeroedOut: boolean;
      interval: YMInterval;
      locationCity: string | null;
      locationGrid: string | null;
      locationState: string | null;
      locationCountry: string | null;
      buildingName: string | null;
      vendorEntity: string | null;
      vendorSubentity: string | null;
      product: string | null;
      categoryId: string | null;
      ghgCategoryId: string;
      footprintKind: string | null;
      subcategoryId: string | null;
      results: {
        __typename?: 'MarketplaceAllocationInstructionResultConnection';
        edges: Array<{
          __typename?: 'MarketplaceAllocationInstructionResultEdge';
          node: {
            __typename?: 'MarketplaceAllocationInstructionResult';
            id: string;
            isZeroedOut: boolean;
            amountAllocatedKwh: number;
          } | null;
        } | null>;
      } | null;
    };
  } | null;
};

export type GQMarketplaceDocumentFieldsForTableCardFragment = {
  __typename?: 'MarketplaceDocument';
  id: string;
  name: string;
  createdAt: Date;
  kind: GQMarketplaceDocumentKind;
  uploader: {
    __typename?: 'WatershedEmployee';
    id: string;
    name: string;
  } | null;
};

export type GQMarketplacePurchaseFieldsForDocumentsTableCardFragment = {
  __typename?: 'MarketplacePurchase';
  id: string;
  org: { __typename?: 'Organization'; id: string };
  lineItems: {
    __typename?: 'MarketplacePurchaseLineItemConnection';
    edges: Array<{
      __typename?: 'MarketplacePurchaseLineItemEdge';
      node: {
        __typename?: 'MarketplacePurchaseLineItem';
        id: string;
        name: string;
      } | null;
    } | null>;
  };
};

export type GQMarketplacePurchaseLineItemFieldsForDocumentsTableCardFragment = {
  __typename?: 'MarketplacePurchaseLineItem';
  id: string;
  name: string;
  org: { __typename?: 'Organization'; id: string };
};

export type GQUploadMarketplaceDocumentsMutationVariables = Exact<{
  input: GQUploadMarketplaceDocumentsInput;
}>;

export type GQUploadMarketplaceDocumentsMutation = {
  __typename?: 'Mutation';
  uploadMarketplaceDocuments: {
    __typename?: 'UploadMarketplaceDocumentsPayload';
    documents: Array<{
      __typename?: 'MarketplaceDocument';
      id: string;
      name: string;
      createdAt: Date;
      kind: GQMarketplaceDocumentKind;
      uploader: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
    }>;
  } | null;
};

export type GQCreateMarketplaceProjectOfferingMutationVariables = Exact<{
  input: GQCreateMarketplaceProjectOfferingInput;
}>;

export type GQCreateMarketplaceProjectOfferingMutation = {
  __typename?: 'Mutation';
  createMarketplaceProjectOffering: {
    __typename?: 'CreateMarketplaceProjectOfferingPayload';
    marketplaceProjectOffering: {
      __typename: 'MarketplaceProjectOffering';
      id: string;
      vintage: number | null;
      createdAt: Date;
      availability: number;
      priceUsdCents: number;
      priceWithFeeUsdCents: number;
      noteMd: string | null;
      supplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
      };
      project: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
        deletedAt: Date | null;
        status: GQMarketplaceProjectStatus;
        archetype: {
          __typename?: 'MarketplaceProjectArchetype';
          id: string;
          name: string;
          unit: GQMarketplaceProjectArchetypeUnit;
          mechanism: GQMarketplaceProjectArchetypeMechanism;
        };
      };
    };
    marketplaceProject: {
      __typename?: 'MarketplaceProject';
      id: string;
      name: string;
      deletedAt: Date | null;
      status: GQMarketplaceProjectStatus;
      internalNotesMd: string | null;
      registryId: string | null;
      archetype: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
      };
      developer: {
        __typename?: 'MarketplaceDeveloper';
        id: string;
        name: string;
        imageUrl: string | null;
        url: string | null;
        descriptionMd: string | null;
        deletedAt: Date | null;
      } | null;
      supplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
      } | null;
      offerings: {
        __typename?: 'MarketplaceProjectOfferingConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectOfferingEdge';
          node: {
            __typename: 'MarketplaceProjectOffering';
            id: string;
            vintage: number | null;
            createdAt: Date;
            availability: number;
            priceUsdCents: number;
            priceWithFeeUsdCents: number;
            noteMd: string | null;
            supplier: {
              __typename?: 'MarketplaceSupplier';
              id: string;
              name: string;
            };
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              deletedAt: Date | null;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
                mechanism: GQMarketplaceProjectArchetypeMechanism;
              };
            };
          } | null;
        } | null>;
      };
    };
    marketplaceSupplier: {
      __typename?: 'MarketplaceSupplier';
      id: string;
      name: string;
      projects: {
        __typename?: 'MarketplaceProjectConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectEdge';
          node: {
            __typename?: 'MarketplaceProject';
            id: string;
            name: string;
            status: GQMarketplaceProjectStatus;
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
            };
          } | null;
        } | null>;
      };
      offerings: {
        __typename?: 'MarketplaceProjectOfferingConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectOfferingEdge';
          node: {
            __typename: 'MarketplaceProjectOffering';
            id: string;
            vintage: number | null;
            createdAt: Date;
            availability: number;
            priceUsdCents: number;
            priceWithFeeUsdCents: number;
            noteMd: string | null;
            supplier: {
              __typename?: 'MarketplaceSupplier';
              id: string;
              name: string;
            };
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              deletedAt: Date | null;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
                mechanism: GQMarketplaceProjectArchetypeMechanism;
              };
            };
          } | null;
        } | null>;
      };
      pointsOfContact: Array<{
        __typename: 'MarketplacePointOfContact';
        id: string;
        name: string;
        email: string;
      }>;
      watershedEmployees: Array<{
        __typename: 'WatershedEmployee';
        id: string;
        name: string;
        email: string;
      }>;
    };
    addedVintageOfferings: Array<{
      __typename: 'MarketplaceProjectOffering';
      id: string;
      vintage: number | null;
      createdAt: Date;
      availability: number;
      priceUsdCents: number;
      priceWithFeeUsdCents: number;
      noteMd: string | null;
      supplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
      };
      project: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
        deletedAt: Date | null;
        status: GQMarketplaceProjectStatus;
        archetype: {
          __typename?: 'MarketplaceProjectArchetype';
          id: string;
          name: string;
          unit: GQMarketplaceProjectArchetypeUnit;
          mechanism: GQMarketplaceProjectArchetypeMechanism;
        };
      };
    }> | null;
  } | null;
};

export type GQUpdateMarketplacePurchaseLineItemMutationVariables = Exact<{
  input: GQUpdateMarketplacePurchaseLineItemInput;
}>;

export type GQUpdateMarketplacePurchaseLineItemMutation = {
  __typename?: 'Mutation';
  updateMarketplacePurchaseLineItem: {
    __typename?: 'UpdateMarketplacePurchaseLineItemPayload';
    marketplacePurchaseLineItem: {
      __typename?: 'MarketplacePurchaseLineItem';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
      vintage: number | null;
      amount: number;
      amountUnit: GQMarketplaceProjectArchetypeUnit;
      mechanism: GQMarketplaceProjectArchetypeMechanism;
      coverageInterval: YMInterval;
      unitPriceUsdCents: number;
      unitFeeUsdCents: number;
      deliveryDate: Date | null;
      closeDate: Date | null;
      status: GQMarketplacePurchaseLineItemStatus;
      waitingOnParty: GQMarketplaceWaitingOnParty | null;
      noteMd: string;
      appliedToFootprint: boolean | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
      };
      purchase: {
        __typename?: 'MarketplacePurchase';
        id: string;
        name: string;
      };
      archetype: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
      } | null;
      project: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
        supplier: {
          __typename?: 'MarketplaceSupplier';
          id: string;
          name: string;
        } | null;
      } | null;
      offering: {
        __typename?: 'MarketplaceProjectOffering';
        id: string;
        vintage: number | null;
      } | null;
      documents: {
        __typename?: 'MarketplaceDocumentConnection';
        edges: Array<{
          __typename?: 'MarketplaceDocumentEdge';
          node: {
            __typename?: 'MarketplaceDocument';
            id: string;
            kind: GQMarketplaceDocumentKind;
          } | null;
        } | null>;
      };
      eacMetadata: {
        __typename?: 'MarketplacePurchaseLineItemEacMetadata';
        id: string;
        countryWithEmissions: string;
        countryWithEacs: string;
        numEacsRequested: number;
        mwh: number;
        marketTco2e: number;
      } | null;
    };
    marketplacePurchase: {
      __typename?: 'MarketplacePurchase';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      offerExpiration: Date | null;
      name: string;
      status: GQMarketplacePurchaseStatus;
      waitingOnParty: GQMarketplaceWaitingOnParty | null;
      customerStatus: GQCustomerStatus;
      noteMd: string;
      totalCostUsdCents: number;
      totalFeeUsdCents: number;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        productWaitlistTypes: Array<GQProductWaitlistType>;
      };
      portfolio: {
        __typename?: 'MarketplacePortfolio';
        id: string;
        name: string;
        targetAmount: number | null;
        notes: string;
        footprintTco2e: number | null;
        budgetUsdCents: number;
        interval: YMInterval | null;
        investInRd: boolean;
        targetFraction: number | null;
        offsetTargetAmount: number | null;
        offsetTargetFraction: number | null;
        cleanPowerTargetAmount: number | null;
        portfolioItems: {
          __typename?: 'MarketplacePortfolioItemConnection';
          edges: Array<{
            __typename?: 'MarketplacePortfolioItemEdge';
            node: {
              __typename?: 'MarketplacePortfolioItem';
              id: string;
              amount: number;
              priceUsdCents: number;
              fraction: number;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
              };
              portfolio: {
                __typename?: 'MarketplacePortfolio';
                id: string;
                interval: YMInterval | null;
              };
            } | null;
          }>;
        };
      } | null;
      editor:
        | { __typename: 'User'; id: string; name: string }
        | { __typename: 'WatershedEmployee'; id: string; name: string }
        | null;
      customerUser: { __typename?: 'User'; id: string; name: string } | null;
      history: {
        __typename?: 'MarketplacePurchaseHistoryConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseHistoryEdge';
          node: {
            __typename?: 'MarketplacePurchaseHistory';
            id: string;
            name: string;
            status: GQMarketplacePurchaseStatus;
            offerExpiration: Date | null;
            recordStartAt: Date;
            recordEndAt: Date | null;
            editor:
              | { __typename: 'User'; id: string; name: string }
              | { __typename: 'WatershedEmployee'; id: string; name: string }
              | null;
          } | null;
        } | null>;
      };
      lineItems: {
        __typename?: 'MarketplacePurchaseLineItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseLineItemEdge';
          node: {
            __typename?: 'MarketplacePurchaseLineItem';
            id: string;
            createdAt: Date;
            updatedAt: Date;
            cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
            vintage: number | null;
            amount: number;
            amountUnit: GQMarketplaceProjectArchetypeUnit;
            mechanism: GQMarketplaceProjectArchetypeMechanism;
            coverageInterval: YMInterval;
            unitPriceUsdCents: number;
            unitFeeUsdCents: number;
            deliveryDate: Date | null;
            closeDate: Date | null;
            status: GQMarketplacePurchaseLineItemStatus;
            waitingOnParty: GQMarketplaceWaitingOnParty | null;
            noteMd: string;
            appliedToFootprint: boolean | null;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
            };
            purchase: {
              __typename?: 'MarketplacePurchase';
              id: string;
              name: string;
            };
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
            } | null;
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              supplier: {
                __typename?: 'MarketplaceSupplier';
                id: string;
                name: string;
              } | null;
            } | null;
            offering: {
              __typename?: 'MarketplaceProjectOffering';
              id: string;
              vintage: number | null;
            } | null;
            documents: {
              __typename?: 'MarketplaceDocumentConnection';
              edges: Array<{
                __typename?: 'MarketplaceDocumentEdge';
                node: {
                  __typename?: 'MarketplaceDocument';
                  id: string;
                  kind: GQMarketplaceDocumentKind;
                } | null;
              } | null>;
            };
            eacMetadata: {
              __typename?: 'MarketplacePurchaseLineItemEacMetadata';
              id: string;
              countryWithEmissions: string;
              countryWithEacs: string;
              numEacsRequested: number;
              mwh: number;
              marketTco2e: number;
            } | null;
          } | null;
        } | null>;
      };
      eacMetadata: {
        __typename?: 'MarketplacePurchaseEacMetadata';
        id: string;
        updatedAt: Date;
        requestNotes: string | null;
        interval: YMInterval;
        ghgCategoryIds: Array<string> | null;
        marketTco2e: number;
      } | null;
    };
  } | null;
};

export type GQCreateMarketplacePurchaseLineItemMutationVariables = Exact<{
  input: GQCreateMarketplacePurchaseLineItemInput;
}>;

export type GQCreateMarketplacePurchaseLineItemMutation = {
  __typename?: 'Mutation';
  createMarketplacePurchaseLineItem: {
    __typename?: 'CreateMarketplacePurchaseLineItemPayload';
    marketplacePurchaseLineItem: {
      __typename?: 'MarketplacePurchaseLineItem';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
      vintage: number | null;
      amount: number;
      amountUnit: GQMarketplaceProjectArchetypeUnit;
      mechanism: GQMarketplaceProjectArchetypeMechanism;
      coverageInterval: YMInterval;
      unitPriceUsdCents: number;
      unitFeeUsdCents: number;
      deliveryDate: Date | null;
      closeDate: Date | null;
      status: GQMarketplacePurchaseLineItemStatus;
      waitingOnParty: GQMarketplaceWaitingOnParty | null;
      noteMd: string;
      appliedToFootprint: boolean | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
      };
      purchase: {
        __typename?: 'MarketplacePurchase';
        id: string;
        name: string;
      };
      archetype: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
      } | null;
      project: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
        supplier: {
          __typename?: 'MarketplaceSupplier';
          id: string;
          name: string;
        } | null;
      } | null;
      offering: {
        __typename?: 'MarketplaceProjectOffering';
        id: string;
        vintage: number | null;
      } | null;
      documents: {
        __typename?: 'MarketplaceDocumentConnection';
        edges: Array<{
          __typename?: 'MarketplaceDocumentEdge';
          node: {
            __typename?: 'MarketplaceDocument';
            id: string;
            kind: GQMarketplaceDocumentKind;
          } | null;
        } | null>;
      };
      eacMetadata: {
        __typename?: 'MarketplacePurchaseLineItemEacMetadata';
        id: string;
        countryWithEmissions: string;
        countryWithEacs: string;
        numEacsRequested: number;
        mwh: number;
        marketTco2e: number;
      } | null;
    };
    marketplacePurchase: {
      __typename?: 'MarketplacePurchase';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      offerExpiration: Date | null;
      name: string;
      status: GQMarketplacePurchaseStatus;
      waitingOnParty: GQMarketplaceWaitingOnParty | null;
      customerStatus: GQCustomerStatus;
      noteMd: string;
      totalCostUsdCents: number;
      totalFeeUsdCents: number;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        productWaitlistTypes: Array<GQProductWaitlistType>;
      };
      portfolio: {
        __typename?: 'MarketplacePortfolio';
        id: string;
        name: string;
        targetAmount: number | null;
        notes: string;
        footprintTco2e: number | null;
        budgetUsdCents: number;
        interval: YMInterval | null;
        investInRd: boolean;
        targetFraction: number | null;
        offsetTargetAmount: number | null;
        offsetTargetFraction: number | null;
        cleanPowerTargetAmount: number | null;
        portfolioItems: {
          __typename?: 'MarketplacePortfolioItemConnection';
          edges: Array<{
            __typename?: 'MarketplacePortfolioItemEdge';
            node: {
              __typename?: 'MarketplacePortfolioItem';
              id: string;
              amount: number;
              priceUsdCents: number;
              fraction: number;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
              };
              portfolio: {
                __typename?: 'MarketplacePortfolio';
                id: string;
                interval: YMInterval | null;
              };
            } | null;
          }>;
        };
      } | null;
      editor:
        | { __typename: 'User'; id: string; name: string }
        | { __typename: 'WatershedEmployee'; id: string; name: string }
        | null;
      customerUser: { __typename?: 'User'; id: string; name: string } | null;
      history: {
        __typename?: 'MarketplacePurchaseHistoryConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseHistoryEdge';
          node: {
            __typename?: 'MarketplacePurchaseHistory';
            id: string;
            name: string;
            status: GQMarketplacePurchaseStatus;
            offerExpiration: Date | null;
            recordStartAt: Date;
            recordEndAt: Date | null;
            editor:
              | { __typename: 'User'; id: string; name: string }
              | { __typename: 'WatershedEmployee'; id: string; name: string }
              | null;
          } | null;
        } | null>;
      };
      lineItems: {
        __typename?: 'MarketplacePurchaseLineItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseLineItemEdge';
          node: {
            __typename?: 'MarketplacePurchaseLineItem';
            id: string;
            createdAt: Date;
            updatedAt: Date;
            cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
            vintage: number | null;
            amount: number;
            amountUnit: GQMarketplaceProjectArchetypeUnit;
            mechanism: GQMarketplaceProjectArchetypeMechanism;
            coverageInterval: YMInterval;
            unitPriceUsdCents: number;
            unitFeeUsdCents: number;
            deliveryDate: Date | null;
            closeDate: Date | null;
            status: GQMarketplacePurchaseLineItemStatus;
            waitingOnParty: GQMarketplaceWaitingOnParty | null;
            noteMd: string;
            appliedToFootprint: boolean | null;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
            };
            purchase: {
              __typename?: 'MarketplacePurchase';
              id: string;
              name: string;
            };
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
            } | null;
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              supplier: {
                __typename?: 'MarketplaceSupplier';
                id: string;
                name: string;
              } | null;
            } | null;
            offering: {
              __typename?: 'MarketplaceProjectOffering';
              id: string;
              vintage: number | null;
            } | null;
            documents: {
              __typename?: 'MarketplaceDocumentConnection';
              edges: Array<{
                __typename?: 'MarketplaceDocumentEdge';
                node: {
                  __typename?: 'MarketplaceDocument';
                  id: string;
                  kind: GQMarketplaceDocumentKind;
                } | null;
              } | null>;
            };
            eacMetadata: {
              __typename?: 'MarketplacePurchaseLineItemEacMetadata';
              id: string;
              countryWithEmissions: string;
              countryWithEacs: string;
              numEacsRequested: number;
              mwh: number;
              marketTco2e: number;
            } | null;
          } | null;
        } | null>;
      };
      eacMetadata: {
        __typename?: 'MarketplacePurchaseEacMetadata';
        id: string;
        updatedAt: Date;
        requestNotes: string | null;
        interval: YMInterval;
        ghgCategoryIds: Array<string> | null;
        marketTco2e: number;
      } | null;
    };
  } | null;
};

export type GQDeleteMarketplacePurchaseLineItemMutationVariables = Exact<{
  input: GQDeleteMarketplacePurchaseLineItemInput;
}>;

export type GQDeleteMarketplacePurchaseLineItemMutation = {
  __typename?: 'Mutation';
  deleteMarketplacePurchaseLineItem: {
    __typename?: 'DeleteMarketplacePurchaseLineItemPayload';
    marketplacePurchase: {
      __typename?: 'MarketplacePurchase';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      offerExpiration: Date | null;
      name: string;
      status: GQMarketplacePurchaseStatus;
      waitingOnParty: GQMarketplaceWaitingOnParty | null;
      customerStatus: GQCustomerStatus;
      noteMd: string;
      totalCostUsdCents: number;
      totalFeeUsdCents: number;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        productWaitlistTypes: Array<GQProductWaitlistType>;
      };
      portfolio: {
        __typename?: 'MarketplacePortfolio';
        id: string;
        name: string;
        targetAmount: number | null;
        notes: string;
        footprintTco2e: number | null;
        budgetUsdCents: number;
        interval: YMInterval | null;
        investInRd: boolean;
        targetFraction: number | null;
        offsetTargetAmount: number | null;
        offsetTargetFraction: number | null;
        cleanPowerTargetAmount: number | null;
        portfolioItems: {
          __typename?: 'MarketplacePortfolioItemConnection';
          edges: Array<{
            __typename?: 'MarketplacePortfolioItemEdge';
            node: {
              __typename?: 'MarketplacePortfolioItem';
              id: string;
              amount: number;
              priceUsdCents: number;
              fraction: number;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
              };
              portfolio: {
                __typename?: 'MarketplacePortfolio';
                id: string;
                interval: YMInterval | null;
              };
            } | null;
          }>;
        };
      } | null;
      editor:
        | { __typename: 'User'; id: string; name: string }
        | { __typename: 'WatershedEmployee'; id: string; name: string }
        | null;
      customerUser: { __typename?: 'User'; id: string; name: string } | null;
      history: {
        __typename?: 'MarketplacePurchaseHistoryConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseHistoryEdge';
          node: {
            __typename?: 'MarketplacePurchaseHistory';
            id: string;
            name: string;
            status: GQMarketplacePurchaseStatus;
            offerExpiration: Date | null;
            recordStartAt: Date;
            recordEndAt: Date | null;
            editor:
              | { __typename: 'User'; id: string; name: string }
              | { __typename: 'WatershedEmployee'; id: string; name: string }
              | null;
          } | null;
        } | null>;
      };
      lineItems: {
        __typename?: 'MarketplacePurchaseLineItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseLineItemEdge';
          node: {
            __typename?: 'MarketplacePurchaseLineItem';
            id: string;
            createdAt: Date;
            updatedAt: Date;
            cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
            vintage: number | null;
            amount: number;
            amountUnit: GQMarketplaceProjectArchetypeUnit;
            mechanism: GQMarketplaceProjectArchetypeMechanism;
            coverageInterval: YMInterval;
            unitPriceUsdCents: number;
            unitFeeUsdCents: number;
            deliveryDate: Date | null;
            closeDate: Date | null;
            status: GQMarketplacePurchaseLineItemStatus;
            waitingOnParty: GQMarketplaceWaitingOnParty | null;
            noteMd: string;
            appliedToFootprint: boolean | null;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
            };
            purchase: {
              __typename?: 'MarketplacePurchase';
              id: string;
              name: string;
            };
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
            } | null;
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              supplier: {
                __typename?: 'MarketplaceSupplier';
                id: string;
                name: string;
              } | null;
            } | null;
            offering: {
              __typename?: 'MarketplaceProjectOffering';
              id: string;
              vintage: number | null;
            } | null;
            documents: {
              __typename?: 'MarketplaceDocumentConnection';
              edges: Array<{
                __typename?: 'MarketplaceDocumentEdge';
                node: {
                  __typename?: 'MarketplaceDocument';
                  id: string;
                  kind: GQMarketplaceDocumentKind;
                } | null;
              } | null>;
            };
            eacMetadata: {
              __typename?: 'MarketplacePurchaseLineItemEacMetadata';
              id: string;
              countryWithEmissions: string;
              countryWithEacs: string;
              numEacsRequested: number;
              mwh: number;
              marketTco2e: number;
            } | null;
          } | null;
        } | null>;
      };
      eacMetadata: {
        __typename?: 'MarketplacePurchaseEacMetadata';
        id: string;
        updatedAt: Date;
        requestNotes: string | null;
        interval: YMInterval;
        ghgCategoryIds: Array<string> | null;
        marketTco2e: number;
      } | null;
    };
  } | null;
};

export type GQMarketplaceProjectArchetypesForLineItemDialogFragment = {
  __typename?: 'MarketplaceProjectArchetype';
  id: string;
  name: string;
  mechanism: GQMarketplaceProjectArchetypeMechanism;
  unit: GQMarketplaceProjectArchetypeUnit;
};

export type GQMarketplacePurchaseLineItemFormDataQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQMarketplacePurchaseLineItemFormDataQuery = {
  __typename?: 'Query';
  featureFlagEnabledForOrg: boolean;
  marketplaceProjectOfferings: {
    __typename?: 'MarketplaceProjectOfferingConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectOfferingEdge';
      node: {
        __typename: 'MarketplaceProjectOffering';
        id: string;
        vintage: number | null;
        createdAt: Date;
        availability: number;
        priceUsdCents: number;
        priceWithFeeUsdCents: number;
        noteMd: string | null;
        supplier: {
          __typename?: 'MarketplaceSupplier';
          id: string;
          name: string;
        };
        project: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
          deletedAt: Date | null;
          status: GQMarketplaceProjectStatus;
          archetype: {
            __typename?: 'MarketplaceProjectArchetype';
            id: string;
            name: string;
            unit: GQMarketplaceProjectArchetypeUnit;
            mechanism: GQMarketplaceProjectArchetypeMechanism;
          };
        };
      } | null;
    } | null>;
  };
  marketplaceProjectArchetypes: {
    __typename?: 'MarketplaceProjectArchetypeConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectArchetypeEdge';
      node: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
        mechanism: GQMarketplaceProjectArchetypeMechanism;
        unit: GQMarketplaceProjectArchetypeUnit;
      } | null;
    } | null>;
  };
};

export type GQMarketplacePortfolioItemForLineItemDialogFragment = {
  __typename?: 'MarketplacePortfolioItem';
  id: string;
  amount: number;
  priceUsdCents: number;
  archetype: { __typename?: 'MarketplaceProjectArchetype'; id: string };
  portfolio: {
    __typename?: 'MarketplacePortfolio';
    id: string;
    interval: YMInterval | null;
  };
};

export type GQBulkUpdateMarketplacePurchaseLineItemsMutationVariables = Exact<{
  input: GQBulkUpdateMarketplacePurchaseLineItemsInput;
}>;

export type GQBulkUpdateMarketplacePurchaseLineItemsMutation = {
  __typename?: 'Mutation';
  bulkUpdateMarketplacePurchaseLineItems: {
    __typename?: 'BulkUpdateMarketplacePurchaseLineItemsPayload';
    marketplacePurchase: {
      __typename?: 'MarketplacePurchase';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      offerExpiration: Date | null;
      name: string;
      status: GQMarketplacePurchaseStatus;
      waitingOnParty: GQMarketplaceWaitingOnParty | null;
      customerStatus: GQCustomerStatus;
      noteMd: string;
      totalCostUsdCents: number;
      totalFeeUsdCents: number;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        productWaitlistTypes: Array<GQProductWaitlistType>;
      };
      portfolio: {
        __typename?: 'MarketplacePortfolio';
        id: string;
        name: string;
        targetAmount: number | null;
        notes: string;
        footprintTco2e: number | null;
        budgetUsdCents: number;
        interval: YMInterval | null;
        investInRd: boolean;
        targetFraction: number | null;
        offsetTargetAmount: number | null;
        offsetTargetFraction: number | null;
        cleanPowerTargetAmount: number | null;
        portfolioItems: {
          __typename?: 'MarketplacePortfolioItemConnection';
          edges: Array<{
            __typename?: 'MarketplacePortfolioItemEdge';
            node: {
              __typename?: 'MarketplacePortfolioItem';
              id: string;
              amount: number;
              priceUsdCents: number;
              fraction: number;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
              };
              portfolio: {
                __typename?: 'MarketplacePortfolio';
                id: string;
                interval: YMInterval | null;
              };
            } | null;
          }>;
        };
      } | null;
      editor:
        | { __typename: 'User'; id: string; name: string }
        | { __typename: 'WatershedEmployee'; id: string; name: string }
        | null;
      customerUser: { __typename?: 'User'; id: string; name: string } | null;
      history: {
        __typename?: 'MarketplacePurchaseHistoryConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseHistoryEdge';
          node: {
            __typename?: 'MarketplacePurchaseHistory';
            id: string;
            name: string;
            status: GQMarketplacePurchaseStatus;
            offerExpiration: Date | null;
            recordStartAt: Date;
            recordEndAt: Date | null;
            editor:
              | { __typename: 'User'; id: string; name: string }
              | { __typename: 'WatershedEmployee'; id: string; name: string }
              | null;
          } | null;
        } | null>;
      };
      lineItems: {
        __typename?: 'MarketplacePurchaseLineItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseLineItemEdge';
          node: {
            __typename?: 'MarketplacePurchaseLineItem';
            id: string;
            createdAt: Date;
            updatedAt: Date;
            cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
            vintage: number | null;
            amount: number;
            amountUnit: GQMarketplaceProjectArchetypeUnit;
            mechanism: GQMarketplaceProjectArchetypeMechanism;
            coverageInterval: YMInterval;
            unitPriceUsdCents: number;
            unitFeeUsdCents: number;
            deliveryDate: Date | null;
            closeDate: Date | null;
            status: GQMarketplacePurchaseLineItemStatus;
            waitingOnParty: GQMarketplaceWaitingOnParty | null;
            noteMd: string;
            appliedToFootprint: boolean | null;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
            };
            purchase: {
              __typename?: 'MarketplacePurchase';
              id: string;
              name: string;
            };
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
            } | null;
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              supplier: {
                __typename?: 'MarketplaceSupplier';
                id: string;
                name: string;
              } | null;
            } | null;
            offering: {
              __typename?: 'MarketplaceProjectOffering';
              id: string;
              vintage: number | null;
            } | null;
            documents: {
              __typename?: 'MarketplaceDocumentConnection';
              edges: Array<{
                __typename?: 'MarketplaceDocumentEdge';
                node: {
                  __typename?: 'MarketplaceDocument';
                  id: string;
                  kind: GQMarketplaceDocumentKind;
                } | null;
              } | null>;
            };
            eacMetadata: {
              __typename?: 'MarketplacePurchaseLineItemEacMetadata';
              id: string;
              countryWithEmissions: string;
              countryWithEacs: string;
              numEacsRequested: number;
              mwh: number;
              marketTco2e: number;
            } | null;
          } | null;
        } | null>;
      };
      eacMetadata: {
        __typename?: 'MarketplacePurchaseEacMetadata';
        id: string;
        updatedAt: Date;
        requestNotes: string | null;
        interval: YMInterval;
        ghgCategoryIds: Array<string> | null;
        marketTco2e: number;
      } | null;
    };
    createdLineItems: Array<{
      __typename?: 'MarketplacePurchaseLineItem';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
      vintage: number | null;
      amount: number;
      amountUnit: GQMarketplaceProjectArchetypeUnit;
      mechanism: GQMarketplaceProjectArchetypeMechanism;
      coverageInterval: YMInterval;
      unitPriceUsdCents: number;
      unitFeeUsdCents: number;
      deliveryDate: Date | null;
      closeDate: Date | null;
      status: GQMarketplacePurchaseLineItemStatus;
      waitingOnParty: GQMarketplaceWaitingOnParty | null;
      noteMd: string;
      appliedToFootprint: boolean | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
      };
      purchase: {
        __typename?: 'MarketplacePurchase';
        id: string;
        name: string;
      };
      archetype: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
      } | null;
      project: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
        supplier: {
          __typename?: 'MarketplaceSupplier';
          id: string;
          name: string;
        } | null;
      } | null;
      offering: {
        __typename?: 'MarketplaceProjectOffering';
        id: string;
        vintage: number | null;
      } | null;
      documents: {
        __typename?: 'MarketplaceDocumentConnection';
        edges: Array<{
          __typename?: 'MarketplaceDocumentEdge';
          node: {
            __typename?: 'MarketplaceDocument';
            id: string;
            kind: GQMarketplaceDocumentKind;
          } | null;
        } | null>;
      };
      eacMetadata: {
        __typename?: 'MarketplacePurchaseLineItemEacMetadata';
        id: string;
        countryWithEmissions: string;
        countryWithEacs: string;
        numEacsRequested: number;
        mwh: number;
        marketTco2e: number;
      } | null;
    }> | null;
    deletedLineItems: Array<{
      __typename?: 'MarketplacePurchaseLineItem';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
      vintage: number | null;
      amount: number;
      amountUnit: GQMarketplaceProjectArchetypeUnit;
      mechanism: GQMarketplaceProjectArchetypeMechanism;
      coverageInterval: YMInterval;
      unitPriceUsdCents: number;
      unitFeeUsdCents: number;
      deliveryDate: Date | null;
      closeDate: Date | null;
      status: GQMarketplacePurchaseLineItemStatus;
      waitingOnParty: GQMarketplaceWaitingOnParty | null;
      noteMd: string;
      appliedToFootprint: boolean | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
      };
      purchase: {
        __typename?: 'MarketplacePurchase';
        id: string;
        name: string;
      };
      archetype: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
      } | null;
      project: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
        supplier: {
          __typename?: 'MarketplaceSupplier';
          id: string;
          name: string;
        } | null;
      } | null;
      offering: {
        __typename?: 'MarketplaceProjectOffering';
        id: string;
        vintage: number | null;
      } | null;
      documents: {
        __typename?: 'MarketplaceDocumentConnection';
        edges: Array<{
          __typename?: 'MarketplaceDocumentEdge';
          node: {
            __typename?: 'MarketplaceDocument';
            id: string;
            kind: GQMarketplaceDocumentKind;
          } | null;
        } | null>;
      };
      eacMetadata: {
        __typename?: 'MarketplacePurchaseLineItemEacMetadata';
        id: string;
        countryWithEmissions: string;
        countryWithEacs: string;
        numEacsRequested: number;
        mwh: number;
        marketTco2e: number;
      } | null;
    }> | null;
    updatedLineItems: Array<{
      __typename?: 'MarketplacePurchaseLineItem';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
      vintage: number | null;
      amount: number;
      amountUnit: GQMarketplaceProjectArchetypeUnit;
      mechanism: GQMarketplaceProjectArchetypeMechanism;
      coverageInterval: YMInterval;
      unitPriceUsdCents: number;
      unitFeeUsdCents: number;
      deliveryDate: Date | null;
      closeDate: Date | null;
      status: GQMarketplacePurchaseLineItemStatus;
      waitingOnParty: GQMarketplaceWaitingOnParty | null;
      noteMd: string;
      appliedToFootprint: boolean | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
      };
      purchase: {
        __typename?: 'MarketplacePurchase';
        id: string;
        name: string;
      };
      archetype: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
      } | null;
      project: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
        supplier: {
          __typename?: 'MarketplaceSupplier';
          id: string;
          name: string;
        } | null;
      } | null;
      offering: {
        __typename?: 'MarketplaceProjectOffering';
        id: string;
        vintage: number | null;
      } | null;
      documents: {
        __typename?: 'MarketplaceDocumentConnection';
        edges: Array<{
          __typename?: 'MarketplaceDocumentEdge';
          node: {
            __typename?: 'MarketplaceDocument';
            id: string;
            kind: GQMarketplaceDocumentKind;
          } | null;
        } | null>;
      };
      eacMetadata: {
        __typename?: 'MarketplacePurchaseLineItemEacMetadata';
        id: string;
        countryWithEmissions: string;
        countryWithEacs: string;
        numEacsRequested: number;
        mwh: number;
        marketTco2e: number;
      } | null;
    }> | null;
  } | null;
};

export type GQCreateMarketplaceSupplierMutationVariables = Exact<{
  input: GQCreateMarketplaceSupplierInput;
}>;

export type GQCreateMarketplaceSupplierMutation = {
  __typename?: 'Mutation';
  createMarketplaceSupplier: {
    __typename?: 'CreateMarketplaceSupplierPayload';
    marketplaceSupplier: {
      __typename?: 'MarketplaceSupplier';
      id: string;
      name: string;
      projects: {
        __typename?: 'MarketplaceProjectConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectEdge';
          node: {
            __typename?: 'MarketplaceProject';
            id: string;
            name: string;
            status: GQMarketplaceProjectStatus;
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
            };
          } | null;
        } | null>;
      };
      offerings: {
        __typename?: 'MarketplaceProjectOfferingConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectOfferingEdge';
          node: {
            __typename: 'MarketplaceProjectOffering';
            id: string;
            vintage: number | null;
            createdAt: Date;
            availability: number;
            priceUsdCents: number;
            priceWithFeeUsdCents: number;
            noteMd: string | null;
            supplier: {
              __typename?: 'MarketplaceSupplier';
              id: string;
              name: string;
            };
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              deletedAt: Date | null;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
                mechanism: GQMarketplaceProjectArchetypeMechanism;
              };
            };
          } | null;
        } | null>;
      };
      pointsOfContact: Array<{
        __typename: 'MarketplacePointOfContact';
        id: string;
        name: string;
        email: string;
      }>;
      watershedEmployees: Array<{
        __typename: 'WatershedEmployee';
        id: string;
        name: string;
        email: string;
      }>;
    };
  } | null;
};

export type GQUpdateMarketplaceSupplierMutationVariables = Exact<{
  input: GQUpdateMarketplaceSupplierInput;
}>;

export type GQUpdateMarketplaceSupplierMutation = {
  __typename?: 'Mutation';
  updateMarketplaceSupplier: {
    __typename?: 'UpdateMarketplaceSupplierPayload';
    marketplaceSupplier: {
      __typename?: 'MarketplaceSupplier';
      id: string;
      name: string;
      projects: {
        __typename?: 'MarketplaceProjectConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectEdge';
          node: {
            __typename?: 'MarketplaceProject';
            id: string;
            name: string;
            status: GQMarketplaceProjectStatus;
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
            };
          } | null;
        } | null>;
      };
      offerings: {
        __typename?: 'MarketplaceProjectOfferingConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectOfferingEdge';
          node: {
            __typename: 'MarketplaceProjectOffering';
            id: string;
            vintage: number | null;
            createdAt: Date;
            availability: number;
            priceUsdCents: number;
            priceWithFeeUsdCents: number;
            noteMd: string | null;
            supplier: {
              __typename?: 'MarketplaceSupplier';
              id: string;
              name: string;
            };
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              deletedAt: Date | null;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
                mechanism: GQMarketplaceProjectArchetypeMechanism;
              };
            };
          } | null;
        } | null>;
      };
      pointsOfContact: Array<{
        __typename: 'MarketplacePointOfContact';
        id: string;
        name: string;
        email: string;
      }>;
      watershedEmployees: Array<{
        __typename: 'WatershedEmployee';
        id: string;
        name: string;
        email: string;
      }>;
    };
  } | null;
};

export type GQCompleteMeasurementProjectMutationVariables = Exact<{
  input: GQCompleteMeasurementProjectInput;
}>;

export type GQCompleteMeasurementProjectMutation = {
  __typename?: 'Mutation';
  completeMeasurementProject: {
    __typename?: 'CompleteMeasurementProjectPayload';
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      active: boolean;
    };
  } | null;
};

export type GQUncompleteMeasurementProjectMutationVariables = Exact<{
  input: GQUncompleteMeasurementProjectInput;
}>;

export type GQUncompleteMeasurementProjectMutation = {
  __typename?: 'Mutation';
  uncompleteMeasurementProject: {
    __typename?: 'UncompleteMeasurementProjectPayload';
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      active: boolean;
    };
  } | null;
};

export type GQUpdateUserUploadTaskMutationVariables = Exact<{
  input: GQUpdateUserUploadTaskInput;
}>;

export type GQUpdateUserUploadTaskMutation = {
  __typename?: 'Mutation';
  updateUserUploadTask: {
    __typename?: 'UpdateUserUploadTaskPayload';
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
      datasource: { __typename?: 'Datasource'; id: string };
    };
  } | null;
};

export type GQDeleteUserUploadTaskMutationVariables = Exact<{
  input: GQDeleteUserUploadTaskInput;
}>;

export type GQDeleteUserUploadTaskMutation = {
  __typename?: 'Mutation';
  deleteUserUploadTask: {
    __typename?: 'DeleteUserUploadTaskPayload';
    measurementProject: {
      __typename: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      status: GQMeasurementProjectStatus;
      completedAt: Date | null;
      coverageStartDate: Date;
      coverageEndDate: Date;
      coverageInterval: YMInterval;
      deadline: Date | null;
      supportedValueMappingRules: Array<string>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: { __typename?: 'Dataset'; id: string; name: string } | null;
        assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
            assignee: { __typename?: 'User'; id: string } | null;
          } | null;
        }>;
      };
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            datasource: { __typename?: 'Datasource'; id: string; name: string };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: { __typename?: 'UserUpload'; id: string } | null;
              } | null>;
            } | null;
            assignee: { __typename?: 'User'; id: string; name: string } | null;
          } | null;
        } | null>;
      };
      measurementProjectDatasets: Array<{
        __typename?: 'MeasurementProjectDataset';
        id: string;
        isCompleted: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          explainer: string | null;
          customUsedFor: string | null;
          customPotentialOverlaps: string | null;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            name: string;
            kind: GQCanonicalDatasetKind;
          } | null;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            customUsedFor: string | null;
            customPotentialOverlaps: string | null;
            customerTargetSchemaIds: Array<string> | null;
          }>;
        };
      }>;
    };
  };
};

export type GQMarkMeasurementProjectDatasetCompletedMutationVariables = Exact<{
  input: GQMarkMeasurementProjectDatasetCompletedInput;
}>;

export type GQMarkMeasurementProjectDatasetCompletedMutation = {
  __typename?: 'Mutation';
  markMeasurementProjectDatasetCompleted: {
    __typename?: 'MarkMeasurementProjectDatasetCompletedPayload';
    measurementProjectDataset: {
      __typename?: 'MeasurementProjectDataset';
      id: string;
      isCompleted: boolean;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        explainer: string | null;
        customUsedFor: string | null;
        customPotentialOverlaps: string | null;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          name: string;
          kind: GQCanonicalDatasetKind;
        } | null;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          customUsedFor: string | null;
          customPotentialOverlaps: string | null;
          customerTargetSchemaIds: Array<string> | null;
        }>;
      };
    };
    measurementProject: {
      __typename: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      status: GQMeasurementProjectStatus;
      completedAt: Date | null;
      coverageStartDate: Date;
      coverageEndDate: Date;
      coverageInterval: YMInterval;
      deadline: Date | null;
      supportedValueMappingRules: Array<string>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: { __typename?: 'Dataset'; id: string; name: string } | null;
        assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
            assignee: { __typename?: 'User'; id: string } | null;
          } | null;
        }>;
      };
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            datasource: { __typename?: 'Datasource'; id: string; name: string };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: { __typename?: 'UserUpload'; id: string } | null;
              } | null>;
            } | null;
            assignee: { __typename?: 'User'; id: string; name: string } | null;
          } | null;
        } | null>;
      };
      measurementProjectDatasets: Array<{
        __typename?: 'MeasurementProjectDataset';
        id: string;
        isCompleted: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          explainer: string | null;
          customUsedFor: string | null;
          customPotentialOverlaps: string | null;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            name: string;
            kind: GQCanonicalDatasetKind;
          } | null;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            customUsedFor: string | null;
            customPotentialOverlaps: string | null;
            customerTargetSchemaIds: Array<string> | null;
          }>;
        };
      }>;
    };
  };
};

export type GQCreateFacilitiesSetupTaskMutationVariables = Exact<{
  input: GQCreateFacilitiesSetupTaskInput;
}>;

export type GQCreateFacilitiesSetupTaskMutation = {
  __typename?: 'Mutation';
  createFacilitiesSetupTask: {
    __typename?: 'CreateFacilitiesSetupTaskPayload';
    measurementProject: {
      __typename: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      status: GQMeasurementProjectStatus;
      completedAt: Date | null;
      coverageStartDate: Date;
      coverageEndDate: Date;
      coverageInterval: YMInterval;
      deadline: Date | null;
      supportedValueMappingRules: Array<string>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: { __typename?: 'Dataset'; id: string; name: string } | null;
        assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
            assignee: { __typename?: 'User'; id: string } | null;
          } | null;
        }>;
      };
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            datasource: { __typename?: 'Datasource'; id: string; name: string };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: { __typename?: 'UserUpload'; id: string } | null;
              } | null>;
            } | null;
            assignee: { __typename?: 'User'; id: string; name: string } | null;
          } | null;
        } | null>;
      };
      measurementProjectDatasets: Array<{
        __typename?: 'MeasurementProjectDataset';
        id: string;
        isCompleted: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          explainer: string | null;
          customUsedFor: string | null;
          customPotentialOverlaps: string | null;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            name: string;
            kind: GQCanonicalDatasetKind;
          } | null;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            customUsedFor: string | null;
            customPotentialOverlaps: string | null;
            customerTargetSchemaIds: Array<string> | null;
          }>;
        };
      }>;
    };
  };
};

export type GQDeleteFacilitiesSetupTaskMutationVariables = Exact<{
  input: GQDeleteFacilitiesSetupTaskInput;
}>;

export type GQDeleteFacilitiesSetupTaskMutation = {
  __typename?: 'Mutation';
  deleteFacilitiesSetupTask: {
    __typename?: 'DeleteFacilitiesSetupTaskPayload';
    measurementProject: {
      __typename: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      status: GQMeasurementProjectStatus;
      completedAt: Date | null;
      coverageStartDate: Date;
      coverageEndDate: Date;
      coverageInterval: YMInterval;
      deadline: Date | null;
      supportedValueMappingRules: Array<string>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: { __typename?: 'Dataset'; id: string; name: string } | null;
        assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
            assignee: { __typename?: 'User'; id: string } | null;
          } | null;
        }>;
      };
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            datasource: { __typename?: 'Datasource'; id: string; name: string };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: { __typename?: 'UserUpload'; id: string } | null;
              } | null>;
            } | null;
            assignee: { __typename?: 'User'; id: string; name: string } | null;
          } | null;
        } | null>;
      };
      measurementProjectDatasets: Array<{
        __typename?: 'MeasurementProjectDataset';
        id: string;
        isCompleted: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          explainer: string | null;
          customUsedFor: string | null;
          customPotentialOverlaps: string | null;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            name: string;
            kind: GQCanonicalDatasetKind;
          } | null;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            customUsedFor: string | null;
            customPotentialOverlaps: string | null;
            customerTargetSchemaIds: Array<string> | null;
          }>;
        };
      }>;
    };
  };
};

export type GQDeleteMeasurementProjectMutationVariables = Exact<{
  input: GQDeleteMeasurementProjectInput;
}>;

export type GQDeleteMeasurementProjectMutation = {
  __typename?: 'Mutation';
  deleteMeasurementProject: {
    __typename?: 'OrganizationPayload';
    org: {
      __typename?: 'Organization';
      id: string;
      measurementProjects: Array<{
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        active: boolean;
        status: GQMeasurementProjectStatus;
        completedAt: Date | null;
        coverageStartDate: Date;
        coverageEndDate: Date;
        coverageInterval: YMInterval;
        deadline: Date | null;
        supportedValueMappingRules: Array<string>;
        measurementTimeline: {
          __typename?: 'MeasurementTimeline';
          id: string;
          kickoff: Date;
          deadline: Date;
        } | null;
        measurementTasks: Array<{
          __typename?: 'MeasurementParentTask';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          subTasks: Array<
            | {
                __typename?: 'MeasurementParentTask';
                id: string;
                title: string;
                link: string | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
            | {
                __typename?: 'MeasurementTaskFlat';
                id: string;
                title: string;
                link: string | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
          >;
          dataset: { __typename?: 'Dataset'; id: string; name: string } | null;
          assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }>;
        measurementVerificationItems: {
          __typename?: 'MeasurementVerificationItemConnection';
          edges: Array<{
            __typename?: 'MeasurementVerificationItemEdge';
            node: {
              __typename?: 'MeasurementVerificationItem';
              id: string;
              status: GQMeasurementVerificationItemStatus;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              } | null;
              assignee: { __typename?: 'User'; id: string } | null;
            } | null;
          }>;
        };
        userUploadTasks: {
          __typename?: 'UserUploadTaskConnection';
          edges: Array<{
            __typename?: 'UserUploadTaskEdge';
            node: {
              __typename: 'UserUploadTask';
              id: string;
              state: GQUserUploadTaskState;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
              };
              userUploads: {
                __typename?: 'UserUploadConnection';
                edges: Array<{
                  __typename?: 'UserUploadEdge';
                  node: { __typename?: 'UserUpload'; id: string } | null;
                } | null>;
              } | null;
              assignee: {
                __typename?: 'User';
                id: string;
                name: string;
              } | null;
            } | null;
          } | null>;
        };
        measurementProjectDatasets: Array<{
          __typename?: 'MeasurementProjectDataset';
          id: string;
          isCompleted: boolean;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            explainer: string | null;
            customUsedFor: string | null;
            customPotentialOverlaps: string | null;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              name: string;
              kind: GQCanonicalDatasetKind;
            } | null;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              customUsedFor: string | null;
              customPotentialOverlaps: string | null;
              customerTargetSchemaIds: Array<string> | null;
            }>;
          };
        }>;
      }>;
    };
  } | null;
};

export type GQMeasurementProjectsForMeasurementProjectSelectorFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  coverageInterval: YMInterval;
  active: boolean;
};

export type GQCreateMeasurementVerificationItemMutationVariables = Exact<{
  input: GQCreateMeasurementVerificationItemInput;
}>;

export type GQCreateMeasurementVerificationItemMutation = {
  __typename?: 'Mutation';
  createMeasurementVerificationItem: {
    __typename?: 'CreateMeasurementVerificationItemPayload';
    measurementVerificationItem: {
      __typename?: 'MeasurementVerificationItem';
      id: string;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
        active: boolean;
        completedAt: Date | null;
        measurementVerificationItems: {
          __typename?: 'MeasurementVerificationItemConnection';
          edges: Array<{
            __typename?: 'MeasurementVerificationItemEdge';
            node: {
              __typename?: 'MeasurementVerificationItem';
              id: string;
              name: string;
              description: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
              } | null;
              assignee: {
                __typename?: 'User';
                id: string;
                name: string;
              } | null;
              questions: {
                __typename?: 'MeasurementVerificationItemQuestionConnection';
                edges: Array<{
                  __typename?: 'MeasurementVerificationItemQuestionEdge';
                  node: {
                    __typename?: 'MeasurementVerificationItemQuestion';
                    id: string;
                    status: GQMeasurementVerificationQuestionStatus;
                  } | null;
                }>;
              };
            } | null;
          }>;
        };
      };
    };
  };
};

export type GQDeleteMeasurementVerificationItemForAdminMutationVariables =
  Exact<{
    input: GQDeleteMeasurementVerificationItemInput;
  }>;

export type GQDeleteMeasurementVerificationItemForAdminMutation = {
  __typename?: 'Mutation';
  deleteMeasurementVerificationItem: {
    __typename?: 'DeleteMeasurementVerificationItemPayload';
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      completedAt: Date | null;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            name: string;
            description: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
            } | null;
            assignee: { __typename?: 'User'; id: string; name: string } | null;
            questions: {
              __typename?: 'MeasurementVerificationItemQuestionConnection';
              edges: Array<{
                __typename?: 'MeasurementVerificationItemQuestionEdge';
                node: {
                  __typename?: 'MeasurementVerificationItemQuestion';
                  id: string;
                  status: GQMeasurementVerificationQuestionStatus;
                } | null;
              }>;
            };
          } | null;
        }>;
      };
    };
  };
};

export type GQGenerateProjectVerificationItemsForAdminMutationVariables =
  Exact<{
    input: GQGenerateMeasurementVerificationItemsInput;
  }>;

export type GQGenerateProjectVerificationItemsForAdminMutation = {
  __typename?: 'Mutation';
  generateMeasurementVerificationItems: {
    __typename?: 'GenerateMeasurementVerificationItemsPayload';
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      completedAt: Date | null;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            name: string;
            description: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
            } | null;
            assignee: { __typename?: 'User'; id: string; name: string } | null;
            questions: {
              __typename?: 'MeasurementVerificationItemQuestionConnection';
              edges: Array<{
                __typename?: 'MeasurementVerificationItemQuestionEdge';
                node: {
                  __typename?: 'MeasurementVerificationItemQuestion';
                  id: string;
                  status: GQMeasurementVerificationQuestionStatus;
                } | null;
              }>;
            };
          } | null;
        }>;
      };
    };
  } | null;
};

export type GQPublishMeasurementVerificationItemsForAdminMutationVariables =
  Exact<{
    input: GQPublishMeasurementVerificationItemsInput;
  }>;

export type GQPublishMeasurementVerificationItemsForAdminMutation = {
  __typename?: 'Mutation';
  publishMeasurementVerificationItems: {
    __typename?: 'PublishMeasurementVerificationItemsPayload';
    updatedQuestions: Array<{
      __typename?: 'MeasurementVerificationItemQuestion';
      id: string;
      status: GQMeasurementVerificationQuestionStatus;
    }> | null;
  };
};

export type GQPreviewMeasurementVerificationItemsForAdminMutationVariables =
  Exact<{
    input: GQPreviewMeasurementVerificationItemEmailsInput;
  }>;

export type GQPreviewMeasurementVerificationItemsForAdminMutation = {
  __typename?: 'Mutation';
  previewMeasurementVerificationItemEmails: {
    __typename?: 'PreviewMeasurementVerificationItemEmailsPayload';
    previewEmails: Array<{
      __typename?: 'EmailContentsWithUserRecipient';
      userId: string;
      emailContents: {
        __typename?: 'EmailContents';
        subject: string;
        bodyHtml: string;
        recipients: Array<{
          __typename?: 'EmailUser';
          email: string;
          name: string;
        }>;
        ccs: Array<{
          __typename?: 'EmailUser';
          email: string;
          name: string;
        }> | null;
        sender: { __typename?: 'EmailUser'; email: string; name: string };
        bccs: Array<{
          __typename?: 'EmailUser';
          email: string;
          name: string;
        } | null> | null;
      };
    }>;
  };
};

export type GQUpdateAdminMeasurementVerificationItemMutationVariables = Exact<{
  input: GQUpdateAdminMeasurementVerificationItemInput;
}>;

export type GQUpdateAdminMeasurementVerificationItemMutation = {
  __typename?: 'Mutation';
  updateAdminMeasurementVerificationItem: {
    __typename?: 'UpdateMeasurementVerificationItemPayload';
    measurementVerificationItem: {
      __typename?: 'MeasurementVerificationItem';
      id: string;
      description: string;
    };
  };
};

export type GQOrgSpecificEmissionsModelsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrgSpecificEmissionsModelsQuery = {
  __typename?: 'Query';
  emissionsModelsForOrg: Array<{
    __typename?: 'EmissionsModelStable';
    id: string;
    title: string;
    description: string;
    isArchived: boolean;
    kind: GQEmissionsModelStableKind;
    scope: GQEmissionsModelStableScope;
    matchers: Array<{
      __typename?: 'EmissionsModelMatcher';
      id: string;
      businessActivityType: string;
    }>;
    latestPublishedVersion: {
      __typename?: 'EmissionsModelVersion';
      id: string;
      createdAt: Date;
      changelog: string;
      title: string;
      author: { __typename?: 'User'; id: string; displayName: string } | null;
    } | null;
  }>;
};

export type GQOrgSpecificReferenceDataQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrgSpecificReferenceDataQuery = {
  __typename?: 'Query';
  latestReferenceDataRevisionsForOrg: Array<{
    __typename?: 'ReferenceDataRevision';
    id: string;
    createdAt: Date;
    archivedAt: Date | null;
    state: GQReferenceDataRevisionState;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
    } | null;
    source: { __typename?: 'ReferenceDataSource'; id: string; name: string };
    version: {
      __typename?: 'ReferenceDataVersion';
      id: string;
      versionName: string;
    };
    author: { __typename?: 'User'; id: string; name: string } | null;
  } | null>;
};

export type GQOrganizationFilesQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  fileFilters: InputMaybe<GQFileMetadataFilterInput>;
}>;

export type GQOrganizationFilesQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: { __typename?: 'Dataset'; id: string; name: string };
            };
          } | null;
        } | null>;
      };
    }>;
  };
  files: {
    __typename?: 'FileMetadataConnection';
    edges: Array<{
      __typename?: 'FileMetadataEdge';
      node: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        description: string | null;
        directory: string;
        size: number;
        createdAt: Date;
        gcloudStorageUrl: string;
        revisionRoot: string | null;
        deletedAt: Date | null;
        uploader:
          | { __typename?: 'User'; id: string; name: string; email: string }
          | {
              __typename?: 'WatershedEmployee';
              id: string;
              name: string;
              email: string;
            }
          | null;
      } | null;
    } | null>;
  };
};

export type GQFileMetadataFieldsForOrgFilesDialogFragment = {
  __typename?: 'FileMetadata';
  id: string;
  name: string;
  description: string | null;
  directory: string;
  size: number;
  createdAt: Date;
  gcloudStorageUrl: string;
  revisionRoot: string | null;
  deletedAt: Date | null;
  uploader:
    | { __typename?: 'User'; id: string; name: string; email: string }
    | {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
        email: string;
      }
    | null;
};

export type GQPipelineResultForCreateFootprintSnapshotDialogFragment = {
  __typename?: 'PipelineResult';
  id: string;
  name: string;
  pipelineKind: GQPipelineKind;
  orgId: string;
  createdAt: Date;
  coverageInterval: YMInterval;
  runUserId: string | null;
  pipelineRunConfig: string;
  deletedAt: Date | null;
  isUsedInLatestDraft: boolean;
  isUsedInLatestPublish: boolean;
  summary: {
    __typename?: 'PipelineOutputSummary';
    format: number;
    emissions: Array<{
      __typename?: 'PipelineOutputSummaryEmission';
      month: Date;
      kgCo2e: number;
      businessCategory: string | null;
      adtName: string | null;
      footprintKind: string;
    }>;
    warnings: Array<{
      __typename?: 'ValidationWarning';
      blocking: boolean;
      message: string;
    }> | null;
  };
};

export type GQPipelineRunForCreateFootprintDialogFragment = {
  __typename?: 'PipelineRun';
  id: string;
  name: string | null;
  createdAt: Date;
  pipelineResults: Array<{
    __typename?: 'PipelineResult';
    id: string;
    name: string;
    pipelineKind: GQPipelineKind;
    orgId: string;
    createdAt: Date;
    coverageInterval: YMInterval;
    runUserId: string | null;
    pipelineRunConfig: string;
    deletedAt: Date | null;
    isUsedInLatestDraft: boolean;
    isUsedInLatestPublish: boolean;
    summary: {
      __typename?: 'PipelineOutputSummary';
      format: number;
      emissions: Array<{
        __typename?: 'PipelineOutputSummaryEmission';
        month: Date;
        kgCo2e: number;
        businessCategory: string | null;
        adtName: string | null;
        footprintKind: string;
      }>;
      warnings: Array<{
        __typename?: 'ValidationWarning';
        blocking: boolean;
        message: string;
      }> | null;
    };
  }>;
};

export type GQFootprintForCreateFootprintSnapshotDialogFragment = {
  __typename?: 'FootprintTemp';
  id: string;
  name: string;
};

export type GQAdtsForRunPipelineDialogQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQAdtsForRunPipelineDialogQuery = {
  __typename?: 'Query';
  shouldAllowPipelineResultFootprintKind: boolean;
  activityDataTables: {
    __typename?: 'ActivityDataTableConnection';
    edges: Array<{
      __typename?: 'ActivityDataTableEdge';
      node: {
        __typename?: 'ActivityDataTable';
        id: string;
        lastRefreshedAt: Date | null;
        name: string;
        latestBartActivityType: string | null;
        filterInterval: YMInterval;
        stage: GQActivityDataTableStageKind;
        publishedExtractor: {
          __typename?: 'ActivityDataExtractor';
          id: string;
          createdAt: Date;
          kind: GQActivityDataExtractorKind;
          userVisible: boolean;
          facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
          bartId: string | null;
        } | null;
        draftCadtExtractor: {
          __typename?: 'ActivityDataExtractor';
          id: string;
          createdAt: Date;
        } | null;
        draftBartExtractor: {
          __typename?: 'ActivityDataExtractor';
          id: string;
          createdAt: Date;
        } | null;
      } | null;
    } | null>;
  };
};

export type GQGetPipelineRunsForCreateFootprintDialogQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetPipelineRunsForCreateFootprintDialogQuery = {
  __typename?: 'Query';
  isMultipleFootprintsEnabled: boolean;
  pipelineRuns: {
    __typename?: 'PipelineRunConnection';
    edges: Array<{
      __typename?: 'PipelineRunEdge';
      node: {
        __typename?: 'PipelineRun';
        id: string;
        name: string | null;
        createdAt: Date;
        pipelineResults: Array<{
          __typename?: 'PipelineResult';
          id: string;
          name: string;
          pipelineKind: GQPipelineKind;
          orgId: string;
          createdAt: Date;
          coverageInterval: YMInterval;
          runUserId: string | null;
          pipelineRunConfig: string;
          deletedAt: Date | null;
          isUsedInLatestDraft: boolean;
          isUsedInLatestPublish: boolean;
          summary: {
            __typename?: 'PipelineOutputSummary';
            format: number;
            emissions: Array<{
              __typename?: 'PipelineOutputSummaryEmission';
              month: Date;
              kgCo2e: number;
              businessCategory: string | null;
              adtName: string | null;
              footprintKind: string;
            }>;
            warnings: Array<{
              __typename?: 'ValidationWarning';
              blocking: boolean;
              message: string;
            }> | null;
          };
        }>;
      } | null;
    } | null>;
  };
  footprintsForOrg: {
    __typename?: 'FootprintConnection';
    edges: Array<{
      __typename?: 'FootprintEdge';
      node: { __typename?: 'FootprintTemp'; id: string; name: string } | null;
    } | null>;
  };
};

export type GQCreateFootprintSnapshotMutationVariables = Exact<{
  input: GQCreateFootprintSnapshotInput;
}>;

export type GQCreateFootprintSnapshotMutation = {
  __typename?: 'Mutation';
  createFootprintSnapshot: {
    __typename?: 'CreateFootprintSnapshotPayload';
    job: {
      __typename?: 'BackgroundJobEntry';
      id: string;
      error: {
        __typename?: 'SerializableError';
        message: string;
        details: any | null;
      } | null;
    };
  };
};

export type GQGetPipelineResultsByIdQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  pipelineResultIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQGetPipelineResultsByIdQuery = {
  __typename?: 'Query';
  pipelineResults: {
    __typename?: 'PipelineResultConnection';
    edges: Array<{
      __typename?: 'PipelineResultEdge';
      node: {
        __typename?: 'PipelineResult';
        id: string;
        name: string;
        pipelineKind: GQPipelineKind;
        orgId: string;
        createdAt: Date;
        coverageInterval: YMInterval;
        runUserId: string | null;
        pipelineRunConfig: string;
        deletedAt: Date | null;
        parquetDataId: string | null;
        prkFileMetadataId: string | null;
        isUsedInLatestDraft: boolean;
        isUsedInLatestPublish: boolean;
        summary: {
          __typename?: 'PipelineOutputSummary';
          format: number;
          emissions: Array<{
            __typename?: 'PipelineOutputSummaryEmission';
            month: Date;
            kgCo2e: number;
            businessCategory: string | null;
            adtName: string | null;
            footprintKind: string;
          }>;
          warnings: Array<{
            __typename?: 'ValidationWarning';
            blocking: boolean;
            message: string;
          }> | null;
        };
        emissionsModelEvaluations: Array<{
          __typename?: 'EmissionsModelEvaluation';
          id: string;
          uniqueBartRowCount: number | null;
          bartTotalRowCount: number | null;
          evaluatedBartId: string | null;
          evaluatedEmtaFmdId: string | null;
          emissionsModelVersion: {
            __typename?: 'EmissionsModelVersion';
            id: string;
            stableModel: {
              __typename?: 'EmissionsModelStable';
              id: string;
              title: string;
            };
          };
          activityDataExtractor: {
            __typename?: 'ActivityDataExtractor';
            id: string;
            activityDataTable: {
              __typename?: 'ActivityDataTable';
              id: string;
              name: string;
            };
          } | null;
          evaluatedExtendedBart: {
            __typename?: 'ExtendedBart';
            fileMetadataId: string;
          } | null;
        }>;
      } | null;
    } | null>;
  };
};

export type GQGetAdtsFromAdeIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQGetAdtsFromAdeIdsQuery = {
  __typename?: 'Query';
  activityDataExtractorsById: Array<{
    __typename?: 'ActivityDataExtractor';
    id: string;
    activityDataTable: {
      __typename?: 'ActivityDataTable';
      id: string;
      name: string;
    };
  }>;
};

export type GQGetFootprintTagDetailsForOrgQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetFootprintTagDetailsForOrgQuery = {
  __typename?: 'Query';
  footprintTagsForOrg: Array<{
    __typename?: 'FootprintTag';
    id: string;
    tagName: string;
    description: string | null;
    watershedEditor: {
      __typename?: 'WatershedEmployee';
      id: string;
      name: string;
      email: string;
    } | null;
  }>;
};

export type GQGetFootprintExclusionAndCategoryRulesForOrgQueryVariables =
  Exact<{
    orgId: Scalars['ID']['input'];
  }>;

export type GQGetFootprintExclusionAndCategoryRulesForOrgQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    footprintExclusionRules: Array<{
      __typename?: 'FootprintExclusionRule';
      id: string;
    }>;
    footprintCategoryRules: Array<{
      __typename?: 'FootprintCategoryRule';
      id: string;
    }>;
  };
};

export type GQGetMarketplaceAllocationsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetMarketplaceAllocationsQuery = {
  __typename?: 'Query';
  marketplaceAllocationInstructions: {
    __typename?: 'MarketplaceAllocationInstructionConnection';
    edges: Array<{
      __typename?: 'MarketplaceAllocationInstructionEdge';
      node: {
        __typename?: 'MarketplaceAllocationInstruction';
        id: string;
        amountKwh: number;
        locationCity: string | null;
        locationGrid: string | null;
        locationState: string | null;
        locationCountry: string | null;
        buildingName: string | null;
        vendorEntity: string | null;
        vendorSubentity: string | null;
        categoryId: string | null;
        ghgCategoryId: string;
        footprintKind: string | null;
        subcategoryId: string | null;
        interval: YMInterval;
        purchaseLineItem: {
          __typename?: 'MarketplacePurchaseLineItem';
          id: string;
          purchase: { __typename?: 'MarketplacePurchase'; id: string };
        };
      } | null;
    } | null>;
  };
};

export type GQAdtFieldsForRunPipelineDialogFragment = {
  __typename?: 'ActivityDataTable';
  id: string;
  lastRefreshedAt: Date | null;
  name: string;
  latestBartActivityType: string | null;
  filterInterval: YMInterval;
  stage: GQActivityDataTableStageKind;
  publishedExtractor: {
    __typename?: 'ActivityDataExtractor';
    id: string;
    createdAt: Date;
    kind: GQActivityDataExtractorKind;
    userVisible: boolean;
    facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
    bartId: string | null;
  } | null;
  draftCadtExtractor: {
    __typename?: 'ActivityDataExtractor';
    id: string;
    createdAt: Date;
  } | null;
  draftBartExtractor: {
    __typename?: 'ActivityDataExtractor';
    id: string;
    createdAt: Date;
  } | null;
};

export type GQEmissionsModelPipelineDialogQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQEmissionsModelPipelineDialogQuery = {
  __typename?: 'Query';
  availableReleases: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    updatedAt: Date;
    userAuthorId: string | null;
    displayName: string;
    businessActivityTypeName: string;
    internalNotes: string;
    externalNotes: string;
    orgId: string | null;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    isCustomToUserOrg: boolean | null;
    isOrgGated: boolean;
    user: { __typename?: 'User'; id: string; name: string } | null;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    } | null;
    latestCompatibleBatVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    orgAccess: Array<{
      __typename?: 'EmissionsModelReleaseOrgAccess';
      id: string;
      createdAt: Date;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    }>;
  }>;
  measurementTestSuites: Array<{
    __typename?: 'MeasurementTestSuite';
    id: string;
    title: string;
    kind: GQMeasurementTestSuiteKind | null;
    status: string;
    measurementTestSuiteBarts: Array<{
      __typename?: 'MeasurementTestSuiteBart';
      id: string;
      activityTypeName: string;
    }>;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
    } | null;
    calculationTags: Array<{
      __typename?: 'CalculationTag';
      id: string;
      name: string;
    }>;
    emissionsModelReleases: Array<{
      __typename?: 'EmissionsModelRelease';
      id: string;
      updatedAt: Date;
      userAuthorId: string | null;
      displayName: string;
      businessActivityTypeName: string;
      internalNotes: string;
      externalNotes: string;
      orgId: string | null;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      isCustomToUserOrg: boolean | null;
      isOrgGated: boolean;
      user: { __typename?: 'User'; id: string; name: string } | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      } | null;
      latestCompatibleBatVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
      orgAccess: Array<{
        __typename?: 'EmissionsModelReleaseOrgAccess';
        id: string;
        createdAt: Date;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        };
      }>;
    }>;
  }>;
  activityDataTables: {
    __typename?: 'ActivityDataTableConnection';
    edges: Array<{
      __typename?: 'ActivityDataTableEdge';
      node: {
        __typename?: 'ActivityDataTable';
        id: string;
        lastRefreshedAt: Date | null;
        name: string;
        latestBartActivityType: string | null;
        filterInterval: YMInterval;
        stage: GQActivityDataTableStageKind;
        publishedExtractor: {
          __typename?: 'ActivityDataExtractor';
          id: string;
          createdAt: Date;
          kind: GQActivityDataExtractorKind;
          userVisible: boolean;
          facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
          bartId: string | null;
        } | null;
        draftCadtExtractor: {
          __typename?: 'ActivityDataExtractor';
          id: string;
          createdAt: Date;
        } | null;
        draftBartExtractor: {
          __typename?: 'ActivityDataExtractor';
          id: string;
          createdAt: Date;
        } | null;
      } | null;
    } | null>;
  };
};

export type GQRefreshCarbonRemovalActivityDataTableMutationVariables = Exact<{
  input: GQRefreshCarbonRemovalActivityDataTableInput;
}>;

export type GQRefreshCarbonRemovalActivityDataTableMutation = {
  __typename?: 'Mutation';
  refreshCarbonRemovalActivityDataTable: {
    __typename?: 'RefreshCarbonRemovalActivityDataTablePayload';
    jobId: string | null;
  } | null;
};

export type GQGetFootprintSnapshotDetailsSnapshotFragment = {
  __typename?: 'FootprintSnapshot';
  id: string;
  publishedAt: Date | null;
  publishedHiddenAt: Date | null;
  publishedDraftAt: Date | null;
  marketplaceAllocationInstructionIds: Array<string>;
  summary: {
    __typename?: 'FootprintSnapshotSummary';
    emissions: Array<{
      __typename?: 'FootprintSnapshotSummaryEmission';
      month: Date;
      businessCategory: string | null;
      pipeline: string;
      kgCo2e: number;
      footprintKind: string;
    }>;
  };
  tags: Array<{ __typename?: 'FootprintTag'; id: string; tagName: string }>;
  categoryRules: Array<{ __typename?: 'FootprintCategoryRule'; id: string }>;
  exclusionRules: Array<{ __typename?: 'FootprintExclusionRule'; id: string }>;
};

export type GQGetFootprintSnapshotDetailsQueryVariables = Exact<{
  snapshotId: Scalars['ID']['input'];
}>;

export type GQGetFootprintSnapshotDetailsQuery = {
  __typename?: 'Query';
  footprintSnapshot: {
    __typename?: 'FootprintSnapshot';
    id: string;
    publishedAt: Date | null;
    publishedHiddenAt: Date | null;
    publishedDraftAt: Date | null;
    marketplaceAllocationInstructionIds: Array<string>;
    summary: {
      __typename?: 'FootprintSnapshotSummary';
      emissions: Array<{
        __typename?: 'FootprintSnapshotSummaryEmission';
        month: Date;
        businessCategory: string | null;
        pipeline: string;
        kgCo2e: number;
        footprintKind: string;
      }>;
    };
    tags: Array<{ __typename?: 'FootprintTag'; id: string; tagName: string }>;
    categoryRules: Array<{ __typename?: 'FootprintCategoryRule'; id: string }>;
    exclusionRules: Array<{
      __typename?: 'FootprintExclusionRule';
      id: string;
    }>;
  };
};

export type GQSaveFootprintSnapshotMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQSaveFootprintSnapshotMutation = {
  __typename?: 'Mutation';
  setFootprintSnapshotIsManuallySaved: {
    __typename?: 'SetFootprintSnapshotIsManuallySavedPayload';
    snapshot: {
      __typename?: 'FootprintSnapshot';
      id: string;
      isManuallySaved: boolean;
    };
  } | null;
};

export type GQUnsaveFootprintSnapshotMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQUnsaveFootprintSnapshotMutation = {
  __typename?: 'Mutation';
  setFootprintSnapshotIsManuallySaved: {
    __typename?: 'SetFootprintSnapshotIsManuallySavedPayload';
    snapshot: {
      __typename?: 'FootprintSnapshot';
      id: string;
      isManuallySaved: boolean;
    };
  } | null;
};

export type GQArchiveFootprintSnapshotMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQArchiveFootprintSnapshotMutation = {
  __typename?: 'Mutation';
  archiveFootprintSnapshot: {
    __typename?: 'ArchiveFootprintSnapshotPayload';
    snapshot: { __typename?: 'FootprintSnapshot'; id: string; orgId: string };
  } | null;
};

export type GQFootprintSnapshotsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  isAutoGenerated: InputMaybe<Scalars['Boolean']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQFootprintSnapshotsQuery = {
  __typename?: 'Query';
  footprintSnapshots: {
    __typename?: 'FootprintSnapshotConnection';
    edges: Array<{
      __typename?: 'FootprintSnapshotEdge';
      node: {
        __typename?: 'FootprintSnapshot';
        id: string;
        createdAt: Date;
        descriptionMd: string | null;
        publishedAt: Date | null;
        publishedHiddenAt: Date | null;
        publishedDraftAt: Date | null;
        dataRegistryTableName: string | null;
        isStableRefreshAvailable: boolean;
        extraAdeIdsForIntDenoms: Array<string>;
        extraAdeIdsForNonEmissiveActivity: Array<string>;
        marketplaceAllocationInstructionIds: Array<string>;
        isManuallySaved: boolean;
        configs: Array<{
          __typename?: 'PipelineConfig';
          pipelineResultId: string;
          coverageInterval: YMInterval;
        }>;
        versions: Array<{
          __typename?: 'FootprintVersion';
          id: string;
          createdAt: Date;
          isUserVisible: boolean;
          kind: GQFootprintVersionKind;
          userChangelogMd: string | null;
          publishableUserChangelogMd: string | null;
          internalNotesMd: string;
          isUserChangelogHidden: boolean;
          originalFootprintSnapshotId: string;
        }>;
        tags: Array<{
          __typename?: 'FootprintTag';
          id: string;
          tagName: string;
          description: string | null;
          watershedEditor: {
            __typename?: 'WatershedEmployee';
            id: string;
            name: string;
            email: string;
          } | null;
        }>;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type GQMultipleFootprintsEnabledForOrgQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQMultipleFootprintsEnabledForOrgQuery = {
  __typename?: 'Query';
  featureFlagEnabledForOrg: boolean;
};

export type GQGetFootprintTagsForOrgQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetFootprintTagsForOrgQuery = {
  __typename?: 'Query';
  footprintTagsForOrg: Array<{
    __typename?: 'FootprintTag';
    id: string;
    createdAt: Date;
    tagName: string;
    description: string | null;
    rulesList: any;
    isFinance: boolean;
    watershedEditor: {
      __typename?: 'WatershedEmployee';
      id: string;
      name: string;
      email: string;
    } | null;
  }>;
};

export type GQDeleteFootprintTagMutationVariables = Exact<{
  input: GQDeleteFootprintTagInput;
}>;

export type GQDeleteFootprintTagMutation = {
  __typename?: 'Mutation';
  deleteFootprintTag: {
    __typename?: 'DeleteFootprintTagPayload';
    footprintTag: { __typename?: 'FootprintTag'; id: string };
  } | null;
};

export type GQDataIssueForOrgInboxFieldsFragment = {
  __typename: 'DataIssue';
  createdAt: Date;
  id: string;
  title: string;
  description: string | null;
  state: GQDataIssueState;
  isPublished: boolean;
  isCustomerInitiated: boolean;
  tags: Array<GQDataIssueTag> | null;
  name: string;
  adminUrl: string;
  assignee: {
    __typename?: 'User';
    id: string;
    name: string;
    permissions: Array<{
      __typename?: 'PermissionItem';
      id: string;
      objectId: string | null;
      permission: GQPermissionType;
      revokedAt: Date | null;
      object:
        | { __typename: 'Company'; id: string; name: string }
        | { __typename: 'CompanySurvey'; id: string; name: string }
        | { __typename: 'Dataset'; id: string; name: string }
        | { __typename: 'Datasource'; id: string; name: string }
        | { __typename: 'EngagementTask'; id: string; name: string }
        | { __typename: 'Fund'; id: string; name: string }
        | null;
    }>;
    roles: Array<{
      __typename?: 'UserRoleAssignment';
      id: string;
      revokedAt: Date | null;
      role: {
        __typename?: 'Role';
        id: string;
        name: string;
        description: string | null;
        permissions: Array<{
          __typename?: 'PermissionItem';
          id: string;
          objectId: string | null;
          permission: GQPermissionType;
          revokedAt: Date | null;
          object:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanySurvey'; id: string; name: string }
            | { __typename: 'Dataset'; id: string; name: string }
            | { __typename: 'Datasource'; id: string; name: string }
            | { __typename: 'EngagementTask'; id: string; name: string }
            | { __typename: 'Fund'; id: string; name: string }
            | null;
        }>;
      };
    }>;
  } | null;
  comments: {
    __typename?: 'CommentConnection';
    edges: Array<{
      __typename?: 'CommentEdge';
      node: {
        __typename?: 'Comment';
        id: string;
        message: string;
        createdAt: Date;
        isPublished: boolean;
        person:
          | {
              __typename?: 'User';
              id: string;
              name: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
            }
          | {
              __typename?: 'WatershedEmployee';
              id: string;
              name: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
            }
          | null;
      } | null;
    } | null>;
  };
  files: {
    __typename?: 'FileMetadataConnection';
    edges: Array<{
      __typename?: 'FileMetadataEdge';
      node: { __typename?: 'FileMetadata'; id: string } | null;
    } | null>;
  };
  linkedObject:
    | { __typename: 'FinancialsReviewItem'; id: string }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        item: {
          __typename?: 'MeasurementVerificationItem';
          id: string;
          datasetName: string;
        };
      }
    | {
        __typename: 'UserUploadTask';
        id: string;
        assignee: {
          __typename?: 'User';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
          roles: Array<{
            __typename?: 'UserRoleAssignment';
            id: string;
            revokedAt: Date | null;
            role: {
              __typename?: 'Role';
              id: string;
              name: string;
              description: string | null;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
            };
          }>;
        } | null;
        datasource: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: { __typename?: 'Dataset'; id: string; name: string };
        };
      }
    | { __typename: 'ValueMappingTask'; id: string }
    | null;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

type GQInboxItemFields_DataIssue_Fragment = {
  __typename: 'DataIssue';
  createdAt: Date;
  id: string;
  title: string;
  description: string | null;
  state: GQDataIssueState;
  isPublished: boolean;
  isCustomerInitiated: boolean;
  tags: Array<GQDataIssueTag> | null;
  name: string;
  adminUrl: string;
  assignee: {
    __typename?: 'User';
    id: string;
    name: string;
    permissions: Array<{
      __typename?: 'PermissionItem';
      id: string;
      objectId: string | null;
      permission: GQPermissionType;
      revokedAt: Date | null;
      object:
        | { __typename: 'Company'; id: string; name: string }
        | { __typename: 'CompanySurvey'; id: string; name: string }
        | { __typename: 'Dataset'; id: string; name: string }
        | { __typename: 'Datasource'; id: string; name: string }
        | { __typename: 'EngagementTask'; id: string; name: string }
        | { __typename: 'Fund'; id: string; name: string }
        | null;
    }>;
    roles: Array<{
      __typename?: 'UserRoleAssignment';
      id: string;
      revokedAt: Date | null;
      role: {
        __typename?: 'Role';
        id: string;
        name: string;
        description: string | null;
        permissions: Array<{
          __typename?: 'PermissionItem';
          id: string;
          objectId: string | null;
          permission: GQPermissionType;
          revokedAt: Date | null;
          object:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanySurvey'; id: string; name: string }
            | { __typename: 'Dataset'; id: string; name: string }
            | { __typename: 'Datasource'; id: string; name: string }
            | { __typename: 'EngagementTask'; id: string; name: string }
            | { __typename: 'Fund'; id: string; name: string }
            | null;
        }>;
      };
    }>;
  } | null;
  comments: {
    __typename?: 'CommentConnection';
    edges: Array<{
      __typename?: 'CommentEdge';
      node: {
        __typename?: 'Comment';
        id: string;
        message: string;
        createdAt: Date;
        isPublished: boolean;
        person:
          | {
              __typename?: 'User';
              id: string;
              name: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
            }
          | {
              __typename?: 'WatershedEmployee';
              id: string;
              name: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
            }
          | null;
      } | null;
    } | null>;
  };
  files: {
    __typename?: 'FileMetadataConnection';
    edges: Array<{
      __typename?: 'FileMetadataEdge';
      node: { __typename?: 'FileMetadata'; id: string } | null;
    } | null>;
  };
  linkedObject:
    | { __typename: 'FinancialsReviewItem'; id: string }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        item: {
          __typename?: 'MeasurementVerificationItem';
          id: string;
          datasetName: string;
        };
      }
    | {
        __typename: 'UserUploadTask';
        id: string;
        assignee: {
          __typename?: 'User';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
          roles: Array<{
            __typename?: 'UserRoleAssignment';
            id: string;
            revokedAt: Date | null;
            role: {
              __typename?: 'Role';
              id: string;
              name: string;
              description: string | null;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
            };
          }>;
        } | null;
        datasource: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: { __typename?: 'Dataset'; id: string; name: string };
        };
      }
    | { __typename: 'ValueMappingTask'; id: string }
    | null;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

type GQInboxItemFields_FootprintQuestion_Fragment = {
  __typename: 'FootprintQuestion';
};

type GQInboxItemFields_UserUploadTask_Fragment = {
  __typename: 'UserUploadTask';
  id: string;
  createdAt: Date;
  taskState: GQUserUploadTaskState;
  datasource: { __typename?: 'Datasource'; id: string; name: string };
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
  };
  userUploads: {
    __typename?: 'UserUploadConnection';
    edges: Array<{
      __typename?: 'UserUploadEdge';
      node: {
        __typename?: 'UserUpload';
        id: string;
        name: string;
        status: GQUserUploadStatus;
        processingMode: GQUserUploadProcessingMode | null;
        userVisibleAttempt: {
          __typename?: 'UserUploadAttempt';
          id: string;
          createdAt: Date;
          gcloudStorageUrl: string;
          size: number;
          userUploadedTables: Array<{
            __typename?: 'UserUploadedTable';
            id: string;
          }>;
        } | null;
        latestAttempt: {
          __typename?: 'UserUploadAttempt';
          id: string;
          userUploadedTables: Array<{
            __typename?: 'UserUploadedTable';
            id: string;
            shouldIgnore: boolean;
          }>;
        };
      } | null;
    } | null>;
  } | null;
};

export type GQInboxItemFieldsFragment =
  | GQInboxItemFields_DataIssue_Fragment
  | GQInboxItemFields_FootprintQuestion_Fragment
  | GQInboxItemFields_UserUploadTask_Fragment;

export type GQOrganizationInboxQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrganizationInboxQuery = {
  __typename?: 'Query';
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
    }>;
  };
  inboxItemsForOrg: {
    __typename?: 'OrganizationInboxItemConnection';
    edges: Array<{
      __typename?: 'OrganizationInboxItemEdge';
      node:
        | {
            __typename: 'DataIssue';
            createdAt: Date;
            id: string;
            title: string;
            description: string | null;
            state: GQDataIssueState;
            isPublished: boolean;
            isCustomerInitiated: boolean;
            tags: Array<GQDataIssueTag> | null;
            name: string;
            adminUrl: string;
            assignee: {
              __typename?: 'User';
              id: string;
              name: string;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
              roles: Array<{
                __typename?: 'UserRoleAssignment';
                id: string;
                revokedAt: Date | null;
                role: {
                  __typename?: 'Role';
                  id: string;
                  name: string;
                  description: string | null;
                  permissions: Array<{
                    __typename?: 'PermissionItem';
                    id: string;
                    objectId: string | null;
                    permission: GQPermissionType;
                    revokedAt: Date | null;
                    object:
                      | { __typename: 'Company'; id: string; name: string }
                      | {
                          __typename: 'CompanySurvey';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Dataset'; id: string; name: string }
                      | { __typename: 'Datasource'; id: string; name: string }
                      | {
                          __typename: 'EngagementTask';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Fund'; id: string; name: string }
                      | null;
                  }>;
                };
              }>;
            } | null;
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  message: string;
                  createdAt: Date;
                  isPublished: boolean;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        name: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
            files: {
              __typename?: 'FileMetadataConnection';
              edges: Array<{
                __typename?: 'FileMetadataEdge';
                node: { __typename?: 'FileMetadata'; id: string } | null;
              } | null>;
            };
            linkedObject:
              | { __typename: 'FinancialsReviewItem'; id: string }
              | {
                  __typename: 'MeasurementVerificationItemQuestion';
                  id: string;
                  item: {
                    __typename?: 'MeasurementVerificationItem';
                    id: string;
                    datasetName: string;
                  };
                }
              | {
                  __typename: 'UserUploadTask';
                  id: string;
                  assignee: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    permissions: Array<{
                      __typename?: 'PermissionItem';
                      id: string;
                      objectId: string | null;
                      permission: GQPermissionType;
                      revokedAt: Date | null;
                      object:
                        | { __typename: 'Company'; id: string; name: string }
                        | {
                            __typename: 'CompanySurvey';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Dataset'; id: string; name: string }
                        | { __typename: 'Datasource'; id: string; name: string }
                        | {
                            __typename: 'EngagementTask';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Fund'; id: string; name: string }
                        | null;
                    }>;
                    roles: Array<{
                      __typename?: 'UserRoleAssignment';
                      id: string;
                      revokedAt: Date | null;
                      role: {
                        __typename?: 'Role';
                        id: string;
                        name: string;
                        description: string | null;
                        permissions: Array<{
                          __typename?: 'PermissionItem';
                          id: string;
                          objectId: string | null;
                          permission: GQPermissionType;
                          revokedAt: Date | null;
                          object:
                            | {
                                __typename: 'Company';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'CompanySurvey';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'Dataset';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'Datasource';
                                id: string;
                                name: string;
                              }
                            | {
                                __typename: 'EngagementTask';
                                id: string;
                                name: string;
                              }
                            | { __typename: 'Fund'; id: string; name: string }
                            | null;
                        }>;
                      };
                    }>;
                  } | null;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask'; id: string }
              | null;
            ancestorRelations: Array<
              | {
                  __typename: 'ActivityDataTable';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'DataIssue';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'Dataset';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'Datasource';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'FileMetadata';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'FootprintQuestion';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'MeasurementProject';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'MeasurementVerificationItemQuestion';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
            >;
          }
        | { __typename: 'FootprintQuestion' }
        | {
            __typename: 'UserUploadTask';
            id: string;
            createdAt: Date;
            taskState: GQUserUploadTaskState;
            datasource: { __typename?: 'Datasource'; id: string; name: string };
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
            };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: {
                  __typename?: 'UserUpload';
                  id: string;
                  name: string;
                  status: GQUserUploadStatus;
                  processingMode: GQUserUploadProcessingMode | null;
                  userVisibleAttempt: {
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    createdAt: Date;
                    gcloudStorageUrl: string;
                    size: number;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                    }>;
                  } | null;
                  latestAttempt: {
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                      shouldIgnore: boolean;
                    }>;
                  };
                } | null;
              } | null>;
            } | null;
          }
        | null;
    } | null>;
  };
};

export type GQOrgResetDialogQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrgResetDialogQuery = {
  __typename?: 'Query';
  employeeReport: { __typename?: 'EmployeeReport'; id: string } | null;
  organizations: {
    __typename?: 'OrgConnection';
    edges: Array<{
      __typename?: 'OrgEdge';
      node: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        company: { __typename?: 'Company'; id: string; name: string };
      };
    } | null>;
  };
};

export type GQDeleteOrgPointOfContactMutationVariables = Exact<{
  input: GQDeleteOrgPointOfContactInput;
}>;

export type GQDeleteOrgPointOfContactMutation = {
  __typename?: 'Mutation';
  deleteOrgPointOfContact: {
    __typename?: 'DeleteOrgPointOfContactPayload';
    org: {
      __typename?: 'Organization';
      id: string;
      pointsOfContact: Array<{ __typename?: 'OrgPointOfContact'; id: string }>;
    };
  } | null;
};

export type GQGetOrganizationPointOfContactQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  pointOfContactKinds: InputMaybe<
    Array<GQOrgPointOfContactKind> | GQOrgPointOfContactKind
  >;
}>;

export type GQGetOrganizationPointOfContactQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    pointsOfContact: Array<{
      __typename?: 'OrgPointOfContact';
      id: string;
      watershedEmployee: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      };
    }>;
  };
};

export type GQOrganizationSelectorDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQOrganizationSelectorDataQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'OrgConnection';
    edges: Array<{
      __typename?: 'OrgEdge';
      node: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    } | null>;
  };
};

export type GQOrganizationAllUsersQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrganizationAllUsersQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          name: string;
          email: string;
        } | null;
      } | null>;
    } | null;
    pointsOfContact: Array<{
      __typename?: 'OrgPointOfContact';
      id: string;
      watershedEmployee: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
        email: string;
      };
    }>;
  };
};

export type GQArchivePipelineResultMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQArchivePipelineResultMutation = {
  __typename?: 'Mutation';
  archivePipelineResult: {
    __typename?: 'ArchivePipelineResultPayload';
    pipelineResult: {
      __typename?: 'PipelineResult';
      id: string;
      orgId: string;
    };
  } | null;
};

export type GQUpdatePipelineResultMutationVariables = Exact<{
  input: GQUpdatePipelineResultInput;
}>;

export type GQUpdatePipelineResultMutation = {
  __typename?: 'Mutation';
  updatePipelineResult: {
    __typename?: 'UpdatePipelineResultPayload';
    pipelineResult: { __typename?: 'PipelineResult'; id: string; name: string };
  } | null;
};

export type GQGetAdeInfoByIdQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQGetAdeInfoByIdQuery = {
  __typename?: 'Query';
  activityDataExtractorsById: Array<{
    __typename?: 'ActivityDataExtractor';
    id: string;
    name: string;
  }>;
};

export type GQPreviewDataIssueEmailsMutationVariables = Exact<{
  input: GQPreviewDataIssueEmailsInput;
}>;

export type GQPreviewDataIssueEmailsMutation = {
  __typename?: 'Mutation';
  previewDataIssueEmails: {
    __typename?: 'PreviewDataIssueEmailsPayload';
    numberOfEmails: number;
    sampleEmails: Array<{
      __typename?: 'EmailContentsWithUserRecipient';
      userId: string;
      emailContents: {
        __typename?: 'EmailContents';
        subject: string;
        bodyHtml: string;
        recipients: Array<{
          __typename?: 'EmailUser';
          email: string;
          name: string;
        }>;
        ccs: Array<{
          __typename?: 'EmailUser';
          email: string;
          name: string;
        }> | null;
        sender: { __typename?: 'EmailUser'; email: string; name: string };
        bccs: Array<{
          __typename?: 'EmailUser';
          email: string;
          name: string;
        } | null> | null;
      };
    }>;
  } | null;
};

export type GQPublishDataIssuesMutationVariables = Exact<{
  input: GQPublishDataIssuesInput;
}>;

export type GQPublishDataIssuesMutation = {
  __typename?: 'Mutation';
  publishDataIssues: {
    __typename?: 'PublishDataIssuesPayload';
    issues: Array<{
      __typename?: 'DataIssue';
      id: string;
      state: GQDataIssueState;
      isPublished: boolean;
    }>;
    comments: Array<{
      __typename?: 'Comment';
      id: string;
      isPublished: boolean;
    }>;
  } | null;
};

export type GQGetAddUploadToAdtDataQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetAddUploadToAdtDataQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
      }>;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    }>;
  };
  activityDataTables: {
    __typename?: 'ActivityDataTableConnection';
    edges: Array<{
      __typename?: 'ActivityDataTableEdge';
      node: {
        __typename?: 'ActivityDataTable';
        id: string;
        name: string;
        lastRefreshedAt: Date | null;
        filterInterval: YMInterval;
        connectedToAutoBart: boolean;
        autoBartKind: GQAutoBartKind | null;
        autoBartTaskApprovalState: GQAutoBartTaskApprovalState;
        stage: GQActivityDataTableStageKind;
        primaryDatasource: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          hasIntegration: boolean;
          hasIntegrationOption: boolean;
          activeIntegrationConnection: {
            __typename?: 'IntegrationConnection';
            id: string;
          } | null;
        } | null;
        datasets: Array<{
          __typename?: 'Dataset';
          name: string;
          id: string;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        }>;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          hasIntegration: boolean;
          hasIntegrationOption: boolean;
          activeIntegrationConnection: {
            __typename?: 'IntegrationConnection';
            id: string;
          } | null;
        }>;
        draftCadtExtractor: {
          __typename?: 'ActivityDataExtractor';
          id: string;
          createdAt: Date;
        } | null;
        draftBartExtractor: {
          __typename?: 'ActivityDataExtractor';
          id: string;
          createdAt: Date;
          pushedBuildings: boolean | null;
        } | null;
        publishedExtractor: {
          __typename?: 'ActivityDataExtractor';
          id: string;
          createdAt: Date;
          userVisible: boolean;
          facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
          bartActivityType: string | null;
        } | null;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          name: string;
        } | null;
        reviews: Array<{
          __typename?: 'ActivityDataTableReview';
          id: string;
          reviewer: { __typename?: 'User'; id: string; name: string };
        }>;
      } | null;
    } | null>;
  };
};

export type GQUserUploadedTableDataPreviewFieldsFragment = {
  __typename?: 'UserUploadedTable';
  id: string;
  sqlTableName: string | null;
  shouldIgnore: boolean;
  parseConfig: {
    __typename?: 'UserUploadedTableParseConfig';
    noHeader: boolean;
    skipTopNRows: number;
    skipBottomNRows: number;
    meltIdVars: Array<string | null> | null;
  } | null;
  dataPreview: {
    __typename?: 'UserUploadedTableDataPreview';
    dataUntyped: any;
    schema: {
      __typename?: 'UserUploadedTableDataPreviewSchema';
      fields: Array<{
        __typename?: 'UserUploadedTableDataPreviewSchemaField';
        name: string;
        type: string;
      }>;
    };
  } | null;
};

export type GQGetUserUploadedTableDataPreviewQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQGetUserUploadedTableDataPreviewQuery = {
  __typename?: 'Query';
  userUploadedTablesById: Array<{
    __typename?: 'UserUploadedTable';
    id: string;
    sqlTableName: string | null;
    shouldIgnore: boolean;
    parseConfig: {
      __typename?: 'UserUploadedTableParseConfig';
      noHeader: boolean;
      skipTopNRows: number;
      skipBottomNRows: number;
      meltIdVars: Array<string | null> | null;
    } | null;
    dataPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
      schema: {
        __typename?: 'UserUploadedTableDataPreviewSchema';
        fields: Array<{
          __typename?: 'UserUploadedTableDataPreviewSchemaField';
          name: string;
          type: string;
        }>;
      };
    } | null;
  }>;
};

export type GQGetDetailsForFootprintSnapshotsQueryVariables = Exact<{
  snapshotIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
}>;

export type GQGetDetailsForFootprintSnapshotsQuery = {
  __typename?: 'Query';
  footprintSnapshots: {
    __typename?: 'FootprintSnapshotConnection';
    edges: Array<{
      __typename?: 'FootprintSnapshotEdge';
      node: {
        __typename?: 'FootprintSnapshot';
        id: string;
        userUploadedTableIds: Array<string>;
        summary: {
          __typename?: 'FootprintSnapshotSummary';
          emissions: Array<{
            __typename?: 'FootprintSnapshotSummaryEmission';
            month: Date;
            businessCategory: string | null;
            pipeline: string;
            kgCo2e: number;
            footprintKind: string;
          }>;
        };
      } | null;
    } | null>;
  };
  userUploads: {
    __typename?: 'UserUploadConnection';
    edges: Array<{
      __typename?: 'UserUploadEdge';
      node: {
        __typename?: 'UserUpload';
        id: string;
        latestAttempt: {
          __typename?: 'UserUploadAttempt';
          id: string;
          userUploadedTables: Array<{
            __typename?: 'UserUploadedTable';
            id: string;
            shouldIgnore: boolean;
          }>;
        };
      } | null;
    } | null>;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    nextFootprintVersionIdentifier: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      coverageInterval: YMInterval;
    }>;
  };
};

export type GQRunPlaygroundSqlMutationVariables = Exact<{
  input: GQRunPlaygroundSqlInput;
}>;

export type GQRunPlaygroundSqlMutation = {
  __typename?: 'Mutation';
  runPlaygroundSql: {
    __typename?: 'RunPlaygroundSqlPayload';
    run: { __typename?: 'PlaygroundSqlRun'; id: string };
  } | null;
};

export type GQPlaygroundSqlRunForPlaygroundFragment = {
  __typename?: 'PlaygroundSqlRun';
  id: string;
  createdAt: Date;
  state: GQBackgroundJobState;
  error: string | null;
  sql: string;
  user: { __typename?: 'User'; id: string; name: string } | null;
  jobError: {
    __typename?: 'SerializableError';
    message: string;
    code: string | null;
    stackTrace: string | null;
    errorType: string | null;
    details: any | null;
  } | null;
  output: {
    __typename?: 'ParquetData';
    id: string;
    numRecords: number;
    ndjsonSignedUrl: string | null;
    ndjsonSize: number | null;
    ndjsonByteBoundaries: Array<number> | null;
    columns: Array<{
      __typename?: 'ParquetColumn';
      name: string;
      sqlType: string;
      minValue: any;
      maxValue: any;
      numNulls: number;
      approxDistinctValues: number;
      sampleValues: Array<any>;
    }>;
  } | null;
};

export type GQGetPlaygroundSqlRunsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  userId: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
}>;

export type GQGetPlaygroundSqlRunsQuery = {
  __typename?: 'Query';
  playgroundSqlRuns: {
    __typename?: 'PlaygroundSqlRunConnection';
    edges: Array<{
      __typename?: 'PlaygroundSqlRunEdge';
      node: {
        __typename?: 'PlaygroundSqlRun';
        id: string;
        createdAt: Date;
        state: GQBackgroundJobState;
        error: string | null;
        sql: string;
        user: { __typename?: 'User'; id: string; name: string } | null;
        jobError: {
          __typename?: 'SerializableError';
          message: string;
          code: string | null;
          stackTrace: string | null;
          errorType: string | null;
          details: any | null;
        } | null;
        output: {
          __typename?: 'ParquetData';
          id: string;
          numRecords: number;
          ndjsonSignedUrl: string | null;
          ndjsonSize: number | null;
          ndjsonByteBoundaries: Array<number> | null;
          columns: Array<{
            __typename?: 'ParquetColumn';
            name: string;
            sqlType: string;
            minValue: any;
            maxValue: any;
            numNulls: number;
            approxDistinctValues: number;
            sampleValues: Array<any>;
          }>;
        } | null;
      } | null;
    } | null>;
  };
};

export type GQGetPlaygroundSqlQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetPlaygroundSqlQuery = {
  __typename?: 'Query';
  playgroundSql: {
    __typename?: 'PlaygroundSql';
    id: string;
    orgId: string;
    sql: string;
  } | null;
};

export type GQGetNonOrgSpecificQuickSwitcherDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetNonOrgSpecificQuickSwitcherDataQuery = {
  __typename?: 'Query';
  emissionsModelsStable: Array<{
    __typename?: 'EmissionsModelStable';
    id: string;
    title: string;
  }>;
  referenceDataSources: {
    __typename?: 'ReferenceDataSourceConnection';
    edges: Array<{
      __typename?: 'ReferenceDataSourceEdge';
      node: {
        __typename?: 'ReferenceDataSource';
        name: string;
        id: string;
        orgId: string | null;
      } | null;
    } | null>;
  } | null;
  measurementTestSuites: Array<{
    __typename?: 'MeasurementTestSuite';
    id: string;
    title: string;
  }>;
  reportConfigs: {
    __typename?: 'ReportConfigConnection';
    edges: Array<{
      __typename?: 'ReportConfigEdge';
      node: {
        __typename?: 'ReportConfig';
        id: string;
        reportType: string;
        shortName: string;
        longName: string;
        description: string;
      } | null;
    } | null>;
  };
  emissionsModelReleases: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    businessActivityTypeName: string;
    displayName: string;
  }>;
};

export type GQGetOrgSpecificQuickSwitcherDataQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetOrgSpecificQuickSwitcherDataQuery = {
  __typename?: 'Query';
  footprintTagsForOrg: Array<{
    __typename?: 'FootprintTag';
    id: string;
    tagName: string;
  }>;
  activityDataTables: {
    __typename?: 'ActivityDataTableConnection';
    edges: Array<{
      __typename?: 'ActivityDataTableEdge';
      node: {
        __typename?: 'ActivityDataTable';
        id: string;
        name: string;
      } | null;
    } | null>;
  };
};

export type GQGetPublicDisclosureIDsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetPublicDisclosureIDsQuery = {
  __typename?: 'Query';
  publicDisclosureIDs: Array<string> | null;
};

export type GQGetPrivateDisclosureIDsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetPrivateDisclosureIDsQuery = {
  __typename?: 'Query';
  privateDisclosureIDs: Array<string> | null;
};

export type GQCreateReferenceDataCitationMutationVariables = Exact<{
  input: GQCreateReferenceDataCitationInput;
}>;

export type GQCreateReferenceDataCitationMutation = {
  __typename?: 'Mutation';
  createReferenceDataCitation: {
    __typename?: 'CreateReferenceDataCitationPayload';
    referenceDataCitation: {
      __typename?: 'ReferenceDataCitation';
      id: string;
      displayName: string;
      slug: string;
      internalSourceUrl: string;
      internalSourceNotes: string;
      externalSourceUrl: string | null;
      externalSourceNotes: string | null;
      hasLicenseRestrictions: boolean;
      publicDisclosureId: string | null;
      privateDisclosureId: string | null;
      isCustomerProvided: boolean;
      isSupplierSpecific: boolean;
      createdAt: Date;
      updatedAt: Date;
      archivedAt: Date | null;
      isArchived: boolean;
      orgId: string | null;
      author: { __typename?: 'User'; id: string; name: string } | null;
      org: { __typename?: 'Organization'; id: string; name: string } | null;
    };
  };
};

export type GQUpdateReferenceDataCitationMutationVariables = Exact<{
  input: GQUpdateReferenceDataCitationInput;
}>;

export type GQUpdateReferenceDataCitationMutation = {
  __typename?: 'Mutation';
  updateReferenceDataCitation: {
    __typename?: 'UpdateReferenceDataCitationPayload';
    referenceDataCitation: {
      __typename?: 'ReferenceDataCitation';
      id: string;
      displayName: string;
      slug: string;
      internalSourceUrl: string;
      internalSourceNotes: string;
      externalSourceUrl: string | null;
      externalSourceNotes: string | null;
      hasLicenseRestrictions: boolean;
      publicDisclosureId: string | null;
      privateDisclosureId: string | null;
      isCustomerProvided: boolean;
      isSupplierSpecific: boolean;
      createdAt: Date;
      updatedAt: Date;
      archivedAt: Date | null;
      isArchived: boolean;
      orgId: string | null;
      author: { __typename?: 'User'; id: string; name: string } | null;
      org: { __typename?: 'Organization'; id: string; name: string } | null;
    };
  };
};

export type GQReferenceDataCitationFieldsFragment = {
  __typename?: 'ReferenceDataCitation';
  id: string;
  displayName: string;
  slug: string;
  internalSourceUrl: string;
  internalSourceNotes: string;
  externalSourceUrl: string | null;
  externalSourceNotes: string | null;
  hasLicenseRestrictions: boolean;
  publicDisclosureId: string | null;
  privateDisclosureId: string | null;
  isCustomerProvided: boolean;
  isSupplierSpecific: boolean;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date | null;
  isArchived: boolean;
  orgId: string | null;
  author: { __typename?: 'User'; id: string; name: string } | null;
  org: { __typename?: 'Organization'; id: string; name: string } | null;
};

export type GQReferenceDataCitationsQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
  searchString: InputMaybe<Scalars['String']['input']>;
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  includeSlugWithName: InputMaybe<Scalars['String']['input']>;
}>;

export type GQReferenceDataCitationsQuery = {
  __typename?: 'Query';
  referenceDataCitations: {
    __typename?: 'ReferenceDataCitationConnection';
    edges: Array<{
      __typename?: 'ReferenceDataCitationEdge';
      node: {
        __typename?: 'ReferenceDataCitation';
        id: string;
        displayName: string;
        slug: string;
        internalSourceUrl: string;
        internalSourceNotes: string;
        externalSourceUrl: string | null;
        externalSourceNotes: string | null;
        hasLicenseRestrictions: boolean;
        publicDisclosureId: string | null;
        privateDisclosureId: string | null;
        isCustomerProvided: boolean;
        isSupplierSpecific: boolean;
        createdAt: Date;
        updatedAt: Date;
        archivedAt: Date | null;
        isArchived: boolean;
        orgId: string | null;
        author: { __typename?: 'User'; id: string; name: string } | null;
        org: { __typename?: 'Organization'; id: string; name: string } | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type GQReferenceDataCitationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQReferenceDataCitationQuery = {
  __typename?: 'Query';
  referenceDataCitation: {
    __typename?: 'ReferenceDataCitation';
    id: string;
    displayName: string;
    slug: string;
    internalSourceUrl: string;
    internalSourceNotes: string;
    externalSourceUrl: string | null;
    externalSourceNotes: string | null;
    hasLicenseRestrictions: boolean;
    publicDisclosureId: string | null;
    privateDisclosureId: string | null;
    isCustomerProvided: boolean;
    isSupplierSpecific: boolean;
    createdAt: Date;
    updatedAt: Date;
    archivedAt: Date | null;
    isArchived: boolean;
    orgId: string | null;
    author: { __typename?: 'User'; id: string; name: string } | null;
    org: { __typename?: 'Organization'; id: string; name: string } | null;
  };
};

export type GQReferenceDataCitationSlugsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQReferenceDataCitationSlugsQuery = {
  __typename?: 'Query';
  referenceDataCitationSlugs: Array<string>;
};

export type GQReferenceDataVersionSchemaQueryVariables = Exact<{
  referenceDataVersionId: Scalars['ID']['input'];
}>;

export type GQReferenceDataVersionSchemaQuery = {
  __typename?: 'Query';
  referenceDataVersion: {
    __typename?: 'ReferenceDataVersion';
    id: string;
    versionName: string;
    latestSchema: {
      __typename?: 'ReferenceDataSchema';
      id: string;
      schemaJson: JSONSchema7;
    } | null;
  };
};

export type GQLatestReferenceDataRevisionForCompositeDataEditorQueryVariables =
  Exact<{
    referenceDataVersionId: Scalars['ID']['input'];
    orgId: InputMaybe<Scalars['ID']['input']>;
  }>;

export type GQLatestReferenceDataRevisionForCompositeDataEditorQuery = {
  __typename?: 'Query';
  latestReferenceDataRevisionForVersion: {
    __typename?: 'ReferenceDataRevision';
    id: string;
    createdAt: Date;
    kind: GQReferenceDataRevisionKind | null;
  } | null;
};

export type GQCompositeDataVersionEditorDataQueryVariables = Exact<{
  compositeDataVersionId: Scalars['ID']['input'];
  orgId: InputMaybe<Scalars['ID']['input']>;
  fetchOrgDetails: Scalars['Boolean']['input'];
}>;

export type GQCompositeDataVersionEditorDataQuery = {
  __typename?: 'Query';
  compositeDataVersion: {
    __typename?: 'ReferenceDataVersion';
    versionName: string;
    id: string;
    sourceId: string;
    versionVintage: Date;
    createdAt: Date;
    updatedAt: Date;
    archivedAt: Date | null;
    state: GQReferenceDataVersionState;
    hasPublishedRevision: boolean;
    canPublish: boolean;
    source: {
      __typename?: 'ReferenceDataSource';
      id: string;
      name: string;
      orgId: string | null;
    };
    referenceDataSqlRuns: Array<{
      __typename?: 'ReferenceDataSql';
      id: string;
      createdAt: Date;
      status: GQReferenceDataSqlStatus | null;
      sql: string;
      error: string | null;
      warnings: any | null;
      queryResultFileId: string | null;
      revisionId: string | null;
      signedParquetOutputUrl: string | null;
      signedParquetRawResultUrl: string | null;
      revisionState: GQReferenceDataRevisionState | null;
      revisionLastUpdatedDate: Date | null;
    }>;
    author: { __typename?: 'User'; id: string; name: string } | null;
    latestSchema: {
      __typename?: 'ReferenceDataSchema';
      id: string;
      schemaJson: JSONSchema7;
    } | null;
  };
  organization?: { __typename?: 'Organization'; id: string; name: string };
};

export type GQReferenceDataOverlayByRevisionIdQueryVariables = Exact<{
  referenceDataRevision: Scalars['ID']['input'];
}>;

export type GQReferenceDataOverlayByRevisionIdQuery = {
  __typename?: 'Query';
  referenceDataOverlayByRevisionId: {
    __typename?: 'ReferenceDataOverlay';
    id: string;
  } | null;
};

export type GQReferenceDataVersionsBySlugsIfExistsQueryVariables = Exact<{
  slugs: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GQReferenceDataVersionsBySlugsIfExistsQuery = {
  __typename?: 'Query';
  referenceDataVersionsBySlugsIfExists: Array<{
    __typename?: 'ReferenceDataVersionSlugOutput';
    slug: string;
    referenceDataVersion: {
      __typename?: 'ReferenceDataVersion';
      id: string;
      latestSignedParquetOutputUrl: string | null;
      latestSchema: {
        __typename?: 'ReferenceDataSchema';
        id: string;
        schemaJson: JSONSchema7;
      } | null;
    };
  } | null>;
};

export type GQReferenceDataDifferQueryVariables = Exact<{
  sourceRevisionId: Scalars['ID']['input'];
  targetRevisionId: Scalars['ID']['input'];
}>;

export type GQReferenceDataDifferQuery = {
  __typename?: 'Query';
  referenceDataDiffer: {
    __typename?: 'ReferenceDataDifferResult';
    fileMetadata: {
      __typename?: 'FileMetadata';
      id: string;
      signedParquetUrl: string;
    };
    sourceReferenceDataRevision: {
      __typename?: 'ReferenceDataRevision';
      id: string;
      revisionName: string;
      state: GQReferenceDataRevisionState;
      createdAt: Date;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
      } | null;
      version: {
        __typename?: 'ReferenceDataVersion';
        id: string;
        slug: string;
        sourceId: string;
      };
    };
    targetReferenceDataRevision: {
      __typename?: 'ReferenceDataRevision';
      id: string;
      revisionName: string;
      state: GQReferenceDataRevisionState;
      createdAt: Date;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
      } | null;
      version: {
        __typename?: 'ReferenceDataVersion';
        id: string;
        slug: string;
        sourceId: string;
      };
    };
  };
};

export type GQReferenceDataRevisionsForSourceByVersionQueryVariables = Exact<{
  sourceId: Scalars['ID']['input'];
  excludeArchived: Scalars['Boolean']['input'];
  includeDrafts: Scalars['Boolean']['input'];
}>;

export type GQReferenceDataRevisionsForSourceByVersionQuery = {
  __typename?: 'Query';
  referenceDataSource: {
    __typename?: 'ReferenceDataSource';
    id: string;
    referenceDataVersions: Array<{
      __typename?: 'ReferenceDataVersion';
      id: string;
      versionName: string;
      referenceDataRevisions: Array<{
        __typename?: 'ReferenceDataRevision';
        id: string;
        revisionName: string;
        createdAt: Date;
        state: GQReferenceDataRevisionState;
      }> | null;
    }>;
  };
};

export type GQUpdateReferenceDataRevisionMutationVariables = Exact<{
  input: GQUpdateReferenceDataRevisionInput;
}>;

export type GQUpdateReferenceDataRevisionMutation = {
  __typename?: 'Mutation';
  updateReferenceDataRevision: {
    __typename?: 'UpdateReferenceDataRevisionPayload';
    referenceDataRevision: {
      __typename?: 'ReferenceDataRevision';
      id: string;
      revisionName: string;
      internalSourceNotes: string | null;
    };
  };
};

export type GQLatestPublishedRevisionByRevisionIdOrNullQueryVariables = Exact<{
  revisionId: Scalars['ID']['input'];
}>;

export type GQLatestPublishedRevisionByRevisionIdOrNullQuery = {
  __typename?: 'Query';
  latestPublishedRevisionForRevisionIdOrNull: {
    __typename?: 'ReferenceDataRevision';
    id: string;
  } | null;
};

export type GQCreateReferenceDataSourceMutationVariables = Exact<{
  input: GQCreateReferenceDataSourceInput;
}>;

export type GQCreateReferenceDataSourceMutation = {
  __typename?: 'Mutation';
  createReferenceDataSource: {
    __typename?: 'CreateReferenceDataSourcePayload';
    referenceDataSource: { __typename?: 'ReferenceDataSource'; id: string };
  };
};

export type GQArchiveReferenceDataSourceMutationVariables = Exact<{
  input: GQArchiveReferenceDataSourceInput;
}>;

export type GQArchiveReferenceDataSourceMutation = {
  __typename?: 'Mutation';
  archiveReferenceDataSource: {
    __typename?: 'ArchiveReferenceDataSourcePayload';
    referenceDataSource: {
      __typename?: 'ReferenceDataSource';
      id: string;
      name: string;
      orgId: string | null;
      externalSourceUrl: string | null;
      externalSourceNotes: string | null;
      kind: GQReferenceDataKind | null;
      isCompositeData: boolean;
      createdAt: Date;
      updatedAt: Date;
      archivedAt: Date | null;
      isSyncedWithCliq: boolean;
      author: { __typename?: 'User'; id: string; name: string } | null;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
      } | null;
      tags: Array<{
        __typename?: 'CalculationTag';
        id: string;
        name: string;
      }> | null;
      assumptionSources: Array<{
        __typename?: 'AssumptionSource';
        id: string;
        name: string;
      }> | null;
    };
  } | null;
};

export type GQUpdateReferenceDataSourceMutationVariables = Exact<{
  input: GQUpdateReferenceDataSourceInput;
}>;

export type GQUpdateReferenceDataSourceMutation = {
  __typename?: 'Mutation';
  updateReferenceDataSource: {
    __typename?: 'UpdateReferenceDataSourcePayload';
    referenceDataSource: {
      __typename?: 'ReferenceDataSource';
      id: string;
      name: string;
      isSyncedWithCliq: boolean;
    };
  };
};

export type GQToggleCliqSyncForReferenceDataSourceMutationVariables = Exact<{
  input: GQToggleCliqSyncForReferenceDataSourceInput;
}>;

export type GQToggleCliqSyncForReferenceDataSourceMutation = {
  __typename?: 'Mutation';
  toggleCliqSyncForReferenceDataSource: {
    __typename?: 'ToggleCliqSyncForReferenceDataSourcePayload';
    cliqReferenceDataConnection: {
      __typename?: 'CliqReferenceDataConnection';
      id: string;
      isActive: boolean;
    };
  };
};

export type GQReferenceDataSourcesQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQReferenceDataSourcesQuery = {
  __typename?: 'Query';
  referenceDataSources: {
    __typename?: 'ReferenceDataSourceConnection';
    edges: Array<{
      __typename?: 'ReferenceDataSourceEdge';
      node: {
        __typename?: 'ReferenceDataSource';
        id: string;
        name: string;
        orgId: string | null;
        externalSourceUrl: string | null;
        externalSourceNotes: string | null;
        kind: GQReferenceDataKind | null;
        isCompositeData: boolean;
        createdAt: Date;
        updatedAt: Date;
        archivedAt: Date | null;
        isSyncedWithCliq: boolean;
        author: { __typename?: 'User'; id: string; name: string } | null;
        organization: {
          __typename?: 'Organization';
          id: string;
          name: string;
        } | null;
        tags: Array<{
          __typename?: 'CalculationTag';
          id: string;
          name: string;
        }> | null;
        assumptionSources: Array<{
          __typename?: 'AssumptionSource';
          id: string;
          name: string;
        }> | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
  } | null;
};

export type GQAddCalculationTagToReferenceDataSourceMutationVariables = Exact<{
  input: GQAddCalculationTagToReferenceDataInput;
}>;

export type GQAddCalculationTagToReferenceDataSourceMutation = {
  __typename?: 'Mutation';
  addCalculationTagToReferenceData: {
    __typename?: 'AddCalculationTagToReferenceDataPayload';
    success: boolean;
  };
};

export type GQRemoveCalculationTagFromReferenceDataSourceMutationVariables =
  Exact<{
    input: GQRemoveCalculationTagFromReferenceDataInput;
  }>;

export type GQRemoveCalculationTagFromReferenceDataSourceMutation = {
  __typename?: 'Mutation';
  removeCalculationTagFromReferenceData: {
    __typename?: 'RemoveCalculationTagFromReferenceDataPayload';
    success: boolean;
  };
};

export type GQCreateCalculationTagMutationVariables = Exact<{
  input: GQCreateCalculationTagInput;
}>;

export type GQCreateCalculationTagMutation = {
  __typename?: 'Mutation';
  createCalculationTag: {
    __typename?: 'CalculationTagPayload';
    tag: { __typename?: 'CalculationTag'; id: string; name: string };
  };
};

export type GQReferenceDataSqlForRevisionIdQueryVariables = Exact<{
  revisionId: Scalars['ID']['input'];
}>;

export type GQReferenceDataSqlForRevisionIdQuery = {
  __typename?: 'Query';
  referenceDataSqlForRevisionId: {
    __typename?: 'ReferenceDataSql';
    id: string;
    sql: string;
    queryResultFileId: string | null;
  } | null;
};

export type GQCreateReferenceDataVersionMutationVariables = Exact<{
  input: GQCreateReferenceDataVersionInput;
}>;

export type GQCreateReferenceDataVersionMutation = {
  __typename?: 'Mutation';
  createReferenceDataVersion: {
    __typename?: 'CreateReferenceDataVersionPayload';
    referenceDataVersion: {
      __typename?: 'ReferenceDataVersion';
      id: string;
      versionName: string;
    };
  };
};

export type GQReferenceDataVersionNamesForSourceQueryVariables = Exact<{
  sourceId: Scalars['ID']['input'];
}>;

export type GQReferenceDataVersionNamesForSourceQuery = {
  __typename?: 'Query';
  referenceDataSource: {
    __typename?: 'ReferenceDataSource';
    id: string;
    referenceDataVersions: Array<{
      __typename?: 'ReferenceDataVersion';
      id: string;
      versionName: string;
    }>;
  };
};

export type GQDependentEmissionsModelsForRdvQueryVariables = Exact<{
  referenceDataVersionId: Scalars['ID']['input'];
}>;

export type GQDependentEmissionsModelsForRdvQuery = {
  __typename?: 'Query';
  dependentEmissionsModelsForRdv: Array<{
    __typename?: 'EmissionsModelForRdv';
    stableId: string;
    stableName: string;
    isArchived: boolean;
    latestPublishedEmvId: string;
    latestPublishedEmvUsesRdv: boolean;
    latestPublishedEmvChangelog: string;
    latestPublishedEmvCreatedAt: Date;
  }> | null;
};

export type GQDependentEmissionsModelReleasesForRdvQueryVariables = Exact<{
  referenceDataVersionId: Scalars['ID']['input'];
}>;

export type GQDependentEmissionsModelReleasesForRdvQuery = {
  __typename?: 'Query';
  dependentEmissionsModelReleasesForRdv: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    updatedAt: Date;
    userAuthorId: string | null;
    displayName: string;
    businessActivityTypeName: string;
    internalNotes: string;
    externalNotes: string;
    orgId: string | null;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    isCustomToUserOrg: boolean | null;
    isOrgGated: boolean;
    user: { __typename?: 'User'; id: string; name: string } | null;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    } | null;
    latestCompatibleBatVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    orgAccess: Array<{
      __typename?: 'EmissionsModelReleaseOrgAccess';
      id: string;
      createdAt: Date;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    }>;
  }> | null;
};

export type GQDependentReferenceDataVersionsForRdvQueryVariables = Exact<{
  referenceDataVersionId: Scalars['ID']['input'];
}>;

export type GQDependentReferenceDataVersionsForRdvQuery = {
  __typename?: 'Query';
  dependentReferenceDataVersionsForRdv: Array<{
    __typename?: 'ReferenceDataVersionForRdv';
    rdvId: string;
    rdvName: string;
    rdsId: string;
    rdsName: string;
    rdvCreatedAt: Date;
    rdvUpdatedAt: Date;
    usingOutdatedRevision: boolean;
    rdvIsLatestPublishedVersion: boolean;
  }> | null;
};

export type GQCitationsForRdvQueryVariables = Exact<{
  versionId: Scalars['ID']['input'];
}>;

export type GQCitationsForRdvQuery = {
  __typename?: 'Query';
  referenceDataVersion: {
    __typename?: 'ReferenceDataVersion';
    id: string;
    citations: Array<{
      __typename?: 'ReferenceDataCitation';
      id: string;
      displayName: string;
      slug: string;
      isCustomerProvided: boolean;
      org: { __typename?: 'Organization'; id: string; name: string } | null;
    }>;
  };
};

export type GQUpdateReferenceDataVersionMutationVariables = Exact<{
  input: GQUpdateReferenceDataVersionInput;
}>;

export type GQUpdateReferenceDataVersionMutation = {
  __typename?: 'Mutation';
  updateReferenceDataVersion: {
    __typename?: 'UpdateReferenceDataVersionPayload';
    referenceDataVersion: {
      __typename?: 'ReferenceDataVersion';
      id: string;
      versionName: string;
      versionVintage: Date;
    };
  };
};

export type GQReferenceDataRevisionsQueryVariables = Exact<{
  referenceDataVersionId: Scalars['ID']['input'];
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
  filterOrgId: InputMaybe<Scalars['ID']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQReferenceDataRevisionsQuery = {
  __typename?: 'Query';
  referenceDataRevisionsByVersionId: {
    __typename?: 'ReferenceDataRevisionConnection';
    edges: Array<{
      __typename?: 'ReferenceDataRevisionEdge';
      node: {
        __typename?: 'ReferenceDataRevision';
        id: string;
        revisionName: string;
        updates: JSONSchema7;
        outputFileId: string;
        sourceFileId: string | null;
        internalSourceNotes: string | null;
        createdAt: Date;
        archivedAt: Date | null;
        signedParquetOutputUrl: string;
        signedSourceUrl: string | null;
        state: GQReferenceDataRevisionState;
        schemaJson: JSONSchema7 | null;
        kind: GQReferenceDataRevisionKind | null;
        schemaLastCheckedAgainst: string | null;
        lastCompatibleSchema: string | null;
        isLatestForOrgAndState: boolean;
        isOverlay: boolean | null;
        author: { __typename?: 'User'; id: string; name: string } | null;
        organization: {
          __typename?: 'Organization';
          id: string;
          name: string;
        } | null;
        assumptionSource: {
          __typename?: 'AssumptionSource';
          id: string;
        } | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
  };
};

export type GQLatestReferenceDataRevisionForVersionQueryVariables = Exact<{
  referenceDataVersionId: Scalars['ID']['input'];
}>;

export type GQLatestReferenceDataRevisionForVersionQuery = {
  __typename?: 'Query';
  latestReferenceDataRevisionForVersion: {
    __typename?: 'ReferenceDataRevision';
    id: string;
    revisionName: string;
    updates: JSONSchema7;
    outputFileId: string;
    sourceFileId: string | null;
    internalSourceNotes: string | null;
    createdAt: Date;
    archivedAt: Date | null;
    signedParquetOutputUrl: string;
    signedSourceUrl: string | null;
    state: GQReferenceDataRevisionState;
    schemaJson: JSONSchema7 | null;
    kind: GQReferenceDataRevisionKind | null;
    schemaLastCheckedAgainst: string | null;
    lastCompatibleSchema: string | null;
    isLatestForOrgAndState: boolean;
    isOverlay: boolean | null;
    author: { __typename?: 'User'; id: string; name: string } | null;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
    } | null;
    assumptionSource: { __typename?: 'AssumptionSource'; id: string } | null;
  } | null;
};

export type GQRunSchemaValidationForReferenceDataVersionMutationVariables =
  Exact<{
    input: GQRunSchemaValidationForReferenceDataVersionInput;
  }>;

export type GQRunSchemaValidationForReferenceDataVersionMutation = {
  __typename?: 'Mutation';
  runSchemaValidationForReferenceDataVersion: {
    __typename?: 'RunSchemaValidationForReferenceDataVersionPayload';
    workflowId: string;
  };
};

export type GQPublishReferenceDataVersionMutationVariables = Exact<{
  input: GQPublishReferenceDataVersionInput;
}>;

export type GQPublishReferenceDataVersionMutation = {
  __typename?: 'Mutation';
  publishReferenceDataVersion: {
    __typename?: 'PublishReferenceDataVersionPayload';
    referenceDataVersion: {
      __typename?: 'ReferenceDataVersion';
      id: string;
      sourceId: string;
      versionName: string;
      versionVintage: Date;
      createdAt: Date;
      updatedAt: Date;
      archivedAt: Date | null;
      state: GQReferenceDataVersionState;
      hasPublishedRevision: boolean;
      canPublish: boolean;
      author: { __typename?: 'User'; id: string; name: string } | null;
      latestSchema: {
        __typename?: 'ReferenceDataSchema';
        id: string;
        schemaJson: JSONSchema7;
      } | null;
    };
  };
};

export type GQArchiveReferenceDataVersionMutationVariables = Exact<{
  input: GQArchiveReferenceDataVersionInput;
}>;

export type GQArchiveReferenceDataVersionMutation = {
  __typename?: 'Mutation';
  archiveReferenceDataVersion: {
    __typename?: 'ArchiveReferenceDataVersionPayload';
    referenceDataVersion: {
      __typename?: 'ReferenceDataVersion';
      id: string;
      sourceId: string;
      versionName: string;
      versionVintage: Date;
      createdAt: Date;
      updatedAt: Date;
      archivedAt: Date | null;
      state: GQReferenceDataVersionState;
      hasPublishedRevision: boolean;
      canPublish: boolean;
      author: { __typename?: 'User'; id: string; name: string } | null;
      latestSchema: {
        __typename?: 'ReferenceDataSchema';
        id: string;
        schemaJson: JSONSchema7;
      } | null;
    };
  } | null;
};

export type GQArchiveReferenceDataRevisionMutationVariables = Exact<{
  input: GQArchiveReferenceDataRevisionInput;
}>;

export type GQArchiveReferenceDataRevisionMutation = {
  __typename?: 'Mutation';
  archiveReferenceDataRevision: {
    __typename?: 'ArchiveReferenceDataRevisionPayload';
    referenceDataRevision: {
      __typename?: 'ReferenceDataRevision';
      id: string;
      revisionName: string;
      updates: JSONSchema7;
      outputFileId: string;
      sourceFileId: string | null;
      internalSourceNotes: string | null;
      createdAt: Date;
      archivedAt: Date | null;
      signedParquetOutputUrl: string;
      signedSourceUrl: string | null;
      state: GQReferenceDataRevisionState;
      schemaJson: JSONSchema7 | null;
      kind: GQReferenceDataRevisionKind | null;
      schemaLastCheckedAgainst: string | null;
      lastCompatibleSchema: string | null;
      isLatestForOrgAndState: boolean;
      isOverlay: boolean | null;
      author: { __typename?: 'User'; id: string; name: string } | null;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
      } | null;
      assumptionSource: { __typename?: 'AssumptionSource'; id: string } | null;
    };
  } | null;
};

export type GQRevisionForSafePublicationQueryVariables = Exact<{
  revisionId: Scalars['ID']['input'];
}>;

export type GQRevisionForSafePublicationQuery = {
  __typename?: 'Query';
  referenceDataRevision: {
    __typename?: 'ReferenceDataRevision';
    id: string;
    signedParquetOutputUrl: string;
    outputFileId: string;
  };
  latestPublishedRevision: {
    __typename?: 'ReferenceDataRevision';
    id: string;
  } | null;
};

export type GQReviewPrepublicationTestResultsForReferenceDataRevisionMutationVariables =
  Exact<{
    input: GQReviewPrepublicationTestResultsInput;
  }>;

export type GQReviewPrepublicationTestResultsForReferenceDataRevisionMutation =
  {
    __typename?: 'Mutation';
    reviewResult: {
      __typename?: 'ReviewPrepublicationTestResultsForReferenceDataRevisionPayload';
      outcome: GQMeasurementResourcePublishWorkflowOutcome;
      errorCode: GQMeasurementResourcePublicationErrorCode | null;
      publishedReferenceDataRevision: {
        __typename?: 'ReferenceDataRevision';
        id: string;
      } | null;
    };
  };

export type GQSchemaUpdateValidationWarningsQueryVariables = Exact<{
  referenceDataVersionId: Scalars['ID']['input'];
  schemaJson: Scalars['JSONSchema']['input'];
  orgId: InputMaybe<Scalars['String']['input']>;
}>;

export type GQSchemaUpdateValidationWarningsQuery = {
  __typename?: 'Query';
  validateUpdateReferenceDataVersionSchema: {
    __typename?: 'ReferenceDataSchemaUpdateValidation';
    warnings: Array<string>;
    errors: Array<string>;
  };
};

export type GQUpdateReferenceDataVersionSchemaMutationVariables = Exact<{
  input: GQUpdateReferenceDataVersionSchemaInput;
}>;

export type GQUpdateReferenceDataVersionSchemaMutation = {
  __typename?: 'Mutation';
  updateReferenceDataVersionSchema: {
    __typename?: 'UpdateReferenceDataVersionSchemaPayload';
    referenceDataVersion: {
      __typename?: 'ReferenceDataVersion';
      id: string;
      latestSchema: {
        __typename?: 'ReferenceDataSchema';
        id: string;
        schemaJson: JSONSchema7;
      } | null;
    };
  };
};

export type GQUpstreamReferenceDataForVersionQueryVariables = Exact<{
  versionId: Scalars['ID']['input'];
}>;

export type GQUpstreamReferenceDataForVersionQuery = {
  __typename?: 'Query';
  referenceDataVersion: {
    __typename?: 'ReferenceDataVersion';
    id: string;
    slug: string;
    upstreamReferenceDataVersions: Array<{
      __typename?: 'ReferenceDataVersion';
      id: string;
    }>;
    source: { __typename?: 'ReferenceDataSource'; id: string; name: string };
  };
};

export type GQUpstreamReferenceDataSlugsForRevisionQueryVariables = Exact<{
  revisionId: Scalars['ID']['input'];
}>;

export type GQUpstreamReferenceDataSlugsForRevisionQuery = {
  __typename?: 'Query';
  referenceDataRevision: {
    __typename?: 'ReferenceDataRevision';
    id: string;
    upstreamReferenceDataVersions: Array<{
      __typename?: 'ReferenceDataVersion';
      id: string;
    }>;
  };
};

export type GQCreateReferenceDataOverlayTemplateMutationVariables = Exact<{
  input: GQCreateReferenceDataOverlayTemplateInput;
}>;

export type GQCreateReferenceDataOverlayTemplateMutation = {
  __typename?: 'Mutation';
  createReferenceDataOverlayTemplate: {
    __typename?: 'CreateReferenceDataOverlayTemplatePayload';
    oneSchemaTemplateKey: string;
  } | null;
};

export type GQStartPrepublicationTestsForReferenceDataRevisionMutationVariables =
  Exact<{
    input: GQStartPrepublicationTestsForReferenceDataRevisionInput;
  }>;

export type GQStartPrepublicationTestsForReferenceDataRevisionMutation = {
  __typename?: 'Mutation';
  startPrepublicationTestsForReferenceDataRevision: {
    __typename?: 'StartPrepublicationTestsPayload';
    workflowId: string;
  };
};

export type GQRenameCustomEmissionsFactorTaskMutationVariables = Exact<{
  input: GQRenameCustomEmissionsFactorSetupTaskInput;
}>;

export type GQRenameCustomEmissionsFactorTaskMutation = {
  __typename?: 'Mutation';
  renameCustomEmissionsFactorSetupTask: {
    __typename?: 'RenameCustomEmissionsFactorSetupTaskPayload';
    taskId: string;
  };
};

export type GQSetFootprintVersionKindMutationVariables = Exact<{
  input: GQSetFootprintVersionKindInput;
}>;

export type GQSetFootprintVersionKindMutation = {
  __typename?: 'Mutation';
  setFootprintVersionKind: {
    __typename?: 'SetFootprintVersionKindPayload';
    footprintVersion: { __typename?: 'FootprintVersion'; id: string };
  } | null;
};

export type GQShareFilesMutationVariables = Exact<{
  input: GQShareFilesInput;
}>;

export type GQShareFilesMutation = {
  __typename?: 'Mutation';
  shareFiles: {
    __typename?: 'ShareFilesPayload';
    sharedFiles: Array<{ __typename?: 'SharedFile'; id: string }>;
  } | null;
};

export type GQUserUploadForShareFilesFragment = {
  __typename?: 'UserUpload';
  id: string;
  name: string;
  deletedAt: Date | null;
};

export type GQMeasurementProjectForShareFilesFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  coverageStartDate: Date;
  userUploadTasks: {
    __typename?: 'UserUploadTaskConnection';
    edges: Array<{
      __typename?: 'UserUploadTaskEdge';
      node: {
        __typename?: 'UserUploadTask';
        id: string;
        datasource: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: { __typename?: 'Dataset'; id: string; name: string };
        };
        userUploads: {
          __typename?: 'UserUploadConnection';
          edges: Array<{
            __typename?: 'UserUploadEdge';
            node: {
              __typename?: 'UserUpload';
              id: string;
              name: string;
              deletedAt: Date | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  };
};

export type GQDatasourceForShareFilesFragment = {
  __typename?: 'Datasource';
  id: string;
  name: string;
  dataset: { __typename?: 'Dataset'; id: string; name: string };
};

export type GQMeasurementProjectForShareFilesQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQMeasurementProjectForShareFilesQuery = {
  __typename?: 'Query';
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    coverageStartDate: Date;
    userUploadTasks: {
      __typename?: 'UserUploadTaskConnection';
      edges: Array<{
        __typename?: 'UserUploadTaskEdge';
        node: {
          __typename?: 'UserUploadTask';
          id: string;
          datasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: { __typename?: 'Dataset'; id: string; name: string };
          };
          userUploads: {
            __typename?: 'UserUploadConnection';
            edges: Array<{
              __typename?: 'UserUploadEdge';
              node: {
                __typename?: 'UserUpload';
                id: string;
                name: string;
                deletedAt: Date | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
  };
};

export type GQSharedFileDialogQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQSharedFileDialogQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      coverageStartDate: Date;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: { __typename?: 'Dataset'; id: string; name: string };
            };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: {
                  __typename?: 'UserUpload';
                  id: string;
                  name: string;
                  deletedAt: Date | null;
                } | null;
              } | null>;
            } | null;
          } | null;
        } | null>;
      };
    }>;
  };
};

type GQSupplierChartConfigFieldsAdmin_SupplierEmissionsForecastChartConfig_Fragment =
  {
    __typename?: 'SupplierEmissionsForecastChartConfig';
    id: string;
    orgId: string | null;
    title: string;
    description: string | null;
    chartType: GQSupplierChartType;
  };

type GQSupplierChartConfigFieldsAdmin_SupplierEngagementFunnelChartConfig_Fragment =
  {
    __typename?: 'SupplierEngagementFunnelChartConfig';
    id: string;
    orgId: string | null;
    title: string;
    description: string | null;
    chartType: GQSupplierChartType;
    funnels: Array<{ __typename?: 'EngagementFunnel'; id: string }>;
  };

type GQSupplierChartConfigFieldsAdmin_SupplierMetricsChartConfig_Fragment = {
  __typename?: 'SupplierMetricsChartConfig';
  metricsTypes: Array<GQSupplyChainMetricType>;
  id: string;
  orgId: string | null;
  title: string;
  description: string | null;
  chartType: GQSupplierChartType;
};

type GQSupplierChartConfigFieldsAdmin_SupplierSbtCommitmentOverTimeChartConfig_Fragment =
  {
    __typename?: 'SupplierSbtCommitmentOverTimeChartConfig';
    colorScale: Array<string>;
    id: string;
    orgId: string | null;
    title: string;
    description: string | null;
    chartType: GQSupplierChartType;
  };

type GQSupplierChartConfigFieldsAdmin_SupplierStandardChartConfig_Fragment = {
  __typename?: 'SupplierStandardChartConfig';
  colorScale: Array<string>;
  xField: string;
  aggregateType: GQSupplierChartAggregateType;
  id: string;
  orgId: string | null;
  title: string;
  description: string | null;
  chartType: GQSupplierChartType;
};

type GQSupplierChartConfigFieldsAdmin_SupplierTaskStatusChangeOverTimeChartConfig_Fragment =
  {
    __typename?: 'SupplierTaskStatusChangeOverTimeChartConfig';
    colorScale: Array<string>;
    isCumulative: boolean | null;
    id: string;
    orgId: string | null;
    title: string;
    description: string | null;
    chartType: GQSupplierChartType;
    tasksStatusChangeConfig: Array<{
      __typename?: 'SurveyDefinitionAndStatus';
      status: string;
      engagementTaskConfigId: string;
    }> | null;
  };

export type GQSupplierChartConfigFieldsAdminFragment =
  | GQSupplierChartConfigFieldsAdmin_SupplierEmissionsForecastChartConfig_Fragment
  | GQSupplierChartConfigFieldsAdmin_SupplierEngagementFunnelChartConfig_Fragment
  | GQSupplierChartConfigFieldsAdmin_SupplierMetricsChartConfig_Fragment
  | GQSupplierChartConfigFieldsAdmin_SupplierSbtCommitmentOverTimeChartConfig_Fragment
  | GQSupplierChartConfigFieldsAdmin_SupplierStandardChartConfig_Fragment
  | GQSupplierChartConfigFieldsAdmin_SupplierTaskStatusChangeOverTimeChartConfig_Fragment;

export type GQCreateSupplierChartAdminMutationVariables = Exact<{
  input: GQCreateSupplierChartInputAdmin;
}>;

export type GQCreateSupplierChartAdminMutation = {
  __typename?: 'Mutation';
  createSupplierChartAdmin: {
    __typename?: 'CreateSupplierChartPayloadAdmin';
    chart:
      | { __typename?: 'SupplierEmissionsForecastChartConfig'; id: string }
      | { __typename?: 'SupplierEngagementFunnelChartConfig'; id: string }
      | { __typename?: 'SupplierMetricsChartConfig'; id: string }
      | { __typename?: 'SupplierSbtCommitmentOverTimeChartConfig'; id: string }
      | { __typename?: 'SupplierStandardChartConfig'; id: string }
      | {
          __typename?: 'SupplierTaskStatusChangeOverTimeChartConfig';
          id: string;
        };
    views: Array<{
      __typename?: 'SupplierView';
      id: string;
      charts: Array<
        | {
            __typename?: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename?: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{ __typename?: 'EngagementFunnel'; id: string }>;
          }
        | {
            __typename?: 'SupplierMetricsChartConfig';
            metricsTypes: Array<GQSupplyChainMetricType>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename?: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename?: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename?: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
    }>;
  } | null;
};

export type GQUpdateSupplierChartAdminMutationVariables = Exact<{
  input: GQUpdateSupplierChartInputAdmin;
}>;

export type GQUpdateSupplierChartAdminMutation = {
  __typename?: 'Mutation';
  updateSupplierChartAdmin: {
    __typename?: 'UpdateSupplierChartPayloadAdmin';
    views: Array<{
      __typename?: 'SupplierView';
      id: string;
      charts: Array<
        | {
            __typename?: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename?: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{ __typename?: 'EngagementFunnel'; id: string }>;
          }
        | {
            __typename?: 'SupplierMetricsChartConfig';
            metricsTypes: Array<GQSupplyChainMetricType>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename?: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename?: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename?: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
    }>;
  } | null;
};

export type GQDeleteSupplierChartAdminMutationVariables = Exact<{
  input: GQDeleteSupplierChartInputAdmin;
}>;

export type GQDeleteSupplierChartAdminMutation = {
  __typename?: 'Mutation';
  deleteSupplierChartAdmin: {
    __typename?: 'DeleteSupplierChartPayloadAdmin';
    views: Array<{
      __typename?: 'SupplierView';
      id: string;
      charts: Array<
        | {
            __typename?: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename?: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{ __typename?: 'EngagementFunnel'; id: string }>;
          }
        | {
            __typename?: 'SupplierMetricsChartConfig';
            metricsTypes: Array<GQSupplyChainMetricType>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename?: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename?: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename?: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
    }>;
  } | null;
};

export type GQGetSupplierColumnsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetSupplierColumnsQuery = {
  __typename?: 'Query';
  supplierTableColumns: Array<{
    __typename?: 'SupplierTableColumn';
    id: string;
    field: string;
    headerName: string | null;
  }>;
};

export type GQSupplierColumnFieldsAdminFragment = {
  __typename?: 'SupplierTableColumn';
  id: string;
  field: string;
  headerName: string | null;
};

export type GQDeleteEngagementTaskMutationVariables = Exact<{
  input: GQDeleteEngagementTasksAndAnswersByCrossOrgModelIdInput;
}>;

export type GQDeleteEngagementTaskMutation = {
  __typename?: 'Mutation';
  deleteEngagementTasksAndAnswersByCrossOrgModelId: {
    __typename?: 'DeleteEngagementTasksAndAnswersByCrossOrgModelIdPayload';
    engagementTasks: Array<{
      __typename?: 'EngagementTask';
      id: string;
      deletedAt: Date | null;
    }>;
  } | null;
};

export type GQTargetForTargetsFormFragment = {
  __typename?: 'Target';
  id: string;
  title: string;
  descriptor: string | null;
  kind: GQTargetKind;
  unit: GQTargetUnit;
  targetAmount: number;
};

export type GQOrganizationForTargetsFormFragment = {
  __typename?: 'Organization';
  id: string;
  targets: {
    __typename?: 'TargetConnection';
    edges: Array<{
      __typename?: 'TargetEdge';
      node: {
        __typename?: 'Target';
        id: string;
        title: string;
        descriptor: string | null;
        kind: GQTargetKind;
        unit: GQTargetUnit;
        targetAmount: number;
      } | null;
    } | null>;
  };
};

export type GQCreateTargetMutationVariables = Exact<{
  input: GQCreateTargetInput;
}>;

export type GQCreateTargetMutation = {
  __typename?: 'Mutation';
  createTarget: {
    __typename?: 'CreateTargetPayload';
    org: {
      __typename?: 'Organization';
      id: string;
      targets: {
        __typename?: 'TargetConnection';
        edges: Array<{
          __typename?: 'TargetEdge';
          node: {
            __typename?: 'Target';
            id: string;
            title: string;
            descriptor: string | null;
            kind: GQTargetKind;
            unit: GQTargetUnit;
            targetAmount: number;
          } | null;
        } | null>;
      };
    };
  };
};

export type GQUpdateTargetMutationVariables = Exact<{
  input: GQUpdateTargetInput;
}>;

export type GQUpdateTargetMutation = {
  __typename?: 'Mutation';
  updateTarget: {
    __typename?: 'UpdateTargetPayload';
    target: {
      __typename?: 'Target';
      id: string;
      title: string;
      descriptor: string | null;
      kind: GQTargetKind;
      unit: GQTargetUnit;
      targetAmount: number;
    };
  };
};

export type GQDeleteTargetMutationVariables = Exact<{
  input: GQDeleteTargetInput;
}>;

export type GQDeleteTargetMutation = {
  __typename?: 'Mutation';
  deleteTarget: {
    __typename?: 'DeleteTargetPayload';
    org: {
      __typename?: 'Organization';
      id: string;
      targets: {
        __typename?: 'TargetConnection';
        edges: Array<{
          __typename?: 'TargetEdge';
          node: {
            __typename?: 'Target';
            id: string;
            title: string;
            descriptor: string | null;
            kind: GQTargetKind;
            unit: GQTargetUnit;
            targetAmount: number;
          } | null;
        } | null>;
      };
    };
  };
};

export type GQGetUserUploadedTableByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQGetUserUploadedTableByIdsQuery = {
  __typename?: 'Query';
  userUploadedTablesById: Array<{
    __typename?: 'UserUploadedTable';
    id: string;
    sqlTableName: string | null;
    shouldIgnore: boolean;
    userVisibleAttempt: {
      __typename?: 'UserUploadAttempt';
      id: string;
      userUpload: {
        __typename?: 'UserUpload';
        id: string;
        name: string;
        userUploadTask: {
          __typename?: 'UserUploadTask';
          id: string;
          datasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: { __typename?: 'Dataset'; id: string };
          };
        } | null;
      };
    } | null;
  }>;
};

export type GQUpdateFootprintVersionChangelogMutationVariables = Exact<{
  input: GQUpdateFootprintVersionInput;
}>;

export type GQUpdateFootprintVersionChangelogMutation = {
  __typename?: 'Mutation';
  updateFootprintVersion: {
    __typename?: 'UpdateFootprintVersionPayload';
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      versionName: string | null;
      userChangelogMd: string | null;
    };
  } | null;
};

export type GQUpdateOrgPointOfContactMutationVariables = Exact<{
  input: GQUpdateOrgPointOfContactInput;
}>;

export type GQUpdateOrgPointOfContactMutation = {
  __typename?: 'Mutation';
  updateOrgPointOfContact: {
    __typename?: 'UpdateOrgPointOfContactPayload';
    org: {
      __typename?: 'Organization';
      id: string;
      pointsOfContact: Array<{
        __typename?: 'OrgPointOfContact';
        id: string;
        kind: GQOrgPointOfContactKind;
      }>;
    };
  } | null;
};

export type GQUpdateUserNameAdminVersionMutationVariables = Exact<{
  input: GQUpdateUserNameAdminVersionInput;
}>;

export type GQUpdateUserNameAdminVersionMutation = {
  __typename?: 'Mutation';
  updateUserNameAdminVersion: {
    __typename?: 'UpdateUserNameAdminVersionPayload';
    user: { __typename?: 'User'; id: string; name: string };
  } | null;
};

export type GQUpdateUserEmailAdminVersionMutationVariables = Exact<{
  input: GQUpdateUserEmailAdminVersionInput;
}>;

export type GQUpdateUserEmailAdminVersionMutation = {
  __typename?: 'Mutation';
  updateUserEmailAdminVersion: {
    __typename?: 'UpdateUserEmailAdminVersionPayload';
    user: { __typename?: 'User'; id: string; email: string };
  } | null;
};

export type GQUserUploadFieldsForTaskTableFragment = {
  __typename?: 'UserUpload';
  id: string;
  name: string;
  deletedAt: Date | null;
  allAttempts: Array<{
    __typename?: 'UserUploadAttempt';
    id: string;
    createdAt: Date;
    size: number;
    gcloudStorageUrl: string;
    deletedAt: Date | null;
    uploader: { __typename?: 'User'; id: string; name: string } | null;
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      sqlTableName: string | null;
      shouldIgnore: boolean;
    }>;
  }>;
  latestAttempt: {
    __typename?: 'UserUploadAttempt';
    id: string;
    name: string;
    gcloudStorageUrl: string;
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      shouldIgnore: boolean;
    }>;
  };
};

export type GQUserUploadTaskUploadsFieldsFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  state: GQUserUploadTaskState;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    coverageInterval: YMInterval;
  };
  userUploads: {
    __typename?: 'UserUploadConnection';
    edges: Array<{
      __typename?: 'UserUploadEdge';
      node: {
        __typename?: 'UserUpload';
        id: string;
        name: string;
        deletedAt: Date | null;
        allAttempts: Array<{
          __typename?: 'UserUploadAttempt';
          id: string;
          createdAt: Date;
          size: number;
          gcloudStorageUrl: string;
          deletedAt: Date | null;
          uploader: { __typename?: 'User'; id: string; name: string } | null;
          userUploadedTables: Array<{
            __typename?: 'UserUploadedTable';
            id: string;
            sqlTableName: string | null;
            shouldIgnore: boolean;
          }>;
        }>;
        latestAttempt: {
          __typename?: 'UserUploadAttempt';
          id: string;
          name: string;
          gcloudStorageUrl: string;
          userUploadedTables: Array<{
            __typename?: 'UserUploadedTable';
            id: string;
            shouldIgnore: boolean;
          }>;
        };
      } | null;
    } | null>;
  } | null;
};

export type GQUserUploadsForAdtSelectorDialogQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  datasourceIds: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
}>;

export type GQUserUploadsForAdtSelectorDialogQuery = {
  __typename?: 'Query';
  userUploads: {
    __typename?: 'UserUploadConnection';
    edges: Array<{
      __typename?: 'UserUploadEdge';
      node: {
        __typename: 'UserUpload';
        id: string;
        name: string;
        userUploadTask: {
          __typename?: 'UserUploadTask';
          id: string;
          datasource: { __typename?: 'Datasource'; id: string; name: string };
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
          };
        } | null;
        userVisibleAttempt: {
          __typename?: 'UserUploadAttempt';
          id: string;
          description: string | null;
        } | null;
        allAttempts: Array<{
          __typename: 'UserUploadAttempt';
          id: string;
          name: string;
          createdAt: Date;
          size: number;
          gcloudStorageUrl: string;
          deletedAt: Date | null;
          uploader: {
            __typename?: 'User';
            id: string;
            name: string;
            email: string;
          } | null;
          userUploadedTables: Array<{
            __typename: 'UserUploadedTable';
            id: string;
            error: string | null;
            errorType: string | null;
            sqlTableName: string | null;
            status: GQUserUploadedTableStatus;
            shouldIgnore: boolean;
            isUsingLatestUIR: boolean;
            integrationDataPull: {
              __typename?: 'IntegrationDataPull';
              id: string;
              integrationConnection: {
                __typename?: 'IntegrationConnection';
                id: string;
              };
            } | null;
            parentFileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              category: GQFileCategory;
              userUploadTask: {
                __typename?: 'UserUploadTask';
                id: string;
                state: GQUserUploadTaskState;
              } | null;
            };
          }>;
        }>;
      } | null;
    } | null>;
  };
};

export type GQUserUploadedTableForAdtSelectorDialogFragment = {
  __typename: 'UserUploadedTable';
  id: string;
  error: string | null;
  errorType: string | null;
  sqlTableName: string | null;
  status: GQUserUploadedTableStatus;
  shouldIgnore: boolean;
  isUsingLatestUIR: boolean;
  integrationDataPull: {
    __typename?: 'IntegrationDataPull';
    id: string;
    integrationConnection: { __typename?: 'IntegrationConnection'; id: string };
  } | null;
  parentFileMetadata: {
    __typename?: 'FileMetadata';
    id: string;
    category: GQFileCategory;
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
    } | null;
  };
};

export type GQUserUploadAttemptForAdtSelectorDialogFragment = {
  __typename: 'UserUploadAttempt';
  id: string;
  name: string;
  createdAt: Date;
  size: number;
  gcloudStorageUrl: string;
  deletedAt: Date | null;
  uploader: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
  } | null;
  userUploadedTables: Array<{
    __typename: 'UserUploadedTable';
    id: string;
    error: string | null;
    errorType: string | null;
    sqlTableName: string | null;
    status: GQUserUploadedTableStatus;
    shouldIgnore: boolean;
    isUsingLatestUIR: boolean;
    integrationDataPull: {
      __typename?: 'IntegrationDataPull';
      id: string;
      integrationConnection: {
        __typename?: 'IntegrationConnection';
        id: string;
      };
    } | null;
    parentFileMetadata: {
      __typename?: 'FileMetadata';
      id: string;
      category: GQFileCategory;
      userUploadTask: {
        __typename?: 'UserUploadTask';
        id: string;
        state: GQUserUploadTaskState;
      } | null;
    };
  }>;
};

export type GQUserUploadForAdtSelectorDialogFragment = {
  __typename: 'UserUpload';
  id: string;
  name: string;
  userUploadTask: {
    __typename?: 'UserUploadTask';
    id: string;
    datasource: { __typename?: 'Datasource'; id: string; name: string };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
    };
  } | null;
  userVisibleAttempt: {
    __typename?: 'UserUploadAttempt';
    id: string;
    description: string | null;
  } | null;
  allAttempts: Array<{
    __typename: 'UserUploadAttempt';
    id: string;
    name: string;
    createdAt: Date;
    size: number;
    gcloudStorageUrl: string;
    deletedAt: Date | null;
    uploader: {
      __typename?: 'User';
      id: string;
      name: string;
      email: string;
    } | null;
    userUploadedTables: Array<{
      __typename: 'UserUploadedTable';
      id: string;
      error: string | null;
      errorType: string | null;
      sqlTableName: string | null;
      status: GQUserUploadedTableStatus;
      shouldIgnore: boolean;
      isUsingLatestUIR: boolean;
      integrationDataPull: {
        __typename?: 'IntegrationDataPull';
        id: string;
        integrationConnection: {
          __typename?: 'IntegrationConnection';
          id: string;
        };
      } | null;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        category: GQFileCategory;
        userUploadTask: {
          __typename?: 'UserUploadTask';
          id: string;
          state: GQUserUploadTaskState;
        } | null;
      };
    }>;
  }>;
};

export type GQRemoveUserAdminVersionMutationVariables = Exact<{
  input: GQRemoveUserAdminVersionInput;
}>;

export type GQRemoveUserAdminVersionMutation = {
  __typename?: 'Mutation';
  removeUserAdminVersion: {
    __typename?: 'RemoveUserAdminVersionPayload';
    user: {
      __typename?: 'User';
      id: string;
      email: string;
      deletedAt: Date | null;
    };
  } | null;
};

export type GQResetLockedUserAdminVersionMutationVariables = Exact<{
  input: GQResetLockedUserAdminVersionInput;
}>;

export type GQResetLockedUserAdminVersionMutation = {
  __typename?: 'Mutation';
  resetLockedUserAdminVersion: {
    __typename?: 'ResetLockedUserAdminVersionPayload';
    user: { __typename?: 'User'; id: string; consecutiveLoginFailures: number };
  } | null;
};

export type GQWatershedEmployeeDropdownFieldQueryVariables = Exact<{
  orgPointOfContactKind: InputMaybe<GQOrgPointOfContactKind>;
}>;

export type GQWatershedEmployeeDropdownFieldQuery = {
  __typename?: 'Query';
  watershedEmployees: {
    __typename?: 'WatershedEmployeeConnection';
    edges: Array<{
      __typename?: 'WatershedEmployeeEdge';
      node: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
        user: { __typename?: 'User'; id: string };
      } | null;
    } | null>;
  };
};

export type GQCreateCanonicalClimateProgramProjectMutationVariables = Exact<{
  input: GQCreateCanonicalClimateProgramProjectInput;
}>;

export type GQCreateCanonicalClimateProgramProjectMutation = {
  __typename?: 'Mutation';
  createCanonicalClimateProgramProject: {
    __typename?: 'CanonicalClimateProgramProjectPayload';
    canonicalClimateProgramProject: {
      __typename?: 'CanonicalClimateProgramProject';
      id: string;
      name: string;
      description: string;
      hidden: boolean | null;
      hiddenFromMeasurement: boolean | null;
      kind: GQClimateProgramProjectKind;
      timeEstimate: string | null;
      costEstimate: string | null;
      iconTypeOverride: GQClimateProgramProjectIconType | null;
      iconType: GQClimateProgramProjectIconType;
      measurementObjectCopy: string | null;
      matchObjectType: string | null;
      commitmentType: string | null;
      reportKind: string | null;
      routeType: string | null;
      datasetRequirements: Array<{
        __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
        id: string;
        additionalNotesMd: string | null;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          name: string;
        };
      }>;
      ghgpRequirements: Array<{
        __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
        id: string;
        ghgScope: number;
        ghgScope3Category: number | null;
        minimumFidelity: GQClimateProgramProjectRequirementFidelity;
        additionalNotesMd: string | null;
      }>;
    };
  };
};

export type GQCreateCanonicalClimateProgramProjectRequirementDatasetMutationVariables =
  Exact<{
    input: GQCreateCanonicalClimateProgramProjectRequirementDatasetInput;
  }>;

export type GQCreateCanonicalClimateProgramProjectRequirementDatasetMutation = {
  __typename?: 'Mutation';
  createCanonicalClimateProgramProjectRequirementDataset: {
    __typename?: 'CanonicalClimateProgramProjectRequirementDatasetPayload';
    canonicalClimateProgramProject: {
      __typename?: 'CanonicalClimateProgramProject';
      id: string;
      datasetRequirements: Array<{
        __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
        id: string;
        additionalNotesMd: string | null;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          name: string;
        };
      }>;
    };
    canonicalClimateProgramProjectRequirementDataset: {
      __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
      id: string;
      additionalNotesMd: string | null;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      };
    };
  };
};

export type GQCanonicalClimateProgramProjectRequirementGhgpFieldsFragment = {
  __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
  id: string;
  ghgScope: number;
  ghgScope3Category: number | null;
  minimumFidelity: GQClimateProgramProjectRequirementFidelity;
  additionalNotesMd: string | null;
};

export type GQCreateCanonicalClimateProgramProjectRequirementGhgpMutationVariables =
  Exact<{
    input: GQCreateCanonicalClimateProgramProjectRequirementGhgpInput;
  }>;

export type GQCreateCanonicalClimateProgramProjectRequirementGhgpMutation = {
  __typename?: 'Mutation';
  createCanonicalClimateProgramProjectRequirementGhgp: {
    __typename?: 'CanonicalClimateProgramProjectRequirementGhgpPayload';
    canonicalClimateProgramProject: {
      __typename?: 'CanonicalClimateProgramProject';
      id: string;
      ghgpRequirements: Array<{
        __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
        id: string;
        ghgScope: number;
        ghgScope3Category: number | null;
        minimumFidelity: GQClimateProgramProjectRequirementFidelity;
        additionalNotesMd: string | null;
      }>;
    };
    canonicalClimateProgramProjectRequirementGhgp: {
      __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
      id: string;
      ghgScope: number;
      ghgScope3Category: number | null;
      minimumFidelity: GQClimateProgramProjectRequirementFidelity;
      additionalNotesMd: string | null;
    };
  };
};

export type GQUpdateCanonicalClimateProgramProjectMutationVariables = Exact<{
  input: GQUpdateCanonicalClimateProgramProjectInput;
}>;

export type GQUpdateCanonicalClimateProgramProjectMutation = {
  __typename?: 'Mutation';
  updateCanonicalClimateProgramProject: {
    __typename?: 'CanonicalClimateProgramProjectPayload';
    canonicalClimateProgramProject: {
      __typename?: 'CanonicalClimateProgramProject';
      id: string;
      name: string;
      description: string;
      hidden: boolean | null;
      hiddenFromMeasurement: boolean | null;
      kind: GQClimateProgramProjectKind;
      timeEstimate: string | null;
      costEstimate: string | null;
      iconTypeOverride: GQClimateProgramProjectIconType | null;
      iconType: GQClimateProgramProjectIconType;
      measurementObjectCopy: string | null;
      matchObjectType: string | null;
      commitmentType: string | null;
      reportKind: string | null;
      routeType: string | null;
      datasetRequirements: Array<{
        __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
        id: string;
        additionalNotesMd: string | null;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          name: string;
        };
      }>;
      ghgpRequirements: Array<{
        __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
        id: string;
        ghgScope: number;
        ghgScope3Category: number | null;
        minimumFidelity: GQClimateProgramProjectRequirementFidelity;
        additionalNotesMd: string | null;
      }>;
    };
  };
};

export type GQUpdateCanonicalClimateProgramProjectRequirementDatasetMutationVariables =
  Exact<{
    input: GQUpdateCanonicalClimateProgramProjectRequirementDatasetInput;
  }>;

export type GQUpdateCanonicalClimateProgramProjectRequirementDatasetMutation = {
  __typename?: 'Mutation';
  updateCanonicalClimateProgramProjectRequirementDataset: {
    __typename?: 'CanonicalClimateProgramProjectRequirementDatasetPayload';
    canonicalClimateProgramProjectRequirementDataset: {
      __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
      id: string;
      additionalNotesMd: string | null;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      };
    };
  };
};

export type GQGetCanonicalDatasetsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetCanonicalDatasetsQuery = {
  __typename?: 'Query';
  canonicalDatasets: Array<{
    __typename?: 'CanonicalDataset';
    id: string;
    name: string;
  }>;
};

export type GQUpdateCanonicalClimateProgramProjectRequirementGhgpMutationVariables =
  Exact<{
    input: GQUpdateCanonicalClimateProgramProjectRequirementGhgpInput;
  }>;

export type GQUpdateCanonicalClimateProgramProjectRequirementGhgpMutation = {
  __typename?: 'Mutation';
  updateCanonicalClimateProgramProjectRequirementGhgp: {
    __typename?: 'CanonicalClimateProgramProjectRequirementGhgpPayload';
    canonicalClimateProgramProjectRequirementGhgp: {
      __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
      id: string;
      ghgScope: number;
      ghgScope3Category: number | null;
      minimumFidelity: GQClimateProgramProjectRequirementFidelity;
      additionalNotesMd: string | null;
    };
  };
};

export type GQCompanyRelationshipsCardFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
  isDemo: boolean;
  companyRelationships: Array<{
    __typename?: 'CompanyRelationship';
    id: string;
    relationshipType: GQCompanyRelationshipType;
    relationshipStartDate: YearMonth | null;
    relationshipEndDate: YearMonth | null;
    companyA: { __typename?: 'Company'; id: string; name: string };
    companyB: { __typename?: 'Company'; id: string; name: string };
  }>;
};

export type GQGetCompanyRelationshipsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetCompanyRelationshipsQuery = {
  __typename?: 'Query';
  company: {
    __typename?: 'Company';
    id: string;
    name: string;
    isDemo: boolean;
    companyRelationships: Array<{
      __typename?: 'CompanyRelationship';
      id: string;
      relationshipType: GQCompanyRelationshipType;
      relationshipStartDate: YearMonth | null;
      relationshipEndDate: YearMonth | null;
      companyA: { __typename?: 'Company'; id: string; name: string };
      companyB: { __typename?: 'Company'; id: string; name: string };
    }>;
  };
};

export type GQCreateCompanyRelationshipMutationVariables = Exact<{
  input: GQCreateCompanyRelationshipInput;
}>;

export type GQCreateCompanyRelationshipMutation = {
  __typename?: 'Mutation';
  createCompanyRelationship: {
    __typename?: 'CreateCompanyRelationshipPayload';
    companyRelationship: {
      __typename?: 'CompanyRelationship';
      id: string;
      relationshipType: GQCompanyRelationshipType;
      relationshipStartDate: YearMonth | null;
      relationshipEndDate: YearMonth | null;
      companyA: { __typename?: 'Company'; id: string; name: string };
      companyB: { __typename?: 'Company'; id: string; name: string };
    };
  };
};

export type GQDeleteCompanyRelationshipMutationVariables = Exact<{
  input: GQDeleteCompanyRelationshipInput;
}>;

export type GQDeleteCompanyRelationshipMutation = {
  __typename?: 'Mutation';
  deleteCompanyRelationship: {
    __typename?: 'DeleteCompanyRelationshipPayload';
    id: string;
  };
};

export type GQUpsertCompanyMutationVariables = Exact<{
  input: GQCompanyInput;
}>;

export type GQUpsertCompanyMutation = {
  __typename?: 'Mutation';
  upsertCompany: {
    __typename?: 'UpsertCompanyPayload';
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      description: string | null;
      isDemo: boolean;
      createdAt: Date;
      updatedAt: Date;
      bestMatchesForCompany: Array<{
        __typename?: 'CompanyMatch';
        id: string;
        score: GQCompanyMatchScore;
        nameMatch: string | null;
        beaMatch: string | null;
        leiMatch: string | null;
        isinMatch: string | null;
        tickerMatch: string | null;
        countryMatch: string | null;
        sAndPIdMatch: string | null;
        company: {
          __typename: 'Company';
          id: string;
          name: string;
          replacedBy: {
            __typename: 'Company';
            id: string;
            name: string;
          } | null;
        };
      }>;
      names: Array<{
        __typename?: 'CompanyName';
        id: string;
        name: string;
        cleanedName: string | null;
        isPrimary: boolean;
        sourceId: string;
      }>;
      isins: Array<{
        __typename?: 'CompanyIsin';
        id: string;
        isin: string;
        sourceId: string;
      }>;
      beas: Array<{
        __typename?: 'CompanyBea';
        id: string;
        bea: string;
        sourceId: string;
      }>;
      leis: Array<{
        __typename?: 'CompanyLei';
        id: string;
        lei: string;
        sourceId: string;
      }>;
      tickerSymbols: Array<{
        __typename?: 'CompanyTickerSymbol';
        id: string;
        tickerSymbol: string;
        exchangeSymbol: string | null;
        isSourcePrimary: boolean;
        sourceId: string;
      }>;
      sAndP: {
        __typename?: 'CompanySAndP';
        id: string;
        sAndPId: string;
        sourceId: string;
      } | null;
      countries: Array<{
        __typename?: 'CompanyCountry';
        id: string;
        countryAlpha2: string;
        isPrimary: boolean;
        sourceId: string;
      }>;
      disclosures: Array<{
        __typename?: 'Disclosure';
        id: string;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          reportType: GQExternalReportType;
          companyId: string | null;
          publishingYear: number;
          reportingYears: Array<number>;
          publicUrl: string;
          qualityScore: GQDisclosureQualityScore | null;
          externalScore: string | null;
          gsUrl: string | null;
          source: GQPublicDisclosureSource;
          cdpVendorData: {
            __typename?: 'CdpVendorData';
            id: string;
            publishingYear: number;
            reportingYear: number;
            scope1Nonzero: boolean;
            scope2Nonzero: boolean;
            scope3Nonzero: boolean;
            totalEmissionsNonzero: boolean;
            scope301Or302Nonzero: boolean;
            scope1Verified: boolean;
            scope2Verified: boolean;
            scope3Verified: boolean;
            pctEvaluationStatusesMatchResponse: number;
            disclosureQualityScore: GQDisclosureQualityScore;
            scope301EvaluationStatus: GQScope3EvaluationStatus;
            scope302EvaluationStatus: GQScope3EvaluationStatus;
            scope303EvaluationStatus: GQScope3EvaluationStatus;
            scope304EvaluationStatus: GQScope3EvaluationStatus;
            scope305EvaluationStatus: GQScope3EvaluationStatus;
            scope306EvaluationStatus: GQScope3EvaluationStatus;
            scope307EvaluationStatus: GQScope3EvaluationStatus;
            scope308EvaluationStatus: GQScope3EvaluationStatus;
            scope309EvaluationStatus: GQScope3EvaluationStatus;
            scope310EvaluationStatus: GQScope3EvaluationStatus;
            scope311EvaluationStatus: GQScope3EvaluationStatus;
            scope312EvaluationStatus: GQScope3EvaluationStatus;
            scope313EvaluationStatus: GQScope3EvaluationStatus;
            scope314EvaluationStatus: GQScope3EvaluationStatus;
            scope315EvaluationStatus: GQScope3EvaluationStatus;
            scope316EvaluationStatus: GQScope3EvaluationStatus;
            scope317EvaluationStatus: GQScope3EvaluationStatus;
          } | null;
        } | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          createdAt: Date;
          publicDisclosureId: string | null;
          privateDisclosureId: string | null;
          footprintEstimateOutputId: string | null;
          buildingEstimateOutputId: string | null;
          assetCorporateId: string | null;
          expenseCategory: string | null;
          reportingYear: number;
          numEmployees: number | null;
          revenue: number | null;
          revenueCurrency: string | null;
          currencyConversionRate: number | null;
          scope1: number | null;
          scope2: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
          units: GQCompanyEmissionsUnits;
          percentageCleanEnergy: number | null;
          totalMwh: number | null;
          orgId: string | null;
          scenario: string | null;
          updatedAt: Date;
          pcafScoreScope1: number | null;
        }> | null;
        climateCommitments: Array<
          | {
              __typename: 'CarbonNeutralCommitment';
              id: string;
              targetYear: number | null;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename: 'CleanEnergyCommitment';
              id: string;
              targetYear: number | null;
              targetPercentageCleanEnergy: number | null;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename: 'NetZeroCommitment';
              id: string;
              targetYear: number | null;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename: 'ScienceBasedTargetCommitment';
              submittedToSBTi: boolean;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
              commitment: {
                __typename?: 'SBTCommitment';
                id: string;
                nearTermTargetYear: number | null;
                longTermTargetYear: number | null;
                baselineYear: number | null;
                stage: GQSbtCommitmentStage | null;
                targetClassification: GQSbtTargetClassification | null;
                netZeroTargetYear: number | null;
                commitmentDeadline: Date | null;
                commitmentType: GQSbtCommitmentType | null;
                netZeroCommitted: boolean | null;
              };
            }
        > | null;
      }>;
      logo: {
        __typename?: 'CompanyUrl';
        id: string;
        url: string;
        sourceId: string;
        source: {
          __typename?: 'CompanySourceKey';
          id: string;
          sourceKey: string;
        };
      } | null;
      companyWebsite: {
        __typename?: 'CompanyUrl';
        id: string;
        url: string;
        sourceId: string;
        source: {
          __typename?: 'CompanySourceKey';
          id: string;
          sourceKey: string;
        };
      } | null;
      sustainabilityWebsite: {
        __typename?: 'CompanyUrl';
        id: string;
        url: string;
        sourceId: string;
        source: {
          __typename?: 'CompanySourceKey';
          id: string;
          sourceKey: string;
        };
      } | null;
      editor: { __typename?: 'User'; id: string; name: string } | null;
      sourceKey: {
        __typename?: 'CompanySourceKey';
        id: string;
        sourceKey: string;
        sourceUrl: string | null;
        sourceNotes: string | null;
        priority: number;
        userVisibleSourceUrl: string | null;
        userVisibleSourceNotes: string | null;
        createdAt: Date;
        updatedAt: Date;
        editor: { __typename?: 'User'; id: string; name: string } | null;
      } | null;
      replacedBy: { __typename: 'Company'; id: string; name: string } | null;
    };
  };
};

export type GQCompanyNameFieldsFragment = {
  __typename?: 'CompanyName';
  id: string;
  name: string;
  cleanedName: string | null;
  isPrimary: boolean;
  sourceId: string;
};

export type GQCompanyUrlFieldsFragment = {
  __typename?: 'CompanyUrl';
  id: string;
  url: string;
  sourceId: string;
  source: { __typename?: 'CompanySourceKey'; id: string; sourceKey: string };
};

export type GQEngagementTaskAllFieldsFragment = {
  __typename?: 'EngagementTask';
  id: string;
  companyId: string;
  rootCompanyId: string;
  approvedAt: Date | null;
  createdAt: Date;
  crossOrgModelId: string;
  deletedAt: Date | null;
  dueDate: Date | null;
  expiresAt: Date | null;
  initialSupplierAssigneeEmail: string | null;
  latestCeeWorkflowId: string | null;
  proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
  reportingYear: number | null;
  rootAssignee: string | null;
  status: GQCompanySurveyStatus;
  statusChangedAt: Date | null;
  submittedAt: Date | null;
  supplierAssignee: string | null;
  supplierFacingDescription: string | null;
  supplierOrgId: string;
  surveyKind: GQCompanySurveyKind;
  watershedGeneric: boolean | null;
};

export type GQSharedMeasurementProjectAllFieldsFragment = {
  __typename?: 'SharedMeasurementProject';
  id: string;
  measurementProjectId: string;
  orgId: string;
};

export type GQPublicDisclosureAllFieldsFragment = {
  __typename?: 'PublicDisclosure';
  id: string;
  companyId: string | null;
  reportType: GQExternalReportType;
  publishingYear: number;
  publicUrl: string;
  gsUrl: string | null;
  createdAt: Date;
  externalScore: string | null;
  source: GQPublicDisclosureSource;
  sourceRecordId: string | null;
  updatedAt: Date;
  qualityScore: GQDisclosureQualityScore | null;
};

export type GQCompanyClimateCommitmentRawAllFieldsFragment = {
  __typename?: 'CompanyClimateCommitmentRaw';
  commitmentMadeDate: Date | null;
  commitmentPeriodEnd: Date | null;
  commitmentPeriodStart: Date | null;
  createdAt: Date;
  deletedAt: Date | null;
  description: string | null;
  publicDisclosureId: string | null;
  externalUrl: string | null;
  id: string;
  kind: GQCompanyClimateCommitmentKind;
  orgId: string | null;
  privateDisclosureId: string | null;
  sbtBaselineYear: number | null;
  sbtCommitmentStage: GQSbtCommitmentStage | null;
  sbtCommitmentType: GQSbtCommitmentType | null;
  sbtCommitmentDeadline: Date | null;
  sbtLongTermTargetYear: number | null;
  sbtNearTermTargetYear: number | null;
  sbtNetZeroTargetYear: number | null;
  sbtNetZeroCommitted: boolean | null;
  sbtTargetClassification: GQSbtTargetClassification | null;
  targetPercentageCleanEnergy: number | null;
  targetYear: number | null;
  updatedAt: Date;
  userEditorId: string | null;
  watershedEditorId: string | null;
};

export type GQFilterExpressionGroupAllFieldsFragment = {
  __typename?: 'FilterExpressionGroup';
  conjunction: GQFilterConjunction;
  expressions: Array<{
    __typename?: 'FilterExpressionPrimitive';
    field: GQFilterFieldLegacy;
    operator: GQFilterOperator;
    value: Array<string>;
  }>;
};

export type GQDisclosureTargetDetailsAllFieldsFragment = {
  __typename?: 'DisclosureTargetDetails';
  id: string;
  name: string;
  description: string;
  baseYear: YearMonth;
  emissionsType: GQDisclosureTargetEmissionsType;
  reductionRate: GQDisclosureTargetReductionRate;
  intensityType: GQDisclosureTargetIntensityType;
  publicDisclosureId: string | null;
  privateDisclosureId: string | null;
  companyClimateCommitmentId: string | null;
  createdAt: Date;
  deletedAt: Date | null;
  emissionsTargetId: string;
  orgId: string | null;
  updatedAt: Date;
  unit: string | null;
  unitDescription: string | null;
  filters: {
    __typename?: 'FilterExpressionGroup';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitive';
      field: GQFilterFieldLegacy;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  };
};

export type GQDisclosureInitiativeAllFieldsFragment = {
  __typename?: 'DisclosureInitiative';
  createdAt: Date;
  description: string | null;
  endYearMonth: YearMonth | null;
  id: string;
  initiativeType: GQDisclosureInitiativeType;
  name: string | null;
  orgId: string | null;
  privateDisclosureId: string | null;
  publicDisclosureId: string | null;
  startYearMonth: YearMonth | null;
  updatedAt: Date;
  startDate: number | null;
  endDate: number | null;
  emissionsTimeseriesId: string | null;
  intensityType: GQDisclosureTargetIntensityType | null;
  filters: {
    __typename?: 'FilterExpressionGroup';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitive';
      field: GQFilterFieldLegacy;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  } | null;
};

export type GQCompanyAllFieldsFragment = {
  __typename?: 'Company';
  id: string;
  alternateNames: Array<string> | null;
  betterCompanyId: string | null;
  country: string | null;
  createdAt: Date;
  deletedAt: Date | null;
  description: string | null;
  isDemo: boolean;
  logoUrl: string | null;
  naicsCode: string | null;
  naicsCodeSource: string | null;
  name: string;
  sfdcOpportunityId: string | null;
  sfdcStage: string | null;
  source: string;
  sustainabilityWebsiteUrl: string | null;
  updatedAt: Date;
  url: string | null;
  userEditorId: string | null;
  logoUrlId: string | null;
  sustainabilityWebsiteUrlId: string | null;
  companyWebsiteUrlId: string | null;
  companyIngestionRecordUpdateId: string | null;
  sourceId: string;
};

export type GQPrivateDisclosureAllFieldsFragment = {
  __typename?: 'PrivateDisclosure';
  id: string;
  companyId: string | null;
  assetCorporateId: string | null;
  assetPersonalMotorVehicleInsuranceId: string | null;
  orgId: string;
  footprintDisclosureId: string | null;
  surveyId: string | null;
  privateDisclosureType: GQPrivateDisclosureType;
  createdAt: Date;
  disclosureQualityScore: GQDisclosureQualityScore | null;
  disclosureQualityExplanation: string | null;
  thirdPartyVerified: boolean;
  updatedAt: Date;
  userEditorId: string;
  watershedEditorId: string | null;
  engagementTaskId: string | null;
  sourceId: string;
  companyIngestionRecordUpdateId: string | null;
};

export type GQCompanyFieldsFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
  description: string | null;
  isDemo: boolean;
  createdAt: Date;
  updatedAt: Date;
  names: Array<{
    __typename?: 'CompanyName';
    id: string;
    name: string;
    cleanedName: string | null;
    isPrimary: boolean;
    sourceId: string;
  }>;
  isins: Array<{
    __typename?: 'CompanyIsin';
    id: string;
    isin: string;
    sourceId: string;
  }>;
  beas: Array<{
    __typename?: 'CompanyBea';
    id: string;
    bea: string;
    sourceId: string;
  }>;
  leis: Array<{
    __typename?: 'CompanyLei';
    id: string;
    lei: string;
    sourceId: string;
  }>;
  tickerSymbols: Array<{
    __typename?: 'CompanyTickerSymbol';
    id: string;
    tickerSymbol: string;
    exchangeSymbol: string | null;
    isSourcePrimary: boolean;
    sourceId: string;
  }>;
  sAndP: {
    __typename?: 'CompanySAndP';
    id: string;
    sAndPId: string;
    sourceId: string;
  } | null;
  countries: Array<{
    __typename?: 'CompanyCountry';
    id: string;
    countryAlpha2: string;
    isPrimary: boolean;
    sourceId: string;
  }>;
  disclosures: Array<{
    __typename?: 'Disclosure';
    id: string;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      reportType: GQExternalReportType;
      companyId: string | null;
      publishingYear: number;
      reportingYears: Array<number>;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      externalScore: string | null;
      gsUrl: string | null;
      source: GQPublicDisclosureSource;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      createdAt: Date;
      publicDisclosureId: string | null;
      privateDisclosureId: string | null;
      footprintEstimateOutputId: string | null;
      buildingEstimateOutputId: string | null;
      assetCorporateId: string | null;
      expenseCategory: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      currencyConversionRate: number | null;
      scope1: number | null;
      scope2: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
      units: GQCompanyEmissionsUnits;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      orgId: string | null;
      scenario: string | null;
      updatedAt: Date;
      pcafScoreScope1: number | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename: 'CarbonNeutralCommitment';
          id: string;
          targetYear: number | null;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename: 'CleanEnergyCommitment';
          id: string;
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename: 'NetZeroCommitment';
          id: string;
          targetYear: number | null;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            netZeroTargetYear: number | null;
            commitmentDeadline: Date | null;
            commitmentType: GQSbtCommitmentType | null;
            netZeroCommitted: boolean | null;
          };
        }
    > | null;
  }>;
  logo: {
    __typename?: 'CompanyUrl';
    id: string;
    url: string;
    sourceId: string;
    source: { __typename?: 'CompanySourceKey'; id: string; sourceKey: string };
  } | null;
  companyWebsite: {
    __typename?: 'CompanyUrl';
    id: string;
    url: string;
    sourceId: string;
    source: { __typename?: 'CompanySourceKey'; id: string; sourceKey: string };
  } | null;
  sustainabilityWebsite: {
    __typename?: 'CompanyUrl';
    id: string;
    url: string;
    sourceId: string;
    source: { __typename?: 'CompanySourceKey'; id: string; sourceKey: string };
  } | null;
  editor: { __typename?: 'User'; id: string; name: string } | null;
  sourceKey: {
    __typename?: 'CompanySourceKey';
    id: string;
    sourceKey: string;
    sourceUrl: string | null;
    sourceNotes: string | null;
    priority: number;
    userVisibleSourceUrl: string | null;
    userVisibleSourceNotes: string | null;
    createdAt: Date;
    updatedAt: Date;
    editor: { __typename?: 'User'; id: string; name: string } | null;
  } | null;
  replacedBy: { __typename: 'Company'; id: string; name: string } | null;
};

export type GQCompaniesPageQueryVariables = Exact<{
  q: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  includeDemo: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQCompaniesPageQuery = {
  __typename?: 'Query';
  companies: {
    __typename?: 'CompanyConnection';
    edges: Array<{
      __typename?: 'CompanyEdge';
      node: {
        __typename?: 'Company';
        id: string;
        name: string;
        description: string | null;
        isDemo: boolean;
        createdAt: Date;
        updatedAt: Date;
        names: Array<{
          __typename?: 'CompanyName';
          id: string;
          name: string;
          cleanedName: string | null;
          isPrimary: boolean;
          sourceId: string;
        }>;
        isins: Array<{
          __typename?: 'CompanyIsin';
          id: string;
          isin: string;
          sourceId: string;
        }>;
        beas: Array<{
          __typename?: 'CompanyBea';
          id: string;
          bea: string;
          sourceId: string;
        }>;
        leis: Array<{
          __typename?: 'CompanyLei';
          id: string;
          lei: string;
          sourceId: string;
        }>;
        tickerSymbols: Array<{
          __typename?: 'CompanyTickerSymbol';
          id: string;
          tickerSymbol: string;
          exchangeSymbol: string | null;
          isSourcePrimary: boolean;
          sourceId: string;
        }>;
        sAndP: {
          __typename?: 'CompanySAndP';
          id: string;
          sAndPId: string;
          sourceId: string;
        } | null;
        countries: Array<{
          __typename?: 'CompanyCountry';
          id: string;
          countryAlpha2: string;
          isPrimary: boolean;
          sourceId: string;
        }>;
        disclosures: Array<{
          __typename?: 'Disclosure';
          id: string;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            reportType: GQExternalReportType;
            companyId: string | null;
            publishingYear: number;
            reportingYears: Array<number>;
            publicUrl: string;
            qualityScore: GQDisclosureQualityScore | null;
            externalScore: string | null;
            gsUrl: string | null;
            source: GQPublicDisclosureSource;
            cdpVendorData: {
              __typename?: 'CdpVendorData';
              id: string;
              publishingYear: number;
              reportingYear: number;
              scope1Nonzero: boolean;
              scope2Nonzero: boolean;
              scope3Nonzero: boolean;
              totalEmissionsNonzero: boolean;
              scope301Or302Nonzero: boolean;
              scope1Verified: boolean;
              scope2Verified: boolean;
              scope3Verified: boolean;
              pctEvaluationStatusesMatchResponse: number;
              disclosureQualityScore: GQDisclosureQualityScore;
              scope301EvaluationStatus: GQScope3EvaluationStatus;
              scope302EvaluationStatus: GQScope3EvaluationStatus;
              scope303EvaluationStatus: GQScope3EvaluationStatus;
              scope304EvaluationStatus: GQScope3EvaluationStatus;
              scope305EvaluationStatus: GQScope3EvaluationStatus;
              scope306EvaluationStatus: GQScope3EvaluationStatus;
              scope307EvaluationStatus: GQScope3EvaluationStatus;
              scope308EvaluationStatus: GQScope3EvaluationStatus;
              scope309EvaluationStatus: GQScope3EvaluationStatus;
              scope310EvaluationStatus: GQScope3EvaluationStatus;
              scope311EvaluationStatus: GQScope3EvaluationStatus;
              scope312EvaluationStatus: GQScope3EvaluationStatus;
              scope313EvaluationStatus: GQScope3EvaluationStatus;
              scope314EvaluationStatus: GQScope3EvaluationStatus;
              scope315EvaluationStatus: GQScope3EvaluationStatus;
              scope316EvaluationStatus: GQScope3EvaluationStatus;
              scope317EvaluationStatus: GQScope3EvaluationStatus;
            } | null;
          } | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            createdAt: Date;
            publicDisclosureId: string | null;
            privateDisclosureId: string | null;
            footprintEstimateOutputId: string | null;
            buildingEstimateOutputId: string | null;
            assetCorporateId: string | null;
            expenseCategory: string | null;
            reportingYear: number;
            numEmployees: number | null;
            revenue: number | null;
            revenueCurrency: string | null;
            currencyConversionRate: number | null;
            scope1: number | null;
            scope2: number | null;
            scope2Market: number | null;
            scope2Location: number | null;
            scope3: number | null;
            scope301: number | null;
            scope302: number | null;
            scope303: number | null;
            scope304: number | null;
            scope305: number | null;
            scope306: number | null;
            scope307: number | null;
            scope308: number | null;
            scope309: number | null;
            scope310: number | null;
            scope311: number | null;
            scope312: number | null;
            scope313: number | null;
            scope314: number | null;
            scope315: number | null;
            scope316: number | null;
            scope317: number | null;
            units: GQCompanyEmissionsUnits;
            percentageCleanEnergy: number | null;
            totalMwh: number | null;
            orgId: string | null;
            scenario: string | null;
            updatedAt: Date;
            pcafScoreScope1: number | null;
          }> | null;
          climateCommitments: Array<
            | {
                __typename: 'CarbonNeutralCommitment';
                id: string;
                targetYear: number | null;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename: 'CleanEnergyCommitment';
                id: string;
                targetYear: number | null;
                targetPercentageCleanEnergy: number | null;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename: 'NetZeroCommitment';
                id: string;
                targetYear: number | null;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename: 'ScienceBasedTargetCommitment';
                submittedToSBTi: boolean;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
                commitment: {
                  __typename?: 'SBTCommitment';
                  id: string;
                  nearTermTargetYear: number | null;
                  longTermTargetYear: number | null;
                  baselineYear: number | null;
                  stage: GQSbtCommitmentStage | null;
                  targetClassification: GQSbtTargetClassification | null;
                  netZeroTargetYear: number | null;
                  commitmentDeadline: Date | null;
                  commitmentType: GQSbtCommitmentType | null;
                  netZeroCommitted: boolean | null;
                };
              }
          > | null;
        }>;
        logo: {
          __typename?: 'CompanyUrl';
          id: string;
          url: string;
          sourceId: string;
          source: {
            __typename?: 'CompanySourceKey';
            id: string;
            sourceKey: string;
          };
        } | null;
        companyWebsite: {
          __typename?: 'CompanyUrl';
          id: string;
          url: string;
          sourceId: string;
          source: {
            __typename?: 'CompanySourceKey';
            id: string;
            sourceKey: string;
          };
        } | null;
        sustainabilityWebsite: {
          __typename?: 'CompanyUrl';
          id: string;
          url: string;
          sourceId: string;
          source: {
            __typename?: 'CompanySourceKey';
            id: string;
            sourceKey: string;
          };
        } | null;
        editor: { __typename?: 'User'; id: string; name: string } | null;
        sourceKey: {
          __typename?: 'CompanySourceKey';
          id: string;
          sourceKey: string;
          sourceUrl: string | null;
          sourceNotes: string | null;
          priority: number;
          userVisibleSourceUrl: string | null;
          userVisibleSourceNotes: string | null;
          createdAt: Date;
          updatedAt: Date;
          editor: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        replacedBy: { __typename: 'Company'; id: string; name: string } | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type GQCompanyIngestionRecordUpdateFieldsFragment = {
  __typename?: 'CompanyIngestionRecordUpdate';
  id: string;
  tableName: string;
  oldValue: any | null;
  newValue: any | null;
  status: GQCompanyIngestionRecordUpdateStatus;
  createdAt: Date;
  updatedAt: Date;
  diff: {
    __typename?: 'CompanyIngestionDiff';
    id: string;
    primaryCompanyName: string;
    companyMatchScore: string | null;
    companyMatchResults: any | null;
    processedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
    isCompanyDemo: boolean;
    sourceRecord:
      | {
          __typename: 'CdpVendorData';
          id: string;
          publishingYear: number;
          reportingYear: number;
          scope1Nonzero: boolean;
          scope2Nonzero: boolean;
          scope3Nonzero: boolean;
          totalEmissionsNonzero: boolean;
          scope301Or302Nonzero: boolean;
          scope1Verified: boolean;
          scope2Verified: boolean;
          scope3Verified: boolean;
          pctEvaluationStatusesMatchResponse: number;
          disclosureQualityScore: GQDisclosureQualityScore;
          scope301EvaluationStatus: GQScope3EvaluationStatus;
          scope302EvaluationStatus: GQScope3EvaluationStatus;
          scope303EvaluationStatus: GQScope3EvaluationStatus;
          scope304EvaluationStatus: GQScope3EvaluationStatus;
          scope305EvaluationStatus: GQScope3EvaluationStatus;
          scope306EvaluationStatus: GQScope3EvaluationStatus;
          scope307EvaluationStatus: GQScope3EvaluationStatus;
          scope308EvaluationStatus: GQScope3EvaluationStatus;
          scope309EvaluationStatus: GQScope3EvaluationStatus;
          scope310EvaluationStatus: GQScope3EvaluationStatus;
          scope311EvaluationStatus: GQScope3EvaluationStatus;
          scope312EvaluationStatus: GQScope3EvaluationStatus;
          scope313EvaluationStatus: GQScope3EvaluationStatus;
          scope314EvaluationStatus: GQScope3EvaluationStatus;
          scope315EvaluationStatus: GQScope3EvaluationStatus;
          scope316EvaluationStatus: GQScope3EvaluationStatus;
          scope317EvaluationStatus: GQScope3EvaluationStatus;
        }
      | {
          __typename: 'Company';
          id: string;
          name: string;
          description: string | null;
          url: string | null;
          fullLogoUrl: string | null;
          naicsCode: string | null;
          naicsCodeSource: string | null;
          countryAlpha2: string | null;
          sfdcOpportunityId: string | null;
          sfdcStage: string | null;
          isDemo: boolean;
          sustainabilityWebsiteUrl: string | null;
        }
      | {
          __typename: 'CompanyChangeRequest';
          id: string;
          action: GQCompanyChangeRequestAction;
          deletedAt: Date | null;
          payload: any;
          createdAt: Date;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
          };
          userEditor: { __typename?: 'User'; id: string; name: string } | null;
        }
      | { __typename: 'IngestedSbtiCompanyUpdate'; id: string }
      | { __typename: 'SbtTarget'; id: string };
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      names: Array<{
        __typename?: 'CompanyName';
        id: string;
        name: string;
        cleanedName: string | null;
        isPrimary: boolean;
        sourceId: string;
      }>;
    } | null;
  };
  record:
    | { __typename: 'AssetCorporate' }
    | {
        __typename: 'Company';
        id: string;
        name: string;
        description: string | null;
        isDemo: boolean;
        createdAt: Date;
        updatedAt: Date;
        names: Array<{
          __typename?: 'CompanyName';
          id: string;
          name: string;
          cleanedName: string | null;
          isPrimary: boolean;
          sourceId: string;
        }>;
        isins: Array<{
          __typename?: 'CompanyIsin';
          id: string;
          isin: string;
          sourceId: string;
        }>;
        beas: Array<{
          __typename?: 'CompanyBea';
          id: string;
          bea: string;
          sourceId: string;
        }>;
        leis: Array<{
          __typename?: 'CompanyLei';
          id: string;
          lei: string;
          sourceId: string;
        }>;
        tickerSymbols: Array<{
          __typename?: 'CompanyTickerSymbol';
          id: string;
          tickerSymbol: string;
          exchangeSymbol: string | null;
          isSourcePrimary: boolean;
          sourceId: string;
        }>;
        sAndP: {
          __typename?: 'CompanySAndP';
          id: string;
          sAndPId: string;
          sourceId: string;
        } | null;
        countries: Array<{
          __typename?: 'CompanyCountry';
          id: string;
          countryAlpha2: string;
          isPrimary: boolean;
          sourceId: string;
        }>;
        disclosures: Array<{
          __typename?: 'Disclosure';
          id: string;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            reportType: GQExternalReportType;
            companyId: string | null;
            publishingYear: number;
            reportingYears: Array<number>;
            publicUrl: string;
            qualityScore: GQDisclosureQualityScore | null;
            externalScore: string | null;
            gsUrl: string | null;
            source: GQPublicDisclosureSource;
            cdpVendorData: {
              __typename?: 'CdpVendorData';
              id: string;
              publishingYear: number;
              reportingYear: number;
              scope1Nonzero: boolean;
              scope2Nonzero: boolean;
              scope3Nonzero: boolean;
              totalEmissionsNonzero: boolean;
              scope301Or302Nonzero: boolean;
              scope1Verified: boolean;
              scope2Verified: boolean;
              scope3Verified: boolean;
              pctEvaluationStatusesMatchResponse: number;
              disclosureQualityScore: GQDisclosureQualityScore;
              scope301EvaluationStatus: GQScope3EvaluationStatus;
              scope302EvaluationStatus: GQScope3EvaluationStatus;
              scope303EvaluationStatus: GQScope3EvaluationStatus;
              scope304EvaluationStatus: GQScope3EvaluationStatus;
              scope305EvaluationStatus: GQScope3EvaluationStatus;
              scope306EvaluationStatus: GQScope3EvaluationStatus;
              scope307EvaluationStatus: GQScope3EvaluationStatus;
              scope308EvaluationStatus: GQScope3EvaluationStatus;
              scope309EvaluationStatus: GQScope3EvaluationStatus;
              scope310EvaluationStatus: GQScope3EvaluationStatus;
              scope311EvaluationStatus: GQScope3EvaluationStatus;
              scope312EvaluationStatus: GQScope3EvaluationStatus;
              scope313EvaluationStatus: GQScope3EvaluationStatus;
              scope314EvaluationStatus: GQScope3EvaluationStatus;
              scope315EvaluationStatus: GQScope3EvaluationStatus;
              scope316EvaluationStatus: GQScope3EvaluationStatus;
              scope317EvaluationStatus: GQScope3EvaluationStatus;
            } | null;
          } | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            createdAt: Date;
            publicDisclosureId: string | null;
            privateDisclosureId: string | null;
            footprintEstimateOutputId: string | null;
            buildingEstimateOutputId: string | null;
            assetCorporateId: string | null;
            expenseCategory: string | null;
            reportingYear: number;
            numEmployees: number | null;
            revenue: number | null;
            revenueCurrency: string | null;
            currencyConversionRate: number | null;
            scope1: number | null;
            scope2: number | null;
            scope2Market: number | null;
            scope2Location: number | null;
            scope3: number | null;
            scope301: number | null;
            scope302: number | null;
            scope303: number | null;
            scope304: number | null;
            scope305: number | null;
            scope306: number | null;
            scope307: number | null;
            scope308: number | null;
            scope309: number | null;
            scope310: number | null;
            scope311: number | null;
            scope312: number | null;
            scope313: number | null;
            scope314: number | null;
            scope315: number | null;
            scope316: number | null;
            scope317: number | null;
            units: GQCompanyEmissionsUnits;
            percentageCleanEnergy: number | null;
            totalMwh: number | null;
            orgId: string | null;
            scenario: string | null;
            updatedAt: Date;
            pcafScoreScope1: number | null;
          }> | null;
          climateCommitments: Array<
            | {
                __typename: 'CarbonNeutralCommitment';
                id: string;
                targetYear: number | null;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename: 'CleanEnergyCommitment';
                id: string;
                targetYear: number | null;
                targetPercentageCleanEnergy: number | null;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename: 'NetZeroCommitment';
                id: string;
                targetYear: number | null;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename: 'ScienceBasedTargetCommitment';
                submittedToSBTi: boolean;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
                commitment: {
                  __typename?: 'SBTCommitment';
                  id: string;
                  nearTermTargetYear: number | null;
                  longTermTargetYear: number | null;
                  baselineYear: number | null;
                  stage: GQSbtCommitmentStage | null;
                  targetClassification: GQSbtTargetClassification | null;
                  netZeroTargetYear: number | null;
                  commitmentDeadline: Date | null;
                  commitmentType: GQSbtCommitmentType | null;
                  netZeroCommitted: boolean | null;
                };
              }
          > | null;
        }>;
        logo: {
          __typename?: 'CompanyUrl';
          id: string;
          url: string;
          sourceId: string;
          source: {
            __typename?: 'CompanySourceKey';
            id: string;
            sourceKey: string;
          };
        } | null;
        companyWebsite: {
          __typename?: 'CompanyUrl';
          id: string;
          url: string;
          sourceId: string;
          source: {
            __typename?: 'CompanySourceKey';
            id: string;
            sourceKey: string;
          };
        } | null;
        sustainabilityWebsite: {
          __typename?: 'CompanyUrl';
          id: string;
          url: string;
          sourceId: string;
          source: {
            __typename?: 'CompanySourceKey';
            id: string;
            sourceKey: string;
          };
        } | null;
        editor: { __typename?: 'User'; id: string; name: string } | null;
        sourceKey: {
          __typename?: 'CompanySourceKey';
          id: string;
          sourceKey: string;
          sourceUrl: string | null;
          sourceNotes: string | null;
          priority: number;
          userVisibleSourceUrl: string | null;
          userVisibleSourceNotes: string | null;
          createdAt: Date;
          updatedAt: Date;
          editor: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        replacedBy: { __typename: 'Company'; id: string; name: string } | null;
      }
    | { __typename: 'CompanyBea' }
    | { __typename: 'CompanyCountry' }
    | { __typename: 'CompanyIsin' }
    | { __typename: 'CompanyLei' }
    | { __typename: 'CompanyName' }
    | { __typename: 'CompanyPublicDisclosure' }
    | { __typename: 'CompanySAndP' }
    | { __typename: 'CompanyTickerSymbol' }
    | { __typename: 'CompanyUrl' }
    | { __typename: 'OrgCompany' }
    | { __typename: 'PrivateDisclosure' }
    | null;
};

export type GQSbtTargetFieldsFragment = {
  __typename?: 'SbtTarget';
  id: string;
  companyName: string;
  isin: string | null;
  lei: string | null;
  location: string | null;
  region: string | null;
  sector: string | null;
  organizationType: string | null;
  action: string | null;
  commitmentType: string | null;
  commitmentDeadline: string | null;
  status: string | null;
  reasonForCommitmentExtensionOrRemoval: string | null;
  fullTargetLanguage: string | null;
  companyTemperatureAlignment: string | null;
  target: string | null;
  targetWording: string | null;
  scope: string | null;
  targetValue: string | null;
  targetType: string | null;
  targetSubtype: string | null;
  targetClassification: string | null;
  baseYear: string | null;
  targetYear: string | null;
  yearType: string | null;
  datePublished: string | null;
  createdAt: Date;
  updatedAt: Date;
  dt: Date;
};

export type GQCompanyChangeRequestFieldsFragment = {
  __typename?: 'CompanyChangeRequest';
  id: string;
  action: GQCompanyChangeRequestAction;
  deletedAt: Date | null;
  payload: any;
  createdAt: Date;
  org: {
    __typename?: 'Organization';
    id: string;
    name: string;
    demoOrg: boolean;
  };
  userEditor: { __typename?: 'User'; id: string; name: string } | null;
};

export type GQCompanyIngestionDiffFieldsFragment = {
  __typename?: 'CompanyIngestionDiff';
  id: string;
  primaryCompanyName: string;
  companyMatchScore: string | null;
  companyMatchResults: any | null;
  processedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  isCompanyDemo: boolean;
  sourceRecord:
    | {
        __typename: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      }
    | {
        __typename: 'Company';
        id: string;
        name: string;
        description: string | null;
        url: string | null;
        fullLogoUrl: string | null;
        naicsCode: string | null;
        naicsCodeSource: string | null;
        countryAlpha2: string | null;
        sfdcOpportunityId: string | null;
        sfdcStage: string | null;
        isDemo: boolean;
        sustainabilityWebsiteUrl: string | null;
      }
    | {
        __typename: 'CompanyChangeRequest';
        id: string;
        action: GQCompanyChangeRequestAction;
        deletedAt: Date | null;
        payload: any;
        createdAt: Date;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
        };
        userEditor: { __typename?: 'User'; id: string; name: string } | null;
      }
    | { __typename: 'IngestedSbtiCompanyUpdate'; id: string }
    | { __typename: 'SbtTarget'; id: string };
  company: {
    __typename?: 'Company';
    id: string;
    name: string;
    names: Array<{
      __typename?: 'CompanyName';
      id: string;
      name: string;
      cleanedName: string | null;
      isPrimary: boolean;
      sourceId: string;
    }>;
  } | null;
};

export type GQCompanyIngestionRecordUpdatesQueryVariables = Exact<{
  filters: InputMaybe<GQCompanyIngestionRecordUpdateFilters>;
  sortDirection: InputMaybe<GQDataGridSortDirection>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQCompanyIngestionRecordUpdatesQuery = {
  __typename?: 'Query';
  companyIngestionRecordUpdates: {
    __typename?: 'CompanyIngestionRecordUpdateRequestConnection';
    edges: Array<{
      __typename?: 'CompanyIngestionRecordUpdateRequestEdge';
      node: {
        __typename?: 'CompanyIngestionRecordUpdate';
        id: string;
        tableName: string;
        oldValue: any | null;
        newValue: any | null;
        status: GQCompanyIngestionRecordUpdateStatus;
        createdAt: Date;
        updatedAt: Date;
        diff: {
          __typename?: 'CompanyIngestionDiff';
          id: string;
          primaryCompanyName: string;
          companyMatchScore: string | null;
          companyMatchResults: any | null;
          processedAt: Date | null;
          createdAt: Date;
          updatedAt: Date;
          isCompanyDemo: boolean;
          sourceRecord:
            | {
                __typename: 'CdpVendorData';
                id: string;
                publishingYear: number;
                reportingYear: number;
                scope1Nonzero: boolean;
                scope2Nonzero: boolean;
                scope3Nonzero: boolean;
                totalEmissionsNonzero: boolean;
                scope301Or302Nonzero: boolean;
                scope1Verified: boolean;
                scope2Verified: boolean;
                scope3Verified: boolean;
                pctEvaluationStatusesMatchResponse: number;
                disclosureQualityScore: GQDisclosureQualityScore;
                scope301EvaluationStatus: GQScope3EvaluationStatus;
                scope302EvaluationStatus: GQScope3EvaluationStatus;
                scope303EvaluationStatus: GQScope3EvaluationStatus;
                scope304EvaluationStatus: GQScope3EvaluationStatus;
                scope305EvaluationStatus: GQScope3EvaluationStatus;
                scope306EvaluationStatus: GQScope3EvaluationStatus;
                scope307EvaluationStatus: GQScope3EvaluationStatus;
                scope308EvaluationStatus: GQScope3EvaluationStatus;
                scope309EvaluationStatus: GQScope3EvaluationStatus;
                scope310EvaluationStatus: GQScope3EvaluationStatus;
                scope311EvaluationStatus: GQScope3EvaluationStatus;
                scope312EvaluationStatus: GQScope3EvaluationStatus;
                scope313EvaluationStatus: GQScope3EvaluationStatus;
                scope314EvaluationStatus: GQScope3EvaluationStatus;
                scope315EvaluationStatus: GQScope3EvaluationStatus;
                scope316EvaluationStatus: GQScope3EvaluationStatus;
                scope317EvaluationStatus: GQScope3EvaluationStatus;
              }
            | {
                __typename: 'Company';
                id: string;
                name: string;
                description: string | null;
                url: string | null;
                fullLogoUrl: string | null;
                naicsCode: string | null;
                naicsCodeSource: string | null;
                countryAlpha2: string | null;
                sfdcOpportunityId: string | null;
                sfdcStage: string | null;
                isDemo: boolean;
                sustainabilityWebsiteUrl: string | null;
              }
            | {
                __typename: 'CompanyChangeRequest';
                id: string;
                action: GQCompanyChangeRequestAction;
                deletedAt: Date | null;
                payload: any;
                createdAt: Date;
                org: {
                  __typename?: 'Organization';
                  id: string;
                  name: string;
                  demoOrg: boolean;
                };
                userEditor: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                } | null;
              }
            | { __typename: 'IngestedSbtiCompanyUpdate'; id: string }
            | { __typename: 'SbtTarget'; id: string };
          company: {
            __typename?: 'Company';
            id: string;
            name: string;
            names: Array<{
              __typename?: 'CompanyName';
              id: string;
              name: string;
              cleanedName: string | null;
              isPrimary: boolean;
              sourceId: string;
            }>;
          } | null;
        };
        record:
          | { __typename: 'AssetCorporate' }
          | {
              __typename: 'Company';
              id: string;
              name: string;
              description: string | null;
              isDemo: boolean;
              createdAt: Date;
              updatedAt: Date;
              names: Array<{
                __typename?: 'CompanyName';
                id: string;
                name: string;
                cleanedName: string | null;
                isPrimary: boolean;
                sourceId: string;
              }>;
              isins: Array<{
                __typename?: 'CompanyIsin';
                id: string;
                isin: string;
                sourceId: string;
              }>;
              beas: Array<{
                __typename?: 'CompanyBea';
                id: string;
                bea: string;
                sourceId: string;
              }>;
              leis: Array<{
                __typename?: 'CompanyLei';
                id: string;
                lei: string;
                sourceId: string;
              }>;
              tickerSymbols: Array<{
                __typename?: 'CompanyTickerSymbol';
                id: string;
                tickerSymbol: string;
                exchangeSymbol: string | null;
                isSourcePrimary: boolean;
                sourceId: string;
              }>;
              sAndP: {
                __typename?: 'CompanySAndP';
                id: string;
                sAndPId: string;
                sourceId: string;
              } | null;
              countries: Array<{
                __typename?: 'CompanyCountry';
                id: string;
                countryAlpha2: string;
                isPrimary: boolean;
                sourceId: string;
              }>;
              disclosures: Array<{
                __typename?: 'Disclosure';
                id: string;
                publicDisclosure: {
                  __typename?: 'PublicDisclosure';
                  id: string;
                  reportType: GQExternalReportType;
                  companyId: string | null;
                  publishingYear: number;
                  reportingYears: Array<number>;
                  publicUrl: string;
                  qualityScore: GQDisclosureQualityScore | null;
                  externalScore: string | null;
                  gsUrl: string | null;
                  source: GQPublicDisclosureSource;
                  cdpVendorData: {
                    __typename?: 'CdpVendorData';
                    id: string;
                    publishingYear: number;
                    reportingYear: number;
                    scope1Nonzero: boolean;
                    scope2Nonzero: boolean;
                    scope3Nonzero: boolean;
                    totalEmissionsNonzero: boolean;
                    scope301Or302Nonzero: boolean;
                    scope1Verified: boolean;
                    scope2Verified: boolean;
                    scope3Verified: boolean;
                    pctEvaluationStatusesMatchResponse: number;
                    disclosureQualityScore: GQDisclosureQualityScore;
                    scope301EvaluationStatus: GQScope3EvaluationStatus;
                    scope302EvaluationStatus: GQScope3EvaluationStatus;
                    scope303EvaluationStatus: GQScope3EvaluationStatus;
                    scope304EvaluationStatus: GQScope3EvaluationStatus;
                    scope305EvaluationStatus: GQScope3EvaluationStatus;
                    scope306EvaluationStatus: GQScope3EvaluationStatus;
                    scope307EvaluationStatus: GQScope3EvaluationStatus;
                    scope308EvaluationStatus: GQScope3EvaluationStatus;
                    scope309EvaluationStatus: GQScope3EvaluationStatus;
                    scope310EvaluationStatus: GQScope3EvaluationStatus;
                    scope311EvaluationStatus: GQScope3EvaluationStatus;
                    scope312EvaluationStatus: GQScope3EvaluationStatus;
                    scope313EvaluationStatus: GQScope3EvaluationStatus;
                    scope314EvaluationStatus: GQScope3EvaluationStatus;
                    scope315EvaluationStatus: GQScope3EvaluationStatus;
                    scope316EvaluationStatus: GQScope3EvaluationStatus;
                    scope317EvaluationStatus: GQScope3EvaluationStatus;
                  } | null;
                } | null;
                historicalEmissionsYears: Array<{
                  __typename?: 'EmissionsYear';
                  id: string;
                  createdAt: Date;
                  publicDisclosureId: string | null;
                  privateDisclosureId: string | null;
                  footprintEstimateOutputId: string | null;
                  buildingEstimateOutputId: string | null;
                  assetCorporateId: string | null;
                  expenseCategory: string | null;
                  reportingYear: number;
                  numEmployees: number | null;
                  revenue: number | null;
                  revenueCurrency: string | null;
                  currencyConversionRate: number | null;
                  scope1: number | null;
                  scope2: number | null;
                  scope2Market: number | null;
                  scope2Location: number | null;
                  scope3: number | null;
                  scope301: number | null;
                  scope302: number | null;
                  scope303: number | null;
                  scope304: number | null;
                  scope305: number | null;
                  scope306: number | null;
                  scope307: number | null;
                  scope308: number | null;
                  scope309: number | null;
                  scope310: number | null;
                  scope311: number | null;
                  scope312: number | null;
                  scope313: number | null;
                  scope314: number | null;
                  scope315: number | null;
                  scope316: number | null;
                  scope317: number | null;
                  units: GQCompanyEmissionsUnits;
                  percentageCleanEnergy: number | null;
                  totalMwh: number | null;
                  orgId: string | null;
                  scenario: string | null;
                  updatedAt: Date;
                  pcafScoreScope1: number | null;
                }> | null;
                climateCommitments: Array<
                  | {
                      __typename: 'CarbonNeutralCommitment';
                      id: string;
                      targetYear: number | null;
                      kind: GQCompanyClimateCommitmentKind;
                      description: string | null;
                      externalUrl: string | null;
                      commitmentMadeDate: Date | null;
                      commitmentPeriodStart: Date | null;
                      commitmentPeriodEnd: Date | null;
                    }
                  | {
                      __typename: 'CleanEnergyCommitment';
                      id: string;
                      targetYear: number | null;
                      targetPercentageCleanEnergy: number | null;
                      kind: GQCompanyClimateCommitmentKind;
                      description: string | null;
                      externalUrl: string | null;
                      commitmentMadeDate: Date | null;
                      commitmentPeriodStart: Date | null;
                      commitmentPeriodEnd: Date | null;
                    }
                  | {
                      __typename: 'NetZeroCommitment';
                      id: string;
                      targetYear: number | null;
                      kind: GQCompanyClimateCommitmentKind;
                      description: string | null;
                      externalUrl: string | null;
                      commitmentMadeDate: Date | null;
                      commitmentPeriodStart: Date | null;
                      commitmentPeriodEnd: Date | null;
                    }
                  | {
                      __typename: 'ScienceBasedTargetCommitment';
                      submittedToSBTi: boolean;
                      id: string;
                      kind: GQCompanyClimateCommitmentKind;
                      description: string | null;
                      externalUrl: string | null;
                      commitmentMadeDate: Date | null;
                      commitmentPeriodStart: Date | null;
                      commitmentPeriodEnd: Date | null;
                      commitment: {
                        __typename?: 'SBTCommitment';
                        id: string;
                        nearTermTargetYear: number | null;
                        longTermTargetYear: number | null;
                        baselineYear: number | null;
                        stage: GQSbtCommitmentStage | null;
                        targetClassification: GQSbtTargetClassification | null;
                        netZeroTargetYear: number | null;
                        commitmentDeadline: Date | null;
                        commitmentType: GQSbtCommitmentType | null;
                        netZeroCommitted: boolean | null;
                      };
                    }
                > | null;
              }>;
              logo: {
                __typename?: 'CompanyUrl';
                id: string;
                url: string;
                sourceId: string;
                source: {
                  __typename?: 'CompanySourceKey';
                  id: string;
                  sourceKey: string;
                };
              } | null;
              companyWebsite: {
                __typename?: 'CompanyUrl';
                id: string;
                url: string;
                sourceId: string;
                source: {
                  __typename?: 'CompanySourceKey';
                  id: string;
                  sourceKey: string;
                };
              } | null;
              sustainabilityWebsite: {
                __typename?: 'CompanyUrl';
                id: string;
                url: string;
                sourceId: string;
                source: {
                  __typename?: 'CompanySourceKey';
                  id: string;
                  sourceKey: string;
                };
              } | null;
              editor: { __typename?: 'User'; id: string; name: string } | null;
              sourceKey: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
                sourceUrl: string | null;
                sourceNotes: string | null;
                priority: number;
                userVisibleSourceUrl: string | null;
                userVisibleSourceNotes: string | null;
                createdAt: Date;
                updatedAt: Date;
                editor: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                } | null;
              } | null;
              replacedBy: {
                __typename: 'Company';
                id: string;
                name: string;
              } | null;
            }
          | { __typename: 'CompanyBea' }
          | { __typename: 'CompanyCountry' }
          | { __typename: 'CompanyIsin' }
          | { __typename: 'CompanyLei' }
          | { __typename: 'CompanyName' }
          | { __typename: 'CompanyPublicDisclosure' }
          | { __typename: 'CompanySAndP' }
          | { __typename: 'CompanyTickerSymbol' }
          | { __typename: 'CompanyUrl' }
          | { __typename: 'OrgCompany' }
          | { __typename: 'PrivateDisclosure' }
          | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type GQApproveCompanyIngestionUpdateMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQApproveCompanyIngestionUpdateMutation = {
  __typename?: 'Mutation';
  approveCompanyIngestionUpdate: {
    __typename?: 'ProcessCompanyIngestionUpdatePayload';
    companyIngestionRecordUpdate: {
      __typename?: 'CompanyIngestionRecordUpdate';
      id: string;
      tableName: string;
      oldValue: any | null;
      newValue: any | null;
      status: GQCompanyIngestionRecordUpdateStatus;
      createdAt: Date;
      updatedAt: Date;
      diff: {
        __typename?: 'CompanyIngestionDiff';
        id: string;
        primaryCompanyName: string;
        companyMatchScore: string | null;
        companyMatchResults: any | null;
        processedAt: Date | null;
        createdAt: Date;
        updatedAt: Date;
        isCompanyDemo: boolean;
        sourceRecord:
          | {
              __typename: 'CdpVendorData';
              id: string;
              publishingYear: number;
              reportingYear: number;
              scope1Nonzero: boolean;
              scope2Nonzero: boolean;
              scope3Nonzero: boolean;
              totalEmissionsNonzero: boolean;
              scope301Or302Nonzero: boolean;
              scope1Verified: boolean;
              scope2Verified: boolean;
              scope3Verified: boolean;
              pctEvaluationStatusesMatchResponse: number;
              disclosureQualityScore: GQDisclosureQualityScore;
              scope301EvaluationStatus: GQScope3EvaluationStatus;
              scope302EvaluationStatus: GQScope3EvaluationStatus;
              scope303EvaluationStatus: GQScope3EvaluationStatus;
              scope304EvaluationStatus: GQScope3EvaluationStatus;
              scope305EvaluationStatus: GQScope3EvaluationStatus;
              scope306EvaluationStatus: GQScope3EvaluationStatus;
              scope307EvaluationStatus: GQScope3EvaluationStatus;
              scope308EvaluationStatus: GQScope3EvaluationStatus;
              scope309EvaluationStatus: GQScope3EvaluationStatus;
              scope310EvaluationStatus: GQScope3EvaluationStatus;
              scope311EvaluationStatus: GQScope3EvaluationStatus;
              scope312EvaluationStatus: GQScope3EvaluationStatus;
              scope313EvaluationStatus: GQScope3EvaluationStatus;
              scope314EvaluationStatus: GQScope3EvaluationStatus;
              scope315EvaluationStatus: GQScope3EvaluationStatus;
              scope316EvaluationStatus: GQScope3EvaluationStatus;
              scope317EvaluationStatus: GQScope3EvaluationStatus;
            }
          | {
              __typename: 'Company';
              id: string;
              name: string;
              description: string | null;
              url: string | null;
              fullLogoUrl: string | null;
              naicsCode: string | null;
              naicsCodeSource: string | null;
              countryAlpha2: string | null;
              sfdcOpportunityId: string | null;
              sfdcStage: string | null;
              isDemo: boolean;
              sustainabilityWebsiteUrl: string | null;
            }
          | {
              __typename: 'CompanyChangeRequest';
              id: string;
              action: GQCompanyChangeRequestAction;
              deletedAt: Date | null;
              payload: any;
              createdAt: Date;
              org: {
                __typename?: 'Organization';
                id: string;
                name: string;
                demoOrg: boolean;
              };
              userEditor: {
                __typename?: 'User';
                id: string;
                name: string;
              } | null;
            }
          | { __typename: 'IngestedSbtiCompanyUpdate'; id: string }
          | { __typename: 'SbtTarget'; id: string };
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          names: Array<{
            __typename?: 'CompanyName';
            id: string;
            name: string;
            cleanedName: string | null;
            isPrimary: boolean;
            sourceId: string;
          }>;
        } | null;
      };
      record:
        | { __typename: 'AssetCorporate' }
        | {
            __typename: 'Company';
            id: string;
            name: string;
            description: string | null;
            isDemo: boolean;
            createdAt: Date;
            updatedAt: Date;
            names: Array<{
              __typename?: 'CompanyName';
              id: string;
              name: string;
              cleanedName: string | null;
              isPrimary: boolean;
              sourceId: string;
            }>;
            isins: Array<{
              __typename?: 'CompanyIsin';
              id: string;
              isin: string;
              sourceId: string;
            }>;
            beas: Array<{
              __typename?: 'CompanyBea';
              id: string;
              bea: string;
              sourceId: string;
            }>;
            leis: Array<{
              __typename?: 'CompanyLei';
              id: string;
              lei: string;
              sourceId: string;
            }>;
            tickerSymbols: Array<{
              __typename?: 'CompanyTickerSymbol';
              id: string;
              tickerSymbol: string;
              exchangeSymbol: string | null;
              isSourcePrimary: boolean;
              sourceId: string;
            }>;
            sAndP: {
              __typename?: 'CompanySAndP';
              id: string;
              sAndPId: string;
              sourceId: string;
            } | null;
            countries: Array<{
              __typename?: 'CompanyCountry';
              id: string;
              countryAlpha2: string;
              isPrimary: boolean;
              sourceId: string;
            }>;
            disclosures: Array<{
              __typename?: 'Disclosure';
              id: string;
              publicDisclosure: {
                __typename?: 'PublicDisclosure';
                id: string;
                reportType: GQExternalReportType;
                companyId: string | null;
                publishingYear: number;
                reportingYears: Array<number>;
                publicUrl: string;
                qualityScore: GQDisclosureQualityScore | null;
                externalScore: string | null;
                gsUrl: string | null;
                source: GQPublicDisclosureSource;
                cdpVendorData: {
                  __typename?: 'CdpVendorData';
                  id: string;
                  publishingYear: number;
                  reportingYear: number;
                  scope1Nonzero: boolean;
                  scope2Nonzero: boolean;
                  scope3Nonzero: boolean;
                  totalEmissionsNonzero: boolean;
                  scope301Or302Nonzero: boolean;
                  scope1Verified: boolean;
                  scope2Verified: boolean;
                  scope3Verified: boolean;
                  pctEvaluationStatusesMatchResponse: number;
                  disclosureQualityScore: GQDisclosureQualityScore;
                  scope301EvaluationStatus: GQScope3EvaluationStatus;
                  scope302EvaluationStatus: GQScope3EvaluationStatus;
                  scope303EvaluationStatus: GQScope3EvaluationStatus;
                  scope304EvaluationStatus: GQScope3EvaluationStatus;
                  scope305EvaluationStatus: GQScope3EvaluationStatus;
                  scope306EvaluationStatus: GQScope3EvaluationStatus;
                  scope307EvaluationStatus: GQScope3EvaluationStatus;
                  scope308EvaluationStatus: GQScope3EvaluationStatus;
                  scope309EvaluationStatus: GQScope3EvaluationStatus;
                  scope310EvaluationStatus: GQScope3EvaluationStatus;
                  scope311EvaluationStatus: GQScope3EvaluationStatus;
                  scope312EvaluationStatus: GQScope3EvaluationStatus;
                  scope313EvaluationStatus: GQScope3EvaluationStatus;
                  scope314EvaluationStatus: GQScope3EvaluationStatus;
                  scope315EvaluationStatus: GQScope3EvaluationStatus;
                  scope316EvaluationStatus: GQScope3EvaluationStatus;
                  scope317EvaluationStatus: GQScope3EvaluationStatus;
                } | null;
              } | null;
              historicalEmissionsYears: Array<{
                __typename?: 'EmissionsYear';
                id: string;
                createdAt: Date;
                publicDisclosureId: string | null;
                privateDisclosureId: string | null;
                footprintEstimateOutputId: string | null;
                buildingEstimateOutputId: string | null;
                assetCorporateId: string | null;
                expenseCategory: string | null;
                reportingYear: number;
                numEmployees: number | null;
                revenue: number | null;
                revenueCurrency: string | null;
                currencyConversionRate: number | null;
                scope1: number | null;
                scope2: number | null;
                scope2Market: number | null;
                scope2Location: number | null;
                scope3: number | null;
                scope301: number | null;
                scope302: number | null;
                scope303: number | null;
                scope304: number | null;
                scope305: number | null;
                scope306: number | null;
                scope307: number | null;
                scope308: number | null;
                scope309: number | null;
                scope310: number | null;
                scope311: number | null;
                scope312: number | null;
                scope313: number | null;
                scope314: number | null;
                scope315: number | null;
                scope316: number | null;
                scope317: number | null;
                units: GQCompanyEmissionsUnits;
                percentageCleanEnergy: number | null;
                totalMwh: number | null;
                orgId: string | null;
                scenario: string | null;
                updatedAt: Date;
                pcafScoreScope1: number | null;
              }> | null;
              climateCommitments: Array<
                | {
                    __typename: 'CarbonNeutralCommitment';
                    id: string;
                    targetYear: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'CleanEnergyCommitment';
                    id: string;
                    targetYear: number | null;
                    targetPercentageCleanEnergy: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'NetZeroCommitment';
                    id: string;
                    targetYear: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'ScienceBasedTargetCommitment';
                    submittedToSBTi: boolean;
                    id: string;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                    commitment: {
                      __typename?: 'SBTCommitment';
                      id: string;
                      nearTermTargetYear: number | null;
                      longTermTargetYear: number | null;
                      baselineYear: number | null;
                      stage: GQSbtCommitmentStage | null;
                      targetClassification: GQSbtTargetClassification | null;
                      netZeroTargetYear: number | null;
                      commitmentDeadline: Date | null;
                      commitmentType: GQSbtCommitmentType | null;
                      netZeroCommitted: boolean | null;
                    };
                  }
              > | null;
            }>;
            logo: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            companyWebsite: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            sustainabilityWebsite: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            editor: { __typename?: 'User'; id: string; name: string } | null;
            sourceKey: {
              __typename?: 'CompanySourceKey';
              id: string;
              sourceKey: string;
              sourceUrl: string | null;
              sourceNotes: string | null;
              priority: number;
              userVisibleSourceUrl: string | null;
              userVisibleSourceNotes: string | null;
              createdAt: Date;
              updatedAt: Date;
              editor: { __typename?: 'User'; id: string; name: string } | null;
            } | null;
            replacedBy: {
              __typename: 'Company';
              id: string;
              name: string;
            } | null;
          }
        | { __typename: 'CompanyBea' }
        | { __typename: 'CompanyCountry' }
        | { __typename: 'CompanyIsin' }
        | { __typename: 'CompanyLei' }
        | { __typename: 'CompanyName' }
        | { __typename: 'CompanyPublicDisclosure' }
        | { __typename: 'CompanySAndP' }
        | { __typename: 'CompanyTickerSymbol' }
        | { __typename: 'CompanyUrl' }
        | { __typename: 'OrgCompany' }
        | { __typename: 'PrivateDisclosure' }
        | null;
    };
  };
};

export type GQDeclineCompanyIngestionUpdateMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQDeclineCompanyIngestionUpdateMutation = {
  __typename?: 'Mutation';
  declineCompanyIngestionUpdate: {
    __typename?: 'ProcessCompanyIngestionUpdatePayload';
    companyIngestionRecordUpdate: {
      __typename?: 'CompanyIngestionRecordUpdate';
      id: string;
      tableName: string;
      oldValue: any | null;
      newValue: any | null;
      status: GQCompanyIngestionRecordUpdateStatus;
      createdAt: Date;
      updatedAt: Date;
      diff: {
        __typename?: 'CompanyIngestionDiff';
        id: string;
        primaryCompanyName: string;
        companyMatchScore: string | null;
        companyMatchResults: any | null;
        processedAt: Date | null;
        createdAt: Date;
        updatedAt: Date;
        isCompanyDemo: boolean;
        sourceRecord:
          | {
              __typename: 'CdpVendorData';
              id: string;
              publishingYear: number;
              reportingYear: number;
              scope1Nonzero: boolean;
              scope2Nonzero: boolean;
              scope3Nonzero: boolean;
              totalEmissionsNonzero: boolean;
              scope301Or302Nonzero: boolean;
              scope1Verified: boolean;
              scope2Verified: boolean;
              scope3Verified: boolean;
              pctEvaluationStatusesMatchResponse: number;
              disclosureQualityScore: GQDisclosureQualityScore;
              scope301EvaluationStatus: GQScope3EvaluationStatus;
              scope302EvaluationStatus: GQScope3EvaluationStatus;
              scope303EvaluationStatus: GQScope3EvaluationStatus;
              scope304EvaluationStatus: GQScope3EvaluationStatus;
              scope305EvaluationStatus: GQScope3EvaluationStatus;
              scope306EvaluationStatus: GQScope3EvaluationStatus;
              scope307EvaluationStatus: GQScope3EvaluationStatus;
              scope308EvaluationStatus: GQScope3EvaluationStatus;
              scope309EvaluationStatus: GQScope3EvaluationStatus;
              scope310EvaluationStatus: GQScope3EvaluationStatus;
              scope311EvaluationStatus: GQScope3EvaluationStatus;
              scope312EvaluationStatus: GQScope3EvaluationStatus;
              scope313EvaluationStatus: GQScope3EvaluationStatus;
              scope314EvaluationStatus: GQScope3EvaluationStatus;
              scope315EvaluationStatus: GQScope3EvaluationStatus;
              scope316EvaluationStatus: GQScope3EvaluationStatus;
              scope317EvaluationStatus: GQScope3EvaluationStatus;
            }
          | {
              __typename: 'Company';
              id: string;
              name: string;
              description: string | null;
              url: string | null;
              fullLogoUrl: string | null;
              naicsCode: string | null;
              naicsCodeSource: string | null;
              countryAlpha2: string | null;
              sfdcOpportunityId: string | null;
              sfdcStage: string | null;
              isDemo: boolean;
              sustainabilityWebsiteUrl: string | null;
            }
          | {
              __typename: 'CompanyChangeRequest';
              id: string;
              action: GQCompanyChangeRequestAction;
              deletedAt: Date | null;
              payload: any;
              createdAt: Date;
              org: {
                __typename?: 'Organization';
                id: string;
                name: string;
                demoOrg: boolean;
              };
              userEditor: {
                __typename?: 'User';
                id: string;
                name: string;
              } | null;
            }
          | { __typename: 'IngestedSbtiCompanyUpdate'; id: string }
          | { __typename: 'SbtTarget'; id: string };
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          names: Array<{
            __typename?: 'CompanyName';
            id: string;
            name: string;
            cleanedName: string | null;
            isPrimary: boolean;
            sourceId: string;
          }>;
        } | null;
      };
      record:
        | { __typename: 'AssetCorporate' }
        | {
            __typename: 'Company';
            id: string;
            name: string;
            description: string | null;
            isDemo: boolean;
            createdAt: Date;
            updatedAt: Date;
            names: Array<{
              __typename?: 'CompanyName';
              id: string;
              name: string;
              cleanedName: string | null;
              isPrimary: boolean;
              sourceId: string;
            }>;
            isins: Array<{
              __typename?: 'CompanyIsin';
              id: string;
              isin: string;
              sourceId: string;
            }>;
            beas: Array<{
              __typename?: 'CompanyBea';
              id: string;
              bea: string;
              sourceId: string;
            }>;
            leis: Array<{
              __typename?: 'CompanyLei';
              id: string;
              lei: string;
              sourceId: string;
            }>;
            tickerSymbols: Array<{
              __typename?: 'CompanyTickerSymbol';
              id: string;
              tickerSymbol: string;
              exchangeSymbol: string | null;
              isSourcePrimary: boolean;
              sourceId: string;
            }>;
            sAndP: {
              __typename?: 'CompanySAndP';
              id: string;
              sAndPId: string;
              sourceId: string;
            } | null;
            countries: Array<{
              __typename?: 'CompanyCountry';
              id: string;
              countryAlpha2: string;
              isPrimary: boolean;
              sourceId: string;
            }>;
            disclosures: Array<{
              __typename?: 'Disclosure';
              id: string;
              publicDisclosure: {
                __typename?: 'PublicDisclosure';
                id: string;
                reportType: GQExternalReportType;
                companyId: string | null;
                publishingYear: number;
                reportingYears: Array<number>;
                publicUrl: string;
                qualityScore: GQDisclosureQualityScore | null;
                externalScore: string | null;
                gsUrl: string | null;
                source: GQPublicDisclosureSource;
                cdpVendorData: {
                  __typename?: 'CdpVendorData';
                  id: string;
                  publishingYear: number;
                  reportingYear: number;
                  scope1Nonzero: boolean;
                  scope2Nonzero: boolean;
                  scope3Nonzero: boolean;
                  totalEmissionsNonzero: boolean;
                  scope301Or302Nonzero: boolean;
                  scope1Verified: boolean;
                  scope2Verified: boolean;
                  scope3Verified: boolean;
                  pctEvaluationStatusesMatchResponse: number;
                  disclosureQualityScore: GQDisclosureQualityScore;
                  scope301EvaluationStatus: GQScope3EvaluationStatus;
                  scope302EvaluationStatus: GQScope3EvaluationStatus;
                  scope303EvaluationStatus: GQScope3EvaluationStatus;
                  scope304EvaluationStatus: GQScope3EvaluationStatus;
                  scope305EvaluationStatus: GQScope3EvaluationStatus;
                  scope306EvaluationStatus: GQScope3EvaluationStatus;
                  scope307EvaluationStatus: GQScope3EvaluationStatus;
                  scope308EvaluationStatus: GQScope3EvaluationStatus;
                  scope309EvaluationStatus: GQScope3EvaluationStatus;
                  scope310EvaluationStatus: GQScope3EvaluationStatus;
                  scope311EvaluationStatus: GQScope3EvaluationStatus;
                  scope312EvaluationStatus: GQScope3EvaluationStatus;
                  scope313EvaluationStatus: GQScope3EvaluationStatus;
                  scope314EvaluationStatus: GQScope3EvaluationStatus;
                  scope315EvaluationStatus: GQScope3EvaluationStatus;
                  scope316EvaluationStatus: GQScope3EvaluationStatus;
                  scope317EvaluationStatus: GQScope3EvaluationStatus;
                } | null;
              } | null;
              historicalEmissionsYears: Array<{
                __typename?: 'EmissionsYear';
                id: string;
                createdAt: Date;
                publicDisclosureId: string | null;
                privateDisclosureId: string | null;
                footprintEstimateOutputId: string | null;
                buildingEstimateOutputId: string | null;
                assetCorporateId: string | null;
                expenseCategory: string | null;
                reportingYear: number;
                numEmployees: number | null;
                revenue: number | null;
                revenueCurrency: string | null;
                currencyConversionRate: number | null;
                scope1: number | null;
                scope2: number | null;
                scope2Market: number | null;
                scope2Location: number | null;
                scope3: number | null;
                scope301: number | null;
                scope302: number | null;
                scope303: number | null;
                scope304: number | null;
                scope305: number | null;
                scope306: number | null;
                scope307: number | null;
                scope308: number | null;
                scope309: number | null;
                scope310: number | null;
                scope311: number | null;
                scope312: number | null;
                scope313: number | null;
                scope314: number | null;
                scope315: number | null;
                scope316: number | null;
                scope317: number | null;
                units: GQCompanyEmissionsUnits;
                percentageCleanEnergy: number | null;
                totalMwh: number | null;
                orgId: string | null;
                scenario: string | null;
                updatedAt: Date;
                pcafScoreScope1: number | null;
              }> | null;
              climateCommitments: Array<
                | {
                    __typename: 'CarbonNeutralCommitment';
                    id: string;
                    targetYear: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'CleanEnergyCommitment';
                    id: string;
                    targetYear: number | null;
                    targetPercentageCleanEnergy: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'NetZeroCommitment';
                    id: string;
                    targetYear: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'ScienceBasedTargetCommitment';
                    submittedToSBTi: boolean;
                    id: string;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                    commitment: {
                      __typename?: 'SBTCommitment';
                      id: string;
                      nearTermTargetYear: number | null;
                      longTermTargetYear: number | null;
                      baselineYear: number | null;
                      stage: GQSbtCommitmentStage | null;
                      targetClassification: GQSbtTargetClassification | null;
                      netZeroTargetYear: number | null;
                      commitmentDeadline: Date | null;
                      commitmentType: GQSbtCommitmentType | null;
                      netZeroCommitted: boolean | null;
                    };
                  }
              > | null;
            }>;
            logo: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            companyWebsite: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            sustainabilityWebsite: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            editor: { __typename?: 'User'; id: string; name: string } | null;
            sourceKey: {
              __typename?: 'CompanySourceKey';
              id: string;
              sourceKey: string;
              sourceUrl: string | null;
              sourceNotes: string | null;
              priority: number;
              userVisibleSourceUrl: string | null;
              userVisibleSourceNotes: string | null;
              createdAt: Date;
              updatedAt: Date;
              editor: { __typename?: 'User'; id: string; name: string } | null;
            } | null;
            replacedBy: {
              __typename: 'Company';
              id: string;
              name: string;
            } | null;
          }
        | { __typename: 'CompanyBea' }
        | { __typename: 'CompanyCountry' }
        | { __typename: 'CompanyIsin' }
        | { __typename: 'CompanyLei' }
        | { __typename: 'CompanyName' }
        | { __typename: 'CompanyPublicDisclosure' }
        | { __typename: 'CompanySAndP' }
        | { __typename: 'CompanyTickerSymbol' }
        | { __typename: 'CompanyUrl' }
        | { __typename: 'OrgCompany' }
        | { __typename: 'PrivateDisclosure' }
        | null;
    };
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      description: string | null;
      isDemo: boolean;
      createdAt: Date;
      updatedAt: Date;
      names: Array<{
        __typename?: 'CompanyName';
        id: string;
        name: string;
        cleanedName: string | null;
        isPrimary: boolean;
        sourceId: string;
      }>;
      isins: Array<{
        __typename?: 'CompanyIsin';
        id: string;
        isin: string;
        sourceId: string;
      }>;
      beas: Array<{
        __typename?: 'CompanyBea';
        id: string;
        bea: string;
        sourceId: string;
      }>;
      leis: Array<{
        __typename?: 'CompanyLei';
        id: string;
        lei: string;
        sourceId: string;
      }>;
      tickerSymbols: Array<{
        __typename?: 'CompanyTickerSymbol';
        id: string;
        tickerSymbol: string;
        exchangeSymbol: string | null;
        isSourcePrimary: boolean;
        sourceId: string;
      }>;
      sAndP: {
        __typename?: 'CompanySAndP';
        id: string;
        sAndPId: string;
        sourceId: string;
      } | null;
      countries: Array<{
        __typename?: 'CompanyCountry';
        id: string;
        countryAlpha2: string;
        isPrimary: boolean;
        sourceId: string;
      }>;
      disclosures: Array<{
        __typename?: 'Disclosure';
        id: string;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          reportType: GQExternalReportType;
          companyId: string | null;
          publishingYear: number;
          reportingYears: Array<number>;
          publicUrl: string;
          qualityScore: GQDisclosureQualityScore | null;
          externalScore: string | null;
          gsUrl: string | null;
          source: GQPublicDisclosureSource;
          cdpVendorData: {
            __typename?: 'CdpVendorData';
            id: string;
            publishingYear: number;
            reportingYear: number;
            scope1Nonzero: boolean;
            scope2Nonzero: boolean;
            scope3Nonzero: boolean;
            totalEmissionsNonzero: boolean;
            scope301Or302Nonzero: boolean;
            scope1Verified: boolean;
            scope2Verified: boolean;
            scope3Verified: boolean;
            pctEvaluationStatusesMatchResponse: number;
            disclosureQualityScore: GQDisclosureQualityScore;
            scope301EvaluationStatus: GQScope3EvaluationStatus;
            scope302EvaluationStatus: GQScope3EvaluationStatus;
            scope303EvaluationStatus: GQScope3EvaluationStatus;
            scope304EvaluationStatus: GQScope3EvaluationStatus;
            scope305EvaluationStatus: GQScope3EvaluationStatus;
            scope306EvaluationStatus: GQScope3EvaluationStatus;
            scope307EvaluationStatus: GQScope3EvaluationStatus;
            scope308EvaluationStatus: GQScope3EvaluationStatus;
            scope309EvaluationStatus: GQScope3EvaluationStatus;
            scope310EvaluationStatus: GQScope3EvaluationStatus;
            scope311EvaluationStatus: GQScope3EvaluationStatus;
            scope312EvaluationStatus: GQScope3EvaluationStatus;
            scope313EvaluationStatus: GQScope3EvaluationStatus;
            scope314EvaluationStatus: GQScope3EvaluationStatus;
            scope315EvaluationStatus: GQScope3EvaluationStatus;
            scope316EvaluationStatus: GQScope3EvaluationStatus;
            scope317EvaluationStatus: GQScope3EvaluationStatus;
          } | null;
        } | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          createdAt: Date;
          publicDisclosureId: string | null;
          privateDisclosureId: string | null;
          footprintEstimateOutputId: string | null;
          buildingEstimateOutputId: string | null;
          assetCorporateId: string | null;
          expenseCategory: string | null;
          reportingYear: number;
          numEmployees: number | null;
          revenue: number | null;
          revenueCurrency: string | null;
          currencyConversionRate: number | null;
          scope1: number | null;
          scope2: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
          units: GQCompanyEmissionsUnits;
          percentageCleanEnergy: number | null;
          totalMwh: number | null;
          orgId: string | null;
          scenario: string | null;
          updatedAt: Date;
          pcafScoreScope1: number | null;
        }> | null;
        climateCommitments: Array<
          | {
              __typename: 'CarbonNeutralCommitment';
              id: string;
              targetYear: number | null;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename: 'CleanEnergyCommitment';
              id: string;
              targetYear: number | null;
              targetPercentageCleanEnergy: number | null;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename: 'NetZeroCommitment';
              id: string;
              targetYear: number | null;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename: 'ScienceBasedTargetCommitment';
              submittedToSBTi: boolean;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
              commitment: {
                __typename?: 'SBTCommitment';
                id: string;
                nearTermTargetYear: number | null;
                longTermTargetYear: number | null;
                baselineYear: number | null;
                stage: GQSbtCommitmentStage | null;
                targetClassification: GQSbtTargetClassification | null;
                netZeroTargetYear: number | null;
                commitmentDeadline: Date | null;
                commitmentType: GQSbtCommitmentType | null;
                netZeroCommitted: boolean | null;
              };
            }
        > | null;
      }>;
      logo: {
        __typename?: 'CompanyUrl';
        id: string;
        url: string;
        sourceId: string;
        source: {
          __typename?: 'CompanySourceKey';
          id: string;
          sourceKey: string;
        };
      } | null;
      companyWebsite: {
        __typename?: 'CompanyUrl';
        id: string;
        url: string;
        sourceId: string;
        source: {
          __typename?: 'CompanySourceKey';
          id: string;
          sourceKey: string;
        };
      } | null;
      sustainabilityWebsite: {
        __typename?: 'CompanyUrl';
        id: string;
        url: string;
        sourceId: string;
        source: {
          __typename?: 'CompanySourceKey';
          id: string;
          sourceKey: string;
        };
      } | null;
      editor: { __typename?: 'User'; id: string; name: string } | null;
      sourceKey: {
        __typename?: 'CompanySourceKey';
        id: string;
        sourceKey: string;
        sourceUrl: string | null;
        sourceNotes: string | null;
        priority: number;
        userVisibleSourceUrl: string | null;
        userVisibleSourceNotes: string | null;
        createdAt: Date;
        updatedAt: Date;
        editor: { __typename?: 'User'; id: string; name: string } | null;
      } | null;
      replacedBy: { __typename: 'Company'; id: string; name: string } | null;
    } | null;
  };
};

export type GQApproveCompanyIngestionDiffMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQApproveCompanyIngestionDiffMutation = {
  __typename?: 'Mutation';
  approveCompanyIngestionDiff: {
    __typename?: 'ProcessCompanyIngestionDiffPayload';
    companyIngestionDiff: {
      __typename?: 'CompanyIngestionDiff';
      id: string;
      primaryCompanyName: string;
      companyMatchScore: string | null;
      companyMatchResults: any | null;
      processedAt: Date | null;
      createdAt: Date;
      updatedAt: Date;
      isCompanyDemo: boolean;
      sourceRecord:
        | {
            __typename: 'CdpVendorData';
            id: string;
            publishingYear: number;
            reportingYear: number;
            scope1Nonzero: boolean;
            scope2Nonzero: boolean;
            scope3Nonzero: boolean;
            totalEmissionsNonzero: boolean;
            scope301Or302Nonzero: boolean;
            scope1Verified: boolean;
            scope2Verified: boolean;
            scope3Verified: boolean;
            pctEvaluationStatusesMatchResponse: number;
            disclosureQualityScore: GQDisclosureQualityScore;
            scope301EvaluationStatus: GQScope3EvaluationStatus;
            scope302EvaluationStatus: GQScope3EvaluationStatus;
            scope303EvaluationStatus: GQScope3EvaluationStatus;
            scope304EvaluationStatus: GQScope3EvaluationStatus;
            scope305EvaluationStatus: GQScope3EvaluationStatus;
            scope306EvaluationStatus: GQScope3EvaluationStatus;
            scope307EvaluationStatus: GQScope3EvaluationStatus;
            scope308EvaluationStatus: GQScope3EvaluationStatus;
            scope309EvaluationStatus: GQScope3EvaluationStatus;
            scope310EvaluationStatus: GQScope3EvaluationStatus;
            scope311EvaluationStatus: GQScope3EvaluationStatus;
            scope312EvaluationStatus: GQScope3EvaluationStatus;
            scope313EvaluationStatus: GQScope3EvaluationStatus;
            scope314EvaluationStatus: GQScope3EvaluationStatus;
            scope315EvaluationStatus: GQScope3EvaluationStatus;
            scope316EvaluationStatus: GQScope3EvaluationStatus;
            scope317EvaluationStatus: GQScope3EvaluationStatus;
          }
        | {
            __typename: 'Company';
            id: string;
            name: string;
            description: string | null;
            url: string | null;
            fullLogoUrl: string | null;
            naicsCode: string | null;
            naicsCodeSource: string | null;
            countryAlpha2: string | null;
            sfdcOpportunityId: string | null;
            sfdcStage: string | null;
            isDemo: boolean;
            sustainabilityWebsiteUrl: string | null;
          }
        | {
            __typename: 'CompanyChangeRequest';
            id: string;
            action: GQCompanyChangeRequestAction;
            deletedAt: Date | null;
            payload: any;
            createdAt: Date;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
            };
            userEditor: {
              __typename?: 'User';
              id: string;
              name: string;
            } | null;
          }
        | { __typename: 'IngestedSbtiCompanyUpdate'; id: string }
        | { __typename: 'SbtTarget'; id: string };
      company: {
        __typename?: 'Company';
        id: string;
        name: string;
        names: Array<{
          __typename?: 'CompanyName';
          id: string;
          name: string;
          cleanedName: string | null;
          isPrimary: boolean;
          sourceId: string;
        }>;
      } | null;
    };
    companyIngestionRecordUpdates: Array<{
      __typename?: 'CompanyIngestionRecordUpdate';
      id: string;
      tableName: string;
      oldValue: any | null;
      newValue: any | null;
      status: GQCompanyIngestionRecordUpdateStatus;
      createdAt: Date;
      updatedAt: Date;
      diff: {
        __typename?: 'CompanyIngestionDiff';
        id: string;
        primaryCompanyName: string;
        companyMatchScore: string | null;
        companyMatchResults: any | null;
        processedAt: Date | null;
        createdAt: Date;
        updatedAt: Date;
        isCompanyDemo: boolean;
        sourceRecord:
          | {
              __typename: 'CdpVendorData';
              id: string;
              publishingYear: number;
              reportingYear: number;
              scope1Nonzero: boolean;
              scope2Nonzero: boolean;
              scope3Nonzero: boolean;
              totalEmissionsNonzero: boolean;
              scope301Or302Nonzero: boolean;
              scope1Verified: boolean;
              scope2Verified: boolean;
              scope3Verified: boolean;
              pctEvaluationStatusesMatchResponse: number;
              disclosureQualityScore: GQDisclosureQualityScore;
              scope301EvaluationStatus: GQScope3EvaluationStatus;
              scope302EvaluationStatus: GQScope3EvaluationStatus;
              scope303EvaluationStatus: GQScope3EvaluationStatus;
              scope304EvaluationStatus: GQScope3EvaluationStatus;
              scope305EvaluationStatus: GQScope3EvaluationStatus;
              scope306EvaluationStatus: GQScope3EvaluationStatus;
              scope307EvaluationStatus: GQScope3EvaluationStatus;
              scope308EvaluationStatus: GQScope3EvaluationStatus;
              scope309EvaluationStatus: GQScope3EvaluationStatus;
              scope310EvaluationStatus: GQScope3EvaluationStatus;
              scope311EvaluationStatus: GQScope3EvaluationStatus;
              scope312EvaluationStatus: GQScope3EvaluationStatus;
              scope313EvaluationStatus: GQScope3EvaluationStatus;
              scope314EvaluationStatus: GQScope3EvaluationStatus;
              scope315EvaluationStatus: GQScope3EvaluationStatus;
              scope316EvaluationStatus: GQScope3EvaluationStatus;
              scope317EvaluationStatus: GQScope3EvaluationStatus;
            }
          | {
              __typename: 'Company';
              id: string;
              name: string;
              description: string | null;
              url: string | null;
              fullLogoUrl: string | null;
              naicsCode: string | null;
              naicsCodeSource: string | null;
              countryAlpha2: string | null;
              sfdcOpportunityId: string | null;
              sfdcStage: string | null;
              isDemo: boolean;
              sustainabilityWebsiteUrl: string | null;
            }
          | {
              __typename: 'CompanyChangeRequest';
              id: string;
              action: GQCompanyChangeRequestAction;
              deletedAt: Date | null;
              payload: any;
              createdAt: Date;
              org: {
                __typename?: 'Organization';
                id: string;
                name: string;
                demoOrg: boolean;
              };
              userEditor: {
                __typename?: 'User';
                id: string;
                name: string;
              } | null;
            }
          | { __typename: 'IngestedSbtiCompanyUpdate'; id: string }
          | { __typename: 'SbtTarget'; id: string };
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          names: Array<{
            __typename?: 'CompanyName';
            id: string;
            name: string;
            cleanedName: string | null;
            isPrimary: boolean;
            sourceId: string;
          }>;
        } | null;
      };
      record:
        | { __typename: 'AssetCorporate' }
        | {
            __typename: 'Company';
            id: string;
            name: string;
            description: string | null;
            isDemo: boolean;
            createdAt: Date;
            updatedAt: Date;
            names: Array<{
              __typename?: 'CompanyName';
              id: string;
              name: string;
              cleanedName: string | null;
              isPrimary: boolean;
              sourceId: string;
            }>;
            isins: Array<{
              __typename?: 'CompanyIsin';
              id: string;
              isin: string;
              sourceId: string;
            }>;
            beas: Array<{
              __typename?: 'CompanyBea';
              id: string;
              bea: string;
              sourceId: string;
            }>;
            leis: Array<{
              __typename?: 'CompanyLei';
              id: string;
              lei: string;
              sourceId: string;
            }>;
            tickerSymbols: Array<{
              __typename?: 'CompanyTickerSymbol';
              id: string;
              tickerSymbol: string;
              exchangeSymbol: string | null;
              isSourcePrimary: boolean;
              sourceId: string;
            }>;
            sAndP: {
              __typename?: 'CompanySAndP';
              id: string;
              sAndPId: string;
              sourceId: string;
            } | null;
            countries: Array<{
              __typename?: 'CompanyCountry';
              id: string;
              countryAlpha2: string;
              isPrimary: boolean;
              sourceId: string;
            }>;
            disclosures: Array<{
              __typename?: 'Disclosure';
              id: string;
              publicDisclosure: {
                __typename?: 'PublicDisclosure';
                id: string;
                reportType: GQExternalReportType;
                companyId: string | null;
                publishingYear: number;
                reportingYears: Array<number>;
                publicUrl: string;
                qualityScore: GQDisclosureQualityScore | null;
                externalScore: string | null;
                gsUrl: string | null;
                source: GQPublicDisclosureSource;
                cdpVendorData: {
                  __typename?: 'CdpVendorData';
                  id: string;
                  publishingYear: number;
                  reportingYear: number;
                  scope1Nonzero: boolean;
                  scope2Nonzero: boolean;
                  scope3Nonzero: boolean;
                  totalEmissionsNonzero: boolean;
                  scope301Or302Nonzero: boolean;
                  scope1Verified: boolean;
                  scope2Verified: boolean;
                  scope3Verified: boolean;
                  pctEvaluationStatusesMatchResponse: number;
                  disclosureQualityScore: GQDisclosureQualityScore;
                  scope301EvaluationStatus: GQScope3EvaluationStatus;
                  scope302EvaluationStatus: GQScope3EvaluationStatus;
                  scope303EvaluationStatus: GQScope3EvaluationStatus;
                  scope304EvaluationStatus: GQScope3EvaluationStatus;
                  scope305EvaluationStatus: GQScope3EvaluationStatus;
                  scope306EvaluationStatus: GQScope3EvaluationStatus;
                  scope307EvaluationStatus: GQScope3EvaluationStatus;
                  scope308EvaluationStatus: GQScope3EvaluationStatus;
                  scope309EvaluationStatus: GQScope3EvaluationStatus;
                  scope310EvaluationStatus: GQScope3EvaluationStatus;
                  scope311EvaluationStatus: GQScope3EvaluationStatus;
                  scope312EvaluationStatus: GQScope3EvaluationStatus;
                  scope313EvaluationStatus: GQScope3EvaluationStatus;
                  scope314EvaluationStatus: GQScope3EvaluationStatus;
                  scope315EvaluationStatus: GQScope3EvaluationStatus;
                  scope316EvaluationStatus: GQScope3EvaluationStatus;
                  scope317EvaluationStatus: GQScope3EvaluationStatus;
                } | null;
              } | null;
              historicalEmissionsYears: Array<{
                __typename?: 'EmissionsYear';
                id: string;
                createdAt: Date;
                publicDisclosureId: string | null;
                privateDisclosureId: string | null;
                footprintEstimateOutputId: string | null;
                buildingEstimateOutputId: string | null;
                assetCorporateId: string | null;
                expenseCategory: string | null;
                reportingYear: number;
                numEmployees: number | null;
                revenue: number | null;
                revenueCurrency: string | null;
                currencyConversionRate: number | null;
                scope1: number | null;
                scope2: number | null;
                scope2Market: number | null;
                scope2Location: number | null;
                scope3: number | null;
                scope301: number | null;
                scope302: number | null;
                scope303: number | null;
                scope304: number | null;
                scope305: number | null;
                scope306: number | null;
                scope307: number | null;
                scope308: number | null;
                scope309: number | null;
                scope310: number | null;
                scope311: number | null;
                scope312: number | null;
                scope313: number | null;
                scope314: number | null;
                scope315: number | null;
                scope316: number | null;
                scope317: number | null;
                units: GQCompanyEmissionsUnits;
                percentageCleanEnergy: number | null;
                totalMwh: number | null;
                orgId: string | null;
                scenario: string | null;
                updatedAt: Date;
                pcafScoreScope1: number | null;
              }> | null;
              climateCommitments: Array<
                | {
                    __typename: 'CarbonNeutralCommitment';
                    id: string;
                    targetYear: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'CleanEnergyCommitment';
                    id: string;
                    targetYear: number | null;
                    targetPercentageCleanEnergy: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'NetZeroCommitment';
                    id: string;
                    targetYear: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'ScienceBasedTargetCommitment';
                    submittedToSBTi: boolean;
                    id: string;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                    commitment: {
                      __typename?: 'SBTCommitment';
                      id: string;
                      nearTermTargetYear: number | null;
                      longTermTargetYear: number | null;
                      baselineYear: number | null;
                      stage: GQSbtCommitmentStage | null;
                      targetClassification: GQSbtTargetClassification | null;
                      netZeroTargetYear: number | null;
                      commitmentDeadline: Date | null;
                      commitmentType: GQSbtCommitmentType | null;
                      netZeroCommitted: boolean | null;
                    };
                  }
              > | null;
            }>;
            logo: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            companyWebsite: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            sustainabilityWebsite: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            editor: { __typename?: 'User'; id: string; name: string } | null;
            sourceKey: {
              __typename?: 'CompanySourceKey';
              id: string;
              sourceKey: string;
              sourceUrl: string | null;
              sourceNotes: string | null;
              priority: number;
              userVisibleSourceUrl: string | null;
              userVisibleSourceNotes: string | null;
              createdAt: Date;
              updatedAt: Date;
              editor: { __typename?: 'User'; id: string; name: string } | null;
            } | null;
            replacedBy: {
              __typename: 'Company';
              id: string;
              name: string;
            } | null;
          }
        | { __typename: 'CompanyBea' }
        | { __typename: 'CompanyCountry' }
        | { __typename: 'CompanyIsin' }
        | { __typename: 'CompanyLei' }
        | { __typename: 'CompanyName' }
        | { __typename: 'CompanyPublicDisclosure' }
        | { __typename: 'CompanySAndP' }
        | { __typename: 'CompanyTickerSymbol' }
        | { __typename: 'CompanyUrl' }
        | { __typename: 'OrgCompany' }
        | { __typename: 'PrivateDisclosure' }
        | null;
    }>;
  };
};

export type GQDeclineCompanyIngestionDiffMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQDeclineCompanyIngestionDiffMutation = {
  __typename?: 'Mutation';
  declineCompanyIngestionDiff: {
    __typename?: 'ProcessCompanyIngestionDiffPayload';
    companyIngestionDiff: {
      __typename?: 'CompanyIngestionDiff';
      id: string;
      primaryCompanyName: string;
      companyMatchScore: string | null;
      companyMatchResults: any | null;
      processedAt: Date | null;
      createdAt: Date;
      updatedAt: Date;
      isCompanyDemo: boolean;
      sourceRecord:
        | {
            __typename: 'CdpVendorData';
            id: string;
            publishingYear: number;
            reportingYear: number;
            scope1Nonzero: boolean;
            scope2Nonzero: boolean;
            scope3Nonzero: boolean;
            totalEmissionsNonzero: boolean;
            scope301Or302Nonzero: boolean;
            scope1Verified: boolean;
            scope2Verified: boolean;
            scope3Verified: boolean;
            pctEvaluationStatusesMatchResponse: number;
            disclosureQualityScore: GQDisclosureQualityScore;
            scope301EvaluationStatus: GQScope3EvaluationStatus;
            scope302EvaluationStatus: GQScope3EvaluationStatus;
            scope303EvaluationStatus: GQScope3EvaluationStatus;
            scope304EvaluationStatus: GQScope3EvaluationStatus;
            scope305EvaluationStatus: GQScope3EvaluationStatus;
            scope306EvaluationStatus: GQScope3EvaluationStatus;
            scope307EvaluationStatus: GQScope3EvaluationStatus;
            scope308EvaluationStatus: GQScope3EvaluationStatus;
            scope309EvaluationStatus: GQScope3EvaluationStatus;
            scope310EvaluationStatus: GQScope3EvaluationStatus;
            scope311EvaluationStatus: GQScope3EvaluationStatus;
            scope312EvaluationStatus: GQScope3EvaluationStatus;
            scope313EvaluationStatus: GQScope3EvaluationStatus;
            scope314EvaluationStatus: GQScope3EvaluationStatus;
            scope315EvaluationStatus: GQScope3EvaluationStatus;
            scope316EvaluationStatus: GQScope3EvaluationStatus;
            scope317EvaluationStatus: GQScope3EvaluationStatus;
          }
        | {
            __typename: 'Company';
            id: string;
            name: string;
            description: string | null;
            url: string | null;
            fullLogoUrl: string | null;
            naicsCode: string | null;
            naicsCodeSource: string | null;
            countryAlpha2: string | null;
            sfdcOpportunityId: string | null;
            sfdcStage: string | null;
            isDemo: boolean;
            sustainabilityWebsiteUrl: string | null;
          }
        | {
            __typename: 'CompanyChangeRequest';
            id: string;
            action: GQCompanyChangeRequestAction;
            deletedAt: Date | null;
            payload: any;
            createdAt: Date;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
            };
            userEditor: {
              __typename?: 'User';
              id: string;
              name: string;
            } | null;
          }
        | { __typename: 'IngestedSbtiCompanyUpdate'; id: string }
        | { __typename: 'SbtTarget'; id: string };
      company: {
        __typename?: 'Company';
        id: string;
        name: string;
        names: Array<{
          __typename?: 'CompanyName';
          id: string;
          name: string;
          cleanedName: string | null;
          isPrimary: boolean;
          sourceId: string;
        }>;
      } | null;
    };
    companyIngestionRecordUpdates: Array<{
      __typename?: 'CompanyIngestionRecordUpdate';
      id: string;
      tableName: string;
      oldValue: any | null;
      newValue: any | null;
      status: GQCompanyIngestionRecordUpdateStatus;
      createdAt: Date;
      updatedAt: Date;
      diff: {
        __typename?: 'CompanyIngestionDiff';
        id: string;
        primaryCompanyName: string;
        companyMatchScore: string | null;
        companyMatchResults: any | null;
        processedAt: Date | null;
        createdAt: Date;
        updatedAt: Date;
        isCompanyDemo: boolean;
        sourceRecord:
          | {
              __typename: 'CdpVendorData';
              id: string;
              publishingYear: number;
              reportingYear: number;
              scope1Nonzero: boolean;
              scope2Nonzero: boolean;
              scope3Nonzero: boolean;
              totalEmissionsNonzero: boolean;
              scope301Or302Nonzero: boolean;
              scope1Verified: boolean;
              scope2Verified: boolean;
              scope3Verified: boolean;
              pctEvaluationStatusesMatchResponse: number;
              disclosureQualityScore: GQDisclosureQualityScore;
              scope301EvaluationStatus: GQScope3EvaluationStatus;
              scope302EvaluationStatus: GQScope3EvaluationStatus;
              scope303EvaluationStatus: GQScope3EvaluationStatus;
              scope304EvaluationStatus: GQScope3EvaluationStatus;
              scope305EvaluationStatus: GQScope3EvaluationStatus;
              scope306EvaluationStatus: GQScope3EvaluationStatus;
              scope307EvaluationStatus: GQScope3EvaluationStatus;
              scope308EvaluationStatus: GQScope3EvaluationStatus;
              scope309EvaluationStatus: GQScope3EvaluationStatus;
              scope310EvaluationStatus: GQScope3EvaluationStatus;
              scope311EvaluationStatus: GQScope3EvaluationStatus;
              scope312EvaluationStatus: GQScope3EvaluationStatus;
              scope313EvaluationStatus: GQScope3EvaluationStatus;
              scope314EvaluationStatus: GQScope3EvaluationStatus;
              scope315EvaluationStatus: GQScope3EvaluationStatus;
              scope316EvaluationStatus: GQScope3EvaluationStatus;
              scope317EvaluationStatus: GQScope3EvaluationStatus;
            }
          | {
              __typename: 'Company';
              id: string;
              name: string;
              description: string | null;
              url: string | null;
              fullLogoUrl: string | null;
              naicsCode: string | null;
              naicsCodeSource: string | null;
              countryAlpha2: string | null;
              sfdcOpportunityId: string | null;
              sfdcStage: string | null;
              isDemo: boolean;
              sustainabilityWebsiteUrl: string | null;
            }
          | {
              __typename: 'CompanyChangeRequest';
              id: string;
              action: GQCompanyChangeRequestAction;
              deletedAt: Date | null;
              payload: any;
              createdAt: Date;
              org: {
                __typename?: 'Organization';
                id: string;
                name: string;
                demoOrg: boolean;
              };
              userEditor: {
                __typename?: 'User';
                id: string;
                name: string;
              } | null;
            }
          | { __typename: 'IngestedSbtiCompanyUpdate'; id: string }
          | { __typename: 'SbtTarget'; id: string };
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          names: Array<{
            __typename?: 'CompanyName';
            id: string;
            name: string;
            cleanedName: string | null;
            isPrimary: boolean;
            sourceId: string;
          }>;
        } | null;
      };
      record:
        | { __typename: 'AssetCorporate' }
        | {
            __typename: 'Company';
            id: string;
            name: string;
            description: string | null;
            isDemo: boolean;
            createdAt: Date;
            updatedAt: Date;
            names: Array<{
              __typename?: 'CompanyName';
              id: string;
              name: string;
              cleanedName: string | null;
              isPrimary: boolean;
              sourceId: string;
            }>;
            isins: Array<{
              __typename?: 'CompanyIsin';
              id: string;
              isin: string;
              sourceId: string;
            }>;
            beas: Array<{
              __typename?: 'CompanyBea';
              id: string;
              bea: string;
              sourceId: string;
            }>;
            leis: Array<{
              __typename?: 'CompanyLei';
              id: string;
              lei: string;
              sourceId: string;
            }>;
            tickerSymbols: Array<{
              __typename?: 'CompanyTickerSymbol';
              id: string;
              tickerSymbol: string;
              exchangeSymbol: string | null;
              isSourcePrimary: boolean;
              sourceId: string;
            }>;
            sAndP: {
              __typename?: 'CompanySAndP';
              id: string;
              sAndPId: string;
              sourceId: string;
            } | null;
            countries: Array<{
              __typename?: 'CompanyCountry';
              id: string;
              countryAlpha2: string;
              isPrimary: boolean;
              sourceId: string;
            }>;
            disclosures: Array<{
              __typename?: 'Disclosure';
              id: string;
              publicDisclosure: {
                __typename?: 'PublicDisclosure';
                id: string;
                reportType: GQExternalReportType;
                companyId: string | null;
                publishingYear: number;
                reportingYears: Array<number>;
                publicUrl: string;
                qualityScore: GQDisclosureQualityScore | null;
                externalScore: string | null;
                gsUrl: string | null;
                source: GQPublicDisclosureSource;
                cdpVendorData: {
                  __typename?: 'CdpVendorData';
                  id: string;
                  publishingYear: number;
                  reportingYear: number;
                  scope1Nonzero: boolean;
                  scope2Nonzero: boolean;
                  scope3Nonzero: boolean;
                  totalEmissionsNonzero: boolean;
                  scope301Or302Nonzero: boolean;
                  scope1Verified: boolean;
                  scope2Verified: boolean;
                  scope3Verified: boolean;
                  pctEvaluationStatusesMatchResponse: number;
                  disclosureQualityScore: GQDisclosureQualityScore;
                  scope301EvaluationStatus: GQScope3EvaluationStatus;
                  scope302EvaluationStatus: GQScope3EvaluationStatus;
                  scope303EvaluationStatus: GQScope3EvaluationStatus;
                  scope304EvaluationStatus: GQScope3EvaluationStatus;
                  scope305EvaluationStatus: GQScope3EvaluationStatus;
                  scope306EvaluationStatus: GQScope3EvaluationStatus;
                  scope307EvaluationStatus: GQScope3EvaluationStatus;
                  scope308EvaluationStatus: GQScope3EvaluationStatus;
                  scope309EvaluationStatus: GQScope3EvaluationStatus;
                  scope310EvaluationStatus: GQScope3EvaluationStatus;
                  scope311EvaluationStatus: GQScope3EvaluationStatus;
                  scope312EvaluationStatus: GQScope3EvaluationStatus;
                  scope313EvaluationStatus: GQScope3EvaluationStatus;
                  scope314EvaluationStatus: GQScope3EvaluationStatus;
                  scope315EvaluationStatus: GQScope3EvaluationStatus;
                  scope316EvaluationStatus: GQScope3EvaluationStatus;
                  scope317EvaluationStatus: GQScope3EvaluationStatus;
                } | null;
              } | null;
              historicalEmissionsYears: Array<{
                __typename?: 'EmissionsYear';
                id: string;
                createdAt: Date;
                publicDisclosureId: string | null;
                privateDisclosureId: string | null;
                footprintEstimateOutputId: string | null;
                buildingEstimateOutputId: string | null;
                assetCorporateId: string | null;
                expenseCategory: string | null;
                reportingYear: number;
                numEmployees: number | null;
                revenue: number | null;
                revenueCurrency: string | null;
                currencyConversionRate: number | null;
                scope1: number | null;
                scope2: number | null;
                scope2Market: number | null;
                scope2Location: number | null;
                scope3: number | null;
                scope301: number | null;
                scope302: number | null;
                scope303: number | null;
                scope304: number | null;
                scope305: number | null;
                scope306: number | null;
                scope307: number | null;
                scope308: number | null;
                scope309: number | null;
                scope310: number | null;
                scope311: number | null;
                scope312: number | null;
                scope313: number | null;
                scope314: number | null;
                scope315: number | null;
                scope316: number | null;
                scope317: number | null;
                units: GQCompanyEmissionsUnits;
                percentageCleanEnergy: number | null;
                totalMwh: number | null;
                orgId: string | null;
                scenario: string | null;
                updatedAt: Date;
                pcafScoreScope1: number | null;
              }> | null;
              climateCommitments: Array<
                | {
                    __typename: 'CarbonNeutralCommitment';
                    id: string;
                    targetYear: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'CleanEnergyCommitment';
                    id: string;
                    targetYear: number | null;
                    targetPercentageCleanEnergy: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'NetZeroCommitment';
                    id: string;
                    targetYear: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'ScienceBasedTargetCommitment';
                    submittedToSBTi: boolean;
                    id: string;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                    commitment: {
                      __typename?: 'SBTCommitment';
                      id: string;
                      nearTermTargetYear: number | null;
                      longTermTargetYear: number | null;
                      baselineYear: number | null;
                      stage: GQSbtCommitmentStage | null;
                      targetClassification: GQSbtTargetClassification | null;
                      netZeroTargetYear: number | null;
                      commitmentDeadline: Date | null;
                      commitmentType: GQSbtCommitmentType | null;
                      netZeroCommitted: boolean | null;
                    };
                  }
              > | null;
            }>;
            logo: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            companyWebsite: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            sustainabilityWebsite: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            editor: { __typename?: 'User'; id: string; name: string } | null;
            sourceKey: {
              __typename?: 'CompanySourceKey';
              id: string;
              sourceKey: string;
              sourceUrl: string | null;
              sourceNotes: string | null;
              priority: number;
              userVisibleSourceUrl: string | null;
              userVisibleSourceNotes: string | null;
              createdAt: Date;
              updatedAt: Date;
              editor: { __typename?: 'User'; id: string; name: string } | null;
            } | null;
            replacedBy: {
              __typename: 'Company';
              id: string;
              name: string;
            } | null;
          }
        | { __typename: 'CompanyBea' }
        | { __typename: 'CompanyCountry' }
        | { __typename: 'CompanyIsin' }
        | { __typename: 'CompanyLei' }
        | { __typename: 'CompanyName' }
        | { __typename: 'CompanyPublicDisclosure' }
        | { __typename: 'CompanySAndP' }
        | { __typename: 'CompanyTickerSymbol' }
        | { __typename: 'CompanyUrl' }
        | { __typename: 'OrgCompany' }
        | { __typename: 'PrivateDisclosure' }
        | null;
    }>;
  };
};

export type GQReingestCompanyIngestionDiffsMutationVariables = Exact<{
  input: GQReingestCompanyIngestionDiffInput;
}>;

export type GQReingestCompanyIngestionDiffsMutation = {
  __typename?: 'Mutation';
  reingestCompanyIngestionDiffs: {
    __typename?: 'ReingestCompanyIngestionDiffPayload';
    companyIngestionDiffs: Array<{
      __typename?: 'CompanyIngestionDiff';
      id: string;
      primaryCompanyName: string;
      companyMatchScore: string | null;
      companyMatchResults: any | null;
      processedAt: Date | null;
      createdAt: Date;
      updatedAt: Date;
      isCompanyDemo: boolean;
      sourceRecord:
        | {
            __typename: 'CdpVendorData';
            id: string;
            publishingYear: number;
            reportingYear: number;
            scope1Nonzero: boolean;
            scope2Nonzero: boolean;
            scope3Nonzero: boolean;
            totalEmissionsNonzero: boolean;
            scope301Or302Nonzero: boolean;
            scope1Verified: boolean;
            scope2Verified: boolean;
            scope3Verified: boolean;
            pctEvaluationStatusesMatchResponse: number;
            disclosureQualityScore: GQDisclosureQualityScore;
            scope301EvaluationStatus: GQScope3EvaluationStatus;
            scope302EvaluationStatus: GQScope3EvaluationStatus;
            scope303EvaluationStatus: GQScope3EvaluationStatus;
            scope304EvaluationStatus: GQScope3EvaluationStatus;
            scope305EvaluationStatus: GQScope3EvaluationStatus;
            scope306EvaluationStatus: GQScope3EvaluationStatus;
            scope307EvaluationStatus: GQScope3EvaluationStatus;
            scope308EvaluationStatus: GQScope3EvaluationStatus;
            scope309EvaluationStatus: GQScope3EvaluationStatus;
            scope310EvaluationStatus: GQScope3EvaluationStatus;
            scope311EvaluationStatus: GQScope3EvaluationStatus;
            scope312EvaluationStatus: GQScope3EvaluationStatus;
            scope313EvaluationStatus: GQScope3EvaluationStatus;
            scope314EvaluationStatus: GQScope3EvaluationStatus;
            scope315EvaluationStatus: GQScope3EvaluationStatus;
            scope316EvaluationStatus: GQScope3EvaluationStatus;
            scope317EvaluationStatus: GQScope3EvaluationStatus;
          }
        | {
            __typename: 'Company';
            id: string;
            name: string;
            description: string | null;
            url: string | null;
            fullLogoUrl: string | null;
            naicsCode: string | null;
            naicsCodeSource: string | null;
            countryAlpha2: string | null;
            sfdcOpportunityId: string | null;
            sfdcStage: string | null;
            isDemo: boolean;
            sustainabilityWebsiteUrl: string | null;
          }
        | {
            __typename: 'CompanyChangeRequest';
            id: string;
            action: GQCompanyChangeRequestAction;
            deletedAt: Date | null;
            payload: any;
            createdAt: Date;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
            };
            userEditor: {
              __typename?: 'User';
              id: string;
              name: string;
            } | null;
          }
        | { __typename: 'IngestedSbtiCompanyUpdate'; id: string }
        | { __typename: 'SbtTarget'; id: string };
      company: {
        __typename?: 'Company';
        id: string;
        name: string;
        names: Array<{
          __typename?: 'CompanyName';
          id: string;
          name: string;
          cleanedName: string | null;
          isPrimary: boolean;
          sourceId: string;
        }>;
      } | null;
    }>;
    companyIngestionRecordUpdates: Array<{
      __typename?: 'CompanyIngestionRecordUpdate';
      id: string;
      tableName: string;
      oldValue: any | null;
      newValue: any | null;
      status: GQCompanyIngestionRecordUpdateStatus;
      createdAt: Date;
      updatedAt: Date;
      diff: {
        __typename?: 'CompanyIngestionDiff';
        id: string;
        primaryCompanyName: string;
        companyMatchScore: string | null;
        companyMatchResults: any | null;
        processedAt: Date | null;
        createdAt: Date;
        updatedAt: Date;
        isCompanyDemo: boolean;
        sourceRecord:
          | {
              __typename: 'CdpVendorData';
              id: string;
              publishingYear: number;
              reportingYear: number;
              scope1Nonzero: boolean;
              scope2Nonzero: boolean;
              scope3Nonzero: boolean;
              totalEmissionsNonzero: boolean;
              scope301Or302Nonzero: boolean;
              scope1Verified: boolean;
              scope2Verified: boolean;
              scope3Verified: boolean;
              pctEvaluationStatusesMatchResponse: number;
              disclosureQualityScore: GQDisclosureQualityScore;
              scope301EvaluationStatus: GQScope3EvaluationStatus;
              scope302EvaluationStatus: GQScope3EvaluationStatus;
              scope303EvaluationStatus: GQScope3EvaluationStatus;
              scope304EvaluationStatus: GQScope3EvaluationStatus;
              scope305EvaluationStatus: GQScope3EvaluationStatus;
              scope306EvaluationStatus: GQScope3EvaluationStatus;
              scope307EvaluationStatus: GQScope3EvaluationStatus;
              scope308EvaluationStatus: GQScope3EvaluationStatus;
              scope309EvaluationStatus: GQScope3EvaluationStatus;
              scope310EvaluationStatus: GQScope3EvaluationStatus;
              scope311EvaluationStatus: GQScope3EvaluationStatus;
              scope312EvaluationStatus: GQScope3EvaluationStatus;
              scope313EvaluationStatus: GQScope3EvaluationStatus;
              scope314EvaluationStatus: GQScope3EvaluationStatus;
              scope315EvaluationStatus: GQScope3EvaluationStatus;
              scope316EvaluationStatus: GQScope3EvaluationStatus;
              scope317EvaluationStatus: GQScope3EvaluationStatus;
            }
          | {
              __typename: 'Company';
              id: string;
              name: string;
              description: string | null;
              url: string | null;
              fullLogoUrl: string | null;
              naicsCode: string | null;
              naicsCodeSource: string | null;
              countryAlpha2: string | null;
              sfdcOpportunityId: string | null;
              sfdcStage: string | null;
              isDemo: boolean;
              sustainabilityWebsiteUrl: string | null;
            }
          | {
              __typename: 'CompanyChangeRequest';
              id: string;
              action: GQCompanyChangeRequestAction;
              deletedAt: Date | null;
              payload: any;
              createdAt: Date;
              org: {
                __typename?: 'Organization';
                id: string;
                name: string;
                demoOrg: boolean;
              };
              userEditor: {
                __typename?: 'User';
                id: string;
                name: string;
              } | null;
            }
          | { __typename: 'IngestedSbtiCompanyUpdate'; id: string }
          | { __typename: 'SbtTarget'; id: string };
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          names: Array<{
            __typename?: 'CompanyName';
            id: string;
            name: string;
            cleanedName: string | null;
            isPrimary: boolean;
            sourceId: string;
          }>;
        } | null;
      };
      record:
        | { __typename: 'AssetCorporate' }
        | {
            __typename: 'Company';
            id: string;
            name: string;
            description: string | null;
            isDemo: boolean;
            createdAt: Date;
            updatedAt: Date;
            names: Array<{
              __typename?: 'CompanyName';
              id: string;
              name: string;
              cleanedName: string | null;
              isPrimary: boolean;
              sourceId: string;
            }>;
            isins: Array<{
              __typename?: 'CompanyIsin';
              id: string;
              isin: string;
              sourceId: string;
            }>;
            beas: Array<{
              __typename?: 'CompanyBea';
              id: string;
              bea: string;
              sourceId: string;
            }>;
            leis: Array<{
              __typename?: 'CompanyLei';
              id: string;
              lei: string;
              sourceId: string;
            }>;
            tickerSymbols: Array<{
              __typename?: 'CompanyTickerSymbol';
              id: string;
              tickerSymbol: string;
              exchangeSymbol: string | null;
              isSourcePrimary: boolean;
              sourceId: string;
            }>;
            sAndP: {
              __typename?: 'CompanySAndP';
              id: string;
              sAndPId: string;
              sourceId: string;
            } | null;
            countries: Array<{
              __typename?: 'CompanyCountry';
              id: string;
              countryAlpha2: string;
              isPrimary: boolean;
              sourceId: string;
            }>;
            disclosures: Array<{
              __typename?: 'Disclosure';
              id: string;
              publicDisclosure: {
                __typename?: 'PublicDisclosure';
                id: string;
                reportType: GQExternalReportType;
                companyId: string | null;
                publishingYear: number;
                reportingYears: Array<number>;
                publicUrl: string;
                qualityScore: GQDisclosureQualityScore | null;
                externalScore: string | null;
                gsUrl: string | null;
                source: GQPublicDisclosureSource;
                cdpVendorData: {
                  __typename?: 'CdpVendorData';
                  id: string;
                  publishingYear: number;
                  reportingYear: number;
                  scope1Nonzero: boolean;
                  scope2Nonzero: boolean;
                  scope3Nonzero: boolean;
                  totalEmissionsNonzero: boolean;
                  scope301Or302Nonzero: boolean;
                  scope1Verified: boolean;
                  scope2Verified: boolean;
                  scope3Verified: boolean;
                  pctEvaluationStatusesMatchResponse: number;
                  disclosureQualityScore: GQDisclosureQualityScore;
                  scope301EvaluationStatus: GQScope3EvaluationStatus;
                  scope302EvaluationStatus: GQScope3EvaluationStatus;
                  scope303EvaluationStatus: GQScope3EvaluationStatus;
                  scope304EvaluationStatus: GQScope3EvaluationStatus;
                  scope305EvaluationStatus: GQScope3EvaluationStatus;
                  scope306EvaluationStatus: GQScope3EvaluationStatus;
                  scope307EvaluationStatus: GQScope3EvaluationStatus;
                  scope308EvaluationStatus: GQScope3EvaluationStatus;
                  scope309EvaluationStatus: GQScope3EvaluationStatus;
                  scope310EvaluationStatus: GQScope3EvaluationStatus;
                  scope311EvaluationStatus: GQScope3EvaluationStatus;
                  scope312EvaluationStatus: GQScope3EvaluationStatus;
                  scope313EvaluationStatus: GQScope3EvaluationStatus;
                  scope314EvaluationStatus: GQScope3EvaluationStatus;
                  scope315EvaluationStatus: GQScope3EvaluationStatus;
                  scope316EvaluationStatus: GQScope3EvaluationStatus;
                  scope317EvaluationStatus: GQScope3EvaluationStatus;
                } | null;
              } | null;
              historicalEmissionsYears: Array<{
                __typename?: 'EmissionsYear';
                id: string;
                createdAt: Date;
                publicDisclosureId: string | null;
                privateDisclosureId: string | null;
                footprintEstimateOutputId: string | null;
                buildingEstimateOutputId: string | null;
                assetCorporateId: string | null;
                expenseCategory: string | null;
                reportingYear: number;
                numEmployees: number | null;
                revenue: number | null;
                revenueCurrency: string | null;
                currencyConversionRate: number | null;
                scope1: number | null;
                scope2: number | null;
                scope2Market: number | null;
                scope2Location: number | null;
                scope3: number | null;
                scope301: number | null;
                scope302: number | null;
                scope303: number | null;
                scope304: number | null;
                scope305: number | null;
                scope306: number | null;
                scope307: number | null;
                scope308: number | null;
                scope309: number | null;
                scope310: number | null;
                scope311: number | null;
                scope312: number | null;
                scope313: number | null;
                scope314: number | null;
                scope315: number | null;
                scope316: number | null;
                scope317: number | null;
                units: GQCompanyEmissionsUnits;
                percentageCleanEnergy: number | null;
                totalMwh: number | null;
                orgId: string | null;
                scenario: string | null;
                updatedAt: Date;
                pcafScoreScope1: number | null;
              }> | null;
              climateCommitments: Array<
                | {
                    __typename: 'CarbonNeutralCommitment';
                    id: string;
                    targetYear: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'CleanEnergyCommitment';
                    id: string;
                    targetYear: number | null;
                    targetPercentageCleanEnergy: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'NetZeroCommitment';
                    id: string;
                    targetYear: number | null;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename: 'ScienceBasedTargetCommitment';
                    submittedToSBTi: boolean;
                    id: string;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                    commitment: {
                      __typename?: 'SBTCommitment';
                      id: string;
                      nearTermTargetYear: number | null;
                      longTermTargetYear: number | null;
                      baselineYear: number | null;
                      stage: GQSbtCommitmentStage | null;
                      targetClassification: GQSbtTargetClassification | null;
                      netZeroTargetYear: number | null;
                      commitmentDeadline: Date | null;
                      commitmentType: GQSbtCommitmentType | null;
                      netZeroCommitted: boolean | null;
                    };
                  }
              > | null;
            }>;
            logo: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            companyWebsite: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            sustainabilityWebsite: {
              __typename?: 'CompanyUrl';
              id: string;
              url: string;
              sourceId: string;
              source: {
                __typename?: 'CompanySourceKey';
                id: string;
                sourceKey: string;
              };
            } | null;
            editor: { __typename?: 'User'; id: string; name: string } | null;
            sourceKey: {
              __typename?: 'CompanySourceKey';
              id: string;
              sourceKey: string;
              sourceUrl: string | null;
              sourceNotes: string | null;
              priority: number;
              userVisibleSourceUrl: string | null;
              userVisibleSourceNotes: string | null;
              createdAt: Date;
              updatedAt: Date;
              editor: { __typename?: 'User'; id: string; name: string } | null;
            } | null;
            replacedBy: {
              __typename: 'Company';
              id: string;
              name: string;
            } | null;
          }
        | { __typename: 'CompanyBea' }
        | { __typename: 'CompanyCountry' }
        | { __typename: 'CompanyIsin' }
        | { __typename: 'CompanyLei' }
        | { __typename: 'CompanyName' }
        | { __typename: 'CompanyPublicDisclosure' }
        | { __typename: 'CompanySAndP' }
        | { __typename: 'CompanyTickerSymbol' }
        | { __typename: 'CompanyUrl' }
        | { __typename: 'OrgCompany' }
        | { __typename: 'PrivateDisclosure' }
        | null;
    }>;
  };
};

export type GQGetPublicDisclosureForIngestionRecordQueryVariables = Exact<{
  publicDisclosureId: InputMaybe<Scalars['ID']['input']>;
  privateDisclosureId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQGetPublicDisclosureForIngestionRecordQuery = {
  __typename?: 'Query';
  disclosure: {
    __typename?: 'Disclosure';
    id: string;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      reportType: GQExternalReportType;
      companyId: string | null;
      publishingYear: number;
      reportingYears: Array<number>;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      externalScore: string | null;
      gsUrl: string | null;
      source: GQPublicDisclosureSource;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      companyId: string | null;
      assetCorporateId: string | null;
      assetPersonalMotorVehicleInsuranceId: string | null;
      orgId: string;
      footprintDisclosureId: string | null;
      surveyId: string | null;
      privateDisclosureType: GQPrivateDisclosureType;
      createdAt: Date;
      disclosureQualityScore: GQDisclosureQualityScore | null;
      disclosureQualityExplanation: string | null;
      thirdPartyVerified: boolean;
      updatedAt: Date;
      userEditorId: string;
      watershedEditorId: string | null;
      engagementTaskId: string | null;
      sourceId: string;
      companyIngestionRecordUpdateId: string | null;
    } | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      createdAt: Date;
      publicDisclosureId: string | null;
      privateDisclosureId: string | null;
      footprintEstimateOutputId: string | null;
      buildingEstimateOutputId: string | null;
      assetCorporateId: string | null;
      expenseCategory: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      currencyConversionRate: number | null;
      scope1: number | null;
      scope2: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
      units: GQCompanyEmissionsUnits;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      orgId: string | null;
      scenario: string | null;
      updatedAt: Date;
      pcafScoreScope1: number | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename: 'CarbonNeutralCommitment';
          id: string;
          targetYear: number | null;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename: 'CleanEnergyCommitment';
          id: string;
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename: 'NetZeroCommitment';
          id: string;
          targetYear: number | null;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            netZeroTargetYear: number | null;
            commitmentDeadline: Date | null;
            commitmentType: GQSbtCommitmentType | null;
            netZeroCommitted: boolean | null;
          };
        }
    > | null;
  } | null;
};

export type GQCompanyIngestionDiffErrorsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQCompanyIngestionDiffErrorsQuery = {
  __typename?: 'Query';
  companyIngestionDiffErrors: Array<{
    __typename?: 'CompanyIngestionDiffError';
    id: string;
    createdAt: Date;
    companyIngestionDiffId: string;
    errorCode: string;
    errorData: string;
    status: string;
  }>;
};

export type GQCompanyListFieldsFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
  description: string | null;
  url: string | null;
  fullLogoUrl: string | null;
  naicsCode: string | null;
  naicsCodeSource: string | null;
  countryAlpha2: string | null;
  sfdcOpportunityId: string | null;
  sfdcStage: string | null;
  isDemo: boolean;
  sustainabilityWebsiteUrl: string | null;
};

export type GQCreateCtsMutationVariables = Exact<{
  input: GQCreateCtsInput;
}>;

export type GQCreateCtsMutation = {
  __typename?: 'Mutation';
  createCts: {
    __typename?: 'CreateCtsPayload';
    schema: {
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      descriptionMd: string | null;
      rank: number;
      deletedAt: Date | null;
      versions: Array<{
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        createdAt: Date;
        publishedAt: Date | null;
        publishedForTestingAt: Date | null;
        unpublishedAt: Date | null;
        lastEditedByUser: { __typename?: 'User'; id: string; name: string };
        transforms: Array<{
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          testCases: Array<{
            __typename?: 'CustomerTargetSchemaTransformTestCase';
            id: string;
            kind: GQCtsTransformTestKind;
          }>;
        }>;
      }>;
      canonicalDatasets: Array<{
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      }>;
    };
  };
};

export type GQUpdateCtsMutationVariables = Exact<{
  input: GQUpdateCtsInput;
}>;

export type GQUpdateCtsMutation = {
  __typename?: 'Mutation';
  updateCts: {
    __typename?: 'UpdateCtsPayload';
    schema: {
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      descriptionMd: string | null;
      rank: number;
      deletedAt: Date | null;
      versions: Array<{
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        createdAt: Date;
        publishedAt: Date | null;
        publishedForTestingAt: Date | null;
        unpublishedAt: Date | null;
        lastEditedByUser: { __typename?: 'User'; id: string; name: string };
        transforms: Array<{
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          testCases: Array<{
            __typename?: 'CustomerTargetSchemaTransformTestCase';
            id: string;
            kind: GQCtsTransformTestKind;
          }>;
        }>;
      }>;
      canonicalDatasets: Array<{
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      }>;
    };
  };
};

export type GQCtsInfoHeaderFragment = {
  __typename?: 'CustomerTargetSchema';
  id: string;
  name: string;
  descriptionMd: string | null;
  rank: number;
  schemaId: string | null;
  versions: Array<{
    __typename?: 'CustomerTargetSchemaVersion';
    id: string;
    schemaJson: any;
    semverVersion: string;
    versionNotesMd: string;
    exampleData: any;
    formattingNotesMd: string | null;
    createdAt: Date;
    publishedAt: Date | null;
    publishedForTestingAt: Date | null;
    unpublishedAt: Date | null;
    schemaVersionId: string | null;
    schema: {
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      versions: Array<{
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        publishedAt: Date | null;
        publishedForTestingAt: Date | null;
        unpublishedAt: Date | null;
      }>;
    };
    globalIngestionExampleData: {
      __typename?: 'IngestionExampleData';
      id: string;
      title: string;
      datasetId: string | null;
      columns: Array<{
        __typename?: 'IngestionExampleDataColumn';
        fieldName: string;
        title: string;
        description: string;
        examples: Array<string>;
        required: boolean;
        optionalToMap: boolean | null;
        defaultValue: any | null;
      }>;
      rows: Array<{
        __typename?: 'IngestionExampleDataRow';
        fields: Array<{
          __typename?: 'IngestionExampleDataRowField';
          fieldName: string;
          value: string;
        }>;
      }>;
    };
    transformsIncludingDeleted: Array<{
      __typename?: 'CustomerTargetSchemaTransform';
      id: string;
      deletedAt: Date | null;
      sql: string;
      transformTarget: GQCustomerTargetSchemaTransformTarget;
      businessActivityTypeName: string | null;
      businessActivityTypeVersionId: string | null;
      businessActivityTypeVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
      runs: Array<{
        __typename?: 'CustomerTargetSchemaTransformRun';
        id: string;
        sql: string;
        error: string | null;
        createdAt: Date;
        transform: {
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          transformTarget: GQCustomerTargetSchemaTransformTarget;
          businessActivityTypeName: string | null;
        };
        input: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          category: GQFileCategory;
          revisionRootFileMetadata: {
            __typename?: 'FileMetadata';
            id: string;
            name: string;
          } | null;
        };
        output: {
          __typename?: 'ParquetData';
          id: string;
          numRecords: number;
          ndjsonSignedUrl: string | null;
          ndjsonSize: number | null;
          ndjsonByteBoundaries: Array<number> | null;
          columns: Array<{
            __typename?: 'ParquetColumn';
            name: string;
            sqlType: string;
            minValue: any;
            maxValue: any;
            numNulls: number;
            approxDistinctValues: number;
            sampleValues: Array<any>;
          }>;
        } | null;
        standardizedOutput: {
          __typename?: 'ParquetData';
          id: string;
          numRecords: number;
          ndjsonSignedUrl: string | null;
          ndjsonSize: number | null;
          ndjsonByteBoundaries: Array<number> | null;
          columns: Array<{
            __typename?: 'ParquetColumn';
            name: string;
            sqlType: string;
            minValue: any;
            maxValue: any;
            numNulls: number;
            approxDistinctValues: number;
            sampleValues: Array<any>;
          }>;
        } | null;
        warnings: Array<{
          __typename?: 'ValidationWarning';
          blocking: boolean;
          message: string;
        }>;
        runner: { __typename?: 'User'; id: string; name: string };
      }>;
      testCases: Array<{
        __typename?: 'CustomerTargetSchemaTransformTestCase';
        id: string;
        name: string;
        inputJson: any | null;
        kind: GQCtsTransformTestKind;
        expectedOutput: {
          __typename?: 'ParquetData';
          id: string;
          numRecords: number;
          ndjsonSignedUrl: string | null;
          ndjsonSize: number | null;
          ndjsonByteBoundaries: Array<number> | null;
          columns: Array<{
            __typename?: 'ParquetColumn';
            name: string;
            sqlType: string;
            minValue: any;
            maxValue: any;
            numNulls: number;
            approxDistinctValues: number;
            sampleValues: Array<any>;
          }>;
        } | null;
        latestRun: {
          __typename?: 'CustomerTargetSchemaTransformTestRun';
          id: string;
          status: GQCtsTestRunStatus;
          testDiffResults: any | null;
          transformRun: {
            __typename?: 'CustomerTargetSchemaTransformRun';
            id: string;
            error: string | null;
            standardizedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
          };
        } | null;
      }>;
    }>;
    lastEditedByUser: { __typename?: 'User'; id: string; name: string };
  }>;
  latestPublishedVersion: {
    __typename?: 'CustomerTargetSchemaVersion';
    id: string;
    publishedAt: Date | null;
  } | null;
  canonicalDatasets: Array<{
    __typename?: 'CanonicalDataset';
    id: string;
    name: string;
  }>;
};

export type GQConvertCtsToTSchemaMutationVariables = Exact<{
  input: GQConvertCtsToTSchemaInput;
}>;

export type GQConvertCtsToTSchemaMutation = {
  __typename?: 'Mutation';
  convertCtsToTSchema: {
    __typename?: 'ConvertCtsToTSchemaPayload';
    cts: {
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      descriptionMd: string | null;
      rank: number;
      schemaId: string | null;
      versions: Array<{
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        schemaJson: any;
        semverVersion: string;
        versionNotesMd: string;
        exampleData: any;
        formattingNotesMd: string | null;
        createdAt: Date;
        publishedAt: Date | null;
        publishedForTestingAt: Date | null;
        unpublishedAt: Date | null;
        schemaVersionId: string | null;
        schema: {
          __typename?: 'CustomerTargetSchema';
          id: string;
          name: string;
          versions: Array<{
            __typename?: 'CustomerTargetSchemaVersion';
            id: string;
            publishedAt: Date | null;
            publishedForTestingAt: Date | null;
            unpublishedAt: Date | null;
          }>;
        };
        globalIngestionExampleData: {
          __typename?: 'IngestionExampleData';
          id: string;
          title: string;
          datasetId: string | null;
          columns: Array<{
            __typename?: 'IngestionExampleDataColumn';
            fieldName: string;
            title: string;
            description: string;
            examples: Array<string>;
            required: boolean;
            optionalToMap: boolean | null;
            defaultValue: any | null;
          }>;
          rows: Array<{
            __typename?: 'IngestionExampleDataRow';
            fields: Array<{
              __typename?: 'IngestionExampleDataRowField';
              fieldName: string;
              value: string;
            }>;
          }>;
        };
        transformsIncludingDeleted: Array<{
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          deletedAt: Date | null;
          sql: string;
          transformTarget: GQCustomerTargetSchemaTransformTarget;
          businessActivityTypeName: string | null;
          businessActivityTypeVersionId: string | null;
          businessActivityTypeVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
            name: string;
          } | null;
          runs: Array<{
            __typename?: 'CustomerTargetSchemaTransformRun';
            id: string;
            sql: string;
            error: string | null;
            createdAt: Date;
            transform: {
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              transformTarget: GQCustomerTargetSchemaTransformTarget;
              businessActivityTypeName: string | null;
            };
            input: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              category: GQFileCategory;
              revisionRootFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              } | null;
            };
            output: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            standardizedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            warnings: Array<{
              __typename?: 'ValidationWarning';
              blocking: boolean;
              message: string;
            }>;
            runner: { __typename?: 'User'; id: string; name: string };
          }>;
          testCases: Array<{
            __typename?: 'CustomerTargetSchemaTransformTestCase';
            id: string;
            name: string;
            inputJson: any | null;
            kind: GQCtsTransformTestKind;
            expectedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            latestRun: {
              __typename?: 'CustomerTargetSchemaTransformTestRun';
              id: string;
              status: GQCtsTestRunStatus;
              testDiffResults: any | null;
              transformRun: {
                __typename?: 'CustomerTargetSchemaTransformRun';
                id: string;
                error: string | null;
                standardizedOutput: {
                  __typename?: 'ParquetData';
                  id: string;
                  numRecords: number;
                  ndjsonSignedUrl: string | null;
                  ndjsonSize: number | null;
                  ndjsonByteBoundaries: Array<number> | null;
                  columns: Array<{
                    __typename?: 'ParquetColumn';
                    name: string;
                    sqlType: string;
                    minValue: any;
                    maxValue: any;
                    numNulls: number;
                    approxDistinctValues: number;
                    sampleValues: Array<any>;
                  }>;
                } | null;
              };
            } | null;
          }>;
        }>;
        lastEditedByUser: { __typename?: 'User'; id: string; name: string };
      }>;
      latestPublishedVersion: {
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        publishedAt: Date | null;
      } | null;
      canonicalDatasets: Array<{
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      }>;
    } | null;
  };
};

export type GQConvertCtsBackMutationVariables = Exact<{
  input: GQConvertCtsBackInput;
}>;

export type GQConvertCtsBackMutation = {
  __typename?: 'Mutation';
  convertCtsBack: {
    __typename?: 'ConvertCtsBackPayload';
    cts: {
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      descriptionMd: string | null;
      rank: number;
      schemaId: string | null;
      versions: Array<{
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        schemaJson: any;
        semverVersion: string;
        versionNotesMd: string;
        exampleData: any;
        formattingNotesMd: string | null;
        createdAt: Date;
        publishedAt: Date | null;
        publishedForTestingAt: Date | null;
        unpublishedAt: Date | null;
        schemaVersionId: string | null;
        schema: {
          __typename?: 'CustomerTargetSchema';
          id: string;
          name: string;
          versions: Array<{
            __typename?: 'CustomerTargetSchemaVersion';
            id: string;
            publishedAt: Date | null;
            publishedForTestingAt: Date | null;
            unpublishedAt: Date | null;
          }>;
        };
        globalIngestionExampleData: {
          __typename?: 'IngestionExampleData';
          id: string;
          title: string;
          datasetId: string | null;
          columns: Array<{
            __typename?: 'IngestionExampleDataColumn';
            fieldName: string;
            title: string;
            description: string;
            examples: Array<string>;
            required: boolean;
            optionalToMap: boolean | null;
            defaultValue: any | null;
          }>;
          rows: Array<{
            __typename?: 'IngestionExampleDataRow';
            fields: Array<{
              __typename?: 'IngestionExampleDataRowField';
              fieldName: string;
              value: string;
            }>;
          }>;
        };
        transformsIncludingDeleted: Array<{
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          deletedAt: Date | null;
          sql: string;
          transformTarget: GQCustomerTargetSchemaTransformTarget;
          businessActivityTypeName: string | null;
          businessActivityTypeVersionId: string | null;
          businessActivityTypeVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
            name: string;
          } | null;
          runs: Array<{
            __typename?: 'CustomerTargetSchemaTransformRun';
            id: string;
            sql: string;
            error: string | null;
            createdAt: Date;
            transform: {
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              transformTarget: GQCustomerTargetSchemaTransformTarget;
              businessActivityTypeName: string | null;
            };
            input: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              category: GQFileCategory;
              revisionRootFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              } | null;
            };
            output: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            standardizedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            warnings: Array<{
              __typename?: 'ValidationWarning';
              blocking: boolean;
              message: string;
            }>;
            runner: { __typename?: 'User'; id: string; name: string };
          }>;
          testCases: Array<{
            __typename?: 'CustomerTargetSchemaTransformTestCase';
            id: string;
            name: string;
            inputJson: any | null;
            kind: GQCtsTransformTestKind;
            expectedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            latestRun: {
              __typename?: 'CustomerTargetSchemaTransformTestRun';
              id: string;
              status: GQCtsTestRunStatus;
              testDiffResults: any | null;
              transformRun: {
                __typename?: 'CustomerTargetSchemaTransformRun';
                id: string;
                error: string | null;
                standardizedOutput: {
                  __typename?: 'ParquetData';
                  id: string;
                  numRecords: number;
                  ndjsonSignedUrl: string | null;
                  ndjsonSize: number | null;
                  ndjsonByteBoundaries: Array<number> | null;
                  columns: Array<{
                    __typename?: 'ParquetColumn';
                    name: string;
                    sqlType: string;
                    minValue: any;
                    maxValue: any;
                    numNulls: number;
                    approxDistinctValues: number;
                    sampleValues: Array<any>;
                  }>;
                } | null;
              };
            } | null;
          }>;
        }>;
        lastEditedByUser: { __typename?: 'User'; id: string; name: string };
      }>;
      latestPublishedVersion: {
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        publishedAt: Date | null;
      } | null;
      canonicalDatasets: Array<{
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      }>;
    } | null;
  };
};

export type GQCtsTestFragment = {
  __typename?: 'CustomerTargetSchemaTransformTestCase';
  id: string;
  name: string;
  inputJson: any | null;
  kind: GQCtsTransformTestKind;
  expectedOutput: {
    __typename?: 'ParquetData';
    id: string;
    numRecords: number;
    ndjsonSignedUrl: string | null;
    ndjsonSize: number | null;
    ndjsonByteBoundaries: Array<number> | null;
    columns: Array<{
      __typename?: 'ParquetColumn';
      name: string;
      sqlType: string;
      minValue: any;
      maxValue: any;
      numNulls: number;
      approxDistinctValues: number;
      sampleValues: Array<any>;
    }>;
  } | null;
  latestRun: {
    __typename?: 'CustomerTargetSchemaTransformTestRun';
    id: string;
    status: GQCtsTestRunStatus;
    testDiffResults: any | null;
    transformRun: {
      __typename?: 'CustomerTargetSchemaTransformRun';
      id: string;
      error: string | null;
      standardizedOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
    };
  } | null;
};

export type GQCtsTransformForTestsFragment = {
  __typename?: 'CustomerTargetSchemaTransform';
  id: string;
  runs: Array<{
    __typename?: 'CustomerTargetSchemaTransformRun';
    id: string;
    sql: string;
    error: string | null;
    createdAt: Date;
    transform: {
      __typename?: 'CustomerTargetSchemaTransform';
      id: string;
      transformTarget: GQCustomerTargetSchemaTransformTarget;
      businessActivityTypeName: string | null;
    };
    input: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
      category: GQFileCategory;
      revisionRootFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
      } | null;
    };
    output: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    standardizedOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    warnings: Array<{
      __typename?: 'ValidationWarning';
      message: string;
      blocking: boolean;
    }>;
    runner: { __typename?: 'User'; id: string; name: string };
  }>;
  testCases: Array<{
    __typename?: 'CustomerTargetSchemaTransformTestCase';
    id: string;
    name: string;
    inputJson: any | null;
    kind: GQCtsTransformTestKind;
    expectedOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    latestRun: {
      __typename?: 'CustomerTargetSchemaTransformTestRun';
      id: string;
      status: GQCtsTestRunStatus;
      testDiffResults: any | null;
      transformRun: {
        __typename?: 'CustomerTargetSchemaTransformRun';
        id: string;
        error: string | null;
        standardizedOutput: {
          __typename?: 'ParquetData';
          id: string;
          numRecords: number;
          ndjsonSignedUrl: string | null;
          ndjsonSize: number | null;
          ndjsonByteBoundaries: Array<number> | null;
          columns: Array<{
            __typename?: 'ParquetColumn';
            name: string;
            sqlType: string;
            minValue: any;
            maxValue: any;
            numNulls: number;
            approxDistinctValues: number;
            sampleValues: Array<any>;
          }>;
        } | null;
      };
    } | null;
  }>;
};

export type GQRunCtsTransformTestCaseMutationVariables = Exact<{
  input: GQRunCtsTransformTestCaseInput;
}>;

export type GQRunCtsTransformTestCaseMutation = {
  __typename?: 'Mutation';
  runCtsTransformTestCase: {
    __typename?: 'RunCtsTransformEditorPayload';
    workflowId: string;
  };
};

export type GQCreateCtsTransformTestCaseMutationVariables = Exact<{
  input: GQCreateCtsTransformTestCaseInput;
}>;

export type GQCreateCtsTransformTestCaseMutation = {
  __typename?: 'Mutation';
  createCtsTransformTestCase: {
    __typename?: 'CreateCtsTransformTestCasePayload';
    ctsTransformTestCase: {
      __typename?: 'CustomerTargetSchemaTransformTestCase';
      id: string;
      transform: {
        __typename?: 'CustomerTargetSchemaTransform';
        id: string;
        runs: Array<{
          __typename?: 'CustomerTargetSchemaTransformRun';
          id: string;
          sql: string;
          error: string | null;
          createdAt: Date;
          transform: {
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            transformTarget: GQCustomerTargetSchemaTransformTarget;
            businessActivityTypeName: string | null;
          };
          input: {
            __typename?: 'FileMetadata';
            id: string;
            name: string;
            category: GQFileCategory;
            revisionRootFileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            } | null;
          };
          output: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          standardizedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          warnings: Array<{
            __typename?: 'ValidationWarning';
            message: string;
            blocking: boolean;
          }>;
          runner: { __typename?: 'User'; id: string; name: string };
        }>;
        testCases: Array<{
          __typename?: 'CustomerTargetSchemaTransformTestCase';
          id: string;
          name: string;
          inputJson: any | null;
          kind: GQCtsTransformTestKind;
          expectedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          latestRun: {
            __typename?: 'CustomerTargetSchemaTransformTestRun';
            id: string;
            status: GQCtsTestRunStatus;
            testDiffResults: any | null;
            transformRun: {
              __typename?: 'CustomerTargetSchemaTransformRun';
              id: string;
              error: string | null;
              standardizedOutput: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
            };
          } | null;
        }>;
      };
    };
  } | null;
};

export type GQUpdateCtsTransformTestCaseMutationVariables = Exact<{
  input: GQUpdateCtsTransformTestCaseInput;
}>;

export type GQUpdateCtsTransformTestCaseMutation = {
  __typename?: 'Mutation';
  updateCtsTransformTestCase: {
    __typename?: 'UpdateCtsTransformTestCasePayload';
    ctsTransformTestCase: {
      __typename?: 'CustomerTargetSchemaTransformTestCase';
      id: string;
      name: string;
      inputJson: any | null;
      kind: GQCtsTransformTestKind;
      expectedOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      latestRun: {
        __typename?: 'CustomerTargetSchemaTransformTestRun';
        id: string;
        status: GQCtsTestRunStatus;
        testDiffResults: any | null;
        transformRun: {
          __typename?: 'CustomerTargetSchemaTransformRun';
          id: string;
          error: string | null;
          standardizedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
        };
      } | null;
    };
  } | null;
};

export type GQDeleteCtsTransformTestCaseMutationVariables = Exact<{
  input: GQDeleteCtsTransformTestCaseInput;
}>;

export type GQDeleteCtsTransformTestCaseMutation = {
  __typename?: 'Mutation';
  deleteCtsTransformTestCase: {
    __typename?: 'DeleteCtsTransformTestCasePayload';
    ctsTransform: {
      __typename?: 'CustomerTargetSchemaTransform';
      id: string;
      runs: Array<{
        __typename?: 'CustomerTargetSchemaTransformRun';
        id: string;
        sql: string;
        error: string | null;
        createdAt: Date;
        transform: {
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          transformTarget: GQCustomerTargetSchemaTransformTarget;
          businessActivityTypeName: string | null;
        };
        input: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          category: GQFileCategory;
          revisionRootFileMetadata: {
            __typename?: 'FileMetadata';
            id: string;
            name: string;
          } | null;
        };
        output: {
          __typename?: 'ParquetData';
          id: string;
          numRecords: number;
          ndjsonSignedUrl: string | null;
          ndjsonSize: number | null;
          ndjsonByteBoundaries: Array<number> | null;
          columns: Array<{
            __typename?: 'ParquetColumn';
            name: string;
            sqlType: string;
            minValue: any;
            maxValue: any;
            numNulls: number;
            approxDistinctValues: number;
            sampleValues: Array<any>;
          }>;
        } | null;
        standardizedOutput: {
          __typename?: 'ParquetData';
          id: string;
          numRecords: number;
          ndjsonSignedUrl: string | null;
          ndjsonSize: number | null;
          ndjsonByteBoundaries: Array<number> | null;
          columns: Array<{
            __typename?: 'ParquetColumn';
            name: string;
            sqlType: string;
            minValue: any;
            maxValue: any;
            numNulls: number;
            approxDistinctValues: number;
            sampleValues: Array<any>;
          }>;
        } | null;
        warnings: Array<{
          __typename?: 'ValidationWarning';
          message: string;
          blocking: boolean;
        }>;
        runner: { __typename?: 'User'; id: string; name: string };
      }>;
      testCases: Array<{
        __typename?: 'CustomerTargetSchemaTransformTestCase';
        id: string;
        name: string;
        inputJson: any | null;
        kind: GQCtsTransformTestKind;
        expectedOutput: {
          __typename?: 'ParquetData';
          id: string;
          numRecords: number;
          ndjsonSignedUrl: string | null;
          ndjsonSize: number | null;
          ndjsonByteBoundaries: Array<number> | null;
          columns: Array<{
            __typename?: 'ParquetColumn';
            name: string;
            sqlType: string;
            minValue: any;
            maxValue: any;
            numNulls: number;
            approxDistinctValues: number;
            sampleValues: Array<any>;
          }>;
        } | null;
        latestRun: {
          __typename?: 'CustomerTargetSchemaTransformTestRun';
          id: string;
          status: GQCtsTestRunStatus;
          testDiffResults: any | null;
          transformRun: {
            __typename?: 'CustomerTargetSchemaTransformRun';
            id: string;
            error: string | null;
            standardizedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
          };
        } | null;
      }>;
    };
  } | null;
};

export type GQApproveCtsTransformTestCaseMutationVariables = Exact<{
  input: GQApproveCtsTransformTestCaseInput;
}>;

export type GQApproveCtsTransformTestCaseMutation = {
  __typename?: 'Mutation';
  approveCtsTransformTestCase: {
    __typename?: 'ApproveCtsTransformTestCasePayload';
    ctsTransformTestCase: {
      __typename?: 'CustomerTargetSchemaTransformTestCase';
      id: string;
      name: string;
      inputJson: any | null;
      kind: GQCtsTransformTestKind;
      expectedOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      latestRun: {
        __typename?: 'CustomerTargetSchemaTransformTestRun';
        id: string;
        status: GQCtsTestRunStatus;
        testDiffResults: any | null;
        transformRun: {
          __typename?: 'CustomerTargetSchemaTransformRun';
          id: string;
          error: string | null;
          standardizedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
        };
      } | null;
    };
  };
};

export type GQCustomerTargetSchemaTransformQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQCustomerTargetSchemaTransformQuery = {
  __typename?: 'Query';
  customerTargetSchemaTransform: {
    __typename?: 'CustomerTargetSchemaTransform';
    id: string;
    runs: Array<{
      __typename?: 'CustomerTargetSchemaTransformRun';
      id: string;
      sql: string;
      error: string | null;
      createdAt: Date;
      transform: {
        __typename?: 'CustomerTargetSchemaTransform';
        id: string;
        transformTarget: GQCustomerTargetSchemaTransformTarget;
        businessActivityTypeName: string | null;
      };
      input: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        category: GQFileCategory;
        revisionRootFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
        } | null;
      };
      output: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      standardizedOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      warnings: Array<{
        __typename?: 'ValidationWarning';
        message: string;
        blocking: boolean;
      }>;
      runner: { __typename?: 'User'; id: string; name: string };
    }>;
    testCases: Array<{
      __typename?: 'CustomerTargetSchemaTransformTestCase';
      id: string;
      name: string;
      inputJson: any | null;
      kind: GQCtsTransformTestKind;
      expectedOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      latestRun: {
        __typename?: 'CustomerTargetSchemaTransformTestRun';
        id: string;
        status: GQCtsTestRunStatus;
        testDiffResults: any | null;
        transformRun: {
          __typename?: 'CustomerTargetSchemaTransformRun';
          id: string;
          error: string | null;
          standardizedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
        };
      } | null;
    }>;
  };
};

export type GQFileMetadataForCtsVersionEditorFragment = {
  __typename?: 'FileMetadata';
  id: string;
  remotePath: string;
  gcloudStorageUrl: string;
};

export type GQCtsVersionAdminFragment = {
  __typename?: 'CustomerTargetSchemaVersion';
  id: string;
  schemaJson: any;
  semverVersion: string;
  versionNotesMd: string;
  exampleData: any;
  formattingNotesMd: string | null;
  createdAt: Date;
  publishedAt: Date | null;
  publishedForTestingAt: Date | null;
  unpublishedAt: Date | null;
  schemaVersionId: string | null;
  schema: {
    __typename?: 'CustomerTargetSchema';
    id: string;
    name: string;
    versions: Array<{
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      publishedAt: Date | null;
      publishedForTestingAt: Date | null;
      unpublishedAt: Date | null;
    }>;
  };
  globalIngestionExampleData: {
    __typename?: 'IngestionExampleData';
    id: string;
    title: string;
    datasetId: string | null;
    columns: Array<{
      __typename?: 'IngestionExampleDataColumn';
      fieldName: string;
      title: string;
      description: string;
      examples: Array<string>;
      required: boolean;
      optionalToMap: boolean | null;
      defaultValue: any | null;
    }>;
    rows: Array<{
      __typename?: 'IngestionExampleDataRow';
      fields: Array<{
        __typename?: 'IngestionExampleDataRowField';
        fieldName: string;
        value: string;
      }>;
    }>;
  };
  transformsIncludingDeleted: Array<{
    __typename?: 'CustomerTargetSchemaTransform';
    id: string;
    deletedAt: Date | null;
    sql: string;
    transformTarget: GQCustomerTargetSchemaTransformTarget;
    businessActivityTypeName: string | null;
    businessActivityTypeVersionId: string | null;
    businessActivityTypeVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    runs: Array<{
      __typename?: 'CustomerTargetSchemaTransformRun';
      id: string;
      sql: string;
      error: string | null;
      createdAt: Date;
      transform: {
        __typename?: 'CustomerTargetSchemaTransform';
        id: string;
        transformTarget: GQCustomerTargetSchemaTransformTarget;
        businessActivityTypeName: string | null;
      };
      input: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        category: GQFileCategory;
        revisionRootFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
        } | null;
      };
      output: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      standardizedOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      warnings: Array<{
        __typename?: 'ValidationWarning';
        blocking: boolean;
        message: string;
      }>;
      runner: { __typename?: 'User'; id: string; name: string };
    }>;
    testCases: Array<{
      __typename?: 'CustomerTargetSchemaTransformTestCase';
      id: string;
      name: string;
      inputJson: any | null;
      kind: GQCtsTransformTestKind;
      expectedOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      latestRun: {
        __typename?: 'CustomerTargetSchemaTransformTestRun';
        id: string;
        status: GQCtsTestRunStatus;
        testDiffResults: any | null;
        transformRun: {
          __typename?: 'CustomerTargetSchemaTransformRun';
          id: string;
          error: string | null;
          standardizedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
        };
      } | null;
    }>;
  }>;
  lastEditedByUser: { __typename?: 'User'; id: string; name: string };
};

export type GQPublishCtsVersionMutationVariables = Exact<{
  input: GQPublishCtsVersionInput;
}>;

export type GQPublishCtsVersionMutation = {
  __typename?: 'Mutation';
  publishCtsVersion: {
    __typename?: 'PublishCtsVersionPayload';
    ctsVersion: {
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      schemaJson: any;
      semverVersion: string;
      versionNotesMd: string;
      exampleData: any;
      formattingNotesMd: string | null;
      createdAt: Date;
      publishedAt: Date | null;
      publishedForTestingAt: Date | null;
      unpublishedAt: Date | null;
      schemaVersionId: string | null;
      schema: {
        __typename?: 'CustomerTargetSchema';
        id: string;
        name: string;
        versions: Array<{
          __typename?: 'CustomerTargetSchemaVersion';
          id: string;
          publishedAt: Date | null;
          publishedForTestingAt: Date | null;
          unpublishedAt: Date | null;
        }>;
      };
      globalIngestionExampleData: {
        __typename?: 'IngestionExampleData';
        id: string;
        title: string;
        datasetId: string | null;
        columns: Array<{
          __typename?: 'IngestionExampleDataColumn';
          fieldName: string;
          title: string;
          description: string;
          examples: Array<string>;
          required: boolean;
          optionalToMap: boolean | null;
          defaultValue: any | null;
        }>;
        rows: Array<{
          __typename?: 'IngestionExampleDataRow';
          fields: Array<{
            __typename?: 'IngestionExampleDataRowField';
            fieldName: string;
            value: string;
          }>;
        }>;
      };
      transformsIncludingDeleted: Array<{
        __typename?: 'CustomerTargetSchemaTransform';
        id: string;
        deletedAt: Date | null;
        sql: string;
        transformTarget: GQCustomerTargetSchemaTransformTarget;
        businessActivityTypeName: string | null;
        businessActivityTypeVersionId: string | null;
        businessActivityTypeVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
          name: string;
        } | null;
        runs: Array<{
          __typename?: 'CustomerTargetSchemaTransformRun';
          id: string;
          sql: string;
          error: string | null;
          createdAt: Date;
          transform: {
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            transformTarget: GQCustomerTargetSchemaTransformTarget;
            businessActivityTypeName: string | null;
          };
          input: {
            __typename?: 'FileMetadata';
            id: string;
            name: string;
            category: GQFileCategory;
            revisionRootFileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            } | null;
          };
          output: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          standardizedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          warnings: Array<{
            __typename?: 'ValidationWarning';
            blocking: boolean;
            message: string;
          }>;
          runner: { __typename?: 'User'; id: string; name: string };
        }>;
        testCases: Array<{
          __typename?: 'CustomerTargetSchemaTransformTestCase';
          id: string;
          name: string;
          inputJson: any | null;
          kind: GQCtsTransformTestKind;
          expectedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          latestRun: {
            __typename?: 'CustomerTargetSchemaTransformTestRun';
            id: string;
            status: GQCtsTestRunStatus;
            testDiffResults: any | null;
            transformRun: {
              __typename?: 'CustomerTargetSchemaTransformRun';
              id: string;
              error: string | null;
              standardizedOutput: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
            };
          } | null;
        }>;
      }>;
      lastEditedByUser: { __typename?: 'User'; id: string; name: string };
    };
    customerTargetSchema: {
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      descriptionMd: string | null;
      rank: number;
      schemaId: string | null;
      versions: Array<{
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        schemaJson: any;
        semverVersion: string;
        versionNotesMd: string;
        exampleData: any;
        formattingNotesMd: string | null;
        createdAt: Date;
        publishedAt: Date | null;
        publishedForTestingAt: Date | null;
        unpublishedAt: Date | null;
        schemaVersionId: string | null;
        schema: {
          __typename?: 'CustomerTargetSchema';
          id: string;
          name: string;
          versions: Array<{
            __typename?: 'CustomerTargetSchemaVersion';
            id: string;
            publishedAt: Date | null;
            publishedForTestingAt: Date | null;
            unpublishedAt: Date | null;
          }>;
        };
        globalIngestionExampleData: {
          __typename?: 'IngestionExampleData';
          id: string;
          title: string;
          datasetId: string | null;
          columns: Array<{
            __typename?: 'IngestionExampleDataColumn';
            fieldName: string;
            title: string;
            description: string;
            examples: Array<string>;
            required: boolean;
            optionalToMap: boolean | null;
            defaultValue: any | null;
          }>;
          rows: Array<{
            __typename?: 'IngestionExampleDataRow';
            fields: Array<{
              __typename?: 'IngestionExampleDataRowField';
              fieldName: string;
              value: string;
            }>;
          }>;
        };
        transformsIncludingDeleted: Array<{
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          deletedAt: Date | null;
          sql: string;
          transformTarget: GQCustomerTargetSchemaTransformTarget;
          businessActivityTypeName: string | null;
          businessActivityTypeVersionId: string | null;
          businessActivityTypeVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
            name: string;
          } | null;
          runs: Array<{
            __typename?: 'CustomerTargetSchemaTransformRun';
            id: string;
            sql: string;
            error: string | null;
            createdAt: Date;
            transform: {
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              transformTarget: GQCustomerTargetSchemaTransformTarget;
              businessActivityTypeName: string | null;
            };
            input: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              category: GQFileCategory;
              revisionRootFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              } | null;
            };
            output: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            standardizedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            warnings: Array<{
              __typename?: 'ValidationWarning';
              blocking: boolean;
              message: string;
            }>;
            runner: { __typename?: 'User'; id: string; name: string };
          }>;
          testCases: Array<{
            __typename?: 'CustomerTargetSchemaTransformTestCase';
            id: string;
            name: string;
            inputJson: any | null;
            kind: GQCtsTransformTestKind;
            expectedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            latestRun: {
              __typename?: 'CustomerTargetSchemaTransformTestRun';
              id: string;
              status: GQCtsTestRunStatus;
              testDiffResults: any | null;
              transformRun: {
                __typename?: 'CustomerTargetSchemaTransformRun';
                id: string;
                error: string | null;
                standardizedOutput: {
                  __typename?: 'ParquetData';
                  id: string;
                  numRecords: number;
                  ndjsonSignedUrl: string | null;
                  ndjsonSize: number | null;
                  ndjsonByteBoundaries: Array<number> | null;
                  columns: Array<{
                    __typename?: 'ParquetColumn';
                    name: string;
                    sqlType: string;
                    minValue: any;
                    maxValue: any;
                    numNulls: number;
                    approxDistinctValues: number;
                    sampleValues: Array<any>;
                  }>;
                } | null;
              };
            } | null;
          }>;
        }>;
        lastEditedByUser: { __typename?: 'User'; id: string; name: string };
      }>;
      latestPublishedVersion: {
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        publishedAt: Date | null;
      } | null;
      canonicalDatasets: Array<{
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      }>;
    };
  };
};

export type GQPublishCtsVersionForTestingMutationVariables = Exact<{
  input: GQPublishCtsVersionInput;
}>;

export type GQPublishCtsVersionForTestingMutation = {
  __typename?: 'Mutation';
  publishCtsVersionForTesting: {
    __typename?: 'PublishCtsVersionPayload';
    ctsVersion: {
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      schemaJson: any;
      semverVersion: string;
      versionNotesMd: string;
      exampleData: any;
      formattingNotesMd: string | null;
      createdAt: Date;
      publishedAt: Date | null;
      publishedForTestingAt: Date | null;
      unpublishedAt: Date | null;
      schemaVersionId: string | null;
      schema: {
        __typename?: 'CustomerTargetSchema';
        id: string;
        name: string;
        versions: Array<{
          __typename?: 'CustomerTargetSchemaVersion';
          id: string;
          publishedAt: Date | null;
          publishedForTestingAt: Date | null;
          unpublishedAt: Date | null;
        }>;
      };
      globalIngestionExampleData: {
        __typename?: 'IngestionExampleData';
        id: string;
        title: string;
        datasetId: string | null;
        columns: Array<{
          __typename?: 'IngestionExampleDataColumn';
          fieldName: string;
          title: string;
          description: string;
          examples: Array<string>;
          required: boolean;
          optionalToMap: boolean | null;
          defaultValue: any | null;
        }>;
        rows: Array<{
          __typename?: 'IngestionExampleDataRow';
          fields: Array<{
            __typename?: 'IngestionExampleDataRowField';
            fieldName: string;
            value: string;
          }>;
        }>;
      };
      transformsIncludingDeleted: Array<{
        __typename?: 'CustomerTargetSchemaTransform';
        id: string;
        deletedAt: Date | null;
        sql: string;
        transformTarget: GQCustomerTargetSchemaTransformTarget;
        businessActivityTypeName: string | null;
        businessActivityTypeVersionId: string | null;
        businessActivityTypeVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
          name: string;
        } | null;
        runs: Array<{
          __typename?: 'CustomerTargetSchemaTransformRun';
          id: string;
          sql: string;
          error: string | null;
          createdAt: Date;
          transform: {
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            transformTarget: GQCustomerTargetSchemaTransformTarget;
            businessActivityTypeName: string | null;
          };
          input: {
            __typename?: 'FileMetadata';
            id: string;
            name: string;
            category: GQFileCategory;
            revisionRootFileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            } | null;
          };
          output: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          standardizedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          warnings: Array<{
            __typename?: 'ValidationWarning';
            blocking: boolean;
            message: string;
          }>;
          runner: { __typename?: 'User'; id: string; name: string };
        }>;
        testCases: Array<{
          __typename?: 'CustomerTargetSchemaTransformTestCase';
          id: string;
          name: string;
          inputJson: any | null;
          kind: GQCtsTransformTestKind;
          expectedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          latestRun: {
            __typename?: 'CustomerTargetSchemaTransformTestRun';
            id: string;
            status: GQCtsTestRunStatus;
            testDiffResults: any | null;
            transformRun: {
              __typename?: 'CustomerTargetSchemaTransformRun';
              id: string;
              error: string | null;
              standardizedOutput: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
            };
          } | null;
        }>;
      }>;
      lastEditedByUser: { __typename?: 'User'; id: string; name: string };
    };
  };
};

export type GQUnpublishCtsVersionMutationVariables = Exact<{
  input: GQUnpublishCtsVersionInput;
}>;

export type GQUnpublishCtsVersionMutation = {
  __typename?: 'Mutation';
  unpublishCtsVersion: {
    __typename?: 'UnpublishCtsVersionPayload';
    ctsVersion: {
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      schemaJson: any;
      semverVersion: string;
      versionNotesMd: string;
      exampleData: any;
      formattingNotesMd: string | null;
      createdAt: Date;
      publishedAt: Date | null;
      publishedForTestingAt: Date | null;
      unpublishedAt: Date | null;
      schemaVersionId: string | null;
      schema: {
        __typename?: 'CustomerTargetSchema';
        id: string;
        name: string;
        versions: Array<{
          __typename?: 'CustomerTargetSchemaVersion';
          id: string;
          publishedAt: Date | null;
          publishedForTestingAt: Date | null;
          unpublishedAt: Date | null;
        }>;
      };
      globalIngestionExampleData: {
        __typename?: 'IngestionExampleData';
        id: string;
        title: string;
        datasetId: string | null;
        columns: Array<{
          __typename?: 'IngestionExampleDataColumn';
          fieldName: string;
          title: string;
          description: string;
          examples: Array<string>;
          required: boolean;
          optionalToMap: boolean | null;
          defaultValue: any | null;
        }>;
        rows: Array<{
          __typename?: 'IngestionExampleDataRow';
          fields: Array<{
            __typename?: 'IngestionExampleDataRowField';
            fieldName: string;
            value: string;
          }>;
        }>;
      };
      transformsIncludingDeleted: Array<{
        __typename?: 'CustomerTargetSchemaTransform';
        id: string;
        deletedAt: Date | null;
        sql: string;
        transformTarget: GQCustomerTargetSchemaTransformTarget;
        businessActivityTypeName: string | null;
        businessActivityTypeVersionId: string | null;
        businessActivityTypeVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
          name: string;
        } | null;
        runs: Array<{
          __typename?: 'CustomerTargetSchemaTransformRun';
          id: string;
          sql: string;
          error: string | null;
          createdAt: Date;
          transform: {
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            transformTarget: GQCustomerTargetSchemaTransformTarget;
            businessActivityTypeName: string | null;
          };
          input: {
            __typename?: 'FileMetadata';
            id: string;
            name: string;
            category: GQFileCategory;
            revisionRootFileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            } | null;
          };
          output: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          standardizedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          warnings: Array<{
            __typename?: 'ValidationWarning';
            blocking: boolean;
            message: string;
          }>;
          runner: { __typename?: 'User'; id: string; name: string };
        }>;
        testCases: Array<{
          __typename?: 'CustomerTargetSchemaTransformTestCase';
          id: string;
          name: string;
          inputJson: any | null;
          kind: GQCtsTransformTestKind;
          expectedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          latestRun: {
            __typename?: 'CustomerTargetSchemaTransformTestRun';
            id: string;
            status: GQCtsTestRunStatus;
            testDiffResults: any | null;
            transformRun: {
              __typename?: 'CustomerTargetSchemaTransformRun';
              id: string;
              error: string | null;
              standardizedOutput: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
            };
          } | null;
        }>;
      }>;
      lastEditedByUser: { __typename?: 'User'; id: string; name: string };
    };
    customerTargetSchema: {
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      descriptionMd: string | null;
      rank: number;
      schemaId: string | null;
      versions: Array<{
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        schemaJson: any;
        semverVersion: string;
        versionNotesMd: string;
        exampleData: any;
        formattingNotesMd: string | null;
        createdAt: Date;
        publishedAt: Date | null;
        publishedForTestingAt: Date | null;
        unpublishedAt: Date | null;
        schemaVersionId: string | null;
        schema: {
          __typename?: 'CustomerTargetSchema';
          id: string;
          name: string;
          versions: Array<{
            __typename?: 'CustomerTargetSchemaVersion';
            id: string;
            publishedAt: Date | null;
            publishedForTestingAt: Date | null;
            unpublishedAt: Date | null;
          }>;
        };
        globalIngestionExampleData: {
          __typename?: 'IngestionExampleData';
          id: string;
          title: string;
          datasetId: string | null;
          columns: Array<{
            __typename?: 'IngestionExampleDataColumn';
            fieldName: string;
            title: string;
            description: string;
            examples: Array<string>;
            required: boolean;
            optionalToMap: boolean | null;
            defaultValue: any | null;
          }>;
          rows: Array<{
            __typename?: 'IngestionExampleDataRow';
            fields: Array<{
              __typename?: 'IngestionExampleDataRowField';
              fieldName: string;
              value: string;
            }>;
          }>;
        };
        transformsIncludingDeleted: Array<{
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          deletedAt: Date | null;
          sql: string;
          transformTarget: GQCustomerTargetSchemaTransformTarget;
          businessActivityTypeName: string | null;
          businessActivityTypeVersionId: string | null;
          businessActivityTypeVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
            name: string;
          } | null;
          runs: Array<{
            __typename?: 'CustomerTargetSchemaTransformRun';
            id: string;
            sql: string;
            error: string | null;
            createdAt: Date;
            transform: {
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              transformTarget: GQCustomerTargetSchemaTransformTarget;
              businessActivityTypeName: string | null;
            };
            input: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              category: GQFileCategory;
              revisionRootFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              } | null;
            };
            output: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            standardizedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            warnings: Array<{
              __typename?: 'ValidationWarning';
              blocking: boolean;
              message: string;
            }>;
            runner: { __typename?: 'User'; id: string; name: string };
          }>;
          testCases: Array<{
            __typename?: 'CustomerTargetSchemaTransformTestCase';
            id: string;
            name: string;
            inputJson: any | null;
            kind: GQCtsTransformTestKind;
            expectedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            latestRun: {
              __typename?: 'CustomerTargetSchemaTransformTestRun';
              id: string;
              status: GQCtsTestRunStatus;
              testDiffResults: any | null;
              transformRun: {
                __typename?: 'CustomerTargetSchemaTransformRun';
                id: string;
                error: string | null;
                standardizedOutput: {
                  __typename?: 'ParquetData';
                  id: string;
                  numRecords: number;
                  ndjsonSignedUrl: string | null;
                  ndjsonSize: number | null;
                  ndjsonByteBoundaries: Array<number> | null;
                  columns: Array<{
                    __typename?: 'ParquetColumn';
                    name: string;
                    sqlType: string;
                    minValue: any;
                    maxValue: any;
                    numNulls: number;
                    approxDistinctValues: number;
                    sampleValues: Array<any>;
                  }>;
                } | null;
              };
            } | null;
          }>;
        }>;
        lastEditedByUser: { __typename?: 'User'; id: string; name: string };
      }>;
      latestPublishedVersion: {
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        publishedAt: Date | null;
      } | null;
      canonicalDatasets: Array<{
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      }>;
    };
  };
};

export type GQPushCtsVersionToOneSchemaMutationVariables = Exact<{
  input: GQPushCtsVersionToOneSchemaInput;
}>;

export type GQPushCtsVersionToOneSchemaMutation = {
  __typename?: 'Mutation';
  pushCtsVersionToOneSchema: {
    __typename?: 'PushCtsVersionToOneSchemaPayload';
    oneSchemaTemplateKey: string;
    signedUrl: string;
    oneSchemaTemplateUrl: string;
    fileMetadata: {
      __typename?: 'FileMetadata';
      id: string;
      remotePath: string;
      gcloudStorageUrl: string;
    };
  };
};

export type GQCommentForCommentDisplayFieldsFragment = {
  __typename?: 'Comment';
  id: string;
  message: string;
  createdAt: Date;
  isPublished: boolean;
  person:
    | {
        __typename?: 'User';
        id: string;
        name: string;
        isWatershedEmployee: boolean;
        isWatershedContractor: boolean;
      }
    | {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
        isWatershedEmployee: boolean;
        isWatershedContractor: boolean;
      }
    | null;
};

export type GQUpdateCommentMutationVariables = Exact<{
  input: GQUpdateCommentInput;
}>;

export type GQUpdateCommentMutation = {
  __typename?: 'Mutation';
  updateComment: {
    __typename?: 'UpdateCommentPayload';
    comment: {
      __typename?: 'Comment';
      id: string;
      message: string;
      createdAt: Date;
      isPublished: boolean;
      person:
        | {
            __typename?: 'User';
            id: string;
            name: string;
            isWatershedEmployee: boolean;
            isWatershedContractor: boolean;
          }
        | {
            __typename?: 'WatershedEmployee';
            id: string;
            name: string;
            isWatershedEmployee: boolean;
            isWatershedContractor: boolean;
          }
        | null;
    };
  } | null;
};

export type GQDeleteCommentMutationVariables = Exact<{
  input: GQDeleteCommentInput;
}>;

export type GQDeleteCommentMutation = {
  __typename?: 'Mutation';
  deleteComment: {
    __typename?: 'DeleteCommentPayload';
    question:
      | {
          __typename?: 'DataIssue';
          id: string;
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                message: string;
                createdAt: Date;
                isPublished: boolean;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        }
      | {
          __typename?: 'Discussion';
          id: string;
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                message: string;
                createdAt: Date;
                isPublished: boolean;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        }
      | {
          __typename?: 'FootprintQuestion';
          id: string;
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                message: string;
                createdAt: Date;
                isPublished: boolean;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        };
  } | null;
};

export type GQCreateDataIssueMutationVariables = Exact<{
  input: GQCreateDataIssueInput;
  orgId: Scalars['ID']['input'];
}>;

export type GQCreateDataIssueMutation = {
  __typename?: 'Mutation';
  createDataIssue: {
    __typename?: 'CreateDataIssuePayload';
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      issues: {
        __typename?: 'DataIssueConnection';
        edges: Array<{
          __typename?: 'DataIssueEdge';
          node: { __typename?: 'DataIssue'; id: string } | null;
        } | null>;
      } | null;
    };
    issue: {
      __typename: 'DataIssue';
      id: string;
      title: string;
      description: string | null;
      state: GQDataIssueState;
      isPublished: boolean;
      isCustomerInitiated: boolean;
      createdAt: Date;
      tags: Array<GQDataIssueTag> | null;
      name: string;
      adminUrl: string;
      assignee: {
        __typename?: 'User';
        id: string;
        name: string;
        permissions: Array<{
          __typename?: 'PermissionItem';
          id: string;
          objectId: string | null;
          permission: GQPermissionType;
          revokedAt: Date | null;
          object:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanySurvey'; id: string; name: string }
            | { __typename: 'Dataset'; id: string; name: string }
            | { __typename: 'Datasource'; id: string; name: string }
            | { __typename: 'EngagementTask'; id: string; name: string }
            | { __typename: 'Fund'; id: string; name: string }
            | null;
        }>;
        roles: Array<{
          __typename?: 'UserRoleAssignment';
          id: string;
          revokedAt: Date | null;
          role: {
            __typename?: 'Role';
            id: string;
            name: string;
            description: string | null;
            permissions: Array<{
              __typename?: 'PermissionItem';
              id: string;
              objectId: string | null;
              permission: GQPermissionType;
              revokedAt: Date | null;
              object:
                | { __typename: 'Company'; id: string; name: string }
                | { __typename: 'CompanySurvey'; id: string; name: string }
                | { __typename: 'Dataset'; id: string; name: string }
                | { __typename: 'Datasource'; id: string; name: string }
                | { __typename: 'EngagementTask'; id: string; name: string }
                | { __typename: 'Fund'; id: string; name: string }
                | null;
            }>;
          };
        }>;
      } | null;
      comments: {
        __typename?: 'CommentConnection';
        edges: Array<{
          __typename?: 'CommentEdge';
          node: {
            __typename?: 'Comment';
            id: string;
            message: string;
            createdAt: Date;
            isPublished: boolean;
            person:
              | {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | {
                  __typename?: 'WatershedEmployee';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | null;
          } | null;
        } | null>;
      };
      files: {
        __typename?: 'FileMetadataConnection';
        edges: Array<{
          __typename?: 'FileMetadataEdge';
          node: { __typename?: 'FileMetadata'; id: string } | null;
        } | null>;
      };
      linkedObject:
        | { __typename: 'FinancialsReviewItem'; id: string }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            item: {
              __typename?: 'MeasurementVerificationItem';
              id: string;
              datasetName: string;
            };
          }
        | {
            __typename: 'UserUploadTask';
            id: string;
            assignee: {
              __typename?: 'User';
              id: string;
              name: string;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
              roles: Array<{
                __typename?: 'UserRoleAssignment';
                id: string;
                revokedAt: Date | null;
                role: {
                  __typename?: 'Role';
                  id: string;
                  name: string;
                  description: string | null;
                  permissions: Array<{
                    __typename?: 'PermissionItem';
                    id: string;
                    objectId: string | null;
                    permission: GQPermissionType;
                    revokedAt: Date | null;
                    object:
                      | { __typename: 'Company'; id: string; name: string }
                      | {
                          __typename: 'CompanySurvey';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Dataset'; id: string; name: string }
                      | { __typename: 'Datasource'; id: string; name: string }
                      | {
                          __typename: 'EngagementTask';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Fund'; id: string; name: string }
                      | null;
                  }>;
                };
              }>;
            } | null;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: { __typename?: 'Dataset'; id: string; name: string };
            };
          }
        | { __typename: 'ValueMappingTask'; id: string }
        | null;
      ancestorRelations: Array<
        | {
            __typename: 'ActivityDataTable';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'DataIssue';
            id: string;
            name: string;
            adminUrl: string;
          }
        | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
        | {
            __typename: 'Datasource';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FileMetadata';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FootprintQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementProject';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
      >;
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      coverageInterval: YMInterval;
      active: boolean;
      dataIssues: Array<{
        __typename: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        isPublished: boolean;
        isCustomerInitiated: boolean;
        createdAt: Date;
        tags: Array<GQDataIssueTag> | null;
        name: string;
        adminUrl: string;
        assignee: {
          __typename?: 'User';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
          roles: Array<{
            __typename?: 'UserRoleAssignment';
            id: string;
            revokedAt: Date | null;
            role: {
              __typename?: 'Role';
              id: string;
              name: string;
              description: string | null;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
            };
          }>;
        } | null;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
        files: {
          __typename?: 'FileMetadataConnection';
          edges: Array<{
            __typename?: 'FileMetadataEdge';
            node: { __typename?: 'FileMetadata'; id: string } | null;
          } | null>;
        };
        linkedObject:
          | { __typename: 'FinancialsReviewItem'; id: string }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              item: {
                __typename?: 'MeasurementVerificationItem';
                id: string;
                datasetName: string;
              };
            }
          | {
              __typename: 'UserUploadTask';
              id: string;
              assignee: {
                __typename?: 'User';
                id: string;
                name: string;
                permissions: Array<{
                  __typename?: 'PermissionItem';
                  id: string;
                  objectId: string | null;
                  permission: GQPermissionType;
                  revokedAt: Date | null;
                  object:
                    | { __typename: 'Company'; id: string; name: string }
                    | { __typename: 'CompanySurvey'; id: string; name: string }
                    | { __typename: 'Dataset'; id: string; name: string }
                    | { __typename: 'Datasource'; id: string; name: string }
                    | { __typename: 'EngagementTask'; id: string; name: string }
                    | { __typename: 'Fund'; id: string; name: string }
                    | null;
                }>;
                roles: Array<{
                  __typename?: 'UserRoleAssignment';
                  id: string;
                  revokedAt: Date | null;
                  role: {
                    __typename?: 'Role';
                    id: string;
                    name: string;
                    description: string | null;
                    permissions: Array<{
                      __typename?: 'PermissionItem';
                      id: string;
                      objectId: string | null;
                      permission: GQPermissionType;
                      revokedAt: Date | null;
                      object:
                        | { __typename: 'Company'; id: string; name: string }
                        | {
                            __typename: 'CompanySurvey';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Dataset'; id: string; name: string }
                        | { __typename: 'Datasource'; id: string; name: string }
                        | {
                            __typename: 'EngagementTask';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Fund'; id: string; name: string }
                        | null;
                    }>;
                  };
                }>;
              } | null;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: { __typename?: 'Dataset'; id: string; name: string };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | null;
        ancestorRelations: Array<
          | {
              __typename: 'ActivityDataTable';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'DataIssue';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Dataset';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Datasource';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FileMetadata';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FootprintQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementProject';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
        >;
      }>;
    };
  } | null;
};

export type GQMeasurementProjectFieldsForCreateDataIssueFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  active: boolean;
  coverageInterval: YMInterval;
  userUploadTasks: {
    __typename?: 'UserUploadTaskConnection';
    edges: Array<{
      __typename?: 'UserUploadTaskEdge';
      node: {
        __typename: 'UserUploadTask';
        id: string;
        state: GQUserUploadTaskState;
        datasource: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: { __typename?: 'Dataset'; id: string; name: string };
        };
      } | null;
    } | null>;
  };
};

export type GQOrganizationProjectCreateDataIssuesFieldsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrganizationProjectCreateDataIssuesFieldsQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      coverageInterval: YMInterval;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: { __typename?: 'Dataset'; id: string; name: string };
            };
          } | null;
        } | null>;
      };
    }>;
  };
};

export type GQUpdateDataIssueMutationVariables = Exact<{
  input: GQUpdateDataIssueInput;
  orgId: Scalars['ID']['input'];
}>;

export type GQUpdateDataIssueMutation = {
  __typename?: 'Mutation';
  updateDataIssue: {
    __typename?: 'UpdateDataIssuePayload';
    issue: {
      __typename: 'DataIssue';
      id: string;
      title: string;
      description: string | null;
      state: GQDataIssueState;
      isPublished: boolean;
      isCustomerInitiated: boolean;
      createdAt: Date;
      tags: Array<GQDataIssueTag> | null;
      name: string;
      adminUrl: string;
      assignee: {
        __typename?: 'User';
        id: string;
        name: string;
        permissions: Array<{
          __typename?: 'PermissionItem';
          id: string;
          objectId: string | null;
          permission: GQPermissionType;
          revokedAt: Date | null;
          object:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanySurvey'; id: string; name: string }
            | { __typename: 'Dataset'; id: string; name: string }
            | { __typename: 'Datasource'; id: string; name: string }
            | { __typename: 'EngagementTask'; id: string; name: string }
            | { __typename: 'Fund'; id: string; name: string }
            | null;
        }>;
        roles: Array<{
          __typename?: 'UserRoleAssignment';
          id: string;
          revokedAt: Date | null;
          role: {
            __typename?: 'Role';
            id: string;
            name: string;
            description: string | null;
            permissions: Array<{
              __typename?: 'PermissionItem';
              id: string;
              objectId: string | null;
              permission: GQPermissionType;
              revokedAt: Date | null;
              object:
                | { __typename: 'Company'; id: string; name: string }
                | { __typename: 'CompanySurvey'; id: string; name: string }
                | { __typename: 'Dataset'; id: string; name: string }
                | { __typename: 'Datasource'; id: string; name: string }
                | { __typename: 'EngagementTask'; id: string; name: string }
                | { __typename: 'Fund'; id: string; name: string }
                | null;
            }>;
          };
        }>;
      } | null;
      comments: {
        __typename?: 'CommentConnection';
        edges: Array<{
          __typename?: 'CommentEdge';
          node: {
            __typename?: 'Comment';
            id: string;
            message: string;
            createdAt: Date;
            isPublished: boolean;
            person:
              | {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | {
                  __typename?: 'WatershedEmployee';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | null;
          } | null;
        } | null>;
      };
      files: {
        __typename?: 'FileMetadataConnection';
        edges: Array<{
          __typename?: 'FileMetadataEdge';
          node: { __typename?: 'FileMetadata'; id: string } | null;
        } | null>;
      };
      linkedObject:
        | { __typename: 'FinancialsReviewItem'; id: string }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            item: {
              __typename?: 'MeasurementVerificationItem';
              id: string;
              datasetName: string;
            };
          }
        | {
            __typename: 'UserUploadTask';
            id: string;
            assignee: {
              __typename?: 'User';
              id: string;
              name: string;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
              roles: Array<{
                __typename?: 'UserRoleAssignment';
                id: string;
                revokedAt: Date | null;
                role: {
                  __typename?: 'Role';
                  id: string;
                  name: string;
                  description: string | null;
                  permissions: Array<{
                    __typename?: 'PermissionItem';
                    id: string;
                    objectId: string | null;
                    permission: GQPermissionType;
                    revokedAt: Date | null;
                    object:
                      | { __typename: 'Company'; id: string; name: string }
                      | {
                          __typename: 'CompanySurvey';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Dataset'; id: string; name: string }
                      | { __typename: 'Datasource'; id: string; name: string }
                      | {
                          __typename: 'EngagementTask';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Fund'; id: string; name: string }
                      | null;
                  }>;
                };
              }>;
            } | null;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: { __typename?: 'Dataset'; id: string; name: string };
            };
          }
        | { __typename: 'ValueMappingTask'; id: string }
        | null;
      ancestorRelations: Array<
        | {
            __typename: 'ActivityDataTable';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'DataIssue';
            id: string;
            name: string;
            adminUrl: string;
          }
        | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
        | {
            __typename: 'Datasource';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FileMetadata';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FootprintQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementProject';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
      >;
    };
  } | null;
};

export type GQCreateDataIssueCommentMutationVariables = Exact<{
  input: GQCreateDataIssueCommentInput;
}>;

export type GQCreateDataIssueCommentMutation = {
  __typename?: 'Mutation';
  createDataIssueComment: {
    __typename?: 'CreateDataIssueCommentPayload';
    issue: {
      __typename?: 'DataIssue';
      id: string;
      comments: {
        __typename?: 'CommentConnection';
        edges: Array<{
          __typename?: 'CommentEdge';
          node: {
            __typename?: 'Comment';
            id: string;
            message: string;
            createdAt: Date;
            isPublished: boolean;
            person:
              | {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | {
                  __typename?: 'WatershedEmployee';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | null;
          } | null;
        } | null>;
      };
    };
  } | null;
};

export type GQCreateEmissionsModelMutationVariables = Exact<{
  input: GQCreateEmissionsModelInput;
}>;

export type GQCreateEmissionsModelMutation = {
  __typename?: 'Mutation';
  createEmissionsModel:
    | {
        __typename: 'CreateEmissionsModelPayloadInvalid';
        validationErrors: Array<{
          __typename?: 'EmissionsModelError';
          msg: string;
          path: Array<string>;
        }>;
      }
    | {
        __typename: 'CreateEmissionsModelPayloadValid';
        emissionsModelStableRecord: {
          __typename?: 'EmissionsModelStable';
          id: string;
        };
      };
};

export type GQValidateEmissionsModelMatcherMutationVariables = Exact<{
  input: GQValidateEmissionsModelMatcherInput;
}>;

export type GQValidateEmissionsModelMatcherMutation = {
  __typename?: 'Mutation';
  validateEmissionsModelMatcher: {
    __typename?: 'ValidateEmissionsModelMatcherPayload';
    isValid: boolean;
    errors: Array<{
      __typename?: 'EmissionsModelError';
      path: Array<string>;
      msg: string;
      severity: GQEmissionsModelErrorSeverity;
    }>;
  };
};

export type GQGetDiffSourceEmissionsModelVersionQueryVariables = Exact<{
  stableId: Scalars['ID']['input'];
}>;

export type GQGetDiffSourceEmissionsModelVersionQuery = {
  __typename?: 'Query';
  latestEmissionsModelVersionForStableId: {
    __typename?: 'EmissionsModelVersion';
    id: string;
    emissionsModelJson: any;
    parameterResolverJson: any;
  } | null;
  emissionsModelMatchersForStableId: Array<{
    __typename?: 'EmissionsModelMatcher';
    id: string;
    kind: GQEmissionsModelMatcherKind;
    emissionsModelStableId: string;
    businessActivityType: string;
    businessActivityTypeVersionId: string;
    filterExpression: string | null;
    quantitySpecifier: string | null;
    bindings: any;
    keepBatVersionAtLatest: boolean;
  }>;
};

export type GQGetReferenceDataFromSlugsQueryVariables = Exact<{
  slugs: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GQGetReferenceDataFromSlugsQuery = {
  __typename?: 'Query';
  getReferenceDataFromRawSlugs: Array<{
    __typename?: 'ReferenceDataSource';
    name: string;
    id: string;
  }>;
};

export type GQReviewPrepublicationTestResultsForEmissionsModelMutationVariables =
  Exact<{
    input: GQReviewPrepublicationTestResultsInput;
  }>;

export type GQReviewPrepublicationTestResultsForEmissionsModelMutation = {
  __typename?: 'Mutation';
  reviewResult: {
    __typename?: 'ReviewPrepublicationTestResultsForEmissionsModelPayload';
    outcome: GQMeasurementResourcePublishWorkflowOutcome;
    errorCode: GQMeasurementResourcePublicationErrorCode | null;
    publishedEmissionsModelVersion: {
      __typename?: 'EmissionsModelVersion';
      id: string;
      kind: GQEmissionsModelVersionKind;
      changelog: string;
      createdAt: Date;
      schemaVersion: string;
      emissionsModelJson: any;
      parameterResolverJson: any;
      author: { __typename?: 'User'; id: string; name: string } | null;
      activeDraftForVersion: {
        __typename?: 'EmissionsModelActiveDraft';
        id: string;
        isCheckedOut: boolean;
        autosavedAt: Date;
        emissionsModelJson: any | null;
        parameterResolverJson: any | null;
        matchers: any;
        activeAuthor: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        };
        importedDescendants: Array<{
          __typename?: 'EmissionsModelVersion';
          id: string;
          title: string;
          description: string;
          changelog: string;
          createdAt: Date;
          schemaVersion: string;
          emissionsModelJson: any;
          parameterResolverJson: any;
          stableModel: {
            __typename?: 'EmissionsModelStable';
            id: string;
            latestPublishedVersion: {
              __typename?: 'EmissionsModelVersion';
              id: string;
              changelog: string;
              createdAt: Date;
            } | null;
          };
        }>;
      } | null;
      importedDescendants: Array<{
        __typename?: 'EmissionsModelVersion';
        id: string;
        title: string;
        description: string;
        changelog: string;
        createdAt: Date;
        schemaVersion: string;
        emissionsModelJson: any;
        parameterResolverJson: any;
        stableModel: {
          __typename?: 'EmissionsModelStable';
          id: string;
          latestPublishedVersion: {
            __typename?: 'EmissionsModelVersion';
            id: string;
            changelog: string;
            createdAt: Date;
          } | null;
        };
      }>;
    } | null;
    publishedEmissionsModelMatchers: Array<{
      __typename?: 'EmissionsModelMatcher';
      id: string;
      kind: GQEmissionsModelMatcherKind;
      emissionsModelStableId: string;
      businessActivityType: string;
      businessActivityTypeVersionId: string;
      filterExpression: string | null;
      quantitySpecifier: string | null;
      bindings: any;
      keepBatVersionAtLatest: boolean;
    }> | null;
  };
};

export type GQSaveEmissionsModelDraftMutationVariables = Exact<{
  input: GQSaveEmissionsModelDraftInput;
}>;

export type GQSaveEmissionsModelDraftMutation = {
  __typename?: 'Mutation';
  saveEmissionsModelDraft:
    | {
        __typename: 'SaveEmissionsModelDraftPayloadInvalid';
        validationErrors: Array<{
          __typename?: 'EmissionsModelError';
          msg: string;
          path: Array<string>;
          severity: GQEmissionsModelErrorSeverity;
        }>;
      }
    | {
        __typename: 'SaveEmissionsModelDraftPayloadValid';
        emissionsModelVersionRecord: {
          __typename?: 'EmissionsModelVersion';
          id: string;
          kind: GQEmissionsModelVersionKind;
          changelog: string;
          createdAt: Date;
          schemaVersion: string;
          emissionsModelJson: any;
          parameterResolverJson: any;
          author: { __typename?: 'User'; id: string; name: string } | null;
          activeDraftForVersion: {
            __typename?: 'EmissionsModelActiveDraft';
            id: string;
            isCheckedOut: boolean;
            autosavedAt: Date;
            emissionsModelJson: any | null;
            parameterResolverJson: any | null;
            matchers: any;
            activeAuthor: {
              __typename?: 'WatershedEmployee';
              id: string;
              name: string;
            };
            importedDescendants: Array<{
              __typename?: 'EmissionsModelVersion';
              id: string;
              title: string;
              description: string;
              changelog: string;
              createdAt: Date;
              schemaVersion: string;
              emissionsModelJson: any;
              parameterResolverJson: any;
              stableModel: {
                __typename?: 'EmissionsModelStable';
                id: string;
                latestPublishedVersion: {
                  __typename?: 'EmissionsModelVersion';
                  id: string;
                  changelog: string;
                  createdAt: Date;
                } | null;
              };
            }>;
          } | null;
          importedDescendants: Array<{
            __typename?: 'EmissionsModelVersion';
            id: string;
            title: string;
            description: string;
            changelog: string;
            createdAt: Date;
            schemaVersion: string;
            emissionsModelJson: any;
            parameterResolverJson: any;
            stableModel: {
              __typename?: 'EmissionsModelStable';
              id: string;
              latestPublishedVersion: {
                __typename?: 'EmissionsModelVersion';
                id: string;
                changelog: string;
                createdAt: Date;
              } | null;
            };
          }>;
        };
        parameterResolverRecord: {
          __typename?: 'ParameterResolver';
          id: string;
        };
        matchers: Array<{
          __typename?: 'EmissionsModelMatcher';
          id: string;
          kind: GQEmissionsModelMatcherKind;
          emissionsModelStableId: string;
          businessActivityType: string;
          businessActivityTypeVersionId: string;
          filterExpression: string | null;
          quantitySpecifier: string | null;
          bindings: any;
          keepBatVersionAtLatest: boolean;
        }>;
      };
};

export type GQReferenceDataSourceFieldsForPickerFragment = {
  __typename?: 'ReferenceDataSource';
  id: string;
  name: string;
  latestPublishedVersion: {
    __typename?: 'ReferenceDataVersion';
    id: string;
    versionName: string;
    latestSignedParquetOutputUrl: string | null;
    latestSchema: {
      __typename?: 'ReferenceDataSchema';
      id: string;
      schemaJson: JSONSchema7;
    } | null;
  } | null;
};

export type GQTypeAheadRefDataSourceQueryVariables = Exact<{
  q: Scalars['String']['input'];
  orgId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQTypeAheadRefDataSourceQuery = {
  __typename?: 'Query';
  searchReferenceDataSourceName: Array<{
    __typename?: 'ReferenceDataSource';
    id: string;
    name: string;
    latestPublishedVersion: {
      __typename?: 'ReferenceDataVersion';
      id: string;
      versionName: string;
      latestSignedParquetOutputUrl: string | null;
      latestSchema: {
        __typename?: 'ReferenceDataSchema';
        id: string;
        schemaJson: JSONSchema7;
      } | null;
    } | null;
  }>;
};

export type GQGetReferenceDataSourceByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
  orgId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQGetReferenceDataSourceByNameQuery = {
  __typename?: 'Query';
  referenceDataSourceByName: {
    __typename?: 'ReferenceDataSource';
    id: string;
    name: string;
    latestPublishedVersion: {
      __typename?: 'ReferenceDataVersion';
      id: string;
      versionName: string;
      latestSignedParquetOutputUrl: string | null;
      latestSchema: {
        __typename?: 'ReferenceDataSchema';
        id: string;
        schemaJson: JSONSchema7;
      } | null;
    } | null;
  };
};

export type GQAutosaveEmissionsModelMutationVariables = Exact<{
  input: GQAutosaveEmissionsModelInput;
}>;

export type GQAutosaveEmissionsModelMutation = {
  __typename?: 'Mutation';
  autosaveEmissionsModel:
    | {
        __typename: 'AutosaveEmissionsModelPayloadBlocked';
        authorId: string;
        authorName: string;
        autosavedAt: Date;
      }
    | {
        __typename: 'AutosaveEmissionsModelPayloadError';
        error: {
          __typename?: 'SerializableError';
          message: string;
          code: string | null;
          errorType: string | null;
          details: any | null;
        };
      }
    | {
        __typename: 'AutosaveEmissionsModelPayloadOk';
        emissionsModelActiveDraftRecord: {
          __typename?: 'EmissionsModelActiveDraft';
          id: string;
          autosavedAt: Date;
        };
      };
};

export type GQReleaseEmissionsModelActiveDraftMutationVariables = Exact<{
  input: GQReleaseEmissionsModelActiveDraftInput;
}>;

export type GQReleaseEmissionsModelActiveDraftMutation = {
  __typename?: 'Mutation';
  releaseEmissionsModelActiveDraft:
    | {
        __typename: 'ReleaseEmissionsModelActiveDraftPayloadNonOp';
        message: string | null;
      }
    | {
        __typename: 'ReleaseEmissionsModelActiveDraftPayloadSuccess';
        emissionsModelActiveDraftRecord: {
          __typename?: 'EmissionsModelActiveDraft';
          id: string;
          isCheckedOut: boolean;
          autosavedAt: Date;
          releasedAt: Date | null;
        } | null;
      };
};

export type GQEmissionsModelActiveDraftFieldsForEditorFragment = {
  __typename?: 'EmissionsModelActiveDraft';
  id: string;
  isCheckedOut: boolean;
  autosavedAt: Date;
  emissionsModelJson: any | null;
  parameterResolverJson: any | null;
  matchers: any;
  activeAuthor: { __typename?: 'WatershedEmployee'; id: string; name: string };
  importedDescendants: Array<{
    __typename?: 'EmissionsModelVersion';
    id: string;
    title: string;
    description: string;
    changelog: string;
    createdAt: Date;
    schemaVersion: string;
    emissionsModelJson: any;
    parameterResolverJson: any;
    stableModel: {
      __typename?: 'EmissionsModelStable';
      id: string;
      latestPublishedVersion: {
        __typename?: 'EmissionsModelVersion';
        id: string;
        changelog: string;
        createdAt: Date;
      } | null;
    };
  }>;
};

export type GQSlugSchemasQueryVariables = Exact<{
  slugs: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GQSlugSchemasQuery = {
  __typename?: 'Query';
  referenceDataVersionsBySlugs: Array<{
    __typename?: 'ReferenceDataVersionSlugOutput';
    slug: string;
    referenceDataVersion: {
      __typename?: 'ReferenceDataVersion';
      id: string;
      latestSchema: {
        __typename?: 'ReferenceDataSchema';
        id: string;
        schemaJson: JSONSchema7;
      } | null;
    };
  } | null>;
};

export type GQAddEmissionsModelToOrgMutationVariables = Exact<{
  input: GQAddEmissionsModelToOrgInput;
}>;

export type GQAddEmissionsModelToOrgMutation = {
  __typename?: 'Mutation';
  addEmissionsModelToOrg: {
    __typename?: 'AddEmissionsModelToOrgPayload';
    emissionsModelStable: {
      __typename?: 'EmissionsModelStable';
      id: string;
      org: { __typename?: 'Organization'; id: string; name: string } | null;
    };
  };
};

export type GQForkEmissionsModelMutationVariables = Exact<{
  input: GQForkEmissionsModelInput;
}>;

export type GQForkEmissionsModelMutation = {
  __typename?: 'Mutation';
  forkEmissionsModel: {
    __typename?: 'ForkEmissionsModelPayload';
    emissionsModelStableRecord: {
      __typename?: 'EmissionsModelStable';
      id: string;
    };
  };
};

export type GQSetEmissionsModelIsArchivedMutationVariables = Exact<{
  input: GQSetEmissionsModelIsArchivedInput;
}>;

export type GQSetEmissionsModelIsArchivedMutation = {
  __typename?: 'Mutation';
  setEmissionsModelIsArchived: {
    __typename?: 'SetEmissionsModelIsArchivedPayload';
    emissionsModelStableRecord: {
      __typename?: 'EmissionsModelStable';
      id: string;
      isArchived: boolean;
    };
  };
};

export type GQGetEmissionsModelLibraryForStableModelQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetEmissionsModelLibraryForStableModelQuery = {
  __typename?: 'Query';
  emissionsModelLibraryForStableModel: Array<{
    __typename?: 'EmissionsModelStable';
    id: string;
    description: string;
    title: string;
    isArchived: boolean;
    kind: GQEmissionsModelStableKind;
    org: { __typename?: 'Organization'; id: string; name: string } | null;
    latestPublishedVersion: {
      __typename?: 'EmissionsModelVersion';
      id: string;
      createdAt: Date;
      kind: GQEmissionsModelVersionKind;
      changelog: string;
      author: { __typename?: 'User'; id: string; name: string } | null;
    } | null;
  }>;
};

export type GQBusinessActivityTypeVersionNamesForVersionIdsQueryVariables =
  Exact<{
    ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  }>;

export type GQBusinessActivityTypeVersionNamesForVersionIdsQuery = {
  __typename?: 'Query';
  businessActivityTypeVersionsByIds: Array<{
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
  }>;
};

export type GQGetEmissionsModelForkRelationshipsQueryVariables = Exact<{
  stableId: Scalars['ID']['input'];
}>;

export type GQGetEmissionsModelForkRelationshipsQuery = {
  __typename?: 'Query';
  emissionsModelForkRelationships: {
    __typename?: 'EmissionsModelForkRelationshipsPayload';
    upstreamEntry: {
      __typename?: 'EmissionsModelFork';
      id: string;
      forkedStableId: string;
      forkedStable: {
        __typename?: 'EmissionsModelStable';
        id: string;
        title: string;
      };
    } | null;
    downstreamEntries: Array<{
      __typename?: 'EmissionsModelFork';
      id: string;
      newStableId: string;
      newStable: {
        __typename?: 'EmissionsModelStable';
        id: string;
        title: string;
      };
    }> | null;
  };
};

export type GQStartPublishingEmissionsModelMutationVariables = Exact<{
  input: GQStartPublishingEmissionsModelInput;
}>;

export type GQStartPublishingEmissionsModelMutation = {
  __typename?: 'Mutation';
  startPublishingEmissionsModel:
    | {
        __typename: 'StartPublishingEmissionsModelPayloadError';
        error: {
          __typename?: 'SerializableError';
          message: string;
          code: string | null;
          stackTrace: string | null;
          errorType: string | null;
          details: any | null;
        };
      }
    | {
        __typename: 'StartPublishingEmissionsModelPayloadInvalid';
        validationErrors: Array<{
          __typename?: 'EmissionsModelError';
          msg: string;
          path: Array<string>;
        }>;
      }
    | {
        __typename: 'StartPublishingEmissionsModelPayloadValid';
        emissionsModelVersionRecord: {
          __typename?: 'EmissionsModelVersion';
          id: string;
          kind: GQEmissionsModelVersionKind;
          changelog: string;
          createdAt: Date;
          schemaVersion: string;
          emissionsModelJson: any;
          parameterResolverJson: any;
          author: { __typename?: 'User'; id: string; name: string } | null;
          activeDraftForVersion: {
            __typename?: 'EmissionsModelActiveDraft';
            id: string;
            isCheckedOut: boolean;
            autosavedAt: Date;
            emissionsModelJson: any | null;
            parameterResolverJson: any | null;
            matchers: any;
            activeAuthor: {
              __typename?: 'WatershedEmployee';
              id: string;
              name: string;
            };
            importedDescendants: Array<{
              __typename?: 'EmissionsModelVersion';
              id: string;
              title: string;
              description: string;
              changelog: string;
              createdAt: Date;
              schemaVersion: string;
              emissionsModelJson: any;
              parameterResolverJson: any;
              stableModel: {
                __typename?: 'EmissionsModelStable';
                id: string;
                latestPublishedVersion: {
                  __typename?: 'EmissionsModelVersion';
                  id: string;
                  changelog: string;
                  createdAt: Date;
                } | null;
              };
            }>;
          } | null;
          importedDescendants: Array<{
            __typename?: 'EmissionsModelVersion';
            id: string;
            title: string;
            description: string;
            changelog: string;
            createdAt: Date;
            schemaVersion: string;
            emissionsModelJson: any;
            parameterResolverJson: any;
            stableModel: {
              __typename?: 'EmissionsModelStable';
              id: string;
              latestPublishedVersion: {
                __typename?: 'EmissionsModelVersion';
                id: string;
                changelog: string;
                createdAt: Date;
              } | null;
            };
          }>;
        };
        parameterResolverRecord: {
          __typename?: 'ParameterResolver';
          id: string;
        };
        matchers: Array<{
          __typename?: 'EmissionsModelMatcher';
          id: string;
          kind: GQEmissionsModelMatcherKind;
          emissionsModelStableId: string;
          businessActivityType: string;
          businessActivityTypeVersionId: string;
          filterExpression: string | null;
          quantitySpecifier: string | null;
          bindings: any;
          keepBatVersionAtLatest: boolean;
        }>;
      };
};

export type GQGetEmissionsModelVersionHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  before: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQGetEmissionsModelVersionHistoryQuery = {
  __typename?: 'Query';
  emissionsModelStableVersionHistory: {
    __typename?: 'EmissionsModelVersionHistoryConnection';
    edges: Array<{
      __typename?: 'EmissionsModelVersionHistoryEdge';
      node: {
        __typename?: 'EmissionsModelVersion';
        id: string;
        createdAt: Date;
        changelog: string;
        kind: GQEmissionsModelVersionKind;
        author: { __typename?: 'User'; id: string; name: string } | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type GQGetEmissionsModelVersionJsonQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetEmissionsModelVersionJsonQuery = {
  __typename?: 'Query';
  emissionsModelVersion: {
    __typename?: 'EmissionsModelVersion';
    id: string;
    emissionsModelJson: any;
    parameterResolverJson: any;
  };
};

export type GQEmissionsModelAncestorFieldsFragment = {
  __typename?: 'EmissionsModelAncestor';
  rootIsLatestPublishedVersion: boolean;
  ancestorForkOriginStableModelId: string | null;
  ancestorStableModel: {
    __typename?: 'EmissionsModelStable';
    id: string;
    title: string;
    description: string;
  };
  relationship: {
    __typename?: 'EmissionsModelImportRelationship';
    parentId: string;
    childId: string;
  };
};

export type GQGetEmissionsModelAncestryQueryVariables = Exact<{
  emissionsModelStableId: Scalars['ID']['input'];
}>;

export type GQGetEmissionsModelAncestryQuery = {
  __typename?: 'Query';
  emissionsModelStable: {
    __typename?: 'EmissionsModelStable';
    id: string;
    ancestry: {
      __typename?: 'EmissionsModelAncestry';
      ancestors: Array<{
        __typename?: 'EmissionsModelAncestor';
        rootIsLatestPublishedVersion: boolean;
        ancestorForkOriginStableModelId: string | null;
        ancestorStableModel: {
          __typename?: 'EmissionsModelStable';
          id: string;
          title: string;
          description: string;
        };
        relationship: {
          __typename?: 'EmissionsModelImportRelationship';
          parentId: string;
          childId: string;
        };
      }>;
    };
  };
};

export type GQEmissionsModelEditorEvalQueryVariables = Exact<{
  input: GQEmissionsModelEditorEvalInput;
}>;

export type GQEmissionsModelEditorEvalQuery = {
  __typename?: 'Query';
  emissionsModelEditorEval: {
    __typename?: 'EmissionsModelEditorEvalPayload';
    resultJson: any;
    warnings: Array<string>;
    emissionsEquations: Array<{
      __typename?: 'EmissionsModelEvalEquation';
      emissionsVariableName: string;
      trace: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    }>;
  };
};

export type GQStartPrepublicationTestsForEmissionsModelMutationVariables =
  Exact<{
    input: GQStartPrepublicationTestsForEmissionsModelInput;
  }>;

export type GQStartPrepublicationTestsForEmissionsModelMutation = {
  __typename?: 'Mutation';
  startPrepublicationTestsForEmissionsModel: {
    __typename?: 'StartPrepublicationTestsPayload';
    workflowId: string;
  };
};

export type GQEmissionsModelReleasesForPickerQueryVariables = Exact<{
  orgId: InputMaybe<Scalars['ID']['input']>;
  businessActivityTypeNames: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  includeAllOrgs: Scalars['Boolean']['input'];
  includeAlphas: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQEmissionsModelReleasesForPickerQuery = {
  __typename?: 'Query';
  emissionsModelReleases: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    updatedAt: Date;
    userAuthorId: string | null;
    displayName: string;
    businessActivityTypeName: string;
    internalNotes: string;
    externalNotes: string;
    orgId: string | null;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    isCustomToUserOrg: boolean | null;
    isOrgGated: boolean;
    user: { __typename?: 'User'; id: string; name: string } | null;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    } | null;
    latestCompatibleBatVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    orgAccess: Array<{
      __typename?: 'EmissionsModelReleaseOrgAccess';
      id: string;
      createdAt: Date;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    }>;
  }>;
};

export type GQGetAllGlobalFootprintTagNamesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetAllGlobalFootprintTagNamesQuery = {
  __typename?: 'Query';
  globalFootprintTags: Array<{
    __typename?: 'GlobalFootprintTag';
    id: string;
    tagName: string;
    recordEndAt: Date | null;
  }>;
};

export type GQGetGlobalFootprintTagVersionsForTagNameQueryVariables = Exact<{
  tagName: InputMaybe<Scalars['String']['input']>;
}>;

export type GQGetGlobalFootprintTagVersionsForTagNameQuery = {
  __typename?: 'Query';
  globalFootprintTags: Array<{
    __typename?: 'GlobalFootprintTag';
    id: string;
    tagName: string;
    description: string | null;
    createdAt: Date;
    recordStartAt: Date;
    rulesList: any;
    watershedEditor: {
      __typename?: 'WatershedEmployee';
      id: string;
      name: string;
    } | null;
  }>;
};

export type GQGetManyEmissionsModelStablesQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQGetManyEmissionsModelStablesQuery = {
  __typename?: 'Query';
  emissionsModelsStableByIds: Array<{
    __typename?: 'EmissionsModelStable';
    id: string;
    title: string;
    description: string;
  }>;
};

export type GQReviewPrepublicationTestResultsForReleaseMutationVariables =
  Exact<{
    input: GQReviewPrepublicationTestResultsInput;
  }>;

export type GQReviewPrepublicationTestResultsForReleaseMutation = {
  __typename?: 'Mutation';
  reviewResult: {
    __typename?: 'ReviewPrepublicationTestResultsForReleasePayload';
    outcome: GQMeasurementResourcePublishWorkflowOutcome;
    errorCode: GQMeasurementResourcePublicationErrorCode | null;
    publishedRelease: {
      __typename?: 'EmissionsModelRelease';
      id: string;
    } | null;
  };
};

export type GQStartPrepublicationTestsForReleaseMutationVariables = Exact<{
  input: GQStartPrepublicationTestsForReleaseInput;
}>;

export type GQStartPrepublicationTestsForReleaseMutation = {
  __typename?: 'Mutation';
  startPrepublicationTestsForRelease: {
    __typename?: 'StartPrepublicationTestsPayload';
    workflowId: string;
  };
};

export type GQCreateEmissionsModelReleaseMutationVariables = Exact<{
  input: GQCreateEmissionsModelReleaseInput;
}>;

export type GQCreateEmissionsModelReleaseMutation = {
  __typename?: 'Mutation';
  createEmissionsModelRelease: {
    __typename?: 'EmissionsModelReleasePayload';
    emissionsModelRelease: { __typename?: 'EmissionsModelRelease'; id: string };
  };
};

export type GQUpdateEmissionsModelReleaseMetadataMutationVariables = Exact<{
  input: GQUpdateEmissionsModelReleaseMetadataInput;
}>;

export type GQUpdateEmissionsModelReleaseMetadataMutation = {
  __typename?: 'Mutation';
  updateEmissionsModelReleaseMetadata: {
    __typename?: 'EmissionsModelReleasePayload';
    emissionsModelRelease: {
      __typename?: 'EmissionsModelRelease';
      id: string;
      updatedAt: Date;
      userAuthorId: string | null;
      displayName: string;
      businessActivityTypeName: string;
      internalNotes: string;
      externalNotes: string;
      orgId: string | null;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      isCustomToUserOrg: boolean | null;
      isOrgGated: boolean;
      user: { __typename?: 'User'; id: string; name: string } | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      } | null;
      latestCompatibleBatVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
      orgAccess: Array<{
        __typename?: 'EmissionsModelReleaseOrgAccess';
        id: string;
        createdAt: Date;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        };
      }>;
    };
  };
};

export type GQDeleteEmissionsModelReleaseMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQDeleteEmissionsModelReleaseMutation = {
  __typename?: 'Mutation';
  deleteEmissionsModelRelease: {
    __typename?: 'DeleteEmissionsModelReleasePayload';
    id: string;
  };
};

export type GQUpdateEmissionsModelReleaseLifecyclePhaseMutationVariables =
  Exact<{
    input: GQUpdateEmissionsModelReleaseLifecyclePhaseInput;
  }>;

export type GQUpdateEmissionsModelReleaseLifecyclePhaseMutation = {
  __typename?: 'Mutation';
  updateEmissionsModelReleaseLifecyclePhase: {
    __typename?: 'EmissionsModelReleasePayload';
    emissionsModelRelease: {
      __typename?: 'EmissionsModelRelease';
      id: string;
      updatedAt: Date;
      userAuthorId: string | null;
      displayName: string;
      businessActivityTypeName: string;
      internalNotes: string;
      externalNotes: string;
      orgId: string | null;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      isCustomToUserOrg: boolean | null;
      isOrgGated: boolean;
      emissionsModels: Array<{
        __typename?: 'EmissionsModelStable';
        id: string;
        title: string;
        description: string;
        org: { __typename?: 'Organization'; id: string; name: string } | null;
        latestPublishedVersion: {
          __typename?: 'EmissionsModelVersion';
          id: string;
          createdAt: Date;
          changelog: string;
          valid: boolean;
          author: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        matchers: Array<{
          __typename?: 'EmissionsModelMatcher';
          id: string;
          businessActivityType: string;
          filterExpression: string | null;
          keepBatVersionAtLatest: boolean;
        }>;
        releases: Array<{
          __typename?: 'EmissionsModelRelease';
          id: string;
          updatedAt: Date;
          userAuthorId: string | null;
          displayName: string;
          businessActivityTypeName: string;
          internalNotes: string;
          externalNotes: string;
          orgId: string | null;
          lifecyclePhase: GQEmReleaseLifecyclePhase;
          isCustomToUserOrg: boolean | null;
          isOrgGated: boolean;
          user: { __typename?: 'User'; id: string; name: string } | null;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
            testOrg: boolean;
          } | null;
          latestCompatibleBatVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
            name: string;
          } | null;
          orgAccess: Array<{
            __typename?: 'EmissionsModelReleaseOrgAccess';
            id: string;
            createdAt: Date;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
              testOrg: boolean;
            };
          }>;
        }>;
      }>;
      globalFootprintTags: Array<{
        __typename?: 'GlobalFootprintTag';
        id: string;
        createdAt: Date;
        tagName: string;
        description: string | null;
        watershedEditor: {
          __typename?: 'WatershedEmployee';
          id: string;
          displayName: string;
        } | null;
      }>;
      measurementTestSuites: Array<{
        __typename?: 'MeasurementTestSuite';
        id: string;
        title: string;
        description: string;
        kind: GQMeasurementTestSuiteKind | null;
        createdAt: Date;
        updatedAt: Date | null;
        status: string;
      }>;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      } | null;
      user: { __typename?: 'User'; id: string; name: string } | null;
      latestCompatibleBatVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
      orgAccess: Array<{
        __typename?: 'EmissionsModelReleaseOrgAccess';
        id: string;
        createdAt: Date;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        };
      }>;
    };
  };
};

export type GQLinkEmissionsModelsToReleaseMutationVariables = Exact<{
  input: GQLinkEmissionsModelsToReleaseInput;
}>;

export type GQLinkEmissionsModelsToReleaseMutation = {
  __typename?: 'Mutation';
  linkEmissionsModelsToRelease: {
    __typename?: 'EmissionsModelReleasePayload';
    emissionsModelRelease: {
      __typename?: 'EmissionsModelRelease';
      id: string;
      emissionsModels: Array<{
        __typename?: 'EmissionsModelStable';
        id: string;
        title: string;
        description: string;
        org: { __typename?: 'Organization'; id: string; name: string } | null;
        latestPublishedVersion: {
          __typename?: 'EmissionsModelVersion';
          id: string;
          createdAt: Date;
          changelog: string;
          valid: boolean;
          author: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        matchers: Array<{
          __typename?: 'EmissionsModelMatcher';
          id: string;
          businessActivityType: string;
          filterExpression: string | null;
          keepBatVersionAtLatest: boolean;
        }>;
        releases: Array<{
          __typename?: 'EmissionsModelRelease';
          id: string;
          updatedAt: Date;
          userAuthorId: string | null;
          displayName: string;
          businessActivityTypeName: string;
          internalNotes: string;
          externalNotes: string;
          orgId: string | null;
          lifecyclePhase: GQEmReleaseLifecyclePhase;
          isCustomToUserOrg: boolean | null;
          isOrgGated: boolean;
          user: { __typename?: 'User'; id: string; name: string } | null;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
            testOrg: boolean;
          } | null;
          latestCompatibleBatVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
            name: string;
          } | null;
          orgAccess: Array<{
            __typename?: 'EmissionsModelReleaseOrgAccess';
            id: string;
            createdAt: Date;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
              testOrg: boolean;
            };
          }>;
        }>;
      }>;
    };
  };
};

export type GQUnlinkEmissionsModelsFromReleaseMutationVariables = Exact<{
  input: GQUnlinkEmissionsModelsFromReleaseInput;
}>;

export type GQUnlinkEmissionsModelsFromReleaseMutation = {
  __typename?: 'Mutation';
  unlinkEmissionsModelsFromRelease: {
    __typename?: 'EmissionsModelReleasePayload';
    emissionsModelRelease: {
      __typename?: 'EmissionsModelRelease';
      id: string;
      emissionsModels: Array<{
        __typename?: 'EmissionsModelStable';
        id: string;
        title: string;
        description: string;
        org: { __typename?: 'Organization'; id: string; name: string } | null;
        latestPublishedVersion: {
          __typename?: 'EmissionsModelVersion';
          id: string;
          createdAt: Date;
          changelog: string;
          valid: boolean;
          author: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
        matchers: Array<{
          __typename?: 'EmissionsModelMatcher';
          id: string;
          businessActivityType: string;
          filterExpression: string | null;
          keepBatVersionAtLatest: boolean;
        }>;
        releases: Array<{
          __typename?: 'EmissionsModelRelease';
          id: string;
          updatedAt: Date;
          userAuthorId: string | null;
          displayName: string;
          businessActivityTypeName: string;
          internalNotes: string;
          externalNotes: string;
          orgId: string | null;
          lifecyclePhase: GQEmReleaseLifecyclePhase;
          isCustomToUserOrg: boolean | null;
          isOrgGated: boolean;
          user: { __typename?: 'User'; id: string; name: string } | null;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
            testOrg: boolean;
          } | null;
          latestCompatibleBatVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
            name: string;
          } | null;
          orgAccess: Array<{
            __typename?: 'EmissionsModelReleaseOrgAccess';
            id: string;
            createdAt: Date;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
              testOrg: boolean;
            };
          }>;
        }>;
      }>;
    };
  };
};

export type GQLinkGlobalFootprintTagsToReleaseMutationVariables = Exact<{
  input: GQLinkGlobalFootprintTagsToReleaseInput;
}>;

export type GQLinkGlobalFootprintTagsToReleaseMutation = {
  __typename?: 'Mutation';
  linkGlobalFootprintTagsToRelease: {
    __typename?: 'EmissionsModelReleasePayload';
    emissionsModelRelease: {
      __typename?: 'EmissionsModelRelease';
      id: string;
      globalFootprintTags: Array<{
        __typename?: 'GlobalFootprintTag';
        id: string;
        createdAt: Date;
        tagName: string;
        description: string | null;
        watershedEditor: {
          __typename?: 'WatershedEmployee';
          id: string;
          displayName: string;
        } | null;
      }>;
    };
  };
};

export type GQUnlinkGlobalFootprintTagsFromReleaseMutationVariables = Exact<{
  input: GQUnlinkGlobalFootprintTagsFromReleaseInput;
}>;

export type GQUnlinkGlobalFootprintTagsFromReleaseMutation = {
  __typename?: 'Mutation';
  unlinkGlobalFootprintTagsFromRelease: {
    __typename?: 'EmissionsModelReleasePayload';
    emissionsModelRelease: {
      __typename?: 'EmissionsModelRelease';
      id: string;
      globalFootprintTags: Array<{
        __typename?: 'GlobalFootprintTag';
        id: string;
        createdAt: Date;
        tagName: string;
        description: string | null;
        watershedEditor: {
          __typename?: 'WatershedEmployee';
          id: string;
          displayName: string;
        } | null;
      }>;
    };
  };
};

export type GQAddOrgAccessToReleaseMutationVariables = Exact<{
  input: GQAddOrgAccessToReleaseInput;
}>;

export type GQAddOrgAccessToReleaseMutation = {
  __typename?: 'Mutation';
  addOrgAccessToRelease: {
    __typename?: 'EmissionsModelReleasePayload';
    emissionsModelRelease: {
      __typename?: 'EmissionsModelRelease';
      id: string;
      orgAccess: Array<{
        __typename?: 'EmissionsModelReleaseOrgAccess';
        id: string;
        createdAt: Date;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        };
      }>;
    };
  };
};

export type GQConvertGlobalReleaseToOrgGatedMutationVariables = Exact<{
  input: GQEmissionsModelReleaseIdInput;
}>;

export type GQConvertGlobalReleaseToOrgGatedMutation = {
  __typename?: 'Mutation';
  convertGlobalReleaseToOrgGated: {
    __typename?: 'EmissionsModelReleasePayload';
    emissionsModelRelease: {
      __typename?: 'EmissionsModelRelease';
      id: string;
      isOrgGated: boolean;
    };
  };
};

export type GQConvertOrgGatedReleaseToGlobalMutationVariables = Exact<{
  input: GQEmissionsModelReleaseIdInput;
}>;

export type GQConvertOrgGatedReleaseToGlobalMutation = {
  __typename?: 'Mutation';
  convertOrgGatedReleaseToGlobal: {
    __typename?: 'EmissionsModelReleasePayload';
    emissionsModelRelease: {
      __typename?: 'EmissionsModelRelease';
      id: string;
      isOrgGated: boolean;
    };
  };
};

export type GQEmissionsModelReleaseOrgAccessFieldsFragment = {
  __typename?: 'EmissionsModelReleaseOrgAccess';
  id: string;
  createdAt: Date;
  org: {
    __typename?: 'Organization';
    id: string;
    name: string;
    demoOrg: boolean;
    testOrg: boolean;
  };
};

export type GQEmissionsModelReleaseMetadataFragment = {
  __typename?: 'EmissionsModelRelease';
  id: string;
  updatedAt: Date;
  userAuthorId: string | null;
  displayName: string;
  businessActivityTypeName: string;
  internalNotes: string;
  externalNotes: string;
  orgId: string | null;
  lifecyclePhase: GQEmReleaseLifecyclePhase;
  isCustomToUserOrg: boolean | null;
  isOrgGated: boolean;
  user: { __typename?: 'User'; id: string; name: string } | null;
  org: {
    __typename?: 'Organization';
    id: string;
    name: string;
    demoOrg: boolean;
    testOrg: boolean;
  } | null;
  latestCompatibleBatVersion: {
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
  } | null;
  orgAccess: Array<{
    __typename?: 'EmissionsModelReleaseOrgAccess';
    id: string;
    createdAt: Date;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    };
  }>;
};

export type GQEmissionsModelStableFieldsForReleasesFragment = {
  __typename?: 'EmissionsModelStable';
  id: string;
  title: string;
  description: string;
  org: { __typename?: 'Organization'; id: string; name: string } | null;
  latestPublishedVersion: {
    __typename?: 'EmissionsModelVersion';
    id: string;
    createdAt: Date;
    changelog: string;
    valid: boolean;
    author: { __typename?: 'User'; id: string; name: string } | null;
  } | null;
  matchers: Array<{
    __typename?: 'EmissionsModelMatcher';
    id: string;
    businessActivityType: string;
    filterExpression: string | null;
    keepBatVersionAtLatest: boolean;
  }>;
  releases: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    updatedAt: Date;
    userAuthorId: string | null;
    displayName: string;
    businessActivityTypeName: string;
    internalNotes: string;
    externalNotes: string;
    orgId: string | null;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    isCustomToUserOrg: boolean | null;
    isOrgGated: boolean;
    user: { __typename?: 'User'; id: string; name: string } | null;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    } | null;
    latestCompatibleBatVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    orgAccess: Array<{
      __typename?: 'EmissionsModelReleaseOrgAccess';
      id: string;
      createdAt: Date;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    }>;
  }>;
};

export type GQGlobalFootprintTagFieldsForReleasesFragment = {
  __typename?: 'GlobalFootprintTag';
  id: string;
  createdAt: Date;
  tagName: string;
  description: string | null;
  watershedEditor: {
    __typename?: 'WatershedEmployee';
    id: string;
    displayName: string;
  } | null;
};

export type GQEmissionsModelReleaseDetailsPageFragment = {
  __typename?: 'EmissionsModelRelease';
  id: string;
  updatedAt: Date;
  userAuthorId: string | null;
  displayName: string;
  businessActivityTypeName: string;
  internalNotes: string;
  externalNotes: string;
  orgId: string | null;
  lifecyclePhase: GQEmReleaseLifecyclePhase;
  isCustomToUserOrg: boolean | null;
  isOrgGated: boolean;
  emissionsModels: Array<{
    __typename?: 'EmissionsModelStable';
    id: string;
    title: string;
    description: string;
    org: { __typename?: 'Organization'; id: string; name: string } | null;
    latestPublishedVersion: {
      __typename?: 'EmissionsModelVersion';
      id: string;
      createdAt: Date;
      changelog: string;
      valid: boolean;
      author: { __typename?: 'User'; id: string; name: string } | null;
    } | null;
    matchers: Array<{
      __typename?: 'EmissionsModelMatcher';
      id: string;
      businessActivityType: string;
      filterExpression: string | null;
      keepBatVersionAtLatest: boolean;
    }>;
    releases: Array<{
      __typename?: 'EmissionsModelRelease';
      id: string;
      updatedAt: Date;
      userAuthorId: string | null;
      displayName: string;
      businessActivityTypeName: string;
      internalNotes: string;
      externalNotes: string;
      orgId: string | null;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      isCustomToUserOrg: boolean | null;
      isOrgGated: boolean;
      user: { __typename?: 'User'; id: string; name: string } | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      } | null;
      latestCompatibleBatVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
      orgAccess: Array<{
        __typename?: 'EmissionsModelReleaseOrgAccess';
        id: string;
        createdAt: Date;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        };
      }>;
    }>;
  }>;
  globalFootprintTags: Array<{
    __typename?: 'GlobalFootprintTag';
    id: string;
    createdAt: Date;
    tagName: string;
    description: string | null;
    watershedEditor: {
      __typename?: 'WatershedEmployee';
      id: string;
      displayName: string;
    } | null;
  }>;
  measurementTestSuites: Array<{
    __typename?: 'MeasurementTestSuite';
    id: string;
    title: string;
    description: string;
    kind: GQMeasurementTestSuiteKind | null;
    createdAt: Date;
    updatedAt: Date | null;
    status: string;
  }>;
  org: {
    __typename?: 'Organization';
    id: string;
    name: string;
    demoOrg: boolean;
    testOrg: boolean;
  } | null;
  user: { __typename?: 'User'; id: string; name: string } | null;
  latestCompatibleBatVersion: {
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
  } | null;
  orgAccess: Array<{
    __typename?: 'EmissionsModelReleaseOrgAccess';
    id: string;
    createdAt: Date;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    };
  }>;
};

export type GQEmissionsModelReleaseDetailsQueryVariables = Exact<{
  releaseId: Scalars['ID']['input'];
}>;

export type GQEmissionsModelReleaseDetailsQuery = {
  __typename?: 'Query';
  emissionsModelRelease: {
    __typename?: 'EmissionsModelRelease';
    id: string;
    updatedAt: Date;
    userAuthorId: string | null;
    displayName: string;
    businessActivityTypeName: string;
    internalNotes: string;
    externalNotes: string;
    orgId: string | null;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    isCustomToUserOrg: boolean | null;
    isOrgGated: boolean;
    emissionsModels: Array<{
      __typename?: 'EmissionsModelStable';
      id: string;
      title: string;
      description: string;
      org: { __typename?: 'Organization'; id: string; name: string } | null;
      latestPublishedVersion: {
        __typename?: 'EmissionsModelVersion';
        id: string;
        createdAt: Date;
        changelog: string;
        valid: boolean;
        author: { __typename?: 'User'; id: string; name: string } | null;
      } | null;
      matchers: Array<{
        __typename?: 'EmissionsModelMatcher';
        id: string;
        businessActivityType: string;
        filterExpression: string | null;
        keepBatVersionAtLatest: boolean;
      }>;
      releases: Array<{
        __typename?: 'EmissionsModelRelease';
        id: string;
        updatedAt: Date;
        userAuthorId: string | null;
        displayName: string;
        businessActivityTypeName: string;
        internalNotes: string;
        externalNotes: string;
        orgId: string | null;
        lifecyclePhase: GQEmReleaseLifecyclePhase;
        isCustomToUserOrg: boolean | null;
        isOrgGated: boolean;
        user: { __typename?: 'User'; id: string; name: string } | null;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        } | null;
        latestCompatibleBatVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
          name: string;
        } | null;
        orgAccess: Array<{
          __typename?: 'EmissionsModelReleaseOrgAccess';
          id: string;
          createdAt: Date;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
            testOrg: boolean;
          };
        }>;
      }>;
    }>;
    globalFootprintTags: Array<{
      __typename?: 'GlobalFootprintTag';
      id: string;
      createdAt: Date;
      tagName: string;
      description: string | null;
      watershedEditor: {
        __typename?: 'WatershedEmployee';
        id: string;
        displayName: string;
      } | null;
    }>;
    measurementTestSuites: Array<{
      __typename?: 'MeasurementTestSuite';
      id: string;
      title: string;
      description: string;
      kind: GQMeasurementTestSuiteKind | null;
      createdAt: Date;
      updatedAt: Date | null;
      status: string;
    }>;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    } | null;
    user: { __typename?: 'User'; id: string; name: string } | null;
    latestCompatibleBatVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    orgAccess: Array<{
      __typename?: 'EmissionsModelReleaseOrgAccess';
      id: string;
      createdAt: Date;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    }>;
  };
};

export type GQEmissionsModelReleaseListEntryFragment = {
  __typename?: 'EmissionsModelRelease';
  id: string;
  updatedAt: Date;
  userAuthorId: string | null;
  displayName: string;
  businessActivityTypeName: string;
  internalNotes: string;
  externalNotes: string;
  orgId: string | null;
  lifecyclePhase: GQEmReleaseLifecyclePhase;
  isCustomToUserOrg: boolean | null;
  isOrgGated: boolean;
  emissionsModels: Array<{
    __typename?: 'EmissionsModelStable';
    id: string;
    title: string;
  }>;
  user: { __typename?: 'User'; id: string; name: string } | null;
  org: {
    __typename?: 'Organization';
    id: string;
    name: string;
    demoOrg: boolean;
    testOrg: boolean;
  } | null;
  latestCompatibleBatVersion: {
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
  } | null;
  orgAccess: Array<{
    __typename?: 'EmissionsModelReleaseOrgAccess';
    id: string;
    createdAt: Date;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    };
  }>;
};

export type GQEmissionsModelReleasesQueryVariables = Exact<{
  orgId: InputMaybe<Scalars['ID']['input']>;
  batNames: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  includeAllOrgs: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQEmissionsModelReleasesQuery = {
  __typename?: 'Query';
  emissionsModelReleases: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    updatedAt: Date;
    userAuthorId: string | null;
    displayName: string;
    businessActivityTypeName: string;
    internalNotes: string;
    externalNotes: string;
    orgId: string | null;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    isCustomToUserOrg: boolean | null;
    isOrgGated: boolean;
    emissionsModels: Array<{
      __typename?: 'EmissionsModelStable';
      id: string;
      title: string;
    }>;
    user: { __typename?: 'User'; id: string; name: string } | null;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    } | null;
    latestCompatibleBatVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    orgAccess: Array<{
      __typename?: 'EmissionsModelReleaseOrgAccess';
      id: string;
      createdAt: Date;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    }>;
  }>;
};

export type GQEmissionsModelsStableForBusinessActivityTypeQueryVariables =
  Exact<{
    businessActivityTypeName: Scalars['String']['input'];
    orgId: InputMaybe<Scalars['ID']['input']>;
  }>;

export type GQEmissionsModelsStableForBusinessActivityTypeQuery = {
  __typename?: 'Query';
  emissionsModelsStableForBusinessActivityType: Array<{
    __typename?: 'EmissionsModelStable';
    id: string;
    title: string;
    description: string;
    org: { __typename?: 'Organization'; id: string; name: string } | null;
    latestPublishedVersion: {
      __typename?: 'EmissionsModelVersion';
      id: string;
      createdAt: Date;
      changelog: string;
      valid: boolean;
      author: { __typename?: 'User'; id: string; name: string } | null;
    } | null;
    matchers: Array<{
      __typename?: 'EmissionsModelMatcher';
      id: string;
      businessActivityType: string;
      filterExpression: string | null;
      keepBatVersionAtLatest: boolean;
    }>;
    releases: Array<{
      __typename?: 'EmissionsModelRelease';
      id: string;
      updatedAt: Date;
      userAuthorId: string | null;
      displayName: string;
      businessActivityTypeName: string;
      internalNotes: string;
      externalNotes: string;
      orgId: string | null;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      isCustomToUserOrg: boolean | null;
      isOrgGated: boolean;
      user: { __typename?: 'User'; id: string; name: string } | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      } | null;
      latestCompatibleBatVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
      orgAccess: Array<{
        __typename?: 'EmissionsModelReleaseOrgAccess';
        id: string;
        createdAt: Date;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        };
      }>;
    }>;
  }>;
};

export type GQEmissionsModelsStablesForReleasesQueryVariables = Exact<{
  releaseIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  excludeOrgSpecificModels?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQEmissionsModelsStablesForReleasesQuery = {
  __typename?: 'Query';
  emissionsModelsStablesForReleases: Array<{
    __typename?: 'EmissionsModelStable';
    id: string;
    title: string;
    description: string;
    org: { __typename?: 'Organization'; id: string; name: string } | null;
    latestPublishedVersion: {
      __typename?: 'EmissionsModelVersion';
      id: string;
      createdAt: Date;
      changelog: string;
      valid: boolean;
      author: { __typename?: 'User'; id: string; name: string } | null;
    } | null;
    matchers: Array<{
      __typename?: 'EmissionsModelMatcher';
      id: string;
      businessActivityType: string;
      filterExpression: string | null;
      keepBatVersionAtLatest: boolean;
    }>;
    releases: Array<{
      __typename?: 'EmissionsModelRelease';
      id: string;
      updatedAt: Date;
      userAuthorId: string | null;
      displayName: string;
      businessActivityTypeName: string;
      internalNotes: string;
      externalNotes: string;
      orgId: string | null;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      isCustomToUserOrg: boolean | null;
      isOrgGated: boolean;
      user: { __typename?: 'User'; id: string; name: string } | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      } | null;
      latestCompatibleBatVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
      orgAccess: Array<{
        __typename?: 'EmissionsModelReleaseOrgAccess';
        id: string;
        createdAt: Date;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        };
      }>;
    }>;
  }>;
};

export type GQEmissionsModelReleasesForEmissionsModelStableQueryVariables =
  Exact<{
    stableId: Scalars['ID']['input'];
  }>;

export type GQEmissionsModelReleasesForEmissionsModelStableQuery = {
  __typename?: 'Query';
  emissionsModelReleasesForEmissionsModelStable: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    updatedAt: Date;
    userAuthorId: string | null;
    displayName: string;
    businessActivityTypeName: string;
    internalNotes: string;
    externalNotes: string;
    orgId: string | null;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    isCustomToUserOrg: boolean | null;
    isOrgGated: boolean;
    user: { __typename?: 'User'; id: string; name: string } | null;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    } | null;
    latestCompatibleBatVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    orgAccess: Array<{
      __typename?: 'EmissionsModelReleaseOrgAccess';
      id: string;
      createdAt: Date;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    }>;
  }>;
};

export type GQEmissionsModelReleasesForEmissionsModelsStableQueryVariables =
  Exact<{
    stableIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  }>;

export type GQEmissionsModelReleasesForEmissionsModelsStableQuery = {
  __typename?: 'Query';
  emissionsModelReleasesForEmissionsModelsStable: Array<{
    __typename?: 'EmissionsModelReleaseEmissionsModelsStable';
    models: Array<{
      __typename?: 'EmissionsModelStable';
      id: string;
      title: string;
      isArchived: boolean;
    }>;
    release: {
      __typename?: 'EmissionsModelRelease';
      id: string;
      updatedAt: Date;
      userAuthorId: string | null;
      displayName: string;
      businessActivityTypeName: string;
      internalNotes: string;
      externalNotes: string;
      orgId: string | null;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      isCustomToUserOrg: boolean | null;
      isOrgGated: boolean;
      user: { __typename?: 'User'; id: string; name: string } | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      } | null;
      latestCompatibleBatVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
      orgAccess: Array<{
        __typename?: 'EmissionsModelReleaseOrgAccess';
        id: string;
        createdAt: Date;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        };
      }>;
    };
  }>;
};

export type GQGetFootprintTagQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetFootprintTagQuery = {
  __typename?: 'Query';
  footprintTag: {
    __typename?: 'FootprintTag';
    id: string;
    tagName: string;
    description: string | null;
    rulesList: any;
  } | null;
};

export type GQApplyTagsToPipelineResultMutationVariables = Exact<{
  input: GQApplyTagsToPipelineResultInput;
}>;

export type GQApplyTagsToPipelineResultMutation = {
  __typename?: 'Mutation';
  applyTagsToPipelineResult: {
    __typename?: 'ApplyTagsToPipelineResultPayload';
    taggedResultParquetSignedUrl: string;
    distinctCount: number;
  } | null;
};

export type GQSaveFootprintTagMutationVariables = Exact<{
  input: GQSaveFootprintTagInput;
}>;

export type GQSaveFootprintTagMutation = {
  __typename?: 'Mutation';
  saveFootprintTag: {
    __typename?: 'SaveFootprintTagPayload';
    footprintTag: { __typename?: 'FootprintTag'; id: string };
  } | null;
};

export type GQApplyGlobalTagToTestDataMutationVariables = Exact<{
  input: GQApplyGlobalTagToTestDataInput;
}>;

export type GQApplyGlobalTagToTestDataMutation = {
  __typename?: 'Mutation';
  applyGlobalTagToTestData: {
    __typename?: 'ApplyGlobalTagToTestDataPayload';
    taggedResultParquetSignedUrl: string;
  } | null;
};

export type GQSaveGlobalFootprintTagMutationVariables = Exact<{
  input: GQSaveGlobalFootprintTagInput;
}>;

export type GQSaveGlobalFootprintTagMutation = {
  __typename?: 'Mutation';
  saveGlobalFootprintTag: {
    __typename?: 'SaveGlobalFootprintTagPayload';
    footprintTag: { __typename?: 'GlobalFootprintTag'; id: string };
  } | null;
};

export type GQGlobalFootprintTagsForGlobalTagsEditorFragment = {
  __typename?: 'GlobalFootprintTag';
  id: string;
  tagName: string;
  description: string | null;
  rulesList: any;
  recordEndAt: Date | null;
  createdAt: Date;
  watershedEditor: {
    __typename?: 'WatershedEmployee';
    id: string;
    name: string;
  } | null;
};

export type GQGetGlobalFootprintTagsQueryVariables = Exact<{
  tagName: Scalars['String']['input'];
}>;

export type GQGetGlobalFootprintTagsQuery = {
  __typename?: 'Query';
  globalFootprintTags: Array<{
    __typename?: 'GlobalFootprintTag';
    id: string;
    tagName: string;
    description: string | null;
    rulesList: any;
    recordEndAt: Date | null;
    createdAt: Date;
    watershedEditor: {
      __typename?: 'WatershedEmployee';
      id: string;
      name: string;
    } | null;
  }>;
};

export type GQGenerateTokenMutationVariables = Exact<{
  input: GQGetNewDecryptedTokenInput;
}>;

export type GQGenerateTokenMutation = {
  __typename?: 'Mutation';
  getNewDecryptedToken: {
    __typename?: 'GetNewDecryptedTokenPayload';
    decryptedToken: any | null;
  } | null;
};

export type GQUpdateFinancialsReviewItemMutationVariables = Exact<{
  input: GQUpdateFinancialsReviewItemInput;
}>;

export type GQUpdateFinancialsReviewItemMutation = {
  __typename?: 'Mutation';
  updateFinancialsReviewItem: {
    __typename?: 'UpdateFinancialsReviewItemPayload';
    financialsReviewItem: {
      __typename: 'FinancialsReviewItem';
      id: string;
      createdAt: Date;
      status: GQFinancialsReviewItemStatus;
      initiallyIncluded: boolean | null;
      exclusionReason: string | null;
      prompt: string | null;
      userResponse: string | null;
      name: string;
      adminUrl: string;
      account: {
        __typename?: 'FinancialsAccount';
        accountId: string;
        description: string | null;
      };
      initialIndustryClassification: {
        __typename?: 'IndustryClassification';
        id: string;
        shortDescription: string;
        longDescription: string;
      } | null;
      respondingUser: { __typename?: 'User'; id: string; name: string } | null;
      discussion: {
        __typename?: 'DataIssue';
        id: string;
        state: GQDataIssueState;
      } | null;
      group: {
        __typename?: 'FinancialsReviewGroup';
        id: string;
        measurementProject: { __typename?: 'MeasurementProject'; id: string };
      };
      ancestorRelations: Array<
        | {
            __typename: 'ActivityDataTable';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'DataIssue';
            id: string;
            name: string;
            adminUrl: string;
          }
        | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
        | {
            __typename: 'Datasource';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FileMetadata';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FootprintQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementProject';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
      >;
    };
  } | null;
};

export type GQCreateFinancialsReviewItemDraftsMutationVariables = Exact<{
  input: GQCreateFinancialsReviewItemDraftsInput;
}>;

export type GQCreateFinancialsReviewItemDraftsMutation = {
  __typename?: 'Mutation';
  createFinancialsReviewItemDrafts: {
    __typename?: 'CreateFinancialsReviewItemDraftsPayload';
    financialsReviewItems: {
      __typename?: 'FinancialsReviewItemConnection';
      edges: Array<{
        __typename?: 'FinancialsReviewItemEdge';
        node: {
          __typename: 'FinancialsReviewItem';
          id: string;
          createdAt: Date;
          status: GQFinancialsReviewItemStatus;
          initiallyIncluded: boolean | null;
          exclusionReason: string | null;
          prompt: string | null;
          userResponse: string | null;
          name: string;
          adminUrl: string;
          account: {
            __typename?: 'FinancialsAccount';
            accountId: string;
            description: string | null;
          };
          initialIndustryClassification: {
            __typename?: 'IndustryClassification';
            id: string;
            shortDescription: string;
            longDescription: string;
          } | null;
          respondingUser: {
            __typename?: 'User';
            id: string;
            name: string;
          } | null;
          discussion: {
            __typename?: 'DataIssue';
            id: string;
            state: GQDataIssueState;
          } | null;
          group: {
            __typename?: 'FinancialsReviewGroup';
            id: string;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
            };
          };
          ancestorRelations: Array<
            | {
                __typename: 'ActivityDataTable';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'DataIssue';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'Dataset';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'Datasource';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'FileMetadata';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'FootprintQuestion';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'MeasurementProject';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'MeasurementVerificationItemQuestion';
                id: string;
                name: string;
                adminUrl: string;
              }
          >;
        } | null;
      } | null>;
    };
  } | null;
};

export type GQFinancialsReviewItemAdminDialogFragment = {
  __typename: 'FinancialsReviewItem';
  id: string;
  createdAt: Date;
  status: GQFinancialsReviewItemStatus;
  initiallyIncluded: boolean | null;
  exclusionReason: string | null;
  prompt: string | null;
  userResponse: string | null;
  name: string;
  adminUrl: string;
  account: {
    __typename?: 'FinancialsAccount';
    accountId: string;
    description: string | null;
  };
  initialIndustryClassification: {
    __typename?: 'IndustryClassification';
    id: string;
    shortDescription: string;
    longDescription: string;
  } | null;
  respondingUser: { __typename?: 'User'; id: string; name: string } | null;
  discussion: {
    __typename?: 'DataIssue';
    id: string;
    state: GQDataIssueState;
  } | null;
  group: {
    __typename?: 'FinancialsReviewGroup';
    id: string;
    measurementProject: { __typename?: 'MeasurementProject'; id: string };
  };
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

export type GQReviewItemsDialogQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQReviewItemsDialogQuery = {
  __typename?: 'Query';
  financialsReviewItemsAdmin: {
    __typename?: 'FinancialsReviewItemConnection';
    edges: Array<{
      __typename?: 'FinancialsReviewItemEdge';
      node: {
        __typename: 'FinancialsReviewItem';
        id: string;
        createdAt: Date;
        status: GQFinancialsReviewItemStatus;
        initiallyIncluded: boolean | null;
        exclusionReason: string | null;
        prompt: string | null;
        userResponse: string | null;
        name: string;
        adminUrl: string;
        account: {
          __typename?: 'FinancialsAccount';
          accountId: string;
          description: string | null;
        };
        initialIndustryClassification: {
          __typename?: 'IndustryClassification';
          id: string;
          shortDescription: string;
          longDescription: string;
        } | null;
        respondingUser: {
          __typename?: 'User';
          id: string;
          name: string;
        } | null;
        discussion: {
          __typename?: 'DataIssue';
          id: string;
          state: GQDataIssueState;
        } | null;
        group: {
          __typename?: 'FinancialsReviewGroup';
          id: string;
          measurementProject: { __typename?: 'MeasurementProject'; id: string };
        };
        ancestorRelations: Array<
          | {
              __typename: 'ActivityDataTable';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'DataIssue';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Dataset';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Datasource';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FileMetadata';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FootprintQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementProject';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
        >;
      } | null;
    } | null>;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      completedAt: Date | null;
      status: GQMeasurementProjectStatus;
      coverageEndDate: Date;
      deadline: Date | null;
    }>;
  };
};

export type GQPublishFinancialsReviewItemsMutationVariables = Exact<{
  input: GQPublishFinancialsReviewItemsInput;
}>;

export type GQPublishFinancialsReviewItemsMutation = {
  __typename?: 'Mutation';
  publishFinancialsReviewItems: {
    __typename?: 'PublishFinancialsReviewItemsPayload';
    financialsReviewItems: {
      __typename?: 'FinancialsReviewItemConnection';
      edges: Array<{
        __typename?: 'FinancialsReviewItemEdge';
        node: {
          __typename: 'FinancialsReviewItem';
          id: string;
          createdAt: Date;
          status: GQFinancialsReviewItemStatus;
          initiallyIncluded: boolean | null;
          exclusionReason: string | null;
          prompt: string | null;
          userResponse: string | null;
          name: string;
          adminUrl: string;
          account: {
            __typename?: 'FinancialsAccount';
            accountId: string;
            description: string | null;
          };
          initialIndustryClassification: {
            __typename?: 'IndustryClassification';
            id: string;
            shortDescription: string;
            longDescription: string;
          } | null;
          respondingUser: {
            __typename?: 'User';
            id: string;
            name: string;
          } | null;
          discussion: {
            __typename?: 'DataIssue';
            id: string;
            state: GQDataIssueState;
          } | null;
          group: {
            __typename?: 'FinancialsReviewGroup';
            id: string;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
            };
          };
          ancestorRelations: Array<
            | {
                __typename: 'ActivityDataTable';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'DataIssue';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'Dataset';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'Datasource';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'FileMetadata';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'FootprintQuestion';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'MeasurementProject';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'MeasurementVerificationItemQuestion';
                id: string;
                name: string;
                adminUrl: string;
              }
          >;
        } | null;
      } | null>;
    };
  } | null;
};

export type GQPreviewFinancialsReviewEmailsMutationVariables = Exact<{
  input: GQPreviewFinancialsReviewEmailsInput;
}>;

export type GQPreviewFinancialsReviewEmailsMutation = {
  __typename?: 'Mutation';
  previewFinancialsReviewEmails: {
    __typename?: 'PreviewFinancialsReviewEmailsPayload';
    emails: Array<{
      __typename?: 'EmailContentsWithUserRecipient';
      userId: string;
      emailContents: {
        __typename?: 'EmailContents';
        subject: string;
        bodyHtml: string;
        recipients: Array<{
          __typename?: 'EmailUser';
          email: string;
          name: string;
        }>;
        ccs: Array<{
          __typename?: 'EmailUser';
          email: string;
          name: string;
        }> | null;
        sender: { __typename?: 'EmailUser'; email: string; name: string };
        bccs: Array<{
          __typename?: 'EmailUser';
          email: string;
          name: string;
        } | null> | null;
      };
    }>;
  } | null;
};

export type GQDeleteFinancialsReviewItemMutationVariables = Exact<{
  input: GQDeleteFinancialsReviewItemInput;
}>;

export type GQDeleteFinancialsReviewItemMutation = {
  __typename?: 'Mutation';
  deleteFinancialsReviewItem: {
    __typename?: 'DeleteFinancialsReviewItemPayload';
    id: string;
  } | null;
};

export type GQLogLocalMeasurementStateMutationVariables = Exact<{
  orgId: Scalars['String']['input'];
  tag: Scalars['String']['input'];
  data: Scalars['String']['input'];
}>;

export type GQLogLocalMeasurementStateMutation = {
  __typename?: 'Mutation';
  logLocalMeasurementState: boolean | null;
};

type GQContextRelationFields_ActivityDataTable_Fragment = {
  __typename: 'ActivityDataTable';
  id: string;
  name: string;
  adminUrl: string;
};

type GQContextRelationFields_DataIssue_Fragment = {
  __typename: 'DataIssue';
  id: string;
  name: string;
  adminUrl: string;
};

type GQContextRelationFields_Dataset_Fragment = {
  __typename: 'Dataset';
  id: string;
  name: string;
  adminUrl: string;
};

type GQContextRelationFields_Datasource_Fragment = {
  __typename: 'Datasource';
  id: string;
  name: string;
  adminUrl: string;
};

type GQContextRelationFields_FileMetadata_Fragment = {
  __typename: 'FileMetadata';
  id: string;
  name: string;
  adminUrl: string;
};

type GQContextRelationFields_FinancialsReviewItem_Fragment = {
  __typename: 'FinancialsReviewItem';
  id: string;
  name: string;
  adminUrl: string;
};

type GQContextRelationFields_FootprintQuestion_Fragment = {
  __typename: 'FootprintQuestion';
  id: string;
  name: string;
  adminUrl: string;
};

type GQContextRelationFields_MeasurementProject_Fragment = {
  __typename: 'MeasurementProject';
  id: string;
  name: string;
  adminUrl: string;
};

type GQContextRelationFields_MeasurementVerificationItemQuestion_Fragment = {
  __typename: 'MeasurementVerificationItemQuestion';
  id: string;
  name: string;
  adminUrl: string;
};

export type GQContextRelationFieldsFragment =
  | GQContextRelationFields_ActivityDataTable_Fragment
  | GQContextRelationFields_DataIssue_Fragment
  | GQContextRelationFields_Dataset_Fragment
  | GQContextRelationFields_Datasource_Fragment
  | GQContextRelationFields_FileMetadata_Fragment
  | GQContextRelationFields_FinancialsReviewItem_Fragment
  | GQContextRelationFields_FootprintQuestion_Fragment
  | GQContextRelationFields_MeasurementProject_Fragment
  | GQContextRelationFields_MeasurementVerificationItemQuestion_Fragment;

type GQContextSeedObjectFields_ActivityDataTable_Fragment = {
  __typename: 'ActivityDataTable';
  id: string;
  name: string;
  adminUrl: string;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

type GQContextSeedObjectFields_DataIssue_Fragment = {
  __typename: 'DataIssue';
  id: string;
  name: string;
  adminUrl: string;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

type GQContextSeedObjectFields_Dataset_Fragment = {
  __typename: 'Dataset';
  id: string;
  name: string;
  adminUrl: string;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

type GQContextSeedObjectFields_Datasource_Fragment = {
  __typename: 'Datasource';
  id: string;
  name: string;
  adminUrl: string;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

type GQContextSeedObjectFields_FileMetadata_Fragment = {
  __typename: 'FileMetadata';
  id: string;
  name: string;
  adminUrl: string;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

type GQContextSeedObjectFields_FinancialsReviewItem_Fragment = {
  __typename: 'FinancialsReviewItem';
  id: string;
  name: string;
  adminUrl: string;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

type GQContextSeedObjectFields_FootprintQuestion_Fragment = {
  __typename: 'FootprintQuestion';
  id: string;
  name: string;
  adminUrl: string;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

type GQContextSeedObjectFields_MeasurementProject_Fragment = {
  __typename: 'MeasurementProject';
  id: string;
  name: string;
  adminUrl: string;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

type GQContextSeedObjectFields_MeasurementVerificationItemQuestion_Fragment = {
  __typename: 'MeasurementVerificationItemQuestion';
  id: string;
  name: string;
  adminUrl: string;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

export type GQContextSeedObjectFieldsFragment =
  | GQContextSeedObjectFields_ActivityDataTable_Fragment
  | GQContextSeedObjectFields_DataIssue_Fragment
  | GQContextSeedObjectFields_Dataset_Fragment
  | GQContextSeedObjectFields_Datasource_Fragment
  | GQContextSeedObjectFields_FileMetadata_Fragment
  | GQContextSeedObjectFields_FinancialsReviewItem_Fragment
  | GQContextSeedObjectFields_FootprintQuestion_Fragment
  | GQContextSeedObjectFields_MeasurementProject_Fragment
  | GQContextSeedObjectFields_MeasurementVerificationItemQuestion_Fragment;

export type GQMeasurementContextItemFieldsForListFragment = {
  __typename?: 'MeasurementContextItem';
  id: string;
  title: string;
  descriptionMd: string;
  tags: Array<string>;
  urls: Array<string>;
  updatedAt: Date;
  author: { __typename?: 'WatershedEmployee'; id: string; name: string } | null;
  org: { __typename?: 'Organization'; id: string };
  history: Array<{
    __typename?: 'MeasurementContextItemHistory';
    id: string;
    title: string;
    descriptionMd: string;
    tags: Array<string>;
    urls: Array<string>;
    createdAt: Date;
    author: {
      __typename?: 'WatershedEmployee';
      id: string;
      name: string;
    } | null;
    relations: Array<
      | {
          __typename: 'ActivityDataTable';
          id: string;
          name: string;
          adminUrl: string;
        }
      | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
      | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
      | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
      | {
          __typename: 'FileMetadata';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'FinancialsReviewItem';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'FootprintQuestion';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'MeasurementProject';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'MeasurementVerificationItemQuestion';
          id: string;
          name: string;
          adminUrl: string;
        }
    >;
  }>;
  relations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

export type GQMeasurementContextPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMeasurementContextPageQuery = {
  __typename?: 'Query';
  measurementContextItems: {
    __typename?: 'MeasurementContextItemConnection';
    edges: Array<{
      __typename?: 'MeasurementContextItemEdge';
      node: {
        __typename?: 'MeasurementContextItem';
        id: string;
        title: string;
        descriptionMd: string;
        tags: Array<string>;
        urls: Array<string>;
        updatedAt: Date;
        author: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        } | null;
        org: { __typename?: 'Organization'; id: string };
        history: Array<{
          __typename?: 'MeasurementContextItemHistory';
          id: string;
          title: string;
          descriptionMd: string;
          tags: Array<string>;
          urls: Array<string>;
          createdAt: Date;
          author: {
            __typename?: 'WatershedEmployee';
            id: string;
            name: string;
          } | null;
          relations: Array<
            | {
                __typename: 'ActivityDataTable';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'DataIssue';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'Dataset';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'Datasource';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'FileMetadata';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'FootprintQuestion';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'MeasurementProject';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'MeasurementVerificationItemQuestion';
                id: string;
                name: string;
                adminUrl: string;
              }
          >;
        }>;
        relations: Array<
          | {
              __typename: 'ActivityDataTable';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'DataIssue';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Dataset';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Datasource';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FileMetadata';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FootprintQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementProject';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
        >;
      } | null;
    } | null>;
  };
};

export type GQCreateMeasurementContextItemMutationVariables = Exact<{
  input: GQCreateMeasurementContextItemInput;
}>;

export type GQCreateMeasurementContextItemMutation = {
  __typename?: 'Mutation';
  createMeasurementContextItem: {
    __typename?: 'CreateMeasurementContextItemPayload';
    measurementContextItem: {
      __typename?: 'MeasurementContextItem';
      id: string;
      title: string;
      descriptionMd: string;
      tags: Array<string>;
      urls: Array<string>;
      updatedAt: Date;
      author: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
      org: { __typename?: 'Organization'; id: string };
      history: Array<{
        __typename?: 'MeasurementContextItemHistory';
        id: string;
        title: string;
        descriptionMd: string;
        tags: Array<string>;
        urls: Array<string>;
        createdAt: Date;
        author: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        } | null;
        relations: Array<
          | {
              __typename: 'ActivityDataTable';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'DataIssue';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Dataset';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Datasource';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FileMetadata';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FootprintQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementProject';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
        >;
      }>;
      relations: Array<
        | {
            __typename: 'ActivityDataTable';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'DataIssue';
            id: string;
            name: string;
            adminUrl: string;
          }
        | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
        | {
            __typename: 'Datasource';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FileMetadata';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FootprintQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementProject';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
      >;
    };
  };
};

export type GQUpdateMeasurementContextItemMutationVariables = Exact<{
  input: GQUpdateMeasurementContextItemInput;
}>;

export type GQUpdateMeasurementContextItemMutation = {
  __typename?: 'Mutation';
  updateMeasurementContextItem: {
    __typename?: 'UpdateMeasurementContextItemPayload';
    measurementContextItem: {
      __typename?: 'MeasurementContextItem';
      id: string;
      title: string;
      descriptionMd: string;
      tags: Array<string>;
      urls: Array<string>;
      updatedAt: Date;
      author: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
      org: { __typename?: 'Organization'; id: string };
      history: Array<{
        __typename?: 'MeasurementContextItemHistory';
        id: string;
        title: string;
        descriptionMd: string;
        tags: Array<string>;
        urls: Array<string>;
        createdAt: Date;
        author: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        } | null;
        relations: Array<
          | {
              __typename: 'ActivityDataTable';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'DataIssue';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Dataset';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Datasource';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FileMetadata';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FootprintQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementProject';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
        >;
      }>;
      relations: Array<
        | {
            __typename: 'ActivityDataTable';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'DataIssue';
            id: string;
            name: string;
            adminUrl: string;
          }
        | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
        | {
            __typename: 'Datasource';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FileMetadata';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FootprintQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementProject';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
      >;
    };
  };
};

export type GQDeleteMeasurementContextItemMutationVariables = Exact<{
  input: GQDeleteMeasurementContextItemInput;
}>;

export type GQDeleteMeasurementContextItemMutation = {
  __typename?: 'Mutation';
  deleteMeasurementContextItem: {
    __typename?: 'DeleteMeasurementContextItemPayload';
    measurementContextItem: {
      __typename?: 'MeasurementContextItem';
      id: string;
      title: string;
      descriptionMd: string;
      tags: Array<string>;
      urls: Array<string>;
      updatedAt: Date;
      author: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
      org: { __typename?: 'Organization'; id: string };
      history: Array<{
        __typename?: 'MeasurementContextItemHistory';
        id: string;
        title: string;
        descriptionMd: string;
        tags: Array<string>;
        urls: Array<string>;
        createdAt: Date;
        author: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        } | null;
        relations: Array<
          | {
              __typename: 'ActivityDataTable';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'DataIssue';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Dataset';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Datasource';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FileMetadata';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FootprintQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementProject';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
        >;
      }>;
      relations: Array<
        | {
            __typename: 'ActivityDataTable';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'DataIssue';
            id: string;
            name: string;
            adminUrl: string;
          }
        | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
        | {
            __typename: 'Datasource';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FileMetadata';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FootprintQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementProject';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
      >;
    };
  };
};

type GQContextRelationFieldsForSearch_ActivityDataTable_Fragment = {
  __typename?: 'ActivityDataTable';
  id: string;
  name: string;
  ancestorRelations: Array<
    | { __typename?: 'ActivityDataTable'; id: string }
    | { __typename?: 'DataIssue'; id: string }
    | { __typename?: 'Dataset'; id: string }
    | { __typename?: 'Datasource'; id: string }
    | { __typename?: 'FileMetadata'; id: string }
    | { __typename?: 'FinancialsReviewItem'; id: string }
    | { __typename?: 'FootprintQuestion'; id: string }
    | { __typename?: 'MeasurementProject'; id: string }
    | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
  >;
};

type GQContextRelationFieldsForSearch_DataIssue_Fragment = {
  __typename?: 'DataIssue';
  id: string;
  name: string;
  ancestorRelations: Array<
    | { __typename?: 'ActivityDataTable'; id: string }
    | { __typename?: 'DataIssue'; id: string }
    | { __typename?: 'Dataset'; id: string }
    | { __typename?: 'Datasource'; id: string }
    | { __typename?: 'FileMetadata'; id: string }
    | { __typename?: 'FinancialsReviewItem'; id: string }
    | { __typename?: 'FootprintQuestion'; id: string }
    | { __typename?: 'MeasurementProject'; id: string }
    | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
  >;
};

type GQContextRelationFieldsForSearch_Dataset_Fragment = {
  __typename?: 'Dataset';
  id: string;
  name: string;
  ancestorRelations: Array<
    | { __typename?: 'ActivityDataTable'; id: string }
    | { __typename?: 'DataIssue'; id: string }
    | { __typename?: 'Dataset'; id: string }
    | { __typename?: 'Datasource'; id: string }
    | { __typename?: 'FileMetadata'; id: string }
    | { __typename?: 'FinancialsReviewItem'; id: string }
    | { __typename?: 'FootprintQuestion'; id: string }
    | { __typename?: 'MeasurementProject'; id: string }
    | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
  >;
};

type GQContextRelationFieldsForSearch_Datasource_Fragment = {
  __typename?: 'Datasource';
  id: string;
  name: string;
  ancestorRelations: Array<
    | { __typename?: 'ActivityDataTable'; id: string }
    | { __typename?: 'DataIssue'; id: string }
    | { __typename?: 'Dataset'; id: string }
    | { __typename?: 'Datasource'; id: string }
    | { __typename?: 'FileMetadata'; id: string }
    | { __typename?: 'FinancialsReviewItem'; id: string }
    | { __typename?: 'FootprintQuestion'; id: string }
    | { __typename?: 'MeasurementProject'; id: string }
    | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
  >;
};

type GQContextRelationFieldsForSearch_FileMetadata_Fragment = {
  __typename?: 'FileMetadata';
  id: string;
  name: string;
  ancestorRelations: Array<
    | { __typename?: 'ActivityDataTable'; id: string }
    | { __typename?: 'DataIssue'; id: string }
    | { __typename?: 'Dataset'; id: string }
    | { __typename?: 'Datasource'; id: string }
    | { __typename?: 'FileMetadata'; id: string }
    | { __typename?: 'FinancialsReviewItem'; id: string }
    | { __typename?: 'FootprintQuestion'; id: string }
    | { __typename?: 'MeasurementProject'; id: string }
    | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
  >;
};

type GQContextRelationFieldsForSearch_FinancialsReviewItem_Fragment = {
  __typename?: 'FinancialsReviewItem';
  id: string;
  name: string;
  ancestorRelations: Array<
    | { __typename?: 'ActivityDataTable'; id: string }
    | { __typename?: 'DataIssue'; id: string }
    | { __typename?: 'Dataset'; id: string }
    | { __typename?: 'Datasource'; id: string }
    | { __typename?: 'FileMetadata'; id: string }
    | { __typename?: 'FinancialsReviewItem'; id: string }
    | { __typename?: 'FootprintQuestion'; id: string }
    | { __typename?: 'MeasurementProject'; id: string }
    | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
  >;
};

type GQContextRelationFieldsForSearch_FootprintQuestion_Fragment = {
  __typename?: 'FootprintQuestion';
  id: string;
  name: string;
  ancestorRelations: Array<
    | { __typename?: 'ActivityDataTable'; id: string }
    | { __typename?: 'DataIssue'; id: string }
    | { __typename?: 'Dataset'; id: string }
    | { __typename?: 'Datasource'; id: string }
    | { __typename?: 'FileMetadata'; id: string }
    | { __typename?: 'FinancialsReviewItem'; id: string }
    | { __typename?: 'FootprintQuestion'; id: string }
    | { __typename?: 'MeasurementProject'; id: string }
    | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
  >;
};

type GQContextRelationFieldsForSearch_MeasurementProject_Fragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  ancestorRelations: Array<
    | { __typename?: 'ActivityDataTable'; id: string }
    | { __typename?: 'DataIssue'; id: string }
    | { __typename?: 'Dataset'; id: string }
    | { __typename?: 'Datasource'; id: string }
    | { __typename?: 'FileMetadata'; id: string }
    | { __typename?: 'FinancialsReviewItem'; id: string }
    | { __typename?: 'FootprintQuestion'; id: string }
    | { __typename?: 'MeasurementProject'; id: string }
    | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
  >;
};

type GQContextRelationFieldsForSearch_MeasurementVerificationItemQuestion_Fragment =
  {
    __typename?: 'MeasurementVerificationItemQuestion';
    id: string;
    name: string;
    ancestorRelations: Array<
      | { __typename?: 'ActivityDataTable'; id: string }
      | { __typename?: 'DataIssue'; id: string }
      | { __typename?: 'Dataset'; id: string }
      | { __typename?: 'Datasource'; id: string }
      | { __typename?: 'FileMetadata'; id: string }
      | { __typename?: 'FinancialsReviewItem'; id: string }
      | { __typename?: 'FootprintQuestion'; id: string }
      | { __typename?: 'MeasurementProject'; id: string }
      | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
    >;
  };

export type GQContextRelationFieldsForSearchFragment =
  | GQContextRelationFieldsForSearch_ActivityDataTable_Fragment
  | GQContextRelationFieldsForSearch_DataIssue_Fragment
  | GQContextRelationFieldsForSearch_Dataset_Fragment
  | GQContextRelationFieldsForSearch_Datasource_Fragment
  | GQContextRelationFieldsForSearch_FileMetadata_Fragment
  | GQContextRelationFieldsForSearch_FinancialsReviewItem_Fragment
  | GQContextRelationFieldsForSearch_FootprintQuestion_Fragment
  | GQContextRelationFieldsForSearch_MeasurementProject_Fragment
  | GQContextRelationFieldsForSearch_MeasurementVerificationItemQuestion_Fragment;

export type GQGetMeasurementContextRelationsForSearchQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetMeasurementContextRelationsForSearchQuery = {
  __typename?: 'Query';
  activityDataTables: {
    __typename?: 'ActivityDataTableConnection';
    edges: Array<{
      __typename?: 'ActivityDataTableEdge';
      node: {
        __typename?: 'ActivityDataTable';
        id: string;
        name: string;
        ancestorRelations: Array<
          | { __typename?: 'ActivityDataTable'; id: string }
          | { __typename?: 'DataIssue'; id: string }
          | { __typename?: 'Dataset'; id: string }
          | { __typename?: 'Datasource'; id: string }
          | { __typename?: 'FileMetadata'; id: string }
          | { __typename?: 'FinancialsReviewItem'; id: string }
          | { __typename?: 'FootprintQuestion'; id: string }
          | { __typename?: 'MeasurementProject'; id: string }
          | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
        >;
      } | null;
    } | null>;
  };
  files: {
    __typename?: 'FileMetadataConnection';
    edges: Array<{
      __typename?: 'FileMetadataEdge';
      node: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        ancestorRelations: Array<
          | { __typename?: 'ActivityDataTable'; id: string }
          | { __typename?: 'DataIssue'; id: string }
          | { __typename?: 'Dataset'; id: string }
          | { __typename?: 'Datasource'; id: string }
          | { __typename?: 'FileMetadata'; id: string }
          | { __typename?: 'FinancialsReviewItem'; id: string }
          | { __typename?: 'FootprintQuestion'; id: string }
          | { __typename?: 'MeasurementProject'; id: string }
          | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
        >;
      } | null;
    } | null>;
  };
  financialsReviewItemsAdmin: {
    __typename?: 'FinancialsReviewItemConnection';
    edges: Array<{
      __typename?: 'FinancialsReviewItemEdge';
      node: {
        __typename?: 'FinancialsReviewItem';
        initiallyIncluded: boolean | null;
        prompt: string | null;
        id: string;
        name: string;
        initialIndustryClassification: {
          __typename?: 'IndustryClassification';
          id: string;
        } | null;
        ancestorRelations: Array<
          | { __typename?: 'ActivityDataTable'; id: string }
          | { __typename?: 'DataIssue'; id: string }
          | { __typename?: 'Dataset'; id: string }
          | { __typename?: 'Datasource'; id: string }
          | { __typename?: 'FileMetadata'; id: string }
          | { __typename?: 'FinancialsReviewItem'; id: string }
          | { __typename?: 'FootprintQuestion'; id: string }
          | { __typename?: 'MeasurementProject'; id: string }
          | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
        >;
      } | null;
    } | null>;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
        ancestorRelations: Array<
          | { __typename?: 'ActivityDataTable'; id: string }
          | { __typename?: 'DataIssue'; id: string }
          | { __typename?: 'Dataset'; id: string }
          | { __typename?: 'Datasource'; id: string }
          | { __typename?: 'FileMetadata'; id: string }
          | { __typename?: 'FinancialsReviewItem'; id: string }
          | { __typename?: 'FootprintQuestion'; id: string }
          | { __typename?: 'MeasurementProject'; id: string }
          | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
        >;
      }>;
      ancestorRelations: Array<
        | { __typename?: 'ActivityDataTable'; id: string }
        | { __typename?: 'DataIssue'; id: string }
        | { __typename?: 'Dataset'; id: string }
        | { __typename?: 'Datasource'; id: string }
        | { __typename?: 'FileMetadata'; id: string }
        | { __typename?: 'FinancialsReviewItem'; id: string }
        | { __typename?: 'FootprintQuestion'; id: string }
        | { __typename?: 'MeasurementProject'; id: string }
        | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
      >;
    }>;
    dataIssues: Array<{
      __typename?: 'DataIssue';
      id: string;
      name: string;
      linkedObject:
        | { __typename: 'FinancialsReviewItem'; id: string }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            item: {
              __typename?: 'MeasurementVerificationItem';
              id: string;
              datasetName: string;
            };
          }
        | {
            __typename: 'UserUploadTask';
            id: string;
            assignee: {
              __typename?: 'User';
              id: string;
              name: string;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
              roles: Array<{
                __typename?: 'UserRoleAssignment';
                id: string;
                revokedAt: Date | null;
                role: {
                  __typename?: 'Role';
                  id: string;
                  name: string;
                  description: string | null;
                  permissions: Array<{
                    __typename?: 'PermissionItem';
                    id: string;
                    objectId: string | null;
                    permission: GQPermissionType;
                    revokedAt: Date | null;
                    object:
                      | { __typename: 'Company'; id: string; name: string }
                      | {
                          __typename: 'CompanySurvey';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Dataset'; id: string; name: string }
                      | { __typename: 'Datasource'; id: string; name: string }
                      | {
                          __typename: 'EngagementTask';
                          id: string;
                          name: string;
                        }
                      | { __typename: 'Fund'; id: string; name: string }
                      | null;
                  }>;
                };
              }>;
            } | null;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: { __typename?: 'Dataset'; id: string; name: string };
            };
          }
        | { __typename: 'ValueMappingTask'; id: string }
        | null;
      ancestorRelations: Array<
        | { __typename?: 'ActivityDataTable'; id: string }
        | { __typename?: 'DataIssue'; id: string }
        | { __typename?: 'Dataset'; id: string }
        | { __typename?: 'Datasource'; id: string }
        | { __typename?: 'FileMetadata'; id: string }
        | { __typename?: 'FinancialsReviewItem'; id: string }
        | { __typename?: 'FootprintQuestion'; id: string }
        | { __typename?: 'MeasurementProject'; id: string }
        | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
      >;
    }>;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      coverageStartDate: Date;
      id: string;
      name: string;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            questions: {
              __typename?: 'MeasurementVerificationItemQuestionConnection';
              edges: Array<{
                __typename?: 'MeasurementVerificationItemQuestionEdge';
                node: {
                  __typename?: 'MeasurementVerificationItemQuestion';
                  id: string;
                  name: string;
                  ancestorRelations: Array<
                    | { __typename?: 'ActivityDataTable'; id: string }
                    | { __typename?: 'DataIssue'; id: string }
                    | { __typename?: 'Dataset'; id: string }
                    | { __typename?: 'Datasource'; id: string }
                    | { __typename?: 'FileMetadata'; id: string }
                    | { __typename?: 'FinancialsReviewItem'; id: string }
                    | { __typename?: 'FootprintQuestion'; id: string }
                    | { __typename?: 'MeasurementProject'; id: string }
                    | {
                        __typename?: 'MeasurementVerificationItemQuestion';
                        id: string;
                      }
                  >;
                } | null;
              }>;
            };
          } | null;
        }>;
      };
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: { __typename?: 'Dataset'; id: string; name: string };
            };
          } | null;
        } | null>;
      };
      ancestorRelations: Array<
        | { __typename?: 'ActivityDataTable'; id: string }
        | { __typename?: 'DataIssue'; id: string }
        | { __typename?: 'Dataset'; id: string }
        | { __typename?: 'Datasource'; id: string }
        | { __typename?: 'FileMetadata'; id: string }
        | { __typename?: 'FinancialsReviewItem'; id: string }
        | { __typename?: 'FootprintQuestion'; id: string }
        | { __typename?: 'MeasurementProject'; id: string }
        | { __typename?: 'MeasurementVerificationItemQuestion'; id: string }
      >;
    }>;
  };
  inboxItemsForOrg: {
    __typename?: 'OrganizationInboxItemConnection';
    edges: Array<{
      __typename?: 'OrganizationInboxItemEdge';
      node:
        | { __typename?: 'DataIssue' }
        | {
            __typename: 'FootprintQuestion';
            id: string;
            name: string;
            ancestorRelations: Array<
              | { __typename?: 'ActivityDataTable'; id: string }
              | { __typename?: 'DataIssue'; id: string }
              | { __typename?: 'Dataset'; id: string }
              | { __typename?: 'Datasource'; id: string }
              | { __typename?: 'FileMetadata'; id: string }
              | { __typename?: 'FinancialsReviewItem'; id: string }
              | { __typename?: 'FootprintQuestion'; id: string }
              | { __typename?: 'MeasurementProject'; id: string }
              | {
                  __typename?: 'MeasurementVerificationItemQuestion';
                  id: string;
                }
            >;
          }
        | { __typename?: 'UserUploadTask' }
        | null;
    } | null>;
  };
};

export type GQPrepublicationTestSuiteExecutionFieldsFragment = {
  __typename?: 'PrepublicationTestSuiteExecution';
  errorMessage: string | null;
  measurementTestSuite: {
    __typename?: 'MeasurementTestSuite';
    description: string;
    createdAt: Date;
    deletedAt: Date | null;
    id: string;
    title: string;
    kind: GQMeasurementTestSuiteKind | null;
    status: string;
    latestChangelogEntry: {
      __typename?: 'MeasurementTestSuiteChangelog';
      id: string;
      changelog: string;
      createdAt: Date;
      author: { __typename?: 'User'; id: string; name: string } | null;
      measurementResourceEvent: {
        __typename?: 'MeasurementResourceEvent';
        id: string;
        createdAt: Date;
        eventKind: string;
        eventData: any;
        author: { __typename?: 'User'; id: string; name: string } | null;
      } | null;
    };
    measurementTestSuiteBarts: Array<{
      __typename?: 'MeasurementTestSuiteBart';
      id: string;
      title: string;
      activityTypeName: string;
      fieldDefaults: any;
      hiddenFields: Array<string>;
      measurementTestRows: Array<{
        __typename?: 'MeasurementTestRow';
        id: string;
        description: string | null;
        bartRowId: number;
        activityRow: any;
      }>;
      activityTypeFields: Array<{
        __typename?: 'BusinessActivityTypeField';
        id: string;
        description: string;
        fieldName: string;
        fieldType: GQBusinessActivityTypeFieldType;
        fieldValueOneOf: any | null;
        isRequired: boolean;
      }>;
      businessActivityTypeVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      };
    }>;
    footprintTestSuiteConfig: {
      __typename?: 'FootprintTestSuiteConfig';
      id: string;
      pipelineStartDate: YearMonth | null;
      pipelineEndDate: YearMonth | null;
      customTagNames: Array<string> | null;
      marketplaceAllocationInstructionIds: Array<string> | null;
      exclusionRuleIds: Array<string> | null;
      categoryRuleIds: Array<string> | null;
      shouldExclude: boolean;
    } | null;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
    } | null;
    calculationTags: Array<{
      __typename?: 'CalculationTag';
      id: string;
      name: string;
    }>;
    emissionsModelReleases: Array<{
      __typename?: 'EmissionsModelRelease';
      id: string;
      updatedAt: Date;
      userAuthorId: string | null;
      displayName: string;
      businessActivityTypeName: string;
      internalNotes: string;
      externalNotes: string;
      orgId: string | null;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      isCustomToUserOrg: boolean | null;
      isOrgGated: boolean;
      user: { __typename?: 'User'; id: string; name: string } | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      } | null;
      latestCompatibleBatVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
      orgAccess: Array<{
        __typename?: 'EmissionsModelReleaseOrgAccess';
        id: string;
        createdAt: Date;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        };
      }>;
    }>;
  };
  methodologyTestSuiteExecution: {
    __typename?: 'MethodologyTestSuiteExecution';
    id: string;
    createdAt: Date;
    status: GQMeasurementTestSuiteStageExecutionStatus;
    author: {
      __typename?: 'WatershedEmployee';
      id: string;
      name: string;
    } | null;
  };
  footprintTestSuiteExecution: {
    __typename?: 'FootprintTestSuiteExecution';
    id: string;
    status: GQMeasurementTestSuiteStageExecutionStatus;
    error: string | null;
    warnings: Array<string | null> | null;
    failedStep: GQFootprintAssemblyStep | null;
    runOnTheT: boolean | null;
  } | null;
  footprintTestSuiteExecutionOnTheT: {
    __typename?: 'FootprintTestSuiteExecution';
    id: string;
    status: GQMeasurementTestSuiteStageExecutionStatus;
    error: string | null;
    warnings: Array<string | null> | null;
    failedStep: GQFootprintAssemblyStep | null;
    runOnTheT: boolean | null;
  } | null;
};

export type GQGetPrepublicationMeasurementTestResultsQueryVariables = Exact<{
  workflowId: Scalars['ID']['input'];
}>;

export type GQGetPrepublicationMeasurementTestResultsQuery = {
  __typename?: 'Query';
  prepublicationMeasurementTestResults: {
    __typename?: 'PrepublicationTestResults';
    measurementTestSuiteExecutions: Array<{
      __typename?: 'PrepublicationTestSuiteExecution';
      errorMessage: string | null;
      measurementTestSuite: {
        __typename?: 'MeasurementTestSuite';
        description: string;
        createdAt: Date;
        deletedAt: Date | null;
        id: string;
        title: string;
        kind: GQMeasurementTestSuiteKind | null;
        status: string;
        latestChangelogEntry: {
          __typename?: 'MeasurementTestSuiteChangelog';
          id: string;
          changelog: string;
          createdAt: Date;
          author: { __typename?: 'User'; id: string; name: string } | null;
          measurementResourceEvent: {
            __typename?: 'MeasurementResourceEvent';
            id: string;
            createdAt: Date;
            eventKind: string;
            eventData: any;
            author: { __typename?: 'User'; id: string; name: string } | null;
          } | null;
        };
        measurementTestSuiteBarts: Array<{
          __typename?: 'MeasurementTestSuiteBart';
          id: string;
          title: string;
          activityTypeName: string;
          fieldDefaults: any;
          hiddenFields: Array<string>;
          measurementTestRows: Array<{
            __typename?: 'MeasurementTestRow';
            id: string;
            description: string | null;
            bartRowId: number;
            activityRow: any;
          }>;
          activityTypeFields: Array<{
            __typename?: 'BusinessActivityTypeField';
            id: string;
            description: string;
            fieldName: string;
            fieldType: GQBusinessActivityTypeFieldType;
            fieldValueOneOf: any | null;
            isRequired: boolean;
          }>;
          businessActivityTypeVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
            name: string;
          };
        }>;
        footprintTestSuiteConfig: {
          __typename?: 'FootprintTestSuiteConfig';
          id: string;
          pipelineStartDate: YearMonth | null;
          pipelineEndDate: YearMonth | null;
          customTagNames: Array<string> | null;
          marketplaceAllocationInstructionIds: Array<string> | null;
          exclusionRuleIds: Array<string> | null;
          categoryRuleIds: Array<string> | null;
          shouldExclude: boolean;
        } | null;
        organization: {
          __typename?: 'Organization';
          id: string;
          name: string;
        } | null;
        calculationTags: Array<{
          __typename?: 'CalculationTag';
          id: string;
          name: string;
        }>;
        emissionsModelReleases: Array<{
          __typename?: 'EmissionsModelRelease';
          id: string;
          updatedAt: Date;
          userAuthorId: string | null;
          displayName: string;
          businessActivityTypeName: string;
          internalNotes: string;
          externalNotes: string;
          orgId: string | null;
          lifecyclePhase: GQEmReleaseLifecyclePhase;
          isCustomToUserOrg: boolean | null;
          isOrgGated: boolean;
          user: { __typename?: 'User'; id: string; name: string } | null;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
            testOrg: boolean;
          } | null;
          latestCompatibleBatVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
            name: string;
          } | null;
          orgAccess: Array<{
            __typename?: 'EmissionsModelReleaseOrgAccess';
            id: string;
            createdAt: Date;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
              testOrg: boolean;
            };
          }>;
        }>;
      };
      methodologyTestSuiteExecution: {
        __typename?: 'MethodologyTestSuiteExecution';
        id: string;
        createdAt: Date;
        status: GQMeasurementTestSuiteStageExecutionStatus;
        author: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        } | null;
      };
      footprintTestSuiteExecution: {
        __typename?: 'FootprintTestSuiteExecution';
        id: string;
        status: GQMeasurementTestSuiteStageExecutionStatus;
        error: string | null;
        warnings: Array<string | null> | null;
        failedStep: GQFootprintAssemblyStep | null;
        runOnTheT: boolean | null;
      } | null;
      footprintTestSuiteExecutionOnTheT: {
        __typename?: 'FootprintTestSuiteExecution';
        id: string;
        status: GQMeasurementTestSuiteStageExecutionStatus;
        error: string | null;
        warnings: Array<string | null> | null;
        failedStep: GQFootprintAssemblyStep | null;
        runOnTheT: boolean | null;
      } | null;
    }>;
    emissionsModelValidationErrors: Array<{
      __typename?: 'EmissionsModelError';
      msg: string;
      path: Array<string>;
      severity: GQEmissionsModelErrorSeverity;
    }> | null;
  };
};

export type GQFootprintTestSuiteDefaultOrgQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFootprintTestSuiteDefaultOrgQuery = {
  __typename?: 'Query';
  footprintTestSuiteConfigDefaultOrgId: string | null;
};

export type GQSetFootprintTestSuiteDefaultOrgMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQSetFootprintTestSuiteDefaultOrgMutation = {
  __typename?: 'Mutation';
  setFootprintTestSuiteConfigDefaultOrgId: string;
};

export type GQMethodologyTestSuiteValidationResultsQueryVariables = Exact<{
  measurementTestSuiteId: Scalars['ID']['input'];
}>;

export type GQMethodologyTestSuiteValidationResultsQuery = {
  __typename?: 'Query';
  methodologyTestSuiteValidationResults: {
    __typename?: 'MethodologyTestSuiteValidationResults';
    id: string;
    isValid: boolean;
    warningMessage: string | null;
  } | null;
};

export type GQCreateMeasurementTestSuiteMutationVariables = Exact<{
  input: GQCreateMeasurementTestSuiteInput;
}>;

export type GQCreateMeasurementTestSuiteMutation = {
  __typename?: 'Mutation';
  createMeasurementTestSuite: {
    __typename?: 'CreateMeasurementTestSuitePayload';
    measurementTestSuite: {
      __typename?: 'MeasurementTestSuite';
      description: string;
      createdAt: Date;
      deletedAt: Date | null;
      id: string;
      title: string;
      kind: GQMeasurementTestSuiteKind | null;
      status: string;
      measurementTestSuiteBarts: Array<{
        __typename?: 'MeasurementTestSuiteBart';
        measurementTestRowCount: number;
        id: string;
        title: string;
        activityTypeName: string;
        fieldDefaults: any;
        hiddenFields: Array<string>;
        businessActivityTypeVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
          name: string;
        };
      }>;
      latestMethodologyTestSuiteExecution: {
        __typename?: 'MethodologyTestSuiteExecution';
        id: string;
        status: GQMeasurementTestSuiteStageExecutionStatus;
      } | null;
      latestFootprintTestSuiteExecution: {
        __typename?: 'FootprintTestSuiteExecution';
        id: string;
        status: GQMeasurementTestSuiteStageExecutionStatus;
      } | null;
      latestFootprintTestSuiteExecutionOnTheT: {
        __typename?: 'FootprintTestSuiteExecution';
        id: string;
        status: GQMeasurementTestSuiteStageExecutionStatus;
      } | null;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
      } | null;
      calculationTags: Array<{
        __typename?: 'CalculationTag';
        id: string;
        name: string;
      }>;
      emissionsModelReleases: Array<{
        __typename?: 'EmissionsModelRelease';
        id: string;
        updatedAt: Date;
        userAuthorId: string | null;
        displayName: string;
        businessActivityTypeName: string;
        internalNotes: string;
        externalNotes: string;
        orgId: string | null;
        lifecyclePhase: GQEmReleaseLifecyclePhase;
        isCustomToUserOrg: boolean | null;
        isOrgGated: boolean;
        user: { __typename?: 'User'; id: string; name: string } | null;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        } | null;
        latestCompatibleBatVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
          name: string;
        } | null;
        orgAccess: Array<{
          __typename?: 'EmissionsModelReleaseOrgAccess';
          id: string;
          createdAt: Date;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
            testOrg: boolean;
          };
        }>;
      }>;
    };
  };
};

export type GQUpdateMeasurementTestSuiteMutationVariables = Exact<{
  input: GQUpdateMeasurementTestSuiteInput;
}>;

export type GQUpdateMeasurementTestSuiteMutation = {
  __typename?: 'Mutation';
  updateMeasurementTestSuite: {
    __typename?: 'UpdateMeasurementTestSuitePayload';
    measurementTestSuite: {
      __typename?: 'MeasurementTestSuite';
      description: string;
      createdAt: Date;
      deletedAt: Date | null;
      id: string;
      title: string;
      kind: GQMeasurementTestSuiteKind | null;
      status: string;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
      } | null;
      calculationTags: Array<{
        __typename?: 'CalculationTag';
        id: string;
        name: string;
      }>;
      emissionsModelReleases: Array<{
        __typename?: 'EmissionsModelRelease';
        id: string;
        updatedAt: Date;
        userAuthorId: string | null;
        displayName: string;
        businessActivityTypeName: string;
        internalNotes: string;
        externalNotes: string;
        orgId: string | null;
        lifecyclePhase: GQEmReleaseLifecyclePhase;
        isCustomToUserOrg: boolean | null;
        isOrgGated: boolean;
        user: { __typename?: 'User'; id: string; name: string } | null;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        } | null;
        latestCompatibleBatVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
          name: string;
        } | null;
        orgAccess: Array<{
          __typename?: 'EmissionsModelReleaseOrgAccess';
          id: string;
          createdAt: Date;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
            testOrg: boolean;
          };
        }>;
      }>;
    };
  };
};

export type GQDeleteMeasurementTestSuiteMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQDeleteMeasurementTestSuiteMutation = {
  __typename?: 'Mutation';
  deleteMeasurementTestSuite: {
    __typename?: 'DeleteMeasurementTestSuitePayload';
    id: string;
  };
};

export type GQUndeleteMeasurementTestSuiteMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQUndeleteMeasurementTestSuiteMutation = {
  __typename?: 'Mutation';
  undeleteMeasurementTestSuite: {
    __typename?: 'UndeleteMeasurementTestSuitePayload';
    id: string;
  };
};

export type GQCreateMeasurementTestSuiteBartMutationVariables = Exact<{
  input: GQCreateMeasurementTestSuiteBartInput;
}>;

export type GQCreateMeasurementTestSuiteBartMutation = {
  __typename?: 'Mutation';
  createMeasurementTestSuiteBart: {
    __typename?: 'CreateMeasurementTestSuiteBartPayload';
    measurementTestSuite: {
      __typename?: 'MeasurementTestSuite';
      id: string;
      measurementTestSuiteBarts: Array<{
        __typename?: 'MeasurementTestSuiteBart';
        id: string;
      }>;
    };
  };
};

export type GQUpdateMeasurementTestSuiteBartMutationVariables = Exact<{
  input: GQUpdateMeasurementTestSuiteBartInput;
}>;

export type GQUpdateMeasurementTestSuiteBartMutation = {
  __typename?: 'Mutation';
  updateMeasurementTestSuiteBart: {
    __typename?: 'UpdateMeasurementTestSuiteBartPayload';
    measurementTestSuiteBart: {
      __typename?: 'MeasurementTestSuiteBart';
      id: string;
      title: string;
      activityTypeName: string;
      fieldDefaults: any;
      hiddenFields: Array<string>;
      businessActivityTypeVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      };
    };
  };
};

export type GQToggleMeasurementTestSuiteCriticalMutationVariables = Exact<{
  measurementTestSuiteId: Scalars['ID']['input'];
}>;

export type GQToggleMeasurementTestSuiteCriticalMutation = {
  __typename?: 'Mutation';
  toggleMeasurementTestSuiteCritical: {
    __typename?: 'UpdateMeasurementTestSuitePayload';
    measurementTestSuite: {
      __typename?: 'MeasurementTestSuite';
      id: string;
      kind: GQMeasurementTestSuiteKind | null;
    };
  };
};

export type GQDeleteMeasurementTestSuiteBartMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQDeleteMeasurementTestSuiteBartMutation = {
  __typename?: 'Mutation';
  deleteMeasurementTestSuiteBart: {
    __typename?: 'DeleteMeasurementTestSuiteBartPayload';
    id: string;
  };
};

export type GQAddTestRowsToTestSuiteBartMutationVariables = Exact<{
  input: GQAddTestRowsToTestSuiteBartInput;
}>;

export type GQAddTestRowsToTestSuiteBartMutation = {
  __typename?: 'Mutation';
  addTestRowsToTestSuiteBart: {
    __typename?: 'AddTestRowsToTestSuiteBartPayload';
    measurementTestSuiteBart: {
      __typename?: 'MeasurementTestSuiteBart';
      id: string;
      title: string;
      activityTypeName: string;
      fieldDefaults: any;
      hiddenFields: Array<string>;
      measurementTestRows: Array<{
        __typename?: 'MeasurementTestRow';
        id: string;
        description: string | null;
        bartRowId: number;
        activityRow: any;
      }>;
      businessActivityTypeVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      };
    };
  };
};

export type GQUpdateTestRowsInTestSuiteBartMutationVariables = Exact<{
  input: GQUpdateTestRowsInTestSuiteBartInput;
}>;

export type GQUpdateTestRowsInTestSuiteBartMutation = {
  __typename?: 'Mutation';
  updateTestRowsInTestSuiteBart: {
    __typename?: 'UpdateTestRowsInTestSuiteBartPayload';
    measurementTestSuiteBart: {
      __typename?: 'MeasurementTestSuiteBart';
      id: string;
      title: string;
      activityTypeName: string;
      fieldDefaults: any;
      hiddenFields: Array<string>;
      measurementTestRows: Array<{
        __typename?: 'MeasurementTestRow';
        id: string;
        description: string | null;
        bartRowId: number;
        activityRow: any;
      }>;
      businessActivityTypeVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      };
    };
  };
};

export type GQDeleteMeasurementTestRowsMutationVariables = Exact<{
  input: GQDeleteMeasurementTestRowsInput;
}>;

export type GQDeleteMeasurementTestRowsMutation = {
  __typename?: 'Mutation';
  deleteMeasurementTestRows: {
    __typename?: 'DeleteMeasurementTestRowsPayload';
    ids: Array<string>;
  };
};

export type GQAcceptMethodologyTestSuiteExecutionResultsMutationVariables =
  Exact<{
    input: GQAcceptMethodologyTestSuiteExecutionResultsInput;
  }>;

export type GQAcceptMethodologyTestSuiteExecutionResultsMutation = {
  __typename?: 'Mutation';
  acceptMethodologyTestSuiteExecutionResults: {
    __typename?: 'AcceptMethodologyTestSuiteExecutionResultsPayload';
    measurementTestSuite: {
      __typename?: 'MeasurementTestSuite';
      description: string;
      createdAt: Date;
      deletedAt: Date | null;
      id: string;
      title: string;
      kind: GQMeasurementTestSuiteKind | null;
      status: string;
      latestMethodologyTestSuiteExecution: {
        __typename?: 'MethodologyTestSuiteExecution';
        id: string;
        status: GQMeasurementTestSuiteStageExecutionStatus;
        methodologyTestRowResults: Array<{
          __typename?: 'MethodologyTestRowResult';
          id: string;
          resultAccepted: boolean;
        }>;
      } | null;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
      } | null;
      calculationTags: Array<{
        __typename?: 'CalculationTag';
        id: string;
        name: string;
      }>;
      emissionsModelReleases: Array<{
        __typename?: 'EmissionsModelRelease';
        id: string;
        updatedAt: Date;
        userAuthorId: string | null;
        displayName: string;
        businessActivityTypeName: string;
        internalNotes: string;
        externalNotes: string;
        orgId: string | null;
        lifecyclePhase: GQEmReleaseLifecyclePhase;
        isCustomToUserOrg: boolean | null;
        isOrgGated: boolean;
        user: { __typename?: 'User'; id: string; name: string } | null;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        } | null;
        latestCompatibleBatVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
          name: string;
        } | null;
        orgAccess: Array<{
          __typename?: 'EmissionsModelReleaseOrgAccess';
          id: string;
          createdAt: Date;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
            testOrg: boolean;
          };
        }>;
      }>;
    };
  };
};

export type GQSignalExecuteMeasurementTestSuiteMutationVariables = Exact<{
  input: GQSignalExecuteMeasurementTestSuiteInput;
}>;

export type GQSignalExecuteMeasurementTestSuiteMutation = {
  __typename?: 'Mutation';
  signalExecuteMeasurementTestSuite: {
    __typename?: 'SignalExecuteMeasurementTestSuitePayload';
    id: string;
  };
};

export type GQInvalidateMeasurementTestSuitePagePostRunMutationVariables =
  Exact<{
    input: GQIdInput;
  }>;

export type GQInvalidateMeasurementTestSuitePagePostRunMutation = {
  __typename?: 'Mutation';
  invalidateMeasurementTestSuitePage: {
    __typename?: 'InvalidateMeasurementTestSuitePagePayload';
    measurementTestSuite: {
      __typename?: 'MeasurementTestSuite';
      description: string;
      createdAt: Date;
      deletedAt: Date | null;
      id: string;
      title: string;
      kind: GQMeasurementTestSuiteKind | null;
      status: string;
      latestMethodologyTestSuiteExecution: {
        __typename?: 'MethodologyTestSuiteExecution';
        id: string;
        createdAt: Date;
        status: GQMeasurementTestSuiteStageExecutionStatus;
        methodologyTestRowResults: Array<{
          __typename?: 'MethodologyTestRowResult';
          id: string;
          measurementTestRowId: string;
          frozenExpectation: any;
          result: any;
          diff: any;
          status: GQMeasurementTestRowResultStatus;
          resultAccepted: boolean;
          emissionsModelStable: {
            __typename?: 'EmissionsModelStable';
            id: string;
            title: string;
          };
          measurementTestRow: {
            __typename?: 'MeasurementTestRow';
            id: string;
            bartRowId: number;
            activityRow: any;
          };
        }>;
        author: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        } | null;
      } | null;
      latestFootprintTestSuiteExecution: {
        __typename?: 'FootprintTestSuiteExecution';
        id: string;
        status: GQMeasurementTestSuiteStageExecutionStatus;
        error: string | null;
        warnings: Array<string | null> | null;
        failedStep: GQFootprintAssemblyStep | null;
        runOnTheT: boolean | null;
      } | null;
      latestFootprintTestSuiteExecutionOnTheT: {
        __typename?: 'FootprintTestSuiteExecution';
        id: string;
        status: GQMeasurementTestSuiteStageExecutionStatus;
        error: string | null;
        warnings: Array<string | null> | null;
        failedStep: GQFootprintAssemblyStep | null;
        runOnTheT: boolean | null;
      } | null;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
      } | null;
      calculationTags: Array<{
        __typename?: 'CalculationTag';
        id: string;
        name: string;
      }>;
      emissionsModelReleases: Array<{
        __typename?: 'EmissionsModelRelease';
        id: string;
        updatedAt: Date;
        userAuthorId: string | null;
        displayName: string;
        businessActivityTypeName: string;
        internalNotes: string;
        externalNotes: string;
        orgId: string | null;
        lifecyclePhase: GQEmReleaseLifecyclePhase;
        isCustomToUserOrg: boolean | null;
        isOrgGated: boolean;
        user: { __typename?: 'User'; id: string; name: string } | null;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        } | null;
        latestCompatibleBatVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
          name: string;
        } | null;
        orgAccess: Array<{
          __typename?: 'EmissionsModelReleaseOrgAccess';
          id: string;
          createdAt: Date;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
            testOrg: boolean;
          };
        }>;
      }>;
    };
  };
};

export type GQUpsertFootprintTestSuiteConfigMutationVariables = Exact<{
  input: GQUpsertFootprintTestSuiteConfigInput;
}>;

export type GQUpsertFootprintTestSuiteConfigMutation = {
  __typename?: 'Mutation';
  upsertFootprintTestSuiteConfig: {
    __typename?: 'FootprintTestSuiteConfigPayload';
    measurementTestSuite: {
      __typename?: 'MeasurementTestSuite';
      description: string;
      createdAt: Date;
      deletedAt: Date | null;
      id: string;
      title: string;
      kind: GQMeasurementTestSuiteKind | null;
      status: string;
      footprintTestSuiteConfig: {
        __typename?: 'FootprintTestSuiteConfig';
        id: string;
        pipelineStartDate: YearMonth | null;
        pipelineEndDate: YearMonth | null;
        customTagNames: Array<string> | null;
        marketplaceAllocationInstructionIds: Array<string> | null;
        exclusionRuleIds: Array<string> | null;
        categoryRuleIds: Array<string> | null;
        shouldExclude: boolean;
      } | null;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
      } | null;
      calculationTags: Array<{
        __typename?: 'CalculationTag';
        id: string;
        name: string;
      }>;
      emissionsModelReleases: Array<{
        __typename?: 'EmissionsModelRelease';
        id: string;
        updatedAt: Date;
        userAuthorId: string | null;
        displayName: string;
        businessActivityTypeName: string;
        internalNotes: string;
        externalNotes: string;
        orgId: string | null;
        lifecyclePhase: GQEmReleaseLifecyclePhase;
        isCustomToUserOrg: boolean | null;
        isOrgGated: boolean;
        user: { __typename?: 'User'; id: string; name: string } | null;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        } | null;
        latestCompatibleBatVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
          name: string;
        } | null;
        orgAccess: Array<{
          __typename?: 'EmissionsModelReleaseOrgAccess';
          id: string;
          createdAt: Date;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
            testOrg: boolean;
          };
        }>;
      }>;
    };
  };
};

export type GQDeleteFootprintTestSuiteConfigMutationVariables = Exact<{
  input: GQDeleteFootprintTestSuiteConfigInput;
}>;

export type GQDeleteFootprintTestSuiteConfigMutation = {
  __typename?: 'Mutation';
  deleteFootprintTestSuiteConfig: {
    __typename?: 'DeleteFootprintTestSuiteConfigPayload';
    measurementTestSuite: {
      __typename?: 'MeasurementTestSuite';
      description: string;
      createdAt: Date;
      deletedAt: Date | null;
      id: string;
      title: string;
      kind: GQMeasurementTestSuiteKind | null;
      status: string;
      footprintTestSuiteConfig: {
        __typename?: 'FootprintTestSuiteConfig';
        id: string;
        pipelineStartDate: YearMonth | null;
        pipelineEndDate: YearMonth | null;
        customTagNames: Array<string> | null;
        marketplaceAllocationInstructionIds: Array<string> | null;
        exclusionRuleIds: Array<string> | null;
        categoryRuleIds: Array<string> | null;
        shouldExclude: boolean;
      } | null;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
      } | null;
      calculationTags: Array<{
        __typename?: 'CalculationTag';
        id: string;
        name: string;
      }>;
      emissionsModelReleases: Array<{
        __typename?: 'EmissionsModelRelease';
        id: string;
        updatedAt: Date;
        userAuthorId: string | null;
        displayName: string;
        businessActivityTypeName: string;
        internalNotes: string;
        externalNotes: string;
        orgId: string | null;
        lifecyclePhase: GQEmReleaseLifecyclePhase;
        isCustomToUserOrg: boolean | null;
        isOrgGated: boolean;
        user: { __typename?: 'User'; id: string; name: string } | null;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        } | null;
        latestCompatibleBatVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
          name: string;
        } | null;
        orgAccess: Array<{
          __typename?: 'EmissionsModelReleaseOrgAccess';
          id: string;
          createdAt: Date;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
            testOrg: boolean;
          };
        }>;
      }>;
    };
  };
};

export type GQAcceptFootprintTestSuiteExecutionResultsMutationVariables =
  Exact<{
    input: GQAcceptFootprintTestSuiteExecutionResultsInput;
  }>;

export type GQAcceptFootprintTestSuiteExecutionResultsMutation = {
  __typename?: 'Mutation';
  acceptFootprintTestSuiteExecutionResults: {
    __typename?: 'AcceptFootprintTestSuiteExecutionResultsPayload';
    measurementTestSuite: {
      __typename?: 'MeasurementTestSuite';
      description: string;
      createdAt: Date;
      deletedAt: Date | null;
      id: string;
      title: string;
      kind: GQMeasurementTestSuiteKind | null;
      status: string;
      latestFootprintTestSuiteExecutionOnTheT: {
        __typename?: 'FootprintTestSuiteExecution';
        id: string;
        status: GQMeasurementTestSuiteStageExecutionStatus;
        error: string | null;
        warnings: Array<string | null> | null;
        failedStep: GQFootprintAssemblyStep | null;
        runOnTheT: boolean | null;
      } | null;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
      } | null;
      calculationTags: Array<{
        __typename?: 'CalculationTag';
        id: string;
        name: string;
      }>;
      emissionsModelReleases: Array<{
        __typename?: 'EmissionsModelRelease';
        id: string;
        updatedAt: Date;
        userAuthorId: string | null;
        displayName: string;
        businessActivityTypeName: string;
        internalNotes: string;
        externalNotes: string;
        orgId: string | null;
        lifecyclePhase: GQEmReleaseLifecyclePhase;
        isCustomToUserOrg: boolean | null;
        isOrgGated: boolean;
        user: { __typename?: 'User'; id: string; name: string } | null;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        } | null;
        latestCompatibleBatVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
          name: string;
        } | null;
        orgAccess: Array<{
          __typename?: 'EmissionsModelReleaseOrgAccess';
          id: string;
          createdAt: Date;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
            testOrg: boolean;
          };
        }>;
      }>;
    };
    footprintTestExecutionStepResult: {
      __typename?: 'FootprintTestExecutionStepResult';
      id: string;
      resultRecords: Array<{
        __typename?: 'FootprintTestExecutionStepResultRecord';
        id: string;
        month: string;
        bartRowId: number;
        bartName: string;
        emStableId: string;
        emStableTitle: string;
        emVariableName: string;
        rowSplitKey: string | null;
        ghgCategoryId: string | null;
        categoryId: string | null;
        subcategoryId: string | null;
        description: string | null;
        quantityKgCo2e: number | null;
        allocationLocationQuantityKgCo2E: number | null;
        fullDiff: any;
        status: GQFootprintTestExecutionStepStatus;
        inActual: boolean | null;
        inExpected: boolean | null;
        resultAccepted: boolean | null;
      }>;
    };
  };
};

export type GQImportMeasurementTestSuiteMutationVariables = Exact<{
  input: GQImportMeasurementTestSuiteInput;
}>;

export type GQImportMeasurementTestSuiteMutation = {
  __typename?: 'Mutation';
  importMeasurementTestSuite: {
    __typename?: 'ImportMeasurementTestSuitePayload';
    id: string;
  };
};

export type GQExportMeasurementTestSuiteMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQExportMeasurementTestSuiteMutation = {
  __typename?: 'Mutation';
  exportMeasurementTestSuite: {
    __typename?: 'ExportMeasurementTestSuitePayload';
    downloadUrl: string;
  };
};

export type GQExportMeasurementTestSuiteBundleMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQExportMeasurementTestSuiteBundleMutation = {
  __typename?: 'Mutation';
  exportMeasurementTestSuiteBundle: {
    __typename?: 'ExportMeasurementTestSuiteBundlePayload';
    downloadUrl: string;
  };
};

export type GQDuplicateMeasurementTestSuiteMutationVariables = Exact<{
  input: GQDuplicateMeasurementTestSuiteInput;
}>;

export type GQDuplicateMeasurementTestSuiteMutation = {
  __typename?: 'Mutation';
  duplicateMeasurementTestSuite: {
    __typename?: 'DuplicateMeasurementTestSuitePayload';
    id: string;
  };
};

export type GQSwapEmissionsModelExpectationsForMeasurementTestRowsMutationVariables =
  Exact<{
    input: GQSwapEmissionsModelExpectationsForMeasurementTestRowsInput;
  }>;

export type GQSwapEmissionsModelExpectationsForMeasurementTestRowsMutation = {
  __typename?: 'Mutation';
  swapEmissionsModelExpectationsForTestRows: {
    __typename?: 'SwapEmissionsModelExpectationsForMeasurementTestRowsPayload';
    ids: Array<string>;
  };
};

export type GQSwapEmissionsModelExpectationsForFootprintTestRowsMutationVariables =
  Exact<{
    input: GQSwapEmissionsModelExpectationsForFootprintTestRowsInput;
  }>;

export type GQSwapEmissionsModelExpectationsForFootprintTestRowsMutation = {
  __typename?: 'Mutation';
  swapEmissionsModelExpectationsForFootprintTestRows: {
    __typename?: 'SwapEmissionsModelExpectationsForFootprintTestRowsPayload';
    ids: Array<string>;
  };
};

export type GQMeasurementTestSuiteMetadataLiteFieldsFragment = {
  __typename?: 'MeasurementTestSuite';
  id: string;
  title: string;
  kind: GQMeasurementTestSuiteKind | null;
  status: string;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
  } | null;
  calculationTags: Array<{
    __typename?: 'CalculationTag';
    id: string;
    name: string;
  }>;
  emissionsModelReleases: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    updatedAt: Date;
    userAuthorId: string | null;
    displayName: string;
    businessActivityTypeName: string;
    internalNotes: string;
    externalNotes: string;
    orgId: string | null;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    isCustomToUserOrg: boolean | null;
    isOrgGated: boolean;
    user: { __typename?: 'User'; id: string; name: string } | null;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    } | null;
    latestCompatibleBatVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    orgAccess: Array<{
      __typename?: 'EmissionsModelReleaseOrgAccess';
      id: string;
      createdAt: Date;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    }>;
  }>;
};

export type GQMeasurementTestSuiteMetadataFieldsFragment = {
  __typename?: 'MeasurementTestSuite';
  description: string;
  createdAt: Date;
  deletedAt: Date | null;
  id: string;
  title: string;
  kind: GQMeasurementTestSuiteKind | null;
  status: string;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
  } | null;
  calculationTags: Array<{
    __typename?: 'CalculationTag';
    id: string;
    name: string;
  }>;
  emissionsModelReleases: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    updatedAt: Date;
    userAuthorId: string | null;
    displayName: string;
    businessActivityTypeName: string;
    internalNotes: string;
    externalNotes: string;
    orgId: string | null;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    isCustomToUserOrg: boolean | null;
    isOrgGated: boolean;
    user: { __typename?: 'User'; id: string; name: string } | null;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    } | null;
    latestCompatibleBatVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    orgAccess: Array<{
      __typename?: 'EmissionsModelReleaseOrgAccess';
      id: string;
      createdAt: Date;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    }>;
  }>;
};

export type GQMeasurementTestSuiteListFieldsFragment = {
  __typename?: 'MeasurementTestSuite';
  description: string;
  createdAt: Date;
  deletedAt: Date | null;
  id: string;
  title: string;
  kind: GQMeasurementTestSuiteKind | null;
  status: string;
  measurementTestSuiteBarts: Array<{
    __typename?: 'MeasurementTestSuiteBart';
    measurementTestRowCount: number;
    id: string;
    title: string;
    activityTypeName: string;
    fieldDefaults: any;
    hiddenFields: Array<string>;
    businessActivityTypeVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    };
  }>;
  latestMethodologyTestSuiteExecution: {
    __typename?: 'MethodologyTestSuiteExecution';
    id: string;
    status: GQMeasurementTestSuiteStageExecutionStatus;
  } | null;
  latestFootprintTestSuiteExecution: {
    __typename?: 'FootprintTestSuiteExecution';
    id: string;
    status: GQMeasurementTestSuiteStageExecutionStatus;
  } | null;
  latestFootprintTestSuiteExecutionOnTheT: {
    __typename?: 'FootprintTestSuiteExecution';
    id: string;
    status: GQMeasurementTestSuiteStageExecutionStatus;
  } | null;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
  } | null;
  calculationTags: Array<{
    __typename?: 'CalculationTag';
    id: string;
    name: string;
  }>;
  emissionsModelReleases: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    updatedAt: Date;
    userAuthorId: string | null;
    displayName: string;
    businessActivityTypeName: string;
    internalNotes: string;
    externalNotes: string;
    orgId: string | null;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    isCustomToUserOrg: boolean | null;
    isOrgGated: boolean;
    user: { __typename?: 'User'; id: string; name: string } | null;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    } | null;
    latestCompatibleBatVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    orgAccess: Array<{
      __typename?: 'EmissionsModelReleaseOrgAccess';
      id: string;
      createdAt: Date;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    }>;
  }>;
};

export type GQMeasurementTestSuiteLiteListFieldsFragment = {
  __typename?: 'MeasurementTestSuite';
  id: string;
  title: string;
  kind: GQMeasurementTestSuiteKind | null;
  status: string;
  measurementTestSuiteBarts: Array<{
    __typename?: 'MeasurementTestSuiteBart';
    id: string;
    activityTypeName: string;
  }>;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
  } | null;
  calculationTags: Array<{
    __typename?: 'CalculationTag';
    id: string;
    name: string;
  }>;
  emissionsModelReleases: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    updatedAt: Date;
    userAuthorId: string | null;
    displayName: string;
    businessActivityTypeName: string;
    internalNotes: string;
    externalNotes: string;
    orgId: string | null;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    isCustomToUserOrg: boolean | null;
    isOrgGated: boolean;
    user: { __typename?: 'User'; id: string; name: string } | null;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    } | null;
    latestCompatibleBatVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    orgAccess: Array<{
      __typename?: 'EmissionsModelReleaseOrgAccess';
      id: string;
      createdAt: Date;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    }>;
  }>;
};

export type GQMethodologyTestSuiteExecutionMetadataFieldsFragment = {
  __typename?: 'MethodologyTestSuiteExecution';
  id: string;
  createdAt: Date;
  status: GQMeasurementTestSuiteStageExecutionStatus;
  author: { __typename?: 'WatershedEmployee'; id: string; name: string } | null;
};

export type GQMeasurementTestRowFieldsFragment = {
  __typename?: 'MeasurementTestRow';
  id: string;
  description: string | null;
  bartRowId: number;
  activityRow: any;
};

export type GQMethodologyTestRowResultFieldsFragment = {
  __typename?: 'MethodologyTestRowResult';
  id: string;
  measurementTestRowId: string;
  frozenExpectation: any;
  result: any;
  diff: any;
  status: GQMeasurementTestRowResultStatus;
  resultAccepted: boolean;
  emissionsModelStable: {
    __typename?: 'EmissionsModelStable';
    id: string;
    title: string;
  };
  measurementTestRow: {
    __typename?: 'MeasurementTestRow';
    id: string;
    bartRowId: number;
    activityRow: any;
  };
};

export type GQMethodologyTestSuiteExecutionWithResultsFieldsFragment = {
  __typename?: 'MethodologyTestSuiteExecution';
  id: string;
  createdAt: Date;
  status: GQMeasurementTestSuiteStageExecutionStatus;
  methodologyTestRowResults: Array<{
    __typename?: 'MethodologyTestRowResult';
    id: string;
    measurementTestRowId: string;
    frozenExpectation: any;
    result: any;
    diff: any;
    status: GQMeasurementTestRowResultStatus;
    resultAccepted: boolean;
    emissionsModelStable: {
      __typename?: 'EmissionsModelStable';
      id: string;
      title: string;
    };
    measurementTestRow: {
      __typename?: 'MeasurementTestRow';
      id: string;
      bartRowId: number;
      activityRow: any;
    };
  }>;
  author: { __typename?: 'WatershedEmployee'; id: string; name: string } | null;
};

export type GQFootprintTestExecutionMetadataFieldsFragment = {
  __typename?: 'FootprintTestSuiteExecution';
  id: string;
  status: GQMeasurementTestSuiteStageExecutionStatus;
  error: string | null;
  warnings: Array<string | null> | null;
  failedStep: GQFootprintAssemblyStep | null;
  runOnTheT: boolean | null;
};

export type GQFootprintTestSuiteConfigFieldsFragment = {
  __typename?: 'FootprintTestSuiteConfig';
  id: string;
  pipelineStartDate: YearMonth | null;
  pipelineEndDate: YearMonth | null;
  customTagNames: Array<string> | null;
  marketplaceAllocationInstructionIds: Array<string> | null;
  exclusionRuleIds: Array<string> | null;
  categoryRuleIds: Array<string> | null;
  shouldExclude: boolean;
};

export type GQFootprintTestExecutionStepResultFieldsFragment = {
  __typename?: 'FootprintTestExecutionStepResult';
  id: string;
  resultRecords: Array<{
    __typename?: 'FootprintTestExecutionStepResultRecord';
    id: string;
    month: string;
    bartRowId: number;
    bartName: string;
    emStableId: string;
    emStableTitle: string;
    emVariableName: string;
    rowSplitKey: string | null;
    ghgCategoryId: string | null;
    categoryId: string | null;
    subcategoryId: string | null;
    description: string | null;
    quantityKgCo2e: number | null;
    allocationLocationQuantityKgCo2E: number | null;
    fullDiff: any;
    status: GQFootprintTestExecutionStepStatus;
    inActual: boolean | null;
    inExpected: boolean | null;
    resultAccepted: boolean | null;
  }>;
};

export type GQMeasurementTestSuiteBartMetadataFieldsFragment = {
  __typename?: 'MeasurementTestSuiteBart';
  id: string;
  title: string;
  activityTypeName: string;
  fieldDefaults: any;
  hiddenFields: Array<string>;
  businessActivityTypeVersion: {
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
  };
};

export type GQMeasurementTestSuiteChangelogFieldsFragment = {
  __typename?: 'MeasurementTestSuiteChangelog';
  id: string;
  changelog: string;
  createdAt: Date;
  author: { __typename?: 'User'; id: string; name: string } | null;
  measurementResourceEvent: {
    __typename?: 'MeasurementResourceEvent';
    id: string;
    createdAt: Date;
    eventKind: string;
    eventData: any;
    author: { __typename?: 'User'; id: string; name: string } | null;
  } | null;
};

export type GQDefaultCalculationTagsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQDefaultCalculationTagsQuery = {
  __typename?: 'Query';
  defaultCalculationTags: Array<{
    __typename?: 'CalculationTag';
    id: string;
    name: string;
  }>;
};

export type GQAvailableActivityTypeNamesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQAvailableActivityTypeNamesQuery = {
  __typename?: 'Query';
  activityTypes: Array<{
    __typename?: 'BusinessActivityType';
    id: string;
    activityTypeName: string;
  }>;
};

export type GQMeasurementTestSuiteListQueryVariables = Exact<{
  includeDeleted: Scalars['Boolean']['input'];
}>;

export type GQMeasurementTestSuiteListQuery = {
  __typename?: 'Query';
  measurementTestSuites: Array<{
    __typename?: 'MeasurementTestSuite';
    description: string;
    createdAt: Date;
    deletedAt: Date | null;
    id: string;
    title: string;
    kind: GQMeasurementTestSuiteKind | null;
    status: string;
    measurementTestSuiteBarts: Array<{
      __typename?: 'MeasurementTestSuiteBart';
      measurementTestRowCount: number;
      id: string;
      title: string;
      activityTypeName: string;
      fieldDefaults: any;
      hiddenFields: Array<string>;
      businessActivityTypeVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      };
    }>;
    latestMethodologyTestSuiteExecution: {
      __typename?: 'MethodologyTestSuiteExecution';
      id: string;
      status: GQMeasurementTestSuiteStageExecutionStatus;
    } | null;
    latestFootprintTestSuiteExecution: {
      __typename?: 'FootprintTestSuiteExecution';
      id: string;
      status: GQMeasurementTestSuiteStageExecutionStatus;
    } | null;
    latestFootprintTestSuiteExecutionOnTheT: {
      __typename?: 'FootprintTestSuiteExecution';
      id: string;
      status: GQMeasurementTestSuiteStageExecutionStatus;
    } | null;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
    } | null;
    calculationTags: Array<{
      __typename?: 'CalculationTag';
      id: string;
      name: string;
    }>;
    emissionsModelReleases: Array<{
      __typename?: 'EmissionsModelRelease';
      id: string;
      updatedAt: Date;
      userAuthorId: string | null;
      displayName: string;
      businessActivityTypeName: string;
      internalNotes: string;
      externalNotes: string;
      orgId: string | null;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      isCustomToUserOrg: boolean | null;
      isOrgGated: boolean;
      user: { __typename?: 'User'; id: string; name: string } | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      } | null;
      latestCompatibleBatVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
      orgAccess: Array<{
        __typename?: 'EmissionsModelReleaseOrgAccess';
        id: string;
        createdAt: Date;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        };
      }>;
    }>;
  }>;
};

export type GQMeasurementTestSuiteDetailsWithoutExecutionsFragment = {
  __typename?: 'MeasurementTestSuite';
  description: string;
  createdAt: Date;
  deletedAt: Date | null;
  id: string;
  title: string;
  kind: GQMeasurementTestSuiteKind | null;
  status: string;
  latestChangelogEntry: {
    __typename?: 'MeasurementTestSuiteChangelog';
    id: string;
    changelog: string;
    createdAt: Date;
    author: { __typename?: 'User'; id: string; name: string } | null;
    measurementResourceEvent: {
      __typename?: 'MeasurementResourceEvent';
      id: string;
      createdAt: Date;
      eventKind: string;
      eventData: any;
      author: { __typename?: 'User'; id: string; name: string } | null;
    } | null;
  };
  measurementTestSuiteBarts: Array<{
    __typename?: 'MeasurementTestSuiteBart';
    id: string;
    title: string;
    activityTypeName: string;
    fieldDefaults: any;
    hiddenFields: Array<string>;
    measurementTestRows: Array<{
      __typename?: 'MeasurementTestRow';
      id: string;
      description: string | null;
      bartRowId: number;
      activityRow: any;
    }>;
    activityTypeFields: Array<{
      __typename?: 'BusinessActivityTypeField';
      id: string;
      description: string;
      fieldName: string;
      fieldType: GQBusinessActivityTypeFieldType;
      fieldValueOneOf: any | null;
      isRequired: boolean;
    }>;
    businessActivityTypeVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    };
  }>;
  footprintTestSuiteConfig: {
    __typename?: 'FootprintTestSuiteConfig';
    id: string;
    pipelineStartDate: YearMonth | null;
    pipelineEndDate: YearMonth | null;
    customTagNames: Array<string> | null;
    marketplaceAllocationInstructionIds: Array<string> | null;
    exclusionRuleIds: Array<string> | null;
    categoryRuleIds: Array<string> | null;
    shouldExclude: boolean;
  } | null;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
  } | null;
  calculationTags: Array<{
    __typename?: 'CalculationTag';
    id: string;
    name: string;
  }>;
  emissionsModelReleases: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    updatedAt: Date;
    userAuthorId: string | null;
    displayName: string;
    businessActivityTypeName: string;
    internalNotes: string;
    externalNotes: string;
    orgId: string | null;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    isCustomToUserOrg: boolean | null;
    isOrgGated: boolean;
    user: { __typename?: 'User'; id: string; name: string } | null;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
      testOrg: boolean;
    } | null;
    latestCompatibleBatVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    orgAccess: Array<{
      __typename?: 'EmissionsModelReleaseOrgAccess';
      id: string;
      createdAt: Date;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    }>;
  }>;
};

export type GQMeasurementTestSuiteDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMeasurementTestSuiteDetailQuery = {
  __typename?: 'Query';
  measurementTestSuite: {
    __typename?: 'MeasurementTestSuite';
    description: string;
    createdAt: Date;
    deletedAt: Date | null;
    id: string;
    title: string;
    kind: GQMeasurementTestSuiteKind | null;
    status: string;
    latestMethodologyTestSuiteExecution: {
      __typename?: 'MethodologyTestSuiteExecution';
      id: string;
      createdAt: Date;
      status: GQMeasurementTestSuiteStageExecutionStatus;
      author: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
    } | null;
    latestFootprintTestSuiteExecution: {
      __typename?: 'FootprintTestSuiteExecution';
      id: string;
      status: GQMeasurementTestSuiteStageExecutionStatus;
      error: string | null;
      warnings: Array<string | null> | null;
      failedStep: GQFootprintAssemblyStep | null;
      runOnTheT: boolean | null;
    } | null;
    latestFootprintTestSuiteExecutionOnTheT: {
      __typename?: 'FootprintTestSuiteExecution';
      id: string;
      status: GQMeasurementTestSuiteStageExecutionStatus;
      error: string | null;
      warnings: Array<string | null> | null;
      failedStep: GQFootprintAssemblyStep | null;
      runOnTheT: boolean | null;
    } | null;
    latestChangelogEntry: {
      __typename?: 'MeasurementTestSuiteChangelog';
      id: string;
      changelog: string;
      createdAt: Date;
      author: { __typename?: 'User'; id: string; name: string } | null;
      measurementResourceEvent: {
        __typename?: 'MeasurementResourceEvent';
        id: string;
        createdAt: Date;
        eventKind: string;
        eventData: any;
        author: { __typename?: 'User'; id: string; name: string } | null;
      } | null;
    };
    measurementTestSuiteBarts: Array<{
      __typename?: 'MeasurementTestSuiteBart';
      id: string;
      title: string;
      activityTypeName: string;
      fieldDefaults: any;
      hiddenFields: Array<string>;
      measurementTestRows: Array<{
        __typename?: 'MeasurementTestRow';
        id: string;
        description: string | null;
        bartRowId: number;
        activityRow: any;
      }>;
      activityTypeFields: Array<{
        __typename?: 'BusinessActivityTypeField';
        id: string;
        description: string;
        fieldName: string;
        fieldType: GQBusinessActivityTypeFieldType;
        fieldValueOneOf: any | null;
        isRequired: boolean;
      }>;
      businessActivityTypeVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      };
    }>;
    footprintTestSuiteConfig: {
      __typename?: 'FootprintTestSuiteConfig';
      id: string;
      pipelineStartDate: YearMonth | null;
      pipelineEndDate: YearMonth | null;
      customTagNames: Array<string> | null;
      marketplaceAllocationInstructionIds: Array<string> | null;
      exclusionRuleIds: Array<string> | null;
      categoryRuleIds: Array<string> | null;
      shouldExclude: boolean;
    } | null;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
    } | null;
    calculationTags: Array<{
      __typename?: 'CalculationTag';
      id: string;
      name: string;
    }>;
    emissionsModelReleases: Array<{
      __typename?: 'EmissionsModelRelease';
      id: string;
      updatedAt: Date;
      userAuthorId: string | null;
      displayName: string;
      businessActivityTypeName: string;
      internalNotes: string;
      externalNotes: string;
      orgId: string | null;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      isCustomToUserOrg: boolean | null;
      isOrgGated: boolean;
      user: { __typename?: 'User'; id: string; name: string } | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      } | null;
      latestCompatibleBatVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
      orgAccess: Array<{
        __typename?: 'EmissionsModelReleaseOrgAccess';
        id: string;
        createdAt: Date;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        };
      }>;
    }>;
  };
};

export type GQMethodologyTestSuiteExecutionDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMethodologyTestSuiteExecutionDetailQuery = {
  __typename?: 'Query';
  methodologyTestSuiteExecution: {
    __typename?: 'MethodologyTestSuiteExecution';
    id: string;
    createdAt: Date;
    status: GQMeasurementTestSuiteStageExecutionStatus;
    methodologyTestRowResults: Array<{
      __typename?: 'MethodologyTestRowResult';
      id: string;
      measurementTestRowId: string;
      frozenExpectation: any;
      result: any;
      diff: any;
      status: GQMeasurementTestRowResultStatus;
      resultAccepted: boolean;
      emissionsModelStable: {
        __typename?: 'EmissionsModelStable';
        id: string;
        title: string;
      };
      measurementTestRow: {
        __typename?: 'MeasurementTestRow';
        id: string;
        bartRowId: number;
        activityRow: any;
      };
    }>;
    author: {
      __typename?: 'WatershedEmployee';
      id: string;
      name: string;
    } | null;
  };
};

export type GQMeasurementTestSuiteChangelogQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  before: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQMeasurementTestSuiteChangelogQuery = {
  __typename?: 'Query';
  measurementTestSuiteChangelog: {
    __typename?: 'MeasurementTestSuiteChangelogConnection';
    edges: Array<{
      __typename?: 'MeasurementTestSuiteChangelogEdge';
      node: {
        __typename?: 'MeasurementTestSuiteChangelog';
        id: string;
        changelog: string;
        createdAt: Date;
        author: { __typename?: 'User'; id: string; name: string } | null;
        measurementResourceEvent: {
          __typename?: 'MeasurementResourceEvent';
          id: string;
          createdAt: Date;
          eventKind: string;
          eventData: any;
          author: { __typename?: 'User'; id: string; name: string } | null;
        } | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type GQMeasurementTestSuiteStatusQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMeasurementTestSuiteStatusQuery = {
  __typename?: 'Query';
  measurementTestSuite: {
    __typename?: 'MeasurementTestSuite';
    id: string;
    status: string;
    latestChangelogEntry: {
      __typename?: 'MeasurementTestSuiteChangelog';
      id: string;
      changelog: string;
      createdAt: Date;
      author: { __typename?: 'User'; id: string; name: string } | null;
      measurementResourceEvent: {
        __typename?: 'MeasurementResourceEvent';
        id: string;
        createdAt: Date;
        eventKind: string;
        eventData: any;
        author: { __typename?: 'User'; id: string; name: string } | null;
      } | null;
    };
    latestMethodologyTestSuiteExecution: {
      __typename?: 'MethodologyTestSuiteExecution';
      id: string;
      status: GQMeasurementTestSuiteStageExecutionStatus;
    } | null;
    latestFootprintTestSuiteExecution: {
      __typename?: 'FootprintTestSuiteExecution';
      id: string;
      status: GQMeasurementTestSuiteStageExecutionStatus;
    } | null;
    latestFootprintTestSuiteExecutionOnTheT: {
      __typename?: 'FootprintTestSuiteExecution';
      id: string;
      status: GQMeasurementTestSuiteStageExecutionStatus;
    } | null;
  };
};

export type GQFootprintTestSuiteConfigFieldOptionsQueryVariables = Exact<{
  orgId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQFootprintTestSuiteConfigFieldOptionsQuery = {
  __typename?: 'Query';
  footprintTestSuiteFootprintTags: Array<{
    __typename?: 'FootprintTag';
    id: string;
    tagName: string;
  }> | null;
  footprintTestSuiteMarketplaceAllocInstrs: Array<{
    __typename?: 'MarketplaceAllocationInstruction';
    id: string;
  }> | null;
  footprintTestSuiteExclusionRules: Array<{
    __typename?: 'FootprintExclusionRule';
    id: string;
  }> | null;
  footprintTestSuiteCategoryRules: Array<{
    __typename?: 'FootprintCategoryRule';
    id: string;
  }> | null;
};

export type GQFootprintTestSuiteExecutionQueryVariables = Exact<{
  footprintTestSuiteExecutionId: Scalars['ID']['input'];
  footprintAssemblyStep: GQFootprintAssemblyStep;
}>;

export type GQFootprintTestSuiteExecutionQuery = {
  __typename?: 'Query';
  footprintTestSuiteExecutionStepResult: {
    __typename?: 'FootprintTestExecutionStepResult';
    id: string;
    resultRecords: Array<{
      __typename?: 'FootprintTestExecutionStepResultRecord';
      id: string;
      month: string;
      bartRowId: number;
      bartName: string;
      emStableId: string;
      emStableTitle: string;
      emVariableName: string;
      rowSplitKey: string | null;
      ghgCategoryId: string | null;
      categoryId: string | null;
      subcategoryId: string | null;
      description: string | null;
      quantityKgCo2e: number | null;
      allocationLocationQuantityKgCo2E: number | null;
      fullDiff: any;
      status: GQFootprintTestExecutionStepStatus;
      inActual: boolean | null;
      inExpected: boolean | null;
      resultAccepted: boolean | null;
    }>;
  };
};

export type GQGetRefDataMetadataForMethodologyTreeQueryVariables = Exact<{
  versionId: Scalars['ID']['input'];
}>;

export type GQGetRefDataMetadataForMethodologyTreeQuery = {
  __typename?: 'Query';
  referenceDataVersion: {
    __typename?: 'ReferenceDataVersion';
    id: string;
    versionName: string;
    latestPublishedRevisionByVersionId: {
      __typename?: 'ReferenceDataRevision';
      id: string;
      kind: GQReferenceDataRevisionKind | null;
      signedParquetOutputUrl: string;
      outputFileId: string;
    } | null;
    latestReferenceDataSqlRun: {
      __typename?: 'ReferenceDataSql';
      id: string;
      sql: string;
      status: GQReferenceDataSqlStatus | null;
    } | null;
    latestSchema: {
      __typename?: 'ReferenceDataSchema';
      id: string;
      schemaJson: JSONSchema7;
    } | null;
    source: { __typename?: 'ReferenceDataSource'; id: string; name: string };
  };
};

export type GQGetBusinessActivityTypeMetadataForMethodologyTreeQueryVariables =
  Exact<{
    versionId: Scalars['ID']['input'];
  }>;

export type GQGetBusinessActivityTypeMetadataForMethodologyTreeQuery = {
  __typename?: 'Query';
  businessActivityTypeVersionById: {
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
  };
};

export type GQGetModelMetadataForMethodologyTreeQueryVariables = Exact<{
  versionId: Scalars['ID']['input'];
}>;

export type GQGetModelMetadataForMethodologyTreeQuery = {
  __typename?: 'Query';
  emissionsModelVersion: {
    __typename?: 'EmissionsModelVersion';
    id: string;
    stableId: string;
  };
};

export type GQGetReleaseMetadataForMethodologyTreeQueryVariables = Exact<{
  releaseId: Scalars['ID']['input'];
}>;

export type GQGetReleaseMetadataForMethodologyTreeQuery = {
  __typename?: 'Query';
  emissionsModelRelease: {
    __typename?: 'EmissionsModelRelease';
    id: string;
    displayName: string;
    orgId: string | null;
    internalNotes: string;
    externalNotes: string;
    businessActivityTypeName: string;
    isOrgGated: boolean;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    emissionsModels: Array<{
      __typename?: 'EmissionsModelStable';
      id: string;
      title: string;
    }>;
  };
};

export type GQGetCtsMetadataForMethodologyTreeQueryVariables = Exact<{
  versionId: Scalars['ID']['input'];
}>;

export type GQGetCtsMetadataForMethodologyTreeQuery = {
  __typename?: 'Query';
  rawCustomerTargetSchemaVersion: {
    __typename?: 'CustomerTargetSchemaVersion';
    id: string;
    schemaJson: any;
    exampleData: any;
    transforms: Array<{
      __typename?: 'CustomerTargetSchemaTransform';
      id: string;
      sql: string;
      businessActivityTypeName: string | null;
      businessActivityTypeVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
    }>;
    schema: {
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      descriptionMd: string | null;
      utilityType: GQBuildingUtilityType | null;
      rank: number;
      org: { __typename?: 'Organization'; id: string; name: string } | null;
    };
  };
};

export type GQGetDatasetMetadataForMethodologyTreeQueryVariables = Exact<{
  datasetId: Scalars['ID']['input'];
}>;

export type GQGetDatasetMetadataForMethodologyTreeQuery = {
  __typename?: 'Query';
  canonicalDataset: {
    __typename?: 'CanonicalDataset';
    id: string;
    name: string;
    description: string | null;
    kind: GQCanonicalDatasetKind;
  };
};

export type GQVersionFieldsForGlobalEmissionsModelTreeFragment = {
  __typename?: 'EmissionsModelVersion';
  id: string;
  title: string;
  emissionsModelJson: any;
  referenceDataVersions: Array<{
    __typename?: 'ReferenceDataVersion';
    id: string;
    slug: string;
    citations: Array<{
      __typename?: 'ReferenceDataCitation';
      id: string;
      slug: string;
    }>;
  }>;
};

export type GQStableFieldsForGlobalEmissionsModelTreeFragment = {
  __typename?: 'EmissionsModelStable';
  id: string;
  latestPublishedVersion: {
    __typename?: 'EmissionsModelVersion';
    id: string;
    title: string;
    emissionsModelJson: any;
    importedDescendants: Array<{
      __typename?: 'EmissionsModelVersion';
      id: string;
      title: string;
      emissionsModelJson: any;
      referenceDataVersions: Array<{
        __typename?: 'ReferenceDataVersion';
        id: string;
        slug: string;
        citations: Array<{
          __typename?: 'ReferenceDataCitation';
          id: string;
          slug: string;
        }>;
      }>;
    }>;
    referenceDataVersions: Array<{
      __typename?: 'ReferenceDataVersion';
      id: string;
      slug: string;
      citations: Array<{
        __typename?: 'ReferenceDataCitation';
        id: string;
        slug: string;
      }>;
    }>;
  } | null;
  matchers: Array<{
    __typename?: 'EmissionsModelMatcher';
    id: string;
    kind: GQEmissionsModelMatcherKind;
    emissionsModelStableId: string;
    businessActivityType: string;
    businessActivityTypeVersionId: string;
    filterExpression: string | null;
    quantitySpecifier: string | null;
    bindings: any;
    keepBatVersionAtLatest: boolean;
  }>;
  releases: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    businessActivityTypeName: string;
    displayName: string;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    latestCompatibleBatVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
    } | null;
  }>;
};

export type GQGetGlobalEmissionsModelTreeFromReleasesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetGlobalEmissionsModelTreeFromReleasesQuery = {
  __typename?: 'Query';
  emissionsModelReleases: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    emissionsModels: Array<{
      __typename?: 'EmissionsModelStable';
      id: string;
      latestPublishedVersion: {
        __typename?: 'EmissionsModelVersion';
        id: string;
        title: string;
        emissionsModelJson: any;
        importedDescendants: Array<{
          __typename?: 'EmissionsModelVersion';
          id: string;
          title: string;
          emissionsModelJson: any;
          referenceDataVersions: Array<{
            __typename?: 'ReferenceDataVersion';
            id: string;
            slug: string;
            citations: Array<{
              __typename?: 'ReferenceDataCitation';
              id: string;
              slug: string;
            }>;
          }>;
        }>;
        referenceDataVersions: Array<{
          __typename?: 'ReferenceDataVersion';
          id: string;
          slug: string;
          citations: Array<{
            __typename?: 'ReferenceDataCitation';
            id: string;
            slug: string;
          }>;
        }>;
      } | null;
      matchers: Array<{
        __typename?: 'EmissionsModelMatcher';
        id: string;
        kind: GQEmissionsModelMatcherKind;
        emissionsModelStableId: string;
        businessActivityType: string;
        businessActivityTypeVersionId: string;
        filterExpression: string | null;
        quantitySpecifier: string | null;
        bindings: any;
        keepBatVersionAtLatest: boolean;
      }>;
      releases: Array<{
        __typename?: 'EmissionsModelRelease';
        id: string;
        businessActivityTypeName: string;
        displayName: string;
        lifecyclePhase: GQEmReleaseLifecyclePhase;
        latestCompatibleBatVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
        } | null;
      }>;
    }>;
  }>;
};

export type GQGetEmissionsModelTreeQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQGetEmissionsModelTreeQuery = {
  __typename?: 'Query';
  stableModels: Array<{
    __typename?: 'EmissionsModelStable';
    id: string;
    latestPublishedVersion: {
      __typename?: 'EmissionsModelVersion';
      id: string;
      title: string;
      emissionsModelJson: any;
      importedDescendants: Array<{
        __typename?: 'EmissionsModelVersion';
        id: string;
        title: string;
        emissionsModelJson: any;
        referenceDataVersions: Array<{
          __typename?: 'ReferenceDataVersion';
          id: string;
          slug: string;
          citations: Array<{
            __typename?: 'ReferenceDataCitation';
            id: string;
            slug: string;
          }>;
        }>;
      }>;
      referenceDataVersions: Array<{
        __typename?: 'ReferenceDataVersion';
        id: string;
        slug: string;
        citations: Array<{
          __typename?: 'ReferenceDataCitation';
          id: string;
          slug: string;
        }>;
      }>;
    } | null;
    matchers: Array<{
      __typename?: 'EmissionsModelMatcher';
      id: string;
      kind: GQEmissionsModelMatcherKind;
      emissionsModelStableId: string;
      businessActivityType: string;
      businessActivityTypeVersionId: string;
      filterExpression: string | null;
      quantitySpecifier: string | null;
      bindings: any;
      keepBatVersionAtLatest: boolean;
    }>;
    releases: Array<{
      __typename?: 'EmissionsModelRelease';
      id: string;
      businessActivityTypeName: string;
      displayName: string;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      latestCompatibleBatVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
      } | null;
    }>;
  }>;
};

export type GQGetReferenceDataAncestryTreeQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQGetReferenceDataAncestryTreeQuery = {
  __typename?: 'Query';
  ancestorRelationships: Array<{
    __typename?: 'ReferenceDataAncestorRelationship';
    versionId: string;
    versionSlug: string;
    upstreamVersionId: string;
    upstreamVersionSlug: string;
  }>;
};

export type GQGetCtsMethodologyTreeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetCtsMethodologyTreeQuery = {
  __typename?: 'Query';
  customerTargetSchemas: Array<{
    __typename?: 'CustomerTargetSchema';
    id: string;
    name: string;
    latestPublishedVersion: {
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      transforms: Array<{
        __typename?: 'CustomerTargetSchemaTransform';
        id: string;
        businessActivityTypeName: string | null;
        businessActivityTypeVersionId: string | null;
      }>;
    } | null;
    canonicalDatasets: Array<{
      __typename?: 'CanonicalDataset';
      id: string;
      name: string;
    }>;
  }>;
};

export type GQSchemaRegistrySchemaSelectorDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQSchemaRegistrySchemaSelectorDataQuery = {
  __typename?: 'Query';
  schemaRegistrySchemas: {
    __typename?: 'SchemaRegistrySchemaConnection';
    edges: Array<{
      __typename?: 'SchemaRegistrySchemaEdge';
      node: {
        __typename?: 'SchemaRegistrySchema';
        id: string;
        name: string;
      } | null;
    } | null>;
  } | null;
};

export type GQSchemaRegistrySchemaVersionSelectorDataQueryVariables = Exact<{
  schemaId: Scalars['ID']['input'];
}>;

export type GQSchemaRegistrySchemaVersionSelectorDataQuery = {
  __typename?: 'Query';
  schemaRegistrySchemaVersions: {
    __typename?: 'SchemaRegistrySchemaVersionConnection';
    edges: Array<{
      __typename?: 'SchemaRegistrySchemaVersionEdge';
      node: {
        __typename?: 'SchemaRegistrySchemaVersion';
        id: string;
        version: string;
      } | null;
    } | null>;
  } | null;
};

export type GQPublishDataRegistryTablesMutationVariables = Exact<{
  input: GQPublishDataRegistryTablesInput;
}>;

export type GQPublishDataRegistryTablesMutation = {
  __typename?: 'Mutation';
  publishDataRegistryTables: {
    __typename?: 'PublishDataRegistryTablesPayload';
    publishedCount: number;
  };
};

export type GQUnpublishDataRegistryTablesMutationVariables = Exact<{
  input: GQUnpublishDataRegistryTablesInput;
}>;

export type GQUnpublishDataRegistryTablesMutation = {
  __typename?: 'Mutation';
  unpublishDataRegistryTables: {
    __typename?: 'UnpublishDataRegistryTablesPayload';
    unpublishedCount: number;
  };
};

export type GQDeleteDataRegistryTablesMutationVariables = Exact<{
  input: GQDeleteDataRegistryTablesInput;
}>;

export type GQDeleteDataRegistryTablesMutation = {
  __typename?: 'Mutation';
  deleteDataRegistryTables: {
    __typename?: 'DeleteDataRegistryTablesPayload';
    deletedCount: number;
  };
};

export type GQSchemaRegistrySchemasQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
}>;

export type GQSchemaRegistrySchemasQuery = {
  __typename?: 'Query';
  schemaRegistrySchemas: {
    __typename?: 'SchemaRegistrySchemaConnection';
    edges: Array<{
      __typename?: 'SchemaRegistrySchemaEdge';
      node: {
        __typename?: 'SchemaRegistrySchema';
        id: string;
        name: string;
        createdAt: Date;
        latestSchemaVersion: {
          __typename?: 'SchemaRegistrySchemaVersion';
          id: string;
          version: string;
        } | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
  } | null;
};

export type GQSchemaRegistrySchemaSchemaFragment = {
  __typename?: 'SchemaRegistrySchema';
  id: string;
  name: string;
  createdAt: Date;
  latestSchemaVersion: {
    __typename?: 'SchemaRegistrySchemaVersion';
    id: string;
    version: string;
  } | null;
};

export type GQDataRegistryBatchMigratorRunsQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  searchDescription: InputMaybe<Scalars['String']['input']>;
}>;

export type GQDataRegistryBatchMigratorRunsQuery = {
  __typename?: 'Query';
  dataRegistryBatchMigratorRuns: {
    __typename?: 'DataRegistryBatchMigratorRunConnection';
    edges: Array<{
      __typename?: 'DataRegistryBatchMigratorRunEdge';
      node: {
        __typename?: 'DataRegistryBatchMigratorRun';
        id: string;
        description: string | null;
        createdAt: Date;
        status: string;
        workflowId: string | null;
        filters: any | null;
        successCount: number | null;
        failureCount: number | null;
        error: string | null;
        deletedAt: Date | null;
        author: { __typename?: 'User'; id: string; name: string } | null;
        fromSchemaVersion: {
          __typename?: 'SchemaRegistrySchemaVersion';
          id: string;
          version: string;
          schema: {
            __typename?: 'SchemaRegistrySchema';
            id: string;
            name: string;
          };
        };
        toSchemaVersion: {
          __typename?: 'SchemaRegistrySchemaVersion';
          id: string;
          version: string;
          schema: {
            __typename?: 'SchemaRegistrySchema';
            id: string;
            name: string;
          };
        };
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
  } | null;
};

export type GQDataRegistryBatchMigratorRunSchemaFragment = {
  __typename?: 'DataRegistryBatchMigratorRun';
  id: string;
  description: string | null;
  createdAt: Date;
  status: string;
  workflowId: string | null;
  filters: any | null;
  successCount: number | null;
  failureCount: number | null;
  error: string | null;
  deletedAt: Date | null;
  author: { __typename?: 'User'; id: string; name: string } | null;
  fromSchemaVersion: {
    __typename?: 'SchemaRegistrySchemaVersion';
    id: string;
    version: string;
    schema: { __typename?: 'SchemaRegistrySchema'; id: string; name: string };
  };
  toSchemaVersion: {
    __typename?: 'SchemaRegistrySchemaVersion';
    id: string;
    version: string;
    schema: { __typename?: 'SchemaRegistrySchema'; id: string; name: string };
  };
};

export type GQSchemaRegistrySchemaVersionFragmentFragment = {
  __typename?: 'SchemaRegistrySchemaVersion';
  id: string;
  version: string;
  schema: { __typename?: 'SchemaRegistrySchema'; id: string; name: string };
};

export type GQSchemaRegistrySchemaFragmentFragment = {
  __typename?: 'SchemaRegistrySchema';
  id: string;
  name: string;
};

export type GQDataRegistryMigratorsQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  schemaId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQDataRegistryMigratorsQuery = {
  __typename?: 'Query';
  dataRegistryMigrators: {
    __typename?: 'DataRegistryMigratorConnection';
    edges: Array<{
      __typename?: 'DataRegistryMigratorEdge';
      node: {
        __typename?: 'DataRegistryMigrator';
        id: string;
        createdAt: Date;
        migratorType: string;
        migrationCode: string | null;
        inputVersion: {
          __typename?: 'SchemaRegistrySchemaVersion';
          id: string;
          version: string;
        } | null;
        outputVersion: {
          __typename?: 'SchemaRegistrySchemaVersion';
          id: string;
          version: string;
        } | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
  } | null;
};

export type GQDataRegistryMigratorSchemaFragment = {
  __typename?: 'DataRegistryMigrator';
  id: string;
  createdAt: Date;
  migratorType: string;
  migrationCode: string | null;
  inputVersion: {
    __typename?: 'SchemaRegistrySchemaVersion';
    id: string;
    version: string;
  } | null;
  outputVersion: {
    __typename?: 'SchemaRegistrySchemaVersion';
    id: string;
    version: string;
  } | null;
};

export type GQGetDataRegistryMigratorQueryVariables = Exact<{
  migratorId: Scalars['ID']['input'];
}>;

export type GQGetDataRegistryMigratorQuery = {
  __typename?: 'Query';
  dataRegistryMigrator: {
    __typename?: 'DataRegistryMigrator';
    id: string;
    createdAt: Date;
    migratorType: string;
    migrationCode: string | null;
    description: string | null;
    inputSchemaVersionId: string;
    outputSchemaVersionId: string;
  } | null;
};

export type GQRunDataRegistryMigrationMutationVariables = Exact<{
  input: GQRunDataRegistryMigrationInput;
}>;

export type GQRunDataRegistryMigrationMutation = {
  __typename?: 'Mutation';
  runDataRegistryMigration: string;
};

export type GQDataRegistryMigratorBestPathQueryVariables = Exact<{
  fromSchemaVersionId: Scalars['ID']['input'];
  toSchemaVersionId: Scalars['ID']['input'];
}>;

export type GQDataRegistryMigratorBestPathQuery = {
  __typename?: 'Query';
  dataRegistryMigratorBestPath: Array<{
    __typename?: 'DataRegistryMigratorAndSchema';
    migrator: {
      __typename?: 'DataRegistryMigrator';
      id: string;
      migratorType: string;
      migrationCode: string | null;
      description: string | null;
    };
    outputSchema: {
      __typename?: 'SchemaRegistrySchemaVersion';
      id: string;
      version: string;
    };
  }>;
};

export type GQSchemaRegistrySchemaVersionsQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  schemaId: InputMaybe<Scalars['ID']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
}>;

export type GQSchemaRegistrySchemaVersionsQuery = {
  __typename?: 'Query';
  schemaRegistrySchemaVersions: {
    __typename?: 'SchemaRegistrySchemaVersionConnection';
    edges: Array<{
      __typename?: 'SchemaRegistrySchemaVersionEdge';
      node: {
        __typename?: 'SchemaRegistrySchemaVersion';
        id: string;
        version: string;
        createdAt: Date;
        schemaJson: JSONSchema7;
        schemaCount: number;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
  } | null;
};

export type GQSchemaRegistrySchemaVersionSchemaFragment = {
  __typename?: 'SchemaRegistrySchemaVersion';
  id: string;
  version: string;
  createdAt: Date;
  schemaJson: JSONSchema7;
  schemaCount: number;
};

export type GQDeleteOneSchemaTemplateMutationVariables = Exact<{
  input: GQDeleteOneSchemaTemplateInput;
}>;

export type GQDeleteOneSchemaTemplateMutation = {
  __typename?: 'Mutation';
  deleteOneSchemaTemplate: {
    __typename?: 'DeleteOneSchemaTemplatePayload';
    oneSchemaTemplateKey: string;
  };
};

export type GQGetEngagementTasksAdminQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetEngagementTasksAdminQuery = {
  __typename?: 'Query';
  engagementTasksAdmin: {
    __typename?: 'EngagementTaskConnection';
    edges: Array<{
      __typename?: 'EngagementTaskEdge';
      node: {
        __typename?: 'EngagementTask';
        id: string;
        name: string;
        crossOrgModelId: string;
        status: GQCompanySurveyStatus;
        deletedAt: Date | null;
        company: { __typename?: 'Company'; id: string };
        engagementTaskConfig: {
          __typename?: 'EngagementTaskConfig';
          id: string;
          name: string;
        };
      } | null;
    }>;
  };
};

export type GQGetAssetsWithTasksQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetAssetsWithTasksQuery = {
  __typename?: 'Query';
  assetsWithTasks: Array<{
    __typename?: 'AssetCorporate';
    id: string;
    companyId: string | null;
    name: string;
  }>;
};

export type GQFootprintDisclosureShareLogFieldsFragment = {
  __typename?: 'FootprintDisclosureShareLog';
  id: string;
  orgId: string;
  footprintDisclosureId: string;
  sharedToOrgId: string;
  sharedByUserId: string;
};

export type GQFootprintDisclosureFieldsFragment = {
  __typename?: 'FootprintDisclosure';
  id: string;
  createdAt: Date;
  reportingYear: number | null;
  footprintSnapshot: {
    __typename?: 'FootprintSnapshot';
    id: string;
    configs: Array<{
      __typename?: 'PipelineConfig';
      coverageInterval: YMInterval;
    }>;
  };
  shareLogs: Array<{
    __typename?: 'FootprintDisclosureShareLog';
    id: string;
    orgId: string;
    footprintDisclosureId: string;
    sharedToOrgId: string;
    sharedByUserId: string;
  }>;
};

export type GQShareDisclosureDialogQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQShareDisclosureDialogQuery = {
  __typename?: 'Query';
  orgFootprintDisclosures: {
    __typename?: 'FootprintDisclosureConnection';
    edges: Array<{
      __typename?: 'FootprintDisclosureEdge';
      node: {
        __typename?: 'FootprintDisclosure';
        id: string;
        createdAt: Date;
        reportingYear: number | null;
        footprintSnapshot: {
          __typename?: 'FootprintSnapshot';
          id: string;
          configs: Array<{
            __typename?: 'PipelineConfig';
            coverageInterval: YMInterval;
          }>;
        };
        shareLogs: Array<{
          __typename?: 'FootprintDisclosureShareLog';
          id: string;
          orgId: string;
          footprintDisclosureId: string;
          sharedToOrgId: string;
          sharedByUserId: string;
        }>;
      } | null;
    } | null>;
  };
};

export type GQCreateFootprintDisclosureMutationVariables = Exact<{
  input: GQCreateFootprintDisclosureInput;
}>;

export type GQCreateFootprintDisclosureMutation = {
  __typename?: 'Mutation';
  createFootprintDisclosure: {
    __typename?: 'CreateFootprintDisclosurePayload';
    footprintDisclosure: { __typename?: 'FootprintDisclosure'; id: string };
  } | null;
};

export type GQGetOrgsForOrgPickerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetOrgsForOrgPickerQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'OrgConnection';
    edges: Array<{
      __typename?: 'OrgEdge';
      node: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    } | null>;
  };
};

export type GQFinanceAdminAssetDifferQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQFinanceAdminAssetDifferQuery = {
  __typename?: 'Query';
  portcos: Array<{ __typename?: 'AssetCorporate'; id: string; name: string }>;
};

export type GQFinanceAdminAssetQueryVariables = Exact<{
  assetId: Scalars['ID']['input'];
}>;

export type GQFinanceAdminAssetQuery = {
  __typename?: 'Query';
  assetCorporateAdmin: {
    __typename: 'AssetCorporate';
    id: string;
    orgId: string;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    naicsCode: string | null;
    companyIsin: string | null;
    companyLei: string | null;
    companyUrl: string | null;
    companyTicker: string | null;
    currencyCode: GQCurrencyCode;
    countryAlpha2: string | null;
    buildingSizeUnit: GQBuildingSizeUnit | null;
    companyId: string | null;
    otherData: any | null;
    tagData: any | null;
    externalId: string | null;
    assetGroupId: string | null;
    anonymousCompanyUuid: string | null;
    company: { __typename?: 'Company'; id: string; name: string } | null;
    assetYears: Array<{
      __typename: 'FinanceAssetYearNew';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      year: number;
      assetCommercialRealEstateId: string | null;
      assetCorporateId: string | null;
      assetGroupId: string | null;
      assetSovereignBondId: string | null;
      assetPersonalMotorVehicleInsuranceId: string | null;
      buildingSizeNative: number | null;
      estimationMethodology: GQEstimationMethodology | null;
      headcount: number | null;
      percentRenewable: number | null;
      cloudSpendNative: number | null;
      valueNative: number | null;
      revenueNative: number | null;
      flightMiles: number | null;
      percentEmployeesWfh: number | null;
      currencyCode: GQCurrencyCode;
      currencyConversionRate: number;
      totalMwh: number | null;
      revenueSourceEmissionsYearId: string | null;
      emissionsYearId: string | null;
      scope1OverrideTco2e: number | null;
      scope1OverridePcaf: number | null;
      scope2LocationOverrideTco2e: number | null;
      scope2LocationOverridePcaf: number | null;
      scope2MarketOverrideTco2e: number | null;
      scope2MarketOverridePcaf: number | null;
      scope3OverrideKind: GQScope3OverrideKind | null;
      scope3OverrideTco2e: number | null;
      scope3OverridePcaf: number | null;
      scope301OverrideTco2e: number | null;
      scope302OverrideTco2e: number | null;
      scope303OverrideTco2e: number | null;
      scope304OverrideTco2e: number | null;
      scope305OverrideTco2e: number | null;
      scope306OverrideTco2e: number | null;
      scope307OverrideTco2e: number | null;
      scope308OverrideTco2e: number | null;
      scope309OverrideTco2e: number | null;
      scope310OverrideTco2e: number | null;
      scope311OverrideTco2e: number | null;
      scope312OverrideTco2e: number | null;
      scope313OverrideTco2e: number | null;
      scope314OverrideTco2e: number | null;
      scope315OverrideTco2e: number | null;
      buildingEstimationErr: string | null;
      companyEstimationErr: string | null;
      measuringScope1And2Emissions: boolean | null;
      measuringMaterialScope3Emissions: boolean | null;
      initiatedDecarbonizationPlan: boolean | null;
      committedToNearTermSbtAlignedNetZero: boolean | null;
      demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
      iciAlignmentOverride: GQIciAlignmentStatus | null;
      scenario: string | null;
      tagData: any | null;
    }>;
    assetHoldings: Array<{
      __typename: 'FinanceAssetHolding';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      assetYearId: string;
      fundId: string;
      yearOfInitialInvestment: number | null;
      outstandingAmountNative: number | null;
      outstandingAmountNativeQ1: number | null;
      outstandingAmountNativeQ2: number | null;
      outstandingAmountNativeQ3: number | null;
      outstandingAmountNativeQ4: number | null;
      currencyCode: GQCurrencyCode;
      currencyConversionRate: number;
      assetClass: GQFundAssetClass;
      tagData: any | null;
      premiumNative: number | null;
      emissionsYearId: string | null;
    }>;
  };
};

export type GQFinanceMergeAssetsMutationVariables = Exact<{
  input: GQMergeAssetsInput;
}>;

export type GQFinanceMergeAssetsMutation = {
  __typename?: 'Mutation';
  mergeAssets: { __typename?: 'MergeAssetsPayload'; id: string };
};

export type GQOrganizationsFinanceDisclosuresPageQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrganizationsFinanceDisclosuresPageQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    company: { __typename?: 'Company'; id: string };
  };
  orgFootprintDisclosures: {
    __typename?: 'FootprintDisclosureConnection';
    edges: Array<{
      __typename?: 'FootprintDisclosureEdge';
      node: {
        __typename?: 'FootprintDisclosure';
        id: string;
        createdAt: Date;
        reportingYear: number | null;
        footprintSnapshot: {
          __typename?: 'FootprintSnapshot';
          id: string;
          configs: Array<{
            __typename?: 'PipelineConfig';
            coverageInterval: YMInterval;
          }>;
        };
        shareLogs: Array<{
          __typename?: 'FootprintDisclosureShareLog';
          id: string;
          orgId: string;
          footprintDisclosureId: string;
          sharedToOrgId: string;
          sharedByUserId: string;
        }>;
      } | null;
    } | null>;
  };
  orgFootprintDisclosureShareLogs: {
    __typename?: 'FootprintDisclosureShareLogConnection';
    edges: Array<{
      __typename?: 'FootprintDisclosureShareLogEdge';
      node: {
        __typename?: 'FootprintDisclosureShareLog';
        id: string;
        orgId: string;
        footprintDisclosureId: string;
        sharedToOrgId: string;
        sharedByUserId: string;
      } | null;
    } | null>;
  };
};

export type GQFinanceAdminGetRefreshableAssetsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
}>;

export type GQFinanceAdminGetRefreshableAssetsQuery = {
  __typename?: 'Query';
  refreshableAssets: Array<{
    __typename?: 'AssetCorporate';
    id: string;
    name: string;
    naicsCode: string | null;
    countryAlpha2: string | null;
    assetYears: Array<{ __typename?: 'FinanceAssetYearNew'; id: string }>;
  }>;
};

export type GQRefreshAssetYearEstimationsMutationVariables = Exact<{
  input: GQRefreshAssetYearEstimationsInput;
}>;

export type GQRefreshAssetYearEstimationsMutation = {
  __typename?: 'Mutation';
  refreshAssetYearEstimations: {
    __typename?: 'RefreshAssetYearEstimationsPayload';
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQOrganizationsFundsPageQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrganizationsFundsPageQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    company: { __typename?: 'Company'; id: string };
  };
  orgFunds: {
    __typename?: 'FundConnection';
    edges: Array<{
      __typename?: 'FundEdge';
      node: {
        __typename: 'Fund';
        id: string;
        name: string;
        nameSortable: string | null;
        fundGroup: string | null;
        fundCategory: string | null;
        excludeAsSandbox: boolean | null;
        anonymousFundUuid: string | null;
        createdAt: Date;
        orgId: string;
        updatedAt: Date;
        tagData: any | null;
        externalId: string | null;
      } | null;
    } | null>;
  };
};

export type GQCreateFundAdminMutationVariables = Exact<{
  input: GQCreateFundInputAdmin;
}>;

export type GQCreateFundAdminMutation = {
  __typename?: 'Mutation';
  createFundAdmin: {
    __typename?: 'CreateFundPayload';
    fund: {
      __typename: 'Fund';
      id: string;
      name: string;
      nameSortable: string | null;
      fundGroup: string | null;
      fundCategory: string | null;
      excludeAsSandbox: boolean | null;
      anonymousFundUuid: string | null;
      createdAt: Date;
      orgId: string;
      updatedAt: Date;
      tagData: any | null;
      externalId: string | null;
    };
  } | null;
};

export type GQUpdateFundMutationVariables = Exact<{
  input: GQUpdateFundInput;
}>;

export type GQUpdateFundMutation = {
  __typename?: 'Mutation';
  updateFund: {
    __typename?: 'UpdateFundPayload';
    fund: {
      __typename: 'Fund';
      id: string;
      name: string;
      nameSortable: string | null;
      fundGroup: string | null;
      fundCategory: string | null;
      excludeAsSandbox: boolean | null;
      anonymousFundUuid: string | null;
      createdAt: Date;
      orgId: string;
      updatedAt: Date;
      tagData: any | null;
      externalId: string | null;
    };
  } | null;
};

export type GQDeleteFundsMutationVariables = Exact<{
  input: GQDeleteFundsInput;
}>;

export type GQDeleteFundsMutation = {
  __typename?: 'Mutation';
  deleteFunds: {
    __typename?: 'DeleteFundsPayload';
    ids: Array<string>;
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQFinanceMeasurementProgressTabAdminQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
}>;

export type GQFinanceMeasurementProgressTabAdminQuery = {
  __typename?: 'Query';
  financeMeasurementProgressRowsAdmin: Array<{
    __typename?: 'FinanceMeasurementProgressRow';
    assetId: string;
    assetName: string;
    companyId: string;
    projectName: string | null;
    hasRequestedProgress: boolean | null;
    hasRequestedFootprint: boolean | null;
    coverageStartDate: Date | null;
    coverageEndDate: Date | null;
    updatedAt: Date | null;
    fundIds: Array<string>;
    hasApprovedProgressRequest: boolean | null;
    contacts: Array<{
      __typename?: 'SupplierContact';
      id: string;
      name: string;
      email: string;
      role: string | null;
      userId: string | null;
      companyId: string;
    }> | null;
    taskStatusDumps: Array<{
      __typename?: 'TaskStatusDump';
      taskId: string;
      taskName: string;
      assigneeNames: Array<string> | null;
      datasetName: string | null;
      status: GQTaskWatershedProcessStateSimplified;
    }> | null;
    portcoFootprintReviewRequestMetadata: Array<{
      __typename?: 'PortcoFootprintReviewRequestMetadata';
      id: string;
      footprintVersionId: string;
      footprintSnapshotId: string | null;
      footprintVersionKind: string | null;
      status: GQWatershedFootprintReviewRequestStatus | null;
    }> | null;
    measuredFootprintDetails: {
      __typename?: 'MeasuredFootprintDetails';
      footprintSharedAt: Date;
      emissionsTco2e: number;
    } | null;
  }>;
};

export type GQAvailableProjectsForCompanyQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
}>;

export type GQAvailableProjectsForCompanyQuery = {
  __typename?: 'Query';
  availableProjectsForCompany: Array<{
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    coverageStartDate: Date;
    coverageEndDate: Date;
  }>;
};

export type GQShareMeasurementProjectMutationVariables = Exact<{
  input: GQShareMeasurementProjectInput;
}>;

export type GQShareMeasurementProjectMutation = {
  __typename?: 'Mutation';
  shareMeasurementProject: {
    __typename?: 'ShareMeasurementProjectPayload';
    measurementProjectStatusShare: {
      __typename?: 'MeasurementProjectStatusShare';
      id: string;
    };
  } | null;
};

export type GQUserFieldsForFinancePageFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  displayName: string;
};

export type GQPortcoOrgFieldsForFinancePageFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
};

export type GQFinanceAdminPortcoLoginTabQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQFinanceAdminPortcoLoginTabQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
  }>;
  portcoOrgs: Array<{ __typename?: 'Organization'; id: string; name: string }>;
};

export type GQGrantFinanceUsersPortcoAccessesMutationVariables = Exact<{
  input: GQGrantFinanceUsersPortcoAccessesInput;
}>;

export type GQGrantFinanceUsersPortcoAccessesMutation = {
  __typename?: 'Mutation';
  grantFinanceUsersPortcoAccesses: {
    __typename?: 'GrantFinanceUsersPortcoAccessesPayload';
    backgroundJobId: string;
  } | null;
};

export type GQUserTaskPermissionsFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    active: boolean;
  };
  datasource: {
    __typename?: 'Datasource';
    id: string;
    name: string;
    dataset: { __typename?: 'Dataset'; id: string; name: string };
  };
};

export type GQUserTablePermissionsFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  displayName: string;
  orgAccessId: string;
  userUploadTasks: Array<{
    __typename?: 'UserUploadTask';
    id: string;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
    };
    datasource: {
      __typename?: 'Datasource';
      id: string;
      name: string;
      dataset: { __typename?: 'Dataset'; id: string; name: string };
    };
  }>;
  roles: Array<{
    __typename?: 'UserRoleAssignment';
    id: string;
    revokedAt: Date | null;
    role: {
      __typename?: 'Role';
      id: string;
      name: string;
      description: string | null;
      permissions: Array<{
        __typename?: 'PermissionItem';
        id: string;
        objectId: string | null;
        permission: GQPermissionType;
        revokedAt: Date | null;
        object:
          | { __typename: 'Company'; id: string; name: string }
          | { __typename: 'CompanySurvey'; id: string; name: string }
          | { __typename: 'Dataset'; id: string; name: string }
          | { __typename: 'Datasource'; id: string; name: string }
          | { __typename: 'EngagementTask'; id: string; name: string }
          | { __typename: 'Fund'; id: string; name: string }
          | null;
      }>;
    };
  }>;
  permissions: Array<{
    __typename?: 'PermissionItem';
    id: string;
    objectId: string | null;
    permission: GQPermissionType;
    revokedAt: Date | null;
    object:
      | { __typename: 'Company'; id: string; name: string }
      | { __typename: 'CompanySurvey'; id: string; name: string }
      | { __typename: 'Dataset'; id: string; name: string }
      | { __typename: 'Datasource'; id: string; name: string }
      | { __typename: 'EngagementTask'; id: string; name: string }
      | { __typename: 'Fund'; id: string; name: string }
      | null;
  }>;
};

type GQCompanyClimateCommitmentFields_CarbonNeutralCommitment_Fragment = {
  __typename: 'CarbonNeutralCommitment';
  id: string;
  targetYear: number | null;
  kind: GQCompanyClimateCommitmentKind;
  description: string | null;
  externalUrl: string | null;
  commitmentMadeDate: Date | null;
  commitmentPeriodStart: Date | null;
  commitmentPeriodEnd: Date | null;
};

type GQCompanyClimateCommitmentFields_CleanEnergyCommitment_Fragment = {
  __typename: 'CleanEnergyCommitment';
  id: string;
  targetYear: number | null;
  targetPercentageCleanEnergy: number | null;
  kind: GQCompanyClimateCommitmentKind;
  description: string | null;
  externalUrl: string | null;
  commitmentMadeDate: Date | null;
  commitmentPeriodStart: Date | null;
  commitmentPeriodEnd: Date | null;
};

type GQCompanyClimateCommitmentFields_NetZeroCommitment_Fragment = {
  __typename: 'NetZeroCommitment';
  id: string;
  targetYear: number | null;
  kind: GQCompanyClimateCommitmentKind;
  description: string | null;
  externalUrl: string | null;
  commitmentMadeDate: Date | null;
  commitmentPeriodStart: Date | null;
  commitmentPeriodEnd: Date | null;
};

type GQCompanyClimateCommitmentFields_ScienceBasedTargetCommitment_Fragment = {
  __typename: 'ScienceBasedTargetCommitment';
  submittedToSBTi: boolean;
  id: string;
  kind: GQCompanyClimateCommitmentKind;
  description: string | null;
  externalUrl: string | null;
  commitmentMadeDate: Date | null;
  commitmentPeriodStart: Date | null;
  commitmentPeriodEnd: Date | null;
  commitment: {
    __typename?: 'SBTCommitment';
    id: string;
    nearTermTargetYear: number | null;
    longTermTargetYear: number | null;
    baselineYear: number | null;
    stage: GQSbtCommitmentStage | null;
    targetClassification: GQSbtTargetClassification | null;
    netZeroTargetYear: number | null;
    commitmentDeadline: Date | null;
    commitmentType: GQSbtCommitmentType | null;
    netZeroCommitted: boolean | null;
  };
};

export type GQCompanyClimateCommitmentFieldsFragment =
  | GQCompanyClimateCommitmentFields_CarbonNeutralCommitment_Fragment
  | GQCompanyClimateCommitmentFields_CleanEnergyCommitment_Fragment
  | GQCompanyClimateCommitmentFields_NetZeroCommitment_Fragment
  | GQCompanyClimateCommitmentFields_ScienceBasedTargetCommitment_Fragment;

export type GQDeleteCompanyClimateCommitmentMutationVariables = Exact<{
  input: GQDeleteCompanyClimateCommitmentInput;
}>;

export type GQDeleteCompanyClimateCommitmentMutation = {
  __typename?: 'Mutation';
  deleteCompanyClimateCommitment: {
    __typename?: 'DeleteCompanyClimateCommitmentPayload';
    disclosure: { __typename?: 'Disclosure'; id: string };
  } | null;
};

export type GQHistoricalEmissionsYearFieldsFragment = {
  __typename?: 'EmissionsYear';
  id: string;
  publicDisclosureId: string | null;
  reportingYear: number;
  numEmployees: number | null;
  revenue: number | null;
  revenueCurrency: string | null;
  revenueUsd: number | null;
  scope1: number | null;
  scope2Market: number | null;
  scope2Location: number | null;
  scope3: number | null;
  scope301: number | null;
  scope302: number | null;
  scope303: number | null;
  scope304: number | null;
  scope305: number | null;
  scope306: number | null;
  scope307: number | null;
  scope308: number | null;
  scope309: number | null;
  scope310: number | null;
  scope311: number | null;
  scope312: number | null;
  scope313: number | null;
  scope314: number | null;
  scope315: number | null;
  scope316: number | null;
  scope317: number | null;
  units: GQCompanyEmissionsUnits;
  percentageCleanEnergy: number | null;
  totalMwh: number | null;
  scope123RevenueIntensityKgco2e: {
    __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
    id: string;
    scope1: number | null;
    scope2Market: number | null;
    scope2Location: number | null;
    scope3: number | null;
  } | null;
};

export type GQPublicDisclosureTableFragment = {
  __typename?: 'PublicDisclosure';
  id: string;
  publicUrl: string;
  publishingYear: number;
  reportType: GQExternalReportType;
  gsUrl: string | null;
  company: { __typename?: 'Company'; id: string; name: string } | null;
  historicalEmissionsYearsDetails: Array<{
    __typename?: 'EmissionsYear';
    id: string;
    publicDisclosureId: string | null;
    reportingYear: number;
    numEmployees: number | null;
    revenue: number | null;
    revenueCurrency: string | null;
    revenueUsd: number | null;
    scope1: number | null;
    scope2Market: number | null;
    scope2Location: number | null;
    scope3: number | null;
    scope301: number | null;
    scope302: number | null;
    scope303: number | null;
    scope304: number | null;
    scope305: number | null;
    scope306: number | null;
    scope307: number | null;
    scope308: number | null;
    scope309: number | null;
    scope310: number | null;
    scope311: number | null;
    scope312: number | null;
    scope313: number | null;
    scope314: number | null;
    scope315: number | null;
    scope316: number | null;
    scope317: number | null;
    units: GQCompanyEmissionsUnits;
    percentageCleanEnergy: number | null;
    totalMwh: number | null;
    scope123RevenueIntensityKgco2e: {
      __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
      id: string;
      scope1: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
    } | null;
  }>;
};

export type GQCreatePublicDisclosureMutationVariables = Exact<{
  input: GQCreatePublicDisclosureInput;
}>;

export type GQCreatePublicDisclosureMutation = {
  __typename?: 'Mutation';
  createPublicDisclosure: {
    __typename?: 'CreatePublicDisclosurePayload';
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      publicUrl: string;
      publishingYear: number;
      reportType: GQExternalReportType;
      gsUrl: string | null;
      company: { __typename?: 'Company'; id: string; name: string } | null;
      historicalEmissionsYearsDetails: Array<{
        __typename?: 'EmissionsYear';
        id: string;
        publicDisclosureId: string | null;
        reportingYear: number;
        numEmployees: number | null;
        revenue: number | null;
        revenueCurrency: string | null;
        revenueUsd: number | null;
        scope1: number | null;
        scope2Market: number | null;
        scope2Location: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
        units: GQCompanyEmissionsUnits;
        percentageCleanEnergy: number | null;
        totalMwh: number | null;
        scope123RevenueIntensityKgco2e: {
          __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
          id: string;
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
        } | null;
      }>;
    };
  };
};

export type GQEmissionsYearAllFieldsFragment = {
  __typename?: 'EmissionsYear';
  id: string;
  createdAt: Date;
  publicDisclosureId: string | null;
  privateDisclosureId: string | null;
  footprintEstimateOutputId: string | null;
  buildingEstimateOutputId: string | null;
  assetCorporateId: string | null;
  expenseCategory: string | null;
  reportingYear: number;
  numEmployees: number | null;
  revenue: number | null;
  revenueCurrency: string | null;
  currencyConversionRate: number | null;
  scope1: number | null;
  scope2: number | null;
  scope2Market: number | null;
  scope2Location: number | null;
  scope3: number | null;
  scope301: number | null;
  scope302: number | null;
  scope303: number | null;
  scope304: number | null;
  scope305: number | null;
  scope306: number | null;
  scope307: number | null;
  scope308: number | null;
  scope309: number | null;
  scope310: number | null;
  scope311: number | null;
  scope312: number | null;
  scope313: number | null;
  scope314: number | null;
  scope315: number | null;
  scope316: number | null;
  scope317: number | null;
  units: GQCompanyEmissionsUnits;
  percentageCleanEnergy: number | null;
  totalMwh: number | null;
  orgId: string | null;
  scenario: string | null;
  updatedAt: Date;
  pcafScoreScope1: number | null;
};

export type GQEditEmissionsYearMutationVariables = Exact<{
  input: GQEditEmissionsYearInput;
}>;

export type GQEditEmissionsYearMutation = {
  __typename?: 'Mutation';
  editEmissionsYear: {
    __typename?: 'EditEmissionsYearPayload';
    emissionsYear: {
      __typename?: 'EmissionsYear';
      id: string;
      createdAt: Date;
      publicDisclosureId: string | null;
      privateDisclosureId: string | null;
      footprintEstimateOutputId: string | null;
      buildingEstimateOutputId: string | null;
      assetCorporateId: string | null;
      expenseCategory: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      currencyConversionRate: number | null;
      scope1: number | null;
      scope2: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
      units: GQCompanyEmissionsUnits;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      orgId: string | null;
      scenario: string | null;
      updatedAt: Date;
      pcafScoreScope1: number | null;
    };
  };
};

export type GQDeleteEmissionsYearMutationVariables = Exact<{
  input: GQDeleteEmissionsYearInput;
}>;

export type GQDeleteEmissionsYearMutation = {
  __typename?: 'Mutation';
  deleteEmissionsYear: {
    __typename?: 'DeleteEmissionsYearPayload';
    id: string;
  };
};

export type GQEditPublicDisclosureMutationVariables = Exact<{
  input: GQEditPublicDisclosureInput;
}>;

export type GQEditPublicDisclosureMutation = {
  __typename?: 'Mutation';
  editPublicDisclosure: {
    __typename?: 'EditPublicDisclosurePayload';
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      publicUrl: string;
      publishingYear: number;
      reportType: GQExternalReportType;
      gsUrl: string | null;
      company: { __typename?: 'Company'; id: string; name: string } | null;
      historicalEmissionsYearsDetails: Array<{
        __typename?: 'EmissionsYear';
        id: string;
        publicDisclosureId: string | null;
        reportingYear: number;
        numEmployees: number | null;
        revenue: number | null;
        revenueCurrency: string | null;
        revenueUsd: number | null;
        scope1: number | null;
        scope2Market: number | null;
        scope2Location: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
        units: GQCompanyEmissionsUnits;
        percentageCleanEnergy: number | null;
        totalMwh: number | null;
        scope123RevenueIntensityKgco2e: {
          __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
          id: string;
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
        } | null;
      }>;
    };
  };
};

export type GQDeletePublicDisclosureMutationVariables = Exact<{
  input: GQDeletePublicDisclosureInput;
}>;

export type GQDeletePublicDisclosureMutation = {
  __typename?: 'Mutation';
  deletePublicDisclosure: {
    __typename?: 'DeletePublicDisclosurePayload';
    id: string;
  };
};

export type GQCreatePlanWithDefaultsMutationVariables = Exact<{
  input: GQCreatePlanInput;
}>;

export type GQCreatePlanWithDefaultsMutation = {
  __typename?: 'Mutation';
  createPlan: {
    __typename?: 'CreatePlanPayload';
    plan: { __typename?: 'Plan'; id: string; name: string };
  } | null;
};

export type GQUpdatePlanAdminMutationVariables = Exact<{
  input: GQUpdatePlanInput;
}>;

export type GQUpdatePlanAdminMutation = {
  __typename?: 'Mutation';
  adminUpdatePlan: {
    __typename?: 'UpdatePlanPayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      name: string;
      createdAt: Date;
      deletedAt: Date | null;
      updatedAt: Date;
      updatedByName: string | null;
      state: GQPlanState;
      baselineYearStart: YearMonth;
      isHidden: boolean;
      isSbtValidated: boolean;
      forecast: {
        __typename?: 'Forecast';
        id: string;
        interval: YMInterval;
        referencePeriodInterval: YMInterval;
        footprintSnapshotId: string;
        validReferenceInterval: YMInterval;
        overrideFootprintKind: string | null;
      };
      variables: {
        __typename?: 'PlanVariables';
        commitmentsSBTTermLength: GQSbtTermLength;
      };
    };
  } | null;
};

export type GQCustomReportFieldsFragment = {
  __typename?: 'FootprintCustomReport';
  id: string;
  name: string;
  description: string;
  downloadUrl: string;
  published: boolean;
  footprintSnapshotId: string | null;
  lastRefreshedAt: Date;
  archivedAt: Date | null;
};

export type GQFormalReportFieldsAdminFragment = {
  __typename?: 'Report';
  id: string;
  name: string;
  isHidden: boolean | null;
  reportKind: string;
  createdAt: Date;
  deletedAt: Date | null;
  footprintSnapshot: { __typename?: 'FootprintSnapshot'; id: string } | null;
  config: { __typename?: 'ReportConfig'; id: string; reportType: string };
};

export type GQReportQuestionIdentifiersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQReportQuestionIdentifiersQuery = {
  __typename?: 'Query';
  reportQuestionIdentifiers: {
    __typename?: 'ReportQuestionIdentifierConnection';
    edges: Array<{
      __typename?: 'ReportQuestionIdentifierEdge';
      node: {
        __typename?: 'ReportQuestionIdentifier';
        id: string;
        identifier: string;
      } | null;
    } | null>;
  };
};

export type GQInsertReportQuestionIdentifierMutationVariables = Exact<{
  input: GQInsertReportQuestionIdentifierInput;
}>;

export type GQInsertReportQuestionIdentifierMutation = {
  __typename?: 'Mutation';
  insertReportQuestionIdentifier: {
    __typename?: 'ReportQuestionIdentifier';
    id: string;
    identifier: string;
  };
};

export type GQCreateReportWithConfigAdminMutationVariables = Exact<{
  input: GQCreateReportWithConfigAdminInput;
}>;

export type GQCreateReportWithConfigAdminMutation = {
  __typename?: 'Mutation';
  createReportWithConfigAdmin: { __typename?: 'ReportWithConfig'; id: string };
};

export type GQCreateReportWithCustomConfigAdminMutationVariables = Exact<{
  input: GQCreateReportWithCustomConfigAdminInput;
}>;

export type GQCreateReportWithCustomConfigAdminMutation = {
  __typename?: 'Mutation';
  createReportWithCustomConfigAdmin: {
    __typename?: 'CreateReportWithCustomConfigAdminPayload';
    report: {
      __typename?: 'Report';
      id: string;
      name: string;
      reportKind: string;
    };
  } | null;
};

export type GQExternalReportQuestionFieldsFragment = {
  __typename?: 'ExternalReportQuestion';
  id: string;
  question: string;
  type: {
    __typename?: 'ExternalReportTypeWithRevision';
    id: string;
    type: GQExternalReportType;
    revision: string;
    specUrl: string | null;
  };
  reportQuestionIdentifier: {
    __typename?: 'ReportQuestionIdentifier';
    id: string;
    identifier: string;
  };
};

export type GQExternalReportAnswerFieldsFragment = {
  __typename?: 'ExternalReportAnswer';
  id: string;
  questionPhrasing: string | null;
  answer: any;
  answerType: string;
  index: number;
  watershedQualityScore: GQDisclosureQualityScore | null;
  question: {
    __typename?: 'ExternalReportQuestion';
    id: string;
    question: string;
    type: {
      __typename?: 'ExternalReportTypeWithRevision';
      id: string;
      type: GQExternalReportType;
      revision: string;
      specUrl: string | null;
    };
    reportQuestionIdentifier: {
      __typename?: 'ReportQuestionIdentifier';
      id: string;
      identifier: string;
    };
  };
  publicDisclosure: {
    __typename?: 'PublicDisclosure';
    id: string;
    company: { __typename?: 'Company'; id: string; name: string } | null;
  };
};

export type GQExternalReportAnswersForReportQueryVariables = Exact<{
  publicDisclosureId: Scalars['ID']['input'];
}>;

export type GQExternalReportAnswersForReportQuery = {
  __typename?: 'Query';
  externalReportAnswersForReport: Array<{
    __typename?: 'ExternalReportAnswer';
    id: string;
    questionPhrasing: string | null;
    answer: any;
    answerType: string;
    index: number;
    watershedQualityScore: GQDisclosureQualityScore | null;
    question: {
      __typename?: 'ExternalReportQuestion';
      id: string;
      question: string;
      type: {
        __typename?: 'ExternalReportTypeWithRevision';
        id: string;
        type: GQExternalReportType;
        revision: string;
        specUrl: string | null;
      };
      reportQuestionIdentifier: {
        __typename?: 'ReportQuestionIdentifier';
        id: string;
        identifier: string;
      };
    };
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      company: { __typename?: 'Company'; id: string; name: string } | null;
    };
  }>;
};

export type GQScoreAnswerMutationVariables = Exact<{
  answerId: Scalars['ID']['input'];
  score: InputMaybe<GQDisclosureQualityScore>;
}>;

export type GQScoreAnswerMutation = {
  __typename?: 'Mutation';
  scoreAnswer: {
    __typename?: 'ExternalReportAnswer';
    id: string;
    watershedQualityScore: GQDisclosureQualityScore | null;
  };
};

export type GQSearchCompaniesForExternalReportAnswersQueryVariables = Exact<{
  company: Scalars['String']['input'];
  first: Scalars['Int']['input'];
}>;

export type GQSearchCompaniesForExternalReportAnswersQuery = {
  __typename?: 'Query';
  companies: {
    __typename?: 'CompanyConnection';
    edges: Array<{
      __typename?: 'CompanyEdge';
      node: {
        __typename?: 'Company';
        id: string;
        name: string;
        disclosuresV2: Array<{
          __typename?: 'Disclosure';
          id: string;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            reportType: GQExternalReportType;
            source: GQPublicDisclosureSource;
            reportingYears: Array<number>;
          } | null;
        }>;
      } | null;
    } | null>;
  };
};

export type GQGetArchetypeOpportunitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetArchetypeOpportunitiesQuery = {
  __typename?: 'Query';
  tcfdArchetypeOpportunities: Array<{
    __typename?: 'TcfdArchetypeOpportunity';
    geographies: Array<string>;
    sectors: Array<string>;
    distributionModel: GQTcfdStrategyModuleDistributionModel;
    id: string;
    title: string;
    userDescription: string;
  }>;
};

export type GQCreateTcfdArchetypeOpportunityMutationVariables = Exact<{
  input: GQCreateTcfdArchetypeOpportunityInput;
}>;

export type GQCreateTcfdArchetypeOpportunityMutation = {
  __typename?: 'Mutation';
  createTcfdArchetypeOpportunity: {
    __typename?: 'TcfdArchetypeOpportunity';
    geographies: Array<string>;
    sectors: Array<string>;
    distributionModel: GQTcfdStrategyModuleDistributionModel;
    id: string;
    title: string;
    userDescription: string;
  };
};

export type GQUpdateTcfdArchetypeOpportunityMutationVariables = Exact<{
  input: GQUpdateTcfdArchetypeOpportunityInput;
}>;

export type GQUpdateTcfdArchetypeOpportunityMutation = {
  __typename?: 'Mutation';
  updateTcfdArchetypeOpportunity: {
    __typename?: 'TcfdArchetypeOpportunity';
    geographies: Array<string>;
    sectors: Array<string>;
    distributionModel: GQTcfdStrategyModuleDistributionModel;
    id: string;
    title: string;
    userDescription: string;
  };
};

export type GQGetArchetypeRisksQueryVariables = Exact<{ [key: string]: never }>;

export type GQGetArchetypeRisksQuery = {
  __typename?: 'Query';
  tcfdArchetypeRisks: Array<{
    __typename?: 'TcfdArchetypeRisk';
    geographies: Array<string>;
    sectors: Array<string>;
    distributionModel: GQTcfdStrategyModuleDistributionModel;
    whyWeChoseThisMd: string;
    id: string;
    title: string;
    userDescription: string;
    kind: GQTcfdRiskKind;
    possibleImpactDescription: string | null;
    lowTrajectoryImpact: GQTcfdRiskImpact;
    mediumTrajectoryImpact: GQTcfdRiskImpact;
    highTrajectoryImpact: GQTcfdRiskImpact;
    physicalRiskSeverity: GQTcfdPhysicalRiskSeverity | null;
  }>;
};

export type GQDeleteTcfdArchetypeRiskMutationVariables = Exact<{
  input: GQDeleteTcfdArchetypeRiskInput;
}>;

export type GQDeleteTcfdArchetypeRiskMutation = {
  __typename?: 'Mutation';
  deleteTcfdArchetypeRisk: {
    __typename?: 'TcfdArchetypeRisk';
    geographies: Array<string>;
    sectors: Array<string>;
    distributionModel: GQTcfdStrategyModuleDistributionModel;
    whyWeChoseThisMd: string;
    id: string;
    title: string;
    userDescription: string;
    kind: GQTcfdRiskKind;
    possibleImpactDescription: string | null;
    lowTrajectoryImpact: GQTcfdRiskImpact;
    mediumTrajectoryImpact: GQTcfdRiskImpact;
    highTrajectoryImpact: GQTcfdRiskImpact;
    physicalRiskSeverity: GQTcfdPhysicalRiskSeverity | null;
  };
};

export type GQCreateTcfdArchetypeRiskMutationVariables = Exact<{
  input: GQCreateTcfdArchetypeRiskInput;
}>;

export type GQCreateTcfdArchetypeRiskMutation = {
  __typename?: 'Mutation';
  createTcfdArchetypeRisk: {
    __typename?: 'TcfdArchetypeRisk';
    geographies: Array<string>;
    sectors: Array<string>;
    distributionModel: GQTcfdStrategyModuleDistributionModel;
    whyWeChoseThisMd: string;
    id: string;
    title: string;
    userDescription: string;
    kind: GQTcfdRiskKind;
    possibleImpactDescription: string | null;
    lowTrajectoryImpact: GQTcfdRiskImpact;
    mediumTrajectoryImpact: GQTcfdRiskImpact;
    highTrajectoryImpact: GQTcfdRiskImpact;
    physicalRiskSeverity: GQTcfdPhysicalRiskSeverity | null;
  };
};

export type GQUpdateTcfdArchetypeRiskMutationVariables = Exact<{
  input: GQUpdateTcfdArchetypeRiskInput;
}>;

export type GQUpdateTcfdArchetypeRiskMutation = {
  __typename?: 'Mutation';
  updateTcfdArchetypeRisk: {
    __typename?: 'TcfdArchetypeRisk';
    geographies: Array<string>;
    sectors: Array<string>;
    distributionModel: GQTcfdStrategyModuleDistributionModel;
    whyWeChoseThisMd: string;
    id: string;
    title: string;
    userDescription: string;
    kind: GQTcfdRiskKind;
    possibleImpactDescription: string | null;
    lowTrajectoryImpact: GQTcfdRiskImpact;
    mediumTrajectoryImpact: GQTcfdRiskImpact;
    highTrajectoryImpact: GQTcfdRiskImpact;
    physicalRiskSeverity: GQTcfdPhysicalRiskSeverity | null;
  };
};

export type GQInitializeDefaultSupplierViewAdminMutationVariables = Exact<{
  input: GQInitializeDefaultSupplierViewAdminInput;
}>;

export type GQInitializeDefaultSupplierViewAdminMutation = {
  __typename?: 'Mutation';
  initializeDefaultSupplierViewAdmin: {
    __typename?: 'InitializeDefaultSupplierViewAdminPayload';
    view: {
      __typename?: 'SupplierView';
      id: string;
      charts: Array<
        | {
            __typename: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{ __typename?: 'EngagementFunnel'; id: string }>;
          }
        | {
            __typename: 'SupplierMetricsChartConfig';
            metricsTypes: Array<GQSupplyChainMetricType>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
      chartOrder: Array<{
        __typename?: 'SupplierViewChartOrder';
        chartId: string;
        visible: boolean;
      }> | null;
    };
  };
};

export type GQSupplierViewFieldsAdminFragment = {
  __typename?: 'SupplierView';
  id: string;
  charts: Array<
    | {
        __typename: 'SupplierEmissionsForecastChartConfig';
        id: string;
        orgId: string | null;
        title: string;
        description: string | null;
        chartType: GQSupplierChartType;
      }
    | {
        __typename: 'SupplierEngagementFunnelChartConfig';
        id: string;
        orgId: string | null;
        title: string;
        description: string | null;
        chartType: GQSupplierChartType;
        funnels: Array<{ __typename?: 'EngagementFunnel'; id: string }>;
      }
    | {
        __typename: 'SupplierMetricsChartConfig';
        metricsTypes: Array<GQSupplyChainMetricType>;
        id: string;
        orgId: string | null;
        title: string;
        description: string | null;
        chartType: GQSupplierChartType;
      }
    | {
        __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
        colorScale: Array<string>;
        id: string;
        orgId: string | null;
        title: string;
        description: string | null;
        chartType: GQSupplierChartType;
      }
    | {
        __typename: 'SupplierStandardChartConfig';
        colorScale: Array<string>;
        xField: string;
        aggregateType: GQSupplierChartAggregateType;
        id: string;
        orgId: string | null;
        title: string;
        description: string | null;
        chartType: GQSupplierChartType;
      }
    | {
        __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
        colorScale: Array<string>;
        isCumulative: boolean | null;
        id: string;
        orgId: string | null;
        title: string;
        description: string | null;
        chartType: GQSupplierChartType;
        tasksStatusChangeConfig: Array<{
          __typename?: 'SurveyDefinitionAndStatus';
          status: string;
          engagementTaskConfigId: string;
        }> | null;
      }
  >;
  chartOrder: Array<{
    __typename?: 'SupplierViewChartOrder';
    chartId: string;
    visible: boolean;
  }> | null;
};

export type GQActiveWatershedEmployeeFieldsFragment = {
  __typename?: 'WatershedEmployee';
  id: string;
  name: string;
  handle: string;
  email: string;
  user: {
    __typename?: 'User';
    id: string;
    createdAt: Date;
    primaryLoginOrgId: string;
    accessibleOrgs: Array<{
      __typename?: 'OrgLimitedProfile';
      id: string;
      name: string;
    }>;
  };
  orgPointsOfContact: Array<{
    __typename?: 'OrgPointOfContact';
    id: string;
    orgId: string;
    kind: GQOrgPointOfContactKind;
  }>;
};

export type GQActiveWatershedEmployeeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQActiveWatershedEmployeeQuery = {
  __typename?: 'Query';
  activeWatershedEmployee: {
    __typename?: 'WatershedEmployee';
    id: string;
    name: string;
    handle: string;
    email: string;
    user: {
      __typename?: 'User';
      id: string;
      createdAt: Date;
      primaryLoginOrgId: string;
      accessibleOrgs: Array<{
        __typename?: 'OrgLimitedProfile';
        id: string;
        name: string;
      }>;
    };
    orgPointsOfContact: Array<{
      __typename?: 'OrgPointOfContact';
      id: string;
      orgId: string;
      kind: GQOrgPointOfContactKind;
    }>;
  } | null;
};

export type GQBusinessActivityTypesForTypeNameAndVersionPageQueryVariables =
  Exact<{
    versionName: Scalars['String']['input'];
    typeName: Scalars['String']['input'];
  }>;

export type GQBusinessActivityTypesForTypeNameAndVersionPageQuery = {
  __typename?: 'Query';
  businessActivityTypeVersions: Array<{
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
    recordStartAt: Date;
    recordEndAt: Date | null;
  }>;
  businessActivityTypeVersionByName: {
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
    recordStartAt: Date;
    recordEndAt: Date | null;
  };
  businessActivityTypesForVersion: Array<{
    __typename?: 'BusinessActivityType';
    id: string;
    versionId: string;
    name: string;
    description: string;
  }>;
  businessActivityTypeWithFields: {
    __typename?: 'BusinessActivityType';
    id: string;
    versionId: string;
    name: string;
    description: string;
    fields: Array<{
      __typename?: 'BusinessActivityTypeField';
      id: string;
      typeId: string;
      versionId: string;
      description: string;
      fieldName: string;
      fieldType: GQBusinessActivityTypeFieldType;
      fieldValueOneOf: any | null;
      isRequired: boolean;
      isProratable: boolean;
      unitFieldName: string | null;
    }>;
  } | null;
};

export type GQBusinessActivityTypesForVersionPageQueryVariables = Exact<{
  versionName: Scalars['String']['input'];
}>;

export type GQBusinessActivityTypesForVersionPageQuery = {
  __typename?: 'Query';
  businessActivityTypeVersions: Array<{
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
    recordStartAt: Date;
    recordEndAt: Date | null;
  }>;
  businessActivityTypeVersionByName: {
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
    recordStartAt: Date;
    recordEndAt: Date | null;
  };
  businessActivityTypesForVersion: Array<{
    __typename?: 'BusinessActivityType';
    id: string;
    versionId: string;
    name: string;
    description: string;
  }>;
  businessActivityTypesPrimaryForVersionWithFields: Array<{
    __typename?: 'BusinessActivityType';
    id: string;
    versionId: string;
    name: string;
    description: string;
    fields: Array<{
      __typename?: 'BusinessActivityTypeField';
      id: string;
      typeId: string;
      versionId: string;
      description: string;
      fieldName: string;
      fieldType: GQBusinessActivityTypeFieldType;
      fieldValueOneOf: any | null;
      isRequired: boolean;
      isProratable: boolean;
      unitFieldName: string | null;
    }>;
  }>;
};

export type GQBusinessActivityTypesPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQBusinessActivityTypesPageQuery = {
  __typename?: 'Query';
  businessActivityTypeVersionLive: {
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
    recordStartAt: Date;
    recordEndAt: Date | null;
  };
};

export type GQCalculationTagsWithUsageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQCalculationTagsWithUsageQuery = {
  __typename?: 'Query';
  calculationTagsWithUsage: Array<{
    __typename?: 'CalculationTagWithUsage';
    tag: {
      __typename?: 'CalculationTag';
      id: string;
      name: string;
      createdAt: Date;
      updatedAt: Date;
    };
    referenceDataSources: Array<{
      __typename?: 'ReferenceDataSource';
      id: string;
      name: string;
      updatedAt: Date;
      externalSourceNotes: string | null;
    }>;
  }>;
};

export type GQUpdateCalculationTagMutationVariables = Exact<{
  input: GQUpdateCalculationTagInput;
}>;

export type GQUpdateCalculationTagMutation = {
  __typename?: 'Mutation';
  updateCalculationTag: {
    __typename?: 'UpdateCalculationTagPayload';
    success: boolean;
  };
};

export type GQDeleteCalculationTagMutationVariables = Exact<{
  input: GQDeleteCalculationTagInput;
}>;

export type GQDeleteCalculationTagMutation = {
  __typename?: 'Mutation';
  deleteCalculationTag: {
    __typename?: 'DeleteCalculationTagPayload';
    success: boolean;
  };
};

export type GQCalculationTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GQCalculationTagsQuery = {
  __typename?: 'Query';
  calculationTags: Array<{
    __typename?: 'CalculationTag';
    name: string;
    id: string;
    createdAt: Date;
    updatedAt: Date;
  }>;
};

export type GQInstructionsStepBaseFieldsFragment = {
  __typename?: 'InstructionsStep';
  descriptionMd: string;
  fileMetadataId: string | null;
  remotePath: string | null;
  publicUrl: string | null;
};

export type GQSdiInstructionsStepBaseFieldsFragment = {
  __typename?: 'SdiInstructionsStep';
  title: string;
  descriptionMd: string;
};

export type GQSdiInstructionsFaqStepBaseFieldsFragment = {
  __typename?: 'SdiInstructionsFaqType';
  question: string;
  answerMd: string;
};

export type GQInstructionsBundleBaseFieldsFragment = {
  __typename?: 'InstructionsBundle';
  instructionsDataFormattingMd: string | null;
  instructionsIntroMd: string | null;
  sdiInstructionsDataFormattingMd: string | null;
  sdiInstructionsIntroMd: string | null;
  ghgScopes: Array<GQGhgScope> | null;
  usedFor: string | null;
  potentialOverlaps: string | null;
  instructionsSteps: Array<{
    __typename?: 'InstructionsStep';
    descriptionMd: string;
    fileMetadataId: string | null;
    remotePath: string | null;
    publicUrl: string | null;
  }> | null;
  sdiInstructionsSteps: Array<{
    __typename?: 'SdiInstructionsStep';
    title: string;
    descriptionMd: string;
  }> | null;
  sdiInstructionsFaq: Array<{
    __typename?: 'SdiInstructionsFaqType';
    question: string;
    answerMd: string;
  }> | null;
};

export type GQCanonicalDatasetBaseFieldsFragment = {
  __typename?: 'CanonicalDataset';
  id: string;
  name: string;
  description: string | null;
  usedFor: string | null;
  potentialOverlaps: string | null;
  kind: GQCanonicalDatasetKind;
  selfServeDisabledReason: string | null;
  instructions: {
    __typename?: 'InstructionsBundle';
    instructionsDataFormattingMd: string | null;
    instructionsIntroMd: string | null;
    sdiInstructionsDataFormattingMd: string | null;
    sdiInstructionsIntroMd: string | null;
    ghgScopes: Array<GQGhgScope> | null;
    usedFor: string | null;
    potentialOverlaps: string | null;
    instructionsSteps: Array<{
      __typename?: 'InstructionsStep';
      descriptionMd: string;
      fileMetadataId: string | null;
      remotePath: string | null;
      publicUrl: string | null;
    }> | null;
    sdiInstructionsSteps: Array<{
      __typename?: 'SdiInstructionsStep';
      title: string;
      descriptionMd: string;
    }> | null;
    sdiInstructionsFaq: Array<{
      __typename?: 'SdiInstructionsFaqType';
      question: string;
      answerMd: string;
    }> | null;
  } | null;
};

export type GQCanonicalDatasourceBaseFieldsFragment = {
  __typename?: 'CanonicalDatasource';
  id: string;
  name: string;
  instructions: {
    __typename?: 'InstructionsBundle';
    instructionsDataFormattingMd: string | null;
    instructionsIntroMd: string | null;
    sdiInstructionsDataFormattingMd: string | null;
    sdiInstructionsIntroMd: string | null;
    ghgScopes: Array<GQGhgScope> | null;
    usedFor: string | null;
    potentialOverlaps: string | null;
    instructionsSteps: Array<{
      __typename?: 'InstructionsStep';
      descriptionMd: string;
      fileMetadataId: string | null;
      remotePath: string | null;
      publicUrl: string | null;
    }> | null;
    sdiInstructionsSteps: Array<{
      __typename?: 'SdiInstructionsStep';
      title: string;
      descriptionMd: string;
    }> | null;
    sdiInstructionsFaq: Array<{
      __typename?: 'SdiInstructionsFaqType';
      question: string;
      answerMd: string;
    }> | null;
  } | null;
};

export type GQIntegrationPartnerBaseFieldsFragment = {
  __typename?: 'IntegrationPartner';
  id: string;
  name: string;
};

export type GQGetCanonicalDatasetsWithCanonicalDatasourcesQueryVariables =
  Exact<{ [key: string]: never }>;

export type GQGetCanonicalDatasetsWithCanonicalDatasourcesQuery = {
  __typename?: 'Query';
  canonicalDatasets: Array<{
    __typename?: 'CanonicalDataset';
    id: string;
    name: string;
    description: string | null;
    usedFor: string | null;
    potentialOverlaps: string | null;
    kind: GQCanonicalDatasetKind;
    selfServeDisabledReason: string | null;
    canonicalDatasources: Array<{
      __typename?: 'CanonicalDatasource';
      id: string;
      name: string;
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsDataFormattingMd: string | null;
        instructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        usedFor: string | null;
        potentialOverlaps: string | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          fileMetadataId: string | null;
          remotePath: string | null;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
    }>;
    instructions: {
      __typename?: 'InstructionsBundle';
      instructionsDataFormattingMd: string | null;
      instructionsIntroMd: string | null;
      sdiInstructionsDataFormattingMd: string | null;
      sdiInstructionsIntroMd: string | null;
      ghgScopes: Array<GQGhgScope> | null;
      usedFor: string | null;
      potentialOverlaps: string | null;
      instructionsSteps: Array<{
        __typename?: 'InstructionsStep';
        descriptionMd: string;
        fileMetadataId: string | null;
        remotePath: string | null;
        publicUrl: string | null;
      }> | null;
      sdiInstructionsSteps: Array<{
        __typename?: 'SdiInstructionsStep';
        title: string;
        descriptionMd: string;
      }> | null;
      sdiInstructionsFaq: Array<{
        __typename?: 'SdiInstructionsFaqType';
        question: string;
        answerMd: string;
      }> | null;
    } | null;
  }>;
  allIntegrationPartners: Array<{
    __typename?: 'IntegrationPartner';
    id: string;
    name: string;
  }>;
};

export type GQCreateCanonicalDatasetMutationVariables = Exact<{
  input: GQCreateCanonicalDatasetInput;
}>;

export type GQCreateCanonicalDatasetMutation = {
  __typename?: 'Mutation';
  createCanonicalDataset: {
    __typename?: 'CanonicalDatasetPayload';
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      name: string;
      description: string | null;
      usedFor: string | null;
      potentialOverlaps: string | null;
      kind: GQCanonicalDatasetKind;
      selfServeDisabledReason: string | null;
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsDataFormattingMd: string | null;
        instructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        usedFor: string | null;
        potentialOverlaps: string | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          fileMetadataId: string | null;
          remotePath: string | null;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
    };
  } | null;
};

export type GQCreateCanonicalDatasourceMutationVariables = Exact<{
  input: GQCreateCanonicalDatasourceInput;
}>;

export type GQCreateCanonicalDatasourceMutation = {
  __typename?: 'Mutation';
  createCanonicalDatasource: {
    __typename?: 'CanonicalDatasourcePayload';
    canonicalDatasource: {
      __typename?: 'CanonicalDatasource';
      id: string;
      name: string;
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsDataFormattingMd: string | null;
        instructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        usedFor: string | null;
        potentialOverlaps: string | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          fileMetadataId: string | null;
          remotePath: string | null;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
    };
  } | null;
};

export type GQUpdateCanonicalDatasetMutationVariables = Exact<{
  input: GQUpdateCanonicalDatasetInput;
}>;

export type GQUpdateCanonicalDatasetMutation = {
  __typename?: 'Mutation';
  updateCanonicalDataset: {
    __typename?: 'CanonicalDatasetPayload';
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      name: string;
      description: string | null;
      usedFor: string | null;
      potentialOverlaps: string | null;
      kind: GQCanonicalDatasetKind;
      selfServeDisabledReason: string | null;
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsDataFormattingMd: string | null;
        instructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        usedFor: string | null;
        potentialOverlaps: string | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          fileMetadataId: string | null;
          remotePath: string | null;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
    };
  } | null;
};

export type GQUpdateCanonicalDatasourceMutationVariables = Exact<{
  input: GQUpdateCanonicalDatasourceInput;
}>;

export type GQUpdateCanonicalDatasourceMutation = {
  __typename?: 'Mutation';
  updateCanonicalDatasource: {
    __typename?: 'CanonicalDatasourcePayload';
    canonicalDatasource: {
      __typename?: 'CanonicalDatasource';
      id: string;
      name: string;
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsDataFormattingMd: string | null;
        instructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        usedFor: string | null;
        potentialOverlaps: string | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          fileMetadataId: string | null;
          remotePath: string | null;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
    };
  } | null;
};

export type GQCanonicalProjectRequirementDatasetFieldsFragment = {
  __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
  id: string;
  additionalNotesMd: string | null;
  canonicalDataset: {
    __typename?: 'CanonicalDataset';
    id: string;
    name: string;
  };
};

export type GQCanonicalProjectRequirementGhgpFieldsFragment = {
  __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
  id: string;
  ghgScope: number;
  ghgScope3Category: number | null;
  minimumFidelity: GQClimateProgramProjectRequirementFidelity;
  additionalNotesMd: string | null;
};

export type GQCanonicalClimateProgramProjectPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQCanonicalClimateProgramProjectPageQuery = {
  __typename?: 'Query';
  canonicalClimateProgramProject: {
    __typename?: 'CanonicalClimateProgramProject';
    id: string;
    name: string;
    description: string;
    hidden: boolean | null;
    hiddenFromMeasurement: boolean | null;
    kind: GQClimateProgramProjectKind;
    timeEstimate: string | null;
    costEstimate: string | null;
    iconTypeOverride: GQClimateProgramProjectIconType | null;
    iconType: GQClimateProgramProjectIconType;
    measurementObjectCopy: string | null;
    matchObjectType: string | null;
    commitmentType: string | null;
    reportKind: string | null;
    routeType: string | null;
    datasetRequirements: Array<{
      __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
      id: string;
      additionalNotesMd: string | null;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      };
    }>;
    ghgpRequirements: Array<{
      __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
      id: string;
      ghgScope: number;
      ghgScope3Category: number | null;
      minimumFidelity: GQClimateProgramProjectRequirementFidelity;
      additionalNotesMd: string | null;
    }>;
  };
};

export type GQDeleteCanonicalClimateProgramProjectRequirementDatasetMutationVariables =
  Exact<{
    input: GQDeleteCanonicalClimateProgramProjectRequirementDatasetInput;
  }>;

export type GQDeleteCanonicalClimateProgramProjectRequirementDatasetMutation = {
  __typename?: 'Mutation';
  deleteCanonicalClimateProgramProjectRequirementDataset: {
    __typename?: 'CanonicalClimateProgramProjectPayload';
    canonicalClimateProgramProject: {
      __typename?: 'CanonicalClimateProgramProject';
      id: string;
      name: string;
      description: string;
      hidden: boolean | null;
      hiddenFromMeasurement: boolean | null;
      kind: GQClimateProgramProjectKind;
      timeEstimate: string | null;
      costEstimate: string | null;
      iconTypeOverride: GQClimateProgramProjectIconType | null;
      iconType: GQClimateProgramProjectIconType;
      measurementObjectCopy: string | null;
      matchObjectType: string | null;
      commitmentType: string | null;
      reportKind: string | null;
      routeType: string | null;
      datasetRequirements: Array<{
        __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
        id: string;
        additionalNotesMd: string | null;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          name: string;
        };
      }>;
      ghgpRequirements: Array<{
        __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
        id: string;
        ghgScope: number;
        ghgScope3Category: number | null;
        minimumFidelity: GQClimateProgramProjectRequirementFidelity;
        additionalNotesMd: string | null;
      }>;
    };
  };
};

export type GQDeleteCanonicalClimateProgramProjectRequirementGhgpMutationVariables =
  Exact<{
    input: GQDeleteCanonicalClimateProgramProjectRequirementGhgpInput;
  }>;

export type GQDeleteCanonicalClimateProgramProjectRequirementGhgpMutation = {
  __typename?: 'Mutation';
  deleteCanonicalClimateProgramProjectRequirementGhgp: {
    __typename?: 'CanonicalClimateProgramProjectPayload';
    canonicalClimateProgramProject: {
      __typename?: 'CanonicalClimateProgramProject';
      id: string;
      name: string;
      description: string;
      hidden: boolean | null;
      hiddenFromMeasurement: boolean | null;
      kind: GQClimateProgramProjectKind;
      timeEstimate: string | null;
      costEstimate: string | null;
      iconTypeOverride: GQClimateProgramProjectIconType | null;
      iconType: GQClimateProgramProjectIconType;
      measurementObjectCopy: string | null;
      matchObjectType: string | null;
      commitmentType: string | null;
      reportKind: string | null;
      routeType: string | null;
      ghgpRequirements: Array<{
        __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
        id: string;
        ghgScope: number;
        ghgScope3Category: number | null;
        minimumFidelity: GQClimateProgramProjectRequirementFidelity;
        additionalNotesMd: string | null;
      }>;
      datasetRequirements: Array<{
        __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
        id: string;
        additionalNotesMd: string | null;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          name: string;
        };
      }>;
    };
  };
};

export type GQCanonicalClimateProgramProjectFieldsFragment = {
  __typename?: 'CanonicalClimateProgramProject';
  id: string;
  name: string;
  description: string;
  hidden: boolean | null;
  hiddenFromMeasurement: boolean | null;
  kind: GQClimateProgramProjectKind;
  timeEstimate: string | null;
  costEstimate: string | null;
  iconTypeOverride: GQClimateProgramProjectIconType | null;
  iconType: GQClimateProgramProjectIconType;
  measurementObjectCopy: string | null;
  matchObjectType: string | null;
  commitmentType: string | null;
  reportKind: string | null;
  routeType: string | null;
  datasetRequirements: Array<{
    __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
    id: string;
    additionalNotesMd: string | null;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      name: string;
    };
  }>;
  ghgpRequirements: Array<{
    __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
    id: string;
    ghgScope: number;
    ghgScope3Category: number | null;
    minimumFidelity: GQClimateProgramProjectRequirementFidelity;
    additionalNotesMd: string | null;
  }>;
};

export type GQCanonicalClimateProgramProjectsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQCanonicalClimateProgramProjectsQuery = {
  __typename?: 'Query';
  canonicalClimateProgramProjects: Array<{
    __typename?: 'CanonicalClimateProgramProject';
    id: string;
    name: string;
    description: string;
    hidden: boolean | null;
    hiddenFromMeasurement: boolean | null;
    kind: GQClimateProgramProjectKind;
    timeEstimate: string | null;
    costEstimate: string | null;
    iconTypeOverride: GQClimateProgramProjectIconType | null;
    iconType: GQClimateProgramProjectIconType;
    measurementObjectCopy: string | null;
    matchObjectType: string | null;
    commitmentType: string | null;
    reportKind: string | null;
    routeType: string | null;
    datasetRequirements: Array<{
      __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
      id: string;
      additionalNotesMd: string | null;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      };
    }>;
    ghgpRequirements: Array<{
      __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
      id: string;
      ghgScope: number;
      ghgScope3Category: number | null;
      minimumFidelity: GQClimateProgramProjectRequirementFidelity;
      additionalNotesMd: string | null;
    }>;
  }>;
};

export type GQDeleteCanonicalClimateProgramProjectMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQDeleteCanonicalClimateProgramProjectMutation = {
  __typename?: 'Mutation';
  deleteCanonicalClimateProgramProject: {
    __typename?: 'DeleteCanonicalClimateProgramProjectPayload';
    id: string;
  };
};

export type GQPublicDisclosureForAdminEditFragment = {
  __typename?: 'PublicDisclosure';
  id: string;
  reportType: GQExternalReportType;
  companyId: string | null;
  publishingYear: number;
  reportingYears: Array<number>;
  publicUrl: string;
  qualityScore: GQDisclosureQualityScore | null;
  externalScore: string | null;
  gsUrl: string | null;
  source: GQPublicDisclosureSource;
  cdpVendorData: {
    __typename?: 'CdpVendorData';
    id: string;
    publishingYear: number;
    reportingYear: number;
    scope1Nonzero: boolean;
    scope2Nonzero: boolean;
    scope3Nonzero: boolean;
    totalEmissionsNonzero: boolean;
    scope301Or302Nonzero: boolean;
    scope1Verified: boolean;
    scope2Verified: boolean;
    scope3Verified: boolean;
    pctEvaluationStatusesMatchResponse: number;
    disclosureQualityScore: GQDisclosureQualityScore;
    scope301EvaluationStatus: GQScope3EvaluationStatus;
    scope302EvaluationStatus: GQScope3EvaluationStatus;
    scope303EvaluationStatus: GQScope3EvaluationStatus;
    scope304EvaluationStatus: GQScope3EvaluationStatus;
    scope305EvaluationStatus: GQScope3EvaluationStatus;
    scope306EvaluationStatus: GQScope3EvaluationStatus;
    scope307EvaluationStatus: GQScope3EvaluationStatus;
    scope308EvaluationStatus: GQScope3EvaluationStatus;
    scope309EvaluationStatus: GQScope3EvaluationStatus;
    scope310EvaluationStatus: GQScope3EvaluationStatus;
    scope311EvaluationStatus: GQScope3EvaluationStatus;
    scope312EvaluationStatus: GQScope3EvaluationStatus;
    scope313EvaluationStatus: GQScope3EvaluationStatus;
    scope314EvaluationStatus: GQScope3EvaluationStatus;
    scope315EvaluationStatus: GQScope3EvaluationStatus;
    scope316EvaluationStatus: GQScope3EvaluationStatus;
    scope317EvaluationStatus: GQScope3EvaluationStatus;
  } | null;
};

export type GQDisclosureForAdminEditFragment = {
  __typename?: 'Disclosure';
  id: string;
  publicDisclosure: {
    __typename?: 'PublicDisclosure';
    id: string;
    reportType: GQExternalReportType;
    companyId: string | null;
    publishingYear: number;
    reportingYears: Array<number>;
    publicUrl: string;
    qualityScore: GQDisclosureQualityScore | null;
    externalScore: string | null;
    gsUrl: string | null;
    source: GQPublicDisclosureSource;
    cdpVendorData: {
      __typename?: 'CdpVendorData';
      id: string;
      publishingYear: number;
      reportingYear: number;
      scope1Nonzero: boolean;
      scope2Nonzero: boolean;
      scope3Nonzero: boolean;
      totalEmissionsNonzero: boolean;
      scope301Or302Nonzero: boolean;
      scope1Verified: boolean;
      scope2Verified: boolean;
      scope3Verified: boolean;
      pctEvaluationStatusesMatchResponse: number;
      disclosureQualityScore: GQDisclosureQualityScore;
      scope301EvaluationStatus: GQScope3EvaluationStatus;
      scope302EvaluationStatus: GQScope3EvaluationStatus;
      scope303EvaluationStatus: GQScope3EvaluationStatus;
      scope304EvaluationStatus: GQScope3EvaluationStatus;
      scope305EvaluationStatus: GQScope3EvaluationStatus;
      scope306EvaluationStatus: GQScope3EvaluationStatus;
      scope307EvaluationStatus: GQScope3EvaluationStatus;
      scope308EvaluationStatus: GQScope3EvaluationStatus;
      scope309EvaluationStatus: GQScope3EvaluationStatus;
      scope310EvaluationStatus: GQScope3EvaluationStatus;
      scope311EvaluationStatus: GQScope3EvaluationStatus;
      scope312EvaluationStatus: GQScope3EvaluationStatus;
      scope313EvaluationStatus: GQScope3EvaluationStatus;
      scope314EvaluationStatus: GQScope3EvaluationStatus;
      scope315EvaluationStatus: GQScope3EvaluationStatus;
      scope316EvaluationStatus: GQScope3EvaluationStatus;
      scope317EvaluationStatus: GQScope3EvaluationStatus;
    } | null;
  } | null;
  privateDisclosure: {
    __typename?: 'PrivateDisclosure';
    id: string;
    privateDisclosureType: GQPrivateDisclosureType;
    companyId: string | null;
    disclosureQualityScore: GQDisclosureQualityScore | null;
  } | null;
  historicalEmissionsYears: Array<{
    __typename?: 'EmissionsYear';
    id: string;
    createdAt: Date;
    publicDisclosureId: string | null;
    privateDisclosureId: string | null;
    footprintEstimateOutputId: string | null;
    buildingEstimateOutputId: string | null;
    assetCorporateId: string | null;
    expenseCategory: string | null;
    reportingYear: number;
    numEmployees: number | null;
    revenue: number | null;
    revenueCurrency: string | null;
    currencyConversionRate: number | null;
    scope1: number | null;
    scope2: number | null;
    scope2Market: number | null;
    scope2Location: number | null;
    scope3: number | null;
    scope301: number | null;
    scope302: number | null;
    scope303: number | null;
    scope304: number | null;
    scope305: number | null;
    scope306: number | null;
    scope307: number | null;
    scope308: number | null;
    scope309: number | null;
    scope310: number | null;
    scope311: number | null;
    scope312: number | null;
    scope313: number | null;
    scope314: number | null;
    scope315: number | null;
    scope316: number | null;
    scope317: number | null;
    units: GQCompanyEmissionsUnits;
    percentageCleanEnergy: number | null;
    totalMwh: number | null;
    orgId: string | null;
    scenario: string | null;
    updatedAt: Date;
    pcafScoreScope1: number | null;
  }> | null;
  climateCommitments: Array<
    | {
        __typename: 'CarbonNeutralCommitment';
        id: string;
        targetYear: number | null;
        kind: GQCompanyClimateCommitmentKind;
        description: string | null;
        externalUrl: string | null;
        commitmentMadeDate: Date | null;
        commitmentPeriodStart: Date | null;
        commitmentPeriodEnd: Date | null;
      }
    | {
        __typename: 'CleanEnergyCommitment';
        id: string;
        targetYear: number | null;
        targetPercentageCleanEnergy: number | null;
        kind: GQCompanyClimateCommitmentKind;
        description: string | null;
        externalUrl: string | null;
        commitmentMadeDate: Date | null;
        commitmentPeriodStart: Date | null;
        commitmentPeriodEnd: Date | null;
      }
    | {
        __typename: 'NetZeroCommitment';
        id: string;
        targetYear: number | null;
        kind: GQCompanyClimateCommitmentKind;
        description: string | null;
        externalUrl: string | null;
        commitmentMadeDate: Date | null;
        commitmentPeriodStart: Date | null;
        commitmentPeriodEnd: Date | null;
      }
    | {
        __typename: 'ScienceBasedTargetCommitment';
        submittedToSBTi: boolean;
        id: string;
        kind: GQCompanyClimateCommitmentKind;
        description: string | null;
        externalUrl: string | null;
        commitmentMadeDate: Date | null;
        commitmentPeriodStart: Date | null;
        commitmentPeriodEnd: Date | null;
        commitment: {
          __typename?: 'SBTCommitment';
          id: string;
          nearTermTargetYear: number | null;
          longTermTargetYear: number | null;
          baselineYear: number | null;
          stage: GQSbtCommitmentStage | null;
          targetClassification: GQSbtTargetClassification | null;
          netZeroTargetYear: number | null;
          commitmentDeadline: Date | null;
          commitmentType: GQSbtCommitmentType | null;
          netZeroCommitted: boolean | null;
        };
      }
  > | null;
};

export type GQUnifiedPublicCompanyDisclosuresPageQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type GQUnifiedPublicCompanyDisclosuresPageQuery = {
  __typename?: 'Query';
  company: {
    __typename?: 'Company';
    id: string;
    name: string;
    countryAlpha2: string | null;
    naicsCode: string | null;
  };
};

export type GQCompanyMatchFieldsFragment = {
  __typename?: 'CompanyMatch';
  id: string;
  score: GQCompanyMatchScore;
  nameMatch: string | null;
  beaMatch: string | null;
  leiMatch: string | null;
  isinMatch: string | null;
  tickerMatch: string | null;
  countryMatch: string | null;
  sAndPIdMatch: string | null;
  company: {
    __typename: 'Company';
    id: string;
    name: string;
    replacedBy: { __typename: 'Company'; id: string; name: string } | null;
  };
};

export type GQCompanyFieldsWithMatchesFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
  description: string | null;
  isDemo: boolean;
  createdAt: Date;
  updatedAt: Date;
  bestMatchesForCompany: Array<{
    __typename?: 'CompanyMatch';
    id: string;
    score: GQCompanyMatchScore;
    nameMatch: string | null;
    beaMatch: string | null;
    leiMatch: string | null;
    isinMatch: string | null;
    tickerMatch: string | null;
    countryMatch: string | null;
    sAndPIdMatch: string | null;
    company: {
      __typename: 'Company';
      id: string;
      name: string;
      replacedBy: { __typename: 'Company'; id: string; name: string } | null;
    };
  }>;
  names: Array<{
    __typename?: 'CompanyName';
    id: string;
    name: string;
    cleanedName: string | null;
    isPrimary: boolean;
    sourceId: string;
  }>;
  isins: Array<{
    __typename?: 'CompanyIsin';
    id: string;
    isin: string;
    sourceId: string;
  }>;
  beas: Array<{
    __typename?: 'CompanyBea';
    id: string;
    bea: string;
    sourceId: string;
  }>;
  leis: Array<{
    __typename?: 'CompanyLei';
    id: string;
    lei: string;
    sourceId: string;
  }>;
  tickerSymbols: Array<{
    __typename?: 'CompanyTickerSymbol';
    id: string;
    tickerSymbol: string;
    exchangeSymbol: string | null;
    isSourcePrimary: boolean;
    sourceId: string;
  }>;
  sAndP: {
    __typename?: 'CompanySAndP';
    id: string;
    sAndPId: string;
    sourceId: string;
  } | null;
  countries: Array<{
    __typename?: 'CompanyCountry';
    id: string;
    countryAlpha2: string;
    isPrimary: boolean;
    sourceId: string;
  }>;
  disclosures: Array<{
    __typename?: 'Disclosure';
    id: string;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      reportType: GQExternalReportType;
      companyId: string | null;
      publishingYear: number;
      reportingYears: Array<number>;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      externalScore: string | null;
      gsUrl: string | null;
      source: GQPublicDisclosureSource;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      createdAt: Date;
      publicDisclosureId: string | null;
      privateDisclosureId: string | null;
      footprintEstimateOutputId: string | null;
      buildingEstimateOutputId: string | null;
      assetCorporateId: string | null;
      expenseCategory: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      currencyConversionRate: number | null;
      scope1: number | null;
      scope2: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
      units: GQCompanyEmissionsUnits;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      orgId: string | null;
      scenario: string | null;
      updatedAt: Date;
      pcafScoreScope1: number | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename: 'CarbonNeutralCommitment';
          id: string;
          targetYear: number | null;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename: 'CleanEnergyCommitment';
          id: string;
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename: 'NetZeroCommitment';
          id: string;
          targetYear: number | null;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            netZeroTargetYear: number | null;
            commitmentDeadline: Date | null;
            commitmentType: GQSbtCommitmentType | null;
            netZeroCommitted: boolean | null;
          };
        }
    > | null;
  }>;
  logo: {
    __typename?: 'CompanyUrl';
    id: string;
    url: string;
    sourceId: string;
    source: { __typename?: 'CompanySourceKey'; id: string; sourceKey: string };
  } | null;
  companyWebsite: {
    __typename?: 'CompanyUrl';
    id: string;
    url: string;
    sourceId: string;
    source: { __typename?: 'CompanySourceKey'; id: string; sourceKey: string };
  } | null;
  sustainabilityWebsite: {
    __typename?: 'CompanyUrl';
    id: string;
    url: string;
    sourceId: string;
    source: { __typename?: 'CompanySourceKey'; id: string; sourceKey: string };
  } | null;
  editor: { __typename?: 'User'; id: string; name: string } | null;
  sourceKey: {
    __typename?: 'CompanySourceKey';
    id: string;
    sourceKey: string;
    sourceUrl: string | null;
    sourceNotes: string | null;
    priority: number;
    userVisibleSourceUrl: string | null;
    userVisibleSourceNotes: string | null;
    createdAt: Date;
    updatedAt: Date;
    editor: { __typename?: 'User'; id: string; name: string } | null;
  } | null;
  replacedBy: { __typename: 'Company'; id: string; name: string } | null;
};

export type GQGetCompanyForAdminQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetCompanyForAdminQuery = {
  __typename?: 'Query';
  company: {
    __typename?: 'Company';
    id: string;
    name: string;
    description: string | null;
    isDemo: boolean;
    createdAt: Date;
    updatedAt: Date;
    names: Array<{
      __typename?: 'CompanyName';
      id: string;
      name: string;
      cleanedName: string | null;
      isPrimary: boolean;
      sourceId: string;
    }>;
    isins: Array<{
      __typename?: 'CompanyIsin';
      id: string;
      isin: string;
      sourceId: string;
    }>;
    beas: Array<{
      __typename?: 'CompanyBea';
      id: string;
      bea: string;
      sourceId: string;
    }>;
    leis: Array<{
      __typename?: 'CompanyLei';
      id: string;
      lei: string;
      sourceId: string;
    }>;
    tickerSymbols: Array<{
      __typename?: 'CompanyTickerSymbol';
      id: string;
      tickerSymbol: string;
      exchangeSymbol: string | null;
      isSourcePrimary: boolean;
      sourceId: string;
    }>;
    sAndP: {
      __typename?: 'CompanySAndP';
      id: string;
      sAndPId: string;
      sourceId: string;
    } | null;
    countries: Array<{
      __typename?: 'CompanyCountry';
      id: string;
      countryAlpha2: string;
      isPrimary: boolean;
      sourceId: string;
    }>;
    disclosures: Array<{
      __typename?: 'Disclosure';
      id: string;
      publicDisclosure: {
        __typename?: 'PublicDisclosure';
        id: string;
        reportType: GQExternalReportType;
        companyId: string | null;
        publishingYear: number;
        reportingYears: Array<number>;
        publicUrl: string;
        qualityScore: GQDisclosureQualityScore | null;
        externalScore: string | null;
        gsUrl: string | null;
        source: GQPublicDisclosureSource;
        cdpVendorData: {
          __typename?: 'CdpVendorData';
          id: string;
          publishingYear: number;
          reportingYear: number;
          scope1Nonzero: boolean;
          scope2Nonzero: boolean;
          scope3Nonzero: boolean;
          totalEmissionsNonzero: boolean;
          scope301Or302Nonzero: boolean;
          scope1Verified: boolean;
          scope2Verified: boolean;
          scope3Verified: boolean;
          pctEvaluationStatusesMatchResponse: number;
          disclosureQualityScore: GQDisclosureQualityScore;
          scope301EvaluationStatus: GQScope3EvaluationStatus;
          scope302EvaluationStatus: GQScope3EvaluationStatus;
          scope303EvaluationStatus: GQScope3EvaluationStatus;
          scope304EvaluationStatus: GQScope3EvaluationStatus;
          scope305EvaluationStatus: GQScope3EvaluationStatus;
          scope306EvaluationStatus: GQScope3EvaluationStatus;
          scope307EvaluationStatus: GQScope3EvaluationStatus;
          scope308EvaluationStatus: GQScope3EvaluationStatus;
          scope309EvaluationStatus: GQScope3EvaluationStatus;
          scope310EvaluationStatus: GQScope3EvaluationStatus;
          scope311EvaluationStatus: GQScope3EvaluationStatus;
          scope312EvaluationStatus: GQScope3EvaluationStatus;
          scope313EvaluationStatus: GQScope3EvaluationStatus;
          scope314EvaluationStatus: GQScope3EvaluationStatus;
          scope315EvaluationStatus: GQScope3EvaluationStatus;
          scope316EvaluationStatus: GQScope3EvaluationStatus;
          scope317EvaluationStatus: GQScope3EvaluationStatus;
        } | null;
      } | null;
      historicalEmissionsYears: Array<{
        __typename?: 'EmissionsYear';
        id: string;
        createdAt: Date;
        publicDisclosureId: string | null;
        privateDisclosureId: string | null;
        footprintEstimateOutputId: string | null;
        buildingEstimateOutputId: string | null;
        assetCorporateId: string | null;
        expenseCategory: string | null;
        reportingYear: number;
        numEmployees: number | null;
        revenue: number | null;
        revenueCurrency: string | null;
        currencyConversionRate: number | null;
        scope1: number | null;
        scope2: number | null;
        scope2Market: number | null;
        scope2Location: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
        units: GQCompanyEmissionsUnits;
        percentageCleanEnergy: number | null;
        totalMwh: number | null;
        orgId: string | null;
        scenario: string | null;
        updatedAt: Date;
        pcafScoreScope1: number | null;
      }> | null;
      climateCommitments: Array<
        | {
            __typename: 'CarbonNeutralCommitment';
            id: string;
            targetYear: number | null;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
          }
        | {
            __typename: 'CleanEnergyCommitment';
            id: string;
            targetYear: number | null;
            targetPercentageCleanEnergy: number | null;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
          }
        | {
            __typename: 'NetZeroCommitment';
            id: string;
            targetYear: number | null;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
          }
        | {
            __typename: 'ScienceBasedTargetCommitment';
            submittedToSBTi: boolean;
            id: string;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
            commitment: {
              __typename?: 'SBTCommitment';
              id: string;
              nearTermTargetYear: number | null;
              longTermTargetYear: number | null;
              baselineYear: number | null;
              stage: GQSbtCommitmentStage | null;
              targetClassification: GQSbtTargetClassification | null;
              netZeroTargetYear: number | null;
              commitmentDeadline: Date | null;
              commitmentType: GQSbtCommitmentType | null;
              netZeroCommitted: boolean | null;
            };
          }
      > | null;
    }>;
    logo: {
      __typename?: 'CompanyUrl';
      id: string;
      url: string;
      sourceId: string;
      source: {
        __typename?: 'CompanySourceKey';
        id: string;
        sourceKey: string;
      };
    } | null;
    companyWebsite: {
      __typename?: 'CompanyUrl';
      id: string;
      url: string;
      sourceId: string;
      source: {
        __typename?: 'CompanySourceKey';
        id: string;
        sourceKey: string;
      };
    } | null;
    sustainabilityWebsite: {
      __typename?: 'CompanyUrl';
      id: string;
      url: string;
      sourceId: string;
      source: {
        __typename?: 'CompanySourceKey';
        id: string;
        sourceKey: string;
      };
    } | null;
    editor: { __typename?: 'User'; id: string; name: string } | null;
    sourceKey: {
      __typename?: 'CompanySourceKey';
      id: string;
      sourceKey: string;
      sourceUrl: string | null;
      sourceNotes: string | null;
      priority: number;
      userVisibleSourceUrl: string | null;
      userVisibleSourceNotes: string | null;
      createdAt: Date;
      updatedAt: Date;
      editor: { __typename?: 'User'; id: string; name: string } | null;
    } | null;
    replacedBy: { __typename: 'Company'; id: string; name: string } | null;
  };
};

export type GQGetCompanyMatchesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetCompanyMatchesQuery = {
  __typename?: 'Query';
  company: {
    __typename?: 'Company';
    id: string;
    bestMatchesForCompany: Array<{
      __typename?: 'CompanyMatch';
      id: string;
      score: GQCompanyMatchScore;
      nameMatch: string | null;
      beaMatch: string | null;
      leiMatch: string | null;
      isinMatch: string | null;
      tickerMatch: string | null;
      countryMatch: string | null;
      sAndPIdMatch: string | null;
      company: {
        __typename: 'Company';
        id: string;
        name: string;
        replacedBy: { __typename: 'Company'; id: string; name: string } | null;
      };
    }>;
  };
};

export type GQReplaceCompanyMutationVariables = Exact<{
  input: GQReplaceCompanyInput;
}>;

export type GQReplaceCompanyMutation = {
  __typename?: 'Mutation';
  replaceCompany: {
    __typename?: 'ReplaceCompanyPayload';
    success: boolean;
    message: string;
    fromCompany: { __typename?: 'Company'; id: string };
    toCompany: {
      __typename?: 'Company';
      id: string;
      name: string;
      description: string | null;
      isDemo: boolean;
      createdAt: Date;
      updatedAt: Date;
      bestMatchesForCompany: Array<{
        __typename?: 'CompanyMatch';
        id: string;
        score: GQCompanyMatchScore;
        nameMatch: string | null;
        beaMatch: string | null;
        leiMatch: string | null;
        isinMatch: string | null;
        tickerMatch: string | null;
        countryMatch: string | null;
        sAndPIdMatch: string | null;
        company: {
          __typename: 'Company';
          id: string;
          name: string;
          replacedBy: {
            __typename: 'Company';
            id: string;
            name: string;
          } | null;
        };
      }>;
      names: Array<{
        __typename?: 'CompanyName';
        id: string;
        name: string;
        cleanedName: string | null;
        isPrimary: boolean;
        sourceId: string;
      }>;
      isins: Array<{
        __typename?: 'CompanyIsin';
        id: string;
        isin: string;
        sourceId: string;
      }>;
      beas: Array<{
        __typename?: 'CompanyBea';
        id: string;
        bea: string;
        sourceId: string;
      }>;
      leis: Array<{
        __typename?: 'CompanyLei';
        id: string;
        lei: string;
        sourceId: string;
      }>;
      tickerSymbols: Array<{
        __typename?: 'CompanyTickerSymbol';
        id: string;
        tickerSymbol: string;
        exchangeSymbol: string | null;
        isSourcePrimary: boolean;
        sourceId: string;
      }>;
      sAndP: {
        __typename?: 'CompanySAndP';
        id: string;
        sAndPId: string;
        sourceId: string;
      } | null;
      countries: Array<{
        __typename?: 'CompanyCountry';
        id: string;
        countryAlpha2: string;
        isPrimary: boolean;
        sourceId: string;
      }>;
      disclosures: Array<{
        __typename?: 'Disclosure';
        id: string;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          reportType: GQExternalReportType;
          companyId: string | null;
          publishingYear: number;
          reportingYears: Array<number>;
          publicUrl: string;
          qualityScore: GQDisclosureQualityScore | null;
          externalScore: string | null;
          gsUrl: string | null;
          source: GQPublicDisclosureSource;
          cdpVendorData: {
            __typename?: 'CdpVendorData';
            id: string;
            publishingYear: number;
            reportingYear: number;
            scope1Nonzero: boolean;
            scope2Nonzero: boolean;
            scope3Nonzero: boolean;
            totalEmissionsNonzero: boolean;
            scope301Or302Nonzero: boolean;
            scope1Verified: boolean;
            scope2Verified: boolean;
            scope3Verified: boolean;
            pctEvaluationStatusesMatchResponse: number;
            disclosureQualityScore: GQDisclosureQualityScore;
            scope301EvaluationStatus: GQScope3EvaluationStatus;
            scope302EvaluationStatus: GQScope3EvaluationStatus;
            scope303EvaluationStatus: GQScope3EvaluationStatus;
            scope304EvaluationStatus: GQScope3EvaluationStatus;
            scope305EvaluationStatus: GQScope3EvaluationStatus;
            scope306EvaluationStatus: GQScope3EvaluationStatus;
            scope307EvaluationStatus: GQScope3EvaluationStatus;
            scope308EvaluationStatus: GQScope3EvaluationStatus;
            scope309EvaluationStatus: GQScope3EvaluationStatus;
            scope310EvaluationStatus: GQScope3EvaluationStatus;
            scope311EvaluationStatus: GQScope3EvaluationStatus;
            scope312EvaluationStatus: GQScope3EvaluationStatus;
            scope313EvaluationStatus: GQScope3EvaluationStatus;
            scope314EvaluationStatus: GQScope3EvaluationStatus;
            scope315EvaluationStatus: GQScope3EvaluationStatus;
            scope316EvaluationStatus: GQScope3EvaluationStatus;
            scope317EvaluationStatus: GQScope3EvaluationStatus;
          } | null;
        } | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          createdAt: Date;
          publicDisclosureId: string | null;
          privateDisclosureId: string | null;
          footprintEstimateOutputId: string | null;
          buildingEstimateOutputId: string | null;
          assetCorporateId: string | null;
          expenseCategory: string | null;
          reportingYear: number;
          numEmployees: number | null;
          revenue: number | null;
          revenueCurrency: string | null;
          currencyConversionRate: number | null;
          scope1: number | null;
          scope2: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
          units: GQCompanyEmissionsUnits;
          percentageCleanEnergy: number | null;
          totalMwh: number | null;
          orgId: string | null;
          scenario: string | null;
          updatedAt: Date;
          pcafScoreScope1: number | null;
        }> | null;
        climateCommitments: Array<
          | {
              __typename: 'CarbonNeutralCommitment';
              id: string;
              targetYear: number | null;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename: 'CleanEnergyCommitment';
              id: string;
              targetYear: number | null;
              targetPercentageCleanEnergy: number | null;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename: 'NetZeroCommitment';
              id: string;
              targetYear: number | null;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename: 'ScienceBasedTargetCommitment';
              submittedToSBTi: boolean;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
              commitment: {
                __typename?: 'SBTCommitment';
                id: string;
                nearTermTargetYear: number | null;
                longTermTargetYear: number | null;
                baselineYear: number | null;
                stage: GQSbtCommitmentStage | null;
                targetClassification: GQSbtTargetClassification | null;
                netZeroTargetYear: number | null;
                commitmentDeadline: Date | null;
                commitmentType: GQSbtCommitmentType | null;
                netZeroCommitted: boolean | null;
              };
            }
        > | null;
      }>;
      logo: {
        __typename?: 'CompanyUrl';
        id: string;
        url: string;
        sourceId: string;
        source: {
          __typename?: 'CompanySourceKey';
          id: string;
          sourceKey: string;
        };
      } | null;
      companyWebsite: {
        __typename?: 'CompanyUrl';
        id: string;
        url: string;
        sourceId: string;
        source: {
          __typename?: 'CompanySourceKey';
          id: string;
          sourceKey: string;
        };
      } | null;
      sustainabilityWebsite: {
        __typename?: 'CompanyUrl';
        id: string;
        url: string;
        sourceId: string;
        source: {
          __typename?: 'CompanySourceKey';
          id: string;
          sourceKey: string;
        };
      } | null;
      editor: { __typename?: 'User'; id: string; name: string } | null;
      sourceKey: {
        __typename?: 'CompanySourceKey';
        id: string;
        sourceKey: string;
        sourceUrl: string | null;
        sourceNotes: string | null;
        priority: number;
        userVisibleSourceUrl: string | null;
        userVisibleSourceNotes: string | null;
        createdAt: Date;
        updatedAt: Date;
        editor: { __typename?: 'User'; id: string; name: string } | null;
      } | null;
      replacedBy: { __typename: 'Company'; id: string; name: string } | null;
    };
  };
};

export type GQCompanySourceKeyFieldsFragment = {
  __typename?: 'CompanySourceKey';
  id: string;
  sourceKey: string;
  sourceUrl: string | null;
  sourceNotes: string | null;
  priority: number;
  userVisibleSourceUrl: string | null;
  userVisibleSourceNotes: string | null;
  createdAt: Date;
  updatedAt: Date;
  editor: { __typename?: 'User'; id: string; name: string } | null;
};

export type GQCompanySourceKeysPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQCompanySourceKeysPageQuery = {
  __typename?: 'Query';
  companySourceKeys: Array<{
    __typename?: 'CompanySourceKey';
    id: string;
    sourceKey: string;
    sourceUrl: string | null;
    sourceNotes: string | null;
    priority: number;
    userVisibleSourceUrl: string | null;
    userVisibleSourceNotes: string | null;
    createdAt: Date;
    updatedAt: Date;
    editor: { __typename?: 'User'; id: string; name: string } | null;
  }>;
};

export type GQCreateCompanySourceKeyMutationVariables = Exact<{
  input: GQCreateCompanySourceKeyInput;
}>;

export type GQCreateCompanySourceKeyMutation = {
  __typename?: 'Mutation';
  createCompanySourceKey: {
    __typename?: 'CompanySourceKeyPayload';
    companySourceKey: {
      __typename?: 'CompanySourceKey';
      id: string;
      sourceKey: string;
      sourceUrl: string | null;
      sourceNotes: string | null;
      priority: number;
      userVisibleSourceUrl: string | null;
      userVisibleSourceNotes: string | null;
      createdAt: Date;
      updatedAt: Date;
      editor: { __typename?: 'User'; id: string; name: string } | null;
    };
  };
};

export type GQUpdateCompanySourceKeyMutationVariables = Exact<{
  input: GQUpdateCompanySourceKeyInput;
}>;

export type GQUpdateCompanySourceKeyMutation = {
  __typename?: 'Mutation';
  updateCompanySourceKey: {
    __typename?: 'CompanySourceKeyPayload';
    companySourceKey: {
      __typename?: 'CompanySourceKey';
      id: string;
      sourceKey: string;
      sourceUrl: string | null;
      sourceNotes: string | null;
      priority: number;
      userVisibleSourceUrl: string | null;
      userVisibleSourceNotes: string | null;
      createdAt: Date;
      updatedAt: Date;
      editor: { __typename?: 'User'; id: string; name: string } | null;
    };
  };
};

export type GQDeleteCompanySourceKeyMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQDeleteCompanySourceKeyMutation = {
  __typename?: 'Mutation';
  deleteCompanySourceKey: {
    __typename?: 'DeleteCompanySourceKeyPayload';
    id: string;
  };
};

export type GQCustomerTargetSchemaQueryVariables = Exact<{
  ctsId: Scalars['ID']['input'];
}>;

export type GQCustomerTargetSchemaQuery = {
  __typename?: 'Query';
  customerTargetSchema: {
    __typename?: 'CustomerTargetSchema';
    id: string;
    name: string;
    descriptionMd: string | null;
    rank: number;
    schemaId: string | null;
    versions: Array<{
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      schemaJson: any;
      semverVersion: string;
      versionNotesMd: string;
      exampleData: any;
      formattingNotesMd: string | null;
      createdAt: Date;
      publishedAt: Date | null;
      publishedForTestingAt: Date | null;
      unpublishedAt: Date | null;
      schemaVersionId: string | null;
      schema: {
        __typename?: 'CustomerTargetSchema';
        id: string;
        name: string;
        versions: Array<{
          __typename?: 'CustomerTargetSchemaVersion';
          id: string;
          publishedAt: Date | null;
          publishedForTestingAt: Date | null;
          unpublishedAt: Date | null;
        }>;
      };
      globalIngestionExampleData: {
        __typename?: 'IngestionExampleData';
        id: string;
        title: string;
        datasetId: string | null;
        columns: Array<{
          __typename?: 'IngestionExampleDataColumn';
          fieldName: string;
          title: string;
          description: string;
          examples: Array<string>;
          required: boolean;
          optionalToMap: boolean | null;
          defaultValue: any | null;
        }>;
        rows: Array<{
          __typename?: 'IngestionExampleDataRow';
          fields: Array<{
            __typename?: 'IngestionExampleDataRowField';
            fieldName: string;
            value: string;
          }>;
        }>;
      };
      transformsIncludingDeleted: Array<{
        __typename?: 'CustomerTargetSchemaTransform';
        id: string;
        deletedAt: Date | null;
        sql: string;
        transformTarget: GQCustomerTargetSchemaTransformTarget;
        businessActivityTypeName: string | null;
        businessActivityTypeVersionId: string | null;
        businessActivityTypeVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
          name: string;
        } | null;
        runs: Array<{
          __typename?: 'CustomerTargetSchemaTransformRun';
          id: string;
          sql: string;
          error: string | null;
          createdAt: Date;
          transform: {
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            transformTarget: GQCustomerTargetSchemaTransformTarget;
            businessActivityTypeName: string | null;
          };
          input: {
            __typename?: 'FileMetadata';
            id: string;
            name: string;
            category: GQFileCategory;
            revisionRootFileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            } | null;
          };
          output: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          standardizedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          warnings: Array<{
            __typename?: 'ValidationWarning';
            blocking: boolean;
            message: string;
          }>;
          runner: { __typename?: 'User'; id: string; name: string };
        }>;
        testCases: Array<{
          __typename?: 'CustomerTargetSchemaTransformTestCase';
          id: string;
          name: string;
          inputJson: any | null;
          kind: GQCtsTransformTestKind;
          expectedOutput: {
            __typename?: 'ParquetData';
            id: string;
            numRecords: number;
            ndjsonSignedUrl: string | null;
            ndjsonSize: number | null;
            ndjsonByteBoundaries: Array<number> | null;
            columns: Array<{
              __typename?: 'ParquetColumn';
              name: string;
              sqlType: string;
              minValue: any;
              maxValue: any;
              numNulls: number;
              approxDistinctValues: number;
              sampleValues: Array<any>;
            }>;
          } | null;
          latestRun: {
            __typename?: 'CustomerTargetSchemaTransformTestRun';
            id: string;
            status: GQCtsTestRunStatus;
            testDiffResults: any | null;
            transformRun: {
              __typename?: 'CustomerTargetSchemaTransformRun';
              id: string;
              error: string | null;
              standardizedOutput: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
            };
          } | null;
        }>;
      }>;
      lastEditedByUser: { __typename?: 'User'; id: string; name: string };
    }>;
    latestPublishedVersion: {
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      publishedAt: Date | null;
    } | null;
    canonicalDatasets: Array<{
      __typename?: 'CanonicalDataset';
      id: string;
      name: string;
    }>;
  };
  canonicalDatasets: Array<{
    __typename?: 'CanonicalDataset';
    id: string;
    name: string;
  }>;
};

export type GQCreateCtsVersionMutationVariables = Exact<{
  input: GQCreateCtsVersionInput;
}>;

export type GQCreateCtsVersionMutation = {
  __typename?: 'Mutation';
  createCtsVersion: {
    __typename?: 'CreateCtsVersionPayload';
    customerTargetSchema: {
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      descriptionMd: string | null;
      rank: number;
      schemaId: string | null;
      versions: Array<{
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        schemaJson: any;
        semverVersion: string;
        versionNotesMd: string;
        exampleData: any;
        formattingNotesMd: string | null;
        createdAt: Date;
        publishedAt: Date | null;
        publishedForTestingAt: Date | null;
        unpublishedAt: Date | null;
        schemaVersionId: string | null;
        schema: {
          __typename?: 'CustomerTargetSchema';
          id: string;
          name: string;
          versions: Array<{
            __typename?: 'CustomerTargetSchemaVersion';
            id: string;
            publishedAt: Date | null;
            publishedForTestingAt: Date | null;
            unpublishedAt: Date | null;
          }>;
        };
        globalIngestionExampleData: {
          __typename?: 'IngestionExampleData';
          id: string;
          title: string;
          datasetId: string | null;
          columns: Array<{
            __typename?: 'IngestionExampleDataColumn';
            fieldName: string;
            title: string;
            description: string;
            examples: Array<string>;
            required: boolean;
            optionalToMap: boolean | null;
            defaultValue: any | null;
          }>;
          rows: Array<{
            __typename?: 'IngestionExampleDataRow';
            fields: Array<{
              __typename?: 'IngestionExampleDataRowField';
              fieldName: string;
              value: string;
            }>;
          }>;
        };
        transformsIncludingDeleted: Array<{
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          deletedAt: Date | null;
          sql: string;
          transformTarget: GQCustomerTargetSchemaTransformTarget;
          businessActivityTypeName: string | null;
          businessActivityTypeVersionId: string | null;
          businessActivityTypeVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
            name: string;
          } | null;
          runs: Array<{
            __typename?: 'CustomerTargetSchemaTransformRun';
            id: string;
            sql: string;
            error: string | null;
            createdAt: Date;
            transform: {
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              transformTarget: GQCustomerTargetSchemaTransformTarget;
              businessActivityTypeName: string | null;
            };
            input: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              category: GQFileCategory;
              revisionRootFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              } | null;
            };
            output: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            standardizedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            warnings: Array<{
              __typename?: 'ValidationWarning';
              blocking: boolean;
              message: string;
            }>;
            runner: { __typename?: 'User'; id: string; name: string };
          }>;
          testCases: Array<{
            __typename?: 'CustomerTargetSchemaTransformTestCase';
            id: string;
            name: string;
            inputJson: any | null;
            kind: GQCtsTransformTestKind;
            expectedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            latestRun: {
              __typename?: 'CustomerTargetSchemaTransformTestRun';
              id: string;
              status: GQCtsTestRunStatus;
              testDiffResults: any | null;
              transformRun: {
                __typename?: 'CustomerTargetSchemaTransformRun';
                id: string;
                error: string | null;
                standardizedOutput: {
                  __typename?: 'ParquetData';
                  id: string;
                  numRecords: number;
                  ndjsonSignedUrl: string | null;
                  ndjsonSize: number | null;
                  ndjsonByteBoundaries: Array<number> | null;
                  columns: Array<{
                    __typename?: 'ParquetColumn';
                    name: string;
                    sqlType: string;
                    minValue: any;
                    maxValue: any;
                    numNulls: number;
                    approxDistinctValues: number;
                    sampleValues: Array<any>;
                  }>;
                } | null;
              };
            } | null;
          }>;
        }>;
        lastEditedByUser: { __typename?: 'User'; id: string; name: string };
      }>;
      latestPublishedVersion: {
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        publishedAt: Date | null;
      } | null;
      canonicalDatasets: Array<{
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      }>;
    } | null;
  };
};

export type GQCustomerTargetSchemaForListViewFragment = {
  __typename?: 'CustomerTargetSchema';
  id: string;
  name: string;
  descriptionMd: string | null;
  rank: number;
  deletedAt: Date | null;
  versions: Array<{
    __typename?: 'CustomerTargetSchemaVersion';
    id: string;
    createdAt: Date;
    publishedAt: Date | null;
    publishedForTestingAt: Date | null;
    unpublishedAt: Date | null;
    lastEditedByUser: { __typename?: 'User'; id: string; name: string };
    transforms: Array<{
      __typename?: 'CustomerTargetSchemaTransform';
      id: string;
      testCases: Array<{
        __typename?: 'CustomerTargetSchemaTransformTestCase';
        id: string;
        kind: GQCtsTransformTestKind;
      }>;
    }>;
  }>;
  canonicalDatasets: Array<{
    __typename?: 'CanonicalDataset';
    id: string;
    name: string;
  }>;
};

export type GQGlobalCustomerTargetSchemasQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGlobalCustomerTargetSchemasQuery = {
  __typename?: 'Query';
  customerTargetSchemas: Array<{
    __typename?: 'CustomerTargetSchema';
    id: string;
    name: string;
    descriptionMd: string | null;
    rank: number;
    deletedAt: Date | null;
    versions: Array<{
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      createdAt: Date;
      publishedAt: Date | null;
      publishedForTestingAt: Date | null;
      unpublishedAt: Date | null;
      lastEditedByUser: { __typename?: 'User'; id: string; name: string };
      transforms: Array<{
        __typename?: 'CustomerTargetSchemaTransform';
        id: string;
        testCases: Array<{
          __typename?: 'CustomerTargetSchemaTransformTestCase';
          id: string;
          kind: GQCtsTransformTestKind;
        }>;
      }>;
    }>;
    canonicalDatasets: Array<{
      __typename?: 'CanonicalDataset';
      id: string;
      name: string;
    }>;
  }>;
  canonicalDatasets: Array<{
    __typename?: 'CanonicalDataset';
    id: string;
    name: string;
  }>;
};

export type GQDuplicateCtsMutationVariables = Exact<{
  input: GQDuplicateCtsInput;
}>;

export type GQDuplicateCtsMutation = {
  __typename?: 'Mutation';
  duplicateCts: {
    __typename?: 'DuplicateCtsPayload';
    schema: {
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      descriptionMd: string | null;
      rank: number;
      deletedAt: Date | null;
      versions: Array<{
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        createdAt: Date;
        publishedAt: Date | null;
        publishedForTestingAt: Date | null;
        unpublishedAt: Date | null;
        lastEditedByUser: { __typename?: 'User'; id: string; name: string };
        transforms: Array<{
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          testCases: Array<{
            __typename?: 'CustomerTargetSchemaTransformTestCase';
            id: string;
            kind: GQCtsTransformTestKind;
          }>;
        }>;
      }>;
      canonicalDatasets: Array<{
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      }>;
    };
  };
};

export type GQDeleteCtsMutationVariables = Exact<{
  input: GQDeleteCtsInput;
}>;

export type GQDeleteCtsMutation = {
  __typename?: 'Mutation';
  deleteCts: {
    __typename?: 'DeleteCtsPayload';
    schema: {
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      descriptionMd: string | null;
      rank: number;
      deletedAt: Date | null;
      versions: Array<{
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        createdAt: Date;
        publishedAt: Date | null;
        publishedForTestingAt: Date | null;
        unpublishedAt: Date | null;
        lastEditedByUser: { __typename?: 'User'; id: string; name: string };
        transforms: Array<{
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          testCases: Array<{
            __typename?: 'CustomerTargetSchemaTransformTestCase';
            id: string;
            kind: GQCtsTransformTestKind;
          }>;
        }>;
      }>;
      canonicalDatasets: Array<{
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
      }>;
    };
  };
};

export type GQDuckHuntPageQueryVariables = Exact<{ [key: string]: never }>;

export type GQDuckHuntPageQuery = {
  __typename?: 'Query';
  ducksForHunter: Array<string>;
  duckHuntLeaderboard: Array<{
    __typename?: 'DuckHuntLeaderboardEntry';
    numDucks: number;
    lastFoundAt: Date;
    hunter: { __typename?: 'WatershedEmployee'; id: string; name: string };
  }>;
};

export type GQOrganizationEmailsSentQueryVariables = Exact<{
  orgId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQOrganizationEmailsSentQuery = {
  __typename?: 'Query';
  emailsSent: Array<{
    __typename?: 'EmailSent';
    id: string;
    kind: string;
    fromEmail: string;
    subject: string;
    htmlContent: string;
    createdAt: Date;
    openCount: number | null;
    clickCount: number | null;
    users: Array<{
      __typename?: 'EmailSentUser';
      id: string;
      relationshipKind: GQEmailRelationshipKind;
      recipientEmail: string;
      recipientUser: {
        __typename?: 'User';
        id: string;
        name: string;
        email: string;
      } | null;
    }>;
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
    };
  }>;
  organizations: {
    __typename?: 'OrgConnection';
    edges: Array<{
      __typename?: 'OrgEdge';
      node: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
      };
    } | null>;
  };
};

export type GQEmailSentForAdminTableFragment = {
  __typename?: 'EmailSent';
  id: string;
  kind: string;
  fromEmail: string;
  subject: string;
  htmlContent: string;
  createdAt: Date;
  openCount: number | null;
  clickCount: number | null;
  users: Array<{
    __typename?: 'EmailSentUser';
    id: string;
    relationshipKind: GQEmailRelationshipKind;
    recipientEmail: string;
    recipientUser: {
      __typename?: 'User';
      id: string;
      name: string;
      email: string;
    } | null;
  }>;
  org: {
    __typename?: 'Organization';
    id: string;
    name: string;
    demoOrg: boolean;
  };
};

export type GQEmeMissingReferenceValueMetadataFieldsFragment = {
  __typename?: 'EmeMissingReferenceValueMetadata';
  slug: string;
  revisionId: string;
  column: string;
  rowId: string | null;
};

export type GQEmeMissingTraceMetadataFieldsFragment = {
  __typename?: 'EmeMissingTraceMetadata';
  missingReferenceValueCitations: Array<{
    __typename?: 'EmeMissingReferenceValueMetadata';
    slug: string;
    revisionId: string;
    column: string;
    rowId: string | null;
  }>;
  missingReferenceValueDescriptions: Array<{
    __typename?: 'EmeMissingReferenceValueMetadata';
    slug: string;
    revisionId: string;
    column: string;
    rowId: string | null;
  }>;
  missingBartValueMetadata: Array<{
    __typename?: 'EmeMissingBartValueMetadata';
    businessActivityTypeName: string;
    column: string;
  }>;
};

export type GQGetEmissionsModelEvaluationPageDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetEmissionsModelEvaluationPageDataQuery = {
  __typename?: 'Query';
  emissionsModelEvaluation: {
    __typename?: 'EmissionsModelEvaluation';
    id: string;
    fingerprintCount: number;
    missingTraceMetadata: {
      __typename?: 'EmeMissingTraceMetadata';
      missingReferenceValueCitations: Array<{
        __typename?: 'EmeMissingReferenceValueMetadata';
        slug: string;
        revisionId: string;
        column: string;
        rowId: string | null;
      }>;
      missingReferenceValueDescriptions: Array<{
        __typename?: 'EmeMissingReferenceValueMetadata';
        slug: string;
        revisionId: string;
        column: string;
        rowId: string | null;
      }>;
      missingBartValueMetadata: Array<{
        __typename?: 'EmeMissingBartValueMetadata';
        businessActivityTypeName: string;
        column: string;
      }>;
    };
    calculationSampler: {
      __typename?: 'EmeCalculationSampler';
      samples: Array<{
        __typename?: 'EmeCalculationSamplerItem';
        rowId: number;
        fingerprint: string;
        equation: {
          __typename?: 'EmissionsModelEvalEquation';
          emissionsVariableName: string;
          trace: string;
          traceDetails: {
            __typename?: 'TraceDetails';
            referenceValueDetails: Array<any>;
            emissionsModelVariableDetails: Array<any>;
            bartValueDetails: Array<any>;
            emissionsModelVersionId: string | null;
            emissionsModelEvaluationId: string | null;
          };
        };
      }>;
    } | null;
  };
};

export type GQEmissionsModelVersionFieldsForImportedDescendantsFragment = {
  __typename?: 'EmissionsModelVersion';
  id: string;
  title: string;
  description: string;
  changelog: string;
  createdAt: Date;
  schemaVersion: string;
  emissionsModelJson: any;
  parameterResolverJson: any;
  stableModel: {
    __typename?: 'EmissionsModelStable';
    id: string;
    latestPublishedVersion: {
      __typename?: 'EmissionsModelVersion';
      id: string;
      changelog: string;
      createdAt: Date;
    } | null;
  };
};

export type GQEmissionsModelVersionFieldsForEditorFragment = {
  __typename?: 'EmissionsModelVersion';
  id: string;
  kind: GQEmissionsModelVersionKind;
  changelog: string;
  createdAt: Date;
  schemaVersion: string;
  emissionsModelJson: any;
  parameterResolverJson: any;
  author: { __typename?: 'User'; id: string; name: string } | null;
  activeDraftForVersion: {
    __typename?: 'EmissionsModelActiveDraft';
    id: string;
    isCheckedOut: boolean;
    autosavedAt: Date;
    emissionsModelJson: any | null;
    parameterResolverJson: any | null;
    matchers: any;
    activeAuthor: {
      __typename?: 'WatershedEmployee';
      id: string;
      name: string;
    };
    importedDescendants: Array<{
      __typename?: 'EmissionsModelVersion';
      id: string;
      title: string;
      description: string;
      changelog: string;
      createdAt: Date;
      schemaVersion: string;
      emissionsModelJson: any;
      parameterResolverJson: any;
      stableModel: {
        __typename?: 'EmissionsModelStable';
        id: string;
        latestPublishedVersion: {
          __typename?: 'EmissionsModelVersion';
          id: string;
          changelog: string;
          createdAt: Date;
        } | null;
      };
    }>;
  } | null;
  importedDescendants: Array<{
    __typename?: 'EmissionsModelVersion';
    id: string;
    title: string;
    description: string;
    changelog: string;
    createdAt: Date;
    schemaVersion: string;
    emissionsModelJson: any;
    parameterResolverJson: any;
    stableModel: {
      __typename?: 'EmissionsModelStable';
      id: string;
      latestPublishedVersion: {
        __typename?: 'EmissionsModelVersion';
        id: string;
        changelog: string;
        createdAt: Date;
      } | null;
    };
  }>;
};

export type GQEmissionsModelStableBaseFieldsFragment = {
  __typename?: 'EmissionsModelStable';
  id: string;
  title: string;
  description: string;
  scope: GQEmissionsModelStableScope;
  createdAt: Date;
  tags: Array<{ __typename?: 'CalculationTag'; id: string; name: string }>;
};

export type GQEmissionsModelMatcherFieldsFragment = {
  __typename?: 'EmissionsModelMatcher';
  id: string;
  kind: GQEmissionsModelMatcherKind;
  emissionsModelStableId: string;
  businessActivityType: string;
  businessActivityTypeVersionId: string;
  filterExpression: string | null;
  quantitySpecifier: string | null;
  bindings: any;
  keepBatVersionAtLatest: boolean;
};

export type GQEmissionsModelReleaseFieldsForEmEditorFragment = {
  __typename?: 'EmissionsModelRelease';
  id: string;
  businessActivityTypeName: string;
  displayName: string;
  lifecyclePhase: GQEmReleaseLifecyclePhase;
  internalNotes: string;
  externalNotes: string;
};

export type GQEmissionsModelStableFieldsFragment = {
  __typename?: 'EmissionsModelStable';
  isMutable: boolean;
  isArchived: boolean;
  id: string;
  title: string;
  description: string;
  scope: GQEmissionsModelStableScope;
  createdAt: Date;
  latestVersion: {
    __typename?: 'EmissionsModelVersion';
    id: string;
    kind: GQEmissionsModelVersionKind;
    changelog: string;
    createdAt: Date;
    schemaVersion: string;
    emissionsModelJson: any;
    parameterResolverJson: any;
    author: { __typename?: 'User'; id: string; name: string } | null;
    activeDraftForVersion: {
      __typename?: 'EmissionsModelActiveDraft';
      id: string;
      isCheckedOut: boolean;
      autosavedAt: Date;
      emissionsModelJson: any | null;
      parameterResolverJson: any | null;
      matchers: any;
      activeAuthor: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      };
      importedDescendants: Array<{
        __typename?: 'EmissionsModelVersion';
        id: string;
        title: string;
        description: string;
        changelog: string;
        createdAt: Date;
        schemaVersion: string;
        emissionsModelJson: any;
        parameterResolverJson: any;
        stableModel: {
          __typename?: 'EmissionsModelStable';
          id: string;
          latestPublishedVersion: {
            __typename?: 'EmissionsModelVersion';
            id: string;
            changelog: string;
            createdAt: Date;
          } | null;
        };
      }>;
    } | null;
    importedDescendants: Array<{
      __typename?: 'EmissionsModelVersion';
      id: string;
      title: string;
      description: string;
      changelog: string;
      createdAt: Date;
      schemaVersion: string;
      emissionsModelJson: any;
      parameterResolverJson: any;
      stableModel: {
        __typename?: 'EmissionsModelStable';
        id: string;
        latestPublishedVersion: {
          __typename?: 'EmissionsModelVersion';
          id: string;
          changelog: string;
          createdAt: Date;
        } | null;
      };
    }>;
  };
  org: { __typename?: 'Organization'; id: string; name: string } | null;
  matchers: Array<{
    __typename?: 'EmissionsModelMatcher';
    id: string;
    kind: GQEmissionsModelMatcherKind;
    emissionsModelStableId: string;
    businessActivityType: string;
    businessActivityTypeVersionId: string;
    filterExpression: string | null;
    quantitySpecifier: string | null;
    bindings: any;
    keepBatVersionAtLatest: boolean;
  }>;
  releases: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    businessActivityTypeName: string;
    displayName: string;
    lifecyclePhase: GQEmReleaseLifecyclePhase;
    internalNotes: string;
    externalNotes: string;
  }>;
  tags: Array<{ __typename?: 'CalculationTag'; id: string; name: string }>;
};

export type GQGetEmissionsModelPageDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetEmissionsModelPageDataQuery = {
  __typename?: 'Query';
  emissionsModelStable: {
    __typename?: 'EmissionsModelStable';
    isMutable: boolean;
    isArchived: boolean;
    id: string;
    title: string;
    description: string;
    scope: GQEmissionsModelStableScope;
    createdAt: Date;
    latestVersion: {
      __typename?: 'EmissionsModelVersion';
      id: string;
      kind: GQEmissionsModelVersionKind;
      changelog: string;
      createdAt: Date;
      schemaVersion: string;
      emissionsModelJson: any;
      parameterResolverJson: any;
      author: { __typename?: 'User'; id: string; name: string } | null;
      activeDraftForVersion: {
        __typename?: 'EmissionsModelActiveDraft';
        id: string;
        isCheckedOut: boolean;
        autosavedAt: Date;
        emissionsModelJson: any | null;
        parameterResolverJson: any | null;
        matchers: any;
        activeAuthor: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        };
        importedDescendants: Array<{
          __typename?: 'EmissionsModelVersion';
          id: string;
          title: string;
          description: string;
          changelog: string;
          createdAt: Date;
          schemaVersion: string;
          emissionsModelJson: any;
          parameterResolverJson: any;
          stableModel: {
            __typename?: 'EmissionsModelStable';
            id: string;
            latestPublishedVersion: {
              __typename?: 'EmissionsModelVersion';
              id: string;
              changelog: string;
              createdAt: Date;
            } | null;
          };
        }>;
      } | null;
      importedDescendants: Array<{
        __typename?: 'EmissionsModelVersion';
        id: string;
        title: string;
        description: string;
        changelog: string;
        createdAt: Date;
        schemaVersion: string;
        emissionsModelJson: any;
        parameterResolverJson: any;
        stableModel: {
          __typename?: 'EmissionsModelStable';
          id: string;
          latestPublishedVersion: {
            __typename?: 'EmissionsModelVersion';
            id: string;
            changelog: string;
            createdAt: Date;
          } | null;
        };
      }>;
    };
    org: { __typename?: 'Organization'; id: string; name: string } | null;
    matchers: Array<{
      __typename?: 'EmissionsModelMatcher';
      id: string;
      kind: GQEmissionsModelMatcherKind;
      emissionsModelStableId: string;
      businessActivityType: string;
      businessActivityTypeVersionId: string;
      filterExpression: string | null;
      quantitySpecifier: string | null;
      bindings: any;
      keepBatVersionAtLatest: boolean;
    }>;
    releases: Array<{
      __typename?: 'EmissionsModelRelease';
      id: string;
      businessActivityTypeName: string;
      displayName: string;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      internalNotes: string;
      externalNotes: string;
    }>;
    tags: Array<{ __typename?: 'CalculationTag'; id: string; name: string }>;
  };
  businessActivityTypeVersionLive: {
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
  };
};

export type GQGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsQueryVariables =
  Exact<{
    ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  }>;

export type GQGetEmissionsModelVersionImportedDescendantsFromDirectImportIdsQuery =
  {
    __typename?: 'Query';
    emissionsModelVersionImportedDescendantsFromDirectImportIds: Array<{
      __typename?: 'EmissionsModelVersion';
      id: string;
      title: string;
      description: string;
      changelog: string;
      createdAt: Date;
      schemaVersion: string;
      emissionsModelJson: any;
      parameterResolverJson: any;
      stableModel: {
        __typename?: 'EmissionsModelStable';
        id: string;
        latestPublishedVersion: {
          __typename?: 'EmissionsModelVersion';
          id: string;
          changelog: string;
          createdAt: Date;
        } | null;
      };
    }>;
  };

export type GQUpdateEmissionsModelStableMutationVariables = Exact<{
  input: GQUpdateEmissionsModelStableInput;
}>;

export type GQUpdateEmissionsModelStableMutation = {
  __typename?: 'Mutation';
  updateEmissionsModelStable: {
    __typename?: 'UpdateEmissionsModelStablePayload';
    emissionsModelStable: {
      __typename?: 'EmissionsModelStable';
      id: string;
      title: string;
      description: string;
      scope: GQEmissionsModelStableScope;
      createdAt: Date;
      tags: Array<{ __typename?: 'CalculationTag'; id: string; name: string }>;
    };
  } | null;
};

export type GQGetEmissionsModelTableDataQueryVariables = Exact<{
  showArchived: Scalars['Boolean']['input'];
  emissionsModelListTab: InputMaybe<Scalars['String']['input']>;
}>;

export type GQGetEmissionsModelTableDataQuery = {
  __typename?: 'Query';
  emissionsModelsStable: Array<{
    __typename?: 'EmissionsModelStable';
    id: string;
    title: string;
    description: string;
    scope: GQEmissionsModelStableScope;
    isArchived: boolean;
    org: { __typename?: 'Organization'; id: string; name: string } | null;
    latestPublishedVersion: {
      __typename?: 'EmissionsModelVersion';
      id: string;
      createdAt: Date;
    } | null;
    matchers: Array<{
      __typename?: 'EmissionsModelMatcher';
      id: string;
      businessActivityType: string;
    }>;
    releases: Array<{
      __typename?: 'EmissionsModelRelease';
      id: string;
      updatedAt: Date;
      userAuthorId: string | null;
      displayName: string;
      businessActivityTypeName: string;
      internalNotes: string;
      externalNotes: string;
      orgId: string | null;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      isCustomToUserOrg: boolean | null;
      isOrgGated: boolean;
      user: { __typename?: 'User'; id: string; name: string } | null;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      } | null;
      latestCompatibleBatVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
      orgAccess: Array<{
        __typename?: 'EmissionsModelReleaseOrgAccess';
        id: string;
        createdAt: Date;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        };
      }>;
    }>;
  }>;
};

export type GQWatershedEmployeesPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQWatershedEmployeesPageQuery = {
  __typename?: 'Query';
  watershedEmployees: {
    __typename?: 'WatershedEmployeeConnection';
    edges: Array<{
      __typename?: 'WatershedEmployeeEdge';
      node: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
        email: string;
      } | null;
    } | null>;
  };
};

export type GQAddWatershedEmployeeMutationVariables = Exact<{
  input: GQAddWatershedEmployeeInput;
}>;

export type GQAddWatershedEmployeeMutation = {
  __typename?: 'Mutation';
  addWatershedEmployee: {
    __typename?: 'AddWatershedEmployeePayload';
    watershedEmployee: {
      __typename?: 'WatershedEmployee';
      id: string;
      name: string;
      email: string;
    };
  } | null;
};

export type GQEngagementTaskConfigAdminQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQEngagementTaskConfigAdminQuery = {
  __typename?: 'Query';
  engagementTaskConfig: {
    __typename?: 'EngagementTaskConfig';
    id: string;
    name: string;
    sidebarTitle: string;
    type: GQEngagementTaskConfigType | null;
    category: GQSurveyDefinitionCategory;
    order: number;
    publishedAt: Date | null;
    rootFacingDescription: string | null;
    defaultSupplierFacingDescription: string | null;
    engagementTaskInputs: Array<GQEngagementTaskInput>;
    optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
    canDeleteInAdmin: boolean;
    timeNeeded: number | null;
    configGroup: GQEngagementTaskConfigGroup | null;
    containers: Array<{
      __typename?: 'EngagementTaskContainer';
      id: string;
      label: string;
      description: string | null;
      componentId: GQEngagementTaskContainerComponentId | null;
      editable: boolean;
      statusJson: any;
      sidebarStepLabelOverride: string | null;
      eyebrowText: string | null;
      headerImage: GQEngagementTaskImageId | null;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          answerIsPrivate: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          videoLink: string | null;
        }>;
      }>;
      questions: Array<{
        __typename?: 'EngagementTaskQuestion';
        id: string;
        label: string | null;
        description: string | null;
        componentId: GQEngagementTaskQuestionComponentId;
        editable: boolean;
        deletable: boolean;
        answerIsPrivate: boolean;
        questionKey: string;
        statusJson: any;
        whyCollectThis: string | null;
        videoLink: string | null;
      }>;
    }>;
  };
};

export type GQUpdateEngagementTaskConfigAdminMutationVariables = Exact<{
  input: GQEngagementTaskConfigInputForAdmin;
}>;

export type GQUpdateEngagementTaskConfigAdminMutation = {
  __typename?: 'Mutation';
  updateEngagementTaskConfigForAdmin: {
    __typename?: 'EngagementTaskConfigPayload';
    engagementTaskConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      name: string;
      sidebarTitle: string;
      type: GQEngagementTaskConfigType | null;
      category: GQSurveyDefinitionCategory;
      order: number;
      publishedAt: Date | null;
      rootFacingDescription: string | null;
      defaultSupplierFacingDescription: string | null;
      engagementTaskInputs: Array<GQEngagementTaskInput>;
      optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
      canDeleteInAdmin: boolean;
      timeNeeded: number | null;
      configGroup: GQEngagementTaskConfigGroup | null;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            answerIsPrivate: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            videoLink: string | null;
          }>;
        }>;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          answerIsPrivate: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          videoLink: string | null;
        }>;
      }>;
    };
  };
};

export type GQEngagementTaskQuestionFieldsAdminFragment = {
  __typename?: 'EngagementTaskQuestion';
  id: string;
  label: string | null;
  description: string | null;
  componentId: GQEngagementTaskQuestionComponentId;
  editable: boolean;
  deletable: boolean;
  answerIsPrivate: boolean;
  questionKey: string;
  statusJson: any;
  whyCollectThis: string | null;
  videoLink: string | null;
};

export type GQEngagementTaskContainerFieldsAdminFragment = {
  __typename?: 'EngagementTaskContainer';
  id: string;
  label: string;
  description: string | null;
  componentId: GQEngagementTaskContainerComponentId | null;
  editable: boolean;
  statusJson: any;
  sidebarStepLabelOverride: string | null;
  eyebrowText: string | null;
  headerImage: GQEngagementTaskImageId | null;
  questions: Array<{
    __typename?: 'EngagementTaskQuestion';
    id: string;
    label: string | null;
    description: string | null;
    componentId: GQEngagementTaskQuestionComponentId;
    editable: boolean;
    deletable: boolean;
    answerIsPrivate: boolean;
    questionKey: string;
    statusJson: any;
    whyCollectThis: string | null;
    videoLink: string | null;
  }>;
};

export type GQEngagementTaskContainerWithSubSectionFieldsAdminFragment = {
  __typename?: 'EngagementTaskContainer';
  id: string;
  label: string;
  description: string | null;
  componentId: GQEngagementTaskContainerComponentId | null;
  editable: boolean;
  statusJson: any;
  sidebarStepLabelOverride: string | null;
  eyebrowText: string | null;
  headerImage: GQEngagementTaskImageId | null;
  containers: Array<{
    __typename?: 'EngagementTaskContainer';
    id: string;
    label: string;
    description: string | null;
    componentId: GQEngagementTaskContainerComponentId | null;
    editable: boolean;
    statusJson: any;
    sidebarStepLabelOverride: string | null;
    eyebrowText: string | null;
    headerImage: GQEngagementTaskImageId | null;
    questions: Array<{
      __typename?: 'EngagementTaskQuestion';
      id: string;
      label: string | null;
      description: string | null;
      componentId: GQEngagementTaskQuestionComponentId;
      editable: boolean;
      deletable: boolean;
      answerIsPrivate: boolean;
      questionKey: string;
      statusJson: any;
      whyCollectThis: string | null;
      videoLink: string | null;
    }>;
  }>;
  questions: Array<{
    __typename?: 'EngagementTaskQuestion';
    id: string;
    label: string | null;
    description: string | null;
    componentId: GQEngagementTaskQuestionComponentId;
    editable: boolean;
    deletable: boolean;
    answerIsPrivate: boolean;
    questionKey: string;
    statusJson: any;
    whyCollectThis: string | null;
    videoLink: string | null;
  }>;
};

export type GQEngagementTaskConfigFieldsAdminFragment = {
  __typename?: 'EngagementTaskConfig';
  id: string;
  name: string;
  sidebarTitle: string;
  type: GQEngagementTaskConfigType | null;
  category: GQSurveyDefinitionCategory;
  order: number;
  publishedAt: Date | null;
  rootFacingDescription: string | null;
  defaultSupplierFacingDescription: string | null;
  engagementTaskInputs: Array<GQEngagementTaskInput>;
  optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
  canDeleteInAdmin: boolean;
  timeNeeded: number | null;
  configGroup: GQEngagementTaskConfigGroup | null;
  containers: Array<{
    __typename?: 'EngagementTaskContainer';
    id: string;
    label: string;
    description: string | null;
    componentId: GQEngagementTaskContainerComponentId | null;
    editable: boolean;
    statusJson: any;
    sidebarStepLabelOverride: string | null;
    eyebrowText: string | null;
    headerImage: GQEngagementTaskImageId | null;
    containers: Array<{
      __typename?: 'EngagementTaskContainer';
      id: string;
      label: string;
      description: string | null;
      componentId: GQEngagementTaskContainerComponentId | null;
      editable: boolean;
      statusJson: any;
      sidebarStepLabelOverride: string | null;
      eyebrowText: string | null;
      headerImage: GQEngagementTaskImageId | null;
      questions: Array<{
        __typename?: 'EngagementTaskQuestion';
        id: string;
        label: string | null;
        description: string | null;
        componentId: GQEngagementTaskQuestionComponentId;
        editable: boolean;
        deletable: boolean;
        answerIsPrivate: boolean;
        questionKey: string;
        statusJson: any;
        whyCollectThis: string | null;
        videoLink: string | null;
      }>;
    }>;
    questions: Array<{
      __typename?: 'EngagementTaskQuestion';
      id: string;
      label: string | null;
      description: string | null;
      componentId: GQEngagementTaskQuestionComponentId;
      editable: boolean;
      deletable: boolean;
      answerIsPrivate: boolean;
      questionKey: string;
      statusJson: any;
      whyCollectThis: string | null;
      videoLink: string | null;
    }>;
  }>;
};

export type GQGlobalEngagementTaskConfigsAdminQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGlobalEngagementTaskConfigsAdminQuery = {
  __typename?: 'Query';
  globalEngagementTaskConfigs: Array<{
    __typename?: 'EngagementTaskConfig';
    id: string;
    name: string;
    sidebarTitle: string;
    type: GQEngagementTaskConfigType | null;
    category: GQSurveyDefinitionCategory;
    order: number;
    publishedAt: Date | null;
    rootFacingDescription: string | null;
    defaultSupplierFacingDescription: string | null;
    engagementTaskInputs: Array<GQEngagementTaskInput>;
    optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
    canDeleteInAdmin: boolean;
    timeNeeded: number | null;
    configGroup: GQEngagementTaskConfigGroup | null;
    containers: Array<{
      __typename?: 'EngagementTaskContainer';
      id: string;
      label: string;
      description: string | null;
      componentId: GQEngagementTaskContainerComponentId | null;
      editable: boolean;
      statusJson: any;
      sidebarStepLabelOverride: string | null;
      eyebrowText: string | null;
      headerImage: GQEngagementTaskImageId | null;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          answerIsPrivate: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          videoLink: string | null;
        }>;
      }>;
      questions: Array<{
        __typename?: 'EngagementTaskQuestion';
        id: string;
        label: string | null;
        description: string | null;
        componentId: GQEngagementTaskQuestionComponentId;
        editable: boolean;
        deletable: boolean;
        answerIsPrivate: boolean;
        questionKey: string;
        statusJson: any;
        whyCollectThis: string | null;
        videoLink: string | null;
      }>;
    }>;
  }>;
};

export type GQOrgSpecificEngagementTaskConfigsAdminQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrgSpecificEngagementTaskConfigsAdminQuery = {
  __typename?: 'Query';
  engagementTaskConfigs: Array<{
    __typename?: 'EngagementTaskConfig';
    id: string;
    name: string;
    sidebarTitle: string;
    type: GQEngagementTaskConfigType | null;
    category: GQSurveyDefinitionCategory;
    order: number;
    publishedAt: Date | null;
    rootFacingDescription: string | null;
    defaultSupplierFacingDescription: string | null;
    engagementTaskInputs: Array<GQEngagementTaskInput>;
    optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
    canDeleteInAdmin: boolean;
    timeNeeded: number | null;
    configGroup: GQEngagementTaskConfigGroup | null;
    containers: Array<{
      __typename?: 'EngagementTaskContainer';
      id: string;
      label: string;
      description: string | null;
      componentId: GQEngagementTaskContainerComponentId | null;
      editable: boolean;
      statusJson: any;
      sidebarStepLabelOverride: string | null;
      eyebrowText: string | null;
      headerImage: GQEngagementTaskImageId | null;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          answerIsPrivate: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          videoLink: string | null;
        }>;
      }>;
      questions: Array<{
        __typename?: 'EngagementTaskQuestion';
        id: string;
        label: string | null;
        description: string | null;
        componentId: GQEngagementTaskQuestionComponentId;
        editable: boolean;
        deletable: boolean;
        answerIsPrivate: boolean;
        questionKey: string;
        statusJson: any;
        whyCollectThis: string | null;
        videoLink: string | null;
      }>;
    }>;
  }>;
};

export type GQCreateEngagementTaskConfigAdminMutationVariables = Exact<{
  input: GQEngagementTaskConfigCreateInputForAdmin;
}>;

export type GQCreateEngagementTaskConfigAdminMutation = {
  __typename?: 'Mutation';
  createEngagementTaskConfigForAdmin: {
    __typename?: 'EngagementTaskConfigPayload';
    engagementTaskConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      name: string;
      sidebarTitle: string;
      type: GQEngagementTaskConfigType | null;
      category: GQSurveyDefinitionCategory;
      order: number;
      publishedAt: Date | null;
      rootFacingDescription: string | null;
      defaultSupplierFacingDescription: string | null;
      engagementTaskInputs: Array<GQEngagementTaskInput>;
      optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
      canDeleteInAdmin: boolean;
      timeNeeded: number | null;
      configGroup: GQEngagementTaskConfigGroup | null;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            answerIsPrivate: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            videoLink: string | null;
          }>;
        }>;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          answerIsPrivate: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          videoLink: string | null;
        }>;
      }>;
    };
  };
};

export type GQDeleteEngagementTaskConfigAdminMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQDeleteEngagementTaskConfigAdminMutation = {
  __typename?: 'Mutation';
  deleteEngagementTaskConfigForAdmin: {
    __typename?: 'DeleteEngagementTaskConfigForAdminPayload';
    id: string;
  } | null;
};

export type GQPublishEngagementTaskConfigAdminMutationVariables = Exact<{
  input: GQPublishEngagementTaskConfigForAdminInput;
}>;

export type GQPublishEngagementTaskConfigAdminMutation = {
  __typename?: 'Mutation';
  publishEngagementTaskConfigForAdmin: {
    __typename?: 'PublishEngagementTaskConfigForAdminPayload';
    engagementTaskConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      name: string;
      sidebarTitle: string;
      type: GQEngagementTaskConfigType | null;
      category: GQSurveyDefinitionCategory;
      order: number;
      publishedAt: Date | null;
      rootFacingDescription: string | null;
      defaultSupplierFacingDescription: string | null;
      engagementTaskInputs: Array<GQEngagementTaskInput>;
      optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
      canDeleteInAdmin: boolean;
      timeNeeded: number | null;
      configGroup: GQEngagementTaskConfigGroup | null;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            answerIsPrivate: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            videoLink: string | null;
          }>;
        }>;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          answerIsPrivate: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          videoLink: string | null;
        }>;
      }>;
    };
  } | null;
};

export type GQCopyEngagementTaskConfigToOrgForAdminMutationVariables = Exact<{
  input: GQCopyEngagementTaskConfigToOrgForAdminInput;
}>;

export type GQCopyEngagementTaskConfigToOrgForAdminMutation = {
  __typename?: 'Mutation';
  copyEngagementTaskConfigToOrgForAdmin: {
    __typename?: 'EngagementTaskConfig';
    id: string;
    name: string;
    sidebarTitle: string;
    type: GQEngagementTaskConfigType | null;
    category: GQSurveyDefinitionCategory;
    order: number;
    publishedAt: Date | null;
    rootFacingDescription: string | null;
    defaultSupplierFacingDescription: string | null;
    engagementTaskInputs: Array<GQEngagementTaskInput>;
    optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
    canDeleteInAdmin: boolean;
    timeNeeded: number | null;
    configGroup: GQEngagementTaskConfigGroup | null;
    containers: Array<{
      __typename?: 'EngagementTaskContainer';
      id: string;
      label: string;
      description: string | null;
      componentId: GQEngagementTaskContainerComponentId | null;
      editable: boolean;
      statusJson: any;
      sidebarStepLabelOverride: string | null;
      eyebrowText: string | null;
      headerImage: GQEngagementTaskImageId | null;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          answerIsPrivate: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          videoLink: string | null;
        }>;
      }>;
      questions: Array<{
        __typename?: 'EngagementTaskQuestion';
        id: string;
        label: string | null;
        description: string | null;
        componentId: GQEngagementTaskQuestionComponentId;
        editable: boolean;
        deletable: boolean;
        answerIsPrivate: boolean;
        questionKey: string;
        statusJson: any;
        whyCollectThis: string | null;
        videoLink: string | null;
      }>;
    }>;
  };
};

export type GQFeatureFlagDetailsFragment = {
  __typename?: 'FeatureFlag';
  id: string;
  name: GQFlags;
  description: string;
  enabledForAllOrgs: boolean;
  enabledForOrgs: {
    __typename?: 'OrgConnection';
    edges: Array<{
      __typename?: 'OrgEdge';
      node: { __typename?: 'Organization'; id: string; name: string };
    } | null>;
  };
};

export type GQFeatureFlagsQueryVariables = Exact<{ [key: string]: never }>;

export type GQFeatureFlagsQuery = {
  __typename?: 'Query';
  featureFlags: {
    __typename?: 'FeatureFlagConnection';
    edges: Array<{
      __typename?: 'FeatureFlagEdge';
      node: {
        __typename?: 'FeatureFlag';
        id: string;
        name: GQFlags;
        description: string;
        enabledForAllOrgs: boolean;
        enabledForOrgs: {
          __typename?: 'OrgConnection';
          edges: Array<{
            __typename?: 'OrgEdge';
            node: { __typename?: 'Organization'; id: string; name: string };
          } | null>;
        };
      };
    } | null>;
  };
  organizations: {
    __typename?: 'OrgConnection';
    edges: Array<{
      __typename?: 'OrgEdge';
      node: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    } | null>;
  };
};

export type GQSetOrgsForFeatureFlagMutationVariables = Exact<{
  input: GQSetOrgsForFeatureFlagInput;
}>;

export type GQSetOrgsForFeatureFlagMutation = {
  __typename?: 'Mutation';
  setOrgsForFeatureFlag: {
    __typename?: 'SetOrgsForFeatureFlagPayload';
    featureFlag: {
      __typename?: 'FeatureFlag';
      id: string;
      name: GQFlags;
      description: string;
      enabledForAllOrgs: boolean;
      enabledForOrgs: {
        __typename?: 'OrgConnection';
        edges: Array<{
          __typename?: 'OrgEdge';
          node: { __typename?: 'Organization'; id: string; name: string };
        } | null>;
      };
    };
  };
};

export type GQUpdateOrgForFeatureFlagMutationVariables = Exact<{
  input: GQUpdateOrgForFeatureFlagInput;
}>;

export type GQUpdateOrgForFeatureFlagMutation = {
  __typename?: 'Mutation';
  updateOrgForFeatureFlag: {
    __typename?: 'UpdateOrgForFeatureFlagPayload';
    featureFlag: {
      __typename?: 'FeatureFlag';
      id: string;
      name: GQFlags;
      description: string;
      enabledForAllOrgs: boolean;
      enabledForOrgs: {
        __typename?: 'OrgConnection';
        edges: Array<{
          __typename?: 'OrgEdge';
          node: { __typename?: 'Organization'; id: string; name: string };
        } | null>;
      };
    };
  };
};

export type GQSyncFeatureFlagsMutationVariables = Exact<{
  input: GQSyncFeatureFlagsInput;
}>;

export type GQSyncFeatureFlagsMutation = {
  __typename?: 'Mutation';
  syncFeatureFlags: {
    __typename?: 'SyncFeatureFlagsPayload';
    diff: {
      __typename?: 'FeatureFlagsDiff';
      add: Array<GQFlags>;
      remove: Array<string>;
    };
    featureFlags: Array<{
      __typename?: 'FeatureFlag';
      id: string;
      name: GQFlags;
      description: string;
      enabledForAllOrgs: boolean;
      enabledForOrgs: {
        __typename?: 'OrgConnection';
        edges: Array<{
          __typename?: 'OrgEdge';
          node: { __typename?: 'Organization'; id: string; name: string };
        } | null>;
      };
    }>;
  };
};

export type GQReportsForFootprintDebuggerFragment = {
  __typename?: 'Report';
  id: string;
  reportKind: string;
  publishedAt: Date | null;
  isHidden: boolean | null;
};

export type GQFootprintSnapshotForFootprintDebuggerFragment = {
  __typename?: 'FootprintSnapshot';
  id: string;
  orgId: string;
  createdAt: Date;
  footprintInterval: YMInterval;
  intermediateFileIds: any | null;
  stitchedFootprintSnapshotId: string | null;
  excludedScopesWithPriceGating: Array<string>;
  assemblyDebugJson: any | null;
  versions: Array<{
    __typename?: 'FootprintVersion';
    id: string;
    isUserVisible: boolean;
    kind: GQFootprintVersionKind;
    newDataCoverageInterval: YMInterval | null;
  }>;
  footprintAssemblyRun: {
    __typename?: 'FootprintAssemblyRun';
    id: string;
    workflowId: string | null;
  } | null;
  marketplaceAllocationInstructions: Array<{
    __typename?: 'MarketplaceAllocationInstruction';
    id: string;
  }>;
  tags: Array<{ __typename?: 'FootprintTag'; id: string; tagName: string }>;
  globalTags: Array<{
    __typename?: 'GlobalFootprintTag';
    id: string;
    tagName: string;
    recordStartAt: Date;
    recordEndAt: Date | null;
  }>;
  categoryRules: Array<{ __typename?: 'FootprintCategoryRule'; id: string }>;
  exclusionRules: Array<{ __typename?: 'FootprintExclusionRule'; id: string }>;
  createdBy: {
    __typename?: 'User';
    id: string;
    name: string;
    isWatershed: boolean;
  } | null;
  reportsUsingFootprint: Array<{
    __typename?: 'Report';
    id: string;
    reportKind: string;
    publishedAt: Date | null;
    isHidden: boolean | null;
  }>;
};

export type GQGlobalTagsForFootprintDebuggerFragment = {
  __typename?: 'GlobalFootprintTag';
  id: string;
  tagName: string;
  recordStartAt: Date;
  recordEndAt: Date | null;
};

export type GQFootprintSnapshotExclusionRuleForFootprintDebuggerFragment = {
  __typename?: 'FootprintSnapshotExclusionRule';
  id: string;
  footprintExclusionRule: {
    __typename?: 'FootprintExclusionRule';
    id: string;
    recordStartAt: Date;
    recordEndAt: Date | null;
  };
  impactedEmissions: {
    __typename?: 'FootprintExclusionRuleEmissions';
    excludedTco2eQuantity: number;
    excludedMwhConsumed: number | null;
  };
};

export type GQInstructionImpactForFootprintDebuggerFragment = {
  __typename?: 'MarketplaceAllocationInstructionImpact';
  id: string;
  createdAt: Date;
  updatedAt: Date;
  amountKwh: number;
  allocatedKwh: number;
};

export type GQCleanPowerImpactForFootprintDebuggerFragment = {
  __typename?: 'CleanPowerImpact';
  appliedInstructions: Array<{
    __typename?: 'MarketplaceAllocationInstructionImpact';
    id: string;
    createdAt: Date;
    updatedAt: Date;
    amountKwh: number;
    allocatedKwh: number;
  }>;
};

export type GQGetFootprintDebugQueryVariables = Exact<{
  fpsId: Scalars['ID']['input'];
}>;

export type GQGetFootprintDebugQuery = {
  __typename?: 'Query';
  footprintSnapshot: {
    __typename?: 'FootprintSnapshot';
    id: string;
    orgId: string;
    createdAt: Date;
    footprintInterval: YMInterval;
    intermediateFileIds: any | null;
    stitchedFootprintSnapshotId: string | null;
    excludedScopesWithPriceGating: Array<string>;
    assemblyDebugJson: any | null;
    versions: Array<{
      __typename?: 'FootprintVersion';
      id: string;
      isUserVisible: boolean;
      kind: GQFootprintVersionKind;
      newDataCoverageInterval: YMInterval | null;
    }>;
    footprintAssemblyRun: {
      __typename?: 'FootprintAssemblyRun';
      id: string;
      workflowId: string | null;
    } | null;
    marketplaceAllocationInstructions: Array<{
      __typename?: 'MarketplaceAllocationInstruction';
      id: string;
    }>;
    tags: Array<{ __typename?: 'FootprintTag'; id: string; tagName: string }>;
    globalTags: Array<{
      __typename?: 'GlobalFootprintTag';
      id: string;
      tagName: string;
      recordStartAt: Date;
      recordEndAt: Date | null;
    }>;
    categoryRules: Array<{ __typename?: 'FootprintCategoryRule'; id: string }>;
    exclusionRules: Array<{
      __typename?: 'FootprintExclusionRule';
      id: string;
    }>;
    createdBy: {
      __typename?: 'User';
      id: string;
      name: string;
      isWatershed: boolean;
    } | null;
    reportsUsingFootprint: Array<{
      __typename?: 'Report';
      id: string;
      reportKind: string;
      publishedAt: Date | null;
      isHidden: boolean | null;
    }>;
  };
  footprintDebug: {
    __typename?: 'FootprintDebugPayload';
    id: string;
    appliedExclusionRules: Array<{
      __typename?: 'FootprintSnapshotExclusionRule';
      id: string;
      footprintExclusionRule: {
        __typename?: 'FootprintExclusionRule';
        id: string;
        recordStartAt: Date;
        recordEndAt: Date | null;
      };
      impactedEmissions: {
        __typename?: 'FootprintExclusionRuleEmissions';
        excludedTco2eQuantity: number;
        excludedMwhConsumed: number | null;
      };
    }>;
    cleanPowerImpact: {
      __typename?: 'CleanPowerImpact';
      appliedInstructions: Array<{
        __typename?: 'MarketplaceAllocationInstructionImpact';
        id: string;
        createdAt: Date;
        updatedAt: Date;
        amountKwh: number;
        allocatedKwh: number;
      }>;
    } | null;
  };
};

export type GQGenerateGmailXmlMutationVariables = Exact<{
  input: GQGenerateGmailXmlInput;
}>;

export type GQGenerateGmailXmlMutation = {
  __typename?: 'Mutation';
  generateGmailXml: {
    __typename?: 'GenerateGmailXmlPayload';
    xml: string;
  } | null;
};

export type GQOrganizationListPageOrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  domains: Array<string>;
  demoOrg: boolean;
  testOrg: boolean;
  stagingOrg: boolean | null;
  canAccessFinance: boolean | null;
  hasUsers: boolean;
  watershedPlanLegacy: GQWatershedPlanLegacy;
};

export type GQAllOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type GQAllOrganizationsQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'OrgConnection';
    edges: Array<{
      __typename?: 'OrgEdge';
      node: {
        __typename: 'Organization';
        id: string;
        name: string;
        domains: Array<string>;
        demoOrg: boolean;
        testOrg: boolean;
        stagingOrg: boolean | null;
        canAccessFinance: boolean | null;
        hasUsers: boolean;
        watershedPlanLegacy: GQWatershedPlanLegacy;
      };
    } | null>;
  };
};

export type GQIntegrationConnectionFieldsFragment = {
  __typename?: 'IntegrationConnection';
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  isActive: boolean;
  org: { __typename?: 'Organization'; id: string; name: string };
  integrationPartner: {
    __typename?: 'IntegrationPartner';
    id: string;
    name: string;
    externalId: string;
  };
  integrationDataPulls: {
    __typename?: 'IntegrationDataPullConnection';
    edges: Array<{
      __typename?: 'IntegrationDataPullEdge';
      node: {
        __typename?: 'IntegrationDataPull';
        id: string;
        createdAt: Date;
        totalChunksToFetch: number | null;
        chunksFetched: number;
        totalFilesToProcess: number | null;
        filesProcessed: number;
        completed: boolean;
        adtName: string;
        adtUrl: string;
        createdBy: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        };
        userUploadedTable: {
          __typename?: 'UserUploadedTable';
          id: string;
          sqlTableName: string | null;
        } | null;
        backgroundJob: {
          __typename?: 'BackgroundJob';
          id: string;
          state: GQBackgroundJobState;
          error: {
            __typename?: 'SerializableError';
            message: string;
            code: string | null;
            stackTrace: string | null;
            errorType: string | null;
            details: any | null;
          } | null;
        } | null;
      } | null;
    } | null>;
  };
  datasources: {
    __typename?: 'DatasourceConnection';
    edges: Array<{
      __typename?: 'DatasourceEdge';
      node: {
        __typename?: 'Datasource';
        id: string;
        name: string;
        dataset: { __typename?: 'Dataset'; id: string; name: string };
      } | null;
    }>;
  };
  netsuiteIntegrationSettings: {
    __typename?: 'NetsuiteIntegrationSettings';
    id: string;
    transactionTypeFilters: Array<string>;
    accountNamesToExclude: Array<string>;
    usePostingPeriodForDateFilters: boolean;
  } | null;
};

export type GQDatasourceFieldsFragment = {
  __typename?: 'Datasource';
  id: string;
  name: string;
  dataset: { __typename?: 'Dataset'; id: string; name: string };
};

export type GQIntegrationRequestFieldsFragment = {
  __typename?: 'IntegrationRequest';
  id: string;
  createdAt: Date;
  updatedAt: Date;
  requestMethod: string;
  requestUrl: string | null;
  responseStatus: number | null;
  integrationConnectionId: string;
  orgId: string;
  requestBody: string | null;
  responseFileMetadata: {
    __typename?: 'FileMetadata';
    id: string;
    gcloudStorageUrl: string;
  } | null;
};

export type GQIntegrationDataPullFieldsFragment = {
  __typename?: 'IntegrationDataPull';
  id: string;
  createdAt: Date;
  totalChunksToFetch: number | null;
  chunksFetched: number;
  totalFilesToProcess: number | null;
  filesProcessed: number;
  completed: boolean;
  adtName: string;
  adtUrl: string;
  createdBy: { __typename?: 'WatershedEmployee'; id: string; name: string };
  userUploadedTable: {
    __typename?: 'UserUploadedTable';
    id: string;
    sqlTableName: string | null;
  } | null;
  backgroundJob: {
    __typename?: 'BackgroundJob';
    id: string;
    state: GQBackgroundJobState;
    error: {
      __typename?: 'SerializableError';
      message: string;
      code: string | null;
      stackTrace: string | null;
      errorType: string | null;
      details: any | null;
    } | null;
  } | null;
};

export type GQIntegrationConnectionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQIntegrationConnectionQuery = {
  __typename?: 'Query';
  integrationConnection: {
    __typename?: 'IntegrationConnection';
    id: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
    isActive: boolean;
    org: { __typename?: 'Organization'; id: string; name: string };
    integrationPartner: {
      __typename?: 'IntegrationPartner';
      id: string;
      name: string;
      externalId: string;
    };
    integrationDataPulls: {
      __typename?: 'IntegrationDataPullConnection';
      edges: Array<{
        __typename?: 'IntegrationDataPullEdge';
        node: {
          __typename?: 'IntegrationDataPull';
          id: string;
          createdAt: Date;
          totalChunksToFetch: number | null;
          chunksFetched: number;
          totalFilesToProcess: number | null;
          filesProcessed: number;
          completed: boolean;
          adtName: string;
          adtUrl: string;
          createdBy: {
            __typename?: 'WatershedEmployee';
            id: string;
            name: string;
          };
          userUploadedTable: {
            __typename?: 'UserUploadedTable';
            id: string;
            sqlTableName: string | null;
          } | null;
          backgroundJob: {
            __typename?: 'BackgroundJob';
            id: string;
            state: GQBackgroundJobState;
            error: {
              __typename?: 'SerializableError';
              message: string;
              code: string | null;
              stackTrace: string | null;
              errorType: string | null;
              details: any | null;
            } | null;
          } | null;
        } | null;
      } | null>;
    };
    datasources: {
      __typename?: 'DatasourceConnection';
      edges: Array<{
        __typename?: 'DatasourceEdge';
        node: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: { __typename?: 'Dataset'; id: string; name: string };
        } | null;
      }>;
    };
    netsuiteIntegrationSettings: {
      __typename?: 'NetsuiteIntegrationSettings';
      id: string;
      transactionTypeFilters: Array<string>;
      accountNamesToExclude: Array<string>;
      usePostingPeriodForDateFilters: boolean;
    } | null;
  };
};

export type GQIntegrationRequestsPaginatedQueryVariables = Exact<{
  integrationConnectionId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
}>;

export type GQIntegrationRequestsPaginatedQuery = {
  __typename?: 'Query';
  integrationConnection: {
    __typename?: 'IntegrationConnection';
    id: string;
    integrationRequests: {
      __typename?: 'IntegrationRequestConnection';
      edges: Array<{
        __typename?: 'IntegrationRequestEdge';
        node: {
          __typename?: 'IntegrationRequest';
          id: string;
          createdAt: Date;
          updatedAt: Date;
          requestMethod: string;
          requestUrl: string | null;
          responseStatus: number | null;
          integrationConnectionId: string;
          orgId: string;
          requestBody: string | null;
          responseFileMetadata: {
            __typename?: 'FileMetadata';
            id: string;
            gcloudStorageUrl: string;
          } | null;
        } | null;
      } | null>;
      pageInfo: {
        __typename?: 'PageInfo';
        startCursor: string | null;
        endCursor: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
    };
  };
};

export type GQIntegrationDataPullsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQIntegrationDataPullsQuery = {
  __typename?: 'Query';
  integrationDataPulls: Array<{
    __typename?: 'IntegrationDataPull';
    id: string;
    createdAt: Date;
    totalChunksToFetch: number | null;
    chunksFetched: number;
    totalFilesToProcess: number | null;
    filesProcessed: number;
    completed: boolean;
    adtName: string;
    adtUrl: string;
    createdBy: { __typename?: 'WatershedEmployee'; id: string; name: string };
    userUploadedTable: {
      __typename?: 'UserUploadedTable';
      id: string;
      sqlTableName: string | null;
    } | null;
    backgroundJob: {
      __typename?: 'BackgroundJob';
      id: string;
      state: GQBackgroundJobState;
      error: {
        __typename?: 'SerializableError';
        message: string;
        code: string | null;
        stackTrace: string | null;
        errorType: string | null;
        details: any | null;
      } | null;
    } | null;
  } | null>;
};

export type GQUpdateIntegrationConnectionMutationVariables = Exact<{
  input: GQUpdateIntegrationConnectionInput;
}>;

export type GQUpdateIntegrationConnectionMutation = {
  __typename?: 'Mutation';
  updateIntegrationConnection: {
    __typename?: 'UpdateIntegrationConnectionPayload';
    integrationConnection: {
      __typename?: 'IntegrationConnection';
      id: string;
      netsuiteIntegrationSettings: {
        __typename?: 'NetsuiteIntegrationSettings';
        id: string;
        transactionTypeFilters: Array<string>;
        accountNamesToExclude: Array<string>;
        usePostingPeriodForDateFilters: boolean;
      } | null;
    };
  };
};

export type GQUnassociateIntegrationConnectionFromDatasourceMutationVariables =
  Exact<{
    input: GQUnassociateIntegrationConnectionFromDatasourceInput;
  }>;

export type GQUnassociateIntegrationConnectionFromDatasourceMutation = {
  __typename?: 'Mutation';
  unassociateIntegrationConnectionFromDatasource: {
    __typename?: 'UnassociateIntegrationConnectionFromDatasourcePayload';
    integrationConnection: {
      __typename?: 'IntegrationConnection';
      id: string;
      datasources: {
        __typename?: 'DatasourceConnection';
        edges: Array<{
          __typename?: 'DatasourceEdge';
          node: { __typename?: 'Datasource'; id: string } | null;
        }>;
      };
    };
  };
};

export type GQIntegrationConnectionListFieldsFragment = {
  __typename?: 'IntegrationConnection';
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  isActive: boolean;
  org: { __typename?: 'Organization'; id: string; name: string };
  integrationPartner: {
    __typename?: 'IntegrationPartner';
    id: string;
    name: string;
    dataType: string;
  };
};

export type GQIntegrationsIndexPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQIntegrationsIndexPageQuery = {
  __typename?: 'Query';
  integrationConnections: {
    __typename?: 'IntegrationConnectionConnection';
    edges: Array<{
      __typename?: 'IntegrationConnectionEdge';
      node: {
        __typename?: 'IntegrationConnection';
        id: string;
        createdAt: Date;
        updatedAt: Date;
        deletedAt: Date | null;
        isActive: boolean;
        org: { __typename?: 'Organization'; id: string; name: string };
        integrationPartner: {
          __typename?: 'IntegrationPartner';
          id: string;
          name: string;
          dataType: string;
        };
      } | null;
    } | null>;
  };
};

export type GQStartWorkflowMutationVariables = Exact<{
  input: GQStartWorkflowInput;
}>;

export type GQStartWorkflowMutation = {
  __typename?: 'Mutation';
  startWorkflow: {
    __typename?: 'StartWorkflowPayload';
    workflowExecution: {
      __typename?: 'WorkflowExecution';
      workflowId: string;
      runId: string | null;
    };
  } | null;
};

export type GQCheckAdminAuthenticationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQCheckAdminAuthenticationQuery = {
  __typename?: 'Query';
  activeWatershedEmployee: {
    __typename?: 'WatershedEmployee';
    id: string;
  } | null;
};

export type GQMarketplaceProjectForArchetypeFieldsFragment = {
  __typename?: 'MarketplaceProject';
  id: string;
  name: string;
};

export type GQMarketplaceProjectArchetypeFieldsFragment = {
  __typename?: 'MarketplaceProjectArchetype';
  id: string;
  kind: GQMarketplaceProjectArchetypeKind;
  unit: GQMarketplaceProjectArchetypeUnit;
  isUserVisible: boolean;
  name: string;
  descriptionMd: string;
  summaryMd: string;
  imageUrl: string | null;
  bannerImageUrl: string | null;
  certificateBlurb: string | null;
  certificateColor: string | null;
  additionalityRating: number | null;
  permanenceRating: string | null;
  projects: {
    __typename?: 'MarketplaceProjectConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectEdge';
      node: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
      } | null;
    } | null>;
  };
  offerings: {
    __typename?: 'MarketplaceProjectOfferingConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectOfferingEdge';
      node: {
        __typename: 'MarketplaceProjectOffering';
        id: string;
        vintage: number | null;
        createdAt: Date;
        availability: number;
        priceUsdCents: number;
        priceWithFeeUsdCents: number;
        noteMd: string | null;
        supplier: {
          __typename?: 'MarketplaceSupplier';
          id: string;
          name: string;
        };
        project: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
          deletedAt: Date | null;
          status: GQMarketplaceProjectStatus;
          archetype: {
            __typename?: 'MarketplaceProjectArchetype';
            id: string;
            name: string;
            unit: GQMarketplaceProjectArchetypeUnit;
            mechanism: GQMarketplaceProjectArchetypeMechanism;
          };
        };
      } | null;
    } | null>;
  };
};

export type GQMarketplaceProjectArchetypeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMarketplaceProjectArchetypeQuery = {
  __typename?: 'Query';
  marketplaceProjectArchetype: {
    __typename?: 'MarketplaceProjectArchetype';
    id: string;
    kind: GQMarketplaceProjectArchetypeKind;
    unit: GQMarketplaceProjectArchetypeUnit;
    isUserVisible: boolean;
    name: string;
    descriptionMd: string;
    summaryMd: string;
    imageUrl: string | null;
    bannerImageUrl: string | null;
    certificateBlurb: string | null;
    certificateColor: string | null;
    additionalityRating: number | null;
    permanenceRating: string | null;
    projects: {
      __typename?: 'MarketplaceProjectConnection';
      edges: Array<{
        __typename?: 'MarketplaceProjectEdge';
        node: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
        } | null;
      } | null>;
    };
    offerings: {
      __typename?: 'MarketplaceProjectOfferingConnection';
      edges: Array<{
        __typename?: 'MarketplaceProjectOfferingEdge';
        node: {
          __typename: 'MarketplaceProjectOffering';
          id: string;
          vintage: number | null;
          createdAt: Date;
          availability: number;
          priceUsdCents: number;
          priceWithFeeUsdCents: number;
          noteMd: string | null;
          supplier: {
            __typename?: 'MarketplaceSupplier';
            id: string;
            name: string;
          };
          project: {
            __typename?: 'MarketplaceProject';
            id: string;
            name: string;
            deletedAt: Date | null;
            status: GQMarketplaceProjectStatus;
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
              unit: GQMarketplaceProjectArchetypeUnit;
              mechanism: GQMarketplaceProjectArchetypeMechanism;
            };
          };
        } | null;
      } | null>;
    };
  };
};

export type GQMarketplaceProjectArchetypeListFieldsFragment = {
  __typename: 'MarketplaceProjectArchetype';
  id: string;
  name: string;
  isUserVisible: boolean;
  mechanism: GQMarketplaceProjectArchetypeMechanism;
  kind: GQMarketplaceProjectArchetypeKind;
  unit: GQMarketplaceProjectArchetypeUnit;
  summaryMd: string;
  priceUsdCentsMin: number | null;
  priceUsdCentsMax: number | null;
  projects: {
    __typename?: 'MarketplaceProjectConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectEdge';
      node: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
      } | null;
    } | null>;
  };
};

export type GQMarketplaceProjectArchetypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQMarketplaceProjectArchetypesQuery = {
  __typename?: 'Query';
  marketplaceProjectArchetypes: {
    __typename?: 'MarketplaceProjectArchetypeConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectArchetypeEdge';
      node: {
        __typename: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
        isUserVisible: boolean;
        mechanism: GQMarketplaceProjectArchetypeMechanism;
        kind: GQMarketplaceProjectArchetypeKind;
        unit: GQMarketplaceProjectArchetypeUnit;
        summaryMd: string;
        priceUsdCentsMin: number | null;
        priceUsdCentsMax: number | null;
        projects: {
          __typename?: 'MarketplaceProjectConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectEdge';
            node: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
};

export type GQMarketplaceDeveloperFieldsFragment = {
  __typename?: 'MarketplaceDeveloper';
  id: string;
  name: string;
  imageUrl: string | null;
  url: string | null;
  descriptionMd: string | null;
  deletedAt: Date | null;
};

export type GQMarketplaceDeveloperWithProjectsFieldsFragment = {
  __typename?: 'MarketplaceDeveloper';
  id: string;
  name: string;
  imageUrl: string | null;
  url: string | null;
  descriptionMd: string | null;
  deletedAt: Date | null;
  projects: {
    __typename?: 'MarketplaceProjectConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectEdge';
      node: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
      } | null;
    } | null>;
  };
};

export type GQMarketplaceDeveloperPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMarketplaceDeveloperPageQuery = {
  __typename?: 'Query';
  marketplaceDeveloper: {
    __typename?: 'MarketplaceDeveloper';
    id: string;
    name: string;
    imageUrl: string | null;
    url: string | null;
    descriptionMd: string | null;
    deletedAt: Date | null;
    projects: {
      __typename?: 'MarketplaceProjectConnection';
      edges: Array<{
        __typename?: 'MarketplaceProjectEdge';
        node: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
        } | null;
      } | null>;
    };
  };
};

export type GQCreateMarketplaceDeveloperMutationVariables = Exact<{
  input: GQCreateMarketplaceDeveloperInput;
}>;

export type GQCreateMarketplaceDeveloperMutation = {
  __typename?: 'Mutation';
  createMarketplaceDeveloper: {
    __typename?: 'CreateMarketplaceDeveloperPayload';
    marketplaceDeveloper: {
      __typename?: 'MarketplaceDeveloper';
      id: string;
      name: string;
      imageUrl: string | null;
      url: string | null;
      descriptionMd: string | null;
      deletedAt: Date | null;
    };
  } | null;
};

export type GQUpdateMarketplaceDeveloperMutationVariables = Exact<{
  input: GQUpdateMarketplaceDeveloperInput;
}>;

export type GQUpdateMarketplaceDeveloperMutation = {
  __typename?: 'Mutation';
  updateMarketplaceDeveloper: {
    __typename?: 'UpdateMarketplaceDeveloperPayload';
    marketplaceDeveloper: {
      __typename?: 'MarketplaceDeveloper';
      id: string;
      name: string;
      imageUrl: string | null;
      url: string | null;
      descriptionMd: string | null;
      deletedAt: Date | null;
    };
  } | null;
};

export type GQDeleteMarketplaceDeveloperMutationVariables = Exact<{
  input: GQDeleteMarketplaceDeveloperInput;
}>;

export type GQDeleteMarketplaceDeveloperMutation = {
  __typename?: 'Mutation';
  deleteMarketplaceDeveloper: {
    __typename?: 'DeleteMarketplaceDeveloperPayload';
    marketplaceDeveloper: {
      __typename?: 'MarketplaceDeveloper';
      id: string;
      name: string;
      imageUrl: string | null;
      url: string | null;
      descriptionMd: string | null;
      deletedAt: Date | null;
    };
    marketplaceProjects: Array<{
      __typename?: 'MarketplaceProject';
      id: string;
      name: string;
      deletedAt: Date | null;
      status: GQMarketplaceProjectStatus;
      internalNotesMd: string | null;
      registryId: string | null;
      archetype: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
      };
      developer: {
        __typename?: 'MarketplaceDeveloper';
        id: string;
        name: string;
        imageUrl: string | null;
        url: string | null;
        descriptionMd: string | null;
        deletedAt: Date | null;
      } | null;
      supplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
      } | null;
      offerings: {
        __typename?: 'MarketplaceProjectOfferingConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectOfferingEdge';
          node: {
            __typename: 'MarketplaceProjectOffering';
            id: string;
            vintage: number | null;
            createdAt: Date;
            availability: number;
            priceUsdCents: number;
            priceWithFeeUsdCents: number;
            noteMd: string | null;
            supplier: {
              __typename?: 'MarketplaceSupplier';
              id: string;
              name: string;
            };
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              deletedAt: Date | null;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
                mechanism: GQMarketplaceProjectArchetypeMechanism;
              };
            };
          } | null;
        } | null>;
      };
    }>;
  } | null;
};

export type GQMarketplaceDeveloperListFieldsFragment = {
  __typename?: 'MarketplaceDeveloper';
  id: string;
  name: string;
  imageUrl: string | null;
  url: string | null;
  descriptionMd: string | null;
  deletedAt: Date | null;
};

export type GQMarketplaceDevelopersListPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQMarketplaceDevelopersListPageQuery = {
  __typename?: 'Query';
  marketplaceDevelopers: {
    __typename?: 'MarketplaceDeveloperConnection';
    edges: Array<{
      __typename?: 'MarketplaceDeveloperEdge';
      node: {
        __typename?: 'MarketplaceDeveloper';
        id: string;
        name: string;
        imageUrl: string | null;
        url: string | null;
        descriptionMd: string | null;
        deletedAt: Date | null;
      } | null;
    } | null>;
  };
};

export type GQMarketplaceDocumentForDetailPageFragment = {
  __typename?: 'MarketplaceDocument';
  id: string;
  name: string;
  createdAt: Date;
  deletedAt: Date | null;
  kind: GQMarketplaceDocumentKind;
  noteMd: string | null;
  downloadUrl: string;
  uploader: {
    __typename?: 'WatershedEmployee';
    id: string;
    name: string;
  } | null;
  purchase: {
    __typename?: 'MarketplacePurchase';
    id: string;
    name: string;
  } | null;
  purchaseLineItem: {
    __typename?: 'MarketplacePurchaseLineItem';
    id: string;
    purchase: { __typename?: 'MarketplacePurchase'; id: string; name: string };
    offering: {
      __typename?: 'MarketplaceProjectOffering';
      id: string;
      vintage: number | null;
      project: { __typename?: 'MarketplaceProject'; id: string; name: string };
      supplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
      };
    } | null;
  } | null;
};

export type GQMarketplaceDocumentPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMarketplaceDocumentPageQuery = {
  __typename?: 'Query';
  marketplaceDocument: {
    __typename?: 'MarketplaceDocument';
    id: string;
    name: string;
    createdAt: Date;
    deletedAt: Date | null;
    kind: GQMarketplaceDocumentKind;
    noteMd: string | null;
    downloadUrl: string;
    uploader: {
      __typename?: 'WatershedEmployee';
      id: string;
      name: string;
    } | null;
    purchase: {
      __typename?: 'MarketplacePurchase';
      id: string;
      name: string;
    } | null;
    purchaseLineItem: {
      __typename?: 'MarketplacePurchaseLineItem';
      id: string;
      purchase: {
        __typename?: 'MarketplacePurchase';
        id: string;
        name: string;
      };
      offering: {
        __typename?: 'MarketplaceProjectOffering';
        id: string;
        vintage: number | null;
        project: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
        };
        supplier: {
          __typename?: 'MarketplaceSupplier';
          id: string;
          name: string;
        };
      } | null;
    } | null;
  };
};

export type GQUpdateMarketplaceDocumentMutationVariables = Exact<{
  input: GQUpdateMarketplaceDocumentInput;
}>;

export type GQUpdateMarketplaceDocumentMutation = {
  __typename?: 'Mutation';
  updateMarketplaceDocument: {
    __typename?: 'UpdateMarketplaceDocumentPayload';
    document: {
      __typename?: 'MarketplaceDocument';
      id: string;
      name: string;
      createdAt: Date;
      deletedAt: Date | null;
      kind: GQMarketplaceDocumentKind;
      noteMd: string | null;
      downloadUrl: string;
      uploader: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
      purchase: {
        __typename?: 'MarketplacePurchase';
        id: string;
        name: string;
      } | null;
      purchaseLineItem: {
        __typename?: 'MarketplacePurchaseLineItem';
        id: string;
        purchase: {
          __typename?: 'MarketplacePurchase';
          id: string;
          name: string;
        };
        offering: {
          __typename?: 'MarketplaceProjectOffering';
          id: string;
          vintage: number | null;
          project: {
            __typename?: 'MarketplaceProject';
            id: string;
            name: string;
          };
          supplier: {
            __typename?: 'MarketplaceSupplier';
            id: string;
            name: string;
          };
        } | null;
      } | null;
    };
  } | null;
};

export type GQDeleteMarketplaceDocumentMutationVariables = Exact<{
  input: GQDeleteMarketplaceDocumentInput;
}>;

export type GQDeleteMarketplaceDocumentMutation = {
  __typename?: 'Mutation';
  deleteMarketplaceDocument: {
    __typename?: 'DeleteMarketplaceDocumentPayload';
    document: {
      __typename?: 'MarketplaceDocument';
      id: string;
      name: string;
      createdAt: Date;
      deletedAt: Date | null;
      kind: GQMarketplaceDocumentKind;
      noteMd: string | null;
      downloadUrl: string;
      uploader: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
      purchase: {
        __typename?: 'MarketplacePurchase';
        id: string;
        name: string;
      } | null;
      purchaseLineItem: {
        __typename?: 'MarketplacePurchaseLineItem';
        id: string;
        purchase: {
          __typename?: 'MarketplacePurchase';
          id: string;
          name: string;
        };
        offering: {
          __typename?: 'MarketplaceProjectOffering';
          id: string;
          vintage: number | null;
          project: {
            __typename?: 'MarketplaceProject';
            id: string;
            name: string;
          };
          supplier: {
            __typename?: 'MarketplaceSupplier';
            id: string;
            name: string;
          };
        } | null;
      } | null;
    };
  } | null;
};

export type GQMarketplaceSupplierForMarketplacePurchaseLineItemFragment = {
  __typename?: 'MarketplaceSupplier';
  id: string;
  name: string;
};

export type GQMarketplacePurchaseLineItemFieldsForMarketplaceDocumentListFragment =
  {
    __typename?: 'MarketplacePurchaseLineItem';
    id: string;
    offering: {
      __typename?: 'MarketplaceProjectOffering';
      id: string;
      supplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
      };
    } | null;
  };

export type GQMarketplaceDocumentListFieldsFragment = {
  __typename?: 'MarketplaceDocument';
  createdAt: Date;
  deletedAt: Date | null;
  id: string;
  kind: GQMarketplaceDocumentKind;
  name: string;
  downloadUrl: string;
  org: { __typename?: 'Organization'; id: string; name: string };
  uploader: {
    __typename?: 'WatershedEmployee';
    id: string;
    name: string;
  } | null;
  purchaseLineItem: {
    __typename?: 'MarketplacePurchaseLineItem';
    id: string;
    offering: {
      __typename?: 'MarketplaceProjectOffering';
      id: string;
      supplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
      };
    } | null;
  } | null;
  purchase: {
    __typename?: 'MarketplacePurchase';
    id: string;
    lineItems: {
      __typename?: 'MarketplacePurchaseLineItemConnection';
      edges: Array<{
        __typename?: 'MarketplacePurchaseLineItemEdge';
        node: {
          __typename?: 'MarketplacePurchaseLineItem';
          id: string;
          offering: {
            __typename?: 'MarketplaceProjectOffering';
            id: string;
            supplier: {
              __typename?: 'MarketplaceSupplier';
              id: string;
              name: string;
            };
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GQMarketplaceDocumentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQMarketplaceDocumentsQuery = {
  __typename?: 'Query';
  marketplaceDocuments: {
    __typename?: 'MarketplaceDocumentConnection';
    edges: Array<{
      __typename?: 'MarketplaceDocumentEdge';
      node: {
        __typename?: 'MarketplaceDocument';
        createdAt: Date;
        deletedAt: Date | null;
        id: string;
        kind: GQMarketplaceDocumentKind;
        name: string;
        downloadUrl: string;
        org: { __typename?: 'Organization'; id: string; name: string };
        uploader: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        } | null;
        purchaseLineItem: {
          __typename?: 'MarketplacePurchaseLineItem';
          id: string;
          offering: {
            __typename?: 'MarketplaceProjectOffering';
            id: string;
            supplier: {
              __typename?: 'MarketplaceSupplier';
              id: string;
              name: string;
            };
          } | null;
        } | null;
        purchase: {
          __typename?: 'MarketplacePurchase';
          id: string;
          lineItems: {
            __typename?: 'MarketplacePurchaseLineItemConnection';
            edges: Array<{
              __typename?: 'MarketplacePurchaseLineItemEdge';
              node: {
                __typename?: 'MarketplacePurchaseLineItem';
                id: string;
                offering: {
                  __typename?: 'MarketplaceProjectOffering';
                  id: string;
                  supplier: {
                    __typename?: 'MarketplaceSupplier';
                    id: string;
                    name: string;
                  };
                } | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null;
    } | null>;
  };
};

export type GQMarketplacePurchaseListFieldsFragment = {
  __typename?: 'MarketplacePurchase';
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  totalCostUsdCents: number;
  totalFeeUsdCents: number;
  status: GQMarketplacePurchaseStatus;
  offerExpiration: Date | null;
  waitingOnParty: GQMarketplaceWaitingOnParty | null;
  customerStatus: GQCustomerStatus;
  noteMd: string;
  org: {
    __typename?: 'Organization';
    id: string;
    name: string;
    demoOrg: boolean;
  };
  eacMetadata: {
    __typename?: 'MarketplacePurchaseEacMetadata';
    id: string;
    requestNotes: string | null;
    interval: YMInterval;
    ghgCategoryIds: Array<string> | null;
    marketTco2e: number;
  } | null;
  documents: {
    __typename?: 'MarketplaceDocumentConnection';
    edges: Array<{
      __typename?: 'MarketplaceDocumentEdge';
      node: {
        __typename?: 'MarketplaceDocument';
        id: string;
        kind: GQMarketplaceDocumentKind;
      } | null;
    } | null>;
  };
};

export type GQMarketplaceIndexPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQMarketplaceIndexPageQuery = {
  __typename?: 'Query';
  marketplacePurchases: {
    __typename?: 'MarketplacePurchaseConnection';
    edges: Array<{
      __typename?: 'MarketplacePurchaseEdge';
      node: {
        __typename?: 'MarketplacePurchase';
        id: string;
        createdAt: Date;
        updatedAt: Date;
        name: string;
        totalCostUsdCents: number;
        totalFeeUsdCents: number;
        status: GQMarketplacePurchaseStatus;
        offerExpiration: Date | null;
        waitingOnParty: GQMarketplaceWaitingOnParty | null;
        customerStatus: GQCustomerStatus;
        noteMd: string;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
        };
        eacMetadata: {
          __typename?: 'MarketplacePurchaseEacMetadata';
          id: string;
          requestNotes: string | null;
          interval: YMInterval;
          ghgCategoryIds: Array<string> | null;
          marketTco2e: number;
        } | null;
        documents: {
          __typename?: 'MarketplaceDocumentConnection';
          edges: Array<{
            __typename?: 'MarketplaceDocumentEdge';
            node: {
              __typename?: 'MarketplaceDocument';
              id: string;
              kind: GQMarketplaceDocumentKind;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
  marketplacePurchaseLineItems: {
    __typename?: 'MarketplacePurchaseLineItemConnection';
    edges: Array<{
      __typename?: 'MarketplacePurchaseLineItemEdge';
      node: {
        __typename?: 'MarketplacePurchaseLineItem';
        id: string;
        createdAt: Date;
        updatedAt: Date;
        cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
        vintage: number | null;
        amount: number;
        amountUnit: GQMarketplaceProjectArchetypeUnit;
        mechanism: GQMarketplaceProjectArchetypeMechanism;
        coverageInterval: YMInterval;
        unitPriceUsdCents: number;
        unitFeeUsdCents: number;
        deliveryDate: Date | null;
        closeDate: Date | null;
        status: GQMarketplacePurchaseLineItemStatus;
        waitingOnParty: GQMarketplaceWaitingOnParty | null;
        noteMd: string;
        appliedToFootprint: boolean | null;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
        };
        purchase: {
          __typename?: 'MarketplacePurchase';
          id: string;
          name: string;
        };
        archetype: {
          __typename?: 'MarketplaceProjectArchetype';
          id: string;
          name: string;
        } | null;
        project: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
          supplier: {
            __typename?: 'MarketplaceSupplier';
            id: string;
            name: string;
          } | null;
        } | null;
        offering: {
          __typename?: 'MarketplaceProjectOffering';
          id: string;
          vintage: number | null;
        } | null;
        documents: {
          __typename?: 'MarketplaceDocumentConnection';
          edges: Array<{
            __typename?: 'MarketplaceDocumentEdge';
            node: {
              __typename?: 'MarketplaceDocument';
              id: string;
              kind: GQMarketplaceDocumentKind;
            } | null;
          } | null>;
        };
        eacMetadata: {
          __typename?: 'MarketplacePurchaseLineItemEacMetadata';
          id: string;
          countryWithEmissions: string;
          countryWithEacs: string;
          numEacsRequested: number;
          mwh: number;
          marketTco2e: number;
        } | null;
      } | null;
    } | null>;
  };
  organizations: {
    __typename?: 'OrgConnection';
    edges: Array<{
      __typename?: 'OrgEdge';
      node: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
      };
    } | null>;
  };
};

export type GQCreateMarketplacePurchaseMutationVariables = Exact<{
  input: GQCreateMarketplacePurchaseInput;
}>;

export type GQCreateMarketplacePurchaseMutation = {
  __typename?: 'Mutation';
  createMarketplacePurchase: {
    __typename?: 'CreateMarketplacePurchasePayload';
    marketplacePurchase: {
      __typename?: 'MarketplacePurchase';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      offerExpiration: Date | null;
      name: string;
      status: GQMarketplacePurchaseStatus;
      waitingOnParty: GQMarketplaceWaitingOnParty | null;
      customerStatus: GQCustomerStatus;
      noteMd: string;
      totalCostUsdCents: number;
      totalFeeUsdCents: number;
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        productWaitlistTypes: Array<GQProductWaitlistType>;
      };
      portfolio: {
        __typename?: 'MarketplacePortfolio';
        id: string;
        name: string;
        targetAmount: number | null;
        notes: string;
        footprintTco2e: number | null;
        budgetUsdCents: number;
        interval: YMInterval | null;
        investInRd: boolean;
        targetFraction: number | null;
        offsetTargetAmount: number | null;
        offsetTargetFraction: number | null;
        cleanPowerTargetAmount: number | null;
        portfolioItems: {
          __typename?: 'MarketplacePortfolioItemConnection';
          edges: Array<{
            __typename?: 'MarketplacePortfolioItemEdge';
            node: {
              __typename?: 'MarketplacePortfolioItem';
              id: string;
              amount: number;
              priceUsdCents: number;
              fraction: number;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
              };
              portfolio: {
                __typename?: 'MarketplacePortfolio';
                id: string;
                interval: YMInterval | null;
              };
            } | null;
          }>;
        };
      } | null;
      editor:
        | { __typename: 'User'; id: string; name: string }
        | { __typename: 'WatershedEmployee'; id: string; name: string }
        | null;
      customerUser: { __typename?: 'User'; id: string; name: string } | null;
      history: {
        __typename?: 'MarketplacePurchaseHistoryConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseHistoryEdge';
          node: {
            __typename?: 'MarketplacePurchaseHistory';
            id: string;
            name: string;
            status: GQMarketplacePurchaseStatus;
            offerExpiration: Date | null;
            recordStartAt: Date;
            recordEndAt: Date | null;
            editor:
              | { __typename: 'User'; id: string; name: string }
              | { __typename: 'WatershedEmployee'; id: string; name: string }
              | null;
          } | null;
        } | null>;
      };
      lineItems: {
        __typename?: 'MarketplacePurchaseLineItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseLineItemEdge';
          node: {
            __typename?: 'MarketplacePurchaseLineItem';
            id: string;
            createdAt: Date;
            updatedAt: Date;
            cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
            vintage: number | null;
            amount: number;
            amountUnit: GQMarketplaceProjectArchetypeUnit;
            mechanism: GQMarketplaceProjectArchetypeMechanism;
            coverageInterval: YMInterval;
            unitPriceUsdCents: number;
            unitFeeUsdCents: number;
            deliveryDate: Date | null;
            closeDate: Date | null;
            status: GQMarketplacePurchaseLineItemStatus;
            waitingOnParty: GQMarketplaceWaitingOnParty | null;
            noteMd: string;
            appliedToFootprint: boolean | null;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
            };
            purchase: {
              __typename?: 'MarketplacePurchase';
              id: string;
              name: string;
            };
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
            } | null;
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              supplier: {
                __typename?: 'MarketplaceSupplier';
                id: string;
                name: string;
              } | null;
            } | null;
            offering: {
              __typename?: 'MarketplaceProjectOffering';
              id: string;
              vintage: number | null;
            } | null;
            documents: {
              __typename?: 'MarketplaceDocumentConnection';
              edges: Array<{
                __typename?: 'MarketplaceDocumentEdge';
                node: {
                  __typename?: 'MarketplaceDocument';
                  id: string;
                  kind: GQMarketplaceDocumentKind;
                } | null;
              } | null>;
            };
            eacMetadata: {
              __typename?: 'MarketplacePurchaseLineItemEacMetadata';
              id: string;
              countryWithEmissions: string;
              countryWithEacs: string;
              numEacsRequested: number;
              mwh: number;
              marketTco2e: number;
            } | null;
          } | null;
        } | null>;
      };
      eacMetadata: {
        __typename?: 'MarketplacePurchaseEacMetadata';
        id: string;
        updatedAt: Date;
        requestNotes: string | null;
        interval: YMInterval;
        ghgCategoryIds: Array<string> | null;
        marketTco2e: number;
      } | null;
    };
  } | null;
};

export type GQMarketplaceProjectOfferingFieldsFragment = {
  __typename: 'MarketplaceProjectOffering';
  id: string;
  vintage: number | null;
  createdAt: Date;
  availability: number;
  priceUsdCents: number;
  priceWithFeeUsdCents: number;
  noteMd: string | null;
  supplier: { __typename?: 'MarketplaceSupplier'; id: string; name: string };
  project: {
    __typename?: 'MarketplaceProject';
    id: string;
    name: string;
    deletedAt: Date | null;
    status: GQMarketplaceProjectStatus;
    archetype: {
      __typename?: 'MarketplaceProjectArchetype';
      id: string;
      name: string;
      unit: GQMarketplaceProjectArchetypeUnit;
      mechanism: GQMarketplaceProjectArchetypeMechanism;
    };
  };
};

export type GQMarketplaceProjectOfferingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMarketplaceProjectOfferingQuery = {
  __typename?: 'Query';
  marketplaceProjectOffering: {
    __typename: 'MarketplaceProjectOffering';
    id: string;
    vintage: number | null;
    createdAt: Date;
    availability: number;
    priceUsdCents: number;
    priceWithFeeUsdCents: number;
    noteMd: string | null;
    supplier: { __typename?: 'MarketplaceSupplier'; id: string; name: string };
    project: {
      __typename?: 'MarketplaceProject';
      id: string;
      name: string;
      deletedAt: Date | null;
      status: GQMarketplaceProjectStatus;
      archetype: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
        unit: GQMarketplaceProjectArchetypeUnit;
        mechanism: GQMarketplaceProjectArchetypeMechanism;
      };
    };
  };
};

export type GQMarketplaceProjectOfferingUpdatesQueryVariables = Exact<{
  baseOfferingId: Scalars['ID']['input'];
}>;

export type GQMarketplaceProjectOfferingUpdatesQuery = {
  __typename?: 'Query';
  marketplaceProjectOfferingUpdates: {
    __typename?: 'MarketplaceProjectOfferingConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectOfferingEdge';
      node: {
        __typename: 'MarketplaceProjectOffering';
        id: string;
        vintage: number | null;
        createdAt: Date;
        availability: number;
        priceUsdCents: number;
        priceWithFeeUsdCents: number;
        noteMd: string | null;
        supplier: {
          __typename?: 'MarketplaceSupplier';
          id: string;
          name: string;
        };
        project: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
          deletedAt: Date | null;
          status: GQMarketplaceProjectStatus;
          archetype: {
            __typename?: 'MarketplaceProjectArchetype';
            id: string;
            name: string;
            unit: GQMarketplaceProjectArchetypeUnit;
            mechanism: GQMarketplaceProjectArchetypeMechanism;
          };
        };
      } | null;
    } | null>;
  };
};

export type GQMarketplaceProjectOfferingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQMarketplaceProjectOfferingsQuery = {
  __typename?: 'Query';
  marketplaceProjectOfferings: {
    __typename?: 'MarketplaceProjectOfferingConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectOfferingEdge';
      node: {
        __typename: 'MarketplaceProjectOffering';
        id: string;
        vintage: number | null;
        createdAt: Date;
        availability: number;
        priceUsdCents: number;
        priceWithFeeUsdCents: number;
        noteMd: string | null;
        supplier: {
          __typename?: 'MarketplaceSupplier';
          id: string;
          name: string;
        };
        project: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
          deletedAt: Date | null;
          status: GQMarketplaceProjectStatus;
          archetype: {
            __typename?: 'MarketplaceProjectArchetype';
            id: string;
            name: string;
            unit: GQMarketplaceProjectArchetypeUnit;
            mechanism: GQMarketplaceProjectArchetypeMechanism;
          };
        };
      } | null;
    } | null>;
  };
  marketplaceProjectArchetypes: {
    __typename?: 'MarketplaceProjectArchetypeConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectArchetypeEdge';
      node: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
      } | null;
    } | null>;
  };
  marketplaceSuppliers: {
    __typename?: 'MarketplaceSupplierConnection';
    edges: Array<{
      __typename?: 'MarketplaceSupplierEdge';
      node: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
      } | null;
    } | null>;
  };
  marketplaceProjects: {
    __typename?: 'MarketplaceProjectConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectEdge';
      node: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
        deletedAt: Date | null;
        archetype: { __typename?: 'MarketplaceProjectArchetype'; id: string };
      } | null;
    } | null>;
  };
};

export type GQEacPriceEstimateFieldsFragment = {
  __typename?: 'EacPriceEstimate';
  id: string;
  updatedAt: Date;
  country: string;
  alternativeCountries: Array<string>;
  minCreditPurchase: number;
  standard: string;
  priceEstimateUsdCents: number;
  defaultProject: {
    __typename?: 'MarketplaceProject';
    id: string;
    name: string;
  } | null;
};

export type GQGetEacPriceEstimatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetEacPriceEstimatesQuery = {
  __typename?: 'Query';
  eacPriceEstimates: Array<{
    __typename?: 'EacPriceEstimate';
    id: string;
    updatedAt: Date;
    country: string;
    alternativeCountries: Array<string>;
    minCreditPurchase: number;
    standard: string;
    priceEstimateUsdCents: number;
    defaultProject: {
      __typename?: 'MarketplaceProject';
      id: string;
      name: string;
    } | null;
  }>;
};

export type GQCreateEacPriceEstimateMutationVariables = Exact<{
  input: GQCreateEacPriceEstimateInput;
}>;

export type GQCreateEacPriceEstimateMutation = {
  __typename?: 'Mutation';
  createEacPriceEstimate: {
    __typename?: 'CreateEacPriceEstimatePayload';
    id: string;
    eacPriceEstimate: {
      __typename?: 'EacPriceEstimate';
      id: string;
      updatedAt: Date;
      country: string;
      alternativeCountries: Array<string>;
      minCreditPurchase: number;
      standard: string;
      priceEstimateUsdCents: number;
      defaultProject: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
      } | null;
    };
  };
};

export type GQUpdateEacPriceEstimateMutationVariables = Exact<{
  input: GQUpdateEacPriceEstimateInput;
}>;

export type GQUpdateEacPriceEstimateMutation = {
  __typename?: 'Mutation';
  updateEacPriceEstimate: {
    __typename?: 'UpdateEacPriceEstimatePayload';
    id: string;
    eacPriceEstimate: {
      __typename?: 'EacPriceEstimate';
      id: string;
      updatedAt: Date;
      country: string;
      alternativeCountries: Array<string>;
      minCreditPurchase: number;
      standard: string;
      priceEstimateUsdCents: number;
      defaultProject: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
      } | null;
    };
  };
};

export type GQUpdateEacPriceEstimatesMutationVariables = Exact<{
  input: GQUpdateEacPriceEstimatesInput;
}>;

export type GQUpdateEacPriceEstimatesMutation = {
  __typename?: 'Mutation';
  updateEacPriceEstimates: {
    __typename?: 'UpdateEacPriceEstimatesPayload';
    eacPriceEstimates: Array<{
      __typename?: 'EacPriceEstimate';
      id: string;
      updatedAt: Date;
      country: string;
      alternativeCountries: Array<string>;
      minCreditPurchase: number;
      standard: string;
      priceEstimateUsdCents: number;
      defaultProject: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
      } | null;
    }>;
  };
};

export type GQDeleteEacPriceEstimateMutationVariables = Exact<{
  input: GQDeleteEacPriceEstimateInput;
}>;

export type GQDeleteEacPriceEstimateMutation = {
  __typename?: 'Mutation';
  deleteEacPriceEstimate: {
    __typename?: 'DeleteEacPriceEstimatePayload';
    id: string;
  };
};

export type GQMarketplaceSupplierForProjectFieldsFragment = {
  __typename?: 'MarketplaceSupplier';
  id: string;
  name: string;
};

export type GQMarketplaceProjectFieldsFragment = {
  __typename?: 'MarketplaceProject';
  id: string;
  name: string;
  deletedAt: Date | null;
  status: GQMarketplaceProjectStatus;
  internalNotesMd: string | null;
  registryId: string | null;
  archetype: {
    __typename?: 'MarketplaceProjectArchetype';
    id: string;
    name: string;
  };
  developer: {
    __typename?: 'MarketplaceDeveloper';
    id: string;
    name: string;
    imageUrl: string | null;
    url: string | null;
    descriptionMd: string | null;
    deletedAt: Date | null;
  } | null;
  supplier: {
    __typename?: 'MarketplaceSupplier';
    id: string;
    name: string;
  } | null;
  offerings: {
    __typename?: 'MarketplaceProjectOfferingConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectOfferingEdge';
      node: {
        __typename: 'MarketplaceProjectOffering';
        id: string;
        vintage: number | null;
        createdAt: Date;
        availability: number;
        priceUsdCents: number;
        priceWithFeeUsdCents: number;
        noteMd: string | null;
        supplier: {
          __typename?: 'MarketplaceSupplier';
          id: string;
          name: string;
        };
        project: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
          deletedAt: Date | null;
          status: GQMarketplaceProjectStatus;
          archetype: {
            __typename?: 'MarketplaceProjectArchetype';
            id: string;
            name: string;
            unit: GQMarketplaceProjectArchetypeUnit;
            mechanism: GQMarketplaceProjectArchetypeMechanism;
          };
        };
      } | null;
    } | null>;
  };
};

export type GQMarketplaceProjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMarketplaceProjectQuery = {
  __typename?: 'Query';
  marketplaceProject: {
    __typename?: 'MarketplaceProject';
    id: string;
    name: string;
    deletedAt: Date | null;
    status: GQMarketplaceProjectStatus;
    internalNotesMd: string | null;
    registryId: string | null;
    archetype: {
      __typename?: 'MarketplaceProjectArchetype';
      id: string;
      name: string;
    };
    developer: {
      __typename?: 'MarketplaceDeveloper';
      id: string;
      name: string;
      imageUrl: string | null;
      url: string | null;
      descriptionMd: string | null;
      deletedAt: Date | null;
    } | null;
    supplier: {
      __typename?: 'MarketplaceSupplier';
      id: string;
      name: string;
    } | null;
    offerings: {
      __typename?: 'MarketplaceProjectOfferingConnection';
      edges: Array<{
        __typename?: 'MarketplaceProjectOfferingEdge';
        node: {
          __typename: 'MarketplaceProjectOffering';
          id: string;
          vintage: number | null;
          createdAt: Date;
          availability: number;
          priceUsdCents: number;
          priceWithFeeUsdCents: number;
          noteMd: string | null;
          supplier: {
            __typename?: 'MarketplaceSupplier';
            id: string;
            name: string;
          };
          project: {
            __typename?: 'MarketplaceProject';
            id: string;
            name: string;
            deletedAt: Date | null;
            status: GQMarketplaceProjectStatus;
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
              unit: GQMarketplaceProjectArchetypeUnit;
              mechanism: GQMarketplaceProjectArchetypeMechanism;
            };
          };
        } | null;
      } | null>;
    };
  };
};

export type GQDeleteMarketplaceProjectMutationVariables = Exact<{
  input: GQDeleteMarketplaceProjectInput;
}>;

export type GQDeleteMarketplaceProjectMutation = {
  __typename?: 'Mutation';
  deleteMarketplaceProject: {
    __typename?: 'DeleteMarketplaceProjectPayload';
    marketplaceProject: {
      __typename?: 'MarketplaceProject';
      id: string;
      name: string;
      deletedAt: Date | null;
      status: GQMarketplaceProjectStatus;
      internalNotesMd: string | null;
      registryId: string | null;
      archetype: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
      };
      developer: {
        __typename?: 'MarketplaceDeveloper';
        id: string;
        name: string;
        imageUrl: string | null;
        url: string | null;
        descriptionMd: string | null;
        deletedAt: Date | null;
      } | null;
      supplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
      } | null;
      offerings: {
        __typename?: 'MarketplaceProjectOfferingConnection';
        edges: Array<{
          __typename?: 'MarketplaceProjectOfferingEdge';
          node: {
            __typename: 'MarketplaceProjectOffering';
            id: string;
            vintage: number | null;
            createdAt: Date;
            availability: number;
            priceUsdCents: number;
            priceWithFeeUsdCents: number;
            noteMd: string | null;
            supplier: {
              __typename?: 'MarketplaceSupplier';
              id: string;
              name: string;
            };
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              deletedAt: Date | null;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
                mechanism: GQMarketplaceProjectArchetypeMechanism;
              };
            };
          } | null;
        } | null>;
      };
    };
  } | null;
};

export type GQMarketplaceProjectListFieldsFragment = {
  __typename: 'MarketplaceProject';
  id: string;
  name: string;
  status: GQMarketplaceProjectStatus;
  registryId: string | null;
  archetype: {
    __typename?: 'MarketplaceProjectArchetype';
    id: string;
    name: string;
  };
  developer: {
    __typename?: 'MarketplaceDeveloper';
    id: string;
    name: string;
    imageUrl: string | null;
    url: string | null;
    descriptionMd: string | null;
    deletedAt: Date | null;
  } | null;
  supplier: {
    __typename?: 'MarketplaceSupplier';
    id: string;
    name: string;
  } | null;
  offerings: {
    __typename?: 'MarketplaceProjectOfferingConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectOfferingEdge';
      node: { __typename?: 'MarketplaceProjectOffering'; id: string } | null;
    } | null>;
  };
};

export type GQMarketplaceProjectsQueryVariables = Exact<{
  supplierId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQMarketplaceProjectsQuery = {
  __typename?: 'Query';
  marketplaceProjects: {
    __typename?: 'MarketplaceProjectConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectEdge';
      node: {
        __typename: 'MarketplaceProject';
        id: string;
        name: string;
        status: GQMarketplaceProjectStatus;
        registryId: string | null;
        archetype: {
          __typename?: 'MarketplaceProjectArchetype';
          id: string;
          name: string;
        };
        developer: {
          __typename?: 'MarketplaceDeveloper';
          id: string;
          name: string;
          imageUrl: string | null;
          url: string | null;
          descriptionMd: string | null;
          deletedAt: Date | null;
        } | null;
        supplier: {
          __typename?: 'MarketplaceSupplier';
          id: string;
          name: string;
        } | null;
        offerings: {
          __typename?: 'MarketplaceProjectOfferingConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectOfferingEdge';
            node: {
              __typename?: 'MarketplaceProjectOffering';
              id: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
};

type GQTrackedEditorFields_User_Fragment = {
  __typename: 'User';
  id: string;
  name: string;
};

type GQTrackedEditorFields_WatershedEmployee_Fragment = {
  __typename: 'WatershedEmployee';
  id: string;
  name: string;
};

export type GQTrackedEditorFieldsFragment =
  | GQTrackedEditorFields_User_Fragment
  | GQTrackedEditorFields_WatershedEmployee_Fragment;

export type GQMarketplacePurchaseFieldsFragment = {
  __typename?: 'MarketplacePurchase';
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  offerExpiration: Date | null;
  name: string;
  status: GQMarketplacePurchaseStatus;
  waitingOnParty: GQMarketplaceWaitingOnParty | null;
  customerStatus: GQCustomerStatus;
  noteMd: string;
  totalCostUsdCents: number;
  totalFeeUsdCents: number;
  org: {
    __typename?: 'Organization';
    id: string;
    name: string;
    productWaitlistTypes: Array<GQProductWaitlistType>;
  };
  portfolio: {
    __typename?: 'MarketplacePortfolio';
    id: string;
    name: string;
    targetAmount: number | null;
    notes: string;
    footprintTco2e: number | null;
    budgetUsdCents: number;
    interval: YMInterval | null;
    investInRd: boolean;
    targetFraction: number | null;
    offsetTargetAmount: number | null;
    offsetTargetFraction: number | null;
    cleanPowerTargetAmount: number | null;
    portfolioItems: {
      __typename?: 'MarketplacePortfolioItemConnection';
      edges: Array<{
        __typename?: 'MarketplacePortfolioItemEdge';
        node: {
          __typename?: 'MarketplacePortfolioItem';
          id: string;
          amount: number;
          priceUsdCents: number;
          fraction: number;
          archetype: {
            __typename?: 'MarketplaceProjectArchetype';
            id: string;
            name: string;
            unit: GQMarketplaceProjectArchetypeUnit;
          };
          portfolio: {
            __typename?: 'MarketplacePortfolio';
            id: string;
            interval: YMInterval | null;
          };
        } | null;
      }>;
    };
  } | null;
  editor:
    | { __typename: 'User'; id: string; name: string }
    | { __typename: 'WatershedEmployee'; id: string; name: string }
    | null;
  customerUser: { __typename?: 'User'; id: string; name: string } | null;
  history: {
    __typename?: 'MarketplacePurchaseHistoryConnection';
    edges: Array<{
      __typename?: 'MarketplacePurchaseHistoryEdge';
      node: {
        __typename?: 'MarketplacePurchaseHistory';
        id: string;
        name: string;
        status: GQMarketplacePurchaseStatus;
        offerExpiration: Date | null;
        recordStartAt: Date;
        recordEndAt: Date | null;
        editor:
          | { __typename: 'User'; id: string; name: string }
          | { __typename: 'WatershedEmployee'; id: string; name: string }
          | null;
      } | null;
    } | null>;
  };
  lineItems: {
    __typename?: 'MarketplacePurchaseLineItemConnection';
    edges: Array<{
      __typename?: 'MarketplacePurchaseLineItemEdge';
      node: {
        __typename?: 'MarketplacePurchaseLineItem';
        id: string;
        createdAt: Date;
        updatedAt: Date;
        cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
        vintage: number | null;
        amount: number;
        amountUnit: GQMarketplaceProjectArchetypeUnit;
        mechanism: GQMarketplaceProjectArchetypeMechanism;
        coverageInterval: YMInterval;
        unitPriceUsdCents: number;
        unitFeeUsdCents: number;
        deliveryDate: Date | null;
        closeDate: Date | null;
        status: GQMarketplacePurchaseLineItemStatus;
        waitingOnParty: GQMarketplaceWaitingOnParty | null;
        noteMd: string;
        appliedToFootprint: boolean | null;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
        };
        purchase: {
          __typename?: 'MarketplacePurchase';
          id: string;
          name: string;
        };
        archetype: {
          __typename?: 'MarketplaceProjectArchetype';
          id: string;
          name: string;
        } | null;
        project: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
          supplier: {
            __typename?: 'MarketplaceSupplier';
            id: string;
            name: string;
          } | null;
        } | null;
        offering: {
          __typename?: 'MarketplaceProjectOffering';
          id: string;
          vintage: number | null;
        } | null;
        documents: {
          __typename?: 'MarketplaceDocumentConnection';
          edges: Array<{
            __typename?: 'MarketplaceDocumentEdge';
            node: {
              __typename?: 'MarketplaceDocument';
              id: string;
              kind: GQMarketplaceDocumentKind;
            } | null;
          } | null>;
        };
        eacMetadata: {
          __typename?: 'MarketplacePurchaseLineItemEacMetadata';
          id: string;
          countryWithEmissions: string;
          countryWithEacs: string;
          numEacsRequested: number;
          mwh: number;
          marketTco2e: number;
        } | null;
      } | null;
    } | null>;
  };
  eacMetadata: {
    __typename?: 'MarketplacePurchaseEacMetadata';
    id: string;
    updatedAt: Date;
    requestNotes: string | null;
    interval: YMInterval;
    ghgCategoryIds: Array<string> | null;
    marketTco2e: number;
  } | null;
};

export type GQMarketplacePortfolioItemFieldsForDetailPageFragment = {
  __typename?: 'MarketplacePortfolioItem';
  id: string;
  amount: number;
  priceUsdCents: number;
  fraction: number;
  archetype: {
    __typename?: 'MarketplaceProjectArchetype';
    id: string;
    name: string;
    unit: GQMarketplaceProjectArchetypeUnit;
  };
  portfolio: {
    __typename?: 'MarketplacePortfolio';
    id: string;
    interval: YMInterval | null;
  };
};

export type GQMarketplacePurchaseFieldsForDetailPageFragment = {
  __typename?: 'MarketplacePurchase';
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  offerExpiration: Date | null;
  name: string;
  status: GQMarketplacePurchaseStatus;
  waitingOnParty: GQMarketplaceWaitingOnParty | null;
  customerStatus: GQCustomerStatus;
  noteMd: string;
  totalCostUsdCents: number;
  totalFeeUsdCents: number;
  lineItems: {
    __typename?: 'MarketplacePurchaseLineItemConnection';
    edges: Array<{
      __typename?: 'MarketplacePurchaseLineItemEdge';
      node: {
        __typename?: 'MarketplacePurchaseLineItem';
        id: string;
        name: string;
        createdAt: Date;
        updatedAt: Date;
        cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
        vintage: number | null;
        amount: number;
        amountUnit: GQMarketplaceProjectArchetypeUnit;
        mechanism: GQMarketplaceProjectArchetypeMechanism;
        coverageInterval: YMInterval;
        unitPriceUsdCents: number;
        unitFeeUsdCents: number;
        deliveryDate: Date | null;
        closeDate: Date | null;
        status: GQMarketplacePurchaseLineItemStatus;
        waitingOnParty: GQMarketplaceWaitingOnParty | null;
        noteMd: string;
        appliedToFootprint: boolean | null;
        org: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
        };
        purchase: {
          __typename?: 'MarketplacePurchase';
          id: string;
          name: string;
        };
        archetype: {
          __typename?: 'MarketplaceProjectArchetype';
          id: string;
          name: string;
        } | null;
        project: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
          supplier: {
            __typename?: 'MarketplaceSupplier';
            id: string;
            name: string;
          } | null;
        } | null;
        offering: {
          __typename?: 'MarketplaceProjectOffering';
          id: string;
          vintage: number | null;
        } | null;
        documents: {
          __typename?: 'MarketplaceDocumentConnection';
          edges: Array<{
            __typename?: 'MarketplaceDocumentEdge';
            node: {
              __typename?: 'MarketplaceDocument';
              id: string;
              kind: GQMarketplaceDocumentKind;
            } | null;
          } | null>;
        };
        eacMetadata: {
          __typename?: 'MarketplacePurchaseLineItemEacMetadata';
          id: string;
          countryWithEmissions: string;
          countryWithEacs: string;
          numEacsRequested: number;
          mwh: number;
          marketTco2e: number;
        } | null;
      } | null;
    } | null>;
  };
  documents: {
    __typename?: 'MarketplaceDocumentConnection';
    edges: Array<{
      __typename?: 'MarketplaceDocumentEdge';
      node: {
        __typename?: 'MarketplaceDocument';
        id: string;
        name: string;
        createdAt: Date;
        kind: GQMarketplaceDocumentKind;
        uploader: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        } | null;
      } | null;
    } | null>;
  };
  org: {
    __typename?: 'Organization';
    id: string;
    name: string;
    productWaitlistTypes: Array<GQProductWaitlistType>;
  };
  portfolio: {
    __typename?: 'MarketplacePortfolio';
    id: string;
    name: string;
    targetAmount: number | null;
    notes: string;
    footprintTco2e: number | null;
    budgetUsdCents: number;
    interval: YMInterval | null;
    investInRd: boolean;
    targetFraction: number | null;
    offsetTargetAmount: number | null;
    offsetTargetFraction: number | null;
    cleanPowerTargetAmount: number | null;
    portfolioItems: {
      __typename?: 'MarketplacePortfolioItemConnection';
      edges: Array<{
        __typename?: 'MarketplacePortfolioItemEdge';
        node: {
          __typename?: 'MarketplacePortfolioItem';
          id: string;
          amount: number;
          priceUsdCents: number;
          fraction: number;
          archetype: {
            __typename?: 'MarketplaceProjectArchetype';
            id: string;
            name: string;
            unit: GQMarketplaceProjectArchetypeUnit;
          };
          portfolio: {
            __typename?: 'MarketplacePortfolio';
            id: string;
            interval: YMInterval | null;
          };
        } | null;
      }>;
    };
  } | null;
  editor:
    | { __typename: 'User'; id: string; name: string }
    | { __typename: 'WatershedEmployee'; id: string; name: string }
    | null;
  customerUser: { __typename?: 'User'; id: string; name: string } | null;
  history: {
    __typename?: 'MarketplacePurchaseHistoryConnection';
    edges: Array<{
      __typename?: 'MarketplacePurchaseHistoryEdge';
      node: {
        __typename?: 'MarketplacePurchaseHistory';
        id: string;
        name: string;
        status: GQMarketplacePurchaseStatus;
        offerExpiration: Date | null;
        recordStartAt: Date;
        recordEndAt: Date | null;
        editor:
          | { __typename: 'User'; id: string; name: string }
          | { __typename: 'WatershedEmployee'; id: string; name: string }
          | null;
      } | null;
    } | null>;
  };
  eacMetadata: {
    __typename?: 'MarketplacePurchaseEacMetadata';
    id: string;
    updatedAt: Date;
    requestNotes: string | null;
    interval: YMInterval;
    ghgCategoryIds: Array<string> | null;
    marketTco2e: number;
  } | null;
};

export type GQMarketplacePurchaseLineItemFieldsFragment = {
  __typename?: 'MarketplacePurchaseLineItem';
  id: string;
  createdAt: Date;
  updatedAt: Date;
  cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
  vintage: number | null;
  amount: number;
  amountUnit: GQMarketplaceProjectArchetypeUnit;
  mechanism: GQMarketplaceProjectArchetypeMechanism;
  coverageInterval: YMInterval;
  unitPriceUsdCents: number;
  unitFeeUsdCents: number;
  deliveryDate: Date | null;
  closeDate: Date | null;
  status: GQMarketplacePurchaseLineItemStatus;
  waitingOnParty: GQMarketplaceWaitingOnParty | null;
  noteMd: string;
  appliedToFootprint: boolean | null;
  org: {
    __typename?: 'Organization';
    id: string;
    name: string;
    demoOrg: boolean;
  };
  purchase: { __typename?: 'MarketplacePurchase'; id: string; name: string };
  archetype: {
    __typename?: 'MarketplaceProjectArchetype';
    id: string;
    name: string;
  } | null;
  project: {
    __typename?: 'MarketplaceProject';
    id: string;
    name: string;
    supplier: {
      __typename?: 'MarketplaceSupplier';
      id: string;
      name: string;
    } | null;
  } | null;
  offering: {
    __typename?: 'MarketplaceProjectOffering';
    id: string;
    vintage: number | null;
  } | null;
  documents: {
    __typename?: 'MarketplaceDocumentConnection';
    edges: Array<{
      __typename?: 'MarketplaceDocumentEdge';
      node: {
        __typename?: 'MarketplaceDocument';
        id: string;
        kind: GQMarketplaceDocumentKind;
      } | null;
    } | null>;
  };
  eacMetadata: {
    __typename?: 'MarketplacePurchaseLineItemEacMetadata';
    id: string;
    countryWithEmissions: string;
    countryWithEacs: string;
    numEacsRequested: number;
    mwh: number;
    marketTco2e: number;
  } | null;
};

export type GQMarketplacePurchaseQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMarketplacePurchaseQuery = {
  __typename?: 'Query';
  marketplacePurchase: {
    __typename?: 'MarketplacePurchase';
    id: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
    offerExpiration: Date | null;
    name: string;
    status: GQMarketplacePurchaseStatus;
    waitingOnParty: GQMarketplaceWaitingOnParty | null;
    customerStatus: GQCustomerStatus;
    noteMd: string;
    totalCostUsdCents: number;
    totalFeeUsdCents: number;
    lineItems: {
      __typename?: 'MarketplacePurchaseLineItemConnection';
      edges: Array<{
        __typename?: 'MarketplacePurchaseLineItemEdge';
        node: {
          __typename?: 'MarketplacePurchaseLineItem';
          id: string;
          name: string;
          createdAt: Date;
          updatedAt: Date;
          cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
          vintage: number | null;
          amount: number;
          amountUnit: GQMarketplaceProjectArchetypeUnit;
          mechanism: GQMarketplaceProjectArchetypeMechanism;
          coverageInterval: YMInterval;
          unitPriceUsdCents: number;
          unitFeeUsdCents: number;
          deliveryDate: Date | null;
          closeDate: Date | null;
          status: GQMarketplacePurchaseLineItemStatus;
          waitingOnParty: GQMarketplaceWaitingOnParty | null;
          noteMd: string;
          appliedToFootprint: boolean | null;
          org: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
          };
          purchase: {
            __typename?: 'MarketplacePurchase';
            id: string;
            name: string;
          };
          archetype: {
            __typename?: 'MarketplaceProjectArchetype';
            id: string;
            name: string;
          } | null;
          project: {
            __typename?: 'MarketplaceProject';
            id: string;
            name: string;
            supplier: {
              __typename?: 'MarketplaceSupplier';
              id: string;
              name: string;
            } | null;
          } | null;
          offering: {
            __typename?: 'MarketplaceProjectOffering';
            id: string;
            vintage: number | null;
          } | null;
          documents: {
            __typename?: 'MarketplaceDocumentConnection';
            edges: Array<{
              __typename?: 'MarketplaceDocumentEdge';
              node: {
                __typename?: 'MarketplaceDocument';
                id: string;
                kind: GQMarketplaceDocumentKind;
              } | null;
            } | null>;
          };
          eacMetadata: {
            __typename?: 'MarketplacePurchaseLineItemEacMetadata';
            id: string;
            countryWithEmissions: string;
            countryWithEacs: string;
            numEacsRequested: number;
            mwh: number;
            marketTco2e: number;
          } | null;
        } | null;
      } | null>;
    };
    documents: {
      __typename?: 'MarketplaceDocumentConnection';
      edges: Array<{
        __typename?: 'MarketplaceDocumentEdge';
        node: {
          __typename?: 'MarketplaceDocument';
          id: string;
          name: string;
          createdAt: Date;
          kind: GQMarketplaceDocumentKind;
          uploader: {
            __typename?: 'WatershedEmployee';
            id: string;
            name: string;
          } | null;
        } | null;
      } | null>;
    };
    org: {
      __typename?: 'Organization';
      id: string;
      name: string;
      productWaitlistTypes: Array<GQProductWaitlistType>;
    };
    portfolio: {
      __typename?: 'MarketplacePortfolio';
      id: string;
      name: string;
      targetAmount: number | null;
      notes: string;
      footprintTco2e: number | null;
      budgetUsdCents: number;
      interval: YMInterval | null;
      investInRd: boolean;
      targetFraction: number | null;
      offsetTargetAmount: number | null;
      offsetTargetFraction: number | null;
      cleanPowerTargetAmount: number | null;
      portfolioItems: {
        __typename?: 'MarketplacePortfolioItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePortfolioItemEdge';
          node: {
            __typename?: 'MarketplacePortfolioItem';
            id: string;
            amount: number;
            priceUsdCents: number;
            fraction: number;
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
              unit: GQMarketplaceProjectArchetypeUnit;
            };
            portfolio: {
              __typename?: 'MarketplacePortfolio';
              id: string;
              interval: YMInterval | null;
            };
          } | null;
        }>;
      };
    } | null;
    editor:
      | { __typename: 'User'; id: string; name: string }
      | { __typename: 'WatershedEmployee'; id: string; name: string }
      | null;
    customerUser: { __typename?: 'User'; id: string; name: string } | null;
    history: {
      __typename?: 'MarketplacePurchaseHistoryConnection';
      edges: Array<{
        __typename?: 'MarketplacePurchaseHistoryEdge';
        node: {
          __typename?: 'MarketplacePurchaseHistory';
          id: string;
          name: string;
          status: GQMarketplacePurchaseStatus;
          offerExpiration: Date | null;
          recordStartAt: Date;
          recordEndAt: Date | null;
          editor:
            | { __typename: 'User'; id: string; name: string }
            | { __typename: 'WatershedEmployee'; id: string; name: string }
            | null;
        } | null;
      } | null>;
    };
    eacMetadata: {
      __typename?: 'MarketplacePurchaseEacMetadata';
      id: string;
      updatedAt: Date;
      requestNotes: string | null;
      interval: YMInterval;
      ghgCategoryIds: Array<string> | null;
      marketTco2e: number;
    } | null;
  };
};

export type GQUpdateMarketplacePurchaseMutationVariables = Exact<{
  input: GQUpdateMarketplacePurchaseInput;
}>;

export type GQUpdateMarketplacePurchaseMutation = {
  __typename?: 'Mutation';
  updateMarketplacePurchase: {
    __typename?: 'UpdateMarketplacePurchasePayload';
    marketplacePurchase: {
      __typename?: 'MarketplacePurchase';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      offerExpiration: Date | null;
      name: string;
      status: GQMarketplacePurchaseStatus;
      waitingOnParty: GQMarketplaceWaitingOnParty | null;
      customerStatus: GQCustomerStatus;
      noteMd: string;
      totalCostUsdCents: number;
      totalFeeUsdCents: number;
      lineItems: {
        __typename?: 'MarketplacePurchaseLineItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseLineItemEdge';
          node: {
            __typename?: 'MarketplacePurchaseLineItem';
            id: string;
            name: string;
            createdAt: Date;
            updatedAt: Date;
            cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
            vintage: number | null;
            amount: number;
            amountUnit: GQMarketplaceProjectArchetypeUnit;
            mechanism: GQMarketplaceProjectArchetypeMechanism;
            coverageInterval: YMInterval;
            unitPriceUsdCents: number;
            unitFeeUsdCents: number;
            deliveryDate: Date | null;
            closeDate: Date | null;
            status: GQMarketplacePurchaseLineItemStatus;
            waitingOnParty: GQMarketplaceWaitingOnParty | null;
            noteMd: string;
            appliedToFootprint: boolean | null;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
            };
            purchase: {
              __typename?: 'MarketplacePurchase';
              id: string;
              name: string;
            };
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
            } | null;
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              supplier: {
                __typename?: 'MarketplaceSupplier';
                id: string;
                name: string;
              } | null;
            } | null;
            offering: {
              __typename?: 'MarketplaceProjectOffering';
              id: string;
              vintage: number | null;
            } | null;
            documents: {
              __typename?: 'MarketplaceDocumentConnection';
              edges: Array<{
                __typename?: 'MarketplaceDocumentEdge';
                node: {
                  __typename?: 'MarketplaceDocument';
                  id: string;
                  kind: GQMarketplaceDocumentKind;
                } | null;
              } | null>;
            };
            eacMetadata: {
              __typename?: 'MarketplacePurchaseLineItemEacMetadata';
              id: string;
              countryWithEmissions: string;
              countryWithEacs: string;
              numEacsRequested: number;
              mwh: number;
              marketTco2e: number;
            } | null;
          } | null;
        } | null>;
      };
      documents: {
        __typename?: 'MarketplaceDocumentConnection';
        edges: Array<{
          __typename?: 'MarketplaceDocumentEdge';
          node: {
            __typename?: 'MarketplaceDocument';
            id: string;
            name: string;
            createdAt: Date;
            kind: GQMarketplaceDocumentKind;
            uploader: {
              __typename?: 'WatershedEmployee';
              id: string;
              name: string;
            } | null;
          } | null;
        } | null>;
      };
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        productWaitlistTypes: Array<GQProductWaitlistType>;
      };
      portfolio: {
        __typename?: 'MarketplacePortfolio';
        id: string;
        name: string;
        targetAmount: number | null;
        notes: string;
        footprintTco2e: number | null;
        budgetUsdCents: number;
        interval: YMInterval | null;
        investInRd: boolean;
        targetFraction: number | null;
        offsetTargetAmount: number | null;
        offsetTargetFraction: number | null;
        cleanPowerTargetAmount: number | null;
        portfolioItems: {
          __typename?: 'MarketplacePortfolioItemConnection';
          edges: Array<{
            __typename?: 'MarketplacePortfolioItemEdge';
            node: {
              __typename?: 'MarketplacePortfolioItem';
              id: string;
              amount: number;
              priceUsdCents: number;
              fraction: number;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
              };
              portfolio: {
                __typename?: 'MarketplacePortfolio';
                id: string;
                interval: YMInterval | null;
              };
            } | null;
          }>;
        };
      } | null;
      editor:
        | { __typename: 'User'; id: string; name: string }
        | { __typename: 'WatershedEmployee'; id: string; name: string }
        | null;
      customerUser: { __typename?: 'User'; id: string; name: string } | null;
      history: {
        __typename?: 'MarketplacePurchaseHistoryConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseHistoryEdge';
          node: {
            __typename?: 'MarketplacePurchaseHistory';
            id: string;
            name: string;
            status: GQMarketplacePurchaseStatus;
            offerExpiration: Date | null;
            recordStartAt: Date;
            recordEndAt: Date | null;
            editor:
              | { __typename: 'User'; id: string; name: string }
              | { __typename: 'WatershedEmployee'; id: string; name: string }
              | null;
          } | null;
        } | null>;
      };
      eacMetadata: {
        __typename?: 'MarketplacePurchaseEacMetadata';
        id: string;
        updatedAt: Date;
        requestNotes: string | null;
        interval: YMInterval;
        ghgCategoryIds: Array<string> | null;
        marketTco2e: number;
      } | null;
    };
  } | null;
};

export type GQDeleteMarketplacePurchaseMutationVariables = Exact<{
  input: GQDeleteMarketplacePurchaseInput;
}>;

export type GQDeleteMarketplacePurchaseMutation = {
  __typename?: 'Mutation';
  deleteMarketplacePurchase: {
    __typename?: 'DeleteMarketplacePurchasePayload';
    marketplacePurchase: {
      __typename?: 'MarketplacePurchase';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      offerExpiration: Date | null;
      name: string;
      status: GQMarketplacePurchaseStatus;
      waitingOnParty: GQMarketplaceWaitingOnParty | null;
      customerStatus: GQCustomerStatus;
      noteMd: string;
      totalCostUsdCents: number;
      totalFeeUsdCents: number;
      lineItems: {
        __typename?: 'MarketplacePurchaseLineItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseLineItemEdge';
          node: {
            __typename?: 'MarketplacePurchaseLineItem';
            id: string;
            name: string;
            createdAt: Date;
            updatedAt: Date;
            cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
            vintage: number | null;
            amount: number;
            amountUnit: GQMarketplaceProjectArchetypeUnit;
            mechanism: GQMarketplaceProjectArchetypeMechanism;
            coverageInterval: YMInterval;
            unitPriceUsdCents: number;
            unitFeeUsdCents: number;
            deliveryDate: Date | null;
            closeDate: Date | null;
            status: GQMarketplacePurchaseLineItemStatus;
            waitingOnParty: GQMarketplaceWaitingOnParty | null;
            noteMd: string;
            appliedToFootprint: boolean | null;
            org: {
              __typename?: 'Organization';
              id: string;
              name: string;
              demoOrg: boolean;
            };
            purchase: {
              __typename?: 'MarketplacePurchase';
              id: string;
              name: string;
            };
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
            } | null;
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              supplier: {
                __typename?: 'MarketplaceSupplier';
                id: string;
                name: string;
              } | null;
            } | null;
            offering: {
              __typename?: 'MarketplaceProjectOffering';
              id: string;
              vintage: number | null;
            } | null;
            documents: {
              __typename?: 'MarketplaceDocumentConnection';
              edges: Array<{
                __typename?: 'MarketplaceDocumentEdge';
                node: {
                  __typename?: 'MarketplaceDocument';
                  id: string;
                  kind: GQMarketplaceDocumentKind;
                } | null;
              } | null>;
            };
            eacMetadata: {
              __typename?: 'MarketplacePurchaseLineItemEacMetadata';
              id: string;
              countryWithEmissions: string;
              countryWithEacs: string;
              numEacsRequested: number;
              mwh: number;
              marketTco2e: number;
            } | null;
          } | null;
        } | null>;
      };
      documents: {
        __typename?: 'MarketplaceDocumentConnection';
        edges: Array<{
          __typename?: 'MarketplaceDocumentEdge';
          node: {
            __typename?: 'MarketplaceDocument';
            id: string;
            name: string;
            createdAt: Date;
            kind: GQMarketplaceDocumentKind;
            uploader: {
              __typename?: 'WatershedEmployee';
              id: string;
              name: string;
            } | null;
          } | null;
        } | null>;
      };
      org: {
        __typename?: 'Organization';
        id: string;
        name: string;
        productWaitlistTypes: Array<GQProductWaitlistType>;
      };
      portfolio: {
        __typename?: 'MarketplacePortfolio';
        id: string;
        name: string;
        targetAmount: number | null;
        notes: string;
        footprintTco2e: number | null;
        budgetUsdCents: number;
        interval: YMInterval | null;
        investInRd: boolean;
        targetFraction: number | null;
        offsetTargetAmount: number | null;
        offsetTargetFraction: number | null;
        cleanPowerTargetAmount: number | null;
        portfolioItems: {
          __typename?: 'MarketplacePortfolioItemConnection';
          edges: Array<{
            __typename?: 'MarketplacePortfolioItemEdge';
            node: {
              __typename?: 'MarketplacePortfolioItem';
              id: string;
              amount: number;
              priceUsdCents: number;
              fraction: number;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                unit: GQMarketplaceProjectArchetypeUnit;
              };
              portfolio: {
                __typename?: 'MarketplacePortfolio';
                id: string;
                interval: YMInterval | null;
              };
            } | null;
          }>;
        };
      } | null;
      editor:
        | { __typename: 'User'; id: string; name: string }
        | { __typename: 'WatershedEmployee'; id: string; name: string }
        | null;
      customerUser: { __typename?: 'User'; id: string; name: string } | null;
      history: {
        __typename?: 'MarketplacePurchaseHistoryConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseHistoryEdge';
          node: {
            __typename?: 'MarketplacePurchaseHistory';
            id: string;
            name: string;
            status: GQMarketplacePurchaseStatus;
            offerExpiration: Date | null;
            recordStartAt: Date;
            recordEndAt: Date | null;
            editor:
              | { __typename: 'User'; id: string; name: string }
              | { __typename: 'WatershedEmployee'; id: string; name: string }
              | null;
          } | null;
        } | null>;
      };
      eacMetadata: {
        __typename?: 'MarketplacePurchaseEacMetadata';
        id: string;
        updatedAt: Date;
        requestNotes: string | null;
        interval: YMInterval;
        ghgCategoryIds: Array<string> | null;
        marketTco2e: number;
      } | null;
    };
  } | null;
};

export type GQMarketplacePurchaseLineItemForPurchaseLineItemPageFragment = {
  __typename?: 'MarketplacePurchaseLineItem';
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
  vintage: number | null;
  amount: number;
  amountUnit: GQMarketplaceProjectArchetypeUnit;
  mechanism: GQMarketplaceProjectArchetypeMechanism;
  coverageInterval: YMInterval;
  unitPriceUsdCents: number;
  unitFeeUsdCents: number;
  deliveryDate: Date | null;
  closeDate: Date | null;
  status: GQMarketplacePurchaseLineItemStatus;
  waitingOnParty: GQMarketplaceWaitingOnParty | null;
  noteMd: string;
  org: { __typename?: 'Organization'; id: string; name: string };
  purchase: { __typename?: 'MarketplacePurchase'; id: string; name: string };
  archetype: {
    __typename?: 'MarketplaceProjectArchetype';
    id: string;
    name: string;
  } | null;
  project: {
    __typename?: 'MarketplaceProject';
    id: string;
    name: string;
  } | null;
  offering: {
    __typename?: 'MarketplaceProjectOffering';
    id: string;
    vintage: number | null;
  } | null;
  documents: {
    __typename?: 'MarketplaceDocumentConnection';
    edges: Array<{
      __typename?: 'MarketplaceDocumentEdge';
      node: {
        __typename?: 'MarketplaceDocument';
        id: string;
        name: string;
        createdAt: Date;
        kind: GQMarketplaceDocumentKind;
        uploader: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        } | null;
      } | null;
    } | null>;
  };
  allocations: {
    __typename?: 'MarketplaceAllocationInstructionConnection';
    edges: Array<{
      __typename?: 'MarketplaceAllocationInstructionEdge';
      node: {
        __typename?: 'MarketplaceAllocationInstruction';
        id: string;
        createdAt: Date;
        deletedAt: Date | null;
        amountKwh: number;
        isZeroedOut: boolean;
        interval: YMInterval;
        locationCity: string | null;
        locationGrid: string | null;
        locationState: string | null;
        locationCountry: string | null;
        buildingName: string | null;
        vendorEntity: string | null;
        vendorSubentity: string | null;
        product: string | null;
        categoryId: string | null;
        ghgCategoryId: string;
        footprintKind: string | null;
        subcategoryId: string | null;
        results: {
          __typename?: 'MarketplaceAllocationInstructionResultConnection';
          edges: Array<{
            __typename?: 'MarketplaceAllocationInstructionResultEdge';
            node: {
              __typename?: 'MarketplaceAllocationInstructionResult';
              id: string;
              isZeroedOut: boolean;
              amountAllocatedKwh: number;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  };
  eacMetadata: {
    __typename?: 'MarketplacePurchaseLineItemEacMetadata';
    id: string;
    updatedAt: Date;
    countryWithEmissions: string;
    countryWithEacs: string;
    numEacsRequested: number;
    mwh: number;
    marketTco2e: number;
  } | null;
};

export type GQMarketplacePurchaseLineItemQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMarketplacePurchaseLineItemQuery = {
  __typename?: 'Query';
  marketplacePurchaseLineItem: {
    __typename?: 'MarketplacePurchaseLineItem';
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
    cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
    vintage: number | null;
    amount: number;
    amountUnit: GQMarketplaceProjectArchetypeUnit;
    mechanism: GQMarketplaceProjectArchetypeMechanism;
    coverageInterval: YMInterval;
    unitPriceUsdCents: number;
    unitFeeUsdCents: number;
    deliveryDate: Date | null;
    closeDate: Date | null;
    status: GQMarketplacePurchaseLineItemStatus;
    waitingOnParty: GQMarketplaceWaitingOnParty | null;
    noteMd: string;
    org: { __typename?: 'Organization'; id: string; name: string };
    purchase: { __typename?: 'MarketplacePurchase'; id: string; name: string };
    archetype: {
      __typename?: 'MarketplaceProjectArchetype';
      id: string;
      name: string;
    } | null;
    project: {
      __typename?: 'MarketplaceProject';
      id: string;
      name: string;
    } | null;
    offering: {
      __typename?: 'MarketplaceProjectOffering';
      id: string;
      vintage: number | null;
    } | null;
    documents: {
      __typename?: 'MarketplaceDocumentConnection';
      edges: Array<{
        __typename?: 'MarketplaceDocumentEdge';
        node: {
          __typename?: 'MarketplaceDocument';
          id: string;
          name: string;
          createdAt: Date;
          kind: GQMarketplaceDocumentKind;
          uploader: {
            __typename?: 'WatershedEmployee';
            id: string;
            name: string;
          } | null;
        } | null;
      } | null>;
    };
    allocations: {
      __typename?: 'MarketplaceAllocationInstructionConnection';
      edges: Array<{
        __typename?: 'MarketplaceAllocationInstructionEdge';
        node: {
          __typename?: 'MarketplaceAllocationInstruction';
          id: string;
          createdAt: Date;
          deletedAt: Date | null;
          amountKwh: number;
          isZeroedOut: boolean;
          interval: YMInterval;
          locationCity: string | null;
          locationGrid: string | null;
          locationState: string | null;
          locationCountry: string | null;
          buildingName: string | null;
          vendorEntity: string | null;
          vendorSubentity: string | null;
          product: string | null;
          categoryId: string | null;
          ghgCategoryId: string;
          footprintKind: string | null;
          subcategoryId: string | null;
          results: {
            __typename?: 'MarketplaceAllocationInstructionResultConnection';
            edges: Array<{
              __typename?: 'MarketplaceAllocationInstructionResultEdge';
              node: {
                __typename?: 'MarketplaceAllocationInstructionResult';
                id: string;
                isZeroedOut: boolean;
                amountAllocatedKwh: number;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
    eacMetadata: {
      __typename?: 'MarketplacePurchaseLineItemEacMetadata';
      id: string;
      updatedAt: Date;
      countryWithEmissions: string;
      countryWithEacs: string;
      numEacsRequested: number;
      mwh: number;
      marketTco2e: number;
    } | null;
  };
};

export type GQAllocationInstructionFieldsForMarketplacePurchaseLineItemFragment =
  {
    __typename?: 'MarketplaceAllocationInstruction';
    id: string;
    createdAt: Date;
    deletedAt: Date | null;
    amountKwh: number;
    isZeroedOut: boolean;
    interval: YMInterval;
    locationCity: string | null;
    locationGrid: string | null;
    locationState: string | null;
    locationCountry: string | null;
    buildingName: string | null;
    vendorEntity: string | null;
    vendorSubentity: string | null;
    product: string | null;
    categoryId: string | null;
    ghgCategoryId: string;
    footprintKind: string | null;
    subcategoryId: string | null;
    results: {
      __typename?: 'MarketplaceAllocationInstructionResultConnection';
      edges: Array<{
        __typename?: 'MarketplaceAllocationInstructionResultEdge';
        node: {
          __typename?: 'MarketplaceAllocationInstructionResult';
          id: string;
          isZeroedOut: boolean;
          amountAllocatedKwh: number;
        } | null;
      } | null>;
    } | null;
  };

export type GQMarketplaceProjectForSupplierPageFragment = {
  __typename?: 'MarketplaceProject';
  id: string;
  name: string;
  status: GQMarketplaceProjectStatus;
  archetype: {
    __typename?: 'MarketplaceProjectArchetype';
    id: string;
    name: string;
  };
};

export type GQMarketplaceSupplierFieldsFragment = {
  __typename?: 'MarketplaceSupplier';
  id: string;
  name: string;
  projects: {
    __typename?: 'MarketplaceProjectConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectEdge';
      node: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
        status: GQMarketplaceProjectStatus;
        archetype: {
          __typename?: 'MarketplaceProjectArchetype';
          id: string;
          name: string;
        };
      } | null;
    } | null>;
  };
  offerings: {
    __typename?: 'MarketplaceProjectOfferingConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectOfferingEdge';
      node: {
        __typename: 'MarketplaceProjectOffering';
        id: string;
        vintage: number | null;
        createdAt: Date;
        availability: number;
        priceUsdCents: number;
        priceWithFeeUsdCents: number;
        noteMd: string | null;
        supplier: {
          __typename?: 'MarketplaceSupplier';
          id: string;
          name: string;
        };
        project: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
          deletedAt: Date | null;
          status: GQMarketplaceProjectStatus;
          archetype: {
            __typename?: 'MarketplaceProjectArchetype';
            id: string;
            name: string;
            unit: GQMarketplaceProjectArchetypeUnit;
            mechanism: GQMarketplaceProjectArchetypeMechanism;
          };
        };
      } | null;
    } | null>;
  };
  pointsOfContact: Array<{
    __typename: 'MarketplacePointOfContact';
    id: string;
    name: string;
    email: string;
  }>;
  watershedEmployees: Array<{
    __typename: 'WatershedEmployee';
    id: string;
    name: string;
    email: string;
  }>;
};

export type GQMarketplaceSupplierQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMarketplaceSupplierQuery = {
  __typename?: 'Query';
  marketplaceSupplier: {
    __typename?: 'MarketplaceSupplier';
    id: string;
    name: string;
    projects: {
      __typename?: 'MarketplaceProjectConnection';
      edges: Array<{
        __typename?: 'MarketplaceProjectEdge';
        node: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
          status: GQMarketplaceProjectStatus;
          archetype: {
            __typename?: 'MarketplaceProjectArchetype';
            id: string;
            name: string;
          };
        } | null;
      } | null>;
    };
    offerings: {
      __typename?: 'MarketplaceProjectOfferingConnection';
      edges: Array<{
        __typename?: 'MarketplaceProjectOfferingEdge';
        node: {
          __typename: 'MarketplaceProjectOffering';
          id: string;
          vintage: number | null;
          createdAt: Date;
          availability: number;
          priceUsdCents: number;
          priceWithFeeUsdCents: number;
          noteMd: string | null;
          supplier: {
            __typename?: 'MarketplaceSupplier';
            id: string;
            name: string;
          };
          project: {
            __typename?: 'MarketplaceProject';
            id: string;
            name: string;
            deletedAt: Date | null;
            status: GQMarketplaceProjectStatus;
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
              unit: GQMarketplaceProjectArchetypeUnit;
              mechanism: GQMarketplaceProjectArchetypeMechanism;
            };
          };
        } | null;
      } | null>;
    };
    pointsOfContact: Array<{
      __typename: 'MarketplacePointOfContact';
      id: string;
      name: string;
      email: string;
    }>;
    watershedEmployees: Array<{
      __typename: 'WatershedEmployee';
      id: string;
      name: string;
      email: string;
    }>;
  };
};

export type GQMarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployeeMutationVariables =
  Exact<{
    input: GQRemoveMarketplaceSupplierWatershedEmployeeInput;
  }>;

export type GQMarketplaceSupplierPage_RemoveMarketplaceSupplierWatershedEmployeeMutation =
  {
    __typename?: 'Mutation';
    removeMarketplaceSupplierWatershedEmployee: {
      __typename?: 'RemoveMarketplaceSupplierWatershedEmployeePayload';
      marketplaceSupplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
        projects: {
          __typename?: 'MarketplaceProjectConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectEdge';
            node: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
              };
            } | null;
          } | null>;
        };
        offerings: {
          __typename?: 'MarketplaceProjectOfferingConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectOfferingEdge';
            node: {
              __typename: 'MarketplaceProjectOffering';
              id: string;
              vintage: number | null;
              createdAt: Date;
              availability: number;
              priceUsdCents: number;
              priceWithFeeUsdCents: number;
              noteMd: string | null;
              supplier: {
                __typename?: 'MarketplaceSupplier';
                id: string;
                name: string;
              };
              project: {
                __typename?: 'MarketplaceProject';
                id: string;
                name: string;
                deletedAt: Date | null;
                status: GQMarketplaceProjectStatus;
                archetype: {
                  __typename?: 'MarketplaceProjectArchetype';
                  id: string;
                  name: string;
                  unit: GQMarketplaceProjectArchetypeUnit;
                  mechanism: GQMarketplaceProjectArchetypeMechanism;
                };
              };
            } | null;
          } | null>;
        };
        pointsOfContact: Array<{
          __typename: 'MarketplacePointOfContact';
          id: string;
          name: string;
          email: string;
        }>;
        watershedEmployees: Array<{
          __typename: 'WatershedEmployee';
          id: string;
          name: string;
          email: string;
        }>;
      };
    } | null;
  };

export type GQMarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContactMutationVariables =
  Exact<{
    input: GQRemoveMarketplaceSupplierPointOfContactInput;
  }>;

export type GQMarketplaceSupplierPage_RemoveMarketplaceSupplierPointOfContactMutation =
  {
    __typename?: 'Mutation';
    removeMarketplaceSupplierPointOfContact: {
      __typename?: 'RemoveMarketplaceSupplierPointOfContactPayload';
      marketplaceSupplier: {
        __typename?: 'MarketplaceSupplier';
        id: string;
        name: string;
        projects: {
          __typename?: 'MarketplaceProjectConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectEdge';
            node: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              status: GQMarketplaceProjectStatus;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
              };
            } | null;
          } | null>;
        };
        offerings: {
          __typename?: 'MarketplaceProjectOfferingConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectOfferingEdge';
            node: {
              __typename: 'MarketplaceProjectOffering';
              id: string;
              vintage: number | null;
              createdAt: Date;
              availability: number;
              priceUsdCents: number;
              priceWithFeeUsdCents: number;
              noteMd: string | null;
              supplier: {
                __typename?: 'MarketplaceSupplier';
                id: string;
                name: string;
              };
              project: {
                __typename?: 'MarketplaceProject';
                id: string;
                name: string;
                deletedAt: Date | null;
                status: GQMarketplaceProjectStatus;
                archetype: {
                  __typename?: 'MarketplaceProjectArchetype';
                  id: string;
                  name: string;
                  unit: GQMarketplaceProjectArchetypeUnit;
                  mechanism: GQMarketplaceProjectArchetypeMechanism;
                };
              };
            } | null;
          } | null>;
        };
        pointsOfContact: Array<{
          __typename: 'MarketplacePointOfContact';
          id: string;
          name: string;
          email: string;
        }>;
        watershedEmployees: Array<{
          __typename: 'WatershedEmployee';
          id: string;
          name: string;
          email: string;
        }>;
      };
    } | null;
  };

export type GQMarketplaceSupplierListFieldsFragment = {
  __typename: 'MarketplaceSupplier';
  id: string;
  name: string;
  offerings: {
    __typename?: 'MarketplaceProjectOfferingConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectOfferingEdge';
      node: { __typename?: 'MarketplaceProjectOffering'; id: string } | null;
    } | null>;
  };
};

export type GQMarketplaceSuppliersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQMarketplaceSuppliersQuery = {
  __typename?: 'Query';
  marketplaceSuppliers: {
    __typename?: 'MarketplaceSupplierConnection';
    edges: Array<{
      __typename?: 'MarketplaceSupplierEdge';
      node: {
        __typename: 'MarketplaceSupplier';
        id: string;
        name: string;
        offerings: {
          __typename?: 'MarketplaceProjectOfferingConnection';
          edges: Array<{
            __typename?: 'MarketplaceProjectOfferingEdge';
            node: {
              __typename?: 'MarketplaceProjectOffering';
              id: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
};

export type GQGetObjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetObjectQuery = {
  __typename?: 'Query';
  object: {
    __typename?: 'GenericObjectPayload';
    id: string;
    model: string;
    data: any;
  };
};

export type GQGetDataRegistryBatchMigratorRunQueryVariables = Exact<{
  batchMigratorRunId: Scalars['ID']['input'];
}>;

export type GQGetDataRegistryBatchMigratorRunQuery = {
  __typename?: 'Query';
  dataRegistryBatchMigratorRun: {
    __typename?: 'DataRegistryBatchMigratorRun';
    id: string;
    createdAt: Date;
    description: string | null;
    status: string;
    workflowId: string | null;
    filters: any | null;
    error: string | null;
    successCount: number | null;
    failureCount: number | null;
    fromSchemaVersion: {
      __typename?: 'SchemaRegistrySchemaVersion';
      id: string;
      version: string;
      schemaJson: JSONSchema7;
      schema: { __typename?: 'SchemaRegistrySchema'; id: string; name: string };
    };
    toSchemaVersion: {
      __typename?: 'SchemaRegistrySchemaVersion';
      id: string;
      version: string;
      schemaJson: JSONSchema7;
      schema: { __typename?: 'SchemaRegistrySchema'; id: string; name: string };
    };
  } | null;
};

export type GQGetDataRegistryMigratorRunsQueryVariables = Exact<{
  batchMigratorRunId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<GQDataRegistryMigratorRunFilters>;
  searchText: InputMaybe<Scalars['String']['input']>;
}>;

export type GQGetDataRegistryMigratorRunsQuery = {
  __typename?: 'Query';
  dataRegistryMigratorRuns: {
    __typename?: 'DataRegistryMigratorRunConnection';
    edges: Array<{
      __typename?: 'DataRegistryMigratorRunEdge';
      node: {
        __typename?: 'DataRegistryMigratorRun';
        id: string;
        status: string;
        createdAt: Date;
        errorMessage: string | null;
        migrator: { __typename?: 'DataRegistryMigrator'; id: string };
        inputTable: {
          __typename?: 'DataRegistryTable';
          id: string;
          name: string;
          isDraft: boolean | null;
          createdAt: Date;
        };
        outputTable: {
          __typename?: 'DataRegistryTable';
          id: string;
          name: string;
          isDraft: boolean | null;
        } | null;
        organization: {
          __typename?: 'Organization';
          id: string;
          name: string;
        } | null;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
  } | null;
};

export type GQDataRegistryMigratorRunSchemaFragment = {
  __typename?: 'DataRegistryMigratorRun';
  id: string;
  status: string;
  createdAt: Date;
  errorMessage: string | null;
  migrator: { __typename?: 'DataRegistryMigrator'; id: string };
  inputTable: {
    __typename?: 'DataRegistryTable';
    id: string;
    name: string;
    isDraft: boolean | null;
    createdAt: Date;
  };
  outputTable: {
    __typename?: 'DataRegistryTable';
    id: string;
    name: string;
    isDraft: boolean | null;
  } | null;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
  } | null;
};

export type GQUpdateDataRegistryBatchMigratorRunMutationVariables = Exact<{
  input: GQUpdateDataRegistryBatchMigratorRunInput;
}>;

export type GQUpdateDataRegistryBatchMigratorRunMutation = {
  __typename?: 'Mutation';
  updateDataRegistryBatchMigratorRun: {
    __typename?: 'DataRegistryBatchMigratorRun';
    id: string;
    description: string | null;
  };
};

export type GQGetSchemaRegistrySchemaVersionQueryVariables = Exact<{
  schemaVersionId: Scalars['ID']['input'];
}>;

export type GQGetSchemaRegistrySchemaVersionQuery = {
  __typename?: 'Query';
  schemaRegistrySchemaVersion: {
    __typename?: 'SchemaRegistrySchemaVersion';
    id: string;
    version: string;
    schemaJson: JSONSchema7;
    createdAt: Date;
    schema: { __typename?: 'SchemaRegistrySchema'; id: string; name: string };
  } | null;
};

export type GQGetSchemaRegistrySchemaQueryVariables = Exact<{
  schemaId: Scalars['ID']['input'];
}>;

export type GQGetSchemaRegistrySchemaQuery = {
  __typename?: 'Query';
  schemaRegistrySchema: {
    __typename?: 'SchemaRegistrySchema';
    createdAt: Date;
    name: string;
    id: string;
  } | null;
};

export type GQMeasurementVerificationQuestionForAdminFragment = {
  __typename: 'MeasurementVerificationItemQuestion';
  id: string;
  section: GQMeasurementVerificationSection;
  questionKind: GQMeasurementVerificationQuestionKind;
  questionPrompt: string;
  answerText: string | null;
  status: GQMeasurementVerificationQuestionStatus;
  contentKind: GQMeasurementVerificationQuestionContentKind;
  contentMetadataJson: any;
  contentTitle: string | null;
  contentSubtitle: string | null;
  createdAt: Date;
  updatedAt: Date;
  rank: number | null;
  name: string;
  adminUrl: string;
  discussion: {
    __typename?: 'DataIssue';
    id: string;
    title: string;
    description: string | null;
    state: GQDataIssueState;
    createdAt: Date;
    isCustomerInitiated: boolean;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      completedAt: Date | null;
    };
    comments: {
      __typename?: 'CommentConnection';
      edges: Array<{
        __typename?: 'CommentEdge';
        node: {
          __typename?: 'Comment';
          id: string;
          message: string;
          isPublished: boolean;
          createdAt: Date;
          person:
            | {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                isWatershedEmployee: boolean;
                isWatershedContractor: boolean;
              }
            | {
                __typename?: 'WatershedEmployee';
                id: string;
                name: string;
                displayName: string;
                isWatershedEmployee: boolean;
                isWatershedContractor: boolean;
              }
            | null;
        } | null;
      } | null>;
    };
  } | null;
  publisher: {
    __typename?: 'WatershedEmployee';
    id: string;
    name: string;
  } | null;
  contentMetadata:
    | {
        __typename: 'MeasurementVerificationItemContentMetadataFileOverlaps';
        id: string;
        files: Array<{
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          uploader:
            | { __typename?: 'User'; id: string; name: string }
            | { __typename?: 'WatershedEmployee'; id: string; name: string }
            | null;
          userUploadTask: {
            __typename?: 'UserUploadTask';
            id: string;
            datasource: { __typename?: 'Datasource'; id: string; name: string };
          } | null;
        }>;
      }
    | {
        __typename: 'MeasurementVerificationItemContentMetadataQuantityTrend';
        id: string;
        aggregateField: string | null;
        filters: Array<{
          __typename?: 'DatasetSummaryFilter';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }> | null;
        anomaly: {
          __typename?: 'QuantityTrendAnomaly';
          highlightMonth: YearMonth;
        } | null;
        frozenDatasourceSummaries: Array<{
          __typename?: 'FrozenDatasourceSummary';
          isStale: boolean;
          signedUrl: string;
          datasource: { __typename?: 'Datasource'; id: string; name: string };
        }> | null;
      }
    | {
        __typename: 'MeasurementVerificationItemContentMetadataTopAccounts';
        id: string;
        frozenDatasourceSummaries: Array<{
          __typename?: 'FrozenDatasourceSummary';
          isStale: boolean;
          signedUrl: string;
          datasource: { __typename?: 'Datasource'; id: string; name: string };
        }> | null;
      };
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

export type GQMeasurementVerificationItemDetailForAdminFragment = {
  __typename?: 'MeasurementVerificationItem';
  id: string;
  name: string;
  description: string;
  datasetName: string;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    name: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
    } | null;
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      name: string;
      summaryWithMetadata: {
        __typename?: 'DatasourceSummaryWithMetadata';
        id: string;
        summaries: Array<{
          __typename?: 'DatasourceSummary';
          id: string;
          signedUrl: string;
        }>;
      };
    }>;
  } | null;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    coverageInterval: YMInterval;
  };
  assignee: { __typename?: 'User'; id: string; name: string } | null;
  questions: {
    __typename?: 'MeasurementVerificationItemQuestionConnection';
    edges: Array<{
      __typename?: 'MeasurementVerificationItemQuestionEdge';
      node: {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        section: GQMeasurementVerificationSection;
        questionKind: GQMeasurementVerificationQuestionKind;
        questionPrompt: string;
        answerText: string | null;
        status: GQMeasurementVerificationQuestionStatus;
        contentKind: GQMeasurementVerificationQuestionContentKind;
        contentMetadataJson: any;
        contentTitle: string | null;
        contentSubtitle: string | null;
        createdAt: Date;
        updatedAt: Date;
        rank: number | null;
        name: string;
        adminUrl: string;
        discussion: {
          __typename?: 'DataIssue';
          id: string;
          title: string;
          description: string | null;
          state: GQDataIssueState;
          createdAt: Date;
          isCustomerInitiated: boolean;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            completedAt: Date | null;
          };
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                message: string;
                isPublished: boolean;
                createdAt: Date;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        } | null;
        publisher: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        } | null;
        contentMetadata:
          | {
              __typename: 'MeasurementVerificationItemContentMetadataFileOverlaps';
              id: string;
              files: Array<{
                __typename?: 'FileMetadata';
                id: string;
                name: string;
                uploader:
                  | { __typename?: 'User'; id: string; name: string }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                    }
                  | null;
                userUploadTask: {
                  __typename?: 'UserUploadTask';
                  id: string;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  };
                } | null;
              }>;
            }
          | {
              __typename: 'MeasurementVerificationItemContentMetadataQuantityTrend';
              id: string;
              aggregateField: string | null;
              filters: Array<{
                __typename?: 'DatasetSummaryFilter';
                field: string;
                operator: GQFilterOperator;
                value: Array<string>;
              }> | null;
              anomaly: {
                __typename?: 'QuantityTrendAnomaly';
                highlightMonth: YearMonth;
              } | null;
              frozenDatasourceSummaries: Array<{
                __typename?: 'FrozenDatasourceSummary';
                isStale: boolean;
                signedUrl: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                };
              }> | null;
            }
          | {
              __typename: 'MeasurementVerificationItemContentMetadataTopAccounts';
              id: string;
              frozenDatasourceSummaries: Array<{
                __typename?: 'FrozenDatasourceSummary';
                isStale: boolean;
                signedUrl: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                };
              }> | null;
            };
        ancestorRelations: Array<
          | {
              __typename: 'ActivityDataTable';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'DataIssue';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Dataset';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Datasource';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FileMetadata';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FootprintQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementProject';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
        >;
      } | null;
    }>;
  };
};

export type GQGetMeasurementVerificationItemForAdminQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetMeasurementVerificationItemForAdminQuery = {
  __typename?: 'Query';
  measurementVerificationItem: {
    __typename?: 'MeasurementVerificationItem';
    id: string;
    name: string;
    description: string;
    datasetName: string;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
        summaryWithMetadata: {
          __typename?: 'DatasourceSummaryWithMetadata';
          id: string;
          summaries: Array<{
            __typename?: 'DatasourceSummary';
            id: string;
            signedUrl: string;
          }>;
        };
      }>;
    } | null;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      coverageInterval: YMInterval;
    };
    assignee: { __typename?: 'User'; id: string; name: string } | null;
    questions: {
      __typename?: 'MeasurementVerificationItemQuestionConnection';
      edges: Array<{
        __typename?: 'MeasurementVerificationItemQuestionEdge';
        node: {
          __typename: 'MeasurementVerificationItemQuestion';
          id: string;
          section: GQMeasurementVerificationSection;
          questionKind: GQMeasurementVerificationQuestionKind;
          questionPrompt: string;
          answerText: string | null;
          status: GQMeasurementVerificationQuestionStatus;
          contentKind: GQMeasurementVerificationQuestionContentKind;
          contentMetadataJson: any;
          contentTitle: string | null;
          contentSubtitle: string | null;
          createdAt: Date;
          updatedAt: Date;
          rank: number | null;
          name: string;
          adminUrl: string;
          discussion: {
            __typename?: 'DataIssue';
            id: string;
            title: string;
            description: string | null;
            state: GQDataIssueState;
            createdAt: Date;
            isCustomerInitiated: boolean;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              completedAt: Date | null;
            };
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  message: string;
                  isPublished: boolean;
                  createdAt: Date;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          } | null;
          publisher: {
            __typename?: 'WatershedEmployee';
            id: string;
            name: string;
          } | null;
          contentMetadata:
            | {
                __typename: 'MeasurementVerificationItemContentMetadataFileOverlaps';
                id: string;
                files: Array<{
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                  uploader:
                    | { __typename?: 'User'; id: string; name: string }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        name: string;
                      }
                    | null;
                  userUploadTask: {
                    __typename?: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    };
                  } | null;
                }>;
              }
            | {
                __typename: 'MeasurementVerificationItemContentMetadataQuantityTrend';
                id: string;
                aggregateField: string | null;
                filters: Array<{
                  __typename?: 'DatasetSummaryFilter';
                  field: string;
                  operator: GQFilterOperator;
                  value: Array<string>;
                }> | null;
                anomaly: {
                  __typename?: 'QuantityTrendAnomaly';
                  highlightMonth: YearMonth;
                } | null;
                frozenDatasourceSummaries: Array<{
                  __typename?: 'FrozenDatasourceSummary';
                  isStale: boolean;
                  signedUrl: string;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  };
                }> | null;
              }
            | {
                __typename: 'MeasurementVerificationItemContentMetadataTopAccounts';
                id: string;
                frozenDatasourceSummaries: Array<{
                  __typename?: 'FrozenDatasourceSummary';
                  isStale: boolean;
                  signedUrl: string;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  };
                }> | null;
              };
          ancestorRelations: Array<
            | {
                __typename: 'ActivityDataTable';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'DataIssue';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'Dataset';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'Datasource';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'FileMetadata';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'FootprintQuestion';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'MeasurementProject';
                id: string;
                name: string;
                adminUrl: string;
              }
            | {
                __typename: 'MeasurementVerificationItemQuestion';
                id: string;
                name: string;
                adminUrl: string;
              }
          >;
        } | null;
      }>;
    };
  };
};

export type GQUpdateMeasurementVerificationItemQuestionForAdminMutationVariables =
  Exact<{
    input: GQUpdateMeasurementVerificationItemQuestionInput;
  }>;

export type GQUpdateMeasurementVerificationItemQuestionForAdminMutation = {
  __typename?: 'Mutation';
  updateMeasurementVerificationItemQuestion: {
    __typename?: 'UpdateMeasurementVerificationItemQuestionPayload';
    measurementVerificationItemQuestion: {
      __typename: 'MeasurementVerificationItemQuestion';
      id: string;
      section: GQMeasurementVerificationSection;
      questionKind: GQMeasurementVerificationQuestionKind;
      questionPrompt: string;
      answerText: string | null;
      status: GQMeasurementVerificationQuestionStatus;
      contentKind: GQMeasurementVerificationQuestionContentKind;
      contentMetadataJson: any;
      contentTitle: string | null;
      contentSubtitle: string | null;
      createdAt: Date;
      updatedAt: Date;
      rank: number | null;
      name: string;
      adminUrl: string;
      discussion: {
        __typename?: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        createdAt: Date;
        isCustomerInitiated: boolean;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          completedAt: Date | null;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              isPublished: boolean;
              createdAt: Date;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
      publisher: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
      contentMetadata:
        | {
            __typename: 'MeasurementVerificationItemContentMetadataFileOverlaps';
            id: string;
            files: Array<{
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              uploader:
                | { __typename?: 'User'; id: string; name: string }
                | { __typename?: 'WatershedEmployee'; id: string; name: string }
                | null;
              userUploadTask: {
                __typename?: 'UserUploadTask';
                id: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                };
              } | null;
            }>;
          }
        | {
            __typename: 'MeasurementVerificationItemContentMetadataQuantityTrend';
            id: string;
            aggregateField: string | null;
            filters: Array<{
              __typename?: 'DatasetSummaryFilter';
              field: string;
              operator: GQFilterOperator;
              value: Array<string>;
            }> | null;
            anomaly: {
              __typename?: 'QuantityTrendAnomaly';
              highlightMonth: YearMonth;
            } | null;
            frozenDatasourceSummaries: Array<{
              __typename?: 'FrozenDatasourceSummary';
              isStale: boolean;
              signedUrl: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
              };
            }> | null;
          }
        | {
            __typename: 'MeasurementVerificationItemContentMetadataTopAccounts';
            id: string;
            frozenDatasourceSummaries: Array<{
              __typename?: 'FrozenDatasourceSummary';
              isStale: boolean;
              signedUrl: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
              };
            }> | null;
          };
      ancestorRelations: Array<
        | {
            __typename: 'ActivityDataTable';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'DataIssue';
            id: string;
            name: string;
            adminUrl: string;
          }
        | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
        | {
            __typename: 'Datasource';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FileMetadata';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FootprintQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementProject';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
      >;
    };
  };
};

export type GQCreateMeasurementVerificationItemQuestionForAdminMutationVariables =
  Exact<{
    input: GQCreateMeasurementVerificationItemQuestionInput;
  }>;

export type GQCreateMeasurementVerificationItemQuestionForAdminMutation = {
  __typename?: 'Mutation';
  createMeasurementVerificationItemQuestion: {
    __typename?: 'CreateMeasurementVerificationItemQuestionPayload';
    measurementVerificationItemQuestion: {
      __typename: 'MeasurementVerificationItemQuestion';
      id: string;
      section: GQMeasurementVerificationSection;
      questionKind: GQMeasurementVerificationQuestionKind;
      questionPrompt: string;
      answerText: string | null;
      status: GQMeasurementVerificationQuestionStatus;
      contentKind: GQMeasurementVerificationQuestionContentKind;
      contentMetadataJson: any;
      contentTitle: string | null;
      contentSubtitle: string | null;
      createdAt: Date;
      updatedAt: Date;
      rank: number | null;
      name: string;
      adminUrl: string;
      discussion: {
        __typename?: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        createdAt: Date;
        isCustomerInitiated: boolean;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          completedAt: Date | null;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              isPublished: boolean;
              createdAt: Date;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
      publisher: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
      contentMetadata:
        | {
            __typename: 'MeasurementVerificationItemContentMetadataFileOverlaps';
            id: string;
            files: Array<{
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              uploader:
                | { __typename?: 'User'; id: string; name: string }
                | { __typename?: 'WatershedEmployee'; id: string; name: string }
                | null;
              userUploadTask: {
                __typename?: 'UserUploadTask';
                id: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                };
              } | null;
            }>;
          }
        | {
            __typename: 'MeasurementVerificationItemContentMetadataQuantityTrend';
            id: string;
            aggregateField: string | null;
            filters: Array<{
              __typename?: 'DatasetSummaryFilter';
              field: string;
              operator: GQFilterOperator;
              value: Array<string>;
            }> | null;
            anomaly: {
              __typename?: 'QuantityTrendAnomaly';
              highlightMonth: YearMonth;
            } | null;
            frozenDatasourceSummaries: Array<{
              __typename?: 'FrozenDatasourceSummary';
              isStale: boolean;
              signedUrl: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
              };
            }> | null;
          }
        | {
            __typename: 'MeasurementVerificationItemContentMetadataTopAccounts';
            id: string;
            frozenDatasourceSummaries: Array<{
              __typename?: 'FrozenDatasourceSummary';
              isStale: boolean;
              signedUrl: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
              };
            }> | null;
          };
      ancestorRelations: Array<
        | {
            __typename: 'ActivityDataTable';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'DataIssue';
            id: string;
            name: string;
            adminUrl: string;
          }
        | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
        | {
            __typename: 'Datasource';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FileMetadata';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FootprintQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementProject';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
      >;
    };
  };
};

export type GQDeleteMeasurementVerificationItemQuestionForAdminMutationVariables =
  Exact<{
    input: GQDeleteMeasurementVerificationItemQuestionInput;
  }>;

export type GQDeleteMeasurementVerificationItemQuestionForAdminMutation = {
  __typename?: 'Mutation';
  deleteMeasurementVerificationItemQuestion: {
    __typename?: 'DeleteMeasurementVerificationItemQuestionPayload';
    measurementVerificationItem: {
      __typename?: 'MeasurementVerificationItem';
      id: string;
      name: string;
      description: string;
      datasetName: string;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          summaryWithMetadata: {
            __typename?: 'DatasourceSummaryWithMetadata';
            id: string;
            summaries: Array<{
              __typename?: 'DatasourceSummary';
              id: string;
              signedUrl: string;
            }>;
          };
        }>;
      } | null;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
        coverageInterval: YMInterval;
      };
      assignee: { __typename?: 'User'; id: string; name: string } | null;
      questions: {
        __typename?: 'MeasurementVerificationItemQuestionConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemQuestionEdge';
          node: {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            section: GQMeasurementVerificationSection;
            questionKind: GQMeasurementVerificationQuestionKind;
            questionPrompt: string;
            answerText: string | null;
            status: GQMeasurementVerificationQuestionStatus;
            contentKind: GQMeasurementVerificationQuestionContentKind;
            contentMetadataJson: any;
            contentTitle: string | null;
            contentSubtitle: string | null;
            createdAt: Date;
            updatedAt: Date;
            rank: number | null;
            name: string;
            adminUrl: string;
            discussion: {
              __typename?: 'DataIssue';
              id: string;
              title: string;
              description: string | null;
              state: GQDataIssueState;
              createdAt: Date;
              isCustomerInitiated: boolean;
              measurementProject: {
                __typename?: 'MeasurementProject';
                id: string;
                completedAt: Date | null;
              };
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    message: string;
                    isPublished: boolean;
                    createdAt: Date;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          name: string;
                          displayName: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            } | null;
            publisher: {
              __typename?: 'WatershedEmployee';
              id: string;
              name: string;
            } | null;
            contentMetadata:
              | {
                  __typename: 'MeasurementVerificationItemContentMetadataFileOverlaps';
                  id: string;
                  files: Array<{
                    __typename?: 'FileMetadata';
                    id: string;
                    name: string;
                    uploader:
                      | { __typename?: 'User'; id: string; name: string }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          name: string;
                        }
                      | null;
                    userUploadTask: {
                      __typename?: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      };
                    } | null;
                  }>;
                }
              | {
                  __typename: 'MeasurementVerificationItemContentMetadataQuantityTrend';
                  id: string;
                  aggregateField: string | null;
                  filters: Array<{
                    __typename?: 'DatasetSummaryFilter';
                    field: string;
                    operator: GQFilterOperator;
                    value: Array<string>;
                  }> | null;
                  anomaly: {
                    __typename?: 'QuantityTrendAnomaly';
                    highlightMonth: YearMonth;
                  } | null;
                  frozenDatasourceSummaries: Array<{
                    __typename?: 'FrozenDatasourceSummary';
                    isStale: boolean;
                    signedUrl: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    };
                  }> | null;
                }
              | {
                  __typename: 'MeasurementVerificationItemContentMetadataTopAccounts';
                  id: string;
                  frozenDatasourceSummaries: Array<{
                    __typename?: 'FrozenDatasourceSummary';
                    isStale: boolean;
                    signedUrl: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    };
                  }> | null;
                };
            ancestorRelations: Array<
              | {
                  __typename: 'ActivityDataTable';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'DataIssue';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'Dataset';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'Datasource';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'FileMetadata';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'FootprintQuestion';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'MeasurementProject';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
              | {
                  __typename: 'MeasurementVerificationItemQuestion';
                  id: string;
                  name: string;
                  adminUrl: string;
                }
            >;
          } | null;
        }>;
      };
    };
  };
};

export type GQUpdateMeasurementVerificationItemQuestionOrderForAdminMutationVariables =
  Exact<{
    input: GQOrderMeasurementVerificationItemQuestionsInput;
  }>;

export type GQUpdateMeasurementVerificationItemQuestionOrderForAdminMutation = {
  __typename?: 'Mutation';
  updateMeasurementVerificationItemQuestionOrder: {
    __typename?: 'OrderMeasurementVerificationItemQuestionsPayload';
    updatedQuestions: Array<{
      __typename: 'MeasurementVerificationItemQuestion';
      id: string;
      section: GQMeasurementVerificationSection;
      questionKind: GQMeasurementVerificationQuestionKind;
      questionPrompt: string;
      answerText: string | null;
      status: GQMeasurementVerificationQuestionStatus;
      contentKind: GQMeasurementVerificationQuestionContentKind;
      contentMetadataJson: any;
      contentTitle: string | null;
      contentSubtitle: string | null;
      createdAt: Date;
      updatedAt: Date;
      rank: number | null;
      name: string;
      adminUrl: string;
      discussion: {
        __typename?: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        createdAt: Date;
        isCustomerInitiated: boolean;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          completedAt: Date | null;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              isPublished: boolean;
              createdAt: Date;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
      publisher: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
      contentMetadata:
        | {
            __typename: 'MeasurementVerificationItemContentMetadataFileOverlaps';
            id: string;
            files: Array<{
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              uploader:
                | { __typename?: 'User'; id: string; name: string }
                | { __typename?: 'WatershedEmployee'; id: string; name: string }
                | null;
              userUploadTask: {
                __typename?: 'UserUploadTask';
                id: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                };
              } | null;
            }>;
          }
        | {
            __typename: 'MeasurementVerificationItemContentMetadataQuantityTrend';
            id: string;
            aggregateField: string | null;
            filters: Array<{
              __typename?: 'DatasetSummaryFilter';
              field: string;
              operator: GQFilterOperator;
              value: Array<string>;
            }> | null;
            anomaly: {
              __typename?: 'QuantityTrendAnomaly';
              highlightMonth: YearMonth;
            } | null;
            frozenDatasourceSummaries: Array<{
              __typename?: 'FrozenDatasourceSummary';
              isStale: boolean;
              signedUrl: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
              };
            }> | null;
          }
        | {
            __typename: 'MeasurementVerificationItemContentMetadataTopAccounts';
            id: string;
            frozenDatasourceSummaries: Array<{
              __typename?: 'FrozenDatasourceSummary';
              isStale: boolean;
              signedUrl: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
              };
            }> | null;
          };
      ancestorRelations: Array<
        | {
            __typename: 'ActivityDataTable';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'DataIssue';
            id: string;
            name: string;
            adminUrl: string;
          }
        | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
        | {
            __typename: 'Datasource';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FileMetadata';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FootprintQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementProject';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
      >;
    }> | null;
  };
};

export type GQOrgFilesForMeasurementVerificationAdminQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  fileIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQOrgFilesForMeasurementVerificationAdminQuery = {
  __typename?: 'Query';
  files: {
    __typename?: 'FileMetadataConnection';
    edges: Array<{
      __typename?: 'FileMetadataEdge';
      node: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        uploader:
          | { __typename?: 'User'; id: string; name: string }
          | { __typename?: 'WatershedEmployee'; id: string; name: string }
          | null;
        userUploadTask: {
          __typename?: 'UserUploadTask';
          id: string;
          datasource: { __typename?: 'Datasource'; id: string; name: string };
        } | null;
      } | null;
    } | null>;
  };
};

export type GQDatasetForDataReviewPageFragment = {
  __typename?: 'Dataset';
  id: string;
  name: string;
  datasources: Array<{ __typename?: 'Datasource'; id: string; name: string }>;
  canonicalDataset: {
    __typename?: 'CanonicalDataset';
    id: string;
    kind: GQCanonicalDatasetKind;
  } | null;
};

export type GQOrganizationDataReviewPageQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrganizationDataReviewPageQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
      }>;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    }>;
    measurementProjects: Array<{
      __typename: 'MeasurementProject';
      id: string;
      name: string;
      coverageInterval: YMInterval;
      active: boolean;
      completedAt: Date | null;
      adminUrl: string;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            name: string;
            description: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
            } | null;
            assignee: { __typename?: 'User'; id: string; name: string } | null;
            questions: {
              __typename?: 'MeasurementVerificationItemQuestionConnection';
              edges: Array<{
                __typename?: 'MeasurementVerificationItemQuestionEdge';
                node: {
                  __typename?: 'MeasurementVerificationItemQuestion';
                  id: string;
                  status: GQMeasurementVerificationQuestionStatus;
                } | null;
              }>;
            };
          } | null;
        }>;
      };
      ancestorRelations: Array<
        | {
            __typename: 'ActivityDataTable';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'DataIssue';
            id: string;
            name: string;
            adminUrl: string;
          }
        | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
        | {
            __typename: 'Datasource';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FileMetadata';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FootprintQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementProject';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
      >;
    }>;
  };
};

export type GQMeasurementProjectForDataReviewPageFragment = {
  __typename: 'MeasurementProject';
  id: string;
  name: string;
  coverageInterval: YMInterval;
  adminUrl: string;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

export type GQMeasurementVerificationItemForAdminFragment = {
  __typename?: 'MeasurementVerificationItem';
  id: string;
  name: string;
  description: string;
  dataset: { __typename?: 'Dataset'; id: string; name: string } | null;
  assignee: { __typename?: 'User'; id: string; name: string } | null;
  questions: {
    __typename?: 'MeasurementVerificationItemQuestionConnection';
    edges: Array<{
      __typename?: 'MeasurementVerificationItemQuestionEdge';
      node: {
        __typename?: 'MeasurementVerificationItemQuestion';
        id: string;
        status: GQMeasurementVerificationQuestionStatus;
      } | null;
    }>;
  };
};

export type GQMeasurementProjectFieldsForMeasurementVerificationCardFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  active: boolean;
  completedAt: Date | null;
  measurementVerificationItems: {
    __typename?: 'MeasurementVerificationItemConnection';
    edges: Array<{
      __typename?: 'MeasurementVerificationItemEdge';
      node: {
        __typename?: 'MeasurementVerificationItem';
        id: string;
        name: string;
        description: string;
        dataset: { __typename?: 'Dataset'; id: string; name: string } | null;
        assignee: { __typename?: 'User'; id: string; name: string } | null;
        questions: {
          __typename?: 'MeasurementVerificationItemQuestionConnection';
          edges: Array<{
            __typename?: 'MeasurementVerificationItemQuestionEdge';
            node: {
              __typename?: 'MeasurementVerificationItemQuestion';
              id: string;
              status: GQMeasurementVerificationQuestionStatus;
            } | null;
          }>;
        };
      } | null;
    }>;
  };
};

export type GQDatasourceForDatasetPageFragment = {
  __typename?: 'Datasource';
  id: string;
  name: string;
  importInstructionsMd: string | null;
  refreshCadence: GQDatasetCadence;
  isUserConfirmed: boolean | null;
  customUsedFor: string | null;
  customPotentialOverlaps: string | null;
  notesFromWatershedMd: string | null;
  customerTargetSchemaIds: Array<string> | null;
  dataset: { __typename?: 'Dataset'; id: string };
  canonicalDatasource: {
    __typename?: 'CanonicalDatasource';
    id: string;
    name: string;
  } | null;
  userUploadTasks: {
    __typename?: 'UserUploadTaskConnection';
    edges: Array<{
      __typename?: 'UserUploadTaskEdge';
      node: {
        __typename?: 'UserUploadTask';
        id: string;
        state: GQUserUploadTaskState;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          name: string;
          coverageInterval: YMInterval;
        };
        userUploads: {
          __typename?: 'UserUploadConnection';
          edges: Array<{
            __typename?: 'UserUploadEdge';
            node: {
              __typename?: 'UserUpload';
              id: string;
              name: string;
              deletedAt: Date | null;
              allAttempts: Array<{
                __typename?: 'UserUploadAttempt';
                id: string;
                createdAt: Date;
                size: number;
                gcloudStorageUrl: string;
                deletedAt: Date | null;
                uploader: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                } | null;
                userUploadedTables: Array<{
                  __typename?: 'UserUploadedTable';
                  id: string;
                  sqlTableName: string | null;
                  shouldIgnore: boolean;
                }>;
              }>;
              latestAttempt: {
                __typename?: 'UserUploadAttempt';
                id: string;
                name: string;
                gcloudStorageUrl: string;
                userUploadedTables: Array<{
                  __typename?: 'UserUploadedTable';
                  id: string;
                  shouldIgnore: boolean;
                }>;
              };
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  };
  instructions: {
    __typename?: 'InstructionsBundle';
    instructionsDataFormattingMd: string | null;
    instructionsIntroMd: string | null;
    sdiInstructionsDataFormattingMd: string | null;
    sdiInstructionsIntroMd: string | null;
    ghgScopes: Array<GQGhgScope> | null;
    usedFor: string | null;
    potentialOverlaps: string | null;
    instructionsSteps: Array<{
      __typename?: 'InstructionsStep';
      descriptionMd: string;
      fileMetadataId: string | null;
      remotePath: string | null;
      publicUrl: string | null;
    }> | null;
    sdiInstructionsSteps: Array<{
      __typename?: 'SdiInstructionsStep';
      title: string;
      descriptionMd: string;
    }> | null;
    sdiInstructionsFaq: Array<{
      __typename?: 'SdiInstructionsFaqType';
      question: string;
      answerMd: string;
    }> | null;
  } | null;
};

export type GQEditDatasetPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQEditDatasetPageQuery = {
  __typename?: 'Query';
  dataset: {
    __typename: 'Dataset';
    id: string;
    name: string;
    explainer: string | null;
    customUsedFor: string | null;
    customPotentialOverlaps: string | null;
    adminUrl: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      name: string;
    } | null;
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      name: string;
      importInstructionsMd: string | null;
      refreshCadence: GQDatasetCadence;
      isUserConfirmed: boolean | null;
      customUsedFor: string | null;
      customPotentialOverlaps: string | null;
      notesFromWatershedMd: string | null;
      customerTargetSchemaIds: Array<string> | null;
      dataset: { __typename?: 'Dataset'; id: string };
      canonicalDatasource: {
        __typename?: 'CanonicalDatasource';
        id: string;
        name: string;
      } | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
              coverageInterval: YMInterval;
            };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: {
                  __typename?: 'UserUpload';
                  id: string;
                  name: string;
                  deletedAt: Date | null;
                  allAttempts: Array<{
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    createdAt: Date;
                    size: number;
                    gcloudStorageUrl: string;
                    deletedAt: Date | null;
                    uploader: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                    } | null;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                      sqlTableName: string | null;
                      shouldIgnore: boolean;
                    }>;
                  }>;
                  latestAttempt: {
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    name: string;
                    gcloudStorageUrl: string;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                      shouldIgnore: boolean;
                    }>;
                  };
                } | null;
              } | null>;
            } | null;
          } | null;
        } | null>;
      };
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsDataFormattingMd: string | null;
        instructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        usedFor: string | null;
        potentialOverlaps: string | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          fileMetadataId: string | null;
          remotePath: string | null;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
    }>;
    instructions: {
      __typename?: 'InstructionsBundle';
      instructionsDataFormattingMd: string | null;
      instructionsIntroMd: string | null;
      sdiInstructionsDataFormattingMd: string | null;
      sdiInstructionsIntroMd: string | null;
      ghgScopes: Array<GQGhgScope> | null;
      usedFor: string | null;
      potentialOverlaps: string | null;
      instructionsSteps: Array<{
        __typename?: 'InstructionsStep';
        descriptionMd: string;
        fileMetadataId: string | null;
        remotePath: string | null;
        publicUrl: string | null;
      }> | null;
      sdiInstructionsSteps: Array<{
        __typename?: 'SdiInstructionsStep';
        title: string;
        descriptionMd: string;
      }> | null;
      sdiInstructionsFaq: Array<{
        __typename?: 'SdiInstructionsFaqType';
        question: string;
        answerMd: string;
      }> | null;
    } | null;
    customExampleData: {
      __typename?: 'TabularData';
      cells: Array<Array<any>>;
    } | null;
    ancestorRelations: Array<
      | {
          __typename: 'ActivityDataTable';
          id: string;
          name: string;
          adminUrl: string;
        }
      | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
      | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
      | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
      | {
          __typename: 'FileMetadata';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'FinancialsReviewItem';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'FootprintQuestion';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'MeasurementProject';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'MeasurementVerificationItemQuestion';
          id: string;
          name: string;
          adminUrl: string;
        }
    >;
  } | null;
};

export type GQDeleteDatasourceAdminMutationVariables = Exact<{
  input: GQDeleteDatasourceInput;
}>;

export type GQDeleteDatasourceAdminMutation = {
  __typename?: 'Mutation';
  deleteDatasourceAdmin: {
    __typename?: 'DeleteDatasourceAdminPayload';
    id: string;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      datasources: Array<{ __typename?: 'Datasource'; id: string }>;
    };
  } | null;
};

export type GQOrganizationDatasetsPageDatasetFragment = {
  __typename?: 'Dataset';
  id: string;
  name: string;
  explainer: string | null;
  customUsedFor: string | null;
  customPotentialOverlaps: string | null;
  canonicalDataset: {
    __typename?: 'CanonicalDataset';
    id: string;
    name: string;
    kind: GQCanonicalDatasetKind;
  } | null;
  datasources: Array<{
    __typename?: 'Datasource';
    id: string;
    name: string;
    customUsedFor: string | null;
    customPotentialOverlaps: string | null;
    customerTargetSchemaIds: Array<string> | null;
  }>;
};

export type GQOrganizationDatasetsPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQOrganizationDatasetsPageQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    measurementProjects: Array<{
      __typename: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      status: GQMeasurementProjectStatus;
      completedAt: Date | null;
      coverageStartDate: Date;
      coverageEndDate: Date;
      coverageInterval: YMInterval;
      deadline: Date | null;
      supportedValueMappingRules: Array<string>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: { __typename?: 'Dataset'; id: string; name: string } | null;
        assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
            assignee: { __typename?: 'User'; id: string } | null;
          } | null;
        }>;
      };
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            datasource: { __typename?: 'Datasource'; id: string; name: string };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: { __typename?: 'UserUpload'; id: string } | null;
              } | null>;
            } | null;
            assignee: { __typename?: 'User'; id: string; name: string } | null;
          } | null;
        } | null>;
      };
      measurementProjectDatasets: Array<{
        __typename?: 'MeasurementProjectDataset';
        id: string;
        isCompleted: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          explainer: string | null;
          customUsedFor: string | null;
          customPotentialOverlaps: string | null;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            name: string;
            kind: GQCanonicalDatasetKind;
          } | null;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            customUsedFor: string | null;
            customPotentialOverlaps: string | null;
            customerTargetSchemaIds: Array<string> | null;
          }>;
        };
      }>;
    }>;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      explainer: string | null;
      customUsedFor: string | null;
      customPotentialOverlaps: string | null;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
        kind: GQCanonicalDatasetKind;
      } | null;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
        customUsedFor: string | null;
        customPotentialOverlaps: string | null;
        customerTargetSchemaIds: Array<string> | null;
      }>;
    }>;
  };
};

export type GQUserUploadTaskFieldsForOrgPageFragment = {
  __typename: 'UserUploadTask';
  id: string;
  state: GQUserUploadTaskState;
  datasource: { __typename?: 'Datasource'; id: string; name: string };
  userUploads: {
    __typename?: 'UserUploadConnection';
    edges: Array<{
      __typename?: 'UserUploadEdge';
      node: { __typename?: 'UserUpload'; id: string } | null;
    } | null>;
  } | null;
  assignee: { __typename?: 'User'; id: string; name: string } | null;
};

export type GQMeasurementProjectDatasetFieldsForOrgPageFragment = {
  __typename?: 'MeasurementProjectDataset';
  id: string;
  isCompleted: boolean;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    name: string;
    explainer: string | null;
    customUsedFor: string | null;
    customPotentialOverlaps: string | null;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      name: string;
      kind: GQCanonicalDatasetKind;
    } | null;
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      name: string;
      customUsedFor: string | null;
      customPotentialOverlaps: string | null;
      customerTargetSchemaIds: Array<string> | null;
    }>;
  };
};

export type GQMeasurementVerificationItemForOrgPageFragment = {
  __typename?: 'MeasurementVerificationItem';
  id: string;
  status: GQMeasurementVerificationItemStatus;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
    } | null;
  } | null;
  assignee: { __typename?: 'User'; id: string } | null;
};

type GQMeasurementTaskForOrgPage_MeasurementParentTask_Fragment = {
  __typename?: 'MeasurementParentTask';
  id: string;
  title: string;
  link: string | null;
  isAssignedToWatershed: boolean;
  statusSimplified: GQTaskWatershedProcessStateSimplified;
  assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
  object:
    | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
    | {
        __typename: 'DataIssue';
        id: string;
        linkedObject:
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              item: {
                __typename: 'MeasurementVerificationItem';
                id: string;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                } | null;
              };
            }
          | {
              __typename: 'UserUploadTask';
              id: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              };
            }
          | { __typename: 'ValueMappingTask' }
          | null;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      }
    | { __typename: 'FacilitiesSetup'; id: string }
    | {
        __typename: 'FinancialsReviewGroup';
        id: string;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          };
        }>;
      }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        group: {
          __typename: 'FinancialsReviewGroup';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            };
          }>;
        };
      }
    | { __typename: 'FootprintQuestion'; id: string }
    | {
        __typename: 'MeasurementDataMappingTask';
        id: string;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        };
      }
    | { __typename: 'MeasurementProject'; id: string }
    | {
        __typename: 'MeasurementProjectDataset';
        modelId: string | null;
        isCompleted: boolean;
        id: string;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        };
      }
    | {
        __typename: 'MeasurementVerificationItem';
        id: string;
        mviStatus: GQMeasurementVerificationItemStatus;
        maybeDataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        } | null;
      }
    | {
        __typename: 'UserUploadTask';
        state: GQUserUploadTaskState;
        id: string;
        approvers: Array<{
          __typename?: 'UserWithApprovalStatus';
          approvalStatus: GQApprovalStatus | null;
          user: {
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          };
        }>;
        datasource: {
          __typename?: 'Datasource';
          hasIntegration: boolean;
          id: string;
          name: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          };
        };
      }
    | { __typename: 'ValueMappingTask'; id: string }
    | { __typename: 'VendorMatchingTask'; id: string };
};

type GQMeasurementTaskForOrgPage_MeasurementTaskFlat_Fragment = {
  __typename?: 'MeasurementTaskFlat';
  id: string;
  title: string;
  link: string | null;
  isAssignedToWatershed: boolean;
  statusSimplified: GQTaskWatershedProcessStateSimplified;
  assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
  object:
    | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
    | {
        __typename: 'DataIssue';
        id: string;
        linkedObject:
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              item: {
                __typename: 'MeasurementVerificationItem';
                id: string;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                } | null;
              };
            }
          | {
              __typename: 'UserUploadTask';
              id: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              };
            }
          | { __typename: 'ValueMappingTask' }
          | null;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      }
    | { __typename: 'FacilitiesSetup'; id: string }
    | {
        __typename: 'FinancialsReviewGroup';
        id: string;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          };
        }>;
      }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        group: {
          __typename: 'FinancialsReviewGroup';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            };
          }>;
        };
      }
    | { __typename: 'FootprintQuestion'; id: string }
    | {
        __typename: 'MeasurementDataMappingTask';
        id: string;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        };
      }
    | { __typename: 'MeasurementProject'; id: string }
    | {
        __typename: 'MeasurementProjectDataset';
        modelId: string | null;
        isCompleted: boolean;
        id: string;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        };
      }
    | {
        __typename: 'MeasurementVerificationItem';
        id: string;
        mviStatus: GQMeasurementVerificationItemStatus;
        maybeDataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        } | null;
      }
    | {
        __typename: 'UserUploadTask';
        state: GQUserUploadTaskState;
        id: string;
        approvers: Array<{
          __typename?: 'UserWithApprovalStatus';
          approvalStatus: GQApprovalStatus | null;
          user: {
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          };
        }>;
        datasource: {
          __typename?: 'Datasource';
          hasIntegration: boolean;
          id: string;
          name: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          };
        };
      }
    | { __typename: 'ValueMappingTask'; id: string }
    | { __typename: 'VendorMatchingTask'; id: string };
};

export type GQMeasurementTaskForOrgPageFragment =
  | GQMeasurementTaskForOrgPage_MeasurementParentTask_Fragment
  | GQMeasurementTaskForOrgPage_MeasurementTaskFlat_Fragment;

export type GQMeasurementParentTaskForOrgPageFragment = {
  __typename?: 'MeasurementParentTask';
  taskGroup: string;
  id: string;
  title: string;
  link: string | null;
  isAssignedToWatershed: boolean;
  statusSimplified: GQTaskWatershedProcessStateSimplified;
  subTasks: Array<
    | {
        __typename?: 'MeasurementParentTask';
        id: string;
        title: string;
        link: string | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }
    | {
        __typename?: 'MeasurementTaskFlat';
        id: string;
        title: string;
        link: string | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }
  >;
  dataset: { __typename?: 'Dataset'; id: string; name: string } | null;
  assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
  object:
    | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
    | {
        __typename: 'DataIssue';
        id: string;
        linkedObject:
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              item: {
                __typename: 'MeasurementVerificationItem';
                id: string;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                } | null;
              };
            }
          | {
              __typename: 'UserUploadTask';
              id: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              };
            }
          | { __typename: 'ValueMappingTask' }
          | null;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      }
    | { __typename: 'FacilitiesSetup'; id: string }
    | {
        __typename: 'FinancialsReviewGroup';
        id: string;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          };
        }>;
      }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        group: {
          __typename: 'FinancialsReviewGroup';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            };
          }>;
        };
      }
    | { __typename: 'FootprintQuestion'; id: string }
    | {
        __typename: 'MeasurementDataMappingTask';
        id: string;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        };
      }
    | { __typename: 'MeasurementProject'; id: string }
    | {
        __typename: 'MeasurementProjectDataset';
        modelId: string | null;
        isCompleted: boolean;
        id: string;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        };
      }
    | {
        __typename: 'MeasurementVerificationItem';
        id: string;
        mviStatus: GQMeasurementVerificationItemStatus;
        maybeDataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        } | null;
      }
    | {
        __typename: 'UserUploadTask';
        state: GQUserUploadTaskState;
        id: string;
        approvers: Array<{
          __typename?: 'UserWithApprovalStatus';
          approvalStatus: GQApprovalStatus | null;
          user: {
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          };
        }>;
        datasource: {
          __typename?: 'Datasource';
          hasIntegration: boolean;
          id: string;
          name: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          };
        };
      }
    | { __typename: 'ValueMappingTask'; id: string }
    | { __typename: 'VendorMatchingTask'; id: string };
};

export type GQMeasurementTimelineForOrgPageFragment = {
  __typename?: 'MeasurementTimeline';
  id: string;
  kickoff: Date;
  deadline: Date;
};

export type GQMeasurementProjectFieldsForOrgPageFragment = {
  __typename: 'MeasurementProject';
  id: string;
  name: string;
  active: boolean;
  status: GQMeasurementProjectStatus;
  completedAt: Date | null;
  coverageStartDate: Date;
  coverageEndDate: Date;
  coverageInterval: YMInterval;
  deadline: Date | null;
  supportedValueMappingRules: Array<string>;
  measurementTimeline: {
    __typename?: 'MeasurementTimeline';
    id: string;
    kickoff: Date;
    deadline: Date;
  } | null;
  measurementTasks: Array<{
    __typename?: 'MeasurementParentTask';
    taskGroup: string;
    id: string;
    title: string;
    link: string | null;
    isAssignedToWatershed: boolean;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
    subTasks: Array<
      | {
          __typename?: 'MeasurementParentTask';
          id: string;
          title: string;
          link: string | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
      | {
          __typename?: 'MeasurementTaskFlat';
          id: string;
          title: string;
          link: string | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
    >;
    dataset: { __typename?: 'Dataset'; id: string; name: string } | null;
    assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
    object:
      | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
      | {
          __typename: 'DataIssue';
          id: string;
          linkedObject:
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItemQuestion';
                id: string;
                item: {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  } | null;
                };
              }
            | {
                __typename: 'UserUploadTask';
                id: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                };
              }
            | { __typename: 'ValueMappingTask' }
            | null;
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                createdAt: Date;
                isPublished: boolean;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        }
      | { __typename: 'FacilitiesSetup'; id: string }
      | {
          __typename: 'FinancialsReviewGroup';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            };
          }>;
        }
      | {
          __typename: 'FinancialsReviewItem';
          id: string;
          group: {
            __typename: 'FinancialsReviewGroup';
            id: string;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              };
            }>;
          };
        }
      | { __typename: 'FootprintQuestion'; id: string }
      | {
          __typename: 'MeasurementDataMappingTask';
          id: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          };
        }
      | { __typename: 'MeasurementProject'; id: string }
      | {
          __typename: 'MeasurementProjectDataset';
          modelId: string | null;
          isCompleted: boolean;
          id: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          };
        }
      | {
          __typename: 'MeasurementVerificationItem';
          id: string;
          mviStatus: GQMeasurementVerificationItemStatus;
          maybeDataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          } | null;
        }
      | {
          __typename: 'UserUploadTask';
          state: GQUserUploadTaskState;
          id: string;
          approvers: Array<{
            __typename?: 'UserWithApprovalStatus';
            approvalStatus: GQApprovalStatus | null;
            user: {
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            };
          }>;
          datasource: {
            __typename?: 'Datasource';
            hasIntegration: boolean;
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            };
          };
        }
      | { __typename: 'ValueMappingTask'; id: string }
      | { __typename: 'VendorMatchingTask'; id: string };
  }>;
  measurementVerificationItems: {
    __typename?: 'MeasurementVerificationItemConnection';
    edges: Array<{
      __typename?: 'MeasurementVerificationItemEdge';
      node: {
        __typename?: 'MeasurementVerificationItem';
        id: string;
        status: GQMeasurementVerificationItemStatus;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
        assignee: { __typename?: 'User'; id: string } | null;
      } | null;
    }>;
  };
  userUploadTasks: {
    __typename?: 'UserUploadTaskConnection';
    edges: Array<{
      __typename?: 'UserUploadTaskEdge';
      node: {
        __typename: 'UserUploadTask';
        id: string;
        state: GQUserUploadTaskState;
        datasource: { __typename?: 'Datasource'; id: string; name: string };
        userUploads: {
          __typename?: 'UserUploadConnection';
          edges: Array<{
            __typename?: 'UserUploadEdge';
            node: { __typename?: 'UserUpload'; id: string } | null;
          } | null>;
        } | null;
        assignee: { __typename?: 'User'; id: string; name: string } | null;
      } | null;
    } | null>;
  };
  measurementProjectDatasets: Array<{
    __typename?: 'MeasurementProjectDataset';
    id: string;
    isCompleted: boolean;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      explainer: string | null;
      customUsedFor: string | null;
      customPotentialOverlaps: string | null;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
        kind: GQCanonicalDatasetKind;
      } | null;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
        customUsedFor: string | null;
        customPotentialOverlaps: string | null;
        customerTargetSchemaIds: Array<string> | null;
      }>;
    };
  }>;
};

export type GQEmployeeReportForAdminFragment = {
  __typename?: 'EmployeeReport';
  id: string;
  programKind: GQClimateProgramKind;
  visibility: GQEmployeeReportVisibility;
  footprintInterval: YMInterval;
  marketplaceInterval: YMInterval;
  netZeroYear: number | null;
  markdownCardInfo: {
    __typename?: 'MarkdownCardInfo';
    title: string;
    contentMd: string;
  } | null;
  climateProgramInfo: {
    __typename?: 'ClimateProgramInfo';
    hasCustomizedSteps: boolean;
    steps: Array<{
      __typename?: 'ClimateProgramInfoStep';
      title: string;
      descriptionMd: string;
    }>;
  };
  climateProgramInfoDefault: {
    __typename?: 'ClimateProgramInfo';
    steps: Array<{
      __typename?: 'ClimateProgramInfoStep';
      title: string;
      descriptionMd: string;
    }>;
  };
};

export type GQOrganizationEmployeeReportQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrganizationEmployeeReportQuery = {
  __typename?: 'Query';
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
    targets: {
      __typename?: 'TargetConnection';
      edges: Array<{
        __typename?: 'TargetEdge';
        node: {
          __typename?: 'Target';
          id: string;
          title: string;
          descriptor: string | null;
          kind: GQTargetKind;
          unit: GQTargetUnit;
          targetAmount: number;
        } | null;
      } | null>;
    };
  };
  employeeReport: {
    __typename?: 'EmployeeReport';
    id: string;
    programKind: GQClimateProgramKind;
    visibility: GQEmployeeReportVisibility;
    footprintInterval: YMInterval;
    marketplaceInterval: YMInterval;
    netZeroYear: number | null;
    markdownCardInfo: {
      __typename?: 'MarkdownCardInfo';
      title: string;
      contentMd: string;
    } | null;
    climateProgramInfo: {
      __typename?: 'ClimateProgramInfo';
      hasCustomizedSteps: boolean;
      steps: Array<{
        __typename?: 'ClimateProgramInfoStep';
        title: string;
        descriptionMd: string;
      }>;
    };
    climateProgramInfoDefault: {
      __typename?: 'ClimateProgramInfo';
      steps: Array<{
        __typename?: 'ClimateProgramInfoStep';
        title: string;
        descriptionMd: string;
      }>;
    };
  } | null;
};

export type GQCreateEmployeeReportMutationVariables = Exact<{
  input: GQCreateEmployeeReportInput;
}>;

export type GQCreateEmployeeReportMutation = {
  __typename?: 'Mutation';
  createEmployeeReport: {
    __typename?: 'CreateEmployeeReportPayload';
    employeeReport: {
      __typename?: 'EmployeeReport';
      id: string;
      programKind: GQClimateProgramKind;
      visibility: GQEmployeeReportVisibility;
      footprintInterval: YMInterval;
      marketplaceInterval: YMInterval;
      netZeroYear: number | null;
      markdownCardInfo: {
        __typename?: 'MarkdownCardInfo';
        title: string;
        contentMd: string;
      } | null;
      climateProgramInfo: {
        __typename?: 'ClimateProgramInfo';
        hasCustomizedSteps: boolean;
        steps: Array<{
          __typename?: 'ClimateProgramInfoStep';
          title: string;
          descriptionMd: string;
        }>;
      };
      climateProgramInfoDefault: {
        __typename?: 'ClimateProgramInfo';
        steps: Array<{
          __typename?: 'ClimateProgramInfoStep';
          title: string;
          descriptionMd: string;
        }>;
      };
    };
  } | null;
};

export type GQUpdateEmployeeReportMutationVariables = Exact<{
  input: GQUpdateEmployeeReportInput;
}>;

export type GQUpdateEmployeeReportMutation = {
  __typename?: 'Mutation';
  updateEmployeeReport: {
    __typename?: 'UpdateEmployeeReportPayload';
    employeeReport: {
      __typename?: 'EmployeeReport';
      id: string;
      programKind: GQClimateProgramKind;
      visibility: GQEmployeeReportVisibility;
      footprintInterval: YMInterval;
      marketplaceInterval: YMInterval;
      netZeroYear: number | null;
      markdownCardInfo: {
        __typename?: 'MarkdownCardInfo';
        title: string;
        contentMd: string;
      } | null;
      climateProgramInfo: {
        __typename?: 'ClimateProgramInfo';
        hasCustomizedSteps: boolean;
        steps: Array<{
          __typename?: 'ClimateProgramInfoStep';
          title: string;
          descriptionMd: string;
        }>;
      };
      climateProgramInfoDefault: {
        __typename?: 'ClimateProgramInfo';
        steps: Array<{
          __typename?: 'ClimateProgramInfoStep';
          title: string;
          descriptionMd: string;
        }>;
      };
    };
  } | null;
};

export type GQSharedFileForFilesTableFragment = {
  __typename?: 'SharedFile';
  id: string;
  createdAt: Date;
  expiresAt: Date;
  user: { __typename?: 'User'; id: string; name: string };
  watershedEmployee: {
    __typename?: 'WatershedEmployee';
    id: string;
    name: string;
  } | null;
  fileMetadata: {
    __typename?: 'FileMetadata';
    id: string;
    name: string;
    deletedAt: Date | null;
  };
  datasource: {
    __typename?: 'Datasource';
    id: string;
    name: string;
    dataset: { __typename?: 'Dataset'; id: string; name: string };
  };
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
  } | null;
};

export type GQSharedFileRequestForTableFragment = {
  __typename?: 'SharedFileRequest';
  id: string;
  createdAt: Date;
  fulfilledAt: Date | null;
  user: { __typename?: 'User'; id: string; name: string };
  fulfilledBy: {
    __typename?: 'WatershedEmployee';
    id: string;
    name: string;
  } | null;
  fileMetadata: {
    __typename?: 'FileMetadata';
    id: string;
    name: string;
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
      };
    } | null;
  };
  datasource: {
    __typename?: 'Datasource';
    id: string;
    name: string;
    dataset: { __typename?: 'Dataset'; id: string; name: string };
  };
};

export type GQOrganizationFilesPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQOrganizationFilesPageQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    sharedFiles: {
      __typename?: 'SharedFileConnection';
      edges: Array<{
        __typename?: 'SharedFileEdge';
        node: {
          __typename?: 'SharedFile';
          id: string;
          createdAt: Date;
          expiresAt: Date;
          user: { __typename?: 'User'; id: string; name: string };
          watershedEmployee: {
            __typename?: 'WatershedEmployee';
            id: string;
            name: string;
          } | null;
          fileMetadata: {
            __typename?: 'FileMetadata';
            id: string;
            name: string;
            deletedAt: Date | null;
          };
          datasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: { __typename?: 'Dataset'; id: string; name: string };
          };
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
          } | null;
        } | null;
      } | null>;
    };
    sharedFileRequests: {
      __typename?: 'SharedFileRequestConnection';
      edges: Array<{
        __typename?: 'SharedFileRequestEdge';
        node: {
          __typename?: 'SharedFileRequest';
          id: string;
          createdAt: Date;
          fulfilledAt: Date | null;
          user: { __typename?: 'User'; id: string; name: string };
          fulfilledBy: {
            __typename?: 'WatershedEmployee';
            id: string;
            name: string;
          } | null;
          fileMetadata: {
            __typename?: 'FileMetadata';
            id: string;
            name: string;
            userUploadTask: {
              __typename?: 'UserUploadTask';
              id: string;
              measurementProject: {
                __typename?: 'MeasurementProject';
                id: string;
                name: string;
              };
            } | null;
          };
          datasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: { __typename?: 'Dataset'; id: string; name: string };
          };
        } | null;
      } | null>;
    };
  };
};

export type GQFulfillShareFileRequestMutationVariables = Exact<{
  input: GQFulfillShareFileRequestInput;
}>;

export type GQFulfillShareFileRequestMutation = {
  __typename?: 'Mutation';
  fulfillShareFileRequest: {
    __typename?: 'FulfillShareFileRequestPayload';
    sharedFileRequests: Array<{
      __typename?: 'SharedFileRequest';
      id: string;
      createdAt: Date;
      fulfilledAt: Date | null;
      user: { __typename?: 'User'; id: string; name: string };
      fulfilledBy: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
      fileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        userUploadTask: {
          __typename?: 'UserUploadTask';
          id: string;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
          };
        } | null;
      };
      datasource: {
        __typename?: 'Datasource';
        id: string;
        name: string;
        dataset: { __typename?: 'Dataset'; id: string; name: string };
      };
    }>;
    newSharedFiles: Array<{
      __typename?: 'SharedFile';
      id: string;
      createdAt: Date;
      expiresAt: Date;
      user: { __typename?: 'User'; id: string; name: string };
      watershedEmployee: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
      fileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        deletedAt: Date | null;
      };
      datasource: {
        __typename?: 'Datasource';
        id: string;
        name: string;
        dataset: { __typename?: 'Dataset'; id: string; name: string };
      };
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
      } | null;
    }>;
  } | null;
};

export type GQActivityDataTableFieldsFragment = {
  __typename?: 'ActivityDataTable';
  id: string;
  name: string;
  lastRefreshedAt: Date | null;
  filterInterval: YMInterval;
  connectedToAutoBart: boolean;
  autoBartKind: GQAutoBartKind | null;
  autoBartTaskApprovalState: GQAutoBartTaskApprovalState;
  stage: GQActivityDataTableStageKind;
  primaryDatasource: {
    __typename?: 'Datasource';
    id: string;
    name: string;
    hasIntegration: boolean;
    hasIntegrationOption: boolean;
    activeIntegrationConnection: {
      __typename?: 'IntegrationConnection';
      id: string;
    } | null;
  } | null;
  datasets: Array<{
    __typename?: 'Dataset';
    name: string;
    id: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
    } | null;
  }>;
  datasources: Array<{
    __typename?: 'Datasource';
    id: string;
    name: string;
    hasIntegration: boolean;
    hasIntegrationOption: boolean;
    activeIntegrationConnection: {
      __typename?: 'IntegrationConnection';
      id: string;
    } | null;
  }>;
  draftCadtExtractor: {
    __typename?: 'ActivityDataExtractor';
    id: string;
    createdAt: Date;
  } | null;
  draftBartExtractor: {
    __typename?: 'ActivityDataExtractor';
    id: string;
    createdAt: Date;
    pushedBuildings: boolean | null;
  } | null;
  publishedExtractor: {
    __typename?: 'ActivityDataExtractor';
    id: string;
    createdAt: Date;
    userVisible: boolean;
    facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
    bartActivityType: string | null;
  } | null;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
  } | null;
  reviews: Array<{
    __typename?: 'ActivityDataTableReview';
    id: string;
    reviewer: { __typename?: 'User'; id: string; name: string };
  }>;
};

export type GQFootprintVersionFieldsFragment = {
  __typename?: 'FootprintVersion';
  id: string;
  orgId: string;
  createdAt: Date;
  kind: GQFootprintVersionKind;
  originalFootprintSnapshotId: string;
  internalNotesMd: string;
  userChangelogMd: string | null;
  publishableUserChangelogMd: string | null;
  isUserChangelogHidden: boolean;
  isUserVisible: boolean;
  originalFootprintSnapshot: {
    __typename?: 'FootprintSnapshot';
    id: string;
    createdAt: Date;
    descriptionMd: string | null;
    publishedAt: Date | null;
    publishedHiddenAt: Date | null;
    publishedDraftAt: Date | null;
    dataRegistryTableName: string | null;
    isStableRefreshAvailable: boolean;
    extraAdeIdsForIntDenoms: Array<string>;
    extraAdeIdsForNonEmissiveActivity: Array<string>;
    marketplaceAllocationInstructionIds: Array<string>;
    isManuallySaved: boolean;
    configs: Array<{
      __typename?: 'PipelineConfig';
      pipelineResultId: string;
      coverageInterval: YMInterval;
    }>;
    versions: Array<{
      __typename?: 'FootprintVersion';
      id: string;
      createdAt: Date;
      isUserVisible: boolean;
      kind: GQFootprintVersionKind;
      userChangelogMd: string | null;
      publishableUserChangelogMd: string | null;
      internalNotesMd: string;
      isUserChangelogHidden: boolean;
      originalFootprintSnapshotId: string;
    }>;
    tags: Array<{
      __typename?: 'FootprintTag';
      id: string;
      tagName: string;
      description: string | null;
      watershedEditor: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
        email: string;
      } | null;
    }>;
  } | null;
};

export type GQDatasetFieldsForOrgPageFragment = {
  __typename?: 'Dataset';
  id: string;
  name: string;
  datasources: Array<{ __typename?: 'Datasource'; id: string; name: string }>;
  canonicalDataset: {
    __typename?: 'CanonicalDataset';
    id: string;
    kind: GQCanonicalDatasetKind;
  } | null;
};

export type GQOrganizationFootprintsPageQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrganizationFootprintsPageQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    bigqueryTableSchema: string;
    hasAnyPv2PipelineResults: boolean;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
      }>;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    }>;
    measurementProjects: Array<{
      __typename: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      coverageInterval: YMInterval;
      adminUrl: string;
      ancestorRelations: Array<
        | {
            __typename: 'ActivityDataTable';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'DataIssue';
            id: string;
            name: string;
            adminUrl: string;
          }
        | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
        | {
            __typename: 'Datasource';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FileMetadata';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FootprintQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementProject';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
      >;
    }>;
  };
  activityDataTables: {
    __typename?: 'ActivityDataTableConnection';
    edges: Array<{
      __typename?: 'ActivityDataTableEdge';
      node: {
        __typename?: 'ActivityDataTable';
        id: string;
        name: string;
        lastRefreshedAt: Date | null;
        filterInterval: YMInterval;
        connectedToAutoBart: boolean;
        autoBartKind: GQAutoBartKind | null;
        autoBartTaskApprovalState: GQAutoBartTaskApprovalState;
        stage: GQActivityDataTableStageKind;
        primaryDatasource: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          hasIntegration: boolean;
          hasIntegrationOption: boolean;
          activeIntegrationConnection: {
            __typename?: 'IntegrationConnection';
            id: string;
          } | null;
        } | null;
        datasets: Array<{
          __typename?: 'Dataset';
          name: string;
          id: string;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        }>;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          hasIntegration: boolean;
          hasIntegrationOption: boolean;
          activeIntegrationConnection: {
            __typename?: 'IntegrationConnection';
            id: string;
          } | null;
        }>;
        draftCadtExtractor: {
          __typename?: 'ActivityDataExtractor';
          id: string;
          createdAt: Date;
        } | null;
        draftBartExtractor: {
          __typename?: 'ActivityDataExtractor';
          id: string;
          createdAt: Date;
          pushedBuildings: boolean | null;
        } | null;
        publishedExtractor: {
          __typename?: 'ActivityDataExtractor';
          id: string;
          createdAt: Date;
          userVisible: boolean;
          facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
          bartActivityType: string | null;
        } | null;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          name: string;
        } | null;
        reviews: Array<{
          __typename?: 'ActivityDataTableReview';
          id: string;
          reviewer: { __typename?: 'User'; id: string; name: string };
        }>;
      } | null;
    } | null>;
  };
  latestUserVisibleDraftFootprintVersion: {
    __typename?: 'FootprintVersion';
    id: string;
    orgId: string;
    createdAt: Date;
    kind: GQFootprintVersionKind;
    originalFootprintSnapshotId: string;
    internalNotesMd: string;
    userChangelogMd: string | null;
    publishableUserChangelogMd: string | null;
    isUserChangelogHidden: boolean;
    isUserVisible: boolean;
    originalFootprintSnapshot: {
      __typename?: 'FootprintSnapshot';
      id: string;
      createdAt: Date;
      descriptionMd: string | null;
      publishedAt: Date | null;
      publishedHiddenAt: Date | null;
      publishedDraftAt: Date | null;
      dataRegistryTableName: string | null;
      isStableRefreshAvailable: boolean;
      extraAdeIdsForIntDenoms: Array<string>;
      extraAdeIdsForNonEmissiveActivity: Array<string>;
      marketplaceAllocationInstructionIds: Array<string>;
      isManuallySaved: boolean;
      configs: Array<{
        __typename?: 'PipelineConfig';
        pipelineResultId: string;
        coverageInterval: YMInterval;
      }>;
      versions: Array<{
        __typename?: 'FootprintVersion';
        id: string;
        createdAt: Date;
        isUserVisible: boolean;
        kind: GQFootprintVersionKind;
        userChangelogMd: string | null;
        publishableUserChangelogMd: string | null;
        internalNotesMd: string;
        isUserChangelogHidden: boolean;
        originalFootprintSnapshotId: string;
      }>;
      tags: Array<{
        __typename?: 'FootprintTag';
        id: string;
        tagName: string;
        description: string | null;
        watershedEditor: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
          email: string;
        } | null;
      }>;
    } | null;
  } | null;
  latestUserVisiblePublishedFootprintVersion: {
    __typename?: 'FootprintVersion';
    id: string;
    orgId: string;
    createdAt: Date;
    kind: GQFootprintVersionKind;
    originalFootprintSnapshotId: string;
    internalNotesMd: string;
    userChangelogMd: string | null;
    publishableUserChangelogMd: string | null;
    isUserChangelogHidden: boolean;
    isUserVisible: boolean;
    originalFootprintSnapshot: {
      __typename?: 'FootprintSnapshot';
      id: string;
      createdAt: Date;
      descriptionMd: string | null;
      publishedAt: Date | null;
      publishedHiddenAt: Date | null;
      publishedDraftAt: Date | null;
      dataRegistryTableName: string | null;
      isStableRefreshAvailable: boolean;
      extraAdeIdsForIntDenoms: Array<string>;
      extraAdeIdsForNonEmissiveActivity: Array<string>;
      marketplaceAllocationInstructionIds: Array<string>;
      isManuallySaved: boolean;
      configs: Array<{
        __typename?: 'PipelineConfig';
        pipelineResultId: string;
        coverageInterval: YMInterval;
      }>;
      versions: Array<{
        __typename?: 'FootprintVersion';
        id: string;
        createdAt: Date;
        isUserVisible: boolean;
        kind: GQFootprintVersionKind;
        userChangelogMd: string | null;
        publishableUserChangelogMd: string | null;
        internalNotesMd: string;
        isUserChangelogHidden: boolean;
        originalFootprintSnapshotId: string;
      }>;
      tags: Array<{
        __typename?: 'FootprintTag';
        id: string;
        tagName: string;
        description: string | null;
        watershedEditor: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
          email: string;
        } | null;
      }>;
    } | null;
  } | null;
};

export type GQMeasurementProjectForFootprintPageFragment = {
  __typename: 'MeasurementProject';
  id: string;
  name: string;
  active: boolean;
  coverageInterval: YMInterval;
  adminUrl: string;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

export type GQPipelineResultForOrgFragment = {
  __typename?: 'PipelineResult';
  id: string;
  name: string;
  pipelineKind: GQPipelineKind;
  orgId: string;
  createdAt: Date;
  coverageInterval: YMInterval;
  runUserId: string | null;
  pipelineRunConfig: string;
  deletedAt: Date | null;
  parquetDataId: string | null;
  prkFileMetadataId: string | null;
  isUsedInLatestDraft: boolean;
  isUsedInLatestPublish: boolean;
  summary: {
    __typename?: 'PipelineOutputSummary';
    format: number;
    emissions: Array<{
      __typename?: 'PipelineOutputSummaryEmission';
      month: Date;
      kgCo2e: number;
      businessCategory: string | null;
      adtName: string | null;
      footprintKind: string;
    }>;
    warnings: Array<{
      __typename?: 'ValidationWarning';
      blocking: boolean;
      message: string;
    }> | null;
  };
  emissionsModelEvaluations: Array<{
    __typename?: 'EmissionsModelEvaluation';
    id: string;
    uniqueBartRowCount: number | null;
    bartTotalRowCount: number | null;
    evaluatedBartId: string | null;
    evaluatedEmtaFmdId: string | null;
    emissionsModelVersion: {
      __typename?: 'EmissionsModelVersion';
      id: string;
      stableModel: {
        __typename?: 'EmissionsModelStable';
        id: string;
        title: string;
      };
    };
    activityDataExtractor: {
      __typename?: 'ActivityDataExtractor';
      id: string;
      activityDataTable: {
        __typename?: 'ActivityDataTable';
        id: string;
        name: string;
      };
    } | null;
    evaluatedExtendedBart: {
      __typename?: 'ExtendedBart';
      fileMetadataId: string;
    } | null;
  }>;
};

export type GQGetPipelineResultsForOrgQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetPipelineResultsForOrgQuery = {
  __typename?: 'Query';
  pipelineResults: {
    __typename?: 'PipelineResultConnection';
    edges: Array<{
      __typename?: 'PipelineResultEdge';
      node: {
        __typename?: 'PipelineResult';
        id: string;
        name: string;
        pipelineKind: GQPipelineKind;
        orgId: string;
        createdAt: Date;
        coverageInterval: YMInterval;
        runUserId: string | null;
        pipelineRunConfig: string;
        deletedAt: Date | null;
        parquetDataId: string | null;
        prkFileMetadataId: string | null;
        isUsedInLatestDraft: boolean;
        isUsedInLatestPublish: boolean;
        summary: {
          __typename?: 'PipelineOutputSummary';
          format: number;
          emissions: Array<{
            __typename?: 'PipelineOutputSummaryEmission';
            month: Date;
            kgCo2e: number;
            businessCategory: string | null;
            adtName: string | null;
            footprintKind: string;
          }>;
          warnings: Array<{
            __typename?: 'ValidationWarning';
            blocking: boolean;
            message: string;
          }> | null;
        };
        emissionsModelEvaluations: Array<{
          __typename?: 'EmissionsModelEvaluation';
          id: string;
          uniqueBartRowCount: number | null;
          bartTotalRowCount: number | null;
          evaluatedBartId: string | null;
          evaluatedEmtaFmdId: string | null;
          emissionsModelVersion: {
            __typename?: 'EmissionsModelVersion';
            id: string;
            stableModel: {
              __typename?: 'EmissionsModelStable';
              id: string;
              title: string;
            };
          };
          activityDataExtractor: {
            __typename?: 'ActivityDataExtractor';
            id: string;
            activityDataTable: {
              __typename?: 'ActivityDataTable';
              id: string;
              name: string;
            };
          } | null;
          evaluatedExtendedBart: {
            __typename?: 'ExtendedBart';
            fileMetadataId: string;
          } | null;
        }>;
      } | null;
    } | null>;
  };
};

export type GQPipelineDialogContentsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQPipelineDialogContentsQuery = {
  __typename?: 'Query';
  pipelineResults: {
    __typename?: 'PipelineResultConnection';
    edges: Array<{
      __typename?: 'PipelineResultEdge';
      node: {
        __typename?: 'PipelineResult';
        id: string;
        name: string;
        pipelineKind: GQPipelineKind;
        orgId: string;
        createdAt: Date;
        coverageInterval: YMInterval;
        runUserId: string | null;
        pipelineRunConfig: string;
        deletedAt: Date | null;
        parquetDataId: string | null;
        prkFileMetadataId: string | null;
        isUsedInLatestDraft: boolean;
        isUsedInLatestPublish: boolean;
        summary: {
          __typename?: 'PipelineOutputSummary';
          format: number;
          emissions: Array<{
            __typename?: 'PipelineOutputSummaryEmission';
            month: Date;
            kgCo2e: number;
            businessCategory: string | null;
            adtName: string | null;
            footprintKind: string;
          }>;
          warnings: Array<{
            __typename?: 'ValidationWarning';
            blocking: boolean;
            message: string;
          }> | null;
        };
        emissionsModelEvaluations: Array<{
          __typename?: 'EmissionsModelEvaluation';
          id: string;
          uniqueBartRowCount: number | null;
          bartTotalRowCount: number | null;
          evaluatedBartId: string | null;
          evaluatedEmtaFmdId: string | null;
          emissionsModelVersion: {
            __typename?: 'EmissionsModelVersion';
            id: string;
            stableModel: {
              __typename?: 'EmissionsModelStable';
              id: string;
              title: string;
            };
          };
          activityDataExtractor: {
            __typename?: 'ActivityDataExtractor';
            id: string;
            activityDataTable: {
              __typename?: 'ActivityDataTable';
              id: string;
              name: string;
            };
          } | null;
          evaluatedExtendedBart: {
            __typename?: 'ExtendedBart';
            fileMetadataId: string;
          } | null;
        }>;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type GQFootprintSnapshotOrgFootprintsFieldsFragment = {
  __typename?: 'FootprintSnapshot';
  id: string;
  createdAt: Date;
  descriptionMd: string | null;
  publishedAt: Date | null;
  publishedHiddenAt: Date | null;
  publishedDraftAt: Date | null;
  dataRegistryTableName: string | null;
  isStableRefreshAvailable: boolean;
  extraAdeIdsForIntDenoms: Array<string>;
  extraAdeIdsForNonEmissiveActivity: Array<string>;
  marketplaceAllocationInstructionIds: Array<string>;
  isManuallySaved: boolean;
  configs: Array<{
    __typename?: 'PipelineConfig';
    pipelineResultId: string;
    coverageInterval: YMInterval;
  }>;
  versions: Array<{
    __typename?: 'FootprintVersion';
    id: string;
    createdAt: Date;
    isUserVisible: boolean;
    kind: GQFootprintVersionKind;
    userChangelogMd: string | null;
    publishableUserChangelogMd: string | null;
    internalNotesMd: string;
    isUserChangelogHidden: boolean;
    originalFootprintSnapshotId: string;
  }>;
  tags: Array<{
    __typename?: 'FootprintTag';
    id: string;
    tagName: string;
    description: string | null;
    watershedEditor: {
      __typename?: 'WatershedEmployee';
      id: string;
      name: string;
      email: string;
    } | null;
  }>;
};

export type GQAdminUserFieldsForOrgPageFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  displayName: string;
  email: string;
  loginActivated: boolean;
  consecutiveLoginFailures: number;
  didLatestEmailBounce: boolean;
  orgAccessId: string;
};

export type GQWatershedEmployeeFieldsForOrgPageFragment = {
  __typename: 'WatershedEmployee';
  id: string;
  name: string;
  email: string;
};

export type GQOrgPointOfContactFieldsForOrgPageFragment = {
  __typename: 'OrgPointOfContact';
  id: string;
  kind: GQOrgPointOfContactKind;
  createdAt: Date;
  deletedAt: Date | null;
  watershedEmployee: {
    __typename: 'WatershedEmployee';
    id: string;
    name: string;
    email: string;
  };
};

export type GQAdminUsersForOrgPageFragment = {
  __typename: 'Organization';
  adminUsers: {
    __typename?: 'UserConnection';
    edges: Array<{
      __typename?: 'UserEdge';
      node: {
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
        email: string;
        loginActivated: boolean;
        consecutiveLoginFailures: number;
        didLatestEmailBounce: boolean;
        orgAccessId: string;
      } | null;
    } | null>;
  } | null;
};

export type GQOrganizationFieldsForOrgPageFragment = {
  __typename: 'Organization';
  id: string;
  name: string;
  bigqueryDatasetName: string | null;
  legalName: string | null;
  helpChannel: string | null;
  domains: Array<string>;
  passwordAuthDisabled: boolean;
  notionUrl: string | null;
  magicLinkDisabled: boolean;
  demoOrg: boolean;
  dataRegion: GQDataRegion;
  isWatershed: boolean;
  watershedPlan: GQWatershedPlan;
  watershedPlanLegacy: GQWatershedPlanLegacy;
  workosOrgId: string | null;
  fiscalYearStartMonth: number | null;
  currency: string | null;
  iconRemotePath: string | null;
  logoRemotePath: string | null;
  country: string | null;
  productWaitlistTypes: Array<GQProductWaitlistType>;
  canAccessCorporate: boolean | null;
  canAccessFinance: boolean | null;
  testOrg: boolean;
  stagingOrg: boolean | null;
  watershedSlackChannelId: string | null;
  deletedAt: Date | null;
  lifecycle: {
    __typename?: 'ClimateProgramLifecycle';
    nextStage: GQNextLifecycleStage | null;
    completedStage: GQCompletedLifecycleStage;
    kickoffBlurb: string | null;
  } | null;
  pointsOfContact: Array<{
    __typename: 'OrgPointOfContact';
    id: string;
    kind: GQOrgPointOfContactKind;
    createdAt: Date;
    deletedAt: Date | null;
    watershedEmployee: {
      __typename: 'WatershedEmployee';
      id: string;
      name: string;
      email: string;
    };
  }>;
  pointsOfContactIncludingDeleted: Array<{
    __typename: 'OrgPointOfContact';
    id: string;
    kind: GQOrgPointOfContactKind;
    createdAt: Date;
    deletedAt: Date | null;
    watershedEmployee: {
      __typename: 'WatershedEmployee';
      id: string;
      name: string;
      email: string;
    };
  }>;
  apiKeys: Array<{ __typename?: 'ApiKey'; id: string; secret: string }>;
  workosConnections: Array<{
    __typename?: 'WorkosConnection';
    id: string;
    name: string;
    connectionType: string;
  }>;
  company: { __typename?: 'Company'; id: string; name: string };
  profile: {
    __typename?: 'OrgProfile';
    id: string;
    name: string | null;
    fiscalYearStartMonth: number | null;
    country: string | null;
    industry: string | null;
    isCompanyDataConfirmed: boolean | null;
    hasMeasuredFootprintOutsideOfWatershed: GQYesNoUnknown | null;
    lastNonWatershedMeasurementYearString: string | null;
    hasClimateCommitments: GQYesNoUnknown | null;
    climateCommitments: Array<GQCompanyClimateCommitmentKind> | null;
    otherClimateCommitment: string | null;
    climateGoals: Array<string> | null;
    climateMotivation: string | null;
    completedAt: Date | null;
    updatedAt: Date | null;
    completedBy: { __typename?: 'User'; id: string; name: string } | null;
    updatedBy: { __typename?: 'User'; id: string; name: string } | null;
  };
};

export type GQOrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQOrganizationQuery = {
  __typename?: 'Query';
  orgFunds: {
    __typename?: 'FundConnection';
    edges: Array<{
      __typename?: 'FundEdge';
      node: { __typename?: 'Fund'; id: string; name: string } | null;
    } | null>;
  };
  organization: {
    __typename: 'Organization';
    id: string;
    name: string;
    bigqueryDatasetName: string | null;
    legalName: string | null;
    helpChannel: string | null;
    domains: Array<string>;
    passwordAuthDisabled: boolean;
    notionUrl: string | null;
    magicLinkDisabled: boolean;
    demoOrg: boolean;
    dataRegion: GQDataRegion;
    isWatershed: boolean;
    watershedPlan: GQWatershedPlan;
    watershedPlanLegacy: GQWatershedPlanLegacy;
    workosOrgId: string | null;
    fiscalYearStartMonth: number | null;
    currency: string | null;
    iconRemotePath: string | null;
    logoRemotePath: string | null;
    country: string | null;
    productWaitlistTypes: Array<GQProductWaitlistType>;
    canAccessCorporate: boolean | null;
    canAccessFinance: boolean | null;
    testOrg: boolean;
    stagingOrg: boolean | null;
    watershedSlackChannelId: string | null;
    deletedAt: Date | null;
    roles: {
      __typename?: 'RoleConnection';
      edges: Array<{
        __typename?: 'RoleEdge';
        node: {
          __typename?: 'Role';
          id: string;
          name: string;
          description: string | null;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
          users: Array<{
            __typename?: 'UserRoleAssignment';
            id: string;
            user: {
              __typename?: 'User';
              id: string;
              displayName: string;
              name: string;
            };
          }>;
          apiKeys: Array<{
            __typename?: 'ApiKeyRoleAssignment';
            id: string;
            apiKey: {
              __typename?: 'ApiKey';
              id: string;
              name: string;
              description: string | null;
            };
          }>;
        } | null;
      } | null>;
    } | null;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      datasources: Array<{
        __typename?: 'Datasource';
        name: string;
        id: string;
      }>;
    }>;
    lifecycle: {
      __typename?: 'ClimateProgramLifecycle';
      nextStage: GQNextLifecycleStage | null;
      completedStage: GQCompletedLifecycleStage;
      kickoffBlurb: string | null;
    } | null;
    pointsOfContact: Array<{
      __typename: 'OrgPointOfContact';
      id: string;
      kind: GQOrgPointOfContactKind;
      createdAt: Date;
      deletedAt: Date | null;
      watershedEmployee: {
        __typename: 'WatershedEmployee';
        id: string;
        name: string;
        email: string;
      };
    }>;
    pointsOfContactIncludingDeleted: Array<{
      __typename: 'OrgPointOfContact';
      id: string;
      kind: GQOrgPointOfContactKind;
      createdAt: Date;
      deletedAt: Date | null;
      watershedEmployee: {
        __typename: 'WatershedEmployee';
        id: string;
        name: string;
        email: string;
      };
    }>;
    apiKeys: Array<{ __typename?: 'ApiKey'; id: string; secret: string }>;
    workosConnections: Array<{
      __typename?: 'WorkosConnection';
      id: string;
      name: string;
      connectionType: string;
    }>;
    company: { __typename?: 'Company'; id: string; name: string };
    profile: {
      __typename?: 'OrgProfile';
      id: string;
      name: string | null;
      fiscalYearStartMonth: number | null;
      country: string | null;
      industry: string | null;
      isCompanyDataConfirmed: boolean | null;
      hasMeasuredFootprintOutsideOfWatershed: GQYesNoUnknown | null;
      lastNonWatershedMeasurementYearString: string | null;
      hasClimateCommitments: GQYesNoUnknown | null;
      climateCommitments: Array<GQCompanyClimateCommitmentKind> | null;
      otherClimateCommitment: string | null;
      climateGoals: Array<string> | null;
      climateMotivation: string | null;
      completedAt: Date | null;
      updatedAt: Date | null;
      completedBy: { __typename?: 'User'; id: string; name: string } | null;
      updatedBy: { __typename?: 'User'; id: string; name: string } | null;
    };
    adminUsers: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          email: string;
          loginActivated: boolean;
          consecutiveLoginFailures: number;
          didLatestEmailBounce: boolean;
          orgAccessId: string;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQPlansForOrgQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQPlansForOrgQuery = {
  __typename?: 'Query';
  plansForOrg: Array<{ __typename?: 'Plan'; id: string }>;
};

export type GQUpdateOrganizationMutationVariables = Exact<{
  input: GQUpdateOrganizationInput;
}>;

export type GQUpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization: {
    __typename?: 'OrganizationPayload';
    org: {
      __typename: 'Organization';
      id: string;
      passwordAuthDisabled: boolean;
      magicLinkDisabled: boolean;
      notionUrl: string | null;
      domains: Array<string>;
      currency: string | null;
      fiscalYearStartMonth: number | null;
      logoRemotePath: string | null;
      iconRemotePath: string | null;
      country: string | null;
      name: string;
      bigqueryDatasetName: string | null;
      legalName: string | null;
      helpChannel: string | null;
      demoOrg: boolean;
      dataRegion: GQDataRegion;
      isWatershed: boolean;
      watershedPlan: GQWatershedPlan;
      watershedPlanLegacy: GQWatershedPlanLegacy;
      workosOrgId: string | null;
      productWaitlistTypes: Array<GQProductWaitlistType>;
      canAccessCorporate: boolean | null;
      canAccessFinance: boolean | null;
      testOrg: boolean;
      stagingOrg: boolean | null;
      watershedSlackChannelId: string | null;
      deletedAt: Date | null;
      company: { __typename?: 'Company'; name: string; id: string };
      lifecycle: {
        __typename?: 'ClimateProgramLifecycle';
        nextStage: GQNextLifecycleStage | null;
        completedStage: GQCompletedLifecycleStage;
        kickoffBlurb: string | null;
      } | null;
      pointsOfContact: Array<{
        __typename: 'OrgPointOfContact';
        id: string;
        kind: GQOrgPointOfContactKind;
        createdAt: Date;
        deletedAt: Date | null;
        watershedEmployee: {
          __typename: 'WatershedEmployee';
          id: string;
          name: string;
          email: string;
        };
      }>;
      pointsOfContactIncludingDeleted: Array<{
        __typename: 'OrgPointOfContact';
        id: string;
        kind: GQOrgPointOfContactKind;
        createdAt: Date;
        deletedAt: Date | null;
        watershedEmployee: {
          __typename: 'WatershedEmployee';
          id: string;
          name: string;
          email: string;
        };
      }>;
      apiKeys: Array<{ __typename?: 'ApiKey'; id: string; secret: string }>;
      workosConnections: Array<{
        __typename?: 'WorkosConnection';
        id: string;
        name: string;
        connectionType: string;
      }>;
      profile: {
        __typename?: 'OrgProfile';
        id: string;
        name: string | null;
        fiscalYearStartMonth: number | null;
        country: string | null;
        industry: string | null;
        isCompanyDataConfirmed: boolean | null;
        hasMeasuredFootprintOutsideOfWatershed: GQYesNoUnknown | null;
        lastNonWatershedMeasurementYearString: string | null;
        hasClimateCommitments: GQYesNoUnknown | null;
        climateCommitments: Array<GQCompanyClimateCommitmentKind> | null;
        otherClimateCommitment: string | null;
        climateGoals: Array<string> | null;
        climateMotivation: string | null;
        completedAt: Date | null;
        updatedAt: Date | null;
        completedBy: { __typename?: 'User'; id: string; name: string } | null;
        updatedBy: { __typename?: 'User'; id: string; name: string } | null;
      };
    };
  } | null;
};

export type GQDeleteOrganizationMutationVariables = Exact<{
  input: GQDeleteOrganizationInput;
}>;

export type GQDeleteOrganizationMutation = {
  __typename?: 'Mutation';
  deleteOrganization: {
    __typename?: 'DeleteOrganizationPayload';
    id: string;
  } | null;
};

export type GQInitializeWorkosMutationVariables = Exact<{
  input: GQInitializeWorkosInput;
}>;

export type GQInitializeWorkosMutation = {
  __typename?: 'Mutation';
  initializeWorkos: {
    __typename?: 'InitializeWorkosPayload';
    org: {
      __typename?: 'Organization';
      id: string;
      workosOrgId: string | null;
    };
  } | null;
};

export type GQCreateApiKeyMutationVariables = Exact<{
  input: GQCreateApiKeyInput;
}>;

export type GQCreateApiKeyMutation = {
  __typename?: 'Mutation';
  createApiKey: {
    __typename?: 'CreateApiKeyPayload';
    key: { __typename?: 'ApiKey'; id: string; secret: string };
  } | null;
};

export type GQUpdateOrgProfileAdminMutationVariables = Exact<{
  input: GQUpdateOrgProfileInput;
}>;

export type GQUpdateOrgProfileAdminMutation = {
  __typename?: 'Mutation';
  updateOrgProfile: {
    __typename?: 'UpdateOrgProfilePayload';
    organization: {
      __typename: 'Organization';
      id: string;
      name: string;
      bigqueryDatasetName: string | null;
      legalName: string | null;
      helpChannel: string | null;
      domains: Array<string>;
      passwordAuthDisabled: boolean;
      notionUrl: string | null;
      magicLinkDisabled: boolean;
      demoOrg: boolean;
      dataRegion: GQDataRegion;
      isWatershed: boolean;
      watershedPlan: GQWatershedPlan;
      watershedPlanLegacy: GQWatershedPlanLegacy;
      workosOrgId: string | null;
      fiscalYearStartMonth: number | null;
      currency: string | null;
      iconRemotePath: string | null;
      logoRemotePath: string | null;
      country: string | null;
      productWaitlistTypes: Array<GQProductWaitlistType>;
      canAccessCorporate: boolean | null;
      canAccessFinance: boolean | null;
      testOrg: boolean;
      stagingOrg: boolean | null;
      watershedSlackChannelId: string | null;
      deletedAt: Date | null;
      lifecycle: {
        __typename?: 'ClimateProgramLifecycle';
        nextStage: GQNextLifecycleStage | null;
        completedStage: GQCompletedLifecycleStage;
        kickoffBlurb: string | null;
      } | null;
      pointsOfContact: Array<{
        __typename: 'OrgPointOfContact';
        id: string;
        kind: GQOrgPointOfContactKind;
        createdAt: Date;
        deletedAt: Date | null;
        watershedEmployee: {
          __typename: 'WatershedEmployee';
          id: string;
          name: string;
          email: string;
        };
      }>;
      pointsOfContactIncludingDeleted: Array<{
        __typename: 'OrgPointOfContact';
        id: string;
        kind: GQOrgPointOfContactKind;
        createdAt: Date;
        deletedAt: Date | null;
        watershedEmployee: {
          __typename: 'WatershedEmployee';
          id: string;
          name: string;
          email: string;
        };
      }>;
      apiKeys: Array<{ __typename?: 'ApiKey'; id: string; secret: string }>;
      workosConnections: Array<{
        __typename?: 'WorkosConnection';
        id: string;
        name: string;
        connectionType: string;
      }>;
      company: { __typename?: 'Company'; id: string; name: string };
      profile: {
        __typename?: 'OrgProfile';
        id: string;
        name: string | null;
        fiscalYearStartMonth: number | null;
        country: string | null;
        industry: string | null;
        isCompanyDataConfirmed: boolean | null;
        hasMeasuredFootprintOutsideOfWatershed: GQYesNoUnknown | null;
        lastNonWatershedMeasurementYearString: string | null;
        hasClimateCommitments: GQYesNoUnknown | null;
        climateCommitments: Array<GQCompanyClimateCommitmentKind> | null;
        otherClimateCommitment: string | null;
        climateGoals: Array<string> | null;
        climateMotivation: string | null;
        completedAt: Date | null;
        updatedAt: Date | null;
        completedBy: { __typename?: 'User'; id: string; name: string } | null;
        updatedBy: { __typename?: 'User'; id: string; name: string } | null;
      };
    };
  };
};

export type GQAssigneeForEditDataIssuesPageFieldsFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  permissions: Array<{
    __typename?: 'PermissionItem';
    id: string;
    objectId: string | null;
    permission: GQPermissionType;
    revokedAt: Date | null;
    object:
      | { __typename: 'Company'; id: string; name: string }
      | { __typename: 'CompanySurvey'; id: string; name: string }
      | { __typename: 'Dataset'; id: string; name: string }
      | { __typename: 'Datasource'; id: string; name: string }
      | { __typename: 'EngagementTask'; id: string; name: string }
      | { __typename: 'Fund'; id: string; name: string }
      | null;
  }>;
  roles: Array<{
    __typename?: 'UserRoleAssignment';
    id: string;
    revokedAt: Date | null;
    role: {
      __typename?: 'Role';
      id: string;
      name: string;
      description: string | null;
      permissions: Array<{
        __typename?: 'PermissionItem';
        id: string;
        objectId: string | null;
        permission: GQPermissionType;
        revokedAt: Date | null;
        object:
          | { __typename: 'Company'; id: string; name: string }
          | { __typename: 'CompanySurvey'; id: string; name: string }
          | { __typename: 'Dataset'; id: string; name: string }
          | { __typename: 'Datasource'; id: string; name: string }
          | { __typename: 'EngagementTask'; id: string; name: string }
          | { __typename: 'Fund'; id: string; name: string }
          | null;
      }>;
    };
  }>;
};

type GQDataIssueLinkedObjectFields_FinancialsReviewItem_Fragment = {
  __typename: 'FinancialsReviewItem';
  id: string;
};

type GQDataIssueLinkedObjectFields_MeasurementVerificationItemQuestion_Fragment =
  {
    __typename: 'MeasurementVerificationItemQuestion';
    id: string;
    item: {
      __typename?: 'MeasurementVerificationItem';
      id: string;
      datasetName: string;
    };
  };

type GQDataIssueLinkedObjectFields_UserUploadTask_Fragment = {
  __typename: 'UserUploadTask';
  id: string;
  assignee: {
    __typename?: 'User';
    id: string;
    name: string;
    permissions: Array<{
      __typename?: 'PermissionItem';
      id: string;
      objectId: string | null;
      permission: GQPermissionType;
      revokedAt: Date | null;
      object:
        | { __typename: 'Company'; id: string; name: string }
        | { __typename: 'CompanySurvey'; id: string; name: string }
        | { __typename: 'Dataset'; id: string; name: string }
        | { __typename: 'Datasource'; id: string; name: string }
        | { __typename: 'EngagementTask'; id: string; name: string }
        | { __typename: 'Fund'; id: string; name: string }
        | null;
    }>;
    roles: Array<{
      __typename?: 'UserRoleAssignment';
      id: string;
      revokedAt: Date | null;
      role: {
        __typename?: 'Role';
        id: string;
        name: string;
        description: string | null;
        permissions: Array<{
          __typename?: 'PermissionItem';
          id: string;
          objectId: string | null;
          permission: GQPermissionType;
          revokedAt: Date | null;
          object:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanySurvey'; id: string; name: string }
            | { __typename: 'Dataset'; id: string; name: string }
            | { __typename: 'Datasource'; id: string; name: string }
            | { __typename: 'EngagementTask'; id: string; name: string }
            | { __typename: 'Fund'; id: string; name: string }
            | null;
        }>;
      };
    }>;
  } | null;
  datasource: {
    __typename?: 'Datasource';
    id: string;
    name: string;
    dataset: { __typename?: 'Dataset'; id: string; name: string };
  };
};

type GQDataIssueLinkedObjectFields_ValueMappingTask_Fragment = {
  __typename: 'ValueMappingTask';
  id: string;
};

export type GQDataIssueLinkedObjectFieldsFragment =
  | GQDataIssueLinkedObjectFields_FinancialsReviewItem_Fragment
  | GQDataIssueLinkedObjectFields_MeasurementVerificationItemQuestion_Fragment
  | GQDataIssueLinkedObjectFields_UserUploadTask_Fragment
  | GQDataIssueLinkedObjectFields_ValueMappingTask_Fragment;

export type GQDataIssueForEditDataIssuesPageFieldsFragment = {
  __typename: 'DataIssue';
  id: string;
  title: string;
  description: string | null;
  state: GQDataIssueState;
  isPublished: boolean;
  isCustomerInitiated: boolean;
  createdAt: Date;
  tags: Array<GQDataIssueTag> | null;
  name: string;
  adminUrl: string;
  assignee: {
    __typename?: 'User';
    id: string;
    name: string;
    permissions: Array<{
      __typename?: 'PermissionItem';
      id: string;
      objectId: string | null;
      permission: GQPermissionType;
      revokedAt: Date | null;
      object:
        | { __typename: 'Company'; id: string; name: string }
        | { __typename: 'CompanySurvey'; id: string; name: string }
        | { __typename: 'Dataset'; id: string; name: string }
        | { __typename: 'Datasource'; id: string; name: string }
        | { __typename: 'EngagementTask'; id: string; name: string }
        | { __typename: 'Fund'; id: string; name: string }
        | null;
    }>;
    roles: Array<{
      __typename?: 'UserRoleAssignment';
      id: string;
      revokedAt: Date | null;
      role: {
        __typename?: 'Role';
        id: string;
        name: string;
        description: string | null;
        permissions: Array<{
          __typename?: 'PermissionItem';
          id: string;
          objectId: string | null;
          permission: GQPermissionType;
          revokedAt: Date | null;
          object:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanySurvey'; id: string; name: string }
            | { __typename: 'Dataset'; id: string; name: string }
            | { __typename: 'Datasource'; id: string; name: string }
            | { __typename: 'EngagementTask'; id: string; name: string }
            | { __typename: 'Fund'; id: string; name: string }
            | null;
        }>;
      };
    }>;
  } | null;
  comments: {
    __typename?: 'CommentConnection';
    edges: Array<{
      __typename?: 'CommentEdge';
      node: {
        __typename?: 'Comment';
        id: string;
        message: string;
        createdAt: Date;
        isPublished: boolean;
        person:
          | {
              __typename?: 'User';
              id: string;
              name: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
            }
          | {
              __typename?: 'WatershedEmployee';
              id: string;
              name: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
            }
          | null;
      } | null;
    } | null>;
  };
  files: {
    __typename?: 'FileMetadataConnection';
    edges: Array<{
      __typename?: 'FileMetadataEdge';
      node: { __typename?: 'FileMetadata'; id: string } | null;
    } | null>;
  };
  linkedObject:
    | { __typename: 'FinancialsReviewItem'; id: string }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        item: {
          __typename?: 'MeasurementVerificationItem';
          id: string;
          datasetName: string;
        };
      }
    | {
        __typename: 'UserUploadTask';
        id: string;
        assignee: {
          __typename?: 'User';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
          roles: Array<{
            __typename?: 'UserRoleAssignment';
            id: string;
            revokedAt: Date | null;
            role: {
              __typename?: 'Role';
              id: string;
              name: string;
              description: string | null;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
            };
          }>;
        } | null;
        datasource: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: { __typename?: 'Dataset'; id: string; name: string };
        };
      }
    | { __typename: 'ValueMappingTask'; id: string }
    | null;
  ancestorRelations: Array<
    | {
        __typename: 'ActivityDataTable';
        id: string;
        name: string;
        adminUrl: string;
      }
    | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
    | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
    | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
    | { __typename: 'FileMetadata'; id: string; name: string; adminUrl: string }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'FootprintQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        adminUrl: string;
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        name: string;
        adminUrl: string;
      }
  >;
};

export type GQUserUploadTaskForAssigneePermissionCheckFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  assignee: {
    __typename?: 'User';
    id: string;
    name: string;
    permissions: Array<{
      __typename?: 'PermissionItem';
      id: string;
      objectId: string | null;
      permission: GQPermissionType;
      revokedAt: Date | null;
      object:
        | { __typename: 'Company'; id: string; name: string }
        | { __typename: 'CompanySurvey'; id: string; name: string }
        | { __typename: 'Dataset'; id: string; name: string }
        | { __typename: 'Datasource'; id: string; name: string }
        | { __typename: 'EngagementTask'; id: string; name: string }
        | { __typename: 'Fund'; id: string; name: string }
        | null;
    }>;
    roles: Array<{
      __typename?: 'UserRoleAssignment';
      id: string;
      revokedAt: Date | null;
      role: {
        __typename?: 'Role';
        id: string;
        name: string;
        description: string | null;
        permissions: Array<{
          __typename?: 'PermissionItem';
          id: string;
          objectId: string | null;
          permission: GQPermissionType;
          revokedAt: Date | null;
          object:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanySurvey'; id: string; name: string }
            | { __typename: 'Dataset'; id: string; name: string }
            | { __typename: 'Datasource'; id: string; name: string }
            | { __typename: 'EngagementTask'; id: string; name: string }
            | { __typename: 'Fund'; id: string; name: string }
            | null;
        }>;
      };
    }>;
  } | null;
  datasource: {
    __typename?: 'Datasource';
    id: string;
    name: string;
    dataset: { __typename?: 'Dataset'; id: string; name: string };
  };
};

export type GQMeasurementProjectForEditDataIssuesPageFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  coverageInterval: YMInterval;
  active: boolean;
  dataIssues: Array<{
    __typename: 'DataIssue';
    id: string;
    title: string;
    description: string | null;
    state: GQDataIssueState;
    isPublished: boolean;
    isCustomerInitiated: boolean;
    createdAt: Date;
    tags: Array<GQDataIssueTag> | null;
    name: string;
    adminUrl: string;
    assignee: {
      __typename?: 'User';
      id: string;
      name: string;
      permissions: Array<{
        __typename?: 'PermissionItem';
        id: string;
        objectId: string | null;
        permission: GQPermissionType;
        revokedAt: Date | null;
        object:
          | { __typename: 'Company'; id: string; name: string }
          | { __typename: 'CompanySurvey'; id: string; name: string }
          | { __typename: 'Dataset'; id: string; name: string }
          | { __typename: 'Datasource'; id: string; name: string }
          | { __typename: 'EngagementTask'; id: string; name: string }
          | { __typename: 'Fund'; id: string; name: string }
          | null;
      }>;
      roles: Array<{
        __typename?: 'UserRoleAssignment';
        id: string;
        revokedAt: Date | null;
        role: {
          __typename?: 'Role';
          id: string;
          name: string;
          description: string | null;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
        };
      }>;
    } | null;
    comments: {
      __typename?: 'CommentConnection';
      edges: Array<{
        __typename?: 'CommentEdge';
        node: {
          __typename?: 'Comment';
          id: string;
          message: string;
          createdAt: Date;
          isPublished: boolean;
          person:
            | {
                __typename?: 'User';
                id: string;
                name: string;
                isWatershedEmployee: boolean;
                isWatershedContractor: boolean;
              }
            | {
                __typename?: 'WatershedEmployee';
                id: string;
                name: string;
                isWatershedEmployee: boolean;
                isWatershedContractor: boolean;
              }
            | null;
        } | null;
      } | null>;
    };
    files: {
      __typename?: 'FileMetadataConnection';
      edges: Array<{
        __typename?: 'FileMetadataEdge';
        node: { __typename?: 'FileMetadata'; id: string } | null;
      } | null>;
    };
    linkedObject:
      | { __typename: 'FinancialsReviewItem'; id: string }
      | {
          __typename: 'MeasurementVerificationItemQuestion';
          id: string;
          item: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            datasetName: string;
          };
        }
      | {
          __typename: 'UserUploadTask';
          id: string;
          assignee: {
            __typename?: 'User';
            id: string;
            name: string;
            permissions: Array<{
              __typename?: 'PermissionItem';
              id: string;
              objectId: string | null;
              permission: GQPermissionType;
              revokedAt: Date | null;
              object:
                | { __typename: 'Company'; id: string; name: string }
                | { __typename: 'CompanySurvey'; id: string; name: string }
                | { __typename: 'Dataset'; id: string; name: string }
                | { __typename: 'Datasource'; id: string; name: string }
                | { __typename: 'EngagementTask'; id: string; name: string }
                | { __typename: 'Fund'; id: string; name: string }
                | null;
            }>;
            roles: Array<{
              __typename?: 'UserRoleAssignment';
              id: string;
              revokedAt: Date | null;
              role: {
                __typename?: 'Role';
                id: string;
                name: string;
                description: string | null;
                permissions: Array<{
                  __typename?: 'PermissionItem';
                  id: string;
                  objectId: string | null;
                  permission: GQPermissionType;
                  revokedAt: Date | null;
                  object:
                    | { __typename: 'Company'; id: string; name: string }
                    | { __typename: 'CompanySurvey'; id: string; name: string }
                    | { __typename: 'Dataset'; id: string; name: string }
                    | { __typename: 'Datasource'; id: string; name: string }
                    | { __typename: 'EngagementTask'; id: string; name: string }
                    | { __typename: 'Fund'; id: string; name: string }
                    | null;
                }>;
              };
            }>;
          } | null;
          datasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: { __typename?: 'Dataset'; id: string; name: string };
          };
        }
      | { __typename: 'ValueMappingTask'; id: string }
      | null;
    ancestorRelations: Array<
      | {
          __typename: 'ActivityDataTable';
          id: string;
          name: string;
          adminUrl: string;
        }
      | { __typename: 'DataIssue'; id: string; name: string; adminUrl: string }
      | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
      | { __typename: 'Datasource'; id: string; name: string; adminUrl: string }
      | {
          __typename: 'FileMetadata';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'FinancialsReviewItem';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'FootprintQuestion';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'MeasurementProject';
          id: string;
          name: string;
          adminUrl: string;
        }
      | {
          __typename: 'MeasurementVerificationItemQuestion';
          id: string;
          name: string;
          adminUrl: string;
        }
    >;
  }>;
};

export type GQOrganizationDataIssuesPageQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrganizationDataIssuesPageQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      coverageInterval: YMInterval;
      active: boolean;
      dataIssues: Array<{
        __typename: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        isPublished: boolean;
        isCustomerInitiated: boolean;
        createdAt: Date;
        tags: Array<GQDataIssueTag> | null;
        name: string;
        adminUrl: string;
        assignee: {
          __typename?: 'User';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
          roles: Array<{
            __typename?: 'UserRoleAssignment';
            id: string;
            revokedAt: Date | null;
            role: {
              __typename?: 'Role';
              id: string;
              name: string;
              description: string | null;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
            };
          }>;
        } | null;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
        files: {
          __typename?: 'FileMetadataConnection';
          edges: Array<{
            __typename?: 'FileMetadataEdge';
            node: { __typename?: 'FileMetadata'; id: string } | null;
          } | null>;
        };
        linkedObject:
          | { __typename: 'FinancialsReviewItem'; id: string }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              item: {
                __typename?: 'MeasurementVerificationItem';
                id: string;
                datasetName: string;
              };
            }
          | {
              __typename: 'UserUploadTask';
              id: string;
              assignee: {
                __typename?: 'User';
                id: string;
                name: string;
                permissions: Array<{
                  __typename?: 'PermissionItem';
                  id: string;
                  objectId: string | null;
                  permission: GQPermissionType;
                  revokedAt: Date | null;
                  object:
                    | { __typename: 'Company'; id: string; name: string }
                    | { __typename: 'CompanySurvey'; id: string; name: string }
                    | { __typename: 'Dataset'; id: string; name: string }
                    | { __typename: 'Datasource'; id: string; name: string }
                    | { __typename: 'EngagementTask'; id: string; name: string }
                    | { __typename: 'Fund'; id: string; name: string }
                    | null;
                }>;
                roles: Array<{
                  __typename?: 'UserRoleAssignment';
                  id: string;
                  revokedAt: Date | null;
                  role: {
                    __typename?: 'Role';
                    id: string;
                    name: string;
                    description: string | null;
                    permissions: Array<{
                      __typename?: 'PermissionItem';
                      id: string;
                      objectId: string | null;
                      permission: GQPermissionType;
                      revokedAt: Date | null;
                      object:
                        | { __typename: 'Company'; id: string; name: string }
                        | {
                            __typename: 'CompanySurvey';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Dataset'; id: string; name: string }
                        | { __typename: 'Datasource'; id: string; name: string }
                        | {
                            __typename: 'EngagementTask';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Fund'; id: string; name: string }
                        | null;
                    }>;
                  };
                }>;
              } | null;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: { __typename?: 'Dataset'; id: string; name: string };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | null;
        ancestorRelations: Array<
          | {
              __typename: 'ActivityDataTable';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'DataIssue';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Dataset';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Datasource';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FileMetadata';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FootprintQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementProject';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
        >;
      }>;
    }>;
  };
};

export type GQEmailContentsFieldsFragment = {
  __typename?: 'EmailContents';
  subject: string;
  bodyHtml: string;
  recipients: Array<{ __typename?: 'EmailUser'; email: string; name: string }>;
  ccs: Array<{ __typename?: 'EmailUser'; email: string; name: string }> | null;
  sender: { __typename?: 'EmailUser'; email: string; name: string };
  bccs: Array<{
    __typename?: 'EmailUser';
    email: string;
    name: string;
  } | null> | null;
};

export type GQEmailContentWithUserRecipientFieldsFragment = {
  __typename?: 'EmailContentsWithUserRecipient';
  userId: string;
  emailContents: {
    __typename?: 'EmailContents';
    subject: string;
    bodyHtml: string;
    recipients: Array<{
      __typename?: 'EmailUser';
      email: string;
      name: string;
    }>;
    ccs: Array<{
      __typename?: 'EmailUser';
      email: string;
      name: string;
    }> | null;
    sender: { __typename?: 'EmailUser'; email: string; name: string };
    bccs: Array<{
      __typename?: 'EmailUser';
      email: string;
      name: string;
    } | null> | null;
  };
};

export type GQDeleteDataIssueMutationVariables = Exact<{
  input: GQDeleteDataIssueInput;
  orgId: Scalars['ID']['input'];
}>;

export type GQDeleteDataIssueMutation = {
  __typename?: 'Mutation';
  deleteDataIssue: {
    __typename?: 'DeleteDataIssuePayload';
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      issues: {
        __typename?: 'DataIssueConnection';
        edges: Array<{
          __typename?: 'DataIssueEdge';
          node: { __typename?: 'DataIssue'; id: string } | null;
        } | null>;
      } | null;
    } | null;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      coverageInterval: YMInterval;
      active: boolean;
      dataIssues: Array<{
        __typename: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        isPublished: boolean;
        isCustomerInitiated: boolean;
        createdAt: Date;
        tags: Array<GQDataIssueTag> | null;
        name: string;
        adminUrl: string;
        assignee: {
          __typename?: 'User';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
          roles: Array<{
            __typename?: 'UserRoleAssignment';
            id: string;
            revokedAt: Date | null;
            role: {
              __typename?: 'Role';
              id: string;
              name: string;
              description: string | null;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
            };
          }>;
        } | null;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
        files: {
          __typename?: 'FileMetadataConnection';
          edges: Array<{
            __typename?: 'FileMetadataEdge';
            node: { __typename?: 'FileMetadata'; id: string } | null;
          } | null>;
        };
        linkedObject:
          | { __typename: 'FinancialsReviewItem'; id: string }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              item: {
                __typename?: 'MeasurementVerificationItem';
                id: string;
                datasetName: string;
              };
            }
          | {
              __typename: 'UserUploadTask';
              id: string;
              assignee: {
                __typename?: 'User';
                id: string;
                name: string;
                permissions: Array<{
                  __typename?: 'PermissionItem';
                  id: string;
                  objectId: string | null;
                  permission: GQPermissionType;
                  revokedAt: Date | null;
                  object:
                    | { __typename: 'Company'; id: string; name: string }
                    | { __typename: 'CompanySurvey'; id: string; name: string }
                    | { __typename: 'Dataset'; id: string; name: string }
                    | { __typename: 'Datasource'; id: string; name: string }
                    | { __typename: 'EngagementTask'; id: string; name: string }
                    | { __typename: 'Fund'; id: string; name: string }
                    | null;
                }>;
                roles: Array<{
                  __typename?: 'UserRoleAssignment';
                  id: string;
                  revokedAt: Date | null;
                  role: {
                    __typename?: 'Role';
                    id: string;
                    name: string;
                    description: string | null;
                    permissions: Array<{
                      __typename?: 'PermissionItem';
                      id: string;
                      objectId: string | null;
                      permission: GQPermissionType;
                      revokedAt: Date | null;
                      object:
                        | { __typename: 'Company'; id: string; name: string }
                        | {
                            __typename: 'CompanySurvey';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Dataset'; id: string; name: string }
                        | { __typename: 'Datasource'; id: string; name: string }
                        | {
                            __typename: 'EngagementTask';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Fund'; id: string; name: string }
                        | null;
                    }>;
                  };
                }>;
              } | null;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: { __typename?: 'Dataset'; id: string; name: string };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | null;
        ancestorRelations: Array<
          | {
              __typename: 'ActivityDataTable';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'DataIssue';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Dataset';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Datasource';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FileMetadata';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FootprintQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementProject';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
        >;
      }>;
    };
  } | null;
};

export type GQUpdateDataIssuesMutationVariables = Exact<{
  input: Array<GQUpdateDataIssueInput> | GQUpdateDataIssueInput;
  orgId: Scalars['ID']['input'];
}>;

export type GQUpdateDataIssuesMutation = {
  __typename?: 'Mutation';
  updateDataIssues: {
    __typename?: 'UpdateDataIssuesPayload';
    payload: Array<{
      __typename?: 'UpdateDataIssuePayload';
      issue: {
        __typename: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        isPublished: boolean;
        isCustomerInitiated: boolean;
        createdAt: Date;
        tags: Array<GQDataIssueTag> | null;
        name: string;
        adminUrl: string;
        assignee: {
          __typename?: 'User';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
          roles: Array<{
            __typename?: 'UserRoleAssignment';
            id: string;
            revokedAt: Date | null;
            role: {
              __typename?: 'Role';
              id: string;
              name: string;
              description: string | null;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
            };
          }>;
        } | null;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
        files: {
          __typename?: 'FileMetadataConnection';
          edges: Array<{
            __typename?: 'FileMetadataEdge';
            node: { __typename?: 'FileMetadata'; id: string } | null;
          } | null>;
        };
        linkedObject:
          | { __typename: 'FinancialsReviewItem'; id: string }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              item: {
                __typename?: 'MeasurementVerificationItem';
                id: string;
                datasetName: string;
              };
            }
          | {
              __typename: 'UserUploadTask';
              id: string;
              assignee: {
                __typename?: 'User';
                id: string;
                name: string;
                permissions: Array<{
                  __typename?: 'PermissionItem';
                  id: string;
                  objectId: string | null;
                  permission: GQPermissionType;
                  revokedAt: Date | null;
                  object:
                    | { __typename: 'Company'; id: string; name: string }
                    | { __typename: 'CompanySurvey'; id: string; name: string }
                    | { __typename: 'Dataset'; id: string; name: string }
                    | { __typename: 'Datasource'; id: string; name: string }
                    | { __typename: 'EngagementTask'; id: string; name: string }
                    | { __typename: 'Fund'; id: string; name: string }
                    | null;
                }>;
                roles: Array<{
                  __typename?: 'UserRoleAssignment';
                  id: string;
                  revokedAt: Date | null;
                  role: {
                    __typename?: 'Role';
                    id: string;
                    name: string;
                    description: string | null;
                    permissions: Array<{
                      __typename?: 'PermissionItem';
                      id: string;
                      objectId: string | null;
                      permission: GQPermissionType;
                      revokedAt: Date | null;
                      object:
                        | { __typename: 'Company'; id: string; name: string }
                        | {
                            __typename: 'CompanySurvey';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Dataset'; id: string; name: string }
                        | { __typename: 'Datasource'; id: string; name: string }
                        | {
                            __typename: 'EngagementTask';
                            id: string;
                            name: string;
                          }
                        | { __typename: 'Fund'; id: string; name: string }
                        | null;
                    }>;
                  };
                }>;
              } | null;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: { __typename?: 'Dataset'; id: string; name: string };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | null;
        ancestorRelations: Array<
          | {
              __typename: 'ActivityDataTable';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'DataIssue';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Dataset';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Datasource';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FileMetadata';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FootprintQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementProject';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
        >;
      };
    } | null> | null;
  } | null;
};

export type GQMappingAssumptionFragment = {
  __typename?: 'AssumptionRecordForAdmin';
  id: string;
  assumptionId: string;
  inputEmissionsSpecifier: string | null;
  inputEmissionsSubspecifier: string | null;
  outputEmissionsSpecifier: string | null;
  outputEmissionsSubspecifier: string | null;
  vendorEntity: string | null;
  startMonth: YearMonth | null;
  endMonth: YearMonth | null;
  excludeFromResults: boolean | null;
  ghgCategoryId: string | null;
  categoryId: string | null;
  subcategoryId: string | null;
  description: string | null;
  authorExplanation: string | null;
};

type GQMeasurementMappingFieldsFragment_LegacyAssumptionAsMeasurementMapping_Fragment =
  {
    __typename?: 'LegacyAssumptionAsMeasurementMapping';
    authorExplanation: string | null;
    inputEmissionsSpecifier: string;
    inputEmissionsSubspecifier: string;
    outputEmissionsSpecifier: string;
    outputEmissionsSubspecifier: string;
    vendorEntity: string;
    startMonth: YearMonth;
    endMonth: YearMonth;
    shouldInclude: boolean;
    ghgCategoryId: string | null;
    categoryId: string | null;
    subcategoryId: string | null;
    description: string | null;
    updatedAt: Date | null;
    author: { __typename?: 'WatershedEmployee'; id: string; name: string };
  };

type GQMeasurementMappingFieldsFragment_MeasurementMapping_Fragment = {
  __typename?: 'MeasurementMapping';
  authorExplanation: string | null;
  inputEmissionsSpecifier: string;
  inputEmissionsSubspecifier: string;
  outputEmissionsSpecifier: string;
  outputEmissionsSubspecifier: string;
  vendorEntity: string;
  startMonth: YearMonth;
  endMonth: YearMonth;
  shouldInclude: boolean;
  ghgCategoryId: string | null;
  categoryId: string | null;
  subcategoryId: string | null;
  description: string | null;
  updatedAt: Date | null;
  author: { __typename?: 'WatershedEmployee'; id: string; name: string };
};

export type GQMeasurementMappingFieldsFragmentFragment =
  | GQMeasurementMappingFieldsFragment_LegacyAssumptionAsMeasurementMapping_Fragment
  | GQMeasurementMappingFieldsFragment_MeasurementMapping_Fragment;

export type GQCadtRowsToMapFieldsFragment = {
  __typename?: 'RawCadtRowToMap';
  emissions_specifier: string;
  emissions_subspecifier: string;
  unnormalized_emissions_subspecifier: string;
  unit: string | null;
  month_earliest: YearMonth;
  month_latest: YearMonth;
  total_amount: number | null;
  description_from_cadt: string | null;
  adt_ids: Array<string>;
  top_vendors: Array<{
    __typename?: 'RawCadtRowToMapVendor';
    vendor_entity: string;
    amount: number;
  }>;
};

export type GQOrganizationMappingsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  startYearMonth: InputMaybe<Scalars['YearMonth']['input']>;
  endYearMonth: InputMaybe<Scalars['YearMonth']['input']>;
}>;

export type GQOrganizationMappingsQuery = {
  __typename?: 'Query';
  globalMappingsUrl: string;
  defaultMappingsUrl: string;
  legacyMappingsCodesUrl: string;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    demoOrg: boolean;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      coverageInterval: YMInterval;
      completedAt: Date | null;
      supportedValueMappingRules: Array<string>;
    }>;
  };
  organizations: {
    __typename?: 'OrgConnection';
    edges: Array<{
      __typename?: 'OrgEdge';
      node: {
        __typename?: 'Organization';
        id: string;
        name: string;
        demoOrg: boolean;
        testOrg: boolean;
      };
    } | null>;
  };
  measurementMappingOutputOptions: {
    __typename?: 'MeasurementMappingOutputOptionConnection';
    edges: Array<{
      __typename?: 'MeasurementMappingOutputOptionEdge';
      node: {
        __typename?: 'MeasurementMappingOutputOption';
        id: string;
        inputEmissionsSpecifier: string;
        outputEmissionsSpecifier: string;
        units: string;
        description: string;
        efKgCo2eDescription: string;
        efKgCo2eAverage: number | null;
        updatedAt: Date | null;
      } | null;
    } | null>;
  };
  cadtRowsToMapForOrg: {
    __typename?: 'CadtRowsToMapForOrgOutput';
    cadtRowsToMap: {
      __typename?: 'RawCadtRowToMapConnection';
      edges: Array<{
        __typename?: 'RawCadtRowToMapEdge';
        node: {
          __typename?: 'RawCadtRowToMap';
          emissions_specifier: string;
          emissions_subspecifier: string;
          unnormalized_emissions_subspecifier: string;
          unit: string | null;
          month_earliest: YearMonth;
          month_latest: YearMonth;
          total_amount: number | null;
          description_from_cadt: string | null;
          adt_ids: Array<string>;
          top_vendors: Array<{
            __typename?: 'RawCadtRowToMapVendor';
            vendor_entity: string;
            amount: number;
          }>;
        } | null;
      } | null>;
    };
  };
  measurementMappings: {
    __typename?: 'MeasurementMappingConnection';
    edges: Array<{
      __typename?: 'MeasurementMappingEdge';
      node: {
        __typename?: 'MeasurementMapping';
        id: string;
        authorExplanation: string | null;
        inputEmissionsSpecifier: string;
        inputEmissionsSubspecifier: string;
        outputEmissionsSpecifier: string;
        outputEmissionsSubspecifier: string;
        vendorEntity: string;
        startMonth: YearMonth;
        endMonth: YearMonth;
        shouldInclude: boolean;
        ghgCategoryId: string | null;
        categoryId: string | null;
        subcategoryId: string | null;
        description: string | null;
        updatedAt: Date | null;
        author: { __typename?: 'WatershedEmployee'; id: string; name: string };
      } | null;
    } | null>;
  };
  legacyAssumptionAsMeasurementMappings: {
    __typename?: 'LegacyAssumptionAsMeasurementMappingConnection';
    edges: Array<{
      __typename?: 'LegacyAssumptionAsMeasurementMappingEdge';
      node: {
        __typename?: 'LegacyAssumptionAsMeasurementMapping';
        id: string;
        authorExplanation: string | null;
        inputEmissionsSpecifier: string;
        inputEmissionsSubspecifier: string;
        outputEmissionsSpecifier: string;
        outputEmissionsSubspecifier: string;
        vendorEntity: string;
        startMonth: YearMonth;
        endMonth: YearMonth;
        shouldInclude: boolean;
        ghgCategoryId: string | null;
        categoryId: string | null;
        subcategoryId: string | null;
        description: string | null;
        updatedAt: Date | null;
        author: { __typename?: 'WatershedEmployee'; id: string; name: string };
      } | null;
    } | null>;
  };
  mappingCategoryIds: {
    __typename?: 'MappingCategoryIds';
    ghgCategoryId: Array<{
      __typename?: 'CategoryInfo';
      id: string;
      name: string;
      description: string | null;
    }>;
    categoryId: Array<{
      __typename?: 'CategoryInfo';
      id: string;
      name: string;
      description: string | null;
    }>;
    subcategoryId: Array<{
      __typename?: 'CategoryInfo';
      id: string;
      name: string;
      description: string | null;
    }>;
  };
  activityDataTables: {
    __typename?: 'ActivityDataTableConnection';
    edges: Array<{
      __typename?: 'ActivityDataTableEdge';
      node: {
        __typename?: 'ActivityDataTable';
        id: string;
        name: string;
      } | null;
    } | null>;
  };
};

export type GQOrganizationFinancialsReviewItemsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrganizationFinancialsReviewItemsQuery = {
  __typename?: 'Query';
  financialsReviewItemsAdmin: {
    __typename?: 'FinancialsReviewItemConnection';
    edges: Array<{
      __typename?: 'FinancialsReviewItemEdge';
      node: {
        __typename: 'FinancialsReviewItem';
        id: string;
        createdAt: Date;
        status: GQFinancialsReviewItemStatus;
        initiallyIncluded: boolean | null;
        exclusionReason: string | null;
        prompt: string | null;
        userResponse: string | null;
        name: string;
        adminUrl: string;
        account: {
          __typename?: 'FinancialsAccount';
          accountId: string;
          description: string | null;
        };
        initialIndustryClassification: {
          __typename?: 'IndustryClassification';
          id: string;
          shortDescription: string;
          longDescription: string;
        } | null;
        respondingUser: {
          __typename?: 'User';
          id: string;
          name: string;
        } | null;
        discussion: {
          __typename?: 'DataIssue';
          id: string;
          state: GQDataIssueState;
        } | null;
        group: {
          __typename?: 'FinancialsReviewGroup';
          id: string;
          measurementProject: { __typename?: 'MeasurementProject'; id: string };
        };
        ancestorRelations: Array<
          | {
              __typename: 'ActivityDataTable';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'DataIssue';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Dataset';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'Datasource';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FileMetadata';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'FootprintQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementProject';
              id: string;
              name: string;
              adminUrl: string;
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              name: string;
              adminUrl: string;
            }
        >;
      } | null;
    } | null>;
  };
};

export type GQSyncMeasurementMappingsMutationVariables = Exact<{
  input: GQSyncMeasurementMappingsInput;
}>;

export type GQSyncMeasurementMappingsMutation = {
  __typename?: 'Mutation';
  syncMeasurementMappings: {
    __typename?: 'SyncMeasurementMappingsPayload';
    jobId: string | null;
    mappings: Array<{
      __typename?: 'MeasurementMapping';
      id: string;
      authorExplanation: string | null;
      inputEmissionsSpecifier: string;
      inputEmissionsSubspecifier: string;
      outputEmissionsSpecifier: string;
      outputEmissionsSubspecifier: string;
      vendorEntity: string;
      startMonth: YearMonth;
      endMonth: YearMonth;
      shouldInclude: boolean;
      ghgCategoryId: string | null;
      categoryId: string | null;
      subcategoryId: string | null;
      description: string | null;
      updatedAt: Date | null;
      author: { __typename?: 'WatershedEmployee'; id: string; name: string };
    }>;
  } | null;
};

export type GQAllSimpleTimeseriesFieldsFragment = {
  __typename?: 'SimpleTimeseries';
  id: string | null;
  base: Date;
  frequency: GQTimeseriesFrequency;
  values: Array<number>;
};

export type GQPlanTargetForOrgPlanCategoriesPageFragment = {
  __typename?: 'PlanTarget';
  id: string;
  description: string;
  reportDescription: string;
  filters: {
    __typename?: 'FilterExpressionGroup';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitive';
      field: GQFilterFieldLegacy;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  };
};

export type GQOrganizationPlanCategoriesQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
}>;

export type GQOrganizationPlanCategoriesQuery = {
  __typename?: 'Query';
  organization: { __typename?: 'Organization'; id: string; name: string };
  plan: { __typename?: 'Plan'; id: string; name: string };
};

export type GQForecastForPlanPageFragment = {
  __typename?: 'Forecast';
  id: string;
  interval: YMInterval;
  referencePeriodInterval: YMInterval;
  footprintSnapshotId: string;
  validReferenceInterval: YMInterval;
  overrideFootprintKind: string | null;
};

export type GQPlanForPlanPageFragment = {
  __typename?: 'Plan';
  id: string;
  name: string;
  createdAt: Date;
  deletedAt: Date | null;
  updatedAt: Date;
  updatedByName: string | null;
  state: GQPlanState;
  baselineYearStart: YearMonth;
  isHidden: boolean;
  isSbtValidated: boolean;
  forecast: {
    __typename?: 'Forecast';
    id: string;
    interval: YMInterval;
    referencePeriodInterval: YMInterval;
    footprintSnapshotId: string;
    validReferenceInterval: YMInterval;
    overrideFootprintKind: string | null;
  };
  variables: {
    __typename?: 'PlanVariables';
    commitmentsSBTTermLength: GQSbtTermLength;
  };
};

export type GQOrganizationPlansQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQOrganizationPlansQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    fiscalYearStartMonth: number | null;
  };
  plansForOrg: Array<{
    __typename?: 'Plan';
    id: string;
    name: string;
    createdAt: Date;
    deletedAt: Date | null;
    updatedAt: Date;
    updatedByName: string | null;
    state: GQPlanState;
    baselineYearStart: YearMonth;
    isHidden: boolean;
    isSbtValidated: boolean;
    forecast: {
      __typename?: 'Forecast';
      id: string;
      interval: YMInterval;
      referencePeriodInterval: YMInterval;
      footprintSnapshotId: string;
      validReferenceInterval: YMInterval;
      overrideFootprintKind: string | null;
    };
    variables: {
      __typename?: 'PlanVariables';
      commitmentsSBTTermLength: GQSbtTermLength;
    };
  }>;
  footprintAnalysisAdmin: {
    __typename?: 'FootprintAnalysisAdmin';
    id: string | null;
    footprintInterval: YMInterval | null;
    footprintKinds: Array<string>;
  } | null;
  forecastAdmin: {
    __typename?: 'Forecast';
    id: string;
    interval: YMInterval;
    referencePeriodInterval: YMInterval;
    footprintSnapshotId: string;
    validReferenceInterval: YMInterval;
    overrideFootprintKind: string | null;
  } | null;
  latestUserVisiblePublishedFootprintVersion: {
    __typename?: 'FootprintVersion';
    id: string;
    originalFootprintSnapshotId: string;
  } | null;
};

export type GQDeletePlanAdminMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQDeletePlanAdminMutation = {
  __typename?: 'Mutation';
  deletePlanAdmin: {
    __typename?: 'DeletePlanAdminPayload';
    plans: Array<{
      __typename?: 'Plan';
      id: string;
      name: string;
      createdAt: Date;
      deletedAt: Date | null;
      updatedAt: Date;
      updatedByName: string | null;
      state: GQPlanState;
      baselineYearStart: YearMonth;
      isHidden: boolean;
      isSbtValidated: boolean;
      forecast: {
        __typename?: 'Forecast';
        id: string;
        interval: YMInterval;
        referencePeriodInterval: YMInterval;
        footprintSnapshotId: string;
        validReferenceInterval: YMInterval;
        overrideFootprintKind: string | null;
      };
      variables: {
        __typename?: 'PlanVariables';
        commitmentsSBTTermLength: GQSbtTermLength;
      };
    }>;
  };
};

export type GQDuplicatePlanAdminMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQDuplicatePlanAdminMutation = {
  __typename?: 'Mutation';
  duplicatePlanAdmin: {
    __typename?: 'Plan';
    id: string;
    name: string;
    createdAt: Date;
    deletedAt: Date | null;
    updatedAt: Date;
    updatedByName: string | null;
    state: GQPlanState;
    baselineYearStart: YearMonth;
    isHidden: boolean;
    isSbtValidated: boolean;
    forecast: {
      __typename?: 'Forecast';
      id: string;
      interval: YMInterval;
      referencePeriodInterval: YMInterval;
      footprintSnapshotId: string;
      validReferenceInterval: YMInterval;
      overrideFootprintKind: string | null;
    };
    variables: {
      __typename?: 'PlanVariables';
      commitmentsSBTTermLength: GQSbtTermLength;
    };
  };
};

export type GQDeleteAllReductionsDataMutationVariables = Exact<{
  input: GQDeleteAllReductionsDataInput;
}>;

export type GQDeleteAllReductionsDataMutation = {
  __typename?: 'Mutation';
  deleteAllReductionsData: {
    __typename?: 'DeleteAllReductionsDataPayload';
    message: string;
  };
};

export type GQUpdateForecastFootprintSnapshotIdToLatestMutationVariables =
  Exact<{
    input: GQUpdateForecastFootprintSnapshotIdToLatestInput;
  }>;

export type GQUpdateForecastFootprintSnapshotIdToLatestMutation = {
  __typename?: 'Mutation';
  updateForecastFootprintSnapshotIdToLatest: {
    __typename?: 'UpdateForecastFootprintSnapshotIdToLatestPayload';
    forecasts: Array<{
      __typename?: 'Forecast';
      id: string;
      name: string;
      validReferenceInterval: YMInterval;
      footprintSnapshotId: string;
    }>;
  };
};

export type GQOrganizationReportsPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQOrganizationReportsPageQuery = {
  __typename?: 'Query';
  organization: { __typename?: 'Organization'; id: string; name: string };
  latestUserVisiblePublishedFootprintVersion: {
    __typename?: 'FootprintVersion';
    id: string;
    originalFootprintSnapshotId: string;
  } | null;
  footprintVersions: {
    __typename?: 'FootprintVersionConnection';
    edges: Array<{
      __typename?: 'FootprintVersionEdge';
      node: {
        __typename?: 'FootprintVersion';
        id: string;
        kind: GQFootprintVersionKind;
        originalFootprintSnapshotId: string;
        createdAt: Date;
      } | null;
    } | null>;
  };
  formalReportsAdmin: {
    __typename?: 'ReportConnection';
    edges: Array<{
      __typename?: 'ReportEdge';
      node: {
        __typename?: 'Report';
        id: string;
        name: string;
        isHidden: boolean | null;
        reportKind: string;
        createdAt: Date;
        deletedAt: Date | null;
        footprintSnapshot: {
          __typename?: 'FootprintSnapshot';
          id: string;
        } | null;
        config: { __typename?: 'ReportConfig'; id: string; reportType: string };
      } | null;
    } | null>;
  };
  footprintCustomReports: Array<{
    __typename?: 'FootprintCustomReport';
    id: string;
    name: string;
    description: string;
    downloadUrl: string;
    published: boolean;
    footprintSnapshotId: string | null;
    lastRefreshedAt: Date;
    archivedAt: Date | null;
  }>;
  footprintAnalysisAdmin: {
    __typename?: 'FootprintAnalysisAdmin';
    id: string | null;
    footprintInterval: YMInterval | null;
  } | null;
};

export type GQUpdateReportMutationVariables = Exact<{
  input: GQUpdateReportInput;
}>;

export type GQUpdateReportMutation = {
  __typename?: 'Mutation';
  updateReport: {
    __typename?: 'UpdateReportPayload';
    report: {
      __typename?: 'Report';
      id: string;
      name: string;
      isHidden: boolean | null;
      reportKind: string;
      createdAt: Date;
      deletedAt: Date | null;
      footprintSnapshot: {
        __typename?: 'FootprintSnapshot';
        id: string;
      } | null;
      config: { __typename?: 'ReportConfig'; id: string; reportType: string };
    };
  } | null;
};

export type GQArchiveReportMutationVariables = Exact<{
  reportId: Scalars['ID']['input'];
}>;

export type GQArchiveReportMutation = {
  __typename?: 'Mutation';
  archiveReport: {
    __typename?: 'ArchiveReportPayload';
    report: { __typename?: 'Report'; id: string; deletedAt: Date | null };
  } | null;
};

export type GQRestoreReportMutationVariables = Exact<{
  reportId: Scalars['ID']['input'];
}>;

export type GQRestoreReportMutation = {
  __typename?: 'Mutation';
  restoreReport: {
    __typename?: 'RestoreReportAdminPayload';
    report: {
      __typename?: 'Report';
      id: string;
      name: string;
      isHidden: boolean | null;
      reportKind: string;
      createdAt: Date;
      deletedAt: Date | null;
      footprintSnapshot: {
        __typename?: 'FootprintSnapshot';
        id: string;
      } | null;
      config: { __typename?: 'ReportConfig'; id: string; reportType: string };
    };
  } | null;
};

export type GQArchiveFootprintCustomReportMutationVariables = Exact<{
  input: GQArchiveFootprintCustomReportInput;
}>;

export type GQArchiveFootprintCustomReportMutation = {
  __typename?: 'Mutation';
  archiveFootprintCustomReport: {
    __typename?: 'ArchiveFootprintCustomReportPayload';
    footprintCustomReport: {
      __typename?: 'FootprintCustomReport';
      id: string;
      archivedAt: Date | null;
    };
  } | null;
};

export type GQPublishFootprintCustomReportMutationVariables = Exact<{
  input: GQPublishFootprintCustomReportInput;
}>;

export type GQPublishFootprintCustomReportMutation = {
  __typename?: 'Mutation';
  publishFootprintCustomReport: {
    __typename?: 'PublishFootprintCustomReportPayload';
    footprintCustomReport: {
      __typename?: 'FootprintCustomReport';
      id: string;
      name: string;
      description: string;
      downloadUrl: string;
      published: boolean;
      footprintSnapshotId: string | null;
    };
  } | null;
};

export type GQEngagementTaskFieldsAdminFragment = {
  __typename?: 'EngagementTask';
  id: string;
  name: string;
  crossOrgModelId: string;
  status: GQCompanySurveyStatus;
  deletedAt: Date | null;
  company: { __typename?: 'Company'; id: string };
  engagementTaskConfig: {
    __typename?: 'EngagementTaskConfig';
    id: string;
    name: string;
  };
};

export type GQSuppliersSettingsFieldsAdminFragment = {
  __typename?: 'SuppliersSettings';
  id: string;
  includedGhgCategories: Array<string>;
  viewLatestSupplierMapping: boolean;
  suppressNotifications: boolean;
};

export type GQGetSupplyChainDataQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetSupplyChainDataQuery = {
  __typename?: 'Query';
  engagementTasksAdmin: {
    __typename?: 'EngagementTaskConnection';
    edges: Array<{
      __typename?: 'EngagementTaskEdge';
      node: {
        __typename?: 'EngagementTask';
        id: string;
        name: string;
        crossOrgModelId: string;
        status: GQCompanySurveyStatus;
        deletedAt: Date | null;
        company: { __typename?: 'Company'; id: string };
        engagementTaskConfig: {
          __typename?: 'EngagementTaskConfig';
          id: string;
          name: string;
        };
      } | null;
    }>;
  };
  suppliersSettingsAdmin: {
    __typename?: 'SuppliersSettings';
    id: string;
    includedGhgCategories: Array<string>;
    viewLatestSupplierMapping: boolean;
    suppressNotifications: boolean;
  };
  organization: { __typename?: 'Organization'; id: string; name: string };
};

export type GQUpdateSuppliersSettingsAdminMutationVariables = Exact<{
  input: GQUpdateSuppliersSettingsAdminInput;
}>;

export type GQUpdateSuppliersSettingsAdminMutation = {
  __typename?: 'Mutation';
  updateSuppliersSettingsAdmin: {
    __typename?: 'UpdateSuppliersSettingsPayload';
    suppliersSettings: {
      __typename?: 'SuppliersSettings';
      id: string;
      includedGhgCategories: Array<string>;
      viewLatestSupplierMapping: boolean;
      suppressNotifications: boolean;
    };
  } | null;
};

export type GQUserUploadTaskPageQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  userUploadTaskId: Scalars['ID']['input'];
}>;

export type GQUserUploadTaskPageQuery = {
  __typename?: 'Query';
  organization: { __typename?: 'Organization'; id: string; name: string };
  userUploadTask: {
    __typename?: 'UserUploadTask';
    id: string;
    state: GQUserUploadTaskState;
    datasource: {
      __typename: 'Datasource';
      id: string;
      name: string;
      adminUrl: string;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        explainer: string | null;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          name: string;
        } | null;
      };
      ancestorRelations: Array<
        | {
            __typename: 'ActivityDataTable';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'DataIssue';
            id: string;
            name: string;
            adminUrl: string;
          }
        | { __typename: 'Dataset'; id: string; name: string; adminUrl: string }
        | {
            __typename: 'Datasource';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FileMetadata';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'FootprintQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementProject';
            id: string;
            name: string;
            adminUrl: string;
          }
        | {
            __typename: 'MeasurementVerificationItemQuestion';
            id: string;
            name: string;
            adminUrl: string;
          }
      >;
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      coverageInterval: YMInterval;
    };
    userUploads: {
      __typename?: 'UserUploadConnection';
      edges: Array<{
        __typename?: 'UserUploadEdge';
        node: {
          __typename?: 'UserUpload';
          id: string;
          name: string;
          deletedAt: Date | null;
          allAttempts: Array<{
            __typename?: 'UserUploadAttempt';
            id: string;
            createdAt: Date;
            size: number;
            gcloudStorageUrl: string;
            deletedAt: Date | null;
            uploader: { __typename?: 'User'; id: string; name: string } | null;
            userUploadedTables: Array<{
              __typename?: 'UserUploadedTable';
              id: string;
              sqlTableName: string | null;
              shouldIgnore: boolean;
            }>;
          }>;
          latestAttempt: {
            __typename?: 'UserUploadAttempt';
            id: string;
            name: string;
            gcloudStorageUrl: string;
            userUploadedTables: Array<{
              __typename?: 'UserUploadedTable';
              id: string;
              shouldIgnore: boolean;
            }>;
          };
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GQUserFieldsForOrgPageFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  displayName: string;
  email: string;
  loginActivated: boolean;
  signupLink: string | null;
  orgAccessId: string;
  orgAccessCreatedAt: Date;
  consecutiveLoginFailures: number;
  didLatestEmailBounce: boolean;
  preferredLocale: string | null;
  userUploadTasks: Array<{
    __typename?: 'UserUploadTask';
    id: string;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
    };
    datasource: {
      __typename?: 'Datasource';
      id: string;
      name: string;
      dataset: { __typename?: 'Dataset'; id: string; name: string };
    };
  }>;
  roles: Array<{
    __typename?: 'UserRoleAssignment';
    id: string;
    revokedAt: Date | null;
    role: {
      __typename?: 'Role';
      id: string;
      name: string;
      description: string | null;
      permissions: Array<{
        __typename?: 'PermissionItem';
        id: string;
        objectId: string | null;
        permission: GQPermissionType;
        revokedAt: Date | null;
        object:
          | { __typename: 'Company'; id: string; name: string }
          | { __typename: 'CompanySurvey'; id: string; name: string }
          | { __typename: 'Dataset'; id: string; name: string }
          | { __typename: 'Datasource'; id: string; name: string }
          | { __typename: 'EngagementTask'; id: string; name: string }
          | { __typename: 'Fund'; id: string; name: string }
          | null;
      }>;
    };
  }>;
  permissions: Array<{
    __typename?: 'PermissionItem';
    id: string;
    objectId: string | null;
    permission: GQPermissionType;
    revokedAt: Date | null;
    object:
      | { __typename: 'Company'; id: string; name: string }
      | { __typename: 'CompanySurvey'; id: string; name: string }
      | { __typename: 'Dataset'; id: string; name: string }
      | { __typename: 'Datasource'; id: string; name: string }
      | { __typename: 'EngagementTask'; id: string; name: string }
      | { __typename: 'Fund'; id: string; name: string }
      | null;
  }>;
};

export type GQOrganizationUsersQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrganizationUsersQuery = {
  __typename?: 'Query';
  orgFunds: {
    __typename?: 'FundConnection';
    edges: Array<{
      __typename?: 'FundEdge';
      node: { __typename?: 'Fund'; id: string; name: string } | null;
    } | null>;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    domains: Array<string>;
    watershedPlanLegacy: GQWatershedPlanLegacy;
    canAccessCorporate: boolean | null;
    canAccessFinance: boolean | null;
    passwordAuthDisabled: boolean;
    roles: {
      __typename?: 'RoleConnection';
      edges: Array<{
        __typename?: 'RoleEdge';
        node: {
          __typename?: 'Role';
          id: string;
          name: string;
          description: string | null;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
        } | null;
      } | null>;
    } | null;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      datasources: Array<{
        __typename?: 'Datasource';
        name: string;
        id: string;
      }>;
    }>;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          email: string;
          loginActivated: boolean;
          signupLink: string | null;
          orgAccessId: string;
          orgAccessCreatedAt: Date;
          consecutiveLoginFailures: number;
          didLatestEmailBounce: boolean;
          preferredLocale: string | null;
          userUploadTasks: Array<{
            __typename?: 'UserUploadTask';
            id: string;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
              active: boolean;
            };
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: { __typename?: 'Dataset'; id: string; name: string };
            };
          }>;
          roles: Array<{
            __typename?: 'UserRoleAssignment';
            id: string;
            revokedAt: Date | null;
            role: {
              __typename?: 'Role';
              id: string;
              name: string;
              description: string | null;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
            };
          }>;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQGetSignedParquetUrlQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetSignedParquetUrlQuery = {
  __typename?: 'Query';
  signedParquetUrl: string;
};

export type GQReferenceDataRevisionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQReferenceDataRevisionQuery = {
  __typename?: 'Query';
  referenceDataRevision: {
    __typename?: 'ReferenceDataRevision';
    id: string;
    revisionName: string;
  };
};

export type GQCompositeDataSourceQueryVariables = Exact<{
  compositeDataSourceId: Scalars['ID']['input'];
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQCompositeDataSourceQuery = {
  __typename?: 'Query';
  referenceDataSource: {
    __typename?: 'ReferenceDataSource';
    id: string;
    name: string;
    orgId: string | null;
    externalSourceUrl: string | null;
    externalSourceNotes: string | null;
    kind: GQReferenceDataKind | null;
    isCompositeData: boolean;
    createdAt: Date;
    updatedAt: Date;
    archivedAt: Date | null;
    isSyncedWithCliq: boolean;
    referenceDataVersions: Array<{
      __typename?: 'ReferenceDataVersion';
      id: string;
      sourceId: string;
      versionName: string;
      versionVintage: Date;
      createdAt: Date;
      updatedAt: Date;
      archivedAt: Date | null;
      state: GQReferenceDataVersionState;
      hasPublishedRevision: boolean;
      canPublish: boolean;
      compositeDataOrgDetails: Array<{
        __typename?: 'CompositeDataOrgDetail';
        organization: {
          __typename?: 'Organization';
          id: string;
          name: string;
        } | null;
        latestPublishedRevision: {
          __typename?: 'ReferenceDataRevision';
          id: string;
          revisionName: string;
          updates: JSONSchema7;
          outputFileId: string;
          sourceFileId: string | null;
          internalSourceNotes: string | null;
          createdAt: Date;
          archivedAt: Date | null;
          signedParquetOutputUrl: string;
          signedSourceUrl: string | null;
          state: GQReferenceDataRevisionState;
          schemaJson: JSONSchema7 | null;
          kind: GQReferenceDataRevisionKind | null;
          schemaLastCheckedAgainst: string | null;
          lastCompatibleSchema: string | null;
          isLatestForOrgAndState: boolean;
          isOverlay: boolean | null;
          author: { __typename?: 'User'; id: string; name: string } | null;
          organization: {
            __typename?: 'Organization';
            id: string;
            name: string;
          } | null;
          assumptionSource: {
            __typename?: 'AssumptionSource';
            id: string;
          } | null;
        } | null;
      }> | null;
      author: { __typename?: 'User'; id: string; name: string } | null;
      latestSchema: {
        __typename?: 'ReferenceDataSchema';
        id: string;
        schemaJson: JSONSchema7;
      } | null;
    }>;
    author: { __typename?: 'User'; id: string; name: string } | null;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
    } | null;
    tags: Array<{
      __typename?: 'CalculationTag';
      id: string;
      name: string;
    }> | null;
    assumptionSources: Array<{
      __typename?: 'AssumptionSource';
      id: string;
      name: string;
    }> | null;
  };
};

export type GQCompositeDataOrgDetailSchemaFragment = {
  __typename?: 'CompositeDataOrgDetail';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
  } | null;
  latestPublishedRevision: {
    __typename?: 'ReferenceDataRevision';
    id: string;
    revisionName: string;
    updates: JSONSchema7;
    outputFileId: string;
    sourceFileId: string | null;
    internalSourceNotes: string | null;
    createdAt: Date;
    archivedAt: Date | null;
    signedParquetOutputUrl: string;
    signedSourceUrl: string | null;
    state: GQReferenceDataRevisionState;
    schemaJson: JSONSchema7 | null;
    kind: GQReferenceDataRevisionKind | null;
    schemaLastCheckedAgainst: string | null;
    lastCompatibleSchema: string | null;
    isLatestForOrgAndState: boolean;
    isOverlay: boolean | null;
    author: { __typename?: 'User'; id: string; name: string } | null;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
    } | null;
    assumptionSource: { __typename?: 'AssumptionSource'; id: string } | null;
  } | null;
};

export type GQReferenceDataSourceForCreateOverlayQueryVariables = Exact<{
  referenceDataSourceId: Scalars['ID']['input'];
}>;

export type GQReferenceDataSourceForCreateOverlayQuery = {
  __typename?: 'Query';
  referenceDataSource: {
    __typename?: 'ReferenceDataSource';
    id: string;
    name: string;
  };
};

export type GQReferenceDataOverlayPreviewQueryVariables = Exact<{
  overlayId: Scalars['ID']['input'];
}>;

export type GQReferenceDataOverlayPreviewQuery = {
  __typename?: 'Query';
  referenceDataOverlay: {
    __typename?: 'ReferenceDataOverlay';
    id: string;
    globalCdrId: string | null;
    signedMergedDataUrl: string;
    orgId: string;
    source: {
      __typename?: 'ReferenceDataSource';
      id: string;
      name: string;
    } | null;
  };
};

export type GQReferenceDataSourceQueryVariables = Exact<{
  referenceDataSourceId: Scalars['ID']['input'];
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
  filterOrgId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQReferenceDataSourceQuery = {
  __typename?: 'Query';
  referenceDataSource: {
    __typename?: 'ReferenceDataSource';
    id: string;
    name: string;
    orgId: string | null;
    externalSourceUrl: string | null;
    externalSourceNotes: string | null;
    kind: GQReferenceDataKind | null;
    isCompositeData: boolean;
    createdAt: Date;
    updatedAt: Date;
    archivedAt: Date | null;
    isSyncedWithCliq: boolean;
    orgsWithRevisions: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
    }> | null;
    referenceDataVersions: Array<{
      __typename?: 'ReferenceDataVersion';
      id: string;
      sourceId: string;
      versionName: string;
      versionVintage: Date;
      createdAt: Date;
      updatedAt: Date;
      archivedAt: Date | null;
      state: GQReferenceDataVersionState;
      hasPublishedRevision: boolean;
      canPublish: boolean;
      latestPublishedGlobalRevision: {
        __typename?: 'ReferenceDataRevision';
        id: string;
        revisionName: string;
        updates: JSONSchema7;
        outputFileId: string;
        sourceFileId: string | null;
        internalSourceNotes: string | null;
        createdAt: Date;
        archivedAt: Date | null;
        signedParquetOutputUrl: string;
        signedSourceUrl: string | null;
        state: GQReferenceDataRevisionState;
        schemaJson: JSONSchema7 | null;
        kind: GQReferenceDataRevisionKind | null;
        schemaLastCheckedAgainst: string | null;
        lastCompatibleSchema: string | null;
        isLatestForOrgAndState: boolean;
        isOverlay: boolean | null;
        author: { __typename?: 'User'; id: string; name: string } | null;
        organization: {
          __typename?: 'Organization';
          id: string;
          name: string;
        } | null;
        assumptionSource: {
          __typename?: 'AssumptionSource';
          id: string;
        } | null;
      } | null;
      latestPublishedRevision: {
        __typename?: 'ReferenceDataRevision';
        id: string;
        revisionName: string;
        updates: JSONSchema7;
        outputFileId: string;
        sourceFileId: string | null;
        internalSourceNotes: string | null;
        createdAt: Date;
        archivedAt: Date | null;
        signedParquetOutputUrl: string;
        signedSourceUrl: string | null;
        state: GQReferenceDataRevisionState;
        schemaJson: JSONSchema7 | null;
        kind: GQReferenceDataRevisionKind | null;
        schemaLastCheckedAgainst: string | null;
        lastCompatibleSchema: string | null;
        isLatestForOrgAndState: boolean;
        isOverlay: boolean | null;
        author: { __typename?: 'User'; id: string; name: string } | null;
        organization: {
          __typename?: 'Organization';
          id: string;
          name: string;
        } | null;
        assumptionSource: {
          __typename?: 'AssumptionSource';
          id: string;
        } | null;
      } | null;
      author: { __typename?: 'User'; id: string; name: string } | null;
      latestSchema: {
        __typename?: 'ReferenceDataSchema';
        id: string;
        schemaJson: JSONSchema7;
      } | null;
    }>;
    author: { __typename?: 'User'; id: string; name: string } | null;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
    } | null;
    tags: Array<{
      __typename?: 'CalculationTag';
      id: string;
      name: string;
    }> | null;
    assumptionSources: Array<{
      __typename?: 'AssumptionSource';
      id: string;
      name: string;
    }> | null;
  };
};

export type GQReferenceDataSourceSchemaFragment = {
  __typename?: 'ReferenceDataSource';
  id: string;
  name: string;
  orgId: string | null;
  externalSourceUrl: string | null;
  externalSourceNotes: string | null;
  kind: GQReferenceDataKind | null;
  isCompositeData: boolean;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date | null;
  isSyncedWithCliq: boolean;
  author: { __typename?: 'User'; id: string; name: string } | null;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
  } | null;
  tags: Array<{
    __typename?: 'CalculationTag';
    id: string;
    name: string;
  }> | null;
  assumptionSources: Array<{
    __typename?: 'AssumptionSource';
    id: string;
    name: string;
  }> | null;
};

export type GQReferenceDataSourceSchemaWithOutputUrlFragment = {
  __typename?: 'ReferenceDataSource';
  latestSignedParquetOutputUrl: string | null;
  id: string;
  name: string;
  orgId: string | null;
  externalSourceUrl: string | null;
  externalSourceNotes: string | null;
  kind: GQReferenceDataKind | null;
  isCompositeData: boolean;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date | null;
  isSyncedWithCliq: boolean;
  author: { __typename?: 'User'; id: string; name: string } | null;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
  } | null;
  tags: Array<{
    __typename?: 'CalculationTag';
    id: string;
    name: string;
  }> | null;
  assumptionSources: Array<{
    __typename?: 'AssumptionSource';
    id: string;
    name: string;
  }> | null;
};

export type GQReferenceDataVersionSchemaFragment = {
  __typename?: 'ReferenceDataVersion';
  id: string;
  sourceId: string;
  versionName: string;
  versionVintage: Date;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date | null;
  state: GQReferenceDataVersionState;
  hasPublishedRevision: boolean;
  canPublish: boolean;
  author: { __typename?: 'User'; id: string; name: string } | null;
  latestSchema: {
    __typename?: 'ReferenceDataSchema';
    id: string;
    schemaJson: JSONSchema7;
  } | null;
};

export type GQReferenceDataVersionSchemaWithOutputUrlFragment = {
  __typename?: 'ReferenceDataVersion';
  latestSignedParquetOutputUrl: string | null;
  id: string;
  sourceId: string;
  versionName: string;
  versionVintage: Date;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: Date | null;
  state: GQReferenceDataVersionState;
  hasPublishedRevision: boolean;
  canPublish: boolean;
  author: { __typename?: 'User'; id: string; name: string } | null;
  latestSchema: {
    __typename?: 'ReferenceDataSchema';
    id: string;
    schemaJson: JSONSchema7;
  } | null;
};

export type GQReferenceDataRevisionSchemaFragment = {
  __typename?: 'ReferenceDataRevision';
  id: string;
  revisionName: string;
  updates: JSONSchema7;
  outputFileId: string;
  sourceFileId: string | null;
  internalSourceNotes: string | null;
  createdAt: Date;
  archivedAt: Date | null;
  signedParquetOutputUrl: string;
  signedSourceUrl: string | null;
  state: GQReferenceDataRevisionState;
  schemaJson: JSONSchema7 | null;
  kind: GQReferenceDataRevisionKind | null;
  schemaLastCheckedAgainst: string | null;
  lastCompatibleSchema: string | null;
  isLatestForOrgAndState: boolean;
  isOverlay: boolean | null;
  author: { __typename?: 'User'; id: string; name: string } | null;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
  } | null;
  assumptionSource: { __typename?: 'AssumptionSource'; id: string } | null;
};

export type GQReferenceDataSourceAndVersionQueryVariables = Exact<{
  referenceDataSourceId: Scalars['ID']['input'];
  referenceDataVersionId: Scalars['ID']['input'];
}>;

export type GQReferenceDataSourceAndVersionQuery = {
  __typename?: 'Query';
  referenceDataSource: {
    __typename?: 'ReferenceDataSource';
    id: string;
    name: string;
    organization: { __typename?: 'Organization'; id: string } | null;
  };
  referenceDataVersion: {
    __typename?: 'ReferenceDataVersion';
    id: string;
    versionName: string;
    state: GQReferenceDataVersionState;
    latestSchema: {
      __typename?: 'ReferenceDataSchema';
      id: string;
      schemaJson: JSONSchema7;
    } | null;
  };
};

export type GQReferenceDataSourceFindPageQueryVariables = Exact<{
  query: Scalars['String']['input'];
}>;

export type GQReferenceDataSourceFindPageQuery = {
  __typename?: 'Query';
  findReferenceDataSource: { __typename?: 'ReferenceDataSource'; id: string };
};

export type GQReportQuestionForCopyFragment = {
  __typename?: 'ReportQuestion';
  id: string;
  identifier: string;
};

export type GQReportQuestionForAnswerCopyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQReportQuestionForAnswerCopyQuery = {
  __typename?: 'Query';
  reportQuestion: {
    __typename?: 'ReportQuestion';
    id: string;
    identifier: string;
    questionsWithMatchingOutputSchema: Array<{
      __typename?: 'ReportQuestion';
      id: string;
      identifier: string;
    }>;
  };
};

export type GQCopyReportQuestionAnswersMutationVariables = Exact<{
  input: GQCopyReportQuestionAnswersInput;
}>;

export type GQCopyReportQuestionAnswersMutation = {
  __typename?: 'Mutation';
  copyReportQuestionAnswers: {
    __typename?: 'CopyReportQuestionAnswersPayload';
    numCopied: number;
  } | null;
};

export type GQReportAnswerForAdminTableFragment = {
  __typename?: 'ReportAnswer';
  id: string;
  outputJson: any | null;
  updatedAt: Date;
  question: {
    __typename?: 'ReportQuestion';
    id: string;
    identifier: string;
    componentId: string;
    isComputed: boolean;
  };
  report: {
    __typename?: 'Report';
    id: string;
    name: string;
    isMigratedFromStaticConfig: boolean;
    createdAt: Date;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      demoOrg: boolean;
    };
    config: {
      __typename?: 'ReportConfig';
      id: string;
      reportType: string;
      shortName: string;
    };
  };
};

export type GQReportAnswersQueryVariables = Exact<{
  filters: InputMaybe<GQReportAnswerFilters>;
  before: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQReportAnswersQuery = {
  __typename?: 'Query';
  reportAnswers: {
    __typename?: 'ReportAnswerConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: Array<{
      __typename?: 'ReportAnswerEdge';
      node: {
        __typename?: 'ReportAnswer';
        id: string;
        outputJson: any | null;
        updatedAt: Date;
        question: {
          __typename?: 'ReportQuestion';
          id: string;
          identifier: string;
          componentId: string;
          isComputed: boolean;
        };
        report: {
          __typename?: 'Report';
          id: string;
          name: string;
          isMigratedFromStaticConfig: boolean;
          createdAt: Date;
          organization: {
            __typename?: 'Organization';
            id: string;
            name: string;
            demoOrg: boolean;
          };
          config: {
            __typename?: 'ReportConfig';
            id: string;
            reportType: string;
            shortName: string;
          };
        };
      } | null;
    } | null>;
  };
};

export type GQGetReportConfigQuestionsForAdminQueryVariables = Exact<{
  reportConfigId: Scalars['ID']['input'];
}>;

export type GQGetReportConfigQuestionsForAdminQuery = {
  __typename?: 'Query';
  reportConfigQuestions: Array<{
    __typename?: 'ReportQuestion';
    id: string;
    identifier: string;
  }>;
};

export type GQDeleteReportAnswersMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQDeleteReportAnswersMutation = {
  __typename?: 'Mutation';
  deleteReportAnswers: {
    __typename?: 'DeleteReportAnswersPayload';
    deleteCount: number;
  } | null;
};

export type GQVerifyReportAnswersMutationVariables = Exact<{
  reportAnswerIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQVerifyReportAnswersMutation = {
  __typename?: 'Mutation';
  verifyReportAnswers: {
    __typename?: 'VerifyReportAnswersPayload';
    successCount: number;
    failureCount: number;
  } | null;
};

export type GQGetCdpIdMappingsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
  hasOrgAndReportId: Scalars['Boolean']['input'];
}>;

export type GQGetCdpIdMappingsQuery = {
  __typename?: 'Query';
  cdpIdMappings: {
    __typename: 'CdpIdMappingsPayload';
    id: string;
    questionIdMappings: Array<{
      __typename?: 'CdpQuestionIdMapping';
      id: string;
      cdpRefId: string | null;
      reportQuestionIdentifier: {
        __typename?: 'ReportQuestionIdentifier';
        id: string;
        identifier: string;
      };
    }>;
    columnIdMappings: Array<{
      __typename?: 'CdpColumnIdMapping';
      id: string;
      cdpRefId: string | null;
      watershedColumnId: string;
      otherColumnIdMapping: any | null;
    }>;
  };
  cdpMissingIdMappings?: {
    __typename?: 'CdpMissingIdMappingsPayload';
    candidateQuestionMappings: Array<{
      __typename?: 'CdpCandidateQuestionIdMapping';
      cdpRefId: string;
      cdpQuestionJson: any;
      reportQuestion: {
        __typename?: 'ReportQuestion';
        id: string;
        identifier: string;
        identifierId: string;
      } | null;
    }>;
    candidateColumnMappings: Array<{
      __typename?: 'CdpCandidateColumnIdMapping';
      cdpRefId: string;
      cdpParentRefId: string;
      cdpColumnQuestionJson: any;
      cdpParentQuestionJson: any;
      columnId: string | null;
      columnLabel: string | null;
      otherColumnIdMapping: any | null;
      parentReportQuestion: {
        __typename?: 'ReportQuestion';
        id: string;
        identifier: string;
        identifierId: string;
      } | null;
    }>;
    unmappedWatershedQuestions: Array<{
      __typename?: 'ReportQuestion';
      id: string;
      identifier: string;
      componentId: string;
      isComputed: boolean;
    }>;
  };
};

export type GQCdpIdMappingsFieldsFragment = {
  __typename: 'CdpIdMappingsPayload';
  id: string;
  questionIdMappings: Array<{
    __typename?: 'CdpQuestionIdMapping';
    id: string;
    cdpRefId: string | null;
    reportQuestionIdentifier: {
      __typename?: 'ReportQuestionIdentifier';
      id: string;
      identifier: string;
    };
  }>;
  columnIdMappings: Array<{
    __typename?: 'CdpColumnIdMapping';
    id: string;
    cdpRefId: string | null;
    watershedColumnId: string;
    otherColumnIdMapping: any | null;
  }>;
};

export type GQCdpQuestionIdMappingFragment = {
  __typename?: 'CdpQuestionIdMapping';
  id: string;
  cdpRefId: string | null;
  reportQuestionIdentifier: {
    __typename?: 'ReportQuestionIdentifier';
    id: string;
    identifier: string;
  };
};

export type GQCdpColumnIdMappingFragment = {
  __typename?: 'CdpColumnIdMapping';
  id: string;
  cdpRefId: string | null;
  watershedColumnId: string;
  otherColumnIdMapping: any | null;
};

export type GQCandidateQuestionMappingFieldsFragment = {
  __typename?: 'CdpCandidateQuestionIdMapping';
  cdpRefId: string;
  cdpQuestionJson: any;
  reportQuestion: {
    __typename?: 'ReportQuestion';
    id: string;
    identifier: string;
    identifierId: string;
  } | null;
};

export type GQCandidateColumnMappingFieldsFragment = {
  __typename?: 'CdpCandidateColumnIdMapping';
  cdpRefId: string;
  cdpParentRefId: string;
  cdpColumnQuestionJson: any;
  cdpParentQuestionJson: any;
  columnId: string | null;
  columnLabel: string | null;
  otherColumnIdMapping: any | null;
  parentReportQuestion: {
    __typename?: 'ReportQuestion';
    id: string;
    identifier: string;
    identifierId: string;
  } | null;
};

export type GQUnmappedWatershedQuestionFieldsFragment = {
  __typename?: 'ReportQuestion';
  id: string;
  identifier: string;
  componentId: string;
  isComputed: boolean;
};

export type GQCreateCdpQuestionMappingsMutationVariables = Exact<{
  input: GQCreateCdpQuestionMappingsInput;
}>;

export type GQCreateCdpQuestionMappingsMutation = {
  __typename?: 'Mutation';
  createCdpQuestionMappings: {
    __typename?: 'CreateCdpQuestionMappingsPayload';
    mappings: {
      __typename: 'CdpIdMappingsPayload';
      id: string;
      questionIdMappings: Array<{
        __typename?: 'CdpQuestionIdMapping';
        id: string;
        cdpRefId: string | null;
        reportQuestionIdentifier: {
          __typename?: 'ReportQuestionIdentifier';
          id: string;
          identifier: string;
        };
      }>;
      columnIdMappings: Array<{
        __typename?: 'CdpColumnIdMapping';
        id: string;
        cdpRefId: string | null;
        watershedColumnId: string;
        otherColumnIdMapping: any | null;
      }>;
    };
  } | null;
};

export type GQDeleteCdpQuestionMappingsMutationVariables = Exact<{
  input: GQDeleteCdpQuestionMappingsInput;
}>;

export type GQDeleteCdpQuestionMappingsMutation = {
  __typename?: 'Mutation';
  deleteCdpQuestionMappings: {
    __typename?: 'DeleteCdpQuestionMappingsPayload';
    mappings: {
      __typename: 'CdpIdMappingsPayload';
      id: string;
      questionIdMappings: Array<{
        __typename?: 'CdpQuestionIdMapping';
        id: string;
        cdpRefId: string | null;
        reportQuestionIdentifier: {
          __typename?: 'ReportQuestionIdentifier';
          id: string;
          identifier: string;
        };
      }>;
      columnIdMappings: Array<{
        __typename?: 'CdpColumnIdMapping';
        id: string;
        cdpRefId: string | null;
        watershedColumnId: string;
        otherColumnIdMapping: any | null;
      }>;
    };
  } | null;
};

export type GQUpsertCdpQuestionMappingsMutationVariables = Exact<{
  input: GQUpsertCdpQuestionMappingsInput;
}>;

export type GQUpsertCdpQuestionMappingsMutation = {
  __typename?: 'Mutation';
  upsertCdpQuestionMappings: {
    __typename?: 'UpsertCdpQuestionMappingsPayload';
    updatedMappings: Array<{
      __typename?: 'CdpQuestionIdMapping';
      id: string;
      cdpRefId: string | null;
      reportQuestionIdentifier: {
        __typename?: 'ReportQuestionIdentifier';
        id: string;
        identifier: string;
      };
    }>;
  } | null;
};

export type GQCreateCdpColumnMappingsMutationVariables = Exact<{
  input: GQCreateCdpColumnMappingsInput;
}>;

export type GQCreateCdpColumnMappingsMutation = {
  __typename?: 'Mutation';
  createCdpColumnMappings: {
    __typename?: 'CreateCdpColumnMappingsPayload';
    mappings: {
      __typename: 'CdpIdMappingsPayload';
      id: string;
      questionIdMappings: Array<{
        __typename?: 'CdpQuestionIdMapping';
        id: string;
        cdpRefId: string | null;
        reportQuestionIdentifier: {
          __typename?: 'ReportQuestionIdentifier';
          id: string;
          identifier: string;
        };
      }>;
      columnIdMappings: Array<{
        __typename?: 'CdpColumnIdMapping';
        id: string;
        cdpRefId: string | null;
        watershedColumnId: string;
        otherColumnIdMapping: any | null;
      }>;
    };
  } | null;
};

export type GQDeleteCdpColumnMappingsMutationVariables = Exact<{
  input: GQDeleteCdpColumnMappingsInput;
}>;

export type GQDeleteCdpColumnMappingsMutation = {
  __typename?: 'Mutation';
  deleteCdpColumnMappings: {
    __typename?: 'DeleteCdpColumnMappingsPayload';
    mappings: {
      __typename: 'CdpIdMappingsPayload';
      id: string;
      questionIdMappings: Array<{
        __typename?: 'CdpQuestionIdMapping';
        id: string;
        cdpRefId: string | null;
        reportQuestionIdentifier: {
          __typename?: 'ReportQuestionIdentifier';
          id: string;
          identifier: string;
        };
      }>;
      columnIdMappings: Array<{
        __typename?: 'CdpColumnIdMapping';
        id: string;
        cdpRefId: string | null;
        watershedColumnId: string;
        otherColumnIdMapping: any | null;
      }>;
    };
  } | null;
};

export type GQUpsertCdpColumnMappingsMutationVariables = Exact<{
  input: GQUpsertCdpColumnMappingsInput;
}>;

export type GQUpsertCdpColumnMappingsMutation = {
  __typename?: 'Mutation';
  upsertCdpColumnMappings: {
    __typename?: 'UpsertCdpColumnMappingsPayload';
    updatedMappings: Array<{
      __typename?: 'CdpColumnIdMapping';
      id: string;
      cdpRefId: string | null;
      watershedColumnId: string;
      otherColumnIdMapping: any | null;
    }>;
  } | null;
};

export type GQReplaceReportingCdpMappingsMutationVariables = Exact<{
  input: GQReplaceReportingCdpMappingsInput;
}>;

export type GQReplaceReportingCdpMappingsMutation = {
  __typename?: 'Mutation';
  replaceReportingCdpMappings: {
    __typename?: 'ReplaceReportingCdpMappingsPayload';
    success: boolean;
    mappings: {
      __typename: 'CdpIdMappingsPayload';
      id: string;
      questionIdMappings: Array<{
        __typename?: 'CdpQuestionIdMapping';
        id: string;
        cdpRefId: string | null;
        reportQuestionIdentifier: {
          __typename?: 'ReportQuestionIdentifier';
          id: string;
          identifier: string;
        };
      }>;
      columnIdMappings: Array<{
        __typename?: 'CdpColumnIdMapping';
        id: string;
        cdpRefId: string | null;
        watershedColumnId: string;
        otherColumnIdMapping: any | null;
      }>;
    };
  };
};

export type GQGetReportsForCdpIdMappingsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetReportsForCdpIdMappingsQuery = {
  __typename?: 'Query';
  reports: {
    __typename?: 'ReportConnection';
    edges: Array<{
      __typename?: 'ReportEdge';
      node: {
        __typename?: 'Report';
        id: string;
        name: string;
        updatedAt: Date;
        config: { __typename?: 'ReportConfig'; id: string; shortName: string };
      } | null;
    } | null>;
  };
};

export type GQUpdateReportConfigFromAdminTableMutationVariables = Exact<{
  input: GQUpdateReportConfigInput;
}>;

export type GQUpdateReportConfigFromAdminTableMutation = {
  __typename?: 'Mutation';
  updateReportConfig: {
    __typename?: 'UpdateReportConfigPayload';
    reportConfig: {
      __typename?: 'ReportConfig';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      reportType: string;
      shortName: string;
      longName: string;
      description: string;
      brandColor: string;
      isHiddenFromOverview: boolean;
      isCreationBlocked: boolean;
      isRegulatoryExposureBlocked: boolean;
      useTableDisplay: boolean | null;
      useReportingIntention: boolean | null;
      requiredPermissions: Array<GQPermissionType>;
      reportObjectives: Array<GQCanonicalReportObjective>;
      isCorporateReport: boolean;
      isFinanceReport: boolean;
      status: GQReportConfigStatus | null;
    };
  } | null;
};

export type GQCreateReportConfigFromAdminTableMutationVariables = Exact<{
  input: GQCreateReportConfigInput;
}>;

export type GQCreateReportConfigFromAdminTableMutation = {
  __typename?: 'Mutation';
  createReportConfig: {
    __typename?: 'CreateReportConfigPayload';
    reportConfig: {
      __typename?: 'ReportConfig';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      reportType: string;
      shortName: string;
      longName: string;
      description: string;
      brandColor: string;
      isHiddenFromOverview: boolean;
      isCreationBlocked: boolean;
      isRegulatoryExposureBlocked: boolean;
      useTableDisplay: boolean | null;
      useReportingIntention: boolean | null;
      requiredPermissions: Array<GQPermissionType>;
      reportObjectives: Array<GQCanonicalReportObjective>;
      isCorporateReport: boolean;
      isFinanceReport: boolean;
      status: GQReportConfigStatus | null;
    };
  } | null;
};

export type GQReportQuestionContainerFieldsFragment = {
  __typename?: 'ReportQuestionContainer';
  id: string;
  label: string | null;
  description: string | null;
  statusJson: any;
  guidanceJson: any | null;
  fullWidth: boolean;
  itemIds: Array<string>;
};

export type GQCreateReportQuestionContainerMutationVariables = Exact<{
  input: GQCreateReportQuestionContainerInput;
}>;

export type GQCreateReportQuestionContainerMutation = {
  __typename?: 'Mutation';
  createReportQuestionContainer: {
    __typename?: 'CreateReportQuestionContainerPayload';
    container: {
      __typename?: 'ReportQuestionContainer';
      id: string;
      label: string | null;
      description: string | null;
      statusJson: any;
      guidanceJson: any | null;
      fullWidth: boolean;
      itemIds: Array<string>;
    };
  } | null;
};

export type GQUpdateReportQuestionContainerMutationVariables = Exact<{
  input: GQUpdateReportQuestionContainerInput;
}>;

export type GQUpdateReportQuestionContainerMutation = {
  __typename?: 'Mutation';
  updateReportQuestionContainer: {
    __typename?: 'UpdateReportQuestionContainerPayload';
    container: {
      __typename?: 'ReportQuestionContainer';
      id: string;
      label: string | null;
      description: string | null;
      statusJson: any;
      guidanceJson: any | null;
      fullWidth: boolean;
      itemIds: Array<string>;
    };
  } | null;
};

export type GQDeleteReportQuestionContainerMutationVariables = Exact<{
  input: GQDeleteReportQuestionContainerInput;
}>;

export type GQDeleteReportQuestionContainerMutation = {
  __typename?: 'Mutation';
  deleteReportQuestionContainer: {
    __typename?: 'DeleteReportQuestionContainerPayload';
    parentId: string;
    container: {
      __typename?: 'ReportQuestionContainer';
      id: string;
      label: string | null;
      description: string | null;
      statusJson: any;
      guidanceJson: any | null;
      fullWidth: boolean;
      itemIds: Array<string>;
    };
  } | null;
};

export type GQReportsToBeDeletedQueryVariables = Exact<{
  configId: Scalars['ID']['input'];
}>;

export type GQReportsToBeDeletedQuery = {
  __typename?: 'Query';
  reports: {
    __typename?: 'ReportConnection';
    edges: Array<{
      __typename?: 'ReportEdge';
      node: {
        __typename?: 'Report';
        id: string;
        name: string;
        updatedAt: Date;
        organization: {
          __typename?: 'Organization';
          id: string;
          name: string;
          demoOrg: boolean;
          testOrg: boolean;
        };
      } | null;
    } | null>;
  };
};

export type GQCreateReportConfigFromSrcMutationVariables = Exact<{
  input: GQCreateReportConfigFromSrcInput;
}>;

export type GQCreateReportConfigFromSrcMutation = {
  __typename?: 'Mutation';
  createReportConfigFromSrc: {
    __typename?: 'CreateReportConfigPayload';
    reportConfig: { __typename?: 'ReportConfig'; id: string };
  } | null;
};

export type GQGetReportConfigForAdminQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetReportConfigForAdminQuery = {
  __typename?: 'Query';
  reportConfig: {
    __typename?: 'ReportConfig';
    id: string;
    shortName: string;
    longName: string;
    description: string;
    brandColor: string;
    isHiddenFromOverview: boolean;
    isCreationBlocked: boolean;
    isRegulatoryExposureBlocked: boolean;
    requiredPermissions: Array<GQPermissionType>;
    reportObjectives: Array<GQCanonicalReportObjective>;
    inputIds: Array<string>;
    computedItemIds: Array<string>;
    itemIds: Array<string>;
    lastLintedAt: Date | null;
    latestLinterStatus: GQConfigLinterStatus | null;
    latestComponentUpdatedAt: Date | null;
    allQuestions: Array<{
      __typename?: 'ReportQuestion';
      id: string;
      identifier: string;
      componentId: string;
      isComputed: boolean;
    }>;
    allContainers: Array<{
      __typename?: 'ReportQuestionContainer';
      id: string;
      label: string | null;
      itemIds: Array<string>;
    }>;
  };
};

export type GQGetReportQuestionContainerForAdminQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetReportQuestionContainerForAdminQuery = {
  __typename?: 'Query';
  reportQuestionContainer: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    label: string | null;
    description: string | null;
    statusJson: any;
    guidanceJson: any | null;
    fullWidth: boolean;
    itemIds: Array<string>;
  };
};

export type GQGetReportQuestionForAdminQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetReportQuestionForAdminQuery = {
  __typename?: 'Query';
  reportQuestion: {
    __typename?: 'ReportQuestion';
    id: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
    identifier: string;
    componentId: string;
    staticInputJson: any | null;
    dynamicInputJson: any | null;
    statusJson: any;
    guidanceJson: any | null;
    isComputed: boolean;
    isDynamic: boolean | null;
    allowNotes: boolean;
    allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
  };
};

export type GQLintReportConfigMutationVariables = Exact<{
  input: GQLintReportConfigInput;
}>;

export type GQLintReportConfigMutation = {
  __typename?: 'Mutation';
  lintReportConfig: {
    __typename?: 'LintReportConfigPayload';
    status: GQConfigLinterStatus;
    lintErrors: Array<{
      __typename?: 'ConfigLinterError';
      reportObjectId: string;
      message: string;
      type: GQConfigLinterErrorType;
    }>;
  } | null;
};

export type GQReportQuestionRawFieldsFragment = {
  __typename?: 'ReportQuestion';
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  identifier: string;
  componentId: string;
  staticInputJson: any | null;
  dynamicInputJson: any | null;
  statusJson: any;
  guidanceJson: any | null;
  isComputed: boolean;
  isDynamic: boolean | null;
  allowNotes: boolean;
  allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
};

export type GQCreateReportQuestionMutationVariables = Exact<{
  input: GQCreateReportQuestionInput;
}>;

export type GQCreateReportQuestionMutation = {
  __typename?: 'Mutation';
  createReportQuestion: {
    __typename?: 'CreateReportQuestionPayload';
    question: {
      __typename?: 'ReportQuestion';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      identifier: string;
      componentId: string;
      staticInputJson: any | null;
      dynamicInputJson: any | null;
      statusJson: any;
      guidanceJson: any | null;
      isComputed: boolean;
      isDynamic: boolean | null;
      allowNotes: boolean;
      allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
      parentContainer: {
        __typename?: 'ReportQuestionContainer';
        id: string;
        label: string | null;
        description: string | null;
        statusJson: any;
        guidanceJson: any | null;
        fullWidth: boolean;
        itemIds: Array<string>;
      } | null;
    };
  } | null;
};

export type GQUpdateReportQuestionMutationVariables = Exact<{
  input: GQUpdateReportQuestionInput;
}>;

export type GQUpdateReportQuestionMutation = {
  __typename?: 'Mutation';
  updateReportQuestion: {
    __typename?: 'UpdateReportQuestionPayload';
    question: {
      __typename?: 'ReportQuestion';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      identifier: string;
      componentId: string;
      staticInputJson: any | null;
      dynamicInputJson: any | null;
      statusJson: any;
      guidanceJson: any | null;
      isComputed: boolean;
      isDynamic: boolean | null;
      allowNotes: boolean;
      allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
    };
  } | null;
};

export type GQDeleteReportQuestionMutationVariables = Exact<{
  input: GQDeleteReportQuestionInput;
}>;

export type GQDeleteReportQuestionMutation = {
  __typename?: 'Mutation';
  deleteReportQuestion: {
    __typename?: 'DeleteReportQuestionPayload';
    parentId: string;
    question: {
      __typename?: 'ReportQuestion';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      identifier: string;
      componentId: string;
      staticInputJson: any | null;
      dynamicInputJson: any | null;
      statusJson: any;
      guidanceJson: any | null;
      isComputed: boolean;
      isDynamic: boolean | null;
      allowNotes: boolean;
      allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
    };
  } | null;
};

export type GQReportObjectConfigPathQueryVariables = Exact<{
  input: GQGetReportObjectConfigPathInput;
}>;

export type GQReportObjectConfigPathQuery = {
  __typename?: 'Query';
  getReportObjectConfigPath: {
    __typename?: 'GetReportObjectConfigPathPayload';
    path: Array<{
      __typename?: 'ReportObjectConfigPathItem';
      id: string | null;
      label: string | null;
      isInput: boolean;
      isComputed: boolean;
    }> | null;
  } | null;
};

export type GQGetReportQuestionWithAllItemsForAdminQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetReportQuestionWithAllItemsForAdminQuery = {
  __typename?: 'Query';
  reportQuestion: {
    __typename?: 'ReportQuestion';
    id: string;
    identifier: string;
    reportConfig: {
      __typename?: 'ReportConfig';
      id: string;
      itemIds: Array<string>;
      allContainers: Array<{
        __typename?: 'ReportQuestionContainer';
        id: string;
        label: string | null;
        itemIds: Array<string>;
      }>;
      allQuestions: Array<{
        __typename?: 'ReportQuestion';
        id: string;
        identifier: string;
        componentId: string;
        isComputed: boolean;
      }>;
    };
  };
};

export type GQGetReportQuestionContainerWithAllItemsForAdminQueryVariables =
  Exact<{
    id: Scalars['ID']['input'];
  }>;

export type GQGetReportQuestionContainerWithAllItemsForAdminQuery = {
  __typename?: 'Query';
  reportQuestionContainer: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    label: string | null;
    reportConfig: {
      __typename?: 'ReportConfig';
      id: string;
      itemIds: Array<string>;
      allContainers: Array<{
        __typename?: 'ReportQuestionContainer';
        id: string;
        label: string | null;
        itemIds: Array<string>;
      }>;
      allQuestions: Array<{
        __typename?: 'ReportQuestion';
        id: string;
        identifier: string;
        componentId: string;
        isComputed: boolean;
      }>;
    };
  };
};

export type GQReportConfigFieldsForAdminTableFragment = {
  __typename?: 'ReportConfig';
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  reportType: string;
  shortName: string;
  longName: string;
  description: string;
  brandColor: string;
  isHiddenFromOverview: boolean;
  isCreationBlocked: boolean;
  isRegulatoryExposureBlocked: boolean;
  useTableDisplay: boolean | null;
  useReportingIntention: boolean | null;
  requiredPermissions: Array<GQPermissionType>;
  reportObjectives: Array<GQCanonicalReportObjective>;
  isCorporateReport: boolean;
  isFinanceReport: boolean;
  status: GQReportConfigStatus | null;
};

export type GQAllReportConfigsForAdminTableQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQAllReportConfigsForAdminTableQuery = {
  __typename?: 'Query';
  reportConfigs: {
    __typename?: 'ReportConfigConnection';
    edges: Array<{
      __typename?: 'ReportConfigEdge';
      node: {
        __typename?: 'ReportConfig';
        id: string;
        createdAt: Date;
        updatedAt: Date;
        deletedAt: Date | null;
        reportType: string;
        shortName: string;
        longName: string;
        description: string;
        brandColor: string;
        isHiddenFromOverview: boolean;
        isCreationBlocked: boolean;
        isRegulatoryExposureBlocked: boolean;
        useTableDisplay: boolean | null;
        useReportingIntention: boolean | null;
        requiredPermissions: Array<GQPermissionType>;
        reportObjectives: Array<GQCanonicalReportObjective>;
        isCorporateReport: boolean;
        isFinanceReport: boolean;
        status: GQReportConfigStatus | null;
      } | null;
    } | null>;
  };
};

export type GQDumpReportConfigToSrcQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQDumpReportConfigToSrcQuery = {
  __typename?: 'Query';
  dumpReportConfigToSrc: {
    __typename?: 'ReportConfigSrcPayload';
    src: any;
    lintStatus: GQConfigLinterStatus;
  };
};

export type GQDeleteReportConfigFromAdminTableMutationVariables = Exact<{
  input: GQDeleteReportConfigInput;
}>;

export type GQDeleteReportConfigFromAdminTableMutation = {
  __typename?: 'Mutation';
  deleteReportConfig: {
    __typename?: 'DeleteReportConfigPayload';
    reportConfig: {
      __typename?: 'ReportConfig';
      id: string;
      createdAt: Date;
      updatedAt: Date;
      deletedAt: Date | null;
      reportType: string;
      shortName: string;
      longName: string;
      description: string;
      brandColor: string;
      isHiddenFromOverview: boolean;
      isCreationBlocked: boolean;
      isRegulatoryExposureBlocked: boolean;
      useTableDisplay: boolean | null;
      useReportingIntention: boolean | null;
      requiredPermissions: Array<GQPermissionType>;
      reportObjectives: Array<GQCanonicalReportObjective>;
      isCorporateReport: boolean;
      isFinanceReport: boolean;
      status: GQReportConfigStatus | null;
    };
  } | null;
};

export type GQReportHealthChecksQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  footprintSnapshotId: InputMaybe<Scalars['ID']['input']>;
  interval: InputMaybe<Scalars['YMInterval']['input']>;
}>;

export type GQReportHealthChecksQuery = {
  __typename?: 'Query';
  reportingFootprintHealthChecks: {
    __typename?: 'ReportingFootprintHealthChecksPayload';
    footprintSnapshotId: string;
    interval: YMInterval;
    failures: Array<{
      __typename?: 'ReportingFootprintHealthCheckFailure';
      validatorId: string;
      outputJson: any;
    }>;
  } | null;
};

export type GQReportQuestionIdentifierFieldsFragment = {
  __typename?: 'ReportQuestionIdentifier';
  id: string;
  identifier: string;
};

export type GQGetReportQuestionIdentifierMappingQueryVariables = Exact<{
  reportQuestionIdentifierId: Scalars['ID']['input'];
}>;

export type GQGetReportQuestionIdentifierMappingQuery = {
  __typename?: 'Query';
  reportQuestionIdentifierMapping: {
    __typename?: 'ReportQuestionIdentifierMapping';
    id: string;
    relatedQuestionIdentifiers: Array<{
      __typename?: 'ReportQuestionIdentifier';
      id: string;
      identifier: string;
    }>;
  };
};

export type GQCreateReportQuestionIdentifierMappingMutationVariables = Exact<{
  identifierId1: Scalars['ID']['input'];
  identifierId2: Scalars['ID']['input'];
}>;

export type GQCreateReportQuestionIdentifierMappingMutation = {
  __typename?: 'Mutation';
  createReportQuestionIdentifierMapping: {
    __typename?: 'UpdateReportQuestionIdentifierMappingPayload';
    reportQuestionIdentifierMapping1: {
      __typename?: 'ReportQuestionIdentifierMapping';
      id: string;
      relatedQuestionIdentifiers: Array<{
        __typename?: 'ReportQuestionIdentifier';
        id: string;
        identifier: string;
      }>;
    };
    reportQuestionIdentifierMapping2: {
      __typename?: 'ReportQuestionIdentifierMapping';
      id: string;
      relatedQuestionIdentifiers: Array<{
        __typename?: 'ReportQuestionIdentifier';
        id: string;
        identifier: string;
      }>;
    };
  };
};

export type GQDeleteReportQuestionIdentifierMappingMutationVariables = Exact<{
  identifierId1: Scalars['ID']['input'];
  identifierId2: Scalars['ID']['input'];
}>;

export type GQDeleteReportQuestionIdentifierMappingMutation = {
  __typename?: 'Mutation';
  deleteReportQuestionIdentifierMapping: {
    __typename?: 'UpdateReportQuestionIdentifierMappingPayload';
    reportQuestionIdentifierMapping1: {
      __typename?: 'ReportQuestionIdentifierMapping';
      id: string;
      relatedQuestionIdentifiers: Array<{
        __typename?: 'ReportQuestionIdentifier';
        id: string;
        identifier: string;
      }>;
    };
    reportQuestionIdentifierMapping2: {
      __typename?: 'ReportQuestionIdentifierMapping';
      id: string;
      relatedQuestionIdentifiers: Array<{
        __typename?: 'ReportQuestionIdentifier';
        id: string;
        identifier: string;
      }>;
    };
  };
};

export type GQReportAnswerVerifierFailuresQueryVariables = Exact<{
  reportAnswerFilters: GQReportAnswerFilterInput;
  before: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQReportAnswerVerifierFailuresQuery = {
  __typename?: 'Query';
  reportAnswerVerifierFailures: {
    __typename?: 'ReportAnswerVerifierFailureConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: Array<{
      __typename?: 'ReportAnswerVerifierFailureEdge';
      node: {
        __typename?: 'ReportAnswerVerifierFailure';
        id: string;
        reportAnswerUpdatedAt: Date;
        mismatchTime: Date;
        outputJson: any | null;
        errorJson: any | null;
        mismatchApprovalTime: Date | null;
        organization: { __typename?: 'Organization'; id: string; name: string };
        reportAnswer: {
          __typename?: 'ReportAnswer';
          id: string;
          outputJson: any | null;
          updatedAt: Date;
          question: {
            __typename?: 'ReportQuestion';
            id: string;
            identifier: string;
            componentId: string;
          };
          report: {
            __typename?: 'Report';
            id: string;
            name: string;
            isMigratedFromStaticConfig: boolean;
            createdAt: Date;
            config: {
              __typename?: 'ReportConfig';
              id: string;
              reportType: string;
            };
          };
        };
      } | null;
    } | null>;
  };
};

export type GQGetReportAnswerVerifierOptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetReportAnswerVerifierOptionsQuery = {
  __typename?: 'Query';
  reportAnswerVerifierOptions: {
    __typename?: 'ReportAnswerVerifierOptions';
    componentIds: Array<string> | null;
    reportTypes: Array<string> | null;
    organizations: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
    }>;
  };
};

export type GQApproveReportAnswerVerifiersMutationVariables = Exact<{
  input: GQApproveReportAnswerVerifiersInput;
}>;

export type GQApproveReportAnswerVerifiersMutation = {
  __typename?: 'Mutation';
  approveReportAnswerVerifiers: {
    __typename?: 'ApproveReportAnswerVerifiersPayload';
    updatedAnswers: Array<{
      __typename?: 'ReportAnswer';
      id: string;
      updatedAt: Date;
      outputJson: any | null;
    }>;
  } | null;
};

export type GQAcceptReportAnswerVerifierFailuresMutationVariables = Exact<{
  input: GQAcceptReportAnswerVerifierFailuresInput;
}>;

export type GQAcceptReportAnswerVerifierFailuresMutation = {
  __typename?: 'Mutation';
  acceptReportAnswerVerifierFailures: {
    __typename?: 'AcceptReportAnswerVerifierFailuresPayload';
    updatedVerifierFailures: Array<{
      __typename?: 'ReportAnswerVerifierFailure';
      id: string;
      mismatchApprovalTime: Date | null;
    }>;
  } | null;
};

export type GQClearReportAnswerVerifiersMutationVariables = Exact<{
  input: GQClearReportAnswerVerifierFailuresInput;
}>;

export type GQClearReportAnswerVerifiersMutation = {
  __typename?: 'Mutation';
  clearReportAnswerVerifierFailures: {
    __typename?: 'ClearReportAnswerVerifierFailuresPayload';
    num: number;
  } | null;
};

export type GQRunReportAnswerVerifierMutationVariables = Exact<{
  input: GQRunReportAnswerVerifierInput;
}>;

export type GQRunReportAnswerVerifierMutation = {
  __typename?: 'Mutation';
  runReportAnswerVerifier: {
    __typename?: 'RunReportAnswerVerifierPayload';
    reportAnswerId: string;
  } | null;
};

export type GQCommonOpportunityFieldsFragment = {
  __typename?: 'TcfdArchetypeOpportunity';
  id: string;
  title: string;
  userDescription: string;
};

export type GQArchetypeOpportunityFieldsFragment = {
  __typename?: 'TcfdArchetypeOpportunity';
  geographies: Array<string>;
  sectors: Array<string>;
  distributionModel: GQTcfdStrategyModuleDistributionModel;
  id: string;
  title: string;
  userDescription: string;
};

export type GQRiskCommonFieldsFragment = {
  __typename?: 'TcfdArchetypeRisk';
  id: string;
  title: string;
  userDescription: string;
  kind: GQTcfdRiskKind;
  possibleImpactDescription: string | null;
  lowTrajectoryImpact: GQTcfdRiskImpact;
  mediumTrajectoryImpact: GQTcfdRiskImpact;
  highTrajectoryImpact: GQTcfdRiskImpact;
  physicalRiskSeverity: GQTcfdPhysicalRiskSeverity | null;
};

export type GQRiskArchetypeFieldsFragment = {
  __typename?: 'TcfdArchetypeRisk';
  geographies: Array<string>;
  sectors: Array<string>;
  distributionModel: GQTcfdStrategyModuleDistributionModel;
  whyWeChoseThisMd: string;
  id: string;
  title: string;
  userDescription: string;
  kind: GQTcfdRiskKind;
  possibleImpactDescription: string | null;
  lowTrajectoryImpact: GQTcfdRiskImpact;
  mediumTrajectoryImpact: GQTcfdRiskImpact;
  highTrajectoryImpact: GQTcfdRiskImpact;
  physicalRiskSeverity: GQTcfdPhysicalRiskSeverity | null;
};

export type GQGetExtractorRunQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetExtractorRunQuery = {
  __typename?: 'Query';
  extractorRun: {
    __typename?: 'ExtractorRun';
    id: string;
    state: GQBackgroundJobState;
    error: string | null;
    activityDataExtractor: {
      __typename?: 'ActivityDataExtractor';
      id: string;
      createdAt: Date;
      kind: GQActivityDataExtractorKind;
      sql: string;
      warnings: any | null;
      error: string | null;
      published: boolean | null;
      userVisible: boolean;
      generated: boolean | null;
      duckdbVersion: string;
      bartActivityType: string | null;
      facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
      user: { __typename?: 'User'; id: string; name: string } | null;
      userUploadedTables: Array<{
        __typename?: 'UserUploadedTable';
        id: string;
        parentFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          category: GQFileCategory;
        };
      }>;
      runSqlOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      transformedOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      cleanAdtOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
    } | null;
    jobError: {
      __typename?: 'SerializableError';
      message: string;
      code: string | null;
      stackTrace: string | null;
      errorType: string | null;
      details: any | null;
    } | null;
  } | null;
};

export type GQGetPlaygroundSqlRunQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetPlaygroundSqlRunQuery = {
  __typename?: 'Query';
  playgroundSqlRun: {
    __typename?: 'PlaygroundSqlRun';
    id: string;
    createdAt: Date;
    state: GQBackgroundJobState;
    error: string | null;
    sql: string;
    user: { __typename?: 'User'; id: string; name: string } | null;
    jobError: {
      __typename?: 'SerializableError';
      message: string;
      code: string | null;
      stackTrace: string | null;
      errorType: string | null;
      details: any | null;
    } | null;
    output: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
  } | null;
};

type GQQueryRunFields_ExtractorRun_Fragment = {
  __typename?: 'ExtractorRun';
  id: string;
  state: GQBackgroundJobState;
  error: string | null;
  jobError: {
    __typename?: 'SerializableError';
    message: string;
    code: string | null;
    stackTrace: string | null;
    errorType: string | null;
    details: any | null;
  } | null;
};

type GQQueryRunFields_PlaygroundSqlRun_Fragment = {
  __typename?: 'PlaygroundSqlRun';
  id: string;
  state: GQBackgroundJobState;
  error: string | null;
  jobError: {
    __typename?: 'SerializableError';
    message: string;
    code: string | null;
    stackTrace: string | null;
    errorType: string | null;
    details: any | null;
  } | null;
};

export type GQQueryRunFieldsFragment =
  | GQQueryRunFields_ExtractorRun_Fragment
  | GQQueryRunFields_PlaygroundSqlRun_Fragment;

export type GQParquetDataFieldsFragment = {
  __typename?: 'ParquetData';
  id: string;
  numRecords: number;
  ndjsonSignedUrl: string | null;
  ndjsonSize: number | null;
  ndjsonByteBoundaries: Array<number> | null;
  columns: Array<{
    __typename?: 'ParquetColumn';
    name: string;
    sqlType: string;
    minValue: any;
    maxValue: any;
    numNulls: number;
    approxDistinctValues: number;
    sampleValues: Array<any>;
  }>;
};

export type GQEnqueueBackgroundJobMutationVariables = Exact<{
  kind: Scalars['String']['input'];
  args: Scalars['JSONString']['input'];
  orgId: InputMaybe<Scalars['String']['input']>;
}>;

export type GQEnqueueBackgroundJobMutation = {
  __typename?: 'Mutation';
  enqueueBackgroundJob: {
    __typename?: 'EnqueueBackgroundJobPayload';
    job: { __typename?: 'BackgroundJob'; id: string };
  } | null;
};

export type GQEnqueueBackgroundJobWithQueueNameMutationVariables = Exact<{
  kind: Scalars['String']['input'];
  args: Scalars['JSONString']['input'];
  orgId: InputMaybe<Scalars['String']['input']>;
  queueName: InputMaybe<GQBackgroundJobQueueName>;
}>;

export type GQEnqueueBackgroundJobWithQueueNameMutation = {
  __typename?: 'Mutation';
  enqueueBackgroundJob: {
    __typename?: 'EnqueueBackgroundJobPayload';
    job: { __typename?: 'BackgroundJob'; id: string };
  } | null;
};

export type GQMarketplaceInternationalEacProjectsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQMarketplaceInternationalEacProjectsQuery = {
  __typename?: 'Query';
  marketplaceInternationalEacProjects: Array<{
    __typename?: 'MarketplaceProject';
    id: string;
    name: string;
  }>;
};

export type GQAdtsForMagicSqlQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQAdtsForMagicSqlQuery = {
  __typename?: 'Query';
  activityDataTables: {
    __typename?: 'ActivityDataTableConnection';
    edges: Array<{
      __typename?: 'ActivityDataTableEdge';
      node: {
        __typename?: 'ActivityDataTable';
        id: string;
        name: string;
      } | null;
    } | null>;
  };
};

export type GQFmdsForPlaygroundQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQFmdsForPlaygroundQuery = {
  __typename?: 'Query';
  files: {
    __typename?: 'FileMetadataConnection';
    edges: Array<{
      __typename?: 'FileMetadataEdge';
      node: { __typename?: 'FileMetadata'; id: string; name: string } | null;
    } | null>;
  };
};

export type GQFpssForPlaygroundQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQFpssForPlaygroundQuery = {
  __typename?: 'Query';
  footprintSnapshots: {
    __typename?: 'FootprintSnapshotConnection';
    edges: Array<{
      __typename?: 'FootprintSnapshotEdge';
      node: { __typename?: 'FootprintSnapshot'; id: string } | null;
    } | null>;
  };
};

export type GQUutsForPlaygroundQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQUutsForPlaygroundQuery = {
  __typename?: 'Query';
  userUploadedTables: {
    __typename?: 'UserUploadedTableConnection';
    edges: Array<{
      __typename?: 'UserUploadedTableEdge';
      node: {
        __typename?: 'UserUploadedTable';
        id: string;
        sqlTableName: string | null;
      } | null;
    } | null>;
  };
};

export type GQCreateAdminUploadMutationVariables = Exact<{
  input: GQCreateAdminUploadInput;
}>;

export type GQCreateAdminUploadMutation = {
  __typename?: 'Mutation';
  createAdminUpload: {
    __typename?: 'CreateAdminUploadPayload';
    url: string;
    fileId: string;
    remotePath: string;
  } | null;
};

export type GQMarkAdminUploadCompletedMutationVariables = Exact<{
  input: GQMarkAdminUploadCompletedInput;
}>;

export type GQMarkAdminUploadCompletedMutation = {
  __typename?: 'Mutation';
  markAdminUploadCompleted: {
    __typename?: 'MarkAdminUploadCompletedPayload';
    parseJobId: string | null;
    file: { __typename?: 'FileMetadata'; id: string };
  } | null;
};

export type GQFeatureFlagEnabledGloballyQueryVariables = Exact<{
  flag: GQFlags;
}>;

export type GQFeatureFlagEnabledGloballyQuery = {
  __typename?: 'Query';
  isEnabled: boolean;
};

export type GQSignOneSchemaJwtQueryVariables = Exact<{
  payload: Scalars['JSONString']['input'];
}>;

export type GQSignOneSchemaJwtQuery = {
  __typename?: 'Query';
  signOneSchemaJwt: { __typename?: 'OneSchemaJwtPayload'; token: string };
};

export type GQGetSuppliersAdminQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetSuppliersAdminQuery = {
  __typename?: 'Query';
  suppliersAdmin: {
    __typename?: 'OrgAdminSuppliers';
    suppliers: {
      __typename?: 'AdminSuppliers';
      suppliersMerged: { __typename?: 'SuppliersData'; id: string; data: any };
    };
  } | null;
};

export type GQCreateUserDashboardVersionMutationVariables = Exact<{
  input: GQCreateUserInput;
}>;

export type GQCreateUserDashboardVersionMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'CreateUserPayload';
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    };
  } | null;
};

export type GQGetFootprintSnapshotDescriptionQueryVariables = Exact<{
  version: Scalars['String']['input'];
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
}>;

export type GQGetFootprintSnapshotDescriptionQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    snapshotDescription: string | null;
  } | null;
};

export type GQGetBetterCompanyQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type GQGetBetterCompanyQuery = {
  __typename?: 'Query';
  companyForDashboard: {
    __typename?: 'Company';
    id: string;
    betterCompanyId: string | null;
  } | null;
};

export type GQUserContactFieldsFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  displayName: string;
  email: string;
  createdAt: Date;
  loginActivated: boolean;
};

export type GQForbiddenPageDataQueryVariables = Exact<{
  missingPermission: GQPermissionType;
}>;

export type GQForbiddenPageDataQuery = {
  __typename?: 'Query';
  adminUsers: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
    email: string;
    createdAt: Date;
    loginActivated: boolean;
  }>;
  pendingUserPermissionRequestsForUser: Array<{
    __typename?: 'UserPermissionRequest';
    id: string;
    orgId: string;
    name: string;
    email: string;
    state: GQUserPermissionRequestState;
    userId: string | null;
    responderUser: string | null;
    respondedAt: Date | null;
    creatorOrgId: string | null;
    creatorUser: string | null;
    permission: GQPermissionType | null;
  }>;
};

export type GQCreateUserPermissionRequestMutationVariables = Exact<{
  input: GQCreateUserPermissionRequestInput;
}>;

export type GQCreateUserPermissionRequestMutation = {
  __typename?: 'Mutation';
  createUserPermissionRequest: {
    __typename?: 'CreateUserPermissionRequestPayload';
    userPermissionRequest: {
      __typename?: 'UserPermissionRequest';
      id: string;
      orgId: string;
      name: string;
      email: string;
      state: GQUserPermissionRequestState;
      userId: string | null;
      responderUser: string | null;
      respondedAt: Date | null;
      creatorOrgId: string | null;
      creatorUser: string | null;
      permission: GQPermissionType | null;
    } | null;
  } | null;
};

export type GQRequestHelpMutationVariables = Exact<{
  input: GQRequestHelpInput;
}>;

export type GQRequestHelpMutation = {
  __typename?: 'Mutation';
  requestHelp: { __typename?: 'RequestHelpPayload'; status: string };
};

export type GQActivityDataUserUploadFragment = {
  __typename?: 'UserUpload';
  id: string;
  category: GQFileCategory;
  name: string;
  createdAt: Date;
  revisionRoot: string | null;
  deletedAt: Date | null;
  uploader: { __typename?: 'User'; id: string; name: string } | null;
  userUploadTask: {
    __typename?: 'UserUploadTask';
    id: string;
    datasource: { __typename?: 'Datasource'; id: string; name: string };
  } | null;
};

export type GQActivityContextMetadataQueryVariables = Exact<{
  input: GQBiActivityQueryContextInput;
}>;

export type GQActivityContextMetadataQuery = {
  __typename?: 'Query';
  activityContextMetadata: {
    __typename?: 'ActivityContextMetadata';
    uploads: Array<{
      __typename?: 'UserUpload';
      id: string;
      category: GQFileCategory;
      name: string;
      createdAt: Date;
      revisionRoot: string | null;
      deletedAt: Date | null;
      uploader: { __typename?: 'User'; id: string; name: string } | null;
      userUploadTask: {
        __typename?: 'UserUploadTask';
        id: string;
        datasource: { __typename?: 'Datasource'; id: string; name: string };
      } | null;
    }>;
  };
};

export type GQGenerateTemplateDownloadUrlForOrgMutationVariables = Exact<{
  input: GQGenerateTemplateDownloadUrlForOrgDatasetsInput;
}>;

export type GQGenerateTemplateDownloadUrlForOrgMutation = {
  __typename?: 'Mutation';
  generateTemplateDownloadUrlForOrgDatasets: {
    __typename?: 'GenerateTemplateDownloadUrlForOrgDatasetsPayload';
    templateDownloadUrl: string;
  };
};

export type GQAuditReportMetadataQueryVariables = Exact<{
  input: GQAuditReportMetadataInput;
}>;

export type GQAuditReportMetadataQuery = {
  __typename?: 'Query';
  auditReportMetadata: {
    __typename?: 'AuditReportMetadataPayload';
    columns: Array<{
      __typename?: 'AuditReportColumn';
      column: string;
      title: string;
      description: string;
    }>;
  };
};

export type GQCreateCtsFormMutationVariables = Exact<{
  input: GQCreateCtsFormInput;
}>;

export type GQCreateCtsFormMutation = {
  __typename?: 'Mutation';
  createCtsForm: {
    __typename?: 'UserUploadTask';
    id: string;
    createdAt: Date;
    state: GQUserUploadTaskState;
    importState: GQDatasourceImportState;
    approvalStatus: GQApprovalStatus;
    isDatasetComplete: boolean | null;
    lockState: GQApprovalTargetLockState;
    assignees: Array<{
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    }>;
    assignee: {
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    } | null;
    measurementTask: {
      __typename?: 'MeasurementTaskFlat';
      id: string;
      status: GQTaskWatershedProcessState;
      statusSimplified: GQTaskWatershedProcessStateSimplified;
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      coverageInterval: YMInterval;
      coverageStartDate: Date;
      coverageEndDate: Date;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
      }>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        deadline: Date;
      } | null;
    };
    datasetRequirements: Array<{
      __typename?: 'ClimateProgramProjectRequirementDataset';
      id: string;
      additionalNotesMd: string | null;
      climateProgramProject: {
        __typename: 'ClimateProgramProject';
        name: string;
        id: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          kind: GQClimateProgramProjectKind;
          measurementObjectCopy: string | null;
          routeType: string | null;
          name: string;
        } | null;
      };
    }>;
    userUploads: {
      __typename?: 'UserUploadConnection';
      edges: Array<{
        __typename?: 'UserUploadEdge';
        node: {
          __typename?: 'UserUpload';
          id: string;
          name: string;
          status: GQUserUploadStatus;
          processingMode: GQUserUploadProcessingMode | null;
          userVisibleErrorMessage: string | null;
          createdAt: Date;
          latestAdeIdForSDIFacilitiesFlow: string | null;
          description: string | null;
          revisionRoot: string | null;
          processingWorkflowId: string | null;
          isBeingValueMapped: boolean;
          actualUserUploadId: string | null;
          associatedUtilityCount: number;
          userUploadKind: GQUserUploadCandidateKind | null;
          userVisibleAttempt: {
            __typename?: 'UserUploadAttempt';
            id: string;
            name: string;
            size: number;
            remoteWritten: boolean;
            createdAt: Date;
            userUploadSource: GQUserUploadSource | null;
            uploader: { __typename?: 'User'; id: string; name: string } | null;
            userUploadedTables: Array<{
              __typename?: 'UserUploadedTable';
              id: string;
              sheetName: string | null;
              sheetIndex: number | null;
              errorMessage: string | null;
              errorType: string | null;
              numRows: number | null;
              status: GQUserUploadedTableStatus;
              schema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              uirSchema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              parseConfig: {
                __typename?: 'UserUploadedTableParseConfig';
                noHeader: boolean;
                skipTopNRows: number;
                skipBottomNRows: number;
                meltIdVars: Array<string | null> | null;
              } | null;
              roboCleanerResult: {
                __typename?: 'UserUploadedTableRoboCleanerResult';
                skipTopNRows: number;
              } | null;
              userReviewMetadata: {
                __typename?: 'UserUploadedTableUserReviewMetadata';
                headerRowIndex: number;
              } | null;
              parentFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              };
              dataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataUnshiftedPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
            }>;
            latestUutsIfRevisionExists: Array<{
              __typename?: 'UserUploadedTable';
              id: string;
              sheetName: string | null;
              sheetIndex: number | null;
              errorMessage: string | null;
              errorType: string | null;
              numRows: number | null;
              status: GQUserUploadedTableStatus;
              schema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              uirSchema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              parseConfig: {
                __typename?: 'UserUploadedTableParseConfig';
                noHeader: boolean;
                skipTopNRows: number;
                skipBottomNRows: number;
                meltIdVars: Array<string | null> | null;
              } | null;
              roboCleanerResult: {
                __typename?: 'UserUploadedTableRoboCleanerResult';
                skipTopNRows: number;
              } | null;
              userReviewMetadata: {
                __typename?: 'UserUploadedTableUserReviewMetadata';
                headerRowIndex: number;
              } | null;
              parentFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              };
              dataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataUnshiftedPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
            }> | null;
          } | null;
          userUploadTask: {
            __typename?: 'UserUploadTask';
            id: string;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
            };
          } | null;
          transformsIncludingDeleted: Array<{
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            deletedAt: Date | null;
            sql: string;
            transformTarget: GQCustomerTargetSchemaTransformTarget;
            businessActivityTypeName: string | null;
            businessActivityTypeVersionId: string | null;
            businessActivityTypeVersion: {
              __typename?: 'BusinessActivityTypeVersion';
              id: string;
              name: string;
            } | null;
            runs: Array<{
              __typename?: 'CustomerTargetSchemaTransformRun';
              id: string;
              sql: string;
              error: string | null;
              createdAt: Date;
              transform: {
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                transformTarget: GQCustomerTargetSchemaTransformTarget;
                businessActivityTypeName: string | null;
              };
              input: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
                category: GQFileCategory;
                revisionRootFileMetadata: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                } | null;
              };
              output: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
              standardizedOutput: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
              warnings: Array<{
                __typename?: 'ValidationWarning';
                message: string;
                blocking: boolean;
              }>;
              runner: { __typename?: 'User'; id: string; name: string };
            }>;
          }>;
        } | null;
      } | null>;
    } | null;
    issues: {
      __typename?: 'DataIssueConnection';
      edges: Array<{
        __typename?: 'DataIssueEdge';
        node: {
          __typename?: 'DataIssue';
          id: string;
          title: string;
          description: string | null;
          state: GQDataIssueState;
          createdAt: Date;
          isCustomerInitiated: boolean;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            completedAt: Date | null;
          };
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                message: string;
                isPublished: boolean;
                createdAt: Date;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    } | null;
    valueMappingTask: {
      __typename?: 'ValueMappingTask';
      id: string;
      mappingRuleId: string;
    } | null;
    valueMappingTasks: Array<{
      __typename?: 'ValueMappingTask';
      id: string;
      mappingRuleId: string;
      status: GQTaskWatershedProcessState;
    }>;
    approvers: Array<{
      __typename?: 'UserWithApprovalStatus';
      approvalStatus: GQApprovalStatus | null;
      user: {
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      };
    }>;
    ctsForm: {
      __typename?: 'CtsForm';
      id: string;
      ctsVersionId: string;
      customerTargetSchemaId: string;
      datasetId: string;
      title: string | null;
      description: string | null;
      entries: Array<{
        __typename?: 'CtsFormEntry';
        id: string;
        data: any;
        createdBy: { __typename?: 'User'; id: string; displayName: string };
      }>;
    } | null;
  };
};

export type GQUpdateCtsFormMutationVariables = Exact<{
  input: GQUpdateCtsFormInput;
}>;

export type GQUpdateCtsFormMutation = {
  __typename?: 'Mutation';
  updateCtsForm: {
    __typename?: 'UserUploadTask';
    id: string;
    createdAt: Date;
    state: GQUserUploadTaskState;
    importState: GQDatasourceImportState;
    approvalStatus: GQApprovalStatus;
    isDatasetComplete: boolean | null;
    lockState: GQApprovalTargetLockState;
    assignees: Array<{
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    }>;
    assignee: {
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    } | null;
    measurementTask: {
      __typename?: 'MeasurementTaskFlat';
      id: string;
      status: GQTaskWatershedProcessState;
      statusSimplified: GQTaskWatershedProcessStateSimplified;
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      coverageInterval: YMInterval;
      coverageStartDate: Date;
      coverageEndDate: Date;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
      }>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        deadline: Date;
      } | null;
    };
    datasetRequirements: Array<{
      __typename?: 'ClimateProgramProjectRequirementDataset';
      id: string;
      additionalNotesMd: string | null;
      climateProgramProject: {
        __typename: 'ClimateProgramProject';
        name: string;
        id: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          kind: GQClimateProgramProjectKind;
          measurementObjectCopy: string | null;
          routeType: string | null;
          name: string;
        } | null;
      };
    }>;
    userUploads: {
      __typename?: 'UserUploadConnection';
      edges: Array<{
        __typename?: 'UserUploadEdge';
        node: {
          __typename?: 'UserUpload';
          id: string;
          name: string;
          status: GQUserUploadStatus;
          processingMode: GQUserUploadProcessingMode | null;
          userVisibleErrorMessage: string | null;
          createdAt: Date;
          latestAdeIdForSDIFacilitiesFlow: string | null;
          description: string | null;
          revisionRoot: string | null;
          processingWorkflowId: string | null;
          isBeingValueMapped: boolean;
          actualUserUploadId: string | null;
          associatedUtilityCount: number;
          userUploadKind: GQUserUploadCandidateKind | null;
          userVisibleAttempt: {
            __typename?: 'UserUploadAttempt';
            id: string;
            name: string;
            size: number;
            remoteWritten: boolean;
            createdAt: Date;
            userUploadSource: GQUserUploadSource | null;
            uploader: { __typename?: 'User'; id: string; name: string } | null;
            userUploadedTables: Array<{
              __typename?: 'UserUploadedTable';
              id: string;
              sheetName: string | null;
              sheetIndex: number | null;
              errorMessage: string | null;
              errorType: string | null;
              numRows: number | null;
              status: GQUserUploadedTableStatus;
              schema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              uirSchema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              parseConfig: {
                __typename?: 'UserUploadedTableParseConfig';
                noHeader: boolean;
                skipTopNRows: number;
                skipBottomNRows: number;
                meltIdVars: Array<string | null> | null;
              } | null;
              roboCleanerResult: {
                __typename?: 'UserUploadedTableRoboCleanerResult';
                skipTopNRows: number;
              } | null;
              userReviewMetadata: {
                __typename?: 'UserUploadedTableUserReviewMetadata';
                headerRowIndex: number;
              } | null;
              parentFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              };
              dataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataUnshiftedPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
            }>;
            latestUutsIfRevisionExists: Array<{
              __typename?: 'UserUploadedTable';
              id: string;
              sheetName: string | null;
              sheetIndex: number | null;
              errorMessage: string | null;
              errorType: string | null;
              numRows: number | null;
              status: GQUserUploadedTableStatus;
              schema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              uirSchema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              parseConfig: {
                __typename?: 'UserUploadedTableParseConfig';
                noHeader: boolean;
                skipTopNRows: number;
                skipBottomNRows: number;
                meltIdVars: Array<string | null> | null;
              } | null;
              roboCleanerResult: {
                __typename?: 'UserUploadedTableRoboCleanerResult';
                skipTopNRows: number;
              } | null;
              userReviewMetadata: {
                __typename?: 'UserUploadedTableUserReviewMetadata';
                headerRowIndex: number;
              } | null;
              parentFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              };
              dataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataUnshiftedPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
            }> | null;
          } | null;
          userUploadTask: {
            __typename?: 'UserUploadTask';
            id: string;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
            };
          } | null;
          transformsIncludingDeleted: Array<{
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            deletedAt: Date | null;
            sql: string;
            transformTarget: GQCustomerTargetSchemaTransformTarget;
            businessActivityTypeName: string | null;
            businessActivityTypeVersionId: string | null;
            businessActivityTypeVersion: {
              __typename?: 'BusinessActivityTypeVersion';
              id: string;
              name: string;
            } | null;
            runs: Array<{
              __typename?: 'CustomerTargetSchemaTransformRun';
              id: string;
              sql: string;
              error: string | null;
              createdAt: Date;
              transform: {
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                transformTarget: GQCustomerTargetSchemaTransformTarget;
                businessActivityTypeName: string | null;
              };
              input: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
                category: GQFileCategory;
                revisionRootFileMetadata: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                } | null;
              };
              output: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
              standardizedOutput: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
              warnings: Array<{
                __typename?: 'ValidationWarning';
                message: string;
                blocking: boolean;
              }>;
              runner: { __typename?: 'User'; id: string; name: string };
            }>;
          }>;
        } | null;
      } | null>;
    } | null;
    issues: {
      __typename?: 'DataIssueConnection';
      edges: Array<{
        __typename?: 'DataIssueEdge';
        node: {
          __typename?: 'DataIssue';
          id: string;
          title: string;
          description: string | null;
          state: GQDataIssueState;
          createdAt: Date;
          isCustomerInitiated: boolean;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            completedAt: Date | null;
          };
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                message: string;
                isPublished: boolean;
                createdAt: Date;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    } | null;
    valueMappingTask: {
      __typename?: 'ValueMappingTask';
      id: string;
      mappingRuleId: string;
    } | null;
    valueMappingTasks: Array<{
      __typename?: 'ValueMappingTask';
      id: string;
      mappingRuleId: string;
      status: GQTaskWatershedProcessState;
    }>;
    approvers: Array<{
      __typename?: 'UserWithApprovalStatus';
      approvalStatus: GQApprovalStatus | null;
      user: {
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      };
    }>;
    ctsForm: {
      __typename?: 'CtsForm';
      id: string;
      ctsVersionId: string;
      customerTargetSchemaId: string;
      datasetId: string;
      title: string | null;
      description: string | null;
      entries: Array<{
        __typename?: 'CtsFormEntry';
        id: string;
        data: any;
        createdBy: { __typename?: 'User'; id: string; displayName: string };
      }>;
    } | null;
  };
};

export type GQValidateFormAgainstCtsMutationVariables = Exact<{
  input: GQValidateFormAgainstCtsInput;
}>;

export type GQValidateFormAgainstCtsMutation = {
  __typename?: 'Mutation';
  validateFormAgainstCts: {
    __typename?: 'ValidateFormAgainstCtsPayload';
    errors: Array<{
      __typename?: 'ValidateFormAgainstCtsErrorPayload';
      errorMessage: string;
    }>;
  };
};

export type GQCustomerTargetSchemaVersionForSurveyQueryVariables = Exact<{
  customerTargetSchemaId: Scalars['ID']['input'];
  ctsVersionId: Scalars['ID']['input'];
  datasetId: Scalars['ID']['input'];
  datasourceId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQCustomerTargetSchemaVersionForSurveyQuery = {
  __typename?: 'Query';
  customerTargetSchemaVersion: {
    __typename?: 'CustomerTargetSchemaVersion';
    id: string;
    orgSchemaJson: any;
  };
};

export type GQGetCtsFormQueryVariables = Exact<{
  ctsFormId: Scalars['ID']['input'];
}>;

export type GQGetCtsFormQuery = {
  __typename?: 'Query';
  ctsForm: {
    __typename?: 'CtsForm';
    id: string;
    ctsVersionId: string;
    customerTargetSchemaId: string;
    datasetId: string;
    title: string | null;
    description: string | null;
    closedAt: Date | null;
    dataset: { __typename?: 'Dataset'; id: string; name: string };
  };
};

export type GQCreateCtsFormEntryMutationVariables = Exact<{
  input: GQCreateCtsFormEntryInput;
}>;

export type GQCreateCtsFormEntryMutation = {
  __typename?: 'Mutation';
  createCtsFormEntry: { __typename?: 'CtsFormEntry'; id: string };
};

export type GQSubmitAndCloseCtsFormMutationVariables = Exact<{
  ctsFormId: Scalars['ID']['input'];
}>;

export type GQSubmitAndCloseCtsFormMutation = {
  __typename?: 'Mutation';
  submitAndCloseCtsForm: {
    __typename?: 'UserUploadTask';
    id: string;
    createdAt: Date;
    state: GQUserUploadTaskState;
    importState: GQDatasourceImportState;
    approvalStatus: GQApprovalStatus;
    isDatasetComplete: boolean | null;
    lockState: GQApprovalTargetLockState;
    assignees: Array<{
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    }>;
    assignee: {
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    } | null;
    measurementTask: {
      __typename?: 'MeasurementTaskFlat';
      id: string;
      status: GQTaskWatershedProcessState;
      statusSimplified: GQTaskWatershedProcessStateSimplified;
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      coverageInterval: YMInterval;
      coverageStartDate: Date;
      coverageEndDate: Date;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
      }>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        deadline: Date;
      } | null;
    };
    datasetRequirements: Array<{
      __typename?: 'ClimateProgramProjectRequirementDataset';
      id: string;
      additionalNotesMd: string | null;
      climateProgramProject: {
        __typename: 'ClimateProgramProject';
        name: string;
        id: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          kind: GQClimateProgramProjectKind;
          measurementObjectCopy: string | null;
          routeType: string | null;
          name: string;
        } | null;
      };
    }>;
    userUploads: {
      __typename?: 'UserUploadConnection';
      edges: Array<{
        __typename?: 'UserUploadEdge';
        node: {
          __typename?: 'UserUpload';
          id: string;
          name: string;
          status: GQUserUploadStatus;
          processingMode: GQUserUploadProcessingMode | null;
          userVisibleErrorMessage: string | null;
          createdAt: Date;
          latestAdeIdForSDIFacilitiesFlow: string | null;
          description: string | null;
          revisionRoot: string | null;
          processingWorkflowId: string | null;
          isBeingValueMapped: boolean;
          actualUserUploadId: string | null;
          associatedUtilityCount: number;
          userUploadKind: GQUserUploadCandidateKind | null;
          userVisibleAttempt: {
            __typename?: 'UserUploadAttempt';
            id: string;
            name: string;
            size: number;
            remoteWritten: boolean;
            createdAt: Date;
            userUploadSource: GQUserUploadSource | null;
            uploader: { __typename?: 'User'; id: string; name: string } | null;
            userUploadedTables: Array<{
              __typename?: 'UserUploadedTable';
              id: string;
              sheetName: string | null;
              sheetIndex: number | null;
              errorMessage: string | null;
              errorType: string | null;
              numRows: number | null;
              status: GQUserUploadedTableStatus;
              schema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              uirSchema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              parseConfig: {
                __typename?: 'UserUploadedTableParseConfig';
                noHeader: boolean;
                skipTopNRows: number;
                skipBottomNRows: number;
                meltIdVars: Array<string | null> | null;
              } | null;
              roboCleanerResult: {
                __typename?: 'UserUploadedTableRoboCleanerResult';
                skipTopNRows: number;
              } | null;
              userReviewMetadata: {
                __typename?: 'UserUploadedTableUserReviewMetadata';
                headerRowIndex: number;
              } | null;
              parentFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              };
              dataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataUnshiftedPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
            }>;
            latestUutsIfRevisionExists: Array<{
              __typename?: 'UserUploadedTable';
              id: string;
              sheetName: string | null;
              sheetIndex: number | null;
              errorMessage: string | null;
              errorType: string | null;
              numRows: number | null;
              status: GQUserUploadedTableStatus;
              schema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              uirSchema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              parseConfig: {
                __typename?: 'UserUploadedTableParseConfig';
                noHeader: boolean;
                skipTopNRows: number;
                skipBottomNRows: number;
                meltIdVars: Array<string | null> | null;
              } | null;
              roboCleanerResult: {
                __typename?: 'UserUploadedTableRoboCleanerResult';
                skipTopNRows: number;
              } | null;
              userReviewMetadata: {
                __typename?: 'UserUploadedTableUserReviewMetadata';
                headerRowIndex: number;
              } | null;
              parentFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              };
              dataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataUnshiftedPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
            }> | null;
          } | null;
          userUploadTask: {
            __typename?: 'UserUploadTask';
            id: string;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
            };
          } | null;
          transformsIncludingDeleted: Array<{
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            deletedAt: Date | null;
            sql: string;
            transformTarget: GQCustomerTargetSchemaTransformTarget;
            businessActivityTypeName: string | null;
            businessActivityTypeVersionId: string | null;
            businessActivityTypeVersion: {
              __typename?: 'BusinessActivityTypeVersion';
              id: string;
              name: string;
            } | null;
            runs: Array<{
              __typename?: 'CustomerTargetSchemaTransformRun';
              id: string;
              sql: string;
              error: string | null;
              createdAt: Date;
              transform: {
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                transformTarget: GQCustomerTargetSchemaTransformTarget;
                businessActivityTypeName: string | null;
              };
              input: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
                category: GQFileCategory;
                revisionRootFileMetadata: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                } | null;
              };
              output: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
              standardizedOutput: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
              warnings: Array<{
                __typename?: 'ValidationWarning';
                message: string;
                blocking: boolean;
              }>;
              runner: { __typename?: 'User'; id: string; name: string };
            }>;
          }>;
        } | null;
      } | null>;
    } | null;
    issues: {
      __typename?: 'DataIssueConnection';
      edges: Array<{
        __typename?: 'DataIssueEdge';
        node: {
          __typename?: 'DataIssue';
          id: string;
          title: string;
          description: string | null;
          state: GQDataIssueState;
          createdAt: Date;
          isCustomerInitiated: boolean;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            completedAt: Date | null;
          };
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                message: string;
                isPublished: boolean;
                createdAt: Date;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    } | null;
    valueMappingTask: {
      __typename?: 'ValueMappingTask';
      id: string;
      mappingRuleId: string;
    } | null;
    valueMappingTasks: Array<{
      __typename?: 'ValueMappingTask';
      id: string;
      mappingRuleId: string;
      status: GQTaskWatershedProcessState;
    }>;
    approvers: Array<{
      __typename?: 'UserWithApprovalStatus';
      approvalStatus: GQApprovalStatus | null;
      user: {
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      };
    }>;
    ctsForm: {
      __typename?: 'CtsForm';
      id: string;
      ctsVersionId: string;
      customerTargetSchemaId: string;
      datasetId: string;
      title: string | null;
      description: string | null;
      entries: Array<{
        __typename?: 'CtsFormEntry';
        id: string;
        data: any;
        createdBy: { __typename?: 'User'; id: string; displayName: string };
      }>;
    } | null;
  };
};

export type GQUploadSchemaDetailVersionFragment = {
  __typename?: 'CustomerTargetSchemaVersion';
  id: string;
  schemaJson: any;
  semverVersion: string;
  versionNotesMd: string;
  formattingNotesMd: string | null;
  deprecatedAt: Date | null;
  plannedRemovedAt: Date | null;
  removedAt: Date | null;
  createdAt: Date;
  orgDatasetExampleData: Array<{
    __typename?: 'IngestionExampleData';
    id: string;
    title: string;
    datasetId: string | null;
    columns: Array<{
      __typename?: 'IngestionExampleDataColumn';
      fieldName: string;
      title: string;
      description: string;
      examples: Array<string>;
      required: boolean;
      optionalToMap: boolean | null;
      defaultValue: any | null;
    }>;
    rows: Array<{
      __typename?: 'IngestionExampleDataRow';
      fields: Array<{
        __typename?: 'IngestionExampleDataRowField';
        fieldName: string;
        value: string;
      }>;
    }>;
  }>;
  transforms: Array<{
    __typename?: 'CustomerTargetSchemaTransform';
    id: string;
    businessActivityTypeName: string | null;
  }>;
};

export type GQUploadSchemaDetailQueryVariables = Exact<{
  datasetId: Scalars['ID']['input'];
  schemaId: Scalars['ID']['input'];
}>;

export type GQUploadSchemaDetailQuery = {
  __typename?: 'Query';
  dataset: {
    __typename?: 'Dataset';
    id: string;
    name: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      instructions: {
        __typename?: 'InstructionsBundle';
        sdiInstructionsIntroMd: string | null;
      } | null;
    } | null;
  } | null;
  customerTargetSchema: {
    __typename?: 'CustomerTargetSchema';
    id: string;
    name: string;
    versions: Array<{
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      schemaJson: any;
      semverVersion: string;
      versionNotesMd: string;
      formattingNotesMd: string | null;
      deprecatedAt: Date | null;
      plannedRemovedAt: Date | null;
      removedAt: Date | null;
      createdAt: Date;
      orgDatasetExampleData: Array<{
        __typename?: 'IngestionExampleData';
        id: string;
        title: string;
        datasetId: string | null;
        columns: Array<{
          __typename?: 'IngestionExampleDataColumn';
          fieldName: string;
          title: string;
          description: string;
          examples: Array<string>;
          required: boolean;
          optionalToMap: boolean | null;
          defaultValue: any | null;
        }>;
        rows: Array<{
          __typename?: 'IngestionExampleDataRow';
          fields: Array<{
            __typename?: 'IngestionExampleDataRowField';
            fieldName: string;
            value: string;
          }>;
        }>;
      }>;
      transforms: Array<{
        __typename?: 'CustomerTargetSchemaTransform';
        id: string;
        businessActivityTypeName: string | null;
      }>;
    }>;
    publishedVersions: Array<{
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      schemaJson: any;
      semverVersion: string;
      versionNotesMd: string;
      formattingNotesMd: string | null;
      deprecatedAt: Date | null;
      plannedRemovedAt: Date | null;
      removedAt: Date | null;
      createdAt: Date;
      orgDatasetExampleData: Array<{
        __typename?: 'IngestionExampleData';
        id: string;
        title: string;
        datasetId: string | null;
        columns: Array<{
          __typename?: 'IngestionExampleDataColumn';
          fieldName: string;
          title: string;
          description: string;
          examples: Array<string>;
          required: boolean;
          optionalToMap: boolean | null;
          defaultValue: any | null;
        }>;
        rows: Array<{
          __typename?: 'IngestionExampleDataRow';
          fields: Array<{
            __typename?: 'IngestionExampleDataRowField';
            fieldName: string;
            value: string;
          }>;
        }>;
      }>;
      transforms: Array<{
        __typename?: 'CustomerTargetSchemaTransform';
        id: string;
        businessActivityTypeName: string | null;
      }>;
    }>;
  };
};

export type GQUploadSchemaExplorerDatasetFragment = {
  __typename?: 'Dataset';
  id: string;
  name: string;
  canonicalDataset: {
    __typename?: 'CanonicalDataset';
    id: string;
    customerTargetSchemas: Array<{
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
    }>;
  } | null;
};

export type GQUploadSchemaExplorerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQUploadSchemaExplorerQuery = {
  __typename?: 'Query';
  datasets: Array<{
    __typename?: 'Dataset';
    id: string;
    name: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      customerTargetSchemas: Array<{
        __typename?: 'CustomerTargetSchema';
        id: string;
        name: string;
      }>;
    } | null;
  }>;
};

export type GQCreateOneSchemaSessionForApiUploadMutationVariables = Exact<{
  input: GQCreateOneSchemaSessionForApiUploadInput;
}>;

export type GQCreateOneSchemaSessionForApiUploadMutation = {
  __typename?: 'Mutation';
  createOneSchemaSessionForApiUpload: {
    __typename?: 'CreateOneSchemaSessionForApiUploadPayload';
    oneSchemaEmbedId: string;
    oneSchemaSessionToken: string;
    oneSchemaEmbedStatus: string;
    oneSchemaEmbedUserJwt: string;
    fileMetadataId: string;
    fileName: string;
    signedUrl: string;
    userUploadTaskId: string;
    measurementProjectId: string;
  } | null;
};

export type GQGroupedAuditLogEntryFieldsFragment = {
  __typename?: 'GroupedAuditLogEntry';
  id: string;
  createdAt: Date;
  eventKind: string;
  numEvents: number;
  firstEventMetadata: any | null;
  objectKind: string | null;
  user: { __typename?: 'User'; id: string; displayName: string } | null;
};

export type GQGroupedAuditLogsQueryVariables = Exact<{
  eventKinds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  filters: InputMaybe<Scalars['UntypedData']['input']>;
}>;

export type GQGroupedAuditLogsQuery = {
  __typename?: 'Query';
  groupedAuditLogs: {
    __typename?: 'GroupedAuditLogConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: Array<{
      __typename?: 'GroupedAuditLogEdge';
      node: {
        __typename?: 'GroupedAuditLogEntry';
        id: string;
        createdAt: Date;
        eventKind: string;
        numEvents: number;
        firstEventMetadata: any | null;
        objectKind: string | null;
        user: { __typename?: 'User'; id: string; displayName: string } | null;
      } | null;
    } | null>;
  };
};

export type GQAddCompaniesToPeerCompanyGroupDashboardMutationVariables = Exact<{
  input: GQAddCompaniesToPeerCompanyGroupsInput;
}>;

export type GQAddCompaniesToPeerCompanyGroupDashboardMutation = {
  __typename?: 'Mutation';
  addCompaniesToPeerCompanyGroups: {
    __typename?: 'AddCompaniesToPeerCompanyGroupsPayload';
    peerCompanyGroups: Array<{
      __typename?: 'PeerCompanyGroup';
      id: string;
      name: string;
      createdAt: Date;
      shouldHideFromFootprintOverview: boolean;
      revenueForRevenueAtRisk: number | null;
      members: Array<{
        __typename?: 'PeerCompanyGroupMember';
        id: string;
        revenueAtRisk: number | null;
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          description: string | null;
          sustainabilityWebsiteUrl: string | null;
          naicsCode: string | null;
          fullLogoUrl: string | null;
          disclosuresV2: Array<{
            __typename?: 'Disclosure';
            id: string;
            publicDisclosure: {
              __typename?: 'PublicDisclosure';
              id: string;
              reportType: GQExternalReportType;
              publishingYear: number;
              reportingYears: Array<number>;
              publicUrl: string;
              qualityScore: GQDisclosureQualityScore | null;
              externalScore: string | null;
              cdpVendorData: {
                __typename?: 'CdpVendorData';
                id: string;
                publishingYear: number;
                reportingYear: number;
                scope1Nonzero: boolean;
                scope2Nonzero: boolean;
                scope3Nonzero: boolean;
                totalEmissionsNonzero: boolean;
                scope301Or302Nonzero: boolean;
                scope1Verified: boolean;
                scope2Verified: boolean;
                scope3Verified: boolean;
                pctEvaluationStatusesMatchResponse: number;
                disclosureQualityScore: GQDisclosureQualityScore;
                scope301EvaluationStatus: GQScope3EvaluationStatus;
                scope302EvaluationStatus: GQScope3EvaluationStatus;
                scope303EvaluationStatus: GQScope3EvaluationStatus;
                scope304EvaluationStatus: GQScope3EvaluationStatus;
                scope305EvaluationStatus: GQScope3EvaluationStatus;
                scope306EvaluationStatus: GQScope3EvaluationStatus;
                scope307EvaluationStatus: GQScope3EvaluationStatus;
                scope308EvaluationStatus: GQScope3EvaluationStatus;
                scope309EvaluationStatus: GQScope3EvaluationStatus;
                scope310EvaluationStatus: GQScope3EvaluationStatus;
                scope311EvaluationStatus: GQScope3EvaluationStatus;
                scope312EvaluationStatus: GQScope3EvaluationStatus;
                scope313EvaluationStatus: GQScope3EvaluationStatus;
                scope314EvaluationStatus: GQScope3EvaluationStatus;
                scope315EvaluationStatus: GQScope3EvaluationStatus;
                scope316EvaluationStatus: GQScope3EvaluationStatus;
                scope317EvaluationStatus: GQScope3EvaluationStatus;
              } | null;
            } | null;
            historicalEmissionsYears: Array<{
              __typename?: 'EmissionsYear';
              id: string;
              publicDisclosureId: string | null;
              reportingYear: number;
              numEmployees: number | null;
              revenue: number | null;
              revenueCurrency: string | null;
              revenueUsd: number | null;
              scope1: number | null;
              scope2Market: number | null;
              scope2Location: number | null;
              scope3: number | null;
              scope301: number | null;
              scope302: number | null;
              scope303: number | null;
              scope304: number | null;
              scope305: number | null;
              scope306: number | null;
              scope307: number | null;
              scope308: number | null;
              scope309: number | null;
              scope310: number | null;
              scope311: number | null;
              scope312: number | null;
              scope313: number | null;
              scope314: number | null;
              scope315: number | null;
              scope316: number | null;
              scope317: number | null;
              units: GQCompanyEmissionsUnits;
              percentageCleanEnergy: number | null;
              totalMwh: number | null;
              scope123RevenueIntensityKgco2e: {
                __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
                id: string;
                scope1: number | null;
                scope2Market: number | null;
                scope2Location: number | null;
                scope3: number | null;
              } | null;
            }> | null;
            climateCommitments: Array<
              | {
                  __typename?: 'CarbonNeutralCommitment';
                  targetYear: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'CleanEnergyCommitment';
                  targetYear: number | null;
                  targetPercentageCleanEnergy: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'NetZeroCommitment';
                  targetYear: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'ScienceBasedTargetCommitment';
                  submittedToSBTi: boolean;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                  commitment: {
                    __typename?: 'SBTCommitment';
                    id: string;
                    stage: GQSbtCommitmentStage | null;
                    targetClassification: GQSbtTargetClassification | null;
                    nearTermTargetYear: number | null;
                    longTermTargetYear: number | null;
                    baselineYear: number | null;
                    netZeroCommitted: boolean | null;
                    commitmentType: GQSbtCommitmentType | null;
                    commitmentDeadline: Date | null;
                    netZeroTargetYear: number | null;
                  };
                }
            > | null;
          }>;
        };
      }>;
    }>;
  } | null;
};

export type GQPeerCompanyGroupsForAddMemberQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQPeerCompanyGroupsForAddMemberQuery = {
  __typename?: 'Query';
  peerCompanyGroups: Array<{
    __typename?: 'PeerCompanyGroup';
    id: string;
    name: string;
  }>;
};

export type GQCompanyBenchmarkQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQCompanyBenchmarkQuery = {
  __typename?: 'Query';
  company: {
    __typename?: 'Company';
    id: string;
    name: string;
    description: string | null;
    sustainabilityWebsiteUrl: string | null;
    naicsCode: string | null;
    fullLogoUrl: string | null;
    disclosuresV2: Array<{
      __typename?: 'Disclosure';
      id: string;
      publicDisclosure: {
        __typename?: 'PublicDisclosure';
        id: string;
        reportType: GQExternalReportType;
        publishingYear: number;
        reportingYears: Array<number>;
        publicUrl: string;
        qualityScore: GQDisclosureQualityScore | null;
        externalScore: string | null;
        cdpVendorData: {
          __typename?: 'CdpVendorData';
          id: string;
          publishingYear: number;
          reportingYear: number;
          scope1Nonzero: boolean;
          scope2Nonzero: boolean;
          scope3Nonzero: boolean;
          totalEmissionsNonzero: boolean;
          scope301Or302Nonzero: boolean;
          scope1Verified: boolean;
          scope2Verified: boolean;
          scope3Verified: boolean;
          pctEvaluationStatusesMatchResponse: number;
          disclosureQualityScore: GQDisclosureQualityScore;
          scope301EvaluationStatus: GQScope3EvaluationStatus;
          scope302EvaluationStatus: GQScope3EvaluationStatus;
          scope303EvaluationStatus: GQScope3EvaluationStatus;
          scope304EvaluationStatus: GQScope3EvaluationStatus;
          scope305EvaluationStatus: GQScope3EvaluationStatus;
          scope306EvaluationStatus: GQScope3EvaluationStatus;
          scope307EvaluationStatus: GQScope3EvaluationStatus;
          scope308EvaluationStatus: GQScope3EvaluationStatus;
          scope309EvaluationStatus: GQScope3EvaluationStatus;
          scope310EvaluationStatus: GQScope3EvaluationStatus;
          scope311EvaluationStatus: GQScope3EvaluationStatus;
          scope312EvaluationStatus: GQScope3EvaluationStatus;
          scope313EvaluationStatus: GQScope3EvaluationStatus;
          scope314EvaluationStatus: GQScope3EvaluationStatus;
          scope315EvaluationStatus: GQScope3EvaluationStatus;
          scope316EvaluationStatus: GQScope3EvaluationStatus;
          scope317EvaluationStatus: GQScope3EvaluationStatus;
        } | null;
      } | null;
      historicalEmissionsYears: Array<{
        __typename?: 'EmissionsYear';
        id: string;
        publicDisclosureId: string | null;
        reportingYear: number;
        numEmployees: number | null;
        revenue: number | null;
        revenueCurrency: string | null;
        revenueUsd: number | null;
        scope1: number | null;
        scope2Market: number | null;
        scope2Location: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
        units: GQCompanyEmissionsUnits;
        percentageCleanEnergy: number | null;
        totalMwh: number | null;
        scope123RevenueIntensityKgco2e: {
          __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
          id: string;
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
        } | null;
      }> | null;
      climateCommitments: Array<
        | {
            __typename?: 'CarbonNeutralCommitment';
            targetYear: number | null;
            id: string;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
          }
        | {
            __typename?: 'CleanEnergyCommitment';
            targetYear: number | null;
            targetPercentageCleanEnergy: number | null;
            id: string;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
          }
        | {
            __typename?: 'NetZeroCommitment';
            targetYear: number | null;
            id: string;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
          }
        | {
            __typename?: 'ScienceBasedTargetCommitment';
            submittedToSBTi: boolean;
            id: string;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
            commitment: {
              __typename?: 'SBTCommitment';
              id: string;
              stage: GQSbtCommitmentStage | null;
              targetClassification: GQSbtTargetClassification | null;
              nearTermTargetYear: number | null;
              longTermTargetYear: number | null;
              baselineYear: number | null;
              netZeroCommitted: boolean | null;
              commitmentType: GQSbtCommitmentType | null;
              commitmentDeadline: Date | null;
              netZeroTargetYear: number | null;
            };
          }
      > | null;
    }>;
  };
};

export type GQDeletePeerCompanyGroupMutationVariables = Exact<{
  input: GQDeletePeerCompanyGroupInput;
}>;

export type GQDeletePeerCompanyGroupMutation = {
  __typename?: 'Mutation';
  deletePeerCompanyGroup: {
    __typename?: 'DeletePeerCompanyGroupPayload';
    id: string;
  } | null;
};

export type GQUpdatePeerCompanyGroupMutationVariables = Exact<{
  input: GQUpdatePeerCompanyGroupInput;
}>;

export type GQUpdatePeerCompanyGroupMutation = {
  __typename?: 'Mutation';
  updatePeerCompanyGroup: {
    __typename?: 'PeerCompanyGroupPayload';
    id: string;
    peerCompanyGroup: {
      __typename?: 'PeerCompanyGroup';
      id: string;
      name: string;
      shouldHideFromFootprintOverview: boolean;
      revenueForRevenueAtRisk: number | null;
    };
  } | null;
};

export type GQCreatePeerCompanyGroupMutationVariables = Exact<{
  input: GQCreatePeerCompanyGroupInput;
}>;

export type GQCreatePeerCompanyGroupMutation = {
  __typename?: 'Mutation';
  createPeerCompanyGroup: {
    __typename?: 'PeerCompanyGroupPayload';
    id: string;
    peerCompanyGroup: {
      __typename?: 'PeerCompanyGroup';
      id: string;
      name: string;
      createdAt: Date;
      shouldHideFromFootprintOverview: boolean;
      revenueForRevenueAtRisk: number | null;
      members: Array<{
        __typename?: 'PeerCompanyGroupMember';
        id: string;
        revenueAtRisk: number | null;
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          description: string | null;
          sustainabilityWebsiteUrl: string | null;
          naicsCode: string | null;
          fullLogoUrl: string | null;
          disclosuresV2: Array<{
            __typename?: 'Disclosure';
            id: string;
            publicDisclosure: {
              __typename?: 'PublicDisclosure';
              id: string;
              reportType: GQExternalReportType;
              publishingYear: number;
              reportingYears: Array<number>;
              publicUrl: string;
              qualityScore: GQDisclosureQualityScore | null;
              externalScore: string | null;
              cdpVendorData: {
                __typename?: 'CdpVendorData';
                id: string;
                publishingYear: number;
                reportingYear: number;
                scope1Nonzero: boolean;
                scope2Nonzero: boolean;
                scope3Nonzero: boolean;
                totalEmissionsNonzero: boolean;
                scope301Or302Nonzero: boolean;
                scope1Verified: boolean;
                scope2Verified: boolean;
                scope3Verified: boolean;
                pctEvaluationStatusesMatchResponse: number;
                disclosureQualityScore: GQDisclosureQualityScore;
                scope301EvaluationStatus: GQScope3EvaluationStatus;
                scope302EvaluationStatus: GQScope3EvaluationStatus;
                scope303EvaluationStatus: GQScope3EvaluationStatus;
                scope304EvaluationStatus: GQScope3EvaluationStatus;
                scope305EvaluationStatus: GQScope3EvaluationStatus;
                scope306EvaluationStatus: GQScope3EvaluationStatus;
                scope307EvaluationStatus: GQScope3EvaluationStatus;
                scope308EvaluationStatus: GQScope3EvaluationStatus;
                scope309EvaluationStatus: GQScope3EvaluationStatus;
                scope310EvaluationStatus: GQScope3EvaluationStatus;
                scope311EvaluationStatus: GQScope3EvaluationStatus;
                scope312EvaluationStatus: GQScope3EvaluationStatus;
                scope313EvaluationStatus: GQScope3EvaluationStatus;
                scope314EvaluationStatus: GQScope3EvaluationStatus;
                scope315EvaluationStatus: GQScope3EvaluationStatus;
                scope316EvaluationStatus: GQScope3EvaluationStatus;
                scope317EvaluationStatus: GQScope3EvaluationStatus;
              } | null;
            } | null;
            historicalEmissionsYears: Array<{
              __typename?: 'EmissionsYear';
              id: string;
              publicDisclosureId: string | null;
              reportingYear: number;
              numEmployees: number | null;
              revenue: number | null;
              revenueCurrency: string | null;
              revenueUsd: number | null;
              scope1: number | null;
              scope2Market: number | null;
              scope2Location: number | null;
              scope3: number | null;
              scope301: number | null;
              scope302: number | null;
              scope303: number | null;
              scope304: number | null;
              scope305: number | null;
              scope306: number | null;
              scope307: number | null;
              scope308: number | null;
              scope309: number | null;
              scope310: number | null;
              scope311: number | null;
              scope312: number | null;
              scope313: number | null;
              scope314: number | null;
              scope315: number | null;
              scope316: number | null;
              scope317: number | null;
              units: GQCompanyEmissionsUnits;
              percentageCleanEnergy: number | null;
              totalMwh: number | null;
              scope123RevenueIntensityKgco2e: {
                __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
                id: string;
                scope1: number | null;
                scope2Market: number | null;
                scope2Location: number | null;
                scope3: number | null;
              } | null;
            }> | null;
            climateCommitments: Array<
              | {
                  __typename?: 'CarbonNeutralCommitment';
                  targetYear: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'CleanEnergyCommitment';
                  targetYear: number | null;
                  targetPercentageCleanEnergy: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'NetZeroCommitment';
                  targetYear: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'ScienceBasedTargetCommitment';
                  submittedToSBTi: boolean;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                  commitment: {
                    __typename?: 'SBTCommitment';
                    id: string;
                    stage: GQSbtCommitmentStage | null;
                    targetClassification: GQSbtTargetClassification | null;
                    nearTermTargetYear: number | null;
                    longTermTargetYear: number | null;
                    baselineYear: number | null;
                    netZeroCommitted: boolean | null;
                    commitmentType: GQSbtCommitmentType | null;
                    commitmentDeadline: Date | null;
                    netZeroTargetYear: number | null;
                  };
                }
            > | null;
          }>;
        };
      }>;
    };
  } | null;
};

export type GQActivityDataMainPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQActivityDataMainPageQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  organization: {
    __typename?: 'Organization';
    id: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      createdAt: Date;
      completedAt: Date | null;
      coverageInterval: YMInterval;
      active: boolean;
      status: GQMeasurementProjectStatus;
    }>;
  };
};

export type GQAssumptionSourceForMethodologyExplainerFragment = {
  __typename?: 'AssumptionSource';
  id: string;
  externalSourceNotes: string | null;
  userVisibleSourceUrl: string | null;
  hasMultiplierLicenseRestrictions: boolean | null;
};

export type GQAssumptionForMethodologyExplainerFragment = {
  __typename?: 'AssumptionRecordForDashboard';
  id: string;
  conversionDescription: string | null;
  conversionMultiplier: number | null;
  inputUnit: string | null;
  outputUnit: string | null;
  assumptionSource: {
    __typename?: 'AssumptionSource';
    id: string;
    externalSourceNotes: string | null;
    userVisibleSourceUrl: string | null;
    hasMultiplierLicenseRestrictions: boolean | null;
  };
  assumptionDescription: {
    __typename?: 'AssumptionDescription';
    id: string;
    updatedAt: Date;
    assumptionId: string;
    userVisibleConversionDescription: string;
  } | null;
};

export type GQUnitConversionForMethodologyExplainerFragment = {
  __typename?: 'UnitConversionRecord';
  assumptionId: string | null;
  assumptionUuid: string | null;
  conversionDescription: string | null;
  conversionMultiplier: number | null;
  inputUnit: string | null;
  outputUnit: string | null;
};

type GQConversionChainItemForMethodologyExplainer_AssumptionRecordForDashboard_Fragment =
  {
    __typename: 'AssumptionRecordForDashboard';
    id: string;
    conversionDescription: string | null;
    conversionMultiplier: number | null;
    inputUnit: string | null;
    outputUnit: string | null;
    assumptionSource: {
      __typename?: 'AssumptionSource';
      id: string;
      externalSourceNotes: string | null;
      userVisibleSourceUrl: string | null;
      hasMultiplierLicenseRestrictions: boolean | null;
    };
    assumptionDescription: {
      __typename?: 'AssumptionDescription';
      id: string;
      updatedAt: Date;
      assumptionId: string;
      userVisibleConversionDescription: string;
    } | null;
  };

type GQConversionChainItemForMethodologyExplainer_UnitConversionRecord_Fragment =
  {
    __typename: 'UnitConversionRecord';
    assumptionId: string | null;
    assumptionUuid: string | null;
    conversionDescription: string | null;
    conversionMultiplier: number | null;
    inputUnit: string | null;
    outputUnit: string | null;
  };

export type GQConversionChainItemForMethodologyExplainerFragment =
  | GQConversionChainItemForMethodologyExplainer_AssumptionRecordForDashboard_Fragment
  | GQConversionChainItemForMethodologyExplainer_UnitConversionRecord_Fragment;

export type GQConversionFactorForMethodologyExplainerFragment = {
  __typename?: 'ConversionFactorForDashboard';
  id: string;
  conversionFactorId: string;
  inputUnit: string;
  outputUnit: string;
  conversionChain: Array<
    | {
        __typename: 'AssumptionRecordForDashboard';
        id: string;
        conversionDescription: string | null;
        conversionMultiplier: number | null;
        inputUnit: string | null;
        outputUnit: string | null;
        assumptionSource: {
          __typename?: 'AssumptionSource';
          id: string;
          externalSourceNotes: string | null;
          userVisibleSourceUrl: string | null;
          hasMultiplierLicenseRestrictions: boolean | null;
        };
        assumptionDescription: {
          __typename?: 'AssumptionDescription';
          id: string;
          updatedAt: Date;
          assumptionId: string;
          userVisibleConversionDescription: string;
        } | null;
      }
    | {
        __typename: 'UnitConversionRecord';
        assumptionId: string | null;
        assumptionUuid: string | null;
        conversionDescription: string | null;
        conversionMultiplier: number | null;
        inputUnit: string | null;
        outputUnit: string | null;
      }
  > | null;
};

export type GQDisclosureForMethodologyExplainerFragment = {
  __typename?: 'Disclosure';
  id: string;
  qualityScore: GQDisclosureQualityScore | null;
  historicalEmissionsYears: Array<{
    __typename?: 'EmissionsYear';
    id: string;
    reportingYear: number;
  }> | null;
  privateDisclosure: {
    __typename?: 'PrivateDisclosure';
    id: string;
    privateDisclosureType: GQPrivateDisclosureType;
  } | null;
  publicDisclosure: {
    __typename?: 'PublicDisclosure';
    id: string;
    reportType: GQExternalReportType;
    publicUrl: string;
  } | null;
  climateCommitments: Array<
    | {
        __typename?: 'CarbonNeutralCommitment';
        targetYear: number | null;
        id: string;
        externalUrl: string | null;
        kind: GQCompanyClimateCommitmentKind;
      }
    | {
        __typename?: 'CleanEnergyCommitment';
        id: string;
        externalUrl: string | null;
        kind: GQCompanyClimateCommitmentKind;
      }
    | {
        __typename?: 'NetZeroCommitment';
        targetYear: number | null;
        id: string;
        externalUrl: string | null;
        kind: GQCompanyClimateCommitmentKind;
      }
    | {
        __typename?: 'ScienceBasedTargetCommitment';
        id: string;
        externalUrl: string | null;
        kind: GQCompanyClimateCommitmentKind;
        commitment: {
          __typename?: 'SBTCommitment';
          id: string;
          stage: GQSbtCommitmentStage | null;
          targetClassification: GQSbtTargetClassification | null;
        };
      }
  > | null;
};

export type GQEmissionsFactorForMethodologyExplainerFragment = {
  __typename?: 'EmissionsFactorRecordForDashboard';
  id: string;
  emissionsFactorId: string;
  kgCo2e: number | null;
  unit: string | null;
  efName: string | null;
  isVendorSpecific: boolean | null;
  startMonth: YearMonth;
  endMonth: YearMonth;
  assumptionSource: {
    __typename?: 'AssumptionSource';
    id: string;
    externalSourceNotes: string | null;
    userVisibleSourceUrl: string | null;
    hasMultiplierLicenseRestrictions: boolean | null;
  } | null;
  emissionsFactorDescription: {
    __typename?: 'EmissionsFactorDescription';
    id: string;
    updatedAt: Date;
    emissionsFactorId: string;
    userVisibleEfName: string;
  } | null;
  supplierDisclosure: {
    __typename?: 'Disclosure';
    id: string;
    qualityScore: GQDisclosureQualityScore | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      reportingYear: number;
    }> | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      privateDisclosureType: GQPrivateDisclosureType;
    } | null;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      reportType: GQExternalReportType;
      publicUrl: string;
    } | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          targetYear: number | null;
          id: string;
          externalUrl: string | null;
          kind: GQCompanyClimateCommitmentKind;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          id: string;
          externalUrl: string | null;
          kind: GQCompanyClimateCommitmentKind;
        }
      | {
          __typename?: 'NetZeroCommitment';
          targetYear: number | null;
          id: string;
          externalUrl: string | null;
          kind: GQCompanyClimateCommitmentKind;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          id: string;
          externalUrl: string | null;
          kind: GQCompanyClimateCommitmentKind;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
          };
        }
    > | null;
  } | null;
};

export type GQCalculationChainForMethodologyExplainerFragment = {
  __typename: 'CalculationChain';
  inputQuantity: number | null;
  inputUnit: string | null;
  convertedQuantity: number;
  tco2eQuantity: number;
  tco2eLocationQuantity: number;
  sumOfMeasure: number;
  emissionsModelVersionId: string | null;
  conversionFactor: {
    __typename?: 'ConversionFactorForDashboard';
    id: string;
    conversionFactorId: string;
    inputUnit: string;
    outputUnit: string;
    conversionChain: Array<
      | {
          __typename: 'AssumptionRecordForDashboard';
          id: string;
          conversionDescription: string | null;
          conversionMultiplier: number | null;
          inputUnit: string | null;
          outputUnit: string | null;
          assumptionSource: {
            __typename?: 'AssumptionSource';
            id: string;
            externalSourceNotes: string | null;
            userVisibleSourceUrl: string | null;
            hasMultiplierLicenseRestrictions: boolean | null;
          };
          assumptionDescription: {
            __typename?: 'AssumptionDescription';
            id: string;
            updatedAt: Date;
            assumptionId: string;
            userVisibleConversionDescription: string;
          } | null;
        }
      | {
          __typename: 'UnitConversionRecord';
          assumptionId: string | null;
          assumptionUuid: string | null;
          conversionDescription: string | null;
          conversionMultiplier: number | null;
          inputUnit: string | null;
          outputUnit: string | null;
        }
    > | null;
  } | null;
  emissionsFactor: {
    __typename?: 'EmissionsFactorRecordForDashboard';
    id: string;
    emissionsFactorId: string;
    kgCo2e: number | null;
    unit: string | null;
    efName: string | null;
    isVendorSpecific: boolean | null;
    startMonth: YearMonth;
    endMonth: YearMonth;
    assumptionSource: {
      __typename?: 'AssumptionSource';
      id: string;
      externalSourceNotes: string | null;
      userVisibleSourceUrl: string | null;
      hasMultiplierLicenseRestrictions: boolean | null;
    } | null;
    emissionsFactorDescription: {
      __typename?: 'EmissionsFactorDescription';
      id: string;
      updatedAt: Date;
      emissionsFactorId: string;
      userVisibleEfName: string;
    } | null;
    supplierDisclosure: {
      __typename?: 'Disclosure';
      id: string;
      qualityScore: GQDisclosureQualityScore | null;
      historicalEmissionsYears: Array<{
        __typename?: 'EmissionsYear';
        id: string;
        reportingYear: number;
      }> | null;
      privateDisclosure: {
        __typename?: 'PrivateDisclosure';
        id: string;
        privateDisclosureType: GQPrivateDisclosureType;
      } | null;
      publicDisclosure: {
        __typename?: 'PublicDisclosure';
        id: string;
        reportType: GQExternalReportType;
        publicUrl: string;
      } | null;
      climateCommitments: Array<
        | {
            __typename?: 'CarbonNeutralCommitment';
            targetYear: number | null;
            id: string;
            externalUrl: string | null;
            kind: GQCompanyClimateCommitmentKind;
          }
        | {
            __typename?: 'CleanEnergyCommitment';
            id: string;
            externalUrl: string | null;
            kind: GQCompanyClimateCommitmentKind;
          }
        | {
            __typename?: 'NetZeroCommitment';
            targetYear: number | null;
            id: string;
            externalUrl: string | null;
            kind: GQCompanyClimateCommitmentKind;
          }
        | {
            __typename?: 'ScienceBasedTargetCommitment';
            id: string;
            externalUrl: string | null;
            kind: GQCompanyClimateCommitmentKind;
            commitment: {
              __typename?: 'SBTCommitment';
              id: string;
              stage: GQSbtCommitmentStage | null;
              targetClassification: GQSbtTargetClassification | null;
            };
          }
      > | null;
    } | null;
  } | null;
  locationEmissionsFactor: {
    __typename?: 'EmissionsFactorRecordForDashboard';
    id: string;
    emissionsFactorId: string;
    kgCo2e: number | null;
    unit: string | null;
    efName: string | null;
    isVendorSpecific: boolean | null;
    startMonth: YearMonth;
    endMonth: YearMonth;
    assumptionSource: {
      __typename?: 'AssumptionSource';
      id: string;
      externalSourceNotes: string | null;
      userVisibleSourceUrl: string | null;
      hasMultiplierLicenseRestrictions: boolean | null;
    } | null;
    emissionsFactorDescription: {
      __typename?: 'EmissionsFactorDescription';
      id: string;
      updatedAt: Date;
      emissionsFactorId: string;
      userVisibleEfName: string;
    } | null;
    supplierDisclosure: {
      __typename?: 'Disclosure';
      id: string;
      qualityScore: GQDisclosureQualityScore | null;
      historicalEmissionsYears: Array<{
        __typename?: 'EmissionsYear';
        id: string;
        reportingYear: number;
      }> | null;
      privateDisclosure: {
        __typename?: 'PrivateDisclosure';
        id: string;
        privateDisclosureType: GQPrivateDisclosureType;
      } | null;
      publicDisclosure: {
        __typename?: 'PublicDisclosure';
        id: string;
        reportType: GQExternalReportType;
        publicUrl: string;
      } | null;
      climateCommitments: Array<
        | {
            __typename?: 'CarbonNeutralCommitment';
            targetYear: number | null;
            id: string;
            externalUrl: string | null;
            kind: GQCompanyClimateCommitmentKind;
          }
        | {
            __typename?: 'CleanEnergyCommitment';
            id: string;
            externalUrl: string | null;
            kind: GQCompanyClimateCommitmentKind;
          }
        | {
            __typename?: 'NetZeroCommitment';
            targetYear: number | null;
            id: string;
            externalUrl: string | null;
            kind: GQCompanyClimateCommitmentKind;
          }
        | {
            __typename?: 'ScienceBasedTargetCommitment';
            id: string;
            externalUrl: string | null;
            kind: GQCompanyClimateCommitmentKind;
            commitment: {
              __typename?: 'SBTCommitment';
              id: string;
              stage: GQSbtCommitmentStage | null;
              targetClassification: GQSbtTargetClassification | null;
            };
          }
      > | null;
    } | null;
  } | null;
};

export type GQFootprintExclusionRuleForMethodologyExplainerFragment = {
  __typename?: 'FootprintExclusionRule';
  id: string;
  stableId: string;
  deduplicationNote: string | null;
  outputValue: number;
};

export type GQCalculationTraceForMethodologyExplainerFragment = {
  __typename: 'MethodologyExplainerCalculationTrace';
  id: string;
  fingerprint: string;
  sumOfFunctionalUnit: number;
  sumOfConvertedActivity: number;
  sumOfMeasure: number;
  inputUnit: string | null;
  convertedUnit: string | null;
  quantitySpecifier: string | null;
  emissionsModelVersionId: string;
  emissionsModelEvaluationId: string;
  footprintExclusionRule: {
    __typename?: 'FootprintExclusionRule';
    id: string;
    stableId: string;
    deduplicationNote: string | null;
    outputValue: number;
  } | null;
};

export type GQCompanyForMethodologyExplainerFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
  description: string | null;
  url: string | null;
  fullLogoUrl: string | null;
  climateProgress: GQCompanyClimateProgress;
  disclosuresV2: Array<{
    __typename?: 'Disclosure';
    id: string;
    qualityScore: GQDisclosureQualityScore | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      reportingYear: number;
    }> | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      privateDisclosureType: GQPrivateDisclosureType;
    } | null;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      reportType: GQExternalReportType;
      publicUrl: string;
    } | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          targetYear: number | null;
          id: string;
          externalUrl: string | null;
          kind: GQCompanyClimateCommitmentKind;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          id: string;
          externalUrl: string | null;
          kind: GQCompanyClimateCommitmentKind;
        }
      | {
          __typename?: 'NetZeroCommitment';
          targetYear: number | null;
          id: string;
          externalUrl: string | null;
          kind: GQCompanyClimateCommitmentKind;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          id: string;
          externalUrl: string | null;
          kind: GQCompanyClimateCommitmentKind;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
          };
        }
    > | null;
  }>;
};

export type GQFilesForMethodologyExplainerFragment = {
  __typename?: 'FootprintAnalysisFile';
  id: string;
  name: string;
  category: GQFileCategory;
  createdAt: Date;
  uploader: { __typename?: 'User'; id: string; name: string } | null;
  userUploadTask: {
    __typename?: 'UserUploadTask';
    id: string;
    datasource: { __typename?: 'Datasource'; id: string; name: string };
  } | null;
};

export type GQGetBiMethodologyExplainerContentQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
  measureSelector: GQBiQuerySingleMeasureSelectorInput;
  companyId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQGetBiMethodologyExplainerContentQuery = {
  __typename?: 'Query';
  companyForDashboard: {
    __typename?: 'Company';
    id: string;
    name: string;
    description: string | null;
    url: string | null;
    fullLogoUrl: string | null;
    climateProgress: GQCompanyClimateProgress;
    disclosuresV2: Array<{
      __typename?: 'Disclosure';
      id: string;
      qualityScore: GQDisclosureQualityScore | null;
      historicalEmissionsYears: Array<{
        __typename?: 'EmissionsYear';
        id: string;
        reportingYear: number;
      }> | null;
      privateDisclosure: {
        __typename?: 'PrivateDisclosure';
        id: string;
        privateDisclosureType: GQPrivateDisclosureType;
      } | null;
      publicDisclosure: {
        __typename?: 'PublicDisclosure';
        id: string;
        reportType: GQExternalReportType;
        publicUrl: string;
      } | null;
      climateCommitments: Array<
        | {
            __typename?: 'CarbonNeutralCommitment';
            targetYear: number | null;
            id: string;
            externalUrl: string | null;
            kind: GQCompanyClimateCommitmentKind;
          }
        | {
            __typename?: 'CleanEnergyCommitment';
            id: string;
            externalUrl: string | null;
            kind: GQCompanyClimateCommitmentKind;
          }
        | {
            __typename?: 'NetZeroCommitment';
            targetYear: number | null;
            id: string;
            externalUrl: string | null;
            kind: GQCompanyClimateCommitmentKind;
          }
        | {
            __typename?: 'ScienceBasedTargetCommitment';
            id: string;
            externalUrl: string | null;
            kind: GQCompanyClimateCommitmentKind;
            commitment: {
              __typename?: 'SBTCommitment';
              id: string;
              stage: GQSbtCommitmentStage | null;
              targetClassification: GQSbtTargetClassification | null;
            };
          }
      > | null;
    }>;
  } | null;
  methodologyExplainer: {
    __typename?: 'MethodologyExplainer';
    id: string | null;
    biGhgCategoryIds: Array<string>;
    biCalculationTraces: Array<any>;
    biCalculationChains: Array<{
      __typename: 'CalculationChain';
      inputQuantity: number | null;
      inputUnit: string | null;
      convertedQuantity: number;
      tco2eQuantity: number;
      tco2eLocationQuantity: number;
      sumOfMeasure: number;
      emissionsModelVersionId: string | null;
      conversionFactor: {
        __typename?: 'ConversionFactorForDashboard';
        id: string;
        conversionFactorId: string;
        inputUnit: string;
        outputUnit: string;
        conversionChain: Array<
          | {
              __typename: 'AssumptionRecordForDashboard';
              id: string;
              conversionDescription: string | null;
              conversionMultiplier: number | null;
              inputUnit: string | null;
              outputUnit: string | null;
              assumptionSource: {
                __typename?: 'AssumptionSource';
                id: string;
                externalSourceNotes: string | null;
                userVisibleSourceUrl: string | null;
                hasMultiplierLicenseRestrictions: boolean | null;
              };
              assumptionDescription: {
                __typename?: 'AssumptionDescription';
                id: string;
                updatedAt: Date;
                assumptionId: string;
                userVisibleConversionDescription: string;
              } | null;
            }
          | {
              __typename: 'UnitConversionRecord';
              assumptionId: string | null;
              assumptionUuid: string | null;
              conversionDescription: string | null;
              conversionMultiplier: number | null;
              inputUnit: string | null;
              outputUnit: string | null;
            }
        > | null;
      } | null;
      emissionsFactor: {
        __typename?: 'EmissionsFactorRecordForDashboard';
        id: string;
        emissionsFactorId: string;
        kgCo2e: number | null;
        unit: string | null;
        efName: string | null;
        isVendorSpecific: boolean | null;
        startMonth: YearMonth;
        endMonth: YearMonth;
        assumptionSource: {
          __typename?: 'AssumptionSource';
          id: string;
          externalSourceNotes: string | null;
          userVisibleSourceUrl: string | null;
          hasMultiplierLicenseRestrictions: boolean | null;
        } | null;
        emissionsFactorDescription: {
          __typename?: 'EmissionsFactorDescription';
          id: string;
          updatedAt: Date;
          emissionsFactorId: string;
          userVisibleEfName: string;
        } | null;
        supplierDisclosure: {
          __typename?: 'Disclosure';
          id: string;
          qualityScore: GQDisclosureQualityScore | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            reportingYear: number;
          }> | null;
          privateDisclosure: {
            __typename?: 'PrivateDisclosure';
            id: string;
            privateDisclosureType: GQPrivateDisclosureType;
          } | null;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            reportType: GQExternalReportType;
            publicUrl: string;
          } | null;
          climateCommitments: Array<
            | {
                __typename?: 'CarbonNeutralCommitment';
                targetYear: number | null;
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'CleanEnergyCommitment';
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'NetZeroCommitment';
                targetYear: number | null;
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'ScienceBasedTargetCommitment';
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
                commitment: {
                  __typename?: 'SBTCommitment';
                  id: string;
                  stage: GQSbtCommitmentStage | null;
                  targetClassification: GQSbtTargetClassification | null;
                };
              }
          > | null;
        } | null;
      } | null;
      locationEmissionsFactor: {
        __typename?: 'EmissionsFactorRecordForDashboard';
        id: string;
        emissionsFactorId: string;
        kgCo2e: number | null;
        unit: string | null;
        efName: string | null;
        isVendorSpecific: boolean | null;
        startMonth: YearMonth;
        endMonth: YearMonth;
        assumptionSource: {
          __typename?: 'AssumptionSource';
          id: string;
          externalSourceNotes: string | null;
          userVisibleSourceUrl: string | null;
          hasMultiplierLicenseRestrictions: boolean | null;
        } | null;
        emissionsFactorDescription: {
          __typename?: 'EmissionsFactorDescription';
          id: string;
          updatedAt: Date;
          emissionsFactorId: string;
          userVisibleEfName: string;
        } | null;
        supplierDisclosure: {
          __typename?: 'Disclosure';
          id: string;
          qualityScore: GQDisclosureQualityScore | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            reportingYear: number;
          }> | null;
          privateDisclosure: {
            __typename?: 'PrivateDisclosure';
            id: string;
            privateDisclosureType: GQPrivateDisclosureType;
          } | null;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            reportType: GQExternalReportType;
            publicUrl: string;
          } | null;
          climateCommitments: Array<
            | {
                __typename?: 'CarbonNeutralCommitment';
                targetYear: number | null;
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'CleanEnergyCommitment';
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'NetZeroCommitment';
                targetYear: number | null;
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'ScienceBasedTargetCommitment';
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
                commitment: {
                  __typename?: 'SBTCommitment';
                  id: string;
                  stage: GQSbtCommitmentStage | null;
                  targetClassification: GQSbtTargetClassification | null;
                };
              }
          > | null;
        } | null;
      } | null;
    }>;
    biExampleTracesForFingerprints: {
      __typename?: 'ExampleTracesForFingerprints';
      tracesByFingerprint: Array<any>;
      referenceValueDetails: Array<{
        __typename?: 'ReferenceValueDetails';
        refDataCellPath: string;
        description: string | null;
        citation: {
          __typename?: 'TraceReferenceValueCitationDetails';
          displayName: string | null;
          notes: string | null;
          url: string | null;
          publicDisclosureId: string | null;
          isCustomerProvided: boolean | null;
          org: {
            __typename?: 'TraceReferenceValueCitationDetailsOrg';
            orgName: string | null;
            orgId: string | null;
          };
        };
      }>;
      emissionsModelVariableDetails: Array<{
        __typename?: 'EmissionsModelVariableDetails';
        emissionsModelEvaluationId: string;
        variableName: string;
        displayName: string;
        description: string | null;
      }>;
      bartColumnDetails: Array<{
        __typename?: 'BartColumnDetails';
        emissionsModelEvaluationId: string;
        columnName: string;
        displayName: string | null;
        description: string | null;
      }>;
    };
  } | null;
};

export type GQExecuteBiAiQueryQueryVariables = Exact<{
  input: GQBiAiQueryInput;
}>;

export type GQExecuteBiAiQueryQuery = {
  __typename?: 'Query';
  biAiQuery: {
    __typename?: 'BiAiQueryResponse';
    payload: {
      __typename?: 'BiAiQueryPayload';
      dimensions: Array<string>;
      normalizedMeasureId: string;
      timeIntervals: Array<YMInterval>;
      filters: Array<{
        __typename?: 'BiQueryFilter';
        dimension: string;
        operator: GQBiFilterOperator;
        value: Array<FilterValue | null>;
      }>;
    } | null;
  };
};

export type GQCreateBiSavedViewAndPropagateToCustomReportsMutationVariables =
  Exact<{
    input: GQCreateBiSavedViewAndPropagateToCustomReportsInput;
  }>;

export type GQCreateBiSavedViewAndPropagateToCustomReportsMutation = {
  __typename?: 'Mutation';
  createBiSavedViewAndPropagateToCustomReports: {
    __typename?: 'BiSavedView';
    id: string;
    stableId: string;
    name: string;
    dimensions: Array<string>;
    chartKind: GQBiChartKind;
    editVisibility: GQBiSavedViewVisibility;
    viewVisibility: GQBiSavedViewVisibility;
    updatedAt: Date;
    createdAt: Date;
    timeIntervals: Array<YMInterval>;
    searchTerm: string | null;
    owner: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    };
    order: {
      __typename?: 'BiQueryOrder';
      dimensionOrMeasure: string;
      direction: GQBiQueryOrderDirection;
    } | null;
    filters: Array<{
      __typename?: 'BiQueryFilter';
      dimension: string;
      operator: GQBiFilterOperator;
      value: Array<FilterValue | null>;
    }>;
    normalizedMeasures: Array<{
      __typename?: 'BiDynamicNormalizingMeasure';
      baseMeasure: string;
      normalizingQuery: {
        __typename?: 'BiDynamicNormalizingQuery';
        measure: string;
        computedDimensionType: GQDimensionComputedType | null;
        computedFiltersType: GQDimensionComputedType | null;
        dimensions: Array<string> | null;
        filters: Array<{
          __typename?: 'BiQueryFilter';
          dimension: string;
          operator: GQBiFilterOperator;
          value: Array<FilterValue | null>;
        }> | null;
      } | null;
    }>;
    customMetrics: Array<{
      __typename?: 'BiCustomMetricRef';
      id: string | null;
      stableId: string;
    }> | null;
    queryContext: {
      __typename?: 'BiSavedViewSnapshotQueryContext';
      footprintKind: string;
    } | null;
  };
};

export type GQUpdateBiSavedViewMutationVariables = Exact<{
  input: GQUpdateBiSavedViewInput;
}>;

export type GQUpdateBiSavedViewMutation = {
  __typename?: 'Mutation';
  updateBiSavedView: {
    __typename?: 'BiSavedView';
    id: string;
    stableId: string;
    name: string;
    dimensions: Array<string>;
    chartKind: GQBiChartKind;
    editVisibility: GQBiSavedViewVisibility;
    viewVisibility: GQBiSavedViewVisibility;
    updatedAt: Date;
    createdAt: Date;
    timeIntervals: Array<YMInterval>;
    searchTerm: string | null;
    owner: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    };
    order: {
      __typename?: 'BiQueryOrder';
      dimensionOrMeasure: string;
      direction: GQBiQueryOrderDirection;
    } | null;
    filters: Array<{
      __typename?: 'BiQueryFilter';
      dimension: string;
      operator: GQBiFilterOperator;
      value: Array<FilterValue | null>;
    }>;
    normalizedMeasures: Array<{
      __typename?: 'BiDynamicNormalizingMeasure';
      baseMeasure: string;
      normalizingQuery: {
        __typename?: 'BiDynamicNormalizingQuery';
        measure: string;
        computedDimensionType: GQDimensionComputedType | null;
        computedFiltersType: GQDimensionComputedType | null;
        dimensions: Array<string> | null;
        filters: Array<{
          __typename?: 'BiQueryFilter';
          dimension: string;
          operator: GQBiFilterOperator;
          value: Array<FilterValue | null>;
        }> | null;
      } | null;
    }>;
    customMetrics: Array<{
      __typename?: 'BiCustomMetricRef';
      id: string | null;
      stableId: string;
    }> | null;
    queryContext: {
      __typename?: 'BiSavedViewSnapshotQueryContext';
      footprintKind: string;
    } | null;
  };
};

export type GQRenameBiSavedViewMutationVariables = Exact<{
  input: GQRenameBiSavedViewInput;
}>;

export type GQRenameBiSavedViewMutation = {
  __typename?: 'Mutation';
  renameBiSavedView: {
    __typename?: 'BiSavedView';
    id: string;
    stableId: string;
    name: string;
    dimensions: Array<string>;
    chartKind: GQBiChartKind;
    editVisibility: GQBiSavedViewVisibility;
    viewVisibility: GQBiSavedViewVisibility;
    updatedAt: Date;
    createdAt: Date;
    timeIntervals: Array<YMInterval>;
    searchTerm: string | null;
    owner: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    };
    order: {
      __typename?: 'BiQueryOrder';
      dimensionOrMeasure: string;
      direction: GQBiQueryOrderDirection;
    } | null;
    filters: Array<{
      __typename?: 'BiQueryFilter';
      dimension: string;
      operator: GQBiFilterOperator;
      value: Array<FilterValue | null>;
    }>;
    normalizedMeasures: Array<{
      __typename?: 'BiDynamicNormalizingMeasure';
      baseMeasure: string;
      normalizingQuery: {
        __typename?: 'BiDynamicNormalizingQuery';
        measure: string;
        computedDimensionType: GQDimensionComputedType | null;
        computedFiltersType: GQDimensionComputedType | null;
        dimensions: Array<string> | null;
        filters: Array<{
          __typename?: 'BiQueryFilter';
          dimension: string;
          operator: GQBiFilterOperator;
          value: Array<FilterValue | null>;
        }> | null;
      } | null;
    }>;
    customMetrics: Array<{
      __typename?: 'BiCustomMetricRef';
      id: string | null;
      stableId: string;
    }> | null;
    queryContext: {
      __typename?: 'BiSavedViewSnapshotQueryContext';
      footprintKind: string;
    } | null;
  };
};

export type GQDeleteBiSavedViewMutationVariables = Exact<{
  input: GQDeleteBiSavedViewInput;
}>;

export type GQDeleteBiSavedViewMutation = {
  __typename?: 'Mutation';
  deleteBiSavedView: {
    __typename?: 'BiSavedView';
    id: string;
    stableId: string;
    name: string;
    dimensions: Array<string>;
    chartKind: GQBiChartKind;
    editVisibility: GQBiSavedViewVisibility;
    viewVisibility: GQBiSavedViewVisibility;
    updatedAt: Date;
    createdAt: Date;
    timeIntervals: Array<YMInterval>;
    searchTerm: string | null;
    owner: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    };
    order: {
      __typename?: 'BiQueryOrder';
      dimensionOrMeasure: string;
      direction: GQBiQueryOrderDirection;
    } | null;
    filters: Array<{
      __typename?: 'BiQueryFilter';
      dimension: string;
      operator: GQBiFilterOperator;
      value: Array<FilterValue | null>;
    }>;
    normalizedMeasures: Array<{
      __typename?: 'BiDynamicNormalizingMeasure';
      baseMeasure: string;
      normalizingQuery: {
        __typename?: 'BiDynamicNormalizingQuery';
        measure: string;
        computedDimensionType: GQDimensionComputedType | null;
        computedFiltersType: GQDimensionComputedType | null;
        dimensions: Array<string> | null;
        filters: Array<{
          __typename?: 'BiQueryFilter';
          dimension: string;
          operator: GQBiFilterOperator;
          value: Array<FilterValue | null>;
        }> | null;
      } | null;
    }>;
    customMetrics: Array<{
      __typename?: 'BiCustomMetricRef';
      id: string | null;
      stableId: string;
    }> | null;
    queryContext: {
      __typename?: 'BiSavedViewSnapshotQueryContext';
      footprintKind: string;
    } | null;
  };
};

type GQBiBaseFieldMetaFragment_BiFieldBooleanMeta_Fragment = {
  __typename: 'BiFieldBooleanMeta';
  fieldId: string;
  displayName: string;
  fieldFamily: string | null;
  description: string | null;
  hiddenInFilters: boolean | null;
  hiddenInGroupBy: boolean | null;
  emptyStringType: GQBiEmptyStringType | null;
  isMultiValue: boolean | null;
  isCustomField: boolean | null;
};

type GQBiBaseFieldMetaFragment_BiFieldDateMeta_Fragment = {
  __typename: 'BiFieldDateMeta';
  fieldId: string;
  displayName: string;
  fieldFamily: string | null;
  description: string | null;
  hiddenInFilters: boolean | null;
  hiddenInGroupBy: boolean | null;
  emptyStringType: GQBiEmptyStringType | null;
  isMultiValue: boolean | null;
  isCustomField: boolean | null;
};

type GQBiBaseFieldMetaFragment_BiFieldNumberMeta_Fragment = {
  __typename: 'BiFieldNumberMeta';
  fieldId: string;
  displayName: string;
  fieldFamily: string | null;
  description: string | null;
  hiddenInFilters: boolean | null;
  hiddenInGroupBy: boolean | null;
  emptyStringType: GQBiEmptyStringType | null;
  isMultiValue: boolean | null;
  isCustomField: boolean | null;
};

type GQBiBaseFieldMetaFragment_BiFieldStringMeta_Fragment = {
  __typename: 'BiFieldStringMeta';
  fieldId: string;
  displayName: string;
  fieldFamily: string | null;
  description: string | null;
  hiddenInFilters: boolean | null;
  hiddenInGroupBy: boolean | null;
  emptyStringType: GQBiEmptyStringType | null;
  isMultiValue: boolean | null;
  isCustomField: boolean | null;
};

type GQBiBaseFieldMetaFragment_BiFieldYearMonthMeta_Fragment = {
  __typename: 'BiFieldYearMonthMeta';
  fieldId: string;
  displayName: string;
  fieldFamily: string | null;
  description: string | null;
  hiddenInFilters: boolean | null;
  hiddenInGroupBy: boolean | null;
  emptyStringType: GQBiEmptyStringType | null;
  isMultiValue: boolean | null;
  isCustomField: boolean | null;
};

export type GQBiBaseFieldMetaFragmentFragment =
  | GQBiBaseFieldMetaFragment_BiFieldBooleanMeta_Fragment
  | GQBiBaseFieldMetaFragment_BiFieldDateMeta_Fragment
  | GQBiBaseFieldMetaFragment_BiFieldNumberMeta_Fragment
  | GQBiBaseFieldMetaFragment_BiFieldStringMeta_Fragment
  | GQBiBaseFieldMetaFragment_BiFieldYearMonthMeta_Fragment;

export type GQBiDateFieldMetaFragmentFragment = {
  __typename?: 'BiFieldDateMeta';
  format: {
    __typename?: 'DateTimeFormatOptions';
    day: string | null;
    month: string | null;
    year: string | null;
  } | null;
};

export type GQBiYearMonthFieldMetaFragmentFragment = {
  __typename?: 'BiFieldYearMonthMeta';
  format: {
    __typename?: 'DateTimeFormatOptions';
    day: string | null;
    month: string | null;
    year: string | null;
  } | null;
};

export type GQBiStringFieldMetaFragmentFragment = {
  __typename?: 'BiFieldStringMeta';
  shouldSentenceCaseValues: boolean;
  shouldNullifyPseudoNulls: boolean | null;
  knownDisplayValueMappingType: GQBiKnownDisplayValueMappingType | null;
  staticListOptions: Array<{
    __typename?: 'BiDimensionListOptionItem';
    value: any | null;
    label: string | null;
  }> | null;
};

export type GQBiNumberFieldMetaFragmentFragment = {
  __typename?: 'BiFieldNumberMeta';
  precision: number | null;
  disableCommas: boolean | null;
  includeUnit: boolean | null;
  shouldFormatToPercent: boolean | null;
  unit: string | null;
  unitDimension: string | null;
  unitUserFacing: string | null;
  dependentDimensions: Array<string> | null;
  aggregatedUnitDimension: string | null;
  aggregateType: GQBiAggregateMethod | null;
  divideBy: number | null;
  isCurrency: boolean | null;
};

export type GQBiQueryMetadataFieldsFragment = {
  __typename?: 'BiQueryMetadataResult';
  id: string;
  timeInterval: YMInterval | null;
  schemaNames: Array<string> | null;
  dimensions: Array<{
    __typename?: 'BiDimensionMeta';
    fieldMeta:
      | {
          __typename: 'BiFieldBooleanMeta';
          fieldId: string;
          displayName: string;
          fieldFamily: string | null;
          description: string | null;
          hiddenInFilters: boolean | null;
          hiddenInGroupBy: boolean | null;
          emptyStringType: GQBiEmptyStringType | null;
          isMultiValue: boolean | null;
          isCustomField: boolean | null;
        }
      | {
          __typename: 'BiFieldDateMeta';
          fieldId: string;
          displayName: string;
          fieldFamily: string | null;
          description: string | null;
          hiddenInFilters: boolean | null;
          hiddenInGroupBy: boolean | null;
          emptyStringType: GQBiEmptyStringType | null;
          isMultiValue: boolean | null;
          isCustomField: boolean | null;
          format: {
            __typename?: 'DateTimeFormatOptions';
            day: string | null;
            month: string | null;
            year: string | null;
          } | null;
        }
      | {
          __typename: 'BiFieldNumberMeta';
          fieldId: string;
          displayName: string;
          fieldFamily: string | null;
          description: string | null;
          hiddenInFilters: boolean | null;
          hiddenInGroupBy: boolean | null;
          emptyStringType: GQBiEmptyStringType | null;
          isMultiValue: boolean | null;
          isCustomField: boolean | null;
          precision: number | null;
          disableCommas: boolean | null;
          includeUnit: boolean | null;
          shouldFormatToPercent: boolean | null;
          unit: string | null;
          unitDimension: string | null;
          unitUserFacing: string | null;
          dependentDimensions: Array<string> | null;
          aggregatedUnitDimension: string | null;
          aggregateType: GQBiAggregateMethod | null;
          divideBy: number | null;
          isCurrency: boolean | null;
        }
      | {
          __typename: 'BiFieldStringMeta';
          fieldId: string;
          displayName: string;
          fieldFamily: string | null;
          description: string | null;
          hiddenInFilters: boolean | null;
          hiddenInGroupBy: boolean | null;
          emptyStringType: GQBiEmptyStringType | null;
          isMultiValue: boolean | null;
          isCustomField: boolean | null;
          shouldSentenceCaseValues: boolean;
          shouldNullifyPseudoNulls: boolean | null;
          knownDisplayValueMappingType: GQBiKnownDisplayValueMappingType | null;
          staticListOptions: Array<{
            __typename?: 'BiDimensionListOptionItem';
            value: any | null;
            label: string | null;
          }> | null;
        }
      | {
          __typename: 'BiFieldYearMonthMeta';
          fieldId: string;
          displayName: string;
          fieldFamily: string | null;
          description: string | null;
          hiddenInFilters: boolean | null;
          hiddenInGroupBy: boolean | null;
          emptyStringType: GQBiEmptyStringType | null;
          isMultiValue: boolean | null;
          isCustomField: boolean | null;
          format: {
            __typename?: 'DateTimeFormatOptions';
            day: string | null;
            month: string | null;
            year: string | null;
          } | null;
        };
  }>;
  measures: Array<{
    __typename?: 'BiMeasureMeta';
    supportedDimensions: Array<string>;
    fieldMeta: {
      __typename: 'BiFieldNumberMeta';
      fieldId: string;
      displayName: string;
      fieldFamily: string | null;
      description: string | null;
      hiddenInFilters: boolean | null;
      hiddenInGroupBy: boolean | null;
      emptyStringType: GQBiEmptyStringType | null;
      isMultiValue: boolean | null;
      isCustomField: boolean | null;
      precision: number | null;
      disableCommas: boolean | null;
      includeUnit: boolean | null;
      shouldFormatToPercent: boolean | null;
      unit: string | null;
      unitDimension: string | null;
      unitUserFacing: string | null;
      dependentDimensions: Array<string> | null;
      aggregatedUnitDimension: string | null;
      aggregateType: GQBiAggregateMethod | null;
      divideBy: number | null;
      isCurrency: boolean | null;
    };
  }>;
};

export type GQBiMetadataQueryVariables = Exact<{
  input: GQBiQueryMetadataInput;
}>;

export type GQBiMetadataQuery = {
  __typename?: 'Query';
  biQueryMetadata: {
    __typename?: 'BiQueryMetadataResult';
    id: string;
    timeInterval: YMInterval | null;
    schemaNames: Array<string> | null;
    dimensions: Array<{
      __typename?: 'BiDimensionMeta';
      fieldMeta:
        | {
            __typename: 'BiFieldBooleanMeta';
            fieldId: string;
            displayName: string;
            fieldFamily: string | null;
            description: string | null;
            hiddenInFilters: boolean | null;
            hiddenInGroupBy: boolean | null;
            emptyStringType: GQBiEmptyStringType | null;
            isMultiValue: boolean | null;
            isCustomField: boolean | null;
          }
        | {
            __typename: 'BiFieldDateMeta';
            fieldId: string;
            displayName: string;
            fieldFamily: string | null;
            description: string | null;
            hiddenInFilters: boolean | null;
            hiddenInGroupBy: boolean | null;
            emptyStringType: GQBiEmptyStringType | null;
            isMultiValue: boolean | null;
            isCustomField: boolean | null;
            format: {
              __typename?: 'DateTimeFormatOptions';
              day: string | null;
              month: string | null;
              year: string | null;
            } | null;
          }
        | {
            __typename: 'BiFieldNumberMeta';
            fieldId: string;
            displayName: string;
            fieldFamily: string | null;
            description: string | null;
            hiddenInFilters: boolean | null;
            hiddenInGroupBy: boolean | null;
            emptyStringType: GQBiEmptyStringType | null;
            isMultiValue: boolean | null;
            isCustomField: boolean | null;
            precision: number | null;
            disableCommas: boolean | null;
            includeUnit: boolean | null;
            shouldFormatToPercent: boolean | null;
            unit: string | null;
            unitDimension: string | null;
            unitUserFacing: string | null;
            dependentDimensions: Array<string> | null;
            aggregatedUnitDimension: string | null;
            aggregateType: GQBiAggregateMethod | null;
            divideBy: number | null;
            isCurrency: boolean | null;
          }
        | {
            __typename: 'BiFieldStringMeta';
            fieldId: string;
            displayName: string;
            fieldFamily: string | null;
            description: string | null;
            hiddenInFilters: boolean | null;
            hiddenInGroupBy: boolean | null;
            emptyStringType: GQBiEmptyStringType | null;
            isMultiValue: boolean | null;
            isCustomField: boolean | null;
            shouldSentenceCaseValues: boolean;
            shouldNullifyPseudoNulls: boolean | null;
            knownDisplayValueMappingType: GQBiKnownDisplayValueMappingType | null;
            staticListOptions: Array<{
              __typename?: 'BiDimensionListOptionItem';
              value: any | null;
              label: string | null;
            }> | null;
          }
        | {
            __typename: 'BiFieldYearMonthMeta';
            fieldId: string;
            displayName: string;
            fieldFamily: string | null;
            description: string | null;
            hiddenInFilters: boolean | null;
            hiddenInGroupBy: boolean | null;
            emptyStringType: GQBiEmptyStringType | null;
            isMultiValue: boolean | null;
            isCustomField: boolean | null;
            format: {
              __typename?: 'DateTimeFormatOptions';
              day: string | null;
              month: string | null;
              year: string | null;
            } | null;
          };
    }>;
    measures: Array<{
      __typename?: 'BiMeasureMeta';
      supportedDimensions: Array<string>;
      fieldMeta: {
        __typename: 'BiFieldNumberMeta';
        fieldId: string;
        displayName: string;
        fieldFamily: string | null;
        description: string | null;
        hiddenInFilters: boolean | null;
        hiddenInGroupBy: boolean | null;
        emptyStringType: GQBiEmptyStringType | null;
        isMultiValue: boolean | null;
        isCustomField: boolean | null;
        precision: number | null;
        disableCommas: boolean | null;
        includeUnit: boolean | null;
        shouldFormatToPercent: boolean | null;
        unit: string | null;
        unitDimension: string | null;
        unitUserFacing: string | null;
        dependentDimensions: Array<string> | null;
        aggregatedUnitDimension: string | null;
        aggregateType: GQBiAggregateMethod | null;
        divideBy: number | null;
        isCurrency: boolean | null;
      };
    }>;
  };
};

export type GQBiDataQueryVariables = Exact<{
  input: GQBiQueryDataInput;
}>;

export type GQBiDataQuery = {
  __typename?: 'Query';
  biQueryData: {
    __typename?: 'BiQueryDataResult';
    id: string;
    data: GQBiDataResult;
    displayValueMappings: Array<{
      __typename?: 'BiDisplayValueMapping';
      dimension: string;
      listOptionItems: Array<{
        __typename?: 'BiDimensionListOptionItem';
        value: any | null;
        label: string | null;
      }>;
    }> | null;
    warnings: Array<{
      __typename?: 'BiWarning';
      learnMoreLink: string | null;
      warningMessage: string;
      warningType: GQBiWarningType;
    }> | null;
  };
};

export type GQBiDataMultipleQueryVariables = Exact<{
  input: GQBiQueryDataMultipleInput;
}>;

export type GQBiDataMultipleQuery = {
  __typename?: 'Query';
  biQueryDataMultiple: Array<{
    __typename?: 'BiQueryDataResult';
    id: string;
    data: GQBiDataResult;
    displayValueMappings: Array<{
      __typename?: 'BiDisplayValueMapping';
      dimension: string;
      listOptionItems: Array<{
        __typename?: 'BiDimensionListOptionItem';
        value: any | null;
        label: string | null;
      }>;
    }> | null;
  }>;
};

export type GQBiDimensionListOptionsQueryVariables = Exact<{
  input: GQBiDimensionListOptionsInput;
}>;

export type GQBiDimensionListOptionsQuery = {
  __typename?: 'Query';
  biDimensionListOptions: {
    __typename?: 'BiDimensionListOptionsResult';
    id: string;
    listOptionItems: Array<{
      __typename?: 'BiDimensionListOptionItem';
      value: any | null;
      label: string | null;
    }>;
  };
};

export type GQBiFilterDisplayValueMappingsQueryVariables = Exact<{
  input: GQBiFilterDisplayValueMappingsInput;
}>;

export type GQBiFilterDisplayValueMappingsQuery = {
  __typename?: 'Query';
  biFilterDisplayValueMappings: Array<{
    __typename?: 'BiDisplayValueMapping';
    dimension: string;
    listOptionItems: Array<{
      __typename?: 'BiDimensionListOptionItem';
      value: any | null;
      label: string | null;
    }>;
  }>;
};

export type GQBiQueryFilterFieldsFragment = {
  __typename?: 'BiQueryFilter';
  dimension: string;
  operator: GQBiFilterOperator;
  value: Array<FilterValue | null>;
};

export type GQBiNormalizedMeasureFieldsFragment = {
  __typename?: 'BiDynamicNormalizingMeasure';
  baseMeasure: string;
  normalizingQuery: {
    __typename?: 'BiDynamicNormalizingQuery';
    measure: string;
    computedDimensionType: GQDimensionComputedType | null;
    computedFiltersType: GQDimensionComputedType | null;
    dimensions: Array<string> | null;
    filters: Array<{
      __typename?: 'BiQueryFilter';
      dimension: string;
      operator: GQBiFilterOperator;
      value: Array<FilterValue | null>;
    }> | null;
  } | null;
};

export type GQBiSavedViewFragment = {
  __typename?: 'BiSavedView';
  id: string;
  stableId: string;
  name: string;
  dimensions: Array<string>;
  chartKind: GQBiChartKind;
  editVisibility: GQBiSavedViewVisibility;
  viewVisibility: GQBiSavedViewVisibility;
  updatedAt: Date;
  createdAt: Date;
  timeIntervals: Array<YMInterval>;
  searchTerm: string | null;
  owner: { __typename?: 'User'; id: string; name: string; displayName: string };
  order: {
    __typename?: 'BiQueryOrder';
    dimensionOrMeasure: string;
    direction: GQBiQueryOrderDirection;
  } | null;
  filters: Array<{
    __typename?: 'BiQueryFilter';
    dimension: string;
    operator: GQBiFilterOperator;
    value: Array<FilterValue | null>;
  }>;
  normalizedMeasures: Array<{
    __typename?: 'BiDynamicNormalizingMeasure';
    baseMeasure: string;
    normalizingQuery: {
      __typename?: 'BiDynamicNormalizingQuery';
      measure: string;
      computedDimensionType: GQDimensionComputedType | null;
      computedFiltersType: GQDimensionComputedType | null;
      dimensions: Array<string> | null;
      filters: Array<{
        __typename?: 'BiQueryFilter';
        dimension: string;
        operator: GQBiFilterOperator;
        value: Array<FilterValue | null>;
      }> | null;
    } | null;
  }>;
  customMetrics: Array<{
    __typename?: 'BiCustomMetricRef';
    id: string | null;
    stableId: string;
  }> | null;
  queryContext: {
    __typename?: 'BiSavedViewSnapshotQueryContext';
    footprintKind: string;
  } | null;
};

export type GQGetBiSavedViewsQueryVariables = Exact<{
  input: InputMaybe<GQBiSavedViewsInput>;
}>;

export type GQGetBiSavedViewsQuery = {
  __typename?: 'Query';
  biSavedViews: Array<{
    __typename?: 'BiSavedView';
    id: string;
    stableId: string;
    name: string;
    dimensions: Array<string>;
    chartKind: GQBiChartKind;
    editVisibility: GQBiSavedViewVisibility;
    viewVisibility: GQBiSavedViewVisibility;
    updatedAt: Date;
    createdAt: Date;
    timeIntervals: Array<YMInterval>;
    searchTerm: string | null;
    owner: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    };
    order: {
      __typename?: 'BiQueryOrder';
      dimensionOrMeasure: string;
      direction: GQBiQueryOrderDirection;
    } | null;
    filters: Array<{
      __typename?: 'BiQueryFilter';
      dimension: string;
      operator: GQBiFilterOperator;
      value: Array<FilterValue | null>;
    }>;
    normalizedMeasures: Array<{
      __typename?: 'BiDynamicNormalizingMeasure';
      baseMeasure: string;
      normalizingQuery: {
        __typename?: 'BiDynamicNormalizingQuery';
        measure: string;
        computedDimensionType: GQDimensionComputedType | null;
        computedFiltersType: GQDimensionComputedType | null;
        dimensions: Array<string> | null;
        filters: Array<{
          __typename?: 'BiQueryFilter';
          dimension: string;
          operator: GQBiFilterOperator;
          value: Array<FilterValue | null>;
        }> | null;
      } | null;
    }>;
    customMetrics: Array<{
      __typename?: 'BiCustomMetricRef';
      id: string | null;
      stableId: string;
    }> | null;
    queryContext: {
      __typename?: 'BiSavedViewSnapshotQueryContext';
      footprintKind: string;
    } | null;
  }> | null;
};

export type GQCustomReportsWithSavedViewQueryVariables = Exact<{
  input: GQCustomReportsWithBiSavedViewInput;
}>;

export type GQCustomReportsWithSavedViewQuery = {
  __typename?: 'Query';
  customReportsWithBiSavedView: Array<{
    __typename?: 'Report';
    id: string;
    name: string;
    config: { __typename?: 'ReportConfig'; id: string; updatedAt: Date };
  }>;
};

export type GQBiCustomMetricsQueryVariables = Exact<{ [key: string]: never }>;

export type GQBiCustomMetricsQuery = {
  __typename?: 'Query';
  biCustomMetrics: Array<{
    __typename?: 'BiCustomMetric';
    id: string;
    stableId: string;
    name: string;
    expression: Expression;
    creator: { __typename?: 'User'; id: string; displayName: string };
  }>;
};

export type GQCreateBiCustomMetricMutationVariables = Exact<{
  input: GQCreateBiCustomMetricInput;
}>;

export type GQCreateBiCustomMetricMutation = {
  __typename?: 'Mutation';
  createBiCustomMetric: {
    __typename?: 'BiCustomMetric';
    id: string;
    stableId: string;
    name: string;
    expression: Expression;
  };
};

export type GQUpdateBiCustomMetricMutationVariables = Exact<{
  input: GQUpdateBiCustomMetricInput;
}>;

export type GQUpdateBiCustomMetricMutation = {
  __typename?: 'Mutation';
  updateBiCustomMetric: {
    __typename?: 'BiCustomMetric';
    id: string;
    stableId: string;
    name: string;
    expression: Expression;
  };
};

export type GQDeleteBiCustomMetricMutationVariables = Exact<{
  input: GQDeleteBiCustomMetricInput;
}>;

export type GQDeleteBiCustomMetricMutation = {
  __typename?: 'Mutation';
  deleteBiCustomMetric: {
    __typename?: 'BiCustomMetric';
    id: string;
    stableId: string;
    name: string;
    expression: Expression;
  };
};

export type GQFootprintKindMetaForDrilldownFragment = {
  __typename?: 'SnapshotFootprintKindMeta';
  id: string;
  footprintKind: string;
  name: string;
  description: string;
  footprintInterval: YMInterval | null;
};

export type GQGetFootprintKindsForSnapshotQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
}>;

export type GQGetFootprintKindsForSnapshotQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    snapshotFootprintKindsMeta: Array<{
      __typename?: 'SnapshotFootprintKindMeta';
      id: string;
      footprintKind: string;
      name: string;
      description: string;
      footprintInterval: YMInterval | null;
    }> | null;
  } | null;
};

export type GQFootprintSnapshotSelectorVersionQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
}>;

export type GQFootprintSnapshotSelectorVersionQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      kind: GQFootprintVersionKind;
      versionName: string | null;
      versionIdentifier: string | null;
      originalFootprintSnapshotId: string;
    } | null;
  } | null;
};

export type GQEmissionsModelsOverviewQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
}>;

export type GQEmissionsModelsOverviewQuery = {
  __typename?: 'Query';
  emissionsModelVersionsForFootprint: Array<{
    __typename?: 'EmissionsModelVersionWithReleaseIds';
    id: string;
    releaseIds: Array<string>;
    emv: {
      __typename?: 'EmissionsModelVersion';
      id: string;
      title: string;
      description: string;
      createdAt: Date;
    };
  }>;
};

export type GQMethodologiesOverviewQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
}>;

export type GQMethodologiesOverviewQuery = {
  __typename?: 'Query';
  calculationMethodVersionsForFootprint: Array<{
    __typename?: 'EmissionsModelRelease';
    id: string;
    businessActivityTypeName: string;
    displayName: string;
    externalNotes: string;
    updatedAt: Date;
  }>;
};

export type GQCreateClimateProgramProjectMutationVariables = Exact<{
  input: GQCreateClimateProgramProjectInput;
}>;

export type GQCreateClimateProgramProjectMutation = {
  __typename?: 'Mutation';
  createClimateProgramProject: {
    __typename?: 'ClimateProgramProjectPayload';
    climateProgramProject: {
      __typename: 'ClimateProgramProject';
      id: string;
      name: string;
      description: string;
      kind: GQClimateProgramProjectKind;
      startDate: Date | null;
      endDate: Date | null;
      plannedYear: number;
      completedAt: Date | null;
      status: GQClimateProgramProjectStatus;
      iconType: GQClimateProgramProjectIconType;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
      } | null;
      canonicalClimateProgramProject: {
        __typename?: 'CanonicalClimateProgramProject';
        id: string;
        routeType: string | null;
        name: string;
      } | null;
    };
  };
};

export type GQCreateClimateProgramCalendarEventMutationVariables = Exact<{
  input: GQCreateClimateProgramCalendarEventInput;
}>;

export type GQCreateClimateProgramCalendarEventMutation = {
  __typename?: 'Mutation';
  createClimateProgramCalendarEvent: {
    __typename?: 'ClimateProgramCalendarEventPayload';
    climateProgramCalendarEvent: {
      __typename: 'ClimateProgramCalendarEvent';
      id: string;
      name: string;
      startDate: Date;
      endDate: Date | null;
    };
  };
};

export type GQUpdateClimateProgramCalendarEventMutationVariables = Exact<{
  input: GQUpdateClimateProgramCalendarEventInput;
}>;

export type GQUpdateClimateProgramCalendarEventMutation = {
  __typename?: 'Mutation';
  updateClimateProgramCalendarEvent: {
    __typename?: 'ClimateProgramCalendarEventPayload';
    climateProgramCalendarEvent: {
      __typename: 'ClimateProgramCalendarEvent';
      id: string;
      name: string;
      startDate: Date;
      endDate: Date | null;
    };
  };
};

export type GQDeleteClimateProgramCalendarEventMutationVariables = Exact<{
  input: GQDeleteClimateProgramCalendarEventInput;
}>;

export type GQDeleteClimateProgramCalendarEventMutation = {
  __typename?: 'Mutation';
  deleteClimateProgramCalendarEvent: {
    __typename?: 'DeleteClimateProgramCalendarEventPayload';
    id: string;
  };
};

export type GQUpdateClimateProgramProjectMutationVariables = Exact<{
  input: GQUpdateClimateProgramProjectInput;
}>;

export type GQUpdateClimateProgramProjectMutation = {
  __typename?: 'Mutation';
  updateClimateProgramProject: {
    __typename?: 'ClimateProgramProjectPayload';
    climateProgramProject: {
      __typename: 'ClimateProgramProject';
      id: string;
      name: string;
      description: string;
      kind: GQClimateProgramProjectKind;
      startDate: Date | null;
      endDate: Date | null;
      plannedYear: number;
      completedAt: Date | null;
      status: GQClimateProgramProjectStatus;
      iconType: GQClimateProgramProjectIconType;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
      } | null;
      canonicalClimateProgramProject: {
        __typename?: 'CanonicalClimateProgramProject';
        id: string;
        routeType: string | null;
        name: string;
      } | null;
    };
  };
};

export type GQDeleteClimateProgramProjectMutationVariables = Exact<{
  input: GQDeleteClimateProgramProjectInput;
}>;

export type GQDeleteClimateProgramProjectMutation = {
  __typename?: 'Mutation';
  deleteClimateProgramProject: {
    __typename?: 'DeleteClimateProgramProjectPayload';
    id: string;
  };
};

export type GQUpdateClimateProgramTimelineProjectMutationVariables = Exact<{
  input: GQUpdateClimateProgramTimelineProjectInput;
}>;

export type GQUpdateClimateProgramTimelineProjectMutation = {
  __typename?: 'Mutation';
  updateClimateProgramTimelineProject: {
    __typename?: 'ClimateProgramTimelineProjectPayload';
    climateProgramTimelineProject: {
      __typename: 'ClimateProgramTimelineProject';
      id: string;
      name: string;
      description: string;
      kind: GQClimateProgramProjectKind;
      startDate: Date | null;
      endDate: Date | null;
      plannedYear: number;
      completedAt: Date | null;
      iconType: GQClimateProgramProjectIconType;
      objectType: GQClimateProgramTimelineProjectObjectType;
      object:
        | { __typename: 'ClimateProgramProject'; id: string }
        | {
            __typename: 'MeasurementProject';
            id: string;
            name: string;
            setupAt: Date | null;
            completedAt: Date | null;
            measurementTimeline: {
              __typename?: 'MeasurementTimeline';
              id: string;
              kickoff: Date;
              deadline: Date;
            } | null;
          }
        | {
            __typename: 'Report';
            id: string;
            name: string;
            createdAt: Date;
            reportKind: string;
            config: {
              __typename?: 'ReportConfig';
              id: string;
              reportType: string;
            };
          };
      canonicalClimateProgramProject: {
        __typename?: 'CanonicalClimateProgramProject';
        id: string;
        name: string;
        routeType: string | null;
      } | null;
    };
  };
};

export type GQDeleteClimateProgramTimelineProjectMutationVariables = Exact<{
  input: GQDeleteClimateProgramTimelineProjectInput;
}>;

export type GQDeleteClimateProgramTimelineProjectMutation = {
  __typename?: 'Mutation';
  deleteClimateProgramTimelineProject: {
    __typename?: 'DeleteClimateProgramTimelineProjectPayload';
    id: string;
    objectType: GQClimateProgramTimelineProjectObjectType;
  };
};

export type GQGetClimateProgramProjectQueryVariables = Exact<{
  cppId: Scalars['ID']['input'];
}>;

export type GQGetClimateProgramProjectQuery = {
  __typename?: 'Query';
  climateProgramProject: {
    __typename: 'ClimateProgramProject';
    id: string;
    name: string;
    description: string;
    kind: GQClimateProgramProjectKind;
    startDate: Date | null;
    endDate: Date | null;
    plannedYear: number;
    completedAt: Date | null;
    status: GQClimateProgramProjectStatus;
    iconType: GQClimateProgramProjectIconType;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
    } | null;
    canonicalClimateProgramProject: {
      __typename?: 'CanonicalClimateProgramProject';
      id: string;
      routeType: string | null;
      name: string;
    } | null;
  };
};

export type GQClimateProgramProjectFieldsFragment = {
  __typename: 'ClimateProgramProject';
  id: string;
  name: string;
  description: string;
  kind: GQClimateProgramProjectKind;
  startDate: Date | null;
  endDate: Date | null;
  plannedYear: number;
  completedAt: Date | null;
  status: GQClimateProgramProjectStatus;
  iconType: GQClimateProgramProjectIconType;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
  } | null;
  canonicalClimateProgramProject: {
    __typename?: 'CanonicalClimateProgramProject';
    id: string;
    routeType: string | null;
    name: string;
  } | null;
};

export type GQClimateProgramTimelineProjectFieldsFragment = {
  __typename: 'ClimateProgramTimelineProject';
  id: string;
  name: string;
  description: string;
  kind: GQClimateProgramProjectKind;
  startDate: Date | null;
  endDate: Date | null;
  plannedYear: number;
  completedAt: Date | null;
  iconType: GQClimateProgramProjectIconType;
  objectType: GQClimateProgramTimelineProjectObjectType;
  object:
    | { __typename: 'ClimateProgramProject'; id: string }
    | {
        __typename: 'MeasurementProject';
        id: string;
        name: string;
        setupAt: Date | null;
        completedAt: Date | null;
        measurementTimeline: {
          __typename?: 'MeasurementTimeline';
          id: string;
          kickoff: Date;
          deadline: Date;
        } | null;
      }
    | {
        __typename: 'Report';
        id: string;
        name: string;
        createdAt: Date;
        reportKind: string;
        config: { __typename?: 'ReportConfig'; id: string; reportType: string };
      };
  canonicalClimateProgramProject: {
    __typename?: 'CanonicalClimateProgramProject';
    id: string;
    name: string;
    routeType: string | null;
  } | null;
};

export type GQLinkedReportFieldsFragment = {
  __typename?: 'Report';
  id: string;
  name: string;
  createdAt: Date;
  reportKind: string;
  config: { __typename?: 'ReportConfig'; id: string; reportType: string };
};

export type GQLinkedMeasurementFieldsFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  setupAt: Date | null;
  completedAt: Date | null;
  measurementTimeline: {
    __typename?: 'MeasurementTimeline';
    id: string;
    kickoff: Date;
    deadline: Date;
  } | null;
};

export type GQClimateProgramProjectRequirementDatasetFieldsFragment = {
  __typename?: 'ClimateProgramProjectRequirementDataset';
  id: string;
  additionalNotesMd: string | null;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    name: string;
    instructions: {
      __typename?: 'InstructionsBundle';
      instructionsIntroMd: string | null;
    } | null;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
      instructions: {
        __typename?: 'InstructionsBundle';
        sdiInstructionsIntroMd: string | null;
      } | null;
    } | null;
  };
  canonicalGhgpRequirement: {
    __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
    id: string;
    ghgScope: number;
    ghgScope3Category: number | null;
    minimumFidelity: GQClimateProgramProjectRequirementFidelity;
  } | null;
};

export type GQCanonicalClimateProgramProjectMeasurementCopyFragment = {
  __typename: 'CanonicalClimateProgramProject';
  id: string;
  kind: GQClimateProgramProjectKind;
  measurementObjectCopy: string | null;
  routeType: string | null;
  name: string;
};

export type GQClimateProgramProjectMeasurementCopyFragment = {
  __typename: 'ClimateProgramProject';
  id: string;
  canonicalClimateProgramProject: {
    __typename: 'CanonicalClimateProgramProject';
    id: string;
    kind: GQClimateProgramProjectKind;
    measurementObjectCopy: string | null;
    routeType: string | null;
    name: string;
  } | null;
};

export type GQClimateProgramProjectMeasurementConcernsFieldsFragment = {
  __typename: 'ClimateProgramProject';
  id: string;
  datasetRequirements: Array<{
    __typename?: 'ClimateProgramProjectRequirementDataset';
    id: string;
    additionalNotesMd: string | null;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsIntroMd: string | null;
      } | null;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
        instructions: {
          __typename?: 'InstructionsBundle';
          sdiInstructionsIntroMd: string | null;
        } | null;
      } | null;
    };
    canonicalGhgpRequirement: {
      __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
      id: string;
      ghgScope: number;
      ghgScope3Category: number | null;
      minimumFidelity: GQClimateProgramProjectRequirementFidelity;
    } | null;
  }>;
  canonicalClimateProgramProject: {
    __typename: 'CanonicalClimateProgramProject';
    id: string;
    kind: GQClimateProgramProjectKind;
    measurementObjectCopy: string | null;
    routeType: string | null;
    name: string;
  } | null;
};

export type GQClimateProgramProjectWithMeasurementConcernsFieldsFragment = {
  __typename: 'ClimateProgramProject';
  id: string;
  name: string;
  description: string;
  kind: GQClimateProgramProjectKind;
  startDate: Date | null;
  endDate: Date | null;
  plannedYear: number;
  completedAt: Date | null;
  status: GQClimateProgramProjectStatus;
  iconType: GQClimateProgramProjectIconType;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
  } | null;
  canonicalClimateProgramProject: {
    __typename: 'CanonicalClimateProgramProject';
    id: string;
    routeType: string | null;
    name: string;
    kind: GQClimateProgramProjectKind;
    measurementObjectCopy: string | null;
  } | null;
  datasetRequirements: Array<{
    __typename?: 'ClimateProgramProjectRequirementDataset';
    id: string;
    additionalNotesMd: string | null;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsIntroMd: string | null;
      } | null;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
        instructions: {
          __typename?: 'InstructionsBundle';
          sdiInstructionsIntroMd: string | null;
        } | null;
      } | null;
    };
    canonicalGhgpRequirement: {
      __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
      id: string;
      ghgScope: number;
      ghgScope3Category: number | null;
      minimumFidelity: GQClimateProgramProjectRequirementFidelity;
    } | null;
  }>;
};

export type GQCanonicalClimateProgramProjectForAddProjectFragment = {
  __typename?: 'CanonicalClimateProgramProject';
  id: string;
  name: string;
  description: string;
  costEstimate: string | null;
  timeEstimate: string | null;
  iconType: GQClimateProgramProjectIconType;
  hasBenchmarkMapping: boolean;
  matchObjectType: string | null;
  reportKind: string | null;
  routeType: string | null;
  kind: GQClimateProgramProjectKind;
  peerBenchmarkCompanies: Array<{
    __typename?: 'Company';
    id: string;
    name: string;
    fullLogoUrl: string | null;
  }>;
};

export type GQCanonicalClimateProgramProjectWithMeasurementConcernsFieldsFragment =
  {
    __typename: 'CanonicalClimateProgramProject';
    id: string;
    kind: GQClimateProgramProjectKind;
    measurementObjectCopy: string | null;
    routeType: string | null;
    name: string;
    description: string;
    costEstimate: string | null;
    timeEstimate: string | null;
    iconType: GQClimateProgramProjectIconType;
    hasBenchmarkMapping: boolean;
    matchObjectType: string | null;
    reportKind: string | null;
    peerBenchmarkCompanies: Array<{
      __typename?: 'Company';
      id: string;
      name: string;
      fullLogoUrl: string | null;
    }>;
  };

export type GQClimateProgramCalendarEventFieldsFragment = {
  __typename: 'ClimateProgramCalendarEvent';
  id: string;
  name: string;
  startDate: Date;
  endDate: Date | null;
};

export type GQSetPreferredLocaleMutationVariables = Exact<{
  input: GQSetPreferredLocaleInput;
}>;

export type GQSetPreferredLocaleMutation = {
  __typename?: 'Mutation';
  setPreferredLocale: {
    __typename?: 'SetPreferredLocalePayload';
    preferredLocale: string;
  };
};

export type GQUserUploadedTableForDatasetPageFragment = {
  __typename?: 'UserUploadedTable';
  extBartId: string | null;
  businessActivityType: string | null;
  id: string;
  sheetName: string | null;
  sheetIndex: number | null;
  errorMessage: string | null;
  errorType: string | null;
  numRows: number | null;
  status: GQUserUploadedTableStatus;
  schema: Array<{
    __typename?: 'UserUploadedTableSchemaColumn';
    index: number;
    kind: GQUserUploadedTableSchemaColumnKind;
    name: string;
    alias: string;
  }> | null;
  uirSchema: Array<{
    __typename?: 'UserUploadedTableSchemaColumn';
    index: number;
    kind: GQUserUploadedTableSchemaColumnKind;
    name: string;
    alias: string;
  }> | null;
  parseConfig: {
    __typename?: 'UserUploadedTableParseConfig';
    noHeader: boolean;
    skipTopNRows: number;
    skipBottomNRows: number;
    meltIdVars: Array<string | null> | null;
  } | null;
  roboCleanerResult: {
    __typename?: 'UserUploadedTableRoboCleanerResult';
    skipTopNRows: number;
  } | null;
  userReviewMetadata: {
    __typename?: 'UserUploadedTableUserReviewMetadata';
    headerRowIndex: number;
  } | null;
  parentFileMetadata: { __typename?: 'FileMetadata'; id: string; name: string };
  dataPreview: {
    __typename?: 'UserUploadedTableDataPreview';
    dataUntyped: any;
  } | null;
  rawDataPreview: {
    __typename?: 'UserUploadedTableDataPreview';
    dataUntyped: any;
  } | null;
  rawDataUnshiftedPreview: {
    __typename?: 'UserUploadedTableDataPreview';
    dataUntyped: any;
  } | null;
};

export type GQUserUploadAttemptFieldsForDatasetPageFragment = {
  __typename?: 'UserUploadAttempt';
  id: string;
  name: string;
  createdAt: Date;
  size: number;
  description: string | null;
  remoteWritten: boolean;
  uploader: { __typename?: 'User'; id: string; name: string } | null;
  userUploadedTables: Array<{
    __typename?: 'UserUploadedTable';
    extBartId: string | null;
    businessActivityType: string | null;
    id: string;
    sheetName: string | null;
    sheetIndex: number | null;
    errorMessage: string | null;
    errorType: string | null;
    numRows: number | null;
    status: GQUserUploadedTableStatus;
    schema: Array<{
      __typename?: 'UserUploadedTableSchemaColumn';
      index: number;
      kind: GQUserUploadedTableSchemaColumnKind;
      name: string;
      alias: string;
    }> | null;
    uirSchema: Array<{
      __typename?: 'UserUploadedTableSchemaColumn';
      index: number;
      kind: GQUserUploadedTableSchemaColumnKind;
      name: string;
      alias: string;
    }> | null;
    parseConfig: {
      __typename?: 'UserUploadedTableParseConfig';
      noHeader: boolean;
      skipTopNRows: number;
      skipBottomNRows: number;
      meltIdVars: Array<string | null> | null;
    } | null;
    roboCleanerResult: {
      __typename?: 'UserUploadedTableRoboCleanerResult';
      skipTopNRows: number;
    } | null;
    userReviewMetadata: {
      __typename?: 'UserUploadedTableUserReviewMetadata';
      headerRowIndex: number;
    } | null;
    parentFileMetadata: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
    };
    dataPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
    rawDataPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
    rawDataUnshiftedPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
  }>;
  latestUutsIfRevisionExists: Array<{
    __typename?: 'UserUploadedTable';
    extBartId: string | null;
    businessActivityType: string | null;
    id: string;
    sheetName: string | null;
    sheetIndex: number | null;
    errorMessage: string | null;
    errorType: string | null;
    numRows: number | null;
    status: GQUserUploadedTableStatus;
    schema: Array<{
      __typename?: 'UserUploadedTableSchemaColumn';
      index: number;
      kind: GQUserUploadedTableSchemaColumnKind;
      name: string;
      alias: string;
    }> | null;
    uirSchema: Array<{
      __typename?: 'UserUploadedTableSchemaColumn';
      index: number;
      kind: GQUserUploadedTableSchemaColumnKind;
      name: string;
      alias: string;
    }> | null;
    parseConfig: {
      __typename?: 'UserUploadedTableParseConfig';
      noHeader: boolean;
      skipTopNRows: number;
      skipBottomNRows: number;
      meltIdVars: Array<string | null> | null;
    } | null;
    roboCleanerResult: {
      __typename?: 'UserUploadedTableRoboCleanerResult';
      skipTopNRows: number;
    } | null;
    userReviewMetadata: {
      __typename?: 'UserUploadedTableUserReviewMetadata';
      headerRowIndex: number;
    } | null;
    parentFileMetadata: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
    };
    dataPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
    rawDataPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
    rawDataUnshiftedPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
  }> | null;
};

export type GQUserUploadForDatasetPageFragment = {
  __typename?: 'UserUpload';
  id: string;
  name: string;
  category: GQFileCategory;
  createdAt: Date;
  processingMode: GQUserUploadProcessingMode | null;
  revisionRoot: string | null;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    deletedAt: Date | null;
  } | null;
  uploader: { __typename?: 'User'; id: string; name: string } | null;
  userVisibleAttempt: {
    __typename?: 'UserUploadAttempt';
    id: string;
    name: string;
    createdAt: Date;
    size: number;
    description: string | null;
    remoteWritten: boolean;
    uploader: { __typename?: 'User'; id: string; name: string } | null;
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      extBartId: string | null;
      businessActivityType: string | null;
      id: string;
      sheetName: string | null;
      sheetIndex: number | null;
      errorMessage: string | null;
      errorType: string | null;
      numRows: number | null;
      status: GQUserUploadedTableStatus;
      schema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      uirSchema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      parseConfig: {
        __typename?: 'UserUploadedTableParseConfig';
        noHeader: boolean;
        skipTopNRows: number;
        skipBottomNRows: number;
        meltIdVars: Array<string | null> | null;
      } | null;
      roboCleanerResult: {
        __typename?: 'UserUploadedTableRoboCleanerResult';
        skipTopNRows: number;
      } | null;
      userReviewMetadata: {
        __typename?: 'UserUploadedTableUserReviewMetadata';
        headerRowIndex: number;
      } | null;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
      };
      dataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataUnshiftedPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
    }>;
    latestUutsIfRevisionExists: Array<{
      __typename?: 'UserUploadedTable';
      extBartId: string | null;
      businessActivityType: string | null;
      id: string;
      sheetName: string | null;
      sheetIndex: number | null;
      errorMessage: string | null;
      errorType: string | null;
      numRows: number | null;
      status: GQUserUploadedTableStatus;
      schema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      uirSchema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      parseConfig: {
        __typename?: 'UserUploadedTableParseConfig';
        noHeader: boolean;
        skipTopNRows: number;
        skipBottomNRows: number;
        meltIdVars: Array<string | null> | null;
      } | null;
      roboCleanerResult: {
        __typename?: 'UserUploadedTableRoboCleanerResult';
        skipTopNRows: number;
      } | null;
      userReviewMetadata: {
        __typename?: 'UserUploadedTableUserReviewMetadata';
        headerRowIndex: number;
      } | null;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
      };
      dataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataUnshiftedPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
    }> | null;
  } | null;
  userUploadTask: {
    __typename?: 'UserUploadTask';
    id: string;
    datasource: { __typename?: 'Datasource'; id: string; name: string };
  } | null;
};

export type GQDatasetForSpecificDatasetPageFragment = {
  __typename?: 'Dataset';
  id: string;
  name: string;
  explainer: string | null;
  usedFor: string | null;
  potentialOverlaps: string | null;
  canonicalDataset: {
    __typename?: 'CanonicalDataset';
    id: string;
    name: string;
    kind: GQCanonicalDatasetKind;
    instructions: {
      __typename?: 'InstructionsBundle';
      instructionsIntroMd: string | null;
      instructionsDataFormattingMd: string | null;
      sdiInstructionsIntroMd: string | null;
      sdiInstructionsDataFormattingMd: string | null;
      ghgScopes: Array<GQGhgScope> | null;
      instructionsSteps: Array<{
        __typename?: 'InstructionsStep';
        descriptionMd: string;
        publicUrl: string | null;
      }> | null;
      sdiInstructionsSteps: Array<{
        __typename?: 'SdiInstructionsStep';
        title: string;
        descriptionMd: string;
      }> | null;
      sdiInstructionsFaq: Array<{
        __typename?: 'SdiInstructionsFaqType';
        question: string;
        answerMd: string;
      }> | null;
    } | null;
    customerTargetSchemas: Array<{
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      utilityType: GQBuildingUtilityType | null;
      rank: number;
      latestPublishedVersion: {
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        formattingNotesMd: string | null;
        orgDatasetExampleData: Array<{
          __typename?: 'IngestionExampleData';
          id: string;
          title: string;
          datasetId: string | null;
          columns: Array<{
            __typename?: 'IngestionExampleDataColumn';
            fieldName: string;
            title: string;
            description: string;
            examples: Array<string>;
            required: boolean;
            optionalToMap: boolean | null;
            defaultValue: any | null;
          }>;
          rows: Array<{
            __typename?: 'IngestionExampleDataRow';
            fields: Array<{
              __typename?: 'IngestionExampleDataRowField';
              fieldName: string;
              value: string;
            }>;
          }>;
        }>;
        transforms: Array<{
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          businessActivityTypeName: string | null;
          businessActivityTypeVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
  datasources: Array<{
    __typename?: 'Datasource';
    id: string;
    name: string;
    userUploadTasks: {
      __typename?: 'UserUploadTaskConnection';
      edges: Array<{
        __typename?: 'UserUploadTaskEdge';
        node: {
          __typename?: 'UserUploadTask';
          id: string;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
          };
          measurementTask: {
            __typename?: 'MeasurementTaskFlat';
            id: string;
            statusSimplified: GQTaskWatershedProcessStateSimplified;
            title: string;
          };
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          userUploads: {
            __typename?: 'UserUploadConnection';
            edges: Array<{
              __typename?: 'UserUploadEdge';
              node: {
                __typename?: 'UserUpload';
                id: string;
                name: string;
                category: GQFileCategory;
                createdAt: Date;
                processingMode: GQUserUploadProcessingMode | null;
                revisionRoot: string | null;
                measurementProject: {
                  __typename?: 'MeasurementProject';
                  id: string;
                  name: string;
                  deletedAt: Date | null;
                } | null;
                uploader: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                } | null;
                userVisibleAttempt: {
                  __typename?: 'UserUploadAttempt';
                  id: string;
                  name: string;
                  createdAt: Date;
                  size: number;
                  description: string | null;
                  remoteWritten: boolean;
                  uploader: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                  } | null;
                  userUploadedTables: Array<{
                    __typename?: 'UserUploadedTable';
                    extBartId: string | null;
                    businessActivityType: string | null;
                    id: string;
                    sheetName: string | null;
                    sheetIndex: number | null;
                    errorMessage: string | null;
                    errorType: string | null;
                    numRows: number | null;
                    status: GQUserUploadedTableStatus;
                    schema: Array<{
                      __typename?: 'UserUploadedTableSchemaColumn';
                      index: number;
                      kind: GQUserUploadedTableSchemaColumnKind;
                      name: string;
                      alias: string;
                    }> | null;
                    uirSchema: Array<{
                      __typename?: 'UserUploadedTableSchemaColumn';
                      index: number;
                      kind: GQUserUploadedTableSchemaColumnKind;
                      name: string;
                      alias: string;
                    }> | null;
                    parseConfig: {
                      __typename?: 'UserUploadedTableParseConfig';
                      noHeader: boolean;
                      skipTopNRows: number;
                      skipBottomNRows: number;
                      meltIdVars: Array<string | null> | null;
                    } | null;
                    roboCleanerResult: {
                      __typename?: 'UserUploadedTableRoboCleanerResult';
                      skipTopNRows: number;
                    } | null;
                    userReviewMetadata: {
                      __typename?: 'UserUploadedTableUserReviewMetadata';
                      headerRowIndex: number;
                    } | null;
                    parentFileMetadata: {
                      __typename?: 'FileMetadata';
                      id: string;
                      name: string;
                    };
                    dataPreview: {
                      __typename?: 'UserUploadedTableDataPreview';
                      dataUntyped: any;
                    } | null;
                    rawDataPreview: {
                      __typename?: 'UserUploadedTableDataPreview';
                      dataUntyped: any;
                    } | null;
                    rawDataUnshiftedPreview: {
                      __typename?: 'UserUploadedTableDataPreview';
                      dataUntyped: any;
                    } | null;
                  }>;
                  latestUutsIfRevisionExists: Array<{
                    __typename?: 'UserUploadedTable';
                    extBartId: string | null;
                    businessActivityType: string | null;
                    id: string;
                    sheetName: string | null;
                    sheetIndex: number | null;
                    errorMessage: string | null;
                    errorType: string | null;
                    numRows: number | null;
                    status: GQUserUploadedTableStatus;
                    schema: Array<{
                      __typename?: 'UserUploadedTableSchemaColumn';
                      index: number;
                      kind: GQUserUploadedTableSchemaColumnKind;
                      name: string;
                      alias: string;
                    }> | null;
                    uirSchema: Array<{
                      __typename?: 'UserUploadedTableSchemaColumn';
                      index: number;
                      kind: GQUserUploadedTableSchemaColumnKind;
                      name: string;
                      alias: string;
                    }> | null;
                    parseConfig: {
                      __typename?: 'UserUploadedTableParseConfig';
                      noHeader: boolean;
                      skipTopNRows: number;
                      skipBottomNRows: number;
                      meltIdVars: Array<string | null> | null;
                    } | null;
                    roboCleanerResult: {
                      __typename?: 'UserUploadedTableRoboCleanerResult';
                      skipTopNRows: number;
                    } | null;
                    userReviewMetadata: {
                      __typename?: 'UserUploadedTableUserReviewMetadata';
                      headerRowIndex: number;
                    } | null;
                    parentFileMetadata: {
                      __typename?: 'FileMetadata';
                      id: string;
                      name: string;
                    };
                    dataPreview: {
                      __typename?: 'UserUploadedTableDataPreview';
                      dataUntyped: any;
                    } | null;
                    rawDataPreview: {
                      __typename?: 'UserUploadedTableDataPreview';
                      dataUntyped: any;
                    } | null;
                    rawDataUnshiftedPreview: {
                      __typename?: 'UserUploadedTableDataPreview';
                      dataUntyped: any;
                    } | null;
                  }> | null;
                } | null;
                userUploadTask: {
                  __typename?: 'UserUploadTask';
                  id: string;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  };
                } | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
  }>;
};

export type GQDatasetIndexPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQDatasetIndexPageQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      status: GQMeasurementProjectStatus;
      createdAt: Date;
      completedAt: Date | null;
      coverageInterval: YMInterval;
      coverageStartDate: Date;
      coverageEndDate: Date;
      deadline: Date | null;
    }>;
  };
  dataset: {
    __typename?: 'Dataset';
    id: string;
    name: string;
    explainer: string | null;
    usedFor: string | null;
    potentialOverlaps: string | null;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      name: string;
      kind: GQCanonicalDatasetKind;
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsIntroMd: string | null;
        instructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
      customerTargetSchemas: Array<{
        __typename?: 'CustomerTargetSchema';
        id: string;
        name: string;
        utilityType: GQBuildingUtilityType | null;
        rank: number;
        latestPublishedVersion: {
          __typename?: 'CustomerTargetSchemaVersion';
          id: string;
          formattingNotesMd: string | null;
          orgDatasetExampleData: Array<{
            __typename?: 'IngestionExampleData';
            id: string;
            title: string;
            datasetId: string | null;
            columns: Array<{
              __typename?: 'IngestionExampleDataColumn';
              fieldName: string;
              title: string;
              description: string;
              examples: Array<string>;
              required: boolean;
              optionalToMap: boolean | null;
              defaultValue: any | null;
            }>;
            rows: Array<{
              __typename?: 'IngestionExampleDataRow';
              fields: Array<{
                __typename?: 'IngestionExampleDataRowField';
                fieldName: string;
                value: string;
              }>;
            }>;
          }>;
          transforms: Array<{
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            businessActivityTypeName: string | null;
            businessActivityTypeVersion: {
              __typename?: 'BusinessActivityTypeVersion';
              id: string;
            } | null;
          }>;
        } | null;
      }>;
    } | null;
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      name: string;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
            };
            measurementTask: {
              __typename?: 'MeasurementTaskFlat';
              id: string;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              title: string;
            };
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
              didLatestEmailBounce: boolean;
            }>;
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: {
                  __typename?: 'UserUpload';
                  id: string;
                  name: string;
                  category: GQFileCategory;
                  createdAt: Date;
                  processingMode: GQUserUploadProcessingMode | null;
                  revisionRoot: string | null;
                  measurementProject: {
                    __typename?: 'MeasurementProject';
                    id: string;
                    name: string;
                    deletedAt: Date | null;
                  } | null;
                  uploader: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                  } | null;
                  userVisibleAttempt: {
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    name: string;
                    createdAt: Date;
                    size: number;
                    description: string | null;
                    remoteWritten: boolean;
                    uploader: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                    } | null;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      extBartId: string | null;
                      businessActivityType: string | null;
                      id: string;
                      sheetName: string | null;
                      sheetIndex: number | null;
                      errorMessage: string | null;
                      errorType: string | null;
                      numRows: number | null;
                      status: GQUserUploadedTableStatus;
                      schema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      uirSchema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      parseConfig: {
                        __typename?: 'UserUploadedTableParseConfig';
                        noHeader: boolean;
                        skipTopNRows: number;
                        skipBottomNRows: number;
                        meltIdVars: Array<string | null> | null;
                      } | null;
                      roboCleanerResult: {
                        __typename?: 'UserUploadedTableRoboCleanerResult';
                        skipTopNRows: number;
                      } | null;
                      userReviewMetadata: {
                        __typename?: 'UserUploadedTableUserReviewMetadata';
                        headerRowIndex: number;
                      } | null;
                      parentFileMetadata: {
                        __typename?: 'FileMetadata';
                        id: string;
                        name: string;
                      };
                      dataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataUnshiftedPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                    }>;
                    latestUutsIfRevisionExists: Array<{
                      __typename?: 'UserUploadedTable';
                      extBartId: string | null;
                      businessActivityType: string | null;
                      id: string;
                      sheetName: string | null;
                      sheetIndex: number | null;
                      errorMessage: string | null;
                      errorType: string | null;
                      numRows: number | null;
                      status: GQUserUploadedTableStatus;
                      schema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      uirSchema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      parseConfig: {
                        __typename?: 'UserUploadedTableParseConfig';
                        noHeader: boolean;
                        skipTopNRows: number;
                        skipBottomNRows: number;
                        meltIdVars: Array<string | null> | null;
                      } | null;
                      roboCleanerResult: {
                        __typename?: 'UserUploadedTableRoboCleanerResult';
                        skipTopNRows: number;
                      } | null;
                      userReviewMetadata: {
                        __typename?: 'UserUploadedTableUserReviewMetadata';
                        headerRowIndex: number;
                      } | null;
                      parentFileMetadata: {
                        __typename?: 'FileMetadata';
                        id: string;
                        name: string;
                      };
                      dataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataUnshiftedPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                    }> | null;
                  } | null;
                  userUploadTask: {
                    __typename?: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    };
                  } | null;
                } | null;
              } | null>;
            } | null;
          } | null;
        } | null>;
      };
    }>;
  } | null;
};

export type GQInputDataByExtBartIdsQueryVariables = Exact<{
  extBartIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQInputDataByExtBartIdsQuery = {
  __typename?: 'Query';
  inputDataByExtBartIds: {
    __typename?: 'InputDataQueryResult';
    totalRows: number;
    rows: Array<any>;
  };
};

export type GQCreateDiscussionMutationVariables = Exact<{
  input: GQCreateDiscussionInput;
}>;

export type GQCreateDiscussionMutation = {
  __typename?: 'Mutation';
  createDiscussion: {
    __typename?: 'CreateDiscussionPayload';
    discussion: {
      __typename?: 'Discussion';
      id: string;
      title: string;
      description: string | null;
      state: GQDataIssueState;
      createdAt: Date;
      isCustomerInitiated: boolean;
      anchor: {
        __typename?: 'DiscussionAnchorPayload';
        userUploadTaskId: string | null;
        valueMappingTaskId: string | null;
        measurementVerificationItemQuestionId: string | null;
        financialsReviewItemId: string | null;
        productSection: GQDiscussionProductSection | null;
      };
      comments: {
        __typename?: 'CommentConnection';
        edges: Array<{
          __typename?: 'CommentEdge';
          node: {
            __typename?: 'Comment';
            id: string;
            message: string;
            createdAt: Date;
            person:
              | {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                  displayName: string;
                }
              | {
                  __typename?: 'WatershedEmployee';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                  displayName: string;
                }
              | null;
          } | null;
        } | null>;
      };
    };
  };
};

export type GQRespondToDiscussionMutationVariables = Exact<{
  input: GQRespondToDiscussionInput;
}>;

export type GQRespondToDiscussionMutation = {
  __typename?: 'Mutation';
  respondToDiscussion: {
    __typename?: 'RespondToDiscussionPayload';
    discussion: {
      __typename?: 'Discussion';
      id: string;
      title: string;
      description: string | null;
      state: GQDataIssueState;
      createdAt: Date;
      isCustomerInitiated: boolean;
      anchor: {
        __typename?: 'DiscussionAnchorPayload';
        userUploadTaskId: string | null;
        valueMappingTaskId: string | null;
        measurementVerificationItemQuestionId: string | null;
        financialsReviewItemId: string | null;
        productSection: GQDiscussionProductSection | null;
      };
      comments: {
        __typename?: 'CommentConnection';
        edges: Array<{
          __typename?: 'CommentEdge';
          node: {
            __typename?: 'Comment';
            id: string;
            message: string;
            createdAt: Date;
            person:
              | {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                  displayName: string;
                }
              | {
                  __typename?: 'WatershedEmployee';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                  displayName: string;
                }
              | null;
          } | null;
        } | null>;
      };
    };
  } | null;
};

export type GQResolveDiscussionMutationVariables = Exact<{
  input: GQResolveDiscussionInput;
}>;

export type GQResolveDiscussionMutation = {
  __typename?: 'Mutation';
  resolveDiscussion: {
    __typename?: 'ResolveDiscussionPayload';
    discussion: {
      __typename?: 'Discussion';
      id: string;
      title: string;
      description: string | null;
      state: GQDataIssueState;
      createdAt: Date;
      isCustomerInitiated: boolean;
      anchor: {
        __typename?: 'DiscussionAnchorPayload';
        userUploadTaskId: string | null;
        valueMappingTaskId: string | null;
        measurementVerificationItemQuestionId: string | null;
        financialsReviewItemId: string | null;
        productSection: GQDiscussionProductSection | null;
      };
      comments: {
        __typename?: 'CommentConnection';
        edges: Array<{
          __typename?: 'CommentEdge';
          node: {
            __typename?: 'Comment';
            id: string;
            message: string;
            createdAt: Date;
            person:
              | {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                  displayName: string;
                }
              | {
                  __typename?: 'WatershedEmployee';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                  displayName: string;
                }
              | null;
          } | null;
        } | null>;
      };
    };
  } | null;
};

export type GQCommentDiscussionsForAnchorFieldsFragment = {
  __typename?: 'DiscussionsPayload';
  id: string;
  discussions: Array<{
    __typename?: 'Discussion';
    id: string;
    title: string;
    description: string | null;
    state: GQDataIssueState;
    createdAt: Date;
    isCustomerInitiated: boolean;
    anchor: {
      __typename?: 'DiscussionAnchorPayload';
      userUploadTaskId: string | null;
      valueMappingTaskId: string | null;
      measurementVerificationItemQuestionId: string | null;
      financialsReviewItemId: string | null;
      productSection: GQDiscussionProductSection | null;
    };
    comments: {
      __typename?: 'CommentConnection';
      edges: Array<{
        __typename?: 'CommentEdge';
        node: {
          __typename?: 'Comment';
          id: string;
          message: string;
          createdAt: Date;
          person:
            | {
                __typename?: 'User';
                id: string;
                name: string;
                isWatershedEmployee: boolean;
                isWatershedContractor: boolean;
                displayName: string;
              }
            | {
                __typename?: 'WatershedEmployee';
                id: string;
                name: string;
                isWatershedEmployee: boolean;
                isWatershedContractor: boolean;
                displayName: string;
              }
            | null;
        } | null;
      } | null>;
    };
  }>;
};

export type GQCommentDiscussionsForAnchorQueryVariables = Exact<{
  input: GQDiscussionAnchor;
}>;

export type GQCommentDiscussionsForAnchorQuery = {
  __typename?: 'Query';
  commentDiscussionsForAnchor: {
    __typename?: 'DiscussionsPayload';
    id: string;
    discussions: Array<{
      __typename?: 'Discussion';
      id: string;
      title: string;
      description: string | null;
      state: GQDataIssueState;
      createdAt: Date;
      isCustomerInitiated: boolean;
      anchor: {
        __typename?: 'DiscussionAnchorPayload';
        userUploadTaskId: string | null;
        valueMappingTaskId: string | null;
        measurementVerificationItemQuestionId: string | null;
        financialsReviewItemId: string | null;
        productSection: GQDiscussionProductSection | null;
      };
      comments: {
        __typename?: 'CommentConnection';
        edges: Array<{
          __typename?: 'CommentEdge';
          node: {
            __typename?: 'Comment';
            id: string;
            message: string;
            createdAt: Date;
            person:
              | {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                  displayName: string;
                }
              | {
                  __typename?: 'WatershedEmployee';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                  displayName: string;
                }
              | null;
          } | null;
        } | null>;
      };
    }>;
  };
};

export type GQDiscussionCommentFieldsFragment = {
  __typename?: 'Comment';
  id: string;
  message: string;
  createdAt: Date;
  person:
    | {
        __typename?: 'User';
        id: string;
        name: string;
        isWatershedEmployee: boolean;
        isWatershedContractor: boolean;
        displayName: string;
      }
    | {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
        isWatershedEmployee: boolean;
        isWatershedContractor: boolean;
        displayName: string;
      }
    | null;
};

export type GQDiscussionAnchorFieldsFragment = {
  __typename?: 'DiscussionAnchorPayload';
  userUploadTaskId: string | null;
  valueMappingTaskId: string | null;
  measurementVerificationItemQuestionId: string | null;
  financialsReviewItemId: string | null;
  productSection: GQDiscussionProductSection | null;
};

export type GQDiscussionFieldsFragment = {
  __typename?: 'Discussion';
  id: string;
  title: string;
  description: string | null;
  state: GQDataIssueState;
  createdAt: Date;
  isCustomerInitiated: boolean;
  anchor: {
    __typename?: 'DiscussionAnchorPayload';
    userUploadTaskId: string | null;
    valueMappingTaskId: string | null;
    measurementVerificationItemQuestionId: string | null;
    financialsReviewItemId: string | null;
    productSection: GQDiscussionProductSection | null;
  };
  comments: {
    __typename?: 'CommentConnection';
    edges: Array<{
      __typename?: 'CommentEdge';
      node: {
        __typename?: 'Comment';
        id: string;
        message: string;
        createdAt: Date;
        person:
          | {
              __typename?: 'User';
              id: string;
              name: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
              displayName: string;
            }
          | {
              __typename?: 'WatershedEmployee';
              id: string;
              name: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
              displayName: string;
            }
          | null;
      } | null;
    } | null>;
  };
};

export type GQProductWaitlistTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQProductWaitlistTypesQuery = {
  __typename?: 'Query';
  productWaitlistTypes: Array<GQProductWaitlistType>;
};

export type GQJoinProductWaitlistMutationVariables = Exact<{
  type: GQProductWaitlistType;
}>;

export type GQJoinProductWaitlistMutation = {
  __typename?: 'Mutation';
  joinWaitlist: {
    __typename?: 'JoinProductWaitlistPayload';
    id: string;
    type: GQProductWaitlistType;
  };
};

export type GQCreateMarketplaceEacPurchaseMutationVariables = Exact<{
  input: GQCreateMarketplaceEacPurchaseInput;
}>;

export type GQCreateMarketplaceEacPurchaseMutation = {
  __typename?: 'Mutation';
  createMarketplaceEacPurchase: {
    __typename?: 'CreateMarketplaceEacPurchasePayload';
    marketplacePurchaseId: string | null;
  } | null;
};

export type GQEacPurchaseJoinProductWaitlistMutationVariables = Exact<{
  type: GQProductWaitlistType;
}>;

export type GQEacPurchaseJoinProductWaitlistMutation = {
  __typename?: 'Mutation';
  joinWaitlist: {
    __typename?: 'JoinProductWaitlistPayload';
    id: string;
    type: GQProductWaitlistType;
  };
};

export type GQGetCleanPowerLandingPageQueryVariables = Exact<{
  version: Scalars['String']['input'];
}>;

export type GQGetCleanPowerLandingPageQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    customFields: Array<string>;
  } | null;
  marketplacePurchases: {
    __typename?: 'MarketplacePurchaseConnection';
    edges: Array<{
      __typename?: 'MarketplacePurchaseEdge';
      node: {
        __typename?: 'MarketplacePurchase';
        id: string;
        customerStatus: GQCustomerStatus;
        eacMetadata: {
          __typename?: 'MarketplacePurchaseEacMetadata';
          id: string;
        } | null;
      } | null;
    } | null>;
  };
};

export type GQGetFootprintEnergyConsumptionBreakdownQueryVariables = Exact<{
  version: Scalars['String']['input'];
  interval: Scalars['YMInterval']['input'];
  groupBy: Array<Scalars['String']['input']> | Scalars['String']['input'];
  isGridOnly: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQGetFootprintEnergyConsumptionBreakdownQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    energyConsumptionBreakdown: {
      __typename?: 'EnergyConsumptionBreakdown';
      fields: Array<string>;
      totalFootprintTco2e: number;
      totalElectricityTco2e: number;
      totalCleanMwh: number;
      totalMwh: number;
      dataPoints: Array<{
        __typename?: 'EnergyConsumptionDataPoint';
        marketTco2e: number;
        locationTco2e: number;
        mwhConsumed: number;
        avoidedMarketTco2e: number;
        fieldValues: Array<{
          __typename?: 'FootprintFieldValue';
          field: string;
          value: string;
        }>;
      }>;
      totalFootprintResults: Array<{
        __typename?: 'FootprintKindTotals';
        footprintKind: string;
        totalTco2e: number;
      }>;
    };
  } | null;
};

export type GQEnergyBreakdownFieldsFragment = {
  __typename?: 'EnergyConsumptionBreakdown';
  fields: Array<string>;
  totalFootprintTco2e: number;
  totalElectricityTco2e: number;
  totalCleanMwh: number;
  totalMwh: number;
  dataPoints: Array<{
    __typename?: 'EnergyConsumptionDataPoint';
    marketTco2e: number;
    locationTco2e: number;
    mwhConsumed: number;
    avoidedMarketTco2e: number;
    fieldValues: Array<{
      __typename?: 'FootprintFieldValue';
      field: string;
      value: string;
    }>;
  }>;
  totalFootprintResults: Array<{
    __typename?: 'FootprintKindTotals';
    footprintKind: string;
    totalTco2e: number;
  }>;
};

export type GQEacPriceEstimateFieldsForDashboardFragment = {
  __typename?: 'EacPriceEstimate';
  id: string;
  country: string;
  alternativeCountries: Array<string>;
  minCreditPurchase: number;
  priceEstimateUsdCents: number;
};

export type GQGetEacPriceEstimatesForDashboardQueryVariables = Exact<{
  targetCurrency: Scalars['String']['input'];
}>;

export type GQGetEacPriceEstimatesForDashboardQuery = {
  __typename?: 'Query';
  eacPriceEstimates: Array<{
    __typename?: 'EacPriceEstimate';
    id: string;
    country: string;
    alternativeCountries: Array<string>;
    minCreditPurchase: number;
    priceEstimateUsdCents: number;
  }>;
  latestCurrencyExchangeRate: {
    __typename?: 'CurrencyExchangeRate';
    id: string;
    updatedAt: Date;
    currency: string;
    value: number;
  } | null;
};

export type GQRunAdvancedEnergyEstimationMutationVariables = Exact<{
  input: GQRunAdvancedEnergyEstimationInput;
}>;

export type GQRunAdvancedEnergyEstimationMutation = {
  __typename?: 'Mutation';
  runAdvancedEnergyEstimation: {
    __typename?: 'RunAdvancedEnergyEstimationPayload';
    jobId: string;
    jobStatus: string;
    countryProjectedEnergyConsumptions: Array<{
      __typename?: 'CountryProjectedEnergyConsumption';
      country: string;
      scope2: number;
      scope33: number;
      scope37: number;
    }>;
  } | null;
};

export type GQCompanyWithClimateCommitmentsFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
  fullLogoUrl: string | null;
  sustainabilityWebsiteUrl: string | null;
  disclosuresV2: Array<{
    __typename?: 'Disclosure';
    id: string;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      publishingYear: number;
      publicUrl: string;
    } | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
        }
      | {
          __typename?: 'NetZeroCommitment';
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
        }
    > | null;
  }>;
};

export type GQOrgPeerClimateProgramsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrgPeerClimateProgramsQuery = {
  __typename?: 'Query';
  peerCompanyGroups: Array<{
    __typename?: 'PeerCompanyGroup';
    id: string;
    members: Array<{
      __typename?: 'PeerCompanyGroupMember';
      id: string;
      company: {
        __typename?: 'Company';
        id: string;
        name: string;
        fullLogoUrl: string | null;
        sustainabilityWebsiteUrl: string | null;
        disclosuresV2: Array<{
          __typename?: 'Disclosure';
          id: string;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            publishingYear: number;
            publicUrl: string;
          } | null;
          climateCommitments: Array<
            | {
                __typename?: 'CarbonNeutralCommitment';
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
              }
            | {
                __typename?: 'CleanEnergyCommitment';
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
              }
            | {
                __typename?: 'NetZeroCommitment';
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
              }
            | {
                __typename?: 'ScienceBasedTargetCommitment';
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
              }
          > | null;
        }>;
      };
    }>;
  }>;
};

export type GQFootprintSnapshotsForYearQueryVariables = Exact<{
  year: Scalars['Int']['input'];
}>;

export type GQFootprintSnapshotsForYearQuery = {
  __typename?: 'Query';
  footprintSnapshotsForYear: Array<{
    __typename?: 'FootprintSnapshot';
    id: string;
  }>;
};

export type GQMeasurementProjectFieldsForShareStatusEngagementTaskFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  coverageStartDate: Date;
  coverageEndDate: Date;
  taskStatusDumps: Array<{
    __typename?: 'TaskStatusDump';
    taskId: string;
    taskName: string;
    assigneeNames: Array<string> | null;
    datasetName: string | null;
    status: GQTaskWatershedProcessStateSimplified;
  }>;
};

export type GQGetYearMeasurementProjectsQueryVariables = Exact<{
  reportingYear: Scalars['Int']['input'];
}>;

export type GQGetYearMeasurementProjectsQuery = {
  __typename?: 'Query';
  yearMeasurementProjects: Array<{
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    coverageStartDate: Date;
    coverageEndDate: Date;
    taskStatusDumps: Array<{
      __typename?: 'TaskStatusDump';
      taskId: string;
      taskName: string;
      assigneeNames: Array<string> | null;
      datasetName: string | null;
      status: GQTaskWatershedProcessStateSimplified;
    }>;
  }>;
};

export type GQAssetPageSnapshotQueryVariables = Exact<{
  standardView: InputMaybe<GQFinanceStandardView>;
  filters: InputMaybe<Scalars['UntypedData']['input']>;
  financeSnapshotId: Scalars['ID']['input'];
  assetId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
  isTotalEmissions: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQAssetPageSnapshotQuery = {
  __typename?: 'Query';
  financeHoldingView: {
    __typename?: 'FinanceView';
    charts: Array<{
      __typename?: 'FinanceChart';
      id: string;
      title: string;
      subtitle: string | null;
      chartKind: GQFinanceChartKind;
      tooltipText: string | null;
      data: any;
      isWideFormat: boolean | null;
      activeObjectType: GQFinanceChartActiveObjectType | null;
      highchartsKind: GQFinanceHighchartsChartKind;
      highchartsData: any;
      highchartsUnit: string | null;
      metric: GQFinanceChartMetric | null;
    }> | null;
    metricBar: {
      __typename?: 'FinanceMetricBar';
      id: string;
      metrics: Array<
        | {
            __typename: 'FinanceAggregateMetric';
            metric: GQFinanceMetric;
            name: string;
            description: string | null;
            quantity: number;
            unit: string | null;
            additionalInfo: string | null;
            isMissingInputs: boolean;
          }
        | {
            __typename: 'FinanceStringMetric';
            metric: GQFinanceMetric;
            name: string;
            description: string | null;
            value: string;
            unit: string | null;
            additionalInfo: string | null;
            isMissingInputs: boolean;
          }
      >;
      emptyMetrics: Array<
        | {
            __typename: 'FinanceAggregateMetric';
            metric: GQFinanceMetric;
            name: string;
            description: string | null;
          }
        | {
            __typename: 'FinanceStringMetric';
            metric: GQFinanceMetric;
            name: string;
            description: string | null;
          }
      >;
    } | null;
  };
  financeSnapshotAsset: {
    __typename?: 'FinanceSnapshotAsset';
    asset: any | null;
  };
};

export type GQFinanceSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GQFinanceSettingsQuery = {
  __typename?: 'Query';
  funds: Array<{
    __typename: 'Fund';
    id: string;
    name: string;
    nameSortable: string | null;
    fundGroup: string | null;
    fundCategory: string | null;
    excludeAsSandbox: boolean | null;
    anonymousFundUuid: string | null;
    createdAt: Date;
    orgId: string;
    updatedAt: Date;
    tagData: any | null;
    externalId: string | null;
  }>;
  financeTags: Array<{
    __typename?: 'FinanceTag';
    id: string;
    name: string;
    description: string | null;
    columnFormat: GQGridColumnFormat;
    columnConfiguration: any | null;
    tagType: GQFinanceTagType;
  }>;
};

export type GQSnapshotAssemblyCreateSnapshotMutationVariables = Exact<{
  input: GQCreateSnapshotInput;
}>;

export type GQSnapshotAssemblyCreateSnapshotMutation = {
  __typename?: 'Mutation';
  createSnapshot: {
    __typename?: 'CreateSnapshotPayload';
    snapshot: { __typename?: 'FinanceSnapshot'; id: string };
  };
};

export type GQSnapshotAssemblyFlowQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQSnapshotAssemblyFlowQuery = {
  __typename?: 'Query';
  financeSettings: {
    __typename?: 'FinanceSettings';
    id: string;
    estimateDownstreamScope3: boolean;
  };
  financeMetadata: {
    __typename?: 'FinanceMetadata';
    anyAssetYearHasEstimationMethodology: boolean;
    hasSomeAssetCorporate: boolean;
  };
};

export type GQGenerateAuditReportForAssetYearMutationVariables = Exact<{
  input: GQFinanceAuditReportForAssetYearInput;
}>;

export type GQGenerateAuditReportForAssetYearMutation = {
  __typename?: 'Mutation';
  generateAuditReportForAssetYear: {
    __typename?: 'FinanceAuditReportForAssetYearPayload';
    jobId: string;
  } | null;
};

export type GQFinanceChangelogLineItemFragment = {
  __typename?: 'FinanceChangelogLineItem';
  id: string;
  field: string | null;
  oldValue: string | null;
  newValue: string | null;
};

export type GQFinanceChangelogEntryFragment = {
  __typename?: 'FinanceChangelogEntry';
  id: string;
  createdAt: Date;
  eventKind: string;
  userDisplayName: string;
  objectLabel: string;
  assetId: string | null;
  fundId: string | null;
  lineItems: Array<{
    __typename?: 'FinanceChangelogLineItem';
    id: string;
    field: string | null;
    oldValue: string | null;
    newValue: string | null;
  }>;
};

export type GQFinanceLogEventsQueryVariables = Exact<{
  filters: InputMaybe<Array<GQBiQueryFilterInput> | GQBiQueryFilterInput>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQFinanceLogEventsQuery = {
  __typename?: 'Query';
  financeChangelog: {
    __typename?: 'FinanceChangelogConnection';
    edges: Array<{
      __typename?: 'FinanceChangelogEdge';
      node: {
        __typename?: 'FinanceChangelogEntry';
        id: string;
        createdAt: Date;
        eventKind: string;
        userDisplayName: string;
        objectLabel: string;
        assetId: string | null;
        fundId: string | null;
        lineItems: Array<{
          __typename?: 'FinanceChangelogLineItem';
          id: string;
          field: string | null;
          oldValue: string | null;
          newValue: string | null;
        }>;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
  };
};

export type GQAssetCorporateAllFieldsFragment = {
  __typename: 'AssetCorporate';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  naicsCode: string | null;
  companyIsin: string | null;
  companyLei: string | null;
  companyUrl: string | null;
  companyTicker: string | null;
  currencyCode: GQCurrencyCode;
  countryAlpha2: string | null;
  buildingSizeUnit: GQBuildingSizeUnit | null;
  companyId: string | null;
  otherData: any | null;
  tagData: any | null;
  externalId: string | null;
  assetGroupId: string | null;
  anonymousCompanyUuid: string | null;
};

export type GQUpdateAssetCorporateMutationVariables = Exact<{
  input: GQUpdateAssetCorporateInput;
}>;

export type GQUpdateAssetCorporateMutation = {
  __typename?: 'Mutation';
  updateAssetCorporate: {
    __typename?: 'UpdateAssetCorporatePayload';
    asset: {
      __typename: 'AssetCorporate';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      name: string;
      naicsCode: string | null;
      companyIsin: string | null;
      companyLei: string | null;
      companyUrl: string | null;
      companyTicker: string | null;
      currencyCode: GQCurrencyCode;
      countryAlpha2: string | null;
      buildingSizeUnit: GQBuildingSizeUnit | null;
      companyId: string | null;
      otherData: any | null;
      tagData: any | null;
      externalId: string | null;
      assetGroupId: string | null;
      anonymousCompanyUuid: string | null;
      company: {
        __typename?: 'Company';
        id: string;
        alternateNames: Array<string> | null;
        betterCompanyId: string | null;
        country: string | null;
        createdAt: Date;
        deletedAt: Date | null;
        description: string | null;
        isDemo: boolean;
        logoUrl: string | null;
        naicsCode: string | null;
        naicsCodeSource: string | null;
        name: string;
        sfdcOpportunityId: string | null;
        sfdcStage: string | null;
        source: string;
        sustainabilityWebsiteUrl: string | null;
        updatedAt: Date;
        url: string | null;
        userEditorId: string | null;
        logoUrlId: string | null;
        sustainabilityWebsiteUrlId: string | null;
        companyWebsiteUrlId: string | null;
        companyIngestionRecordUpdateId: string | null;
        sourceId: string;
      } | null;
    } | null;
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQAssetGroupAllFieldsFragment = {
  __typename: 'AssetGroup';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  currencyCode: GQCurrencyCode;
  buildingSizeUnit: GQBuildingSizeUnit | null;
  otherData: any | null;
  tagData: any | null;
  externalId: string | null;
  companyId: string | null;
  assetGroupId: string | null;
};

export type GQUpdateAssetGroupMutationVariables = Exact<{
  input: GQUpdateAssetGroupInput;
}>;

export type GQUpdateAssetGroupMutation = {
  __typename?: 'Mutation';
  updateAssetGroup: {
    __typename?: 'UpdateAssetGroupPayload';
    asset: {
      __typename: 'AssetGroup';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      name: string;
      currencyCode: GQCurrencyCode;
      buildingSizeUnit: GQBuildingSizeUnit | null;
      otherData: any | null;
      tagData: any | null;
      externalId: string | null;
      companyId: string | null;
      assetGroupId: string | null;
    } | null;
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQAssetHoldingAllFieldsFragment = {
  __typename: 'FinanceAssetHolding';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  assetYearId: string;
  fundId: string;
  yearOfInitialInvestment: number | null;
  outstandingAmountNative: number | null;
  outstandingAmountNativeQ1: number | null;
  outstandingAmountNativeQ2: number | null;
  outstandingAmountNativeQ3: number | null;
  outstandingAmountNativeQ4: number | null;
  currencyCode: GQCurrencyCode;
  currencyConversionRate: number;
  assetClass: GQFundAssetClass;
  tagData: any | null;
  premiumNative: number | null;
  emissionsYearId: string | null;
};

export type GQCreateAssetHoldingMutationVariables = Exact<{
  input: GQCreateAssetHoldingNewInput;
  year: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQCreateAssetHoldingMutation = {
  __typename?: 'Mutation';
  createAssetHolding: {
    __typename?: 'CreateAssetHoldingPayload';
    assetHolding: {
      __typename: 'FinanceAssetHolding';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      assetYearId: string;
      fundId: string;
      yearOfInitialInvestment: number | null;
      outstandingAmountNative: number | null;
      outstandingAmountNativeQ1: number | null;
      outstandingAmountNativeQ2: number | null;
      outstandingAmountNativeQ3: number | null;
      outstandingAmountNativeQ4: number | null;
      currencyCode: GQCurrencyCode;
      currencyConversionRate: number;
      assetClass: GQFundAssetClass;
      tagData: any | null;
      premiumNative: number | null;
      emissionsYearId: string | null;
      asset:
        | {
            __typename?: 'AssetCorporate';
            id: string;
            assetHoldings: Array<{
              __typename?: 'FinanceAssetHolding';
              id: string;
            }>;
          }
        | {
            __typename?: 'AssetGroup';
            id: string;
            assetHoldings: Array<{
              __typename?: 'FinanceAssetHolding';
              id: string;
            }>;
          }
        | {
            __typename?: 'AssetPersonalMotorVehicleInsurance';
            id: string;
            assetHoldings: Array<{
              __typename?: 'FinanceAssetHolding';
              id: string;
            }>;
          }
        | {
            __typename?: 'AssetRealEstate';
            id: string;
            assetHoldings: Array<{
              __typename?: 'FinanceAssetHolding';
              id: string;
            }>;
          }
        | {
            __typename?: 'AssetSovereignBond';
            id: string;
            assetHoldings: Array<{
              __typename?: 'FinanceAssetHolding';
              id: string;
            }>;
          }
        | null;
    };
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQDeleteAssetHoldingMutationVariables = Exact<{
  input: GQDeleteAssetHoldingInput;
  year: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQDeleteAssetHoldingMutation = {
  __typename?: 'Mutation';
  deleteAssetHolding: {
    __typename?: 'DeleteAssetHoldingPayload';
    id: string;
    asset:
      | {
          __typename?: 'AssetCorporate';
          id: string;
          assetHoldings: Array<{
            __typename?: 'FinanceAssetHolding';
            id: string;
          }>;
        }
      | {
          __typename?: 'AssetGroup';
          id: string;
          assetHoldings: Array<{
            __typename?: 'FinanceAssetHolding';
            id: string;
          }>;
        }
      | {
          __typename?: 'AssetPersonalMotorVehicleInsurance';
          id: string;
          assetHoldings: Array<{
            __typename?: 'FinanceAssetHolding';
            id: string;
          }>;
        }
      | {
          __typename?: 'AssetRealEstate';
          id: string;
          assetHoldings: Array<{
            __typename?: 'FinanceAssetHolding';
            id: string;
          }>;
        }
      | {
          __typename?: 'AssetSovereignBond';
          id: string;
          assetHoldings: Array<{
            __typename?: 'FinanceAssetHolding';
            id: string;
          }>;
        }
      | null;
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQUpdateAssetHoldingMutationVariables = Exact<{
  input: GQUpdateAssetHoldingNewInput;
}>;

export type GQUpdateAssetHoldingMutation = {
  __typename?: 'Mutation';
  updateAssetHolding: {
    __typename?: 'UpdateAssetHoldingPayload';
    assetHolding: {
      __typename: 'FinanceAssetHolding';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      assetYearId: string;
      fundId: string;
      yearOfInitialInvestment: number | null;
      outstandingAmountNative: number | null;
      outstandingAmountNativeQ1: number | null;
      outstandingAmountNativeQ2: number | null;
      outstandingAmountNativeQ3: number | null;
      outstandingAmountNativeQ4: number | null;
      currencyCode: GQCurrencyCode;
      currencyConversionRate: number;
      assetClass: GQFundAssetClass;
      tagData: any | null;
      premiumNative: number | null;
      emissionsYearId: string | null;
    };
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQAssetInfoPageQueryVariables = Exact<{
  assetId: Scalars['ID']['input'];
}>;

export type GQAssetInfoPageQuery = {
  __typename?: 'Query';
  asset:
    | {
        __typename: 'AssetCorporate';
        id: string;
        orgId: string;
        createdAt: Date;
        updatedAt: Date;
        name: string;
        naicsCode: string | null;
        companyIsin: string | null;
        companyLei: string | null;
        companyUrl: string | null;
        companyTicker: string | null;
        currencyCode: GQCurrencyCode;
        countryAlpha2: string | null;
        buildingSizeUnit: GQBuildingSizeUnit | null;
        companyId: string | null;
        otherData: any | null;
        tagData: any | null;
        externalId: string | null;
        assetGroupId: string | null;
        anonymousCompanyUuid: string | null;
        company: {
          __typename?: 'Company';
          id: string;
          naicsCode: string | null;
          sbtiStage: GQCompanySbtCommitmentStage;
          climateProgress: GQCompanyClimateProgress;
          sustainabilityWebsiteUrl: string | null;
          isWatershedCorporateCustomer: boolean;
          parentRelationships: Array<{
            __typename?: 'ParentRelationship';
            id: string;
            relationshipStartDate: YearMonth | null;
            relationshipEndDate: YearMonth | null;
            parent: {
              __typename?: 'Company';
              id: string;
              name: string;
              disclosuresV2: Array<{
                __typename?: 'Disclosure';
                id: string;
                company: { __typename?: 'Company'; id: string } | null;
                publicDisclosure: {
                  __typename?: 'PublicDisclosure';
                  id: string;
                  publishingYear: number;
                  reportType: GQExternalReportType;
                  publicUrl: string;
                  qualityScore: GQDisclosureQualityScore | null;
                  cdpVendorData: {
                    __typename?: 'CdpVendorData';
                    id: string;
                    publishingYear: number;
                    reportingYear: number;
                    scope1Nonzero: boolean;
                    scope2Nonzero: boolean;
                    scope3Nonzero: boolean;
                    totalEmissionsNonzero: boolean;
                    scope301Or302Nonzero: boolean;
                    scope1Verified: boolean;
                    scope2Verified: boolean;
                    scope3Verified: boolean;
                    pctEvaluationStatusesMatchResponse: number;
                    disclosureQualityScore: GQDisclosureQualityScore;
                    scope301EvaluationStatus: GQScope3EvaluationStatus;
                    scope302EvaluationStatus: GQScope3EvaluationStatus;
                    scope303EvaluationStatus: GQScope3EvaluationStatus;
                    scope304EvaluationStatus: GQScope3EvaluationStatus;
                    scope305EvaluationStatus: GQScope3EvaluationStatus;
                    scope306EvaluationStatus: GQScope3EvaluationStatus;
                    scope307EvaluationStatus: GQScope3EvaluationStatus;
                    scope308EvaluationStatus: GQScope3EvaluationStatus;
                    scope309EvaluationStatus: GQScope3EvaluationStatus;
                    scope310EvaluationStatus: GQScope3EvaluationStatus;
                    scope311EvaluationStatus: GQScope3EvaluationStatus;
                    scope312EvaluationStatus: GQScope3EvaluationStatus;
                    scope313EvaluationStatus: GQScope3EvaluationStatus;
                    scope314EvaluationStatus: GQScope3EvaluationStatus;
                    scope315EvaluationStatus: GQScope3EvaluationStatus;
                    scope316EvaluationStatus: GQScope3EvaluationStatus;
                    scope317EvaluationStatus: GQScope3EvaluationStatus;
                  } | null;
                } | null;
                privateDisclosure: {
                  __typename?: 'PrivateDisclosure';
                  id: string;
                  companyId: string | null;
                  orgId: string;
                  orgName: string;
                  surveyId: string | null;
                  privateDisclosureType: GQPrivateDisclosureType;
                  createdAt: Date;
                  disclosureQualityScore: GQDisclosureQualityScore | null;
                  disclosureQualityExplanation: string | null;
                  thirdPartyVerified: boolean;
                  cdpVendorData: {
                    __typename?: 'CdpVendorData';
                    id: string;
                    publishingYear: number;
                    reportingYear: number;
                    scope1Nonzero: boolean;
                    scope2Nonzero: boolean;
                    scope3Nonzero: boolean;
                    totalEmissionsNonzero: boolean;
                    scope301Or302Nonzero: boolean;
                    scope1Verified: boolean;
                    scope2Verified: boolean;
                    scope3Verified: boolean;
                    pctEvaluationStatusesMatchResponse: number;
                    disclosureQualityScore: GQDisclosureQualityScore;
                    scope301EvaluationStatus: GQScope3EvaluationStatus;
                    scope302EvaluationStatus: GQScope3EvaluationStatus;
                    scope303EvaluationStatus: GQScope3EvaluationStatus;
                    scope304EvaluationStatus: GQScope3EvaluationStatus;
                    scope305EvaluationStatus: GQScope3EvaluationStatus;
                    scope306EvaluationStatus: GQScope3EvaluationStatus;
                    scope307EvaluationStatus: GQScope3EvaluationStatus;
                    scope308EvaluationStatus: GQScope3EvaluationStatus;
                    scope309EvaluationStatus: GQScope3EvaluationStatus;
                    scope310EvaluationStatus: GQScope3EvaluationStatus;
                    scope311EvaluationStatus: GQScope3EvaluationStatus;
                    scope312EvaluationStatus: GQScope3EvaluationStatus;
                    scope313EvaluationStatus: GQScope3EvaluationStatus;
                    scope314EvaluationStatus: GQScope3EvaluationStatus;
                    scope315EvaluationStatus: GQScope3EvaluationStatus;
                    scope316EvaluationStatus: GQScope3EvaluationStatus;
                    scope317EvaluationStatus: GQScope3EvaluationStatus;
                  } | null;
                } | null;
                historicalEmissionsYears: Array<{
                  __typename?: 'EmissionsYear';
                  id: string;
                  publicDisclosureId: string | null;
                  reportingYear: number;
                  numEmployees: number | null;
                  revenue: number | null;
                  revenueCurrency: string | null;
                  revenueUsd: number | null;
                  percentageCleanEnergy: number | null;
                  totalMwh: number | null;
                  units: GQCompanyEmissionsUnits;
                  expenseCategory: string | null;
                  scope1: number | null;
                  scope2Market: number | null;
                  scope2Location: number | null;
                  scope3: number | null;
                  scope301: number | null;
                  scope302: number | null;
                  scope303: number | null;
                  scope304: number | null;
                  scope305: number | null;
                  scope306: number | null;
                  scope307: number | null;
                  scope308: number | null;
                  scope309: number | null;
                  scope310: number | null;
                  scope311: number | null;
                  scope312: number | null;
                  scope313: number | null;
                  scope314: number | null;
                  scope315: number | null;
                  scope316: number | null;
                  scope317: number | null;
                }> | null;
                targets: Array<{
                  __typename?: 'DisclosureTargetDetails';
                  id: string;
                  name: string;
                  description: string;
                  baseYear: YearMonth;
                  emissionsType: GQDisclosureTargetEmissionsType;
                  reductionRate: GQDisclosureTargetReductionRate;
                  intensityType: GQDisclosureTargetIntensityType;
                  unit: string | null;
                  unitDescription: string | null;
                  filters: {
                    __typename?: 'FilterExpressionGroup';
                    conjunction: GQFilterConjunction;
                    expressions: Array<{
                      __typename?: 'FilterExpressionPrimitive';
                      field: GQFilterFieldLegacy;
                      operator: GQFilterOperator;
                      value: Array<string>;
                    }>;
                  };
                  emissionsTarget: {
                    __typename?: 'SimpleTimeseries';
                    id: string | null;
                    base: Date;
                    frequency: GQTimeseriesFrequency;
                    values: Array<number>;
                  };
                }> | null;
                initiatives: Array<{
                  __typename?: 'DisclosureInitiative';
                  id: string;
                  name: string | null;
                  initiativeType: GQDisclosureInitiativeType;
                  description: string | null;
                  startYearMonth: YearMonth | null;
                  endYearMonth: YearMonth | null;
                  intensityType: GQDisclosureTargetIntensityType | null;
                  emissionsTimeseries: {
                    __typename?: 'SimpleTimeseries';
                    id: string | null;
                    base: Date;
                    frequency: GQTimeseriesFrequency;
                    values: Array<number>;
                  } | null;
                  filters: {
                    __typename?: 'FilterExpressionGroup';
                    conjunction: GQFilterConjunction;
                    expressions: Array<{
                      __typename?: 'FilterExpressionPrimitive';
                      field: GQFilterFieldLegacy;
                      operator: GQFilterOperator;
                      value: Array<string>;
                    }>;
                  } | null;
                }> | null;
                climateCommitments: Array<
                  | {
                      __typename?: 'CarbonNeutralCommitment';
                      targetYear: number | null;
                      id: string;
                      kind: GQCompanyClimateCommitmentKind;
                      description: string | null;
                      externalUrl: string | null;
                      commitmentMadeDate: Date | null;
                      commitmentPeriodStart: Date | null;
                      commitmentPeriodEnd: Date | null;
                    }
                  | {
                      __typename?: 'CleanEnergyCommitment';
                      targetYear: number | null;
                      targetPercentageCleanEnergy: number | null;
                      id: string;
                      kind: GQCompanyClimateCommitmentKind;
                      description: string | null;
                      externalUrl: string | null;
                      commitmentMadeDate: Date | null;
                      commitmentPeriodStart: Date | null;
                      commitmentPeriodEnd: Date | null;
                    }
                  | {
                      __typename?: 'NetZeroCommitment';
                      targetYear: number | null;
                      id: string;
                      kind: GQCompanyClimateCommitmentKind;
                      description: string | null;
                      externalUrl: string | null;
                      commitmentMadeDate: Date | null;
                      commitmentPeriodStart: Date | null;
                      commitmentPeriodEnd: Date | null;
                    }
                  | {
                      __typename?: 'ScienceBasedTargetCommitment';
                      submittedToSBTi: boolean;
                      id: string;
                      kind: GQCompanyClimateCommitmentKind;
                      description: string | null;
                      externalUrl: string | null;
                      commitmentMadeDate: Date | null;
                      commitmentPeriodStart: Date | null;
                      commitmentPeriodEnd: Date | null;
                      commitment: {
                        __typename?: 'SBTCommitment';
                        id: string;
                        stage: GQSbtCommitmentStage | null;
                        targetClassification: GQSbtTargetClassification | null;
                        nearTermTargetYear: number | null;
                        longTermTargetYear: number | null;
                        baselineYear: number | null;
                        netZeroCommitted: boolean | null;
                        commitmentType: GQSbtCommitmentType | null;
                        commitmentDeadline: Date | null;
                        netZeroTargetYear: number | null;
                      };
                    }
                > | null;
              }>;
            };
          }>;
          parentRelationshipsForSidebar: Array<{
            __typename?: 'ParentRelationship';
            id: string;
            relationshipStartDate: YearMonth | null;
            relationshipEndDate: YearMonth | null;
            parent: { __typename?: 'Company'; id: string; name: string };
          }>;
          contacts: Array<{
            __typename?: 'SupplierContact';
            id: string;
            name: string;
            email: string;
            role: string | null;
            userId: string | null;
            companyId: string;
          }>;
        } | null;
        engagementTasks: Array<{
          __typename?: 'EngagementTask';
          id: string;
          crossOrgModelId: string;
          reportingYear: number | null;
          facilityName: string | null;
          submittedAt: Date | null;
          createdAt: Date;
          dueDate: Date | null;
          status: GQCompanySurveyStatus;
          surveyKind: GQCompanySurveyKind;
          supplierFacingDescription: string | null;
          watershedGeneric: boolean | null;
          currentAssignee: GQCompanySurveyAssignee;
          rootAssignee: string | null;
          supplierAssignee: string | null;
          initialSupplierAssigneeEmail: string | null;
          approvedAt: Date | null;
          statusChangedAt: Date | null;
          rootOrgId: string;
          supplierOrgId: string;
          latestCeeWorkflowId: string | null;
          latestPleeWorkflowId: string | null;
          latestFootprintDisclosureWorkflowId: string | null;
          engagementTaskBatchId: string | null;
          proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
          engagementTaskConfig: {
            __typename?: 'EngagementTaskConfig';
            id: string;
            name: string;
            sidebarTitle: string;
            defaultSupplierFacingDescription: string | null;
            rootFacingDescription: string | null;
            orgId: string | null;
            type: GQEngagementTaskConfigType | null;
            configHasTaskAssignedToCompany: boolean;
            timeNeeded: number | null;
            category: GQSurveyDefinitionCategory;
            engagementTaskInputs: Array<GQEngagementTaskInput>;
            optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
            containers: Array<{
              __typename?: 'EngagementTaskContainer';
              id: string;
              label: string;
              description: string | null;
              componentId: GQEngagementTaskContainerComponentId | null;
              editable: boolean;
              statusJson: any;
              sidebarStepLabelOverride: string | null;
              eyebrowText: string | null;
              headerImage: GQEngagementTaskImageId | null;
              containers: Array<{
                __typename?: 'EngagementTaskContainer';
                id: string;
                label: string;
                description: string | null;
                componentId: GQEngagementTaskContainerComponentId | null;
                editable: boolean;
                statusJson: any;
                sidebarStepLabelOverride: string | null;
                eyebrowText: string | null;
                headerImage: GQEngagementTaskImageId | null;
                questions: Array<{
                  __typename?: 'EngagementTaskQuestion';
                  id: string;
                  label: string | null;
                  description: string | null;
                  componentId: GQEngagementTaskQuestionComponentId;
                  editable: boolean;
                  deletable: boolean;
                  questionKey: string;
                  statusJson: any;
                  whyCollectThis: string | null;
                  answerIsPrivate: boolean;
                  videoLink: string | null;
                }>;
              }>;
              questions: Array<{
                __typename?: 'EngagementTaskQuestion';
                id: string;
                label: string | null;
                description: string | null;
                componentId: GQEngagementTaskQuestionComponentId;
                editable: boolean;
                deletable: boolean;
                questionKey: string;
                statusJson: any;
                whyCollectThis: string | null;
                answerIsPrivate: boolean;
                videoLink: string | null;
              }>;
            }>;
            baseConfig: {
              __typename?: 'EngagementTaskConfig';
              id: string;
              containers: Array<{
                __typename?: 'EngagementTaskContainer';
                id: string;
                label: string;
                description: string | null;
                componentId: GQEngagementTaskContainerComponentId | null;
                editable: boolean;
                statusJson: any;
                sidebarStepLabelOverride: string | null;
                eyebrowText: string | null;
                headerImage: GQEngagementTaskImageId | null;
                containers: Array<{
                  __typename?: 'EngagementTaskContainer';
                  id: string;
                  label: string;
                  description: string | null;
                  componentId: GQEngagementTaskContainerComponentId | null;
                  editable: boolean;
                  statusJson: any;
                  sidebarStepLabelOverride: string | null;
                  eyebrowText: string | null;
                  headerImage: GQEngagementTaskImageId | null;
                  questions: Array<{
                    __typename?: 'EngagementTaskQuestion';
                    id: string;
                    label: string | null;
                    description: string | null;
                    componentId: GQEngagementTaskQuestionComponentId;
                    editable: boolean;
                    deletable: boolean;
                    questionKey: string;
                    statusJson: any;
                    whyCollectThis: string | null;
                    answerIsPrivate: boolean;
                    videoLink: string | null;
                  }>;
                }>;
                questions: Array<{
                  __typename?: 'EngagementTaskQuestion';
                  id: string;
                  label: string | null;
                  description: string | null;
                  componentId: GQEngagementTaskQuestionComponentId;
                  editable: boolean;
                  deletable: boolean;
                  questionKey: string;
                  statusJson: any;
                  whyCollectThis: string | null;
                  answerIsPrivate: boolean;
                  videoLink: string | null;
                }>;
              }>;
            } | null;
          };
          rootCompany: {
            __typename?: 'Company';
            id: string;
            name: string;
            fullLogoUrl: string | null;
            url: string | null;
          };
          company: {
            __typename?: 'Company';
            id: string;
            name: string;
            naicsCode: string | null;
            sbtiStage: GQCompanySbtCommitmentStage;
            publicDisclosures: Array<{
              __typename?: 'Disclosure';
              id: string;
              publicDisclosure: {
                __typename?: 'PublicDisclosure';
                id: string;
                publishingYear: number;
              } | null;
              historicalEmissionsYears: Array<{
                __typename?: 'EmissionsYear';
                id: string;
                publicDisclosureId: string | null;
                reportingYear: number;
                numEmployees: number | null;
                revenue: number | null;
                revenueCurrency: string | null;
                revenueUsd: number | null;
                percentageCleanEnergy: number | null;
                totalMwh: number | null;
                scope1: number | null;
                scope2Market: number | null;
                scope2Location: number | null;
                scope3: number | null;
                scope301: number | null;
                scope302: number | null;
                scope303: number | null;
                scope304: number | null;
                scope305: number | null;
                scope306: number | null;
                scope307: number | null;
                scope308: number | null;
                scope309: number | null;
                scope310: number | null;
                scope311: number | null;
                scope312: number | null;
                scope313: number | null;
                scope314: number | null;
                scope315: number | null;
                scope316: number | null;
                scope317: number | null;
              }> | null;
            }>;
          };
          expenseCategories: Array<{
            __typename?: 'DiscloseEmissionsExpenseCategories';
            beaCode: string;
            inputUnit: string;
            inputQuantity: number;
            kgco2e: number;
          }> | null;
          proposeTargetFilters: {
            __typename?: 'FilterExpressionGroup';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitive';
              field: GQFilterFieldLegacy;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          } | null;
          proposeTargetEmissionsTarget: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          } | null;
        }>;
        assetGroupAssets: Array<
          | {
              __typename: 'AssetCorporate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              naicsCode: string | null;
              companyIsin: string | null;
              companyLei: string | null;
              companyUrl: string | null;
              companyTicker: string | null;
              currencyCode: GQCurrencyCode;
              countryAlpha2: string | null;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              companyId: string | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              anonymousCompanyUuid: string | null;
            }
          | {
              __typename: 'AssetGroup';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              companyId: string | null;
              assetGroupId: string | null;
            }
          | {
              __typename: 'AssetRealEstate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              buildingKind: string | null;
              name: string;
              streetAddress: string | null;
              streetAddress2: string | null;
              city: string | null;
              state: string | null;
              countryAlpha2: string | null;
              postalCode: string | null;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              sizeUnit: GQBuildingSizeUnit | null;
              country: string | null;
              buildingName: string;
            }
        > | null;
      }
    | {
        __typename: 'AssetGroup';
        id: string;
        orgId: string;
        createdAt: Date;
        updatedAt: Date;
        name: string;
        currencyCode: GQCurrencyCode;
        buildingSizeUnit: GQBuildingSizeUnit | null;
        otherData: any | null;
        tagData: any | null;
        externalId: string | null;
        companyId: string | null;
        assetGroupId: string | null;
        assetGroupAssets: Array<
          | {
              __typename: 'AssetCorporate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              naicsCode: string | null;
              companyIsin: string | null;
              companyLei: string | null;
              companyUrl: string | null;
              companyTicker: string | null;
              currencyCode: GQCurrencyCode;
              countryAlpha2: string | null;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              companyId: string | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              anonymousCompanyUuid: string | null;
            }
          | {
              __typename: 'AssetGroup';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              companyId: string | null;
              assetGroupId: string | null;
            }
          | {
              __typename: 'AssetRealEstate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              buildingKind: string | null;
              name: string;
              streetAddress: string | null;
              streetAddress2: string | null;
              city: string | null;
              state: string | null;
              countryAlpha2: string | null;
              postalCode: string | null;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              sizeUnit: GQBuildingSizeUnit | null;
              country: string | null;
              buildingName: string;
            }
        >;
      }
    | {
        __typename: 'AssetPersonalMotorVehicleInsurance';
        id: string;
        orgId: string;
        createdAt: Date;
        updatedAt: Date;
        name: string;
        currencyCode: GQCurrencyCode;
        tagData: any | null;
        assetGroupAssets: Array<
          | {
              __typename: 'AssetCorporate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              naicsCode: string | null;
              companyIsin: string | null;
              companyLei: string | null;
              companyUrl: string | null;
              companyTicker: string | null;
              currencyCode: GQCurrencyCode;
              countryAlpha2: string | null;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              companyId: string | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              anonymousCompanyUuid: string | null;
            }
          | {
              __typename: 'AssetGroup';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              companyId: string | null;
              assetGroupId: string | null;
            }
          | {
              __typename: 'AssetRealEstate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              buildingKind: string | null;
              name: string;
              streetAddress: string | null;
              streetAddress2: string | null;
              city: string | null;
              state: string | null;
              countryAlpha2: string | null;
              postalCode: string | null;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              sizeUnit: GQBuildingSizeUnit | null;
              country: string | null;
              buildingName: string;
            }
        > | null;
      }
    | {
        __typename: 'AssetRealEstate';
        id: string;
        orgId: string;
        createdAt: Date;
        updatedAt: Date;
        buildingKind: string | null;
        name: string;
        streetAddress: string | null;
        streetAddress2: string | null;
        city: string | null;
        state: string | null;
        countryAlpha2: string | null;
        postalCode: string | null;
        currencyCode: GQCurrencyCode;
        buildingSizeUnit: GQBuildingSizeUnit | null;
        otherData: any | null;
        tagData: any | null;
        externalId: string | null;
        assetGroupId: string | null;
        sizeUnit: GQBuildingSizeUnit | null;
        country: string | null;
        buildingName: string;
        assetGroupAssets: Array<
          | {
              __typename: 'AssetCorporate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              naicsCode: string | null;
              companyIsin: string | null;
              companyLei: string | null;
              companyUrl: string | null;
              companyTicker: string | null;
              currencyCode: GQCurrencyCode;
              countryAlpha2: string | null;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              companyId: string | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              anonymousCompanyUuid: string | null;
            }
          | {
              __typename: 'AssetGroup';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              companyId: string | null;
              assetGroupId: string | null;
            }
          | {
              __typename: 'AssetRealEstate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              buildingKind: string | null;
              name: string;
              streetAddress: string | null;
              streetAddress2: string | null;
              city: string | null;
              state: string | null;
              countryAlpha2: string | null;
              postalCode: string | null;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              sizeUnit: GQBuildingSizeUnit | null;
              country: string | null;
              buildingName: string;
            }
        > | null;
      }
    | {
        __typename: 'AssetSovereignBond';
        id: string;
        orgId: string;
        createdAt: Date;
        updatedAt: Date;
        name: string;
        sovereignEntity: string;
        currencyCode: GQCurrencyCode;
        tagData: any | null;
        assetGroupAssets: Array<
          | {
              __typename: 'AssetCorporate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              naicsCode: string | null;
              companyIsin: string | null;
              companyLei: string | null;
              companyUrl: string | null;
              companyTicker: string | null;
              currencyCode: GQCurrencyCode;
              countryAlpha2: string | null;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              companyId: string | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              anonymousCompanyUuid: string | null;
            }
          | {
              __typename: 'AssetGroup';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              companyId: string | null;
              assetGroupId: string | null;
            }
          | {
              __typename: 'AssetRealEstate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              buildingKind: string | null;
              name: string;
              streetAddress: string | null;
              streetAddress2: string | null;
              city: string | null;
              state: string | null;
              countryAlpha2: string | null;
              postalCode: string | null;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              sizeUnit: GQBuildingSizeUnit | null;
              country: string | null;
              buildingName: string;
            }
        > | null;
      };
  funds: Array<{
    __typename: 'Fund';
    id: string;
    name: string;
    nameSortable: string | null;
    fundGroup: string | null;
    fundCategory: string | null;
    excludeAsSandbox: boolean | null;
    anonymousFundUuid: string | null;
    createdAt: Date;
    orgId: string;
    updatedAt: Date;
    tagData: any | null;
    externalId: string | null;
  }>;
};

export type GQAssetPersonalMotorVehicleInsuranceFieldsFragment = {
  __typename: 'AssetPersonalMotorVehicleInsurance';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  currencyCode: GQCurrencyCode;
  tagData: any | null;
};

type GQAssetGroupAssetAllFields_AssetCorporate_Fragment = {
  __typename: 'AssetCorporate';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  naicsCode: string | null;
  companyIsin: string | null;
  companyLei: string | null;
  companyUrl: string | null;
  companyTicker: string | null;
  currencyCode: GQCurrencyCode;
  countryAlpha2: string | null;
  buildingSizeUnit: GQBuildingSizeUnit | null;
  companyId: string | null;
  otherData: any | null;
  tagData: any | null;
  externalId: string | null;
  assetGroupId: string | null;
  anonymousCompanyUuid: string | null;
};

type GQAssetGroupAssetAllFields_AssetGroup_Fragment = {
  __typename: 'AssetGroup';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  currencyCode: GQCurrencyCode;
  buildingSizeUnit: GQBuildingSizeUnit | null;
  otherData: any | null;
  tagData: any | null;
  externalId: string | null;
  companyId: string | null;
  assetGroupId: string | null;
};

type GQAssetGroupAssetAllFields_AssetRealEstate_Fragment = {
  __typename: 'AssetRealEstate';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  buildingKind: string | null;
  name: string;
  streetAddress: string | null;
  streetAddress2: string | null;
  city: string | null;
  state: string | null;
  countryAlpha2: string | null;
  postalCode: string | null;
  currencyCode: GQCurrencyCode;
  buildingSizeUnit: GQBuildingSizeUnit | null;
  otherData: any | null;
  tagData: any | null;
  externalId: string | null;
  assetGroupId: string | null;
  sizeUnit: GQBuildingSizeUnit | null;
  country: string | null;
  buildingName: string;
};

export type GQAssetGroupAssetAllFieldsFragment =
  | GQAssetGroupAssetAllFields_AssetCorporate_Fragment
  | GQAssetGroupAssetAllFields_AssetGroup_Fragment
  | GQAssetGroupAssetAllFields_AssetRealEstate_Fragment;

type GQAssetAllFields_AssetCorporate_Fragment = {
  __typename: 'AssetCorporate';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  naicsCode: string | null;
  companyIsin: string | null;
  companyLei: string | null;
  companyUrl: string | null;
  companyTicker: string | null;
  currencyCode: GQCurrencyCode;
  countryAlpha2: string | null;
  buildingSizeUnit: GQBuildingSizeUnit | null;
  companyId: string | null;
  otherData: any | null;
  tagData: any | null;
  externalId: string | null;
  assetGroupId: string | null;
  anonymousCompanyUuid: string | null;
};

type GQAssetAllFields_AssetGroup_Fragment = {
  __typename: 'AssetGroup';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  currencyCode: GQCurrencyCode;
  buildingSizeUnit: GQBuildingSizeUnit | null;
  otherData: any | null;
  tagData: any | null;
  externalId: string | null;
  companyId: string | null;
  assetGroupId: string | null;
};

type GQAssetAllFields_AssetPersonalMotorVehicleInsurance_Fragment = {
  __typename: 'AssetPersonalMotorVehicleInsurance';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  currencyCode: GQCurrencyCode;
  tagData: any | null;
};

type GQAssetAllFields_AssetRealEstate_Fragment = {
  __typename: 'AssetRealEstate';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  buildingKind: string | null;
  name: string;
  streetAddress: string | null;
  streetAddress2: string | null;
  city: string | null;
  state: string | null;
  countryAlpha2: string | null;
  postalCode: string | null;
  currencyCode: GQCurrencyCode;
  buildingSizeUnit: GQBuildingSizeUnit | null;
  otherData: any | null;
  tagData: any | null;
  externalId: string | null;
  assetGroupId: string | null;
  sizeUnit: GQBuildingSizeUnit | null;
  country: string | null;
  buildingName: string;
};

type GQAssetAllFields_AssetSovereignBond_Fragment = {
  __typename: 'AssetSovereignBond';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  sovereignEntity: string;
  currencyCode: GQCurrencyCode;
  tagData: any | null;
};

export type GQAssetAllFieldsFragment =
  | GQAssetAllFields_AssetCorporate_Fragment
  | GQAssetAllFields_AssetGroup_Fragment
  | GQAssetAllFields_AssetPersonalMotorVehicleInsurance_Fragment
  | GQAssetAllFields_AssetRealEstate_Fragment
  | GQAssetAllFields_AssetSovereignBond_Fragment;

export type GQAssetInfoPageEditableQueryVariables = Exact<{
  assetId: Scalars['ID']['input'];
  year: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQAssetInfoPageEditableQuery = {
  __typename?: 'Query';
  asset:
    | {
        __typename: 'AssetCorporate';
        id: string;
        orgId: string;
        createdAt: Date;
        updatedAt: Date;
        name: string;
        naicsCode: string | null;
        companyIsin: string | null;
        companyLei: string | null;
        companyUrl: string | null;
        companyTicker: string | null;
        currencyCode: GQCurrencyCode;
        countryAlpha2: string | null;
        buildingSizeUnit: GQBuildingSizeUnit | null;
        companyId: string | null;
        otherData: any | null;
        tagData: any | null;
        externalId: string | null;
        assetGroupId: string | null;
        anonymousCompanyUuid: string | null;
        company: {
          __typename?: 'Company';
          id: string;
          naicsCode: string | null;
          sbtiStage: GQCompanySbtCommitmentStage;
          climateProgress: GQCompanyClimateProgress;
          sustainabilityWebsiteUrl: string | null;
          isWatershedCorporateCustomer: boolean;
          parentRelationships: Array<{
            __typename?: 'ParentRelationship';
            id: string;
            relationshipStartDate: YearMonth | null;
            relationshipEndDate: YearMonth | null;
            parent: {
              __typename?: 'Company';
              id: string;
              name: string;
              disclosuresV2: Array<{
                __typename?: 'Disclosure';
                id: string;
                company: { __typename?: 'Company'; id: string } | null;
                publicDisclosure: {
                  __typename?: 'PublicDisclosure';
                  id: string;
                  publishingYear: number;
                  reportType: GQExternalReportType;
                  publicUrl: string;
                  qualityScore: GQDisclosureQualityScore | null;
                  cdpVendorData: {
                    __typename?: 'CdpVendorData';
                    id: string;
                    publishingYear: number;
                    reportingYear: number;
                    scope1Nonzero: boolean;
                    scope2Nonzero: boolean;
                    scope3Nonzero: boolean;
                    totalEmissionsNonzero: boolean;
                    scope301Or302Nonzero: boolean;
                    scope1Verified: boolean;
                    scope2Verified: boolean;
                    scope3Verified: boolean;
                    pctEvaluationStatusesMatchResponse: number;
                    disclosureQualityScore: GQDisclosureQualityScore;
                    scope301EvaluationStatus: GQScope3EvaluationStatus;
                    scope302EvaluationStatus: GQScope3EvaluationStatus;
                    scope303EvaluationStatus: GQScope3EvaluationStatus;
                    scope304EvaluationStatus: GQScope3EvaluationStatus;
                    scope305EvaluationStatus: GQScope3EvaluationStatus;
                    scope306EvaluationStatus: GQScope3EvaluationStatus;
                    scope307EvaluationStatus: GQScope3EvaluationStatus;
                    scope308EvaluationStatus: GQScope3EvaluationStatus;
                    scope309EvaluationStatus: GQScope3EvaluationStatus;
                    scope310EvaluationStatus: GQScope3EvaluationStatus;
                    scope311EvaluationStatus: GQScope3EvaluationStatus;
                    scope312EvaluationStatus: GQScope3EvaluationStatus;
                    scope313EvaluationStatus: GQScope3EvaluationStatus;
                    scope314EvaluationStatus: GQScope3EvaluationStatus;
                    scope315EvaluationStatus: GQScope3EvaluationStatus;
                    scope316EvaluationStatus: GQScope3EvaluationStatus;
                    scope317EvaluationStatus: GQScope3EvaluationStatus;
                  } | null;
                } | null;
                privateDisclosure: {
                  __typename?: 'PrivateDisclosure';
                  id: string;
                  companyId: string | null;
                  orgId: string;
                  orgName: string;
                  surveyId: string | null;
                  privateDisclosureType: GQPrivateDisclosureType;
                  createdAt: Date;
                  disclosureQualityScore: GQDisclosureQualityScore | null;
                  disclosureQualityExplanation: string | null;
                  thirdPartyVerified: boolean;
                  cdpVendorData: {
                    __typename?: 'CdpVendorData';
                    id: string;
                    publishingYear: number;
                    reportingYear: number;
                    scope1Nonzero: boolean;
                    scope2Nonzero: boolean;
                    scope3Nonzero: boolean;
                    totalEmissionsNonzero: boolean;
                    scope301Or302Nonzero: boolean;
                    scope1Verified: boolean;
                    scope2Verified: boolean;
                    scope3Verified: boolean;
                    pctEvaluationStatusesMatchResponse: number;
                    disclosureQualityScore: GQDisclosureQualityScore;
                    scope301EvaluationStatus: GQScope3EvaluationStatus;
                    scope302EvaluationStatus: GQScope3EvaluationStatus;
                    scope303EvaluationStatus: GQScope3EvaluationStatus;
                    scope304EvaluationStatus: GQScope3EvaluationStatus;
                    scope305EvaluationStatus: GQScope3EvaluationStatus;
                    scope306EvaluationStatus: GQScope3EvaluationStatus;
                    scope307EvaluationStatus: GQScope3EvaluationStatus;
                    scope308EvaluationStatus: GQScope3EvaluationStatus;
                    scope309EvaluationStatus: GQScope3EvaluationStatus;
                    scope310EvaluationStatus: GQScope3EvaluationStatus;
                    scope311EvaluationStatus: GQScope3EvaluationStatus;
                    scope312EvaluationStatus: GQScope3EvaluationStatus;
                    scope313EvaluationStatus: GQScope3EvaluationStatus;
                    scope314EvaluationStatus: GQScope3EvaluationStatus;
                    scope315EvaluationStatus: GQScope3EvaluationStatus;
                    scope316EvaluationStatus: GQScope3EvaluationStatus;
                    scope317EvaluationStatus: GQScope3EvaluationStatus;
                  } | null;
                } | null;
                historicalEmissionsYears: Array<{
                  __typename?: 'EmissionsYear';
                  id: string;
                  publicDisclosureId: string | null;
                  reportingYear: number;
                  numEmployees: number | null;
                  revenue: number | null;
                  revenueCurrency: string | null;
                  revenueUsd: number | null;
                  percentageCleanEnergy: number | null;
                  totalMwh: number | null;
                  units: GQCompanyEmissionsUnits;
                  expenseCategory: string | null;
                  scope1: number | null;
                  scope2Market: number | null;
                  scope2Location: number | null;
                  scope3: number | null;
                  scope301: number | null;
                  scope302: number | null;
                  scope303: number | null;
                  scope304: number | null;
                  scope305: number | null;
                  scope306: number | null;
                  scope307: number | null;
                  scope308: number | null;
                  scope309: number | null;
                  scope310: number | null;
                  scope311: number | null;
                  scope312: number | null;
                  scope313: number | null;
                  scope314: number | null;
                  scope315: number | null;
                  scope316: number | null;
                  scope317: number | null;
                }> | null;
                targets: Array<{
                  __typename?: 'DisclosureTargetDetails';
                  id: string;
                  name: string;
                  description: string;
                  baseYear: YearMonth;
                  emissionsType: GQDisclosureTargetEmissionsType;
                  reductionRate: GQDisclosureTargetReductionRate;
                  intensityType: GQDisclosureTargetIntensityType;
                  unit: string | null;
                  unitDescription: string | null;
                  filters: {
                    __typename?: 'FilterExpressionGroup';
                    conjunction: GQFilterConjunction;
                    expressions: Array<{
                      __typename?: 'FilterExpressionPrimitive';
                      field: GQFilterFieldLegacy;
                      operator: GQFilterOperator;
                      value: Array<string>;
                    }>;
                  };
                  emissionsTarget: {
                    __typename?: 'SimpleTimeseries';
                    id: string | null;
                    base: Date;
                    frequency: GQTimeseriesFrequency;
                    values: Array<number>;
                  };
                }> | null;
                initiatives: Array<{
                  __typename?: 'DisclosureInitiative';
                  id: string;
                  name: string | null;
                  initiativeType: GQDisclosureInitiativeType;
                  description: string | null;
                  startYearMonth: YearMonth | null;
                  endYearMonth: YearMonth | null;
                  intensityType: GQDisclosureTargetIntensityType | null;
                  emissionsTimeseries: {
                    __typename?: 'SimpleTimeseries';
                    id: string | null;
                    base: Date;
                    frequency: GQTimeseriesFrequency;
                    values: Array<number>;
                  } | null;
                  filters: {
                    __typename?: 'FilterExpressionGroup';
                    conjunction: GQFilterConjunction;
                    expressions: Array<{
                      __typename?: 'FilterExpressionPrimitive';
                      field: GQFilterFieldLegacy;
                      operator: GQFilterOperator;
                      value: Array<string>;
                    }>;
                  } | null;
                }> | null;
                climateCommitments: Array<
                  | {
                      __typename?: 'CarbonNeutralCommitment';
                      targetYear: number | null;
                      id: string;
                      kind: GQCompanyClimateCommitmentKind;
                      description: string | null;
                      externalUrl: string | null;
                      commitmentMadeDate: Date | null;
                      commitmentPeriodStart: Date | null;
                      commitmentPeriodEnd: Date | null;
                    }
                  | {
                      __typename?: 'CleanEnergyCommitment';
                      targetYear: number | null;
                      targetPercentageCleanEnergy: number | null;
                      id: string;
                      kind: GQCompanyClimateCommitmentKind;
                      description: string | null;
                      externalUrl: string | null;
                      commitmentMadeDate: Date | null;
                      commitmentPeriodStart: Date | null;
                      commitmentPeriodEnd: Date | null;
                    }
                  | {
                      __typename?: 'NetZeroCommitment';
                      targetYear: number | null;
                      id: string;
                      kind: GQCompanyClimateCommitmentKind;
                      description: string | null;
                      externalUrl: string | null;
                      commitmentMadeDate: Date | null;
                      commitmentPeriodStart: Date | null;
                      commitmentPeriodEnd: Date | null;
                    }
                  | {
                      __typename?: 'ScienceBasedTargetCommitment';
                      submittedToSBTi: boolean;
                      id: string;
                      kind: GQCompanyClimateCommitmentKind;
                      description: string | null;
                      externalUrl: string | null;
                      commitmentMadeDate: Date | null;
                      commitmentPeriodStart: Date | null;
                      commitmentPeriodEnd: Date | null;
                      commitment: {
                        __typename?: 'SBTCommitment';
                        id: string;
                        stage: GQSbtCommitmentStage | null;
                        targetClassification: GQSbtTargetClassification | null;
                        nearTermTargetYear: number | null;
                        longTermTargetYear: number | null;
                        baselineYear: number | null;
                        netZeroCommitted: boolean | null;
                        commitmentType: GQSbtCommitmentType | null;
                        commitmentDeadline: Date | null;
                        netZeroTargetYear: number | null;
                      };
                    }
                > | null;
              }>;
            };
          }>;
          parentRelationshipsForSidebar: Array<{
            __typename?: 'ParentRelationship';
            id: string;
            relationshipStartDate: YearMonth | null;
            relationshipEndDate: YearMonth | null;
            parent: { __typename?: 'Company'; id: string; name: string };
          }>;
          contacts: Array<{
            __typename?: 'SupplierContact';
            id: string;
            name: string;
            email: string;
            role: string | null;
            userId: string | null;
            companyId: string;
          }>;
        } | null;
        engagementTasks: Array<{
          __typename?: 'EngagementTask';
          id: string;
          crossOrgModelId: string;
          reportingYear: number | null;
          facilityName: string | null;
          submittedAt: Date | null;
          createdAt: Date;
          dueDate: Date | null;
          status: GQCompanySurveyStatus;
          surveyKind: GQCompanySurveyKind;
          supplierFacingDescription: string | null;
          watershedGeneric: boolean | null;
          currentAssignee: GQCompanySurveyAssignee;
          rootAssignee: string | null;
          supplierAssignee: string | null;
          initialSupplierAssigneeEmail: string | null;
          approvedAt: Date | null;
          statusChangedAt: Date | null;
          rootOrgId: string;
          supplierOrgId: string;
          latestCeeWorkflowId: string | null;
          latestPleeWorkflowId: string | null;
          latestFootprintDisclosureWorkflowId: string | null;
          engagementTaskBatchId: string | null;
          proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
          engagementTaskConfig: {
            __typename?: 'EngagementTaskConfig';
            id: string;
            name: string;
            sidebarTitle: string;
            defaultSupplierFacingDescription: string | null;
            rootFacingDescription: string | null;
            orgId: string | null;
            type: GQEngagementTaskConfigType | null;
            configHasTaskAssignedToCompany: boolean;
            timeNeeded: number | null;
            category: GQSurveyDefinitionCategory;
            engagementTaskInputs: Array<GQEngagementTaskInput>;
            optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
            containers: Array<{
              __typename?: 'EngagementTaskContainer';
              id: string;
              label: string;
              description: string | null;
              componentId: GQEngagementTaskContainerComponentId | null;
              editable: boolean;
              statusJson: any;
              sidebarStepLabelOverride: string | null;
              eyebrowText: string | null;
              headerImage: GQEngagementTaskImageId | null;
              containers: Array<{
                __typename?: 'EngagementTaskContainer';
                id: string;
                label: string;
                description: string | null;
                componentId: GQEngagementTaskContainerComponentId | null;
                editable: boolean;
                statusJson: any;
                sidebarStepLabelOverride: string | null;
                eyebrowText: string | null;
                headerImage: GQEngagementTaskImageId | null;
                questions: Array<{
                  __typename?: 'EngagementTaskQuestion';
                  id: string;
                  label: string | null;
                  description: string | null;
                  componentId: GQEngagementTaskQuestionComponentId;
                  editable: boolean;
                  deletable: boolean;
                  questionKey: string;
                  statusJson: any;
                  whyCollectThis: string | null;
                  answerIsPrivate: boolean;
                  videoLink: string | null;
                }>;
              }>;
              questions: Array<{
                __typename?: 'EngagementTaskQuestion';
                id: string;
                label: string | null;
                description: string | null;
                componentId: GQEngagementTaskQuestionComponentId;
                editable: boolean;
                deletable: boolean;
                questionKey: string;
                statusJson: any;
                whyCollectThis: string | null;
                answerIsPrivate: boolean;
                videoLink: string | null;
              }>;
            }>;
            baseConfig: {
              __typename?: 'EngagementTaskConfig';
              id: string;
              containers: Array<{
                __typename?: 'EngagementTaskContainer';
                id: string;
                label: string;
                description: string | null;
                componentId: GQEngagementTaskContainerComponentId | null;
                editable: boolean;
                statusJson: any;
                sidebarStepLabelOverride: string | null;
                eyebrowText: string | null;
                headerImage: GQEngagementTaskImageId | null;
                containers: Array<{
                  __typename?: 'EngagementTaskContainer';
                  id: string;
                  label: string;
                  description: string | null;
                  componentId: GQEngagementTaskContainerComponentId | null;
                  editable: boolean;
                  statusJson: any;
                  sidebarStepLabelOverride: string | null;
                  eyebrowText: string | null;
                  headerImage: GQEngagementTaskImageId | null;
                  questions: Array<{
                    __typename?: 'EngagementTaskQuestion';
                    id: string;
                    label: string | null;
                    description: string | null;
                    componentId: GQEngagementTaskQuestionComponentId;
                    editable: boolean;
                    deletable: boolean;
                    questionKey: string;
                    statusJson: any;
                    whyCollectThis: string | null;
                    answerIsPrivate: boolean;
                    videoLink: string | null;
                  }>;
                }>;
                questions: Array<{
                  __typename?: 'EngagementTaskQuestion';
                  id: string;
                  label: string | null;
                  description: string | null;
                  componentId: GQEngagementTaskQuestionComponentId;
                  editable: boolean;
                  deletable: boolean;
                  questionKey: string;
                  statusJson: any;
                  whyCollectThis: string | null;
                  answerIsPrivate: boolean;
                  videoLink: string | null;
                }>;
              }>;
            } | null;
          };
          rootCompany: {
            __typename?: 'Company';
            id: string;
            name: string;
            fullLogoUrl: string | null;
            url: string | null;
          };
          company: {
            __typename?: 'Company';
            id: string;
            name: string;
            naicsCode: string | null;
            sbtiStage: GQCompanySbtCommitmentStage;
            publicDisclosures: Array<{
              __typename?: 'Disclosure';
              id: string;
              publicDisclosure: {
                __typename?: 'PublicDisclosure';
                id: string;
                publishingYear: number;
              } | null;
              historicalEmissionsYears: Array<{
                __typename?: 'EmissionsYear';
                id: string;
                publicDisclosureId: string | null;
                reportingYear: number;
                numEmployees: number | null;
                revenue: number | null;
                revenueCurrency: string | null;
                revenueUsd: number | null;
                percentageCleanEnergy: number | null;
                totalMwh: number | null;
                scope1: number | null;
                scope2Market: number | null;
                scope2Location: number | null;
                scope3: number | null;
                scope301: number | null;
                scope302: number | null;
                scope303: number | null;
                scope304: number | null;
                scope305: number | null;
                scope306: number | null;
                scope307: number | null;
                scope308: number | null;
                scope309: number | null;
                scope310: number | null;
                scope311: number | null;
                scope312: number | null;
                scope313: number | null;
                scope314: number | null;
                scope315: number | null;
                scope316: number | null;
                scope317: number | null;
              }> | null;
            }>;
          };
          expenseCategories: Array<{
            __typename?: 'DiscloseEmissionsExpenseCategories';
            beaCode: string;
            inputUnit: string;
            inputQuantity: number;
            kgco2e: number;
          }> | null;
          proposeTargetFilters: {
            __typename?: 'FilterExpressionGroup';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitive';
              field: GQFilterFieldLegacy;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          } | null;
          proposeTargetEmissionsTarget: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          } | null;
        }>;
        footprintSourceOptions: Array<{
          __typename?: 'FootprintSourceOption';
          id: string;
          label: string;
          year: number;
          source: GQEmissionsYearSource;
          externalScore: string | null;
        }>;
        revenueSourceOptions: Array<{
          __typename?: 'RevenueSourceOption';
          id: string;
          label: string;
          year: number;
          revenue: number | null;
          currencyCode: string | null;
        }>;
        assetYears: Array<{
          __typename: 'FinanceAssetYearNew';
          id: string;
          orgId: string;
          createdAt: Date;
          updatedAt: Date;
          year: number;
          assetCommercialRealEstateId: string | null;
          assetCorporateId: string | null;
          assetGroupId: string | null;
          assetSovereignBondId: string | null;
          assetPersonalMotorVehicleInsuranceId: string | null;
          buildingSizeNative: number | null;
          estimationMethodology: GQEstimationMethodology | null;
          headcount: number | null;
          percentRenewable: number | null;
          cloudSpendNative: number | null;
          valueNative: number | null;
          revenueNative: number | null;
          flightMiles: number | null;
          percentEmployeesWfh: number | null;
          currencyCode: GQCurrencyCode;
          currencyConversionRate: number;
          totalMwh: number | null;
          revenueSourceEmissionsYearId: string | null;
          emissionsYearId: string | null;
          scope1OverrideTco2e: number | null;
          scope1OverridePcaf: number | null;
          scope2LocationOverrideTco2e: number | null;
          scope2LocationOverridePcaf: number | null;
          scope2MarketOverrideTco2e: number | null;
          scope2MarketOverridePcaf: number | null;
          scope3OverrideKind: GQScope3OverrideKind | null;
          scope3OverrideTco2e: number | null;
          scope3OverridePcaf: number | null;
          scope301OverrideTco2e: number | null;
          scope302OverrideTco2e: number | null;
          scope303OverrideTco2e: number | null;
          scope304OverrideTco2e: number | null;
          scope305OverrideTco2e: number | null;
          scope306OverrideTco2e: number | null;
          scope307OverrideTco2e: number | null;
          scope308OverrideTco2e: number | null;
          scope309OverrideTco2e: number | null;
          scope310OverrideTco2e: number | null;
          scope311OverrideTco2e: number | null;
          scope312OverrideTco2e: number | null;
          scope313OverrideTco2e: number | null;
          scope314OverrideTco2e: number | null;
          scope315OverrideTco2e: number | null;
          buildingEstimationErr: string | null;
          companyEstimationErr: string | null;
          measuringScope1And2Emissions: boolean | null;
          measuringMaterialScope3Emissions: boolean | null;
          initiatedDecarbonizationPlan: boolean | null;
          committedToNearTermSbtAlignedNetZero: boolean | null;
          demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
          iciAlignmentOverride: GQIciAlignmentStatus | null;
          scenario: string | null;
          tagData: any | null;
        }>;
        assetHoldings: Array<{
          __typename: 'FinanceAssetHolding';
          id: string;
          orgId: string;
          createdAt: Date;
          updatedAt: Date;
          assetYearId: string;
          fundId: string;
          yearOfInitialInvestment: number | null;
          outstandingAmountNative: number | null;
          outstandingAmountNativeQ1: number | null;
          outstandingAmountNativeQ2: number | null;
          outstandingAmountNativeQ3: number | null;
          outstandingAmountNativeQ4: number | null;
          currencyCode: GQCurrencyCode;
          currencyConversionRate: number;
          assetClass: GQFundAssetClass;
          tagData: any | null;
          premiumNative: number | null;
          emissionsYearId: string | null;
        }>;
        assetGroupAssets: Array<
          | {
              __typename: 'AssetCorporate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              naicsCode: string | null;
              companyIsin: string | null;
              companyLei: string | null;
              companyUrl: string | null;
              companyTicker: string | null;
              currencyCode: GQCurrencyCode;
              countryAlpha2: string | null;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              companyId: string | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              anonymousCompanyUuid: string | null;
            }
          | {
              __typename: 'AssetGroup';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              companyId: string | null;
              assetGroupId: string | null;
            }
          | {
              __typename: 'AssetRealEstate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              buildingKind: string | null;
              name: string;
              streetAddress: string | null;
              streetAddress2: string | null;
              city: string | null;
              state: string | null;
              countryAlpha2: string | null;
              postalCode: string | null;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              sizeUnit: GQBuildingSizeUnit | null;
              country: string | null;
              buildingName: string;
            }
        > | null;
      }
    | {
        __typename: 'AssetGroup';
        id: string;
        orgId: string;
        createdAt: Date;
        updatedAt: Date;
        name: string;
        currencyCode: GQCurrencyCode;
        buildingSizeUnit: GQBuildingSizeUnit | null;
        otherData: any | null;
        tagData: any | null;
        externalId: string | null;
        companyId: string | null;
        assetGroupId: string | null;
        footprintSourceOptions: Array<{
          __typename?: 'FootprintSourceOption';
          id: string;
          label: string;
          year: number;
          source: GQEmissionsYearSource;
          externalScore: string | null;
        }>;
        revenueSourceOptions: Array<{
          __typename?: 'RevenueSourceOption';
          id: string;
          label: string;
          year: number;
          revenue: number | null;
          currencyCode: string | null;
        }>;
        assetYears: Array<{
          __typename: 'FinanceAssetYearNew';
          id: string;
          orgId: string;
          createdAt: Date;
          updatedAt: Date;
          year: number;
          assetCommercialRealEstateId: string | null;
          assetCorporateId: string | null;
          assetGroupId: string | null;
          assetSovereignBondId: string | null;
          assetPersonalMotorVehicleInsuranceId: string | null;
          buildingSizeNative: number | null;
          estimationMethodology: GQEstimationMethodology | null;
          headcount: number | null;
          percentRenewable: number | null;
          cloudSpendNative: number | null;
          valueNative: number | null;
          revenueNative: number | null;
          flightMiles: number | null;
          percentEmployeesWfh: number | null;
          currencyCode: GQCurrencyCode;
          currencyConversionRate: number;
          totalMwh: number | null;
          revenueSourceEmissionsYearId: string | null;
          emissionsYearId: string | null;
          scope1OverrideTco2e: number | null;
          scope1OverridePcaf: number | null;
          scope2LocationOverrideTco2e: number | null;
          scope2LocationOverridePcaf: number | null;
          scope2MarketOverrideTco2e: number | null;
          scope2MarketOverridePcaf: number | null;
          scope3OverrideKind: GQScope3OverrideKind | null;
          scope3OverrideTco2e: number | null;
          scope3OverridePcaf: number | null;
          scope301OverrideTco2e: number | null;
          scope302OverrideTco2e: number | null;
          scope303OverrideTco2e: number | null;
          scope304OverrideTco2e: number | null;
          scope305OverrideTco2e: number | null;
          scope306OverrideTco2e: number | null;
          scope307OverrideTco2e: number | null;
          scope308OverrideTco2e: number | null;
          scope309OverrideTco2e: number | null;
          scope310OverrideTco2e: number | null;
          scope311OverrideTco2e: number | null;
          scope312OverrideTco2e: number | null;
          scope313OverrideTco2e: number | null;
          scope314OverrideTco2e: number | null;
          scope315OverrideTco2e: number | null;
          buildingEstimationErr: string | null;
          companyEstimationErr: string | null;
          measuringScope1And2Emissions: boolean | null;
          measuringMaterialScope3Emissions: boolean | null;
          initiatedDecarbonizationPlan: boolean | null;
          committedToNearTermSbtAlignedNetZero: boolean | null;
          demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
          iciAlignmentOverride: GQIciAlignmentStatus | null;
          scenario: string | null;
          tagData: any | null;
        }>;
        assetHoldings: Array<{
          __typename: 'FinanceAssetHolding';
          id: string;
          orgId: string;
          createdAt: Date;
          updatedAt: Date;
          assetYearId: string;
          fundId: string;
          yearOfInitialInvestment: number | null;
          outstandingAmountNative: number | null;
          outstandingAmountNativeQ1: number | null;
          outstandingAmountNativeQ2: number | null;
          outstandingAmountNativeQ3: number | null;
          outstandingAmountNativeQ4: number | null;
          currencyCode: GQCurrencyCode;
          currencyConversionRate: number;
          assetClass: GQFundAssetClass;
          tagData: any | null;
          premiumNative: number | null;
          emissionsYearId: string | null;
        }>;
        assetGroupAssets: Array<
          | {
              __typename: 'AssetCorporate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              naicsCode: string | null;
              companyIsin: string | null;
              companyLei: string | null;
              companyUrl: string | null;
              companyTicker: string | null;
              currencyCode: GQCurrencyCode;
              countryAlpha2: string | null;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              companyId: string | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              anonymousCompanyUuid: string | null;
            }
          | {
              __typename: 'AssetGroup';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              companyId: string | null;
              assetGroupId: string | null;
            }
          | {
              __typename: 'AssetRealEstate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              buildingKind: string | null;
              name: string;
              streetAddress: string | null;
              streetAddress2: string | null;
              city: string | null;
              state: string | null;
              countryAlpha2: string | null;
              postalCode: string | null;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              sizeUnit: GQBuildingSizeUnit | null;
              country: string | null;
              buildingName: string;
            }
        >;
      }
    | {
        __typename: 'AssetPersonalMotorVehicleInsurance';
        id: string;
        orgId: string;
        createdAt: Date;
        updatedAt: Date;
        name: string;
        currencyCode: GQCurrencyCode;
        tagData: any | null;
        footprintSourceOptions: Array<{
          __typename?: 'FootprintSourceOption';
          id: string;
          label: string;
          year: number;
          source: GQEmissionsYearSource;
          externalScore: string | null;
        }>;
        revenueSourceOptions: Array<{
          __typename?: 'RevenueSourceOption';
          id: string;
          label: string;
          year: number;
          revenue: number | null;
          currencyCode: string | null;
        }>;
        assetYears: Array<{
          __typename: 'FinanceAssetYearNew';
          id: string;
          orgId: string;
          createdAt: Date;
          updatedAt: Date;
          year: number;
          assetCommercialRealEstateId: string | null;
          assetCorporateId: string | null;
          assetGroupId: string | null;
          assetSovereignBondId: string | null;
          assetPersonalMotorVehicleInsuranceId: string | null;
          buildingSizeNative: number | null;
          estimationMethodology: GQEstimationMethodology | null;
          headcount: number | null;
          percentRenewable: number | null;
          cloudSpendNative: number | null;
          valueNative: number | null;
          revenueNative: number | null;
          flightMiles: number | null;
          percentEmployeesWfh: number | null;
          currencyCode: GQCurrencyCode;
          currencyConversionRate: number;
          totalMwh: number | null;
          revenueSourceEmissionsYearId: string | null;
          emissionsYearId: string | null;
          scope1OverrideTco2e: number | null;
          scope1OverridePcaf: number | null;
          scope2LocationOverrideTco2e: number | null;
          scope2LocationOverridePcaf: number | null;
          scope2MarketOverrideTco2e: number | null;
          scope2MarketOverridePcaf: number | null;
          scope3OverrideKind: GQScope3OverrideKind | null;
          scope3OverrideTco2e: number | null;
          scope3OverridePcaf: number | null;
          scope301OverrideTco2e: number | null;
          scope302OverrideTco2e: number | null;
          scope303OverrideTco2e: number | null;
          scope304OverrideTco2e: number | null;
          scope305OverrideTco2e: number | null;
          scope306OverrideTco2e: number | null;
          scope307OverrideTco2e: number | null;
          scope308OverrideTco2e: number | null;
          scope309OverrideTco2e: number | null;
          scope310OverrideTco2e: number | null;
          scope311OverrideTco2e: number | null;
          scope312OverrideTco2e: number | null;
          scope313OverrideTco2e: number | null;
          scope314OverrideTco2e: number | null;
          scope315OverrideTco2e: number | null;
          buildingEstimationErr: string | null;
          companyEstimationErr: string | null;
          measuringScope1And2Emissions: boolean | null;
          measuringMaterialScope3Emissions: boolean | null;
          initiatedDecarbonizationPlan: boolean | null;
          committedToNearTermSbtAlignedNetZero: boolean | null;
          demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
          iciAlignmentOverride: GQIciAlignmentStatus | null;
          scenario: string | null;
          tagData: any | null;
        }>;
        assetHoldings: Array<{
          __typename: 'FinanceAssetHolding';
          id: string;
          orgId: string;
          createdAt: Date;
          updatedAt: Date;
          assetYearId: string;
          fundId: string;
          yearOfInitialInvestment: number | null;
          outstandingAmountNative: number | null;
          outstandingAmountNativeQ1: number | null;
          outstandingAmountNativeQ2: number | null;
          outstandingAmountNativeQ3: number | null;
          outstandingAmountNativeQ4: number | null;
          currencyCode: GQCurrencyCode;
          currencyConversionRate: number;
          assetClass: GQFundAssetClass;
          tagData: any | null;
          premiumNative: number | null;
          emissionsYearId: string | null;
        }>;
        assetGroupAssets: Array<
          | {
              __typename: 'AssetCorporate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              naicsCode: string | null;
              companyIsin: string | null;
              companyLei: string | null;
              companyUrl: string | null;
              companyTicker: string | null;
              currencyCode: GQCurrencyCode;
              countryAlpha2: string | null;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              companyId: string | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              anonymousCompanyUuid: string | null;
            }
          | {
              __typename: 'AssetGroup';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              companyId: string | null;
              assetGroupId: string | null;
            }
          | {
              __typename: 'AssetRealEstate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              buildingKind: string | null;
              name: string;
              streetAddress: string | null;
              streetAddress2: string | null;
              city: string | null;
              state: string | null;
              countryAlpha2: string | null;
              postalCode: string | null;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              sizeUnit: GQBuildingSizeUnit | null;
              country: string | null;
              buildingName: string;
            }
        > | null;
      }
    | {
        __typename: 'AssetRealEstate';
        id: string;
        orgId: string;
        createdAt: Date;
        updatedAt: Date;
        buildingKind: string | null;
        name: string;
        streetAddress: string | null;
        streetAddress2: string | null;
        city: string | null;
        state: string | null;
        countryAlpha2: string | null;
        postalCode: string | null;
        currencyCode: GQCurrencyCode;
        buildingSizeUnit: GQBuildingSizeUnit | null;
        otherData: any | null;
        tagData: any | null;
        externalId: string | null;
        assetGroupId: string | null;
        sizeUnit: GQBuildingSizeUnit | null;
        country: string | null;
        buildingName: string;
        footprintSourceOptions: Array<{
          __typename?: 'FootprintSourceOption';
          id: string;
          label: string;
          year: number;
          source: GQEmissionsYearSource;
          externalScore: string | null;
        }>;
        revenueSourceOptions: Array<{
          __typename?: 'RevenueSourceOption';
          id: string;
          label: string;
          year: number;
          revenue: number | null;
          currencyCode: string | null;
        }>;
        assetYears: Array<{
          __typename: 'FinanceAssetYearNew';
          id: string;
          orgId: string;
          createdAt: Date;
          updatedAt: Date;
          year: number;
          assetCommercialRealEstateId: string | null;
          assetCorporateId: string | null;
          assetGroupId: string | null;
          assetSovereignBondId: string | null;
          assetPersonalMotorVehicleInsuranceId: string | null;
          buildingSizeNative: number | null;
          estimationMethodology: GQEstimationMethodology | null;
          headcount: number | null;
          percentRenewable: number | null;
          cloudSpendNative: number | null;
          valueNative: number | null;
          revenueNative: number | null;
          flightMiles: number | null;
          percentEmployeesWfh: number | null;
          currencyCode: GQCurrencyCode;
          currencyConversionRate: number;
          totalMwh: number | null;
          revenueSourceEmissionsYearId: string | null;
          emissionsYearId: string | null;
          scope1OverrideTco2e: number | null;
          scope1OverridePcaf: number | null;
          scope2LocationOverrideTco2e: number | null;
          scope2LocationOverridePcaf: number | null;
          scope2MarketOverrideTco2e: number | null;
          scope2MarketOverridePcaf: number | null;
          scope3OverrideKind: GQScope3OverrideKind | null;
          scope3OverrideTco2e: number | null;
          scope3OverridePcaf: number | null;
          scope301OverrideTco2e: number | null;
          scope302OverrideTco2e: number | null;
          scope303OverrideTco2e: number | null;
          scope304OverrideTco2e: number | null;
          scope305OverrideTco2e: number | null;
          scope306OverrideTco2e: number | null;
          scope307OverrideTco2e: number | null;
          scope308OverrideTco2e: number | null;
          scope309OverrideTco2e: number | null;
          scope310OverrideTco2e: number | null;
          scope311OverrideTco2e: number | null;
          scope312OverrideTco2e: number | null;
          scope313OverrideTco2e: number | null;
          scope314OverrideTco2e: number | null;
          scope315OverrideTco2e: number | null;
          buildingEstimationErr: string | null;
          companyEstimationErr: string | null;
          measuringScope1And2Emissions: boolean | null;
          measuringMaterialScope3Emissions: boolean | null;
          initiatedDecarbonizationPlan: boolean | null;
          committedToNearTermSbtAlignedNetZero: boolean | null;
          demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
          iciAlignmentOverride: GQIciAlignmentStatus | null;
          scenario: string | null;
          tagData: any | null;
        }>;
        assetHoldings: Array<{
          __typename: 'FinanceAssetHolding';
          id: string;
          orgId: string;
          createdAt: Date;
          updatedAt: Date;
          assetYearId: string;
          fundId: string;
          yearOfInitialInvestment: number | null;
          outstandingAmountNative: number | null;
          outstandingAmountNativeQ1: number | null;
          outstandingAmountNativeQ2: number | null;
          outstandingAmountNativeQ3: number | null;
          outstandingAmountNativeQ4: number | null;
          currencyCode: GQCurrencyCode;
          currencyConversionRate: number;
          assetClass: GQFundAssetClass;
          tagData: any | null;
          premiumNative: number | null;
          emissionsYearId: string | null;
        }>;
        assetGroupAssets: Array<
          | {
              __typename: 'AssetCorporate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              naicsCode: string | null;
              companyIsin: string | null;
              companyLei: string | null;
              companyUrl: string | null;
              companyTicker: string | null;
              currencyCode: GQCurrencyCode;
              countryAlpha2: string | null;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              companyId: string | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              anonymousCompanyUuid: string | null;
            }
          | {
              __typename: 'AssetGroup';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              companyId: string | null;
              assetGroupId: string | null;
            }
          | {
              __typename: 'AssetRealEstate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              buildingKind: string | null;
              name: string;
              streetAddress: string | null;
              streetAddress2: string | null;
              city: string | null;
              state: string | null;
              countryAlpha2: string | null;
              postalCode: string | null;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              sizeUnit: GQBuildingSizeUnit | null;
              country: string | null;
              buildingName: string;
            }
        > | null;
      }
    | {
        __typename: 'AssetSovereignBond';
        id: string;
        orgId: string;
        createdAt: Date;
        updatedAt: Date;
        name: string;
        sovereignEntity: string;
        currencyCode: GQCurrencyCode;
        tagData: any | null;
        footprintSourceOptions: Array<{
          __typename?: 'FootprintSourceOption';
          id: string;
          label: string;
          year: number;
          source: GQEmissionsYearSource;
          externalScore: string | null;
        }>;
        revenueSourceOptions: Array<{
          __typename?: 'RevenueSourceOption';
          id: string;
          label: string;
          year: number;
          revenue: number | null;
          currencyCode: string | null;
        }>;
        assetYears: Array<{
          __typename: 'FinanceAssetYearNew';
          id: string;
          orgId: string;
          createdAt: Date;
          updatedAt: Date;
          year: number;
          assetCommercialRealEstateId: string | null;
          assetCorporateId: string | null;
          assetGroupId: string | null;
          assetSovereignBondId: string | null;
          assetPersonalMotorVehicleInsuranceId: string | null;
          buildingSizeNative: number | null;
          estimationMethodology: GQEstimationMethodology | null;
          headcount: number | null;
          percentRenewable: number | null;
          cloudSpendNative: number | null;
          valueNative: number | null;
          revenueNative: number | null;
          flightMiles: number | null;
          percentEmployeesWfh: number | null;
          currencyCode: GQCurrencyCode;
          currencyConversionRate: number;
          totalMwh: number | null;
          revenueSourceEmissionsYearId: string | null;
          emissionsYearId: string | null;
          scope1OverrideTco2e: number | null;
          scope1OverridePcaf: number | null;
          scope2LocationOverrideTco2e: number | null;
          scope2LocationOverridePcaf: number | null;
          scope2MarketOverrideTco2e: number | null;
          scope2MarketOverridePcaf: number | null;
          scope3OverrideKind: GQScope3OverrideKind | null;
          scope3OverrideTco2e: number | null;
          scope3OverridePcaf: number | null;
          scope301OverrideTco2e: number | null;
          scope302OverrideTco2e: number | null;
          scope303OverrideTco2e: number | null;
          scope304OverrideTco2e: number | null;
          scope305OverrideTco2e: number | null;
          scope306OverrideTco2e: number | null;
          scope307OverrideTco2e: number | null;
          scope308OverrideTco2e: number | null;
          scope309OverrideTco2e: number | null;
          scope310OverrideTco2e: number | null;
          scope311OverrideTco2e: number | null;
          scope312OverrideTco2e: number | null;
          scope313OverrideTco2e: number | null;
          scope314OverrideTco2e: number | null;
          scope315OverrideTco2e: number | null;
          buildingEstimationErr: string | null;
          companyEstimationErr: string | null;
          measuringScope1And2Emissions: boolean | null;
          measuringMaterialScope3Emissions: boolean | null;
          initiatedDecarbonizationPlan: boolean | null;
          committedToNearTermSbtAlignedNetZero: boolean | null;
          demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
          iciAlignmentOverride: GQIciAlignmentStatus | null;
          scenario: string | null;
          tagData: any | null;
        }>;
        assetHoldings: Array<{
          __typename: 'FinanceAssetHolding';
          id: string;
          orgId: string;
          createdAt: Date;
          updatedAt: Date;
          assetYearId: string;
          fundId: string;
          yearOfInitialInvestment: number | null;
          outstandingAmountNative: number | null;
          outstandingAmountNativeQ1: number | null;
          outstandingAmountNativeQ2: number | null;
          outstandingAmountNativeQ3: number | null;
          outstandingAmountNativeQ4: number | null;
          currencyCode: GQCurrencyCode;
          currencyConversionRate: number;
          assetClass: GQFundAssetClass;
          tagData: any | null;
          premiumNative: number | null;
          emissionsYearId: string | null;
        }>;
        assetGroupAssets: Array<
          | {
              __typename: 'AssetCorporate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              naicsCode: string | null;
              companyIsin: string | null;
              companyLei: string | null;
              companyUrl: string | null;
              companyTicker: string | null;
              currencyCode: GQCurrencyCode;
              countryAlpha2: string | null;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              companyId: string | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              anonymousCompanyUuid: string | null;
            }
          | {
              __typename: 'AssetGroup';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              name: string;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              companyId: string | null;
              assetGroupId: string | null;
            }
          | {
              __typename: 'AssetRealEstate';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              buildingKind: string | null;
              name: string;
              streetAddress: string | null;
              streetAddress2: string | null;
              city: string | null;
              state: string | null;
              countryAlpha2: string | null;
              postalCode: string | null;
              currencyCode: GQCurrencyCode;
              buildingSizeUnit: GQBuildingSizeUnit | null;
              otherData: any | null;
              tagData: any | null;
              externalId: string | null;
              assetGroupId: string | null;
              sizeUnit: GQBuildingSizeUnit | null;
              country: string | null;
              buildingName: string;
            }
        > | null;
      };
  funds: Array<{
    __typename: 'Fund';
    id: string;
    name: string;
    nameSortable: string | null;
    fundGroup: string | null;
    fundCategory: string | null;
    excludeAsSandbox: boolean | null;
    anonymousFundUuid: string | null;
    createdAt: Date;
    orgId: string;
    updatedAt: Date;
    tagData: any | null;
    externalId: string | null;
  }>;
};

export type GQAssetInfoPageSnapshotsQueryVariables = Exact<{
  financeSnapshotId: Scalars['ID']['input'];
  filters: Scalars['UntypedData']['input'];
  year: Scalars['Int']['input'];
  assetId: Scalars['ID']['input'];
}>;

export type GQAssetInfoPageSnapshotsQuery = {
  __typename?: 'Query';
  financeSnapshotHoldings: {
    __typename?: 'FinanceSnapshotHoldings';
    id: string;
    edges: Array<{
      __typename?: 'FinanceSnapshotHoldingEdge';
      node: any | null;
    } | null>;
  };
  financeSnapshotAsset: {
    __typename?: 'FinanceSnapshotAsset';
    asset: any | null;
  };
};

export type GQUpdateAssetPersonalMotorVehicleInsuranceMutationVariables =
  Exact<{
    input: GQUpdateAssetPersonalMotorVehicleInsuranceInput;
  }>;

export type GQUpdateAssetPersonalMotorVehicleInsuranceMutation = {
  __typename?: 'Mutation';
  updateAssetPersonalMotorVehicleInsurance: {
    __typename?: 'UpdateAssetPersonalMotorVehicleInsurancePayload';
    asset: {
      __typename: 'AssetPersonalMotorVehicleInsurance';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      name: string;
      currencyCode: GQCurrencyCode;
      tagData: any | null;
      assetYears: Array<{
        __typename?: 'FinanceAssetYearNew';
        id: string;
        currencyCode: GQCurrencyCode;
        valueNative: number | null;
        emissionsYearId: string | null;
      }>;
      footprintSourceOptions: Array<{
        __typename?: 'FootprintSourceOption';
        id: string;
      }>;
    } | null;
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQAssetRealEstateAllFieldsFragment = {
  __typename: 'AssetRealEstate';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  buildingKind: string | null;
  name: string;
  streetAddress: string | null;
  streetAddress2: string | null;
  city: string | null;
  state: string | null;
  countryAlpha2: string | null;
  postalCode: string | null;
  currencyCode: GQCurrencyCode;
  buildingSizeUnit: GQBuildingSizeUnit | null;
  otherData: any | null;
  tagData: any | null;
  externalId: string | null;
  assetGroupId: string | null;
  sizeUnit: GQBuildingSizeUnit | null;
  country: string | null;
  buildingName: string;
};

export type GQUpdateAssetRealEstateMutationVariables = Exact<{
  input: GQUpdateAssetRealEstateInput;
}>;

export type GQUpdateAssetRealEstateMutation = {
  __typename?: 'Mutation';
  updateAssetRealEstate: {
    __typename?: 'UpdateAssetRealEstatePayload';
    asset: {
      __typename: 'AssetRealEstate';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      buildingKind: string | null;
      name: string;
      streetAddress: string | null;
      streetAddress2: string | null;
      city: string | null;
      state: string | null;
      countryAlpha2: string | null;
      postalCode: string | null;
      currencyCode: GQCurrencyCode;
      buildingSizeUnit: GQBuildingSizeUnit | null;
      otherData: any | null;
      tagData: any | null;
      externalId: string | null;
      assetGroupId: string | null;
      sizeUnit: GQBuildingSizeUnit | null;
      country: string | null;
      buildingName: string;
    } | null;
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQAssetSovereignBondAllFieldsFragment = {
  __typename: 'AssetSovereignBond';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  sovereignEntity: string;
  currencyCode: GQCurrencyCode;
  tagData: any | null;
};

export type GQUpdateAssetSovereignBondMutationVariables = Exact<{
  input: GQUpdateAssetSovereignBondInput;
}>;

export type GQUpdateAssetSovereignBondMutation = {
  __typename?: 'Mutation';
  updateAssetSovereignBond: {
    __typename?: 'UpdateAssetSovereignBondPayload';
    asset: {
      __typename: 'AssetSovereignBond';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      name: string;
      sovereignEntity: string;
      currencyCode: GQCurrencyCode;
      tagData: any | null;
      assetYears: Array<{
        __typename?: 'FinanceAssetYearNew';
        id: string;
        currencyCode: GQCurrencyCode;
        valueNative: number | null;
        emissionsYearId: string | null;
      }>;
      footprintSourceOptions: Array<{
        __typename?: 'FootprintSourceOption';
        id: string;
      }>;
    } | null;
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQAssetYearAllFieldsFragment = {
  __typename: 'FinanceAssetYearNew';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  year: number;
  assetCommercialRealEstateId: string | null;
  assetCorporateId: string | null;
  assetGroupId: string | null;
  assetSovereignBondId: string | null;
  assetPersonalMotorVehicleInsuranceId: string | null;
  buildingSizeNative: number | null;
  estimationMethodology: GQEstimationMethodology | null;
  headcount: number | null;
  percentRenewable: number | null;
  cloudSpendNative: number | null;
  valueNative: number | null;
  revenueNative: number | null;
  flightMiles: number | null;
  percentEmployeesWfh: number | null;
  currencyCode: GQCurrencyCode;
  currencyConversionRate: number;
  totalMwh: number | null;
  revenueSourceEmissionsYearId: string | null;
  emissionsYearId: string | null;
  scope1OverrideTco2e: number | null;
  scope1OverridePcaf: number | null;
  scope2LocationOverrideTco2e: number | null;
  scope2LocationOverridePcaf: number | null;
  scope2MarketOverrideTco2e: number | null;
  scope2MarketOverridePcaf: number | null;
  scope3OverrideKind: GQScope3OverrideKind | null;
  scope3OverrideTco2e: number | null;
  scope3OverridePcaf: number | null;
  scope301OverrideTco2e: number | null;
  scope302OverrideTco2e: number | null;
  scope303OverrideTco2e: number | null;
  scope304OverrideTco2e: number | null;
  scope305OverrideTco2e: number | null;
  scope306OverrideTco2e: number | null;
  scope307OverrideTco2e: number | null;
  scope308OverrideTco2e: number | null;
  scope309OverrideTco2e: number | null;
  scope310OverrideTco2e: number | null;
  scope311OverrideTco2e: number | null;
  scope312OverrideTco2e: number | null;
  scope313OverrideTco2e: number | null;
  scope314OverrideTco2e: number | null;
  scope315OverrideTco2e: number | null;
  buildingEstimationErr: string | null;
  companyEstimationErr: string | null;
  measuringScope1And2Emissions: boolean | null;
  measuringMaterialScope3Emissions: boolean | null;
  initiatedDecarbonizationPlan: boolean | null;
  committedToNearTermSbtAlignedNetZero: boolean | null;
  demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
  iciAlignmentOverride: GQIciAlignmentStatus | null;
  scenario: string | null;
  tagData: any | null;
};

export type GQCreateAssetYearMutationVariables = Exact<{
  input: GQCreateAssetYearNewInput;
  year: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQCreateAssetYearMutation = {
  __typename?: 'Mutation';
  createAssetYear: {
    __typename?: 'CreateAssetYearNewPayload';
    assetYear: {
      __typename: 'FinanceAssetYearNew';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      year: number;
      assetCommercialRealEstateId: string | null;
      assetCorporateId: string | null;
      assetGroupId: string | null;
      assetSovereignBondId: string | null;
      assetPersonalMotorVehicleInsuranceId: string | null;
      buildingSizeNative: number | null;
      estimationMethodology: GQEstimationMethodology | null;
      headcount: number | null;
      percentRenewable: number | null;
      cloudSpendNative: number | null;
      valueNative: number | null;
      revenueNative: number | null;
      flightMiles: number | null;
      percentEmployeesWfh: number | null;
      currencyCode: GQCurrencyCode;
      currencyConversionRate: number;
      totalMwh: number | null;
      revenueSourceEmissionsYearId: string | null;
      emissionsYearId: string | null;
      scope1OverrideTco2e: number | null;
      scope1OverridePcaf: number | null;
      scope2LocationOverrideTco2e: number | null;
      scope2LocationOverridePcaf: number | null;
      scope2MarketOverrideTco2e: number | null;
      scope2MarketOverridePcaf: number | null;
      scope3OverrideKind: GQScope3OverrideKind | null;
      scope3OverrideTco2e: number | null;
      scope3OverridePcaf: number | null;
      scope301OverrideTco2e: number | null;
      scope302OverrideTco2e: number | null;
      scope303OverrideTco2e: number | null;
      scope304OverrideTco2e: number | null;
      scope305OverrideTco2e: number | null;
      scope306OverrideTco2e: number | null;
      scope307OverrideTco2e: number | null;
      scope308OverrideTco2e: number | null;
      scope309OverrideTco2e: number | null;
      scope310OverrideTco2e: number | null;
      scope311OverrideTco2e: number | null;
      scope312OverrideTco2e: number | null;
      scope313OverrideTco2e: number | null;
      scope314OverrideTco2e: number | null;
      scope315OverrideTco2e: number | null;
      buildingEstimationErr: string | null;
      companyEstimationErr: string | null;
      measuringScope1And2Emissions: boolean | null;
      measuringMaterialScope3Emissions: boolean | null;
      initiatedDecarbonizationPlan: boolean | null;
      committedToNearTermSbtAlignedNetZero: boolean | null;
      demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
      iciAlignmentOverride: GQIciAlignmentStatus | null;
      scenario: string | null;
      tagData: any | null;
      asset:
        | {
            __typename?: 'AssetCorporate';
            id: string;
            assetYears: Array<{
              __typename: 'FinanceAssetYearNew';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              year: number;
              assetCommercialRealEstateId: string | null;
              assetCorporateId: string | null;
              assetGroupId: string | null;
              assetSovereignBondId: string | null;
              assetPersonalMotorVehicleInsuranceId: string | null;
              buildingSizeNative: number | null;
              estimationMethodology: GQEstimationMethodology | null;
              headcount: number | null;
              percentRenewable: number | null;
              cloudSpendNative: number | null;
              valueNative: number | null;
              revenueNative: number | null;
              flightMiles: number | null;
              percentEmployeesWfh: number | null;
              currencyCode: GQCurrencyCode;
              currencyConversionRate: number;
              totalMwh: number | null;
              revenueSourceEmissionsYearId: string | null;
              emissionsYearId: string | null;
              scope1OverrideTco2e: number | null;
              scope1OverridePcaf: number | null;
              scope2LocationOverrideTco2e: number | null;
              scope2LocationOverridePcaf: number | null;
              scope2MarketOverrideTco2e: number | null;
              scope2MarketOverridePcaf: number | null;
              scope3OverrideKind: GQScope3OverrideKind | null;
              scope3OverrideTco2e: number | null;
              scope3OverridePcaf: number | null;
              scope301OverrideTco2e: number | null;
              scope302OverrideTco2e: number | null;
              scope303OverrideTco2e: number | null;
              scope304OverrideTco2e: number | null;
              scope305OverrideTco2e: number | null;
              scope306OverrideTco2e: number | null;
              scope307OverrideTco2e: number | null;
              scope308OverrideTco2e: number | null;
              scope309OverrideTco2e: number | null;
              scope310OverrideTco2e: number | null;
              scope311OverrideTco2e: number | null;
              scope312OverrideTco2e: number | null;
              scope313OverrideTco2e: number | null;
              scope314OverrideTco2e: number | null;
              scope315OverrideTco2e: number | null;
              buildingEstimationErr: string | null;
              companyEstimationErr: string | null;
              measuringScope1And2Emissions: boolean | null;
              measuringMaterialScope3Emissions: boolean | null;
              initiatedDecarbonizationPlan: boolean | null;
              committedToNearTermSbtAlignedNetZero: boolean | null;
              demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
              iciAlignmentOverride: GQIciAlignmentStatus | null;
              scenario: string | null;
              tagData: any | null;
            }>;
          }
        | {
            __typename?: 'AssetGroup';
            id: string;
            assetYears: Array<{
              __typename: 'FinanceAssetYearNew';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              year: number;
              assetCommercialRealEstateId: string | null;
              assetCorporateId: string | null;
              assetGroupId: string | null;
              assetSovereignBondId: string | null;
              assetPersonalMotorVehicleInsuranceId: string | null;
              buildingSizeNative: number | null;
              estimationMethodology: GQEstimationMethodology | null;
              headcount: number | null;
              percentRenewable: number | null;
              cloudSpendNative: number | null;
              valueNative: number | null;
              revenueNative: number | null;
              flightMiles: number | null;
              percentEmployeesWfh: number | null;
              currencyCode: GQCurrencyCode;
              currencyConversionRate: number;
              totalMwh: number | null;
              revenueSourceEmissionsYearId: string | null;
              emissionsYearId: string | null;
              scope1OverrideTco2e: number | null;
              scope1OverridePcaf: number | null;
              scope2LocationOverrideTco2e: number | null;
              scope2LocationOverridePcaf: number | null;
              scope2MarketOverrideTco2e: number | null;
              scope2MarketOverridePcaf: number | null;
              scope3OverrideKind: GQScope3OverrideKind | null;
              scope3OverrideTco2e: number | null;
              scope3OverridePcaf: number | null;
              scope301OverrideTco2e: number | null;
              scope302OverrideTco2e: number | null;
              scope303OverrideTco2e: number | null;
              scope304OverrideTco2e: number | null;
              scope305OverrideTco2e: number | null;
              scope306OverrideTco2e: number | null;
              scope307OverrideTco2e: number | null;
              scope308OverrideTco2e: number | null;
              scope309OverrideTco2e: number | null;
              scope310OverrideTco2e: number | null;
              scope311OverrideTco2e: number | null;
              scope312OverrideTco2e: number | null;
              scope313OverrideTco2e: number | null;
              scope314OverrideTco2e: number | null;
              scope315OverrideTco2e: number | null;
              buildingEstimationErr: string | null;
              companyEstimationErr: string | null;
              measuringScope1And2Emissions: boolean | null;
              measuringMaterialScope3Emissions: boolean | null;
              initiatedDecarbonizationPlan: boolean | null;
              committedToNearTermSbtAlignedNetZero: boolean | null;
              demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
              iciAlignmentOverride: GQIciAlignmentStatus | null;
              scenario: string | null;
              tagData: any | null;
            }>;
          }
        | {
            __typename?: 'AssetPersonalMotorVehicleInsurance';
            id: string;
            assetYears: Array<{
              __typename: 'FinanceAssetYearNew';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              year: number;
              assetCommercialRealEstateId: string | null;
              assetCorporateId: string | null;
              assetGroupId: string | null;
              assetSovereignBondId: string | null;
              assetPersonalMotorVehicleInsuranceId: string | null;
              buildingSizeNative: number | null;
              estimationMethodology: GQEstimationMethodology | null;
              headcount: number | null;
              percentRenewable: number | null;
              cloudSpendNative: number | null;
              valueNative: number | null;
              revenueNative: number | null;
              flightMiles: number | null;
              percentEmployeesWfh: number | null;
              currencyCode: GQCurrencyCode;
              currencyConversionRate: number;
              totalMwh: number | null;
              revenueSourceEmissionsYearId: string | null;
              emissionsYearId: string | null;
              scope1OverrideTco2e: number | null;
              scope1OverridePcaf: number | null;
              scope2LocationOverrideTco2e: number | null;
              scope2LocationOverridePcaf: number | null;
              scope2MarketOverrideTco2e: number | null;
              scope2MarketOverridePcaf: number | null;
              scope3OverrideKind: GQScope3OverrideKind | null;
              scope3OverrideTco2e: number | null;
              scope3OverridePcaf: number | null;
              scope301OverrideTco2e: number | null;
              scope302OverrideTco2e: number | null;
              scope303OverrideTco2e: number | null;
              scope304OverrideTco2e: number | null;
              scope305OverrideTco2e: number | null;
              scope306OverrideTco2e: number | null;
              scope307OverrideTco2e: number | null;
              scope308OverrideTco2e: number | null;
              scope309OverrideTco2e: number | null;
              scope310OverrideTco2e: number | null;
              scope311OverrideTco2e: number | null;
              scope312OverrideTco2e: number | null;
              scope313OverrideTco2e: number | null;
              scope314OverrideTco2e: number | null;
              scope315OverrideTco2e: number | null;
              buildingEstimationErr: string | null;
              companyEstimationErr: string | null;
              measuringScope1And2Emissions: boolean | null;
              measuringMaterialScope3Emissions: boolean | null;
              initiatedDecarbonizationPlan: boolean | null;
              committedToNearTermSbtAlignedNetZero: boolean | null;
              demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
              iciAlignmentOverride: GQIciAlignmentStatus | null;
              scenario: string | null;
              tagData: any | null;
            }>;
          }
        | {
            __typename?: 'AssetRealEstate';
            id: string;
            assetYears: Array<{
              __typename: 'FinanceAssetYearNew';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              year: number;
              assetCommercialRealEstateId: string | null;
              assetCorporateId: string | null;
              assetGroupId: string | null;
              assetSovereignBondId: string | null;
              assetPersonalMotorVehicleInsuranceId: string | null;
              buildingSizeNative: number | null;
              estimationMethodology: GQEstimationMethodology | null;
              headcount: number | null;
              percentRenewable: number | null;
              cloudSpendNative: number | null;
              valueNative: number | null;
              revenueNative: number | null;
              flightMiles: number | null;
              percentEmployeesWfh: number | null;
              currencyCode: GQCurrencyCode;
              currencyConversionRate: number;
              totalMwh: number | null;
              revenueSourceEmissionsYearId: string | null;
              emissionsYearId: string | null;
              scope1OverrideTco2e: number | null;
              scope1OverridePcaf: number | null;
              scope2LocationOverrideTco2e: number | null;
              scope2LocationOverridePcaf: number | null;
              scope2MarketOverrideTco2e: number | null;
              scope2MarketOverridePcaf: number | null;
              scope3OverrideKind: GQScope3OverrideKind | null;
              scope3OverrideTco2e: number | null;
              scope3OverridePcaf: number | null;
              scope301OverrideTco2e: number | null;
              scope302OverrideTco2e: number | null;
              scope303OverrideTco2e: number | null;
              scope304OverrideTco2e: number | null;
              scope305OverrideTco2e: number | null;
              scope306OverrideTco2e: number | null;
              scope307OverrideTco2e: number | null;
              scope308OverrideTco2e: number | null;
              scope309OverrideTco2e: number | null;
              scope310OverrideTco2e: number | null;
              scope311OverrideTco2e: number | null;
              scope312OverrideTco2e: number | null;
              scope313OverrideTco2e: number | null;
              scope314OverrideTco2e: number | null;
              scope315OverrideTco2e: number | null;
              buildingEstimationErr: string | null;
              companyEstimationErr: string | null;
              measuringScope1And2Emissions: boolean | null;
              measuringMaterialScope3Emissions: boolean | null;
              initiatedDecarbonizationPlan: boolean | null;
              committedToNearTermSbtAlignedNetZero: boolean | null;
              demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
              iciAlignmentOverride: GQIciAlignmentStatus | null;
              scenario: string | null;
              tagData: any | null;
            }>;
          }
        | {
            __typename?: 'AssetSovereignBond';
            id: string;
            assetYears: Array<{
              __typename: 'FinanceAssetYearNew';
              id: string;
              orgId: string;
              createdAt: Date;
              updatedAt: Date;
              year: number;
              assetCommercialRealEstateId: string | null;
              assetCorporateId: string | null;
              assetGroupId: string | null;
              assetSovereignBondId: string | null;
              assetPersonalMotorVehicleInsuranceId: string | null;
              buildingSizeNative: number | null;
              estimationMethodology: GQEstimationMethodology | null;
              headcount: number | null;
              percentRenewable: number | null;
              cloudSpendNative: number | null;
              valueNative: number | null;
              revenueNative: number | null;
              flightMiles: number | null;
              percentEmployeesWfh: number | null;
              currencyCode: GQCurrencyCode;
              currencyConversionRate: number;
              totalMwh: number | null;
              revenueSourceEmissionsYearId: string | null;
              emissionsYearId: string | null;
              scope1OverrideTco2e: number | null;
              scope1OverridePcaf: number | null;
              scope2LocationOverrideTco2e: number | null;
              scope2LocationOverridePcaf: number | null;
              scope2MarketOverrideTco2e: number | null;
              scope2MarketOverridePcaf: number | null;
              scope3OverrideKind: GQScope3OverrideKind | null;
              scope3OverrideTco2e: number | null;
              scope3OverridePcaf: number | null;
              scope301OverrideTco2e: number | null;
              scope302OverrideTco2e: number | null;
              scope303OverrideTco2e: number | null;
              scope304OverrideTco2e: number | null;
              scope305OverrideTco2e: number | null;
              scope306OverrideTco2e: number | null;
              scope307OverrideTco2e: number | null;
              scope308OverrideTco2e: number | null;
              scope309OverrideTco2e: number | null;
              scope310OverrideTco2e: number | null;
              scope311OverrideTco2e: number | null;
              scope312OverrideTco2e: number | null;
              scope313OverrideTco2e: number | null;
              scope314OverrideTco2e: number | null;
              scope315OverrideTco2e: number | null;
              buildingEstimationErr: string | null;
              companyEstimationErr: string | null;
              measuringScope1And2Emissions: boolean | null;
              measuringMaterialScope3Emissions: boolean | null;
              initiatedDecarbonizationPlan: boolean | null;
              committedToNearTermSbtAlignedNetZero: boolean | null;
              demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
              iciAlignmentOverride: GQIciAlignmentStatus | null;
              scenario: string | null;
              tagData: any | null;
            }>;
          }
        | null;
    };
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQUpdateAssetYearMutationVariables = Exact<{
  input: GQUpdateAssetYearNewInput;
}>;

export type GQUpdateAssetYearMutation = {
  __typename?: 'Mutation';
  updateAssetYear: {
    __typename?: 'UpdateAssetYearNewPayload';
    assetYear: {
      __typename: 'FinanceAssetYearNew';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      year: number;
      assetCommercialRealEstateId: string | null;
      assetCorporateId: string | null;
      assetGroupId: string | null;
      assetSovereignBondId: string | null;
      assetPersonalMotorVehicleInsuranceId: string | null;
      buildingSizeNative: number | null;
      estimationMethodology: GQEstimationMethodology | null;
      headcount: number | null;
      percentRenewable: number | null;
      cloudSpendNative: number | null;
      valueNative: number | null;
      revenueNative: number | null;
      flightMiles: number | null;
      percentEmployeesWfh: number | null;
      currencyCode: GQCurrencyCode;
      currencyConversionRate: number;
      totalMwh: number | null;
      revenueSourceEmissionsYearId: string | null;
      emissionsYearId: string | null;
      scope1OverrideTco2e: number | null;
      scope1OverridePcaf: number | null;
      scope2LocationOverrideTco2e: number | null;
      scope2LocationOverridePcaf: number | null;
      scope2MarketOverrideTco2e: number | null;
      scope2MarketOverridePcaf: number | null;
      scope3OverrideKind: GQScope3OverrideKind | null;
      scope3OverrideTco2e: number | null;
      scope3OverridePcaf: number | null;
      scope301OverrideTco2e: number | null;
      scope302OverrideTco2e: number | null;
      scope303OverrideTco2e: number | null;
      scope304OverrideTco2e: number | null;
      scope305OverrideTco2e: number | null;
      scope306OverrideTco2e: number | null;
      scope307OverrideTco2e: number | null;
      scope308OverrideTco2e: number | null;
      scope309OverrideTco2e: number | null;
      scope310OverrideTco2e: number | null;
      scope311OverrideTco2e: number | null;
      scope312OverrideTco2e: number | null;
      scope313OverrideTco2e: number | null;
      scope314OverrideTco2e: number | null;
      scope315OverrideTco2e: number | null;
      buildingEstimationErr: string | null;
      companyEstimationErr: string | null;
      measuringScope1And2Emissions: boolean | null;
      measuringMaterialScope3Emissions: boolean | null;
      initiatedDecarbonizationPlan: boolean | null;
      committedToNearTermSbtAlignedNetZero: boolean | null;
      demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
      iciAlignmentOverride: GQIciAlignmentStatus | null;
      scenario: string | null;
      tagData: any | null;
    };
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQDeleteFinanceSavedViewMutationVariables = Exact<{
  input: GQDeleteFinanceSavedViewInput;
}>;

export type GQDeleteFinanceSavedViewMutation = {
  __typename?: 'Mutation';
  deleteFinanceSavedView: {
    __typename?: 'DeleteFinanceSavedViewPayload';
    id: string;
  } | null;
};

export type GQFinanceTagForChartSelectorFragment = {
  __typename?: 'FinanceTag';
  id: string;
  name: string;
  columnFormat: GQGridColumnFormat;
};

export type GQFinanceSavedViewFormQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFinanceSavedViewFormQuery = {
  __typename?: 'Query';
  financeTags: Array<{
    __typename?: 'FinanceTag';
    id: string;
    name: string;
    columnFormat: GQGridColumnFormat;
  }>;
};

export type GQAssetNameQueryVariables = Exact<{
  assetId: Scalars['ID']['input'];
}>;

export type GQAssetNameQuery = {
  __typename?: 'Query';
  asset:
    | { __typename?: 'AssetCorporate'; id: string; name: string }
    | { __typename?: 'AssetGroup'; id: string; name: string }
    | {
        __typename?: 'AssetPersonalMotorVehicleInsurance';
        id: string;
        name: string;
      }
    | { __typename?: 'AssetRealEstate'; id: string; name: string }
    | { __typename?: 'AssetSovereignBond'; id: string; name: string };
};

export type GQFinanceMetricBarMinimalFragment = {
  __typename?: 'FinanceMetricBar';
  id: string;
  metrics: Array<
    | {
        __typename: 'FinanceAggregateMetric';
        metric: GQFinanceMetric;
        name: string;
        description: string | null;
        quantity: number;
        unit: string | null;
        additionalInfo: string | null;
        isMissingInputs: boolean;
      }
    | {
        __typename: 'FinanceStringMetric';
        metric: GQFinanceMetric;
        name: string;
        description: string | null;
        value: string;
        unit: string | null;
        additionalInfo: string | null;
        isMissingInputs: boolean;
      }
  >;
  emptyMetrics: Array<
    | {
        __typename: 'FinanceAggregateMetric';
        metric: GQFinanceMetric;
        name: string;
        description: string | null;
      }
    | {
        __typename: 'FinanceStringMetric';
        metric: GQFinanceMetric;
        name: string;
        description: string | null;
      }
  >;
};

export type GQFinanceChartAllFieldsFragment = {
  __typename?: 'FinanceChart';
  id: string;
  title: string;
  subtitle: string | null;
  chartKind: GQFinanceChartKind;
  tooltipText: string | null;
  data: any;
  isWideFormat: boolean | null;
  activeObjectType: GQFinanceChartActiveObjectType | null;
  highchartsKind: GQFinanceHighchartsChartKind;
  highchartsData: any;
  highchartsUnit: string | null;
  metric: GQFinanceChartMetric | null;
};

export type GQFinanceMetricsAndChartsQueryVariables = Exact<{
  financeSnapshotId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
  isTotalEmissions: InputMaybe<Scalars['Boolean']['input']>;
  filters: InputMaybe<Scalars['UntypedData']['input']>;
  standardView: InputMaybe<GQFinanceStandardView>;
  viewId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQFinanceMetricsAndChartsQuery = {
  __typename?: 'Query';
  financeHoldingView: {
    __typename?: 'FinanceView';
    metricBar: {
      __typename?: 'FinanceMetricBar';
      id: string;
      metrics: Array<
        | {
            __typename: 'FinanceAggregateMetric';
            metric: GQFinanceMetric;
            name: string;
            description: string | null;
            quantity: number;
            unit: string | null;
            additionalInfo: string | null;
            isMissingInputs: boolean;
          }
        | {
            __typename: 'FinanceStringMetric';
            metric: GQFinanceMetric;
            name: string;
            description: string | null;
            value: string;
            unit: string | null;
            additionalInfo: string | null;
            isMissingInputs: boolean;
          }
      >;
      emptyMetrics: Array<
        | {
            __typename: 'FinanceAggregateMetric';
            metric: GQFinanceMetric;
            name: string;
            description: string | null;
          }
        | {
            __typename: 'FinanceStringMetric';
            metric: GQFinanceMetric;
            name: string;
            description: string | null;
          }
      >;
    } | null;
    charts: Array<{
      __typename?: 'FinanceChart';
      id: string;
      title: string;
      subtitle: string | null;
      chartKind: GQFinanceChartKind;
      tooltipText: string | null;
      data: any;
      isWideFormat: boolean | null;
      activeObjectType: GQFinanceChartActiveObjectType | null;
      highchartsKind: GQFinanceHighchartsChartKind;
      highchartsData: any;
      highchartsUnit: string | null;
      metric: GQFinanceChartMetric | null;
    }> | null;
  };
};

export type GQTaskStatusDumpAllFieldsFragment = {
  __typename?: 'TaskStatusDump';
  taskId: string;
  taskName: string;
  assigneeNames: Array<string> | null;
  datasetName: string | null;
  status: GQTaskWatershedProcessStateSimplified;
};

export type GQPortcoFootprintReviewRequestMetadataAllFieldsFragment = {
  __typename?: 'PortcoFootprintReviewRequestMetadata';
  id: string;
  footprintVersionId: string;
  footprintSnapshotId: string | null;
  footprintVersionKind: string | null;
  status: GQWatershedFootprintReviewRequestStatus | null;
};

export type GQFinanceMeasurementProgressRowAllFieldsFragment = {
  __typename?: 'FinanceMeasurementProgressRow';
  assetId: string;
  assetName: string;
  companyId: string;
  projectName: string | null;
  hasRequestedProgress: boolean | null;
  hasRequestedFootprint: boolean | null;
  coverageStartDate: Date | null;
  coverageEndDate: Date | null;
  updatedAt: Date | null;
  fundIds: Array<string>;
  hasApprovedProgressRequest: boolean | null;
  contacts: Array<{
    __typename?: 'SupplierContact';
    id: string;
    name: string;
    email: string;
    role: string | null;
    userId: string | null;
    companyId: string;
  }> | null;
  taskStatusDumps: Array<{
    __typename?: 'TaskStatusDump';
    taskId: string;
    taskName: string;
    assigneeNames: Array<string> | null;
    datasetName: string | null;
    status: GQTaskWatershedProcessStateSimplified;
  }> | null;
  portcoFootprintReviewRequestMetadata: Array<{
    __typename?: 'PortcoFootprintReviewRequestMetadata';
    id: string;
    footprintVersionId: string;
    footprintSnapshotId: string | null;
    footprintVersionKind: string | null;
    status: GQWatershedFootprintReviewRequestStatus | null;
  }> | null;
  measuredFootprintDetails: {
    __typename?: 'MeasuredFootprintDetails';
    footprintSharedAt: Date;
    emissionsTco2e: number;
  } | null;
};

export type GQFinanceMeasurementProgressTabQueryVariables = Exact<{
  year: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
}>;

export type GQFinanceMeasurementProgressTabQuery = {
  __typename?: 'Query';
  financeMeasurementProgressRows: Array<{
    __typename?: 'FinanceMeasurementProgressRow';
    assetId: string;
    assetName: string;
    companyId: string;
    projectName: string | null;
    hasRequestedProgress: boolean | null;
    hasRequestedFootprint: boolean | null;
    coverageStartDate: Date | null;
    coverageEndDate: Date | null;
    updatedAt: Date | null;
    fundIds: Array<string>;
    hasApprovedProgressRequest: boolean | null;
    contacts: Array<{
      __typename?: 'SupplierContact';
      id: string;
      name: string;
      email: string;
      role: string | null;
      userId: string | null;
      companyId: string;
    }> | null;
    taskStatusDumps: Array<{
      __typename?: 'TaskStatusDump';
      taskId: string;
      taskName: string;
      assigneeNames: Array<string> | null;
      datasetName: string | null;
      status: GQTaskWatershedProcessStateSimplified;
    }> | null;
    portcoFootprintReviewRequestMetadata: Array<{
      __typename?: 'PortcoFootprintReviewRequestMetadata';
      id: string;
      footprintVersionId: string;
      footprintSnapshotId: string | null;
      footprintVersionKind: string | null;
      status: GQWatershedFootprintReviewRequestStatus | null;
    }> | null;
    measuredFootprintDetails: {
      __typename?: 'MeasuredFootprintDetails';
      footprintSharedAt: Date;
      emissionsTco2e: number;
    } | null;
  }>;
  funds: Array<{ __typename?: 'Fund'; id: string; name: string }>;
};

export type GQFinanceAutomaticReminderAllFieldsFragment = {
  __typename?: 'FinanceAutomaticReminder';
  id: string;
  enabled: boolean;
  reminderConfig: any;
  reminderType: GQFinanceReminderType;
  deadline: Date;
};

export type GQFinanceAutomaticRemindersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFinanceAutomaticRemindersQuery = {
  __typename?: 'Query';
  financeAutomaticReminders: Array<{
    __typename?: 'FinanceAutomaticReminder';
    id: string;
    enabled: boolean;
    reminderConfig: any;
    reminderType: GQFinanceReminderType;
    deadline: Date;
  }>;
};

export type GQUpdateFinanceAutomaticRemindersMutationVariables = Exact<{
  input: GQUpdateFinanceAutomaticRemindersInput;
}>;

export type GQUpdateFinanceAutomaticRemindersMutation = {
  __typename?: 'Mutation';
  updateFinanceAutomaticReminders: {
    __typename?: 'UpdateFinanceAutomaticRemindersPayload';
    financeAutomaticReminders: Array<{
      __typename?: 'FinanceAutomaticReminder';
      id: string;
      enabled: boolean;
      reminderConfig: any;
      reminderType: GQFinanceReminderType;
      deadline: Date;
    }>;
  };
};

export type GQUpdatePortalSettingsMutationVariables = Exact<{
  portalSettingsInput: GQUpsertCompanyPortalSettingsInput;
}>;

export type GQUpdatePortalSettingsMutation = {
  __typename?: 'Mutation';
  upsertCompanyPortalSettings: {
    __typename?: 'UpsertCompanyPortalSettingsPayload';
    companyPortalSettings: {
      __typename?: 'CompanyPortalSettings';
      id: string | null;
      portalCallout: string | null;
      sidebarContent: string | null;
      portalTitle: string;
      toggleHeaderImage: boolean | null;
    };
  } | null;
};

export type GQGetPortalSettingsQueryVariables = Exact<{
  rootCompanyId: Scalars['ID']['input'];
}>;

export type GQGetPortalSettingsQuery = {
  __typename?: 'Query';
  companyPortalSettings: {
    __typename?: 'CompanyPortalSettings';
    id: string | null;
    portalCallout: string | null;
    sidebarContent: string | null;
    portalTitle: string;
    toggleHeaderImage: boolean | null;
  };
};

export type GQAssetCorporateDataForTasksFragment = {
  __typename?: 'AssetCorporate';
  id: string;
  name: string;
  companyId: string | null;
  company: {
    __typename?: 'Company';
    id: string;
    naicsCode: string | null;
    sbtiStage: GQCompanySbtCommitmentStage;
    climateProgress: GQCompanyClimateProgress;
    sustainabilityWebsiteUrl: string | null;
    isWatershedCorporateCustomer: boolean;
    parentRelationships: Array<{
      __typename?: 'ParentRelationship';
      id: string;
      relationshipStartDate: YearMonth | null;
      relationshipEndDate: YearMonth | null;
      parent: {
        __typename?: 'Company';
        id: string;
        name: string;
        disclosuresV2: Array<{
          __typename?: 'Disclosure';
          id: string;
          company: { __typename?: 'Company'; id: string } | null;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            publishingYear: number;
            reportType: GQExternalReportType;
            publicUrl: string;
            qualityScore: GQDisclosureQualityScore | null;
            cdpVendorData: {
              __typename?: 'CdpVendorData';
              id: string;
              publishingYear: number;
              reportingYear: number;
              scope1Nonzero: boolean;
              scope2Nonzero: boolean;
              scope3Nonzero: boolean;
              totalEmissionsNonzero: boolean;
              scope301Or302Nonzero: boolean;
              scope1Verified: boolean;
              scope2Verified: boolean;
              scope3Verified: boolean;
              pctEvaluationStatusesMatchResponse: number;
              disclosureQualityScore: GQDisclosureQualityScore;
              scope301EvaluationStatus: GQScope3EvaluationStatus;
              scope302EvaluationStatus: GQScope3EvaluationStatus;
              scope303EvaluationStatus: GQScope3EvaluationStatus;
              scope304EvaluationStatus: GQScope3EvaluationStatus;
              scope305EvaluationStatus: GQScope3EvaluationStatus;
              scope306EvaluationStatus: GQScope3EvaluationStatus;
              scope307EvaluationStatus: GQScope3EvaluationStatus;
              scope308EvaluationStatus: GQScope3EvaluationStatus;
              scope309EvaluationStatus: GQScope3EvaluationStatus;
              scope310EvaluationStatus: GQScope3EvaluationStatus;
              scope311EvaluationStatus: GQScope3EvaluationStatus;
              scope312EvaluationStatus: GQScope3EvaluationStatus;
              scope313EvaluationStatus: GQScope3EvaluationStatus;
              scope314EvaluationStatus: GQScope3EvaluationStatus;
              scope315EvaluationStatus: GQScope3EvaluationStatus;
              scope316EvaluationStatus: GQScope3EvaluationStatus;
              scope317EvaluationStatus: GQScope3EvaluationStatus;
            } | null;
          } | null;
          privateDisclosure: {
            __typename?: 'PrivateDisclosure';
            id: string;
            companyId: string | null;
            orgId: string;
            orgName: string;
            surveyId: string | null;
            privateDisclosureType: GQPrivateDisclosureType;
            createdAt: Date;
            disclosureQualityScore: GQDisclosureQualityScore | null;
            disclosureQualityExplanation: string | null;
            thirdPartyVerified: boolean;
            cdpVendorData: {
              __typename?: 'CdpVendorData';
              id: string;
              publishingYear: number;
              reportingYear: number;
              scope1Nonzero: boolean;
              scope2Nonzero: boolean;
              scope3Nonzero: boolean;
              totalEmissionsNonzero: boolean;
              scope301Or302Nonzero: boolean;
              scope1Verified: boolean;
              scope2Verified: boolean;
              scope3Verified: boolean;
              pctEvaluationStatusesMatchResponse: number;
              disclosureQualityScore: GQDisclosureQualityScore;
              scope301EvaluationStatus: GQScope3EvaluationStatus;
              scope302EvaluationStatus: GQScope3EvaluationStatus;
              scope303EvaluationStatus: GQScope3EvaluationStatus;
              scope304EvaluationStatus: GQScope3EvaluationStatus;
              scope305EvaluationStatus: GQScope3EvaluationStatus;
              scope306EvaluationStatus: GQScope3EvaluationStatus;
              scope307EvaluationStatus: GQScope3EvaluationStatus;
              scope308EvaluationStatus: GQScope3EvaluationStatus;
              scope309EvaluationStatus: GQScope3EvaluationStatus;
              scope310EvaluationStatus: GQScope3EvaluationStatus;
              scope311EvaluationStatus: GQScope3EvaluationStatus;
              scope312EvaluationStatus: GQScope3EvaluationStatus;
              scope313EvaluationStatus: GQScope3EvaluationStatus;
              scope314EvaluationStatus: GQScope3EvaluationStatus;
              scope315EvaluationStatus: GQScope3EvaluationStatus;
              scope316EvaluationStatus: GQScope3EvaluationStatus;
              scope317EvaluationStatus: GQScope3EvaluationStatus;
            } | null;
          } | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            publicDisclosureId: string | null;
            reportingYear: number;
            numEmployees: number | null;
            revenue: number | null;
            revenueCurrency: string | null;
            revenueUsd: number | null;
            percentageCleanEnergy: number | null;
            totalMwh: number | null;
            units: GQCompanyEmissionsUnits;
            expenseCategory: string | null;
            scope1: number | null;
            scope2Market: number | null;
            scope2Location: number | null;
            scope3: number | null;
            scope301: number | null;
            scope302: number | null;
            scope303: number | null;
            scope304: number | null;
            scope305: number | null;
            scope306: number | null;
            scope307: number | null;
            scope308: number | null;
            scope309: number | null;
            scope310: number | null;
            scope311: number | null;
            scope312: number | null;
            scope313: number | null;
            scope314: number | null;
            scope315: number | null;
            scope316: number | null;
            scope317: number | null;
          }> | null;
          targets: Array<{
            __typename?: 'DisclosureTargetDetails';
            id: string;
            name: string;
            description: string;
            baseYear: YearMonth;
            emissionsType: GQDisclosureTargetEmissionsType;
            reductionRate: GQDisclosureTargetReductionRate;
            intensityType: GQDisclosureTargetIntensityType;
            unit: string | null;
            unitDescription: string | null;
            filters: {
              __typename?: 'FilterExpressionGroup';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitive';
                field: GQFilterFieldLegacy;
                operator: GQFilterOperator;
                value: Array<string>;
              }>;
            };
            emissionsTarget: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
          }> | null;
          initiatives: Array<{
            __typename?: 'DisclosureInitiative';
            id: string;
            name: string | null;
            initiativeType: GQDisclosureInitiativeType;
            description: string | null;
            startYearMonth: YearMonth | null;
            endYearMonth: YearMonth | null;
            intensityType: GQDisclosureTargetIntensityType | null;
            emissionsTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            } | null;
            filters: {
              __typename?: 'FilterExpressionGroup';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitive';
                field: GQFilterFieldLegacy;
                operator: GQFilterOperator;
                value: Array<string>;
              }>;
            } | null;
          }> | null;
          climateCommitments: Array<
            | {
                __typename?: 'CarbonNeutralCommitment';
                targetYear: number | null;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename?: 'CleanEnergyCommitment';
                targetYear: number | null;
                targetPercentageCleanEnergy: number | null;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename?: 'NetZeroCommitment';
                targetYear: number | null;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename?: 'ScienceBasedTargetCommitment';
                submittedToSBTi: boolean;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
                commitment: {
                  __typename?: 'SBTCommitment';
                  id: string;
                  stage: GQSbtCommitmentStage | null;
                  targetClassification: GQSbtTargetClassification | null;
                  nearTermTargetYear: number | null;
                  longTermTargetYear: number | null;
                  baselineYear: number | null;
                  netZeroCommitted: boolean | null;
                  commitmentType: GQSbtCommitmentType | null;
                  commitmentDeadline: Date | null;
                  netZeroTargetYear: number | null;
                };
              }
          > | null;
        }>;
      };
    }>;
    parentRelationshipsForSidebar: Array<{
      __typename?: 'ParentRelationship';
      id: string;
      relationshipStartDate: YearMonth | null;
      relationshipEndDate: YearMonth | null;
      parent: { __typename?: 'Company'; id: string; name: string };
    }>;
    contacts: Array<{
      __typename?: 'SupplierContact';
      id: string;
      name: string;
      email: string;
      role: string | null;
      userId: string | null;
      companyId: string;
    }>;
  } | null;
  engagementTasks: Array<{
    __typename?: 'EngagementTask';
    id: string;
    crossOrgModelId: string;
    reportingYear: number | null;
    facilityName: string | null;
    submittedAt: Date | null;
    createdAt: Date;
    dueDate: Date | null;
    status: GQCompanySurveyStatus;
    surveyKind: GQCompanySurveyKind;
    supplierFacingDescription: string | null;
    watershedGeneric: boolean | null;
    currentAssignee: GQCompanySurveyAssignee;
    rootAssignee: string | null;
    supplierAssignee: string | null;
    initialSupplierAssigneeEmail: string | null;
    approvedAt: Date | null;
    statusChangedAt: Date | null;
    rootOrgId: string;
    supplierOrgId: string;
    latestCeeWorkflowId: string | null;
    latestPleeWorkflowId: string | null;
    latestFootprintDisclosureWorkflowId: string | null;
    engagementTaskBatchId: string | null;
    proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
    engagementTaskConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      name: string;
      sidebarTitle: string;
      defaultSupplierFacingDescription: string | null;
      rootFacingDescription: string | null;
      orgId: string | null;
      type: GQEngagementTaskConfigType | null;
      configHasTaskAssignedToCompany: boolean;
      timeNeeded: number | null;
      category: GQSurveyDefinitionCategory;
      engagementTaskInputs: Array<GQEngagementTaskInput>;
      optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          answerIsPrivate: boolean;
          videoLink: string | null;
        }>;
      }>;
      baseConfig: {
        __typename?: 'EngagementTaskConfig';
        id: string;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
      } | null;
    };
    rootCompany: {
      __typename?: 'Company';
      id: string;
      name: string;
      fullLogoUrl: string | null;
      url: string | null;
    };
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      naicsCode: string | null;
      sbtiStage: GQCompanySbtCommitmentStage;
      publicDisclosures: Array<{
        __typename?: 'Disclosure';
        id: string;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          publishingYear: number;
        } | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          publicDisclosureId: string | null;
          reportingYear: number;
          numEmployees: number | null;
          revenue: number | null;
          revenueCurrency: string | null;
          revenueUsd: number | null;
          percentageCleanEnergy: number | null;
          totalMwh: number | null;
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
        }> | null;
      }>;
    };
    expenseCategories: Array<{
      __typename?: 'DiscloseEmissionsExpenseCategories';
      beaCode: string;
      inputUnit: string;
      inputQuantity: number;
      kgco2e: number;
    }> | null;
    proposeTargetFilters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    } | null;
    proposeTargetEmissionsTarget: {
      __typename?: 'SimpleTimeseries';
      id: string | null;
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    } | null;
  }>;
};

export type GQFinanceAssetsForDataTasksQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFinanceAssetsForDataTasksQuery = {
  __typename?: 'Query';
  assetsCorporate: Array<{
    __typename?: 'AssetCorporate';
    id: string;
    name: string;
    companyId: string | null;
    company: {
      __typename?: 'Company';
      id: string;
      naicsCode: string | null;
      sbtiStage: GQCompanySbtCommitmentStage;
      climateProgress: GQCompanyClimateProgress;
      sustainabilityWebsiteUrl: string | null;
      isWatershedCorporateCustomer: boolean;
      parentRelationships: Array<{
        __typename?: 'ParentRelationship';
        id: string;
        relationshipStartDate: YearMonth | null;
        relationshipEndDate: YearMonth | null;
        parent: {
          __typename?: 'Company';
          id: string;
          name: string;
          disclosuresV2: Array<{
            __typename?: 'Disclosure';
            id: string;
            company: { __typename?: 'Company'; id: string } | null;
            publicDisclosure: {
              __typename?: 'PublicDisclosure';
              id: string;
              publishingYear: number;
              reportType: GQExternalReportType;
              publicUrl: string;
              qualityScore: GQDisclosureQualityScore | null;
              cdpVendorData: {
                __typename?: 'CdpVendorData';
                id: string;
                publishingYear: number;
                reportingYear: number;
                scope1Nonzero: boolean;
                scope2Nonzero: boolean;
                scope3Nonzero: boolean;
                totalEmissionsNonzero: boolean;
                scope301Or302Nonzero: boolean;
                scope1Verified: boolean;
                scope2Verified: boolean;
                scope3Verified: boolean;
                pctEvaluationStatusesMatchResponse: number;
                disclosureQualityScore: GQDisclosureQualityScore;
                scope301EvaluationStatus: GQScope3EvaluationStatus;
                scope302EvaluationStatus: GQScope3EvaluationStatus;
                scope303EvaluationStatus: GQScope3EvaluationStatus;
                scope304EvaluationStatus: GQScope3EvaluationStatus;
                scope305EvaluationStatus: GQScope3EvaluationStatus;
                scope306EvaluationStatus: GQScope3EvaluationStatus;
                scope307EvaluationStatus: GQScope3EvaluationStatus;
                scope308EvaluationStatus: GQScope3EvaluationStatus;
                scope309EvaluationStatus: GQScope3EvaluationStatus;
                scope310EvaluationStatus: GQScope3EvaluationStatus;
                scope311EvaluationStatus: GQScope3EvaluationStatus;
                scope312EvaluationStatus: GQScope3EvaluationStatus;
                scope313EvaluationStatus: GQScope3EvaluationStatus;
                scope314EvaluationStatus: GQScope3EvaluationStatus;
                scope315EvaluationStatus: GQScope3EvaluationStatus;
                scope316EvaluationStatus: GQScope3EvaluationStatus;
                scope317EvaluationStatus: GQScope3EvaluationStatus;
              } | null;
            } | null;
            privateDisclosure: {
              __typename?: 'PrivateDisclosure';
              id: string;
              companyId: string | null;
              orgId: string;
              orgName: string;
              surveyId: string | null;
              privateDisclosureType: GQPrivateDisclosureType;
              createdAt: Date;
              disclosureQualityScore: GQDisclosureQualityScore | null;
              disclosureQualityExplanation: string | null;
              thirdPartyVerified: boolean;
              cdpVendorData: {
                __typename?: 'CdpVendorData';
                id: string;
                publishingYear: number;
                reportingYear: number;
                scope1Nonzero: boolean;
                scope2Nonzero: boolean;
                scope3Nonzero: boolean;
                totalEmissionsNonzero: boolean;
                scope301Or302Nonzero: boolean;
                scope1Verified: boolean;
                scope2Verified: boolean;
                scope3Verified: boolean;
                pctEvaluationStatusesMatchResponse: number;
                disclosureQualityScore: GQDisclosureQualityScore;
                scope301EvaluationStatus: GQScope3EvaluationStatus;
                scope302EvaluationStatus: GQScope3EvaluationStatus;
                scope303EvaluationStatus: GQScope3EvaluationStatus;
                scope304EvaluationStatus: GQScope3EvaluationStatus;
                scope305EvaluationStatus: GQScope3EvaluationStatus;
                scope306EvaluationStatus: GQScope3EvaluationStatus;
                scope307EvaluationStatus: GQScope3EvaluationStatus;
                scope308EvaluationStatus: GQScope3EvaluationStatus;
                scope309EvaluationStatus: GQScope3EvaluationStatus;
                scope310EvaluationStatus: GQScope3EvaluationStatus;
                scope311EvaluationStatus: GQScope3EvaluationStatus;
                scope312EvaluationStatus: GQScope3EvaluationStatus;
                scope313EvaluationStatus: GQScope3EvaluationStatus;
                scope314EvaluationStatus: GQScope3EvaluationStatus;
                scope315EvaluationStatus: GQScope3EvaluationStatus;
                scope316EvaluationStatus: GQScope3EvaluationStatus;
                scope317EvaluationStatus: GQScope3EvaluationStatus;
              } | null;
            } | null;
            historicalEmissionsYears: Array<{
              __typename?: 'EmissionsYear';
              id: string;
              publicDisclosureId: string | null;
              reportingYear: number;
              numEmployees: number | null;
              revenue: number | null;
              revenueCurrency: string | null;
              revenueUsd: number | null;
              percentageCleanEnergy: number | null;
              totalMwh: number | null;
              units: GQCompanyEmissionsUnits;
              expenseCategory: string | null;
              scope1: number | null;
              scope2Market: number | null;
              scope2Location: number | null;
              scope3: number | null;
              scope301: number | null;
              scope302: number | null;
              scope303: number | null;
              scope304: number | null;
              scope305: number | null;
              scope306: number | null;
              scope307: number | null;
              scope308: number | null;
              scope309: number | null;
              scope310: number | null;
              scope311: number | null;
              scope312: number | null;
              scope313: number | null;
              scope314: number | null;
              scope315: number | null;
              scope316: number | null;
              scope317: number | null;
            }> | null;
            targets: Array<{
              __typename?: 'DisclosureTargetDetails';
              id: string;
              name: string;
              description: string;
              baseYear: YearMonth;
              emissionsType: GQDisclosureTargetEmissionsType;
              reductionRate: GQDisclosureTargetReductionRate;
              intensityType: GQDisclosureTargetIntensityType;
              unit: string | null;
              unitDescription: string | null;
              filters: {
                __typename?: 'FilterExpressionGroup';
                conjunction: GQFilterConjunction;
                expressions: Array<{
                  __typename?: 'FilterExpressionPrimitive';
                  field: GQFilterFieldLegacy;
                  operator: GQFilterOperator;
                  value: Array<string>;
                }>;
              };
              emissionsTarget: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              };
            }> | null;
            initiatives: Array<{
              __typename?: 'DisclosureInitiative';
              id: string;
              name: string | null;
              initiativeType: GQDisclosureInitiativeType;
              description: string | null;
              startYearMonth: YearMonth | null;
              endYearMonth: YearMonth | null;
              intensityType: GQDisclosureTargetIntensityType | null;
              emissionsTimeseries: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              } | null;
              filters: {
                __typename?: 'FilterExpressionGroup';
                conjunction: GQFilterConjunction;
                expressions: Array<{
                  __typename?: 'FilterExpressionPrimitive';
                  field: GQFilterFieldLegacy;
                  operator: GQFilterOperator;
                  value: Array<string>;
                }>;
              } | null;
            }> | null;
            climateCommitments: Array<
              | {
                  __typename?: 'CarbonNeutralCommitment';
                  targetYear: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'CleanEnergyCommitment';
                  targetYear: number | null;
                  targetPercentageCleanEnergy: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'NetZeroCommitment';
                  targetYear: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'ScienceBasedTargetCommitment';
                  submittedToSBTi: boolean;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                  commitment: {
                    __typename?: 'SBTCommitment';
                    id: string;
                    stage: GQSbtCommitmentStage | null;
                    targetClassification: GQSbtTargetClassification | null;
                    nearTermTargetYear: number | null;
                    longTermTargetYear: number | null;
                    baselineYear: number | null;
                    netZeroCommitted: boolean | null;
                    commitmentType: GQSbtCommitmentType | null;
                    commitmentDeadline: Date | null;
                    netZeroTargetYear: number | null;
                  };
                }
            > | null;
          }>;
        };
      }>;
      parentRelationshipsForSidebar: Array<{
        __typename?: 'ParentRelationship';
        id: string;
        relationshipStartDate: YearMonth | null;
        relationshipEndDate: YearMonth | null;
        parent: { __typename?: 'Company'; id: string; name: string };
      }>;
      contacts: Array<{
        __typename?: 'SupplierContact';
        id: string;
        name: string;
        email: string;
        role: string | null;
        userId: string | null;
        companyId: string;
      }>;
    } | null;
    engagementTasks: Array<{
      __typename?: 'EngagementTask';
      id: string;
      crossOrgModelId: string;
      reportingYear: number | null;
      facilityName: string | null;
      submittedAt: Date | null;
      createdAt: Date;
      dueDate: Date | null;
      status: GQCompanySurveyStatus;
      surveyKind: GQCompanySurveyKind;
      supplierFacingDescription: string | null;
      watershedGeneric: boolean | null;
      currentAssignee: GQCompanySurveyAssignee;
      rootAssignee: string | null;
      supplierAssignee: string | null;
      initialSupplierAssigneeEmail: string | null;
      approvedAt: Date | null;
      statusChangedAt: Date | null;
      rootOrgId: string;
      supplierOrgId: string;
      latestCeeWorkflowId: string | null;
      latestPleeWorkflowId: string | null;
      latestFootprintDisclosureWorkflowId: string | null;
      engagementTaskBatchId: string | null;
      proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
      engagementTaskConfig: {
        __typename?: 'EngagementTaskConfig';
        id: string;
        name: string;
        sidebarTitle: string;
        defaultSupplierFacingDescription: string | null;
        rootFacingDescription: string | null;
        orgId: string | null;
        type: GQEngagementTaskConfigType | null;
        configHasTaskAssignedToCompany: boolean;
        timeNeeded: number | null;
        category: GQSurveyDefinitionCategory;
        engagementTaskInputs: Array<GQEngagementTaskInput>;
        optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
        baseConfig: {
          __typename?: 'EngagementTaskConfig';
          id: string;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            containers: Array<{
              __typename?: 'EngagementTaskContainer';
              id: string;
              label: string;
              description: string | null;
              componentId: GQEngagementTaskContainerComponentId | null;
              editable: boolean;
              statusJson: any;
              sidebarStepLabelOverride: string | null;
              eyebrowText: string | null;
              headerImage: GQEngagementTaskImageId | null;
              questions: Array<{
                __typename?: 'EngagementTaskQuestion';
                id: string;
                label: string | null;
                description: string | null;
                componentId: GQEngagementTaskQuestionComponentId;
                editable: boolean;
                deletable: boolean;
                questionKey: string;
                statusJson: any;
                whyCollectThis: string | null;
                answerIsPrivate: boolean;
                videoLink: string | null;
              }>;
            }>;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
        } | null;
      };
      rootCompany: {
        __typename?: 'Company';
        id: string;
        name: string;
        fullLogoUrl: string | null;
        url: string | null;
      };
      company: {
        __typename?: 'Company';
        id: string;
        name: string;
        naicsCode: string | null;
        sbtiStage: GQCompanySbtCommitmentStage;
        publicDisclosures: Array<{
          __typename?: 'Disclosure';
          id: string;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            publishingYear: number;
          } | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            publicDisclosureId: string | null;
            reportingYear: number;
            numEmployees: number | null;
            revenue: number | null;
            revenueCurrency: string | null;
            revenueUsd: number | null;
            percentageCleanEnergy: number | null;
            totalMwh: number | null;
            scope1: number | null;
            scope2Market: number | null;
            scope2Location: number | null;
            scope3: number | null;
            scope301: number | null;
            scope302: number | null;
            scope303: number | null;
            scope304: number | null;
            scope305: number | null;
            scope306: number | null;
            scope307: number | null;
            scope308: number | null;
            scope309: number | null;
            scope310: number | null;
            scope311: number | null;
            scope312: number | null;
            scope313: number | null;
            scope314: number | null;
            scope315: number | null;
            scope316: number | null;
            scope317: number | null;
          }> | null;
        }>;
      };
      expenseCategories: Array<{
        __typename?: 'DiscloseEmissionsExpenseCategories';
        beaCode: string;
        inputUnit: string;
        inputQuantity: number;
        kgco2e: number;
      }> | null;
      proposeTargetFilters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
      proposeTargetEmissionsTarget: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      } | null;
    }>;
  }>;
};

export type GQAssetCorporateForMatchingFragment = {
  __typename?: 'AssetCorporate';
  id: string;
  name: string;
  company: {
    __typename?: 'Company';
    id: string;
    alternateNames: Array<string> | null;
    betterCompanyId: string | null;
    country: string | null;
    createdAt: Date;
    deletedAt: Date | null;
    description: string | null;
    isDemo: boolean;
    logoUrl: string | null;
    naicsCode: string | null;
    naicsCodeSource: string | null;
    name: string;
    sfdcOpportunityId: string | null;
    sfdcStage: string | null;
    source: string;
    sustainabilityWebsiteUrl: string | null;
    updatedAt: Date;
    url: string | null;
    userEditorId: string | null;
    logoUrlId: string | null;
    sustainabilityWebsiteUrlId: string | null;
    companyWebsiteUrlId: string | null;
    companyIngestionRecordUpdateId: string | null;
    sourceId: string;
  } | null;
};

export type GQFinanceCompanyMatchingTabQueryVariables = Exact<{
  search: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQFinanceCompanyMatchingTabQuery = {
  __typename?: 'Query';
  assetsCorporatePaginated: {
    __typename?: 'AssetsCorporateConnection';
    edges: Array<{
      __typename?: 'AssetsCorporateEdge';
      node: {
        __typename?: 'AssetCorporate';
        id: string;
        name: string;
        company: {
          __typename?: 'Company';
          id: string;
          alternateNames: Array<string> | null;
          betterCompanyId: string | null;
          country: string | null;
          createdAt: Date;
          deletedAt: Date | null;
          description: string | null;
          isDemo: boolean;
          logoUrl: string | null;
          naicsCode: string | null;
          naicsCodeSource: string | null;
          name: string;
          sfdcOpportunityId: string | null;
          sfdcStage: string | null;
          source: string;
          sustainabilityWebsiteUrl: string | null;
          updatedAt: Date;
          url: string | null;
          userEditorId: string | null;
          logoUrlId: string | null;
          sustainabilityWebsiteUrlId: string | null;
          companyWebsiteUrlId: string | null;
          companyIngestionRecordUpdateId: string | null;
          sourceId: string;
        } | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type GQUpdateAssetYearsMutationVariables = Exact<{
  input: GQUpdateAssetYearsNewInput;
}>;

export type GQUpdateAssetYearsMutation = {
  __typename?: 'Mutation';
  updateAssetYears: {
    __typename?: 'UpdateAssetYearsNewPayload';
    assetYears: Array<{
      __typename: 'FinanceAssetYearNew';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      year: number;
      assetCommercialRealEstateId: string | null;
      assetCorporateId: string | null;
      assetGroupId: string | null;
      assetSovereignBondId: string | null;
      assetPersonalMotorVehicleInsuranceId: string | null;
      buildingSizeNative: number | null;
      estimationMethodology: GQEstimationMethodology | null;
      headcount: number | null;
      percentRenewable: number | null;
      cloudSpendNative: number | null;
      valueNative: number | null;
      revenueNative: number | null;
      flightMiles: number | null;
      percentEmployeesWfh: number | null;
      currencyCode: GQCurrencyCode;
      currencyConversionRate: number;
      totalMwh: number | null;
      revenueSourceEmissionsYearId: string | null;
      emissionsYearId: string | null;
      scope1OverrideTco2e: number | null;
      scope1OverridePcaf: number | null;
      scope2LocationOverrideTco2e: number | null;
      scope2LocationOverridePcaf: number | null;
      scope2MarketOverrideTco2e: number | null;
      scope2MarketOverridePcaf: number | null;
      scope3OverrideKind: GQScope3OverrideKind | null;
      scope3OverrideTco2e: number | null;
      scope3OverridePcaf: number | null;
      scope301OverrideTco2e: number | null;
      scope302OverrideTco2e: number | null;
      scope303OverrideTco2e: number | null;
      scope304OverrideTco2e: number | null;
      scope305OverrideTco2e: number | null;
      scope306OverrideTco2e: number | null;
      scope307OverrideTco2e: number | null;
      scope308OverrideTco2e: number | null;
      scope309OverrideTco2e: number | null;
      scope310OverrideTco2e: number | null;
      scope311OverrideTco2e: number | null;
      scope312OverrideTco2e: number | null;
      scope313OverrideTco2e: number | null;
      scope314OverrideTco2e: number | null;
      scope315OverrideTco2e: number | null;
      buildingEstimationErr: string | null;
      companyEstimationErr: string | null;
      measuringScope1And2Emissions: boolean | null;
      measuringMaterialScope3Emissions: boolean | null;
      initiatedDecarbonizationPlan: boolean | null;
      committedToNearTermSbtAlignedNetZero: boolean | null;
      demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
      iciAlignmentOverride: GQIciAlignmentStatus | null;
      scenario: string | null;
      tagData: any | null;
    }>;
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQFinanceDataFlagForFlagViewFragment = {
  __typename?: 'FinanceDataFlag';
  id: string;
  finSnapAssetId: string | null;
  finSnapYearId: string | null;
  finSnapFundId: string | null;
  finSnapHoldingId: string | null;
  assetYearId: string | null;
  rowData: any;
  checkKind: GQFinanceDataFlagCheck;
  objectKind: GQFinanceDataFlagObjectKind;
  category: GQFinanceDataFlagCategory;
  title: string;
  description: string;
  stepsToResolve: string;
  columns: Array<string>;
};

export type GQFinanceDataFlagsQueryVariables = Exact<{
  financeSnapshotId: InputMaybe<Scalars['ID']['input']>;
  fundId: InputMaybe<Scalars['ID']['input']>;
  assetId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQFinanceDataFlagsQuery = {
  __typename?: 'Query';
  financeDataFlags: Array<{
    __typename?: 'FinanceDataFlag';
    id: string;
    finSnapAssetId: string | null;
    finSnapYearId: string | null;
    finSnapFundId: string | null;
    finSnapHoldingId: string | null;
    assetYearId: string | null;
    rowData: any;
    checkKind: GQFinanceDataFlagCheck;
    objectKind: GQFinanceDataFlagObjectKind;
    category: GQFinanceDataFlagCategory;
    title: string;
    description: string;
    stepsToResolve: string;
    columns: Array<string>;
  }>;
};

export type GQGetFinanceImportDiffQueryVariables = Exact<{
  financeImportId: Scalars['ID']['input'];
}>;

export type GQGetFinanceImportDiffQuery = {
  __typename?: 'Query';
  financeImport: { __typename?: 'FinanceImport'; id: string; diff: any | null };
};

export type GQAcceptFinanceImportDiffMutationVariables = Exact<{
  input: GQAcceptFinanceImportDiffInput;
}>;

export type GQAcceptFinanceImportDiffMutation = {
  __typename?: 'Mutation';
  acceptFinanceImportDiff: {
    __typename?: 'AcceptFinanceImportDiffPayload';
    financeImport: {
      __typename?: 'FinanceImport';
      id: string;
      accepted: boolean;
    };
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  };
};

export type GQCreateFinanceImportMutationVariables = Exact<{
  input: GQCreateFinanceImportInput;
}>;

export type GQCreateFinanceImportMutation = {
  __typename?: 'Mutation';
  createFinanceImport: {
    __typename?: 'CreateFinanceImportPayload';
    backgroundJobId: string;
    financeImport: { __typename?: 'FinanceImport'; id: string };
  };
};

export type GQCreateFinanceUploadTemplateMutationVariables = Exact<{
  input: GQCreateFinanceUploadTemplateInput;
}>;

export type GQCreateFinanceUploadTemplateMutation = {
  __typename?: 'Mutation';
  createFinanceUploadTemplate: {
    __typename?: 'CreateFinanceUploadTemplatePayload';
    oneSchemaTemplateKey: string;
  };
};

export type GQDeleteAssetYearsMutationVariables = Exact<{
  input: GQDeleteAssetYearsInput;
}>;

export type GQDeleteAssetYearsMutation = {
  __typename?: 'Mutation';
  deleteAssetYears: {
    __typename?: 'DeleteAssetYearsPayload';
    assetYearIds: Array<string>;
    assetHoldingIds: Array<string>;
    privateDisclosureIds: Array<string>;
    emissionsYearIds: Array<string>;
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  };
};

export type GQDeleteAssetHoldingsMutationVariables = Exact<{
  input: GQDeleteAssetHoldingsInput;
}>;

export type GQDeleteAssetHoldingsMutation = {
  __typename?: 'Mutation';
  deleteAssetHoldings: {
    __typename?: 'DeleteAssetHoldingsPayload';
    ids: Array<string>;
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQFinanceModelCardMetadataQueryVariables = Exact<{
  year: Scalars['Int']['input'];
}>;

export type GQFinanceModelCardMetadataQuery = {
  __typename?: 'Query';
  financeModelCardMetadata: {
    __typename?: 'FinanceModelCardMetadataPayload';
    id: string;
    numFunds: number;
    numAssets: number;
    numAnnualData: number;
    numHoldings: number;
    numContacts: number;
  };
};

export type GQFinanceImportsForRenderFragment = {
  __typename?: 'FinanceImport';
  id: string;
  createdAt: Date;
  errorMessages: any;
  dataFormat: string | null;
  recordsCreated: number | null;
  recordsUpdated: number | null;
  accepted: boolean;
  fileMetadata: {
    __typename?: 'FileMetadata';
    id: string;
    name: string;
    uploader:
      | { __typename?: 'User'; id: string; displayName: string }
      | { __typename?: 'WatershedEmployee'; id: string; displayName: string }
      | null;
  } | null;
};

export type GQFinanceDataPreviousImportsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFinanceDataPreviousImportsQuery = {
  __typename?: 'Query';
  financeImports: Array<{
    __typename?: 'FinanceImport';
    id: string;
    createdAt: Date;
    errorMessages: any;
    dataFormat: string | null;
    recordsCreated: number | null;
    recordsUpdated: number | null;
    accepted: boolean;
    fileMetadata: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
      uploader:
        | { __typename?: 'User'; id: string; displayName: string }
        | { __typename?: 'WatershedEmployee'; id: string; displayName: string }
        | null;
    } | null;
  }>;
};

export type GQCreateCorporateSandboxEstimationMutationVariables = Exact<{
  input: GQCreateCorporateSandboxEstimationInput;
}>;

export type GQCreateCorporateSandboxEstimationMutation = {
  __typename?: 'Mutation';
  createCorporateSandboxEstimation: {
    __typename?: 'CreateCorporateSandboxEstimationPayload';
    backgroundJobId: string;
  } | null;
};

export type GQFinanceSandboxCorporateMetricsViewQueryVariables = Exact<{
  emissionsYearId: Scalars['ID']['input'];
}>;

export type GQFinanceSandboxCorporateMetricsViewQuery = {
  __typename?: 'Query';
  emissionsYear: {
    __typename?: 'EmissionsYear';
    priorEstimateInputs: any | null;
    id: string;
    createdAt: Date;
    publicDisclosureId: string | null;
    privateDisclosureId: string | null;
    footprintEstimateOutputId: string | null;
    buildingEstimateOutputId: string | null;
    assetCorporateId: string | null;
    expenseCategory: string | null;
    reportingYear: number;
    numEmployees: number | null;
    revenue: number | null;
    revenueCurrency: string | null;
    currencyConversionRate: number | null;
    scope1: number | null;
    scope2: number | null;
    scope2Market: number | null;
    scope2Location: number | null;
    scope3: number | null;
    scope301: number | null;
    scope302: number | null;
    scope303: number | null;
    scope304: number | null;
    scope305: number | null;
    scope306: number | null;
    scope307: number | null;
    scope308: number | null;
    scope309: number | null;
    scope310: number | null;
    scope311: number | null;
    scope312: number | null;
    scope313: number | null;
    scope314: number | null;
    scope315: number | null;
    scope316: number | null;
    scope317: number | null;
    units: GQCompanyEmissionsUnits;
    percentageCleanEnergy: number | null;
    totalMwh: number | null;
    orgId: string | null;
    scenario: string | null;
    updatedAt: Date;
    pcafScoreScope1: number | null;
    emissionsByGhgCategory: {
      __typename?: 'EmissionsByGhgCategoryForFinance';
      fullIntervalGross: number | null;
      topGhgCategories: Array<{
        __typename?: 'GhgCategorizedEmissionData';
        ghgCategoryId: string;
        businessSubcategory: string | null;
        proportion: number;
        amountKgco2e: number;
      }>;
      topGhgCategoriesWithSubcategories: Array<{
        __typename?: 'GhgCategorizedEmissionData';
        ghgCategoryId: string;
        businessSubcategory: string | null;
        proportion: number;
        amountKgco2e: number;
      }>;
    } | null;
  };
};

export type GQCreateRealEstateSandboxEstimationMutationVariables = Exact<{
  input: GQCreateRealEstateSandboxEstimationInput;
}>;

export type GQCreateRealEstateSandboxEstimationMutation = {
  __typename?: 'Mutation';
  createRealEstateSandboxEstimation: {
    __typename?: 'CreateRealEstateSandboxEstimationPayload';
    backgroundJobId: string;
  } | null;
};

export type GQFinanceSandboxRealEstateMetricsViewQueryVariables = Exact<{
  emissionsYearId: Scalars['ID']['input'];
}>;

export type GQFinanceSandboxRealEstateMetricsViewQuery = {
  __typename?: 'Query';
  emissionsYear: {
    __typename?: 'EmissionsYear';
    id: string;
    createdAt: Date;
    publicDisclosureId: string | null;
    privateDisclosureId: string | null;
    footprintEstimateOutputId: string | null;
    buildingEstimateOutputId: string | null;
    assetCorporateId: string | null;
    expenseCategory: string | null;
    reportingYear: number;
    numEmployees: number | null;
    revenue: number | null;
    revenueCurrency: string | null;
    currencyConversionRate: number | null;
    scope1: number | null;
    scope2: number | null;
    scope2Market: number | null;
    scope2Location: number | null;
    scope3: number | null;
    scope301: number | null;
    scope302: number | null;
    scope303: number | null;
    scope304: number | null;
    scope305: number | null;
    scope306: number | null;
    scope307: number | null;
    scope308: number | null;
    scope309: number | null;
    scope310: number | null;
    scope311: number | null;
    scope312: number | null;
    scope313: number | null;
    scope314: number | null;
    scope315: number | null;
    scope316: number | null;
    scope317: number | null;
    units: GQCompanyEmissionsUnits;
    percentageCleanEnergy: number | null;
    totalMwh: number | null;
    orgId: string | null;
    scenario: string | null;
    updatedAt: Date;
    pcafScoreScope1: number | null;
  };
};

export type GQFinanceSandboxHistoryFieldsFragment = {
  __typename?: 'EmissionsYear';
  id: string;
  createdAt: Date;
  scope1: number | null;
  scope2Location: number | null;
  scope2Market: number | null;
  scope3: number | null;
  footprintEstimateOutputId: string | null;
  buildingEstimateOutputId: string | null;
  reportingYear: number;
  revenue: number | null;
  revenueCurrency: string | null;
  numEmployees: number | null;
  priorEstimateInputs: any | null;
};

export type GQGetFinanceSandboxHistoryQueryVariables = Exact<{
  before: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQGetFinanceSandboxHistoryQuery = {
  __typename?: 'Query';
  sandboxEmissionsYears: {
    __typename?: 'EmissionsYearConnection';
    edges: Array<{
      __typename?: 'EmissionsYearEdge';
      node: {
        __typename?: 'EmissionsYear';
        id: string;
        createdAt: Date;
        scope1: number | null;
        scope2Location: number | null;
        scope2Market: number | null;
        scope3: number | null;
        footprintEstimateOutputId: string | null;
        buildingEstimateOutputId: string | null;
        reportingYear: number;
        revenue: number | null;
        revenueCurrency: string | null;
        numEmployees: number | null;
        priorEstimateInputs: any | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
  };
};

export type GQFinanceSettingsFieldsFragment = {
  __typename?: 'FinanceSettings';
  id: string;
  estimateDownstreamScope3: boolean;
  disabledFinanceDataFlags: Array<string>;
};

export type GQFinanceSettingsTabQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFinanceSettingsTabQuery = {
  __typename?: 'Query';
  financeSettings: {
    __typename?: 'FinanceSettings';
    id: string;
    estimateDownstreamScope3: boolean;
    disabledFinanceDataFlags: Array<string>;
  };
};

export type GQUpdateFinanceSettingsMutationVariables = Exact<{
  input: GQUpdateFinanceSettingsInput;
}>;

export type GQUpdateFinanceSettingsMutation = {
  __typename?: 'Mutation';
  updateFinanceSettings: {
    __typename?: 'UpdateFinanceSettingsPayload';
    financeSettings: {
      __typename?: 'FinanceSettings';
      id: string;
      estimateDownstreamScope3: boolean;
      disabledFinanceDataFlags: Array<string>;
    };
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  } | null;
};

export type GQFinanceShowYourWorkQueryVariables = Exact<{
  financeSnapshotId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  financeMetric: GQFinanceMetric;
}>;

export type GQFinanceShowYourWorkQuery = {
  __typename?: 'Query';
  financeShowYourWork: {
    __typename?: 'FinanceShowYourWorkData';
    subtitle: string | null;
    aggregateRows: any | null;
    rowsWithOnlyFinancedEmissions: any | null;
  } | null;
};

export type GQAssetCorporateSummaryPageQueryVariables = Exact<{
  assetCorporateId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
  emissionsYearId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQAssetCorporateSummaryPageQuery = {
  __typename?: 'Query';
  assetCorporate: {
    __typename?: 'AssetCorporate';
    id: string;
    emissionsByGhgCategory: {
      __typename?: 'EmissionsByGhgCategoryForFinance';
      fullIntervalGross: number | null;
      topGhgCategories: Array<{
        __typename?: 'GhgCategorizedEmissionData';
        ghgCategoryId: string;
        businessSubcategory: string | null;
        proportion: number;
        amountKgco2e: number;
      }>;
      topGhgCategoriesWithSubcategories: Array<{
        __typename?: 'GhgCategorizedEmissionData';
        ghgCategoryId: string;
        businessSubcategory: string | null;
        proportion: number;
        amountKgco2e: number;
      }>;
    } | null;
    benchmarks: Array<{
      __typename?: 'Benchmark';
      id: string;
      reportId: string | null;
      name: string;
      reportingYear: number;
      companyId: string;
      kgco2eByScope: Array<{
        __typename?: 'BenchmarkScopeBreakdownDataPoint';
        scope: GQGhgScope;
        kgco2e: number | null;
      }>;
    }>;
    selfBenchmark: {
      __typename?: 'Benchmark';
      id: string;
      reportId: string | null;
      name: string;
      reportingYear: number;
      companyId: string;
      kgco2eByScope: Array<{
        __typename?: 'BenchmarkScopeBreakdownDataPoint';
        scope: GQGhgScope;
        kgco2e: number | null;
      }>;
    } | null;
    company: {
      __typename?: 'Company';
      id: string;
      naicsCode: string | null;
      sbtiStage: GQCompanySbtCommitmentStage;
      climateProgress: GQCompanyClimateProgress;
      sustainabilityWebsiteUrl: string | null;
      isWatershedCorporateCustomer: boolean;
      parentRelationships: Array<{
        __typename?: 'ParentRelationship';
        id: string;
        relationshipStartDate: YearMonth | null;
        relationshipEndDate: YearMonth | null;
        parent: {
          __typename?: 'Company';
          id: string;
          name: string;
          disclosuresV2: Array<{
            __typename?: 'Disclosure';
            id: string;
            company: { __typename?: 'Company'; id: string } | null;
            publicDisclosure: {
              __typename?: 'PublicDisclosure';
              id: string;
              publishingYear: number;
              reportType: GQExternalReportType;
              publicUrl: string;
              qualityScore: GQDisclosureQualityScore | null;
              cdpVendorData: {
                __typename?: 'CdpVendorData';
                id: string;
                publishingYear: number;
                reportingYear: number;
                scope1Nonzero: boolean;
                scope2Nonzero: boolean;
                scope3Nonzero: boolean;
                totalEmissionsNonzero: boolean;
                scope301Or302Nonzero: boolean;
                scope1Verified: boolean;
                scope2Verified: boolean;
                scope3Verified: boolean;
                pctEvaluationStatusesMatchResponse: number;
                disclosureQualityScore: GQDisclosureQualityScore;
                scope301EvaluationStatus: GQScope3EvaluationStatus;
                scope302EvaluationStatus: GQScope3EvaluationStatus;
                scope303EvaluationStatus: GQScope3EvaluationStatus;
                scope304EvaluationStatus: GQScope3EvaluationStatus;
                scope305EvaluationStatus: GQScope3EvaluationStatus;
                scope306EvaluationStatus: GQScope3EvaluationStatus;
                scope307EvaluationStatus: GQScope3EvaluationStatus;
                scope308EvaluationStatus: GQScope3EvaluationStatus;
                scope309EvaluationStatus: GQScope3EvaluationStatus;
                scope310EvaluationStatus: GQScope3EvaluationStatus;
                scope311EvaluationStatus: GQScope3EvaluationStatus;
                scope312EvaluationStatus: GQScope3EvaluationStatus;
                scope313EvaluationStatus: GQScope3EvaluationStatus;
                scope314EvaluationStatus: GQScope3EvaluationStatus;
                scope315EvaluationStatus: GQScope3EvaluationStatus;
                scope316EvaluationStatus: GQScope3EvaluationStatus;
                scope317EvaluationStatus: GQScope3EvaluationStatus;
              } | null;
            } | null;
            privateDisclosure: {
              __typename?: 'PrivateDisclosure';
              id: string;
              companyId: string | null;
              orgId: string;
              orgName: string;
              surveyId: string | null;
              privateDisclosureType: GQPrivateDisclosureType;
              createdAt: Date;
              disclosureQualityScore: GQDisclosureQualityScore | null;
              disclosureQualityExplanation: string | null;
              thirdPartyVerified: boolean;
              cdpVendorData: {
                __typename?: 'CdpVendorData';
                id: string;
                publishingYear: number;
                reportingYear: number;
                scope1Nonzero: boolean;
                scope2Nonzero: boolean;
                scope3Nonzero: boolean;
                totalEmissionsNonzero: boolean;
                scope301Or302Nonzero: boolean;
                scope1Verified: boolean;
                scope2Verified: boolean;
                scope3Verified: boolean;
                pctEvaluationStatusesMatchResponse: number;
                disclosureQualityScore: GQDisclosureQualityScore;
                scope301EvaluationStatus: GQScope3EvaluationStatus;
                scope302EvaluationStatus: GQScope3EvaluationStatus;
                scope303EvaluationStatus: GQScope3EvaluationStatus;
                scope304EvaluationStatus: GQScope3EvaluationStatus;
                scope305EvaluationStatus: GQScope3EvaluationStatus;
                scope306EvaluationStatus: GQScope3EvaluationStatus;
                scope307EvaluationStatus: GQScope3EvaluationStatus;
                scope308EvaluationStatus: GQScope3EvaluationStatus;
                scope309EvaluationStatus: GQScope3EvaluationStatus;
                scope310EvaluationStatus: GQScope3EvaluationStatus;
                scope311EvaluationStatus: GQScope3EvaluationStatus;
                scope312EvaluationStatus: GQScope3EvaluationStatus;
                scope313EvaluationStatus: GQScope3EvaluationStatus;
                scope314EvaluationStatus: GQScope3EvaluationStatus;
                scope315EvaluationStatus: GQScope3EvaluationStatus;
                scope316EvaluationStatus: GQScope3EvaluationStatus;
                scope317EvaluationStatus: GQScope3EvaluationStatus;
              } | null;
            } | null;
            historicalEmissionsYears: Array<{
              __typename?: 'EmissionsYear';
              id: string;
              publicDisclosureId: string | null;
              reportingYear: number;
              numEmployees: number | null;
              revenue: number | null;
              revenueCurrency: string | null;
              revenueUsd: number | null;
              percentageCleanEnergy: number | null;
              totalMwh: number | null;
              units: GQCompanyEmissionsUnits;
              expenseCategory: string | null;
              scope1: number | null;
              scope2Market: number | null;
              scope2Location: number | null;
              scope3: number | null;
              scope301: number | null;
              scope302: number | null;
              scope303: number | null;
              scope304: number | null;
              scope305: number | null;
              scope306: number | null;
              scope307: number | null;
              scope308: number | null;
              scope309: number | null;
              scope310: number | null;
              scope311: number | null;
              scope312: number | null;
              scope313: number | null;
              scope314: number | null;
              scope315: number | null;
              scope316: number | null;
              scope317: number | null;
            }> | null;
            targets: Array<{
              __typename?: 'DisclosureTargetDetails';
              id: string;
              name: string;
              description: string;
              baseYear: YearMonth;
              emissionsType: GQDisclosureTargetEmissionsType;
              reductionRate: GQDisclosureTargetReductionRate;
              intensityType: GQDisclosureTargetIntensityType;
              unit: string | null;
              unitDescription: string | null;
              filters: {
                __typename?: 'FilterExpressionGroup';
                conjunction: GQFilterConjunction;
                expressions: Array<{
                  __typename?: 'FilterExpressionPrimitive';
                  field: GQFilterFieldLegacy;
                  operator: GQFilterOperator;
                  value: Array<string>;
                }>;
              };
              emissionsTarget: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              };
            }> | null;
            initiatives: Array<{
              __typename?: 'DisclosureInitiative';
              id: string;
              name: string | null;
              initiativeType: GQDisclosureInitiativeType;
              description: string | null;
              startYearMonth: YearMonth | null;
              endYearMonth: YearMonth | null;
              intensityType: GQDisclosureTargetIntensityType | null;
              emissionsTimeseries: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              } | null;
              filters: {
                __typename?: 'FilterExpressionGroup';
                conjunction: GQFilterConjunction;
                expressions: Array<{
                  __typename?: 'FilterExpressionPrimitive';
                  field: GQFilterFieldLegacy;
                  operator: GQFilterOperator;
                  value: Array<string>;
                }>;
              } | null;
            }> | null;
            climateCommitments: Array<
              | {
                  __typename?: 'CarbonNeutralCommitment';
                  targetYear: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'CleanEnergyCommitment';
                  targetYear: number | null;
                  targetPercentageCleanEnergy: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'NetZeroCommitment';
                  targetYear: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'ScienceBasedTargetCommitment';
                  submittedToSBTi: boolean;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                  commitment: {
                    __typename?: 'SBTCommitment';
                    id: string;
                    stage: GQSbtCommitmentStage | null;
                    targetClassification: GQSbtTargetClassification | null;
                    nearTermTargetYear: number | null;
                    longTermTargetYear: number | null;
                    baselineYear: number | null;
                    netZeroCommitted: boolean | null;
                    commitmentType: GQSbtCommitmentType | null;
                    commitmentDeadline: Date | null;
                    netZeroTargetYear: number | null;
                  };
                }
            > | null;
          }>;
        };
      }>;
      parentRelationshipsForSidebar: Array<{
        __typename?: 'ParentRelationship';
        id: string;
        relationshipStartDate: YearMonth | null;
        relationshipEndDate: YearMonth | null;
        parent: { __typename?: 'Company'; id: string; name: string };
      }>;
    } | null;
    disclosures: Array<{
      __typename?: 'Disclosure';
      id: string;
      company: { __typename?: 'Company'; id: string } | null;
      publicDisclosure: {
        __typename?: 'PublicDisclosure';
        id: string;
        publishingYear: number;
        reportType: GQExternalReportType;
        publicUrl: string;
        qualityScore: GQDisclosureQualityScore | null;
        cdpVendorData: {
          __typename?: 'CdpVendorData';
          id: string;
          publishingYear: number;
          reportingYear: number;
          scope1Nonzero: boolean;
          scope2Nonzero: boolean;
          scope3Nonzero: boolean;
          totalEmissionsNonzero: boolean;
          scope301Or302Nonzero: boolean;
          scope1Verified: boolean;
          scope2Verified: boolean;
          scope3Verified: boolean;
          pctEvaluationStatusesMatchResponse: number;
          disclosureQualityScore: GQDisclosureQualityScore;
          scope301EvaluationStatus: GQScope3EvaluationStatus;
          scope302EvaluationStatus: GQScope3EvaluationStatus;
          scope303EvaluationStatus: GQScope3EvaluationStatus;
          scope304EvaluationStatus: GQScope3EvaluationStatus;
          scope305EvaluationStatus: GQScope3EvaluationStatus;
          scope306EvaluationStatus: GQScope3EvaluationStatus;
          scope307EvaluationStatus: GQScope3EvaluationStatus;
          scope308EvaluationStatus: GQScope3EvaluationStatus;
          scope309EvaluationStatus: GQScope3EvaluationStatus;
          scope310EvaluationStatus: GQScope3EvaluationStatus;
          scope311EvaluationStatus: GQScope3EvaluationStatus;
          scope312EvaluationStatus: GQScope3EvaluationStatus;
          scope313EvaluationStatus: GQScope3EvaluationStatus;
          scope314EvaluationStatus: GQScope3EvaluationStatus;
          scope315EvaluationStatus: GQScope3EvaluationStatus;
          scope316EvaluationStatus: GQScope3EvaluationStatus;
          scope317EvaluationStatus: GQScope3EvaluationStatus;
        } | null;
      } | null;
      privateDisclosure: {
        __typename?: 'PrivateDisclosure';
        id: string;
        companyId: string | null;
        orgId: string;
        orgName: string;
        surveyId: string | null;
        privateDisclosureType: GQPrivateDisclosureType;
        createdAt: Date;
        disclosureQualityScore: GQDisclosureQualityScore | null;
        disclosureQualityExplanation: string | null;
        thirdPartyVerified: boolean;
        cdpVendorData: {
          __typename?: 'CdpVendorData';
          id: string;
          publishingYear: number;
          reportingYear: number;
          scope1Nonzero: boolean;
          scope2Nonzero: boolean;
          scope3Nonzero: boolean;
          totalEmissionsNonzero: boolean;
          scope301Or302Nonzero: boolean;
          scope1Verified: boolean;
          scope2Verified: boolean;
          scope3Verified: boolean;
          pctEvaluationStatusesMatchResponse: number;
          disclosureQualityScore: GQDisclosureQualityScore;
          scope301EvaluationStatus: GQScope3EvaluationStatus;
          scope302EvaluationStatus: GQScope3EvaluationStatus;
          scope303EvaluationStatus: GQScope3EvaluationStatus;
          scope304EvaluationStatus: GQScope3EvaluationStatus;
          scope305EvaluationStatus: GQScope3EvaluationStatus;
          scope306EvaluationStatus: GQScope3EvaluationStatus;
          scope307EvaluationStatus: GQScope3EvaluationStatus;
          scope308EvaluationStatus: GQScope3EvaluationStatus;
          scope309EvaluationStatus: GQScope3EvaluationStatus;
          scope310EvaluationStatus: GQScope3EvaluationStatus;
          scope311EvaluationStatus: GQScope3EvaluationStatus;
          scope312EvaluationStatus: GQScope3EvaluationStatus;
          scope313EvaluationStatus: GQScope3EvaluationStatus;
          scope314EvaluationStatus: GQScope3EvaluationStatus;
          scope315EvaluationStatus: GQScope3EvaluationStatus;
          scope316EvaluationStatus: GQScope3EvaluationStatus;
          scope317EvaluationStatus: GQScope3EvaluationStatus;
        } | null;
      } | null;
      historicalEmissionsYears: Array<{
        __typename?: 'EmissionsYear';
        id: string;
        publicDisclosureId: string | null;
        reportingYear: number;
        numEmployees: number | null;
        revenue: number | null;
        revenueCurrency: string | null;
        revenueUsd: number | null;
        percentageCleanEnergy: number | null;
        totalMwh: number | null;
        units: GQCompanyEmissionsUnits;
        expenseCategory: string | null;
        scope1: number | null;
        scope2Market: number | null;
        scope2Location: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
      }> | null;
      targets: Array<{
        __typename?: 'DisclosureTargetDetails';
        id: string;
        name: string;
        description: string;
        baseYear: YearMonth;
        emissionsType: GQDisclosureTargetEmissionsType;
        reductionRate: GQDisclosureTargetReductionRate;
        intensityType: GQDisclosureTargetIntensityType;
        unit: string | null;
        unitDescription: string | null;
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
        emissionsTarget: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
      }> | null;
      initiatives: Array<{
        __typename?: 'DisclosureInitiative';
        id: string;
        name: string | null;
        initiativeType: GQDisclosureInitiativeType;
        description: string | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        intensityType: GQDisclosureTargetIntensityType | null;
        emissionsTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        } | null;
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        } | null;
      }> | null;
      climateCommitments: Array<
        | {
            __typename?: 'CarbonNeutralCommitment';
            targetYear: number | null;
            id: string;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
          }
        | {
            __typename?: 'CleanEnergyCommitment';
            targetYear: number | null;
            targetPercentageCleanEnergy: number | null;
            id: string;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
          }
        | {
            __typename?: 'NetZeroCommitment';
            targetYear: number | null;
            id: string;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
          }
        | {
            __typename?: 'ScienceBasedTargetCommitment';
            submittedToSBTi: boolean;
            id: string;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
            commitment: {
              __typename?: 'SBTCommitment';
              id: string;
              stage: GQSbtCommitmentStage | null;
              targetClassification: GQSbtTargetClassification | null;
              nearTermTargetYear: number | null;
              longTermTargetYear: number | null;
              baselineYear: number | null;
              netZeroCommitted: boolean | null;
              commitmentType: GQSbtCommitmentType | null;
              commitmentDeadline: Date | null;
              netZeroTargetYear: number | null;
            };
          }
      > | null;
    }>;
  };
};

export type GQFinanceAssetsYearOverYearQueryVariables = Exact<{
  financeSnapshotId: Scalars['ID']['input'];
  baselineSnapshotId: Scalars['ID']['input'];
  baselineYear: Scalars['Int']['input'];
  comparisonYear: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  search: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQFinanceAssetsYearOverYearQuery = {
  __typename?: 'Query';
  financeAssetsYearOverYear: {
    __typename?: 'FinanceAssetsYearOverYear';
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
    edges: Array<{
      __typename?: 'FinanceAssetYearOverYearEdge';
      node: any | null;
    } | null>;
  };
};

export type GQFinanceFundsYearOverYearQueryVariables = Exact<{
  financeSnapshotId: Scalars['ID']['input'];
  baselineSnapshotId: Scalars['ID']['input'];
  baselineYear: Scalars['Int']['input'];
  comparisonYear: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  search: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQFinanceFundsYearOverYearQuery = {
  __typename?: 'Query';
  financeFundsYearOverYear: {
    __typename?: 'FinanceFundsYearOverYear';
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
    edges: Array<{
      __typename?: 'FinanceFundYearOverYearEdge';
      node: any | null;
    } | null>;
  };
};

export type GQWaterfallBlockAllFieldsFragment = {
  __typename?: 'WaterfallBlock';
  id: string;
  label: string;
  value: number;
  isIntermediateSum: boolean | null;
  isSum: boolean | null;
};

export type GQFinanceSnapshotWaterfallChartQueryVariables = Exact<{
  financeSnapshotId: Scalars['ID']['input'];
  baselineSnapshotId: Scalars['ID']['input'];
  baselineYear: Scalars['Int']['input'];
  comparisonYear: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  financeWaterfallChartType: GQFinanceWaterfallChartType;
}>;

export type GQFinanceSnapshotWaterfallChartQuery = {
  __typename?: 'Query';
  financeSnapshotWaterfallChart: {
    __typename?: 'WaterfallChartPayload';
    waterfallBlocks: Array<{
      __typename?: 'WaterfallBlock';
      id: string;
      label: string;
      value: number;
      isIntermediateSum: boolean | null;
      isSum: boolean | null;
    }>;
  };
};

export type GQFinanceGridWorksheetQueryVariables = Exact<{
  standardView: GQFinanceStandardView;
}>;

export type GQFinanceGridWorksheetQuery = {
  __typename?: 'Query';
  financeGridWorksheet: {
    __typename?: 'FinanceWorksheet';
    id: string;
    hiddenColumns: Array<string> | null;
    standardView: GQFinanceStandardView | null;
    columns: Array<{
      __typename?: 'FinanceWorksheetColumn';
      id: string;
      financeWorksheetId: string | null;
      field: string;
      columnFormat: GQGridColumnFormat;
      headerName: string;
      description: string;
      width: number | null;
      columnConfiguration:
        | {
            __typename: 'GridColumnConfigurationMultiSelect';
            columnFormat: GQGridColumnFormat;
            options: Array<string>;
          }
        | {
            __typename: 'GridColumnConfigurationSingleSelect';
            columnFormat: GQGridColumnFormat;
            options: Array<string>;
          }
        | null;
    }>;
    orgColumns: Array<{
      __typename?: 'FinanceColumn';
      id: string;
      field: string;
      columnFormat: GQGridColumnFormat;
      headerName: string;
      description: string | null;
      width: number | null;
      columnConfiguration:
        | { __typename?: 'GridColumnConfigurationMultiSelect' }
        | {
            __typename: 'GridColumnConfigurationSingleSelect';
            columnFormat: GQGridColumnFormat;
            options: Array<string>;
          }
        | null;
    }>;
    sortModel: Array<{
      __typename?: 'DataGridSortItem';
      field: string;
      sort: GQDataGridSortDirection;
    }>;
  };
};

export type GQGetFootprintBridgeChartDataQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
  shouldShowFootprintBridge: InputMaybe<Scalars['Boolean']['input']>;
  aggregateKind: GQAggregateKind;
  intensityKind: InputMaybe<GQIntensityDenominatorKind>;
  groupBy: GQFootprintBridgeGroupByColumn;
}>;

export type GQGetFootprintBridgeChartDataQuery = {
  __typename?: 'Query';
  footprint: {
    __typename?: 'LegacyFootprint';
    id: string;
    bridgeWithDates: {
      __typename?: 'FootprintBridgeData';
      footprintBridgeRecords: Array<{
        __typename?: 'FootprintBridgeRecord';
        groupByValue: string;
        aggregateKind: GQAggregateKind;
        groupBy: GQFootprintBridgeGroupByColumn;
        emissionsOld: number | null;
        activityDeltaPositive: number | null;
        activityDeltaNegative: number | null;
        methodologyDeltaPositive: number | null;
        methodologyDeltaNegative: number | null;
        emissionsNew: number | null;
      }>;
      footprintBridgeDetails: Array<{
        __typename?: 'FootprintBridgeDetail';
        groupByValue: string;
        inputUnit: string | null;
        inputQuantityOld: number | null;
        kgco2eQuantityOld: number | null;
        inputQuantityNew: number | null;
        kgco2eQuantityNew: number | null;
        emissionsOld: number | null;
        emissionsCounterfactual: number | null;
        emissionsNew: number | null;
        methodologyDelta: number | null;
        activityDelta: number | null;
      }>;
      footprintBridgeDates: {
        __typename?: 'FootprintBridgeDates';
        oldStartDate: YearMonth;
        oldEndDate: YearMonth;
        newStartDate: YearMonth;
        newEndDate: YearMonth;
      } | null;
    };
  } | null;
};

export type GQFootprintVersionForDrilldownFragment = {
  __typename?: 'FootprintVersion';
  id: string;
  kind: GQFootprintVersionKind;
  createdAt: Date;
  originalFootprintSnapshotId: string;
};

export type GQGetFootprintMetadataForSnapshotQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
}>;

export type GQGetFootprintMetadataForSnapshotQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    footprintInterval: YMInterval | null;
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      kind: GQFootprintVersionKind;
      createdAt: Date;
      originalFootprintSnapshotId: string;
    } | null;
    snapshotFootprintKindsMeta: Array<{
      __typename?: 'SnapshotFootprintKindMeta';
      id: string;
      footprintKind: string;
      name: string;
      description: string;
      footprintInterval: YMInterval | null;
    }> | null;
  } | null;
};

export type GQPublishDraftFootprintMutationVariables = Exact<{
  input: GQPublishDraftFootprintInput;
}>;

export type GQPublishDraftFootprintMutation = {
  __typename?: 'Mutation';
  publishDraftFootprint: {
    __typename?: 'PublishDraftFootprintPayload';
    footprintAnalysis: { __typename?: 'FootprintAnalysis'; id: string | null };
  } | null;
};

export type GQSetPeerCompanyGroupMemberFeatureStatusMutationVariables = Exact<{
  input: GQSetPeerCompanyGroupMemberFeatureStatusInput;
}>;

export type GQSetPeerCompanyGroupMemberFeatureStatusMutation = {
  __typename?: 'Mutation';
  setPeerCompanyGroupMemberFeatureStatus: {
    __typename?: 'SetPeerCompanyGroupMemberFeatureStatusPayload';
    peerCompanyGroupMember: {
      __typename?: 'PeerCompanyGroupMember';
      id: string;
      shouldHideFromFootprintOverview: boolean;
      revenueAtRisk: number | null;
    };
  } | null;
};

export type GQCategorySubcategoriesQueryQueryVariables = Exact<{
  filters: GQFootprintAnalysisFilters;
  version: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
}>;

export type GQCategorySubcategoriesQueryQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    timeseries: {
      __typename?: 'FootprintAnalysisTimeSeries';
      id: string;
      series: Array<{
        __typename?: 'FootprintAnalysisTimeseriesSeries';
        label: string | null;
        value: string | null;
        data: Array<{
          __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
          date: Date;
          value: number;
        }>;
      }>;
    } | null;
  } | null;
};

export type GQFootprintAssemblyRunForChangelogFragment = {
  __typename?: 'FootprintAssemblyRun';
  id: string;
  createdAt: Date;
  status: GQFootprintAssemblyRunStatus;
  workflowId: string | null;
  footprintSnapshotId: string | null;
  generatedBy: { __typename?: 'User'; id: string; displayName: string } | null;
};

export type GQFootprintVersionForChangelogV3Fragment = {
  __typename?: 'FootprintVersion';
  id: string;
  createdAt: Date;
  kind: GQFootprintVersionKind;
  userVisibleFootprintSnapshotId: string;
  footprintInterval: YMInterval | null;
  pinInfo: {
    __typename?: 'FootprintVersionPin';
    id: string;
    name: string;
  } | null;
  measurementProjects: Array<{
    __typename?: 'MeasurementProject';
    id: string;
    coverageInterval: YMInterval;
  }>;
};

export type GQGetFootprintChangelogV3EntriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetFootprintChangelogV3EntriesQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  footprintChangelog: {
    __typename?: 'FootprintChangelog';
    id: string;
    latestDraftVersion: {
      __typename?: 'FootprintVersionConnection';
      edges: Array<{
        __typename?: 'FootprintVersionEdge';
        node: {
          __typename?: 'FootprintVersion';
          id: string;
          createdAt: Date;
          kind: GQFootprintVersionKind;
          userVisibleFootprintSnapshotId: string;
          footprintInterval: YMInterval | null;
          pinInfo: {
            __typename?: 'FootprintVersionPin';
            id: string;
            name: string;
          } | null;
          measurementProjects: Array<{
            __typename?: 'MeasurementProject';
            id: string;
            coverageInterval: YMInterval;
          }>;
        } | null;
      } | null>;
    } | null;
    publishedVersions: {
      __typename?: 'FootprintVersionConnection';
      edges: Array<{
        __typename?: 'FootprintVersionEdge';
        node: {
          __typename?: 'FootprintVersion';
          id: string;
          createdAt: Date;
          kind: GQFootprintVersionKind;
          userVisibleFootprintSnapshotId: string;
          footprintInterval: YMInterval | null;
          pinInfo: {
            __typename?: 'FootprintVersionPin';
            id: string;
            name: string;
          } | null;
          measurementProjects: Array<{
            __typename?: 'MeasurementProject';
            id: string;
            coverageInterval: YMInterval;
          }>;
        } | null;
      } | null>;
    } | null;
  };
  latestFootprintAssemblyRun: {
    __typename?: 'FootprintAssemblyRun';
    id: string;
    createdAt: Date;
    status: GQFootprintAssemblyRunStatus;
    workflowId: string | null;
    footprintSnapshotId: string | null;
    generatedBy: {
      __typename?: 'User';
      id: string;
      displayName: string;
    } | null;
  } | null;
  activeMeasurementProjects: Array<{
    __typename?: 'MeasurementProject';
    id: string;
  }>;
};

export type GQDeduplicationSuggestionsQueryVariables = Exact<{
  footprintSnapshotId: Scalars['ID']['input'];
}>;

export type GQDeduplicationSuggestionsQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    footprintExclusionSuggestions: Array<{
      __typename?: 'FootprintExclusionSuggestion';
      id: string;
    }>;
  };
};

export type GQFootprintConfigurationQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
}>;

export type GQFootprintConfigurationQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      versionName: string | null;
      userChangelogMd: string | null;
      publishableUserChangelogMd: string | null;
      kind: GQFootprintVersionKind;
      createdAt: Date;
      isGeneratedFromProduct: boolean;
      footprintInterval: YMInterval | null;
      pinInfo: {
        __typename?: 'FootprintVersionPin';
        id: string;
        name: string;
        description: string | null;
      } | null;
      generatedBy: {
        __typename?: 'User';
        id: string;
        displayName: string;
      } | null;
      configuration: {
        __typename?: 'FootprintConfiguration';
        id: string;
        methodologyConfig: {
          __typename?: 'MethodologyConfig';
          id: string;
          spendMethodology: GQSpendMethodology | null;
        } | null;
        marketplaceAllocationInstructions: {
          __typename?: 'MarketplaceAllocationInstructionConnection';
          edges: Array<{
            __typename?: 'MarketplaceAllocationInstructionEdge';
            node: {
              __typename?: 'MarketplaceAllocationInstruction';
              id: string;
              amountKwh: number;
              ghgCategoryId: string;
              purchaseLineItem: {
                __typename?: 'MarketplacePurchaseLineItem';
                id: string;
                project: {
                  __typename?: 'MarketplaceProject';
                  id: string;
                  name: string;
                } | null;
              };
            } | null;
          } | null>;
        };
        measurementProjectDatasets: Array<{
          __typename?: 'FootprintMeasurementProjectDataset';
          id: string;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
            createdAt: Date;
          };
          datasetsAndDatasetGroups: Array<{
            __typename?: 'FootprintDatasetOrDatasetGroup';
            id: string;
            datasetGroupName: string | null;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          }>;
        }>;
        carbonRemoval: {
          __typename?: 'FootprintConfigCarbonRemoval';
          id: string;
          totalTco2e: number;
        } | null;
        releasesByBat: Array<{
          __typename?: 'EmissionsModelReleaseByBatName';
          batName: string;
          lifecyclePhase: GQEmReleaseLifecyclePhase | null;
          release: {
            __typename?: 'EmissionsModelRelease';
            id: string;
            displayName: string;
          };
        }>;
      } | null;
    } | null;
  } | null;
};

export type GQFootprintVersionForTimelineFragment = {
  __typename?: 'FootprintVersion';
  id: string;
  createdAt: Date;
  versionName: string | null;
  versionIdentifier: string | null;
  userChangelogMd: string | null;
  publishableUserChangelogMd: string | null;
  kind: GQFootprintVersionKind;
  canUserPublish: boolean;
  isGeneratedFromProduct: boolean;
  isAutoGenerated: boolean;
  userVisibleFootprintSnapshotId: string;
  originalFootprintSnapshotId: string;
  footprintInterval: YMInterval | null;
  pinInfo: {
    __typename?: 'FootprintVersionPin';
    id: string;
    name: string;
    description: string | null;
  } | null;
  generatedBy: { __typename?: 'User'; id: string; displayName: string } | null;
  measurementProjects: Array<{
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    coverageInterval: YMInterval;
  }>;
  watershedFootprintReviewRequest: {
    __typename?: 'WatershedFootprintReviewRequest';
    id: string;
    status: GQWatershedFootprintReviewRequestStatus;
  } | null;
};

export type GQGetFootprintVersionTimelineEntriesQueryVariables = Exact<{
  footprintVersionKindFilter: InputMaybe<GQFootprintVersionKind>;
  footprintId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQGetFootprintVersionTimelineEntriesQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  footprintChangelog: {
    __typename?: 'FootprintChangelog';
    id: string;
    versions: {
      __typename?: 'FootprintVersionConnection';
      edges: Array<{
        __typename?: 'FootprintVersionEdge';
        node: {
          __typename?: 'FootprintVersion';
          id: string;
          createdAt: Date;
          versionName: string | null;
          versionIdentifier: string | null;
          userChangelogMd: string | null;
          publishableUserChangelogMd: string | null;
          kind: GQFootprintVersionKind;
          canUserPublish: boolean;
          isGeneratedFromProduct: boolean;
          isAutoGenerated: boolean;
          userVisibleFootprintSnapshotId: string;
          originalFootprintSnapshotId: string;
          footprintInterval: YMInterval | null;
          pinInfo: {
            __typename?: 'FootprintVersionPin';
            id: string;
            name: string;
            description: string | null;
          } | null;
          generatedBy: {
            __typename?: 'User';
            id: string;
            displayName: string;
          } | null;
          measurementProjects: Array<{
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
            coverageInterval: YMInterval;
          }>;
          watershedFootprintReviewRequest: {
            __typename?: 'WatershedFootprintReviewRequest';
            id: string;
            status: GQWatershedFootprintReviewRequestStatus;
          } | null;
        } | null;
      } | null>;
    } | null;
  };
  latestFootprintAssemblyRun: {
    __typename?: 'FootprintAssemblyRun';
    id: string;
    createdAt: Date;
    status: GQFootprintAssemblyRunStatus;
    workflowId: string | null;
    footprintSnapshotId: string | null;
    generatedBy: {
      __typename?: 'User';
      id: string;
      displayName: string;
    } | null;
  } | null;
};

export type GQUpdateFootprintRunStatusMutationVariables = Exact<{
  input: GQUpdateFootprintAssemblyRunStatusInput;
}>;

export type GQUpdateFootprintRunStatusMutation = {
  __typename?: 'Mutation';
  updateFootprintAssemblyRunStatus: {
    __typename?: 'UpdateFootprintAssemblyRunStatusPayload';
    footprintAssemblyRun: {
      __typename?: 'FootprintAssemblyRun';
      id: string;
      createdAt: Date;
      status: GQFootprintAssemblyRunStatus;
      workflowId: string | null;
      footprintSnapshotId: string | null;
      generatedBy: {
        __typename?: 'User';
        id: string;
        displayName: string;
      } | null;
    };
  };
};

export type GQEditFootprintVersionMutationVariables = Exact<{
  input: GQRenameFootprintVersionInput;
}>;

export type GQEditFootprintVersionMutation = {
  __typename?: 'Mutation';
  renameFootprintVersion: {
    __typename?: 'RenameFootprintVersionPayload';
    footprintVersion: { __typename?: 'FootprintVersion'; id: string };
  };
};

export type GQGetFootprintVersionTimelineDrawerContentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetFootprintVersionTimelineDrawerContentsQuery = {
  __typename?: 'Query';
  latestFootprintAssemblyRun: {
    __typename?: 'FootprintAssemblyRun';
    id: string;
    createdAt: Date;
    status: GQFootprintAssemblyRunStatus;
    workflowId: string | null;
    footprintSnapshotId: string | null;
    generatedBy: {
      __typename?: 'User';
      id: string;
      displayName: string;
    } | null;
  } | null;
  activeMeasurementProjects: Array<{
    __typename?: 'MeasurementProject';
    id: string;
  }>;
};

export type GQGenerateAutoFootprintMutationVariables = Exact<{
  input: GQGenerateAutoFootprintInput;
}>;

export type GQGenerateAutoFootprintMutation = {
  __typename?: 'Mutation';
  generateAutoFootprint: {
    __typename?: 'GenerateAutoFootprintPayload';
    jobId: string;
    footprintAssemblyRun: {
      __typename?: 'FootprintAssemblyRun';
      id: string;
      createdAt: Date;
      status: GQFootprintAssemblyRunStatus;
      workflowId: string | null;
      footprintSnapshotId: string | null;
      generatedBy: {
        __typename?: 'User';
        id: string;
        displayName: string;
      } | null;
    };
  };
};

export type GQGetDetailsForGenerateFootprintQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetDetailsForGenerateFootprintQuery = {
  __typename?: 'Query';
  footprintGenerationInfo: {
    __typename?: 'FootprintGenerationInfo';
    totalPublishedRecordsForOrg: number;
  };
};

export type GQFootprintExclusionRuleForEditorFragment = {
  __typename?: 'FootprintExclusionRule';
  id: string;
  timeIntervals: Array<YMInterval> | null;
  ruleOrder: number;
  stableId: string;
  outputValue: number;
  selectedSource: GQFootprintExclusionRuleSelectedSource;
  deduplicationNote: string | null;
  filtersSourceOne: Array<{
    __typename?: 'BiQueryFilter';
    dimension: string;
    operator: GQBiFilterOperator;
    value: Array<FilterValue | null>;
  }>;
  filtersSourceTwo: Array<{
    __typename?: 'BiQueryFilter';
    dimension: string;
    operator: GQBiFilterOperator;
    value: Array<FilterValue | null>;
  }> | null;
};

export type GQDeduplicationEditorQueryVariables = Exact<{
  footprintSnapshotId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQDeduplicationEditorQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    footprintExclusionRules: Array<{
      __typename?: 'FootprintExclusionRule';
      id: string;
      timeIntervals: Array<YMInterval> | null;
      ruleOrder: number;
      stableId: string;
      outputValue: number;
      selectedSource: GQFootprintExclusionRuleSelectedSource;
      deduplicationNote: string | null;
      filtersSourceOne: Array<{
        __typename?: 'BiQueryFilter';
        dimension: string;
        operator: GQBiFilterOperator;
        value: Array<FilterValue | null>;
      }>;
      filtersSourceTwo: Array<{
        __typename?: 'BiQueryFilter';
        dimension: string;
        operator: GQBiFilterOperator;
        value: Array<FilterValue | null>;
      }> | null;
    }>;
  };
  footprintChangelog: {
    __typename?: 'FootprintChangelog';
    id: string;
    latestDraftVersion: {
      __typename?: 'FootprintVersionConnection';
      edges: Array<{
        __typename?: 'FootprintVersionEdge';
        node: {
          __typename?: 'FootprintVersion';
          id: string;
          newDataCoverageInterval: YMInterval | null;
        } | null;
      } | null>;
    } | null;
  };
  activeMeasurementProjects: Array<{
    __typename?: 'MeasurementProject';
    id: string;
    coverageInterval: YMInterval;
  }>;
};

export type GQUpsertFootprintExclusionRulesForEditorMutationVariables = Exact<{
  input: GQUpsertFootprintExclusionRulesInput;
}>;

export type GQUpsertFootprintExclusionRulesForEditorMutation = {
  __typename?: 'Mutation';
  upsertFootprintExclusionRules: {
    __typename?: 'UpsertFootprintExclusionRulesPayload';
    organization: {
      __typename?: 'Organization';
      id: string;
      footprintExclusionRules: Array<{
        __typename?: 'FootprintExclusionRule';
        id: string;
        timeIntervals: Array<YMInterval> | null;
        ruleOrder: number;
        stableId: string;
        outputValue: number;
        selectedSource: GQFootprintExclusionRuleSelectedSource;
        deduplicationNote: string | null;
        filtersSourceOne: Array<{
          __typename?: 'BiQueryFilter';
          dimension: string;
          operator: GQBiFilterOperator;
          value: Array<FilterValue | null>;
        }>;
        filtersSourceTwo: Array<{
          __typename?: 'BiQueryFilter';
          dimension: string;
          operator: GQBiFilterOperator;
          value: Array<FilterValue | null>;
        }> | null;
      }>;
    };
  } | null;
};

export type GQExclusionRuleTargetedEmissionsQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
}>;

export type GQExclusionRuleTargetedEmissionsQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    exclusionRuleTargetedEmissions: Array<{
      __typename?: 'FootprintExclusionRuleTargetedEmissions';
      footprintExclusionRuleId: string;
      targetedQuantities: Array<{
        __typename?: 'ExclusionRuleTargetedQuantity';
        measure: string;
        value: number;
      }> | null;
    }> | null;
  } | null;
};

export type GQDeduplicationRulesDetailQueryVariables = Exact<{
  footprintSnapshotId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQDeduplicationRulesDetailQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    footprintExclusionRules: Array<{
      __typename?: 'FootprintExclusionRule';
      id: string;
      timeIntervals: Array<YMInterval> | null;
      ruleOrder: number;
      stableId: string;
      outputValue: number;
      selectedSource: GQFootprintExclusionRuleSelectedSource;
      deduplicationNote: string | null;
      filtersSourceOne: Array<{
        __typename?: 'BiQueryFilter';
        dimension: string;
        operator: GQBiFilterOperator;
        value: Array<FilterValue | null>;
      }>;
      filtersSourceTwo: Array<{
        __typename?: 'BiQueryFilter';
        dimension: string;
        operator: GQBiFilterOperator;
        value: Array<FilterValue | null>;
      }> | null;
    }>;
  };
};

export type GQDeduplicationRuleSuggestionsForIndexPageQueryVariables = Exact<{
  footprintSnapshotId: Scalars['ID']['input'];
}>;

export type GQDeduplicationRuleSuggestionsForIndexPageQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    footprintExclusionSuggestions: Array<{
      __typename?: 'FootprintExclusionSuggestion';
      id: string;
      suggestion:
        | {
            __typename: 'FootprintExclusionSuggestionSuggestionConflict';
            beaCode: string;
            beaCodeDescription: string;
            kind: GQFootprintExclusionSuggestionSuggestionKind;
            conflictingSources: Array<
              | {
                  __typename: 'FootprintExclusionSuggestionSuggestionConflictActivity';
                  tCO2e: number;
                  source: GQFootprintExclusionSuggestionConflictingSourceKind;
                  dataset: { __typename?: 'Dataset'; id: string; name: string };
                }
              | {
                  __typename: 'FootprintExclusionSuggestionSuggestionConflictSpend';
                  financialAccountId: string;
                  financialAccountName: string;
                  vendorEntity: string | null;
                  tCO2e: number;
                  source: GQFootprintExclusionSuggestionConflictingSourceKind;
                }
            >;
          }
        | {
            __typename: 'FootprintExclusionSuggestionSuggestionUnmappedActivity';
            tCO2e: number;
            kind: GQFootprintExclusionSuggestionSuggestionKind;
            dataset: { __typename?: 'Dataset'; id: string; name: string };
          };
    }>;
  };
};

export type GQDismissDeduplicationSuggestionMutationVariables = Exact<{
  suggestionId: Scalars['ID']['input'];
}>;

export type GQDismissDeduplicationSuggestionMutation = {
  __typename?: 'Mutation';
  dismissFootprintExclusionSuggestion: {
    __typename?: 'DismissFootprintExclusionSuggestionPayload';
    id: string;
  } | null;
};

export type GQResetDeduplicationSuggestionsMutationVariables = Exact<{
  footprintSnapshotId: Scalars['ID']['input'];
}>;

export type GQResetDeduplicationSuggestionsMutation = {
  __typename?: 'Mutation';
  resetFootprintExclusionSuggestions: {
    __typename?: 'ResetFootprintExclusionSuggestionsPayload';
    footprintSnapshotId: string;
  } | null;
};

export type GQCategoryRulesDetailQueryVariables = Exact<{
  footprintSnapshotId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQCategoryRulesDetailQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    footprintCategoryRules: Array<{
      __typename?: 'FootprintCategoryRule';
      id: string;
      outputColumn: string;
      outputValue: string;
      ruleOrder: number;
      stableId: string;
      filters: Array<{
        __typename?: 'BiQueryFilter';
        dimension: string;
        operator: GQBiFilterOperator;
        value: Array<FilterValue | null>;
      }>;
    }>;
  };
};

export type GQFootprintCategoryRuleForEditorFragment = {
  __typename?: 'FootprintCategoryRule';
  id: string;
  outputColumn: string;
  outputValue: string;
  ruleOrder: number;
  stableId: string;
  filters: Array<{
    __typename?: 'BiQueryFilter';
    dimension: string;
    operator: GQBiFilterOperator;
    value: Array<FilterValue | null>;
  }>;
};

export type GQUpsertFootprintCategoryRulesForEditorMutationVariables = Exact<{
  input: GQUpsertFootprintCategoryRulesInput;
}>;

export type GQUpsertFootprintCategoryRulesForEditorMutation = {
  __typename?: 'Mutation';
  upsertFootprintCategoryRules: {
    __typename?: 'UpsertFootprintCategoryRulesPayload';
    organization: {
      __typename?: 'Organization';
      id: string;
      footprintCategoryRules: Array<{
        __typename?: 'FootprintCategoryRule';
        id: string;
        outputColumn: string;
        outputValue: string;
        ruleOrder: number;
        stableId: string;
        filters: Array<{
          __typename?: 'BiQueryFilter';
          dimension: string;
          operator: GQBiFilterOperator;
          value: Array<FilterValue | null>;
        }>;
      }>;
    };
  } | null;
};

export type GQSaveCategoryRuleGhgCategoryNoteMutationVariables = Exact<{
  input: GQSaveCategoryRuleGhgCategoryNoteInput;
}>;

export type GQSaveCategoryRuleGhgCategoryNoteMutation = {
  __typename?: 'Mutation';
  saveCategoryRuleGhgCategoryNote: {
    __typename?: 'SaveCategoryRuleGhgCategoryNotePayload';
    note: string;
  } | null;
};

export type GQRecategorizationEditorQueryVariables = Exact<{
  footprintSnapshotId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQRecategorizationEditorQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    footprintCategoryRules: Array<{
      __typename?: 'FootprintCategoryRule';
      id: string;
      outputColumn: string;
      outputValue: string;
      ruleOrder: number;
      stableId: string;
      filters: Array<{
        __typename?: 'BiQueryFilter';
        dimension: string;
        operator: GQBiFilterOperator;
        value: Array<FilterValue | null>;
      }>;
    }>;
  };
  latestFootprintAssemblyRun: {
    __typename?: 'FootprintAssemblyRun';
    id: string;
    createdAt: Date;
    status: GQFootprintAssemblyRunStatus;
    workflowId: string | null;
    footprintSnapshotId: string | null;
    generatedBy: {
      __typename?: 'User';
      id: string;
      displayName: string;
    } | null;
  } | null;
};

export type GQFootprintConfigDrawerQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
}>;

export type GQFootprintConfigDrawerQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      createdAt: Date;
      generatedBy: {
        __typename?: 'User';
        id: string;
        displayName: string;
      } | null;
    } | null;
  } | null;
};

export type GQDrilldownSavedViewFieldsFragment = {
  __typename?: 'DrilldownSavedView';
  id: string;
  name: string;
  description: string | null;
  isPrivate: boolean;
  footprintKind: string;
  aggregateKind: GQDrilldownAggregateKind;
  inclusionKind: GQDrilldownInclusionKind;
  intensityDenominatorName: string | null;
  timeSeriesKind: GQDrilldownTimeSeriesKind;
  hasPieChart: boolean;
  hasTimeSeriesChart: boolean;
  hasDataTable: boolean;
  groupBy: Array<string> | null;
  searchTerm: string | null;
  defaultTimeInterval: YMInterval | null;
  biSavedViewId: string | null;
  filterExpressions: {
    __typename?: 'FilterExpressionGroupWithNewFilters';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitiveWithNewFilters';
      field: string;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  } | null;
};

export type GQGetDrilldownSavedViewsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetDrilldownSavedViewsQuery = {
  __typename?: 'Query';
  drilldownSavedViews: Array<{
    __typename?: 'DrilldownSavedView';
    id: string;
    name: string;
    description: string | null;
    isPrivate: boolean;
    footprintKind: string;
    aggregateKind: GQDrilldownAggregateKind;
    inclusionKind: GQDrilldownInclusionKind;
    intensityDenominatorName: string | null;
    timeSeriesKind: GQDrilldownTimeSeriesKind;
    hasPieChart: boolean;
    hasTimeSeriesChart: boolean;
    hasDataTable: boolean;
    groupBy: Array<string> | null;
    searchTerm: string | null;
    defaultTimeInterval: YMInterval | null;
    biSavedViewId: string | null;
    filterExpressions: {
      __typename?: 'FilterExpressionGroupWithNewFilters';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitiveWithNewFilters';
        field: string;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    } | null;
  }>;
};

export type GQGetFootprintTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GQGetFootprintTagsQuery = {
  __typename?: 'Query';
  footprintTags: Array<{
    __typename?: 'FootprintTag';
    id: string;
    tagName: string;
    description: string | null;
  }>;
};

export type GQGenerateAuditReportMutationVariables = Exact<{
  input: GQGenerateAuditReportInput;
}>;

export type GQGenerateAuditReportMutation = {
  __typename?: 'Mutation';
  generateAuditReport: {
    __typename?: 'GenerateAuditReportPayload';
    jobId: string;
  };
};

export type GQRegenerateAuditReportMutationVariables = Exact<{
  input: GQRegenerateAuditReportInput;
}>;

export type GQRegenerateAuditReportMutation = {
  __typename?: 'Mutation';
  regenerateAuditReport: {
    __typename?: 'GenerateAuditReportPayload';
    jobId: string;
  };
};

export type GQGetPublicDisclosureQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetPublicDisclosureQuery = {
  __typename?: 'Query';
  publicDisclosure: {
    __typename?: 'PublicDisclosure';
    id: string;
    companyId: string | null;
    reportType: GQExternalReportType;
    publishingYear: number;
    publicUrl: string;
    gsUrl: string | null;
    createdAt: Date;
    externalScore: string | null;
    source: GQPublicDisclosureSource;
    sourceRecordId: string | null;
    updatedAt: Date;
    qualityScore: GQDisclosureQualityScore | null;
  };
};

export type GQUserUploadedTableForSourceFileDataLineageFragment = {
  __typename?: 'UserUploadedTable';
  id: string;
  sheetName: string | null;
  numRows: number | null;
  schema: Array<{
    __typename?: 'UserUploadedTableSchemaColumn';
    index: number;
    kind: GQUserUploadedTableSchemaColumnKind;
    name: string;
    alias: string;
  }> | null;
  userVisibleAttempt: {
    __typename?: 'UserUploadAttempt';
    id: string;
    name: string;
    category: GQFileCategory;
    createdAt: Date;
    size: number;
    uploader: { __typename?: 'User'; id: string; name: string } | null;
    userUpload: {
      __typename?: 'UserUpload';
      id: string;
      revisionRoot: string | null;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
        deletedAt: Date | null;
      } | null;
      userUploadTask: {
        __typename?: 'UserUploadTask';
        id: string;
        datasource: { __typename?: 'Datasource'; id: string; name: string };
      } | null;
    };
  } | null;
};

export type GQUutRowsDataLineageFragment = {
  __typename?: 'UutRowsDataLineage';
  fileMetadataId: string;
  rows: Array<any>;
  userUploadedTable: {
    __typename?: 'UserUploadedTable';
    id: string;
    sheetName: string | null;
    numRows: number | null;
    schema: Array<{
      __typename?: 'UserUploadedTableSchemaColumn';
      index: number;
      kind: GQUserUploadedTableSchemaColumnKind;
      name: string;
      alias: string;
    }> | null;
    userVisibleAttempt: {
      __typename?: 'UserUploadAttempt';
      id: string;
      name: string;
      category: GQFileCategory;
      createdAt: Date;
      size: number;
      uploader: { __typename?: 'User'; id: string; name: string } | null;
      userUpload: {
        __typename?: 'UserUpload';
        id: string;
        revisionRoot: string | null;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          name: string;
          deletedAt: Date | null;
        } | null;
        userUploadTask: {
          __typename?: 'UserUploadTask';
          id: string;
          datasource: { __typename?: 'Datasource'; id: string; name: string };
        } | null;
      };
    } | null;
  };
};

export type GQUserUploadsByIdForParsedFilePreviewQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQUserUploadsByIdForParsedFilePreviewQuery = {
  __typename?: 'Query';
  userUploadsById: Array<{
    __typename?: 'UserUpload';
    id: string;
    name: string;
    category: GQFileCategory;
    createdAt: Date;
    processingMode: GQUserUploadProcessingMode | null;
    revisionRoot: string | null;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      deletedAt: Date | null;
    } | null;
    uploader: { __typename?: 'User'; id: string; name: string } | null;
    userVisibleAttempt: {
      __typename?: 'UserUploadAttempt';
      id: string;
      name: string;
      createdAt: Date;
      size: number;
      description: string | null;
      remoteWritten: boolean;
      uploader: { __typename?: 'User'; id: string; name: string } | null;
      userUploadedTables: Array<{
        __typename?: 'UserUploadedTable';
        extBartId: string | null;
        businessActivityType: string | null;
        id: string;
        sheetName: string | null;
        sheetIndex: number | null;
        errorMessage: string | null;
        errorType: string | null;
        numRows: number | null;
        status: GQUserUploadedTableStatus;
        schema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        uirSchema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        parseConfig: {
          __typename?: 'UserUploadedTableParseConfig';
          noHeader: boolean;
          skipTopNRows: number;
          skipBottomNRows: number;
          meltIdVars: Array<string | null> | null;
        } | null;
        roboCleanerResult: {
          __typename?: 'UserUploadedTableRoboCleanerResult';
          skipTopNRows: number;
        } | null;
        userReviewMetadata: {
          __typename?: 'UserUploadedTableUserReviewMetadata';
          headerRowIndex: number;
        } | null;
        parentFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
        };
        dataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataUnshiftedPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
      }>;
      latestUutsIfRevisionExists: Array<{
        __typename?: 'UserUploadedTable';
        extBartId: string | null;
        businessActivityType: string | null;
        id: string;
        sheetName: string | null;
        sheetIndex: number | null;
        errorMessage: string | null;
        errorType: string | null;
        numRows: number | null;
        status: GQUserUploadedTableStatus;
        schema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        uirSchema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        parseConfig: {
          __typename?: 'UserUploadedTableParseConfig';
          noHeader: boolean;
          skipTopNRows: number;
          skipBottomNRows: number;
          meltIdVars: Array<string | null> | null;
        } | null;
        roboCleanerResult: {
          __typename?: 'UserUploadedTableRoboCleanerResult';
          skipTopNRows: number;
        } | null;
        userReviewMetadata: {
          __typename?: 'UserUploadedTableUserReviewMetadata';
          headerRowIndex: number;
        } | null;
        parentFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
        };
        dataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataUnshiftedPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
      }> | null;
    } | null;
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      datasource: { __typename?: 'Datasource'; id: string; name: string };
    } | null;
  }>;
};

export type GQExtBartRowsDataLineageFragment = {
  __typename?: 'FileDataLineage';
  batType: string | null;
  fileMetadataId: string;
  rows: Array<any>;
};

export type GQSelectedCalculationDataLineageFragment = {
  __typename?: 'CalculationDataLineagePayload';
  fileMetadataIds: Array<string> | null;
  isTruncated: boolean;
  extBartRows: Array<{
    __typename?: 'FileDataLineage';
    batType: string | null;
    fileMetadataId: string;
    rows: Array<any>;
  }> | null;
  uutRows: Array<{
    __typename?: 'UutRowsDataLineage';
    fileMetadataId: string;
    rows: Array<any>;
    userUploadedTable: {
      __typename?: 'UserUploadedTable';
      id: string;
      sheetName: string | null;
      numRows: number | null;
      schema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      userVisibleAttempt: {
        __typename?: 'UserUploadAttempt';
        id: string;
        name: string;
        category: GQFileCategory;
        createdAt: Date;
        size: number;
        uploader: { __typename?: 'User'; id: string; name: string } | null;
        userUpload: {
          __typename?: 'UserUpload';
          id: string;
          revisionRoot: string | null;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
            deletedAt: Date | null;
          } | null;
          userUploadTask: {
            __typename?: 'UserUploadTask';
            id: string;
            datasource: { __typename?: 'Datasource'; id: string; name: string };
          } | null;
        };
      } | null;
    };
  }> | null;
};

export type GQBiCalculationDataLineageQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
  fingerprint: InputMaybe<Scalars['String']['input']>;
  emissionsModelEvaluationId: InputMaybe<Scalars['String']['input']>;
  conversionFactorId: InputMaybe<Scalars['String']['input']>;
}>;

export type GQBiCalculationDataLineageQuery = {
  __typename?: 'Query';
  biCalculationDataLineage: {
    __typename?: 'CalculationDataLineagePayload';
    fileMetadataIds: Array<string> | null;
    isTruncated: boolean;
    extBartRows: Array<{
      __typename?: 'FileDataLineage';
      batType: string | null;
      fileMetadataId: string;
      rows: Array<any>;
    }> | null;
    uutRows: Array<{
      __typename?: 'UutRowsDataLineage';
      fileMetadataId: string;
      rows: Array<any>;
      userUploadedTable: {
        __typename?: 'UserUploadedTable';
        id: string;
        sheetName: string | null;
        numRows: number | null;
        schema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        userVisibleAttempt: {
          __typename?: 'UserUploadAttempt';
          id: string;
          name: string;
          category: GQFileCategory;
          createdAt: Date;
          size: number;
          uploader: { __typename?: 'User'; id: string; name: string } | null;
          userUpload: {
            __typename?: 'UserUpload';
            id: string;
            revisionRoot: string | null;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
              deletedAt: Date | null;
            } | null;
            userUploadTask: {
              __typename?: 'UserUploadTask';
              id: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
              };
            } | null;
          };
        } | null;
      };
    }> | null;
  };
};

export type GQActivityDataDataLineageQueryVariables = Exact<{
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
  queryContext: GQBiQueryContextInput;
}>;

export type GQActivityDataDataLineageQuery = {
  __typename?: 'Query';
  activityDataDataLineage: {
    __typename?: 'CalculationDataLineagePayload';
    fileMetadataIds: Array<string> | null;
    isTruncated: boolean;
    extBartRows: Array<{
      __typename?: 'FileDataLineage';
      batType: string | null;
      fileMetadataId: string;
      rows: Array<any>;
    }> | null;
    uutRows: Array<{
      __typename?: 'UutRowsDataLineage';
      fileMetadataId: string;
      rows: Array<any>;
      userUploadedTable: {
        __typename?: 'UserUploadedTable';
        id: string;
        sheetName: string | null;
        numRows: number | null;
        schema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        userVisibleAttempt: {
          __typename?: 'UserUploadAttempt';
          id: string;
          name: string;
          category: GQFileCategory;
          createdAt: Date;
          size: number;
          uploader: { __typename?: 'User'; id: string; name: string } | null;
          userUpload: {
            __typename?: 'UserUpload';
            id: string;
            revisionRoot: string | null;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
              deletedAt: Date | null;
            } | null;
            userUploadTask: {
              __typename?: 'UserUploadTask';
              id: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
              };
            } | null;
          };
        } | null;
      };
    }> | null;
  };
};

export type GQSingleRowMethodologyExplainerFieldsFragment = {
  __typename?: 'SingleRowMethodologyExplainer';
  id: string;
  files: Array<{
    __typename?: 'FootprintAnalysisFile';
    id: string;
    name: string;
    category: GQFileCategory;
    createdAt: Date;
    uploader: { __typename?: 'User'; id: string; name: string } | null;
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      datasource: { __typename?: 'Datasource'; id: string; name: string };
    } | null;
  }>;
  pipelineV2Chain: {
    __typename: 'CalculationChain';
    inputQuantity: number | null;
    inputUnit: string | null;
    convertedQuantity: number;
    tco2eQuantity: number;
    tco2eLocationQuantity: number;
    sumOfMeasure: number;
    emissionsModelVersionId: string | null;
    conversionFactor: {
      __typename?: 'ConversionFactorForDashboard';
      id: string;
      conversionFactorId: string;
      inputUnit: string;
      outputUnit: string;
      conversionChain: Array<
        | {
            __typename: 'AssumptionRecordForDashboard';
            id: string;
            conversionDescription: string | null;
            conversionMultiplier: number | null;
            inputUnit: string | null;
            outputUnit: string | null;
            assumptionSource: {
              __typename?: 'AssumptionSource';
              id: string;
              externalSourceNotes: string | null;
              userVisibleSourceUrl: string | null;
              hasMultiplierLicenseRestrictions: boolean | null;
            };
            assumptionDescription: {
              __typename?: 'AssumptionDescription';
              id: string;
              updatedAt: Date;
              assumptionId: string;
              userVisibleConversionDescription: string;
            } | null;
          }
        | {
            __typename: 'UnitConversionRecord';
            assumptionId: string | null;
            assumptionUuid: string | null;
            conversionDescription: string | null;
            conversionMultiplier: number | null;
            inputUnit: string | null;
            outputUnit: string | null;
          }
      > | null;
    } | null;
    emissionsFactor: {
      __typename?: 'EmissionsFactorRecordForDashboard';
      id: string;
      emissionsFactorId: string;
      kgCo2e: number | null;
      unit: string | null;
      efName: string | null;
      isVendorSpecific: boolean | null;
      startMonth: YearMonth;
      endMonth: YearMonth;
      assumptionSource: {
        __typename?: 'AssumptionSource';
        id: string;
        externalSourceNotes: string | null;
        userVisibleSourceUrl: string | null;
        hasMultiplierLicenseRestrictions: boolean | null;
      } | null;
      emissionsFactorDescription: {
        __typename?: 'EmissionsFactorDescription';
        id: string;
        updatedAt: Date;
        emissionsFactorId: string;
        userVisibleEfName: string;
      } | null;
      supplierDisclosure: {
        __typename?: 'Disclosure';
        id: string;
        qualityScore: GQDisclosureQualityScore | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          reportingYear: number;
        }> | null;
        privateDisclosure: {
          __typename?: 'PrivateDisclosure';
          id: string;
          privateDisclosureType: GQPrivateDisclosureType;
        } | null;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          reportType: GQExternalReportType;
          publicUrl: string;
        } | null;
        climateCommitments: Array<
          | {
              __typename?: 'CarbonNeutralCommitment';
              targetYear: number | null;
              id: string;
              externalUrl: string | null;
              kind: GQCompanyClimateCommitmentKind;
            }
          | {
              __typename?: 'CleanEnergyCommitment';
              id: string;
              externalUrl: string | null;
              kind: GQCompanyClimateCommitmentKind;
            }
          | {
              __typename?: 'NetZeroCommitment';
              targetYear: number | null;
              id: string;
              externalUrl: string | null;
              kind: GQCompanyClimateCommitmentKind;
            }
          | {
              __typename?: 'ScienceBasedTargetCommitment';
              id: string;
              externalUrl: string | null;
              kind: GQCompanyClimateCommitmentKind;
              commitment: {
                __typename?: 'SBTCommitment';
                id: string;
                stage: GQSbtCommitmentStage | null;
                targetClassification: GQSbtTargetClassification | null;
              };
            }
        > | null;
      } | null;
    } | null;
    locationEmissionsFactor: {
      __typename?: 'EmissionsFactorRecordForDashboard';
      id: string;
      emissionsFactorId: string;
      kgCo2e: number | null;
      unit: string | null;
      efName: string | null;
      isVendorSpecific: boolean | null;
      startMonth: YearMonth;
      endMonth: YearMonth;
      assumptionSource: {
        __typename?: 'AssumptionSource';
        id: string;
        externalSourceNotes: string | null;
        userVisibleSourceUrl: string | null;
        hasMultiplierLicenseRestrictions: boolean | null;
      } | null;
      emissionsFactorDescription: {
        __typename?: 'EmissionsFactorDescription';
        id: string;
        updatedAt: Date;
        emissionsFactorId: string;
        userVisibleEfName: string;
      } | null;
      supplierDisclosure: {
        __typename?: 'Disclosure';
        id: string;
        qualityScore: GQDisclosureQualityScore | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          reportingYear: number;
        }> | null;
        privateDisclosure: {
          __typename?: 'PrivateDisclosure';
          id: string;
          privateDisclosureType: GQPrivateDisclosureType;
        } | null;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          reportType: GQExternalReportType;
          publicUrl: string;
        } | null;
        climateCommitments: Array<
          | {
              __typename?: 'CarbonNeutralCommitment';
              targetYear: number | null;
              id: string;
              externalUrl: string | null;
              kind: GQCompanyClimateCommitmentKind;
            }
          | {
              __typename?: 'CleanEnergyCommitment';
              id: string;
              externalUrl: string | null;
              kind: GQCompanyClimateCommitmentKind;
            }
          | {
              __typename?: 'NetZeroCommitment';
              targetYear: number | null;
              id: string;
              externalUrl: string | null;
              kind: GQCompanyClimateCommitmentKind;
            }
          | {
              __typename?: 'ScienceBasedTargetCommitment';
              id: string;
              externalUrl: string | null;
              kind: GQCompanyClimateCommitmentKind;
              commitment: {
                __typename?: 'SBTCommitment';
                id: string;
                stage: GQSbtCommitmentStage | null;
                targetClassification: GQSbtTargetClassification | null;
              };
            }
        > | null;
      } | null;
    } | null;
  } | null;
  marketTrace: {
    __typename?: 'SingleRowTrace';
    id: string;
    trace: any;
    ghgCategoryId: string;
    traceDetails: {
      __typename?: 'TraceDetails';
      referenceValueDetails: Array<any>;
      emissionsModelVariableDetails: Array<any>;
      bartValueDetails: Array<any>;
      emissionsModelVersionId: string | null;
      emissionsModelEvaluationId: string | null;
    };
  } | null;
  locationTrace: {
    __typename?: 'SingleRowTrace';
    id: string;
    trace: any;
    ghgCategoryId: string;
    traceDetails: {
      __typename?: 'TraceDetails';
      referenceValueDetails: Array<any>;
      emissionsModelVariableDetails: Array<any>;
      bartValueDetails: Array<any>;
      emissionsModelVersionId: string | null;
      emissionsModelEvaluationId: string | null;
    };
  } | null;
  biogenicCo2Trace: {
    __typename?: 'SingleRowTrace';
    id: string;
    trace: any;
    ghgCategoryId: string;
    traceDetails: {
      __typename?: 'TraceDetails';
      referenceValueDetails: Array<any>;
      emissionsModelVariableDetails: Array<any>;
      bartValueDetails: Array<any>;
      emissionsModelVersionId: string | null;
      emissionsModelEvaluationId: string | null;
    };
  } | null;
  biogenicCo2LocationTrace: {
    __typename?: 'SingleRowTrace';
    id: string;
    trace: any;
    ghgCategoryId: string;
    traceDetails: {
      __typename?: 'TraceDetails';
      referenceValueDetails: Array<any>;
      emissionsModelVariableDetails: Array<any>;
      bartValueDetails: Array<any>;
      emissionsModelVersionId: string | null;
      emissionsModelEvaluationId: string | null;
    };
  } | null;
  cleanPowerAvoidedEmissionsTrace: {
    __typename?: 'SingleRowTrace';
    id: string;
    trace: any;
    ghgCategoryId: string;
    traceDetails: {
      __typename?: 'TraceDetails';
      referenceValueDetails: Array<any>;
      emissionsModelVariableDetails: Array<any>;
      bartValueDetails: Array<any>;
      emissionsModelVersionId: string | null;
      emissionsModelEvaluationId: string | null;
    };
  } | null;
  wasteEmissionsTrace: {
    __typename?: 'SingleRowTrace';
    id: string;
    trace: any;
    ghgCategoryId: string;
    traceDetails: {
      __typename?: 'TraceDetails';
      referenceValueDetails: Array<any>;
      emissionsModelVariableDetails: Array<any>;
      bartValueDetails: Array<any>;
      emissionsModelVersionId: string | null;
      emissionsModelEvaluationId: string | null;
    };
  } | null;
  microplasticsInflowEmissionsTrace: {
    __typename?: 'SingleRowTrace';
    id: string;
    trace: any;
    ghgCategoryId: string;
    traceDetails: {
      __typename?: 'TraceDetails';
      referenceValueDetails: Array<any>;
      emissionsModelVariableDetails: Array<any>;
      bartValueDetails: Array<any>;
      emissionsModelVersionId: string | null;
      emissionsModelEvaluationId: string | null;
    };
  } | null;
  microplasticsOutflowEmissionsTrace: {
    __typename?: 'SingleRowTrace';
    id: string;
    trace: any;
    ghgCategoryId: string;
    traceDetails: {
      __typename?: 'TraceDetails';
      referenceValueDetails: Array<any>;
      emissionsModelVariableDetails: Array<any>;
      bartValueDetails: Array<any>;
      emissionsModelVersionId: string | null;
      emissionsModelEvaluationId: string | null;
    };
  } | null;
  substanceInflowEmissionsTrace: {
    __typename?: 'SingleRowTrace';
    id: string;
    trace: any;
    ghgCategoryId: string;
    traceDetails: {
      __typename?: 'TraceDetails';
      referenceValueDetails: Array<any>;
      emissionsModelVariableDetails: Array<any>;
      bartValueDetails: Array<any>;
      emissionsModelVersionId: string | null;
      emissionsModelEvaluationId: string | null;
    };
  } | null;
  substanceOutflowEmissionsTrace: {
    __typename?: 'SingleRowTrace';
    id: string;
    trace: any;
    ghgCategoryId: string;
    traceDetails: {
      __typename?: 'TraceDetails';
      referenceValueDetails: Array<any>;
      emissionsModelVariableDetails: Array<any>;
      bartValueDetails: Array<any>;
      emissionsModelVersionId: string | null;
      emissionsModelEvaluationId: string | null;
    };
  } | null;
  waterConsumptionEmissionsTrace: {
    __typename?: 'SingleRowTrace';
    id: string;
    trace: any;
    ghgCategoryId: string;
    traceDetails: {
      __typename?: 'TraceDetails';
      referenceValueDetails: Array<any>;
      emissionsModelVariableDetails: Array<any>;
      bartValueDetails: Array<any>;
      emissionsModelVersionId: string | null;
      emissionsModelEvaluationId: string | null;
    };
  } | null;
  footprintExclusionRule: {
    __typename?: 'FootprintExclusionRule';
    id: string;
    stableId: string;
    deduplicationNote: string | null;
    outputValue: number;
  } | null;
};

export type GQCreateFootprintMutationVariables = Exact<{
  input: GQCreateFootprintInput;
}>;

export type GQCreateFootprintMutation = {
  __typename?: 'Mutation';
  createFootprint: {
    __typename?: 'CreateFootprintPayload';
    footprint: {
      __typename?: 'FootprintTemp';
      id: string;
      name: string;
      orgId: string;
      locked: boolean;
      createdAt: Date;
      deletedAt: Date | null;
      updatedAt: Date;
      status: GQFootprintStatus;
      createdBy: {
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      } | null;
      defaultFootprintSnapshot: {
        __typename?: 'FootprintSnapshot';
        id: string;
        footprintInterval: YMInterval;
        isLatest: boolean;
      } | null;
    };
  };
};

export type GQRenameFootprintMutationVariables = Exact<{
  input: GQRenameFootprintInput;
}>;

export type GQRenameFootprintMutation = {
  __typename?: 'Mutation';
  renameFootprint: {
    __typename?: 'RenameFootprintPayload';
    footprint: {
      __typename?: 'FootprintTemp';
      id: string;
      name: string;
      orgId: string;
      locked: boolean;
      createdAt: Date;
      deletedAt: Date | null;
      updatedAt: Date;
      status: GQFootprintStatus;
      createdBy: {
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      } | null;
      defaultFootprintSnapshot: {
        __typename?: 'FootprintSnapshot';
        id: string;
        footprintInterval: YMInterval;
        isLatest: boolean;
      } | null;
    };
  };
};

export type GQFootprintVersionForProductsFragment = {
  __typename?: 'FootprintVersion';
  id: string;
  kind: GQFootprintVersionKind;
  createdAt: Date;
  originalFootprintSnapshotId: string;
};

export type GQProductsAndMaterialFootprintQueryVariables = Exact<{
  footprintVersion: InputMaybe<Scalars['String']['input']>;
}>;

export type GQProductsAndMaterialFootprintQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    footprintInterval: YMInterval | null;
    footprintKinds: Array<string>;
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      kind: GQFootprintVersionKind;
      createdAt: Date;
      originalFootprintSnapshotId: string;
    } | null;
  } | null;
};

export type GQGetFootprintKindsWithProductsOrMaterialsQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
}>;

export type GQGetFootprintKindsWithProductsOrMaterialsQuery = {
  __typename?: 'Query';
  footprintKindsWithProductsOrMaterials: Array<{
    __typename?: 'FootprintKindWithProductsOrMaterials';
    footprintKind: string;
  }> | null;
};

export type GQMaterialsUpdatedTaxonomyQueryVariables = Exact<{
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  orgMaterial: InputMaybe<Scalars['String']['input']>;
  orgRawMaterial: InputMaybe<Scalars['String']['input']>;
  groupByMonth: Scalars['Boolean']['input'];
  dateInterval: InputMaybe<Scalars['YMInterval']['input']>;
  footprintKind: InputMaybe<Scalars['String']['input']>;
}>;

export type GQMaterialsUpdatedTaxonomyQuery = {
  __typename?: 'Query';
  materialsUpdatedTaxonomy: Array<{
    __typename?: 'MaterialFootprintRowUpdatedTaxonomy';
    id: string;
    orgMaterial: string;
    orgRawMaterial: string;
    emissionsPerKg: number | null;
    inputQuantity: number;
    inputQuantityPercentage: number | null;
    totalEmissions: number;
    totalEmissionsPercent: number | null;
    product: Array<{
      __typename?: 'ProductFootprintRowReference';
      id: string;
      product: string;
      totalEmissions: number;
    }>;
    vectors: Array<{
      __typename?: 'VectorNameAndValues';
      vectorName: string;
      vectorValues: Array<string> | null;
    }> | null;
  }> | null;
};

export type GQProductsUpdatedTaxonomyQueryVariables = Exact<{
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  product: InputMaybe<Scalars['String']['input']>;
  groupByMonth: Scalars['Boolean']['input'];
  dateInterval: InputMaybe<Scalars['YMInterval']['input']>;
  footprintKind: InputMaybe<Scalars['String']['input']>;
}>;

export type GQProductsUpdatedTaxonomyQuery = {
  __typename?: 'Query';
  productsUpdatedTaxonomy: Array<{
    __typename?: 'ProductFootprintRowUpdatedTaxonomy';
    id: string;
    product: string;
    emissionsPerUnit: number | null;
    unitsProduced: number;
    unitsProducedPercentage: number | null;
    totalEmissions: number;
    totalEmissionsPercent: number | null;
    categoryEmissionsPercent: number | null;
    vectors: Array<{
      __typename?: 'VectorNameAndValues';
      vectorName: string;
      vectorValues: Array<string> | null;
    }> | null;
    relatedMaterials: Array<{
      __typename?: 'MaterialFootprintRowReference';
      id: string;
      material: string;
    }>;
  }> | null;
};

export type GQEmissionsSliceMaterialFootprintRowUpdatedTaxonomyFragment = {
  __typename?: 'MaterialFootprintRowUpdatedTaxonomy';
  id: string;
  orgMaterial: string;
  emissionsPerKg: number | null;
  inputQuantity: number;
  totalEmissions: number;
  totalEmissionsPercent: number | null;
  vectors: Array<{
    __typename?: 'VectorNameAndValues';
    vectorName: string;
    vectorValues: Array<string> | null;
  }> | null;
  product: Array<{
    __typename?: 'ProductFootprintRowReference';
    id: string;
    product: string;
    totalEmissions: number;
  }>;
};

export type GQEmissionsSliceMaterialPageDataUpdatedTaxonomyQueryVariables =
  Exact<{
    footprintVersion: InputMaybe<Scalars['String']['input']>;
    footprintKind: InputMaybe<Scalars['String']['input']>;
    orgMaterial: Scalars['String']['input'];
    orgRawMaterial: InputMaybe<Scalars['String']['input']>;
  }>;

export type GQEmissionsSliceMaterialPageDataUpdatedTaxonomyQuery = {
  __typename?: 'Query';
  items: Array<{
    __typename?: 'MaterialFootprintRowUpdatedTaxonomy';
    id: string;
    orgMaterial: string;
    emissionsPerKg: number | null;
    inputQuantity: number;
    totalEmissions: number;
    totalEmissionsPercent: number | null;
    vectors: Array<{
      __typename?: 'VectorNameAndValues';
      vectorName: string;
      vectorValues: Array<string> | null;
    }> | null;
    product: Array<{
      __typename?: 'ProductFootprintRowReference';
      id: string;
      product: string;
      totalEmissions: number;
    }>;
  }> | null;
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    footprintInterval: YMInterval | null;
  } | null;
};

export type GQEmissionsSliceProductFootprintRowUpdatedTaxonomyFragment = {
  __typename?: 'ProductFootprintRowUpdatedTaxonomy';
  id: string;
  product: string;
  emissionsPerUnit: number | null;
  unitsProduced: number;
  totalEmissions: number;
  totalEmissionsPercent: number | null;
  categoryEmissionsPercent: number | null;
  vectors: Array<{
    __typename?: 'VectorNameAndValues';
    vectorName: string;
    vectorValues: Array<string> | null;
  }> | null;
  relatedMaterials: Array<{
    __typename?: 'MaterialFootprintRowReference';
    id: string;
    material: string;
    percentageOfProduct: number;
  }>;
};

export type GQEmissionsSliceProductPageDataUpdatedTaxonomyQueryVariables =
  Exact<{
    footprintVersion: InputMaybe<Scalars['String']['input']>;
    footprintKind: InputMaybe<Scalars['String']['input']>;
    productId: Scalars['String']['input'];
  }>;

export type GQEmissionsSliceProductPageDataUpdatedTaxonomyQuery = {
  __typename?: 'Query';
  items: Array<{
    __typename?: 'ProductFootprintRowUpdatedTaxonomy';
    id: string;
    product: string;
    emissionsPerUnit: number | null;
    unitsProduced: number;
    totalEmissions: number;
    totalEmissionsPercent: number | null;
    categoryEmissionsPercent: number | null;
    vectors: Array<{
      __typename?: 'VectorNameAndValues';
      vectorName: string;
      vectorValues: Array<string> | null;
    }> | null;
    relatedMaterials: Array<{
      __typename?: 'MaterialFootprintRowReference';
      id: string;
      material: string;
      percentageOfProduct: number;
    }>;
  }> | null;
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    footprintInterval: YMInterval | null;
  } | null;
};

export type GQEvaluatedEmissionsSliceDataQueryVariables = Exact<{
  footprintVersion: InputMaybe<Scalars['String']['input']>;
  footprintKind: InputMaybe<Scalars['String']['input']>;
  orgMaterial: InputMaybe<Scalars['String']['input']>;
  orgRawMaterial: InputMaybe<Scalars['String']['input']>;
  productId: InputMaybe<Scalars['String']['input']>;
}>;

export type GQEvaluatedEmissionsSliceDataQuery = {
  __typename?: 'Query';
  evaluatedEmissionsModels: {
    __typename?: 'EvaluatedEmissionsModelsResults';
    emissionsModelVersions: Array<{
      __typename?: 'EmissionsModelVersion';
      id: string;
      description: string;
      emissionsModelJson: any;
      importedDescendants: Array<{
        __typename?: 'EmissionsModelVersion';
        id: string;
        title: string;
        description: string;
        changelog: string;
        createdAt: Date;
        schemaVersion: string;
        emissionsModelJson: any;
        parameterResolverJson: any;
        stableModel: {
          __typename?: 'EmissionsModelStable';
          id: string;
          latestPublishedVersion: {
            __typename?: 'EmissionsModelVersion';
            id: string;
            changelog: string;
            createdAt: Date;
          } | null;
        };
      }>;
    }>;
  };
};

export type GQFootprintFieldsFragment = {
  __typename?: 'FootprintTemp';
  id: string;
  name: string;
  orgId: string;
  locked: boolean;
  createdAt: Date;
  deletedAt: Date | null;
  updatedAt: Date;
  status: GQFootprintStatus;
  createdBy: {
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
  } | null;
  defaultFootprintSnapshot: {
    __typename?: 'FootprintSnapshot';
    id: string;
    footprintInterval: YMInterval;
    isLatest: boolean;
  } | null;
};

export type GQFootprintQueryVariables = Exact<{
  footprintId: InputMaybe<Scalars['String']['input']>;
}>;

export type GQFootprintQuery = {
  __typename?: 'Query';
  footprintTemp: {
    __typename?: 'FootprintTemp';
    id: string;
    name: string;
    defaultFootprintSnapshot: {
      __typename?: 'FootprintSnapshot';
      id: string;
    } | null;
    latestPublishedFootprintSnapshot: {
      __typename?: 'FootprintSnapshot';
      id: string;
    } | null;
    latestDraftFootprintSnapshot: {
      __typename?: 'FootprintSnapshot';
      id: string;
    } | null;
  };
};

export type GQFootprintsQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
}>;

export type GQFootprintsQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  footprints: {
    __typename?: 'FootprintConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
    edges: Array<{
      __typename?: 'FootprintEdge';
      node: {
        __typename?: 'FootprintTemp';
        id: string;
        name: string;
        orgId: string;
        locked: boolean;
        createdAt: Date;
        deletedAt: Date | null;
        updatedAt: Date;
        status: GQFootprintStatus;
        createdBy: {
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        } | null;
        defaultFootprintSnapshot: {
          __typename?: 'FootprintSnapshot';
          id: string;
          footprintInterval: YMInterval;
          isLatest: boolean;
        } | null;
      } | null;
    } | null>;
  };
};

export type GQCancelFootprintReviewRequestMutationVariables = Exact<{
  input: GQDeleteWatershedFootprintReviewRequestInput;
}>;

export type GQCancelFootprintReviewRequestMutation = {
  __typename?: 'Mutation';
  deleteWatershedFootprintReviewRequest: {
    __typename?: 'DeleteWatershedFootprintReviewRequestPayload';
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      watershedFootprintReviewRequest: {
        __typename?: 'WatershedFootprintReviewRequest';
        id: string;
      } | null;
    };
    discussion: {
      __typename?: 'Discussion';
      id: string;
      title: string;
      description: string | null;
      state: GQDataIssueState;
      createdAt: Date;
      isCustomerInitiated: boolean;
      anchor: {
        __typename?: 'DiscussionAnchorPayload';
        userUploadTaskId: string | null;
        valueMappingTaskId: string | null;
        measurementVerificationItemQuestionId: string | null;
        financialsReviewItemId: string | null;
        productSection: GQDiscussionProductSection | null;
      };
      comments: {
        __typename?: 'CommentConnection';
        edges: Array<{
          __typename?: 'CommentEdge';
          node: {
            __typename?: 'Comment';
            id: string;
            message: string;
            createdAt: Date;
            person:
              | {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                  displayName: string;
                }
              | {
                  __typename?: 'WatershedEmployee';
                  id: string;
                  name: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                  displayName: string;
                }
              | null;
          } | null;
        } | null>;
      };
    };
  };
};

export type GQCreateWatershedFootprintReviewRequestMutationVariables = Exact<{
  footprintVersionId: Scalars['ID']['input'];
}>;

export type GQCreateWatershedFootprintReviewRequestMutation = {
  __typename?: 'Mutation';
  createWatershedFootprintReviewRequest: {
    __typename?: 'CreateWatershedFootprintReviewRequestPayload';
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      watershedFootprintReviewRequest: {
        __typename?: 'WatershedFootprintReviewRequest';
        id: string;
        createdAt: Date;
        status: GQWatershedFootprintReviewRequestStatus;
        user: { __typename?: 'User'; id: string; displayName: string };
        discussion: { __typename?: 'Discussion'; id: string } | null;
      } | null;
    };
    discussionThreads: {
      __typename?: 'DiscussionsPayload';
      id: string;
      discussions: Array<{
        __typename?: 'Discussion';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        createdAt: Date;
        isCustomerInitiated: boolean;
        anchor: {
          __typename?: 'DiscussionAnchorPayload';
          userUploadTaskId: string | null;
          valueMappingTaskId: string | null;
          measurementVerificationItemQuestionId: string | null;
          financialsReviewItemId: string | null;
          productSection: GQDiscussionProductSection | null;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              createdAt: Date;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                    displayName: string;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                    displayName: string;
                  }
                | null;
            } | null;
          } | null>;
        };
      }>;
    };
  };
};

export type GQUpdateFootprintReviewRequestStatusMutationVariables = Exact<{
  input: GQUpdateWatershedFootprintReviewRequestStatusInput;
}>;

export type GQUpdateFootprintReviewRequestStatusMutation = {
  __typename?: 'Mutation';
  updateWatershedFootprintReviewRequestStatus: {
    __typename?: 'UpdateWatershedFootprintReviewRequestStatusPayload';
    watershedFootprintReviewRequest: {
      __typename?: 'WatershedFootprintReviewRequest';
      id: string;
      status: GQWatershedFootprintReviewRequestStatus;
      discussion: {
        __typename?: 'Discussion';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        createdAt: Date;
        isCustomerInitiated: boolean;
        anchor: {
          __typename?: 'DiscussionAnchorPayload';
          userUploadTaskId: string | null;
          valueMappingTaskId: string | null;
          measurementVerificationItemQuestionId: string | null;
          financialsReviewItemId: string | null;
          productSection: GQDiscussionProductSection | null;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              createdAt: Date;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                    displayName: string;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                    displayName: string;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
    };
  };
};

export type GQEngagementFunnelsProgressTrackerFieldsFragment = {
  __typename?: 'EngagementFunnel';
  id: string;
  name: string;
  displayOnSupplierDetailsPage: boolean | null;
  steps: Array<{
    __typename?: 'EngagementFunnelStep';
    id: string;
    stepName: string;
    stepType: GQEngagementFunnelStepType;
    sbtStage: GQCompanySbtCommitmentStage | null;
    engagementTaskConfigId: string | null;
    supplierTableColumnValues: Array<string> | null;
    supplierTableColumn: {
      __typename?: 'SupplierTableColumn';
      id: string;
      field: string;
    } | null;
  }>;
};

export type GQEngagementFunnelStepFieldsFragment = {
  __typename?: 'EngagementFunnelStep';
  id: string;
  stepName: string;
  stepType: GQEngagementFunnelStepType;
  sbtStage: GQCompanySbtCommitmentStage | null;
  engagementTaskConfigId: string | null;
  supplierTableColumnValues: Array<string> | null;
  supplierTableColumn: {
    __typename?: 'SupplierTableColumn';
    id: string;
    field: string;
  } | null;
};

export type GQEngagementFunnelsChartFieldsFragment = {
  __typename?: 'EngagementFunnel';
  id: string;
  name: string;
  steps: Array<{
    __typename?: 'EngagementFunnelStep';
    id: string;
    stepName: string;
    stepType: GQEngagementFunnelStepType;
    sbtStage: GQCompanySbtCommitmentStage | null;
    engagementTaskConfigId: string | null;
    supplierTableColumnValues: Array<string> | null;
    supplierTableColumn: {
      __typename?: 'SupplierTableColumn';
      id: string;
      field: string;
    } | null;
  }>;
};

export type GQRequestSupplyChainHelpMutationVariables = Exact<{
  input: GQRequestHelpInput;
}>;

export type GQRequestSupplyChainHelpMutation = {
  __typename?: 'Mutation';
  requestSupplyChainHelp: { __typename?: 'RequestHelpPayload'; status: string };
};

export type GQSupplierChartConfigFieldsForMetricsChartFragment = {
  __typename?: 'SupplierMetricsChartConfig';
  id: string;
  title: string;
  metricsTypes: Array<GQSupplyChainMetricType>;
};

export type GQEngagementTaskQuestionsForBaseConfigIdQueryVariables = Exact<{
  baseConfigId: Scalars['ID']['input'];
  excludeComplex: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQEngagementTaskQuestionsForBaseConfigIdQuery = {
  __typename?: 'Query';
  engagementTaskQuestionsForBaseConfigId: Array<{
    __typename?: 'EngagementTaskQuestionSelectOption';
    questionKey: string;
    description: string | null;
    engagementTaskConfigId: string;
    engagementTaskConfigName: string;
    taskConfigsWithQuestion: Array<string>;
    componentId: GQEngagementTaskQuestionComponentId;
  }>;
};

export type GQSupplierAttachmentFieldsFragment = {
  __typename?: 'SupplierAttachment';
  id: string;
  fileMetadataId: string;
  fileName: string;
  companyId: string;
  isImported: boolean;
  crossOrgModelId: string | null;
};

export type GQGetSupplierAttachmentsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type GQGetSupplierAttachmentsQuery = {
  __typename?: 'Query';
  supplierAttachments: Array<{
    __typename?: 'SupplierAttachment';
    id: string;
    fileMetadataId: string;
    fileName: string;
    companyId: string;
    isImported: boolean;
    crossOrgModelId: string | null;
  }>;
};

export type GQGetSupplierAttachmentsByCrossOrgIdsQueryVariables = Exact<{
  crossOrgModelIds:
    | Array<Scalars['String']['input']>
    | Scalars['String']['input'];
}>;

export type GQGetSupplierAttachmentsByCrossOrgIdsQuery = {
  __typename?: 'Query';
  supplierAttachmentsByCrossOrgIds: Array<{
    __typename?: 'SupplierAttachment';
    id: string;
    fileMetadataId: string;
    fileName: string;
    companyId: string;
    isImported: boolean;
    crossOrgModelId: string | null;
  }>;
};

export type GQCreateSupplierAttachmentsMutationVariables = Exact<{
  input: GQCreateSupplierAttachmentsInput;
}>;

export type GQCreateSupplierAttachmentsMutation = {
  __typename?: 'Mutation';
  createSupplierAttachments: {
    __typename?: 'CreateSupplierAttachmentsPayload';
    fileUrls: Array<{
      __typename?: 'CreateSupplierAttachmentItemPayload';
      url: string;
      attachment: {
        __typename?: 'SupplierAttachment';
        id: string;
        fileMetadataId: string;
        fileName: string;
        companyId: string;
        isImported: boolean;
        crossOrgModelId: string | null;
      };
    }>;
  } | null;
};

export type GQUpdateSupplierAttachmentMutationVariables = Exact<{
  input: GQUpdateSupplierAttachmentInput;
}>;

export type GQUpdateSupplierAttachmentMutation = {
  __typename?: 'Mutation';
  updateSupplierAttachment: {
    __typename?: 'UpdateSupplierAttachmentPayload';
    attachment: {
      __typename?: 'SupplierAttachment';
      id: string;
      fileMetadataId: string;
      fileName: string;
      companyId: string;
      isImported: boolean;
      crossOrgModelId: string | null;
    };
  } | null;
};

export type GQDeleteSupplierAttachmentMutationVariables = Exact<{
  input: GQDeleteSupplierAttachmentInput;
}>;

export type GQDeleteSupplierAttachmentMutation = {
  __typename?: 'Mutation';
  deleteSupplierAttachment: {
    __typename?: 'DeleteSupplierAttachmentPayload';
    numberDeleted: number;
  } | null;
};

export type GQGetFileDownloadUrlQueryVariables = Exact<{
  fileMetadataId: Scalars['ID']['input'];
}>;

export type GQGetFileDownloadUrlQuery = {
  __typename?: 'Query';
  supplierAttachmentDownloadUrl: string;
};

export type GQGetUsersWithSupplierPermissionQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type GQGetUsersWithSupplierPermissionQuery = {
  __typename?: 'Query';
  usersWithSupplierPermission: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
    email: string;
  }>;
};

export type GQGetDisclosureEmissionsFactorsQueryVariables = Exact<{
  publicDisclosureIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  privateDisclosureIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQGetDisclosureEmissionsFactorsQuery = {
  __typename?: 'Query';
  disclosureEmissionsFactors: Array<{
    __typename?: 'DisclosureEmissionsFactor';
    publicDisclosureId: string | null;
    privateDisclosureId: string | null;
    emissionsFactorRow: {
      __typename?: 'ReferenceDataRevisionRow';
      id: string;
      rowFields: any;
      citationName: string;
      citationNotes: string | null;
      citationUrl: string | null;
      citationSlug: string;
      isCustomerProvided: boolean | null;
    };
  }>;
};

export type GQSupplierEmissionsFactorsTableRowsQueryVariables = Exact<{
  supplierName: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
}>;

export type GQSupplierEmissionsFactorsTableRowsQuery = {
  __typename?: 'Query';
  supplierEmissionsFactorsTableRows: {
    __typename?: 'SupplierEmissionsFactorsTableRows';
    referenceDataRevisionRows: Array<{
      __typename?: 'ReferenceDataRevisionRow';
      id: string;
      rowFields: any;
      citationName: string;
      citationNotes: string | null;
      citationUrl: string | null;
      citationSlug: string;
      isCustomerProvided: boolean | null;
    }>;
  };
};

export type GQSupplierEmissionsFactorsTableMetadataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQSupplierEmissionsFactorsTableMetadataQuery = {
  __typename?: 'Query';
  supplierEmissionsFactorsTableMetadata: {
    __typename?: 'SupplierEmissionsFactorsTableMetadata';
    quantityColumnName: string;
    displayColumns: Array<{
      __typename?: 'MethodologyCustomizationColumnDisplayFormat';
      parquetColumnName: string;
      displayName: string;
      type: string;
      description: string | null;
    }>;
  };
};

export type GQSupplierDataExportMutationVariables = Exact<{
  input: GQSupplierDataExportInput;
}>;

export type GQSupplierDataExportMutation = {
  __typename?: 'Mutation';
  supplierDataExport: {
    __typename?: 'SupplierDataExportPayload';
    workflowId: string;
  };
};

export type GQSupplierFilterOptionsQueryVariables = Exact<{
  dimension: Scalars['String']['input'];
  nestedDimensions: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
}>;

export type GQSupplierFilterOptionsQuery = {
  __typename?: 'Query';
  supplierFilterOptions: Array<{
    __typename?: 'SupplierFilterOption';
    rawValue: FilterValue;
    formattedValue: string;
  }>;
};

export type GQCreateSupplierChartMutationVariables = Exact<{
  input: GQCreateSupplierChartInput;
}>;

export type GQCreateSupplierChartMutation = {
  __typename?: 'Mutation';
  createSupplierChart: {
    __typename?: 'CreateSupplierChartPayload';
    views: Array<{
      __typename?: 'SupplierView';
      id: string;
      reductionPlan: {
        __typename?: 'Plan';
        id: string;
        name: string;
        createdAt: Date;
        updatedAt: Date;
        updatedByName: string | null;
        state: GQPlanState;
        baselineYearStart: YearMonth;
        fiscalMonth: number;
        isSbtValidated: boolean;
        hideCarbonAvoidance: boolean;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          interval: YMInterval;
          referencePeriodInterval: YMInterval;
          overrideFootprintKind: string | null;
          validReferenceInterval: YMInterval;
          footprintSnapshotId: string;
          scenarios: Array<{
            __typename?: 'ForecastScenario';
            id: string;
            name: string;
            description: string | null;
            isDefault: boolean;
            updatedByName: string | null;
            updatedAt: Date;
            historicalUserInputtedGrowthFactorDataPoints: Array<{
              __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
              date: Date;
              growthFactors: Array<{
                __typename?: 'GrowthFactorDataPoint';
                growthFactorType: GQGrowthFactorType;
                customIntensityConfigId: string | null;
                value: number | null;
              }>;
            }>;
            growthForecasts: Array<{
              __typename?: 'GrowthForecast';
              id: string;
              growthFactorType: GQGrowthFactorType;
              customIntensityConfigId: string | null;
              isDefaultScalingFactor: boolean;
              hasUserInputtedHistoricalPeriod: boolean;
              forecast: {
                __typename?: 'SimpleTimeseries';
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              };
              scalingFactorFilters: {
                __typename?: 'FilterExpressionGroupWithNewFilters';
                conjunction: GQFilterConjunction;
                expressions: Array<{
                  __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                  field: string;
                  operator: GQFilterOperator;
                  value: Array<string>;
                }>;
              } | null;
            }>;
          }>;
          historicalPeriod: {
            __typename?: 'ForecastPeriod';
            interval: YMInterval;
            data: Array<{
              __typename?: 'ForecastPeriodPoint';
              date: Date;
              headcount: number | null;
              revenue: number | null;
              customGrowthFactors: Array<{
                __typename?: 'ForecastCustomIntensityDataPoint';
                customIntensityId: string;
                value: number | null;
              }>;
            }>;
          };
          customIntensityConfigs: Array<{
            __typename?: 'CustomIntensityConfig';
            id: string;
            name: string;
            description: string;
            unit: string | null;
            humanReadable: boolean;
            quantityType: GQCustomIntensityConfigQuantityType;
            isSbtValidated: boolean | null;
          }>;
          businessCategories: Array<{
            __typename?: 'CategorizedEmissionData';
            businessCategory: string;
          }>;
        };
        variables: {
          __typename?: 'PlanVariables';
          commitmentsHasNetZero: boolean;
          commitmentsNetZeroYear: number;
          cleanPowerStart: YearMonth;
          commitmentsHasSBT: boolean;
          commitmentsSBTTargetYear: number;
          commitmentsSBTScope12: GQSbtScope1And2Target;
          commitmentsSBTScope3: GQSbtScope3Target;
          commitmentsSBTTermLength: GQSbtTermLength;
          commitmentsSBTSubmissionDate: YearMonth;
          flightsPerEmployeeForecastEnd: number;
          percentWorkFromHomeForecastEnd: number;
          netZeroSuppliersReductionPercent: number;
        };
        reduxInitiatives: Array<
          | {
              __typename: 'FootprintSliceInitiative';
              id: string;
              name: string;
              enabled: boolean;
              description: string | null;
              scheduleType: GQScheduleType;
              deletedAt: Date | null;
              createdAt: Date;
              reductionTimeseries: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              };
              costTimeseries: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              };
              filters: {
                __typename?: 'FilterExpressionGroupWithNewFilters';
                conjunction: GQFilterConjunction;
                expressions: Array<{
                  __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                  field: string;
                  value: Array<string>;
                  operator: GQFilterOperator;
                }>;
              };
            }
          | {
              __typename: 'PrecomputedActivityBasedInitiative';
              id: string;
              name: string;
              enabled: boolean;
              description: string | null;
              scheduleType: GQScheduleType;
              deletedAt: Date | null;
              createdAt: Date;
              precomputedLever: GQPrecomputedLever;
              implementationTimeseries: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              };
              costTimeseries: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              };
              filters: {
                __typename?: 'FilterExpressionGroupWithNewFilters';
                conjunction: GQFilterConjunction;
                expressions: Array<{
                  __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                  field: string;
                  value: Array<string>;
                  operator: GQFilterOperator;
                }>;
              };
            }
        >;
        targets: Array<{
          __typename?: 'PlanTarget';
          id: string;
          createdAt: Date | null;
          description: string;
          reportDescription: string;
          customNotes: string;
          baseYear: YearMonth;
          intensityType: GQPlanTargetIntensityType;
          customIntensityConfigId: string | null;
          comparisonType: GQPlanTargetTargetComparisonType;
          reductionRate: GQTargetReductionRate;
          deletedAt: Date | null;
          assignedTo: string | null;
          parentTargetId: string | null;
          interimTargetDate: YearMonth | null;
          interimTargetValue: number | null;
          emissionsTargetCustom: {
            __typename?: 'SimpleTimeseries';
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          filters: {
            __typename?: 'FilterExpressionGroup';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitive';
              field: GQFilterFieldLegacy;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          };
        }>;
        reduxCarbonOffsets: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        reduxCarbonOffsetsCosts: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        reduxCarbonRemoval: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        reduxCarbonRemovalCosts: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
      } | null;
      charts: Array<
        | {
            __typename: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{
              __typename?: 'EngagementFunnel';
              id: string;
              name: string;
              displayOnSupplierDetailsPage: boolean | null;
              steps: Array<{
                __typename?: 'EngagementFunnelStep';
                id: string;
                stepName: string;
                stepType: GQEngagementFunnelStepType;
                sbtStage: GQCompanySbtCommitmentStage | null;
                engagementTaskConfigId: string | null;
                supplierTableColumnValues: Array<string> | null;
                supplierTableColumn: {
                  __typename?: 'SupplierTableColumn';
                  id: string;
                  field: string;
                } | null;
              }>;
            }>;
          }
        | {
            __typename: 'SupplierMetricsChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            metricsTypes: Array<GQSupplyChainMetricType>;
          }
        | {
            __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
    }>;
  } | null;
};

export type GQUpdateSupplierChartMutationVariables = Exact<{
  input: GQUpdateSupplierChartInput;
}>;

export type GQUpdateSupplierChartMutation = {
  __typename?: 'Mutation';
  updateSupplierChart: {
    __typename?: 'UpdateSupplierChartPayload';
    views: Array<{
      __typename?: 'SupplierView';
      id: string;
      charts: Array<
        | {
            __typename: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{
              __typename?: 'EngagementFunnel';
              id: string;
              name: string;
              displayOnSupplierDetailsPage: boolean | null;
              steps: Array<{
                __typename?: 'EngagementFunnelStep';
                id: string;
                stepName: string;
                stepType: GQEngagementFunnelStepType;
                sbtStage: GQCompanySbtCommitmentStage | null;
                engagementTaskConfigId: string | null;
                supplierTableColumnValues: Array<string> | null;
                supplierTableColumn: {
                  __typename?: 'SupplierTableColumn';
                  id: string;
                  field: string;
                } | null;
              }>;
            }>;
          }
        | {
            __typename: 'SupplierMetricsChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            metricsTypes: Array<GQSupplyChainMetricType>;
          }
        | {
            __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
    }>;
  } | null;
};

export type GQTargetsDialogDisclosureTargetDetailsFragment = {
  __typename?: 'DisclosureTargetDetails';
  id: string;
  name: string;
  description: string;
  baseYear: YearMonth;
  reductionRate: GQDisclosureTargetReductionRate;
  intensityType: GQDisclosureTargetIntensityType;
  unit: string | null;
  unitDescription: string | null;
  filters: {
    __typename?: 'FilterExpressionGroup';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitive';
      field: GQFilterFieldLegacy;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  };
  emissionsTarget: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    frequency: GQTimeseriesFrequency;
    base: Date;
    values: Array<number>;
  };
};

export type GQDisclosureForTargetsDialogFragment = {
  __typename?: 'Disclosure';
  id: string;
  company: { __typename?: 'Company'; id: string } | null;
  publicDisclosure: {
    __typename?: 'PublicDisclosure';
    id: string;
    reportType: GQExternalReportType;
  } | null;
  privateDisclosure: {
    __typename?: 'PrivateDisclosure';
    id: string;
    privateDisclosureType: GQPrivateDisclosureType;
  } | null;
  targets: Array<{
    __typename?: 'DisclosureTargetDetails';
    id: string;
    name: string;
    description: string;
    baseYear: YearMonth;
    reductionRate: GQDisclosureTargetReductionRate;
    intensityType: GQDisclosureTargetIntensityType;
    unit: string | null;
    unitDescription: string | null;
    filters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    };
    emissionsTarget: {
      __typename?: 'SimpleTimeseries';
      id: string | null;
      frequency: GQTimeseriesFrequency;
      base: Date;
      values: Array<number>;
    };
  }> | null;
};

export type GQGetCompanyTargetDisclosuresQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  opts: InputMaybe<GQDisclosureInput>;
}>;

export type GQGetCompanyTargetDisclosuresQuery = {
  __typename?: 'Query';
  companyDisclosures: Array<{
    __typename?: 'Disclosure';
    id: string;
    company: { __typename?: 'Company'; id: string } | null;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      reportType: GQExternalReportType;
    } | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      privateDisclosureType: GQPrivateDisclosureType;
    } | null;
    targets: Array<{
      __typename?: 'DisclosureTargetDetails';
      id: string;
      name: string;
      description: string;
      baseYear: YearMonth;
      reductionRate: GQDisclosureTargetReductionRate;
      intensityType: GQDisclosureTargetIntensityType;
      unit: string | null;
      unitDescription: string | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
      emissionsTarget: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        frequency: GQTimeseriesFrequency;
        base: Date;
        values: Array<number>;
      };
    }> | null;
  }>;
};

export type GQCreatePrivateDisclosureMutationVariables = Exact<{
  input: GQCreatePrivateDisclosureInput;
}>;

export type GQCreatePrivateDisclosureMutation = {
  __typename?: 'Mutation';
  createPrivateDisclosure: {
    __typename?: 'CreatePrivateDisclosurePayload';
    disclosure: {
      __typename?: 'Disclosure';
      id: string;
      company: { __typename?: 'Company'; id: string } | null;
      publicDisclosure: {
        __typename?: 'PublicDisclosure';
        id: string;
        reportType: GQExternalReportType;
      } | null;
      privateDisclosure: {
        __typename?: 'PrivateDisclosure';
        id: string;
        privateDisclosureType: GQPrivateDisclosureType;
      } | null;
      targets: Array<{
        __typename?: 'DisclosureTargetDetails';
        id: string;
        name: string;
        description: string;
        baseYear: YearMonth;
        reductionRate: GQDisclosureTargetReductionRate;
        intensityType: GQDisclosureTargetIntensityType;
        unit: string | null;
        unitDescription: string | null;
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
        emissionsTarget: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          frequency: GQTimeseriesFrequency;
          base: Date;
          values: Array<number>;
        };
      }> | null;
    };
  } | null;
};

export type GQUpdatePrivateDisclosureMutationVariables = Exact<{
  input: GQUpdatePrivateDisclosureInput;
}>;

export type GQUpdatePrivateDisclosureMutation = {
  __typename?: 'Mutation';
  updatePrivateDisclosure: {
    __typename?: 'UpdatePrivateDisclosurePayload';
    disclosure: {
      __typename?: 'Disclosure';
      id: string;
      company: { __typename?: 'Company'; id: string } | null;
      publicDisclosure: {
        __typename?: 'PublicDisclosure';
        id: string;
        reportType: GQExternalReportType;
      } | null;
      privateDisclosure: {
        __typename?: 'PrivateDisclosure';
        id: string;
        privateDisclosureType: GQPrivateDisclosureType;
      } | null;
      targets: Array<{
        __typename?: 'DisclosureTargetDetails';
        id: string;
        name: string;
        description: string;
        baseYear: YearMonth;
        reductionRate: GQDisclosureTargetReductionRate;
        intensityType: GQDisclosureTargetIntensityType;
        unit: string | null;
        unitDescription: string | null;
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
        emissionsTarget: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          frequency: GQTimeseriesFrequency;
          base: Date;
          values: Array<number>;
        };
      }> | null;
    };
  } | null;
};

export type GQGetCompanyPrivateDisclosuresQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
}>;

export type GQGetCompanyPrivateDisclosuresQuery = {
  __typename?: 'Query';
  companyDisclosures: Array<{
    __typename?: 'Disclosure';
    id: string;
    company: { __typename?: 'Company'; id: string } | null;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      publishingYear: number;
      reportType: GQExternalReportType;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      companyId: string | null;
      orgId: string;
      orgName: string;
      surveyId: string | null;
      privateDisclosureType: GQPrivateDisclosureType;
      createdAt: Date;
      disclosureQualityScore: GQDisclosureQualityScore | null;
      disclosureQualityExplanation: string | null;
      thirdPartyVerified: boolean;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      publicDisclosureId: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      revenueUsd: number | null;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      units: GQCompanyEmissionsUnits;
      expenseCategory: string | null;
      scope1: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
    }> | null;
    targets: Array<{
      __typename?: 'DisclosureTargetDetails';
      id: string;
      name: string;
      description: string;
      baseYear: YearMonth;
      emissionsType: GQDisclosureTargetEmissionsType;
      reductionRate: GQDisclosureTargetReductionRate;
      intensityType: GQDisclosureTargetIntensityType;
      unit: string | null;
      unitDescription: string | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
      emissionsTarget: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    }> | null;
    initiatives: Array<{
      __typename?: 'DisclosureInitiative';
      id: string;
      name: string | null;
      initiativeType: GQDisclosureInitiativeType;
      description: string | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      intensityType: GQDisclosureTargetIntensityType | null;
      emissionsTimeseries: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      } | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'NetZeroCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            netZeroCommitted: boolean | null;
            commitmentType: GQSbtCommitmentType | null;
            commitmentDeadline: Date | null;
            netZeroTargetYear: number | null;
          };
        }
    > | null;
  }>;
};

export type GQCompanyEngagementTaskFieldsForOverviewFragment = {
  __typename?: 'EngagementTask';
  id: string;
  createdAt: Date;
  approvedAt: Date | null;
  submittedAt: Date | null;
  companyId: string;
  dueDate: Date | null;
  rootAssignee: string | null;
  rootAssigneeDisplayName: string | null;
  rootCompanyId: string;
  status: GQCompanySurveyStatus;
  statusChangedAt: Date | null;
  engagementTaskConfigId: string;
  engagementTaskBatchId: string | null;
  initialSupplierAssigneeEmail: string | null;
  company: { __typename?: 'Company'; id: string; name: string };
  engagementTaskConfig: {
    __typename?: 'EngagementTaskConfig';
    id: string;
    name: string;
    category: GQSurveyDefinitionCategory;
    rootFacingDescription: string | null;
    baseConfig: { __typename?: 'EngagementTaskConfig'; id: string } | null;
  };
};

export type GQEngagementTaskTypeAndStatusFieldsFragment = {
  __typename?: 'EngagementTask';
  id: string;
  submittedAt: Date | null;
  engagementTaskConfig: {
    __typename?: 'EngagementTaskConfig';
    type: GQEngagementTaskConfigType | null;
  };
};

export type GQGetOrgEngagementTasksForOverviewQueryVariables = Exact<{
  rootOnly: InputMaybe<Scalars['Boolean']['input']>;
  ignoreLearningTasks: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQGetOrgEngagementTasksForOverviewQuery = {
  __typename?: 'Query';
  engagementTasks: {
    __typename?: 'EngagementTaskConnection';
    edges: Array<{
      __typename?: 'EngagementTaskEdge';
      node: {
        __typename?: 'EngagementTask';
        id: string;
        createdAt: Date;
        approvedAt: Date | null;
        submittedAt: Date | null;
        companyId: string;
        dueDate: Date | null;
        rootAssignee: string | null;
        rootAssigneeDisplayName: string | null;
        rootCompanyId: string;
        status: GQCompanySurveyStatus;
        statusChangedAt: Date | null;
        engagementTaskConfigId: string;
        engagementTaskBatchId: string | null;
        initialSupplierAssigneeEmail: string | null;
        company: { __typename?: 'Company'; id: string; name: string };
        engagementTaskConfig: {
          __typename?: 'EngagementTaskConfig';
          id: string;
          name: string;
          category: GQSurveyDefinitionCategory;
          rootFacingDescription: string | null;
          baseConfig: {
            __typename?: 'EngagementTaskConfig';
            id: string;
          } | null;
        };
      } | null;
    }>;
  };
};

export type GQGetCompanyAndOrgEngagementTasksForOverviewQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  rootOnly: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQGetCompanyAndOrgEngagementTasksForOverviewQuery = {
  __typename?: 'Query';
  engagementTasksForCompanyAndOrgV1: Array<{
    __typename?: 'EngagementTask';
    id: string;
    createdAt: Date;
    approvedAt: Date | null;
    submittedAt: Date | null;
    companyId: string;
    dueDate: Date | null;
    rootAssignee: string | null;
    rootAssigneeDisplayName: string | null;
    rootCompanyId: string;
    status: GQCompanySurveyStatus;
    statusChangedAt: Date | null;
    engagementTaskConfigId: string;
    engagementTaskBatchId: string | null;
    initialSupplierAssigneeEmail: string | null;
    company: { __typename?: 'Company'; id: string; name: string };
    engagementTaskConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      name: string;
      category: GQSurveyDefinitionCategory;
      rootFacingDescription: string | null;
      baseConfig: { __typename?: 'EngagementTaskConfig'; id: string } | null;
    };
  }>;
};

export type GQGetCompanyAndOrgEngagementTasksForDetailsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  rootOnly: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQGetCompanyAndOrgEngagementTasksForDetailsQuery = {
  __typename?: 'Query';
  engagementTasksForCompanyAndOrgV1: Array<{
    __typename?: 'EngagementTask';
    id: string;
    crossOrgModelId: string;
    reportingYear: number | null;
    facilityName: string | null;
    submittedAt: Date | null;
    createdAt: Date;
    dueDate: Date | null;
    status: GQCompanySurveyStatus;
    surveyKind: GQCompanySurveyKind;
    supplierFacingDescription: string | null;
    watershedGeneric: boolean | null;
    currentAssignee: GQCompanySurveyAssignee;
    rootAssignee: string | null;
    supplierAssignee: string | null;
    initialSupplierAssigneeEmail: string | null;
    approvedAt: Date | null;
    statusChangedAt: Date | null;
    rootOrgId: string;
    supplierOrgId: string;
    latestCeeWorkflowId: string | null;
    latestPleeWorkflowId: string | null;
    latestFootprintDisclosureWorkflowId: string | null;
    engagementTaskBatchId: string | null;
    proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
    engagementTaskConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      name: string;
      sidebarTitle: string;
      defaultSupplierFacingDescription: string | null;
      rootFacingDescription: string | null;
      orgId: string | null;
      type: GQEngagementTaskConfigType | null;
      configHasTaskAssignedToCompany: boolean;
      timeNeeded: number | null;
      category: GQSurveyDefinitionCategory;
      engagementTaskInputs: Array<GQEngagementTaskInput>;
      optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          answerIsPrivate: boolean;
          videoLink: string | null;
        }>;
      }>;
      baseConfig: {
        __typename?: 'EngagementTaskConfig';
        id: string;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
      } | null;
    };
    rootCompany: {
      __typename?: 'Company';
      id: string;
      name: string;
      fullLogoUrl: string | null;
      url: string | null;
    };
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      naicsCode: string | null;
      sbtiStage: GQCompanySbtCommitmentStage;
      publicDisclosures: Array<{
        __typename?: 'Disclosure';
        id: string;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          publishingYear: number;
        } | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          publicDisclosureId: string | null;
          reportingYear: number;
          numEmployees: number | null;
          revenue: number | null;
          revenueCurrency: string | null;
          revenueUsd: number | null;
          percentageCleanEnergy: number | null;
          totalMwh: number | null;
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
        }> | null;
      }>;
    };
    expenseCategories: Array<{
      __typename?: 'DiscloseEmissionsExpenseCategories';
      beaCode: string;
      inputUnit: string;
      inputQuantity: number;
      kgco2e: number;
    }> | null;
    proposeTargetFilters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    } | null;
    proposeTargetEmissionsTarget: {
      __typename?: 'SimpleTimeseries';
      id: string | null;
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    } | null;
  }>;
};

export type GQGetEngagementTasksAssignedTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetEngagementTasksAssignedTypesQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  engagementTasksAssignedToOrg: Array<{
    __typename?: 'EngagementTask';
    id: string;
    submittedAt: Date | null;
    engagementTaskConfig: {
      __typename?: 'EngagementTaskConfig';
      type: GQEngagementTaskConfigType | null;
    };
  }>;
};

export type GQGetEngagementTasksPageDataQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type GQGetEngagementTasksPageDataQuery = {
  __typename?: 'Query';
  companyForDashboard: {
    __typename?: 'Company';
    id: string;
    name: string;
    fullLogoUrl: string | null;
    naicsCode: string | null;
  } | null;
  engagementTasksAssignedToOrg: Array<{
    __typename?: 'EngagementTask';
    id: string;
    crossOrgModelId: string;
    reportingYear: number | null;
    facilityName: string | null;
    submittedAt: Date | null;
    createdAt: Date;
    dueDate: Date | null;
    status: GQCompanySurveyStatus;
    surveyKind: GQCompanySurveyKind;
    supplierFacingDescription: string | null;
    watershedGeneric: boolean | null;
    currentAssignee: GQCompanySurveyAssignee;
    rootAssignee: string | null;
    supplierAssignee: string | null;
    initialSupplierAssigneeEmail: string | null;
    approvedAt: Date | null;
    statusChangedAt: Date | null;
    rootOrgId: string;
    supplierOrgId: string;
    latestCeeWorkflowId: string | null;
    latestPleeWorkflowId: string | null;
    latestFootprintDisclosureWorkflowId: string | null;
    engagementTaskBatchId: string | null;
    proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
    submittedAnswers: Array<{
      __typename?: 'EngagementTaskAnswer';
      id: string;
      questionKey: string;
      answer: any | null;
      skipped: boolean;
      questionIndex: number | null;
      attachments: Array<{
        __typename?: 'EngagementTaskAnswerAttachment';
        originalFilename: string;
        signedDownloadUrl: string | null;
        questionKey: string;
        fileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          createdAt: Date;
        };
      }>;
    }>;
    comments: Array<{
      __typename?: 'EngagementTaskComment';
      id: string;
      createdAt: Date;
      userEditorId: string | null;
      userEditorName: string | null;
      creatorOrgId: string | null;
      comment: string | null;
    }>;
    edits: Array<{
      __typename?: 'CompanySurveyEdit';
      id: string;
      createdAt: Date;
      userEditorId: string | null;
      userEditorName: string | null;
      creatorOrgId: string | null;
      columnName: GQEditColumn;
      newValue: string | null;
      valueType: GQEditValueType | null;
    }>;
    footprintEstimate: {
      __typename?: 'FootprintEstimateOutput';
      id: string;
      output: {
        __typename?: 'FootprintEstimateOutputByScope';
        scope1: number | null;
        scope2Market: number | null;
        scope2Location: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
      } | null;
    } | null;
    engagementTaskConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      name: string;
      sidebarTitle: string;
      defaultSupplierFacingDescription: string | null;
      rootFacingDescription: string | null;
      orgId: string | null;
      type: GQEngagementTaskConfigType | null;
      configHasTaskAssignedToCompany: boolean;
      timeNeeded: number | null;
      category: GQSurveyDefinitionCategory;
      engagementTaskInputs: Array<GQEngagementTaskInput>;
      optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          answerIsPrivate: boolean;
          videoLink: string | null;
        }>;
      }>;
      baseConfig: {
        __typename?: 'EngagementTaskConfig';
        id: string;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
      } | null;
    };
    rootCompany: {
      __typename?: 'Company';
      id: string;
      name: string;
      fullLogoUrl: string | null;
      url: string | null;
    };
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      naicsCode: string | null;
      sbtiStage: GQCompanySbtCommitmentStage;
      publicDisclosures: Array<{
        __typename?: 'Disclosure';
        id: string;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          publishingYear: number;
        } | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          publicDisclosureId: string | null;
          reportingYear: number;
          numEmployees: number | null;
          revenue: number | null;
          revenueCurrency: string | null;
          revenueUsd: number | null;
          percentageCleanEnergy: number | null;
          totalMwh: number | null;
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
        }> | null;
      }>;
    };
    expenseCategories: Array<{
      __typename?: 'DiscloseEmissionsExpenseCategories';
      beaCode: string;
      inputUnit: string;
      inputQuantity: number;
      kgco2e: number;
    }> | null;
    proposeTargetFilters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    } | null;
    proposeTargetEmissionsTarget: {
      __typename?: 'SimpleTimeseries';
      id: string | null;
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    } | null;
  }>;
  organization: {
    __typename?: 'Organization';
    id: string;
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      fullLogoUrl: string | null;
      naicsCode: string | null;
    };
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        } | null;
      } | null>;
    } | null;
  };
  companyPortalSettings: {
    __typename?: 'CompanyPortalSettings';
    id: string | null;
    portalCallout: string | null;
    sidebarContent: string | null;
    portalTitle: string;
    toggleHeaderImage: boolean | null;
  };
};

export type GQUpsertNonFootprintSupplierMutationVariables = Exact<{
  version: InputMaybe<Scalars['String']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  input: GQUpsertNonFootprintSupplierInput;
}>;

export type GQUpsertNonFootprintSupplierMutation = {
  __typename?: 'Mutation';
  upsertNonFootprintSupplier: {
    __typename?: 'UpsertNonFootprintSupplierPayload';
    supplier: {
      __typename?: 'Supplier';
      id: string;
      name: string;
      footprintVendorNames: Array<string>;
      supplierType: GQSupplierType;
      totalKgco2e: number;
      totalKgco2eFromFootprint: number | null;
      totalSpending: number | null;
      totalSpendUsd: number | null;
      isTotalSpendingPartial: boolean | null;
      latestDisclosureDateTime: Date | null;
      latestCdpDisclosurePublishingYear: number | null;
      percentEmissions: number;
      climateProgress: GQCompanyClimateProgress;
      sbtiStage: GQCompanySbtCommitmentStage;
      customDataV2: any | null;
      notes: string | null;
      ghgCategoryIds: Array<string | null> | null;
      company: {
        __typename?: 'Company';
        id: string;
        naicsCode: string | null;
        sbtiStage: GQCompanySbtCommitmentStage;
        climateProgress: GQCompanyClimateProgress;
        sustainabilityWebsiteUrl: string | null;
        isWatershedCorporateCustomer: boolean;
        parentRelationships: Array<{
          __typename?: 'ParentRelationship';
          id: string;
          relationshipStartDate: YearMonth | null;
          relationshipEndDate: YearMonth | null;
          parent: {
            __typename?: 'Company';
            id: string;
            name: string;
            disclosuresV2: Array<{
              __typename?: 'Disclosure';
              id: string;
              company: { __typename?: 'Company'; id: string } | null;
              publicDisclosure: {
                __typename?: 'PublicDisclosure';
                id: string;
                publishingYear: number;
                reportType: GQExternalReportType;
                publicUrl: string;
                qualityScore: GQDisclosureQualityScore | null;
                cdpVendorData: {
                  __typename?: 'CdpVendorData';
                  id: string;
                  publishingYear: number;
                  reportingYear: number;
                  scope1Nonzero: boolean;
                  scope2Nonzero: boolean;
                  scope3Nonzero: boolean;
                  totalEmissionsNonzero: boolean;
                  scope301Or302Nonzero: boolean;
                  scope1Verified: boolean;
                  scope2Verified: boolean;
                  scope3Verified: boolean;
                  pctEvaluationStatusesMatchResponse: number;
                  disclosureQualityScore: GQDisclosureQualityScore;
                  scope301EvaluationStatus: GQScope3EvaluationStatus;
                  scope302EvaluationStatus: GQScope3EvaluationStatus;
                  scope303EvaluationStatus: GQScope3EvaluationStatus;
                  scope304EvaluationStatus: GQScope3EvaluationStatus;
                  scope305EvaluationStatus: GQScope3EvaluationStatus;
                  scope306EvaluationStatus: GQScope3EvaluationStatus;
                  scope307EvaluationStatus: GQScope3EvaluationStatus;
                  scope308EvaluationStatus: GQScope3EvaluationStatus;
                  scope309EvaluationStatus: GQScope3EvaluationStatus;
                  scope310EvaluationStatus: GQScope3EvaluationStatus;
                  scope311EvaluationStatus: GQScope3EvaluationStatus;
                  scope312EvaluationStatus: GQScope3EvaluationStatus;
                  scope313EvaluationStatus: GQScope3EvaluationStatus;
                  scope314EvaluationStatus: GQScope3EvaluationStatus;
                  scope315EvaluationStatus: GQScope3EvaluationStatus;
                  scope316EvaluationStatus: GQScope3EvaluationStatus;
                  scope317EvaluationStatus: GQScope3EvaluationStatus;
                } | null;
              } | null;
              privateDisclosure: {
                __typename?: 'PrivateDisclosure';
                id: string;
                companyId: string | null;
                orgId: string;
                orgName: string;
                surveyId: string | null;
                privateDisclosureType: GQPrivateDisclosureType;
                createdAt: Date;
                disclosureQualityScore: GQDisclosureQualityScore | null;
                disclosureQualityExplanation: string | null;
                thirdPartyVerified: boolean;
                cdpVendorData: {
                  __typename?: 'CdpVendorData';
                  id: string;
                  publishingYear: number;
                  reportingYear: number;
                  scope1Nonzero: boolean;
                  scope2Nonzero: boolean;
                  scope3Nonzero: boolean;
                  totalEmissionsNonzero: boolean;
                  scope301Or302Nonzero: boolean;
                  scope1Verified: boolean;
                  scope2Verified: boolean;
                  scope3Verified: boolean;
                  pctEvaluationStatusesMatchResponse: number;
                  disclosureQualityScore: GQDisclosureQualityScore;
                  scope301EvaluationStatus: GQScope3EvaluationStatus;
                  scope302EvaluationStatus: GQScope3EvaluationStatus;
                  scope303EvaluationStatus: GQScope3EvaluationStatus;
                  scope304EvaluationStatus: GQScope3EvaluationStatus;
                  scope305EvaluationStatus: GQScope3EvaluationStatus;
                  scope306EvaluationStatus: GQScope3EvaluationStatus;
                  scope307EvaluationStatus: GQScope3EvaluationStatus;
                  scope308EvaluationStatus: GQScope3EvaluationStatus;
                  scope309EvaluationStatus: GQScope3EvaluationStatus;
                  scope310EvaluationStatus: GQScope3EvaluationStatus;
                  scope311EvaluationStatus: GQScope3EvaluationStatus;
                  scope312EvaluationStatus: GQScope3EvaluationStatus;
                  scope313EvaluationStatus: GQScope3EvaluationStatus;
                  scope314EvaluationStatus: GQScope3EvaluationStatus;
                  scope315EvaluationStatus: GQScope3EvaluationStatus;
                  scope316EvaluationStatus: GQScope3EvaluationStatus;
                  scope317EvaluationStatus: GQScope3EvaluationStatus;
                } | null;
              } | null;
              historicalEmissionsYears: Array<{
                __typename?: 'EmissionsYear';
                id: string;
                publicDisclosureId: string | null;
                reportingYear: number;
                numEmployees: number | null;
                revenue: number | null;
                revenueCurrency: string | null;
                revenueUsd: number | null;
                percentageCleanEnergy: number | null;
                totalMwh: number | null;
                units: GQCompanyEmissionsUnits;
                expenseCategory: string | null;
                scope1: number | null;
                scope2Market: number | null;
                scope2Location: number | null;
                scope3: number | null;
                scope301: number | null;
                scope302: number | null;
                scope303: number | null;
                scope304: number | null;
                scope305: number | null;
                scope306: number | null;
                scope307: number | null;
                scope308: number | null;
                scope309: number | null;
                scope310: number | null;
                scope311: number | null;
                scope312: number | null;
                scope313: number | null;
                scope314: number | null;
                scope315: number | null;
                scope316: number | null;
                scope317: number | null;
              }> | null;
              targets: Array<{
                __typename?: 'DisclosureTargetDetails';
                id: string;
                name: string;
                description: string;
                baseYear: YearMonth;
                emissionsType: GQDisclosureTargetEmissionsType;
                reductionRate: GQDisclosureTargetReductionRate;
                intensityType: GQDisclosureTargetIntensityType;
                unit: string | null;
                unitDescription: string | null;
                filters: {
                  __typename?: 'FilterExpressionGroup';
                  conjunction: GQFilterConjunction;
                  expressions: Array<{
                    __typename?: 'FilterExpressionPrimitive';
                    field: GQFilterFieldLegacy;
                    operator: GQFilterOperator;
                    value: Array<string>;
                  }>;
                };
                emissionsTarget: {
                  __typename?: 'SimpleTimeseries';
                  id: string | null;
                  base: Date;
                  frequency: GQTimeseriesFrequency;
                  values: Array<number>;
                };
              }> | null;
              initiatives: Array<{
                __typename?: 'DisclosureInitiative';
                id: string;
                name: string | null;
                initiativeType: GQDisclosureInitiativeType;
                description: string | null;
                startYearMonth: YearMonth | null;
                endYearMonth: YearMonth | null;
                intensityType: GQDisclosureTargetIntensityType | null;
                emissionsTimeseries: {
                  __typename?: 'SimpleTimeseries';
                  id: string | null;
                  base: Date;
                  frequency: GQTimeseriesFrequency;
                  values: Array<number>;
                } | null;
                filters: {
                  __typename?: 'FilterExpressionGroup';
                  conjunction: GQFilterConjunction;
                  expressions: Array<{
                    __typename?: 'FilterExpressionPrimitive';
                    field: GQFilterFieldLegacy;
                    operator: GQFilterOperator;
                    value: Array<string>;
                  }>;
                } | null;
              }> | null;
              climateCommitments: Array<
                | {
                    __typename?: 'CarbonNeutralCommitment';
                    targetYear: number | null;
                    id: string;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename?: 'CleanEnergyCommitment';
                    targetYear: number | null;
                    targetPercentageCleanEnergy: number | null;
                    id: string;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename?: 'NetZeroCommitment';
                    targetYear: number | null;
                    id: string;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                  }
                | {
                    __typename?: 'ScienceBasedTargetCommitment';
                    submittedToSBTi: boolean;
                    id: string;
                    kind: GQCompanyClimateCommitmentKind;
                    description: string | null;
                    externalUrl: string | null;
                    commitmentMadeDate: Date | null;
                    commitmentPeriodStart: Date | null;
                    commitmentPeriodEnd: Date | null;
                    commitment: {
                      __typename?: 'SBTCommitment';
                      id: string;
                      stage: GQSbtCommitmentStage | null;
                      targetClassification: GQSbtTargetClassification | null;
                      nearTermTargetYear: number | null;
                      longTermTargetYear: number | null;
                      baselineYear: number | null;
                      netZeroCommitted: boolean | null;
                      commitmentType: GQSbtCommitmentType | null;
                      commitmentDeadline: Date | null;
                      netZeroTargetYear: number | null;
                    };
                  }
              > | null;
            }>;
          };
        }>;
        parentRelationshipsForSidebar: Array<{
          __typename?: 'ParentRelationship';
          id: string;
          relationshipStartDate: YearMonth | null;
          relationshipEndDate: YearMonth | null;
          parent: { __typename?: 'Company'; id: string; name: string };
        }>;
      } | null;
      historicalEmissions: {
        __typename?: 'SupplierHistoricalEmissions';
        scope1: number | null;
        scope2: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
        scope1Ratio: number | null;
        scope2Ratio: number | null;
        scope3Ratio: number | null;
        publishingYear: number | null;
        reportingYear: number;
        surveyId: string | null;
        source: GQEmissionsSource;
        revenueUsd: number | null;
        revenue: number | null;
        revenueCurrency: string | null;
        publicUrl: string | null;
        units: GQCompanyEmissionsUnits;
        expenseCategory: string | null;
      } | null;
      industryAverageAllocatedEmissions: {
        __typename?: 'SupplierHistoricalEmissions';
        scope1: number | null;
        scope2: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
        scope1Ratio: number | null;
        scope2Ratio: number | null;
        scope3Ratio: number | null;
        publishingYear: number | null;
        reportingYear: number;
        surveyId: string | null;
        source: GQEmissionsSource;
        revenueUsd: number | null;
        revenue: number | null;
        revenueCurrency: string | null;
        publicUrl: string | null;
        units: GQCompanyEmissionsUnits;
        expenseCategory: string | null;
      } | null;
      footprintTags: Array<{
        __typename?: 'StringKeyValue';
        key: string;
        value: string | null;
      }>;
    };
  } | null;
};

export type GQDeleteNonFootprintSupplierMutationVariables = Exact<{
  input: GQDeleteNonFootprintSupplierInput;
  supplierId: Scalars['String']['input'];
}>;

export type GQDeleteNonFootprintSupplierMutation = {
  __typename?: 'Mutation';
  deleteNonFootprintSupplier: {
    __typename?: 'DeleteNonFootprintSupplierPayload';
    success: boolean | null;
  } | null;
};

export type GQEngagementNotificationSettingFieldsFragment = {
  __typename?: 'EngagementNotificationSetting';
  id: string;
  userId: string;
  companyId: string | null;
  engagementTaskId: string | null;
  shouldNotify: boolean;
};

export type GQGetEngagementNotificationSettingsForUserQueryVariables = Exact<{
  side: GQEngagementNotificationSide;
}>;

export type GQGetEngagementNotificationSettingsForUserQuery = {
  __typename?: 'Query';
  activeOrganization: {
    __typename?: 'ActiveOrganization';
    id: string;
    user: {
      __typename?: 'User';
      id: string;
      engagementNotificationSettings: Array<{
        __typename?: 'EngagementNotificationSetting';
        id: string;
        userId: string;
        companyId: string | null;
        engagementTaskId: string | null;
        shouldNotify: boolean;
      }>;
    };
  };
};

export type GQSetEngagementNotificationSettingsForUserMutationVariables =
  Exact<{
    input: GQSetEngagementNotificationSettingsForUserInput;
    side: GQEngagementNotificationSide;
  }>;

export type GQSetEngagementNotificationSettingsForUserMutation = {
  __typename?: 'Mutation';
  setEngagementNotificationSettingsForUser: {
    __typename?: 'User';
    engagementNotificationSettings: Array<{
      __typename?: 'EngagementNotificationSetting';
      id: string;
      userId: string;
      companyId: string | null;
      engagementTaskId: string | null;
      shouldNotify: boolean;
    }>;
  };
};

export type GQGetSupplierActivityTagsQueryVariables = Exact<{
  footprintInterval: Scalars['YMInterval']['input'];
  footprintVersion: InputMaybe<Scalars['String']['input']>;
}>;

export type GQGetSupplierActivityTagsQuery = {
  __typename?: 'Query';
  supplierActivityTags: {
    __typename?: 'SupplierActivityTags';
    tags: Array<{
      __typename?: 'StringKeyValue';
      key: string;
      value: string | null;
    }>;
  };
};

export type GQCreateEngagementCohortMutationVariables = Exact<{
  input: GQCreateEngagementCohortInput;
}>;

export type GQCreateEngagementCohortMutation = {
  __typename?: 'Mutation';
  createEngagementCohort: {
    __typename?: 'EngagementCohort';
    id: string;
    name: string;
  };
};

export type GQDeleteCompanyEngagementCohortMutationVariables = Exact<{
  input: GQDeleteCompanyEngagementCohortInput;
}>;

export type GQDeleteCompanyEngagementCohortMutation = {
  __typename?: 'Mutation';
  deleteCompanyEngagementCohort: {
    __typename?: 'DeleteCompanyEngagementCohortPayload';
    affectedCompanyIds: Array<string>;
  };
};

export type GQEngagementCohortWithCompanyIdsFieldsFragment = {
  __typename?: 'EngagementCohortWithCompanyIds';
  id: string;
  name: string;
  companyIds: Array<string> | null;
};

export type GQSetEngagementCohortAndMembersMutationVariables = Exact<{
  input: GQSetEngagementCohortAndMembersInput;
}>;

export type GQSetEngagementCohortAndMembersMutation = {
  __typename?: 'Mutation';
  setEngagementCohortAndMembers: {
    __typename?: 'SetEngagementCohortAndMembersPayload';
    oldCompanyIds: Array<string>;
    engagementCohortWithCompanyIds: {
      __typename?: 'EngagementCohortWithCompanyIds';
      id: string;
      name: string;
      companyIds: Array<string> | null;
    };
  };
};

export type GQPostMeasurementVendorMatchingTableRowsQueryVariables = Exact<{
  search: InputMaybe<Scalars['String']['input']>;
  sortBy: InputMaybe<GQSupplierMatchingSortOption>;
  version: InputMaybe<Scalars['String']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQPostMeasurementVendorMatchingTableRowsQuery = {
  __typename?: 'Query';
  postMeasurementVendorMatchingTableRows: {
    __typename?: 'VendorMatchingTableRowsPayload';
    id: string;
    totalNumVendorRows: number;
    vendorRows: {
      __typename?: 'VendorMatchingTableRowConnection';
      edges: Array<{
        __typename?: 'VendorMatchingTableRowEdge';
        node: {
          __typename?: 'VendorMatchingTableRow';
          id: string;
          rawVendorEntity: string;
          totalSpendUsd: number;
          autoMatchedCompany: {
            __typename?: 'Company';
            id: string;
            name: string;
            country: string | null;
            url: string | null;
            description: string | null;
            naicsCode: string | null;
            countryAlpha2: string | null;
          } | null;
          orgCompany: {
            __typename?: 'OrgCompany';
            id: string;
            name: string;
            company: {
              __typename?: 'Company';
              id: string;
              name: string;
              country: string | null;
              url: string | null;
              description: string | null;
              naicsCode: string | null;
              countryAlpha2: string | null;
            } | null;
          } | null;
          pendingCompanyCreateRequest: {
            __typename?: 'CompanyChangeRequest';
            id: string;
            action: GQCompanyChangeRequestAction;
            payload: any;
          } | null;
          subRows: Array<{
            __typename?: 'VendorMatchingTableSubRow';
            id: string;
            category: string;
            subCategory: string;
            currencyQuantity: number;
            currency: string;
            spendUsd: number;
          }>;
        } | null;
      } | null>;
      pageInfo: {
        __typename?: 'PageInfo';
        startCursor: string | null;
        endCursor: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
    };
  };
};

export type GQValueMappingQueryVariables = Exact<{
  valueMappingConfigId: Scalars['String']['input'];
}>;

export type GQValueMappingQuery = {
  __typename?: 'Query';
  valueMapping: {
    __typename?: 'ValueMapping';
    id: string;
    revisionState: GQReferenceDataRevisionState | null;
    valueMappingRows: Array<{
      __typename?: 'ValueMappingRow';
      id: string;
      baseRowJson: string;
    }>;
  };
};

export type GQDeleteSupplierScoreCriteriaMutationVariables = Exact<{
  input: GQDeleteSupplierScoreCriteriaInput;
}>;

export type GQDeleteSupplierScoreCriteriaMutation = {
  __typename?: 'Mutation';
  deleteSupplierScoreCriteria: {
    __typename?: 'DeleteSupplierScoreCriteriaPayload';
    supplierScorecard: {
      __typename?: 'SupplierScorecard';
      id: string;
      name: string;
      field: string;
      criteria: Array<{
        __typename?: 'SupplierScoreCriteria';
        id: string;
        label: string;
        supplierScoreId: string;
        field: string;
        weight: number;
        filters: Array<{
          __typename?: 'BiQueryFilter';
          dimension: string;
          operator: GQBiFilterOperator;
          value: Array<FilterValue | null>;
        }>;
      }>;
    };
  };
};

export type GQCreateSupplierScoreCriteriaMutationVariables = Exact<{
  input: GQCreateSupplierScoreCriteriaInput;
}>;

export type GQCreateSupplierScoreCriteriaMutation = {
  __typename?: 'Mutation';
  createSupplierScoreCriteria: {
    __typename?: 'CreateSupplierScoreCriteriaPayload';
    supplierScoreCriteria: {
      __typename?: 'SupplierScoreCriteria';
      id: string;
      label: string;
      supplierScoreId: string;
      field: string;
      weight: number;
      filters: Array<{
        __typename?: 'BiQueryFilter';
        dimension: string;
        operator: GQBiFilterOperator;
        value: Array<FilterValue | null>;
      }>;
    };
  };
};

export type GQUpdateSupplierScoreCriteriaMutationVariables = Exact<{
  input: GQUpdateSupplierScoreCriteriaInput;
}>;

export type GQUpdateSupplierScoreCriteriaMutation = {
  __typename?: 'Mutation';
  updateSupplierScoreCriteria: {
    __typename?: 'UpdateSupplierScoreCriteriaPayload';
    supplierScoreCriteria: {
      __typename?: 'SupplierScoreCriteria';
      id: string;
      label: string;
      supplierScoreId: string;
      field: string;
      weight: number;
      filters: Array<{
        __typename?: 'BiQueryFilter';
        dimension: string;
        operator: GQBiFilterOperator;
        value: Array<FilterValue | null>;
      }>;
    };
  };
};

export type GQEngagementTaskConfigForEditingFragment = {
  __typename?: 'EngagementTaskConfig';
  id: string;
  name: string;
  orgId: string | null;
  canDelete: boolean;
  type: GQEngagementTaskConfigType | null;
  category: GQSurveyDefinitionCategory;
  order: number;
  publishedAt: Date | null;
  archivedAt: Date | null;
  baseConfig: { __typename?: 'EngagementTaskConfig'; id: string } | null;
};

export type GQEngagementTaskConfigsForEditingQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  includeUnpublishedGlobal: InputMaybe<Scalars['Boolean']['input']>;
  includeUnpublishedOrgSpecific: InputMaybe<Scalars['Boolean']['input']>;
  configGroup: InputMaybe<GQEngagementTaskConfigGroup>;
}>;

export type GQEngagementTaskConfigsForEditingQuery = {
  __typename?: 'Query';
  engagementTaskConfigs: Array<{
    __typename?: 'EngagementTaskConfig';
    id: string;
    name: string;
    orgId: string | null;
    canDelete: boolean;
    type: GQEngagementTaskConfigType | null;
    category: GQSurveyDefinitionCategory;
    order: number;
    publishedAt: Date | null;
    archivedAt: Date | null;
    baseConfig: { __typename?: 'EngagementTaskConfig'; id: string } | null;
  }>;
};

export type GQDeleteEngagementTaskConfigMutationVariables = Exact<{
  input: GQIdInput;
}>;

export type GQDeleteEngagementTaskConfigMutation = {
  __typename?: 'Mutation';
  deleteEngagementTaskConfig: {
    __typename?: 'DeleteEngagementTaskConfigPayload';
    message: string | null;
    success: boolean;
  } | null;
};

export type GQSetPublishEngagementTaskConfigMutationVariables = Exact<{
  input: GQSetPublishEngagementTaskConfigInput;
}>;

export type GQSetPublishEngagementTaskConfigMutation = {
  __typename?: 'Mutation';
  setPublishEngagementTaskConfig: {
    __typename?: 'SetPublishEngagementTaskConfigPayload';
    engagementTaskConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      name: string;
      orgId: string | null;
      canDelete: boolean;
      type: GQEngagementTaskConfigType | null;
      category: GQSurveyDefinitionCategory;
      order: number;
      publishedAt: Date | null;
      archivedAt: Date | null;
      baseConfig: { __typename?: 'EngagementTaskConfig'; id: string } | null;
    };
  } | null;
};

export type GQSetArchiveEngagementTaskConfigMutationVariables = Exact<{
  input: GQSetArchiveEngagementTaskConfigInput;
}>;

export type GQSetArchiveEngagementTaskConfigMutation = {
  __typename?: 'Mutation';
  setArchiveEngagementTaskConfig: {
    __typename?: 'SetArchiveEngagementTaskConfigPayload';
    engagementTaskConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      name: string;
      orgId: string | null;
      canDelete: boolean;
      type: GQEngagementTaskConfigType | null;
      category: GQSurveyDefinitionCategory;
      order: number;
      publishedAt: Date | null;
      archivedAt: Date | null;
      baseConfig: { __typename?: 'EngagementTaskConfig'; id: string } | null;
    };
  } | null;
};

export type GQSupplierSurveyResponseExportMutationVariables = Exact<{
  input: GQSupplierSurveyResponseExportInput;
}>;

export type GQSupplierSurveyResponseExportMutation = {
  __typename?: 'Mutation';
  supplierSurveyResponseExport: {
    __typename?: 'SupplierSurveyResponseExportPayload';
    workflowId: string;
  };
};

export type GQCreateSupplierSavedViewMutationVariables = Exact<{
  input: GQCreateSupplierSavedViewInput;
}>;

export type GQCreateSupplierSavedViewMutation = {
  __typename?: 'Mutation';
  createSupplierSavedView: {
    __typename?: 'CreateSupplierSavedViewPayload';
    view: {
      __typename?: 'SupplierView';
      id: string;
      name: string;
      isDefault: boolean;
      reductionPlan: {
        __typename?: 'Plan';
        id: string;
        name: string;
        state: GQPlanState;
        createdAt: Date;
        isHidden: boolean;
        relevantFootprintFields: Array<string>;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          footprintSnapshotId: string;
          overrideFootprintKind: string | null;
          referencePeriodInterval: YMInterval;
        };
      } | null;
      table: {
        __typename?: 'SupplierTable';
        columns: Array<{
          __typename?: 'SupplierTableColumn';
          id: string;
          field: string;
          headerName: string | null;
          description: string | null;
          visible: boolean | null;
          columnFormat: GQSupplierTableColumnFormat | null;
          selectOptions: Array<string> | null;
          footprintTagName: string | null;
          csatTagName: string | null;
          isSupplierScore: boolean;
          isSupplierScoreCriteria: boolean;
          questionKey: string | null;
        }>;
      };
      columnOrder: Array<{
        __typename?: 'SupplierViewColumnOrder';
        columnId: string;
        visible: boolean;
        width: number;
      }> | null;
      charts: Array<
        | {
            __typename: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{
              __typename?: 'EngagementFunnel';
              id: string;
              name: string;
              displayOnSupplierDetailsPage: boolean | null;
              steps: Array<{
                __typename?: 'EngagementFunnelStep';
                id: string;
                stepName: string;
                stepType: GQEngagementFunnelStepType;
                sbtStage: GQCompanySbtCommitmentStage | null;
                engagementTaskConfigId: string | null;
                supplierTableColumnValues: Array<string> | null;
                supplierTableColumn: {
                  __typename?: 'SupplierTableColumn';
                  id: string;
                  field: string;
                } | null;
              }>;
            }>;
          }
        | {
            __typename: 'SupplierMetricsChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            metricsTypes: Array<GQSupplyChainMetricType>;
          }
        | {
            __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
      chartOrder: Array<{
        __typename?: 'SupplierViewChartOrder';
        chartId: string;
        visible: boolean;
      }> | null;
      filters: {
        __typename?: 'FilterExpressionGroupWithNewFilters';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitiveWithNewFilters';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    };
  };
};

export type GQUpdateSupplierSavedViewMutationVariables = Exact<{
  input: GQUpdateSupplierSavedViewInput;
}>;

export type GQUpdateSupplierSavedViewMutation = {
  __typename?: 'Mutation';
  updateSupplierSavedView: {
    __typename?: 'UpdateSupplierSavedViewPayload';
    view: {
      __typename?: 'SupplierView';
      id: string;
      name: string;
      isDefault: boolean;
      reductionPlan: {
        __typename?: 'Plan';
        id: string;
        name: string;
        state: GQPlanState;
        createdAt: Date;
        isHidden: boolean;
        relevantFootprintFields: Array<string>;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          footprintSnapshotId: string;
          overrideFootprintKind: string | null;
          referencePeriodInterval: YMInterval;
        };
      } | null;
      table: {
        __typename?: 'SupplierTable';
        columns: Array<{
          __typename?: 'SupplierTableColumn';
          id: string;
          field: string;
          headerName: string | null;
          description: string | null;
          visible: boolean | null;
          columnFormat: GQSupplierTableColumnFormat | null;
          selectOptions: Array<string> | null;
          footprintTagName: string | null;
          csatTagName: string | null;
          isSupplierScore: boolean;
          isSupplierScoreCriteria: boolean;
          questionKey: string | null;
        }>;
      };
      columnOrder: Array<{
        __typename?: 'SupplierViewColumnOrder';
        columnId: string;
        visible: boolean;
        width: number;
      }> | null;
      charts: Array<
        | {
            __typename: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{
              __typename?: 'EngagementFunnel';
              id: string;
              name: string;
              displayOnSupplierDetailsPage: boolean | null;
              steps: Array<{
                __typename?: 'EngagementFunnelStep';
                id: string;
                stepName: string;
                stepType: GQEngagementFunnelStepType;
                sbtStage: GQCompanySbtCommitmentStage | null;
                engagementTaskConfigId: string | null;
                supplierTableColumnValues: Array<string> | null;
                supplierTableColumn: {
                  __typename?: 'SupplierTableColumn';
                  id: string;
                  field: string;
                } | null;
              }>;
            }>;
          }
        | {
            __typename: 'SupplierMetricsChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            metricsTypes: Array<GQSupplyChainMetricType>;
          }
        | {
            __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
      chartOrder: Array<{
        __typename?: 'SupplierViewChartOrder';
        chartId: string;
        visible: boolean;
      }> | null;
      filters: {
        __typename?: 'FilterExpressionGroupWithNewFilters';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitiveWithNewFilters';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    };
  };
};

export type GQDeleteSupplierSavedViewMutationVariables = Exact<{
  input: GQDeleteSupplierSavedViewInput;
}>;

export type GQDeleteSupplierSavedViewMutation = {
  __typename?: 'Mutation';
  deleteSupplierSavedView: {
    __typename?: 'DeleteSupplierSavedViewPayload';
    id: string;
  };
};

export type GQGetSupplierColumnsForCustomFieldQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetSupplierColumnsForCustomFieldQuery = {
  __typename?: 'Query';
  supplierTableColumnsDashboard: Array<{
    __typename?: 'SupplierTableColumn';
    id: string;
    field: string;
    headerName: string | null;
    questionKey: string | null;
    columnFormat: GQSupplierTableColumnFormat | null;
    baseEngagementTaskConfigId: string | null;
    isSupplierScore: boolean;
    isSupplierScoreCriteria: boolean;
  }>;
};

export type GQGetEngagementTaskQuestionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetEngagementTaskQuestionsQuery = {
  __typename?: 'Query';
  engagementTaskQuestions: Array<{
    __typename?: 'EngagementTaskQuestion';
    id: string;
    questionKey: string;
    engagementTaskConfigId: string | null;
    componentId: GQEngagementTaskQuestionComponentId;
  }>;
};

export type GQSelfServeSupplierColumnFieldsFragment = {
  __typename?: 'SupplierTableColumn';
  id: string;
  field: string;
  headerName: string | null;
  questionKey: string | null;
  columnFormat: GQSupplierTableColumnFormat | null;
  baseEngagementTaskConfigId: string | null;
  isSupplierScore: boolean;
  isSupplierScoreCriteria: boolean;
};

export type GQDeleteSupplierChartMutationVariables = Exact<{
  input: GQDeleteSupplierChartInput;
}>;

export type GQDeleteSupplierChartMutation = {
  __typename?: 'Mutation';
  deleteSupplierChart: {
    __typename?: 'DeleteSupplierChartPayload';
    views: Array<{
      __typename?: 'SupplierView';
      id: string;
      charts: Array<
        | {
            __typename: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{
              __typename?: 'EngagementFunnel';
              id: string;
              name: string;
              displayOnSupplierDetailsPage: boolean | null;
              steps: Array<{
                __typename?: 'EngagementFunnelStep';
                id: string;
                stepName: string;
                stepType: GQEngagementFunnelStepType;
                sbtStage: GQCompanySbtCommitmentStage | null;
                engagementTaskConfigId: string | null;
                supplierTableColumnValues: Array<string> | null;
                supplierTableColumn: {
                  __typename?: 'SupplierTableColumn';
                  id: string;
                  field: string;
                } | null;
              }>;
            }>;
          }
        | {
            __typename: 'SupplierMetricsChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            metricsTypes: Array<GQSupplyChainMetricType>;
          }
        | {
            __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
    }>;
  } | null;
};

export type GQEngagementTaskDeployStatusFieldsFragment = {
  __typename?: 'EngagementTaskDeployStatus';
  id: string;
  success: boolean;
  latestContactEmail: string | null;
  company: {
    __typename?: 'Company';
    id: string;
    name: string;
    isWatershedCorporateCustomer: boolean;
    contacts: Array<{
      __typename?: 'SupplierContact';
      id: string;
      name: string;
      role: string | null;
      email: string;
    }>;
  };
  latestEmailSent: {
    __typename?: 'EmailSent';
    id: string;
    users: Array<{
      __typename?: 'EmailSentUser';
      id: string;
      recipientEmail: string;
      latestEmailStatus: GQEmailStatus;
    }>;
  } | null;
};

export type GQEngagementTaskBatchFieldsForBulkDeployStatusFragment = {
  __typename?: 'EngagementTaskBatch';
  id: string;
  deployStatuses: Array<{
    __typename?: 'EngagementTaskDeployStatus';
    id: string;
    success: boolean;
    latestContactEmail: string | null;
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      isWatershedCorporateCustomer: boolean;
      contacts: Array<{
        __typename?: 'SupplierContact';
        id: string;
        name: string;
        role: string | null;
        email: string;
      }>;
    };
    latestEmailSent: {
      __typename?: 'EmailSent';
      id: string;
      users: Array<{
        __typename?: 'EmailSentUser';
        id: string;
        recipientEmail: string;
        latestEmailStatus: GQEmailStatus;
      }>;
    } | null;
  }>;
};

export type GQEngagementTaskBatchForBulkDeployStatusQueryVariables = Exact<{
  engagementTaskBatchId: Scalars['ID']['input'];
}>;

export type GQEngagementTaskBatchForBulkDeployStatusQuery = {
  __typename?: 'Query';
  engagementTaskBatch: {
    __typename?: 'EngagementTaskBatch';
    id: string;
    deployStatuses: Array<{
      __typename?: 'EngagementTaskDeployStatus';
      id: string;
      success: boolean;
      latestContactEmail: string | null;
      company: {
        __typename?: 'Company';
        id: string;
        name: string;
        isWatershedCorporateCustomer: boolean;
        contacts: Array<{
          __typename?: 'SupplierContact';
          id: string;
          name: string;
          role: string | null;
          email: string;
        }>;
      };
      latestEmailSent: {
        __typename?: 'EmailSent';
        id: string;
        users: Array<{
          __typename?: 'EmailSentUser';
          id: string;
          recipientEmail: string;
          latestEmailStatus: GQEmailStatus;
        }>;
      } | null;
    }>;
  };
};

export type GQEngagementTaskStatusesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQEngagementTaskStatusesQuery = {
  __typename?: 'Query';
  engagementTasks: {
    __typename?: 'EngagementTaskConnection';
    edges: Array<{
      __typename?: 'EngagementTaskEdge';
      node: {
        __typename?: 'EngagementTask';
        companyId: string;
        status: GQCompanySurveyStatus;
        engagementTaskBatchId: string | null;
      } | null;
    }>;
  };
};

export type GQGetOrSendEmailsMutationVariables = Exact<{
  input: GQGetOrSendEmailsInput;
}>;

export type GQGetOrSendEmailsMutation = {
  __typename?: 'Mutation';
  getOrSendEmails: {
    __typename?: 'GetOrSendEmailsPayload';
    defaultSubject: string;
    defaultBlurb: string;
    sampleEmail: {
      __typename?: 'EmailContents';
      subject: string;
      bodyHtml: string;
      recipients: Array<{
        __typename?: 'EmailUser';
        email: string;
        name: string;
      }>;
      ccs: Array<{
        __typename?: 'EmailUser';
        email: string;
        name: string;
      }> | null;
      sender: { __typename?: 'EmailUser'; email: string; name: string };
      bccs: Array<{
        __typename?: 'EmailUser';
        email: string;
        name: string;
      } | null> | null;
    };
  };
};

export type GQNotifyEngagementTaskCreationMutationVariables = Exact<{
  input: GQNotifyEngagementTaskCreationInput;
}>;

export type GQNotifyEngagementTaskCreationMutation = {
  __typename?: 'Mutation';
  notifyEngagementTaskCreation: {
    __typename?: 'NotifyEngagementTaskCreationPayload';
    engagementTaskBatch: {
      __typename?: 'EngagementTaskBatch';
      id: string;
      deployStatuses: Array<{
        __typename?: 'EngagementTaskDeployStatus';
        id: string;
        latestEmailSent: {
          __typename?: 'EmailSent';
          id: string;
          users: Array<{
            __typename?: 'EmailSentUser';
            id: string;
            latestEmailStatus: GQEmailStatus;
          }>;
        } | null;
      }>;
    };
  } | null;
};

export type GQBulkDeployToSuppliersMutationVariables = Exact<{
  input: GQBulkStartCompanySurveyEngagementInput;
}>;

export type GQBulkDeployToSuppliersMutation = {
  __typename?: 'Mutation';
  bulkStartCompanySurveyEngagement: {
    __typename?: 'BulkStartCompanySurveyEngagementPayload';
    engagementTaskBatchIds: Array<string>;
  } | null;
};

export type GQSetEngagementCohortsForCompanyMutationVariables = Exact<{
  input: GQSetEngagementCohortsForCompanyInput;
}>;

export type GQSetEngagementCohortsForCompanyMutation = {
  __typename?: 'Mutation';
  setEngagementCohortsForCompany: {
    __typename?: 'SetEngagementCohortsForCompanyPayload';
    cohortIds: Array<string>;
    companyId: string;
  };
};

export type GQCreateEngagementCohortsForCompanyMutationVariables = Exact<{
  input: GQCreateEngagementCohortsForCompanyInput;
}>;

export type GQCreateEngagementCohortsForCompanyMutation = {
  __typename?: 'Mutation';
  createEngagementCohortsForCompany: Array<{
    __typename?: 'EngagementCohort';
    id: string;
    name: string;
  }>;
};

export type GQUpdateEngagementTaskConfigMutationVariables = Exact<{
  input: GQUpdateEngagementTaskConfigInput;
}>;

export type GQUpdateEngagementTaskConfigMutation = {
  __typename?: 'Mutation';
  updateEngagementTaskConfig: {
    __typename?: 'UpdateEngagementTaskConfigPayload';
    engagementTaskConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      name: string;
      sidebarTitle: string;
      defaultSupplierFacingDescription: string | null;
      rootFacingDescription: string | null;
      orgId: string | null;
      type: GQEngagementTaskConfigType | null;
      configHasTaskAssignedToCompany: boolean;
      timeNeeded: number | null;
      category: GQSurveyDefinitionCategory;
      engagementTaskInputs: Array<GQEngagementTaskInput>;
      optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          answerIsPrivate: boolean;
          videoLink: string | null;
        }>;
      }>;
      baseConfig: {
        __typename?: 'EngagementTaskConfig';
        id: string;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
      } | null;
    };
  } | null;
};

export type GQEngagementTaskQuestionFieldsFragment = {
  __typename?: 'EngagementTaskQuestion';
  id: string;
  label: string | null;
  description: string | null;
  componentId: GQEngagementTaskQuestionComponentId;
  editable: boolean;
  deletable: boolean;
  questionKey: string;
  statusJson: any;
  whyCollectThis: string | null;
  answerIsPrivate: boolean;
  videoLink: string | null;
};

export type GQEngagementTaskContainerFieldsFragment = {
  __typename?: 'EngagementTaskContainer';
  id: string;
  label: string;
  description: string | null;
  componentId: GQEngagementTaskContainerComponentId | null;
  editable: boolean;
  statusJson: any;
  sidebarStepLabelOverride: string | null;
  eyebrowText: string | null;
  headerImage: GQEngagementTaskImageId | null;
  questions: Array<{
    __typename?: 'EngagementTaskQuestion';
    id: string;
    label: string | null;
    description: string | null;
    componentId: GQEngagementTaskQuestionComponentId;
    editable: boolean;
    deletable: boolean;
    questionKey: string;
    statusJson: any;
    whyCollectThis: string | null;
    answerIsPrivate: boolean;
    videoLink: string | null;
  }>;
};

export type GQEngagementTaskContainerWithSubSectionFieldsFragment = {
  __typename?: 'EngagementTaskContainer';
  id: string;
  label: string;
  description: string | null;
  componentId: GQEngagementTaskContainerComponentId | null;
  editable: boolean;
  statusJson: any;
  sidebarStepLabelOverride: string | null;
  eyebrowText: string | null;
  headerImage: GQEngagementTaskImageId | null;
  containers: Array<{
    __typename?: 'EngagementTaskContainer';
    id: string;
    label: string;
    description: string | null;
    componentId: GQEngagementTaskContainerComponentId | null;
    editable: boolean;
    statusJson: any;
    sidebarStepLabelOverride: string | null;
    eyebrowText: string | null;
    headerImage: GQEngagementTaskImageId | null;
    questions: Array<{
      __typename?: 'EngagementTaskQuestion';
      id: string;
      label: string | null;
      description: string | null;
      componentId: GQEngagementTaskQuestionComponentId;
      editable: boolean;
      deletable: boolean;
      questionKey: string;
      statusJson: any;
      whyCollectThis: string | null;
      answerIsPrivate: boolean;
      videoLink: string | null;
    }>;
  }>;
  questions: Array<{
    __typename?: 'EngagementTaskQuestion';
    id: string;
    label: string | null;
    description: string | null;
    componentId: GQEngagementTaskQuestionComponentId;
    editable: boolean;
    deletable: boolean;
    questionKey: string;
    statusJson: any;
    whyCollectThis: string | null;
    answerIsPrivate: boolean;
    videoLink: string | null;
  }>;
};

export type GQEngagementTaskConfigFieldsFragment = {
  __typename?: 'EngagementTaskConfig';
  id: string;
  name: string;
  sidebarTitle: string;
  defaultSupplierFacingDescription: string | null;
  rootFacingDescription: string | null;
  orgId: string | null;
  type: GQEngagementTaskConfigType | null;
  configHasTaskAssignedToCompany: boolean;
  timeNeeded: number | null;
  category: GQSurveyDefinitionCategory;
  engagementTaskInputs: Array<GQEngagementTaskInput>;
  optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
  containers: Array<{
    __typename?: 'EngagementTaskContainer';
    id: string;
    label: string;
    description: string | null;
    componentId: GQEngagementTaskContainerComponentId | null;
    editable: boolean;
    statusJson: any;
    sidebarStepLabelOverride: string | null;
    eyebrowText: string | null;
    headerImage: GQEngagementTaskImageId | null;
    containers: Array<{
      __typename?: 'EngagementTaskContainer';
      id: string;
      label: string;
      description: string | null;
      componentId: GQEngagementTaskContainerComponentId | null;
      editable: boolean;
      statusJson: any;
      sidebarStepLabelOverride: string | null;
      eyebrowText: string | null;
      headerImage: GQEngagementTaskImageId | null;
      questions: Array<{
        __typename?: 'EngagementTaskQuestion';
        id: string;
        label: string | null;
        description: string | null;
        componentId: GQEngagementTaskQuestionComponentId;
        editable: boolean;
        deletable: boolean;
        questionKey: string;
        statusJson: any;
        whyCollectThis: string | null;
        answerIsPrivate: boolean;
        videoLink: string | null;
      }>;
    }>;
    questions: Array<{
      __typename?: 'EngagementTaskQuestion';
      id: string;
      label: string | null;
      description: string | null;
      componentId: GQEngagementTaskQuestionComponentId;
      editable: boolean;
      deletable: boolean;
      questionKey: string;
      statusJson: any;
      whyCollectThis: string | null;
      answerIsPrivate: boolean;
      videoLink: string | null;
    }>;
  }>;
  baseConfig: {
    __typename?: 'EngagementTaskConfig';
    id: string;
    containers: Array<{
      __typename?: 'EngagementTaskContainer';
      id: string;
      label: string;
      description: string | null;
      componentId: GQEngagementTaskContainerComponentId | null;
      editable: boolean;
      statusJson: any;
      sidebarStepLabelOverride: string | null;
      eyebrowText: string | null;
      headerImage: GQEngagementTaskImageId | null;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          answerIsPrivate: boolean;
          videoLink: string | null;
        }>;
      }>;
      questions: Array<{
        __typename?: 'EngagementTaskQuestion';
        id: string;
        label: string | null;
        description: string | null;
        componentId: GQEngagementTaskQuestionComponentId;
        editable: boolean;
        deletable: boolean;
        questionKey: string;
        statusJson: any;
        whyCollectThis: string | null;
        answerIsPrivate: boolean;
        videoLink: string | null;
      }>;
    }>;
  } | null;
};

export type GQTaskConfigurationPageQueryVariables = Exact<{
  engagementTaskConfigId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
}>;

export type GQTaskConfigurationPageQuery = {
  __typename?: 'Query';
  engagementTaskConfig: {
    __typename?: 'EngagementTaskConfig';
    id: string;
    name: string;
    sidebarTitle: string;
    defaultSupplierFacingDescription: string | null;
    rootFacingDescription: string | null;
    orgId: string | null;
    type: GQEngagementTaskConfigType | null;
    configHasTaskAssignedToCompany: boolean;
    timeNeeded: number | null;
    category: GQSurveyDefinitionCategory;
    engagementTaskInputs: Array<GQEngagementTaskInput>;
    optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
    containers: Array<{
      __typename?: 'EngagementTaskContainer';
      id: string;
      label: string;
      description: string | null;
      componentId: GQEngagementTaskContainerComponentId | null;
      editable: boolean;
      statusJson: any;
      sidebarStepLabelOverride: string | null;
      eyebrowText: string | null;
      headerImage: GQEngagementTaskImageId | null;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          answerIsPrivate: boolean;
          videoLink: string | null;
        }>;
      }>;
      questions: Array<{
        __typename?: 'EngagementTaskQuestion';
        id: string;
        label: string | null;
        description: string | null;
        componentId: GQEngagementTaskQuestionComponentId;
        editable: boolean;
        deletable: boolean;
        questionKey: string;
        statusJson: any;
        whyCollectThis: string | null;
        answerIsPrivate: boolean;
        videoLink: string | null;
      }>;
    }>;
    baseConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          answerIsPrivate: boolean;
          videoLink: string | null;
        }>;
      }>;
    } | null;
  };
  companyForDashboard: {
    __typename?: 'Company';
    id: string;
    name: string;
    fullLogoUrl: string | null;
    naicsCode: string | null;
  } | null;
};

export type GQSupplierCategorizedEmissionDataFieldsFragment = {
  __typename?: 'CategorizedEmissionData';
  businessCategory: string;
  businessSubcategory: string | null;
  proportion: number;
  amountKgco2e: number;
};

export type GQSupplierHistoricalEmissionsFieldsFragment = {
  __typename?: 'SupplierHistoricalEmissions';
  scope1: number | null;
  scope2: number | null;
  scope3: number | null;
  scope301: number | null;
  scope302: number | null;
  scope303: number | null;
  scope304: number | null;
  scope305: number | null;
  scope306: number | null;
  scope307: number | null;
  scope308: number | null;
  scope309: number | null;
  scope310: number | null;
  scope311: number | null;
  scope312: number | null;
  scope313: number | null;
  scope314: number | null;
  scope315: number | null;
  scope316: number | null;
  scope317: number | null;
  scope1Ratio: number | null;
  scope2Ratio: number | null;
  scope3Ratio: number | null;
  publishingYear: number | null;
  reportingYear: number;
  surveyId: string | null;
  source: GQEmissionsSource;
  revenueUsd: number | null;
  revenue: number | null;
  revenueCurrency: string | null;
  publicUrl: string | null;
  units: GQCompanyEmissionsUnits;
  expenseCategory: string | null;
};

export type GQSupplierCustomDataFieldsFragment = {
  __typename?: 'SupplierCustomData';
  id: string;
  priority: GQSupplierPriority | null;
  notes: string | null;
  customData: any | null;
};

export type GQSupplierRowFieldsFragment = {
  __typename?: 'SupplierRow';
  id: string;
  name: string;
  groupingKey: string;
  categoryId: string;
  subcategoryId: string;
  kgco2eQuantity: number;
  convertedUnit: string;
  convertedQuantity: number;
  month: YearMonth | null;
  ghgCategoryId: string | null;
  beaCode: string | null;
};

export type GQCompanyFieldsForCcisAndParentsFragment = {
  __typename?: 'Company';
  id: string;
  naicsCode: string | null;
  sbtiStage: GQCompanySbtCommitmentStage;
  climateProgress: GQCompanyClimateProgress;
  sustainabilityWebsiteUrl: string | null;
  isWatershedCorporateCustomer: boolean;
  parentRelationships: Array<{
    __typename?: 'ParentRelationship';
    id: string;
    relationshipStartDate: YearMonth | null;
    relationshipEndDate: YearMonth | null;
    parent: {
      __typename?: 'Company';
      id: string;
      name: string;
      disclosuresV2: Array<{
        __typename?: 'Disclosure';
        id: string;
        company: { __typename?: 'Company'; id: string } | null;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          publishingYear: number;
          reportType: GQExternalReportType;
          publicUrl: string;
          qualityScore: GQDisclosureQualityScore | null;
          cdpVendorData: {
            __typename?: 'CdpVendorData';
            id: string;
            publishingYear: number;
            reportingYear: number;
            scope1Nonzero: boolean;
            scope2Nonzero: boolean;
            scope3Nonzero: boolean;
            totalEmissionsNonzero: boolean;
            scope301Or302Nonzero: boolean;
            scope1Verified: boolean;
            scope2Verified: boolean;
            scope3Verified: boolean;
            pctEvaluationStatusesMatchResponse: number;
            disclosureQualityScore: GQDisclosureQualityScore;
            scope301EvaluationStatus: GQScope3EvaluationStatus;
            scope302EvaluationStatus: GQScope3EvaluationStatus;
            scope303EvaluationStatus: GQScope3EvaluationStatus;
            scope304EvaluationStatus: GQScope3EvaluationStatus;
            scope305EvaluationStatus: GQScope3EvaluationStatus;
            scope306EvaluationStatus: GQScope3EvaluationStatus;
            scope307EvaluationStatus: GQScope3EvaluationStatus;
            scope308EvaluationStatus: GQScope3EvaluationStatus;
            scope309EvaluationStatus: GQScope3EvaluationStatus;
            scope310EvaluationStatus: GQScope3EvaluationStatus;
            scope311EvaluationStatus: GQScope3EvaluationStatus;
            scope312EvaluationStatus: GQScope3EvaluationStatus;
            scope313EvaluationStatus: GQScope3EvaluationStatus;
            scope314EvaluationStatus: GQScope3EvaluationStatus;
            scope315EvaluationStatus: GQScope3EvaluationStatus;
            scope316EvaluationStatus: GQScope3EvaluationStatus;
            scope317EvaluationStatus: GQScope3EvaluationStatus;
          } | null;
        } | null;
        privateDisclosure: {
          __typename?: 'PrivateDisclosure';
          id: string;
          companyId: string | null;
          orgId: string;
          orgName: string;
          surveyId: string | null;
          privateDisclosureType: GQPrivateDisclosureType;
          createdAt: Date;
          disclosureQualityScore: GQDisclosureQualityScore | null;
          disclosureQualityExplanation: string | null;
          thirdPartyVerified: boolean;
          cdpVendorData: {
            __typename?: 'CdpVendorData';
            id: string;
            publishingYear: number;
            reportingYear: number;
            scope1Nonzero: boolean;
            scope2Nonzero: boolean;
            scope3Nonzero: boolean;
            totalEmissionsNonzero: boolean;
            scope301Or302Nonzero: boolean;
            scope1Verified: boolean;
            scope2Verified: boolean;
            scope3Verified: boolean;
            pctEvaluationStatusesMatchResponse: number;
            disclosureQualityScore: GQDisclosureQualityScore;
            scope301EvaluationStatus: GQScope3EvaluationStatus;
            scope302EvaluationStatus: GQScope3EvaluationStatus;
            scope303EvaluationStatus: GQScope3EvaluationStatus;
            scope304EvaluationStatus: GQScope3EvaluationStatus;
            scope305EvaluationStatus: GQScope3EvaluationStatus;
            scope306EvaluationStatus: GQScope3EvaluationStatus;
            scope307EvaluationStatus: GQScope3EvaluationStatus;
            scope308EvaluationStatus: GQScope3EvaluationStatus;
            scope309EvaluationStatus: GQScope3EvaluationStatus;
            scope310EvaluationStatus: GQScope3EvaluationStatus;
            scope311EvaluationStatus: GQScope3EvaluationStatus;
            scope312EvaluationStatus: GQScope3EvaluationStatus;
            scope313EvaluationStatus: GQScope3EvaluationStatus;
            scope314EvaluationStatus: GQScope3EvaluationStatus;
            scope315EvaluationStatus: GQScope3EvaluationStatus;
            scope316EvaluationStatus: GQScope3EvaluationStatus;
            scope317EvaluationStatus: GQScope3EvaluationStatus;
          } | null;
        } | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          publicDisclosureId: string | null;
          reportingYear: number;
          numEmployees: number | null;
          revenue: number | null;
          revenueCurrency: string | null;
          revenueUsd: number | null;
          percentageCleanEnergy: number | null;
          totalMwh: number | null;
          units: GQCompanyEmissionsUnits;
          expenseCategory: string | null;
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
        }> | null;
        targets: Array<{
          __typename?: 'DisclosureTargetDetails';
          id: string;
          name: string;
          description: string;
          baseYear: YearMonth;
          emissionsType: GQDisclosureTargetEmissionsType;
          reductionRate: GQDisclosureTargetReductionRate;
          intensityType: GQDisclosureTargetIntensityType;
          unit: string | null;
          unitDescription: string | null;
          filters: {
            __typename?: 'FilterExpressionGroup';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitive';
              field: GQFilterFieldLegacy;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          };
          emissionsTarget: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
        }> | null;
        initiatives: Array<{
          __typename?: 'DisclosureInitiative';
          id: string;
          name: string | null;
          initiativeType: GQDisclosureInitiativeType;
          description: string | null;
          startYearMonth: YearMonth | null;
          endYearMonth: YearMonth | null;
          intensityType: GQDisclosureTargetIntensityType | null;
          emissionsTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          } | null;
          filters: {
            __typename?: 'FilterExpressionGroup';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitive';
              field: GQFilterFieldLegacy;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          } | null;
        }> | null;
        climateCommitments: Array<
          | {
              __typename?: 'CarbonNeutralCommitment';
              targetYear: number | null;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename?: 'CleanEnergyCommitment';
              targetYear: number | null;
              targetPercentageCleanEnergy: number | null;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename?: 'NetZeroCommitment';
              targetYear: number | null;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename?: 'ScienceBasedTargetCommitment';
              submittedToSBTi: boolean;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
              commitment: {
                __typename?: 'SBTCommitment';
                id: string;
                stage: GQSbtCommitmentStage | null;
                targetClassification: GQSbtTargetClassification | null;
                nearTermTargetYear: number | null;
                longTermTargetYear: number | null;
                baselineYear: number | null;
                netZeroCommitted: boolean | null;
                commitmentType: GQSbtCommitmentType | null;
                commitmentDeadline: Date | null;
                netZeroTargetYear: number | null;
              };
            }
        > | null;
      }>;
    };
  }>;
  parentRelationshipsForSidebar: Array<{
    __typename?: 'ParentRelationship';
    id: string;
    relationshipStartDate: YearMonth | null;
    relationshipEndDate: YearMonth | null;
    parent: { __typename?: 'Company'; id: string; name: string };
  }>;
};

export type GQSupplierFieldsFragment = {
  __typename?: 'Supplier';
  id: string;
  name: string;
  footprintVendorNames: Array<string>;
  supplierType: GQSupplierType;
  totalKgco2e: number;
  totalKgco2eFromFootprint: number | null;
  totalSpending: number | null;
  totalSpendUsd: number | null;
  isTotalSpendingPartial: boolean | null;
  latestDisclosureDateTime: Date | null;
  latestCdpDisclosurePublishingYear: number | null;
  percentEmissions: number;
  climateProgress: GQCompanyClimateProgress;
  sbtiStage: GQCompanySbtCommitmentStage;
  customDataV2: any | null;
  notes: string | null;
  ghgCategoryIds: Array<string | null> | null;
  company: {
    __typename?: 'Company';
    id: string;
    naicsCode: string | null;
    sbtiStage: GQCompanySbtCommitmentStage;
    climateProgress: GQCompanyClimateProgress;
    sustainabilityWebsiteUrl: string | null;
    isWatershedCorporateCustomer: boolean;
    parentRelationships: Array<{
      __typename?: 'ParentRelationship';
      id: string;
      relationshipStartDate: YearMonth | null;
      relationshipEndDate: YearMonth | null;
      parent: {
        __typename?: 'Company';
        id: string;
        name: string;
        disclosuresV2: Array<{
          __typename?: 'Disclosure';
          id: string;
          company: { __typename?: 'Company'; id: string } | null;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            publishingYear: number;
            reportType: GQExternalReportType;
            publicUrl: string;
            qualityScore: GQDisclosureQualityScore | null;
            cdpVendorData: {
              __typename?: 'CdpVendorData';
              id: string;
              publishingYear: number;
              reportingYear: number;
              scope1Nonzero: boolean;
              scope2Nonzero: boolean;
              scope3Nonzero: boolean;
              totalEmissionsNonzero: boolean;
              scope301Or302Nonzero: boolean;
              scope1Verified: boolean;
              scope2Verified: boolean;
              scope3Verified: boolean;
              pctEvaluationStatusesMatchResponse: number;
              disclosureQualityScore: GQDisclosureQualityScore;
              scope301EvaluationStatus: GQScope3EvaluationStatus;
              scope302EvaluationStatus: GQScope3EvaluationStatus;
              scope303EvaluationStatus: GQScope3EvaluationStatus;
              scope304EvaluationStatus: GQScope3EvaluationStatus;
              scope305EvaluationStatus: GQScope3EvaluationStatus;
              scope306EvaluationStatus: GQScope3EvaluationStatus;
              scope307EvaluationStatus: GQScope3EvaluationStatus;
              scope308EvaluationStatus: GQScope3EvaluationStatus;
              scope309EvaluationStatus: GQScope3EvaluationStatus;
              scope310EvaluationStatus: GQScope3EvaluationStatus;
              scope311EvaluationStatus: GQScope3EvaluationStatus;
              scope312EvaluationStatus: GQScope3EvaluationStatus;
              scope313EvaluationStatus: GQScope3EvaluationStatus;
              scope314EvaluationStatus: GQScope3EvaluationStatus;
              scope315EvaluationStatus: GQScope3EvaluationStatus;
              scope316EvaluationStatus: GQScope3EvaluationStatus;
              scope317EvaluationStatus: GQScope3EvaluationStatus;
            } | null;
          } | null;
          privateDisclosure: {
            __typename?: 'PrivateDisclosure';
            id: string;
            companyId: string | null;
            orgId: string;
            orgName: string;
            surveyId: string | null;
            privateDisclosureType: GQPrivateDisclosureType;
            createdAt: Date;
            disclosureQualityScore: GQDisclosureQualityScore | null;
            disclosureQualityExplanation: string | null;
            thirdPartyVerified: boolean;
            cdpVendorData: {
              __typename?: 'CdpVendorData';
              id: string;
              publishingYear: number;
              reportingYear: number;
              scope1Nonzero: boolean;
              scope2Nonzero: boolean;
              scope3Nonzero: boolean;
              totalEmissionsNonzero: boolean;
              scope301Or302Nonzero: boolean;
              scope1Verified: boolean;
              scope2Verified: boolean;
              scope3Verified: boolean;
              pctEvaluationStatusesMatchResponse: number;
              disclosureQualityScore: GQDisclosureQualityScore;
              scope301EvaluationStatus: GQScope3EvaluationStatus;
              scope302EvaluationStatus: GQScope3EvaluationStatus;
              scope303EvaluationStatus: GQScope3EvaluationStatus;
              scope304EvaluationStatus: GQScope3EvaluationStatus;
              scope305EvaluationStatus: GQScope3EvaluationStatus;
              scope306EvaluationStatus: GQScope3EvaluationStatus;
              scope307EvaluationStatus: GQScope3EvaluationStatus;
              scope308EvaluationStatus: GQScope3EvaluationStatus;
              scope309EvaluationStatus: GQScope3EvaluationStatus;
              scope310EvaluationStatus: GQScope3EvaluationStatus;
              scope311EvaluationStatus: GQScope3EvaluationStatus;
              scope312EvaluationStatus: GQScope3EvaluationStatus;
              scope313EvaluationStatus: GQScope3EvaluationStatus;
              scope314EvaluationStatus: GQScope3EvaluationStatus;
              scope315EvaluationStatus: GQScope3EvaluationStatus;
              scope316EvaluationStatus: GQScope3EvaluationStatus;
              scope317EvaluationStatus: GQScope3EvaluationStatus;
            } | null;
          } | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            publicDisclosureId: string | null;
            reportingYear: number;
            numEmployees: number | null;
            revenue: number | null;
            revenueCurrency: string | null;
            revenueUsd: number | null;
            percentageCleanEnergy: number | null;
            totalMwh: number | null;
            units: GQCompanyEmissionsUnits;
            expenseCategory: string | null;
            scope1: number | null;
            scope2Market: number | null;
            scope2Location: number | null;
            scope3: number | null;
            scope301: number | null;
            scope302: number | null;
            scope303: number | null;
            scope304: number | null;
            scope305: number | null;
            scope306: number | null;
            scope307: number | null;
            scope308: number | null;
            scope309: number | null;
            scope310: number | null;
            scope311: number | null;
            scope312: number | null;
            scope313: number | null;
            scope314: number | null;
            scope315: number | null;
            scope316: number | null;
            scope317: number | null;
          }> | null;
          targets: Array<{
            __typename?: 'DisclosureTargetDetails';
            id: string;
            name: string;
            description: string;
            baseYear: YearMonth;
            emissionsType: GQDisclosureTargetEmissionsType;
            reductionRate: GQDisclosureTargetReductionRate;
            intensityType: GQDisclosureTargetIntensityType;
            unit: string | null;
            unitDescription: string | null;
            filters: {
              __typename?: 'FilterExpressionGroup';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitive';
                field: GQFilterFieldLegacy;
                operator: GQFilterOperator;
                value: Array<string>;
              }>;
            };
            emissionsTarget: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
          }> | null;
          initiatives: Array<{
            __typename?: 'DisclosureInitiative';
            id: string;
            name: string | null;
            initiativeType: GQDisclosureInitiativeType;
            description: string | null;
            startYearMonth: YearMonth | null;
            endYearMonth: YearMonth | null;
            intensityType: GQDisclosureTargetIntensityType | null;
            emissionsTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            } | null;
            filters: {
              __typename?: 'FilterExpressionGroup';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitive';
                field: GQFilterFieldLegacy;
                operator: GQFilterOperator;
                value: Array<string>;
              }>;
            } | null;
          }> | null;
          climateCommitments: Array<
            | {
                __typename?: 'CarbonNeutralCommitment';
                targetYear: number | null;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename?: 'CleanEnergyCommitment';
                targetYear: number | null;
                targetPercentageCleanEnergy: number | null;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename?: 'NetZeroCommitment';
                targetYear: number | null;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename?: 'ScienceBasedTargetCommitment';
                submittedToSBTi: boolean;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
                commitment: {
                  __typename?: 'SBTCommitment';
                  id: string;
                  stage: GQSbtCommitmentStage | null;
                  targetClassification: GQSbtTargetClassification | null;
                  nearTermTargetYear: number | null;
                  longTermTargetYear: number | null;
                  baselineYear: number | null;
                  netZeroCommitted: boolean | null;
                  commitmentType: GQSbtCommitmentType | null;
                  commitmentDeadline: Date | null;
                  netZeroTargetYear: number | null;
                };
              }
          > | null;
        }>;
      };
    }>;
    parentRelationshipsForSidebar: Array<{
      __typename?: 'ParentRelationship';
      id: string;
      relationshipStartDate: YearMonth | null;
      relationshipEndDate: YearMonth | null;
      parent: { __typename?: 'Company'; id: string; name: string };
    }>;
  } | null;
  historicalEmissions: {
    __typename?: 'SupplierHistoricalEmissions';
    scope1: number | null;
    scope2: number | null;
    scope3: number | null;
    scope301: number | null;
    scope302: number | null;
    scope303: number | null;
    scope304: number | null;
    scope305: number | null;
    scope306: number | null;
    scope307: number | null;
    scope308: number | null;
    scope309: number | null;
    scope310: number | null;
    scope311: number | null;
    scope312: number | null;
    scope313: number | null;
    scope314: number | null;
    scope315: number | null;
    scope316: number | null;
    scope317: number | null;
    scope1Ratio: number | null;
    scope2Ratio: number | null;
    scope3Ratio: number | null;
    publishingYear: number | null;
    reportingYear: number;
    surveyId: string | null;
    source: GQEmissionsSource;
    revenueUsd: number | null;
    revenue: number | null;
    revenueCurrency: string | null;
    publicUrl: string | null;
    units: GQCompanyEmissionsUnits;
    expenseCategory: string | null;
  } | null;
  industryAverageAllocatedEmissions: {
    __typename?: 'SupplierHistoricalEmissions';
    scope1: number | null;
    scope2: number | null;
    scope3: number | null;
    scope301: number | null;
    scope302: number | null;
    scope303: number | null;
    scope304: number | null;
    scope305: number | null;
    scope306: number | null;
    scope307: number | null;
    scope308: number | null;
    scope309: number | null;
    scope310: number | null;
    scope311: number | null;
    scope312: number | null;
    scope313: number | null;
    scope314: number | null;
    scope315: number | null;
    scope316: number | null;
    scope317: number | null;
    scope1Ratio: number | null;
    scope2Ratio: number | null;
    scope3Ratio: number | null;
    publishingYear: number | null;
    reportingYear: number;
    surveyId: string | null;
    source: GQEmissionsSource;
    revenueUsd: number | null;
    revenue: number | null;
    revenueCurrency: string | null;
    publicUrl: string | null;
    units: GQCompanyEmissionsUnits;
    expenseCategory: string | null;
  } | null;
  footprintTags: Array<{
    __typename?: 'StringKeyValue';
    key: string;
    value: string | null;
  }>;
};

export type GQSupplierDisclosureHistoricalEmissionsYearFieldsFragment = {
  __typename?: 'EmissionsYear';
  id: string;
  publicDisclosureId: string | null;
  reportingYear: number;
  numEmployees: number | null;
  revenue: number | null;
  revenueCurrency: string | null;
  revenueUsd: number | null;
  percentageCleanEnergy: number | null;
  totalMwh: number | null;
  units: GQCompanyEmissionsUnits;
  expenseCategory: string | null;
  scope1: number | null;
  scope2Market: number | null;
  scope2Location: number | null;
  scope3: number | null;
  scope301: number | null;
  scope302: number | null;
  scope303: number | null;
  scope304: number | null;
  scope305: number | null;
  scope306: number | null;
  scope307: number | null;
  scope308: number | null;
  scope309: number | null;
  scope310: number | null;
  scope311: number | null;
  scope312: number | null;
  scope313: number | null;
  scope314: number | null;
  scope315: number | null;
  scope316: number | null;
  scope317: number | null;
};

export type GQSimpleTimeseriesForDisclosuresFragment = {
  __typename?: 'SimpleTimeseries';
  id: string | null;
  base: Date;
  frequency: GQTimeseriesFrequency;
  values: Array<number>;
};

export type GQSupplierDisclosureTargetFieldsFragment = {
  __typename?: 'DisclosureTargetDetails';
  id: string;
  name: string;
  description: string;
  baseYear: YearMonth;
  emissionsType: GQDisclosureTargetEmissionsType;
  reductionRate: GQDisclosureTargetReductionRate;
  intensityType: GQDisclosureTargetIntensityType;
  unit: string | null;
  unitDescription: string | null;
  filters: {
    __typename?: 'FilterExpressionGroup';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitive';
      field: GQFilterFieldLegacy;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  };
  emissionsTarget: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
};

export type GQSupplierDisclosureInitiativeFieldsFragment = {
  __typename?: 'DisclosureInitiative';
  id: string;
  name: string | null;
  initiativeType: GQDisclosureInitiativeType;
  description: string | null;
  startYearMonth: YearMonth | null;
  endYearMonth: YearMonth | null;
  intensityType: GQDisclosureTargetIntensityType | null;
  emissionsTimeseries: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  } | null;
  filters: {
    __typename?: 'FilterExpressionGroup';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitive';
      field: GQFilterFieldLegacy;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  } | null;
};

export type GQSupplierPrivateDisclosureFieldsFragment = {
  __typename?: 'PrivateDisclosure';
  id: string;
  companyId: string | null;
  orgId: string;
  orgName: string;
  surveyId: string | null;
  privateDisclosureType: GQPrivateDisclosureType;
  createdAt: Date;
  disclosureQualityScore: GQDisclosureQualityScore | null;
  disclosureQualityExplanation: string | null;
  thirdPartyVerified: boolean;
  cdpVendorData: {
    __typename?: 'CdpVendorData';
    id: string;
    publishingYear: number;
    reportingYear: number;
    scope1Nonzero: boolean;
    scope2Nonzero: boolean;
    scope3Nonzero: boolean;
    totalEmissionsNonzero: boolean;
    scope301Or302Nonzero: boolean;
    scope1Verified: boolean;
    scope2Verified: boolean;
    scope3Verified: boolean;
    pctEvaluationStatusesMatchResponse: number;
    disclosureQualityScore: GQDisclosureQualityScore;
    scope301EvaluationStatus: GQScope3EvaluationStatus;
    scope302EvaluationStatus: GQScope3EvaluationStatus;
    scope303EvaluationStatus: GQScope3EvaluationStatus;
    scope304EvaluationStatus: GQScope3EvaluationStatus;
    scope305EvaluationStatus: GQScope3EvaluationStatus;
    scope306EvaluationStatus: GQScope3EvaluationStatus;
    scope307EvaluationStatus: GQScope3EvaluationStatus;
    scope308EvaluationStatus: GQScope3EvaluationStatus;
    scope309EvaluationStatus: GQScope3EvaluationStatus;
    scope310EvaluationStatus: GQScope3EvaluationStatus;
    scope311EvaluationStatus: GQScope3EvaluationStatus;
    scope312EvaluationStatus: GQScope3EvaluationStatus;
    scope313EvaluationStatus: GQScope3EvaluationStatus;
    scope314EvaluationStatus: GQScope3EvaluationStatus;
    scope315EvaluationStatus: GQScope3EvaluationStatus;
    scope316EvaluationStatus: GQScope3EvaluationStatus;
    scope317EvaluationStatus: GQScope3EvaluationStatus;
  } | null;
};

export type GQSupplierDisclosureFieldsFragment = {
  __typename?: 'Disclosure';
  id: string;
  company: { __typename?: 'Company'; id: string } | null;
  publicDisclosure: {
    __typename?: 'PublicDisclosure';
    id: string;
    publishingYear: number;
    reportType: GQExternalReportType;
    publicUrl: string;
    qualityScore: GQDisclosureQualityScore | null;
    cdpVendorData: {
      __typename?: 'CdpVendorData';
      id: string;
      publishingYear: number;
      reportingYear: number;
      scope1Nonzero: boolean;
      scope2Nonzero: boolean;
      scope3Nonzero: boolean;
      totalEmissionsNonzero: boolean;
      scope301Or302Nonzero: boolean;
      scope1Verified: boolean;
      scope2Verified: boolean;
      scope3Verified: boolean;
      pctEvaluationStatusesMatchResponse: number;
      disclosureQualityScore: GQDisclosureQualityScore;
      scope301EvaluationStatus: GQScope3EvaluationStatus;
      scope302EvaluationStatus: GQScope3EvaluationStatus;
      scope303EvaluationStatus: GQScope3EvaluationStatus;
      scope304EvaluationStatus: GQScope3EvaluationStatus;
      scope305EvaluationStatus: GQScope3EvaluationStatus;
      scope306EvaluationStatus: GQScope3EvaluationStatus;
      scope307EvaluationStatus: GQScope3EvaluationStatus;
      scope308EvaluationStatus: GQScope3EvaluationStatus;
      scope309EvaluationStatus: GQScope3EvaluationStatus;
      scope310EvaluationStatus: GQScope3EvaluationStatus;
      scope311EvaluationStatus: GQScope3EvaluationStatus;
      scope312EvaluationStatus: GQScope3EvaluationStatus;
      scope313EvaluationStatus: GQScope3EvaluationStatus;
      scope314EvaluationStatus: GQScope3EvaluationStatus;
      scope315EvaluationStatus: GQScope3EvaluationStatus;
      scope316EvaluationStatus: GQScope3EvaluationStatus;
      scope317EvaluationStatus: GQScope3EvaluationStatus;
    } | null;
  } | null;
  privateDisclosure: {
    __typename?: 'PrivateDisclosure';
    id: string;
    companyId: string | null;
    orgId: string;
    orgName: string;
    surveyId: string | null;
    privateDisclosureType: GQPrivateDisclosureType;
    createdAt: Date;
    disclosureQualityScore: GQDisclosureQualityScore | null;
    disclosureQualityExplanation: string | null;
    thirdPartyVerified: boolean;
    cdpVendorData: {
      __typename?: 'CdpVendorData';
      id: string;
      publishingYear: number;
      reportingYear: number;
      scope1Nonzero: boolean;
      scope2Nonzero: boolean;
      scope3Nonzero: boolean;
      totalEmissionsNonzero: boolean;
      scope301Or302Nonzero: boolean;
      scope1Verified: boolean;
      scope2Verified: boolean;
      scope3Verified: boolean;
      pctEvaluationStatusesMatchResponse: number;
      disclosureQualityScore: GQDisclosureQualityScore;
      scope301EvaluationStatus: GQScope3EvaluationStatus;
      scope302EvaluationStatus: GQScope3EvaluationStatus;
      scope303EvaluationStatus: GQScope3EvaluationStatus;
      scope304EvaluationStatus: GQScope3EvaluationStatus;
      scope305EvaluationStatus: GQScope3EvaluationStatus;
      scope306EvaluationStatus: GQScope3EvaluationStatus;
      scope307EvaluationStatus: GQScope3EvaluationStatus;
      scope308EvaluationStatus: GQScope3EvaluationStatus;
      scope309EvaluationStatus: GQScope3EvaluationStatus;
      scope310EvaluationStatus: GQScope3EvaluationStatus;
      scope311EvaluationStatus: GQScope3EvaluationStatus;
      scope312EvaluationStatus: GQScope3EvaluationStatus;
      scope313EvaluationStatus: GQScope3EvaluationStatus;
      scope314EvaluationStatus: GQScope3EvaluationStatus;
      scope315EvaluationStatus: GQScope3EvaluationStatus;
      scope316EvaluationStatus: GQScope3EvaluationStatus;
      scope317EvaluationStatus: GQScope3EvaluationStatus;
    } | null;
  } | null;
  historicalEmissionsYears: Array<{
    __typename?: 'EmissionsYear';
    id: string;
    publicDisclosureId: string | null;
    reportingYear: number;
    numEmployees: number | null;
    revenue: number | null;
    revenueCurrency: string | null;
    revenueUsd: number | null;
    percentageCleanEnergy: number | null;
    totalMwh: number | null;
    units: GQCompanyEmissionsUnits;
    expenseCategory: string | null;
    scope1: number | null;
    scope2Market: number | null;
    scope2Location: number | null;
    scope3: number | null;
    scope301: number | null;
    scope302: number | null;
    scope303: number | null;
    scope304: number | null;
    scope305: number | null;
    scope306: number | null;
    scope307: number | null;
    scope308: number | null;
    scope309: number | null;
    scope310: number | null;
    scope311: number | null;
    scope312: number | null;
    scope313: number | null;
    scope314: number | null;
    scope315: number | null;
    scope316: number | null;
    scope317: number | null;
  }> | null;
  targets: Array<{
    __typename?: 'DisclosureTargetDetails';
    id: string;
    name: string;
    description: string;
    baseYear: YearMonth;
    emissionsType: GQDisclosureTargetEmissionsType;
    reductionRate: GQDisclosureTargetReductionRate;
    intensityType: GQDisclosureTargetIntensityType;
    unit: string | null;
    unitDescription: string | null;
    filters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    };
    emissionsTarget: {
      __typename?: 'SimpleTimeseries';
      id: string | null;
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
  }> | null;
  initiatives: Array<{
    __typename?: 'DisclosureInitiative';
    id: string;
    name: string | null;
    initiativeType: GQDisclosureInitiativeType;
    description: string | null;
    startYearMonth: YearMonth | null;
    endYearMonth: YearMonth | null;
    intensityType: GQDisclosureTargetIntensityType | null;
    emissionsTimeseries: {
      __typename?: 'SimpleTimeseries';
      id: string | null;
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    } | null;
    filters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    } | null;
  }> | null;
  climateCommitments: Array<
    | {
        __typename?: 'CarbonNeutralCommitment';
        targetYear: number | null;
        id: string;
        kind: GQCompanyClimateCommitmentKind;
        description: string | null;
        externalUrl: string | null;
        commitmentMadeDate: Date | null;
        commitmentPeriodStart: Date | null;
        commitmentPeriodEnd: Date | null;
      }
    | {
        __typename?: 'CleanEnergyCommitment';
        targetYear: number | null;
        targetPercentageCleanEnergy: number | null;
        id: string;
        kind: GQCompanyClimateCommitmentKind;
        description: string | null;
        externalUrl: string | null;
        commitmentMadeDate: Date | null;
        commitmentPeriodStart: Date | null;
        commitmentPeriodEnd: Date | null;
      }
    | {
        __typename?: 'NetZeroCommitment';
        targetYear: number | null;
        id: string;
        kind: GQCompanyClimateCommitmentKind;
        description: string | null;
        externalUrl: string | null;
        commitmentMadeDate: Date | null;
        commitmentPeriodStart: Date | null;
        commitmentPeriodEnd: Date | null;
      }
    | {
        __typename?: 'ScienceBasedTargetCommitment';
        submittedToSBTi: boolean;
        id: string;
        kind: GQCompanyClimateCommitmentKind;
        description: string | null;
        externalUrl: string | null;
        commitmentMadeDate: Date | null;
        commitmentPeriodStart: Date | null;
        commitmentPeriodEnd: Date | null;
        commitment: {
          __typename?: 'SBTCommitment';
          id: string;
          stage: GQSbtCommitmentStage | null;
          targetClassification: GQSbtTargetClassification | null;
          nearTermTargetYear: number | null;
          longTermTargetYear: number | null;
          baselineYear: number | null;
          netZeroCommitted: boolean | null;
          commitmentType: GQSbtCommitmentType | null;
          commitmentDeadline: Date | null;
          netZeroTargetYear: number | null;
        };
      }
  > | null;
};

export type GQSupplierEmissionsFactorsFragment = {
  __typename?: 'EmissionsFactorRecordForDashboard';
  id: string;
  isVendorSpecific: boolean | null;
  efName: string | null;
  supplierDisclosure: {
    __typename?: 'Disclosure';
    id: string;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      reportingYear: number;
    }> | null;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      reportType: GQExternalReportType;
    } | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      privateDisclosureType: GQPrivateDisclosureType;
    } | null;
  } | null;
  emissionsFactorDescription: {
    __typename?: 'EmissionsFactorDescription';
    id: string;
    userVisibleEfName: string;
  } | null;
};

export type GQSupplierEmissionsModelEmissionsFactorsFragment = {
  __typename?: 'EmissionsModelEmissionsFactor';
  id: string;
  isSupplierSpecific: boolean | null;
  externalDescription: string | null;
  supplierDisclosure: {
    __typename?: 'Disclosure';
    id: string;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      reportingYear: number;
    }> | null;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      reportType: GQExternalReportType;
    } | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      privateDisclosureType: GQPrivateDisclosureType;
    } | null;
  } | null;
};

export type GQGetSupplierQueryVariables = Exact<{
  companyId: InputMaybe<Scalars['ID']['input']>;
  vendorName: InputMaybe<Scalars['String']['input']>;
  version: InputMaybe<Scalars['String']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  viewFilters: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
}>;

export type GQGetSupplierQuery = {
  __typename?: 'Query';
  supplier: {
    __typename?: 'Supplier';
    id: string;
    name: string;
    footprintVendorNames: Array<string>;
    supplierType: GQSupplierType;
    totalKgco2e: number;
    totalKgco2eFromFootprint: number | null;
    totalSpending: number | null;
    totalSpendUsd: number | null;
    isTotalSpendingPartial: boolean | null;
    latestDisclosureDateTime: Date | null;
    latestCdpDisclosurePublishingYear: number | null;
    percentEmissions: number;
    climateProgress: GQCompanyClimateProgress;
    sbtiStage: GQCompanySbtCommitmentStage;
    customDataV2: any | null;
    notes: string | null;
    ghgCategoryIds: Array<string | null> | null;
    company: {
      __typename?: 'Company';
      id: string;
      naicsCode: string | null;
      sbtiStage: GQCompanySbtCommitmentStage;
      climateProgress: GQCompanyClimateProgress;
      sustainabilityWebsiteUrl: string | null;
      isWatershedCorporateCustomer: boolean;
      parentRelationships: Array<{
        __typename?: 'ParentRelationship';
        id: string;
        relationshipStartDate: YearMonth | null;
        relationshipEndDate: YearMonth | null;
        parent: {
          __typename?: 'Company';
          id: string;
          name: string;
          disclosuresV2: Array<{
            __typename?: 'Disclosure';
            id: string;
            company: { __typename?: 'Company'; id: string } | null;
            publicDisclosure: {
              __typename?: 'PublicDisclosure';
              id: string;
              publishingYear: number;
              reportType: GQExternalReportType;
              publicUrl: string;
              qualityScore: GQDisclosureQualityScore | null;
              cdpVendorData: {
                __typename?: 'CdpVendorData';
                id: string;
                publishingYear: number;
                reportingYear: number;
                scope1Nonzero: boolean;
                scope2Nonzero: boolean;
                scope3Nonzero: boolean;
                totalEmissionsNonzero: boolean;
                scope301Or302Nonzero: boolean;
                scope1Verified: boolean;
                scope2Verified: boolean;
                scope3Verified: boolean;
                pctEvaluationStatusesMatchResponse: number;
                disclosureQualityScore: GQDisclosureQualityScore;
                scope301EvaluationStatus: GQScope3EvaluationStatus;
                scope302EvaluationStatus: GQScope3EvaluationStatus;
                scope303EvaluationStatus: GQScope3EvaluationStatus;
                scope304EvaluationStatus: GQScope3EvaluationStatus;
                scope305EvaluationStatus: GQScope3EvaluationStatus;
                scope306EvaluationStatus: GQScope3EvaluationStatus;
                scope307EvaluationStatus: GQScope3EvaluationStatus;
                scope308EvaluationStatus: GQScope3EvaluationStatus;
                scope309EvaluationStatus: GQScope3EvaluationStatus;
                scope310EvaluationStatus: GQScope3EvaluationStatus;
                scope311EvaluationStatus: GQScope3EvaluationStatus;
                scope312EvaluationStatus: GQScope3EvaluationStatus;
                scope313EvaluationStatus: GQScope3EvaluationStatus;
                scope314EvaluationStatus: GQScope3EvaluationStatus;
                scope315EvaluationStatus: GQScope3EvaluationStatus;
                scope316EvaluationStatus: GQScope3EvaluationStatus;
                scope317EvaluationStatus: GQScope3EvaluationStatus;
              } | null;
            } | null;
            privateDisclosure: {
              __typename?: 'PrivateDisclosure';
              id: string;
              companyId: string | null;
              orgId: string;
              orgName: string;
              surveyId: string | null;
              privateDisclosureType: GQPrivateDisclosureType;
              createdAt: Date;
              disclosureQualityScore: GQDisclosureQualityScore | null;
              disclosureQualityExplanation: string | null;
              thirdPartyVerified: boolean;
              cdpVendorData: {
                __typename?: 'CdpVendorData';
                id: string;
                publishingYear: number;
                reportingYear: number;
                scope1Nonzero: boolean;
                scope2Nonzero: boolean;
                scope3Nonzero: boolean;
                totalEmissionsNonzero: boolean;
                scope301Or302Nonzero: boolean;
                scope1Verified: boolean;
                scope2Verified: boolean;
                scope3Verified: boolean;
                pctEvaluationStatusesMatchResponse: number;
                disclosureQualityScore: GQDisclosureQualityScore;
                scope301EvaluationStatus: GQScope3EvaluationStatus;
                scope302EvaluationStatus: GQScope3EvaluationStatus;
                scope303EvaluationStatus: GQScope3EvaluationStatus;
                scope304EvaluationStatus: GQScope3EvaluationStatus;
                scope305EvaluationStatus: GQScope3EvaluationStatus;
                scope306EvaluationStatus: GQScope3EvaluationStatus;
                scope307EvaluationStatus: GQScope3EvaluationStatus;
                scope308EvaluationStatus: GQScope3EvaluationStatus;
                scope309EvaluationStatus: GQScope3EvaluationStatus;
                scope310EvaluationStatus: GQScope3EvaluationStatus;
                scope311EvaluationStatus: GQScope3EvaluationStatus;
                scope312EvaluationStatus: GQScope3EvaluationStatus;
                scope313EvaluationStatus: GQScope3EvaluationStatus;
                scope314EvaluationStatus: GQScope3EvaluationStatus;
                scope315EvaluationStatus: GQScope3EvaluationStatus;
                scope316EvaluationStatus: GQScope3EvaluationStatus;
                scope317EvaluationStatus: GQScope3EvaluationStatus;
              } | null;
            } | null;
            historicalEmissionsYears: Array<{
              __typename?: 'EmissionsYear';
              id: string;
              publicDisclosureId: string | null;
              reportingYear: number;
              numEmployees: number | null;
              revenue: number | null;
              revenueCurrency: string | null;
              revenueUsd: number | null;
              percentageCleanEnergy: number | null;
              totalMwh: number | null;
              units: GQCompanyEmissionsUnits;
              expenseCategory: string | null;
              scope1: number | null;
              scope2Market: number | null;
              scope2Location: number | null;
              scope3: number | null;
              scope301: number | null;
              scope302: number | null;
              scope303: number | null;
              scope304: number | null;
              scope305: number | null;
              scope306: number | null;
              scope307: number | null;
              scope308: number | null;
              scope309: number | null;
              scope310: number | null;
              scope311: number | null;
              scope312: number | null;
              scope313: number | null;
              scope314: number | null;
              scope315: number | null;
              scope316: number | null;
              scope317: number | null;
            }> | null;
            targets: Array<{
              __typename?: 'DisclosureTargetDetails';
              id: string;
              name: string;
              description: string;
              baseYear: YearMonth;
              emissionsType: GQDisclosureTargetEmissionsType;
              reductionRate: GQDisclosureTargetReductionRate;
              intensityType: GQDisclosureTargetIntensityType;
              unit: string | null;
              unitDescription: string | null;
              filters: {
                __typename?: 'FilterExpressionGroup';
                conjunction: GQFilterConjunction;
                expressions: Array<{
                  __typename?: 'FilterExpressionPrimitive';
                  field: GQFilterFieldLegacy;
                  operator: GQFilterOperator;
                  value: Array<string>;
                }>;
              };
              emissionsTarget: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              };
            }> | null;
            initiatives: Array<{
              __typename?: 'DisclosureInitiative';
              id: string;
              name: string | null;
              initiativeType: GQDisclosureInitiativeType;
              description: string | null;
              startYearMonth: YearMonth | null;
              endYearMonth: YearMonth | null;
              intensityType: GQDisclosureTargetIntensityType | null;
              emissionsTimeseries: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              } | null;
              filters: {
                __typename?: 'FilterExpressionGroup';
                conjunction: GQFilterConjunction;
                expressions: Array<{
                  __typename?: 'FilterExpressionPrimitive';
                  field: GQFilterFieldLegacy;
                  operator: GQFilterOperator;
                  value: Array<string>;
                }>;
              } | null;
            }> | null;
            climateCommitments: Array<
              | {
                  __typename?: 'CarbonNeutralCommitment';
                  targetYear: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'CleanEnergyCommitment';
                  targetYear: number | null;
                  targetPercentageCleanEnergy: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'NetZeroCommitment';
                  targetYear: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'ScienceBasedTargetCommitment';
                  submittedToSBTi: boolean;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                  commitment: {
                    __typename?: 'SBTCommitment';
                    id: string;
                    stage: GQSbtCommitmentStage | null;
                    targetClassification: GQSbtTargetClassification | null;
                    nearTermTargetYear: number | null;
                    longTermTargetYear: number | null;
                    baselineYear: number | null;
                    netZeroCommitted: boolean | null;
                    commitmentType: GQSbtCommitmentType | null;
                    commitmentDeadline: Date | null;
                    netZeroTargetYear: number | null;
                  };
                }
            > | null;
          }>;
        };
      }>;
      parentRelationshipsForSidebar: Array<{
        __typename?: 'ParentRelationship';
        id: string;
        relationshipStartDate: YearMonth | null;
        relationshipEndDate: YearMonth | null;
        parent: { __typename?: 'Company'; id: string; name: string };
      }>;
    } | null;
    historicalEmissions: {
      __typename?: 'SupplierHistoricalEmissions';
      scope1: number | null;
      scope2: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
      scope1Ratio: number | null;
      scope2Ratio: number | null;
      scope3Ratio: number | null;
      publishingYear: number | null;
      reportingYear: number;
      surveyId: string | null;
      source: GQEmissionsSource;
      revenueUsd: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      publicUrl: string | null;
      units: GQCompanyEmissionsUnits;
      expenseCategory: string | null;
    } | null;
    industryAverageAllocatedEmissions: {
      __typename?: 'SupplierHistoricalEmissions';
      scope1: number | null;
      scope2: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
      scope1Ratio: number | null;
      scope2Ratio: number | null;
      scope3Ratio: number | null;
      publishingYear: number | null;
      reportingYear: number;
      surveyId: string | null;
      source: GQEmissionsSource;
      revenueUsd: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      publicUrl: string | null;
      units: GQCompanyEmissionsUnits;
      expenseCategory: string | null;
    } | null;
    footprintTags: Array<{
      __typename?: 'StringKeyValue';
      key: string;
      value: string | null;
    }>;
  } | null;
  organization: {
    __typename?: 'Organization';
    id: string;
    engagementFunnels: Array<{
      __typename?: 'EngagementFunnel';
      id: string;
      name: string;
      displayOnSupplierDetailsPage: boolean | null;
      steps: Array<{
        __typename?: 'EngagementFunnelStep';
        id: string;
        stepName: string;
        stepType: GQEngagementFunnelStepType;
        sbtStage: GQCompanySbtCommitmentStage | null;
        engagementTaskConfigId: string | null;
        supplierTableColumnValues: Array<string> | null;
        supplierTableColumn: {
          __typename?: 'SupplierTableColumn';
          id: string;
          field: string;
        } | null;
      }>;
    }>;
  };
};

export type GQSupplierEmissionsByMonthFieldsFragment = {
  __typename?: 'LabeledTimeseries';
  label: string;
  timeseries: {
    __typename?: 'Timeseries';
    base: YearMonth;
    values: Array<number>;
  };
};

export type GQGetSupplierDetailedEmissionsQueryVariables = Exact<{
  companyId: InputMaybe<Scalars['ID']['input']>;
  vendorName: InputMaybe<Scalars['String']['input']>;
  version: InputMaybe<Scalars['String']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  viewFilters: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
}>;

export type GQGetSupplierDetailedEmissionsQuery = {
  __typename?: 'Query';
  supplierDetailedEmissions: {
    __typename?: 'SupplierDetailedEmissions';
    id: string;
    rows: Array<{
      __typename?: 'SupplierRow';
      id: string;
      name: string;
      groupingKey: string;
      categoryId: string;
      subcategoryId: string;
      kgco2eQuantity: number;
      convertedUnit: string;
      convertedQuantity: number;
      month: YearMonth | null;
      ghgCategoryId: string | null;
      beaCode: string | null;
    }>;
    emissionsByCategory: Array<{
      __typename?: 'CategorizedEmissionData';
      businessCategory: string;
      businessSubcategory: string | null;
      proportion: number;
      amountKgco2e: number;
    }>;
    emissionsByMonth: Array<{
      __typename?: 'LabeledTimeseries';
      label: string;
      timeseries: {
        __typename?: 'Timeseries';
        base: YearMonth;
        values: Array<number>;
      };
    }>;
    emissionsFactors: Array<{
      __typename?: 'EmissionsFactorRecordForDashboard';
      id: string;
      isVendorSpecific: boolean | null;
      efName: string | null;
      supplierDisclosure: {
        __typename?: 'Disclosure';
        id: string;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          reportingYear: number;
        }> | null;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          reportType: GQExternalReportType;
        } | null;
        privateDisclosure: {
          __typename?: 'PrivateDisclosure';
          id: string;
          privateDisclosureType: GQPrivateDisclosureType;
        } | null;
      } | null;
      emissionsFactorDescription: {
        __typename?: 'EmissionsFactorDescription';
        id: string;
        userVisibleEfName: string;
      } | null;
    }>;
    emissionsModelEmissionsFactors: Array<{
      __typename?: 'EmissionsModelEmissionsFactor';
      id: string;
      isSupplierSpecific: boolean | null;
      externalDescription: string | null;
      supplierDisclosure: {
        __typename?: 'Disclosure';
        id: string;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          reportingYear: number;
        }> | null;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          reportType: GQExternalReportType;
        } | null;
        privateDisclosure: {
          __typename?: 'PrivateDisclosure';
          id: string;
          privateDisclosureType: GQPrivateDisclosureType;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GQGetSupplierDetailsDataQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
}>;

export type GQGetSupplierDetailsDataQuery = {
  __typename?: 'Query';
  allDisclosures: Array<{
    __typename?: 'Disclosure';
    id: string;
    company: { __typename?: 'Company'; id: string } | null;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      publishingYear: number;
      reportType: GQExternalReportType;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      companyId: string | null;
      orgId: string;
      orgName: string;
      surveyId: string | null;
      privateDisclosureType: GQPrivateDisclosureType;
      createdAt: Date;
      disclosureQualityScore: GQDisclosureQualityScore | null;
      disclosureQualityExplanation: string | null;
      thirdPartyVerified: boolean;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      publicDisclosureId: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      revenueUsd: number | null;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      units: GQCompanyEmissionsUnits;
      expenseCategory: string | null;
      scope1: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
    }> | null;
    targets: Array<{
      __typename?: 'DisclosureTargetDetails';
      id: string;
      name: string;
      description: string;
      baseYear: YearMonth;
      emissionsType: GQDisclosureTargetEmissionsType;
      reductionRate: GQDisclosureTargetReductionRate;
      intensityType: GQDisclosureTargetIntensityType;
      unit: string | null;
      unitDescription: string | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
      emissionsTarget: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    }> | null;
    initiatives: Array<{
      __typename?: 'DisclosureInitiative';
      id: string;
      name: string | null;
      initiativeType: GQDisclosureInitiativeType;
      description: string | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      intensityType: GQDisclosureTargetIntensityType | null;
      emissionsTimeseries: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      } | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'NetZeroCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            netZeroCommitted: boolean | null;
            commitmentType: GQSbtCommitmentType | null;
            commitmentDeadline: Date | null;
            netZeroTargetYear: number | null;
          };
        }
    > | null;
  }>;
  historicalYears: Array<{
    __typename?: 'Disclosure';
    id: string;
    company: { __typename?: 'Company'; id: string } | null;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      publishingYear: number;
      reportType: GQExternalReportType;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      companyId: string | null;
      orgId: string;
      orgName: string;
      surveyId: string | null;
      privateDisclosureType: GQPrivateDisclosureType;
      createdAt: Date;
      disclosureQualityScore: GQDisclosureQualityScore | null;
      disclosureQualityExplanation: string | null;
      thirdPartyVerified: boolean;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      publicDisclosureId: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      revenueUsd: number | null;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      units: GQCompanyEmissionsUnits;
      expenseCategory: string | null;
      scope1: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
    }> | null;
    targets: Array<{
      __typename?: 'DisclosureTargetDetails';
      id: string;
      name: string;
      description: string;
      baseYear: YearMonth;
      emissionsType: GQDisclosureTargetEmissionsType;
      reductionRate: GQDisclosureTargetReductionRate;
      intensityType: GQDisclosureTargetIntensityType;
      unit: string | null;
      unitDescription: string | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
      emissionsTarget: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    }> | null;
    initiatives: Array<{
      __typename?: 'DisclosureInitiative';
      id: string;
      name: string | null;
      initiativeType: GQDisclosureInitiativeType;
      description: string | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      intensityType: GQDisclosureTargetIntensityType | null;
      emissionsTimeseries: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      } | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'NetZeroCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            netZeroCommitted: boolean | null;
            commitmentType: GQSbtCommitmentType | null;
            commitmentDeadline: Date | null;
            netZeroTargetYear: number | null;
          };
        }
    > | null;
  }>;
  targets: Array<{
    __typename?: 'Disclosure';
    id: string;
    company: { __typename?: 'Company'; id: string } | null;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      publishingYear: number;
      reportType: GQExternalReportType;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      companyId: string | null;
      orgId: string;
      orgName: string;
      surveyId: string | null;
      privateDisclosureType: GQPrivateDisclosureType;
      createdAt: Date;
      disclosureQualityScore: GQDisclosureQualityScore | null;
      disclosureQualityExplanation: string | null;
      thirdPartyVerified: boolean;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      publicDisclosureId: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      revenueUsd: number | null;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      units: GQCompanyEmissionsUnits;
      expenseCategory: string | null;
      scope1: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
    }> | null;
    targets: Array<{
      __typename?: 'DisclosureTargetDetails';
      id: string;
      name: string;
      description: string;
      baseYear: YearMonth;
      emissionsType: GQDisclosureTargetEmissionsType;
      reductionRate: GQDisclosureTargetReductionRate;
      intensityType: GQDisclosureTargetIntensityType;
      unit: string | null;
      unitDescription: string | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
      emissionsTarget: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    }> | null;
    initiatives: Array<{
      __typename?: 'DisclosureInitiative';
      id: string;
      name: string | null;
      initiativeType: GQDisclosureInitiativeType;
      description: string | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      intensityType: GQDisclosureTargetIntensityType | null;
      emissionsTimeseries: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      } | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'NetZeroCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            netZeroCommitted: boolean | null;
            commitmentType: GQSbtCommitmentType | null;
            commitmentDeadline: Date | null;
            netZeroTargetYear: number | null;
          };
        }
    > | null;
  }>;
  initiatives: Array<{
    __typename?: 'Disclosure';
    id: string;
    company: { __typename?: 'Company'; id: string } | null;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      publishingYear: number;
      reportType: GQExternalReportType;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      companyId: string | null;
      orgId: string;
      orgName: string;
      surveyId: string | null;
      privateDisclosureType: GQPrivateDisclosureType;
      createdAt: Date;
      disclosureQualityScore: GQDisclosureQualityScore | null;
      disclosureQualityExplanation: string | null;
      thirdPartyVerified: boolean;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      publicDisclosureId: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      revenueUsd: number | null;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      units: GQCompanyEmissionsUnits;
      expenseCategory: string | null;
      scope1: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
    }> | null;
    targets: Array<{
      __typename?: 'DisclosureTargetDetails';
      id: string;
      name: string;
      description: string;
      baseYear: YearMonth;
      emissionsType: GQDisclosureTargetEmissionsType;
      reductionRate: GQDisclosureTargetReductionRate;
      intensityType: GQDisclosureTargetIntensityType;
      unit: string | null;
      unitDescription: string | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
      emissionsTarget: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    }> | null;
    initiatives: Array<{
      __typename?: 'DisclosureInitiative';
      id: string;
      name: string | null;
      initiativeType: GQDisclosureInitiativeType;
      description: string | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      intensityType: GQDisclosureTargetIntensityType | null;
      emissionsTimeseries: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      } | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'NetZeroCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            netZeroCommitted: boolean | null;
            commitmentType: GQSbtCommitmentType | null;
            commitmentDeadline: Date | null;
            netZeroTargetYear: number | null;
          };
        }
    > | null;
  }>;
  climateCommitments: Array<{
    __typename?: 'Disclosure';
    id: string;
    company: { __typename?: 'Company'; id: string } | null;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      publishingYear: number;
      reportType: GQExternalReportType;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      companyId: string | null;
      orgId: string;
      orgName: string;
      surveyId: string | null;
      privateDisclosureType: GQPrivateDisclosureType;
      createdAt: Date;
      disclosureQualityScore: GQDisclosureQualityScore | null;
      disclosureQualityExplanation: string | null;
      thirdPartyVerified: boolean;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      publicDisclosureId: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      revenueUsd: number | null;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      units: GQCompanyEmissionsUnits;
      expenseCategory: string | null;
      scope1: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
    }> | null;
    targets: Array<{
      __typename?: 'DisclosureTargetDetails';
      id: string;
      name: string;
      description: string;
      baseYear: YearMonth;
      emissionsType: GQDisclosureTargetEmissionsType;
      reductionRate: GQDisclosureTargetReductionRate;
      intensityType: GQDisclosureTargetIntensityType;
      unit: string | null;
      unitDescription: string | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
      emissionsTarget: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    }> | null;
    initiatives: Array<{
      __typename?: 'DisclosureInitiative';
      id: string;
      name: string | null;
      initiativeType: GQDisclosureInitiativeType;
      description: string | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      intensityType: GQDisclosureTargetIntensityType | null;
      emissionsTimeseries: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      } | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'NetZeroCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            netZeroCommitted: boolean | null;
            commitmentType: GQSbtCommitmentType | null;
            commitmentDeadline: Date | null;
            netZeroTargetYear: number | null;
          };
        }
    > | null;
  }>;
};

export type GQSupplierTableColumnFieldsFragment = {
  __typename?: 'SupplierTableColumn';
  id: string;
  field: string;
  headerName: string | null;
  description: string | null;
  visible: boolean | null;
  columnFormat: GQSupplierTableColumnFormat | null;
  selectOptions: Array<string> | null;
  footprintTagName: string | null;
  csatTagName: string | null;
  isSupplierScore: boolean;
  isSupplierScoreCriteria: boolean;
  questionKey: string | null;
};

export type GQSupplierTableFieldsFragment = {
  __typename?: 'SupplierTable';
  columns: Array<{
    __typename?: 'SupplierTableColumn';
    id: string;
    field: string;
    headerName: string | null;
    description: string | null;
    visible: boolean | null;
    columnFormat: GQSupplierTableColumnFormat | null;
    selectOptions: Array<string> | null;
    footprintTagName: string | null;
    csatTagName: string | null;
    isSupplierScore: boolean;
    isSupplierScoreCriteria: boolean;
    questionKey: string | null;
  }>;
};

type GQSupplierChartConfigFields_SupplierEmissionsForecastChartConfig_Fragment =
  {
    __typename: 'SupplierEmissionsForecastChartConfig';
    id: string;
    orgId: string | null;
    title: string;
    description: string | null;
    chartType: GQSupplierChartType;
  };

type GQSupplierChartConfigFields_SupplierEngagementFunnelChartConfig_Fragment =
  {
    __typename: 'SupplierEngagementFunnelChartConfig';
    id: string;
    orgId: string | null;
    title: string;
    description: string | null;
    chartType: GQSupplierChartType;
    funnels: Array<{
      __typename?: 'EngagementFunnel';
      id: string;
      name: string;
      displayOnSupplierDetailsPage: boolean | null;
      steps: Array<{
        __typename?: 'EngagementFunnelStep';
        id: string;
        stepName: string;
        stepType: GQEngagementFunnelStepType;
        sbtStage: GQCompanySbtCommitmentStage | null;
        engagementTaskConfigId: string | null;
        supplierTableColumnValues: Array<string> | null;
        supplierTableColumn: {
          __typename?: 'SupplierTableColumn';
          id: string;
          field: string;
        } | null;
      }>;
    }>;
  };

type GQSupplierChartConfigFields_SupplierMetricsChartConfig_Fragment = {
  __typename: 'SupplierMetricsChartConfig';
  id: string;
  orgId: string | null;
  title: string;
  description: string | null;
  chartType: GQSupplierChartType;
  metricsTypes: Array<GQSupplyChainMetricType>;
};

type GQSupplierChartConfigFields_SupplierSbtCommitmentOverTimeChartConfig_Fragment =
  {
    __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
    colorScale: Array<string>;
    id: string;
    orgId: string | null;
    title: string;
    description: string | null;
    chartType: GQSupplierChartType;
  };

type GQSupplierChartConfigFields_SupplierStandardChartConfig_Fragment = {
  __typename: 'SupplierStandardChartConfig';
  colorScale: Array<string>;
  xField: string;
  aggregateType: GQSupplierChartAggregateType;
  id: string;
  orgId: string | null;
  title: string;
  description: string | null;
  chartType: GQSupplierChartType;
};

type GQSupplierChartConfigFields_SupplierTaskStatusChangeOverTimeChartConfig_Fragment =
  {
    __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
    colorScale: Array<string>;
    isCumulative: boolean | null;
    id: string;
    orgId: string | null;
    title: string;
    description: string | null;
    chartType: GQSupplierChartType;
    tasksStatusChangeConfig: Array<{
      __typename?: 'SurveyDefinitionAndStatus';
      status: string;
      engagementTaskConfigId: string;
    }> | null;
  };

export type GQSupplierChartConfigFieldsFragment =
  | GQSupplierChartConfigFields_SupplierEmissionsForecastChartConfig_Fragment
  | GQSupplierChartConfigFields_SupplierEngagementFunnelChartConfig_Fragment
  | GQSupplierChartConfigFields_SupplierMetricsChartConfig_Fragment
  | GQSupplierChartConfigFields_SupplierSbtCommitmentOverTimeChartConfig_Fragment
  | GQSupplierChartConfigFields_SupplierStandardChartConfig_Fragment
  | GQSupplierChartConfigFields_SupplierTaskStatusChangeOverTimeChartConfig_Fragment;

export type GQSupplierViewFieldsFragment = {
  __typename?: 'SupplierView';
  id: string;
  name: string;
  isDefault: boolean;
  reductionPlan: {
    __typename?: 'Plan';
    id: string;
    name: string;
    state: GQPlanState;
    createdAt: Date;
    isHidden: boolean;
    relevantFootprintFields: Array<string>;
    forecast: {
      __typename?: 'Forecast';
      id: string;
      footprintSnapshotId: string;
      overrideFootprintKind: string | null;
      referencePeriodInterval: YMInterval;
    };
  } | null;
  table: {
    __typename?: 'SupplierTable';
    columns: Array<{
      __typename?: 'SupplierTableColumn';
      id: string;
      field: string;
      headerName: string | null;
      description: string | null;
      visible: boolean | null;
      columnFormat: GQSupplierTableColumnFormat | null;
      selectOptions: Array<string> | null;
      footprintTagName: string | null;
      csatTagName: string | null;
      isSupplierScore: boolean;
      isSupplierScoreCriteria: boolean;
      questionKey: string | null;
    }>;
  };
  columnOrder: Array<{
    __typename?: 'SupplierViewColumnOrder';
    columnId: string;
    visible: boolean;
    width: number;
  }> | null;
  charts: Array<
    | {
        __typename: 'SupplierEmissionsForecastChartConfig';
        id: string;
        orgId: string | null;
        title: string;
        description: string | null;
        chartType: GQSupplierChartType;
      }
    | {
        __typename: 'SupplierEngagementFunnelChartConfig';
        id: string;
        orgId: string | null;
        title: string;
        description: string | null;
        chartType: GQSupplierChartType;
        funnels: Array<{
          __typename?: 'EngagementFunnel';
          id: string;
          name: string;
          displayOnSupplierDetailsPage: boolean | null;
          steps: Array<{
            __typename?: 'EngagementFunnelStep';
            id: string;
            stepName: string;
            stepType: GQEngagementFunnelStepType;
            sbtStage: GQCompanySbtCommitmentStage | null;
            engagementTaskConfigId: string | null;
            supplierTableColumnValues: Array<string> | null;
            supplierTableColumn: {
              __typename?: 'SupplierTableColumn';
              id: string;
              field: string;
            } | null;
          }>;
        }>;
      }
    | {
        __typename: 'SupplierMetricsChartConfig';
        id: string;
        orgId: string | null;
        title: string;
        description: string | null;
        chartType: GQSupplierChartType;
        metricsTypes: Array<GQSupplyChainMetricType>;
      }
    | {
        __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
        colorScale: Array<string>;
        id: string;
        orgId: string | null;
        title: string;
        description: string | null;
        chartType: GQSupplierChartType;
      }
    | {
        __typename: 'SupplierStandardChartConfig';
        colorScale: Array<string>;
        xField: string;
        aggregateType: GQSupplierChartAggregateType;
        id: string;
        orgId: string | null;
        title: string;
        description: string | null;
        chartType: GQSupplierChartType;
      }
    | {
        __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
        colorScale: Array<string>;
        isCumulative: boolean | null;
        id: string;
        orgId: string | null;
        title: string;
        description: string | null;
        chartType: GQSupplierChartType;
        tasksStatusChangeConfig: Array<{
          __typename?: 'SurveyDefinitionAndStatus';
          status: string;
          engagementTaskConfigId: string;
        }> | null;
      }
  >;
  chartOrder: Array<{
    __typename?: 'SupplierViewChartOrder';
    chartId: string;
    visible: boolean;
  }> | null;
  filters: {
    __typename?: 'FilterExpressionGroupWithNewFilters';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitiveWithNewFilters';
      field: string;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  } | null;
};

export type GQEmissionsYearForSuppliersTableFragment = {
  __typename?: 'EmissionsYear';
  id: string;
  reportingYear: number;
  expenseCategory: string | null;
  units: GQCompanyEmissionsUnits;
  scope1: number | null;
  scope2Market: number | null;
  scope2Location: number | null;
  scope3: number | null;
  scope301: number | null;
  scope302: number | null;
  scope303: number | null;
  scope304: number | null;
  scope305: number | null;
  scope306: number | null;
  scope307: number | null;
  scope308: number | null;
  scope309: number | null;
  scope310: number | null;
  scope311: number | null;
  scope312: number | null;
  scope313: number | null;
  scope314: number | null;
  scope315: number | null;
  scope316: number | null;
  scope317: number | null;
};

export type GQDisclosureForSuppliersTableFragment = {
  __typename?: 'Disclosure';
  id: string;
  publicDisclosure: {
    __typename?: 'PublicDisclosure';
    publishingYear: number;
    reportType: GQExternalReportType;
    publicUrl: string;
    cdpVendorData: {
      __typename?: 'CdpVendorData';
      id: string;
      reportingYear: number;
    } | null;
  } | null;
  privateDisclosure: {
    __typename?: 'PrivateDisclosure';
    privateDisclosureType: GQPrivateDisclosureType;
    createdAt: Date;
    orgName: string;
  } | null;
  targets: Array<{
    __typename?: 'DisclosureTargetDetails';
    id: string;
    name: string;
    description: string;
    baseYear: YearMonth;
    intensityType: GQDisclosureTargetIntensityType;
    unit: string | null;
    filters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    };
    emissionsTarget: {
      __typename?: 'SimpleTimeseries';
      id: string | null;
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
  }> | null;
  climateCommitments: Array<
    | {
        __typename?: 'CarbonNeutralCommitment';
        targetYear: number | null;
        id: string;
        kind: GQCompanyClimateCommitmentKind;
        description: string | null;
        externalUrl: string | null;
        commitmentMadeDate: Date | null;
        commitmentPeriodStart: Date | null;
        commitmentPeriodEnd: Date | null;
      }
    | {
        __typename?: 'CleanEnergyCommitment';
        targetYear: number | null;
        targetPercentageCleanEnergy: number | null;
        id: string;
        kind: GQCompanyClimateCommitmentKind;
        description: string | null;
        externalUrl: string | null;
        commitmentMadeDate: Date | null;
        commitmentPeriodStart: Date | null;
        commitmentPeriodEnd: Date | null;
      }
    | {
        __typename?: 'NetZeroCommitment';
        targetYear: number | null;
        id: string;
        kind: GQCompanyClimateCommitmentKind;
        description: string | null;
        externalUrl: string | null;
        commitmentMadeDate: Date | null;
        commitmentPeriodStart: Date | null;
        commitmentPeriodEnd: Date | null;
      }
    | {
        __typename?: 'ScienceBasedTargetCommitment';
        submittedToSBTi: boolean;
        id: string;
        kind: GQCompanyClimateCommitmentKind;
        description: string | null;
        externalUrl: string | null;
        commitmentMadeDate: Date | null;
        commitmentPeriodStart: Date | null;
        commitmentPeriodEnd: Date | null;
        commitment: {
          __typename?: 'SBTCommitment';
          id: string;
          stage: GQSbtCommitmentStage | null;
          targetClassification: GQSbtTargetClassification | null;
          nearTermTargetYear: number | null;
          longTermTargetYear: number | null;
          baselineYear: number | null;
          netZeroCommitted: boolean | null;
          commitmentType: GQSbtCommitmentType | null;
          commitmentDeadline: Date | null;
          netZeroTargetYear: number | null;
        };
      }
  > | null;
  initiatives: Array<{
    __typename?: 'DisclosureInitiative';
    id: string;
    name: string | null;
    initiativeType: GQDisclosureInitiativeType;
    description: string | null;
    startYearMonth: YearMonth | null;
    endYearMonth: YearMonth | null;
    intensityType: GQDisclosureTargetIntensityType | null;
    emissionsTimeseries: {
      __typename?: 'SimpleTimeseries';
      id: string | null;
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    } | null;
    filters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    } | null;
  }> | null;
  historicalEmissionsYears: Array<{
    __typename?: 'EmissionsYear';
    id: string;
    reportingYear: number;
    expenseCategory: string | null;
    units: GQCompanyEmissionsUnits;
    scope1: number | null;
    scope2Market: number | null;
    scope2Location: number | null;
    scope3: number | null;
    scope301: number | null;
    scope302: number | null;
    scope303: number | null;
    scope304: number | null;
    scope305: number | null;
    scope306: number | null;
    scope307: number | null;
    scope308: number | null;
    scope309: number | null;
    scope310: number | null;
    scope311: number | null;
    scope312: number | null;
    scope313: number | null;
    scope314: number | null;
    scope315: number | null;
    scope316: number | null;
    scope317: number | null;
  }> | null;
};

export type GQSuppliersDataFieldsFragment = {
  __typename?: 'SuppliersData';
  id: string;
  data: any;
};

export type GQEngagementCohortFieldsFragment = {
  __typename?: 'EngagementCohort';
  id: string;
  name: string;
};

export type GQGetSuppliersPageDataV2QueryVariables = Exact<{
  version: InputMaybe<Scalars['String']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  includedGhgCategories: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  viewFilters: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
  tableFilters: InputMaybe<Array<GQBiQueryFilterInput> | GQBiQueryFilterInput>;
  enableExperimentalCache: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQGetSuppliersPageDataV2Query = {
  __typename?: 'Query';
  suppliersV2: { __typename?: 'SuppliersData'; id: string; data: any };
};

export type GQGetEngagementCohortsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetEngagementCohortsQuery = {
  __typename?: 'Query';
  engagementCohorts: Array<{
    __typename?: 'EngagementCohort';
    id: string;
    name: string;
  }>;
};

export type GQUpdateSupplierCustomDataMutationVariables = Exact<{
  input: GQUpdateSupplierCustomDataInput;
}>;

export type GQUpdateSupplierCustomDataMutation = {
  __typename?: 'Mutation';
  updateSupplierCustomData: {
    __typename?: 'UpdateSupplierCustomDataPayload';
    supplierId: string;
    supplierCustomData: {
      __typename?: 'SupplierCustomData';
      id: string;
      priority: GQSupplierPriority | null;
      notes: string | null;
      customData: any | null;
    };
  } | null;
};

export type GQUpdateSupplierColumnVisibilityBatchMutationVariables = Exact<{
  input: GQUpdateSupplierColumnVisibilityBatchInput;
}>;

export type GQUpdateSupplierColumnVisibilityBatchMutation = {
  __typename?: 'Mutation';
  updateSupplierColumnVisibilityBatch: {
    __typename?: 'UpdateSupplierColumnVisibilityBatchPayload';
    view: {
      __typename?: 'SupplierView';
      id: string;
      name: string;
      isDefault: boolean;
      reductionPlan: {
        __typename?: 'Plan';
        id: string;
        name: string;
        state: GQPlanState;
        createdAt: Date;
        isHidden: boolean;
        relevantFootprintFields: Array<string>;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          footprintSnapshotId: string;
          overrideFootprintKind: string | null;
          referencePeriodInterval: YMInterval;
        };
      } | null;
      table: {
        __typename?: 'SupplierTable';
        columns: Array<{
          __typename?: 'SupplierTableColumn';
          id: string;
          field: string;
          headerName: string | null;
          description: string | null;
          visible: boolean | null;
          columnFormat: GQSupplierTableColumnFormat | null;
          selectOptions: Array<string> | null;
          footprintTagName: string | null;
          csatTagName: string | null;
          isSupplierScore: boolean;
          isSupplierScoreCriteria: boolean;
          questionKey: string | null;
        }>;
      };
      columnOrder: Array<{
        __typename?: 'SupplierViewColumnOrder';
        columnId: string;
        visible: boolean;
        width: number;
      }> | null;
      charts: Array<
        | {
            __typename: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{
              __typename?: 'EngagementFunnel';
              id: string;
              name: string;
              displayOnSupplierDetailsPage: boolean | null;
              steps: Array<{
                __typename?: 'EngagementFunnelStep';
                id: string;
                stepName: string;
                stepType: GQEngagementFunnelStepType;
                sbtStage: GQCompanySbtCommitmentStage | null;
                engagementTaskConfigId: string | null;
                supplierTableColumnValues: Array<string> | null;
                supplierTableColumn: {
                  __typename?: 'SupplierTableColumn';
                  id: string;
                  field: string;
                } | null;
              }>;
            }>;
          }
        | {
            __typename: 'SupplierMetricsChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            metricsTypes: Array<GQSupplyChainMetricType>;
          }
        | {
            __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
      chartOrder: Array<{
        __typename?: 'SupplierViewChartOrder';
        chartId: string;
        visible: boolean;
      }> | null;
      filters: {
        __typename?: 'FilterExpressionGroupWithNewFilters';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitiveWithNewFilters';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    };
  } | null;
};

export type GQUpdateSupplierColumnWidthMutationVariables = Exact<{
  input: GQUpdateSupplierColumnWidthInput;
}>;

export type GQUpdateSupplierColumnWidthMutation = {
  __typename?: 'Mutation';
  updateSupplierColumnWidth: {
    __typename?: 'UpdateSupplierColumnWidthPayload';
    view: {
      __typename?: 'SupplierView';
      id: string;
      name: string;
      isDefault: boolean;
      reductionPlan: {
        __typename?: 'Plan';
        id: string;
        name: string;
        state: GQPlanState;
        createdAt: Date;
        isHidden: boolean;
        relevantFootprintFields: Array<string>;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          footprintSnapshotId: string;
          overrideFootprintKind: string | null;
          referencePeriodInterval: YMInterval;
        };
      } | null;
      table: {
        __typename?: 'SupplierTable';
        columns: Array<{
          __typename?: 'SupplierTableColumn';
          id: string;
          field: string;
          headerName: string | null;
          description: string | null;
          visible: boolean | null;
          columnFormat: GQSupplierTableColumnFormat | null;
          selectOptions: Array<string> | null;
          footprintTagName: string | null;
          csatTagName: string | null;
          isSupplierScore: boolean;
          isSupplierScoreCriteria: boolean;
          questionKey: string | null;
        }>;
      };
      columnOrder: Array<{
        __typename?: 'SupplierViewColumnOrder';
        columnId: string;
        visible: boolean;
        width: number;
      }> | null;
      charts: Array<
        | {
            __typename: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{
              __typename?: 'EngagementFunnel';
              id: string;
              name: string;
              displayOnSupplierDetailsPage: boolean | null;
              steps: Array<{
                __typename?: 'EngagementFunnelStep';
                id: string;
                stepName: string;
                stepType: GQEngagementFunnelStepType;
                sbtStage: GQCompanySbtCommitmentStage | null;
                engagementTaskConfigId: string | null;
                supplierTableColumnValues: Array<string> | null;
                supplierTableColumn: {
                  __typename?: 'SupplierTableColumn';
                  id: string;
                  field: string;
                } | null;
              }>;
            }>;
          }
        | {
            __typename: 'SupplierMetricsChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            metricsTypes: Array<GQSupplyChainMetricType>;
          }
        | {
            __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
      chartOrder: Array<{
        __typename?: 'SupplierViewChartOrder';
        chartId: string;
        visible: boolean;
      }> | null;
      filters: {
        __typename?: 'FilterExpressionGroupWithNewFilters';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitiveWithNewFilters';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    };
  } | null;
};

export type GQUpdateSupplierColumnOrderMutationVariables = Exact<{
  input: GQUpdateSupplierColumnOrderInput;
}>;

export type GQUpdateSupplierColumnOrderMutation = {
  __typename?: 'Mutation';
  updateSupplierColumnOrder: {
    __typename?: 'UpdateSupplierColumnOrderPayload';
    view: {
      __typename?: 'SupplierView';
      id: string;
      name: string;
      isDefault: boolean;
      reductionPlan: {
        __typename?: 'Plan';
        id: string;
        name: string;
        state: GQPlanState;
        createdAt: Date;
        isHidden: boolean;
        relevantFootprintFields: Array<string>;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          footprintSnapshotId: string;
          overrideFootprintKind: string | null;
          referencePeriodInterval: YMInterval;
        };
      } | null;
      table: {
        __typename?: 'SupplierTable';
        columns: Array<{
          __typename?: 'SupplierTableColumn';
          id: string;
          field: string;
          headerName: string | null;
          description: string | null;
          visible: boolean | null;
          columnFormat: GQSupplierTableColumnFormat | null;
          selectOptions: Array<string> | null;
          footprintTagName: string | null;
          csatTagName: string | null;
          isSupplierScore: boolean;
          isSupplierScoreCriteria: boolean;
          questionKey: string | null;
        }>;
      };
      columnOrder: Array<{
        __typename?: 'SupplierViewColumnOrder';
        columnId: string;
        visible: boolean;
        width: number;
      }> | null;
      charts: Array<
        | {
            __typename: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{
              __typename?: 'EngagementFunnel';
              id: string;
              name: string;
              displayOnSupplierDetailsPage: boolean | null;
              steps: Array<{
                __typename?: 'EngagementFunnelStep';
                id: string;
                stepName: string;
                stepType: GQEngagementFunnelStepType;
                sbtStage: GQCompanySbtCommitmentStage | null;
                engagementTaskConfigId: string | null;
                supplierTableColumnValues: Array<string> | null;
                supplierTableColumn: {
                  __typename?: 'SupplierTableColumn';
                  id: string;
                  field: string;
                } | null;
              }>;
            }>;
          }
        | {
            __typename: 'SupplierMetricsChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            metricsTypes: Array<GQSupplyChainMetricType>;
          }
        | {
            __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
      chartOrder: Array<{
        __typename?: 'SupplierViewChartOrder';
        chartId: string;
        visible: boolean;
      }> | null;
      filters: {
        __typename?: 'FilterExpressionGroupWithNewFilters';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitiveWithNewFilters';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    };
  } | null;
};

export type GQCreateSupplierTableColumnMutationVariables = Exact<{
  input: GQCreateSupplierTableColumnInput;
}>;

export type GQCreateSupplierTableColumnMutation = {
  __typename?: 'Mutation';
  createSupplierTableColumn: {
    __typename?: 'CreateSupplierTableColumnPayload';
    views: Array<{
      __typename?: 'SupplierView';
      id: string;
      name: string;
      isDefault: boolean;
      reductionPlan: {
        __typename?: 'Plan';
        id: string;
        name: string;
        state: GQPlanState;
        createdAt: Date;
        isHidden: boolean;
        relevantFootprintFields: Array<string>;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          footprintSnapshotId: string;
          overrideFootprintKind: string | null;
          referencePeriodInterval: YMInterval;
        };
      } | null;
      table: {
        __typename?: 'SupplierTable';
        columns: Array<{
          __typename?: 'SupplierTableColumn';
          id: string;
          field: string;
          headerName: string | null;
          description: string | null;
          visible: boolean | null;
          columnFormat: GQSupplierTableColumnFormat | null;
          selectOptions: Array<string> | null;
          footprintTagName: string | null;
          csatTagName: string | null;
          isSupplierScore: boolean;
          isSupplierScoreCriteria: boolean;
          questionKey: string | null;
        }>;
      };
      columnOrder: Array<{
        __typename?: 'SupplierViewColumnOrder';
        columnId: string;
        visible: boolean;
        width: number;
      }> | null;
      charts: Array<
        | {
            __typename: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{
              __typename?: 'EngagementFunnel';
              id: string;
              name: string;
              displayOnSupplierDetailsPage: boolean | null;
              steps: Array<{
                __typename?: 'EngagementFunnelStep';
                id: string;
                stepName: string;
                stepType: GQEngagementFunnelStepType;
                sbtStage: GQCompanySbtCommitmentStage | null;
                engagementTaskConfigId: string | null;
                supplierTableColumnValues: Array<string> | null;
                supplierTableColumn: {
                  __typename?: 'SupplierTableColumn';
                  id: string;
                  field: string;
                } | null;
              }>;
            }>;
          }
        | {
            __typename: 'SupplierMetricsChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            metricsTypes: Array<GQSupplyChainMetricType>;
          }
        | {
            __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
      chartOrder: Array<{
        __typename?: 'SupplierViewChartOrder';
        chartId: string;
        visible: boolean;
      }> | null;
      filters: {
        __typename?: 'FilterExpressionGroupWithNewFilters';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitiveWithNewFilters';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }>;
  } | null;
};

export type GQUpdateSupplierTableColumnMutationVariables = Exact<{
  input: GQUpdateSupplierTableColumnInput;
}>;

export type GQUpdateSupplierTableColumnMutation = {
  __typename?: 'Mutation';
  updateSupplierTableColumn: {
    __typename?: 'UpdateSupplierTableColumnPayload';
    views: Array<{
      __typename?: 'SupplierView';
      id: string;
      name: string;
      isDefault: boolean;
      reductionPlan: {
        __typename?: 'Plan';
        id: string;
        name: string;
        state: GQPlanState;
        createdAt: Date;
        isHidden: boolean;
        relevantFootprintFields: Array<string>;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          footprintSnapshotId: string;
          overrideFootprintKind: string | null;
          referencePeriodInterval: YMInterval;
        };
      } | null;
      table: {
        __typename?: 'SupplierTable';
        columns: Array<{
          __typename?: 'SupplierTableColumn';
          id: string;
          field: string;
          headerName: string | null;
          description: string | null;
          visible: boolean | null;
          columnFormat: GQSupplierTableColumnFormat | null;
          selectOptions: Array<string> | null;
          footprintTagName: string | null;
          csatTagName: string | null;
          isSupplierScore: boolean;
          isSupplierScoreCriteria: boolean;
          questionKey: string | null;
        }>;
      };
      columnOrder: Array<{
        __typename?: 'SupplierViewColumnOrder';
        columnId: string;
        visible: boolean;
        width: number;
      }> | null;
      charts: Array<
        | {
            __typename: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{
              __typename?: 'EngagementFunnel';
              id: string;
              name: string;
              displayOnSupplierDetailsPage: boolean | null;
              steps: Array<{
                __typename?: 'EngagementFunnelStep';
                id: string;
                stepName: string;
                stepType: GQEngagementFunnelStepType;
                sbtStage: GQCompanySbtCommitmentStage | null;
                engagementTaskConfigId: string | null;
                supplierTableColumnValues: Array<string> | null;
                supplierTableColumn: {
                  __typename?: 'SupplierTableColumn';
                  id: string;
                  field: string;
                } | null;
              }>;
            }>;
          }
        | {
            __typename: 'SupplierMetricsChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            metricsTypes: Array<GQSupplyChainMetricType>;
          }
        | {
            __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
      chartOrder: Array<{
        __typename?: 'SupplierViewChartOrder';
        chartId: string;
        visible: boolean;
      }> | null;
      filters: {
        __typename?: 'FilterExpressionGroupWithNewFilters';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitiveWithNewFilters';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }>;
  } | null;
};

export type GQDeleteSupplierTableColumnMutationVariables = Exact<{
  input: GQDeleteSupplierTableColumnInput;
}>;

export type GQDeleteSupplierTableColumnMutation = {
  __typename?: 'Mutation';
  deleteSupplierTableColumn: {
    __typename?: 'DeleteSupplierTableColumnPayload';
    views: Array<{
      __typename?: 'SupplierView';
      id: string;
      name: string;
      isDefault: boolean;
      reductionPlan: {
        __typename?: 'Plan';
        id: string;
        name: string;
        state: GQPlanState;
        createdAt: Date;
        isHidden: boolean;
        relevantFootprintFields: Array<string>;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          footprintSnapshotId: string;
          overrideFootprintKind: string | null;
          referencePeriodInterval: YMInterval;
        };
      } | null;
      table: {
        __typename?: 'SupplierTable';
        columns: Array<{
          __typename?: 'SupplierTableColumn';
          id: string;
          field: string;
          headerName: string | null;
          description: string | null;
          visible: boolean | null;
          columnFormat: GQSupplierTableColumnFormat | null;
          selectOptions: Array<string> | null;
          footprintTagName: string | null;
          csatTagName: string | null;
          isSupplierScore: boolean;
          isSupplierScoreCriteria: boolean;
          questionKey: string | null;
        }>;
      };
      columnOrder: Array<{
        __typename?: 'SupplierViewColumnOrder';
        columnId: string;
        visible: boolean;
        width: number;
      }> | null;
      charts: Array<
        | {
            __typename: 'SupplierEmissionsForecastChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierEngagementFunnelChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            funnels: Array<{
              __typename?: 'EngagementFunnel';
              id: string;
              name: string;
              displayOnSupplierDetailsPage: boolean | null;
              steps: Array<{
                __typename?: 'EngagementFunnelStep';
                id: string;
                stepName: string;
                stepType: GQEngagementFunnelStepType;
                sbtStage: GQCompanySbtCommitmentStage | null;
                engagementTaskConfigId: string | null;
                supplierTableColumnValues: Array<string> | null;
                supplierTableColumn: {
                  __typename?: 'SupplierTableColumn';
                  id: string;
                  field: string;
                } | null;
              }>;
            }>;
          }
        | {
            __typename: 'SupplierMetricsChartConfig';
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            metricsTypes: Array<GQSupplyChainMetricType>;
          }
        | {
            __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
            colorScale: Array<string>;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierStandardChartConfig';
            colorScale: Array<string>;
            xField: string;
            aggregateType: GQSupplierChartAggregateType;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
          }
        | {
            __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
            colorScale: Array<string>;
            isCumulative: boolean | null;
            id: string;
            orgId: string | null;
            title: string;
            description: string | null;
            chartType: GQSupplierChartType;
            tasksStatusChangeConfig: Array<{
              __typename?: 'SurveyDefinitionAndStatus';
              status: string;
              engagementTaskConfigId: string;
            }> | null;
          }
      >;
      chartOrder: Array<{
        __typename?: 'SupplierViewChartOrder';
        chartId: string;
        visible: boolean;
      }> | null;
      filters: {
        __typename?: 'FilterExpressionGroupWithNewFilters';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitiveWithNewFilters';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }>;
  } | null;
};

export type GQSuppliersSettingsFieldsFragment = {
  __typename?: 'SuppliersSettings';
  id: string;
  includedGhgCategories: Array<string>;
  viewLatestSupplierMapping: boolean;
};

export type GQCompanyPortalSettingsFieldsFragment = {
  __typename?: 'CompanyPortalSettings';
  id: string | null;
  portalCallout: string | null;
  sidebarContent: string | null;
  portalTitle: string;
  toggleHeaderImage: boolean | null;
};

export type GQUpdateSuppliersSettingsMutationVariables = Exact<{
  suppliersSettingsInput: GQUpdateSuppliersSettingsInput;
  portalSettingsInput: GQUpsertCompanyPortalSettingsInput;
}>;

export type GQUpdateSuppliersSettingsMutation = {
  __typename?: 'Mutation';
  updateSuppliersSettings: {
    __typename?: 'UpdateSuppliersSettingsPayload';
    suppliersSettings: {
      __typename?: 'SuppliersSettings';
      id: string;
      includedGhgCategories: Array<string>;
      viewLatestSupplierMapping: boolean;
    };
  } | null;
  upsertCompanyPortalSettings: {
    __typename?: 'UpsertCompanyPortalSettingsPayload';
    companyPortalSettings: {
      __typename?: 'CompanyPortalSettings';
      id: string | null;
      portalCallout: string | null;
      sidebarContent: string | null;
      portalTitle: string;
      toggleHeaderImage: boolean | null;
    };
  } | null;
};

export type GQGetSuppliersSettingsQueryVariables = Exact<{
  rootCompanyId: Scalars['ID']['input'];
}>;

export type GQGetSuppliersSettingsQuery = {
  __typename?: 'Query';
  suppliersSettings: {
    __typename?: 'SuppliersSettings';
    id: string;
    includedGhgCategories: Array<string>;
    viewLatestSupplierMapping: boolean;
  };
  companyPortalSettings: {
    __typename?: 'CompanyPortalSettings';
    id: string | null;
    portalCallout: string | null;
    sidebarContent: string | null;
    portalTitle: string;
    toggleHeaderImage: boolean | null;
  };
};

export type GQGetVariousSuppliersQueryVariables = Exact<{
  footprintVersion: InputMaybe<Scalars['String']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  includedGhgCategories: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type GQGetVariousSuppliersQuery = {
  __typename?: 'Query';
  variousSuppliers: {
    __typename?: 'VariousSuppliersPayload';
    variousSuppliers: Array<{
      __typename?: 'VariousSupplierInfo';
      vendor: string;
      companyId: string | null;
      totalKgco2e: number;
      totalSpendUsd: number;
    }>;
  };
};

export type GQGetBulkDeployStatusQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetBulkDeployStatusQuery = {
  __typename?: 'Query';
  engagementTaskBatchDeployStatus: {
    __typename?: 'EngagementTaskBatchDeployStatus';
    id: string;
    successfulDeployCompanies: Array<{
      __typename?: 'Company';
      id: string;
      name: string;
    }>;
    failedDeployCompanies: Array<{
      __typename?: 'Company';
      id: string;
      name: string;
    }>;
  };
};

export type GQBulkDeployStatusCompanyFieldsFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
};

export type GQGetExpenseCategoriesForSuppliersQueryVariables = Exact<{
  version: InputMaybe<Scalars['String']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  companyIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  useDuckdbPostgresConnection: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQGetExpenseCategoriesForSuppliersQuery = {
  __typename?: 'Query';
  expenseCategoriesForSuppliers: Array<{
    __typename?: 'SupplierExpenseCategory';
    companyId: string;
    expenseCategories: Array<{
      __typename?: 'ExpenseCategoryData';
      beaCode: string;
      inputUnit: string;
      inputQuantity: number;
      kgco2e: number;
    }> | null;
  }>;
};

export type GQSupplierContactFieldsFragment = {
  __typename?: 'SupplierContact';
  id: string;
  name: string;
  email: string;
  role: string | null;
  userId: string | null;
  companyId: string;
};

export type GQGetContactsForCompanyQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type GQGetContactsForCompanyQuery = {
  __typename?: 'Query';
  contactsForCompany: Array<{
    __typename?: 'SupplierContact';
    id: string;
    name: string;
    email: string;
    role: string | null;
    userId: string | null;
    companyId: string;
  }>;
};

export type GQGetContactsForOrgQueryVariables = Exact<{ [key: string]: never }>;

export type GQGetContactsForOrgQuery = {
  __typename?: 'Query';
  contacts: Array<{
    __typename?: 'SupplierContact';
    id: string;
    name: string;
    email: string;
    role: string | null;
    userId: string | null;
    companyId: string;
  }>;
};

export type GQSupplierScoreCriteriaFieldsFragment = {
  __typename?: 'SupplierScoreCriteria';
  id: string;
  label: string;
  supplierScoreId: string;
  field: string;
  weight: number;
  filters: Array<{
    __typename?: 'BiQueryFilter';
    dimension: string;
    operator: GQBiFilterOperator;
    value: Array<FilterValue | null>;
  }>;
};

export type GQSupplierScorecardFieldsFragment = {
  __typename?: 'SupplierScorecard';
  id: string;
  name: string;
  field: string;
  criteria: Array<{
    __typename?: 'SupplierScoreCriteria';
    id: string;
    label: string;
    supplierScoreId: string;
    field: string;
    weight: number;
    filters: Array<{
      __typename?: 'BiQueryFilter';
      dimension: string;
      operator: GQBiFilterOperator;
      value: Array<FilterValue | null>;
    }>;
  }>;
};

export type GQSupplierScorecardsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQSupplierScorecardsQuery = {
  __typename?: 'Query';
  supplierScorecards: Array<{
    __typename?: 'SupplierScorecard';
    id: string;
    name: string;
    field: string;
    criteria: Array<{
      __typename?: 'SupplierScoreCriteria';
      id: string;
      label: string;
      supplierScoreId: string;
      field: string;
      weight: number;
      filters: Array<{
        __typename?: 'BiQueryFilter';
        dimension: string;
        operator: GQBiFilterOperator;
        value: Array<FilterValue | null>;
      }>;
    }>;
  }>;
};

export type GQGetSupplierViewsQueryVariables = Exact<{ [key: string]: never }>;

export type GQGetSupplierViewsQuery = {
  __typename?: 'Query';
  supplierViews: Array<{
    __typename?: 'SupplierView';
    id: string;
    name: string;
    isDefault: boolean;
    reductionPlan: {
      __typename?: 'Plan';
      id: string;
      name: string;
      state: GQPlanState;
      createdAt: Date;
      isHidden: boolean;
      relevantFootprintFields: Array<string>;
      forecast: {
        __typename?: 'Forecast';
        id: string;
        footprintSnapshotId: string;
        overrideFootprintKind: string | null;
        referencePeriodInterval: YMInterval;
      };
    } | null;
    table: {
      __typename?: 'SupplierTable';
      columns: Array<{
        __typename?: 'SupplierTableColumn';
        id: string;
        field: string;
        headerName: string | null;
        description: string | null;
        visible: boolean | null;
        columnFormat: GQSupplierTableColumnFormat | null;
        selectOptions: Array<string> | null;
        footprintTagName: string | null;
        csatTagName: string | null;
        isSupplierScore: boolean;
        isSupplierScoreCriteria: boolean;
        questionKey: string | null;
      }>;
    };
    columnOrder: Array<{
      __typename?: 'SupplierViewColumnOrder';
      columnId: string;
      visible: boolean;
      width: number;
    }> | null;
    charts: Array<
      | {
          __typename: 'SupplierEmissionsForecastChartConfig';
          id: string;
          orgId: string | null;
          title: string;
          description: string | null;
          chartType: GQSupplierChartType;
        }
      | {
          __typename: 'SupplierEngagementFunnelChartConfig';
          id: string;
          orgId: string | null;
          title: string;
          description: string | null;
          chartType: GQSupplierChartType;
          funnels: Array<{
            __typename?: 'EngagementFunnel';
            id: string;
            name: string;
            displayOnSupplierDetailsPage: boolean | null;
            steps: Array<{
              __typename?: 'EngagementFunnelStep';
              id: string;
              stepName: string;
              stepType: GQEngagementFunnelStepType;
              sbtStage: GQCompanySbtCommitmentStage | null;
              engagementTaskConfigId: string | null;
              supplierTableColumnValues: Array<string> | null;
              supplierTableColumn: {
                __typename?: 'SupplierTableColumn';
                id: string;
                field: string;
              } | null;
            }>;
          }>;
        }
      | {
          __typename: 'SupplierMetricsChartConfig';
          id: string;
          orgId: string | null;
          title: string;
          description: string | null;
          chartType: GQSupplierChartType;
          metricsTypes: Array<GQSupplyChainMetricType>;
        }
      | {
          __typename: 'SupplierSbtCommitmentOverTimeChartConfig';
          colorScale: Array<string>;
          id: string;
          orgId: string | null;
          title: string;
          description: string | null;
          chartType: GQSupplierChartType;
        }
      | {
          __typename: 'SupplierStandardChartConfig';
          colorScale: Array<string>;
          xField: string;
          aggregateType: GQSupplierChartAggregateType;
          id: string;
          orgId: string | null;
          title: string;
          description: string | null;
          chartType: GQSupplierChartType;
        }
      | {
          __typename: 'SupplierTaskStatusChangeOverTimeChartConfig';
          colorScale: Array<string>;
          isCumulative: boolean | null;
          id: string;
          orgId: string | null;
          title: string;
          description: string | null;
          chartType: GQSupplierChartType;
          tasksStatusChangeConfig: Array<{
            __typename?: 'SurveyDefinitionAndStatus';
            status: string;
            engagementTaskConfigId: string;
          }> | null;
        }
    >;
    chartOrder: Array<{
      __typename?: 'SupplierViewChartOrder';
      chartId: string;
      visible: boolean;
    }> | null;
    filters: {
      __typename?: 'FilterExpressionGroupWithNewFilters';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitiveWithNewFilters';
        field: string;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    } | null;
  }>;
};

export type GQSupplierForReductionsForecastFragment = {
  __typename?: 'Supplier';
  id: string;
  name: string;
  footprintVendorNames: Array<string>;
  cleanedFootprintVendorNames: Array<string>;
  totalSpending: number | null;
  company: { __typename?: 'Company'; id: string } | null;
  disclosures: Array<{
    __typename?: 'Disclosure';
    id: string;
    company: { __typename?: 'Company'; id: string } | null;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      publishingYear: number;
      reportType: GQExternalReportType;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      companyId: string | null;
      orgId: string;
      orgName: string;
      surveyId: string | null;
      privateDisclosureType: GQPrivateDisclosureType;
      createdAt: Date;
      disclosureQualityScore: GQDisclosureQualityScore | null;
      disclosureQualityExplanation: string | null;
      thirdPartyVerified: boolean;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      publicDisclosureId: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      revenueUsd: number | null;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      units: GQCompanyEmissionsUnits;
      expenseCategory: string | null;
      scope1: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
    }> | null;
    targets: Array<{
      __typename?: 'DisclosureTargetDetails';
      id: string;
      name: string;
      description: string;
      baseYear: YearMonth;
      emissionsType: GQDisclosureTargetEmissionsType;
      reductionRate: GQDisclosureTargetReductionRate;
      intensityType: GQDisclosureTargetIntensityType;
      unit: string | null;
      unitDescription: string | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
      emissionsTarget: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    }> | null;
    initiatives: Array<{
      __typename?: 'DisclosureInitiative';
      id: string;
      name: string | null;
      initiativeType: GQDisclosureInitiativeType;
      description: string | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      intensityType: GQDisclosureTargetIntensityType | null;
      emissionsTimeseries: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      } | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'NetZeroCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            netZeroCommitted: boolean | null;
            commitmentType: GQSbtCommitmentType | null;
            commitmentDeadline: Date | null;
            netZeroTargetYear: number | null;
          };
        }
    > | null;
  }>;
  industryAverageAllocatedEmissions: {
    __typename?: 'SupplierHistoricalEmissions';
    scope1: number | null;
    scope2: number | null;
    scope3: number | null;
    scope301: number | null;
    scope302: number | null;
    scope303: number | null;
    scope304: number | null;
    scope305: number | null;
    scope306: number | null;
    scope307: number | null;
    scope308: number | null;
    scope309: number | null;
    scope310: number | null;
    scope311: number | null;
    scope312: number | null;
    scope313: number | null;
    scope314: number | null;
    scope315: number | null;
    scope316: number | null;
    scope317: number | null;
    scope1Ratio: number | null;
    scope2Ratio: number | null;
    scope3Ratio: number | null;
    publishingYear: number | null;
    reportingYear: number;
    surveyId: string | null;
    source: GQEmissionsSource;
    revenueUsd: number | null;
    revenue: number | null;
    revenueCurrency: string | null;
    publicUrl: string | null;
    units: GQCompanyEmissionsUnits;
    expenseCategory: string | null;
  } | null;
};

export type GQSuppliersReductionsForecastQueryVariables = Exact<{
  footprintVersion: InputMaybe<Scalars['String']['input']>;
  suppliersQueryInterval: Scalars['YMInterval']['input'];
  orgId: Scalars['ID']['input'];
  viewFilters: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
}>;

export type GQSuppliersReductionsForecastQuery = {
  __typename?: 'Query';
  suppliersWithTargetDisclosures: {
    __typename?: 'SuppliersWithInterval';
    suppliers: Array<{
      __typename?: 'Supplier';
      id: string;
      name: string;
      footprintVendorNames: Array<string>;
      cleanedFootprintVendorNames: Array<string>;
      totalSpending: number | null;
      company: { __typename?: 'Company'; id: string } | null;
      disclosures: Array<{
        __typename?: 'Disclosure';
        id: string;
        company: { __typename?: 'Company'; id: string } | null;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          publishingYear: number;
          reportType: GQExternalReportType;
          publicUrl: string;
          qualityScore: GQDisclosureQualityScore | null;
          cdpVendorData: {
            __typename?: 'CdpVendorData';
            id: string;
            publishingYear: number;
            reportingYear: number;
            scope1Nonzero: boolean;
            scope2Nonzero: boolean;
            scope3Nonzero: boolean;
            totalEmissionsNonzero: boolean;
            scope301Or302Nonzero: boolean;
            scope1Verified: boolean;
            scope2Verified: boolean;
            scope3Verified: boolean;
            pctEvaluationStatusesMatchResponse: number;
            disclosureQualityScore: GQDisclosureQualityScore;
            scope301EvaluationStatus: GQScope3EvaluationStatus;
            scope302EvaluationStatus: GQScope3EvaluationStatus;
            scope303EvaluationStatus: GQScope3EvaluationStatus;
            scope304EvaluationStatus: GQScope3EvaluationStatus;
            scope305EvaluationStatus: GQScope3EvaluationStatus;
            scope306EvaluationStatus: GQScope3EvaluationStatus;
            scope307EvaluationStatus: GQScope3EvaluationStatus;
            scope308EvaluationStatus: GQScope3EvaluationStatus;
            scope309EvaluationStatus: GQScope3EvaluationStatus;
            scope310EvaluationStatus: GQScope3EvaluationStatus;
            scope311EvaluationStatus: GQScope3EvaluationStatus;
            scope312EvaluationStatus: GQScope3EvaluationStatus;
            scope313EvaluationStatus: GQScope3EvaluationStatus;
            scope314EvaluationStatus: GQScope3EvaluationStatus;
            scope315EvaluationStatus: GQScope3EvaluationStatus;
            scope316EvaluationStatus: GQScope3EvaluationStatus;
            scope317EvaluationStatus: GQScope3EvaluationStatus;
          } | null;
        } | null;
        privateDisclosure: {
          __typename?: 'PrivateDisclosure';
          id: string;
          companyId: string | null;
          orgId: string;
          orgName: string;
          surveyId: string | null;
          privateDisclosureType: GQPrivateDisclosureType;
          createdAt: Date;
          disclosureQualityScore: GQDisclosureQualityScore | null;
          disclosureQualityExplanation: string | null;
          thirdPartyVerified: boolean;
          cdpVendorData: {
            __typename?: 'CdpVendorData';
            id: string;
            publishingYear: number;
            reportingYear: number;
            scope1Nonzero: boolean;
            scope2Nonzero: boolean;
            scope3Nonzero: boolean;
            totalEmissionsNonzero: boolean;
            scope301Or302Nonzero: boolean;
            scope1Verified: boolean;
            scope2Verified: boolean;
            scope3Verified: boolean;
            pctEvaluationStatusesMatchResponse: number;
            disclosureQualityScore: GQDisclosureQualityScore;
            scope301EvaluationStatus: GQScope3EvaluationStatus;
            scope302EvaluationStatus: GQScope3EvaluationStatus;
            scope303EvaluationStatus: GQScope3EvaluationStatus;
            scope304EvaluationStatus: GQScope3EvaluationStatus;
            scope305EvaluationStatus: GQScope3EvaluationStatus;
            scope306EvaluationStatus: GQScope3EvaluationStatus;
            scope307EvaluationStatus: GQScope3EvaluationStatus;
            scope308EvaluationStatus: GQScope3EvaluationStatus;
            scope309EvaluationStatus: GQScope3EvaluationStatus;
            scope310EvaluationStatus: GQScope3EvaluationStatus;
            scope311EvaluationStatus: GQScope3EvaluationStatus;
            scope312EvaluationStatus: GQScope3EvaluationStatus;
            scope313EvaluationStatus: GQScope3EvaluationStatus;
            scope314EvaluationStatus: GQScope3EvaluationStatus;
            scope315EvaluationStatus: GQScope3EvaluationStatus;
            scope316EvaluationStatus: GQScope3EvaluationStatus;
            scope317EvaluationStatus: GQScope3EvaluationStatus;
          } | null;
        } | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          publicDisclosureId: string | null;
          reportingYear: number;
          numEmployees: number | null;
          revenue: number | null;
          revenueCurrency: string | null;
          revenueUsd: number | null;
          percentageCleanEnergy: number | null;
          totalMwh: number | null;
          units: GQCompanyEmissionsUnits;
          expenseCategory: string | null;
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
        }> | null;
        targets: Array<{
          __typename?: 'DisclosureTargetDetails';
          id: string;
          name: string;
          description: string;
          baseYear: YearMonth;
          emissionsType: GQDisclosureTargetEmissionsType;
          reductionRate: GQDisclosureTargetReductionRate;
          intensityType: GQDisclosureTargetIntensityType;
          unit: string | null;
          unitDescription: string | null;
          filters: {
            __typename?: 'FilterExpressionGroup';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitive';
              field: GQFilterFieldLegacy;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          };
          emissionsTarget: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
        }> | null;
        initiatives: Array<{
          __typename?: 'DisclosureInitiative';
          id: string;
          name: string | null;
          initiativeType: GQDisclosureInitiativeType;
          description: string | null;
          startYearMonth: YearMonth | null;
          endYearMonth: YearMonth | null;
          intensityType: GQDisclosureTargetIntensityType | null;
          emissionsTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          } | null;
          filters: {
            __typename?: 'FilterExpressionGroup';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitive';
              field: GQFilterFieldLegacy;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          } | null;
        }> | null;
        climateCommitments: Array<
          | {
              __typename?: 'CarbonNeutralCommitment';
              targetYear: number | null;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename?: 'CleanEnergyCommitment';
              targetYear: number | null;
              targetPercentageCleanEnergy: number | null;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename?: 'NetZeroCommitment';
              targetYear: number | null;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename?: 'ScienceBasedTargetCommitment';
              submittedToSBTi: boolean;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
              commitment: {
                __typename?: 'SBTCommitment';
                id: string;
                stage: GQSbtCommitmentStage | null;
                targetClassification: GQSbtTargetClassification | null;
                nearTermTargetYear: number | null;
                longTermTargetYear: number | null;
                baselineYear: number | null;
                netZeroCommitted: boolean | null;
                commitmentType: GQSbtCommitmentType | null;
                commitmentDeadline: Date | null;
                netZeroTargetYear: number | null;
              };
            }
        > | null;
      }>;
      industryAverageAllocatedEmissions: {
        __typename?: 'SupplierHistoricalEmissions';
        scope1: number | null;
        scope2: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
        scope1Ratio: number | null;
        scope2Ratio: number | null;
        scope3Ratio: number | null;
        publishingYear: number | null;
        reportingYear: number;
        surveyId: string | null;
        source: GQEmissionsSource;
        revenueUsd: number | null;
        revenue: number | null;
        revenueCurrency: string | null;
        publicUrl: string | null;
        units: GQCompanyEmissionsUnits;
        expenseCategory: string | null;
      } | null;
    }>;
  };
};

export type GQBiSavedViewForLandingPageFragment = {
  __typename?: 'BiSavedView';
  id: string;
  stableId: string;
  name: string;
  updatedAt: Date;
  createdAt: Date;
  owner: { __typename?: 'User'; id: string; name: string; displayName: string };
};

export type GQGetBiSavedViewsForLandingPageQueryVariables = Exact<{
  input: InputMaybe<GQBiSavedViewsInput>;
}>;

export type GQGetBiSavedViewsForLandingPageQuery = {
  __typename?: 'Query';
  biSavedViews: Array<{
    __typename?: 'BiSavedView';
    id: string;
    stableId: string;
    name: string;
    updatedAt: Date;
    createdAt: Date;
    owner: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    };
  }> | null;
};

export type GQBenchmarkFieldsForExplorerFragment = {
  __typename?: 'Benchmark';
  id: string;
  reportId: string | null;
  name: string;
  reportingYear: number;
  companyId: string;
  kgco2eByScope: Array<{
    __typename?: 'BenchmarkScopeBreakdownDataPoint';
    scope: GQGhgScope;
    kgco2e: number | null;
  }>;
};

export type GQSelectAssetBenchmarksMutationVariables = Exact<{
  input: GQSetAssetBenchmarksInput;
}>;

export type GQSelectAssetBenchmarksMutation = {
  __typename?: 'Mutation';
  setAssetBenchmarks: {
    __typename?: 'SetAssetBenchmarksPayload';
    status: string | null;
  };
};

export type GQContactFieldsForEmailFragment = {
  __typename?: 'SupplierContact';
  id: string;
  email: string;
  name: string;
  role: string | null;
  companyId: string;
};

export type GQFinanceWorksheetColumnAssetGridFragment = {
  __typename?: 'FinanceWorksheetColumn';
  id: string;
  financeWorksheetId: string | null;
  field: string;
  columnFormat: GQGridColumnFormat;
  headerName: string;
  description: string;
  width: number | null;
  columnConfiguration:
    | {
        __typename: 'GridColumnConfigurationMultiSelect';
        columnFormat: GQGridColumnFormat;
        options: Array<string>;
      }
    | {
        __typename: 'GridColumnConfigurationSingleSelect';
        columnFormat: GQGridColumnFormat;
        options: Array<string>;
      }
    | null;
};

export type GQFinanceColumnAssetGridFragment = {
  __typename?: 'FinanceColumn';
  id: string;
  field: string;
  columnFormat: GQGridColumnFormat;
  headerName: string;
  description: string | null;
  width: number | null;
  columnConfiguration:
    | { __typename?: 'GridColumnConfigurationMultiSelect' }
    | {
        __typename: 'GridColumnConfigurationSingleSelect';
        columnFormat: GQGridColumnFormat;
        options: Array<string>;
      }
    | null;
};

export type GQFinanceWorksheetAssetGridFragment = {
  __typename?: 'FinanceWorksheet';
  id: string;
  hiddenColumns: Array<string> | null;
  standardView: GQFinanceStandardView | null;
  columns: Array<{
    __typename?: 'FinanceWorksheetColumn';
    id: string;
    financeWorksheetId: string | null;
    field: string;
    columnFormat: GQGridColumnFormat;
    headerName: string;
    description: string;
    width: number | null;
    columnConfiguration:
      | {
          __typename: 'GridColumnConfigurationMultiSelect';
          columnFormat: GQGridColumnFormat;
          options: Array<string>;
        }
      | {
          __typename: 'GridColumnConfigurationSingleSelect';
          columnFormat: GQGridColumnFormat;
          options: Array<string>;
        }
      | null;
  }>;
  orgColumns: Array<{
    __typename?: 'FinanceColumn';
    id: string;
    field: string;
    columnFormat: GQGridColumnFormat;
    headerName: string;
    description: string | null;
    width: number | null;
    columnConfiguration:
      | { __typename?: 'GridColumnConfigurationMultiSelect' }
      | {
          __typename: 'GridColumnConfigurationSingleSelect';
          columnFormat: GQGridColumnFormat;
          options: Array<string>;
        }
      | null;
  }>;
  sortModel: Array<{
    __typename?: 'DataGridSortItem';
    field: string;
    sort: GQDataGridSortDirection;
  }>;
};

export type GQUpdateFinanceWorksheetMutationVariables = Exact<{
  input: GQUpdateFinanceWorksheetInput;
}>;

export type GQUpdateFinanceWorksheetMutation = {
  __typename?: 'Mutation';
  updateFinanceWorksheet: {
    __typename?: 'UpdateFinanceWorksheetPayload';
    worksheet: {
      __typename?: 'FinanceWorksheet';
      id: string;
      hiddenColumns: Array<string> | null;
      standardView: GQFinanceStandardView | null;
      columns: Array<{
        __typename?: 'FinanceWorksheetColumn';
        id: string;
        financeWorksheetId: string | null;
        field: string;
        columnFormat: GQGridColumnFormat;
        headerName: string;
        description: string;
        width: number | null;
        columnConfiguration:
          | {
              __typename: 'GridColumnConfigurationMultiSelect';
              columnFormat: GQGridColumnFormat;
              options: Array<string>;
            }
          | {
              __typename: 'GridColumnConfigurationSingleSelect';
              columnFormat: GQGridColumnFormat;
              options: Array<string>;
            }
          | null;
      }>;
      orgColumns: Array<{
        __typename?: 'FinanceColumn';
        id: string;
        field: string;
        columnFormat: GQGridColumnFormat;
        headerName: string;
        description: string | null;
        width: number | null;
        columnConfiguration:
          | { __typename?: 'GridColumnConfigurationMultiSelect' }
          | {
              __typename: 'GridColumnConfigurationSingleSelect';
              columnFormat: GQGridColumnFormat;
              options: Array<string>;
            }
          | null;
      }>;
      sortModel: Array<{
        __typename?: 'DataGridSortItem';
        field: string;
        sort: GQDataGridSortDirection;
      }>;
    };
  };
};

export type GQDeleteAssetsMutationVariables = Exact<{
  input: GQDeleteAssetsInput;
}>;

export type GQDeleteAssetsMutation = {
  __typename?: 'Mutation';
  deleteAssets: {
    __typename?: 'DeleteAssetsPayload';
    assetYearIds: Array<string>;
    assetHoldingIds: Array<string>;
    privateDisclosureIds: Array<string>;
    emissionsYearIds: Array<string>;
    fundHoldingBenchmarkIds: Array<string>;
    footprintEstimateOutputIds: Array<string>;
    assetCorporateIds: Array<string>;
    assetRealEstateIds: Array<string>;
    assetGroupIds: Array<string>;
    assetSovereignBondIds: Array<string>;
    assetPersonalMotorVehicleInsuranceIds: Array<string>;
    invalidatedSnapshots: Array<{
      __typename?: 'FinanceSnapshot';
      id: string;
      isStale: boolean | null;
    }>;
  };
};

export type GQFinanceSnapshotHoldingsQueryVariables = Exact<{
  financeSnapshotId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  search: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQFinanceSnapshotHoldingsQuery = {
  __typename?: 'Query';
  financeSnapshotHoldings: {
    __typename?: 'FinanceSnapshotHoldings';
    id: string;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
    edges: Array<{
      __typename?: 'FinanceSnapshotHoldingEdge';
      node: any | null;
    } | null>;
  };
};

export type GQFinanceSnapshotAssetsQueryVariables = Exact<{
  financeSnapshotId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  search: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQFinanceSnapshotAssetsQuery = {
  __typename?: 'Query';
  financeSnapshotAssets: {
    __typename?: 'FinanceSnapshotAssets';
    id: string;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
    edges: Array<{
      __typename?: 'FinanceSnapshotAssetEdge';
      node: any | null;
    } | null>;
  };
};

export type GQFinanceSnapshotAssetsGroupedByCompanyQueryVariables = Exact<{
  financeSnapshotId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  search: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQFinanceSnapshotAssetsGroupedByCompanyQuery = {
  __typename?: 'Query';
  financeSnapshotAssetsGroupedByCompany: {
    __typename?: 'FinanceSnapshotAssetsGroupedByCompany';
    id: string;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
    edges: Array<{
      __typename?: 'FinanceSnapshotAssetGroupedByCompanyEdge';
      node: any | null;
    } | null>;
  };
};

export type GQFinanceSnapshotFundsQueryVariables = Exact<{
  financeSnapshotId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  search: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQFinanceSnapshotFundsQuery = {
  __typename?: 'Query';
  financeSnapshotFunds: {
    __typename?: 'FinanceSnapshotFunds';
    id: string;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
    edges: Array<{
      __typename?: 'FinanceSnapshotFundEdge';
      node: any | null;
    } | null>;
  };
};

export type GQFinanceTagForGridFragment = {
  __typename?: 'FinanceTag';
  id: string;
  name: string;
  description: string | null;
  columnFormat: GQGridColumnFormat;
  columnConfiguration: any | null;
  tagType: GQFinanceTagType;
};

export type GQFinanceSnapshotGridTagsQueryVariables = Exact<{
  financeSnapshotId: Scalars['ID']['input'];
}>;

export type GQFinanceSnapshotGridTagsQuery = {
  __typename?: 'Query';
  financeSnapshotTags: Array<{
    __typename?: 'FinanceSnapshotTag';
    id: string;
    financeTag: {
      __typename?: 'FinanceTag';
      id: string;
      name: string;
      description: string | null;
      columnFormat: GQGridColumnFormat;
      columnConfiguration: any | null;
      tagType: GQFinanceTagType;
    };
  }>;
};

export type GQFinanceBenchmarkOptionsQueryVariables = Exact<{
  search: InputMaybe<Scalars['String']['input']>;
}>;

export type GQFinanceBenchmarkOptionsQuery = {
  __typename?: 'Query';
  publicDisclosureSearch: {
    __typename?: 'PublicDisclosureConnection';
    edges: Array<{
      __typename?: 'PublicDisclosureEdge';
      node: {
        __typename?: 'PublicDisclosure';
        id: string;
        reportingYears: Array<number>;
        company: { __typename?: 'Company'; id: string; name: string } | null;
      } | null;
    }>;
  };
};

export type GQFinanceAggregateEmptyMetricFragment = {
  __typename: 'FinanceAggregateMetric';
  metric: GQFinanceMetric;
  name: string;
  description: string | null;
};

export type GQFinanceStringEmptyMetricFragment = {
  __typename: 'FinanceStringMetric';
  metric: GQFinanceMetric;
  name: string;
  description: string | null;
};

export type GQCreateAssetCorporateMutationVariables = Exact<{
  input: GQCreateAssetCorporateInput;
}>;

export type GQCreateAssetCorporateMutation = {
  __typename?: 'Mutation';
  createAssetCorporate: {
    __typename?: 'CreateAssetCorporatePayload';
    asset: {
      __typename: 'AssetCorporate';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      name: string;
      naicsCode: string | null;
      companyIsin: string | null;
      companyLei: string | null;
      companyUrl: string | null;
      companyTicker: string | null;
      currencyCode: GQCurrencyCode;
      countryAlpha2: string | null;
      buildingSizeUnit: GQBuildingSizeUnit | null;
      companyId: string | null;
      otherData: any | null;
      tagData: any | null;
      externalId: string | null;
      assetGroupId: string | null;
      anonymousCompanyUuid: string | null;
    };
  } | null;
};

export type GQCreateAssetGroupMutationVariables = Exact<{
  input: GQCreateAssetGroupInput;
}>;

export type GQCreateAssetGroupMutation = {
  __typename?: 'Mutation';
  createAssetGroup: {
    __typename?: 'CreateAssetGroupPayload';
    asset: {
      __typename: 'AssetGroup';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      name: string;
      currencyCode: GQCurrencyCode;
      buildingSizeUnit: GQBuildingSizeUnit | null;
      otherData: any | null;
      tagData: any | null;
      externalId: string | null;
      companyId: string | null;
      assetGroupId: string | null;
    };
  } | null;
};

export type GQAssetPersonalMotorVehicleInsuranceAllFieldsFragment = {
  __typename: 'AssetPersonalMotorVehicleInsurance';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  currencyCode: GQCurrencyCode;
  tagData: any | null;
};

export type GQCreateAssetPersonalMotorVehicleInsuranceMutationVariables =
  Exact<{
    input: GQCreateAssetPersonalMotorVehicleInsuranceInput;
  }>;

export type GQCreateAssetPersonalMotorVehicleInsuranceMutation = {
  __typename?: 'Mutation';
  createAssetPersonalMotorVehicleInsurance: {
    __typename?: 'CreateAssetPersonalMotorVehicleInsurancePayload';
    asset: {
      __typename: 'AssetPersonalMotorVehicleInsurance';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      name: string;
      currencyCode: GQCurrencyCode;
      tagData: any | null;
    };
  } | null;
};

export type GQCreateAssetRealEstateMutationVariables = Exact<{
  input: GQCreateAssetRealEstateInput;
}>;

export type GQCreateAssetRealEstateMutation = {
  __typename?: 'Mutation';
  createAssetRealEstate: {
    __typename?: 'CreateAssetRealEstatePayload';
    asset: {
      __typename: 'AssetRealEstate';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      buildingKind: string | null;
      name: string;
      streetAddress: string | null;
      streetAddress2: string | null;
      city: string | null;
      state: string | null;
      countryAlpha2: string | null;
      postalCode: string | null;
      currencyCode: GQCurrencyCode;
      buildingSizeUnit: GQBuildingSizeUnit | null;
      otherData: any | null;
      tagData: any | null;
      externalId: string | null;
      assetGroupId: string | null;
      sizeUnit: GQBuildingSizeUnit | null;
      country: string | null;
      buildingName: string;
    };
  } | null;
};

export type GQCreateAssetSovereignBondMutationVariables = Exact<{
  input: GQCreateAssetSovereignBondInput;
}>;

export type GQCreateAssetSovereignBondMutation = {
  __typename?: 'Mutation';
  createAssetSovereignBond: {
    __typename?: 'CreateAssetSovereignBondPayload';
    asset: {
      __typename: 'AssetSovereignBond';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      name: string;
      sovereignEntity: string;
      currencyCode: GQCurrencyCode;
      tagData: any | null;
    };
  } | null;
};

export type GQCreateFinanceSavedViewMutationVariables = Exact<{
  input: GQCreateFinanceSavedViewInput;
}>;

export type GQCreateFinanceSavedViewMutation = {
  __typename?: 'Mutation';
  createFinanceSavedView: {
    __typename?: 'CreateFinanceSavedViewPayload';
    financeSavedView: {
      __typename?: 'FinanceSavedView';
      id: string;
      name: string;
      standardView: GQFinanceStandardView | null;
      fundId: string | null;
      layout: string | null;
      metrics: Array<string> | null;
      charts: Array<string> | null;
      filters: any | null;
    };
  } | null;
};

export type GQCreateFinanceTagMutationVariables = Exact<{
  input: GQCreateFinanceTagInput;
}>;

export type GQCreateFinanceTagMutation = {
  __typename?: 'Mutation';
  createFinanceTag: {
    __typename?: 'CreateFinanceTagPayload';
    financeTag: {
      __typename: 'FinanceTag';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      createdBy: string | null;
      updatedBy: string | null;
      name: string;
      description: string | null;
      columnFormat: GQGridColumnFormat;
      tagType: GQFinanceTagType;
      columnConfiguration: any | null;
      tagCategory: string | null;
    };
  };
};

export type GQCreateFundMutationVariables = Exact<{
  input: GQCreateFundInput;
}>;

export type GQCreateFundMutation = {
  __typename?: 'Mutation';
  createFund: {
    __typename?: 'CreateFundPayload';
    fund: {
      __typename: 'Fund';
      id: string;
      name: string;
      nameSortable: string | null;
      fundGroup: string | null;
      fundCategory: string | null;
      excludeAsSandbox: boolean | null;
      anonymousFundUuid: string | null;
      createdAt: Date;
      orgId: string;
      updatedAt: Date;
      tagData: any | null;
      externalId: string | null;
    };
  } | null;
};

export type GQCreateFinanceReportingTagsMutationVariables = Exact<{
  input: GQCreateFinanceReportingTagsInput;
}>;

export type GQCreateFinanceReportingTagsMutation = {
  __typename?: 'Mutation';
  createFinanceReportingTags: {
    __typename?: 'CreateFinanceReportingTagsPayload';
    financeTags: Array<{
      __typename: 'FinanceTag';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      createdBy: string | null;
      updatedBy: string | null;
      name: string;
      description: string | null;
      columnFormat: GQGridColumnFormat;
      tagType: GQFinanceTagType;
      columnConfiguration: any | null;
      tagCategory: string | null;
    }>;
  };
};

export type GQDeleteFinanceTagMutationVariables = Exact<{
  input: GQDeleteFinanceTagInput;
}>;

export type GQDeleteFinanceTagMutation = {
  __typename?: 'Mutation';
  deleteFinanceTag: { __typename?: 'DeleteFinanceTagPayload'; id: string };
};

export type GQFinanceTagAllFieldsFragment = {
  __typename: 'FinanceTag';
  id: string;
  orgId: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string | null;
  updatedBy: string | null;
  name: string;
  description: string | null;
  columnFormat: GQGridColumnFormat;
  tagType: GQFinanceTagType;
  columnConfiguration: any | null;
  tagCategory: string | null;
};

export type GQFinanceTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GQFinanceTagsQuery = {
  __typename?: 'Query';
  financeTags: Array<{
    __typename: 'FinanceTag';
    id: string;
    orgId: string;
    createdAt: Date;
    updatedAt: Date;
    createdBy: string | null;
    updatedBy: string | null;
    name: string;
    description: string | null;
    columnFormat: GQGridColumnFormat;
    tagType: GQFinanceTagType;
    columnConfiguration: any | null;
    tagCategory: string | null;
  }>;
};

export type GQDeleteFinanceTagsMutationVariables = Exact<{
  input: GQDeleteFinanceTagsInput;
}>;

export type GQDeleteFinanceTagsMutation = {
  __typename?: 'Mutation';
  deleteFinanceTags: {
    __typename?: 'DeleteFinanceTagsPayload';
    ids: Array<string>;
  };
};

export type GQUpdateFinanceTagMutationVariables = Exact<{
  input: GQUpdateFinanceTagInput;
}>;

export type GQUpdateFinanceTagMutation = {
  __typename?: 'Mutation';
  updateFinanceTag: {
    __typename?: 'UpdateFinanceTagPayload';
    financeTag: {
      __typename: 'FinanceTag';
      id: string;
      orgId: string;
      createdAt: Date;
      updatedAt: Date;
      createdBy: string | null;
      updatedBy: string | null;
      name: string;
      description: string | null;
      columnFormat: GQGridColumnFormat;
      tagType: GQFinanceTagType;
      columnConfiguration: any | null;
      tagCategory: string | null;
    };
  };
};

export type GQCategorizedEmissionDataFieldsFragment = {
  __typename?: 'CategorizedEmissionData';
  businessCategory: string;
  businessSubcategory: string | null;
  proportion: number;
  amountKgco2e: number;
};

export type GQGhgCategorizedEmissionDataFieldsFragment = {
  __typename?: 'GhgCategorizedEmissionData';
  ghgCategoryId: string;
  businessSubcategory: string | null;
  proportion: number;
  amountKgco2e: number;
};

export type GQCreateAssetCommentMutationVariables = Exact<{
  input: GQCreateAssetCommentInput;
}>;

export type GQCreateAssetCommentMutation = {
  __typename?: 'Mutation';
  createAssetComment: {
    __typename?: 'CreateAssetCommentPayload';
    assetComment: {
      __typename?: 'AssetComment';
      orgId: string;
      id: string;
      createdAt: Date;
      updatedAt: Date;
      commentText: string;
      userId: string;
      lastEdited: Date | null;
      assetCorporateId: string | null;
      assetCommercialRealEstateId: string | null;
      assetGroupId: string | null;
      deletedAt: Date | null;
      commentHistory: any | null;
      asset:
        | {
            __typename?: 'AssetCorporate';
            id: string;
            assetComments: Array<{ __typename?: 'AssetComment'; id: string }>;
          }
        | {
            __typename?: 'AssetGroup';
            id: string;
            assetComments: Array<{ __typename?: 'AssetComment'; id: string }>;
          }
        | {
            __typename?: 'AssetPersonalMotorVehicleInsurance';
            id: string;
            assetComments: Array<{ __typename?: 'AssetComment'; id: string }>;
          }
        | {
            __typename?: 'AssetRealEstate';
            id: string;
            assetComments: Array<{ __typename?: 'AssetComment'; id: string }>;
          }
        | {
            __typename?: 'AssetSovereignBond';
            id: string;
            assetComments: Array<{ __typename?: 'AssetComment'; id: string }>;
          }
        | null;
      user: { __typename?: 'User'; id: string; name: string } | null;
      attachments: Array<{
        __typename?: 'AssetCommentAttachment';
        id: string;
        assetCommentId: string;
        fileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
        } | null;
      }> | null;
    };
    attachmentMetadata: Array<{
      __typename?: 'AttachmentMetadata';
      signedUrl: string;
      fileMetadataId: string;
    }>;
  };
};

export type GQAssetCommentAttachmentForSidebarFragment = {
  __typename?: 'AssetCommentAttachment';
  id: string;
  assetCommentId: string;
  fileMetadata: {
    __typename?: 'FileMetadata';
    id: string;
    name: string;
  } | null;
};

export type GQAssetCommentAllFieldsFragment = {
  __typename?: 'AssetComment';
  orgId: string;
  id: string;
  createdAt: Date;
  updatedAt: Date;
  commentText: string;
  userId: string;
  lastEdited: Date | null;
  assetCorporateId: string | null;
  assetCommercialRealEstateId: string | null;
  assetGroupId: string | null;
  deletedAt: Date | null;
  commentHistory: any | null;
  user: { __typename?: 'User'; id: string; name: string } | null;
  attachments: Array<{
    __typename?: 'AssetCommentAttachment';
    id: string;
    assetCommentId: string;
    fileMetadata: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
    } | null;
  }> | null;
};

export type GQAssetCommentsQueryVariables = Exact<{
  assetId: Scalars['ID']['input'];
}>;

export type GQAssetCommentsQuery = {
  __typename?: 'Query';
  asset:
    | {
        __typename?: 'AssetCorporate';
        id: string;
        assetComments: Array<{
          __typename?: 'AssetComment';
          orgId: string;
          id: string;
          createdAt: Date;
          updatedAt: Date;
          commentText: string;
          userId: string;
          lastEdited: Date | null;
          assetCorporateId: string | null;
          assetCommercialRealEstateId: string | null;
          assetGroupId: string | null;
          deletedAt: Date | null;
          commentHistory: any | null;
          user: { __typename?: 'User'; id: string; name: string } | null;
          attachments: Array<{
            __typename?: 'AssetCommentAttachment';
            id: string;
            assetCommentId: string;
            fileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            } | null;
          }> | null;
        }>;
      }
    | {
        __typename?: 'AssetGroup';
        id: string;
        assetComments: Array<{
          __typename?: 'AssetComment';
          orgId: string;
          id: string;
          createdAt: Date;
          updatedAt: Date;
          commentText: string;
          userId: string;
          lastEdited: Date | null;
          assetCorporateId: string | null;
          assetCommercialRealEstateId: string | null;
          assetGroupId: string | null;
          deletedAt: Date | null;
          commentHistory: any | null;
          user: { __typename?: 'User'; id: string; name: string } | null;
          attachments: Array<{
            __typename?: 'AssetCommentAttachment';
            id: string;
            assetCommentId: string;
            fileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            } | null;
          }> | null;
        }>;
      }
    | {
        __typename?: 'AssetPersonalMotorVehicleInsurance';
        id: string;
        assetComments: Array<{
          __typename?: 'AssetComment';
          orgId: string;
          id: string;
          createdAt: Date;
          updatedAt: Date;
          commentText: string;
          userId: string;
          lastEdited: Date | null;
          assetCorporateId: string | null;
          assetCommercialRealEstateId: string | null;
          assetGroupId: string | null;
          deletedAt: Date | null;
          commentHistory: any | null;
          user: { __typename?: 'User'; id: string; name: string } | null;
          attachments: Array<{
            __typename?: 'AssetCommentAttachment';
            id: string;
            assetCommentId: string;
            fileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            } | null;
          }> | null;
        }>;
      }
    | {
        __typename?: 'AssetRealEstate';
        id: string;
        assetComments: Array<{
          __typename?: 'AssetComment';
          orgId: string;
          id: string;
          createdAt: Date;
          updatedAt: Date;
          commentText: string;
          userId: string;
          lastEdited: Date | null;
          assetCorporateId: string | null;
          assetCommercialRealEstateId: string | null;
          assetGroupId: string | null;
          deletedAt: Date | null;
          commentHistory: any | null;
          user: { __typename?: 'User'; id: string; name: string } | null;
          attachments: Array<{
            __typename?: 'AssetCommentAttachment';
            id: string;
            assetCommentId: string;
            fileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            } | null;
          }> | null;
        }>;
      }
    | {
        __typename?: 'AssetSovereignBond';
        id: string;
        assetComments: Array<{
          __typename?: 'AssetComment';
          orgId: string;
          id: string;
          createdAt: Date;
          updatedAt: Date;
          commentText: string;
          userId: string;
          lastEdited: Date | null;
          assetCorporateId: string | null;
          assetCommercialRealEstateId: string | null;
          assetGroupId: string | null;
          deletedAt: Date | null;
          commentHistory: any | null;
          user: { __typename?: 'User'; id: string; name: string } | null;
          attachments: Array<{
            __typename?: 'AssetCommentAttachment';
            id: string;
            assetCommentId: string;
            fileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            } | null;
          }> | null;
        }>;
      };
};

export type GQUpdateAssetCommentMutationVariables = Exact<{
  input: GQUpdateAssetCommentInput;
}>;

export type GQUpdateAssetCommentMutation = {
  __typename?: 'Mutation';
  updateAssetComment: {
    __typename?: 'UpdateAssetCommentPayload';
    assetComment: {
      __typename?: 'AssetComment';
      orgId: string;
      id: string;
      createdAt: Date;
      updatedAt: Date;
      commentText: string;
      userId: string;
      lastEdited: Date | null;
      assetCorporateId: string | null;
      assetCommercialRealEstateId: string | null;
      assetGroupId: string | null;
      deletedAt: Date | null;
      commentHistory: any | null;
      user: { __typename?: 'User'; id: string; name: string } | null;
      attachments: Array<{
        __typename?: 'AssetCommentAttachment';
        id: string;
        assetCommentId: string;
        fileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
        } | null;
      }> | null;
    };
  };
};

export type GQDeleteAssetCommentMutationVariables = Exact<{
  input: GQDeleteAssetCommentInput;
}>;

export type GQDeleteAssetCommentMutation = {
  __typename?: 'Mutation';
  deleteAssetComment: {
    __typename?: 'DeleteAssetCommentPayload';
    id: string;
    asset:
      | {
          __typename?: 'AssetCorporate';
          id: string;
          assetComments: Array<{ __typename?: 'AssetComment'; id: string }>;
        }
      | {
          __typename?: 'AssetGroup';
          id: string;
          assetComments: Array<{ __typename?: 'AssetComment'; id: string }>;
        }
      | {
          __typename?: 'AssetPersonalMotorVehicleInsurance';
          id: string;
          assetComments: Array<{ __typename?: 'AssetComment'; id: string }>;
        }
      | {
          __typename?: 'AssetRealEstate';
          id: string;
          assetComments: Array<{ __typename?: 'AssetComment'; id: string }>;
        }
      | {
          __typename?: 'AssetSovereignBond';
          id: string;
          assetComments: Array<{ __typename?: 'AssetComment'; id: string }>;
        }
      | null;
  };
};

export type GQFinanceAggregateMetricMoreFragment = {
  __typename: 'FinanceAggregateMetric';
  metric: GQFinanceMetric;
  name: string;
  description: string | null;
  quantity: number;
  unit: string | null;
  additionalInfo: string | null;
  isMissingInputs: boolean;
};

export type GQFinanceStringMetricMoreFragment = {
  __typename: 'FinanceStringMetric';
  metric: GQFinanceMetric;
  name: string;
  description: string | null;
  value: string;
  unit: string | null;
  additionalInfo: string | null;
  isMissingInputs: boolean;
};

export type GQFinanceSnapshotFilterOptionsQueryVariables = Exact<{
  financeSnapshotId: Scalars['ID']['input'];
  filters: Scalars['UntypedData']['input'];
}>;

export type GQFinanceSnapshotFilterOptionsQuery = {
  __typename?: 'Query';
  financeSnapshotFilterOptions: any;
};

export type GQFinanceHeaderCurrentFragment = {
  __typename?: 'FinanceHeader';
  title: string;
  fundExcluded: boolean;
  isAssetGroupItem: boolean;
};

export type GQFinanceSavedViewAllFieldsFragment = {
  __typename?: 'FinanceSavedView';
  id: string;
  name: string;
  standardView: GQFinanceStandardView | null;
  fundId: string | null;
  layout: string | null;
  metrics: Array<string> | null;
  charts: Array<string> | null;
  filters: any | null;
};

export type GQOrgFundsV3PageQueryVariables = Exact<{
  financeSnapshotId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
  isTotalEmissions: InputMaybe<Scalars['Boolean']['input']>;
  filters: InputMaybe<Scalars['UntypedData']['input']>;
  standardView: InputMaybe<GQFinanceStandardView>;
  viewId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQOrgFundsV3PageQuery = {
  __typename?: 'Query';
  financeView: {
    __typename?: 'FinanceView';
    standardView: GQFinanceStandardView;
    savedView: {
      __typename?: 'FinanceSavedView';
      id: string;
      name: string;
      standardView: GQFinanceStandardView | null;
      fundId: string | null;
      layout: string | null;
      metrics: Array<string> | null;
      charts: Array<string> | null;
      filters: any | null;
    };
    header: {
      __typename?: 'FinanceHeader';
      title: string;
      fundExcluded: boolean;
      isAssetGroupItem: boolean;
    };
    gridWorksheet: {
      __typename?: 'FinanceWorksheet';
      id: string;
      hiddenColumns: Array<string> | null;
      standardView: GQFinanceStandardView | null;
      columns: Array<{
        __typename?: 'FinanceWorksheetColumn';
        id: string;
        financeWorksheetId: string | null;
        field: string;
        columnFormat: GQGridColumnFormat;
        headerName: string;
        description: string;
        width: number | null;
        columnConfiguration:
          | {
              __typename: 'GridColumnConfigurationMultiSelect';
              columnFormat: GQGridColumnFormat;
              options: Array<string>;
            }
          | {
              __typename: 'GridColumnConfigurationSingleSelect';
              columnFormat: GQGridColumnFormat;
              options: Array<string>;
            }
          | null;
      }>;
      orgColumns: Array<{
        __typename?: 'FinanceColumn';
        id: string;
        field: string;
        columnFormat: GQGridColumnFormat;
        headerName: string;
        description: string | null;
        width: number | null;
        columnConfiguration:
          | { __typename?: 'GridColumnConfigurationMultiSelect' }
          | {
              __typename: 'GridColumnConfigurationSingleSelect';
              columnFormat: GQGridColumnFormat;
              options: Array<string>;
            }
          | null;
      }>;
      sortModel: Array<{
        __typename?: 'DataGridSortItem';
        field: string;
        sort: GQDataGridSortDirection;
      }>;
    } | null;
  };
};

export type GQDeleteSnapshotMutationVariables = Exact<{
  input: GQDeleteSnapshotInput;
}>;

export type GQDeleteSnapshotMutation = {
  __typename?: 'Mutation';
  deleteSnapshot: { __typename?: 'DeleteSnapshotPayload'; id: string };
};

export type GQUpdateSnapshotNameMutationVariables = Exact<{
  input: GQUpdateSnapshotNameInput;
}>;

export type GQUpdateSnapshotNameMutation = {
  __typename?: 'Mutation';
  updateSnapshotName: {
    __typename?: 'UpdateSnapshotNamePayload';
    snapshot: {
      __typename?: 'FinanceSnapshot';
      id: string;
      snapshotName: string | null;
      note: string | null;
      config: any | null;
      createdAt: Date;
      executionStatus: GQFinanceSnapshotExecutionStatus;
      executionStatusMessage: string;
      completedAt: Date | null;
      isStale: boolean | null;
      flagsPopulated: boolean | null;
      financedEmissionsTonnesCurrentYear: number | null;
      footprintSnapshotId: string | null;
      createdBy: {
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      } | null;
    };
  };
};

export type GQUpdateFinanceSavedViewMutationVariables = Exact<{
  input: GQUpdateFinanceSavedViewInput;
}>;

export type GQUpdateFinanceSavedViewMutation = {
  __typename?: 'Mutation';
  updateFinanceSavedView: {
    __typename?: 'UpdateFinanceSavedViewPayload';
    financeSavedView: {
      __typename?: 'FinanceSavedView';
      id: string;
      name: string;
      standardView: GQFinanceStandardView | null;
      fundId: string | null;
      layout: string | null;
      metrics: Array<string> | null;
      charts: Array<string> | null;
      filters: any | null;
    };
  } | null;
};

export type GQNextMeasurementProjectDataFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  active: boolean;
  coverageStartDate: Date;
  footprintStatus: GQMeasurementProjectFootprintStatus;
  measurementTimeline: {
    __typename?: 'MeasurementTimeline';
    id: string;
    kickoff: Date;
    deadline: Date;
  } | null;
  datasetsWithStages: Array<{
    __typename?: 'DatasetWithStage';
    id: string;
    areAllTasksComplete: boolean;
    areUploadsComplete: boolean;
    isUsableInFootprint: boolean;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      materialCsrdStandards: Array<GQCsrdStandard>;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    } | null;
  }>;
  measurementTasks: Array<{
    __typename?: 'MeasurementParentTask';
    id: string;
    title: string;
    link: string | null;
    lockState: GQApprovalTargetLockState | null;
    isAssignedToWatershed: boolean;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
    assignees: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
      didLatestEmailBounce: boolean;
    }>;
    approvers: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    }>;
    object:
      | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
      | {
          __typename: 'DataIssue';
          id: string;
          linkedObject:
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItemQuestion';
                id: string;
                item: {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  } | null;
                };
              }
            | {
                __typename: 'UserUploadTask';
                id: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                };
              }
            | { __typename: 'ValueMappingTask' }
            | null;
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                createdAt: Date;
                isPublished: boolean;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        }
      | { __typename: 'FacilitiesSetup'; id: string }
      | {
          __typename: 'FinancialsReviewGroup';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            };
          }>;
        }
      | {
          __typename: 'FinancialsReviewItem';
          id: string;
          group: {
            __typename: 'FinancialsReviewGroup';
            id: string;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              };
            }>;
          };
        }
      | { __typename: 'FootprintQuestion'; id: string }
      | {
          __typename: 'MeasurementDataMappingTask';
          id: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          };
        }
      | { __typename: 'MeasurementProject'; id: string }
      | {
          __typename: 'MeasurementProjectDataset';
          modelId: string | null;
          isCompleted: boolean;
          id: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          };
        }
      | {
          __typename: 'MeasurementVerificationItem';
          id: string;
          mviStatus: GQMeasurementVerificationItemStatus;
          maybeDataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          } | null;
        }
      | {
          __typename: 'UserUploadTask';
          state: GQUserUploadTaskState;
          id: string;
          approvers: Array<{
            __typename?: 'UserWithApprovalStatus';
            approvalStatus: GQApprovalStatus | null;
            user: {
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            };
          }>;
          datasource: {
            __typename?: 'Datasource';
            hasIntegration: boolean;
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            };
          };
        }
      | { __typename: 'ValueMappingTask'; id: string }
      | { __typename: 'VendorMatchingTask'; id: string };
  }>;
};

export type GQActiveMeasurementProjectForOnboardingHomePageFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  footprintStatus: GQMeasurementProjectFootprintStatus;
  measurementTasks: Array<{
    __typename?: 'MeasurementParentTask';
    id: string;
    title: string;
    link: string | null;
    lockState: GQApprovalTargetLockState | null;
    isAssignedToWatershed: boolean;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
    assignees: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
      didLatestEmailBounce: boolean;
    }>;
    approvers: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    }>;
    object:
      | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
      | {
          __typename: 'DataIssue';
          id: string;
          linkedObject:
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItemQuestion';
                id: string;
                item: {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  } | null;
                };
              }
            | {
                __typename: 'UserUploadTask';
                id: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                };
              }
            | { __typename: 'ValueMappingTask' }
            | null;
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                createdAt: Date;
                isPublished: boolean;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        }
      | { __typename: 'FacilitiesSetup'; id: string }
      | {
          __typename: 'FinancialsReviewGroup';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            };
          }>;
        }
      | {
          __typename: 'FinancialsReviewItem';
          id: string;
          group: {
            __typename: 'FinancialsReviewGroup';
            id: string;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              };
            }>;
          };
        }
      | { __typename: 'FootprintQuestion'; id: string }
      | {
          __typename: 'MeasurementDataMappingTask';
          id: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          };
        }
      | { __typename: 'MeasurementProject'; id: string }
      | {
          __typename: 'MeasurementProjectDataset';
          modelId: string | null;
          isCompleted: boolean;
          id: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          };
        }
      | {
          __typename: 'MeasurementVerificationItem';
          id: string;
          mviStatus: GQMeasurementVerificationItemStatus;
          maybeDataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          } | null;
        }
      | {
          __typename: 'UserUploadTask';
          state: GQUserUploadTaskState;
          id: string;
          approvers: Array<{
            __typename?: 'UserWithApprovalStatus';
            approvalStatus: GQApprovalStatus | null;
            user: {
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            };
          }>;
          datasource: {
            __typename?: 'Datasource';
            hasIntegration: boolean;
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            };
          };
        }
      | { __typename: 'ValueMappingTask'; id: string }
      | { __typename: 'VendorMatchingTask'; id: string };
  }>;
};

export type GQOrganizationForOnboardingHomePageFragment = {
  __typename?: 'Organization';
  id: string;
  hasActiveWorkosConnection: boolean;
  users: {
    __typename?: 'UserConnection';
    edges: Array<{
      __typename?: 'UserEdge';
      node: { __typename?: 'User'; id: string } | null;
    } | null>;
  } | null;
};

export type GQOnboardingHomePageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQOnboardingHomePageQuery = {
  __typename?: 'Query';
  climateProgramTimelineProjects: Array<{
    __typename: 'ClimateProgramTimelineProject';
    id: string;
    name: string;
    description: string;
    kind: GQClimateProgramProjectKind;
    startDate: Date | null;
    endDate: Date | null;
    plannedYear: number;
    completedAt: Date | null;
    iconType: GQClimateProgramProjectIconType;
    objectType: GQClimateProgramTimelineProjectObjectType;
    object:
      | { __typename: 'ClimateProgramProject'; id: string }
      | {
          __typename: 'MeasurementProject';
          id: string;
          name: string;
          setupAt: Date | null;
          completedAt: Date | null;
          measurementTimeline: {
            __typename?: 'MeasurementTimeline';
            id: string;
            kickoff: Date;
            deadline: Date;
          } | null;
        }
      | {
          __typename: 'Report';
          id: string;
          name: string;
          createdAt: Date;
          reportKind: string;
          config: {
            __typename?: 'ReportConfig';
            id: string;
            reportType: string;
          };
        };
    canonicalClimateProgramProject: {
      __typename?: 'CanonicalClimateProgramProject';
      id: string;
      name: string;
      routeType: string | null;
    } | null;
  }>;
  activeMeasurementProjects: Array<{
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    footprintStatus: GQMeasurementProjectFootprintStatus;
    measurementTasks: Array<{
      __typename?: 'MeasurementParentTask';
      id: string;
      title: string;
      link: string | null;
      lockState: GQApprovalTargetLockState | null;
      isAssignedToWatershed: boolean;
      statusSimplified: GQTaskWatershedProcessStateSimplified;
      assignees: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
        didLatestEmailBounce: boolean;
      }>;
      approvers: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      }>;
      object:
        | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
        | {
            __typename: 'DataIssue';
            id: string;
            linkedObject:
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItemQuestion';
                  id: string;
                  item: {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    } | null;
                  };
                }
              | {
                  __typename: 'UserUploadTask';
                  id: string;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask' }
              | null;
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  createdAt: Date;
                  isPublished: boolean;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          }
        | { __typename: 'FacilitiesSetup'; id: string }
        | {
            __typename: 'FinancialsReviewGroup';
            id: string;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              };
            }>;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            group: {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            };
          }
        | { __typename: 'FootprintQuestion'; id: string }
        | {
            __typename: 'MeasurementDataMappingTask';
            id: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            };
          }
        | { __typename: 'MeasurementProject'; id: string }
        | {
            __typename: 'MeasurementProjectDataset';
            modelId: string | null;
            isCompleted: boolean;
            id: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            };
          }
        | {
            __typename: 'MeasurementVerificationItem';
            id: string;
            mviStatus: GQMeasurementVerificationItemStatus;
            maybeDataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            } | null;
          }
        | {
            __typename: 'UserUploadTask';
            state: GQUserUploadTaskState;
            id: string;
            approvers: Array<{
              __typename?: 'UserWithApprovalStatus';
              approvalStatus: GQApprovalStatus | null;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              };
            }>;
            datasource: {
              __typename?: 'Datasource';
              hasIntegration: boolean;
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
          }
        | { __typename: 'ValueMappingTask'; id: string }
        | { __typename: 'VendorMatchingTask'; id: string };
    }>;
  }>;
};

export type GQOnboardingNavCardQueryVariables = Exact<{ [key: string]: never }>;

export type GQOnboardingNavCardQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    hasActiveWorkosConnection: boolean;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: { __typename?: 'User'; id: string } | null;
      } | null>;
    } | null;
  };
};

export type GQGetValueMappingOutputOptionsQueryVariables = Exact<{
  columnName: Scalars['String']['input'];
  searchTerm: InputMaybe<Scalars['String']['input']>;
  selectedValue: InputMaybe<Scalars['String']['input']>;
  suggestedValues: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  configId: Scalars['String']['input'];
}>;

export type GQGetValueMappingOutputOptionsQuery = {
  __typename?: 'Query';
  getValueMappingOutputOptions: Array<{
    __typename?: 'ValueMappingOutputOption';
    id: string;
    title: string;
    subtitle: string | null;
    descriptionLong: string | null;
    isSuggested: boolean;
    category: string | null;
    extraData: any | null;
  }>;
};

export type GQValueMappingPageValueMappingFragment = {
  __typename?: 'ValueMapping';
  id: string;
  revisionState: GQReferenceDataRevisionState | null;
  valueMappingRows: Array<{
    __typename?: 'ValueMappingRow';
    id: string;
    baseRowJson: string;
  }>;
};

export type GQValueMappingPageValueMappingRowFragment = {
  __typename?: 'ValueMappingRow';
  id: string;
  baseRowJson: string;
};

export type GQApproveAllValueMappingRecordsMutationVariables = Exact<{
  input: GQApproveAllValueMappingRecordsInput;
}>;

export type GQApproveAllValueMappingRecordsMutation = {
  __typename?: 'Mutation';
  approveAllValueMappingRecords: {
    __typename?: 'ApproveAllValueMappingRecordsPayload';
    success: boolean;
    workflowId: string;
  } | null;
};

export type GQValueMappingRowsQueryVariables = Exact<{
  valueMappingConfigId: Scalars['ID']['input'];
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
  filter: InputMaybe<GQValueMappingFilterType>;
  searchText: InputMaybe<Scalars['String']['input']>;
  sortModel: InputMaybe<
    Array<GQDataGridSortItemInput> | GQDataGridSortItemInput
  >;
}>;

export type GQValueMappingRowsQuery = {
  __typename?: 'Query';
  valueMappingRows: {
    __typename?: 'ValueMappingRowConnection';
    edges: Array<{
      __typename?: 'ValueMappingRowEdge';
      node: {
        __typename?: 'ValueMappingRow';
        id: string;
        baseRowJson: string;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
    metadata: {
      __typename?: 'ValueMappingRowsMetadata';
      id: string;
      allRowsAreFilled: boolean;
      totalRowCount: number;
      unmappedRowCount: number;
      flaggedRowCount: number;
      suggestionsRowCount: number;
    };
  } | null;
};

export type GQGetManageValueMappingPermissionQueryVariables = Exact<{
  valueMappingRuleId: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
}>;

export type GQGetManageValueMappingPermissionQuery = {
  __typename?: 'Query';
  userHasManageValueMappingPermission: boolean;
};

export type GQGetValueMappingCommentsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  valueMappingConfigId: Scalars['ID']['input'];
  subjectId: Scalars['ID']['input'];
}>;

export type GQGetValueMappingCommentsQuery = {
  __typename?: 'Query';
  valueMappingCommentsForSubject: Array<{
    __typename?: 'ValueMappingComment';
    id: string;
    message: string;
    createdAt: Date;
    lastEditedAt: Date | null;
    creator: {
      __typename?: 'User';
      id: string;
      isWatershedEmployee: boolean;
      isWatershedContractor: boolean;
      name: string;
    };
    lastEditor: {
      __typename?: 'User';
      id: string;
      isWatershedEmployee: boolean;
      isWatershedContractor: boolean;
      name: string;
    } | null;
  }>;
};

export type GQCreateValueMappingCommentMutationVariables = Exact<{
  input: GQCreateValueMappingCommentInput;
}>;

export type GQCreateValueMappingCommentMutation = {
  __typename?: 'Mutation';
  createValueMappingComment: {
    __typename?: 'ValueMappingComment';
    id: string;
    message: string;
  };
};

export type GQGetValueMappingHistoryForGroupQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  valueMappingConfigId: Scalars['ID']['input'];
  groupId: Scalars['ID']['input'];
}>;

export type GQGetValueMappingHistoryForGroupQuery = {
  __typename?: 'Query';
  valueMappingHistoryForGroup: {
    __typename?: 'ValueMappingHistoryForGroupPayload';
    id: string;
    entries: Array<{
      __typename?: 'ValueMappingHistoryEntry';
      id: string;
      rowId: string;
      columnName: string;
      operation: string;
      oldValue: any | null;
      newValue: any | null;
      changedAt: Date;
      revision: {
        __typename?: 'ReferenceDataRevision';
        id: string;
        isValueMappingLegacyMigration: boolean | null;
      };
      changedBy: {
        __typename?: 'User';
        id: string;
        name: string;
        isWatershedEmployee: boolean;
        isWatershedContractor: boolean;
      };
    }>;
  };
};

export type GQValueMappingFileRowDataQueryVariables = Exact<{
  userUploadIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  filter: Array<GQValueMappingFileDataFilter> | GQValueMappingFileDataFilter;
  sortColumn: Scalars['String']['input'];
}>;

export type GQValueMappingFileRowDataQuery = {
  __typename?: 'Query';
  valueMappingFileRowData: Array<{
    __typename?: 'ValueMappingFileData';
    id: string;
    matchedRows: JSONSchema7;
    fileData: {
      __typename?: 'UserUpload';
      id: string;
      userVisibleAttempt: {
        __typename?: 'UserUploadAttempt';
        id: string;
        createdAt: Date;
        name: string;
        uploader: { __typename?: 'User'; id: string; name: string } | null;
      } | null;
    };
  }>;
};

export type GQValueMappingOutputOptionFieldsFragment = {
  __typename?: 'ValueMappingOutputOption';
  id: string;
  title: string;
  subtitle: string | null;
  descriptionLong: string | null;
  isSuggested: boolean;
  category: string | null;
  extraData: any | null;
};

export type GQValueMappingTableSourcesQueryVariables = Exact<{
  valueMappingConfigId: Scalars['String']['input'];
}>;

export type GQValueMappingTableSourcesQuery = {
  __typename?: 'Query';
  valueMappingTableSourceData: {
    __typename?: 'ValueMappingTableSourceData';
    id: string;
    outputOptionPickers: Array<{
      __typename?: 'ValueMappingOutputPicker';
      id: string;
      columnName: string;
      placeholder: string | null;
      options: Array<{
        __typename?: 'ValueMappingOutputOption';
        id: string;
        title: string;
        subtitle: string | null;
        descriptionLong: string | null;
        isSuggested: boolean;
        category: string | null;
        extraData: any | null;
      }>;
    }>;
  };
};

export type GQDownloadValueMappingMutationVariables = Exact<{
  valueMappingConfigId: Scalars['ID']['input'];
  withComments: InputMaybe<Scalars['Boolean']['input']>;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQDownloadValueMappingMutation = {
  __typename?: 'Mutation';
  generateValueMappingDownloadUrl: string;
};

export type GQSuggestMappingsMutationVariables = Exact<{
  valueMappingConfigId: Scalars['ID']['input'];
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQSuggestMappingsMutation = {
  __typename?: 'Mutation';
  suggestMappings: { __typename?: 'suggestMappingsPayload'; jobId: string };
};

export type GQValueMappingConfigQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GQValueMappingConfigQuery = {
  __typename?: 'Query';
  valueMappingConfig: any;
};

export type GQValueMappingAggregatedQuantitiesQueryVariables = Exact<{
  configId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  contextColumn: Scalars['String']['input'];
}>;

export type GQValueMappingAggregatedQuantitiesQuery = {
  __typename?: 'Query';
  valueMappingAggregatedQuantities: {
    __typename?: 'ValueMappingAggregatedQuantities';
    totalQuantity: number;
    mappedQuantity: number;
  } | null;
};

export type GQSaveDeltaProgressValueMappingMutationVariables = Exact<{
  input: GQSaveDeltaProgressValueMappingInput;
}>;

export type GQSaveDeltaProgressValueMappingMutation = {
  __typename?: 'Mutation';
  saveDeltaProgressValueMapping: {
    __typename?: 'SaveDeltaProgressValueMappingPayload';
    revisionData: {
      __typename?: 'DatasourceValueMappingRevisionData';
      id: string;
      revisionState: GQReferenceDataRevisionState | null;
    };
    updatedRows: Array<{
      __typename?: 'ValueMappingRow';
      id: string;
      baseRowJson: string;
    }>;
    metadata: {
      __typename?: 'ValueMappingRowsMetadata';
      id: string;
      allRowsAreFilled: boolean;
    } | null;
  };
};

export type GQConfirmMarketplacePurchaseMutationVariables = Exact<{
  input: GQConfirmMarketplacePurchaseInput;
}>;

export type GQConfirmMarketplacePurchaseMutation = {
  __typename?: 'Mutation';
  confirmMarketplacePurchase: {
    __typename?: 'ConfirmMarketplacePurchasePayload';
    purchase: {
      __typename?: 'MarketplacePurchase';
      id: string;
      name: string;
      createdAt: Date;
      updatedAt: Date;
      totalCostUsdCents: number;
      customerStatus: GQCustomerStatus;
      offerExpiration: Date | null;
      lineItems: {
        __typename?: 'MarketplacePurchaseLineItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseLineItemEdge';
          node: {
            __typename?: 'MarketplacePurchaseLineItem';
            id: string;
            amount: number;
            amountUnit: GQMarketplaceProjectArchetypeUnit;
            coverageInterval: YMInterval;
            unitCostUsdCents: number;
            deliveryDate: Date | null;
            isDelivered: boolean;
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
              bannerImageUrl: string | null;
              imageUrl: string | null;
              descriptionMd: string;
              certificateBlurb: string | null;
              kind: GQMarketplaceProjectArchetypeKind;
              summaryMd: string;
            } | null;
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              developer: {
                __typename?: 'MarketplaceDeveloper';
                id: string;
                name: string;
                imageUrl: string | null;
              } | null;
            } | null;
            documents: {
              __typename?: 'MarketplaceDocumentConnection';
              edges: Array<{
                __typename?: 'MarketplaceDocumentEdge';
                node: {
                  __typename?: 'MarketplaceDocument';
                  id: string;
                  name: string;
                  kind: GQMarketplaceDocumentKind;
                  downloadUrl: string;
                } | null;
              } | null>;
            };
            eacMetadata: {
              __typename?: 'MarketplacePurchaseLineItemEacMetadata';
              id: string;
              countryWithEmissions: string;
              countryWithEacs: string;
              numEacsRequested: number;
              mwh: number;
              marketTco2e: number;
            } | null;
          } | null;
        } | null>;
      };
      documents: {
        __typename?: 'MarketplaceDocumentConnection';
        edges: Array<{
          __typename?: 'MarketplaceDocumentEdge';
          node: {
            __typename?: 'MarketplaceDocument';
            id: string;
            name: string;
            kind: GQMarketplaceDocumentKind;
            downloadUrl: string;
          } | null;
        } | null>;
      };
      portfolio: { __typename?: 'MarketplacePortfolio'; id: string } | null;
      eacMetadata: {
        __typename?: 'MarketplacePurchaseEacMetadata';
        id: string;
        interval: YMInterval;
        requestNotes: string | null;
        ghgCategoryIds: Array<string> | null;
        marketTco2e: number;
      } | null;
    };
  } | null;
};

export type GQVisibleMarketplaceDeveloperFieldsFragment = {
  __typename?: 'MarketplaceDeveloper';
  id: string;
  name: string;
  url: string | null;
  descriptionMd: string | null;
  imageUrl: string | null;
};

export type GQVisibleMarketplaceProjectArchetypeFieldsFragment = {
  __typename: 'MarketplaceProjectArchetype';
  id: string;
  kind: GQMarketplaceProjectArchetypeKind;
  unit: GQMarketplaceProjectArchetypeUnit;
  name: string;
  isUserVisible: boolean;
  descriptionMd: string;
  summaryMd: string;
  imageUrl: string | null;
  bannerImageUrl: string | null;
  additionalityRating: number | null;
  permanenceRating: string | null;
  priceUsdCentsMin: number | null;
  priceUsdCentsMax: number | null;
  visibleDevelopers: {
    __typename?: 'MarketplaceDeveloperConnection';
    edges: Array<{
      __typename?: 'MarketplaceDeveloperEdge';
      node: {
        __typename?: 'MarketplaceDeveloper';
        id: string;
        name: string;
        url: string | null;
        descriptionMd: string | null;
        imageUrl: string | null;
      } | null;
    } | null>;
  };
};

export type GQGetVisibleMarketplaceProjectArchetypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetVisibleMarketplaceProjectArchetypesQuery = {
  __typename?: 'Query';
  visibleMarketplaceProjectArchetypes: {
    __typename?: 'MarketplaceProjectArchetypeConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectArchetypeEdge';
      node: {
        __typename: 'MarketplaceProjectArchetype';
        id: string;
        kind: GQMarketplaceProjectArchetypeKind;
        unit: GQMarketplaceProjectArchetypeUnit;
        name: string;
        isUserVisible: boolean;
        descriptionMd: string;
        summaryMd: string;
        imageUrl: string | null;
        bannerImageUrl: string | null;
        additionalityRating: number | null;
        permanenceRating: string | null;
        priceUsdCentsMin: number | null;
        priceUsdCentsMax: number | null;
        visibleDevelopers: {
          __typename?: 'MarketplaceDeveloperConnection';
          edges: Array<{
            __typename?: 'MarketplaceDeveloperEdge';
            node: {
              __typename?: 'MarketplaceDeveloper';
              id: string;
              name: string;
              url: string | null;
              descriptionMd: string | null;
              imageUrl: string | null;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
};

export type GQGetMarketplaceVoluntaryCarbonMarketDisclosuresQueryVariables =
  Exact<{ [key: string]: never }>;

export type GQGetMarketplaceVoluntaryCarbonMarketDisclosuresQuery = {
  __typename?: 'Query';
  marketplaceVoluntaryCarbonMarketDisclosures: Array<{
    __typename?: 'MarketplaceVoluntaryCarbonMarketDisclosure';
    year: number;
    url: string;
  }>;
};

export type GQRefreshMarketplacePurchaseMutationVariables = Exact<{
  input: GQRefreshMarketplacePurchaseInput;
}>;

export type GQRefreshMarketplacePurchaseMutation = {
  __typename?: 'Mutation';
  refreshMarketplacePurchase: {
    __typename?: 'ConfirmMarketplacePurchasePayload';
    purchase: {
      __typename?: 'MarketplacePurchase';
      id: string;
      name: string;
      createdAt: Date;
      updatedAt: Date;
      totalCostUsdCents: number;
      customerStatus: GQCustomerStatus;
      offerExpiration: Date | null;
      lineItems: {
        __typename?: 'MarketplacePurchaseLineItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePurchaseLineItemEdge';
          node: {
            __typename?: 'MarketplacePurchaseLineItem';
            id: string;
            amount: number;
            amountUnit: GQMarketplaceProjectArchetypeUnit;
            coverageInterval: YMInterval;
            unitCostUsdCents: number;
            deliveryDate: Date | null;
            isDelivered: boolean;
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
              name: string;
              bannerImageUrl: string | null;
              imageUrl: string | null;
              descriptionMd: string;
              certificateBlurb: string | null;
              kind: GQMarketplaceProjectArchetypeKind;
              summaryMd: string;
            } | null;
            project: {
              __typename?: 'MarketplaceProject';
              id: string;
              name: string;
              developer: {
                __typename?: 'MarketplaceDeveloper';
                id: string;
                name: string;
                imageUrl: string | null;
              } | null;
            } | null;
            documents: {
              __typename?: 'MarketplaceDocumentConnection';
              edges: Array<{
                __typename?: 'MarketplaceDocumentEdge';
                node: {
                  __typename?: 'MarketplaceDocument';
                  id: string;
                  name: string;
                  kind: GQMarketplaceDocumentKind;
                  downloadUrl: string;
                } | null;
              } | null>;
            };
            eacMetadata: {
              __typename?: 'MarketplacePurchaseLineItemEacMetadata';
              id: string;
              countryWithEmissions: string;
              countryWithEacs: string;
              numEacsRequested: number;
              mwh: number;
              marketTco2e: number;
            } | null;
          } | null;
        } | null>;
      };
      documents: {
        __typename?: 'MarketplaceDocumentConnection';
        edges: Array<{
          __typename?: 'MarketplaceDocumentEdge';
          node: {
            __typename?: 'MarketplaceDocument';
            id: string;
            name: string;
            kind: GQMarketplaceDocumentKind;
            downloadUrl: string;
          } | null;
        } | null>;
      };
      portfolio: { __typename?: 'MarketplacePortfolio'; id: string } | null;
      eacMetadata: {
        __typename?: 'MarketplacePurchaseEacMetadata';
        id: string;
        interval: YMInterval;
        requestNotes: string | null;
        ghgCategoryIds: Array<string> | null;
        marketTco2e: number;
      } | null;
    };
  } | null;
};

export type GQFetchDatasetsSimpleQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFetchDatasetsSimpleQuery = {
  __typename?: 'Query';
  datasets: Array<{
    __typename?: 'Dataset';
    id: string;
    name: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
      selfServeDisabledReason: string | null;
    } | null;
  }>;
};

export type GQFetchDatasetsForPlanQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFetchDatasetsForPlanQuery = {
  __typename?: 'Query';
  availableCanonicalDatasetsForPlan: Array<{
    __typename?: 'CanonicalDataset';
    id: string;
    kind: GQCanonicalDatasetKind;
    name: string;
    selfServeDisabledReason: string | null;
    canonicalDatasources: Array<{
      __typename?: 'CanonicalDatasource';
      id: string;
      name: string;
      activeIntegrationConnection: {
        __typename?: 'IntegrationConnection';
        id: string;
      } | null;
    }>;
  }>;
};

export type GQCreateClimateProgramProjectDatasetRequirementMutationVariables =
  Exact<{
    input: GQCreateClimateProgramProjectDatasetRequirementInput;
  }>;

export type GQCreateClimateProgramProjectDatasetRequirementMutation = {
  __typename?: 'Mutation';
  createClimateProgramProjectDatasetRequirement: {
    __typename?: 'ClimateProgramProjectPayload';
    climateProgramProject: {
      __typename: 'ClimateProgramProject';
      id: string;
      name: string;
      description: string;
      kind: GQClimateProgramProjectKind;
      startDate: Date | null;
      endDate: Date | null;
      plannedYear: number;
      completedAt: Date | null;
      status: GQClimateProgramProjectStatus;
      iconType: GQClimateProgramProjectIconType;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
      } | null;
      canonicalClimateProgramProject: {
        __typename: 'CanonicalClimateProgramProject';
        id: string;
        routeType: string | null;
        name: string;
        kind: GQClimateProgramProjectKind;
        measurementObjectCopy: string | null;
      } | null;
      datasetRequirements: Array<{
        __typename?: 'ClimateProgramProjectRequirementDataset';
        id: string;
        additionalNotesMd: string | null;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          instructions: {
            __typename?: 'InstructionsBundle';
            instructionsIntroMd: string | null;
          } | null;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
            instructions: {
              __typename?: 'InstructionsBundle';
              sdiInstructionsIntroMd: string | null;
            } | null;
          } | null;
        };
        canonicalGhgpRequirement: {
          __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
          id: string;
          ghgScope: number;
          ghgScope3Category: number | null;
          minimumFidelity: GQClimateProgramProjectRequirementFidelity;
        } | null;
      }>;
    };
  };
};

export type GQGetOrCreateDatasetFromCanonicalDatasetKindMutationVariables =
  Exact<{
    input: GQGetOrCreateDatasetFromCanonicalDatasetKindInput;
  }>;

export type GQGetOrCreateDatasetFromCanonicalDatasetKindMutation = {
  __typename?: 'Mutation';
  getOrCreateDatasetFromCanonicalDatasetKind: {
    __typename?: 'GetOrCreateDatasetFromCanonicalDatasetKindPayload';
    dataset: { __typename?: 'Dataset'; id: string; name: string };
  };
};

export type GQCreateMeasurementProjectFromCanonicalClimateProgramProjectMutationVariables =
  Exact<{
    input: GQCreateMeasurementProjectFromCanonicalClimateProgramProjectInput;
  }>;

export type GQCreateMeasurementProjectFromCanonicalClimateProgramProjectMutation =
  {
    __typename?: 'Mutation';
    createMeasurementProjectFromCanonicalClimateProgramProject: {
      __typename?: 'CreateMeasurementProjectFromCanonicalClimateProgramProjectPayload';
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
        active: boolean;
        setupAt: Date | null;
        completedAt: Date | null;
        footprintStatus: GQMeasurementProjectFootprintStatus;
        coverageInterval: YMInterval;
        deadline: Date | null;
        status: GQMeasurementProjectStatus;
        createdAt: Date;
        coverageStartDate: Date;
        coverageEndDate: Date;
        userUploadTasks: {
          __typename?: 'UserUploadTaskConnection';
          edges: Array<{
            __typename?: 'UserUploadTaskEdge';
            node: {
              __typename?: 'UserUploadTask';
              id: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
                userCanAccessDetails: boolean | null;
                userCanApproveDatasource: boolean | null;
                userCanManageDatasource: boolean | null;
                isUserConfirmed: boolean | null;
                hasIntegration: boolean;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
              issues: {
                __typename?: 'DataIssueConnection';
                edges: Array<{
                  __typename?: 'DataIssueEdge';
                  node: {
                    __typename?: 'DataIssue';
                    id: string;
                    state: GQDataIssueState;
                    title: string;
                  } | null;
                } | null>;
              } | null;
            } | null;
          } | null>;
        };
        measurementTimeline: {
          __typename?: 'MeasurementTimeline';
          id: string;
          kickoff: Date;
          deadline: Date;
        } | null;
        measurementTasks: Array<{
          __typename?: 'MeasurementParentTask';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          lockState: GQApprovalTargetLockState | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          subTasks: Array<
            | {
                __typename?: 'MeasurementParentTask';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
            | {
                __typename?: 'MeasurementTaskFlat';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
          >;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          approvers: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }>;
        datasetsWithStages: Array<{
          __typename?: 'DatasetWithStage';
          id: string;
          areAllTasksComplete: boolean;
          areUploadsComplete: boolean;
          isUsableInFootprint: boolean;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          } | null;
        }>;
        measurementVerificationItems: {
          __typename?: 'MeasurementVerificationItemConnection';
          edges: Array<{
            __typename?: 'MeasurementVerificationItemEdge';
            node: {
              __typename?: 'MeasurementVerificationItem';
              id: string;
              status: GQMeasurementVerificationItemStatus;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              } | null;
            } | null;
          }>;
        };
        climateProgramProjects: Array<{
          __typename: 'ClimateProgramProject';
          id: string;
          name: string;
          canonicalClimateProgramProject: {
            __typename: 'CanonicalClimateProgramProject';
            id: string;
            measurementObjectCopy: string | null;
            kind: GQClimateProgramProjectKind;
            routeType: string | null;
            name: string;
          } | null;
        }>;
      };
      climateProgramProjects: Array<{
        __typename?: 'ClimateProgramProject';
        id: string;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          name: string;
        } | null;
      }>;
    };
  };

export type GQCreateCustomerInitiatedDataIssueMutationVariables = Exact<{
  input: GQCreateCustomerInitiatedDataIssueInput;
}>;

export type GQCreateCustomerInitiatedDataIssueMutation = {
  __typename?: 'Mutation';
  createCustomerInitiatedDataIssue: {
    __typename?: 'CreateCustomerInitiatedDataIssuePayload';
    issue: {
      __typename?: 'DataIssue';
      id: string;
      title: string;
      description: string | null;
      state: GQDataIssueState;
      createdAt: Date;
      isCustomerInitiated: boolean;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        completedAt: Date | null;
      };
      comments: {
        __typename?: 'CommentConnection';
        edges: Array<{
          __typename?: 'CommentEdge';
          node: {
            __typename?: 'Comment';
            id: string;
            message: string;
            isPublished: boolean;
            createdAt: Date;
            person:
              | {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | {
                  __typename?: 'WatershedEmployee';
                  id: string;
                  name: string;
                  displayName: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | null;
          } | null;
        } | null>;
      };
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      setupAt: Date | null;
      completedAt: Date | null;
      footprintStatus: GQMeasurementProjectFootprintStatus;
      coverageInterval: YMInterval;
      deadline: Date | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              userCanAccessDetails: boolean | null;
              userCanApproveDatasource: boolean | null;
              userCanManageDatasource: boolean | null;
              isUserConfirmed: boolean | null;
              hasIntegration: boolean;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  state: GQDataIssueState;
                  title: string;
                } | null;
              } | null>;
            } | null;
          } | null;
        } | null>;
      };
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        lockState: GQApprovalTargetLockState | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          didLatestEmailBounce: boolean;
        }>;
        approvers: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
        areUploadsComplete: boolean;
        isUsableInFootprint: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          } | null;
        }>;
      };
      climateProgramProjects: Array<{
        __typename: 'ClimateProgramProject';
        id: string;
        name: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          measurementObjectCopy: string | null;
          kind: GQClimateProgramProjectKind;
          routeType: string | null;
          name: string;
        } | null;
      }>;
    };
    valueMappingTask: {
      __typename?: 'ValueMappingTask';
      id: string;
      publishStatus: GQValueMappingPublishStatus;
      issues: {
        __typename?: 'DataIssueConnection';
        edges: Array<{
          __typename?: 'DataIssueEdge';
          node: {
            __typename?: 'DataIssue';
            id: string;
            title: string;
            description: string | null;
            state: GQDataIssueState;
            createdAt: Date;
            isCustomerInitiated: boolean;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              completedAt: Date | null;
            };
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  message: string;
                  isPublished: boolean;
                  createdAt: Date;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type GQDataCollectionCommandPaletteCustomFieldFragment = {
  __typename?: 'IngestionCustomField';
  id: string;
  name: string;
  type: GQIngestionCustomFieldType;
  possibleValues: Array<string> | null;
  description: string | null;
};

export type GQDataCollectionCommandPaletteCustomFieldUsedValuesQueryVariables =
  Exact<{
    id: Scalars['ID']['input'];
  }>;

export type GQDataCollectionCommandPaletteCustomFieldUsedValuesQuery = {
  __typename?: 'Query';
  ingestionCustomField: {
    __typename?: 'IngestionCustomField';
    id: string;
    usedValues: Array<string>;
  } | null;
};

export type GQDeleteClimateProgramProjectDatasetRequirementMutationVariables =
  Exact<{
    input: GQIdInput;
  }>;

export type GQDeleteClimateProgramProjectDatasetRequirementMutation = {
  __typename?: 'Mutation';
  deleteClimateProgramProjectDatasetRequirement: {
    __typename?: 'ClimateProgramProjectPayload';
    climateProgramProject: {
      __typename: 'ClimateProgramProject';
      id: string;
      name: string;
      description: string;
      kind: GQClimateProgramProjectKind;
      startDate: Date | null;
      endDate: Date | null;
      plannedYear: number;
      completedAt: Date | null;
      status: GQClimateProgramProjectStatus;
      iconType: GQClimateProgramProjectIconType;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
      } | null;
      canonicalClimateProgramProject: {
        __typename: 'CanonicalClimateProgramProject';
        id: string;
        routeType: string | null;
        name: string;
        kind: GQClimateProgramProjectKind;
        measurementObjectCopy: string | null;
      } | null;
      datasetRequirements: Array<{
        __typename?: 'ClimateProgramProjectRequirementDataset';
        id: string;
        additionalNotesMd: string | null;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          instructions: {
            __typename?: 'InstructionsBundle';
            instructionsIntroMd: string | null;
          } | null;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
            instructions: {
              __typename?: 'InstructionsBundle';
              sdiInstructionsIntroMd: string | null;
            } | null;
          } | null;
        };
        canonicalGhgpRequirement: {
          __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
          id: string;
          ghgScope: number;
          ghgScope3Category: number | null;
          minimumFidelity: GQClimateProgramProjectRequirementFidelity;
        } | null;
      }>;
    };
  };
};

export type GQUserUploadFieldsForFilePreviewFragment = {
  __typename?: 'UserUpload';
  id: string;
  processingMode: GQUserUploadProcessingMode | null;
  revisionRoot: string | null;
  userVisibleAttempt: {
    __typename?: 'UserUploadAttempt';
    id: string;
    name: string;
    createdAt: Date;
    size: number;
    description: string | null;
    remoteWritten: boolean;
    uploader: { __typename?: 'User'; id: string; name: string } | null;
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      sheetName: string | null;
      sheetIndex: number | null;
      errorMessage: string | null;
      errorType: string | null;
      numRows: number | null;
      status: GQUserUploadedTableStatus;
      schema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      uirSchema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      parseConfig: {
        __typename?: 'UserUploadedTableParseConfig';
        noHeader: boolean;
        skipTopNRows: number;
        skipBottomNRows: number;
        meltIdVars: Array<string | null> | null;
      } | null;
      roboCleanerResult: {
        __typename?: 'UserUploadedTableRoboCleanerResult';
        skipTopNRows: number;
      } | null;
      userReviewMetadata: {
        __typename?: 'UserUploadedTableUserReviewMetadata';
        headerRowIndex: number;
      } | null;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
      };
      dataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataUnshiftedPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
    }>;
    latestUutsIfRevisionExists: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      sheetName: string | null;
      sheetIndex: number | null;
      errorMessage: string | null;
      errorType: string | null;
      numRows: number | null;
      status: GQUserUploadedTableStatus;
      schema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      uirSchema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      parseConfig: {
        __typename?: 'UserUploadedTableParseConfig';
        noHeader: boolean;
        skipTopNRows: number;
        skipBottomNRows: number;
        meltIdVars: Array<string | null> | null;
      } | null;
      roboCleanerResult: {
        __typename?: 'UserUploadedTableRoboCleanerResult';
        skipTopNRows: number;
      } | null;
      userReviewMetadata: {
        __typename?: 'UserUploadedTableUserReviewMetadata';
        headerRowIndex: number;
      } | null;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
      };
      dataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataUnshiftedPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
    }> | null;
  } | null;
  userUploadTask: {
    __typename?: 'UserUploadTask';
    id: string;
    measurementProject: { __typename?: 'MeasurementProject'; id: string };
    datasource: {
      __typename?: 'Datasource';
      id: string;
      name: string;
      dataset: { __typename?: 'Dataset'; id: string; name: string };
    };
  } | null;
};

export type GQGetUserUploadsByIdFilePreviewDialogQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQGetUserUploadsByIdFilePreviewDialogQuery = {
  __typename?: 'Query';
  userUploadsById: Array<{
    __typename?: 'UserUpload';
    id: string;
    processingMode: GQUserUploadProcessingMode | null;
    revisionRoot: string | null;
    userVisibleAttempt: {
      __typename?: 'UserUploadAttempt';
      id: string;
      name: string;
      createdAt: Date;
      size: number;
      description: string | null;
      remoteWritten: boolean;
      uploader: { __typename?: 'User'; id: string; name: string } | null;
      userUploadedTables: Array<{
        __typename?: 'UserUploadedTable';
        id: string;
        sheetName: string | null;
        sheetIndex: number | null;
        errorMessage: string | null;
        errorType: string | null;
        numRows: number | null;
        status: GQUserUploadedTableStatus;
        schema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        uirSchema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        parseConfig: {
          __typename?: 'UserUploadedTableParseConfig';
          noHeader: boolean;
          skipTopNRows: number;
          skipBottomNRows: number;
          meltIdVars: Array<string | null> | null;
        } | null;
        roboCleanerResult: {
          __typename?: 'UserUploadedTableRoboCleanerResult';
          skipTopNRows: number;
        } | null;
        userReviewMetadata: {
          __typename?: 'UserUploadedTableUserReviewMetadata';
          headerRowIndex: number;
        } | null;
        parentFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
        };
        dataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataUnshiftedPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
      }>;
      latestUutsIfRevisionExists: Array<{
        __typename?: 'UserUploadedTable';
        id: string;
        sheetName: string | null;
        sheetIndex: number | null;
        errorMessage: string | null;
        errorType: string | null;
        numRows: number | null;
        status: GQUserUploadedTableStatus;
        schema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        uirSchema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        parseConfig: {
          __typename?: 'UserUploadedTableParseConfig';
          noHeader: boolean;
          skipTopNRows: number;
          skipBottomNRows: number;
          meltIdVars: Array<string | null> | null;
        } | null;
        roboCleanerResult: {
          __typename?: 'UserUploadedTableRoboCleanerResult';
          skipTopNRows: number;
        } | null;
        userReviewMetadata: {
          __typename?: 'UserUploadedTableUserReviewMetadata';
          headerRowIndex: number;
        } | null;
        parentFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
        };
        dataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataUnshiftedPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
      }> | null;
    } | null;
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      measurementProject: { __typename?: 'MeasurementProject'; id: string };
      datasource: {
        __typename?: 'Datasource';
        id: string;
        name: string;
        dataset: { __typename?: 'Dataset'; id: string; name: string };
      };
    } | null;
  }>;
};

export type GQUpdateClimateProgramProjectDatasetRequirementMutationVariables =
  Exact<{
    input: GQUpdateClimateProgramProjectDatasetRequirementInput;
  }>;

export type GQUpdateClimateProgramProjectDatasetRequirementMutation = {
  __typename?: 'Mutation';
  updateClimateProgramProjectDatasetRequirement: {
    __typename?: 'ClimateProgramProjectRequirementDatasetPayload';
    climateProgramProjectRequirementDataset: {
      __typename?: 'ClimateProgramProjectRequirementDataset';
      id: string;
      additionalNotesMd: string | null;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        instructions: {
          __typename?: 'InstructionsBundle';
          instructionsIntroMd: string | null;
        } | null;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
          instructions: {
            __typename?: 'InstructionsBundle';
            sdiInstructionsIntroMd: string | null;
          } | null;
        } | null;
      };
      canonicalGhgpRequirement: {
        __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
        id: string;
        ghgScope: number;
        ghgScope3Category: number | null;
        minimumFidelity: GQClimateProgramProjectRequirementFidelity;
      } | null;
    };
  };
};

export type GQUserUploadedTableForTablePreviewDialogFragment = {
  __typename?: 'UserUploadedTable';
  id: string;
  sheetName: string | null;
  sheetIndex: number | null;
  errorMessage: string | null;
  errorType: string | null;
  numRows: number | null;
  status: GQUserUploadedTableStatus;
  schema: Array<{
    __typename?: 'UserUploadedTableSchemaColumn';
    index: number;
    kind: GQUserUploadedTableSchemaColumnKind;
    name: string;
    alias: string;
  }> | null;
  uirSchema: Array<{
    __typename?: 'UserUploadedTableSchemaColumn';
    index: number;
    kind: GQUserUploadedTableSchemaColumnKind;
    name: string;
    alias: string;
  }> | null;
  parseConfig: {
    __typename?: 'UserUploadedTableParseConfig';
    noHeader: boolean;
    skipTopNRows: number;
    skipBottomNRows: number;
    meltIdVars: Array<string | null> | null;
  } | null;
  roboCleanerResult: {
    __typename?: 'UserUploadedTableRoboCleanerResult';
    skipTopNRows: number;
  } | null;
  userReviewMetadata: {
    __typename?: 'UserUploadedTableUserReviewMetadata';
    headerRowIndex: number;
  } | null;
  parentFileMetadata: { __typename?: 'FileMetadata'; id: string; name: string };
  dataPreview: {
    __typename?: 'UserUploadedTableDataPreview';
    dataUntyped: any;
  } | null;
  rawDataPreview: {
    __typename?: 'UserUploadedTableDataPreview';
    dataUntyped: any;
  } | null;
  rawDataUnshiftedPreview: {
    __typename?: 'UserUploadedTableDataPreview';
    dataUntyped: any;
  } | null;
};

export type GQUserUploadedTableByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQUserUploadedTableByIdQuery = {
  __typename?: 'Query';
  userUploadedTableById: {
    __typename?: 'UserUploadedTable';
    id: string;
    sheetName: string | null;
    sheetIndex: number | null;
    errorMessage: string | null;
    errorType: string | null;
    numRows: number | null;
    status: GQUserUploadedTableStatus;
    schema: Array<{
      __typename?: 'UserUploadedTableSchemaColumn';
      index: number;
      kind: GQUserUploadedTableSchemaColumnKind;
      name: string;
      alias: string;
    }> | null;
    uirSchema: Array<{
      __typename?: 'UserUploadedTableSchemaColumn';
      index: number;
      kind: GQUserUploadedTableSchemaColumnKind;
      name: string;
      alias: string;
    }> | null;
    parseConfig: {
      __typename?: 'UserUploadedTableParseConfig';
      noHeader: boolean;
      skipTopNRows: number;
      skipBottomNRows: number;
      meltIdVars: Array<string | null> | null;
    } | null;
    roboCleanerResult: {
      __typename?: 'UserUploadedTableRoboCleanerResult';
      skipTopNRows: number;
    } | null;
    userReviewMetadata: {
      __typename?: 'UserUploadedTableUserReviewMetadata';
      headerRowIndex: number;
    } | null;
    parentFileMetadata: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
    };
    dataPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
    rawDataPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
    rawDataUnshiftedPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
  };
};

export type GQSubmitFacilitiesBulkAdditionMutationVariables = Exact<{
  input: GQSubmitFacilitiesBulkAdditionInput;
}>;

export type GQSubmitFacilitiesBulkAdditionMutation = {
  __typename?: 'Mutation';
  submitFacilitiesBulkAddition: {
    __typename?: 'SubmitFacilitiesBulkAdditionPayload';
    numFacilitiesCreated: number;
    org: {
      __typename?: 'Organization';
      id: string;
      buildingsUntyped: Array<{
        __typename?: 'BuildingUntyped';
        id: string;
        data: any;
      }>;
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      setupAt: Date | null;
      completedAt: Date | null;
      footprintStatus: GQMeasurementProjectFootprintStatus;
      coverageInterval: YMInterval;
      deadline: Date | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        lockState: GQApprovalTargetLockState | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          didLatestEmailBounce: boolean;
        }>;
        approvers: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            buildingIds: Array<string>;
            lockState: GQApprovalTargetLockState;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  title: string;
                  description: string | null;
                  state: GQDataIssueState;
                  createdAt: Date;
                  isCustomerInitiated: boolean;
                  measurementProject: {
                    __typename?: 'MeasurementProject';
                    id: string;
                    completedAt: Date | null;
                  };
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        message: string;
                        isPublished: boolean;
                        createdAt: Date;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            } | null;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              isApi: boolean | null;
              userCanAccessDetails: boolean | null;
              userCanApproveDatasource: boolean | null;
              userCanManageDatasource: boolean | null;
              isUserConfirmed: boolean | null;
              hasIntegration: boolean;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              active: boolean;
            };
          } | null;
        } | null>;
      };
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
        areUploadsComplete: boolean;
        isUsableInFootprint: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          } | null;
        }>;
      };
      climateProgramProjects: Array<{
        __typename: 'ClimateProgramProject';
        id: string;
        name: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          measurementObjectCopy: string | null;
          kind: GQClimateProgramProjectKind;
          routeType: string | null;
          name: string;
        } | null;
      }>;
    };
  };
};

export type GQCreateSingleUploaderFacilitiesTaskMutationVariables = Exact<{
  input: GQCreateSingleUploaderFacilitiesTaskInput;
}>;

export type GQCreateSingleUploaderFacilitiesTaskMutation = {
  __typename?: 'Mutation';
  createSingleUploaderFacilitiesTask: {
    __typename?: 'CreateSingleUploaderFacilitiesTaskPayload';
    task: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
      buildingIds: Array<string>;
      lockState: GQApprovalTargetLockState;
      assignees: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      }>;
      issues: {
        __typename?: 'DataIssueConnection';
        edges: Array<{
          __typename?: 'DataIssueEdge';
          node: {
            __typename?: 'DataIssue';
            id: string;
            title: string;
            description: string | null;
            state: GQDataIssueState;
            createdAt: Date;
            isCustomerInitiated: boolean;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              completedAt: Date | null;
            };
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  message: string;
                  isPublished: boolean;
                  createdAt: Date;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      } | null;
      datasource: {
        __typename?: 'Datasource';
        id: string;
        name: string;
        isApi: boolean | null;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        };
      };
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        active: boolean;
      };
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      setupAt: Date | null;
      completedAt: Date | null;
      footprintStatus: GQMeasurementProjectFootprintStatus;
      coverageInterval: YMInterval;
      deadline: Date | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            buildingIds: Array<string>;
            lockState: GQApprovalTargetLockState;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  title: string;
                  description: string | null;
                  state: GQDataIssueState;
                  createdAt: Date;
                  isCustomerInitiated: boolean;
                  measurementProject: {
                    __typename?: 'MeasurementProject';
                    id: string;
                    completedAt: Date | null;
                  };
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        message: string;
                        isPublished: boolean;
                        createdAt: Date;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            } | null;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              isApi: boolean | null;
              userCanAccessDetails: boolean | null;
              userCanApproveDatasource: boolean | null;
              userCanManageDatasource: boolean | null;
              isUserConfirmed: boolean | null;
              hasIntegration: boolean;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              active: boolean;
            };
          } | null;
        } | null>;
      };
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        lockState: GQApprovalTargetLockState | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          didLatestEmailBounce: boolean;
        }>;
        approvers: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
        areUploadsComplete: boolean;
        isUsableInFootprint: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          } | null;
        }>;
      };
      climateProgramProjects: Array<{
        __typename: 'ClimateProgramProject';
        id: string;
        name: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          measurementObjectCopy: string | null;
          kind: GQClimateProgramProjectKind;
          routeType: string | null;
          name: string;
        } | null;
      }>;
    };
  };
};

export type GQBulkCreateUploadTasksMutationVariables = Exact<{
  input: GQBulkCreateUploadTasksInput;
}>;

export type GQBulkCreateUploadTasksMutation = {
  __typename?: 'Mutation';
  bulkCreateUploadTasks: {
    __typename?: 'BulkCreateUploadTasksPayload';
    success: boolean;
  };
};

export type GQBatchSetCustomFieldForDatasourcesMutationVariables = Exact<{
  input: GQBatchSetCustomFieldForDatasourcesInput;
}>;

export type GQBatchSetCustomFieldForDatasourcesMutation = {
  __typename?: 'Mutation';
  batchSetCustomFieldForDatasources: {
    __typename?: 'BatchSetCustomFieldForDatasourcesPayload';
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      presetCustomTags: Array<{
        __typename?: 'PresetCustomTag';
        ingestionCustomField: {
          __typename?: 'IngestionCustomField';
          id: string;
          name: string;
          type: GQIngestionCustomFieldType;
          possibleValues: Array<string> | null;
          description: string | null;
          appliesToAllDatasets: boolean;
          specificDatasets: Array<{
            __typename?: 'Dataset';
            id: string;
            name: string;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          }>;
        };
        ingestionCustomFieldDatasource: {
          __typename?: 'IngestionCustomFieldDatasource';
          id: string;
          datasourceId: string;
          ingestionCustomFieldId: string;
          ingestionCustomFieldValue: string;
        };
      }>;
    }>;
  };
};

export type GQBatchUnsetCustomFieldForDatasourcesMutationVariables = Exact<{
  input: GQBatchUnsetCustomFieldForDatasourcesInput;
}>;

export type GQBatchUnsetCustomFieldForDatasourcesMutation = {
  __typename?: 'Mutation';
  batchUnsetCustomFieldForDatasources: {
    __typename?: 'BatchUnsetCustomFieldForDatasourcesPayload';
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      presetCustomTags: Array<{
        __typename?: 'PresetCustomTag';
        ingestionCustomField: {
          __typename?: 'IngestionCustomField';
          id: string;
          name: string;
          type: GQIngestionCustomFieldType;
          possibleValues: Array<string> | null;
          description: string | null;
          appliesToAllDatasets: boolean;
          specificDatasets: Array<{
            __typename?: 'Dataset';
            id: string;
            name: string;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          }>;
        };
        ingestionCustomFieldDatasource: {
          __typename?: 'IngestionCustomFieldDatasource';
          id: string;
          datasourceId: string;
          ingestionCustomFieldId: string;
          ingestionCustomFieldValue: string;
        };
      }>;
    }>;
  };
};

export type GQAssignMeasurementTaskMutationVariables = Exact<{
  input: GQAssignMeasurementTaskInput;
  permissions: InputMaybe<Array<GQHasPermissionInput> | GQHasPermissionInput>;
}>;

export type GQAssignMeasurementTaskMutation = {
  __typename?: 'Mutation';
  assignMeasurementTask: {
    __typename?: 'AssignMeasurementTaskPayload';
    measurementTask:
      | {
          __typename?: 'MeasurementParentTask';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          lockState: GQApprovalTargetLockState | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          subTasks: Array<
            | {
                __typename?: 'MeasurementParentTask';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
            | {
                __typename?: 'MeasurementTaskFlat';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
          >;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          approvers: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
      | { __typename?: 'MeasurementTaskFlat' };
    organization: {
      __typename?: 'Organization';
      id: string;
      usersWithPermissions: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: { __typename?: 'User'; id: string } | null;
        } | null>;
      } | null;
    };
  };
};

export type GQSetApproversMutationVariables = Exact<{
  input: GQSetApproversInput;
}>;

export type GQSetApproversMutation = {
  __typename?: 'Mutation';
  setApprovers: {
    __typename?: 'SetApproversPayload';
    target: {
      __typename?: 'UserUploadTask';
      id: string;
      approvers: Array<{
        __typename?: 'UserWithApprovalStatus';
        approvalStatus: GQApprovalStatus | null;
        user: {
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        };
      }>;
    };
  };
};

export type GQGetCustomEmissionsFactorsTaskQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQGetCustomEmissionsFactorsTaskQuery = {
  __typename?: 'Query';
  customEmissionsFactorsTask: {
    __typename?: 'MeasurementParentTask';
    id: string;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
  } | null;
};

export type GQMethodologyCustomizationOneSchemaTemplateQueryVariables = Exact<{
  customizationTypeId: Scalars['String']['input'];
}>;

export type GQMethodologyCustomizationOneSchemaTemplateQuery = {
  __typename?: 'Query';
  methodologyCustomizationOneSchemaTemplateForType: any;
};

export type GQGenerateCustomerUploadedReferenceDataRevisionMutationVariables =
  Exact<{
    input: GQGenerateCustomerUploadedReferenceDataRevisionInput;
  }>;

export type GQGenerateCustomerUploadedReferenceDataRevisionMutation = {
  __typename?: 'Mutation';
  generateCustomerUploadedReferenceDataRevision: {
    __typename?: 'GenerateCustomerUploadedReferenceDataRevisionPayload';
    jobId: string;
  };
};

export type GQCustomEmissionsFactorForDashboardQueryVariables = Exact<{
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQCustomEmissionsFactorForDashboardQuery = {
  __typename?: 'Query';
  customEmissionsFactorsForOrg: Array<{
    __typename?: 'CustomEmissionsFactorsForDashboard';
    id: string;
    startDate: string;
    endDate: string;
    emissionsSpecifier: string;
    kgco2e: number;
    unit: string;
    description: string;
    citationUrl: string;
    citationTitle: string;
    citationNotes: string;
  }> | null;
};

export type GQCreateCustomEmissionsFactorsReferenceDataRevisionMutationVariables =
  Exact<{
    input: GQCreateCustomEmissionsFactorsReferenceDataRevisionInput;
  }>;

export type GQCreateCustomEmissionsFactorsReferenceDataRevisionMutation = {
  __typename?: 'Mutation';
  createCustomEmissionsFactorsReferenceDataRevision: {
    __typename?: 'CreateCustomEmissionsFactorsReferenceDataRevisionPayload';
    jobId: string;
  };
};

export type GQActivityForCustomEmissionsFactorsQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQActivityForCustomEmissionsFactorsQuery = {
  __typename?: 'Query';
  customEmissionsFactorActivityUserUploadTask: {
    __typename?: 'UserUploadTask';
    id: string;
    state: GQUserUploadTaskState;
    createdAt: Date;
    userUploads: {
      __typename?: 'UserUploadConnection';
      edges: Array<{
        __typename?: 'UserUploadEdge';
        node: { __typename?: 'UserUpload'; id: string } | null;
      } | null>;
    } | null;
    datasource: { __typename?: 'Datasource'; id: string };
  } | null;
};

export type GQCustomFieldForEditDialogFragment = {
  __typename?: 'IngestionCustomField';
  id: string;
  name: string;
  createdAt: Date;
  type: GQIngestionCustomFieldType;
  description: string | null;
  possibleValues: Array<string> | null;
  exampleValues: Array<string> | null;
  appliesToAllDatasets: boolean;
  specificUtilityTypes: Array<GQBuildingUtilityType>;
  specificDatasets: Array<{
    __typename?: 'Dataset';
    id: string;
    name: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
    } | null;
  }>;
  ingestionCustomFieldDatasources: Array<{
    __typename?: 'IngestionCustomFieldDatasource';
    id: string;
  }> | null;
};

export type GQDatasetFieldsForCustomFieldEditDialogFragment = {
  __typename?: 'Dataset';
  id: string;
  name: string;
  canonicalDataset: {
    __typename?: 'CanonicalDataset';
    id: string;
    kind: GQCanonicalDatasetKind;
  } | null;
};

export type GQCreateCustomFieldForEditDialogMutationVariables = Exact<{
  input: GQCreateIngestionCustomFieldInput;
}>;

export type GQCreateCustomFieldForEditDialogMutation = {
  __typename?: 'Mutation';
  createIngestionCustomField: {
    __typename?: 'CreateIngestionCustomFieldPayload';
    ingestionCustomField: {
      __typename?: 'IngestionCustomField';
      id: string;
      name: string;
      createdAt: Date;
      type: GQIngestionCustomFieldType;
      description: string | null;
      possibleValues: Array<string> | null;
      exampleValues: Array<string> | null;
      appliesToAllDatasets: boolean;
      specificUtilityTypes: Array<GQBuildingUtilityType>;
      specificDatasets: Array<{
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      }>;
      ingestionCustomFieldDatasources: Array<{
        __typename?: 'IngestionCustomFieldDatasource';
        id: string;
      }> | null;
    };
    organization: {
      __typename?: 'Organization';
      id: string;
      ingestionCustomFields: Array<{
        __typename?: 'IngestionCustomField';
        bartTagName: string;
        id: string;
        name: string;
        description: string | null;
        type: GQIngestionCustomFieldType;
        possibleValues: Array<string> | null;
        appliesToAllDatasets: boolean;
        createdAt: Date;
        exampleValues: Array<string> | null;
        specificUtilityTypes: Array<GQBuildingUtilityType>;
        ingestionCustomFieldDatasources: Array<{
          __typename?: 'IngestionCustomFieldDatasource';
          id: string;
        }> | null;
        specificDatasets: Array<{
          __typename?: 'Dataset';
          id: string;
          name: string;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        }>;
      }>;
      datasets: Array<{
        __typename?: 'Dataset';
        id: string;
        name: string;
        instructions: {
          __typename?: 'InstructionsBundle';
          sdiInstructionsSteps: Array<{
            __typename?: 'SdiInstructionsStep';
            title: string;
            descriptionMd: string;
          }> | null;
          sdiInstructionsFaq: Array<{
            __typename?: 'SdiInstructionsFaqType';
            question: string;
            answerMd: string;
          }> | null;
        } | null;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
          customerTargetSchemas: Array<{
            __typename?: 'CustomerTargetSchema';
            id: string;
            name: string;
            utilityType: GQBuildingUtilityType | null;
            rank: number;
            latestPublishedVersion: {
              __typename?: 'CustomerTargetSchemaVersion';
              id: string;
              formattingNotesMd: string | null;
              orgDatasetExampleData: Array<{
                __typename?: 'IngestionExampleData';
                id: string;
                title: string;
                datasetId: string | null;
                columns: Array<{
                  __typename?: 'IngestionExampleDataColumn';
                  fieldName: string;
                  title: string;
                  description: string;
                  examples: Array<string>;
                  required: boolean;
                  optionalToMap: boolean | null;
                  defaultValue: any | null;
                }>;
                rows: Array<{
                  __typename?: 'IngestionExampleDataRow';
                  fields: Array<{
                    __typename?: 'IngestionExampleDataRowField';
                    fieldName: string;
                    value: string;
                  }>;
                }>;
              }>;
              transforms: Array<{
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                businessActivityTypeName: string | null;
                businessActivityTypeVersion: {
                  __typename?: 'BusinessActivityTypeVersion';
                  id: string;
                } | null;
              }>;
            } | null;
          }>;
        } | null;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          customerTargetSchemaIds: Array<string> | null;
        }>;
      }>;
    };
  };
};

export type GQEditCustomFieldForEditDialogMutationVariables = Exact<{
  input: GQEditIngestionCustomFieldInput;
}>;

export type GQEditCustomFieldForEditDialogMutation = {
  __typename?: 'Mutation';
  editIngestionCustomField: {
    __typename?: 'EditIngestionCustomFieldPayload';
    ingestionCustomField: {
      __typename?: 'IngestionCustomField';
      id: string;
      name: string;
      createdAt: Date;
      type: GQIngestionCustomFieldType;
      description: string | null;
      possibleValues: Array<string> | null;
      exampleValues: Array<string> | null;
      appliesToAllDatasets: boolean;
      specificUtilityTypes: Array<GQBuildingUtilityType>;
      specificDatasets: Array<{
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      }>;
      ingestionCustomFieldDatasources: Array<{
        __typename?: 'IngestionCustomFieldDatasource';
        id: string;
      }> | null;
    };
  };
};

export type GQDeleteCustomFieldForEditDialogMutationVariables = Exact<{
  input: GQDeleteIngestionCustomFieldInput;
}>;

export type GQDeleteCustomFieldForEditDialogMutation = {
  __typename?: 'Mutation';
  deleteIngestionCustomField: {
    __typename?: 'DeleteIngestionCustomFieldPayload';
    organization: {
      __typename?: 'Organization';
      id: string;
      ingestionCustomFields: Array<{
        __typename?: 'IngestionCustomField';
        bartTagName: string;
        id: string;
        name: string;
        description: string | null;
        type: GQIngestionCustomFieldType;
        possibleValues: Array<string> | null;
        appliesToAllDatasets: boolean;
        createdAt: Date;
        exampleValues: Array<string> | null;
        specificUtilityTypes: Array<GQBuildingUtilityType>;
        ingestionCustomFieldDatasources: Array<{
          __typename?: 'IngestionCustomFieldDatasource';
          id: string;
        }> | null;
        specificDatasets: Array<{
          __typename?: 'Dataset';
          id: string;
          name: string;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        }>;
      }>;
      datasets: Array<{
        __typename?: 'Dataset';
        id: string;
        name: string;
        instructions: {
          __typename?: 'InstructionsBundle';
          sdiInstructionsSteps: Array<{
            __typename?: 'SdiInstructionsStep';
            title: string;
            descriptionMd: string;
          }> | null;
          sdiInstructionsFaq: Array<{
            __typename?: 'SdiInstructionsFaqType';
            question: string;
            answerMd: string;
          }> | null;
        } | null;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
          customerTargetSchemas: Array<{
            __typename?: 'CustomerTargetSchema';
            id: string;
            name: string;
            utilityType: GQBuildingUtilityType | null;
            rank: number;
            latestPublishedVersion: {
              __typename?: 'CustomerTargetSchemaVersion';
              id: string;
              formattingNotesMd: string | null;
              orgDatasetExampleData: Array<{
                __typename?: 'IngestionExampleData';
                id: string;
                title: string;
                datasetId: string | null;
                columns: Array<{
                  __typename?: 'IngestionExampleDataColumn';
                  fieldName: string;
                  title: string;
                  description: string;
                  examples: Array<string>;
                  required: boolean;
                  optionalToMap: boolean | null;
                  defaultValue: any | null;
                }>;
                rows: Array<{
                  __typename?: 'IngestionExampleDataRow';
                  fields: Array<{
                    __typename?: 'IngestionExampleDataRowField';
                    fieldName: string;
                    value: string;
                  }>;
                }>;
              }>;
              transforms: Array<{
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                businessActivityTypeName: string | null;
                businessActivityTypeVersion: {
                  __typename?: 'BusinessActivityTypeVersion';
                  id: string;
                } | null;
              }>;
            } | null;
          }>;
        } | null;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          customerTargetSchemaIds: Array<string> | null;
        }>;
      }>;
    };
    ingestionCustomField: {
      __typename?: 'IngestionCustomField';
      id: string;
      name: string;
      createdAt: Date;
      type: GQIngestionCustomFieldType;
      description: string | null;
      possibleValues: Array<string> | null;
      exampleValues: Array<string> | null;
      appliesToAllDatasets: boolean;
      specificUtilityTypes: Array<GQBuildingUtilityType>;
      specificDatasets: Array<{
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      }>;
      ingestionCustomFieldDatasources: Array<{
        __typename?: 'IngestionCustomFieldDatasource';
        id: string;
      }> | null;
    };
  };
};

export type GQAuditLogEntryFieldsFragment = {
  __typename?: 'AuditLogEntry';
  id: string;
  createdAt: Date;
  eventKind: string;
  eventMetadata: any;
  userDisplayName: string | null;
  appType: string | null;
};

export type GQOrganizationAuditLogsQueryVariables = Exact<{
  interval: Scalars['YMInterval']['input'];
  eventKind: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQOrganizationAuditLogsQuery = {
  __typename?: 'Query';
  auditLogs: {
    __typename?: 'AuditLogConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: Array<{
      __typename?: 'AuditLogEdge';
      node: {
        __typename?: 'AuditLogEntry';
        id: string;
        createdAt: Date;
        eventKind: string;
        eventMetadata: any;
        userDisplayName: string | null;
        appType: string | null;
      } | null;
    } | null>;
    facets: { __typename?: 'AuditLogFacets'; eventKinds: Array<string> };
  };
};

export type GQAddDatasourceDialogQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQAddDatasourceDialogQuery = {
  __typename?: 'Query';
  datasets: Array<{
    __typename?: 'Dataset';
    id: string;
    name: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
      selfServeDisabledReason: string | null;
      canonicalDatasources: Array<{
        __typename?: 'CanonicalDatasource';
        id: string;
        name: string;
        activeIntegrationConnection: {
          __typename?: 'IntegrationConnection';
          id: string;
        } | null;
      }>;
    } | null;
  }>;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    userUploadTasks: {
      __typename?: 'UserUploadTaskConnection';
      edges: Array<{
        __typename?: 'UserUploadTaskEdge';
        node: {
          __typename?: 'UserUploadTask';
          id: string;
          datasource: {
            __typename?: 'Datasource';
            id: string;
            dataset: { __typename?: 'Dataset'; id: string };
          };
        } | null;
      } | null>;
    };
  };
};

export type GQCreateDatasourceMutationVariables = Exact<{
  input: GQCreateDatasourceInput;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQCreateDatasourceMutation = {
  __typename?: 'Mutation';
  createDatasource: {
    __typename?: 'DatasourcePayload';
    datasource: {
      __typename?: 'Datasource';
      id: string;
      name: string;
      isUserConfirmed: boolean | null;
      hasIntegrationOption: boolean;
      hasIntegration: boolean;
      customerTargetSchemaIds: Array<string> | null;
      importInstructionsMd: string | null;
      refreshCadence: GQDatasetCadence;
      hasApiAccess: boolean;
      notesFromWatershedMd: string | null;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          customerTargetSchemaIds: Array<string> | null;
        }>;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          name: string;
          kind: GQCanonicalDatasetKind;
          instructions: {
            __typename?: 'InstructionsBundle';
            instructionsIntroMd: string | null;
            instructionsDataFormattingMd: string | null;
            sdiInstructionsIntroMd: string | null;
            sdiInstructionsDataFormattingMd: string | null;
            ghgScopes: Array<GQGhgScope> | null;
            instructionsSteps: Array<{
              __typename?: 'InstructionsStep';
              descriptionMd: string;
              publicUrl: string | null;
            }> | null;
            sdiInstructionsSteps: Array<{
              __typename?: 'SdiInstructionsStep';
              title: string;
              descriptionMd: string;
            }> | null;
            sdiInstructionsFaq: Array<{
              __typename?: 'SdiInstructionsFaqType';
              question: string;
              answerMd: string;
            }> | null;
          } | null;
          customerTargetSchemas: Array<{
            __typename?: 'CustomerTargetSchema';
            id: string;
            name: string;
            utilityType: GQBuildingUtilityType | null;
            rank: number;
            latestPublishedVersion: {
              __typename?: 'CustomerTargetSchemaVersion';
              id: string;
              formattingNotesMd: string | null;
              orgDatasetExampleData: Array<{
                __typename?: 'IngestionExampleData';
                id: string;
                title: string;
                datasetId: string | null;
                columns: Array<{
                  __typename?: 'IngestionExampleDataColumn';
                  fieldName: string;
                  title: string;
                  description: string;
                  examples: Array<string>;
                  required: boolean;
                  optionalToMap: boolean | null;
                  defaultValue: any | null;
                }>;
                rows: Array<{
                  __typename?: 'IngestionExampleDataRow';
                  fields: Array<{
                    __typename?: 'IngestionExampleDataRowField';
                    fieldName: string;
                    value: string;
                  }>;
                }>;
              }>;
              transforms: Array<{
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                businessActivityTypeName: string | null;
                businessActivityTypeVersion: {
                  __typename?: 'BusinessActivityTypeVersion';
                  id: string;
                } | null;
              }>;
            } | null;
          }>;
        } | null;
        customExampleData: {
          __typename?: 'TabularData';
          cells: Array<Array<any>>;
        } | null;
      };
      activeIntegrationConnection: {
        __typename?: 'IntegrationConnection';
        id: string;
        additionalIdentifier: string | null;
      } | null;
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsIntroMd: string | null;
        instructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
      canonicalDatasource: {
        __typename?: 'CanonicalDatasource';
        id: string;
        name: string;
        integrationPartner: {
          __typename?: 'IntegrationPartner';
          id: string;
          name: string;
          logoUrl: string;
          externalId: string;
          dataType: string;
          isRequestOnly: boolean;
          applicableDatasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            hasIntegration: boolean;
            isUserConfirmed: boolean | null;
            activeIntegrationConnection: {
              __typename?: 'IntegrationConnection';
              id: string;
            } | null;
            dataset: { __typename?: 'Dataset'; id: string; name: string };
          }>;
          activeIntegrationConnections: Array<{
            __typename?: 'IntegrationConnection';
            id: string;
            additionalIdentifier: string | null;
          }>;
        } | null;
      } | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            createdAt: Date;
            state: GQUserUploadTaskState;
            importState: GQDatasourceImportState;
            approvalStatus: GQApprovalStatus;
            isDatasetComplete: boolean | null;
            lockState: GQApprovalTargetLockState;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              displayName: string;
              name: string;
            }>;
            assignee: {
              __typename?: 'User';
              id: string;
              displayName: string;
              name: string;
            } | null;
            measurementTask: {
              __typename?: 'MeasurementTaskFlat';
              id: string;
              status: GQTaskWatershedProcessState;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
            };
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
              active: boolean;
              coverageInterval: YMInterval;
              coverageStartDate: Date;
              coverageEndDate: Date;
              datasetsWithStages: Array<{
                __typename?: 'DatasetWithStage';
                id: string;
                areAllTasksComplete: boolean;
              }>;
              measurementTimeline: {
                __typename?: 'MeasurementTimeline';
                id: string;
                deadline: Date;
              } | null;
            };
            datasetRequirements: Array<{
              __typename?: 'ClimateProgramProjectRequirementDataset';
              id: string;
              additionalNotesMd: string | null;
              climateProgramProject: {
                __typename: 'ClimateProgramProject';
                name: string;
                id: string;
                canonicalClimateProgramProject: {
                  __typename: 'CanonicalClimateProgramProject';
                  id: string;
                  kind: GQClimateProgramProjectKind;
                  measurementObjectCopy: string | null;
                  routeType: string | null;
                  name: string;
                } | null;
              };
            }>;
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: {
                  __typename?: 'UserUpload';
                  id: string;
                  name: string;
                  status: GQUserUploadStatus;
                  processingMode: GQUserUploadProcessingMode | null;
                  userVisibleErrorMessage: string | null;
                  createdAt: Date;
                  latestAdeIdForSDIFacilitiesFlow: string | null;
                  description: string | null;
                  revisionRoot: string | null;
                  processingWorkflowId: string | null;
                  isBeingValueMapped: boolean;
                  actualUserUploadId: string | null;
                  associatedUtilityCount: number;
                  userUploadKind: GQUserUploadCandidateKind | null;
                  userVisibleAttempt: {
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    name: string;
                    size: number;
                    remoteWritten: boolean;
                    createdAt: Date;
                    userUploadSource: GQUserUploadSource | null;
                    uploader: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                    } | null;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                      sheetName: string | null;
                      sheetIndex: number | null;
                      errorMessage: string | null;
                      errorType: string | null;
                      numRows: number | null;
                      status: GQUserUploadedTableStatus;
                      schema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      uirSchema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      parseConfig: {
                        __typename?: 'UserUploadedTableParseConfig';
                        noHeader: boolean;
                        skipTopNRows: number;
                        skipBottomNRows: number;
                        meltIdVars: Array<string | null> | null;
                      } | null;
                      roboCleanerResult: {
                        __typename?: 'UserUploadedTableRoboCleanerResult';
                        skipTopNRows: number;
                      } | null;
                      userReviewMetadata: {
                        __typename?: 'UserUploadedTableUserReviewMetadata';
                        headerRowIndex: number;
                      } | null;
                      parentFileMetadata: {
                        __typename?: 'FileMetadata';
                        id: string;
                        name: string;
                      };
                      dataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataUnshiftedPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                    }>;
                    latestUutsIfRevisionExists: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                      sheetName: string | null;
                      sheetIndex: number | null;
                      errorMessage: string | null;
                      errorType: string | null;
                      numRows: number | null;
                      status: GQUserUploadedTableStatus;
                      schema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      uirSchema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      parseConfig: {
                        __typename?: 'UserUploadedTableParseConfig';
                        noHeader: boolean;
                        skipTopNRows: number;
                        skipBottomNRows: number;
                        meltIdVars: Array<string | null> | null;
                      } | null;
                      roboCleanerResult: {
                        __typename?: 'UserUploadedTableRoboCleanerResult';
                        skipTopNRows: number;
                      } | null;
                      userReviewMetadata: {
                        __typename?: 'UserUploadedTableUserReviewMetadata';
                        headerRowIndex: number;
                      } | null;
                      parentFileMetadata: {
                        __typename?: 'FileMetadata';
                        id: string;
                        name: string;
                      };
                      dataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataUnshiftedPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                    }> | null;
                  } | null;
                  userUploadTask: {
                    __typename?: 'UserUploadTask';
                    id: string;
                    measurementProject: {
                      __typename?: 'MeasurementProject';
                      id: string;
                      name: string;
                    };
                  } | null;
                  transformsIncludingDeleted: Array<{
                    __typename?: 'CustomerTargetSchemaTransform';
                    id: string;
                    deletedAt: Date | null;
                    sql: string;
                    transformTarget: GQCustomerTargetSchemaTransformTarget;
                    businessActivityTypeName: string | null;
                    businessActivityTypeVersionId: string | null;
                    businessActivityTypeVersion: {
                      __typename?: 'BusinessActivityTypeVersion';
                      id: string;
                      name: string;
                    } | null;
                    runs: Array<{
                      __typename?: 'CustomerTargetSchemaTransformRun';
                      id: string;
                      sql: string;
                      error: string | null;
                      createdAt: Date;
                      transform: {
                        __typename?: 'CustomerTargetSchemaTransform';
                        id: string;
                        transformTarget: GQCustomerTargetSchemaTransformTarget;
                        businessActivityTypeName: string | null;
                      };
                      input: {
                        __typename?: 'FileMetadata';
                        id: string;
                        name: string;
                        category: GQFileCategory;
                        revisionRootFileMetadata: {
                          __typename?: 'FileMetadata';
                          id: string;
                          name: string;
                        } | null;
                      };
                      output: {
                        __typename?: 'ParquetData';
                        id: string;
                        numRecords: number;
                        ndjsonSignedUrl: string | null;
                        ndjsonSize: number | null;
                        ndjsonByteBoundaries: Array<number> | null;
                        columns: Array<{
                          __typename?: 'ParquetColumn';
                          name: string;
                          sqlType: string;
                          minValue: any;
                          maxValue: any;
                          numNulls: number;
                          approxDistinctValues: number;
                          sampleValues: Array<any>;
                        }>;
                      } | null;
                      standardizedOutput: {
                        __typename?: 'ParquetData';
                        id: string;
                        numRecords: number;
                        ndjsonSignedUrl: string | null;
                        ndjsonSize: number | null;
                        ndjsonByteBoundaries: Array<number> | null;
                        columns: Array<{
                          __typename?: 'ParquetColumn';
                          name: string;
                          sqlType: string;
                          minValue: any;
                          maxValue: any;
                          numNulls: number;
                          approxDistinctValues: number;
                          sampleValues: Array<any>;
                        }>;
                      } | null;
                      warnings: Array<{
                        __typename?: 'ValidationWarning';
                        message: string;
                        blocking: boolean;
                      }>;
                      runner: { __typename?: 'User'; id: string; name: string };
                    }>;
                  }>;
                } | null;
              } | null>;
            } | null;
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  title: string;
                  description: string | null;
                  state: GQDataIssueState;
                  createdAt: Date;
                  isCustomerInitiated: boolean;
                  measurementProject: {
                    __typename?: 'MeasurementProject';
                    id: string;
                    completedAt: Date | null;
                  };
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        message: string;
                        isPublished: boolean;
                        createdAt: Date;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            } | null;
            valueMappingTask: {
              __typename?: 'ValueMappingTask';
              id: string;
              mappingRuleId: string;
            } | null;
            valueMappingTasks: Array<{
              __typename?: 'ValueMappingTask';
              id: string;
              mappingRuleId: string;
              status: GQTaskWatershedProcessState;
            }>;
            approvers: Array<{
              __typename?: 'UserWithApprovalStatus';
              approvalStatus: GQApprovalStatus | null;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              };
            }>;
            ctsForm: {
              __typename?: 'CtsForm';
              id: string;
              ctsVersionId: string;
              customerTargetSchemaId: string;
              datasetId: string;
              title: string | null;
              description: string | null;
              entries: Array<{
                __typename?: 'CtsFormEntry';
                id: string;
                data: any;
                createdBy: {
                  __typename?: 'User';
                  id: string;
                  displayName: string;
                };
              }>;
            } | null;
          } | null;
        } | null>;
      };
      presetCustomTags: Array<{
        __typename?: 'PresetCustomTag';
        ingestionCustomField: {
          __typename?: 'IngestionCustomField';
          id: string;
          name: string;
          type: GQIngestionCustomFieldType;
          possibleValues: Array<string> | null;
          description: string | null;
          appliesToAllDatasets: boolean;
          specificDatasets: Array<{
            __typename?: 'Dataset';
            id: string;
            name: string;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          }>;
        };
        ingestionCustomFieldDatasource: {
          __typename?: 'IngestionCustomFieldDatasource';
          id: string;
          datasourceId: string;
          ingestionCustomFieldId: string;
          ingestionCustomFieldValue: string;
        };
      }>;
      presetOrgUnits: Array<{
        __typename?: 'PresetOrgUnit';
        orgUnit: { __typename?: 'OrgUnit'; id: string; name: string };
        orgUnitDatasource: { __typename?: 'OrgUnitDatasource'; id: string };
      }>;
    };
  };
};

export type GQFacilitiesForAddFacilitiesGridFragment = {
  __typename?: 'Facility';
  id: string;
  buildingName: string;
  streetAddress: string | null;
  streetAddress2: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  leaseType: GQBuildingLeaseType | null;
  startYearMonth: YearMonth | null;
  endYearMonth: YearMonth | null;
  buildingKind: string | null;
  sizeUnit: GQBuildingSizeUnit | null;
  periods: Array<{
    __typename?: 'BuildingPeriod';
    id: string;
    kind: GQBuildingPeriodKind;
    sizeQuantity: number | null;
    customFieldId: string | null;
    customFieldValue: string | null;
    orgUnitStableId: string | null;
    orgUnitTypeStableId: string | null;
  }>;
  tasks: Array<{
    __typename?: 'UserUploadTask';
    id: string;
    datasource: {
      __typename?: 'Datasource';
      id: string;
      name: string;
      dataset: { __typename?: 'Dataset'; id: string; name: string };
    };
  }>;
};

export type GQFacilitiesForAddFacilitiesGridQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  filter: InputMaybe<Array<GQFacilitiesFilterInput> | GQFacilitiesFilterInput>;
  sort: InputMaybe<Array<GQFacilitiesSortInput> | GQFacilitiesSortInput>;
  search: InputMaybe<Scalars['String']['input']>;
  canonicalDatasetKind: GQCanonicalDatasetKind;
  orgStructureEnabled: Scalars['Boolean']['input'];
}>;

export type GQFacilitiesForAddFacilitiesGridQuery = {
  __typename?: 'Query';
  facilities: {
    __typename?: 'FacilityConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
    edges: Array<{
      __typename?: 'FacilityEdge';
      node: {
        __typename?: 'Facility';
        id: string;
        buildingName: string;
        streetAddress: string | null;
        streetAddress2: string | null;
        city: string | null;
        state: string | null;
        country: string | null;
        leaseType: GQBuildingLeaseType | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        buildingKind: string | null;
        sizeUnit: GQBuildingSizeUnit | null;
        periods: Array<{
          __typename?: 'BuildingPeriod';
          id: string;
          kind: GQBuildingPeriodKind;
          sizeQuantity: number | null;
          customFieldId: string | null;
          customFieldValue: string | null;
          orgUnitStableId: string | null;
          orgUnitTypeStableId: string | null;
        }>;
        tasks: Array<{
          __typename?: 'UserUploadTask';
          id: string;
          datasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: { __typename?: 'Dataset'; id: string; name: string };
          };
        }>;
      } | null;
    } | null>;
  };
  latestOrgUnitTypesForCanonicalDatasetKind?: Array<{
    __typename?: 'OrgUnitType';
    id: string;
    name: string;
    stableId: string;
    orgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      stableId: string;
    }>;
  }>;
};

export type GQAddFacilitiesGridQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQAddFacilitiesGridQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    facilitiesMetadata: {
      __typename?: 'FacilitiesMetadata';
      buildingsDataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        instructions: {
          __typename?: 'InstructionsBundle';
          sdiInstructionsSteps: Array<{
            __typename?: 'SdiInstructionsStep';
            title: string;
            descriptionMd: string;
          }> | null;
          sdiInstructionsFaq: Array<{
            __typename?: 'SdiInstructionsFaqType';
            question: string;
            answerMd: string;
          }> | null;
        } | null;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          customerTargetSchemaIds: Array<string> | null;
        }>;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
          customerTargetSchemas: Array<{
            __typename?: 'CustomerTargetSchema';
            id: string;
            name: string;
            utilityType: GQBuildingUtilityType | null;
            rank: number;
            latestPublishedVersion: {
              __typename?: 'CustomerTargetSchemaVersion';
              id: string;
              formattingNotesMd: string | null;
              orgDatasetExampleData: Array<{
                __typename?: 'IngestionExampleData';
                id: string;
                title: string;
                datasetId: string | null;
                columns: Array<{
                  __typename?: 'IngestionExampleDataColumn';
                  fieldName: string;
                  title: string;
                  description: string;
                  examples: Array<string>;
                  required: boolean;
                  optionalToMap: boolean | null;
                  defaultValue: any | null;
                }>;
                rows: Array<{
                  __typename?: 'IngestionExampleDataRow';
                  fields: Array<{
                    __typename?: 'IngestionExampleDataRowField';
                    fieldName: string;
                    value: string;
                  }>;
                }>;
              }>;
              transforms: Array<{
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                businessActivityTypeName: string | null;
                businessActivityTypeVersion: {
                  __typename?: 'BusinessActivityTypeVersion';
                  id: string;
                } | null;
              }>;
            } | null;
          }>;
        } | null;
      } | null;
      utilitiesDataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
      } | null;
    };
    ingestionCustomFields: Array<{
      __typename?: 'IngestionCustomField';
      bartTagName: string;
      id: string;
      name: string;
      description: string | null;
      type: GQIngestionCustomFieldType;
      possibleValues: Array<string> | null;
      appliesToAllDatasets: boolean;
      createdAt: Date;
      exampleValues: Array<string> | null;
      specificUtilityTypes: Array<GQBuildingUtilityType>;
      ingestionCustomFieldDatasources: Array<{
        __typename?: 'IngestionCustomFieldDatasource';
        id: string;
      }> | null;
      specificDatasets: Array<{
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      }>;
    }>;
  };
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    active: boolean;
    setupAt: Date | null;
    completedAt: Date | null;
    footprintStatus: GQMeasurementProjectFootprintStatus;
    coverageInterval: YMInterval;
    deadline: Date | null;
    userUploadTasks: {
      __typename?: 'UserUploadTaskConnection';
      edges: Array<{
        __typename?: 'UserUploadTaskEdge';
        node: {
          __typename?: 'UserUploadTask';
          id: string;
          state: GQUserUploadTaskState;
          buildingIds: Array<string>;
          lockState: GQApprovalTargetLockState;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          datasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            userCanAccessDetails: boolean | null;
            userCanApproveDatasource: boolean | null;
            userCanManageDatasource: boolean | null;
            isUserConfirmed: boolean | null;
            hasIntegration: boolean;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            };
          };
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            active: boolean;
          };
          approvers: Array<{
            __typename?: 'UserWithApprovalStatus';
            approvalStatus: GQApprovalStatus | null;
            user: {
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            };
          }>;
          facilitiesPreview: {
            __typename?: 'FacilitiesPreview';
            totalCount: number;
            preview: Array<{
              __typename?: 'Facility';
              buildingName: string;
              id: string;
            }>;
          };
          userUploads: {
            __typename?: 'UserUploadConnection';
            edges: Array<{
              __typename?: 'UserUploadEdge';
              node: { __typename?: 'UserUpload'; id: string } | null;
            } | null>;
          } | null;
          issues: {
            __typename?: 'DataIssueConnection';
            edges: Array<{
              __typename?: 'DataIssueEdge';
              node: {
                __typename?: 'DataIssue';
                id: string;
                state: GQDataIssueState;
                title: string;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
    measurementTimeline: {
      __typename?: 'MeasurementTimeline';
      id: string;
      kickoff: Date;
      deadline: Date;
    } | null;
    measurementTasks: Array<{
      __typename?: 'MeasurementParentTask';
      taskGroup: string;
      id: string;
      title: string;
      link: string | null;
      lockState: GQApprovalTargetLockState | null;
      isAssignedToWatershed: boolean;
      statusSimplified: GQTaskWatershedProcessStateSimplified;
      subTasks: Array<
        | {
            __typename?: 'MeasurementParentTask';
            taskGroup: string;
            id: string;
            title: string;
            link: string | null;
            lockState: GQApprovalTargetLockState | null;
            isAssignedToWatershed: boolean;
            statusSimplified: GQTaskWatershedProcessStateSimplified;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            } | null;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
              didLatestEmailBounce: boolean;
            }>;
            approvers: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            object:
              | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
              | {
                  __typename: 'DataIssue';
                  id: string;
                  linkedObject:
                    | {
                        __typename: 'FinancialsReviewItem';
                        id: string;
                        group: {
                          __typename: 'FinancialsReviewGroup';
                          id: string;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        };
                      }
                    | {
                        __typename: 'MeasurementVerificationItemQuestion';
                        id: string;
                        item: {
                          __typename: 'MeasurementVerificationItem';
                          id: string;
                          maybeDataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          } | null;
                        };
                      }
                    | {
                        __typename: 'UserUploadTask';
                        id: string;
                        datasource: {
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        };
                      }
                    | { __typename: 'ValueMappingTask' }
                    | null;
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        createdAt: Date;
                        isPublished: boolean;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                }
              | { __typename: 'FacilitiesSetup'; id: string }
              | {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                }
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | { __typename: 'FootprintQuestion'; id: string }
              | {
                  __typename: 'MeasurementDataMappingTask';
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | { __typename: 'MeasurementProject'; id: string }
              | {
                  __typename: 'MeasurementProjectDataset';
                  modelId: string | null;
                  isCompleted: boolean;
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  mviStatus: GQMeasurementVerificationItemStatus;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  } | null;
                }
              | {
                  __typename: 'UserUploadTask';
                  state: GQUserUploadTaskState;
                  id: string;
                  approvers: Array<{
                    __typename?: 'UserWithApprovalStatus';
                    approvalStatus: GQApprovalStatus | null;
                    user: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                    };
                  }>;
                  datasource: {
                    __typename?: 'Datasource';
                    hasIntegration: boolean;
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      canonicalDataset: {
                        __typename?: 'CanonicalDataset';
                        id: string;
                        kind: GQCanonicalDatasetKind;
                      } | null;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask'; id: string }
              | { __typename: 'VendorMatchingTask'; id: string };
          }
        | {
            __typename?: 'MeasurementTaskFlat';
            taskGroup: string;
            id: string;
            title: string;
            link: string | null;
            lockState: GQApprovalTargetLockState | null;
            isAssignedToWatershed: boolean;
            statusSimplified: GQTaskWatershedProcessStateSimplified;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            } | null;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
              didLatestEmailBounce: boolean;
            }>;
            approvers: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            object:
              | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
              | {
                  __typename: 'DataIssue';
                  id: string;
                  linkedObject:
                    | {
                        __typename: 'FinancialsReviewItem';
                        id: string;
                        group: {
                          __typename: 'FinancialsReviewGroup';
                          id: string;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        };
                      }
                    | {
                        __typename: 'MeasurementVerificationItemQuestion';
                        id: string;
                        item: {
                          __typename: 'MeasurementVerificationItem';
                          id: string;
                          maybeDataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          } | null;
                        };
                      }
                    | {
                        __typename: 'UserUploadTask';
                        id: string;
                        datasource: {
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        };
                      }
                    | { __typename: 'ValueMappingTask' }
                    | null;
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        createdAt: Date;
                        isPublished: boolean;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                }
              | { __typename: 'FacilitiesSetup'; id: string }
              | {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                }
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | { __typename: 'FootprintQuestion'; id: string }
              | {
                  __typename: 'MeasurementDataMappingTask';
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | { __typename: 'MeasurementProject'; id: string }
              | {
                  __typename: 'MeasurementProjectDataset';
                  modelId: string | null;
                  isCompleted: boolean;
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  mviStatus: GQMeasurementVerificationItemStatus;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  } | null;
                }
              | {
                  __typename: 'UserUploadTask';
                  state: GQUserUploadTaskState;
                  id: string;
                  approvers: Array<{
                    __typename?: 'UserWithApprovalStatus';
                    approvalStatus: GQApprovalStatus | null;
                    user: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                    };
                  }>;
                  datasource: {
                    __typename?: 'Datasource';
                    hasIntegration: boolean;
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      canonicalDataset: {
                        __typename?: 'CanonicalDataset';
                        id: string;
                        kind: GQCanonicalDatasetKind;
                      } | null;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask'; id: string }
              | { __typename: 'VendorMatchingTask'; id: string };
          }
      >;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        materialCsrdStandards: Array<GQCsrdStandard>;
      } | null;
      assignees: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
        didLatestEmailBounce: boolean;
      }>;
      approvers: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      }>;
      object:
        | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
        | {
            __typename: 'DataIssue';
            id: string;
            linkedObject:
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItemQuestion';
                  id: string;
                  item: {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    } | null;
                  };
                }
              | {
                  __typename: 'UserUploadTask';
                  id: string;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask' }
              | null;
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  createdAt: Date;
                  isPublished: boolean;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          }
        | { __typename: 'FacilitiesSetup'; id: string }
        | {
            __typename: 'FinancialsReviewGroup';
            id: string;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              };
            }>;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            group: {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            };
          }
        | { __typename: 'FootprintQuestion'; id: string }
        | {
            __typename: 'MeasurementDataMappingTask';
            id: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            };
          }
        | { __typename: 'MeasurementProject'; id: string }
        | {
            __typename: 'MeasurementProjectDataset';
            modelId: string | null;
            isCompleted: boolean;
            id: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            };
          }
        | {
            __typename: 'MeasurementVerificationItem';
            id: string;
            mviStatus: GQMeasurementVerificationItemStatus;
            maybeDataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            } | null;
          }
        | {
            __typename: 'UserUploadTask';
            state: GQUserUploadTaskState;
            id: string;
            approvers: Array<{
              __typename?: 'UserWithApprovalStatus';
              approvalStatus: GQApprovalStatus | null;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              };
            }>;
            datasource: {
              __typename?: 'Datasource';
              hasIntegration: boolean;
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
          }
        | { __typename: 'ValueMappingTask'; id: string }
        | { __typename: 'VendorMatchingTask'; id: string };
    }>;
    datasetsWithStages: Array<{
      __typename?: 'DatasetWithStage';
      id: string;
      areAllTasksComplete: boolean;
      areUploadsComplete: boolean;
      isUsableInFootprint: boolean;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        materialCsrdStandards: Array<GQCsrdStandard>;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      } | null;
    }>;
    measurementVerificationItems: {
      __typename?: 'MeasurementVerificationItemConnection';
      edges: Array<{
        __typename?: 'MeasurementVerificationItemEdge';
        node: {
          __typename?: 'MeasurementVerificationItem';
          id: string;
          status: GQMeasurementVerificationItemStatus;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          } | null;
        } | null;
      }>;
    };
    climateProgramProjects: Array<{
      __typename: 'ClimateProgramProject';
      id: string;
      name: string;
      canonicalClimateProgramProject: {
        __typename: 'CanonicalClimateProgramProject';
        id: string;
        measurementObjectCopy: string | null;
        kind: GQClimateProgramProjectKind;
        routeType: string | null;
        name: string;
      } | null;
    }>;
  };
};

export type GQUnassignFacilitiesFromTaskMutationVariables = Exact<{
  input: GQUnassignFacilitiesFromTaskInput;
}>;

export type GQUnassignFacilitiesFromTaskMutation = {
  __typename?: 'Mutation';
  unassignFacilitiesFromTask: {
    __typename?: 'UnassignFacilitiesFromTaskPayload';
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      setupAt: Date | null;
      completedAt: Date | null;
      footprintStatus: GQMeasurementProjectFootprintStatus;
      coverageInterval: YMInterval;
      deadline: Date | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            buildingIds: Array<string>;
            lockState: GQApprovalTargetLockState;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              userCanAccessDetails: boolean | null;
              userCanApproveDatasource: boolean | null;
              userCanManageDatasource: boolean | null;
              isUserConfirmed: boolean | null;
              hasIntegration: boolean;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              active: boolean;
            };
            approvers: Array<{
              __typename?: 'UserWithApprovalStatus';
              approvalStatus: GQApprovalStatus | null;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              };
            }>;
            facilitiesPreview: {
              __typename?: 'FacilitiesPreview';
              totalCount: number;
              preview: Array<{
                __typename?: 'Facility';
                buildingName: string;
                id: string;
              }>;
            };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: { __typename?: 'UserUpload'; id: string } | null;
              } | null>;
            } | null;
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  state: GQDataIssueState;
                  title: string;
                } | null;
              } | null>;
            } | null;
          } | null;
        } | null>;
      };
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        lockState: GQApprovalTargetLockState | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          didLatestEmailBounce: boolean;
        }>;
        approvers: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
        areUploadsComplete: boolean;
        isUsableInFootprint: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          } | null;
        }>;
      };
      climateProgramProjects: Array<{
        __typename: 'ClimateProgramProject';
        id: string;
        name: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          measurementObjectCopy: string | null;
          kind: GQClimateProgramProjectKind;
          routeType: string | null;
          name: string;
        } | null;
      }>;
    };
    facilities: Array<{
      __typename?: 'Facility';
      id: string;
      buildingName: string;
      streetAddress: string | null;
      streetAddress2: string | null;
      city: string | null;
      state: string | null;
      country: string | null;
      sizeUnit: GQBuildingSizeUnit | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      editState: GQBuildingEditState;
      postalCode: string | null;
      buildingKind: string | null;
      buildingSubkind: string | null;
      leaseType: GQBuildingLeaseType | null;
      buildingUniqueId: string;
      isWithoutNaturalGas: boolean | null;
      isWithoutRefrigerants: boolean | null;
      periods: Array<{
        __typename?: 'BuildingPeriod';
        id: string;
        kind: GQBuildingPeriodKind;
        sizeQuantity: number | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        partialCleanPowerPercent: number | null;
        cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
        powerUsageEffectiveness: number | null;
        isBuildingEmpty: boolean | null;
        customFieldValue: string | null;
        customFieldId: string | null;
        orgUnitTypeStableId: string | null;
        orgUnitStableId: string | null;
      }>;
      latestApprovedVersion: {
        __typename?: 'BuildingForDiff';
        id: string | null;
        streetAddress: string | null;
        streetAddress2: string | null;
        postalCode: string | null;
        city: string | null;
        state: string | null;
        country: string | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        sizeUnit: GQBuildingSizeUnit | null;
        buildingKind: string | null;
        buildingSubkind: string | null;
        leaseType: GQBuildingLeaseType | null;
        buildingName: string;
        buildingUniqueId: string;
        isWithoutNaturalGas: boolean | null;
        isWithoutRefrigerants: boolean | null;
        periods: Array<{
          __typename?: 'BuildingPeriodForDiff';
          kind: GQBuildingPeriodKind;
          sizeQuantity: number | null;
          startYearMonth: YearMonth | null;
          endYearMonth: YearMonth | null;
          partialCleanPowerPercent: number | null;
          cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
          powerUsageEffectiveness: number | null;
          isBuildingEmpty: boolean | null;
          customFieldValue: string | null;
          customFieldId: string | null;
          orgUnitTypeStableId: string | null;
          orgUnitStableId: string | null;
        }>;
      } | null;
    }>;
  };
};

export type GQCustomTransformEditorQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQCustomTransformEditorQuery = {
  __typename?: 'Query';
  activityTypeSchemas: Array<{
    __typename?: 'ActivityTypeSchema';
    id: string;
    activityType: string;
    schema: { __typename?: 'CanonicalSchema'; id: string; jsonSchema: any };
  }>;
  organization: {
    __typename?: 'Organization';
    id: string;
    ingestionCustomFields: Array<{
      __typename?: 'IngestionCustomField';
      id: string;
      name: string;
      type: GQIngestionCustomFieldType;
      description: string | null;
      bartTagName: string;
      possibleValues: Array<string> | null;
      appliesToAllDatasets: boolean;
      specificDatasets: Array<{ __typename?: 'Dataset'; id: string }>;
    }>;
  };
};

export type GQPublishCustomTransformForUserUploadMutationVariables = Exact<{
  input: GQPublishCustomTransformForUserUploadInput;
}>;

export type GQPublishCustomTransformForUserUploadMutation = {
  __typename?: 'Mutation';
  publishCustomTransformForUserUpload: {
    __typename?: 'PublishCustomTransformForUserUploadPayload';
    jobId: string;
  };
};

export type GQGetCustomerTargetSchemasForCanonicalDatasetQueryVariables =
  Exact<{
    id: Scalars['ID']['input'];
  }>;

export type GQGetCustomerTargetSchemasForCanonicalDatasetQuery = {
  __typename?: 'Query';
  canonicalDataset: {
    __typename?: 'CanonicalDataset';
    id: string;
    name: string;
    customerTargetSchemas: Array<{
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      descriptionMd: string | null;
    }>;
  };
};

export type GQGetDatasourceSummaryForDatasourcePageQueryVariables = Exact<{
  datasourceId: Scalars['ID']['input'];
}>;

export type GQGetDatasourceSummaryForDatasourcePageQuery = {
  __typename?: 'Query';
  datasource: {
    __typename?: 'Datasource';
    id: string;
    name: string;
    summaryWithMetadata: {
      __typename?: 'DatasourceSummaryWithMetadata';
      id: string;
      summaries: Array<{ __typename?: 'DatasourceSummary'; id: string }>;
    };
  } | null;
};

export type GQUpdateMeasurementProjectDashboardMutationVariables = Exact<{
  input: GQUpdateMeasurementProjectInput;
}>;

export type GQUpdateMeasurementProjectDashboardMutation = {
  __typename?: 'Mutation';
  updateMeasurementProjectDashboard: {
    __typename?: 'UpdateMeasurementProjectPayload';
    measurementProject: {
      __typename: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      status: GQMeasurementProjectStatus;
      completedAt: Date | null;
      coverageStartDate: Date;
      coverageEndDate: Date;
      coverageInterval: YMInterval;
      deadline: Date | null;
      supportedValueMappingRules: Array<string>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              id: string;
              title: string;
              link: string | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: { __typename?: 'Dataset'; id: string; name: string } | null;
        assignees: Array<{ __typename?: 'User'; id: string; name: string }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
            assignee: { __typename?: 'User'; id: string } | null;
          } | null;
        }>;
      };
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            datasource: { __typename?: 'Datasource'; id: string; name: string };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: { __typename?: 'UserUpload'; id: string } | null;
              } | null>;
            } | null;
            assignee: { __typename?: 'User'; id: string; name: string } | null;
          } | null;
        } | null>;
      };
      measurementProjectDatasets: Array<{
        __typename?: 'MeasurementProjectDataset';
        id: string;
        isCompleted: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          explainer: string | null;
          customUsedFor: string | null;
          customPotentialOverlaps: string | null;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            name: string;
            kind: GQCanonicalDatasetKind;
          } | null;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            customUsedFor: string | null;
            customPotentialOverlaps: string | null;
            customerTargetSchemaIds: Array<string> | null;
          }>;
        };
      }>;
    };
  };
};

export type GQMeasurementProjectForEditQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQMeasurementProjectForEditQuery = {
  __typename?: 'Query';
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    canEditCoverageInterval: boolean;
    coverageInterval: YMInterval;
    name: string;
    measurementTimeline: {
      __typename?: 'MeasurementTimeline';
      id: string;
      kickoff: Date;
      deadline: Date;
    } | null;
  };
};

export type GQDeleteFileMutationVariables = Exact<{
  fileId: Scalars['ID']['input'];
}>;

export type GQDeleteFileMutation = {
  __typename?: 'Mutation';
  deleteFile: {
    __typename?: 'DeleteFilePayload';
    file: { __typename?: 'FileMetadata'; id: string } | null;
    datasource: {
      __typename?: 'Datasource';
      id: string;
      summaryWithMetadata: {
        __typename?: 'DatasourceSummaryWithMetadata';
        id: string;
        summaries: Array<{
          __typename?: 'DatasourceSummary';
          id: string;
          signedUrl: string;
        }>;
      };
    } | null;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areUploadsComplete: boolean;
        isUsableInFootprint: boolean;
        dataset: { __typename?: 'Dataset'; id: string } | null;
      }>;
    } | null;
  } | null;
};

export type GQGetValueMappingRulesForMeasurementProjectQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQGetValueMappingRulesForMeasurementProjectQuery = {
  __typename?: 'Query';
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    supportedValueMappingRules: Array<string>;
  };
};

export type GQMarkUserUploadTaskAsCompleteMutationVariables = Exact<{
  userUploadTaskId: Scalars['ID']['input'];
}>;

export type GQMarkUserUploadTaskAsCompleteMutation = {
  __typename?: 'Mutation';
  completeUserUploadTask: {
    __typename?: 'CompleteUserUploadTaskPayload';
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
      userUploads: {
        __typename?: 'UserUploadConnection';
        edges: Array<{
          __typename?: 'UserUploadEdge';
          node: {
            __typename?: 'UserUpload';
            id: string;
            status: GQUserUploadStatus;
            processingMode: GQUserUploadProcessingMode | null;
          } | null;
        } | null>;
      } | null;
      measurementTask: {
        __typename?: 'MeasurementTaskFlat';
        id: string;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
      };
    };
  };
};

export type GQFacilityFragmentFragment = {
  __typename?: 'Facility';
  id: string;
  buildingName: string;
  streetAddress: string | null;
  streetAddress2: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  sizeUnit: GQBuildingSizeUnit | null;
  startYearMonth: YearMonth | null;
  endYearMonth: YearMonth | null;
  editState: GQBuildingEditState;
  postalCode: string | null;
  buildingKind: string | null;
  buildingSubkind: string | null;
  leaseType: GQBuildingLeaseType | null;
  buildingUniqueId: string;
  isWithoutNaturalGas: boolean | null;
  isWithoutRefrigerants: boolean | null;
  periods: Array<{
    __typename?: 'BuildingPeriod';
    id: string;
    kind: GQBuildingPeriodKind;
    sizeQuantity: number | null;
    startYearMonth: YearMonth | null;
    endYearMonth: YearMonth | null;
    partialCleanPowerPercent: number | null;
    cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
    powerUsageEffectiveness: number | null;
    isBuildingEmpty: boolean | null;
    customFieldValue: string | null;
    customFieldId: string | null;
    orgUnitTypeStableId: string | null;
    orgUnitStableId: string | null;
  }>;
  latestApprovedVersion: {
    __typename?: 'BuildingForDiff';
    id: string | null;
    streetAddress: string | null;
    streetAddress2: string | null;
    postalCode: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    startYearMonth: YearMonth | null;
    endYearMonth: YearMonth | null;
    sizeUnit: GQBuildingSizeUnit | null;
    buildingKind: string | null;
    buildingSubkind: string | null;
    leaseType: GQBuildingLeaseType | null;
    buildingName: string;
    buildingUniqueId: string;
    isWithoutNaturalGas: boolean | null;
    isWithoutRefrigerants: boolean | null;
    periods: Array<{
      __typename?: 'BuildingPeriodForDiff';
      kind: GQBuildingPeriodKind;
      sizeQuantity: number | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      partialCleanPowerPercent: number | null;
      cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
      powerUsageEffectiveness: number | null;
      isBuildingEmpty: boolean | null;
      customFieldValue: string | null;
      customFieldId: string | null;
      orgUnitTypeStableId: string | null;
      orgUnitStableId: string | null;
    }>;
  } | null;
};

export type GQBuildingsTaskFacilitiesQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  filter: InputMaybe<Array<GQFacilitiesFilterInput> | GQFacilitiesFilterInput>;
}>;

export type GQBuildingsTaskFacilitiesQuery = {
  __typename?: 'Query';
  facilities: {
    __typename?: 'FacilityConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
    edges: Array<{
      __typename?: 'FacilityEdge';
      node: {
        __typename?: 'Facility';
        id: string;
        buildingName: string;
        streetAddress: string | null;
        streetAddress2: string | null;
        city: string | null;
        state: string | null;
        country: string | null;
        sizeUnit: GQBuildingSizeUnit | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        editState: GQBuildingEditState;
        postalCode: string | null;
        buildingKind: string | null;
        buildingSubkind: string | null;
        leaseType: GQBuildingLeaseType | null;
        buildingUniqueId: string;
        isWithoutNaturalGas: boolean | null;
        isWithoutRefrigerants: boolean | null;
        periods: Array<{
          __typename?: 'BuildingPeriod';
          id: string;
          kind: GQBuildingPeriodKind;
          sizeQuantity: number | null;
          startYearMonth: YearMonth | null;
          endYearMonth: YearMonth | null;
          partialCleanPowerPercent: number | null;
          cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
          powerUsageEffectiveness: number | null;
          isBuildingEmpty: boolean | null;
          customFieldValue: string | null;
          customFieldId: string | null;
          orgUnitTypeStableId: string | null;
          orgUnitStableId: string | null;
        }>;
        latestApprovedVersion: {
          __typename?: 'BuildingForDiff';
          id: string | null;
          streetAddress: string | null;
          streetAddress2: string | null;
          postalCode: string | null;
          city: string | null;
          state: string | null;
          country: string | null;
          startYearMonth: YearMonth | null;
          endYearMonth: YearMonth | null;
          sizeUnit: GQBuildingSizeUnit | null;
          buildingKind: string | null;
          buildingSubkind: string | null;
          leaseType: GQBuildingLeaseType | null;
          buildingName: string;
          buildingUniqueId: string;
          isWithoutNaturalGas: boolean | null;
          isWithoutRefrigerants: boolean | null;
          periods: Array<{
            __typename?: 'BuildingPeriodForDiff';
            kind: GQBuildingPeriodKind;
            sizeQuantity: number | null;
            startYearMonth: YearMonth | null;
            endYearMonth: YearMonth | null;
            partialCleanPowerPercent: number | null;
            cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
            powerUsageEffectiveness: number | null;
            isBuildingEmpty: boolean | null;
            customFieldValue: string | null;
            customFieldId: string | null;
            orgUnitTypeStableId: string | null;
            orgUnitStableId: string | null;
          }>;
        } | null;
      } | null;
    } | null>;
  };
};

export type GQUserUploadTaskForDataIssueFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  issues: {
    __typename?: 'DataIssueConnection';
    edges: Array<{
      __typename?: 'DataIssueEdge';
      node: {
        __typename?: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        createdAt: Date;
        isCustomerInitiated: boolean;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          completedAt: Date | null;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              isPublished: boolean;
              createdAt: Date;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
  datasource: { __typename?: 'Datasource'; id: string; name: string };
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    active: boolean;
  };
};

export type GQValueMappingTaskForDataIssueFragment = {
  __typename?: 'ValueMappingTask';
  id: string;
  issues: {
    __typename?: 'DataIssueConnection';
    edges: Array<{
      __typename?: 'DataIssueEdge';
      node: {
        __typename?: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        createdAt: Date;
        isCustomerInitiated: boolean;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          completedAt: Date | null;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              isPublished: boolean;
              createdAt: Date;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    active: boolean;
  };
};

export type GQGetUserUploadTaskForDataIssueQueryVariables = Exact<{
  userUploadTaskId: Scalars['ID']['input'];
}>;

export type GQGetUserUploadTaskForDataIssueQuery = {
  __typename?: 'Query';
  userUploadTask: {
    __typename?: 'UserUploadTask';
    id: string;
    issues: {
      __typename?: 'DataIssueConnection';
      edges: Array<{
        __typename?: 'DataIssueEdge';
        node: {
          __typename?: 'DataIssue';
          id: string;
          title: string;
          description: string | null;
          state: GQDataIssueState;
          createdAt: Date;
          isCustomerInitiated: boolean;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            completedAt: Date | null;
          };
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                message: string;
                isPublished: boolean;
                createdAt: Date;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    } | null;
    datasource: { __typename?: 'Datasource'; id: string; name: string };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      active: boolean;
    };
  } | null;
};

export type GQGetValueMappingTaskForDataIssueQueryVariables = Exact<{
  valueMappingTaskId: Scalars['ID']['input'];
}>;

export type GQGetValueMappingTaskForDataIssueQuery = {
  __typename?: 'Query';
  valueMappingTask: {
    __typename?: 'ValueMappingTask';
    id: string;
    issues: {
      __typename?: 'DataIssueConnection';
      edges: Array<{
        __typename?: 'DataIssueEdge';
        node: {
          __typename?: 'DataIssue';
          id: string;
          title: string;
          description: string | null;
          state: GQDataIssueState;
          createdAt: Date;
          isCustomerInitiated: boolean;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            completedAt: Date | null;
          };
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                message: string;
                isPublished: boolean;
                createdAt: Date;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    } | null;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      active: boolean;
    };
  };
};

export type GQCompleteUserUploadTaskDashboardMutationVariables = Exact<{
  input: GQCompleteUserUploadTaskInput;
}>;

export type GQCompleteUserUploadTaskDashboardMutation = {
  __typename?: 'Mutation';
  completeUserUploadTask: {
    __typename?: 'CompleteUserUploadTaskPayload';
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      coverageInterval: YMInterval;
      coverageStartDate: Date;
      coverageEndDate: Date;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
      }>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        deadline: Date;
      } | null;
    };
  };
};

export type GQUncompleteUserUploadTaskDashboardMutationVariables = Exact<{
  input: GQUncompleteUserUploadTaskInput;
}>;

export type GQUncompleteUserUploadTaskDashboardMutation = {
  __typename?: 'Mutation';
  uncompleteUserUploadTask: {
    __typename?: 'CompleteUserUploadTaskPayload';
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      coverageInterval: YMInterval;
      coverageStartDate: Date;
      coverageEndDate: Date;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
      }>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        deadline: Date;
      } | null;
    };
  };
};

export type GQGetDatasourceSummaryFieldsFragment = {
  __typename?: 'Datasource';
  id: string;
  name: string;
  canonicalDatasource: {
    __typename?: 'CanonicalDatasource';
    id: string;
    name: string;
  } | null;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
    } | null;
  };
  userUploadTaskForMeasurementProject: {
    __typename?: 'UserUploadTask';
    id: string;
    userUploads: {
      __typename?: 'UserUploadConnection';
      edges: Array<{
        __typename?: 'UserUploadEdge';
        node: {
          __typename?: 'UserUpload';
          id: string;
          isBeingValueMapped: boolean;
          userVisibleAttempt: {
            __typename?: 'UserUploadAttempt';
            id: string;
          } | null;
        } | null;
      } | null>;
    } | null;
  };
  userUploadTasks: {
    __typename?: 'UserUploadTaskConnection';
    edges: Array<{
      __typename?: 'UserUploadTaskEdge';
      node: {
        __typename?: 'UserUploadTask';
        id: string;
        userUploads: {
          __typename?: 'UserUploadConnection';
          edges: Array<{
            __typename?: 'UserUploadEdge';
            node: {
              __typename?: 'UserUpload';
              id: string;
              createdAt: Date;
              revisionRoot: string | null;
              status: GQUserUploadStatus;
              processingMode: GQUserUploadProcessingMode | null;
              isBeingValueMapped: boolean;
              userVisibleAttempt: {
                __typename?: 'UserUploadAttempt';
                id: string;
                name: string;
                remoteWritten: boolean;
                userUploadedTables: Array<{
                  __typename?: 'UserUploadedTable';
                  id: string;
                  status: GQUserUploadedTableStatus;
                }>;
              } | null;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  };
  firstUnansweredMeasurementVerificationQuestion: {
    __typename?: 'MeasurementVerificationItemQuestion';
    id: string;
    verificationItemId: string;
  } | null;
};

export type GQGetDatasourceSummaryQueryVariables = Exact<{
  datasourceId: Scalars['ID']['input'];
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQGetDatasourceSummaryQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  datasource: {
    __typename?: 'Datasource';
    id: string;
    name: string;
    canonicalDatasource: {
      __typename?: 'CanonicalDatasource';
      id: string;
      name: string;
    } | null;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    };
    userUploadTaskForMeasurementProject: {
      __typename?: 'UserUploadTask';
      id: string;
      userUploads: {
        __typename?: 'UserUploadConnection';
        edges: Array<{
          __typename?: 'UserUploadEdge';
          node: {
            __typename?: 'UserUpload';
            id: string;
            isBeingValueMapped: boolean;
            userVisibleAttempt: {
              __typename?: 'UserUploadAttempt';
              id: string;
            } | null;
          } | null;
        } | null>;
      } | null;
    };
    userUploadTasks: {
      __typename?: 'UserUploadTaskConnection';
      edges: Array<{
        __typename?: 'UserUploadTaskEdge';
        node: {
          __typename?: 'UserUploadTask';
          id: string;
          userUploads: {
            __typename?: 'UserUploadConnection';
            edges: Array<{
              __typename?: 'UserUploadEdge';
              node: {
                __typename?: 'UserUpload';
                id: string;
                createdAt: Date;
                revisionRoot: string | null;
                status: GQUserUploadStatus;
                processingMode: GQUserUploadProcessingMode | null;
                isBeingValueMapped: boolean;
                userVisibleAttempt: {
                  __typename?: 'UserUploadAttempt';
                  id: string;
                  name: string;
                  remoteWritten: boolean;
                  userUploadedTables: Array<{
                    __typename?: 'UserUploadedTable';
                    id: string;
                    status: GQUserUploadedTableStatus;
                  }>;
                } | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
    firstUnansweredMeasurementVerificationQuestion: {
      __typename?: 'MeasurementVerificationItemQuestion';
      id: string;
      verificationItemId: string;
    } | null;
  } | null;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    coverageInterval: YMInterval;
  };
};

export type GQGetDatasourceSummaryDetailsQueryVariables = Exact<{
  datasourceId: Scalars['ID']['input'];
}>;

export type GQGetDatasourceSummaryDetailsQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  datasource: {
    __typename?: 'Datasource';
    id: string;
    summaryWithMetadata: {
      __typename?: 'DatasourceSummaryWithMetadata';
      id: string;
      legacyUploads: Array<{
        __typename?: 'UserUpload';
        id: string;
        name: string;
      }>;
      unsupportedUploads: Array<{
        __typename?: 'UserUpload';
        id: string;
        name: string;
      }>;
      summaries: Array<{
        __typename?: 'DatasourceSummary';
        id: string;
        signedUrl: string;
        title: string;
        bartActivityType: string | null;
      }>;
    };
  } | null;
};

export type GQUserUploadsByIdQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQUserUploadsByIdQuery = {
  __typename?: 'Query';
  userUploadsById: Array<{
    __typename?: 'UserUpload';
    id: string;
    userVisibleAttempt: {
      __typename?: 'UserUploadAttempt';
      id: string;
      name: string;
      createdAt: Date;
      size: number;
      description: string | null;
      remoteWritten: boolean;
      uploader: { __typename?: 'User'; id: string; name: string } | null;
      userUploadedTables: Array<{
        __typename?: 'UserUploadedTable';
        id: string;
        sheetName: string | null;
        sheetIndex: number | null;
        errorMessage: string | null;
        errorType: string | null;
        numRows: number | null;
        status: GQUserUploadedTableStatus;
        schema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        uirSchema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        parseConfig: {
          __typename?: 'UserUploadedTableParseConfig';
          noHeader: boolean;
          skipTopNRows: number;
          skipBottomNRows: number;
          meltIdVars: Array<string | null> | null;
        } | null;
        roboCleanerResult: {
          __typename?: 'UserUploadedTableRoboCleanerResult';
          skipTopNRows: number;
        } | null;
        userReviewMetadata: {
          __typename?: 'UserUploadedTableUserReviewMetadata';
          headerRowIndex: number;
        } | null;
        parentFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
        };
        dataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataUnshiftedPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
      }>;
      latestUutsIfRevisionExists: Array<{
        __typename?: 'UserUploadedTable';
        id: string;
        sheetName: string | null;
        sheetIndex: number | null;
        errorMessage: string | null;
        errorType: string | null;
        numRows: number | null;
        status: GQUserUploadedTableStatus;
        schema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        uirSchema: Array<{
          __typename?: 'UserUploadedTableSchemaColumn';
          index: number;
          kind: GQUserUploadedTableSchemaColumnKind;
          name: string;
          alias: string;
        }> | null;
        parseConfig: {
          __typename?: 'UserUploadedTableParseConfig';
          noHeader: boolean;
          skipTopNRows: number;
          skipBottomNRows: number;
          meltIdVars: Array<string | null> | null;
        } | null;
        roboCleanerResult: {
          __typename?: 'UserUploadedTableRoboCleanerResult';
          skipTopNRows: number;
        } | null;
        userReviewMetadata: {
          __typename?: 'UserUploadedTableUserReviewMetadata';
          headerRowIndex: number;
        } | null;
        parentFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
        };
        dataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataUnshiftedPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
      }> | null;
    } | null;
  }>;
};

export type GQUserReviewMetadataForIngestionFragment = {
  __typename?: 'UserUploadedTableUserReviewMetadata';
  headerRowIndex: number;
};

export type GQUutSchemaForIngestionFragment = {
  __typename?: 'UserUploadedTableSchemaColumn';
  index: number;
  kind: GQUserUploadedTableSchemaColumnKind;
  name: string;
  alias: string;
};

export type GQUserUploadedTableFieldsWithoutDataPreviewFragment = {
  __typename?: 'UserUploadedTable';
  id: string;
  sheetName: string | null;
  sheetIndex: number | null;
  errorMessage: string | null;
  errorType: string | null;
  numRows: number | null;
  status: GQUserUploadedTableStatus;
  schema: Array<{
    __typename?: 'UserUploadedTableSchemaColumn';
    index: number;
    kind: GQUserUploadedTableSchemaColumnKind;
    name: string;
    alias: string;
  }> | null;
  uirSchema: Array<{
    __typename?: 'UserUploadedTableSchemaColumn';
    index: number;
    kind: GQUserUploadedTableSchemaColumnKind;
    name: string;
    alias: string;
  }> | null;
  parseConfig: {
    __typename?: 'UserUploadedTableParseConfig';
    noHeader: boolean;
    skipTopNRows: number;
    skipBottomNRows: number;
    meltIdVars: Array<string | null> | null;
  } | null;
  roboCleanerResult: {
    __typename?: 'UserUploadedTableRoboCleanerResult';
    skipTopNRows: number;
  } | null;
  userReviewMetadata: {
    __typename?: 'UserUploadedTableUserReviewMetadata';
    headerRowIndex: number;
  } | null;
  parentFileMetadata: { __typename?: 'FileMetadata'; id: string; name: string };
};

export type GQUserUploadedTableWithOnlyDataPreviewFieldsFragment = {
  __typename?: 'UserUploadedTable';
  status: GQUserUploadedTableStatus;
  dataPreview: {
    __typename?: 'UserUploadedTableDataPreview';
    dataUntyped: any;
  } | null;
  rawDataPreview: {
    __typename?: 'UserUploadedTableDataPreview';
    dataUntyped: any;
  } | null;
  rawDataUnshiftedPreview: {
    __typename?: 'UserUploadedTableDataPreview';
    dataUntyped: any;
  } | null;
};

export type GQUserUploadedTableWithDataPreviewFragment = {
  __typename?: 'UserUploadedTable';
  id: string;
  sheetName: string | null;
  sheetIndex: number | null;
  errorMessage: string | null;
  errorType: string | null;
  numRows: number | null;
  status: GQUserUploadedTableStatus;
  schema: Array<{
    __typename?: 'UserUploadedTableSchemaColumn';
    index: number;
    kind: GQUserUploadedTableSchemaColumnKind;
    name: string;
    alias: string;
  }> | null;
  uirSchema: Array<{
    __typename?: 'UserUploadedTableSchemaColumn';
    index: number;
    kind: GQUserUploadedTableSchemaColumnKind;
    name: string;
    alias: string;
  }> | null;
  parseConfig: {
    __typename?: 'UserUploadedTableParseConfig';
    noHeader: boolean;
    skipTopNRows: number;
    skipBottomNRows: number;
    meltIdVars: Array<string | null> | null;
  } | null;
  roboCleanerResult: {
    __typename?: 'UserUploadedTableRoboCleanerResult';
    skipTopNRows: number;
  } | null;
  userReviewMetadata: {
    __typename?: 'UserUploadedTableUserReviewMetadata';
    headerRowIndex: number;
  } | null;
  parentFileMetadata: { __typename?: 'FileMetadata'; id: string; name: string };
  dataPreview: {
    __typename?: 'UserUploadedTableDataPreview';
    dataUntyped: any;
  } | null;
  rawDataPreview: {
    __typename?: 'UserUploadedTableDataPreview';
    dataUntyped: any;
  } | null;
  rawDataUnshiftedPreview: {
    __typename?: 'UserUploadedTableDataPreview';
    dataUntyped: any;
  } | null;
};

export type GQUserUploadAttemptFieldsWithDataPreviewFragment = {
  __typename?: 'UserUploadAttempt';
  id: string;
  name: string;
  createdAt: Date;
  size: number;
  description: string | null;
  remoteWritten: boolean;
  uploader: { __typename?: 'User'; id: string; name: string } | null;
  userUploadedTables: Array<{
    __typename?: 'UserUploadedTable';
    id: string;
    sheetName: string | null;
    sheetIndex: number | null;
    errorMessage: string | null;
    errorType: string | null;
    numRows: number | null;
    status: GQUserUploadedTableStatus;
    schema: Array<{
      __typename?: 'UserUploadedTableSchemaColumn';
      index: number;
      kind: GQUserUploadedTableSchemaColumnKind;
      name: string;
      alias: string;
    }> | null;
    uirSchema: Array<{
      __typename?: 'UserUploadedTableSchemaColumn';
      index: number;
      kind: GQUserUploadedTableSchemaColumnKind;
      name: string;
      alias: string;
    }> | null;
    parseConfig: {
      __typename?: 'UserUploadedTableParseConfig';
      noHeader: boolean;
      skipTopNRows: number;
      skipBottomNRows: number;
      meltIdVars: Array<string | null> | null;
    } | null;
    roboCleanerResult: {
      __typename?: 'UserUploadedTableRoboCleanerResult';
      skipTopNRows: number;
    } | null;
    userReviewMetadata: {
      __typename?: 'UserUploadedTableUserReviewMetadata';
      headerRowIndex: number;
    } | null;
    parentFileMetadata: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
    };
    dataPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
    rawDataPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
    rawDataUnshiftedPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
  }>;
  latestUutsIfRevisionExists: Array<{
    __typename?: 'UserUploadedTable';
    id: string;
    sheetName: string | null;
    sheetIndex: number | null;
    errorMessage: string | null;
    errorType: string | null;
    numRows: number | null;
    status: GQUserUploadedTableStatus;
    schema: Array<{
      __typename?: 'UserUploadedTableSchemaColumn';
      index: number;
      kind: GQUserUploadedTableSchemaColumnKind;
      name: string;
      alias: string;
    }> | null;
    uirSchema: Array<{
      __typename?: 'UserUploadedTableSchemaColumn';
      index: number;
      kind: GQUserUploadedTableSchemaColumnKind;
      name: string;
      alias: string;
    }> | null;
    parseConfig: {
      __typename?: 'UserUploadedTableParseConfig';
      noHeader: boolean;
      skipTopNRows: number;
      skipBottomNRows: number;
      meltIdVars: Array<string | null> | null;
    } | null;
    roboCleanerResult: {
      __typename?: 'UserUploadedTableRoboCleanerResult';
      skipTopNRows: number;
    } | null;
    userReviewMetadata: {
      __typename?: 'UserUploadedTableUserReviewMetadata';
      headerRowIndex: number;
    } | null;
    parentFileMetadata: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
    };
    dataPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
    rawDataPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
    rawDataUnshiftedPreview: {
      __typename?: 'UserUploadedTableDataPreview';
      dataUntyped: any;
    } | null;
  }> | null;
};

export type GQUtilitiesTaskFacilitiesQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  filter: InputMaybe<Array<GQFacilitiesFilterInput> | GQFacilitiesFilterInput>;
  userUploadTaskId: Scalars['ID']['input'];
}>;

export type GQUtilitiesTaskFacilitiesQuery = {
  __typename?: 'Query';
  facilities: {
    __typename?: 'FacilityConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
    edges: Array<{
      __typename?: 'FacilityEdge';
      node: {
        __typename?: 'Facility';
        id: string;
        buildingName: string;
        buildingWithUtilities: {
          __typename?: 'BuildingWithUtilities';
          id: string;
          completionIntervals: {
            __typename?: 'BuildingCompletionIntervals';
            id: string;
            data: Array<{
              __typename?: 'BuildingCompletionIntervalsData';
              utilityType: GQBuildingUtilityType;
              fuelKind: GQStationaryCombustionFuelKind | null;
              periodsWithActuals: Array<{
                __typename?: 'DateTimeInterval';
                start: Date;
                end: Date;
              }>;
              periodsWithSupportingDocs: Array<{
                __typename?: 'DateTimeInterval';
                start: Date;
                end: Date;
              }>;
            }>;
          };
        };
      } | null;
    } | null>;
  };
};

export type GQApprovalTargetFieldsForChangingStatusFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  approvalStatus: GQApprovalStatus;
  lockState: GQApprovalTargetLockState;
  approvers: Array<{
    __typename?: 'UserWithApprovalStatus';
    approvalStatus: GQApprovalStatus | null;
    user: { __typename?: 'User'; id: string };
  }>;
};

export type GQApproveDataMutationVariables = Exact<{
  input: GQApproveDataInput;
}>;

export type GQApproveDataMutation = {
  __typename?: 'Mutation';
  approveData: {
    __typename?: 'ApproveDataPayload';
    target: {
      __typename?: 'UserUploadTask';
      id: string;
      approvalStatus: GQApprovalStatus;
      lockState: GQApprovalTargetLockState;
      approvers: Array<{
        __typename?: 'UserWithApprovalStatus';
        approvalStatus: GQApprovalStatus | null;
        user: { __typename?: 'User'; id: string };
      }>;
    };
    newChangelogEvents: Array<{
      __typename?: 'DataApprovalChangelogEvent';
      id: string;
      activityType: GQActivityTimelineItemType;
      eventTime: Date;
      moreDetails: any;
      actorUser: {
        __typename?: 'User';
        id: string;
        displayName: string;
      } | null;
    }>;
  };
};

export type GQRejectDataMutationVariables = Exact<{
  input: GQRejectDataInput;
}>;

export type GQRejectDataMutation = {
  __typename?: 'Mutation';
  rejectData: {
    __typename?: 'RejectDataPayload';
    target: {
      __typename?: 'UserUploadTask';
      id: string;
      approvalStatus: GQApprovalStatus;
      lockState: GQApprovalTargetLockState;
      approvers: Array<{
        __typename?: 'UserWithApprovalStatus';
        approvalStatus: GQApprovalStatus | null;
        user: { __typename?: 'User'; id: string };
      }>;
    };
    newChangelogEvents: Array<{
      __typename?: 'DataApprovalChangelogEvent';
      id: string;
      activityType: GQActivityTimelineItemType;
      eventTime: Date;
      moreDetails: any;
      actorUser: {
        __typename?: 'User';
        id: string;
        displayName: string;
      } | null;
    }>;
  };
};

export type GQRequestDataApprovalMutationVariables = Exact<{
  input: GQRequestDataApprovalInput;
}>;

export type GQRequestDataApprovalMutation = {
  __typename?: 'Mutation';
  requestDataApproval: {
    __typename?: 'RequestDataApprovalPayload';
    target: {
      __typename?: 'UserUploadTask';
      id: string;
      approvalStatus: GQApprovalStatus;
      lockState: GQApprovalTargetLockState;
      approvers: Array<{
        __typename?: 'UserWithApprovalStatus';
        approvalStatus: GQApprovalStatus | null;
        user: { __typename?: 'User'; id: string };
      }>;
    };
    newChangelogEvents: Array<{
      __typename?: 'DataApprovalChangelogEvent';
      id: string;
      activityType: GQActivityTimelineItemType;
      eventTime: Date;
      moreDetails: any;
      actorUser: {
        __typename?: 'User';
        id: string;
        displayName: string;
      } | null;
    }>;
  };
};

export type GQChangelogEventPartsFragment = {
  __typename?: 'DataApprovalChangelogEvent';
  id: string;
  activityType: GQActivityTimelineItemType;
  eventTime: Date;
  moreDetails: any;
  actorUser: { __typename?: 'User'; id: string; displayName: string } | null;
};

export type GQDataApprovalUserUploadTaskChangelogQueryVariables = Exact<{
  input: GQDataApprovalUserUploadTaskChangelogInput;
}>;

export type GQDataApprovalUserUploadTaskChangelogQuery = {
  __typename?: 'Query';
  dataApprovalUserUploadTaskChangelog: {
    __typename?: 'DataApprovalUserUploadTaskChangelogPayload';
    changelogEvents: Array<{
      __typename?: 'DataApprovalChangelogEvent';
      id: string;
      activityType: GQActivityTimelineItemType;
      eventTime: Date;
      moreDetails: any;
      actorUser: {
        __typename?: 'User';
        id: string;
        displayName: string;
      } | null;
    }>;
  };
};

export type GQDataApprovalObjectChangelogQueryVariables = Exact<{
  input: GQDataApprovalObjectChangelogInput;
}>;

export type GQDataApprovalObjectChangelogQuery = {
  __typename?: 'Query';
  dataApprovalObjectChangelog: {
    __typename?: 'DataApprovalObjectChangelogPayload';
    changelogEvents: Array<{
      __typename?: 'DataApprovalChangelogEvent';
      id: string;
      activityType: GQActivityTimelineItemType;
      eventTime: Date;
      moreDetails: any;
      actorUser: {
        __typename?: 'User';
        id: string;
        displayName: string;
      } | null;
    }>;
  };
};

export type GQAddDataApprovalCommentMutationVariables = Exact<{
  input: GQAddDataApprovalCommentInput;
}>;

export type GQAddDataApprovalCommentMutation = {
  __typename?: 'Mutation';
  addDataApprovalComment: {
    __typename?: 'AddDataApprovalCommentPayload';
    dataIssue: { __typename?: 'DataIssue'; id: string };
    newChangelogEvents: Array<{
      __typename?: 'DataApprovalChangelogEvent';
      id: string;
      activityType: GQActivityTimelineItemType;
      eventTime: Date;
      moreDetails: any;
      actorUser: {
        __typename?: 'User';
        id: string;
        displayName: string;
      } | null;
    }>;
  };
};

export type GQUnlockDataMutationVariables = Exact<{
  input: GQUnlockDataInput;
}>;

export type GQUnlockDataMutation = {
  __typename?: 'Mutation';
  unlockData: {
    __typename?: 'UnlockDataPayload';
    target: {
      __typename?: 'UserUploadTask';
      id: string;
      approvalStatus: GQApprovalStatus;
      lockState: GQApprovalTargetLockState;
      approvers: Array<{
        __typename?: 'UserWithApprovalStatus';
        approvalStatus: GQApprovalStatus | null;
        user: { __typename?: 'User'; id: string };
      }>;
    };
    newChangelogEvents: Array<{
      __typename?: 'DataApprovalChangelogEvent';
      id: string;
      activityType: GQActivityTimelineItemType;
      eventTime: Date;
      moreDetails: any;
      actorUser: {
        __typename?: 'User';
        id: string;
        displayName: string;
      } | null;
    }>;
  };
};

export type GQRequestUnlockDataMutationVariables = Exact<{
  input: GQRequestUnlockDataInput;
}>;

export type GQRequestUnlockDataMutation = {
  __typename?: 'Mutation';
  requestUnlockData: {
    __typename?: 'RequestUnlockDataPayload';
    target: {
      __typename?: 'UserUploadTask';
      id: string;
      approvalStatus: GQApprovalStatus;
      lockState: GQApprovalTargetLockState;
      approvers: Array<{
        __typename?: 'UserWithApprovalStatus';
        approvalStatus: GQApprovalStatus | null;
        user: { __typename?: 'User'; id: string };
      }>;
    };
    newChangelogEvents: Array<{
      __typename?: 'DataApprovalChangelogEvent';
      id: string;
      activityType: GQActivityTimelineItemType;
      eventTime: Date;
      moreDetails: any;
      actorUser: {
        __typename?: 'User';
        id: string;
        displayName: string;
      } | null;
    }>;
  };
};

export type GQUserUploadTaskFieldsForBuildingMutationResponseFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  state: GQUserUploadTaskState;
  importState: GQDatasourceImportState;
  buildingIds: Array<string>;
};

export type GQGetAllBuildingNamesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetAllBuildingNamesQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    buildingNames: Array<string>;
  };
};

export type GQOrganizationWithIngestionCustomFieldsFragment = {
  __typename?: 'Organization';
  id: string;
  ingestionCustomFields: Array<{
    __typename?: 'IngestionCustomField';
    id: string;
    name: string;
    description: string | null;
    type: GQIngestionCustomFieldType;
    possibleValues: Array<string> | null;
    appliesToAllDatasets: boolean;
    createdAt: Date;
    exampleValues: Array<string> | null;
    specificUtilityTypes: Array<GQBuildingUtilityType>;
    specificDatasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    }>;
    ingestionCustomFieldDatasources: Array<{
      __typename?: 'IngestionCustomFieldDatasource';
      id: string;
    }> | null;
  }>;
};

export type GQCreateBuildingMutationVariables = Exact<{
  input: GQCreateBuildingInput;
}>;

export type GQCreateBuildingMutation = {
  __typename?: 'Mutation';
  createBuilding: {
    __typename?: 'CreateBuildingPayload';
    updatedUserUploadTasks: Array<{
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
      importState: GQDatasourceImportState;
      buildingIds: Array<string>;
    }>;
    organization: {
      __typename?: 'Organization';
      id: string;
      ingestionCustomFields: Array<{
        __typename?: 'IngestionCustomField';
        id: string;
        name: string;
        description: string | null;
        type: GQIngestionCustomFieldType;
        possibleValues: Array<string> | null;
        appliesToAllDatasets: boolean;
        createdAt: Date;
        exampleValues: Array<string> | null;
        specificUtilityTypes: Array<GQBuildingUtilityType>;
        specificDatasets: Array<{
          __typename?: 'Dataset';
          id: string;
          name: string;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        }>;
        ingestionCustomFieldDatasources: Array<{
          __typename?: 'IngestionCustomFieldDatasource';
          id: string;
        }> | null;
      }>;
      buildingsUntyped: Array<{
        __typename?: 'BuildingUntyped';
        id: string;
        data: any;
      }>;
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      setupAt: Date | null;
      completedAt: Date | null;
      footprintStatus: GQMeasurementProjectFootprintStatus;
      coverageInterval: YMInterval;
      deadline: Date | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            buildingIds: Array<string>;
            lockState: GQApprovalTargetLockState;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  title: string;
                  description: string | null;
                  state: GQDataIssueState;
                  createdAt: Date;
                  isCustomerInitiated: boolean;
                  measurementProject: {
                    __typename?: 'MeasurementProject';
                    id: string;
                    completedAt: Date | null;
                  };
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        message: string;
                        isPublished: boolean;
                        createdAt: Date;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            } | null;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              isApi: boolean | null;
              userCanAccessDetails: boolean | null;
              userCanApproveDatasource: boolean | null;
              userCanManageDatasource: boolean | null;
              isUserConfirmed: boolean | null;
              hasIntegration: boolean;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              active: boolean;
            };
          } | null;
        } | null>;
      };
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        lockState: GQApprovalTargetLockState | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          didLatestEmailBounce: boolean;
        }>;
        approvers: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
        areUploadsComplete: boolean;
        isUsableInFootprint: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          } | null;
        }>;
      };
      climateProgramProjects: Array<{
        __typename: 'ClimateProgramProject';
        id: string;
        name: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          measurementObjectCopy: string | null;
          kind: GQClimateProgramProjectKind;
          routeType: string | null;
          name: string;
        } | null;
      }>;
    } | null;
  } | null;
};

export type GQUpdateBuildingMutationVariables = Exact<{
  input: GQUpdateBuildingInput;
}>;

export type GQUpdateBuildingMutation = {
  __typename?: 'Mutation';
  updateBuilding: {
    __typename?: 'UpdateBuildingPayload';
    buildingUntyped: { __typename?: 'BuildingUntyped'; id: string; data: any };
    facility: {
      __typename?: 'Facility';
      id: string;
      buildingName: string;
      streetAddress: string | null;
      streetAddress2: string | null;
      city: string | null;
      state: string | null;
      country: string | null;
      sizeUnit: GQBuildingSizeUnit | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      editState: GQBuildingEditState;
      postalCode: string | null;
      buildingKind: string | null;
      buildingSubkind: string | null;
      leaseType: GQBuildingLeaseType | null;
      buildingUniqueId: string;
      isWithoutNaturalGas: boolean | null;
      isWithoutRefrigerants: boolean | null;
      periods: Array<{
        __typename?: 'BuildingPeriod';
        id: string;
        kind: GQBuildingPeriodKind;
        sizeQuantity: number | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        partialCleanPowerPercent: number | null;
        cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
        powerUsageEffectiveness: number | null;
        isBuildingEmpty: boolean | null;
        customFieldValue: string | null;
        customFieldId: string | null;
        orgUnitTypeStableId: string | null;
        orgUnitStableId: string | null;
      }>;
      latestApprovedVersion: {
        __typename?: 'BuildingForDiff';
        id: string | null;
        streetAddress: string | null;
        streetAddress2: string | null;
        postalCode: string | null;
        city: string | null;
        state: string | null;
        country: string | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        sizeUnit: GQBuildingSizeUnit | null;
        buildingKind: string | null;
        buildingSubkind: string | null;
        leaseType: GQBuildingLeaseType | null;
        buildingName: string;
        buildingUniqueId: string;
        isWithoutNaturalGas: boolean | null;
        isWithoutRefrigerants: boolean | null;
        periods: Array<{
          __typename?: 'BuildingPeriodForDiff';
          kind: GQBuildingPeriodKind;
          sizeQuantity: number | null;
          startYearMonth: YearMonth | null;
          endYearMonth: YearMonth | null;
          partialCleanPowerPercent: number | null;
          cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
          powerUsageEffectiveness: number | null;
          isBuildingEmpty: boolean | null;
          customFieldValue: string | null;
          customFieldId: string | null;
          orgUnitTypeStableId: string | null;
          orgUnitStableId: string | null;
        }>;
      } | null;
    };
    updatedUserUploadTasks: Array<{
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
      importState: GQDatasourceImportState;
      buildingIds: Array<string>;
    }>;
    organization: {
      __typename?: 'Organization';
      id: string;
      ingestionCustomFields: Array<{
        __typename?: 'IngestionCustomField';
        id: string;
        name: string;
        description: string | null;
        type: GQIngestionCustomFieldType;
        possibleValues: Array<string> | null;
        appliesToAllDatasets: boolean;
        createdAt: Date;
        exampleValues: Array<string> | null;
        specificUtilityTypes: Array<GQBuildingUtilityType>;
        specificDatasets: Array<{
          __typename?: 'Dataset';
          id: string;
          name: string;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        }>;
        ingestionCustomFieldDatasources: Array<{
          __typename?: 'IngestionCustomFieldDatasource';
          id: string;
        }> | null;
      }>;
    };
  } | null;
};

export type GQCompletionIntervalFieldsFragment = {
  __typename?: 'BuildingCompletionIntervals';
  id: string;
  data: Array<{
    __typename?: 'BuildingCompletionIntervalsData';
    utilityType: GQBuildingUtilityType;
    fuelKind: GQStationaryCombustionFuelKind | null;
    hasOnsiteCleanElectricity: boolean | null;
    periodsWithActuals: Array<{
      __typename?: 'DateTimeInterval';
      start: Date;
      end: Date;
    }>;
  }>;
};

export type GQBuildingUtilityConnectionFragoFragment = {
  __typename?: 'BuildingUtilityConnection';
  edges: Array<{
    __typename?: 'BuildingUtilityEdge';
    node: {
      __typename?: 'BuildingUtility';
      id: string;
      periodStart: Date;
      periodEnd: Date;
      quantity: number;
      unit: GQBuildingUtilityUnit;
      utilityType: GQBuildingUtilityType;
      fuelKind: GQStationaryCombustionFuelKind | null;
      fuelCombustionReason: string | null;
      notes: string | null;
      provider: string;
      wasteTreatment: GQBuildingWasteTreatment | null;
      wasteMaterial: GQBuildingWasteMaterial | null;
      isWasteHazardous: boolean | null;
      isWasteRadioactive: boolean | null;
      waterUseKind: GQBuildingWaterUseKind | null;
      refrigerantName: string | null;
      refrigerantLeakageSource: GQRefrigerantLeakageSource | null;
      cleanPowerPercent: number | null;
      cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
      onsiteCleanFuelKind: string | null;
      supportingDocuments: Array<{
        __typename?: 'SupportingDocument';
        id: string;
        createdAt: Date;
        utilityType: GQBuildingUtilityType | null;
        buildingUtilityId: string | null;
        file: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          remoteWritten: boolean;
        };
      }>;
      customFieldValues: Array<{
        __typename?: 'BuildingUtilityCustomField';
        id: string;
        ingestionCustomFieldId: string;
        customFieldValue: string | null;
      }>;
    } | null;
  } | null>;
  pageInfo: {
    __typename?: 'PageInfo';
    startCursor: string | null;
    endCursor: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    totalRowCount: number | null;
  };
};

export type GQBuildingUtilityFieldsForUtilitiesGridPageFragment = {
  __typename?: 'BuildingUtility';
  id: string;
  periodStart: Date;
  periodEnd: Date;
  quantity: number;
  unit: GQBuildingUtilityUnit;
  utilityType: GQBuildingUtilityType;
  fuelKind: GQStationaryCombustionFuelKind | null;
  fuelCombustionReason: string | null;
  notes: string | null;
  provider: string;
  wasteTreatment: GQBuildingWasteTreatment | null;
  wasteMaterial: GQBuildingWasteMaterial | null;
  isWasteHazardous: boolean | null;
  isWasteRadioactive: boolean | null;
  waterUseKind: GQBuildingWaterUseKind | null;
  refrigerantName: string | null;
  refrigerantLeakageSource: GQRefrigerantLeakageSource | null;
  cleanPowerPercent: number | null;
  cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
  onsiteCleanFuelKind: string | null;
  supportingDocuments: Array<{
    __typename?: 'SupportingDocument';
    id: string;
    createdAt: Date;
    utilityType: GQBuildingUtilityType | null;
    buildingUtilityId: string | null;
    file: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
      remoteWritten: boolean;
    };
  }>;
  customFieldValues: Array<{
    __typename?: 'BuildingUtilityCustomField';
    id: string;
    ingestionCustomFieldId: string;
    customFieldValue: string | null;
  }>;
};

export type GQCustomFieldForBuildingUtilitiesDialogFragment = {
  __typename?: 'IngestionCustomField';
  id: string;
  name: string;
  description: string | null;
  type: GQIngestionCustomFieldType;
  possibleValues: Array<string> | null;
  specificUtilityTypes: Array<GQBuildingUtilityType>;
};

export type GQUserUploadTaskForUtilitiesMutationResponseFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  state: GQUserUploadTaskState;
};

export type GQUpdateBuildingUtilityMutationVariables = Exact<{
  input: GQUpdateBuildingUtilityInput;
}>;

export type GQUpdateBuildingUtilityMutation = {
  __typename?: 'Mutation';
  updateBuildingUtility: {
    __typename?: 'UpdateBuildingUtilityPayload';
    buildingUtility: {
      __typename?: 'BuildingUtility';
      id: string;
      periodStart: Date;
      periodEnd: Date;
      quantity: number;
      unit: GQBuildingUtilityUnit;
      utilityType: GQBuildingUtilityType;
      fuelKind: GQStationaryCombustionFuelKind | null;
      fuelCombustionReason: string | null;
      notes: string | null;
      provider: string;
      wasteTreatment: GQBuildingWasteTreatment | null;
      wasteMaterial: GQBuildingWasteMaterial | null;
      isWasteHazardous: boolean | null;
      isWasteRadioactive: boolean | null;
      waterUseKind: GQBuildingWaterUseKind | null;
      refrigerantName: string | null;
      refrigerantLeakageSource: GQRefrigerantLeakageSource | null;
      cleanPowerPercent: number | null;
      cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
      onsiteCleanFuelKind: string | null;
      supportingDocuments: Array<{
        __typename?: 'SupportingDocument';
        id: string;
        createdAt: Date;
        utilityType: GQBuildingUtilityType | null;
        buildingUtilityId: string | null;
        file: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          remoteWritten: boolean;
        };
      }>;
      customFieldValues: Array<{
        __typename?: 'BuildingUtilityCustomField';
        id: string;
        ingestionCustomFieldId: string;
        customFieldValue: string | null;
      }>;
    };
    buildingWithUtilities: {
      __typename?: 'BuildingWithUtilities';
      id: string;
      completionIntervals: {
        __typename?: 'BuildingCompletionIntervals';
        id: string;
        data: Array<{
          __typename?: 'BuildingCompletionIntervalsData';
          utilityType: GQBuildingUtilityType;
          fuelKind: GQStationaryCombustionFuelKind | null;
          hasOnsiteCleanElectricity: boolean | null;
          periodsWithActuals: Array<{
            __typename?: 'DateTimeInterval';
            start: Date;
            end: Date;
          }>;
        }>;
      };
    };
    updatedUserUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
    } | null;
  };
};

export type GQCreateBuildingUtilityMutationVariables = Exact<{
  input: GQCreateBuildingUtilityInput;
  utilityType: InputMaybe<GQBuildingUtilityType>;
  sortModel: InputMaybe<
    Array<GQDataGridSortItemInput> | GQDataGridSortItemInput
  >;
  before: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQCreateBuildingUtilityMutation = {
  __typename?: 'Mutation';
  createBuildingUtility: {
    __typename?: 'CreateBuildingUtilityPayload';
    buildingUtility: { __typename?: 'BuildingUtility'; id: string };
    buildingWithUtilities: {
      __typename?: 'BuildingWithUtilities';
      id: string;
      utilitiesPaginated: {
        __typename?: 'BuildingUtilityConnection';
        edges: Array<{
          __typename?: 'BuildingUtilityEdge';
          node: {
            __typename?: 'BuildingUtility';
            id: string;
            periodStart: Date;
            periodEnd: Date;
            quantity: number;
            unit: GQBuildingUtilityUnit;
            utilityType: GQBuildingUtilityType;
            fuelKind: GQStationaryCombustionFuelKind | null;
            fuelCombustionReason: string | null;
            notes: string | null;
            provider: string;
            wasteTreatment: GQBuildingWasteTreatment | null;
            wasteMaterial: GQBuildingWasteMaterial | null;
            isWasteHazardous: boolean | null;
            isWasteRadioactive: boolean | null;
            waterUseKind: GQBuildingWaterUseKind | null;
            refrigerantName: string | null;
            refrigerantLeakageSource: GQRefrigerantLeakageSource | null;
            cleanPowerPercent: number | null;
            cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
            onsiteCleanFuelKind: string | null;
            supportingDocuments: Array<{
              __typename?: 'SupportingDocument';
              id: string;
              createdAt: Date;
              utilityType: GQBuildingUtilityType | null;
              buildingUtilityId: string | null;
              file: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
                remoteWritten: boolean;
              };
            }>;
            customFieldValues: Array<{
              __typename?: 'BuildingUtilityCustomField';
              id: string;
              ingestionCustomFieldId: string;
              customFieldValue: string | null;
            }>;
          } | null;
        } | null>;
        pageInfo: {
          __typename?: 'PageInfo';
          startCursor: string | null;
          endCursor: string | null;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
          totalRowCount: number | null;
        };
      };
      completionIntervals: {
        __typename?: 'BuildingCompletionIntervals';
        id: string;
        data: Array<{
          __typename?: 'BuildingCompletionIntervalsData';
          utilityType: GQBuildingUtilityType;
          fuelKind: GQStationaryCombustionFuelKind | null;
          hasOnsiteCleanElectricity: boolean | null;
          periodsWithActuals: Array<{
            __typename?: 'DateTimeInterval';
            start: Date;
            end: Date;
          }>;
        }>;
      };
    };
    buildingUntyped: { __typename?: 'BuildingUntyped'; id: string; data: any };
    updatedUserUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
    } | null;
  };
};

export type GQDeleteBuildingUtilityMutationVariables = Exact<{
  input: GQDeleteBuildingUtilityInput;
  utilityType: InputMaybe<GQBuildingUtilityType>;
  sortModel: InputMaybe<
    Array<GQDataGridSortItemInput> | GQDataGridSortItemInput
  >;
  before: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQDeleteBuildingUtilityMutation = {
  __typename?: 'Mutation';
  deleteBuildingUtility: {
    __typename?: 'DeleteBuildingUtilityPayload';
    buildingWithUtilities: {
      __typename?: 'BuildingWithUtilities';
      id: string;
      utilitiesPaginated: {
        __typename?: 'BuildingUtilityConnection';
        edges: Array<{
          __typename?: 'BuildingUtilityEdge';
          node: {
            __typename?: 'BuildingUtility';
            id: string;
            periodStart: Date;
            periodEnd: Date;
            quantity: number;
            unit: GQBuildingUtilityUnit;
            utilityType: GQBuildingUtilityType;
            fuelKind: GQStationaryCombustionFuelKind | null;
            fuelCombustionReason: string | null;
            notes: string | null;
            provider: string;
            wasteTreatment: GQBuildingWasteTreatment | null;
            wasteMaterial: GQBuildingWasteMaterial | null;
            isWasteHazardous: boolean | null;
            isWasteRadioactive: boolean | null;
            waterUseKind: GQBuildingWaterUseKind | null;
            refrigerantName: string | null;
            refrigerantLeakageSource: GQRefrigerantLeakageSource | null;
            cleanPowerPercent: number | null;
            cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
            onsiteCleanFuelKind: string | null;
            supportingDocuments: Array<{
              __typename?: 'SupportingDocument';
              id: string;
              createdAt: Date;
              utilityType: GQBuildingUtilityType | null;
              buildingUtilityId: string | null;
              file: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
                remoteWritten: boolean;
              };
            }>;
            customFieldValues: Array<{
              __typename?: 'BuildingUtilityCustomField';
              id: string;
              ingestionCustomFieldId: string;
              customFieldValue: string | null;
            }>;
          } | null;
        } | null>;
        pageInfo: {
          __typename?: 'PageInfo';
          startCursor: string | null;
          endCursor: string | null;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
          totalRowCount: number | null;
        };
      };
      completionIntervals: {
        __typename?: 'BuildingCompletionIntervals';
        id: string;
        data: Array<{
          __typename?: 'BuildingCompletionIntervalsData';
          utilityType: GQBuildingUtilityType;
          fuelKind: GQStationaryCombustionFuelKind | null;
          hasOnsiteCleanElectricity: boolean | null;
          periodsWithActuals: Array<{
            __typename?: 'DateTimeInterval';
            start: Date;
            end: Date;
          }>;
        }>;
      };
    };
    buildingUntyped: { __typename?: 'BuildingUntyped'; id: string; data: any };
    updatedUserUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
    } | null;
  };
};

export type GQBuildingUtilitiesDialogQueryVariables = Exact<{
  buildingId: Scalars['ID']['input'];
}>;

export type GQBuildingUtilitiesDialogQuery = {
  __typename?: 'Query';
  buildingUntyped: { __typename?: 'BuildingUntyped'; id: string; data: any };
  buildingWithUtilities: {
    __typename?: 'BuildingWithUtilities';
    id: string;
    completionIntervals: {
      __typename?: 'BuildingCompletionIntervals';
      id: string;
      data: Array<{
        __typename?: 'BuildingCompletionIntervalsData';
        utilityType: GQBuildingUtilityType;
        fuelKind: GQStationaryCombustionFuelKind | null;
        hasOnsiteCleanElectricity: boolean | null;
        periodsWithActuals: Array<{
          __typename?: 'DateTimeInterval';
          start: Date;
          end: Date;
        }>;
      }>;
    };
  };
};

export type GQPaginatedUtilityRowsQueryVariables = Exact<{
  buildingId: Scalars['ID']['input'];
  utilityType: InputMaybe<GQBuildingUtilityType>;
  sortModel: InputMaybe<
    Array<GQDataGridSortItemInput> | GQDataGridSortItemInput
  >;
  before: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQPaginatedUtilityRowsQuery = {
  __typename?: 'Query';
  buildingWithUtilities: {
    __typename?: 'BuildingWithUtilities';
    id: string;
    utilitiesPaginated: {
      __typename?: 'BuildingUtilityConnection';
      edges: Array<{
        __typename?: 'BuildingUtilityEdge';
        node: {
          __typename?: 'BuildingUtility';
          id: string;
          periodStart: Date;
          periodEnd: Date;
          quantity: number;
          unit: GQBuildingUtilityUnit;
          utilityType: GQBuildingUtilityType;
          fuelKind: GQStationaryCombustionFuelKind | null;
          fuelCombustionReason: string | null;
          notes: string | null;
          provider: string;
          wasteTreatment: GQBuildingWasteTreatment | null;
          wasteMaterial: GQBuildingWasteMaterial | null;
          isWasteHazardous: boolean | null;
          isWasteRadioactive: boolean | null;
          waterUseKind: GQBuildingWaterUseKind | null;
          refrigerantName: string | null;
          refrigerantLeakageSource: GQRefrigerantLeakageSource | null;
          cleanPowerPercent: number | null;
          cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
          onsiteCleanFuelKind: string | null;
          supportingDocuments: Array<{
            __typename?: 'SupportingDocument';
            id: string;
            createdAt: Date;
            utilityType: GQBuildingUtilityType | null;
            buildingUtilityId: string | null;
            file: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              remoteWritten: boolean;
            };
          }>;
          customFieldValues: Array<{
            __typename?: 'BuildingUtilityCustomField';
            id: string;
            ingestionCustomFieldId: string;
            customFieldValue: string | null;
          }>;
        } | null;
      } | null>;
      pageInfo: {
        __typename?: 'PageInfo';
        startCursor: string | null;
        endCursor: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        totalRowCount: number | null;
      };
    };
  };
};

export type GQSubmitBuildingsMutationVariables = Exact<{
  input: GQSubmitFacilitiesInput;
}>;

export type GQSubmitBuildingsMutation = {
  __typename?: 'Mutation';
  submitBuildings: {
    __typename?: 'SubmitFacilitiesPayload';
    measurementTask:
      | {
          __typename?: 'MeasurementParentTask';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          lockState: GQApprovalTargetLockState | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          subTasks: Array<
            | {
                __typename?: 'MeasurementParentTask';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
            | {
                __typename?: 'MeasurementTaskFlat';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
          >;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          approvers: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
      | { __typename?: 'MeasurementTaskFlat' };
    submittedUserUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
      importState: GQDatasourceImportState;
      buildingIds: Array<string>;
    };
  } | null;
};

export type GQSubmitUtilitiesMutationVariables = Exact<{
  input: GQSubmitFacilitiesInput;
}>;

export type GQSubmitUtilitiesMutation = {
  __typename?: 'Mutation';
  submitUtilities: {
    __typename?: 'SubmitFacilitiesPayload';
    measurementTask:
      | {
          __typename?: 'MeasurementParentTask';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          lockState: GQApprovalTargetLockState | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          subTasks: Array<
            | {
                __typename?: 'MeasurementParentTask';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
            | {
                __typename?: 'MeasurementTaskFlat';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
          >;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          approvers: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
      | { __typename?: 'MeasurementTaskFlat' };
    submittedUserUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
      importState: GQDatasourceImportState;
      buildingIds: Array<string>;
    };
  };
};

export type GQBuildingFormSupportingDocumentFragment = {
  __typename?: 'SupportingDocument';
  id: string;
  createdAt: Date;
  utilityType: GQBuildingUtilityType | null;
  buildingUtilityId: string | null;
  file: {
    __typename?: 'FileMetadata';
    id: string;
    name: string;
    remoteWritten: boolean;
  };
};

export type GQGetBuildingSupportingDocumentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetBuildingSupportingDocumentsQuery = {
  __typename?: 'Query';
  buildingUntyped: { __typename?: 'BuildingUntyped'; id: string; data: any };
};

export type GQCreateBuildingSupportingDocumentsMutationVariables = Exact<{
  input: GQCreateSupportingDocumentsInput;
}>;

export type GQCreateBuildingSupportingDocumentsMutation = {
  __typename?: 'Mutation';
  createSupportingDocuments: {
    __typename?: 'CreateSupportingDocumentsPayload';
    supportingDocuments: Array<{
      __typename?: 'SupportingDocument';
      id: string;
      createdAt: Date;
      utilityType: GQBuildingUtilityType | null;
      buildingUtilityId: string | null;
      file: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        remoteWritten: boolean;
      };
    }>;
    buildingUntyped: {
      __typename?: 'BuildingUntyped';
      id: string;
      data: any;
    } | null;
    files: Array<{
      __typename?: 'CreateUserUploadItemPayload';
      url: string;
      fileId: string;
      filename: string;
    }>;
    buildingUtility: {
      __typename?: 'BuildingUtility';
      id: string;
      supportingDocuments: Array<{
        __typename?: 'SupportingDocument';
        id: string;
        createdAt: Date;
        utilityType: GQBuildingUtilityType | null;
        buildingUtilityId: string | null;
        file: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          remoteWritten: boolean;
        };
      }>;
    } | null;
  } | null;
};

export type GQDeleteBuildingSupportingDocumentMutationVariables = Exact<{
  input: GQDeleteSupportingDocumentInput;
}>;

export type GQDeleteBuildingSupportingDocumentMutation = {
  __typename?: 'Mutation';
  deleteSupportingDocument: {
    __typename?: 'DeleteSupportingDocumentPayload';
    buildingUntyped: {
      __typename?: 'BuildingUntyped';
      id: string;
      data: any;
    } | null;
    supportingDocument: { __typename?: 'SupportingDocument'; id: string };
  } | null;
};

export type GQGenerateTemplateDownloadUrlMutationVariables = Exact<{
  input: GQGenerateTemplateDownloadUrlForDatasetInput;
}>;

export type GQGenerateTemplateDownloadUrlMutation = {
  __typename?: 'Mutation';
  generateTemplateDownloadUrlForDataset: {
    __typename?: 'GenerateTemplateDownloadUrlForDatasetPayload';
    templateDownloadUrl: string;
  };
};

export type GQUpdateUserIngestionReviewMutationVariables = Exact<{
  input: GQUpdateUserIngestionReviewInput;
}>;

export type GQUpdateUserIngestionReviewMutation = {
  __typename?: 'Mutation';
  updateUserIngestionReview: {
    __typename?: 'UpdateUserIngestionReviewPayload';
    userUploadedTable: {
      __typename?: 'UserUploadedTable';
      id: string;
      sheetName: string | null;
      sheetIndex: number | null;
      errorMessage: string | null;
      errorType: string | null;
      numRows: number | null;
      status: GQUserUploadedTableStatus;
      schema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      uirSchema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      parseConfig: {
        __typename?: 'UserUploadedTableParseConfig';
        noHeader: boolean;
        skipTopNRows: number;
        skipBottomNRows: number;
        meltIdVars: Array<string | null> | null;
      } | null;
      roboCleanerResult: {
        __typename?: 'UserUploadedTableRoboCleanerResult';
        skipTopNRows: number;
      } | null;
      userReviewMetadata: {
        __typename?: 'UserUploadedTableUserReviewMetadata';
        headerRowIndex: number;
      } | null;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
      };
      dataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataUnshiftedPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
    };
  } | null;
};

export type GQUpdateUserUploadMutationVariables = Exact<{
  fileId: Scalars['ID']['input'];
  description: InputMaybe<Scalars['String']['input']>;
}>;

export type GQUpdateUserUploadMutation = {
  __typename?: 'Mutation';
  updateUserUpload: {
    __typename?: 'UpdateUserUploadPayload';
    userUpload: {
      __typename?: 'UserUpload';
      id: string;
      description: string | null;
    } | null;
  } | null;
};

export type GQSubmitEarlyUploadHelpRequestMutationVariables = Exact<{
  input: GQSubmitEarlyUploadHelpRequestInput;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQSubmitEarlyUploadHelpRequestMutation = {
  __typename?: 'Mutation';
  submitEarlyUploadHelpRequest: {
    __typename?: 'SubmitEarlyUploadHelpRequestPayload';
    userUploads: Array<{
      __typename?: 'UserUpload';
      id: string;
      userUploadTask: {
        __typename?: 'UserUploadTask';
        id: string;
        createdAt: Date;
        state: GQUserUploadTaskState;
        importState: GQDatasourceImportState;
        approvalStatus: GQApprovalStatus;
        isDatasetComplete: boolean | null;
        lockState: GQApprovalTargetLockState;
        datasource: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          isUserConfirmed: boolean | null;
          hasIntegrationOption: boolean;
          hasIntegration: boolean;
          customerTargetSchemaIds: Array<string> | null;
          importInstructionsMd: string | null;
          refreshCadence: GQDatasetCadence;
          hasApiAccess: boolean;
          notesFromWatershedMd: string | null;
          activeIntegrationConnection: {
            __typename?: 'IntegrationConnection';
            id: string;
            additionalIdentifier: string | null;
          } | null;
          instructions: {
            __typename?: 'InstructionsBundle';
            instructionsIntroMd: string | null;
            instructionsDataFormattingMd: string | null;
            sdiInstructionsIntroMd: string | null;
            sdiInstructionsDataFormattingMd: string | null;
            ghgScopes: Array<GQGhgScope> | null;
            instructionsSteps: Array<{
              __typename?: 'InstructionsStep';
              descriptionMd: string;
              publicUrl: string | null;
            }> | null;
            sdiInstructionsSteps: Array<{
              __typename?: 'SdiInstructionsStep';
              title: string;
              descriptionMd: string;
            }> | null;
            sdiInstructionsFaq: Array<{
              __typename?: 'SdiInstructionsFaqType';
              question: string;
              answerMd: string;
            }> | null;
          } | null;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              customerTargetSchemaIds: Array<string> | null;
            }>;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              name: string;
              kind: GQCanonicalDatasetKind;
              instructions: {
                __typename?: 'InstructionsBundle';
                instructionsIntroMd: string | null;
                instructionsDataFormattingMd: string | null;
                sdiInstructionsIntroMd: string | null;
                sdiInstructionsDataFormattingMd: string | null;
                ghgScopes: Array<GQGhgScope> | null;
                instructionsSteps: Array<{
                  __typename?: 'InstructionsStep';
                  descriptionMd: string;
                  publicUrl: string | null;
                }> | null;
                sdiInstructionsSteps: Array<{
                  __typename?: 'SdiInstructionsStep';
                  title: string;
                  descriptionMd: string;
                }> | null;
                sdiInstructionsFaq: Array<{
                  __typename?: 'SdiInstructionsFaqType';
                  question: string;
                  answerMd: string;
                }> | null;
              } | null;
              customerTargetSchemas: Array<{
                __typename?: 'CustomerTargetSchema';
                id: string;
                name: string;
                utilityType: GQBuildingUtilityType | null;
                rank: number;
                latestPublishedVersion: {
                  __typename?: 'CustomerTargetSchemaVersion';
                  id: string;
                  formattingNotesMd: string | null;
                  orgDatasetExampleData: Array<{
                    __typename?: 'IngestionExampleData';
                    id: string;
                    title: string;
                    datasetId: string | null;
                    columns: Array<{
                      __typename?: 'IngestionExampleDataColumn';
                      fieldName: string;
                      title: string;
                      description: string;
                      examples: Array<string>;
                      required: boolean;
                      optionalToMap: boolean | null;
                      defaultValue: any | null;
                    }>;
                    rows: Array<{
                      __typename?: 'IngestionExampleDataRow';
                      fields: Array<{
                        __typename?: 'IngestionExampleDataRowField';
                        fieldName: string;
                        value: string;
                      }>;
                    }>;
                  }>;
                  transforms: Array<{
                    __typename?: 'CustomerTargetSchemaTransform';
                    id: string;
                    businessActivityTypeName: string | null;
                    businessActivityTypeVersion: {
                      __typename?: 'BusinessActivityTypeVersion';
                      id: string;
                    } | null;
                  }>;
                } | null;
              }>;
            } | null;
            customExampleData: {
              __typename?: 'TabularData';
              cells: Array<Array<any>>;
            } | null;
          };
          canonicalDatasource: {
            __typename?: 'CanonicalDatasource';
            id: string;
            name: string;
            integrationPartner: {
              __typename?: 'IntegrationPartner';
              id: string;
              name: string;
              logoUrl: string;
              externalId: string;
              dataType: string;
              isRequestOnly: boolean;
              applicableDatasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                hasIntegration: boolean;
                isUserConfirmed: boolean | null;
                activeIntegrationConnection: {
                  __typename?: 'IntegrationConnection';
                  id: string;
                } | null;
                dataset: { __typename?: 'Dataset'; id: string; name: string };
              }>;
              activeIntegrationConnections: Array<{
                __typename?: 'IntegrationConnection';
                id: string;
                additionalIdentifier: string | null;
              }>;
            } | null;
          } | null;
          userUploadTasks: {
            __typename?: 'UserUploadTaskConnection';
            edges: Array<{
              __typename?: 'UserUploadTaskEdge';
              node: {
                __typename?: 'UserUploadTask';
                id: string;
                createdAt: Date;
                state: GQUserUploadTaskState;
                importState: GQDatasourceImportState;
                approvalStatus: GQApprovalStatus;
                isDatasetComplete: boolean | null;
                lockState: GQApprovalTargetLockState;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  displayName: string;
                  name: string;
                }>;
                assignee: {
                  __typename?: 'User';
                  id: string;
                  displayName: string;
                  name: string;
                } | null;
                measurementTask: {
                  __typename?: 'MeasurementTaskFlat';
                  id: string;
                  status: GQTaskWatershedProcessState;
                  statusSimplified: GQTaskWatershedProcessStateSimplified;
                };
                measurementProject: {
                  __typename?: 'MeasurementProject';
                  id: string;
                  name: string;
                  active: boolean;
                  coverageInterval: YMInterval;
                  coverageStartDate: Date;
                  coverageEndDate: Date;
                  datasetsWithStages: Array<{
                    __typename?: 'DatasetWithStage';
                    id: string;
                    areAllTasksComplete: boolean;
                  }>;
                  measurementTimeline: {
                    __typename?: 'MeasurementTimeline';
                    id: string;
                    deadline: Date;
                  } | null;
                };
                datasetRequirements: Array<{
                  __typename?: 'ClimateProgramProjectRequirementDataset';
                  id: string;
                  additionalNotesMd: string | null;
                  climateProgramProject: {
                    __typename: 'ClimateProgramProject';
                    name: string;
                    id: string;
                    canonicalClimateProgramProject: {
                      __typename: 'CanonicalClimateProgramProject';
                      id: string;
                      kind: GQClimateProgramProjectKind;
                      measurementObjectCopy: string | null;
                      routeType: string | null;
                      name: string;
                    } | null;
                  };
                }>;
                userUploads: {
                  __typename?: 'UserUploadConnection';
                  edges: Array<{
                    __typename?: 'UserUploadEdge';
                    node: {
                      __typename?: 'UserUpload';
                      id: string;
                      name: string;
                      status: GQUserUploadStatus;
                      processingMode: GQUserUploadProcessingMode | null;
                      userVisibleErrorMessage: string | null;
                      createdAt: Date;
                      latestAdeIdForSDIFacilitiesFlow: string | null;
                      description: string | null;
                      revisionRoot: string | null;
                      processingWorkflowId: string | null;
                      isBeingValueMapped: boolean;
                      actualUserUploadId: string | null;
                      associatedUtilityCount: number;
                      userUploadKind: GQUserUploadCandidateKind | null;
                      userVisibleAttempt: {
                        __typename?: 'UserUploadAttempt';
                        id: string;
                        name: string;
                        size: number;
                        remoteWritten: boolean;
                        createdAt: Date;
                        userUploadSource: GQUserUploadSource | null;
                        uploader: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                        } | null;
                        userUploadedTables: Array<{
                          __typename?: 'UserUploadedTable';
                          id: string;
                          sheetName: string | null;
                          sheetIndex: number | null;
                          errorMessage: string | null;
                          errorType: string | null;
                          numRows: number | null;
                          status: GQUserUploadedTableStatus;
                          schema: Array<{
                            __typename?: 'UserUploadedTableSchemaColumn';
                            index: number;
                            kind: GQUserUploadedTableSchemaColumnKind;
                            name: string;
                            alias: string;
                          }> | null;
                          uirSchema: Array<{
                            __typename?: 'UserUploadedTableSchemaColumn';
                            index: number;
                            kind: GQUserUploadedTableSchemaColumnKind;
                            name: string;
                            alias: string;
                          }> | null;
                          parseConfig: {
                            __typename?: 'UserUploadedTableParseConfig';
                            noHeader: boolean;
                            skipTopNRows: number;
                            skipBottomNRows: number;
                            meltIdVars: Array<string | null> | null;
                          } | null;
                          roboCleanerResult: {
                            __typename?: 'UserUploadedTableRoboCleanerResult';
                            skipTopNRows: number;
                          } | null;
                          userReviewMetadata: {
                            __typename?: 'UserUploadedTableUserReviewMetadata';
                            headerRowIndex: number;
                          } | null;
                          parentFileMetadata: {
                            __typename?: 'FileMetadata';
                            id: string;
                            name: string;
                          };
                          dataPreview: {
                            __typename?: 'UserUploadedTableDataPreview';
                            dataUntyped: any;
                          } | null;
                          rawDataPreview: {
                            __typename?: 'UserUploadedTableDataPreview';
                            dataUntyped: any;
                          } | null;
                          rawDataUnshiftedPreview: {
                            __typename?: 'UserUploadedTableDataPreview';
                            dataUntyped: any;
                          } | null;
                        }>;
                        latestUutsIfRevisionExists: Array<{
                          __typename?: 'UserUploadedTable';
                          id: string;
                          sheetName: string | null;
                          sheetIndex: number | null;
                          errorMessage: string | null;
                          errorType: string | null;
                          numRows: number | null;
                          status: GQUserUploadedTableStatus;
                          schema: Array<{
                            __typename?: 'UserUploadedTableSchemaColumn';
                            index: number;
                            kind: GQUserUploadedTableSchemaColumnKind;
                            name: string;
                            alias: string;
                          }> | null;
                          uirSchema: Array<{
                            __typename?: 'UserUploadedTableSchemaColumn';
                            index: number;
                            kind: GQUserUploadedTableSchemaColumnKind;
                            name: string;
                            alias: string;
                          }> | null;
                          parseConfig: {
                            __typename?: 'UserUploadedTableParseConfig';
                            noHeader: boolean;
                            skipTopNRows: number;
                            skipBottomNRows: number;
                            meltIdVars: Array<string | null> | null;
                          } | null;
                          roboCleanerResult: {
                            __typename?: 'UserUploadedTableRoboCleanerResult';
                            skipTopNRows: number;
                          } | null;
                          userReviewMetadata: {
                            __typename?: 'UserUploadedTableUserReviewMetadata';
                            headerRowIndex: number;
                          } | null;
                          parentFileMetadata: {
                            __typename?: 'FileMetadata';
                            id: string;
                            name: string;
                          };
                          dataPreview: {
                            __typename?: 'UserUploadedTableDataPreview';
                            dataUntyped: any;
                          } | null;
                          rawDataPreview: {
                            __typename?: 'UserUploadedTableDataPreview';
                            dataUntyped: any;
                          } | null;
                          rawDataUnshiftedPreview: {
                            __typename?: 'UserUploadedTableDataPreview';
                            dataUntyped: any;
                          } | null;
                        }> | null;
                      } | null;
                      userUploadTask: {
                        __typename?: 'UserUploadTask';
                        id: string;
                        measurementProject: {
                          __typename?: 'MeasurementProject';
                          id: string;
                          name: string;
                        };
                      } | null;
                      transformsIncludingDeleted: Array<{
                        __typename?: 'CustomerTargetSchemaTransform';
                        id: string;
                        deletedAt: Date | null;
                        sql: string;
                        transformTarget: GQCustomerTargetSchemaTransformTarget;
                        businessActivityTypeName: string | null;
                        businessActivityTypeVersionId: string | null;
                        businessActivityTypeVersion: {
                          __typename?: 'BusinessActivityTypeVersion';
                          id: string;
                          name: string;
                        } | null;
                        runs: Array<{
                          __typename?: 'CustomerTargetSchemaTransformRun';
                          id: string;
                          sql: string;
                          error: string | null;
                          createdAt: Date;
                          transform: {
                            __typename?: 'CustomerTargetSchemaTransform';
                            id: string;
                            transformTarget: GQCustomerTargetSchemaTransformTarget;
                            businessActivityTypeName: string | null;
                          };
                          input: {
                            __typename?: 'FileMetadata';
                            id: string;
                            name: string;
                            category: GQFileCategory;
                            revisionRootFileMetadata: {
                              __typename?: 'FileMetadata';
                              id: string;
                              name: string;
                            } | null;
                          };
                          output: {
                            __typename?: 'ParquetData';
                            id: string;
                            numRecords: number;
                            ndjsonSignedUrl: string | null;
                            ndjsonSize: number | null;
                            ndjsonByteBoundaries: Array<number> | null;
                            columns: Array<{
                              __typename?: 'ParquetColumn';
                              name: string;
                              sqlType: string;
                              minValue: any;
                              maxValue: any;
                              numNulls: number;
                              approxDistinctValues: number;
                              sampleValues: Array<any>;
                            }>;
                          } | null;
                          standardizedOutput: {
                            __typename?: 'ParquetData';
                            id: string;
                            numRecords: number;
                            ndjsonSignedUrl: string | null;
                            ndjsonSize: number | null;
                            ndjsonByteBoundaries: Array<number> | null;
                            columns: Array<{
                              __typename?: 'ParquetColumn';
                              name: string;
                              sqlType: string;
                              minValue: any;
                              maxValue: any;
                              numNulls: number;
                              approxDistinctValues: number;
                              sampleValues: Array<any>;
                            }>;
                          } | null;
                          warnings: Array<{
                            __typename?: 'ValidationWarning';
                            message: string;
                            blocking: boolean;
                          }>;
                          runner: {
                            __typename?: 'User';
                            id: string;
                            name: string;
                          };
                        }>;
                      }>;
                    } | null;
                  } | null>;
                } | null;
                issues: {
                  __typename?: 'DataIssueConnection';
                  edges: Array<{
                    __typename?: 'DataIssueEdge';
                    node: {
                      __typename?: 'DataIssue';
                      id: string;
                      title: string;
                      description: string | null;
                      state: GQDataIssueState;
                      createdAt: Date;
                      isCustomerInitiated: boolean;
                      measurementProject: {
                        __typename?: 'MeasurementProject';
                        id: string;
                        completedAt: Date | null;
                      };
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            message: string;
                            isPublished: boolean;
                            createdAt: Date;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  name: string;
                                  displayName: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  name: string;
                                  displayName: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    } | null;
                  } | null>;
                } | null;
                valueMappingTask: {
                  __typename?: 'ValueMappingTask';
                  id: string;
                  mappingRuleId: string;
                } | null;
                valueMappingTasks: Array<{
                  __typename?: 'ValueMappingTask';
                  id: string;
                  mappingRuleId: string;
                  status: GQTaskWatershedProcessState;
                }>;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                ctsForm: {
                  __typename?: 'CtsForm';
                  id: string;
                  ctsVersionId: string;
                  customerTargetSchemaId: string;
                  datasetId: string;
                  title: string | null;
                  description: string | null;
                  entries: Array<{
                    __typename?: 'CtsFormEntry';
                    id: string;
                    data: any;
                    createdBy: {
                      __typename?: 'User';
                      id: string;
                      displayName: string;
                    };
                  }>;
                } | null;
              } | null;
            } | null>;
          };
          presetCustomTags: Array<{
            __typename?: 'PresetCustomTag';
            ingestionCustomField: {
              __typename?: 'IngestionCustomField';
              id: string;
              name: string;
              type: GQIngestionCustomFieldType;
              possibleValues: Array<string> | null;
              description: string | null;
              appliesToAllDatasets: boolean;
              specificDatasets: Array<{
                __typename?: 'Dataset';
                id: string;
                name: string;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              }>;
            };
            ingestionCustomFieldDatasource: {
              __typename?: 'IngestionCustomFieldDatasource';
              id: string;
              datasourceId: string;
              ingestionCustomFieldId: string;
              ingestionCustomFieldValue: string;
            };
          }>;
          presetOrgUnits: Array<{
            __typename?: 'PresetOrgUnit';
            orgUnit: { __typename?: 'OrgUnit'; id: string; name: string };
            orgUnitDatasource: { __typename?: 'OrgUnitDatasource'; id: string };
          }>;
        };
        facilitiesPreview: {
          __typename?: 'FacilitiesPreview';
          totalCount: number;
        };
        assignees: Array<{
          __typename?: 'User';
          id: string;
          displayName: string;
          name: string;
        }>;
        assignee: {
          __typename?: 'User';
          id: string;
          displayName: string;
          name: string;
        } | null;
        measurementTask: {
          __typename?: 'MeasurementTaskFlat';
          id: string;
          status: GQTaskWatershedProcessState;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
        };
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          name: string;
          active: boolean;
          coverageInterval: YMInterval;
          coverageStartDate: Date;
          coverageEndDate: Date;
          datasetsWithStages: Array<{
            __typename?: 'DatasetWithStage';
            id: string;
            areAllTasksComplete: boolean;
          }>;
          measurementTimeline: {
            __typename?: 'MeasurementTimeline';
            id: string;
            deadline: Date;
          } | null;
        };
        datasetRequirements: Array<{
          __typename?: 'ClimateProgramProjectRequirementDataset';
          id: string;
          additionalNotesMd: string | null;
          climateProgramProject: {
            __typename: 'ClimateProgramProject';
            name: string;
            id: string;
            canonicalClimateProgramProject: {
              __typename: 'CanonicalClimateProgramProject';
              id: string;
              kind: GQClimateProgramProjectKind;
              measurementObjectCopy: string | null;
              routeType: string | null;
              name: string;
            } | null;
          };
        }>;
        userUploads: {
          __typename?: 'UserUploadConnection';
          edges: Array<{
            __typename?: 'UserUploadEdge';
            node: {
              __typename?: 'UserUpload';
              id: string;
              name: string;
              status: GQUserUploadStatus;
              processingMode: GQUserUploadProcessingMode | null;
              userVisibleErrorMessage: string | null;
              createdAt: Date;
              latestAdeIdForSDIFacilitiesFlow: string | null;
              description: string | null;
              revisionRoot: string | null;
              processingWorkflowId: string | null;
              isBeingValueMapped: boolean;
              actualUserUploadId: string | null;
              associatedUtilityCount: number;
              userUploadKind: GQUserUploadCandidateKind | null;
              userVisibleAttempt: {
                __typename?: 'UserUploadAttempt';
                id: string;
                name: string;
                size: number;
                remoteWritten: boolean;
                createdAt: Date;
                userUploadSource: GQUserUploadSource | null;
                uploader: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                } | null;
                userUploadedTables: Array<{
                  __typename?: 'UserUploadedTable';
                  id: string;
                  sheetName: string | null;
                  sheetIndex: number | null;
                  errorMessage: string | null;
                  errorType: string | null;
                  numRows: number | null;
                  status: GQUserUploadedTableStatus;
                  schema: Array<{
                    __typename?: 'UserUploadedTableSchemaColumn';
                    index: number;
                    kind: GQUserUploadedTableSchemaColumnKind;
                    name: string;
                    alias: string;
                  }> | null;
                  uirSchema: Array<{
                    __typename?: 'UserUploadedTableSchemaColumn';
                    index: number;
                    kind: GQUserUploadedTableSchemaColumnKind;
                    name: string;
                    alias: string;
                  }> | null;
                  parseConfig: {
                    __typename?: 'UserUploadedTableParseConfig';
                    noHeader: boolean;
                    skipTopNRows: number;
                    skipBottomNRows: number;
                    meltIdVars: Array<string | null> | null;
                  } | null;
                  roboCleanerResult: {
                    __typename?: 'UserUploadedTableRoboCleanerResult';
                    skipTopNRows: number;
                  } | null;
                  userReviewMetadata: {
                    __typename?: 'UserUploadedTableUserReviewMetadata';
                    headerRowIndex: number;
                  } | null;
                  parentFileMetadata: {
                    __typename?: 'FileMetadata';
                    id: string;
                    name: string;
                  };
                  dataPreview: {
                    __typename?: 'UserUploadedTableDataPreview';
                    dataUntyped: any;
                  } | null;
                  rawDataPreview: {
                    __typename?: 'UserUploadedTableDataPreview';
                    dataUntyped: any;
                  } | null;
                  rawDataUnshiftedPreview: {
                    __typename?: 'UserUploadedTableDataPreview';
                    dataUntyped: any;
                  } | null;
                }>;
                latestUutsIfRevisionExists: Array<{
                  __typename?: 'UserUploadedTable';
                  id: string;
                  sheetName: string | null;
                  sheetIndex: number | null;
                  errorMessage: string | null;
                  errorType: string | null;
                  numRows: number | null;
                  status: GQUserUploadedTableStatus;
                  schema: Array<{
                    __typename?: 'UserUploadedTableSchemaColumn';
                    index: number;
                    kind: GQUserUploadedTableSchemaColumnKind;
                    name: string;
                    alias: string;
                  }> | null;
                  uirSchema: Array<{
                    __typename?: 'UserUploadedTableSchemaColumn';
                    index: number;
                    kind: GQUserUploadedTableSchemaColumnKind;
                    name: string;
                    alias: string;
                  }> | null;
                  parseConfig: {
                    __typename?: 'UserUploadedTableParseConfig';
                    noHeader: boolean;
                    skipTopNRows: number;
                    skipBottomNRows: number;
                    meltIdVars: Array<string | null> | null;
                  } | null;
                  roboCleanerResult: {
                    __typename?: 'UserUploadedTableRoboCleanerResult';
                    skipTopNRows: number;
                  } | null;
                  userReviewMetadata: {
                    __typename?: 'UserUploadedTableUserReviewMetadata';
                    headerRowIndex: number;
                  } | null;
                  parentFileMetadata: {
                    __typename?: 'FileMetadata';
                    id: string;
                    name: string;
                  };
                  dataPreview: {
                    __typename?: 'UserUploadedTableDataPreview';
                    dataUntyped: any;
                  } | null;
                  rawDataPreview: {
                    __typename?: 'UserUploadedTableDataPreview';
                    dataUntyped: any;
                  } | null;
                  rawDataUnshiftedPreview: {
                    __typename?: 'UserUploadedTableDataPreview';
                    dataUntyped: any;
                  } | null;
                }> | null;
              } | null;
              userUploadTask: {
                __typename?: 'UserUploadTask';
                id: string;
                measurementProject: {
                  __typename?: 'MeasurementProject';
                  id: string;
                  name: string;
                };
              } | null;
              transformsIncludingDeleted: Array<{
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                deletedAt: Date | null;
                sql: string;
                transformTarget: GQCustomerTargetSchemaTransformTarget;
                businessActivityTypeName: string | null;
                businessActivityTypeVersionId: string | null;
                businessActivityTypeVersion: {
                  __typename?: 'BusinessActivityTypeVersion';
                  id: string;
                  name: string;
                } | null;
                runs: Array<{
                  __typename?: 'CustomerTargetSchemaTransformRun';
                  id: string;
                  sql: string;
                  error: string | null;
                  createdAt: Date;
                  transform: {
                    __typename?: 'CustomerTargetSchemaTransform';
                    id: string;
                    transformTarget: GQCustomerTargetSchemaTransformTarget;
                    businessActivityTypeName: string | null;
                  };
                  input: {
                    __typename?: 'FileMetadata';
                    id: string;
                    name: string;
                    category: GQFileCategory;
                    revisionRootFileMetadata: {
                      __typename?: 'FileMetadata';
                      id: string;
                      name: string;
                    } | null;
                  };
                  output: {
                    __typename?: 'ParquetData';
                    id: string;
                    numRecords: number;
                    ndjsonSignedUrl: string | null;
                    ndjsonSize: number | null;
                    ndjsonByteBoundaries: Array<number> | null;
                    columns: Array<{
                      __typename?: 'ParquetColumn';
                      name: string;
                      sqlType: string;
                      minValue: any;
                      maxValue: any;
                      numNulls: number;
                      approxDistinctValues: number;
                      sampleValues: Array<any>;
                    }>;
                  } | null;
                  standardizedOutput: {
                    __typename?: 'ParquetData';
                    id: string;
                    numRecords: number;
                    ndjsonSignedUrl: string | null;
                    ndjsonSize: number | null;
                    ndjsonByteBoundaries: Array<number> | null;
                    columns: Array<{
                      __typename?: 'ParquetColumn';
                      name: string;
                      sqlType: string;
                      minValue: any;
                      maxValue: any;
                      numNulls: number;
                      approxDistinctValues: number;
                      sampleValues: Array<any>;
                    }>;
                  } | null;
                  warnings: Array<{
                    __typename?: 'ValidationWarning';
                    message: string;
                    blocking: boolean;
                  }>;
                  runner: { __typename?: 'User'; id: string; name: string };
                }>;
              }>;
            } | null;
          } | null>;
        } | null;
        issues: {
          __typename?: 'DataIssueConnection';
          edges: Array<{
            __typename?: 'DataIssueEdge';
            node: {
              __typename?: 'DataIssue';
              id: string;
              title: string;
              description: string | null;
              state: GQDataIssueState;
              createdAt: Date;
              isCustomerInitiated: boolean;
              measurementProject: {
                __typename?: 'MeasurementProject';
                id: string;
                completedAt: Date | null;
              };
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    message: string;
                    isPublished: boolean;
                    createdAt: Date;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          name: string;
                          displayName: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            } | null;
          } | null>;
        } | null;
        valueMappingTask: {
          __typename?: 'ValueMappingTask';
          id: string;
          mappingRuleId: string;
        } | null;
        valueMappingTasks: Array<{
          __typename?: 'ValueMappingTask';
          id: string;
          mappingRuleId: string;
          status: GQTaskWatershedProcessState;
        }>;
        approvers: Array<{
          __typename?: 'UserWithApprovalStatus';
          approvalStatus: GQApprovalStatus | null;
          user: {
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          };
        }>;
        ctsForm: {
          __typename?: 'CtsForm';
          id: string;
          ctsVersionId: string;
          customerTargetSchemaId: string;
          datasetId: string;
          title: string | null;
          description: string | null;
          entries: Array<{
            __typename?: 'CtsFormEntry';
            id: string;
            data: any;
            createdBy: { __typename?: 'User'; id: string; displayName: string };
          }>;
        } | null;
      } | null;
    }>;
  };
};

export type GQImportImport_UserUploadFragment = {
  __typename?: 'UserUpload';
  id: string;
  name: string;
  status: GQUserUploadStatus;
  userVisibleAttempt: {
    __typename?: 'UserUploadAttempt';
    id: string;
    createdAt: Date;
    size: number;
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      status: GQUserUploadedTableStatus;
      sheetName: string | null;
      sheetIndex: number | null;
      errorType: string | null;
      errorMessage: string | null;
      dataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataUnshiftedPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
    }>;
  } | null;
  ctsVersion: { __typename?: 'CustomerTargetSchemaVersion'; id: string } | null;
};

export type GQUserUploadTaskStatusFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  state: GQUserUploadTaskState;
  userUploads: {
    __typename?: 'UserUploadConnection';
    edges: Array<{
      __typename?: 'UserUploadEdge';
      node: {
        __typename?: 'UserUpload';
        id: string;
        name: string;
        status: GQUserUploadStatus;
        userVisibleAttempt: {
          __typename?: 'UserUploadAttempt';
          id: string;
          createdAt: Date;
          size: number;
          userUploadedTables: Array<{
            __typename?: 'UserUploadedTable';
            id: string;
            status: GQUserUploadedTableStatus;
            sheetName: string | null;
            sheetIndex: number | null;
            errorType: string | null;
            errorMessage: string | null;
            dataPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
            rawDataPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
            rawDataUnshiftedPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
          }>;
        } | null;
        ctsVersion: {
          __typename?: 'CustomerTargetSchemaVersion';
          id: string;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GQImportGetUserUploadsByIdsQueryVariables = Exact<{
  userUploadIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQImportGetUserUploadsByIdsQuery = {
  __typename?: 'Query';
  userUploadsById: Array<{
    __typename?: 'UserUpload';
    id: string;
    name: string;
    status: GQUserUploadStatus;
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
      userUploads: {
        __typename?: 'UserUploadConnection';
        edges: Array<{
          __typename?: 'UserUploadEdge';
          node: {
            __typename?: 'UserUpload';
            id: string;
            name: string;
            status: GQUserUploadStatus;
            userVisibleAttempt: {
              __typename?: 'UserUploadAttempt';
              id: string;
              createdAt: Date;
              size: number;
              userUploadedTables: Array<{
                __typename?: 'UserUploadedTable';
                id: string;
                status: GQUserUploadedTableStatus;
                sheetName: string | null;
                sheetIndex: number | null;
                errorType: string | null;
                errorMessage: string | null;
                dataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataUnshiftedPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
              }>;
            } | null;
            ctsVersion: {
              __typename?: 'CustomerTargetSchemaVersion';
              id: string;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
    userVisibleAttempt: {
      __typename?: 'UserUploadAttempt';
      id: string;
      createdAt: Date;
      size: number;
      userUploadedTables: Array<{
        __typename?: 'UserUploadedTable';
        id: string;
        status: GQUserUploadedTableStatus;
        sheetName: string | null;
        sheetIndex: number | null;
        errorType: string | null;
        errorMessage: string | null;
        dataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
        rawDataUnshiftedPreview: {
          __typename?: 'UserUploadedTableDataPreview';
          dataUntyped: any;
        } | null;
      }>;
    } | null;
    ctsVersion: {
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
    } | null;
  }>;
};

export type GQImportCreateUserUploadsMutationVariables = Exact<{
  input: GQCreateUserUploadsInput;
}>;

export type GQImportCreateUserUploadsMutation = {
  __typename?: 'Mutation';
  createUserUploads: {
    __typename?: 'CreateUserUploadsPayload';
    files: Array<{
      __typename?: 'CreateUserUploadItemPayload';
      url: string;
      fileId: string;
      filename: string;
    }>;
    userUploads: Array<{
      __typename?: 'UserUpload';
      id: string;
      name: string;
      status: GQUserUploadStatus;
      userVisibleAttempt: {
        __typename?: 'UserUploadAttempt';
        id: string;
        createdAt: Date;
        size: number;
        userUploadedTables: Array<{
          __typename?: 'UserUploadedTable';
          id: string;
          status: GQUserUploadedTableStatus;
          sheetName: string | null;
          sheetIndex: number | null;
          errorType: string | null;
          errorMessage: string | null;
          dataPreview: {
            __typename?: 'UserUploadedTableDataPreview';
            dataUntyped: any;
          } | null;
          rawDataPreview: {
            __typename?: 'UserUploadedTableDataPreview';
            dataUntyped: any;
          } | null;
          rawDataUnshiftedPreview: {
            __typename?: 'UserUploadedTableDataPreview';
            dataUntyped: any;
          } | null;
        }>;
      } | null;
      ctsVersion: {
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
      } | null;
    }>;
    failedToUpload: Array<{
      __typename?: 'UserUploadFailure';
      filename: string;
      reason: string;
    }>;
  };
};

export type GQImportDeleteFileMutationVariables = Exact<{
  fileId: Scalars['ID']['input'];
}>;

export type GQImportDeleteFileMutation = {
  __typename?: 'Mutation';
  deleteFile: {
    __typename?: 'DeleteFilePayload';
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
      userUploads: {
        __typename?: 'UserUploadConnection';
        edges: Array<{
          __typename?: 'UserUploadEdge';
          node: {
            __typename?: 'UserUpload';
            id: string;
            name: string;
            status: GQUserUploadStatus;
            userVisibleAttempt: {
              __typename?: 'UserUploadAttempt';
              id: string;
              createdAt: Date;
              size: number;
              userUploadedTables: Array<{
                __typename?: 'UserUploadedTable';
                id: string;
                status: GQUserUploadedTableStatus;
                sheetName: string | null;
                sheetIndex: number | null;
                errorType: string | null;
                errorMessage: string | null;
                dataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataUnshiftedPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
              }>;
            } | null;
            ctsVersion: {
              __typename?: 'CustomerTargetSchemaVersion';
              id: string;
            } | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type GQSetUserUploadedTableSheetsNotIngestedMutationVariables = Exact<{
  input: GQSetUserUploadedTableSheetsNotIngestedInput;
}>;

export type GQSetUserUploadedTableSheetsNotIngestedMutation = {
  __typename?: 'Mutation';
  setUserUploadedTableSheetsNotIngested: {
    __typename?: 'SetUserUploadedTableSheetsNotIngestedPayload';
    userUploadAttempt: {
      __typename?: 'UserUploadAttempt';
      id: string;
      userUploadedTables: Array<{
        __typename?: 'UserUploadedTable';
        id: string;
      }>;
    };
  } | null;
};

export type GQImportSetupQueryVariables = Exact<{
  datasetId: Scalars['ID']['input'];
}>;

export type GQImportSetupQuery = {
  __typename?: 'Query';
  dataset: {
    __typename?: 'Dataset';
    id: string;
    name: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      canonicalDatasources: Array<{
        __typename?: 'CanonicalDatasource';
        id: string;
        name: string;
        activeIntegrationConnection: {
          __typename?: 'IntegrationConnection';
          id: string;
        } | null;
      }>;
    } | null;
  } | null;
};

export type GQImportGetSharedFilesQueryVariables = Exact<{
  datasourceId: Scalars['ID']['input'];
  currentUserId: Scalars['ID']['input'];
}>;

export type GQImportGetSharedFilesQuery = {
  __typename?: 'Query';
  datasource: {
    __typename?: 'Datasource';
    id: string;
    sharedFiles: {
      __typename?: 'SharedFileConnection';
      edges: Array<{
        __typename?: 'SharedFileEdge';
        node: {
          __typename?: 'SharedFile';
          id: string;
          expiresAt: Date;
          fileMetadata: {
            __typename?: 'FileMetadata';
            id: string;
            name: string;
            size: number;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type GQSharedFileForImportImportFragment = {
  __typename?: 'SharedFile';
  id: string;
  expiresAt: Date;
  fileMetadata: {
    __typename?: 'FileMetadata';
    id: string;
    name: string;
    size: number;
  };
};

export type GQImportMarkUserUploadAsProcessingMutationVariables = Exact<{
  fileId: Scalars['ID']['input'];
  shouldSubmitUserUploadTask: Scalars['Boolean']['input'];
}>;

export type GQImportMarkUserUploadAsProcessingMutation = {
  __typename?: 'Mutation';
  markUserUploadAsProcessing: {
    __typename?: 'MarkUserUploadAsProcessingPayload';
    userUpload: {
      __typename?: 'UserUpload';
      id: string;
      status: GQUserUploadStatus;
    } | null;
  } | null;
};

export type GQCustomFieldForImporterPageFragment = {
  __typename?: 'IngestionCustomField';
  id: string;
  name: string;
  type: GQIngestionCustomFieldType;
  description: string | null;
  bartTagName: string;
  possibleValues: Array<string> | null;
  appliesToAllDatasets: boolean;
  specificDatasets: Array<{ __typename?: 'Dataset'; id: string }>;
};

export type GQOrganizationCustomFieldsForImporterPageFragment = {
  __typename?: 'Organization';
  id: string;
  ingestionCustomFields: Array<{
    __typename?: 'IngestionCustomField';
    id: string;
    name: string;
    type: GQIngestionCustomFieldType;
    description: string | null;
    bartTagName: string;
    possibleValues: Array<string> | null;
    appliesToAllDatasets: boolean;
    specificDatasets: Array<{ __typename?: 'Dataset'; id: string }>;
  }>;
};

export type GQGenerateBartFromFileUploadMutationVariables = Exact<{
  input: GQGenerateBartFromFileUploadInput;
}>;

export type GQGenerateBartFromFileUploadMutation = {
  __typename?: 'Mutation';
  generateBartFromFileUpload: {
    __typename?: 'GenerateBartFromFileUploadPayload';
    jobId: string;
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      createdAt: Date;
      state: GQUserUploadTaskState;
      importState: GQDatasourceImportState;
      approvalStatus: GQApprovalStatus;
      isDatasetComplete: boolean | null;
      lockState: GQApprovalTargetLockState;
      assignees: Array<{
        __typename?: 'User';
        id: string;
        displayName: string;
        name: string;
      }>;
      assignee: {
        __typename?: 'User';
        id: string;
        displayName: string;
        name: string;
      } | null;
      measurementTask: {
        __typename?: 'MeasurementTaskFlat';
        id: string;
        status: GQTaskWatershedProcessState;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
      };
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
        active: boolean;
        coverageInterval: YMInterval;
        coverageStartDate: Date;
        coverageEndDate: Date;
        datasetsWithStages: Array<{
          __typename?: 'DatasetWithStage';
          id: string;
          areAllTasksComplete: boolean;
        }>;
        measurementTimeline: {
          __typename?: 'MeasurementTimeline';
          id: string;
          deadline: Date;
        } | null;
      };
      datasetRequirements: Array<{
        __typename?: 'ClimateProgramProjectRequirementDataset';
        id: string;
        additionalNotesMd: string | null;
        climateProgramProject: {
          __typename: 'ClimateProgramProject';
          name: string;
          id: string;
          canonicalClimateProgramProject: {
            __typename: 'CanonicalClimateProgramProject';
            id: string;
            kind: GQClimateProgramProjectKind;
            measurementObjectCopy: string | null;
            routeType: string | null;
            name: string;
          } | null;
        };
      }>;
      userUploads: {
        __typename?: 'UserUploadConnection';
        edges: Array<{
          __typename?: 'UserUploadEdge';
          node: {
            __typename?: 'UserUpload';
            id: string;
            name: string;
            status: GQUserUploadStatus;
            processingMode: GQUserUploadProcessingMode | null;
            userVisibleErrorMessage: string | null;
            createdAt: Date;
            latestAdeIdForSDIFacilitiesFlow: string | null;
            description: string | null;
            revisionRoot: string | null;
            processingWorkflowId: string | null;
            isBeingValueMapped: boolean;
            actualUserUploadId: string | null;
            associatedUtilityCount: number;
            userUploadKind: GQUserUploadCandidateKind | null;
            userVisibleAttempt: {
              __typename?: 'UserUploadAttempt';
              id: string;
              name: string;
              size: number;
              remoteWritten: boolean;
              createdAt: Date;
              userUploadSource: GQUserUploadSource | null;
              uploader: {
                __typename?: 'User';
                id: string;
                name: string;
              } | null;
              userUploadedTables: Array<{
                __typename?: 'UserUploadedTable';
                id: string;
                sheetName: string | null;
                sheetIndex: number | null;
                errorMessage: string | null;
                errorType: string | null;
                numRows: number | null;
                status: GQUserUploadedTableStatus;
                schema: Array<{
                  __typename?: 'UserUploadedTableSchemaColumn';
                  index: number;
                  kind: GQUserUploadedTableSchemaColumnKind;
                  name: string;
                  alias: string;
                }> | null;
                uirSchema: Array<{
                  __typename?: 'UserUploadedTableSchemaColumn';
                  index: number;
                  kind: GQUserUploadedTableSchemaColumnKind;
                  name: string;
                  alias: string;
                }> | null;
                parseConfig: {
                  __typename?: 'UserUploadedTableParseConfig';
                  noHeader: boolean;
                  skipTopNRows: number;
                  skipBottomNRows: number;
                  meltIdVars: Array<string | null> | null;
                } | null;
                roboCleanerResult: {
                  __typename?: 'UserUploadedTableRoboCleanerResult';
                  skipTopNRows: number;
                } | null;
                userReviewMetadata: {
                  __typename?: 'UserUploadedTableUserReviewMetadata';
                  headerRowIndex: number;
                } | null;
                parentFileMetadata: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                };
                dataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataUnshiftedPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
              }>;
              latestUutsIfRevisionExists: Array<{
                __typename?: 'UserUploadedTable';
                id: string;
                sheetName: string | null;
                sheetIndex: number | null;
                errorMessage: string | null;
                errorType: string | null;
                numRows: number | null;
                status: GQUserUploadedTableStatus;
                schema: Array<{
                  __typename?: 'UserUploadedTableSchemaColumn';
                  index: number;
                  kind: GQUserUploadedTableSchemaColumnKind;
                  name: string;
                  alias: string;
                }> | null;
                uirSchema: Array<{
                  __typename?: 'UserUploadedTableSchemaColumn';
                  index: number;
                  kind: GQUserUploadedTableSchemaColumnKind;
                  name: string;
                  alias: string;
                }> | null;
                parseConfig: {
                  __typename?: 'UserUploadedTableParseConfig';
                  noHeader: boolean;
                  skipTopNRows: number;
                  skipBottomNRows: number;
                  meltIdVars: Array<string | null> | null;
                } | null;
                roboCleanerResult: {
                  __typename?: 'UserUploadedTableRoboCleanerResult';
                  skipTopNRows: number;
                } | null;
                userReviewMetadata: {
                  __typename?: 'UserUploadedTableUserReviewMetadata';
                  headerRowIndex: number;
                } | null;
                parentFileMetadata: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                };
                dataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataUnshiftedPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
              }> | null;
            } | null;
            userUploadTask: {
              __typename?: 'UserUploadTask';
              id: string;
              measurementProject: {
                __typename?: 'MeasurementProject';
                id: string;
                name: string;
              };
            } | null;
            transformsIncludingDeleted: Array<{
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              deletedAt: Date | null;
              sql: string;
              transformTarget: GQCustomerTargetSchemaTransformTarget;
              businessActivityTypeName: string | null;
              businessActivityTypeVersionId: string | null;
              businessActivityTypeVersion: {
                __typename?: 'BusinessActivityTypeVersion';
                id: string;
                name: string;
              } | null;
              runs: Array<{
                __typename?: 'CustomerTargetSchemaTransformRun';
                id: string;
                sql: string;
                error: string | null;
                createdAt: Date;
                transform: {
                  __typename?: 'CustomerTargetSchemaTransform';
                  id: string;
                  transformTarget: GQCustomerTargetSchemaTransformTarget;
                  businessActivityTypeName: string | null;
                };
                input: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                  category: GQFileCategory;
                  revisionRootFileMetadata: {
                    __typename?: 'FileMetadata';
                    id: string;
                    name: string;
                  } | null;
                };
                output: {
                  __typename?: 'ParquetData';
                  id: string;
                  numRecords: number;
                  ndjsonSignedUrl: string | null;
                  ndjsonSize: number | null;
                  ndjsonByteBoundaries: Array<number> | null;
                  columns: Array<{
                    __typename?: 'ParquetColumn';
                    name: string;
                    sqlType: string;
                    minValue: any;
                    maxValue: any;
                    numNulls: number;
                    approxDistinctValues: number;
                    sampleValues: Array<any>;
                  }>;
                } | null;
                standardizedOutput: {
                  __typename?: 'ParquetData';
                  id: string;
                  numRecords: number;
                  ndjsonSignedUrl: string | null;
                  ndjsonSize: number | null;
                  ndjsonByteBoundaries: Array<number> | null;
                  columns: Array<{
                    __typename?: 'ParquetColumn';
                    name: string;
                    sqlType: string;
                    minValue: any;
                    maxValue: any;
                    numNulls: number;
                    approxDistinctValues: number;
                    sampleValues: Array<any>;
                  }>;
                } | null;
                warnings: Array<{
                  __typename?: 'ValidationWarning';
                  message: string;
                  blocking: boolean;
                }>;
                runner: { __typename?: 'User'; id: string; name: string };
              }>;
            }>;
          } | null;
        } | null>;
      } | null;
      issues: {
        __typename?: 'DataIssueConnection';
        edges: Array<{
          __typename?: 'DataIssueEdge';
          node: {
            __typename?: 'DataIssue';
            id: string;
            title: string;
            description: string | null;
            state: GQDataIssueState;
            createdAt: Date;
            isCustomerInitiated: boolean;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              completedAt: Date | null;
            };
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  message: string;
                  isPublished: boolean;
                  createdAt: Date;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      } | null;
      valueMappingTask: {
        __typename?: 'ValueMappingTask';
        id: string;
        mappingRuleId: string;
      } | null;
      valueMappingTasks: Array<{
        __typename?: 'ValueMappingTask';
        id: string;
        mappingRuleId: string;
        status: GQTaskWatershedProcessState;
      }>;
      approvers: Array<{
        __typename?: 'UserWithApprovalStatus';
        approvalStatus: GQApprovalStatus | null;
        user: {
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        };
      }>;
      ctsForm: {
        __typename?: 'CtsForm';
        id: string;
        ctsVersionId: string;
        customerTargetSchemaId: string;
        datasetId: string;
        title: string | null;
        description: string | null;
        entries: Array<{
          __typename?: 'CtsFormEntry';
          id: string;
          data: any;
          createdBy: { __typename?: 'User'; id: string; displayName: string };
        }>;
      } | null;
    };
  };
};

export type GQSampledFinancialAccountIdsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQSampledFinancialAccountIdsQuery = {
  __typename?: 'Query';
  sampledFinancialAccountIds: Array<string>;
};

export type GQCustomEmissionsSpecifiersForDashboardQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQCustomEmissionsSpecifiersForDashboardQuery = {
  __typename?: 'Query';
  customEmissionsSpecifiersForOrg: Array<string> | null;
};

export type GQDatasetForImporterFragment = {
  __typename?: 'Dataset';
  id: string;
  name: string;
  datasources: Array<{
    __typename?: 'Datasource';
    id: string;
    name: string;
    customerTargetSchemaIds: Array<string> | null;
  }>;
  canonicalDataset: {
    __typename?: 'CanonicalDataset';
    id: string;
    kind: GQCanonicalDatasetKind;
    customerTargetSchemas: Array<{
      __typename?: 'CustomerTargetSchema';
      id: string;
      name: string;
      utilityType: GQBuildingUtilityType | null;
      rank: number;
      latestPublishedVersion: {
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        formattingNotesMd: string | null;
        orgDatasetExampleData: Array<{
          __typename?: 'IngestionExampleData';
          id: string;
          title: string;
          datasetId: string | null;
          columns: Array<{
            __typename?: 'IngestionExampleDataColumn';
            fieldName: string;
            title: string;
            description: string;
            examples: Array<string>;
            required: boolean;
            optionalToMap: boolean | null;
            defaultValue: any | null;
          }>;
          rows: Array<{
            __typename?: 'IngestionExampleDataRow';
            fields: Array<{
              __typename?: 'IngestionExampleDataRowField';
              fieldName: string;
              value: string;
            }>;
          }>;
        }>;
        transforms: Array<{
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          businessActivityTypeName: string | null;
          businessActivityTypeVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GQGetOneSchemaEmbedStatusQueryVariables = Exact<{
  embedId: Scalars['String']['input'];
}>;

export type GQGetOneSchemaEmbedStatusQuery = {
  __typename?: 'Query';
  getOneSchemaEmbedStatus: string | null;
};

export type GQSaveIncompleteOneSchemaImportMutationVariables = Exact<{
  input: GQSaveIncompleteOneSchemaImportInput;
}>;

export type GQSaveIncompleteOneSchemaImportMutation = {
  __typename?: 'Mutation';
  saveIncompleteOneSchemaImport: {
    __typename?: 'SaveIncompleteOneSchemaImportPayload';
    jobId: string;
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      createdAt: Date;
      state: GQUserUploadTaskState;
      importState: GQDatasourceImportState;
      approvalStatus: GQApprovalStatus;
      isDatasetComplete: boolean | null;
      lockState: GQApprovalTargetLockState;
      assignees: Array<{
        __typename?: 'User';
        id: string;
        displayName: string;
        name: string;
      }>;
      assignee: {
        __typename?: 'User';
        id: string;
        displayName: string;
        name: string;
      } | null;
      measurementTask: {
        __typename?: 'MeasurementTaskFlat';
        id: string;
        status: GQTaskWatershedProcessState;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
      };
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
        active: boolean;
        coverageInterval: YMInterval;
        coverageStartDate: Date;
        coverageEndDate: Date;
        datasetsWithStages: Array<{
          __typename?: 'DatasetWithStage';
          id: string;
          areAllTasksComplete: boolean;
        }>;
        measurementTimeline: {
          __typename?: 'MeasurementTimeline';
          id: string;
          deadline: Date;
        } | null;
      };
      datasetRequirements: Array<{
        __typename?: 'ClimateProgramProjectRequirementDataset';
        id: string;
        additionalNotesMd: string | null;
        climateProgramProject: {
          __typename: 'ClimateProgramProject';
          name: string;
          id: string;
          canonicalClimateProgramProject: {
            __typename: 'CanonicalClimateProgramProject';
            id: string;
            kind: GQClimateProgramProjectKind;
            measurementObjectCopy: string | null;
            routeType: string | null;
            name: string;
          } | null;
        };
      }>;
      userUploads: {
        __typename?: 'UserUploadConnection';
        edges: Array<{
          __typename?: 'UserUploadEdge';
          node: {
            __typename?: 'UserUpload';
            id: string;
            name: string;
            status: GQUserUploadStatus;
            processingMode: GQUserUploadProcessingMode | null;
            userVisibleErrorMessage: string | null;
            createdAt: Date;
            latestAdeIdForSDIFacilitiesFlow: string | null;
            description: string | null;
            revisionRoot: string | null;
            processingWorkflowId: string | null;
            isBeingValueMapped: boolean;
            actualUserUploadId: string | null;
            associatedUtilityCount: number;
            userUploadKind: GQUserUploadCandidateKind | null;
            userVisibleAttempt: {
              __typename?: 'UserUploadAttempt';
              id: string;
              name: string;
              size: number;
              remoteWritten: boolean;
              createdAt: Date;
              userUploadSource: GQUserUploadSource | null;
              uploader: {
                __typename?: 'User';
                id: string;
                name: string;
              } | null;
              userUploadedTables: Array<{
                __typename?: 'UserUploadedTable';
                id: string;
                sheetName: string | null;
                sheetIndex: number | null;
                errorMessage: string | null;
                errorType: string | null;
                numRows: number | null;
                status: GQUserUploadedTableStatus;
                schema: Array<{
                  __typename?: 'UserUploadedTableSchemaColumn';
                  index: number;
                  kind: GQUserUploadedTableSchemaColumnKind;
                  name: string;
                  alias: string;
                }> | null;
                uirSchema: Array<{
                  __typename?: 'UserUploadedTableSchemaColumn';
                  index: number;
                  kind: GQUserUploadedTableSchemaColumnKind;
                  name: string;
                  alias: string;
                }> | null;
                parseConfig: {
                  __typename?: 'UserUploadedTableParseConfig';
                  noHeader: boolean;
                  skipTopNRows: number;
                  skipBottomNRows: number;
                  meltIdVars: Array<string | null> | null;
                } | null;
                roboCleanerResult: {
                  __typename?: 'UserUploadedTableRoboCleanerResult';
                  skipTopNRows: number;
                } | null;
                userReviewMetadata: {
                  __typename?: 'UserUploadedTableUserReviewMetadata';
                  headerRowIndex: number;
                } | null;
                parentFileMetadata: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                };
                dataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataUnshiftedPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
              }>;
              latestUutsIfRevisionExists: Array<{
                __typename?: 'UserUploadedTable';
                id: string;
                sheetName: string | null;
                sheetIndex: number | null;
                errorMessage: string | null;
                errorType: string | null;
                numRows: number | null;
                status: GQUserUploadedTableStatus;
                schema: Array<{
                  __typename?: 'UserUploadedTableSchemaColumn';
                  index: number;
                  kind: GQUserUploadedTableSchemaColumnKind;
                  name: string;
                  alias: string;
                }> | null;
                uirSchema: Array<{
                  __typename?: 'UserUploadedTableSchemaColumn';
                  index: number;
                  kind: GQUserUploadedTableSchemaColumnKind;
                  name: string;
                  alias: string;
                }> | null;
                parseConfig: {
                  __typename?: 'UserUploadedTableParseConfig';
                  noHeader: boolean;
                  skipTopNRows: number;
                  skipBottomNRows: number;
                  meltIdVars: Array<string | null> | null;
                } | null;
                roboCleanerResult: {
                  __typename?: 'UserUploadedTableRoboCleanerResult';
                  skipTopNRows: number;
                } | null;
                userReviewMetadata: {
                  __typename?: 'UserUploadedTableUserReviewMetadata';
                  headerRowIndex: number;
                } | null;
                parentFileMetadata: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                };
                dataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataUnshiftedPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
              }> | null;
            } | null;
            userUploadTask: {
              __typename?: 'UserUploadTask';
              id: string;
              measurementProject: {
                __typename?: 'MeasurementProject';
                id: string;
                name: string;
              };
            } | null;
            transformsIncludingDeleted: Array<{
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              deletedAt: Date | null;
              sql: string;
              transformTarget: GQCustomerTargetSchemaTransformTarget;
              businessActivityTypeName: string | null;
              businessActivityTypeVersionId: string | null;
              businessActivityTypeVersion: {
                __typename?: 'BusinessActivityTypeVersion';
                id: string;
                name: string;
              } | null;
              runs: Array<{
                __typename?: 'CustomerTargetSchemaTransformRun';
                id: string;
                sql: string;
                error: string | null;
                createdAt: Date;
                transform: {
                  __typename?: 'CustomerTargetSchemaTransform';
                  id: string;
                  transformTarget: GQCustomerTargetSchemaTransformTarget;
                  businessActivityTypeName: string | null;
                };
                input: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                  category: GQFileCategory;
                  revisionRootFileMetadata: {
                    __typename?: 'FileMetadata';
                    id: string;
                    name: string;
                  } | null;
                };
                output: {
                  __typename?: 'ParquetData';
                  id: string;
                  numRecords: number;
                  ndjsonSignedUrl: string | null;
                  ndjsonSize: number | null;
                  ndjsonByteBoundaries: Array<number> | null;
                  columns: Array<{
                    __typename?: 'ParquetColumn';
                    name: string;
                    sqlType: string;
                    minValue: any;
                    maxValue: any;
                    numNulls: number;
                    approxDistinctValues: number;
                    sampleValues: Array<any>;
                  }>;
                } | null;
                standardizedOutput: {
                  __typename?: 'ParquetData';
                  id: string;
                  numRecords: number;
                  ndjsonSignedUrl: string | null;
                  ndjsonSize: number | null;
                  ndjsonByteBoundaries: Array<number> | null;
                  columns: Array<{
                    __typename?: 'ParquetColumn';
                    name: string;
                    sqlType: string;
                    minValue: any;
                    maxValue: any;
                    numNulls: number;
                    approxDistinctValues: number;
                    sampleValues: Array<any>;
                  }>;
                } | null;
                warnings: Array<{
                  __typename?: 'ValidationWarning';
                  message: string;
                  blocking: boolean;
                }>;
                runner: { __typename?: 'User'; id: string; name: string };
              }>;
            }>;
          } | null;
        } | null>;
      } | null;
      issues: {
        __typename?: 'DataIssueConnection';
        edges: Array<{
          __typename?: 'DataIssueEdge';
          node: {
            __typename?: 'DataIssue';
            id: string;
            title: string;
            description: string | null;
            state: GQDataIssueState;
            createdAt: Date;
            isCustomerInitiated: boolean;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              completedAt: Date | null;
            };
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  message: string;
                  isPublished: boolean;
                  createdAt: Date;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      } | null;
      valueMappingTask: {
        __typename?: 'ValueMappingTask';
        id: string;
        mappingRuleId: string;
      } | null;
      valueMappingTasks: Array<{
        __typename?: 'ValueMappingTask';
        id: string;
        mappingRuleId: string;
        status: GQTaskWatershedProcessState;
      }>;
      approvers: Array<{
        __typename?: 'UserWithApprovalStatus';
        approvalStatus: GQApprovalStatus | null;
        user: {
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        };
      }>;
      ctsForm: {
        __typename?: 'CtsForm';
        id: string;
        ctsVersionId: string;
        customerTargetSchemaId: string;
        datasetId: string;
        title: string | null;
        description: string | null;
        entries: Array<{
          __typename?: 'CtsFormEntry';
          id: string;
          data: any;
          createdBy: { __typename?: 'User'; id: string; displayName: string };
        }>;
      } | null;
    };
  };
};

export type GQCreateUtilityDocsMutationVariables = Exact<{
  input: GQCreateUtilityDocsInput;
}>;

export type GQCreateUtilityDocsMutation = {
  __typename?: 'Mutation';
  createUtilityDocs: {
    __typename?: 'CreateUtilityDocsPayload';
    candidateIds: Array<string>;
    supportingDocuments: Array<{
      __typename?: 'SupportingDocument';
      id: string;
      createdAt: Date;
      utilityType: GQBuildingUtilityType | null;
      buildingUtilityId: string | null;
      file: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        remoteWritten: boolean;
      };
    }>;
    files: Array<{
      __typename?: 'CreateUserUploadItemPayload';
      url: string;
      fileId: string;
      filename: string;
    }>;
    failedToUpload: Array<{
      __typename?: 'UserUploadFailure';
      filename: string;
      reason: string;
    }> | null;
  };
};

export type GQExecuteExtractSchemasMutationVariables = Exact<{
  input: GQExecuteExtractSchemasInput;
}>;

export type GQExecuteExtractSchemasMutation = {
  __typename?: 'Mutation';
  executeExtractSchemas: {
    __typename?: 'ExecuteExtractSchemasPayload';
    success: boolean;
  };
};

export type GQUserUploadCandidateRowFieldsFragment = {
  __typename?: 'UserUploadCandidateRowFields';
  id: string;
  data: any;
};

export type GQUserUploadCandidateFieldsFragment = {
  __typename?: 'UserUploadCandidateEntry';
  id: string;
  ctsVersion: {
    __typename?: 'CustomerTargetSchemaVersion';
    id: string;
    schemaJson: any;
  };
  candidateRows: Array<{
    __typename?: 'UserUploadCandidateRowFields';
    id: string;
    data: any;
  }>;
};

export type GQDocumentCandidateFieldsFragment = {
  __typename?: 'DocumentCandidate';
  pdfUrl: string;
  fileName: string;
  id: string;
};

export type GQGetUserUploadCandidatesQueryVariables = Exact<{
  userUploadCandidateIds:
    | Array<Scalars['ID']['input']>
    | Scalars['ID']['input'];
}>;

export type GQGetUserUploadCandidatesQuery = {
  __typename?: 'Query';
  userUploadCandidates: {
    __typename?: 'UserUploadCandidatePayload';
    candidateEntries: Array<{
      __typename?: 'UserUploadCandidateEntry';
      id: string;
      ctsVersion: {
        __typename?: 'CustomerTargetSchemaVersion';
        id: string;
        schemaJson: any;
      };
      candidateRows: Array<{
        __typename?: 'UserUploadCandidateRowFields';
        id: string;
        data: any;
      }>;
    }>;
  };
  documentCandidates: {
    __typename?: 'DocumentCandidatesPayload';
    documentEntries: Array<{
      __typename?: 'DocumentCandidate';
      pdfUrl: string;
      fileName: string;
      id: string;
    }>;
  };
};

export type GQCreateCandidateRowEntryMutationVariables = Exact<{
  input: GQCreateCandidateRowInput;
}>;

export type GQCreateCandidateRowEntryMutation = {
  __typename?: 'Mutation';
  createCandidateRowEntry: {
    __typename?: 'UserUploadCandidateEntry';
    id: string;
    ctsVersion: {
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      schemaJson: any;
    };
    candidateRows: Array<{
      __typename?: 'UserUploadCandidateRowFields';
      id: string;
      data: any;
    }>;
  };
};

export type GQUpdateCandidateRowEntryMutationVariables = Exact<{
  input: GQUpdateCandidateRowInput;
}>;

export type GQUpdateCandidateRowEntryMutation = {
  __typename?: 'Mutation';
  updateCandidateRowEntry: {
    __typename?: 'UserUploadCandidateEntry';
    id: string;
    ctsVersion: {
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      schemaJson: any;
    };
    candidateRows: Array<{
      __typename?: 'UserUploadCandidateRowFields';
      id: string;
      data: any;
    }>;
  };
};

export type GQDeleteCandidateRowEntryMutationVariables = Exact<{
  input: GQDeleteCandidateRowInput;
}>;

export type GQDeleteCandidateRowEntryMutation = {
  __typename?: 'Mutation';
  deleteCandidateRowEntry: {
    __typename?: 'UserUploadCandidateEntry';
    id: string;
    ctsVersion: {
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      schemaJson: any;
    };
    candidateRows: Array<{
      __typename?: 'UserUploadCandidateRowFields';
      id: string;
      data: any;
    }>;
  };
};

export type GQConfirmCandidateMutationVariables = Exact<{
  input: GQConfirmUtilitySchemaInput;
}>;

export type GQConfirmCandidateMutation = {
  __typename?: 'Mutation';
  confirmUtilitySchema: {
    __typename?: 'ConfirmUtilitySchemaPayload';
    success: boolean;
    error: string | null;
  };
};

export type GQDeleteUserUploadCandidateAndRowsMutationVariables = Exact<{
  input: GQDeleteUserUploadCandidateAndRowsInput;
}>;

export type GQDeleteUserUploadCandidateAndRowsMutation = {
  __typename?: 'Mutation';
  deleteUserUploadCandidateAndRows: {
    __typename?: 'DeleteUserUploadCandidateAndRowsPayload';
    id: string;
  };
};

export type GQCustomFieldForBuldingDiffFragment = {
  __typename?: 'IngestionCustomField';
  id: string;
  name: string;
};

export type GQOrgUnitTypeForBuildingDiffFragment = {
  __typename?: 'OrgUnitType';
  id: string;
  name: string;
  stableId: string;
};

export type GQOrgUnitForBuildingDiffFragment = {
  __typename?: 'OrgUnit';
  id: string;
  name: string;
  stableId: string;
};

export type GQBuildingDiffFragment = {
  __typename?: 'CadtBuildingDiff';
  buildingPresentInBart: boolean | null;
  old: {
    __typename?: 'BuildingForDiff';
    streetAddress: string | null;
    streetAddress2: string | null;
    postalCode: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    startYearMonth: YearMonth | null;
    endYearMonth: YearMonth | null;
    sizeUnit: GQBuildingSizeUnit | null;
    buildingKind: string | null;
    buildingSubkind: string | null;
    leaseType: GQBuildingLeaseType | null;
    buildingName: string;
    buildingUniqueId: string;
    isWithoutNaturalGas: boolean | null;
    isWithoutRefrigerants: boolean | null;
    periods: Array<{
      __typename?: 'BuildingPeriodForDiff';
      kind: GQBuildingPeriodKind;
      sizeQuantity: number | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      partialCleanPowerPercent: number | null;
      cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
      powerUsageEffectiveness: number | null;
      isBuildingEmpty: boolean | null;
      customFieldValue: string | null;
      customFieldId: string | null;
      orgUnitTypeStableId: string | null;
      orgUnitStableId: string | null;
    }>;
  } | null;
  new: {
    __typename?: 'BuildingForDiff';
    streetAddress: string | null;
    streetAddress2: string | null;
    postalCode: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    startYearMonth: YearMonth | null;
    endYearMonth: YearMonth | null;
    sizeUnit: GQBuildingSizeUnit | null;
    buildingKind: string | null;
    buildingSubkind: string | null;
    leaseType: GQBuildingLeaseType | null;
    buildingName: string;
    buildingUniqueId: string;
    isWithoutNaturalGas: boolean | null;
    isWithoutRefrigerants: boolean | null;
    periods: Array<{
      __typename?: 'BuildingPeriodForDiff';
      kind: GQBuildingPeriodKind;
      sizeQuantity: number | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      partialCleanPowerPercent: number | null;
      cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
      powerUsageEffectiveness: number | null;
      isBuildingEmpty: boolean | null;
      customFieldValue: string | null;
      customFieldId: string | null;
      orgUnitTypeStableId: string | null;
      orgUnitStableId: string | null;
    }>;
  } | null;
  errors: Array<{
    __typename?: 'BuildingDiffError';
    message: string;
    adminMessage: string;
    code: GQBuildingDiffErrorCode;
  }>;
  warnings: Array<{
    __typename?: 'BuildingDiffWarning';
    adminMessage: string;
    message: string;
    code: GQBuildingDiffWarningCode;
  }>;
};

export type GQBuildingDiffSetFragment = {
  __typename?: 'CadtBuildingDiffSet';
  added: number;
  removed: number;
  changed: number;
  unchanged: number;
  diffs: Array<{
    __typename?: 'CadtBuildingDiff';
    buildingPresentInBart: boolean | null;
    old: {
      __typename?: 'BuildingForDiff';
      streetAddress: string | null;
      streetAddress2: string | null;
      postalCode: string | null;
      city: string | null;
      state: string | null;
      country: string | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      sizeUnit: GQBuildingSizeUnit | null;
      buildingKind: string | null;
      buildingSubkind: string | null;
      leaseType: GQBuildingLeaseType | null;
      buildingName: string;
      buildingUniqueId: string;
      isWithoutNaturalGas: boolean | null;
      isWithoutRefrigerants: boolean | null;
      periods: Array<{
        __typename?: 'BuildingPeriodForDiff';
        kind: GQBuildingPeriodKind;
        sizeQuantity: number | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        partialCleanPowerPercent: number | null;
        cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
        powerUsageEffectiveness: number | null;
        isBuildingEmpty: boolean | null;
        customFieldValue: string | null;
        customFieldId: string | null;
        orgUnitTypeStableId: string | null;
        orgUnitStableId: string | null;
      }>;
    } | null;
    new: {
      __typename?: 'BuildingForDiff';
      streetAddress: string | null;
      streetAddress2: string | null;
      postalCode: string | null;
      city: string | null;
      state: string | null;
      country: string | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      sizeUnit: GQBuildingSizeUnit | null;
      buildingKind: string | null;
      buildingSubkind: string | null;
      leaseType: GQBuildingLeaseType | null;
      buildingName: string;
      buildingUniqueId: string;
      isWithoutNaturalGas: boolean | null;
      isWithoutRefrigerants: boolean | null;
      periods: Array<{
        __typename?: 'BuildingPeriodForDiff';
        kind: GQBuildingPeriodKind;
        sizeQuantity: number | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        partialCleanPowerPercent: number | null;
        cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
        powerUsageEffectiveness: number | null;
        isBuildingEmpty: boolean | null;
        customFieldValue: string | null;
        customFieldId: string | null;
        orgUnitTypeStableId: string | null;
        orgUnitStableId: string | null;
      }>;
    } | null;
    errors: Array<{
      __typename?: 'BuildingDiffError';
      message: string;
      adminMessage: string;
      code: GQBuildingDiffErrorCode;
    }>;
    warnings: Array<{
      __typename?: 'BuildingDiffWarning';
      adminMessage: string;
      message: string;
      code: GQBuildingDiffWarningCode;
    }>;
  }>;
};

export type GQProductPreviewBartBuildingsPushQueryVariables = Exact<{
  activityDataExtractorId: Scalars['ID']['input'];
}>;

export type GQProductPreviewBartBuildingsPushQuery = {
  __typename?: 'Query';
  previewCadtBuildingsPush: {
    __typename?: 'CadtBuildingsPushPreview';
    overwriteDuplicatesDiffUntyped: any | null;
    mergePeriodsDiffUntyped: any | null;
    customFields: Array<{
      __typename?: 'IngestionCustomField';
      id: string;
      name: string;
    }>;
    orgUnitTypes: Array<{
      __typename?: 'OrgUnitType';
      id: string;
      name: string;
      stableId: string;
    }>;
    orgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      stableId: string;
    }>;
  } | null;
};

export type GQPushBartBuildingMutationVariables = Exact<{
  input: GQPushBartBuildingInput;
}>;

export type GQPushBartBuildingMutation = {
  __typename?: 'Mutation';
  pushBartBuilding: {
    __typename?: 'FacilitiesSdiPayload';
    workflowId: string;
  } | null;
};

export type GQGetBuildingTemplateDataForTaskQueryVariables = Exact<{
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQGetBuildingTemplateDataForTaskQuery = {
  __typename?: 'Query';
  buildingTemplateDataUntyped: any | null;
};

export type GQPushCtsVersionToOneSchemaInProductMutationVariables = Exact<{
  input: GQPushCtsVersionToOneSchemaInProductInput;
}>;

export type GQPushCtsVersionToOneSchemaInProductMutation = {
  __typename?: 'Mutation';
  pushCtsVersionToOneSchemaInProduct: {
    __typename?: 'PushCtsVersionToOneSchemaInProductPayload';
    oneSchemaTemplateKey: string;
  };
};

export type GQInstructionsBundleForImporterFragment = {
  __typename?: 'InstructionsBundle';
  sdiInstructionsSteps: Array<{
    __typename?: 'SdiInstructionsStep';
    title: string;
    descriptionMd: string;
  }> | null;
  sdiInstructionsFaq: Array<{
    __typename?: 'SdiInstructionsFaqType';
    question: string;
    answerMd: string;
  }> | null;
};

export type GQPreviewUtilitiesDiffMutationVariables = Exact<{
  input: GQPreviewUtilitiesDiffInput;
}>;

export type GQPreviewUtilitiesDiffMutation = {
  __typename?: 'Mutation';
  previewUtilitiesDiff: {
    __typename?: 'FacilitiesSdiPayload';
    workflowId: string;
  };
};

export type GQPushUtilitiesDiffMutationVariables = Exact<{
  input: GQPushUtilitiesDiffInput;
}>;

export type GQPushUtilitiesDiffMutation = {
  __typename?: 'Mutation';
  pushUtilitiesDiff: {
    __typename?: 'FacilitiesSdiPayload';
    workflowId: string;
  };
};

export type GQValueMappingPageValueMappingTaskFragment = {
  __typename?: 'ValueMappingTask';
  id: string;
  publishStatus: GQValueMappingPublishStatus;
  issues: {
    __typename?: 'DataIssueConnection';
    edges: Array<{
      __typename?: 'DataIssueEdge';
      node: {
        __typename?: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        createdAt: Date;
        isCustomerInitiated: boolean;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          completedAt: Date | null;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              isPublished: boolean;
              createdAt: Date;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
};

export type GQDatasourceValueMappingRuleFragment = {
  __typename?: 'DatasourceValueMappingRule';
  id: string;
  featureFlag: string | null;
  valueMappingConfigId: string;
  applicableDatasetKinds: Array<GQCanonicalDatasetKind>;
  revisionData: {
    __typename?: 'DatasourceValueMappingRevisionData';
    id: string;
    revisionState: GQReferenceDataRevisionState | null;
  };
  valueMapping: {
    __typename?: 'ValueMapping';
    id: string;
    revisionState: GQReferenceDataRevisionState | null;
    valueMappingRows: Array<{
      __typename?: 'ValueMappingRow';
      id: string;
      baseRowJson: string;
    }>;
  } | null;
  valueMappingTask: {
    __typename?: 'ValueMappingTask';
    id: string;
    publishStatus: GQValueMappingPublishStatus;
    issues: {
      __typename?: 'DataIssueConnection';
      edges: Array<{
        __typename?: 'DataIssueEdge';
        node: {
          __typename?: 'DataIssue';
          id: string;
          title: string;
          description: string | null;
          state: GQDataIssueState;
          createdAt: Date;
          isCustomerInitiated: boolean;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            completedAt: Date | null;
          };
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                message: string;
                isPublished: boolean;
                createdAt: Date;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    } | null;
  };
};

export type GQDatasourceValueMappingMeasurementProjectFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  orgId: string;
  active: boolean;
  datasetsWithStages: Array<{
    __typename?: 'DatasetWithStage';
    id: string;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
        name: string;
      } | null;
    } | null;
  }>;
  valueMappingTask: {
    __typename?: 'ValueMappingTask';
    id: string;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
    publishStatus: GQValueMappingPublishStatus;
  } | null;
};

export type GQDatasourceValueMappingCanonicalDatasetFragment = {
  __typename?: 'CanonicalDataset';
  id: string;
  kind: GQCanonicalDatasetKind;
  name: string;
};

export type GQGetValueMappingTaskPublishStatusQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetValueMappingTaskPublishStatusQuery = {
  __typename?: 'Query';
  valueMappingTask: {
    __typename?: 'ValueMappingTask';
    id: string;
    publishStatus: GQValueMappingPublishStatus;
  };
};

export type GQFinancialsReviewAccountDetailsFragment = {
  __typename?: 'FinancialsAccount';
  displayAccountId: string;
  description: string | null;
};

export type GQFinancialsReviewItemInboxDetailsFragment = {
  __typename?: 'FinancialsReviewItem';
  id: string;
  status: GQFinancialsReviewItemStatus;
  account: {
    __typename?: 'FinancialsAccount';
    displayAccountId: string;
    description: string | null;
  };
};

export type GQIndustryClassificationDetailsFragment = {
  __typename?: 'IndustryClassification';
  id: string;
  shortDescription: string;
  longDescription: string;
};

export type GQFinancialsReviewItemDetailsFragment = {
  __typename?: 'FinancialsReviewItem';
  id: string;
  status: GQFinancialsReviewItemStatus;
  kind: GQFinancialsReviewItemKind;
  initiallyIncluded: boolean | null;
  exclusionReason: string | null;
  prompt: string | null;
  userResponse: string | null;
  account: {
    __typename?: 'FinancialsAccount';
    displayAccountId: string;
    description: string | null;
  };
  initialIndustryClassification: {
    __typename?: 'IndustryClassification';
    id: string;
    shortDescription: string;
    longDescription: string;
  } | null;
  askingEmployee: {
    __typename?: 'WatershedEmployee';
    id: string;
    name: string;
  } | null;
  discussion: {
    __typename?: 'DataIssue';
    id: string;
    title: string;
    description: string | null;
    state: GQDataIssueState;
    createdAt: Date;
    isCustomerInitiated: boolean;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      completedAt: Date | null;
    };
    comments: {
      __typename?: 'CommentConnection';
      edges: Array<{
        __typename?: 'CommentEdge';
        node: {
          __typename?: 'Comment';
          id: string;
          message: string;
          isPublished: boolean;
          createdAt: Date;
          person:
            | {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                isWatershedEmployee: boolean;
                isWatershedContractor: boolean;
              }
            | {
                __typename?: 'WatershedEmployee';
                id: string;
                name: string;
                displayName: string;
                isWatershedEmployee: boolean;
                isWatershedContractor: boolean;
              }
            | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GQFinancialsReviewItemDetailPageQueryVariables = Exact<{
  reviewItemId: Scalars['ID']['input'];
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQFinancialsReviewItemDetailPageQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  financialsReviewItems: {
    __typename?: 'FinancialsReviewItemConnection';
    edges: Array<{
      __typename?: 'FinancialsReviewItemEdge';
      node: {
        __typename?: 'FinancialsReviewItem';
        id: string;
        status: GQFinancialsReviewItemStatus;
        kind: GQFinancialsReviewItemKind;
      } | null;
    } | null>;
  };
  financialsReviewItem: {
    __typename?: 'FinancialsReviewItem';
    id: string;
    status: GQFinancialsReviewItemStatus;
    kind: GQFinancialsReviewItemKind;
    initiallyIncluded: boolean | null;
    exclusionReason: string | null;
    prompt: string | null;
    userResponse: string | null;
    account: {
      __typename?: 'FinancialsAccount';
      displayAccountId: string;
      description: string | null;
    };
    initialIndustryClassification: {
      __typename?: 'IndustryClassification';
      id: string;
      shortDescription: string;
      longDescription: string;
    } | null;
    askingEmployee: {
      __typename?: 'WatershedEmployee';
      id: string;
      name: string;
    } | null;
    discussion: {
      __typename?: 'DataIssue';
      id: string;
      title: string;
      description: string | null;
      state: GQDataIssueState;
      createdAt: Date;
      isCustomerInitiated: boolean;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        completedAt: Date | null;
      };
      comments: {
        __typename?: 'CommentConnection';
        edges: Array<{
          __typename?: 'CommentEdge';
          node: {
            __typename?: 'Comment';
            id: string;
            message: string;
            isPublished: boolean;
            createdAt: Date;
            person:
              | {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | {
                  __typename?: 'WatershedEmployee';
                  id: string;
                  name: string;
                  displayName: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type GQSubmitReviewItemReviewMutationVariables = Exact<{
  input: GQReviewFinancialsReviewItemInput;
}>;

export type GQSubmitReviewItemReviewMutation = {
  __typename?: 'Mutation';
  reviewFinancialsReviewItem: {
    __typename?: 'ReviewFinancialsReviewItemPayload';
    reviewItem: {
      __typename?: 'FinancialsReviewItem';
      id: string;
      status: GQFinancialsReviewItemStatus;
      kind: GQFinancialsReviewItemKind;
      initiallyIncluded: boolean | null;
      exclusionReason: string | null;
      prompt: string | null;
      userResponse: string | null;
      account: {
        __typename?: 'FinancialsAccount';
        displayAccountId: string;
        description: string | null;
      };
      initialIndustryClassification: {
        __typename?: 'IndustryClassification';
        id: string;
        shortDescription: string;
        longDescription: string;
      } | null;
      askingEmployee: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
      discussion: {
        __typename?: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        createdAt: Date;
        isCustomerInitiated: boolean;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          completedAt: Date | null;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              isPublished: boolean;
              createdAt: Date;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
    };
    measurementTask:
      | {
          __typename?: 'MeasurementParentTask';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          lockState: GQApprovalTargetLockState | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          subTasks: Array<
            | {
                __typename?: 'MeasurementParentTask';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
            | {
                __typename?: 'MeasurementTaskFlat';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
          >;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          approvers: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
      | { __typename?: 'MeasurementTaskFlat' };
    financialsUserUploadTasks: Array<{
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
    }>;
  } | null;
};

export type GQFinancialsReviewInboxItemFragment = {
  __typename?: 'FinancialsReviewItem';
  id: string;
  status: GQFinancialsReviewItemStatus;
  account: {
    __typename?: 'FinancialsAccount';
    displayAccountId: string;
    description: string | null;
  };
  initialIndustryClassification: {
    __typename?: 'IndustryClassification';
    id: string;
    shortDescription: string;
  } | null;
};

export type GQMappingsReviewIndexPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMappingsReviewIndexPageQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
  };
  financialsReviewItems: {
    __typename?: 'FinancialsReviewItemConnection';
    edges: Array<{
      __typename?: 'FinancialsReviewItemEdge';
      node: {
        __typename?: 'FinancialsReviewItem';
        id: string;
        status: GQFinancialsReviewItemStatus;
        account: {
          __typename?: 'FinancialsAccount';
          displayAccountId: string;
          description: string | null;
        };
        initialIndustryClassification: {
          __typename?: 'IndustryClassification';
          id: string;
          shortDescription: string;
        } | null;
      } | null;
    } | null>;
  };
};

export type GQGetUploadsForUserUploadTaskQueryVariables = Exact<{
  userUploadTaskId: Scalars['ID']['input'];
}>;

export type GQGetUploadsForUserUploadTaskQuery = {
  __typename?: 'Query';
  userUploadTask: {
    __typename?: 'UserUploadTask';
    id: string;
    isDatasetComplete: boolean | null;
    measurementTask: {
      __typename?: 'MeasurementTaskFlat';
      id: string;
      statusSimplified: GQTaskWatershedProcessStateSimplified;
    };
    userUploads: {
      __typename?: 'UserUploadConnection';
      edges: Array<{
        __typename?: 'UserUploadEdge';
        node: {
          __typename?: 'UserUpload';
          id: string;
          name: string;
          status: GQUserUploadStatus;
          processingMode: GQUserUploadProcessingMode | null;
          userVisibleErrorMessage: string | null;
          createdAt: Date;
          latestAdeIdForSDIFacilitiesFlow: string | null;
          description: string | null;
          revisionRoot: string | null;
          processingWorkflowId: string | null;
          isBeingValueMapped: boolean;
          actualUserUploadId: string | null;
          associatedUtilityCount: number;
          userUploadKind: GQUserUploadCandidateKind | null;
          userVisibleAttempt: {
            __typename?: 'UserUploadAttempt';
            id: string;
            name: string;
            size: number;
            remoteWritten: boolean;
            createdAt: Date;
            userUploadSource: GQUserUploadSource | null;
            uploader: { __typename?: 'User'; id: string; name: string } | null;
            userUploadedTables: Array<{
              __typename?: 'UserUploadedTable';
              id: string;
              sheetName: string | null;
              sheetIndex: number | null;
              errorMessage: string | null;
              errorType: string | null;
              numRows: number | null;
              status: GQUserUploadedTableStatus;
              schema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              uirSchema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              parseConfig: {
                __typename?: 'UserUploadedTableParseConfig';
                noHeader: boolean;
                skipTopNRows: number;
                skipBottomNRows: number;
                meltIdVars: Array<string | null> | null;
              } | null;
              roboCleanerResult: {
                __typename?: 'UserUploadedTableRoboCleanerResult';
                skipTopNRows: number;
              } | null;
              userReviewMetadata: {
                __typename?: 'UserUploadedTableUserReviewMetadata';
                headerRowIndex: number;
              } | null;
              parentFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              };
              dataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataUnshiftedPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
            }>;
            latestUutsIfRevisionExists: Array<{
              __typename?: 'UserUploadedTable';
              id: string;
              sheetName: string | null;
              sheetIndex: number | null;
              errorMessage: string | null;
              errorType: string | null;
              numRows: number | null;
              status: GQUserUploadedTableStatus;
              schema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              uirSchema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              parseConfig: {
                __typename?: 'UserUploadedTableParseConfig';
                noHeader: boolean;
                skipTopNRows: number;
                skipBottomNRows: number;
                meltIdVars: Array<string | null> | null;
              } | null;
              roboCleanerResult: {
                __typename?: 'UserUploadedTableRoboCleanerResult';
                skipTopNRows: number;
              } | null;
              userReviewMetadata: {
                __typename?: 'UserUploadedTableUserReviewMetadata';
                headerRowIndex: number;
              } | null;
              parentFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              };
              dataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataUnshiftedPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
            }> | null;
          } | null;
          userUploadTask: {
            __typename?: 'UserUploadTask';
            id: string;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
            };
          } | null;
          transformsIncludingDeleted: Array<{
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            deletedAt: Date | null;
            sql: string;
            transformTarget: GQCustomerTargetSchemaTransformTarget;
            businessActivityTypeName: string | null;
            businessActivityTypeVersionId: string | null;
            businessActivityTypeVersion: {
              __typename?: 'BusinessActivityTypeVersion';
              id: string;
              name: string;
            } | null;
            runs: Array<{
              __typename?: 'CustomerTargetSchemaTransformRun';
              id: string;
              sql: string;
              error: string | null;
              createdAt: Date;
              transform: {
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                transformTarget: GQCustomerTargetSchemaTransformTarget;
                businessActivityTypeName: string | null;
              };
              input: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
                category: GQFileCategory;
                revisionRootFileMetadata: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                } | null;
              };
              output: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
              standardizedOutput: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
              warnings: Array<{
                __typename?: 'ValidationWarning';
                message: string;
                blocking: boolean;
              }>;
              runner: { __typename?: 'User'; id: string; name: string };
            }>;
          }>;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type GQGetCustomizationTypeSelectorSectionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetCustomizationTypeSelectorSectionsQuery = {
  __typename?: 'Query';
  methodologyCustomizationDisplaySections: Array<{
    __typename?: 'MethodologyCustomizationDisplaySection';
    sectionName: string;
    sectionRows: Array<{
      __typename?: 'MethodologyCustomizationDisplayRow';
      customizationTypeId: string;
      typeConfig: {
        __typename?: 'MethodologyCustomizationTypeConfig';
        id: string;
        displayName: string;
        description: string;
        iconName: GQMethodologyCustomizationDisplayConfigIcon;
      };
    }>;
  }>;
};

export type GQMethodologyCustomizationRowsForLibraryQueryVariables = Exact<{
  customizationTypeId: Scalars['String']['input'];
}>;

export type GQMethodologyCustomizationRowsForLibraryQuery = {
  __typename?: 'Query';
  methodologyCustomizationRowsForOrg: {
    __typename?: 'MethodologyCustomizationBundle';
    id: string;
    revisionCreatedAt: Date | null;
    orgName: string;
    referenceDataRevisionRows: Array<{
      __typename?: 'ReferenceDataRevisionRow';
      id: string;
      rowFields: any;
      citationName: string;
      citationNotes: string | null;
      citationUrl: string | null;
      citationSlug: string;
      isCustomerProvided: boolean | null;
    }>;
  };
};

export type GQMethodologyCustomizationTypeConfigQueryVariables = Exact<{
  customizationTypeId: Scalars['String']['input'];
}>;

export type GQMethodologyCustomizationTypeConfigQuery = {
  __typename?: 'Query';
  methodologyCustomizationTypeConfig: {
    __typename?: 'MethodologyCustomizationTypeConfig';
    id: string;
    displayName: string;
    description: string;
    iconName: GQMethodologyCustomizationDisplayConfigIcon;
    globalReferenceDataSources: Array<string>;
    quantityColumnName: string;
    globalColumnsToCustomerUploadedColumns: any;
    displayColumns: Array<{
      __typename?: 'MethodologyCustomizationColumnDisplayFormat';
      parquetColumnName: string;
      displayName: string;
      description: string | null;
      type: string;
    }>;
  };
};

export type GQMethodologyCustomizationValueMappingRowsQueryVariables = Exact<{
  valueMappingConfigId: Scalars['String']['input'];
}>;

export type GQMethodologyCustomizationValueMappingRowsQuery = {
  __typename?: 'Query';
  valueMappingWithLatestStatus: {
    __typename?: 'ValueMapping';
    id: string;
    revisionState: GQReferenceDataRevisionState | null;
    valueMappingRows: Array<{
      __typename?: 'ValueMappingRow';
      id: string;
      baseRowJson: string;
    }>;
  };
};

export type GQPublishMethodologyCustomizationValueMappingArtifactMutationVariables =
  Exact<{
    valueMappingConfigId: Scalars['ID']['input'];
  }>;

export type GQPublishMethodologyCustomizationValueMappingArtifactMutation = {
  __typename?: 'Mutation';
  publishValueMappingArtifact: {
    __typename?: 'PublishValueMappingArtifactPayload';
    publishedArtifactRevisionId: string;
  } | null;
};

export type GQGenerateOverrideReferenceDataRevisionMutationVariables = Exact<{
  input: GQGenerateOverrideReferenceDataRevisionInput;
}>;

export type GQGenerateOverrideReferenceDataRevisionMutation = {
  __typename?: 'Mutation';
  generateOverrideReferenceDataRevision: {
    __typename?: 'GenerateOverrideReferenceDataRevisionPayload';
    jobId: string;
  };
};

export type GQMethodologyCustomizationMissingSourceDataQueryVariables = Exact<{
  configId: Scalars['String']['input'];
}>;

export type GQMethodologyCustomizationMissingSourceDataQuery = {
  __typename?: 'Query';
  methodologyCustomizationMappingConfig: {
    __typename?: 'MethodologyCustomizationMappingConfig';
    id: string;
    missingSourceDataConfig: {
      __typename?: 'MethodologyCustomizationMissingSourceDataConfig';
      message: string;
      buttonLabel: string;
      imageName: GQMethodologyCustomizationMissingDataSourceConfigImage;
    };
  };
};

export type GQGetMappingTabSidebarSectionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetMappingTabSidebarSectionsQuery = {
  __typename?: 'Query';
  methodologyCustomizationDisplaySections: Array<{
    __typename?: 'MethodologyCustomizationDisplaySection';
    sectionName: string;
    sectionRows: Array<{
      __typename?: 'MethodologyCustomizationDisplayRow';
      customizationTypeId: string;
      mappingConfigIds: Array<string>;
      typeConfig: {
        __typename?: 'MethodologyCustomizationTypeConfig';
        id: string;
        displayName: string;
      };
    }>;
  }>;
};

export type GQMethodologyCustomizationValueMappingFootprintPreviewQueryVariables =
  Exact<{
    mappingConfigId: Scalars['String']['input'];
  }>;

export type GQMethodologyCustomizationValueMappingFootprintPreviewQuery = {
  __typename?: 'Query';
  methodologyCustomizationPreviewFootprint: {
    __typename?: 'MethodologyCustomizationFootprintPreviewPayload';
    id: string;
    mappingArtifactCreatedAt: Date | null;
    footprintSnapshot: {
      __typename?: 'FootprintSnapshot';
      id: string;
      createdAt: Date;
    } | null;
  } | null;
};

export type GQUserFieldsForFacilitiesTasksFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  displayName: string;
};

export type GQFacilitiesUserUploadTaskForTasksPageFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  state: GQUserUploadTaskState;
  buildingIds: Array<string>;
  lockState: GQApprovalTargetLockState;
  assignees: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
  }>;
  datasource: { __typename?: 'Datasource'; id: string; name: string };
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    active: boolean;
  };
  approvers: Array<{
    __typename?: 'UserWithApprovalStatus';
    approvalStatus: GQApprovalStatus | null;
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    };
  }>;
  facilitiesPreview: {
    __typename?: 'FacilitiesPreview';
    totalCount: number;
    preview: Array<{
      __typename?: 'Facility';
      buildingName: string;
      id: string;
    }>;
  };
  userUploads: {
    __typename?: 'UserUploadConnection';
    edges: Array<{
      __typename?: 'UserUploadEdge';
      node: { __typename?: 'UserUpload'; id: string } | null;
    } | null>;
  } | null;
};

export type GQMeasurementProjectForConfigureFacilitiesTasksPageFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  active: boolean;
  setupAt: Date | null;
  completedAt: Date | null;
  footprintStatus: GQMeasurementProjectFootprintStatus;
  coverageInterval: YMInterval;
  deadline: Date | null;
  userUploadTasks: {
    __typename?: 'UserUploadTaskConnection';
    edges: Array<{
      __typename?: 'UserUploadTaskEdge';
      node: {
        __typename?: 'UserUploadTask';
        id: string;
        state: GQUserUploadTaskState;
        buildingIds: Array<string>;
        lockState: GQApprovalTargetLockState;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        datasource: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          userCanAccessDetails: boolean | null;
          userCanApproveDatasource: boolean | null;
          userCanManageDatasource: boolean | null;
          isUserConfirmed: boolean | null;
          hasIntegration: boolean;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          };
        };
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          active: boolean;
        };
        approvers: Array<{
          __typename?: 'UserWithApprovalStatus';
          approvalStatus: GQApprovalStatus | null;
          user: {
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          };
        }>;
        facilitiesPreview: {
          __typename?: 'FacilitiesPreview';
          totalCount: number;
          preview: Array<{
            __typename?: 'Facility';
            buildingName: string;
            id: string;
          }>;
        };
        userUploads: {
          __typename?: 'UserUploadConnection';
          edges: Array<{
            __typename?: 'UserUploadEdge';
            node: { __typename?: 'UserUpload'; id: string } | null;
          } | null>;
        } | null;
        issues: {
          __typename?: 'DataIssueConnection';
          edges: Array<{
            __typename?: 'DataIssueEdge';
            node: {
              __typename?: 'DataIssue';
              id: string;
              state: GQDataIssueState;
              title: string;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  };
  measurementTimeline: {
    __typename?: 'MeasurementTimeline';
    id: string;
    kickoff: Date;
    deadline: Date;
  } | null;
  measurementTasks: Array<{
    __typename?: 'MeasurementParentTask';
    taskGroup: string;
    id: string;
    title: string;
    link: string | null;
    lockState: GQApprovalTargetLockState | null;
    isAssignedToWatershed: boolean;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
    subTasks: Array<
      | {
          __typename?: 'MeasurementParentTask';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          lockState: GQApprovalTargetLockState | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          approvers: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
      | {
          __typename?: 'MeasurementTaskFlat';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          lockState: GQApprovalTargetLockState | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          approvers: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
    >;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      materialCsrdStandards: Array<GQCsrdStandard>;
    } | null;
    assignees: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
      didLatestEmailBounce: boolean;
    }>;
    approvers: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    }>;
    object:
      | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
      | {
          __typename: 'DataIssue';
          id: string;
          linkedObject:
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItemQuestion';
                id: string;
                item: {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  } | null;
                };
              }
            | {
                __typename: 'UserUploadTask';
                id: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                };
              }
            | { __typename: 'ValueMappingTask' }
            | null;
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                createdAt: Date;
                isPublished: boolean;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        }
      | { __typename: 'FacilitiesSetup'; id: string }
      | {
          __typename: 'FinancialsReviewGroup';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            };
          }>;
        }
      | {
          __typename: 'FinancialsReviewItem';
          id: string;
          group: {
            __typename: 'FinancialsReviewGroup';
            id: string;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              };
            }>;
          };
        }
      | { __typename: 'FootprintQuestion'; id: string }
      | {
          __typename: 'MeasurementDataMappingTask';
          id: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          };
        }
      | { __typename: 'MeasurementProject'; id: string }
      | {
          __typename: 'MeasurementProjectDataset';
          modelId: string | null;
          isCompleted: boolean;
          id: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          };
        }
      | {
          __typename: 'MeasurementVerificationItem';
          id: string;
          mviStatus: GQMeasurementVerificationItemStatus;
          maybeDataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          } | null;
        }
      | {
          __typename: 'UserUploadTask';
          state: GQUserUploadTaskState;
          id: string;
          approvers: Array<{
            __typename?: 'UserWithApprovalStatus';
            approvalStatus: GQApprovalStatus | null;
            user: {
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            };
          }>;
          datasource: {
            __typename?: 'Datasource';
            hasIntegration: boolean;
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            };
          };
        }
      | { __typename: 'ValueMappingTask'; id: string }
      | { __typename: 'VendorMatchingTask'; id: string };
  }>;
  datasetsWithStages: Array<{
    __typename?: 'DatasetWithStage';
    id: string;
    areAllTasksComplete: boolean;
    areUploadsComplete: boolean;
    isUsableInFootprint: boolean;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      materialCsrdStandards: Array<GQCsrdStandard>;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    } | null;
  }>;
  measurementVerificationItems: {
    __typename?: 'MeasurementVerificationItemConnection';
    edges: Array<{
      __typename?: 'MeasurementVerificationItemEdge';
      node: {
        __typename?: 'MeasurementVerificationItem';
        id: string;
        status: GQMeasurementVerificationItemStatus;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      } | null;
    }>;
  };
  climateProgramProjects: Array<{
    __typename: 'ClimateProgramProject';
    id: string;
    name: string;
    canonicalClimateProgramProject: {
      __typename: 'CanonicalClimateProgramProject';
      id: string;
      measurementObjectCopy: string | null;
      kind: GQClimateProgramProjectKind;
      routeType: string | null;
      name: string;
    } | null;
  }>;
};

export type GQFacilitiesMetadataForFacilitiesTasksFragment = {
  __typename?: 'FacilitiesMetadata';
  buildingsDataset: {
    __typename?: 'Dataset';
    id: string;
    name: string;
    instructions: {
      __typename?: 'InstructionsBundle';
      sdiInstructionsSteps: Array<{
        __typename?: 'SdiInstructionsStep';
        title: string;
        descriptionMd: string;
      }> | null;
      sdiInstructionsFaq: Array<{
        __typename?: 'SdiInstructionsFaqType';
        question: string;
        answerMd: string;
      }> | null;
    } | null;
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      name: string;
      customerTargetSchemaIds: Array<string> | null;
    }>;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
      customerTargetSchemas: Array<{
        __typename?: 'CustomerTargetSchema';
        id: string;
        name: string;
        utilityType: GQBuildingUtilityType | null;
        rank: number;
        latestPublishedVersion: {
          __typename?: 'CustomerTargetSchemaVersion';
          id: string;
          formattingNotesMd: string | null;
          orgDatasetExampleData: Array<{
            __typename?: 'IngestionExampleData';
            id: string;
            title: string;
            datasetId: string | null;
            columns: Array<{
              __typename?: 'IngestionExampleDataColumn';
              fieldName: string;
              title: string;
              description: string;
              examples: Array<string>;
              required: boolean;
              optionalToMap: boolean | null;
              defaultValue: any | null;
            }>;
            rows: Array<{
              __typename?: 'IngestionExampleDataRow';
              fields: Array<{
                __typename?: 'IngestionExampleDataRowField';
                fieldName: string;
                value: string;
              }>;
            }>;
          }>;
          transforms: Array<{
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            businessActivityTypeName: string | null;
            businessActivityTypeVersion: {
              __typename?: 'BusinessActivityTypeVersion';
              id: string;
            } | null;
          }>;
        } | null;
      }>;
    } | null;
  } | null;
  utilitiesDataset: { __typename?: 'Dataset'; id: string; name: string } | null;
};

export type GQOrganizationForConfigureFacilitiesTasksPageFragment = {
  __typename?: 'Organization';
  id: string;
  facilitiesMetadata: {
    __typename?: 'FacilitiesMetadata';
    buildingsDataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      instructions: {
        __typename?: 'InstructionsBundle';
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
        customerTargetSchemaIds: Array<string> | null;
      }>;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
        customerTargetSchemas: Array<{
          __typename?: 'CustomerTargetSchema';
          id: string;
          name: string;
          utilityType: GQBuildingUtilityType | null;
          rank: number;
          latestPublishedVersion: {
            __typename?: 'CustomerTargetSchemaVersion';
            id: string;
            formattingNotesMd: string | null;
            orgDatasetExampleData: Array<{
              __typename?: 'IngestionExampleData';
              id: string;
              title: string;
              datasetId: string | null;
              columns: Array<{
                __typename?: 'IngestionExampleDataColumn';
                fieldName: string;
                title: string;
                description: string;
                examples: Array<string>;
                required: boolean;
                optionalToMap: boolean | null;
                defaultValue: any | null;
              }>;
              rows: Array<{
                __typename?: 'IngestionExampleDataRow';
                fields: Array<{
                  __typename?: 'IngestionExampleDataRowField';
                  fieldName: string;
                  value: string;
                }>;
              }>;
            }>;
            transforms: Array<{
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              businessActivityTypeName: string | null;
              businessActivityTypeVersion: {
                __typename?: 'BusinessActivityTypeVersion';
                id: string;
              } | null;
            }>;
          } | null;
        }>;
      } | null;
    } | null;
    utilitiesDataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
    } | null;
  };
  ingestionCustomFields: Array<{
    __typename?: 'IngestionCustomField';
    bartTagName: string;
    id: string;
    name: string;
    description: string | null;
    type: GQIngestionCustomFieldType;
    possibleValues: Array<string> | null;
    appliesToAllDatasets: boolean;
    createdAt: Date;
    exampleValues: Array<string> | null;
    specificUtilityTypes: Array<GQBuildingUtilityType>;
    ingestionCustomFieldDatasources: Array<{
      __typename?: 'IngestionCustomFieldDatasource';
      id: string;
    }> | null;
    specificDatasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    }>;
  }>;
};

export type GQFacilityPeriodFragment = {
  __typename?: 'BuildingPeriod';
  id: string;
  kind: GQBuildingPeriodKind;
  sizeQuantity: number | null;
  customFieldId: string | null;
  customFieldValue: string | null;
  orgUnitStableId: string | null;
  orgUnitTypeStableId: string | null;
};

export type GQFacilitiesForTasksPageFragment = {
  __typename?: 'Facility';
  id: string;
  buildingName: string;
  streetAddress: string | null;
  streetAddress2: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  sizeUnit: GQBuildingSizeUnit | null;
  startYearMonth: YearMonth | null;
  endYearMonth: YearMonth | null;
  leaseType: GQBuildingLeaseType | null;
  buildingKind: string | null;
  editState: GQBuildingEditState;
  periods: Array<{
    __typename?: 'BuildingPeriod';
    id: string;
    kind: GQBuildingPeriodKind;
    sizeQuantity: number | null;
    customFieldId: string | null;
    customFieldValue: string | null;
    orgUnitStableId: string | null;
    orgUnitTypeStableId: string | null;
  }>;
  tasks: Array<{
    __typename?: 'UserUploadTask';
    id: string;
    datasource: {
      __typename?: 'Datasource';
      id: string;
      name: string;
      dataset: { __typename?: 'Dataset'; id: string; name: string };
    };
  }>;
};

export type GQOrgUnitTypeWithOrgUnitsForFacilitiesFragment = {
  __typename?: 'OrgUnitType';
  id: string;
  name: string;
  stableId: string;
  orgUnits: Array<{
    __typename?: 'OrgUnit';
    id: string;
    name: string;
    stableId: string;
  }>;
};

export type GQConfigureFacilitiesTasksPageQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  filter: InputMaybe<Array<GQFacilitiesFilterInput> | GQFacilitiesFilterInput>;
  sort: InputMaybe<Array<GQFacilitiesSortInput> | GQFacilitiesSortInput>;
  search: InputMaybe<Scalars['String']['input']>;
  canonicalDatasetKind: GQCanonicalDatasetKind;
  orgStructureEnabled: Scalars['Boolean']['input'];
}>;

export type GQConfigureFacilitiesTasksPageQuery = {
  __typename?: 'Query';
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    active: boolean;
    setupAt: Date | null;
    completedAt: Date | null;
    footprintStatus: GQMeasurementProjectFootprintStatus;
    coverageInterval: YMInterval;
    deadline: Date | null;
    userUploadTasks: {
      __typename?: 'UserUploadTaskConnection';
      edges: Array<{
        __typename?: 'UserUploadTaskEdge';
        node: {
          __typename?: 'UserUploadTask';
          id: string;
          state: GQUserUploadTaskState;
          buildingIds: Array<string>;
          lockState: GQApprovalTargetLockState;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          datasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            userCanAccessDetails: boolean | null;
            userCanApproveDatasource: boolean | null;
            userCanManageDatasource: boolean | null;
            isUserConfirmed: boolean | null;
            hasIntegration: boolean;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            };
          };
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            active: boolean;
          };
          approvers: Array<{
            __typename?: 'UserWithApprovalStatus';
            approvalStatus: GQApprovalStatus | null;
            user: {
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            };
          }>;
          facilitiesPreview: {
            __typename?: 'FacilitiesPreview';
            totalCount: number;
            preview: Array<{
              __typename?: 'Facility';
              buildingName: string;
              id: string;
            }>;
          };
          userUploads: {
            __typename?: 'UserUploadConnection';
            edges: Array<{
              __typename?: 'UserUploadEdge';
              node: { __typename?: 'UserUpload'; id: string } | null;
            } | null>;
          } | null;
          issues: {
            __typename?: 'DataIssueConnection';
            edges: Array<{
              __typename?: 'DataIssueEdge';
              node: {
                __typename?: 'DataIssue';
                id: string;
                state: GQDataIssueState;
                title: string;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
    measurementTimeline: {
      __typename?: 'MeasurementTimeline';
      id: string;
      kickoff: Date;
      deadline: Date;
    } | null;
    measurementTasks: Array<{
      __typename?: 'MeasurementParentTask';
      taskGroup: string;
      id: string;
      title: string;
      link: string | null;
      lockState: GQApprovalTargetLockState | null;
      isAssignedToWatershed: boolean;
      statusSimplified: GQTaskWatershedProcessStateSimplified;
      subTasks: Array<
        | {
            __typename?: 'MeasurementParentTask';
            taskGroup: string;
            id: string;
            title: string;
            link: string | null;
            lockState: GQApprovalTargetLockState | null;
            isAssignedToWatershed: boolean;
            statusSimplified: GQTaskWatershedProcessStateSimplified;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            } | null;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
              didLatestEmailBounce: boolean;
            }>;
            approvers: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            object:
              | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
              | {
                  __typename: 'DataIssue';
                  id: string;
                  linkedObject:
                    | {
                        __typename: 'FinancialsReviewItem';
                        id: string;
                        group: {
                          __typename: 'FinancialsReviewGroup';
                          id: string;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        };
                      }
                    | {
                        __typename: 'MeasurementVerificationItemQuestion';
                        id: string;
                        item: {
                          __typename: 'MeasurementVerificationItem';
                          id: string;
                          maybeDataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          } | null;
                        };
                      }
                    | {
                        __typename: 'UserUploadTask';
                        id: string;
                        datasource: {
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        };
                      }
                    | { __typename: 'ValueMappingTask' }
                    | null;
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        createdAt: Date;
                        isPublished: boolean;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                }
              | { __typename: 'FacilitiesSetup'; id: string }
              | {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                }
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | { __typename: 'FootprintQuestion'; id: string }
              | {
                  __typename: 'MeasurementDataMappingTask';
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | { __typename: 'MeasurementProject'; id: string }
              | {
                  __typename: 'MeasurementProjectDataset';
                  modelId: string | null;
                  isCompleted: boolean;
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  mviStatus: GQMeasurementVerificationItemStatus;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  } | null;
                }
              | {
                  __typename: 'UserUploadTask';
                  state: GQUserUploadTaskState;
                  id: string;
                  approvers: Array<{
                    __typename?: 'UserWithApprovalStatus';
                    approvalStatus: GQApprovalStatus | null;
                    user: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                    };
                  }>;
                  datasource: {
                    __typename?: 'Datasource';
                    hasIntegration: boolean;
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      canonicalDataset: {
                        __typename?: 'CanonicalDataset';
                        id: string;
                        kind: GQCanonicalDatasetKind;
                      } | null;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask'; id: string }
              | { __typename: 'VendorMatchingTask'; id: string };
          }
        | {
            __typename?: 'MeasurementTaskFlat';
            taskGroup: string;
            id: string;
            title: string;
            link: string | null;
            lockState: GQApprovalTargetLockState | null;
            isAssignedToWatershed: boolean;
            statusSimplified: GQTaskWatershedProcessStateSimplified;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            } | null;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
              didLatestEmailBounce: boolean;
            }>;
            approvers: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            object:
              | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
              | {
                  __typename: 'DataIssue';
                  id: string;
                  linkedObject:
                    | {
                        __typename: 'FinancialsReviewItem';
                        id: string;
                        group: {
                          __typename: 'FinancialsReviewGroup';
                          id: string;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        };
                      }
                    | {
                        __typename: 'MeasurementVerificationItemQuestion';
                        id: string;
                        item: {
                          __typename: 'MeasurementVerificationItem';
                          id: string;
                          maybeDataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          } | null;
                        };
                      }
                    | {
                        __typename: 'UserUploadTask';
                        id: string;
                        datasource: {
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        };
                      }
                    | { __typename: 'ValueMappingTask' }
                    | null;
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        createdAt: Date;
                        isPublished: boolean;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                }
              | { __typename: 'FacilitiesSetup'; id: string }
              | {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                }
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | { __typename: 'FootprintQuestion'; id: string }
              | {
                  __typename: 'MeasurementDataMappingTask';
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | { __typename: 'MeasurementProject'; id: string }
              | {
                  __typename: 'MeasurementProjectDataset';
                  modelId: string | null;
                  isCompleted: boolean;
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  mviStatus: GQMeasurementVerificationItemStatus;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  } | null;
                }
              | {
                  __typename: 'UserUploadTask';
                  state: GQUserUploadTaskState;
                  id: string;
                  approvers: Array<{
                    __typename?: 'UserWithApprovalStatus';
                    approvalStatus: GQApprovalStatus | null;
                    user: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                    };
                  }>;
                  datasource: {
                    __typename?: 'Datasource';
                    hasIntegration: boolean;
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      canonicalDataset: {
                        __typename?: 'CanonicalDataset';
                        id: string;
                        kind: GQCanonicalDatasetKind;
                      } | null;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask'; id: string }
              | { __typename: 'VendorMatchingTask'; id: string };
          }
      >;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        materialCsrdStandards: Array<GQCsrdStandard>;
      } | null;
      assignees: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
        didLatestEmailBounce: boolean;
      }>;
      approvers: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      }>;
      object:
        | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
        | {
            __typename: 'DataIssue';
            id: string;
            linkedObject:
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItemQuestion';
                  id: string;
                  item: {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    } | null;
                  };
                }
              | {
                  __typename: 'UserUploadTask';
                  id: string;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask' }
              | null;
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  createdAt: Date;
                  isPublished: boolean;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          }
        | { __typename: 'FacilitiesSetup'; id: string }
        | {
            __typename: 'FinancialsReviewGroup';
            id: string;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              };
            }>;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            group: {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            };
          }
        | { __typename: 'FootprintQuestion'; id: string }
        | {
            __typename: 'MeasurementDataMappingTask';
            id: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            };
          }
        | { __typename: 'MeasurementProject'; id: string }
        | {
            __typename: 'MeasurementProjectDataset';
            modelId: string | null;
            isCompleted: boolean;
            id: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            };
          }
        | {
            __typename: 'MeasurementVerificationItem';
            id: string;
            mviStatus: GQMeasurementVerificationItemStatus;
            maybeDataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            } | null;
          }
        | {
            __typename: 'UserUploadTask';
            state: GQUserUploadTaskState;
            id: string;
            approvers: Array<{
              __typename?: 'UserWithApprovalStatus';
              approvalStatus: GQApprovalStatus | null;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              };
            }>;
            datasource: {
              __typename?: 'Datasource';
              hasIntegration: boolean;
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
          }
        | { __typename: 'ValueMappingTask'; id: string }
        | { __typename: 'VendorMatchingTask'; id: string };
    }>;
    datasetsWithStages: Array<{
      __typename?: 'DatasetWithStage';
      id: string;
      areAllTasksComplete: boolean;
      areUploadsComplete: boolean;
      isUsableInFootprint: boolean;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        materialCsrdStandards: Array<GQCsrdStandard>;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      } | null;
    }>;
    measurementVerificationItems: {
      __typename?: 'MeasurementVerificationItemConnection';
      edges: Array<{
        __typename?: 'MeasurementVerificationItemEdge';
        node: {
          __typename?: 'MeasurementVerificationItem';
          id: string;
          status: GQMeasurementVerificationItemStatus;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          } | null;
        } | null;
      }>;
    };
    climateProgramProjects: Array<{
      __typename: 'ClimateProgramProject';
      id: string;
      name: string;
      canonicalClimateProgramProject: {
        __typename: 'CanonicalClimateProgramProject';
        id: string;
        measurementObjectCopy: string | null;
        kind: GQClimateProgramProjectKind;
        routeType: string | null;
        name: string;
      } | null;
    }>;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    facilitiesMetadata: {
      __typename?: 'FacilitiesMetadata';
      buildingsDataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        instructions: {
          __typename?: 'InstructionsBundle';
          sdiInstructionsSteps: Array<{
            __typename?: 'SdiInstructionsStep';
            title: string;
            descriptionMd: string;
          }> | null;
          sdiInstructionsFaq: Array<{
            __typename?: 'SdiInstructionsFaqType';
            question: string;
            answerMd: string;
          }> | null;
        } | null;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          customerTargetSchemaIds: Array<string> | null;
        }>;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
          customerTargetSchemas: Array<{
            __typename?: 'CustomerTargetSchema';
            id: string;
            name: string;
            utilityType: GQBuildingUtilityType | null;
            rank: number;
            latestPublishedVersion: {
              __typename?: 'CustomerTargetSchemaVersion';
              id: string;
              formattingNotesMd: string | null;
              orgDatasetExampleData: Array<{
                __typename?: 'IngestionExampleData';
                id: string;
                title: string;
                datasetId: string | null;
                columns: Array<{
                  __typename?: 'IngestionExampleDataColumn';
                  fieldName: string;
                  title: string;
                  description: string;
                  examples: Array<string>;
                  required: boolean;
                  optionalToMap: boolean | null;
                  defaultValue: any | null;
                }>;
                rows: Array<{
                  __typename?: 'IngestionExampleDataRow';
                  fields: Array<{
                    __typename?: 'IngestionExampleDataRowField';
                    fieldName: string;
                    value: string;
                  }>;
                }>;
              }>;
              transforms: Array<{
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                businessActivityTypeName: string | null;
                businessActivityTypeVersion: {
                  __typename?: 'BusinessActivityTypeVersion';
                  id: string;
                } | null;
              }>;
            } | null;
          }>;
        } | null;
      } | null;
      utilitiesDataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
      } | null;
    };
    ingestionCustomFields: Array<{
      __typename?: 'IngestionCustomField';
      bartTagName: string;
      id: string;
      name: string;
      description: string | null;
      type: GQIngestionCustomFieldType;
      possibleValues: Array<string> | null;
      appliesToAllDatasets: boolean;
      createdAt: Date;
      exampleValues: Array<string> | null;
      specificUtilityTypes: Array<GQBuildingUtilityType>;
      ingestionCustomFieldDatasources: Array<{
        __typename?: 'IngestionCustomFieldDatasource';
        id: string;
      }> | null;
      specificDatasets: Array<{
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      }>;
    }>;
  };
  facilities: {
    __typename?: 'FacilityConnection';
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      totalRowCount: number | null;
    };
    edges: Array<{
      __typename?: 'FacilityEdge';
      node: {
        __typename?: 'Facility';
        id: string;
        buildingName: string;
        streetAddress: string | null;
        streetAddress2: string | null;
        city: string | null;
        state: string | null;
        country: string | null;
        sizeUnit: GQBuildingSizeUnit | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        leaseType: GQBuildingLeaseType | null;
        buildingKind: string | null;
        editState: GQBuildingEditState;
        periods: Array<{
          __typename?: 'BuildingPeriod';
          id: string;
          kind: GQBuildingPeriodKind;
          sizeQuantity: number | null;
          customFieldId: string | null;
          customFieldValue: string | null;
          orgUnitStableId: string | null;
          orgUnitTypeStableId: string | null;
        }>;
        tasks: Array<{
          __typename?: 'UserUploadTask';
          id: string;
          datasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: { __typename?: 'Dataset'; id: string; name: string };
          };
        }>;
      } | null;
    } | null>;
  };
  latestOrgUnitTypesForCanonicalDatasetKind?: Array<{
    __typename?: 'OrgUnitType';
    id: string;
    name: string;
    stableId: string;
    orgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      stableId: string;
    }>;
  }>;
};

export type GQAssignFacilitiesToTasksMutationVariables = Exact<{
  input: GQAssignFacilitiesToTasksInput;
}>;

export type GQAssignFacilitiesToTasksMutation = {
  __typename?: 'Mutation';
  assignFacilitiesToTasks: {
    __typename?: 'AssignFacilitiesToTasksPayload';
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      setupAt: Date | null;
      completedAt: Date | null;
      footprintStatus: GQMeasurementProjectFootprintStatus;
      coverageInterval: YMInterval;
      deadline: Date | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            buildingIds: Array<string>;
            lockState: GQApprovalTargetLockState;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              userCanAccessDetails: boolean | null;
              userCanApproveDatasource: boolean | null;
              userCanManageDatasource: boolean | null;
              isUserConfirmed: boolean | null;
              hasIntegration: boolean;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              active: boolean;
            };
            approvers: Array<{
              __typename?: 'UserWithApprovalStatus';
              approvalStatus: GQApprovalStatus | null;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              };
            }>;
            facilitiesPreview: {
              __typename?: 'FacilitiesPreview';
              totalCount: number;
              preview: Array<{
                __typename?: 'Facility';
                buildingName: string;
                id: string;
              }>;
            };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: { __typename?: 'UserUpload'; id: string } | null;
              } | null>;
            } | null;
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  state: GQDataIssueState;
                  title: string;
                } | null;
              } | null>;
            } | null;
          } | null;
        } | null>;
      };
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        lockState: GQApprovalTargetLockState | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          didLatestEmailBounce: boolean;
        }>;
        approvers: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
        areUploadsComplete: boolean;
        isUsableInFootprint: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          } | null;
        }>;
      };
      climateProgramProjects: Array<{
        __typename: 'ClimateProgramProject';
        id: string;
        name: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          measurementObjectCopy: string | null;
          kind: GQClimateProgramProjectKind;
          routeType: string | null;
          name: string;
        } | null;
      }>;
    };
    facilitiesAssigned: Array<{
      __typename?: 'Facility';
      id: string;
      buildingName: string;
      streetAddress: string | null;
      streetAddress2: string | null;
      city: string | null;
      state: string | null;
      country: string | null;
      sizeUnit: GQBuildingSizeUnit | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      editState: GQBuildingEditState;
      postalCode: string | null;
      buildingKind: string | null;
      buildingSubkind: string | null;
      leaseType: GQBuildingLeaseType | null;
      buildingUniqueId: string;
      isWithoutNaturalGas: boolean | null;
      isWithoutRefrigerants: boolean | null;
      periods: Array<{
        __typename?: 'BuildingPeriod';
        id: string;
        kind: GQBuildingPeriodKind;
        sizeQuantity: number | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        partialCleanPowerPercent: number | null;
        cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
        powerUsageEffectiveness: number | null;
        isBuildingEmpty: boolean | null;
        customFieldValue: string | null;
        customFieldId: string | null;
        orgUnitTypeStableId: string | null;
        orgUnitStableId: string | null;
      }>;
      latestApprovedVersion: {
        __typename?: 'BuildingForDiff';
        id: string | null;
        streetAddress: string | null;
        streetAddress2: string | null;
        postalCode: string | null;
        city: string | null;
        state: string | null;
        country: string | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        sizeUnit: GQBuildingSizeUnit | null;
        buildingKind: string | null;
        buildingSubkind: string | null;
        leaseType: GQBuildingLeaseType | null;
        buildingName: string;
        buildingUniqueId: string;
        isWithoutNaturalGas: boolean | null;
        isWithoutRefrigerants: boolean | null;
        periods: Array<{
          __typename?: 'BuildingPeriodForDiff';
          kind: GQBuildingPeriodKind;
          sizeQuantity: number | null;
          startYearMonth: YearMonth | null;
          endYearMonth: YearMonth | null;
          partialCleanPowerPercent: number | null;
          cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
          powerUsageEffectiveness: number | null;
          isBuildingEmpty: boolean | null;
          customFieldValue: string | null;
          customFieldId: string | null;
          orgUnitTypeStableId: string | null;
          orgUnitStableId: string | null;
        }>;
      } | null;
    }>;
    facilitiesRequiringConfirmation: Array<{
      __typename?: 'Facility';
      id: string;
      buildingName: string;
    }>;
    lockedDestinationTasks: Array<{
      __typename?: 'UserUploadTask';
      id: string;
      lockState: GQApprovalTargetLockState;
      datasource: { __typename?: 'Datasource'; id: string; name: string };
    }>;
    lockedSourceTasks: Array<{
      __typename?: 'UserUploadTask';
      id: string;
      lockState: GQApprovalTargetLockState;
      datasource: { __typename?: 'Datasource'; id: string; name: string };
    }>;
  };
};

export type GQDeleteBuildingsForConfigureFacilitiesMutationVariables = Exact<{
  input: GQDeleteBuildingsInput;
}>;

export type GQDeleteBuildingsForConfigureFacilitiesMutation = {
  __typename?: 'Mutation';
  deleteBuildings: {
    __typename?: 'DeleteBuildingsPayload';
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      setupAt: Date | null;
      completedAt: Date | null;
      footprintStatus: GQMeasurementProjectFootprintStatus;
      coverageInterval: YMInterval;
      deadline: Date | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            buildingIds: Array<string>;
            lockState: GQApprovalTargetLockState;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              userCanAccessDetails: boolean | null;
              userCanApproveDatasource: boolean | null;
              userCanManageDatasource: boolean | null;
              isUserConfirmed: boolean | null;
              hasIntegration: boolean;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              active: boolean;
            };
            approvers: Array<{
              __typename?: 'UserWithApprovalStatus';
              approvalStatus: GQApprovalStatus | null;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              };
            }>;
            facilitiesPreview: {
              __typename?: 'FacilitiesPreview';
              totalCount: number;
              preview: Array<{
                __typename?: 'Facility';
                buildingName: string;
                id: string;
              }>;
            };
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: { __typename?: 'UserUpload'; id: string } | null;
              } | null>;
            } | null;
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  state: GQDataIssueState;
                  title: string;
                } | null;
              } | null>;
            } | null;
          } | null;
        } | null>;
      };
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        lockState: GQApprovalTargetLockState | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          didLatestEmailBounce: boolean;
        }>;
        approvers: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
        areUploadsComplete: boolean;
        isUsableInFootprint: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          } | null;
        }>;
      };
      climateProgramProjects: Array<{
        __typename: 'ClimateProgramProject';
        id: string;
        name: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          measurementObjectCopy: string | null;
          kind: GQClimateProgramProjectKind;
          routeType: string | null;
          name: string;
        } | null;
      }>;
    };
  };
};

export type GQFacilitiesDataSummaryQueryVariables = Exact<{
  input: GQBiActivityQueryContextInput;
}>;

export type GQFacilitiesDataSummaryQuery = {
  __typename?: 'Query';
  biPostgresToActivityData: {
    __typename?: 'BiPostgresToActivityDataPayload';
    isFacilitiesDataStale: boolean;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      coverageInterval: YMInterval;
    }>;
  };
};

export type GQCheckAndGenerateFacilitiesBartsMutationVariables = Exact<{
  input: GQCheckAndGenerateFacilitiesBartsInput;
}>;

export type GQCheckAndGenerateFacilitiesBartsMutation = {
  __typename?: 'Mutation';
  checkAndGenerateFacilitiesBarts: {
    __typename?: 'FacilitiesSdiPayload';
    workflowId: string;
  };
};

export type GQGetBuildingTemplateDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetBuildingTemplateDataQuery = {
  __typename?: 'Query';
  buildingTemplateDataUntyped: any | null;
};

export type GQDeleteBuildingsForFacilitiesManagementMutationVariables = Exact<{
  input: GQDeleteBuildingsInput;
}>;

export type GQDeleteBuildingsForFacilitiesManagementMutation = {
  __typename?: 'Mutation';
  deleteBuildings: {
    __typename?: 'DeleteBuildingsPayload';
    organization: {
      __typename?: 'Organization';
      id: string;
      buildingsUntyped: Array<{
        __typename?: 'BuildingUntyped';
        id: string;
        data: any;
      }>;
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      setupAt: Date | null;
      completedAt: Date | null;
      footprintStatus: GQMeasurementProjectFootprintStatus;
      coverageInterval: YMInterval;
      deadline: Date | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            buildingIds: Array<string>;
            lockState: GQApprovalTargetLockState;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  title: string;
                  description: string | null;
                  state: GQDataIssueState;
                  createdAt: Date;
                  isCustomerInitiated: boolean;
                  measurementProject: {
                    __typename?: 'MeasurementProject';
                    id: string;
                    completedAt: Date | null;
                  };
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        message: string;
                        isPublished: boolean;
                        createdAt: Date;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            } | null;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              isApi: boolean | null;
              userCanAccessDetails: boolean | null;
              userCanApproveDatasource: boolean | null;
              userCanManageDatasource: boolean | null;
              isUserConfirmed: boolean | null;
              hasIntegration: boolean;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              active: boolean;
            };
          } | null;
        } | null>;
      };
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        lockState: GQApprovalTargetLockState | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          didLatestEmailBounce: boolean;
        }>;
        approvers: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
        areUploadsComplete: boolean;
        isUsableInFootprint: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          } | null;
        }>;
      };
      climateProgramProjects: Array<{
        __typename: 'ClimateProgramProject';
        id: string;
        name: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          measurementObjectCopy: string | null;
          kind: GQClimateProgramProjectKind;
          routeType: string | null;
          name: string;
        } | null;
      }>;
    };
  };
};

export type GQReassignFacilitiesTaskMutationVariables = Exact<{
  input: GQReassignFacilitiesTaskInput;
}>;

export type GQReassignFacilitiesTaskMutation = {
  __typename?: 'Mutation';
  reassignFacilitiesTask: {
    __typename?: 'ReassignFacilitiesTaskPayload';
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      setupAt: Date | null;
      completedAt: Date | null;
      footprintStatus: GQMeasurementProjectFootprintStatus;
      coverageInterval: YMInterval;
      deadline: Date | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            state: GQUserUploadTaskState;
            buildingIds: Array<string>;
            lockState: GQApprovalTargetLockState;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  title: string;
                  description: string | null;
                  state: GQDataIssueState;
                  createdAt: Date;
                  isCustomerInitiated: boolean;
                  measurementProject: {
                    __typename?: 'MeasurementProject';
                    id: string;
                    completedAt: Date | null;
                  };
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        message: string;
                        isPublished: boolean;
                        createdAt: Date;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            } | null;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              isApi: boolean | null;
              userCanAccessDetails: boolean | null;
              userCanApproveDatasource: boolean | null;
              userCanManageDatasource: boolean | null;
              isUserConfirmed: boolean | null;
              hasIntegration: boolean;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              active: boolean;
            };
          } | null;
        } | null>;
      };
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        lockState: GQApprovalTargetLockState | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          didLatestEmailBounce: boolean;
        }>;
        approvers: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
        areUploadsComplete: boolean;
        isUsableInFootprint: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          } | null;
        }>;
      };
      climateProgramProjects: Array<{
        __typename: 'ClimateProgramProject';
        id: string;
        name: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          measurementObjectCopy: string | null;
          kind: GQClimateProgramProjectKind;
          routeType: string | null;
          name: string;
        } | null;
      }>;
    };
  };
};

export type GQBatchSetCustomFieldForBuildingsMutationVariables = Exact<{
  input: GQBatchSetCustomFieldForBuildingsInput;
}>;

export type GQBatchSetCustomFieldForBuildingsMutation = {
  __typename?: 'Mutation';
  batchSetCustomFieldForBuildings: {
    __typename?: 'BatchSetCustomFieldForBuildingsPayload';
    buildingsUntyped: Array<{
      __typename?: 'BuildingUntyped';
      id: string;
      data: any;
    }>;
  };
};

export type GQBatchUnsetCustomFieldForBuildingsMutationVariables = Exact<{
  input: GQBatchUnsetCustomFieldForBuildingsInput;
}>;

export type GQBatchUnsetCustomFieldForBuildingsMutation = {
  __typename?: 'Mutation';
  batchUnsetCustomFieldForBuildings: {
    __typename?: 'BatchUnsetCustomFieldForBuildingsPayload';
    buildingsUntyped: Array<{
      __typename?: 'BuildingUntyped';
      id: string;
      data: any;
    }>;
  };
};

export type GQUpdateBuildingsMutationVariables = Exact<{
  input: GQUpdateBuildingsInput;
}>;

export type GQUpdateBuildingsMutation = {
  __typename?: 'Mutation';
  updateBuildings: {
    __typename?: 'UpdateBuildingsPayload';
    buildingsUntyped: Array<{
      __typename?: 'BuildingUntyped';
      id: string;
      data: any;
    }>;
  };
};

export type GQUserForFacilitiesImportFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  displayName: string;
  email: string;
};

export type GQOrganizationFieldsForFacilitiesImportFragment = {
  __typename?: 'Organization';
  id: string;
  users: {
    __typename?: 'UserConnection';
    edges: Array<{
      __typename?: 'UserEdge';
      node: {
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
        email: string;
      } | null;
    } | null>;
  } | null;
};

export type GQOrganizationUsersForFacilitiesImportQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQOrganizationUsersForFacilitiesImportQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          email: string;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQBulkCreateUsersForFacilitiesMutationVariables = Exact<{
  input: GQBulkCreateUsersForFacilitiesInput;
}>;

export type GQBulkCreateUsersForFacilitiesMutation = {
  __typename?: 'Mutation';
  bulkCreateUsersForFacilities: {
    __typename?: 'BulkCreateUsersForFacilitiesPayload';
    users: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
      email: string;
    }>;
    organization: {
      __typename?: 'Organization';
      id: string;
      users: {
        __typename?: 'UserConnection';
        edges: Array<{
          __typename?: 'UserEdge';
          node: {
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            email: string;
          } | null;
        } | null>;
      } | null;
    };
  };
};

export type GQFacilitiesUserUploadTaskFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  state: GQUserUploadTaskState;
  buildingIds: Array<string>;
  lockState: GQApprovalTargetLockState;
  assignees: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
  }>;
  issues: {
    __typename?: 'DataIssueConnection';
    edges: Array<{
      __typename?: 'DataIssueEdge';
      node: {
        __typename?: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        createdAt: Date;
        isCustomerInitiated: boolean;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          completedAt: Date | null;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              isPublished: boolean;
              createdAt: Date;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
  datasource: {
    __typename?: 'Datasource';
    id: string;
    name: string;
    isApi: boolean | null;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    };
  };
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    active: boolean;
  };
};

export type GQMeasurementProjectForManageFacilitiesPageFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  active: boolean;
  setupAt: Date | null;
  completedAt: Date | null;
  footprintStatus: GQMeasurementProjectFootprintStatus;
  coverageInterval: YMInterval;
  deadline: Date | null;
  userUploadTasks: {
    __typename?: 'UserUploadTaskConnection';
    edges: Array<{
      __typename?: 'UserUploadTaskEdge';
      node: {
        __typename?: 'UserUploadTask';
        id: string;
        state: GQUserUploadTaskState;
        buildingIds: Array<string>;
        lockState: GQApprovalTargetLockState;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        issues: {
          __typename?: 'DataIssueConnection';
          edges: Array<{
            __typename?: 'DataIssueEdge';
            node: {
              __typename?: 'DataIssue';
              id: string;
              title: string;
              description: string | null;
              state: GQDataIssueState;
              createdAt: Date;
              isCustomerInitiated: boolean;
              measurementProject: {
                __typename?: 'MeasurementProject';
                id: string;
                completedAt: Date | null;
              };
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    message: string;
                    isPublished: boolean;
                    createdAt: Date;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          name: string;
                          displayName: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            } | null;
          } | null>;
        } | null;
        datasource: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          isApi: boolean | null;
          userCanAccessDetails: boolean | null;
          userCanApproveDatasource: boolean | null;
          userCanManageDatasource: boolean | null;
          isUserConfirmed: boolean | null;
          hasIntegration: boolean;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          };
        };
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          active: boolean;
        };
      } | null;
    } | null>;
  };
  measurementTimeline: {
    __typename?: 'MeasurementTimeline';
    id: string;
    kickoff: Date;
    deadline: Date;
  } | null;
  measurementTasks: Array<{
    __typename?: 'MeasurementParentTask';
    taskGroup: string;
    id: string;
    title: string;
    link: string | null;
    lockState: GQApprovalTargetLockState | null;
    isAssignedToWatershed: boolean;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
    subTasks: Array<
      | {
          __typename?: 'MeasurementParentTask';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          lockState: GQApprovalTargetLockState | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          approvers: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
      | {
          __typename?: 'MeasurementTaskFlat';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          lockState: GQApprovalTargetLockState | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          approvers: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
    >;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      materialCsrdStandards: Array<GQCsrdStandard>;
    } | null;
    assignees: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
      didLatestEmailBounce: boolean;
    }>;
    approvers: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    }>;
    object:
      | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
      | {
          __typename: 'DataIssue';
          id: string;
          linkedObject:
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItemQuestion';
                id: string;
                item: {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  } | null;
                };
              }
            | {
                __typename: 'UserUploadTask';
                id: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                };
              }
            | { __typename: 'ValueMappingTask' }
            | null;
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                createdAt: Date;
                isPublished: boolean;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        }
      | { __typename: 'FacilitiesSetup'; id: string }
      | {
          __typename: 'FinancialsReviewGroup';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            };
          }>;
        }
      | {
          __typename: 'FinancialsReviewItem';
          id: string;
          group: {
            __typename: 'FinancialsReviewGroup';
            id: string;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              };
            }>;
          };
        }
      | { __typename: 'FootprintQuestion'; id: string }
      | {
          __typename: 'MeasurementDataMappingTask';
          id: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          };
        }
      | { __typename: 'MeasurementProject'; id: string }
      | {
          __typename: 'MeasurementProjectDataset';
          modelId: string | null;
          isCompleted: boolean;
          id: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          };
        }
      | {
          __typename: 'MeasurementVerificationItem';
          id: string;
          mviStatus: GQMeasurementVerificationItemStatus;
          maybeDataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          } | null;
        }
      | {
          __typename: 'UserUploadTask';
          state: GQUserUploadTaskState;
          id: string;
          approvers: Array<{
            __typename?: 'UserWithApprovalStatus';
            approvalStatus: GQApprovalStatus | null;
            user: {
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            };
          }>;
          datasource: {
            __typename?: 'Datasource';
            hasIntegration: boolean;
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            };
          };
        }
      | { __typename: 'ValueMappingTask'; id: string }
      | { __typename: 'VendorMatchingTask'; id: string };
  }>;
  datasetsWithStages: Array<{
    __typename?: 'DatasetWithStage';
    id: string;
    areAllTasksComplete: boolean;
    areUploadsComplete: boolean;
    isUsableInFootprint: boolean;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      materialCsrdStandards: Array<GQCsrdStandard>;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    } | null;
  }>;
  measurementVerificationItems: {
    __typename?: 'MeasurementVerificationItemConnection';
    edges: Array<{
      __typename?: 'MeasurementVerificationItemEdge';
      node: {
        __typename?: 'MeasurementVerificationItem';
        id: string;
        status: GQMeasurementVerificationItemStatus;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      } | null;
    }>;
  };
  climateProgramProjects: Array<{
    __typename: 'ClimateProgramProject';
    id: string;
    name: string;
    canonicalClimateProgramProject: {
      __typename: 'CanonicalClimateProgramProject';
      id: string;
      measurementObjectCopy: string | null;
      kind: GQClimateProgramProjectKind;
      routeType: string | null;
      name: string;
    } | null;
  }>;
};

export type GQCustomFieldForBuildingsGridPageFragment = {
  __typename?: 'IngestionCustomField';
  id: string;
  name: string;
  description: string | null;
  type: GQIngestionCustomFieldType;
  possibleValues: Array<string> | null;
  appliesToAllDatasets: boolean;
  specificUtilityTypes: Array<GQBuildingUtilityType>;
  specificDatasets: Array<{ __typename?: 'Dataset'; id: string }>;
};

export type GQOrganizationCustomFieldsForManagePageFragment = {
  __typename?: 'Organization';
  id: string;
  ingestionCustomFields: Array<{
    __typename?: 'IngestionCustomField';
    bartTagName: string;
    id: string;
    name: string;
    description: string | null;
    type: GQIngestionCustomFieldType;
    possibleValues: Array<string> | null;
    appliesToAllDatasets: boolean;
    createdAt: Date;
    exampleValues: Array<string> | null;
    specificUtilityTypes: Array<GQBuildingUtilityType>;
    ingestionCustomFieldDatasources: Array<{
      __typename?: 'IngestionCustomFieldDatasource';
      id: string;
    }> | null;
    specificDatasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    }>;
  }>;
  datasets: Array<{
    __typename?: 'Dataset';
    id: string;
    name: string;
    instructions: {
      __typename?: 'InstructionsBundle';
      sdiInstructionsSteps: Array<{
        __typename?: 'SdiInstructionsStep';
        title: string;
        descriptionMd: string;
      }> | null;
      sdiInstructionsFaq: Array<{
        __typename?: 'SdiInstructionsFaqType';
        question: string;
        answerMd: string;
      }> | null;
    } | null;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
      customerTargetSchemas: Array<{
        __typename?: 'CustomerTargetSchema';
        id: string;
        name: string;
        utilityType: GQBuildingUtilityType | null;
        rank: number;
        latestPublishedVersion: {
          __typename?: 'CustomerTargetSchemaVersion';
          id: string;
          formattingNotesMd: string | null;
          orgDatasetExampleData: Array<{
            __typename?: 'IngestionExampleData';
            id: string;
            title: string;
            datasetId: string | null;
            columns: Array<{
              __typename?: 'IngestionExampleDataColumn';
              fieldName: string;
              title: string;
              description: string;
              examples: Array<string>;
              required: boolean;
              optionalToMap: boolean | null;
              defaultValue: any | null;
            }>;
            rows: Array<{
              __typename?: 'IngestionExampleDataRow';
              fields: Array<{
                __typename?: 'IngestionExampleDataRowField';
                fieldName: string;
                value: string;
              }>;
            }>;
          }>;
          transforms: Array<{
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            businessActivityTypeName: string | null;
            businessActivityTypeVersion: {
              __typename?: 'BusinessActivityTypeVersion';
              id: string;
            } | null;
          }>;
        } | null;
      }>;
    } | null;
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      name: string;
      customerTargetSchemaIds: Array<string> | null;
    }>;
  }>;
};

export type GQFacilitiesManagementPageNewQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQFacilitiesManagementPageNewQuery = {
  __typename?: 'Query';
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    active: boolean;
    setupAt: Date | null;
    completedAt: Date | null;
    footprintStatus: GQMeasurementProjectFootprintStatus;
    coverageInterval: YMInterval;
    deadline: Date | null;
    userUploadTasks: {
      __typename?: 'UserUploadTaskConnection';
      edges: Array<{
        __typename?: 'UserUploadTaskEdge';
        node: {
          __typename?: 'UserUploadTask';
          id: string;
          state: GQUserUploadTaskState;
          buildingIds: Array<string>;
          lockState: GQApprovalTargetLockState;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          issues: {
            __typename?: 'DataIssueConnection';
            edges: Array<{
              __typename?: 'DataIssueEdge';
              node: {
                __typename?: 'DataIssue';
                id: string;
                title: string;
                description: string | null;
                state: GQDataIssueState;
                createdAt: Date;
                isCustomerInitiated: boolean;
                measurementProject: {
                  __typename?: 'MeasurementProject';
                  id: string;
                  completedAt: Date | null;
                };
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      message: string;
                      isPublished: boolean;
                      createdAt: Date;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            name: string;
                            displayName: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            name: string;
                            displayName: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              } | null;
            } | null>;
          } | null;
          datasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            isApi: boolean | null;
            userCanAccessDetails: boolean | null;
            userCanApproveDatasource: boolean | null;
            userCanManageDatasource: boolean | null;
            isUserConfirmed: boolean | null;
            hasIntegration: boolean;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            };
          };
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            active: boolean;
          };
        } | null;
      } | null>;
    };
    measurementTimeline: {
      __typename?: 'MeasurementTimeline';
      id: string;
      kickoff: Date;
      deadline: Date;
    } | null;
    measurementTasks: Array<{
      __typename?: 'MeasurementParentTask';
      taskGroup: string;
      id: string;
      title: string;
      link: string | null;
      lockState: GQApprovalTargetLockState | null;
      isAssignedToWatershed: boolean;
      statusSimplified: GQTaskWatershedProcessStateSimplified;
      subTasks: Array<
        | {
            __typename?: 'MeasurementParentTask';
            taskGroup: string;
            id: string;
            title: string;
            link: string | null;
            lockState: GQApprovalTargetLockState | null;
            isAssignedToWatershed: boolean;
            statusSimplified: GQTaskWatershedProcessStateSimplified;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            } | null;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
              didLatestEmailBounce: boolean;
            }>;
            approvers: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            object:
              | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
              | {
                  __typename: 'DataIssue';
                  id: string;
                  linkedObject:
                    | {
                        __typename: 'FinancialsReviewItem';
                        id: string;
                        group: {
                          __typename: 'FinancialsReviewGroup';
                          id: string;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        };
                      }
                    | {
                        __typename: 'MeasurementVerificationItemQuestion';
                        id: string;
                        item: {
                          __typename: 'MeasurementVerificationItem';
                          id: string;
                          maybeDataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          } | null;
                        };
                      }
                    | {
                        __typename: 'UserUploadTask';
                        id: string;
                        datasource: {
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        };
                      }
                    | { __typename: 'ValueMappingTask' }
                    | null;
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        createdAt: Date;
                        isPublished: boolean;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                }
              | { __typename: 'FacilitiesSetup'; id: string }
              | {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                }
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | { __typename: 'FootprintQuestion'; id: string }
              | {
                  __typename: 'MeasurementDataMappingTask';
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | { __typename: 'MeasurementProject'; id: string }
              | {
                  __typename: 'MeasurementProjectDataset';
                  modelId: string | null;
                  isCompleted: boolean;
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  mviStatus: GQMeasurementVerificationItemStatus;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  } | null;
                }
              | {
                  __typename: 'UserUploadTask';
                  state: GQUserUploadTaskState;
                  id: string;
                  approvers: Array<{
                    __typename?: 'UserWithApprovalStatus';
                    approvalStatus: GQApprovalStatus | null;
                    user: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                    };
                  }>;
                  datasource: {
                    __typename?: 'Datasource';
                    hasIntegration: boolean;
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      canonicalDataset: {
                        __typename?: 'CanonicalDataset';
                        id: string;
                        kind: GQCanonicalDatasetKind;
                      } | null;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask'; id: string }
              | { __typename: 'VendorMatchingTask'; id: string };
          }
        | {
            __typename?: 'MeasurementTaskFlat';
            taskGroup: string;
            id: string;
            title: string;
            link: string | null;
            lockState: GQApprovalTargetLockState | null;
            isAssignedToWatershed: boolean;
            statusSimplified: GQTaskWatershedProcessStateSimplified;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            } | null;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
              didLatestEmailBounce: boolean;
            }>;
            approvers: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            object:
              | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
              | {
                  __typename: 'DataIssue';
                  id: string;
                  linkedObject:
                    | {
                        __typename: 'FinancialsReviewItem';
                        id: string;
                        group: {
                          __typename: 'FinancialsReviewGroup';
                          id: string;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        };
                      }
                    | {
                        __typename: 'MeasurementVerificationItemQuestion';
                        id: string;
                        item: {
                          __typename: 'MeasurementVerificationItem';
                          id: string;
                          maybeDataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          } | null;
                        };
                      }
                    | {
                        __typename: 'UserUploadTask';
                        id: string;
                        datasource: {
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        };
                      }
                    | { __typename: 'ValueMappingTask' }
                    | null;
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        createdAt: Date;
                        isPublished: boolean;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                }
              | { __typename: 'FacilitiesSetup'; id: string }
              | {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                }
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | { __typename: 'FootprintQuestion'; id: string }
              | {
                  __typename: 'MeasurementDataMappingTask';
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | { __typename: 'MeasurementProject'; id: string }
              | {
                  __typename: 'MeasurementProjectDataset';
                  modelId: string | null;
                  isCompleted: boolean;
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  mviStatus: GQMeasurementVerificationItemStatus;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  } | null;
                }
              | {
                  __typename: 'UserUploadTask';
                  state: GQUserUploadTaskState;
                  id: string;
                  approvers: Array<{
                    __typename?: 'UserWithApprovalStatus';
                    approvalStatus: GQApprovalStatus | null;
                    user: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                    };
                  }>;
                  datasource: {
                    __typename?: 'Datasource';
                    hasIntegration: boolean;
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      canonicalDataset: {
                        __typename?: 'CanonicalDataset';
                        id: string;
                        kind: GQCanonicalDatasetKind;
                      } | null;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask'; id: string }
              | { __typename: 'VendorMatchingTask'; id: string };
          }
      >;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        materialCsrdStandards: Array<GQCsrdStandard>;
      } | null;
      assignees: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
        didLatestEmailBounce: boolean;
      }>;
      approvers: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      }>;
      object:
        | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
        | {
            __typename: 'DataIssue';
            id: string;
            linkedObject:
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItemQuestion';
                  id: string;
                  item: {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    } | null;
                  };
                }
              | {
                  __typename: 'UserUploadTask';
                  id: string;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask' }
              | null;
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  createdAt: Date;
                  isPublished: boolean;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          }
        | { __typename: 'FacilitiesSetup'; id: string }
        | {
            __typename: 'FinancialsReviewGroup';
            id: string;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              };
            }>;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            group: {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            };
          }
        | { __typename: 'FootprintQuestion'; id: string }
        | {
            __typename: 'MeasurementDataMappingTask';
            id: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            };
          }
        | { __typename: 'MeasurementProject'; id: string }
        | {
            __typename: 'MeasurementProjectDataset';
            modelId: string | null;
            isCompleted: boolean;
            id: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            };
          }
        | {
            __typename: 'MeasurementVerificationItem';
            id: string;
            mviStatus: GQMeasurementVerificationItemStatus;
            maybeDataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            } | null;
          }
        | {
            __typename: 'UserUploadTask';
            state: GQUserUploadTaskState;
            id: string;
            approvers: Array<{
              __typename?: 'UserWithApprovalStatus';
              approvalStatus: GQApprovalStatus | null;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              };
            }>;
            datasource: {
              __typename?: 'Datasource';
              hasIntegration: boolean;
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
          }
        | { __typename: 'ValueMappingTask'; id: string }
        | { __typename: 'VendorMatchingTask'; id: string };
    }>;
    datasetsWithStages: Array<{
      __typename?: 'DatasetWithStage';
      id: string;
      areAllTasksComplete: boolean;
      areUploadsComplete: boolean;
      isUsableInFootprint: boolean;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        materialCsrdStandards: Array<GQCsrdStandard>;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      } | null;
    }>;
    measurementVerificationItems: {
      __typename?: 'MeasurementVerificationItemConnection';
      edges: Array<{
        __typename?: 'MeasurementVerificationItemEdge';
        node: {
          __typename?: 'MeasurementVerificationItem';
          id: string;
          status: GQMeasurementVerificationItemStatus;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          } | null;
        } | null;
      }>;
    };
    climateProgramProjects: Array<{
      __typename: 'ClimateProgramProject';
      id: string;
      name: string;
      canonicalClimateProgramProject: {
        __typename: 'CanonicalClimateProgramProject';
        id: string;
        measurementObjectCopy: string | null;
        kind: GQClimateProgramProjectKind;
        routeType: string | null;
        name: string;
      } | null;
    }>;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    ingestionCustomFields: Array<{
      __typename?: 'IngestionCustomField';
      bartTagName: string;
      id: string;
      name: string;
      description: string | null;
      type: GQIngestionCustomFieldType;
      possibleValues: Array<string> | null;
      appliesToAllDatasets: boolean;
      createdAt: Date;
      exampleValues: Array<string> | null;
      specificUtilityTypes: Array<GQBuildingUtilityType>;
      ingestionCustomFieldDatasources: Array<{
        __typename?: 'IngestionCustomFieldDatasource';
        id: string;
      }> | null;
      specificDatasets: Array<{
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      }>;
    }>;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      instructions: {
        __typename?: 'InstructionsBundle';
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
        customerTargetSchemas: Array<{
          __typename?: 'CustomerTargetSchema';
          id: string;
          name: string;
          utilityType: GQBuildingUtilityType | null;
          rank: number;
          latestPublishedVersion: {
            __typename?: 'CustomerTargetSchemaVersion';
            id: string;
            formattingNotesMd: string | null;
            orgDatasetExampleData: Array<{
              __typename?: 'IngestionExampleData';
              id: string;
              title: string;
              datasetId: string | null;
              columns: Array<{
                __typename?: 'IngestionExampleDataColumn';
                fieldName: string;
                title: string;
                description: string;
                examples: Array<string>;
                required: boolean;
                optionalToMap: boolean | null;
                defaultValue: any | null;
              }>;
              rows: Array<{
                __typename?: 'IngestionExampleDataRow';
                fields: Array<{
                  __typename?: 'IngestionExampleDataRowField';
                  fieldName: string;
                  value: string;
                }>;
              }>;
            }>;
            transforms: Array<{
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              businessActivityTypeName: string | null;
              businessActivityTypeVersion: {
                __typename?: 'BusinessActivityTypeVersion';
                id: string;
              } | null;
            }>;
          } | null;
        }>;
      } | null;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
        customerTargetSchemaIds: Array<string> | null;
      }>;
    }>;
  };
};

export type GQOrganizationFacilitiesUntypedFragment = {
  __typename?: 'Organization';
  id: string;
  buildingsUntyped: Array<{
    __typename?: 'BuildingUntyped';
    id: string;
    data: any;
  }>;
};

export type GQBuildingsUntypedQueryVariables = Exact<{ [key: string]: never }>;

export type GQBuildingsUntypedQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    buildingsUntyped: Array<{
      __typename?: 'BuildingUntyped';
      id: string;
      data: any;
    }>;
  };
};

export type GQMeasurementProjectsWithFacilitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQMeasurementProjectsWithFacilitiesQuery = {
  __typename?: 'Query';
  measurementProjectsWithFacilities: Array<{
    __typename?: 'MeasurementProject';
    id: string;
  }>;
};

export type GQSingleFacilityBuildingPropertiesFragment = {
  __typename?: 'Facility';
  id: string;
  createdAt: Date;
  updatedAt: Date;
  editState: GQBuildingEditState;
  streetAddress: string | null;
  streetAddress2: string | null;
  postalCode: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  startYearMonth: YearMonth | null;
  endYearMonth: YearMonth | null;
  sizeUnit: GQBuildingSizeUnit | null;
  buildingKind: string | null;
  buildingSubkind: string | null;
  leaseType: GQBuildingLeaseType | null;
  buildingName: string;
  buildingUniqueId: string;
  isWithoutNaturalGas: boolean | null;
  isWithoutRefrigerants: boolean | null;
  periods: Array<{
    __typename?: 'BuildingPeriod';
    id: string;
    kind: GQBuildingPeriodKind;
    sizeQuantity: number | null;
    startYearMonth: YearMonth | null;
    endYearMonth: YearMonth | null;
    partialCleanPowerPercent: number | null;
    cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
    powerUsageEffectiveness: number | null;
    isBuildingEmpty: boolean | null;
    customFieldValue: string | null;
    customFieldId: string | null;
    orgUnitTypeStableId: string | null;
    orgUnitStableId: string | null;
  }>;
  supportingDocuments: Array<{
    __typename?: 'SupportingDocument';
    id: string;
    createdAt: Date;
    utilityType: GQBuildingUtilityType | null;
    buildingUtilityId: string | null;
    file: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
      remoteWritten: boolean;
    };
  }>;
  latestApprovedVersion: {
    __typename?: 'BuildingForDiff';
    id: string | null;
    streetAddress: string | null;
    streetAddress2: string | null;
    postalCode: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    startYearMonth: YearMonth | null;
    endYearMonth: YearMonth | null;
    sizeUnit: GQBuildingSizeUnit | null;
    buildingKind: string | null;
    buildingSubkind: string | null;
    leaseType: GQBuildingLeaseType | null;
    buildingName: string;
    buildingUniqueId: string;
    isWithoutNaturalGas: boolean | null;
    isWithoutRefrigerants: boolean | null;
    periods: Array<{
      __typename?: 'BuildingPeriodForDiff';
      kind: GQBuildingPeriodKind;
      sizeQuantity: number | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      partialCleanPowerPercent: number | null;
      cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
      powerUsageEffectiveness: number | null;
      isBuildingEmpty: boolean | null;
      customFieldValue: string | null;
      customFieldId: string | null;
      orgUnitTypeStableId: string | null;
      orgUnitStableId: string | null;
    }>;
  } | null;
};

export type GQSingleFacilityUtilitiesQueryVariables = Exact<{
  buildingId: Scalars['ID']['input'];
  userUploadTaskId: Scalars['ID']['input'];
}>;

export type GQSingleFacilityUtilitiesQuery = {
  __typename?: 'Query';
  facility: {
    __typename?: 'Facility';
    id: string;
    startYearMonth: YearMonth | null;
    endYearMonth: YearMonth | null;
    buildingWithUtilities: {
      __typename?: 'BuildingWithUtilities';
      id: string;
      completionIntervals: {
        __typename?: 'BuildingCompletionIntervals';
        id: string;
        data: Array<{
          __typename?: 'BuildingCompletionIntervalsData';
          utilityType: GQBuildingUtilityType;
          fuelKind: GQStationaryCombustionFuelKind | null;
          periodsWithActuals: Array<{
            __typename?: 'DateTimeInterval';
            start: Date;
            end: Date;
          }>;
        }>;
      };
    };
  };
};

export type GQBuildingCompletionIntervalsUntypedQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQBuildingCompletionIntervalsUntypedQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    buildingCompletionIntervalsUntyped: Array<{
      __typename?: 'BuildingCompletionIntervalsUntyped';
      id: string;
      data: any;
    }>;
  };
};

export type GQBuildingUtilitiesExportQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQBuildingUtilitiesExportQuery = {
  __typename?: 'Query';
  buildingUtilitiesExport: {
    __typename?: 'BuildingUtilitiesExport';
    jobId: string;
  };
};

export type GQBuildingSupportingDocsExportQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQBuildingSupportingDocsExportQuery = {
  __typename?: 'Query';
  buildingSupportingDocsExport: {
    __typename?: 'BuildingSupportingDocsExport';
    jobId: string;
  };
};

export type GQDatasetAndDatasetGroupsForGenerateFootprintFragment = {
  __typename?: 'FootprintDatasetOrDatasetGroup';
  id: string;
  datasetGroupName: string | null;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    name: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
    } | null;
  } | null;
};

export type GQFootprintConfigurationWithReleasesFragment = {
  __typename?: 'FootprintConfiguration';
  id: string;
  releasesByBat: Array<{
    __typename?: 'EmissionsModelReleaseByBatName';
    batName: string;
    release: {
      __typename?: 'EmissionsModelRelease';
      id: string;
      updatedAt: Date;
    };
  }>;
};

export type GQFootprintConfigurationForGenerateFootprintFragment = {
  __typename?: 'FootprintConfiguration';
  id: string;
  methodologyConfig: {
    __typename?: 'MethodologyConfig';
    id: string;
    spendMethodology: GQSpendMethodology | null;
    wasteEmissionsExclusions: Array<{
      __typename?: 'WasteEmissionsExclusion';
      wasteEmissionsMethodology: GQWasteEmissionsMethodology;
      shouldExclude: boolean;
    }> | null;
  } | null;
  measurementProjectDatasets: Array<{
    __typename?: 'FootprintMeasurementProjectDataset';
    id: string;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      createdAt: Date;
    };
    datasetsAndDatasetGroups: Array<{
      __typename?: 'FootprintDatasetOrDatasetGroup';
      id: string;
      datasetGroupName: string | null;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      } | null;
    }>;
  }>;
  exclusionRules: Array<{
    __typename?: 'FootprintExclusionRule';
    id: string;
    latestActiveRule: {
      __typename?: 'FootprintExclusionRule';
      id: string;
    } | null;
  }>;
  categoryRules: Array<{
    __typename?: 'FootprintCategoryRule';
    id: string;
    latestActiveRule: {
      __typename?: 'FootprintCategoryRule';
      id: string;
    } | null;
  }>;
};

export type GQDatasetWithStageForGenerateFootprintFragment = {
  __typename?: 'DatasetWithStage';
  id: string;
  isUsableInFootprint: boolean;
  hasUnsupportedBarts: boolean;
  largeUserUploadedFiles: Array<string> | null;
  isNonEmissive: boolean;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    name: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
    } | null;
  } | null;
};

export type GQPipelineConfigsForFootprintSnapshotFragment = {
  __typename?: 'FootprintSnapshot';
  id: string;
  configs: Array<{
    __typename?: 'PipelineConfig';
    pipelineResultId: string;
    coverageInterval: YMInterval;
  }>;
};

export type GQFootprintVersionForNameGenFragment = {
  __typename?: 'FootprintVersion';
  id: string;
  kind: GQFootprintVersionKind;
};

export type GQMeasurementProjectForGenerateFootprintFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  createdAt: Date;
  coverageInterval: YMInterval;
  datasetsWithStages: Array<{
    __typename?: 'DatasetWithStage';
    id: string;
    isUsableInFootprint: boolean;
    hasUnsupportedBarts: boolean;
    largeUserUploadedFiles: Array<string> | null;
    isNonEmissive: boolean;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    } | null;
  }>;
};

export type GQGetLatestFootprintInfoAfterFootprintAssemblyRunQueryVariables =
  Exact<{
    id: Scalars['ID']['input'];
  }>;

export type GQGetLatestFootprintInfoAfterFootprintAssemblyRunQuery = {
  __typename?: 'Query';
  footprintAssemblyRun: {
    __typename?: 'FootprintAssemblyRun';
    id: string;
    status: GQFootprintAssemblyRunStatus;
    footprintSnapshotId: string | null;
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      newDataCoverageInterval: YMInterval | null;
    } | null;
  };
  activeOrganization: {
    __typename?: 'ActiveOrganization';
    id: string;
    latestDraftFootprintVersion: string | null;
  };
};

export type GQGenerateFootprintStepRendererQueryVariables = Exact<{
  footprintId: InputMaybe<Scalars['String']['input']>;
  latestDraftFootprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  latestPublishedFootprintSnapshotId: InputMaybe<Scalars['String']['input']>;
}>;

export type GQGenerateFootprintStepRendererQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  footprintTemp: {
    __typename?: 'FootprintTemp';
    id: string;
    name: string;
    defaultFootprintSnapshot: {
      __typename?: 'FootprintSnapshot';
      id: string;
      footprintConfiguration: {
        __typename?: 'FootprintConfiguration';
        id: string;
        methodologyConfig: {
          __typename?: 'MethodologyConfig';
          id: string;
          spendMethodology: GQSpendMethodology | null;
          wasteEmissionsExclusions: Array<{
            __typename?: 'WasteEmissionsExclusion';
            wasteEmissionsMethodology: GQWasteEmissionsMethodology;
            shouldExclude: boolean;
          }> | null;
        } | null;
        measurementProjectDatasets: Array<{
          __typename?: 'FootprintMeasurementProjectDataset';
          id: string;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
            createdAt: Date;
          };
          datasetsAndDatasetGroups: Array<{
            __typename?: 'FootprintDatasetOrDatasetGroup';
            id: string;
            datasetGroupName: string | null;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          }>;
        }>;
        exclusionRules: Array<{
          __typename?: 'FootprintExclusionRule';
          id: string;
          latestActiveRule: {
            __typename?: 'FootprintExclusionRule';
            id: string;
          } | null;
        }>;
        categoryRules: Array<{
          __typename?: 'FootprintCategoryRule';
          id: string;
          latestActiveRule: {
            __typename?: 'FootprintCategoryRule';
            id: string;
          } | null;
        }>;
      };
    } | null;
  };
  footprintChangelog: {
    __typename?: 'FootprintChangelog';
    id: string;
    versions: {
      __typename?: 'FootprintVersionConnection';
      edges: Array<{
        __typename?: 'FootprintVersionEdge';
        node: {
          __typename?: 'FootprintVersion';
          id: string;
          kind: GQFootprintVersionKind;
        } | null;
      } | null>;
    } | null;
  };
  activeMeasurementProjects: Array<{
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    createdAt: Date;
    coverageInterval: YMInterval;
    datasetsWithStages: Array<{
      __typename?: 'DatasetWithStage';
      id: string;
      isUsableInFootprint: boolean;
      hasUnsupportedBarts: boolean;
      largeUserUploadedFiles: Array<string> | null;
      isNonEmissive: boolean;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      } | null;
    }>;
  }>;
  utilitiesMetadata: {
    __typename?: 'UtilitiesMetadata';
    id: string;
    utilityTypes: Array<{
      __typename?: 'UtilitiesMetadataByType';
      utilityType: GQBuildingUtilityType | null;
      containingInterval: YMInterval;
    }>;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    nextFootprintVersionIdentifier: string;
    facilitiesMetadata: {
      __typename?: 'FacilitiesMetadata';
      buildingsDataset: { __typename?: 'Dataset'; id: string } | null;
      utilitiesDataset: { __typename?: 'Dataset'; id: string } | null;
    };
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      createdAt: Date;
      coverageInterval: YMInterval;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        isUsableInFootprint: boolean;
        hasUnsupportedBarts: boolean;
        largeUserUploadedFiles: Array<string> | null;
        isNonEmissive: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      }>;
    }>;
  };
  latestDraft: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      createdAt: Date;
      configuration: {
        __typename?: 'FootprintConfiguration';
        id: string;
        methodologyConfig: {
          __typename?: 'MethodologyConfig';
          id: string;
          spendMethodology: GQSpendMethodology | null;
          wasteEmissionsExclusions: Array<{
            __typename?: 'WasteEmissionsExclusion';
            wasteEmissionsMethodology: GQWasteEmissionsMethodology;
            shouldExclude: boolean;
          }> | null;
        } | null;
        measurementProjectDatasets: Array<{
          __typename?: 'FootprintMeasurementProjectDataset';
          id: string;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
            createdAt: Date;
          };
          datasetsAndDatasetGroups: Array<{
            __typename?: 'FootprintDatasetOrDatasetGroup';
            id: string;
            datasetGroupName: string | null;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          }>;
        }>;
        exclusionRules: Array<{
          __typename?: 'FootprintExclusionRule';
          id: string;
          latestActiveRule: {
            __typename?: 'FootprintExclusionRule';
            id: string;
          } | null;
        }>;
        categoryRules: Array<{
          __typename?: 'FootprintCategoryRule';
          id: string;
          latestActiveRule: {
            __typename?: 'FootprintCategoryRule';
            id: string;
          } | null;
        }>;
        releasesByBat: Array<{
          __typename?: 'EmissionsModelReleaseByBatName';
          batName: string;
          release: {
            __typename?: 'EmissionsModelRelease';
            id: string;
            updatedAt: Date;
          };
        }>;
      } | null;
    } | null;
  } | null;
  latestPublished: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      createdAt: Date;
      userVisibleFootprintSnapshot: {
        __typename?: 'FootprintSnapshot';
        id: string;
        configs: Array<{
          __typename?: 'PipelineConfig';
          pipelineResultId: string;
          coverageInterval: YMInterval;
        }>;
      };
      configuration: {
        __typename?: 'FootprintConfiguration';
        id: string;
        releasesByBat: Array<{
          __typename?: 'EmissionsModelReleaseByBatName';
          batName: string;
          release: {
            __typename?: 'EmissionsModelRelease';
            id: string;
            updatedAt: Date;
          };
        }>;
      } | null;
    } | null;
  } | null;
};

export type GQAvailableReleasesForSelectedDatasetsQueryVariables = Exact<{
  datasetIdsPerMeasurementProjectId:
    | Array<GQDatasetIdsPerMeasurementProjectId>
    | GQDatasetIdsPerMeasurementProjectId;
}>;

export type GQAvailableReleasesForSelectedDatasetsQuery = {
  __typename?: 'Query';
  availableReleasesForSelectedDatasets: Array<{
    __typename?: 'BusinessActivityTypeWithReleases';
    businessActivityType: string;
    displayName: string;
    datasetIds: Array<string>;
    releases: Array<{
      __typename?: 'EmissionsModelRelease';
      id: string;
      displayName: string;
      businessActivityTypeName: string;
      externalNotes: string;
      lifecyclePhase: GQEmReleaseLifecyclePhase;
      updatedAt: Date;
      orgId: string | null;
      isCustomToUserOrg: boolean | null;
      latestCompatibleBatVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
        name: string;
      } | null;
      lifecyclePhaseHistory: Array<{
        __typename?: 'EmissionsModelReleaseLifecyclePhase';
        id: string;
        recordStartAt: Date;
        recordEndAt: Date | null;
        lifecyclePhase: GQEmReleaseLifecyclePhase;
      }>;
    }>;
  }>;
  selectedDatasetsByBatWithMetadata: {
    __typename?: 'DatasetsByBatWithMetadata';
    id: string;
    datasetsByBat: Array<{
      __typename?: 'DatasetsByBat';
      id: string;
      businessActivityType: string;
      businessActivityTypeDisplayName: string;
      datasets: Array<{
        __typename?: 'LiteDataset';
        id: string;
        datasetName: string;
      }>;
    }>;
    ctsUserUploads: Array<{
      __typename?: 'CtsUserUpload';
      id: string;
      datasourceId: string;
      userUploadId: string;
      userUploadFileName: string;
      batvName: string;
      businessActivityType: string;
      measurementProjectId: string;
    }>;
  };
};

export type GQApplyIntegrationConnectionDashboardMutationVariables = Exact<{
  input: GQApplyIntegrationConnectionInput;
}>;

export type GQApplyIntegrationConnectionDashboardMutation = {
  __typename?: 'Mutation';
  applyIntegrationConnection: {
    __typename?: 'ApplyIntegrationConnectionPayload';
    integrationConnection: {
      __typename?: 'IntegrationConnection';
      id: string;
      datasources: {
        __typename?: 'DatasourceConnection';
        edges: Array<{
          __typename?: 'DatasourceEdge';
          node: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: { __typename?: 'Dataset'; id: string; name: string };
          } | null;
        }>;
      };
    };
  };
};

export type GQMaybeCreateIntegrationConnectionDoNotLogMutationVariables =
  Exact<{
    input: GQMaybeCreateIntegrationConnectionInput;
  }>;

export type GQMaybeCreateIntegrationConnectionDoNotLogMutation = {
  __typename?: 'Mutation';
  maybeCreateIntegrationConnection: {
    __typename?: 'MaybeCreateIntegrationConnectionPayload';
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      hasIntegration: boolean;
      activeIntegrationConnection: {
        __typename?: 'IntegrationConnection';
        id: string;
      } | null;
    }>;
    integrationConnection: {
      __typename?: 'IntegrationConnection';
      id: string;
      integrationPartner: {
        __typename?: 'IntegrationPartner';
        id: string;
        activeIntegrationConnections: Array<{
          __typename?: 'IntegrationConnection';
          id: string;
        }>;
      };
    } | null;
  } | null;
};

export type GQDisconnectIntegrationMutationVariables = Exact<{
  input: GQDisconnectIntegrationConnectionInput;
}>;

export type GQDisconnectIntegrationMutation = {
  __typename?: 'Mutation';
  disconnectIntegrationConnection: {
    __typename?: 'DisconnectIntegrationConnectionPayload';
    revoked: boolean;
    integrationPartner: {
      __typename?: 'IntegrationPartner';
      id: string;
      activeIntegrationConnections: Array<{
        __typename?: 'IntegrationConnection';
        id: string;
        additionalIdentifier: string | null;
      }>;
    };
  };
};

export type GQGetIntegrationInviteMutationVariables = Exact<{
  input: GQSendIntegrationInviteInput;
}>;

export type GQGetIntegrationInviteMutation = {
  __typename?: 'Mutation';
  sendIntegrationInvite: {
    __typename?: 'SendIntegrationInvitePayload';
    numberOfEmails: number;
    sampleEmail: {
      __typename?: 'EmailContents';
      subject: string;
      bodyHtml: string;
      recipients: Array<{
        __typename?: 'EmailUser';
        email: string;
        name: string;
      }>;
      ccs: Array<{
        __typename?: 'EmailUser';
        email: string;
        name: string;
      }> | null;
      sender: { __typename?: 'EmailUser'; email: string; name: string };
      bccs: Array<{
        __typename?: 'EmailUser';
        email: string;
        name: string;
      } | null> | null;
    };
  };
};

export type GQSendIntegrationInviteMutationVariables = Exact<{
  input: GQSendIntegrationInviteInput;
}>;

export type GQSendIntegrationInviteMutation = {
  __typename?: 'Mutation';
  sendIntegrationInvite: {
    __typename?: 'SendIntegrationInvitePayload';
    numberOfEmails: number;
  };
};

export type GQOrganizationIntegrationPartnersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQOrganizationIntegrationPartnersQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  integrationPartners: {
    __typename?: 'IntegrationPartnerConnection';
    edges: Array<{
      __typename?: 'IntegrationPartnerEdge';
      node: {
        __typename?: 'IntegrationPartner';
        id: string;
        name: string;
        logoUrl: string;
        externalId: string;
        dataType: string;
        isRequestOnly: boolean;
        activeIntegrationConnections: Array<{
          __typename?: 'IntegrationConnection';
          id: string;
          additionalIdentifier: string | null;
        }>;
      } | null;
    } | null>;
  };
  integrationConnections: {
    __typename?: 'IntegrationConnectionConnection';
    edges: Array<{
      __typename?: 'IntegrationConnectionEdge';
      node: {
        __typename?: 'IntegrationConnection';
        id: string;
        isActive: boolean;
        integrationPartner: {
          __typename?: 'IntegrationPartner';
          id: string;
          name: string;
        };
      } | null;
    } | null>;
  };
};

export type GQGetOrSendMeasurementEmailsMutationVariables = Exact<{
  input: GQGetOrSendMeasurementEmailsInput;
}>;

export type GQGetOrSendMeasurementEmailsMutation = {
  __typename?: 'Mutation';
  getOrSendMeasurementEmails: {
    __typename?: 'GetOrSendMeasurementEmailsPayload';
    defaultBlurb: string;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      deadline: Date | null;
      active: boolean;
    };
    sampleEmail: {
      __typename?: 'EmailContents';
      subject: string;
      bodyHtml: string;
      recipients: Array<{
        __typename?: 'EmailUser';
        email: string;
        name: string;
      }>;
      ccs: Array<{
        __typename?: 'EmailUser';
        email: string;
        name: string;
      }> | null;
      sender: { __typename?: 'EmailUser'; email: string; name: string };
      bccs: Array<{
        __typename?: 'EmailUser';
        email: string;
        name: string;
      } | null> | null;
    };
  } | null;
};

export type GQMeasurementProjectForSendEmailsButtonFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  deadline: Date | null;
  active: boolean;
};

export type GQCreateCompanyChangeRequestCreateMutationVariables = Exact<{
  input: GQCreateCompanyChangeRequestCreateInput;
}>;

export type GQCreateCompanyChangeRequestCreateMutation = {
  __typename?: 'Mutation';
  createCompanyChangeRequestCreate: {
    __typename?: 'CreateCompanyChangeRequestCreatePayload';
    companyChangeRequest: {
      __typename?: 'CompanyChangeRequest';
      id: string;
      action: GQCompanyChangeRequestAction;
      payload: any;
    };
  };
};

export type GQVendorMatchingTaskFieldsFragment = {
  __typename?: 'VendorMatchingTask';
  id: string;
  measurementProject: { __typename?: 'MeasurementProject'; id: string };
};

export type GQPreMeasurementVendorMatchingTableRowsQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
  search: InputMaybe<Scalars['String']['input']>;
  sortBy: InputMaybe<GQSupplierMatchingSortOption>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQPreMeasurementVendorMatchingTableRowsQuery = {
  __typename?: 'Query';
  preMeasurementVendorMatchingTableRows: {
    __typename?: 'VendorMatchingTableRowsPayload';
    id: string;
    totalNumVendorRows: number;
    vendorRows: {
      __typename?: 'VendorMatchingTableRowConnection';
      edges: Array<{
        __typename?: 'VendorMatchingTableRowEdge';
        node: {
          __typename?: 'VendorMatchingTableRow';
          id: string;
          rawVendorEntity: string;
          totalSpendUsd: number;
          autoMatchedCompany: {
            __typename?: 'Company';
            id: string;
            name: string;
            country: string | null;
            url: string | null;
            description: string | null;
            naicsCode: string | null;
            countryAlpha2: string | null;
          } | null;
          orgCompany: {
            __typename?: 'OrgCompany';
            id: string;
            name: string;
            company: {
              __typename?: 'Company';
              id: string;
              name: string;
              country: string | null;
              url: string | null;
              description: string | null;
              naicsCode: string | null;
              countryAlpha2: string | null;
            } | null;
          } | null;
          pendingCompanyCreateRequest: {
            __typename?: 'CompanyChangeRequest';
            id: string;
            action: GQCompanyChangeRequestAction;
            payload: any;
          } | null;
          subRows: Array<{
            __typename?: 'VendorMatchingTableSubRow';
            id: string;
            category: string;
            subCategory: string;
            currencyQuantity: number;
            currency: string;
            spendUsd: number;
          }>;
        } | null;
      } | null>;
      pageInfo: {
        __typename?: 'PageInfo';
        startCursor: string | null;
        endCursor: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
      };
    };
  };
};

export type GQPreMeasurementSupplierMappingV1AnalyticsQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQPreMeasurementSupplierMappingV1AnalyticsQuery = {
  __typename?: 'Query';
  preMeasurementSupplierMappingV1Analytics: {
    __typename?: 'SupplierMappingV1Analytics';
    mappedSupplierSpendUsd: number;
    totalSupplierSpendUsd: number;
  };
};

export type GQVendorMatchingTaskPageDataQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQVendorMatchingTaskPageDataQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  vendorMatchingTask: {
    __typename?: 'VendorMatchingTask';
    id: string;
    measurementProject: { __typename?: 'MeasurementProject'; id: string };
  } | null;
};

export type GQSubmitVendorMatchingTaskMutationVariables = Exact<{
  input: GQSubmitVendorMatchingTaskInput;
}>;

export type GQSubmitVendorMatchingTaskMutation = {
  __typename?: 'Mutation';
  submitVendorMatchingTask: {
    __typename?: 'SubmitVendorMatchingTaskPayload';
    measurementTask:
      | { __typename?: 'MeasurementParentTask'; id: string }
      | { __typename?: 'MeasurementTaskFlat'; id: string };
  };
};

export type GQOrganizationForVendorMatchingFragment = {
  __typename?: 'Organization';
  id: string;
  orgCompanies: Array<{
    __typename?: 'OrgCompany';
    id: string;
    name: string;
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      country: string | null;
      url: string | null;
      description: string | null;
      naicsCode: string | null;
      countryAlpha2: string | null;
    } | null;
  }>;
};

export type GQCompanyForVendorMatchingFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
  country: string | null;
  url: string | null;
  description: string | null;
  naicsCode: string | null;
  countryAlpha2: string | null;
};

export type GQCompanyChangeRequestForVendorMatchingFragment = {
  __typename?: 'CompanyChangeRequest';
  id: string;
  action: GQCompanyChangeRequestAction;
  payload: any;
};

export type GQOrgCompanyForVendorMatchingFragment = {
  __typename?: 'OrgCompany';
  id: string;
  name: string;
  company: {
    __typename?: 'Company';
    id: string;
    name: string;
    country: string | null;
    url: string | null;
    description: string | null;
    naicsCode: string | null;
    countryAlpha2: string | null;
  } | null;
};

export type GQVendorMatchingTableSubRowFieldsFragment = {
  __typename?: 'VendorMatchingTableSubRow';
  id: string;
  category: string;
  subCategory: string;
  currencyQuantity: number;
  currency: string;
  spendUsd: number;
};

export type GQVendorMatchingTableRowFieldsFragment = {
  __typename?: 'VendorMatchingTableRow';
  id: string;
  rawVendorEntity: string;
  totalSpendUsd: number;
  autoMatchedCompany: {
    __typename?: 'Company';
    id: string;
    name: string;
    country: string | null;
    url: string | null;
    description: string | null;
    naicsCode: string | null;
    countryAlpha2: string | null;
  } | null;
  orgCompany: {
    __typename?: 'OrgCompany';
    id: string;
    name: string;
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      country: string | null;
      url: string | null;
      description: string | null;
      naicsCode: string | null;
      countryAlpha2: string | null;
    } | null;
  } | null;
  pendingCompanyCreateRequest: {
    __typename?: 'CompanyChangeRequest';
    id: string;
    action: GQCompanyChangeRequestAction;
    payload: any;
  } | null;
  subRows: Array<{
    __typename?: 'VendorMatchingTableSubRow';
    id: string;
    category: string;
    subCategory: string;
    currencyQuantity: number;
    currency: string;
    spendUsd: number;
  }>;
};

export type GQVendorMatchingTableRowsPayloadFieldsFragment = {
  __typename?: 'VendorMatchingTableRowsPayload';
  id: string;
  totalNumVendorRows: number;
  vendorRows: {
    __typename?: 'VendorMatchingTableRowConnection';
    edges: Array<{
      __typename?: 'VendorMatchingTableRowEdge';
      node: {
        __typename?: 'VendorMatchingTableRow';
        id: string;
        rawVendorEntity: string;
        totalSpendUsd: number;
        autoMatchedCompany: {
          __typename?: 'Company';
          id: string;
          name: string;
          country: string | null;
          url: string | null;
          description: string | null;
          naicsCode: string | null;
          countryAlpha2: string | null;
        } | null;
        orgCompany: {
          __typename?: 'OrgCompany';
          id: string;
          name: string;
          company: {
            __typename?: 'Company';
            id: string;
            name: string;
            country: string | null;
            url: string | null;
            description: string | null;
            naicsCode: string | null;
            countryAlpha2: string | null;
          } | null;
        } | null;
        pendingCompanyCreateRequest: {
          __typename?: 'CompanyChangeRequest';
          id: string;
          action: GQCompanyChangeRequestAction;
          payload: any;
        } | null;
        subRows: Array<{
          __typename?: 'VendorMatchingTableSubRow';
          id: string;
          category: string;
          subCategory: string;
          currencyQuantity: number;
          currency: string;
          spendUsd: number;
        }>;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type GQSupplierMappingV1AnalyticsFieldsFragment = {
  __typename?: 'SupplierMappingV1Analytics';
  mappedSupplierSpendUsd: number;
  totalSupplierSpendUsd: number;
};

export type GQUpsertOrgCompanyForNameMutationVariables = Exact<{
  input: GQUpsertOrgCompanyForNameInput;
}>;

export type GQUpsertOrgCompanyForNameMutation = {
  __typename?: 'Mutation';
  upsertOrgCompanyForName: {
    __typename?: 'UpsertOrgCompanyForNamePayload';
    orgCompany: {
      __typename?: 'OrgCompany';
      id: string;
      name: string;
      company: {
        __typename?: 'Company';
        id: string;
        name: string;
        country: string | null;
        url: string | null;
        description: string | null;
        naicsCode: string | null;
        countryAlpha2: string | null;
      } | null;
    };
  };
};

export type GQWithdrawCompanyChangeRequestMutationVariables = Exact<{
  input: GQWithdrawCompanyChangeRequestInput;
}>;

export type GQWithdrawCompanyChangeRequestMutation = {
  __typename?: 'Mutation';
  withdrawCompanyChangeRequest: {
    __typename?: 'WithdrawCompanyChangeRequestPayload';
    id: string;
  };
};

export type GQDeleteOrgCompanyMutationVariables = Exact<{
  input: GQDeleteOrgCompanyInput;
}>;

export type GQDeleteOrgCompanyMutation = {
  __typename?: 'Mutation';
  deleteOrgCompany: { __typename?: 'DeleteOrgCompanyPayload'; id: string };
};

export type GQLogUpdateVendorMatchingTaskMutationVariables = Exact<{
  input: GQLogUpdateVendorMatchingTaskInput;
}>;

export type GQLogUpdateVendorMatchingTaskMutation = {
  __typename?: 'Mutation';
  logUpdateVendorMatchingTask: {
    __typename?: 'LogUpdateVendorMatchingTaskPayload';
    vendorMatchingTaskId: string;
  };
};

export type GQMeasurementVerificationItemForSummariesFragment = {
  __typename?: 'MeasurementVerificationItem';
  id: string;
  name: string;
  datasetName: string;
  description: string;
  status: GQMeasurementVerificationItemStatus;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    active: boolean;
    coverageInterval: YMInterval;
  };
  assignee: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
  } | null;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
    } | null;
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      name: string;
      summaryWithMetadata: {
        __typename?: 'DatasourceSummaryWithMetadata';
        id: string;
        summaries: Array<{
          __typename?: 'DatasourceSummary';
          id: string;
          signedUrl: string;
        }>;
      };
    }>;
  } | null;
  questions: {
    __typename?: 'MeasurementVerificationItemQuestionConnection';
    edges: Array<{
      __typename?: 'MeasurementVerificationItemQuestionEdge';
      node: {
        __typename?: 'MeasurementVerificationItemQuestion';
        id: string;
        section: GQMeasurementVerificationSection;
        status: GQMeasurementVerificationQuestionStatus;
        contentKind: GQMeasurementVerificationQuestionContentKind;
        contentTitle: string | null;
        contentSubtitle: string | null;
        questionKind: GQMeasurementVerificationQuestionKind;
        questionPrompt: string;
        answerText: string | null;
        rank: number | null;
        discussion: {
          __typename?: 'DataIssue';
          id: string;
          title: string;
          description: string | null;
          state: GQDataIssueState;
          createdAt: Date;
          isCustomerInitiated: boolean;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            completedAt: Date | null;
          };
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                message: string;
                isPublished: boolean;
                createdAt: Date;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        } | null;
        publisher: {
          __typename?: 'WatershedEmployee';
          id: string;
          name: string;
        } | null;
        contentMetadata:
          | {
              __typename: 'MeasurementVerificationItemContentMetadataFileOverlaps';
              id: string;
              files: Array<{
                __typename?: 'FileMetadata';
                id: string;
                name: string;
                uploader:
                  | { __typename?: 'User'; id: string; name: string }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                    }
                  | null;
                userUploadTask: {
                  __typename?: 'UserUploadTask';
                  id: string;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  };
                } | null;
              }>;
            }
          | {
              __typename: 'MeasurementVerificationItemContentMetadataQuantityTrend';
              id: string;
              aggregateField: string | null;
              filters: Array<{
                __typename?: 'DatasetSummaryFilter';
                field: string;
                operator: GQFilterOperator;
                value: Array<string>;
              }> | null;
              anomaly: {
                __typename?: 'QuantityTrendAnomaly';
                highlightMonth: YearMonth;
              } | null;
              frozenDatasourceSummaries: Array<{
                __typename?: 'FrozenDatasourceSummary';
                isStale: boolean;
                signedUrl: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                };
              }> | null;
            }
          | {
              __typename: 'MeasurementVerificationItemContentMetadataTopAccounts';
              id: string;
              frozenDatasourceSummaries: Array<{
                __typename?: 'FrozenDatasourceSummary';
                isStale: boolean;
                signedUrl: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                };
              }> | null;
            };
      } | null;
    }>;
  };
};

export type GQMeasurementVerificationItemQuestionForSummariesFragment = {
  __typename?: 'MeasurementVerificationItemQuestion';
  id: string;
  section: GQMeasurementVerificationSection;
  status: GQMeasurementVerificationQuestionStatus;
  contentKind: GQMeasurementVerificationQuestionContentKind;
  contentTitle: string | null;
  contentSubtitle: string | null;
  questionKind: GQMeasurementVerificationQuestionKind;
  questionPrompt: string;
  answerText: string | null;
  rank: number | null;
  discussion: {
    __typename?: 'DataIssue';
    id: string;
    title: string;
    description: string | null;
    state: GQDataIssueState;
    createdAt: Date;
    isCustomerInitiated: boolean;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      completedAt: Date | null;
    };
    comments: {
      __typename?: 'CommentConnection';
      edges: Array<{
        __typename?: 'CommentEdge';
        node: {
          __typename?: 'Comment';
          id: string;
          message: string;
          isPublished: boolean;
          createdAt: Date;
          person:
            | {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                isWatershedEmployee: boolean;
                isWatershedContractor: boolean;
              }
            | {
                __typename?: 'WatershedEmployee';
                id: string;
                name: string;
                displayName: string;
                isWatershedEmployee: boolean;
                isWatershedContractor: boolean;
              }
            | null;
        } | null;
      } | null>;
    };
  } | null;
  publisher: {
    __typename?: 'WatershedEmployee';
    id: string;
    name: string;
  } | null;
  contentMetadata:
    | {
        __typename: 'MeasurementVerificationItemContentMetadataFileOverlaps';
        id: string;
        files: Array<{
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          uploader:
            | { __typename?: 'User'; id: string; name: string }
            | { __typename?: 'WatershedEmployee'; id: string; name: string }
            | null;
          userUploadTask: {
            __typename?: 'UserUploadTask';
            id: string;
            datasource: { __typename?: 'Datasource'; id: string; name: string };
          } | null;
        }>;
      }
    | {
        __typename: 'MeasurementVerificationItemContentMetadataQuantityTrend';
        id: string;
        aggregateField: string | null;
        filters: Array<{
          __typename?: 'DatasetSummaryFilter';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }> | null;
        anomaly: {
          __typename?: 'QuantityTrendAnomaly';
          highlightMonth: YearMonth;
        } | null;
        frozenDatasourceSummaries: Array<{
          __typename?: 'FrozenDatasourceSummary';
          isStale: boolean;
          signedUrl: string;
          datasource: { __typename?: 'Datasource'; id: string; name: string };
        }> | null;
      }
    | {
        __typename: 'MeasurementVerificationItemContentMetadataTopAccounts';
        id: string;
        frozenDatasourceSummaries: Array<{
          __typename?: 'FrozenDatasourceSummary';
          isStale: boolean;
          signedUrl: string;
          datasource: { __typename?: 'Datasource'; id: string; name: string };
        }> | null;
      };
};

export type GQMeasurementVerificationDetailQueryVariables = Exact<{
  itemId: Scalars['ID']['input'];
}>;

export type GQMeasurementVerificationDetailQuery = {
  __typename?: 'Query';
  measurementVerificationItem: {
    __typename?: 'MeasurementVerificationItem';
    id: string;
    name: string;
    datasetName: string;
    description: string;
    status: GQMeasurementVerificationItemStatus;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      coverageInterval: YMInterval;
    };
    assignee: {
      __typename?: 'User';
      id: string;
      name: string;
      email: string;
    } | null;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
        summaryWithMetadata: {
          __typename?: 'DatasourceSummaryWithMetadata';
          id: string;
          summaries: Array<{
            __typename?: 'DatasourceSummary';
            id: string;
            signedUrl: string;
          }>;
        };
      }>;
    } | null;
    questions: {
      __typename?: 'MeasurementVerificationItemQuestionConnection';
      edges: Array<{
        __typename?: 'MeasurementVerificationItemQuestionEdge';
        node: {
          __typename?: 'MeasurementVerificationItemQuestion';
          id: string;
          section: GQMeasurementVerificationSection;
          status: GQMeasurementVerificationQuestionStatus;
          contentKind: GQMeasurementVerificationQuestionContentKind;
          contentTitle: string | null;
          contentSubtitle: string | null;
          questionKind: GQMeasurementVerificationQuestionKind;
          questionPrompt: string;
          answerText: string | null;
          rank: number | null;
          discussion: {
            __typename?: 'DataIssue';
            id: string;
            title: string;
            description: string | null;
            state: GQDataIssueState;
            createdAt: Date;
            isCustomerInitiated: boolean;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              completedAt: Date | null;
            };
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  message: string;
                  isPublished: boolean;
                  createdAt: Date;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          } | null;
          publisher: {
            __typename?: 'WatershedEmployee';
            id: string;
            name: string;
          } | null;
          contentMetadata:
            | {
                __typename: 'MeasurementVerificationItemContentMetadataFileOverlaps';
                id: string;
                files: Array<{
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                  uploader:
                    | { __typename?: 'User'; id: string; name: string }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        name: string;
                      }
                    | null;
                  userUploadTask: {
                    __typename?: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    };
                  } | null;
                }>;
              }
            | {
                __typename: 'MeasurementVerificationItemContentMetadataQuantityTrend';
                id: string;
                aggregateField: string | null;
                filters: Array<{
                  __typename?: 'DatasetSummaryFilter';
                  field: string;
                  operator: GQFilterOperator;
                  value: Array<string>;
                }> | null;
                anomaly: {
                  __typename?: 'QuantityTrendAnomaly';
                  highlightMonth: YearMonth;
                } | null;
                frozenDatasourceSummaries: Array<{
                  __typename?: 'FrozenDatasourceSummary';
                  isStale: boolean;
                  signedUrl: string;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  };
                }> | null;
              }
            | {
                __typename: 'MeasurementVerificationItemContentMetadataTopAccounts';
                id: string;
                frozenDatasourceSummaries: Array<{
                  __typename?: 'FrozenDatasourceSummary';
                  isStale: boolean;
                  signedUrl: string;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  };
                }> | null;
              };
        } | null;
      }>;
    };
  };
};

export type GQAnswerMeasurementVerificationItemQuestionMutationVariables =
  Exact<{
    input: GQAnswerMeasurementVerificationItemQuestionInput;
  }>;

export type GQAnswerMeasurementVerificationItemQuestionMutation = {
  __typename?: 'Mutation';
  answerMeasurementVerificationItemQuestion: {
    __typename?: 'AnswerMeasurementVerificationItemQuestionPayload';
    measurementTask:
      | {
          __typename?: 'MeasurementParentTask';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          lockState: GQApprovalTargetLockState | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          subTasks: Array<
            | {
                __typename?: 'MeasurementParentTask';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
            | {
                __typename?: 'MeasurementTaskFlat';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
          >;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          approvers: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
      | { __typename?: 'MeasurementTaskFlat' };
    question: {
      __typename?: 'MeasurementVerificationItemQuestion';
      id: string;
      section: GQMeasurementVerificationSection;
      status: GQMeasurementVerificationQuestionStatus;
      contentKind: GQMeasurementVerificationQuestionContentKind;
      contentTitle: string | null;
      contentSubtitle: string | null;
      questionKind: GQMeasurementVerificationQuestionKind;
      questionPrompt: string;
      answerText: string | null;
      rank: number | null;
      item: {
        __typename?: 'MeasurementVerificationItem';
        id: string;
        status: GQMeasurementVerificationItemStatus;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            userUploadTasks: {
              __typename?: 'UserUploadTaskConnection';
              edges: Array<{
                __typename?: 'UserUploadTaskEdge';
                node: {
                  __typename?: 'UserUploadTask';
                  id: string;
                  state: GQUserUploadTaskState;
                } | null;
              } | null>;
            };
            firstUnansweredMeasurementVerificationQuestion: {
              __typename?: 'MeasurementVerificationItemQuestion';
              id: string;
            } | null;
          }>;
        } | null;
      };
      discussion: {
        __typename?: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        createdAt: Date;
        isCustomerInitiated: boolean;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          completedAt: Date | null;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              isPublished: boolean;
              createdAt: Date;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
      publisher: {
        __typename?: 'WatershedEmployee';
        id: string;
        name: string;
      } | null;
      contentMetadata:
        | {
            __typename: 'MeasurementVerificationItemContentMetadataFileOverlaps';
            id: string;
            files: Array<{
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              uploader:
                | { __typename?: 'User'; id: string; name: string }
                | { __typename?: 'WatershedEmployee'; id: string; name: string }
                | null;
              userUploadTask: {
                __typename?: 'UserUploadTask';
                id: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                };
              } | null;
            }>;
          }
        | {
            __typename: 'MeasurementVerificationItemContentMetadataQuantityTrend';
            id: string;
            aggregateField: string | null;
            filters: Array<{
              __typename?: 'DatasetSummaryFilter';
              field: string;
              operator: GQFilterOperator;
              value: Array<string>;
            }> | null;
            anomaly: {
              __typename?: 'QuantityTrendAnomaly';
              highlightMonth: YearMonth;
            } | null;
            frozenDatasourceSummaries: Array<{
              __typename?: 'FrozenDatasourceSummary';
              isStale: boolean;
              signedUrl: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
              };
            }> | null;
          }
        | {
            __typename: 'MeasurementVerificationItemContentMetadataTopAccounts';
            id: string;
            frozenDatasourceSummaries: Array<{
              __typename?: 'FrozenDatasourceSummary';
              isStale: boolean;
              signedUrl: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
              };
            }> | null;
          };
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      setupAt: Date | null;
      completedAt: Date | null;
      footprintStatus: GQMeasurementProjectFootprintStatus;
      coverageInterval: YMInterval;
      deadline: Date | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              userCanAccessDetails: boolean | null;
              userCanApproveDatasource: boolean | null;
              userCanManageDatasource: boolean | null;
              isUserConfirmed: boolean | null;
              hasIntegration: boolean;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  state: GQDataIssueState;
                  title: string;
                } | null;
              } | null>;
            } | null;
          } | null;
        } | null>;
      };
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        lockState: GQApprovalTargetLockState | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          didLatestEmailBounce: boolean;
        }>;
        approvers: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
        areUploadsComplete: boolean;
        isUsableInFootprint: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          } | null;
        }>;
      };
      climateProgramProjects: Array<{
        __typename: 'ClimateProgramProject';
        id: string;
        name: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          measurementObjectCopy: string | null;
          kind: GQClimateProgramProjectKind;
          routeType: string | null;
          name: string;
        } | null;
      }>;
    } | null;
  };
};

export type GQActiveOrgProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GQActiveOrgProfileQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    profile: {
      __typename?: 'OrgProfile';
      id: string;
      name: string | null;
      fiscalYearStartMonth: number | null;
      country: string | null;
      industry: string | null;
      isCompanyDataConfirmed: boolean | null;
      hasMeasuredFootprintOutsideOfWatershed: GQYesNoUnknown | null;
      lastNonWatershedMeasurementYearString: string | null;
      hasClimateCommitments: GQYesNoUnknown | null;
      climateCommitments: Array<GQCompanyClimateCommitmentKind> | null;
      otherClimateCommitment: string | null;
      climateGoals: Array<string> | null;
      climateMotivation: string | null;
      completedAt: Date | null;
      updatedAt: Date | null;
      completedBy: { __typename?: 'User'; id: string; name: string } | null;
      updatedBy: { __typename?: 'User'; id: string; name: string } | null;
    };
  };
};

export type GQUpdateOrgProfileMutationVariables = Exact<{
  input: GQUpdateOrgProfileInput;
}>;

export type GQUpdateOrgProfileMutation = {
  __typename?: 'Mutation';
  updateOrgProfile: {
    __typename?: 'UpdateOrgProfilePayload';
    orgProfile: {
      __typename?: 'OrgProfile';
      id: string;
      name: string | null;
      fiscalYearStartMonth: number | null;
      country: string | null;
      industry: string | null;
      isCompanyDataConfirmed: boolean | null;
      hasMeasuredFootprintOutsideOfWatershed: GQYesNoUnknown | null;
      lastNonWatershedMeasurementYearString: string | null;
      hasClimateCommitments: GQYesNoUnknown | null;
      climateCommitments: Array<GQCompanyClimateCommitmentKind> | null;
      otherClimateCommitment: string | null;
      climateGoals: Array<string> | null;
      climateMotivation: string | null;
      completedAt: Date | null;
      updatedAt: Date | null;
      completedBy: { __typename?: 'User'; id: string; name: string } | null;
      updatedBy: { __typename?: 'User'; id: string; name: string } | null;
    };
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      country: string | null;
      fiscalYearStartMonth: number | null;
    };
  };
};

export type GQOrganizationForOnboardingFlowFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  country: string | null;
  fiscalYearStartMonth: number | null;
};

export type GQOrgProfileForOnboardingFlowFragment = {
  __typename?: 'OrgProfile';
  id: string;
  name: string | null;
  fiscalYearStartMonth: number | null;
  country: string | null;
  industry: string | null;
  isCompanyDataConfirmed: boolean | null;
  hasMeasuredFootprintOutsideOfWatershed: GQYesNoUnknown | null;
  lastNonWatershedMeasurementYearString: string | null;
  hasClimateCommitments: GQYesNoUnknown | null;
  climateCommitments: Array<GQCompanyClimateCommitmentKind> | null;
  otherClimateCommitment: string | null;
  climateGoals: Array<string> | null;
  climateMotivation: string | null;
  completedAt: Date | null;
  updatedAt: Date | null;
  completedBy: { __typename?: 'User'; id: string; name: string } | null;
  updatedBy: { __typename?: 'User'; id: string; name: string } | null;
};

export type GQCreateOrgStructureImportTemplateMutationVariables = Exact<{
  input: GQCreateOrgStructureImportTemplateInput;
}>;

export type GQCreateOrgStructureImportTemplateMutation = {
  __typename?: 'Mutation';
  createOrgStructureImportTemplate: {
    __typename?: 'CreateOrgStructureImportTemplatePayload';
    oneSchemaTemplateKey: string;
  };
};

export type GQFootprintAnalysisTopVendorFieldsFragment = {
  __typename?: 'FootprintAnalysisTopVendor';
  proportion: number;
  series: {
    __typename?: 'FootprintAnalysisTimeseriesSeries';
    data: Array<{
      __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
      date: Date;
      value: number;
    }>;
  };
  vendorInfo: {
    __typename?: 'Vendor';
    vendor: string;
    program: string | null;
    programUrl: string | null;
    targetYear: number | null;
    sbtTarget: string | null;
  };
};

export type GQGetFootprintAnalysisTopVendorsDataQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
  oneYearAnalysisFilters: GQFootprintAnalysisFilters;
}>;

export type GQGetFootprintAnalysisTopVendorsDataQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    topVendors: {
      __typename?: 'FootprintAnalysisTopVendors';
      vendors: Array<{
        __typename?: 'FootprintAnalysisTopVendor';
        proportion: number;
        series: {
          __typename?: 'FootprintAnalysisTimeseriesSeries';
          data: Array<{
            __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
            date: Date;
            value: number;
          }>;
        };
        vendorInfo: {
          __typename?: 'Vendor';
          vendor: string;
          program: string | null;
          programUrl: string | null;
          targetYear: number | null;
          sbtTarget: string | null;
        };
      }>;
      summarySentences: {
        __typename?: 'FootprintAnalysisTopVendorsSummarySentences';
        all: string;
        sbt: string;
        netZero: string;
        other: string;
      };
    };
  } | null;
};

export type GQGetOrganizationPermissionsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetOrganizationPermissionsQuery = {
  __typename?: 'Query';
  permissions: {
    __typename?: 'PermissionConnection';
    edges: Array<{
      __typename?: 'PermissionEdge';
      node: {
        __typename?: 'PermissionItem';
        id: string;
        permission: GQPermissionType;
        object:
          | { __typename?: 'Company'; id: string; name: string }
          | { __typename?: 'CompanySurvey'; id: string; name: string }
          | { __typename?: 'Dataset'; id: string; name: string }
          | { __typename?: 'Datasource'; id: string; name: string }
          | { __typename?: 'EngagementTask'; id: string; name: string }
          | { __typename?: 'Fund'; id: string; name: string }
          | null;
        user: {
          __typename?: 'User';
          id: string;
          displayName: string;
          name: string;
          orgAccessId: string;
        } | null;
      } | null;
    } | null>;
  };
};

export type GQSupplierForTeamsTableFieldsFragment = {
  __typename?: 'Supplier';
  id: string;
  name: string;
  company: { __typename?: 'Company'; id: string } | null;
};

export type GQGetOrganizationRolesAndPermissionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetOrganizationRolesAndPermissionsQuery = {
  __typename?: 'Query';
  roles: {
    __typename?: 'RoleConnection';
    edges: Array<{
      __typename?: 'RoleEdge';
      node: {
        __typename?: 'Role';
        id: string;
        name: string;
        description: string | null;
        permissions: Array<{
          __typename?: 'PermissionItem';
          id: string;
          objectId: string | null;
          permission: GQPermissionType;
          revokedAt: Date | null;
          object:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanySurvey'; id: string; name: string }
            | { __typename: 'Dataset'; id: string; name: string }
            | { __typename: 'Datasource'; id: string; name: string }
            | { __typename: 'EngagementTask'; id: string; name: string }
            | { __typename: 'Fund'; id: string; name: string }
            | null;
        }>;
        users: Array<{
          __typename?: 'UserRoleAssignment';
          id: string;
          user: {
            __typename?: 'User';
            id: string;
            displayName: string;
            name: string;
          };
        }>;
        apiKeys: Array<{
          __typename?: 'ApiKeyRoleAssignment';
          id: string;
          apiKey: {
            __typename?: 'ApiKey';
            id: string;
            name: string;
            description: string | null;
          };
        }>;
      } | null;
    } | null>;
  };
};

export type GQUserPermissionRequestFieldsFragment = {
  __typename?: 'UserPermissionRequest';
  id: string;
  orgId: string;
  name: string;
  email: string;
  state: GQUserPermissionRequestState;
  userId: string | null;
  responderUser: string | null;
  respondedAt: Date | null;
  creatorOrgId: string | null;
  creatorUser: string | null;
  permission: GQPermissionType | null;
};

export type GQGetPendingUserPermissionRequestsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetPendingUserPermissionRequestsQuery = {
  __typename?: 'Query';
  pendingUserPermissionRequests: Array<{
    __typename?: 'UserPermissionRequest';
    id: string;
    orgId: string;
    name: string;
    email: string;
    state: GQUserPermissionRequestState;
    userId: string | null;
    responderUser: string | null;
    respondedAt: Date | null;
    creatorOrgId: string | null;
    creatorUser: string | null;
    permission: GQPermissionType | null;
  }>;
};

export type GQUpdateUserPermissionRequestMutationVariables = Exact<{
  input: GQUpdateUserPermissionRequestInput;
}>;

export type GQUpdateUserPermissionRequestMutation = {
  __typename?: 'Mutation';
  updateUserPermissionRequest: {
    __typename?: 'UpdateUserPermissionRequestPayload';
    userPermissionRequest: {
      __typename?: 'UserPermissionRequest';
      id: string;
      orgId: string;
      name: string;
      email: string;
      state: GQUserPermissionRequestState;
      userId: string | null;
      responderUser: string | null;
      respondedAt: Date | null;
      creatorOrgId: string | null;
      creatorUser: string | null;
      permission: GQPermissionType | null;
    } | null;
  } | null;
};

export type GQDeletePortfolioMutationVariables = Exact<{
  input: GQDeleteMarketplacePortfolioInput;
}>;

export type GQDeletePortfolioMutation = {
  __typename?: 'Mutation';
  deleteMarketplacePortfolio: {
    __typename?: 'DeleteMarketplacePortfolioPayload';
    success: boolean;
  } | null;
};

export type GQSyncPortfolioMutationVariables = Exact<{
  portfolio: GQUpdateMarketplacePortfolioInput;
  items: GQSyncMarketplacePortfolioItemsInput;
}>;

export type GQSyncPortfolioMutation = {
  __typename?: 'Mutation';
  syncMarketplacePortfolioItems: {
    __typename?: 'SyncMarketplacePortfolioItemsPayload';
    portfolio: { __typename?: 'MarketplacePortfolio'; id: string };
  } | null;
  updateMarketplacePortfolio: {
    __typename?: 'UpdateMarketplacePortfolioPayload';
    portfolio: {
      __typename?: 'MarketplacePortfolio';
      id: string;
      name: string;
      notes: string;
      budgetUsdCents: number;
      footprintTco2e: number | null;
      interval: YMInterval | null;
      investInRd: boolean;
      targetAmount: number | null;
      targetFraction: number | null;
      offsetTargetAmount: number | null;
      offsetTargetFraction: number | null;
      cleanPowerTargetAmount: number | null;
      portfolioItems: {
        __typename?: 'MarketplacePortfolioItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePortfolioItemEdge';
          node: {
            __typename?: 'MarketplacePortfolioItem';
            id: string;
            amount: number;
            priceUsdCents: number;
            fraction: number;
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
            };
          } | null;
        }>;
      };
      purchase: {
        __typename?: 'MarketplacePurchase';
        id: string;
        customerStatus: GQCustomerStatus;
      } | null;
    };
  } | null;
};

export type GQDuplicatePortfolioMutationVariables = Exact<{
  input: GQDuplicateMarketplacePortfolioInput;
}>;

export type GQDuplicatePortfolioMutation = {
  __typename?: 'Mutation';
  duplicateMarketplacePortfolio: {
    __typename?: 'DuplicateMarketplacePortfolioPayload';
    portfolio: {
      __typename?: 'MarketplacePortfolio';
      id: string;
      name: string;
      notes: string;
      budgetUsdCents: number;
      footprintTco2e: number | null;
      interval: YMInterval | null;
      investInRd: boolean;
      targetAmount: number | null;
      targetFraction: number | null;
      offsetTargetAmount: number | null;
      offsetTargetFraction: number | null;
      cleanPowerTargetAmount: number | null;
      portfolioItems: {
        __typename?: 'MarketplacePortfolioItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePortfolioItemEdge';
          node: {
            __typename?: 'MarketplacePortfolioItem';
            id: string;
            amount: number;
            priceUsdCents: number;
            fraction: number;
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
            };
          } | null;
        }>;
      };
      purchase: {
        __typename?: 'MarketplacePurchase';
        id: string;
        customerStatus: GQCustomerStatus;
      } | null;
    };
  } | null;
};

export type GQCreatePortfolioItemMutationVariables = Exact<{
  input: GQCreateMarketplacePortfolioItemInput;
}>;

export type GQCreatePortfolioItemMutation = {
  __typename?: 'Mutation';
  createMarketplacePortfolioItem: {
    __typename?: 'CreateMarketplacePortfolioItemPayload';
    portfolioItem: {
      __typename?: 'MarketplacePortfolioItem';
      id: string;
      amount: number;
      priceUsdCents: number;
      fraction: number;
      archetype: { __typename?: 'MarketplaceProjectArchetype'; id: string };
    };
  } | null;
};

export type GQPortfolioForPortfolioBuilderFragment = {
  __typename?: 'MarketplacePortfolio';
  id: string;
  name: string;
  notes: string;
  budgetUsdCents: number;
  footprintTco2e: number | null;
  interval: YMInterval | null;
  investInRd: boolean;
  targetAmount: number | null;
  targetFraction: number | null;
  offsetTargetAmount: number | null;
  offsetTargetFraction: number | null;
  cleanPowerTargetAmount: number | null;
  portfolioItems: {
    __typename?: 'MarketplacePortfolioItemConnection';
    edges: Array<{
      __typename?: 'MarketplacePortfolioItemEdge';
      node: {
        __typename?: 'MarketplacePortfolioItem';
        id: string;
        amount: number;
        priceUsdCents: number;
        fraction: number;
        archetype: { __typename?: 'MarketplaceProjectArchetype'; id: string };
      } | null;
    }>;
  };
  purchase: {
    __typename?: 'MarketplacePurchase';
    id: string;
    customerStatus: GQCustomerStatus;
  } | null;
};

export type GQPortfolioItemForPortfolioBuilderFragment = {
  __typename?: 'MarketplacePortfolioItem';
  id: string;
  amount: number;
  priceUsdCents: number;
  fraction: number;
  archetype: { __typename?: 'MarketplaceProjectArchetype'; id: string };
};

export type GQProjectArchetypeForPortfolioBuilderFragment = {
  __typename?: 'MarketplaceProjectArchetype';
  id: string;
  name: string;
  mechanism: GQMarketplaceProjectArchetypeMechanism;
  kind: GQMarketplaceProjectArchetypeKind;
  unit: GQMarketplaceProjectArchetypeUnit;
  descriptionMd: string;
  summaryMd: string;
  imageUrl: string | null;
  bannerImageUrl: string | null;
  additionalityRating: number | null;
  permanenceRating: string | null;
  medianPriceWithFeeUsdCents: number | null;
  availability: number;
  isUserVisible: boolean;
};

export type GQAllPortfoliosQueryVariables = Exact<{ [key: string]: never }>;

export type GQAllPortfoliosQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  marketplacePortfolios: {
    __typename?: 'MarketplacePortfolioConnection';
    edges: Array<{
      __typename?: 'MarketplacePortfolioEdge';
      node: {
        __typename?: 'MarketplacePortfolio';
        id: string;
        name: string;
        notes: string;
        budgetUsdCents: number;
        footprintTco2e: number | null;
        interval: YMInterval | null;
        investInRd: boolean;
        targetAmount: number | null;
        targetFraction: number | null;
        offsetTargetAmount: number | null;
        offsetTargetFraction: number | null;
        cleanPowerTargetAmount: number | null;
        portfolioItems: {
          __typename?: 'MarketplacePortfolioItemConnection';
          edges: Array<{
            __typename?: 'MarketplacePortfolioItemEdge';
            node: {
              __typename?: 'MarketplacePortfolioItem';
              id: string;
              amount: number;
              priceUsdCents: number;
              fraction: number;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
              };
            } | null;
          }>;
        };
        purchase: {
          __typename?: 'MarketplacePurchase';
          id: string;
          customerStatus: GQCustomerStatus;
        } | null;
      } | null;
    }>;
  };
  portfolioBuilderArchetypes: {
    __typename?: 'MarketplaceProjectArchetypeConnection';
    edges: Array<{
      __typename?: 'MarketplaceProjectArchetypeEdge';
      node: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
        mechanism: GQMarketplaceProjectArchetypeMechanism;
        kind: GQMarketplaceProjectArchetypeKind;
        unit: GQMarketplaceProjectArchetypeUnit;
        descriptionMd: string;
        summaryMd: string;
        imageUrl: string | null;
        bannerImageUrl: string | null;
        additionalityRating: number | null;
        permanenceRating: string | null;
        medianPriceWithFeeUsdCents: number | null;
        availability: number;
        isUserVisible: boolean;
      } | null;
    } | null>;
  };
};

export type GQCreatePortfolioMutationVariables = Exact<{
  input: GQCreateMarketplacePortfolioInput;
}>;

export type GQCreatePortfolioMutation = {
  __typename?: 'Mutation';
  createMarketplacePortfolio: {
    __typename?: 'CreateMarketplacePortfolioPayload';
    portfolio: {
      __typename?: 'MarketplacePortfolio';
      id: string;
      name: string;
      notes: string;
      budgetUsdCents: number;
      footprintTco2e: number | null;
      interval: YMInterval | null;
      investInRd: boolean;
      targetAmount: number | null;
      targetFraction: number | null;
      offsetTargetAmount: number | null;
      offsetTargetFraction: number | null;
      cleanPowerTargetAmount: number | null;
      portfolioItems: {
        __typename?: 'MarketplacePortfolioItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePortfolioItemEdge';
          node: {
            __typename?: 'MarketplacePortfolioItem';
            id: string;
            amount: number;
            priceUsdCents: number;
            fraction: number;
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
            };
          } | null;
        }>;
      };
      purchase: {
        __typename?: 'MarketplacePurchase';
        id: string;
        customerStatus: GQCustomerStatus;
      } | null;
    };
  } | null;
};

export type GQCreateMarketplacePurchaseFromPortfolioMutationVariables = Exact<{
  input: GQCreateMarketplacePurchaseFromPortfolioInput;
}>;

export type GQCreateMarketplacePurchaseFromPortfolioMutation = {
  __typename?: 'Mutation';
  createMarketplacePurchaseFromPortfolio: {
    __typename?: 'CreateMarketplacePurchaseFromPortfolioPayload';
    purchase: {
      __typename?: 'MarketplacePurchase';
      id: string;
      customerStatus: GQCustomerStatus;
    };
    portfolio: {
      __typename?: 'MarketplacePortfolio';
      id: string;
      name: string;
      notes: string;
      budgetUsdCents: number;
      footprintTco2e: number | null;
      interval: YMInterval | null;
      investInRd: boolean;
      targetAmount: number | null;
      targetFraction: number | null;
      offsetTargetAmount: number | null;
      offsetTargetFraction: number | null;
      cleanPowerTargetAmount: number | null;
      portfolioItems: {
        __typename?: 'MarketplacePortfolioItemConnection';
        edges: Array<{
          __typename?: 'MarketplacePortfolioItemEdge';
          node: {
            __typename?: 'MarketplacePortfolioItem';
            id: string;
            amount: number;
            priceUsdCents: number;
            fraction: number;
            archetype: {
              __typename?: 'MarketplaceProjectArchetype';
              id: string;
            };
          } | null;
        }>;
      };
      purchase: {
        __typename?: 'MarketplacePurchase';
        id: string;
        customerStatus: GQCustomerStatus;
      } | null;
    };
  } | null;
};

export type GQVendorFieldsFragment = {
  __typename?: 'Vendor';
  vendor: string;
  program: string | null;
  targetYear: number | null;
  sbtTarget: string | null;
};

export type GQSimpleTimeseriesFieldsFragment = {
  __typename?: 'SimpleTimeseries';
  base: Date;
  frequency: GQTimeseriesFrequency;
  values: Array<number>;
};

export type GQCommonPlanTargetFieldsFragment = {
  __typename?: 'PlanTarget';
  id: string;
  createdAt: Date | null;
  description: string;
  reportDescription: string;
  customNotes: string;
  baseYear: YearMonth;
  intensityType: GQPlanTargetIntensityType;
  customIntensityConfigId: string | null;
  comparisonType: GQPlanTargetTargetComparisonType;
  reductionRate: GQTargetReductionRate;
  deletedAt: Date | null;
  assignedTo: string | null;
  parentTargetId: string | null;
  interimTargetDate: YearMonth | null;
  interimTargetValue: number | null;
  emissionsTargetCustom: {
    __typename?: 'SimpleTimeseries';
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  filters: {
    __typename?: 'FilterExpressionGroup';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitive';
      field: GQFilterFieldLegacy;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  };
};

export type GQPlanTargetFieldsFragment = {
  __typename?: 'PlanTarget';
  id: string;
  createdAt: Date | null;
  description: string;
  reportDescription: string;
  customNotes: string;
  baseYear: YearMonth;
  intensityType: GQPlanTargetIntensityType;
  customIntensityConfigId: string | null;
  comparisonType: GQPlanTargetTargetComparisonType;
  reductionRate: GQTargetReductionRate;
  deletedAt: Date | null;
  assignedTo: string | null;
  parentTargetId: string | null;
  interimTargetDate: YearMonth | null;
  interimTargetValue: number | null;
  emissionsTargetCustom: {
    __typename?: 'SimpleTimeseries';
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  filters: {
    __typename?: 'FilterExpressionGroup';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitive';
      field: GQFilterFieldLegacy;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  };
};

export type GQPlanVariablesFieldsFragment = {
  __typename?: 'PlanVariables';
  commitmentsHasNetZero: boolean;
  commitmentsNetZeroYear: number;
  cleanPowerStart: YearMonth;
  commitmentsHasSBT: boolean;
  commitmentsSBTTargetYear: number;
  commitmentsSBTScope12: GQSbtScope1And2Target;
  commitmentsSBTScope3: GQSbtScope3Target;
  commitmentsSBTTermLength: GQSbtTermLength;
  commitmentsSBTSubmissionDate: YearMonth;
  flightsPerEmployeeForecastEnd: number;
  percentWorkFromHomeForecastEnd: number;
  netZeroSuppliersReductionPercent: number;
};

export type GQForecastFieldsForPlanFragment = {
  __typename?: 'Forecast';
  id: string;
  interval: YMInterval;
  referencePeriodInterval: YMInterval;
  overrideFootprintKind: string | null;
  validReferenceInterval: YMInterval;
  footprintSnapshotId: string;
  scenarios: Array<{
    __typename?: 'ForecastScenario';
    id: string;
    name: string;
    description: string | null;
    isDefault: boolean;
    updatedByName: string | null;
    updatedAt: Date;
    historicalUserInputtedGrowthFactorDataPoints: Array<{
      __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
      date: Date;
      growthFactors: Array<{
        __typename?: 'GrowthFactorDataPoint';
        growthFactorType: GQGrowthFactorType;
        customIntensityConfigId: string | null;
        value: number | null;
      }>;
    }>;
    growthForecasts: Array<{
      __typename?: 'GrowthForecast';
      id: string;
      growthFactorType: GQGrowthFactorType;
      customIntensityConfigId: string | null;
      isDefaultScalingFactor: boolean;
      hasUserInputtedHistoricalPeriod: boolean;
      forecast: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      scalingFactorFilters: {
        __typename?: 'FilterExpressionGroupWithNewFilters';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitiveWithNewFilters';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }>;
  }>;
  historicalPeriod: {
    __typename?: 'ForecastPeriod';
    interval: YMInterval;
    data: Array<{
      __typename?: 'ForecastPeriodPoint';
      date: Date;
      headcount: number | null;
      revenue: number | null;
      customGrowthFactors: Array<{
        __typename?: 'ForecastCustomIntensityDataPoint';
        customIntensityId: string;
        value: number | null;
      }>;
    }>;
  };
  customIntensityConfigs: Array<{
    __typename?: 'CustomIntensityConfig';
    id: string;
    name: string;
    description: string;
    unit: string | null;
    humanReadable: boolean;
    quantityType: GQCustomIntensityConfigQuantityType;
    isSbtValidated: boolean | null;
  }>;
  businessCategories: Array<{
    __typename?: 'CategorizedEmissionData';
    businessCategory: string;
  }>;
};

export type GQPlanWithForecastFieldsFragment = {
  __typename?: 'Plan';
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  updatedByName: string | null;
  state: GQPlanState;
  baselineYearStart: YearMonth;
  fiscalMonth: number;
  isSbtValidated: boolean;
  hideCarbonAvoidance: boolean;
  forecast: {
    __typename?: 'Forecast';
    id: string;
    interval: YMInterval;
    referencePeriodInterval: YMInterval;
    overrideFootprintKind: string | null;
    validReferenceInterval: YMInterval;
    footprintSnapshotId: string;
    scenarios: Array<{
      __typename?: 'ForecastScenario';
      id: string;
      name: string;
      description: string | null;
      isDefault: boolean;
      updatedByName: string | null;
      updatedAt: Date;
      historicalUserInputtedGrowthFactorDataPoints: Array<{
        __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
        date: Date;
        growthFactors: Array<{
          __typename?: 'GrowthFactorDataPoint';
          growthFactorType: GQGrowthFactorType;
          customIntensityConfigId: string | null;
          value: number | null;
        }>;
      }>;
      growthForecasts: Array<{
        __typename?: 'GrowthForecast';
        id: string;
        growthFactorType: GQGrowthFactorType;
        customIntensityConfigId: string | null;
        isDefaultScalingFactor: boolean;
        hasUserInputtedHistoricalPeriod: boolean;
        forecast: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        scalingFactorFilters: {
          __typename?: 'FilterExpressionGroupWithNewFilters';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitiveWithNewFilters';
            field: string;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        } | null;
      }>;
    }>;
    historicalPeriod: {
      __typename?: 'ForecastPeriod';
      interval: YMInterval;
      data: Array<{
        __typename?: 'ForecastPeriodPoint';
        date: Date;
        headcount: number | null;
        revenue: number | null;
        customGrowthFactors: Array<{
          __typename?: 'ForecastCustomIntensityDataPoint';
          customIntensityId: string;
          value: number | null;
        }>;
      }>;
    };
    customIntensityConfigs: Array<{
      __typename?: 'CustomIntensityConfig';
      id: string;
      name: string;
      description: string;
      unit: string | null;
      humanReadable: boolean;
      quantityType: GQCustomIntensityConfigQuantityType;
      isSbtValidated: boolean | null;
    }>;
    businessCategories: Array<{
      __typename?: 'CategorizedEmissionData';
      businessCategory: string;
    }>;
  };
  variables: {
    __typename?: 'PlanVariables';
    commitmentsHasNetZero: boolean;
    commitmentsNetZeroYear: number;
    cleanPowerStart: YearMonth;
    commitmentsHasSBT: boolean;
    commitmentsSBTTargetYear: number;
    commitmentsSBTScope12: GQSbtScope1And2Target;
    commitmentsSBTScope3: GQSbtScope3Target;
    commitmentsSBTTermLength: GQSbtTermLength;
    commitmentsSBTSubmissionDate: YearMonth;
    flightsPerEmployeeForecastEnd: number;
    percentWorkFromHomeForecastEnd: number;
    netZeroSuppliersReductionPercent: number;
  };
  reduxInitiatives: Array<
    | {
        __typename: 'FootprintSliceInitiative';
        id: string;
        name: string;
        enabled: boolean;
        description: string | null;
        scheduleType: GQScheduleType;
        deletedAt: Date | null;
        createdAt: Date;
        reductionTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        costTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroupWithNewFilters';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitiveWithNewFilters';
            field: string;
            value: Array<string>;
            operator: GQFilterOperator;
          }>;
        };
      }
    | {
        __typename: 'PrecomputedActivityBasedInitiative';
        id: string;
        name: string;
        enabled: boolean;
        description: string | null;
        scheduleType: GQScheduleType;
        deletedAt: Date | null;
        createdAt: Date;
        precomputedLever: GQPrecomputedLever;
        implementationTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        costTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroupWithNewFilters';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitiveWithNewFilters';
            field: string;
            value: Array<string>;
            operator: GQFilterOperator;
          }>;
        };
      }
  >;
  targets: Array<{
    __typename?: 'PlanTarget';
    id: string;
    createdAt: Date | null;
    description: string;
    reportDescription: string;
    customNotes: string;
    baseYear: YearMonth;
    intensityType: GQPlanTargetIntensityType;
    customIntensityConfigId: string | null;
    comparisonType: GQPlanTargetTargetComparisonType;
    reductionRate: GQTargetReductionRate;
    deletedAt: Date | null;
    assignedTo: string | null;
    parentTargetId: string | null;
    interimTargetDate: YearMonth | null;
    interimTargetValue: number | null;
    emissionsTargetCustom: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
    filters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    };
  }>;
  reduxCarbonOffsets: {
    __typename?: 'SimpleTimeseries';
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  reduxCarbonOffsetsCosts: {
    __typename?: 'SimpleTimeseries';
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  reduxCarbonRemoval: {
    __typename?: 'SimpleTimeseries';
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  reduxCarbonRemovalCosts: {
    __typename?: 'SimpleTimeseries';
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
};

export type GQPlanAndInitiativesQueryVariables = Exact<{
  planId: Scalars['ID']['input'];
  suppliersQueryInterval: Scalars['YMInterval']['input'];
  orgId: Scalars['ID']['input'];
}>;

export type GQPlanAndInitiativesQuery = {
  __typename?: 'Query';
  userCanViewReductions: boolean;
  userCanManageReductions: boolean;
  plan: {
    __typename?: 'Plan';
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    updatedByName: string | null;
    state: GQPlanState;
    baselineYearStart: YearMonth;
    fiscalMonth: number;
    isSbtValidated: boolean;
    hideCarbonAvoidance: boolean;
    forecast: {
      __typename?: 'Forecast';
      id: string;
      interval: YMInterval;
      referencePeriodInterval: YMInterval;
      overrideFootprintKind: string | null;
      validReferenceInterval: YMInterval;
      footprintSnapshotId: string;
      scenarios: Array<{
        __typename?: 'ForecastScenario';
        id: string;
        name: string;
        description: string | null;
        isDefault: boolean;
        updatedByName: string | null;
        updatedAt: Date;
        historicalUserInputtedGrowthFactorDataPoints: Array<{
          __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
          date: Date;
          growthFactors: Array<{
            __typename?: 'GrowthFactorDataPoint';
            growthFactorType: GQGrowthFactorType;
            customIntensityConfigId: string | null;
            value: number | null;
          }>;
        }>;
        growthForecasts: Array<{
          __typename?: 'GrowthForecast';
          id: string;
          growthFactorType: GQGrowthFactorType;
          customIntensityConfigId: string | null;
          isDefaultScalingFactor: boolean;
          hasUserInputtedHistoricalPeriod: boolean;
          forecast: {
            __typename?: 'SimpleTimeseries';
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          scalingFactorFilters: {
            __typename?: 'FilterExpressionGroupWithNewFilters';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitiveWithNewFilters';
              field: string;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          } | null;
        }>;
      }>;
      historicalPeriod: {
        __typename?: 'ForecastPeriod';
        interval: YMInterval;
        data: Array<{
          __typename?: 'ForecastPeriodPoint';
          date: Date;
          headcount: number | null;
          revenue: number | null;
          customGrowthFactors: Array<{
            __typename?: 'ForecastCustomIntensityDataPoint';
            customIntensityId: string;
            value: number | null;
          }>;
        }>;
      };
      customIntensityConfigs: Array<{
        __typename?: 'CustomIntensityConfig';
        id: string;
        name: string;
        description: string;
        unit: string | null;
        humanReadable: boolean;
        quantityType: GQCustomIntensityConfigQuantityType;
        isSbtValidated: boolean | null;
      }>;
      businessCategories: Array<{
        __typename?: 'CategorizedEmissionData';
        businessCategory: string;
      }>;
    };
    variables: {
      __typename?: 'PlanVariables';
      commitmentsHasNetZero: boolean;
      commitmentsNetZeroYear: number;
      cleanPowerStart: YearMonth;
      commitmentsHasSBT: boolean;
      commitmentsSBTTargetYear: number;
      commitmentsSBTScope12: GQSbtScope1And2Target;
      commitmentsSBTScope3: GQSbtScope3Target;
      commitmentsSBTTermLength: GQSbtTermLength;
      commitmentsSBTSubmissionDate: YearMonth;
      flightsPerEmployeeForecastEnd: number;
      percentWorkFromHomeForecastEnd: number;
      netZeroSuppliersReductionPercent: number;
    };
    reduxInitiatives: Array<
      | {
          __typename: 'FootprintSliceInitiative';
          id: string;
          name: string;
          enabled: boolean;
          description: string | null;
          scheduleType: GQScheduleType;
          deletedAt: Date | null;
          createdAt: Date;
          reductionTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          costTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          filters: {
            __typename?: 'FilterExpressionGroupWithNewFilters';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitiveWithNewFilters';
              field: string;
              value: Array<string>;
              operator: GQFilterOperator;
            }>;
          };
        }
      | {
          __typename: 'PrecomputedActivityBasedInitiative';
          id: string;
          name: string;
          enabled: boolean;
          description: string | null;
          scheduleType: GQScheduleType;
          deletedAt: Date | null;
          createdAt: Date;
          precomputedLever: GQPrecomputedLever;
          implementationTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          costTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          filters: {
            __typename?: 'FilterExpressionGroupWithNewFilters';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitiveWithNewFilters';
              field: string;
              value: Array<string>;
              operator: GQFilterOperator;
            }>;
          };
        }
    >;
    targets: Array<{
      __typename?: 'PlanTarget';
      id: string;
      createdAt: Date | null;
      description: string;
      reportDescription: string;
      customNotes: string;
      baseYear: YearMonth;
      intensityType: GQPlanTargetIntensityType;
      customIntensityConfigId: string | null;
      comparisonType: GQPlanTargetTargetComparisonType;
      reductionRate: GQTargetReductionRate;
      deletedAt: Date | null;
      assignedTo: string | null;
      parentTargetId: string | null;
      interimTargetDate: YearMonth | null;
      interimTargetValue: number | null;
      emissionsTargetCustom: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
    }>;
    reduxCarbonOffsets: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
    reduxCarbonOffsetsCosts: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
    reduxCarbonRemoval: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
    reduxCarbonRemovalCosts: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
  };
  suppliersWithTargetDisclosures: {
    __typename?: 'SuppliersWithInterval';
    suppliers: Array<{
      __typename?: 'Supplier';
      id: string;
      name: string;
      footprintVendorNames: Array<string>;
      cleanedFootprintVendorNames: Array<string>;
      totalSpending: number | null;
      company: { __typename?: 'Company'; id: string } | null;
      disclosures: Array<{
        __typename?: 'Disclosure';
        id: string;
        company: { __typename?: 'Company'; id: string } | null;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          publishingYear: number;
          reportType: GQExternalReportType;
          publicUrl: string;
          qualityScore: GQDisclosureQualityScore | null;
          cdpVendorData: {
            __typename?: 'CdpVendorData';
            id: string;
            publishingYear: number;
            reportingYear: number;
            scope1Nonzero: boolean;
            scope2Nonzero: boolean;
            scope3Nonzero: boolean;
            totalEmissionsNonzero: boolean;
            scope301Or302Nonzero: boolean;
            scope1Verified: boolean;
            scope2Verified: boolean;
            scope3Verified: boolean;
            pctEvaluationStatusesMatchResponse: number;
            disclosureQualityScore: GQDisclosureQualityScore;
            scope301EvaluationStatus: GQScope3EvaluationStatus;
            scope302EvaluationStatus: GQScope3EvaluationStatus;
            scope303EvaluationStatus: GQScope3EvaluationStatus;
            scope304EvaluationStatus: GQScope3EvaluationStatus;
            scope305EvaluationStatus: GQScope3EvaluationStatus;
            scope306EvaluationStatus: GQScope3EvaluationStatus;
            scope307EvaluationStatus: GQScope3EvaluationStatus;
            scope308EvaluationStatus: GQScope3EvaluationStatus;
            scope309EvaluationStatus: GQScope3EvaluationStatus;
            scope310EvaluationStatus: GQScope3EvaluationStatus;
            scope311EvaluationStatus: GQScope3EvaluationStatus;
            scope312EvaluationStatus: GQScope3EvaluationStatus;
            scope313EvaluationStatus: GQScope3EvaluationStatus;
            scope314EvaluationStatus: GQScope3EvaluationStatus;
            scope315EvaluationStatus: GQScope3EvaluationStatus;
            scope316EvaluationStatus: GQScope3EvaluationStatus;
            scope317EvaluationStatus: GQScope3EvaluationStatus;
          } | null;
        } | null;
        privateDisclosure: {
          __typename?: 'PrivateDisclosure';
          id: string;
          companyId: string | null;
          orgId: string;
          orgName: string;
          surveyId: string | null;
          privateDisclosureType: GQPrivateDisclosureType;
          createdAt: Date;
          disclosureQualityScore: GQDisclosureQualityScore | null;
          disclosureQualityExplanation: string | null;
          thirdPartyVerified: boolean;
          cdpVendorData: {
            __typename?: 'CdpVendorData';
            id: string;
            publishingYear: number;
            reportingYear: number;
            scope1Nonzero: boolean;
            scope2Nonzero: boolean;
            scope3Nonzero: boolean;
            totalEmissionsNonzero: boolean;
            scope301Or302Nonzero: boolean;
            scope1Verified: boolean;
            scope2Verified: boolean;
            scope3Verified: boolean;
            pctEvaluationStatusesMatchResponse: number;
            disclosureQualityScore: GQDisclosureQualityScore;
            scope301EvaluationStatus: GQScope3EvaluationStatus;
            scope302EvaluationStatus: GQScope3EvaluationStatus;
            scope303EvaluationStatus: GQScope3EvaluationStatus;
            scope304EvaluationStatus: GQScope3EvaluationStatus;
            scope305EvaluationStatus: GQScope3EvaluationStatus;
            scope306EvaluationStatus: GQScope3EvaluationStatus;
            scope307EvaluationStatus: GQScope3EvaluationStatus;
            scope308EvaluationStatus: GQScope3EvaluationStatus;
            scope309EvaluationStatus: GQScope3EvaluationStatus;
            scope310EvaluationStatus: GQScope3EvaluationStatus;
            scope311EvaluationStatus: GQScope3EvaluationStatus;
            scope312EvaluationStatus: GQScope3EvaluationStatus;
            scope313EvaluationStatus: GQScope3EvaluationStatus;
            scope314EvaluationStatus: GQScope3EvaluationStatus;
            scope315EvaluationStatus: GQScope3EvaluationStatus;
            scope316EvaluationStatus: GQScope3EvaluationStatus;
            scope317EvaluationStatus: GQScope3EvaluationStatus;
          } | null;
        } | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          publicDisclosureId: string | null;
          reportingYear: number;
          numEmployees: number | null;
          revenue: number | null;
          revenueCurrency: string | null;
          revenueUsd: number | null;
          percentageCleanEnergy: number | null;
          totalMwh: number | null;
          units: GQCompanyEmissionsUnits;
          expenseCategory: string | null;
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
        }> | null;
        targets: Array<{
          __typename?: 'DisclosureTargetDetails';
          id: string;
          name: string;
          description: string;
          baseYear: YearMonth;
          emissionsType: GQDisclosureTargetEmissionsType;
          reductionRate: GQDisclosureTargetReductionRate;
          intensityType: GQDisclosureTargetIntensityType;
          unit: string | null;
          unitDescription: string | null;
          filters: {
            __typename?: 'FilterExpressionGroup';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitive';
              field: GQFilterFieldLegacy;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          };
          emissionsTarget: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
        }> | null;
        initiatives: Array<{
          __typename?: 'DisclosureInitiative';
          id: string;
          name: string | null;
          initiativeType: GQDisclosureInitiativeType;
          description: string | null;
          startYearMonth: YearMonth | null;
          endYearMonth: YearMonth | null;
          intensityType: GQDisclosureTargetIntensityType | null;
          emissionsTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          } | null;
          filters: {
            __typename?: 'FilterExpressionGroup';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitive';
              field: GQFilterFieldLegacy;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          } | null;
        }> | null;
        climateCommitments: Array<
          | {
              __typename?: 'CarbonNeutralCommitment';
              targetYear: number | null;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename?: 'CleanEnergyCommitment';
              targetYear: number | null;
              targetPercentageCleanEnergy: number | null;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename?: 'NetZeroCommitment';
              targetYear: number | null;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename?: 'ScienceBasedTargetCommitment';
              submittedToSBTi: boolean;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
              commitment: {
                __typename?: 'SBTCommitment';
                id: string;
                stage: GQSbtCommitmentStage | null;
                targetClassification: GQSbtTargetClassification | null;
                nearTermTargetYear: number | null;
                longTermTargetYear: number | null;
                baselineYear: number | null;
                netZeroCommitted: boolean | null;
                commitmentType: GQSbtCommitmentType | null;
                commitmentDeadline: Date | null;
                netZeroTargetYear: number | null;
              };
            }
        > | null;
      }>;
      industryAverageAllocatedEmissions: {
        __typename?: 'SupplierHistoricalEmissions';
        scope1: number | null;
        scope2: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
        scope1Ratio: number | null;
        scope2Ratio: number | null;
        scope3Ratio: number | null;
        publishingYear: number | null;
        reportingYear: number;
        surveyId: string | null;
        source: GQEmissionsSource;
        revenueUsd: number | null;
        revenue: number | null;
        revenueCurrency: string | null;
        publicUrl: string | null;
        units: GQCompanyEmissionsUnits;
        expenseCategory: string | null;
      } | null;
    }>;
  };
};

export type GQSbtiTargetExclusionFieldsFragment = {
  __typename?: 'SbtiTargetExclusion';
  id: string;
  filters: {
    __typename?: 'FilterExpressionGroupWithNewFilters';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitiveWithNewFilters';
      field: string;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  };
};

export type GQReduxEnterprisePlanAndInitiativesQueryVariables = Exact<{
  planId: Scalars['ID']['input'];
}>;

export type GQReduxEnterprisePlanAndInitiativesQuery = {
  __typename?: 'Query';
  userCanViewReductions: boolean;
  userCanManageReductions: boolean;
  organization: {
    __typename?: 'Organization';
    id: string;
    sbtiTargetExclusions: Array<{
      __typename?: 'SbtiTargetExclusion';
      id: string;
      filters: {
        __typename?: 'FilterExpressionGroupWithNewFilters';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitiveWithNewFilters';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
    }>;
  };
  plan: {
    __typename?: 'Plan';
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    updatedByName: string | null;
    state: GQPlanState;
    baselineYearStart: YearMonth;
    fiscalMonth: number;
    isSbtValidated: boolean;
    hideCarbonAvoidance: boolean;
    forecast: {
      __typename?: 'Forecast';
      id: string;
      interval: YMInterval;
      referencePeriodInterval: YMInterval;
      overrideFootprintKind: string | null;
      validReferenceInterval: YMInterval;
      footprintSnapshotId: string;
      scenarios: Array<{
        __typename?: 'ForecastScenario';
        id: string;
        name: string;
        description: string | null;
        isDefault: boolean;
        updatedByName: string | null;
        updatedAt: Date;
        historicalUserInputtedGrowthFactorDataPoints: Array<{
          __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
          date: Date;
          growthFactors: Array<{
            __typename?: 'GrowthFactorDataPoint';
            growthFactorType: GQGrowthFactorType;
            customIntensityConfigId: string | null;
            value: number | null;
          }>;
        }>;
        growthForecasts: Array<{
          __typename?: 'GrowthForecast';
          id: string;
          growthFactorType: GQGrowthFactorType;
          customIntensityConfigId: string | null;
          isDefaultScalingFactor: boolean;
          hasUserInputtedHistoricalPeriod: boolean;
          forecast: {
            __typename?: 'SimpleTimeseries';
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          scalingFactorFilters: {
            __typename?: 'FilterExpressionGroupWithNewFilters';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitiveWithNewFilters';
              field: string;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          } | null;
        }>;
      }>;
      historicalPeriod: {
        __typename?: 'ForecastPeriod';
        interval: YMInterval;
        data: Array<{
          __typename?: 'ForecastPeriodPoint';
          date: Date;
          headcount: number | null;
          revenue: number | null;
          customGrowthFactors: Array<{
            __typename?: 'ForecastCustomIntensityDataPoint';
            customIntensityId: string;
            value: number | null;
          }>;
        }>;
      };
      customIntensityConfigs: Array<{
        __typename?: 'CustomIntensityConfig';
        id: string;
        name: string;
        description: string;
        unit: string | null;
        humanReadable: boolean;
        quantityType: GQCustomIntensityConfigQuantityType;
        isSbtValidated: boolean | null;
      }>;
      businessCategories: Array<{
        __typename?: 'CategorizedEmissionData';
        businessCategory: string;
      }>;
    };
    variables: {
      __typename?: 'PlanVariables';
      commitmentsHasNetZero: boolean;
      commitmentsNetZeroYear: number;
      cleanPowerStart: YearMonth;
      commitmentsHasSBT: boolean;
      commitmentsSBTTargetYear: number;
      commitmentsSBTScope12: GQSbtScope1And2Target;
      commitmentsSBTScope3: GQSbtScope3Target;
      commitmentsSBTTermLength: GQSbtTermLength;
      commitmentsSBTSubmissionDate: YearMonth;
      flightsPerEmployeeForecastEnd: number;
      percentWorkFromHomeForecastEnd: number;
      netZeroSuppliersReductionPercent: number;
    };
    reduxInitiatives: Array<
      | {
          __typename: 'FootprintSliceInitiative';
          id: string;
          name: string;
          enabled: boolean;
          description: string | null;
          scheduleType: GQScheduleType;
          deletedAt: Date | null;
          createdAt: Date;
          reductionTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          costTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          filters: {
            __typename?: 'FilterExpressionGroupWithNewFilters';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitiveWithNewFilters';
              field: string;
              value: Array<string>;
              operator: GQFilterOperator;
            }>;
          };
        }
      | {
          __typename: 'PrecomputedActivityBasedInitiative';
          id: string;
          name: string;
          enabled: boolean;
          description: string | null;
          scheduleType: GQScheduleType;
          deletedAt: Date | null;
          createdAt: Date;
          precomputedLever: GQPrecomputedLever;
          implementationTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          costTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          filters: {
            __typename?: 'FilterExpressionGroupWithNewFilters';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitiveWithNewFilters';
              field: string;
              value: Array<string>;
              operator: GQFilterOperator;
            }>;
          };
        }
    >;
    targets: Array<{
      __typename?: 'PlanTarget';
      id: string;
      createdAt: Date | null;
      description: string;
      reportDescription: string;
      customNotes: string;
      baseYear: YearMonth;
      intensityType: GQPlanTargetIntensityType;
      customIntensityConfigId: string | null;
      comparisonType: GQPlanTargetTargetComparisonType;
      reductionRate: GQTargetReductionRate;
      deletedAt: Date | null;
      assignedTo: string | null;
      parentTargetId: string | null;
      interimTargetDate: YearMonth | null;
      interimTargetValue: number | null;
      emissionsTargetCustom: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
    }>;
    reduxCarbonOffsets: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
    reduxCarbonOffsetsCosts: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
    reduxCarbonRemoval: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
    reduxCarbonRemovalCosts: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
  };
};

export type GQUpdatePlanMutationVariables = Exact<{
  input: GQUpdatePlanAndArtifactsInput;
}>;

export type GQUpdatePlanMutation = {
  __typename?: 'Mutation';
  updatePlan: {
    __typename?: 'UpdatePlanPayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      name: string;
      createdAt: Date;
      updatedAt: Date;
      updatedByName: string | null;
      state: GQPlanState;
      baselineYearStart: YearMonth;
      fiscalMonth: number;
      isSbtValidated: boolean;
      hideCarbonAvoidance: boolean;
      forecast: {
        __typename?: 'Forecast';
        id: string;
        interval: YMInterval;
        referencePeriodInterval: YMInterval;
        overrideFootprintKind: string | null;
        validReferenceInterval: YMInterval;
        footprintSnapshotId: string;
        scenarios: Array<{
          __typename?: 'ForecastScenario';
          id: string;
          name: string;
          description: string | null;
          isDefault: boolean;
          updatedByName: string | null;
          updatedAt: Date;
          historicalUserInputtedGrowthFactorDataPoints: Array<{
            __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
            date: Date;
            growthFactors: Array<{
              __typename?: 'GrowthFactorDataPoint';
              growthFactorType: GQGrowthFactorType;
              customIntensityConfigId: string | null;
              value: number | null;
            }>;
          }>;
          growthForecasts: Array<{
            __typename?: 'GrowthForecast';
            id: string;
            growthFactorType: GQGrowthFactorType;
            customIntensityConfigId: string | null;
            isDefaultScalingFactor: boolean;
            hasUserInputtedHistoricalPeriod: boolean;
            forecast: {
              __typename?: 'SimpleTimeseries';
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            scalingFactorFilters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                operator: GQFilterOperator;
                value: Array<string>;
              }>;
            } | null;
          }>;
        }>;
        historicalPeriod: {
          __typename?: 'ForecastPeriod';
          interval: YMInterval;
          data: Array<{
            __typename?: 'ForecastPeriodPoint';
            date: Date;
            headcount: number | null;
            revenue: number | null;
            customGrowthFactors: Array<{
              __typename?: 'ForecastCustomIntensityDataPoint';
              customIntensityId: string;
              value: number | null;
            }>;
          }>;
        };
        customIntensityConfigs: Array<{
          __typename?: 'CustomIntensityConfig';
          id: string;
          name: string;
          description: string;
          unit: string | null;
          humanReadable: boolean;
          quantityType: GQCustomIntensityConfigQuantityType;
          isSbtValidated: boolean | null;
        }>;
        businessCategories: Array<{
          __typename?: 'CategorizedEmissionData';
          businessCategory: string;
        }>;
      };
      variables: {
        __typename?: 'PlanVariables';
        commitmentsHasNetZero: boolean;
        commitmentsNetZeroYear: number;
        cleanPowerStart: YearMonth;
        commitmentsHasSBT: boolean;
        commitmentsSBTTargetYear: number;
        commitmentsSBTScope12: GQSbtScope1And2Target;
        commitmentsSBTScope3: GQSbtScope3Target;
        commitmentsSBTTermLength: GQSbtTermLength;
        commitmentsSBTSubmissionDate: YearMonth;
        flightsPerEmployeeForecastEnd: number;
        percentWorkFromHomeForecastEnd: number;
        netZeroSuppliersReductionPercent: number;
      };
      reduxInitiatives: Array<
        | {
            __typename: 'FootprintSliceInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            reductionTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
        | {
            __typename: 'PrecomputedActivityBasedInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            precomputedLever: GQPrecomputedLever;
            implementationTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
      >;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
      reduxCarbonOffsets: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonOffsetsCosts: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonRemoval: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonRemovalCosts: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    };
  } | null;
};

export type GQDuplicatePlanMutationVariables = Exact<{
  input: GQDuplicatePlanInput;
}>;

export type GQDuplicatePlanMutation = {
  __typename?: 'Mutation';
  duplicatePlan: {
    __typename?: 'DuplicatePlanPayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      name: string;
      createdAt: Date;
      updatedAt: Date;
      updatedByName: string | null;
      state: GQPlanState;
      baselineYearStart: YearMonth;
      fiscalMonth: number;
      isSbtValidated: boolean;
      hideCarbonAvoidance: boolean;
      forecast: {
        __typename?: 'Forecast';
        id: string;
        interval: YMInterval;
        referencePeriodInterval: YMInterval;
        overrideFootprintKind: string | null;
        validReferenceInterval: YMInterval;
        footprintSnapshotId: string;
        scenarios: Array<{
          __typename?: 'ForecastScenario';
          id: string;
          name: string;
          description: string | null;
          isDefault: boolean;
          updatedByName: string | null;
          updatedAt: Date;
          historicalUserInputtedGrowthFactorDataPoints: Array<{
            __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
            date: Date;
            growthFactors: Array<{
              __typename?: 'GrowthFactorDataPoint';
              growthFactorType: GQGrowthFactorType;
              customIntensityConfigId: string | null;
              value: number | null;
            }>;
          }>;
          growthForecasts: Array<{
            __typename?: 'GrowthForecast';
            id: string;
            growthFactorType: GQGrowthFactorType;
            customIntensityConfigId: string | null;
            isDefaultScalingFactor: boolean;
            hasUserInputtedHistoricalPeriod: boolean;
            forecast: {
              __typename?: 'SimpleTimeseries';
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            scalingFactorFilters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                operator: GQFilterOperator;
                value: Array<string>;
              }>;
            } | null;
          }>;
        }>;
        historicalPeriod: {
          __typename?: 'ForecastPeriod';
          interval: YMInterval;
          data: Array<{
            __typename?: 'ForecastPeriodPoint';
            date: Date;
            headcount: number | null;
            revenue: number | null;
            customGrowthFactors: Array<{
              __typename?: 'ForecastCustomIntensityDataPoint';
              customIntensityId: string;
              value: number | null;
            }>;
          }>;
        };
        customIntensityConfigs: Array<{
          __typename?: 'CustomIntensityConfig';
          id: string;
          name: string;
          description: string;
          unit: string | null;
          humanReadable: boolean;
          quantityType: GQCustomIntensityConfigQuantityType;
          isSbtValidated: boolean | null;
        }>;
        businessCategories: Array<{
          __typename?: 'CategorizedEmissionData';
          businessCategory: string;
        }>;
      };
      variables: {
        __typename?: 'PlanVariables';
        commitmentsHasNetZero: boolean;
        commitmentsNetZeroYear: number;
        cleanPowerStart: YearMonth;
        commitmentsHasSBT: boolean;
        commitmentsSBTTargetYear: number;
        commitmentsSBTScope12: GQSbtScope1And2Target;
        commitmentsSBTScope3: GQSbtScope3Target;
        commitmentsSBTTermLength: GQSbtTermLength;
        commitmentsSBTSubmissionDate: YearMonth;
        flightsPerEmployeeForecastEnd: number;
        percentWorkFromHomeForecastEnd: number;
        netZeroSuppliersReductionPercent: number;
      };
      reduxInitiatives: Array<
        | {
            __typename: 'FootprintSliceInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            reductionTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
        | {
            __typename: 'PrecomputedActivityBasedInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            precomputedLever: GQPrecomputedLever;
            implementationTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
      >;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
      reduxCarbonOffsets: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonOffsetsCosts: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonRemoval: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonRemovalCosts: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    };
  } | null;
};

export type GQUpdatePlanTargetCategorySetDescriptionMutationVariables = Exact<{
  input: GQUpdatePlanTargetInput;
}>;

export type GQUpdatePlanTargetCategorySetDescriptionMutation = {
  __typename?: 'Mutation';
  updatePlanTarget: {
    __typename?: 'UpdatePlanTargetPayload';
    planTarget: {
      __typename?: 'PlanTarget';
      id: string;
      createdAt: Date | null;
      description: string;
      reportDescription: string;
      customNotes: string;
      baseYear: YearMonth;
      intensityType: GQPlanTargetIntensityType;
      customIntensityConfigId: string | null;
      comparisonType: GQPlanTargetTargetComparisonType;
      reductionRate: GQTargetReductionRate;
      deletedAt: Date | null;
      assignedTo: string | null;
      parentTargetId: string | null;
      interimTargetDate: YearMonth | null;
      interimTargetValue: number | null;
      emissionsTargetCustom: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
    };
  } | null;
};

type GQPeerCommitmentFields_CarbonNeutralCommitment_Fragment = {
  __typename: 'CarbonNeutralCommitment';
  id: string;
  description: string | null;
};

type GQPeerCommitmentFields_CleanEnergyCommitment_Fragment = {
  __typename: 'CleanEnergyCommitment';
  id: string;
  description: string | null;
};

type GQPeerCommitmentFields_NetZeroCommitment_Fragment = {
  __typename: 'NetZeroCommitment';
  id: string;
  description: string | null;
};

type GQPeerCommitmentFields_ScienceBasedTargetCommitment_Fragment = {
  __typename: 'ScienceBasedTargetCommitment';
  id: string;
  description: string | null;
  commitment: {
    __typename?: 'SBTCommitment';
    id: string;
    nearTermTargetYear: number | null;
    longTermTargetYear: number | null;
    baselineYear: number | null;
    stage: GQSbtCommitmentStage | null;
    targetClassification: GQSbtTargetClassification | null;
  };
};

export type GQPeerCommitmentFieldsFragment =
  | GQPeerCommitmentFields_CarbonNeutralCommitment_Fragment
  | GQPeerCommitmentFields_CleanEnergyCommitment_Fragment
  | GQPeerCommitmentFields_NetZeroCommitment_Fragment
  | GQPeerCommitmentFields_ScienceBasedTargetCommitment_Fragment;

export type GQPeerReductionTargetsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQPeerReductionTargetsQuery = {
  __typename?: 'Query';
  peerCompanyGroups: Array<{
    __typename?: 'PeerCompanyGroup';
    id: string;
    name: string;
    members: Array<{
      __typename?: 'PeerCompanyGroupMember';
      id: string;
      company: {
        __typename?: 'Company';
        id: string;
        name: string;
        fullLogoUrl: string | null;
        disclosuresV2: Array<{
          __typename?: 'Disclosure';
          id: string;
          targets: Array<{
            __typename?: 'DisclosureTargetDetails';
            id: string;
          }> | null;
          climateCommitments: Array<
            | {
                __typename: 'CarbonNeutralCommitment';
                id: string;
                description: string | null;
              }
            | {
                __typename: 'CleanEnergyCommitment';
                id: string;
                description: string | null;
              }
            | {
                __typename: 'NetZeroCommitment';
                id: string;
                description: string | null;
              }
            | {
                __typename: 'ScienceBasedTargetCommitment';
                id: string;
                description: string | null;
                commitment: {
                  __typename?: 'SBTCommitment';
                  id: string;
                  nearTermTargetYear: number | null;
                  longTermTargetYear: number | null;
                  baselineYear: number | null;
                  stage: GQSbtCommitmentStage | null;
                  targetClassification: GQSbtTargetClassification | null;
                };
              }
          > | null;
        }>;
      };
    }>;
  }>;
};

export type GQCreatePrecomputedActivityBasedInitiativeMutationVariables =
  Exact<{
    input: GQCreatePrecomputedActivityBasedInitiativeInput;
  }>;

export type GQCreatePrecomputedActivityBasedInitiativeMutation = {
  __typename?: 'Mutation';
  createPrecomputedActivityBasedInitiative: {
    __typename?: 'CreatePrecomputedActivityBasedInitiativePayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
      reduxInitiatives: Array<
        | {
            __typename: 'FootprintSliceInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            reductionTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
        | {
            __typename: 'PrecomputedActivityBasedInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            precomputedLever: GQPrecomputedLever;
            implementationTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
      >;
    };
  };
};

export type GQUpdatePrecomputedActivityBasedInitiativeMutationVariables =
  Exact<{
    input: GQUpdatePrecomputedActivityBasedInitiativeInput;
  }>;

export type GQUpdatePrecomputedActivityBasedInitiativeMutation = {
  __typename?: 'Mutation';
  updatePrecomputedActivityBasedInitiative: {
    __typename?: 'UpdatePrecomputedActivityBasedInitiativePayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
      reduxInitiatives: Array<
        | {
            __typename: 'FootprintSliceInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            reductionTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
        | {
            __typename: 'PrecomputedActivityBasedInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            precomputedLever: GQPrecomputedLever;
            implementationTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
      >;
    };
  } | null;
};

export type GQTargetAndInitiativeForecastFieldsFragment = {
  __typename?: 'Plan';
  id: string;
  targets: Array<{
    __typename?: 'PlanTarget';
    id: string;
    createdAt: Date | null;
    description: string;
    reportDescription: string;
    customNotes: string;
    baseYear: YearMonth;
    intensityType: GQPlanTargetIntensityType;
    customIntensityConfigId: string | null;
    comparisonType: GQPlanTargetTargetComparisonType;
    reductionRate: GQTargetReductionRate;
    deletedAt: Date | null;
    assignedTo: string | null;
    parentTargetId: string | null;
    interimTargetDate: YearMonth | null;
    interimTargetValue: number | null;
    emissionsTargetCustom: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
    filters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    };
  }>;
  reduxInitiatives: Array<
    | {
        __typename: 'FootprintSliceInitiative';
        id: string;
        name: string;
        enabled: boolean;
        description: string | null;
        scheduleType: GQScheduleType;
        deletedAt: Date | null;
        createdAt: Date;
        reductionTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        costTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroupWithNewFilters';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitiveWithNewFilters';
            field: string;
            value: Array<string>;
            operator: GQFilterOperator;
          }>;
        };
      }
    | {
        __typename: 'PrecomputedActivityBasedInitiative';
        id: string;
        name: string;
        enabled: boolean;
        description: string | null;
        scheduleType: GQScheduleType;
        deletedAt: Date | null;
        createdAt: Date;
        precomputedLever: GQPrecomputedLever;
        implementationTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        costTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroupWithNewFilters';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitiveWithNewFilters';
            field: string;
            value: Array<string>;
            operator: GQFilterOperator;
          }>;
        };
      }
  >;
};

export type GQCreateFootprintSliceInitiativeMutationVariables = Exact<{
  input: GQCreateFootprintSliceInitiativeInput;
}>;

export type GQCreateFootprintSliceInitiativeMutation = {
  __typename?: 'Mutation';
  createFootprintSliceInitiative: {
    __typename?: 'CreateFootprintSliceInitiativePayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
      reduxInitiatives: Array<
        | {
            __typename: 'FootprintSliceInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            reductionTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
        | {
            __typename: 'PrecomputedActivityBasedInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            precomputedLever: GQPrecomputedLever;
            implementationTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
      >;
    };
  };
};

export type GQUpdateFootprintSliceInitiativeMutationVariables = Exact<{
  input: GQUpdateFootprintSliceInitiativeInput;
}>;

export type GQUpdateFootprintSliceInitiativeMutation = {
  __typename?: 'Mutation';
  updateFootprintSliceInitiative: {
    __typename?: 'UpdateFootprintSliceInitiativePayload';
    initiative:
      | {
          __typename: 'FootprintSliceInitiative';
          id: string;
          name: string;
          enabled: boolean;
          description: string | null;
          scheduleType: GQScheduleType;
          deletedAt: Date | null;
          createdAt: Date;
          reductionTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          costTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          filters: {
            __typename?: 'FilterExpressionGroupWithNewFilters';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitiveWithNewFilters';
              field: string;
              value: Array<string>;
              operator: GQFilterOperator;
            }>;
          };
        }
      | {
          __typename: 'PrecomputedActivityBasedInitiative';
          id: string;
          name: string;
          enabled: boolean;
          description: string | null;
          scheduleType: GQScheduleType;
          deletedAt: Date | null;
          createdAt: Date;
          precomputedLever: GQPrecomputedLever;
          implementationTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          costTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          filters: {
            __typename?: 'FilterExpressionGroupWithNewFilters';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitiveWithNewFilters';
              field: string;
              value: Array<string>;
              operator: GQFilterOperator;
            }>;
          };
        };
    plan: {
      __typename?: 'Plan';
      id: string;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
      reduxInitiatives: Array<
        | {
            __typename: 'FootprintSliceInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            reductionTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
        | {
            __typename: 'PrecomputedActivityBasedInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            precomputedLever: GQPrecomputedLever;
            implementationTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
      >;
    };
  } | null;
};

export type GQCreatePlanTargetReduxMutationVariables = Exact<{
  input: GQCreatePlanTargetReduxInput;
}>;

export type GQCreatePlanTargetReduxMutation = {
  __typename?: 'Mutation';
  createPlanTargetRedux: {
    __typename?: 'CreatePlanTargetPayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
    };
  } | null;
};

export type GQCreateRenewableElectricityTargetMutationVariables = Exact<{
  input: GQCreateRenewableElectricityTargetInput;
}>;

export type GQCreateRenewableElectricityTargetMutation = {
  __typename?: 'Mutation';
  createRenewableElectricityTarget: {
    __typename?: 'CreatePlanTargetPayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
    };
  } | null;
};

export type GQFootprintVersionForReduxFragment = {
  __typename?: 'FootprintVersion';
  id: string;
  createdAt: Date;
  userChangelogMd: string | null;
  kind: GQFootprintVersionKind;
  isGeneratedFromProduct: boolean;
  isAutoGenerated: boolean;
  originalFootprintSnapshotId: string;
  footprintInterval: YMInterval | null;
  generatedBy: { __typename?: 'User'; id: string; displayName: string } | null;
};

export type GQGetFootprintVersionChangelogReduxQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetFootprintVersionChangelogReduxQuery = {
  __typename?: 'Query';
  footprintChangelog: {
    __typename?: 'FootprintChangelog';
    id: string;
    versions: {
      __typename?: 'FootprintVersionConnection';
      edges: Array<{
        __typename?: 'FootprintVersionEdge';
        node: {
          __typename?: 'FootprintVersion';
          id: string;
          createdAt: Date;
          userChangelogMd: string | null;
          kind: GQFootprintVersionKind;
          isGeneratedFromProduct: boolean;
          isAutoGenerated: boolean;
          originalFootprintSnapshotId: string;
          footprintInterval: YMInterval | null;
          generatedBy: {
            __typename?: 'User';
            id: string;
            displayName: string;
          } | null;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQAcknowledgeLatestFootprintSnapshotReduxMutationVariables = Exact<{
  input: GQUpdateForecastInput;
}>;

export type GQAcknowledgeLatestFootprintSnapshotReduxMutation = {
  __typename?: 'Mutation';
  updateForecast: {
    __typename?: 'UpdateForecastPayload';
    forecast: {
      __typename?: 'Forecast';
      id: string;
      acknowledgedFootprintSnapshotId: string | null;
      footprintSnapshotId: string;
    };
  };
};

export type GQDeleteInitiativeMutationVariables = Exact<{
  initiativeId: Scalars['ID']['input'];
}>;

export type GQDeleteInitiativeMutation = {
  __typename?: 'Mutation';
  deleteInitiative: {
    __typename?: 'DeleteInitiativePayload';
    initiative:
      | {
          __typename: 'FootprintSliceInitiative';
          id: string;
          name: string;
          enabled: boolean;
          description: string | null;
          scheduleType: GQScheduleType;
          deletedAt: Date | null;
          createdAt: Date;
          reductionTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          costTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          filters: {
            __typename?: 'FilterExpressionGroupWithNewFilters';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitiveWithNewFilters';
              field: string;
              value: Array<string>;
              operator: GQFilterOperator;
            }>;
          };
        }
      | {
          __typename: 'PrecomputedActivityBasedInitiative';
          id: string;
          name: string;
          enabled: boolean;
          description: string | null;
          scheduleType: GQScheduleType;
          deletedAt: Date | null;
          createdAt: Date;
          precomputedLever: GQPrecomputedLever;
          implementationTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          costTimeseries: {
            __typename?: 'SimpleTimeseries';
            id: string | null;
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          filters: {
            __typename?: 'FilterExpressionGroupWithNewFilters';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitiveWithNewFilters';
              field: string;
              value: Array<string>;
              operator: GQFilterOperator;
            }>;
          };
        };
    plan: {
      __typename?: 'Plan';
      id: string;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
      reduxInitiatives: Array<
        | {
            __typename: 'FootprintSliceInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            reductionTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
        | {
            __typename: 'PrecomputedActivityBasedInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            precomputedLever: GQPrecomputedLever;
            implementationTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
      >;
    };
  } | null;
};

export type GQDuplicateInitiativeMutationVariables = Exact<{
  duplicatedInitiativeId: Scalars['ID']['input'];
  kind: GQInitiativeKind;
  planId: Scalars['ID']['input'];
}>;

export type GQDuplicateInitiativeMutation = {
  __typename?: 'Mutation';
  duplicateInitiative: {
    __typename?: 'DuplicateInitiativePayload';
    initiative:
      | { __typename?: 'FootprintSliceInitiative'; id: string }
      | { __typename?: 'PrecomputedActivityBasedInitiative'; id: string };
    plan: {
      __typename?: 'Plan';
      id: string;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
      reduxInitiatives: Array<
        | {
            __typename: 'FootprintSliceInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            reductionTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
        | {
            __typename: 'PrecomputedActivityBasedInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            precomputedLever: GQPrecomputedLever;
            implementationTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
      >;
    };
  };
};

export type GQPlanForPlanTabFragment = {
  __typename?: 'Plan';
  id: string;
  name: string;
  state: GQPlanState;
  createdAt: Date;
  isHidden: boolean;
  relevantFootprintFields: Array<string>;
  forecast: {
    __typename?: 'Forecast';
    id: string;
    footprintSnapshotId: string;
    overrideFootprintKind: string | null;
    referencePeriodInterval: YMInterval;
  };
};

export type GQForecastScenarioForScenarioSelectorFragment = {
  __typename?: 'ForecastScenario';
  id: string;
  name: string;
  isDefault: boolean;
};

export type GQForecastForScenarioSelectorFragment = {
  __typename?: 'Forecast';
  id: string;
  footprintSnapshotId: string;
  acknowledgedFootprintSnapshotId: string | null;
  scenarios: Array<{
    __typename?: 'ForecastScenario';
    id: string;
    name: string;
    isDefault: boolean;
  }>;
};

export type GQAllPlansAndForecastScenariosQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQAllPlansAndForecastScenariosQuery = {
  __typename?: 'Query';
  plans: {
    __typename?: 'PlanConnection';
    edges: Array<{
      __typename?: 'PlanEdge';
      node: {
        __typename?: 'Plan';
        id: string;
        name: string;
        state: GQPlanState;
        createdAt: Date;
        isHidden: boolean;
        relevantFootprintFields: Array<string>;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          footprintSnapshotId: string;
          overrideFootprintKind: string | null;
          referencePeriodInterval: YMInterval;
        };
      } | null;
    } | null>;
  };
  forecast: {
    __typename?: 'Forecast';
    id: string;
    footprintSnapshotId: string;
    acknowledgedFootprintSnapshotId: string | null;
    scenarios: Array<{
      __typename?: 'ForecastScenario';
      id: string;
      name: string;
      isDefault: boolean;
    }>;
  };
};

export type GQCreatePlanDashboardMutationVariables = Exact<{
  termLength: InputMaybe<GQSbtTermLength>;
}>;

export type GQCreatePlanDashboardMutation = {
  __typename?: 'Mutation';
  createPlanWithDefaults: {
    __typename?: 'CreatePlanWithDefaultsPayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      name: string;
      createdAt: Date;
      updatedAt: Date;
      updatedByName: string | null;
      state: GQPlanState;
      baselineYearStart: YearMonth;
      fiscalMonth: number;
      isSbtValidated: boolean;
      hideCarbonAvoidance: boolean;
      forecast: {
        __typename?: 'Forecast';
        id: string;
        interval: YMInterval;
        referencePeriodInterval: YMInterval;
        overrideFootprintKind: string | null;
        validReferenceInterval: YMInterval;
        footprintSnapshotId: string;
        scenarios: Array<{
          __typename?: 'ForecastScenario';
          id: string;
          name: string;
          description: string | null;
          isDefault: boolean;
          updatedByName: string | null;
          updatedAt: Date;
          historicalUserInputtedGrowthFactorDataPoints: Array<{
            __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
            date: Date;
            growthFactors: Array<{
              __typename?: 'GrowthFactorDataPoint';
              growthFactorType: GQGrowthFactorType;
              customIntensityConfigId: string | null;
              value: number | null;
            }>;
          }>;
          growthForecasts: Array<{
            __typename?: 'GrowthForecast';
            id: string;
            growthFactorType: GQGrowthFactorType;
            customIntensityConfigId: string | null;
            isDefaultScalingFactor: boolean;
            hasUserInputtedHistoricalPeriod: boolean;
            forecast: {
              __typename?: 'SimpleTimeseries';
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            scalingFactorFilters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                operator: GQFilterOperator;
                value: Array<string>;
              }>;
            } | null;
          }>;
        }>;
        historicalPeriod: {
          __typename?: 'ForecastPeriod';
          interval: YMInterval;
          data: Array<{
            __typename?: 'ForecastPeriodPoint';
            date: Date;
            headcount: number | null;
            revenue: number | null;
            customGrowthFactors: Array<{
              __typename?: 'ForecastCustomIntensityDataPoint';
              customIntensityId: string;
              value: number | null;
            }>;
          }>;
        };
        customIntensityConfigs: Array<{
          __typename?: 'CustomIntensityConfig';
          id: string;
          name: string;
          description: string;
          unit: string | null;
          humanReadable: boolean;
          quantityType: GQCustomIntensityConfigQuantityType;
          isSbtValidated: boolean | null;
        }>;
        businessCategories: Array<{
          __typename?: 'CategorizedEmissionData';
          businessCategory: string;
        }>;
      };
      variables: {
        __typename?: 'PlanVariables';
        commitmentsHasNetZero: boolean;
        commitmentsNetZeroYear: number;
        cleanPowerStart: YearMonth;
        commitmentsHasSBT: boolean;
        commitmentsSBTTargetYear: number;
        commitmentsSBTScope12: GQSbtScope1And2Target;
        commitmentsSBTScope3: GQSbtScope3Target;
        commitmentsSBTTermLength: GQSbtTermLength;
        commitmentsSBTSubmissionDate: YearMonth;
        flightsPerEmployeeForecastEnd: number;
        percentWorkFromHomeForecastEnd: number;
        netZeroSuppliersReductionPercent: number;
      };
      reduxInitiatives: Array<
        | {
            __typename: 'FootprintSliceInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            reductionTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
        | {
            __typename: 'PrecomputedActivityBasedInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            precomputedLever: GQPrecomputedLever;
            implementationTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
      >;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
      reduxCarbonOffsets: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonOffsetsCosts: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonRemoval: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonRemovalCosts: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    };
  };
};

export type GQRenamePlanFromPlanTabMutationVariables = Exact<{
  planId: Scalars['ID']['input'];
  newName: Scalars['String']['input'];
}>;

export type GQRenamePlanFromPlanTabMutation = {
  __typename?: 'Mutation';
  updatePlanSettings: {
    __typename?: 'UpdatePlanSettingsPayload';
    plan: { __typename?: 'Plan'; id: string; name: string };
  } | null;
};

export type GQDuplicatePlanFromPlanTabMutationVariables = Exact<{
  input: GQDuplicatePlanInput;
}>;

export type GQDuplicatePlanFromPlanTabMutation = {
  __typename?: 'Mutation';
  duplicatePlan: {
    __typename?: 'DuplicatePlanPayload';
    plan: { __typename?: 'Plan'; id: string; name: string };
  } | null;
};

export type GQDeletePlanFromPlanTabMutationVariables = Exact<{
  input: GQDeletePlanInput;
}>;

export type GQDeletePlanFromPlanTabMutation = {
  __typename?: 'Mutation';
  deletePlan: boolean;
};

export type GQElectricityConsumptionBreakdownQueryVariables = Exact<{
  planId: Scalars['ID']['input'];
}>;

export type GQElectricityConsumptionBreakdownQuery = {
  __typename?: 'Query';
  plan: {
    __typename?: 'Plan';
    id: string;
    forecast: {
      __typename?: 'Forecast';
      id: string;
      electricityConsumptionBreakdown: Array<{
        __typename?: 'RenewableEnergyPercentagePoint';
        yearStart: YearMonth;
        renewableEnergyFraction: number;
      }>;
    };
  };
};

type GQCommonInitiativeFields_FootprintSliceInitiative_Fragment = {
  __typename: 'FootprintSliceInitiative';
  id: string;
  name: string;
  enabled: boolean;
  description: string | null;
  scheduleType: GQScheduleType;
  deletedAt: Date | null;
  createdAt: Date;
  costTimeseries: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  filters: {
    __typename?: 'FilterExpressionGroupWithNewFilters';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitiveWithNewFilters';
      field: string;
      value: Array<string>;
      operator: GQFilterOperator;
    }>;
  };
};

type GQCommonInitiativeFields_PrecomputedActivityBasedInitiative_Fragment = {
  __typename: 'PrecomputedActivityBasedInitiative';
  id: string;
  name: string;
  enabled: boolean;
  description: string | null;
  scheduleType: GQScheduleType;
  deletedAt: Date | null;
  createdAt: Date;
  costTimeseries: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  filters: {
    __typename?: 'FilterExpressionGroupWithNewFilters';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitiveWithNewFilters';
      field: string;
      value: Array<string>;
      operator: GQFilterOperator;
    }>;
  };
};

export type GQCommonInitiativeFieldsFragment =
  | GQCommonInitiativeFields_FootprintSliceInitiative_Fragment
  | GQCommonInitiativeFields_PrecomputedActivityBasedInitiative_Fragment;

export type GQFootprintSliceInitiativeFieldsFragment = {
  __typename: 'FootprintSliceInitiative';
  id: string;
  name: string;
  enabled: boolean;
  description: string | null;
  scheduleType: GQScheduleType;
  deletedAt: Date | null;
  createdAt: Date;
  reductionTimeseries: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  costTimeseries: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  filters: {
    __typename?: 'FilterExpressionGroupWithNewFilters';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitiveWithNewFilters';
      field: string;
      value: Array<string>;
      operator: GQFilterOperator;
    }>;
  };
};

export type GQPrecomputedActivityBasedInitiativeFieldsFragment = {
  __typename: 'PrecomputedActivityBasedInitiative';
  id: string;
  name: string;
  enabled: boolean;
  description: string | null;
  scheduleType: GQScheduleType;
  deletedAt: Date | null;
  createdAt: Date;
  precomputedLever: GQPrecomputedLever;
  implementationTimeseries: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  costTimeseries: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  filters: {
    __typename?: 'FilterExpressionGroupWithNewFilters';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitiveWithNewFilters';
      field: string;
      value: Array<string>;
      operator: GQFilterOperator;
    }>;
  };
};

type GQReduxInitiativeFields_FootprintSliceInitiative_Fragment = {
  __typename: 'FootprintSliceInitiative';
  id: string;
  name: string;
  enabled: boolean;
  description: string | null;
  scheduleType: GQScheduleType;
  deletedAt: Date | null;
  createdAt: Date;
  reductionTimeseries: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  costTimeseries: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  filters: {
    __typename?: 'FilterExpressionGroupWithNewFilters';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitiveWithNewFilters';
      field: string;
      value: Array<string>;
      operator: GQFilterOperator;
    }>;
  };
};

type GQReduxInitiativeFields_PrecomputedActivityBasedInitiative_Fragment = {
  __typename: 'PrecomputedActivityBasedInitiative';
  id: string;
  name: string;
  enabled: boolean;
  description: string | null;
  scheduleType: GQScheduleType;
  deletedAt: Date | null;
  createdAt: Date;
  precomputedLever: GQPrecomputedLever;
  implementationTimeseries: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  costTimeseries: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  filters: {
    __typename?: 'FilterExpressionGroupWithNewFilters';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitiveWithNewFilters';
      field: string;
      value: Array<string>;
      operator: GQFilterOperator;
    }>;
  };
};

export type GQReduxInitiativeFieldsFragment =
  | GQReduxInitiativeFields_FootprintSliceInitiative_Fragment
  | GQReduxInitiativeFields_PrecomputedActivityBasedInitiative_Fragment;

export type GQUpdatePlanTargetReduxMutationVariables = Exact<{
  input: GQUpdatePlanTargetInput;
}>;

export type GQUpdatePlanTargetReduxMutation = {
  __typename?: 'Mutation';
  updatePlanTarget: {
    __typename?: 'UpdatePlanTargetPayload';
    planTarget: {
      __typename?: 'PlanTarget';
      id: string;
      createdAt: Date | null;
      description: string;
      reportDescription: string;
      customNotes: string;
      baseYear: YearMonth;
      intensityType: GQPlanTargetIntensityType;
      customIntensityConfigId: string | null;
      comparisonType: GQPlanTargetTargetComparisonType;
      reductionRate: GQTargetReductionRate;
      deletedAt: Date | null;
      assignedTo: string | null;
      parentTargetId: string | null;
      interimTargetDate: YearMonth | null;
      interimTargetValue: number | null;
      emissionsTargetCustom: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
    };
    forecast: {
      __typename?: 'Forecast';
      id: string;
      validYearsForIntervalEnd: YMInterval;
    };
  } | null;
};

export type GQDeletePlanTargetReduxMutationVariables = Exact<{
  input: GQDeletePlanTargetInput;
}>;

export type GQDeletePlanTargetReduxMutation = {
  __typename?: 'Mutation';
  deletePlanTarget: {
    __typename?: 'DeletePlanTargetPayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        deletedAt: Date | null;
      }>;
    };
  };
};

export type GQPlansForCompareSheetQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQPlansForCompareSheetQuery = {
  __typename?: 'Query';
  plans: {
    __typename?: 'PlanConnection';
    edges: Array<{
      __typename?: 'PlanEdge';
      node: {
        __typename?: 'Plan';
        id: string;
        isHidden: boolean;
        name: string;
        createdAt: Date;
        updatedAt: Date;
        updatedByName: string | null;
        state: GQPlanState;
        baselineYearStart: YearMonth;
        fiscalMonth: number;
        isSbtValidated: boolean;
        hideCarbonAvoidance: boolean;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          interval: YMInterval;
          referencePeriodInterval: YMInterval;
          overrideFootprintKind: string | null;
          validReferenceInterval: YMInterval;
          footprintSnapshotId: string;
          scenarios: Array<{
            __typename?: 'ForecastScenario';
            id: string;
            name: string;
            description: string | null;
            isDefault: boolean;
            updatedByName: string | null;
            updatedAt: Date;
            historicalUserInputtedGrowthFactorDataPoints: Array<{
              __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
              date: Date;
              growthFactors: Array<{
                __typename?: 'GrowthFactorDataPoint';
                growthFactorType: GQGrowthFactorType;
                customIntensityConfigId: string | null;
                value: number | null;
              }>;
            }>;
            growthForecasts: Array<{
              __typename?: 'GrowthForecast';
              id: string;
              growthFactorType: GQGrowthFactorType;
              customIntensityConfigId: string | null;
              isDefaultScalingFactor: boolean;
              hasUserInputtedHistoricalPeriod: boolean;
              forecast: {
                __typename?: 'SimpleTimeseries';
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              };
              scalingFactorFilters: {
                __typename?: 'FilterExpressionGroupWithNewFilters';
                conjunction: GQFilterConjunction;
                expressions: Array<{
                  __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                  field: string;
                  operator: GQFilterOperator;
                  value: Array<string>;
                }>;
              } | null;
            }>;
          }>;
          historicalPeriod: {
            __typename?: 'ForecastPeriod';
            interval: YMInterval;
            data: Array<{
              __typename?: 'ForecastPeriodPoint';
              date: Date;
              headcount: number | null;
              revenue: number | null;
              customGrowthFactors: Array<{
                __typename?: 'ForecastCustomIntensityDataPoint';
                customIntensityId: string;
                value: number | null;
              }>;
            }>;
          };
          customIntensityConfigs: Array<{
            __typename?: 'CustomIntensityConfig';
            id: string;
            name: string;
            description: string;
            unit: string | null;
            humanReadable: boolean;
            quantityType: GQCustomIntensityConfigQuantityType;
            isSbtValidated: boolean | null;
          }>;
          businessCategories: Array<{
            __typename?: 'CategorizedEmissionData';
            businessCategory: string;
          }>;
        };
        variables: {
          __typename?: 'PlanVariables';
          commitmentsHasNetZero: boolean;
          commitmentsNetZeroYear: number;
          cleanPowerStart: YearMonth;
          commitmentsHasSBT: boolean;
          commitmentsSBTTargetYear: number;
          commitmentsSBTScope12: GQSbtScope1And2Target;
          commitmentsSBTScope3: GQSbtScope3Target;
          commitmentsSBTTermLength: GQSbtTermLength;
          commitmentsSBTSubmissionDate: YearMonth;
          flightsPerEmployeeForecastEnd: number;
          percentWorkFromHomeForecastEnd: number;
          netZeroSuppliersReductionPercent: number;
        };
        reduxInitiatives: Array<
          | {
              __typename: 'FootprintSliceInitiative';
              id: string;
              name: string;
              enabled: boolean;
              description: string | null;
              scheduleType: GQScheduleType;
              deletedAt: Date | null;
              createdAt: Date;
              reductionTimeseries: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              };
              costTimeseries: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              };
              filters: {
                __typename?: 'FilterExpressionGroupWithNewFilters';
                conjunction: GQFilterConjunction;
                expressions: Array<{
                  __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                  field: string;
                  value: Array<string>;
                  operator: GQFilterOperator;
                }>;
              };
            }
          | {
              __typename: 'PrecomputedActivityBasedInitiative';
              id: string;
              name: string;
              enabled: boolean;
              description: string | null;
              scheduleType: GQScheduleType;
              deletedAt: Date | null;
              createdAt: Date;
              precomputedLever: GQPrecomputedLever;
              implementationTimeseries: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              };
              costTimeseries: {
                __typename?: 'SimpleTimeseries';
                id: string | null;
                base: Date;
                frequency: GQTimeseriesFrequency;
                values: Array<number>;
              };
              filters: {
                __typename?: 'FilterExpressionGroupWithNewFilters';
                conjunction: GQFilterConjunction;
                expressions: Array<{
                  __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                  field: string;
                  value: Array<string>;
                  operator: GQFilterOperator;
                }>;
              };
            }
        >;
        targets: Array<{
          __typename?: 'PlanTarget';
          id: string;
          createdAt: Date | null;
          description: string;
          reportDescription: string;
          customNotes: string;
          baseYear: YearMonth;
          intensityType: GQPlanTargetIntensityType;
          customIntensityConfigId: string | null;
          comparisonType: GQPlanTargetTargetComparisonType;
          reductionRate: GQTargetReductionRate;
          deletedAt: Date | null;
          assignedTo: string | null;
          parentTargetId: string | null;
          interimTargetDate: YearMonth | null;
          interimTargetValue: number | null;
          emissionsTargetCustom: {
            __typename?: 'SimpleTimeseries';
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          filters: {
            __typename?: 'FilterExpressionGroup';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitive';
              field: GQFilterFieldLegacy;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          };
        }>;
        reduxCarbonOffsets: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        reduxCarbonOffsetsCosts: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        reduxCarbonRemoval: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        reduxCarbonRemovalCosts: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
      } | null;
    } | null>;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    sbtiTargetExclusions: Array<{
      __typename?: 'SbtiTargetExclusion';
      id: string;
      filters: {
        __typename?: 'FilterExpressionGroupWithNewFilters';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitiveWithNewFilters';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
    }>;
  };
};

export type GQGenerateCustomIntensityConfigIdMutationVariables = Exact<{
  input: GQGenerateCustomIntensityConfigIdInput;
}>;

export type GQGenerateCustomIntensityConfigIdMutation = {
  __typename?: 'Mutation';
  generateCustomIntensityConfigId: {
    __typename?: 'GenerateCustomIntensityConfigIdPayload';
    id: string;
  };
};

export type GQCreateEditCustomIntensityDialog_CustomIntensityConfigFragment = {
  __typename?: 'CustomIntensityConfig';
  id: string;
  name: string;
  description: string;
  unit: string | null;
  humanReadable: boolean;
  quantityType: GQCustomIntensityConfigQuantityType;
  isSbtValidated: boolean | null;
};

export type GQGenerateForecastScenarioIdMutationVariables = Exact<{
  input: GQGenerateForecastScenarioIdInput;
}>;

export type GQGenerateForecastScenarioIdMutation = {
  __typename?: 'Mutation';
  generateForecastScenarioId: {
    __typename?: 'GenerateForecastScenarioIdPayload';
    id: string;
  };
};

export type GQManageCustomIntensities_CustomIntensityConfigFragment = {
  __typename?: 'CustomIntensityConfig';
  id: string;
  name: string;
  description: string;
  unit: string | null;
  humanReadable: boolean;
  quantityType: GQCustomIntensityConfigQuantityType;
  isSbtValidated: boolean | null;
};

export type GQCreateUpdateAndDeleteForecastScenariosMutationVariables = Exact<{
  input: GQCreateUpdateAndDeleteForecastScenariosInput;
}>;

export type GQCreateUpdateAndDeleteForecastScenariosMutation = {
  __typename?: 'Mutation';
  createUpdateAndDeleteForecastScenarios: {
    __typename?: 'CreateUpdateAndDeleteForecastScenariosPayload';
    forecast: {
      __typename?: 'Forecast';
      id: string;
      scenarios: Array<{
        __typename?: 'ForecastScenario';
        id: string;
        name: string;
        description: string | null;
        isDefault: boolean;
        updatedByName: string | null;
        updatedAt: Date;
        historicalUserInputtedGrowthFactorDataPoints: Array<{
          __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
          date: Date;
          growthFactors: Array<{
            __typename?: 'GrowthFactorDataPoint';
            growthFactorType: GQGrowthFactorType;
            customIntensityConfigId: string | null;
            value: number | null;
          }>;
        }>;
        growthForecasts: Array<{
          __typename?: 'GrowthForecast';
          id: string;
          growthFactorType: GQGrowthFactorType;
          customIntensityConfigId: string | null;
          isDefaultScalingFactor: boolean;
          hasUserInputtedHistoricalPeriod: boolean;
          forecast: {
            __typename?: 'SimpleTimeseries';
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          scalingFactorFilters: {
            __typename?: 'FilterExpressionGroupWithNewFilters';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitiveWithNewFilters';
              field: string;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          } | null;
        }>;
      }>;
    };
  };
};

export type GQSimulationPortfolioComparePageDataFragment = {
  __typename?: 'SimulationPortfolio';
  id: string;
  name: string;
  scenarios: Array<{
    __typename?: 'SimulationScenario';
    id: string;
    name: string;
    latestRun: {
      __typename?: 'SimulationScenarioRun';
      id: string;
      scenarioConfiguration: any;
      resultData: any | null;
      targetingFilters: any;
    } | null;
  }>;
};

export type GQGetSimulationPortfolioComparePageDataQueryVariables = Exact<{
  portfolioId: Scalars['ID']['input'];
}>;

export type GQGetSimulationPortfolioComparePageDataQuery = {
  __typename?: 'Query';
  simulationPortfolio: {
    __typename?: 'SimulationPortfolio';
    id: string;
    name: string;
    scenarios: Array<{
      __typename?: 'SimulationScenario';
      id: string;
      name: string;
      latestRun: {
        __typename?: 'SimulationScenarioRun';
        id: string;
        scenarioConfiguration: any;
        resultData: any | null;
        targetingFilters: any;
      } | null;
    }>;
  } | null;
};

export type GQCreateSimulationPortfolioMutationVariables = Exact<{
  name: Scalars['String']['input'];
  type: GQSimulationScenarioConfigType;
}>;

export type GQCreateSimulationPortfolioMutation = {
  __typename?: 'Mutation';
  createSimulationPortfolio: { __typename?: 'SimulationPortfolio'; id: string };
};

export type GQDeleteSimulationPortfolioMutationVariables = Exact<{
  simulationPortfolioId: Scalars['ID']['input'];
}>;

export type GQDeleteSimulationPortfolioMutation = {
  __typename?: 'Mutation';
  deleteSimulationPortfolio: { __typename?: 'SimulationPortfolio'; id: string };
};

export type GQEditSimulationPortfolioNameMutationVariables = Exact<{
  portfolioId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;

export type GQEditSimulationPortfolioNameMutation = {
  __typename?: 'Mutation';
  editSimulationPortfolio: {
    __typename?: 'SimulationPortfolio';
    id: string;
    name: string;
  };
};

export type GQCopySimulationScenarioMutationVariables = Exact<{
  simulationScenarioId: Scalars['ID']['input'];
}>;

export type GQCopySimulationScenarioMutation = {
  __typename?: 'Mutation';
  copySimulationScenario: { __typename?: 'SimulationScenario'; id: string };
};

export type GQGetSimulationPortfolioDataQueryVariables = Exact<{
  portfolioId: Scalars['ID']['input'];
}>;

export type GQGetSimulationPortfolioDataQuery = {
  __typename?: 'Query';
  simulationPortfolio: {
    __typename?: 'SimulationPortfolio';
    id: string;
    name: string;
    updatedAt: Date;
    scenarios: Array<{
      __typename?: 'SimulationScenario';
      id: string;
      createdAt: Date;
      name: string;
      type: GQSimulationScenarioConfigType;
      latestRun: { __typename?: 'SimulationScenarioRun'; id: string } | null;
    }>;
  } | null;
};

export type GQSimulationScenarioListViewDataFragment = {
  __typename?: 'SimulationScenario';
  id: string;
  name: string;
  createdAt: Date;
  type: GQSimulationScenarioConfigType;
  creator: { __typename?: 'User'; id: string; name: string };
  latestRun: {
    __typename?: 'SimulationScenarioRun';
    id: string;
    resultData: any | null;
  } | null;
  swapOverlayData: {
    __typename?: 'SimulationSwapOverlay';
    original: {
      __typename?: 'SimulationSwapOverlayData';
      choice: string;
      emissionsPerKg: number;
      cost: {
        __typename?: 'SimulationCostPerUnitOverride';
        deltaPerUnit: number;
        isPercentage: boolean;
        unit: string;
        hideCost: boolean;
      } | null;
    } | null;
    swap: {
      __typename?: 'SimulationSwapOverlayData';
      choice: string;
      emissionsPerKg: number;
      assumptionMarkdown: string | null;
      cost: {
        __typename?: 'SimulationCostPerUnitOverride';
        deltaPerUnit: number;
        isPercentage: boolean;
        unit: string;
        hideCost: boolean;
      } | null;
    } | null;
  } | null;
};

export type GQSimulationPortfolioListViewDataFragment = {
  __typename?: 'SimulationPortfolio';
  id: string;
  name: string;
  createdAt: Date;
  scenarios: Array<{
    __typename?: 'SimulationScenario';
    id: string;
    name: string;
    createdAt: Date;
    type: GQSimulationScenarioConfigType;
    creator: { __typename?: 'User'; id: string; name: string };
    latestRun: {
      __typename?: 'SimulationScenarioRun';
      id: string;
      resultData: any | null;
    } | null;
    swapOverlayData: {
      __typename?: 'SimulationSwapOverlay';
      original: {
        __typename?: 'SimulationSwapOverlayData';
        choice: string;
        emissionsPerKg: number;
        cost: {
          __typename?: 'SimulationCostPerUnitOverride';
          deltaPerUnit: number;
          isPercentage: boolean;
          unit: string;
          hideCost: boolean;
        } | null;
      } | null;
      swap: {
        __typename?: 'SimulationSwapOverlayData';
        choice: string;
        emissionsPerKg: number;
        assumptionMarkdown: string | null;
        cost: {
          __typename?: 'SimulationCostPerUnitOverride';
          deltaPerUnit: number;
          isPercentage: boolean;
          unit: string;
          hideCost: boolean;
        } | null;
      } | null;
    } | null;
  }>;
};

export type GQSimulationPortfoliosQueryVariables = Exact<{
  isTemplate: Scalars['Boolean']['input'];
}>;

export type GQSimulationPortfoliosQuery = {
  __typename?: 'Query';
  simulationPortfolios: Array<{
    __typename?: 'SimulationPortfolio';
    id: string;
    name: string;
    createdAt: Date;
    scenarios: Array<{
      __typename?: 'SimulationScenario';
      id: string;
      name: string;
      createdAt: Date;
      type: GQSimulationScenarioConfigType;
      creator: { __typename?: 'User'; id: string; name: string };
      latestRun: {
        __typename?: 'SimulationScenarioRun';
        id: string;
        resultData: any | null;
      } | null;
      swapOverlayData: {
        __typename?: 'SimulationSwapOverlay';
        original: {
          __typename?: 'SimulationSwapOverlayData';
          choice: string;
          emissionsPerKg: number;
          cost: {
            __typename?: 'SimulationCostPerUnitOverride';
            deltaPerUnit: number;
            isPercentage: boolean;
            unit: string;
            hideCost: boolean;
          } | null;
        } | null;
        swap: {
          __typename?: 'SimulationSwapOverlayData';
          choice: string;
          emissionsPerKg: number;
          assumptionMarkdown: string | null;
          cost: {
            __typename?: 'SimulationCostPerUnitOverride';
            deltaPerUnit: number;
            isPercentage: boolean;
            unit: string;
            hideCost: boolean;
          } | null;
        } | null;
      } | null;
    }>;
  }>;
};

export type GQSimulationTemplateScenarioListViewDataFragment = {
  __typename?: 'SimulationScenario';
  id: string;
  name: string;
  createdAt: Date;
  type: GQSimulationScenarioConfigType;
  isTemplate: boolean;
  creator: { __typename?: 'User'; id: string; name: string };
  latestRun: {
    __typename?: 'SimulationScenarioRun';
    id: string;
    scenarioConfiguration: any;
    resultData: any | null;
    targetingFilters: any;
    footprintSnapshotId: string;
    intervalStartInclusive: YearMonth;
    intervalEndExclusive: YearMonth;
  } | null;
  swapOverlayData: {
    __typename?: 'SimulationSwapOverlay';
    original: {
      __typename?: 'SimulationSwapOverlayData';
      choice: string;
      emissionsPerKg: number;
      cost: {
        __typename?: 'SimulationCostPerUnitOverride';
        deltaPerUnit: number;
        isPercentage: boolean;
        unit: string;
        hideCost: boolean;
      } | null;
    } | null;
    swap: {
      __typename?: 'SimulationSwapOverlayData';
      choice: string;
      emissionsPerKg: number;
      assumptionMarkdown: string | null;
      cost: {
        __typename?: 'SimulationCostPerUnitOverride';
        deltaPerUnit: number;
        isPercentage: boolean;
        unit: string;
        hideCost: boolean;
      } | null;
    } | null;
  } | null;
};

export type GQSimulationTemplatePortfolioListViewDataFragment = {
  __typename?: 'SimulationPortfolio';
  id: string;
  name: string;
  createdAt: Date;
  scenarios: Array<{
    __typename?: 'SimulationScenario';
    id: string;
    name: string;
    createdAt: Date;
    type: GQSimulationScenarioConfigType;
    isTemplate: boolean;
    creator: { __typename?: 'User'; id: string; name: string };
    latestRun: {
      __typename?: 'SimulationScenarioRun';
      id: string;
      scenarioConfiguration: any;
      resultData: any | null;
      targetingFilters: any;
      footprintSnapshotId: string;
      intervalStartInclusive: YearMonth;
      intervalEndExclusive: YearMonth;
    } | null;
    swapOverlayData: {
      __typename?: 'SimulationSwapOverlay';
      original: {
        __typename?: 'SimulationSwapOverlayData';
        choice: string;
        emissionsPerKg: number;
        cost: {
          __typename?: 'SimulationCostPerUnitOverride';
          deltaPerUnit: number;
          isPercentage: boolean;
          unit: string;
          hideCost: boolean;
        } | null;
      } | null;
      swap: {
        __typename?: 'SimulationSwapOverlayData';
        choice: string;
        emissionsPerKg: number;
        assumptionMarkdown: string | null;
        cost: {
          __typename?: 'SimulationCostPerUnitOverride';
          deltaPerUnit: number;
          isPercentage: boolean;
          unit: string;
          hideCost: boolean;
        } | null;
      } | null;
    } | null;
  }>;
};

export type GQSimulationTemplatePortfoliosQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQSimulationTemplatePortfoliosQuery = {
  __typename?: 'Query';
  simulationPortfolios: Array<{
    __typename?: 'SimulationPortfolio';
    id: string;
    name: string;
    createdAt: Date;
    scenarios: Array<{
      __typename?: 'SimulationScenario';
      id: string;
      name: string;
      createdAt: Date;
      type: GQSimulationScenarioConfigType;
      isTemplate: boolean;
      creator: { __typename?: 'User'; id: string; name: string };
      latestRun: {
        __typename?: 'SimulationScenarioRun';
        id: string;
        scenarioConfiguration: any;
        resultData: any | null;
        targetingFilters: any;
        footprintSnapshotId: string;
        intervalStartInclusive: YearMonth;
        intervalEndExclusive: YearMonth;
      } | null;
      swapOverlayData: {
        __typename?: 'SimulationSwapOverlay';
        original: {
          __typename?: 'SimulationSwapOverlayData';
          choice: string;
          emissionsPerKg: number;
          cost: {
            __typename?: 'SimulationCostPerUnitOverride';
            deltaPerUnit: number;
            isPercentage: boolean;
            unit: string;
            hideCost: boolean;
          } | null;
        } | null;
        swap: {
          __typename?: 'SimulationSwapOverlayData';
          choice: string;
          emissionsPerKg: number;
          assumptionMarkdown: string | null;
          cost: {
            __typename?: 'SimulationCostPerUnitOverride';
            deltaPerUnit: number;
            isPercentage: boolean;
            unit: string;
            hideCost: boolean;
          } | null;
        } | null;
      } | null;
    }>;
  }>;
};

export type GQPlanForSimulationExportFragment = {
  __typename?: 'Plan';
  id: string;
  name: string;
  state: GQPlanState;
  createdAt: Date;
  isHidden: boolean;
  forecast: {
    __typename?: 'Forecast';
    id: string;
    overrideFootprintKind: string | null;
    interval: YMInterval;
  };
};

export type GQAllReduxPlansForSimulationExportQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQAllReduxPlansForSimulationExportQuery = {
  __typename?: 'Query';
  plans: {
    __typename?: 'PlanConnection';
    edges: Array<{
      __typename?: 'PlanEdge';
      node: {
        __typename?: 'Plan';
        id: string;
        name: string;
        state: GQPlanState;
        createdAt: Date;
        isHidden: boolean;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          overrideFootprintKind: string | null;
          interval: YMInterval;
        };
      } | null;
    } | null>;
  };
};

export type GQSimulationScenarioPageDataFragment = {
  __typename?: 'SimulationScenario';
  id: string;
  name: string;
  type: GQSimulationScenarioConfigType;
  createdAt: Date;
  updatedAt: Date;
  latestRun: {
    __typename?: 'SimulationScenarioRun';
    id: string;
    footprintSnapshotId: string;
    scenarioConfiguration: any;
    targetingFilters: any;
    resultData: any | null;
    intervalStartInclusive: YearMonth;
    intervalEndExclusive: YearMonth;
  } | null;
};

export type GQGetSimulationScenarioDataQueryVariables = Exact<{
  scenarioId: Scalars['ID']['input'];
}>;

export type GQGetSimulationScenarioDataQuery = {
  __typename?: 'Query';
  simulationScenario: {
    __typename?: 'SimulationScenario';
    id: string;
    name: string;
    type: GQSimulationScenarioConfigType;
    createdAt: Date;
    updatedAt: Date;
    latestRun: {
      __typename?: 'SimulationScenarioRun';
      id: string;
      footprintSnapshotId: string;
      scenarioConfiguration: any;
      targetingFilters: any;
      resultData: any | null;
      intervalStartInclusive: YearMonth;
      intervalEndExclusive: YearMonth;
    } | null;
  } | null;
};

export type GQGetSimulationEligibleFootprintIntervalsQueryVariables = Exact<{
  footprintSnapshotId: Scalars['ID']['input'];
  footprintKind: InputMaybe<Scalars['String']['input']>;
  simulationType: GQSimulationScenarioConfigType;
}>;

export type GQGetSimulationEligibleFootprintIntervalsQuery = {
  __typename?: 'Query';
  simulationEligibleFootprintIntervals: {
    __typename?: 'SimulationEligibleFootprintIntervals';
    intervals: Array<YMInterval>;
  };
};

export type GQDeleteSimulationScenarioMutationVariables = Exact<{
  simulationScenarioId: Scalars['ID']['input'];
}>;

export type GQDeleteSimulationScenarioMutation = {
  __typename?: 'Mutation';
  deleteSimulationScenario: { __typename?: 'SimulationScenario'; id: string };
};

export type GQEditSimulationCandidateCostMutationVariables = Exact<{
  scenarioId: Scalars['ID']['input'];
  candidate: Scalars['String']['input'];
  override: GQSimulationCostPerUnitOverrideInput;
}>;

export type GQEditSimulationCandidateCostMutation = {
  __typename?: 'Mutation';
  editSimulationCandidateCost: {
    __typename?: 'SimulationCostPerUnitOverride';
    unit: string;
  };
};

export type GQEditSimulationScenarioMutationVariables = Exact<{
  simulationScenarioId: Scalars['ID']['input'];
  props: GQSimulationScenarioProps;
}>;

export type GQEditSimulationScenarioMutation = {
  __typename?: 'Mutation';
  editSimulationScenario: { __typename?: 'SimulationScenario'; id: string };
};

export type GQTargetAndInitiativeForecastFieldsForSimluationFragment = {
  __typename?: 'Plan';
  id: string;
  targets: Array<{
    __typename?: 'PlanTarget';
    id: string;
    createdAt: Date | null;
    description: string;
    reportDescription: string;
    customNotes: string;
    baseYear: YearMonth;
    intensityType: GQPlanTargetIntensityType;
    customIntensityConfigId: string | null;
    comparisonType: GQPlanTargetTargetComparisonType;
    reductionRate: GQTargetReductionRate;
    deletedAt: Date | null;
    assignedTo: string | null;
    parentTargetId: string | null;
    interimTargetDate: YearMonth | null;
    interimTargetValue: number | null;
    emissionsTargetCustom: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
    filters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    };
  }>;
  reduxInitiatives: Array<
    | {
        __typename: 'FootprintSliceInitiative';
        id: string;
        name: string;
        enabled: boolean;
        description: string | null;
        scheduleType: GQScheduleType;
        deletedAt: Date | null;
        createdAt: Date;
        reductionTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        costTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroupWithNewFilters';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitiveWithNewFilters';
            field: string;
            value: Array<string>;
            operator: GQFilterOperator;
          }>;
        };
      }
    | {
        __typename: 'PrecomputedActivityBasedInitiative';
        id: string;
        name: string;
        enabled: boolean;
        description: string | null;
        scheduleType: GQScheduleType;
        deletedAt: Date | null;
        createdAt: Date;
        precomputedLever: GQPrecomputedLever;
        implementationTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        costTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroupWithNewFilters';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitiveWithNewFilters';
            field: string;
            value: Array<string>;
            operator: GQFilterOperator;
          }>;
        };
      }
  >;
};

export type GQCreateFootprintSliceInitiativeFromSimulationMutationVariables =
  Exact<{
    input: GQCreateFootprintSliceInitiativeInput;
  }>;

export type GQCreateFootprintSliceInitiativeFromSimulationMutation = {
  __typename?: 'Mutation';
  createFootprintSliceInitiative: {
    __typename?: 'CreateFootprintSliceInitiativePayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      forecast: { __typename?: 'Forecast'; id: string; interval: YMInterval };
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
      reduxInitiatives: Array<
        | {
            __typename: 'FootprintSliceInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            reductionTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
        | {
            __typename: 'PrecomputedActivityBasedInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            precomputedLever: GQPrecomputedLever;
            implementationTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
      >;
    };
  };
};

export type GQCreateSimulationScenarioMutationVariables = Exact<{
  portfolioId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  type: GQSimulationScenarioConfigType;
}>;

export type GQCreateSimulationScenarioMutation = {
  __typename?: 'Mutation';
  createSimulationScenario: { __typename?: 'SimulationScenario'; id: string };
};

export type GQSimulationScenarioIdsQueryVariables = Exact<{
  portfolioId: Scalars['ID']['input'];
}>;

export type GQSimulationScenarioIdsQuery = {
  __typename?: 'Query';
  simulationPortfolio: {
    __typename?: 'SimulationPortfolio';
    id: string;
    scenarios: Array<{
      __typename?: 'SimulationScenario';
      id: string;
      name: string;
    }>;
  } | null;
};

export type GQRunSimulationMutationVariables = Exact<{
  config: GQSimulationScenarioRunConfig;
}>;

export type GQRunSimulationMutation = {
  __typename?: 'Mutation';
  runSimulation: {
    __typename?: 'SimulationScenarioRun';
    id: string;
    footprintSnapshotId: string;
    scenarioConfiguration: any;
    targetingFilters: any;
    resultData: any | null;
    scenario: { __typename?: 'SimulationScenario'; id: string };
  };
};

export type GQTargetingOptionsFragment = {
  __typename?: 'SimulationTargetingOptions';
  field: string;
  categoryLabel: string;
  options: Array<string>;
};

export type GQProductSimulationRowsFragment = {
  __typename?: 'ProductsAndMaterialSimulationData';
  products: Array<{
    __typename?: 'ProductFootprintRowUpdatedTaxonomy';
    id: string;
    product: string;
    emissionsPerUnit: number | null;
    unitsProduced: number;
    unitsProducedPercentage: number | null;
    totalEmissions: number;
    totalEmissionsPercent: number | null;
    categoryEmissionsPercent: number | null;
    vectors: Array<{
      __typename?: 'VectorNameAndValues';
      vectorName: string;
      vectorValues: Array<string> | null;
    }> | null;
    relatedMaterials: Array<{
      __typename?: 'MaterialFootprintRowReference';
      id: string;
      material: string;
      percentageOfProduct: number;
    }>;
  }>;
  materials: Array<{
    __typename?: 'MaterialFootprintRowUpdatedTaxonomy';
    id: string;
    orgMaterial: string;
    orgRawMaterial: string;
    emissionsPerKg: number | null;
    inputQuantity: number;
    inputQuantityPercentage: number | null;
    totalEmissions: number;
    totalEmissionsPercent: number | null;
    product: Array<{
      __typename?: 'ProductFootprintRowReference';
      id: string;
      product: string;
      totalEmissions: number;
    }>;
    vectors: Array<{
      __typename?: 'VectorNameAndValues';
      vectorName: string;
      vectorValues: Array<string> | null;
    }> | null;
  }>;
};

export type GQPowerSimulationRowsFragment = {
  __typename?: 'PowerSimulationData';
  rows: Array<{
    __typename?: 'PowerSimulationRow';
    supplier: string | null;
    region: string | null;
    unitsProduced: number | null;
    totalEmissions: number | null;
    percentageOfEmissions: number | null;
  }>;
};

export type GQSwapOverlayFragment = {
  __typename?: 'SimulationSwapOverlay';
  original: {
    __typename?: 'SimulationSwapOverlayData';
    choice: string;
    emissionsPerKg: number;
    cost: {
      __typename?: 'SimulationCostPerUnitOverride';
      deltaPerUnit: number;
      isPercentage: boolean;
      unit: string;
      hideCost: boolean;
    } | null;
  } | null;
  swap: {
    __typename?: 'SimulationSwapOverlayData';
    choice: string;
    emissionsPerKg: number;
    assumptionMarkdown: string | null;
    cost: {
      __typename?: 'SimulationCostPerUnitOverride';
      deltaPerUnit: number;
      isPercentage: boolean;
      unit: string;
      hideCost: boolean;
    } | null;
  } | null;
};

export type GQGetScenarioPageSwapAndTargetingOptionsQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
  footprintInterval: Scalars['YMInterval']['input'];
  footprintKind: InputMaybe<Scalars['String']['input']>;
  simulationType: GQSimulationScenarioConfigType;
  config: Scalars['JSONString']['input'];
}>;

export type GQGetScenarioPageSwapAndTargetingOptionsQuery = {
  __typename?: 'Query';
  swapOptions: Array<{
    __typename?: 'SimulationSwapOption';
    sourceCategory: string;
    targetCategories: Array<string>;
    percentOfTotalEmissions: number;
    targetCategoriesWithDetails: Array<{
      __typename?: 'SimulationOptionDetails';
      category: string;
      kgco2ePerKg: number;
    }>;
  }>;
  targetingOptions: Array<{
    __typename?: 'SimulationTargetingOptions';
    field: string;
    categoryLabel: string;
    options: Array<string>;
  }>;
};

export type GQGetScenarioPageSimulationRowDataQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
  footprintInterval: Scalars['YMInterval']['input'];
  footprintKind: InputMaybe<Scalars['String']['input']>;
  simulationType: GQSimulationScenarioConfigType;
  config: Scalars['JSONString']['input'];
}>;

export type GQGetScenarioPageSimulationRowDataQuery = {
  __typename?: 'Query';
  simulationRowResult:
    | {
        __typename: 'PowerSimulationData';
        rows: Array<{
          __typename?: 'PowerSimulationRow';
          supplier: string | null;
          region: string | null;
          unitsProduced: number | null;
          totalEmissions: number | null;
          percentageOfEmissions: number | null;
        }>;
      }
    | {
        __typename: 'ProductsAndMaterialSimulationData';
        products: Array<{
          __typename?: 'ProductFootprintRowUpdatedTaxonomy';
          id: string;
          product: string;
          emissionsPerUnit: number | null;
          unitsProduced: number;
          unitsProducedPercentage: number | null;
          totalEmissions: number;
          totalEmissionsPercent: number | null;
          categoryEmissionsPercent: number | null;
          vectors: Array<{
            __typename?: 'VectorNameAndValues';
            vectorName: string;
            vectorValues: Array<string> | null;
          }> | null;
          relatedMaterials: Array<{
            __typename?: 'MaterialFootprintRowReference';
            id: string;
            material: string;
            percentageOfProduct: number;
          }>;
        }>;
        materials: Array<{
          __typename?: 'MaterialFootprintRowUpdatedTaxonomy';
          id: string;
          orgMaterial: string;
          orgRawMaterial: string;
          emissionsPerKg: number | null;
          inputQuantity: number;
          inputQuantityPercentage: number | null;
          totalEmissions: number;
          totalEmissionsPercent: number | null;
          product: Array<{
            __typename?: 'ProductFootprintRowReference';
            id: string;
            product: string;
            totalEmissions: number;
          }>;
          vectors: Array<{
            __typename?: 'VectorNameAndValues';
            vectorName: string;
            vectorValues: Array<string> | null;
          }> | null;
        }>;
      };
};

export type GQGetSimulationSwapOverlayDataQueryVariables = Exact<{
  config: Scalars['JSONString']['input'];
  scenarioId: Scalars['ID']['input'];
}>;

export type GQGetSimulationSwapOverlayDataQuery = {
  __typename?: 'Query';
  selectedSwapOverlayData: {
    __typename?: 'SimulationSwapOverlay';
    original: {
      __typename?: 'SimulationSwapOverlayData';
      choice: string;
      emissionsPerKg: number;
      cost: {
        __typename?: 'SimulationCostPerUnitOverride';
        deltaPerUnit: number;
        isPercentage: boolean;
        unit: string;
        hideCost: boolean;
      } | null;
    } | null;
    swap: {
      __typename?: 'SimulationSwapOverlayData';
      choice: string;
      emissionsPerKg: number;
      assumptionMarkdown: string | null;
      cost: {
        __typename?: 'SimulationCostPerUnitOverride';
        deltaPerUnit: number;
        isPercentage: boolean;
        unit: string;
        hideCost: boolean;
      } | null;
    } | null;
  };
};

export type GQCreateDuplicateSimulationMutationVariables = Exact<{
  scenarioId: Scalars['ID']['input'];
}>;

export type GQCreateDuplicateSimulationMutation = {
  __typename?: 'Mutation';
  duplicateScenarioWithPortfolio: {
    __typename?: 'SimulationScenario';
    id: string;
    portfolio: { __typename?: 'SimulationPortfolio'; id: string };
  };
};

export type GQGetReductionsContributionsQueryVariables = Exact<{
  footprintKind: Scalars['String']['input'];
  footprintVersion: InputMaybe<Scalars['String']['input']>;
  timeseriesKind: GQColumnDimension;
  columns: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  enableReduxContributionsIntervalOptimization: InputMaybe<
    Scalars['Boolean']['input']
  >;
}>;

export type GQGetReductionsContributionsQuery = {
  __typename?: 'Query';
  footprint: {
    __typename?: 'LegacyFootprint';
    id: string;
    summary: {
      __typename?: 'FootprintSummary';
      id: string;
      timeseries: {
        __typename?: 'FootprintAnalysisTimeSeries';
        id: string;
        series: Array<{
          __typename?: 'FootprintAnalysisTimeseriesSeries';
          data: Array<{
            __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
            date: Date;
            value: number;
            headcount: number | null;
            revenue: number | null;
            customIntensities: Array<{
              __typename?: 'FootprintAnalysisCustomIntensityDataPoint';
              customIntensityId: string;
              value: number | null;
            }> | null;
          }>;
        }>;
      } | null;
      removalTimeseries: {
        __typename?: 'FootprintAnalysisTimeSeries';
        id: string;
        series: Array<{
          __typename?: 'FootprintAnalysisTimeseriesSeries';
          data: Array<{
            __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
            date: Date;
            value: number;
            headcount: number | null;
            revenue: number | null;
            customIntensities: Array<{
              __typename?: 'FootprintAnalysisCustomIntensityDataPoint';
              customIntensityId: string;
              value: number | null;
            }> | null;
          }>;
        }>;
      } | null;
      customIntensityMetadata: Array<{
        __typename?: 'CustomIntensityMetadata';
        id: string;
      }>;
    };
  } | null;
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    reductionsContributionsData: {
      __typename?: 'FootprintContributionsData';
      id: string;
      data: any;
    };
  } | null;
};

export type GQGetForecastFootprintSnapshotIdQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetForecastFootprintSnapshotIdQuery = {
  __typename?: 'Query';
  forecast: {
    __typename?: 'Forecast';
    id: string;
    footprintSnapshotId: string;
  };
};

type GQAssigneeInfoForContainedQuestions_ReportQuestion_Fragment = {
  __typename: 'ReportQuestion';
  id: string;
  assignees: Array<{
    __typename?: 'User';
    id: string;
    displayName: string;
    name: string;
  }>;
};

type GQAssigneeInfoForContainedQuestions_ReportQuestionContainer_Fragment = {
  __typename: 'ReportQuestionContainer';
  id: string;
  items: Array<
    | {
        __typename: 'ReportQuestion';
        id: string;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          displayName: string;
          name: string;
        }>;
      }
    | {
        __typename: 'ReportQuestionContainer';
        id: string;
        items: Array<
          | {
              __typename: 'ReportQuestion';
              id: string;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                displayName: string;
                name: string;
              }>;
            }
          | {
              __typename: 'ReportQuestionContainer';
              id: string;
              items: Array<
                | {
                    __typename: 'ReportQuestion';
                    id: string;
                    assignees: Array<{
                      __typename?: 'User';
                      id: string;
                      displayName: string;
                      name: string;
                    }>;
                  }
                | { __typename: 'ReportQuestionContainer' }
              >;
            }
        >;
      }
  >;
};

export type GQAssigneeInfoForContainedQuestionsFragment =
  | GQAssigneeInfoForContainedQuestions_ReportQuestion_Fragment
  | GQAssigneeInfoForContainedQuestions_ReportQuestionContainer_Fragment;

export type GQAssignToContainerMutationVariables = Exact<{
  input: GQAssignToContainerInput;
  reportId: Scalars['ID']['input'];
}>;

export type GQAssignToContainerMutation = {
  __typename?: 'Mutation';
  assignToContainer: {
    __typename?: 'AssignToContainerPayload';
    container: {
      __typename?: 'ReportQuestionContainer';
      id: string;
      assignees: Array<{
        __typename?: 'User';
        id: string;
        displayName: string;
        name: string;
      }>;
      items: Array<
        | {
            __typename: 'ReportQuestion';
            id: string;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              displayName: string;
              name: string;
            }>;
          }
        | {
            __typename: 'ReportQuestionContainer';
            id: string;
            items: Array<
              | {
                  __typename: 'ReportQuestion';
                  id: string;
                  assignees: Array<{
                    __typename?: 'User';
                    id: string;
                    displayName: string;
                    name: string;
                  }>;
                }
              | {
                  __typename: 'ReportQuestionContainer';
                  id: string;
                  items: Array<
                    | {
                        __typename: 'ReportQuestion';
                        id: string;
                        assignees: Array<{
                          __typename?: 'User';
                          id: string;
                          displayName: string;
                          name: string;
                        }>;
                      }
                    | {
                        __typename: 'ReportQuestionContainer';
                        id: string;
                        items: Array<
                          | {
                              __typename: 'ReportQuestion';
                              id: string;
                              assignees: Array<{
                                __typename?: 'User';
                                id: string;
                                displayName: string;
                                name: string;
                              }>;
                            }
                          | { __typename: 'ReportQuestionContainer' }
                        >;
                      }
                  >;
                }
            >;
          }
      >;
    };
  } | null;
};

export type GQGetReportQuestionContainerAssignmentEmailQueryVariables = Exact<{
  input: GQAssignReportQuestionContainerPreviewEmailInput;
}>;

export type GQGetReportQuestionContainerAssignmentEmailQuery = {
  __typename?: 'Query';
  assignReportQuestionContainerPreviewEmail: {
    __typename?: 'EmailContents';
    subject: string;
    bodyHtml: string;
    recipients: Array<{
      __typename?: 'EmailUser';
      email: string;
      name: string;
    }>;
    ccs: Array<{
      __typename?: 'EmailUser';
      email: string;
      name: string;
    }> | null;
    sender: { __typename?: 'EmailUser'; email: string; name: string };
    bccs: Array<{
      __typename?: 'EmailUser';
      email: string;
      name: string;
    } | null> | null;
  };
};

export type GQCreateReportWithCustomConfigMutationVariables = Exact<{
  input: InputMaybe<GQCreateReportWithCustomConfigInput>;
}>;

export type GQCreateReportWithCustomConfigMutation = {
  __typename?: 'Mutation';
  createReportWithCustomConfig: {
    __typename?: 'CreateReportWithCustomConfigPayload';
    report: {
      __typename?: 'Report';
      id: string;
      name: string;
      reportKind: string;
      reportStatus: GQReportStatus;
    };
  } | null;
};

export type GQRenameReportMutationVariables = Exact<{
  input: GQRenameReportInput;
}>;

export type GQRenameReportMutation = {
  __typename?: 'Mutation';
  renameReport: {
    __typename?: 'RenameReportPayload';
    report: { __typename?: 'Report'; id: string; name: string };
  } | null;
};

export type GQReportCompletionQueryVariables = Exact<{
  input: GQReportProgressInput;
}>;

export type GQReportCompletionQuery = {
  __typename?: 'Query';
  reportProgress: {
    __typename?: 'ReportProgressPayload';
    id: string;
    percentComplete: number;
    reasons: Array<GQReportProgressReason>;
  } | null;
};

export type GQCreateReportWithConfigMutationVariables = Exact<{
  input: GQCreateReportWithConfigInput;
}>;

export type GQCreateReportWithConfigMutation = {
  __typename?: 'Mutation';
  createReportWithConfig: { __typename?: 'ReportWithConfig'; id: string };
};

export type GQReportPageReportFragment = {
  __typename: 'Report';
  id: string;
  name: string;
  reportStatus: GQReportStatus;
  reportKind: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  warnings: Array<{
    __typename?: 'ReportAnswerWarning';
    id: string;
    outputJson: any;
    reportId: string;
    validatorId: string;
    acknowledgedBy: { __typename?: 'User'; id: string } | null;
  }>;
  config: {
    __typename?: 'ReportConfig';
    id: string;
    reportType: string;
    shortName: string;
    longName: string;
    requiredPermissions: Array<GQPermissionType>;
    createdAt: Date;
  };
};

export type GQReportPageCustomReportFragment = {
  __typename: 'FootprintCustomReport';
  id: string;
  name: string;
  description: string;
  lastRefreshedAt: Date;
  updatedAt: Date;
  downloadUrl: string;
};

export type GQReportsPageQueryVariables = Exact<{
  isAdmin: Scalars['Boolean']['input'];
}>;

export type GQReportsPageQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  formalReports: {
    __typename?: 'ReportConnection';
    edges: Array<{
      __typename?: 'ReportEdge';
      node: {
        __typename: 'Report';
        id: string;
        name: string;
        reportStatus: GQReportStatus;
        reportKind: string;
        createdAt: Date;
        updatedAt: Date;
        deletedAt: Date | null;
        warnings: Array<{
          __typename?: 'ReportAnswerWarning';
          id: string;
          outputJson: any;
          reportId: string;
          validatorId: string;
          acknowledgedBy: { __typename?: 'User'; id: string } | null;
        }>;
        config: {
          __typename?: 'ReportConfig';
          id: string;
          reportType: string;
          shortName: string;
          longName: string;
          requiredPermissions: Array<GQPermissionType>;
          createdAt: Date;
        };
      } | null;
    } | null>;
  };
  footprintAnalysis?: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    footprintInterval: YMInterval | null;
    customReports: Array<{
      __typename: 'FootprintCustomReport';
      id: string;
      name: string;
      description: string;
      lastRefreshedAt: Date;
      updatedAt: Date;
      downloadUrl: string;
    }>;
  } | null;
  footprint: { __typename?: 'LegacyFootprint'; id: string } | null;
  organization: {
    __typename?: 'Organization';
    id: string;
    currency: string | null;
  };
};

export type GQReportQuestionUsingSavedViewFieldsFragment = {
  __typename?: 'ReportQuestion';
  id: string;
  componentId: string;
  isComputed: boolean;
};

export type GQGetDrilldownForReportQuestionQueryVariables = Exact<{
  reportId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
}>;

export type GQGetDrilldownForReportQuestionQuery = {
  __typename?: 'Query';
  drilldownForReportQuestion: {
    __typename?: 'DrilldownForReportQuestionPayload';
    mergedControlsJson: any;
    savedViewControlsJson: any;
    reportControlsJson: any;
    cardControlsJson: any | null;
    report: {
      __typename?: 'Report';
      id: string;
      name: string;
      dmaId: string | null;
      config: {
        __typename?: 'ReportConfig';
        id: string;
        shortName: string;
        longName: string;
        description: string;
        brandColor: string;
        status: GQReportConfigStatus | null;
        reportType: string;
        updatedAt: Date;
        useTableDisplay: boolean | null;
        useReportingIntention: boolean | null;
        inputs: Array<{
          __typename?: 'ReportQuestion';
          id: string;
          identifier: string;
          identifierId: string;
          componentId: string;
          isComputed: boolean;
          guidanceJson: any | null;
          resolvedInputs:
            | {
                __typename: 'ResolvedReportQuestionInputsFailure';
                questionIds: Array<string>;
                errorMessage: string | null;
              }
            | {
                __typename: 'ResolvedReportQuestionInputsSuccess';
                inputs: any;
                unparsedInputs: any;
              };
          answer: {
            __typename?: 'ReportAnswer';
            id: string;
            outputJson: any | null;
          } | null;
        }>;
        items: Array<
          | {
              __typename: 'ReportQuestion';
              allowNotes: boolean;
              allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
              id: string;
              identifier: string;
              identifierId: string;
              componentId: string;
              isComputed: boolean;
              guidanceJson: any | null;
              parentContainer: {
                __typename?: 'ReportQuestionContainer';
                id: string;
                label: string | null;
              } | null;
              resolvedWarnings: Array<{
                __typename?: 'ReportAnswerWarning';
                id: string;
                outputJson: any;
                reportId: string;
                validatorId: string;
                acknowledgedBy: { __typename?: 'User'; id: string } | null;
                answer: {
                  __typename?: 'ReportAnswer';
                  id: string;
                  question: {
                    __typename?: 'ReportQuestion';
                    id: string;
                    isComputed: boolean;
                    identifier: string;
                    parentContainer: {
                      __typename?: 'ReportQuestionContainer';
                      id: string;
                      label: string | null;
                    } | null;
                  };
                };
              }>;
              resolvedInputs:
                | {
                    __typename: 'ResolvedReportQuestionInputsFailure';
                    errorMessage: string | null;
                    questionIds: Array<string>;
                  }
                | {
                    __typename: 'ResolvedReportQuestionInputsSuccess';
                    inputs: any;
                    unparsedInputs: any;
                  };
              resolvedStatus: {
                __typename?: 'ResolvedReportQuestionStatus';
                id: string;
                status: GQReportQuestionStatus;
                blockingMessage: string | null;
              };
              answer: {
                __typename?: 'ReportAnswer';
                id: string;
                updatedAt: Date;
                outputJson: any | null;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                displayName: string;
                name: string;
              }>;
            }
          | {
              __typename: 'ReportQuestionContainer';
              id: string;
              label: string | null;
              description: string | null;
              items: Array<
                | {
                    __typename: 'ReportQuestion';
                    id: string;
                    allowNotes: boolean;
                    allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
                    identifier: string;
                    identifierId: string;
                    componentId: string;
                    isComputed: boolean;
                    guidanceJson: any | null;
                    parentContainer: {
                      __typename?: 'ReportQuestionContainer';
                      id: string;
                      label: string | null;
                    } | null;
                    resolvedWarnings: Array<{
                      __typename?: 'ReportAnswerWarning';
                      id: string;
                      outputJson: any;
                      reportId: string;
                      validatorId: string;
                      acknowledgedBy: {
                        __typename?: 'User';
                        id: string;
                      } | null;
                      answer: {
                        __typename?: 'ReportAnswer';
                        id: string;
                        question: {
                          __typename?: 'ReportQuestion';
                          id: string;
                          isComputed: boolean;
                          identifier: string;
                          parentContainer: {
                            __typename?: 'ReportQuestionContainer';
                            id: string;
                            label: string | null;
                          } | null;
                        };
                      };
                    }>;
                    resolvedInputs:
                      | {
                          __typename: 'ResolvedReportQuestionInputsFailure';
                          errorMessage: string | null;
                          questionIds: Array<string>;
                        }
                      | {
                          __typename: 'ResolvedReportQuestionInputsSuccess';
                          inputs: any;
                          unparsedInputs: any;
                        };
                    resolvedStatus: {
                      __typename?: 'ResolvedReportQuestionStatus';
                      id: string;
                      status: GQReportQuestionStatus;
                      blockingMessage: string | null;
                    };
                    answer: {
                      __typename?: 'ReportAnswer';
                      id: string;
                      updatedAt: Date;
                      outputJson: any | null;
                    } | null;
                    assignees: Array<{
                      __typename?: 'User';
                      id: string;
                      displayName: string;
                      name: string;
                    }>;
                  }
                | { __typename: 'ReportQuestionContainer'; id: string }
              >;
            }
        >;
      };
    };
    question: {
      __typename?: 'ReportQuestion';
      id: string;
      componentId: string;
      isComputed: boolean;
    };
  };
};

export type GQCreateCsrdDmaMutationVariables = Exact<{
  input: GQCreateCsrdDmaInput;
}>;

export type GQCreateCsrdDmaMutation = {
  __typename?: 'Mutation';
  createCsrdDma: {
    __typename?: 'CreateCsrdDmaPayload';
    updatedReport: { __typename?: 'Report'; id: string; dmaId: string | null };
    dmaTopics: Array<{
      __typename?: 'CsrdDoubleMaterialityAssessmentTopic';
      topicIdentifier: GQCsrdTopic;
      isMaterial: boolean;
    }>;
  };
};

export type GQFetchCsrdDmaValidityQueryVariables = Exact<{
  dmaId: Scalars['ID']['input'];
}>;

export type GQFetchCsrdDmaValidityQuery = {
  __typename?: 'Query';
  csrdDma: {
    __typename?: 'CsrdDoubleMaterialityAssessment';
    validityStart: YearMonth;
    validityEnd: YearMonth;
  } | null;
};

export type GQUpdateReportConfigMutationVariables = Exact<{
  reportId: Scalars['ID']['input'];
  input: GQUpdateReportConfigDashboardInput;
}>;

export type GQUpdateReportConfigMutation = {
  __typename?: 'Mutation';
  updateReportConfigDashboard: {
    __typename?: 'UpdateReportConfigDashboardPayload';
    reportConfig: {
      __typename?: 'ReportConfig';
      id: string;
      shortName: string;
      longName: string;
      description: string;
      brandColor: string;
      status: GQReportConfigStatus | null;
      reportType: string;
      updatedAt: Date;
      useTableDisplay: boolean | null;
      useReportingIntention: boolean | null;
      inputs: Array<{
        __typename?: 'ReportQuestion';
        id: string;
        identifier: string;
        identifierId: string;
        componentId: string;
        isComputed: boolean;
        guidanceJson: any | null;
        resolvedInputs:
          | {
              __typename: 'ResolvedReportQuestionInputsFailure';
              questionIds: Array<string>;
              errorMessage: string | null;
            }
          | {
              __typename: 'ResolvedReportQuestionInputsSuccess';
              inputs: any;
              unparsedInputs: any;
            };
        answer: {
          __typename?: 'ReportAnswer';
          id: string;
          outputJson: any | null;
        } | null;
      }>;
      items: Array<
        | {
            __typename: 'ReportQuestion';
            allowNotes: boolean;
            allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
            id: string;
            identifier: string;
            identifierId: string;
            componentId: string;
            isComputed: boolean;
            guidanceJson: any | null;
            parentContainer: {
              __typename?: 'ReportQuestionContainer';
              id: string;
              label: string | null;
            } | null;
            resolvedWarnings: Array<{
              __typename?: 'ReportAnswerWarning';
              id: string;
              outputJson: any;
              reportId: string;
              validatorId: string;
              acknowledgedBy: { __typename?: 'User'; id: string } | null;
              answer: {
                __typename?: 'ReportAnswer';
                id: string;
                question: {
                  __typename?: 'ReportQuestion';
                  id: string;
                  isComputed: boolean;
                  identifier: string;
                  parentContainer: {
                    __typename?: 'ReportQuestionContainer';
                    id: string;
                    label: string | null;
                  } | null;
                };
              };
            }>;
            resolvedInputs:
              | {
                  __typename: 'ResolvedReportQuestionInputsFailure';
                  errorMessage: string | null;
                  questionIds: Array<string>;
                }
              | {
                  __typename: 'ResolvedReportQuestionInputsSuccess';
                  inputs: any;
                  unparsedInputs: any;
                };
            resolvedStatus: {
              __typename?: 'ResolvedReportQuestionStatus';
              id: string;
              status: GQReportQuestionStatus;
              blockingMessage: string | null;
            };
            answer: {
              __typename?: 'ReportAnswer';
              id: string;
              updatedAt: Date;
              outputJson: any | null;
            } | null;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              displayName: string;
              name: string;
            }>;
          }
        | {
            __typename: 'ReportQuestionContainer';
            id: string;
            label: string | null;
            description: string | null;
            items: Array<
              | {
                  __typename: 'ReportQuestion';
                  id: string;
                  allowNotes: boolean;
                  allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
                  identifier: string;
                  identifierId: string;
                  componentId: string;
                  isComputed: boolean;
                  guidanceJson: any | null;
                  parentContainer: {
                    __typename?: 'ReportQuestionContainer';
                    id: string;
                    label: string | null;
                  } | null;
                  resolvedWarnings: Array<{
                    __typename?: 'ReportAnswerWarning';
                    id: string;
                    outputJson: any;
                    reportId: string;
                    validatorId: string;
                    acknowledgedBy: { __typename?: 'User'; id: string } | null;
                    answer: {
                      __typename?: 'ReportAnswer';
                      id: string;
                      question: {
                        __typename?: 'ReportQuestion';
                        id: string;
                        isComputed: boolean;
                        identifier: string;
                        parentContainer: {
                          __typename?: 'ReportQuestionContainer';
                          id: string;
                          label: string | null;
                        } | null;
                      };
                    };
                  }>;
                  resolvedInputs:
                    | {
                        __typename: 'ResolvedReportQuestionInputsFailure';
                        errorMessage: string | null;
                        questionIds: Array<string>;
                      }
                    | {
                        __typename: 'ResolvedReportQuestionInputsSuccess';
                        inputs: any;
                        unparsedInputs: any;
                      };
                  resolvedStatus: {
                    __typename?: 'ResolvedReportQuestionStatus';
                    id: string;
                    status: GQReportQuestionStatus;
                    blockingMessage: string | null;
                  };
                  answer: {
                    __typename?: 'ReportAnswer';
                    id: string;
                    updatedAt: Date;
                    outputJson: any | null;
                  } | null;
                  assignees: Array<{
                    __typename?: 'User';
                    id: string;
                    displayName: string;
                    name: string;
                  }>;
                }
              | { __typename: 'ReportQuestionContainer'; id: string }
            >;
          }
      >;
    };
  } | null;
};

export type GQReportWithConfigForTableFragment = {
  __typename?: 'ReportWithConfig';
  id: string;
  report: { __typename?: 'Report'; id: string; name: string };
  config: {
    __typename?: 'ReportConfig';
    id: string;
    reportType: string;
    useReportingIntention: boolean | null;
    description: string;
    status: GQReportConfigStatus | null;
    shortName: string;
    longName: string;
    allQuestions: Array<{
      __typename?: 'ReportQuestion';
      id: string;
      identifier: string;
      staticInputJson: any | null;
      displayName: string | null;
      componentId: string;
      resolvedReportingIntention?: {
        __typename?: 'ResolvedReportingIntention';
        id: string;
        intention: GQReportingIntention;
      };
      assignees: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      }>;
      answer: {
        __typename?: 'ReportAnswer';
        id: string;
        updatedAt: Date;
      } | null;
      resolvedInputs:
        | {
            __typename?: 'ResolvedReportQuestionInputsFailure';
            errorMessage: string | null;
            questionIds: Array<string>;
          }
        | { __typename?: 'ResolvedReportQuestionInputsSuccess'; inputs: any };
      path: Array<{
        __typename?: 'ReportObjectConfigPathItem';
        id: string | null;
        label: string | null;
      }>;
      resolvedStatusIncludingContainer: {
        __typename?: 'ResolvedReportQuestionStatus';
        id: string;
        status: GQReportQuestionStatus;
        blockingMessage: string | null;
      };
      parentContainer: {
        __typename?: 'ReportQuestionContainer';
        label: string | null;
        id: string;
        resolvedStatus: {
          __typename?: 'ResolvedReportQuestionStatus';
          id: string;
          blockingMessage: string | null;
          status: GQReportQuestionStatus;
        };
        parentContainer: {
          __typename?: 'ReportQuestionContainer';
          label: string | null;
          id: string;
          resolvedStatus: {
            __typename?: 'ResolvedReportQuestionStatus';
            id: string;
            blockingMessage: string | null;
            status: GQReportQuestionStatus;
          };
          parentContainer: {
            __typename?: 'ReportQuestionContainer';
            label: string | null;
            id: string;
            resolvedStatus: {
              __typename?: 'ResolvedReportQuestionStatus';
              id: string;
              status: GQReportQuestionStatus;
              blockingMessage: string | null;
            };
          } | null;
        } | null;
      } | null;
    }>;
    inputs: Array<{
      __typename?: 'ReportQuestion';
      id: string;
      componentId: string;
    }>;
  };
};

export type GQReportConfigQuestionForTableFragment = {
  __typename?: 'ReportQuestion';
  id: string;
  identifier: string;
  staticInputJson: any | null;
  displayName: string | null;
  componentId: string;
  resolvedReportingIntention?: {
    __typename?: 'ResolvedReportingIntention';
    id: string;
    intention: GQReportingIntention;
  };
  assignees: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
  }>;
  answer: { __typename?: 'ReportAnswer'; id: string; updatedAt: Date } | null;
  resolvedInputs:
    | {
        __typename?: 'ResolvedReportQuestionInputsFailure';
        errorMessage: string | null;
        questionIds: Array<string>;
      }
    | { __typename?: 'ResolvedReportQuestionInputsSuccess'; inputs: any };
  path: Array<{
    __typename?: 'ReportObjectConfigPathItem';
    id: string | null;
    label: string | null;
  }>;
  resolvedStatusIncludingContainer: {
    __typename?: 'ResolvedReportQuestionStatus';
    id: string;
    status: GQReportQuestionStatus;
    blockingMessage: string | null;
  };
  parentContainer: {
    __typename?: 'ReportQuestionContainer';
    label: string | null;
    id: string;
    resolvedStatus: {
      __typename?: 'ResolvedReportQuestionStatus';
      id: string;
      blockingMessage: string | null;
      status: GQReportQuestionStatus;
    };
    parentContainer: {
      __typename?: 'ReportQuestionContainer';
      label: string | null;
      id: string;
      resolvedStatus: {
        __typename?: 'ResolvedReportQuestionStatus';
        id: string;
        blockingMessage: string | null;
        status: GQReportQuestionStatus;
      };
      parentContainer: {
        __typename?: 'ReportQuestionContainer';
        label: string | null;
        id: string;
        resolvedStatus: {
          __typename?: 'ResolvedReportQuestionStatus';
          id: string;
          status: GQReportQuestionStatus;
          blockingMessage: string | null;
        };
      } | null;
    } | null;
  } | null;
};

export type GQUserForReportTableAssignmentFragment = {
  __typename?: 'User';
  id: string;
  displayName: string;
  hasPermission: boolean;
};

export type GQGetReportQuestionsListQueryVariables = Exact<{
  reportId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  input: InputMaybe<GQReportAllQuestionsInput>;
  includeReportingIntention?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQGetReportQuestionsListQuery = {
  __typename?: 'Query';
  reportWithConfig: {
    __typename?: 'ReportWithConfig';
    id: string;
    report: { __typename?: 'Report'; id: string; name: string };
    config: {
      __typename?: 'ReportConfig';
      id: string;
      reportType: string;
      useReportingIntention: boolean | null;
      description: string;
      status: GQReportConfigStatus | null;
      shortName: string;
      longName: string;
      allQuestions: Array<{
        __typename?: 'ReportQuestion';
        id: string;
        identifier: string;
        staticInputJson: any | null;
        displayName: string | null;
        componentId: string;
        resolvedReportingIntention?: {
          __typename?: 'ResolvedReportingIntention';
          id: string;
          intention: GQReportingIntention;
        };
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        answer: {
          __typename?: 'ReportAnswer';
          id: string;
          updatedAt: Date;
        } | null;
        resolvedInputs:
          | {
              __typename?: 'ResolvedReportQuestionInputsFailure';
              errorMessage: string | null;
              questionIds: Array<string>;
            }
          | { __typename?: 'ResolvedReportQuestionInputsSuccess'; inputs: any };
        path: Array<{
          __typename?: 'ReportObjectConfigPathItem';
          id: string | null;
          label: string | null;
        }>;
        resolvedStatusIncludingContainer: {
          __typename?: 'ResolvedReportQuestionStatus';
          id: string;
          status: GQReportQuestionStatus;
          blockingMessage: string | null;
        };
        parentContainer: {
          __typename?: 'ReportQuestionContainer';
          label: string | null;
          id: string;
          resolvedStatus: {
            __typename?: 'ResolvedReportQuestionStatus';
            id: string;
            blockingMessage: string | null;
            status: GQReportQuestionStatus;
          };
          parentContainer: {
            __typename?: 'ReportQuestionContainer';
            label: string | null;
            id: string;
            resolvedStatus: {
              __typename?: 'ResolvedReportQuestionStatus';
              id: string;
              blockingMessage: string | null;
              status: GQReportQuestionStatus;
            };
            parentContainer: {
              __typename?: 'ReportQuestionContainer';
              label: string | null;
              id: string;
              resolvedStatus: {
                __typename?: 'ResolvedReportQuestionStatus';
                id: string;
                status: GQReportQuestionStatus;
                blockingMessage: string | null;
              };
            } | null;
          } | null;
        } | null;
      }>;
      inputs: Array<{
        __typename?: 'ReportQuestion';
        id: string;
        componentId: string;
      }>;
    };
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          displayName: string;
          hasPermission: boolean;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQGetReportInputsQueryVariables = Exact<{
  reportId: Scalars['ID']['input'];
}>;

export type GQGetReportInputsQuery = {
  __typename?: 'Query';
  reportWithConfig: {
    __typename?: 'ReportWithConfig';
    id: string;
    config: {
      __typename?: 'ReportConfig';
      id: string;
      inputs: Array<{
        __typename?: 'ReportQuestion';
        id: string;
        identifier: string;
        identifierId: string;
        componentId: string;
        isComputed: boolean;
        guidanceJson: any | null;
        resolvedStatus: {
          __typename?: 'ResolvedReportQuestionStatus';
          id: string;
          status: GQReportQuestionStatus;
          blockingMessage: string | null;
          excludingQuestionId: string | null;
        };
        resolvedInputs:
          | {
              __typename: 'ResolvedReportQuestionInputsFailure';
              questionIds: Array<string>;
              errorMessage: string | null;
            }
          | {
              __typename: 'ResolvedReportQuestionInputsSuccess';
              inputs: any;
              unparsedInputs: any;
            };
        answer: {
          __typename?: 'ReportAnswer';
          id: string;
          outputJson: any | null;
        } | null;
      }>;
    };
  };
};

export type GQPeerExternalReportAnswersForExternalAnswersListFragment = {
  __typename?: 'PeerExternalReportAnswers';
  peerCompanies: Array<{ __typename?: 'Company'; id: string; name: string }>;
  answers: Array<{
    __typename?: 'ExternalReportAnswer';
    id: string;
    externalReportQuestionId: string;
    answerType: string;
    answer: any;
    watershedQualityScore: GQDisclosureQualityScore | null;
    questionPhrasing: string | null;
    index: number;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      reportType: GQExternalReportType;
      publishingYear: number;
      externalScore: string | null;
      company: {
        __typename?: 'Company';
        id: string;
        name: string;
        fullLogoUrl: string | null;
      } | null;
    };
  }>;
  questions: Array<{
    __typename?: 'ExternalReportQuestion';
    id: string;
    question: string;
    type: {
      __typename?: 'ExternalReportTypeWithRevision';
      id: string;
      type: GQExternalReportType;
      revision: string;
    };
    reportQuestionIdentifier: {
      __typename?: 'ReportQuestionIdentifier';
      id: string;
      identifier: string;
    };
  }>;
};

export type GQReportQuestionForBenchmarkFragment = {
  __typename: 'ReportQuestion';
  id: string;
  identifier: string;
  identifierId: string;
  isComputed: boolean;
  guidanceJson: any | null;
  componentId: string;
  resolvedStatus: {
    __typename?: 'ResolvedReportQuestionStatus';
    id: string;
    status: GQReportQuestionStatus;
    blockingMessage: string | null;
    excludingQuestionId: string | null;
  };
  resolvedInputs:
    | {
        __typename: 'ResolvedReportQuestionInputsFailure';
        errorMessage: string | null;
        questionIds: Array<string>;
      }
    | {
        __typename: 'ResolvedReportQuestionInputsSuccess';
        inputs: any;
        unparsedInputs: any;
      };
  reportConfig: { __typename?: 'ReportConfig'; shortName: string };
  peerExternalReportAnswers: {
    __typename?: 'PeerExternalReportAnswers';
    peerCompanies: Array<{ __typename?: 'Company'; id: string; name: string }>;
    answers: Array<{
      __typename?: 'ExternalReportAnswer';
      id: string;
      externalReportQuestionId: string;
      answerType: string;
      answer: any;
      watershedQualityScore: GQDisclosureQualityScore | null;
      questionPhrasing: string | null;
      index: number;
      publicDisclosure: {
        __typename?: 'PublicDisclosure';
        id: string;
        reportType: GQExternalReportType;
        publishingYear: number;
        externalScore: string | null;
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          fullLogoUrl: string | null;
        } | null;
      };
    }>;
    questions: Array<{
      __typename?: 'ExternalReportQuestion';
      id: string;
      question: string;
      type: {
        __typename?: 'ExternalReportTypeWithRevision';
        id: string;
        type: GQExternalReportType;
        revision: string;
      };
      reportQuestionIdentifier: {
        __typename?: 'ReportQuestionIdentifier';
        id: string;
        identifier: string;
      };
    }>;
  } | null;
};

export type GQGetContainerAndQuestionsForClimateIntelligencePanelQueryVariables =
  Exact<{
    containerId: Scalars['ID']['input'];
    reportId: Scalars['ID']['input'];
  }>;

export type GQGetContainerAndQuestionsForClimateIntelligencePanelQuery = {
  __typename?: 'Query';
  reportQuestionContainer: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    guidanceJson: any | null;
    items: Array<
      | {
          __typename: 'ReportQuestion';
          id: string;
          identifier: string;
          identifierId: string;
          isComputed: boolean;
          guidanceJson: any | null;
          componentId: string;
          resolvedStatus: {
            __typename?: 'ResolvedReportQuestionStatus';
            id: string;
            status: GQReportQuestionStatus;
            blockingMessage: string | null;
            excludingQuestionId: string | null;
          };
          resolvedInputs:
            | {
                __typename: 'ResolvedReportQuestionInputsFailure';
                errorMessage: string | null;
                questionIds: Array<string>;
              }
            | {
                __typename: 'ResolvedReportQuestionInputsSuccess';
                inputs: any;
                unparsedInputs: any;
              };
          reportConfig: { __typename?: 'ReportConfig'; shortName: string };
          peerExternalReportAnswers: {
            __typename?: 'PeerExternalReportAnswers';
            peerCompanies: Array<{
              __typename?: 'Company';
              id: string;
              name: string;
            }>;
            answers: Array<{
              __typename?: 'ExternalReportAnswer';
              id: string;
              externalReportQuestionId: string;
              answerType: string;
              answer: any;
              watershedQualityScore: GQDisclosureQualityScore | null;
              questionPhrasing: string | null;
              index: number;
              publicDisclosure: {
                __typename?: 'PublicDisclosure';
                id: string;
                reportType: GQExternalReportType;
                publishingYear: number;
                externalScore: string | null;
                company: {
                  __typename?: 'Company';
                  id: string;
                  name: string;
                  fullLogoUrl: string | null;
                } | null;
              };
            }>;
            questions: Array<{
              __typename?: 'ExternalReportQuestion';
              id: string;
              question: string;
              type: {
                __typename?: 'ExternalReportTypeWithRevision';
                id: string;
                type: GQExternalReportType;
                revision: string;
              };
              reportQuestionIdentifier: {
                __typename?: 'ReportQuestionIdentifier';
                id: string;
                identifier: string;
              };
            }>;
          } | null;
        }
      | { __typename: 'ReportQuestionContainer' }
    >;
  };
};

export type GQCustomReportContentFragment = {
  __typename?: 'Report';
  id: string;
  config: {
    __typename?: 'ReportConfig';
    id: string;
    items: Array<
      | {
          __typename: 'ReportQuestion';
          allowNotes: boolean;
          allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
          id: string;
          identifier: string;
          identifierId: string;
          componentId: string;
          isComputed: boolean;
          guidanceJson: any | null;
          parentContainer: {
            __typename?: 'ReportQuestionContainer';
            id: string;
            label: string | null;
          } | null;
          resolvedWarnings: Array<{
            __typename?: 'ReportAnswerWarning';
            id: string;
            outputJson: any;
            reportId: string;
            validatorId: string;
            acknowledgedBy: { __typename?: 'User'; id: string } | null;
            answer: {
              __typename?: 'ReportAnswer';
              id: string;
              question: {
                __typename?: 'ReportQuestion';
                id: string;
                isComputed: boolean;
                identifier: string;
                parentContainer: {
                  __typename?: 'ReportQuestionContainer';
                  id: string;
                  label: string | null;
                } | null;
              };
            };
          }>;
          resolvedInputs:
            | {
                __typename: 'ResolvedReportQuestionInputsFailure';
                errorMessage: string | null;
                questionIds: Array<string>;
              }
            | {
                __typename: 'ResolvedReportQuestionInputsSuccess';
                inputs: any;
                unparsedInputs: any;
              };
          resolvedStatus: {
            __typename?: 'ResolvedReportQuestionStatus';
            id: string;
            status: GQReportQuestionStatus;
            blockingMessage: string | null;
          };
          answer: {
            __typename?: 'ReportAnswer';
            id: string;
            updatedAt: Date;
            outputJson: any | null;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            displayName: string;
            name: string;
          }>;
        }
      | {
          __typename: 'ReportQuestionContainer';
          id: string;
          label: string | null;
          description: string | null;
          items: Array<
            | {
                __typename: 'ReportQuestion';
                allowNotes: boolean;
                allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
                id: string;
                identifier: string;
                identifierId: string;
                componentId: string;
                isComputed: boolean;
                guidanceJson: any | null;
                parentContainer: {
                  __typename?: 'ReportQuestionContainer';
                  id: string;
                  label: string | null;
                } | null;
                resolvedWarnings: Array<{
                  __typename?: 'ReportAnswerWarning';
                  id: string;
                  outputJson: any;
                  reportId: string;
                  validatorId: string;
                  acknowledgedBy: { __typename?: 'User'; id: string } | null;
                  answer: {
                    __typename?: 'ReportAnswer';
                    id: string;
                    question: {
                      __typename?: 'ReportQuestion';
                      id: string;
                      isComputed: boolean;
                      identifier: string;
                      parentContainer: {
                        __typename?: 'ReportQuestionContainer';
                        id: string;
                        label: string | null;
                      } | null;
                    };
                  };
                }>;
                resolvedInputs:
                  | {
                      __typename: 'ResolvedReportQuestionInputsFailure';
                      errorMessage: string | null;
                      questionIds: Array<string>;
                    }
                  | {
                      __typename: 'ResolvedReportQuestionInputsSuccess';
                      inputs: any;
                      unparsedInputs: any;
                    };
                resolvedStatus: {
                  __typename?: 'ResolvedReportQuestionStatus';
                  id: string;
                  status: GQReportQuestionStatus;
                  blockingMessage: string | null;
                };
                answer: {
                  __typename?: 'ReportAnswer';
                  id: string;
                  updatedAt: Date;
                  outputJson: any | null;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  displayName: string;
                  name: string;
                }>;
              }
            | { __typename?: 'ReportQuestionContainer' }
          >;
        }
    >;
  };
};

export type GQAddHeaderToCustomReportMutationVariables = Exact<{
  input: GQAddHeaderToCustomReportInput;
  reportId: Scalars['ID']['input'];
}>;

export type GQAddHeaderToCustomReportMutation = {
  __typename?: 'Mutation';
  addHeaderToCustomReport: {
    __typename?: 'AddHeaderToCustomReportPayload';
    report: {
      __typename?: 'Report';
      id: string;
      config: {
        __typename?: 'ReportConfig';
        id: string;
        items: Array<
          | {
              __typename: 'ReportQuestion';
              allowNotes: boolean;
              allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
              id: string;
              identifier: string;
              identifierId: string;
              componentId: string;
              isComputed: boolean;
              guidanceJson: any | null;
              parentContainer: {
                __typename?: 'ReportQuestionContainer';
                id: string;
                label: string | null;
              } | null;
              resolvedWarnings: Array<{
                __typename?: 'ReportAnswerWarning';
                id: string;
                outputJson: any;
                reportId: string;
                validatorId: string;
                acknowledgedBy: { __typename?: 'User'; id: string } | null;
                answer: {
                  __typename?: 'ReportAnswer';
                  id: string;
                  question: {
                    __typename?: 'ReportQuestion';
                    id: string;
                    isComputed: boolean;
                    identifier: string;
                    parentContainer: {
                      __typename?: 'ReportQuestionContainer';
                      id: string;
                      label: string | null;
                    } | null;
                  };
                };
              }>;
              resolvedInputs:
                | {
                    __typename: 'ResolvedReportQuestionInputsFailure';
                    errorMessage: string | null;
                    questionIds: Array<string>;
                  }
                | {
                    __typename: 'ResolvedReportQuestionInputsSuccess';
                    inputs: any;
                    unparsedInputs: any;
                  };
              resolvedStatus: {
                __typename?: 'ResolvedReportQuestionStatus';
                id: string;
                status: GQReportQuestionStatus;
                blockingMessage: string | null;
              };
              answer: {
                __typename?: 'ReportAnswer';
                id: string;
                updatedAt: Date;
                outputJson: any | null;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                displayName: string;
                name: string;
              }>;
            }
          | {
              __typename: 'ReportQuestionContainer';
              id: string;
              label: string | null;
              description: string | null;
              items: Array<
                | {
                    __typename: 'ReportQuestion';
                    allowNotes: boolean;
                    allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
                    id: string;
                    identifier: string;
                    identifierId: string;
                    componentId: string;
                    isComputed: boolean;
                    guidanceJson: any | null;
                    parentContainer: {
                      __typename?: 'ReportQuestionContainer';
                      id: string;
                      label: string | null;
                    } | null;
                    resolvedWarnings: Array<{
                      __typename?: 'ReportAnswerWarning';
                      id: string;
                      outputJson: any;
                      reportId: string;
                      validatorId: string;
                      acknowledgedBy: {
                        __typename?: 'User';
                        id: string;
                      } | null;
                      answer: {
                        __typename?: 'ReportAnswer';
                        id: string;
                        question: {
                          __typename?: 'ReportQuestion';
                          id: string;
                          isComputed: boolean;
                          identifier: string;
                          parentContainer: {
                            __typename?: 'ReportQuestionContainer';
                            id: string;
                            label: string | null;
                          } | null;
                        };
                      };
                    }>;
                    resolvedInputs:
                      | {
                          __typename: 'ResolvedReportQuestionInputsFailure';
                          errorMessage: string | null;
                          questionIds: Array<string>;
                        }
                      | {
                          __typename: 'ResolvedReportQuestionInputsSuccess';
                          inputs: any;
                          unparsedInputs: any;
                        };
                    resolvedStatus: {
                      __typename?: 'ResolvedReportQuestionStatus';
                      id: string;
                      status: GQReportQuestionStatus;
                      blockingMessage: string | null;
                    };
                    answer: {
                      __typename?: 'ReportAnswer';
                      id: string;
                      updatedAt: Date;
                      outputJson: any | null;
                    } | null;
                    assignees: Array<{
                      __typename?: 'User';
                      id: string;
                      displayName: string;
                      name: string;
                    }>;
                  }
                | { __typename?: 'ReportQuestionContainer' }
              >;
            }
        >;
      };
    };
  } | null;
};

export type GQGetCustomReportControlsQueryVariables = Exact<{
  reportId: Scalars['ID']['input'];
}>;

export type GQGetCustomReportControlsQuery = {
  __typename?: 'Query';
  customReportControls: {
    __typename?: 'CustomReportControlsPayload';
    customReportControlsQuestion: { __typename?: 'ReportQuestion'; id: string };
  } | null;
};

export type GQRestoreDeletedSavedViewMutationVariables = Exact<{
  input: GQRestoreDeletedSavedViewInput;
}>;

export type GQRestoreDeletedSavedViewMutation = {
  __typename?: 'Mutation';
  restoreDeletedSavedView: {
    __typename?: 'BiSavedView';
    id: string;
    stableId: string;
    name: string;
    dimensions: Array<string>;
    chartKind: GQBiChartKind;
    editVisibility: GQBiSavedViewVisibility;
    viewVisibility: GQBiSavedViewVisibility;
    updatedAt: Date;
    createdAt: Date;
    timeIntervals: Array<YMInterval>;
    searchTerm: string | null;
    owner: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    };
    order: {
      __typename?: 'BiQueryOrder';
      dimensionOrMeasure: string;
      direction: GQBiQueryOrderDirection;
    } | null;
    filters: Array<{
      __typename?: 'BiQueryFilter';
      dimension: string;
      operator: GQBiFilterOperator;
      value: Array<FilterValue | null>;
    }>;
    normalizedMeasures: Array<{
      __typename?: 'BiDynamicNormalizingMeasure';
      baseMeasure: string;
      normalizingQuery: {
        __typename?: 'BiDynamicNormalizingQuery';
        measure: string;
        computedDimensionType: GQDimensionComputedType | null;
        computedFiltersType: GQDimensionComputedType | null;
        dimensions: Array<string> | null;
        filters: Array<{
          __typename?: 'BiQueryFilter';
          dimension: string;
          operator: GQBiFilterOperator;
          value: Array<FilterValue | null>;
        }> | null;
      } | null;
    }>;
    customMetrics: Array<{
      __typename?: 'BiCustomMetricRef';
      id: string | null;
      stableId: string;
    }> | null;
    queryContext: {
      __typename?: 'BiSavedViewSnapshotQueryContext';
      footprintKind: string;
    } | null;
  } | null;
};

export type GQResolvedReportQuestionInputsFailureForReportItemFragment = {
  __typename: 'ResolvedReportQuestionInputsFailure';
  errorMessage: string | null;
  questionIds: Array<string>;
};

export type GQReportQuestionForReportItemFieldsFragment = {
  __typename: 'ReportQuestion';
  allowNotes: boolean;
  allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
  id: string;
  identifier: string;
  identifierId: string;
  componentId: string;
  isComputed: boolean;
  guidanceJson: any | null;
  parentContainer: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    label: string | null;
  } | null;
  resolvedWarnings: Array<{
    __typename?: 'ReportAnswerWarning';
    id: string;
    outputJson: any;
    reportId: string;
    validatorId: string;
    acknowledgedBy: { __typename?: 'User'; id: string } | null;
    answer: {
      __typename?: 'ReportAnswer';
      id: string;
      question: {
        __typename?: 'ReportQuestion';
        id: string;
        isComputed: boolean;
        identifier: string;
        parentContainer: {
          __typename?: 'ReportQuestionContainer';
          id: string;
          label: string | null;
        } | null;
      };
    };
  }>;
  resolvedInputs:
    | {
        __typename: 'ResolvedReportQuestionInputsFailure';
        errorMessage: string | null;
        questionIds: Array<string>;
      }
    | {
        __typename: 'ResolvedReportQuestionInputsSuccess';
        inputs: any;
        unparsedInputs: any;
      };
  resolvedStatus: {
    __typename?: 'ResolvedReportQuestionStatus';
    id: string;
    status: GQReportQuestionStatus;
    blockingMessage: string | null;
  };
  answer: {
    __typename?: 'ReportAnswer';
    id: string;
    updatedAt: Date;
    outputJson: any | null;
  } | null;
  assignees: Array<{
    __typename?: 'User';
    id: string;
    displayName: string;
    name: string;
  }>;
};

export type GQContainerAssigneesFragment = {
  __typename?: 'ReportQuestionContainer';
  id: string;
  assignees: Array<{
    __typename?: 'User';
    id: string;
    displayName: string;
    name: string;
  }>;
};

type GQParentContainerAssignees_ReportQuestion_Fragment = {
  __typename: 'ReportQuestion';
};

type GQParentContainerAssignees_ReportQuestionContainer_Fragment = {
  __typename: 'ReportQuestionContainer';
  id: string;
  parentContainer: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    parentContainer: {
      __typename?: 'ReportQuestionContainer';
      id: string;
      parentContainer: {
        __typename?: 'ReportQuestionContainer';
        id: string;
        parentContainer: {
          __typename?: 'ReportQuestionContainer';
          id: string;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            displayName: string;
            name: string;
          }>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          displayName: string;
          name: string;
        }>;
      } | null;
      assignees: Array<{
        __typename?: 'User';
        id: string;
        displayName: string;
        name: string;
      }>;
    } | null;
    assignees: Array<{
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    }>;
  } | null;
  assignees: Array<{
    __typename?: 'User';
    id: string;
    displayName: string;
    name: string;
  }>;
};

export type GQParentContainerAssigneesFragment =
  | GQParentContainerAssignees_ReportQuestion_Fragment
  | GQParentContainerAssignees_ReportQuestionContainer_Fragment;

export type GQReportQuestionFieldsWithReportingIntentionFragment = {
  __typename: 'ReportQuestion';
  allowNotes: boolean;
  allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
  id: string;
  identifier: string;
  identifierId: string;
  componentId: string;
  isComputed: boolean;
  guidanceJson: any | null;
  resolvedReportingIntention?: {
    __typename?: 'ResolvedReportingIntention';
    id: string;
    intention: GQReportingIntention;
    event: {
      __typename?: 'ReportingIntentionLogEvent';
      createdAt: Date;
      reason: string | null;
      createdBy: { __typename?: 'User'; id: string; displayName: string };
    } | null;
  };
  parentContainer: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    label: string | null;
  } | null;
  resolvedWarnings: Array<{
    __typename?: 'ReportAnswerWarning';
    id: string;
    outputJson: any;
    reportId: string;
    validatorId: string;
    acknowledgedBy: { __typename?: 'User'; id: string } | null;
    answer: {
      __typename?: 'ReportAnswer';
      id: string;
      question: {
        __typename?: 'ReportQuestion';
        id: string;
        isComputed: boolean;
        identifier: string;
        parentContainer: {
          __typename?: 'ReportQuestionContainer';
          id: string;
          label: string | null;
        } | null;
      };
    };
  }>;
  resolvedInputs:
    | {
        __typename: 'ResolvedReportQuestionInputsFailure';
        errorMessage: string | null;
        questionIds: Array<string>;
      }
    | {
        __typename: 'ResolvedReportQuestionInputsSuccess';
        inputs: any;
        unparsedInputs: any;
      };
  resolvedStatus: {
    __typename?: 'ResolvedReportQuestionStatus';
    id: string;
    status: GQReportQuestionStatus;
    blockingMessage: string | null;
  };
  answer: {
    __typename?: 'ReportAnswer';
    id: string;
    updatedAt: Date;
    outputJson: any | null;
  } | null;
  assignees: Array<{
    __typename?: 'User';
    id: string;
    displayName: string;
    name: string;
  }>;
};

export type GQGetReportQuestionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
  includeReportingIntention: Scalars['Boolean']['input'];
}>;

export type GQGetReportQuestionQuery = {
  __typename?: 'Query';
  reportQuestion: {
    __typename: 'ReportQuestion';
    allowNotes: boolean;
    allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
    id: string;
    identifier: string;
    identifierId: string;
    componentId: string;
    isComputed: boolean;
    guidanceJson: any | null;
    resolvedReportingIntention?: {
      __typename?: 'ResolvedReportingIntention';
      id: string;
      intention: GQReportingIntention;
      event: {
        __typename?: 'ReportingIntentionLogEvent';
        createdAt: Date;
        reason: string | null;
        createdBy: { __typename?: 'User'; id: string; displayName: string };
      } | null;
    };
    parentContainer: {
      __typename?: 'ReportQuestionContainer';
      id: string;
      label: string | null;
    } | null;
    resolvedWarnings: Array<{
      __typename?: 'ReportAnswerWarning';
      id: string;
      outputJson: any;
      reportId: string;
      validatorId: string;
      acknowledgedBy: { __typename?: 'User'; id: string } | null;
      answer: {
        __typename?: 'ReportAnswer';
        id: string;
        question: {
          __typename?: 'ReportQuestion';
          id: string;
          isComputed: boolean;
          identifier: string;
          parentContainer: {
            __typename?: 'ReportQuestionContainer';
            id: string;
            label: string | null;
          } | null;
        };
      };
    }>;
    resolvedInputs:
      | {
          __typename: 'ResolvedReportQuestionInputsFailure';
          errorMessage: string | null;
          questionIds: Array<string>;
        }
      | {
          __typename: 'ResolvedReportQuestionInputsSuccess';
          inputs: any;
          unparsedInputs: any;
        };
    resolvedStatus: {
      __typename?: 'ResolvedReportQuestionStatus';
      id: string;
      status: GQReportQuestionStatus;
      blockingMessage: string | null;
    };
    answer: {
      __typename?: 'ReportAnswer';
      id: string;
      updatedAt: Date;
      outputJson: any | null;
    } | null;
    assignees: Array<{
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    }>;
  };
};

export type GQGetReportQuestionAssigneesQueryVariables = Exact<{
  questionId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
}>;

export type GQGetReportQuestionAssigneesQuery = {
  __typename?: 'Query';
  reportQuestion: {
    __typename?: 'ReportQuestion';
    id: string;
    assignees: Array<{
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    }>;
  };
};

export type GQUpdateReportQuestionAssigneesMutationVariables = Exact<{
  input: GQUpdateReportQuestionAssigneesInput;
  reportId: Scalars['ID']['input'];
}>;

export type GQUpdateReportQuestionAssigneesMutation = {
  __typename?: 'Mutation';
  updateReportQuestionAssignees: {
    __typename?: 'UpdateReportQuestionAssigneesPayload';
    questions: Array<{
      __typename?: 'ReportQuestion';
      id: string;
      assignees: Array<{
        __typename?: 'User';
        id: string;
        displayName: string;
        name: string;
      }>;
      parentContainer: {
        __typename: 'ReportQuestionContainer';
        id: string;
        parentContainer: {
          __typename?: 'ReportQuestionContainer';
          id: string;
          parentContainer: {
            __typename?: 'ReportQuestionContainer';
            id: string;
            parentContainer: {
              __typename?: 'ReportQuestionContainer';
              id: string;
              parentContainer: {
                __typename?: 'ReportQuestionContainer';
                id: string;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  displayName: string;
                  name: string;
                }>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                displayName: string;
                name: string;
              }>;
            } | null;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              displayName: string;
              name: string;
            }>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            displayName: string;
            name: string;
          }>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          displayName: string;
          name: string;
        }>;
      } | null;
    }>;
  } | null;
};

export type GQGetReportQuestionForMissingInputsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetReportQuestionForMissingInputsQuery = {
  __typename?: 'Query';
  reportQuestion: {
    __typename?: 'ReportQuestion';
    id: string;
    parentContainer: {
      __typename?: 'ReportQuestionContainer';
      id: string;
      label: string | null;
    } | null;
  };
};

export type GQAcknowledgeReportAnswerWarningMutationVariables = Exact<{
  input: GQAcknowledgeReportAnswerWarningInput;
}>;

export type GQAcknowledgeReportAnswerWarningMutation = {
  __typename?: 'Mutation';
  acknowledgeReportAnswerWarning: {
    __typename?: 'ReportAnswerWarning';
    id: string;
    outputJson: any;
    reportId: string;
    validatorId: string;
    acknowledgedBy: { __typename?: 'User'; id: string } | null;
    answer: {
      __typename?: 'ReportAnswer';
      id: string;
      question: {
        __typename?: 'ReportQuestion';
        id: string;
        isComputed: boolean;
        identifier: string;
        parentContainer: {
          __typename?: 'ReportQuestionContainer';
          id: string;
          label: string | null;
        } | null;
      };
    };
  };
};

export type GQReportQuestionFieldsForDebugFragment = {
  __typename: 'ReportQuestion';
  dynamicInputJson: any | null;
  staticInputJson: any | null;
  statusJson: any;
  allowNotes: boolean;
  allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
  id: string;
  identifier: string;
  identifierId: string;
  componentId: string;
  isComputed: boolean;
  guidanceJson: any | null;
  parentContainer: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    label: string | null;
  } | null;
  resolvedWarnings: Array<{
    __typename?: 'ReportAnswerWarning';
    id: string;
    outputJson: any;
    reportId: string;
    validatorId: string;
    acknowledgedBy: { __typename?: 'User'; id: string } | null;
    answer: {
      __typename?: 'ReportAnswer';
      id: string;
      question: {
        __typename?: 'ReportQuestion';
        id: string;
        isComputed: boolean;
        identifier: string;
        parentContainer: {
          __typename?: 'ReportQuestionContainer';
          id: string;
          label: string | null;
        } | null;
      };
    };
  }>;
  resolvedInputs:
    | {
        __typename: 'ResolvedReportQuestionInputsFailure';
        errorMessage: string | null;
        questionIds: Array<string>;
      }
    | {
        __typename: 'ResolvedReportQuestionInputsSuccess';
        inputs: any;
        unparsedInputs: any;
      };
  resolvedStatus: {
    __typename?: 'ResolvedReportQuestionStatus';
    id: string;
    status: GQReportQuestionStatus;
    blockingMessage: string | null;
  };
  answer: {
    __typename?: 'ReportAnswer';
    id: string;
    updatedAt: Date;
    outputJson: any | null;
  } | null;
  assignees: Array<{
    __typename?: 'User';
    id: string;
    displayName: string;
    name: string;
  }>;
};

export type GQGetReportQuestionFieldsForDebugQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
}>;

export type GQGetReportQuestionFieldsForDebugQuery = {
  __typename?: 'Query';
  reportQuestion: {
    __typename: 'ReportQuestion';
    dynamicInputJson: any | null;
    staticInputJson: any | null;
    statusJson: any;
    allowNotes: boolean;
    allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
    id: string;
    identifier: string;
    identifierId: string;
    componentId: string;
    isComputed: boolean;
    guidanceJson: any | null;
    parentContainer: {
      __typename?: 'ReportQuestionContainer';
      id: string;
      label: string | null;
    } | null;
    resolvedWarnings: Array<{
      __typename?: 'ReportAnswerWarning';
      id: string;
      outputJson: any;
      reportId: string;
      validatorId: string;
      acknowledgedBy: { __typename?: 'User'; id: string } | null;
      answer: {
        __typename?: 'ReportAnswer';
        id: string;
        question: {
          __typename?: 'ReportQuestion';
          id: string;
          isComputed: boolean;
          identifier: string;
          parentContainer: {
            __typename?: 'ReportQuestionContainer';
            id: string;
            label: string | null;
          } | null;
        };
      };
    }>;
    resolvedInputs:
      | {
          __typename: 'ResolvedReportQuestionInputsFailure';
          errorMessage: string | null;
          questionIds: Array<string>;
        }
      | {
          __typename: 'ResolvedReportQuestionInputsSuccess';
          inputs: any;
          unparsedInputs: any;
        };
    resolvedStatus: {
      __typename?: 'ResolvedReportQuestionStatus';
      id: string;
      status: GQReportQuestionStatus;
      blockingMessage: string | null;
    };
    answer: {
      __typename?: 'ReportAnswer';
      id: string;
      updatedAt: Date;
      outputJson: any | null;
    } | null;
    assignees: Array<{
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    }>;
  };
};

export type GQGetReportQuestionIdentifyingFieldsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetReportQuestionIdentifyingFieldsQuery = {
  __typename?: 'Query';
  reportQuestion: {
    __typename?: 'ReportQuestion';
    id: string;
    identifier: string;
    identifierId: string;
    componentId: string;
    isComputed: boolean;
    guidanceJson: any | null;
  };
};

export type GQReportAnswerWarningForCardFragment = {
  __typename?: 'ReportAnswerWarning';
  id: string;
  outputJson: any;
  reportId: string;
  validatorId: string;
  acknowledgedBy: { __typename?: 'User'; id: string } | null;
  answer: {
    __typename?: 'ReportAnswer';
    id: string;
    question: {
      __typename?: 'ReportQuestion';
      id: string;
      isComputed: boolean;
      identifier: string;
      parentContainer: {
        __typename?: 'ReportQuestionContainer';
        id: string;
        label: string | null;
      } | null;
    };
  };
};

export type GQGetReportQuestionContainerWarningsQueryVariables = Exact<{
  containerId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
}>;

export type GQGetReportQuestionContainerWarningsQuery = {
  __typename?: 'Query';
  reportQuestionContainer: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    questionsWithResolvedWarnings: Array<{
      __typename?: 'ReportQuestion';
      id: string;
      resolvedWarnings: Array<{
        __typename?: 'ReportAnswerWarning';
        id: string;
        outputJson: any;
        reportId: string;
        validatorId: string;
        acknowledgedBy: { __typename?: 'User'; id: string } | null;
        answer: {
          __typename?: 'ReportAnswer';
          id: string;
          question: {
            __typename?: 'ReportQuestion';
            id: string;
            isComputed: boolean;
            identifier: string;
            parentContainer: {
              __typename?: 'ReportQuestionContainer';
              id: string;
              label: string | null;
            } | null;
          };
        };
      }>;
    }>;
  };
};

export type GQGetReportQuestionContainerForCardQueryVariables = Exact<{
  containerId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
}>;

export type GQGetReportQuestionContainerForCardQuery = {
  __typename?: 'Query';
  reportQuestionContainer: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    label: string | null;
    description: string | null;
    guidanceJson: any | null;
    resolvedStatus: {
      __typename?: 'ResolvedReportQuestionStatus';
      id: string;
      status: GQReportQuestionStatus;
      blockingMessage: string | null;
    };
  };
};

export type GQGetReportQuestionContainerProgressForCardQueryVariables = Exact<{
  containerId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
}>;

export type GQGetReportQuestionContainerProgressForCardQuery = {
  __typename?: 'Query';
  reportQuestionContainer: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    automaticProgress: GQResolvedContainerProgress;
    overriddenProgress: GQResolvedContainerProgress | null;
  };
};

export type GQGetContainerAssigneesQueryVariables = Exact<{
  containerId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
}>;

export type GQGetContainerAssigneesQuery = {
  __typename?: 'Query';
  reportQuestionContainer: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    assignees: Array<{
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    }>;
  };
};

export type GQUnassignFromContainerMutationVariables = Exact<{
  input: GQUnassignFromContainerInput;
  reportId: Scalars['ID']['input'];
}>;

export type GQUnassignFromContainerMutation = {
  __typename?: 'Mutation';
  unassignFromContainer: {
    __typename?: 'UnassignFromContainerPayload';
    container: {
      __typename?: 'ReportQuestionContainer';
      id: string;
      assignees: Array<{
        __typename?: 'User';
        id: string;
        displayName: string;
        name: string;
      }>;
      items: Array<
        | {
            __typename: 'ReportQuestion';
            id: string;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              displayName: string;
              name: string;
            }>;
          }
        | {
            __typename: 'ReportQuestionContainer';
            id: string;
            items: Array<
              | {
                  __typename: 'ReportQuestion';
                  id: string;
                  assignees: Array<{
                    __typename?: 'User';
                    id: string;
                    displayName: string;
                    name: string;
                  }>;
                }
              | {
                  __typename: 'ReportQuestionContainer';
                  id: string;
                  items: Array<
                    | {
                        __typename: 'ReportQuestion';
                        id: string;
                        assignees: Array<{
                          __typename?: 'User';
                          id: string;
                          displayName: string;
                          name: string;
                        }>;
                      }
                    | {
                        __typename: 'ReportQuestionContainer';
                        id: string;
                        items: Array<
                          | {
                              __typename: 'ReportQuestion';
                              id: string;
                              assignees: Array<{
                                __typename?: 'User';
                                id: string;
                                displayName: string;
                                name: string;
                              }>;
                            }
                          | { __typename: 'ReportQuestionContainer' }
                        >;
                      }
                  >;
                }
            >;
          }
      >;
    };
  } | null;
};

export type GQOverrideContainerProgressMutationVariables = Exact<{
  input: GQOverrideContainerProgressInput;
  reportId: Scalars['ID']['input'];
}>;

export type GQOverrideContainerProgressMutation = {
  __typename?: 'Mutation';
  overrideContainerProgress: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    overriddenProgress: GQResolvedContainerProgress | null;
  };
};

export type GQGetContainerAndQuestionsForSheetContentsPanelQueryVariables =
  Exact<{
    containerId: Scalars['ID']['input'];
    reportId: Scalars['ID']['input'];
  }>;

export type GQGetContainerAndQuestionsForSheetContentsPanelQuery = {
  __typename?: 'Query';
  reportQuestionContainer: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    guidanceJson: any | null;
    fullWidth: boolean;
    items: Array<
      | {
          __typename: 'ReportQuestion';
          id: string;
          identifier: string;
          resolvedStatus: {
            __typename?: 'ResolvedReportQuestionStatus';
            id: string;
            status: GQReportQuestionStatus;
          };
        }
      | { __typename: 'ReportQuestionContainer' }
    >;
  };
};

export type GQSetReportingIntentionForQuestionMutationVariables = Exact<{
  reportId: Scalars['ID']['input'];
  questionIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  intention: GQReportingIntention;
  note: InputMaybe<Scalars['String']['input']>;
}>;

export type GQSetReportingIntentionForQuestionMutation = {
  __typename?: 'Mutation';
  updateReportingIntentions: {
    __typename?: 'UpdateReportingIntentionsPayload';
    reportQuestions: Array<{
      __typename?: 'ReportQuestion';
      id: string;
      resolvedReportingIntention: {
        __typename?: 'ResolvedReportingIntention';
        id: string;
        intention: GQReportingIntention;
        event: {
          __typename?: 'ReportingIntentionLogEvent';
          createdAt: Date;
          reason: string | null;
          createdBy: {
            __typename?: 'User';
            id: string;
            displayName: string;
            name: string;
          };
        } | null;
      };
      assignees: Array<{
        __typename?: 'User';
        id: string;
        displayName: string;
        name: string;
      }>;
    }>;
  };
};

export type GQAddMetricsToCustomReportMutationVariables = Exact<{
  input: GQAddMetricsToCustomReportInput;
  reportId: Scalars['ID']['input'];
}>;

export type GQAddMetricsToCustomReportMutation = {
  __typename?: 'Mutation';
  addMetricsToCustomReport: {
    __typename?: 'AddMetricsToCustomReportPayload';
    report: {
      __typename?: 'Report';
      id: string;
      config: {
        __typename?: 'ReportConfig';
        id: string;
        items: Array<
          | {
              __typename: 'ReportQuestion';
              allowNotes: boolean;
              allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
              id: string;
              identifier: string;
              identifierId: string;
              componentId: string;
              isComputed: boolean;
              guidanceJson: any | null;
              parentContainer: {
                __typename?: 'ReportQuestionContainer';
                id: string;
                label: string | null;
              } | null;
              resolvedWarnings: Array<{
                __typename?: 'ReportAnswerWarning';
                id: string;
                outputJson: any;
                reportId: string;
                validatorId: string;
                acknowledgedBy: { __typename?: 'User'; id: string } | null;
                answer: {
                  __typename?: 'ReportAnswer';
                  id: string;
                  question: {
                    __typename?: 'ReportQuestion';
                    id: string;
                    isComputed: boolean;
                    identifier: string;
                    parentContainer: {
                      __typename?: 'ReportQuestionContainer';
                      id: string;
                      label: string | null;
                    } | null;
                  };
                };
              }>;
              resolvedInputs:
                | {
                    __typename: 'ResolvedReportQuestionInputsFailure';
                    errorMessage: string | null;
                    questionIds: Array<string>;
                  }
                | {
                    __typename: 'ResolvedReportQuestionInputsSuccess';
                    inputs: any;
                    unparsedInputs: any;
                  };
              resolvedStatus: {
                __typename?: 'ResolvedReportQuestionStatus';
                id: string;
                status: GQReportQuestionStatus;
                blockingMessage: string | null;
              };
              answer: {
                __typename?: 'ReportAnswer';
                id: string;
                updatedAt: Date;
                outputJson: any | null;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                displayName: string;
                name: string;
              }>;
            }
          | {
              __typename: 'ReportQuestionContainer';
              id: string;
              label: string | null;
              description: string | null;
              items: Array<
                | {
                    __typename: 'ReportQuestion';
                    allowNotes: boolean;
                    allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
                    id: string;
                    identifier: string;
                    identifierId: string;
                    componentId: string;
                    isComputed: boolean;
                    guidanceJson: any | null;
                    parentContainer: {
                      __typename?: 'ReportQuestionContainer';
                      id: string;
                      label: string | null;
                    } | null;
                    resolvedWarnings: Array<{
                      __typename?: 'ReportAnswerWarning';
                      id: string;
                      outputJson: any;
                      reportId: string;
                      validatorId: string;
                      acknowledgedBy: {
                        __typename?: 'User';
                        id: string;
                      } | null;
                      answer: {
                        __typename?: 'ReportAnswer';
                        id: string;
                        question: {
                          __typename?: 'ReportQuestion';
                          id: string;
                          isComputed: boolean;
                          identifier: string;
                          parentContainer: {
                            __typename?: 'ReportQuestionContainer';
                            id: string;
                            label: string | null;
                          } | null;
                        };
                      };
                    }>;
                    resolvedInputs:
                      | {
                          __typename: 'ResolvedReportQuestionInputsFailure';
                          errorMessage: string | null;
                          questionIds: Array<string>;
                        }
                      | {
                          __typename: 'ResolvedReportQuestionInputsSuccess';
                          inputs: any;
                          unparsedInputs: any;
                        };
                    resolvedStatus: {
                      __typename?: 'ResolvedReportQuestionStatus';
                      id: string;
                      status: GQReportQuestionStatus;
                      blockingMessage: string | null;
                    };
                    answer: {
                      __typename?: 'ReportAnswer';
                      id: string;
                      updatedAt: Date;
                      outputJson: any | null;
                    } | null;
                    assignees: Array<{
                      __typename?: 'User';
                      id: string;
                      displayName: string;
                      name: string;
                    }>;
                  }
                | { __typename?: 'ReportQuestionContainer' }
              >;
            }
        >;
      };
    };
  } | null;
};

export type GQGetCdpWorkflowSyncStatusQueryVariables = Exact<{
  workflowId: Scalars['String']['input'];
}>;

export type GQGetCdpWorkflowSyncStatusQuery = {
  __typename?: 'Query';
  cdpSyncWorkflowStatus: {
    __typename?: 'CdpSyncWorkflowStatusPayload';
    id: string;
    workflowId: string;
    questions: Array<{
      __typename?: 'CdpSyncQuestionStatus';
      id: string;
      reportId: string;
      reportQuestionId: string;
      syncTime: Date;
      isSuccess: boolean | null;
      failureKind: string | null;
      failureDetails: any | null;
      reportQuestion: {
        __typename?: 'ReportQuestion';
        id: string;
        parentContainer: {
          __typename?: 'ReportQuestionContainer';
          id: string;
          label: string | null;
        } | null;
      };
    }>;
  };
};

export type GQQuestionStatusForCdpSyncDialogFragment = {
  __typename?: 'CdpSyncQuestionStatus';
  id: string;
  reportId: string;
  reportQuestionId: string;
  syncTime: Date;
  isSuccess: boolean | null;
  failureKind: string | null;
  failureDetails: any | null;
  reportQuestion: {
    __typename?: 'ReportQuestion';
    id: string;
    parentContainer: {
      __typename?: 'ReportQuestionContainer';
      id: string;
      label: string | null;
    } | null;
  };
};

export type GQStartCdpSyncAnswersMutationVariables = Exact<{
  input: GQStartCdpSyncAnswersInput;
}>;

export type GQStartCdpSyncAnswersMutation = {
  __typename?: 'Mutation';
  startCdpSyncAnswers: {
    __typename?: 'StartCdpSyncAnswersPayload';
    id: string;
    workflowId: string;
  };
};

export type GQReportQuestionNoteFieldsFragment = {
  __typename?: 'Discussion';
  id: string;
  title: string;
  description: string | null;
  state: GQDataIssueState;
  comments: {
    __typename?: 'CommentConnection';
    edges: Array<{
      __typename?: 'CommentEdge';
      node: {
        __typename?: 'Comment';
        message: string;
        id: string;
        createdAt: Date;
        isPublished: boolean;
        person:
          | {
              __typename?: 'User';
              id: string;
              displayName: string;
              name: string;
            }
          | {
              __typename?: 'WatershedEmployee';
              id: string;
              displayName: string;
              name: string;
            }
          | null;
      } | null;
    } | null>;
  };
};

export type GQGetReportQuestionNotesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
}>;

export type GQGetReportQuestionNotesQuery = {
  __typename: 'Query';
  reportQuestion: {
    __typename: 'ReportQuestion';
    id: string;
    notes: {
      __typename?: 'Discussion';
      id: string;
      title: string;
      description: string | null;
      state: GQDataIssueState;
      comments: {
        __typename?: 'CommentConnection';
        edges: Array<{
          __typename?: 'CommentEdge';
          node: {
            __typename?: 'Comment';
            message: string;
            id: string;
            createdAt: Date;
            isPublished: boolean;
            person:
              | {
                  __typename?: 'User';
                  id: string;
                  displayName: string;
                  name: string;
                }
              | {
                  __typename?: 'WatershedEmployee';
                  id: string;
                  displayName: string;
                  name: string;
                }
              | null;
          } | null;
        } | null>;
      };
    } | null;
  };
};

export type GQAddReportQuestionNoteMutationVariables = Exact<{
  note: GQAddReportQuestionNoteInput;
  reportId: Scalars['ID']['input'];
}>;

export type GQAddReportQuestionNoteMutation = {
  __typename?: 'Mutation';
  addReportQuestionNote: {
    __typename?: 'AddReportQuestionNotePayload';
    reportQuestion: {
      __typename?: 'ReportQuestion';
      id: string;
      notes: {
        __typename?: 'Discussion';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              message: string;
              id: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    displayName: string;
                    name: string;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    displayName: string;
                    name: string;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
    };
  };
};

export type GQUpdateReportQuestionNotesMutationVariables = Exact<{
  input: GQUpdateReportQuestionNoteInput;
  reportId: Scalars['ID']['input'];
}>;

export type GQUpdateReportQuestionNotesMutation = {
  __typename?: 'Mutation';
  updateReportQuestionNote: {
    __typename?: 'UpdateReportQuestionNotePayload';
    reportQuestion: {
      __typename?: 'ReportQuestion';
      id: string;
      notes: {
        __typename?: 'Discussion';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              message: string;
              id: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    displayName: string;
                    name: string;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    displayName: string;
                    name: string;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
    };
  };
};

export type GQUpdateReportQuestionNoteItemMutationVariables = Exact<{
  input: GQUpdateReportQuestionNoteItemInput;
  reportId: Scalars['ID']['input'];
}>;

export type GQUpdateReportQuestionNoteItemMutation = {
  __typename?: 'Mutation';
  updateReportQuestionNoteItem: {
    __typename?: 'UpdateReportQuestionNoteItemPayload';
    reportQuestion: {
      __typename?: 'ReportQuestion';
      id: string;
      notes: {
        __typename?: 'Discussion';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              message: string;
              id: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    displayName: string;
                    name: string;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    displayName: string;
                    name: string;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
    };
  };
};

export type GQDeleteReportQuestionNoteItemMutationVariables = Exact<{
  input: GQDeleteReportQuestionNoteItemInput;
  reportId: Scalars['ID']['input'];
}>;

export type GQDeleteReportQuestionNoteItemMutation = {
  __typename?: 'Mutation';
  deleteReportQuestionNoteItem: {
    __typename?: 'DeleteReportQuestionNoteItemPayload';
    reportQuestion: {
      __typename?: 'ReportQuestion';
      id: string;
      notes: {
        __typename?: 'Discussion';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              message: string;
              id: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    displayName: string;
                    name: string;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    displayName: string;
                    name: string;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
    };
  };
};

export type GQDeleteMetricFromCustomReportMutationVariables = Exact<{
  reportId: Scalars['ID']['input'];
  input: GQDeleteMetricFromCustomReportInput;
}>;

export type GQDeleteMetricFromCustomReportMutation = {
  __typename?: 'Mutation';
  deleteMetricFromCustomReport: {
    __typename?: 'DeleteMetricFromCustomReportPayload';
    report: {
      __typename?: 'Report';
      id: string;
      config: {
        __typename?: 'ReportConfig';
        id: string;
        items: Array<
          | { __typename: 'ReportQuestion' }
          | {
              __typename: 'ReportQuestionContainer';
              id: string;
              label: string | null;
              description: string | null;
              items: Array<
                | {
                    __typename: 'ReportQuestion';
                    allowNotes: boolean;
                    allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
                    id: string;
                    identifier: string;
                    identifierId: string;
                    componentId: string;
                    isComputed: boolean;
                    guidanceJson: any | null;
                    parentContainer: {
                      __typename?: 'ReportQuestionContainer';
                      id: string;
                      label: string | null;
                    } | null;
                    resolvedWarnings: Array<{
                      __typename?: 'ReportAnswerWarning';
                      id: string;
                      outputJson: any;
                      reportId: string;
                      validatorId: string;
                      acknowledgedBy: {
                        __typename?: 'User';
                        id: string;
                      } | null;
                      answer: {
                        __typename?: 'ReportAnswer';
                        id: string;
                        question: {
                          __typename?: 'ReportQuestion';
                          id: string;
                          isComputed: boolean;
                          identifier: string;
                          parentContainer: {
                            __typename?: 'ReportQuestionContainer';
                            id: string;
                            label: string | null;
                          } | null;
                        };
                      };
                    }>;
                    resolvedInputs:
                      | {
                          __typename: 'ResolvedReportQuestionInputsFailure';
                          errorMessage: string | null;
                          questionIds: Array<string>;
                        }
                      | {
                          __typename: 'ResolvedReportQuestionInputsSuccess';
                          inputs: any;
                          unparsedInputs: any;
                        };
                    resolvedStatus: {
                      __typename?: 'ResolvedReportQuestionStatus';
                      id: string;
                      status: GQReportQuestionStatus;
                      blockingMessage: string | null;
                    };
                    answer: {
                      __typename?: 'ReportAnswer';
                      id: string;
                      updatedAt: Date;
                      outputJson: any | null;
                    } | null;
                    assignees: Array<{
                      __typename?: 'User';
                      id: string;
                      displayName: string;
                      name: string;
                    }>;
                  }
                | { __typename?: 'ReportQuestionContainer' }
              >;
            }
        >;
      };
    };
  } | null;
};

export type GQDrilldownSavedViewsForSelectorFragment = {
  __typename?: 'DrilldownSavedView';
  id: string;
  name: string;
  isPrivate: boolean;
  biSavedViewId: string | null;
  footprintKind: string;
  filterExpressions: {
    __typename?: 'FilterExpressionGroupWithNewFilters';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitiveWithNewFilters';
      field: string;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  } | null;
};

export type GQDrilldownSavedViewsForReportQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQDrilldownSavedViewsForReportQuery = {
  __typename?: 'Query';
  drilldownSavedViews: Array<{
    __typename?: 'DrilldownSavedView';
    id: string;
    name: string;
    isPrivate: boolean;
    biSavedViewId: string | null;
    footprintKind: string;
    filterExpressions: {
      __typename?: 'FilterExpressionGroupWithNewFilters';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitiveWithNewFilters';
        field: string;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    } | null;
  }>;
};

export type GQReportingFootprintVersionFieldsFragment = {
  __typename?: 'FootprintVersion';
  id: string;
  originalFootprintSnapshotId: string;
  createdAt: Date;
  userChangelogMd: string | null;
  publishableUserChangelogMd: string | null;
  kind: GQFootprintVersionKind;
  canUserPublish: boolean;
  isUserVisible: boolean;
  versionName: string | null;
  versionIdentifier: string | null;
};

export type GQGetFootprintChangelogEntriesForReportingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetFootprintChangelogEntriesForReportingQuery = {
  __typename?: 'Query';
  footprintChangelog: {
    __typename?: 'FootprintChangelog';
    id: string;
    versions: {
      __typename?: 'FootprintVersionConnection';
      edges: Array<{
        __typename?: 'FootprintVersionEdge';
        node: {
          __typename?: 'FootprintVersion';
          id: string;
          originalFootprintSnapshotId: string;
          createdAt: Date;
          userChangelogMd: string | null;
          publishableUserChangelogMd: string | null;
          kind: GQFootprintVersionKind;
          canUserPublish: boolean;
          isUserVisible: boolean;
          versionName: string | null;
          versionIdentifier: string | null;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQGetFundsQueryVariables = Exact<{ [key: string]: never }>;

export type GQGetFundsQuery = {
  __typename?: 'Query';
  funds: Array<{
    __typename: 'Fund';
    id: string;
    name: string;
    nameSortable: string | null;
    fundGroup: string | null;
    fundCategory: string | null;
    excludeAsSandbox: boolean | null;
    anonymousFundUuid: string | null;
    createdAt: Date;
    orgId: string;
    updatedAt: Date;
    tagData: any | null;
    externalId: string | null;
  }>;
};

export type GQGetReductionPlanForIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetReductionPlanForIdQuery = {
  __typename?: 'Query';
  plan: {
    __typename?: 'Plan';
    id: string;
    name: string;
    state: GQPlanState;
    variables: {
      __typename?: 'PlanVariables';
      commitmentsHasNetZero: boolean;
      commitmentsNetZeroYear: number;
    };
  };
};

export type GQGetOrganizationReductionPlansQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetOrganizationReductionPlansQuery = {
  __typename?: 'Query';
  plans: {
    __typename?: 'PlanConnection';
    edges: Array<{
      __typename?: 'PlanEdge';
      node: {
        __typename?: 'Plan';
        id: string;
        name: string;
        state: GQPlanState;
        variables: {
          __typename?: 'PlanVariables';
          commitmentsHasNetZero: boolean;
          commitmentsNetZeroYear: number;
        };
      } | null;
    } | null>;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    sbtiTargetExclusions: Array<{
      __typename?: 'SbtiTargetExclusion';
      id: string;
      filters: {
        __typename?: 'FilterExpressionGroupWithNewFilters';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitiveWithNewFilters';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
    }>;
  };
};

export type GQReductionsPlansWithNetZeroVariablesFragment = {
  __typename?: 'Plan';
  id: string;
  name: string;
  state: GQPlanState;
  variables: {
    __typename?: 'PlanVariables';
    commitmentsHasNetZero: boolean;
    commitmentsNetZeroYear: number;
  };
};

export type GQReviewSectionReportQuestionsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQReviewSectionReportQuestionsQuery = {
  __typename?: 'Query';
  reportQuestions: Array<{
    __typename?: 'ReportQuestion';
    id: string;
    identifier: string;
    identifierId: string;
    componentId: string;
    isComputed: boolean;
    guidanceJson: any | null;
  }>;
};

export type GQStandardTableUiFragment = {
  __typename?: 'ReportItemStandardTable';
  id: string;
  isFullWidth: boolean | null;
  columns: Array<{
    __typename?: 'ReportTableColumn';
    label: string | null;
    alignment: GQTableCellAlignment | null;
    isMarkdown: boolean | null;
    minWidthPx: number | null;
  }>;
  rows: Array<{
    __typename?: 'ReportTableRow';
    values: Array<string>;
    colspans: Array<number> | null;
    explanations: Array<{
      __typename?: 'ReportExplanation';
      markdownText: string | null;
      url: string | null;
    } | null> | null;
  }>;
};

export type GQSecrStandardTableUiFragment = {
  __typename?: 'ReportItemSecrStandardTable';
  id: string;
  isFullWidth: boolean | null;
  rows: Array<{
    __typename?: 'ReportTableRow';
    values: Array<string>;
    colspans: Array<number> | null;
    explanations: Array<{
      __typename?: 'ReportExplanation';
      markdownText: string | null;
      url: string | null;
    } | null> | null;
  }>;
};

export type GQReportContainerProgressForSurveyQueryVariables = Exact<{
  reportId: Scalars['ID']['input'];
}>;

export type GQReportContainerProgressForSurveyQuery = {
  __typename?: 'Query';
  formalReport: {
    __typename?: 'Report';
    id: string;
    config: {
      __typename?: 'ReportConfig';
      id: string;
      containers: Array<{
        __typename?: 'ReportQuestionContainer';
        id: string;
        automaticProgress: GQResolvedContainerProgress;
        overriddenProgress: GQResolvedContainerProgress | null;
      }>;
    };
  } | null;
};

export type GQGetCustomReportFootprintMetadataQueryVariables = Exact<{
  snapshotId: Scalars['String']['input'];
}>;

export type GQGetCustomReportFootprintMetadataQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    footprintInterval: YMInterval | null;
  } | null;
  footprintChangelog: {
    __typename?: 'FootprintChangelog';
    id: string;
    versions: {
      __typename?: 'FootprintVersionConnection';
      edges: Array<{
        __typename?: 'FootprintVersionEdge';
        node: {
          __typename?: 'FootprintVersion';
          id: string;
          originalFootprintSnapshotId: string;
          createdAt: Date;
          userChangelogMd: string | null;
          publishableUserChangelogMd: string | null;
          kind: GQFootprintVersionKind;
          canUserPublish: boolean;
          isUserVisible: boolean;
          versionName: string | null;
          versionIdentifier: string | null;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQTcfdArchetypeOpportunityFieldsFragment = {
  __typename?: 'TcfdArchetypeOpportunity';
  id: string;
  title: string;
  userDescription: string;
  archetypeRiskId: string | null;
  geographies: Array<string>;
  sectors: Array<string>;
  distributionModel: GQTcfdStrategyModuleDistributionModel;
};

export type GQGetArchetypeOpportunitiesForTcfdQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetArchetypeOpportunitiesForTcfdQuery = {
  __typename?: 'Query';
  tcfdArchetypeOpportunities: Array<{
    __typename?: 'TcfdArchetypeOpportunity';
    id: string;
    title: string;
    userDescription: string;
    archetypeRiskId: string | null;
    geographies: Array<string>;
    sectors: Array<string>;
    distributionModel: GQTcfdStrategyModuleDistributionModel;
  }>;
};

export type GQGetExcludingReportQuestionQueryVariables = Exact<{
  questionId: Scalars['ID']['input'];
}>;

export type GQGetExcludingReportQuestionQuery = {
  __typename?: 'Query';
  reportQuestion: {
    __typename?: 'ReportQuestion';
    id: string;
    isComputed: boolean;
    parentContainer: {
      __typename?: 'ReportQuestionContainer';
      id: string;
      label: string | null;
    } | null;
  };
};

export type GQReportQuestionContainerStatusFieldsFragment = {
  __typename: 'ReportQuestionContainer';
  id: string;
  label: string | null;
  resolvedStatus: {
    __typename?: 'ResolvedReportQuestionStatus';
    id: string;
    status: GQReportQuestionStatus;
    blockingMessage: string | null;
    excludingQuestionId: string | null;
  };
};

export type GQGetContainerStatusQueryVariables = Exact<{
  containerId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
}>;

export type GQGetContainerStatusQuery = {
  __typename?: 'Query';
  reportQuestionContainer: {
    __typename: 'ReportQuestionContainer';
    id: string;
    label: string | null;
    containers: Array<{
      __typename: 'ReportQuestionContainer';
      id: string;
      label: string | null;
      containers: Array<{
        __typename: 'ReportQuestionContainer';
        id: string;
        label: string | null;
        resolvedStatus: {
          __typename?: 'ResolvedReportQuestionStatus';
          id: string;
          status: GQReportQuestionStatus;
          blockingMessage: string | null;
          excludingQuestionId: string | null;
        };
      }>;
      resolvedStatus: {
        __typename?: 'ResolvedReportQuestionStatus';
        id: string;
        status: GQReportQuestionStatus;
        blockingMessage: string | null;
        excludingQuestionId: string | null;
      };
    }>;
    resolvedStatus: {
      __typename?: 'ResolvedReportQuestionStatus';
      id: string;
      status: GQReportQuestionStatus;
      blockingMessage: string | null;
      excludingQuestionId: string | null;
    };
  };
};

export type GQGetCdpSyncStatusForReportQueryVariables = Exact<{
  reportId: Scalars['ID']['input'];
}>;

export type GQGetCdpSyncStatusForReportQuery = {
  __typename?: 'Query';
  cdpSyncQuestionStatusForReport: Array<{
    __typename?: 'CdpSyncQuestionStatus';
    id: string;
    syncTime: Date;
    isSuccess: boolean | null;
    reportQuestionId: string;
    failureKind: string | null;
    failureDetails: any | null;
    actor: {
      __typename?: 'User';
      name: string;
      displayName: string;
      id: string;
    };
  }>;
};

export type GQQuestionStatusForCdpSyncColumnFragment = {
  __typename?: 'CdpSyncQuestionStatus';
  id: string;
  syncTime: Date;
  isSuccess: boolean | null;
  reportQuestionId: string;
  failureKind: string | null;
  failureDetails: any | null;
  actor: { __typename?: 'User'; name: string; displayName: string; id: string };
};

export type GQReportQuestionFieldsFragment = {
  __typename?: 'ReportQuestion';
  id: string;
  identifier: string;
  identifierId: string;
  componentId: string;
  isComputed: boolean;
  guidanceJson: any | null;
};

export type GQReportQuestionContainerBaseFieldsFragment = {
  __typename?: 'ReportQuestionContainer';
  id: string;
  label: string | null;
  description: string | null;
  statusJson: any;
  guidanceJson: any | null;
};

export type GQReportConfigFieldsFragment = {
  __typename?: 'ReportConfig';
  id: string;
  shortName: string;
  longName: string;
  description: string;
  brandColor: string;
  status: GQReportConfigStatus | null;
  reportType: string;
  updatedAt: Date;
  useTableDisplay: boolean | null;
  useReportingIntention: boolean | null;
  inputs: Array<{
    __typename?: 'ReportQuestion';
    id: string;
    identifier: string;
    identifierId: string;
    componentId: string;
    isComputed: boolean;
    guidanceJson: any | null;
    resolvedInputs:
      | {
          __typename: 'ResolvedReportQuestionInputsFailure';
          questionIds: Array<string>;
          errorMessage: string | null;
        }
      | {
          __typename: 'ResolvedReportQuestionInputsSuccess';
          inputs: any;
          unparsedInputs: any;
        };
    answer: {
      __typename?: 'ReportAnswer';
      id: string;
      outputJson: any | null;
    } | null;
  }>;
  items: Array<
    | {
        __typename: 'ReportQuestion';
        allowNotes: boolean;
        allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
        id: string;
        identifier: string;
        identifierId: string;
        componentId: string;
        isComputed: boolean;
        guidanceJson: any | null;
        parentContainer: {
          __typename?: 'ReportQuestionContainer';
          id: string;
          label: string | null;
        } | null;
        resolvedWarnings: Array<{
          __typename?: 'ReportAnswerWarning';
          id: string;
          outputJson: any;
          reportId: string;
          validatorId: string;
          acknowledgedBy: { __typename?: 'User'; id: string } | null;
          answer: {
            __typename?: 'ReportAnswer';
            id: string;
            question: {
              __typename?: 'ReportQuestion';
              id: string;
              isComputed: boolean;
              identifier: string;
              parentContainer: {
                __typename?: 'ReportQuestionContainer';
                id: string;
                label: string | null;
              } | null;
            };
          };
        }>;
        resolvedInputs:
          | {
              __typename: 'ResolvedReportQuestionInputsFailure';
              errorMessage: string | null;
              questionIds: Array<string>;
            }
          | {
              __typename: 'ResolvedReportQuestionInputsSuccess';
              inputs: any;
              unparsedInputs: any;
            };
        resolvedStatus: {
          __typename?: 'ResolvedReportQuestionStatus';
          id: string;
          status: GQReportQuestionStatus;
          blockingMessage: string | null;
        };
        answer: {
          __typename?: 'ReportAnswer';
          id: string;
          updatedAt: Date;
          outputJson: any | null;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          displayName: string;
          name: string;
        }>;
      }
    | {
        __typename: 'ReportQuestionContainer';
        id: string;
        label: string | null;
        description: string | null;
        items: Array<
          | {
              __typename: 'ReportQuestion';
              id: string;
              allowNotes: boolean;
              allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
              identifier: string;
              identifierId: string;
              componentId: string;
              isComputed: boolean;
              guidanceJson: any | null;
              parentContainer: {
                __typename?: 'ReportQuestionContainer';
                id: string;
                label: string | null;
              } | null;
              resolvedWarnings: Array<{
                __typename?: 'ReportAnswerWarning';
                id: string;
                outputJson: any;
                reportId: string;
                validatorId: string;
                acknowledgedBy: { __typename?: 'User'; id: string } | null;
                answer: {
                  __typename?: 'ReportAnswer';
                  id: string;
                  question: {
                    __typename?: 'ReportQuestion';
                    id: string;
                    isComputed: boolean;
                    identifier: string;
                    parentContainer: {
                      __typename?: 'ReportQuestionContainer';
                      id: string;
                      label: string | null;
                    } | null;
                  };
                };
              }>;
              resolvedInputs:
                | {
                    __typename: 'ResolvedReportQuestionInputsFailure';
                    errorMessage: string | null;
                    questionIds: Array<string>;
                  }
                | {
                    __typename: 'ResolvedReportQuestionInputsSuccess';
                    inputs: any;
                    unparsedInputs: any;
                  };
              resolvedStatus: {
                __typename?: 'ResolvedReportQuestionStatus';
                id: string;
                status: GQReportQuestionStatus;
                blockingMessage: string | null;
              };
              answer: {
                __typename?: 'ReportAnswer';
                id: string;
                updatedAt: Date;
                outputJson: any | null;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                displayName: string;
                name: string;
              }>;
            }
          | { __typename: 'ReportQuestionContainer'; id: string }
        >;
      }
  >;
};

export type GQReportAnswerWarningFieldsFragment = {
  __typename?: 'ReportAnswerWarning';
  validatorId: string;
  outputJson: any;
  acknowledgedBy: {
    __typename?: 'User';
    id: string;
    displayName: string;
  } | null;
  answer: {
    __typename?: 'ReportAnswer';
    question: {
      __typename?: 'ReportQuestion';
      id: string;
      identifier: string;
      identifierId: string;
      componentId: string;
      isComputed: boolean;
      guidanceJson: any | null;
    };
  };
};

export type GQReportAnswerFieldsFragment = {
  __typename?: 'ReportAnswer';
  id: string;
  inputJson: any;
  outputJson: any | null;
  question: {
    __typename?: 'ReportQuestion';
    id: string;
    identifier: string;
    identifierId: string;
    componentId: string;
    isComputed: boolean;
    guidanceJson: any | null;
  };
};

export type GQReportWithConfigFieldsFragment = {
  __typename?: 'Report';
  id: string;
  name: string;
  dmaId: string | null;
  config: {
    __typename?: 'ReportConfig';
    id: string;
    shortName: string;
    longName: string;
    description: string;
    brandColor: string;
    status: GQReportConfigStatus | null;
    reportType: string;
    updatedAt: Date;
    useTableDisplay: boolean | null;
    useReportingIntention: boolean | null;
    inputs: Array<{
      __typename?: 'ReportQuestion';
      id: string;
      identifier: string;
      identifierId: string;
      componentId: string;
      isComputed: boolean;
      guidanceJson: any | null;
      resolvedInputs:
        | {
            __typename: 'ResolvedReportQuestionInputsFailure';
            questionIds: Array<string>;
            errorMessage: string | null;
          }
        | {
            __typename: 'ResolvedReportQuestionInputsSuccess';
            inputs: any;
            unparsedInputs: any;
          };
      answer: {
        __typename?: 'ReportAnswer';
        id: string;
        outputJson: any | null;
      } | null;
    }>;
    items: Array<
      | {
          __typename: 'ReportQuestion';
          allowNotes: boolean;
          allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
          id: string;
          identifier: string;
          identifierId: string;
          componentId: string;
          isComputed: boolean;
          guidanceJson: any | null;
          parentContainer: {
            __typename?: 'ReportQuestionContainer';
            id: string;
            label: string | null;
          } | null;
          resolvedWarnings: Array<{
            __typename?: 'ReportAnswerWarning';
            id: string;
            outputJson: any;
            reportId: string;
            validatorId: string;
            acknowledgedBy: { __typename?: 'User'; id: string } | null;
            answer: {
              __typename?: 'ReportAnswer';
              id: string;
              question: {
                __typename?: 'ReportQuestion';
                id: string;
                isComputed: boolean;
                identifier: string;
                parentContainer: {
                  __typename?: 'ReportQuestionContainer';
                  id: string;
                  label: string | null;
                } | null;
              };
            };
          }>;
          resolvedInputs:
            | {
                __typename: 'ResolvedReportQuestionInputsFailure';
                errorMessage: string | null;
                questionIds: Array<string>;
              }
            | {
                __typename: 'ResolvedReportQuestionInputsSuccess';
                inputs: any;
                unparsedInputs: any;
              };
          resolvedStatus: {
            __typename?: 'ResolvedReportQuestionStatus';
            id: string;
            status: GQReportQuestionStatus;
            blockingMessage: string | null;
          };
          answer: {
            __typename?: 'ReportAnswer';
            id: string;
            updatedAt: Date;
            outputJson: any | null;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            displayName: string;
            name: string;
          }>;
        }
      | {
          __typename: 'ReportQuestionContainer';
          id: string;
          label: string | null;
          description: string | null;
          items: Array<
            | {
                __typename: 'ReportQuestion';
                id: string;
                allowNotes: boolean;
                allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
                identifier: string;
                identifierId: string;
                componentId: string;
                isComputed: boolean;
                guidanceJson: any | null;
                parentContainer: {
                  __typename?: 'ReportQuestionContainer';
                  id: string;
                  label: string | null;
                } | null;
                resolvedWarnings: Array<{
                  __typename?: 'ReportAnswerWarning';
                  id: string;
                  outputJson: any;
                  reportId: string;
                  validatorId: string;
                  acknowledgedBy: { __typename?: 'User'; id: string } | null;
                  answer: {
                    __typename?: 'ReportAnswer';
                    id: string;
                    question: {
                      __typename?: 'ReportQuestion';
                      id: string;
                      isComputed: boolean;
                      identifier: string;
                      parentContainer: {
                        __typename?: 'ReportQuestionContainer';
                        id: string;
                        label: string | null;
                      } | null;
                    };
                  };
                }>;
                resolvedInputs:
                  | {
                      __typename: 'ResolvedReportQuestionInputsFailure';
                      errorMessage: string | null;
                      questionIds: Array<string>;
                    }
                  | {
                      __typename: 'ResolvedReportQuestionInputsSuccess';
                      inputs: any;
                      unparsedInputs: any;
                    };
                resolvedStatus: {
                  __typename?: 'ResolvedReportQuestionStatus';
                  id: string;
                  status: GQReportQuestionStatus;
                  blockingMessage: string | null;
                };
                answer: {
                  __typename?: 'ReportAnswer';
                  id: string;
                  updatedAt: Date;
                  outputJson: any | null;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  displayName: string;
                  name: string;
                }>;
              }
            | { __typename: 'ReportQuestionContainer'; id: string }
          >;
        }
    >;
  };
};

export type GQGetReportWithConfigQueryVariables = Exact<{
  reportId: Scalars['ID']['input'];
}>;

export type GQGetReportWithConfigQuery = {
  __typename?: 'Query';
  formalReport: {
    __typename?: 'Report';
    id: string;
    name: string;
    dmaId: string | null;
    config: {
      __typename?: 'ReportConfig';
      id: string;
      shortName: string;
      longName: string;
      description: string;
      brandColor: string;
      status: GQReportConfigStatus | null;
      reportType: string;
      updatedAt: Date;
      useTableDisplay: boolean | null;
      useReportingIntention: boolean | null;
      inputs: Array<{
        __typename?: 'ReportQuestion';
        id: string;
        identifier: string;
        identifierId: string;
        componentId: string;
        isComputed: boolean;
        guidanceJson: any | null;
        resolvedInputs:
          | {
              __typename: 'ResolvedReportQuestionInputsFailure';
              questionIds: Array<string>;
              errorMessage: string | null;
            }
          | {
              __typename: 'ResolvedReportQuestionInputsSuccess';
              inputs: any;
              unparsedInputs: any;
            };
        answer: {
          __typename?: 'ReportAnswer';
          id: string;
          outputJson: any | null;
        } | null;
      }>;
      items: Array<
        | {
            __typename: 'ReportQuestion';
            allowNotes: boolean;
            allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
            id: string;
            identifier: string;
            identifierId: string;
            componentId: string;
            isComputed: boolean;
            guidanceJson: any | null;
            parentContainer: {
              __typename?: 'ReportQuestionContainer';
              id: string;
              label: string | null;
            } | null;
            resolvedWarnings: Array<{
              __typename?: 'ReportAnswerWarning';
              id: string;
              outputJson: any;
              reportId: string;
              validatorId: string;
              acknowledgedBy: { __typename?: 'User'; id: string } | null;
              answer: {
                __typename?: 'ReportAnswer';
                id: string;
                question: {
                  __typename?: 'ReportQuestion';
                  id: string;
                  isComputed: boolean;
                  identifier: string;
                  parentContainer: {
                    __typename?: 'ReportQuestionContainer';
                    id: string;
                    label: string | null;
                  } | null;
                };
              };
            }>;
            resolvedInputs:
              | {
                  __typename: 'ResolvedReportQuestionInputsFailure';
                  errorMessage: string | null;
                  questionIds: Array<string>;
                }
              | {
                  __typename: 'ResolvedReportQuestionInputsSuccess';
                  inputs: any;
                  unparsedInputs: any;
                };
            resolvedStatus: {
              __typename?: 'ResolvedReportQuestionStatus';
              id: string;
              status: GQReportQuestionStatus;
              blockingMessage: string | null;
            };
            answer: {
              __typename?: 'ReportAnswer';
              id: string;
              updatedAt: Date;
              outputJson: any | null;
            } | null;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              displayName: string;
              name: string;
            }>;
          }
        | {
            __typename: 'ReportQuestionContainer';
            id: string;
            label: string | null;
            description: string | null;
            items: Array<
              | {
                  __typename: 'ReportQuestion';
                  id: string;
                  allowNotes: boolean;
                  allowedAttachmentKinds: Array<GQReportAttachmentItemKind> | null;
                  identifier: string;
                  identifierId: string;
                  componentId: string;
                  isComputed: boolean;
                  guidanceJson: any | null;
                  parentContainer: {
                    __typename?: 'ReportQuestionContainer';
                    id: string;
                    label: string | null;
                  } | null;
                  resolvedWarnings: Array<{
                    __typename?: 'ReportAnswerWarning';
                    id: string;
                    outputJson: any;
                    reportId: string;
                    validatorId: string;
                    acknowledgedBy: { __typename?: 'User'; id: string } | null;
                    answer: {
                      __typename?: 'ReportAnswer';
                      id: string;
                      question: {
                        __typename?: 'ReportQuestion';
                        id: string;
                        isComputed: boolean;
                        identifier: string;
                        parentContainer: {
                          __typename?: 'ReportQuestionContainer';
                          id: string;
                          label: string | null;
                        } | null;
                      };
                    };
                  }>;
                  resolvedInputs:
                    | {
                        __typename: 'ResolvedReportQuestionInputsFailure';
                        errorMessage: string | null;
                        questionIds: Array<string>;
                      }
                    | {
                        __typename: 'ResolvedReportQuestionInputsSuccess';
                        inputs: any;
                        unparsedInputs: any;
                      };
                  resolvedStatus: {
                    __typename?: 'ResolvedReportQuestionStatus';
                    id: string;
                    status: GQReportQuestionStatus;
                    blockingMessage: string | null;
                  };
                  answer: {
                    __typename?: 'ReportAnswer';
                    id: string;
                    updatedAt: Date;
                    outputJson: any | null;
                  } | null;
                  assignees: Array<{
                    __typename?: 'User';
                    id: string;
                    displayName: string;
                    name: string;
                  }>;
                }
              | { __typename: 'ReportQuestionContainer'; id: string }
            >;
          }
      >;
    };
  } | null;
};

export type GQCompanyReportingFactsFieldsFragment = {
  __typename?: 'CompanyReportingFacts';
  id: string;
  companyId: string;
  asOfMonth: YearMonth;
  overriddenAt: Date | null;
  numEmployeesGlobal: number | null;
  numEmployeesEu: number | null;
  numEmployeesUk: number | null;
  revenueGlobalUsd: number | null;
  revenueEuUsd: number | null;
  revenueUkUsd: number | null;
  assetsGlobalUsd: number | null;
  assetsEuUsd: number | null;
  assetsUkUsd: number | null;
  federalContractsUsUsd: number | null;
  federalContractsUkUsd: number | null;
  largestContractUkUsd: number | null;
  primaryJurisdiction: string | null;
  legalEntityCountries: Array<string> | null;
  listedExchanges: Array<string> | null;
  secFilerStatus: string | null;
  lseListingStatus: string | null;
  nfrdFilerStatus: string | null;
  isDoingBusinessCa: boolean | null;
  isBank: boolean | null;
  isInsurance: boolean | null;
  isSmallCreditInsurance: boolean | null;
  isTradedBankingLlpUk: boolean | null;
  isLlpUk: boolean | null;
  isNfisFilerUk: boolean | null;
  isDirectorsFilerUk: boolean | null;
  isPublicEu: boolean | null;
};

export type GQCompanyReportingFactsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type GQCompanyReportingFactsQuery = {
  __typename?: 'Query';
  companyReportingFacts: Array<{
    __typename?: 'CompanyReportingFacts';
    id: string;
    companyId: string;
    asOfMonth: YearMonth;
    overriddenAt: Date | null;
    numEmployeesGlobal: number | null;
    numEmployeesEu: number | null;
    numEmployeesUk: number | null;
    revenueGlobalUsd: number | null;
    revenueEuUsd: number | null;
    revenueUkUsd: number | null;
    assetsGlobalUsd: number | null;
    assetsEuUsd: number | null;
    assetsUkUsd: number | null;
    federalContractsUsUsd: number | null;
    federalContractsUkUsd: number | null;
    largestContractUkUsd: number | null;
    primaryJurisdiction: string | null;
    legalEntityCountries: Array<string> | null;
    listedExchanges: Array<string> | null;
    secFilerStatus: string | null;
    lseListingStatus: string | null;
    nfrdFilerStatus: string | null;
    isDoingBusinessCa: boolean | null;
    isBank: boolean | null;
    isInsurance: boolean | null;
    isSmallCreditInsurance: boolean | null;
    isTradedBankingLlpUk: boolean | null;
    isLlpUk: boolean | null;
    isNfisFilerUk: boolean | null;
    isDirectorsFilerUk: boolean | null;
    isPublicEu: boolean | null;
  }>;
};

export type GQCurrencyExchangeRateFromUsdAsOfMonthQueryVariables = Exact<{
  targetCurrency: Scalars['String']['input'];
  month: Scalars['YearMonth']['input'];
}>;

export type GQCurrencyExchangeRateFromUsdAsOfMonthQuery = {
  __typename?: 'Query';
  currencyExchangeRateFromUsdAsOfMonth: {
    __typename?: 'CurrencyExchangeRate';
    id: string;
    currency: string;
    value: number;
  };
};

export type GQUpsertCompanyReportingFactsMutationVariables = Exact<{
  input: GQUpsertCompanyReportingFactsInput;
}>;

export type GQUpsertCompanyReportingFactsMutation = {
  __typename?: 'Mutation';
  upsertCompanyReportingFacts: {
    __typename?: 'UpsertCompanyReportingFactsPayload';
    companyReportingFacts: {
      __typename?: 'CompanyReportingFacts';
      id: string;
      companyId: string;
      asOfMonth: YearMonth;
      overriddenAt: Date | null;
      numEmployeesGlobal: number | null;
      numEmployeesEu: number | null;
      numEmployeesUk: number | null;
      revenueGlobalUsd: number | null;
      revenueEuUsd: number | null;
      revenueUkUsd: number | null;
      assetsGlobalUsd: number | null;
      assetsEuUsd: number | null;
      assetsUkUsd: number | null;
      federalContractsUsUsd: number | null;
      federalContractsUkUsd: number | null;
      largestContractUkUsd: number | null;
      primaryJurisdiction: string | null;
      legalEntityCountries: Array<string> | null;
      listedExchanges: Array<string> | null;
      secFilerStatus: string | null;
      lseListingStatus: string | null;
      nfrdFilerStatus: string | null;
      isDoingBusinessCa: boolean | null;
      isBank: boolean | null;
      isInsurance: boolean | null;
      isSmallCreditInsurance: boolean | null;
      isTradedBankingLlpUk: boolean | null;
      isLlpUk: boolean | null;
      isNfisFilerUk: boolean | null;
      isDirectorsFilerUk: boolean | null;
      isPublicEu: boolean | null;
    };
  };
};

export type GQRecommendedReportKindsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type GQRecommendedReportKindsQuery = {
  __typename?: 'Query';
  recommendedReportKinds: {
    __typename?: 'RecommendedReportKinds';
    id: string;
    state: GQRecommendedReportKindsState;
    required: Array<{
      __typename?: 'ReportKindWithReason';
      id: string;
      kind: string;
      reason: string | null;
    }>;
    recommended: Array<{
      __typename?: 'ReportKindWithReason';
      id: string;
      kind: string;
      reason: string | null;
    }>;
  } | null;
};

export type GQPeerExternalReportQuestionFieldsFragment = {
  __typename?: 'ExternalReportQuestion';
  id: string;
  question: string;
  type: {
    __typename?: 'ExternalReportTypeWithRevision';
    id: string;
    type: GQExternalReportType;
    revision: string;
  };
  reportQuestionIdentifier: {
    __typename?: 'ReportQuestionIdentifier';
    id: string;
    identifier: string;
  };
};

export type GQPeerExternalReportAnswerFieldsFragment = {
  __typename?: 'ExternalReportAnswer';
  id: string;
  externalReportQuestionId: string;
  answerType: string;
  answer: any;
  watershedQualityScore: GQDisclosureQualityScore | null;
  questionPhrasing: string | null;
  index: number;
  publicDisclosure: {
    __typename?: 'PublicDisclosure';
    id: string;
    reportType: GQExternalReportType;
    publishingYear: number;
    externalScore: string | null;
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      fullLogoUrl: string | null;
    } | null;
  };
};

export type GQPeerExternalReportAnswersForIdentifiersQueryVariables = Exact<{
  reportQuestionIdentifiers:
    | Array<Scalars['String']['input']>
    | Scalars['String']['input'];
}>;

export type GQPeerExternalReportAnswersForIdentifiersQuery = {
  __typename?: 'Query';
  peerExternalReportAnswersForIdentifiers: {
    __typename?: 'PeerExternalReportAnswersForIdentifiersPayload';
    peerCompanies: Array<{ __typename?: 'Company'; id: string; name: string }>;
    answers: Array<{
      __typename?: 'ExternalReportAnswer';
      id: string;
      externalReportQuestionId: string;
      answerType: string;
      answer: any;
      watershedQualityScore: GQDisclosureQualityScore | null;
      questionPhrasing: string | null;
      index: number;
      publicDisclosure: {
        __typename?: 'PublicDisclosure';
        id: string;
        reportType: GQExternalReportType;
        publishingYear: number;
        externalScore: string | null;
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          fullLogoUrl: string | null;
        } | null;
      };
    }>;
    questions: Array<{
      __typename?: 'ExternalReportQuestion';
      id: string;
      question: string;
      type: {
        __typename?: 'ExternalReportTypeWithRevision';
        id: string;
        type: GQExternalReportType;
        revision: string;
      };
      reportQuestionIdentifier: {
        __typename?: 'ReportQuestionIdentifier';
        id: string;
        identifier: string;
      };
    }>;
  };
};

export type GQComputeSeriesMetricQueryVariables = Exact<{
  input: GQComputeReportQuestionMetricInput;
}>;

export type GQComputeSeriesMetricQuery = {
  __typename?: 'Query';
  computeReportQuestionSeriesMetric: {
    __typename?: 'ReportQuestionSeriesMetric';
    series: Array<{
      __typename?: 'ReportQuestionSeriesMetricItem';
      label: string;
      value: string;
    }> | null;
  };
};

export type GQGetOrganizationReductionPlanIdsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetOrganizationReductionPlanIdsQuery = {
  __typename?: 'Query';
  plans: {
    __typename?: 'PlanConnection';
    edges: Array<{
      __typename?: 'PlanEdge';
      node: { __typename?: 'Plan'; id: string } | null;
    } | null>;
  };
};

export type GQComputeSingleValueMetricQueryVariables = Exact<{
  input: GQComputeReportQuestionMetricInput;
}>;

export type GQComputeSingleValueMetricQuery = {
  __typename?: 'Query';
  computeReportQuestionSingleValueMetric: {
    __typename?: 'ReportQuestionSingleValueMetric';
    value: string | null;
  };
};

export type GQDownloadDocxReportQueryVariables = Exact<{
  reportId: Scalars['ID']['input'];
}>;

export type GQDownloadDocxReportQuery = {
  __typename?: 'Query';
  formalReport: {
    __typename?: 'Report';
    id: string;
    downloadableDocxUrl: string;
  } | null;
};

export type GQDownloadXlsxReportQueryVariables = Exact<{
  reportId: Scalars['ID']['input'];
}>;

export type GQDownloadXlsxReportQuery = {
  __typename?: 'Query';
  formalReport: {
    __typename?: 'Report';
    id: string;
    reportKind: string;
    downloadableXlsxUrl: string;
  } | null;
};

export type GQInProgressReportsQueryVariables = Exact<{ [key: string]: never }>;

export type GQInProgressReportsQuery = {
  __typename?: 'Query';
  formalReports: {
    __typename?: 'ReportConnection';
    edges: Array<{
      __typename?: 'ReportEdge';
      node: {
        __typename: 'Report';
        id: string;
        name: string;
        reportStatus: GQReportStatus;
        reportKind: string;
        createdAt: Date;
        updatedAt: Date;
        deletedAt: Date | null;
        warnings: Array<{
          __typename?: 'ReportAnswerWarning';
          id: string;
          outputJson: any;
          reportId: string;
          validatorId: string;
          acknowledgedBy: { __typename?: 'User'; id: string } | null;
        }>;
        config: {
          __typename?: 'ReportConfig';
          id: string;
          reportType: string;
          shortName: string;
          longName: string;
          requiredPermissions: Array<GQPermissionType>;
          createdAt: Date;
        };
      } | null;
    } | null>;
  };
};

export type GQReportWaitlistTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQReportWaitlistTypesQuery = {
  __typename?: 'Query';
  reportWaitlistTypes: Array<string>;
};

export type GQJoinReportWaitlistMutationVariables = Exact<{
  kind: Scalars['String']['input'];
}>;

export type GQJoinReportWaitlistMutation = {
  __typename?: 'Mutation';
  joinReportWaitlist: {
    __typename?: 'JoinReportWaitlistPayload';
    id: string;
    kind: string;
  };
};

export type GQLeaveReportWaitlistMutationVariables = Exact<{
  kind: Scalars['String']['input'];
}>;

export type GQLeaveReportWaitlistMutation = {
  __typename?: 'Mutation';
  leaveReportWaitlist: {
    __typename?: 'LeaveReportWaitlistPayload';
    kind: string;
  };
};

export type GQGetCdpApiRegistrationQueryVariables = Exact<{
  reportId: Scalars['ID']['input'];
}>;

export type GQGetCdpApiRegistrationQuery = {
  __typename?: 'Query';
  cdpApiRegistrationForReport: {
    __typename?: 'CdpApiRegistrationPayload';
    id: string;
    isReportRegistered: boolean;
    cdpOrganizationId: string | null;
    registeredReportId: string | null;
  };
  cdpLatestSyncWorkflowStatus: {
    __typename?: 'CdpLatestSyncWorkflowStatusPayload';
    workflowStatus: {
      __typename?: 'CdpSyncWorkflowStatusPayload';
      id: string;
      workflowId: string;
      questions: Array<{
        __typename?: 'CdpSyncQuestionStatus';
        id: string;
        syncTime: Date;
        isSuccess: boolean | null;
      }>;
    } | null;
  };
};

export type GQCreateCdpApiRegistrationMutationVariables = Exact<{
  input: GQCreateCdpApiRegistrationInput;
}>;

export type GQCreateCdpApiRegistrationMutation = {
  __typename?: 'Mutation';
  createCdpApiRegistration: {
    __typename?: 'CreateCdpApiRegistrationPayload';
    id: string;
  };
};

export type GQDeleteCdpApiRegistrationMutationVariables = Exact<{
  input: GQDeleteCdpApiRegistrationInput;
}>;

export type GQDeleteCdpApiRegistrationMutation = {
  __typename?: 'Mutation';
  deleteCdpApiRegistration: {
    __typename?: 'DeleteCdpApiRegistrationPayload';
    id: string;
  };
};

export type GQReportQuestionDependentFieldsFragment = {
  __typename?: 'ReportQuestion';
  id: string;
  resolvedWarnings: Array<{
    __typename?: 'ReportAnswerWarning';
    id: string;
    outputJson: any;
    reportId: string;
    validatorId: string;
    acknowledgedBy: { __typename?: 'User'; id: string } | null;
    answer: {
      __typename?: 'ReportAnswer';
      id: string;
      question: {
        __typename?: 'ReportQuestion';
        id: string;
        isComputed: boolean;
        identifier: string;
        parentContainer: {
          __typename?: 'ReportQuestionContainer';
          id: string;
          label: string | null;
        } | null;
      };
    };
  }>;
  answer: {
    __typename?: 'ReportAnswer';
    id: string;
    outputJson: any | null;
  } | null;
  dependentItems: {
    __typename?: 'DependentReportItems';
    inputs: Array<{
      __typename?: 'ReportQuestion';
      id: string;
      updatedAt: Date;
      resolvedInputs:
        | {
            __typename: 'ResolvedReportQuestionInputsFailure';
            questionIds: Array<string>;
            errorMessage: string | null;
          }
        | {
            __typename: 'ResolvedReportQuestionInputsSuccess';
            inputs: any;
            unparsedInputs: any;
          };
      resolvedWarnings: Array<{
        __typename?: 'ReportAnswerWarning';
        id: string;
        outputJson: any;
        reportId: string;
        validatorId: string;
        acknowledgedBy: { __typename?: 'User'; id: string } | null;
        answer: {
          __typename?: 'ReportAnswer';
          id: string;
          question: {
            __typename?: 'ReportQuestion';
            id: string;
            isComputed: boolean;
            identifier: string;
            parentContainer: {
              __typename?: 'ReportQuestionContainer';
              id: string;
              label: string | null;
            } | null;
          };
        };
      }>;
    }>;
    questionStatuses: Array<{
      __typename?: 'ReportQuestion';
      id: string;
      updatedAt: Date;
      resolvedStatus: {
        __typename?: 'ResolvedReportQuestionStatus';
        id: string;
        status: GQReportQuestionStatus;
        blockingMessage: string | null;
        excludingQuestionId: string | null;
      };
    }>;
    containerStatuses: Array<{
      __typename?: 'ReportQuestionContainer';
      id: string;
      resolvedStatus: {
        __typename?: 'ResolvedReportQuestionStatus';
        id: string;
        status: GQReportQuestionStatus;
        blockingMessage: string | null;
        excludingQuestionId: string | null;
      };
    }>;
    containerProgresses: Array<{
      __typename?: 'ReportQuestionContainer';
      id: string;
      automaticProgress: GQResolvedContainerProgress;
    }>;
  };
};

export type GQUpsertReportAnswerMutationVariables = Exact<{
  input: GQUpsertReportAnswerInput;
  reportId: Scalars['ID']['input'];
}>;

export type GQUpsertReportAnswerMutation = {
  __typename?: 'Mutation';
  upsertReportAnswerWithMetadata: {
    __typename?: 'UpsertReportAnswerPayload';
    doesConflict: boolean;
    updatedAnswer: {
      __typename?: 'ReportAnswer';
      id: string;
      editorName: string;
      question: {
        __typename?: 'ReportQuestion';
        id: string;
        resolvedWarnings: Array<{
          __typename?: 'ReportAnswerWarning';
          id: string;
          outputJson: any;
          reportId: string;
          validatorId: string;
          acknowledgedBy: { __typename?: 'User'; id: string } | null;
          answer: {
            __typename?: 'ReportAnswer';
            id: string;
            question: {
              __typename?: 'ReportQuestion';
              id: string;
              isComputed: boolean;
              identifier: string;
              parentContainer: {
                __typename?: 'ReportQuestionContainer';
                id: string;
                label: string | null;
              } | null;
            };
          };
        }>;
        answer: {
          __typename?: 'ReportAnswer';
          id: string;
          outputJson: any | null;
        } | null;
        dependentItems: {
          __typename?: 'DependentReportItems';
          inputs: Array<{
            __typename?: 'ReportQuestion';
            id: string;
            updatedAt: Date;
            resolvedInputs:
              | {
                  __typename: 'ResolvedReportQuestionInputsFailure';
                  questionIds: Array<string>;
                  errorMessage: string | null;
                }
              | {
                  __typename: 'ResolvedReportQuestionInputsSuccess';
                  inputs: any;
                  unparsedInputs: any;
                };
            resolvedWarnings: Array<{
              __typename?: 'ReportAnswerWarning';
              id: string;
              outputJson: any;
              reportId: string;
              validatorId: string;
              acknowledgedBy: { __typename?: 'User'; id: string } | null;
              answer: {
                __typename?: 'ReportAnswer';
                id: string;
                question: {
                  __typename?: 'ReportQuestion';
                  id: string;
                  isComputed: boolean;
                  identifier: string;
                  parentContainer: {
                    __typename?: 'ReportQuestionContainer';
                    id: string;
                    label: string | null;
                  } | null;
                };
              };
            }>;
          }>;
          questionStatuses: Array<{
            __typename?: 'ReportQuestion';
            id: string;
            updatedAt: Date;
            resolvedStatus: {
              __typename?: 'ResolvedReportQuestionStatus';
              id: string;
              status: GQReportQuestionStatus;
              blockingMessage: string | null;
              excludingQuestionId: string | null;
            };
          }>;
          containerStatuses: Array<{
            __typename?: 'ReportQuestionContainer';
            id: string;
            resolvedStatus: {
              __typename?: 'ResolvedReportQuestionStatus';
              id: string;
              status: GQReportQuestionStatus;
              blockingMessage: string | null;
              excludingQuestionId: string | null;
            };
          }>;
          containerProgresses: Array<{
            __typename?: 'ReportQuestionContainer';
            id: string;
            automaticProgress: GQResolvedContainerProgress;
          }>;
        };
      };
    };
  };
};

export type GQDeleteReportAnswerMutationVariables = Exact<{
  input: GQDeleteReportAnswerInput;
  reportId: Scalars['ID']['input'];
}>;

export type GQDeleteReportAnswerMutation = {
  __typename?: 'Mutation';
  deleteReportAnswer: {
    __typename?: 'DeleteReportAnswerPayload';
    numDeleted: number;
    question: {
      __typename?: 'ReportQuestion';
      id: string;
      resolvedWarnings: Array<{
        __typename?: 'ReportAnswerWarning';
        id: string;
        outputJson: any;
        reportId: string;
        validatorId: string;
        acknowledgedBy: { __typename?: 'User'; id: string } | null;
        answer: {
          __typename?: 'ReportAnswer';
          id: string;
          question: {
            __typename?: 'ReportQuestion';
            id: string;
            isComputed: boolean;
            identifier: string;
            parentContainer: {
              __typename?: 'ReportQuestionContainer';
              id: string;
              label: string | null;
            } | null;
          };
        };
      }>;
      answer: {
        __typename?: 'ReportAnswer';
        id: string;
        outputJson: any | null;
      } | null;
      dependentItems: {
        __typename?: 'DependentReportItems';
        inputs: Array<{
          __typename?: 'ReportQuestion';
          id: string;
          updatedAt: Date;
          resolvedInputs:
            | {
                __typename: 'ResolvedReportQuestionInputsFailure';
                questionIds: Array<string>;
                errorMessage: string | null;
              }
            | {
                __typename: 'ResolvedReportQuestionInputsSuccess';
                inputs: any;
                unparsedInputs: any;
              };
          resolvedWarnings: Array<{
            __typename?: 'ReportAnswerWarning';
            id: string;
            outputJson: any;
            reportId: string;
            validatorId: string;
            acknowledgedBy: { __typename?: 'User'; id: string } | null;
            answer: {
              __typename?: 'ReportAnswer';
              id: string;
              question: {
                __typename?: 'ReportQuestion';
                id: string;
                isComputed: boolean;
                identifier: string;
                parentContainer: {
                  __typename?: 'ReportQuestionContainer';
                  id: string;
                  label: string | null;
                } | null;
              };
            };
          }>;
        }>;
        questionStatuses: Array<{
          __typename?: 'ReportQuestion';
          id: string;
          updatedAt: Date;
          resolvedStatus: {
            __typename?: 'ResolvedReportQuestionStatus';
            id: string;
            status: GQReportQuestionStatus;
            blockingMessage: string | null;
            excludingQuestionId: string | null;
          };
        }>;
        containerStatuses: Array<{
          __typename?: 'ReportQuestionContainer';
          id: string;
          resolvedStatus: {
            __typename?: 'ResolvedReportQuestionStatus';
            id: string;
            status: GQReportQuestionStatus;
            blockingMessage: string | null;
            excludingQuestionId: string | null;
          };
        }>;
        containerProgresses: Array<{
          __typename?: 'ReportQuestionContainer';
          id: string;
          automaticProgress: GQResolvedContainerProgress;
        }>;
      };
    };
  };
};

export type GQGetReportMetadataComputedComponentQueryVariables = Exact<{
  reportId: Scalars['ID']['input'];
}>;

export type GQGetReportMetadataComputedComponentQuery = {
  __typename?: 'Query';
  formalReport: {
    __typename?: 'Report';
    id: string;
    config: {
      __typename?: 'ReportConfig';
      id: string;
      computedItems: Array<{
        __typename?: 'ReportQuestion';
        id: string;
        componentId: string;
        resolvedStatus: {
          __typename?: 'ResolvedReportQuestionStatus';
          id: string;
          status: GQReportQuestionStatus;
          blockingMessage: string | null;
        };
        answer: {
          __typename?: 'ReportAnswer';
          id: string;
          outputJson: any | null;
        } | null;
      }>;
    };
  } | null;
};

export type GQGetWorkivaContributionsQueryVariables = Exact<{
  footprintKind: Scalars['String']['input'];
  footprintSnapshotId: Scalars['String']['input'];
  reportingInterval: Scalars['YMInterval']['input'];
}>;

export type GQGetWorkivaContributionsQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    workivaContributions: {
      __typename?: 'WorkivaContributionsPayload';
      id: string;
      interval: YMInterval;
      data: any;
    };
  } | null;
};

export type GQUpdateUserNameMutationVariables = Exact<{
  input: GQUpdateUserNameInput;
}>;

export type GQUpdateUserNameMutation = {
  __typename?: 'Mutation';
  updateUserName: {
    __typename?: 'UpdateUserNamePayload';
    user: { __typename?: 'User'; id: string; name: string };
  } | null;
};

export type GQResetLockedUserMutationVariables = Exact<{
  input: GQResetLockedUserInput;
}>;

export type GQResetLockedUserMutation = {
  __typename?: 'Mutation';
  resetLockedUser: {
    __typename?: 'ResetLockedUserPayload';
    user: { __typename?: 'User'; id: string; consecutiveLoginFailures: number };
  } | null;
};

export type GQRevokeUserAppSessionsMutationVariables = Exact<{
  input: GQRevokeUserAppSessionsInput;
}>;

export type GQRevokeUserAppSessionsMutation = {
  __typename?: 'Mutation';
  revokeUserAppSessions: {
    __typename?: 'RevokeUserAppSessionsPayload';
    user: { __typename?: 'User'; id: string; hasValidAppSessions: boolean };
  };
};

export type GQRegenerateUserSignupTokenMutationVariables = Exact<{
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type GQRegenerateUserSignupTokenMutation = {
  __typename?: 'Mutation';
  regenerateUserSignupToken: {
    __typename?: 'RegenerateUserSignupTokenPayload';
    user: { __typename?: 'User'; id: string; signupLink: string | null };
  } | null;
};

export type GQOrganizationMemberFieldsFragment = {
  __typename?: 'User';
  id: string;
  orgAccessCreatedAt: Date;
  name: string;
  email: string;
  loginActivated: boolean;
  signupLink: string | null;
  consecutiveLoginFailures: number;
  didLatestEmailBounce: boolean;
  hasValidAppSessions: boolean;
  displayName: string;
  orgAccessId: string;
  userRoles: Array<{
    __typename?: 'UserRoleAssignment';
    id: string;
    revokedAt: Date | null;
    role: {
      __typename?: 'Role';
      id: string;
      name: string;
      description: string | null;
      permissions: Array<{
        __typename?: 'PermissionItem';
        id: string;
        objectId: string | null;
        permission: GQPermissionType;
        revokedAt: Date | null;
        object:
          | { __typename: 'Company'; id: string; name: string }
          | { __typename: 'CompanySurvey'; id: string; name: string }
          | { __typename: 'Dataset'; id: string; name: string }
          | { __typename: 'Datasource'; id: string; name: string }
          | { __typename: 'EngagementTask'; id: string; name: string }
          | { __typename: 'Fund'; id: string; name: string }
          | null;
      }>;
    };
  }>;
  userPermissions: Array<{
    __typename?: 'PermissionItem';
    id: string;
    objectId: string | null;
    permission: GQPermissionType;
    revokedAt: Date | null;
    object:
      | { __typename: 'Company'; id: string; name: string }
      | { __typename: 'CompanySurvey'; id: string; name: string }
      | { __typename: 'Dataset'; id: string; name: string }
      | { __typename: 'Datasource'; id: string; name: string }
      | { __typename: 'EngagementTask'; id: string; name: string }
      | { __typename: 'Fund'; id: string; name: string }
      | null;
  }>;
  userUploadTasks: Array<{
    __typename?: 'UserUploadTask';
    id: string;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
    };
    datasource: {
      __typename?: 'Datasource';
      id: string;
      name: string;
      dataset: { __typename?: 'Dataset'; id: string; name: string };
    };
  }>;
  roles: Array<{
    __typename?: 'UserRoleAssignment';
    id: string;
    revokedAt: Date | null;
    role: {
      __typename?: 'Role';
      id: string;
      name: string;
      description: string | null;
      permissions: Array<{
        __typename?: 'PermissionItem';
        id: string;
        objectId: string | null;
        permission: GQPermissionType;
        revokedAt: Date | null;
        object:
          | { __typename: 'Company'; id: string; name: string }
          | { __typename: 'CompanySurvey'; id: string; name: string }
          | { __typename: 'Dataset'; id: string; name: string }
          | { __typename: 'Datasource'; id: string; name: string }
          | { __typename: 'EngagementTask'; id: string; name: string }
          | { __typename: 'Fund'; id: string; name: string }
          | null;
      }>;
    };
  }>;
  permissions: Array<{
    __typename?: 'PermissionItem';
    id: string;
    objectId: string | null;
    permission: GQPermissionType;
    revokedAt: Date | null;
    object:
      | { __typename: 'Company'; id: string; name: string }
      | { __typename: 'CompanySurvey'; id: string; name: string }
      | { __typename: 'Dataset'; id: string; name: string }
      | { __typename: 'Datasource'; id: string; name: string }
      | { __typename: 'EngagementTask'; id: string; name: string }
      | { __typename: 'Fund'; id: string; name: string }
      | null;
  }>;
};

export type GQGetOrganizationMembersQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQGetOrganizationMembersQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    workosOrgId: string | null;
    domains: Array<string>;
    passwordAuthDisabled: boolean;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          orgAccessCreatedAt: Date;
          name: string;
          email: string;
          loginActivated: boolean;
          signupLink: string | null;
          consecutiveLoginFailures: number;
          didLatestEmailBounce: boolean;
          hasValidAppSessions: boolean;
          displayName: string;
          orgAccessId: string;
          userRoles: Array<{
            __typename?: 'UserRoleAssignment';
            id: string;
            revokedAt: Date | null;
            role: {
              __typename?: 'Role';
              id: string;
              name: string;
              description: string | null;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
            };
          }>;
          userPermissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
          userUploadTasks: Array<{
            __typename?: 'UserUploadTask';
            id: string;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
              active: boolean;
            };
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: { __typename?: 'Dataset'; id: string; name: string };
            };
          }>;
          roles: Array<{
            __typename?: 'UserRoleAssignment';
            id: string;
            revokedAt: Date | null;
            role: {
              __typename?: 'Role';
              id: string;
              name: string;
              description: string | null;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                objectId: string | null;
                permission: GQPermissionType;
                revokedAt: Date | null;
                object:
                  | { __typename: 'Company'; id: string; name: string }
                  | { __typename: 'CompanySurvey'; id: string; name: string }
                  | { __typename: 'Dataset'; id: string; name: string }
                  | { __typename: 'Datasource'; id: string; name: string }
                  | { __typename: 'EngagementTask'; id: string; name: string }
                  | { __typename: 'Fund'; id: string; name: string }
                  | null;
              }>;
            };
          }>;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            objectId: string | null;
            permission: GQPermissionType;
            revokedAt: Date | null;
            object:
              | { __typename: 'Company'; id: string; name: string }
              | { __typename: 'CompanySurvey'; id: string; name: string }
              | { __typename: 'Dataset'; id: string; name: string }
              | { __typename: 'Datasource'; id: string; name: string }
              | { __typename: 'EngagementTask'; id: string; name: string }
              | { __typename: 'Fund'; id: string; name: string }
              | null;
          }>;
        } | null;
      } | null>;
    } | null;
  };
  roles: {
    __typename?: 'RoleConnection';
    edges: Array<{
      __typename?: 'RoleEdge';
      node: {
        __typename?: 'Role';
        id: string;
        name: string;
        description: string | null;
        permissions: Array<{
          __typename?: 'PermissionItem';
          id: string;
          objectId: string | null;
          permission: GQPermissionType;
          revokedAt: Date | null;
          object:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanySurvey'; id: string; name: string }
            | { __typename: 'Dataset'; id: string; name: string }
            | { __typename: 'Datasource'; id: string; name: string }
            | { __typename: 'EngagementTask'; id: string; name: string }
            | { __typename: 'Fund'; id: string; name: string }
            | null;
        }>;
      } | null;
    } | null>;
  };
};

export type GQRemoveUserMutationVariables = Exact<{
  input: GQRemoveUserInput;
}>;

export type GQRemoveUserMutation = {
  __typename?: 'Mutation';
  removeUser: {
    __typename?: 'RemoveUserPayload';
    user: { __typename?: 'User'; id: string };
  } | null;
};

export type GQReportEmissionsOverTimeQueryVariables = Exact<{
  reportId: Scalars['ID']['input'];
  footprintKind: Scalars['String']['input'];
}>;

export type GQReportEmissionsOverTimeQuery = {
  __typename?: 'Query';
  forceRedirect: string | null;
  report: {
    __typename?: 'EmployeeReport';
    id: string;
    footprintInterval: YMInterval;
    timeseries: {
      __typename?: 'FootprintAnalysisTimeSeries';
      id: string;
      kind: GQAggregateKind;
      series: Array<{
        __typename?: 'FootprintAnalysisTimeseriesSeries';
        label: string | null;
        value: string | null;
        data: Array<{
          __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
          date: Date;
          value: number;
          headcount: number | null;
          revenue: number | null;
          customIntensities: Array<{
            __typename?: 'FootprintAnalysisCustomIntensityDataPoint';
            customIntensityId: string;
            value: number | null;
          }> | null;
        }>;
      }>;
    } | null;
    intensityTimeseries: {
      __typename?: 'FootprintAnalysisTimeSeries';
      id: string;
      kind: GQAggregateKind;
      series: Array<{
        __typename?: 'FootprintAnalysisTimeseriesSeries';
        label: string | null;
        value: string | null;
        data: Array<{
          __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
          date: Date;
          value: number;
          headcount: number | null;
          revenue: number | null;
          customIntensities: Array<{
            __typename?: 'FootprintAnalysisCustomIntensityDataPoint';
            customIntensityId: string;
            value: number | null;
          }> | null;
        }>;
      }>;
    } | null;
  } | null;
};

export type GQGetPendingCompanyChangeRequestsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetPendingCompanyChangeRequestsQuery = {
  __typename?: 'Query';
  pendingCompanyChangeRequests: Array<{
    __typename?: 'CompanyChangeRequest';
    id: string;
    action: GQCompanyChangeRequestAction;
    payload: any;
  }>;
};

export type GQCompanyFieldsForEngagementTasksFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
  fullLogoUrl: string | null;
  naicsCode: string | null;
};

export type GQGetCustomerHubDataQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type GQGetCustomerHubDataQuery = {
  __typename?: 'Query';
  companyForDashboard: {
    __typename?: 'Company';
    id: string;
    name: string;
    fullLogoUrl: string | null;
    naicsCode: string | null;
  } | null;
  engagementTasks: {
    __typename?: 'EngagementTaskConnection';
    edges: Array<{
      __typename?: 'EngagementTaskEdge';
      node: {
        __typename?: 'EngagementTask';
        id: string;
        crossOrgModelId: string;
        reportingYear: number | null;
        facilityName: string | null;
        submittedAt: Date | null;
        createdAt: Date;
        dueDate: Date | null;
        status: GQCompanySurveyStatus;
        surveyKind: GQCompanySurveyKind;
        supplierFacingDescription: string | null;
        watershedGeneric: boolean | null;
        currentAssignee: GQCompanySurveyAssignee;
        rootAssignee: string | null;
        supplierAssignee: string | null;
        initialSupplierAssigneeEmail: string | null;
        approvedAt: Date | null;
        statusChangedAt: Date | null;
        rootOrgId: string;
        supplierOrgId: string;
        latestCeeWorkflowId: string | null;
        latestPleeWorkflowId: string | null;
        latestFootprintDisclosureWorkflowId: string | null;
        engagementTaskBatchId: string | null;
        proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
        submittedAnswers: Array<{
          __typename?: 'EngagementTaskAnswer';
          id: string;
          questionKey: string;
          answer: any | null;
          skipped: boolean;
          questionIndex: number | null;
          attachments: Array<{
            __typename?: 'EngagementTaskAnswerAttachment';
            originalFilename: string;
            signedDownloadUrl: string | null;
            questionKey: string;
            fileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              createdAt: Date;
            };
          }>;
        }>;
        comments: Array<{
          __typename?: 'EngagementTaskComment';
          id: string;
          createdAt: Date;
          userEditorId: string | null;
          userEditorName: string | null;
          creatorOrgId: string | null;
          comment: string | null;
        }>;
        edits: Array<{
          __typename?: 'CompanySurveyEdit';
          id: string;
          createdAt: Date;
          userEditorId: string | null;
          userEditorName: string | null;
          creatorOrgId: string | null;
          columnName: GQEditColumn;
          newValue: string | null;
          valueType: GQEditValueType | null;
        }>;
        footprintEstimate: {
          __typename?: 'FootprintEstimateOutput';
          id: string;
          output: {
            __typename?: 'FootprintEstimateOutputByScope';
            scope1: number | null;
            scope2Market: number | null;
            scope2Location: number | null;
            scope3: number | null;
            scope301: number | null;
            scope302: number | null;
            scope303: number | null;
            scope304: number | null;
            scope305: number | null;
            scope306: number | null;
            scope307: number | null;
            scope308: number | null;
            scope309: number | null;
            scope310: number | null;
            scope311: number | null;
            scope312: number | null;
            scope313: number | null;
            scope314: number | null;
            scope315: number | null;
            scope316: number | null;
            scope317: number | null;
          } | null;
        } | null;
        engagementTaskConfig: {
          __typename?: 'EngagementTaskConfig';
          id: string;
          name: string;
          sidebarTitle: string;
          defaultSupplierFacingDescription: string | null;
          rootFacingDescription: string | null;
          orgId: string | null;
          type: GQEngagementTaskConfigType | null;
          configHasTaskAssignedToCompany: boolean;
          timeNeeded: number | null;
          category: GQSurveyDefinitionCategory;
          engagementTaskInputs: Array<GQEngagementTaskInput>;
          optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            containers: Array<{
              __typename?: 'EngagementTaskContainer';
              id: string;
              label: string;
              description: string | null;
              componentId: GQEngagementTaskContainerComponentId | null;
              editable: boolean;
              statusJson: any;
              sidebarStepLabelOverride: string | null;
              eyebrowText: string | null;
              headerImage: GQEngagementTaskImageId | null;
              questions: Array<{
                __typename?: 'EngagementTaskQuestion';
                id: string;
                label: string | null;
                description: string | null;
                componentId: GQEngagementTaskQuestionComponentId;
                editable: boolean;
                deletable: boolean;
                questionKey: string;
                statusJson: any;
                whyCollectThis: string | null;
                answerIsPrivate: boolean;
                videoLink: string | null;
              }>;
            }>;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
          baseConfig: {
            __typename?: 'EngagementTaskConfig';
            id: string;
            containers: Array<{
              __typename?: 'EngagementTaskContainer';
              id: string;
              label: string;
              description: string | null;
              componentId: GQEngagementTaskContainerComponentId | null;
              editable: boolean;
              statusJson: any;
              sidebarStepLabelOverride: string | null;
              eyebrowText: string | null;
              headerImage: GQEngagementTaskImageId | null;
              containers: Array<{
                __typename?: 'EngagementTaskContainer';
                id: string;
                label: string;
                description: string | null;
                componentId: GQEngagementTaskContainerComponentId | null;
                editable: boolean;
                statusJson: any;
                sidebarStepLabelOverride: string | null;
                eyebrowText: string | null;
                headerImage: GQEngagementTaskImageId | null;
                questions: Array<{
                  __typename?: 'EngagementTaskQuestion';
                  id: string;
                  label: string | null;
                  description: string | null;
                  componentId: GQEngagementTaskQuestionComponentId;
                  editable: boolean;
                  deletable: boolean;
                  questionKey: string;
                  statusJson: any;
                  whyCollectThis: string | null;
                  answerIsPrivate: boolean;
                  videoLink: string | null;
                }>;
              }>;
              questions: Array<{
                __typename?: 'EngagementTaskQuestion';
                id: string;
                label: string | null;
                description: string | null;
                componentId: GQEngagementTaskQuestionComponentId;
                editable: boolean;
                deletable: boolean;
                questionKey: string;
                statusJson: any;
                whyCollectThis: string | null;
                answerIsPrivate: boolean;
                videoLink: string | null;
              }>;
            }>;
          } | null;
        };
        rootCompany: {
          __typename?: 'Company';
          id: string;
          name: string;
          fullLogoUrl: string | null;
          url: string | null;
        };
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          naicsCode: string | null;
          sbtiStage: GQCompanySbtCommitmentStage;
          publicDisclosures: Array<{
            __typename?: 'Disclosure';
            id: string;
            publicDisclosure: {
              __typename?: 'PublicDisclosure';
              id: string;
              publishingYear: number;
            } | null;
            historicalEmissionsYears: Array<{
              __typename?: 'EmissionsYear';
              id: string;
              publicDisclosureId: string | null;
              reportingYear: number;
              numEmployees: number | null;
              revenue: number | null;
              revenueCurrency: string | null;
              revenueUsd: number | null;
              percentageCleanEnergy: number | null;
              totalMwh: number | null;
              scope1: number | null;
              scope2Market: number | null;
              scope2Location: number | null;
              scope3: number | null;
              scope301: number | null;
              scope302: number | null;
              scope303: number | null;
              scope304: number | null;
              scope305: number | null;
              scope306: number | null;
              scope307: number | null;
              scope308: number | null;
              scope309: number | null;
              scope310: number | null;
              scope311: number | null;
              scope312: number | null;
              scope313: number | null;
              scope314: number | null;
              scope315: number | null;
              scope316: number | null;
              scope317: number | null;
            }> | null;
          }>;
        };
        expenseCategories: Array<{
          __typename?: 'DiscloseEmissionsExpenseCategories';
          beaCode: string;
          inputUnit: string;
          inputQuantity: number;
          kgco2e: number;
        }> | null;
        proposeTargetFilters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        } | null;
        proposeTargetEmissionsTarget: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        } | null;
      } | null;
    }>;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      fullLogoUrl: string | null;
      naicsCode: string | null;
    };
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        } | null;
      } | null>;
    } | null;
  };
  companyPortalSettings: {
    __typename?: 'CompanyPortalSettings';
    id: string | null;
    portalCallout: string | null;
    sidebarContent: string | null;
    portalTitle: string;
    toggleHeaderImage: boolean | null;
  };
};

export type GQLogCustomerHubViewedMutationVariables = Exact<{
  input: GQLogCustomerHubViewedInput;
}>;

export type GQLogCustomerHubViewedMutation = {
  __typename?: 'Mutation';
  logCustomerHubViewed: {
    __typename?: 'LogCustomerHubViewedPayload';
    customerCompanyId: string;
  } | null;
};

export type GQGetPreviewAsCompanyQueryVariables = Exact<{
  previewAsCompanyId: Scalars['ID']['input'];
}>;

export type GQGetPreviewAsCompanyQuery = {
  __typename?: 'Query';
  companyForDashboard: {
    __typename?: 'Company';
    id: string;
    name: string;
    fullLogoUrl: string | null;
    naicsCode: string | null;
  } | null;
};

export type GQGetEngagementTasksPortalDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetEngagementTasksPortalDataQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  engagementTasks: {
    __typename?: 'EngagementTaskConnection';
    edges: Array<{
      __typename?: 'EngagementTaskEdge';
      node: {
        __typename?: 'EngagementTask';
        id: string;
        crossOrgModelId: string;
        reportingYear: number | null;
        facilityName: string | null;
        submittedAt: Date | null;
        createdAt: Date;
        dueDate: Date | null;
        status: GQCompanySurveyStatus;
        surveyKind: GQCompanySurveyKind;
        supplierFacingDescription: string | null;
        watershedGeneric: boolean | null;
        currentAssignee: GQCompanySurveyAssignee;
        rootAssignee: string | null;
        supplierAssignee: string | null;
        initialSupplierAssigneeEmail: string | null;
        approvedAt: Date | null;
        statusChangedAt: Date | null;
        rootOrgId: string;
        supplierOrgId: string;
        latestCeeWorkflowId: string | null;
        latestPleeWorkflowId: string | null;
        latestFootprintDisclosureWorkflowId: string | null;
        engagementTaskBatchId: string | null;
        proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
        engagementTaskConfig: {
          __typename?: 'EngagementTaskConfig';
          id: string;
          name: string;
          sidebarTitle: string;
          defaultSupplierFacingDescription: string | null;
          rootFacingDescription: string | null;
          orgId: string | null;
          type: GQEngagementTaskConfigType | null;
          configHasTaskAssignedToCompany: boolean;
          timeNeeded: number | null;
          category: GQSurveyDefinitionCategory;
          engagementTaskInputs: Array<GQEngagementTaskInput>;
          optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            containers: Array<{
              __typename?: 'EngagementTaskContainer';
              id: string;
              label: string;
              description: string | null;
              componentId: GQEngagementTaskContainerComponentId | null;
              editable: boolean;
              statusJson: any;
              sidebarStepLabelOverride: string | null;
              eyebrowText: string | null;
              headerImage: GQEngagementTaskImageId | null;
              questions: Array<{
                __typename?: 'EngagementTaskQuestion';
                id: string;
                label: string | null;
                description: string | null;
                componentId: GQEngagementTaskQuestionComponentId;
                editable: boolean;
                deletable: boolean;
                questionKey: string;
                statusJson: any;
                whyCollectThis: string | null;
                answerIsPrivate: boolean;
                videoLink: string | null;
              }>;
            }>;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
          baseConfig: {
            __typename?: 'EngagementTaskConfig';
            id: string;
            containers: Array<{
              __typename?: 'EngagementTaskContainer';
              id: string;
              label: string;
              description: string | null;
              componentId: GQEngagementTaskContainerComponentId | null;
              editable: boolean;
              statusJson: any;
              sidebarStepLabelOverride: string | null;
              eyebrowText: string | null;
              headerImage: GQEngagementTaskImageId | null;
              containers: Array<{
                __typename?: 'EngagementTaskContainer';
                id: string;
                label: string;
                description: string | null;
                componentId: GQEngagementTaskContainerComponentId | null;
                editable: boolean;
                statusJson: any;
                sidebarStepLabelOverride: string | null;
                eyebrowText: string | null;
                headerImage: GQEngagementTaskImageId | null;
                questions: Array<{
                  __typename?: 'EngagementTaskQuestion';
                  id: string;
                  label: string | null;
                  description: string | null;
                  componentId: GQEngagementTaskQuestionComponentId;
                  editable: boolean;
                  deletable: boolean;
                  questionKey: string;
                  statusJson: any;
                  whyCollectThis: string | null;
                  answerIsPrivate: boolean;
                  videoLink: string | null;
                }>;
              }>;
              questions: Array<{
                __typename?: 'EngagementTaskQuestion';
                id: string;
                label: string | null;
                description: string | null;
                componentId: GQEngagementTaskQuestionComponentId;
                editable: boolean;
                deletable: boolean;
                questionKey: string;
                statusJson: any;
                whyCollectThis: string | null;
                answerIsPrivate: boolean;
                videoLink: string | null;
              }>;
            }>;
          } | null;
        };
        rootCompany: {
          __typename?: 'Company';
          id: string;
          name: string;
          fullLogoUrl: string | null;
          url: string | null;
        };
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          naicsCode: string | null;
          sbtiStage: GQCompanySbtCommitmentStage;
          publicDisclosures: Array<{
            __typename?: 'Disclosure';
            id: string;
            publicDisclosure: {
              __typename?: 'PublicDisclosure';
              id: string;
              publishingYear: number;
            } | null;
            historicalEmissionsYears: Array<{
              __typename?: 'EmissionsYear';
              id: string;
              publicDisclosureId: string | null;
              reportingYear: number;
              numEmployees: number | null;
              revenue: number | null;
              revenueCurrency: string | null;
              revenueUsd: number | null;
              percentageCleanEnergy: number | null;
              totalMwh: number | null;
              scope1: number | null;
              scope2Market: number | null;
              scope2Location: number | null;
              scope3: number | null;
              scope301: number | null;
              scope302: number | null;
              scope303: number | null;
              scope304: number | null;
              scope305: number | null;
              scope306: number | null;
              scope307: number | null;
              scope308: number | null;
              scope309: number | null;
              scope310: number | null;
              scope311: number | null;
              scope312: number | null;
              scope313: number | null;
              scope314: number | null;
              scope315: number | null;
              scope316: number | null;
              scope317: number | null;
            }> | null;
          }>;
        };
        expenseCategories: Array<{
          __typename?: 'DiscloseEmissionsExpenseCategories';
          beaCode: string;
          inputUnit: string;
          inputQuantity: number;
          kgco2e: number;
        }> | null;
        proposeTargetFilters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        } | null;
        proposeTargetEmissionsTarget: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        } | null;
      } | null;
    }>;
  };
};

export type GQOrgUsersForSurveyQueryVariables = Exact<{ [key: string]: never }>;

export type GQOrgUsersForSurveyQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQLogSupplyChainSurveyEventMutationVariables = Exact<{
  input: GQLogSupplyChainSurveyEventInput;
}>;

export type GQLogSupplyChainSurveyEventMutation = {
  __typename?: 'Mutation';
  logSupplyChainSurveyEvent: {
    __typename?: 'LogSupplyChainSurveyEventPayload';
    success: boolean;
  } | null;
};

export type GQGetEngagementTaskByCrossOrgModelIdQueryVariables = Exact<{
  crossOrgModelId: Scalars['String']['input'];
}>;

export type GQGetEngagementTaskByCrossOrgModelIdQuery = {
  __typename?: 'Query';
  engagementTaskByCrossOrgModelId: {
    __typename?: 'EngagementTask';
    id: string;
    crossOrgModelId: string;
    reportingYear: number | null;
    facilityName: string | null;
    submittedAt: Date | null;
    createdAt: Date;
    dueDate: Date | null;
    status: GQCompanySurveyStatus;
    surveyKind: GQCompanySurveyKind;
    supplierFacingDescription: string | null;
    watershedGeneric: boolean | null;
    currentAssignee: GQCompanySurveyAssignee;
    rootAssignee: string | null;
    supplierAssignee: string | null;
    initialSupplierAssigneeEmail: string | null;
    approvedAt: Date | null;
    statusChangedAt: Date | null;
    rootOrgId: string;
    supplierOrgId: string;
    latestCeeWorkflowId: string | null;
    latestPleeWorkflowId: string | null;
    latestFootprintDisclosureWorkflowId: string | null;
    engagementTaskBatchId: string | null;
    proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
    submittedAnswers: Array<{
      __typename?: 'EngagementTaskAnswer';
      id: string;
      questionKey: string;
      answer: any | null;
      skipped: boolean;
      questionIndex: number | null;
      attachments: Array<{
        __typename?: 'EngagementTaskAnswerAttachment';
        originalFilename: string;
        signedDownloadUrl: string | null;
        questionKey: string;
        fileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          createdAt: Date;
        };
      }>;
    }>;
    comments: Array<{
      __typename?: 'EngagementTaskComment';
      id: string;
      createdAt: Date;
      userEditorId: string | null;
      userEditorName: string | null;
      creatorOrgId: string | null;
      comment: string | null;
    }>;
    edits: Array<{
      __typename?: 'CompanySurveyEdit';
      id: string;
      createdAt: Date;
      userEditorId: string | null;
      userEditorName: string | null;
      creatorOrgId: string | null;
      columnName: GQEditColumn;
      newValue: string | null;
      valueType: GQEditValueType | null;
    }>;
    footprintEstimate: {
      __typename?: 'FootprintEstimateOutput';
      id: string;
      output: {
        __typename?: 'FootprintEstimateOutputByScope';
        scope1: number | null;
        scope2Market: number | null;
        scope2Location: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
      } | null;
    } | null;
    engagementTaskConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      name: string;
      sidebarTitle: string;
      defaultSupplierFacingDescription: string | null;
      rootFacingDescription: string | null;
      orgId: string | null;
      type: GQEngagementTaskConfigType | null;
      configHasTaskAssignedToCompany: boolean;
      timeNeeded: number | null;
      category: GQSurveyDefinitionCategory;
      engagementTaskInputs: Array<GQEngagementTaskInput>;
      optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          answerIsPrivate: boolean;
          videoLink: string | null;
        }>;
      }>;
      baseConfig: {
        __typename?: 'EngagementTaskConfig';
        id: string;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
      } | null;
    };
    rootCompany: {
      __typename?: 'Company';
      id: string;
      name: string;
      fullLogoUrl: string | null;
      url: string | null;
    };
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      naicsCode: string | null;
      sbtiStage: GQCompanySbtCommitmentStage;
      publicDisclosures: Array<{
        __typename?: 'Disclosure';
        id: string;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          publishingYear: number;
        } | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          publicDisclosureId: string | null;
          reportingYear: number;
          numEmployees: number | null;
          revenue: number | null;
          revenueCurrency: string | null;
          revenueUsd: number | null;
          percentageCleanEnergy: number | null;
          totalMwh: number | null;
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
        }> | null;
      }>;
    };
    expenseCategories: Array<{
      __typename?: 'DiscloseEmissionsExpenseCategories';
      beaCode: string;
      inputUnit: string;
      inputQuantity: number;
      kgco2e: number;
    }> | null;
    proposeTargetFilters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    } | null;
    proposeTargetEmissionsTarget: {
      __typename?: 'SimpleTimeseries';
      id: string | null;
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    } | null;
  } | null;
};

export type GQSubmitEngagementTaskMutationVariables = Exact<{
  input: GQSubmitEngagementTaskInput;
}>;

export type GQSubmitEngagementTaskMutation = {
  __typename?: 'Mutation';
  submitEngagementTask: {
    __typename?: 'SubmitEngagementTaskPayload';
    engagementTask: {
      __typename?: 'EngagementTask';
      id: string;
      crossOrgModelId: string;
      reportingYear: number | null;
      facilityName: string | null;
      submittedAt: Date | null;
      createdAt: Date;
      dueDate: Date | null;
      status: GQCompanySurveyStatus;
      surveyKind: GQCompanySurveyKind;
      supplierFacingDescription: string | null;
      watershedGeneric: boolean | null;
      currentAssignee: GQCompanySurveyAssignee;
      rootAssignee: string | null;
      supplierAssignee: string | null;
      initialSupplierAssigneeEmail: string | null;
      approvedAt: Date | null;
      statusChangedAt: Date | null;
      rootOrgId: string;
      supplierOrgId: string;
      latestCeeWorkflowId: string | null;
      latestPleeWorkflowId: string | null;
      latestFootprintDisclosureWorkflowId: string | null;
      engagementTaskBatchId: string | null;
      proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
      submittedAnswers: Array<{
        __typename?: 'EngagementTaskAnswer';
        id: string;
        questionKey: string;
        answer: any | null;
        skipped: boolean;
        questionIndex: number | null;
        attachments: Array<{
          __typename?: 'EngagementTaskAnswerAttachment';
          originalFilename: string;
          signedDownloadUrl: string | null;
          questionKey: string;
          fileMetadata: {
            __typename?: 'FileMetadata';
            id: string;
            createdAt: Date;
          };
        }>;
      }>;
      comments: Array<{
        __typename?: 'EngagementTaskComment';
        id: string;
        createdAt: Date;
        userEditorId: string | null;
        userEditorName: string | null;
        creatorOrgId: string | null;
        comment: string | null;
      }>;
      edits: Array<{
        __typename?: 'CompanySurveyEdit';
        id: string;
        createdAt: Date;
        userEditorId: string | null;
        userEditorName: string | null;
        creatorOrgId: string | null;
        columnName: GQEditColumn;
        newValue: string | null;
        valueType: GQEditValueType | null;
      }>;
      footprintEstimate: {
        __typename?: 'FootprintEstimateOutput';
        id: string;
        output: {
          __typename?: 'FootprintEstimateOutputByScope';
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
        } | null;
      } | null;
      engagementTaskConfig: {
        __typename?: 'EngagementTaskConfig';
        id: string;
        name: string;
        sidebarTitle: string;
        defaultSupplierFacingDescription: string | null;
        rootFacingDescription: string | null;
        orgId: string | null;
        type: GQEngagementTaskConfigType | null;
        configHasTaskAssignedToCompany: boolean;
        timeNeeded: number | null;
        category: GQSurveyDefinitionCategory;
        engagementTaskInputs: Array<GQEngagementTaskInput>;
        optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
        baseConfig: {
          __typename?: 'EngagementTaskConfig';
          id: string;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            containers: Array<{
              __typename?: 'EngagementTaskContainer';
              id: string;
              label: string;
              description: string | null;
              componentId: GQEngagementTaskContainerComponentId | null;
              editable: boolean;
              statusJson: any;
              sidebarStepLabelOverride: string | null;
              eyebrowText: string | null;
              headerImage: GQEngagementTaskImageId | null;
              questions: Array<{
                __typename?: 'EngagementTaskQuestion';
                id: string;
                label: string | null;
                description: string | null;
                componentId: GQEngagementTaskQuestionComponentId;
                editable: boolean;
                deletable: boolean;
                questionKey: string;
                statusJson: any;
                whyCollectThis: string | null;
                answerIsPrivate: boolean;
                videoLink: string | null;
              }>;
            }>;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
        } | null;
      };
      rootCompany: {
        __typename?: 'Company';
        id: string;
        name: string;
        fullLogoUrl: string | null;
        url: string | null;
      };
      company: {
        __typename?: 'Company';
        id: string;
        name: string;
        naicsCode: string | null;
        sbtiStage: GQCompanySbtCommitmentStage;
        publicDisclosures: Array<{
          __typename?: 'Disclosure';
          id: string;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            publishingYear: number;
          } | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            publicDisclosureId: string | null;
            reportingYear: number;
            numEmployees: number | null;
            revenue: number | null;
            revenueCurrency: string | null;
            revenueUsd: number | null;
            percentageCleanEnergy: number | null;
            totalMwh: number | null;
            scope1: number | null;
            scope2Market: number | null;
            scope2Location: number | null;
            scope3: number | null;
            scope301: number | null;
            scope302: number | null;
            scope303: number | null;
            scope304: number | null;
            scope305: number | null;
            scope306: number | null;
            scope307: number | null;
            scope308: number | null;
            scope309: number | null;
            scope310: number | null;
            scope311: number | null;
            scope312: number | null;
            scope313: number | null;
            scope314: number | null;
            scope315: number | null;
            scope316: number | null;
            scope317: number | null;
          }> | null;
        }>;
      };
      expenseCategories: Array<{
        __typename?: 'DiscloseEmissionsExpenseCategories';
        beaCode: string;
        inputUnit: string;
        inputQuantity: number;
        kgco2e: number;
      }> | null;
      proposeTargetFilters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
      proposeTargetEmissionsTarget: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      } | null;
    } | null;
  } | null;
};

export type GQUpsertEngagementTaskAnswersMutationVariables = Exact<{
  input: GQUpsertEngagementTaskAnswersInput;
}>;

export type GQUpsertEngagementTaskAnswersMutation = {
  __typename?: 'Mutation';
  upsertEngagementTaskAnswers: {
    __typename?: 'UpsertEngagementTaskAnswersPayload';
    engagementTask: {
      __typename?: 'EngagementTask';
      id: string;
      crossOrgModelId: string;
      reportingYear: number | null;
      facilityName: string | null;
      submittedAt: Date | null;
      createdAt: Date;
      dueDate: Date | null;
      status: GQCompanySurveyStatus;
      surveyKind: GQCompanySurveyKind;
      supplierFacingDescription: string | null;
      watershedGeneric: boolean | null;
      currentAssignee: GQCompanySurveyAssignee;
      rootAssignee: string | null;
      supplierAssignee: string | null;
      initialSupplierAssigneeEmail: string | null;
      approvedAt: Date | null;
      statusChangedAt: Date | null;
      rootOrgId: string;
      supplierOrgId: string;
      latestCeeWorkflowId: string | null;
      latestPleeWorkflowId: string | null;
      latestFootprintDisclosureWorkflowId: string | null;
      engagementTaskBatchId: string | null;
      proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
      submittedAnswers: Array<{
        __typename?: 'EngagementTaskAnswer';
        id: string;
        questionKey: string;
        answer: any | null;
        skipped: boolean;
        questionIndex: number | null;
        attachments: Array<{
          __typename?: 'EngagementTaskAnswerAttachment';
          originalFilename: string;
          signedDownloadUrl: string | null;
          questionKey: string;
          fileMetadata: {
            __typename?: 'FileMetadata';
            id: string;
            createdAt: Date;
          };
        }>;
      }>;
      comments: Array<{
        __typename?: 'EngagementTaskComment';
        id: string;
        createdAt: Date;
        userEditorId: string | null;
        userEditorName: string | null;
        creatorOrgId: string | null;
        comment: string | null;
      }>;
      edits: Array<{
        __typename?: 'CompanySurveyEdit';
        id: string;
        createdAt: Date;
        userEditorId: string | null;
        userEditorName: string | null;
        creatorOrgId: string | null;
        columnName: GQEditColumn;
        newValue: string | null;
        valueType: GQEditValueType | null;
      }>;
      footprintEstimate: {
        __typename?: 'FootprintEstimateOutput';
        id: string;
        output: {
          __typename?: 'FootprintEstimateOutputByScope';
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
        } | null;
      } | null;
      engagementTaskConfig: {
        __typename?: 'EngagementTaskConfig';
        id: string;
        name: string;
        sidebarTitle: string;
        defaultSupplierFacingDescription: string | null;
        rootFacingDescription: string | null;
        orgId: string | null;
        type: GQEngagementTaskConfigType | null;
        configHasTaskAssignedToCompany: boolean;
        timeNeeded: number | null;
        category: GQSurveyDefinitionCategory;
        engagementTaskInputs: Array<GQEngagementTaskInput>;
        optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
        baseConfig: {
          __typename?: 'EngagementTaskConfig';
          id: string;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            containers: Array<{
              __typename?: 'EngagementTaskContainer';
              id: string;
              label: string;
              description: string | null;
              componentId: GQEngagementTaskContainerComponentId | null;
              editable: boolean;
              statusJson: any;
              sidebarStepLabelOverride: string | null;
              eyebrowText: string | null;
              headerImage: GQEngagementTaskImageId | null;
              questions: Array<{
                __typename?: 'EngagementTaskQuestion';
                id: string;
                label: string | null;
                description: string | null;
                componentId: GQEngagementTaskQuestionComponentId;
                editable: boolean;
                deletable: boolean;
                questionKey: string;
                statusJson: any;
                whyCollectThis: string | null;
                answerIsPrivate: boolean;
                videoLink: string | null;
              }>;
            }>;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
        } | null;
      };
      rootCompany: {
        __typename?: 'Company';
        id: string;
        name: string;
        fullLogoUrl: string | null;
        url: string | null;
      };
      company: {
        __typename?: 'Company';
        id: string;
        name: string;
        naicsCode: string | null;
        sbtiStage: GQCompanySbtCommitmentStage;
        publicDisclosures: Array<{
          __typename?: 'Disclosure';
          id: string;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            publishingYear: number;
          } | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            publicDisclosureId: string | null;
            reportingYear: number;
            numEmployees: number | null;
            revenue: number | null;
            revenueCurrency: string | null;
            revenueUsd: number | null;
            percentageCleanEnergy: number | null;
            totalMwh: number | null;
            scope1: number | null;
            scope2Market: number | null;
            scope2Location: number | null;
            scope3: number | null;
            scope301: number | null;
            scope302: number | null;
            scope303: number | null;
            scope304: number | null;
            scope305: number | null;
            scope306: number | null;
            scope307: number | null;
            scope308: number | null;
            scope309: number | null;
            scope310: number | null;
            scope311: number | null;
            scope312: number | null;
            scope313: number | null;
            scope314: number | null;
            scope315: number | null;
            scope316: number | null;
            scope317: number | null;
          }> | null;
        }>;
      };
      expenseCategories: Array<{
        __typename?: 'DiscloseEmissionsExpenseCategories';
        beaCode: string;
        inputUnit: string;
        inputQuantity: number;
        kgco2e: number;
      }> | null;
      proposeTargetFilters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
      proposeTargetEmissionsTarget: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      } | null;
    } | null;
  } | null;
};

export type GQCreateEngagementTaskAttachmentsMutationVariables = Exact<{
  input: GQCreateEngagementTaskAttachmentsInput;
}>;

export type GQCreateEngagementTaskAttachmentsMutation = {
  __typename?: 'Mutation';
  createEngagementTaskAttachments: {
    __typename?: 'CreateEngagementTaskAttachmentsPayload';
    attachmentMetadata: Array<{
      __typename?: 'EngagementTaskAttachment';
      signedUploadUrl: string;
      fileMetadataId: string;
      supplierAttachmentCrossOrgId: string;
    }>;
  } | null;
};

export type GQPeerCompanyGroupForBenchmarksFragment = {
  __typename?: 'PeerCompanyGroup';
  id: string;
  name: string;
  createdAt: Date;
  shouldHideFromFootprintOverview: boolean;
  revenueForRevenueAtRisk: number | null;
  members: Array<{
    __typename?: 'PeerCompanyGroupMember';
    id: string;
    revenueAtRisk: number | null;
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      description: string | null;
      sustainabilityWebsiteUrl: string | null;
      naicsCode: string | null;
      fullLogoUrl: string | null;
      disclosuresV2: Array<{
        __typename?: 'Disclosure';
        id: string;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          reportType: GQExternalReportType;
          publishingYear: number;
          reportingYears: Array<number>;
          publicUrl: string;
          qualityScore: GQDisclosureQualityScore | null;
          externalScore: string | null;
          cdpVendorData: {
            __typename?: 'CdpVendorData';
            id: string;
            publishingYear: number;
            reportingYear: number;
            scope1Nonzero: boolean;
            scope2Nonzero: boolean;
            scope3Nonzero: boolean;
            totalEmissionsNonzero: boolean;
            scope301Or302Nonzero: boolean;
            scope1Verified: boolean;
            scope2Verified: boolean;
            scope3Verified: boolean;
            pctEvaluationStatusesMatchResponse: number;
            disclosureQualityScore: GQDisclosureQualityScore;
            scope301EvaluationStatus: GQScope3EvaluationStatus;
            scope302EvaluationStatus: GQScope3EvaluationStatus;
            scope303EvaluationStatus: GQScope3EvaluationStatus;
            scope304EvaluationStatus: GQScope3EvaluationStatus;
            scope305EvaluationStatus: GQScope3EvaluationStatus;
            scope306EvaluationStatus: GQScope3EvaluationStatus;
            scope307EvaluationStatus: GQScope3EvaluationStatus;
            scope308EvaluationStatus: GQScope3EvaluationStatus;
            scope309EvaluationStatus: GQScope3EvaluationStatus;
            scope310EvaluationStatus: GQScope3EvaluationStatus;
            scope311EvaluationStatus: GQScope3EvaluationStatus;
            scope312EvaluationStatus: GQScope3EvaluationStatus;
            scope313EvaluationStatus: GQScope3EvaluationStatus;
            scope314EvaluationStatus: GQScope3EvaluationStatus;
            scope315EvaluationStatus: GQScope3EvaluationStatus;
            scope316EvaluationStatus: GQScope3EvaluationStatus;
            scope317EvaluationStatus: GQScope3EvaluationStatus;
          } | null;
        } | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          publicDisclosureId: string | null;
          reportingYear: number;
          numEmployees: number | null;
          revenue: number | null;
          revenueCurrency: string | null;
          revenueUsd: number | null;
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
          units: GQCompanyEmissionsUnits;
          percentageCleanEnergy: number | null;
          totalMwh: number | null;
          scope123RevenueIntensityKgco2e: {
            __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
            id: string;
            scope1: number | null;
            scope2Market: number | null;
            scope2Location: number | null;
            scope3: number | null;
          } | null;
        }> | null;
        climateCommitments: Array<
          | {
              __typename?: 'CarbonNeutralCommitment';
              targetYear: number | null;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename?: 'CleanEnergyCommitment';
              targetYear: number | null;
              targetPercentageCleanEnergy: number | null;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename?: 'NetZeroCommitment';
              targetYear: number | null;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
            }
          | {
              __typename?: 'ScienceBasedTargetCommitment';
              submittedToSBTi: boolean;
              id: string;
              kind: GQCompanyClimateCommitmentKind;
              description: string | null;
              externalUrl: string | null;
              commitmentMadeDate: Date | null;
              commitmentPeriodStart: Date | null;
              commitmentPeriodEnd: Date | null;
              commitment: {
                __typename?: 'SBTCommitment';
                id: string;
                stage: GQSbtCommitmentStage | null;
                targetClassification: GQSbtTargetClassification | null;
                nearTermTargetYear: number | null;
                longTermTargetYear: number | null;
                baselineYear: number | null;
                netZeroCommitted: boolean | null;
                commitmentType: GQSbtCommitmentType | null;
                commitmentDeadline: Date | null;
                netZeroTargetYear: number | null;
              };
            }
        > | null;
      }>;
    };
  }>;
};

export type GQPublicDisclosureForBenchmarksFragment = {
  __typename?: 'PublicDisclosure';
  id: string;
  reportType: GQExternalReportType;
  publishingYear: number;
  reportingYears: Array<number>;
  publicUrl: string;
  qualityScore: GQDisclosureQualityScore | null;
  externalScore: string | null;
  cdpVendorData: {
    __typename?: 'CdpVendorData';
    id: string;
    publishingYear: number;
    reportingYear: number;
    scope1Nonzero: boolean;
    scope2Nonzero: boolean;
    scope3Nonzero: boolean;
    totalEmissionsNonzero: boolean;
    scope301Or302Nonzero: boolean;
    scope1Verified: boolean;
    scope2Verified: boolean;
    scope3Verified: boolean;
    pctEvaluationStatusesMatchResponse: number;
    disclosureQualityScore: GQDisclosureQualityScore;
    scope301EvaluationStatus: GQScope3EvaluationStatus;
    scope302EvaluationStatus: GQScope3EvaluationStatus;
    scope303EvaluationStatus: GQScope3EvaluationStatus;
    scope304EvaluationStatus: GQScope3EvaluationStatus;
    scope305EvaluationStatus: GQScope3EvaluationStatus;
    scope306EvaluationStatus: GQScope3EvaluationStatus;
    scope307EvaluationStatus: GQScope3EvaluationStatus;
    scope308EvaluationStatus: GQScope3EvaluationStatus;
    scope309EvaluationStatus: GQScope3EvaluationStatus;
    scope310EvaluationStatus: GQScope3EvaluationStatus;
    scope311EvaluationStatus: GQScope3EvaluationStatus;
    scope312EvaluationStatus: GQScope3EvaluationStatus;
    scope313EvaluationStatus: GQScope3EvaluationStatus;
    scope314EvaluationStatus: GQScope3EvaluationStatus;
    scope315EvaluationStatus: GQScope3EvaluationStatus;
    scope316EvaluationStatus: GQScope3EvaluationStatus;
    scope317EvaluationStatus: GQScope3EvaluationStatus;
  } | null;
};

export type GQCompanyForBenchmarksFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
  description: string | null;
  sustainabilityWebsiteUrl: string | null;
  naicsCode: string | null;
  fullLogoUrl: string | null;
  disclosuresV2: Array<{
    __typename?: 'Disclosure';
    id: string;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      reportType: GQExternalReportType;
      publishingYear: number;
      reportingYears: Array<number>;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      externalScore: string | null;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      publicDisclosureId: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      revenueUsd: number | null;
      scope1: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
      units: GQCompanyEmissionsUnits;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      scope123RevenueIntensityKgco2e: {
        __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
        id: string;
        scope1: number | null;
        scope2Market: number | null;
        scope2Location: number | null;
        scope3: number | null;
      } | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'NetZeroCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            netZeroCommitted: boolean | null;
            commitmentType: GQSbtCommitmentType | null;
            commitmentDeadline: Date | null;
            netZeroTargetYear: number | null;
          };
        }
    > | null;
  }>;
};

export type GQRemoveCompanyFromPeerCompanyGroupMutationVariables = Exact<{
  input: GQRemoveCompanyFromPeerCompanyGroupInput;
}>;

export type GQRemoveCompanyFromPeerCompanyGroupMutation = {
  __typename?: 'Mutation';
  removeCompanyFromPeerCompanyGroup: {
    __typename?: 'RemoveCompanyFromPeerCompanyGroupPayload';
    peerCompanyGroup: {
      __typename?: 'PeerCompanyGroup';
      id: string;
      name: string;
      createdAt: Date;
      shouldHideFromFootprintOverview: boolean;
      revenueForRevenueAtRisk: number | null;
      members: Array<{
        __typename?: 'PeerCompanyGroupMember';
        id: string;
        revenueAtRisk: number | null;
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          description: string | null;
          sustainabilityWebsiteUrl: string | null;
          naicsCode: string | null;
          fullLogoUrl: string | null;
          disclosuresV2: Array<{
            __typename?: 'Disclosure';
            id: string;
            publicDisclosure: {
              __typename?: 'PublicDisclosure';
              id: string;
              reportType: GQExternalReportType;
              publishingYear: number;
              reportingYears: Array<number>;
              publicUrl: string;
              qualityScore: GQDisclosureQualityScore | null;
              externalScore: string | null;
              cdpVendorData: {
                __typename?: 'CdpVendorData';
                id: string;
                publishingYear: number;
                reportingYear: number;
                scope1Nonzero: boolean;
                scope2Nonzero: boolean;
                scope3Nonzero: boolean;
                totalEmissionsNonzero: boolean;
                scope301Or302Nonzero: boolean;
                scope1Verified: boolean;
                scope2Verified: boolean;
                scope3Verified: boolean;
                pctEvaluationStatusesMatchResponse: number;
                disclosureQualityScore: GQDisclosureQualityScore;
                scope301EvaluationStatus: GQScope3EvaluationStatus;
                scope302EvaluationStatus: GQScope3EvaluationStatus;
                scope303EvaluationStatus: GQScope3EvaluationStatus;
                scope304EvaluationStatus: GQScope3EvaluationStatus;
                scope305EvaluationStatus: GQScope3EvaluationStatus;
                scope306EvaluationStatus: GQScope3EvaluationStatus;
                scope307EvaluationStatus: GQScope3EvaluationStatus;
                scope308EvaluationStatus: GQScope3EvaluationStatus;
                scope309EvaluationStatus: GQScope3EvaluationStatus;
                scope310EvaluationStatus: GQScope3EvaluationStatus;
                scope311EvaluationStatus: GQScope3EvaluationStatus;
                scope312EvaluationStatus: GQScope3EvaluationStatus;
                scope313EvaluationStatus: GQScope3EvaluationStatus;
                scope314EvaluationStatus: GQScope3EvaluationStatus;
                scope315EvaluationStatus: GQScope3EvaluationStatus;
                scope316EvaluationStatus: GQScope3EvaluationStatus;
                scope317EvaluationStatus: GQScope3EvaluationStatus;
              } | null;
            } | null;
            historicalEmissionsYears: Array<{
              __typename?: 'EmissionsYear';
              id: string;
              publicDisclosureId: string | null;
              reportingYear: number;
              numEmployees: number | null;
              revenue: number | null;
              revenueCurrency: string | null;
              revenueUsd: number | null;
              scope1: number | null;
              scope2Market: number | null;
              scope2Location: number | null;
              scope3: number | null;
              scope301: number | null;
              scope302: number | null;
              scope303: number | null;
              scope304: number | null;
              scope305: number | null;
              scope306: number | null;
              scope307: number | null;
              scope308: number | null;
              scope309: number | null;
              scope310: number | null;
              scope311: number | null;
              scope312: number | null;
              scope313: number | null;
              scope314: number | null;
              scope315: number | null;
              scope316: number | null;
              scope317: number | null;
              units: GQCompanyEmissionsUnits;
              percentageCleanEnergy: number | null;
              totalMwh: number | null;
              scope123RevenueIntensityKgco2e: {
                __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
                id: string;
                scope1: number | null;
                scope2Market: number | null;
                scope2Location: number | null;
                scope3: number | null;
              } | null;
            }> | null;
            climateCommitments: Array<
              | {
                  __typename?: 'CarbonNeutralCommitment';
                  targetYear: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'CleanEnergyCommitment';
                  targetYear: number | null;
                  targetPercentageCleanEnergy: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'NetZeroCommitment';
                  targetYear: number | null;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                }
              | {
                  __typename?: 'ScienceBasedTargetCommitment';
                  submittedToSBTi: boolean;
                  id: string;
                  kind: GQCompanyClimateCommitmentKind;
                  description: string | null;
                  externalUrl: string | null;
                  commitmentMadeDate: Date | null;
                  commitmentPeriodStart: Date | null;
                  commitmentPeriodEnd: Date | null;
                  commitment: {
                    __typename?: 'SBTCommitment';
                    id: string;
                    stage: GQSbtCommitmentStage | null;
                    targetClassification: GQSbtTargetClassification | null;
                    nearTermTargetYear: number | null;
                    longTermTargetYear: number | null;
                    baselineYear: number | null;
                    netZeroCommitted: boolean | null;
                    commitmentType: GQSbtCommitmentType | null;
                    commitmentDeadline: Date | null;
                    netZeroTargetYear: number | null;
                  };
                }
            > | null;
          }>;
        };
      }>;
    } | null;
  } | null;
};

export type GQCompanySearchForBenchmarksQueryVariables = Exact<{
  search: InputMaybe<Scalars['String']['input']>;
  naicsCode: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQCompanySearchForBenchmarksQuery = {
  __typename?: 'Query';
  benchmarkNaicsCodes: Array<string>;
  benchmarkCompanies: {
    __typename?: 'CompanyConnection';
    edges: Array<{
      __typename?: 'CompanyEdge';
      node: {
        __typename?: 'Company';
        id: string;
        name: string;
        description: string | null;
        sustainabilityWebsiteUrl: string | null;
        naicsCode: string | null;
        fullLogoUrl: string | null;
        disclosuresV2: Array<{
          __typename?: 'Disclosure';
          id: string;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            reportType: GQExternalReportType;
            publishingYear: number;
            reportingYears: Array<number>;
            publicUrl: string;
            qualityScore: GQDisclosureQualityScore | null;
            externalScore: string | null;
            cdpVendorData: {
              __typename?: 'CdpVendorData';
              id: string;
              publishingYear: number;
              reportingYear: number;
              scope1Nonzero: boolean;
              scope2Nonzero: boolean;
              scope3Nonzero: boolean;
              totalEmissionsNonzero: boolean;
              scope301Or302Nonzero: boolean;
              scope1Verified: boolean;
              scope2Verified: boolean;
              scope3Verified: boolean;
              pctEvaluationStatusesMatchResponse: number;
              disclosureQualityScore: GQDisclosureQualityScore;
              scope301EvaluationStatus: GQScope3EvaluationStatus;
              scope302EvaluationStatus: GQScope3EvaluationStatus;
              scope303EvaluationStatus: GQScope3EvaluationStatus;
              scope304EvaluationStatus: GQScope3EvaluationStatus;
              scope305EvaluationStatus: GQScope3EvaluationStatus;
              scope306EvaluationStatus: GQScope3EvaluationStatus;
              scope307EvaluationStatus: GQScope3EvaluationStatus;
              scope308EvaluationStatus: GQScope3EvaluationStatus;
              scope309EvaluationStatus: GQScope3EvaluationStatus;
              scope310EvaluationStatus: GQScope3EvaluationStatus;
              scope311EvaluationStatus: GQScope3EvaluationStatus;
              scope312EvaluationStatus: GQScope3EvaluationStatus;
              scope313EvaluationStatus: GQScope3EvaluationStatus;
              scope314EvaluationStatus: GQScope3EvaluationStatus;
              scope315EvaluationStatus: GQScope3EvaluationStatus;
              scope316EvaluationStatus: GQScope3EvaluationStatus;
              scope317EvaluationStatus: GQScope3EvaluationStatus;
            } | null;
          } | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            publicDisclosureId: string | null;
            reportingYear: number;
            numEmployees: number | null;
            revenue: number | null;
            revenueCurrency: string | null;
            revenueUsd: number | null;
            scope1: number | null;
            scope2Market: number | null;
            scope2Location: number | null;
            scope3: number | null;
            scope301: number | null;
            scope302: number | null;
            scope303: number | null;
            scope304: number | null;
            scope305: number | null;
            scope306: number | null;
            scope307: number | null;
            scope308: number | null;
            scope309: number | null;
            scope310: number | null;
            scope311: number | null;
            scope312: number | null;
            scope313: number | null;
            scope314: number | null;
            scope315: number | null;
            scope316: number | null;
            scope317: number | null;
            units: GQCompanyEmissionsUnits;
            percentageCleanEnergy: number | null;
            totalMwh: number | null;
            scope123RevenueIntensityKgco2e: {
              __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
              id: string;
              scope1: number | null;
              scope2Market: number | null;
              scope2Location: number | null;
              scope3: number | null;
            } | null;
          }> | null;
          climateCommitments: Array<
            | {
                __typename?: 'CarbonNeutralCommitment';
                targetYear: number | null;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename?: 'CleanEnergyCommitment';
                targetYear: number | null;
                targetPercentageCleanEnergy: number | null;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename?: 'NetZeroCommitment';
                targetYear: number | null;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename?: 'ScienceBasedTargetCommitment';
                submittedToSBTi: boolean;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
                commitment: {
                  __typename?: 'SBTCommitment';
                  id: string;
                  stage: GQSbtCommitmentStage | null;
                  targetClassification: GQSbtTargetClassification | null;
                  nearTermTargetYear: number | null;
                  longTermTargetYear: number | null;
                  baselineYear: number | null;
                  netZeroCommitted: boolean | null;
                  commitmentType: GQSbtCommitmentType | null;
                  commitmentDeadline: Date | null;
                  netZeroTargetYear: number | null;
                };
              }
          > | null;
        }>;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type GQCompanyGroupsForBenchmarksQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQCompanyGroupsForBenchmarksQuery = {
  __typename?: 'Query';
  peerCompanyGroups: Array<{
    __typename?: 'PeerCompanyGroup';
    id: string;
    name: string;
    createdAt: Date;
    shouldHideFromFootprintOverview: boolean;
    revenueForRevenueAtRisk: number | null;
    members: Array<{
      __typename?: 'PeerCompanyGroupMember';
      id: string;
      revenueAtRisk: number | null;
      company: {
        __typename?: 'Company';
        id: string;
        name: string;
        description: string | null;
        sustainabilityWebsiteUrl: string | null;
        naicsCode: string | null;
        fullLogoUrl: string | null;
        disclosuresV2: Array<{
          __typename?: 'Disclosure';
          id: string;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            reportType: GQExternalReportType;
            publishingYear: number;
            reportingYears: Array<number>;
            publicUrl: string;
            qualityScore: GQDisclosureQualityScore | null;
            externalScore: string | null;
            cdpVendorData: {
              __typename?: 'CdpVendorData';
              id: string;
              publishingYear: number;
              reportingYear: number;
              scope1Nonzero: boolean;
              scope2Nonzero: boolean;
              scope3Nonzero: boolean;
              totalEmissionsNonzero: boolean;
              scope301Or302Nonzero: boolean;
              scope1Verified: boolean;
              scope2Verified: boolean;
              scope3Verified: boolean;
              pctEvaluationStatusesMatchResponse: number;
              disclosureQualityScore: GQDisclosureQualityScore;
              scope301EvaluationStatus: GQScope3EvaluationStatus;
              scope302EvaluationStatus: GQScope3EvaluationStatus;
              scope303EvaluationStatus: GQScope3EvaluationStatus;
              scope304EvaluationStatus: GQScope3EvaluationStatus;
              scope305EvaluationStatus: GQScope3EvaluationStatus;
              scope306EvaluationStatus: GQScope3EvaluationStatus;
              scope307EvaluationStatus: GQScope3EvaluationStatus;
              scope308EvaluationStatus: GQScope3EvaluationStatus;
              scope309EvaluationStatus: GQScope3EvaluationStatus;
              scope310EvaluationStatus: GQScope3EvaluationStatus;
              scope311EvaluationStatus: GQScope3EvaluationStatus;
              scope312EvaluationStatus: GQScope3EvaluationStatus;
              scope313EvaluationStatus: GQScope3EvaluationStatus;
              scope314EvaluationStatus: GQScope3EvaluationStatus;
              scope315EvaluationStatus: GQScope3EvaluationStatus;
              scope316EvaluationStatus: GQScope3EvaluationStatus;
              scope317EvaluationStatus: GQScope3EvaluationStatus;
            } | null;
          } | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            publicDisclosureId: string | null;
            reportingYear: number;
            numEmployees: number | null;
            revenue: number | null;
            revenueCurrency: string | null;
            revenueUsd: number | null;
            scope1: number | null;
            scope2Market: number | null;
            scope2Location: number | null;
            scope3: number | null;
            scope301: number | null;
            scope302: number | null;
            scope303: number | null;
            scope304: number | null;
            scope305: number | null;
            scope306: number | null;
            scope307: number | null;
            scope308: number | null;
            scope309: number | null;
            scope310: number | null;
            scope311: number | null;
            scope312: number | null;
            scope313: number | null;
            scope314: number | null;
            scope315: number | null;
            scope316: number | null;
            scope317: number | null;
            units: GQCompanyEmissionsUnits;
            percentageCleanEnergy: number | null;
            totalMwh: number | null;
            scope123RevenueIntensityKgco2e: {
              __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
              id: string;
              scope1: number | null;
              scope2Market: number | null;
              scope2Location: number | null;
              scope3: number | null;
            } | null;
          }> | null;
          climateCommitments: Array<
            | {
                __typename?: 'CarbonNeutralCommitment';
                targetYear: number | null;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename?: 'CleanEnergyCommitment';
                targetYear: number | null;
                targetPercentageCleanEnergy: number | null;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename?: 'NetZeroCommitment';
                targetYear: number | null;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
              }
            | {
                __typename?: 'ScienceBasedTargetCommitment';
                submittedToSBTi: boolean;
                id: string;
                kind: GQCompanyClimateCommitmentKind;
                description: string | null;
                externalUrl: string | null;
                commitmentMadeDate: Date | null;
                commitmentPeriodStart: Date | null;
                commitmentPeriodEnd: Date | null;
                commitment: {
                  __typename?: 'SBTCommitment';
                  id: string;
                  stage: GQSbtCommitmentStage | null;
                  targetClassification: GQSbtTargetClassification | null;
                  nearTermTargetYear: number | null;
                  longTermTargetYear: number | null;
                  baselineYear: number | null;
                  netZeroCommitted: boolean | null;
                  commitmentType: GQSbtCommitmentType | null;
                  commitmentDeadline: Date | null;
                  netZeroTargetYear: number | null;
                };
              }
          > | null;
        }>;
      };
    }>;
  }>;
};

export type GQAccessibleOrgsAndEngagementTasksQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQAccessibleOrgsAndEngagementTasksQuery = {
  __typename?: 'Query';
  activeOrganization: {
    __typename?: 'ActiveOrganization';
    id: string;
    user: {
      __typename?: 'User';
      id: string;
      accessibleOrgs: Array<{
        __typename?: 'OrgLimitedProfile';
        id: string;
        name: string;
        watershedPlanLegacy: GQWatershedPlanLegacy;
        company: {
          __typename?: 'Company';
          id: string;
          fullLogoUrl: string | null;
        };
        engagementTasks: Array<{
          __typename?: 'EngagementTaskLimitedProfile';
          id: string;
          rootCompany: {
            __typename?: 'Company';
            id: string;
            name: string;
            fullLogoUrl: string | null;
          };
        }>;
      }>;
    };
  };
};

export type GQAccessibleOrgsAndEngagementTasksFragment = {
  __typename?: 'ActiveOrganization';
  id: string;
  user: {
    __typename?: 'User';
    id: string;
    accessibleOrgs: Array<{
      __typename?: 'OrgLimitedProfile';
      id: string;
      name: string;
      watershedPlanLegacy: GQWatershedPlanLegacy;
      company: {
        __typename?: 'Company';
        id: string;
        fullLogoUrl: string | null;
      };
      engagementTasks: Array<{
        __typename?: 'EngagementTaskLimitedProfile';
        id: string;
        rootCompany: {
          __typename?: 'Company';
          id: string;
          name: string;
          fullLogoUrl: string | null;
        };
      }>;
    }>;
  };
};

export type GQCanonicalClimateProgramProjectsForAddProjectQueryVariables =
  Exact<{
    orgId: Scalars['ID']['input'];
  }>;

export type GQCanonicalClimateProgramProjectsForAddProjectQuery = {
  __typename?: 'Query';
  canonicalClimateProgramProjects: Array<{
    __typename?: 'CanonicalClimateProgramProject';
    id: string;
    name: string;
    description: string;
    costEstimate: string | null;
    timeEstimate: string | null;
    iconType: GQClimateProgramProjectIconType;
    hasBenchmarkMapping: boolean;
    matchObjectType: string | null;
    reportKind: string | null;
    routeType: string | null;
    kind: GQClimateProgramProjectKind;
    peerBenchmarkCompanies: Array<{
      __typename?: 'Company';
      id: string;
      name: string;
      fullLogoUrl: string | null;
    }>;
  }>;
  peerCompanyGroups: Array<{
    __typename: 'PeerCompanyGroup';
    id: string;
    name: string;
    footprintKinds: Array<string> | null;
    shouldHideFromFootprintOverview: boolean;
    members: Array<{
      __typename?: 'PeerCompanyGroupMember';
      id: string;
      shouldHideFromFootprintOverview: boolean;
      mostRecentHighQualityBenchmark: {
        __typename: 'Benchmark';
        id: string;
        reportId: string | null;
        name: string;
        reportingYear: number;
        publishingYear: number;
        kind: GQBenchmarkKind;
        normalizationKind: GQAggregateKind;
        amountKgco2e: number;
        currency: string;
        scope2Source: GQScope2Source | null;
        kgco2eByScope: Array<{
          __typename?: 'BenchmarkScopeBreakdownDataPoint';
          scope: GQGhgScope;
          kgco2e: number | null;
        }>;
      } | null;
    }>;
    selfBenchmarks: Array<{
      __typename: 'Benchmark';
      id: string;
      reportId: string | null;
      name: string;
      reportingYear: number;
      publishingYear: number;
      kind: GQBenchmarkKind;
      normalizationKind: GQAggregateKind;
      amountKgco2e: number;
      currency: string;
      scope2Source: GQScope2Source | null;
      kgco2eByScope: Array<{
        __typename?: 'BenchmarkScopeBreakdownDataPoint';
        scope: GQGhgScope;
        kgco2e: number | null;
      }>;
    }>;
  }>;
};

export type GQClimateProgramProjectsForHomepageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQClimateProgramProjectsForHomepageQuery = {
  __typename?: 'Query';
  climateProgramTimelineProjects: Array<{
    __typename: 'ClimateProgramTimelineProject';
    id: string;
    name: string;
    description: string;
    kind: GQClimateProgramProjectKind;
    startDate: Date | null;
    endDate: Date | null;
    plannedYear: number;
    completedAt: Date | null;
    iconType: GQClimateProgramProjectIconType;
    objectType: GQClimateProgramTimelineProjectObjectType;
    object:
      | { __typename: 'ClimateProgramProject'; id: string }
      | {
          __typename: 'MeasurementProject';
          id: string;
          name: string;
          setupAt: Date | null;
          completedAt: Date | null;
          measurementTimeline: {
            __typename?: 'MeasurementTimeline';
            id: string;
            kickoff: Date;
            deadline: Date;
          } | null;
        }
      | {
          __typename: 'Report';
          id: string;
          name: string;
          createdAt: Date;
          reportKind: string;
          config: {
            __typename?: 'ReportConfig';
            id: string;
            reportType: string;
          };
        };
    canonicalClimateProgramProject: {
      __typename?: 'CanonicalClimateProgramProject';
      id: string;
      name: string;
      routeType: string | null;
    } | null;
  }>;
  climateProgramCalendarEvents: Array<{
    __typename: 'ClimateProgramCalendarEvent';
    id: string;
    name: string;
    startDate: Date;
    endDate: Date | null;
  }>;
};

export type GQGetSingleRowMethodologyForEmissionsYearQueryVariables = Exact<{
  emissionsYearId: Scalars['String']['input'];
  rowId: Scalars['Int']['input'];
}>;

export type GQGetSingleRowMethodologyForEmissionsYearQuery = {
  __typename?: 'Query';
  singleRowMethodologyExplainerForEmissionsYear: {
    __typename?: 'SingleRowMethodologyExplainer';
    id: string;
    files: Array<{
      __typename?: 'FootprintAnalysisFile';
      id: string;
      name: string;
      category: GQFileCategory;
      createdAt: Date;
      uploader: { __typename?: 'User'; id: string; name: string } | null;
      userUploadTask: {
        __typename?: 'UserUploadTask';
        id: string;
        datasource: { __typename?: 'Datasource'; id: string; name: string };
      } | null;
    }>;
    pipelineV2Chain: {
      __typename: 'CalculationChain';
      inputQuantity: number | null;
      inputUnit: string | null;
      convertedQuantity: number;
      tco2eQuantity: number;
      tco2eLocationQuantity: number;
      sumOfMeasure: number;
      emissionsModelVersionId: string | null;
      conversionFactor: {
        __typename?: 'ConversionFactorForDashboard';
        id: string;
        conversionFactorId: string;
        inputUnit: string;
        outputUnit: string;
        conversionChain: Array<
          | {
              __typename: 'AssumptionRecordForDashboard';
              id: string;
              conversionDescription: string | null;
              conversionMultiplier: number | null;
              inputUnit: string | null;
              outputUnit: string | null;
              assumptionSource: {
                __typename?: 'AssumptionSource';
                id: string;
                externalSourceNotes: string | null;
                userVisibleSourceUrl: string | null;
                hasMultiplierLicenseRestrictions: boolean | null;
              };
              assumptionDescription: {
                __typename?: 'AssumptionDescription';
                id: string;
                updatedAt: Date;
                assumptionId: string;
                userVisibleConversionDescription: string;
              } | null;
            }
          | {
              __typename: 'UnitConversionRecord';
              assumptionId: string | null;
              assumptionUuid: string | null;
              conversionDescription: string | null;
              conversionMultiplier: number | null;
              inputUnit: string | null;
              outputUnit: string | null;
            }
        > | null;
      } | null;
      emissionsFactor: {
        __typename?: 'EmissionsFactorRecordForDashboard';
        id: string;
        emissionsFactorId: string;
        kgCo2e: number | null;
        unit: string | null;
        efName: string | null;
        isVendorSpecific: boolean | null;
        startMonth: YearMonth;
        endMonth: YearMonth;
        assumptionSource: {
          __typename?: 'AssumptionSource';
          id: string;
          externalSourceNotes: string | null;
          userVisibleSourceUrl: string | null;
          hasMultiplierLicenseRestrictions: boolean | null;
        } | null;
        emissionsFactorDescription: {
          __typename?: 'EmissionsFactorDescription';
          id: string;
          updatedAt: Date;
          emissionsFactorId: string;
          userVisibleEfName: string;
        } | null;
        supplierDisclosure: {
          __typename?: 'Disclosure';
          id: string;
          qualityScore: GQDisclosureQualityScore | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            reportingYear: number;
          }> | null;
          privateDisclosure: {
            __typename?: 'PrivateDisclosure';
            id: string;
            privateDisclosureType: GQPrivateDisclosureType;
          } | null;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            reportType: GQExternalReportType;
            publicUrl: string;
          } | null;
          climateCommitments: Array<
            | {
                __typename?: 'CarbonNeutralCommitment';
                targetYear: number | null;
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'CleanEnergyCommitment';
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'NetZeroCommitment';
                targetYear: number | null;
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'ScienceBasedTargetCommitment';
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
                commitment: {
                  __typename?: 'SBTCommitment';
                  id: string;
                  stage: GQSbtCommitmentStage | null;
                  targetClassification: GQSbtTargetClassification | null;
                };
              }
          > | null;
        } | null;
      } | null;
      locationEmissionsFactor: {
        __typename?: 'EmissionsFactorRecordForDashboard';
        id: string;
        emissionsFactorId: string;
        kgCo2e: number | null;
        unit: string | null;
        efName: string | null;
        isVendorSpecific: boolean | null;
        startMonth: YearMonth;
        endMonth: YearMonth;
        assumptionSource: {
          __typename?: 'AssumptionSource';
          id: string;
          externalSourceNotes: string | null;
          userVisibleSourceUrl: string | null;
          hasMultiplierLicenseRestrictions: boolean | null;
        } | null;
        emissionsFactorDescription: {
          __typename?: 'EmissionsFactorDescription';
          id: string;
          updatedAt: Date;
          emissionsFactorId: string;
          userVisibleEfName: string;
        } | null;
        supplierDisclosure: {
          __typename?: 'Disclosure';
          id: string;
          qualityScore: GQDisclosureQualityScore | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            reportingYear: number;
          }> | null;
          privateDisclosure: {
            __typename?: 'PrivateDisclosure';
            id: string;
            privateDisclosureType: GQPrivateDisclosureType;
          } | null;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            reportType: GQExternalReportType;
            publicUrl: string;
          } | null;
          climateCommitments: Array<
            | {
                __typename?: 'CarbonNeutralCommitment';
                targetYear: number | null;
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'CleanEnergyCommitment';
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'NetZeroCommitment';
                targetYear: number | null;
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'ScienceBasedTargetCommitment';
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
                commitment: {
                  __typename?: 'SBTCommitment';
                  id: string;
                  stage: GQSbtCommitmentStage | null;
                  targetClassification: GQSbtTargetClassification | null;
                };
              }
          > | null;
        } | null;
      } | null;
    } | null;
    marketTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    locationTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    biogenicCo2Trace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    biogenicCo2LocationTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    cleanPowerAvoidedEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    wasteEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    microplasticsInflowEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    microplasticsOutflowEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    substanceInflowEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    substanceOutflowEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    waterConsumptionEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    footprintExclusionRule: {
      __typename?: 'FootprintExclusionRule';
      id: string;
      stableId: string;
      deduplicationNote: string | null;
      outputValue: number;
    } | null;
  };
};

export type GQSingleRowTraceFragmentFragment = {
  __typename?: 'SingleRowTrace';
  id: string;
  trace: any;
  ghgCategoryId: string;
  traceDetails: {
    __typename?: 'TraceDetails';
    referenceValueDetails: Array<any>;
    emissionsModelVariableDetails: Array<any>;
    bartValueDetails: Array<any>;
    emissionsModelVersionId: string | null;
    emissionsModelEvaluationId: string | null;
  };
};

export type GQGetSingleRowMethodologyQueryVariables = Exact<{
  bundleId: Scalars['String']['input'];
  rowId: Scalars['Int']['input'];
}>;

export type GQGetSingleRowMethodologyQuery = {
  __typename?: 'Query';
  singleRowMethodologyExplainer: {
    __typename?: 'SingleRowMethodologyExplainer';
    id: string;
    files: Array<{
      __typename?: 'FootprintAnalysisFile';
      id: string;
      name: string;
      category: GQFileCategory;
      createdAt: Date;
      uploader: { __typename?: 'User'; id: string; name: string } | null;
      userUploadTask: {
        __typename?: 'UserUploadTask';
        id: string;
        datasource: { __typename?: 'Datasource'; id: string; name: string };
      } | null;
    }>;
    pipelineV2Chain: {
      __typename: 'CalculationChain';
      inputQuantity: number | null;
      inputUnit: string | null;
      convertedQuantity: number;
      tco2eQuantity: number;
      tco2eLocationQuantity: number;
      sumOfMeasure: number;
      emissionsModelVersionId: string | null;
      conversionFactor: {
        __typename?: 'ConversionFactorForDashboard';
        id: string;
        conversionFactorId: string;
        inputUnit: string;
        outputUnit: string;
        conversionChain: Array<
          | {
              __typename: 'AssumptionRecordForDashboard';
              id: string;
              conversionDescription: string | null;
              conversionMultiplier: number | null;
              inputUnit: string | null;
              outputUnit: string | null;
              assumptionSource: {
                __typename?: 'AssumptionSource';
                id: string;
                externalSourceNotes: string | null;
                userVisibleSourceUrl: string | null;
                hasMultiplierLicenseRestrictions: boolean | null;
              };
              assumptionDescription: {
                __typename?: 'AssumptionDescription';
                id: string;
                updatedAt: Date;
                assumptionId: string;
                userVisibleConversionDescription: string;
              } | null;
            }
          | {
              __typename: 'UnitConversionRecord';
              assumptionId: string | null;
              assumptionUuid: string | null;
              conversionDescription: string | null;
              conversionMultiplier: number | null;
              inputUnit: string | null;
              outputUnit: string | null;
            }
        > | null;
      } | null;
      emissionsFactor: {
        __typename?: 'EmissionsFactorRecordForDashboard';
        id: string;
        emissionsFactorId: string;
        kgCo2e: number | null;
        unit: string | null;
        efName: string | null;
        isVendorSpecific: boolean | null;
        startMonth: YearMonth;
        endMonth: YearMonth;
        assumptionSource: {
          __typename?: 'AssumptionSource';
          id: string;
          externalSourceNotes: string | null;
          userVisibleSourceUrl: string | null;
          hasMultiplierLicenseRestrictions: boolean | null;
        } | null;
        emissionsFactorDescription: {
          __typename?: 'EmissionsFactorDescription';
          id: string;
          updatedAt: Date;
          emissionsFactorId: string;
          userVisibleEfName: string;
        } | null;
        supplierDisclosure: {
          __typename?: 'Disclosure';
          id: string;
          qualityScore: GQDisclosureQualityScore | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            reportingYear: number;
          }> | null;
          privateDisclosure: {
            __typename?: 'PrivateDisclosure';
            id: string;
            privateDisclosureType: GQPrivateDisclosureType;
          } | null;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            reportType: GQExternalReportType;
            publicUrl: string;
          } | null;
          climateCommitments: Array<
            | {
                __typename?: 'CarbonNeutralCommitment';
                targetYear: number | null;
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'CleanEnergyCommitment';
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'NetZeroCommitment';
                targetYear: number | null;
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'ScienceBasedTargetCommitment';
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
                commitment: {
                  __typename?: 'SBTCommitment';
                  id: string;
                  stage: GQSbtCommitmentStage | null;
                  targetClassification: GQSbtTargetClassification | null;
                };
              }
          > | null;
        } | null;
      } | null;
      locationEmissionsFactor: {
        __typename?: 'EmissionsFactorRecordForDashboard';
        id: string;
        emissionsFactorId: string;
        kgCo2e: number | null;
        unit: string | null;
        efName: string | null;
        isVendorSpecific: boolean | null;
        startMonth: YearMonth;
        endMonth: YearMonth;
        assumptionSource: {
          __typename?: 'AssumptionSource';
          id: string;
          externalSourceNotes: string | null;
          userVisibleSourceUrl: string | null;
          hasMultiplierLicenseRestrictions: boolean | null;
        } | null;
        emissionsFactorDescription: {
          __typename?: 'EmissionsFactorDescription';
          id: string;
          updatedAt: Date;
          emissionsFactorId: string;
          userVisibleEfName: string;
        } | null;
        supplierDisclosure: {
          __typename?: 'Disclosure';
          id: string;
          qualityScore: GQDisclosureQualityScore | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            reportingYear: number;
          }> | null;
          privateDisclosure: {
            __typename?: 'PrivateDisclosure';
            id: string;
            privateDisclosureType: GQPrivateDisclosureType;
          } | null;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            reportType: GQExternalReportType;
            publicUrl: string;
          } | null;
          climateCommitments: Array<
            | {
                __typename?: 'CarbonNeutralCommitment';
                targetYear: number | null;
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'CleanEnergyCommitment';
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'NetZeroCommitment';
                targetYear: number | null;
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
              }
            | {
                __typename?: 'ScienceBasedTargetCommitment';
                id: string;
                externalUrl: string | null;
                kind: GQCompanyClimateCommitmentKind;
                commitment: {
                  __typename?: 'SBTCommitment';
                  id: string;
                  stage: GQSbtCommitmentStage | null;
                  targetClassification: GQSbtTargetClassification | null;
                };
              }
          > | null;
        } | null;
      } | null;
    } | null;
    marketTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    locationTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    biogenicCo2Trace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    biogenicCo2LocationTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    cleanPowerAvoidedEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    wasteEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    microplasticsInflowEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    microplasticsOutflowEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    substanceInflowEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    substanceOutflowEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    waterConsumptionEmissionsTrace: {
      __typename?: 'SingleRowTrace';
      id: string;
      trace: any;
      ghgCategoryId: string;
      traceDetails: {
        __typename?: 'TraceDetails';
        referenceValueDetails: Array<any>;
        emissionsModelVariableDetails: Array<any>;
        bartValueDetails: Array<any>;
        emissionsModelVersionId: string | null;
        emissionsModelEvaluationId: string | null;
      };
    } | null;
    footprintExclusionRule: {
      __typename?: 'FootprintExclusionRule';
      id: string;
      stableId: string;
      deduplicationNote: string | null;
      outputValue: number;
    } | null;
  };
  footprintAuditBundle: {
    __typename?: 'FootprintAuditBundle';
    id: string;
    createdAt: Date;
    inputJson: any | null;
  };
};

export type GQGetFootprintAuditBundleQueryVariables = Exact<{
  bundleId: Scalars['String']['input'];
}>;

export type GQGetFootprintAuditBundleQuery = {
  __typename?: 'Query';
  footprintAuditVerificationTemplateUrl: string;
  footprintAuditBundle: {
    __typename?: 'FootprintAuditBundle';
    id: string;
    createdAt: Date;
    downloadUrl: string | null;
    footprintEmissionsKind: GQFootprintEmissionsKind;
  };
};

export type GQGetPinInfoQueryVariables = Exact<{
  version: Scalars['String']['input'];
}>;

export type GQGetPinInfoQuery = {
  __typename?: 'Query';
  footprintVersion: {
    __typename?: 'FootprintVersion';
    id: string;
    createdAt: Date;
    originalFootprintSnapshotId: string;
    pinInfo: {
      __typename?: 'FootprintVersionPin';
      id: string;
      name: string;
      description: string | null;
    } | null;
  } | null;
};

export type GQWatershedFootprintReviewRequestForReviewPageFragment = {
  __typename?: 'WatershedFootprintReviewRequest';
  id: string;
  createdAt: Date;
  status: GQWatershedFootprintReviewRequestStatus;
  user: { __typename?: 'User'; id: string; displayName: string };
  discussion: { __typename?: 'Discussion'; id: string } | null;
};

export type GQFootprintVersionForReviewFragment = {
  __typename?: 'FootprintVersion';
  id: string;
  kind: GQFootprintVersionKind;
  canUserPublish: boolean;
  versionName: string | null;
  versionIdentifier: string | null;
  originalFootprintSnapshotId: string;
  userChangelogMd: string | null;
  publishableUserChangelogMd: string | null;
  createdAt: Date;
  generatedBy: { __typename?: 'User'; id: string; displayName: string } | null;
  watershedFootprintReviewRequest: {
    __typename?: 'WatershedFootprintReviewRequest';
    id: string;
    createdAt: Date;
    status: GQWatershedFootprintReviewRequestStatus;
    user: { __typename?: 'User'; id: string; displayName: string };
    discussion: { __typename?: 'Discussion'; id: string } | null;
  } | null;
};

export type GQGetReviewPageDataQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
}>;

export type GQGetReviewPageDataQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    footprintInterval: YMInterval | null;
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      kind: GQFootprintVersionKind;
      canUserPublish: boolean;
      versionName: string | null;
      versionIdentifier: string | null;
      originalFootprintSnapshotId: string;
      userChangelogMd: string | null;
      publishableUserChangelogMd: string | null;
      createdAt: Date;
      generatedBy: {
        __typename?: 'User';
        id: string;
        displayName: string;
      } | null;
      watershedFootprintReviewRequest: {
        __typename?: 'WatershedFootprintReviewRequest';
        id: string;
        createdAt: Date;
        status: GQWatershedFootprintReviewRequestStatus;
        user: { __typename?: 'User'; id: string; displayName: string };
        discussion: { __typename?: 'Discussion'; id: string } | null;
      } | null;
    } | null;
  } | null;
  footprintChangelog: {
    __typename?: 'FootprintChangelog';
    id: string;
    versions: {
      __typename?: 'FootprintVersionConnection';
      edges: Array<{
        __typename?: 'FootprintVersionEdge';
        node: {
          __typename?: 'FootprintVersion';
          id: string;
          createdAt: Date;
          versionName: string | null;
          versionIdentifier: string | null;
          userChangelogMd: string | null;
          publishableUserChangelogMd: string | null;
          kind: GQFootprintVersionKind;
          canUserPublish: boolean;
          isGeneratedFromProduct: boolean;
          isAutoGenerated: boolean;
          userVisibleFootprintSnapshotId: string;
          originalFootprintSnapshotId: string;
          footprintInterval: YMInterval | null;
          pinInfo: {
            __typename?: 'FootprintVersionPin';
            id: string;
            name: string;
            description: string | null;
          } | null;
          generatedBy: {
            __typename?: 'User';
            id: string;
            displayName: string;
          } | null;
          measurementProjects: Array<{
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
            coverageInterval: YMInterval;
          }>;
          watershedFootprintReviewRequest: {
            __typename?: 'WatershedFootprintReviewRequest';
            id: string;
            status: GQWatershedFootprintReviewRequestStatus;
          } | null;
        } | null;
      } | null>;
    } | null;
  };
  footprintSnapshotInDashboard: {
    __typename?: 'FootprintSnapshot';
    id: string;
    footprint: { __typename?: 'FootprintTemp'; id: string; name: string };
  };
};

export type GQTimeseriesFieldsFragment = {
  __typename?: 'FootprintAnalysisTimeSeries';
  id: string;
  kind: GQAggregateKind;
  series: Array<{
    __typename?: 'FootprintAnalysisTimeseriesSeries';
    label: string | null;
    value: string | null;
    data: Array<{
      __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
      date: Date;
      value: number;
      headcount: number | null;
      revenue: number | null;
      customIntensities: Array<{
        __typename?: 'FootprintAnalysisCustomIntensityDataPoint';
        customIntensityId: string;
        value: number | null;
      }> | null;
    }>;
  }>;
};

export type GQBenchmarkFieldsForInsightsFragment = {
  __typename?: 'Benchmark';
  id: string;
  reportId: string | null;
  name: string;
  reportingYear: number;
  publishingYear: number;
  kind: GQBenchmarkKind;
  normalizationKind: GQAggregateKind;
  amountKgco2e: number;
  currency: string;
  source: GQExternalReportType | null;
  qualityScore: GQDisclosureQualityScore | null;
  scope2Source: GQScope2Source | null;
  kgco2eByScope: Array<{
    __typename?: 'BenchmarkScopeBreakdownDataPoint';
    scope: GQGhgScope;
    kgco2e: number | null;
  }>;
};

export type GQPeerCompanyGroupFieldsForInsightsFragment = {
  __typename?: 'PeerCompanyGroup';
  id: string;
  name: string;
  footprintKinds: Array<string> | null;
  shouldHideFromFootprintOverview: boolean;
  members: Array<{
    __typename?: 'PeerCompanyGroupMember';
    id: string;
    shouldHideFromFootprintOverview: boolean;
    company: { __typename?: 'Company'; id: string; name: string };
    mostRecentHighQualityBenchmark: {
      __typename?: 'Benchmark';
      id: string;
      reportId: string | null;
      name: string;
      reportingYear: number;
      publishingYear: number;
      kind: GQBenchmarkKind;
      normalizationKind: GQAggregateKind;
      amountKgco2e: number;
      currency: string;
      source: GQExternalReportType | null;
      qualityScore: GQDisclosureQualityScore | null;
      scope2Source: GQScope2Source | null;
      kgco2eByScope: Array<{
        __typename?: 'BenchmarkScopeBreakdownDataPoint';
        scope: GQGhgScope;
        kgco2e: number | null;
      }>;
    } | null;
  }>;
  selfBenchmarks: Array<{
    __typename?: 'Benchmark';
    id: string;
    reportId: string | null;
    name: string;
    reportingYear: number;
    publishingYear: number;
    kind: GQBenchmarkKind;
    normalizationKind: GQAggregateKind;
    amountKgco2e: number;
    currency: string;
    source: GQExternalReportType | null;
    qualityScore: GQDisclosureQualityScore | null;
    scope2Source: GQScope2Source | null;
    kgco2eByScope: Array<{
      __typename?: 'BenchmarkScopeBreakdownDataPoint';
      scope: GQGhgScope;
      kgco2e: number | null;
    }>;
  }>;
};

export type GQFootprintVersionForOverviewFragment = {
  __typename?: 'FootprintVersion';
  id: string;
  kind: GQFootprintVersionKind;
  createdAt: Date;
  originalFootprintSnapshotId: string;
};

export type GQGetFootprintInsightsPageDataQueryVariables = Exact<{
  oneYearFilters: GQFootprintSummaryFilters;
  oneYearScope1Filters: GQFootprintSummaryFilters;
  oneYearScope2Filters: GQFootprintSummaryFilters;
  oneYearScope3Filters: GQFootprintSummaryFilters;
  oneYearNetFilters: GQFootprintSummaryFilters;
  twoYearNetFilters: GQFootprintSummaryFilters;
  allYearFilters: GQFootprintSummaryFilters;
  allYearRemovalFilters: GQFootprintSummaryFilters;
  footprintSnapshotId: Scalars['String']['input'];
  kind: Scalars['String']['input'];
}>;

export type GQGetFootprintInsightsPageDataQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  organization: { __typename?: 'Organization'; id: string };
  footprint: {
    __typename?: 'LegacyFootprint';
    id: string;
    footprintKinds: Array<string> | null;
    summary: {
      __typename?: 'FootprintSummary';
      id: string;
      oneYearNet: number | null;
      twoYearNet: number | null;
      oneYearGross: number | null;
      oneYearScope1: number | null;
      oneYearScope2: number | null;
      oneYearScope3: number | null;
      timeseries: {
        __typename?: 'FootprintAnalysisTimeSeries';
        id: string;
        kind: GQAggregateKind;
        series: Array<{
          __typename?: 'FootprintAnalysisTimeseriesSeries';
          label: string | null;
          value: string | null;
          data: Array<{
            __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
            date: Date;
            value: number;
            headcount: number | null;
            revenue: number | null;
            customIntensities: Array<{
              __typename?: 'FootprintAnalysisCustomIntensityDataPoint';
              customIntensityId: string;
              value: number | null;
            }> | null;
          }>;
        }>;
      } | null;
      removalTimeseries: {
        __typename?: 'FootprintAnalysisTimeSeries';
        id: string;
        kind: GQAggregateKind;
        series: Array<{
          __typename?: 'FootprintAnalysisTimeseriesSeries';
          label: string | null;
          value: string | null;
          data: Array<{
            __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
            date: Date;
            value: number;
            headcount: number | null;
            revenue: number | null;
            customIntensities: Array<{
              __typename?: 'FootprintAnalysisCustomIntensityDataPoint';
              customIntensityId: string;
              value: number | null;
            }> | null;
          }>;
        }>;
      } | null;
      heavyHitters: Array<{
        __typename?: 'CategorizedEmissionData';
        businessCategory: string;
        businessSubcategory: string | null;
        proportion: number;
      }>;
      topCategories: Array<{
        __typename?: 'CategorizedEmissionData';
        businessCategory: string;
        businessSubcategory: string | null;
        proportion: number;
      }>;
    };
    intensityDenominators: Array<{
      __typename?: 'IntensityDenominatorRecord';
      aggregateKind: GQAggregateKind;
      intensityKind: GQIntensityDenominatorKind;
      data: Array<{
        __typename?: 'IntensityDenominatorDataPoint';
        yearMonth: YearMonth;
        value: number;
      }>;
    }>;
    footprintBridgeDates: {
      __typename?: 'FootprintBridgeDates';
      oldStartDate: YearMonth;
      oldEndDate: YearMonth;
      newStartDate: YearMonth;
      newEndDate: YearMonth;
    } | null;
  } | null;
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    peerCompanyGroups: Array<{
      __typename?: 'PeerCompanyGroup';
      id: string;
      name: string;
      footprintKinds: Array<string> | null;
      shouldHideFromFootprintOverview: boolean;
      members: Array<{
        __typename?: 'PeerCompanyGroupMember';
        id: string;
        shouldHideFromFootprintOverview: boolean;
        company: { __typename?: 'Company'; id: string; name: string };
        mostRecentHighQualityBenchmark: {
          __typename?: 'Benchmark';
          id: string;
          reportId: string | null;
          name: string;
          reportingYear: number;
          publishingYear: number;
          kind: GQBenchmarkKind;
          normalizationKind: GQAggregateKind;
          amountKgco2e: number;
          currency: string;
          source: GQExternalReportType | null;
          qualityScore: GQDisclosureQualityScore | null;
          scope2Source: GQScope2Source | null;
          kgco2eByScope: Array<{
            __typename?: 'BenchmarkScopeBreakdownDataPoint';
            scope: GQGhgScope;
            kgco2e: number | null;
          }>;
        } | null;
      }>;
      selfBenchmarks: Array<{
        __typename?: 'Benchmark';
        id: string;
        reportId: string | null;
        name: string;
        reportingYear: number;
        publishingYear: number;
        kind: GQBenchmarkKind;
        normalizationKind: GQAggregateKind;
        amountKgco2e: number;
        currency: string;
        source: GQExternalReportType | null;
        qualityScore: GQDisclosureQualityScore | null;
        scope2Source: GQScope2Source | null;
        kgco2eByScope: Array<{
          __typename?: 'BenchmarkScopeBreakdownDataPoint';
          scope: GQGhgScope;
          kgco2e: number | null;
        }>;
      }>;
    }>;
  } | null;
  footprintChangelog: {
    __typename?: 'FootprintChangelog';
    id: string;
    versions: {
      __typename?: 'FootprintVersionConnection';
      edges: Array<{
        __typename?: 'FootprintVersionEdge';
        node: {
          __typename?: 'FootprintVersion';
          id: string;
          kind: GQFootprintVersionKind;
          createdAt: Date;
          originalFootprintSnapshotId: string;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQPlanFieldsForHomepageFragment = {
  __typename?: 'Plan';
  id: string;
  name: string;
  forecast: {
    __typename?: 'Forecast';
    id: string;
    interval: YMInterval;
    customIntensityConfigs: Array<{
      __typename?: 'CustomIntensityConfig';
      id: string;
      name: string;
      description: string;
      unit: string | null;
      humanReadable: boolean;
      quantityType: GQCustomIntensityConfigQuantityType;
      isSbtValidated: boolean | null;
    }>;
  };
  targets: Array<{
    __typename?: 'PlanTarget';
    id: string;
    createdAt: Date | null;
    description: string;
    reportDescription: string;
    customNotes: string;
    baseYear: YearMonth;
    intensityType: GQPlanTargetIntensityType;
    customIntensityConfigId: string | null;
    comparisonType: GQPlanTargetTargetComparisonType;
    reductionRate: GQTargetReductionRate;
    deletedAt: Date | null;
    assignedTo: string | null;
    parentTargetId: string | null;
    interimTargetDate: YearMonth | null;
    interimTargetValue: number | null;
    emissionsTargetCustom: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
    filters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    };
  }>;
  carbonOffsets: {
    __typename?: 'SimpleTimeseries';
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  carbonRemoval: {
    __typename?: 'SimpleTimeseries';
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
};

export type GQFootprintAnalysisTimeSeriesDataFieldsFragment = {
  __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
  date: Date;
  value: number;
};

export type GQHomePageQueryVariables = Exact<{ [key: string]: never }>;

export type GQHomePageQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    profile: {
      __typename?: 'OrgProfile';
      id: string;
      name: string | null;
      fiscalYearStartMonth: number | null;
      country: string | null;
      industry: string | null;
      isCompanyDataConfirmed: boolean | null;
      hasMeasuredFootprintOutsideOfWatershed: GQYesNoUnknown | null;
      lastNonWatershedMeasurementYearString: string | null;
      hasClimateCommitments: GQYesNoUnknown | null;
      climateCommitments: Array<GQCompanyClimateCommitmentKind> | null;
      otherClimateCommitment: string | null;
      climateGoals: Array<string> | null;
      climateMotivation: string | null;
      completedAt: Date | null;
      updatedAt: Date | null;
      completedBy: { __typename?: 'User'; id: string; name: string } | null;
      updatedBy: { __typename?: 'User'; id: string; name: string } | null;
    };
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
    }>;
  };
};

export type GQHomePageFootprintQueryVariables = Exact<{
  oneYear: InputMaybe<GQFootprintSummaryFilters>;
  measurementFilters: InputMaybe<GQFootprintSummaryFilters>;
  footprintKind: Scalars['String']['input'];
}>;

export type GQHomePageFootprintQuery = {
  __typename?: 'Query';
  footprint: {
    __typename?: 'LegacyFootprint';
    id: string;
    summary: {
      __typename?: 'FootprintSummary';
      id: string;
      oneYearTotal: number | null;
      measurementGross: number | null;
      oneYearHeavyHitters: Array<{
        __typename?: 'CategorizedEmissionData';
        businessCategory: string;
        businessSubcategory: string | null;
        amountKgco2e: number;
      }>;
      measurementTimeseries: {
        __typename?: 'FootprintAnalysisTimeSeries';
        id: string;
        series: Array<{
          __typename?: 'FootprintAnalysisTimeseriesSeries';
          label: string | null;
          value: string | null;
          data: Array<{
            __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
            date: Date;
            value: number;
          }>;
        }>;
      } | null;
      measurementHeavyHitters: Array<{
        __typename?: 'CategorizedEmissionData';
        businessCategory: string;
        businessSubcategory: string | null;
        proportion: number;
        amountKgco2e: number;
      }>;
    };
  } | null;
};

export type GQHomePageExpensiveQueryVariables = Exact<{
  hasViewReductionsPermissions: Scalars['Boolean']['input'];
  hasManageAnyDatasourcePermissions: Scalars['Boolean']['input'];
}>;

export type GQHomePageExpensiveQuery = {
  __typename?: 'Query';
  activeMeasurementProjects?: Array<{
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    active: boolean;
    coverageStartDate: Date;
    footprintStatus: GQMeasurementProjectFootprintStatus;
    measurementTimeline: {
      __typename?: 'MeasurementTimeline';
      id: string;
      kickoff: Date;
      deadline: Date;
    } | null;
    datasetsWithStages: Array<{
      __typename?: 'DatasetWithStage';
      id: string;
      areAllTasksComplete: boolean;
      areUploadsComplete: boolean;
      isUsableInFootprint: boolean;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        materialCsrdStandards: Array<GQCsrdStandard>;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      } | null;
    }>;
    measurementTasks: Array<{
      __typename?: 'MeasurementParentTask';
      id: string;
      title: string;
      link: string | null;
      lockState: GQApprovalTargetLockState | null;
      isAssignedToWatershed: boolean;
      statusSimplified: GQTaskWatershedProcessStateSimplified;
      assignees: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
        didLatestEmailBounce: boolean;
      }>;
      approvers: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      }>;
      object:
        | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
        | {
            __typename: 'DataIssue';
            id: string;
            linkedObject:
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItemQuestion';
                  id: string;
                  item: {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    } | null;
                  };
                }
              | {
                  __typename: 'UserUploadTask';
                  id: string;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask' }
              | null;
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  createdAt: Date;
                  isPublished: boolean;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          }
        | { __typename: 'FacilitiesSetup'; id: string }
        | {
            __typename: 'FinancialsReviewGroup';
            id: string;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              };
            }>;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            group: {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            };
          }
        | { __typename: 'FootprintQuestion'; id: string }
        | {
            __typename: 'MeasurementDataMappingTask';
            id: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            };
          }
        | { __typename: 'MeasurementProject'; id: string }
        | {
            __typename: 'MeasurementProjectDataset';
            modelId: string | null;
            isCompleted: boolean;
            id: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            };
          }
        | {
            __typename: 'MeasurementVerificationItem';
            id: string;
            mviStatus: GQMeasurementVerificationItemStatus;
            maybeDataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            } | null;
          }
        | {
            __typename: 'UserUploadTask';
            state: GQUserUploadTaskState;
            id: string;
            approvers: Array<{
              __typename?: 'UserWithApprovalStatus';
              approvalStatus: GQApprovalStatus | null;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              };
            }>;
            datasource: {
              __typename?: 'Datasource';
              hasIntegration: boolean;
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
          }
        | { __typename: 'ValueMappingTask'; id: string }
        | { __typename: 'VendorMatchingTask'; id: string };
    }>;
  }>;
  organization: {
    __typename?: 'Organization';
    id: string;
    lifecycle: {
      __typename?: 'ClimateProgramLifecycle';
      completedStage: GQCompletedLifecycleStage;
      nextStage: GQNextLifecycleStage | null;
      kickoffBlurb: string | null;
    } | null;
    profile: {
      __typename?: 'OrgProfile';
      id: string;
      name: string | null;
      fiscalYearStartMonth: number | null;
      country: string | null;
      industry: string | null;
      isCompanyDataConfirmed: boolean | null;
      hasMeasuredFootprintOutsideOfWatershed: GQYesNoUnknown | null;
      lastNonWatershedMeasurementYearString: string | null;
      hasClimateCommitments: GQYesNoUnknown | null;
      climateCommitments: Array<GQCompanyClimateCommitmentKind> | null;
      otherClimateCommitment: string | null;
      climateGoals: Array<string> | null;
      climateMotivation: string | null;
      completedAt: Date | null;
      updatedAt: Date | null;
      completedBy: { __typename?: 'User'; id: string; name: string } | null;
      updatedBy: { __typename?: 'User'; id: string; name: string } | null;
    };
  };
  committedPlans?: {
    __typename?: 'PlanConnection';
    edges: Array<{
      __typename?: 'PlanEdge';
      node: {
        __typename?: 'Plan';
        id: string;
        name: string;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          interval: YMInterval;
          customIntensityConfigs: Array<{
            __typename?: 'CustomIntensityConfig';
            id: string;
            name: string;
            description: string;
            unit: string | null;
            humanReadable: boolean;
            quantityType: GQCustomIntensityConfigQuantityType;
            isSbtValidated: boolean | null;
          }>;
        };
        targets: Array<{
          __typename?: 'PlanTarget';
          id: string;
          createdAt: Date | null;
          description: string;
          reportDescription: string;
          customNotes: string;
          baseYear: YearMonth;
          intensityType: GQPlanTargetIntensityType;
          customIntensityConfigId: string | null;
          comparisonType: GQPlanTargetTargetComparisonType;
          reductionRate: GQTargetReductionRate;
          deletedAt: Date | null;
          assignedTo: string | null;
          parentTargetId: string | null;
          interimTargetDate: YearMonth | null;
          interimTargetValue: number | null;
          emissionsTargetCustom: {
            __typename?: 'SimpleTimeseries';
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          filters: {
            __typename?: 'FilterExpressionGroup';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitive';
              field: GQFilterFieldLegacy;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          };
        }>;
        carbonOffsets: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        carbonRemoval: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
      } | null;
    } | null>;
  };
  visiblePlans?: {
    __typename?: 'PlanConnection';
    edges: Array<{
      __typename?: 'PlanEdge';
      node: { __typename?: 'Plan'; id: string; name: string } | null;
    } | null>;
  };
};

export type GQI18nSandboxPageQueryVariables = Exact<{ [key: string]: never }>;

export type GQI18nSandboxPageQuery = {
  __typename?: 'Query';
  i18nSandbox: {
    __typename?: 'I18nSandbox';
    name: string;
    name2: string;
    name3: string;
  };
};

export type GQEacPurchaseProductWaitlistQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQEacPurchaseProductWaitlistQuery = {
  __typename?: 'Query';
  productWaitlistTypes: Array<GQProductWaitlistType>;
};

export type GQMarketplaceDocumentFieldsForPurchasesPageFragment = {
  __typename?: 'MarketplaceDocument';
  id: string;
  name: string;
  kind: GQMarketplaceDocumentKind;
  downloadUrl: string;
};

export type GQMarketplacePurchaseLineItemFieldsForPurchasesPageFragment = {
  __typename?: 'MarketplacePurchaseLineItem';
  id: string;
  amount: number;
  amountUnit: GQMarketplaceProjectArchetypeUnit;
  coverageInterval: YMInterval;
  unitCostUsdCents: number;
  deliveryDate: Date | null;
  isDelivered: boolean;
  archetype: {
    __typename?: 'MarketplaceProjectArchetype';
    id: string;
    name: string;
    bannerImageUrl: string | null;
    imageUrl: string | null;
    descriptionMd: string;
    certificateBlurb: string | null;
    kind: GQMarketplaceProjectArchetypeKind;
    summaryMd: string;
  } | null;
  project: {
    __typename?: 'MarketplaceProject';
    id: string;
    name: string;
    developer: {
      __typename?: 'MarketplaceDeveloper';
      id: string;
      name: string;
      imageUrl: string | null;
    } | null;
  } | null;
  documents: {
    __typename?: 'MarketplaceDocumentConnection';
    edges: Array<{
      __typename?: 'MarketplaceDocumentEdge';
      node: {
        __typename?: 'MarketplaceDocument';
        id: string;
        name: string;
        kind: GQMarketplaceDocumentKind;
        downloadUrl: string;
      } | null;
    } | null>;
  };
  eacMetadata: {
    __typename?: 'MarketplacePurchaseLineItemEacMetadata';
    id: string;
    countryWithEmissions: string;
    countryWithEacs: string;
    numEacsRequested: number;
    mwh: number;
    marketTco2e: number;
  } | null;
};

export type GQMarketplacePurchaseFieldsForPurchasesPageFragment = {
  __typename?: 'MarketplacePurchase';
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  totalCostUsdCents: number;
  customerStatus: GQCustomerStatus;
  offerExpiration: Date | null;
  lineItems: {
    __typename?: 'MarketplacePurchaseLineItemConnection';
    edges: Array<{
      __typename?: 'MarketplacePurchaseLineItemEdge';
      node: {
        __typename?: 'MarketplacePurchaseLineItem';
        id: string;
        amount: number;
        amountUnit: GQMarketplaceProjectArchetypeUnit;
        coverageInterval: YMInterval;
        unitCostUsdCents: number;
        deliveryDate: Date | null;
        isDelivered: boolean;
        archetype: {
          __typename?: 'MarketplaceProjectArchetype';
          id: string;
          name: string;
          bannerImageUrl: string | null;
          imageUrl: string | null;
          descriptionMd: string;
          certificateBlurb: string | null;
          kind: GQMarketplaceProjectArchetypeKind;
          summaryMd: string;
        } | null;
        project: {
          __typename?: 'MarketplaceProject';
          id: string;
          name: string;
          developer: {
            __typename?: 'MarketplaceDeveloper';
            id: string;
            name: string;
            imageUrl: string | null;
          } | null;
        } | null;
        documents: {
          __typename?: 'MarketplaceDocumentConnection';
          edges: Array<{
            __typename?: 'MarketplaceDocumentEdge';
            node: {
              __typename?: 'MarketplaceDocument';
              id: string;
              name: string;
              kind: GQMarketplaceDocumentKind;
              downloadUrl: string;
            } | null;
          } | null>;
        };
        eacMetadata: {
          __typename?: 'MarketplacePurchaseLineItemEacMetadata';
          id: string;
          countryWithEmissions: string;
          countryWithEacs: string;
          numEacsRequested: number;
          mwh: number;
          marketTco2e: number;
        } | null;
      } | null;
    } | null>;
  };
  documents: {
    __typename?: 'MarketplaceDocumentConnection';
    edges: Array<{
      __typename?: 'MarketplaceDocumentEdge';
      node: {
        __typename?: 'MarketplaceDocument';
        id: string;
        name: string;
        kind: GQMarketplaceDocumentKind;
        downloadUrl: string;
      } | null;
    } | null>;
  };
  portfolio: { __typename?: 'MarketplacePortfolio'; id: string } | null;
  eacMetadata: {
    __typename?: 'MarketplacePurchaseEacMetadata';
    id: string;
    interval: YMInterval;
    requestNotes: string | null;
    ghgCategoryIds: Array<string> | null;
    marketTco2e: number;
  } | null;
};

export type GQCurrencyExchangeRateForPurchasesPageFragment = {
  __typename?: 'CurrencyExchangeRate';
  id: string;
  currency: string;
  updatedAt: Date;
  value: number;
};

export type GQMarketplacePurchasesPageQueryVariables = Exact<{
  targetCurrency: Scalars['String']['input'];
}>;

export type GQMarketplacePurchasesPageQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  marketplacePurchases: {
    __typename?: 'MarketplacePurchaseConnection';
    edges: Array<{
      __typename?: 'MarketplacePurchaseEdge';
      node: {
        __typename?: 'MarketplacePurchase';
        id: string;
        name: string;
        createdAt: Date;
        updatedAt: Date;
        totalCostUsdCents: number;
        customerStatus: GQCustomerStatus;
        offerExpiration: Date | null;
        lineItems: {
          __typename?: 'MarketplacePurchaseLineItemConnection';
          edges: Array<{
            __typename?: 'MarketplacePurchaseLineItemEdge';
            node: {
              __typename?: 'MarketplacePurchaseLineItem';
              id: string;
              amount: number;
              amountUnit: GQMarketplaceProjectArchetypeUnit;
              coverageInterval: YMInterval;
              unitCostUsdCents: number;
              deliveryDate: Date | null;
              isDelivered: boolean;
              archetype: {
                __typename?: 'MarketplaceProjectArchetype';
                id: string;
                name: string;
                bannerImageUrl: string | null;
                imageUrl: string | null;
                descriptionMd: string;
                certificateBlurb: string | null;
                kind: GQMarketplaceProjectArchetypeKind;
                summaryMd: string;
              } | null;
              project: {
                __typename?: 'MarketplaceProject';
                id: string;
                name: string;
                developer: {
                  __typename?: 'MarketplaceDeveloper';
                  id: string;
                  name: string;
                  imageUrl: string | null;
                } | null;
              } | null;
              documents: {
                __typename?: 'MarketplaceDocumentConnection';
                edges: Array<{
                  __typename?: 'MarketplaceDocumentEdge';
                  node: {
                    __typename?: 'MarketplaceDocument';
                    id: string;
                    name: string;
                    kind: GQMarketplaceDocumentKind;
                    downloadUrl: string;
                  } | null;
                } | null>;
              };
              eacMetadata: {
                __typename?: 'MarketplacePurchaseLineItemEacMetadata';
                id: string;
                countryWithEmissions: string;
                countryWithEacs: string;
                numEacsRequested: number;
                mwh: number;
                marketTco2e: number;
              } | null;
            } | null;
          } | null>;
        };
        documents: {
          __typename?: 'MarketplaceDocumentConnection';
          edges: Array<{
            __typename?: 'MarketplaceDocumentEdge';
            node: {
              __typename?: 'MarketplaceDocument';
              id: string;
              name: string;
              kind: GQMarketplaceDocumentKind;
              downloadUrl: string;
            } | null;
          } | null>;
        };
        portfolio: { __typename?: 'MarketplacePortfolio'; id: string } | null;
        eacMetadata: {
          __typename?: 'MarketplacePurchaseEacMetadata';
          id: string;
          interval: YMInterval;
          requestNotes: string | null;
          ghgCategoryIds: Array<string> | null;
          marketTco2e: number;
        } | null;
      } | null;
    } | null>;
  };
  latestCurrencyExchangeRate: {
    __typename?: 'CurrencyExchangeRate';
    id: string;
    currency: string;
    updatedAt: Date;
    value: number;
  } | null;
};

export type GQMeasurementVerificationItemForMeasurementPageFragment = {
  __typename?: 'MeasurementVerificationItem';
  id: string;
  status: GQMeasurementVerificationItemStatus;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
    } | null;
  } | null;
};

export type GQMeasurementTaskObjectDatasetFieldsFragment = {
  __typename?: 'Dataset';
  id: string;
  name: string;
  materialCsrdStandards: Array<GQCsrdStandard>;
  datasources: Array<{ __typename?: 'Datasource'; id: string; name: string }>;
};

export type GQDatasourceForMeasurementPageFragment = {
  __typename?: 'Datasource';
  id: string;
  name: string;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    name: string;
    materialCsrdStandards: Array<GQCsrdStandard>;
  };
};

export type GQPresetCustomTagForMeasurementPageFragment = {
  __typename?: 'PresetCustomTag';
  ingestionCustomField: {
    __typename?: 'IngestionCustomField';
    id: string;
    name: string;
    type: GQIngestionCustomFieldType;
    possibleValues: Array<string> | null;
    description: string | null;
    appliesToAllDatasets: boolean;
    specificDatasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    }>;
  };
  ingestionCustomFieldDatasource: {
    __typename?: 'IngestionCustomFieldDatasource';
    id: string;
    datasourceId: string;
    ingestionCustomFieldId: string;
    ingestionCustomFieldValue: string;
  };
};

export type GQIngestionCustomFieldForMeasurementPageFragment = {
  __typename?: 'IngestionCustomField';
  id: string;
  name: string;
  type: GQIngestionCustomFieldType;
  possibleValues: Array<string> | null;
  description: string | null;
  appliesToAllDatasets: boolean;
  specificDatasets: Array<{
    __typename?: 'Dataset';
    id: string;
    name: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
    } | null;
  }>;
};

export type GQIngestionCustomFieldDatasourceForMeasurementPageFragment = {
  __typename?: 'IngestionCustomFieldDatasource';
  id: string;
  datasourceId: string;
  ingestionCustomFieldId: string;
  ingestionCustomFieldValue: string;
};

export type GQFinancialsReviewItemForAssignmentPermissionCheckFragment = {
  __typename?: 'FinancialsReviewItem';
  id: string;
  group: {
    __typename: 'FinancialsReviewGroup';
    id: string;
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      name: string;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        materialCsrdStandards: Array<GQCsrdStandard>;
      };
    }>;
  };
};

export type GQApproverFieldsForMeasurementPageFragment = {
  __typename?: 'UserWithApprovalStatus';
  approvalStatus: GQApprovalStatus | null;
  user: { __typename?: 'User'; id: string; name: string; displayName: string };
};

type GQMeasurementObjectForMeasurementPage_CustomEmissionsFactorsSetup_Fragment =
  { __typename: 'CustomEmissionsFactorsSetup'; id: string };

type GQMeasurementObjectForMeasurementPage_DataIssue_Fragment = {
  __typename: 'DataIssue';
  id: string;
  linkedObject:
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        group: {
          __typename: 'FinancialsReviewGroup';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            };
          }>;
        };
      }
    | {
        __typename: 'MeasurementVerificationItemQuestion';
        id: string;
        item: {
          __typename: 'MeasurementVerificationItem';
          id: string;
          maybeDataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              };
            }>;
          } | null;
        };
      }
    | {
        __typename: 'UserUploadTask';
        id: string;
        datasource: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          };
        };
      }
    | { __typename: 'ValueMappingTask' }
    | null;
  comments: {
    __typename?: 'CommentConnection';
    edges: Array<{
      __typename?: 'CommentEdge';
      node: {
        __typename?: 'Comment';
        id: string;
        createdAt: Date;
        isPublished: boolean;
        person:
          | {
              __typename?: 'User';
              id: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
            }
          | {
              __typename?: 'WatershedEmployee';
              id: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
            }
          | null;
      } | null;
    } | null>;
  };
};

type GQMeasurementObjectForMeasurementPage_FacilitiesSetup_Fragment = {
  __typename: 'FacilitiesSetup';
  id: string;
};

type GQMeasurementObjectForMeasurementPage_FinancialsReviewGroup_Fragment = {
  __typename: 'FinancialsReviewGroup';
  id: string;
  datasources: Array<{
    __typename?: 'Datasource';
    id: string;
    name: string;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      materialCsrdStandards: Array<GQCsrdStandard>;
    };
  }>;
};

type GQMeasurementObjectForMeasurementPage_FinancialsReviewItem_Fragment = {
  __typename: 'FinancialsReviewItem';
  id: string;
  group: {
    __typename: 'FinancialsReviewGroup';
    id: string;
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      name: string;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        materialCsrdStandards: Array<GQCsrdStandard>;
      };
    }>;
  };
};

type GQMeasurementObjectForMeasurementPage_FootprintQuestion_Fragment = {
  __typename: 'FootprintQuestion';
  id: string;
};

type GQMeasurementObjectForMeasurementPage_MeasurementDataMappingTask_Fragment =
  {
    __typename: 'MeasurementDataMappingTask';
    id: string;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      materialCsrdStandards: Array<GQCsrdStandard>;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
      }>;
    };
  };

type GQMeasurementObjectForMeasurementPage_MeasurementProject_Fragment = {
  __typename: 'MeasurementProject';
  id: string;
};

type GQMeasurementObjectForMeasurementPage_MeasurementProjectDataset_Fragment =
  {
    __typename: 'MeasurementProjectDataset';
    modelId: string | null;
    isCompleted: boolean;
    id: string;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      materialCsrdStandards: Array<GQCsrdStandard>;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
      }>;
    };
  };

type GQMeasurementObjectForMeasurementPage_MeasurementVerificationItem_Fragment =
  {
    __typename: 'MeasurementVerificationItem';
    id: string;
    mviStatus: GQMeasurementVerificationItemStatus;
    maybeDataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      materialCsrdStandards: Array<GQCsrdStandard>;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
      }>;
    } | null;
  };

type GQMeasurementObjectForMeasurementPage_UserUploadTask_Fragment = {
  __typename: 'UserUploadTask';
  state: GQUserUploadTaskState;
  id: string;
  approvers: Array<{
    __typename?: 'UserWithApprovalStatus';
    approvalStatus: GQApprovalStatus | null;
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    };
  }>;
  datasource: {
    __typename?: 'Datasource';
    hasIntegration: boolean;
    id: string;
    name: string;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      materialCsrdStandards: Array<GQCsrdStandard>;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    };
  };
};

type GQMeasurementObjectForMeasurementPage_ValueMappingTask_Fragment = {
  __typename: 'ValueMappingTask';
  id: string;
};

type GQMeasurementObjectForMeasurementPage_VendorMatchingTask_Fragment = {
  __typename: 'VendorMatchingTask';
  id: string;
};

export type GQMeasurementObjectForMeasurementPageFragment =
  | GQMeasurementObjectForMeasurementPage_CustomEmissionsFactorsSetup_Fragment
  | GQMeasurementObjectForMeasurementPage_DataIssue_Fragment
  | GQMeasurementObjectForMeasurementPage_FacilitiesSetup_Fragment
  | GQMeasurementObjectForMeasurementPage_FinancialsReviewGroup_Fragment
  | GQMeasurementObjectForMeasurementPage_FinancialsReviewItem_Fragment
  | GQMeasurementObjectForMeasurementPage_FootprintQuestion_Fragment
  | GQMeasurementObjectForMeasurementPage_MeasurementDataMappingTask_Fragment
  | GQMeasurementObjectForMeasurementPage_MeasurementProject_Fragment
  | GQMeasurementObjectForMeasurementPage_MeasurementProjectDataset_Fragment
  | GQMeasurementObjectForMeasurementPage_MeasurementVerificationItem_Fragment
  | GQMeasurementObjectForMeasurementPage_UserUploadTask_Fragment
  | GQMeasurementObjectForMeasurementPage_ValueMappingTask_Fragment
  | GQMeasurementObjectForMeasurementPage_VendorMatchingTask_Fragment;

export type GQDataIssueWithCommentsForMeasurementPageFragment = {
  __typename?: 'DataIssue';
  comments: {
    __typename?: 'CommentConnection';
    edges: Array<{
      __typename?: 'CommentEdge';
      node: {
        __typename?: 'Comment';
        id: string;
        createdAt: Date;
        isPublished: boolean;
        person:
          | {
              __typename?: 'User';
              id: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
            }
          | {
              __typename?: 'WatershedEmployee';
              id: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
            }
          | null;
      } | null;
    } | null>;
  };
};

type GQMeasurementTaskForMeasurementPage_MeasurementParentTask_Fragment = {
  __typename?: 'MeasurementParentTask';
  id: string;
  title: string;
  link: string | null;
  lockState: GQApprovalTargetLockState | null;
  isAssignedToWatershed: boolean;
  statusSimplified: GQTaskWatershedProcessStateSimplified;
  assignees: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
    didLatestEmailBounce: boolean;
  }>;
  approvers: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
  }>;
  object:
    | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
    | {
        __typename: 'DataIssue';
        id: string;
        linkedObject:
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              item: {
                __typename: 'MeasurementVerificationItem';
                id: string;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                } | null;
              };
            }
          | {
              __typename: 'UserUploadTask';
              id: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              };
            }
          | { __typename: 'ValueMappingTask' }
          | null;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      }
    | { __typename: 'FacilitiesSetup'; id: string }
    | {
        __typename: 'FinancialsReviewGroup';
        id: string;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          };
        }>;
      }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        group: {
          __typename: 'FinancialsReviewGroup';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            };
          }>;
        };
      }
    | { __typename: 'FootprintQuestion'; id: string }
    | {
        __typename: 'MeasurementDataMappingTask';
        id: string;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        };
      }
    | { __typename: 'MeasurementProject'; id: string }
    | {
        __typename: 'MeasurementProjectDataset';
        modelId: string | null;
        isCompleted: boolean;
        id: string;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        };
      }
    | {
        __typename: 'MeasurementVerificationItem';
        id: string;
        mviStatus: GQMeasurementVerificationItemStatus;
        maybeDataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        } | null;
      }
    | {
        __typename: 'UserUploadTask';
        state: GQUserUploadTaskState;
        id: string;
        approvers: Array<{
          __typename?: 'UserWithApprovalStatus';
          approvalStatus: GQApprovalStatus | null;
          user: {
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          };
        }>;
        datasource: {
          __typename?: 'Datasource';
          hasIntegration: boolean;
          id: string;
          name: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          };
        };
      }
    | { __typename: 'ValueMappingTask'; id: string }
    | { __typename: 'VendorMatchingTask'; id: string };
};

type GQMeasurementTaskForMeasurementPage_MeasurementTaskFlat_Fragment = {
  __typename?: 'MeasurementTaskFlat';
  id: string;
  title: string;
  link: string | null;
  lockState: GQApprovalTargetLockState | null;
  isAssignedToWatershed: boolean;
  statusSimplified: GQTaskWatershedProcessStateSimplified;
  assignees: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
    didLatestEmailBounce: boolean;
  }>;
  approvers: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
  }>;
  object:
    | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
    | {
        __typename: 'DataIssue';
        id: string;
        linkedObject:
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              item: {
                __typename: 'MeasurementVerificationItem';
                id: string;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                } | null;
              };
            }
          | {
              __typename: 'UserUploadTask';
              id: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              };
            }
          | { __typename: 'ValueMappingTask' }
          | null;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      }
    | { __typename: 'FacilitiesSetup'; id: string }
    | {
        __typename: 'FinancialsReviewGroup';
        id: string;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          };
        }>;
      }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        group: {
          __typename: 'FinancialsReviewGroup';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            };
          }>;
        };
      }
    | { __typename: 'FootprintQuestion'; id: string }
    | {
        __typename: 'MeasurementDataMappingTask';
        id: string;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        };
      }
    | { __typename: 'MeasurementProject'; id: string }
    | {
        __typename: 'MeasurementProjectDataset';
        modelId: string | null;
        isCompleted: boolean;
        id: string;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        };
      }
    | {
        __typename: 'MeasurementVerificationItem';
        id: string;
        mviStatus: GQMeasurementVerificationItemStatus;
        maybeDataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        } | null;
      }
    | {
        __typename: 'UserUploadTask';
        state: GQUserUploadTaskState;
        id: string;
        approvers: Array<{
          __typename?: 'UserWithApprovalStatus';
          approvalStatus: GQApprovalStatus | null;
          user: {
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          };
        }>;
        datasource: {
          __typename?: 'Datasource';
          hasIntegration: boolean;
          id: string;
          name: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          };
        };
      }
    | { __typename: 'ValueMappingTask'; id: string }
    | { __typename: 'VendorMatchingTask'; id: string };
};

export type GQMeasurementTaskForMeasurementPageFragment =
  | GQMeasurementTaskForMeasurementPage_MeasurementParentTask_Fragment
  | GQMeasurementTaskForMeasurementPage_MeasurementTaskFlat_Fragment;

export type GQMeasurementParentTaskForMeasurementPageFragment = {
  __typename?: 'MeasurementParentTask';
  taskGroup: string;
  id: string;
  title: string;
  link: string | null;
  lockState: GQApprovalTargetLockState | null;
  isAssignedToWatershed: boolean;
  statusSimplified: GQTaskWatershedProcessStateSimplified;
  subTasks: Array<
    | {
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        lockState: GQApprovalTargetLockState | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          didLatestEmailBounce: boolean;
        }>;
        approvers: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }
    | {
        __typename?: 'MeasurementTaskFlat';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        lockState: GQApprovalTargetLockState | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          didLatestEmailBounce: boolean;
        }>;
        approvers: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }
  >;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    name: string;
    materialCsrdStandards: Array<GQCsrdStandard>;
  } | null;
  assignees: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
    didLatestEmailBounce: boolean;
  }>;
  approvers: Array<{
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
  }>;
  object:
    | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
    | {
        __typename: 'DataIssue';
        id: string;
        linkedObject:
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItemQuestion';
              id: string;
              item: {
                __typename: 'MeasurementVerificationItem';
                id: string;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                } | null;
              };
            }
          | {
              __typename: 'UserUploadTask';
              id: string;
              datasource: {
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              };
            }
          | { __typename: 'ValueMappingTask' }
          | null;
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              createdAt: Date;
              isPublished: boolean;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      }
    | { __typename: 'FacilitiesSetup'; id: string }
    | {
        __typename: 'FinancialsReviewGroup';
        id: string;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          };
        }>;
      }
    | {
        __typename: 'FinancialsReviewItem';
        id: string;
        group: {
          __typename: 'FinancialsReviewGroup';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            };
          }>;
        };
      }
    | { __typename: 'FootprintQuestion'; id: string }
    | {
        __typename: 'MeasurementDataMappingTask';
        id: string;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        };
      }
    | { __typename: 'MeasurementProject'; id: string }
    | {
        __typename: 'MeasurementProjectDataset';
        modelId: string | null;
        isCompleted: boolean;
        id: string;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        };
      }
    | {
        __typename: 'MeasurementVerificationItem';
        id: string;
        mviStatus: GQMeasurementVerificationItemStatus;
        maybeDataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
          }>;
        } | null;
      }
    | {
        __typename: 'UserUploadTask';
        state: GQUserUploadTaskState;
        id: string;
        approvers: Array<{
          __typename?: 'UserWithApprovalStatus';
          approvalStatus: GQApprovalStatus | null;
          user: {
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          };
        }>;
        datasource: {
          __typename?: 'Datasource';
          hasIntegration: boolean;
          id: string;
          name: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          };
        };
      }
    | { __typename: 'ValueMappingTask'; id: string }
    | { __typename: 'VendorMatchingTask'; id: string };
};

export type GQDatasetWithStageForMeasurementPageFragment = {
  __typename?: 'DatasetWithStage';
  id: string;
  areAllTasksComplete: boolean;
  areUploadsComplete: boolean;
  isUsableInFootprint: boolean;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    name: string;
    materialCsrdStandards: Array<GQCsrdStandard>;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
    } | null;
  } | null;
};

export type GQMeasurementTimelineForMeasurementPageFragment = {
  __typename?: 'MeasurementTimeline';
  id: string;
  kickoff: Date;
  deadline: Date;
};

export type GQMeasurementProjectForMeasurementPageFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  active: boolean;
  setupAt: Date | null;
  completedAt: Date | null;
  footprintStatus: GQMeasurementProjectFootprintStatus;
  coverageInterval: YMInterval;
  deadline: Date | null;
  userUploadTasks: {
    __typename?: 'UserUploadTaskConnection';
    edges: Array<{
      __typename?: 'UserUploadTaskEdge';
      node: {
        __typename?: 'UserUploadTask';
        id: string;
        datasource: {
          __typename?: 'Datasource';
          id: string;
          name: string;
          userCanAccessDetails: boolean | null;
          userCanApproveDatasource: boolean | null;
          userCanManageDatasource: boolean | null;
          isUserConfirmed: boolean | null;
          hasIntegration: boolean;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          };
        };
        issues: {
          __typename?: 'DataIssueConnection';
          edges: Array<{
            __typename?: 'DataIssueEdge';
            node: {
              __typename?: 'DataIssue';
              id: string;
              state: GQDataIssueState;
              title: string;
            } | null;
          } | null>;
        } | null;
      } | null;
    } | null>;
  };
  measurementTimeline: {
    __typename?: 'MeasurementTimeline';
    id: string;
    kickoff: Date;
    deadline: Date;
  } | null;
  measurementTasks: Array<{
    __typename?: 'MeasurementParentTask';
    taskGroup: string;
    id: string;
    title: string;
    link: string | null;
    lockState: GQApprovalTargetLockState | null;
    isAssignedToWatershed: boolean;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
    subTasks: Array<
      | {
          __typename?: 'MeasurementParentTask';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          lockState: GQApprovalTargetLockState | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          approvers: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
      | {
          __typename?: 'MeasurementTaskFlat';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          lockState: GQApprovalTargetLockState | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          approvers: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
    >;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      materialCsrdStandards: Array<GQCsrdStandard>;
    } | null;
    assignees: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
      didLatestEmailBounce: boolean;
    }>;
    approvers: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    }>;
    object:
      | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
      | {
          __typename: 'DataIssue';
          id: string;
          linkedObject:
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItemQuestion';
                id: string;
                item: {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  } | null;
                };
              }
            | {
                __typename: 'UserUploadTask';
                id: string;
                datasource: {
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                };
              }
            | { __typename: 'ValueMappingTask' }
            | null;
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                createdAt: Date;
                isPublished: boolean;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        }
      | { __typename: 'FacilitiesSetup'; id: string }
      | {
          __typename: 'FinancialsReviewGroup';
          id: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            };
          }>;
        }
      | {
          __typename: 'FinancialsReviewItem';
          id: string;
          group: {
            __typename: 'FinancialsReviewGroup';
            id: string;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              };
            }>;
          };
        }
      | { __typename: 'FootprintQuestion'; id: string }
      | {
          __typename: 'MeasurementDataMappingTask';
          id: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          };
        }
      | { __typename: 'MeasurementProject'; id: string }
      | {
          __typename: 'MeasurementProjectDataset';
          modelId: string | null;
          isCompleted: boolean;
          id: string;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          };
        }
      | {
          __typename: 'MeasurementVerificationItem';
          id: string;
          mviStatus: GQMeasurementVerificationItemStatus;
          maybeDataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
            }>;
          } | null;
        }
      | {
          __typename: 'UserUploadTask';
          state: GQUserUploadTaskState;
          id: string;
          approvers: Array<{
            __typename?: 'UserWithApprovalStatus';
            approvalStatus: GQApprovalStatus | null;
            user: {
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            };
          }>;
          datasource: {
            __typename?: 'Datasource';
            hasIntegration: boolean;
            id: string;
            name: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            };
          };
        }
      | { __typename: 'ValueMappingTask'; id: string }
      | { __typename: 'VendorMatchingTask'; id: string };
  }>;
  datasetsWithStages: Array<{
    __typename?: 'DatasetWithStage';
    id: string;
    areAllTasksComplete: boolean;
    areUploadsComplete: boolean;
    isUsableInFootprint: boolean;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      materialCsrdStandards: Array<GQCsrdStandard>;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    } | null;
  }>;
  measurementVerificationItems: {
    __typename?: 'MeasurementVerificationItemConnection';
    edges: Array<{
      __typename?: 'MeasurementVerificationItemEdge';
      node: {
        __typename?: 'MeasurementVerificationItem';
        id: string;
        status: GQMeasurementVerificationItemStatus;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      } | null;
    }>;
  };
  climateProgramProjects: Array<{
    __typename: 'ClimateProgramProject';
    id: string;
    name: string;
    canonicalClimateProgramProject: {
      __typename: 'CanonicalClimateProgramProject';
      id: string;
      measurementObjectCopy: string | null;
      kind: GQClimateProgramProjectKind;
      routeType: string | null;
      name: string;
    } | null;
  }>;
};

export type GQClimateProgramProjectForMeasurementPageFragment = {
  __typename: 'ClimateProgramProject';
  id: string;
  name: string;
  canonicalClimateProgramProject: {
    __typename: 'CanonicalClimateProgramProject';
    id: string;
    measurementObjectCopy: string | null;
    kind: GQClimateProgramProjectKind;
    routeType: string | null;
    name: string;
  } | null;
};

export type GQDatasetForMeasurementPageFragment = {
  __typename?: 'Dataset';
  id: string;
  name: string;
  materialCsrdStandards: Array<GQCsrdStandard>;
};

export type GQDataIssueForMeasurementPageFragment = {
  __typename?: 'DataIssue';
  id: string;
  state: GQDataIssueState;
  title: string;
};

export type GQUserUploadTaskForMeasurementPageFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  datasource: {
    __typename?: 'Datasource';
    id: string;
    name: string;
    userCanAccessDetails: boolean | null;
    userCanApproveDatasource: boolean | null;
    userCanManageDatasource: boolean | null;
    isUserConfirmed: boolean | null;
    hasIntegration: boolean;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      materialCsrdStandards: Array<GQCsrdStandard>;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    };
  };
  issues: {
    __typename?: 'DataIssueConnection';
    edges: Array<{
      __typename?: 'DataIssueEdge';
      node: {
        __typename?: 'DataIssue';
        id: string;
        state: GQDataIssueState;
        title: string;
      } | null;
    } | null>;
  } | null;
};

export type GQMeasurementProjectPageIngestionCustomFieldFragment = {
  __typename?: 'IngestionCustomField';
  id: string;
  name: string;
  type: GQIngestionCustomFieldType;
  possibleValues: Array<string> | null;
  description: string | null;
  appliesToAllDatasets: boolean;
  specificDatasets: Array<{
    __typename?: 'Dataset';
    id: string;
    name: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
    } | null;
  }>;
};

export type GQMeasurementProjectPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQMeasurementProjectPageQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  userHasManageMeasurement: boolean;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    active: boolean;
    setupAt: Date | null;
    completedAt: Date | null;
    footprintStatus: GQMeasurementProjectFootprintStatus;
    coverageInterval: YMInterval;
    deadline: Date | null;
    userUploadTasks: {
      __typename?: 'UserUploadTaskConnection';
      edges: Array<{
        __typename?: 'UserUploadTaskEdge';
        node: {
          __typename?: 'UserUploadTask';
          id: string;
          datasource: {
            __typename?: 'Datasource';
            id: string;
            name: string;
            userCanAccessDetails: boolean | null;
            userCanApproveDatasource: boolean | null;
            userCanManageDatasource: boolean | null;
            isUserConfirmed: boolean | null;
            hasIntegration: boolean;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            };
          };
          issues: {
            __typename?: 'DataIssueConnection';
            edges: Array<{
              __typename?: 'DataIssueEdge';
              node: {
                __typename?: 'DataIssue';
                id: string;
                state: GQDataIssueState;
                title: string;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
    measurementTimeline: {
      __typename?: 'MeasurementTimeline';
      id: string;
      kickoff: Date;
      deadline: Date;
    } | null;
    measurementTasks: Array<{
      __typename?: 'MeasurementParentTask';
      taskGroup: string;
      id: string;
      title: string;
      link: string | null;
      lockState: GQApprovalTargetLockState | null;
      isAssignedToWatershed: boolean;
      statusSimplified: GQTaskWatershedProcessStateSimplified;
      subTasks: Array<
        | {
            __typename?: 'MeasurementParentTask';
            taskGroup: string;
            id: string;
            title: string;
            link: string | null;
            lockState: GQApprovalTargetLockState | null;
            isAssignedToWatershed: boolean;
            statusSimplified: GQTaskWatershedProcessStateSimplified;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            } | null;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
              didLatestEmailBounce: boolean;
            }>;
            approvers: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            object:
              | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
              | {
                  __typename: 'DataIssue';
                  id: string;
                  linkedObject:
                    | {
                        __typename: 'FinancialsReviewItem';
                        id: string;
                        group: {
                          __typename: 'FinancialsReviewGroup';
                          id: string;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        };
                      }
                    | {
                        __typename: 'MeasurementVerificationItemQuestion';
                        id: string;
                        item: {
                          __typename: 'MeasurementVerificationItem';
                          id: string;
                          maybeDataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          } | null;
                        };
                      }
                    | {
                        __typename: 'UserUploadTask';
                        id: string;
                        datasource: {
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        };
                      }
                    | { __typename: 'ValueMappingTask' }
                    | null;
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        createdAt: Date;
                        isPublished: boolean;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                }
              | { __typename: 'FacilitiesSetup'; id: string }
              | {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                }
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | { __typename: 'FootprintQuestion'; id: string }
              | {
                  __typename: 'MeasurementDataMappingTask';
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | { __typename: 'MeasurementProject'; id: string }
              | {
                  __typename: 'MeasurementProjectDataset';
                  modelId: string | null;
                  isCompleted: boolean;
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  mviStatus: GQMeasurementVerificationItemStatus;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  } | null;
                }
              | {
                  __typename: 'UserUploadTask';
                  state: GQUserUploadTaskState;
                  id: string;
                  approvers: Array<{
                    __typename?: 'UserWithApprovalStatus';
                    approvalStatus: GQApprovalStatus | null;
                    user: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                    };
                  }>;
                  datasource: {
                    __typename?: 'Datasource';
                    hasIntegration: boolean;
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      canonicalDataset: {
                        __typename?: 'CanonicalDataset';
                        id: string;
                        kind: GQCanonicalDatasetKind;
                      } | null;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask'; id: string }
              | { __typename: 'VendorMatchingTask'; id: string };
          }
        | {
            __typename?: 'MeasurementTaskFlat';
            taskGroup: string;
            id: string;
            title: string;
            link: string | null;
            lockState: GQApprovalTargetLockState | null;
            isAssignedToWatershed: boolean;
            statusSimplified: GQTaskWatershedProcessStateSimplified;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
            } | null;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
              didLatestEmailBounce: boolean;
            }>;
            approvers: Array<{
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            }>;
            object:
              | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
              | {
                  __typename: 'DataIssue';
                  id: string;
                  linkedObject:
                    | {
                        __typename: 'FinancialsReviewItem';
                        id: string;
                        group: {
                          __typename: 'FinancialsReviewGroup';
                          id: string;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        };
                      }
                    | {
                        __typename: 'MeasurementVerificationItemQuestion';
                        id: string;
                        item: {
                          __typename: 'MeasurementVerificationItem';
                          id: string;
                          maybeDataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          } | null;
                        };
                      }
                    | {
                        __typename: 'UserUploadTask';
                        id: string;
                        datasource: {
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        };
                      }
                    | { __typename: 'ValueMappingTask' }
                    | null;
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        createdAt: Date;
                        isPublished: boolean;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                }
              | { __typename: 'FacilitiesSetup'; id: string }
              | {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                }
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | { __typename: 'FootprintQuestion'; id: string }
              | {
                  __typename: 'MeasurementDataMappingTask';
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | { __typename: 'MeasurementProject'; id: string }
              | {
                  __typename: 'MeasurementProjectDataset';
                  modelId: string | null;
                  isCompleted: boolean;
                  id: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItem';
                  id: string;
                  mviStatus: GQMeasurementVerificationItemStatus;
                  maybeDataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                    }>;
                  } | null;
                }
              | {
                  __typename: 'UserUploadTask';
                  state: GQUserUploadTaskState;
                  id: string;
                  approvers: Array<{
                    __typename?: 'UserWithApprovalStatus';
                    approvalStatus: GQApprovalStatus | null;
                    user: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                    };
                  }>;
                  datasource: {
                    __typename?: 'Datasource';
                    hasIntegration: boolean;
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      canonicalDataset: {
                        __typename?: 'CanonicalDataset';
                        id: string;
                        kind: GQCanonicalDatasetKind;
                      } | null;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask'; id: string }
              | { __typename: 'VendorMatchingTask'; id: string };
          }
      >;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        materialCsrdStandards: Array<GQCsrdStandard>;
      } | null;
      assignees: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
        didLatestEmailBounce: boolean;
      }>;
      approvers: Array<{
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      }>;
      object:
        | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
        | {
            __typename: 'DataIssue';
            id: string;
            linkedObject:
              | {
                  __typename: 'FinancialsReviewItem';
                  id: string;
                  group: {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  };
                }
              | {
                  __typename: 'MeasurementVerificationItemQuestion';
                  id: string;
                  item: {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    } | null;
                  };
                }
              | {
                  __typename: 'UserUploadTask';
                  id: string;
                  datasource: {
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  };
                }
              | { __typename: 'ValueMappingTask' }
              | null;
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  createdAt: Date;
                  isPublished: boolean;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          }
        | { __typename: 'FacilitiesSetup'; id: string }
        | {
            __typename: 'FinancialsReviewGroup';
            id: string;
            datasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              };
            }>;
          }
        | {
            __typename: 'FinancialsReviewItem';
            id: string;
            group: {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            };
          }
        | { __typename: 'FootprintQuestion'; id: string }
        | {
            __typename: 'MeasurementDataMappingTask';
            id: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            };
          }
        | { __typename: 'MeasurementProject'; id: string }
        | {
            __typename: 'MeasurementProjectDataset';
            modelId: string | null;
            isCompleted: boolean;
            id: string;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            };
          }
        | {
            __typename: 'MeasurementVerificationItem';
            id: string;
            mviStatus: GQMeasurementVerificationItemStatus;
            maybeDataset: {
              __typename?: 'Dataset';
              id: string;
              name: string;
              materialCsrdStandards: Array<GQCsrdStandard>;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
              }>;
            } | null;
          }
        | {
            __typename: 'UserUploadTask';
            state: GQUserUploadTaskState;
            id: string;
            approvers: Array<{
              __typename?: 'UserWithApprovalStatus';
              approvalStatus: GQApprovalStatus | null;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              };
            }>;
            datasource: {
              __typename?: 'Datasource';
              hasIntegration: boolean;
              id: string;
              name: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
          }
        | { __typename: 'ValueMappingTask'; id: string }
        | { __typename: 'VendorMatchingTask'; id: string };
    }>;
    datasetsWithStages: Array<{
      __typename?: 'DatasetWithStage';
      id: string;
      areAllTasksComplete: boolean;
      areUploadsComplete: boolean;
      isUsableInFootprint: boolean;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        materialCsrdStandards: Array<GQCsrdStandard>;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      } | null;
    }>;
    measurementVerificationItems: {
      __typename?: 'MeasurementVerificationItemConnection';
      edges: Array<{
        __typename?: 'MeasurementVerificationItemEdge';
        node: {
          __typename?: 'MeasurementVerificationItem';
          id: string;
          status: GQMeasurementVerificationItemStatus;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          } | null;
        } | null;
      }>;
    };
    climateProgramProjects: Array<{
      __typename: 'ClimateProgramProject';
      id: string;
      name: string;
      canonicalClimateProgramProject: {
        __typename: 'CanonicalClimateProgramProject';
        id: string;
        measurementObjectCopy: string | null;
        kind: GQClimateProgramProjectKind;
        routeType: string | null;
        name: string;
      } | null;
    }>;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      status: GQMeasurementProjectStatus;
      createdAt: Date;
      completedAt: Date | null;
      coverageInterval: YMInterval;
      coverageStartDate: Date;
      coverageEndDate: Date;
      deadline: Date | null;
    }>;
    facilitiesMetadata: {
      __typename?: 'FacilitiesMetadata';
      buildingsDataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
      } | null;
      utilitiesDataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
      } | null;
    };
    ingestionCustomFields: Array<{
      __typename?: 'IngestionCustomField';
      id: string;
      name: string;
      type: GQIngestionCustomFieldType;
      possibleValues: Array<string> | null;
      description: string | null;
      appliesToAllDatasets: boolean;
      specificDatasets: Array<{
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      }>;
    }>;
  };
  latestFootprintAssemblyRun: {
    __typename?: 'FootprintAssemblyRun';
    id: string;
    status: GQFootprintAssemblyRunStatus;
  } | null;
};

export type GQPresetCustomTagsForMeasurementProjectPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQPresetCustomTagsForMeasurementProjectPageQuery = {
  __typename?: 'Query';
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    measurementTasks: Array<{
      __typename?: 'MeasurementParentTask';
      id: string;
      object:
        | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
        | { __typename: 'DataIssue'; id: string }
        | { __typename: 'FacilitiesSetup'; id: string }
        | { __typename: 'FinancialsReviewGroup'; id: string }
        | { __typename: 'FinancialsReviewItem'; id: string }
        | { __typename: 'FootprintQuestion'; id: string }
        | { __typename: 'MeasurementDataMappingTask'; id: string }
        | { __typename: 'MeasurementProject'; id: string }
        | { __typename: 'MeasurementProjectDataset'; id: string }
        | { __typename: 'MeasurementVerificationItem'; id: string }
        | {
            __typename: 'UserUploadTask';
            id: string;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              presetCustomTags: Array<{
                __typename?: 'PresetCustomTag';
                ingestionCustomField: {
                  __typename?: 'IngestionCustomField';
                  id: string;
                  name: string;
                  type: GQIngestionCustomFieldType;
                  possibleValues: Array<string> | null;
                  description: string | null;
                  appliesToAllDatasets: boolean;
                  specificDatasets: Array<{
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  }>;
                };
                ingestionCustomFieldDatasource: {
                  __typename?: 'IngestionCustomFieldDatasource';
                  id: string;
                  datasourceId: string;
                  ingestionCustomFieldId: string;
                  ingestionCustomFieldValue: string;
                };
              }>;
            };
          }
        | { __typename: 'ValueMappingTask'; id: string }
        | { __typename: 'VendorMatchingTask'; id: string };
    }>;
  };
};

export type GQUserUploadCountsForMeasurementProjectPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  enableUserUploadCountsOnMeasurementPage: Scalars['Boolean']['input'];
}>;

export type GQUserUploadCountsForMeasurementProjectPageQuery = {
  __typename?: 'Query';
  measurementProject?: {
    __typename?: 'MeasurementProject';
    id: string;
    userUploadTasks: {
      __typename?: 'UserUploadTaskConnection';
      edges: Array<{
        __typename?: 'UserUploadTaskEdge';
        node: {
          __typename?: 'UserUploadTask';
          id: string;
          userUploads: {
            __typename?: 'UserUploadConnection';
            edges: Array<{
              __typename?: 'UserUploadEdge';
              node: {
                __typename?: 'UserUpload';
                id: string;
                revisionRoot: string | null;
                userVisibleAttempt: {
                  __typename?: 'UserUploadAttempt';
                  id: string;
                  name: string;
                  remoteWritten: boolean;
                } | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
  };
};

export type GQCustomerTargetSchemaVersionForDataFormatFragment = {
  __typename?: 'CustomerTargetSchemaVersion';
  id: string;
  formattingNotesMd: string | null;
  orgDatasetExampleData: Array<{
    __typename?: 'IngestionExampleData';
    id: string;
    title: string;
    datasetId: string | null;
    columns: Array<{
      __typename?: 'IngestionExampleDataColumn';
      fieldName: string;
      title: string;
      description: string;
      examples: Array<string>;
      required: boolean;
      optionalToMap: boolean | null;
      defaultValue: any | null;
    }>;
    rows: Array<{
      __typename?: 'IngestionExampleDataRow';
      fields: Array<{
        __typename?: 'IngestionExampleDataRowField';
        fieldName: string;
        value: string;
      }>;
    }>;
  }>;
  transforms: Array<{
    __typename?: 'CustomerTargetSchemaTransform';
    id: string;
    businessActivityTypeName: string | null;
    businessActivityTypeVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
    } | null;
  }>;
};

export type GQCustomerTargetSchemaForDataFormatFragment = {
  __typename?: 'CustomerTargetSchema';
  id: string;
  name: string;
  utilityType: GQBuildingUtilityType | null;
  rank: number;
  latestPublishedVersion: {
    __typename?: 'CustomerTargetSchemaVersion';
    id: string;
    formattingNotesMd: string | null;
    orgDatasetExampleData: Array<{
      __typename?: 'IngestionExampleData';
      id: string;
      title: string;
      datasetId: string | null;
      columns: Array<{
        __typename?: 'IngestionExampleDataColumn';
        fieldName: string;
        title: string;
        description: string;
        examples: Array<string>;
        required: boolean;
        optionalToMap: boolean | null;
        defaultValue: any | null;
      }>;
      rows: Array<{
        __typename?: 'IngestionExampleDataRow';
        fields: Array<{
          __typename?: 'IngestionExampleDataRowField';
          fieldName: string;
          value: string;
        }>;
      }>;
    }>;
    transforms: Array<{
      __typename?: 'CustomerTargetSchemaTransform';
      id: string;
      businessActivityTypeName: string | null;
      businessActivityTypeVersion: {
        __typename?: 'BusinessActivityTypeVersion';
        id: string;
      } | null;
    }>;
  } | null;
};

export type GQCanonicalDatasetFieldsForImportFragment = {
  __typename?: 'CanonicalDataset';
  id: string;
  name: string;
  kind: GQCanonicalDatasetKind;
  instructions: {
    __typename?: 'InstructionsBundle';
    instructionsIntroMd: string | null;
    instructionsDataFormattingMd: string | null;
    sdiInstructionsIntroMd: string | null;
    sdiInstructionsDataFormattingMd: string | null;
    ghgScopes: Array<GQGhgScope> | null;
    instructionsSteps: Array<{
      __typename?: 'InstructionsStep';
      descriptionMd: string;
      publicUrl: string | null;
    }> | null;
    sdiInstructionsSteps: Array<{
      __typename?: 'SdiInstructionsStep';
      title: string;
      descriptionMd: string;
    }> | null;
    sdiInstructionsFaq: Array<{
      __typename?: 'SdiInstructionsFaqType';
      question: string;
      answerMd: string;
    }> | null;
  } | null;
  customerTargetSchemas: Array<{
    __typename?: 'CustomerTargetSchema';
    id: string;
    name: string;
    utilityType: GQBuildingUtilityType | null;
    rank: number;
    latestPublishedVersion: {
      __typename?: 'CustomerTargetSchemaVersion';
      id: string;
      formattingNotesMd: string | null;
      orgDatasetExampleData: Array<{
        __typename?: 'IngestionExampleData';
        id: string;
        title: string;
        datasetId: string | null;
        columns: Array<{
          __typename?: 'IngestionExampleDataColumn';
          fieldName: string;
          title: string;
          description: string;
          examples: Array<string>;
          required: boolean;
          optionalToMap: boolean | null;
          defaultValue: any | null;
        }>;
        rows: Array<{
          __typename?: 'IngestionExampleDataRow';
          fields: Array<{
            __typename?: 'IngestionExampleDataRowField';
            fieldName: string;
            value: string;
          }>;
        }>;
      }>;
      transforms: Array<{
        __typename?: 'CustomerTargetSchemaTransform';
        id: string;
        businessActivityTypeName: string | null;
        businessActivityTypeVersion: {
          __typename?: 'BusinessActivityTypeVersion';
          id: string;
        } | null;
      }>;
    } | null;
  }>;
};

export type GQInstructionsStepForImportInstructionsFragment = {
  __typename?: 'InstructionsStep';
  descriptionMd: string;
  publicUrl: string | null;
};

export type GQSdiInstructionsStepForImportInstructionsFragment = {
  __typename?: 'SdiInstructionsStep';
  title: string;
  descriptionMd: string;
};

export type GQSdiInstructionsFaqForImportInstructionsFragment = {
  __typename?: 'SdiInstructionsFaqType';
  question: string;
  answerMd: string;
};

export type GQInstructionsBundleForImportInstructionsFragment = {
  __typename?: 'InstructionsBundle';
  instructionsIntroMd: string | null;
  instructionsDataFormattingMd: string | null;
  sdiInstructionsIntroMd: string | null;
  sdiInstructionsDataFormattingMd: string | null;
  ghgScopes: Array<GQGhgScope> | null;
  instructionsSteps: Array<{
    __typename?: 'InstructionsStep';
    descriptionMd: string;
    publicUrl: string | null;
  }> | null;
  sdiInstructionsSteps: Array<{
    __typename?: 'SdiInstructionsStep';
    title: string;
    descriptionMd: string;
  }> | null;
  sdiInstructionsFaq: Array<{
    __typename?: 'SdiInstructionsFaqType';
    question: string;
    answerMd: string;
  }> | null;
};

export type GQClimateProgramProjectRequirementDatasetForDatasourceInstructionsFragment =
  {
    __typename?: 'ClimateProgramProjectRequirementDataset';
    id: string;
    additionalNotesMd: string | null;
    climateProgramProject: {
      __typename: 'ClimateProgramProject';
      name: string;
      id: string;
      canonicalClimateProgramProject: {
        __typename: 'CanonicalClimateProgramProject';
        id: string;
        kind: GQClimateProgramProjectKind;
        measurementObjectCopy: string | null;
        routeType: string | null;
        name: string;
      } | null;
    };
  };

export type GQIntegrationPartnerForDatasourcePageFragment = {
  __typename?: 'IntegrationPartner';
  id: string;
  name: string;
  logoUrl: string;
  externalId: string;
  dataType: string;
  isRequestOnly: boolean;
  applicableDatasources: Array<{
    __typename?: 'Datasource';
    id: string;
    name: string;
    hasIntegration: boolean;
    isUserConfirmed: boolean | null;
    activeIntegrationConnection: {
      __typename?: 'IntegrationConnection';
      id: string;
    } | null;
    dataset: { __typename?: 'Dataset'; id: string; name: string };
  }>;
  activeIntegrationConnections: Array<{
    __typename?: 'IntegrationConnection';
    id: string;
    additionalIdentifier: string | null;
  }>;
};

export type GQCanonicalDatasourceForDatasourcePageFragment = {
  __typename?: 'CanonicalDatasource';
  id: string;
  name: string;
  integrationPartner: {
    __typename?: 'IntegrationPartner';
    id: string;
    name: string;
    logoUrl: string;
    externalId: string;
    dataType: string;
    isRequestOnly: boolean;
    applicableDatasources: Array<{
      __typename?: 'Datasource';
      id: string;
      name: string;
      hasIntegration: boolean;
      isUserConfirmed: boolean | null;
      activeIntegrationConnection: {
        __typename?: 'IntegrationConnection';
        id: string;
      } | null;
      dataset: { __typename?: 'Dataset'; id: string; name: string };
    }>;
    activeIntegrationConnections: Array<{
      __typename?: 'IntegrationConnection';
      id: string;
      additionalIdentifier: string | null;
    }>;
  } | null;
};

export type GQUserUploadForDatasourceFragment = {
  __typename?: 'UserUpload';
  id: string;
  name: string;
  status: GQUserUploadStatus;
  processingMode: GQUserUploadProcessingMode | null;
  userVisibleErrorMessage: string | null;
  createdAt: Date;
  latestAdeIdForSDIFacilitiesFlow: string | null;
  description: string | null;
  revisionRoot: string | null;
  processingWorkflowId: string | null;
  isBeingValueMapped: boolean;
  actualUserUploadId: string | null;
  associatedUtilityCount: number;
  userUploadKind: GQUserUploadCandidateKind | null;
  userVisibleAttempt: {
    __typename?: 'UserUploadAttempt';
    id: string;
    name: string;
    size: number;
    remoteWritten: boolean;
    createdAt: Date;
    userUploadSource: GQUserUploadSource | null;
    uploader: { __typename?: 'User'; id: string; name: string } | null;
    userUploadedTables: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      sheetName: string | null;
      sheetIndex: number | null;
      errorMessage: string | null;
      errorType: string | null;
      numRows: number | null;
      status: GQUserUploadedTableStatus;
      schema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      uirSchema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      parseConfig: {
        __typename?: 'UserUploadedTableParseConfig';
        noHeader: boolean;
        skipTopNRows: number;
        skipBottomNRows: number;
        meltIdVars: Array<string | null> | null;
      } | null;
      roboCleanerResult: {
        __typename?: 'UserUploadedTableRoboCleanerResult';
        skipTopNRows: number;
      } | null;
      userReviewMetadata: {
        __typename?: 'UserUploadedTableUserReviewMetadata';
        headerRowIndex: number;
      } | null;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
      };
      dataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataUnshiftedPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
    }>;
    latestUutsIfRevisionExists: Array<{
      __typename?: 'UserUploadedTable';
      id: string;
      sheetName: string | null;
      sheetIndex: number | null;
      errorMessage: string | null;
      errorType: string | null;
      numRows: number | null;
      status: GQUserUploadedTableStatus;
      schema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      uirSchema: Array<{
        __typename?: 'UserUploadedTableSchemaColumn';
        index: number;
        kind: GQUserUploadedTableSchemaColumnKind;
        name: string;
        alias: string;
      }> | null;
      parseConfig: {
        __typename?: 'UserUploadedTableParseConfig';
        noHeader: boolean;
        skipTopNRows: number;
        skipBottomNRows: number;
        meltIdVars: Array<string | null> | null;
      } | null;
      roboCleanerResult: {
        __typename?: 'UserUploadedTableRoboCleanerResult';
        skipTopNRows: number;
      } | null;
      userReviewMetadata: {
        __typename?: 'UserUploadedTableUserReviewMetadata';
        headerRowIndex: number;
      } | null;
      parentFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
      };
      dataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
      rawDataUnshiftedPreview: {
        __typename?: 'UserUploadedTableDataPreview';
        dataUntyped: any;
      } | null;
    }> | null;
  } | null;
  userUploadTask: {
    __typename?: 'UserUploadTask';
    id: string;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
    };
  } | null;
  transformsIncludingDeleted: Array<{
    __typename?: 'CustomerTargetSchemaTransform';
    id: string;
    deletedAt: Date | null;
    sql: string;
    transformTarget: GQCustomerTargetSchemaTransformTarget;
    businessActivityTypeName: string | null;
    businessActivityTypeVersionId: string | null;
    businessActivityTypeVersion: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    } | null;
    runs: Array<{
      __typename?: 'CustomerTargetSchemaTransformRun';
      id: string;
      sql: string;
      error: string | null;
      createdAt: Date;
      transform: {
        __typename?: 'CustomerTargetSchemaTransform';
        id: string;
        transformTarget: GQCustomerTargetSchemaTransformTarget;
        businessActivityTypeName: string | null;
      };
      input: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        category: GQFileCategory;
        revisionRootFileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          name: string;
        } | null;
      };
      output: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      standardizedOutput: {
        __typename?: 'ParquetData';
        id: string;
        numRecords: number;
        ndjsonSignedUrl: string | null;
        ndjsonSize: number | null;
        ndjsonByteBoundaries: Array<number> | null;
        columns: Array<{
          __typename?: 'ParquetColumn';
          name: string;
          sqlType: string;
          minValue: any;
          maxValue: any;
          numNulls: number;
          approxDistinctValues: number;
          sampleValues: Array<any>;
        }>;
      } | null;
      warnings: Array<{
        __typename?: 'ValidationWarning';
        message: string;
        blocking: boolean;
      }>;
      runner: { __typename?: 'User'; id: string; name: string };
    }>;
  }>;
};

export type GQDataIssueWithCommentsFieldsFragment = {
  __typename?: 'DataIssue';
  id: string;
  title: string;
  description: string | null;
  state: GQDataIssueState;
  createdAt: Date;
  isCustomerInitiated: boolean;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    completedAt: Date | null;
  };
  comments: {
    __typename?: 'CommentConnection';
    edges: Array<{
      __typename?: 'CommentEdge';
      node: {
        __typename?: 'Comment';
        id: string;
        message: string;
        isPublished: boolean;
        createdAt: Date;
        person:
          | {
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
            }
          | {
              __typename?: 'WatershedEmployee';
              id: string;
              name: string;
              displayName: string;
              isWatershedEmployee: boolean;
              isWatershedContractor: boolean;
            }
          | null;
      } | null;
    } | null>;
  };
};

export type GQMeasurementProjectFieldsForDatasourcePageFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  active: boolean;
  coverageInterval: YMInterval;
  coverageStartDate: Date;
  coverageEndDate: Date;
  datasetsWithStages: Array<{
    __typename?: 'DatasetWithStage';
    id: string;
    areAllTasksComplete: boolean;
  }>;
  measurementTimeline: {
    __typename?: 'MeasurementTimeline';
    id: string;
    deadline: Date;
  } | null;
};

export type GQValueMappingTaskFieldsForDatasourcePageFragment = {
  __typename?: 'ValueMappingTask';
  id: string;
  mappingRuleId: string;
  status: GQTaskWatershedProcessState;
};

export type GQCtsFormWithResultsFieldsFragment = {
  __typename?: 'CtsForm';
  id: string;
  ctsVersionId: string;
  customerTargetSchemaId: string;
  datasetId: string;
  title: string | null;
  description: string | null;
  entries: Array<{
    __typename?: 'CtsFormEntry';
    id: string;
    data: any;
    createdBy: { __typename?: 'User'; id: string; displayName: string };
  }>;
};

export type GQUserUploadTaskFieldsForDatasourcePageFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  createdAt: Date;
  state: GQUserUploadTaskState;
  importState: GQDatasourceImportState;
  approvalStatus: GQApprovalStatus;
  isDatasetComplete: boolean | null;
  lockState: GQApprovalTargetLockState;
  assignees: Array<{
    __typename?: 'User';
    id: string;
    displayName: string;
    name: string;
  }>;
  assignee: {
    __typename?: 'User';
    id: string;
    displayName: string;
    name: string;
  } | null;
  measurementTask: {
    __typename?: 'MeasurementTaskFlat';
    id: string;
    status: GQTaskWatershedProcessState;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
  };
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    active: boolean;
    coverageInterval: YMInterval;
    coverageStartDate: Date;
    coverageEndDate: Date;
    datasetsWithStages: Array<{
      __typename?: 'DatasetWithStage';
      id: string;
      areAllTasksComplete: boolean;
    }>;
    measurementTimeline: {
      __typename?: 'MeasurementTimeline';
      id: string;
      deadline: Date;
    } | null;
  };
  datasetRequirements: Array<{
    __typename?: 'ClimateProgramProjectRequirementDataset';
    id: string;
    additionalNotesMd: string | null;
    climateProgramProject: {
      __typename: 'ClimateProgramProject';
      name: string;
      id: string;
      canonicalClimateProgramProject: {
        __typename: 'CanonicalClimateProgramProject';
        id: string;
        kind: GQClimateProgramProjectKind;
        measurementObjectCopy: string | null;
        routeType: string | null;
        name: string;
      } | null;
    };
  }>;
  userUploads: {
    __typename?: 'UserUploadConnection';
    edges: Array<{
      __typename?: 'UserUploadEdge';
      node: {
        __typename?: 'UserUpload';
        id: string;
        name: string;
        status: GQUserUploadStatus;
        processingMode: GQUserUploadProcessingMode | null;
        userVisibleErrorMessage: string | null;
        createdAt: Date;
        latestAdeIdForSDIFacilitiesFlow: string | null;
        description: string | null;
        revisionRoot: string | null;
        processingWorkflowId: string | null;
        isBeingValueMapped: boolean;
        actualUserUploadId: string | null;
        associatedUtilityCount: number;
        userUploadKind: GQUserUploadCandidateKind | null;
        userVisibleAttempt: {
          __typename?: 'UserUploadAttempt';
          id: string;
          name: string;
          size: number;
          remoteWritten: boolean;
          createdAt: Date;
          userUploadSource: GQUserUploadSource | null;
          uploader: { __typename?: 'User'; id: string; name: string } | null;
          userUploadedTables: Array<{
            __typename?: 'UserUploadedTable';
            id: string;
            sheetName: string | null;
            sheetIndex: number | null;
            errorMessage: string | null;
            errorType: string | null;
            numRows: number | null;
            status: GQUserUploadedTableStatus;
            schema: Array<{
              __typename?: 'UserUploadedTableSchemaColumn';
              index: number;
              kind: GQUserUploadedTableSchemaColumnKind;
              name: string;
              alias: string;
            }> | null;
            uirSchema: Array<{
              __typename?: 'UserUploadedTableSchemaColumn';
              index: number;
              kind: GQUserUploadedTableSchemaColumnKind;
              name: string;
              alias: string;
            }> | null;
            parseConfig: {
              __typename?: 'UserUploadedTableParseConfig';
              noHeader: boolean;
              skipTopNRows: number;
              skipBottomNRows: number;
              meltIdVars: Array<string | null> | null;
            } | null;
            roboCleanerResult: {
              __typename?: 'UserUploadedTableRoboCleanerResult';
              skipTopNRows: number;
            } | null;
            userReviewMetadata: {
              __typename?: 'UserUploadedTableUserReviewMetadata';
              headerRowIndex: number;
            } | null;
            parentFileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            };
            dataPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
            rawDataPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
            rawDataUnshiftedPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
          }>;
          latestUutsIfRevisionExists: Array<{
            __typename?: 'UserUploadedTable';
            id: string;
            sheetName: string | null;
            sheetIndex: number | null;
            errorMessage: string | null;
            errorType: string | null;
            numRows: number | null;
            status: GQUserUploadedTableStatus;
            schema: Array<{
              __typename?: 'UserUploadedTableSchemaColumn';
              index: number;
              kind: GQUserUploadedTableSchemaColumnKind;
              name: string;
              alias: string;
            }> | null;
            uirSchema: Array<{
              __typename?: 'UserUploadedTableSchemaColumn';
              index: number;
              kind: GQUserUploadedTableSchemaColumnKind;
              name: string;
              alias: string;
            }> | null;
            parseConfig: {
              __typename?: 'UserUploadedTableParseConfig';
              noHeader: boolean;
              skipTopNRows: number;
              skipBottomNRows: number;
              meltIdVars: Array<string | null> | null;
            } | null;
            roboCleanerResult: {
              __typename?: 'UserUploadedTableRoboCleanerResult';
              skipTopNRows: number;
            } | null;
            userReviewMetadata: {
              __typename?: 'UserUploadedTableUserReviewMetadata';
              headerRowIndex: number;
            } | null;
            parentFileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            };
            dataPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
            rawDataPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
            rawDataUnshiftedPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
          }> | null;
        } | null;
        userUploadTask: {
          __typename?: 'UserUploadTask';
          id: string;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
          };
        } | null;
        transformsIncludingDeleted: Array<{
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          deletedAt: Date | null;
          sql: string;
          transformTarget: GQCustomerTargetSchemaTransformTarget;
          businessActivityTypeName: string | null;
          businessActivityTypeVersionId: string | null;
          businessActivityTypeVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
            name: string;
          } | null;
          runs: Array<{
            __typename?: 'CustomerTargetSchemaTransformRun';
            id: string;
            sql: string;
            error: string | null;
            createdAt: Date;
            transform: {
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              transformTarget: GQCustomerTargetSchemaTransformTarget;
              businessActivityTypeName: string | null;
            };
            input: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              category: GQFileCategory;
              revisionRootFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              } | null;
            };
            output: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            standardizedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            warnings: Array<{
              __typename?: 'ValidationWarning';
              message: string;
              blocking: boolean;
            }>;
            runner: { __typename?: 'User'; id: string; name: string };
          }>;
        }>;
      } | null;
    } | null>;
  } | null;
  issues: {
    __typename?: 'DataIssueConnection';
    edges: Array<{
      __typename?: 'DataIssueEdge';
      node: {
        __typename?: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        createdAt: Date;
        isCustomerInitiated: boolean;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          completedAt: Date | null;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              isPublished: boolean;
              createdAt: Date;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
  valueMappingTask: {
    __typename?: 'ValueMappingTask';
    id: string;
    mappingRuleId: string;
  } | null;
  valueMappingTasks: Array<{
    __typename?: 'ValueMappingTask';
    id: string;
    mappingRuleId: string;
    status: GQTaskWatershedProcessState;
  }>;
  approvers: Array<{
    __typename?: 'UserWithApprovalStatus';
    approvalStatus: GQApprovalStatus | null;
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    };
  }>;
  ctsForm: {
    __typename?: 'CtsForm';
    id: string;
    ctsVersionId: string;
    customerTargetSchemaId: string;
    datasetId: string;
    title: string | null;
    description: string | null;
    entries: Array<{
      __typename?: 'CtsFormEntry';
      id: string;
      data: any;
      createdBy: { __typename?: 'User'; id: string; displayName: string };
    }>;
  } | null;
};

export type GQDatasourceFieldsForDatasourcePageFragment = {
  __typename?: 'Datasource';
  id: string;
  name: string;
  isUserConfirmed: boolean | null;
  hasIntegrationOption: boolean;
  hasIntegration: boolean;
  customerTargetSchemaIds: Array<string> | null;
  importInstructionsMd: string | null;
  refreshCadence: GQDatasetCadence;
  hasApiAccess: boolean;
  notesFromWatershedMd: string | null;
  activeIntegrationConnection: {
    __typename?: 'IntegrationConnection';
    id: string;
    additionalIdentifier: string | null;
  } | null;
  instructions: {
    __typename?: 'InstructionsBundle';
    instructionsIntroMd: string | null;
    instructionsDataFormattingMd: string | null;
    sdiInstructionsIntroMd: string | null;
    sdiInstructionsDataFormattingMd: string | null;
    ghgScopes: Array<GQGhgScope> | null;
    instructionsSteps: Array<{
      __typename?: 'InstructionsStep';
      descriptionMd: string;
      publicUrl: string | null;
    }> | null;
    sdiInstructionsSteps: Array<{
      __typename?: 'SdiInstructionsStep';
      title: string;
      descriptionMd: string;
    }> | null;
    sdiInstructionsFaq: Array<{
      __typename?: 'SdiInstructionsFaqType';
      question: string;
      answerMd: string;
    }> | null;
  } | null;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    name: string;
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      name: string;
      customerTargetSchemaIds: Array<string> | null;
    }>;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      name: string;
      kind: GQCanonicalDatasetKind;
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsIntroMd: string | null;
        instructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
      customerTargetSchemas: Array<{
        __typename?: 'CustomerTargetSchema';
        id: string;
        name: string;
        utilityType: GQBuildingUtilityType | null;
        rank: number;
        latestPublishedVersion: {
          __typename?: 'CustomerTargetSchemaVersion';
          id: string;
          formattingNotesMd: string | null;
          orgDatasetExampleData: Array<{
            __typename?: 'IngestionExampleData';
            id: string;
            title: string;
            datasetId: string | null;
            columns: Array<{
              __typename?: 'IngestionExampleDataColumn';
              fieldName: string;
              title: string;
              description: string;
              examples: Array<string>;
              required: boolean;
              optionalToMap: boolean | null;
              defaultValue: any | null;
            }>;
            rows: Array<{
              __typename?: 'IngestionExampleDataRow';
              fields: Array<{
                __typename?: 'IngestionExampleDataRowField';
                fieldName: string;
                value: string;
              }>;
            }>;
          }>;
          transforms: Array<{
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            businessActivityTypeName: string | null;
            businessActivityTypeVersion: {
              __typename?: 'BusinessActivityTypeVersion';
              id: string;
            } | null;
          }>;
        } | null;
      }>;
    } | null;
    customExampleData: {
      __typename?: 'TabularData';
      cells: Array<Array<any>>;
    } | null;
  };
  canonicalDatasource: {
    __typename?: 'CanonicalDatasource';
    id: string;
    name: string;
    integrationPartner: {
      __typename?: 'IntegrationPartner';
      id: string;
      name: string;
      logoUrl: string;
      externalId: string;
      dataType: string;
      isRequestOnly: boolean;
      applicableDatasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
        hasIntegration: boolean;
        isUserConfirmed: boolean | null;
        activeIntegrationConnection: {
          __typename?: 'IntegrationConnection';
          id: string;
        } | null;
        dataset: { __typename?: 'Dataset'; id: string; name: string };
      }>;
      activeIntegrationConnections: Array<{
        __typename?: 'IntegrationConnection';
        id: string;
        additionalIdentifier: string | null;
      }>;
    } | null;
  } | null;
  userUploadTasks: {
    __typename?: 'UserUploadTaskConnection';
    edges: Array<{
      __typename?: 'UserUploadTaskEdge';
      node: {
        __typename?: 'UserUploadTask';
        id: string;
        createdAt: Date;
        state: GQUserUploadTaskState;
        importState: GQDatasourceImportState;
        approvalStatus: GQApprovalStatus;
        isDatasetComplete: boolean | null;
        lockState: GQApprovalTargetLockState;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          displayName: string;
          name: string;
        }>;
        assignee: {
          __typename?: 'User';
          id: string;
          displayName: string;
          name: string;
        } | null;
        measurementTask: {
          __typename?: 'MeasurementTaskFlat';
          id: string;
          status: GQTaskWatershedProcessState;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
        };
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          name: string;
          active: boolean;
          coverageInterval: YMInterval;
          coverageStartDate: Date;
          coverageEndDate: Date;
          datasetsWithStages: Array<{
            __typename?: 'DatasetWithStage';
            id: string;
            areAllTasksComplete: boolean;
          }>;
          measurementTimeline: {
            __typename?: 'MeasurementTimeline';
            id: string;
            deadline: Date;
          } | null;
        };
        datasetRequirements: Array<{
          __typename?: 'ClimateProgramProjectRequirementDataset';
          id: string;
          additionalNotesMd: string | null;
          climateProgramProject: {
            __typename: 'ClimateProgramProject';
            name: string;
            id: string;
            canonicalClimateProgramProject: {
              __typename: 'CanonicalClimateProgramProject';
              id: string;
              kind: GQClimateProgramProjectKind;
              measurementObjectCopy: string | null;
              routeType: string | null;
              name: string;
            } | null;
          };
        }>;
        userUploads: {
          __typename?: 'UserUploadConnection';
          edges: Array<{
            __typename?: 'UserUploadEdge';
            node: {
              __typename?: 'UserUpload';
              id: string;
              name: string;
              status: GQUserUploadStatus;
              processingMode: GQUserUploadProcessingMode | null;
              userVisibleErrorMessage: string | null;
              createdAt: Date;
              latestAdeIdForSDIFacilitiesFlow: string | null;
              description: string | null;
              revisionRoot: string | null;
              processingWorkflowId: string | null;
              isBeingValueMapped: boolean;
              actualUserUploadId: string | null;
              associatedUtilityCount: number;
              userUploadKind: GQUserUploadCandidateKind | null;
              userVisibleAttempt: {
                __typename?: 'UserUploadAttempt';
                id: string;
                name: string;
                size: number;
                remoteWritten: boolean;
                createdAt: Date;
                userUploadSource: GQUserUploadSource | null;
                uploader: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                } | null;
                userUploadedTables: Array<{
                  __typename?: 'UserUploadedTable';
                  id: string;
                  sheetName: string | null;
                  sheetIndex: number | null;
                  errorMessage: string | null;
                  errorType: string | null;
                  numRows: number | null;
                  status: GQUserUploadedTableStatus;
                  schema: Array<{
                    __typename?: 'UserUploadedTableSchemaColumn';
                    index: number;
                    kind: GQUserUploadedTableSchemaColumnKind;
                    name: string;
                    alias: string;
                  }> | null;
                  uirSchema: Array<{
                    __typename?: 'UserUploadedTableSchemaColumn';
                    index: number;
                    kind: GQUserUploadedTableSchemaColumnKind;
                    name: string;
                    alias: string;
                  }> | null;
                  parseConfig: {
                    __typename?: 'UserUploadedTableParseConfig';
                    noHeader: boolean;
                    skipTopNRows: number;
                    skipBottomNRows: number;
                    meltIdVars: Array<string | null> | null;
                  } | null;
                  roboCleanerResult: {
                    __typename?: 'UserUploadedTableRoboCleanerResult';
                    skipTopNRows: number;
                  } | null;
                  userReviewMetadata: {
                    __typename?: 'UserUploadedTableUserReviewMetadata';
                    headerRowIndex: number;
                  } | null;
                  parentFileMetadata: {
                    __typename?: 'FileMetadata';
                    id: string;
                    name: string;
                  };
                  dataPreview: {
                    __typename?: 'UserUploadedTableDataPreview';
                    dataUntyped: any;
                  } | null;
                  rawDataPreview: {
                    __typename?: 'UserUploadedTableDataPreview';
                    dataUntyped: any;
                  } | null;
                  rawDataUnshiftedPreview: {
                    __typename?: 'UserUploadedTableDataPreview';
                    dataUntyped: any;
                  } | null;
                }>;
                latestUutsIfRevisionExists: Array<{
                  __typename?: 'UserUploadedTable';
                  id: string;
                  sheetName: string | null;
                  sheetIndex: number | null;
                  errorMessage: string | null;
                  errorType: string | null;
                  numRows: number | null;
                  status: GQUserUploadedTableStatus;
                  schema: Array<{
                    __typename?: 'UserUploadedTableSchemaColumn';
                    index: number;
                    kind: GQUserUploadedTableSchemaColumnKind;
                    name: string;
                    alias: string;
                  }> | null;
                  uirSchema: Array<{
                    __typename?: 'UserUploadedTableSchemaColumn';
                    index: number;
                    kind: GQUserUploadedTableSchemaColumnKind;
                    name: string;
                    alias: string;
                  }> | null;
                  parseConfig: {
                    __typename?: 'UserUploadedTableParseConfig';
                    noHeader: boolean;
                    skipTopNRows: number;
                    skipBottomNRows: number;
                    meltIdVars: Array<string | null> | null;
                  } | null;
                  roboCleanerResult: {
                    __typename?: 'UserUploadedTableRoboCleanerResult';
                    skipTopNRows: number;
                  } | null;
                  userReviewMetadata: {
                    __typename?: 'UserUploadedTableUserReviewMetadata';
                    headerRowIndex: number;
                  } | null;
                  parentFileMetadata: {
                    __typename?: 'FileMetadata';
                    id: string;
                    name: string;
                  };
                  dataPreview: {
                    __typename?: 'UserUploadedTableDataPreview';
                    dataUntyped: any;
                  } | null;
                  rawDataPreview: {
                    __typename?: 'UserUploadedTableDataPreview';
                    dataUntyped: any;
                  } | null;
                  rawDataUnshiftedPreview: {
                    __typename?: 'UserUploadedTableDataPreview';
                    dataUntyped: any;
                  } | null;
                }> | null;
              } | null;
              userUploadTask: {
                __typename?: 'UserUploadTask';
                id: string;
                measurementProject: {
                  __typename?: 'MeasurementProject';
                  id: string;
                  name: string;
                };
              } | null;
              transformsIncludingDeleted: Array<{
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                deletedAt: Date | null;
                sql: string;
                transformTarget: GQCustomerTargetSchemaTransformTarget;
                businessActivityTypeName: string | null;
                businessActivityTypeVersionId: string | null;
                businessActivityTypeVersion: {
                  __typename?: 'BusinessActivityTypeVersion';
                  id: string;
                  name: string;
                } | null;
                runs: Array<{
                  __typename?: 'CustomerTargetSchemaTransformRun';
                  id: string;
                  sql: string;
                  error: string | null;
                  createdAt: Date;
                  transform: {
                    __typename?: 'CustomerTargetSchemaTransform';
                    id: string;
                    transformTarget: GQCustomerTargetSchemaTransformTarget;
                    businessActivityTypeName: string | null;
                  };
                  input: {
                    __typename?: 'FileMetadata';
                    id: string;
                    name: string;
                    category: GQFileCategory;
                    revisionRootFileMetadata: {
                      __typename?: 'FileMetadata';
                      id: string;
                      name: string;
                    } | null;
                  };
                  output: {
                    __typename?: 'ParquetData';
                    id: string;
                    numRecords: number;
                    ndjsonSignedUrl: string | null;
                    ndjsonSize: number | null;
                    ndjsonByteBoundaries: Array<number> | null;
                    columns: Array<{
                      __typename?: 'ParquetColumn';
                      name: string;
                      sqlType: string;
                      minValue: any;
                      maxValue: any;
                      numNulls: number;
                      approxDistinctValues: number;
                      sampleValues: Array<any>;
                    }>;
                  } | null;
                  standardizedOutput: {
                    __typename?: 'ParquetData';
                    id: string;
                    numRecords: number;
                    ndjsonSignedUrl: string | null;
                    ndjsonSize: number | null;
                    ndjsonByteBoundaries: Array<number> | null;
                    columns: Array<{
                      __typename?: 'ParquetColumn';
                      name: string;
                      sqlType: string;
                      minValue: any;
                      maxValue: any;
                      numNulls: number;
                      approxDistinctValues: number;
                      sampleValues: Array<any>;
                    }>;
                  } | null;
                  warnings: Array<{
                    __typename?: 'ValidationWarning';
                    message: string;
                    blocking: boolean;
                  }>;
                  runner: { __typename?: 'User'; id: string; name: string };
                }>;
              }>;
            } | null;
          } | null>;
        } | null;
        issues: {
          __typename?: 'DataIssueConnection';
          edges: Array<{
            __typename?: 'DataIssueEdge';
            node: {
              __typename?: 'DataIssue';
              id: string;
              title: string;
              description: string | null;
              state: GQDataIssueState;
              createdAt: Date;
              isCustomerInitiated: boolean;
              measurementProject: {
                __typename?: 'MeasurementProject';
                id: string;
                completedAt: Date | null;
              };
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    message: string;
                    isPublished: boolean;
                    createdAt: Date;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          name: string;
                          displayName: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            } | null;
          } | null>;
        } | null;
        valueMappingTask: {
          __typename?: 'ValueMappingTask';
          id: string;
          mappingRuleId: string;
        } | null;
        valueMappingTasks: Array<{
          __typename?: 'ValueMappingTask';
          id: string;
          mappingRuleId: string;
          status: GQTaskWatershedProcessState;
        }>;
        approvers: Array<{
          __typename?: 'UserWithApprovalStatus';
          approvalStatus: GQApprovalStatus | null;
          user: {
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          };
        }>;
        ctsForm: {
          __typename?: 'CtsForm';
          id: string;
          ctsVersionId: string;
          customerTargetSchemaId: string;
          datasetId: string;
          title: string | null;
          description: string | null;
          entries: Array<{
            __typename?: 'CtsFormEntry';
            id: string;
            data: any;
            createdBy: { __typename?: 'User'; id: string; displayName: string };
          }>;
        } | null;
      } | null;
    } | null>;
  };
  presetCustomTags: Array<{
    __typename?: 'PresetCustomTag';
    ingestionCustomField: {
      __typename?: 'IngestionCustomField';
      id: string;
      name: string;
      type: GQIngestionCustomFieldType;
      possibleValues: Array<string> | null;
      description: string | null;
      appliesToAllDatasets: boolean;
      specificDatasets: Array<{
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      }>;
    };
    ingestionCustomFieldDatasource: {
      __typename?: 'IngestionCustomFieldDatasource';
      id: string;
      datasourceId: string;
      ingestionCustomFieldId: string;
      ingestionCustomFieldValue: string;
    };
  }>;
  presetOrgUnits: Array<{
    __typename?: 'PresetOrgUnit';
    orgUnit: { __typename?: 'OrgUnit'; id: string; name: string };
    orgUnitDatasource: { __typename?: 'OrgUnitDatasource'; id: string };
  }>;
};

export type GQPresetCustomTagForDatasourcePageFragment = {
  __typename?: 'PresetCustomTag';
  ingestionCustomField: {
    __typename?: 'IngestionCustomField';
    id: string;
    name: string;
    type: GQIngestionCustomFieldType;
    possibleValues: Array<string> | null;
    description: string | null;
    appliesToAllDatasets: boolean;
    specificDatasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    }>;
  };
  ingestionCustomFieldDatasource: {
    __typename?: 'IngestionCustomFieldDatasource';
    id: string;
    datasourceId: string;
    ingestionCustomFieldId: string;
    ingestionCustomFieldValue: string;
  };
};

export type GQPresetOrgUnitForDatasourcePageFragment = {
  __typename?: 'PresetOrgUnit';
  orgUnit: { __typename?: 'OrgUnit'; id: string; name: string };
  orgUnitDatasource: { __typename?: 'OrgUnitDatasource'; id: string };
};

export type GQIngestionCustomFieldForDatasourcePageFragment = {
  __typename?: 'IngestionCustomField';
  id: string;
  name: string;
  type: GQIngestionCustomFieldType;
  possibleValues: Array<string> | null;
  description: string | null;
  appliesToAllDatasets: boolean;
  specificDatasets: Array<{
    __typename?: 'Dataset';
    id: string;
    name: string;
    canonicalDataset: {
      __typename?: 'CanonicalDataset';
      id: string;
      kind: GQCanonicalDatasetKind;
    } | null;
  }>;
};

export type GQIngestionCustomFieldDatasourceForDatasourcePageFragment = {
  __typename?: 'IngestionCustomFieldDatasource';
  id: string;
  datasourceId: string;
  ingestionCustomFieldId: string;
  ingestionCustomFieldValue: string;
};

export type GQOrganizationCustomFieldsForDatasourcePageFragment = {
  __typename?: 'Organization';
  id: string;
  ingestionCustomFields: Array<{
    __typename?: 'IngestionCustomField';
    id: string;
    name: string;
    type: GQIngestionCustomFieldType;
    possibleValues: Array<string> | null;
    description: string | null;
    appliesToAllDatasets: boolean;
    specificDatasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
      } | null;
    }>;
  }>;
};

export type GQUserUploadTaskWithDatasourceFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  createdAt: Date;
  state: GQUserUploadTaskState;
  importState: GQDatasourceImportState;
  approvalStatus: GQApprovalStatus;
  isDatasetComplete: boolean | null;
  lockState: GQApprovalTargetLockState;
  datasource: {
    __typename?: 'Datasource';
    id: string;
    name: string;
    isUserConfirmed: boolean | null;
    hasIntegrationOption: boolean;
    hasIntegration: boolean;
    customerTargetSchemaIds: Array<string> | null;
    importInstructionsMd: string | null;
    refreshCadence: GQDatasetCadence;
    hasApiAccess: boolean;
    notesFromWatershedMd: string | null;
    activeIntegrationConnection: {
      __typename?: 'IntegrationConnection';
      id: string;
      additionalIdentifier: string | null;
    } | null;
    instructions: {
      __typename?: 'InstructionsBundle';
      instructionsIntroMd: string | null;
      instructionsDataFormattingMd: string | null;
      sdiInstructionsIntroMd: string | null;
      sdiInstructionsDataFormattingMd: string | null;
      ghgScopes: Array<GQGhgScope> | null;
      instructionsSteps: Array<{
        __typename?: 'InstructionsStep';
        descriptionMd: string;
        publicUrl: string | null;
      }> | null;
      sdiInstructionsSteps: Array<{
        __typename?: 'SdiInstructionsStep';
        title: string;
        descriptionMd: string;
      }> | null;
      sdiInstructionsFaq: Array<{
        __typename?: 'SdiInstructionsFaqType';
        question: string;
        answerMd: string;
      }> | null;
    } | null;
    dataset: {
      __typename?: 'Dataset';
      id: string;
      name: string;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
        customerTargetSchemaIds: Array<string> | null;
      }>;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        name: string;
        kind: GQCanonicalDatasetKind;
        instructions: {
          __typename?: 'InstructionsBundle';
          instructionsIntroMd: string | null;
          instructionsDataFormattingMd: string | null;
          sdiInstructionsIntroMd: string | null;
          sdiInstructionsDataFormattingMd: string | null;
          ghgScopes: Array<GQGhgScope> | null;
          instructionsSteps: Array<{
            __typename?: 'InstructionsStep';
            descriptionMd: string;
            publicUrl: string | null;
          }> | null;
          sdiInstructionsSteps: Array<{
            __typename?: 'SdiInstructionsStep';
            title: string;
            descriptionMd: string;
          }> | null;
          sdiInstructionsFaq: Array<{
            __typename?: 'SdiInstructionsFaqType';
            question: string;
            answerMd: string;
          }> | null;
        } | null;
        customerTargetSchemas: Array<{
          __typename?: 'CustomerTargetSchema';
          id: string;
          name: string;
          utilityType: GQBuildingUtilityType | null;
          rank: number;
          latestPublishedVersion: {
            __typename?: 'CustomerTargetSchemaVersion';
            id: string;
            formattingNotesMd: string | null;
            orgDatasetExampleData: Array<{
              __typename?: 'IngestionExampleData';
              id: string;
              title: string;
              datasetId: string | null;
              columns: Array<{
                __typename?: 'IngestionExampleDataColumn';
                fieldName: string;
                title: string;
                description: string;
                examples: Array<string>;
                required: boolean;
                optionalToMap: boolean | null;
                defaultValue: any | null;
              }>;
              rows: Array<{
                __typename?: 'IngestionExampleDataRow';
                fields: Array<{
                  __typename?: 'IngestionExampleDataRowField';
                  fieldName: string;
                  value: string;
                }>;
              }>;
            }>;
            transforms: Array<{
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              businessActivityTypeName: string | null;
              businessActivityTypeVersion: {
                __typename?: 'BusinessActivityTypeVersion';
                id: string;
              } | null;
            }>;
          } | null;
        }>;
      } | null;
      customExampleData: {
        __typename?: 'TabularData';
        cells: Array<Array<any>>;
      } | null;
    };
    canonicalDatasource: {
      __typename?: 'CanonicalDatasource';
      id: string;
      name: string;
      integrationPartner: {
        __typename?: 'IntegrationPartner';
        id: string;
        name: string;
        logoUrl: string;
        externalId: string;
        dataType: string;
        isRequestOnly: boolean;
        applicableDatasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          hasIntegration: boolean;
          isUserConfirmed: boolean | null;
          activeIntegrationConnection: {
            __typename?: 'IntegrationConnection';
            id: string;
          } | null;
          dataset: { __typename?: 'Dataset'; id: string; name: string };
        }>;
        activeIntegrationConnections: Array<{
          __typename?: 'IntegrationConnection';
          id: string;
          additionalIdentifier: string | null;
        }>;
      } | null;
    } | null;
    userUploadTasks: {
      __typename?: 'UserUploadTaskConnection';
      edges: Array<{
        __typename?: 'UserUploadTaskEdge';
        node: {
          __typename?: 'UserUploadTask';
          id: string;
          createdAt: Date;
          state: GQUserUploadTaskState;
          importState: GQDatasourceImportState;
          approvalStatus: GQApprovalStatus;
          isDatasetComplete: boolean | null;
          lockState: GQApprovalTargetLockState;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            displayName: string;
            name: string;
          }>;
          assignee: {
            __typename?: 'User';
            id: string;
            displayName: string;
            name: string;
          } | null;
          measurementTask: {
            __typename?: 'MeasurementTaskFlat';
            id: string;
            status: GQTaskWatershedProcessState;
            statusSimplified: GQTaskWatershedProcessStateSimplified;
          };
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
            active: boolean;
            coverageInterval: YMInterval;
            coverageStartDate: Date;
            coverageEndDate: Date;
            datasetsWithStages: Array<{
              __typename?: 'DatasetWithStage';
              id: string;
              areAllTasksComplete: boolean;
            }>;
            measurementTimeline: {
              __typename?: 'MeasurementTimeline';
              id: string;
              deadline: Date;
            } | null;
          };
          datasetRequirements: Array<{
            __typename?: 'ClimateProgramProjectRequirementDataset';
            id: string;
            additionalNotesMd: string | null;
            climateProgramProject: {
              __typename: 'ClimateProgramProject';
              name: string;
              id: string;
              canonicalClimateProgramProject: {
                __typename: 'CanonicalClimateProgramProject';
                id: string;
                kind: GQClimateProgramProjectKind;
                measurementObjectCopy: string | null;
                routeType: string | null;
                name: string;
              } | null;
            };
          }>;
          userUploads: {
            __typename?: 'UserUploadConnection';
            edges: Array<{
              __typename?: 'UserUploadEdge';
              node: {
                __typename?: 'UserUpload';
                id: string;
                name: string;
                status: GQUserUploadStatus;
                processingMode: GQUserUploadProcessingMode | null;
                userVisibleErrorMessage: string | null;
                createdAt: Date;
                latestAdeIdForSDIFacilitiesFlow: string | null;
                description: string | null;
                revisionRoot: string | null;
                processingWorkflowId: string | null;
                isBeingValueMapped: boolean;
                actualUserUploadId: string | null;
                associatedUtilityCount: number;
                userUploadKind: GQUserUploadCandidateKind | null;
                userVisibleAttempt: {
                  __typename?: 'UserUploadAttempt';
                  id: string;
                  name: string;
                  size: number;
                  remoteWritten: boolean;
                  createdAt: Date;
                  userUploadSource: GQUserUploadSource | null;
                  uploader: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                  } | null;
                  userUploadedTables: Array<{
                    __typename?: 'UserUploadedTable';
                    id: string;
                    sheetName: string | null;
                    sheetIndex: number | null;
                    errorMessage: string | null;
                    errorType: string | null;
                    numRows: number | null;
                    status: GQUserUploadedTableStatus;
                    schema: Array<{
                      __typename?: 'UserUploadedTableSchemaColumn';
                      index: number;
                      kind: GQUserUploadedTableSchemaColumnKind;
                      name: string;
                      alias: string;
                    }> | null;
                    uirSchema: Array<{
                      __typename?: 'UserUploadedTableSchemaColumn';
                      index: number;
                      kind: GQUserUploadedTableSchemaColumnKind;
                      name: string;
                      alias: string;
                    }> | null;
                    parseConfig: {
                      __typename?: 'UserUploadedTableParseConfig';
                      noHeader: boolean;
                      skipTopNRows: number;
                      skipBottomNRows: number;
                      meltIdVars: Array<string | null> | null;
                    } | null;
                    roboCleanerResult: {
                      __typename?: 'UserUploadedTableRoboCleanerResult';
                      skipTopNRows: number;
                    } | null;
                    userReviewMetadata: {
                      __typename?: 'UserUploadedTableUserReviewMetadata';
                      headerRowIndex: number;
                    } | null;
                    parentFileMetadata: {
                      __typename?: 'FileMetadata';
                      id: string;
                      name: string;
                    };
                    dataPreview: {
                      __typename?: 'UserUploadedTableDataPreview';
                      dataUntyped: any;
                    } | null;
                    rawDataPreview: {
                      __typename?: 'UserUploadedTableDataPreview';
                      dataUntyped: any;
                    } | null;
                    rawDataUnshiftedPreview: {
                      __typename?: 'UserUploadedTableDataPreview';
                      dataUntyped: any;
                    } | null;
                  }>;
                  latestUutsIfRevisionExists: Array<{
                    __typename?: 'UserUploadedTable';
                    id: string;
                    sheetName: string | null;
                    sheetIndex: number | null;
                    errorMessage: string | null;
                    errorType: string | null;
                    numRows: number | null;
                    status: GQUserUploadedTableStatus;
                    schema: Array<{
                      __typename?: 'UserUploadedTableSchemaColumn';
                      index: number;
                      kind: GQUserUploadedTableSchemaColumnKind;
                      name: string;
                      alias: string;
                    }> | null;
                    uirSchema: Array<{
                      __typename?: 'UserUploadedTableSchemaColumn';
                      index: number;
                      kind: GQUserUploadedTableSchemaColumnKind;
                      name: string;
                      alias: string;
                    }> | null;
                    parseConfig: {
                      __typename?: 'UserUploadedTableParseConfig';
                      noHeader: boolean;
                      skipTopNRows: number;
                      skipBottomNRows: number;
                      meltIdVars: Array<string | null> | null;
                    } | null;
                    roboCleanerResult: {
                      __typename?: 'UserUploadedTableRoboCleanerResult';
                      skipTopNRows: number;
                    } | null;
                    userReviewMetadata: {
                      __typename?: 'UserUploadedTableUserReviewMetadata';
                      headerRowIndex: number;
                    } | null;
                    parentFileMetadata: {
                      __typename?: 'FileMetadata';
                      id: string;
                      name: string;
                    };
                    dataPreview: {
                      __typename?: 'UserUploadedTableDataPreview';
                      dataUntyped: any;
                    } | null;
                    rawDataPreview: {
                      __typename?: 'UserUploadedTableDataPreview';
                      dataUntyped: any;
                    } | null;
                    rawDataUnshiftedPreview: {
                      __typename?: 'UserUploadedTableDataPreview';
                      dataUntyped: any;
                    } | null;
                  }> | null;
                } | null;
                userUploadTask: {
                  __typename?: 'UserUploadTask';
                  id: string;
                  measurementProject: {
                    __typename?: 'MeasurementProject';
                    id: string;
                    name: string;
                  };
                } | null;
                transformsIncludingDeleted: Array<{
                  __typename?: 'CustomerTargetSchemaTransform';
                  id: string;
                  deletedAt: Date | null;
                  sql: string;
                  transformTarget: GQCustomerTargetSchemaTransformTarget;
                  businessActivityTypeName: string | null;
                  businessActivityTypeVersionId: string | null;
                  businessActivityTypeVersion: {
                    __typename?: 'BusinessActivityTypeVersion';
                    id: string;
                    name: string;
                  } | null;
                  runs: Array<{
                    __typename?: 'CustomerTargetSchemaTransformRun';
                    id: string;
                    sql: string;
                    error: string | null;
                    createdAt: Date;
                    transform: {
                      __typename?: 'CustomerTargetSchemaTransform';
                      id: string;
                      transformTarget: GQCustomerTargetSchemaTransformTarget;
                      businessActivityTypeName: string | null;
                    };
                    input: {
                      __typename?: 'FileMetadata';
                      id: string;
                      name: string;
                      category: GQFileCategory;
                      revisionRootFileMetadata: {
                        __typename?: 'FileMetadata';
                        id: string;
                        name: string;
                      } | null;
                    };
                    output: {
                      __typename?: 'ParquetData';
                      id: string;
                      numRecords: number;
                      ndjsonSignedUrl: string | null;
                      ndjsonSize: number | null;
                      ndjsonByteBoundaries: Array<number> | null;
                      columns: Array<{
                        __typename?: 'ParquetColumn';
                        name: string;
                        sqlType: string;
                        minValue: any;
                        maxValue: any;
                        numNulls: number;
                        approxDistinctValues: number;
                        sampleValues: Array<any>;
                      }>;
                    } | null;
                    standardizedOutput: {
                      __typename?: 'ParquetData';
                      id: string;
                      numRecords: number;
                      ndjsonSignedUrl: string | null;
                      ndjsonSize: number | null;
                      ndjsonByteBoundaries: Array<number> | null;
                      columns: Array<{
                        __typename?: 'ParquetColumn';
                        name: string;
                        sqlType: string;
                        minValue: any;
                        maxValue: any;
                        numNulls: number;
                        approxDistinctValues: number;
                        sampleValues: Array<any>;
                      }>;
                    } | null;
                    warnings: Array<{
                      __typename?: 'ValidationWarning';
                      message: string;
                      blocking: boolean;
                    }>;
                    runner: { __typename?: 'User'; id: string; name: string };
                  }>;
                }>;
              } | null;
            } | null>;
          } | null;
          issues: {
            __typename?: 'DataIssueConnection';
            edges: Array<{
              __typename?: 'DataIssueEdge';
              node: {
                __typename?: 'DataIssue';
                id: string;
                title: string;
                description: string | null;
                state: GQDataIssueState;
                createdAt: Date;
                isCustomerInitiated: boolean;
                measurementProject: {
                  __typename?: 'MeasurementProject';
                  id: string;
                  completedAt: Date | null;
                };
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      message: string;
                      isPublished: boolean;
                      createdAt: Date;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            name: string;
                            displayName: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            name: string;
                            displayName: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              } | null;
            } | null>;
          } | null;
          valueMappingTask: {
            __typename?: 'ValueMappingTask';
            id: string;
            mappingRuleId: string;
          } | null;
          valueMappingTasks: Array<{
            __typename?: 'ValueMappingTask';
            id: string;
            mappingRuleId: string;
            status: GQTaskWatershedProcessState;
          }>;
          approvers: Array<{
            __typename?: 'UserWithApprovalStatus';
            approvalStatus: GQApprovalStatus | null;
            user: {
              __typename?: 'User';
              id: string;
              name: string;
              displayName: string;
            };
          }>;
          ctsForm: {
            __typename?: 'CtsForm';
            id: string;
            ctsVersionId: string;
            customerTargetSchemaId: string;
            datasetId: string;
            title: string | null;
            description: string | null;
            entries: Array<{
              __typename?: 'CtsFormEntry';
              id: string;
              data: any;
              createdBy: {
                __typename?: 'User';
                id: string;
                displayName: string;
              };
            }>;
          } | null;
        } | null;
      } | null>;
    };
    presetCustomTags: Array<{
      __typename?: 'PresetCustomTag';
      ingestionCustomField: {
        __typename?: 'IngestionCustomField';
        id: string;
        name: string;
        type: GQIngestionCustomFieldType;
        possibleValues: Array<string> | null;
        description: string | null;
        appliesToAllDatasets: boolean;
        specificDatasets: Array<{
          __typename?: 'Dataset';
          id: string;
          name: string;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        }>;
      };
      ingestionCustomFieldDatasource: {
        __typename?: 'IngestionCustomFieldDatasource';
        id: string;
        datasourceId: string;
        ingestionCustomFieldId: string;
        ingestionCustomFieldValue: string;
      };
    }>;
    presetOrgUnits: Array<{
      __typename?: 'PresetOrgUnit';
      orgUnit: { __typename?: 'OrgUnit'; id: string; name: string };
      orgUnitDatasource: { __typename?: 'OrgUnitDatasource'; id: string };
    }>;
  };
  facilitiesPreview: { __typename?: 'FacilitiesPreview'; totalCount: number };
  assignees: Array<{
    __typename?: 'User';
    id: string;
    displayName: string;
    name: string;
  }>;
  assignee: {
    __typename?: 'User';
    id: string;
    displayName: string;
    name: string;
  } | null;
  measurementTask: {
    __typename?: 'MeasurementTaskFlat';
    id: string;
    status: GQTaskWatershedProcessState;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
  };
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    active: boolean;
    coverageInterval: YMInterval;
    coverageStartDate: Date;
    coverageEndDate: Date;
    datasetsWithStages: Array<{
      __typename?: 'DatasetWithStage';
      id: string;
      areAllTasksComplete: boolean;
    }>;
    measurementTimeline: {
      __typename?: 'MeasurementTimeline';
      id: string;
      deadline: Date;
    } | null;
  };
  datasetRequirements: Array<{
    __typename?: 'ClimateProgramProjectRequirementDataset';
    id: string;
    additionalNotesMd: string | null;
    climateProgramProject: {
      __typename: 'ClimateProgramProject';
      name: string;
      id: string;
      canonicalClimateProgramProject: {
        __typename: 'CanonicalClimateProgramProject';
        id: string;
        kind: GQClimateProgramProjectKind;
        measurementObjectCopy: string | null;
        routeType: string | null;
        name: string;
      } | null;
    };
  }>;
  userUploads: {
    __typename?: 'UserUploadConnection';
    edges: Array<{
      __typename?: 'UserUploadEdge';
      node: {
        __typename?: 'UserUpload';
        id: string;
        name: string;
        status: GQUserUploadStatus;
        processingMode: GQUserUploadProcessingMode | null;
        userVisibleErrorMessage: string | null;
        createdAt: Date;
        latestAdeIdForSDIFacilitiesFlow: string | null;
        description: string | null;
        revisionRoot: string | null;
        processingWorkflowId: string | null;
        isBeingValueMapped: boolean;
        actualUserUploadId: string | null;
        associatedUtilityCount: number;
        userUploadKind: GQUserUploadCandidateKind | null;
        userVisibleAttempt: {
          __typename?: 'UserUploadAttempt';
          id: string;
          name: string;
          size: number;
          remoteWritten: boolean;
          createdAt: Date;
          userUploadSource: GQUserUploadSource | null;
          uploader: { __typename?: 'User'; id: string; name: string } | null;
          userUploadedTables: Array<{
            __typename?: 'UserUploadedTable';
            id: string;
            sheetName: string | null;
            sheetIndex: number | null;
            errorMessage: string | null;
            errorType: string | null;
            numRows: number | null;
            status: GQUserUploadedTableStatus;
            schema: Array<{
              __typename?: 'UserUploadedTableSchemaColumn';
              index: number;
              kind: GQUserUploadedTableSchemaColumnKind;
              name: string;
              alias: string;
            }> | null;
            uirSchema: Array<{
              __typename?: 'UserUploadedTableSchemaColumn';
              index: number;
              kind: GQUserUploadedTableSchemaColumnKind;
              name: string;
              alias: string;
            }> | null;
            parseConfig: {
              __typename?: 'UserUploadedTableParseConfig';
              noHeader: boolean;
              skipTopNRows: number;
              skipBottomNRows: number;
              meltIdVars: Array<string | null> | null;
            } | null;
            roboCleanerResult: {
              __typename?: 'UserUploadedTableRoboCleanerResult';
              skipTopNRows: number;
            } | null;
            userReviewMetadata: {
              __typename?: 'UserUploadedTableUserReviewMetadata';
              headerRowIndex: number;
            } | null;
            parentFileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            };
            dataPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
            rawDataPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
            rawDataUnshiftedPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
          }>;
          latestUutsIfRevisionExists: Array<{
            __typename?: 'UserUploadedTable';
            id: string;
            sheetName: string | null;
            sheetIndex: number | null;
            errorMessage: string | null;
            errorType: string | null;
            numRows: number | null;
            status: GQUserUploadedTableStatus;
            schema: Array<{
              __typename?: 'UserUploadedTableSchemaColumn';
              index: number;
              kind: GQUserUploadedTableSchemaColumnKind;
              name: string;
              alias: string;
            }> | null;
            uirSchema: Array<{
              __typename?: 'UserUploadedTableSchemaColumn';
              index: number;
              kind: GQUserUploadedTableSchemaColumnKind;
              name: string;
              alias: string;
            }> | null;
            parseConfig: {
              __typename?: 'UserUploadedTableParseConfig';
              noHeader: boolean;
              skipTopNRows: number;
              skipBottomNRows: number;
              meltIdVars: Array<string | null> | null;
            } | null;
            roboCleanerResult: {
              __typename?: 'UserUploadedTableRoboCleanerResult';
              skipTopNRows: number;
            } | null;
            userReviewMetadata: {
              __typename?: 'UserUploadedTableUserReviewMetadata';
              headerRowIndex: number;
            } | null;
            parentFileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
            };
            dataPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
            rawDataPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
            rawDataUnshiftedPreview: {
              __typename?: 'UserUploadedTableDataPreview';
              dataUntyped: any;
            } | null;
          }> | null;
        } | null;
        userUploadTask: {
          __typename?: 'UserUploadTask';
          id: string;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            name: string;
          };
        } | null;
        transformsIncludingDeleted: Array<{
          __typename?: 'CustomerTargetSchemaTransform';
          id: string;
          deletedAt: Date | null;
          sql: string;
          transformTarget: GQCustomerTargetSchemaTransformTarget;
          businessActivityTypeName: string | null;
          businessActivityTypeVersionId: string | null;
          businessActivityTypeVersion: {
            __typename?: 'BusinessActivityTypeVersion';
            id: string;
            name: string;
          } | null;
          runs: Array<{
            __typename?: 'CustomerTargetSchemaTransformRun';
            id: string;
            sql: string;
            error: string | null;
            createdAt: Date;
            transform: {
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              transformTarget: GQCustomerTargetSchemaTransformTarget;
              businessActivityTypeName: string | null;
            };
            input: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              category: GQFileCategory;
              revisionRootFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              } | null;
            };
            output: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            standardizedOutput: {
              __typename?: 'ParquetData';
              id: string;
              numRecords: number;
              ndjsonSignedUrl: string | null;
              ndjsonSize: number | null;
              ndjsonByteBoundaries: Array<number> | null;
              columns: Array<{
                __typename?: 'ParquetColumn';
                name: string;
                sqlType: string;
                minValue: any;
                maxValue: any;
                numNulls: number;
                approxDistinctValues: number;
                sampleValues: Array<any>;
              }>;
            } | null;
            warnings: Array<{
              __typename?: 'ValidationWarning';
              message: string;
              blocking: boolean;
            }>;
            runner: { __typename?: 'User'; id: string; name: string };
          }>;
        }>;
      } | null;
    } | null>;
  } | null;
  issues: {
    __typename?: 'DataIssueConnection';
    edges: Array<{
      __typename?: 'DataIssueEdge';
      node: {
        __typename?: 'DataIssue';
        id: string;
        title: string;
        description: string | null;
        state: GQDataIssueState;
        createdAt: Date;
        isCustomerInitiated: boolean;
        measurementProject: {
          __typename?: 'MeasurementProject';
          id: string;
          completedAt: Date | null;
        };
        comments: {
          __typename?: 'CommentConnection';
          edges: Array<{
            __typename?: 'CommentEdge';
            node: {
              __typename?: 'Comment';
              id: string;
              message: string;
              isPublished: boolean;
              createdAt: Date;
              person:
                | {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | {
                    __typename?: 'WatershedEmployee';
                    id: string;
                    name: string;
                    displayName: string;
                    isWatershedEmployee: boolean;
                    isWatershedContractor: boolean;
                  }
                | null;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
  valueMappingTask: {
    __typename?: 'ValueMappingTask';
    id: string;
    mappingRuleId: string;
  } | null;
  valueMappingTasks: Array<{
    __typename?: 'ValueMappingTask';
    id: string;
    mappingRuleId: string;
    status: GQTaskWatershedProcessState;
  }>;
  approvers: Array<{
    __typename?: 'UserWithApprovalStatus';
    approvalStatus: GQApprovalStatus | null;
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    };
  }>;
  ctsForm: {
    __typename?: 'CtsForm';
    id: string;
    ctsVersionId: string;
    customerTargetSchemaId: string;
    datasetId: string;
    title: string | null;
    description: string | null;
    entries: Array<{
      __typename?: 'CtsFormEntry';
      id: string;
      data: any;
      createdBy: { __typename?: 'User'; id: string; displayName: string };
    }>;
  } | null;
};

export type GQDatasourcePageQueryVariables = Exact<{
  datasourceId: Scalars['ID']['input'];
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQDatasourcePageQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  userUploadTaskForDatasource: {
    __typename?: 'UserUploadTask';
    id: string;
    createdAt: Date;
    state: GQUserUploadTaskState;
    importState: GQDatasourceImportState;
    approvalStatus: GQApprovalStatus;
    isDatasetComplete: boolean | null;
    lockState: GQApprovalTargetLockState;
    datasource: {
      __typename?: 'Datasource';
      id: string;
      name: string;
      isUserConfirmed: boolean | null;
      hasIntegrationOption: boolean;
      hasIntegration: boolean;
      customerTargetSchemaIds: Array<string> | null;
      importInstructionsMd: string | null;
      refreshCadence: GQDatasetCadence;
      hasApiAccess: boolean;
      notesFromWatershedMd: string | null;
      activeIntegrationConnection: {
        __typename?: 'IntegrationConnection';
        id: string;
        additionalIdentifier: string | null;
      } | null;
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsIntroMd: string | null;
        instructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          customerTargetSchemaIds: Array<string> | null;
        }>;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          name: string;
          kind: GQCanonicalDatasetKind;
          instructions: {
            __typename?: 'InstructionsBundle';
            instructionsIntroMd: string | null;
            instructionsDataFormattingMd: string | null;
            sdiInstructionsIntroMd: string | null;
            sdiInstructionsDataFormattingMd: string | null;
            ghgScopes: Array<GQGhgScope> | null;
            instructionsSteps: Array<{
              __typename?: 'InstructionsStep';
              descriptionMd: string;
              publicUrl: string | null;
            }> | null;
            sdiInstructionsSteps: Array<{
              __typename?: 'SdiInstructionsStep';
              title: string;
              descriptionMd: string;
            }> | null;
            sdiInstructionsFaq: Array<{
              __typename?: 'SdiInstructionsFaqType';
              question: string;
              answerMd: string;
            }> | null;
          } | null;
          customerTargetSchemas: Array<{
            __typename?: 'CustomerTargetSchema';
            id: string;
            name: string;
            utilityType: GQBuildingUtilityType | null;
            rank: number;
            latestPublishedVersion: {
              __typename?: 'CustomerTargetSchemaVersion';
              id: string;
              formattingNotesMd: string | null;
              orgDatasetExampleData: Array<{
                __typename?: 'IngestionExampleData';
                id: string;
                title: string;
                datasetId: string | null;
                columns: Array<{
                  __typename?: 'IngestionExampleDataColumn';
                  fieldName: string;
                  title: string;
                  description: string;
                  examples: Array<string>;
                  required: boolean;
                  optionalToMap: boolean | null;
                  defaultValue: any | null;
                }>;
                rows: Array<{
                  __typename?: 'IngestionExampleDataRow';
                  fields: Array<{
                    __typename?: 'IngestionExampleDataRowField';
                    fieldName: string;
                    value: string;
                  }>;
                }>;
              }>;
              transforms: Array<{
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                businessActivityTypeName: string | null;
                businessActivityTypeVersion: {
                  __typename?: 'BusinessActivityTypeVersion';
                  id: string;
                } | null;
              }>;
            } | null;
          }>;
        } | null;
        customExampleData: {
          __typename?: 'TabularData';
          cells: Array<Array<any>>;
        } | null;
      };
      canonicalDatasource: {
        __typename?: 'CanonicalDatasource';
        id: string;
        name: string;
        integrationPartner: {
          __typename?: 'IntegrationPartner';
          id: string;
          name: string;
          logoUrl: string;
          externalId: string;
          dataType: string;
          isRequestOnly: boolean;
          applicableDatasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            hasIntegration: boolean;
            isUserConfirmed: boolean | null;
            activeIntegrationConnection: {
              __typename?: 'IntegrationConnection';
              id: string;
            } | null;
            dataset: { __typename?: 'Dataset'; id: string; name: string };
          }>;
          activeIntegrationConnections: Array<{
            __typename?: 'IntegrationConnection';
            id: string;
            additionalIdentifier: string | null;
          }>;
        } | null;
      } | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            createdAt: Date;
            state: GQUserUploadTaskState;
            importState: GQDatasourceImportState;
            approvalStatus: GQApprovalStatus;
            isDatasetComplete: boolean | null;
            lockState: GQApprovalTargetLockState;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              displayName: string;
              name: string;
            }>;
            assignee: {
              __typename?: 'User';
              id: string;
              displayName: string;
              name: string;
            } | null;
            measurementTask: {
              __typename?: 'MeasurementTaskFlat';
              id: string;
              status: GQTaskWatershedProcessState;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
            };
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
              active: boolean;
              coverageInterval: YMInterval;
              coverageStartDate: Date;
              coverageEndDate: Date;
              datasetsWithStages: Array<{
                __typename?: 'DatasetWithStage';
                id: string;
                areAllTasksComplete: boolean;
              }>;
              measurementTimeline: {
                __typename?: 'MeasurementTimeline';
                id: string;
                deadline: Date;
              } | null;
            };
            datasetRequirements: Array<{
              __typename?: 'ClimateProgramProjectRequirementDataset';
              id: string;
              additionalNotesMd: string | null;
              climateProgramProject: {
                __typename: 'ClimateProgramProject';
                name: string;
                id: string;
                canonicalClimateProgramProject: {
                  __typename: 'CanonicalClimateProgramProject';
                  id: string;
                  kind: GQClimateProgramProjectKind;
                  measurementObjectCopy: string | null;
                  routeType: string | null;
                  name: string;
                } | null;
              };
            }>;
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: {
                  __typename?: 'UserUpload';
                  id: string;
                  name: string;
                  status: GQUserUploadStatus;
                  processingMode: GQUserUploadProcessingMode | null;
                  userVisibleErrorMessage: string | null;
                  createdAt: Date;
                  latestAdeIdForSDIFacilitiesFlow: string | null;
                  description: string | null;
                  revisionRoot: string | null;
                  processingWorkflowId: string | null;
                  isBeingValueMapped: boolean;
                  actualUserUploadId: string | null;
                  associatedUtilityCount: number;
                  userUploadKind: GQUserUploadCandidateKind | null;
                  userVisibleAttempt: {
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    name: string;
                    size: number;
                    remoteWritten: boolean;
                    createdAt: Date;
                    userUploadSource: GQUserUploadSource | null;
                    uploader: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                    } | null;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                      sheetName: string | null;
                      sheetIndex: number | null;
                      errorMessage: string | null;
                      errorType: string | null;
                      numRows: number | null;
                      status: GQUserUploadedTableStatus;
                      schema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      uirSchema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      parseConfig: {
                        __typename?: 'UserUploadedTableParseConfig';
                        noHeader: boolean;
                        skipTopNRows: number;
                        skipBottomNRows: number;
                        meltIdVars: Array<string | null> | null;
                      } | null;
                      roboCleanerResult: {
                        __typename?: 'UserUploadedTableRoboCleanerResult';
                        skipTopNRows: number;
                      } | null;
                      userReviewMetadata: {
                        __typename?: 'UserUploadedTableUserReviewMetadata';
                        headerRowIndex: number;
                      } | null;
                      parentFileMetadata: {
                        __typename?: 'FileMetadata';
                        id: string;
                        name: string;
                      };
                      dataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataUnshiftedPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                    }>;
                    latestUutsIfRevisionExists: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                      sheetName: string | null;
                      sheetIndex: number | null;
                      errorMessage: string | null;
                      errorType: string | null;
                      numRows: number | null;
                      status: GQUserUploadedTableStatus;
                      schema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      uirSchema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      parseConfig: {
                        __typename?: 'UserUploadedTableParseConfig';
                        noHeader: boolean;
                        skipTopNRows: number;
                        skipBottomNRows: number;
                        meltIdVars: Array<string | null> | null;
                      } | null;
                      roboCleanerResult: {
                        __typename?: 'UserUploadedTableRoboCleanerResult';
                        skipTopNRows: number;
                      } | null;
                      userReviewMetadata: {
                        __typename?: 'UserUploadedTableUserReviewMetadata';
                        headerRowIndex: number;
                      } | null;
                      parentFileMetadata: {
                        __typename?: 'FileMetadata';
                        id: string;
                        name: string;
                      };
                      dataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataUnshiftedPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                    }> | null;
                  } | null;
                  userUploadTask: {
                    __typename?: 'UserUploadTask';
                    id: string;
                    measurementProject: {
                      __typename?: 'MeasurementProject';
                      id: string;
                      name: string;
                    };
                  } | null;
                  transformsIncludingDeleted: Array<{
                    __typename?: 'CustomerTargetSchemaTransform';
                    id: string;
                    deletedAt: Date | null;
                    sql: string;
                    transformTarget: GQCustomerTargetSchemaTransformTarget;
                    businessActivityTypeName: string | null;
                    businessActivityTypeVersionId: string | null;
                    businessActivityTypeVersion: {
                      __typename?: 'BusinessActivityTypeVersion';
                      id: string;
                      name: string;
                    } | null;
                    runs: Array<{
                      __typename?: 'CustomerTargetSchemaTransformRun';
                      id: string;
                      sql: string;
                      error: string | null;
                      createdAt: Date;
                      transform: {
                        __typename?: 'CustomerTargetSchemaTransform';
                        id: string;
                        transformTarget: GQCustomerTargetSchemaTransformTarget;
                        businessActivityTypeName: string | null;
                      };
                      input: {
                        __typename?: 'FileMetadata';
                        id: string;
                        name: string;
                        category: GQFileCategory;
                        revisionRootFileMetadata: {
                          __typename?: 'FileMetadata';
                          id: string;
                          name: string;
                        } | null;
                      };
                      output: {
                        __typename?: 'ParquetData';
                        id: string;
                        numRecords: number;
                        ndjsonSignedUrl: string | null;
                        ndjsonSize: number | null;
                        ndjsonByteBoundaries: Array<number> | null;
                        columns: Array<{
                          __typename?: 'ParquetColumn';
                          name: string;
                          sqlType: string;
                          minValue: any;
                          maxValue: any;
                          numNulls: number;
                          approxDistinctValues: number;
                          sampleValues: Array<any>;
                        }>;
                      } | null;
                      standardizedOutput: {
                        __typename?: 'ParquetData';
                        id: string;
                        numRecords: number;
                        ndjsonSignedUrl: string | null;
                        ndjsonSize: number | null;
                        ndjsonByteBoundaries: Array<number> | null;
                        columns: Array<{
                          __typename?: 'ParquetColumn';
                          name: string;
                          sqlType: string;
                          minValue: any;
                          maxValue: any;
                          numNulls: number;
                          approxDistinctValues: number;
                          sampleValues: Array<any>;
                        }>;
                      } | null;
                      warnings: Array<{
                        __typename?: 'ValidationWarning';
                        message: string;
                        blocking: boolean;
                      }>;
                      runner: { __typename?: 'User'; id: string; name: string };
                    }>;
                  }>;
                } | null;
              } | null>;
            } | null;
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  title: string;
                  description: string | null;
                  state: GQDataIssueState;
                  createdAt: Date;
                  isCustomerInitiated: boolean;
                  measurementProject: {
                    __typename?: 'MeasurementProject';
                    id: string;
                    completedAt: Date | null;
                  };
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        message: string;
                        isPublished: boolean;
                        createdAt: Date;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            } | null;
            valueMappingTask: {
              __typename?: 'ValueMappingTask';
              id: string;
              mappingRuleId: string;
            } | null;
            valueMappingTasks: Array<{
              __typename?: 'ValueMappingTask';
              id: string;
              mappingRuleId: string;
              status: GQTaskWatershedProcessState;
            }>;
            approvers: Array<{
              __typename?: 'UserWithApprovalStatus';
              approvalStatus: GQApprovalStatus | null;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              };
            }>;
            ctsForm: {
              __typename?: 'CtsForm';
              id: string;
              ctsVersionId: string;
              customerTargetSchemaId: string;
              datasetId: string;
              title: string | null;
              description: string | null;
              entries: Array<{
                __typename?: 'CtsFormEntry';
                id: string;
                data: any;
                createdBy: {
                  __typename?: 'User';
                  id: string;
                  displayName: string;
                };
              }>;
            } | null;
          } | null;
        } | null>;
      };
      presetCustomTags: Array<{
        __typename?: 'PresetCustomTag';
        ingestionCustomField: {
          __typename?: 'IngestionCustomField';
          id: string;
          name: string;
          type: GQIngestionCustomFieldType;
          possibleValues: Array<string> | null;
          description: string | null;
          appliesToAllDatasets: boolean;
          specificDatasets: Array<{
            __typename?: 'Dataset';
            id: string;
            name: string;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          }>;
        };
        ingestionCustomFieldDatasource: {
          __typename?: 'IngestionCustomFieldDatasource';
          id: string;
          datasourceId: string;
          ingestionCustomFieldId: string;
          ingestionCustomFieldValue: string;
        };
      }>;
      presetOrgUnits: Array<{
        __typename?: 'PresetOrgUnit';
        orgUnit: { __typename?: 'OrgUnit'; id: string; name: string };
        orgUnitDatasource: { __typename?: 'OrgUnitDatasource'; id: string };
      }>;
    };
    facilitiesPreview: { __typename?: 'FacilitiesPreview'; totalCount: number };
    assignees: Array<{
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    }>;
    assignee: {
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    } | null;
    measurementTask: {
      __typename?: 'MeasurementTaskFlat';
      id: string;
      status: GQTaskWatershedProcessState;
      statusSimplified: GQTaskWatershedProcessStateSimplified;
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      coverageInterval: YMInterval;
      coverageStartDate: Date;
      coverageEndDate: Date;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
      }>;
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        deadline: Date;
      } | null;
    };
    datasetRequirements: Array<{
      __typename?: 'ClimateProgramProjectRequirementDataset';
      id: string;
      additionalNotesMd: string | null;
      climateProgramProject: {
        __typename: 'ClimateProgramProject';
        name: string;
        id: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          kind: GQClimateProgramProjectKind;
          measurementObjectCopy: string | null;
          routeType: string | null;
          name: string;
        } | null;
      };
    }>;
    userUploads: {
      __typename?: 'UserUploadConnection';
      edges: Array<{
        __typename?: 'UserUploadEdge';
        node: {
          __typename?: 'UserUpload';
          id: string;
          name: string;
          status: GQUserUploadStatus;
          processingMode: GQUserUploadProcessingMode | null;
          userVisibleErrorMessage: string | null;
          createdAt: Date;
          latestAdeIdForSDIFacilitiesFlow: string | null;
          description: string | null;
          revisionRoot: string | null;
          processingWorkflowId: string | null;
          isBeingValueMapped: boolean;
          actualUserUploadId: string | null;
          associatedUtilityCount: number;
          userUploadKind: GQUserUploadCandidateKind | null;
          userVisibleAttempt: {
            __typename?: 'UserUploadAttempt';
            id: string;
            name: string;
            size: number;
            remoteWritten: boolean;
            createdAt: Date;
            userUploadSource: GQUserUploadSource | null;
            uploader: { __typename?: 'User'; id: string; name: string } | null;
            userUploadedTables: Array<{
              __typename?: 'UserUploadedTable';
              id: string;
              sheetName: string | null;
              sheetIndex: number | null;
              errorMessage: string | null;
              errorType: string | null;
              numRows: number | null;
              status: GQUserUploadedTableStatus;
              schema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              uirSchema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              parseConfig: {
                __typename?: 'UserUploadedTableParseConfig';
                noHeader: boolean;
                skipTopNRows: number;
                skipBottomNRows: number;
                meltIdVars: Array<string | null> | null;
              } | null;
              roboCleanerResult: {
                __typename?: 'UserUploadedTableRoboCleanerResult';
                skipTopNRows: number;
              } | null;
              userReviewMetadata: {
                __typename?: 'UserUploadedTableUserReviewMetadata';
                headerRowIndex: number;
              } | null;
              parentFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              };
              dataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataUnshiftedPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
            }>;
            latestUutsIfRevisionExists: Array<{
              __typename?: 'UserUploadedTable';
              id: string;
              sheetName: string | null;
              sheetIndex: number | null;
              errorMessage: string | null;
              errorType: string | null;
              numRows: number | null;
              status: GQUserUploadedTableStatus;
              schema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              uirSchema: Array<{
                __typename?: 'UserUploadedTableSchemaColumn';
                index: number;
                kind: GQUserUploadedTableSchemaColumnKind;
                name: string;
                alias: string;
              }> | null;
              parseConfig: {
                __typename?: 'UserUploadedTableParseConfig';
                noHeader: boolean;
                skipTopNRows: number;
                skipBottomNRows: number;
                meltIdVars: Array<string | null> | null;
              } | null;
              roboCleanerResult: {
                __typename?: 'UserUploadedTableRoboCleanerResult';
                skipTopNRows: number;
              } | null;
              userReviewMetadata: {
                __typename?: 'UserUploadedTableUserReviewMetadata';
                headerRowIndex: number;
              } | null;
              parentFileMetadata: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
              };
              dataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
              rawDataUnshiftedPreview: {
                __typename?: 'UserUploadedTableDataPreview';
                dataUntyped: any;
              } | null;
            }> | null;
          } | null;
          userUploadTask: {
            __typename?: 'UserUploadTask';
            id: string;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
            };
          } | null;
          transformsIncludingDeleted: Array<{
            __typename?: 'CustomerTargetSchemaTransform';
            id: string;
            deletedAt: Date | null;
            sql: string;
            transformTarget: GQCustomerTargetSchemaTransformTarget;
            businessActivityTypeName: string | null;
            businessActivityTypeVersionId: string | null;
            businessActivityTypeVersion: {
              __typename?: 'BusinessActivityTypeVersion';
              id: string;
              name: string;
            } | null;
            runs: Array<{
              __typename?: 'CustomerTargetSchemaTransformRun';
              id: string;
              sql: string;
              error: string | null;
              createdAt: Date;
              transform: {
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                transformTarget: GQCustomerTargetSchemaTransformTarget;
                businessActivityTypeName: string | null;
              };
              input: {
                __typename?: 'FileMetadata';
                id: string;
                name: string;
                category: GQFileCategory;
                revisionRootFileMetadata: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                } | null;
              };
              output: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
              standardizedOutput: {
                __typename?: 'ParquetData';
                id: string;
                numRecords: number;
                ndjsonSignedUrl: string | null;
                ndjsonSize: number | null;
                ndjsonByteBoundaries: Array<number> | null;
                columns: Array<{
                  __typename?: 'ParquetColumn';
                  name: string;
                  sqlType: string;
                  minValue: any;
                  maxValue: any;
                  numNulls: number;
                  approxDistinctValues: number;
                  sampleValues: Array<any>;
                }>;
              } | null;
              warnings: Array<{
                __typename?: 'ValidationWarning';
                message: string;
                blocking: boolean;
              }>;
              runner: { __typename?: 'User'; id: string; name: string };
            }>;
          }>;
        } | null;
      } | null>;
    } | null;
    issues: {
      __typename?: 'DataIssueConnection';
      edges: Array<{
        __typename?: 'DataIssueEdge';
        node: {
          __typename?: 'DataIssue';
          id: string;
          title: string;
          description: string | null;
          state: GQDataIssueState;
          createdAt: Date;
          isCustomerInitiated: boolean;
          measurementProject: {
            __typename?: 'MeasurementProject';
            id: string;
            completedAt: Date | null;
          };
          comments: {
            __typename?: 'CommentConnection';
            edges: Array<{
              __typename?: 'CommentEdge';
              node: {
                __typename?: 'Comment';
                id: string;
                message: string;
                isPublished: boolean;
                createdAt: Date;
                person:
                  | {
                      __typename?: 'User';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | {
                      __typename?: 'WatershedEmployee';
                      id: string;
                      name: string;
                      displayName: string;
                      isWatershedEmployee: boolean;
                      isWatershedContractor: boolean;
                    }
                  | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    } | null;
    valueMappingTask: {
      __typename?: 'ValueMappingTask';
      id: string;
      mappingRuleId: string;
    } | null;
    valueMappingTasks: Array<{
      __typename?: 'ValueMappingTask';
      id: string;
      mappingRuleId: string;
      status: GQTaskWatershedProcessState;
    }>;
    approvers: Array<{
      __typename?: 'UserWithApprovalStatus';
      approvalStatus: GQApprovalStatus | null;
      user: {
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      };
    }>;
    ctsForm: {
      __typename?: 'CtsForm';
      id: string;
      ctsVersionId: string;
      customerTargetSchemaId: string;
      datasetId: string;
      title: string | null;
      description: string | null;
      entries: Array<{
        __typename?: 'CtsFormEntry';
        id: string;
        data: any;
        createdBy: { __typename?: 'User'; id: string; displayName: string };
      }>;
    } | null;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    ingestionCustomFields: Array<{
      __typename?: 'IngestionCustomField';
      id: string;
      name: string;
      type: GQIngestionCustomFieldType;
      possibleValues: Array<string> | null;
      description: string | null;
      appliesToAllDatasets: boolean;
      specificDatasets: Array<{
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      }>;
    }>;
  };
};

export type GQUpdateDatasourceNewMutationVariables = Exact<{
  input: GQUpdateDatasourceInput;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQUpdateDatasourceNewMutation = {
  __typename?: 'Mutation';
  updateDatasource: {
    __typename?: 'DatasourcePayload';
    datasource: {
      __typename?: 'Datasource';
      id: string;
      name: string;
      isUserConfirmed: boolean | null;
      hasIntegrationOption: boolean;
      hasIntegration: boolean;
      customerTargetSchemaIds: Array<string> | null;
      importInstructionsMd: string | null;
      refreshCadence: GQDatasetCadence;
      hasApiAccess: boolean;
      notesFromWatershedMd: string | null;
      activeIntegrationConnection: {
        __typename?: 'IntegrationConnection';
        id: string;
        additionalIdentifier: string | null;
      } | null;
      instructions: {
        __typename?: 'InstructionsBundle';
        instructionsIntroMd: string | null;
        instructionsDataFormattingMd: string | null;
        sdiInstructionsIntroMd: string | null;
        sdiInstructionsDataFormattingMd: string | null;
        ghgScopes: Array<GQGhgScope> | null;
        instructionsSteps: Array<{
          __typename?: 'InstructionsStep';
          descriptionMd: string;
          publicUrl: string | null;
        }> | null;
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          customerTargetSchemaIds: Array<string> | null;
        }>;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          name: string;
          kind: GQCanonicalDatasetKind;
          instructions: {
            __typename?: 'InstructionsBundle';
            instructionsIntroMd: string | null;
            instructionsDataFormattingMd: string | null;
            sdiInstructionsIntroMd: string | null;
            sdiInstructionsDataFormattingMd: string | null;
            ghgScopes: Array<GQGhgScope> | null;
            instructionsSteps: Array<{
              __typename?: 'InstructionsStep';
              descriptionMd: string;
              publicUrl: string | null;
            }> | null;
            sdiInstructionsSteps: Array<{
              __typename?: 'SdiInstructionsStep';
              title: string;
              descriptionMd: string;
            }> | null;
            sdiInstructionsFaq: Array<{
              __typename?: 'SdiInstructionsFaqType';
              question: string;
              answerMd: string;
            }> | null;
          } | null;
          customerTargetSchemas: Array<{
            __typename?: 'CustomerTargetSchema';
            id: string;
            name: string;
            utilityType: GQBuildingUtilityType | null;
            rank: number;
            latestPublishedVersion: {
              __typename?: 'CustomerTargetSchemaVersion';
              id: string;
              formattingNotesMd: string | null;
              orgDatasetExampleData: Array<{
                __typename?: 'IngestionExampleData';
                id: string;
                title: string;
                datasetId: string | null;
                columns: Array<{
                  __typename?: 'IngestionExampleDataColumn';
                  fieldName: string;
                  title: string;
                  description: string;
                  examples: Array<string>;
                  required: boolean;
                  optionalToMap: boolean | null;
                  defaultValue: any | null;
                }>;
                rows: Array<{
                  __typename?: 'IngestionExampleDataRow';
                  fields: Array<{
                    __typename?: 'IngestionExampleDataRowField';
                    fieldName: string;
                    value: string;
                  }>;
                }>;
              }>;
              transforms: Array<{
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                businessActivityTypeName: string | null;
                businessActivityTypeVersion: {
                  __typename?: 'BusinessActivityTypeVersion';
                  id: string;
                } | null;
              }>;
            } | null;
          }>;
        } | null;
        customExampleData: {
          __typename?: 'TabularData';
          cells: Array<Array<any>>;
        } | null;
      };
      canonicalDatasource: {
        __typename?: 'CanonicalDatasource';
        id: string;
        name: string;
        integrationPartner: {
          __typename?: 'IntegrationPartner';
          id: string;
          name: string;
          logoUrl: string;
          externalId: string;
          dataType: string;
          isRequestOnly: boolean;
          applicableDatasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            hasIntegration: boolean;
            isUserConfirmed: boolean | null;
            activeIntegrationConnection: {
              __typename?: 'IntegrationConnection';
              id: string;
            } | null;
            dataset: { __typename?: 'Dataset'; id: string; name: string };
          }>;
          activeIntegrationConnections: Array<{
            __typename?: 'IntegrationConnection';
            id: string;
            additionalIdentifier: string | null;
          }>;
        } | null;
      } | null;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            createdAt: Date;
            state: GQUserUploadTaskState;
            importState: GQDatasourceImportState;
            approvalStatus: GQApprovalStatus;
            isDatasetComplete: boolean | null;
            lockState: GQApprovalTargetLockState;
            assignees: Array<{
              __typename?: 'User';
              id: string;
              displayName: string;
              name: string;
            }>;
            assignee: {
              __typename?: 'User';
              id: string;
              displayName: string;
              name: string;
            } | null;
            measurementTask: {
              __typename?: 'MeasurementTaskFlat';
              id: string;
              status: GQTaskWatershedProcessState;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
            };
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              name: string;
              active: boolean;
              coverageInterval: YMInterval;
              coverageStartDate: Date;
              coverageEndDate: Date;
              datasetsWithStages: Array<{
                __typename?: 'DatasetWithStage';
                id: string;
                areAllTasksComplete: boolean;
              }>;
              measurementTimeline: {
                __typename?: 'MeasurementTimeline';
                id: string;
                deadline: Date;
              } | null;
            };
            datasetRequirements: Array<{
              __typename?: 'ClimateProgramProjectRequirementDataset';
              id: string;
              additionalNotesMd: string | null;
              climateProgramProject: {
                __typename: 'ClimateProgramProject';
                name: string;
                id: string;
                canonicalClimateProgramProject: {
                  __typename: 'CanonicalClimateProgramProject';
                  id: string;
                  kind: GQClimateProgramProjectKind;
                  measurementObjectCopy: string | null;
                  routeType: string | null;
                  name: string;
                } | null;
              };
            }>;
            userUploads: {
              __typename?: 'UserUploadConnection';
              edges: Array<{
                __typename?: 'UserUploadEdge';
                node: {
                  __typename?: 'UserUpload';
                  id: string;
                  name: string;
                  status: GQUserUploadStatus;
                  processingMode: GQUserUploadProcessingMode | null;
                  userVisibleErrorMessage: string | null;
                  createdAt: Date;
                  latestAdeIdForSDIFacilitiesFlow: string | null;
                  description: string | null;
                  revisionRoot: string | null;
                  processingWorkflowId: string | null;
                  isBeingValueMapped: boolean;
                  actualUserUploadId: string | null;
                  associatedUtilityCount: number;
                  userUploadKind: GQUserUploadCandidateKind | null;
                  userVisibleAttempt: {
                    __typename?: 'UserUploadAttempt';
                    id: string;
                    name: string;
                    size: number;
                    remoteWritten: boolean;
                    createdAt: Date;
                    userUploadSource: GQUserUploadSource | null;
                    uploader: {
                      __typename?: 'User';
                      id: string;
                      name: string;
                    } | null;
                    userUploadedTables: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                      sheetName: string | null;
                      sheetIndex: number | null;
                      errorMessage: string | null;
                      errorType: string | null;
                      numRows: number | null;
                      status: GQUserUploadedTableStatus;
                      schema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      uirSchema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      parseConfig: {
                        __typename?: 'UserUploadedTableParseConfig';
                        noHeader: boolean;
                        skipTopNRows: number;
                        skipBottomNRows: number;
                        meltIdVars: Array<string | null> | null;
                      } | null;
                      roboCleanerResult: {
                        __typename?: 'UserUploadedTableRoboCleanerResult';
                        skipTopNRows: number;
                      } | null;
                      userReviewMetadata: {
                        __typename?: 'UserUploadedTableUserReviewMetadata';
                        headerRowIndex: number;
                      } | null;
                      parentFileMetadata: {
                        __typename?: 'FileMetadata';
                        id: string;
                        name: string;
                      };
                      dataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataUnshiftedPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                    }>;
                    latestUutsIfRevisionExists: Array<{
                      __typename?: 'UserUploadedTable';
                      id: string;
                      sheetName: string | null;
                      sheetIndex: number | null;
                      errorMessage: string | null;
                      errorType: string | null;
                      numRows: number | null;
                      status: GQUserUploadedTableStatus;
                      schema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      uirSchema: Array<{
                        __typename?: 'UserUploadedTableSchemaColumn';
                        index: number;
                        kind: GQUserUploadedTableSchemaColumnKind;
                        name: string;
                        alias: string;
                      }> | null;
                      parseConfig: {
                        __typename?: 'UserUploadedTableParseConfig';
                        noHeader: boolean;
                        skipTopNRows: number;
                        skipBottomNRows: number;
                        meltIdVars: Array<string | null> | null;
                      } | null;
                      roboCleanerResult: {
                        __typename?: 'UserUploadedTableRoboCleanerResult';
                        skipTopNRows: number;
                      } | null;
                      userReviewMetadata: {
                        __typename?: 'UserUploadedTableUserReviewMetadata';
                        headerRowIndex: number;
                      } | null;
                      parentFileMetadata: {
                        __typename?: 'FileMetadata';
                        id: string;
                        name: string;
                      };
                      dataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                      rawDataUnshiftedPreview: {
                        __typename?: 'UserUploadedTableDataPreview';
                        dataUntyped: any;
                      } | null;
                    }> | null;
                  } | null;
                  userUploadTask: {
                    __typename?: 'UserUploadTask';
                    id: string;
                    measurementProject: {
                      __typename?: 'MeasurementProject';
                      id: string;
                      name: string;
                    };
                  } | null;
                  transformsIncludingDeleted: Array<{
                    __typename?: 'CustomerTargetSchemaTransform';
                    id: string;
                    deletedAt: Date | null;
                    sql: string;
                    transformTarget: GQCustomerTargetSchemaTransformTarget;
                    businessActivityTypeName: string | null;
                    businessActivityTypeVersionId: string | null;
                    businessActivityTypeVersion: {
                      __typename?: 'BusinessActivityTypeVersion';
                      id: string;
                      name: string;
                    } | null;
                    runs: Array<{
                      __typename?: 'CustomerTargetSchemaTransformRun';
                      id: string;
                      sql: string;
                      error: string | null;
                      createdAt: Date;
                      transform: {
                        __typename?: 'CustomerTargetSchemaTransform';
                        id: string;
                        transformTarget: GQCustomerTargetSchemaTransformTarget;
                        businessActivityTypeName: string | null;
                      };
                      input: {
                        __typename?: 'FileMetadata';
                        id: string;
                        name: string;
                        category: GQFileCategory;
                        revisionRootFileMetadata: {
                          __typename?: 'FileMetadata';
                          id: string;
                          name: string;
                        } | null;
                      };
                      output: {
                        __typename?: 'ParquetData';
                        id: string;
                        numRecords: number;
                        ndjsonSignedUrl: string | null;
                        ndjsonSize: number | null;
                        ndjsonByteBoundaries: Array<number> | null;
                        columns: Array<{
                          __typename?: 'ParquetColumn';
                          name: string;
                          sqlType: string;
                          minValue: any;
                          maxValue: any;
                          numNulls: number;
                          approxDistinctValues: number;
                          sampleValues: Array<any>;
                        }>;
                      } | null;
                      standardizedOutput: {
                        __typename?: 'ParquetData';
                        id: string;
                        numRecords: number;
                        ndjsonSignedUrl: string | null;
                        ndjsonSize: number | null;
                        ndjsonByteBoundaries: Array<number> | null;
                        columns: Array<{
                          __typename?: 'ParquetColumn';
                          name: string;
                          sqlType: string;
                          minValue: any;
                          maxValue: any;
                          numNulls: number;
                          approxDistinctValues: number;
                          sampleValues: Array<any>;
                        }>;
                      } | null;
                      warnings: Array<{
                        __typename?: 'ValidationWarning';
                        message: string;
                        blocking: boolean;
                      }>;
                      runner: { __typename?: 'User'; id: string; name: string };
                    }>;
                  }>;
                } | null;
              } | null>;
            } | null;
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  title: string;
                  description: string | null;
                  state: GQDataIssueState;
                  createdAt: Date;
                  isCustomerInitiated: boolean;
                  measurementProject: {
                    __typename?: 'MeasurementProject';
                    id: string;
                    completedAt: Date | null;
                  };
                  comments: {
                    __typename?: 'CommentConnection';
                    edges: Array<{
                      __typename?: 'CommentEdge';
                      node: {
                        __typename?: 'Comment';
                        id: string;
                        message: string;
                        isPublished: boolean;
                        createdAt: Date;
                        person:
                          | {
                              __typename?: 'User';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | {
                              __typename?: 'WatershedEmployee';
                              id: string;
                              name: string;
                              displayName: string;
                              isWatershedEmployee: boolean;
                              isWatershedContractor: boolean;
                            }
                          | null;
                      } | null;
                    } | null>;
                  };
                } | null;
              } | null>;
            } | null;
            valueMappingTask: {
              __typename?: 'ValueMappingTask';
              id: string;
              mappingRuleId: string;
            } | null;
            valueMappingTasks: Array<{
              __typename?: 'ValueMappingTask';
              id: string;
              mappingRuleId: string;
              status: GQTaskWatershedProcessState;
            }>;
            approvers: Array<{
              __typename?: 'UserWithApprovalStatus';
              approvalStatus: GQApprovalStatus | null;
              user: {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              };
            }>;
            ctsForm: {
              __typename?: 'CtsForm';
              id: string;
              ctsVersionId: string;
              customerTargetSchemaId: string;
              datasetId: string;
              title: string | null;
              description: string | null;
              entries: Array<{
                __typename?: 'CtsFormEntry';
                id: string;
                data: any;
                createdBy: {
                  __typename?: 'User';
                  id: string;
                  displayName: string;
                };
              }>;
            } | null;
          } | null;
        } | null>;
      };
      presetCustomTags: Array<{
        __typename?: 'PresetCustomTag';
        ingestionCustomField: {
          __typename?: 'IngestionCustomField';
          id: string;
          name: string;
          type: GQIngestionCustomFieldType;
          possibleValues: Array<string> | null;
          description: string | null;
          appliesToAllDatasets: boolean;
          specificDatasets: Array<{
            __typename?: 'Dataset';
            id: string;
            name: string;
            canonicalDataset: {
              __typename?: 'CanonicalDataset';
              id: string;
              kind: GQCanonicalDatasetKind;
            } | null;
          }>;
        };
        ingestionCustomFieldDatasource: {
          __typename?: 'IngestionCustomFieldDatasource';
          id: string;
          datasourceId: string;
          ingestionCustomFieldId: string;
          ingestionCustomFieldValue: string;
        };
      }>;
      presetOrgUnits: Array<{
        __typename?: 'PresetOrgUnit';
        orgUnit: { __typename?: 'OrgUnit'; id: string; name: string };
        orgUnitDatasource: { __typename?: 'OrgUnitDatasource'; id: string };
      }>;
    };
  };
};

export type GQSubmitUserUploadTaskMutationVariables = Exact<{
  input: GQSubmitUserUploadTaskInput;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQSubmitUserUploadTaskMutation = {
  __typename?: 'Mutation';
  submitUserUploadTask: {
    __typename?: 'SubmitUserUploadTaskPayload';
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      createdAt: Date;
      state: GQUserUploadTaskState;
      importState: GQDatasourceImportState;
      approvalStatus: GQApprovalStatus;
      isDatasetComplete: boolean | null;
      lockState: GQApprovalTargetLockState;
      datasource: {
        __typename?: 'Datasource';
        id: string;
        name: string;
        isUserConfirmed: boolean | null;
        hasIntegrationOption: boolean;
        hasIntegration: boolean;
        customerTargetSchemaIds: Array<string> | null;
        importInstructionsMd: string | null;
        refreshCadence: GQDatasetCadence;
        hasApiAccess: boolean;
        notesFromWatershedMd: string | null;
        activeIntegrationConnection: {
          __typename?: 'IntegrationConnection';
          id: string;
          additionalIdentifier: string | null;
        } | null;
        instructions: {
          __typename?: 'InstructionsBundle';
          instructionsIntroMd: string | null;
          instructionsDataFormattingMd: string | null;
          sdiInstructionsIntroMd: string | null;
          sdiInstructionsDataFormattingMd: string | null;
          ghgScopes: Array<GQGhgScope> | null;
          instructionsSteps: Array<{
            __typename?: 'InstructionsStep';
            descriptionMd: string;
            publicUrl: string | null;
          }> | null;
          sdiInstructionsSteps: Array<{
            __typename?: 'SdiInstructionsStep';
            title: string;
            descriptionMd: string;
          }> | null;
          sdiInstructionsFaq: Array<{
            __typename?: 'SdiInstructionsFaqType';
            question: string;
            answerMd: string;
          }> | null;
        } | null;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          datasources: Array<{
            __typename?: 'Datasource';
            id: string;
            name: string;
            customerTargetSchemaIds: Array<string> | null;
          }>;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            name: string;
            kind: GQCanonicalDatasetKind;
            instructions: {
              __typename?: 'InstructionsBundle';
              instructionsIntroMd: string | null;
              instructionsDataFormattingMd: string | null;
              sdiInstructionsIntroMd: string | null;
              sdiInstructionsDataFormattingMd: string | null;
              ghgScopes: Array<GQGhgScope> | null;
              instructionsSteps: Array<{
                __typename?: 'InstructionsStep';
                descriptionMd: string;
                publicUrl: string | null;
              }> | null;
              sdiInstructionsSteps: Array<{
                __typename?: 'SdiInstructionsStep';
                title: string;
                descriptionMd: string;
              }> | null;
              sdiInstructionsFaq: Array<{
                __typename?: 'SdiInstructionsFaqType';
                question: string;
                answerMd: string;
              }> | null;
            } | null;
            customerTargetSchemas: Array<{
              __typename?: 'CustomerTargetSchema';
              id: string;
              name: string;
              utilityType: GQBuildingUtilityType | null;
              rank: number;
              latestPublishedVersion: {
                __typename?: 'CustomerTargetSchemaVersion';
                id: string;
                formattingNotesMd: string | null;
                orgDatasetExampleData: Array<{
                  __typename?: 'IngestionExampleData';
                  id: string;
                  title: string;
                  datasetId: string | null;
                  columns: Array<{
                    __typename?: 'IngestionExampleDataColumn';
                    fieldName: string;
                    title: string;
                    description: string;
                    examples: Array<string>;
                    required: boolean;
                    optionalToMap: boolean | null;
                    defaultValue: any | null;
                  }>;
                  rows: Array<{
                    __typename?: 'IngestionExampleDataRow';
                    fields: Array<{
                      __typename?: 'IngestionExampleDataRowField';
                      fieldName: string;
                      value: string;
                    }>;
                  }>;
                }>;
                transforms: Array<{
                  __typename?: 'CustomerTargetSchemaTransform';
                  id: string;
                  businessActivityTypeName: string | null;
                  businessActivityTypeVersion: {
                    __typename?: 'BusinessActivityTypeVersion';
                    id: string;
                  } | null;
                }>;
              } | null;
            }>;
          } | null;
          customExampleData: {
            __typename?: 'TabularData';
            cells: Array<Array<any>>;
          } | null;
        };
        canonicalDatasource: {
          __typename?: 'CanonicalDatasource';
          id: string;
          name: string;
          integrationPartner: {
            __typename?: 'IntegrationPartner';
            id: string;
            name: string;
            logoUrl: string;
            externalId: string;
            dataType: string;
            isRequestOnly: boolean;
            applicableDatasources: Array<{
              __typename?: 'Datasource';
              id: string;
              name: string;
              hasIntegration: boolean;
              isUserConfirmed: boolean | null;
              activeIntegrationConnection: {
                __typename?: 'IntegrationConnection';
                id: string;
              } | null;
              dataset: { __typename?: 'Dataset'; id: string; name: string };
            }>;
            activeIntegrationConnections: Array<{
              __typename?: 'IntegrationConnection';
              id: string;
              additionalIdentifier: string | null;
            }>;
          } | null;
        } | null;
        userUploadTasks: {
          __typename?: 'UserUploadTaskConnection';
          edges: Array<{
            __typename?: 'UserUploadTaskEdge';
            node: {
              __typename?: 'UserUploadTask';
              id: string;
              createdAt: Date;
              state: GQUserUploadTaskState;
              importState: GQDatasourceImportState;
              approvalStatus: GQApprovalStatus;
              isDatasetComplete: boolean | null;
              lockState: GQApprovalTargetLockState;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                displayName: string;
                name: string;
              }>;
              assignee: {
                __typename?: 'User';
                id: string;
                displayName: string;
                name: string;
              } | null;
              measurementTask: {
                __typename?: 'MeasurementTaskFlat';
                id: string;
                status: GQTaskWatershedProcessState;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
              };
              measurementProject: {
                __typename?: 'MeasurementProject';
                id: string;
                name: string;
                active: boolean;
                coverageInterval: YMInterval;
                coverageStartDate: Date;
                coverageEndDate: Date;
                datasetsWithStages: Array<{
                  __typename?: 'DatasetWithStage';
                  id: string;
                  areAllTasksComplete: boolean;
                }>;
                measurementTimeline: {
                  __typename?: 'MeasurementTimeline';
                  id: string;
                  deadline: Date;
                } | null;
              };
              datasetRequirements: Array<{
                __typename?: 'ClimateProgramProjectRequirementDataset';
                id: string;
                additionalNotesMd: string | null;
                climateProgramProject: {
                  __typename: 'ClimateProgramProject';
                  name: string;
                  id: string;
                  canonicalClimateProgramProject: {
                    __typename: 'CanonicalClimateProgramProject';
                    id: string;
                    kind: GQClimateProgramProjectKind;
                    measurementObjectCopy: string | null;
                    routeType: string | null;
                    name: string;
                  } | null;
                };
              }>;
              userUploads: {
                __typename?: 'UserUploadConnection';
                edges: Array<{
                  __typename?: 'UserUploadEdge';
                  node: {
                    __typename?: 'UserUpload';
                    id: string;
                    name: string;
                    status: GQUserUploadStatus;
                    processingMode: GQUserUploadProcessingMode | null;
                    userVisibleErrorMessage: string | null;
                    createdAt: Date;
                    latestAdeIdForSDIFacilitiesFlow: string | null;
                    description: string | null;
                    revisionRoot: string | null;
                    processingWorkflowId: string | null;
                    isBeingValueMapped: boolean;
                    actualUserUploadId: string | null;
                    associatedUtilityCount: number;
                    userUploadKind: GQUserUploadCandidateKind | null;
                    userVisibleAttempt: {
                      __typename?: 'UserUploadAttempt';
                      id: string;
                      name: string;
                      size: number;
                      remoteWritten: boolean;
                      createdAt: Date;
                      userUploadSource: GQUserUploadSource | null;
                      uploader: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                      } | null;
                      userUploadedTables: Array<{
                        __typename?: 'UserUploadedTable';
                        id: string;
                        sheetName: string | null;
                        sheetIndex: number | null;
                        errorMessage: string | null;
                        errorType: string | null;
                        numRows: number | null;
                        status: GQUserUploadedTableStatus;
                        schema: Array<{
                          __typename?: 'UserUploadedTableSchemaColumn';
                          index: number;
                          kind: GQUserUploadedTableSchemaColumnKind;
                          name: string;
                          alias: string;
                        }> | null;
                        uirSchema: Array<{
                          __typename?: 'UserUploadedTableSchemaColumn';
                          index: number;
                          kind: GQUserUploadedTableSchemaColumnKind;
                          name: string;
                          alias: string;
                        }> | null;
                        parseConfig: {
                          __typename?: 'UserUploadedTableParseConfig';
                          noHeader: boolean;
                          skipTopNRows: number;
                          skipBottomNRows: number;
                          meltIdVars: Array<string | null> | null;
                        } | null;
                        roboCleanerResult: {
                          __typename?: 'UserUploadedTableRoboCleanerResult';
                          skipTopNRows: number;
                        } | null;
                        userReviewMetadata: {
                          __typename?: 'UserUploadedTableUserReviewMetadata';
                          headerRowIndex: number;
                        } | null;
                        parentFileMetadata: {
                          __typename?: 'FileMetadata';
                          id: string;
                          name: string;
                        };
                        dataPreview: {
                          __typename?: 'UserUploadedTableDataPreview';
                          dataUntyped: any;
                        } | null;
                        rawDataPreview: {
                          __typename?: 'UserUploadedTableDataPreview';
                          dataUntyped: any;
                        } | null;
                        rawDataUnshiftedPreview: {
                          __typename?: 'UserUploadedTableDataPreview';
                          dataUntyped: any;
                        } | null;
                      }>;
                      latestUutsIfRevisionExists: Array<{
                        __typename?: 'UserUploadedTable';
                        id: string;
                        sheetName: string | null;
                        sheetIndex: number | null;
                        errorMessage: string | null;
                        errorType: string | null;
                        numRows: number | null;
                        status: GQUserUploadedTableStatus;
                        schema: Array<{
                          __typename?: 'UserUploadedTableSchemaColumn';
                          index: number;
                          kind: GQUserUploadedTableSchemaColumnKind;
                          name: string;
                          alias: string;
                        }> | null;
                        uirSchema: Array<{
                          __typename?: 'UserUploadedTableSchemaColumn';
                          index: number;
                          kind: GQUserUploadedTableSchemaColumnKind;
                          name: string;
                          alias: string;
                        }> | null;
                        parseConfig: {
                          __typename?: 'UserUploadedTableParseConfig';
                          noHeader: boolean;
                          skipTopNRows: number;
                          skipBottomNRows: number;
                          meltIdVars: Array<string | null> | null;
                        } | null;
                        roboCleanerResult: {
                          __typename?: 'UserUploadedTableRoboCleanerResult';
                          skipTopNRows: number;
                        } | null;
                        userReviewMetadata: {
                          __typename?: 'UserUploadedTableUserReviewMetadata';
                          headerRowIndex: number;
                        } | null;
                        parentFileMetadata: {
                          __typename?: 'FileMetadata';
                          id: string;
                          name: string;
                        };
                        dataPreview: {
                          __typename?: 'UserUploadedTableDataPreview';
                          dataUntyped: any;
                        } | null;
                        rawDataPreview: {
                          __typename?: 'UserUploadedTableDataPreview';
                          dataUntyped: any;
                        } | null;
                        rawDataUnshiftedPreview: {
                          __typename?: 'UserUploadedTableDataPreview';
                          dataUntyped: any;
                        } | null;
                      }> | null;
                    } | null;
                    userUploadTask: {
                      __typename?: 'UserUploadTask';
                      id: string;
                      measurementProject: {
                        __typename?: 'MeasurementProject';
                        id: string;
                        name: string;
                      };
                    } | null;
                    transformsIncludingDeleted: Array<{
                      __typename?: 'CustomerTargetSchemaTransform';
                      id: string;
                      deletedAt: Date | null;
                      sql: string;
                      transformTarget: GQCustomerTargetSchemaTransformTarget;
                      businessActivityTypeName: string | null;
                      businessActivityTypeVersionId: string | null;
                      businessActivityTypeVersion: {
                        __typename?: 'BusinessActivityTypeVersion';
                        id: string;
                        name: string;
                      } | null;
                      runs: Array<{
                        __typename?: 'CustomerTargetSchemaTransformRun';
                        id: string;
                        sql: string;
                        error: string | null;
                        createdAt: Date;
                        transform: {
                          __typename?: 'CustomerTargetSchemaTransform';
                          id: string;
                          transformTarget: GQCustomerTargetSchemaTransformTarget;
                          businessActivityTypeName: string | null;
                        };
                        input: {
                          __typename?: 'FileMetadata';
                          id: string;
                          name: string;
                          category: GQFileCategory;
                          revisionRootFileMetadata: {
                            __typename?: 'FileMetadata';
                            id: string;
                            name: string;
                          } | null;
                        };
                        output: {
                          __typename?: 'ParquetData';
                          id: string;
                          numRecords: number;
                          ndjsonSignedUrl: string | null;
                          ndjsonSize: number | null;
                          ndjsonByteBoundaries: Array<number> | null;
                          columns: Array<{
                            __typename?: 'ParquetColumn';
                            name: string;
                            sqlType: string;
                            minValue: any;
                            maxValue: any;
                            numNulls: number;
                            approxDistinctValues: number;
                            sampleValues: Array<any>;
                          }>;
                        } | null;
                        standardizedOutput: {
                          __typename?: 'ParquetData';
                          id: string;
                          numRecords: number;
                          ndjsonSignedUrl: string | null;
                          ndjsonSize: number | null;
                          ndjsonByteBoundaries: Array<number> | null;
                          columns: Array<{
                            __typename?: 'ParquetColumn';
                            name: string;
                            sqlType: string;
                            minValue: any;
                            maxValue: any;
                            numNulls: number;
                            approxDistinctValues: number;
                            sampleValues: Array<any>;
                          }>;
                        } | null;
                        warnings: Array<{
                          __typename?: 'ValidationWarning';
                          message: string;
                          blocking: boolean;
                        }>;
                        runner: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                        };
                      }>;
                    }>;
                  } | null;
                } | null>;
              } | null;
              issues: {
                __typename?: 'DataIssueConnection';
                edges: Array<{
                  __typename?: 'DataIssueEdge';
                  node: {
                    __typename?: 'DataIssue';
                    id: string;
                    title: string;
                    description: string | null;
                    state: GQDataIssueState;
                    createdAt: Date;
                    isCustomerInitiated: boolean;
                    measurementProject: {
                      __typename?: 'MeasurementProject';
                      id: string;
                      completedAt: Date | null;
                    };
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          message: string;
                          isPublished: boolean;
                          createdAt: Date;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                name: string;
                                displayName: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                name: string;
                                displayName: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  } | null;
                } | null>;
              } | null;
              valueMappingTask: {
                __typename?: 'ValueMappingTask';
                id: string;
                mappingRuleId: string;
              } | null;
              valueMappingTasks: Array<{
                __typename?: 'ValueMappingTask';
                id: string;
                mappingRuleId: string;
                status: GQTaskWatershedProcessState;
              }>;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              ctsForm: {
                __typename?: 'CtsForm';
                id: string;
                ctsVersionId: string;
                customerTargetSchemaId: string;
                datasetId: string;
                title: string | null;
                description: string | null;
                entries: Array<{
                  __typename?: 'CtsFormEntry';
                  id: string;
                  data: any;
                  createdBy: {
                    __typename?: 'User';
                    id: string;
                    displayName: string;
                  };
                }>;
              } | null;
            } | null;
          } | null>;
        };
        presetCustomTags: Array<{
          __typename?: 'PresetCustomTag';
          ingestionCustomField: {
            __typename?: 'IngestionCustomField';
            id: string;
            name: string;
            type: GQIngestionCustomFieldType;
            possibleValues: Array<string> | null;
            description: string | null;
            appliesToAllDatasets: boolean;
            specificDatasets: Array<{
              __typename?: 'Dataset';
              id: string;
              name: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            }>;
          };
          ingestionCustomFieldDatasource: {
            __typename?: 'IngestionCustomFieldDatasource';
            id: string;
            datasourceId: string;
            ingestionCustomFieldId: string;
            ingestionCustomFieldValue: string;
          };
        }>;
        presetOrgUnits: Array<{
          __typename?: 'PresetOrgUnit';
          orgUnit: { __typename?: 'OrgUnit'; id: string; name: string };
          orgUnitDatasource: { __typename?: 'OrgUnitDatasource'; id: string };
        }>;
      };
      facilitiesPreview: {
        __typename?: 'FacilitiesPreview';
        totalCount: number;
      };
      assignees: Array<{
        __typename?: 'User';
        id: string;
        displayName: string;
        name: string;
      }>;
      assignee: {
        __typename?: 'User';
        id: string;
        displayName: string;
        name: string;
      } | null;
      measurementTask: {
        __typename?: 'MeasurementTaskFlat';
        id: string;
        status: GQTaskWatershedProcessState;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
      };
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
        active: boolean;
        coverageInterval: YMInterval;
        coverageStartDate: Date;
        coverageEndDate: Date;
        datasetsWithStages: Array<{
          __typename?: 'DatasetWithStage';
          id: string;
          areAllTasksComplete: boolean;
        }>;
        measurementTimeline: {
          __typename?: 'MeasurementTimeline';
          id: string;
          deadline: Date;
        } | null;
      };
      datasetRequirements: Array<{
        __typename?: 'ClimateProgramProjectRequirementDataset';
        id: string;
        additionalNotesMd: string | null;
        climateProgramProject: {
          __typename: 'ClimateProgramProject';
          name: string;
          id: string;
          canonicalClimateProgramProject: {
            __typename: 'CanonicalClimateProgramProject';
            id: string;
            kind: GQClimateProgramProjectKind;
            measurementObjectCopy: string | null;
            routeType: string | null;
            name: string;
          } | null;
        };
      }>;
      userUploads: {
        __typename?: 'UserUploadConnection';
        edges: Array<{
          __typename?: 'UserUploadEdge';
          node: {
            __typename?: 'UserUpload';
            id: string;
            name: string;
            status: GQUserUploadStatus;
            processingMode: GQUserUploadProcessingMode | null;
            userVisibleErrorMessage: string | null;
            createdAt: Date;
            latestAdeIdForSDIFacilitiesFlow: string | null;
            description: string | null;
            revisionRoot: string | null;
            processingWorkflowId: string | null;
            isBeingValueMapped: boolean;
            actualUserUploadId: string | null;
            associatedUtilityCount: number;
            userUploadKind: GQUserUploadCandidateKind | null;
            userVisibleAttempt: {
              __typename?: 'UserUploadAttempt';
              id: string;
              name: string;
              size: number;
              remoteWritten: boolean;
              createdAt: Date;
              userUploadSource: GQUserUploadSource | null;
              uploader: {
                __typename?: 'User';
                id: string;
                name: string;
              } | null;
              userUploadedTables: Array<{
                __typename?: 'UserUploadedTable';
                id: string;
                sheetName: string | null;
                sheetIndex: number | null;
                errorMessage: string | null;
                errorType: string | null;
                numRows: number | null;
                status: GQUserUploadedTableStatus;
                schema: Array<{
                  __typename?: 'UserUploadedTableSchemaColumn';
                  index: number;
                  kind: GQUserUploadedTableSchemaColumnKind;
                  name: string;
                  alias: string;
                }> | null;
                uirSchema: Array<{
                  __typename?: 'UserUploadedTableSchemaColumn';
                  index: number;
                  kind: GQUserUploadedTableSchemaColumnKind;
                  name: string;
                  alias: string;
                }> | null;
                parseConfig: {
                  __typename?: 'UserUploadedTableParseConfig';
                  noHeader: boolean;
                  skipTopNRows: number;
                  skipBottomNRows: number;
                  meltIdVars: Array<string | null> | null;
                } | null;
                roboCleanerResult: {
                  __typename?: 'UserUploadedTableRoboCleanerResult';
                  skipTopNRows: number;
                } | null;
                userReviewMetadata: {
                  __typename?: 'UserUploadedTableUserReviewMetadata';
                  headerRowIndex: number;
                } | null;
                parentFileMetadata: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                };
                dataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataUnshiftedPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
              }>;
              latestUutsIfRevisionExists: Array<{
                __typename?: 'UserUploadedTable';
                id: string;
                sheetName: string | null;
                sheetIndex: number | null;
                errorMessage: string | null;
                errorType: string | null;
                numRows: number | null;
                status: GQUserUploadedTableStatus;
                schema: Array<{
                  __typename?: 'UserUploadedTableSchemaColumn';
                  index: number;
                  kind: GQUserUploadedTableSchemaColumnKind;
                  name: string;
                  alias: string;
                }> | null;
                uirSchema: Array<{
                  __typename?: 'UserUploadedTableSchemaColumn';
                  index: number;
                  kind: GQUserUploadedTableSchemaColumnKind;
                  name: string;
                  alias: string;
                }> | null;
                parseConfig: {
                  __typename?: 'UserUploadedTableParseConfig';
                  noHeader: boolean;
                  skipTopNRows: number;
                  skipBottomNRows: number;
                  meltIdVars: Array<string | null> | null;
                } | null;
                roboCleanerResult: {
                  __typename?: 'UserUploadedTableRoboCleanerResult';
                  skipTopNRows: number;
                } | null;
                userReviewMetadata: {
                  __typename?: 'UserUploadedTableUserReviewMetadata';
                  headerRowIndex: number;
                } | null;
                parentFileMetadata: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                };
                dataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
                rawDataUnshiftedPreview: {
                  __typename?: 'UserUploadedTableDataPreview';
                  dataUntyped: any;
                } | null;
              }> | null;
            } | null;
            userUploadTask: {
              __typename?: 'UserUploadTask';
              id: string;
              measurementProject: {
                __typename?: 'MeasurementProject';
                id: string;
                name: string;
              };
            } | null;
            transformsIncludingDeleted: Array<{
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              deletedAt: Date | null;
              sql: string;
              transformTarget: GQCustomerTargetSchemaTransformTarget;
              businessActivityTypeName: string | null;
              businessActivityTypeVersionId: string | null;
              businessActivityTypeVersion: {
                __typename?: 'BusinessActivityTypeVersion';
                id: string;
                name: string;
              } | null;
              runs: Array<{
                __typename?: 'CustomerTargetSchemaTransformRun';
                id: string;
                sql: string;
                error: string | null;
                createdAt: Date;
                transform: {
                  __typename?: 'CustomerTargetSchemaTransform';
                  id: string;
                  transformTarget: GQCustomerTargetSchemaTransformTarget;
                  businessActivityTypeName: string | null;
                };
                input: {
                  __typename?: 'FileMetadata';
                  id: string;
                  name: string;
                  category: GQFileCategory;
                  revisionRootFileMetadata: {
                    __typename?: 'FileMetadata';
                    id: string;
                    name: string;
                  } | null;
                };
                output: {
                  __typename?: 'ParquetData';
                  id: string;
                  numRecords: number;
                  ndjsonSignedUrl: string | null;
                  ndjsonSize: number | null;
                  ndjsonByteBoundaries: Array<number> | null;
                  columns: Array<{
                    __typename?: 'ParquetColumn';
                    name: string;
                    sqlType: string;
                    minValue: any;
                    maxValue: any;
                    numNulls: number;
                    approxDistinctValues: number;
                    sampleValues: Array<any>;
                  }>;
                } | null;
                standardizedOutput: {
                  __typename?: 'ParquetData';
                  id: string;
                  numRecords: number;
                  ndjsonSignedUrl: string | null;
                  ndjsonSize: number | null;
                  ndjsonByteBoundaries: Array<number> | null;
                  columns: Array<{
                    __typename?: 'ParquetColumn';
                    name: string;
                    sqlType: string;
                    minValue: any;
                    maxValue: any;
                    numNulls: number;
                    approxDistinctValues: number;
                    sampleValues: Array<any>;
                  }>;
                } | null;
                warnings: Array<{
                  __typename?: 'ValidationWarning';
                  message: string;
                  blocking: boolean;
                }>;
                runner: { __typename?: 'User'; id: string; name: string };
              }>;
            }>;
          } | null;
        } | null>;
      } | null;
      issues: {
        __typename?: 'DataIssueConnection';
        edges: Array<{
          __typename?: 'DataIssueEdge';
          node: {
            __typename?: 'DataIssue';
            id: string;
            title: string;
            description: string | null;
            state: GQDataIssueState;
            createdAt: Date;
            isCustomerInitiated: boolean;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              completedAt: Date | null;
            };
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  message: string;
                  isPublished: boolean;
                  createdAt: Date;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      } | null;
      valueMappingTask: {
        __typename?: 'ValueMappingTask';
        id: string;
        mappingRuleId: string;
      } | null;
      valueMappingTasks: Array<{
        __typename?: 'ValueMappingTask';
        id: string;
        mappingRuleId: string;
        status: GQTaskWatershedProcessState;
      }>;
      approvers: Array<{
        __typename?: 'UserWithApprovalStatus';
        approvalStatus: GQApprovalStatus | null;
        user: {
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        };
      }>;
      ctsForm: {
        __typename?: 'CtsForm';
        id: string;
        ctsVersionId: string;
        customerTargetSchemaId: string;
        datasetId: string;
        title: string | null;
        description: string | null;
        entries: Array<{
          __typename?: 'CtsFormEntry';
          id: string;
          data: any;
          createdBy: { __typename?: 'User'; id: string; displayName: string };
        }>;
      } | null;
    } | null;
  };
};

export type GQSingleFacilityPageQueryVariables = Exact<{
  datasourceId: Scalars['ID']['input'];
  measurementProjectId: Scalars['ID']['input'];
  buildingId: Scalars['ID']['input'];
}>;

export type GQSingleFacilityPageQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  userUploadTaskForDatasource: {
    __typename?: 'UserUploadTask';
    id: string;
    lockState: GQApprovalTargetLockState;
    datasource: {
      __typename?: 'Datasource';
      id: string;
      name: string;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
          customerTargetSchemas: Array<{
            __typename?: 'CustomerTargetSchema';
            id: string;
            name: string;
            utilityType: GQBuildingUtilityType | null;
            rank: number;
            latestPublishedVersion: {
              __typename?: 'CustomerTargetSchemaVersion';
              id: string;
              formattingNotesMd: string | null;
              orgDatasetExampleData: Array<{
                __typename?: 'IngestionExampleData';
                id: string;
                title: string;
                datasetId: string | null;
                columns: Array<{
                  __typename?: 'IngestionExampleDataColumn';
                  fieldName: string;
                  title: string;
                  description: string;
                  examples: Array<string>;
                  required: boolean;
                  optionalToMap: boolean | null;
                  defaultValue: any | null;
                }>;
                rows: Array<{
                  __typename?: 'IngestionExampleDataRow';
                  fields: Array<{
                    __typename?: 'IngestionExampleDataRowField';
                    fieldName: string;
                    value: string;
                  }>;
                }>;
              }>;
              transforms: Array<{
                __typename?: 'CustomerTargetSchemaTransform';
                id: string;
                businessActivityTypeName: string | null;
                businessActivityTypeVersion: {
                  __typename?: 'BusinessActivityTypeVersion';
                  id: string;
                } | null;
              }>;
            } | null;
          }>;
        } | null;
        instructions: {
          __typename?: 'InstructionsBundle';
          sdiInstructionsSteps: Array<{
            __typename?: 'SdiInstructionsStep';
            title: string;
            descriptionMd: string;
          }> | null;
          sdiInstructionsFaq: Array<{
            __typename?: 'SdiInstructionsFaqType';
            question: string;
            answerMd: string;
          }> | null;
        } | null;
        datasources: Array<{
          __typename?: 'Datasource';
          id: string;
          name: string;
          customerTargetSchemaIds: Array<string> | null;
        }>;
      };
    };
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      coverageInterval: YMInterval;
      active: boolean;
    };
  };
  facility: {
    __typename?: 'Facility';
    id: string;
    createdAt: Date;
    updatedAt: Date;
    editState: GQBuildingEditState;
    streetAddress: string | null;
    streetAddress2: string | null;
    postalCode: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    startYearMonth: YearMonth | null;
    endYearMonth: YearMonth | null;
    sizeUnit: GQBuildingSizeUnit | null;
    buildingKind: string | null;
    buildingSubkind: string | null;
    leaseType: GQBuildingLeaseType | null;
    buildingName: string;
    buildingUniqueId: string;
    isWithoutNaturalGas: boolean | null;
    isWithoutRefrigerants: boolean | null;
    periods: Array<{
      __typename?: 'BuildingPeriod';
      id: string;
      kind: GQBuildingPeriodKind;
      sizeQuantity: number | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      partialCleanPowerPercent: number | null;
      cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
      powerUsageEffectiveness: number | null;
      isBuildingEmpty: boolean | null;
      customFieldValue: string | null;
      customFieldId: string | null;
      orgUnitTypeStableId: string | null;
      orgUnitStableId: string | null;
    }>;
    supportingDocuments: Array<{
      __typename?: 'SupportingDocument';
      id: string;
      createdAt: Date;
      utilityType: GQBuildingUtilityType | null;
      buildingUtilityId: string | null;
      file: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
        remoteWritten: boolean;
      };
    }>;
    latestApprovedVersion: {
      __typename?: 'BuildingForDiff';
      id: string | null;
      streetAddress: string | null;
      streetAddress2: string | null;
      postalCode: string | null;
      city: string | null;
      state: string | null;
      country: string | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      sizeUnit: GQBuildingSizeUnit | null;
      buildingKind: string | null;
      buildingSubkind: string | null;
      leaseType: GQBuildingLeaseType | null;
      buildingName: string;
      buildingUniqueId: string;
      isWithoutNaturalGas: boolean | null;
      isWithoutRefrigerants: boolean | null;
      periods: Array<{
        __typename?: 'BuildingPeriodForDiff';
        kind: GQBuildingPeriodKind;
        sizeQuantity: number | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        partialCleanPowerPercent: number | null;
        cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
        powerUsageEffectiveness: number | null;
        isBuildingEmpty: boolean | null;
        customFieldValue: string | null;
        customFieldId: string | null;
        orgUnitTypeStableId: string | null;
        orgUnitStableId: string | null;
      }>;
    } | null;
  };
  organization: {
    __typename?: 'Organization';
    id: string;
    ingestionCustomFields: Array<{
      __typename?: 'IngestionCustomField';
      bartTagName: string;
      id: string;
      name: string;
      description: string | null;
      type: GQIngestionCustomFieldType;
      possibleValues: Array<string> | null;
      appliesToAllDatasets: boolean;
      specificUtilityTypes: Array<GQBuildingUtilityType>;
      ingestionCustomFieldDatasources: Array<{
        __typename?: 'IngestionCustomFieldDatasource';
        id: string;
      }> | null;
      specificDatasets: Array<{ __typename?: 'Dataset'; id: string }>;
    }>;
  };
};

export type GQDatasourceValueMappingPageQueryVariables = Exact<{
  datasourceId: InputMaybe<Scalars['ID']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  valueMappingRuleId: Scalars['String']['input'];
}>;

export type GQDatasourceValueMappingPageQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  datasourceValueMappingRule: {
    __typename?: 'DatasourceValueMappingRule';
    id: string;
    featureFlag: string | null;
    valueMappingConfigId: string;
    applicableDatasetKinds: Array<GQCanonicalDatasetKind>;
    revisionData: {
      __typename?: 'DatasourceValueMappingRevisionData';
      id: string;
      revisionState: GQReferenceDataRevisionState | null;
    };
    valueMapping: {
      __typename?: 'ValueMapping';
      id: string;
      revisionState: GQReferenceDataRevisionState | null;
      valueMappingRows: Array<{
        __typename?: 'ValueMappingRow';
        id: string;
        baseRowJson: string;
      }>;
    } | null;
    valueMappingTask: {
      __typename?: 'ValueMappingTask';
      id: string;
      publishStatus: GQValueMappingPublishStatus;
      issues: {
        __typename?: 'DataIssueConnection';
        edges: Array<{
          __typename?: 'DataIssueEdge';
          node: {
            __typename?: 'DataIssue';
            id: string;
            title: string;
            description: string | null;
            state: GQDataIssueState;
            createdAt: Date;
            isCustomerInitiated: boolean;
            measurementProject: {
              __typename?: 'MeasurementProject';
              id: string;
              completedAt: Date | null;
            };
            comments: {
              __typename?: 'CommentConnection';
              edges: Array<{
                __typename?: 'CommentEdge';
                node: {
                  __typename?: 'Comment';
                  id: string;
                  message: string;
                  isPublished: boolean;
                  createdAt: Date;
                  person:
                    | {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | {
                        __typename?: 'WatershedEmployee';
                        id: string;
                        name: string;
                        displayName: string;
                        isWatershedEmployee: boolean;
                        isWatershedContractor: boolean;
                      }
                    | null;
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      } | null;
    };
  };
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    orgId: string;
    active: boolean;
    datasetsWithStages: Array<{
      __typename?: 'DatasetWithStage';
      id: string;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
          name: string;
        } | null;
      } | null;
    }>;
    valueMappingTask: {
      __typename?: 'ValueMappingTask';
      id: string;
      statusSimplified: GQTaskWatershedProcessStateSimplified;
      publishStatus: GQValueMappingPublishStatus;
    } | null;
  };
};

export type GQCustomFieldsSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQCustomFieldsSettingsQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    ingestionCustomFields: Array<{
      __typename?: 'IngestionCustomField';
      bartTagName: string;
      id: string;
      name: string;
      description: string | null;
      type: GQIngestionCustomFieldType;
      possibleValues: Array<string> | null;
      appliesToAllDatasets: boolean;
      createdAt: Date;
      exampleValues: Array<string> | null;
      specificUtilityTypes: Array<GQBuildingUtilityType>;
      ingestionCustomFieldDatasources: Array<{
        __typename?: 'IngestionCustomFieldDatasource';
        id: string;
      }> | null;
      specificDatasets: Array<{
        __typename?: 'Dataset';
        id: string;
        name: string;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
        } | null;
      }>;
    }>;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      instructions: {
        __typename?: 'InstructionsBundle';
        sdiInstructionsSteps: Array<{
          __typename?: 'SdiInstructionsStep';
          title: string;
          descriptionMd: string;
        }> | null;
        sdiInstructionsFaq: Array<{
          __typename?: 'SdiInstructionsFaqType';
          question: string;
          answerMd: string;
        }> | null;
      } | null;
      canonicalDataset: {
        __typename?: 'CanonicalDataset';
        id: string;
        kind: GQCanonicalDatasetKind;
        customerTargetSchemas: Array<{
          __typename?: 'CustomerTargetSchema';
          id: string;
          name: string;
          utilityType: GQBuildingUtilityType | null;
          rank: number;
          latestPublishedVersion: {
            __typename?: 'CustomerTargetSchemaVersion';
            id: string;
            formattingNotesMd: string | null;
            orgDatasetExampleData: Array<{
              __typename?: 'IngestionExampleData';
              id: string;
              title: string;
              datasetId: string | null;
              columns: Array<{
                __typename?: 'IngestionExampleDataColumn';
                fieldName: string;
                title: string;
                description: string;
                examples: Array<string>;
                required: boolean;
                optionalToMap: boolean | null;
                defaultValue: any | null;
              }>;
              rows: Array<{
                __typename?: 'IngestionExampleDataRow';
                fields: Array<{
                  __typename?: 'IngestionExampleDataRowField';
                  fieldName: string;
                  value: string;
                }>;
              }>;
            }>;
            transforms: Array<{
              __typename?: 'CustomerTargetSchemaTransform';
              id: string;
              businessActivityTypeName: string | null;
              businessActivityTypeVersion: {
                __typename?: 'BusinessActivityTypeVersion';
                id: string;
              } | null;
            }>;
          } | null;
        }>;
      } | null;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
        customerTargetSchemaIds: Array<string> | null;
      }>;
    }>;
  };
  footprintTags: Array<{
    __typename?: 'FootprintTag';
    id: string;
    tagName: string;
    rulesList: any;
  }>;
};

export type GQUserUploadVerificationLogsQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQUserUploadVerificationLogsQuery = {
  __typename?: 'Query';
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    status: GQMeasurementProjectStatus;
    userUploadVerificationLogs: Array<{
      __typename?: 'UserUploadVerificationLog';
      id: string;
      fileId: string;
      fileName: string;
      dataset: string;
      datasource: string;
      dataReviewCompleted: boolean;
      sheetName: string | null;
      submittedAt: Date;
      submittedBy: string;
      transformation: string | null;
    } | null>;
  };
};

export type GQDataGovernanceDownloadAllUrlQueryVariables = Exact<{
  measurementProjectId: Scalars['ID']['input'];
}>;

export type GQDataGovernanceDownloadAllUrlQuery = {
  __typename?: 'Query';
  dataGovernanceDownloadAllUrl: string;
};

export type GQNewMeasurementWizardQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQNewMeasurementWizardQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  climateProgramProjects: Array<{
    __typename: 'ClimateProgramProject';
    id: string;
    name: string;
    description: string;
    kind: GQClimateProgramProjectKind;
    startDate: Date | null;
    endDate: Date | null;
    plannedYear: number;
    completedAt: Date | null;
    status: GQClimateProgramProjectStatus;
    iconType: GQClimateProgramProjectIconType;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
    } | null;
    canonicalClimateProgramProject: {
      __typename: 'CanonicalClimateProgramProject';
      id: string;
      routeType: string | null;
      name: string;
      kind: GQClimateProgramProjectKind;
      measurementObjectCopy: string | null;
    } | null;
    datasetRequirements: Array<{
      __typename?: 'ClimateProgramProjectRequirementDataset';
      id: string;
      additionalNotesMd: string | null;
      dataset: {
        __typename?: 'Dataset';
        id: string;
        name: string;
        instructions: {
          __typename?: 'InstructionsBundle';
          instructionsIntroMd: string | null;
        } | null;
        canonicalDataset: {
          __typename?: 'CanonicalDataset';
          id: string;
          kind: GQCanonicalDatasetKind;
          instructions: {
            __typename?: 'InstructionsBundle';
            sdiInstructionsIntroMd: string | null;
          } | null;
        } | null;
      };
      canonicalGhgpRequirement: {
        __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
        id: string;
        ghgScope: number;
        ghgScope3Category: number | null;
        minimumFidelity: GQClimateProgramProjectRequirementFidelity;
      } | null;
    }>;
  }>;
  canonicalClimateProgramProjects: Array<{
    __typename: 'CanonicalClimateProgramProject';
    id: string;
    kind: GQClimateProgramProjectKind;
    measurementObjectCopy: string | null;
    routeType: string | null;
    name: string;
    description: string;
    costEstimate: string | null;
    timeEstimate: string | null;
    iconType: GQClimateProgramProjectIconType;
    hasBenchmarkMapping: boolean;
    matchObjectType: string | null;
    reportKind: string | null;
    peerBenchmarkCompanies: Array<{
      __typename?: 'Company';
      id: string;
      name: string;
      fullLogoUrl: string | null;
    }>;
  }>;
  organization: {
    __typename?: 'Organization';
    id: string;
    fiscalYearStartMonth: number | null;
  };
};

export type GQCreateMeasurementProjectFromWizardMutationVariables = Exact<{
  input: GQCreateMeasurementProjectFromWizardInput;
}>;

export type GQCreateMeasurementProjectFromWizardMutation = {
  __typename?: 'Mutation';
  createMeasurementProjectFromWizard: {
    __typename?: 'CreateMeasurementProjectFromWizardPayload';
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      setupAt: Date | null;
      completedAt: Date | null;
      footprintStatus: GQMeasurementProjectFootprintStatus;
      coverageInterval: YMInterval;
      deadline: Date | null;
      status: GQMeasurementProjectStatus;
      createdAt: Date;
      coverageStartDate: Date;
      coverageEndDate: Date;
      userUploadTasks: {
        __typename?: 'UserUploadTaskConnection';
        edges: Array<{
          __typename?: 'UserUploadTaskEdge';
          node: {
            __typename?: 'UserUploadTask';
            id: string;
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              userCanAccessDetails: boolean | null;
              userCanApproveDatasource: boolean | null;
              userCanManageDatasource: boolean | null;
              isUserConfirmed: boolean | null;
              hasIntegration: boolean;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                canonicalDataset: {
                  __typename?: 'CanonicalDataset';
                  id: string;
                  kind: GQCanonicalDatasetKind;
                } | null;
              };
            };
            issues: {
              __typename?: 'DataIssueConnection';
              edges: Array<{
                __typename?: 'DataIssueEdge';
                node: {
                  __typename?: 'DataIssue';
                  id: string;
                  state: GQDataIssueState;
                  title: string;
                } | null;
              } | null>;
            } | null;
          } | null;
        } | null>;
      };
      measurementTimeline: {
        __typename?: 'MeasurementTimeline';
        id: string;
        kickoff: Date;
        deadline: Date;
      } | null;
      measurementTasks: Array<{
        __typename?: 'MeasurementParentTask';
        taskGroup: string;
        id: string;
        title: string;
        link: string | null;
        lockState: GQApprovalTargetLockState | null;
        isAssignedToWatershed: boolean;
        statusSimplified: GQTaskWatershedProcessStateSimplified;
        subTasks: Array<
          | {
              __typename?: 'MeasurementParentTask';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
          | {
              __typename?: 'MeasurementTaskFlat';
              taskGroup: string;
              id: string;
              title: string;
              link: string | null;
              lockState: GQApprovalTargetLockState | null;
              isAssignedToWatershed: boolean;
              statusSimplified: GQTaskWatershedProcessStateSimplified;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
              } | null;
              assignees: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                didLatestEmailBounce: boolean;
              }>;
              approvers: Array<{
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
              }>;
              object:
                | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                | {
                    __typename: 'DataIssue';
                    id: string;
                    linkedObject:
                      | {
                          __typename: 'FinancialsReviewItem';
                          id: string;
                          group: {
                            __typename: 'FinancialsReviewGroup';
                            id: string;
                            datasources: Array<{
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            }>;
                          };
                        }
                      | {
                          __typename: 'MeasurementVerificationItemQuestion';
                          id: string;
                          item: {
                            __typename: 'MeasurementVerificationItem';
                            id: string;
                            maybeDataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            } | null;
                          };
                        }
                      | {
                          __typename: 'UserUploadTask';
                          id: string;
                          datasource: {
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          };
                        }
                      | { __typename: 'ValueMappingTask' }
                      | null;
                    comments: {
                      __typename?: 'CommentConnection';
                      edges: Array<{
                        __typename?: 'CommentEdge';
                        node: {
                          __typename?: 'Comment';
                          id: string;
                          createdAt: Date;
                          isPublished: boolean;
                          person:
                            | {
                                __typename?: 'User';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | {
                                __typename?: 'WatershedEmployee';
                                id: string;
                                isWatershedEmployee: boolean;
                                isWatershedContractor: boolean;
                              }
                            | null;
                        } | null;
                      } | null>;
                    };
                  }
                | { __typename: 'FacilitiesSetup'; id: string }
                | {
                    __typename: 'FinancialsReviewGroup';
                    id: string;
                    datasources: Array<{
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    }>;
                  }
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | { __typename: 'FootprintQuestion'; id: string }
                | {
                    __typename: 'MeasurementDataMappingTask';
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | { __typename: 'MeasurementProject'; id: string }
                | {
                    __typename: 'MeasurementProjectDataset';
                    modelId: string | null;
                    isCompleted: boolean;
                    id: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItem';
                    id: string;
                    mviStatus: GQMeasurementVerificationItemStatus;
                    maybeDataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'UserUploadTask';
                    state: GQUserUploadTaskState;
                    id: string;
                    approvers: Array<{
                      __typename?: 'UserWithApprovalStatus';
                      approvalStatus: GQApprovalStatus | null;
                      user: {
                        __typename?: 'User';
                        id: string;
                        name: string;
                        displayName: string;
                      };
                    }>;
                    datasource: {
                      __typename?: 'Datasource';
                      hasIntegration: boolean;
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        canonicalDataset: {
                          __typename?: 'CanonicalDataset';
                          id: string;
                          kind: GQCanonicalDatasetKind;
                        } | null;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask'; id: string }
                | { __typename: 'VendorMatchingTask'; id: string };
            }
        >;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
        } | null;
        assignees: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
          didLatestEmailBounce: boolean;
        }>;
        approvers: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        }>;
        object:
          | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
          | {
              __typename: 'DataIssue';
              id: string;
              linkedObject:
                | {
                    __typename: 'FinancialsReviewItem';
                    id: string;
                    group: {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    };
                  }
                | {
                    __typename: 'MeasurementVerificationItemQuestion';
                    id: string;
                    item: {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      } | null;
                    };
                  }
                | {
                    __typename: 'UserUploadTask';
                    id: string;
                    datasource: {
                      __typename?: 'Datasource';
                      id: string;
                      name: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                      };
                    };
                  }
                | { __typename: 'ValueMappingTask' }
                | null;
              comments: {
                __typename?: 'CommentConnection';
                edges: Array<{
                  __typename?: 'CommentEdge';
                  node: {
                    __typename?: 'Comment';
                    id: string;
                    createdAt: Date;
                    isPublished: boolean;
                    person:
                      | {
                          __typename?: 'User';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | {
                          __typename?: 'WatershedEmployee';
                          id: string;
                          isWatershedEmployee: boolean;
                          isWatershedContractor: boolean;
                        }
                      | null;
                  } | null;
                } | null>;
              };
            }
          | { __typename: 'FacilitiesSetup'; id: string }
          | {
              __typename: 'FinancialsReviewGroup';
              id: string;
              datasources: Array<{
                __typename?: 'Datasource';
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                };
              }>;
            }
          | {
              __typename: 'FinancialsReviewItem';
              id: string;
              group: {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              };
            }
          | { __typename: 'FootprintQuestion'; id: string }
          | {
              __typename: 'MeasurementDataMappingTask';
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | { __typename: 'MeasurementProject'; id: string }
          | {
              __typename: 'MeasurementProjectDataset';
              modelId: string | null;
              isCompleted: boolean;
              id: string;
              dataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              };
            }
          | {
              __typename: 'MeasurementVerificationItem';
              id: string;
              mviStatus: GQMeasurementVerificationItemStatus;
              maybeDataset: {
                __typename?: 'Dataset';
                id: string;
                name: string;
                materialCsrdStandards: Array<GQCsrdStandard>;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                }>;
              } | null;
            }
          | {
              __typename: 'UserUploadTask';
              state: GQUserUploadTaskState;
              id: string;
              approvers: Array<{
                __typename?: 'UserWithApprovalStatus';
                approvalStatus: GQApprovalStatus | null;
                user: {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                };
              }>;
              datasource: {
                __typename?: 'Datasource';
                hasIntegration: boolean;
                id: string;
                name: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  canonicalDataset: {
                    __typename?: 'CanonicalDataset';
                    id: string;
                    kind: GQCanonicalDatasetKind;
                  } | null;
                };
              };
            }
          | { __typename: 'ValueMappingTask'; id: string }
          | { __typename: 'VendorMatchingTask'; id: string };
      }>;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
        areUploadsComplete: boolean;
        isUsableInFootprint: boolean;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          materialCsrdStandards: Array<GQCsrdStandard>;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
          } | null;
        } | null;
      }>;
      measurementVerificationItems: {
        __typename?: 'MeasurementVerificationItemConnection';
        edges: Array<{
          __typename?: 'MeasurementVerificationItemEdge';
          node: {
            __typename?: 'MeasurementVerificationItem';
            id: string;
            status: GQMeasurementVerificationItemStatus;
            dataset: {
              __typename?: 'Dataset';
              id: string;
              canonicalDataset: {
                __typename?: 'CanonicalDataset';
                id: string;
                kind: GQCanonicalDatasetKind;
              } | null;
            } | null;
          } | null;
        }>;
      };
      climateProgramProjects: Array<{
        __typename: 'ClimateProgramProject';
        id: string;
        name: string;
        canonicalClimateProgramProject: {
          __typename: 'CanonicalClimateProgramProject';
          id: string;
          measurementObjectCopy: string | null;
          kind: GQClimateProgramProjectKind;
          routeType: string | null;
          name: string;
        } | null;
      }>;
    };
    climateProgramProjects: Array<{
      __typename?: 'ClimateProgramProject';
      id: string;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
      } | null;
    }>;
  };
};

export type GQCreateClimateProgramProjectForMeasurementMutationVariables =
  Exact<{
    input: GQCreateClimateProgramProjectInput;
  }>;

export type GQCreateClimateProgramProjectForMeasurementMutation = {
  __typename?: 'Mutation';
  createClimateProgramProject: {
    __typename?: 'ClimateProgramProjectPayload';
    climateProgramProject: {
      __typename: 'ClimateProgramProject';
      id: string;
      name: string;
      description: string;
      kind: GQClimateProgramProjectKind;
      startDate: Date | null;
      endDate: Date | null;
      plannedYear: number;
      completedAt: Date | null;
      status: GQClimateProgramProjectStatus;
      iconType: GQClimateProgramProjectIconType;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
      } | null;
      canonicalClimateProgramProject: {
        __typename: 'CanonicalClimateProgramProject';
        id: string;
        routeType: string | null;
        name: string;
        kind: GQClimateProgramProjectKind;
        measurementObjectCopy: string | null;
      } | null;
      datasetRequirements: Array<{
        __typename?: 'ClimateProgramProjectRequirementDataset';
        id: string;
        additionalNotesMd: string | null;
        dataset: {
          __typename?: 'Dataset';
          id: string;
          name: string;
          instructions: {
            __typename?: 'InstructionsBundle';
            instructionsIntroMd: string | null;
          } | null;
          canonicalDataset: {
            __typename?: 'CanonicalDataset';
            id: string;
            kind: GQCanonicalDatasetKind;
            instructions: {
              __typename?: 'InstructionsBundle';
              sdiInstructionsIntroMd: string | null;
            } | null;
          } | null;
        };
        canonicalGhgpRequirement: {
          __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
          id: string;
          ghgScope: number;
          ghgScope3Category: number | null;
          minimumFidelity: GQClimateProgramProjectRequirementFidelity;
        } | null;
      }>;
    };
  };
};

export type GQMeasurementProjectsPageProjectFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  active: boolean;
  status: GQMeasurementProjectStatus;
  createdAt: Date;
  completedAt: Date | null;
  coverageInterval: YMInterval;
  coverageStartDate: Date;
  coverageEndDate: Date;
  deadline: Date | null;
  setupBy: {
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
  } | null;
  datasetsWithStages: Array<{
    __typename?: 'DatasetWithStage';
    id: string;
    areAllTasksComplete: boolean;
  }>;
};

export type GQMeasurementProjectsPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQMeasurementProjectsPageQuery = {
  __typename?: 'Query';
  userHasManageMeasurement: boolean;
  organization: {
    __typename?: 'Organization';
    id: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      status: GQMeasurementProjectStatus;
      createdAt: Date;
      completedAt: Date | null;
      coverageInterval: YMInterval;
      coverageStartDate: Date;
      coverageEndDate: Date;
      deadline: Date | null;
      setupBy: {
        __typename?: 'User';
        id: string;
        name: string;
        displayName: string;
      } | null;
      datasetsWithStages: Array<{
        __typename?: 'DatasetWithStage';
        id: string;
        areAllTasksComplete: boolean;
      }>;
    }>;
  };
};

export type GQSharedFileForDashboardPageFragment = {
  __typename?: 'SharedFile';
  id: string;
  expiresAt: Date;
  fileMetadata: {
    __typename?: 'FileMetadata';
    id: string;
    name: string;
    size: number;
    createdAt: Date;
    uploader:
      | { __typename?: 'User'; id: string; name: string }
      | { __typename?: 'WatershedEmployee'; id: string; name: string }
      | null;
  };
  datasource: {
    __typename?: 'Datasource';
    id: string;
    name: string;
    dataset: { __typename?: 'Dataset'; id: string; name: string };
  };
};

export type GQSharedFilesPageQueryVariables = Exact<{
  currentUserId: Scalars['ID']['input'];
}>;

export type GQSharedFilesPageQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      status: GQMeasurementProjectStatus;
      completedAt: Date | null;
      coverageEndDate: Date;
      deadline: Date | null;
      sharedFiles: {
        __typename?: 'SharedFileConnection';
        edges: Array<{
          __typename?: 'SharedFileEdge';
          node: {
            __typename?: 'SharedFile';
            id: string;
            expiresAt: Date;
            fileMetadata: {
              __typename?: 'FileMetadata';
              id: string;
              name: string;
              size: number;
              createdAt: Date;
              uploader:
                | { __typename?: 'User'; id: string; name: string }
                | { __typename?: 'WatershedEmployee'; id: string; name: string }
                | null;
            };
            datasource: {
              __typename?: 'Datasource';
              id: string;
              name: string;
              dataset: { __typename?: 'Dataset'; id: string; name: string };
            };
          } | null;
        } | null>;
      };
    }>;
  };
};

export type GQGetMeasurementProjectsDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetMeasurementProjectsDataQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      status: GQMeasurementProjectStatus;
      completedAt: Date | null;
      coverageInterval: YMInterval;
      coverageStartDate: Date;
      coverageEndDate: Date;
      deadline: Date | null;
    }>;
  };
};

export type GQUserForOrgStructureEditsFragment = {
  __typename?: 'User';
  id: string;
  displayName: string;
  isWatershedEmployee: boolean;
};

export type GQOrgStructureVersionFragment = {
  __typename?: 'OrgStructureVersion';
  id: string;
  name: string;
  isPublished: boolean;
  updatedAt: Date;
  effectiveFromDate: Date | null;
  effectiveToDate: Date | null;
  updatedBy: {
    __typename?: 'User';
    id: string;
    displayName: string;
    isWatershedEmployee: boolean;
  };
};

export type GQOrgUnitTypeFragment = {
  __typename?: 'OrgUnitType';
  id: string;
  name: string;
  externalId: string;
  updatedAt: Date;
  isLegalEntity: boolean;
  enabledForAllDatasets: boolean;
  requiredForAllDatasets: boolean;
  updatedBy: {
    __typename?: 'User';
    id: string;
    displayName: string;
    isWatershedEmployee: boolean;
  };
  enabledDatasets: Array<{
    __typename?: 'DatasetForOrgUnitType';
    id: string;
    datasetId: string;
    name: string;
    isRequired: boolean;
  }>;
  orgUnits: Array<{
    __typename?: 'OrgUnit';
    id: string;
    name: string;
    externalId: string;
  }>;
  relationships: Array<{
    __typename?: 'OrgUnitTypeRelationship';
    id: string;
    orgUnitTypeIdFrom: string;
    orgUnitTypeIdTo: string;
    cardinality: GQOrgUnitRelationshipCardinality;
    isRequired: boolean;
    defaultOrgUnitId: string | null;
  }>;
};

export type GQOrgUnitFragment = {
  __typename?: 'OrgUnit';
  id: string;
  name: string;
  externalId: string;
  description: string | null;
  orgUnitTypeId: string;
  relatedOrgUnits: Array<{
    __typename?: 'OrgUnit';
    id: string;
    name: string;
    orgUnitTypeId: string;
  }>;
  orgUnitDatasources: Array<{
    __typename?: 'OrgUnitDatasource';
    id: string;
    datasourceId: string;
    orgUnitTypeStableId: string;
    orgUnitStableId: string;
    createdAt: Date;
    updatedAt: Date;
  }>;
};

export type GQOrganizationForOrgUnitTypePageFragment = {
  __typename?: 'Organization';
  id: string;
  datasets: Array<{
    __typename?: 'Dataset';
    id: string;
    name: string;
    explainer: string | null;
  }>;
};

export type GQOrgUnitTypeWithOrgUnitsFragment = {
  __typename?: 'OrgUnitType';
  id: string;
  name: string;
  stableId: string;
  orgUnits: Array<{
    __typename?: 'OrgUnit';
    id: string;
    name: string;
    stableId: string;
  }>;
};

export type GQOrgStructureVersionWithTypesAndUnitsFragment = {
  __typename?: 'OrgStructureVersion';
  id: string;
  orgUnitTypes: Array<{
    __typename?: 'OrgUnitType';
    id: string;
    name: string;
    stableId: string;
    orgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      stableId: string;
    }>;
  }>;
};

export type GQLatestOrgStructureVersionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLatestOrgStructureVersionQuery = {
  __typename?: 'Query';
  latestOrgStructureVersion: {
    __typename?: 'OrgStructureVersion';
    id: string;
    orgUnitTypes: Array<{
      __typename?: 'OrgUnitType';
      id: string;
      name: string;
      stableId: string;
      orgUnits: Array<{
        __typename?: 'OrgUnit';
        id: string;
        name: string;
        stableId: string;
      }>;
    }>;
  } | null;
};

export type GQOrgUnitTypeRelationshipFragment = {
  __typename?: 'OrgUnitTypeRelationship';
  id: string;
  orgUnitTypeIdFrom: string;
  orgUnitTypeIdTo: string;
  cardinality: GQOrgUnitRelationshipCardinality;
  isRequired: boolean;
  defaultOrgUnitId: string | null;
};

export type GQOrgUnitTypeForOrgUnitGridFragment = {
  __typename?: 'OrgUnitType';
  id: string;
  name: string;
  cyclesWhenFromNode: Array<Array<string> | null>;
  cyclesWhenToNode: Array<Array<string> | null>;
  orgUnits: Array<{
    __typename?: 'OrgUnit';
    id: string;
    name: string;
    externalId: string;
  }>;
};

export type GQOrgStructureVersionsQueryVariables = Exact<{
  versionId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQOrgStructureVersionsQuery = {
  __typename?: 'Query';
  orgStructureVersions: Array<{
    __typename?: 'OrgStructureVersion';
    id: string;
    name: string;
    isPublished: boolean;
    updatedAt: Date;
    effectiveFromDate: Date | null;
    effectiveToDate: Date | null;
    updatedBy: {
      __typename?: 'User';
      id: string;
      displayName: string;
      isWatershedEmployee: boolean;
    };
  }>;
};

export type GQOrgUnitTypesQueryVariables = Exact<{
  versionId: Scalars['ID']['input'];
}>;

export type GQOrgUnitTypesQuery = {
  __typename?: 'Query';
  orgUnitTypes: Array<{
    __typename?: 'OrgUnitType';
    id: string;
    name: string;
    externalId: string;
    updatedAt: Date;
    isLegalEntity: boolean;
    enabledForAllDatasets: boolean;
    requiredForAllDatasets: boolean;
    updatedBy: {
      __typename?: 'User';
      id: string;
      displayName: string;
      isWatershedEmployee: boolean;
    };
    enabledDatasets: Array<{
      __typename?: 'DatasetForOrgUnitType';
      id: string;
      datasetId: string;
      name: string;
      isRequired: boolean;
    }>;
    orgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      externalId: string;
    }>;
    relationships: Array<{
      __typename?: 'OrgUnitTypeRelationship';
      id: string;
      orgUnitTypeIdFrom: string;
      orgUnitTypeIdTo: string;
      cardinality: GQOrgUnitRelationshipCardinality;
      isRequired: boolean;
      defaultOrgUnitId: string | null;
    }>;
  }>;
};

export type GQOrgUnitTypeQueryVariables = Exact<{
  versionId: Scalars['ID']['input'];
  orgUnitTypeId: Scalars['ID']['input'];
}>;

export type GQOrgUnitTypeQuery = {
  __typename?: 'Query';
  orgUnitTypes: Array<{
    __typename?: 'OrgUnitType';
    id: string;
    name: string;
    externalId: string;
    updatedAt: Date;
    isLegalEntity: boolean;
    enabledForAllDatasets: boolean;
    requiredForAllDatasets: boolean;
    updatedBy: {
      __typename?: 'User';
      id: string;
      displayName: string;
      isWatershedEmployee: boolean;
    };
    enabledDatasets: Array<{
      __typename?: 'DatasetForOrgUnitType';
      id: string;
      datasetId: string;
      name: string;
      isRequired: boolean;
    }>;
    orgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      externalId: string;
    }>;
    relationships: Array<{
      __typename?: 'OrgUnitTypeRelationship';
      id: string;
      orgUnitTypeIdFrom: string;
      orgUnitTypeIdTo: string;
      cardinality: GQOrgUnitRelationshipCardinality;
      isRequired: boolean;
      defaultOrgUnitId: string | null;
    }>;
  }>;
};

export type GQOrgUnitRelationshipExplorerPageQueryVariables = Exact<{
  versionId: Scalars['ID']['input'];
  orgUnitTypeId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQOrgUnitRelationshipExplorerPageQuery = {
  __typename?: 'Query';
  orgUnits: Array<{
    __typename?: 'OrgUnit';
    id: string;
    name: string;
    externalId: string;
    description: string | null;
    orgUnitTypeId: string;
    relatedOrgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      orgUnitTypeId: string;
    }>;
    orgUnitDatasources: Array<{
      __typename?: 'OrgUnitDatasource';
      id: string;
      datasourceId: string;
      orgUnitTypeStableId: string;
      orgUnitStableId: string;
      createdAt: Date;
      updatedAt: Date;
    }>;
  }>;
  orgUnitTypeRelationships: Array<{
    __typename?: 'OrgUnitTypeRelationship';
    id: string;
    orgUnitTypeIdFrom: string;
    orgUnitTypeIdTo: string;
    cardinality: GQOrgUnitRelationshipCardinality;
    isRequired: boolean;
    defaultOrgUnitId: string | null;
  }>;
  orgUnitTypes: Array<{
    __typename?: 'OrgUnitType';
    id: string;
    name: string;
    orgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      externalId: string;
    }>;
  }>;
};

export type GQOrgUnitGridPageQueryVariables = Exact<{
  versionId: Scalars['ID']['input'];
  orgUnitTypeId: Scalars['ID']['input'];
}>;

export type GQOrgUnitGridPageQuery = {
  __typename?: 'Query';
  orgUnits: Array<{
    __typename?: 'OrgUnit';
    id: string;
    name: string;
    externalId: string;
    description: string | null;
    orgUnitTypeId: string;
    relatedOrgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      orgUnitTypeId: string;
    }>;
    orgUnitDatasources: Array<{
      __typename?: 'OrgUnitDatasource';
      id: string;
      datasourceId: string;
      orgUnitTypeStableId: string;
      orgUnitStableId: string;
      createdAt: Date;
      updatedAt: Date;
    }>;
  }>;
  orgUnitTypes: Array<{
    __typename?: 'OrgUnitType';
    id: string;
    name: string;
    cyclesWhenFromNode: Array<Array<string> | null>;
    cyclesWhenToNode: Array<Array<string> | null>;
    orgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      externalId: string;
    }>;
  }>;
};

export type GQOrganizationForOrgUnitTypePageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQOrganizationForOrgUnitTypePageQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      explainer: string | null;
    }>;
  };
};

export type GQUpsertOrgStructureVersionMutationVariables = Exact<{
  input: GQUpsertOrgStructureVersionInput;
}>;

export type GQUpsertOrgStructureVersionMutation = {
  __typename?: 'Mutation';
  upsertOrgStructureVersion: {
    __typename?: 'UpsertOrgStructureVersionPayload';
    orgStructureVersion: {
      __typename?: 'OrgStructureVersion';
      id: string;
      name: string;
      isPublished: boolean;
      updatedAt: Date;
      effectiveFromDate: Date | null;
      effectiveToDate: Date | null;
      updatedBy: {
        __typename?: 'User';
        id: string;
        displayName: string;
        isWatershedEmployee: boolean;
      };
    };
  };
};

export type GQPublishOrgStructureVersionMutationVariables = Exact<{
  input: GQPublishOrgStructureVersionInput;
}>;

export type GQPublishOrgStructureVersionMutation = {
  __typename?: 'Mutation';
  publishOrgStructureVersion: {
    __typename?: 'PublishOrgStructureVersionPayload';
    orgStructureVersion: {
      __typename?: 'OrgStructureVersion';
      id: string;
      name: string;
      isPublished: boolean;
      updatedAt: Date;
      effectiveFromDate: Date | null;
      effectiveToDate: Date | null;
      updatedBy: {
        __typename?: 'User';
        id: string;
        displayName: string;
        isWatershedEmployee: boolean;
      };
    };
  };
};

export type GQUnpublishOrgStructureVersionMutationVariables = Exact<{
  input: GQUnpublishOrgStructureVersionInput;
}>;

export type GQUnpublishOrgStructureVersionMutation = {
  __typename?: 'Mutation';
  unpublishOrgStructureVersion: {
    __typename?: 'UnpublishOrgStructureVersionPayload';
    orgStructureVersion: {
      __typename?: 'OrgStructureVersion';
      id: string;
      name: string;
      isPublished: boolean;
      updatedAt: Date;
      effectiveFromDate: Date | null;
      effectiveToDate: Date | null;
      updatedBy: {
        __typename?: 'User';
        id: string;
        displayName: string;
        isWatershedEmployee: boolean;
      };
    };
  };
};

export type GQDeleteOrgStructureVersionMutationVariables = Exact<{
  input: GQDeleteOrgStructureVersionInput;
}>;

export type GQDeleteOrgStructureVersionMutation = {
  __typename?: 'Mutation';
  deleteOrgStructureVersion: {
    __typename?: 'DeleteOrgStructureVersionPayload';
    orgStructureVersion: {
      __typename?: 'OrgStructureVersion';
      id: string;
      name: string;
      isPublished: boolean;
      updatedAt: Date;
      effectiveFromDate: Date | null;
      effectiveToDate: Date | null;
      updatedBy: {
        __typename?: 'User';
        id: string;
        displayName: string;
        isWatershedEmployee: boolean;
      };
    };
  };
};

export type GQDuplicateOrgStructureVersionMutationVariables = Exact<{
  input: GQDuplicateOrgStructureVersionInput;
}>;

export type GQDuplicateOrgStructureVersionMutation = {
  __typename?: 'Mutation';
  duplicateOrgStructureVersion: {
    __typename?: 'DuplicateOrgStructureVersionPayload';
    orgStructureVersion: {
      __typename?: 'OrgStructureVersion';
      id: string;
      name: string;
      isPublished: boolean;
      updatedAt: Date;
      effectiveFromDate: Date | null;
      effectiveToDate: Date | null;
      updatedBy: {
        __typename?: 'User';
        id: string;
        displayName: string;
        isWatershedEmployee: boolean;
      };
    };
  };
};

export type GQCreateOrgUnitTypeMutationVariables = Exact<{
  input: GQCreateOrgUnitTypeInput;
}>;

export type GQCreateOrgUnitTypeMutation = {
  __typename?: 'Mutation';
  createOrgUnitType: {
    __typename?: 'CreateOrgUnitTypePayload';
    orgUnitType: {
      __typename?: 'OrgUnitType';
      id: string;
      name: string;
      externalId: string;
      updatedAt: Date;
      isLegalEntity: boolean;
      enabledForAllDatasets: boolean;
      requiredForAllDatasets: boolean;
      updatedBy: {
        __typename?: 'User';
        id: string;
        displayName: string;
        isWatershedEmployee: boolean;
      };
      enabledDatasets: Array<{
        __typename?: 'DatasetForOrgUnitType';
        id: string;
        datasetId: string;
        name: string;
        isRequired: boolean;
      }>;
      orgUnits: Array<{
        __typename?: 'OrgUnit';
        id: string;
        name: string;
        externalId: string;
      }>;
      relationships: Array<{
        __typename?: 'OrgUnitTypeRelationship';
        id: string;
        orgUnitTypeIdFrom: string;
        orgUnitTypeIdTo: string;
        cardinality: GQOrgUnitRelationshipCardinality;
        isRequired: boolean;
        defaultOrgUnitId: string | null;
      }>;
    };
  };
};

export type GQUpdateOrgUnitTypeMutationVariables = Exact<{
  input: GQUpdateOrgUnitTypeInput;
}>;

export type GQUpdateOrgUnitTypeMutation = {
  __typename?: 'Mutation';
  updateOrgUnitType: {
    __typename?: 'UpdateOrgUnitTypePayload';
    orgUnitType: {
      __typename?: 'OrgUnitType';
      id: string;
      name: string;
      externalId: string;
      updatedAt: Date;
      isLegalEntity: boolean;
      enabledForAllDatasets: boolean;
      requiredForAllDatasets: boolean;
      updatedBy: {
        __typename?: 'User';
        id: string;
        displayName: string;
        isWatershedEmployee: boolean;
      };
      enabledDatasets: Array<{
        __typename?: 'DatasetForOrgUnitType';
        id: string;
        datasetId: string;
        name: string;
        isRequired: boolean;
      }>;
      orgUnits: Array<{
        __typename?: 'OrgUnit';
        id: string;
        name: string;
        externalId: string;
      }>;
      relationships: Array<{
        __typename?: 'OrgUnitTypeRelationship';
        id: string;
        orgUnitTypeIdFrom: string;
        orgUnitTypeIdTo: string;
        cardinality: GQOrgUnitRelationshipCardinality;
        isRequired: boolean;
        defaultOrgUnitId: string | null;
      }>;
    };
  };
};

export type GQDeleteOrgUnitTypeMutationVariables = Exact<{
  input: GQDeleteOrgUnitTypeInput;
}>;

export type GQDeleteOrgUnitTypeMutation = {
  __typename?: 'Mutation';
  deleteOrgUnitType: {
    __typename?: 'DeleteOrgUnitTypePayload';
    orgUnitType: {
      __typename?: 'OrgUnitType';
      id: string;
      name: string;
      externalId: string;
      updatedAt: Date;
      isLegalEntity: boolean;
      enabledForAllDatasets: boolean;
      requiredForAllDatasets: boolean;
      updatedBy: {
        __typename?: 'User';
        id: string;
        displayName: string;
        isWatershedEmployee: boolean;
      };
      enabledDatasets: Array<{
        __typename?: 'DatasetForOrgUnitType';
        id: string;
        datasetId: string;
        name: string;
        isRequired: boolean;
      }>;
      orgUnits: Array<{
        __typename?: 'OrgUnit';
        id: string;
        name: string;
        externalId: string;
      }>;
      relationships: Array<{
        __typename?: 'OrgUnitTypeRelationship';
        id: string;
        orgUnitTypeIdFrom: string;
        orgUnitTypeIdTo: string;
        cardinality: GQOrgUnitRelationshipCardinality;
        isRequired: boolean;
        defaultOrgUnitId: string | null;
      }>;
    };
  };
};

export type GQCreateOrgUnitMutationVariables = Exact<{
  input: GQCreateOrgUnitInput;
}>;

export type GQCreateOrgUnitMutation = {
  __typename?: 'Mutation';
  createOrgUnit: {
    __typename?: 'CreateOrgUnitPayload';
    orgUnit: {
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      externalId: string;
      description: string | null;
      orgUnitTypeId: string;
      relatedOrgUnits: Array<{
        __typename?: 'OrgUnit';
        id: string;
        name: string;
        orgUnitTypeId: string;
      }>;
      orgUnitDatasources: Array<{
        __typename?: 'OrgUnitDatasource';
        id: string;
        datasourceId: string;
        orgUnitTypeStableId: string;
        orgUnitStableId: string;
        createdAt: Date;
        updatedAt: Date;
      }>;
    };
  };
};

export type GQUpdateOrgUnitsMutationVariables = Exact<{
  input: GQUpdateOrgUnitsInput;
}>;

export type GQUpdateOrgUnitsMutation = {
  __typename?: 'Mutation';
  updateOrgUnits: {
    __typename?: 'UpdateOrgUnitsPayload';
    orgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      externalId: string;
      description: string | null;
      orgUnitTypeId: string;
      relatedOrgUnits: Array<{
        __typename?: 'OrgUnit';
        id: string;
        name: string;
        orgUnitTypeId: string;
      }>;
      orgUnitDatasources: Array<{
        __typename?: 'OrgUnitDatasource';
        id: string;
        datasourceId: string;
        orgUnitTypeStableId: string;
        orgUnitStableId: string;
        createdAt: Date;
        updatedAt: Date;
      }>;
    }>;
  };
};

export type GQDeleteOrgUnitsMutationVariables = Exact<{
  input: GQDeleteOrgUnitsInput;
}>;

export type GQDeleteOrgUnitsMutation = {
  __typename?: 'Mutation';
  deleteOrgUnits: {
    __typename?: 'DeleteOrgUnitsPayload';
    orgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      externalId: string;
      description: string | null;
      orgUnitTypeId: string;
      relatedOrgUnits: Array<{
        __typename?: 'OrgUnit';
        id: string;
        name: string;
        orgUnitTypeId: string;
      }>;
      orgUnitDatasources: Array<{
        __typename?: 'OrgUnitDatasource';
        id: string;
        datasourceId: string;
        orgUnitTypeStableId: string;
        orgUnitStableId: string;
        createdAt: Date;
        updatedAt: Date;
      }>;
    }>;
  };
};

export type GQCreateOrgUnitTypeRelationshipMutationVariables = Exact<{
  input: GQCreateOrgUnitTypeRelationshipInput;
}>;

export type GQCreateOrgUnitTypeRelationshipMutation = {
  __typename?: 'Mutation';
  createOrgUnitTypeRelationship: {
    __typename?: 'CreateOrgUnitTypeRelationshipPayload';
    orgUnitTypeRelationship: {
      __typename?: 'OrgUnitTypeRelationship';
      id: string;
      orgUnitTypeIdFrom: string;
      orgUnitTypeIdTo: string;
      cardinality: GQOrgUnitRelationshipCardinality;
      isRequired: boolean;
      defaultOrgUnitId: string | null;
    };
    orgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      externalId: string;
      description: string | null;
      orgUnitTypeId: string;
      relatedOrgUnits: Array<{
        __typename?: 'OrgUnit';
        id: string;
        name: string;
        orgUnitTypeId: string;
      }>;
      orgUnitDatasources: Array<{
        __typename?: 'OrgUnitDatasource';
        id: string;
        datasourceId: string;
        orgUnitTypeStableId: string;
        orgUnitStableId: string;
        createdAt: Date;
        updatedAt: Date;
      }>;
    }>;
  };
};

export type GQUpdateOrgUnitTypeRelationshipMutationVariables = Exact<{
  input: GQUpdateOrgUnitTypeRelationshipInput;
}>;

export type GQUpdateOrgUnitTypeRelationshipMutation = {
  __typename?: 'Mutation';
  updateOrgUnitTypeRelationship: {
    __typename?: 'UpdateOrgUnitTypeRelationshipPayload';
    orgUnitTypeRelationship: {
      __typename?: 'OrgUnitTypeRelationship';
      id: string;
      orgUnitTypeIdFrom: string;
      orgUnitTypeIdTo: string;
      cardinality: GQOrgUnitRelationshipCardinality;
      isRequired: boolean;
      defaultOrgUnitId: string | null;
    };
    orgUnits: Array<{
      __typename?: 'OrgUnit';
      id: string;
      name: string;
      externalId: string;
      description: string | null;
      orgUnitTypeId: string;
      relatedOrgUnits: Array<{
        __typename?: 'OrgUnit';
        id: string;
        name: string;
        orgUnitTypeId: string;
      }>;
      orgUnitDatasources: Array<{
        __typename?: 'OrgUnitDatasource';
        id: string;
        datasourceId: string;
        orgUnitTypeStableId: string;
        orgUnitStableId: string;
        createdAt: Date;
        updatedAt: Date;
      }>;
    }>;
  };
};

export type GQDeleteOrgUnitTypeRelationshipMutationVariables = Exact<{
  input: GQDeleteOrgUnitTypeRelationshipInput;
}>;

export type GQDeleteOrgUnitTypeRelationshipMutation = {
  __typename?: 'Mutation';
  deleteOrgUnitTypeRelationship: {
    __typename?: 'DeleteOrgUnitTypeRelationshipPayload';
    orgUnitTypeRelationship: {
      __typename?: 'OrgUnitTypeRelationship';
      id: string;
      orgUnitTypeIdFrom: string;
      orgUnitTypeIdTo: string;
      cardinality: GQOrgUnitRelationshipCardinality;
      isRequired: boolean;
      defaultOrgUnitId: string | null;
    };
  };
};

export type GQForecastPeriodFieldsFragment = {
  __typename?: 'ForecastPeriod';
  interval: YMInterval;
  data: Array<{
    __typename?: 'ForecastPeriodPoint';
    date: Date;
    headcount: number | null;
    revenue: number | null;
    customGrowthFactors: Array<{
      __typename?: 'ForecastCustomIntensityDataPoint';
      customIntensityId: string;
      value: number | null;
    }>;
  }>;
};

export type GQUserInputtedForecastPeriodFieldsFragment = {
  __typename?: 'UserInputtedForecastPeriodPoint';
  date: Date;
  customGrowthFactors: Array<{
    __typename?: 'ForecastCustomIntensityDataPoint';
    customIntensityId: string;
    value: number | null;
  }>;
};

export type GQHistoricalUserInputtedGrowthFactorDataFieldsFragment = {
  __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
  date: Date;
  growthFactors: Array<{
    __typename?: 'GrowthFactorDataPoint';
    growthFactorType: GQGrowthFactorType;
    customIntensityConfigId: string | null;
    value: number | null;
  }>;
};

export type GQGrowthForecastFieldsFragment = {
  __typename?: 'GrowthForecast';
  id: string;
  growthFactorType: GQGrowthFactorType;
  customIntensityConfigId: string | null;
  isDefaultScalingFactor: boolean;
  hasUserInputtedHistoricalPeriod: boolean;
  forecast: {
    __typename?: 'SimpleTimeseries';
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  };
  scalingFactorFilters: {
    __typename?: 'FilterExpressionGroupWithNewFilters';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitiveWithNewFilters';
      field: string;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  } | null;
};

export type GQCustomIntensityConfigFieldsFragment = {
  __typename?: 'CustomIntensityConfig';
  id: string;
  name: string;
  description: string;
  unit: string | null;
  humanReadable: boolean;
  quantityType: GQCustomIntensityConfigQuantityType;
  isSbtValidated: boolean | null;
};

export type GQForecastScenarioFieldsFragment = {
  __typename?: 'ForecastScenario';
  id: string;
  name: string;
  description: string | null;
  isDefault: boolean;
  updatedByName: string | null;
  updatedAt: Date;
  historicalUserInputtedGrowthFactorDataPoints: Array<{
    __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
    date: Date;
    growthFactors: Array<{
      __typename?: 'GrowthFactorDataPoint';
      growthFactorType: GQGrowthFactorType;
      customIntensityConfigId: string | null;
      value: number | null;
    }>;
  }>;
  growthForecasts: Array<{
    __typename?: 'GrowthForecast';
    id: string;
    growthFactorType: GQGrowthFactorType;
    customIntensityConfigId: string | null;
    isDefaultScalingFactor: boolean;
    hasUserInputtedHistoricalPeriod: boolean;
    forecast: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
    scalingFactorFilters: {
      __typename?: 'FilterExpressionGroupWithNewFilters';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitiveWithNewFilters';
        field: string;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    } | null;
  }>;
};

export type GQForecastFieldsFragment = {
  __typename?: 'Forecast';
  id: string;
  interval: YMInterval;
  footprintSnapshotId: string;
  referencePeriodInterval: YMInterval;
  overrideFootprintKind: string | null;
  validYearsForIntervalEnd: YMInterval;
  validReferenceInterval: YMInterval;
  scenarios: Array<{
    __typename?: 'ForecastScenario';
    id: string;
    name: string;
    description: string | null;
    isDefault: boolean;
    updatedByName: string | null;
    updatedAt: Date;
    historicalUserInputtedGrowthFactorDataPoints: Array<{
      __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
      date: Date;
      growthFactors: Array<{
        __typename?: 'GrowthFactorDataPoint';
        growthFactorType: GQGrowthFactorType;
        customIntensityConfigId: string | null;
        value: number | null;
      }>;
    }>;
    growthForecasts: Array<{
      __typename?: 'GrowthForecast';
      id: string;
      growthFactorType: GQGrowthFactorType;
      customIntensityConfigId: string | null;
      isDefaultScalingFactor: boolean;
      hasUserInputtedHistoricalPeriod: boolean;
      forecast: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      scalingFactorFilters: {
        __typename?: 'FilterExpressionGroupWithNewFilters';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitiveWithNewFilters';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }>;
  }>;
  referencePeriod: {
    __typename?: 'ForecastPeriod';
    interval: YMInterval;
    data: Array<{
      __typename?: 'ForecastPeriodPoint';
      date: Date;
      headcount: number | null;
      revenue: number | null;
      customGrowthFactors: Array<{
        __typename?: 'ForecastCustomIntensityDataPoint';
        customIntensityId: string;
        value: number | null;
      }>;
    }>;
  };
  historicalPeriod: {
    __typename?: 'ForecastPeriod';
    interval: YMInterval;
    data: Array<{
      __typename?: 'ForecastPeriodPoint';
      date: Date;
      headcount: number | null;
      revenue: number | null;
      customGrowthFactors: Array<{
        __typename?: 'ForecastCustomIntensityDataPoint';
        customIntensityId: string;
        value: number | null;
      }>;
    }>;
  };
  planTargets: Array<{
    __typename?: 'PlanTarget';
    id: string;
    intensityType: GQPlanTargetIntensityType;
    customIntensityConfigId: string | null;
  }>;
  customIntensityConfigs: Array<{
    __typename?: 'CustomIntensityConfig';
    id: string;
    name: string;
    description: string;
    unit: string | null;
    humanReadable: boolean;
    quantityType: GQCustomIntensityConfigQuantityType;
    isSbtValidated: boolean | null;
  }>;
  businessCategories: Array<{
    __typename?: 'CategorizedEmissionData';
    businessCategory: string;
  }>;
};

export type GQBusinessCategoryForForecastingFragment = {
  __typename?: 'CategorizedEmissionData';
  businessCategory: string;
};

export type GQForecastsPageQueryVariables = Exact<{ [key: string]: never }>;

export type GQForecastsPageQuery = {
  __typename?: 'Query';
  forecast: {
    __typename?: 'Forecast';
    id: string;
    interval: YMInterval;
    footprintSnapshotId: string;
    referencePeriodInterval: YMInterval;
    overrideFootprintKind: string | null;
    validYearsForIntervalEnd: YMInterval;
    validReferenceInterval: YMInterval;
    scenarios: Array<{
      __typename?: 'ForecastScenario';
      id: string;
      name: string;
      description: string | null;
      isDefault: boolean;
      updatedByName: string | null;
      updatedAt: Date;
      historicalUserInputtedGrowthFactorDataPoints: Array<{
        __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
        date: Date;
        growthFactors: Array<{
          __typename?: 'GrowthFactorDataPoint';
          growthFactorType: GQGrowthFactorType;
          customIntensityConfigId: string | null;
          value: number | null;
        }>;
      }>;
      growthForecasts: Array<{
        __typename?: 'GrowthForecast';
        id: string;
        growthFactorType: GQGrowthFactorType;
        customIntensityConfigId: string | null;
        isDefaultScalingFactor: boolean;
        hasUserInputtedHistoricalPeriod: boolean;
        forecast: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        scalingFactorFilters: {
          __typename?: 'FilterExpressionGroupWithNewFilters';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitiveWithNewFilters';
            field: string;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        } | null;
      }>;
    }>;
    referencePeriod: {
      __typename?: 'ForecastPeriod';
      interval: YMInterval;
      data: Array<{
        __typename?: 'ForecastPeriodPoint';
        date: Date;
        headcount: number | null;
        revenue: number | null;
        customGrowthFactors: Array<{
          __typename?: 'ForecastCustomIntensityDataPoint';
          customIntensityId: string;
          value: number | null;
        }>;
      }>;
    };
    historicalPeriod: {
      __typename?: 'ForecastPeriod';
      interval: YMInterval;
      data: Array<{
        __typename?: 'ForecastPeriodPoint';
        date: Date;
        headcount: number | null;
        revenue: number | null;
        customGrowthFactors: Array<{
          __typename?: 'ForecastCustomIntensityDataPoint';
          customIntensityId: string;
          value: number | null;
        }>;
      }>;
    };
    planTargets: Array<{
      __typename?: 'PlanTarget';
      id: string;
      intensityType: GQPlanTargetIntensityType;
      customIntensityConfigId: string | null;
    }>;
    customIntensityConfigs: Array<{
      __typename?: 'CustomIntensityConfig';
      id: string;
      name: string;
      description: string;
      unit: string | null;
      humanReadable: boolean;
      quantityType: GQCustomIntensityConfigQuantityType;
      isSbtValidated: boolean | null;
    }>;
    businessCategories: Array<{
      __typename?: 'CategorizedEmissionData';
      businessCategory: string;
    }>;
  };
};

export type GQUpdateForecastMutationVariables = Exact<{
  input: GQUpdateForecastInput;
}>;

export type GQUpdateForecastMutation = {
  __typename?: 'Mutation';
  updateForecast: {
    __typename?: 'UpdateForecastPayload';
    forecast: {
      __typename?: 'Forecast';
      id: string;
      interval: YMInterval;
      footprintSnapshotId: string;
      referencePeriodInterval: YMInterval;
      overrideFootprintKind: string | null;
      validYearsForIntervalEnd: YMInterval;
      validReferenceInterval: YMInterval;
      scenarios: Array<{
        __typename?: 'ForecastScenario';
        id: string;
        name: string;
        description: string | null;
        isDefault: boolean;
        updatedByName: string | null;
        updatedAt: Date;
        historicalUserInputtedGrowthFactorDataPoints: Array<{
          __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
          date: Date;
          growthFactors: Array<{
            __typename?: 'GrowthFactorDataPoint';
            growthFactorType: GQGrowthFactorType;
            customIntensityConfigId: string | null;
            value: number | null;
          }>;
        }>;
        growthForecasts: Array<{
          __typename?: 'GrowthForecast';
          id: string;
          growthFactorType: GQGrowthFactorType;
          customIntensityConfigId: string | null;
          isDefaultScalingFactor: boolean;
          hasUserInputtedHistoricalPeriod: boolean;
          forecast: {
            __typename?: 'SimpleTimeseries';
            base: Date;
            frequency: GQTimeseriesFrequency;
            values: Array<number>;
          };
          scalingFactorFilters: {
            __typename?: 'FilterExpressionGroupWithNewFilters';
            conjunction: GQFilterConjunction;
            expressions: Array<{
              __typename?: 'FilterExpressionPrimitiveWithNewFilters';
              field: string;
              operator: GQFilterOperator;
              value: Array<string>;
            }>;
          } | null;
        }>;
      }>;
      referencePeriod: {
        __typename?: 'ForecastPeriod';
        interval: YMInterval;
        data: Array<{
          __typename?: 'ForecastPeriodPoint';
          date: Date;
          headcount: number | null;
          revenue: number | null;
          customGrowthFactors: Array<{
            __typename?: 'ForecastCustomIntensityDataPoint';
            customIntensityId: string;
            value: number | null;
          }>;
        }>;
      };
      historicalPeriod: {
        __typename?: 'ForecastPeriod';
        interval: YMInterval;
        data: Array<{
          __typename?: 'ForecastPeriodPoint';
          date: Date;
          headcount: number | null;
          revenue: number | null;
          customGrowthFactors: Array<{
            __typename?: 'ForecastCustomIntensityDataPoint';
            customIntensityId: string;
            value: number | null;
          }>;
        }>;
      };
      planTargets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
      }>;
      customIntensityConfigs: Array<{
        __typename?: 'CustomIntensityConfig';
        id: string;
        name: string;
        description: string;
        unit: string | null;
        humanReadable: boolean;
        quantityType: GQCustomIntensityConfigQuantityType;
        isSbtValidated: boolean | null;
      }>;
      businessCategories: Array<{
        __typename?: 'CategorizedEmissionData';
        businessCategory: string;
      }>;
    };
    plans: Array<{
      __typename?: 'Plan';
      id: string;
      name: string;
      createdAt: Date;
      updatedAt: Date;
      updatedByName: string | null;
      state: GQPlanState;
      baselineYearStart: YearMonth;
      fiscalMonth: number;
      isSbtValidated: boolean;
      hideCarbonAvoidance: boolean;
      forecast: {
        __typename?: 'Forecast';
        id: string;
        interval: YMInterval;
        referencePeriodInterval: YMInterval;
        overrideFootprintKind: string | null;
        validReferenceInterval: YMInterval;
        footprintSnapshotId: string;
        scenarios: Array<{
          __typename?: 'ForecastScenario';
          id: string;
          name: string;
          description: string | null;
          isDefault: boolean;
          updatedByName: string | null;
          updatedAt: Date;
          historicalUserInputtedGrowthFactorDataPoints: Array<{
            __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
            date: Date;
            growthFactors: Array<{
              __typename?: 'GrowthFactorDataPoint';
              growthFactorType: GQGrowthFactorType;
              customIntensityConfigId: string | null;
              value: number | null;
            }>;
          }>;
          growthForecasts: Array<{
            __typename?: 'GrowthForecast';
            id: string;
            growthFactorType: GQGrowthFactorType;
            customIntensityConfigId: string | null;
            isDefaultScalingFactor: boolean;
            hasUserInputtedHistoricalPeriod: boolean;
            forecast: {
              __typename?: 'SimpleTimeseries';
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            scalingFactorFilters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                operator: GQFilterOperator;
                value: Array<string>;
              }>;
            } | null;
          }>;
        }>;
        historicalPeriod: {
          __typename?: 'ForecastPeriod';
          interval: YMInterval;
          data: Array<{
            __typename?: 'ForecastPeriodPoint';
            date: Date;
            headcount: number | null;
            revenue: number | null;
            customGrowthFactors: Array<{
              __typename?: 'ForecastCustomIntensityDataPoint';
              customIntensityId: string;
              value: number | null;
            }>;
          }>;
        };
        customIntensityConfigs: Array<{
          __typename?: 'CustomIntensityConfig';
          id: string;
          name: string;
          description: string;
          unit: string | null;
          humanReadable: boolean;
          quantityType: GQCustomIntensityConfigQuantityType;
          isSbtValidated: boolean | null;
        }>;
        businessCategories: Array<{
          __typename?: 'CategorizedEmissionData';
          businessCategory: string;
        }>;
      };
      variables: {
        __typename?: 'PlanVariables';
        commitmentsHasNetZero: boolean;
        commitmentsNetZeroYear: number;
        cleanPowerStart: YearMonth;
        commitmentsHasSBT: boolean;
        commitmentsSBTTargetYear: number;
        commitmentsSBTScope12: GQSbtScope1And2Target;
        commitmentsSBTScope3: GQSbtScope3Target;
        commitmentsSBTTermLength: GQSbtTermLength;
        commitmentsSBTSubmissionDate: YearMonth;
        flightsPerEmployeeForecastEnd: number;
        percentWorkFromHomeForecastEnd: number;
        netZeroSuppliersReductionPercent: number;
      };
      reduxInitiatives: Array<
        | {
            __typename: 'FootprintSliceInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            reductionTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
        | {
            __typename: 'PrecomputedActivityBasedInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            precomputedLever: GQPrecomputedLever;
            implementationTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
      >;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
      reduxCarbonOffsets: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonOffsetsCosts: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonRemoval: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonRemovalCosts: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    }>;
  };
};

export type GQGetFirstPlanQueryVariables = Exact<{ [key: string]: never }>;

export type GQGetFirstPlanQuery = {
  __typename?: 'Query';
  plansWithDefaults: {
    __typename?: 'PlanConnection';
    edges: Array<{
      __typename?: 'PlanEdge';
      node: {
        __typename?: 'Plan';
        id: string;
        name: string;
        state: GQPlanState;
        createdAt: Date;
        isHidden: boolean;
        relevantFootprintFields: Array<string>;
        forecast: {
          __typename?: 'Forecast';
          id: string;
          footprintSnapshotId: string;
          overrideFootprintKind: string | null;
          referencePeriodInterval: YMInterval;
        };
      } | null;
    } | null>;
  };
};

export type GQReductionsMetricsPage_ForecastFragment = {
  __typename?: 'Forecast';
  id: string;
  scenarios: Array<{
    __typename?: 'ForecastScenario';
    id: string;
    name: string;
    growthForecasts: Array<{
      __typename?: 'GrowthForecast';
      id: string;
      customIntensityConfigId: string | null;
    }>;
  }>;
  customIntensityConfigs: Array<{
    __typename?: 'CustomIntensityConfig';
    id: string;
    name: string;
    quantityType: GQCustomIntensityConfigQuantityType;
    description: string;
    unit: string | null;
    humanReadable: boolean;
    isSbtValidated: boolean | null;
  }>;
};

export type GQReductionsMetricsPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQReductionsMetricsPageQuery = {
  __typename?: 'Query';
  forecast: {
    __typename?: 'Forecast';
    id: string;
    scenarios: Array<{
      __typename?: 'ForecastScenario';
      id: string;
      name: string;
      growthForecasts: Array<{
        __typename?: 'GrowthForecast';
        id: string;
        customIntensityConfigId: string | null;
      }>;
    }>;
    customIntensityConfigs: Array<{
      __typename?: 'CustomIntensityConfig';
      id: string;
      name: string;
      quantityType: GQCustomIntensityConfigQuantityType;
      description: string;
      unit: string | null;
      humanReadable: boolean;
      isSbtValidated: boolean | null;
    }>;
  };
};

export type GQReductionsMetricsPage_UpdateForecastMutationVariables = Exact<{
  input: GQUpdateForecastInput;
}>;

export type GQReductionsMetricsPage_UpdateForecastMutation = {
  __typename?: 'Mutation';
  updateForecast: {
    __typename?: 'UpdateForecastPayload';
    forecast: {
      __typename?: 'Forecast';
      id: string;
      scenarios: Array<{
        __typename?: 'ForecastScenario';
        id: string;
        name: string;
        growthForecasts: Array<{
          __typename?: 'GrowthForecast';
          id: string;
          customIntensityConfigId: string | null;
        }>;
      }>;
      customIntensityConfigs: Array<{
        __typename?: 'CustomIntensityConfig';
        id: string;
        name: string;
        quantityType: GQCustomIntensityConfigQuantityType;
        description: string;
        unit: string | null;
        humanReadable: boolean;
        isSbtValidated: boolean | null;
      }>;
    };
  };
};

export type GQPlanForPlanSettingsFragment = {
  __typename?: 'Plan';
  id: string;
  name: string;
  baselineYearStart: YearMonth;
  isSbtValidated: boolean;
  fiscalMonth: number;
  variables: {
    __typename?: 'PlanVariables';
    commitmentsSBTSubmissionDate: YearMonth;
    commitmentsSBTTermLength: GQSbtTermLength;
    commitmentsSBTScope12: GQSbtScope1And2Target;
    commitmentsSBTScope3: GQSbtScope3Target;
  };
  forecast: {
    __typename?: 'Forecast';
    id: string;
    interval: YMInterval;
    validReferenceInterval: YMInterval;
    referencePeriod: { __typename?: 'ForecastPeriod'; interval: YMInterval };
    historicalPeriod: { __typename?: 'ForecastPeriod'; interval: YMInterval };
  };
};

export type GQPlanForPlanSettingsPageQueryVariables = Exact<{
  planId: Scalars['ID']['input'];
}>;

export type GQPlanForPlanSettingsPageQuery = {
  __typename?: 'Query';
  plan: {
    __typename?: 'Plan';
    id: string;
    name: string;
    baselineYearStart: YearMonth;
    isSbtValidated: boolean;
    fiscalMonth: number;
    variables: {
      __typename?: 'PlanVariables';
      commitmentsSBTSubmissionDate: YearMonth;
      commitmentsSBTTermLength: GQSbtTermLength;
      commitmentsSBTScope12: GQSbtScope1And2Target;
      commitmentsSBTScope3: GQSbtScope3Target;
    };
    forecast: {
      __typename?: 'Forecast';
      id: string;
      interval: YMInterval;
      validReferenceInterval: YMInterval;
      referencePeriod: { __typename?: 'ForecastPeriod'; interval: YMInterval };
      historicalPeriod: { __typename?: 'ForecastPeriod'; interval: YMInterval };
    };
  };
};

export type GQUpdatePlanSettingsMutationVariables = Exact<{
  input: GQUpdatePlanSettingsInput;
}>;

export type GQUpdatePlanSettingsMutation = {
  __typename?: 'Mutation';
  updatePlanSettings: {
    __typename?: 'UpdatePlanSettingsPayload';
    plan: {
      __typename?: 'Plan';
      id: string;
      name: string;
      createdAt: Date;
      updatedAt: Date;
      updatedByName: string | null;
      state: GQPlanState;
      baselineYearStart: YearMonth;
      fiscalMonth: number;
      isSbtValidated: boolean;
      hideCarbonAvoidance: boolean;
      forecast: {
        __typename?: 'Forecast';
        id: string;
        interval: YMInterval;
        referencePeriodInterval: YMInterval;
        overrideFootprintKind: string | null;
        validReferenceInterval: YMInterval;
        footprintSnapshotId: string;
        scenarios: Array<{
          __typename?: 'ForecastScenario';
          id: string;
          name: string;
          description: string | null;
          isDefault: boolean;
          updatedByName: string | null;
          updatedAt: Date;
          historicalUserInputtedGrowthFactorDataPoints: Array<{
            __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
            date: Date;
            growthFactors: Array<{
              __typename?: 'GrowthFactorDataPoint';
              growthFactorType: GQGrowthFactorType;
              customIntensityConfigId: string | null;
              value: number | null;
            }>;
          }>;
          growthForecasts: Array<{
            __typename?: 'GrowthForecast';
            id: string;
            growthFactorType: GQGrowthFactorType;
            customIntensityConfigId: string | null;
            isDefaultScalingFactor: boolean;
            hasUserInputtedHistoricalPeriod: boolean;
            forecast: {
              __typename?: 'SimpleTimeseries';
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            scalingFactorFilters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                operator: GQFilterOperator;
                value: Array<string>;
              }>;
            } | null;
          }>;
        }>;
        historicalPeriod: {
          __typename?: 'ForecastPeriod';
          interval: YMInterval;
          data: Array<{
            __typename?: 'ForecastPeriodPoint';
            date: Date;
            headcount: number | null;
            revenue: number | null;
            customGrowthFactors: Array<{
              __typename?: 'ForecastCustomIntensityDataPoint';
              customIntensityId: string;
              value: number | null;
            }>;
          }>;
        };
        customIntensityConfigs: Array<{
          __typename?: 'CustomIntensityConfig';
          id: string;
          name: string;
          description: string;
          unit: string | null;
          humanReadable: boolean;
          quantityType: GQCustomIntensityConfigQuantityType;
          isSbtValidated: boolean | null;
        }>;
        businessCategories: Array<{
          __typename?: 'CategorizedEmissionData';
          businessCategory: string;
        }>;
      };
      variables: {
        __typename?: 'PlanVariables';
        commitmentsHasNetZero: boolean;
        commitmentsNetZeroYear: number;
        cleanPowerStart: YearMonth;
        commitmentsHasSBT: boolean;
        commitmentsSBTTargetYear: number;
        commitmentsSBTScope12: GQSbtScope1And2Target;
        commitmentsSBTScope3: GQSbtScope3Target;
        commitmentsSBTTermLength: GQSbtTermLength;
        commitmentsSBTSubmissionDate: YearMonth;
        flightsPerEmployeeForecastEnd: number;
        percentWorkFromHomeForecastEnd: number;
        netZeroSuppliersReductionPercent: number;
      };
      reduxInitiatives: Array<
        | {
            __typename: 'FootprintSliceInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            reductionTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
        | {
            __typename: 'PrecomputedActivityBasedInitiative';
            id: string;
            name: string;
            enabled: boolean;
            description: string | null;
            scheduleType: GQScheduleType;
            deletedAt: Date | null;
            createdAt: Date;
            precomputedLever: GQPrecomputedLever;
            implementationTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            costTimeseries: {
              __typename?: 'SimpleTimeseries';
              id: string | null;
              base: Date;
              frequency: GQTimeseriesFrequency;
              values: Array<number>;
            };
            filters: {
              __typename?: 'FilterExpressionGroupWithNewFilters';
              conjunction: GQFilterConjunction;
              expressions: Array<{
                __typename?: 'FilterExpressionPrimitiveWithNewFilters';
                field: string;
                value: Array<string>;
                operator: GQFilterOperator;
              }>;
            };
          }
      >;
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
      reduxCarbonOffsets: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonOffsetsCosts: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonRemoval: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
      reduxCarbonRemovalCosts: {
        __typename?: 'SimpleTimeseries';
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    };
  } | null;
};

export type GQSaveEmployeeFeedbackMutationVariables = Exact<{
  feedback: InputMaybe<Scalars['String']['input']>;
}>;

export type GQSaveEmployeeFeedbackMutation = {
  __typename?: 'Mutation';
  saveEmployeeFeedback: {
    __typename?: 'SaveEmployeeFeedbackPayload';
    id: string | null;
  } | null;
};

export type GQMarketplacePurchaseLineItemFieldsForSummaryReportFragment = {
  __typename?: 'MarketplacePurchaseLineItem';
  id: string;
  amount: number;
  amountUnit: GQMarketplaceProjectArchetypeUnit;
  deliveryDate: Date | null;
  isDelivered: boolean;
  archetype: {
    __typename?: 'MarketplaceProjectArchetype';
    id: string;
    name: string;
    bannerImageUrl: string | null;
    imageUrl: string | null;
    descriptionMd: string;
    certificateBlurb: string | null;
    kind: GQMarketplaceProjectArchetypeKind;
    mechanism: GQMarketplaceProjectArchetypeMechanism;
    summaryMd: string;
  } | null;
  project: {
    __typename?: 'MarketplaceProject';
    id: string;
    name: string;
    developer: {
      __typename?: 'MarketplaceDeveloper';
      id: string;
      name: string;
      imageUrl: string | null;
    } | null;
  } | null;
};

export type GQReportPageContentQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
}>;

export type GQReportPageContentQuery = {
  __typename?: 'Query';
  forceRedirect: string | null;
  activeOrganization: {
    __typename?: 'ActiveOrganization';
    id: string;
    name: string;
    helpChannel: string | null;
    demoOrg: boolean;
    testOrg: boolean;
    stagingOrg: boolean | null;
    region: GQRegion;
    watershedPlan: GQWatershedPlan;
    watershedPlanLegacy: GQWatershedPlanLegacy;
    latestPublishedFootprintVersion: string | null;
    latestDraftFootprintVersion: string | null;
    userOnboardingsCompleted: Array<GQOnboardingKind>;
    datasourceIdsToCompletePermissions: Array<string>;
    watershedEmployeePointOfContactKinds: Array<GQOrgPointOfContactKind>;
    logoUrl: string | null;
    iconUrl: string | null;
    canAccessFinance: boolean;
    canAccessCorporate: boolean;
    displayCustomReports: boolean;
    employeeReportId: string | null;
    fiscalYearStartMonth: number | null;
    currency: string | null;
    country: string | null;
    companyId: string;
    domains: Array<string>;
    sessionId: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      completedAt: Date | null;
      coverageInterval: YMInterval;
      coverageStartDate: Date;
      coverageEndDate: Date;
      status: GQMeasurementProjectStatus;
      deadline: Date | null;
    }>;
    footprintKindsMeta: Array<{
      __typename?: 'FootprintKindMeta';
      id: string;
      name: string;
      description: string;
      footprintInterval: YMInterval | null;
      footprintDraftInterval: YMInterval | null;
    }>;
    user: {
      __typename?: 'User';
      id: string;
      createdAt: Date;
      name: string;
      email: string;
      isWatershedEmployee: boolean;
      isWatershedContractor: boolean;
      isE2ETester: boolean;
      accessibleOrgs: Array<{
        __typename?: 'OrgLimitedProfile';
        id: string;
        name: string;
        companyId: string;
      }>;
    };
    userRoles: Array<{
      __typename?: 'UserRoleAssignment';
      id: string;
      role: {
        __typename?: 'Role';
        id: string;
        permissions: Array<{
          __typename?: 'PermissionItem';
          id: string;
          permission: GQPermissionType;
          object:
            | { __typename: 'Company'; id: string }
            | { __typename: 'CompanySurvey'; id: string }
            | { __typename: 'Dataset'; id: string }
            | { __typename: 'Datasource'; id: string }
            | { __typename: 'EngagementTask'; id: string }
            | { __typename: 'Fund'; id: string }
            | null;
        }>;
      };
    }>;
    userPermissions: Array<{
      __typename?: 'PermissionItem';
      id: string;
      permission: GQPermissionType;
      object:
        | { __typename: 'Company'; id: string }
        | { __typename: 'CompanySurvey'; id: string }
        | { __typename: 'Dataset'; id: string }
        | { __typename: 'Datasource'; id: string }
        | { __typename: 'EngagementTask'; id: string }
        | { __typename: 'Fund'; id: string }
        | null;
    }>;
    loginAsUser: {
      __typename?: 'User';
      id: string;
      name: string;
      isWatershedEmployee: boolean;
      isWatershedContractor: boolean;
      isE2ETester: boolean;
    } | null;
    funds: Array<{
      __typename: 'Fund';
      id: string;
      name: string;
      nameSortable: string | null;
      fundGroup: string | null;
      fundCategory: string | null;
      excludeAsSandbox: boolean | null;
      anonymousFundUuid: string | null;
      createdAt: Date;
      orgId: string;
      updatedAt: Date;
      tagData: any | null;
      externalId: string | null;
    }> | null;
    financeSavedViews: Array<{
      __typename?: 'FinanceSavedView';
      id: string;
      name: string;
    }>;
  };
  peerCompanyGroups: Array<{
    __typename: 'PeerCompanyGroup';
    id: string;
    name: string;
    footprintKinds: Array<string> | null;
    shouldHideFromFootprintOverview: boolean;
    members: Array<{
      __typename?: 'PeerCompanyGroupMember';
      id: string;
      shouldHideFromFootprintOverview: boolean;
      mostRecentHighQualityBenchmark: {
        __typename: 'Benchmark';
        id: string;
        reportId: string | null;
        name: string;
        reportingYear: number;
        publishingYear: number;
        kind: GQBenchmarkKind;
        normalizationKind: GQAggregateKind;
        amountKgco2e: number;
        currency: string;
        scope2Source: GQScope2Source | null;
        kgco2eByScope: Array<{
          __typename?: 'BenchmarkScopeBreakdownDataPoint';
          scope: GQGhgScope;
          kgco2e: number | null;
        }>;
      } | null;
    }>;
    selfBenchmarks: Array<{
      __typename: 'Benchmark';
      id: string;
      reportId: string | null;
      name: string;
      reportingYear: number;
      publishingYear: number;
      kind: GQBenchmarkKind;
      normalizationKind: GQAggregateKind;
      amountKgco2e: number;
      currency: string;
      scope2Source: GQScope2Source | null;
      kgco2eByScope: Array<{
        __typename?: 'BenchmarkScopeBreakdownDataPoint';
        scope: GQGhgScope;
        kgco2e: number | null;
      }>;
    }>;
  }>;
  flags: Array<{
    __typename?: 'FeatureFlag';
    id: string;
    name: GQFlags;
    activated: boolean;
  }>;
  report: {
    __typename?: 'EmployeeReport';
    id: string;
    footprintInterval: YMInterval;
    marketplaceInterval: YMInterval;
    programKind: GQClimateProgramKind;
    visibility: GQEmployeeReportVisibility;
    grossEmissions: number | null;
    grossEmissionsRevenueIntensity: number | null;
    plan: {
      __typename?: 'Plan';
      id: string;
      forecast: {
        __typename?: 'Forecast';
        id: string;
        interval: YMInterval;
        customIntensityConfigs: Array<{
          __typename?: 'CustomIntensityConfig';
          id: string;
          name: string;
          description: string;
          unit: string | null;
          humanReadable: boolean;
          quantityType: GQCustomIntensityConfigQuantityType;
          isSbtValidated: boolean | null;
        }>;
      };
      variables: {
        __typename?: 'PlanVariables';
        commitmentsHasNetZero: boolean;
        commitmentsNetZeroYear: number;
      };
      targets: Array<{
        __typename?: 'PlanTarget';
        id: string;
        createdAt: Date | null;
        description: string;
        reportDescription: string;
        customNotes: string;
        baseYear: YearMonth;
        intensityType: GQPlanTargetIntensityType;
        customIntensityConfigId: string | null;
        comparisonType: GQPlanTargetTargetComparisonType;
        reductionRate: GQTargetReductionRate;
        deletedAt: Date | null;
        assignedTo: string | null;
        parentTargetId: string | null;
        interimTargetDate: YearMonth | null;
        interimTargetValue: number | null;
        emissionsTargetCustom: {
          __typename?: 'SimpleTimeseries';
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
      }>;
    } | null;
    categorizedEmissions: Array<{
      __typename?: 'CategorizedEmissionData';
      businessCategory: string;
      businessSubcategory: string | null;
      proportion: number;
      amountKgco2e: number;
    }> | null;
    netZeroPlan: {
      __typename?: 'NetZeroPlan';
      id: string;
      descriptionMd: string;
      summaryTotal: {
        __typename?: 'NetZeroSummary';
        kind: GQNetZeroNormalizationKind;
        years: Array<{
          __typename?: 'NetZeroSummaryYear';
          year: number;
          kind: GQNetZeroNormalizationKind;
          emissionsWithReductions: number;
          bauEmissionsAvoided: number;
          carbonRemoval: number;
        }>;
      } | null;
      summaryRevenue: {
        __typename?: 'NetZeroSummary';
        kind: GQNetZeroNormalizationKind;
        years: Array<{
          __typename?: 'NetZeroSummaryYear';
          year: number;
          kind: GQNetZeroNormalizationKind;
          emissionsWithReductions: number;
          bauEmissionsAvoided: number;
          carbonRemoval: number;
        }>;
      } | null;
    } | null;
    footprintKindBreakdown: {
      __typename?: 'FootprintKindBreakdown';
      id: string;
      footprintKinds: Array<{
        __typename?: 'FootprintKindBreakdownItem';
        id: string;
        footprintKind: string;
        kgco2e: number;
        percentOfTotal: number;
      }>;
    };
    markdownCardInfo: {
      __typename?: 'MarkdownCardInfo';
      title: string;
      contentMd: string;
    } | null;
    climateProgramInfo: {
      __typename?: 'ClimateProgramInfo';
      steps: Array<{
        __typename?: 'ClimateProgramInfoStep';
        title: string;
        descriptionMd: string;
      }>;
      targets: Array<{
        __typename?: 'ClimateProgramInfoTarget';
        title: string;
        amount: string;
        descriptor: string | null;
      }>;
    };
    reductionTargetsInfo: {
      __typename?: 'ReductionTargetsInfo';
      heading: string;
      reductionTargets: Array<{
        __typename?: 'ReductionTargetsInfoItem';
        heading: string;
        subheading: string | null;
        title: string | null;
        description: string;
      }>;
    } | null;
    marketplacePurchaseLineItems: Array<{
      __typename?: 'MarketplacePurchaseLineItem';
      id: string;
      amount: number;
      amountUnit: GQMarketplaceProjectArchetypeUnit;
      deliveryDate: Date | null;
      isDelivered: boolean;
      archetype: {
        __typename?: 'MarketplaceProjectArchetype';
        id: string;
        name: string;
        bannerImageUrl: string | null;
        imageUrl: string | null;
        descriptionMd: string;
        certificateBlurb: string | null;
        kind: GQMarketplaceProjectArchetypeKind;
        mechanism: GQMarketplaceProjectArchetypeMechanism;
        summaryMd: string;
      } | null;
      project: {
        __typename?: 'MarketplaceProject';
        id: string;
        name: string;
        developer: {
          __typename?: 'MarketplaceDeveloper';
          id: string;
          name: string;
          imageUrl: string | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GQNetZeroSummaryYearFieldsFragment = {
  __typename?: 'NetZeroSummaryYear';
  year: number;
  kind: GQNetZeroNormalizationKind;
  emissionsWithReductions: number;
  bauEmissionsAvoided: number;
  carbonRemoval: number;
};

export type GQPlanFieldsForReportFragment = {
  __typename?: 'Plan';
  id: string;
  forecast: {
    __typename?: 'Forecast';
    id: string;
    interval: YMInterval;
    customIntensityConfigs: Array<{
      __typename?: 'CustomIntensityConfig';
      id: string;
      name: string;
      description: string;
      unit: string | null;
      humanReadable: boolean;
      quantityType: GQCustomIntensityConfigQuantityType;
      isSbtValidated: boolean | null;
    }>;
  };
  variables: {
    __typename?: 'PlanVariables';
    commitmentsHasNetZero: boolean;
    commitmentsNetZeroYear: number;
  };
  targets: Array<{
    __typename?: 'PlanTarget';
    id: string;
    createdAt: Date | null;
    description: string;
    reportDescription: string;
    customNotes: string;
    baseYear: YearMonth;
    intensityType: GQPlanTargetIntensityType;
    customIntensityConfigId: string | null;
    comparisonType: GQPlanTargetTargetComparisonType;
    reductionRate: GQTargetReductionRate;
    deletedAt: Date | null;
    assignedTo: string | null;
    parentTargetId: string | null;
    interimTargetDate: YearMonth | null;
    interimTargetValue: number | null;
    emissionsTargetCustom: {
      __typename?: 'SimpleTimeseries';
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    };
    filters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    };
  }>;
};

export type GQPeerCompanyGroupFieldsFragment = {
  __typename: 'PeerCompanyGroup';
  id: string;
  name: string;
  footprintKinds: Array<string> | null;
  shouldHideFromFootprintOverview: boolean;
  members: Array<{
    __typename?: 'PeerCompanyGroupMember';
    id: string;
    shouldHideFromFootprintOverview: boolean;
    mostRecentHighQualityBenchmark: {
      __typename: 'Benchmark';
      id: string;
      reportId: string | null;
      name: string;
      reportingYear: number;
      publishingYear: number;
      kind: GQBenchmarkKind;
      normalizationKind: GQAggregateKind;
      amountKgco2e: number;
      currency: string;
      scope2Source: GQScope2Source | null;
      kgco2eByScope: Array<{
        __typename?: 'BenchmarkScopeBreakdownDataPoint';
        scope: GQGhgScope;
        kgco2e: number | null;
      }>;
    } | null;
  }>;
  selfBenchmarks: Array<{
    __typename: 'Benchmark';
    id: string;
    reportId: string | null;
    name: string;
    reportingYear: number;
    publishingYear: number;
    kind: GQBenchmarkKind;
    normalizationKind: GQAggregateKind;
    amountKgco2e: number;
    currency: string;
    scope2Source: GQScope2Source | null;
    kgco2eByScope: Array<{
      __typename?: 'BenchmarkScopeBreakdownDataPoint';
      scope: GQGhgScope;
      kgco2e: number | null;
    }>;
  }>;
};

export type GQBenchmarkFieldsFragment = {
  __typename: 'Benchmark';
  id: string;
  reportId: string | null;
  name: string;
  reportingYear: number;
  publishingYear: number;
  kind: GQBenchmarkKind;
  normalizationKind: GQAggregateKind;
  amountKgco2e: number;
  currency: string;
  scope2Source: GQScope2Source | null;
  kgco2eByScope: Array<{
    __typename?: 'BenchmarkScopeBreakdownDataPoint';
    scope: GQGhgScope;
    kgco2e: number | null;
  }>;
};

export type GQPeerCompanyReportsFragment = {
  __typename?: 'PeerCompanyGroup';
  id: string;
  members: Array<{
    __typename?: 'PeerCompanyGroupMember';
    id: string;
    company: {
      __typename?: 'Company';
      id: string;
      disclosuresV2: Array<{
        __typename?: 'Disclosure';
        id: string;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          companyId: string | null;
          reportType: GQExternalReportType;
          publishingYear: number;
          gsUrl: string | null;
          publicUrl: string;
          sectionUrls: Array<{
            __typename?: 'ExternalReportSectionUrl';
            id: string;
            sectionKind: GQExternalReportSectionKind;
            urlFragment: string;
          }> | null;
          company: {
            __typename?: 'Company';
            id: string;
            name: string;
            fullLogoUrl: string | null;
          } | null;
        } | null;
      }>;
    };
  }>;
};

export type GQPeerCompanyReportsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQPeerCompanyReportsQuery = {
  __typename?: 'Query';
  peerCompanyGroups: Array<{
    __typename?: 'PeerCompanyGroup';
    id: string;
    members: Array<{
      __typename?: 'PeerCompanyGroupMember';
      id: string;
      company: {
        __typename?: 'Company';
        id: string;
        disclosuresV2: Array<{
          __typename?: 'Disclosure';
          id: string;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            companyId: string | null;
            reportType: GQExternalReportType;
            publishingYear: number;
            gsUrl: string | null;
            publicUrl: string;
            sectionUrls: Array<{
              __typename?: 'ExternalReportSectionUrl';
              id: string;
              sectionKind: GQExternalReportSectionKind;
              urlFragment: string;
            }> | null;
            company: {
              __typename?: 'Company';
              id: string;
              name: string;
              fullLogoUrl: string | null;
            } | null;
          } | null;
        }>;
      };
    }>;
  }>;
};

export type GQPeerReportsPageQueryVariables = Exact<{
  reportTypes: InputMaybe<Array<GQExternalReportType> | GQExternalReportType>;
  publishingYears: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >;
  companyIds: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  filterLicensed: InputMaybe<Scalars['Boolean']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQPeerReportsPageQuery = {
  __typename?: 'Query';
  publicDisclosuresPaginated: {
    __typename?: 'PublicDisclosureConnection';
    edges: Array<{
      __typename?: 'PublicDisclosureEdge';
      node: {
        __typename?: 'PublicDisclosure';
        id: string;
        companyId: string | null;
        reportType: GQExternalReportType;
        publishingYear: number;
        gsUrl: string | null;
        publicUrl: string;
        sectionUrls: Array<{
          __typename?: 'ExternalReportSectionUrl';
          id: string;
          sectionKind: GQExternalReportSectionKind;
          urlFragment: string;
        }> | null;
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          fullLogoUrl: string | null;
        } | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
  uniqueExternalReportFilterValues: {
    __typename?: 'UniqueExternalReportFilterValues';
    reportTypes: Array<GQExternalReportType>;
    publishingYears: Array<number>;
  };
};

export type GQWatershedRecommendedPeerReportsQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQWatershedRecommendedPeerReportsQuery = {
  __typename?: 'Query';
  recommendedPublicDisclosures: {
    __typename?: 'PublicDisclosureConnection';
    edges: Array<{
      __typename?: 'PublicDisclosureEdge';
      node: {
        __typename?: 'PublicDisclosure';
        id: string;
        companyId: string | null;
        reportType: GQExternalReportType;
        publishingYear: number;
        gsUrl: string | null;
        publicUrl: string;
        sectionUrls: Array<{
          __typename?: 'ExternalReportSectionUrl';
          id: string;
          sectionKind: GQExternalReportSectionKind;
          urlFragment: string;
        }> | null;
        company: {
          __typename?: 'Company';
          id: string;
          name: string;
          fullLogoUrl: string | null;
        } | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type GQCompanyFuzzyMatchForPublicDisclosureQueryVariables = Exact<{
  q: Scalars['String']['input'];
}>;

export type GQCompanyFuzzyMatchForPublicDisclosureQuery = {
  __typename?: 'Query';
  companyFuzzyMatch: Array<{
    __typename?: 'Company';
    id: string;
    name: string;
    disclosuresV2: Array<{
      __typename?: 'Disclosure';
      id: string;
      publicDisclosure: { __typename?: 'PublicDisclosure'; id: string } | null;
    }>;
  }>;
};

export type GQGetSsoConfigPageQueryVariables = Exact<{ [key: string]: never }>;

export type GQGetSsoConfigPageQuery = {
  __typename?: 'Query';
  userHasPermission: boolean;
  organization: {
    __typename?: 'Organization';
    id: string;
    workosOrgId: string | null;
    hasActiveWorkosConnection: boolean;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          name: string;
          email: string;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQGetAllAssignableUsersWithPermissionBatchQueryVariables = Exact<{
  permissions: Array<GQHasPermissionInput> | GQHasPermissionInput;
  orgId: Scalars['ID']['input'];
}>;

export type GQGetAllAssignableUsersWithPermissionBatchQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    usersWithPermissions: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: { __typename?: 'User'; id: string } | null;
      } | null>;
    } | null;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          orgAccessId: string;
          id: string;
          name: string;
          displayName: string;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQGetAllAssignableUsersWithPermissionQueryVariables = Exact<{
  permissions: Array<GQHasPermissionInput> | GQHasPermissionInput;
  orgId: Scalars['ID']['input'];
}>;

export type GQGetAllAssignableUsersWithPermissionQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          hasPermissions: boolean;
          hasAnyPermissions: boolean;
          orgAccessId: string;
          id: string;
          name: string;
          displayName: string;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQActiveOrganizationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQActiveOrganizationQuery = {
  __typename?: 'Query';
  preferredLocale: string | null;
  activeOrganization: {
    __typename?: 'ActiveOrganization';
    id: string;
    name: string;
    helpChannel: string | null;
    demoOrg: boolean;
    testOrg: boolean;
    stagingOrg: boolean | null;
    region: GQRegion;
    watershedPlan: GQWatershedPlan;
    watershedPlanLegacy: GQWatershedPlanLegacy;
    latestPublishedFootprintVersion: string | null;
    latestDraftFootprintVersion: string | null;
    userOnboardingsCompleted: Array<GQOnboardingKind>;
    datasourceIdsToCompletePermissions: Array<string>;
    watershedEmployeePointOfContactKinds: Array<GQOrgPointOfContactKind>;
    logoUrl: string | null;
    iconUrl: string | null;
    canAccessFinance: boolean;
    canAccessCorporate: boolean;
    displayCustomReports: boolean;
    employeeReportId: string | null;
    fiscalYearStartMonth: number | null;
    currency: string | null;
    country: string | null;
    companyId: string;
    domains: Array<string>;
    sessionId: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      active: boolean;
      completedAt: Date | null;
      coverageInterval: YMInterval;
      coverageStartDate: Date;
      coverageEndDate: Date;
      status: GQMeasurementProjectStatus;
      deadline: Date | null;
    }>;
    footprintKindsMeta: Array<{
      __typename?: 'FootprintKindMeta';
      id: string;
      name: string;
      description: string;
      footprintInterval: YMInterval | null;
      footprintDraftInterval: YMInterval | null;
    }>;
    user: {
      __typename?: 'User';
      id: string;
      createdAt: Date;
      name: string;
      email: string;
      isWatershedEmployee: boolean;
      isWatershedContractor: boolean;
      isE2ETester: boolean;
      accessibleOrgs: Array<{
        __typename?: 'OrgLimitedProfile';
        id: string;
        name: string;
        companyId: string;
      }>;
    };
    userRoles: Array<{
      __typename?: 'UserRoleAssignment';
      id: string;
      role: {
        __typename?: 'Role';
        id: string;
        permissions: Array<{
          __typename?: 'PermissionItem';
          id: string;
          permission: GQPermissionType;
          object:
            | { __typename: 'Company'; id: string }
            | { __typename: 'CompanySurvey'; id: string }
            | { __typename: 'Dataset'; id: string }
            | { __typename: 'Datasource'; id: string }
            | { __typename: 'EngagementTask'; id: string }
            | { __typename: 'Fund'; id: string }
            | null;
        }>;
      };
    }>;
    userPermissions: Array<{
      __typename?: 'PermissionItem';
      id: string;
      permission: GQPermissionType;
      object:
        | { __typename: 'Company'; id: string }
        | { __typename: 'CompanySurvey'; id: string }
        | { __typename: 'Dataset'; id: string }
        | { __typename: 'Datasource'; id: string }
        | { __typename: 'EngagementTask'; id: string }
        | { __typename: 'Fund'; id: string }
        | null;
    }>;
    loginAsUser: {
      __typename?: 'User';
      id: string;
      name: string;
      isWatershedEmployee: boolean;
      isWatershedContractor: boolean;
      isE2ETester: boolean;
    } | null;
    funds: Array<{
      __typename: 'Fund';
      id: string;
      name: string;
      nameSortable: string | null;
      fundGroup: string | null;
      fundCategory: string | null;
      excludeAsSandbox: boolean | null;
      anonymousFundUuid: string | null;
      createdAt: Date;
      orgId: string;
      updatedAt: Date;
      tagData: any | null;
      externalId: string | null;
    }> | null;
    financeSavedViews: Array<{
      __typename?: 'FinanceSavedView';
      id: string;
      name: string;
    }>;
  };
  flags: Array<{
    __typename?: 'FeatureFlag';
    id: string;
    name: GQFlags;
    description: string;
    activated: boolean;
  }>;
};

export type GQForceRedirectQueryVariables = Exact<{
  pathname: InputMaybe<Scalars['String']['input']>;
}>;

export type GQForceRedirectQuery = {
  __typename?: 'Query';
  forceRedirect: string | null;
};

export type GQCheckUserAuthenticationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQCheckUserAuthenticationQuery = {
  __typename?: 'Query';
  activeOrganization: { __typename?: 'ActiveOrganization'; id: string };
};

export type GQFootprintKindMetaFieldsFragment = {
  __typename?: 'FootprintKindMeta';
  id: string;
  name: string;
  description: string;
  footprintInterval: YMInterval | null;
  footprintDraftInterval: YMInterval | null;
};

export type GQPermissionDetailsFragment = {
  __typename?: 'PermissionItem';
  id: string;
  permission: GQPermissionType;
  object:
    | { __typename: 'Company'; id: string }
    | { __typename: 'CompanySurvey'; id: string }
    | { __typename: 'Dataset'; id: string }
    | { __typename: 'Datasource'; id: string }
    | { __typename: 'EngagementTask'; id: string }
    | { __typename: 'Fund'; id: string }
    | null;
};

export type GQActiveOrganizationSavedViewFragment = {
  __typename?: 'FinanceSavedView';
  id: string;
  name: string;
};

export type GQActiveOrganizationFundFragment = {
  __typename?: 'Fund';
  id: string;
  name: string;
  nameSortable: string | null;
  fundGroup: string | null;
  fundCategory: string | null;
  excludeAsSandbox: boolean | null;
};

export type GQMeasurementProjectUserContextFieldsFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  active: boolean;
  completedAt: Date | null;
  coverageInterval: YMInterval;
  coverageStartDate: Date;
  coverageEndDate: Date;
  status: GQMeasurementProjectStatus;
  deadline: Date | null;
};

export type GQActiveOrganizationFieldsFragment = {
  __typename?: 'ActiveOrganization';
  id: string;
  name: string;
  helpChannel: string | null;
  demoOrg: boolean;
  testOrg: boolean;
  stagingOrg: boolean | null;
  region: GQRegion;
  watershedPlan: GQWatershedPlan;
  watershedPlanLegacy: GQWatershedPlanLegacy;
  latestPublishedFootprintVersion: string | null;
  latestDraftFootprintVersion: string | null;
  userOnboardingsCompleted: Array<GQOnboardingKind>;
  datasourceIdsToCompletePermissions: Array<string>;
  watershedEmployeePointOfContactKinds: Array<GQOrgPointOfContactKind>;
  logoUrl: string | null;
  iconUrl: string | null;
  canAccessFinance: boolean;
  canAccessCorporate: boolean;
  displayCustomReports: boolean;
  employeeReportId: string | null;
  fiscalYearStartMonth: number | null;
  currency: string | null;
  country: string | null;
  companyId: string;
  domains: Array<string>;
  sessionId: string;
  measurementProjects: Array<{
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    active: boolean;
    completedAt: Date | null;
    coverageInterval: YMInterval;
    coverageStartDate: Date;
    coverageEndDate: Date;
    status: GQMeasurementProjectStatus;
    deadline: Date | null;
  }>;
  footprintKindsMeta: Array<{
    __typename?: 'FootprintKindMeta';
    id: string;
    name: string;
    description: string;
    footprintInterval: YMInterval | null;
    footprintDraftInterval: YMInterval | null;
  }>;
  user: {
    __typename?: 'User';
    id: string;
    createdAt: Date;
    name: string;
    email: string;
    isWatershedEmployee: boolean;
    isWatershedContractor: boolean;
    isE2ETester: boolean;
    accessibleOrgs: Array<{
      __typename?: 'OrgLimitedProfile';
      id: string;
      name: string;
      companyId: string;
    }>;
  };
  userRoles: Array<{
    __typename?: 'UserRoleAssignment';
    id: string;
    role: {
      __typename?: 'Role';
      id: string;
      permissions: Array<{
        __typename?: 'PermissionItem';
        id: string;
        permission: GQPermissionType;
        object:
          | { __typename: 'Company'; id: string }
          | { __typename: 'CompanySurvey'; id: string }
          | { __typename: 'Dataset'; id: string }
          | { __typename: 'Datasource'; id: string }
          | { __typename: 'EngagementTask'; id: string }
          | { __typename: 'Fund'; id: string }
          | null;
      }>;
    };
  }>;
  userPermissions: Array<{
    __typename?: 'PermissionItem';
    id: string;
    permission: GQPermissionType;
    object:
      | { __typename: 'Company'; id: string }
      | { __typename: 'CompanySurvey'; id: string }
      | { __typename: 'Dataset'; id: string }
      | { __typename: 'Datasource'; id: string }
      | { __typename: 'EngagementTask'; id: string }
      | { __typename: 'Fund'; id: string }
      | null;
  }>;
  loginAsUser: {
    __typename?: 'User';
    id: string;
    name: string;
    isWatershedEmployee: boolean;
    isWatershedContractor: boolean;
    isE2ETester: boolean;
  } | null;
  funds: Array<{
    __typename: 'Fund';
    id: string;
    name: string;
    nameSortable: string | null;
    fundGroup: string | null;
    fundCategory: string | null;
    excludeAsSandbox: boolean | null;
    anonymousFundUuid: string | null;
    createdAt: Date;
    orgId: string;
    updatedAt: Date;
    tagData: any | null;
    externalId: string | null;
  }> | null;
  financeSavedViews: Array<{
    __typename?: 'FinanceSavedView';
    id: string;
    name: string;
  }>;
};

export type GQFlagsInitFieldsFragment = {
  __typename?: 'FeatureFlag';
  id: string;
  name: GQFlags;
  description: string;
  activated: boolean;
};

export type GQThrowErrorMutationVariables = Exact<{ [key: string]: never }>;

export type GQThrowErrorMutation = {
  __typename?: 'Mutation';
  throwError: {
    __typename?: 'ThrowErrorPayload';
    message: string | null;
  } | null;
};

export type GQCompleteOnboardingMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  kind: GQOnboardingKind;
}>;

export type GQCompleteOnboardingMutation = {
  __typename?: 'Mutation';
  completeOnboarding: {
    __typename?: 'CompleteOnboardingPayload';
    userOnboardingsCompleted: Array<GQOnboardingKind>;
    user: { __typename?: 'User'; id: string } | null;
  } | null;
};

export type GQFundListQueryVariables = Exact<{ [key: string]: never }>;

export type GQFundListQuery = {
  __typename?: 'Query';
  funds: Array<{ __typename: 'Fund'; id: string }>;
};

export type GQAssetCorporateListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQAssetCorporateListQuery = {
  __typename?: 'Query';
  assetsCorporate: Array<{ __typename?: 'AssetCorporate'; id: string }>;
};

export type GQAssetRealEstateListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQAssetRealEstateListQuery = {
  __typename?: 'Query';
  assetsRealEstate: Array<{ __typename?: 'AssetRealEstate'; id: string }>;
};

export type GQAssetGroupListQueryVariables = Exact<{ [key: string]: never }>;

export type GQAssetGroupListQuery = {
  __typename?: 'Query';
  assetGroups: Array<{ __typename?: 'AssetGroup'; id: string }>;
};

export type GQAssetSovereignBondListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQAssetSovereignBondListQuery = {
  __typename?: 'Query';
  assetSovereignBonds: Array<{ __typename?: 'AssetSovereignBond'; id: string }>;
};

export type GQAssetPersonalMotorVehicleInsuranceListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQAssetPersonalMotorVehicleInsuranceListQuery = {
  __typename?: 'Query';
  assetPersonalMotorVehicleInsurances: Array<{
    __typename?: 'AssetPersonalMotorVehicleInsurance';
    id: string;
  }>;
};

export type GQFinanceTagListQueryVariables = Exact<{ [key: string]: never }>;

export type GQFinanceTagListQuery = {
  __typename?: 'Query';
  financeTags: Array<{ __typename?: 'FinanceTag'; id: string }>;
};

export type GQAssetHoldingListQueryVariables = Exact<{ [key: string]: never }>;

export type GQAssetHoldingListQuery = {
  __typename?: 'Query';
  assetHoldings: Array<{ __typename?: 'FinanceAssetHolding'; id: string }>;
};

export type GQPendingCompanyChangeRequestsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQPendingCompanyChangeRequestsQuery = {
  __typename?: 'Query';
  pendingCompanyChangeRequests: Array<{
    __typename?: 'CompanyChangeRequest';
    id: string;
  }>;
};

export type GQOrganizationForCacheUpdateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQOrganizationForCacheUpdateQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    orgCompanies: Array<{
      __typename?: 'OrgCompany';
      id: string;
      name: string;
    }>;
  };
};

export type GQOrganizationForDeleteOrgCompanyCacheUpdateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQOrganizationForDeleteOrgCompanyCacheUpdateQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    orgCompanies: Array<{
      __typename?: 'OrgCompany';
      id: string;
      name: string;
    }>;
  };
};

export type GQPendingChangeReqsForCreateCacheQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQPendingChangeReqsForCreateCacheQuery = {
  __typename?: 'Query';
  pendingCompanyChangeRequests: Array<{
    __typename?: 'CompanyChangeRequest';
    id: string;
  }>;
};

export type GQMeasurementProjectForDemoPlatformFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  coverageStartDate: Date;
  coverageEndDate: Date;
  active: boolean;
};

export type GQDemoPlatformInputsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQDemoPlatformInputsQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    measurementProjects: Array<{
      __typename?: 'MeasurementProject';
      id: string;
      name: string;
      coverageStartDate: Date;
      coverageEndDate: Date;
      active: boolean;
    }>;
  };
};

export type GQDemoPlatformRunForPageFragment = {
  __typename?: 'DemoPlatformRun';
  id: string;
  config: any;
  statusJson: any | null;
  createdAt: Date;
  user: { __typename?: 'User'; id: string; name: string };
};

export type GQDemoPlatformRunPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQDemoPlatformRunPageQuery = {
  __typename?: 'Query';
  demoPlatformRun: {
    __typename?: 'DemoPlatformRun';
    id: string;
    config: any;
    statusJson: any | null;
    createdAt: Date;
    user: { __typename?: 'User'; id: string; name: string };
  } | null;
};

export type GQDemoPlatformRunForGridFragment = {
  __typename?: 'DemoPlatformRun';
  id: string;
  config: any;
  statusJson: any | null;
  createdAt: Date;
  user: { __typename?: 'User'; id: string; name: string };
};

export type GQDemoPlatformRunGridQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQDemoPlatformRunGridQuery = {
  __typename?: 'Query';
  demoPlatformRuns: {
    __typename?: 'DemoPlatformRunConnection';
    edges: Array<{
      __typename?: 'DemoPlatformRunEdge';
      node: {
        __typename?: 'DemoPlatformRun';
        id: string;
        config: any;
        statusJson: any | null;
        createdAt: Date;
        user: { __typename?: 'User'; id: string; name: string };
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor: string | null;
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type GQRunDemoPlatformMutationVariables = Exact<{
  input: GQRunDemoPlatformInput;
}>;

export type GQRunDemoPlatformMutation = {
  __typename?: 'Mutation';
  runDemoPlatform: {
    __typename?: 'RunDemoPlatformPayload';
    demoPlatformRunId: string;
    jobId: string;
  } | null;
};

export type GQFinanceSnapshotIsStaleFragment = {
  __typename?: 'FinanceSnapshot';
  id: string;
  isStale: boolean | null;
};

export type GQFinanceSnapshotForContextFragment = {
  __typename?: 'FinanceSnapshot';
  id: string;
  snapshotName: string | null;
  note: string | null;
  config: any | null;
  createdAt: Date;
  executionStatus: GQFinanceSnapshotExecutionStatus;
  executionStatusMessage: string;
  completedAt: Date | null;
  isStale: boolean | null;
  flagsPopulated: boolean | null;
  financedEmissionsTonnesCurrentYear: number | null;
  footprintSnapshotId: string | null;
  createdBy: {
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
  } | null;
};

export type GQFinanceSnapshotsQueryVariables = Exact<{ [key: string]: never }>;

export type GQFinanceSnapshotsQuery = {
  __typename?: 'Query';
  financeSnapshots: Array<{
    __typename?: 'FinanceSnapshot';
    id: string;
    snapshotName: string | null;
    note: string | null;
    config: any | null;
    createdAt: Date;
    executionStatus: GQFinanceSnapshotExecutionStatus;
    executionStatusMessage: string;
    completedAt: Date | null;
    isStale: boolean | null;
    flagsPopulated: boolean | null;
    financedEmissionsTonnesCurrentYear: number | null;
    footprintSnapshotId: string | null;
    createdBy: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    } | null;
  }>;
};

export type GQFinanceSnapshotForStatusQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQFinanceSnapshotForStatusQuery = {
  __typename?: 'Query';
  financeSnapshot: {
    __typename?: 'FinanceSnapshot';
    id: string;
    snapshotName: string | null;
    note: string | null;
    config: any | null;
    createdAt: Date;
    executionStatus: GQFinanceSnapshotExecutionStatus;
    executionStatusMessage: string;
    completedAt: Date | null;
    isStale: boolean | null;
    flagsPopulated: boolean | null;
    financedEmissionsTonnesCurrentYear: number | null;
    footprintSnapshotId: string | null;
    createdBy: {
      __typename?: 'User';
      id: string;
      name: string;
      displayName: string;
    } | null;
  };
};

export type GQSupplierContactAllFieldsFragment = {
  __typename?: 'SupplierContact';
  id: string;
  name: string;
  email: string;
  role: string | null;
  companyId: string;
  userId: string | null;
};

export type GQFinanceFundsQueryVariables = Exact<{ [key: string]: never }>;

export type GQFinanceFundsQuery = {
  __typename?: 'Query';
  funds: Array<{
    __typename: 'Fund';
    id: string;
    name: string;
    nameSortable: string | null;
    fundGroup: string | null;
    fundCategory: string | null;
    excludeAsSandbox: boolean | null;
    anonymousFundUuid: string | null;
    createdAt: Date;
    orgId: string;
    updatedAt: Date;
    tagData: any | null;
    externalId: string | null;
  }>;
};

export type GQFinanceAssetsCorporateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFinanceAssetsCorporateQuery = {
  __typename?: 'Query';
  assetsCorporate: Array<{
    __typename: 'AssetCorporate';
    id: string;
    orgId: string;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    naicsCode: string | null;
    companyIsin: string | null;
    companyLei: string | null;
    companyUrl: string | null;
    companyTicker: string | null;
    currencyCode: GQCurrencyCode;
    countryAlpha2: string | null;
    buildingSizeUnit: GQBuildingSizeUnit | null;
    companyId: string | null;
    otherData: any | null;
    tagData: any | null;
    externalId: string | null;
    assetGroupId: string | null;
    anonymousCompanyUuid: string | null;
    assetGroup:
      | { __typename?: 'AssetCorporate'; id: string; name: string }
      | { __typename?: 'AssetGroup'; id: string; name: string }
      | {
          __typename?: 'AssetPersonalMotorVehicleInsurance';
          id: string;
          name: string;
        }
      | { __typename?: 'AssetRealEstate'; id: string; name: string }
      | { __typename?: 'AssetSovereignBond'; id: string; name: string }
      | null;
  }>;
};

export type GQFinanceAssetsRealEstateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFinanceAssetsRealEstateQuery = {
  __typename?: 'Query';
  assetsRealEstate: Array<{
    __typename: 'AssetRealEstate';
    id: string;
    orgId: string;
    createdAt: Date;
    updatedAt: Date;
    buildingKind: string | null;
    name: string;
    streetAddress: string | null;
    streetAddress2: string | null;
    city: string | null;
    state: string | null;
    countryAlpha2: string | null;
    postalCode: string | null;
    currencyCode: GQCurrencyCode;
    buildingSizeUnit: GQBuildingSizeUnit | null;
    otherData: any | null;
    tagData: any | null;
    externalId: string | null;
    assetGroupId: string | null;
    sizeUnit: GQBuildingSizeUnit | null;
    country: string | null;
    buildingName: string;
    assetGroup:
      | { __typename?: 'AssetCorporate'; id: string; name: string }
      | { __typename?: 'AssetGroup'; id: string; name: string }
      | {
          __typename?: 'AssetPersonalMotorVehicleInsurance';
          id: string;
          name: string;
        }
      | { __typename?: 'AssetRealEstate'; id: string; name: string }
      | { __typename?: 'AssetSovereignBond'; id: string; name: string }
      | null;
  }>;
};

export type GQFinanceAssetGroupsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFinanceAssetGroupsQuery = {
  __typename?: 'Query';
  assetGroups: Array<{
    __typename: 'AssetGroup';
    id: string;
    orgId: string;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    currencyCode: GQCurrencyCode;
    buildingSizeUnit: GQBuildingSizeUnit | null;
    otherData: any | null;
    tagData: any | null;
    externalId: string | null;
    companyId: string | null;
    assetGroupId: string | null;
  }>;
};

export type GQFinanceAssetSovereignBondsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFinanceAssetSovereignBondsQuery = {
  __typename?: 'Query';
  assetSovereignBonds: Array<{
    __typename: 'AssetSovereignBond';
    id: string;
    orgId: string;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    sovereignEntity: string;
    currencyCode: GQCurrencyCode;
    tagData: any | null;
  }>;
};

export type GQFinanceAssetPersonalMotorVehicleInsurancesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFinanceAssetPersonalMotorVehicleInsurancesQuery = {
  __typename?: 'Query';
  assetPersonalMotorVehicleInsurances: Array<{
    __typename: 'AssetPersonalMotorVehicleInsurance';
    id: string;
    orgId: string;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    currencyCode: GQCurrencyCode;
    tagData: any | null;
  }>;
};

export type GQFinancePersonalMotorVehicleInsuranceAssetYearsQueryVariables =
  Exact<{ [key: string]: never }>;

export type GQFinancePersonalMotorVehicleInsuranceAssetYearsQuery = {
  __typename?: 'Query';
  personalMotorVehicleInsuranceAssetYears: Array<{
    __typename: 'FinanceAssetYearNew';
    id: string;
    orgId: string;
    createdAt: Date;
    updatedAt: Date;
    year: number;
    assetCommercialRealEstateId: string | null;
    assetCorporateId: string | null;
    assetGroupId: string | null;
    assetSovereignBondId: string | null;
    assetPersonalMotorVehicleInsuranceId: string | null;
    buildingSizeNative: number | null;
    estimationMethodology: GQEstimationMethodology | null;
    headcount: number | null;
    percentRenewable: number | null;
    cloudSpendNative: number | null;
    valueNative: number | null;
    revenueNative: number | null;
    flightMiles: number | null;
    percentEmployeesWfh: number | null;
    currencyCode: GQCurrencyCode;
    currencyConversionRate: number;
    totalMwh: number | null;
    revenueSourceEmissionsYearId: string | null;
    emissionsYearId: string | null;
    scope1OverrideTco2e: number | null;
    scope1OverridePcaf: number | null;
    scope2LocationOverrideTco2e: number | null;
    scope2LocationOverridePcaf: number | null;
    scope2MarketOverrideTco2e: number | null;
    scope2MarketOverridePcaf: number | null;
    scope3OverrideKind: GQScope3OverrideKind | null;
    scope3OverrideTco2e: number | null;
    scope3OverridePcaf: number | null;
    scope301OverrideTco2e: number | null;
    scope302OverrideTco2e: number | null;
    scope303OverrideTco2e: number | null;
    scope304OverrideTco2e: number | null;
    scope305OverrideTco2e: number | null;
    scope306OverrideTco2e: number | null;
    scope307OverrideTco2e: number | null;
    scope308OverrideTco2e: number | null;
    scope309OverrideTco2e: number | null;
    scope310OverrideTco2e: number | null;
    scope311OverrideTco2e: number | null;
    scope312OverrideTco2e: number | null;
    scope313OverrideTco2e: number | null;
    scope314OverrideTco2e: number | null;
    scope315OverrideTco2e: number | null;
    buildingEstimationErr: string | null;
    companyEstimationErr: string | null;
    measuringScope1And2Emissions: boolean | null;
    measuringMaterialScope3Emissions: boolean | null;
    initiatedDecarbonizationPlan: boolean | null;
    committedToNearTermSbtAlignedNetZero: boolean | null;
    demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
    iciAlignmentOverride: GQIciAlignmentStatus | null;
    scenario: string | null;
    tagData: any | null;
    asset:
      | { __typename?: 'AssetCorporate'; id: string; name: string }
      | { __typename?: 'AssetGroup'; id: string; name: string }
      | {
          __typename?: 'AssetPersonalMotorVehicleInsurance';
          id: string;
          name: string;
        }
      | { __typename?: 'AssetRealEstate'; id: string; name: string }
      | { __typename?: 'AssetSovereignBond'; id: string; name: string }
      | null;
  }>;
};

export type GQFinanceSovereignBondAssetYearsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFinanceSovereignBondAssetYearsQuery = {
  __typename?: 'Query';
  sovereignBondAssetYears: Array<{
    __typename: 'FinanceAssetYearNew';
    id: string;
    orgId: string;
    createdAt: Date;
    updatedAt: Date;
    year: number;
    assetCommercialRealEstateId: string | null;
    assetCorporateId: string | null;
    assetGroupId: string | null;
    assetSovereignBondId: string | null;
    assetPersonalMotorVehicleInsuranceId: string | null;
    buildingSizeNative: number | null;
    estimationMethodology: GQEstimationMethodology | null;
    headcount: number | null;
    percentRenewable: number | null;
    cloudSpendNative: number | null;
    valueNative: number | null;
    revenueNative: number | null;
    flightMiles: number | null;
    percentEmployeesWfh: number | null;
    currencyCode: GQCurrencyCode;
    currencyConversionRate: number;
    totalMwh: number | null;
    revenueSourceEmissionsYearId: string | null;
    emissionsYearId: string | null;
    scope1OverrideTco2e: number | null;
    scope1OverridePcaf: number | null;
    scope2LocationOverrideTco2e: number | null;
    scope2LocationOverridePcaf: number | null;
    scope2MarketOverrideTco2e: number | null;
    scope2MarketOverridePcaf: number | null;
    scope3OverrideKind: GQScope3OverrideKind | null;
    scope3OverrideTco2e: number | null;
    scope3OverridePcaf: number | null;
    scope301OverrideTco2e: number | null;
    scope302OverrideTco2e: number | null;
    scope303OverrideTco2e: number | null;
    scope304OverrideTco2e: number | null;
    scope305OverrideTco2e: number | null;
    scope306OverrideTco2e: number | null;
    scope307OverrideTco2e: number | null;
    scope308OverrideTco2e: number | null;
    scope309OverrideTco2e: number | null;
    scope310OverrideTco2e: number | null;
    scope311OverrideTco2e: number | null;
    scope312OverrideTco2e: number | null;
    scope313OverrideTco2e: number | null;
    scope314OverrideTco2e: number | null;
    scope315OverrideTco2e: number | null;
    buildingEstimationErr: string | null;
    companyEstimationErr: string | null;
    measuringScope1And2Emissions: boolean | null;
    measuringMaterialScope3Emissions: boolean | null;
    initiatedDecarbonizationPlan: boolean | null;
    committedToNearTermSbtAlignedNetZero: boolean | null;
    demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
    iciAlignmentOverride: GQIciAlignmentStatus | null;
    scenario: string | null;
    tagData: any | null;
    asset:
      | { __typename?: 'AssetCorporate'; id: string; name: string }
      | { __typename?: 'AssetGroup'; id: string; name: string }
      | {
          __typename?: 'AssetPersonalMotorVehicleInsurance';
          id: string;
          name: string;
        }
      | { __typename?: 'AssetRealEstate'; id: string; name: string }
      | { __typename?: 'AssetSovereignBond'; id: string; name: string }
      | null;
  }>;
};

export type GQFinanceAssetYearsQueryVariables = Exact<{ [key: string]: never }>;

export type GQFinanceAssetYearsQuery = {
  __typename?: 'Query';
  assetYears: Array<{
    __typename: 'FinanceAssetYearNew';
    id: string;
    orgId: string;
    createdAt: Date;
    updatedAt: Date;
    year: number;
    assetCommercialRealEstateId: string | null;
    assetCorporateId: string | null;
    assetGroupId: string | null;
    assetSovereignBondId: string | null;
    assetPersonalMotorVehicleInsuranceId: string | null;
    buildingSizeNative: number | null;
    estimationMethodology: GQEstimationMethodology | null;
    headcount: number | null;
    percentRenewable: number | null;
    cloudSpendNative: number | null;
    valueNative: number | null;
    revenueNative: number | null;
    flightMiles: number | null;
    percentEmployeesWfh: number | null;
    currencyCode: GQCurrencyCode;
    currencyConversionRate: number;
    totalMwh: number | null;
    revenueSourceEmissionsYearId: string | null;
    emissionsYearId: string | null;
    scope1OverrideTco2e: number | null;
    scope1OverridePcaf: number | null;
    scope2LocationOverrideTco2e: number | null;
    scope2LocationOverridePcaf: number | null;
    scope2MarketOverrideTco2e: number | null;
    scope2MarketOverridePcaf: number | null;
    scope3OverrideKind: GQScope3OverrideKind | null;
    scope3OverrideTco2e: number | null;
    scope3OverridePcaf: number | null;
    scope301OverrideTco2e: number | null;
    scope302OverrideTco2e: number | null;
    scope303OverrideTco2e: number | null;
    scope304OverrideTco2e: number | null;
    scope305OverrideTco2e: number | null;
    scope306OverrideTco2e: number | null;
    scope307OverrideTco2e: number | null;
    scope308OverrideTco2e: number | null;
    scope309OverrideTco2e: number | null;
    scope310OverrideTco2e: number | null;
    scope311OverrideTco2e: number | null;
    scope312OverrideTco2e: number | null;
    scope313OverrideTco2e: number | null;
    scope314OverrideTco2e: number | null;
    scope315OverrideTco2e: number | null;
    buildingEstimationErr: string | null;
    companyEstimationErr: string | null;
    measuringScope1And2Emissions: boolean | null;
    measuringMaterialScope3Emissions: boolean | null;
    initiatedDecarbonizationPlan: boolean | null;
    committedToNearTermSbtAlignedNetZero: boolean | null;
    demonstratingYoyEmissionsInLineWithNetZero: boolean | null;
    iciAlignmentOverride: GQIciAlignmentStatus | null;
    scenario: string | null;
    tagData: any | null;
    asset:
      | { __typename?: 'AssetCorporate'; id: string; name: string }
      | { __typename?: 'AssetGroup'; id: string; name: string }
      | {
          __typename?: 'AssetPersonalMotorVehicleInsurance';
          id: string;
          name: string;
        }
      | { __typename?: 'AssetRealEstate'; id: string; name: string }
      | { __typename?: 'AssetSovereignBond'; id: string; name: string }
      | null;
  }>;
};

export type GQFinanceAssetHoldingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQFinanceAssetHoldingsQuery = {
  __typename?: 'Query';
  assetHoldings: Array<{
    __typename: 'FinanceAssetHolding';
    id: string;
    year: number | null;
    orgId: string;
    createdAt: Date;
    updatedAt: Date;
    assetYearId: string;
    fundId: string;
    yearOfInitialInvestment: number | null;
    outstandingAmountNative: number | null;
    outstandingAmountNativeQ1: number | null;
    outstandingAmountNativeQ2: number | null;
    outstandingAmountNativeQ3: number | null;
    outstandingAmountNativeQ4: number | null;
    currencyCode: GQCurrencyCode;
    currencyConversionRate: number;
    assetClass: GQFundAssetClass;
    tagData: any | null;
    premiumNative: number | null;
    emissionsYearId: string | null;
    asset:
      | { __typename?: 'AssetCorporate'; id: string; name: string }
      | { __typename?: 'AssetGroup'; id: string; name: string }
      | {
          __typename?: 'AssetPersonalMotorVehicleInsurance';
          id: string;
          name: string;
        }
      | { __typename?: 'AssetRealEstate'; id: string; name: string }
      | { __typename?: 'AssetSovereignBond'; id: string; name: string }
      | null;
    fund: { __typename?: 'Fund'; id: string; name: string } | null;
  }>;
};

export type GQFinancePersonalMotorVehicleInsuranceAssetHoldingsQueryVariables =
  Exact<{ [key: string]: never }>;

export type GQFinancePersonalMotorVehicleInsuranceAssetHoldingsQuery = {
  __typename?: 'Query';
  personalMotorVehicleInsuranceAssetHoldings: Array<{
    __typename: 'FinanceAssetHolding';
    id: string;
    year: number | null;
    orgId: string;
    createdAt: Date;
    updatedAt: Date;
    assetYearId: string;
    fundId: string;
    yearOfInitialInvestment: number | null;
    outstandingAmountNative: number | null;
    outstandingAmountNativeQ1: number | null;
    outstandingAmountNativeQ2: number | null;
    outstandingAmountNativeQ3: number | null;
    outstandingAmountNativeQ4: number | null;
    currencyCode: GQCurrencyCode;
    currencyConversionRate: number;
    assetClass: GQFundAssetClass;
    tagData: any | null;
    premiumNative: number | null;
    emissionsYearId: string | null;
    asset:
      | { __typename?: 'AssetCorporate'; id: string; name: string }
      | { __typename?: 'AssetGroup'; id: string; name: string }
      | {
          __typename?: 'AssetPersonalMotorVehicleInsurance';
          id: string;
          name: string;
        }
      | { __typename?: 'AssetRealEstate'; id: string; name: string }
      | { __typename?: 'AssetSovereignBond'; id: string; name: string }
      | null;
    fund: { __typename?: 'Fund'; id: string; name: string } | null;
  }>;
};

export type GQFinanceContactsQueryVariables = Exact<{ [key: string]: never }>;

export type GQFinanceContactsQuery = {
  __typename?: 'Query';
  contacts: Array<{
    __typename?: 'SupplierContact';
    id: string;
    name: string;
    email: string;
    role: string | null;
    companyId: string;
    userId: string | null;
    company: {
      __typename?: 'Company';
      id: string;
      names: Array<{ __typename?: 'CompanyName'; id: string; name: string }>;
    };
  }>;
};

export type GQCancelWorkflowDashboardMutationVariables = Exact<{
  input: GQCancelWorkflowInput;
}>;

export type GQCancelWorkflowDashboardMutation = {
  __typename?: 'Mutation';
  cancelWorkflow: {
    __typename?: 'CancelWorkflowPayload';
    success: boolean;
  } | null;
};

export type GQGetAverageCurrencyExchangeRatesQueryVariables = Exact<{
  baseCurrency: Scalars['String']['input'];
  targetCurrency: Scalars['String']['input'];
  intervals:
    | Array<Scalars['YMInterval']['input']>
    | Scalars['YMInterval']['input'];
}>;

export type GQGetAverageCurrencyExchangeRatesQuery = {
  __typename?: 'Query';
  averageCurrencyExchangeRates: Array<{
    __typename?: 'CurrencyExchangeRate';
    id: string;
    value: number;
  }>;
};

export type GQGetDrilldownFootprintVersionInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetDrilldownFootprintVersionInfoQuery = {
  __typename?: 'Query';
  footprintChangelog: {
    __typename?: 'FootprintChangelog';
    id: string;
    versions: {
      __typename?: 'FootprintVersionConnection';
      edges: Array<{
        __typename?: 'FootprintVersionEdge';
        node: {
          __typename?: 'FootprintVersion';
          id: string;
          createdAt: Date;
          kind: GQFootprintVersionKind;
          originalFootprintSnapshotId: string;
          userVisibleFootprintSnapshotId: string;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQFootprintVersionInfoForStatusFragment = {
  __typename?: 'FootprintVersion';
  id: string;
  createdAt: Date;
  kind: GQFootprintVersionKind;
  originalFootprintSnapshotId: string;
  userVisibleFootprintSnapshotId: string;
};

export type GQGetFootprintVersionForSnapshotQueryVariables = Exact<{
  footprintSnapshotId: Scalars['String']['input'];
}>;

export type GQGetFootprintVersionForSnapshotQuery = {
  __typename?: 'Query';
  footprintAnalysis: {
    __typename?: 'FootprintAnalysis';
    id: string | null;
    footprintVersion: {
      __typename?: 'FootprintVersion';
      id: string;
      createdAt: Date;
      kind: GQFootprintVersionKind;
      originalFootprintSnapshotId: string;
      userVisibleFootprintSnapshotId: string;
    } | null;
  } | null;
};

export type GQGetLatestCurrencyExchangeRateQueryVariables = Exact<{
  targetCurrency: Scalars['String']['input'];
}>;

export type GQGetLatestCurrencyExchangeRateQuery = {
  __typename?: 'Query';
  latestCurrencyExchangeRate: {
    __typename?: 'CurrencyExchangeRate';
    id: string;
    updatedAt: Date;
    currency: string;
    value: number;
  } | null;
};

export type GQImportMarkUserUploadCompletedMutationVariables = Exact<{
  input: GQMarkUserUploadCompletedInput;
}>;

export type GQImportMarkUserUploadCompletedMutation = {
  __typename?: 'Mutation';
  markUserUploadCompleted: {
    __typename?: 'MarkUserUploadCompletedPayload';
    parseJobId: string | null;
    file: { __typename?: 'FileMetadata'; id: string; remoteWritten: boolean };
    userUpload: {
      __typename?: 'UserUpload';
      id: string;
      name: string;
      status: GQUserUploadStatus;
    } | null;
  } | null;
};

export type GQReportQuestionForHistoryNodeFieldsFragment = {
  __typename: 'ReportQuestion';
  staticInputJson: any | null;
  resolvedOutputJson: any | null;
  id: string;
  identifier: string;
  identifierId: string;
  componentId: string;
  isComputed: boolean;
  guidanceJson: any | null;
  resolvedInputs:
    | {
        __typename: 'ResolvedReportQuestionInputsFailure';
        errorMessage: string | null;
        questionIds: Array<string>;
      }
    | {
        __typename: 'ResolvedReportQuestionInputsSuccess';
        inputs: any;
        unparsedInputs: any;
      };
  resolvedStatus: {
    __typename?: 'ResolvedReportQuestionStatus';
    id: string;
    status: GQReportQuestionStatus;
    blockingMessage: string | null;
    excludingQuestionId: string | null;
  };
  history: {
    __typename?: 'ReportAnswerHistoryConnection';
    edges: Array<{
      __typename?: 'ReportAnswerHistoryEdge';
      node: {
        __typename?: 'ReportAnswer';
        id: string;
        outputJson: any | null;
        createdAt: Date;
        editorName: string;
      } | null;
    } | null>;
  };
  cdpSyncHistory: {
    __typename?: 'CdpSyncQuestionHistoryConnection';
    edges: Array<{
      __typename?: 'CdpSyncQuestionHistoryEdge';
      node: {
        __typename?: 'CdpSyncQuestionStatus';
        id: string;
        createdAt: Date;
        actorName: string;
        isSuccess: boolean | null;
        failureKind: string | null;
        failureDetails: any | null;
      } | null;
    } | null>;
  };
};

export type GQReportAnswerForHistoryNodeFieldsFragment = {
  __typename?: 'ReportAnswer';
  id: string;
  outputJson: any | null;
  createdAt: Date;
  editorName: string;
};

export type GQCdpSyncQuestionStatusForHistoryNodeFieldsFragment = {
  __typename?: 'CdpSyncQuestionStatus';
  id: string;
  createdAt: Date;
  actorName: string;
  isSuccess: boolean | null;
  failureKind: string | null;
  failureDetails: any | null;
};

export type GQGetContainerAndQuestionsForHistoryQueryVariables = Exact<{
  containerId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
}>;

export type GQGetContainerAndQuestionsForHistoryQuery = {
  __typename?: 'Query';
  reportQuestionContainer: {
    __typename?: 'ReportQuestionContainer';
    id: string;
    items: Array<
      | {
          __typename: 'ReportQuestion';
          staticInputJson: any | null;
          resolvedOutputJson: any | null;
          id: string;
          identifier: string;
          identifierId: string;
          componentId: string;
          isComputed: boolean;
          guidanceJson: any | null;
          resolvedInputs:
            | {
                __typename: 'ResolvedReportQuestionInputsFailure';
                errorMessage: string | null;
                questionIds: Array<string>;
              }
            | {
                __typename: 'ResolvedReportQuestionInputsSuccess';
                inputs: any;
                unparsedInputs: any;
              };
          resolvedStatus: {
            __typename?: 'ResolvedReportQuestionStatus';
            id: string;
            status: GQReportQuestionStatus;
            blockingMessage: string | null;
            excludingQuestionId: string | null;
          };
          history: {
            __typename?: 'ReportAnswerHistoryConnection';
            edges: Array<{
              __typename?: 'ReportAnswerHistoryEdge';
              node: {
                __typename?: 'ReportAnswer';
                id: string;
                outputJson: any | null;
                createdAt: Date;
                editorName: string;
              } | null;
            } | null>;
          };
          cdpSyncHistory: {
            __typename?: 'CdpSyncQuestionHistoryConnection';
            edges: Array<{
              __typename?: 'CdpSyncQuestionHistoryEdge';
              node: {
                __typename?: 'CdpSyncQuestionStatus';
                id: string;
                createdAt: Date;
                actorName: string;
                isSuccess: boolean | null;
                failureKind: string | null;
                failureDetails: any | null;
              } | null;
            } | null>;
          };
        }
      | { __typename?: 'ReportQuestionContainer' }
    >;
  };
};

export type GQCompanyAutocompleteResultFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
  countryAlpha2: string | null;
  naicsCode: string | null;
};

export type GQCompanyAutocompleteResultWithSourceFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
  countryAlpha2: string | null;
  naicsCode: string | null;
  source: string;
};

export type GQTypeaheadCompanyQueryVariables = Exact<{
  search: Scalars['String']['input'];
  allowMatchById: Scalars['Boolean']['input'];
  searchDemo: Scalars['Boolean']['input'];
}>;

export type GQTypeaheadCompanyQuery = {
  __typename?: 'Query';
  companyFuzzyMatch: Array<{
    __typename?: 'Company';
    id: string;
    name: string;
    countryAlpha2: string | null;
    naicsCode: string | null;
    source: string;
  }>;
};

export type GQGetCompanyQueryVariables = Exact<{
  id: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQGetCompanyQuery = {
  __typename?: 'Query';
  companyForDashboard: {
    __typename?: 'Company';
    id: string;
    name: string;
    countryAlpha2: string | null;
    naicsCode: string | null;
    source: string;
  } | null;
};

export type GQRespondToDataIssueMutationVariables = Exact<{
  input: GQRespondToDataIssueInput;
}>;

export type GQRespondToDataIssueMutation = {
  __typename?: 'Mutation';
  respondToDataIssue: {
    __typename?: 'RespondToDataIssuePayload';
    measurementTask:
      | {
          __typename?: 'MeasurementParentTask';
          taskGroup: string;
          id: string;
          title: string;
          link: string | null;
          lockState: GQApprovalTargetLockState | null;
          isAssignedToWatershed: boolean;
          statusSimplified: GQTaskWatershedProcessStateSimplified;
          subTasks: Array<
            | {
                __typename?: 'MeasurementParentTask';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
            | {
                __typename?: 'MeasurementTaskFlat';
                taskGroup: string;
                id: string;
                title: string;
                link: string | null;
                lockState: GQApprovalTargetLockState | null;
                isAssignedToWatershed: boolean;
                statusSimplified: GQTaskWatershedProcessStateSimplified;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                } | null;
                assignees: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  didLatestEmailBounce: boolean;
                }>;
                approvers: Array<{
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                }>;
                object:
                  | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
                  | {
                      __typename: 'DataIssue';
                      id: string;
                      linkedObject:
                        | {
                            __typename: 'FinancialsReviewItem';
                            id: string;
                            group: {
                              __typename: 'FinancialsReviewGroup';
                              id: string;
                              datasources: Array<{
                                __typename?: 'Datasource';
                                id: string;
                                name: string;
                                dataset: {
                                  __typename?: 'Dataset';
                                  id: string;
                                  name: string;
                                  materialCsrdStandards: Array<GQCsrdStandard>;
                                };
                              }>;
                            };
                          }
                        | {
                            __typename: 'MeasurementVerificationItemQuestion';
                            id: string;
                            item: {
                              __typename: 'MeasurementVerificationItem';
                              id: string;
                              maybeDataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                                datasources: Array<{
                                  __typename?: 'Datasource';
                                  id: string;
                                  name: string;
                                  dataset: {
                                    __typename?: 'Dataset';
                                    id: string;
                                    name: string;
                                    materialCsrdStandards: Array<GQCsrdStandard>;
                                  };
                                }>;
                              } | null;
                            };
                          }
                        | {
                            __typename: 'UserUploadTask';
                            id: string;
                            datasource: {
                              __typename?: 'Datasource';
                              id: string;
                              name: string;
                              dataset: {
                                __typename?: 'Dataset';
                                id: string;
                                name: string;
                                materialCsrdStandards: Array<GQCsrdStandard>;
                              };
                            };
                          }
                        | { __typename: 'ValueMappingTask' }
                        | null;
                      comments: {
                        __typename?: 'CommentConnection';
                        edges: Array<{
                          __typename?: 'CommentEdge';
                          node: {
                            __typename?: 'Comment';
                            id: string;
                            createdAt: Date;
                            isPublished: boolean;
                            person:
                              | {
                                  __typename?: 'User';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | {
                                  __typename?: 'WatershedEmployee';
                                  id: string;
                                  isWatershedEmployee: boolean;
                                  isWatershedContractor: boolean;
                                }
                              | null;
                          } | null;
                        } | null>;
                      };
                    }
                  | { __typename: 'FacilitiesSetup'; id: string }
                  | {
                      __typename: 'FinancialsReviewGroup';
                      id: string;
                      datasources: Array<{
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      }>;
                    }
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | { __typename: 'FootprintQuestion'; id: string }
                  | {
                      __typename: 'MeasurementDataMappingTask';
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | { __typename: 'MeasurementProject'; id: string }
                  | {
                      __typename: 'MeasurementProjectDataset';
                      modelId: string | null;
                      isCompleted: boolean;
                      id: string;
                      dataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItem';
                      id: string;
                      mviStatus: GQMeasurementVerificationItemStatus;
                      maybeDataset: {
                        __typename?: 'Dataset';
                        id: string;
                        name: string;
                        materialCsrdStandards: Array<GQCsrdStandard>;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                        }>;
                      } | null;
                    }
                  | {
                      __typename: 'UserUploadTask';
                      state: GQUserUploadTaskState;
                      id: string;
                      approvers: Array<{
                        __typename?: 'UserWithApprovalStatus';
                        approvalStatus: GQApprovalStatus | null;
                        user: {
                          __typename?: 'User';
                          id: string;
                          name: string;
                          displayName: string;
                        };
                      }>;
                      datasource: {
                        __typename?: 'Datasource';
                        hasIntegration: boolean;
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          canonicalDataset: {
                            __typename?: 'CanonicalDataset';
                            id: string;
                            kind: GQCanonicalDatasetKind;
                          } | null;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask'; id: string }
                  | { __typename: 'VendorMatchingTask'; id: string };
              }
          >;
          dataset: {
            __typename?: 'Dataset';
            id: string;
            name: string;
            materialCsrdStandards: Array<GQCsrdStandard>;
          } | null;
          assignees: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
            didLatestEmailBounce: boolean;
          }>;
          approvers: Array<{
            __typename?: 'User';
            id: string;
            name: string;
            displayName: string;
          }>;
          object:
            | { __typename: 'CustomEmissionsFactorsSetup'; id: string }
            | {
                __typename: 'DataIssue';
                id: string;
                linkedObject:
                  | {
                      __typename: 'FinancialsReviewItem';
                      id: string;
                      group: {
                        __typename: 'FinancialsReviewGroup';
                        id: string;
                        datasources: Array<{
                          __typename?: 'Datasource';
                          id: string;
                          name: string;
                          dataset: {
                            __typename?: 'Dataset';
                            id: string;
                            name: string;
                            materialCsrdStandards: Array<GQCsrdStandard>;
                          };
                        }>;
                      };
                    }
                  | {
                      __typename: 'MeasurementVerificationItemQuestion';
                      id: string;
                      item: {
                        __typename: 'MeasurementVerificationItem';
                        id: string;
                        maybeDataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                          datasources: Array<{
                            __typename?: 'Datasource';
                            id: string;
                            name: string;
                            dataset: {
                              __typename?: 'Dataset';
                              id: string;
                              name: string;
                              materialCsrdStandards: Array<GQCsrdStandard>;
                            };
                          }>;
                        } | null;
                      };
                    }
                  | {
                      __typename: 'UserUploadTask';
                      id: string;
                      datasource: {
                        __typename?: 'Datasource';
                        id: string;
                        name: string;
                        dataset: {
                          __typename?: 'Dataset';
                          id: string;
                          name: string;
                          materialCsrdStandards: Array<GQCsrdStandard>;
                        };
                      };
                    }
                  | { __typename: 'ValueMappingTask' }
                  | null;
                comments: {
                  __typename?: 'CommentConnection';
                  edges: Array<{
                    __typename?: 'CommentEdge';
                    node: {
                      __typename?: 'Comment';
                      id: string;
                      createdAt: Date;
                      isPublished: boolean;
                      person:
                        | {
                            __typename?: 'User';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | {
                            __typename?: 'WatershedEmployee';
                            id: string;
                            isWatershedEmployee: boolean;
                            isWatershedContractor: boolean;
                          }
                        | null;
                    } | null;
                  } | null>;
                };
              }
            | { __typename: 'FacilitiesSetup'; id: string }
            | {
                __typename: 'FinancialsReviewGroup';
                id: string;
                datasources: Array<{
                  __typename?: 'Datasource';
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                  };
                }>;
              }
            | {
                __typename: 'FinancialsReviewItem';
                id: string;
                group: {
                  __typename: 'FinancialsReviewGroup';
                  id: string;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                    dataset: {
                      __typename?: 'Dataset';
                      id: string;
                      name: string;
                      materialCsrdStandards: Array<GQCsrdStandard>;
                    };
                  }>;
                };
              }
            | { __typename: 'FootprintQuestion'; id: string }
            | {
                __typename: 'MeasurementDataMappingTask';
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | { __typename: 'MeasurementProject'; id: string }
            | {
                __typename: 'MeasurementProjectDataset';
                modelId: string | null;
                isCompleted: boolean;
                id: string;
                dataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                };
              }
            | {
                __typename: 'MeasurementVerificationItem';
                id: string;
                mviStatus: GQMeasurementVerificationItemStatus;
                maybeDataset: {
                  __typename?: 'Dataset';
                  id: string;
                  name: string;
                  materialCsrdStandards: Array<GQCsrdStandard>;
                  datasources: Array<{
                    __typename?: 'Datasource';
                    id: string;
                    name: string;
                  }>;
                } | null;
              }
            | {
                __typename: 'UserUploadTask';
                state: GQUserUploadTaskState;
                id: string;
                approvers: Array<{
                  __typename?: 'UserWithApprovalStatus';
                  approvalStatus: GQApprovalStatus | null;
                  user: {
                    __typename?: 'User';
                    id: string;
                    name: string;
                    displayName: string;
                  };
                }>;
                datasource: {
                  __typename?: 'Datasource';
                  hasIntegration: boolean;
                  id: string;
                  name: string;
                  dataset: {
                    __typename?: 'Dataset';
                    id: string;
                    name: string;
                    materialCsrdStandards: Array<GQCsrdStandard>;
                    canonicalDataset: {
                      __typename?: 'CanonicalDataset';
                      id: string;
                      kind: GQCanonicalDatasetKind;
                    } | null;
                  };
                };
              }
            | { __typename: 'ValueMappingTask'; id: string }
            | { __typename: 'VendorMatchingTask'; id: string };
        }
      | { __typename?: 'MeasurementTaskFlat' };
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      state: GQUserUploadTaskState;
    } | null;
    issue: {
      __typename?: 'DataIssue';
      id: string;
      title: string;
      description: string | null;
      state: GQDataIssueState;
      createdAt: Date;
      isCustomerInitiated: boolean;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        completedAt: Date | null;
      };
      comments: {
        __typename?: 'CommentConnection';
        edges: Array<{
          __typename?: 'CommentEdge';
          node: {
            __typename?: 'Comment';
            id: string;
            message: string;
            isPublished: boolean;
            createdAt: Date;
            person:
              | {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | {
                  __typename?: 'WatershedEmployee';
                  id: string;
                  name: string;
                  displayName: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | null;
          } | null;
        } | null>;
      };
    };
  } | null;
};

export type GQResolveDataIssueMutationVariables = Exact<{
  input: GQResolveDataIssueInput;
}>;

export type GQResolveDataIssueMutation = {
  __typename?: 'Mutation';
  resolveDataIssue: {
    __typename?: 'ResolveDataIssuePayload';
    issue: {
      __typename?: 'DataIssue';
      id: string;
      title: string;
      description: string | null;
      state: GQDataIssueState;
      createdAt: Date;
      isCustomerInitiated: boolean;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        completedAt: Date | null;
      };
      comments: {
        __typename?: 'CommentConnection';
        edges: Array<{
          __typename?: 'CommentEdge';
          node: {
            __typename?: 'Comment';
            id: string;
            message: string;
            isPublished: boolean;
            createdAt: Date;
            person:
              | {
                  __typename?: 'User';
                  id: string;
                  name: string;
                  displayName: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | {
                  __typename?: 'WatershedEmployee';
                  id: string;
                  name: string;
                  displayName: string;
                  isWatershedEmployee: boolean;
                  isWatershedContractor: boolean;
                }
              | null;
          } | null;
        } | null>;
      };
    };
  } | null;
};

export type GQGiveFeedbackMutationVariables = Exact<{
  input: GQGiveFeedbackInput;
}>;

export type GQGiveFeedbackMutation = {
  __typename?: 'Mutation';
  giveFeedback: { __typename?: 'RequestHelpPayload'; status: string };
};

export type GQActivityTypeSchemaFragment = {
  __typename?: 'ActivityTypeSchema';
  id: string;
  activityType: string;
  schema: { __typename?: 'CanonicalSchema'; id: string; jsonSchema: any };
};

export type GQCtsTransformRunFieldsFragment = {
  __typename?: 'CustomerTargetSchemaTransformRun';
  id: string;
  sql: string;
  error: string | null;
  createdAt: Date;
  transform: {
    __typename?: 'CustomerTargetSchemaTransform';
    id: string;
    transformTarget: GQCustomerTargetSchemaTransformTarget;
    businessActivityTypeName: string | null;
  };
  input: {
    __typename?: 'FileMetadata';
    id: string;
    name: string;
    category: GQFileCategory;
    revisionRootFileMetadata: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
    } | null;
  };
  output: {
    __typename?: 'ParquetData';
    id: string;
    numRecords: number;
    ndjsonSignedUrl: string | null;
    ndjsonSize: number | null;
    ndjsonByteBoundaries: Array<number> | null;
    columns: Array<{
      __typename?: 'ParquetColumn';
      name: string;
      sqlType: string;
      minValue: any;
      maxValue: any;
      numNulls: number;
      approxDistinctValues: number;
      sampleValues: Array<any>;
    }>;
  } | null;
  standardizedOutput: {
    __typename?: 'ParquetData';
    id: string;
    numRecords: number;
    ndjsonSignedUrl: string | null;
    ndjsonSize: number | null;
    ndjsonByteBoundaries: Array<number> | null;
    columns: Array<{
      __typename?: 'ParquetColumn';
      name: string;
      sqlType: string;
      minValue: any;
      maxValue: any;
      numNulls: number;
      approxDistinctValues: number;
      sampleValues: Array<any>;
    }>;
  } | null;
  warnings: Array<{
    __typename?: 'ValidationWarning';
    message: string;
    blocking: boolean;
  }>;
  runner: { __typename?: 'User'; id: string; name: string };
};

export type GQCtsTransformForEditorFragment = {
  __typename?: 'CustomerTargetSchemaTransform';
  id: string;
  deletedAt: Date | null;
  sql: string;
  transformTarget: GQCustomerTargetSchemaTransformTarget;
  businessActivityTypeName: string | null;
  businessActivityTypeVersionId: string | null;
  businessActivityTypeVersion: {
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
  } | null;
  runs: Array<{
    __typename?: 'CustomerTargetSchemaTransformRun';
    id: string;
    sql: string;
    error: string | null;
    createdAt: Date;
    transform: {
      __typename?: 'CustomerTargetSchemaTransform';
      id: string;
      transformTarget: GQCustomerTargetSchemaTransformTarget;
      businessActivityTypeName: string | null;
    };
    input: {
      __typename?: 'FileMetadata';
      id: string;
      name: string;
      category: GQFileCategory;
      revisionRootFileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        name: string;
      } | null;
    };
    output: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    standardizedOutput: {
      __typename?: 'ParquetData';
      id: string;
      numRecords: number;
      ndjsonSignedUrl: string | null;
      ndjsonSize: number | null;
      ndjsonByteBoundaries: Array<number> | null;
      columns: Array<{
        __typename?: 'ParquetColumn';
        name: string;
        sqlType: string;
        minValue: any;
        maxValue: any;
        numNulls: number;
        approxDistinctValues: number;
        sampleValues: Array<any>;
      }>;
    } | null;
    warnings: Array<{
      __typename?: 'ValidationWarning';
      message: string;
      blocking: boolean;
    }>;
    runner: { __typename?: 'User'; id: string; name: string };
  }>;
};

export type GQRunCtsTransformEditorMutationVariables = Exact<{
  input: GQRunCtsTransformEditorDashboardInput;
}>;

export type GQRunCtsTransformEditorMutation = {
  __typename?: 'Mutation';
  runCtsTransformEditor: {
    __typename?: 'RunCtsTransformEditorPayload';
    workflowId: string;
  };
};

export type GQCreateDemoOrgMutationVariables = Exact<{
  input: GQCreateDemoOrgInput;
}>;

export type GQCreateDemoOrgMutation = {
  __typename?: 'Mutation';
  createDemoOrg: {
    __typename?: 'CreateDemoOrgPayload';
    backgroundJobId: string;
  } | null;
};

type GQBuildingPeriodInfo_BuildingPeriod_Fragment = {
  __typename?: 'BuildingPeriod';
  kind: GQBuildingPeriodKind;
  sizeQuantity: number | null;
  startYearMonth: YearMonth | null;
  endYearMonth: YearMonth | null;
  partialCleanPowerPercent: number | null;
  cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
  powerUsageEffectiveness: number | null;
  isBuildingEmpty: boolean | null;
  customFieldValue: string | null;
  customFieldId: string | null;
  orgUnitTypeStableId: string | null;
  orgUnitStableId: string | null;
};

type GQBuildingPeriodInfo_BuildingPeriodForDiff_Fragment = {
  __typename?: 'BuildingPeriodForDiff';
  kind: GQBuildingPeriodKind;
  sizeQuantity: number | null;
  startYearMonth: YearMonth | null;
  endYearMonth: YearMonth | null;
  partialCleanPowerPercent: number | null;
  cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
  powerUsageEffectiveness: number | null;
  isBuildingEmpty: boolean | null;
  customFieldValue: string | null;
  customFieldId: string | null;
  orgUnitTypeStableId: string | null;
  orgUnitStableId: string | null;
};

export type GQBuildingPeriodInfoFragment =
  | GQBuildingPeriodInfo_BuildingPeriod_Fragment
  | GQBuildingPeriodInfo_BuildingPeriodForDiff_Fragment;

type GQBuildingInfo_BuildingForDiff_Fragment = {
  __typename?: 'BuildingForDiff';
  streetAddress: string | null;
  streetAddress2: string | null;
  postalCode: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  startYearMonth: YearMonth | null;
  endYearMonth: YearMonth | null;
  sizeUnit: GQBuildingSizeUnit | null;
  buildingKind: string | null;
  buildingSubkind: string | null;
  leaseType: GQBuildingLeaseType | null;
  buildingName: string;
  buildingUniqueId: string;
  isWithoutNaturalGas: boolean | null;
  isWithoutRefrigerants: boolean | null;
  periods: Array<{
    __typename?: 'BuildingPeriodForDiff';
    kind: GQBuildingPeriodKind;
    sizeQuantity: number | null;
    startYearMonth: YearMonth | null;
    endYearMonth: YearMonth | null;
    partialCleanPowerPercent: number | null;
    cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
    powerUsageEffectiveness: number | null;
    isBuildingEmpty: boolean | null;
    customFieldValue: string | null;
    customFieldId: string | null;
    orgUnitTypeStableId: string | null;
    orgUnitStableId: string | null;
  }>;
};

type GQBuildingInfo_Facility_Fragment = {
  __typename?: 'Facility';
  streetAddress: string | null;
  streetAddress2: string | null;
  postalCode: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  startYearMonth: YearMonth | null;
  endYearMonth: YearMonth | null;
  sizeUnit: GQBuildingSizeUnit | null;
  buildingKind: string | null;
  buildingSubkind: string | null;
  leaseType: GQBuildingLeaseType | null;
  buildingName: string;
  buildingUniqueId: string;
  isWithoutNaturalGas: boolean | null;
  isWithoutRefrigerants: boolean | null;
  periods: Array<{
    __typename?: 'BuildingPeriod';
    kind: GQBuildingPeriodKind;
    sizeQuantity: number | null;
    startYearMonth: YearMonth | null;
    endYearMonth: YearMonth | null;
    partialCleanPowerPercent: number | null;
    cleanPowerContractualInstrument: GQCleanPowerContractualInstrument | null;
    powerUsageEffectiveness: number | null;
    isBuildingEmpty: boolean | null;
    customFieldValue: string | null;
    customFieldId: string | null;
    orgUnitTypeStableId: string | null;
    orgUnitStableId: string | null;
  }>;
};

export type GQBuildingInfoFragment =
  | GQBuildingInfo_BuildingForDiff_Fragment
  | GQBuildingInfo_Facility_Fragment;

export type GQReviewDocumentParseAttemptFragment = {
  __typename?: 'DocumentParseAttempt';
  id: string;
  userUploadId: string;
  modelType: GQDocumentParseAttemptModelType;
  createdAt: Date;
  updatedAt: Date;
  utilityUsageData: {
    __typename?: 'UtilityUsageData';
    energyConsumptionAmount: string | null;
    energyConsumptionUnit: string | null;
    receiverAddress: string | null;
    serviceAddress: string | null;
    serviceStartDate: string | null;
    serviceEndDate: string | null;
  } | null;
};

export type GQLatestDocumentParseAttemptForReviewQueryVariables = Exact<{
  fileId: Scalars['ID']['input'];
  modelType: GQDocumentParseAttemptModelType;
}>;

export type GQLatestDocumentParseAttemptForReviewQuery = {
  __typename?: 'Query';
  latestDocumentParseAttempt: {
    __typename?: 'DocumentParseAttempt';
    id: string;
    userUploadId: string;
    modelType: GQDocumentParseAttemptModelType;
    createdAt: Date;
    updatedAt: Date;
    utilityUsageData: {
      __typename?: 'UtilityUsageData';
      energyConsumptionAmount: string | null;
      energyConsumptionUnit: string | null;
      receiverAddress: string | null;
      serviceAddress: string | null;
      serviceStartDate: string | null;
      serviceEndDate: string | null;
    } | null;
  };
};

export type GQReviewUserUploadPreviewUrlQueryVariables = Exact<{
  userUploadId: Scalars['ID']['input'];
}>;

export type GQReviewUserUploadPreviewUrlQuery = {
  __typename?: 'Query';
  userUpload: {
    __typename?: 'UserUpload';
    userVisibleAttempt: {
      __typename?: 'UserUploadAttempt';
      previewUrl: string;
    } | null;
  };
};

export type GQUpsertDocumentParseAttemptReviewMutationVariables = Exact<{
  input: GQUpsertDocumentParseAttemptInput;
}>;

export type GQUpsertDocumentParseAttemptReviewMutation = {
  __typename?: 'Mutation';
  upsertDocumentParseAttempt: {
    __typename?: 'DocumentParseAttempt';
    id: string;
    userUploadId: string;
    modelType: GQDocumentParseAttemptModelType;
    createdAt: Date;
    updatedAt: Date;
    utilityUsageData: {
      __typename?: 'UtilityUsageData';
      energyConsumptionAmount: string | null;
      energyConsumptionUnit: string | null;
      receiverAddress: string | null;
      serviceAddress: string | null;
      serviceStartDate: string | null;
      serviceEndDate: string | null;
    } | null;
  };
};

export type GQFundAllFieldsFragment = {
  __typename: 'Fund';
  id: string;
  name: string;
  nameSortable: string | null;
  fundGroup: string | null;
  fundCategory: string | null;
  excludeAsSandbox: boolean | null;
  anonymousFundUuid: string | null;
  createdAt: Date;
  orgId: string;
  updatedAt: Date;
  tagData: any | null;
  externalId: string | null;
};

export type GQMeasurementVerificationItemForQuestionContentFragment = {
  __typename?: 'MeasurementVerificationItem';
  id: string;
  datasetName: string;
  dataset: {
    __typename?: 'Dataset';
    id: string;
    datasources: Array<{
      __typename?: 'Datasource';
      id: string;
      name: string;
      summaryWithMetadata: {
        __typename?: 'DatasourceSummaryWithMetadata';
        id: string;
        summaries: Array<{
          __typename?: 'DatasourceSummary';
          id: string;
          signedUrl: string;
        }>;
      };
    }>;
  } | null;
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    coverageInterval: YMInterval;
  };
};

export type GQFileForMeasurementVerificationQuestionContentFragment = {
  __typename?: 'FileMetadata';
  id: string;
  name: string;
  uploader:
    | { __typename?: 'User'; id: string; name: string }
    | { __typename?: 'WatershedEmployee'; id: string; name: string }
    | null;
  userUploadTask: {
    __typename?: 'UserUploadTask';
    id: string;
    datasource: { __typename?: 'Datasource'; id: string; name: string };
  } | null;
};

export type GQFrozenDatasourceSummaryContentFragment = {
  __typename?: 'FrozenDatasourceSummary';
  isStale: boolean;
  signedUrl: string;
  datasource: { __typename?: 'Datasource'; id: string; name: string };
};

export type GQMeasurementVerificationItemQuestionForQuestionContentFragment = {
  __typename?: 'MeasurementVerificationItemQuestion';
  id: string;
  contentKind: GQMeasurementVerificationQuestionContentKind;
  contentTitle: string | null;
  contentSubtitle: string | null;
  questionKind: GQMeasurementVerificationQuestionKind;
  questionPrompt: string;
  answerText: string | null;
  status: GQMeasurementVerificationQuestionStatus;
  rank: number | null;
  discussion: {
    __typename?: 'DataIssue';
    id: string;
    title: string;
    description: string | null;
    state: GQDataIssueState;
    createdAt: Date;
    isCustomerInitiated: boolean;
    measurementProject: {
      __typename?: 'MeasurementProject';
      id: string;
      completedAt: Date | null;
    };
    comments: {
      __typename?: 'CommentConnection';
      edges: Array<{
        __typename?: 'CommentEdge';
        node: {
          __typename?: 'Comment';
          id: string;
          message: string;
          isPublished: boolean;
          createdAt: Date;
          person:
            | {
                __typename?: 'User';
                id: string;
                name: string;
                displayName: string;
                isWatershedEmployee: boolean;
                isWatershedContractor: boolean;
              }
            | {
                __typename?: 'WatershedEmployee';
                id: string;
                name: string;
                displayName: string;
                isWatershedEmployee: boolean;
                isWatershedContractor: boolean;
              }
            | null;
        } | null;
      } | null>;
    };
  } | null;
  publisher: {
    __typename?: 'WatershedEmployee';
    id: string;
    name: string;
  } | null;
  contentMetadata:
    | {
        __typename: 'MeasurementVerificationItemContentMetadataFileOverlaps';
        id: string;
        files: Array<{
          __typename?: 'FileMetadata';
          id: string;
          name: string;
          uploader:
            | { __typename?: 'User'; id: string; name: string }
            | { __typename?: 'WatershedEmployee'; id: string; name: string }
            | null;
          userUploadTask: {
            __typename?: 'UserUploadTask';
            id: string;
            datasource: { __typename?: 'Datasource'; id: string; name: string };
          } | null;
        }>;
      }
    | {
        __typename: 'MeasurementVerificationItemContentMetadataQuantityTrend';
        id: string;
        aggregateField: string | null;
        filters: Array<{
          __typename?: 'DatasetSummaryFilter';
          field: string;
          operator: GQFilterOperator;
          value: Array<string>;
        }> | null;
        anomaly: {
          __typename?: 'QuantityTrendAnomaly';
          highlightMonth: YearMonth;
        } | null;
        frozenDatasourceSummaries: Array<{
          __typename?: 'FrozenDatasourceSummary';
          isStale: boolean;
          signedUrl: string;
          datasource: { __typename?: 'Datasource'; id: string; name: string };
        }> | null;
      }
    | {
        __typename: 'MeasurementVerificationItemContentMetadataTopAccounts';
        id: string;
        frozenDatasourceSummaries: Array<{
          __typename?: 'FrozenDatasourceSummary';
          isStale: boolean;
          signedUrl: string;
          datasource: { __typename?: 'Datasource'; id: string; name: string };
        }> | null;
      };
};

export type GQCreateOrgRoleMutationVariables = Exact<{
  input: GQCreateOrgRoleInput;
}>;

export type GQCreateOrgRoleMutation = {
  __typename?: 'Mutation';
  createOrgRole: {
    __typename?: 'CreateRolePayload';
    role: {
      __typename?: 'Role';
      id: string;
      name: string;
      description: string | null;
      permissions: Array<{
        __typename?: 'PermissionItem';
        id: string;
        objectId: string | null;
        permission: GQPermissionType;
        revokedAt: Date | null;
        object:
          | { __typename: 'Company'; id: string; name: string }
          | { __typename: 'CompanySurvey'; id: string; name: string }
          | { __typename: 'Dataset'; id: string; name: string }
          | { __typename: 'Datasource'; id: string; name: string }
          | { __typename: 'EngagementTask'; id: string; name: string }
          | { __typename: 'Fund'; id: string; name: string }
          | null;
      }>;
      users: Array<{
        __typename?: 'UserRoleAssignment';
        id: string;
        user: {
          __typename?: 'User';
          id: string;
          displayName: string;
          name: string;
        };
      }>;
      apiKeys: Array<{
        __typename?: 'ApiKeyRoleAssignment';
        id: string;
        apiKey: {
          __typename?: 'ApiKey';
          id: string;
          name: string;
          description: string | null;
        };
      }>;
    };
  } | null;
};

export type GQCreateRoleMutationVariables = Exact<{
  input: GQCreateRoleInput;
}>;

export type GQCreateRoleMutation = {
  __typename?: 'Mutation';
  createRole: {
    __typename?: 'CreateRolePayload';
    role: {
      __typename?: 'Role';
      id: string;
      name: string;
      description: string | null;
      permissions: Array<{
        __typename?: 'PermissionItem';
        id: string;
        objectId: string | null;
        permission: GQPermissionType;
        revokedAt: Date | null;
        object:
          | { __typename: 'Company'; id: string; name: string }
          | { __typename: 'CompanySurvey'; id: string; name: string }
          | { __typename: 'Dataset'; id: string; name: string }
          | { __typename: 'Datasource'; id: string; name: string }
          | { __typename: 'EngagementTask'; id: string; name: string }
          | { __typename: 'Fund'; id: string; name: string }
          | null;
      }>;
      users: Array<{
        __typename?: 'UserRoleAssignment';
        id: string;
        user: {
          __typename?: 'User';
          id: string;
          displayName: string;
          name: string;
        };
      }>;
      apiKeys: Array<{
        __typename?: 'ApiKeyRoleAssignment';
        id: string;
        apiKey: {
          __typename?: 'ApiKey';
          id: string;
          name: string;
          description: string | null;
        };
      }>;
    };
  } | null;
};

export type GQUpdateRoleMutationVariables = Exact<{
  input: GQUpdateRoleInput;
}>;

export type GQUpdateRoleMutation = {
  __typename?: 'Mutation';
  updateRole: {
    __typename?: 'UpdateRolePayload';
    role: {
      __typename?: 'Role';
      id: string;
      name: string;
      description: string | null;
      permissions: Array<{
        __typename?: 'PermissionItem';
        id: string;
        objectId: string | null;
        permission: GQPermissionType;
        revokedAt: Date | null;
        object:
          | { __typename: 'Company'; id: string; name: string }
          | { __typename: 'CompanySurvey'; id: string; name: string }
          | { __typename: 'Dataset'; id: string; name: string }
          | { __typename: 'Datasource'; id: string; name: string }
          | { __typename: 'EngagementTask'; id: string; name: string }
          | { __typename: 'Fund'; id: string; name: string }
          | null;
      }>;
      users: Array<{
        __typename?: 'UserRoleAssignment';
        id: string;
        user: {
          __typename?: 'User';
          id: string;
          displayName: string;
          name: string;
        };
      }>;
      apiKeys: Array<{
        __typename?: 'ApiKeyRoleAssignment';
        id: string;
        apiKey: {
          __typename?: 'ApiKey';
          id: string;
          name: string;
          description: string | null;
        };
      }>;
    };
  } | null;
};

export type GQOrgDatasetsQueryVariables = Exact<{ [key: string]: never }>;

export type GQOrgDatasetsQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    datasets: Array<{ __typename?: 'Dataset'; id: string; name: string }>;
  };
};

export type GQOrgDatasourcesQueryVariables = Exact<{ [key: string]: never }>;

export type GQOrgDatasourcesQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    datasets: Array<{
      __typename?: 'Dataset';
      id: string;
      name: string;
      datasources: Array<{
        __typename?: 'Datasource';
        id: string;
        name: string;
      }>;
    }>;
  };
};

export type GQGetPermissionsForRoleQueryVariables = Exact<{
  roleId: Scalars['ID']['input'];
}>;

export type GQGetPermissionsForRoleQuery = {
  __typename?: 'Query';
  role: {
    __typename?: 'Role';
    id: string;
    name: string;
    description: string | null;
    permissions: Array<{
      __typename?: 'PermissionItem';
      id: string;
      objectId: string | null;
      permission: GQPermissionType;
      revokedAt: Date | null;
      object:
        | { __typename: 'Company'; id: string; name: string }
        | { __typename: 'CompanySurvey'; id: string; name: string }
        | { __typename: 'Dataset'; id: string; name: string }
        | { __typename: 'Datasource'; id: string; name: string }
        | { __typename: 'EngagementTask'; id: string; name: string }
        | { __typename: 'Fund'; id: string; name: string }
        | null;
    }>;
    users: Array<{
      __typename?: 'UserRoleAssignment';
      id: string;
      user: {
        __typename?: 'User';
        id: string;
        displayName: string;
        name: string;
      };
    }>;
    apiKeys: Array<{
      __typename?: 'ApiKeyRoleAssignment';
      id: string;
      apiKey: {
        __typename?: 'ApiKey';
        id: string;
        name: string;
        description: string | null;
      };
    }>;
  } | null;
};

export type GQOrgUsersQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;

export type GQOrgUsersQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          displayName: string;
          name: string;
          orgAccessId: string;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQTeamsTableRoleFragment = {
  __typename?: 'Role';
  id: string;
  name: string;
  description: string | null;
  permissions: Array<{
    __typename?: 'PermissionItem';
    id: string;
    objectId: string | null;
    permission: GQPermissionType;
    revokedAt: Date | null;
    object:
      | { __typename: 'Company'; id: string; name: string }
      | { __typename: 'CompanySurvey'; id: string; name: string }
      | { __typename: 'Dataset'; id: string; name: string }
      | { __typename: 'Datasource'; id: string; name: string }
      | { __typename: 'EngagementTask'; id: string; name: string }
      | { __typename: 'Fund'; id: string; name: string }
      | null;
  }>;
  users: Array<{
    __typename?: 'UserRoleAssignment';
    id: string;
    user: {
      __typename?: 'User';
      id: string;
      displayName: string;
      name: string;
    };
  }>;
  apiKeys: Array<{
    __typename?: 'ApiKeyRoleAssignment';
    id: string;
    apiKey: {
      __typename?: 'ApiKey';
      id: string;
      name: string;
      description: string | null;
    };
  }>;
};

export type GQDeleteRoleMutationVariables = Exact<{
  roleId: Scalars['ID']['input'];
}>;

export type GQDeleteRoleMutation = {
  __typename?: 'Mutation';
  deleteRole: {
    __typename?: 'DeleteRolePayload';
    orgId: string;
    roleId: string;
  } | null;
};

export type GQMyRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GQMyRolesQuery = {
  __typename?: 'Query';
  roles: {
    __typename?: 'RoleConnection';
    edges: Array<{
      __typename?: 'RoleEdge';
      node: {
        __typename?: 'Role';
        id: string;
        name: string;
        description: string | null;
        permissions: Array<{
          __typename?: 'PermissionItem';
          id: string;
          objectId: string | null;
          permission: GQPermissionType;
          revokedAt: Date | null;
          object:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanySurvey'; id: string; name: string }
            | { __typename: 'Dataset'; id: string; name: string }
            | { __typename: 'Datasource'; id: string; name: string }
            | { __typename: 'EngagementTask'; id: string; name: string }
            | { __typename: 'Fund'; id: string; name: string }
            | null;
        }>;
      } | null;
    } | null>;
  };
};

export type GQUserRoleFieldsFragment = {
  __typename?: 'UserRoleAssignment';
  id: string;
  revokedAt: Date | null;
  role: {
    __typename?: 'Role';
    id: string;
    name: string;
    description: string | null;
    permissions: Array<{
      __typename?: 'PermissionItem';
      id: string;
      objectId: string | null;
      permission: GQPermissionType;
      revokedAt: Date | null;
      object:
        | { __typename: 'Company'; id: string; name: string }
        | { __typename: 'CompanySurvey'; id: string; name: string }
        | { __typename: 'Dataset'; id: string; name: string }
        | { __typename: 'Datasource'; id: string; name: string }
        | { __typename: 'EngagementTask'; id: string; name: string }
        | { __typename: 'Fund'; id: string; name: string }
        | null;
    }>;
  };
};

export type GQPermissionFieldsFragment = {
  __typename?: 'PermissionItem';
  id: string;
  objectId: string | null;
  permission: GQPermissionType;
  revokedAt: Date | null;
  object:
    | { __typename: 'Company'; id: string; name: string }
    | { __typename: 'CompanySurvey'; id: string; name: string }
    | { __typename: 'Dataset'; id: string; name: string }
    | { __typename: 'Datasource'; id: string; name: string }
    | { __typename: 'EngagementTask'; id: string; name: string }
    | { __typename: 'Fund'; id: string; name: string }
    | null;
};

export type GQRoleInfoFragment = {
  __typename?: 'Role';
  id: string;
  name: string;
  description: string | null;
  permissions: Array<{
    __typename?: 'PermissionItem';
    id: string;
    objectId: string | null;
    permission: GQPermissionType;
    revokedAt: Date | null;
    object:
      | { __typename: 'Company'; id: string; name: string }
      | { __typename: 'CompanySurvey'; id: string; name: string }
      | { __typename: 'Dataset'; id: string; name: string }
      | { __typename: 'Datasource'; id: string; name: string }
      | { __typename: 'EngagementTask'; id: string; name: string }
      | { __typename: 'Fund'; id: string; name: string }
      | null;
  }>;
};

export type GQGetPermissionsForUserQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
}>;

export type GQGetPermissionsForUserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    name: string;
    displayName: string;
    orgAccessId: string;
    userUploadTasks: Array<{
      __typename?: 'UserUploadTask';
      id: string;
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
        active: boolean;
      };
      datasource: {
        __typename?: 'Datasource';
        id: string;
        name: string;
        dataset: { __typename?: 'Dataset'; id: string; name: string };
      };
    }>;
    roles: Array<{
      __typename?: 'UserRoleAssignment';
      id: string;
      revokedAt: Date | null;
      role: {
        __typename?: 'Role';
        id: string;
        name: string;
        description: string | null;
        permissions: Array<{
          __typename?: 'PermissionItem';
          id: string;
          objectId: string | null;
          permission: GQPermissionType;
          revokedAt: Date | null;
          object:
            | { __typename: 'Company'; id: string; name: string }
            | { __typename: 'CompanySurvey'; id: string; name: string }
            | { __typename: 'Dataset'; id: string; name: string }
            | { __typename: 'Datasource'; id: string; name: string }
            | { __typename: 'EngagementTask'; id: string; name: string }
            | { __typename: 'Fund'; id: string; name: string }
            | null;
        }>;
      };
    }>;
    permissions: Array<{
      __typename?: 'PermissionItem';
      id: string;
      objectId: string | null;
      permission: GQPermissionType;
      revokedAt: Date | null;
      object:
        | { __typename: 'Company'; id: string; name: string }
        | { __typename: 'CompanySurvey'; id: string; name: string }
        | { __typename: 'Dataset'; id: string; name: string }
        | { __typename: 'Datasource'; id: string; name: string }
        | { __typename: 'EngagementTask'; id: string; name: string }
        | { __typename: 'Fund'; id: string; name: string }
        | null;
    }>;
  } | null;
};

export type GQRevokePermissionMutationVariables = Exact<{
  permissionId: Scalars['ID']['input'];
}>;

export type GQRevokePermissionMutation = {
  __typename?: 'Mutation';
  revokePermissionItem: {
    __typename?: 'RevokePermissionItemPayload';
    permissionItem: {
      __typename?: 'PermissionItem';
      id: string;
      revokedAt: Date | null;
    };
  } | null;
};

export type GQRevokeRoleForUserMutationVariables = Exact<{
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  orgId: InputMaybe<Scalars['ID']['input']>;
}>;

export type GQRevokeRoleForUserMutation = {
  __typename?: 'Mutation';
  revokeUserRole: {
    __typename?: 'RevokeUserRolePayload';
    userRoleAssignment: {
      __typename?: 'UserRoleAssignment';
      id: string;
      revokedAt: Date | null;
    } | null;
  } | null;
};

export type GQAddPermissionMutationVariables = Exact<{
  input: GQCreatePermissionItemInput;
}>;

export type GQAddPermissionMutation = {
  __typename?: 'Mutation';
  createPermissionItem: {
    __typename?: 'CreatePermissionItemPayload';
    permissionItem: {
      __typename?: 'PermissionItem';
      id: string;
      revokedAt: Date | null;
      user: { __typename?: 'User'; id: string } | null;
    };
  } | null;
};

export type GQAddUserRoleMutationVariables = Exact<{
  input: GQGrantUserRoleInput;
}>;

export type GQAddUserRoleMutation = {
  __typename?: 'Mutation';
  grantUserRole: {
    __typename?: 'GrantUserRolePayload';
    userRoleAssignment: {
      __typename?: 'UserRoleAssignment';
      id: string;
    } | null;
  } | null;
};

export type GQMeasurementProjectFieldsFragment = {
  __typename?: 'MeasurementProject';
  id: string;
  name: string;
  active: boolean;
  status: GQMeasurementProjectStatus;
  createdAt: Date;
  completedAt: Date | null;
  coverageInterval: YMInterval;
  coverageStartDate: Date;
  coverageEndDate: Date;
  deadline: Date | null;
};

export type GQUserUploadTaskFieldsFragment = {
  __typename?: 'UserUploadTask';
  id: string;
  assignee: { __typename?: 'User'; id: string; name: string } | null;
  datasource: {
    __typename?: 'Datasource';
    id: string;
    dataset: { __typename?: 'Dataset'; id: string; name: string };
  };
  measurementProject: {
    __typename?: 'MeasurementProject';
    id: string;
    name: string;
    active: boolean;
    coverageStartDate: Date;
    coverageEndDate: Date;
    completedAt: Date | null;
  };
};

export type GQAssignUserUploadTaskMutationVariables = Exact<{
  input: GQAssignUserUploadTaskInput;
}>;

export type GQAssignUserUploadTaskMutation = {
  __typename?: 'Mutation';
  assignUserUploadTask: {
    __typename?: 'AssignUserUploadTaskPayload';
    userUploadTask: {
      __typename?: 'UserUploadTask';
      id: string;
      assignee: { __typename?: 'User'; id: string; name: string } | null;
      datasource: {
        __typename?: 'Datasource';
        id: string;
        dataset: { __typename?: 'Dataset'; id: string; name: string };
      };
      measurementProject: {
        __typename?: 'MeasurementProject';
        id: string;
        name: string;
        active: boolean;
        coverageStartDate: Date;
        coverageEndDate: Date;
        completedAt: Date | null;
      };
    } | null;
  };
};

export type GQGetAllAssignableUsersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQGetAllAssignableUsersQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          name: string;
          displayName: string;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQAssignableUserFieldsFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  displayName: string;
};

export type GQPublicDisclosureDisplayFragment = {
  __typename?: 'PublicDisclosure';
  id: string;
  companyId: string | null;
  reportType: GQExternalReportType;
  publishingYear: number;
  gsUrl: string | null;
  publicUrl: string;
  sectionUrls: Array<{
    __typename?: 'ExternalReportSectionUrl';
    id: string;
    sectionKind: GQExternalReportSectionKind;
    urlFragment: string;
  }> | null;
  company: {
    __typename?: 'Company';
    id: string;
    name: string;
    fullLogoUrl: string | null;
  } | null;
};

export type GQPeerReportsQueryVariables = Exact<{
  reportType: InputMaybe<GQExternalReportType>;
  limit: InputMaybe<Scalars['Int']['input']>;
}>;

export type GQPeerReportsQuery = {
  __typename?: 'Query';
  publicDisclosures: Array<{
    __typename?: 'PublicDisclosure';
    id: string;
    companyId: string | null;
    reportType: GQExternalReportType;
    publishingYear: number;
    gsUrl: string | null;
    publicUrl: string;
    sectionUrls: Array<{
      __typename?: 'ExternalReportSectionUrl';
      id: string;
      sectionKind: GQExternalReportSectionKind;
      urlFragment: string;
    }> | null;
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      fullLogoUrl: string | null;
    } | null;
  }> | null;
  organization: {
    __typename?: 'Organization';
    id: string;
    company: { __typename?: 'Company'; id: string };
  };
};

type GQReportItemUi_ReportItemCallout_Fragment = {
  __typename?: 'ReportItemCallout';
  id: string;
  itemNumber: string | null;
  label: string | null;
  sublabel: string | null;
  optional: boolean | null;
  optionalMessage: string | null;
};

type GQReportItemUi_ReportItemSecrStandardTable_Fragment = {
  __typename?: 'ReportItemSecrStandardTable';
  id: string;
  itemNumber: string | null;
  label: string | null;
  sublabel: string | null;
  optional: boolean | null;
  optionalMessage: string | null;
};

type GQReportItemUi_ReportItemSingleValue_Fragment = {
  __typename?: 'ReportItemSingleValue';
  id: string;
  itemNumber: string | null;
  label: string | null;
  sublabel: string | null;
  optional: boolean | null;
  optionalMessage: string | null;
};

type GQReportItemUi_ReportItemStandardTable_Fragment = {
  __typename?: 'ReportItemStandardTable';
  id: string;
  itemNumber: string | null;
  label: string | null;
  sublabel: string | null;
  optional: boolean | null;
  optionalMessage: string | null;
};

export type GQReportItemUiFragment =
  | GQReportItemUi_ReportItemCallout_Fragment
  | GQReportItemUi_ReportItemSecrStandardTable_Fragment
  | GQReportItemUi_ReportItemSingleValue_Fragment
  | GQReportItemUi_ReportItemStandardTable_Fragment;

export type GQReportFileAttachmentItemFieldsFragment = {
  __typename?: 'ReportAttachmentItem';
  authorId: string;
  createdAt: Date;
  fmdId: string | null;
  id: string;
  kind: GQReportAttachmentItemKind;
  name: string | null;
  orgId: string;
  updatedAt: Date;
  url: string | null;
};

export type GQReportAttachmentItemsQueryVariables = Exact<{
  kinds: Array<GQReportAttachmentItemKind> | GQReportAttachmentItemKind;
}>;

export type GQReportAttachmentItemsQuery = {
  __typename?: 'Query';
  reportAttachmentItems: Array<{
    __typename?: 'ReportAttachmentItem';
    authorId: string;
    createdAt: Date;
    fmdId: string | null;
    id: string;
    kind: GQReportAttachmentItemKind;
    name: string | null;
    orgId: string;
    updatedAt: Date;
    url: string | null;
  }>;
};

export type GQCreateReportUrlAttachmentItemMutationVariables = Exact<{
  input: GQCreateReportUrlAttachmentItemInput;
}>;

export type GQCreateReportUrlAttachmentItemMutation = {
  __typename?: 'Mutation';
  createReportURLAttachmentItem: {
    __typename?: 'CreateReportURLAttachmentItemPayload';
    url: string;
    attachment: {
      __typename?: 'ReportAttachmentItem';
      authorId: string;
      createdAt: Date;
      fmdId: string | null;
      id: string;
      kind: GQReportAttachmentItemKind;
      name: string | null;
      orgId: string;
      updatedAt: Date;
      url: string | null;
    };
  } | null;
};

export type GQCreateReportFileAttachmentsMutationVariables = Exact<{
  input: GQCreateReportFileAttachmentsInput;
}>;

export type GQCreateReportFileAttachmentsMutation = {
  __typename?: 'Mutation';
  createReportFileAttachments: {
    __typename?: 'CreateReportFileAttachmentsPayload';
    items: Array<{
      __typename?: 'CreateReportFileAttachmentItemPayload';
      url: string;
      attachment: {
        __typename?: 'ReportAttachmentItem';
        authorId: string;
        createdAt: Date;
        fmdId: string | null;
        id: string;
        kind: GQReportAttachmentItemKind;
        name: string | null;
        orgId: string;
        updatedAt: Date;
        url: string | null;
      };
    }>;
  } | null;
};

export type GQCreateReportAttachmentsMutationVariables = Exact<{
  input: GQCreateReportAttachmentsInput;
}>;

export type GQCreateReportAttachmentsMutation = {
  __typename?: 'Mutation';
  createReportAttachments: {
    __typename?: 'CreateReportAttachmentsPayload';
    attachmentItems: Array<{
      __typename?: 'ReportAttachmentItem';
      id: string;
      name: string | null;
      kind: GQReportAttachmentItemKind;
      url: string | null;
      fmdId: string | null;
      authorId: string;
      createdAt: Date;
      updatedAt: Date;
    }>;
  } | null;
};

export type GQDeleteReportAttachmentItemMutationVariables = Exact<{
  input: GQDeleteReportAttachmentItemInput;
}>;

export type GQDeleteReportAttachmentItemMutation = {
  __typename?: 'Mutation';
  deleteReportAttachmentItem: {
    __typename?: 'DeleteReportAttachmentItemPayload';
    success: boolean;
  } | null;
};

export type GQReportAttachmentForListFragment = {
  __typename?: 'ReportAttachmentItem';
  id: string;
  name: string | null;
  kind: GQReportAttachmentItemKind;
  url: string | null;
  fmdId: string | null;
  authorId: string;
  createdAt: Date;
  updatedAt: Date;
};

export type GQGetReportAttachmentItemsForQuestionQueryVariables = Exact<{
  reportId: Scalars['ID']['input'];
  reportQuestionId: Scalars['ID']['input'];
}>;

export type GQGetReportAttachmentItemsForQuestionQuery = {
  __typename?: 'Query';
  reportAttachmentItemsForQuestion: Array<{
    __typename?: 'ReportAttachmentItem';
    id: string;
    name: string | null;
    kind: GQReportAttachmentItemKind;
    url: string | null;
    fmdId: string | null;
    authorId: string;
    createdAt: Date;
    updatedAt: Date;
  }>;
};

export type GQDeleteReportAttachmentMutationVariables = Exact<{
  input: GQDeleteReportAttachmentInput;
}>;

export type GQDeleteReportAttachmentMutation = {
  __typename?: 'Mutation';
  deleteReportAttachment: {
    __typename?: 'DeleteReportAttachmentPayload';
    success: boolean;
  } | null;
};

export type GQCreateSupplierContactMutationVariables = Exact<{
  input: GQCreateSupplierContactInput;
  supplierId: Scalars['String']['input'];
}>;

export type GQCreateSupplierContactMutation = {
  __typename?: 'Mutation';
  createSupplierContact: {
    __typename?: 'CreateSupplierContactPayload';
    contact: {
      __typename?: 'SupplierContact';
      id: string;
      name: string;
      email: string;
      role: string | null;
      userId: string | null;
      companyId: string;
      company: {
        __typename?: 'Company';
        id: string;
        contacts: Array<{ __typename?: 'SupplierContact'; id: string }>;
      };
    };
  } | null;
};

export type GQUpdateSupplierContactMutationVariables = Exact<{
  input: GQUpdateSupplierContactInput;
  supplierId: Scalars['String']['input'];
}>;

export type GQUpdateSupplierContactMutation = {
  __typename?: 'Mutation';
  updateSupplierContact: {
    __typename?: 'UpdateSupplierContactPayload';
    contact: {
      __typename?: 'SupplierContact';
      id: string;
      name: string;
      email: string;
      role: string | null;
      userId: string | null;
      companyId: string;
    };
  } | null;
};

export type GQDeleteSupplierContactMutationVariables = Exact<{
  input: GQDeleteSupplierContactInput;
  supplierId: Scalars['String']['input'];
}>;

export type GQDeleteSupplierContactMutation = {
  __typename?: 'Mutation';
  deleteSupplierContact: {
    __typename?: 'DeleteSupplierContactPayload';
    numberDeleted: number;
    id: string;
    company: {
      __typename?: 'Company';
      id: string;
      contacts: Array<{ __typename?: 'SupplierContact'; id: string }>;
    };
  } | null;
};

export type GQEngagementTaskConfigForTaskCreationFragment = {
  __typename?: 'EngagementTaskConfig';
  id: string;
  name: string;
  orgId: string | null;
  order: number;
  category: GQSurveyDefinitionCategory;
  rootFacingDescription: string | null;
  defaultSupplierFacingDescription: string | null;
  crossOrgModelId: string | null;
  engagementTaskInputs: Array<GQEngagementTaskInput>;
  type: GQEngagementTaskConfigType | null;
  optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
  timeNeeded: number | null;
};

export type GQGetEngagementTaskConfigsForTaskCreationQueryVariables = Exact<{
  includeGlobalConfigs: Scalars['Boolean']['input'];
  orgId: Scalars['ID']['input'];
}>;

export type GQGetEngagementTaskConfigsForTaskCreationQuery = {
  __typename?: 'Query';
  engagementTaskConfigs: Array<{
    __typename?: 'EngagementTaskConfig';
    id: string;
    name: string;
    orgId: string | null;
    order: number;
    category: GQSurveyDefinitionCategory;
    rootFacingDescription: string | null;
    defaultSupplierFacingDescription: string | null;
    crossOrgModelId: string | null;
    engagementTaskInputs: Array<GQEngagementTaskInput>;
    type: GQEngagementTaskConfigType | null;
    optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
    timeNeeded: number | null;
  }>;
};

export type GQAllBusinessActivityTypeFieldFieldsFragment = {
  __typename?: 'BusinessActivityTypeField';
  id: string;
  typeId: string;
  versionId: string;
  description: string;
  fieldName: string;
  fieldType: GQBusinessActivityTypeFieldType;
  fieldValueOneOf: any | null;
  isRequired: boolean;
  isProratable: boolean;
  unitFieldName: string | null;
};

export type GQAllBusinessActivityTypeFieldsFragment = {
  __typename?: 'BusinessActivityType';
  id: string;
  versionId: string;
  name: string;
  description: string;
  fields: Array<{
    __typename?: 'BusinessActivityTypeField';
    id: string;
    typeId: string;
    versionId: string;
    description: string;
    fieldName: string;
    fieldType: GQBusinessActivityTypeFieldType;
    fieldValueOneOf: any | null;
    isRequired: boolean;
    isProratable: boolean;
    unitFieldName: string | null;
  }>;
};

export type GQBusinessActivityTypeVersionNamesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQBusinessActivityTypeVersionNamesQuery = {
  __typename?: 'Query';
  businessActivityTypeVersions: Array<{
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
  }>;
};

export type GQBusinessActivityTypeVersionByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQBusinessActivityTypeVersionByIdQuery = {
  __typename?: 'Query';
  businessActivityTypeVersionById: {
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
    businessActivityTypes: Array<{
      __typename?: 'BusinessActivityType';
      id: string;
      versionId: string;
      name: string;
      description: string;
      fields: Array<{
        __typename?: 'BusinessActivityTypeField';
        id: string;
        typeId: string;
        versionId: string;
        description: string;
        fieldName: string;
        fieldType: GQBusinessActivityTypeFieldType;
        fieldValueOneOf: any | null;
        isRequired: boolean;
        isProratable: boolean;
        unitFieldName: string | null;
      }>;
    }>;
  };
};

export type GQVersionedBusinessActivityTypesQueryVariables = Exact<{
  input:
    | Array<GQVersionedBusinessActivityTypeInput>
    | GQVersionedBusinessActivityTypeInput;
}>;

export type GQVersionedBusinessActivityTypesQuery = {
  __typename?: 'Query';
  versionedBusinessActivityTypes: Array<{
    __typename?: 'BusinessActivityType';
    id: string;
    versionId: string;
    name: string;
    description: string;
    version: {
      __typename?: 'BusinessActivityTypeVersion';
      id: string;
      name: string;
    };
    fields: Array<{
      __typename?: 'BusinessActivityTypeField';
      id: string;
      typeId: string;
      versionId: string;
      description: string;
      fieldName: string;
      fieldType: GQBusinessActivityTypeFieldType;
      fieldValueOneOf: any | null;
      isRequired: boolean;
      isProratable: boolean;
      unitFieldName: string | null;
    }>;
  }>;
};

export type GQLiveBusinessActivityTypeVersionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLiveBusinessActivityTypeVersionQuery = {
  __typename?: 'Query';
  businessActivityTypeVersionLive: {
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
    businessActivityTypes: Array<{
      __typename?: 'BusinessActivityType';
      id: string;
      versionId: string;
      name: string;
      description: string;
      fields: Array<{
        __typename?: 'BusinessActivityTypeField';
        id: string;
        typeId: string;
        versionId: string;
        description: string;
        fieldName: string;
        fieldType: GQBusinessActivityTypeFieldType;
        fieldValueOneOf: any | null;
        isRequired: boolean;
        isProratable: boolean;
        unitFieldName: string | null;
      }>;
    }>;
  };
};

export type GQLiveBusinessActivityTypeVersionRecordQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLiveBusinessActivityTypeVersionRecordQuery = {
  __typename?: 'Query';
  businessActivityTypeVersionLive: {
    __typename?: 'BusinessActivityTypeVersion';
    id: string;
    name: string;
  };
};

export type GQCdpVendorDataFieldsFragment = {
  __typename?: 'CdpVendorData';
  id: string;
  publishingYear: number;
  reportingYear: number;
  scope1Nonzero: boolean;
  scope2Nonzero: boolean;
  scope3Nonzero: boolean;
  totalEmissionsNonzero: boolean;
  scope301Or302Nonzero: boolean;
  scope1Verified: boolean;
  scope2Verified: boolean;
  scope3Verified: boolean;
  pctEvaluationStatusesMatchResponse: number;
  disclosureQualityScore: GQDisclosureQualityScore;
  scope301EvaluationStatus: GQScope3EvaluationStatus;
  scope302EvaluationStatus: GQScope3EvaluationStatus;
  scope303EvaluationStatus: GQScope3EvaluationStatus;
  scope304EvaluationStatus: GQScope3EvaluationStatus;
  scope305EvaluationStatus: GQScope3EvaluationStatus;
  scope306EvaluationStatus: GQScope3EvaluationStatus;
  scope307EvaluationStatus: GQScope3EvaluationStatus;
  scope308EvaluationStatus: GQScope3EvaluationStatus;
  scope309EvaluationStatus: GQScope3EvaluationStatus;
  scope310EvaluationStatus: GQScope3EvaluationStatus;
  scope311EvaluationStatus: GQScope3EvaluationStatus;
  scope312EvaluationStatus: GQScope3EvaluationStatus;
  scope313EvaluationStatus: GQScope3EvaluationStatus;
  scope314EvaluationStatus: GQScope3EvaluationStatus;
  scope315EvaluationStatus: GQScope3EvaluationStatus;
  scope316EvaluationStatus: GQScope3EvaluationStatus;
  scope317EvaluationStatus: GQScope3EvaluationStatus;
};

export type GQPublicDisclosureFieldsFragment = {
  __typename?: 'PublicDisclosure';
  id: string;
  companyId: string | null;
  reportType: GQExternalReportType;
  publishingYear: number;
  publicUrl: string;
  gsUrl: string | null;
  qualityScore: GQDisclosureQualityScore | null;
  cdpVendorData: {
    __typename?: 'CdpVendorData';
    id: string;
    publishingYear: number;
    reportingYear: number;
    scope1Nonzero: boolean;
    scope2Nonzero: boolean;
    scope3Nonzero: boolean;
    totalEmissionsNonzero: boolean;
    scope301Or302Nonzero: boolean;
    scope1Verified: boolean;
    scope2Verified: boolean;
    scope3Verified: boolean;
    pctEvaluationStatusesMatchResponse: number;
    disclosureQualityScore: GQDisclosureQualityScore;
    scope301EvaluationStatus: GQScope3EvaluationStatus;
    scope302EvaluationStatus: GQScope3EvaluationStatus;
    scope303EvaluationStatus: GQScope3EvaluationStatus;
    scope304EvaluationStatus: GQScope3EvaluationStatus;
    scope305EvaluationStatus: GQScope3EvaluationStatus;
    scope306EvaluationStatus: GQScope3EvaluationStatus;
    scope307EvaluationStatus: GQScope3EvaluationStatus;
    scope308EvaluationStatus: GQScope3EvaluationStatus;
    scope309EvaluationStatus: GQScope3EvaluationStatus;
    scope310EvaluationStatus: GQScope3EvaluationStatus;
    scope311EvaluationStatus: GQScope3EvaluationStatus;
    scope312EvaluationStatus: GQScope3EvaluationStatus;
    scope313EvaluationStatus: GQScope3EvaluationStatus;
    scope314EvaluationStatus: GQScope3EvaluationStatus;
    scope315EvaluationStatus: GQScope3EvaluationStatus;
    scope316EvaluationStatus: GQScope3EvaluationStatus;
    scope317EvaluationStatus: GQScope3EvaluationStatus;
  } | null;
};

export type GQEmissionsYearScope1FieldsFragment = {
  __typename?: 'EmissionsYear';
  scope1: number | null;
};

export type GQEmissionsYearScope2FieldsFragment = {
  __typename?: 'EmissionsYear';
  scope2Market: number | null;
  scope2Location: number | null;
};

export type GQEmissionsYearScope3FieldsFragment = {
  __typename?: 'EmissionsYear';
  scope3: number | null;
  scope301: number | null;
  scope302: number | null;
  scope303: number | null;
  scope304: number | null;
  scope305: number | null;
  scope306: number | null;
  scope307: number | null;
  scope308: number | null;
  scope309: number | null;
  scope310: number | null;
  scope311: number | null;
  scope312: number | null;
  scope313: number | null;
  scope314: number | null;
  scope315: number | null;
  scope316: number | null;
  scope317: number | null;
};

export type GQCompanyDisclosureHistoricalEmissionsYearFieldsFragment = {
  __typename?: 'EmissionsYear';
  id: string;
  publicDisclosureId: string | null;
  reportingYear: number;
  numEmployees: number | null;
  revenue: number | null;
  revenueCurrency: string | null;
  revenueUsd: number | null;
  percentageCleanEnergy: number | null;
  totalMwh: number | null;
  scope1: number | null;
  scope2Market: number | null;
  scope2Location: number | null;
  scope3: number | null;
  scope301: number | null;
  scope302: number | null;
  scope303: number | null;
  scope304: number | null;
  scope305: number | null;
  scope306: number | null;
  scope307: number | null;
  scope308: number | null;
  scope309: number | null;
  scope310: number | null;
  scope311: number | null;
  scope312: number | null;
  scope313: number | null;
  scope314: number | null;
  scope315: number | null;
  scope316: number | null;
  scope317: number | null;
};

export type GQCompanyFootprintEstimateOutputFieldsFragment = {
  __typename?: 'FootprintEstimateOutput';
  id: string;
  output: {
    __typename?: 'FootprintEstimateOutputByScope';
    scope1: number | null;
    scope2Market: number | null;
    scope2Location: number | null;
    scope3: number | null;
    scope301: number | null;
    scope302: number | null;
    scope303: number | null;
    scope304: number | null;
    scope305: number | null;
    scope306: number | null;
    scope307: number | null;
    scope308: number | null;
    scope309: number | null;
    scope310: number | null;
    scope311: number | null;
    scope312: number | null;
    scope313: number | null;
    scope314: number | null;
    scope315: number | null;
    scope316: number | null;
    scope317: number | null;
  } | null;
};

export type GQCompanyPublicDisclosureFieldsFragment = {
  __typename?: 'PublicDisclosure';
  id: string;
  publishingYear: number;
  reportType: GQExternalReportType;
  publicUrl: string;
  qualityScore: GQDisclosureQualityScore | null;
  cdpVendorData: {
    __typename?: 'CdpVendorData';
    id: string;
    publishingYear: number;
    reportingYear: number;
    scope1Nonzero: boolean;
    scope2Nonzero: boolean;
    scope3Nonzero: boolean;
    totalEmissionsNonzero: boolean;
    scope301Or302Nonzero: boolean;
    scope1Verified: boolean;
    scope2Verified: boolean;
    scope3Verified: boolean;
    pctEvaluationStatusesMatchResponse: number;
    disclosureQualityScore: GQDisclosureQualityScore;
    scope301EvaluationStatus: GQScope3EvaluationStatus;
    scope302EvaluationStatus: GQScope3EvaluationStatus;
    scope303EvaluationStatus: GQScope3EvaluationStatus;
    scope304EvaluationStatus: GQScope3EvaluationStatus;
    scope305EvaluationStatus: GQScope3EvaluationStatus;
    scope306EvaluationStatus: GQScope3EvaluationStatus;
    scope307EvaluationStatus: GQScope3EvaluationStatus;
    scope308EvaluationStatus: GQScope3EvaluationStatus;
    scope309EvaluationStatus: GQScope3EvaluationStatus;
    scope310EvaluationStatus: GQScope3EvaluationStatus;
    scope311EvaluationStatus: GQScope3EvaluationStatus;
    scope312EvaluationStatus: GQScope3EvaluationStatus;
    scope313EvaluationStatus: GQScope3EvaluationStatus;
    scope314EvaluationStatus: GQScope3EvaluationStatus;
    scope315EvaluationStatus: GQScope3EvaluationStatus;
    scope316EvaluationStatus: GQScope3EvaluationStatus;
    scope317EvaluationStatus: GQScope3EvaluationStatus;
  } | null;
};

export type GQCompanyRelationshipFieldsFragment = {
  __typename?: 'CompanyRelationship';
  id: string;
  relationshipType: GQCompanyRelationshipType;
  relationshipStartDate: YearMonth | null;
  relationshipEndDate: YearMonth | null;
  companyA: { __typename?: 'Company'; id: string; name: string };
  companyB: { __typename?: 'Company'; id: string; name: string };
};

export type GQParentRelationshipFieldsFragment = {
  __typename?: 'ParentRelationship';
  id: string;
  relationshipStartDate: YearMonth | null;
  relationshipEndDate: YearMonth | null;
  parent: {
    __typename?: 'Company';
    id: string;
    name: string;
    disclosuresV2: Array<{
      __typename?: 'Disclosure';
      id: string;
      company: { __typename?: 'Company'; id: string } | null;
      publicDisclosure: {
        __typename?: 'PublicDisclosure';
        id: string;
        publishingYear: number;
        reportType: GQExternalReportType;
        publicUrl: string;
        qualityScore: GQDisclosureQualityScore | null;
        cdpVendorData: {
          __typename?: 'CdpVendorData';
          id: string;
          publishingYear: number;
          reportingYear: number;
          scope1Nonzero: boolean;
          scope2Nonzero: boolean;
          scope3Nonzero: boolean;
          totalEmissionsNonzero: boolean;
          scope301Or302Nonzero: boolean;
          scope1Verified: boolean;
          scope2Verified: boolean;
          scope3Verified: boolean;
          pctEvaluationStatusesMatchResponse: number;
          disclosureQualityScore: GQDisclosureQualityScore;
          scope301EvaluationStatus: GQScope3EvaluationStatus;
          scope302EvaluationStatus: GQScope3EvaluationStatus;
          scope303EvaluationStatus: GQScope3EvaluationStatus;
          scope304EvaluationStatus: GQScope3EvaluationStatus;
          scope305EvaluationStatus: GQScope3EvaluationStatus;
          scope306EvaluationStatus: GQScope3EvaluationStatus;
          scope307EvaluationStatus: GQScope3EvaluationStatus;
          scope308EvaluationStatus: GQScope3EvaluationStatus;
          scope309EvaluationStatus: GQScope3EvaluationStatus;
          scope310EvaluationStatus: GQScope3EvaluationStatus;
          scope311EvaluationStatus: GQScope3EvaluationStatus;
          scope312EvaluationStatus: GQScope3EvaluationStatus;
          scope313EvaluationStatus: GQScope3EvaluationStatus;
          scope314EvaluationStatus: GQScope3EvaluationStatus;
          scope315EvaluationStatus: GQScope3EvaluationStatus;
          scope316EvaluationStatus: GQScope3EvaluationStatus;
          scope317EvaluationStatus: GQScope3EvaluationStatus;
        } | null;
      } | null;
      privateDisclosure: {
        __typename?: 'PrivateDisclosure';
        id: string;
        companyId: string | null;
        orgId: string;
        orgName: string;
        surveyId: string | null;
        privateDisclosureType: GQPrivateDisclosureType;
        createdAt: Date;
        disclosureQualityScore: GQDisclosureQualityScore | null;
        disclosureQualityExplanation: string | null;
        thirdPartyVerified: boolean;
        cdpVendorData: {
          __typename?: 'CdpVendorData';
          id: string;
          publishingYear: number;
          reportingYear: number;
          scope1Nonzero: boolean;
          scope2Nonzero: boolean;
          scope3Nonzero: boolean;
          totalEmissionsNonzero: boolean;
          scope301Or302Nonzero: boolean;
          scope1Verified: boolean;
          scope2Verified: boolean;
          scope3Verified: boolean;
          pctEvaluationStatusesMatchResponse: number;
          disclosureQualityScore: GQDisclosureQualityScore;
          scope301EvaluationStatus: GQScope3EvaluationStatus;
          scope302EvaluationStatus: GQScope3EvaluationStatus;
          scope303EvaluationStatus: GQScope3EvaluationStatus;
          scope304EvaluationStatus: GQScope3EvaluationStatus;
          scope305EvaluationStatus: GQScope3EvaluationStatus;
          scope306EvaluationStatus: GQScope3EvaluationStatus;
          scope307EvaluationStatus: GQScope3EvaluationStatus;
          scope308EvaluationStatus: GQScope3EvaluationStatus;
          scope309EvaluationStatus: GQScope3EvaluationStatus;
          scope310EvaluationStatus: GQScope3EvaluationStatus;
          scope311EvaluationStatus: GQScope3EvaluationStatus;
          scope312EvaluationStatus: GQScope3EvaluationStatus;
          scope313EvaluationStatus: GQScope3EvaluationStatus;
          scope314EvaluationStatus: GQScope3EvaluationStatus;
          scope315EvaluationStatus: GQScope3EvaluationStatus;
          scope316EvaluationStatus: GQScope3EvaluationStatus;
          scope317EvaluationStatus: GQScope3EvaluationStatus;
        } | null;
      } | null;
      historicalEmissionsYears: Array<{
        __typename?: 'EmissionsYear';
        id: string;
        publicDisclosureId: string | null;
        reportingYear: number;
        numEmployees: number | null;
        revenue: number | null;
        revenueCurrency: string | null;
        revenueUsd: number | null;
        percentageCleanEnergy: number | null;
        totalMwh: number | null;
        units: GQCompanyEmissionsUnits;
        expenseCategory: string | null;
        scope1: number | null;
        scope2Market: number | null;
        scope2Location: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
      }> | null;
      targets: Array<{
        __typename?: 'DisclosureTargetDetails';
        id: string;
        name: string;
        description: string;
        baseYear: YearMonth;
        emissionsType: GQDisclosureTargetEmissionsType;
        reductionRate: GQDisclosureTargetReductionRate;
        intensityType: GQDisclosureTargetIntensityType;
        unit: string | null;
        unitDescription: string | null;
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        };
        emissionsTarget: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        };
      }> | null;
      initiatives: Array<{
        __typename?: 'DisclosureInitiative';
        id: string;
        name: string | null;
        initiativeType: GQDisclosureInitiativeType;
        description: string | null;
        startYearMonth: YearMonth | null;
        endYearMonth: YearMonth | null;
        intensityType: GQDisclosureTargetIntensityType | null;
        emissionsTimeseries: {
          __typename?: 'SimpleTimeseries';
          id: string | null;
          base: Date;
          frequency: GQTimeseriesFrequency;
          values: Array<number>;
        } | null;
        filters: {
          __typename?: 'FilterExpressionGroup';
          conjunction: GQFilterConjunction;
          expressions: Array<{
            __typename?: 'FilterExpressionPrimitive';
            field: GQFilterFieldLegacy;
            operator: GQFilterOperator;
            value: Array<string>;
          }>;
        } | null;
      }> | null;
      climateCommitments: Array<
        | {
            __typename?: 'CarbonNeutralCommitment';
            targetYear: number | null;
            id: string;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
          }
        | {
            __typename?: 'CleanEnergyCommitment';
            targetYear: number | null;
            targetPercentageCleanEnergy: number | null;
            id: string;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
          }
        | {
            __typename?: 'NetZeroCommitment';
            targetYear: number | null;
            id: string;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
          }
        | {
            __typename?: 'ScienceBasedTargetCommitment';
            submittedToSBTi: boolean;
            id: string;
            kind: GQCompanyClimateCommitmentKind;
            description: string | null;
            externalUrl: string | null;
            commitmentMadeDate: Date | null;
            commitmentPeriodStart: Date | null;
            commitmentPeriodEnd: Date | null;
            commitment: {
              __typename?: 'SBTCommitment';
              id: string;
              stage: GQSbtCommitmentStage | null;
              targetClassification: GQSbtTargetClassification | null;
              nearTermTargetYear: number | null;
              longTermTargetYear: number | null;
              baselineYear: number | null;
              netZeroCommitted: boolean | null;
              commitmentType: GQSbtCommitmentType | null;
              commitmentDeadline: Date | null;
              netZeroTargetYear: number | null;
            };
          }
      > | null;
    }>;
  };
};

export type GQParentRelationshipForSidebarFieldsFragment = {
  __typename?: 'ParentRelationship';
  id: string;
  relationshipStartDate: YearMonth | null;
  relationshipEndDate: YearMonth | null;
  parent: { __typename?: 'Company'; id: string; name: string };
};

export type GQParentCompanyWithDisclosuresFieldsFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
  disclosuresV2: Array<{
    __typename?: 'Disclosure';
    id: string;
    company: { __typename?: 'Company'; id: string } | null;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      publishingYear: number;
      reportType: GQExternalReportType;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    privateDisclosure: {
      __typename?: 'PrivateDisclosure';
      id: string;
      companyId: string | null;
      orgId: string;
      orgName: string;
      surveyId: string | null;
      privateDisclosureType: GQPrivateDisclosureType;
      createdAt: Date;
      disclosureQualityScore: GQDisclosureQualityScore | null;
      disclosureQualityExplanation: string | null;
      thirdPartyVerified: boolean;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      publicDisclosureId: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      revenueUsd: number | null;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      units: GQCompanyEmissionsUnits;
      expenseCategory: string | null;
      scope1: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
    }> | null;
    targets: Array<{
      __typename?: 'DisclosureTargetDetails';
      id: string;
      name: string;
      description: string;
      baseYear: YearMonth;
      emissionsType: GQDisclosureTargetEmissionsType;
      reductionRate: GQDisclosureTargetReductionRate;
      intensityType: GQDisclosureTargetIntensityType;
      unit: string | null;
      unitDescription: string | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      };
      emissionsTarget: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      };
    }> | null;
    initiatives: Array<{
      __typename?: 'DisclosureInitiative';
      id: string;
      name: string | null;
      initiativeType: GQDisclosureInitiativeType;
      description: string | null;
      startYearMonth: YearMonth | null;
      endYearMonth: YearMonth | null;
      intensityType: GQDisclosureTargetIntensityType | null;
      emissionsTimeseries: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      } | null;
      filters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'NetZeroCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            netZeroCommitted: boolean | null;
            commitmentType: GQSbtCommitmentType | null;
            commitmentDeadline: Date | null;
            netZeroTargetYear: number | null;
          };
        }
    > | null;
  }>;
};

export type GQCompanyFieldsForCcisFragment = {
  __typename?: 'Company';
  id: string;
  naicsCode: string | null;
  sbtiStage: GQCompanySbtCommitmentStage;
  climateProgress: GQCompanyClimateProgress;
  sustainabilityWebsiteUrl: string | null;
  isWatershedCorporateCustomer: boolean;
};

export type GQCompanyFieldsForCcisWithDisclosuresFragment = {
  __typename?: 'Company';
  id: string;
  naicsCode: string | null;
  sbtiStage: GQCompanySbtCommitmentStage;
  climateProgress: GQCompanyClimateProgress;
  sustainabilityWebsiteUrl: string | null;
  isWatershedCorporateCustomer: boolean;
  publicDisclosures: Array<{
    __typename?: 'Disclosure';
    id: string;
    publicDisclosure: {
      __typename?: 'PublicDisclosure';
      id: string;
      publishingYear: number;
      reportType: GQExternalReportType;
      publicUrl: string;
      qualityScore: GQDisclosureQualityScore | null;
      cdpVendorData: {
        __typename?: 'CdpVendorData';
        id: string;
        publishingYear: number;
        reportingYear: number;
        scope1Nonzero: boolean;
        scope2Nonzero: boolean;
        scope3Nonzero: boolean;
        totalEmissionsNonzero: boolean;
        scope301Or302Nonzero: boolean;
        scope1Verified: boolean;
        scope2Verified: boolean;
        scope3Verified: boolean;
        pctEvaluationStatusesMatchResponse: number;
        disclosureQualityScore: GQDisclosureQualityScore;
        scope301EvaluationStatus: GQScope3EvaluationStatus;
        scope302EvaluationStatus: GQScope3EvaluationStatus;
        scope303EvaluationStatus: GQScope3EvaluationStatus;
        scope304EvaluationStatus: GQScope3EvaluationStatus;
        scope305EvaluationStatus: GQScope3EvaluationStatus;
        scope306EvaluationStatus: GQScope3EvaluationStatus;
        scope307EvaluationStatus: GQScope3EvaluationStatus;
        scope308EvaluationStatus: GQScope3EvaluationStatus;
        scope309EvaluationStatus: GQScope3EvaluationStatus;
        scope310EvaluationStatus: GQScope3EvaluationStatus;
        scope311EvaluationStatus: GQScope3EvaluationStatus;
        scope312EvaluationStatus: GQScope3EvaluationStatus;
        scope313EvaluationStatus: GQScope3EvaluationStatus;
        scope314EvaluationStatus: GQScope3EvaluationStatus;
        scope315EvaluationStatus: GQScope3EvaluationStatus;
        scope316EvaluationStatus: GQScope3EvaluationStatus;
        scope317EvaluationStatus: GQScope3EvaluationStatus;
      } | null;
    } | null;
    historicalEmissionsYears: Array<{
      __typename?: 'EmissionsYear';
      id: string;
      publicDisclosureId: string | null;
      reportingYear: number;
      numEmployees: number | null;
      revenue: number | null;
      revenueCurrency: string | null;
      revenueUsd: number | null;
      percentageCleanEnergy: number | null;
      totalMwh: number | null;
      scope1: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
    }> | null;
    climateCommitments: Array<
      | {
          __typename?: 'CarbonNeutralCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'CleanEnergyCommitment';
          targetYear: number | null;
          targetPercentageCleanEnergy: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'NetZeroCommitment';
          targetYear: number | null;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
        }
      | {
          __typename?: 'ScienceBasedTargetCommitment';
          submittedToSBTi: boolean;
          id: string;
          kind: GQCompanyClimateCommitmentKind;
          description: string | null;
          externalUrl: string | null;
          commitmentMadeDate: Date | null;
          commitmentPeriodStart: Date | null;
          commitmentPeriodEnd: Date | null;
          commitment: {
            __typename?: 'SBTCommitment';
            id: string;
            stage: GQSbtCommitmentStage | null;
            targetClassification: GQSbtTargetClassification | null;
            nearTermTargetYear: number | null;
            longTermTargetYear: number | null;
            baselineYear: number | null;
            netZeroCommitted: boolean | null;
            commitmentType: GQSbtCommitmentType | null;
            commitmentDeadline: Date | null;
            netZeroTargetYear: number | null;
          };
        }
    > | null;
  }>;
};

export type GQSbtCommitmentFieldsFragment = {
  __typename?: 'SBTCommitment';
  id: string;
  stage: GQSbtCommitmentStage | null;
  targetClassification: GQSbtTargetClassification | null;
  nearTermTargetYear: number | null;
  longTermTargetYear: number | null;
  baselineYear: number | null;
  netZeroCommitted: boolean | null;
  commitmentType: GQSbtCommitmentType | null;
  commitmentDeadline: Date | null;
  netZeroTargetYear: number | null;
};

type GQCompanyClimateCommitmentFieldsForCcis_CarbonNeutralCommitment_Fragment =
  {
    __typename?: 'CarbonNeutralCommitment';
    targetYear: number | null;
    id: string;
    kind: GQCompanyClimateCommitmentKind;
    description: string | null;
    externalUrl: string | null;
    commitmentMadeDate: Date | null;
    commitmentPeriodStart: Date | null;
    commitmentPeriodEnd: Date | null;
  };

type GQCompanyClimateCommitmentFieldsForCcis_CleanEnergyCommitment_Fragment = {
  __typename?: 'CleanEnergyCommitment';
  targetYear: number | null;
  targetPercentageCleanEnergy: number | null;
  id: string;
  kind: GQCompanyClimateCommitmentKind;
  description: string | null;
  externalUrl: string | null;
  commitmentMadeDate: Date | null;
  commitmentPeriodStart: Date | null;
  commitmentPeriodEnd: Date | null;
};

type GQCompanyClimateCommitmentFieldsForCcis_NetZeroCommitment_Fragment = {
  __typename?: 'NetZeroCommitment';
  targetYear: number | null;
  id: string;
  kind: GQCompanyClimateCommitmentKind;
  description: string | null;
  externalUrl: string | null;
  commitmentMadeDate: Date | null;
  commitmentPeriodStart: Date | null;
  commitmentPeriodEnd: Date | null;
};

type GQCompanyClimateCommitmentFieldsForCcis_ScienceBasedTargetCommitment_Fragment =
  {
    __typename?: 'ScienceBasedTargetCommitment';
    submittedToSBTi: boolean;
    id: string;
    kind: GQCompanyClimateCommitmentKind;
    description: string | null;
    externalUrl: string | null;
    commitmentMadeDate: Date | null;
    commitmentPeriodStart: Date | null;
    commitmentPeriodEnd: Date | null;
    commitment: {
      __typename?: 'SBTCommitment';
      id: string;
      stage: GQSbtCommitmentStage | null;
      targetClassification: GQSbtTargetClassification | null;
      nearTermTargetYear: number | null;
      longTermTargetYear: number | null;
      baselineYear: number | null;
      netZeroCommitted: boolean | null;
      commitmentType: GQSbtCommitmentType | null;
      commitmentDeadline: Date | null;
      netZeroTargetYear: number | null;
    };
  };

export type GQCompanyClimateCommitmentFieldsForCcisFragment =
  | GQCompanyClimateCommitmentFieldsForCcis_CarbonNeutralCommitment_Fragment
  | GQCompanyClimateCommitmentFieldsForCcis_CleanEnergyCommitment_Fragment
  | GQCompanyClimateCommitmentFieldsForCcis_NetZeroCommitment_Fragment
  | GQCompanyClimateCommitmentFieldsForCcis_ScienceBasedTargetCommitment_Fragment;

export type GQProposeTargetFieldsFragment = {
  __typename?: 'EngagementTask';
  proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
  proposeTargetFilters: {
    __typename?: 'FilterExpressionGroup';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitive';
      field: GQFilterFieldLegacy;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  } | null;
  proposeTargetEmissionsTarget: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  } | null;
};

export type GQExpenseCategoryFieldsFragment = {
  __typename?: 'DiscloseEmissionsExpenseCategories';
  beaCode: string;
  inputUnit: string;
  inputQuantity: number;
  kgco2e: number;
};

export type GQEngagementTaskFieldsFragment = {
  __typename?: 'EngagementTask';
  id: string;
  crossOrgModelId: string;
  reportingYear: number | null;
  facilityName: string | null;
  submittedAt: Date | null;
  createdAt: Date;
  dueDate: Date | null;
  status: GQCompanySurveyStatus;
  surveyKind: GQCompanySurveyKind;
  supplierFacingDescription: string | null;
  watershedGeneric: boolean | null;
  currentAssignee: GQCompanySurveyAssignee;
  rootAssignee: string | null;
  supplierAssignee: string | null;
  initialSupplierAssigneeEmail: string | null;
  approvedAt: Date | null;
  statusChangedAt: Date | null;
  rootOrgId: string;
  supplierOrgId: string;
  latestCeeWorkflowId: string | null;
  latestPleeWorkflowId: string | null;
  latestFootprintDisclosureWorkflowId: string | null;
  engagementTaskBatchId: string | null;
  proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
  engagementTaskConfig: {
    __typename?: 'EngagementTaskConfig';
    id: string;
    name: string;
    sidebarTitle: string;
    defaultSupplierFacingDescription: string | null;
    rootFacingDescription: string | null;
    orgId: string | null;
    type: GQEngagementTaskConfigType | null;
    configHasTaskAssignedToCompany: boolean;
    timeNeeded: number | null;
    category: GQSurveyDefinitionCategory;
    engagementTaskInputs: Array<GQEngagementTaskInput>;
    optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
    containers: Array<{
      __typename?: 'EngagementTaskContainer';
      id: string;
      label: string;
      description: string | null;
      componentId: GQEngagementTaskContainerComponentId | null;
      editable: boolean;
      statusJson: any;
      sidebarStepLabelOverride: string | null;
      eyebrowText: string | null;
      headerImage: GQEngagementTaskImageId | null;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          answerIsPrivate: boolean;
          videoLink: string | null;
        }>;
      }>;
      questions: Array<{
        __typename?: 'EngagementTaskQuestion';
        id: string;
        label: string | null;
        description: string | null;
        componentId: GQEngagementTaskQuestionComponentId;
        editable: boolean;
        deletable: boolean;
        questionKey: string;
        statusJson: any;
        whyCollectThis: string | null;
        answerIsPrivate: boolean;
        videoLink: string | null;
      }>;
    }>;
    baseConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          answerIsPrivate: boolean;
          videoLink: string | null;
        }>;
      }>;
    } | null;
  };
  rootCompany: {
    __typename?: 'Company';
    id: string;
    name: string;
    fullLogoUrl: string | null;
    url: string | null;
  };
  company: {
    __typename?: 'Company';
    id: string;
    name: string;
    naicsCode: string | null;
    sbtiStage: GQCompanySbtCommitmentStage;
    publicDisclosures: Array<{
      __typename?: 'Disclosure';
      id: string;
      publicDisclosure: {
        __typename?: 'PublicDisclosure';
        id: string;
        publishingYear: number;
      } | null;
      historicalEmissionsYears: Array<{
        __typename?: 'EmissionsYear';
        id: string;
        publicDisclosureId: string | null;
        reportingYear: number;
        numEmployees: number | null;
        revenue: number | null;
        revenueCurrency: string | null;
        revenueUsd: number | null;
        percentageCleanEnergy: number | null;
        totalMwh: number | null;
        scope1: number | null;
        scope2Market: number | null;
        scope2Location: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
      }> | null;
    }>;
  };
  expenseCategories: Array<{
    __typename?: 'DiscloseEmissionsExpenseCategories';
    beaCode: string;
    inputUnit: string;
    inputQuantity: number;
    kgco2e: number;
  }> | null;
  proposeTargetFilters: {
    __typename?: 'FilterExpressionGroup';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitive';
      field: GQFilterFieldLegacy;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  } | null;
  proposeTargetEmissionsTarget: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  } | null;
};

export type GQEngagementTaskAnswerFieldsFragment = {
  __typename?: 'EngagementTaskAnswer';
  id: string;
  questionKey: string;
  answer: any | null;
  skipped: boolean;
  questionIndex: number | null;
  attachments: Array<{
    __typename?: 'EngagementTaskAnswerAttachment';
    originalFilename: string;
    signedDownloadUrl: string | null;
    questionKey: string;
    fileMetadata: { __typename?: 'FileMetadata'; id: string; createdAt: Date };
  }>;
};

export type GQEngagementTaskContentsFragment = {
  __typename?: 'EngagementTask';
  id: string;
  crossOrgModelId: string;
  reportingYear: number | null;
  facilityName: string | null;
  submittedAt: Date | null;
  createdAt: Date;
  dueDate: Date | null;
  status: GQCompanySurveyStatus;
  surveyKind: GQCompanySurveyKind;
  supplierFacingDescription: string | null;
  watershedGeneric: boolean | null;
  currentAssignee: GQCompanySurveyAssignee;
  rootAssignee: string | null;
  supplierAssignee: string | null;
  initialSupplierAssigneeEmail: string | null;
  approvedAt: Date | null;
  statusChangedAt: Date | null;
  rootOrgId: string;
  supplierOrgId: string;
  latestCeeWorkflowId: string | null;
  latestPleeWorkflowId: string | null;
  latestFootprintDisclosureWorkflowId: string | null;
  engagementTaskBatchId: string | null;
  proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
  submittedAnswers: Array<{
    __typename?: 'EngagementTaskAnswer';
    id: string;
    questionKey: string;
    answer: any | null;
    skipped: boolean;
    questionIndex: number | null;
    attachments: Array<{
      __typename?: 'EngagementTaskAnswerAttachment';
      originalFilename: string;
      signedDownloadUrl: string | null;
      questionKey: string;
      fileMetadata: {
        __typename?: 'FileMetadata';
        id: string;
        createdAt: Date;
      };
    }>;
  }>;
  comments: Array<{
    __typename?: 'EngagementTaskComment';
    id: string;
    createdAt: Date;
    userEditorId: string | null;
    userEditorName: string | null;
    creatorOrgId: string | null;
    comment: string | null;
  }>;
  edits: Array<{
    __typename?: 'CompanySurveyEdit';
    id: string;
    createdAt: Date;
    userEditorId: string | null;
    userEditorName: string | null;
    creatorOrgId: string | null;
    columnName: GQEditColumn;
    newValue: string | null;
    valueType: GQEditValueType | null;
  }>;
  footprintEstimate: {
    __typename?: 'FootprintEstimateOutput';
    id: string;
    output: {
      __typename?: 'FootprintEstimateOutputByScope';
      scope1: number | null;
      scope2Market: number | null;
      scope2Location: number | null;
      scope3: number | null;
      scope301: number | null;
      scope302: number | null;
      scope303: number | null;
      scope304: number | null;
      scope305: number | null;
      scope306: number | null;
      scope307: number | null;
      scope308: number | null;
      scope309: number | null;
      scope310: number | null;
      scope311: number | null;
      scope312: number | null;
      scope313: number | null;
      scope314: number | null;
      scope315: number | null;
      scope316: number | null;
      scope317: number | null;
    } | null;
  } | null;
  engagementTaskConfig: {
    __typename?: 'EngagementTaskConfig';
    id: string;
    name: string;
    sidebarTitle: string;
    defaultSupplierFacingDescription: string | null;
    rootFacingDescription: string | null;
    orgId: string | null;
    type: GQEngagementTaskConfigType | null;
    configHasTaskAssignedToCompany: boolean;
    timeNeeded: number | null;
    category: GQSurveyDefinitionCategory;
    engagementTaskInputs: Array<GQEngagementTaskInput>;
    optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
    containers: Array<{
      __typename?: 'EngagementTaskContainer';
      id: string;
      label: string;
      description: string | null;
      componentId: GQEngagementTaskContainerComponentId | null;
      editable: boolean;
      statusJson: any;
      sidebarStepLabelOverride: string | null;
      eyebrowText: string | null;
      headerImage: GQEngagementTaskImageId | null;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          answerIsPrivate: boolean;
          videoLink: string | null;
        }>;
      }>;
      questions: Array<{
        __typename?: 'EngagementTaskQuestion';
        id: string;
        label: string | null;
        description: string | null;
        componentId: GQEngagementTaskQuestionComponentId;
        editable: boolean;
        deletable: boolean;
        questionKey: string;
        statusJson: any;
        whyCollectThis: string | null;
        answerIsPrivate: boolean;
        videoLink: string | null;
      }>;
    }>;
    baseConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          answerIsPrivate: boolean;
          videoLink: string | null;
        }>;
      }>;
    } | null;
  };
  rootCompany: {
    __typename?: 'Company';
    id: string;
    name: string;
    fullLogoUrl: string | null;
    url: string | null;
  };
  company: {
    __typename?: 'Company';
    id: string;
    name: string;
    naicsCode: string | null;
    sbtiStage: GQCompanySbtCommitmentStage;
    publicDisclosures: Array<{
      __typename?: 'Disclosure';
      id: string;
      publicDisclosure: {
        __typename?: 'PublicDisclosure';
        id: string;
        publishingYear: number;
      } | null;
      historicalEmissionsYears: Array<{
        __typename?: 'EmissionsYear';
        id: string;
        publicDisclosureId: string | null;
        reportingYear: number;
        numEmployees: number | null;
        revenue: number | null;
        revenueCurrency: string | null;
        revenueUsd: number | null;
        percentageCleanEnergy: number | null;
        totalMwh: number | null;
        scope1: number | null;
        scope2Market: number | null;
        scope2Location: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
      }> | null;
    }>;
  };
  expenseCategories: Array<{
    __typename?: 'DiscloseEmissionsExpenseCategories';
    beaCode: string;
    inputUnit: string;
    inputQuantity: number;
    kgco2e: number;
  }> | null;
  proposeTargetFilters: {
    __typename?: 'FilterExpressionGroup';
    conjunction: GQFilterConjunction;
    expressions: Array<{
      __typename?: 'FilterExpressionPrimitive';
      field: GQFilterFieldLegacy;
      operator: GQFilterOperator;
      value: Array<string>;
    }>;
  } | null;
  proposeTargetEmissionsTarget: {
    __typename?: 'SimpleTimeseries';
    id: string | null;
    base: Date;
    frequency: GQTimeseriesFrequency;
    values: Array<number>;
  } | null;
};

export type GQGetEngagementTaskQueryVariables = Exact<{
  engagementTaskId: Scalars['ID']['input'];
}>;

export type GQGetEngagementTaskQuery = {
  __typename?: 'Query';
  engagementTask: {
    __typename?: 'EngagementTask';
    id: string;
    crossOrgModelId: string;
    reportingYear: number | null;
    facilityName: string | null;
    submittedAt: Date | null;
    createdAt: Date;
    dueDate: Date | null;
    status: GQCompanySurveyStatus;
    surveyKind: GQCompanySurveyKind;
    supplierFacingDescription: string | null;
    watershedGeneric: boolean | null;
    currentAssignee: GQCompanySurveyAssignee;
    rootAssignee: string | null;
    supplierAssignee: string | null;
    initialSupplierAssigneeEmail: string | null;
    approvedAt: Date | null;
    statusChangedAt: Date | null;
    rootOrgId: string;
    supplierOrgId: string;
    latestCeeWorkflowId: string | null;
    latestPleeWorkflowId: string | null;
    latestFootprintDisclosureWorkflowId: string | null;
    engagementTaskBatchId: string | null;
    proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
    submittedAnswers: Array<{
      __typename?: 'EngagementTaskAnswer';
      id: string;
      questionKey: string;
      answer: any | null;
      skipped: boolean;
      questionIndex: number | null;
      attachments: Array<{
        __typename?: 'EngagementTaskAnswerAttachment';
        originalFilename: string;
        signedDownloadUrl: string | null;
        questionKey: string;
        fileMetadata: {
          __typename?: 'FileMetadata';
          id: string;
          createdAt: Date;
        };
      }>;
    }>;
    comments: Array<{
      __typename?: 'EngagementTaskComment';
      id: string;
      createdAt: Date;
      userEditorId: string | null;
      userEditorName: string | null;
      creatorOrgId: string | null;
      comment: string | null;
    }>;
    edits: Array<{
      __typename?: 'CompanySurveyEdit';
      id: string;
      createdAt: Date;
      userEditorId: string | null;
      userEditorName: string | null;
      creatorOrgId: string | null;
      columnName: GQEditColumn;
      newValue: string | null;
      valueType: GQEditValueType | null;
    }>;
    footprintEstimate: {
      __typename?: 'FootprintEstimateOutput';
      id: string;
      output: {
        __typename?: 'FootprintEstimateOutputByScope';
        scope1: number | null;
        scope2Market: number | null;
        scope2Location: number | null;
        scope3: number | null;
        scope301: number | null;
        scope302: number | null;
        scope303: number | null;
        scope304: number | null;
        scope305: number | null;
        scope306: number | null;
        scope307: number | null;
        scope308: number | null;
        scope309: number | null;
        scope310: number | null;
        scope311: number | null;
        scope312: number | null;
        scope313: number | null;
        scope314: number | null;
        scope315: number | null;
        scope316: number | null;
        scope317: number | null;
      } | null;
    } | null;
    engagementTaskConfig: {
      __typename?: 'EngagementTaskConfig';
      id: string;
      name: string;
      sidebarTitle: string;
      defaultSupplierFacingDescription: string | null;
      rootFacingDescription: string | null;
      orgId: string | null;
      type: GQEngagementTaskConfigType | null;
      configHasTaskAssignedToCompany: boolean;
      timeNeeded: number | null;
      category: GQSurveyDefinitionCategory;
      engagementTaskInputs: Array<GQEngagementTaskInput>;
      optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
      containers: Array<{
        __typename?: 'EngagementTaskContainer';
        id: string;
        label: string;
        description: string | null;
        componentId: GQEngagementTaskContainerComponentId | null;
        editable: boolean;
        statusJson: any;
        sidebarStepLabelOverride: string | null;
        eyebrowText: string | null;
        headerImage: GQEngagementTaskImageId | null;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
        questions: Array<{
          __typename?: 'EngagementTaskQuestion';
          id: string;
          label: string | null;
          description: string | null;
          componentId: GQEngagementTaskQuestionComponentId;
          editable: boolean;
          deletable: boolean;
          questionKey: string;
          statusJson: any;
          whyCollectThis: string | null;
          answerIsPrivate: boolean;
          videoLink: string | null;
        }>;
      }>;
      baseConfig: {
        __typename?: 'EngagementTaskConfig';
        id: string;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
      } | null;
    };
    rootCompany: {
      __typename?: 'Company';
      id: string;
      name: string;
      fullLogoUrl: string | null;
      url: string | null;
    };
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      naicsCode: string | null;
      sbtiStage: GQCompanySbtCommitmentStage;
      publicDisclosures: Array<{
        __typename?: 'Disclosure';
        id: string;
        publicDisclosure: {
          __typename?: 'PublicDisclosure';
          id: string;
          publishingYear: number;
        } | null;
        historicalEmissionsYears: Array<{
          __typename?: 'EmissionsYear';
          id: string;
          publicDisclosureId: string | null;
          reportingYear: number;
          numEmployees: number | null;
          revenue: number | null;
          revenueCurrency: string | null;
          revenueUsd: number | null;
          percentageCleanEnergy: number | null;
          totalMwh: number | null;
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
        }> | null;
      }>;
    };
    expenseCategories: Array<{
      __typename?: 'DiscloseEmissionsExpenseCategories';
      beaCode: string;
      inputUnit: string;
      inputQuantity: number;
      kgco2e: number;
    }> | null;
    proposeTargetFilters: {
      __typename?: 'FilterExpressionGroup';
      conjunction: GQFilterConjunction;
      expressions: Array<{
        __typename?: 'FilterExpressionPrimitive';
        field: GQFilterFieldLegacy;
        operator: GQFilterOperator;
        value: Array<string>;
      }>;
    } | null;
    proposeTargetEmissionsTarget: {
      __typename?: 'SimpleTimeseries';
      id: string | null;
      base: Date;
      frequency: GQTimeseriesFrequency;
      values: Array<number>;
    } | null;
  } | null;
};

export type GQUpdateEngagementTaskMutationVariables = Exact<{
  input: GQUpdateEngagementTaskInput;
  supplierId: Scalars['ID']['input'];
}>;

export type GQUpdateEngagementTaskMutation = {
  __typename?: 'Mutation';
  updateEngagementTask: {
    __typename?: 'UpdateEngagementTaskPayload';
    runCeeBackgroundJobId: string | null;
    engagementTask: {
      __typename?: 'EngagementTask';
      id: string;
      crossOrgModelId: string;
      reportingYear: number | null;
      facilityName: string | null;
      submittedAt: Date | null;
      createdAt: Date;
      dueDate: Date | null;
      status: GQCompanySurveyStatus;
      surveyKind: GQCompanySurveyKind;
      supplierFacingDescription: string | null;
      watershedGeneric: boolean | null;
      currentAssignee: GQCompanySurveyAssignee;
      rootAssignee: string | null;
      supplierAssignee: string | null;
      initialSupplierAssigneeEmail: string | null;
      approvedAt: Date | null;
      statusChangedAt: Date | null;
      rootOrgId: string;
      supplierOrgId: string;
      latestCeeWorkflowId: string | null;
      latestPleeWorkflowId: string | null;
      latestFootprintDisclosureWorkflowId: string | null;
      engagementTaskBatchId: string | null;
      proposeTargetIntensityType: GQDisclosureTargetIntensityType | null;
      engagementTaskConfig: {
        __typename?: 'EngagementTaskConfig';
        id: string;
        name: string;
        sidebarTitle: string;
        defaultSupplierFacingDescription: string | null;
        rootFacingDescription: string | null;
        orgId: string | null;
        type: GQEngagementTaskConfigType | null;
        configHasTaskAssignedToCompany: boolean;
        timeNeeded: number | null;
        category: GQSurveyDefinitionCategory;
        engagementTaskInputs: Array<GQEngagementTaskInput>;
        optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
        containers: Array<{
          __typename?: 'EngagementTaskContainer';
          id: string;
          label: string;
          description: string | null;
          componentId: GQEngagementTaskContainerComponentId | null;
          editable: boolean;
          statusJson: any;
          sidebarStepLabelOverride: string | null;
          eyebrowText: string | null;
          headerImage: GQEngagementTaskImageId | null;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
          questions: Array<{
            __typename?: 'EngagementTaskQuestion';
            id: string;
            label: string | null;
            description: string | null;
            componentId: GQEngagementTaskQuestionComponentId;
            editable: boolean;
            deletable: boolean;
            questionKey: string;
            statusJson: any;
            whyCollectThis: string | null;
            answerIsPrivate: boolean;
            videoLink: string | null;
          }>;
        }>;
        baseConfig: {
          __typename?: 'EngagementTaskConfig';
          id: string;
          containers: Array<{
            __typename?: 'EngagementTaskContainer';
            id: string;
            label: string;
            description: string | null;
            componentId: GQEngagementTaskContainerComponentId | null;
            editable: boolean;
            statusJson: any;
            sidebarStepLabelOverride: string | null;
            eyebrowText: string | null;
            headerImage: GQEngagementTaskImageId | null;
            containers: Array<{
              __typename?: 'EngagementTaskContainer';
              id: string;
              label: string;
              description: string | null;
              componentId: GQEngagementTaskContainerComponentId | null;
              editable: boolean;
              statusJson: any;
              sidebarStepLabelOverride: string | null;
              eyebrowText: string | null;
              headerImage: GQEngagementTaskImageId | null;
              questions: Array<{
                __typename?: 'EngagementTaskQuestion';
                id: string;
                label: string | null;
                description: string | null;
                componentId: GQEngagementTaskQuestionComponentId;
                editable: boolean;
                deletable: boolean;
                questionKey: string;
                statusJson: any;
                whyCollectThis: string | null;
                answerIsPrivate: boolean;
                videoLink: string | null;
              }>;
            }>;
            questions: Array<{
              __typename?: 'EngagementTaskQuestion';
              id: string;
              label: string | null;
              description: string | null;
              componentId: GQEngagementTaskQuestionComponentId;
              editable: boolean;
              deletable: boolean;
              questionKey: string;
              statusJson: any;
              whyCollectThis: string | null;
              answerIsPrivate: boolean;
              videoLink: string | null;
            }>;
          }>;
        } | null;
      };
      rootCompany: {
        __typename?: 'Company';
        id: string;
        name: string;
        fullLogoUrl: string | null;
        url: string | null;
      };
      company: {
        __typename?: 'Company';
        id: string;
        name: string;
        naicsCode: string | null;
        sbtiStage: GQCompanySbtCommitmentStage;
        publicDisclosures: Array<{
          __typename?: 'Disclosure';
          id: string;
          publicDisclosure: {
            __typename?: 'PublicDisclosure';
            id: string;
            publishingYear: number;
          } | null;
          historicalEmissionsYears: Array<{
            __typename?: 'EmissionsYear';
            id: string;
            publicDisclosureId: string | null;
            reportingYear: number;
            numEmployees: number | null;
            revenue: number | null;
            revenueCurrency: string | null;
            revenueUsd: number | null;
            percentageCleanEnergy: number | null;
            totalMwh: number | null;
            scope1: number | null;
            scope2Market: number | null;
            scope2Location: number | null;
            scope3: number | null;
            scope301: number | null;
            scope302: number | null;
            scope303: number | null;
            scope304: number | null;
            scope305: number | null;
            scope306: number | null;
            scope307: number | null;
            scope308: number | null;
            scope309: number | null;
            scope310: number | null;
            scope311: number | null;
            scope312: number | null;
            scope313: number | null;
            scope314: number | null;
            scope315: number | null;
            scope316: number | null;
            scope317: number | null;
          }> | null;
        }>;
      };
      expenseCategories: Array<{
        __typename?: 'DiscloseEmissionsExpenseCategories';
        beaCode: string;
        inputUnit: string;
        inputQuantity: number;
        kgco2e: number;
      }> | null;
      submittedAnswers: Array<{
        __typename?: 'EngagementTaskAnswer';
        id: string;
        questionKey: string;
        answer: any | null;
        skipped: boolean;
        questionIndex: number | null;
        attachments: Array<{
          __typename?: 'EngagementTaskAnswerAttachment';
          originalFilename: string;
          signedDownloadUrl: string | null;
          questionKey: string;
          fileMetadata: {
            __typename?: 'FileMetadata';
            id: string;
            createdAt: Date;
          };
        }>;
      }>;
      comments: Array<{
        __typename?: 'EngagementTaskComment';
        id: string;
        createdAt: Date;
        userEditorId: string | null;
        userEditorName: string | null;
        creatorOrgId: string | null;
        comment: string | null;
      }>;
      edits: Array<{
        __typename?: 'CompanySurveyEdit';
        id: string;
        createdAt: Date;
        userEditorId: string | null;
        userEditorName: string | null;
        creatorOrgId: string | null;
        columnName: GQEditColumn;
        newValue: string | null;
        valueType: GQEditValueType | null;
      }>;
      footprintEstimate: {
        __typename?: 'FootprintEstimateOutput';
        id: string;
        output: {
          __typename?: 'FootprintEstimateOutputByScope';
          scope1: number | null;
          scope2Market: number | null;
          scope2Location: number | null;
          scope3: number | null;
          scope301: number | null;
          scope302: number | null;
          scope303: number | null;
          scope304: number | null;
          scope305: number | null;
          scope306: number | null;
          scope307: number | null;
          scope308: number | null;
          scope309: number | null;
          scope310: number | null;
          scope311: number | null;
          scope312: number | null;
          scope313: number | null;
          scope314: number | null;
          scope315: number | null;
          scope316: number | null;
          scope317: number | null;
        } | null;
      } | null;
      proposeTargetFilters: {
        __typename?: 'FilterExpressionGroup';
        conjunction: GQFilterConjunction;
        expressions: Array<{
          __typename?: 'FilterExpressionPrimitive';
          field: GQFilterFieldLegacy;
          operator: GQFilterOperator;
          value: Array<string>;
        }>;
      } | null;
      proposeTargetEmissionsTarget: {
        __typename?: 'SimpleTimeseries';
        id: string | null;
        base: Date;
        frequency: GQTimeseriesFrequency;
        values: Array<number>;
      } | null;
    } | null;
  } | null;
};

export type GQAddEngagementTaskCommentMutationVariables = Exact<{
  input: GQAddEngagementTaskCommentInput;
  engagementTaskId: Scalars['ID']['input'];
}>;

export type GQAddEngagementTaskCommentMutation = {
  __typename?: 'Mutation';
  addEngagementTaskComment: {
    __typename?: 'AddEngagementTaskCommentPayload';
    comment: {
      __typename?: 'EngagementTaskComment';
      id: string;
      createdAt: Date;
      userEditorId: string | null;
      comment: string | null;
    };
  } | null;
};

export type GQIngestionExampleDataFieldsFragment = {
  __typename?: 'IngestionExampleData';
  id: string;
  title: string;
  datasetId: string | null;
  columns: Array<{
    __typename?: 'IngestionExampleDataColumn';
    fieldName: string;
    title: string;
    description: string;
    examples: Array<string>;
    required: boolean;
    optionalToMap: boolean | null;
    defaultValue: any | null;
  }>;
  rows: Array<{
    __typename?: 'IngestionExampleDataRow';
    fields: Array<{
      __typename?: 'IngestionExampleDataRowField';
      fieldName: string;
      value: string;
    }>;
  }>;
};

export type GQAllOrgUsersForPermissionQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  permissions: Array<GQHasPermissionInput> | GQHasPermissionInput;
}>;

export type GQAllOrgUsersForPermissionQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          name: string;
          orgAccessId: string;
          permissionOrigin: {
            __typename?: 'PermissionOrigin';
            id: string;
            permission: {
              __typename?: 'PermissionItem';
              id: string;
              permission: GQPermissionType;
              object:
                | { __typename?: 'Company'; id: string; name: string }
                | { __typename?: 'CompanySurvey'; id: string; name: string }
                | { __typename?: 'Dataset'; id: string; name: string }
                | { __typename?: 'Datasource'; id: string; name: string }
                | { __typename?: 'EngagementTask'; id: string; name: string }
                | { __typename?: 'Fund'; id: string; name: string }
                | null;
            } | null;
            role: {
              __typename?: 'UserRoleAssignment';
              id: string;
              role: { __typename?: 'Role'; id: string; name: string };
            } | null;
          } | null;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQUserForAllOrgUsersForPermissionFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  orgAccessId: string;
  permissionOrigin: {
    __typename?: 'PermissionOrigin';
    id: string;
    permission: {
      __typename?: 'PermissionItem';
      id: string;
      permission: GQPermissionType;
      object:
        | { __typename?: 'Company'; id: string; name: string }
        | { __typename?: 'CompanySurvey'; id: string; name: string }
        | { __typename?: 'Dataset'; id: string; name: string }
        | { __typename?: 'Datasource'; id: string; name: string }
        | { __typename?: 'EngagementTask'; id: string; name: string }
        | { __typename?: 'Fund'; id: string; name: string }
        | null;
    } | null;
    role: {
      __typename?: 'UserRoleAssignment';
      id: string;
      role: { __typename?: 'Role'; id: string; name: string };
    } | null;
  } | null;
};

export type GQPermissionOriginForFetchOrgUsersForPermissionFragment = {
  __typename?: 'PermissionOrigin';
  id: string;
  permission: {
    __typename?: 'PermissionItem';
    id: string;
    permission: GQPermissionType;
    object:
      | { __typename?: 'Company'; id: string; name: string }
      | { __typename?: 'CompanySurvey'; id: string; name: string }
      | { __typename?: 'Dataset'; id: string; name: string }
      | { __typename?: 'Datasource'; id: string; name: string }
      | { __typename?: 'EngagementTask'; id: string; name: string }
      | { __typename?: 'Fund'; id: string; name: string }
      | null;
  } | null;
  role: {
    __typename?: 'UserRoleAssignment';
    id: string;
    role: { __typename?: 'Role'; id: string; name: string };
  } | null;
};

export type GQOrgUsersWithPermissionsQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
  permissionInfos: Array<GQHasPermissionInput> | GQHasPermissionInput;
}>;

export type GQOrgUsersWithPermissionsQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    users: {
      __typename?: 'UserConnection';
      edges: Array<{
        __typename?: 'UserEdge';
        node: {
          __typename?: 'User';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'PermissionItem';
            id: string;
            permission: GQPermissionType;
          }>;
          roles: Array<{
            __typename?: 'UserRoleAssignment';
            id: string;
            role: {
              __typename?: 'Role';
              id: string;
              permissions: Array<{
                __typename?: 'PermissionItem';
                id: string;
                permission: GQPermissionType;
              }>;
            };
          }>;
        } | null;
      } | null>;
    } | null;
  };
};

export type GQUserForOrgUsersWithPermissionsFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  permissions: Array<{
    __typename?: 'PermissionItem';
    id: string;
    permission: GQPermissionType;
  }>;
  roles: Array<{
    __typename?: 'UserRoleAssignment';
    id: string;
    role: {
      __typename?: 'Role';
      id: string;
      permissions: Array<{
        __typename?: 'PermissionItem';
        id: string;
        permission: GQPermissionType;
      }>;
    };
  }>;
};

export type GQSerializableErrorFieldsFragment = {
  __typename?: 'SerializableError';
  message: string;
  code: string | null;
  stackTrace: string | null;
  errorType: string | null;
  details: any | null;
};

export type GQGetJobResultsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetJobResultsQuery = {
  __typename?: 'Query';
  job: {
    __typename?: 'BackgroundJob';
    id: string;
    kind: string;
    state: GQBackgroundJobState;
    args: any | null;
    result: any | null;
    error: {
      __typename?: 'SerializableError';
      message: string;
      code: string | null;
      stackTrace: string | null;
      errorType: string | null;
      details: any | null;
    } | null;
  } | null;
};

export type GQGetJobStatusQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQGetJobStatusQuery = {
  __typename?: 'Query';
  job: {
    __typename?: 'BackgroundJobStatus';
    id: string;
    orgId: string | null;
    state: GQBackgroundJobState;
    error: {
      __typename?: 'SerializableError';
      message: string;
      code: string | null;
      stackTrace: string | null;
      errorType: string | null;
      details: any | null;
    } | null;
  } | null;
};

export type GQListJobsStatusQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GQListJobsStatusQuery = {
  __typename?: 'Query';
  backgroundJobStatuses: Array<{
    __typename?: 'BackgroundJobStatus';
    id: string;
    orgId: string | null;
    state: GQBackgroundJobState;
    error: {
      __typename?: 'SerializableError';
      message: string;
      code: string | null;
      stackTrace: string | null;
      errorType: string | null;
      details: any | null;
    } | null;
  }>;
};

export type GQCancelWorkflowMutationVariables = Exact<{
  input: GQCancelWorkflowInput;
}>;

export type GQCancelWorkflowMutation = {
  __typename?: 'Mutation';
  cancelWorkflow: {
    __typename?: 'CancelWorkflowPayload';
    success: boolean;
  } | null;
};

export type GQOverviewReportConfigFieldsFragment = {
  __typename?: 'ReportConfig';
  id: string;
  reportType: string;
  shortName: string;
  longName: string;
  description: string;
  brandColor: string;
  status: GQReportConfigStatus | null;
  isHiddenFromOverview: boolean;
  isCreationBlocked: boolean;
  isRegulatoryExposureBlocked: boolean;
  isCorporateReport: boolean;
  isFinanceReport: boolean;
  requiredPermissions: Array<GQPermissionType>;
  reportObjectives: Array<GQCanonicalReportObjective>;
};

export type GQGetAllReportConfigsQueryVariables = Exact<{
  filterHidden: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GQGetAllReportConfigsQuery = {
  __typename?: 'Query';
  allReportConfigs: Array<{
    __typename?: 'ReportConfig';
    id: string;
    reportType: string;
    shortName: string;
    longName: string;
    description: string;
    brandColor: string;
    status: GQReportConfigStatus | null;
    isHiddenFromOverview: boolean;
    isCreationBlocked: boolean;
    isRegulatoryExposureBlocked: boolean;
    isCorporateReport: boolean;
    isFinanceReport: boolean;
    requiredPermissions: Array<GQPermissionType>;
    reportObjectives: Array<GQCanonicalReportObjective>;
  }>;
};
