import { z } from 'zod';
import { GQCanonicalDatasetKind } from '../generated/graphql';
import { makeBetterEnum } from '../utils/betterEnum';
import { socialAndGovernanceTypeNames } from '../batSchemas/types';

import { msg } from '@lingui/core/macro';
import { MessageDescriptor } from '@lingui/core';

export const DEFAULT_EM_DESCRIPTION = `EM run generated from product`;
export const DEFAULT_FOOTPRINT_DESCRIPTION = `Footprint snapshot generated from product`;
export const HISTORICAL_EMISSIONS_BAT = 'historical_emissions';
export const HISTORICAL_EMISSIONS_PV2_BAT = 'historical_emissions_pv2';

// Footprint runs with 300k records will probably take >2 min
export const FOOTPRINT_GEN_DEDICATED_QUEUE_RECORDS_LIMIT = 300_000;

// Max BARTS per EM run. This is to prevent us from hitting
// temporal workflow history limits and message size limits, though
// we have not done any testing to see what the actual limits are.
export const MAX_BARTS_PER_EM_RUN = 100;

export const FOOTPRINT_SNAPSHOT_DRILLDOWN_PARAM = 'footprintSnapshotId';
export const DRILLDOWN_DRAWER_PARAM = 'drawer';

// to deprecate
export const ACTIVITY_DRAWER_QUERY_PARAM = 'viewActivity';
export const FOOTPRINT_QUESTION_QUERY_PARAM = 'footprintQuestion';

// These are NOT emissive, NOT used for intensity calculation, and we don't want
// them to be associated with a footprint.
export const DATASETS_TO_IGNORE_FOR_FOOTPRINT: Array<GQCanonicalDatasetKind> = [
  GQCanonicalDatasetKind.ChartOfAccounts,
];

export const INTENSITY_DENOMINATOR_BATS = [
  'intensity_denominator_custom',
  'intensity_denominator_monetary',
];

// A list of BATs that will be associated with a footprint but are non-emissive.
// (if adding to this list: don't forget to update NON_EMISSIVE_DATASET_KINDS in CanonicalDatasetUtils.ts too)
export const NON_EMISSIVE_ACTIVITY_BATS = [
  'site_land_use',
  'sold_product_circularity',
  'sold_product_recyclability',
  'carbon_credit_retirements',
  ...socialAndGovernanceTypeNames,
];

export const NON_EMISSIVE_BATS = [
  ...INTENSITY_DENOMINATOR_BATS,
  ...NON_EMISSIVE_ACTIVITY_BATS,
];

// order matters
export const FOOTPRINT_CONFIGURATION_DRAWER_TABS_SCHEMA = z.enum([
  'general',
  'calculation',
  'category-rules',
  'deduplication-rules',
]);
export const FOOTPRINT_CONFIGURATION_DRAWER_TABS =
  FOOTPRINT_CONFIGURATION_DRAWER_TABS_SCHEMA.enum;
export type FootprintConfigurationDrawerTab = z.infer<
  typeof FOOTPRINT_CONFIGURATION_DRAWER_TABS_SCHEMA
>;

export const DRILLDOWN_DRAWER_VALUES = makeBetterEnum(
  ...Array.from(Object.keys(FOOTPRINT_CONFIGURATION_DRAWER_TABS)),
  'versionTimeline',
  'versionTimelineDrilldown'
);
export type DrilldownDrawerValues = keyof typeof DRILLDOWN_DRAWER_VALUES;
export const SAVED_VIEW_DRAFT_ID = 'draft'; // Used as the value for an overflow/other category group name throughout drilldown + reductions
// Should not be displayed as is

// multiple footprints
export const LEGACY_FOOTPRINT_NAME = 'Legacy Footprint';
export const FOOTPRINT_NAME_MUST_TRIM_ERROR_MSG: MessageDescriptor = msg`Footprint name cannot start or end with whitespace`;
export const FOOTPRINT_NAME_NOT_UNIQUE_ERROR_MSG: MessageDescriptor = msg`Footprint name is already used`;
export const FOOTPRINT_NAME_NOT_EMPTY_ERROR_MSG: MessageDescriptor = msg`Footprint name cannot be empty`;
